var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-m-cls", staticStyle: {} },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: {
            "max-height": "500px",
            "overflow-y": "auto",
            margin: "0",
          },
        },
        [
          _c(
            "el-card",
            { staticStyle: { margin: "10px 0" }, attrs: { shadow: "never" } },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("留修工单"),
              ]),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "card_item" }, [
                      _c("div", { staticClass: "card_item_label" }, [
                        _vm._v("工单号："),
                      ]),
                      _c("span", [_vm._v(_vm._s(_vm.orderItem.jobNumber))]),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "card_item" }, [
                      _c("div", { staticClass: "card_item_label" }, [
                        _vm._v("工单来源："),
                      ]),
                      _c("span", [_vm._v(_vm._s(_vm.orderItem.sourceType))]),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "card_item" }, [
                      _c("div", { staticClass: "card_item_label" }, [
                        _vm._v("创建人："),
                      ]),
                      _c("span", [_vm._v(_vm._s(_vm.orderItem.creatorByName))]),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "card_item" }, [
                      _c("div", { staticClass: "card_item_label" }, [
                        _vm._v("创建时间："),
                      ]),
                      _c("span", [_vm._v(_vm._s(_vm.orderItem.fixedTime))]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-card",
            { staticStyle: { margin: "10px 0" }, attrs: { shadow: "never" } },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _c("div", [_vm._v("保险留修信息")]),
              ]),
              _c(
                "div",
                [
                  _c(
                    "el-form",
                    {
                      ref: "detail",
                      staticStyle: { "margin-bottom": "-20px" },
                      attrs: {
                        model: _vm.form,
                        "label-width": "120px",
                        size: "mini",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "线索短信：" } },
                            [_vm._v(" " + _vm._s(_vm.orderItem.cluesId) + " ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "保险公司：" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.orderItem.insCorpName) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "车牌号：" } },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.orderItem.plateNo) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "报案号：" } },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.orderItem.reportNo) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "报案时间：" } },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.orderItem.reportTime) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "车主名称：" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.orderItem.customerName) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "客户电话：" } },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.orderItem.phone) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "车辆现状：" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("filterCarStatus")(
                                          _vm.orderItem.carStatus,
                                          _vm.VehicleStatusList
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "品牌型号：" } },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.orderItem.modelName) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "留修类型：" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.orderItem.pushRepairTypeDisplay
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "报案地址：" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.orderItem.reportAddress) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-card",
            { staticStyle: { margin: "10px 0" }, attrs: { shadow: "never" } },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _c("div", [_vm._v("服务信息")]),
              ]),
              _c(
                "div",
                [
                  _c(
                    "el-form",
                    {
                      staticStyle: { "margin-bottom": "-20px" },
                      attrs: {
                        model: _vm.form,
                        "label-width": "110px",
                        size: "mini",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c("el-form-item", { attrs: { label: "接车员：" } }, [
                            _vm._v(
                              " " + _vm._s(_vm.orderItem.conductorUserId) + " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c("el-form-item", {
                                attrs: { label: "电话：" },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "分配时间：" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.orderItem.conductorConfirmTime
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c("el-form-item", {
                                attrs: { label: "跟进人：" },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "跟进状态：" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.orderItem.orderStatusDisplay) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c("el-form-item", {
                                attrs: { label: "跟进时间：" },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "跟进历史：" } },
                                [
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "javaScript:;" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.viewTrack($event)
                                          },
                                        },
                                      },
                                      [_vm._v("点击查看")]
                                    ),
                                  ],
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm.orderItem.state === "已关闭"
            ? _c(
                "el-card",
                {
                  staticStyle: { margin: "10px 0" },
                  attrs: { shadow: "never" },
                },
                [
                  _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                    _c("div", [_vm._v("关闭信息")]),
                  ]),
                  _c(
                    "div",
                    [
                      _c(
                        "el-form",
                        {
                          staticStyle: { "margin-bottom": "-20px" },
                          attrs: {
                            model: _vm.form,
                            "label-width": "110px",
                            size: "mini",
                          },
                        },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c("el-form-item", {
                                    attrs: { label: "关闭时间：" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c("el-form-item", {
                                    attrs: { label: "关闭说明：" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c("el-form-item", {
                                attrs: { label: "关闭原因：" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm.fixedDetailVOlists && _vm.fixedDetailVOlists.length != 0
            ? _c(
                "el-card",
                {
                  staticStyle: { margin: "10px 0" },
                  attrs: { shadow: "never" },
                },
                [
                  _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                    _c("div", [_vm._v("定损信息")]),
                  ]),
                  _c(
                    "div",
                    [
                      _c(
                        "el-form",
                        {
                          staticStyle: { "margin-bottom": "-20px" },
                          attrs: {
                            model: _vm.form,
                            "label-width": "110px",
                            size: "mini",
                          },
                        },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "定损金额：" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.orderItem.fixedAmount) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "定损详情：" } },
                                    [
                                      _c(
                                        "a",
                                        {
                                          attrs: { href: "javaScript:;" },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.lossDetial($event)
                                            },
                                          },
                                        },
                                        [_vm._v("点击查看")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "定损时间：" } },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.orderItem.fixedTime) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm.repairDetailLists && _vm.repairDetailLists.length != 0
            ? _c(
                "el-card",
                {
                  staticStyle: { margin: "10px 0" },
                  attrs: { shadow: "never" },
                },
                [
                  _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                    _c("div", [_vm._v("维修信息")]),
                  ]),
                  _c(
                    "div",
                    [
                      _c(
                        "el-form",
                        {
                          staticStyle: { "margin-bottom": "-20px" },
                          attrs: {
                            model: _vm.form,
                            "label-width": "110px",
                            size: "mini",
                          },
                        },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "维修状态：" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.orderItem.repairsStatusDisplay
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "更新时间：" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.orderItem.repairsTime) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm.orderItem.orderStatusDisplay == "已完结"
            ? _c(
                "el-card",
                {
                  staticStyle: { margin: "10px 0" },
                  attrs: { shadow: "never" },
                },
                [
                  _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                    _c("div", [_vm._v("交车信息")]),
                  ]),
                  _c(
                    "div",
                    [
                      _c(
                        "el-form",
                        {
                          staticStyle: { "margin-bottom": "-20px" },
                          attrs: {
                            model: _vm.form,
                            "label-width": "110px",
                            size: "mini",
                          },
                        },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "支付方式：" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.orderItem.payModeDisplay) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "支付金额：" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.orderItem.payAmount) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "交车时间：" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.orderItem.payTime) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _vm.orderItem.state === "跟进中"
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", plain: "", size: "mini" },
                  on: { click: _vm.trackOrder },
                },
                [_vm._v("跟进")]
              )
            : _vm._e(),
          ["跟进中", "待维修"].includes(_vm.orderItem.state)
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", plain: "", size: "mini" },
                  on: { click: _vm.uplaodImage },
                },
                [_vm._v("影像")]
              )
            : _vm._e(),
          _vm.orderItem.state === "待维修"
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", plain: "", size: "mini" },
                  on: { click: _vm.repairVehicle },
                },
                [_vm._v("维修")]
              )
            : _vm._e(),
          _vm.orderItem.state === "待维修"
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", plain: "", size: "mini" },
                  on: { click: _vm.closeOrder },
                },
                [_vm._v("关闭")]
              )
            : _vm._e(),
          _vm.orderItem.state === "待定损"
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", plain: "", size: "mini" },
                  on: { click: _vm.vehicleLoss },
                },
                [_vm._v("定损")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showTrackOrderDialog,
            "close-on-click-modal": false,
            width: "450px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showTrackOrderDialog = $event
            },
            handleClose: function ($event) {
              _vm.showTrackDialog = false
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("更新跟进进度")]
              ),
            ]
          ),
          _c("TrackDialog", { on: { closePage: _vm.loadData } }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showImageDialog,
            "close-on-click-modal": false,
            width: "450px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showImageDialog = $event
            },
            handleClose: function ($event) {
              _vm.showTrackDialog = false
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("影像资料")]
              ),
            ]
          ),
          _c("div", { staticClass: "app-m-cls", staticStyle: {} }, [
            _c(
              "div",
              {
                ref: "header",
                staticClass: "header-cls",
                staticStyle: { "max-height": "500px", overflow: "auto" },
              },
              [
                _c(
                  "el-card",
                  { attrs: { shadow: "never" } },
                  [
                    _c(
                      "el-upload",
                      {
                        staticClass: "upload-demo",
                        attrs: {
                          action: "https://jsonplaceholder.typicode.com/posts/",
                          "on-preview": _vm.handlePreview,
                          "on-remove": _vm.handleRemove,
                          "file-list": _vm.fileList,
                          "list-type": "picture",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          { attrs: { size: "small", type: "primary" } },
                          [_vm._v("添加文件")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showTrackDialog,
            "close-on-click-modal": false,
            width: "540px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showTrackDialog = $event
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("跟进状态详情")]
              ),
            ]
          ),
          _c("div", { staticClass: "app-m-cls", staticStyle: {} }, [
            _c(
              "div",
              {
                ref: "header",
                staticClass: "header-cls",
                staticStyle: { "max-height": "500px", "overflow-y": "scroll" },
              },
              [
                _c("el-card", { attrs: { shadow: "never" } }, [
                  _vm.trackDetailLists && _vm.trackDetailLists.length != 0
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            height: "300px",
                            "overflow-y": "scroll",
                          },
                        },
                        [
                          _c(
                            "el-timeline",
                            { attrs: { reverse: true } },
                            _vm._l(
                              _vm.trackDetailLists,
                              function (activity, index) {
                                return _c(
                                  "el-timeline-item",
                                  {
                                    key: index,
                                    attrs: { timestamp: activity.createdTime },
                                  },
                                  [_vm._v(" " + _vm._s(activity.content) + " ")]
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      )
                    : _c("div", { staticStyle: { height: "150px" } }, [
                        _vm._v(" 您还没有此工单的跟进记录~ "),
                      ]),
                ]),
              ],
              1
            ),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "justify-content": "center",
                  "margin-top": "8px",
                },
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: { icon: "el-icon-close", size: "mini" },
                    on: { click: _vm.loadData },
                  },
                  [_vm._v("关闭")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showLossDetialDialog,
            "close-on-click-modal": false,
            width: "540px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showLossDetialDialog = $event
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("定损详情")]
              ),
            ]
          ),
          _c("div", { staticClass: "app-m-cls", staticStyle: {} }, [
            _c(
              "div",
              {
                ref: "header",
                staticClass: "header-cls",
                staticStyle: { "max-height": "500px", overflow: "auto" },
              },
              [
                _c("el-card", { attrs: { shadow: "never" } }, [
                  _vm.fixedDetailVOlists && _vm.fixedDetailVOlists.length != 0
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            height: "300px",
                            "overflow-y": "scroll",
                          },
                        },
                        [
                          _c(
                            "el-timeline",
                            { attrs: { reverse: true } },
                            _vm._l(
                              _vm.fixedDetailVOlists,
                              function (activity, index) {
                                return _c(
                                  "el-timeline-item",
                                  {
                                    key: index,
                                    attrs: { timestamp: activity.fixedTime },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "timestamp" },
                                        slot: "timestamp",
                                      },
                                      [
                                        _vm._v(
                                          " 定损时间：" +
                                            _vm._s(activity.fixedTime) +
                                            " " +
                                            _vm._s(activity.creatorByName) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c("div", [
                                      _vm._v(
                                        "定损金额:" +
                                          _vm._s(activity.fixedAmount)
                                      ),
                                    ]),
                                    _c("div", [
                                      _vm._v(
                                        "定损项目:" + _vm._s(activity.fixedItem)
                                      ),
                                    ]),
                                  ]
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      )
                    : _c("div", { staticStyle: { height: "150px" } }, [
                        _vm._v(" 您还没有此工单的定损变更记录~ "),
                      ]),
                ]),
              ],
              1
            ),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "justify-content": "center",
                  "margin-top": "8px",
                },
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: { icon: "el-icon-close", size: "mini" },
                    on: { click: _vm.loadData },
                  },
                  [_vm._v("关闭")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showRepairDialog,
            "close-on-click-modal": false,
            width: "540px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showRepairDialog = $event
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("更新维修进度")]
              ),
            ]
          ),
          _c("RepairDialog", { on: { closePage: _vm.loadData } }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showCloseDialog,
            "close-on-click-modal": false,
            width: "540px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showCloseDialog = $event
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("关闭工单")]
              ),
            ]
          ),
          _c("CloseDialog", { on: { closePage: _vm.loadData } }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showLossDialog,
            "close-on-click-modal": false,
            width: "540px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showLossDialog = $event
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("车辆定损")]
              ),
            ]
          ),
          _c("LossDialog", { on: { closePage: _vm.loadData } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }