var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.formLoading,
          expression: "formLoading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: { "max-height": "550px" },
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "500px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "detail",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-width": "135px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "品牌型号/车型名称:",
                                prop: "modelName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "100",
                                  placeholder: "请输入品牌型号/车型名称",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.modelName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "modelName", $$v)
                                  },
                                  expression: "form.modelName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "精友车型编码:",
                                prop: "makerModelCode",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "100",
                                  placeholder: "请输入精友车型编码",
                                  clearable: "",
                                },
                                on: { input: _vm.inputMakerCode },
                                model: {
                                  value: _vm.form.makerModelCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "makerModelCode", $$v)
                                  },
                                  expression: "form.makerModelCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "行业车型编码:",
                                prop: "industryModelCode",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "100",
                                  placeholder: "请输入行业车型编码",
                                  clearable: "",
                                },
                                on: { input: _vm.inputModelCode },
                                model: {
                                  value: _vm.form.industryModelCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "industryModelCode", $$v)
                                  },
                                  expression: "form.industryModelCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "新车购置价:",
                                prop: "purchasePrice",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入新车购置价",
                                  clearable: "",
                                },
                                on: {
                                  input: function ($event) {
                                    _vm.form.purchasePrice = _vm.onInput(
                                      _vm.form.purchasePrice,
                                      0,
                                      999999999,
                                      2
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.form.purchasePrice,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "purchasePrice", $$v)
                                  },
                                  expression: "form.purchasePrice",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "上市年份:",
                                prop: "yearPattern",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入上市年份",
                                  clearable: "",
                                },
                                on: {
                                  input: function ($event) {
                                    _vm.form.yearPattern = _vm.onInput(
                                      _vm.form.yearPattern,
                                      0,
                                      999912,
                                      0
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.form.yearPattern,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "yearPattern", $$v)
                                  },
                                  expression: "form.yearPattern",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-check",
                type: "primary",
                plain: "",
                size: "mini",
              },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: { click: _vm.closePage },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }