import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import NonAutoRequest from '@/api/nonAutoRequest';
import Pagination from '@/components/Pagination';
export default {
  components: {
    Pagination: Pagination
  },
  data: function data() {
    return {
      loading: false,
      showViewImg: false,
      ChargebackList: [],
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 10
      },
      tabsList: [{
        name: "待审批",
        icon: "daishenpi",
        auditStatus: "IAS01",
        emptyMsg: "暂无待审批数据！",
        titleMsg: "待审批列表"
      }, {
        name: "已审批",
        icon: "yishenpi",
        auditStatus: "IAS02,IAS03",
        emptyMsg: "暂无已审批数据！",
        titleMsg: "已审批列表"
      }],
      currentTabIndex: 0,
      currentEmptyMsg: "暂无待审批数据！",
      vin: '',
      auditStatus: "IAS01",
      titleMsg: "待审批列表",
      timeId: null
    };
  },
  watch: {
    vin: function vin(val) {
      var _this = this;
      if (this.timeId) clearTimeout(this.timeId);
      this.timeId = setTimeout(function () {
        _this.listQuery.pageNum = 1;
        _this.getInsCorpAuditCancelList();
      }, 500);
    }
  },
  created: function created() {
    document.title = '注销审核列表';
    this.getInsCorpAuditCancelList();
  },
  mounted: function mounted() {
    var _this2 = this;
    this.$nextTick(function () {
      try {
        _this2.$refs["tabsRef"].style.height = _this2.$refs["tabsRef"].offsetHeight + "px";
        _this2.$refs["contentRef"].style.maxHeight = document.body.offsetHeight - _this2.$refs["headerRef"].offsetHeight - _this2.$refs["tabsRef"].offsetHeight + "px";
        _this2.$refs["contentRef"].style.overflow = "auto";
      } catch (err) {
        console.log(err);
      }
    });
  },
  methods: {
    //切换tab
    changeTab: function changeTab(item, index) {
      this.currentTabIndex = index;
      this.currentEmptyMsg = item.emptyMsg;
      this.auditStatus = item.auditStatus;
      this.titleMsg = item.titleMsg;
      this.total = 0;
      this.getInsCorpAuditCancelList();
    },
    // 获取列表
    getInsCorpAuditCancelList: function getInsCorpAuditCancelList() {
      var _this3 = this;
      this.loading = true;
      var data = _objectSpread({
        auditStatus: this.auditStatus
      }, this.listQuery);
      if (this.vin) {
        data.vin = this.vin;
      }
      ;
      this.ChargebackList = [];
      NonAutoRequest.insCorpAuditCancelList(data).then(function (res) {
        _this3.loading = false;
        if (res.code === 200 && res.data) {
          var resList = res.data.list ? res.data.list : [];
          _this3.ChargebackList = resList;
          _this3.total = res.data.page.recordsTotal;
        }
      });
    },
    // 去审核
    toDetail: function toDetail(row) {
      this.$router.push({
        name: 'verifyChargeback',
        query: {
          auditRecordId: row.auditRecordId ? row.auditRecordId : null
        }
      });
    }
  }
};