import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '../../mixins/initHeight';
import Dialog from '@/components/Dialog';
import Pagination from '@/components/Pagination';
import request from '../../api/doublerecord';
import Rules from '@/utils/rules';
import { getSupplyers, getCurrentOrganization, getUserOrgInfo } from '@/utils/common';
import requestOrg from '../../api/orgChildren';
export default {
  name: 'doubleRecord',
  components: {
    Pagination: Pagination,
    Dialog: Dialog
  },
  mixins: [initHeight],
  computed: {},
  data: function data() {
    return {
      drawer: false,
      searchForm: {},
      formLoading: false,
      rules: {
        orgCode: [{
          required: true,
          message: '请选择所属机构',
          trigger: 'change'
        }],
        salerIdNo: [{
          required: true,
          message: '请输入身份证号',
          trigger: 'blur'
        }, {
          pattern: Rules['ID_CARD'].value,
          message: '身份证号格式有误',
          trigger: ['blur', 'change']
        }],
        salerMobile: [{
          required: true,
          message: '请输入手机号',
          trigger: 'blur'
        }, {
          pattern: Rules['MOBILE_RULE'].value,
          message: '手机号格式有误',
          trigger: ['blur', 'change']
        }],
        salerName: [{
          required: true,
          message: '请输入姓名',
          trigger: 'blur'
        }, {
          min: 2,
          max: 20,
          pattern: Rules["NAME"].value,
          message: "名称格式有误",
          trigger: ["blur", "change"]
        }],
        supportInsCorpCodes: [{
          required: true,
          message: '请选择保险公司',
          trigger: 'change'
        }]
      },
      form: {
        orgName: '',
        orgCode: '',
        supportInsCorpCodes: []
      },
      showDialog: false,
      list: null,
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      multipleSelection: [],
      opType: '',
      orgData: [],
      defaultProps: {
        children: 'children',
        label: 'fullName'
      },
      highlightCurrent: true,
      defaultSelectOrg: {},
      isClick: false,
      supplyers: [],
      loading: false,
      disableTreeSelect: false,
      userOrgInfo: {}
    };
  },
  created: function created() {
    this.supplyers = getSupplyers();
    var _orgInfo = getUserOrgInfo();
    if (_orgInfo && _orgInfo.organizationId) {
      this.form.orgCode = _orgInfo.orgCode;
      this.form.orgName = _orgInfo.fullName;
    }
    this.loadData();
    this.initOrgData();
  },
  methods: {
    getInsOrgName: function getInsOrgName(items) {
      //获取保险公司中文名称
      var codes = items.split('|');
      var insName = _.filter(this.supplyers, function (o) {
        return _.find(codes, function (item) {
          return _.toLower(item) == _.toLower(o.code);
        });
      });
      var inCorpName = [];
      if (!_.isEmpty(insName)) insName.forEach(function (item) {
        return inCorpName.push(item.shortName);
      });
      return !_.isEmpty(inCorpName) ? inCorpName.toString() : "";
    },
    initOrgData: function initOrgData() {
      var _this = this;
      //初始化组织机构树
      requestOrg.getTree(0).then(function (res) {
        if (res.data) {
          // this.orgData = res.data;
          // this.defaultSelectOrg = res.data[0];
          var _orgData = getCurrentOrganization([res.data]);
          _this.orgData = [_orgData];
          _this.defaultSelectOrg = _orgData;
        }
      });
    },
    orgChange: function orgChange(val) {
      //监听组织机构树选择事件
      this.searchForm.orgName = null;
      this.searchForm.orgCode = null;
    },
    handleNodeClick: function handleNodeClick(data, node, target) {
      // 监听机构树点击事件
      this.orgChange();
      this.searchForm.orgCode = data.code;
      this.searchForm.orgName = data.label;
      this.$refs.orgSelect.blur();
      this.$forceUpdate();
    },
    orgChanges: function orgChanges(val) {
      // 监听弹框内的组织机构树选择事件
      this.form.orgName = null;
      this.form.orgCode = null;
    },
    handleNodeClicks: function handleNodeClicks(data, node, target) {
      // 监听弹框内的组织机构树点击事件
      this.orgChanges();
      this.form.orgCode = data.orgCode;
      this.form.orgName = data.fullName;
      this.$refs.orgSelects.blur();
      this.$forceUpdate();
    },
    filterNode: function filterNode(value, data) {
      //机构树查询
      if (!value) return true;
      return data.fullName.indexOf(value) !== -1;
    },
    dataFilter: function dataFilter(val) {
      //机构树查询
      this.$refs.tree.filter(val);
    },
    dataFilters: function dataFilters(val) {
      //机构树查询
      this.$refs.trees.filter(val);
    },
    orgFocus: function orgFocus(val) {
      // 监听弹框内的组织机构树输入框焦点
      if (this.$refs.orgSelect && (this.$refs.orgSelect.query || this.$refs.orgSelect.query == '' || this.$refs.orgSelect.query == undefined)) {
        this.$refs.tree.filter('');
        this.$forceUpdate();
      }
    },
    orgFocuss: function orgFocuss(val) {
      // 监听弹框内的组织机构树输入框焦点
      if (this.$refs.orgSelects && (this.$refs.orgSelects.query || this.$refs.orgSelects.query == '')) {
        this.$refs.trees.filter('');
        this.$forceUpdate();
      }
    },
    addItem: function addItem() {
      // 新增
      if (!this.disableTreeSelect) {
        this.form = {
          orgName: '',
          orgCode: '',
          supportInsCorpCodes: []
        };
      }
      this.opType = '';
      this.showDialog = true;
    },
    resetSearch: function resetSearch() {
      // 重置搜索条件
      this.searchForm = {};
    },
    handleRowChange: function handleRowChange(row, event, column) {
      // 列表行单选事件
      this.$refs.multipleTable.toggleRowSelection(row); // 点击选中
    },
    loadData: function loadData() {
      var _this2 = this;
      // 获取列表数据
      this.loading = true;
      this.drawer = false;
      request.getList(this.listQuery.pageNum, this.listQuery.pageSize, this.searchForm).then(function (res) {
        _this2.loading = false;
        if (!_.isEmpty(res.data)) {
          _this2.tableData = res.data.list;
          //总条数
          _this2.total = res.data.page.recordsTotal;
        }
      }).catch(function (err) {
        _this2.loading = false;
      });
    },
    submit: function submit() {
      var _this3 = this;
      // 验证表单
      this.$refs['form'].validate(function (valid) {
        if (valid) {
          _this3.isClick = true;
          _this3.formLoading = true;
          var localRequest = _this3.opType == 'update' ? request.update(_this3.form.orgDoubleInputSalerId, _this3.form) : request.create(_this3.form);
          localRequest.then(function (res) {
            _this3.isClick = false;
            _this3.formLoading = false;
            if (res.code == RESPONSE_SUCCESS) {
              _this3.$message({
                type: 'success',
                message: res.msg
              });
              if (!_this3.disableTreeSelect) {
                _this3.form = {
                  orgName: '',
                  orgCode: '',
                  supportInsCorpCodes: []
                };
              }
              _this3.$refs['form'].resetFields();
              _this3.showDialog = false;
              _this3.loadData();
            }
          }).catch(function (err) {
            _this3.formLoading = false;
            _this3.isClick = false;
          });
        }
      });
    },
    removeItems: function removeItems(item) {
      var _this4 = this;
      //删除列表数据
      var ids = [];
      if (!_.isEmpty(item)) {
        ids.push(item.orgDoubleInputSalerId);
      } else {
        if (!_.isEmpty(this.multipleSelection)) {
          ids = _.map(this.multipleSelection, 'orgDoubleInputSalerId');
        }
      }
      if (_.isEmpty(ids)) return;
      this.$confirm("\u662F\u5426\u786E\u8BA4\u5220\u9664".concat(!_.isEmpty(item) ? '该双录人' : '所选中的双录人', ", \u786E\u8BA4\u5220\u9664\u540E\u4E0D\u80FD\u6062\u590D\u3002"), '温馨提示', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        type: 'warning'
      }).then(function () {
        request.delete(ids).then(function (res) {
          if (res.code == RESPONSE_SUCCESS) {
            _this4.$message({
              type: 'success',
              message: res.msg
            });
            _this4.loadData();
          }
        });
      });
    },
    viewDetail: function viewDetail(type, item) {
      var _this5 = this;
      // 查看详情
      this.opType = type;
      this.form.orgCode = item.orgCode;
      this.form.orgName = item.orgName;
      request.getDetail(item.orgDoubleInputSalerId).then(function (res) {
        if (res.code == RESPONSE_SUCCESS) {
          _this5.form = res.data;
          _this5.showDialog = true;
        }
      }).catch(function (err) {});
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      // 列表行单选事件
      this.multipleSelection = val;
    },
    cancel: function cancel() {
      // 关闭弹窗
      if (!this.disableTreeSelect) {
        this.form = {
          orgName: '',
          orgCode: '',
          supportInsCorpCodes: []
        };
      }
      this.$refs['form'].resetFields();
      this.showDialog = false;
    }
  }
};