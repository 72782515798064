import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '../../mixins/initHeight';
import Pagination from '@/components/Pagination';
import UploadBillDialog from './dialog/uploadBillDialog';
import OemSelect from '@/components/OemSelect';
import request from '../../api/finance';
import dictRequest from '@/api/dictionary';
import { getSupplyers, getUserOrgInfoDealerOem, commonExport } from '@/utils/common';
export default {
  name: "brokerageManagement",
  components: {
    Pagination: Pagination,
    UploadBillDialog: UploadBillDialog,
    OemSelect: OemSelect
  },
  mixins: [initHeight],
  data: function data() {
    return {
      drawer: false,
      searchForm: {},
      form: {
        configValue: ''
      },
      total: 0,
      orgData: {
        salesOrgCode: '',
        salesOrgName: '',
        organizationId: ''
      },
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      defaultProps: {
        children: 'children',
        label: 'fullName'
      },
      tableData: [],
      multipleSelection: [],
      loading: false,
      showThresholdDialog: false,
      showUploadBillDialog: false,
      reportStatus: [],
      settleAccountsStatus: [],
      supplyers: [],
      billingList: [],
      billing: false,
      thresholdLoading: false,
      showReconciliation: false,
      curReconciliationData: {},
      bizType: localStorage.getItem("bizType") || ''
    };
  },
  created: function created() {},
  mounted: function mounted() {
    var _this = this;
    if (this.bizType === "DEALER") {
      this.initData();
    }
    ;
    //保司
    this.supplyers = getSupplyers();
    //获取结算状态
    dictRequest.getDictItemsByCode("SETTLE_ACCOUNT_SSTATUS").then(function (res) {
      if (res.data) {
        _this.settleAccountsStatus = res.data;
      }
    });
    //获取上报状态
    dictRequest.getDictItemsByCode("REPORT_STATUS").then(function (res) {
      if (res.data) {
        _this.reportStatus = res.data;
      }
    });
    this.loadData();
  },
  methods: {
    initData: function initData() {
      var orgObj = getUserOrgInfoDealerOem(this.bizType);
      this.searchForm.salesOrgCode = orgObj.salesOrgCode;
      this.searchForm.salesOrgName = orgObj.salesOrgName;
    },
    // 获取阈值
    getThreshold: function getThreshold() {
      var _this2 = this;
      request.viewThreshold().then(function (res) {
        _this2.form.configValue = res.data.configValue;
      });
    },
    // 保存阈值
    saveThreshold: function saveThreshold() {
      var _this3 = this;
      this.thresholdLoading = true;
      request.updateThreshold(this.form).then(function (res) {
        _this3.thresholdLoading = false;
        if (res.code === 200) {
          _this3.$message.success(res.msg);
          _this3.loadData();
        }
        ;
      }).catch(function (err) {
        _this3.thresholdLoading = false;
      });
      ;
    },
    // 导出列表
    exportFn: function exportFn() {
      var exporParams = {
        total: this.total,
        limitNum: 200000,
        fileName: '对账/结账列表',
        exportUrl: '/vi-core-service/financereconciliation/search',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    // 导出明细
    exportDetails: function exportDetails() {
      var searchForm = {
        pageNum: 1,
        pageSize: 0,
        reconciliationIds: _.map(this.multipleSelection, 'reconciliationId')
      };
      var exportData = [{
        property: "reconciliationBatchNo",
        label: "批次"
      }, {
        property: "insCorpName",
        label: "保险公司"
      }, {
        property: "orgCode",
        label: "机构编码"
      }, {
        property: "orgName",
        label: "机构名称"
      }, {
        property: "riskTypeName",
        label: "保单类型"
      }, {
        property: "policyNo",
        label: "保单号"
      }, {
        property: "plateNo",
        label: "车牌号"
      }, {
        property: "insurantName",
        label: "被保人"
      }, {
        property: "invoicePremium",
        label: "保司保费(元,含税)"
      }, {
        property: "invoicePremiumWithoutTax",
        label: "保司保费(元,不含税)"
      }, {
        property: "invoicePoundageProportion",
        label: "保司手续费率(%)"
      }, {
        property: "invoicePoundageAmount",
        label: "保司手续费(元,含税)"
      }, {
        property: "invoicePoundageAmountWithoutTax",
        label: "保司手续费(元,不含税)"
      }, {
        property: "platformPremium",
        label: "平台保费(元,含税)"
      }, {
        property: "platformPremiumWithoutTax",
        label: "平台保费(元,不含税)"
      }, {
        property: "platformPoundageProportion",
        label: "平台手续费率(%)"
      }, {
        property: "platformPoundageAmount",
        label: "平台手续费(元,含税)"
      }, {
        property: "platformPoundageAmountWithoutTax",
        label: "平台手续费(元,不含税)"
      }, {
        property: "amountDiffProportion",
        label: "手续费率差异"
      }, {
        property: "amountDiffAmount",
        label: "手续费差异(元,含税)"
      }, {
        property: "amountDiffAmountWithoutTax",
        label: "手续费差异(元,不含税)"
      }, {
        property: "insuredTime",
        label: "承保日期"
      }, {
        property: "remark",
        label: "备注"
      }];
      var exporParams = {
        total: 99999,
        limitNum: 200000,
        fileName: '对账/结账明细',
        exportUrl: '/vi-core-service/financereconciliationdetails/search',
        searchForm: searchForm,
        exportData: exportData
      };
      commonExport(exporParams);
    },
    // 点击选择
    choose: function choose() {
      var _this4 = this;
      this.billing = true;
      request.billingListSelection(this.listQuery).then(function (res) {
        _this4.billingList = res.data;
      });
    },
    reconciliation: function reconciliation(item) {
      //对账
      this.showReconciliation = true;
      this.curReconciliationData = _.cloneDeep(item);
    },
    saveReconciliation: function saveReconciliation() {
      var _this5 = this;
      //对账保存
      var data = {
        reconciliationIds: [this.curReconciliationData.reconciliationId],
        remark: this.curReconciliationData.remark
      };
      request.markReconciliation(data).then(function (res) {
        if (res.code == 200) {
          _this5.$message.success(res.msg);
          _this5.showReconciliation = false;
          _this5.loadData();
        }
      });
    },
    rejectReport: function rejectReport() {
      var _this6 = this;
      // 驳回上报
      var data = {
        reconciliationIds: [this.curReconciliationData.reconciliationId],
        remark: this.curReconciliationData.remark
      };
      request.dereport(data).then(function (res) {
        if (res.code == 200) {
          _this6.$message.success(res.msg);
          _this6.loadData();
        }
      });
    },
    taskOpen: function taskOpen(item) {
      var _this7 = this;
      //对账
      request.taskOpen(item.repertoireId).then(function (res) {
        if (res.code === 200) {
          _this7.$message({
            message: res.msg,
            type: 'success'
          });
          _this7.loadData();
        }
        ;
      }).catch(function (err) {});
    },
    nodeClick: function nodeClick(val) {
      this.searchForm.orgCode = val.salesOrgCode;
    },
    viewDetail: function viewDetail(item) {
      this.$router.push({
        path: "/brokerage/detail",
        query: {
          reconciliationId: item.reconciliationId,
          reportStatus: item.reportStatus
        }
      });
    },
    batchDelete: function batchDelete(item) {
      var _this8 = this;
      //删除
      var ids = [];
      if (!_.isEmpty(item)) {
        ids.push(item.reconciliationId);
      } else {
        if (!_.isEmpty(this.multipleSelection)) {
          ids = _.map(this.multipleSelection, 'reconciliationId');
        }
        ;
      }
      ;
      this.$confirm('您确定要删除么？', {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        request.deleteReconciliation(ids).then(function (res) {
          if (res.code === 200) {
            _this8.$message.success(res.msg);
            _this8.loadData();
          }
          ;
        });
      });
    },
    uploadBill: function uploadBill() {
      //上传账单
      this.showUploadBillDialog = true;
    },
    setThreshold: function setThreshold() {
      //设置差阀值
      this.getThreshold();
      this.showThresholdDialog = true;
    },
    deleteBill: function deleteBill() {
      //删除账单
      if (this.multipleSelection.length === 0) {
        this.$message({
          message: "请选择要删除的账单",
          type: "error"
        });
      }
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {};
      this.orgData = {};
      this.listQuery.pageNum = 1;
      if (this.bizType === "DEALER") {
        this.initData();
      }
      ;
    },
    // 刷新
    breakData: function breakData() {
      // this.resetSearch()
      this.loadData();
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    loadData: function loadData() {
      var _this9 = this;
      //获取列表数据
      this.loading = true;
      this.drawer = false;
      this.billing = false;
      this.showReconciliation = false;
      this.showThresholdDialog = false;
      this.showUploadBillDialog = false;
      var postData = {};
      postData = Object.assign(postData, this.searchForm, this.listQuery);
      request.getListReconciliation(postData).then(function (res) {
        _this9.loading = false;
        _this9.tableData = res.data.list;
        //总条数
        _this9.total = res.data.page.recordsTotal;
      }).catch(function (err) {
        _this9.loading = false;
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      //列表行选择事件
      this.multipleSelection = val;
    },
    report: function report(item) {
      var _this10 = this;
      var params = {
        reconciliationIds: [item.reconciliationId]
      };
      this.$confirm('上报后无法修改和重新对账。您确定上报吗？', {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        request.report(params).then(function (res) {
          if (res.code === 200) {
            _this10.$message.success(res.msg);
            _this10.loadData();
          }
          ;
        });
      });
    }
  }
};