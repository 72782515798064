import _defineProperty from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/defineProperty.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '../../mixins/initHeight';
import dict from '../../mixins/dict';
import OrgSelect from '@/components/OrgSelect';
import statisticsRequest from '@/api/statistics';
import dayjs from 'dayjs';
import { commonExport } from '@/utils/common';
import { getCurrentDate, getAfterDate, getLastMonth } from '@/utils/common';
export default {
  name: 'SellerInsureReport',
  mixins: [initHeight, dict],
  components: {
    OrgSelect: OrgSelect
  },
  computed: {},
  data: function data() {
    var _ref;
    return _ref = {
      activeName: "PERSON",
      drawer: false,
      searchForm: {},
      form: {},
      tableData: [],
      multipleSelection: []
    }, _defineProperty(_ref, "searchForm", {
      timeSelect: 'lastMonth',
      category: 'SellerInsured',
      startDate: getAfterDate(-29),
      endDate: getCurrentDate(),
      orgCode: '',
      orgName: ''
    }), _defineProperty(_ref, "isDataTime", true), _defineProperty(_ref, "startEndTime", [getAfterDate(-29), getCurrentDate()]), _defineProperty(_ref, "customTime", ''), _defineProperty(_ref, "loading", false), _ref;
  },
  created: function created() {},
  mounted: function mounted() {
    this.customTime = this.startEndTime;
    this.loadData();
  },
  watch: {
    customTime: function customTime() {
      this.searchForm.startDate = this.customTime[0] + ' ' + '00:00:00';
      this.searchForm.endDate = this.customTime[1] + ' ' + '23:59:59';
    }
  },
  methods: {
    //导出
    exportFn: function exportFn() {
      var exporParams = {
        total: this.total,
        fileName: '经营业绩表',
        exportUrl: '/vi-core-service/statistics/operatingPerformance',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    dateChange: function dateChange(start, end, val) {
      //日期选择事件
      if (val && val.length > 1) {
        this.searchForm[start] = val[0];
        this.searchForm[end] = val[1];
        this.loadData();
      } else {
        this.searchForm[start] = null;
        this.searchForm[end] = null;
      }
    },
    handleTime: function handleTime(val) {
      switch (val) {
        case "now":
          this.startEndTime = [getCurrentDate(), getCurrentDate()];
          this.isDataTime = true;
          break;
        case "yesterday":
          this.startEndTime = [getAfterDate(-1), getAfterDate(-1)];
          this.isDataTime = true;
          break;
        case "sevenDays":
          this.startEndTime = [getAfterDate(-6), getCurrentDate()];
          this.isDataTime = true;
          break;
        case "thirtyDays":
          this.startEndTime = [getAfterDate(-29), getCurrentDate()];
          this.isDataTime = true;
          break;
        case "lastMonth":
          this.startEndTime = getLastMonth();
          this.isDataTime = true;
          break;
        case "currentMonth":
          var startDate = dayjs().startOf('M').format("YYYY-MM-DD");
          var endDate = dayjs().endOf('M').format("YYYY-MM-DD");
          this.startEndTime = [startDate, endDate];
          this.isDataTime = true;
          break;
        case "custom":
          this.isDataTime = false;
          this.$refs.input.focus();
          break;
      }
      this.customTime = this.startEndTime;
      this.searchForm.startDate = this.startEndTime[0];
      this.searchForm.endDate = this.startEndTime[1];
      this.loadData();
    },
    handleNodeClick: function handleNodeClick(val) {
      this.searchForm.orgCode = val.salesOrgCode;
      this.searchForm.orgName = val.salesOrgName;
      this.loadData();
    },
    searchData: function searchData() {
      //搜索
      this.loadData(true);
    },
    loadData: function loadData() {
      var _this = this;
      //获取列表数据
      this.loading = true;
      this.drawer = false;
      var postData = {
        startDate: this.customTime[0] + ' ' + '00:00:00',
        endDate: this.customTime[1] + ' ' + '23:59:59',
        orgCode: this.searchForm.orgCode,
        orgName: this.searchForm.orgName
      };
      this.loading = true;
      statisticsRequest.getOperatingPerformance(postData).then(function (res) {
        _this.loading = false;
        _this.tableData = [];
        if (res.data) {
          _this.tableData = res.data;
          _this.$nextTick(function () {
            _this.$refs['multipleTable'].doLayout();
          });
        }
      }).catch(function (err) {
        _this.loading = false;
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      //列表行选择事件
      this.multipleSelection = val;
    }
  }
};