import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import _createForOfIteratorHelper from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import _defineProperty from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import Pagination from '@/components/Pagination';
import DatePicker from '@/components/DatePicker';
import NonAutoRequest from '@/api/nonAutoRequest';
import ChargebackDialog from './dialog/chargebackDialog';
import AuditDialog from './dialog/popupWindow';
import { commonExport, getLastMonthDays, getCurrentDate, getBeforeDate } from '@/utils/common';
import customers from '@/api/customers';
import OemSelect from '@/components/OemSelect';
export default {
  name: 'chargeBackList',
  mixins: [initHeight],
  components: {
    Pagination: Pagination,
    ChargebackDialog: ChargebackDialog,
    AuditDialog: AuditDialog,
    OemSelect: OemSelect,
    DatePicker: DatePicker
  },
  data: function data() {
    var _ref;
    return _ref = {
      TzdLoading: false,
      TzdRuleForm: {
        redInvoiceNo: null
      },
      TzdRules: {
        redInvoiceNo: [{
          required: true,
          message: '请填写红色通知单，红色通知单请邮寄至厂家，谢谢！',
          trigger: 'blur'
        }]
      },
      createdDateTime: [getLastMonthDays(), getCurrentDate()],
      policySaleTime: [],
      modifiedDateTime: [],
      bizType: localStorage.getItem("bizType") || '',
      drawer: false,
      loading: false,
      showAddDialog: false,
      showTzdDialog: false,
      showAuditDialog: false
    }, _defineProperty(_ref, "drawer", false), _defineProperty(_ref, "searchForm", {}), _defineProperty(_ref, "tableData", []), _defineProperty(_ref, "total", 0), _defineProperty(_ref, "listQuery", {
      pageNum: 1,
      pageSize: 20
    }), _defineProperty(_ref, "orgForm", {
      salesOrgCode: '',
      salesOrgName: ''
    }), _defineProperty(_ref, "currentId", ''), _defineProperty(_ref, "policyCancelId", ''), _defineProperty(_ref, "cancelStatusList", [{
      value: 'PTS01',
      label: '待注销'
    }, {
      value: 'PTS02',
      label: '人工审核中'
    }, {
      value: 'PTS05',
      label: '上传红色通知单'
    }, {
      value: 'PTS03',
      label: '已注销'
    }, {
      value: 'PTS04',
      label: '注销失败'
    }]), _defineProperty(_ref, "activeName", ''), _defineProperty(_ref, "tabsList", []), _defineProperty(_ref, "salesman", []), _defineProperty(_ref, "suppliersLists", []), _defineProperty(_ref, "rowCancelNo", null), _defineProperty(_ref, "timeout1", null), _defineProperty(_ref, "timeoutForExport", null), _defineProperty(_ref, "timeoutForRefresh", null), _ref;
  },
  created: function created() {
    var _this = this;
    this.getInsuranceProductType();
    if (localStorage.getItem("bizType") != 'OEM') {
      var data = JSON.parse(localStorage.getItem('userInfo')).data;
      this.searchForm.orgCode = data && data.organization.dealerCode ? data.organization.dealerCode : data.organization.orgCode;
      this.searchForm.salesOrgName = data && data.organization.dealerName ? data.organization.dealerName : data.organization.orgName;
    }
    if (localStorage.getItem('userInfo')) {
      var userInfo = JSON.parse(localStorage.getItem('userInfo'));
      if (userInfo.bizType == 'DEALER') {
        customers.getDealerCustomerList(userInfo.data.organization.dealerCode).then(function (res) {
          if (res.code == 200 && res.data) {
            _this.salesman = res.data ? res.data : [];
          }
        });
      }
    }
  },
  watch: {
    $route: function $route(to, from) {
      if (to) {
        this.drawer = false;
        this.showAddDialog = false;
        this.showAuditDialog = false;
        sessionStorage.setItem('chargeBackActiveName', this.activeName);
      }
    }
  },
  methods: {
    cancelTzd: function cancelTzd() {
      this.TzdRuleForm.redInvoiceNo = null;
      this.showTzdDialog = false;
    },
    SendTzd: function SendTzd() {
      var _this2 = this;
      //防抖
      if (this.timeout1) {
        clearTimeout(this.timeout1);
      }
      this.timeout1 = setTimeout(function () {
        if (_this2.TzdRuleForm.redInvoiceNo) {
          _this2.TzdLoading = true;
        } else {
          _this2.$message({
            type: 'warning',
            message: '请填写红色通知单号！'
          });
          return;
        }
        _this2.SendTzd1();
      }, 800);
    },
    SendTzd1: function SendTzd1() {
      var _this3 = this;
      var data = {
        cancelNo: this.rowCancelNo ? this.rowCancelNo : null,
        redInvoiceNo: this.TzdRuleForm.redInvoiceNo ? this.TzdRuleForm.redInvoiceNo : null
      };
      NonAutoRequest.cancelinputRedInvoice(data).then(function (res) {
        if (res.code === 200) {
          _this3.$message({
            type: 'success',
            message: res.msg
          });
        }
        setTimeout(function () {
          _this3.TzdLoading = false;
          _this3.showTzdDialog = false;
        }, 1000);
      });
    },
    clearChange: function clearChange(val) {
      this.searchForm[val] = null;
    },
    // 获取供应商
    getSupplierLists: function getSupplierLists() {
      var _this4 = this;
      NonAutoRequest.getSuppliersList(this.activeName).then(function (res) {
        if (res.code == 200) {
          _this4.suppliersLists = res.data ? res.data : [];
        }
      });
    },
    handleNodeClick: function handleNodeClick(data, node, target) {
      // 机构树点击事件
      this.salesman = [];
      this.searchForm.orgCode = data.salesOrgCode;
      // this.searchForm.salesOrgName = data.salesOrgName;
      this.searchForm.areaType = data.salesOrgType;
    },
    oemGetUsers: function oemGetUsers(val) {
      this.searchForm.salemanName = null;
      this.salesman = val;
    },
    salemanNameChange: function salemanNameChange(val) {
      //监听选择业务员事件
      if (val) {
        var _user = _.find(this.salesman, function (item) {
          return item.userId == val;
        });
        if (_user) {
          this.searchForm.creatorBy = _user.userId;
          this.searchForm.creatorName = _user.userName;
          this.$forceUpdate();
        }
      }
    },
    getPickersDate: function getPickersDate(name, val) {
      if (val && val.length > 1) {
        this[name] = val;
      } else {
        this[name] = [];
      }
    },
    dateChange: function dateChange(start, end, val) {
      //日期更改监听事件
      if (val && val.length > 1) {
        this.searchForm[start] = val[0] + ' 00:00:00';
        this.searchForm[end] = val[1] + ' 23:59:59';
      } else {
        this.searchForm[start] = null;
        this.searchForm[end] = null;
      }
    },
    handleTabClick: function handleTabClick() {
      this.listQuery.pageNum = 1;
      this.searchForm.classifyCode = this.activeName;
      sessionStorage.setItem('chargeBackActiveName', this.activeName);
      this.loadData();
      this.getSupplierLists();
    },
    getInsuranceProductType: function getInsuranceProductType() {
      var _this5 = this;
      //获取服务类型列表
      NonAutoRequest.getClassTab().then(function (res) {
        _this5.loading = false;
        if (res.code == 200) {
          var resData = res.data ? res.data : [];
          var reslist = [];
          var _iterator = _createForOfIteratorHelper(resData),
            _step;
          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var i = _step.value;
              if (i.allowCancel) {
                reslist.push(i);
              }
            }
            // console.log(reslist)
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }
          if (sessionStorage.getItem('chargeBackActiveName')) {
            var chargeBackActiveName = sessionStorage.getItem('chargeBackActiveName');
            _this5.activeName = chargeBackActiveName;
          } else {
            if (reslist[0].classCode) {
              _this5.activeName = reslist[0].classCode;
            } else {
              _this5.activeName = '';
            }
          }
          _this5.searchForm.classifyCode = _this5.activeName;
          _this5.tabsList = reslist;
          _this5.loadData();
          _this5.getSupplierLists();
        }
      });
    },
    loadData: function loadData() {
      var _this6 = this;
      //获取产品管理列表
      this.loading = true;
      this.drawer = false;
      this.showAddDialog = false;
      this.showAuditDialog = false;
      if (this.createdDateTime) {
        this.searchForm.createStartDate = this.createdDateTime[0] ? this.createdDateTime[0].split(' ')[0] + ' 00:00:00' : null;
        this.searchForm.createEndDate = this.createdDateTime[1] ? this.createdDateTime[1].split(' ')[0] + ' 23:59:59' : null;
      }
      if (this.modifiedDateTime) {
        this.searchForm.modifiedStartTime = this.modifiedDateTime[0] && this.modifiedDateTime[0].split(' ')[0] + ' 00:00:00' || null;
        this.searchForm.modifiedEndTime = this.modifiedDateTime[1] && this.modifiedDateTime[1].split(' ')[0] + ' 23:59:59' || null;
      }
      if (this.policySaleTime) {
        this.searchForm.policyStartSaleTime = this.policySaleTime[0] ? this.policySaleTime[0].split(' ')[0] + ' 00:00:00' : null;
        this.searchForm.policyEndSaleTime = this.policySaleTime[1] ? this.policySaleTime[1].split(' ')[0] + ' 23:59:59' : null;
      }
      var data = _objectSpread(_objectSpread({}, this.listQuery), this.searchForm);
      NonAutoRequest.getNonAutoCancelList(data).then(function (res) {
        _this6.loading = false;
        if (res.code == 200) {
          _this6.tableData = res.data && res.data.list ? res.data.list : [];
          _this6.total = res.data && res.data.page && res.data.page.recordsTotal ? res.data.page.recordsTotal : 0;
        } else {
          _this6.tableData = [];
          _this6.total = 0;
        }
        ;
      }).catch(function (err) {
        _this6.loading = false;
      });
    },
    checkClicked: function checkClicked(row) {
      console.log(row.policyCancelId);
      this.showAuditDialog = true;
      this.policyCancelId = row.policyCancelId.toString();
    },
    //录入红色通知单
    EnterNoticeClicked: function EnterNoticeClicked(row) {
      console.log(row);
      this.rowCancelNo = row.cancelNo ? row.cancelNo : null;
      this.showTzdDialog = true;
    },
    // 导出防抖
    exportFn: function exportFn() {
      var _this7 = this;
      if (this.timeoutForExport) {
        clearTimeout(this.timeoutForExport);
      }
      this.timeoutForExport = setTimeout(function () {
        _this7.exportFn1();
      }, 500);
    },
    // 导出
    exportFn1: function exportFn1() {
      var exporParams = {
        total: this.total,
        limitNum: 200000,
        fileName: '非车险注销管理列表',
        exportUrl: '/biz-nonauto-service/nonauto/cancel/search',
        searchForm: this.searchForm
      };
      if (this.activeName && this.activeName === 'DBC') {
        commonExport(exporParams, this.$refs.multipleTable);
      } else {
        commonExport(exporParams, this.$refs.NoDBCmultipleTable);
      }
    },
    // 刷新防抖
    refreshClicked: function refreshClicked() {
      var _this8 = this;
      if (this.timeoutForRefresh) {
        clearTimeout(this.timeoutForRefresh);
      }
      this.timeoutForRefresh = setTimeout(function () {
        _this8.refreshClicked1();
      }, 500);
    },
    // 刷新
    refreshClicked1: function refreshClicked1() {
      this.loadData();
    },
    // 重置
    resetSearch: function resetSearch() {
      this.createdDateTime = [getLastMonthDays(), getCurrentDate()];
      if (this.bizType === 'OEM') {
        this.searchForm = {};
        this.salesman = [];
        this.orgForm = {
          salesOrgName: this.bizType === 'OEM' ? '全部' : '',
          salesOrgCode: ''
        };
      } else {
        this.searchForm = {};
        var data = JSON.parse(localStorage.getItem('userInfo')).data;
        this.searchForm.orgCode = data && data.organization.dealerCode ? data.organization.dealerCode : data.organization.orgCode;
        this.searchForm.salesOrgName = data && data.organization.dealerName ? data.organization.dealerName : data.organization.orgName;
      }
      this.searchForm.classifyCode = this.activeName;
      this.modifiedDateTime = [];
      this.policySaleTime = [];
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    add: function add() {
      //新增
      this.showAddDialog = true;
    },
    // 查看
    view: function view(row) {
      this.$router.replace({
        path: "/chargebackDetail",
        query: {
          policyCancelId: row.policyCancelId,
          isView: true,
          riskType: 2,
          // vin: row.orderNo,
          tabName: row.productClassName + '注销详情' + row.cancelNo
        }
      });
    },
    batchDelete: function batchDelete(item) {
      var _this9 = this;
      //删除
      this.$confirm('您确定要删除该数据吗？', {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        NonAutoRequest.deleteNonAutoCancelPolicy(item.policyCancelId).then(function (res) {
          if (res.code === 200) {
            _this9.$message.success(res.msg);
            _this9.loadData();
          }
          ;
        });
      }).catch(function (err) {});
    }
  }
};