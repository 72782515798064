import "core-js/modules/es.array.concat.js";
/**
 * 保险服务方案配置接口
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 列表
   * @param {*} id 查询参数
   */
  getInsuranceschemeList: function getInsuranceschemeList(data) {
    return request.get(getUrl("/insuranceScheme/search", 11), {
      params: data
    });
  },
  /**
   * 新增方案
   * @param {*} id 查询参数
   */
  createInsurancescheme: function createInsurancescheme(data) {
    return request.post(getUrl("/insuranceScheme/add", 11), data);
  },
  /**
   * 方案详情
   * @param {*} id 查询参数
   */
  getDetail: function getDetail(id) {
    return request.get(getUrl("/insuranceScheme/detail/".concat(id), 11));
  },
  /**
   * 编辑方案
   * @param {*} id 查询参数
   */
  updateDetail: function updateDetail(data) {
    return request.post(getUrl("/insuranceScheme/update", 11), data);
  },
  /**
  * 批量删除方案
  * @param {*} id 查询参数
  */
  delDetail: function delDetail(ids) {
    return request.post(getUrl("/insuranceScheme/delete/".concat(ids), 11));
  },
  //////////////////////////////////////////===========费用列表=========////////////////////////////////////////////////////////////////////////
  /**
   * 分页查询保险服务方案费用列表
   * @param {*} id 查询参数
   */
  searchDetail: function searchDetail(data) {
    return request.get(getUrl("/insuranceService/search", 11), {
      params: data
    });
  },
  /**
   * 新增险种费率
   * @param {*} id 查询参数
   */
  createInsuranceservice: function createInsuranceservice(data) {
    return request.post(getUrl("/insuranceService/add", 11), data);
  },
  /**
   * 查看险种费率
   * @param {*} id 查询参数
   */
  getInsuranceserviceDetail: function getInsuranceserviceDetail(ids) {
    return request.get(getUrl("/insuranceService/detail/".concat(ids), 11));
  },
  /**
   * 修改险种费率
   * @param {*} id 查询参数
   */
  updateInsuranceservice: function updateInsuranceservice(data) {
    return request.post(getUrl("/insuranceService/update", 11), data);
  },
  /**
   * 批量删除费用
   * @param {*} id 查询参数
   */
  delAllSetting: function delAllSetting(ids) {
    return request.post(getUrl("/insuranceService/delete/".concat(ids), 11));
  },
  /**
   * 删除服务项目险别
   * @param {*} data
   */
  deleteRisk: function deleteRisk(data) {
    return request.delete(getUrl("/insuranceScheme/deleteRisk/".concat(data.insuranceSchemeRiskId, "/").concat(data.serviceProjectId), 11));
  }
};
export default objApi;