var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "app-m-cls" }, [
      _c(
        "div",
        { staticClass: "table-cls normal-btn-cls" },
        [
          _c(
            "el-drawer",
            {
              attrs: {
                title: "",
                visible: _vm.drawer,
                modal: false,
                "append-to-body": true,
                size: "350px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.drawer = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.searchForm,
                    "label-width": "110px",
                    size: "mini",
                  },
                },
                [
                  _vm.bizType == "OEM"
                    ? _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 22 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "出单网点：" } },
                                [
                                  _c("OemSelect", {
                                    attrs: { searchForm: _vm.orgForm },
                                    on: { nodeClick: _vm.handleNodeClick },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 22 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "保险公司：" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    filterable: "",
                                    placeholder: "请选择保险公司",
                                  },
                                  model: {
                                    value: _vm.searchForm.insCorpCode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "insCorpCode",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.insCorpCode",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "全部", value: null },
                                  }),
                                  _vm._l(_vm.supplyers, function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.shortName,
                                        value: item.code,
                                      },
                                    })
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 22 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "投保城市：" } },
                            [
                              _c("el-cascader", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  options: _vm.cityList,
                                  clearable: "",
                                  filterable: "",
                                },
                                model: {
                                  value: _vm.searchForm.insCityCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchForm, "insCityCode", $$v)
                                  },
                                  expression: "searchForm.insCityCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 22 } },
                        [
                          _c("el-form-item", { attrs: { label: "车牌号：" } }, [
                            _c(
                              "div",
                              { staticStyle: { display: "flex" } },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "margin-right": "10px",
                                      width: "100%",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { "margin-right": "10px" },
                                      attrs: {
                                        maxlength: "10",
                                        placeholder: "请输入车牌号",
                                        clearable: "",
                                        disabled: _vm.searchForm.isNotPlate,
                                      },
                                      on: { input: _vm.inputPlateNo },
                                      model: {
                                        value: _vm.searchForm.plateNo,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "plateNo",
                                            $$v
                                          )
                                        },
                                        expression: "searchForm.plateNo",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-checkbox",
                                  {
                                    on: { change: _vm.noPlateNoChange },
                                    model: {
                                      value: _vm.searchForm.isNotPlate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "isNotPlate",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.isNotPlate",
                                    },
                                  },
                                  [_vm._v("未上牌")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 22 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "车主名称：" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入车主名称",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.searchForm.ownerName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchForm, "ownerName", $$v)
                                  },
                                  expression: "searchForm.ownerName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 22 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "车架号：" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "17",
                                  placeholder: "请输入车架号",
                                  clearable: "",
                                },
                                on: { input: _vm.checkValue },
                                model: {
                                  value: _vm.searchForm.frameNo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchForm, "frameNo", $$v)
                                  },
                                  expression: "searchForm.frameNo",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 22 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "投保状态：" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: "请选择投保状态",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.searchForm.proposalStatus,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "proposalStatus",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.proposalStatus",
                                  },
                                },
                                _vm._l(
                                  _vm.dictSource["INSURED_STATUS_DROPDOWN"],
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      style: {
                                        display:
                                          item.dictCode == "99" ? "none" : null,
                                      },
                                      attrs: {
                                        label: item.dictName,
                                        value: item.dictCode,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 22 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "业务单号：" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入业务单号",
                                  clearable: "",
                                },
                                on: { input: _vm.checkQuoteNo },
                                model: {
                                  value: _vm.searchForm.quoteNo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchForm, "quoteNo", $$v)
                                  },
                                  expression: "searchForm.quoteNo",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 22 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "操作人：" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入操作人",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.searchForm.operatorByName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.searchForm,
                                      "operatorByName",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "searchForm.operatorByName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 22 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "操作日期：" } },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  "start-placeholder": "起期",
                                  "end-placeholder": "止期",
                                  type: "daterange",
                                  format: "yyyy-MM-dd",
                                  "value-format": "yyyy-MM-dd",
                                  "unlink-panels": "",
                                  "range-separator": "——",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.dateChange(
                                      "operateStartTime",
                                      "operateEndTime",
                                      $event
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.startEndTime,
                                  callback: function ($$v) {
                                    _vm.startEndTime = $$v
                                  },
                                  expression: "startEndTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c(
                          "div",
                          { staticClass: "searchBtnStyle" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "mini",
                                  icon: "el-icon-refresh-left",
                                },
                                on: { click: _vm.resetSearch },
                              },
                              [_vm._v("重置")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "mini",
                                  type: "primary",
                                  icon: "el-icon-search",
                                },
                                on: { click: _vm.searchData },
                              },
                              [_vm._v("查询")]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-cls normal-btn-cls" },
        [
          _c(
            "el-card",
            { staticClass: "box-card", attrs: { shadow: "never" } },
            [
              _c(
                "div",
                {
                  staticClass: "search-box",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v("车主名称：")]),
                      _c("el-input", {
                        staticStyle: { width: "125px" },
                        attrs: { placeholder: "请输入车主名称", clearable: "" },
                        model: {
                          value: _vm.searchForm.ownerName,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchForm, "ownerName", $$v)
                          },
                          expression: "searchForm.ownerName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v("车牌号：")]),
                      _c("el-input", {
                        staticStyle: { width: "125px" },
                        attrs: {
                          maxlength: "10",
                          placeholder: "请输入车牌号",
                          clearable: "",
                          disabled: _vm.searchForm.isNotPlate,
                        },
                        on: { input: _vm.inputPlateNo },
                        model: {
                          value: _vm.searchForm.plateNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchForm, "plateNo", $$v)
                          },
                          expression: "searchForm.plateNo",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v("车架号：")]),
                      _c("el-input", {
                        staticStyle: { width: "150px" },
                        attrs: {
                          maxlength: "17",
                          placeholder: "请输入车架号",
                          clearable: "",
                        },
                        on: { input: _vm.checkValue },
                        model: {
                          value: _vm.searchForm.frameNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchForm, "frameNo", $$v)
                          },
                          expression: "searchForm.frameNo",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v("保险公司：")]),
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "140px" },
                          attrs: {
                            filterable: "",
                            placeholder: "请选择保险公司",
                            clearable: "",
                          },
                          model: {
                            value: _vm.searchForm.insCorpCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "insCorpCode", $$v)
                            },
                            expression: "searchForm.insCorpCode",
                          },
                        },
                        _vm._l(_vm.supplyers, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.shortName, value: item.code },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v("操作日期：")]),
                      _c("el-date-picker", {
                        staticStyle: { width: "218px" },
                        attrs: {
                          "start-placeholder": "起期",
                          "end-placeholder": "止期",
                          type: "daterange",
                          format: "yyyy-MM-dd",
                          "value-format": "yyyy-MM-dd",
                          "unlink-panels": "",
                          "range-separator": "——",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.dateChange(
                              "operateStartTime",
                              "operateEndTime",
                              $event
                            )
                          },
                        },
                        model: {
                          value: _vm.startEndTime,
                          callback: function ($$v) {
                            _vm.startEndTime = $$v
                          },
                          expression: "startEndTime",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", icon: "el-icon-refresh-left" },
                          on: { click: _vm.resetSearch },
                        },
                        [_vm._v("重置")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            size: "mini",
                            type: "primary",
                            icon: "el-icon-search",
                          },
                          on: { click: _vm.searchData },
                        },
                        [_vm._v("查询")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "search-btn" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: { click: _vm.breakData },
                        },
                        [_vm._v("刷新")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            size: "mini",
                            disabled: _vm._.isEmpty(_vm.multipleSelection),
                          },
                          on: {
                            click: function ($event) {
                              return _vm.removeItems()
                            },
                          },
                        },
                        [_vm._v("批量删除")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: {
                            click: function ($event) {
                              _vm.drawer = true
                            },
                          },
                        },
                        [_vm._v("搜索")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  ref: "multipleTable",
                  staticStyle: { width: "100%" },
                  attrs: {
                    size: "mini",
                    border: "",
                    data: _vm.tableData,
                    height: _vm.tableHeightJD,
                    "header-cell-style": { background: "#F7F7F7" },
                    "highlight-current-row": "",
                  },
                  on: { "selection-change": _vm.handleSelectionChange },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "selection",
                      width: "55",
                      align: "center",
                      selectable: _vm.selectItems,
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "quoteNo",
                      label: "业务单号",
                      width: "120",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "plateNo",
                      label: "车牌号",
                      width: "100",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.plateNo &&
                            scope.row.plateNo != "未上牌" &&
                            scope.row.plateNo != "新车"
                              ? _c("div", [
                                  _c("span", [
                                    _vm._v(_vm._s(scope.row.plateNo)),
                                  ]),
                                ])
                              : _c(
                                  "div",
                                  { staticStyle: { "text-align": "center" } },
                                  [_c("span", [_vm._v("未上牌")])]
                                ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "insCityName",
                      label: "投保城市",
                      width: "120",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "name",
                      label: "被保人名称",
                      width: "120",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "salemanName",
                      label: "业务员",
                      width: "120",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "vin",
                      label: "车架号",
                      width: "160",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "ownerName",
                      label: "车主名称",
                      width: "150",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "salesOrgName",
                      label: "出单网点",
                      width: "200",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "operatorName",
                      label: "操作人",
                      width: "150",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "updateTime",
                      label: "操作时间",
                      width: "150",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "投保状态",
                      align: "left",
                      fixed: "right",
                      "min-width": "180",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.status
                              ? _c(
                                  "div",
                                  _vm._l(
                                    scope.row.status,
                                    function (item, index) {
                                      return _c("div", { key: index }, [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "margin-left": "5px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                item.insCorpName
                                                  ? item.insCorpName + ":"
                                                  : ""
                                              )
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "margin-left": "3px",
                                            },
                                            style: {
                                              color: _vm.getStatusColor(
                                                item.proposalStatus
                                              ),
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(item.statusDescription)
                                            ),
                                          ]
                                        ),
                                      ])
                                    }
                                  ),
                                  0
                                )
                              : _c(
                                  "div",
                                  { staticStyle: { "text-align": "center" } },
                                  [_c("span", [_vm._v("暂存")])]
                                ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "操作",
                      fixed: "right",
                      width: "138",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.viewDetail("view", scope.row)
                                  },
                                },
                              },
                              [_vm._v("查看")]
                            ),
                            !scope.row.status
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "danger",
                                      plain: "",
                                      size: "mini",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.removeItems(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("pagination", {
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.pageNum,
                  limit: _vm.listQuery.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageNum", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageSize", $event)
                  },
                  pagination: function ($event) {
                    return _vm.loadData()
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }