import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import Pagination from '@/components/Pagination';
import FieldAddDialog from './add';
import NonAutoRequest from '@/api/nonAutoRequest';
import { commonExport } from '@/utils/common';
export default {
  name: 'productField',
  mixins: [initHeight],
  components: {
    Pagination: Pagination,
    FieldAddDialog: FieldAddDialog
  },
  data: function data() {
    return {
      timeout1: null,
      timeout2: null,
      activeName: '',
      showAddDialog: false,
      addType: null,
      DialogType: null,
      DialogItem: null,
      loading: true,
      searchForm: {},
      tableData: [],
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      }
    };
  },
  created: function created() {
    //获取产品基本信息列表
    this.getProducInfotLists();
  },
  methods: {
    //获取产品基本信息列表
    getProducInfotLists: function getProducInfotLists() {
      var _this = this;
      var data = _objectSpread(_objectSpread({}, this.listQuery), this.searchForm);
      NonAutoRequest.getProductInfotList(data).then(function (res) {
        _this.loading = false;
        if (res.code == 200) {
          var resData = res.data && res.data.list;
          _this.tableData = resData ? resData : [];
          _this.total = res.data.page.recordsTotal;
        } else {
          _this.tableData = [];
          _this.total = 0;
          _this.loading = false;
        }
      });
    },
    // 导出防抖
    exportFn: function exportFn() {
      var _this2 = this;
      if (this.timeout1) {
        clearTimeout(this.timeout1);
      }
      this.timeout1 = setTimeout(function () {
        _this2.exportFn1();
      }, 500);
    },
    // 导出
    exportFn1: function exportFn1() {
      var exporParams = {
        total: this.total,
        fileName: '非车产品字段管理列表',
        exportUrl: '/biz-nonauto-service/product/attribute/search',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    // 重置 ----不重新获取数据，手动点击获取
    resetSearch: function resetSearch() {
      this.searchForm = {};
    },
    // 刷新防抖
    refreshClicked: function refreshClicked() {
      var _this3 = this;
      if (this.timeout2) {
        clearTimeout(this.timeout2);
      }
      this.timeout2 = setTimeout(function () {
        _this3.refreshClicked1();
      }, 500);
    },
    // 刷新
    refreshClicked1: function refreshClicked1() {
      this.getProducInfotLists();
    },
    // 查询
    searchData: function searchData() {
      this.getProducInfotLists();
    },
    //新增
    showAddDialogClicked: function showAddDialogClicked(type) {
      this.showAddDialog = true;
      this.addType = this.activeName;
      this.DialogType = type;
    },
    // 编辑
    editConfig: function editConfig(type, row) {
      this.showAddDialog = true;
      this.DialogType = type;
      this.addType = this.activeName;
      this.DialogItem = row;
    },
    // 删除二次确认
    deleteProductField: function deleteProductField(row) {
      var _this4 = this;
      this.$confirm('是否删除当前数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this4.deleteApi(row.attributeId);
      }).catch(function () {});
    },
    // 删除api
    deleteApi: function deleteApi(ids) {
      var _this5 = this;
      NonAutoRequest.getProducInfoDel(ids.toString()).then(function (res) {
        if (res.code == 200) {
          _this5.$message.success(res.msg);
        }
        _this5.getProducInfotLists();
      });
    },
    // 关闭弹窗
    loadData: function loadData() {
      this.showAddDialog = false;
      this.DialogType = null;
      this.getProducInfotLists();
    }
  }
};