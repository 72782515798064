var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { background: "#18232b", color: "white", height: "100vh" },
      attrs: { id: "map-container" },
    },
    [
      _c(
        "div",
        { staticClass: "wrapper" },
        [
          _c(
            "el-tooltip",
            {
              staticClass: "ScreenBox",
              attrs: {
                content: "" + (!_vm.isFullscreen ? "全屏" : "退出全屏"),
                effect: "dark",
                placement: "bottom",
              },
            },
            [
              _c("svg-icon", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isFullscreen,
                    expression: "!isFullscreen",
                  },
                ],
                attrs: {
                  "icon-class": _vm.isFullscreen
                    ? "exit-fullscreen"
                    : "fullscreen",
                },
                on: { click: _vm.switchScreen },
              }),
            ],
            1
          ),
          _c(
            "el-carousel",
            {
              ref: "carousel",
              staticStyle: { height: "100%" },
              attrs: {
                autoplay: _vm.autoplay,
                interval: 60000,
                arrow: "hover",
                loop: _vm.looper,
              },
              on: { change: _vm.changeCarousels },
              nativeOn: {
                mouseenter: function ($event) {
                  return _vm.delHandleMouseEnter($event)
                },
              },
            },
            [
              _c(
                "el-carousel-item",
                { staticStyle: { height: "100%" } },
                [_c("mapStatisticsBoard", { ref: "map1" })],
                1
              ),
              _c(
                "el-carousel-item",
                { staticStyle: { height: "100%" } },
                [_c("noVechileMapStatisticsBoard", { ref: "map2" })],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }