import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
/**
 * 增值服务条款差异配置接口
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 获取区域对象
   * @param {*} data 内容
   */
  getCity: function getCity(data) {
    return request.get(getUrl("/districts/getSubDistricts", 3), {
      params: data
    });
  },
  /**
   * 获取区域对象
   * @param {*} data 内容
   */
  getDistricts: function getDistricts(data) {
    return request.get(getUrl("/districts/getByType", 3), {
      params: data
    });
  },
  /**
   * 保司列表-添加城市
   * @param {*} data 内容
   */
  addCity: function addCity() {
    var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return request.post(getUrl("/diff/ins/add/city", 1), data);
  },
  /**
   * 保司列表
   * @param {*} data 内容
   */
  getListDiff: function getListDiff(data) {
    return request.get(getUrl("/diff/ins/search", 1), {
      params: data
    });
  },
  /**
   * 列表
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   * @param {*} data 内容
   */
  getList: function getList(pageNum, pageSize) {
    var data = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    return request.get(getUrl("/diff/search?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 1), {
      params: data
    });
  },
  /**
   * 详情
   * @param {*} id 查询参数
   */
  getDetail: function getDetail(id) {
    return request.get(getUrl("/diff/serviceItem?orgInsCorpUserId=".concat(id), 1));
  },
  /**
   * 新增
   * @param {*} data 内容
   */
  create: function create(data) {
    return request.post(getUrl("/diff/add", 1), data);
  },
  /**
   * 修改
   * @param {*} id 查询参数
   * @param {*} data 内容
   */
  update: function update(data) {
    return request.put(getUrl("/diff/serviceItem", 1), data);
  },
  /**
   * 删除
   * @param {*} ids 
   */
  delete: function _delete(ids) {
    return request.delete(getUrl("/diff/serviceItem?ids=".concat(ids.toString()), 1));
  },
  /**
   * 复制
   * @param {*} ids 
   */
  copyConfig: function copyConfig(data) {
    return request.post(getUrl("/diff/copy", 1), data);
  }
};
export default objApi;