var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.form.insured.type && _vm.form.insured.type == "personal"
        ? _c(
            "el-row",
            { attrs: { gutter: 0 } },
            [
              _c(
                "el-col",
                { attrs: { span: 14 } },
                [
                  _c("el-form-item", { attrs: { label: "证件类型" } }, [
                    _c(
                      "div",
                      { staticStyle: { display: "flex" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "double-input",
                            staticStyle: { width: "210px" },
                            attrs: {
                              filterable: "",
                              placeholder: "请选择证件类型",
                              clearable: false,
                            },
                            on: { change: _vm.getCertificateTypeName },
                            model: {
                              value: _vm.form.insured.certificateType,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.insured,
                                  "certificateType",
                                  $$v
                                )
                              },
                              expression: "form.insured.certificateType",
                            },
                          },
                          _vm._l(
                            _vm.personalCertificateTypeList,
                            function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: {
                                  label: item.dictName,
                                  value: item.dictCode,
                                },
                              })
                            }
                          ),
                          1
                        ),
                        _c("el-input", {
                          staticClass: "input-with-select",
                          attrs: {
                            maxlength: "20",
                            placeholder: "请输入证件号码",
                            clearable: "",
                          },
                          on: {
                            blur: function ($event) {
                              return _vm.certificateNoChange(_vm.form.insured)
                            },
                            input: _vm.inputCardNo,
                          },
                          model: {
                            value: _vm.form.insured.certificateNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.form.insured, "certificateNo", $$v)
                            },
                            expression: "form.insured.certificateNo",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c("el-form-item", { attrs: { label: "证件起止日期" } }, [
                    _c(
                      "div",
                      { staticStyle: { display: "flex" } },
                      [
                        _c(
                          "div",
                          { staticStyle: { display: "flex" } },
                          [
                            _c("el-date-picker", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: "证件有效起期",
                                type: "date",
                                format: "yyyy-MM-dd",
                                "value-format": "yyyy-MM-dd HH:mm:ss",
                                "picker-options": _vm.ownerDisabledStart,
                              },
                              model: {
                                value: _vm.form.insured.ownerCertStartDate,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form.insured,
                                    "ownerCertStartDate",
                                    $$v
                                  )
                                },
                                expression: "form.insured.ownerCertStartDate",
                              },
                            }),
                            _c("span", { staticStyle: { margin: "0 3px" } }, [
                              _vm._v("-"),
                            ]),
                            _c("el-date-picker", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: "证件有效止期",
                                type: "date",
                                format: "yyyy-MM-dd",
                                "value-format": "yyyy-MM-dd HH:mm:ss",
                                "picker-options": _vm.ownerDisabledEnd,
                                disabled:
                                  _vm.form.insured.ownerCertEndDate ===
                                  "9999-12-30 23:59:59",
                              },
                              model: {
                                value: _vm.form.insured.ownerCertEndDate,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form.insured,
                                    "ownerCertEndDate",
                                    $$v
                                  )
                                },
                                expression: "form.insured.ownerCertEndDate",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-checkbox",
                          {
                            staticStyle: { "margin-left": "10px" },
                            on: {
                              change: function (val) {
                                return _vm.dataValueChange(val, true)
                              },
                            },
                            model: {
                              value: _vm.form.insured.isLong,
                              callback: function ($$v) {
                                _vm.$set(_vm.form.insured, "isLong", $$v)
                              },
                              expression: "form.insured.isLong",
                            },
                          },
                          [_vm._v("长期")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "联系电话" } },
                    [
                      _c("el-input", {
                        ref: "ownerPhone",
                        staticStyle: { width: "100%" },
                        attrs: {
                          maxlength: "10",
                          placeholder: "请输入联系电话",
                          clearable: "",
                        },
                        model: {
                          value: _vm.form.insured.phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.form.insured, "phone", $$v)
                          },
                          expression: "form.insured.phone",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 16 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "电子邮箱" } },
                    [
                      _c("el-input", {
                        ref: "ownerEmail",
                        staticStyle: { width: "42%" },
                        attrs: {
                          maxlength: "20",
                          placeholder: "请输入电子邮箱",
                          clearable: "",
                        },
                        model: {
                          value: _vm.form.insured.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.form.insured, "email", $$v)
                          },
                          expression: "form.insured.email",
                        },
                      }),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "font-size": "12px",
                            color: "#999",
                            "margin-left": "10px",
                          },
                        },
                        [
                          _c("i", { staticClass: "el-icon-info" }),
                          _vm._v(" 用于接收保险公司发送的电子保单"),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 18 } },
                [
                  _c(
                    "el-form-item",
                    {
                      ref: "regionCode",
                      staticClass: "special_row_content",
                      attrs: { label: "联系地址", prop: "regionCode" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "special_col_one",
                          staticStyle: { display: "flex" },
                        },
                        [
                          _c("el-cascader", {
                            ref: "addrssCascader",
                            staticClass: "double-input-city",
                            staticStyle: { width: "210px" },
                            attrs: {
                              options: _vm.cityList,
                              clearable: false,
                              filterable: "",
                            },
                            on: { change: _vm.handleChange },
                            model: {
                              value: _vm.form.insured.regionCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.form.insured, "regionCode", $$v)
                              },
                              expression: "form.insured.regionCode",
                            },
                          }),
                          _c("el-input", {
                            staticClass: "input-with-select",
                            attrs: {
                              onkeyup:
                                "this.value = this.value.replace(/\\s+/g,'')",
                              maxlength: "40",
                              placeholder: "请输入详细地址",
                            },
                            model: {
                              value: _vm.form.insured.address,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.insured,
                                  "address",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.insured.address",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      ref: "birthday",
                      attrs: { label: "出生日期", prop: "birthday" },
                    },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          "value-format": "yyyy-MM-dd",
                          type: "date",
                          placeholder: "选择出生日期",
                          "picker-options": _vm.disabledLessThan,
                        },
                        on: { change: _vm.changeValue },
                        model: {
                          value: _vm.form.insured.birthday,
                          callback: function ($$v) {
                            _vm.$set(_vm.form.insured, "birthday", $$v)
                          },
                          expression: "form.insured.birthday",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "性别" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.form.insured.sex,
                            callback: function ($$v) {
                              _vm.$set(_vm.form.insured, "sex", $$v)
                            },
                            expression: "form.insured.sex",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "M" } }, [
                            _vm._v("男"),
                          ]),
                          _c("el-radio", { attrs: { label: "F" } }, [
                            _vm._v("女"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.form.insured.type && _vm.form.insured.type != "personal"
        ? _c(
            "el-row",
            { attrs: { gutter: 0 } },
            [
              _c(
                "el-col",
                { attrs: { span: 16 } },
                [
                  _c("el-form-item", { attrs: { label: "证件类型" } }, [
                    _c(
                      "div",
                      { staticStyle: { display: "flex" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "double-input",
                            staticStyle: { width: "210px" },
                            attrs: {
                              filterable: "",
                              placeholder: "请选择证件类型",
                              clearable: false,
                            },
                            on: { change: _vm.changeValue },
                            model: {
                              value: _vm.form.insured.certificateType,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.insured,
                                  "certificateType",
                                  $$v
                                )
                              },
                              expression: "form.insured.certificateType",
                            },
                          },
                          _vm._l(
                            _vm.unPersonalCertificateTypeList,
                            function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: {
                                  label: item.dictName,
                                  value: item.dictCode,
                                },
                              })
                            }
                          ),
                          1
                        ),
                        _c("el-input", {
                          staticClass: "input-with-select",
                          staticStyle: { width: "400px" },
                          attrs: {
                            maxlength: "20",
                            placeholder: "请输入证件号码",
                            clearable: "",
                          },
                          on: {
                            blur: function ($event) {
                              return _vm.certificateNoChange(_vm.form.insured)
                            },
                            input: _vm.inputCardNo,
                          },
                          model: {
                            value: _vm.form.insured.certificateNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.form.insured, "certificateNo", $$v)
                            },
                            expression: "form.insured.certificateNo",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "座机" } },
                    [
                      _c("el-input", {
                        ref: "ownerPhone",
                        staticStyle: { width: "100%" },
                        attrs: {
                          maxlength: "10",
                          placeholder: "例如：区号-12345678",
                          clearable: "",
                        },
                        model: {
                          value: _vm.form.insured.phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.form.insured, "phone", $$v)
                          },
                          expression: "form.insured.phone",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    {
                      ref: "regionCode",
                      staticClass: "special_row_content",
                      attrs: { label: "联系地址", prop: "regionCode" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "special_col_one",
                          staticStyle: { display: "flex" },
                        },
                        [
                          _c("el-cascader", {
                            ref: "addrssCascader",
                            staticClass: "double-input-city",
                            staticStyle: { width: "210px" },
                            attrs: {
                              options: _vm.cityList,
                              clearable: false,
                              filterable: "",
                            },
                            on: { change: _vm.handleChange },
                            model: {
                              value: _vm.form.insured.regionCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.form.insured, "regionCode", $$v)
                              },
                              expression: "form.insured.regionCode",
                            },
                          }),
                          _c("el-input", {
                            staticClass: "input-with-select",
                            attrs: {
                              onkeyup:
                                "this.value = this.value.replace(/\\s+/g,'')",
                              maxlength: "40",
                              placeholder: "请输入详细地址",
                            },
                            model: {
                              value: _vm.form.insured.address,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.insured,
                                  "address",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.insured.address",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-collapse",
                    {
                      staticClass: "jinbanren",
                      staticStyle: { "margin-top": "10px" },
                      attrs: { size: "mini" },
                      model: {
                        value: _vm.activeOne,
                        callback: function ($$v) {
                          _vm.activeOne = $$v
                        },
                        expression: "activeOne",
                      },
                    },
                    [
                      _c(
                        "el-collapse-item",
                        {
                          staticClass: "collapse-item-style",
                          attrs: { name: "1" },
                        },
                        [
                          _c("template", { slot: "title" }, [
                            _c(
                              "div",
                              {
                                staticClass: "title",
                                staticStyle: { "line-height": "25px" },
                              },
                              [
                                _c("FormLabel", {
                                  staticClass: "jinbanren",
                                  attrs: { "form-label": "经办人" },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "el-row",
                            {
                              staticStyle: {
                                display: "flex",
                                "flex-direction": "row",
                                "flex-wrap": "wrap",
                              },
                              attrs: { gutter: 0 },
                            },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "经办人姓名" } },
                                    [
                                      _c(
                                        "div",
                                        { staticStyle: { display: "flex" } },
                                        [
                                          _c("el-input", {
                                            ref: "ownerName",
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              maxlength: "10",
                                              placeholder: "请输入经办人姓名",
                                              clearable: "",
                                            },
                                            model: {
                                              value: _vm.form.manager.name,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form.manager,
                                                  "name",
                                                  $$v
                                                )
                                              },
                                              expression: "form.manager.name",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      ref: "operatorType",
                                      staticClass: "special_row_content",
                                      attrs: {
                                        label: "经办人类别",
                                        prop: "operatorType",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "special_col_one",
                                          staticStyle: { display: "flex" },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticClass: "double-input",
                                              staticStyle: { width: "200px" },
                                              attrs: {
                                                filterable: "",
                                                placeholder: "请选择经办人类别",
                                                clearable: false,
                                              },
                                              model: {
                                                value:
                                                  _vm.form.manager.operatorType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form.manager,
                                                    "operatorType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.manager.operatorType",
                                              },
                                            },
                                            _vm._l(
                                              _vm.dictSource["OPERRATOR_TYPE"],
                                              function (item, index) {
                                                return _c("el-option", {
                                                  key: index,
                                                  attrs: {
                                                    label: item.dictName,
                                                    value: item.dictCode,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            {
                              staticStyle: {
                                display: "flex",
                                "flex-direction": "row",
                                "flex-wrap": "wrap",
                              },
                              attrs: { gutter: 0 },
                            },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 22 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      ref: "operatorCertificateNo",
                                      attrs: {
                                        label: "经办人证件类型",
                                        prop: "operatorCertificateNo",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "special_col_one",
                                          staticStyle: { display: "flex" },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticClass: "double-input",
                                              staticStyle: { width: "200px" },
                                              attrs: {
                                                filterable: "",
                                                placeholder:
                                                  "请选择经办人证件类型",
                                                clearable: false,
                                              },
                                              on: { change: _vm.changeValue },
                                              model: {
                                                value:
                                                  _vm.form.manager
                                                    .operatorCertificate,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form.manager,
                                                    "operatorCertificate",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.manager.operatorCertificate",
                                              },
                                            },
                                            _vm._l(
                                              _vm.cardTypeOptions,
                                              function (item, index) {
                                                return _c("el-option", {
                                                  key: index,
                                                  attrs: {
                                                    label: item.dictName,
                                                    value: item.dictCode,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                          _c("el-input", {
                                            staticClass: "input-with-select",
                                            style: {
                                              "max-width":
                                                _vm.idCardNoWidth + "px",
                                              "min-width": "300px",
                                            },
                                            attrs: {
                                              maxlength: "20",
                                              placeholder:
                                                "请输入经办人证件号码",
                                              clearable: "",
                                            },
                                            on: {
                                              blur: function ($event) {
                                                return _vm.certificateNoChange(
                                                  _vm.form.manager
                                                )
                                              },
                                              input: _vm.managerInputCardNo,
                                            },
                                            model: {
                                              value:
                                                _vm.form.manager
                                                  .operatorCertificateNo,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form.manager,
                                                  "operatorCertificateNo",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.manager.operatorCertificateNo",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            { attrs: { gutter: 0 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      ref: "operatorMobile",
                                      attrs: {
                                        label: "经办人手机号",
                                        prop: "operatorMobile",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "special_col_one",
                                          staticStyle: { display: "flex" },
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "double-input",
                                            staticStyle: {
                                              "margin-right": "10px",
                                              width: "200px",
                                            },
                                            attrs: {
                                              type: "text",
                                              onkeyup:
                                                "value=value.replace(/[^0-9a-zA-Z\\-.]+/,'')",
                                              maxlength: "13",
                                              placeholder: "请输入经办人手机号",
                                              clearable: false,
                                            },
                                            on: {
                                              blur: function ($event) {
                                                return _vm.mobileChange(
                                                  _vm.form.manager
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.form.manager.operatorMobile,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form.manager,
                                                  "operatorMobile",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.manager.operatorMobile",
                                            },
                                          }),
                                          _vm.insuranceArea == "310100"
                                            ? _c(
                                                "el-checkbox",
                                                {
                                                  on: {
                                                    change: _vm.checkChange,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form.manager
                                                        .isNotSelf,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form.manager,
                                                        "isNotSelf",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.manager.isNotSelf",
                                                  },
                                                },
                                                [_vm._v("非本人手机号")]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 16 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "double-input",
                                      staticStyle: { width: "200px" },
                                      attrs: {
                                        label: "经办人邮箱",
                                        "label-width": "130px",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "special_col_one",
                                          staticStyle: { display: "flex" },
                                        },
                                        [
                                          _c("el-input", {
                                            staticStyle: {
                                              "margin-right": "10px",
                                              "min-width": "200px",
                                            },
                                            attrs: {
                                              maxlength: "40",
                                              placeholder: "请输入经办人邮箱",
                                              clearable: false,
                                            },
                                            on: {
                                              blur: function ($event) {
                                                return _vm.emailChange(
                                                  _vm.form.manager
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.form.manager.operatorEmail,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form.manager,
                                                  "operatorEmail",
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                                )
                                              },
                                              expression:
                                                "form.manager.operatorEmail",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }