/**
 * 工作台
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   *车队出单分析（数量，保费）
   * @param {*} data 查询参数
   */
  getIssuanceStatistics: function getIssuanceStatistics(data) {
    return request.get(getUrl("/workbench/getIssuanceStatistics", 1), {
      params: data
    });
  },
  /**
   *车险报价分析（报价车辆数，报价次数）
   * @param {*} data 查询参数
   */
  getIssuanceQuotation: function getIssuanceQuotation(data) {
    return request.get(getUrl("/workbench/getIssuanceQuotation", 1), {
      params: data
    });
  }
};
export default objApi;