var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "app-m-cls" },
      [
        _c(
          "div",
          { staticClass: "table-cls normal-btn-cls" },
          [
            _c(
              "el-drawer",
              {
                attrs: {
                  title: "",
                  visible: _vm.drawer,
                  modal: false,
                  "append-to-body": true,
                  size: "370px",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.drawer = $event
                  },
                },
              },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    staticStyle: { "margin-bottom": "15px" },
                    attrs: {
                      model: _vm.searchForm,
                      "label-width": "110px",
                      size: "mini",
                    },
                  },
                  [
                    _c(
                      "el-row",
                      [
                        _vm.bizType === "OEM"
                          ? _c(
                              "el-col",
                              { attrs: { span: 22 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "理赔机构" } },
                                  [
                                    _c("OemSelect", {
                                      ref: "oemSelect",
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        searchForm: _vm.orgForm,
                                        clearable: "",
                                        canClickArea: true,
                                        isGetUserList: true,
                                        isContainOem: true,
                                      },
                                      on: {
                                        nodeClick: _vm.handleNodeClick,
                                        getCurrentOrgUsers: _vm.oemGetUsers,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.bizType === "DEALER"
                          ? _c(
                              "el-col",
                              { attrs: { span: 22 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "理赔机构" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.searchForm.salesOrgName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "salesOrgName",
                                            $$v
                                          )
                                        },
                                        expression: "searchForm.salesOrgName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "操作人" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      filterable: "",
                                      size: "mini",
                                      clearable: "",
                                      placeholder: "请选择操作人",
                                    },
                                    on: { change: _vm.salemanNameChange },
                                    model: {
                                      value: _vm.searchForm.creatorName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "creatorName",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.creatorName",
                                    },
                                  },
                                  _vm._l(_vm.salesman, function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.userName,
                                        value: item.userId,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "车架号" } },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    maxlength: "40",
                                    placeholder: "请输入车架号",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.searchForm.vin,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchForm, "vin", $$v)
                                    },
                                    expression: "searchForm.vin",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "客户名称" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "40",
                                    placeholder: "请输入客户名称",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.searchForm.ownerName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchForm, "ownerName", $$v)
                                    },
                                    expression: "searchForm.ownerName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "理赔申请编号" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "请输入理赔申请编号",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.searchForm.claimNo,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchForm, "claimNo", $$v)
                                    },
                                    expression: "searchForm.claimNo",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "报案号" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "请输入报案号",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.searchForm.reportNo,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchForm, "reportNo", $$v)
                                    },
                                    expression: "searchForm.reportNo",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.activeName == "DBC"
                          ? _c(
                              "el-col",
                              { attrs: { span: 22 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "保险公司" } },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          filterable: "",
                                          placeholder: "请选择保险公司",
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.searchForm.supplierCode,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.searchForm,
                                              "supplierCode",
                                              $$v
                                            )
                                          },
                                          expression: "searchForm.supplierCode",
                                        },
                                      },
                                      _vm._l(
                                        _vm.suppliersLists,
                                        function (item, index) {
                                          return _c("el-option", {
                                            key: index,
                                            attrs: {
                                              label: item.supplierName,
                                              value: item.supplierCode,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "理赔状态" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      placeholder: "请选择理赔状态",
                                      clearable: "",
                                      filterable: "",
                                    },
                                    model: {
                                      value: _vm.searchForm.claimStatus,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "claimStatus",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.claimStatus",
                                    },
                                  },
                                  _vm._l(
                                    _vm.claimStatusList,
                                    function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.itemName,
                                          value: item.itemCode,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "申请时间" } },
                              [
                                _c("DatePicker", {
                                  attrs: {
                                    pickerDateTime: _vm.createdDateTime,
                                    startDate: "createStartDate",
                                    endDate: "createEndDate",
                                    width: "100%",
                                  },
                                  on: {
                                    pickerDateChange: function ($event) {
                                      return _vm.getPickersDate(
                                        "createdDateTime",
                                        $event
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "更新时间" } },
                              [
                                _c("DatePicker", {
                                  attrs: {
                                    pickerDateTime: _vm.modifiedDateTime,
                                    startDate: "modifiedStartTime",
                                    endDate: "modifiedEndTime",
                                    width: "100%",
                                  },
                                  on: {
                                    pickerDateChange: function ($event) {
                                      return _vm.getPickersDate(
                                        "modifiedDateTime",
                                        $event
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c("el-col", { attrs: { span: 24 } }, [
                          _c(
                            "div",
                            { staticClass: "searchBtnStyle" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    icon: "el-icon-refresh-left",
                                  },
                                  on: { click: _vm.resetSearch },
                                },
                                [_vm._v("重置")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    type: "primary",
                                    icon: "el-icon-search",
                                  },
                                  on: { click: _vm.searchData },
                                },
                                [_vm._v("查询")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "table-cls normal-btn-cls" },
          [
            _c(
              "el-tabs",
              {
                staticClass: "TopTabList",
                staticStyle: { background: "#fff" },
                on: { "tab-click": _vm.handleTabClick },
                model: {
                  value: _vm.activeName,
                  callback: function ($$v) {
                    _vm.activeName = $$v
                  },
                  expression: "activeName",
                },
              },
              _vm._l(_vm.tabsList, function (item, index) {
                return _c("el-tab-pane", {
                  key: index,
                  attrs: { label: item.className, name: item.classCode },
                })
              }),
              1
            ),
            _c(
              "el-card",
              { staticClass: "box-card", attrs: { shadow: "never" } },
              [
                _c(
                  "div",
                  {
                    staticClass: "search-box",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v("车架号：")]),
                        _c("el-input", {
                          attrs: {
                            size: "mini",
                            maxlength: "40",
                            placeholder: "请输入车架号",
                            clearable: "",
                          },
                          model: {
                            value: _vm.searchForm.vin,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "vin", $$v)
                            },
                            expression: "searchForm.vin",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v("理赔状态：")]),
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: "请选择理赔状态",
                              clearable: "",
                              filterable: "",
                            },
                            model: {
                              value: _vm.searchForm.claimStatus,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "claimStatus", $$v)
                              },
                              expression: "searchForm.claimStatus",
                            },
                          },
                          _vm._l(_vm.claimStatusList, function (item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: {
                                label: item.itemName,
                                value: item.itemCode,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v("申请时间：")]),
                        _c("DatePicker", {
                          attrs: {
                            pickerDateTime: _vm.createdDateTime,
                            startDate: "createStartDate",
                            endDate: "createEndDate",
                            width: "240px",
                          },
                          on: {
                            pickerDateChange: function ($event) {
                              return _vm.getPickersDate(
                                "createdDateTime",
                                $event
                              )
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              icon: "el-icon-refresh-left",
                            },
                            on: { click: _vm.resetSearch },
                          },
                          [_vm._v("重置")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "primary",
                              icon: "el-icon-search",
                            },
                            on: { click: _vm.searchData },
                          },
                          [_vm._v("查询")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "search-btn" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: { click: _vm.refreshClicked },
                          },
                          [_vm._v("刷新")]
                        ),
                        _vm.bizType !== "OEM"
                          ? _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: { click: _vm.addClicked },
                              },
                              [_vm._v("新增理赔申请")]
                            )
                          : _vm._e(),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: { click: _vm.exportFn },
                          },
                          [_vm._v("导出")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: {
                              click: function ($event) {
                                _vm.drawer = true
                              },
                            },
                          },
                          [_vm._v("搜索")]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.activeName == "XXAX" || _vm.activeName == "DXWY",
                        expression:
                          "activeName == 'XXAX'|| activeName == 'DXWY'",
                      },
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    ref: "multipleTable",
                    staticStyle: { width: "100%" },
                    attrs: {
                      size: "mini",
                      border: "",
                      data: _vm.tableData,
                      height: _vm.tableHeightS,
                      "header-cell-style": { background: "#F7F7F7" },
                      "highlight-current-row": "",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "claimNo",
                        label: "理赔申请编号",
                        "min-width": "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "reportNo",
                        label: "报案号",
                        "min-width": "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "classifyName",
                        label: "服务类别",
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "vin",
                        label: "车架号",
                        "min-width": "180",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "vhcSeriesName",
                        label: "车系",
                        "min-width": "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "repairOrderNo",
                        label: "TACT维修工单号",
                        "min-width": "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "claimStatusDisplay",
                        label: "理赔申请状态",
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "compensateStatusDisplay",
                        label: "赔付状态",
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "ownerName",
                        label: "客户名称",
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "plateNo",
                        label: "车牌号",
                        "min-width": "160",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "operateOrgName",
                        label: "出单机构",
                        "min-width": "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "operateOrgCode",
                        label: "出单机构编码",
                        "min-width": "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "creatorName",
                        label: "操作人",
                        "min-width": "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "applyOrgName",
                        label: "理赔机构",
                        "min-width": "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "applyOrgCode",
                        label: "理赔机构编码",
                        "min-width": "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "createdTime",
                        label: "申请时间",
                        width: "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "modifiedTime",
                        label: "更新时间",
                        width: "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "操作",
                        align: "left",
                        width: _vm.bizType != "OEM" ? 230 : 140,
                        fixed: "right",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.view(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("查看")]
                              ),
                              _vm.bizType != "OEM" &&
                              (scope.row.claimStatus == "CS06" ||
                                scope.row.claimStatus == "CS07" ||
                                scope.row.claimStatus == "CS08")
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "mini",
                                        plain: "",
                                        type: "primary",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.edit6(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("编辑")]
                                  )
                                : _vm._e(),
                              _vm.bizType != "OEM" &&
                              scope.row.claimStatus == "CS05"
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "mini",
                                        plain: "",
                                        type: "primary",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.edit5(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("重新申请")]
                                  )
                                : _vm._e(),
                              scope.row.claimStatus == "CS06" ||
                              (scope.row.claimStatus == "CS05" &&
                                !scope.row.reportNo)
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "mini",
                                        plain: "",
                                        type: "danger",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.deleteItem(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.activeName != "DBC" &&
                          _vm.activeName != "XXAX" &&
                          _vm.activeName != "DXWY",
                        expression:
                          "activeName!='DBC'&&activeName!='XXAX'&&activeName!='DXWY'",
                      },
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    ref: "multipleTable",
                    staticStyle: { width: "100%" },
                    attrs: {
                      size: "mini",
                      border: "",
                      data: _vm.tableData,
                      height: _vm.tableHeightS,
                      "header-cell-style": { background: "#F7F7F7" },
                      "highlight-current-row": "",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "claimNo",
                        label: "理赔申请编号",
                        "min-width": "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "reportNo",
                        label: "报案号",
                        "min-width": "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "classifyName",
                        label: "服务类别",
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "vin",
                        label: "车架号",
                        "min-width": "180",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "vhcSeriesName",
                        label: "车系",
                        "min-width": "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "claimStatusDisplay",
                        label: "理赔申请状态",
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "compensateStatusDisplay",
                        label: "赔付状态",
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "ownerName",
                        label: "客户名称",
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "plateNo",
                        label: "车牌号",
                        "min-width": "160",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "operateOrgName",
                        label: "出单机构",
                        "min-width": "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "operateOrgCode",
                        label: "出单机构编码",
                        "min-width": "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "creatorName",
                        label: "操作人",
                        "min-width": "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "applyOrgName",
                        label: "理赔机构",
                        "min-width": "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "applyOrgCode",
                        label: "理赔机构编码",
                        "min-width": "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "createdTime",
                        label: "申请时间",
                        width: "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "modifiedTime",
                        label: "更新时间",
                        width: "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "操作",
                        align: "left",
                        width: _vm.bizType != "OEM" ? 230 : 140,
                        fixed: "right",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.view(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("查看")]
                              ),
                              _vm.bizType != "OEM" &&
                              (scope.row.claimStatus == "CS06" ||
                                scope.row.claimStatus == "CS07" ||
                                scope.row.claimStatus == "CS08")
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "mini",
                                        plain: "",
                                        type: "primary",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.edit6(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("编辑")]
                                  )
                                : _vm._e(),
                              _vm.bizType != "OEM" &&
                              scope.row.claimStatus == "CS05"
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "mini",
                                        plain: "",
                                        type: "primary",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.edit5(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("重新申请")]
                                  )
                                : _vm._e(),
                              scope.row.claimStatus == "CS06" ||
                              (scope.row.claimStatus == "CS05" &&
                                !scope.row.reportNo)
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "mini",
                                        plain: "",
                                        type: "danger",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.deleteItem(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.activeName === "DBC",
                        expression: "activeName==='DBC'",
                      },
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    ref: "multipleTable",
                    staticStyle: { width: "100%" },
                    attrs: {
                      size: "mini",
                      border: "",
                      data: _vm.tableData,
                      height: _vm.tableHeightS,
                      "header-cell-style": { background: "#F7F7F7" },
                      "highlight-current-row": "",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "claimNo",
                        label: "理赔申请编号",
                        "min-width": "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "reportNo",
                        label: "报案号",
                        "min-width": "200",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "classifyName",
                        label: "服务类别",
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "vin",
                        label: "车架号",
                        "min-width": "180",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "vhcSeriesName",
                        label: "车系",
                        "min-width": "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "supplierName",
                        label: "保险公司名称",
                        "min-width": "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "claimStatusDisplay",
                        label: "理赔申请状态",
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "compensateStatusDisplay",
                        label: "赔付状态",
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "ownerName",
                        label: "客户名称",
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "plateNo",
                        label: "车牌号",
                        "min-width": "160",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "operateOrgName",
                        label: "出单机构",
                        "min-width": "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "operateOrgCode",
                        label: "出单机构编码",
                        "min-width": "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "creatorName",
                        label: "操作人",
                        "min-width": "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "applyOrgName",
                        label: "理赔机构",
                        "min-width": "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "applyOrgCode",
                        label: "理赔机构编码",
                        "min-width": "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "createdTime",
                        label: "申请时间",
                        width: "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "modifiedTime",
                        label: "更新时间",
                        width: "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "操作",
                        align: "left",
                        width: _vm.bizType != "OEM" ? 230 : 140,
                        fixed: "right",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.view(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("查看")]
                              ),
                              _vm.bizType != "OEM" &&
                              (scope.row.claimStatus == "CS06" ||
                                scope.row.claimStatus == "CS07")
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "mini",
                                        plain: "",
                                        type: "primary",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.edit6(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("编辑")]
                                  )
                                : _vm._e(),
                              _vm.bizType != "OEM" &&
                              scope.row.claimStatus == "CS05"
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "mini",
                                        plain: "",
                                        type: "primary",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.edit5(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("重新申请")]
                                  )
                                : _vm._e(),
                              scope.row.claimStatus == "CS06" ||
                              (scope.row.claimStatus == "CS05" &&
                                !scope.row.reportNo)
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "mini",
                                        plain: "",
                                        type: "danger",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.deleteItem(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c("pagination", {
                  attrs: {
                    total: _vm.total,
                    page: _vm.listQuery.pageNum,
                    limit: _vm.listQuery.pageSize,
                  },
                  on: {
                    "update:page": function ($event) {
                      return _vm.$set(_vm.listQuery, "pageNum", $event)
                    },
                    "update:limit": function ($event) {
                      return _vm.$set(_vm.listQuery, "pageSize", $event)
                    },
                    pagination: function ($event) {
                      return _vm.loadData()
                    },
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-dialog",
          {
            staticClass: "pageDialog",
            attrs: {
              visible: _vm.showAddDialog,
              "close-on-click-modal": false,
              width: "800px",
              "append-to-body": true,
            },
            on: {
              "update:visible": function ($event) {
                _vm.showAddDialog = $event
              },
            },
          },
          [
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "title" },
                slot: "title",
              },
              [
                _c(
                  "span",
                  {
                    staticStyle: { "font-size": "15px", "font-weight": "700" },
                  },
                  [_vm._v("新增理赔申请")]
                ),
              ]
            ),
            _c("ApplyDialog", {
              attrs: { refresh: _vm.showAddDialog },
              on: { closePage: _vm.loadData },
            }),
          ],
          1
        ),
        _c(
          "el-dialog",
          {
            staticClass: "pageDialog",
            attrs: {
              visible: _vm.showAuditDialog,
              "close-on-click-modal": false,
              width: "800px",
              "append-to-body": true,
            },
            on: {
              "update:visible": function ($event) {
                _vm.showAuditDialog = $event
              },
            },
          },
          [
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "title" },
                slot: "title",
              },
              [
                _c(
                  "span",
                  {
                    staticStyle: { "font-size": "15px", "font-weight": "700" },
                  },
                  [_vm._v("新增注销申请")]
                ),
              ]
            ),
            _c("AuditDialog", {
              attrs: {
                policyCancelId: _vm.policyCancelId,
                refresh: _vm.showAuditDialog,
              },
              on: { closePage: _vm.loadData },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }