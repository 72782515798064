import "core-js/modules/es.array.concat.js";
/**
 * 险别配置接口
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 详情
   * @param {*} id 查询参数
   */
  getDetail: function getDetail(id) {
    return request.get(getUrl("/visystem-riskkind/riskkind?riskkindId=".concat(id), 1));
  },
  /**
   * 新增
   * @param {*} data 内容
   */
  create: function create(data) {
    return request.post(getUrl("/visystem-riskkind/riskkind", 1), data);
  },
  /**
   * 修改
   * @param {*} id 查询参数
   * @param {*} data 内容
   */
  update: function update(id, data) {
    return request.put(getUrl("/visystem-riskkind/riskkind?riskkindId=".concat(id), 1), data);
  },
  /**
   * 列表
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   * @param {*} data 内容
   */
  getList: function getList(pageNum, pageSize, data) {
    return request.get(getUrl("/visystem-riskkind/search?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 1), {
      params: data
    });
  },
  /**
   * 删除
   * @param {*} ids 
   */
  delete: function _delete(ids) {
    return request.delete(getUrl("/visystem-riskkind/delete", 1), ids);
  }
};
export default objApi;