import "core-js/modules/es.array.concat.js";
/**
 * 结算配置
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 列表
   * @param {*} data 数据
   */
  getList: function getList(data) {
    return request.get(getUrl("/financesettleconfig/search", 1), {
      params: data
    });
  },
  /**
   * 新增数据
   * @param {*} data 内容
   */
  createSettleconfig: function createSettleconfig(data) {
    return request.post(getUrl("/financesettleconfig/create", 1), data);
  },
  /**
    * 查询数据
    * @param {*} id
    */
  getDetailById: function getDetailById(id) {
    return request.get(getUrl("/financesettleconfig/get?id=".concat(id), 1));
  },
  /**
   * 编辑数据
   * @param {*} data 内容
   */
  updataSettleconfig: function updataSettleconfig(data) {
    return request.post(getUrl("/financesettleconfig/update", 1), data);
  },
  /**
  * 删除数据
  * @param {*} data 内容
  */
  deleteData: function deleteData(ids) {
    return request.delete(getUrl("/financesettleconfig/batchDelete/".concat(ids), 1));
  },
  /**
  * 用机构编码和保司编码查询一条
  * @param {*} data 内容
  */
  getByOrgAndInsCorp: function getByOrgAndInsCorp(insCorpCode, orgCode) {
    return request.get(getUrl("/financesettleconfig/getByOrgAndInsCorp?insCorpCode=".concat(insCorpCode, "&orgCode=").concat(orgCode), 1));
  }
};
export default objApi;