import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getSupplyers } from '@/utils/common';
import recognitionApi from '@/api/recognition';
import storageRequest from '@/api/storage';
import inscorpdiffconfigRequest from '@/api/inscorpdiffconfig';
export default {
  // mixins: [dict],
  props: {},
  data: function data() {
    return {
      proList: [],
      districtTypeList: [{
        name: "省",
        code: 'province'
      }, {
        name: "市",
        code: 'city'
      }],
      opType: "",
      templateId: "",
      InsuranceList: [{
        code: "BZ",
        name: "交强险"
      }, {
        code: "BS",
        name: "商业险"
      }],
      PDFContent: '',
      form: {
        districtType: "province",
        clause: "",
        defaultStatus: "",
        enableStatus: "",
        fileStorageId: "",
        insCityCode: "",
        insCityName: "",
        insCorpCode: "",
        insCorpName: "",
        riskType: "",
        templateContent: ""
      },
      rules: {
        insCorpCode: [{
          required: true,
          message: '请选择保险公司',
          trigger: 'change'
        }],
        smsContent: [{
          required: true,
          message: '请输入短信内容',
          trigger: 'blur'
        }],
        templateContent: [{
          required: true,
          message: '请输入模板内容',
          trigger: 'blur'
        }]
      },
      tableData: [],
      supplyers: [],
      timeout1: null,
      cascaderAddr: [],
      cityOptions: JSON.parse(localStorage.getItem("VI_CityPcTree")) || [],
      fileList: []
    };
  },
  watch: {},
  mounted: function mounted() {
    var _this = this;
    this.getDistricts();
    if (this.$route.query) {
      this.opType = this.$route.query.type;
      if (this.$route.query.type == 'edit') {
        this.templateId = this.$route.query.id;
        recognitionApi.getTemplateDetail(this.$route.query.id).then(function (res) {
          if (res.code == 200) {
            var dcode = res.data.insCityCode;
            if (res.data.districtType && res.data.districtType == 'province') {
              _this.form.insCityCode = dcode;
            } else {
              var item = _.find(_this.cityOptions, function (i) {
                return i.value.substr(0, 3) == dcode.substr(0, 3);
              });
              _this.cascaderAddr = [item.value, dcode];
            }
            _this.form = res.data ? res.data : {};
            if (res.data.fileStorageId) {
              recognitionApi.getText(res.data.fileStorageId).then(function (resData) {
                // console.log(resData)
                _this.PDFContent = resData.data;
              });
            }
          }
        });
      } else {
        this.form = {
          districtType: "province",
          clause: "",
          defaultStatus: "",
          enableStatus: "",
          fileStorageId: "",
          insCityCode: "",
          insCityName: "",
          insCorpCode: "",
          insCorpName: "",
          riskType: "",
          templateContent: ""
        };
      }
    }
    this.initData();
    this.supplyers = getSupplyers();
  },
  methods: {
    ChangeDistrictType: function ChangeDistrictType() {
      this.form.insCityCode = '';
      this.cascaderAddr = [];
    },
    getDistricts: function getDistricts() {
      var _this2 = this;
      //获取省份
      inscorpdiffconfigRequest.getDistricts({
        type: "PROVINCE"
      }).then(function (res) {
        _this2.proList = res.data;
      });
    },
    setInsCityName: function setInsCityName(val) {
      var item = _.find(this.proList, function (i) {
        return i.code == val;
      });
      this.form.insCityName = item.name;
      this.form.insCityCode = val;
    },
    copyAddress: function copyAddress(val) {
      var oInput = document.createElement("input");
      //这边为链接地址this.liveLink='www.baidu.com'
      oInput.value = "{{".concat(val, "}}");
      document.body.appendChild(oInput);
      oInput.select();
      console.log(oInput.value);
      document.execCommand("Copy");
      oInput.remove();
      this.$message({
        message: "复制成功",
        type: "success"
      });
    },
    copyAddress1: function copyAddress1(val) {
      var oInput = document.createElement("input");
      //这边为链接地址this.liveLink='www.baidu.com'
      oInput.value = val;
      document.body.appendChild(oInput);
      oInput.select();
      // console.log(oInput.value);
      document.execCommand("Copy");
      oInput.remove();
      this.$message({
        message: "复制成功",
        type: "success"
      });
    },
    handleChange: function handleChange(val) {
      var _this3 = this;
      //省/市/区选择
      this.$nextTick(function () {
        var cascaderAddr = _this3.$refs['cascaderAddr'].getCheckedNodes()[0].pathLabels;
        console.log(cascaderAddr);
        console.log(val);
        _this3.form.insCityName = cascaderAddr[1];
        _this3.form.insCityCode = val[1];
      });
    },
    getInsCorpName: function getInsCorpName(val) {
      var item = _.find(this.supplyers, function (i) {
        return i.code == val;
      });
      this.form.insCorpName = item.shortName;
    },
    getTemplatelist: function getTemplatelist() {
      var _this4 = this;
      recognitionApi.getConfigDictList().then(function (res) {
        if (res.code == 200) {
          _this4.tableData = res.data ? res.data : [];
        }
      });
    },
    initData: function initData() {
      //初始化数据
      this.getTemplatelist();
      // this.getTemplateInfo()
      if (this.opType != 'edit') {
        this.$refs['detail'].resetFields();
      }
    },
    removeFile: function removeFile() {
      this.form.fileStorageId = '';
      this.PDFContent = '';
    },
    // 上传文件
    uploadFile: function uploadFile(param) {
      var _this5 = this;
      var formData = new FormData();
      formData.append("file", param.file);
      storageRequest.uploadFile(formData).then(function (res) {
        if (res.code === 200) {
          _this5.$message.success(res.msg);
          setTimeout(function () {
            _this5.fileList = [{
              fileName: param.file.name,
              //	文件名
              name: param.file.name,
              //	文件名
              fileId: res.data.fileStorageId
            }];
            _this5.form.fileStorageId = res.data.fileStorageId;
            _this5.form.fileUrl = res.data.fileUrl;
            recognitionApi.getText(res.data).then(function (resData) {
              // console.log(resData)
              _this5.PDFContent = resData.data;
            });
          }, 1000);
        }
      });
    },
    getTemplateInfo: function getTemplateInfo() {
      var _this6 = this;
      recognitionApi.getTemplateInfo(this.templateId).then(function (res) {
        if (res.code == 200) {
          _this6.form = res.data ? res.data : {};
        }
      });
    },
    save: function save() {
      var _this7 = this;
      if (this.timeout1) {
        //防抖
        clearTimeout(this.timeout1);
      }
      this.timeout1 = setTimeout(function () {
        _this7.save1();
      }, 800);
    },
    save1: function save1() {
      var _this8 = this;
      //保存数据
      this.$refs['detail'].validate(function (valid) {
        if (valid) {
          // this.form.bizType = 'BROKER'
          // this.form.orgCode = 
          // this.form.shared = true
          console.log(_this8.form);
          // return
          var request = _this8.opType == 'edit' ? recognitionApi.updateTemplate(_this8.form) : recognitionApi.addTemplate(_this8.form);
          request.then(function (res) {
            if (res.code == 200) {
              _this8.$message({
                message: res.msg,
                type: 'success'
              });
              _this8.closePage();
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    closePage: function closePage() {
      //关闭弹窗
      this.$refs['detail'].resetFields();
      // this.$emit('closePage');
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.replace({
        name: "PDFparse"
      });
    }
  }
};