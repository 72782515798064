var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.formLoading,
          expression: "formLoading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: {},
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "500px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "detail",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-width": "70px",
                    size: "mini",
                  },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "数量：",
                                prop: "verificationNumber",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入数量",
                                  clearable: "",
                                },
                                on: {
                                  input: function ($event) {
                                    _vm.form.verificationNumber = _vm.onInput(
                                      _vm.form.verificationNumber,
                                      0,
                                      _vm.currentObj.remainingNumber,
                                      0
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.form.verificationNumber,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "verificationNumber",
                                      $$v
                                    )
                                  },
                                  expression: "form.verificationNumber",
                                },
                              }),
                              _c("div", { staticStyle: { color: "red" } }, [
                                _vm._v(
                                  "(最大不超过" +
                                    _vm._s(_vm.currentObj.remainingNumber) +
                                    ")"
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "must-star",
                              attrs: { label: "附件：" },
                            },
                            [
                              _c(
                                "el-upload",
                                {
                                  ref: "upload",
                                  staticStyle: { "margin-bottom": "10px" },
                                  attrs: {
                                    "before-remove": function () {
                                      return (_vm.formData = "")
                                    },
                                    action: "",
                                    "show-file-list": false,
                                    "http-request": _vm.uploadFile,
                                    "file-list": _vm.fileList,
                                    accept: "*",
                                    "list-type": "text",
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        icon: "el-icon-upload2",
                                        size: "mini",
                                        plain: "",
                                      },
                                    },
                                    [_vm._v("选择文件")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-table",
                                {
                                  attrs: {
                                    size: "mini",
                                    border: "",
                                    data: _vm.form.fileJson,
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "fileNmae",
                                      label: "文件名",
                                      "show-overflow-tooltip": "",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "file-text",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handle(scope.row)
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(scope.row.fileNmae)
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "fileSize",
                                      label: "大小",
                                      width: "80",
                                      "show-overflow-tooltip": "",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("filterType")(
                                                    scope.row.fileSize
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "操作",
                                      align: "center",
                                      width: "80",
                                      fixed: "right",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  size: "mini",
                                                  type: "danger",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleDelete(
                                                      scope.$index
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("删除")]
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-check",
                type: "primary",
                plain: "",
                size: "mini",
              },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: {
                click: function ($event) {
                  return _vm.closePage(false, true)
                },
              },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }