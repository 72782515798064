import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import Pagination from '@/components/Pagination';
import userCenterApi from '@/api/userCenter';
export default {
  mixins: [initHeight],
  components: {
    Pagination: Pagination
  },
  props: {
    netUserId: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      listLoading: false,
      tableData: [],
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      }
    };
  },
  watch: {
    netUserId: {
      handler: function handler(newValue, oldValue) {
        this.loadData();
      },
      immediate: true
    }
  },
  methods: {
    // 获取列表数据
    loadData: function loadData() {
      var _this = this;
      this.listLoading = true;
      var data = _objectSpread(_objectSpread({}, this.listQuery), {}, {
        netUserId: this.netUserId
      });
      userCenterApi.getList(data).then(function (res) {
        _this.listLoading = false;
        if (res.code === 200) {
          _this.tableData = res.data.list;
          _this.total = res.data.page.recordsTotal;
        }
        ;
      }).catch(function (err) {
        _this.listLoading = false;
      });
    }
  }
};