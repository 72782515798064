import "core-js/modules/es.array.concat.js";
/**
 * 批量在保
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 列表
   * @param {*} pageNum 当前页
   */
  getList: function getList(data) {
    return request.get(getUrl("/batch/ciitc/search", 1), {
      params: data
    });
  },
  /**
    * 改变在保状态
    * @param {*} data 内容
    */
  changeUnderWarrantyState: function changeUnderWarrantyState(taskId, operate) {
    return request.post(getUrl("/batch/ciitc/operate?taskId=".concat(taskId, "&operate=").concat(operate), 1));
  },
  /**
   * 详情列表
   * @param {*} pageNum 当前页
   */
  getBatchDetailList: function getBatchDetailList(data) {
    return request.get(getUrl("/batch/ciitc/itemSearch", 1), {
      params: data
    });
  },
  /**
  * 创建在保任务
  * @param {*} data 内容
  */
  createUnderWarranty: function createUnderWarranty(data) {
    return request.post(getUrl("/batch/ciitc/create", 1), data);
  },
  /**
  * 在保结果列表
  * @param {*} pageNum 当前页
  */
  getunderWarrantyResultList: function getunderWarrantyResultList(data) {
    return request.get(getUrl("/batch/ciitc/resultSearch", 1), {
      params: data
    });
  }
};
export default objApi;