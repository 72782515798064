import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import dict from '../../mixins/dict';
import Dialog from '@/components/Dialog';
import VI_PROP_SUPPLYER from '@/assets/json/VI_PROP_SUPPLYER';
import VI_CityPcTree from '@/assets/json/VI_CityPcTree';
import policyTemplateRequest from "@/api/policyTemplate";
import storageRequest from '@/api/storage';
import { saveFile } from '@/utils/common';
export default {
  mixins: [dict],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    opType: {
      type: String,
      default: "add"
    },
    itemForm: {
      type: Object,
      default: function _default() {}
    }
  },
  components: {
    Dialog: Dialog
  },
  data: function data() {
    return {
      form: {},
      formLoading: false,
      supplyers: [],
      regionOptions: [],
      address: [],
      riskEnum: [],
      fileList: [],
      rules: {
        insCorpCode: [{
          required: true,
          message: '请选择保险公司',
          trigger: 'change'
        }],
        areaCode: [{
          required: true,
          message: '请选择区域',
          trigger: 'change'
        }],
        riskType: [{
          required: true,
          message: '请选择险种',
          trigger: 'change'
        }],
        templateName: [{
          required: true,
          message: '请输入模板名称',
          trigger: 'blur'
        }],
        priority: [{
          required: true,
          message: '请输入优先级',
          trigger: 'blur'
        }],
        pdfFileId: [{
          required: true,
          message: '请选择上传文件',
          trigger: 'change'
        }]
      },
      cityList: [],
      provinceList: []
    };
  },
  watch: {
    value: function value(val) {
      var _this = this;
      if (val) {
        this.form = {
          enable: true,
          pdfFileId: null,
          areaCode: "",
          areaName: ""
        };
        this.address = [];
        this.fileList = [];
        this.getRiskEnum();
        this.supplyers = VI_PROP_SUPPLYER;
        this.regionOptions = VI_CityPcTree;
        this.cityList = _.flatten(VI_CityPcTree.map(function (item) {
          return item.children;
        }));
        this.provinceList = VI_CityPcTree;
        this.$nextTick(function () {
          return _this.$refs["detail"].clearValidate();
        });
        if (this.opType != 'add') {
          this.getDetailById();
        }
      }
    }
  },
  computed: {
    showDialog: {
      get: function get() {
        return this.value;
      },
      set: function set(val) {
        this.$emit('input', val);
      }
    }
  },
  mounted: function mounted() {},
  methods: {
    //  查看详情
    getDetailById: function getDetailById() {
      var _this2 = this;
      this.formLoading = true;
      policyTemplateRequest.getDetailById(this.itemForm.templateId).then(function (res) {
        _this2.formLoading = false;
        if (res.code == 200) {
          _this2.form = res.data;
          _this2.fileList.push({
            pdfFileId: res.data.pdfFileId,
            name: res.data.pdfFileName
          });
          var areaCode = _this2.form.areaCode;
          var areaCodeLength = _this2.form.areaCode.length;
          if (areaCode.substring(areaCodeLength - 4, areaCodeLength) == "0000") {
            _this2.address = [res.data.areaCode];
          } else {
            var city = _.find(_this2.cityList, function (item) {
              return item.value == _this2.form.areaCode;
            }) || {};
            _this2.address = [city.pid, res.data.areaCode];
          }
        }
      }).catch(function (err) {
        return _this2.formLoading = false;
      });
    },
    // 保存
    save: function save() {
      var _this3 = this;
      this.$refs["detail"].validate(function (valid) {
        if (valid) {
          var postData = _objectSpread(_objectSpread({}, _this3.form), {}, {
            riskTypeName: "",
            insCorpName: ""
          });
          var riskType = _.find(_this3.riskEnum, function (item) {
            return item.code == postData.riskType;
          }) || {};
          postData.riskTypeName = riskType.text;
          var insCorp = _.find(_this3.supplyers, function (item) {
            return item.code == postData.insCorpCode;
          }) || {};
          postData.insCorpName = insCorp.shortName;
          _this3.formLoading = true;
          var req = _this3.opType == 'add' ? policyTemplateRequest.createTemplate(postData) : policyTemplateRequest.updataTemplate(_objectSpread(_objectSpread({}, postData), {}, {
            templateId: _this3.itemForm.templateId
          }));
          req.then(function (res) {
            _this3.formLoading = false;
            if (res.code == 200) {
              _this3.$message.success(res.msg);
              _this3.closePage(true);
            }
          }).catch(function (err) {
            return _this3.formLoading = false;
          });
        }
      });
    },
    // 删除文件
    fileRemove: function fileRemove() {
      this.form.pdfFileId = "";
    },
    // 下载文件
    downloadFile: function downloadFile(file) {
      if (!file.pdfFileId) return;
      var contentType = "application/pdf";
      storageRequest.downloadFile(file.pdfFileId).then(function (res) {
        saveFile(res, contentType, file.name);
      });
    },
    // 上传校验
    beforeUpload: function beforeUpload(file) {
      var fileName = file.name.substring(file.name.lastIndexOf('.') + 1);
      fileName = _.toLower(fileName);
      if (fileName !== 'pdf') {
        this.$message.warning('上传文件只能是pdf格式!');
        this.fileList = [];
        return false;
      }
    },
    uploadFile: function uploadFile(param) {
      var _this4 = this;
      //上传文件
      var formData = new FormData();
      formData.append("file", param.file);
      this.fileList = [];
      this.formLoading = true;
      storageRequest.uploadFile(formData).then(function (res) {
        _this4.formLoading = false;
        if (res.code === 200) {
          _this4.form.pdfFileId = res.data;
          _this4.form.pdfFileName = param.file.name;
          _this4.$message.success(res.msg);
          _this4.fileList.push({
            pdfFileId: res.data.fileStorageId,
            name: param.file.name
          });
        }
      }).catch(function (err) {
        return _this4.formLoading = false;
      });
    },
    getRiskEnum: function getRiskEnum() {
      var _this5 = this;
      policyTemplateRequest.getRiskEnum().then(function (res) {
        if (res.code == 200) {
          _this5.riskEnum = res.data;
        }
      });
    },
    handleChange: function handleChange(val) {
      var _this6 = this;
      //选择省/市/区
      if (!val) return;
      this.form.areaCode = val[val.length - 1]; //城市编码
      this.address = val;
      var areaCode = this.form.areaCode;
      var areaCodeLength = this.form.areaCode.length;
      if (areaCode.substring(areaCodeLength - 4, areaCodeLength) == "0000") {
        var province = _.find(this.provinceList, function (item) {
          return item.value == _this6.form.areaCode;
        }) || {};
        this.form.areaName = province.label;
      } else {
        var city = _.find(this.cityList, function (item) {
          return item.value == _this6.form.areaCode;
        }) || {};
        this.form.areaName = city.label;
      }
      this.$nextTick(function () {
        return _this6.$refs["detail"].clearValidate("areaCode");
      });
    },
    closePage: function closePage(isRefresh) {
      //关闭弹窗
      this.$emit('closePage', isRefresh);
    }
  }
};