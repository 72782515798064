var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "PageContainer",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading",
            },
          ],
        },
        [
          _c("div", { attrs: { slot: "main" }, slot: "main" }, [
            _c(
              "div",
              {
                staticClass: "app-m-cls",
                staticStyle: { "min-height": "calc(100vh - 130px)" },
              },
              [
                _c(
                  "div",
                  { ref: "header", staticClass: "header-cls" },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "form",
                        attrs: {
                          model: _vm.form,
                          rules: _vm.formRules,
                          "label-width": "130px",
                          size: "mini",
                        },
                      },
                      [
                        _c(
                          "el-collapse",
                          {
                            staticStyle: { "margin-top": "10px" },
                            model: {
                              value: _vm.activeOne,
                              callback: function ($$v) {
                                _vm.activeOne = $$v
                              },
                              expression: "activeOne",
                            },
                          },
                          [
                            _c(
                              "el-collapse-item",
                              {
                                staticClass: "collapse-item-style",
                                attrs: { name: "1" },
                              },
                              [
                                _c("template", { slot: "title" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "title",
                                      staticStyle: { "line-height": "25px" },
                                    },
                                    [
                                      _c("FormLabel", {
                                        attrs: { "form-label": "政策信息" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 8,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "政策名称",
                                              prop: "couponActivityName",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                clearable: "",
                                                maxlength: "40",
                                                disabled: _vm.opType == "view",
                                                placeholder: "请输入政策名称",
                                              },
                                              model: {
                                                value:
                                                  _vm.form.couponActivityName,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "couponActivityName",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.couponActivityName",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 8,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "政策对象",
                                              prop: "couponActivityTarget",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  filterable: "",
                                                  disabled:
                                                    _vm.opType == "view",
                                                  placeholder: "请选择政策对象",
                                                },
                                                model: {
                                                  value:
                                                    _vm.form
                                                      .couponActivityTarget,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "couponActivityTarget",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.couponActivityTarget",
                                                },
                                              },
                                              [
                                                _c("el-option", {
                                                  attrs: {
                                                    label: "车主",
                                                    value: "OWNER",
                                                  },
                                                }),
                                                _c("el-option", {
                                                  attrs: {
                                                    label: "经销商",
                                                    value: "DEALER",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 8,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "政策期间",
                                              prop: "startEndTime",
                                            },
                                          },
                                          [
                                            _c("el-date-picker", {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                disabled: _vm.opType == "view",
                                                "start-placeholder":
                                                  "请选择开始日期",
                                                "end-placeholder":
                                                  "请选择结束日期",
                                                type: "daterange",
                                                format: "yyyy-MM-dd ",
                                                "value-format": "yyyy-MM-dd",
                                                "unlink-panels": "",
                                                "range-separator": "—",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.dateChange($event)
                                                },
                                              },
                                              model: {
                                                value: _vm.form.startEndTime,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "startEndTime",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.startEndTime",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 8,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "权益服务",
                                              prop: "couponProductCode",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                clearable: "",
                                                maxlength: "40",
                                                disabled: _vm.opType == "view",
                                                placeholder: "请输入权益服务",
                                              },
                                              model: {
                                                value:
                                                  _vm.form.couponProductCode,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "couponProductCode",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.couponProductCode",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm.isShow
                                      ? _c(
                                          "el-col",
                                          {
                                            attrs: {
                                              xs: 24,
                                              sm: 12,
                                              md: 8,
                                              lg: 8,
                                              xl: 8,
                                            },
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _vm.form
                                                        .couponProductNumUnlimit,
                                                    expression:
                                                      "form.couponProductNumUnlimit",
                                                  },
                                                ],
                                                attrs: { label: "权益数量" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "flex",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          "margin-right":
                                                            "10px",
                                                          flex: "2",
                                                        },
                                                      },
                                                      [
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            "margin-right":
                                                              "10px",
                                                          },
                                                          attrs: {
                                                            disabled: "",
                                                            placeholder:
                                                              "请输入权益数量",
                                                            clearable: "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.form
                                                                .couponProductNum,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.form,
                                                                "couponProductNum",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "form.couponProductNum",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "el-checkbox",
                                                      {
                                                        attrs: {
                                                          disabled:
                                                            _vm.opType ==
                                                            "view",
                                                        },
                                                        on: {
                                                          change:
                                                            _vm.handleCheckbox,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form
                                                              .couponProductNumUnlimit,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form,
                                                              "couponProductNumUnlimit",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form.couponProductNumUnlimit",
                                                        },
                                                      },
                                                      [_vm._v("不限")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                            !_vm.form.couponProductNumUnlimit
                                              ? _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "权益数量",
                                                      prop: "couponProductNum",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          display: "flex",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              "margin-right":
                                                                "10px",
                                                              flex: "2",
                                                            },
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              staticStyle: {
                                                                "margin-right":
                                                                  "10px",
                                                              },
                                                              attrs: {
                                                                maxlength: "40",
                                                                disabled:
                                                                  _vm.opType ==
                                                                  "view",
                                                                placeholder:
                                                                  "请输入权益数量",
                                                                clearable: "",
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    _vm.form.couponProductNum =
                                                                      _vm.onInput(
                                                                        _vm.form
                                                                          .couponProductNum,
                                                                        0,
                                                                        _vm.productMaxNum
                                                                      )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.form
                                                                    .couponProductNum,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.form,
                                                                      "couponProductNum",
                                                                      typeof $$v ===
                                                                        "string"
                                                                        ? $$v.trim()
                                                                        : $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "form.couponProductNum",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "el-checkbox",
                                                          {
                                                            attrs: {
                                                              disabled:
                                                                _vm.opType ==
                                                                "view",
                                                            },
                                                            on: {
                                                              change:
                                                                _vm.handleCheckbox,
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.form
                                                                  .couponProductNumUnlimit,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.form,
                                                                    "couponProductNumUnlimit",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "form.couponProductNumUnlimit",
                                                            },
                                                          },
                                                          [_vm._v("不限")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 8,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "领取方式",
                                              prop: "couponReceiveWay",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  disabled:
                                                    _vm.opType == "view",
                                                  filterable: "",
                                                  placeholder: "请选择领取方式",
                                                },
                                                model: {
                                                  value:
                                                    _vm.form.couponReceiveWay,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "couponReceiveWay",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.couponReceiveWay",
                                                },
                                              },
                                              [
                                                _c("el-option", {
                                                  attrs: {
                                                    label: "整体",
                                                    value: "WHOLE",
                                                  },
                                                }),
                                                _c("el-option", {
                                                  attrs: {
                                                    label: "车辆",
                                                    value: "CAR",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 8,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "政策范围",
                                              prop: "policySource",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  disabled:
                                                    _vm.opType == "view",
                                                  multiple: "",
                                                  filterable: "",
                                                  placeholder: "请选择政策范围",
                                                },
                                                model: {
                                                  value: _vm.form.policySource,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "policySource",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.policySource",
                                                },
                                              },
                                              _vm._l(
                                                _vm.policySourceList,
                                                function (item, index) {
                                                  return _c("el-option", {
                                                    key: index,
                                                    attrs: {
                                                      label: item.itemName,
                                                      value: item.itemCode,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm.form.couponReceiveWay == "CAR" &&
                                    _vm.form.couponActivityTarget == "DEALER"
                                      ? _c(
                                          "el-col",
                                          {
                                            attrs: {
                                              xs: 24,
                                              sm: 12,
                                              md: 8,
                                              lg: 8,
                                              xl: 8,
                                            },
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "次新车续保率基准",
                                                  prop: "littleOldNewRateBase",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "flex",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticStyle: {
                                                        "margin-right": "10px",
                                                      },
                                                      attrs: {
                                                        maxlength: "40",
                                                        disabled:
                                                          _vm.opType == "view",
                                                        placeholder:
                                                          "请输入次新车续保率基准",
                                                        clearable: "",
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          _vm.form.littleOldNewRateBase =
                                                            _vm.onInput(
                                                              _vm.form
                                                                .littleOldNewRateBase,
                                                              0,
                                                              99999
                                                            )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form
                                                            .littleOldNewRateBase,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form,
                                                            "littleOldNewRateBase",
                                                            typeof $$v ===
                                                              "string"
                                                              ? $$v.trim()
                                                              : $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.littleOldNewRateBase",
                                                      },
                                                    }),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          padding: "1px 5px",
                                                        },
                                                      },
                                                      [_vm._v("%")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 24 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "政策描述",
                                              prop: "remark",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                type: "textarea",
                                                clearable: "",
                                                disabled: _vm.opType == "view",
                                                autosize: { minRows: 3 },
                                                placeholder: "请输入政策描述",
                                              },
                                              model: {
                                                value: _vm.form.remark,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "remark",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.remark",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 24 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "核销规则",
                                              prop: "couponConsumeRule",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                type: "textarea",
                                                clearable: "",
                                                disabled: _vm.opType == "view",
                                                autosize: { minRows: 3 },
                                                placeholder: "请输入核销规则",
                                              },
                                              model: {
                                                value:
                                                  _vm.form.couponConsumeRule,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "couponConsumeRule",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.couponConsumeRule",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 24 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "注意事项",
                                              prop: "note",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                type: "textarea",
                                                clearable: "",
                                                disabled: _vm.opType == "view",
                                                autosize: { minRows: 3 },
                                                placeholder: "请输入注意事项",
                                              },
                                              model: {
                                                value: _vm.form.note,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "note",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.note",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-collapse",
                          {
                            staticStyle: { "margin-top": "10px" },
                            model: {
                              value: _vm.activeThree,
                              callback: function ($$v) {
                                _vm.activeThree = $$v
                              },
                              expression: "activeThree",
                            },
                          },
                          [
                            _c(
                              "el-collapse-item",
                              {
                                staticClass: "collapse-item-style",
                                attrs: { name: "1" },
                              },
                              [
                                _c(
                                  "template",
                                  { slot: "title" },
                                  [
                                    _c("FormLabel", {
                                      staticStyle: {
                                        flex: "1",
                                        "line-height": "25px",
                                      },
                                      attrs: { "form-label": "附件列表" },
                                    }),
                                    _c(
                                      "el-upload",
                                      {
                                        ref: "uploadFile",
                                        staticClass: "upload-demo",
                                        staticStyle: {
                                          display: "flex",
                                          "margin-right": "15px",
                                          "z-index": "999",
                                        },
                                        attrs: {
                                          "before-upload": _vm.beforeUpload,
                                          "show-file-list": false,
                                          "http-request": _vm.uploadFile,
                                          action: "",
                                        },
                                      },
                                      [
                                        _vm.opType !== "view"
                                          ? _c(
                                              "el-button",
                                              {
                                                staticStyle: {
                                                  "margin-right": "15px",
                                                  "z-index": "999",
                                                },
                                                attrs: {
                                                  icon: "el-icon-upload2",
                                                  size: "mini",
                                                  plain: "",
                                                  type: "primary",
                                                },
                                              },
                                              [_vm._v("点击上传")]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  { attrs: { gutter: 0 } },
                                  [
                                    !_vm._.isEmpty(_vm.accessoryList)
                                      ? _c(
                                          "el-col",
                                          {
                                            staticClass: "accessoryList",
                                            attrs: { span: 24 },
                                          },
                                          _vm._l(
                                            _vm.accessoryList,
                                            function (item) {
                                              return _c(
                                                "div",
                                                { key: item.fileStorageId },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "accessoryDetail",
                                                      staticStyle: {
                                                        "padding-right": "10px",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.downloadAccessory(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.fileName)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      : _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              color: "#909399",
                                              "text-align": "center",
                                            },
                                          },
                                          [_c("p", [_vm._v("暂无数据")])]
                                        ),
                                  ],
                                  1
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-collapse",
                          {
                            staticStyle: { "margin-top": "10px" },
                            model: {
                              value: _vm.activeTwo,
                              callback: function ($$v) {
                                _vm.activeTwo = $$v
                              },
                              expression: "activeTwo",
                            },
                          },
                          [
                            _c(
                              "el-collapse-item",
                              {
                                staticClass: "collapse-item-style",
                                attrs: { name: "1" },
                              },
                              [
                                _c(
                                  "template",
                                  { slot: "title" },
                                  [
                                    _c("FormLabel", {
                                      staticStyle: {
                                        flex: "1",
                                        "line-height": "25px",
                                      },
                                      attrs: { "form-label": "政策规则" },
                                    }),
                                    _vm.opType !== "view"
                                      ? _c(
                                          "el-button",
                                          {
                                            staticStyle: {
                                              "margin-right": "15px",
                                              "z-index": "999",
                                            },
                                            attrs: {
                                              type: "primary",
                                              icon: "el-icon-plus",
                                              plain: "",
                                              size: "mini",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.createRule($event)
                                              },
                                            },
                                          },
                                          [_vm._v("新增政策规则")]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-table",
                                  {
                                    directives: [
                                      {
                                        name: "loading",
                                        rawName: "v-loading",
                                        value: _vm.ruleLoading,
                                        expression: "ruleLoading",
                                      },
                                    ],
                                    ref: "multipleTable",
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      size: "mini",
                                      border: "",
                                      data: _vm.tableData,
                                      height: 300,
                                      "header-cell-style": {
                                        background: "#F7F7F7",
                                      },
                                      "highlight-current-row": "",
                                    },
                                  },
                                  [
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "couponActivityRuleName",
                                        label: "规则名称",
                                        "show-overflow-tooltip": "",
                                      },
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "couponActivityRuleCode",
                                        label: "规则编码",
                                        "show-overflow-tooltip": "",
                                      },
                                    }),
                                    _vm.isShow
                                      ? _c("el-table-column", {
                                          attrs: {
                                            prop: "couponActivityRuleCount",
                                            label: "权益数量",
                                            "show-overflow-tooltip": "",
                                          },
                                        })
                                      : _vm._e(),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "couponActivityRuleAmount",
                                        label: "政策权益(元)",
                                        "show-overflow-tooltip": "",
                                      },
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        label: "操作",
                                        align: "center",
                                        width: "140px",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    plain: "",
                                                    type: "primary",
                                                    size: "mini",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return _vm.editRule(
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.opType !== "view"
                                                        ? "编辑"
                                                        : "查看"
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm.opType !== "view"
                                                ? _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        plain: "",
                                                        size: "mini",
                                                        type: "danger",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.deleteRule(
                                                            scope.row
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("删除")]
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ]),
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              staticStyle: {
                display: "flex",
                "justify-content": "center",
                "margin-top": "10px",
              },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _vm.opType !== "view"
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        size: "mini",
                        type: "primary",
                        icon: "el-icon-check",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.save(true)
                        },
                      },
                    },
                    [_vm._v("保存并发布")]
                  )
                : _vm._e(),
              _vm.opType !== "view"
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        size: "mini",
                        type: "primary",
                        icon: "el-icon-check",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.save(false)
                        },
                      },
                    },
                    [_vm._v("保存")]
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", icon: "el-icon-close" },
                  on: { click: _vm.close },
                },
                [_vm._v(" 取消 ")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showDialog,
            "close-on-click-modal": false,
            width: "800px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("新增政策规则")]
              ),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.ruleFormLoading,
                  expression: "ruleFormLoading",
                },
              ],
              staticClass: "app-m-cls",
              staticStyle: {},
            },
            [
              _c(
                "div",
                {
                  ref: "header",
                  staticClass: "header-cls",
                  staticStyle: { "max-height": "500px", overflow: "auto" },
                },
                [
                  _c("el-card", { attrs: { shadow: "never" } }, [
                    _c(
                      "div",
                      [
                        _c(
                          "el-form",
                          {
                            ref: "ruleDetail",
                            attrs: {
                              rules: _vm.ruleFormRules,
                              model: _vm.ruleForm,
                              "label-width": "120px",
                              size: "mini",
                            },
                          },
                          [
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "规则名称",
                                          prop: "couponActivityRuleName",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            clearable: "",
                                            disabled: _vm.opType == "view",
                                            placeholder: "请输入规则名称",
                                          },
                                          model: {
                                            value:
                                              _vm.ruleForm
                                                .couponActivityRuleName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.ruleForm,
                                                "couponActivityRuleName",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "ruleForm.couponActivityRuleName",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "政策权益",
                                          prop: "couponActivityRuleAmount",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticStyle: { display: "flex" } },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                disabled: _vm.opType == "view",
                                                placeholder: "请输入政策权益",
                                              },
                                              model: {
                                                value:
                                                  _vm.ruleForm
                                                    .couponActivityRuleAmount,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.ruleForm,
                                                    "couponActivityRuleAmount",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "ruleForm.couponActivityRuleAmount",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "padding-left": "10px",
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    _vm.ruleForm.couponActivityRuleAmount =
                                                      _vm.onInput(
                                                        _vm.ruleForm
                                                          .couponActivityRuleAmount,
                                                        0,
                                                        9999999
                                                      )
                                                  },
                                                },
                                              },
                                              [_vm._v("元")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm.isShow
                                  ? _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "权益数量",
                                              prop: "couponActivityRuleCount",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                clearable: "",
                                                disabled: _vm.opType == "view",
                                                placeholder: "请输入权益数量",
                                              },
                                              on: {
                                                input: function ($event) {
                                                  _vm.ruleForm.couponActivityRuleCount =
                                                    _vm.onInput(
                                                      _vm.ruleForm
                                                        .couponActivityRuleCount,
                                                      0,
                                                      9999999
                                                    )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.ruleForm
                                                    .couponActivityRuleCount,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.ruleForm,
                                                    "couponActivityRuleCount",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "ruleForm.couponActivityRuleCount",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-divider",
                          { attrs: { "content-position": "left" } },
                          [_vm._v("规则")]
                        ),
                        _c(
                          "el-form",
                          {
                            ref: "ruleSubDetail",
                            attrs: {
                              rule: _vm.rulesSub,
                              model: _vm.ruleSubForm,
                              "label-width": "120px",
                              size: "mini",
                            },
                          },
                          [
                            _c(
                              "el-row",
                              _vm._l(
                                _vm.ruleSubForm.allRuleList,
                                function (item, index) {
                                  return _c(
                                    "el-col",
                                    { key: index, attrs: { span: 24 } },
                                    [
                                      ["MULTI_SELECT", "SELECT"].includes(
                                        item.couponRuleFactorType
                                      ) &&
                                      item.couponRuleFactorType !==
                                        "SYMBOL_SELECT"
                                        ? _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: item.label,
                                                prop:
                                                  "allRuleList" +
                                                  "[" +
                                                  index +
                                                  "]" +
                                                  ".multipleVal",
                                                rules: {
                                                  required: item.required,
                                                  message: "请选择",
                                                  trigger: "change",
                                                },
                                              },
                                            },
                                            [
                                              item.couponRuleFactorType ==
                                              "MULTI_SELECT"
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "el-select",
                                                        {
                                                          staticStyle: {
                                                            width: "100%",
                                                          },
                                                          attrs: {
                                                            disabled:
                                                              _vm.opType ==
                                                              "view",
                                                            filterable: "",
                                                            multiple: "",
                                                            clearable: "",
                                                            placeholder:
                                                              "请选择",
                                                          },
                                                          model: {
                                                            value:
                                                              item.multipleVal,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "multipleVal",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.multipleVal",
                                                          },
                                                        },
                                                        _vm._l(
                                                          item.subDict,
                                                          function (
                                                            eitem,
                                                            eindex
                                                          ) {
                                                            return _c(
                                                              "el-option",
                                                              {
                                                                key: eindex,
                                                                attrs: {
                                                                  label:
                                                                    eitem.dictName,
                                                                  value:
                                                                    eitem.dictCode,
                                                                },
                                                              }
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              item.couponRuleFactorType ==
                                              "SELECT"
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "el-select",
                                                        {
                                                          staticStyle: {
                                                            width: "100%",
                                                          },
                                                          attrs: {
                                                            disabled:
                                                              _vm.opType ==
                                                              "view",
                                                            filterable: "",
                                                            clearable: "",
                                                            placeholder:
                                                              "请选择",
                                                          },
                                                          model: {
                                                            value:
                                                              item.multipleVal,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "multipleVal",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.multipleVal",
                                                          },
                                                        },
                                                        _vm._l(
                                                          item.subDict,
                                                          function (
                                                            eitem,
                                                            eindex
                                                          ) {
                                                            return _c(
                                                              "el-option",
                                                              {
                                                                key: eindex,
                                                                attrs: {
                                                                  label:
                                                                    eitem.dictName,
                                                                  value:
                                                                    eitem.dictCode,
                                                                },
                                                              }
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ]
                                          )
                                        : _vm._e(),
                                      ["TEXTAREA", "TEXT"].includes(
                                        item.couponRuleFactorType
                                      ) &&
                                      item.couponRuleFactorType !==
                                        "SYMBOL_SELECT"
                                        ? _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: item.label,
                                                prop:
                                                  "allRuleList" +
                                                  "[" +
                                                  index +
                                                  "]" +
                                                  ".maxValue",
                                                rules: {
                                                  required: item.required,
                                                  message: "请输入",
                                                  trigger: "blur",
                                                },
                                              },
                                            },
                                            [
                                              item.couponRuleFactorType ==
                                              "TEXTAREA"
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c("el-input", {
                                                        attrs: {
                                                          disabled:
                                                            _vm.opType ==
                                                            "view",
                                                          type: "textarea",
                                                          rows: 3,
                                                          placeholder: "请输入",
                                                        },
                                                        model: {
                                                          value: item.maxValue,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "maxValue",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.maxValue",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              item.couponRuleFactorType ==
                                              "TEXT"
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c("el-input", {
                                                        attrs: {
                                                          disabled:
                                                            _vm.opType ==
                                                            "view",
                                                          placeholder: "请输入",
                                                        },
                                                        model: {
                                                          value: item.maxValue,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "maxValue",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.maxValue",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ]
                                          )
                                        : _vm._e(),
                                      item.couponRuleFactorType ==
                                      "SYMBOL_SELECT"
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: { display: "flex" },
                                            },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: item.label,
                                                    prop:
                                                      "allRuleList" +
                                                      "[" +
                                                      index +
                                                      "]" +
                                                      ".operator",
                                                    rules: {
                                                      required: item.required,
                                                      message: "请选择",
                                                      trigger: "change",
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      staticStyle: {
                                                        width: "100%",
                                                      },
                                                      attrs: {
                                                        disabled:
                                                          _vm.opType == "view",
                                                        filterable: "",
                                                        clearable: "",
                                                        placeholder: "请选择",
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.changeOperator(
                                                            $event,
                                                            index
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value: item.operator,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "operator",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.operator",
                                                      },
                                                    },
                                                    _vm._l(
                                                      item.subDict,
                                                      function (eitem, eindex) {
                                                        return _c("el-option", {
                                                          key: eindex,
                                                          attrs: {
                                                            label:
                                                              eitem.dictName,
                                                            value:
                                                              eitem.dictCode,
                                                          },
                                                        })
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              item.operator !== "BETWEEN"
                                                ? _c(
                                                    "el-form-item",
                                                    {
                                                      staticStyle: {
                                                        flex: "1",
                                                      },
                                                      attrs: {
                                                        "label-width": "0",
                                                        prop:
                                                          "allRuleList" +
                                                          "[" +
                                                          index +
                                                          "]" +
                                                          ".maxValue",
                                                        rules: {
                                                          required:
                                                            item.required,
                                                          message: "请输入",
                                                          trigger: "blur",
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            display: "flex",
                                                            "padding-left":
                                                              "5px",
                                                            width: "100%",
                                                          },
                                                        },
                                                        [
                                                          _c("el-input", {
                                                            staticStyle: {
                                                              "padding-left":
                                                                "10px",
                                                            },
                                                            attrs: {
                                                              disabled:
                                                                _vm.opType ==
                                                                "view",
                                                              placeholder:
                                                                "请输入",
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                item.maxValue =
                                                                  _vm.onInput(
                                                                    item.maxValue,
                                                                    2,
                                                                    9999999
                                                                  )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                item.maxValue,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "maxValue",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item.maxValue",
                                                            },
                                                          }),
                                                          item.percent
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    padding:
                                                                      "1px 5px",
                                                                  },
                                                                },
                                                                [_vm._v("%")]
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              item.operator == "BETWEEN"
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        display: "flex",
                                                        "padding-left": "10px",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            "label-width": "0",
                                                            prop:
                                                              "allRuleList" +
                                                              "[" +
                                                              index +
                                                              "]" +
                                                              ".minValue",
                                                            rules: {
                                                              required:
                                                                item.required,
                                                              message: "请输入",
                                                              trigger: "blur",
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                display: "flex",
                                                              },
                                                            },
                                                            [
                                                              _c("el-input", {
                                                                attrs: {
                                                                  disabled:
                                                                    _vm.opType ==
                                                                    "view",
                                                                  clearable: "",
                                                                },
                                                                on: {
                                                                  input:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      item.minValue =
                                                                        _vm.onInput(
                                                                          item.minValue,
                                                                          2,
                                                                          9999999
                                                                        )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    item.minValue,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        item,
                                                                        "minValue",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "item.minValue",
                                                                },
                                                              }),
                                                              item.percent
                                                                ? _c(
                                                                    "span",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          padding:
                                                                            "1px 5px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "%"
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            "label-width": "0",
                                                            prop:
                                                              "allRuleList" +
                                                              "[" +
                                                              index +
                                                              "]" +
                                                              ".minInclude",
                                                            rules: {
                                                              required:
                                                                item.required,
                                                              message: "请选择",
                                                              trigger: "change",
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-select",
                                                            {
                                                              staticStyle: {
                                                                "padding-left":
                                                                  "5px",
                                                              },
                                                              attrs: {
                                                                disabled:
                                                                  _vm.opType ==
                                                                  "view",
                                                                size: "mini",
                                                                filterable: "",
                                                              },
                                                              model: {
                                                                value:
                                                                  item.minInclude,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "minInclude",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "item.minInclude",
                                                              },
                                                            },
                                                            [
                                                              _c("el-option", {
                                                                attrs: {
                                                                  label: "<",
                                                                  value: false,
                                                                },
                                                              }),
                                                              _c("el-option", {
                                                                attrs: {
                                                                  label: "<=",
                                                                  value: true,
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            "label-width": "0",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              ref:
                                                                "labelWidth" +
                                                                index,
                                                              refInFor: true,
                                                              staticStyle: {
                                                                padding:
                                                                  "2px 5px",
                                                                "text-align":
                                                                  "center",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.label
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            "label-width": "0",
                                                            prop:
                                                              "allRuleList" +
                                                              "[" +
                                                              index +
                                                              "]" +
                                                              ".maxInclude",
                                                            rules: {
                                                              required:
                                                                item.required,
                                                              message: "请选择",
                                                              trigger: "change",
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-select",
                                                            {
                                                              attrs: {
                                                                disabled:
                                                                  _vm.opType ==
                                                                  "view",
                                                                size: "mini",
                                                                filterable: "",
                                                              },
                                                              model: {
                                                                value:
                                                                  item.maxInclude,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "maxInclude",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "item.maxInclude",
                                                              },
                                                            },
                                                            [
                                                              _c("el-option", {
                                                                attrs: {
                                                                  label: "<",
                                                                  value: false,
                                                                },
                                                              }),
                                                              _c("el-option", {
                                                                attrs: {
                                                                  label: "<=",
                                                                  value: true,
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            "label-width": "0",
                                                            prop:
                                                              "allRuleList" +
                                                              "[" +
                                                              index +
                                                              "]" +
                                                              ".maxValue",
                                                            rules: {
                                                              required:
                                                                item.required,
                                                              message: "请输入",
                                                              trigger: "blur",
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                display: "flex",
                                                                "padding-left":
                                                                  "5px",
                                                              },
                                                            },
                                                            [
                                                              _c("el-input", {
                                                                attrs: {
                                                                  disabled:
                                                                    _vm.opType ==
                                                                    "view",
                                                                  clearable: "",
                                                                },
                                                                on: {
                                                                  input:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      item.maxValue =
                                                                        _vm.onInput(
                                                                          item.maxValue,
                                                                          2,
                                                                          9999999
                                                                        )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    item.maxValue,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        item,
                                                                        "maxValue",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "item.maxValue",
                                                                },
                                                              }),
                                                              item.percent
                                                                ? _c(
                                                                    "span",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          padding:
                                                                            "1px 5px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "%"
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "center",
                    "margin-top": "8px",
                  },
                },
                [
                  _vm.opType !== "view"
                    ? _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "primary" },
                          on: { click: _vm.saveRule },
                        },
                        [_vm._v("保存")]
                      )
                    : _vm._e(),
                  _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-close", size: "mini" },
                      on: { click: _vm.closeRule },
                    },
                    [_vm._v("关闭")]
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }