import "core-js/modules/es.array.concat.js";
/**
 * 消息接口
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 新增
   * @param {*} data 内容
   */
  create: function create(data) {
    return request.post(getUrl("/tpMessage/add", 8), data);
  },
  /**
   * 修改
   * @param {*} id 查询参数
   * @param {*} data 内容
   */
  updateMessage: function updateMessage(data) {
    return request.post(getUrl("/tpMessage/edit", 8), data);
  },
  /**
   * 获取消息
   * @param {*} userId 
   */
  getMessage: function getMessage(pageNum, pageSize, userId, type, status) {
    return request.get(getUrl("/tpMessage/get/".concat(userId, "?type=").concat(type, "&status=").concat(status, "&pageNum=").concat(pageNum, "&pageSize=").concat(pageSize), 8));
  },
  /**
   * 添加站内消息
   * @param {*} data 
   */
  addSystemMessage: function addSystemMessage(data) {
    return request.post(getUrl("/tpMessage/add", 8), data);
  },
  /**
    * 消息通知列表
    * @param {*} pageNum 当前页
    * @param {*} pageSize 每页显示条数
    * @param {*} data 内容
    */
  getMessageList: function getMessageList(pageNum, pageSize, data) {
    return request.get(getUrl("/message/template/list?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 8), {
      params: data
    });
  },
  /**
    * 消息通知分类数据
    */
  getClassifyList: function getClassifyList() {
    return request.get(getUrl("/message/templatecategory/list", 8));
  },
  /**
    * 消息通知范围数据
    */
  getRangeList: function getRangeList() {
    return request.get(getUrl("/message/range/list", 8));
  },
  /**
   * 消息通知是否启用
   * @param {*} id 内容
   */
  isEnableMessage: function isEnableMessage(data) {
    return request.post(getUrl("/message/template/toggle", 8), data);
  },
  /**
    * 消息通知删除
    */
  deleteRangeList: function deleteRangeList(ids) {
    return request.delete(getUrl("/message/template/delete/".concat(ids), 8));
  },
  /**
    * 消息通知新增
    * @param {*} data 内容
    */
  addMessage: function addMessage(data) {
    return request.post(getUrl("/message/template/add", 8), data);
  },
  /**
    * 消息通知查单个数据
    * @param {*} id
    */
  viewSingleMessage: function viewSingleMessage(id) {
    return request.get(getUrl("/message/template/get?id=".concat(id), 8));
  },
  /**
   * 消息通知删除
   */
  deleteMessageReceivedList: function deleteMessageReceivedList(ids) {
    return request.delete(getUrl("/message/v2/deleteReceivedMessage/".concat(ids), 8));
  },
  /**
    * 站内消息列表
    * @param {*} pageNum 当前页
    * @param {*} pageSize 每页显示条数
    * @param {*} data 内容
    */
  getMessageOnsiteList: function getMessageOnsiteList(pageNum, pageSize, data) {
    return request.get(getUrl("/message/v2/list?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 8), {
      params: data
    });
  },
  /**
    * 站内消息删除
    * @param {*} id 
    */
  deleteMessageOnsite: function deleteMessageOnsite(id) {
    return request.delete(getUrl("/message/v2/delete/".concat(id), 8));
  },
  /**
    * 站内消息查单个数据
    * @param {*} id
    */
  viewMessageOnsite: function viewMessageOnsite(id) {
    return request.get(getUrl("/message/v2/get?id=".concat(id), 8));
  },
  /**
    * 站内消息新增
    * @param {*} data 内容
    */
  addMessageOnsite: function addMessageOnsite(data) {
    return request.post(getUrl("/message/v2/sendNewMessage", 8), data);
  },
  /**
    * 站内消息编辑
    * @param {*} data
    */
  updateMessageOnsite: function updateMessageOnsite(data) {
    return request.post(getUrl("/message/v2/update", 8), data);
  },
  /**
    * 消息通知编辑
    * @param {*} data 内容
    */
  editMessage: function editMessage(data) {
    return request.post(getUrl("/message/template/update", 8), data);
  },
  /**
   * 收到的消息列表
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   * @param {*} data 内容
   */
  getMessageReceivedList: function getMessageReceivedList(pageNum, pageSize, data) {
    return request.get(getUrl("/message/v2/listReceivedMessage?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 8), {
      params: data
    });
  },
  /**
    * 收到的消息删除
    * @param {*} id 
    */
  deleteMessageReceived: function deleteMessageReceived(id) {
    return request.delete(getUrl("/message/v2/deleteReceivedMessage/".concat(id), 8));
  },
  /**
    * 收到的消息查单个数据
    * @param {*} id
    */
  viewMessageReceived: function viewMessageReceived(id) {
    return request.get(getUrl("/message/v2/markread/".concat(id), 8));
  },
  /**
    * 公告消息列表
    * @param {*} pageNum 当前页
    * @param {*} pageSize 每页显示条数
    * @param {*} data 内容
    */
  getAnnouncementList: function getAnnouncementList(pageNum, pageSize, data) {
    return request.get(getUrl("/message/v2/listReceivedMessage?messageBusinessGroup=NOTICE&pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 8), {
      params: data
    });
  },
  /**
    * 公告消息新增
    * @param {*} data 内容
    */
  addAnnouncement: function addAnnouncement(data) {
    return request.post(getUrl("/message/v2/sendNewMessage?messageBusinessGroup=NOTICE", 8), data);
  },
  /**
      * 公告消息详情
      * @param {*} id
      */
  viewAnnouncement: function viewAnnouncement(id) {
    return request.get(getUrl("/message/v2/get?id=".concat(id), 8));
  },
  /**
    * 公告消息弹窗
    * @param {*} data
    */
  newAnnouncementMsg: function newAnnouncementMsg(data) {
    return request.get(getUrl("/message/v2/listReceivedMessage", 8), {
      params: data
    });
  },
  /**
    * 收到的未读消息
    */
  getListReceivedNewMessage: function getListReceivedNewMessage() {
    return request.get(getUrl("/message/v2/listReceivedNewMessage", 8));
  },
  // ******************************公告管理（新）******************************
  /**
   * 分页查询
   * @param {*} data
   */
  getNoticeList: function getNoticeList(data) {
    return request.get(getUrl("/notice/searchList", 8), {
      params: data
    });
  },
  /**
   * 创建公告
   * @param {*} data
   */
  createNotice: function createNotice(data) {
    return request.post(getUrl("/notice/create", 8), data);
  },
  /**
   * 修改公告
   * @param {*} noticeId
   * @param {*} data
   */
  updateNotice: function updateNotice(noticeId, data) {
    return request.put(getUrl("/notice/update?noticeId=".concat(noticeId), 8), data);
  },
  /**
   * 批量删除公告
   * @param {*} ids
   */
  batchDeleteNotice: function batchDeleteNotice(ids) {
    return request.delete(getUrl("/notice/batchDelete?ids=".concat(ids), 8));
  },
  /**
   * 查询一条
   * @param {*} noticeId
   */
  getNoticeInfo: function getNoticeInfo(noticeId) {
    return request.get(getUrl("/notice/get?noticeId=".concat(noticeId), 8));
  },
  /**
   * 查询公告附件
   * @param {*} noticeId
   */
  getNoticeFileList: function getNoticeFileList(noticeId) {
    return request.get(getUrl("/notice/getFileList?noticeId=".concat(noticeId), 8));
  },
  /**
   * 查询公告发送目标
   * @param {*} noticeId
   */
  getNoticeTargetList: function getNoticeTargetList(noticeId) {
    return request.get(getUrl("/notice/getTargetList?noticeId=".concat(noticeId), 8));
  },
  /**
   * 创建公告用户已读
   * @param {*} data
   */
  createNoticeRead: function createNoticeRead(data) {
    return request.post(getUrl("/notice/createRead", 8), data);
  },
  /**
   * 查询公告已读用户
   * @param {*} data
   */
  getNoticeReadList: function getNoticeReadList(data) {
    return request.get(getUrl("/notice/getReadList", 8), {
      params: data
    });
  },
  /**
   * 发布公告
   * @param {*} noticeId
   * @param {*} published
   */
  noticePublish: function noticePublish(noticeId, published) {
    return request.put(getUrl("/notice/publish?noticeId=".concat(noticeId, "&published=").concat(published), 8));
  },
  /**
     * 公告批量用户已读
     * @param {*} data
     */
  batchCreateRead: function batchCreateRead(data) {
    return request.post(getUrl("/notice/batchCreateRead", 8), data);
  },
  // ******************************系统公告（新）******************************
  /**
   * 查询用户公告
   * @param {*} data
   */
  getUserNotice: function getUserNotice(data) {
    return request.get(getUrl("/notice/getUserNotice", 8), {
      params: data
    });
  }
};
export default objApi;