import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import dict from '@/mixins/dict';
import Pagination from '@/components/Pagination';
import DatePicker from '@/components/DatePicker';
import commonReq from '@/api/common';
import OemSelect from '@/components/OemSelect';
import { getCurrentDate, getBeforeDate, character, commonExport } from '@/utils/common';
export default {
  name: 'MaintenanceOrderRecord',
  components: {
    Pagination: Pagination,
    OemSelect: OemSelect,
    DatePicker: DatePicker
  },
  mixins: [initHeight, dict],
  computed: {},
  data: function data() {
    return {
      timeout1: null,
      searchForm: {},
      bizType: localStorage.getItem("bizType") || '',
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      loading: false,
      drawer: false,
      startEndTime: [],
      customerTypesList: [{
        value: 'NEW',
        label: '新保'
      }, {
        value: 'RENEWAL',
        label: '续保'
      }, {
        value: 'NON_POLICY',
        label: '在修不在保'
      }],
      orderStatusList: [{
        value: '1',
        label: '等待零件'
      }, {
        value: '2',
        label: '整备中'
      }, {
        value: '3',
        label: '已完成销售'
      }],
      createdDateTime: [getBeforeDate(6), getCurrentDate()],
      orgForm: {
        salesOrgCode: '',
        salesOrgName: '全部',
        salesOrgType: ''
      }
    };
  },
  created: function created() {
    this.loadData();
    if (this.bizType == 'DEALER') {
      var userInfo = JSON.parse(localStorage.getItem('userInfo'));
      this.searchForm.dealerCode = userInfo.data.orgCode;
      this.searchForm.dealerName = userInfo.data.orgName;
    }
  },
  methods: {
    // 监听输入事件
    inputvin: function inputvin(val) {
      if (val) {
        val = val.replace(/i/g, '').replace(/I/g, '').replace(/o/g, '').replace(/O/g, '').replace(/q/g, '').replace(/Q/g, '');
        this.searchForm.vinNo = character(_.toUpper(val));
        this.$forceUpdate();
      }
    },
    // 监听输入事件
    inputPhone: function inputPhone(val) {
      if (val) {
        this.searchForm.tel1 = val.replace(/[^0-9]/g, '');
        this.$forceUpdate();
      }
    },
    // 导出
    exportFn: function exportFn() {
      var exporParams = {
        total: this.total,
        limitNum: 200000,
        fileName: '维修订单记录',
        exportUrl: '/ps-ftms-service/duringRepair/orderDetailSearch',
        searchForm: _objectSpread(_objectSpread({}, this.listQuery), this.searchForm)
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    //日期选择事件
    getPickersDate: function getPickersDate(name, val) {
      if (val && val.length > 1) {
        this[name] = val;
      } else {
        this[name] = [];
      }
    },
    // 重置搜索表单
    resetSearch: function resetSearch() {
      this.orgForm = {
        salesOrgCode: '',
        salesOrgName: '全部',
        salesOrgType: ''
      };
      if (this.bizType == 'DEALER') {
        var userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.searchForm.dealerCode = userInfo.data.orgCode;
        this.searchForm.dealerName = userInfo.data.orgName;
      }
      this.createdDateTime = [getBeforeDate(6), getCurrentDate()];
    },
    // 搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    // 刷新
    breakData: function breakData() {
      this.loadData();
    },
    // 获取列表数据
    loadData: function loadData() {
      var _this = this;
      this.loading = true;
      this.drawer = false;
      if (this.createdDateTime) {
        this.searchForm.orderStartDate = this.createdDateTime[0] ? this.createdDateTime[0].split(' ')[0] + ' 00:00:00' : null;
        this.searchForm.orderEndDate = this.createdDateTime[1] ? this.createdDateTime[1].split(' ')[0] + ' 23:59:59' : null;
      }
      var data = _objectSpread(_objectSpread({}, this.listQuery), this.searchForm);
      commonReq.getDuringRepairOrderList(data).then(function (res) {
        _this.loading = false;
        if (res.code === 200) {
          var resList = res.data && res.data.list ? _.cloneDeep(res.data.list) : [];
          _this.tableData = resList;
          _this.total = res.data.page.recordsTotal;
        }
        ;
      }).catch(function (err) {
        _this.loading = false;
      });
    },
    // 查看详情;
    viewDetail: function viewDetail(item) {
      this.$router.push({
        path: "/customers/maintainRecordDetail",
        query: {
          id: item.orderId,
          tabName: "维修明细详情-" + item.orderId
        }
      });
    },
    // 切换机构
    nodeClick: function nodeClick(val) {
      this.searchForm.dealerCode = val.salesOrgCode;
      this.searchForm.dealerName = val.salesOrgName;
      this.searchForm.areaType = val.salesOrgType;
    },
    // //保存提交防抖
    toQuote: function toQuote(item) {
      var _this2 = this;
      if (this.timeout1) {
        clearTimeout(this.timeout1);
      }
      this.timeout1 = setTimeout(function () {
        _this2.toQuote1(item);
      }, 500);
    },
    toQuote1: function toQuote1(item) {
      //报价
      sessionStorage.removeItem("quoateForm");
      var router_path = this.$route.path;
      this.$router.push({
        path: "/proposal/add",
        query: {
          back: router_path,
          plateNo: item.registerNo && item.registerNo.indexOf('-') === -1 ? item.registerNo : "".concat(item.registerNo.split('-')[0]).concat(item.registerNo.split('-')[1]) || null,
          vin: item.vinNo,
          ownerCertificateNo: item.customerId,
          ownerMobile: item.tel1,
          // engineNo: item.engineNo,
          // ownerOtherMobile: item.ownerMobile,
          // modelName: item.modelName,
          ownerName: item.customerName,
          fromRouteId: item.orderId ? item.orderId : null
        }
      });
    }
  }
};