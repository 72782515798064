import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.keys.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getSupplyers } from '@/utils/common';
import repairApi from '@/api/repair';
import dictRequest from '@/api/dictionary';
import { number } from 'echarts';
export default {
  // mixins: [dict],
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    dialogType: {
      type: String,
      default: 'add'
    },
    AidId: {
      type: Number,
      default: null
    }
  },
  data: function data() {
    return {
      formOrgName: '',
      form: {
        "aidType": "",
        "bankCardNo": "",
        "bankName": "",
        "cardholderName": "",
        "contact": "",
        "fullAddress": "",
        "mobileNumber": "",
        "name": "",
        "orgItems": "",
        "telephone": ""
      },
      rules: {
        name: [{
          required: true,
          message: '请输入救援机构名称',
          trigger: 'blur'
        }],
        aidType: [{
          required: true,
          message: '请选择救援机构类型',
          trigger: 'change'
        }],
        orgItems: [{
          required: true,
          message: '请选择所属机构',
          trigger: 'change'
        }],
        contact: [{
          required: true,
          message: '请输入联系人',
          trigger: 'blur'
        }],
        mobileNumber: [{
          required: true,
          message: '请输入联系人手机号',
          trigger: 'blur'
        }]
      },
      supplyers: [],
      rescueType: []
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
    }
  },
  mounted: function mounted() {
    this.supplyers = getSupplyers();
    this.initData();
  },
  methods: {
    getAidagencyType: function getAidagencyType() {
      var _this = this;
      //获取结算状态
      dictRequest.getDictItemsByCode("AIDAGENCY_TYPE").then(function (res) {
        if (res.data) {
          _this.rescueType = res.data;
        }
      });
    },
    //  IDAGENCY_TYPE
    initData: function initData() {
      //初始化数据
      this.getAidagencyType();
      if (localStorage.getItem('userInfo')) {
        var userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.formOrgName = userInfo.data.organization.dealerName;
        this.form.orgItems = userInfo.orgCode;
      }
    },
    save: function save() {
      var _this2 = this;
      //保存数据
      this.$refs['detail'].validate(function (valid) {
        if (valid) {
          repairApi.addAid(_this2.form).then(function (res) {
            if (res.code == 200) {
              _this2.$message({
                message: res.msg,
                type: 'success'
              });
              _this2.closePage();
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    closePage: function closePage() {
      //关闭弹窗
      this.$refs['detail'].resetFields();
      this.$emit('closePage');
    }
  }
};