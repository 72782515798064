//
//
//
//

import * as echarts from 'echarts';
require('echarts/theme/macarons'); // echarts theme
import resize from './mixins/resize';
var animationDuration = 6000;
export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '300px'
    },
    chartData: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      chart: null
    };
  },
  watch: {
    chartData: {
      deep: true,
      handler: function handler(val) {
        this.setOptions(val);
      }
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      _this.initChart();
    });
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      this.chart = echarts.init(this.$el, 'macarons');
      this.setOptions(this.chartData);
    },
    setOptions: function setOptions(item, name) {
      if (!_.isEmpty(item)) {
        var option = {
          grid: {
            padding: [50, 0]
          },
          tooltip: {
            trigger: 'item',
            textStyle: {
              color: "#fff"
            }
          },
          series: [{
            type: 'pie',
            smooth: true,
            lineStyle: {
              width: 0
            },
            showSymbol: false,
            data: item
          }]
        };
        this.chart.setOption(option);
      }
    }
  }
};