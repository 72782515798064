import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import Pagination from '@/components/Pagination';
import OemSelect from '@/components/OemSelect';
import request from '@/api/finance';
import exportApi from '@/api/export';
import dict from '@/mixins/dict';
import AuditDialog from './dialog/auditDialog';
export default {
  components: {
    Pagination: Pagination,
    OemSelect: OemSelect,
    AuditDialog: AuditDialog
  },
  mixins: [initHeight, dict],
  data: function data() {
    return {
      drawer: false,
      searchForm: {},
      total: 0,
      orgData: {
        salesOrgName: "",
        salesOrgCode: ""
      },
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      loading: false,
      reviewStatusList: [{
        value: 'SETTLEMENT_PENDING_REVIEW',
        label: '待审核'
      }, {
        value: 'SETTLEMENT_REVIEWED',
        label: '已审核'
      }, {
        value: 'SETTLEMENT_CALL_BACK',
        label: '打回'
      }, {
        value: 'SETTLEMENT_PENDING_PAY',
        label: '待付款'
      }, {
        value: 'SETTLEMENT_PAYING',
        label: '付款中'
      }, {
        value: 'SETTLEMENT_PAY_FAIL',
        label: '付款失败'
      }, {
        value: 'SETTLEMENT_PAY_SUCCESS',
        label: '付款成功'
      }],
      settleTypeList: [{
        value: 'SETTLE_ORG',
        label: '机构结算'
      }, {
        value: 'SETTLE_MAN',
        label: '业务员结算'
      }],
      startEndTime: [],
      showAuditDialog: false,
      currentItem: {}
    };
  },
  created: function created() {},
  mounted: function mounted() {
    //保司
    this.loadData();
  },
  methods: {
    dateChange: function dateChange(start, end, val) {
      //日期选择事件
      if (val && val.length > 1) {
        this.searchForm[start] = val[0];
        this.searchForm[end] = val[1];
      } else {
        this.searchForm[start] = '';
        this.searchForm[end] = '';
      }
      ;
    },
    // 导出
    exportFn: function exportFn() {
      var _this = this;
      var postData = {
        "fields": {
          "settlementCode": "结算编号",
          "settleCostName": "费用类型",
          "settleTypeDisplay": "结算对象",
          "settleAmount": "结算金额",
          "estimatedTax": "预估税额",
          "salesOrgName": "业务机构",
          "salemanName": "业务员",
          "reviewStatusDisplay": "审核状态",
          "reviewByName": "审核人",
          "reviewTime": "审核时间",
          "creatorByName": "提交人",
          "createdTime": "提交时间"
        },
        "name": "费用结算单管理",
        "params": this.searchForm,
        "url": process.env.VUE_APP_APIDOMAIN + "/vi-core-service/finance/settlement"
      };
      exportApi.generateExcel(postData).then(function (res) {
        _this.$confirm("".concat(res.msg), '提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: "前往查看",
          showCancelButton: false
        }).then(function () {
          _this.$router.push({
            path: '/meExportLog'
          });
        }).catch(function () {});
      });
    },
    nodeClick: function nodeClick(val) {
      this.searchForm.salesOrgCode = val.salesOrgCode;
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {};
      this.orgData = {};
      this.startEndTime = [];
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    // 刷新
    breakData: function breakData() {
      this.searchData();
    },
    loadData: function loadData() {
      var _this2 = this;
      //获取列表数据
      this.loading = true;
      this.drawer = false;
      this.showAuditDialog = false;
      if (this.searchForm && this.searchForm.settlementTimeEnd) {
        this.searchForm.settlementTimeEnd = this.searchForm.settlementTimeEnd.split(' ')[0] + ' 23:59:59';
      }
      ;
      var postData = {};
      postData = Object.assign(postData, this.searchForm, this.listQuery);
      request.searchSettlement(postData).then(function (res) {
        _this2.loading = false;
        _this2.tableData = res.data.list;
        _this2.total = res.data.page.recordsTotal;
      }).catch(function (err) {
        _this2.loading = false;
      });
    },
    applyPay: function applyPay(item) {
      var _this3 = this;
      this.$confirm('您确定要结算吗？', '温馨提示', {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        request.applyPay(item.settlementId).then(function (res) {
          if (res.code === 200) {
            _this3.$message.success(res.msg);
            _this3.loadData();
          }
          ;
        });
      }).catch(function () {});
    },
    // 费用结算明细
    viewDetail: function viewDetail(item) {
      this.$router.push({
        path: "/costSettlementDetail",
        query: {
          settlementId: item.settlementId
        }
      });
    },
    // 审核
    audit: function audit(item) {
      this.showAuditDialog = true;
      this.currentItem = item;
    },
    // 反审核
    againstAudit: function againstAudit(item) {
      var _this4 = this;
      this.$confirm('您确定要撤销核对此单吗？', '温馨提示', {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        request.deReviewSettlement(item.settlementId).then(function (res) {
          if (res.code === 200) {
            _this4.$message.success(res.msg);
            _this4.loadData();
          }
          ;
        });
      }).catch(function () {});
    }
  }
};