import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { character } from "@/utils/common";
export default {
  name: 'QueryRenewalDialog',
  props: {
    vehicle: {
      type: Object,
      default: null
    },
    orgCode: {
      type: String,
      default: null
    },
    insCityCode: {
      type: String,
      default: null
    },
    refresh: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    refresh: function refresh(val) {
      if (val) {
        console.log('vehicle=>:', this.vehicle);
        console.log('orgCode=>:', this.orgCode);
        console.log('insCityCode=>:', this.insCityCode);
        this.form = _.cloneDeep(this.vehicle);
      }
    }
  },
  data: function data() {
    return {
      form: {}
    };
  },
  mounted: function mounted() {
    console.log('vehicle=>:', this.vehicle);
    console.log('orgCode=>:', this.orgCode);
    console.log('insCityCode=>:', this.insCityCode);
    this.form = _.cloneDeep(this.vehicle);
  },
  methods: {
    noPlateNoChange: function noPlateNoChange() {},
    changeValue: function changeValue() {},
    pastevin: function pastevin(val) {
      //监听粘贴事件
      var _clipboardData = val.clipboardData || window.clipboardData;
      var text = _clipboardData.getData('text');
      if (text) {
        text = text.replace(/\r\n/g, '').trim();
        this.inputvin(text);
      }
    },
    inputvin: function inputvin(val) {
      //监听输入事件
      if (val) {
        val = val.replace(/i/g, '').replace(/I/g, '').replace(/o/g, '').replace(/O/g, '').replace(/q/g, '').replace(/Q/g, '');
        var vin = _.cloneDeep(this.form.vin);
        vin = character(_.toUpper(val));
        this.form.vin = vin;
        this.$forceUpdate();
      }
    },
    save: function save() {
      var postData = _.pick(this.form, ['vin', 'plateNo', 'engineNo', 'ownerCertificateNo', 'ownerName', 'insuredName', 'lastPolicyNo']);
      this.$emit('submitRenewalData', _objectSpread(_objectSpread({}, postData), {}, {
        orgCode: this.orgCode,
        insCityCode: this.insCityCode
      }));
    },
    close: function close() {
      this.$emit('close');
    }
  }
};