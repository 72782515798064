var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "app-m-cls",
          staticStyle: { background: "#f5f5f5" },
          attrs: { id: "map-container" },
        },
        [
          _c(
            "div",
            { staticClass: "table-cls" },
            [
              _c("el-card", { staticClass: "box-card" }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      position: "relative",
                      "min-height": "100px",
                    },
                  },
                  [
                    _c(
                      "el-tabs",
                      {
                        on: { "tab-click": _vm.handleTabClick },
                        model: {
                          value: _vm.searchForm.statisticsReportDimensionality,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.searchForm,
                              "statisticsReportDimensionality",
                              $$v
                            )
                          },
                          expression:
                            "searchForm.statisticsReportDimensionality",
                        },
                      },
                      [
                        _c("el-tab-pane", {
                          attrs: { label: "时间维度", name: "TIME" },
                        }),
                        _vm.bizType === "OEM"
                          ? _c("el-tab-pane", {
                              attrs: {
                                label: "大区维度",
                                name: "PROVINCE_AREA",
                              },
                            })
                          : _vm._e(),
                        _c("el-tab-pane", {
                          attrs: { label: "保司维度", name: "INS_CORP" },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "search-box",
                        attrs: { slot: "header" },
                        slot: "header",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "timePeriod" },
                          [
                            _c("span", [_vm._v("时间周期：")]),
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  size: "mini",
                                  placeholder: "请选择查询范围",
                                  clearable: false,
                                },
                                on: { change: _vm.handleTimeTabClick },
                                model: {
                                  value:
                                    _vm.searchForm.statisticsReportTimeCycle,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.searchForm,
                                      "statisticsReportTimeCycle",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "searchForm.statisticsReportTimeCycle",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "月", value: "MONTH" },
                                }),
                                _c("el-option", {
                                  attrs: { label: "季度", value: "QUARTER" },
                                }),
                                _c("el-option", {
                                  attrs: { label: "年", value: "YEARS" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("span", [_vm._v("时间范围：")]),
                            _vm.searchForm.statisticsReportTimeCycle == "MONTH"
                              ? _c("el-date-picker", {
                                  key: "month",
                                  attrs: {
                                    format: "yyyy-MM",
                                    size: "mini",
                                    "value-format": "yyyy-MM-dd",
                                    type: "monthrange",
                                    "range-separator": "-",
                                    "start-placeholder": "开始月份",
                                    "end-placeholder": "结束月份",
                                    clearable: false,
                                  },
                                  on: { change: _vm.montnChange },
                                  model: {
                                    value: _vm.startEndTime,
                                    callback: function ($$v) {
                                      _vm.startEndTime = $$v
                                    },
                                    expression: "startEndTime",
                                  },
                                })
                              : _vm._e(),
                            _vm.searchForm.statisticsReportTimeCycle ==
                            "QUARTER"
                              ? _c("el-date-picker", {
                                  key: "quarterYear",
                                  staticClass: "endYear",
                                  attrs: {
                                    size: "mini",
                                    format: "yyyy年",
                                    "value-format": "yyyy",
                                    clearable: false,
                                    type: "year",
                                    placeholder: "选择年",
                                    "picker-options": _vm.curPickerOptions,
                                  },
                                  on: { change: _vm.quarterYearChange },
                                  model: {
                                    value: _vm.quarterYear,
                                    callback: function ($$v) {
                                      _vm.quarterYear = $$v
                                    },
                                    expression: "quarterYear",
                                  },
                                })
                              : _vm._e(),
                            _vm.searchForm.statisticsReportTimeCycle ==
                            "QUARTER"
                              ? _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      size: "mini",
                                      placeholder: "请选择季度",
                                      multiple: "",
                                      clearable: false,
                                      "multiple-limit": 2,
                                      "collapse-tags": "",
                                    },
                                    on: { change: _vm.quarterChange },
                                    model: {
                                      value: _vm.quarterArr,
                                      callback: function ($$v) {
                                        _vm.quarterArr = $$v
                                      },
                                      expression: "quarterArr",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: { label: "第一季度", value: 1 },
                                    }),
                                    _c("el-option", {
                                      attrs: { label: "第二季度", value: 2 },
                                    }),
                                    _c("el-option", {
                                      attrs: { label: "第三季度", value: 3 },
                                    }),
                                    _c("el-option", {
                                      attrs: { label: "第四季度", value: 4 },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.searchForm.statisticsReportTimeCycle == "YEARS"
                              ? _c("el-date-picker", {
                                  key: "startYear",
                                  staticClass: "endYear",
                                  attrs: {
                                    size: "mini",
                                    format: "yyyy年",
                                    "value-format": "yyyy",
                                    clearable: false,
                                    type: "year",
                                    placeholder: "选择年",
                                    "picker-options": _vm.startPickerOptions,
                                  },
                                  on: {
                                    change: function (val) {
                                      return _vm.yearChange(val, "start")
                                    },
                                  },
                                  model: {
                                    value: _vm.searchForm.curStartYear,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "curStartYear",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.curStartYear",
                                  },
                                })
                              : _vm._e(),
                            _vm.searchForm.statisticsReportTimeCycle == "YEARS"
                              ? _c("span", [_vm._v("~")])
                              : _vm._e(),
                            _vm.searchForm.statisticsReportTimeCycle == "YEARS"
                              ? _c("el-date-picker", {
                                  key: "endYear",
                                  staticClass: "endYear",
                                  attrs: {
                                    size: "mini",
                                    format: "yyyy年",
                                    "value-format": "yyyy",
                                    clearable: false,
                                    type: "year",
                                    placeholder: "选择年",
                                    "picker-options": _vm.endPickerOptions,
                                  },
                                  on: {
                                    change: function (val) {
                                      return _vm.yearChange(val, "end")
                                    },
                                  },
                                  model: {
                                    value: _vm.searchForm.curEndYear,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "curEndYear",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.curEndYear",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm.searchForm.statisticsReportDimensionality ==
                        "INS_CORP"
                          ? _c(
                              "div",
                              { staticClass: "timePeriod" },
                              [
                                _c("span", [_vm._v("保险公司：")]),
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      size: "mini",
                                      placeholder: "请选择保险公司",
                                    },
                                    on: { change: _vm.insCorpChange },
                                    model: {
                                      value: _vm.searchForm.insCorpLimit,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "insCorpLimit",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.insCorpLimit",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: { label: "全部", value: null },
                                    }),
                                    _c("el-option", {
                                      attrs: { label: "前三", value: 3 },
                                    }),
                                    _c("el-option", {
                                      attrs: { label: "前五", value: 5 },
                                    }),
                                    _c("el-option", {
                                      attrs: { label: "前十", value: 10 },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.searchForm.statisticsReportDimensionality !==
                        "PROVINCE_AREA"
                          ? _c(
                              "div",
                              {
                                staticClass: "car-type",
                                staticStyle: {
                                  margin: "5px 3px 0 0",
                                  display: "flex",
                                  "align-items": "center",
                                },
                              },
                              [
                                _c("span", [_vm._v("组织机构：")]),
                                _vm.bizType === "OEM"
                                  ? _c("OemSelect", {
                                      staticStyle: { width: "140px" },
                                      attrs: {
                                        searchForm: _vm.orgForm,
                                        canClickArea: true,
                                      },
                                      on: { nodeClick: _vm.nodeClick },
                                    })
                                  : _vm._e(),
                                _vm.bizType === "DEALER"
                                  ? _c("el-input", {
                                      staticStyle: { width: "200px" },
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.searchForm.orgName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "orgName",
                                            $$v
                                          )
                                        },
                                        expression: "searchForm.orgName",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "car-type-s" },
                          [
                            _c("span", [_vm._v("厂牌车型：")]),
                            _c("el-cascader", {
                              staticStyle: { width: "180px" },
                              attrs: {
                                props: { multiple: true },
                                "collapse-tags": "",
                                placeholder: "请选择厂牌车型",
                                filterable: "",
                                clearable: "",
                                options: _vm.CarOptions,
                              },
                              on: { change: _vm.CarHandleChange },
                              model: {
                                value: _vm.carTypeVal,
                                callback: function ($$v) {
                                  _vm.carTypeVal = $$v
                                },
                                expression: "carTypeVal",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "search-btn" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: { click: _vm.insuranceStatistics },
                              },
                              [_vm._v("保单统计")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: { click: _vm.vehicleSalesStatistics },
                              },
                              [_vm._v("车辆销售统计")]
                            ),
                            _c(
                              "el-dropdown",
                              {
                                staticStyle: { "margin-left": "5px" },
                                attrs: { size: "medium" },
                                on: { command: _vm.updateData },
                              },
                              [
                                _c("el-button", { attrs: { size: "mini" } }, [
                                  _vm._v(" 更新数据"),
                                  _c("i", {
                                    staticClass:
                                      "el-icon-arrow-down el-icon--right",
                                  }),
                                ]),
                                _c(
                                  "el-dropdown-menu",
                                  {
                                    attrs: { slot: "dropdown" },
                                    slot: "dropdown",
                                  },
                                  [
                                    _c(
                                      "el-dropdown-item",
                                      { attrs: { command: 0 } },
                                      [_vm._v("当月")]
                                    ),
                                    _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: "不含当月",
                                          placement: "left",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-dropdown-item",
                                          { attrs: { command: 3 } },
                                          [_vm._v("近3个月")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    style: {
                      "overflow-y": "auto",
                      padding: "10px",
                      height: _vm.containerH,
                      "overflow-y": "auto",
                    },
                  },
                  [
                    _c("div", [
                      _c("div", { attrs: { id: "newCarChart" } }),
                      _c("div", { staticClass: "footerText" }, [
                        _c("span", [
                          _vm._v("今年总销量:"),
                          _c("b", [
                            _vm._v(
                              _vm._s(
                                (_vm.newCarForm.newCarTotal &&
                                  _vm.newCarForm.newCarTotal
                                    .yearAllInsuredQT) ||
                                  "0"
                              )
                            ),
                          ]),
                        ]),
                        _c("span", [
                          _vm._v("新车投保量(年累):"),
                          _c("b", [
                            _vm._v(
                              _vm._s(
                                (_vm.newCarForm.newCarTotal &&
                                  _vm.newCarForm.newCarTotal.yearInsuredQT) ||
                                  "0"
                              )
                            ),
                          ]),
                        ]),
                        _c("span", [
                          _vm._v("新车投保率(年累):"),
                          _c("b", [
                            _vm._v(
                              _vm._s(
                                (_vm.newCarForm.newCarTotal &&
                                  _vm.newCarForm.newCarTotal.yearInsuredRate) ||
                                  "0"
                              ) + "%"
                            ),
                          ]),
                        ]),
                        _c("span", [
                          _vm._v("本月总销量:"),
                          _c("b", [
                            _vm._v(
                              _vm._s(
                                (_vm.newCarForm.newCarTotal &&
                                  _vm.newCarForm.newCarTotal
                                    .monthAllInsuredQT) ||
                                  "0"
                              )
                            ),
                          ]),
                        ]),
                        _c("span", [
                          _vm._v("新车投保量(当月):"),
                          _c("b", [
                            _vm._v(
                              _vm._s(
                                (_vm.newCarForm.newCarTotal &&
                                  _vm.newCarForm.newCarTotal.monthInsuredQT) ||
                                  "0"
                              )
                            ),
                          ]),
                        ]),
                        _c("span", [
                          _vm._v("新车投保率(当月):"),
                          _c("b", [
                            _vm._v(
                              _vm._s(
                                (_vm.newCarForm.newCarTotal &&
                                  _vm.newCarForm.newCarTotal
                                    .monthInsuredRate) ||
                                  "0"
                              ) + "%"
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("div", [
                      _c("div", { attrs: { id: "littleOldCarChart" } }),
                      _c("div", { staticClass: "footerText" }, [
                        _c("span", [
                          _vm._v("去年新车投保总量:"),
                          _c("b", [
                            _vm._v(
                              _vm._s(
                                (_vm.littleOldCarForm.littleOldCarTotal &&
                                  _vm.littleOldCarForm.littleOldCarTotal
                                    .yearAllInsuredQT) ||
                                  "0"
                              )
                            ),
                          ]),
                        ]),
                        _c("span", [
                          _vm._v("次新车投保量(年累):"),
                          _c("b", [
                            _vm._v(
                              _vm._s(
                                (_vm.littleOldCarForm.littleOldCarTotal &&
                                  _vm.littleOldCarForm.littleOldCarTotal
                                    .yearInsuredQT) ||
                                  "0"
                              )
                            ),
                          ]),
                        ]),
                        _c("span", [
                          _vm._v("次新车投保率(年累):"),
                          _c("b", [
                            _vm._v(
                              _vm._s(
                                (_vm.littleOldCarForm.littleOldCarTotal &&
                                  _vm.littleOldCarForm.littleOldCarTotal
                                    .yearInsuredRate) ||
                                  "0"
                              ) + "%"
                            ),
                          ]),
                        ]),
                        _c("span", [
                          _vm._v("去年当月新车总投保量:"),
                          _c("b", [
                            _vm._v(
                              _vm._s(
                                (_vm.littleOldCarForm.littleOldCarTotal &&
                                  _vm.littleOldCarForm.littleOldCarTotal
                                    .monthAllInsuredQT) ||
                                  "0"
                              )
                            ),
                          ]),
                        ]),
                        _c("span", [
                          _vm._v("次新车投保量(当月):"),
                          _c("b", [
                            _vm._v(
                              _vm._s(
                                (_vm.littleOldCarForm.littleOldCarTotal &&
                                  _vm.littleOldCarForm.littleOldCarTotal
                                    .monthInsuredQT) ||
                                  "0"
                              )
                            ),
                          ]),
                        ]),
                        _c("span", [
                          _vm._v("次新车投保率(当月):"),
                          _c("b", [
                            _vm._v(
                              _vm._s(
                                (_vm.littleOldCarForm.littleOldCarTotal &&
                                  _vm.littleOldCarForm.littleOldCarTotal
                                    .monthInsuredRate) ||
                                  "0"
                              ) + "%"
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("div", [
                      _c("div", { attrs: { id: "RenewalInsurance" } }),
                      _c("div", { staticClass: "footerText" }, [
                        _c("span", [
                          _vm._v("去年总投保量:"),
                          _c("b", [
                            _vm._v(
                              _vm._s(
                                (_vm.RenewalForm.RenewalInsuranceTotal &&
                                  _vm.RenewalForm.RenewalInsuranceTotal
                                    .yearAllInsuredQT) ||
                                  "0"
                              )
                            ),
                          ]),
                        ]),
                        _c("span", [
                          _vm._v("续保投保量(年累):"),
                          _c("b", [
                            _vm._v(
                              _vm._s(
                                (_vm.RenewalForm.RenewalInsuranceTotal &&
                                  _vm.RenewalForm.RenewalInsuranceTotal
                                    .yearInsuredQT) ||
                                  "0"
                              )
                            ),
                          ]),
                        ]),
                        _c("span", [
                          _vm._v("续保投保率(年累):"),
                          _c("b", [
                            _vm._v(
                              _vm._s(
                                (_vm.RenewalForm.RenewalInsuranceTotal &&
                                  _vm.RenewalForm.RenewalInsuranceTotal
                                    .yearInsuredRate) ||
                                  "0"
                              ) + "%"
                            ),
                          ]),
                        ]),
                        _c("span", [
                          _vm._v("去年当月总投保量:"),
                          _c("b", [
                            _vm._v(
                              _vm._s(
                                (_vm.RenewalForm.RenewalInsuranceTotal &&
                                  _vm.RenewalForm.RenewalInsuranceTotal
                                    .monthAllInsuredQT) ||
                                  "0"
                              )
                            ),
                          ]),
                        ]),
                        _c("span", [
                          _vm._v("续保投保量(当月):"),
                          _c("b", [
                            _vm._v(
                              _vm._s(
                                (_vm.RenewalForm.RenewalInsuranceTotal &&
                                  _vm.RenewalForm.RenewalInsuranceTotal
                                    .monthInsuredQT) ||
                                  "0"
                              )
                            ),
                          ]),
                        ]),
                        _c("span", [
                          _vm._v("续保投保率(当月):"),
                          _c("b", [
                            _vm._v(
                              _vm._s(
                                (_vm.RenewalForm.RenewalInsuranceTotal &&
                                  _vm.RenewalForm.RenewalInsuranceTotal
                                    .monthInsuredRate) ||
                                  "0"
                              ) + "%"
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showInsuranceDialog,
            "close-on-click-modal": false,
            width: "1100px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showInsuranceDialog = $event
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("保单统计")]
              ),
            ]
          ),
          _c("InsuranceDialog", {
            attrs: {
              searchForm: _vm.searchForm,
              refresh: _vm.showInsuranceDialog,
            },
            on: {
              closePage: function ($event) {
                _vm.showInsuranceDialog = false
              },
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showVehicleSalesDialog,
            "close-on-click-modal": false,
            width: "1100px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showVehicleSalesDialog = $event
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("车辆销售统计")]
              ),
            ]
          ),
          _c("VehicleSalesDialog", {
            attrs: {
              searchForm: _vm.searchForm,
              refresh: _vm.showVehicleSalesDialog,
            },
            on: {
              closePage: function ($event) {
                _vm.showVehicleSalesDialog = false
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }