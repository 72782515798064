var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "app-m-cls" }, [
        _c(
          "div",
          { staticClass: "table-cls normal-btn-cls" },
          [
            _c(
              "el-drawer",
              {
                attrs: {
                  title: "",
                  visible: _vm.drawer,
                  modal: false,
                  "append-to-body": true,
                  size: "350px",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.drawer = $event
                  },
                },
              },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.searchForm,
                      "label-width": "110px",
                      size: "mini",
                    },
                  },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "车牌号" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "10",
                                    placeholder: "请输入车牌号",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.searchForm.plateNo,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchForm, "plateNo", $$v)
                                    },
                                    expression: "searchForm.plateNo",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "进店机构" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    ref: "orgSelect",
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      filterable: "",
                                      placeholder: "请选择进店机构",
                                    },
                                    model: {
                                      value: _vm.searchForm.salesOrgName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "salesOrgName",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.salesOrgName",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-option",
                                      {
                                        staticStyle: {
                                          height: "auto",
                                          "background-color": "white",
                                        },
                                        attrs: {
                                          value: _vm.searchForm.operateOrgId,
                                        },
                                      },
                                      [
                                        _c("el-tree", {
                                          ref: "tree",
                                          attrs: {
                                            "expand-on-click-node": false,
                                            "node-key": "code",
                                            "highlight-current":
                                              _vm.highlightCurrent,
                                            data: _vm.orgData,
                                            props: _vm.defaultProps,
                                            "default-expand-all": "",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "续保状态" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      placeholder: "请选择续保状态",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.searchForm.proposalStatus,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "proposalStatus",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.proposalStatus",
                                    },
                                  },
                                  _vm._l(
                                    _vm.dictSource["INSURED_STATUS_DROPDOWN"],
                                    function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.dictName,
                                          value: item.dictCode,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "进店时间" } },
                              [
                                _c("el-date-picker", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: "请选择进店时间",
                                    type: "date",
                                    format: "yyyy-MM-dd",
                                  },
                                  model: {
                                    value: _vm.startEndTime,
                                    callback: function ($$v) {
                                      _vm.startEndTime = $$v
                                    },
                                    expression: "startEndTime",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c("el-col", { attrs: { span: 24 } }, [
                          _c(
                            "div",
                            { staticClass: "searchBtnStyle" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    icon: "el-icon-refresh-left",
                                  },
                                  on: { click: _vm.resetSearch },
                                },
                                [_vm._v("重置")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    type: "primary",
                                    icon: "el-icon-search",
                                  },
                                  on: { click: _vm.searchData },
                                },
                                [_vm._v("查询")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "table-cls normal-btn-cls" },
          [
            _c(
              "el-card",
              { staticClass: "box-card", attrs: { shadow: "never" } },
              [
                _c(
                  "div",
                  { staticStyle: { position: "relative" } },
                  [
                    _c(
                      "el-tabs",
                      {
                        on: { "tab-click": _vm.handleTabClick },
                        model: {
                          value: _vm.orderStatus,
                          callback: function ($$v) {
                            _vm.orderStatus = $$v
                          },
                          expression: "orderStatus",
                        },
                      },
                      [
                        _c("el-tab-pane", {
                          attrs: { label: "待分配", name: "待分配" },
                        }),
                        _c("el-tab-pane", {
                          attrs: { label: "待跟进", name: "待跟进" },
                        }),
                        _c("el-tab-pane", {
                          attrs: { label: "跟进中", name: "跟进中" },
                        }),
                        _c("el-tab-pane", {
                          attrs: { label: "已回店", name: "已回店" },
                        }),
                        _c("el-tab-pane", {
                          attrs: { label: "战败", name: "战败" },
                        }),
                        _c("el-tab-pane", {
                          attrs: { label: "全部", name: "" },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "table-btn-cls",
                        staticStyle: {
                          position: "absolute",
                          right: "0",
                          bottom: "22px",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary", plain: "" },
                          },
                          [_vm._v("导出")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary", plain: "" },
                            on: { click: _vm.distribution },
                          },
                          [_vm._v("分配")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", plain: "" },
                            on: { click: _vm.loadData },
                          },
                          [_vm._v("刷新")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", plain: "" },
                            on: {
                              click: function ($event) {
                                return _vm.add("add")
                              },
                            },
                          },
                          [_vm._v("新增")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { icon: "el-icon-search", size: "mini" },
                            on: {
                              click: function ($event) {
                                _vm.drawer = true
                              },
                            },
                          },
                          [_vm._v("搜索")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    ref: "multipleTable",
                    staticStyle: { width: "100%" },
                    attrs: {
                      size: "mini",
                      border: "",
                      data: _vm.tableData,
                      height: _vm.tableHeightH,
                      "header-cell-style": { background: "#F7F7F7" },
                      "highlight-current-row": "",
                    },
                    on: {
                      "row-dblclick": _vm.dbSelected,
                      "selection-change": _vm.handleSelectionChange,
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        type: "selection",
                        width: "55",
                        align: "center",
                        fixed: "left",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "jobNumber",
                        label: "工单号",
                        "show-overflow-tooltip": "",
                        fixed: "left",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "plateNo",
                        label: "车牌号",
                        width: "100px",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "modelName",
                        label: "品牌型号",
                        width: "100px",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "customerName",
                        label: "车主名称",
                        width: "100px",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "phone",
                        label: "客户电话",
                        width: "120px",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "carStatus",
                        label: "车辆现状",
                        width: "100px",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("filterCarStatus")(
                                      scope.row.carStatus,
                                      _vm.VehicleStatusList
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "reportAddress",
                        label: "出险地址",
                        width: "100px",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "repairsTime",
                        label: "报案时间",
                        width: "150px",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "insCorpCode",
                        label: "保险公司",
                        width: "100px",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "sourceType",
                        label: "工单来源",
                        width: "120px",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "orderStatusDisplay",
                        label: "工单状态",
                        width: "100px",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "trackStatusDisplay",
                        label: "跟进结果",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "a",
                        label: "下次跟进时间",
                        "min-width": "100px",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "a",
                        label: "维修结果",
                        "min-width": "100px",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "conductorUserId",
                        label: "接车员",
                        "min-width": "100px",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "creatorByName",
                        label: "操作员",
                        "min-width": "100px",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "createdTime",
                        label: "创建时间",
                        "min-width": "100px",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "modifiedTime",
                        label: "操作时间",
                        "min-width": "100px",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "操作",
                        fixed: "right",
                        align: "center",
                        width: "200px",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.orderStatus == "待分配",
                                      expression: "orderStatus=='待分配'",
                                    },
                                  ],
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "mini",
                                        type: "primary",
                                        plain: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.viewDetial(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("查看")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.orderStatus != "战败",
                                          expression: "orderStatus!='战败'",
                                        },
                                      ],
                                      attrs: {
                                        size: "mini",
                                        type: "primary",
                                        plain: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.editOrder(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("编辑")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.orderStatus == "待跟进",
                                      expression: "orderStatus=='待跟进'",
                                    },
                                  ],
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "mini",
                                        type: "primary",
                                        plain: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.viewDetial(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("查看")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "mini",
                                        type: "primary",
                                        plain: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.trackOrder(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("跟进")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.orderStatus != "战败",
                                          expression: "orderStatus!='战败'",
                                        },
                                      ],
                                      attrs: {
                                        size: "mini",
                                        type: "primary",
                                        plain: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.editOrder(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("编辑")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.orderStatus == "跟进中",
                                      expression: "orderStatus=='跟进中'",
                                    },
                                  ],
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "mini",
                                        type: "primary",
                                        plain: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.viewDetial(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("查看")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "mini",
                                        type: "primary",
                                        plain: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.trackOrder(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("跟进")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.orderStatus != "战败",
                                          expression: "orderStatus!='战败'",
                                        },
                                      ],
                                      attrs: {
                                        size: "mini",
                                        type: "primary",
                                        plain: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.editOrder(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("编辑")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.orderStatus == "已回店",
                                      expression: "orderStatus=='已回店'",
                                    },
                                  ],
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "mini",
                                        type: "primary",
                                        plain: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.viewDetial(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("查看")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            scope.row.orderStatusDisplay ==
                                            "待定损",
                                          expression:
                                            "scope.row.orderStatusDisplay=='待定损'",
                                        },
                                      ],
                                      attrs: {
                                        size: "mini",
                                        type: "primary",
                                        plain: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.vehicleLoss(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("定损")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            scope.row.orderStatusDisplay ==
                                            "待交车",
                                          expression:
                                            "scope.row.orderStatusDisplay=='待交车'",
                                        },
                                      ],
                                      attrs: {
                                        size: "mini",
                                        type: "primary",
                                        plain: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.VehicleCar(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("交车")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            scope.row.orderStatusDisplay ==
                                              "待维修" ||
                                            scope.row.orderStatusDisplay ==
                                              "维修中",
                                          expression:
                                            "scope.row.orderStatusDisplay=='待维修'||scope.row.orderStatusDisplay=='维修中'",
                                        },
                                      ],
                                      attrs: {
                                        size: "mini",
                                        type: "primary",
                                        plain: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.repairVehicle(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("维修")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.orderStatus != "战败",
                                          expression: "orderStatus!='战败'",
                                        },
                                      ],
                                      attrs: {
                                        size: "mini",
                                        type: "primary",
                                        plain: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.editOrder(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("编辑")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.orderStatus == "战败",
                                      expression: "orderStatus=='战败'",
                                    },
                                  ],
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "mini",
                                        type: "primary",
                                        plain: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.viewDetial(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("查看")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.orderStatus == "5",
                                      expression: "orderStatus=='5'",
                                    },
                                  ],
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "mini",
                                        type: "primary",
                                        plain: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.viewDetial(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("查看")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            scope.row.orderStatusDisplay ==
                                            "待跟进",
                                          expression:
                                            "scope.row.orderStatusDisplay=='待跟进'",
                                        },
                                      ],
                                      attrs: {
                                        size: "mini",
                                        type: "primary",
                                        plain: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.trackOrder(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("跟进")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            scope.row.orderStatusDisplay ==
                                            "待定损",
                                          expression:
                                            "scope.row.orderStatusDisplay=='待定损'",
                                        },
                                      ],
                                      attrs: {
                                        size: "mini",
                                        type: "primary",
                                        plain: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.vehicleLoss(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("定损")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            scope.row.orderStatusDisplay ==
                                            "待交车",
                                          expression:
                                            "scope.row.orderStatusDisplay=='待交车'",
                                        },
                                      ],
                                      attrs: {
                                        size: "mini",
                                        type: "primary",
                                        plain: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.VehicleCar(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("交车")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            scope.row.orderStatusDisplay ==
                                              "待维修" ||
                                            scope.row.orderStatusDisplay ==
                                              "维修中",
                                          expression:
                                            "scope.row.orderStatusDisplay=='待维修'||scope.row.orderStatusDisplay=='维修中'",
                                        },
                                      ],
                                      attrs: {
                                        size: "mini",
                                        type: "primary",
                                        plain: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.repairVehicle(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("维修")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            scope.row.orderStatusDisplay !=
                                            "已关闭",
                                          expression:
                                            "scope.row.orderStatusDisplay!='已关闭'",
                                        },
                                      ],
                                      attrs: {
                                        size: "mini",
                                        type: "primary",
                                        plain: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.editOrder(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("编辑")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c("pagination", {
                  attrs: {
                    total: _vm.total,
                    page: _vm.listQuery.pageNum,
                    limit: _vm.listQuery.pageSize,
                  },
                  on: {
                    "update:page": function ($event) {
                      return _vm.$set(_vm.listQuery, "pageNum", $event)
                    },
                    "update:limit": function ($event) {
                      return _vm.$set(_vm.listQuery, "pageSize", $event)
                    },
                    pagination: function ($event) {
                      return _vm.loadData()
                    },
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showAddDialog,
            "close-on-click-modal": false,
            width: "720px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showAddDialog = $event
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("创建工单")]
              ),
            ]
          ),
          _c("AddDialog", {
            attrs: { dialogType: _vm.dialogType },
            on: { closePage: _vm.loadData },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showDistributionDialog,
            "close-on-click-modal": false,
            width: "540px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDistributionDialog = $event
            },
            handleClose: function ($event) {
              _vm.showDistributionDialog = false
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("分配接车员")]
              ),
            ]
          ),
          _c("div", { staticClass: "app-m-cls", staticStyle: {} }, [
            _c(
              "div",
              {
                ref: "header",
                staticClass: "header-cls",
                staticStyle: { "max-height": "500px", overflow: "auto" },
              },
              [
                _c(
                  "el-card",
                  { attrs: { shadow: "never" } },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "form",
                        attrs: {
                          model: _vm.searchForm,
                          "label-width": "110px",
                          size: "mini",
                        },
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "公司" } },
                          [
                            _c("el-input", {
                              attrs: { disabled: "" },
                              model: {
                                value: _vm.OrgName,
                                callback: function ($$v) {
                                  _vm.OrgName = $$v
                                },
                                expression: "OrgName",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "接车员" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  placeholder: "请选择接车员",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.conductorUserId,
                                  callback: function ($$v) {
                                    _vm.conductorUserId = $$v
                                  },
                                  expression: "conductorUserId",
                                },
                              },
                              _vm._l(_vm.conductorUserlist, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.userName,
                                    value: item.userId,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "justify-content": "center",
                  "margin-top": "8px",
                },
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      icon: "el-icon-check",
                      type: "primary",
                      plain: "",
                      size: "mini",
                    },
                    on: { click: _vm.distributionClicked },
                  },
                  [_vm._v("保存")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { icon: "el-icon-close", size: "mini" },
                    on: { click: _vm.closeDistributionOrder },
                  },
                  [_vm._v("关闭")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showTrackDialog,
            "close-on-click-modal": false,
            width: "450px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showTrackDialog = $event
            },
            handleClose: function ($event) {
              _vm.showTrackDialog = false
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("更新跟进进度")]
              ),
            ]
          ),
          _c("TrackDialog", {
            attrs: {
              trackOrderId: _vm.trackOrderId,
              refresh: _vm.showTrackDialog,
            },
            on: { closePage: _vm.loadData },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showEditDialog,
            "close-on-click-modal": false,
            width: "720px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showEditDialog = $event
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("留修工单信息")]
              ),
            ]
          ),
          _c("EditDialog", {
            attrs: { editOrderId: _vm.editOrderId },
            on: { closePage: _vm.loadData },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showLossDialog,
            "close-on-click-modal": false,
            width: "540px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showLossDialog = $event
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("车辆定损")]
              ),
            ]
          ),
          _c("LossDialog", {
            attrs: {
              vehicleLossId: _vm.vehicleLossId,
              refresh: _vm.showLossDialog,
            },
            on: { closePage: _vm.loadData },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showRepairDialog,
            "close-on-click-modal": false,
            width: "540px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showRepairDialog = $event
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("更新维修进度")]
              ),
            ]
          ),
          _c("RepairDialog", {
            attrs: { RepairId: _vm.RepairId, refresh: _vm.showRepairDialog },
            on: { closePage: _vm.loadData },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showVehicleDialog,
            "close-on-click-modal": false,
            width: "540px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showVehicleDialog = $event
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("交车确认")]
              ),
            ]
          ),
          _c("VehicleCarDialog", {
            attrs: {
              VehicleCarId: _vm.VehicleCarId,
              refresh: _vm.showVehicleDialog,
            },
            on: { closePage: _vm.loadData },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showCloseDialog,
            "close-on-click-modal": false,
            width: "540px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showCloseDialog = $event
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("关闭工单")]
              ),
            ]
          ),
          _c("CloseDialog", { on: { closePage: _vm.loadData } }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showDetialDialog,
            "close-on-click-modal": false,
            width: "720px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDetialDialog = $event
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("工单详情")]
              ),
            ]
          ),
          _c("DetialDialog", {
            attrs: {
              StudyAbroadType: _vm.StudyAbroadType,
              conductorUserlist: _vm.conductorUserlist,
              orderId: _vm.orderId,
              refresh: _vm.showDetialDialog,
            },
            on: { closePage: _vm.loadData },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }