import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import customersRequest from '../../../api/customers';
import Rules from '@/utils/rules';
import { limitInput, character } from "@/utils/common";
import CarBrands from '@/components/CarBrands/index';
export default {
  components: {
    CarBrands: CarBrands
  },
  props: {
    currentData: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      carTypeVal: [],
      VI_CERTIFICATE_TYPE: [],
      USE_ATTRIBUTE: [],
      ENGRGY_TYPES: [],
      PLATE_TYPE: [],
      VEHICLE_KINDS: [],
      form: {},
      checked: false,
      isDisabled: false,
      listLoading: false,
      refreshing: false,
      customerId: this.$route.query.customerId,
      formRules: {
        ownerName: {
          required: true,
          message: '请输入车主',
          trigger: 'blur'
        },
        plateNo: {
          required: true,
          pattern: Rules["PLATENO_RULE"].value,
          message: '请输入车牌号',
          trigger: 'blur'
        },
        vin: [{
          required: true,
          pattern: Rules["VIN_RULE"].value,
          message: '请输入车架号',
          trigger: 'blur'
        }, {
          pattern: Rules["VIN_RULE"].value,
          message: "车架号格式有误",
          trigger: ["blur", "change"]
        }],
        engineNo: [{
          required: true,
          message: "请输入发动机号",
          trigger: "blur"
        }, {
          pattern: Rules["ENGINENO_RULE"].value,
          message: "发动机号格式有误",
          trigger: "change"
        }],
        ownerCertificateNo: [{
          required: true,
          message: '请输入证件号',
          trigger: 'blur'
        }]
      },
      disabledLessThan: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > Date.now();
        }
      }
    };
  },
  watch: {
    currentData: function currentData(val, val2) {
      this.form = _.cloneDeep(val);
      this.carTypeVal = [this.form.cjVhcBrandNo, this.form.cjVhcSeriesNo];
      this.form.modelName = this.form.modelName ? this.form.modelName : '';
      this.form.plateNo = this.form.plateNo !== '未上牌' ? this.form.plateNo : '';
      if (this.form.plateNo && this.form.plateNo !== '未上牌') {
        this.checked = false;
        this.formRules.plateNo.required = true;
      } else {
        this.checked = true;
        this.formRules.plateNo.required = false;
      }
    },
    immediate: true,
    deep: true
  },
  mounted: function mounted() {
    this.VI_CERTIFICATE_TYPE = JSON.parse(localStorage.getItem('VI_CERTIFICATE_TYPE')) || [];
    var temp = [{
      label: '个人',
      options: []
    }, {
      label: '企业/机关',
      options: []
    }];
    this.VI_CERTIFICATE_TYPE.forEach(function (item) {
      if (item.dictName === "组织机构代码证" || item.dictName === "统一社会信用代码") {
        temp[1].options.push({
          dictCode: item.dictCode,
          dictName: item.dictName
        });
      } else {
        temp[0].options.push({
          dictCode: item.dictCode,
          dictName: item.dictName
        });
      }
    });
    this.VI_CERTIFICATE_TYPE = temp;
    this.USE_ATTRIBUTE = JSON.parse(localStorage.getItem("VI_USE_ATTRIBUTE")) || [];
    var engrgy = JSON.parse(localStorage.getItem("VI_ENGRGY_TYPE")) || [];
    this.ENGRGY_TYPES = this.ENGRGY_TYPE = _.filter(engrgy, function (o) {
      return ["0", "1", "2", "3", "4"].includes(o.dictCode);
    });
    this.PLATE_TYPE = JSON.parse(localStorage.getItem("VI_PLATE_TYPE")) || [];
    this.VEHICLE_KINDS = this.VEHICLE_KIND = JSON.parse(localStorage.getItem("VI_VEHICLE_KIND")) || [];

    // let customerId = this.$route.query.customerId;
    // customersRequest.getDetail(this.customerId).then(res => {
    //     this.form = res.data;
    //     this.carTypeVal = [res.data.cjVhcBrandNo, res.data.cjVhcSeriesNo];
    //     this.form.modelName = this.form.modelName ? this.form.modelName : '';
    //     this.form.plateNo = this.form.plateNo !== '未上牌' ? this.form.plateNo : '';
    //     if (this.form.plateNo && this.form.plateNo !== '未上牌') {
    //         this.checked = false;
    //         this.formRules.plateNo.required = true
    //     } else {
    //         this.checked = true;
    //         this.formRules.plateNo.required = false
    //     }
    // })
  },

  methods: {
    // 监听数据变化
    CarHandleChange: function CarHandleChange(val) {
      this.form.cjVhcBrandNo = val[0]; // 厂家品牌编号
      this.form.cjVhcSeriesNo = val[1]; //   厂家车系编号
      this.carTypeVal = val;
      // cjVhcModelNo: val[2], //  厂家车型编号
    },
    onInput: function onInput(num, min, max, limit) {
      //对输入内容做精度校验
      this.$forceUpdate();
      if (limit > 0) {
        if (min && parseFloat(num) < parseFloat(min)) {
          num = min;
        }
        if (max && parseFloat(num) > parseFloat(max)) {
          num = max;
        }
      } else {
        if (min && num < min) {
          num = min;
        }
        if (max && num > max) {
          num = max;
        }
      }
      return limitInput(num, limit);
    },
    noPlateNoChange: function noPlateNoChange(val) {
      if (val) {
        this.form.plateNo = '';
        this.formRules.plateNo.required = false;
        this.$refs.form.clearValidate('plateNo');
      } else {
        this.formRules.plateNo.required = true;
      }
      ;
      this.$forceUpdate();
    },
    save: function save() {
      var _this = this;
      //保存车俩信息
      this.$refs['form'].validate(function (valid, obj) {
        if (valid) {
          var postData = _objectSpread({}, _this.form);
          delete postData.renewalType;
          delete postData.renewalTypeDisplay;
          _this.listLoading = true;
          customersRequest.updateVehicleInfo(_this.customerId, postData).then(function (res) {
            _this.listLoading = false;
            if (res.code == 200) {
              _this.$message.success(res.msg);
              _this.$emit('callback');
            }
            // this.closePage()
          });
        }
      });
    },
    getVehicleSalesData: function getVehicleSalesData() {
      var _this2 = this;
      if (!this.form.vin) {
        this.$message.warning('车架号不能为空');
        return;
      }
      ;
      this.refreshing = true;
      var vehicleData = {
        vin: this.form.vin || '',
        cacheSwitch: true
      };
      customersRequest.getVehicleSales(vehicleData).then(function (res) {
        _this2.refreshing = false;
        if (res.code == 200) {
          _this2.$message.success(res.msg);
          _this2.form.sellDate = res.data ? res.data.salesDate : '';
        }
        ;
      }).catch(function (err) {
        _this2.refreshing = false;
      });
    },
    inputEngineNo: function inputEngineNo(val) {
      //发动机号自动转大写
      val = val.replace(/[^A-Za-z0-9-]/g, '');
      this.form.engineNo = _.toUpper(val);
    }
  }
};