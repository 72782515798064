var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "PageContainer",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      attrs: {
        "element-loading-text": "加载中",
        "element-loading-spinner": "el-icon-loading",
      },
    },
    [
      _c("div", { attrs: { slot: "main" }, slot: "main" }, [
        _c(
          "div",
          {
            staticClass: "app-m-cls",
            staticStyle: { "min-height": "calc(100vh - 130px)" },
          },
          [
            _c(
              "div",
              { ref: "header", staticClass: "header-cls" },
              [
                _c(
                  "el-form",
                  {
                    staticStyle: { "margin-top": "10px" },
                    attrs: { model: _vm.form, "label-width": "140px" },
                  },
                  [
                    _c(
                      "el-collapse",
                      {
                        staticStyle: { "margin-top": "10px" },
                        model: {
                          value: _vm.activeOne,
                          callback: function ($$v) {
                            _vm.activeOne = $$v
                          },
                          expression: "activeOne",
                        },
                      },
                      [
                        _c(
                          "el-collapse-item",
                          {
                            staticClass: "collapse-item-style",
                            attrs: { name: "1" },
                          },
                          [
                            _c("template", { slot: "title" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "title",
                                  staticStyle: { "line-height": "25px" },
                                },
                                [
                                  _c("FormLabel", {
                                    attrs: { "form-label": "订单信息" },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c(
                              "el-form",
                              {
                                staticStyle: { "margin-top": "10px" },
                                attrs: {
                                  model: _vm.form,
                                  "label-width": "145px",
                                },
                              },
                              [
                                _c(
                                  "el-row",
                                  { attrs: { gutter: 0 } },
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 8,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "经销商：" } },
                                          [
                                            _c("el-input", {
                                              attrs: { disabled: "" },
                                              model: {
                                                value: _vm.form.dealerCode,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "dealerCode",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.dealerCode",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 8,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "销售经销商：" } },
                                          [
                                            _c("el-input", {
                                              attrs: { disabled: "" },
                                              model: {
                                                value: _vm.form.salesDealerCode,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "salesDealerCode",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.salesDealerCode",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 8,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "维修经销商：" } },
                                          [
                                            _c("el-input", {
                                              attrs: { disabled: "" },
                                              model: {
                                                value: _vm.form.crDealerCode,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "crDealerCode",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.crDealerCode",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 8,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "订单号：" } },
                                          [
                                            _c("el-input", {
                                              attrs: { disabled: "" },
                                              model: {
                                                value: _vm.form.orderNo,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "orderNo",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.orderNo",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 8,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "订单状态：" } },
                                          [
                                            _c("el-input", {
                                              attrs: { disabled: "" },
                                              model: {
                                                value: _vm.form.orderStatus,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "orderStatus",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.orderStatus",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 8,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "送修问题：" } },
                                          [
                                            _c("el-input", {
                                              attrs: { disabled: "" },
                                              model: {
                                                value: _vm.form.repairReason,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "repairReason",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.repairReason",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 8,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "检查结果：" } },
                                          [
                                            _c("el-input", {
                                              attrs: { disabled: "" },
                                              model: {
                                                value:
                                                  _vm.form.inspectionResult,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "inspectionResult",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.inspectionResult",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 8,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "维修标识：" } },
                                          [
                                            _c("el-input", {
                                              attrs: { disabled: "" },
                                              model: {
                                                value: _vm.form.pauseFlag,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "pauseFlag",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.pauseFlag",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 8,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "下次维修预定里程：",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: { disabled: "" },
                                              model: {
                                                value: _vm.form.nextSrvMileage,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "nextSrvMileage",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.nextSrvMileage",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 8,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "定保通销售单号：",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: { disabled: "" },
                                              model: {
                                                value: _vm.form.patSaleNo,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "patSaleNo",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.patSaleNo",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 8,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "定保通定期保养代码：",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: { disabled: "" },
                                              model: {
                                                value:
                                                  _vm.form.patMainTenAnceCode,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "patMainTenAnceCode",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.patMainTenAnceCode",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 8,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "质检时间：" } },
                                          [
                                            _c("el-input", {
                                              attrs: { disabled: "" },
                                              model: {
                                                value: _vm.form.inspectionTime,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "inspectionTime",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.inspectionTime",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 8,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: { label: "顾客到店时间：" },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: { disabled: "" },
                                              model: {
                                                value: _vm.form.arriveTime,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "arriveTime",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.arriveTime",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 8,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: { label: "承诺预交时间：" },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: { disabled: "" },
                                              model: {
                                                value:
                                                  _vm.form.promiseDeliveryTime,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "promiseDeliveryTime",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.promiseDeliveryTime",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 8,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: { label: "变更交车时间：" },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: { disabled: "" },
                                              model: {
                                                value:
                                                  _vm.form.changeDeliveryTime,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "changeDeliveryTime",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.changeDeliveryTime",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 8,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "回访方式：" } },
                                          [
                                            _c("el-input", {
                                              attrs: { disabled: "" },
                                              model: {
                                                value: _vm.form.returnVisitType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "returnVisitType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.returnVisitType",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 8,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "回访时间：" } },
                                          [
                                            _c("el-input", {
                                              attrs: { disabled: "" },
                                              model: {
                                                value: _vm.form.returnVisitTime,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "returnVisitTime",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.returnVisitTime",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 8,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "创建人：" } },
                                          [
                                            _c("el-input", {
                                              attrs: { disabled: "" },
                                              model: {
                                                value: _vm.form.creUser,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "creUser",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.creUser",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 8,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "创建时间:：" } },
                                          [
                                            _c("el-input", {
                                              attrs: { disabled: "" },
                                              model: {
                                                value: _vm.form.creDate,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "creDate",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.creDate",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 8,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "订单日期：" } },
                                          [
                                            _c("el-input", {
                                              attrs: { disabled: "" },
                                              model: {
                                                value: _vm.form.orderDate,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "orderDate",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.orderDate",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 8,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: { label: "下次维修日期：" },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: { disabled: "" },
                                              model: {
                                                value: _vm.form.nextSrvDate,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "nextSrvDate",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.nextSrvDate",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 8,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: { label: "订单取消日期：" },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: { disabled: "" },
                                              model: {
                                                value: _vm.form.orderCancelDate,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "orderCancelDate",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.orderCancelDate",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-collapse",
                      {
                        staticStyle: { "margin-top": "10px" },
                        model: {
                          value: _vm.activeTwo,
                          callback: function ($$v) {
                            _vm.activeTwo = $$v
                          },
                          expression: "activeTwo",
                        },
                      },
                      [
                        _c(
                          "el-collapse-item",
                          {
                            staticClass: "collapse-item-style",
                            attrs: { name: "1" },
                          },
                          [
                            _c("template", { slot: "title" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "title",
                                  staticStyle: { "line-height": "25px" },
                                },
                                [
                                  _c("FormLabel", {
                                    attrs: { "form-label": "支付信息" },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c(
                              "el-row",
                              { attrs: { gutter: 0 } },
                              [
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "支付金额：" } },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value: _vm.form.salesPayment,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "salesPayment",
                                                $$v
                                              )
                                            },
                                            expression: "form.salesPayment",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "顾客付款金额：" } },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value: _vm.form.custChargeAmount,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "custChargeAmount",
                                                $$v
                                              )
                                            },
                                            expression: "form.custChargeAmount",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "结算方式：" } },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value: _vm.form.settlementType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "settlementType",
                                                $$v
                                              )
                                            },
                                            expression: "form.settlementType",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "零件折扣：" } },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value: _vm.form.partsListDiscount,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "partsListDiscount",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.partsListDiscount",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "零件付款金额：" } },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value:
                                              _vm.form.partsListChargeAmount,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "partsListChargeAmount",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.partsListChargeAmount",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "保修方金额：" } },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value: _vm.form.warraChargeAmount,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "warraChargeAmount",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.warraChargeAmount",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "付款方金额：" } },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value: _vm.form.chargeAmount1,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "chargeAmount1",
                                                $$v
                                              )
                                            },
                                            expression: "form.chargeAmount1",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "付款方金额2：" } },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value: _vm.form.chargeAmount2,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "chargeAmount2",
                                                $$v
                                              )
                                            },
                                            expression: "form.chargeAmount2",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "付款方金额3：" } },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value: _vm.form.chargeAmount3,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "chargeAmount3",
                                                $$v
                                              )
                                            },
                                            expression: "form.chargeAmount3",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-collapse",
                      {
                        staticStyle: { "margin-top": "10px" },
                        model: {
                          value: _vm.activeThree,
                          callback: function ($$v) {
                            _vm.activeThree = $$v
                          },
                          expression: "activeThree",
                        },
                      },
                      [
                        _c(
                          "el-collapse-item",
                          {
                            staticClass: "collapse-item-style",
                            attrs: { name: "1" },
                          },
                          [
                            _c("template", { slot: "title" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "title",
                                  staticStyle: { "line-height": "25px" },
                                },
                                [
                                  _c("FormLabel", {
                                    attrs: { "form-label": "客户信息" },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c(
                              "el-row",
                              { attrs: { gutter: 0 } },
                              [
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "客户ID：" } },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value: _vm.form.customerId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "customerId",
                                                $$v
                                              )
                                            },
                                            expression: "form.customerId",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "客户状态：" } },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value: _vm.form.customerStatusType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "customerStatusType",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.customerStatusType",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "客户姓名：" } },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value: _vm.form.customerName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "customerName",
                                                $$v
                                              )
                                            },
                                            expression: "form.customerName",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "电话号码：" } },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value: _vm.form.tel1,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.form, "tel1", $$v)
                                            },
                                            expression: "form.tel1",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "其他电话：" } },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value: _vm.form.tel2,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.form, "tel2", $$v)
                                            },
                                            expression: "form.tel2",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "电子邮箱：" } },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value: _vm.form.email,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.form, "email", $$v)
                                            },
                                            expression: "form.email",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "邮政编码：" } },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value: _vm.form.customerZipCode,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "customerZipCode",
                                                $$v
                                              )
                                            },
                                            expression: "form.customerZipCode",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 16,
                                      md: 16,
                                      lg: 16,
                                      xl: 16,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "客户地址：" } },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value: _vm.form.customerAddr,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "customerAddr",
                                                $$v
                                              )
                                            },
                                            expression: "form.customerAddr",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-collapse",
                      {
                        staticStyle: { "margin-top": "10px" },
                        model: {
                          value: _vm.activeFour,
                          callback: function ($$v) {
                            _vm.activeFour = $$v
                          },
                          expression: "activeFour",
                        },
                      },
                      [
                        _c(
                          "el-collapse-item",
                          {
                            staticClass: "collapse-item-style",
                            attrs: { name: "1" },
                          },
                          [
                            _c("template", { slot: "title" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "title",
                                  staticStyle: { "line-height": "25px" },
                                },
                                [
                                  _c("FormLabel", {
                                    attrs: { "form-label": "修车人信息" },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c(
                              "el-row",
                              { attrs: { gutter: 0 } },
                              [
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "名称：" } },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value: _vm.form.srvCustomName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "srvCustomName",
                                                $$v
                                              )
                                            },
                                            expression: "form.srvCustomName",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "证件类型：" } },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value:
                                              _vm.form.srvCustomerCertiType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "srvCustomerCertiType",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.srvCustomerCertiType",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "证件号：" } },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value: _vm.form.srvCustomId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "srvCustomId",
                                                $$v
                                              )
                                            },
                                            expression: "form.srvCustomId",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "手机号码：" } },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value: _vm.form.srvCustomerMobil1,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "srvCustomerMobil1",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.srvCustomerMobil1",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "其他手机号码：" } },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value: _vm.form.srvCustomerMobil2,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "srvCustomerMobil2",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.srvCustomerMobil2",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "修车人种类：" } },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value: _vm.form.srvCustomIdType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "srvCustomIdType",
                                                $$v
                                              )
                                            },
                                            expression: "form.srvCustomIdType",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "购车人种类：" } },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value: _vm.form.customerIdType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "customerIdType",
                                                $$v
                                              )
                                            },
                                            expression: "form.customerIdType",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "购车人证件类型：" } },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value: _vm.form.customerCertiType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "customerCertiType",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.customerCertiType",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 16,
                                      md: 16,
                                      lg: 16,
                                      xl: 16,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "地址：" } },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value: _vm.form.srvCustomAddr,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "srvCustomAddr",
                                                $$v
                                              )
                                            },
                                            expression: "form.srvCustomAddr",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-collapse",
                      {
                        staticStyle: { "margin-top": "10px" },
                        model: {
                          value: _vm.activeFive,
                          callback: function ($$v) {
                            _vm.activeFive = $$v
                          },
                          expression: "activeFive",
                        },
                      },
                      [
                        _c(
                          "el-collapse-item",
                          {
                            staticClass: "collapse-item-style",
                            attrs: { name: "1" },
                          },
                          [
                            _c("template", { slot: "title" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "title",
                                  staticStyle: { "line-height": "25px" },
                                },
                                [
                                  _c("FormLabel", {
                                    attrs: { "form-label": "车辆信息" },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c(
                              "el-row",
                              { attrs: { gutter: 0 } },
                              [
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "车牌号：" } },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value: _vm.form.registerNo,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "registerNo",
                                                $$v
                                              )
                                            },
                                            expression: "form.registerNo",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "车架号：" } },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value: _vm.form.vinNo,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.form, "vinNo", $$v)
                                            },
                                            expression: "form.vinNo",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "发动机号：" } },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value: _vm.form.engineNo,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "engineNo",
                                                $$v
                                              )
                                            },
                                            expression: "form.engineNo",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "车型：" } },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value: _vm.form.model,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.form, "model", $$v)
                                            },
                                            expression: "form.model",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "车身颜色：" } },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value: _vm.form.color,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.form, "color", $$v)
                                            },
                                            expression: "form.color",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "车辆类型：" } },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value: _vm.form.vhcType,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.form, "vhcType", $$v)
                                            },
                                            expression: "form.vhcType",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "排气量(L)：" } },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value: _vm.form.exhaust,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.form, "exhaust", $$v)
                                            },
                                            expression: "form.exhaust",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "变速箱：" } },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value: _vm.form.exhaust,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.form, "exhaust", $$v)
                                            },
                                            expression: "form.exhaust",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "行驶里程：" } },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value: _vm.form.mileage,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.form, "mileage", $$v)
                                            },
                                            expression: "form.mileage",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "车辆销售日期：" } },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value: _vm.form.salesDate,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "salesDate",
                                                $$v
                                              )
                                            },
                                            expression: "form.salesDate",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "客户类别：" } },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              placeholder: "请选择客户类别",
                                              clearable: "",
                                            },
                                            on: {
                                              clear: function ($event) {
                                                return _vm.clearChanged(
                                                  "customerType"
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.form.customerType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "customerType",
                                                  $$v
                                                )
                                              },
                                              expression: "form.customerType",
                                            },
                                          },
                                          _vm._l(
                                            _vm.customerTypesList,
                                            function (item, index) {
                                              return _c("el-option", {
                                                key: index,
                                                attrs: {
                                                  label: item.label,
                                                  value: item.value,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "交强险保险公司：" } },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              placeholder:
                                                "请选择交强险保险公司",
                                              clearable: "",
                                            },
                                            on: {
                                              clear: function ($event) {
                                                return _vm.clearChanged(
                                                  "bzInsCorpCode"
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.form.bzInsCorpCode,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "bzInsCorpCode",
                                                  $$v
                                                )
                                              },
                                              expression: "form.bzInsCorpCode",
                                            },
                                          },
                                          _vm._l(
                                            _vm.supplyers,
                                            function (item, index) {
                                              return _c("el-option", {
                                                key: index,
                                                attrs: {
                                                  label: item.shortName,
                                                  value: item.code,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "交强险到期日：" } },
                                      [
                                        _c("el-date-picker", {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            format: "yyyy-MM-dd",
                                            "value-format": "yyyy-MM-dd",
                                            type: "date",
                                            placeholder: "请选择交强险到期日",
                                            clearable: "",
                                          },
                                          on: {
                                            clear: function ($event) {
                                              return _vm.clearChanged(
                                                "bzExpireDate"
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.form.bzExpireDate,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "bzExpireDate",
                                                $$v
                                              )
                                            },
                                            expression: "form.bzExpireDate",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "商业险保险公司：" } },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              placeholder:
                                                "请选择商业险保险公司",
                                              clearable: "",
                                            },
                                            on: {
                                              clear: function ($event) {
                                                return _vm.clearChanged(
                                                  "bsInsCorpCode"
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.form.bsInsCorpCode,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "bsInsCorpCode",
                                                  $$v
                                                )
                                              },
                                              expression: "form.bsInsCorpCode",
                                            },
                                          },
                                          _vm._l(
                                            _vm.supplyers,
                                            function (item, index) {
                                              return _c("el-option", {
                                                key: index,
                                                attrs: {
                                                  label: item.shortName,
                                                  value: item.code,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "商业险到期日：" } },
                                      [
                                        _c("el-date-picker", {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            format: "yyyy-MM-dd",
                                            "value-format": "yyyy-MM-dd",
                                            type: "date",
                                            placeholder: "请选择商业险到期日",
                                            clearable: "",
                                          },
                                          on: {
                                            clear: function ($event) {
                                              return _vm.clearChanged(
                                                "bsExpireDate"
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.form.bsExpireDate,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "bsExpireDate",
                                                $$v
                                              )
                                            },
                                            expression: "form.bsExpireDate",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-collapse",
                      {
                        staticStyle: { "margin-top": "10px" },
                        model: {
                          value: _vm.activeSex,
                          callback: function ($$v) {
                            _vm.activeSex = $$v
                          },
                          expression: "activeSex",
                        },
                      },
                      [
                        _c(
                          "el-collapse-item",
                          {
                            staticClass: "collapse-item-style",
                            attrs: { name: "1" },
                          },
                          [
                            _c("template", { slot: "title" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "title",
                                  staticStyle: { "line-height": "25px" },
                                },
                                [
                                  _c("FormLabel", {
                                    attrs: { "form-label": "保养信息" },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c(
                              "el-row",
                              { attrs: { gutter: 0 } },
                              [
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "保养唯一码：" } },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value: _vm.form.maintainOnlyCode,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "maintainOnlyCode",
                                                $$v
                                              )
                                            },
                                            expression: "form.maintainOnlyCode",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "促销来店标识：" } },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value: _vm.form.salesPromotionFlag,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "salesPromotionFlag",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.salesPromotionFlag",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "服务产品标识：" } },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value: _vm.form.serviceProductFlag,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "serviceProductFlag",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.serviceProductFlag",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "确认项目：" } },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value: _vm.form.confirmItem,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "confirmItem",
                                                $$v
                                              )
                                            },
                                            expression: "form.confirmItem",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "中断时常(天)：" } },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value: _vm.form.interruptDay,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "interruptDay",
                                                $$v
                                              )
                                            },
                                            expression: "form.interruptDay",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "中断时常(时)：" } },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value: _vm.form.interruptHour,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "interruptHour",
                                                $$v
                                              )
                                            },
                                            expression: "form.interruptHour",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "中断时常(分)：" } },
                                      [
                                        _c("el-input", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value: _vm.form.interruptMinute,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "interruptMinute",
                                                $$v
                                              )
                                            },
                                            expression: "form.interruptMinute",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-collapse",
                      {
                        staticStyle: { "margin-top": "10px" },
                        model: {
                          value: _vm.activeSeven,
                          callback: function ($$v) {
                            _vm.activeSeven = $$v
                          },
                          expression: "activeSeven",
                        },
                      },
                      [
                        _c(
                          "el-collapse-item",
                          {
                            staticClass: "collapse-item-style",
                            attrs: { name: "1" },
                          },
                          [
                            _c("template", { slot: "title" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "title",
                                  staticStyle: { "line-height": "25px" },
                                },
                                [
                                  _c("FormLabel", {
                                    attrs: { "form-label": "维修信息" },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c(
                              "div",
                              { staticStyle: { margin: "0px 30px" } },
                              [
                                _c(
                                  "el-tabs",
                                  {
                                    model: {
                                      value: _vm.tabType,
                                      callback: function ($$v) {
                                        _vm.tabType = $$v
                                      },
                                      expression: "tabType",
                                    },
                                  },
                                  [
                                    _c("el-tab-pane", {
                                      attrs: {
                                        label: "维修记录",
                                        name: "REPAIR",
                                      },
                                    }),
                                    _c("el-tab-pane", {
                                      attrs: {
                                        label: "零件信息",
                                        name: "PART",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm.tabType == "REPAIR" &&
                                !_vm._.isEmpty(_vm.form.maintenanceList)
                                  ? _c(
                                      "table",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: { border: "1" },
                                      },
                                      [
                                        _c("colgroup", [
                                          _c("col", {
                                            staticStyle: { width: "25%" },
                                          }),
                                          _c("col", {
                                            staticStyle: { width: "50%" },
                                          }),
                                          _c("col", {
                                            staticStyle: { width: "25%" },
                                          }),
                                        ]),
                                        _c(
                                          "thead",
                                          {
                                            staticStyle: {
                                              "background-color": "#eeee",
                                            },
                                          },
                                          [
                                            _c(
                                              "tr",
                                              {
                                                staticStyle: {
                                                  "font-size": "13px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "col" } },
                                                  [_vm._v("维修编码")]
                                                ),
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "col" } },
                                                  [_vm._v("维修名称")]
                                                ),
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "col" } },
                                                  [_vm._v("作业时间")]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "tbody",
                                          _vm._l(
                                            _vm.form.maintenanceList,
                                            function (item, index) {
                                              return _c("tr", { key: index }, [
                                                _c("td", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.typicalSrvType
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                                _c("td", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.srvGroupName
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                                _c("td", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.workHours) +
                                                      " "
                                                  ),
                                                ]),
                                              ])
                                            }
                                          ),
                                          0
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.tabType == "REPAIR" &&
                                _vm._.isEmpty(_vm.form.maintenanceList)
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          padding: "15px",
                                          "min-height": "100px",
                                          display: "flex",
                                          "align-items": "center",
                                          "justify-content": "center",
                                        },
                                      },
                                      [_vm._v(" 暂无维修记录！ ")]
                                    )
                                  : _vm._e(),
                                _vm.tabType == "PART" &&
                                !_vm._.isEmpty(_vm.form.partsList)
                                  ? _c(
                                      "table",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: { border: "1" },
                                      },
                                      [
                                        _c("colgroup", [
                                          _c("col", {
                                            staticStyle: { width: "50%" },
                                          }),
                                          _c("col", {
                                            staticStyle: { width: "25%" },
                                          }),
                                          _c("col", {
                                            staticStyle: { width: "25%" },
                                          }),
                                          _c("col"),
                                        ]),
                                        _c(
                                          "thead",
                                          {
                                            staticStyle: {
                                              "background-color": "#eeee",
                                            },
                                          },
                                          [
                                            _c(
                                              "tr",
                                              {
                                                staticStyle: {
                                                  "font-size": "13px",
                                                  height: "40px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "col" } },
                                                  [_vm._v("零件名称")]
                                                ),
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "col" } },
                                                  [_vm._v("零件原价")]
                                                ),
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "col" } },
                                                  [_vm._v("零件售出价格")]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "tbody",
                                          _vm._l(
                                            _vm.form.partsList,
                                            function (item, index) {
                                              return _c("tr", { key: index }, [
                                                _c("td", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.partsName) +
                                                      " "
                                                  ),
                                                ]),
                                                _c("td", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.price) +
                                                      " "
                                                  ),
                                                ]),
                                                _c("td", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.sellPrice) +
                                                      " "
                                                  ),
                                                ]),
                                              ])
                                            }
                                          ),
                                          0
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.tabType == "PART" &&
                                _vm._.isEmpty(_vm.form.partsList)
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          padding: "15px",
                                          "min-height": "100px",
                                          display: "flex",
                                          "align-items": "center",
                                          "justify-content": "center",
                                        },
                                      },
                                      [_vm._v(" 暂无零件信息！ ")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "el-row",
                              { staticStyle: { margin: "15px 30px 15px 0px" } },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 24 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "维修备注：" } },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            type: "textarea",
                                            row: "10",
                                            disabled: "",
                                          },
                                          model: {
                                            value: _vm.form.srvMemo,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.form, "srvMemo", $$v)
                                            },
                                            expression: "form.srvMemo",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 24 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "建议：" } },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            type: "textarea",
                                            row: "10",
                                            disabled: "",
                                          },
                                          model: {
                                            value: _vm.form.onePointAdvice,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "onePointAdvice",
                                                $$v
                                              )
                                            },
                                            expression: "form.onePointAdvice",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
      ]),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c("div", { staticClass: "footer-btn" }, [
          _c(
            "div",
            { staticClass: "footer-btn-list" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini", icon: "el-icon-back" },
                  on: { click: _vm.goBack },
                },
                [_vm._v("返回")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    type: "primary",
                    icon: "el-icon-check",
                  },
                  on: { click: _vm.onSave },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }