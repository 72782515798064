import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import gatewayConfigRequest from '@/api/gatewayConfig';
import initHeight from '../../../mixins/initHeight';
import Pagination from '@/components/Pagination';
import RouteGroupDialog from './dialog/routeGroupDialog';
import { commonExport } from '@/utils/common';
export default {
  name: "routingGroupManage",
  mixins: [initHeight],
  components: {
    Pagination: Pagination,
    RouteGroupDialog: RouteGroupDialog
  },
  data: function data() {
    return {
      DialogType: null,
      DialogId: null,
      drawer: true,
      loading: true,
      showAddDialog: false,
      routerTypeList: [{
        routerName: 'GET'
      }, {
        routerName: 'POST'
      }, {
        routerName: 'PUT'
      }, {
        routerName: 'DELETE'
      }],
      searchForm: {
        name: ''
      },
      tableData: [],
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      multipleSelection: []
    };
  },
  created: function created() {
    this.getRouteGroupList(); //获取数据列表
  },

  methods: {
    // 导出
    exportFn: function exportFn() {
      var exporParams = {
        total: this.total,
        fileName: '路由组管理列表',
        exportUrl: '/tp-core-service/apiGroup/search',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    getRouteGroupList: function getRouteGroupList() {
      var _this = this;
      //获取数据列表
      this.loading = true;
      var _this$listQuery = this.listQuery,
        pageNum = _this$listQuery.pageNum,
        pageSize = _this$listQuery.pageSize;
      this.searchForm.pageNum = pageNum;
      this.searchForm.pageSize = pageSize;
      gatewayConfigRequest.RouteGroupLists(this.searchForm).then(function (res) {
        if (res.code == 200) {
          _this.loading = false;
          _this.tableData = res.data && res.data.list ? res.data.list : [];
          _this.total = res.data.page.recordsTotal;
        }
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      //表格选中操作
      this.multipleSelection = val;
    },
    batchDeletion: function batchDeletion() {
      var _this2 = this;
      //批量删除
      this.$confirm('是否确认删除, 确认删除后不能恢复?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        if (_this2.multipleSelection) {
          var ids = [];
          _this2.multipleSelection.map(function (item) {
            ids.push(item.apiGroupId);
          });
          _this2.deleteApi(ids);
        }
        // 暂时缺接口
      }).catch(function () {});
    },
    resetSearch: function resetSearch() {
      // 重置
      this.searchForm = {
        name: ''
      };
      this.getRouteGroupList();
    },
    refreshClicked: function refreshClicked() {
      // 刷新
      this.getRouteGroupList();
    },
    searchData: function searchData() {
      // 查询
      this.listQuery.pageNum = 1;
      this.getRouteGroupList();
    },
    showAddDialogClicked: function showAddDialogClicked(type) {
      //新增
      this.showAddDialog = true;
      this.DialogType = type;
    },
    // 编辑
    editConfig: function editConfig(type, row) {
      this.showAddDialog = true;
      this.DialogType = type;
      this.DialogId = row.apiGroupId.toString();
    },
    updateItem: function updateItem(row) {
      var _this3 = this;
      //是否启用
      var data = {
        id: row.apiGroupId,
        enabled: row.enabled,
        name: row.name
      };
      gatewayConfigRequest.updateRouteGroup(data).then(function (res) {
        if (res.code == 200) {
          _this3.$message.success(res.msg);
        }
        _this3.getRouteGroupList();
      });
    },
    deleteRoute: function deleteRoute(row) {
      var _this4 = this;
      // 删除二次确认
      this.$confirm('是否删除当前数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this4.deleteApi(row.apiGroupId);
      }).catch(function () {});
    },
    deleteApi: function deleteApi(ids) {
      var _this5 = this;
      // 删除api
      var data = {
        ids: ids.toString()
      };
      gatewayConfigRequest.deleteRouteGroup(data).then(function (res) {
        if (res.code == 200) {
          _this5.$message.success(res.msg);
        }
        _this5.getRouteGroupList();
      });
    },
    // 关闭弹窗
    loadData: function loadData() {
      this.showAddDialog = false;
      this.DialogType = null;
      this.getRouteGroupList();
    }
  }
};