import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.splice.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '../../mixins/initHeight';
import dict from '../../mixins/dict';
import Pagination from '@/components/Pagination';
import DatePicker from '@/components/DatePicker';
import customersRequest from '../../api/customers';
import OrgSelect from '@/components/OrgSelect';
import * as echarts from "echarts";
import { character, getBeforeDate, getLastMonthDays, getCurrentDate, commonExport } from '@/utils/common';
export default {
  name: 'failure',
  components: {
    Pagination: Pagination,
    OrgSelect: OrgSelect,
    DatePicker: DatePicker
  },
  mixins: [initHeight, dict],
  computed: {},
  data: function data() {
    return {
      trackDateTime: [getLastMonthDays(), getCurrentDate()],
      tabType: null,
      showApprovalDialog: false,
      activeName: "all",
      drawer: false,
      searchForm: {},
      approvalForm: {
        approvalStatus: "pass"
      },
      total: 0,
      listLoading: true,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      loading: false,
      IsEchart: false,
      pieChart: {},
      //饼图
      loadPieEchartData: [],
      bizType: localStorage.getItem("bizType") || ''
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.loadData();
    this.loadPieReportData();
  },
  methods: {
    // 日期选择事件
    getPickersDate: function getPickersDate(name, val) {
      if (val && val.length > 1) {
        this[name] = val;
      } else {
        this[name] = [];
      }
    },
    breakData: function breakData() {
      this.loadData();
    },
    // 导出
    exportExcel: function exportExcel() {
      var exporParams = {
        total: this.total,
        limitNum: 200000,
        fileName: '战败管理',
        exportUrl: '/vi-core-service/customerTrackFail/trackFail',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    // 监听输入事件
    inputvin: function inputvin(val) {
      if (val) {
        val = val.replace(/i/g, '').replace(/I/g, '').replace(/o/g, '').replace(/O/g, '').replace(/q/g, '').replace(/Q/g, '');
        this.searchForm.vin = character(_.toUpper(val));
        this.$forceUpdate();
      }
    },
    // 复核弹窗
    approval: function approval(id) {
      this.approvalForm.trackIds = [id];
      this.showApprovalDialog = true;
    },
    // 保存复核
    save: function save() {
      var _this = this;
      if (this.approvalForm.approvalStatus != "reject") {
        this.approvalForm.retrackTime = null;
      }
      customersRequest.approvalFn(this.approvalForm).then(function (res) {
        if (res.code == 200) {
          _this.loadData();
          _this.showApprovalDialog = false;
        }
      }).catch(function (err) {});
    },
    // 格式化审核状态
    formatterApprovalStatus: function formatterApprovalStatus(row, column, cellValue, index) {
      if (cellValue) {
        return this.dictSource['A_SRATUS'][cellValue];
      } else {
        return cellValue;
      }
    },
    // tab切换事件
    handleTabClick: function handleTabClick(tab, event) {
      this.listQuery = {
        pageNum: 1,
        pageSize: 20
      };
      switch (tab.name) {
        case "false":
          this.tabType = false;
          this.searchForm.tabType = false;
          break;
        case "invalid":
          this.tabType = true;
          this.searchForm.tabType = true;
          break;
        default:
          this.tabType = null;
          this.searchForm.tabType = null;
          break;
      }
      this.loadData();
    },
    // 查看客户详情
    viewDetail: function viewDetail(item) {
      this.$router.push({
        path: '/customers/detail',
        query: {
          customerId: item.customerId,
          tabName: "\u5BA2\u6237\u8BE6\u60C5-".concat(item.ownerName, "(").concat(item.vin, ")")
        }
      });
    },
    // 重置搜索表单
    resetSearch: function resetSearch() {
      this.searchForm = {};
      this.trackDateTime = [];
      this.searchForm.tabType = this.tabType;
    },
    // 搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      var _this$searchForm = this.searchForm,
        salesOrgCode = _this$searchForm.salesOrgCode,
        salesOrgName = _this$searchForm.salesOrgName;
      this.searchForm.ownerOrgCode = salesOrgCode;
      this.searchForm.ownerOrgName = salesOrgName;
      this.loadData();
    },
    // 双击打开详情
    dbSelected: function dbSelected(item) {
      this.viewDetail(item);
    },
    // 获取列表数据
    loadData: function loadData() {
      var _this2 = this;
      if (this.trackDateTime) {
        this.searchForm.startTrackTime = this.trackDateTime[0] && this.trackDateTime[0].split(' ')[0] + ' 00:00:00' || null;
        this.searchForm.endTrackTime = this.trackDateTime[1] && this.trackDateTime[1].split(' ')[0] + ' 23:59:59' || null;
      }
      this.listLoading = true;
      this.drawer = false;
      customersRequest.getFailList(this.listQuery.pageNum, this.listQuery.pageSize, this.searchForm).then(function (res) {
        _this2.listLoading = false;
        _this2.tableData = res.data.list;
        //总条数
        _this2.total = res.data.page.recordsTotal;
      }).catch(function (err) {
        _this2.listLoading = false;
      });
    },
    // 饼状图
    pieReportFn: function pieReportFn(data) {
      this.pieChart = echarts.init(document.getElementById('pieReport'));
      var option = {
        title: {
          text: '战败数据一览',
          subtext: '',
          // 副标题
          left: 'center',
          show: false
        },
        tooltip: {
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        color: ["#DD7F70", "#0614FC", "#708CDD", "#262C3D", "#992877", "#E5E710"],
        legend: {
          orient: 'vertical',
          left: '12%',
          // 调整标签列表位置
          top: 'center',
          height: '100px'
        },
        series: [{
          name: '数据',
          type: 'pie',
          radius: '70%',
          center: ['6%', '50%'],
          // 调整饼图位置
          data: data,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          },
          label: {
            show: false // 是否显示饼图对应的label
          },

          labelLine: {
            show: false // 是否显示label线
          }
        }]
      };

      option && this.pieChart.setOption(option);
    },
    // 加载饼图数据
    loadPieReportData: function loadPieReportData() {
      var _this3 = this;
      this.IsEchart = true;
      customersRequest.getFailEchartList(this.listQuery.pageNum, this.listQuery.pageSize, this.searchForm).then(function (res) {
        if (res.code == 200) {
          if (_.isEmpty(res.data)) {
            _this3.IsEchart = false;
          }
          // 格式化数组
          var formatData = [];
          formatData.splice(0, formatData.length); // 清空数据
          for (var i = 0; i < res.data.length; i++) {
            formatData.push({
              name: res.data[i].trackContent,
              value: res.data[i].quantity
            });
          }
          _this3.loadPieEchartData = formatData;
          _this3.pieReportFn(_this3.loadPieEchartData);
        }
      }).catch(function (err) {
        _this3.IsEchart = false;
      });
    }
  }
};