import _defineProperty from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/defineProperty.js";
var _objApi;
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
/**
 * 客户接口
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = (_objApi = {
  /**
   * 我的客户列表
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   * @param {*} data 内容
   */
  getList: function getList(pageNum, pageSize, data) {
    return request.get(getUrl("/coreCustomer/customers?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 1), {
      params: data
    });
  },
  /**
   * 我的客户表头数量
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   * @param {*} data 内容
   */
  getMyCustomerSummary: function getMyCustomerSummary(pageNum, pageSize, data) {
    return request.get(getUrl("/coreCustomer/myCustomerSummary?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 1), {
      params: data
    });
  },
  /**
   * 客户资源列表
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   * @param {*} data 内容
   */
  getAllList: function getAllList(pageNum, pageSize, data) {
    return request.get(getUrl("/coreCustomer/customersSource?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 1), {
      params: data
    });
  },
  /**
   * 详情
   * @param {*} id 查询参数
   */
  getDetail: function getDetail(id) {
    return request.get(getUrl("/coreCustomer/customer/".concat(id), 1));
  },
  /**
   * 客户跟进详情
   * @param {*} id 查询参数
   */
  getTrackDetail: function getTrackDetail(ownerBy) {
    return request.get(getUrl("/coreCustomer/track/detail?pageNum=1&pageSize=100&ownerBy=".concat(ownerBy), 1));
  },
  /**
   * 修改
   * @param {*} id 查询参数
   * @param {*} data 内容
   */
  update: function update(id, data) {
    return request.put(getUrl("/coreCustomer/customer?customerId=".concat(id), 1), data);
  },
  /**
  * 修改
  * @param {*} id 查询参数
  * @param {*} data 内容
  */
  updateVehicleInfo: function updateVehicleInfo(id, data) {
    return request.put(getUrl("/coreCustomer/updateVehicleInfo?customerId=".concat(id), 1), data);
  },
  /**
   * 新增跟进
   * @param {*} data 内容
   */
  addTracks: function addTracks(data) {
    return request.post(getUrl("/coreCustomer/customer/track", 1), data);
  },
  /**
   * 根据id批量分配
   * @param {*} data 内容
   */
  distribution: function distribution(data) {
    return request.put(getUrl("/coreCustomer/customer/distribution", 1), data);
  },
  /**
   * 删除
   * @param {*} ids
   */
  delete: function _delete(ids) {
    return request.delete(getUrl("/coreCustomer/customer?customerId=".concat(ids.toString()), 1));
  },
  /**
   * 下载excel模板
   */
  download: function download() {
    return request({
      method: 'get',
      url: getUrl("/coreCustomer/customer/formwork", 1),
      responseType: 'blob'
    });
  },
  /**
   * 导入模板
   *  @param {*} data 内容
   */
  importModul: function importModul(data) {
    return request({
      method: 'post',
      url: getUrl("/coreCustomer/customer/customerResourceImport", 1),
      data: data
    });
  },
  /**
   * 战败列表
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   * @param {*} data 内容
   */
  getFailList: function getFailList(pageNum, pageSize, data) {
    return request.get(getUrl("/customerTrackFail/trackFail?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 1), {
      params: data
    });
  },
  /**
   * 战败审核
   * @param {*} id 查询参数
   */
  approvalFn: function approvalFn(data) {
    return request.put(getUrl("/customerTrackFail/trackFail/approval", 1), data);
  },
  /**
   * 战败导出
   * @param {*} data 查询参数
   */
  exportTrackFail: function exportTrackFail(data) {
    return request.post(getUrl("/customerTrackFail/exprot/excelTrackFail", 1), data);
  },
  /**
   * 跟进明细列表
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   * @param {*} data 内容
   */
  getfollowDetails: function getfollowDetails(pageNum, pageSize, data) {
    return request.get(getUrl("/customerTrackFail/followDetails?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 1), {
      params: data
    });
  },
  /**
   * 战败饼图数据列表
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   * @param {*} data 内容
   */
  getFailEchartList: function getFailEchartList(pageNum, pageSize, data) {
    return request.get(getUrl("/customerTrackFail/trackFail/count?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 1), {
      params: data
    });
  },
  /**
   * 获取客户经理列表(经销商)
   * @param {*} code 编码
   */
  getDealerCustomerList: function getDealerCustomerList(code) {
    return request.get(getUrl("/dealer/employee/getOrgCodeList/list?dealerCode=".concat(code), 12));
  },
  /**
   * 获取客户经理列表(主机厂)
   */
  getOemCustomerList: function getOemCustomerList() {
    return request.get(getUrl("/oem/employee/all/oem/list", 13));
  },
  /**
   * 新增
   * @param {*} data 内容
   */
  addData: function addData(data) {
    return request.post(getUrl("/coreCustomer/customer", 1), data);
  },
  /**
   * 根据条件查询车辆销售数据（本地没有，请求外部接口查询）
   * @param {*} vin
   */
  getVehicleSales: function getVehicleSales(data) {
    return request.get(getUrl("/vehicle/sales/info", 10), {
      params: data
    });
  },
  /**
   * 获取客户操作记录
   * @param {*} id
   */
  getCustomerOperationLog: function getCustomerOperationLog(id) {
    return request.get(getUrl("/coreCustomer/getCustomerOperationLog?customerId=".concat(id), 1));
  },
  /**
   * 客户资源表头分页
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   * @param {*} data 内容
   */
  getCustomerSourceSummary: function getCustomerSourceSummary(pageNum, pageSize, data) {
    return request.get(getUrl("/coreCustomer/customerSourceSummary?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 1), {
      params: data
    });
  },
  /**
   * 维修明细记录查询
   * @param {*} data 内容
   */
  getDuringRepairList: function getDuringRepairList(data) {
    return request.post(getUrl("/duringRepair/customerOrderSearch", 11), data);
  },
  /**
   * 维修记录明细
   * @param {*} data 内容
   */
  getDuringRepairDetail: function getDuringRepairDetail(orderId) {
    return request.get(getUrl("/duringRepair/getOrderDetail?orderId=".concat(orderId), 11));
  },
  /**
   * 编辑记录明细（客户类型，保司名称，保险到期日）
   * @param {*} data 内容
   */
  updateDuringRepairDetail: function updateDuringRepairDetail(data) {
    return request.post(getUrl("/duringRepair/update", 11), data);
  },
  //  **********************************续保配置--任务跟进配置接口************************************

  /**
   * 续保活动-创建/修改任务跟进配置
   * @param {*} data
   */
  trackConfig: function trackConfig(data) {
    return request.post(getUrl("/customerRenewal/trackConfig", 1), data);
  },
  /**
   * 续保任务看板统计查询
   * @param {*} data
   */
  getRenewalStatistics: function getRenewalStatistics(data) {
    return request.get(getUrl("/customerStatistics/renewalStatistics", 1), {
      params: data
    });
  },
  /**
   * 重新统计
   * @param {*} data
   */
  reStatistics: function reStatistics(data) {
    return request.post(getUrl("/customerStatistics/reStatistics", 1), data);
  }
}, _defineProperty(_objApi, "getCustomerOperationLog", function getCustomerOperationLog(id) {
  return request.get(getUrl("/coreCustomer/getCustomerOperationLog?customerId=".concat(id), 1));
}), _defineProperty(_objApi, "quoteDetails", function quoteDetails(data) {
  return request.get(getUrl("/customerStatistics/quoteDetails", 1), {
    params: data
  });
}), _defineProperty(_objApi, "policyDetails", function policyDetails(data) {
  return request.get(getUrl("/customerStatistics/policyDetails", 1), {
    params: data
  });
}), _defineProperty(_objApi, "ownerDetails", function ownerDetails(data) {
  return request.get(getUrl("/customerStatistics/ownerDetails", 1), {
    params: data
  });
}), _defineProperty(_objApi, "getStatisticsInfo", function getStatisticsInfo(statisticsId) {
  return request.get(getUrl("/customerStatistics/get?statisticsId=".concat(statisticsId), 1));
}), _defineProperty(_objApi, "getCallType", function getCallType(customerId) {
  return request.get(getUrl("/callCenter/webCall/getCallType?customerId=".concat(customerId), 13));
}), _defineProperty(_objApi, "webapi", function webapi(data) {
  return request.post(getUrl("/callCenter/webCall/webapi", 13), data);
}), _defineProperty(_objApi, "getGovernanceOrgStatistics", function getGovernanceOrgStatistics(data) {
  return request.get(getUrl("/customer/governance/org", 1), {
    params: data
  });
}), _defineProperty(_objApi, "getGovernanceOwnerStatistics", function getGovernanceOwnerStatistics(data) {
  return request.get(getUrl("/customer/governance/owner", 1), {
    params: data
  });
}), _defineProperty(_objApi, "getSalesmanStatistics", function getSalesmanStatistics(data) {
  return request.get(getUrl("/customerStatistics/ownerBy", 1), {
    params: data
  });
}), _defineProperty(_objApi, "fileDistribution", function fileDistribution(data) {
  return request.post(getUrl("/coreCustomer/customer/fileDistribution", 1), data);
}), _defineProperty(_objApi, "getFollowUpStatisticeList", function getFollowUpStatisticeList(data) {
  return request.get(getUrl("/renewal/statistical/statisticalList", 1), {
    params: data
  });
}), _defineProperty(_objApi, "getCustomerImportList", function getCustomerImportList(data) {
  return request.get(getUrl("/customerImport/search", 1), {
    params: data
  });
}), _defineProperty(_objApi, "searchItemCustomerImport", function searchItemCustomerImport(data) {
  return request.get(getUrl("/customerImport/searchItem", 1), {
    params: data
  });
}), _defineProperty(_objApi, "uploadCustomerImport", function uploadCustomerImport(data) {
  return request.post(getUrl("/customerImport/upload", 1), data);
}), _defineProperty(_objApi, "importCustomerImport", function importCustomerImport(mainId) {
  return request.post(getUrl("/customerImport/import?mainId=".concat(mainId), 1));
}), _defineProperty(_objApi, "operateCustomerImport", function operateCustomerImport(mainId, operate) {
  return request.post(getUrl("/customerImport/operate?mainId=".concat(mainId, "&operate=").concat(operate), 1));
}), _defineProperty(_objApi, "getTaskManageList", function getTaskManageList(data) {
  return request.get(getUrl("/customerRenewal/searchTask", 1), {
    params: data
  });
}), _defineProperty(_objApi, "getTaskByOrg", function getTaskByOrg(data) {
  return request.get(getUrl("/customerImport/getTaskByOrg", 1), {
    params: data
  });
}), _defineProperty(_objApi, "getCustomerRenewal", function getCustomerRenewal(data) {
  return request.get(getUrl("/customerRenewal/getByOrg", 1), {
    params: data
  });
}), _defineProperty(_objApi, "activationCustomer", function activationCustomer(customerId) {
  return request.put(getUrl("/coreCustomer/activation?customerId=".concat(customerId), 1));
}), _defineProperty(_objApi, "getSummaryTask", function getSummaryTask(data) {
  return request.get(getUrl("/customerRenewal/summaryTask", 1), {
    params: data
  });
}), _defineProperty(_objApi, "taskDistribution", function taskDistribution(data) {
  return request.post(getUrl("/customer/clues/manage/distribution", 1), data);
}), _defineProperty(_objApi, "clearOrgCache", function clearOrgCache(data) {
  return request.post(getUrl("/customerRenewal/clearOrgCache", 1), data);
}), _defineProperty(_objApi, "getByOrg", function getByOrg(data) {
  return request.get(getUrl("/customerRenewal/getByOrg", 1), {
    params: data
  });
}), _defineProperty(_objApi, "createConfig", function createConfig(data) {
  return request.post(getUrl("/customerRenewal/createConfig", 1), data);
}), _objApi);
export default objApi;