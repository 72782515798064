import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FormLabel from '@/components/FormSubLabel';
export default {
  components: {
    FormLabel: FormLabel
  },
  data: function data() {
    return {
      showDamageDialog: false,
      showStepDialog: false,
      activeName: '1',
      DamageStepList: [{
        status: '定损',
        time: '2022-02-26 16:25:47',
        manger: '超级管理员',
        DamageAmount: 1,
        DamageDetail: 'GG'
      }],
      stepList: [{
        status: '待跟进',
        time: '2022-02-26 16:25:47',
        msg: '状态已变更为待跟进,接车员:杨子鹏'
      }, {
        status: '已回厂',
        time: '2022-02-26 17:00:04',
        msg: '状态已变更为已回厂'
      }, {
        status: '待定损',
        time: '2022-02-26 17:10:37',
        msg: '状态已变更为待定损'
      }, {
        status: '待维修',
        time: '2022-02-28 09:44:41',
        msg: '状态已变更为待维修,定损金额1.0,增补金额0,总金额1.0,定损项目gghh,增补项目gghh,定损项目gghh,增补项目gghh'
      }, {
        status: '准备配件',
        time: '2022-03-28 09:45:19',
        msg: '状态已变更为准备配件'
      }]
    };
  },
  created: function created() {},
  mounted: function mounted() {
    console.log(this.$route);
  },
  methods: {
    goBack: function goBack() {
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.replace({
        name: 'orderMonitor'
      });
    },
    toview: function toview(val) {
      if (val == 'history') {
        this.showStepDialog = true;
      } else {
        this.showDamageDialog = true;
      }
    },
    closePage: function closePage(val) {
      if (val == 'history') {
        this.showStepDialog = false;
      } else {
        this.showDamageDialog = false;
      }
    },
    handleClick: function handleClick() {}
  }
};