import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.json.stringify.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import messageApi from '@/api/messagePlatform';
import dictRequest from '@/api/dictionary';
import storageRequest from '@/api/storage';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    opType: {
      type: String,
      default: ''
    },
    currentId: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      ENV_TITLE: process.env.VUE_APP_FLAG,
      form: {
        notifyConfigCode: '',
        notifyConfigName: '',
        notifyConfigParameter: '',
        notifyConfigParameterList: [],
        notifyTypeCode: '',
        notifyTypeName: '',
        serviceCode: '',
        serviceName: ''
      },
      rules: {
        notifyConfigParameterList: {},
        notifyConfigCode: {
          required: true,
          message: '请输入配置编码',
          trigger: "blur"
        },
        notifyConfigName: {
          required: true,
          message: '请输入配置名称',
          trigger: "blur"
        },
        serviceCode: {
          required: true,
          message: '请选择服务名称',
          trigger: "change"
        },
        notifyTypeCode: {
          required: true,
          message: '请选择通知方式',
          trigger: "change"
        }
      },
      formLoading: false,
      serviceList: [],
      notifyTypeList: [],
      configName: ''
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
      ;
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    // 初始化数据
    initData: function initData() {
      var _this = this;
      this.getServiceList();
      this.getNotifyTypeList();
      this.form = {
        notifyConfigCode: '',
        notifyConfigName: '',
        notifyConfigParameter: '',
        notifyConfigParameterList: [],
        notifyTypeCode: '',
        notifyTypeName: '',
        serviceCode: '',
        serviceName: ''
      };
      if (this.opType === "edit") {
        this.formLoading = true;
        messageApi.getMessageNotifyChannelInfo(this.currentId).then(function (res) {
          _this.formLoading = false;
          if (res.code === 200) {
            _this.form = res.data;
            _this.$set(_this.form, 'notifyConfigParameterList', []);
            _this.getNotifyConfig();
          }
          ;
        }).catch(function (err) {
          _this.formLoading = false;
        });
      }
      ;
      this.$refs.detail.resetFields();
    },
    // 获取商户方案
    getNotifyConfig: function getNotifyConfig() {
      var _this2 = this;
      this.form.notifyConfigParameterList = [];
      if (!this.form.notifyTypeCode || !this.form.serviceCode) return;
      var params = {
        notifyTypeCode: this.form.notifyTypeCode,
        serviceCode: this.form.serviceCode
      };
      this.formLoading = true;
      messageApi.getConfigSchema(params).then(function (res) {
        _this2.formLoading = false;
        if (res.code === 200 && res.data) {
          _this2.configName = res.data.categoryName;
          var dataList = res.data.items || [];
          if (_this2.form.notifyConfigParameter) {
            var notifyConfigParameter = JSON.parse(_this2.form.notifyConfigParameter);
            dataList.forEach(function (item) {
              for (var key in notifyConfigParameter) {
                if (key == item.storeKey) {
                  item.storeValue = notifyConfigParameter[key];
                }
                ;
              }
              ;
            });
          }
          ;
          _this2.form.notifyConfigParameterList = dataList;
        }
        ;
      }).catch(function (err) {
        _this2.formLoading = false;
      });
    },
    // 上传文件
    uploadFile: function uploadFile(param, item, index) {
      var _this3 = this;
      var formData = new FormData();
      formData.append("file", param.file);
      this.formLoading = true;
      storageRequest.uploadFile(formData).then(function (res) {
        _this3.formLoading = false;
        if (res.code === 200) {
          _this3.$message.success(res.msg);
          item.storeValue = res.data.fileStorageId;
          _this3.$refs['detail'].clearValidate("notifyConfigParameterList.".concat(index, ".storeValue"));
        }
        ;
      }).catch(function (err) {
        _this3.formLoading = false;
      });
    },
    // 获取服务名称
    getServiceList: function getServiceList() {
      var _this4 = this;
      this.serviceList = [];
      dictRequest.getDictItemsByCode("SYSTEM_MICRO_SERVICE").then(function (res) {
        if (res.data) {
          _this4.serviceList = res.data;
        }
        ;
      });
    },
    // 获取通知方式
    getNotifyTypeList: function getNotifyTypeList() {
      var _this5 = this;
      this.notifyTypeList = [];
      dictRequest.getDictItemsByCode("MESSAGE_NOTIFY_TYPE").then(function (res) {
        if (res.data) {
          _this5.notifyTypeList = res.data;
        }
        ;
      });
    },
    // 监听输入事件
    inputNotifyConfigCode: function inputNotifyConfigCode(val) {
      this.form.notifyConfigCode = val.replace(/[^A-Za-z0-9_-]/g, '');
    },
    // 服务名称切换
    changeServiceCode: function changeServiceCode(val) {
      if (val) {
        var serviceObj = _.find(this.serviceList, function (item) {
          return item.itemCode == val;
        });
        this.form.serviceName = serviceObj.itemName;
        this.getNotifyConfig();
      } else {
        this.form.notifyConfigParameterList = [];
        this.form.serviceName = '';
        this.form.serviceCode = '';
      }
    },
    // 通知方式切换
    changeNotifyTypeCode: function changeNotifyTypeCode(val) {
      if (val) {
        var notifyTypeObj = _.find(this.notifyTypeList, function (item) {
          return item.itemCode == val;
        });
        this.form.notifyTypeName = notifyTypeObj.itemName;
        this.getNotifyConfig();
      } else {
        this.form.notifyConfigParameterList = [];
        this.form.notifyTypeName = '';
        this.form.notifyTypeCode = '';
      }
    },
    // 保存数据
    save: function save() {
      var _this6 = this;
      this.$refs.detail.validate(function (valid) {
        if (valid) {
          _this6.formLoading = true;
          if (!_.isEmpty(_this6.form.notifyConfigParameterList)) {
            var temp = {};
            _this6.form.notifyConfigParameterList.forEach(function (item) {
              if (item.storeValue) {
                var newField = item.storeKey;
                temp[newField] = item.storeValue;
              }
              ;
            });
            _this6.form['notifyConfigParameter'] = JSON.stringify(temp);
          } else {
            _this6.form['notifyConfigParameter'] = "{}";
          }
          ;
          var req = _this6.opType === 'add' ? messageApi.createMessageNotifyChannel(_this6.form) : messageApi.updateMessageNotifyChannel(_this6.currentId, _this6.form);
          req.then(function (res) {
            _this6.formLoading = false;
            if (res.code === 200) {
              _this6.$message.success(res.msg);
              _this6.closePage(false);
            }
            ;
          }).catch(function (err) {
            _this6.formLoading = false;
          });
        }
      });
    },
    closePage: function closePage() {
      var boolean = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      //关闭弹窗
      this.$emit('closePage', boolean);
    }
  }
};