import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '../../mixins/initHeight';
import dict from '../../mixins/dict';
import Pagination from '@/components/Pagination';
import OemSelect from '@/components/OemSelect';
import settlementSettingsRequest from "@/api/settlementSettings";
import { getSupplyers, character, commonExport } from "@/utils/common";
export default {
  components: {
    Pagination: Pagination,
    OemSelect: OemSelect
    // InsuranceManaAdd
  },

  mixins: [initHeight, dict],
  computed: {},
  data: function data() {
    return {
      dialogLoading: false,
      showDialog: false,
      insuranceList: [],
      form: {},
      settleFieldTypeEnum: [{
        dictCode: "INSURED_TIME",
        dictName: "承保日期"
      }],
      multipleSelection: [],
      drawer: false,
      searchForm: {},
      list: null,
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      loading: false,
      opType: '',
      financeSettleConfigId: null,
      highlightCurrent: true,
      orgForm: {
        salesOrgCode: '',
        salesOrgName: '',
        salesOrgType: ''
      },
      settingOrgForm: {
        salesOrgCode: '',
        salesOrgName: '',
        salesOrgType: ''
      },
      formRules: {
        orgCode: [{
          required: true,
          message: '请选择业务机构',
          trigger: 'change'
        }],
        billTin: [{
          required: true,
          message: '请输入纳税人识别号',
          trigger: 'blur'
        }],
        billTitle: [{
          required: true,
          message: '请输入发票抬头',
          trigger: 'blur'
        }],
        insCorpCode: [{
          required: true,
          message: '请选择保险公司',
          trigger: 'change'
        }],
        settleFieldType: [{
          required: true,
          message: '请选择结算类型',
          trigger: 'change'
        }]
      }
    };
  },
  watch: {
    showDialog: function showDialog(val) {
      var _this = this;
      if (val) {
        if (this.opType == 'add') {
          this.form = {
            orgCode: "",
            settleFieldType: 'INSURED_TIME'
          };
          this.settingOrgForm = {
            salesOrgCode: '',
            salesOrgName: '',
            salesOrgType: ''
          };
        } else {
          this.getDetailById();
        }
        this.$nextTick(function () {
          return _this.$refs["form"].clearValidate();
        });
      }
    }
  },
  created: function created() {},
  mounted: function mounted() {
    this.loadData();
    this.getInsuranceData();
  },
  methods: {
    breakData: function breakData() {
      this.searchData();
    },
    // 获取详情
    getDetailById: function getDetailById() {
      var _this2 = this;
      this.dialogLoading = true;
      settlementSettingsRequest.getDetailById(this.financeSettleConfigId).then(function (res) {
        _this2.dialogLoading = false;
        if (res.code == 200) {
          _this2.form = res.data;
          _this2.settingOrgForm = {
            salesOrgCode: res.data.orgCode,
            salesOrgName: res.data.orgName,
            salesOrgType: ''
          };
        }
      }).catch(function (err) {
        return _this2.dialogLoading = false;
      });
    },
    // 保存
    save: function save() {
      var _this3 = this;
      this.$refs["form"].validate(function (valid) {
        if (valid) {
          var postData = _objectSpread({}, _this3.form);
          var insCorp = _.find(_this3.insuranceList, function (item) {
            return item.code == _this3.form.insCorpCode;
          });
          if (!_.isEmpty(insCorp)) postData["insCorpName"] = insCorp.shortName;
          var req = _this3.opType == 'add' ? settlementSettingsRequest.createSettleconfig(postData) : settlementSettingsRequest.updataSettleconfig(_objectSpread(_objectSpread({}, postData), {}, {
            coreFinanceSettleConfigId: _this3.financeSettleConfigId
          }));
          _this3.dialogLoading = true;
          req.then(function (res) {
            _this3.dialogLoading = false;
            _this3.showDialog = false;
            if (res.code == 200) {
              _this3.$message.success(res.msg);
              _this3.loadData();
            }
          }).catch(function (err) {
            return _this3.dialogLoading = false;
          });
        }
      });
    },
    // 关闭
    close: function close() {
      this.showDialog = false;
    },
    // 导出
    exportFn: function exportFn() {
      var exporParams = {
        total: this.tableData.length,
        fileName: '结算设置',
        exportUrl: '/vi-core-service/financesettleconfig/search',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    nodeClick: function nodeClick(val) {
      //切换机构
      this.searchForm.orgCode = val.salesOrgCode;
    },
    //切换机构
    settingNodeClick: function settingNodeClick(val) {
      this.form.orgCode = val.salesOrgCode;
      this.form.orgName = val.salesOrgName;
      this.$forceUpdate();
    },
    //获取保司列表
    getInsuranceData: function getInsuranceData() {
      this.insuranceList = getSupplyers();
    },
    insCorpChange: function insCorpChange(val) {
      //保险公司选择
      if (!val) return;
      var obj = {};
      obj = _.find(this.insuranceList, function (item) {
        return item.code == val;
      });
      this.searchForm.insCorpName = obj.shortName;
    },
    //新增
    create: function create(type) {
      this.opType = type;
      this.showDialog = true;
    },
    // 编辑
    edit: function edit(type, item) {
      this.opType = type;
      this.showDialog = true;
      this.financeSettleConfigId = item.coreFinanceSettleConfigId;
    },
    handleSelectionChange: function handleSelectionChange(val) {
      //列表行选择事件
      this.multipleSelection = val;
    },
    removeItems: function removeItems(item) {
      var _this4 = this;
      //删除
      var ids = [];
      if (!_.isEmpty(item)) {
        ids.push(item.coreFinanceSettleConfigId);
      } else {
        if (!_.isEmpty(this.multipleSelection)) {
          ids = _.map(this.multipleSelection, 'coreFinanceSettleConfigId');
        }
      }
      if (_.isEmpty(ids)) return;
      this.$confirm('您确定要删除么？', {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        settlementSettingsRequest.deleteData(ids).then(function (res) {
          if (res.code === 200) {
            _this4.$message({
              message: res.msg,
              type: 'success'
            });
            _this4.loadData();
          }
        });
      });
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {};
      this.orgForm = {
        salesOrgCode: '',
        salesOrgName: '全部',
        salesOrgType: ''
      };
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    loadData: function loadData() {
      var _this5 = this;
      //获取列表数据
      this.loading = true;
      this.drawer = false;
      settlementSettingsRequest.getList(_objectSpread(_objectSpread({}, this.listQuery), this.searchForm)).then(function (res) {
        if (res.code === 200) {
          _this5.loading = false;
          _this5.tableData = res.data.list;
          _this5.total = res.data.page.recordsTotal;
        }
      }).catch(function (err) {
        _this5.loading = false;
      });
    }
  }
};