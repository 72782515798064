var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "app-m-cls" }, [
      _c(
        "div",
        { staticClass: "table-cls normal-btn-cls" },
        [
          _c(
            "el-drawer",
            {
              attrs: {
                title: "",
                visible: _vm.drawer,
                modal: false,
                "append-to-body": false,
                size: "370px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.drawer = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.searchForm,
                    "label-width": "110px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 22 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "批次号：" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入批次号",
                                  maxlength: "100",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.searchForm.batchNo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchForm, "batchNo", $$v)
                                  },
                                  expression: "searchForm.batchNo",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 22 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "车架号：" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入车架号",
                                  maxlength: "18",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.searchForm.batchNo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchForm, "batchNo", $$v)
                                  },
                                  expression: "searchForm.batchNo",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 22 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "车牌号：" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入车牌号",
                                  maxlength: "40",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.searchForm.plateNo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchForm, "plateNo", $$v)
                                  },
                                  expression: "searchForm.plateNo",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c(
                          "div",
                          { staticClass: "searchBtnStyle" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: { click: _vm.resetSearch },
                              },
                              [_vm._v("重置")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "primary" },
                                on: { click: _vm.searchData },
                              },
                              [_vm._v("查询")]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-cls normal-btn-cls" },
        [
          _c(
            "el-card",
            { staticClass: "box-card", attrs: { shadow: "never" } },
            [
              _c(
                "div",
                {
                  staticClass: "search-box",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [
                  _vm._l(_vm.sumList, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticStyle: {
                          "font-size": "16px",
                          "font-weight": "bold",
                        },
                      },
                      [
                        _c("div", [
                          _vm._v(
                            _vm._s(item.displayText) +
                              "：" +
                              _vm._s(item.displayValue)
                          ),
                        ]),
                      ]
                    )
                  }),
                  _c(
                    "div",
                    { staticClass: "search-btn" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: { click: _vm.breakData },
                        },
                        [_vm._v("刷新")]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _c(
                "el-tabs",
                {
                  on: { "tab-click": _vm.handleClick },
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                [
                  _c("el-tab-pane", { attrs: { label: "全部", name: "All" } }),
                  _c("el-tab-pane", {
                    attrs: { label: "重复车架号", name: "RIT01" },
                  }),
                  _c("el-tab-pane", {
                    attrs: { label: "重复保单号", name: "RIT02" },
                  }),
                  _c("el-tab-pane", {
                    attrs: { label: "已发放不符合政策", name: "RIT03" },
                  }),
                  _c("el-tab-pane", {
                    attrs: { label: "未发放符合政策", name: "RIT04" },
                  }),
                  _c("el-tab-pane", {
                    attrs: { label: "待确认符合政策", name: "RIT05" },
                  }),
                  _c("el-tab-pane", {
                    attrs: {
                      label: "明细发放数量和数据管理已发放数量未匹配",
                      name: "RIT06",
                    },
                  }),
                  _c("el-tab-pane", {
                    attrs: {
                      label: "数据管理发放数量大于权益数量",
                      name: "RIT07",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  ref: "multipleTable",
                  staticStyle: { width: "100%" },
                  attrs: {
                    "highlight-current-row": "",
                    size: "mini",
                    border: "",
                    data: _vm.tableData,
                    height: _vm.tableHeightZCHD,
                    "header-cell-style": { background: "#F7F7F7" },
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "routingInspectionTypeDisplay",
                      label: "状态",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "couponActivityName",
                      label: "活动名称",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "policyNo",
                      label: "保单号",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "plateNo",
                      label: "车牌号",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "vin",
                      label: "车架号",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "orgCode",
                      label: "机构编码",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "shouldReleasedQuantity",
                      label: "应发数量",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "actualReleasedQuantity",
                      label: "实际发放数量",
                      "show-overflow-tooltip": "",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }