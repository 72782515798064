import "core-js/modules/es.array.unshift.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import requestApi from '@/api/smsManage';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    opType: {
      type: String,
      default: ""
    },
    currentId: {
      type: String,
      default: ""
    }
  },
  data: function data() {
    return {
      form: {
        typeCode: '',
        typeName: '',
        typeDescribe: '',
        params: [{
          key: '',
          value: ''
        }]
      },
      rules: {
        typeCode: {
          required: true,
          message: '请输入模板类型编码',
          trigger: "blur"
        },
        typeName: {
          required: true,
          message: '请输入模板类型名称',
          trigger: "blur"
        }
      },
      formLoading: false
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
      ;
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    initData: function initData() {
      var _this = this;
      //初始化数据
      if (this.opType === "add") {
        this.form = {
          typeCode: '',
          typeName: '',
          typeDescribe: '',
          params: [{
            key: '',
            value: ''
          }]
        };
      } else {
        requestApi.queryTemplateType(this.currentId).then(function (res) {
          if (res.code === 200) {
            _this.form = res.data;
          }
          ;
        });
      }
      ;
      this.$refs.detail.resetFields();
    },
    addItem: function addItem(index) {
      var obj = {
        key: '',
        value: ''
      };
      this.form.params.unshift(obj);
    },
    deleteItem: function deleteItem(index) {
      this.form.params.splice(index, 1);
    },
    // 模板参数校验
    validate: function validate() {
      var _this2 = this;
      var flag = true;
      this.form.params.some(function (value, index) {
        if (!value.key) {
          _this2.$message.warning("\u6A21\u677F\u53C2\u6570\u7B2C".concat(index + 1, "\u884C\u53C2\u6570\u540D\u4E0D\u80FD\u4E3A\u7A7A"));
          flag = false;
          return true;
        }
        ;
        if (!value.value) {
          _this2.$message.warning("\u6A21\u677F\u53C2\u6570\u7B2C".concat(index + 1, "\u884C\u63CF\u8FF0\u4E0D\u80FD\u4E3A\u7A7A"));
          flag = false;
          return true;
        }
        ;
      });
      return flag;
    },
    save: function save() {
      var _this3 = this;
      //保存数据
      this.$refs.detail.validate(function (valid) {
        if (valid) {
          if (!_this3.validate()) return;
          _this3.formLoading = true;
          var req = _this3.opType === 'add' ? requestApi.addTemplateType(_this3.form) : requestApi.editTemplateType(_this3.currentId, _this3.form);
          req.then(function (res) {
            _this3.formLoading = false;
            if (res.code === 200) {
              _this3.$message.success(res.msg);
              _this3.closePage();
            }
            ;
          }).catch(function (err) {
            _this3.formLoading = false;
          });
        }
        ;
      });
    },
    closePage: function closePage() {
      //关闭弹窗
      this.$emit('closePage');
    }
  }
};