import "core-js/modules/es.array.concat.js";
/**
 * 系统设置
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 列表
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   * @param {*} data 内容
   * 
   */
  getList: function getList(pageNum, pageSize, data) {
    return request.get(getUrl("/insCorpInvokeMonitor/search?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 14), {
      params: data
    });
  },
  /**
   * 获取调用监控明细
   */
  getDetail: function getDetail(id) {
    return request.get(getUrl("/insCorpInvokeMonitor/get?id=".concat(id), 14));
  },
  /**
   * 获取summary表头
   */
  getListHeader: function getListHeader(data) {
    return request.get(getUrl("/insCorpInvokeMonitor/getStatisticsSummary", 14), {
      params: data
    });
  },
  /**
    * 更新监控状态
    */
  update: function update(data) {
    return request.put(getUrl("/insCorpInvokeMonitor/update", 14), data);
  },
  /**
   * 保存用户密码配置信息
   * @param {*} data 内容
   */
  savePwdConfig: function savePwdConfig(data) {
    return request.post(getUrl("/users/saveUserPwdConfig", 5), data);
  }
};
export default objApi;