import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import batchQuoteRequest from '@/api/batchQuote';
import orgchannelconfigRequest from '@/api/orgchannelconfig';
import OemSelect from '@/components/OemSelect';
import { getUserOrgInfo, getSupplyers } from '@/utils/common';
export default {
  components: {
    OemSelect: OemSelect
  },
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    monitorId: {
      type: Number,
      default: null
    }
  },
  data: function data() {
    return {
      form: {
        orgCode: '',
        orgName: ''
      },
      rules: {
        insChannelCorpCode: {
          required: true,
          message: '请选择保险公司',
          trigger: "change"
        },
        orgCode: {
          required: true,
          message: '请选择所属机构',
          trigger: "change"
        },
        insChannelCityCode: {
          required: true,
          message: '请选择投保城市',
          trigger: "change"
        },
        insChannelName: {
          required: true,
          message: '请选择渠道名称',
          trigger: "change"
        },
        notifer: {
          required: true,
          message: '请选择通知人员',
          trigger: "change"
        },
        queryIdentityList: {
          required: true,
          message: '请输入车牌/车架',
          trigger: "blur"
        }
      },
      orgForm: {
        salesOrgCode: '',
        salesOrgName: ''
      },
      supplyers: [],
      channeList: [],
      formLoading: false,
      cityList: [],
      placeholder: "\u5F55\u5165\u8F66\u724C\u53F7\u7801\uFF0C\u8F66\u67B6\u53F7\u3002\u4E00\u6761\u4E00\u884C\uFF0C\u4E00\u6B21\u6700\u591A50\u884C\n\u793A\u4F8B\uFF1A\n\u7CA4B12345\n\u7CA4B12346\nLGE7ED454452D5F54"
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
      ;
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    // 获取渠道
    getInsChannelByCode: function getInsChannelByCode(insCorpCode, insCorpCity) {
      var _this = this;
      orgchannelconfigRequest.getInsChannelByCode(insCorpCode, insCorpCity).then(function (res) {
        if (!_.isEmpty(res.data)) {
          _this.channeList = res.data;
        }
      });
    },
    // 选择保险公司
    changeInsCorp: function changeInsCorp(val) {
      var _this2 = this;
      this.form.insChannelCityCode = "";
      this.form.insChannelName = "";
      if (val) {
        orgchannelconfigRequest.getInsChannelByCode(val).then(function (res) {
          if (!_.isEmpty(res.data)) {
            _this2.cityList = _.uniqBy(res.data, 'insCityCode');
          }
        });
      }
    },
    // 选择城市
    changeInsCity: function changeInsCity(val) {
      var _this3 = this;
      if (val) {
        orgchannelconfigRequest.getInsChannelByCode(this.form.insChannelCorpCode, val).then(function (res) {
          if (!_.isEmpty(res.data)) {
            _this3.channeList = res.data;
          }
        });
      }
    },
    // 初始化数据
    initData: function initData() {
      this.supplyers = getSupplyers();
      this.form = {
        orgCode: '',
        orgName: '',
        insChannelCityCode: "",
        insChannelName: "",
        notifer: []
      };
      var orgObj = getUserOrgInfo();
      this.form.orgCode = orgObj.orgCode;
      this.form.orgName = orgObj.fullName;
      this.orgForm = {
        salesOrgCode: orgObj.orgCode,
        salesOrgName: orgObj.fullName
      };
      this.$refs.detail.resetFields();
    },
    // 机构树点击事件
    handleNodeClick: function handleNodeClick(data) {
      this.form.orgCode = data.salesOrgCode;
      this.form.orgName = data.salesOrgName;
      this.form.notifer = [];
    },
    // 保存数据
    save: function save() {
      var _this4 = this;
      this.$refs.detail.validate(function (valid) {
        if (valid) {
          var userInfo = JSON.parse(localStorage.getItem("userInfo")) || {};
          _this4.formLoading = true;
          var postData = _objectSpread(_objectSpread({}, _this4.form), {}, {
            queryIdentityList: _this4.form.queryIdentityList.split("\n").filter(function (item) {
              if (item) {
                return item;
              }
            }),
            bizType: userInfo.bizType || 'BROKER',
            salesmanId: userInfo.userId
          });
          batchQuoteRequest.createBatchQuote(postData).then(function (res) {
            _this4.formLoading = false;
            if (res.code === 200) {
              _this4.$message.success(res.msg);
              _this4.closePage(true);
            }
            ;
          }).catch(function (err) {
            _this4.formLoading = false;
          });
        }
      });
    },
    // 关闭弹窗
    closePage: function closePage() {
      var isRefresh = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      this.$emit('closePage', isRefresh);
    },
    // 获取详情
    getDetailById: function getDetailById() {
      var _this5 = this;
      batchQuoteRequest.getDetailById(this.monitorId).then(function (res) {
        _this5.formLoading = false;
        if (res.code === 200) {
          _this5.$refs['oemSelectRef'].getUsers(res.data.orgCode);
          _this5.changeInsCorp(res.data.insChannelCorpCode);
          _this5.changeInsCity(res.data.insChannelCityCode);
          _this5.getInsChannelByCode(res.data.insChannelCorpCode, res.data.insChannelCityCode);
          _this5.form = _objectSpread(_objectSpread({}, res.data), {}, {
            notifer: res.data.notifer.split(",")
          });
          _this5.orgForm = {
            salesOrgCode: _this5.form.orgCode,
            salesOrgName: _this5.form.orgName
          };
        }
        ;
      }).catch(function (err) {
        return _this5.formLoading = false;
      });
    }
  }
};