import _createForOfIteratorHelper from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import _defineProperty from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import dict from '@/mixins/dict';
import customersRequest from '@/api/customers';
import Pagination from '@/components/Pagination';
import DatePicker from '@/components/DatePicker';
import CustomerImportDialog from './dialog/customerImportDialog';
import CustomerImportListDialog from './dialog/customerImportListDialog';
import DistributionDialog from './detail/distributionDialog';
import OrgSelect from '@/components/OrgSelect';
import AddDialog from './dialog/addDialog';
import CluesDistribution from '@/views/leadManagement/dialog/cluesDistribution';
import dictRequest from '@/api/dictionary';
import OemSelect from '@/components/OemSelect';
import { character, getSupplyers, commonExport, GetDateStr, getBeforeDate, getCurrentDate } from '@/utils/common';
import CarBrands from '@/components/CarBrands/index';
export default {
  name: "Customers",
  mixins: [initHeight, dict],
  components: {
    Pagination: Pagination,
    CustomerImportDialog: CustomerImportDialog,
    DistributionDialog: DistributionDialog,
    OrgSelect: OrgSelect,
    CluesDistribution: CluesDistribution,
    AddDialog: AddDialog,
    OemSelect: OemSelect,
    CarBrands: CarBrands,
    DatePicker: DatePicker,
    CustomerImportListDialog: CustomerImportListDialog
  },
  data: function data() {
    var _searchForm, _ref;
    return _ref = {
      carTypeVal: [],
      trackStatuslist: [],
      codeList: [{
        code: "TRACKING",
        name: "跟进中"
      }, {
        code: "TRACK_SUCCEED",
        name: "成功出单"
      }, {
        code: "TRACK_DEFEAT",
        name: "战败"
      }, {
        code: "TRACK_INVALID",
        name: "无效"
      }],
      drawer: false,
      renewalTypeList: [],
      actionStyle: {
        right: 0
      },
      searchForm: (_searchForm = {
        assignTabType: "",
        includeSubOrgan: true,
        ownerName: "",
        plateNo: "",
        vin: "",
        startOverdueDate: "",
        endOverdueDate: "",
        overdueDays: "",
        noBzLastYearEndDate: false,
        noBsLastYearEndDate: false,
        startCreatedDate: '',
        endCreatedDate: '',
        salesOrgCode: '',
        salesOrgName: ''
      }, _defineProperty(_searchForm, "includeSubOrgan", true), _defineProperty(_searchForm, "ownerBy", ''), _defineProperty(_searchForm, "ownerByName", ''), _defineProperty(_searchForm, "startRegisterDate", ''), _defineProperty(_searchForm, "endRegisterDate", ''), _defineProperty(_searchForm, "ownerMobile", ''), _defineProperty(_searchForm, "cjVhcBrandNo", ''), _defineProperty(_searchForm, "cjVhcSeriesNo", ''), _searchForm)
    }, _defineProperty(_ref, "trackStatuslist", []), _defineProperty(_ref, "codeList", [{
      code: "TRACKING",
      name: "跟进中"
    }, {
      code: "TRACK_SUCCEED",
      name: "成功出单"
    }, {
      code: "TRACK_DEFEAT",
      name: "战败"
    }, {
      code: "TRACK_INVALID",
      name: "无效"
    }]), _defineProperty(_ref, "orgForm", {
      salesOrgCode: '',
      salesOrgName: '',
      salesOrgType: ''
    }), _defineProperty(_ref, "form", {}), _defineProperty(_ref, "formLoading", false), _defineProperty(_ref, "list", null), _defineProperty(_ref, "total", 0), _defineProperty(_ref, "listLoading", true), _defineProperty(_ref, "listQuery", {
      pageNum: 1,
      pageSize: 20
    }), _defineProperty(_ref, "tableData", []), _defineProperty(_ref, "multipleSelection", []), _defineProperty(_ref, "overdueStartEndTime", []), _defineProperty(_ref, "createdStartEndTime", [getBeforeDate(6), getCurrentDate(0)]), _defineProperty(_ref, "customerTypeList", []), _defineProperty(_ref, "customerSysList", []), _defineProperty(_ref, "registerStartEndTime", []), _defineProperty(_ref, "noMaturityDate", []), _defineProperty(_ref, "loading", false), _defineProperty(_ref, "showCustomerDialog", false), _defineProperty(_ref, "showDistributionDialog", false), _defineProperty(_ref, "customersIds", []), _defineProperty(_ref, "users", []), _defineProperty(_ref, "bizType", localStorage.getItem("bizType") || ''), _defineProperty(_ref, "userInfo", JSON.parse(localStorage.getItem("userInfo")) || {}), _defineProperty(_ref, "sourceData", {
      ADD: '手工录入',
      IMPORT: '人工导入',
      INSURED: '在线出单'
    }), _defineProperty(_ref, "distributionStatus", 'all'), _defineProperty(_ref, "opType", ''), _defineProperty(_ref, "showAddDialog", false), _defineProperty(_ref, "supplyers", []), _defineProperty(_ref, "Assigned", 0), _defineProperty(_ref, "Unassigned", 0), _defineProperty(_ref, "Invalid", 0), _ref;
  },
  watch: {
    drawer: function drawer(val) {
      if (val) {
        this.getTrackStatuslist();
      }
    }
  },
  created: function created() {},
  mounted: function mounted() {
    if (this.bizType === 'DEALER') {
      this.fetchEndUsers(this.bizType, this.userInfo.orgCode);
    }
    //保司
    this.supplyers = getSupplyers();
    this.loadData();
    this.getRenewalTypeList();
    this.getTrackStatuslist();
  },
  methods: {
    // 获取跟进状态字典
    getTrackStatuslist: function getTrackStatuslist() {
      var _this = this;
      var reqList = [];
      this.codeList.forEach(function (item) {
        reqList.push(dictRequest.getDictItemsByCode(item.code));
      });
      dictRequest.requestAll(reqList).then(function (resData) {
        _this.trackStatuslist = resData.map(function (item, index) {
          var obj = {
            label: '',
            options: []
          };
          obj.label = _this.codeList[index].name;
          obj.options = item.data;
          return obj;
        });
      });
    },
    // 监听数据变化
    CarHandleChange: function CarHandleChange(val) {
      this.searchForm.cjVhcBrandNo = val[0]; // 厂家品牌编号
      this.searchForm.cjVhcSeriesNo = val[1]; // 厂家车型编号
      this.carTypeVal = val;
      // cjVhcModelNo: val[2], //  厂家车型编号
    },
    //获取续客户类别
    getRenewalTypeList: function getRenewalTypeList() {
      var _this2 = this;
      dictRequest.getDictItemsByCode("RENEWAL_TYPE").then(function (res) {
        if (res.data) {
          _this2.renewalTypeList = res.data;
        }
      });
    },
    breakData: function breakData() {
      // this.resetSearch();
      this.loadData();
    },
    exportFn: function exportFn() {
      var exporParams = {
        total: this.total,
        limitNum: 200000,
        fileName: '客户资源',
        exportUrl: '/vi-core-service/coreCustomer/customersSource',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    getUsers: function getUsers(val) {
      //列表行选择事件
      this.users = val;
    },
    nodeClick: function nodeClick(data) {
      this.searchForm.ownerBy = '';
      this.searchForm.ownerByName = '';
      this.searchForm.ownerOrgCode = data.salesOrgCode;
      this.searchForm.ownerOrgName = data.salesOrgName;
      this.searchForm.areaType = data.salesOrgType;
      if (!data.salesOrgCode && !data.salesOrgName) {
        this.users = [];
      }
    },
    inputPlateNo: function inputPlateNo(val) {
      //自动将车牌号码转为大写
      this.searchForm.plateNo = _.toUpper(val);
    },
    inputvin: function inputvin(val) {
      //监听输入事件
      if (val) {
        val = val.replace(/i/g, '').replace(/I/g, '').replace(/o/g, '').replace(/O/g, '').replace(/q/g, '').replace(/Q/g, '');
        this.searchForm.vin = character(_.toUpper(val));
        this.$forceUpdate();
      }
    },
    salemanNameChange: function salemanNameChange(val) {
      //监听选择业务员事件
      if (val) {
        var _user = _.find(this.users, function (item) {
          return item.userId == val;
        });
        if (_user) {
          this.searchForm.ownerBy = _user.userId;
          this.searchForm.ownerByName = _user.userName;
          this.$forceUpdate();
        }
      } else {
        this.searchForm.ownerBy = '';
        this.searchForm.ownerByName = '';
      }
    },
    distribution: function distribution(item) {
      var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      //分配
      this.opType = type;
      this.customersIds = [];
      //判断是否多选
      // if (type) {
      //     if (this.multipleSelection.length === 0) {
      //         this.$message.error('请选择要分配的客户');
      //     } else {
      //         this.showDistributionDialog = true;
      //         this.customersIds = _.map(this.multipleSelection, 'customerId');
      //     }
      // } else {
      //     this.showDistributionDialog = true;
      //     this.customersIds = [item.customerId];
      // }

      if (type) {
        if (this.multipleSelection.length === 0) {
          this.$message.warning('请选择要分配的客户');
        } else {
          var showDistribution = false; //判断是否有不同机构
          for (var i = 0; i < this.multipleSelection.length; i++) {
            for (var j = 0; j < this.multipleSelection.length; j++) {
              if (this.multipleSelection[i].orgCode == this.multipleSelection[j].orgCode) {
                showDistribution = true;
              } else {
                showDistribution = false;
                // console.log('不允许跨机构分配')
              }
            }
          }
          // console.log('选的是否是同一机构下的客户', showDistribution)
          if (showDistribution) {
            var list = [];
            var _iterator = _createForOfIteratorHelper(this.multipleSelection),
              _step;
            try {
              for (_iterator.s(); !(_step = _iterator.n()).done;) {
                var _i = _step.value;
                console.log(_i);
                list.push({
                  customerId: _i.customerId,
                  customerOrgCode: _i.orgCode
                });
              }
              // console.log(list)
            } catch (err) {
              _iterator.e(err);
            } finally {
              _iterator.f();
            }
            this.showDistributionDialog = true;
            this.customersIds = list;
          } else {
            this.showDistributionDialog = false;
            this.$message.warning('不允许跨机构分配');
          }
        }
      } else {
        this.showDistributionDialog = true;
        this.customersIds = [{
          customerId: item.customerId,
          customerOrgCode: item.orgCode
        }];
      }
    },
    customerImport: function customerImport() {
      //客户导入

      this.showCustomerDialog = true;
    },
    removeItems: function removeItems() {
      var _this3 = this;
      //删除
      var ids = [];
      if (!_.isEmpty(this.multipleSelection)) {
        ids = _.map(this.multipleSelection, 'customerId');
      }
      if (_.isEmpty(ids)) return;
      this.$confirm("\u662F\u5426\u786E\u8BA4\u5220\u9664, \u786E\u8BA4\u5220\u9664\u540E\u4E0D\u80FD\u6062\u590D\u3002", '温馨提示', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        type: 'warning'
      }).then(function () {
        customersRequest.delete(ids).then(function (res) {
          if (res.code === 200) {
            _this3.$message({
              type: 'success',
              message: res.msg
            });
            _this3.loadData();
          }
        });
      });
    },
    handleTabClick: function handleTabClick(tab, event) {
      //tab切换事件
      this.listQuery = {
        pageNum: 1,
        pageSize: 20
      };
      this.loadData();
      this.searchForm.assignTabType = tab.name !== 'all' ? tab.name : '';
      this.distributionStatus = tab.name;
    },
    getPickersDate: function getPickersDate(name, val) {
      if (val && val.length > 1) {
        this[name] = val;
      } else {
        this[name] = [];
      }
    },
    dateChange: function dateChange(start, end, val) {
      //日期选择事件
      if (val && val.length > 1) {
        this.searchForm[start] = val[0];
        this.searchForm[end] = val[1];
      } else {
        this.searchForm[start] = '';
        this.searchForm[end] = '';
      }
      ;
    },
    ChangeNoMaturityDate: function ChangeNoMaturityDate(val) {
      if (val.length) {
        this.searchForm.noBzLastYearEndDate = val.includes('noBzLastYearEndDate');
        this.searchForm.noBsLastYearEndDate = val.includes('noBsLastYearEndDate');
      } else {
        this.searchForm.noBzLastYearEndDate = false;
        this.searchForm.noBsLastYearEndDate = false;
      }
    },
    viewDetail: function viewDetail(item) {
      // 查看客户详情
      this.$router.push({
        path: '/customers/detail',
        query: {
          customerId: item.customerId,
          tabName: "\u5BA2\u6237\u8BE6\u60C5-".concat(item.ownerName, "(").concat(item.vin, ")")
          // plateNo: item.plateNo,
          // vin: item.vin
        }
      });
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {
        assignTabType: this.searchForm.assignTabType,
        ownerName: "",
        plateNo: "",
        vin: "",
        startOverdueDate: "",
        endOverdueDate: "",
        overdueDays: "",
        noBzLastYearEndDate: false,
        noBsLastYearEndDate: false,
        startCreatedDate: '',
        endCreatedDate: '',
        salesOrgCode: '',
        salesOrgName: '',
        includeSubOrgan: true,
        ownerBy: '',
        ownerByName: '',
        startRegisterDate: '',
        endRegisterDate: '',
        ownerMobile: '',
        cjVhcBrandNo: '',
        cjVhcSeriesNo: ''
      };
      this.orgForm = {
        salesOrgName: this.bizType === 'OEM' ? '全部' : '',
        salesOrgType: '',
        salesOrgCode: ''
      };
      this.carTypeVal = [];
      this.overdueStartEndTime = [];
      this.createdStartEndTime = [getBeforeDate(6), getCurrentDate(0)];
      this.registerStartEndTime = [];
      this.noMaturityDate = [];
      if (this.bizType != 'DEALER') {
        this.users = [];
      }
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    dbSelected: function dbSelected(row) {
      //双击打开详情
      this.viewDetail(row);
    },
    handleSelectionChange: function handleSelectionChange(val) {
      //列表行选择事件
      this.multipleSelection = val;
    },
    getCustomerSourceSummary: function getCustomerSourceSummary() {
      var _this4 = this;
      if (this.registerStartEndTime) {
        this.searchForm.startRegisterDate = this.registerStartEndTime[0] ? this.registerStartEndTime[0].split(' ')[0] + ' 00:00:00' : null;
        this.searchForm.endRegisterDate = this.registerStartEndTime[1] ? this.registerStartEndTime[1].split(' ')[0] + ' 23:59:59' : null;
      }
      if (this.overdueStartEndTime) {
        this.searchForm.startOverdueDate = this.overdueStartEndTime[0] ? this.overdueStartEndTime[0].split(' ')[0] + ' 00:00:00' : null;
        this.searchForm.endOverdueDate = this.overdueStartEndTime[1] ? this.overdueStartEndTime[1].split(' ')[0] + ' 23:59:59' : null;
      }
      if (this.createdStartEndTime) {
        this.searchForm.startCreatedDate = this.createdStartEndTime[0] ? this.createdStartEndTime[0].split(' ')[0] + ' 00:00:00' : null;
        this.searchForm.endCreatedDate = this.createdStartEndTime[1] ? this.createdStartEndTime[1].split(' ')[0] + ' 23:59:59' : null;
      }
      customersRequest.getCustomerSourceSummary(this.listQuery.pageNum, this.listQuery.pageSize, this.searchForm).then(function (res) {
        if (res.code == 200) {
          _this4.Assigned = res.data.Assigned;
          _this4.Unassigned = res.data.Unassigned;
          _this4.Invalid = res.data.Invalid;
        }
      });
    },
    loadData: function loadData() {
      var _this5 = this;
      //获取列表数据
      if (this.registerStartEndTime) {
        this.searchForm.startRegisterDate = this.registerStartEndTime[0] ? this.registerStartEndTime[0].split(' ')[0] + ' 00:00:00' : null;
        this.searchForm.endRegisterDate = this.registerStartEndTime[1] ? this.registerStartEndTime[1].split(' ')[0] + ' 23:59:59' : null;
      }
      if (this.overdueStartEndTime) {
        this.searchForm.startOverdueDate = this.overdueStartEndTime[0] ? this.overdueStartEndTime[0].split(' ')[0] + ' 00:00:00' : null;
        this.searchForm.endOverdueDate = this.overdueStartEndTime[1] ? this.overdueStartEndTime[1].split(' ')[0] + ' 23:59:59' : null;
      }
      if (this.createdStartEndTime) {
        this.searchForm.startCreatedDate = this.createdStartEndTime[0] ? this.createdStartEndTime[0].split(' ')[0] + ' 00:00:00' : null;
        this.searchForm.endCreatedDate = this.createdStartEndTime[1] ? this.createdStartEndTime[1].split(' ')[0] + ' 23:59:59' : null;
      }
      this.drawer = false;
      this.showCustomerDialog = false;
      this.showDistributionDialog = false;
      this.showAddDialog = false;
      this.loading = true;
      customersRequest.getAllList(this.listQuery.pageNum, this.listQuery.pageSize, this.searchForm).then(function (res) {
        _this5.loading = false;
        _this5.tableData = res.data.list;
        //总条数
        _this5.total = res.data.page.recordsTotal;
        _this5.getCustomerSourceSummary();
      }).catch(function (err) {
        _this5.loading = false;
      });
    },
    // 获取客户经理列表（经销商、主机厂）
    fetchEndUsers: function fetchEndUsers(type, code) {
      var _this6 = this;
      var rerequest = type === 'DEALER' ? customersRequest.getDealerCustomerList(code) : customersRequest.getOemCustomerList(code);
      rerequest.then(function (res) {
        if (res.code === 200 && res.data) {
          _this6.users = res.data;
        }
      });
    }
  }
};