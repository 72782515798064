//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import insuranceContractManageRequest from '@/api/insuranceContractManage';
import drivingAccidentInsuranceManageRequest from '@/api/drivingAccidentInsuranceManage.js';
import request from "@/api/connectConfig";
import { getSupplyers } from '@/utils/common';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    DialogType: {
      type: String,
      default: ''
    },
    ChildChannelConfigId: {
      type: String,
      default: ''
    },
    childsInsCorpCode: {
      type: String,
      default: ''
    },
    from: {
      type: String,
      default: 'channel'
    }
  },
  data: function data() {
    return {
      addForm: {
        insCorpCode: '',
        categoryId: '',
        typeId: ''
      },
      formLoading: false,
      formRules: {
        insCorpCode: [{
          required: true,
          message: '请选择保险公司',
          trigger: 'change'
        }],
        categoryId: [{
          required: true,
          message: '请选择驾意险类型',
          trigger: 'change'
        }],
        typeId: [{
          required: true,
          message: '请选择驾意险名称',
          trigger: 'change'
        }]
      },
      supplyers: [],
      accidentList: [],
      CateGoryIdList: []
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.supplyers = getSupplyers();
        this.$refs.addForm.resetFields();
        this.getAccidentType(this.childsInsCorpCode);
      }
      ;
    }
  },
  mounted: function mounted() {
    this.supplyers = getSupplyers();
    this.getAccidentType(this.childsInsCorpCode);
  },
  methods: {
    getAccidentType: function getAccidentType(val) {
      var _this = this;
      drivingAccidentInsuranceManageRequest.getAccidentTypeList({
        insCorpCode: val
      }).then(function (res) {
        if (res.code == 200 && res.data) {
          _this.accidentList = res.data;
          _this.CateGoryIdList = [];
          _this.addForm.categoryId = '';
          _this.addForm.typeId = '';
        }
      });
    },
    getCateGoryIdList: function getCateGoryIdList(val) {
      var _this2 = this;
      insuranceContractManageRequest.getCateGoryIdList(val).then(function (res) {
        if (res.code == 200 && res.data) {
          _this2.CateGoryIdList = res.data;
          _this2.addForm.typeId = '';
        }
      });
    },
    save: function save() {
      var _this3 = this;
      //保存数据
      this.$refs['addForm'].validate(function (valid) {
        if (valid) {
          if (_this3.from == 'apiConfig') {
            _this3.addForm.insCorpApiId = parseInt(_this3.ChildChannelConfigId);
          } else {
            _this3.addForm.insCorpChannelId = parseInt(_this3.ChildChannelConfigId);
          }
          // return
          var _request = _this3.from == 'apiConfig' ? insuranceContractManageRequest.addApiNonAutoInsurance(_this3.addForm) : insuranceContractManageRequest.addNonAutoInsurance(_this3.addForm);
          _request.then(function (res) {
            if (res.code == 200 && res.success == true) {
              _this3.formLoading = false;
              _this3.$message.success(res.msg);
              _this3.closePage();
            }
          });
        }
      });
    },
    closePage: function closePage() {
      //关闭弹窗
      this.$refs.addForm.resetFields();
      this.$emit('closePage');
    }
  }
};