import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import Pagination from '@/components/Pagination';
import DatePicker from '@/components/DatePicker';
import requestApi from '@/api/interactPlatform';
import MonitorPushDetails from './dialog/monitorPushDetails';
import OemSelect from '@/components/OemSelect';
import { getUserOrgInfoDealerOem, commonExport, getCurrentDate } from '@/utils/common';
export default {
  name: 'ExternalSubscripMonitor',
  components: {
    Pagination: Pagination,
    OemSelect: OemSelect,
    MonitorPushDetails: MonitorPushDetails,
    DatePicker: DatePicker
  },
  mixins: [initHeight],
  data: function data() {
    return {
      searchForm: {
        createdTimeStart: null,
        createdTimeEnd: null
      },
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      multipleSelection: [],
      loading: false,
      bizType: localStorage.getItem("bizType") || '',
      orgForm: {
        salesOrgCode: '',
        salesOrgName: '',
        salesOrgType: ''
      },
      pushStateList: [{
        value: 'NEW',
        label: '新建'
      }, {
        value: 'SUCCESS',
        label: '成功'
      }, {
        value: 'FAILURE',
        label: '失败'
      }, {
        value: 'FAILURE_RETRY',
        label: '失败尝试重发'
      }, {
        value: 'INPROGRESS',
        label: '进行中'
      }],
      subscriptionList: [],
      showDialog: false,
      outputRecordId: "",
      outputRecordFlowNo: "",
      drawer: false,
      outputRecordLastSendTime: [],
      createdStartEndTime: [getCurrentDate(), getCurrentDate()],
      emptyText: '默认不加载数据，请点击查询进行搜索'
    };
  },
  created: function created() {
    this.getSubscriptionList();
    if (this.bizType === "DEALER") {
      this.initData();
    }
    ;
  },
  methods: {
    exportExcels: function exportExcels() {
      //导出
      var searchForm = _objectSpread(_objectSpread(_objectSpread({}, this.searchForm), this.listQuery), {}, {
        count: false
      });
      var exporParams = {
        total: this.total,
        limitNum: 200000,
        fileName: '外部交互监控',
        exportUrl: '/tp-data-process-service/duip/external/record/search',
        searchForm: searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    getSubscriptionList: function getSubscriptionList() {
      var _this = this;
      requestApi.getPushData().then(function (res) {
        if (res.code == 200) {
          _this.subscriptionList = res.data;
        }
        ;
      });
    },
    // 初始化数据
    initData: function initData() {
      var orgObj = getUserOrgInfoDealerOem(this.bizType);
      this.searchForm.requestOrgCustomCode = orgObj.salesOrgCode;
      this.searchForm.requestOrgCustomName = orgObj.salesOrgName;
    },
    handleNodeClick: function handleNodeClick(data, node, target) {
      // 机构树点击事件
      this.searchForm.requestOrgCustomCode = data.salesOrgCode;
      this.searchForm.requestOrgCustomName = data.salesOrgName;
      // this.searchForm.areaType = data.salesOrgType;
    },
    // 日期更改监听事件
    getPickersDate: function getPickersDate(name, val) {
      if (val && val.length > 1) {
        this[name] = val;
      } else {
        this[name] = [];
      }
    },
    // 重置搜索表单
    resetSearch: function resetSearch() {
      this.searchForm = {
        requestOrgCustomName: this.bizType === 'OEM' ? '' : this.searchForm.requestOrgCustomName,
        requestOrgCustomCode: this.bizType === 'OEM' ? '' : this.searchForm.requestOrgCustomCode,
        createdTimeStart: getCurrentDate(),
        createdTimeEnd: getCurrentDate()
      };
      this.orgForm = {
        salesOrgName: this.bizType === 'OEM' ? '全部' : '',
        salesOrgType: '',
        salesOrgCode: ''
      };
      this.outputRecordLastSendTime = [];
      this.createdStartEndTime = [getCurrentDate(), getCurrentDate()];
    },
    // 搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    // 刷新
    breakData: function breakData() {
      // this.resetSearch();
      this.loadData();
    },
    // 获取列表数据
    loadData: function loadData() {
      var _this2 = this;
      this.loading = true;
      this.showDialog = false;
      this.drawer = false;
      if (this.outputRecordLastSendTime) {
        this.searchForm.outputRecordLastSendTimeStart = this.outputRecordLastSendTime[0] ? this.outputRecordLastSendTime[0].split(' ')[0] + ' 00:00:00' : null;
        this.searchForm.outputRecordLastSendTimeEnd = this.outputRecordLastSendTime[1] ? this.outputRecordLastSendTime[1].split(' ')[0] + ' 23:59:59' : null;
      }
      if (this.createdStartEndTime) {
        this.searchForm.createdTimeStart = this.createdStartEndTime[0] ? this.createdStartEndTime[0].split(' ')[0] + ' 00:00:00' : null;
        this.searchForm.createdTimeEnd = this.createdStartEndTime[1] ? this.createdStartEndTime[1].split(' ')[0] + ' 23:59:59' : null;
      }
      var postData = _.cloneDeep(this.searchForm);
      postData.requestOrgCustomName = null;
      requestApi.getDuipoutputrecordList(this.listQuery.pageNum, this.listQuery.pageSize, postData).then(function (res) {
        _this2.loading = false;
        if (res.code === 200) {
          _this2.tableData = res.data.list;
          if (_.isEmpty(_this2.tableData)) _this2.emptyText = "暂无数据";
          _this2.total = res.data.page.recordsTotal;
        } else {
          _this2.emptyText = "暂无数据";
        }
      }).catch(function (err) {
        _this2.loading = false;
      });
    },
    // 列表行选择事件
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    view: function view(item) {
      this.showDialog = true;
      this.outputRecordId = item.outputRecordId.toString();
      this.outputRecordFlowNo = item.outputRecordFlowNo.toString();
    },
    ReSendData: function ReSendData(ids) {
      var _this3 = this;
      requestApi.ReSendData(ids).then(function (res) {
        if (res.code == 200) {
          _this3.$message.success(res.msg);
          _this3.loadData();
        }
      });
    },
    toPush: function toPush(item) {
      var _this4 = this;
      var ids = [item.outputRecordId];
      this.$confirm('您确定要重新推送么？', {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        _this4.ReSendData(ids);
      });
    },
    batchPush: function batchPush() {
      var _this5 = this;
      var ids = [];
      if (!_.isEmpty(this.multipleSelection)) {
        ids = _.map(this.multipleSelection, 'outputRecordId');
      }
      ;
      this.$confirm('您确定要重新推送么？', {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        _this5.ReSendData(ids);
      });
    }
  }
};