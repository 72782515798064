var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "app-m-cls" }, [
      _c(
        "div",
        { staticClass: "table-cls normal-btn-cls" },
        [
          _c(
            "el-card",
            { staticClass: "box-card", attrs: { shadow: "never" } },
            [
              _c(
                "div",
                {
                  staticClass: "search-box",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v("组织机构：")]),
                      _c("OemSelect", {
                        attrs: { canClickArea: true, searchForm: _vm.orgForm },
                        on: { nodeClick: _vm.nodeClick },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v("经销商编码：")]),
                      _c("el-input", {
                        staticStyle: { width: "150px" },
                        attrs: {
                          maxlength: "40",
                          placeholder: "请输入经销商编码",
                          clearable: "",
                        },
                        model: {
                          value: _vm.searchForm.dealerCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchForm, "dealerCode", $$v)
                          },
                          expression: "searchForm.dealerCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v("保险公司：")]),
                      _c(
                        "el-select",
                        {
                          staticStyle: { "max-width": "140px" },
                          attrs: {
                            filterable: "",
                            placeholder: "请选择保险公司",
                            clearable: "",
                          },
                          model: {
                            value: _vm.searchForm.insCorpCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "insCorpCode", $$v)
                            },
                            expression: "searchForm.insCorpCode",
                          },
                        },
                        _vm._l(_vm.supplyers, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.shortName, value: item.code },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v("检查结果：")]),
                      _c(
                        "el-select",
                        {
                          staticStyle: { "max-width": "140px" },
                          attrs: {
                            filterable: "",
                            placeholder: "请选择检查结果",
                            clearable: "",
                          },
                          model: {
                            value: _vm.searchForm.channelSetStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "channelSetStatus", $$v)
                            },
                            expression: "searchForm.channelSetStatus",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "已检查", value: "CSS02" },
                          }),
                          _c("el-option", {
                            attrs: { label: "未检查", value: "CSS01" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", icon: "el-icon-refresh-left" },
                          on: { click: _vm.resetSearch },
                        },
                        [_vm._v("重置")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            size: "mini",
                            type: "primary",
                            icon: "el-icon-search",
                          },
                          on: { click: _vm.searchData },
                        },
                        [_vm._v("查询")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "search-btn" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: { click: _vm.breakData },
                        },
                        [_vm._v("刷新")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: { click: _vm.exportFn },
                        },
                        [_vm._v("导出")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-table",
                {
                  ref: "multipleTable",
                  staticStyle: { width: "100%" },
                  attrs: {
                    size: "mini",
                    border: "",
                    data: _vm.tableData,
                    height: _vm.tableHeightJD,
                    "header-cell-style": { background: "#F7F7F7" },
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "dealerName",
                      label: "经销商",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "dealerCode",
                      label: "经销商编码",
                      "min-width": "100",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "省市区",
                      "min-width": "100",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(" " + _vm._s(_vm.cityData(scope.row)) + " "),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "address",
                      label: "详细地址",
                      "min-width": "100",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "insCorpName",
                      label: "保险公司",
                      "min-width": "100",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "applyTime",
                      label: "提交时间",
                      width: "150",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "approvalTime",
                      label: "审批时间",
                      width: "150",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "channelSetStatusDisplay",
                      label: "渠道设置状态",
                      align: "center",
                      width: "120",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(scope.row.channelSetStatusDisplay) +
                                " "
                            ),
                            _c("el-switch", {
                              attrs: {
                                value: scope.row.channelSetStatus,
                                "active-value": "CSS02",
                              },
                              nativeOn: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.enableChange(scope.row)
                                },
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作", align: "center", width: "80" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { plain: "", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.toView("Management", scope.row)
                                  },
                                },
                              },
                              [_vm._v("查看")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("pagination", {
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.pageNum,
                  limit: _vm.listQuery.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageNum", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageSize", $event)
                  },
                  pagination: function ($event) {
                    return _vm.loadData()
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }