var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageContainer", [
        _c("div", { attrs: { slot: "main" }, slot: "main" }, [
          _c(
            "div",
            {
              staticClass: "app-m-cls",
              staticStyle: { "min-height": "calc(100vh - 130px)" },
            },
            [
              _c(
                "div",
                { staticClass: "table-cls normal-btn-cls" },
                [
                  _c(
                    "el-card",
                    { staticClass: "box-card", attrs: { shadow: "never" } },
                    [
                      _c("FormLabel", {
                        attrs: { "form-label": "保险业务开通" },
                      }),
                      _vm.name === "Submit"
                        ? _c(
                            "el-form",
                            {
                              ref: "detail",
                              staticStyle: { "font-size": "14px" },
                              attrs: {
                                model: _vm.form,
                                "label-width": "140px",
                                rules: _vm.formRules,
                              },
                            },
                            [
                              _c(
                                "el-divider",
                                { attrs: { "content-position": "left" } },
                                [
                                  _vm._v("填写信息"),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm._.toUpper(
                                              _vm.form.insCorpCode
                                            ) == "QITA",
                                          expression:
                                            "_.toUpper(form.insCorpCode) == 'QITA'",
                                        },
                                      ],
                                      staticStyle: {
                                        "margin-left": "5px",
                                        color: "#666",
                                      },
                                    },
                                    [
                                      _vm._v("("),
                                      _c("i", { staticClass: "el-icon-info" }),
                                      _vm._v(
                                        _vm._s(
                                          _vm.getInsCorpFullName(
                                            _vm.form.insCorpCode
                                          )
                                        ) + ")"
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "经销商名称：",
                                            "label-width": "140px",
                                            prop: "dealerName",
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(_vm.form.dealerName)),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "经销商编码：",
                                            prop: "dealerCode",
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(_vm.form.dealerCode)),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "省市区：",
                                            prop: "districtCode",
                                            "label-width": "140px",
                                          },
                                        },
                                        [
                                          _c("el-cascader", {
                                            ref: "addrssCascader",
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              placeholder: "请选择省市区",
                                              options: _vm.cityList,
                                              filterable: "",
                                            },
                                            on: { change: _vm.handleChange },
                                            model: {
                                              value: _vm.form.districtCode,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "districtCode",
                                                  $$v
                                                )
                                              },
                                              expression: "form.districtCode",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "详细地址：",
                                            prop: "address",
                                            "label-width": "140px",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入详细地址",
                                              size: "mini",
                                              clearable: "",
                                            },
                                            model: {
                                              value: _vm.form.address,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "address",
                                                  $$v
                                                )
                                              },
                                              expression: "form.address",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "联系人：",
                                            prop: "contactName",
                                            "label-width": "140px",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入联系人",
                                              size: "mini",
                                              clearable: "",
                                            },
                                            model: {
                                              value: _vm.form.contactName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "contactName",
                                                  $$v
                                                )
                                              },
                                              expression: "form.contactName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "联系方式：",
                                            prop: "contactPhone",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入联系方式",
                                              clearable: "",
                                              maxlength: "11",
                                              onkeyup:
                                                "value=value.replace(/[^0-9a-zA-Z\\-.]+/,'')",
                                            },
                                            model: {
                                              value: _vm.form.contactPhone,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "contactPhone",
                                                  $$v
                                                )
                                              },
                                              expression: "form.contactPhone",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "微信号：",
                                            prop: "wxId",
                                            "label-width": "140px",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入微信号",
                                              size: "mini",
                                              clearable: "",
                                            },
                                            model: {
                                              value: _vm.form.wxId,
                                              callback: function ($$v) {
                                                _vm.$set(_vm.form, "wxId", $$v)
                                              },
                                              expression: "form.wxId",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "合作业务类型：",
                                            prop: "cooperationBusinessType",
                                            "label-width": "140px",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio-group",
                                            {
                                              staticClass: "radio-flex",
                                              model: {
                                                value:
                                                  _vm.form
                                                    .cooperationBusinessType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "cooperationBusinessType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.cooperationBusinessType",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-radio",
                                                { attrs: { label: "CBT02" } },
                                                [
                                                  _vm._v(
                                                    "兼业代理业务（自店保险牌照）"
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "el-radio",
                                                {
                                                  staticStyle: {
                                                    margin: "5px 0",
                                                  },
                                                  attrs: { label: "CBT03" },
                                                },
                                                [
                                                  _vm._v(
                                                    "非兼业代理业务（个人代理、专业代理、 经纪公司）"
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "el-radio",
                                                { attrs: { label: "CBT01" } },
                                                [_vm._v("其他")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-divider",
                                { attrs: { "content-position": "left" } },
                                [_vm._v("附件")]
                              ),
                              _vm.controlFile !== "PICC"
                                ? _c(
                                    "el-row",
                                    { staticClass: "elRowComClass" },
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 12 } },
                                        [
                                          _c("p", [_vm._v("本店营业执照：")]),
                                          _c(
                                            "el-upload",
                                            {
                                              ref: "upload",
                                              attrs: {
                                                "before-remove": function () {
                                                  return (_vm.formData = "")
                                                },
                                                "show-file-list": false,
                                                action: "",
                                                "http-request": function (
                                                  param
                                                ) {
                                                  return _vm.uploadFile(
                                                    param,
                                                    "BUSINESS_LICENSE"
                                                  )
                                                },
                                                "file-list": _vm.fileList,
                                                accept: "*",
                                                "list-type": "text",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    icon: "el-icon-upload2",
                                                    size: "mini",
                                                    type: "primary",
                                                    plain: "",
                                                  },
                                                },
                                                [_vm._v("上传文件")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm.form.file.length
                                            ? _c(
                                                "div",
                                                _vm._l(
                                                  _vm.form.file,
                                                  function (file, i) {
                                                    return _c(
                                                      "div",
                                                      { key: i },
                                                      [
                                                        file.fileType ===
                                                        "BUSINESS_LICENSE"
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "file-box",
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "file-text",
                                                                    attrs: {
                                                                      title:
                                                                        file.fileName,
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.handle(
                                                                            file
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        file.fileName
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "el-button",
                                                                  {
                                                                    attrs: {
                                                                      type: "danger",
                                                                      plain: "",
                                                                      size: "mini",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.stopPropagation()
                                                                          return _vm.ondelete(
                                                                            i
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "删除"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _vm.controlFile !== "QITA" &&
                                      _vm.controlFile !== "TMNF"
                                        ? _c(
                                            "el-col",
                                            {
                                              staticStyle: {
                                                "font-size": "14px",
                                              },
                                              attrs: { span: 12 },
                                            },
                                            [
                                              _c("p", [
                                                _vm._v("本店兼业代理许可证："),
                                              ]),
                                              _c(
                                                "el-upload",
                                                {
                                                  ref: "upload",
                                                  attrs: {
                                                    "before-remove":
                                                      function () {
                                                        return (_vm.formData =
                                                          "")
                                                      },
                                                    "show-file-list": false,
                                                    action: "",
                                                    "http-request": function (
                                                      param
                                                    ) {
                                                      return _vm.uploadFile(
                                                        param,
                                                        "OPERATING_LICENSE"
                                                      )
                                                    },
                                                    "file-list": _vm.fileList,
                                                    accept: "*",
                                                    "list-type": "text",
                                                    disabled:
                                                      _vm.form
                                                        .fileCheckboxAft08 &&
                                                      _vm.controlFile ===
                                                        "CPIC",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        icon: "el-icon-upload2",
                                                        size: "mini",
                                                        type: "primary",
                                                        plain: "",
                                                        disabled:
                                                          _vm.form
                                                            .fileCheckboxAft08 &&
                                                          _vm.controlFile ===
                                                            "CPIC",
                                                      },
                                                    },
                                                    [_vm._v("上传文件")]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm.form.file.length
                                                ? _c(
                                                    "div",
                                                    _vm._l(
                                                      _vm.form.file,
                                                      function (file, i) {
                                                        return _c(
                                                          "div",
                                                          { key: i },
                                                          [
                                                            file.fileType ===
                                                            "OPERATING_LICENSE"
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "file-box",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "file-text",
                                                                        attrs: {
                                                                          title:
                                                                            file.fileName,
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.handle(
                                                                                file
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            file.fileName
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "el-button",
                                                                      {
                                                                        attrs: {
                                                                          type: "danger",
                                                                          plain:
                                                                            "",
                                                                          size: "mini",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              $event.stopPropagation()
                                                                              return _vm.ondelete(
                                                                                i
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "删除"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.controlFile === "TMNF"
                                        ? _c(
                                            "el-col",
                                            {
                                              staticStyle: {
                                                "font-size": "14px",
                                              },
                                              attrs: { span: 12 },
                                            },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticStyle: {
                                                    float: "left",
                                                  },
                                                },
                                                [_vm._v("配置信息收集表：")]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "file-box" },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "file-text",
                                                      staticStyle: {
                                                        width: "100%",
                                                      },
                                                      attrs: {
                                                        title: "下载模板",
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.downFileTemplateAFT04,
                                                      },
                                                    },
                                                    [_vm._v(" 下载模板")]
                                                  ),
                                                ]
                                              ),
                                              _c("div", {
                                                staticStyle: { clear: "both" },
                                              }),
                                              _c(
                                                "el-upload",
                                                {
                                                  ref: "upload",
                                                  attrs: {
                                                    "before-remove":
                                                      function () {
                                                        return (_vm.formData =
                                                          "")
                                                      },
                                                    "show-file-list": false,
                                                    action: "",
                                                    "http-request": function (
                                                      param
                                                    ) {
                                                      return _vm.uploadFile(
                                                        param,
                                                        "STORE_CHANNEL_INFORMATION_TABLE"
                                                      )
                                                    },
                                                    "file-list": _vm.fileList,
                                                    accept: "*",
                                                    "list-type": "text",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        icon: "el-icon-upload2",
                                                        size: "mini",
                                                        type: "primary",
                                                        plain: "",
                                                      },
                                                    },
                                                    [_vm._v("上传文件")]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm.form.file.length
                                                ? _c(
                                                    "div",
                                                    _vm._l(
                                                      _vm.form.file,
                                                      function (file, i) {
                                                        return _c(
                                                          "div",
                                                          { key: i },
                                                          [
                                                            file.fileType ===
                                                            "STORE_CHANNEL_INFORMATION_TABLE"
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "file-box",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "file-text",
                                                                        attrs: {
                                                                          title:
                                                                            file.fileName,
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.handle(
                                                                                file
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            file.fileName
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "el-button",
                                                                      {
                                                                        attrs: {
                                                                          type: "danger",
                                                                          plain:
                                                                            "",
                                                                          size: "mini",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              $event.stopPropagation()
                                                                              return _vm.ondelete(
                                                                                i
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "删除"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.controlFile === "CPIC"
                                ? _c(
                                    "el-row",
                                    { staticClass: "elRowComClass" },
                                    [
                                      _c(
                                        "el-col",
                                        {
                                          staticStyle: { "font-size": "14px" },
                                          attrs: { span: 12 },
                                        },
                                        [
                                          _c(
                                            "p",
                                            { staticStyle: { float: "left" } },
                                            [
                                              _vm._v(
                                                "经销商同意使用系统告知书："
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "file-box" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "file-text",
                                                  attrs: { title: "下载模板" },
                                                  on: {
                                                    click:
                                                      _vm.downFileTemplateAFT05,
                                                  },
                                                },
                                                [_vm._v(" 下载模板")]
                                              ),
                                            ]
                                          ),
                                          _c("div", {
                                            staticStyle: { clear: "both" },
                                          }),
                                          _c(
                                            "el-upload",
                                            {
                                              ref: "upload",
                                              attrs: {
                                                "before-remove": function () {
                                                  return (_vm.formData = "")
                                                },
                                                "show-file-list": false,
                                                action: "",
                                                "http-request": function (
                                                  param
                                                ) {
                                                  return _vm.uploadFile(
                                                    param,
                                                    "AFT05"
                                                  )
                                                },
                                                "file-list": _vm.fileList,
                                                accept: "*",
                                                "list-type": "text",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    icon: "el-icon-upload2",
                                                    size: "mini",
                                                    type: "primary",
                                                    plain: "",
                                                  },
                                                },
                                                [_vm._v("上传文件")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm.form.file.length
                                            ? _c(
                                                "div",
                                                _vm._l(
                                                  _vm.form.file,
                                                  function (file, i) {
                                                    return _c(
                                                      "div",
                                                      { key: i },
                                                      [
                                                        file.fileType ===
                                                        "AFT05"
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "file-box",
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "file-text",
                                                                    attrs: {
                                                                      title:
                                                                        file.fileName,
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.handle(
                                                                            file
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        file.fileName
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "el-button",
                                                                  {
                                                                    attrs: {
                                                                      type: "danger",
                                                                      plain: "",
                                                                      size: "mini",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.stopPropagation()
                                                                          return _vm.ondelete(
                                                                            i
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "删除"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        {
                                          staticStyle: { "font-size": "14px" },
                                          attrs: { span: 12 },
                                        },
                                        [
                                          _c(
                                            "p",
                                            { staticStyle: { float: "left" } },
                                            [
                                              _vm._v(
                                                "太平洋财险与经销商(本店)的兼业代理合作协议："
                                              ),
                                            ]
                                          ),
                                          _c("div", {
                                            staticStyle: { clear: "both" },
                                          }),
                                          _c(
                                            "el-upload",
                                            {
                                              ref: "upload",
                                              attrs: {
                                                "before-remove": function () {
                                                  return (_vm.formData = "")
                                                },
                                                "show-file-list": false,
                                                action: "",
                                                "http-request": function (
                                                  param
                                                ) {
                                                  return _vm.uploadFile(
                                                    param,
                                                    "AFT08"
                                                  )
                                                },
                                                "file-list": _vm.fileList,
                                                accept: "*",
                                                "list-type": "text",
                                                disabled:
                                                  _vm.form.fileCheckboxAft08 &&
                                                  _vm.controlFile === "CPIC",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    icon: "el-icon-upload2",
                                                    size: "mini",
                                                    type: "primary",
                                                    plain: "",
                                                    disabled:
                                                      _vm.form
                                                        .fileCheckboxAft08 &&
                                                      _vm.controlFile ===
                                                        "CPIC",
                                                  },
                                                },
                                                [_vm._v("上传文件")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm.form.file.length
                                            ? _c(
                                                "div",
                                                _vm._l(
                                                  _vm.form.file,
                                                  function (file, i) {
                                                    return _c(
                                                      "div",
                                                      { key: i },
                                                      [
                                                        file.fileType ===
                                                        "AFT08"
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "file-box",
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "file-text",
                                                                    attrs: {
                                                                      title:
                                                                        file.fileName,
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.handle(
                                                                            file
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        file.fileName
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "el-button",
                                                                  {
                                                                    attrs: {
                                                                      type: "danger",
                                                                      plain: "",
                                                                      size: "mini",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.stopPropagation()
                                                                          return _vm.ondelete(
                                                                            i
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "删除"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.controlFile === "CPIC"
                                ? _c(
                                    "div",
                                    { staticClass: "elRowComClass" },
                                    [
                                      _c(
                                        "el-checkbox",
                                        {
                                          on: { change: _vm.changeVal },
                                          model: {
                                            value: _vm.form.fileCheckboxAft08,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "fileCheckboxAft08",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.fileCheckboxAft08",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "无兼业代理资质，采用专代/经纪中介机构代理保险业务资质"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.form.fileCheckboxAft08 &&
                              _vm.controlFile === "CPIC"
                                ? _c(
                                    "el-row",
                                    { staticClass: "elRowComClass" },
                                    [
                                      _c(
                                        "el-col",
                                        {
                                          staticStyle: { "font-size": "14px" },
                                          attrs: { span: 8 },
                                        },
                                        [
                                          _c("p", [
                                            _vm._v("中介机构营业执照："),
                                          ]),
                                          _c(
                                            "el-upload",
                                            {
                                              ref: "upload",
                                              attrs: {
                                                "before-remove": function () {
                                                  return (_vm.formData = "")
                                                },
                                                "show-file-list": false,
                                                action: "",
                                                "http-request": function (
                                                  param
                                                ) {
                                                  return _vm.uploadFile(
                                                    param,
                                                    "AFT11"
                                                  )
                                                },
                                                "file-list": _vm.fileList,
                                                accept: "*",
                                                "list-type": "text",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    icon: "el-icon-upload2",
                                                    size: "mini",
                                                    type: "primary",
                                                    plain: "",
                                                  },
                                                },
                                                [_vm._v("上传文件")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm.form.file.length
                                            ? _c(
                                                "div",
                                                _vm._l(
                                                  _vm.form.file,
                                                  function (file, i) {
                                                    return _c(
                                                      "div",
                                                      { key: i },
                                                      [
                                                        file.fileType ===
                                                        "AFT11"
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "file-box",
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "file-text",
                                                                    attrs: {
                                                                      title:
                                                                        file.fileName,
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.handle(
                                                                            file
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        file.fileName
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "el-button",
                                                                  {
                                                                    attrs: {
                                                                      type: "danger",
                                                                      plain: "",
                                                                      size: "mini",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.stopPropagation()
                                                                          return _vm.ondelete(
                                                                            i
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "删除"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        {
                                          staticStyle: { "font-size": "14px" },
                                          attrs: { span: 8 },
                                        },
                                        [
                                          _c("p", [
                                            _vm._v("中介机构保险中介许可证："),
                                          ]),
                                          _c(
                                            "el-upload",
                                            {
                                              ref: "upload",
                                              attrs: {
                                                "before-remove": function () {
                                                  return (_vm.formData = "")
                                                },
                                                "show-file-list": false,
                                                action: "",
                                                "http-request": function (
                                                  param
                                                ) {
                                                  return _vm.uploadFile(
                                                    param,
                                                    "AFT12"
                                                  )
                                                },
                                                "file-list": _vm.fileList,
                                                accept: "*",
                                                "list-type": "text",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    icon: "el-icon-upload2",
                                                    size: "mini",
                                                    type: "primary",
                                                    plain: "",
                                                  },
                                                },
                                                [_vm._v("上传文件")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm.form.file.length
                                            ? _c(
                                                "div",
                                                _vm._l(
                                                  _vm.form.file,
                                                  function (file, i) {
                                                    return _c(
                                                      "div",
                                                      { key: i },
                                                      [
                                                        file.fileType ===
                                                        "AFT12"
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "file-box",
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "file-text",
                                                                    attrs: {
                                                                      title:
                                                                        file.fileName,
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.handle(
                                                                            file
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        file.fileName
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "el-button",
                                                                  {
                                                                    attrs: {
                                                                      type: "danger",
                                                                      plain: "",
                                                                      size: "mini",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.stopPropagation()
                                                                          return _vm.ondelete(
                                                                            i
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "删除"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        {
                                          staticStyle: { "font-size": "14px" },
                                          attrs: { span: 8 },
                                        },
                                        [
                                          _c("p", [
                                            _vm._v(
                                              "中介机构与经销商(本店)的代理合作协议："
                                            ),
                                          ]),
                                          _c(
                                            "el-upload",
                                            {
                                              ref: "upload",
                                              attrs: {
                                                "before-remove": function () {
                                                  return (_vm.formData = "")
                                                },
                                                "show-file-list": false,
                                                action: "",
                                                "http-request": function (
                                                  param
                                                ) {
                                                  return _vm.uploadFile(
                                                    param,
                                                    "AFT13"
                                                  )
                                                },
                                                "file-list": _vm.fileList,
                                                accept: "*",
                                                "list-type": "text",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    icon: "el-icon-upload2",
                                                    size: "mini",
                                                    type: "primary",
                                                    plain: "",
                                                  },
                                                },
                                                [_vm._v("上传文件")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm.form.file.length
                                            ? _c(
                                                "div",
                                                _vm._l(
                                                  _vm.form.file,
                                                  function (file, i) {
                                                    return _c(
                                                      "div",
                                                      { key: i },
                                                      [
                                                        file.fileType ===
                                                        "AFT13"
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "file-box",
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "file-text",
                                                                    attrs: {
                                                                      title:
                                                                        file.fileName,
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.handle(
                                                                            file
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        file.fileName
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "el-button",
                                                                  {
                                                                    attrs: {
                                                                      type: "danger",
                                                                      plain: "",
                                                                      size: "mini",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.stopPropagation()
                                                                          return _vm.ondelete(
                                                                            i
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "删除"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.form.fileCheckboxAft08 &&
                              _vm.controlFile === "CPIC"
                                ? _c(
                                    "el-row",
                                    { staticClass: "elRowComClass" },
                                    [
                                      _c(
                                        "el-col",
                                        {
                                          staticStyle: { "font-size": "14px" },
                                          attrs: { span: 8 },
                                        },
                                        [
                                          _c("p", [
                                            _vm._v(
                                              "太平洋财险与经销商(本店)的专代代理合作协议："
                                            ),
                                          ]),
                                          _c(
                                            "el-upload",
                                            {
                                              ref: "upload",
                                              attrs: {
                                                "before-remove": function () {
                                                  return (_vm.formData = "")
                                                },
                                                "show-file-list": false,
                                                action: "",
                                                "http-request": function (
                                                  param
                                                ) {
                                                  return _vm.uploadFile(
                                                    param,
                                                    "AFT14"
                                                  )
                                                },
                                                "file-list": _vm.fileList,
                                                accept: "*",
                                                "list-type": "text",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    icon: "el-icon-upload2",
                                                    size: "mini",
                                                    type: "primary",
                                                    plain: "",
                                                  },
                                                },
                                                [_vm._v("上传文件")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm.form.file.length
                                            ? _c(
                                                "div",
                                                _vm._l(
                                                  _vm.form.file,
                                                  function (file, i) {
                                                    return _c(
                                                      "div",
                                                      { key: i },
                                                      [
                                                        file.fileType ===
                                                        "AFT14"
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "file-box",
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "file-text",
                                                                    attrs: {
                                                                      title:
                                                                        file.fileName,
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.handle(
                                                                            file
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        file.fileName
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "el-button",
                                                                  {
                                                                    attrs: {
                                                                      type: "danger",
                                                                      plain: "",
                                                                      size: "mini",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.stopPropagation()
                                                                          return _vm.ondelete(
                                                                            i
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "删除"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "el-row",
                                { staticClass: "elRowComClass" },
                                [
                                  _vm.controlFile === "PAIC" ||
                                  _vm.controlFile === "PICC"
                                    ? _c(
                                        "el-col",
                                        {
                                          staticStyle: { "font-size": "14px" },
                                          attrs: { span: 12 },
                                        },
                                        [
                                          _c(
                                            "p",
                                            { staticStyle: { float: "left" } },
                                            [_vm._v("授权证书：")]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "file-box" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "file-text",
                                                  attrs: { title: "下载模板" },
                                                  on: {
                                                    click:
                                                      _vm.downFileTemplateAFT03,
                                                  },
                                                },
                                                [_vm._v(" 下载模板")]
                                              ),
                                            ]
                                          ),
                                          _c("div", {
                                            staticStyle: { clear: "both" },
                                          }),
                                          _c(
                                            "el-upload",
                                            {
                                              ref: "upload",
                                              attrs: {
                                                "before-remove": function () {
                                                  return (_vm.formData = "")
                                                },
                                                "show-file-list": false,
                                                action: "",
                                                "http-request": function (
                                                  param
                                                ) {
                                                  return _vm.uploadFile(
                                                    param,
                                                    "AUTHORIZATION_CERTIFICATE"
                                                  )
                                                },
                                                "file-list": _vm.fileList,
                                                accept: "*",
                                                "list-type": "text",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    icon: "el-icon-upload2",
                                                    size: "mini",
                                                    type: "primary",
                                                    plain: "",
                                                  },
                                                },
                                                [_vm._v("上传文件")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm.form.file.length
                                            ? _c(
                                                "div",
                                                _vm._l(
                                                  _vm.form.file,
                                                  function (file, i) {
                                                    return _c(
                                                      "div",
                                                      { key: i },
                                                      [
                                                        file.fileType ===
                                                        "AUTHORIZATION_CERTIFICATE"
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "file-box",
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "file-text",
                                                                    attrs: {
                                                                      title:
                                                                        file.fileName,
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.handle(
                                                                            file
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        file.fileName
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "el-button",
                                                                  {
                                                                    attrs: {
                                                                      type: "danger",
                                                                      plain: "",
                                                                      size: "mini",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.stopPropagation()
                                                                          return _vm.ondelete(
                                                                            i
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "删除"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.controlFile === "CPIC"
                                    ? _c(
                                        "el-col",
                                        {
                                          staticStyle: { "font-size": "14px" },
                                          attrs: { span: 12 },
                                        },
                                        [
                                          _c(
                                            "p",
                                            { staticStyle: { float: "left" } },
                                            [_vm._v("平台使用需求函：")]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "file-box" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "file-text",
                                                  attrs: { title: "下载模板" },
                                                  on: {
                                                    click:
                                                      _vm.downFileTemplateAFT06,
                                                  },
                                                },
                                                [_vm._v(" 下载模板")]
                                              ),
                                            ]
                                          ),
                                          _c("div", {
                                            staticStyle: { clear: "both" },
                                          }),
                                          _c(
                                            "el-upload",
                                            {
                                              ref: "upload",
                                              attrs: {
                                                "before-remove": function () {
                                                  return (_vm.formData = "")
                                                },
                                                "show-file-list": false,
                                                action: "",
                                                "http-request": function (
                                                  param
                                                ) {
                                                  return _vm.uploadFile(
                                                    param,
                                                    "AFT06"
                                                  )
                                                },
                                                "file-list": _vm.fileList,
                                                accept: "*",
                                                "list-type": "text",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    icon: "el-icon-upload2",
                                                    size: "mini",
                                                    type: "primary",
                                                    plain: "",
                                                  },
                                                },
                                                [_vm._v("上传文件")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm.form.file.length
                                            ? _c(
                                                "div",
                                                _vm._l(
                                                  _vm.form.file,
                                                  function (file, i) {
                                                    return _c(
                                                      "div",
                                                      { key: i },
                                                      [
                                                        file.fileType ===
                                                        "AFT06"
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "file-box",
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "file-text",
                                                                    attrs: {
                                                                      title:
                                                                        file.fileName,
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.handle(
                                                                            file
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        file.fileName
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "el-button",
                                                                  {
                                                                    attrs: {
                                                                      type: "danger",
                                                                      plain: "",
                                                                      size: "mini",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.stopPropagation()
                                                                          return _vm.ondelete(
                                                                            i
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "删除"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.controlFile !== "QITA" &&
                                  _vm.controlFile !== "TMNF"
                                    ? _c(
                                        "el-col",
                                        {
                                          staticStyle: { "font-size": "14px" },
                                          attrs: { span: 12 },
                                        },
                                        [
                                          _c(
                                            "p",
                                            { staticStyle: { float: "left" } },
                                            [_vm._v("配置信息收集表：")]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "file-box" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "file-text",
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: { title: "下载模板" },
                                                  on: {
                                                    click:
                                                      _vm.downFileTemplateAFT04,
                                                  },
                                                },
                                                [_vm._v(" 下载模板")]
                                              ),
                                            ]
                                          ),
                                          _c("div", {
                                            staticStyle: { clear: "both" },
                                          }),
                                          _c(
                                            "el-upload",
                                            {
                                              ref: "upload",
                                              attrs: {
                                                "before-remove": function () {
                                                  return (_vm.formData = "")
                                                },
                                                "show-file-list": false,
                                                action: "",
                                                "http-request": function (
                                                  param
                                                ) {
                                                  return _vm.uploadFile(
                                                    param,
                                                    "STORE_CHANNEL_INFORMATION_TABLE"
                                                  )
                                                },
                                                "file-list": _vm.fileList,
                                                accept: "*",
                                                "list-type": "text",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    icon: "el-icon-upload2",
                                                    size: "mini",
                                                    type: "primary",
                                                    plain: "",
                                                  },
                                                },
                                                [_vm._v("上传文件")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm.form.file.length
                                            ? _c(
                                                "div",
                                                _vm._l(
                                                  _vm.form.file,
                                                  function (file, i) {
                                                    return _c(
                                                      "div",
                                                      { key: i },
                                                      [
                                                        file.fileType ===
                                                        "STORE_CHANNEL_INFORMATION_TABLE"
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "file-box",
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "file-text",
                                                                    attrs: {
                                                                      title:
                                                                        file.fileName,
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.handle(
                                                                            file
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        file.fileName
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "el-button",
                                                                  {
                                                                    attrs: {
                                                                      type: "danger",
                                                                      plain: "",
                                                                      size: "mini",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.stopPropagation()
                                                                          return _vm.ondelete(
                                                                            i
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "删除"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.name === "details"
                        ? _c(
                            "el-form",
                            {
                              ref: "detail",
                              attrs: {
                                model: _vm.form,
                                "label-width": "160px",
                              },
                            },
                            [
                              _c(
                                "el-divider",
                                { attrs: { "content-position": "left" } },
                                [
                                  _vm._v("业务开通信息"),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm._.toUpper(
                                              _vm.form.insCorpCode
                                            ) == "QITA",
                                          expression:
                                            "_.toUpper(form.insCorpCode) == 'QITA'",
                                        },
                                      ],
                                      staticStyle: {
                                        "margin-left": "5px",
                                        color: "#666",
                                      },
                                    },
                                    [
                                      _vm._v("("),
                                      _c("i", { staticClass: "el-icon-info" }),
                                      _vm._v(
                                        _vm._s(
                                          _vm.getInsCorpFullName(
                                            _vm.form.insCorpCode
                                          )
                                        ) + ")"
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "保险公司：" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.form.insCorpName) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "提交时间：" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.form.applyTime) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "经销商名称：" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.form.dealerName) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "经销商编码：" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.form.dealerCode) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "省市区：" } },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.cityData()) + " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "详细地址：" } },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.form.address) + " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "联系人：" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.form.contactName) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "联系方式：" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.form.contactPhone) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "微信号：" } },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.form.wxId) + " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "状态：" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.form.applyStatusDisplay
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "合作业务类型：" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.form
                                                  .cooperationBusinessTypeDisplay
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.form.applyStatus == "PENDING_AUDIT"
                                    ? _c(
                                        "el-col",
                                        { attrs: { span: 8 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "驳回原因：" } },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.form.rejectCause) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-divider",
                                    { attrs: { "content-position": "left" } },
                                    [_vm._v("附件")]
                                  ),
                                ],
                                1
                              ),
                              _vm.controlFile !== "PICC"
                                ? _c(
                                    "el-row",
                                    { staticClass: "elRowComClass" },
                                    [
                                      _c("el-col", { attrs: { span: 12 } }, [
                                        _c("p", [_vm._v("本店营业执照：")]),
                                        _vm.form.file.length
                                          ? _c(
                                              "div",
                                              _vm._l(
                                                _vm.form.file,
                                                function (file, i) {
                                                  return _c("div", { key: i }, [
                                                    file.fileType ===
                                                    "BUSINESS_LICENSE"
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "file-box",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "file-text",
                                                                attrs: {
                                                                  title:
                                                                    file.fileName,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handle(
                                                                        file
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    file.fileName
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ])
                                                }
                                              ),
                                              0
                                            )
                                          : _vm._e(),
                                      ]),
                                      _vm.controlFile !== "QITA" &&
                                      _vm.controlFile !== "TMNF"
                                        ? _c(
                                            "el-col",
                                            {
                                              staticStyle: {
                                                "font-size": "14px",
                                              },
                                              attrs: { span: 12 },
                                            },
                                            [
                                              _c("p", [
                                                _vm._v("本店兼业代理许可证："),
                                              ]),
                                              _vm.form.file.length
                                                ? _c(
                                                    "div",
                                                    _vm._l(
                                                      _vm.form.file,
                                                      function (file, i) {
                                                        return _c(
                                                          "div",
                                                          { key: i },
                                                          [
                                                            file.fileType ===
                                                            "OPERATING_LICENSE"
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "file-box",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "file-text",
                                                                        attrs: {
                                                                          title:
                                                                            file.fileName,
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.handle(
                                                                                file
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            file.fileName
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  )
                                                : _vm._e(),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.controlFile === "TMNF"
                                        ? _c(
                                            "el-col",
                                            {
                                              staticStyle: {
                                                "font-size": "14px",
                                              },
                                              attrs: { span: 12 },
                                            },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticStyle: {
                                                    float: "left",
                                                  },
                                                },
                                                [_vm._v("配置信息收集表：")]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "file-box" },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "file-text",
                                                      staticStyle: {
                                                        width: "100%",
                                                      },
                                                      attrs: {
                                                        title: "下载模板",
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.downFileTemplateAFT04,
                                                      },
                                                    },
                                                    [_vm._v(" 下载模板")]
                                                  ),
                                                ]
                                              ),
                                              _vm.form.file.length
                                                ? _c(
                                                    "div",
                                                    _vm._l(
                                                      _vm.form.file,
                                                      function (file, i) {
                                                        return _c(
                                                          "div",
                                                          { key: i },
                                                          [
                                                            file.fileType ===
                                                            "STORE_CHANNEL_INFORMATION_TABLE"
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "file-box",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "file-text",
                                                                        attrs: {
                                                                          title:
                                                                            file.fileName,
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.handle(
                                                                                file
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            file.fileName
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  )
                                                : _vm._e(),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.controlFile === "CPIC"
                                ? _c(
                                    "el-row",
                                    { staticClass: "elRowComClass" },
                                    [
                                      _c("el-col", { attrs: { span: 12 } }, [
                                        _c(
                                          "p",
                                          { staticStyle: { float: "left" } },
                                          [_vm._v("经销商同意使用系统告知书：")]
                                        ),
                                        _c("div", { staticClass: "file-box" }, [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "file-text",
                                              attrs: { title: "下载模板" },
                                              on: {
                                                click:
                                                  _vm.downFileTemplateAFT05,
                                              },
                                            },
                                            [_vm._v(" 下载模板")]
                                          ),
                                        ]),
                                        _vm.form.file.length
                                          ? _c(
                                              "div",
                                              _vm._l(
                                                _vm.form.file,
                                                function (file, i) {
                                                  return _c("div", { key: i }, [
                                                    file.fileType === "AFT05"
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "file-box",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "file-text",
                                                                attrs: {
                                                                  title:
                                                                    file.fileName,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handle(
                                                                        file
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    file.fileName
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ])
                                                }
                                              ),
                                              0
                                            )
                                          : _vm._e(),
                                      ]),
                                      _c(
                                        "el-col",
                                        {
                                          staticStyle: { "font-size": "14px" },
                                          attrs: { span: 12 },
                                        },
                                        [
                                          _c("p", [
                                            _vm._v(
                                              "太平洋财险与经销商(本店)的兼业代理合作协议："
                                            ),
                                          ]),
                                          _vm.form.file.length
                                            ? _c(
                                                "div",
                                                _vm._l(
                                                  _vm.form.file,
                                                  function (file, i) {
                                                    return _c(
                                                      "div",
                                                      { key: i },
                                                      [
                                                        file.fileType ===
                                                        "AFT08"
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "file-box",
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "file-text",
                                                                    attrs: {
                                                                      title:
                                                                        file.fileName,
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.handle(
                                                                            file
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        file.fileName
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.controlFile === "CPIC"
                                ? _c(
                                    "div",
                                    { staticClass: "elRowComClass" },
                                    [
                                      _c(
                                        "el-checkbox",
                                        {
                                          attrs: { disabled: "" },
                                          model: {
                                            value: _vm.form.fileCheckboxAft08,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "fileCheckboxAft08",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.fileCheckboxAft08",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "无兼业代理资质，采用专代/经纪中介机构代理保险业务资质"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.form.fileCheckboxAft08 &&
                              _vm.controlFile === "CPIC"
                                ? _c(
                                    "el-row",
                                    { staticClass: "elRowComClass" },
                                    [
                                      _c("el-col", { attrs: { span: 8 } }, [
                                        _c("p", [_vm._v("中介机构营业执照：")]),
                                        _vm.form.file.length
                                          ? _c(
                                              "div",
                                              _vm._l(
                                                _vm.form.file,
                                                function (file, i) {
                                                  return _c("div", { key: i }, [
                                                    file.fileType === "AFT11"
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "file-box",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "file-text",
                                                                attrs: {
                                                                  title:
                                                                    file.fileName,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handle(
                                                                        file
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    file.fileName
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ])
                                                }
                                              ),
                                              0
                                            )
                                          : _vm._e(),
                                      ]),
                                      _c("el-col", { attrs: { span: 8 } }, [
                                        _c("p", [
                                          _vm._v("中介机构保险中介许可证："),
                                        ]),
                                        _vm.form.file.length
                                          ? _c(
                                              "div",
                                              _vm._l(
                                                _vm.form.file,
                                                function (file, i) {
                                                  return _c("div", { key: i }, [
                                                    file.fileType === "AFT12"
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "file-box",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "file-text",
                                                                attrs: {
                                                                  title:
                                                                    file.fileName,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handle(
                                                                        file
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    file.fileName
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ])
                                                }
                                              ),
                                              0
                                            )
                                          : _vm._e(),
                                      ]),
                                      _c("el-col", { attrs: { span: 8 } }, [
                                        _c("p", [
                                          _vm._v(
                                            "中介机构与经销商(本店)的代理合作协议："
                                          ),
                                        ]),
                                        _vm.form.file.length
                                          ? _c(
                                              "div",
                                              _vm._l(
                                                _vm.form.file,
                                                function (file, i) {
                                                  return _c("div", { key: i }, [
                                                    file.fileType === "AFT13"
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "file-box",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "file-text",
                                                                attrs: {
                                                                  title:
                                                                    file.fileName,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handle(
                                                                        file
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    file.fileName
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ])
                                                }
                                              ),
                                              0
                                            )
                                          : _vm._e(),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.form.fileCheckboxAft08 &&
                              _vm.controlFile === "CPIC"
                                ? _c(
                                    "el-row",
                                    { staticClass: "elRowComClass" },
                                    [
                                      _c("el-col", { attrs: { span: 8 } }, [
                                        _c("p", [
                                          _vm._v(
                                            "太平洋财险与经销商(本店)的专代代理合作协议："
                                          ),
                                        ]),
                                        _vm.form.file.length
                                          ? _c(
                                              "div",
                                              _vm._l(
                                                _vm.form.file,
                                                function (file, i) {
                                                  return _c("div", { key: i }, [
                                                    file.fileType === "AFT12"
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "file-box",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "file-text",
                                                                attrs: {
                                                                  title:
                                                                    file.fileName,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handle(
                                                                        file
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    file.fileName
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ])
                                                }
                                              ),
                                              0
                                            )
                                          : _vm._e(),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "el-row",
                                { staticClass: "elRowComClass" },
                                [
                                  _vm.controlFile === "PAIC" ||
                                  _vm.controlFile === "PICC"
                                    ? _c(
                                        "el-col",
                                        {
                                          staticStyle: { "font-size": "14px" },
                                          attrs: { span: 12 },
                                        },
                                        [
                                          _c(
                                            "p",
                                            { staticStyle: { float: "left" } },
                                            [_vm._v("授权证书：")]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "file-box" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "file-text",
                                                  attrs: { title: "下载模板" },
                                                  on: {
                                                    click:
                                                      _vm.downFileTemplateAFT03,
                                                  },
                                                },
                                                [_vm._v(" 下载模板")]
                                              ),
                                            ]
                                          ),
                                          _vm.form.file.length
                                            ? _c(
                                                "div",
                                                _vm._l(
                                                  _vm.form.file,
                                                  function (file, i) {
                                                    return _c(
                                                      "div",
                                                      { key: i },
                                                      [
                                                        file.fileType ===
                                                        "AUTHORIZATION_CERTIFICATE"
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "file-box",
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "file-text",
                                                                    attrs: {
                                                                      title:
                                                                        file.fileName,
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.handle(
                                                                            file
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        file.fileName
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _vm._e(),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.controlFile === "CPIC"
                                    ? _c("el-col", { attrs: { span: 12 } }, [
                                        _c(
                                          "p",
                                          { staticStyle: { float: "left" } },
                                          [_vm._v("平台使用需求函：")]
                                        ),
                                        _c("div", { staticClass: "file-box" }, [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "file-text",
                                              attrs: { title: "下载模板" },
                                              on: {
                                                click:
                                                  _vm.downFileTemplateAFT06,
                                              },
                                            },
                                            [_vm._v(" 下载模板")]
                                          ),
                                        ]),
                                        _vm.form.file.length
                                          ? _c(
                                              "div",
                                              _vm._l(
                                                _vm.form.file,
                                                function (file, i) {
                                                  return _c("div", { key: i }, [
                                                    file.fileType === "AFT06"
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "file-box",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "file-text",
                                                                attrs: {
                                                                  title:
                                                                    file.fileName,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handle(
                                                                        file
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    file.fileName
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ])
                                                }
                                              ),
                                              0
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _vm.controlFile !== "QITA" &&
                                  _vm.controlFile !== "TMNF"
                                    ? _c(
                                        "el-col",
                                        {
                                          staticStyle: { "font-size": "14px" },
                                          attrs: { span: 12 },
                                        },
                                        [
                                          _c(
                                            "p",
                                            { staticStyle: { float: "left" } },
                                            [_vm._v("配置信息收集表：")]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "file-box" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "file-text",
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: { title: "下载模板" },
                                                  on: {
                                                    click:
                                                      _vm.downFileTemplateAFT04,
                                                  },
                                                },
                                                [_vm._v(" 下载模板")]
                                              ),
                                            ]
                                          ),
                                          _vm.form.file.length
                                            ? _c(
                                                "div",
                                                _vm._l(
                                                  _vm.form.file,
                                                  function (file, i) {
                                                    return _c(
                                                      "div",
                                                      { key: i },
                                                      [
                                                        file.fileType ===
                                                        "STORE_CHANNEL_INFORMATION_TABLE"
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "file-box",
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "file-text",
                                                                    attrs: {
                                                                      title:
                                                                        file.fileName,
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.handle(
                                                                            file
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        file.fileName
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _vm._e(),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.name === "Management"
                        ? _c(
                            "el-form",
                            {
                              ref: "detail",
                              attrs: {
                                model: _vm.form,
                                "label-width": "160px",
                              },
                            },
                            [
                              _c(
                                "el-divider",
                                { attrs: { "content-position": "left" } },
                                [_vm._v("经销店信息")]
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "经销商名称：" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.form.dealerName) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "经销商编码：" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.form.dealerCode) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "省市区：" } },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.cityData()) + " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "详细地址：" } },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.form.address) + " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "联系人：" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.form.contactName) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "联系方式：" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.form.contactPhone) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "微信号：" } },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.form.wxId) + " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-divider",
                                { attrs: { "content-position": "left" } },
                                [_vm._v("业务开通信息")]
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "保险公司：" } },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.form.insCorpName)
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "提交时间：" } },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.form.applyTime)
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "状态：" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.form.applyStatusDisplay
                                              )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "审批时间：" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.form.approvalTime) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "合作业务类型：" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.form
                                                  .cooperationBusinessTypeDisplay
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.applyStatus == "PENDING_AUDIT"
                                    ? _c(
                                        "el-col",
                                        { attrs: { span: 8 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "驳回原因：" } },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.form.rejectCause) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "el-divider",
                                { attrs: { "content-position": "left" } },
                                [_vm._v("附件")]
                              ),
                              _vm.controlFile !== "PICC"
                                ? _c(
                                    "el-row",
                                    { staticClass: "elRowComClass" },
                                    [
                                      _c("el-col", { attrs: { span: 12 } }, [
                                        _c("p", [_vm._v("本店营业执照：")]),
                                        _vm.form.file.length
                                          ? _c(
                                              "div",
                                              _vm._l(
                                                _vm.form.file,
                                                function (file, i) {
                                                  return _c("div", { key: i }, [
                                                    file.fileType ===
                                                    "BUSINESS_LICENSE"
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "file-box",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "file-text",
                                                                attrs: {
                                                                  title:
                                                                    file.fileName,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handle(
                                                                        file
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    file.fileName
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ])
                                                }
                                              ),
                                              0
                                            )
                                          : _vm._e(),
                                      ]),
                                      _vm.controlFile !== "QITA" &&
                                      _vm.controlFile !== "TMNF"
                                        ? _c(
                                            "el-col",
                                            {
                                              staticStyle: {
                                                "font-size": "14px",
                                              },
                                              attrs: { span: 12 },
                                            },
                                            [
                                              _c("p", [
                                                _vm._v("本店兼业代理许可证："),
                                              ]),
                                              _vm.form.file.length
                                                ? _c(
                                                    "div",
                                                    _vm._l(
                                                      _vm.form.file,
                                                      function (file, i) {
                                                        return _c(
                                                          "div",
                                                          { key: i },
                                                          [
                                                            file.fileType ===
                                                            "OPERATING_LICENSE"
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "file-box",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "file-text",
                                                                        attrs: {
                                                                          title:
                                                                            file.fileName,
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.handle(
                                                                                file
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            file.fileName
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  )
                                                : _vm._e(),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.controlFile === "TMNF"
                                        ? _c(
                                            "el-col",
                                            {
                                              staticStyle: {
                                                "font-size": "14px",
                                              },
                                              attrs: { span: 12 },
                                            },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticStyle: {
                                                    float: "left",
                                                  },
                                                },
                                                [_vm._v("配置信息收集表：")]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "file-box" },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "file-text",
                                                      staticStyle: {
                                                        width: "100%",
                                                      },
                                                      attrs: {
                                                        title: "下载模板",
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.downFileTemplateAFT04,
                                                      },
                                                    },
                                                    [_vm._v(" 下载模板")]
                                                  ),
                                                ]
                                              ),
                                              _vm.form.file.length
                                                ? _c(
                                                    "div",
                                                    _vm._l(
                                                      _vm.form.file,
                                                      function (file, i) {
                                                        return _c(
                                                          "div",
                                                          { key: i },
                                                          [
                                                            file.fileType ===
                                                            "STORE_CHANNEL_INFORMATION_TABLE"
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "file-box",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "file-text",
                                                                        attrs: {
                                                                          title:
                                                                            file.fileName,
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.handle(
                                                                                file
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            file.fileName
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  )
                                                : _vm._e(),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.controlFile === "CPIC"
                                ? _c(
                                    "el-row",
                                    { staticClass: "elRowComClass" },
                                    [
                                      _c("el-col", { attrs: { span: 12 } }, [
                                        _c(
                                          "p",
                                          { staticStyle: { float: "left" } },
                                          [_vm._v("经销商同意使用系统告知书：")]
                                        ),
                                        _c("div", { staticClass: "file-box" }, [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "file-text",
                                              attrs: { title: "下载模板" },
                                              on: {
                                                click:
                                                  _vm.downFileTemplateAFT05,
                                              },
                                            },
                                            [_vm._v(" 下载模板")]
                                          ),
                                        ]),
                                        _vm.form.file.length
                                          ? _c(
                                              "div",
                                              _vm._l(
                                                _vm.form.file,
                                                function (file, i) {
                                                  return _c("div", { key: i }, [
                                                    file.fileType === "AFT05"
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "file-box",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "file-text",
                                                                attrs: {
                                                                  title:
                                                                    file.fileName,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handle(
                                                                        file
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    file.fileName
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ])
                                                }
                                              ),
                                              0
                                            )
                                          : _vm._e(),
                                      ]),
                                      _c(
                                        "el-col",
                                        {
                                          staticStyle: { "font-size": "14px" },
                                          attrs: { span: 12 },
                                        },
                                        [
                                          _c("p", [
                                            _vm._v(
                                              "太平洋财险与经销商(本店)的兼业代理合作协议："
                                            ),
                                          ]),
                                          _vm.form.file.length
                                            ? _c(
                                                "div",
                                                _vm._l(
                                                  _vm.form.file,
                                                  function (file, i) {
                                                    return _c(
                                                      "div",
                                                      { key: i },
                                                      [
                                                        file.fileType ===
                                                        "AFT08"
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "file-box",
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "file-text",
                                                                    attrs: {
                                                                      title:
                                                                        file.fileName,
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.handle(
                                                                            file
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        file.fileName
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.controlFile === "CPIC"
                                ? _c(
                                    "div",
                                    { staticClass: "elRowComClass" },
                                    [
                                      _c(
                                        "el-checkbox",
                                        {
                                          attrs: { disabled: "" },
                                          model: {
                                            value: _vm.form.fileCheckboxAft08,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "fileCheckboxAft08",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.fileCheckboxAft08",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "无兼业代理资质，采用专代/经纪中介机构代理保险业务资质"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.form.fileCheckboxAft08 &&
                              _vm.controlFile === "CPIC"
                                ? _c(
                                    "el-row",
                                    { staticClass: "elRowComClass" },
                                    [
                                      _c("el-col", { attrs: { span: 8 } }, [
                                        _c("p", [_vm._v("中介机构营业执照：")]),
                                        _vm.form.file.length
                                          ? _c(
                                              "div",
                                              _vm._l(
                                                _vm.form.file,
                                                function (file, i) {
                                                  return _c("div", { key: i }, [
                                                    file.fileType === "AFT11"
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "file-box",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "file-text",
                                                                attrs: {
                                                                  title:
                                                                    file.fileName,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handle(
                                                                        file
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    file.fileName
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ])
                                                }
                                              ),
                                              0
                                            )
                                          : _vm._e(),
                                      ]),
                                      _c("el-col", { attrs: { span: 8 } }, [
                                        _c("p", [
                                          _vm._v("中介机构保险中介许可证："),
                                        ]),
                                        _vm.form.file.length
                                          ? _c(
                                              "div",
                                              _vm._l(
                                                _vm.form.file,
                                                function (file, i) {
                                                  return _c("div", { key: i }, [
                                                    file.fileType === "AFT12"
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "file-box",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "file-text",
                                                                attrs: {
                                                                  title:
                                                                    file.fileName,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handle(
                                                                        file
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    file.fileName
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ])
                                                }
                                              ),
                                              0
                                            )
                                          : _vm._e(),
                                      ]),
                                      _c("el-col", { attrs: { span: 8 } }, [
                                        _c("p", [
                                          _vm._v(
                                            "中介机构与经销商(本店)的代理合作协议："
                                          ),
                                        ]),
                                        _vm.form.file.length
                                          ? _c(
                                              "div",
                                              _vm._l(
                                                _vm.form.file,
                                                function (file, i) {
                                                  return _c("div", { key: i }, [
                                                    file.fileType === "AFT13"
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "file-box",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "file-text",
                                                                attrs: {
                                                                  title:
                                                                    file.fileName,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handle(
                                                                        file
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    file.fileName
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ])
                                                }
                                              ),
                                              0
                                            )
                                          : _vm._e(),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.form.fileCheckboxAft08 &&
                              _vm.controlFile === "CPIC"
                                ? _c(
                                    "el-row",
                                    { staticClass: "elRowComClass" },
                                    [
                                      _c("el-col", { attrs: { span: 8 } }, [
                                        _c("p", [
                                          _vm._v(
                                            "太平洋财险与经销商(本店)的专代代理合作协议："
                                          ),
                                        ]),
                                        _vm.form.file.length
                                          ? _c(
                                              "div",
                                              _vm._l(
                                                _vm.form.file,
                                                function (file, i) {
                                                  return _c("div", { key: i }, [
                                                    file.fileType === "AFT12"
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "file-box",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "file-text",
                                                                attrs: {
                                                                  title:
                                                                    file.fileName,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handle(
                                                                        file
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    file.fileName
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ])
                                                }
                                              ),
                                              0
                                            )
                                          : _vm._e(),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "el-row",
                                { staticClass: "elRowComClass" },
                                [
                                  _vm.controlFile === "PAIC" ||
                                  _vm.controlFile === "PICC"
                                    ? _c("el-col", { attrs: { span: 12 } }, [
                                        _c(
                                          "p",
                                          { staticStyle: { float: "left" } },
                                          [_vm._v("授权证书：")]
                                        ),
                                        _c("div", { staticClass: "file-box" }, [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "file-text",
                                              attrs: { title: "下载模板" },
                                              on: {
                                                click:
                                                  _vm.downFileTemplateAFT03,
                                              },
                                            },
                                            [_vm._v(" 下载模板")]
                                          ),
                                        ]),
                                        _vm.form.file.length
                                          ? _c(
                                              "div",
                                              _vm._l(
                                                _vm.form.file,
                                                function (file, i) {
                                                  return _c("div", { key: i }, [
                                                    file.fileType ===
                                                    "AUTHORIZATION_CERTIFICATE"
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "file-box",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "file-text",
                                                                attrs: {
                                                                  title:
                                                                    file.fileName,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handle(
                                                                        file
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    file.fileName
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ])
                                                }
                                              ),
                                              0
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _vm.controlFile === "CPIC"
                                    ? _c("el-col", { attrs: { span: 12 } }, [
                                        _c(
                                          "p",
                                          { staticStyle: { float: "left" } },
                                          [_vm._v("平台使用需求函：")]
                                        ),
                                        _c("div", { staticClass: "file-box" }, [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "file-text",
                                              attrs: { title: "下载模板" },
                                              on: {
                                                click:
                                                  _vm.downFileTemplateAFT06,
                                              },
                                            },
                                            [_vm._v(" 下载模板")]
                                          ),
                                        ]),
                                        _vm.form.file.length
                                          ? _c(
                                              "div",
                                              _vm._l(
                                                _vm.form.file,
                                                function (file, i) {
                                                  return _c("div", { key: i }, [
                                                    file.fileType === "AFT06"
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "file-box",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "file-text",
                                                                attrs: {
                                                                  title:
                                                                    file.fileName,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handle(
                                                                        file
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    file.fileName
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ])
                                                }
                                              ),
                                              0
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _vm.controlFile !== "QITA" &&
                                  _vm.controlFile !== "TMNF"
                                    ? _c(
                                        "el-col",
                                        {
                                          staticStyle: { "font-size": "14px" },
                                          attrs: { span: 12 },
                                        },
                                        [
                                          _c(
                                            "p",
                                            { staticStyle: { float: "left" } },
                                            [_vm._v("配置信息收集表：")]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "file-box" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "file-text",
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: { title: "下载模板" },
                                                  on: {
                                                    click:
                                                      _vm.downFileTemplateAFT04,
                                                  },
                                                },
                                                [_vm._v(" 下载模板")]
                                              ),
                                            ]
                                          ),
                                          _vm.form.file.length
                                            ? _c(
                                                "div",
                                                _vm._l(
                                                  _vm.form.file,
                                                  function (file, i) {
                                                    return _c(
                                                      "div",
                                                      { key: i },
                                                      [
                                                        file.fileType ===
                                                        "STORE_CHANNEL_INFORMATION_TABLE"
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "file-box",
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "file-text",
                                                                    attrs: {
                                                                      title:
                                                                        file.fileName,
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.handle(
                                                                            file
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        file.fileName
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _vm._e(),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              ![
                                "AUDIT_SUCCEEDED",
                                "PENDING_AUDIT",
                                "DISABLE",
                              ].includes(_vm.applyStatus)
                                ? _c(
                                    "div",
                                    { staticStyle: { "margin-top": "10px" } },
                                    [
                                      _c(
                                        "el-row",
                                        [
                                          _c(
                                            "el-col",
                                            {
                                              staticStyle: {
                                                "font-size": "14px",
                                              },
                                            },
                                            [_c("h4", [_vm._v("保司处理")])]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-row",
                                        [
                                          _c(
                                            "el-col",
                                            { attrs: { span: 24 } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "margin-top": "20px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-radio-group",
                                                    {
                                                      attrs: { size: "medium" },
                                                      model: {
                                                        value:
                                                          _vm.manageForm
                                                            .auditStatus,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.manageForm,
                                                            "auditStatus",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "manageForm.auditStatus",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-radio",
                                                        {
                                                          staticStyle: {
                                                            margin: "10px 70px",
                                                          },
                                                          attrs: {
                                                            label:
                                                              "AUDIT_SUCCEEDED",
                                                          },
                                                        },
                                                        [_vm._v("开通")]
                                                      ),
                                                      _c(
                                                        "el-radio",
                                                        {
                                                          attrs: {
                                                            label:
                                                              "PENDING_AUDIT",
                                                          },
                                                        },
                                                        [_vm._v("驳回")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.manageForm.auditStatus ==
                                      "PENDING_AUDIT"
                                        ? _c(
                                            "el-row",
                                            [
                                              _c("el-col", {
                                                attrs: { span: 1 },
                                              }),
                                              _c(
                                                "el-col",
                                                { attrs: { span: 20 } },
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      maxlength: "250",
                                                      "show-word-limit": "",
                                                      type: "textarea",
                                                      rows: 3,
                                                      placeholder:
                                                        "请输入驳回原因",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.manageForm
                                                          .rejectCause,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.manageForm,
                                                          "rejectCause",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "manageForm.rejectCause",
                                                    },
                                                  }),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "font-size": "12px",
                                                        color: "#c0c4cc",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "注:限最大输入250字"
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ]),
        _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
          _c("div", { staticClass: "footer-btn" }, [
            _c(
              "div",
              { staticClass: "footer-btn-list" },
              [
                _vm.name === "Submit"
                  ? _c(
                      "el-button",
                      {
                        attrs: {
                          icon: "el-icon-check",
                          type: "primary",
                          size: "mini",
                        },
                        on: { click: _vm.save },
                      },
                      [_vm._v("提交")]
                    )
                  : _vm._e(),
                _vm.name === "details" && _vm.applyStatus === "PENDING_AUDIT"
                  ? _c(
                      "el-button",
                      {
                        attrs: {
                          icon: "el-icon-check",
                          type: "primary",
                          size: "mini",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.reapply("Submit", _vm.form)
                          },
                        },
                      },
                      [_vm._v("重新申请")]
                    )
                  : _vm._e(),
                _vm.name === "Management" && _vm.applyStatus == "WAIT_APPLY"
                  ? _c(
                      "el-button",
                      {
                        attrs: {
                          icon: "el-icon-check",
                          type: "primary",
                          size: "mini",
                        },
                        on: { click: _vm.ondetermine },
                      },
                      [_vm._v("确定")]
                    )
                  : _vm._e(),
                _c(
                  "el-button",
                  {
                    attrs: { icon: "el-icon-close", size: "mini" },
                    on: { click: _vm.closePage },
                  },
                  [_vm._v("关闭")]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }