import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FormLabel from "@/components/FormSubLabel";
import DynamicColumn from "@/components/DynamicColumn";
import connectReq from "@/api/connectConfig";
import PageContainer from "@/components/PageContainer";
import dict from "@/mixins/dict";
import Dialog from "@/components/Dialog";
import Pagination from "@/components/Pagination";
import { getSupplyers, getCityItem, getProvinceItem } from "@/utils/common";
export default {
  name: "ChannelConfigDetail",
  components: {
    FormLabel: FormLabel,
    DynamicColumn: DynamicColumn,
    PageContainer: PageContainer,
    Dialog: Dialog,
    Pagination: Pagination
  },
  mixins: [dict],
  computed: {
    sidebar: function sidebar() {
      return this.$store.state.app.sidebar;
    }
  },
  watch: {
    sidebar: {
      handler: function handler(newValue, oldValue) {
        this.sidebarOpened = newValue.opened;
        this.calcWidth();
      },
      deep: true
    },
    $route: {
      handler: function handler(val, oldVal) {
        var _this = this;
        this.opType = val.query.type;
        if (this.opType == 'add') {
          this.form = JSON.parse(sessionStorage.getItem('channelConfigForm'));
          this.form.insChannelConfig = JSON.parse(this.form.insChannelConfig);
          if (this.form.insCorpCode) {
            connectReq.getApiConfigByInsCorpCode('INSURED', this.form.insCorpCode).then(function (res) {
              _this.apiConfigs = res.data || [];
              _this.selectConfigType(_this.form.apiConfigId, _this.form.insChannelConfig);
            });
          }
        } else if (this.opType == "update" || this.opType == "view") {
          this.$nextTick(function () {
            _this.channelId = _this.$route.query.channelId;
            _this.getDetail(_this.channelId);
          });
        }
        if (oldVal.query.type == 'add') {
          var settingItem = {};
          var _postData = _.cloneDeep(this.form);
          if (_postData.insChannelConfig) {
            _postData.insChannelConfig.forEach(function (item) {
              if (item.storeValue) {
                var newField = item.storeKey;
                settingItem[newField] = item.storeValue;
              }
            });
            _postData.insChannelConfig = JSON.stringify(settingItem);
          }
          sessionStorage.setItem('channelConfigForm', JSON.stringify(_postData));
        }
      },
      deep: true
    }
  },
  data: function data() {
    return {
      cityOptions: [],
      searchForm: {},
      form: {
        apiConfigId: null,
        apiConfigType: 'INSURED',
        insChannelConfig: [],
        categoryName: null
      },
      opType: "",
      channelId: "",
      formWidth: 0,
      resData: {},
      apiConfigs: [],
      rules: {
        insChannelConfig: {},
        insCorpCode: [{
          required: true,
          message: "请选择保险公司",
          trigger: "change"
        }],
        insChannelName: [{
          required: true,
          message: "请输入渠道工号名称",
          trigger: "blur"
        }],
        insCityCode: [{
          required: true,
          message: "请选择投保城市",
          trigger: "change"
        }],
        apiConfigId: [{
          required: true,
          message: "请选择接口配置",
          trigger: "change"
        }],
        tenantId: [{
          required: true,
          message: "请选择租户",
          trigger: "change"
        }]
      },
      API_CONFIG_TYPE: [],
      supplyers: [],
      showDialog: false,
      tableData: [],
      multipleSelection: [],
      listQuery: {
        pageNum: 1,
        pageSize: 5
      },
      total: 0,
      headerCollapse: false,
      tenantData: [{
        tenantId: '100001',
        tenantName: '默认租户'
      }],
      cityList: [],
      formLoading: false
    };
  },
  created: function created() {
    this.cityList = JSON.parse(localStorage.getItem("VI_CityPcTree")) || [];
    this.opType = this.$route.query.type;
    this.cityOptions = JSON.parse(localStorage.getItem("VI_CityPcTree")) || [];
    this.API_CONFIG_TYPE = this.dictSource["API_CONFIG_TYPE"];
    this.supplyers = getSupplyers();
    if (this.opType == "update" || this.opType == "view") {
      this.channelId = this.$route.query.channelId;
      this.getDetail(this.channelId);
    }
  },
  mounted: function mounted() {
    this.sidebarOpened = this.sidebar.opened;
    this.calcWidth();
  },
  methods: {
    getCityName: function getCityName(code) {
      //获取保司中文名称
      var cityItem = getCityItem(code);
      var name = cityItem ? cityItem.label : '';
      if (!name) {
        cityItem = getProvinceItem(code);
        name = cityItem ? cityItem.label : '';
      }
      if (!name) {
        var provinces = JSON.parse(localStorage.getItem('VI_CityPcTree')) || [];
        provinces.forEach(function (province) {
          if (province.value == code) {
            name = province.label;
          }
          if (province && !_.isEmpty(province.children)) {
            province.children.forEach(function (pitem) {
              if (pitem.value == code) {
                name = pitem.label;
              }
              if (pitem && !_.isEmpty(pitem.children)) {
                pitem.children.forEach(function (araItem) {
                  if (araItem.value == code) {
                    name = pitem.label + araItem.label;
                  }
                });
              }
            });
          }
        });
      }
      return name ? name + '(' + code + ')' : code;
    },
    collapse: function collapse() {
      //收缩或展开
      this.headerCollapse = !this.headerCollapse;
    },
    handleSelectionChange: function handleSelectionChange(val) {
      //收缩或展开
      if (!val) return;
      val.checked = true;
      this.tableData.forEach(function (item) {
        if (item.platformConfigId !== val.platformConfigId) {
          item.checked = false;
        }
      });
      // 如果使用单选框,这里可以把当前选中的这一项先保存起来
      this.multipleSelection = val;
    },
    tenantChange: function tenantChange(val) {
      //监听租户ID变化
      if (!val) {
        this.form.insCorpCode = null;
        this.form.insCorpSysId = '';
        this.form.apiConfigId = null;
        this.form.insChannelConfig = [];
      } else {
        if (this.form.insCorpCode) {
          this.selectInsCorp(this.form.insCorpCode);
        }
      }
    },
    changeValue: function changeValue() {
      //刷新
      this.$forceUpdate();
    },
    inputValue: function inputValue() {
      //刷新
      this.$forceUpdate();
    },
    selectInsCorp: function selectInsCorp(val) {
      var _this2 = this;
      this.form.tenantId = '100001';
      this.form.apiConfigId = null;
      this.form.insChannelConfig = [];
      if (val) {
        this.$nextTick(function () {
          _this2.$refs["form"].clearValidate();
        });
        this.apiConfigs = [];
        connectReq.getApiConfigByInsCorpCode('INSURED', val).then(function (res) {
          _this2.apiConfigs = res.data || [];
        });
      }
    },
    selectConfigType: function selectConfigType(val, _item) {
      var _this3 = this;
      //选择系统标识
      this.form.apiConfigId = val;
      if (val) {
        this.$nextTick(function () {
          _this3.$refs["form"].clearValidate();
        });
        // if (_.isEmpty(_item) && !this.form.tenantId) {
        //     this.$message({
        //         type: 'error',
        //         message: '请选择租户'
        //     });
        //     return;
        // }
        var temp = _.find(this.apiConfigs, function (item) {
          return item.apiConfigId == val;
        });
        connectReq.getBizSchemaConfig(temp.apiConfigCode).then(function (res) {
          if (res.success && res.code == RESPONSE_SUCCESS && res.data) {
            var resAllData = res.data;
            if (!_.isEmpty(_item)) {
              resAllData.items.map(function (item) {
                for (var key in _item) {
                  if (item.storeKey == key) {
                    item.storeValue = _item[key];
                  }
                }
              });
            }
            _this3.form.categoryName = resAllData.categoryName;
            _this3.form.insChannelConfig = resAllData.items;
            _this3.$forceUpdate();
          } else {
            _this3.form.insChannelConfig = [];
          }
        });
      }
    },
    calcWidth: function calcWidth() {
      //动态设置字段宽度
      var width = document.body.clientWidth;
      width = width - (this.sidebarOpened ? 210 : 54) - 30 - 20;
      var w = parseInt(width / 3);
      this.formWidth = width + 50;
    },
    submit: function submit(item) {
      var _this4 = this;
      //表单提交
      if (this.$refs.dynamicColumnItem) this.$refs.dynamicColumnItem.validataForm();

      // 验证表单
      this.$refs["form"].validate(function (valid) {
        if (valid) {
          _this4.formLoading = true;
          var settingItem = {};
          var postData = _.cloneDeep(_this4.form);
          postData.insChannelConfig.forEach(function (item) {
            if (item.storeValue) {
              var newField = item.storeKey;
              settingItem[newField] = item.storeValue;
            }
          });
          postData.insChannelConfig = JSON.stringify(settingItem);
          if (_.isArray(postData.insCityCode)) {
            postData.insCityCode = postData.insCityCode[1];
          }
          if (!_.isEmpty(_this4.tenantData)) {
            var tenant = _.find(_this4.tenantData, function (item) {
              return item.tenantId == _this4.form.tenantId;
            });
            if (tenant) postData.tenantName = tenant.tenantName;
          }
          var localRequest = _this4.opType == "update" ? connectReq.updateChannelConfig(postData.channelConfigId, postData) : connectReq.createChannelConfig(postData);
          localRequest.then(function (res) {
            if (res.code == RESPONSE_SUCCESS) {
              _this4.$message({
                type: 'success',
                message: res.msg
              });
              _this4.goBack();
            }
            _this4.formLoading = false;
          }).catch(function (err) {
            _this4.formLoading = false;
          });
        }
      });
    },
    getDetail: function getDetail(id) {
      var _this5 = this;
      //获取详情
      connectReq.getChannelConfigById(id).then(function (res) {
        if (res.code == RESPONSE_SUCCESS) {
          var resData = res.data;
          var temp = _.cloneDeep(_this5.form);
          temp.channelConfigId = resData.channelConfigId;
          temp.insCorpCode = resData.insCorpCode;
          temp.insCityCode = resData.insCityCode;
          temp.insCorpChannel = resData.insCorpChannel;
          temp.apiConfigType = _this5.API_CONFIG_TYPE[0].dictCode;
          // temp.insChannelConfig = resData.insChannelConfig;
          temp.insChannelName = resData.insChannelName;
          temp.apiConfigId = resData.apiConfigId;
          temp.tenantId = resData.tenantId;
          _this5.form = temp;
          connectReq.getApiConfigByInsCorpCode('INSURED', resData.insCorpCode).then(function (res) {
            _this5.formLoading = false;
            _this5.apiConfigs = res.data || [];
            var currentApiConfig = JSON.parse(resData.insChannelConfig);
            _this5.selectConfigType(resData.apiConfigId, currentApiConfig);
          });
        } else {
          _this5.formLoading = false;
        }
      }).catch(function (err) {
        _this5.formLoading = false;
      });
    },
    goBack: function goBack() {
      //返回上一页
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.push("/channelConfig");
    }
  }
};