var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sidebar-logo-container",
      class: { collapse: _vm.collapse },
    },
    [
      _c(
        "transition",
        { attrs: { name: "sidebarLogoFade" } },
        [
          _vm.collapse
            ? _c(
                "router-link",
                {
                  key: "collapse",
                  staticClass: "sidebar-logo-link",
                  attrs: { to: "/workbench" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "sidebar-title",
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        padding: "6px",
                      },
                    },
                    [
                      _c("img", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          src: require("@/assets/images/logo_mini.png"),
                        },
                      }),
                    ]
                  ),
                ]
              )
            : _c(
                "router-link",
                {
                  key: "expand",
                  staticClass: "sidebar-logo-link",
                  attrs: { to: "/workbench" },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        height: "50px",
                      },
                    },
                    [
                      _c("img", {
                        staticStyle: { width: "100%" },
                        attrs: { src: require("@/assets/images/sys_logo.png") },
                      }),
                    ]
                  ),
                ]
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }