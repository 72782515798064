var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageContainer", [
        _c("div", { attrs: { slot: "main" }, slot: "main" }, [
          _c("div", { staticClass: "app-m-cls" }, [
            _c(
              "div",
              { staticClass: "customer header-cls" },
              [
                _c(
                  "el-collapse",
                  {
                    model: {
                      value: _vm.activeOne,
                      callback: function ($$v) {
                        _vm.activeOne = $$v
                      },
                      expression: "activeOne",
                    },
                  },
                  [
                    _c(
                      "el-collapse-item",
                      {
                        staticClass: "collapse-item-style",
                        attrs: { name: "1" },
                      },
                      [
                        _c("template", { slot: "title" }, [
                          _c(
                            "div",
                            {
                              staticClass: "title",
                              staticStyle: { "line-height": "25px" },
                            },
                            [
                              _c("FormLabel", {
                                attrs: { "form-label": "客户信息" },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c(
                          "el-card",
                          { attrs: { shadow: "always" } },
                          [
                            _c(
                              "el-form",
                              {
                                ref: "form",
                                attrs: { "label-width": "180px" },
                              },
                              [
                                _c(
                                  "el-row",
                                  {
                                    staticClass: "row-bg",
                                    attrs: {
                                      gutter: 20,
                                      type: "flex",
                                      justify: "space-around",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "车主名称：" } },
                                          [
                                            _c("el-input", {
                                              attrs: { disabled: _vm.disabled },
                                              model: {
                                                value: _vm.customForm.ownerName,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.customForm,
                                                    "ownerName",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "customForm.ownerName",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "客户电话：" } },
                                          [
                                            _c("el-input", {
                                              attrs: { disabled: _vm.disabled },
                                              model: {
                                                value:
                                                  _vm.customForm.ownerMobile,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.customForm,
                                                    "ownerMobile",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "customForm.ownerMobile",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "电子邮箱：" } },
                                          [
                                            _c("el-input", {
                                              attrs: { disabled: _vm.disabled },
                                              model: {
                                                value: _vm.customForm.email,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.customForm,
                                                    "email",
                                                    $$v
                                                  )
                                                },
                                                expression: "customForm.email",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  {
                                    staticClass: "row-bg",
                                    attrs: {
                                      gutter: 20,
                                      type: "flex",
                                      justify: "space-around",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "证件类型：" } },
                                          [
                                            _c("el-input", {
                                              attrs: { disabled: _vm.disabled },
                                              model: {
                                                value:
                                                  _vm.customForm
                                                    .ownerCertificateTypeDisplay,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.customForm,
                                                    "ownerCertificateTypeDisplay",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "customForm.ownerCertificateTypeDisplay",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "证件号码：" } },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                title:
                                                  _vm.customForm
                                                    .ownerCertificateNo,
                                                "show-all-levels": false,
                                                disabled: _vm.disabled,
                                              },
                                              model: {
                                                value:
                                                  _vm.customForm
                                                    .ownerCertificateNo,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.customForm,
                                                    "ownerCertificateNo",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "customForm.ownerCertificateNo",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("el-col", { attrs: { span: 8 } }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  {
                                    staticClass: "row-bg",
                                    attrs: {
                                      gutter: 20,
                                      type: "flex",
                                      justify: "space-around",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "省/直辖市：" } },
                                          [
                                            _c("el-input", {
                                              attrs: { disabled: _vm.disabled },
                                              model: {
                                                value:
                                                  _vm.customForm
                                                    .ownerProvinceName,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.customForm,
                                                    "ownerProvinceName",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "customForm.ownerProvinceName",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "市：" } },
                                          [
                                            _c("el-input", {
                                              attrs: { disabled: _vm.disabled },
                                              model: {
                                                value:
                                                  _vm.customForm.ownerCityName,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.customForm,
                                                    "ownerCityName",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "customForm.ownerCityName",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "区/县：" } },
                                          [
                                            _c("el-input", {
                                              attrs: { disabled: _vm.disabled },
                                              model: {
                                                value:
                                                  _vm.customForm
                                                    .ownerDistrictName,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.customForm,
                                                    "ownerDistrictName",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "customForm.ownerDistrictName",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  {
                                    staticClass: "row-bg",
                                    attrs: {
                                      gutter: 20,
                                      type: "flex",
                                      justify: "space-around",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 16 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "地址：" } },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                title:
                                                  _vm.customForm.ownerAddress,
                                                disabled: _vm.disabled,
                                              },
                                              model: {
                                                value:
                                                  _vm.customForm.ownerAddress,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.customForm,
                                                    "ownerAddress",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "customForm.ownerAddress",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("el-col", { attrs: { span: 8 } }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _c(
                  "el-collapse",
                  {
                    model: {
                      value: _vm.activeTwo,
                      callback: function ($$v) {
                        _vm.activeTwo = $$v
                      },
                      expression: "activeTwo",
                    },
                  },
                  [
                    _c(
                      "el-collapse-item",
                      {
                        staticClass: "collapse-item-style",
                        attrs: { name: "1" },
                      },
                      [
                        _c("template", { slot: "title" }, [
                          _c(
                            "div",
                            {
                              staticClass: "title",
                              staticStyle: { "line-height": "25px" },
                            },
                            [
                              _c("FormLabel", {
                                attrs: { "form-label": "车辆信息" },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c(
                          "el-card",
                          { attrs: { shadow: "always" } },
                          [
                            _c(
                              "el-form",
                              {
                                ref: "form",
                                attrs: { "label-width": "180px" },
                              },
                              [
                                _c(
                                  "el-row",
                                  {
                                    staticClass: "row-bg",
                                    attrs: {
                                      gutter: 0,
                                      type: "flex",
                                      justify: "space-around",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "车牌号：" } },
                                          [
                                            _c("el-input", {
                                              attrs: { disabled: _vm.disabled },
                                              model: {
                                                value:
                                                  _vm.voitureInfoForm.plateNo,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.voitureInfoForm,
                                                    "plateNo",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "voitureInfoForm.plateNo",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "车架号：" } },
                                          [
                                            _c("el-input", {
                                              attrs: { disabled: _vm.disabled },
                                              model: {
                                                value: _vm.voitureInfoForm.vin,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.voitureInfoForm,
                                                    "vin",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "voitureInfoForm.vin",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "车系：" } },
                                          [
                                            _c("el-input", {
                                              attrs: { disabled: _vm.disabled },
                                              model: {
                                                value:
                                                  _vm.voitureInfoForm
                                                    .familyName,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.voitureInfoForm,
                                                    "familyName",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "voitureInfoForm.familyName",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  {
                                    staticClass: "row-bg",
                                    attrs: {
                                      gutter: 0,
                                      type: "flex",
                                      justify: "space-around",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "车型：" } },
                                          [
                                            _c("el-input", {
                                              attrs: { disabled: _vm.disabled },
                                              model: {
                                                value:
                                                  _vm.voitureInfoForm.modelName,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.voitureInfoForm,
                                                    "modelName",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "voitureInfoForm.modelName",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "排量(升)：" } },
                                          [
                                            _c("el-input", {
                                              attrs: { disabled: _vm.disabled },
                                              model: {
                                                value:
                                                  _vm.voitureInfoForm
                                                    .displacement,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.voitureInfoForm,
                                                    "displacement",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "voitureInfoForm.displacement",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "新车购置价（含税）：",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: { disabled: _vm.disabled },
                                              model: {
                                                value:
                                                  _vm.voitureInfoForm
                                                    .purchasePrice,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.voitureInfoForm,
                                                    "purchasePrice",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "voitureInfoForm.purchasePrice",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  {
                                    staticClass: "row-bg",
                                    attrs: {
                                      gutter: 0,
                                      type: "flex",
                                      justify: "space-around",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: { label: "车辆销售日期：" },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: { disabled: _vm.disabled },
                                              model: {
                                                value:
                                                  _vm.voitureInfoForm.sellDate,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.voitureInfoForm,
                                                    "sellDate",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "voitureInfoForm.sellDate",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: { label: "原厂质保起期：" },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: { disabled: _vm.disabled },
                                              model: {
                                                value:
                                                  _vm.voitureInfoForm
                                                    .qualityGuaranteeDate,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.voitureInfoForm,
                                                    "qualityGuaranteeDate",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "voitureInfoForm.qualityGuaranteeDate",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("el-col", { attrs: { span: 8 } }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                false
                  ? _c(
                      "el-collapse",
                      {
                        model: {
                          value: _vm.activeThree,
                          callback: function ($$v) {
                            _vm.activeThree = $$v
                          },
                          expression: "activeThree",
                        },
                      },
                      [
                        _c(
                          "el-collapse-item",
                          {
                            staticClass: "collapse-item-style",
                            attrs: { name: "1" },
                          },
                          [
                            _c("template", { slot: "title" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "title",
                                  staticStyle: { "line-height": "25px" },
                                },
                                [
                                  _c("FormLabel", {
                                    attrs: { "form-label": "卡卷信息" },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c(
                              "el-card",
                              [
                                _c("div", { staticClass: "card-title" }, [
                                  _c("span", [_vm._v("卡券信息")]),
                                ]),
                                _c(
                                  "el-table",
                                  {
                                    staticClass: "cardTicket",
                                    staticStyle: { width: "95%" },
                                    attrs: {
                                      data: _vm.cardTicketData,
                                      "max-height": "250",
                                      border: "",
                                    },
                                  },
                                  [
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "insChannelName",
                                        label: "卡券名称",
                                      },
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "effectiveStartTime",
                                        label: "领取/发放时间",
                                      },
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "effectiveEndTime",
                                        label: "有效期",
                                      },
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "application",
                                        label: "适用范围",
                                      },
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "status",
                                        label: "卡券状态",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("p", { staticClass: "message" }, [
                                  _vm._v("查看更多"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-collapse",
                  {
                    model: {
                      value: _vm.activeFour,
                      callback: function ($$v) {
                        _vm.activeFour = $$v
                      },
                      expression: "activeFour",
                    },
                  },
                  [
                    _c(
                      "el-collapse-item",
                      {
                        staticClass: "collapse-item-style",
                        attrs: { name: "1" },
                      },
                      [
                        _c("template", { slot: "title" }, [
                          _c(
                            "div",
                            {
                              staticClass: "title",
                              staticStyle: { "line-height": "25px" },
                            },
                            [
                              _c("FormLabel", {
                                attrs: { "form-label": "交强险投保信息" },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c(
                          "el-card",
                          { attrs: { shadow: "always" } },
                          [
                            _c(
                              "el-form",
                              {
                                ref: "form",
                                attrs: { "label-width": "180px" },
                              },
                              [
                                _c(
                                  "el-row",
                                  {
                                    staticClass: "row-bg",
                                    attrs: {
                                      gutter: 0,
                                      type: "flex",
                                      justify: "space-around",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "交强险保险公司：",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: { disabled: _vm.disabled },
                                              model: {
                                                value:
                                                  _vm.compulsoryInsurnaceForm
                                                    .insCorpName,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.compulsoryInsurnaceForm,
                                                    "insCorpName",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "compulsoryInsurnaceForm.insCorpName",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: { label: "交强险保单号：" },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: { disabled: _vm.disabled },
                                              model: {
                                                value:
                                                  _vm.compulsoryInsurnaceForm
                                                    .policyNo,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.compulsoryInsurnaceForm,
                                                    "policyNo",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "compulsoryInsurnaceForm.policyNo",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: { label: "交强险有效期：" },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: { disabled: _vm.disabled },
                                              model: {
                                                value:
                                                  _vm.compulsoryInsurnaceForm
                                                    .effectiveEndTime,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.compulsoryInsurnaceForm,
                                                    "effectiveEndTime",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "compulsoryInsurnaceForm.effectiveEndTime",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  {
                                    staticClass: "row-bg",
                                    attrs: {
                                      gutter: 0,
                                      type: "flex",
                                      justify: "space-around",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "交强险签单日期：",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: { disabled: _vm.disabled },
                                              model: {
                                                value:
                                                  _vm.compulsoryInsurnaceForm
                                                    .insuredTime,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.compulsoryInsurnaceForm,
                                                    "insuredTime",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "compulsoryInsurnaceForm.insuredTime",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "交强险保费：" } },
                                          [
                                            _c("el-input", {
                                              ref: "compulsoryRef",
                                              attrs: { disabled: _vm.disabled },
                                              model: {
                                                value:
                                                  _vm.compulsoryInsurnaceForm
                                                    .actualPremium,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.compulsoryInsurnaceForm,
                                                    "actualPremium",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "compulsoryInsurnaceForm.actualPremium",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "交强险渠道名称：",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: { disabled: _vm.disabled },
                                              model: {
                                                value:
                                                  _vm.compulsoryInsurnaceForm
                                                    .insuranceChannelName,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.compulsoryInsurnaceForm,
                                                    "insuranceChannelName",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "compulsoryInsurnaceForm.insuranceChannelName",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  {
                                    staticClass: "row-bg",
                                    attrs: {
                                      gutter: 0,
                                      type: "flex",
                                      justify: "space-around",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "投保方式" } },
                                          [
                                            _c("el-input", {
                                              attrs: { disabled: _vm.disabled },
                                              model: {
                                                value:
                                                  _vm.compulsoryInsurnaceForm
                                                    .insuranceInsureWay,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.compulsoryInsurnaceForm,
                                                    "insuranceInsureWay",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "compulsoryInsurnaceForm.insuranceInsureWay",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("el-col", { attrs: { span: 16 } }),
                                  ],
                                  1
                                ),
                                _c("el-input", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: false,
                                      expression: "false",
                                    },
                                  ],
                                  ref: "compulsoryRef",
                                  model: {
                                    value: _vm.compulsoryInsurnaceForm.riskType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.compulsoryInsurnaceForm,
                                        "riskType",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "compulsoryInsurnaceForm.riskType",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "p",
                              {
                                staticClass: "message",
                                on: {
                                  click: function ($event) {
                                    return _vm.insuranceInfoDetail(
                                      "insuranInfo",
                                      $event
                                    )
                                  },
                                },
                              },
                              [_vm._v("往年交强险投保信息查看")]
                            ),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _c(
                  "el-collapse",
                  {
                    model: {
                      value: _vm.activeFive,
                      callback: function ($$v) {
                        _vm.activeFive = $$v
                      },
                      expression: "activeFive",
                    },
                  },
                  [
                    _c(
                      "el-collapse-item",
                      {
                        staticClass: "collapse-item-style",
                        attrs: { name: "1" },
                      },
                      [
                        _c("template", { slot: "title" }, [
                          _c(
                            "div",
                            {
                              staticClass: "title",
                              staticStyle: { "line-height": "25px" },
                            },
                            [
                              _c("FormLabel", {
                                attrs: { "form-label": "商业险投保信息" },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c(
                          "el-card",
                          { attrs: { shadow: "always" } },
                          [
                            _c(
                              "el-form",
                              {
                                ref: "form",
                                attrs: { "label-width": "180px" },
                              },
                              [
                                _c(
                                  "el-row",
                                  {
                                    staticClass: "row-bg",
                                    attrs: {
                                      gutter: 0,
                                      type: "flex",
                                      justify: "space-around",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "商业险保险公司：",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: { disabled: _vm.disabled },
                                              model: {
                                                value:
                                                  _vm.businessInsuranceForm
                                                    .insCorpName,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.businessInsuranceForm,
                                                    "insCorpName",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "businessInsuranceForm.insCorpName",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: { label: "商业险保单号：" },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: { disabled: _vm.disabled },
                                              model: {
                                                value:
                                                  _vm.businessInsuranceForm
                                                    .policyNo,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.businessInsuranceForm,
                                                    "policyNo",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "businessInsuranceForm.policyNo",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: { label: "商业险有效期：" },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: { disabled: _vm.disabled },
                                              model: {
                                                value:
                                                  _vm.businessInsuranceForm
                                                    .effectiveEndTime,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.businessInsuranceForm,
                                                    "effectiveEndTime",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "businessInsuranceForm.effectiveEndTime",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  {
                                    staticClass: "row-bg",
                                    attrs: {
                                      gutter: 0,
                                      type: "flex",
                                      justify: "space-around",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "商业险签单日期：",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: { disabled: _vm.disabled },
                                              model: {
                                                value:
                                                  _vm.businessInsuranceForm
                                                    .insuredTime,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.businessInsuranceForm,
                                                    "insuredTime",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "businessInsuranceForm.insuredTime",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "商业险保费：" } },
                                          [
                                            _c("el-input", {
                                              attrs: { disabled: _vm.disabled },
                                              model: {
                                                value:
                                                  _vm.businessInsuranceForm
                                                    .actualPremium,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.businessInsuranceForm,
                                                    "actualPremium",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "businessInsuranceForm.actualPremium",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "商业险渠道名称：",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: { disabled: _vm.disabled },
                                              model: {
                                                value:
                                                  _vm.businessInsuranceForm
                                                    .insuranceChannelName,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.businessInsuranceForm,
                                                    "insuranceChannelName",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "businessInsuranceForm.insuranceChannelName",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  {
                                    staticClass: "row-bg",
                                    attrs: {
                                      gutter: 0,
                                      type: "flex",
                                      justify: "space-around",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "投保方式" } },
                                          [
                                            _c("el-input", {
                                              attrs: { disabled: _vm.disabled },
                                              model: {
                                                value:
                                                  _vm.compulsoryInsurnaceForm
                                                    .insuranceInsureWay,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.compulsoryInsurnaceForm,
                                                    "insuranceInsureWay",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "compulsoryInsurnaceForm.insuranceInsureWay",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("el-col", { attrs: { span: 16 } }),
                                  ],
                                  1
                                ),
                                _c("el-input", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: false,
                                      expression: "false",
                                    },
                                  ],
                                  ref: "businessRef",
                                  model: {
                                    value: _vm.businessInsuranceForm.riskType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.businessInsuranceForm,
                                        "riskType",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "businessInsuranceForm.riskType",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "p",
                              {
                                staticClass: "message",
                                on: {
                                  click: function ($event) {
                                    return _vm.insuranceInfoDetail(
                                      "businessInfo",
                                      $event
                                    )
                                  },
                                },
                              },
                              [_vm._v("往年商业险投保信息查看")]
                            ),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
          _c("div", { staticClass: "footer-btn" }, [
            _c(
              "div",
              { staticClass: "footer-btn-list" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { size: "mini", icon: "el-icon-back" },
                    on: { click: _vm.goBack },
                  },
                  [_vm._v("返回")]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }