import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import nonAutoRequest from '@/api/nonAutoRequest';
import Rules from '@/utils/rules';
import { character } from '@/utils/common';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    currentId: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      form: {
        vin: ''
      },
      rules: {
        vin: [{
          required: true,
          message: '请输入车架号',
          trigger: "blur"
        }, {
          pattern: Rules["VIN_RULE"].value,
          message: "车架号格式有误",
          trigger: ["blur", "change"]
        }]
      },
      formLoading: false,
      tableData: [],
      currentObj: {},
      timeout1: null
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
      ;
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    initData: function initData() {
      //初始化数据
      this.form = {
        vin: ''
      };
      this.tableData = [];
      this.$refs.detail.resetFields();
    },
    closePage: function closePage() {
      //关闭弹窗
      this.$emit('closePage');
    },
    checkValue: function checkValue(val) {
      //输入的车架号中的字母自动转大写，并去除i，o，q
      if (val) {
        val = val.replace(/i/g, '').replace(/I/g, '').replace(/o/g, '').replace(/O/g, '').replace(/q/g, '').replace(/Q/g, '');
      }
      ;
      this.form.vin = character(_.toUpper(val));
    },
    vincheck: function vincheck() {
      var vinNumber = /^\d{17}$/;
      var vinLetter = /^[A-Z]{17}$/;
      if (vinNumber.test(this.form.vin) || vinLetter.test(this.form.vin)) {
        this.$message({
          type: 'error',
          message: '车架号不能纯数字或纯字母，请修改！'
        });
        this.$forceUpdate();
        return false;
      }
      ;
      return true;
    },
    // 检索
    queryCustomerInfo: function queryCustomerInfo() {
      var _this = this;
      if (!this.vincheck()) return;
      this.$refs.detail.validate(function (valid) {
        if (valid) {
          _this.formLoading = true;
          nonAutoRequest.queryNonAutoCancelList(_this.form).then(function (res) {
            _this.formLoading = false;
            if (res.code === 200 && res.data.length) {
              _this.tableData = res.data;
              _this.tableData.forEach(function (item) {
                return item.verifyLoading = false;
              });
              _this.$message.success(res.msg);
            } else {
              _this.$message.warning("未查到该车辆的投保信息。");
              _this.tableData = [];
            }
            ;
          }).catch(function (err) {
            _this.formLoading = false;
          });
        }
        ;
      });
    },
    chargebackApply: function chargebackApply(item) {
      var _this2 = this;
      // 防抖
      if (this.timeout1) {
        clearTimeout(this.timeout1);
      }
      this.timeout1 = setTimeout(function () {
        _this2.chargebackApply1(item);
      }, 500);
    },
    // 注销申请
    chargebackApply1: function chargebackApply1(item) {
      var _this3 = this;
      if (item.productClassCode == 'YB') {
        if (item.insuredTime) {
          var beforeFebruary = new Date('2023/02/01 00:00:00').getTime();
          var itemInsuredTime = new Date(item.insuredTime).getTime();
          if (itemInsuredTime < beforeFebruary) {
            this.$message.warning('当前订单不允许注销，请发送邮件至厂家老师申请注销');
            return;
          }
        }
      }
      // 检查是否可以注销
      item.verifyLoading = true;
      this.$forceUpdate();
      nonAutoRequest.verifyNonAutoCancel({
        policyNo: item.policyNo
      }).then(function (res) {
        item.verifyLoading = false;
        _this3.$forceUpdate();
        if (res.code == 200) {
          // 跳转到注销界面
          _this3.$router.push({
            path: "/chargebackApply",
            query: {
              policyId: item.policyId,
              tabName: item.productClassName + '注销申请'
            }
          });
        }
      }).catch(function (err) {
        item.verifyLoading = false;
        _this3.$forceUpdate();
      });
    }
  }
};