var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.listLoading,
          expression: "listLoading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: {},
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "500px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "detail",
                  attrs: {
                    model: _vm.searchForm,
                    rules: _vm.rules,
                    "label-width": "150px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "协议名称：",
                                prop: "agreementName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入协议名称",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.searchForm.agreementName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.searchForm,
                                      "agreementName",
                                      $$v
                                    )
                                  },
                                  expression: "searchForm.agreementName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.opType == "edit"
                        ? _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "协议状态：" } },
                                [
                                  _c("div", [
                                    _vm.searchForm.agreementStatus == "FAILURE"
                                      ? _c(
                                          "span",
                                          { staticStyle: { color: "#b5a0b5" } },
                                          [_vm._v("已失效")]
                                        )
                                      : _vm._e(),
                                    _vm.searchForm.agreementStatus == "SIGNED"
                                      ? _c(
                                          "span",
                                          { staticStyle: { color: "#07b357" } },
                                          [_vm._v("已签订")]
                                        )
                                      : _vm._e(),
                                    _vm.searchForm.agreementStatus ==
                                    "EFFECTIVE"
                                      ? _c(
                                          "span",
                                          { staticStyle: { color: "#00cc4f" } },
                                          [_vm._v("生效中")]
                                        )
                                      : _vm._e(),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _vm.bizType === "OEM"
                        ? _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "签约机构：",
                                    prop: "orgCode",
                                  },
                                },
                                [
                                  _c("OemSelect", {
                                    attrs: { searchForm: _vm.orgForm },
                                    on: { nodeClick: _vm.nodeClick },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.bizType === "DEALER"
                        ? _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "签约机构：",
                                    prop: "orgCode",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { disabled: "" },
                                    model: {
                                      value: _vm.searchForm.orgName,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.searchForm, "orgName", $$v)
                                      },
                                      expression: "searchForm.orgName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "合作保险公司：",
                                prop: "insCorpCode",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    size: "mini",
                                    clearable: "",
                                    placeholder: "请输入合作保险公司",
                                  },
                                  on: { change: _vm.insCorpChange },
                                  model: {
                                    value: _vm.searchForm.insCorpCode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "insCorpCode",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.insCorpCode",
                                  },
                                },
                                _vm._l(
                                  _vm.insuranceList,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        placeholder: "请输入合作保险公司",
                                        label: item.shortName,
                                        value: item.code,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "合作机构全称：",
                                prop: "insCorpFullName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入合作机构全称",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.searchForm.insCorpFullName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.searchForm,
                                      "insCorpFullName",
                                      $$v
                                    )
                                  },
                                  expression: "searchForm.insCorpFullName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "合作机构分公司：",
                                prop: "insCorpBranchName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入合作机构分公司",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.searchForm.insCorpBranchName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.searchForm,
                                      "insCorpBranchName",
                                      $$v
                                    )
                                  },
                                  expression: "searchForm.insCorpBranchName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "代理产品类别：",
                                prop: "productType",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { placeholder: "请输入代理产品类别" },
                                  model: {
                                    value: _vm.searchForm.productType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "productType",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.productType",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: {
                                      label: "车险",
                                      value: "CAR_INSURANCE",
                                    },
                                  }),
                                  _c("el-option", {
                                    attrs: {
                                      label: "非车险",
                                      value: "NON_CAR_INSURANCE",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "代理产品名称：",
                                prop: "productName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入代理产品名称",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.searchForm.productName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchForm, "productName", $$v)
                                  },
                                  expression: "searchForm.productName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "签订日期：",
                                prop: "signedDate",
                              },
                            },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  type: "date",
                                  placeholder: "请输入签订日期",
                                },
                                model: {
                                  value: _vm.searchForm.signedDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchForm, "signedDate", $$v)
                                  },
                                  expression: "searchForm.signedDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "协议开始日期：",
                                prop: "startDate",
                              },
                            },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  type: "date",
                                  placeholder: "请输入协议开始日期",
                                },
                                model: {
                                  value: _vm.searchForm.startDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchForm, "startDate", $$v)
                                  },
                                  expression: "searchForm.startDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "协议终止日期：",
                                prop: "endDate",
                              },
                            },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  type: "date",
                                  placeholder: "请输入协议终止日期",
                                },
                                model: {
                                  value: _vm.searchForm.endDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchForm, "endDate", $$v)
                                  },
                                  expression: "searchForm.endDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.searchForm.productType == "NON_CAR_INSURANCE"
                        ? _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "非车险最高费率(%)：",
                                    prop: "accidentMaxRate",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请输入非车险最高费率",
                                      oninput:
                                        "value=value.replace(/[^0-9]{0,1}(\\d*(?:\\.\\d{0,2})?).*$/g, '$1')",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.searchForm.accidentMaxRate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "accidentMaxRate",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.accidentMaxRate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.searchForm.productType == "CAR_INSURANCE"
                        ? _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "交强险最高费率(%)：",
                                    prop: "bzMaxRate",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请输入交强险最高费率",
                                      oninput:
                                        "value=value.replace(/[^0-9]{0,1}(\\d*(?:\\.\\d{0,2})?).*$/g, '$1')",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.searchForm.bzMaxRate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "bzMaxRate",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.bzMaxRate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.searchForm.productType == "CAR_INSURANCE"
                        ? _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "商业险最高费率(%)：",
                                    prop: "bsMaxRate",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请输入商业险最高费率",
                                      oninput:
                                        "value=value.replace(/[^0-9]{0,1}(\\d*(?:\\.\\d{0,2})?).*$/g, '$1')",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.searchForm.bsMaxRate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "bsMaxRate",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.bsMaxRate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "remark", label: "备注：" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "",
                                  type: "textarea",
                                  rows: "3",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.searchForm.remark,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchForm, "remark", $$v)
                                  },
                                  expression: "searchForm.remark",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 21 } }, [
                        _c("p", [_vm._v("附件列表")]),
                      ]),
                      _c(
                        "el-col",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "flex-end",
                            "align-items": "center",
                          },
                          attrs: { span: 3 },
                        },
                        [
                          _c(
                            "el-upload",
                            {
                              staticClass: "upload-demo",
                              attrs: {
                                action: "",
                                limit: 10,
                                "http-request": function (param) {
                                  return _vm.uploadFile(
                                    param,
                                    "BUSINESS_LICENSE"
                                  )
                                },
                                "show-file-list": false,
                                "file-list": _vm.fileList,
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    plain: "",
                                    type: "primary",
                                  },
                                },
                                [_vm._v("选择文件")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-table",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                data: _vm.searchForm.agreementFileList,
                                border: "",
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: { label: "协议类型", width: "120" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: {
                                              clearable: "",
                                              prop: "agreementType",
                                              placeholder: "请选择",
                                            },
                                            on: { change: _vm.agreementChange },
                                            model: {
                                              value: scope.row.agreementType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "agreementType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.agreementType",
                                            },
                                          },
                                          _vm._l(
                                            _vm.agreementType,
                                            function (item, index) {
                                              return _c("el-option", {
                                                key: index,
                                                attrs: {
                                                  label: item.label,
                                                  value: item.value,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: { label: "文件名" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "file-text",
                                            attrs: {
                                              title: scope.row.fileName,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handle(scope.row)
                                              },
                                            },
                                          },
                                          [_vm._v(_vm._s(scope.row.fileName))]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: { label: "状态", width: "80" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            style:
                                              scope.row.uploadStatus ==
                                              "SUCCESS"
                                                ? "color:#5FB878"
                                                : "color:#ff464c",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                scope.row.uploadStatus ==
                                                  "SUCCESS"
                                                  ? "上传成功"
                                                  : "上传失败"
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: { label: "上传时间", width: "170" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("i", {
                                          staticClass: "el-icon-time",
                                        }),
                                        _c("span", [
                                          _vm._v(_vm._s(scope.row.createdTime)),
                                        ]),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "操作",
                                  width: "80",
                                  align: "center",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              size: "mini",
                                              type: "danger",
                                              plain: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleDelete(
                                                  scope.$index,
                                                  scope.row
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" 删除 ")]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-check",
                type: "primary",
                plain: "",
                size: "mini",
              },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: { click: _vm.closePage },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }