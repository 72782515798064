//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import NonAutoRequest from '@/api/nonAutoRequest';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    applyIdArr: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    policyCancelId: {
      type: String,
      return: ''
    }
  },
  data: function data() {
    return {
      form: {
        policyCancelId: '',
        auditType: 'AT01',
        auditDesc: ''
      },
      rules: {
        auditDesc: {
          required: true,
          message: '请输入审批说明',
          trigger: "blur"
        }
      },
      formLoading: false,
      timeout1: null
    };
  },
  watch: {
    applyIdArr: {
      handler: function handler(newValue, oldValue) {
        this.form.applyIds = newValue;
      },
      deep: true
    },
    refresh: function refresh(newValue) {
      if (newValue) {
        this.form = {
          policyCancelId: this.policyCancelId,
          auditType: 'AT01',
          auditDesc: ''
        };
        this.$refs['detail'].clearValidate();
      }
      ;
    }
  },
  created: function created() {
    this.form.policyCancelId = this.policyCancelId;
  },
  methods: {
    save: function save() {
      var _this = this;
      // 防抖
      if (this.timeout1) {
        clearTimeout(this.timeout1);
      }
      this.timeout1 = setTimeout(function () {
        _this.save1();
      }, 500);
    },
    // 保存数据
    save1: function save1() {
      var _this2 = this;
      this.$refs.detail.validate(function (valid) {
        if (valid) {
          _this2.formLoading = true;
          NonAutoRequest.getNonautoAudit(_this2.form).then(function (res) {
            _this2.formLoading = false;
            if (res.code === 200) {
              _this2.$message.success(res.msg);
              _this2.closePage();
            }
          }).catch(function (err) {
            _this2.formLoading = false;
          });
        }
      });
    },
    //关闭弹窗
    closePage: function closePage() {
      this.$emit('closePage');
    },
    loadData: function loadData() {
      //加载数据
    }
  }
};