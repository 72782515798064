import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import dict from '@/mixins/dict';
import Pagination from '@/components/Pagination';
import DatePicker from '@/components/DatePicker';
import commonReq from '@/api/common';
import OemSelect from '@/components/OemSelect';
import { getUserOrgInfoDealerOem, commonExport, getCurrentDate } from '@/utils/common';
export default {
  name: 'PolicyPush',
  components: {
    Pagination: Pagination,
    OemSelect: OemSelect,
    DatePicker: DatePicker
  },
  mixins: [initHeight, dict],
  computed: {},
  data: function data() {
    return {
      searchForm: {},
      startEndTime: [getCurrentDate(), getCurrentDate()],
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      loading: false,
      highlightCurrent: true,
      drawer: false,
      orgForm: {
        salesOrgCode: '',
        salesOrgName: '',
        salesOrgType: ''
      },
      bizType: localStorage.getItem("bizType") || ''
    };
  },
  created: function created() {
    this.loadData();
    if (this.bizType === "DEALER") {
      this.initData();
    }
    ;
  },
  methods: {
    // 初始化数据
    initData: function initData() {
      var orgObj = getUserOrgInfoDealerOem(this.bizType);
      this.searchForm.orgCode = orgObj.salesOrgCode;
      this.searchForm.orgName = orgObj.salesOrgName;
    },
    handleNodeClick: function handleNodeClick(data, node, target) {
      // 机构树点击事件
      this.searchForm.orgCode = data.salesOrgCode;
      this.searchForm.orgName = data.salesOrgName;
      this.searchForm.areaType = data.salesOrgType;
    },
    checkValue: function checkValue(val) {
      //输入的车架号中的字母自动转大写，并去除i，o，q
      if (val) {
        val = val.replace(/i/g, '').replace(/I/g, '').replace(/o/g, '').replace(/O/g, '').replace(/q/g, '').replace(/Q/g, '');
      }
      this.searchForm.vin = character(_.toUpper(val));
    },
    breakData: function breakData() {
      // this.resetSearch();
      this.loadData();
    },
    // 导出
    exportFn: function exportFn() {
      var exporParams = {
        total: this.total,
        limitNum: 200000,
        fileName: '保单推送监控',
        exportUrl: '/ps-ftms-service/ftms/policy/push/search',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    // 日期选择事件
    getPickersDate: function getPickersDate(name, val) {
      if (val && val.length > 1) {
        this[name] = val;
      } else {
        this[name] = [];
      }
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {
        orgName: this.bizType === 'OEM' ? '' : this.searchForm.orgName,
        orgCode: this.bizType === 'OEM' ? '' : this.searchForm.orgCode
      };
      this.orgForm = {
        salesOrgName: this.bizType === 'OEM' ? '全部' : '',
        salesOrgType: '',
        salesOrgCode: ''
      };
      this.startEndTime = [getCurrentDate(), getCurrentDate()];
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    loadData: function loadData() {
      var _this = this;
      //获取列表数据
      this.loading = true;
      this.drawer = false;
      if (this.startEndTime) {
        this.searchForm.startPushDate = this.startEndTime[0] && this.startEndTime[0].split(' ')[0] + ' 00:00:00' || null;
        this.searchForm.endPushDate = this.startEndTime[1] && this.startEndTime[1].split(' ')[0] + ' 23:59:59' || null;
      }
      var data = _objectSpread(_objectSpread({}, this.listQuery), this.searchForm);
      commonReq.searchPolicyPush(data).then(function (res) {
        _this.loading = false;
        if (res.code === 200) {
          _this.tableData = res.data.list;
          _this.total = res.data.page.recordsTotal;
        }
      }).catch(function (err) {
        _this.loading = false;
      });
    },
    push: function push(item) {
      var _this2 = this;
      var data = {
        policyId: item.policyId
      };
      commonReq.pushAgain(data).then(function (res) {
        if (res.code === 200) {
          _this2.$message.success(res.msg);
          _this2.loadData();
        }
        ;
      });
    }
  }
};