import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import dict from '@/mixins/dict';
import Pagination from '@/components/Pagination';
import resignation from '@/api/resignation'; //获取离职
import storageRequest from '@/api/storage';
import { commonExport, downFile } from '@/utils/common';
export default {
  name: 'TheContractManagement',
  components: {
    Pagination: Pagination
  },
  mixins: [initHeight, dict],
  computed: {},
  data: function data() {
    return {
      attachment: [],
      activeName: '',
      drawer: false,
      searchForm: {},
      list: null,
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      defaultProps: {
        children: 'children',
        label: 'fullName'
      },
      tableData: [],
      loading: false,
      highlightCurrent: true,
      orgData: [],
      reviewStatus: [{
        id: 0,
        num: '劳动合同工'
      }, {
        id: 1,
        num: '代理合同工'
      }],
      showImportDialog: false,
      currentEmployeeId: '',
      isEdit: false
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.loadData();
  },
  methods: {
    exportFn: function exportFn() {
      // 导出
      var exporParams = {
        total: this.total,
        fileName: '合同管理',
        exportUrl: '/tp-organization-service/info/search',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    //点击，使用id下载文件
    handle: function handle(row) {
      if (row.fileStorageId) {
        downFile(row.fileUrl, row.fileName);
      }
    },
    save: function save() {
      var _this = this;
      //保存数据
      this.$refs['upload'].clearFiles();
      resignation.updateContract(this.attachment).then(function (res) {
        _this.$message.success(res.msg);
        _this.loadData();
      });
    },
    closePage: function closePage() {
      this.loadData();
    },
    ondelete: function ondelete(item, index) {
      var _this2 = this;
      //删除
      this.$confirm("您确定删除该数据吗?", '提示', {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        if (item.infoId) {
          resignation.deleteContract([item.infoId]).then(function (res) {
            if (res.code === 200) {
              _this2.$message.success(res.msg);
              resignation.queryContract(_this2.currentEmployeeId, 'HT').then(function (res) {
                if (res.code === 200) {
                  _this2.attachment.splice(index, 1);
                }
              });
            }
          });
        } else {
          _this2.attachment.splice(index, 1);
        }
        _this2.$refs['upload'].clearFiles();
      }).catch(function () {});
    },
    uploadFile: function uploadFile(param) {
      var _this3 = this;
      //上传文件
      console.log(param);
      console.log(param.file);
      var formData = new FormData();
      formData.append("file", param.file);
      storageRequest.uploadFile(formData).then(function (res) {
        if (res.code === 200) {
          _this3.$message.success(res.msg);
          setTimeout(function () {
            var obj = {
              employeeId: _this3.currentEmployeeId,
              fileName: param.file.name,
              fileStorageId: res.data.fileStorageId,
              infoType: 'HT',
              infoTypeDisplay: '合同'
            };
            _this3.attachment.push(obj);
          }, 1000);
        }
      });
    },
    // 新增附件
    addFile: function addFile(item) {
      this.isEdit = false;
      this.attachment = [];
      this.showImportDialog = true;
      this.currentEmployeeId = item.employeeId;
    },
    // 删除附件
    view: function view(item) {
      console.log(item);
      console.log(item.employeeInfoItemVOS);
      this.currentEmployeeId = item.employeeId;
      this.showImportDialog = true;
      this.attachment = item.employeeInfoItemVOS;
    },
    // 删除附件
    removeFile: function removeFile(item) {
      var _this4 = this;
      this.isEdit = true;
      this.attachment = [];
      this.showImportDialog = true;
      this.currentEmployeeId = item.employeeId;
      resignation.queryContract(this.currentEmployeeId, 'HT').then(function (res) {
        if (res.code === 200) {
          _this4.attachment = res.data || [];
        }
      });
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {};
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    loadData: function loadData() {
      var _this5 = this;
      //获取列表数据
      this.loading = true;
      this.showImportDialog = false;
      this.showDialog = false;
      resignation.contractList(this.listQuery.pageNum, this.listQuery.pageSize, this.searchForm).then(function (res) {
        _this5.loading = false;
        if (res.code === 200) {
          _this5.tableData = res.data.list;
          _this5.total = res.data.page.recordsTotal;
        }
      }).catch(function (err) {
        _this5.loading = false;
      });
    },
    // 刷新
    breakData: function breakData() {
      // this.resetSearch()
      this.loadData();
    }
  }
};