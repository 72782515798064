import _createForOfIteratorHelper from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import NonAutoRequest from '@/api/nonAutoRequest';
export default {
  data: function data() {
    return {
      timeout1: null,
      ruleForm: {
        verifyCode: '',
        verifyName: '',
        verifyPhone: ''
      },
      rules: {
        verifyCode: [{
          required: true,
          message: '请输入验证码',
          trigger: 'blur'
        }],
        verifyName: [{
          required: true,
          message: '请输入审核人姓名',
          trigger: 'blur'
        }],
        verifyPhone: [{
          required: true,
          message: '请输入审核人手机号',
          trigger: 'blur'
        }]
      },
      cancelNo: ''
    };
  },
  created: function created() {
    // console.log('每次都触发')
    document.title = '审核人验证';
    if (this.$route.query && this.$route.query.cancelNo) {
      this.cancelNo = this.$route.query.cancelNo;
    }
  },
  methods: {
    getProductDetail: function getProductDetail(id) {
      var _this = this;
      NonAutoRequest.getProductDetail(id).then(function (res) {
        if (res.code == 200) {
          var resData = _.cloneDeep(res.data ? res.data : {});
          _this.carTypeVal = [resData.vhcBrandNo, resData.vhcSeriesNo];
          _this.addForm = resData ? resData : {};
          var resEfectiveStartTime = resData ? resData.effectiveStartTime.split(' ')[0] : '';
          var resEffectiveEndTime = resData ? resData.effectiveEndTime.split(' ')[0] : '';
          _this.effectiveTime = [resEfectiveStartTime, resEffectiveEndTime];
          _this.addForm.salesMethod = [];
          var _iterator = _createForOfIteratorHelper(res.data.salesMethod),
            _step;
          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var i = _step.value;
              _this.addForm.salesMethod.push(i.code);
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }
        }
      });
    },
    initData: function initData() {//初始化数据
    },
    save: function save(formName) {
      var _this2 = this;
      if (this.timeout1) {
        //防抖
        clearTimeout(this.timeout1);
      }
      this.timeout1 = setTimeout(function () {
        _this2.save1(formName);
      }, 800);
    },
    save1: function save1(formName) {
      var _this3 = this;
      //保存数据
      // console.log(this.ruleForm)
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var data = {
            mobile: _this3.ruleForm.verifyPhone ? _this3.ruleForm.verifyPhone : "",
            name: _this3.ruleForm.verifyName ? _this3.ruleForm.verifyName : "",
            verificationCode: _this3.ruleForm.verifyCode ? _this3.ruleForm.verifyCode : ""
          };
          NonAutoRequest.insCorpAuditLogin(data).then(function (res) {
            if (res.code == 200) {
              if (res.data) {
                var verifyAccessToken = res.data.accessToken;
                sessionStorage.setItem('verifyAccessToken', verifyAccessToken);
              }
              _this3.$message({
                type: 'success',
                message: '验证成功',
                center: true
              });
              _this3.$router.push({
                // name: 'verifyChargeback',
                name: 'verifyChargebackList'
              });
            }
          });
        }
      });
    }
  }
};