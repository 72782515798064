import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import giftApi from '@/api/giftManagement';
import dictRequest from '@/api/dictionary';
import { limitInput } from '@/utils/common';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    opType: {
      type: String,
      default: ''
    },
    currentId: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      form: {
        cardVoucherName: '',
        cardVoucherCode: '',
        cardVoucherType: '',
        validityDay: '',
        value: '',
        verificationCost: '',
        maxGiving: 1,
        enable: true,
        equities: ''
      },
      rules: {
        cardVoucherName: {
          required: true,
          message: '请输入赠品名称',
          trigger: "blur"
        },
        cardVoucherCode: {
          required: true,
          message: '请输入赠品编码',
          trigger: "blur"
        },
        cardVoucherType: {
          required: true,
          message: '请选择赠品类别',
          trigger: "change"
        },
        validityDay: {
          required: true,
          message: '请输入有效期(天)',
          trigger: "blur"
        }
      },
      formLoading: false,
      cardVoucherTypeList: [{
        value: 'COUPON',
        label: '卡券'
      }],
      longTerm: false
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
      ;
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    // 有效期数据改变
    validityDayChange: function validityDayChange(val) {
      this.longTerm = val == 999999;
    },
    // 有效期长期切换
    changeLongTerm: function changeLongTerm(val) {
      if (val) {
        this.form.validityDay = 999999;
      } else {
        this.form.validityDay = '';
      }
      ;
      this.$refs['detail'].clearValidate('validityDay');
    },
    // 对输入内容做精度校验
    onInput: function onInput(num, min, max, limit) {
      if (min && num < min) {
        num = min;
      }
      ;
      if (max && num > max) {
        num = max;
      }
      ;
      return limitInput(num, limit);
    },
    // 初始化数据
    initData: function initData() {
      var _this = this;
      this.form = {
        cardVoucherName: '',
        cardVoucherCode: '',
        cardVoucherType: '',
        validityDay: '',
        value: '',
        verificationCost: '',
        maxGiving: 1,
        enable: true,
        equities: ''
      };
      this.longTerm = false;
      if (this.opType === "edit") {
        giftApi.getGiftMaintainInfo(this.currentId).then(function (res) {
          if (res.code === 200) {
            _this.form = res.data;
            _this.longTerm = _this.form.validityDay == 999999;
          }
          ;
        });
      }
      ;
      this.$refs.detail.resetFields();
    },
    vouchersValidate: function vouchersValidate() {
      var flag = false;
      if (this.form.validityDay <= 0) {
        this.$message.warning('有效期(天)必须大于0');
        flag = true;
        return flag;
      }
      ;
      if (this.form.value && Number(this.form.value) <= 0) {
        this.$message.warning('价值(元)必须大于0');
        flag = true;
        return flag;
      }
      ;
      if (this.form.verificationCost && Number(this.form.verificationCost) <= 0) {
        this.$message.warning('赠品成本(元)必须大于0');
        flag = true;
        return flag;
      }
      ;
      if (!Number(this.form.maxGiving)) {
        this.$message.warning('最大发放数量不能为空且不能为0');
        flag = true;
        return flag;
      }
      ;
      return flag;
    },
    // 保存数据
    save: function save() {
      var _this2 = this;
      this.$refs.detail.validate(function (valid) {
        if (valid) {
          if (_this2.vouchersValidate()) return;
          _this2.formLoading = true;
          var req = _this2.opType === 'add' ? giftApi.addGiftMaintain(_this2.form) : giftApi.editGiftMaintain(_this2.currentId, _this2.form);
          req.then(function (res) {
            _this2.formLoading = false;
            if (res.code === 200) {
              _this2.$message.success(res.msg);
              _this2.closePage(false);
            }
            ;
          }).catch(function (err) {
            _this2.formLoading = false;
          });
        }
      });
    },
    // 关闭弹窗
    closePage: function closePage() {
      var boolean = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      this.$emit('closePage', boolean);
    }
  }
};