//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getSupplyers } from '@/utils/common';
import repairApi from '@/api/repair';
export default {
  // mixins: [dict],
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    opType: {
      type: String,
      default: "add"
    },
    templateId: {
      type: String,
      default: ""
    }
  },
  data: function data() {
    return {
      form: {},
      rules: {
        insCorpCode: [{
          required: true,
          message: '请选择保险公司',
          trigger: 'change'
        }],
        smsContent: [{
          required: true,
          message: '请输入短信内容',
          trigger: 'blur'
        }],
        templateContent: [{
          required: true,
          message: '请输入模板内容',
          trigger: 'blur'
        }]
      },
      tableData: [],
      supplyers: []
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
    }
  },
  mounted: function mounted() {
    this.initData();
    this.supplyers = getSupplyers();
  },
  methods: {
    getTemplatelist: function getTemplatelist() {
      var _this = this;
      repairApi.getTemplatelist().then(function (res) {
        if (res.code == 200) {
          _this.tableData = res.data ? res.data : [];
        }
      });
    },
    initData: function initData() {
      //初始化数据
      this.getTemplatelist();
      this.getTemplateInfo();
      if (this.opType != 'edit') {
        this.$refs['detail'].resetFields();
      }
    },
    getTemplateInfo: function getTemplateInfo() {
      var _this2 = this;
      repairApi.getTemplateInfo(this.templateId).then(function (res) {
        if (res.code == 200) {
          _this2.form = res.data ? res.data : {};
        }
      });
    },
    save: function save() {
      var _this3 = this;
      //保存数据
      this.$refs['detail'].validate(function (valid) {
        if (valid) {
          console.log(_this3.form);
          _this3.form.bizType = 'DEALER';
          // this.form.orgCode = 
          _this3.form.shared = true;
          // return
          var request = _this3.opType == 'edit' ? repairApi.updateTemplate(_this3.templateId, _this3.form) : repairApi.createTemplate(_this3.form);
          request.then(function (res) {
            if (res.code == 200) {
              _this3.$message({
                message: res.msg,
                type: 'success'
              });
              _this3.closePage();
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
      // repairApi.createTemplate().then(res=>{})
    },
    closePage: function closePage() {
      //关闭弹窗
      this.$refs['detail'].resetFields();
      this.$emit('closePage');
    }
  }
};