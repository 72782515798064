import _defineProperty from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/defineProperty.js";
var _objApi;
import "core-js/modules/es.array.concat.js";
/**
 * 费用台帐接口
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = (_objApi = {
  /**
   * 导入
   * @param {*} data 查询参数
   */
  importExcel: function importExcel(data) {
    return request.post(getUrl("/financestandingbook/importExcel", 1), data);
  },
  /**
   * 业务台账列表分页
   * @param {*} data 查询参数
   */
  getFinanceStandingBookList: function getFinanceStandingBookList(data) {
    return request.get(getUrl("/financestandingbook/search", 1), {
      params: data
    });
  },
  /**
   * 业务台账查看单条
   * @param {*} data 查询参数
   */
  getDetailById: function getDetailById(id) {
    return request.get(getUrl("/financestandingbook/get?id=".concat(id), 1));
  },
  /**
     * 业务台账编辑
     * @param {*} data 查询参数
     */
  updataFinancestandingbook: function updataFinancestandingbook(data) {
    return request.post(getUrl("/financestandingbook/update", 1), data);
  },
  /**
   * 下载excel模板
   */
  download: function download() {
    return request({
      method: 'get',
      url: getUrl("/finance/invoice/download/excel/template", 1),
      responseType: 'blob'
    });
  },
  /**
   *保司台账明细分页列表
   * @param {*} data 查询参数
   */
  getListInvoice: function getListInvoice(data) {
    return request.get(getUrl("/financeinvoicerepertoiredetails/search", 1), {
      params: data
    });
  },
  /**
   *对账/结账列表分页
   * @param {*} data 查询参数
   */
  getListReconciliation: function getListReconciliation(data) {
    return request.get(getUrl("/financereconciliation/search", 1), {
      params: data
    });
  },
  /**
   * 费用台账手续费单条修改
   * @param {*} data 内容
   */
  standingbookApi: function standingbookApi(data) {
    return request.post(getUrl("/finance/standingbook/poundage/update", 1), data);
  },
  /**
   *费用台账手续费单条修改
   * @param {*} data 查询参数
   */
  searchDetails: function searchDetails(data) {
    return request.get(getUrl("/finance/reconciliation/details/search", 1), {
      params: data
    });
  },
  /**
   *开启对账任务
   * @param {*} data 查询参数
   */
  taskOpen: function taskOpen(repertoireId) {
    return request.get(getUrl("/finance/standingbook/task/open/".concat(repertoireId), 1));
  },
  /**
  /**
   *开票清单选择
   * @param {*} data 查询参数
   */
  billingListSelection: function billingListSelection(data) {
    return request.get(getUrl("/finance/invoice/repertoire/select", 1), {
      params: data
    });
  },
  // /**
  //  *根据对账明细id 修改备注
  //  * @param {*} data 查询参数
  //  */
  // remarkDetails: function(data) {
  //     return request.post(getUrl(`/finance/reconciliation/details/remark/update`, 1), data)
  // },
  /**
   *对账/结账删除
   * @param {*} ids
   */
  deleteReconciliation: function deleteReconciliation(ids) {
    return request.delete(getUrl("/financereconciliation/batchDelete/".concat(ids), 1));
  },
  /**
   * 导入模板
   *  @param {*} data 内容
   */
  importModul: function importModul(data) {
    return request.post(getUrl("/finance/standingbook/poundage/update/import", 1), data);
  },
  /**
   * 开票清单导入
   *  @param {*} data 内容
   */
  importRepertoire: function importRepertoire(data) {
    return request.post(getUrl("/finance/invoice/repertoire/import", 1), data);
  },
  /**
   * 财务台账分页列表
   * @param {*} data 查询参数
   */
  searchUpStandingBook: function searchUpStandingBook(data) {
    return request.get(getUrl("/financereconciliationdetails/searchUpStandingBook", 1), {
      params: data
    });
  },
  /**
   * 财务台账导出明细
   * @param {*} data 查询参数
   */
  exportParameterSubsidiary: function exportParameterSubsidiary(data) {
    return request.get(getUrl("/financereconciliationdetails/search", 1), {
      params: data
    });
  },
  /**
   * 查询强平阈值
   */
  viewThreshold: function viewThreshold() {
    return request.get(getUrl("/financeconfig/getForceBalanceThreshold", 1));
  },
  /**
   * 更新强平阈值
   * @param {*} data
   */
  updateThreshold: function updateThreshold(data) {
    return request.post(getUrl("/financeconfig/saveForceBalanceThreshold", 1), data);
  },
  /**
   * 保存附件
   * @param {*} data
   */
  saveFiles: function saveFiles(data) {
    return request.post(getUrl("/financereconciliationfile/saveFiles", 1), data);
  },
  /**
   * 变更比率
   * @param {*} data
   */
  updateRate: function updateRate(data) {
    return request.post(getUrl("/financereconciliationdetails/updateRate", 1), data);
  },
  /**
   * 变更备注
   * @param {*} data
   */
  updateRemark: function updateRemark(data) {
    return request.post(getUrl("/financereconciliationdetails/updateRemark", 1), data);
  },
  /**
   * 批量标记
   * @param {*} data
   */
  batchMarkTax: function batchMarkTax(data) {
    return request.post(getUrl("/financereconciliationdetails/batchMarkTax", 1), data);
  },
  /**
   * 上报
   * @param {*} data
   */
  report: function report(data) {
    return request.post(getUrl("/financereconciliation/report", 1), data);
  },
  /**
   * 标记为驳回上报
   * @param {*} data
   */
  dereport: function dereport(data) {
    return request.post(getUrl("/financereconciliation/dereport", 1), data);
  },
  /**
   * 标记为已对账
   * @param {*} data
   */
  markReconciliation: function markReconciliation(data) {
    return request.post(getUrl("/financereconciliation/markReconciliation", 1), data);
  },
  //  **********************************回款登记************************************

  /**
   * 回款登记分页列表
   * @param {*} data 查询参数
   */
  searchFinancebill: function searchFinancebill(data) {
    return request.get(getUrl("/financebill/searchBill4Receive", 1), {
      params: data
    });
  },
  /**
   * 回款登记不分页列表
   * @param {*} coreFinanceBillId
   */
  searchFinancereceivedrecord: function searchFinancereceivedrecord(coreFinanceBillId) {
    return request.get(getUrl("/financereceivedrecord/list?coreFinanceBillId=".concat(coreFinanceBillId), 1));
  },
  /**
   * 新增
   * @param {*} data
   */
  createFinancereceivedrecord: function createFinancereceivedrecord(data) {
    return request.post(getUrl("/financereceivedrecord/create", 1), data);
  },
  //   **********************************开票登记************************************

  /**
   * 开票登记分页列表
   * @param {*} data 查询参数
   */
  searchFinancereconciliation: function searchFinancereconciliation(data) {
    return request.get(getUrl("/financereconciliation/search4bill", 1), {
      params: data
    });
  },
  /**
   * 查询开票登记详情
   * @param {*} id
   */
  getFinancereconciliation: function getFinancereconciliation(id) {
    return request.get(getUrl("/financereconciliation/get?id=".concat(id), 1));
  },
  /**
   * 开票登记明细不分页列表
   * @param {*} id
   */
  getFinancebill: function getFinancebill(id) {
    return request.get(getUrl("/financebill/list?reconciliationId=".concat(id), 1));
  },
  /**
   * 开票登记新增
   * @param {*} data
   */
  batchCreateFinancebill: function batchCreateFinancebill(data) {
    return request.post(getUrl("/financebill/batchCreate", 1), data);
  },
  /**
   * 开票登记明细废除
   * @param {*} id
   */
  deleteFinancebill: function deleteFinancebill(id) {
    return request.delete(getUrl("/financebill/delete/".concat(id), 1));
  },
  /**
   * 保存附件
   * @param {*} data
   */
  saveFilesFinancebill: function saveFilesFinancebill(data) {
    return request.post(getUrl("/financebillfile/saveFiles", 1), data);
  },
  /**
   * 查询附件分页接口
   * @param {*} data
   */
  searchFilesFinancebill: function searchFilesFinancebill(data) {
    return request.get(getUrl("/financebillfile/search", 1), {
      params: data
    });
  },
  /**
   * 结账
   * @param {*} data
   */
  markSettleFinancereconciliation: function markSettleFinancereconciliation(data) {
    return request.post(getUrl("/financereconciliation/markSettle", 1), data);
  },
  /**
   * 查询费用政策条件信息分页列表
   * @param {*} data
   */
  getFeePolicyConfigList: function getFeePolicyConfigList(data) {
    return request.get(getUrl("/costPolicy/policy", 1), {
      params: data
    });
  },
  /**
    * 查询费用政策因子信息分页列表
    * @param {*} data
    */
  getPolicyFactorList: function getPolicyFactorList(data) {
    return request.get(getUrl("/costPolicy/factor", 1), {
      params: data
    });
  },
  /**
     * 查询费用政策因子信息分页列表
     * @param {*} data
     */
  createPolicyFactor: function createPolicyFactor(data) {
    return request.post(getUrl("/costPolicy/factor", 1), data);
  },
  /**
   * 删除费用政策因子
   * @param {*} id
   */
  deletePolicyFactors: function deletePolicyFactors(id) {
    return request.delete(getUrl("/costPolicy/factor?ids=".concat(id), 1));
  },
  /**
  * 查看政策因子详情
  * @param {*} id
  */
  getDetailByFactorId: function getDetailByFactorId(id) {
    return request.get(getUrl("/costPolicy/factor/".concat(id), 1));
  },
  /**
  * 编辑政策因子
  * @param {*} id
  */
  editPolicyFactors: function editPolicyFactors(id, data) {
    return request.put(getUrl("/costPolicy/factor/".concat(id), 1), data);
  },
  /**
  * 新增政策条件
  * @param {*} id
  */
  createCondition: function createCondition(data) {
    return request.post(getUrl("/costPolicy/condition", 1), data);
  },
  /**
  * 新增政策列表数据
  * @param {*} id
  */
  createFeePolicyConfig: function createFeePolicyConfig(data) {
    return request.post(getUrl("/costPolicy/policy", 1), data);
  }
}, _defineProperty(_objApi, "createFeePolicyConfig", function createFeePolicyConfig(data) {
  return request.post(getUrl("/costPolicy/policy", 1), data);
}), _defineProperty(_objApi, "updataFeePolicyConfig", function updataFeePolicyConfig(id, data) {
  return request.put(getUrl("/costPolicy/policy/".concat(id), 1), data);
}), _defineProperty(_objApi, "deleteFeePolicy", function deleteFeePolicy(ids) {
  return request.delete(getUrl("/costPolicy/policy?ids=".concat(ids), 1));
}), _defineProperty(_objApi, "getDetailByCostPolicyId", function getDetailByCostPolicyId(id) {
  return request.get(getUrl("/costPolicy/policy/".concat(id), 1));
}), _defineProperty(_objApi, "enabledCostPolicy", function enabledCostPolicy(id, enabled) {
  return request.post(getUrl("/costPolicy/policy/enabled/".concat(id, "?enabled=").concat(enabled), 1));
}), _defineProperty(_objApi, "getAllPolicyFactors", function getAllPolicyFactors() {
  return request.get(getUrl("/costPolicy/factor/list", 1));
}), _defineProperty(_objApi, "getByPolicyId", function getByPolicyId(policyId) {
  return request.get(getUrl("/financestandingbook/getByPolicyId?policyId=".concat(policyId), 1));
}), _defineProperty(_objApi, "searchSalesmanCommission", function searchSalesmanCommission(data) {
  return request.get(getUrl("/finance/settlement/searchSalesmanCommission", 1), {
    params: data
  });
}), _defineProperty(_objApi, "searchSalesOrgCommission", function searchSalesOrgCommission(data) {
  return request.get(getUrl("/finance/settlement/searchSalesOrgCommission", 1), {
    params: data
  });
}), _defineProperty(_objApi, "batchSettlement", function batchSettlement(settleType, data) {
  return request.post(getUrl("/finance/settlement/batchSettlement?settleType=".concat(settleType), 1), data);
}), _defineProperty(_objApi, "searchSettlement", function searchSettlement(data) {
  return request.get(getUrl("/finance/settlement", 1), {
    params: data
  });
}), _defineProperty(_objApi, "searchSettlementDetails", function searchSettlementDetails(settlementId) {
  return request.get(getUrl("/finance/settlement/".concat(settlementId), 1));
}), _defineProperty(_objApi, "searchFinanceDetail", function searchFinanceDetail(data) {
  return request.get(getUrl("/finance/detail", 1), {
    params: data
  });
}), _defineProperty(_objApi, "reviewSettlement", function reviewSettlement(data) {
  return request.post(getUrl("/finance/settlement/reviewSettlement", 1), data);
}), _defineProperty(_objApi, "deReviewSettlement", function deReviewSettlement(settlementId) {
  return request.post(getUrl("/finance/settlement/deReviewSettlement?settlementId=".concat(settlementId), 1));
}), _defineProperty(_objApi, "applyPay", function applyPay(settlementId) {
  return request.post(getUrl("/finance/settlement/applyPay?settlementId=".concat(settlementId), 1));
}), _defineProperty(_objApi, "rejectSettlement", function rejectSettlement(settlementId, reviewRemark) {
  return request.post(getUrl("/finance/settlement/rejectSettlement?settlementId=".concat(settlementId, "&reviewRemark=").concat(reviewRemark), 1));
}), _defineProperty(_objApi, "searchPay", function searchPay(data) {
  return request.get(getUrl("/finance/pay", 1), {
    params: data
  });
}), _defineProperty(_objApi, "getByPayCode", function getByPayCode(payCode) {
  return request.get(getUrl("/finance/settlement/getByPayCode?payCode=".concat(payCode), 1));
}), _defineProperty(_objApi, "doPay", function doPay(data) {
  return request.put(getUrl("/finance/pay/doPay", 1), data);
}), _defineProperty(_objApi, "payResult", function payResult(data) {
  return request.put(getUrl("/finance/pay/payResult", 1), data);
}), _objApi);
export default objApi;