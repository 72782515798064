import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
//
//
//
//

import * as echarts from 'echarts';
require('echarts/theme/macarons'); // echarts theme
import resize from './mixins/resize';
var animationDuration = 6000;
export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '300px'
    },
    chartData: {
      type: Array,
      default: []
    },
    seriesName: {
      type: String,
      default: ""
    }
  },
  data: function data() {
    return {
      chart: null
    };
  },
  watch: {
    chartData: {
      deep: true,
      handler: function handler(val) {
        this.setOptions(val);
      }
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      _this.initChart();
    });
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      this.chart = echarts.init(this.$el);
      this.setOptions(this.chartData);
    },
    setOptions: function setOptions(chartData) {
      var _this2 = this;
      var yDatas = [];
      var timeline = [];
      if (!_.isEmpty(chartData)) {
        chartData.forEach(function (element) {
          yDatas.push(element.value);
          timeline.push(element.timeline);
        });
        this.chart.setOption({
          tooltip: {
            trigger: 'axis',
            backgroundColor: 'rgb(0,0,0,0.5)',
            // 提示框浮层的背景颜色。
            borderColor: 'rgb(255,255,255,0.3)',
            // 提示框浮层的边框颜色。
            borderWidth: 0,
            // 提示框浮层的边框宽。
            textStyle: {
              // 提示框浮层的文本样式。
              color: '#fff'
            },
            axisPointer: {
              // 坐标轴指示器，坐标轴触发有效
              type: 'line',
              // 默认为直线，可选为：'line' | 'shadow'
              lineStyle: {
                color: "#2ec7c9"
              }
            },
            formatter: function formatter(params) {
              var temp = timeline[parseInt(params[0].dataIndex)] + "<br/>" + params[0].marker + _this2.seriesName + "：" + params[0].data;
              return temp;
            }
          },
          grid: {
            top: 40,
            left: 0,
            right: 10,
            bottom: 0,
            containLabel: true
          },
          xAxis: [{
            type: 'category',
            show: false,
            data: yDatas,
            boundaryGap: false //不留白，从0开始
          }],

          yAxis: [{
            type: "value",
            show: false,
            axisLabel: {
              formatter: function formatter() {
                return "";
              }
            }
          }],
          series: [{
            name: this.seriesName,
            type: 'line',
            symbol: "none",
            smooth: true,
            itemStyle: {
              color: "#2ec7c9"
            },
            areaStyle: {
              //区域颜色
              color: "rgba(152,228,228,0.4)"
            },
            data: yDatas,
            animationEasing: 'cubicInOut'
          }]
        });
      }
    }
  }
};