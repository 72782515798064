var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loadingTree,
          expression: "loadingTree",
        },
      ],
      staticClass: "transferTreeBox",
    },
    [
      _c("div", { staticClass: "SelectBox" }, [
        _c(
          "div",
          { staticClass: "boxTitle", on: { click: _vm.clickAllSelect } },
          [_vm._v("全部经销商")]
        ),
        _c(
          "div",
          { staticClass: "boxCenter" },
          [
            _c(
              "div",
              {
                staticClass: "search",
                staticStyle: {
                  width: "100%",
                  padding: "10px",
                  "box-sizing": "border-box",
                },
              },
              [
                _c("el-input", {
                  attrs: { placeholder: "输入关键字进行过滤", clearable: "" },
                  model: {
                    value: _vm.leftTreeFilterText,
                    callback: function ($$v) {
                      _vm.leftTreeFilterText = $$v
                    },
                    expression: "leftTreeFilterText",
                  },
                }),
              ],
              1
            ),
            _c("el-tree", {
              ref: "leftTree",
              attrs: {
                data: _vm.leftData,
                props: _vm.defaultProps,
                "node-key": "id",
                indeterminate: true,
                "show-checkbox": "",
                "filter-node-method": _vm.filterNode,
              },
              on: { check: _vm.towardsRight },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var node = ref.node
                    var data = ref.data
                    return _c("span", { staticClass: "custom-tree-node" }, [
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              data.bizType == "DEALER"
                                ? data.name + " - " + data.id
                                : data.name
                            ) +
                            " "
                        ),
                      ]),
                    ])
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }