var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.formLoading,
          expression: "formLoading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: {},
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "500px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "addForm",
                  staticStyle: { "margin-bottom": "-20px" },
                  attrs: {
                    model: _vm.addForm,
                    rules: _vm.formRules,
                    "label-width": "100px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 20 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "路由组名称:", prop: "name" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入路由组名称",
                                  maxlength: "40",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.addForm.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addForm, "name", $$v)
                                  },
                                  expression: "addForm.name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c("el-transfer", {
                            staticClass: "flexCenter",
                            attrs: {
                              filterable: "",
                              titles: ["可选路由", "已选路由"],
                              props: { key: "value", label: "desc" },
                              data: _vm.routesData,
                            },
                            model: {
                              value: _vm.addForm.apiRouteIdList,
                              callback: function ($$v) {
                                _vm.$set(_vm.addForm, "apiRouteIdList", $$v)
                              },
                              expression: "addForm.apiRouteIdList",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-check",
                type: "primary",
                plain: "",
                size: "mini",
              },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: { click: _vm.closePage },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }