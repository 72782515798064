var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.riskTypeDisabled,
          expression: "riskTypeDisabled",
        },
      ],
      staticClass: "mapMask",
      staticStyle: { background: "#18232b", color: "white" },
      attrs: {
        id: "map-container",
        "element-loading-text": "查询中...",
        "element-loading-background": "rgba(0, 0, 0, 0.7)",
      },
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            padding: "5px 15px",
            background: "#163456",
            height: "50px",
            "align-items": "center",
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                width: "450px",
                display: "flex",
                "align-items": "center",
              },
            },
            [
              _c(
                "el-radio-group",
                {
                  staticClass: "radio_group",
                  attrs: { disabled: _vm.riskTypeDisabled, fill: "#00579c" },
                  on: { change: _vm.handleRadio },
                  model: {
                    value: _vm.tabPosition,
                    callback: function ($$v) {
                      _vm.tabPosition = $$v
                    },
                    expression: "tabPosition",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: "DAY" } }, [
                    _vm._v("今天"),
                  ]),
                  _c(
                    "el-radio-button",
                    {
                      staticStyle: { margin: "0 2px" },
                      attrs: { label: "WEEK" },
                    },
                    [_vm._v("本周")]
                  ),
                  _c("el-radio-button", { attrs: { label: "MONTH" } }, [
                    _vm._v("本月"),
                  ]),
                  _c(
                    "el-radio-button",
                    {
                      staticStyle: { margin: "0 2px" },
                      attrs: { label: "YEAR" },
                    },
                    [_vm._v("今年")]
                  ),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    margin: "0 3px",
                    color: "#f9f9f9",
                    opacity: "0.5",
                  },
                },
                [_vm._v("|")]
              ),
              _c(
                "el-radio-group",
                {
                  staticClass: "radio_group",
                  attrs: { disabled: _vm.riskTypeDisabled, fill: "#00579c" },
                  on: { change: _vm.riskTypeC },
                  model: {
                    value: _vm.riskType,
                    callback: function ($$v) {
                      _vm.riskType = $$v
                    },
                    expression: "riskType",
                  },
                },
                [
                  _c(
                    "el-radio-button",
                    {
                      staticStyle: { "margin-left": "2px" },
                      attrs: { label: "BZ,BS" },
                    },
                    [_vm._v("交&商")]
                  ),
                  _c(
                    "el-radio-button",
                    {
                      staticStyle: { margin: "0 2px" },
                      attrs: { label: "BZ" },
                    },
                    [_vm._v("交强险")]
                  ),
                  _c("el-radio-button", { attrs: { label: "BS" } }, [
                    _vm._v("商业险"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                flex: "1",
                "text-align": "center",
                "font-size": "18px",
                "font-weight": "bold",
              },
            },
            [_vm._v(_vm._s(_vm.title))]
          ),
          _c(
            "div",
            {
              staticStyle: {
                width: "450px",
                display: "flex",
                "justify-content": "flex-end",
                "font-size": "14px",
              },
            },
            [
              _c(
                "span",
                { staticStyle: { "margin-right": "15px" } },
                [
                  _vm._v("当前位置： "),
                  _c(
                    "el-link",
                    {
                      staticStyle: {
                        "font-weight": "normal",
                        color: "#f7f7f7",
                        "margin-bottom": "2px",
                      },
                      attrs: {
                        underline: false,
                        disabled: _vm.riskTypeDisabled,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.switchMap(_vm.defaultCity)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.defaultCity))]
                  ),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currentCity,
                          expression: "currentCity",
                        },
                      ],
                    },
                    [_vm._v(" | ")]
                  ),
                  _c(
                    "el-link",
                    {
                      staticStyle: {
                        "font-weight": "normal",
                        color: "#f7f7f7",
                        "margin-bottom": "2px",
                      },
                      attrs: {
                        underline: false,
                        disabled: _vm.riskTypeDisabled,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.switchMap(_vm.currentCity)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.currentCity))]
                  ),
                ],
                1
              ),
              _c(
                "el-tooltip",
                {
                  attrs: {
                    content: "" + (!_vm.isFullscreen ? "全屏" : "退出全屏"),
                    effect: "dark",
                    placement: "bottom",
                  },
                },
                [
                  _c("svg-icon", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.isFullscreen,
                        expression: "!isFullscreen",
                      },
                    ],
                    attrs: {
                      "icon-class": _vm.isFullscreen
                        ? "exit-fullscreen"
                        : "fullscreen",
                    },
                    on: { click: _vm.switchScreen },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          style: {
            "overflow-y": "auto",
            padding: "10px",
            height: _vm.containerH,
            "overflow-y": "auto",
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-card",
                    {
                      staticClass: "box-card",
                      staticStyle: {
                        border: "1px solid #173551",
                        height: "270px",
                        color: "white",
                      },
                      attrs: { shadow: "never" },
                    },
                    [
                      _c("div", { staticClass: "chart-wrapper" }, [
                        _c(
                          "div",
                          {
                            staticClass: "header",
                            staticStyle: { "font-weight": "700" },
                          },
                          [_vm._v("总出单量走势")]
                        ),
                        _c(
                          "div",
                          { staticStyle: { padding: "10px" } },
                          [
                            _c("line-smooth-chart", {
                              attrs: {
                                xAxis: _vm.xlineData,
                                series: _vm.seriesLineData,
                                height: "240px",
                                "series-name": "出单",
                                "filter-type": _vm.tabPosition,
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-card",
                    {
                      staticClass: "box-card",
                      staticStyle: {
                        border: "1px solid #173551",
                        background: "#163456",
                        "margin-top": "10px",
                        height: "270px",
                        color: "white",
                      },
                      attrs: { shadow: "never" },
                    },
                    [
                      _c("div", { staticClass: "chart-wrapper" }, [
                        _c(
                          "div",
                          {
                            staticClass: "header",
                            staticStyle: { "font-weight": "700" },
                          },
                          [_vm._v("总保费走势")]
                        ),
                        _c(
                          "div",
                          { staticStyle: { padding: "10px" } },
                          [
                            _c("line-smooth-chart", {
                              attrs: {
                                xAxis: _vm.xlineData,
                                series: _vm.seriesLineData1,
                                height: "240px",
                                "series-name": "保费",
                                "filter-type": _vm.tabPosition,
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { staticStyle: { position: "relative" }, attrs: { span: 12 } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "grid-content bg-purple-dark",
                      staticStyle: { color: "white," },
                    },
                    [
                      _c(
                        "el-row",
                        { staticStyle: { padding: "5px" } },
                        [
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c(
                              "div",
                              { staticStyle: { "text-align": "center" } },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "font-size": "14px",
                                      opacity: "0.5",
                                    },
                                  },
                                  [_vm._v("出单总量")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "padding-top": "5px",
                                      "font-size": "18px",
                                      "font-weight": "bold",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.totalQuantity
                                          ? _vm.totalQuantity.toLocaleString()
                                          : 0
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c(
                              "div",
                              { staticStyle: { "text-align": "center" } },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "font-size": "14px",
                                      opacity: "0.5",
                                    },
                                  },
                                  [_vm._v("总保费(元)")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "padding-top": "5px",
                                      "font-size": "18px",
                                      "font-weight": "bold",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "￥ " +
                                        _vm._s(
                                          _vm.totalPremium
                                            ? Number(
                                                _vm.totalPremium.toFixed(0)
                                              ).toLocaleString()
                                            : "0"
                                        )
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c("ChinaMapChart", {
                        ref: "chinaMap",
                        attrs: {
                          id: _vm.mapId,
                          "chart-data": _vm.mapData,
                          width: _vm.mapW,
                          height: _vm.mapH,
                        },
                        on: { selectedCity: _vm.selectedCity },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-card",
                    {
                      staticClass: "box-card",
                      staticStyle: {
                        border: "1px solid #173551",
                        background: "#163456",
                        height: "270px",
                        color: "white",
                      },
                      attrs: { shadow: "never" },
                    },
                    [
                      _c("div", { staticClass: "chart-wrapper" }, [
                        _c(
                          "div",
                          {
                            staticClass: "header",
                            staticStyle: { "font-weight": "700" },
                          },
                          [_vm._v("出单保费TOP5")]
                        ),
                        !_vm._.isEmpty(_vm.premiumData)
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  padding: "0 5px",
                                  "font-size": "12px",
                                  "overflow-y": "auto",
                                  "max-height": "220px",
                                },
                              },
                              _vm._l(_vm.premiumData, function (item, index) {
                                return _c("div", { key: index }, [
                                  _c(
                                    "p",
                                    {
                                      staticStyle: {
                                        padding: "5px 0",
                                        "border-bottom": "1px dashed #294664",
                                        display: "flex",
                                        "justify-content": "space-between",
                                      },
                                    },
                                    [
                                      _vm._v(_vm._s(item.salesOrgName)),
                                      _c("span", [
                                        _vm._v(
                                          "￥ " +
                                            _vm._s(
                                              item.premium
                                                ? Number(
                                                    item.premium.toFixed(0)
                                                  ).toLocaleString()
                                                : "0"
                                            )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ])
                              }),
                              0
                            )
                          : _c(
                              "div",
                              {
                                staticStyle: {
                                  padding: "5px",
                                  "font-size": "12px",
                                },
                              },
                              [_vm._v(" 暂无网点出单数据！ ")]
                            ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-card",
                    {
                      staticClass: "box-card",
                      staticStyle: {
                        border: "1px solid #173551",
                        background: "#163456",
                        "margin-top": "10px",
                        height: "270px",
                        color: "white",
                      },
                      attrs: { shadow: "never" },
                    },
                    [
                      _c("div", { staticClass: "chart-wrapper" }, [
                        _c(
                          "div",
                          {
                            staticClass: "header",
                            staticStyle: { "font-weight": "700" },
                          },
                          [_vm._v("出单量TOP5")]
                        ),
                        !_vm._.isEmpty(_vm.quantityData)
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  padding: "0 5px",
                                  "font-size": "12px",
                                  "overflow-y": "auto",
                                  "max-height": "220px",
                                },
                              },
                              _vm._l(_vm.quantityData, function (item, index) {
                                return _c("div", { key: index }, [
                                  _c(
                                    "p",
                                    {
                                      staticStyle: {
                                        padding: "5px 0",
                                        "border-bottom": "1px dashed #294664",
                                        display: "flex",
                                        "justify-content": "space-between",
                                      },
                                    },
                                    [
                                      _vm._v(_vm._s(item.salesOrgName)),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            item.quantity
                                              ? item.quantity.toLocaleString()
                                              : "0"
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ])
                              }),
                              0
                            )
                          : _c(
                              "div",
                              {
                                staticStyle: {
                                  padding: "0 5px",
                                  "font-size": "12px",
                                },
                              },
                              [_vm._v(" 暂无网点出单数据！ ")]
                            ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "box-card",
              staticStyle: {
                background: "#163456",
                border: "1px solid #173551",
                padding: "5px",
                "margin-top": "10px",
                "border-radius": "4px",
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    "font-weight": "700",
                    padding: "5px",
                    "line-height": "40px",
                    color: "white",
                  },
                },
                [_vm._v("保司出单业务")]
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("barChart", {
                        attrs: {
                          xAxis: _vm.xAxisData,
                          series: _vm.seriesData,
                          height: "300px",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "padding-left": "10px" },
                      attrs: { span: 12 },
                    },
                    [
                      _c("pie-charts", {
                        attrs: {
                          legendColor: "#fff",
                          "chart-data": _vm.quantityRateData,
                          height: "300px",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "box-card",
              staticStyle: {
                background: "#163456",
                border: "1px solid #173551",
                padding: "5px",
                margin: "10px 0px",
                "border-radius": "4px",
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    "font-weight": "700",
                    padding: "5px",
                    "line-height": "40px",
                    color: "white",
                  },
                },
                [_vm._v("保司保费业务")]
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("barChart", {
                        attrs: {
                          xAxis: _vm.xAxisData,
                          series: _vm.seriesData1,
                          height: "300px",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "padding-left": "10px" },
                      attrs: { span: 12 },
                    },
                    [
                      _c("pie-charts", {
                        attrs: {
                          legendColor: "#fff",
                          "chart-data": _vm.premiumRateData,
                          height: "300px",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }