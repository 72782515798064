/**
 * 批量同步接口
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 列表
   * @param {*} data 参数
   */
  getList: function getList(data) {
    return request.get(getUrl("/syncPolicy/search", 1), {
      params: data
    });
  },
  /**
   * 新增
   * @param {*} data 内容
   */
  createBatchSync: function createBatchSync(data) {
    return request.post(getUrl("/syncPolicy/create", 1), data);
  },
  /**
   * 分页查询批量同步结果
   * @param {*} data 内容
   */
  getResultList: function getResultList(data) {
    return request.get(getUrl("/syncPolicy/searchResult", 1), {
      params: data
    });
  },
  /**
   * 分页查询表头数量
   * @param {*} data 内容
   */
  getAllQuantity: function getAllQuantity(data) {
    return request.get(getUrl("/syncPolicy/summaryResult", 1), {
      params: data
    });
  },
  /**
  * 同步结果操作功能
  * @param {*} data 内容
  * @param {*} operation 枚举值
  */
  distributionOrSearch: function distributionOrSearch(operation, data) {
    return request.post(getUrl("/syncPolicy/operation?operation=".concat(operation), 1), data);
  }
};
export default objApi;