import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.keys.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "SpecialForm",
  props: {
    specialList: {
      type: Array,
      required: true
    },
    productId: {
      type: Number,
      required: true
    }
  },
  data: function data() {
    return {
      formData: [],
      form: {},
      checkedList: []
    };
  },
  computed: {},
  watch: {
    specialList: function specialList() {
      this.initData();
    }
  },
  created: function created() {},
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    setChecked: function setChecked(row, index) {
      if (row.checked) {
        this.checkedList.push(row);
      } else {
        if (this.checkedList) {
          if (row.proposalRiskSpecialtermsId) {
            _.remove(this.checkedList, function (o) {
              return o.proposalRiskSpecialtermsId == row.proposalRiskSpecialtermsId;
            });
          } else {
            this.checkedList.splice(index, 1);
          }
        } else {
          this.checkedList = [];
        }
        this.$forceUpdate();
      }
    },
    initData: function initData() {
      var _this = this;
      if (!_.isEmpty(this.specialList)) {
        var _list = _.cloneDeep(this.specialList);
        var newData = [];
        _list.forEach(function (item) {
          item.proposalRiskId = _this.productId;
          item.newContent = item.specialTermsText;
          //自定义特约
          item.isSelf = !item.specialTermsText || item.specialTermsStatus == '2';
          //自主特约
          item.isCustom = item.specialTermsStatus == '3';
          var _item = _.find(_list, function (eitem) {
            return eitem.specialTermsCode == item.specialTermsCode && eitem.checked && eitem.specialTermsStatus == '1';
          });
          if (_item) {
            item.specialEditContent = _item.specialEditContent;
            if (!item.checked) {
              item.checked = true;
              newData.push(item);
            }
          } else {
            item.specialEditContent = "";
            newData.push(item);
          }
        });
        this.formData = newData;
      } else {
        this.formData = [];
      }
    },
    deleteCustom: function deleteCustom(index) {
      this.formData.splice(index, 1);
    },
    addCustomSpecial: function addCustomSpecial() {
      this.formData.push({
        proposalRiskId: this.productId,
        specialTermsNo: null,
        specialTermsCode: "",
        specialTermsName: null,
        specialTermsText: "",
        specialTermsStatus: '3',
        //0. 固定 1.可编辑 2.自定义(特约编码是保司的，特约内容是自己编辑) 3.自主(特约编码和特约内容都是自己编辑)
        isCustom: true,
        newContent: '',
        isSelf: false,
        checked: false,
        specialEditContent: ''
      });
      this.$nextTick(function () {
        var ele = this.$refs.special_div;
        ele.scrollTop = ele.scrollHeight;
      });
    },
    submit: function submit() {
      var selectItems = [];
      //获取输入框中的内容替换*号，组装新的条款内容；
      this.formData.forEach(function (item) {
        if (item.checked) {
          if (item.isCustom) {
            item.isNull = !item.specialTermsCode || !item.specialTermsText;
          } else {
            if (item.isSelf) {
              item.isNull = !item.specialTermsText;
            } else {
              var texts = item.specialTermsText.split('*');
              var newContent = '';
              var _i = 0;
              var inputLen = 0;
              var childLen = 0;
              var inputData = [];
              texts.forEach(function (text) {
                var child = document.getElementsByClassName("item_".concat(item.specialTermsCode, "_").concat(_i));
                if (!_.isEmpty(child)) {
                  childLen++;
                }
                var value = child[0] ? child[0].value : null;
                if (value) {
                  inputLen++;
                  if (_i < texts.length - 1) {
                    inputData.push(child[0].value);
                    newContent = newContent + text + child[0].value;
                  } else {
                    newContent = newContent + text;
                  }
                } else {
                  newContent = newContent + text;
                }
                _i++;
              });
              item.isNull = childLen != inputLen;
              item.newContent = newContent;
              item.specialEditContent = inputData ? JSON.stringify(inputData) : null;
            }
          }
          selectItems.push(item);
        }
      });
      //判断是否全部都补充完整；
      var hasNullData = _.find(selectItems, function (item) {
        return item.isNull;
      });
      if (hasNullData) {
        this.$message({
          type: 'warning',
          message: "\u7279\u522B\u7EA6\u5B9A\u3010".concat(hasNullData.specialTermsCode, "\u3011\u7684\u5185\u5BB9\u4E0D\u5B8C\u6574\uFF0C\u8BF7\u8865\u5145\uFF01"),
          offset: 50,
          customClass: 'zZindex'
        });
      } else {
        //检查自主特约是否有重复的编码或者名称
        var customItems = _.filter(selectItems, function (o) {
          return o.isCustom;
        });
        if (!_.isEmpty(customItems)) {
          var sameItem = null;
          for (var i = 0; i < customItems.length; i++) {
            for (var j = i + 1; j < customItems.length; j++) {
              if (customItems[i].specialTermsCode == customItems[j].specialTermsCode) {
                // || customItems[i].specialTermsName == customItems[j].specialTermsName) {
                sameItem = customItems[j];
                break;
              }
            }
          }
          if (sameItem) {
            this.$message({
              type: 'warning',
              message: "\u60A8\u6DFB\u52A0\u4E86\u76F8\u540C\u7F16\u7801\u7684\u81EA\u4E3B\u7279\u7EA6\uFF0C\u8BF7\u4FEE\u6539\uFF01",
              offset: 50,
              customClass: 'zZindex'
            });
            return;
          }
        }
        this.$emit('closeSpecial', this.formData);
      }
    },
    cancel: function cancel() {
      this.checkedList = [];
      this.$emit('closeSpecial');
    },
    reSetName: function reSetName(item) {
      //将含有固定占位符“***”的内容替换成输入框；
      var _content = item.specialTermsText.split('*');
      var _newContent = '';
      var i = 0;
      var _contents = [];
      if (item.specialEditContent) {
        try {
          _contents = JSON.parse(item.specialEditContent);
        } catch (err) {
          _contents = [];
        }
        console.log('_contents=>:', _contents);
      }
      var inputLen = 0;
      _content.forEach(function (content) {
        if (i < _content.length - 1 && content) {
          var input = "<input type=\"text\" class='item_".concat(item.specialTermsCode, "_").concat(i, "' value='").concat(_contents && _contents.length > 0 ? _contents[inputLen] || '' : '', "' style=\"width:120px;border: 1px solid #d8d8d8;border-radius: 3px;padding: 3px 5px;margin:2px\">");
          _newContent = _newContent + content + input;
          inputLen++;
        } else {
          if (i == 0 && !content) {
            var input = "<input type=\"text\" class='item_".concat(item.specialTermsCode, "_").concat(i, "' value='").concat(_contents && _contents.length > 0 ? _contents[0] || '' : '', "' style=\"width:120px;border: 1px solid #d8d8d8;border-radius: 3px;padding: 3px 5px;margin:2px\">");
            _newContent = _newContent + content + input;
          } else {
            _newContent = _newContent + content;
          }
        }
        i++;
      });
      return _newContent;
    }
  }
};