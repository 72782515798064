//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import claimManageApi from '@/api/claimManage';
import PageContainer from "@/components/PageContainer";
import FormLabel from "@/components/FormSubLabel";
import WorkOrderDialog from "./dialog/workOrderDialog";
export default {
  name: 'ReportClaimDetail',
  components: {
    PageContainer: PageContainer,
    FormLabel: FormLabel,
    WorkOrderDialog: WorkOrderDialog
  },
  data: function data() {
    return {
      activeNames: ['1', '2'],
      formLoading: false,
      reportId: '',
      reportData: {},
      showWorkOrderDialog: false,
      loadingRepairStatus: false
    };
  },
  created: function created() {
    this.initData();
  },
  watch: {
    // 监听路由是否变化）
    $route: function $route(route) {
      this.initData();
    }
  },
  methods: {
    // 初始化数据
    initData: function initData() {
      this.reportId = this.$route.query.reportId || '';
      this.getCaseDetails();
    },
    // 获取案件详情
    getCaseDetails: function getCaseDetails() {
      var _this = this;
      var isDialogNoRefresh = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.showWorkOrderDialog = false;
      if (isDialogNoRefresh) return;
      this.formLoading = true;
      claimManageApi.getClaimsReportInfo(this.reportId).then(function (res) {
        _this.formLoading = false;
        if (res.code === 200) {
          _this.reportData = res.data || {};
        }
        ;
      }).catch(function (err) {
        _this.formLoading = false;
      });
    },
    // 刷新车主报案线索状态
    refreshRepairStatus: function refreshRepairStatus() {
      var _this2 = this;
      this.loadingRepairStatus = true;
      claimManageApi.getClaimsReportInfo(this.reportId).then(function (res) {
        _this2.loadingRepairStatus = false;
        if (res.code === 200) {
          _this2.reportData = res.data || {};
        }
        ;
      }).catch(function (err) {
        _this2.loadingRepairStatus = false;
      });
    },
    // 返回
    back: function back() {
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.go(-1);
    }
  }
};