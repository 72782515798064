import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '../../../mixins/initHeight';
import dict from '../../../mixins/dict';
import policyRequest from '@/api/insurancepolicy';
import archivesManagement from "@/api/archivesManagement";
export default {
  mixins: [initHeight, dict],
  props: {
    vin: {
      type: String,
      default: null
    },
    transmitObj: {
      type: Object
    },
    isInsurance: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    vin: {
      handler: function handler(newValue, oldValue) {
        this.fetchData();
      },
      deep: true
    },
    receiveObj: {
      handler: function handler(newValue, oldValue) {
        this.fetchData();
      },
      deep: true
    },
    '$route': {
      handler: function handler(val) {
        switch (val.path) {
          // 同路由不同参数刷新
          case "/ArchivesManagement/insuranceBillList":
            this.refreshData();
            return;
        }
      }
    }
  },
  data: function data() {
    return {
      listData: [],
      listLoading: true,
      total: 0,
      pageNum: 1,
      pageSize: 20,
      receiveObj: {},
      insurancePolicy_vin: ''
    };
  },
  activated: function activated() {
    this.refreshData();
  },
  mounted: function mounted() {
    if (this.transmitObj) {
      this.receiveObj = this.transmitObj;
    }
    // this.fetchData();
  },

  methods: {
    refreshData: function refreshData() {
      this.insurancePolicy_vin = this.$route.query.vin || '';
      var currentInsurancePolicy_vin = sessionStorage.getItem('insurancePolicy_vin');
      if (currentInsurancePolicy_vin !== this.insurancePolicy_vin) {
        this.transmitObj.pageNum = 1, this.transmitObj.pageSize = 20, this.transmitObj.riskType = this.$route.query.riskType, this.transmitObj.vin = this.$route.query.vin, this.pageSize = 20;
        this.pageNum = 1;
        sessionStorage.setItem('insurancePolicy_vin', this.insurancePolicy_vin);
      }
    },
    viewDetail: function viewDetail(item) {
      //查看保单详情
      var router_path = this.$route.fullPath;
      this.$router.push({
        path: "/policy/detail",
        query: {
          policyId: item.policyId,
          back: router_path,
          plateNo: item.plateNo,
          vin: item.vin,
          policyNo: item.policyNo
        }
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      //分页查询事件
      this.pageNum = 1;
      this.pageSize = val;
      this.fetchData();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      //分页查询事件
      this.pageNum = val;
      this.fetchData();
    },
    fetchData: function fetchData() {
      var _this = this;
      //初始化数据列表
      this.listLoading = false;
      if (this.$route.query.type == 'insuranInfo' || this.$route.query.type == 'businessInfo') {
        archivesManagement.getInsuranceTypeList(this.transmitObj).then(function (res) {
          console.log('getInsuranceTypeList', res);
          _this.listData = res.data.list;
          _this.listLoading = false;
          if (!_.isEmpty(res.data)) {
            _this.listData = res.data.list;
            //总条数
            _this.total = res.data.page.recordsTotal;
          }
        }).catch(function (err) {
          _this.listLoading = false;
        });
      } else {
        policyRequest.getList(this.pageNum, this.pageSize, {
          vin: this.vin
        }).then(function (res) {
          _this.listLoading = false;
          if (!_.isEmpty(res.data)) {
            _this.listData = res.data.list;
            //总条数
            _this.total = res.data.page.recordsTotal;
          }
        }).catch(function (err) {
          _this.listLoading = false;
        });
      }
    }
  }
};