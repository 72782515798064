var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticStyle: { background: "#f5f5f5" },
      attrs: { id: "map-container" },
    },
    [
      _c(
        "div",
        {
          staticClass: "search-box",
          staticStyle: { padding: "5px 15px", background: "#fff" },
        },
        [
          _c("div", [
            _c("span", [_vm._v("组织机构：")]),
            _vm.bizType === "BROKER"
              ? _c(
                  "div",
                  { staticStyle: { "margin-right": "5px" } },
                  [
                    _c("OrgSelect", {
                      staticStyle: { width: "200px" },
                      attrs: { searchForm: _vm.orgData },
                      on: { nodeClick: _vm.nodeClick },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.bizType === "OEM"
              ? _c(
                  "div",
                  { staticStyle: { "margin-right": "5px" } },
                  [
                    _c("OemSelect", {
                      attrs: {
                        searchForm: _vm.orgForm,
                        size: "mini",
                        canClickArea: true,
                      },
                      on: { nodeClick: _vm.nodeClick },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.bizType === "DEALER"
              ? _c(
                  "div",
                  { staticStyle: { "margin-right": "5px" } },
                  [
                    _c("el-input", {
                      attrs: { size: "mini", disabled: "" },
                      model: {
                        value: _vm.searchForm.orgName,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchForm, "orgName", $$v)
                        },
                        expression: "searchForm.orgName",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _c(
            "div",
            [
              _c("span", [_vm._v("查询时段：")]),
              _c(
                "el-select",
                {
                  staticStyle: { width: "90px" },
                  attrs: {
                    size: "mini",
                    placeholder: "请选择查询范围",
                    clearable: false,
                  },
                  on: { change: _vm.selectFilterType },
                  model: {
                    value: _vm.filterType,
                    callback: function ($$v) {
                      _vm.filterType = $$v
                    },
                    expression: "filterType",
                  },
                },
                [
                  _c("el-option", {
                    attrs: { label: "昨天", value: "lastDay" },
                  }),
                  _c("el-option", {
                    attrs: { label: "近一周", value: "week" },
                  }),
                  _c("el-option", {
                    attrs: { label: "本月", value: "currentMonth" },
                  }),
                  _c("el-option", {
                    attrs: { label: "近一月", value: "month" },
                  }),
                  _c("el-option", {
                    attrs: { label: "上月", value: "lastMonth" },
                  }),
                  _c("el-option", {
                    attrs: { label: "近一年", value: "year" },
                  }),
                  _c("el-option", {
                    attrs: { label: "自定义", value: "custom" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _vm.filterType != "custom"
                ? _c("el-date-picker", {
                    staticStyle: { "margin-left": "5px", width: "220px" },
                    attrs: {
                      disabled: _vm.filterType != "custom",
                      size: "mini",
                      "picker-options": _vm.pickerOptions,
                      clearable: false,
                      "start-placeholder": "起期",
                      "end-placeholder": "止期",
                      type: "daterange",
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.dateChange("startDate", "endDate", $event)
                      },
                    },
                    model: {
                      value: _vm.startEndTime,
                      callback: function ($$v) {
                        _vm.startEndTime = $$v
                      },
                      expression: "startEndTime",
                    },
                  })
                : _c("DatePicker", {
                    staticStyle: { "margin-left": "5px" },
                    attrs: {
                      pickerDateTime: _vm.startEndTime,
                      clearable: false,
                      hasPickerOptions: false,
                      startDate: "startDate",
                      endDate: "endDate",
                      width: "240px",
                    },
                    on: {
                      pickerDateChange: function ($event) {
                        return _vm.getPickersDate("startEndTime", $event)
                      },
                    },
                  }),
            ],
            1
          ),
          _c("div", [
            _c("span", [_vm._v("保单来源：")]),
            _c(
              "div",
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      size: "mini",
                      placeholder: "请选择保单来源",
                      multiple: "",
                      "collapse-tags": "",
                      clearable: false,
                    },
                    on: { change: _vm.PolicySourceChange },
                    model: {
                      value: _vm.searchForm.sourceList,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchForm, "sourceList", $$v)
                      },
                      expression: "searchForm.sourceList",
                    },
                  },
                  _vm._l(_vm.PolicySourceList, function (item, index) {
                    return _c("el-option", {
                      key: index,
                      attrs: { label: item.itemName, value: item.itemCode },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            [
              _c("span", [_vm._v("险种：")]),
              _c(
                "el-select",
                {
                  attrs: {
                    size: "mini",
                    multiple: "",
                    "collapse-tags": "",
                    placeholder: "请选择险种",
                    clearable: false,
                  },
                  on: { change: _vm.riskTypeC },
                  model: {
                    value: _vm.searchForm.riskTypeList,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchForm, "riskTypeList", $$v)
                    },
                    expression: "searchForm.riskTypeList",
                  },
                },
                [
                  _c("el-option", {
                    attrs: { label: "驾意险", value: "ACCIDENT" },
                  }),
                  _c("el-option", { attrs: { label: "商业险", value: "BS" } }),
                  _c("el-option", { attrs: { label: "交强险", value: "BZ" } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("span", [_vm._v("车辆标识：")]),
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: {
                    filterable: "",
                    multiple: "",
                    "collapse-tags": "",
                    placeholder: "请选择车辆标识",
                    clearable: "",
                  },
                  on: { change: _vm.vehicleStampChange },
                  model: {
                    value: _vm.vehicleStampList,
                    callback: function ($$v) {
                      _vm.vehicleStampList = $$v
                    },
                    expression: "vehicleStampList",
                  },
                },
                [
                  _vm._l(_vm.dictSource["CAR_STATUS"], function (item, index) {
                    return _c("el-option", {
                      key: index,
                      attrs: { label: item.dictName, value: item.dictCode },
                    })
                  }),
                  _c("el-option", { attrs: { label: "无", value: null } }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "search-btn" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    disabled:
                      _vm.searchForm.sourceList.length === 0 ||
                      _vm.searchForm.riskTypeList.length === 0,
                    type: "primary",
                    icon: "el-icon-search",
                  },
                  on: { click: _vm.searchData },
                },
                [_vm._v("统计")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          style: {
            "overflow-y": "auto",
            padding: "10px",
            height: _vm.containerH,
            "overflow-y": "auto",
          },
        },
        [
          _c(
            "el-card",
            { staticClass: "box-card", staticStyle: { "margin-top": "5px" } },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    "font-size": "18px",
                    margin: "20px 0 10px 25px",
                  },
                },
                [
                  _c("span", {
                    staticStyle: {
                      "margin-right": "2px",
                      "background-color": "#1890ff",
                    },
                    domProps: { innerHTML: _vm._s("&nbsp;") },
                  }),
                  _vm._v(" 业务统计 "),
                ]
              ),
              _c(
                "div",
                { staticStyle: { "text-align": "center", width: "100%" } },
                [
                  _c(
                    "div",
                    { staticStyle: { display: "flex" } },
                    _vm._l(_vm.cardList, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "AllTotal",
                          staticStyle: {
                            "text-align": "center",
                            width: "268px",
                            height: "140px",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "AllTotal_title" },
                            [
                              _c("svg-icon", {
                                style: {
                                  "font-size":
                                    item.svgIcon === "tj_quote"
                                      ? "44px"
                                      : item.svgIcon === "tj_premium"
                                      ? "34px"
                                      : "30px",
                                  "margin-right":
                                    item.svgIcon === "tj_quote"
                                      ? "0px"
                                      : item.svgIcon === "tj_premium"
                                      ? "4px"
                                      : "6px",
                                },
                                attrs: { "icon-class": item.svgIcon },
                              }),
                              _vm._v(" " + _vm._s(item.title) + " "),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "font-size": "24px",
                                "font-weight": "700",
                                "line-height": "30px",
                                padding: "20px 0",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  item.value ? item.value.toLocaleString() : 0
                                )
                              ),
                            ]
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              ),
              _c("InsStatisticsBarChart", {
                attrs: {
                  toDayQuantity: _vm.toDayQuantity,
                  toDayPremium: _vm.toDayPremium,
                  width: "100%",
                  height: "500px",
                  chartData: _vm.insStatisticsBar,
                },
              }),
            ],
            1
          ),
          _c(
            "el-card",
            { staticClass: "box-card", staticStyle: { "margin-top": "10px" } },
            [
              _c(
                "div",
                { staticStyle: { margin: "20px" } },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 10 } },
                    _vm._l(_vm.insPieDataList, function (item, index) {
                      return _c("el-col", { key: index, attrs: { span: 12 } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                            },
                          },
                          [
                            _c("svg-icon", {
                              staticStyle: { color: "blue", margin: "0 10px" },
                              attrs: { "icon-class": "tongji" },
                            }),
                            _c(
                              "div",
                              { staticStyle: { "font-size": "18px" } },
                              [_vm._v(_vm._s(item.title))]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "text-align": "center",
                              margin: "60px 0",
                            },
                          },
                          [
                            _c("InsStatisticsPieChart", {
                              attrs: {
                                width: "100%",
                                height: "200px",
                                chartData: item.chartData,
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    }),
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "padding-top": "20px",
                        "font-size": "18px",
                      },
                    },
                    [
                      _c("span", {
                        staticStyle: {
                          "margin-right": "2px",
                          "background-color": "#1890ff",
                        },
                        domProps: { innerHTML: _vm._s("&nbsp;") },
                      }),
                      _vm._v(" 各保险公司出单业务统计 "),
                    ]
                  ),
                  _c(
                    "div",
                    { staticStyle: { "padding-top": "20px" } },
                    [
                      _c(
                        "el-table",
                        {
                          ref: "multipleTable",
                          staticStyle: { width: "100%" },
                          attrs: {
                            stripe: "",
                            size: "mini",
                            data: _vm.tableData,
                            "header-cell-style": {
                              background: "#fafafa",
                              color: "black",
                              "font-size": "16px",
                              "font-weight": "600",
                            },
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: { prop: "insCorpName", label: "保险公司" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "font-size": "15px",
                                          display: "flex",
                                          "align-items": "center",
                                        },
                                      },
                                      [
                                        _c("img", {
                                          staticStyle: {
                                            "margin-right": "5px",
                                            width: "16px",
                                          },
                                          attrs: { src: scope.row.icon },
                                        }),
                                        _vm._v(
                                          _vm._s(scope.row.insCorpName) + " "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "出单总量",
                              align: "center",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "span",
                                      { staticStyle: { "font-size": "14px" } },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              scope.row.quantity
                                                ? scope.row.quantity.toLocaleString()
                                                : "0.00"
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "出单占比",
                              align: "center",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "div",
                                      [
                                        _c("pie-doughnut", {
                                          attrs: {
                                            fillColor: "#7C90B6",
                                            "chart-data":
                                              "" +
                                              ("" +
                                                (
                                                  scope.row.quantityProportion *
                                                  100
                                                ).toFixed(2)),
                                            height: "50px",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "出单保费",
                              align: "center",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "span",
                                      { staticStyle: { "font-size": "14px" } },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              scope.row.premium
                                                ? scope.row.premium.toLocaleString()
                                                : "0.00"
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "出单保费占比",
                              align: "center",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "div",
                                      [
                                        _c("pie-doughnut", {
                                          attrs: {
                                            fillColor: "#7C90B6",
                                            "chart-data":
                                              "" +
                                              ("" +
                                                (
                                                  scope.row.premiumProportion *
                                                  100
                                                ).toFixed(2)),
                                            height: "50px",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }