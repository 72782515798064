var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "app-m-cls" }, [
      _c(
        "div",
        { staticClass: "table-cls normal-btn-cls " },
        [
          _c(
            "el-card",
            { staticClass: "box-card", attrs: { shadow: "never" } },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _c("div", { staticClass: "search-box" }, [
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v("机构：")]),
                      _c("OemSelect", {
                        staticStyle: {
                          "max-width": "150px",
                          display: "inline-block",
                        },
                        attrs: { searchForm: _vm.orgData },
                        on: { nodeClick: _vm.nodeClick },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v("统计日期：")]),
                      _c("DatePicker", {
                        attrs: {
                          noPickerOptions: "",
                          pickerDateTime: _vm.createdDateTime,
                          startDate: "startTime",
                          endDate: "endTime",
                        },
                        on: {
                          pickerDateChange: function ($event) {
                            return _vm.getPickersDate("createdDateTime", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "primary" },
                          on: { click: _vm.loadData },
                        },
                        [_vm._v("重新统计")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticStyle: { position: "relative" } },
                [
                  _c(
                    "el-tabs",
                    {
                      on: { "tab-click": _vm.handleTabClick },
                      model: {
                        value: _vm.activeName,
                        callback: function ($$v) {
                          _vm.activeName = $$v
                        },
                        expression: "activeName",
                      },
                    },
                    [
                      _c("el-tab-pane", {
                        attrs: { label: "按机构统计", name: "Organization" },
                      }),
                      _c("el-tab-pane", {
                        attrs: { label: "按业务员统计", name: "Employee" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  ref: "multipleTable",
                  staticStyle: { width: "100%", height: "100%" },
                  attrs: {
                    size: "mini",
                    border: "",
                    data: _vm.tableData,
                    height: _vm.tableHeightGJTJ,
                    "header-cell-style": { background: "#F7F7F7" },
                    "highlight-current-row": "",
                  },
                },
                [
                  _vm.activeName == "Employee"
                    ? _c("el-table-column", {
                        attrs: {
                          prop: "ownerByName",
                          label: "业务员",
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                        },
                      })
                    : _vm._e(),
                  _vm.activeName == "Organization"
                    ? _c("el-table-column", {
                        attrs: {
                          prop: "orgName",
                          label: "机构",
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                        },
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    attrs: {
                      prop: "followUpTotal",
                      label: "跟进客户数",
                      "min-width": "100",
                      align: "right",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "successTotal",
                      label: "成交客户数",
                      "min-width": "100",
                      align: "right",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "failureTotal",
                      label: "失败客户数",
                      "min-width": "100",
                      align: "right",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "invalidTotal",
                      label: "无效客户数",
                      "min-width": "100",
                      align: "right",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "successRate",
                      label: "成功率",
                      "min-width": "100",
                      align: "right",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.successRate
                                    ? scope.row.successRate + "%"
                                    : scope.row.successRate
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "failureRate",
                      label: "失败率",
                      "min-width": "120",
                      align: "right",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.failureRate
                                    ? scope.row.failureRate + "%"
                                    : scope.row.failureRate
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c(
                    "el-table-column",
                    { attrs: { label: "跟进中", align: "center" } },
                    _vm._l(_vm.trackingDetailsList, function (item, index) {
                      return _c("el-table-column", {
                        key: index,
                        attrs: {
                          label: item.itemName,
                          "min-width": "150",
                          align: "right",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.getQuantity(
                                          scope.row.trackingDetailsList,
                                          item
                                        ).quantity
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        label: "战败",
                        "min-width": "120",
                        align: "center",
                        "show-overflow-tooltip": "",
                      },
                    },
                    _vm._l(_vm.trackingDefeatList, function (item, index) {
                      return _c("el-table-column", {
                        key: index,
                        attrs: {
                          label: item.itemName,
                          "min-width": "150",
                          align: "right",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.getQuantity(
                                          scope.row.trackingDefeatList,
                                          item
                                        ).quantity
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }