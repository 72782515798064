/**
 * 存储服务上传、下载接口
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 上传文件
   */
  uploadFile: function uploadFile(file) {
    return request({
      method: 'post',
      // url: getUrl(`/storage/upload`, 7),
      url: getUrl("/storage/upload/result", 7),
      data: file,
      headers: {
        'content-type': 'multipart/form-data'
      }
    });
  },
  /**
   * 下载图片
   * @param {*} objStorageRecordId
   */
  downloadFile: function downloadFile(objStorageRecordId) {
    return request({
      method: 'get',
      url: getUrl("/storage/".concat(objStorageRecordId), 7),
      responseType: 'blob'
    });
  },
  /**
   * 下载Zip
   * @param {*} objStorageRecordId
   */
  downloadZipFile: function downloadZipFile(ids) {
    return request({
      method: 'get',
      url: getUrl("/storage/zip/?objStorageRecordId=".concat(ids), 7),
      responseType: 'blob'
    });
  },
  /**
   * 下载模板文件
   */
  downFileTemplate: function downFileTemplate(downName) {
    return request({
      method: 'get',
      url: getUrl("/fileTemplate/getByCode/".concat(downName), 3)
      // responseType: 'blob'
    });
  }
};

export default objApi;