import request from '@/utils/request';
import getUrl from '@/utils/get-url';

/**
 * 保险方案配置
 * 
*/
var objApi = {
  /**
   * 获取保险方案配置内容
   * @param {*} data 内容 => 包含pageNum ,pageSize ,name
   */
  insuranceGetData: function insuranceGetData(data) {
    return request.get(getUrl("/riskkind/page/page", 1), {
      params: data
    });
  },
  /**
   * 查看详情
   * @param {*} id 用户id
   */
  insuranceViewDet: function insuranceViewDet(id) {
    return request.get(getUrl("/riskkind/page/".concat(id), 1));
  },
  /**
  * 保险方案配置添加
  * @param  {*} data 配置内容
  */
  insuranceAdd: function insuranceAdd(data) {
    return request({
      method: 'post',
      url: getUrl("/riskkind/page/create", 1),
      data: data
    });
  },
  /**
   * 保险方案配置删除
   * @param {*} data 用户 id => 数组形式
   */
  insuranceDelete: function insuranceDelete(data) {
    return request({
      method: 'delete',
      url: getUrl("/riskkind/page/delete", 1),
      data: data
    });
  },
  /**
   * 保险方案配置修改更新
   * @param {*} data 内容
   */
  insuranceUpdate: function insuranceUpdate(data) {
    return request({
      method: 'put',
      url: getUrl("/riskkind/page/update", 1),
      data: data
    });
  }
};
export default objApi;