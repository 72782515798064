import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import request from '@/api/message';
import OemSelect from '@/components/OemSelect';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    currentType: {
      type: String,
      default: ''
    },
    currentId: {
      type: String,
      default: ''
    },
    rangeList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    classifyList: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      formLoading: false,
      form: {
        messageRange: 'ALL',
        messageTargets: []
      },
      orgForm: {
        salesOrgCode: '',
        salesOrgName: '',
        salesOrgType: ''
      },
      rules: {
        messageTitle: [{
          required: true,
          message: '请输入标题',
          trigger: 'blur'
        }],
        orgCode: [{
          required: true,
          message: '请选择指定机构',
          trigger: ["blur", "change"]
        }],
        messageContent: [{
          required: true,
          message: '请输入内容',
          trigger: 'change'
        }]
      },
      salesOemName: ''
    };
  },
  components: {
    OemSelect: OemSelect
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
        this.$refs.detail.resetFields();
      }
      ;
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    messageRidioChange: function messageRidioChange(val) {
      this.form.messageTargets = [];
      this.salesOemName = '';
      this.orgForm = {
        salesOrgCode: '',
        salesOrgName: '',
        salesOrgType: ''
      };
    },
    // 初始化数据
    initData: function initData() {
      var _this = this;
      this.form = {
        messageRange: 'ALL'
      };
      if (this.currentType === 'viewDetail') {
        this.$refs.detail.resetFields();
        request.viewMessageOnsite(this.currentId).then(function (res) {
          if (res.code === 200 && res.data) {
            _this.form = res.data;
            if (res.data.messageTargets.length > 1) {
              _this.salesOemName = res.data.messageTargets[0].name + '等' + res.data.messageTargets.length + '家';
            } else {
              _this.salesOemName = res.data.messageTargetNames;
            }
            _this.$forceUpdate();
          }
          ;
        });
      } else {
        this.salesOemName = '';
      }
    },
    nodeClick: function nodeClick(val) {
      var _this2 = this;
      //切换机构
      this.form.messageTargets = [];
      var postData = {};
      if (val.salesOrgCode.length && val.salesOrgName.length) {
        val.salesOrgCode.forEach(function (item, index) {
          postData = {
            id: item,
            name: val.salesOrgName[index]
          };
          _this2.form.messageTargets.push(postData);
        });
      }
    },
    // 保存数据
    save: function save() {
      var _this3 = this;
      this.$refs.detail.validate(function (valid) {
        if (valid) {
          _this3.formLoading = true;
          request.addMessageOnsite(_this3.form).then(function (res) {
            _this3.formLoading = false;
            if (res.code === 200) {
              _this3.$message.success(res.msg);
              _this3.closePage();
            }
            ;
          }).catch(function (err) {
            _this3.formLoading = false;
          });
        }
        ;
      });
    },
    //关闭弹窗
    closePage: function closePage() {
      this.$emit('closePage');
    }
  }
};