import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import _defineProperty from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/defineProperty.js";
var _name$components$data;
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import storageRequest from "@/api/storage";
import CustomerTracks from './detail/customerTracks.vue';
import ProposalHistory from './detail/proposalHistory.vue';
import InsurancePolicy from './detail/insurancePolicy.vue';
import VehicleInfo from './detail/vehicleInfo.vue';
import Attachments from './detail/attachments';
// import NonVehicle from './detail/nonVehicleList';
import DistributeDialog from './detail/distributionDialog';
import EditDialog from './detail/editDialog';
import InsuranceDateDialog from './detail/insuranceDateDialog';
import InInsuranceDialog from './dialog/insuranceDialog';
import customersRequest from '../../api/customers';
import FollowUpDialog from '@/views/leadManagement/dialog/followUpDialog';
import leadManagementApi from '@/api/leadManagement';
import OperationRecords from './detail/operationRecords';
import CallDialog from './dialog/callDialog';
import GiftInformation from './detail/giftInformation';
import MaintenanceInformation from './detail/maintenanceInformation';
import LoanInformation from './detail/loanInformation';
import ServicePackList from './detail/servicePackList';
import insurancepolicyRequest from '../../api/insurancepolicy';
export default (_name$components$data = {
  name: 'CustomersDetail',
  components: {
    VehicleInfo: VehicleInfo,
    InsurancePolicy: InsurancePolicy,
    ProposalHistory: ProposalHistory,
    CustomerTracks: CustomerTracks,
    Attachments: Attachments,
    DistributeDialog: DistributeDialog,
    EditDialog: EditDialog,
    InsuranceDateDialog: InsuranceDateDialog,
    InInsuranceDialog: InInsuranceDialog,
    FollowUpDialog: FollowUpDialog,
    OperationRecords: OperationRecords,
    CallDialog: CallDialog,
    GiftInformation: GiftInformation,
    MaintenanceInformation: MaintenanceInformation,
    LoanInformation: LoanInformation,
    ServicePackList: ServicePackList
    // NonVehicle
  },
  data: function data() {
    var _ref;
    return _ref = {
      appRoot: process.env.VUE_APP_ROOT_PATH.substr(1, process.env.VUE_APP_ROOT_PATH.length),
      DialogData: {},
      customerId: "",
      ShowInsuranceDialog: false,
      fileList: [],
      viewDisabled: false,
      IsDeprotection: false,
      IsDeprotectionDay: '',
      IsBSDeprotection: false,
      IsBSDeprotectionDay: '',
      isToQuote: false,
      activeName: 'VEHICLEINFO',
      showDistributeDialog: false,
      showEditDialog: false,
      showInsuranceDateDialog: false,
      showAddTracksDialog: false,
      customersInfo: {},
      customersIds: [],
      currentType: false,
      followHistory: [],
      customerOperationLog: []
    }, _defineProperty(_ref, "customerId", ""), _defineProperty(_ref, "IsDeprotection", false), _defineProperty(_ref, "IsDeprotectionDay", ''), _defineProperty(_ref, "IsBSDeprotection", false), _defineProperty(_ref, "IsBSDeprotectionDay", ''), _defineProperty(_ref, "viewDisabled", false), _defineProperty(_ref, "fileList", []), _defineProperty(_ref, "DialogData", {}), _defineProperty(_ref, "ShowInsuranceDialog", false), _defineProperty(_ref, "callDialog", false), _defineProperty(_ref, "callLoading", false), _defineProperty(_ref, "currentObj", {}), _defineProperty(_ref, "vouchersTableData", []), _defineProperty(_ref, "renewalLoading", false), _defineProperty(_ref, "insuranceLoading", false), _ref;
  },
  watch: {
    // 监听路由是否变化）
    '$route': function $route(to, from) {
      this.initView();
    }
  },
  computed: {
    // 计算卡片高度
    scrollerHeight: function scrollerHeight() {
      return document.body.clientHeight - 320 + 'px';
    },
    address: function address() {
      var _address = this.customersInfo.ownerAddress || '';
      if (!_address) {
        _address = "".concat(this.customersInfo.ownerProvinceName || '', " ").concat(this.customersInfo.ownerCityName || '', " ").concat(this.customersInfo.ownerDistrictName || '');
      }
      return _address;
    }
  }
}, _defineProperty(_name$components$data, "watch", {
  // 监听路由是否变化）
  '$route': function $route(to, from) {
    this.initView();
  }
}), _defineProperty(_name$components$data, "mounted", function mounted() {
  this.initView();
}), _defineProperty(_name$components$data, "methods", {
  initView: function initView() {
    this.currentType = this.$route.query.currentType ? true : false;
    this.customerId = this.$route.query.customerId;
    this.getOperationRecords();
    this.loadData();
  },
  // 客户详情卡券信息
  getCustomerCardVoucher: function getCustomerCardVoucher() {
    var _this = this;
    var data = {
      vin: this.customersInfo.vin,
      orgCode: this.customersInfo.ownerOrgCode
    };
    insurancepolicyRequest.getCustomerCardVoucher(data).then(function (res) {
      if (res.code == 200) {
        _this.vouchersTableData = res.data;
      }
    }).catch(function (err) {});
  },
  openCall: function openCall() {
    var _this2 = this;
    if (this.callLoading) return;
    this.callLoading = true;
    customersRequest.getCallType(this.customerId).then(function (res) {
      _this2.callLoading = false;
      if (res.code === 200 && res.data) {
        _this2.callDialog = res.data.tips;
        _this2.currentObj = _objectSpread(_objectSpread({}, res.data), {}, {
          customerId: _this2.customerId
        });
        if (!_this2.callDialog) {
          _this2.callCustomer(_this2.currentObj);
        }
        ;
      }
      ;
    }).catch(function (err) {
      _this2.callLoading = false;
    });
  },
  callCustomer: function callCustomer(obj) {
    var _this3 = this;
    if (!obj.caller) {
      this.$message.warning("\u547C\u51FA\u4E3B\u53EB\u53F7\u7801\u4E0D\u80FD\u4E3A\u7A7A\uFF0C\u8BF7\u8054\u7CFB\u7BA1\u7406\u4EBA\u5458\u7EF4\u62A4");
      return;
    }
    ;
    this.callLoading = true;
    customersRequest.webapi(obj).then(function (res) {
      _this3.callLoading = false;
      if (res.code === 200) {
        _this3.$message.success(res.msg);
      }
      ;
    }).catch(function (err) {
      _this3.callLoading = false;
    });
  },
  getOperationRecords: function getOperationRecords() {
    var _this4 = this;
    customersRequest.getCustomerOperationLog(this.customerId).then(function (res) {
      if (res.code === 200) {
        _this4.customerOperationLog = res.data;
      }
    });
  },
  checkInsurance: function checkInsurance() {
    var _this5 = this;
    var userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {};
    var data = {
      bizType: 'BROKER',
      orgCode: userInfo && userInfo.data.organization.orgCode ? userInfo.data.organization.orgCode : '',
      plateNo: this.customersInfo && this.customersInfo.plateNo ? this.customersInfo.plateNo : '',
      vin: this.customersInfo && this.customersInfo.vin ? this.customersInfo.vin : ''
    };
    this.insuranceLoading = true;
    customersRequest.queryCiitcInformation(data).then(function (res) {
      _this5.insuranceLoading = false;
      if (res.code == 200) {
        if (res.data) {
          _this5.ShowInsuranceDialog = true;
          _this5.DialogData = res.data;
          _this5.customerId = _this5.customersInfo.customerId.toString();
        } else {
          _this5.$message.warning('暂未检查到最新在保信息');
        }
      }
    }).catch(function (err) {
      return _this5.insuranceLoading = false;
    });
  },
  InsuranceDateDialog: function InsuranceDateDialog() {
    console.log(this.customersInfo.customerId);
    this.customerId = this.customersInfo.customerId.toString();
    this.showInsuranceDateDialog = true;
  },
  checkRenewal: function checkRenewal() {
    var _this6 = this;
    var customerId = this.customersInfo.customerId;
    this.renewalLoading = true;
    customersRequest.updateCustomerByRenewal(customerId).then(function (res) {
      _this6.renewalLoading = false;
      if (res.code == 200) {
        if (res.data) {
          _this6.$message.success('已获取最新续保信息并更新了客户信息');
          _this6.customersInfo = res.data;
        } else {
          _this6.$message.warning('暂未检查到最新续保信息');
        }
      }
    }).catch(function (err) {
      return _this6.renewalLoading = false;
    });
  },
  // 拨号
  callService: function callService(phone) {
    var self = this;
    console.log('phone=>:', phone);
    var ws = new WebSocket("ws://127.0.0.1:3333/call");
    ws.onopen = function (evt) {
      ws.send(JSON.stringify({
        line: 1,
        number: phone
      }));
    };
    ws.onmessage = function (evt) {
      ws.close();
      var _data = JSON.parse(evt.data);
      console.log('_data=>:', _data);
      if (_data && _data.Status == 500) {
        self.$message({
          message: _data && _data.ErrMsg,
          type: "warning"
        });
      }
    };
    ws.onerror = function () {
      self.$message({
        message: "连接呼叫中心客户端失败，请检查客户端是否正在运行。",
        type: "warning"
      });
    };
    ws.onclose = function (evt) {};
  },
  // 去报价
  toQuote: function toQuote() {
    sessionStorage.removeItem("quoateForm");
    var router_path = this.$route.path + "?customerId=".concat(this.$route.query.customerId, "&plateNo=").concat(this.customersInfo.plateNo, "&vin=").concat(this.customersInfo.vin);
    this.$router.push({
      path: "/proposal/add",
      query: {
        back: router_path,
        plateNo: this.customersInfo.plateNo,
        vin: this.customersInfo.vin
      }
    });
  },
  // 上传文件
  uploadFile: function uploadFile(param) {
    var _this7 = this;
    var formData = new FormData();
    formData.append("file", param.file);
    // return
    storageRequest.uploadFile(formData).then(function (res) {
      if (res.code === 200) {
        _this7.$message.succes;
        var obj = {
          attachmentIds: res.data.fileStorageId,
          name: param.file.name,
          attachmentTypeDisplay: _this7.activeName,
          type: "上传成功"
        };
        _this7.fileList.push(obj);
      }
    });
  },
  // 删除文件
  deleteFile: function deleteFile(row) {
    var uid = row.attachmentIds;
    this.fileList.splice(this.fileList.findIndex(function (item) {
      return item.attachmentIds === uid;
    }), 1);
  },
  // tabs切换
  handleClick: function handleClick(tab, event) {},
  // 加载数据
  loadData: function loadData() {
    var _this8 = this;
    this.ShowInsuranceDialog = false;
    this.callDialog = false;
    this.showDistributeDialog = false;
    this.showEditDialog = false;
    this.showInsuranceDateDialog = false;
    this.showAddTracksDialog = false;
    var customerId = this.$route.query.customerId;
    this.customersIds = [customerId];
    customersRequest.getDetail(customerId).then(function (res) {
      var nowTime = new Date().getTime();
      if (res.data && res.data.bzLastYearEndDate) {
        var bzTime = new Date(res.data.bzLastYearEndDate).getTime();
        if (bzTime > nowTime) {
          // 在保
          var timeNum = parseInt(bzTime) - parseInt(nowTime);
          _this8.IsDeprotectionDay = parseInt(timeNum / (24 * 3600) / 1000);
          _this8.IsDeprotection = false;
        } else {
          // 已脱保
          _this8.IsDeprotection = true;
        }
      }
      if (res.data && res.data.bsLastYearEndDate) {
        var bsTime = new Date(res.data.bsLastYearEndDate).getTime();
        if (bsTime > nowTime) {
          // 在保
          var _timeNum = parseInt(bsTime) - parseInt(nowTime);
          _this8.IsBSDeprotectionDay = parseInt(_timeNum / (24 * 3600) / 1000);
          _this8.IsBSDeprotectio = false;
        } else {
          // 已脱保
          _this8.IsBSDeprotectio = true;
        }
      }
      _this8.customersInfo = res.data;
      // customersRequest.getTrackDetail(this.customersInfo.ownerBy);
      _this8.getCustomerCardVoucher();
      _this8.getOperationRecords();
    });
    leadManagementApi.checkDetails(this.customerId).then(function (res) {
      if (res.code === 200) {
        _this8.followHistory = res.data.followHistory.map(function (item) {
          var contentSupplement = item.contentSupplement.replace(/\n/g, "<br/>");
          return _objectSpread(_objectSpread({}, item), {}, {
            contentSupplement: contentSupplement
          });
        });
      }
    });
  }
}), _name$components$data);