import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import dict from '@/mixins/dict';
import Pagination from '@/components/Pagination';
import NonAutoRequest from '@/api/nonAutoRequest';
import addDialog from './add';
import { commonExport } from '@/utils/common';
export default {
  name: 'serviceType',
  components: {
    Pagination: Pagination,
    addDialog: addDialog
  },
  mixins: [initHeight, dict],
  computed: {},
  data: function data() {
    return {
      orgForm: {
        salesOrgCode: "",
        salesOrgName: ""
      },
      dialogType: '',
      dialogId: '',
      showAddDialog: false,
      showViewDialog: false,
      activeName: "all",
      drawer: false,
      searchForm: {},
      form: {},
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      defaultProps: {
        children: 'children',
        label: 'fullName'
      },
      tableData: [],
      multipleSelection: [],
      opType: '',
      startEndTime: null,
      loading: false,
      orgData: [],
      highlightCurrent: true,
      cluesId: '',
      tabName: 'all',
      details: {},
      classCode: '',
      timeoutForExport: null,
      timeoutForRefresh: null
    };
  },
  mounted: function mounted() {
    this.loadData();
  },
  methods: {
    // 导出防抖
    exportFn: function exportFn() {
      var _this = this;
      if (this.timeoutForExport) {
        clearTimeout(this.timeoutForExport);
      }
      this.timeoutForExport = setTimeout(function () {
        _this.exportFn1();
      }, 500);
    },
    // 导出
    exportFn1: function exportFn1() {
      var exporParams = {
        total: this.total,
        fileName: '非车服务类型管理列表',
        exportUrl: '/biz-nonauto-service/product/class/search',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    // 树形点击
    nodeClick: function nodeClick(data) {
      this.searchForm.orgCode = data.salesOrgCode;
    },
    // 刷新防抖
    refreshClicked: function refreshClicked() {
      var _this2 = this;
      if (this.timeoutForRefresh) {
        clearTimeout(this.timeoutForRefresh);
      }
      this.timeoutForRefresh = setTimeout(function () {
        _this2.refreshClicked1();
      }, 500);
    },
    refreshClicked1: function refreshClicked1() {
      this.searchData();
    },
    handleTabClick: function handleTabClick(tab, event) {
      //tab切换事件
      this.tabName = tab.name;
      this.listQuery.pageNum = 1;
      this.loadData();
      this.$forceUpdate();
    },
    addClicked: function addClicked(type) {
      this.dialogType = type;
      this.showAddDialog = true;
    },
    editCLicked: function editCLicked(type, item) {
      // console.log(item)
      this.dialogType = type;
      this.classCode = item.classCode;
      this.dialogId = item.classId.toString();
      this.showAddDialog = true;
    },
    del: function del(item) {
      var _this3 = this;
      this.$confirm('是否删除当前数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        NonAutoRequest.getClassDel(item.classId).then(function (res) {
          if (res.code == 200) {
            _this3.$message.success('删除成功');
            _this3.loadData();
          }
        });
      }).catch(function () {});
    },
    detail: function detail(item) {
      this.showViewDialog = true;
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.listQuery.pageNum = 1;
      this.searchForm = {
        orgCode: null
      };
      this.orgForm = {
        salesOrgCode: null,
        salesOrgName: null
      };
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    loadData: function loadData() {
      var _this4 = this;
      //获取列表数据
      // this.loading = true;
      this.drawer = false;
      this.showAddDialog = false;
      this.searchForm.pageSize = this.listQuery.pageSize;
      this.searchForm.pageNum = this.listQuery.pageNum;
      NonAutoRequest.getClassList(this.searchForm).then(function (res) {
        if (res.code == 200) {
          _this4.tableData = res.data.list ? res.data.list : [];
          _this4.total = res.data.page && res.data.page.recordsTotal ? res.data.page.recordsTotal : 0;
        } else {
          _this4.tableData = [];
          _this4.total = 0;
        }
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      //列表行选择事件
      this.multipleSelection = val;
    }
  }
};