//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import Pagination from '@/components/Pagination';
import userCenterApi from '@/api/userCenter';
export default {
  mixins: [initHeight],
  components: {
    Pagination: Pagination
  },
  props: {
    netUserId: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      listLoading: false,
      tableData: []
    };
  },
  watch: {
    netUserId: {
      handler: function handler(newValue, oldValue) {
        this.loadData();
      },
      immediate: true
    }
  },
  methods: {
    // 获取列表数据
    loadData: function loadData() {
      var _this = this;
      this.listLoading = true;
      userCenterApi.getNetUserChannelAccountList(this.netUserId).then(function (res) {
        _this.listLoading = false;
        if (res.code === 200) {
          _this.tableData = res.data || [];
        }
        ;
      }).catch(function (err) {
        _this.listLoading = false;
      });
    }
  }
};