//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import carService from '@/api/carService';
export default {
  // mixins: [dict],
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    opType: {
      type: String,
      default: {}
    },
    insCorpItem: {
      type: Object,
      default: {}
    }
  },
  data: function data() {
    return {
      options: [],
      form: {
        seriesNo: "",
        seriesName: "",
        brandName: "",
        vehicleBrandId: '',
        "sortBy": 0,
        "vehicleBrandSeriesId": 0
      },
      listLoading: false,
      rules: {
        seriesNo: [{
          required: true,
          message: '请输入车系编号',
          trigger: 'blur'
        }],
        brandName: [{
          required: true,
          message: '请选择品牌',
          trigger: 'blur'
        }],
        seriesName: [{
          required: true,
          message: '请输入车系名称',
          trigger: 'blur'
        }],
        vehicleBrandId: [{
          required: true,
          message: '请选择品牌',
          trigger: 'blur'
        }]
      }
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    initData: function initData() {
      var _this = this;
      if (this.opType === "add") {
        this.form = {}, this.options = [];
      } else {
        this.form = this.insCorpItem;
      }
      // 获取获取所有品牌列表
      carService.brandOptions().then(function (res) {
        if (res.code === 200) {
          _this.options = res.data;
        }
      });
    },
    save: function save() {
      var _this2 = this;
      //保存数据
      this.$refs.detail.validate(function (valid) {
        if (valid) {
          var req = _this2.opType === "add" ? carService.carSeriesBrand(_this2.form) : carService.carSeriesEditor(_this2.insCorpItem.vehicleBrandSeriesId, _this2.form);
          req.then(function (res) {
            if (res.code === 200) {
              _this2.$message({
                message: res.msg,
                type: 'success'
              });
              _this2.closePage();
            }
          });
        }
      });
    },
    closePage: function closePage() {
      //关闭弹窗
      this.$emit('closePage');
    },
    loadData: function loadData() {//加载数据
    }
  }
};