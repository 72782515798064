//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import dict from '@/mixins/dict';
import Pagination from '@/components/Pagination';
import DatePicker from '@/components/DatePicker';
import sysSetting from '@/api/sysSetting';
import OemSelect from '@/components/OemSelect';
import { getNowDate, commonExport, getCurrentDate } from '@/utils/common';
export default {
  name: 'SysLog',
  components: {
    Pagination: Pagination,
    OemSelect: OemSelect,
    DatePicker: DatePicker
  },
  mixins: [initHeight, dict],
  computed: {},
  data: function data() {
    return {
      insuranceList: [],
      drawer: true,
      searchForm: {},
      operationDateTime: [getCurrentDate(), getCurrentDate()],
      startEndTime: [],
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      loading: false,
      highlightCurrent: true,
      orgForm: {
        salesOrgCode: '',
        salesOrgName: '',
        salesOrgType: ''
      }
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.loadData();
  },
  methods: {
    breakData: function breakData() {
      this.loadData();
    },
    // 导出
    exportFn: function exportFn() {
      var exporParams = {
        total: this.total,
        fileName: '操作日志',
        exportUrl: '/tp-core-service/operation/log/list',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    // 日期选择事件
    getPickersDate: function getPickersDate(name, val) {
      if (val && val.length > 1) {
        this[name] = val;
      } else {
        this[name] = [];
      }
    },
    //切换机构
    nodeClick: function nodeClick(val) {
      this.searchForm.bizType = val.salesOrgName;
    },
    //重置搜索表单
    resetSearch: function resetSearch() {
      this.searchForm = {};
      this.startEndTime = [];
      this.operationDateTime = [getCurrentDate(), getCurrentDate()];
      this.orgForm = {
        salesOrgCode: '',
        salesOrgName: '全部',
        salesOrgType: ''
      };
    },
    //搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    //获取列表数据
    loadData: function loadData() {
      var _this = this;
      if (this.operationDateTime) {
        this.searchForm.operationTimeStart = this.operationDateTime[0] && this.operationDateTime[0].split(' ')[0] + ' 00:00:00' || null;
        this.searchForm.operationTimeEnd = this.operationDateTime[1] && this.operationDateTime[1].split(' ')[0] + ' 23:59:59' || null;
      }
      this.loading = true;
      this.drawer = false;
      sysSetting.getList(this.listQuery.pageNum, this.listQuery.pageSize, this.searchForm).then(function (res) {
        if (res.code === 200) {
          _this.tableData = res.data.list;
          _this.loading = false;
          _this.total = res.data.page.recordsTotal;
        }
      }).catch(function (err) {
        _this.loading = false;
      });
    }
  }
};