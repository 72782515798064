import _defineProperty from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/defineProperty.js";
var _methods;
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/web.url.to-json.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import storageRequest from '@/api/storage';
import agreementManagement from '@/api/agreementManagement';
import OemSelect from '@/components/OemSelect';
import dict from "@/mixins/dict";
import { getUserOrgInfoDealerOem, getSupplyers, downFile } from "@/utils/common";
export default {
  mixins: [dict],
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    opType: {
      type: String,
      default: {}
    },
    agreementItem: {
      type: Object,
      default: {}
    }
  },
  data: function data() {
    return {
      fileList: [],
      uploadStatus: '',
      agreementType: [{
        label: '协议框架',
        value: 'FRAMEWORK'
      }, {
        label: '子协议',
        value: 'SUB'
      }, {
        label: '补充协议',
        value: 'SUPPLEMENT'
      }],
      searchForm: {
        agreementFileList: [],
        agreementType: '',
        orgCode: '',
        orgName: ''
      },
      orgForm: {
        salesOrgCode: '',
        salesOrgName: '',
        salesOrgType: ''
      },
      insuranceList: [],
      listLoading: false,
      rules: {
        agreementName: [{
          required: true,
          message: '请输入协议名称',
          trigger: 'blur'
        }],
        orgCode: [{
          required: true,
          message: '请选择签约机构',
          trigger: ["blur", "change"]
        }],
        agreementStatus: [{
          required: true,
          message: '请输入协议状态',
          trigger: 'blur'
        }],
        insCorpFullName: [{
          required: true,
          message: '请输入合作机构全称',
          trigger: ['change']
        }],
        insCorpCode: [{
          required: true,
          message: '请选择合作保险公司',
          trigger: ["blur", "change"]
        }],
        insCorpBranchName: [{
          required: true,
          message: '请输入合作机构分公司',
          trigger: 'blur'
        }],
        productType: [{
          required: true,
          message: '请输入代理产品类别',
          trigger: ["blur", "change"]
        }],
        productName: [{
          required: true,
          message: '请选择代理产品名称',
          trigger: 'blur'
        }],
        signedDate: [{
          required: true,
          message: '请输入签订日期',
          trigger: ['change']
        }],
        startDate: [{
          required: true,
          message: '请输入协议开始日期',
          trigger: ['change']
        }],
        endDate: [{
          required: true,
          message: '请输入协议终止日期',
          trigger: ['change']
        }],
        accidentMaxRate: [{
          required: true,
          message: '请输入非车险最高费率',
          trigger: 'blur'
        }],
        bzMaxRate: [{
          required: true,
          message: '请输入交强险最高费率',
          trigger: 'blur'
        }],
        bsMaxRate: [{
          required: true,
          message: '请输入商业险最高费率',
          trigger: 'blur'
        }],
        remark: [{
          required: false,
          message: '请输入备注信息',
          trigger: 'blur'
        }],
        agreementType: [{
          required: true,
          message: '请输入协议类型',
          trigger: ['change']
        }]
      },
      bizType: localStorage.getItem("bizType") || ''
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
    }
  },
  components: {
    OemSelect: OemSelect
  },
  created: function created() {},
  mounted: function mounted() {
    this.getInsuranceData();
    this.initData();
    this.$nextTick(function () {
      this.orgForm.salesOrgName = this.searchForm.orgName;
      this.orgForm.salesOrgCode = this.searchForm.orgCode;
    });
  },
  methods: (_methods = {
    // 切换机构
    nodeClick: function nodeClick(val) {
      this.orgForm.salesOrgCode = val.salesOrgCode;
      this.orgForm.salesOrgName = val.salesOrgName;
    },
    initData: function initData() {
      var _this = this;
      this.$refs.detail.clearValidate();
      this.$refs.detail.resetFields();
      if (this.opType === "add") {
        this.searchForm = {
          orgCode: '',
          orgName: ''
        };
        this.searchForm.agreementFileList = [];
        this.orgForm = {
          salesOrgCode: '',
          salesOrgName: '',
          salesOrgType: ''
        };
        this.$forceUpdate();
      } else {
        this.listLoading = true;
        agreementManagement.seachData(this.agreementItem.agreementId).then(function (res) {
          _this.listLoading = false;
          if (res.code === 200) {
            _this.searchForm = res.data;
            _this.orgForm.salesOrgCode = res.data.orgCode;
            _this.orgForm.salesOrgName = res.data.orgName;
            _this.$forceUpdate();
          }
        }).catch(function (error) {
          _this.listLoading = false;
        });
      }
      if (this.bizType == "DEALER") {
        var orgObj = getUserOrgInfoDealerOem(this.bizType);
        this.orgForm.salesOrgCode = orgObj.salesOrgCode;
        this.orgForm.salesOrgName = orgObj.salesOrgName;
        this.searchForm.orgCode = orgObj.salesOrgCode;
        this.searchForm.orgName = orgObj.salesOrgName;
      }
    },
    // 文件删除
    handleDelete: function handleDelete(index, row) {
      this.searchForm.agreementFileList.splice(index, 1);
    },
    agreementChange: function agreementChange(val) {
      this.searchForm.agreementType = val;
    },
    // 上传文件
    uploadFile: function uploadFile(param) {
      var _this2 = this;
      var nowDate = new Date(+new Date() + 8 * 3600 * 1000).toJSON().substr(0, 19).replace("T", " ");
      var formData = new FormData();
      formData.append('file', param.file);
      storageRequest.uploadFile(formData).then(function (res) {
        if (res.code === 200) {
          _this2.$message.success(res.msg);
          _this2.uploadStatus = 'SUCCESS';
          var obj = {
            createdTime: nowDate,
            agreementType: _this2.searchForm.agreementType,
            agreementId: res.data,
            fileStorageId: res.data.fileStorageId,
            fileName: param.file.name,
            uploadStatus: _this2.uploadStatus
          };
          _this2.searchForm.agreementFileList.push(obj);
        } else {
          _this2.uploadStatus = 'UFAILURE';
        }
      });
    }
  }, _defineProperty(_methods, "nodeClick", function nodeClick(val) {
    this.searchForm.orgCode = val.salesOrgCode;
    this.searchForm.orgName = val.salesOrgName;
  }), _defineProperty(_methods, "handle", function handle(row) {
    if (row.fileStorageId) {
      downFile(row.fileUrl, row.fileName);
    }
  }), _defineProperty(_methods, "getInsuranceData", function getInsuranceData() {
    this.insuranceList = getSupplyers();
  }), _defineProperty(_methods, "insCorpChange", function insCorpChange(val) {
    if (!val) return;
    var obj = {};
    obj = _.find(this.insuranceList, function (item) {
      return item.code == val;
    });
    this.searchForm.insCorpName = obj.shortName;
    this.searchForm.insCorpFullName = obj.fullName;
  }), _defineProperty(_methods, "save", function save() {
    var _this3 = this;
    this.$refs.detail.validate(function (valid) {
      if (valid) {
        if (!_.isEmpty(_this3.searchForm.agreementFileList)) {
          var isAgreementType = _this3.searchForm.agreementFileList.some(function (val) {
            return val.agreementType === '' || val.agreementType === undefined;
          });
          if (isAgreementType) {
            _this3.$message.warning('请选择协议类型');
            return;
          }
        }
        _this3.listLoading = true;
        var req = _this3.opType === "add" ? agreementManagement.addData(_this3.searchForm) : agreementManagement.updateData(_this3.agreementItem.agreementId, _this3.searchForm);
        req.then(function (res) {
          _this3.listLoading = false;
          if (res.code === 200) {
            _this3.$message.success(res.msg);
            _this3.closePage();
          }
        }).catch(function (error) {
          _this3.listLoading = false;
        });
      }
    });
  }), _defineProperty(_methods, "closePage", function closePage() {
    this.$emit('closePage');
  }), _methods)
};