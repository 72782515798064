var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.formLoading,
          expression: "formLoading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: {},
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "500px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "detail",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-width": "125px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "车型ID:", prop: "vehicleId" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入车型ID",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.vehicleId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "vehicleId", $$v)
                                  },
                                  expression: "form.vehicleId",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "车型编码:",
                                prop: "makerModelCode",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入车型编码",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.makerModelCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "makerModelCode", $$v)
                                  },
                                  expression: "form.makerModelCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "车型名称:",
                                prop: "vehicleName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入车型名称",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.vehicleName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "vehicleName", $$v)
                                  },
                                  expression: "form.vehicleName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "品牌名称:", prop: "brandName" },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入品牌名称",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.brandName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "brandName", $$v)
                                  },
                                  expression: "form.brandName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "车系名称:", prop: "familyName" },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入车系名称",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.familyName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "familyName", $$v)
                                  },
                                  expression: "form.familyName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "新车购置价:",
                                prop: "purchasePrice",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入新车购置价",
                                  clearable: "",
                                },
                                on: {
                                  input: function ($event) {
                                    _vm.form.purchasePrice = _vm.onInput(
                                      _vm.form.purchasePrice,
                                      0,
                                      999999999,
                                      2
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.form.purchasePrice,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "purchasePrice", $$v)
                                  },
                                  expression: "form.purchasePrice",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "年款:", prop: "yearPattern" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入年款",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.yearPattern,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "yearPattern", $$v)
                                  },
                                  expression: "form.yearPattern",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "排量:", prop: "displacement" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入排量",
                                  clearable: "",
                                },
                                on: {
                                  input: function ($event) {
                                    _vm.form.displacement = _vm.onInput(
                                      _vm.form.displacement,
                                      0,
                                      1000,
                                      3
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.form.displacement,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "displacement", $$v)
                                  },
                                  expression: "form.displacement",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "排量描述:", prop: "engineDesc" },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入排量描述",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.engineDesc,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "engineDesc", $$v)
                                  },
                                  expression: "form.engineDesc",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "变速器类型:",
                                prop: "gearboxType",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入变速器类型",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.gearboxType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "gearboxType", $$v)
                                  },
                                  expression: "form.gearboxType",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "额定座位数:", prop: "seat" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入额定座位数",
                                  clearable: "",
                                },
                                on: {
                                  input: function ($event) {
                                    _vm.form.seat = _vm.onInput(
                                      _vm.form.seat,
                                      0,
                                      100,
                                      0
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.form.seat,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "seat", $$v)
                                  },
                                  expression: "form.seat",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "额定座位数(最大):",
                                prop: "seatMax",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入额定座位数(最大)",
                                  clearable: "",
                                },
                                on: {
                                  input: function ($event) {
                                    _vm.form.seatMax = _vm.onInput(
                                      _vm.form.seatMax,
                                      0,
                                      100,
                                      0
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.form.seatMax,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "seatMax", $$v)
                                  },
                                  expression: "form.seatMax",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "额定座位数(最小):",
                                prop: "seatMin",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入额定座位数(最小)",
                                  clearable: "",
                                },
                                on: {
                                  input: function ($event) {
                                    _vm.form.seatMin = _vm.onInput(
                                      _vm.form.seatMin,
                                      0,
                                      100,
                                      0
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.form.seatMin,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "seatMin", $$v)
                                  },
                                  expression: "form.seatMin",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "配置名称:", prop: "configName" },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入配置名称",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.configName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "configName", $$v)
                                  },
                                  expression: "form.configName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "系别名称:",
                                prop: "vehicleImport",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入系别名称",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.vehicleImport,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "vehicleImport", $$v)
                                  },
                                  expression: "form.vehicleImport",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "燃料种类:", prop: "powerType" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { placeholder: "请选择燃料种类" },
                                  model: {
                                    value: _vm.form.powerType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "powerType", $$v)
                                    },
                                    expression: "form.powerType",
                                  },
                                },
                                _vm._l(
                                  _vm.powerTypeList,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.itemName,
                                        value: item.itemName,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "车型分类:",
                                prop: "vehicleClass",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { placeholder: "请选择车型分类" },
                                  model: {
                                    value: _vm.form.vehicleClass,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "vehicleClass", $$v)
                                    },
                                    expression: "form.vehicleClass",
                                  },
                                },
                                _vm._l(
                                  _vm.vehicleClassList,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.itemName,
                                        value: item.itemName,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "上市时间:", prop: "marketDate" },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入上市时间",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.marketDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "marketDate", $$v)
                                  },
                                  expression: "form.marketDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "厂商指导价:",
                                prop: "guidePrice",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入厂商指导价",
                                  clearable: "",
                                },
                                on: {
                                  input: function ($event) {
                                    _vm.form.guidePrice = _vm.onInput(
                                      _vm.form.guidePrice,
                                      0,
                                      999999999,
                                      2
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.form.guidePrice,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "guidePrice", $$v)
                                  },
                                  expression: "form.guidePrice",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "装备质量(最大):",
                                prop: "fullWeightMax",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入装备质量(最大)",
                                  clearable: "",
                                },
                                on: {
                                  input: function ($event) {
                                    _vm.form.fullWeightMax = _vm.onInput(
                                      _vm.form.fullWeightMax,
                                      0,
                                      1000,
                                      3
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.form.fullWeightMax,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "fullWeightMax", $$v)
                                  },
                                  expression: "form.fullWeightMax",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "装备质量(最小):",
                                prop: "fullWeightMin",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入装备质量(最小)",
                                  clearable: "",
                                },
                                on: {
                                  input: function ($event) {
                                    _vm.form.fullWeightMin = _vm.onInput(
                                      _vm.form.fullWeightMin,
                                      0,
                                      1000,
                                      3
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.form.fullWeightMin,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "fullWeightMin", $$v)
                                  },
                                  expression: "form.fullWeightMin",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-check",
                type: "primary",
                plain: "",
                size: "mini",
              },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: { click: _vm.closePage },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }