import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import OemSelect from '@/components/OemSelect';
import request from '../../../api/finance';
import storageRequest from '@/api/storage';
import { getSupplyers, getUserOrgInfoDealerOem, downFile } from '@/utils/common';
export default {
  // mixins: [dict],
  props: {
    refresh: {
      type: Boolean,
      default: true
    }
  },
  components: {
    OemSelect: OemSelect
  },
  data: function data() {
    return {
      orgData: {
        salesOrgName: "",
        salesOrgCode: "",
        organizationId: ''
      },
      form: {
        orgCode: '',
        orgName: '',
        insCorpCode: "",
        startTime: "",
        endTime: "",
        // isTaxInclusive: false,
        storageId: '',
        fileName: '',
        startEndTime: [],
        bizType: this.bizType
      },
      fileList: [],
      supplyers: [],
      formRules: {
        orgCode: {
          required: true,
          message: '请选择账单机构',
          trigger: ['blur', 'change']
        },
        insCorpCode: {
          required: true,
          message: '请选择保险公司',
          trigger: 'change'
        },
        startEndTime: {
          required: true,
          message: '请选择账单周期',
          trigger: 'change'
        }
      },
      disabledLessThan: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      bizType: localStorage.getItem("bizType") || '',
      canSave: false,
      importLoading: false
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
        this.$refs.detail.resetFields();
      }
    }
  },
  mounted: function mounted() {
    //保司
    this.supplyers = getSupplyers();
    this.initData();
  },
  methods: {
    initData: function initData() {
      this.form = {
        orgCode: '',
        orgName: '',
        insCorpCode: "",
        startTime: "",
        endTime: "",
        // isTaxInclusive: false,
        storageId: '',
        fileName: '',
        startEndTime: [],
        bizType: this.bizType
      };
      this.fileList = [];
      this.orgData = {
        salesOrgName: '',
        salesOrgCode: '',
        organizationId: ""
      };
      if (this.bizType == 'DEALER') {
        var orgObj = getUserOrgInfoDealerOem(this.bizType);
        this.form.orgCode = orgObj.salesOrgCode;
        this.form.orgName = orgObj.salesOrgName;
      }
    },
    nodeClick: function nodeClick(val) {
      this.form.orgCode = val.salesOrgCode;
      this.form.orgName = val.salesOrgName;
    },
    dateChange: function dateChange(start, end, val) {
      //日期选择事件
      if (val && val.length > 1) {
        this.form.startTime = "".concat(val[0], " 00:00:00");
        this.form.endTime = "".concat(val[1], " 23:59:59");
      } else {
        this.form.endTime = '';
        this.form.startTime = '';
      }
    },
    // 附件校验
    validate: function validate() {
      var flag = true;
      if (!this.form.storageId) {
        this.$message.warning('请上传文件');
        flag = false;
        return flag;
      }
      return flag;
    },
    save: function save() {
      var _this = this;
      //保存数据
      this.$refs["detail"].validate(function (valid) {
        if (valid) {
          if (!_this.validate()) return;
          _this.importLoading = true;
          request.importRepertoire(_this.form).then(function (res) {
            _this.importLoading = false;
            if (res.code === 200) {
              _this.$confirm("".concat(res.data.failures ? '文件模板失败数据' + res.data.failures + '条，点击打开文件，重新上传导入，谢谢！' : '导入成功', " "), '提示', {
                showCancelButton: false,
                confirmButtonText: "".concat(res.data.failures ? '打开文件' : "确定"),
                type: "".concat(res.data.failures ? 'warning' : 'success')
              }).then(function () {
                if (res.data.failures) {
                  downFile(res.data.fileUrl, res.data.fileName);
                  _this.form.storageId = '';
                  _this.fileList = [];
                } else {
                  _this.initData();
                  _this.closePage();
                }
              }).catch(function () {});
            }
          }).catch(function () {
            _this.importLoading = false;
          });
          ;
        } else {
          return false;
        }
      });
    },
    closePage: function closePage() {
      //关闭弹窗
      this.$emit('closePage');
    },
    uploadFile: function uploadFile(param) {
      var _this2 = this;
      //上传文件
      this.canSave = true;
      var formData = new FormData();
      formData.append("file", param.file);
      storageRequest.uploadFile(formData).then(function (res) {
        setTimeout(function () {
          _this2.canSave = false;
        }, 1000);
        if (res.code === 200) {
          _this2.$message.success(res.msg);
          setTimeout(function () {
            _this2.form.storageId = res.data.fileStorageId;
            _this2.form.fileName = param.file.name;
            _this2.form.fileUrl = res.data.fileUrl;
          }, 1000);
        }
      });
    }
  }
};