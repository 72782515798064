//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import NonAutoRequest from '@/api/nonAutoRequest';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    AddType: {
      type: String,
      default: ''
    },
    DialogType: {
      type: String,
      default: {}
    },
    DialogItem: {
      type: Object,
      default: {}
    }
  },
  data: function data() {
    return {
      formLoading: false,
      addForm: {
        attributeCode: "",
        attributeName: "",
        dateType: ""
      },
      formRules: {
        attributeName: [{
          required: true,
          message: '请输入属性名称',
          trigger: 'blur'
        }],
        attributeCode: [{
          required: true,
          message: '请输入属性编码',
          trigger: 'blur'
        }],
        dateType: [{
          required: true,
          message: '请选择数据类型',
          trigger: 'change'
        }]
      },
      propertyTypes: [{
        value: 'CHARACTER',
        name: '字符'
      }, {
        value: 'BOOLEAN',
        name: '布尔值'
      }, {
        value: 'INTEGER',
        name: '整数'
      }, {
        value: 'DECIMALS',
        name: '小数'
      }, {
        value: 'DATE',
        name: '日期'
      }],
      timeout1: null
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
      ;
    }
  },
  mounted: function mounted() {
    //初始化数据
    this.initData();
  },
  methods: {
    //初始化数据
    initData: function initData() {
      var _this = this;
      if (this.DialogType == 'add') {
        this.addForm = {
          attributeCode: "",
          attributeName: "",
          dateType: ""
        };
        this.$nextTick(function () {
          _this.$refs.addForm.resetFields();
        });
      } else {
        if (this.DialogItem) {
          this.addForm = {
            attributeId: this.DialogItem.attributeId,
            attributeCode: this.DialogItem.attributeCode,
            attributeName: this.DialogItem.attributeName,
            dateType: this.DialogItem.dateType
          };
        }
      }
    },
    // 保存数据防抖
    save: function save() {
      var _this2 = this;
      if (this.timeout1) {
        clearTimeout(this.timeout1);
      }
      this.timeout1 = setTimeout(function () {
        _this2.save1();
      }, 500);
    },
    //保存数据
    save1: function save1() {
      var _this3 = this;
      this.$refs['addForm'].validate(function (valid) {
        if (valid) {
          _this3.formLoading = true;
          if (_this3.DialogType == 'add') {
            //新增
            NonAutoRequest.getProducInfoAdd(_this3.addForm).then(function (res) {
              _this3.formLoading = false;
              if (res.code == 200 && res.success == true) {
                _this3.$message.success(res.msg);
                _this3.closePage();
              }
            });
          } else {
            //编辑
            NonAutoRequest.getProducInfoEdit(_this3.addForm).then(function (res) {
              _this3.formLoading = false;
              if (res.code == 200 && res.success == true) {
                _this3.$message.success(res.msg);
                _this3.closePage();
              }
            });
          }
        }
      });
    },
    //关闭弹窗
    closePage: function closePage() {
      var _this4 = this;
      this.$nextTick(function () {
        _this4.$refs.addForm.resetFields();
        _this4.$refs['addForm'].clearValidate();
      });
      this.$emit('closePage');
    }
  }
};