import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from "@/mixins/initHeight";
import dict from "@/mixins/dict";
import Dialog from "@/components/Dialog";
import Pagination from "@/components/Pagination";
import servicePackageApi from "@/api/servicePackage";
import addDiadlog from './dialog/add';
import { getCurrentDate, getBeforeDate } from '@/utils/common';
export default {
  name: 'DrivingAccidentInsurancePproducts',
  components: {
    Pagination: Pagination,
    Dialog: Dialog,
    addDiadlog: addDiadlog
  },
  mixins: [initHeight, dict],
  data: function data() {
    return {
      opType: "add",
      showAddDialog: false,
      drawer: false,
      searchForm: {
        createdTimeStart: "".concat(getBeforeDate(6), " 00:00:00"),
        createdTimeEnd: "".concat(getCurrentDate(), " 23:59:59")
      },
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      loading: false,
      formLoading: false,
      accidentSchemeId: '',
      startEndTime: [getBeforeDate(6), getCurrentDate()]
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.loadData();
  },
  methods: {
    // 日期选择事件
    dateChange: function dateChange(start, end, val) {
      if (val && val.length > 1) {
        this.searchForm[start] = "".concat(val[0], " 00:00:00");
        this.searchForm[end] = "".concat(val[1], " 23:59:59");
      } else {
        this.searchForm[start] = null;
        this.searchForm[end] = null;
      }
      ;
    },
    closeAddDialog: function closeAddDialog() {
      this.$refs.Diadlog.closePage();
      this.showAddDialog = false;
    },
    delDetail: function delDetail(row) {
      var _this = this;
      this.$confirm("您确定要将该产品删除吗？", "温馨提示", {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: "warning"
      }).then(function () {
        servicePackageApi.AccidentDel(row.accidentSchemeId.toString()).then(function (res) {
          if (res.code === 200) {
            _this.$message({
              type: "success",
              message: res.msg
            });
            _this.loadData();
          }
        });
      });
    },
    breakData: function breakData() {
      this.loadData();
    },
    openAdd: function openAdd(type) {
      this.opType = type;
      this.showAddDialog = true;
    },
    viewDetail: function viewDetail(type, item) {
      //编辑内勤
      this.opType = type;
      this.accidentSchemeId = item.accidentSchemeId.toString();
      this.showAddDialog = true;
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {
        createdTimeStart: "".concat(getBeforeDate(6), " 00:00:00"),
        createdTimeEnd: "".concat(getCurrentDate(), " 23:59:59")
      };
      this.startEndTime = [getBeforeDate(6), getCurrentDate()];
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    loadData: function loadData() {
      var _this2 = this;
      var isDialogNoRefresh = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      //获取列表数据
      this.showAddDialog = false;
      if (isDialogNoRefresh) return;
      this.drawer = false;
      this.loading = true;
      var data = _objectSpread(_objectSpread({}, this.listQuery), this.searchForm);
      servicePackageApi.getAccidentLists(data).then(function (res) {
        _this2.loading = false;
        if (res.code == 200) {
          _this2.tableData = res.data.list;
        } else {
          _this2.tableData = [];
        }
      });
    }
  }
};