var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.formLoading,
          expression: "formLoading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: {},
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "500px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "detail",
                  staticStyle: { "margin-bottom": "-20px" },
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-width": "126px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "模板类型编码：",
                                prop: "typeCode",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入模板类型编码",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.typeCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "typeCode", $$v)
                                  },
                                  expression: "form.typeCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "模板类型名称：",
                                prop: "typeName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入模板类型名称",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.typeName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "typeName", $$v)
                                  },
                                  expression: "form.typeName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "模板类型描述：",
                                prop: "typeDescribe",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入模板类型描述",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.typeDescribe,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "typeDescribe", $$v)
                                  },
                                  expression: "form.typeDescribe",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "模板参数：" } },
                            [
                              _c(
                                "el-table",
                                {
                                  ref: "multipleTable",
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    size: "mini",
                                    border: "",
                                    data: _vm.form.params,
                                    "header-cell-style": {
                                      background: "#F7F7F7",
                                    },
                                    "highlight-current-row": "",
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: { label: "参数名" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("el-input", {
                                              attrs: {
                                                maxlength: "40",
                                                placeholder: "请输入参数名",
                                                clearable: "",
                                              },
                                              model: {
                                                value: scope.row.key,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "key",
                                                    $$v
                                                  )
                                                },
                                                expression: "scope.row.key",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                  _c("el-table-column", {
                                    attrs: { label: "描述" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("el-input", {
                                              attrs: {
                                                maxlength: "40",
                                                placeholder: "请输入描述",
                                                clearable: "",
                                              },
                                              model: {
                                                value: scope.row.value,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "value",
                                                    $$v
                                                  )
                                                },
                                                expression: "scope.row.value",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      width: "100",
                                      label: "操作",
                                      align: "center",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("i", {
                                              staticClass:
                                                "el-icon-circle-plus-outline",
                                              staticStyle: {
                                                "font-size": "24px",
                                                color: "#356ef6",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addItem(
                                                    scope.$index
                                                  )
                                                },
                                              },
                                            }),
                                            scope.$index
                                              ? _c("i", {
                                                  staticClass:
                                                    "el-icon-remove-outline",
                                                  staticStyle: {
                                                    "font-size": "24px",
                                                    color: "#ed6262",
                                                    "margin-left": "8px",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.deleteItem(
                                                        scope.$index
                                                      )
                                                    },
                                                  },
                                                })
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-check",
                type: "primary",
                plain: "",
                size: "mini",
              },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: { click: _vm.closePage },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }