//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import requestApi from '@/api/smsManage';
import OemSelect from '@/components/OemSelect';
import { getUserOrgInfoDealerOem } from '@/utils/common';
export default {
  components: {
    OemSelect: OemSelect
  },
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    opType: {
      type: String,
      default: ""
    },
    currentId: {
      type: String,
      default: ""
    },
    templateTypeList: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      form: {
        templateTypeId: '',
        orgCode: '',
        orgName: '',
        messageType: '',
        templateName: '',
        supplierTemplateCode: '',
        templateContent: '',
        enabled: true,
        subordinate: true
      },
      rules: {
        templateTypeId: {
          required: true,
          message: '请选择模板类型',
          trigger: "change"
        },
        orgCode: {
          required: true,
          message: '请选择所属机构',
          trigger: "change"
        },
        messageType: {
          required: true,
          message: '请选择短信类型',
          trigger: "change"
        },
        templateName: {
          required: true,
          message: '请输入模板名称',
          trigger: "blur"
        },
        supplierTemplateCode: {
          required: true,
          message: '请输入供应商模板编码',
          trigger: "blur"
        },
        templateContent: {
          required: true,
          message: '请输入模板内容',
          trigger: "blur"
        }
      },
      tableData: [],
      typeDescribe: '',
      messageTypeList: [{
        value: 'MT01',
        label: '营销短信'
      }, {
        value: 'MT02',
        label: '标准短信'
      }],
      formLoading: false,
      orgForm: {
        salesOrgCode: '',
        salesOrgName: ''
      },
      bizType: localStorage.getItem("bizType") || ''
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    initData: function initData() {
      var _this = this;
      //初始化数据
      this.form = {
        templateTypeId: '',
        orgCode: '',
        orgName: '',
        tableData: [],
        messageType: '',
        typeDescribe: '',
        templateName: '',
        supplierTemplateCode: '',
        templateContent: '',
        enabled: true,
        subordinate: true
      };
      this.tableData = [];
      this.typeDescribe = '';
      this.orgForm = {
        salesOrgCode: '',
        salesOrgName: ''
      };
      if (this.opType === "add") {
        if (this.bizType === "DEALER") {
          var orgObj = getUserOrgInfoDealerOem(this.bizType);
          this.form.orgCode = orgObj.salesOrgCode;
          this.form.orgName = orgObj.salesOrgName;
        }
        ;
      } else {
        requestApi.queryTemplate(this.currentId).then(function (res) {
          if (res.code === 200) {
            _this.form = res.data;
            _this.orgForm = {
              salesOrgCode: _this.form.orgCode,
              salesOrgName: _this.form.orgName
            };
            _this.templateChange(_this.form.templateTypeId);
          }
          ;
        });
      }
      ;
      this.$refs.detail.resetFields();
    },
    templateChange: function templateChange(val) {
      var _this2 = this;
      requestApi.getTemplateInfo(val).then(function (res) {
        _this2.tableData = res.data.params;
        _this2.typeDescribe = res.data.typeDescribe;
      });
    },
    handleNodeClick: function handleNodeClick(data, node, target) {
      // 机构树点击事件
      this.form.orgCode = data.salesOrgCode;
      this.form.orgName = data.salesOrgName;
    },
    save: function save() {
      var _this3 = this;
      //保存数据
      this.$refs.detail.validate(function (valid) {
        if (valid) {
          _this3.formLoading = true;
          var req = _this3.opType === 'add' ? requestApi.addTemplate(_this3.form) : requestApi.editTemplate(_this3.currentId, _this3.form);
          req.then(function (res) {
            _this3.formLoading = false;
            if (res.code === 200) {
              _this3.$message.success(res.msg);
              _this3.closePage();
            }
            ;
          }).catch(function (err) {
            _this3.formLoading = false;
          });
        }
        ;
      });
    },
    closePage: function closePage() {
      //关闭弹窗
      this.$emit('closePage');
    }
  }
};