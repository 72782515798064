import "core-js/modules/es.object.keys.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import dict from '@/mixins/dict'
import Rules from '@/utils/rules';
import { getSupplyers } from '@/utils/common';
export default {
  // mixins: [dict],
  props: {
    refresh: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      form: {},
      formRules: {
        ownerName: [{
          required: false,
          message: '请输入车主名',
          trigger: 'blur'
        }, {
          min: 2,
          max: 50,
          pattern: Rules["NAME"].value,
          message: "车主名格式有误",
          trigger: ["blur", "change"]
        }],
        ownerMobile: [{
          required: false,
          message: '',
          trigger: 'blur'
        }, {
          pattern: Rules["MOBILE_RULE"].value,
          message: "联系电话格式有误",
          trigger: ["blur", "change"]
        }],
        ownerOtherMobile: [{
          required: false,
          message: '',
          trigger: 'blur'
        }, {
          pattern: Rules["MOBILE_RULE"].value,
          message: "其他电话格式有误",
          trigger: ["blur", "change"]
        }],
        email: [{
          required: false,
          message: '',
          trigger: 'blur'
        }, {
          pattern: Rules["EMAIL"].value,
          message: "电子邮箱格式有误",
          trigger: ["blur", "change"]
        }]
      },
      listData: [],
      cascaderAddr: [],
      options: JSON.parse(localStorage.getItem('VI_CityAreaTree')) || [],
      listLoading: false,
      supplyers: []
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
    }
  },
  mounted: function mounted() {
    this.initData();
    this.supplyers = getSupplyers();
    if (this.supplyers) {
      _.remove(this.supplyers, function (o) {
        return o.code == 'QITA' || o.code == 'qita';
      });
    }
  },
  methods: {
    // 初始化数据
    initData: function initData() {
      // this.$refs.add.resetFields();
      this.cascaderAddr = [];
    },
    // 省/市/区选择
    handleChange: function handleChange(val) {
      this.$nextTick(function () {
        var cascaderAddr = this.$refs['cascaderAddr'].getCheckedNodes()[0].pathLabels;
        this.form.ownerProvinceName = cascaderAddr[0];
        this.form.ownerCityName = cascaderAddr[1];
        this.form.ownerDistrictName = cascaderAddr[2];
        this.form.ownerProvinceCode = val[0];
        this.form.ownerCityCode = val[1];
        this.form.ownerDistrictCode = val[2];
      });
    },
    // 保存数据
    save: function save() {
      var _this = this;
      this.$refs['add'].validate(function (valid, obj) {
        if (valid) {
          _this.closePage();
        }
        ;
      });
    },
    // 关闭弹窗
    closePage: function closePage() {
      this.$emit('closePage');
    }
  }
};