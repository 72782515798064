//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import dict from '../../mixins/dict'

export default {
  // mixins: [dict],
  data: function data() {
    return {
      form: {},
      listLoading: false,
      startEndTime: ""
    };
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    initData: function initData() {//初始化数据
    },
    loadData: function loadData() {//加载数据
    },
    save: function save() {
      //保存数据
      this.closePage();
    },
    closePage: function closePage() {
      //关闭弹窗
      this.$emit('closePage');
    }
  }
};