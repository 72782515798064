import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.concat.js";
//
//
//
//

import * as echarts from 'echarts';
require('echarts/theme/macarons'); // echarts theme
import resize from './mixins/resize';
export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '300px'
    },
    chartData: {
      type: Array,
      default: []
    }
  },
  data: function data() {
    return {
      chart: null
    };
  },
  watch: {
    chartData: {
      deep: true,
      handler: function handler(val) {
        this.setOptions(val);
      }
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      _this.initChart();
    });
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      this.chart = echarts.init(this.$el, 'macarons');
      this.setOptions();
    },
    setOptions: function setOptions() {
      var self = this;
      var legendData = this.chartData.map(function (val) {
        return val.name;
      });
      self.chart && self.chart.setOption({
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgb(0,0,0,0.5)',
          // 提示框浮层的背景颜色。
          borderColor: 'rgb(255,255,255,0.3)',
          // 提示框浮层的边框颜色。
          borderWidth: 0,
          // 提示框浮层的边框宽。
          textStyle: {
            // 提示框浮层的文本样式。
            color: '#fff'
          },
          formatter: function formatter(params) {
            var res = "";
            params.forEach(function (element) {
              res += "".concat(element.marker).concat(element.seriesName, "\uFF1A").concat(element.data, " \u5355<br/>");
            });
            return res;
          }
        },
        legend: {
          data: legendData,
          textStyle: {
            color: '#f9f9f9'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['01日', '02日', '03日', '04日', '05日', '06日', '07日', '08日', '09日', '10日', '11日', '12日', '13日', '14日', '15日', '16日', '17日', '18日', '19日', '20日', '21日', '22日', '23日', '24日', '25日', '26日', '27日', '28日', '29日', '30日', '31日'],
          axisLine: {
            lineStyle: {
              color: '#ffffff'
            }
          },
          axisLabel: {
            show: true,
            interval: 0,
            rotate: -30,
            textStyle: {
              color: '#f9f9f9' //更改坐标轴文字颜色
            }
          }
        },

        yAxis: {
          type: 'value',
          axisLine: {
            lineStyle: {
              color: '#ffffff'
            }
          },
          splitArea: {
            show: false,
            areaStyle: {
              color: []
            }
          },
          axisLabel: {
            show: true,
            formatter: function formatter(value) {
              if (self.chartData[0].title == "出单量") {
                if (value === 0 || value < 1) {
                  return value;
                } else {
                  return "".concat(value) + '单';
                }
              }
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ['rgb(255,255,255,0.15)'],
              type: "dashed",
              width: 1
            }
          }
        },
        series: [{
          name: this.chartData[0] ? this.chartData[0].name : '',
          type: 'line',
          data: this.chartData[0] ? this.chartData[0].value : [],
          itemStyle: {
            normal: {
              lineStyle: {
                width: 2 //设置线条粗细
              }
            }
          }
        }, {
          name: this.chartData[1] ? this.chartData[1].name : '',
          type: 'line',
          data: this.chartData[1] ? this.chartData[1].value : [],
          itemStyle: {
            normal: {
              lineStyle: {
                width: 2 //设置线条粗细
              }
            }
          }
        }, {
          name: this.chartData[2] ? this.chartData[2].name : '',
          type: 'line',
          data: this.chartData[2] ? this.chartData[2].value : [],
          itemStyle: {
            normal: {
              lineStyle: {
                width: 2 //设置线条粗细
              }
            }
          }
        }, {
          name: this.chartData[3] ? this.chartData[3].name : '',
          type: 'line',
          data: this.chartData[3] ? this.chartData[3].value : [],
          itemStyle: {
            normal: {
              lineStyle: {
                width: 2 //设置线条粗细
              }
            }
          }
        }]
      });
    }
  }
};