import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import _createForOfIteratorHelper from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import _defineProperty from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.join.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from "@/mixins/initHeight";
import dict from "@/mixins/dict";
import Dialog from "@/components/Dialog";
import Pagination from "@/components/Pagination";
import employeeRequest from "@/api/employee";
import storageRequest from "@/api/storage";
import Rules from "@/utils/rules";
import request from "@/api/dictionary";
import addDiadlog from './add';
import CarBrands from '@/components/CarBrands/index';
import servicePackageApi from "@/api/servicePackage";
import { getSupplyers } from '@/utils/common';
var delay = function () {
  var _this = this;
  var timeout;
  return function (callback, ms) {
    if (timeout) clearTimeout(timeout);
    var callNow = !timeout;
    timeout = setTimeout(function () {
      timeout = undefined;
    }, ms);
    if (callNow) callback.apply(_this, [callback, ms]);
  };
}();
export default {
  name: 'ServicePackageProducts',
  components: {
    Pagination: Pagination,
    Dialog: Dialog,
    addDiadlog: addDiadlog,
    CarBrands: CarBrands
  },
  mixins: [initHeight, dict],
  data: function data() {
    var _ref;
    return _ref = {
      showUploadDialog: false,
      importLoading: false,
      uploadForm: {},
      carTypeVal: [],
      supplyers: [],
      packSchemeId: '',
      editItem: {},
      showAddDialog: false,
      DialogType: '',
      multipleSelection: [],
      activeName: "HT",
      drawer: false,
      searchForm: {},
      form: {
        ProgramCode: '',
        ProgramName: '',
        ProgramDescription: '',
        ProgramProducts: []
      },
      fileTypeList: [],
      total: 5,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      defaultProps: {
        children: "children",
        label: "fullName"
      },
      tableData: []
    }, _defineProperty(_ref, "multipleSelection", []), _defineProperty(_ref, "showEmployeeDialog", false), _defineProperty(_ref, "opType", "add"), _defineProperty(_ref, "loading", false), _defineProperty(_ref, "formLoading", false), _defineProperty(_ref, "highlightCurrent", true), _defineProperty(_ref, "orgData", []), _defineProperty(_ref, "employeeItem", {}), _defineProperty(_ref, "formRules", {
      insuranceProductsCode: [{
        required: true,
        message: "请输入产品编码",
        trigger: "blur"
      }],
      insuranceProductsName: [{
        required: true,
        message: "请输入产品名称",
        trigger: "blur"
      }]
    }), _defineProperty(_ref, "roles", []), _defineProperty(_ref, "fileDate", []), _defineProperty(_ref, "fileList", []), _defineProperty(_ref, "contract", []), _defineProperty(_ref, "contractType", "CONTRACT"), _defineProperty(_ref, "VI_CERTIFICATE_TYPE", []), _defineProperty(_ref, "isClick", false), _defineProperty(_ref, "viewDisabled", false), _defineProperty(_ref, "allFileList", []), _defineProperty(_ref, "conuntriesList", []), _defineProperty(_ref, "schoolList", []), _defineProperty(_ref, "maritalList", []), _defineProperty(_ref, "mationList", []), _defineProperty(_ref, "politicalList", []), _defineProperty(_ref, "employeeTypeList", [{
      value: 'OFFICE_WORK',
      label: '内勤'
    }, {
      value: 'SALESMAN',
      label: '业务员'
    }]), _ref; //data结束
  },

  watch: {
    showEmployeeDialog: function showEmployeeDialog(newValue) {
      if (newValue) {
        if (this.$refs["detail"]) this.$refs["detail"].resetFields();
        if (this.opType == "add") {
          this.form = {
            insuranceProductsCode: '',
            insuranceProductsName: '',
            insuranceProducts: []
          };
        }
      }
    }
  },
  created: function created() {
    this.supplyers = getSupplyers();
    this.$forceUpdate();
  },
  mounted: function mounted() {
    var _this2 = this;
    request.getDictItemsByCode(this.contractType).then(function (res) {
      if (res.code === 200) {
        _this2.contract = res.data;
      }
    });
    this.loadData();
    var certificateList = JSON.parse(localStorage.getItem("VI_CERTIFICATE_TYPE")) || [];
    this.VI_CERTIFICATE_TYPE = [];
    certificateList.forEach(function (val) {
      if (!["E01", "E02"].includes(val.dictCode)) {
        _this2.VI_CERTIFICATE_TYPE.push(val);
      }
    });
    this.$forceUpdate();
  },
  methods: {
    downloadFormwork: function downloadFormwork() {
      //下载模板
      console.log('下载模板');
      // productRequest.download().then(res => {
      //     let fileName = "产品模板.xlsx";
      //     let contentType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      //     saveFile(res, contentType, fileName);
      // })
    },
    selectFile: function selectFile() {
      // 上传文件
      if (!this.validate()) return;
      this.importLoading = true;
      // productRequest.importProduct(this.uploadForm).then((res) => {
      //             if (res.code === 200) {
      //                 this.importLoading = false;
      //                 this.errorList = res.data.errorList
      //                 this.repeatList = res.data.warningList
      //                 if (this.errorList.length || this.repeatList.length) {
      //                     this.showResultDialog = true
      //                     this.showDialog = false;
      //                 } else {
      //                     this.showResultDialog = false
      //                     this.fileList = []
      //                     this.$message.success(res.msg)
      //                     this.loadData()
      //                 }
      //             }
      //         },
      //         err => {})
      //     .catch((err) => {
      //         this.importLoading = false;
      //     });
    },
    uploadFile: function uploadFile(param) {
      var _this3 = this;
      //上传文件
      var formData = new FormData();
      formData.append("file", param.file);
      storageRequest.uploadFile(formData).then(function (res) {
        if (res.code === 200) {
          _this3.$message.success(res.msg);
          // this.uploadForm.storageId = res.data.fileStorageId
        }
      });
    },
    // 品牌车型监听数据变化
    CarHandleChange: function CarHandleChange(val, options) {
      var _iterator = _createForOfIteratorHelper(options),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var i = _step.value;
          if (i.value == val[0]) {
            this.searchForm.cjVhcBrandName = i.label; // 品牌编号
            if (i.children && i.children.length != 0) {
              var _iterator2 = _createForOfIteratorHelper(i.children),
                _step2;
              try {
                for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
                  var j = _step2.value;
                  if (j.value == val[1]) {
                    this.searchForm.cjVhcSeriesName = j.label; // 品牌编号
                  }
                }
              } catch (err) {
                _iterator2.e(err);
              } finally {
                _iterator2.f();
              }
            }
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      this.searchForm.cjVhcBrandNo = val[0]; // 品牌编号
      this.searchForm.cjVhcSeriesNo = val[1]; // 车系编号
      this.carTypeVal = val;
    },
    // 启用禁用
    EnabledClicked: function EnabledClicked(row) {
      var _this4 = this;
      servicePackageApi.packageEnabled(row.packSchemeId, row.enabled).then(function (res) {
        if (res.code === 200) {
          _this4.$message({
            type: "success",
            message: res.msg
          });
          _this4.loadData();
        }
      });
    },
    showAddDialogClose: function showAddDialogClose() {
      this.$refs.Diadlog.closePage();
      this.showAddDialog = false;
    },
    delDetail: function delDetail(row) {
      var _this5 = this;
      this.$confirm("您确定要将该产品删除吗？", "温馨提示", {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: "warning"
      }).then(function () {
        _this5.delClicked(row.packSchemeId);
      });
    },
    delClicked: function delClicked(ids) {
      var _this6 = this;
      servicePackageApi.packageDel(ids).then(function (res) {
        if (res.code === 200) {
          _this6.$message({
            type: "success",
            message: res.msg
          });
          _this6.loadData();
        }
      });
    },
    // 复选框数据
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    breakData: function breakData() {
      // this.resetSearch();
      this.loadData();
    },
    handleTabClick: function handleTabClick(tab, event) {
      //tab切换事件
      this.searchForm = {};
      this.searchForm.tabType = tab.name;
      this.tabType = tab.name;
      // this.loadData();
      var list = _.filter(this.allFileList, function (o) {
        return o.attachmentTypeDisplay == tab.name;
      });
      this.fileList = list;
    },
    close: function close() {
      //关闭
      this.fileList = [];
      this.viewDisabled = false;
      this.form = {
        insuranceProductsCode: '',
        insuranceProductsName: '',
        insuranceProductsDescription: '',
        insuranceProducts: []
      };
      this.$refs.multipleTable.clearSelection();
      this.multipleSelection = [];
      this.insuranceData.map(function (item) {
        item.value = '';
      });
      this.showEmployeeDialog = false;
    },
    filterNode: function filterNode(value, data) {
      //机构树查询
      if (!value) return true;
      return data.fullName.indexOf(value) !== -1;
    },
    addClicked: function addClicked(type) {
      this.DialogType = type;
      this.showAddDialog = true;
    },
    viewDetail: function viewDetail(type, item) {
      this.DialogType = type;
      this.packSchemeId = item.packSchemeId.toString();
      this.showAddDialog = true;
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {};
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    loadData: function loadData() {
      var _this7 = this;
      var isDialogNoRefresh = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      //获取列表数据
      this.showAddDialog = false;
      this.showEmployeeDialog = false;
      if (isDialogNoRefresh) return;
      this.drawer = false;
      this.loading = true;
      var data = _objectSpread(_objectSpread({}, this.listQuery), this.searchForm);
      servicePackageApi.packageLists(data).then(function (res) {
        _this7.loading = false;
        if (res.code == 200) {
          _this7.tableData = res.data ? res.data.list : [];
        }
      }).catch(function (err) {
        _this7.loading = false;
      });
    },
    getRoleNames: function getRoleNames(row) {
      var nameArr = [];
      if (row) {
        nameArr = row.map(function (val) {
          return val.roleName;
        });
      }
      return nameArr.join(",");
    }
  }
};