var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.listLoading,
          expression: "listLoading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: {},
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "540px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "detail",
                  staticStyle: { "margin-bottom": "-20px" },
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-width": "140px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "经销商名称：",
                                prop: "dealerName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入经销商名称",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.dealerName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "dealerName", $$v)
                                  },
                                  expression: "form.dealerName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "经销商编码：",
                                prop: "dealerCode",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入经销商编码",
                                  onkeyup: "value=value.replace(/\\s+/g,'')",
                                  clearable: "",
                                  disabled: _vm.opType === "edit",
                                },
                                model: {
                                  value: _vm.form.dealerCode,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "dealerCode",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form.dealerCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "经销商类型：",
                                prop: "dealerType",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    filterable: "",
                                    placeholder: "请选择经销商类型",
                                  },
                                  on: { change: _vm.dealerTypeChange },
                                  model: {
                                    value: _vm.form.dealerType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "dealerType", $$v)
                                    },
                                    expression: "form.dealerType",
                                  },
                                },
                                _vm._l(
                                  _vm.dealerTypeOption,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.label,
                                        value: item.value,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.form.dealerType === "LEVEL_2"
                    ? _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "上级经销商：",
                                    prop: "parentDealerCode",
                                  },
                                },
                                [
                                  _c("OemSelect", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      searchForm: _vm.orgForm,
                                      isContainOem: false,
                                      isSecondNet: true,
                                    },
                                    on: { nodeClick: _vm.nodeClick },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "运营模式：",
                                prop: "dealerOperateMode",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    filterable: "",
                                    placeholder: "请选择运营模式",
                                  },
                                  model: {
                                    value: _vm.form.dealerOperateMode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "dealerOperateMode",
                                        $$v
                                      )
                                    },
                                    expression: "form.dealerOperateMode",
                                  },
                                },
                                _vm._l(
                                  _vm.operateModeOption,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.label,
                                        value: item.value,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "公司名称：",
                                prop: "companyName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入公司名称",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.companyName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "companyName", $$v)
                                  },
                                  expression: "form.companyName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "统一社会信用代码：",
                                prop: "certificateNo",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入统一社会信用代码",
                                  maxlength: "20",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.certificateNo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "certificateNo", $$v)
                                  },
                                  expression: "form.certificateNo",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "公司电话：",
                                prop: "companyPhone",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入公司电话",
                                  onkeyup:
                                    "value=value.replace(/[^0-9a-zA-Z\\-.]+/,'')",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.companyPhone,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "companyPhone", $$v)
                                  },
                                  expression: "form.companyPhone",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.form.dealerType !== "LEVEL_2"
                    ? _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "所在地址：",
                                    prop: "cityData",
                                  },
                                },
                                [
                                  _c("el-cascader", {
                                    ref: "addrssCascader",
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      options: _vm.cityList,
                                      clearable: "",
                                      filterable: false,
                                    },
                                    on: { change: _vm.areaCodeChange },
                                    model: {
                                      value: _vm.form.cityData,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "cityData", $$v)
                                      },
                                      expression: "form.cityData",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "区/县", prop: "" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    filterable: "",
                                    placeholder: "请选择区/县",
                                    clearable: "",
                                  },
                                  on: { change: _vm.districtChange },
                                  model: {
                                    value: _vm.form.districtCode,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "districtCode", $$v)
                                    },
                                    expression: "form.districtCode",
                                  },
                                },
                                _vm._l(
                                  _vm.form.districtData,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.label,
                                        value: item.value,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "详细地址：",
                                prop: "detailAddr",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  rows: 2,
                                  placeholder: "请输入详细地址",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.detailAddr,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "detailAddr", $$v)
                                  },
                                  expression: "form.detailAddr",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "联系人：", prop: "contactName" },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入联系人",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.contactName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "contactName", $$v)
                                  },
                                  expression: "form.contactName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "联系电话：",
                                prop: "contactPhone",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入联系电话",
                                  clearable: "",
                                  maxlength: "11",
                                  onkeyup:
                                    "value=value.replace(/[^0-9a-zA-Z\\-.]+/,'')",
                                },
                                model: {
                                  value: _vm.form.contactPhone,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "contactPhone", $$v)
                                  },
                                  expression: "form.contactPhone",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-check",
                type: "primary",
                plain: "",
                size: "mini",
              },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: { click: _vm.closePage },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }