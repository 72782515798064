import _defineProperty from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '../../mixins/initHeight';
import dict from '../../mixins/dict';
import Pagination from '@/components/Pagination';
import DatePicker from '@/components/DatePicker';
import business from '@/api/insuranceBusiness';
import PopupWindow from './dialog/popupWindow';
import OemSelect from '@/components/OemSelect';
import { getSupplyers, commonExport } from '@/utils/common';
export default {
  name: 'BusinessManagement',
  components: {
    Pagination: Pagination,
    PopupWindow: PopupWindow,
    OemSelect: OemSelect,
    DatePicker: DatePicker
  },
  mixins: [initHeight, dict],
  computed: {},
  data: function data() {
    var _ref;
    return _ref = {
      dealerData: [],
      arealist: [],
      applyData: [{
        label: "待审核",
        value: 'WAIT_APPLY'
      }, {
        label: "申请驳回",
        value: 'PENDING_AUDIT'
      }, {
        label: "已开通",
        value: 'AUDIT_SUCCEEDED'
      }, {
        label: "禁用",
        value: 'DISABLE'
      }],
      activeName: 'All',
      drawer: false,
      searchForm: {
        dealerCode: ''
      },
      orgForm: {
        salesOrgCode: '',
        salesOrgName: '',
        salesOrgType: ''
      },
      total: 0,
      listLoading: true,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      startEndTime: null,
      loading: false,
      highlightCurrent: true,
      users: [],
      showImportDialog: false,
      opType: '',
      insCorpItem: {},
      showDialog: false,
      applyIdArr: []
    }, _defineProperty(_ref, "dealerData", []), _defineProperty(_ref, "BigCityList", []), _defineProperty(_ref, "cityList", JSON.parse(localStorage.getItem('VI_CityPcTree')) || []), _defineProperty(_ref, "optionProps", {
      label: "name",
      children: "child"
    }), _defineProperty(_ref, "bizType", localStorage.getItem('bizType') || ""), _defineProperty(_ref, "userInfo", JSON.parse(localStorage.getItem("userInfo")) || {}), _defineProperty(_ref, "supplyers", []), _defineProperty(_ref, "approvalDateTime", []), _ref;
  },
  mounted: function mounted() {
    this.loadData();
    //保司
    this.supplyers = getSupplyers();
  },
  methods: {
    //日期更改监听事件
    getPickersDate: function getPickersDate(name, val) {
      if (val && val.length > 1) {
        this[name] = val;
      } else {
        this[name] = [];
      }
    },
    statusChange: function statusChange() {
      this.$forceUpdate();
    },
    cityHandleChange: function cityHandleChange(val) {
      //省市选择事件
      if (!val) return;
      this.searchForm.provinceCode = val[0];
      this.searchForm.cityCode = val[1];

      //获取地区名称
      // let regionName = '';
      // let province = _.find(this.cityList, (item => item.value == val[0]));
      // if (province) {
      //     regionName = province.label;
      //     // this.searchForm.areaName = province.label
      //     let city = _.find(province.children, (item => item.value == val[1]));
      //     if (city) {
      //         regionName = regionName + ' ' + city.label;
      //         this.searchForm.areaName = city.label
      //     }
      // }
      // console.log(this.searchForm.areaName,'this.searchForm.areaName')
      this.$forceUpdate();
    },
    breakData: function breakData() {
      // this.resetSearch()
      this.loadData();
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.approvalDateTime = [];
      this.searchForm = {
        applyStatus: this.activeName === 'All' ? '' : this.searchForm.applyStatus,
        dealerCode: ''
      };
      this.orgForm = {
        salesOrgName: '全部',
        salesOrgType: '',
        salesOrgCode: ''
      };
    },
    nodeClick: function nodeClick(val) {
      this.searchForm.areaType = val.salesOrgType;
      this.searchForm.areaNo = val.salesOrgType ? val.salesOrgCode : '';
      this.searchForm.dealerCode = val.salesOrgType ? this.searchForm.dealerCode : val.salesOrgCode;
    },
    //搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    //批量处理
    processing: function processing() {
      if (!this.applyIdArr.length) {
        this.$message.warning('请选择需要处理的数据');
        return;
      }
      this.showDialog = true;
    },
    //导出
    exportFn: function exportFn() {
      var exporParams = {
        total: this.total,
        limitNum: 200000,
        fileName: '业务管理导出',
        exportUrl: '/ps-ftms-service/business/page/admin',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    //tab切换事件
    handleTabClick: function handleTabClick(tab, event) {
      // this.searchForm = {
      //     dealerCode: ''
      // }
      this.searchForm.dealerCode = '';
      this.orgForm = {
        salesOrgCode: '',
        salesOrgName: '全部',
        salesOrgType: ''
      };
      this.searchForm.applyStatus = tab.name === 'All' ? '' : tab.name;
      this.tabType = tab.name;
      this.listQuery = {
        pageNum: 1,
        pageSize: 20
      }, this.loadData();
    },
    // 保司处理、查看
    toView: function toView(type, item) {
      this.$router.push({
        path: '/submitBusiness',
        query: {
          applyId: item.applyId,
          name: type,
          applyStatus: item.applyStatus,
          insCorpCode: item.insCorpCode
        }
      });
    },
    //获取列表数据
    loadData: function loadData() {
      var _this = this;
      this.listLoading = true;
      this.drawer = false;
      this.showDialog = false;
      this.showImportDialog = false;
      this.applyIdArr = [];
      if (this.approvalDateTime) {
        this.searchForm.approvalStartTime = this.approvalDateTime[0] ? this.approvalDateTime[0].split(' ')[0] + ' 00:00:00' : null;
        this.searchForm.approvalEndTime = this.approvalDateTime[1] ? this.approvalDateTime[1].split(' ')[0] + ' 23:59:59' : null;
      }
      business.managementList(this.listQuery.pageNum, this.listQuery.pageSize, this.searchForm).then(function (res) {
        if (res.code === 200) {
          _this.tableData = res.data.list;
          _this.loading = false;
          if (res.data.summaryData) {
            _this.summaryData = res.data.summaryData;
          }
          _this.total = res.data.page.recordsTotal;
        }
      });
    },
    cityData: function cityData(row) {
      var city = [];
      if (row.provinceName) {
        city.push(row.provinceName);
        if (row.cityName) {
          city.push(row.cityName);
          if (row.districtName) {
            city.push(row.districtName);
          }
        }
      }
      return city.join('-');
    },
    // 复选框数据
    handleSelectionChange: function handleSelectionChange(val) {
      if (val && val.length) {
        this.applyIdArr = val.map(function (val) {
          return val.applyId;
        });
      }
    },
    // 是否启用
    enableChange: function enableChange(val) {
      var _this2 = this;
      this.$confirm("\u60A8\u786E\u5B9A\u8981".concat(val.applyStatus == 'AUDIT_SUCCEEDED' ? '禁用' : '启用', "\u8BE5\u4FE1\u606F\uFF1F"), {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        type: 'warning'
      }).then(function () {
        if (val.applyStatus == 'AUDIT_SUCCEEDED') {
          val.applyStatus = 'DISABLE';
        } else {
          val.applyStatus = 'AUDIT_SUCCEEDED';
        }
        var data = {
          applyIds: [val.applyId],
          auditStatus: val.applyStatus,
          rejectCause: ''
        };
        business.update(data).then(function (res) {
          if (res.code === 200) {
            _this2.$message.success(res.msg);
            _this2.loadData();
          }
        });
      }).catch(function () {});
    }
  }
};