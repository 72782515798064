import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
/**
 * 呼叫中心
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  //  **********************************通话记录************************************
  /**
   * 查询通话记录列表
   * @param {*} data 数据
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示数量
   */
  getRecordList: function getRecordList(pageNum, pageSize, data) {
    return request.get(getUrl("/callCenter/record?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 15), {
      params: data
    });
  },
  /**
   * 删除通话记录
   * @param {*} ids 
   */
  deleteRecord: function deleteRecord(ids) {
    return request.delete(getUrl("/callCenter/record?ids=".concat(ids.toString()), 15));
  },
  /**
   * 修改保存通话记录
   * @param {*} id
   * @param {*} data 数据
   */
  editRecord: function editRecord(id, data) {
    return request.put(getUrl("/callCenter/record/".concat(id), 15), data);
  },
  //  **********************************录音设备管理************************************
  /**
   * 获取通话坐席分页列表
   * @param {*} data 数据
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示数量
   */
  getDeviceList: function getDeviceList(pageNum, pageSize, data) {
    return request.get(getUrl("/callCenter/device?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 15), {
      params: data
    });
  },
  /**
   * 新增录音设备
   * @param {*} data 内容
   */
  addDevice: function addDevice(data) {
    return request.post(getUrl("/callCenter/device", 15), data);
  },
  /**
   * 获取录音设备
   * @param {*} id 
   */
  queryDevice: function queryDevice(id) {
    return request.get(getUrl("/callCenter/device/".concat(id), 15));
  },
  /**
   * 删除呼录音设备
   * @param {*} ids
   */
  deleteDevice: function deleteDevice(ids) {
    return request.delete(getUrl("/callCenter/device?ids=".concat(ids.toString()), 15));
  },
  /**
   * 编辑录音设备
   * @param {*} id
   * @param {*} data
   */
  editDevice: function editDevice(id, data) {
    return request.put(getUrl("/callCenter/device/".concat(id), 15), data);
  },
  /**
   * 通话坐席心跳
   * @param {*} data
   */
  seatKeepAlive: function seatKeepAlive(data) {
    return request.post(getUrl("/callCenter/seat/keepAlive", 15), data);
  },
  //  **********************************话务报表************************************
  /**
   * 话务报表
   * @param {*} data 数据
   */
  getStatisticReport: function getStatisticReport(data) {
    return request.get(getUrl("/callCenter/record/statisticReport", 15), {
      params: data
    });
  },
  //  **********************************安装包版本************************************
  /**
   * 查询安装包列表
   * @param {*} data 数据
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示数量
   */
  getPackageList: function getPackageList(pageNum, pageSize, data) {
    return request.get(getUrl("/callCenter/package?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 15), {
      params: data
    });
  },
  /**
   * 新增保存安装包
   * @param {*} data 内容
   */
  addPackage: function addPackage(data) {
    return request.post(getUrl("/callCenter/package", 15), data);
  },
  /**
   * 修改安装包
   * @param {*} id
   * @param {*} status
   */
  editPackage: function editPackage(id, status) {
    return request.put(getUrl("/callCenter/package/".concat(id, "?issueStatus=").concat(status), 15));
  },
  /**
   * 删除安装包
   * @param {*} ids
   */
  deletePackage: function deletePackage(ids) {
    return request.delete(getUrl("/callCenter/package?ids=".concat(ids.toString()), 15));
  },
  /**
   * 下载安装包
   * @param {*} id
   */
  downloadPackage: function downloadPackage(id) {
    return request({
      method: 'put',
      url: getUrl("/callCenter/package/downloadTime/".concat(id), 15),
      responseType: 'blob'
    });
  }
};
export default objApi;