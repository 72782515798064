import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
/**
 * 批单管理接口
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 列表
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   */
  getList: function getList(pageNum, pageSize, data) {
    return request.get(getUrl("/riskAccidentProduct/search?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 14), {
      params: data
    });
  },
  /**
   * 根据保司编码获取非车险类型列表
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   */
  getAccidentTypeList: function getAccidentTypeList(data) {
    return request.get(getUrl("/riskAccidentType/getAccidentTypeList", 14), {
      params: data
    });
  },
  /**
   * 新增数据
   * @param {*} data 内容
   */
  addData: function addData(data) {
    return request.post(getUrl("/riskAccidentProduct/create", 14), data);
  },
  /**
   * 修改数据
   * @param {*} data 内容
   */
  updateData: function updateData(id, data) {
    return request.post(getUrl("/riskAccidentProduct/update?riskProductId=".concat(id), 14), data);
  },
  /**
   * 查看数据
   * @param {*} data 内容
   */
  getRiskProductDetail: function getRiskProductDetail(data) {
    return request.get(getUrl("/riskAccidentProduct/getRiskProductDetail", 14), {
      params: data
    });
  },
  /**
   * 删除数据
   * @param {*} id 
   */
  deleteData: function deleteData(id) {
    return request.delete(getUrl("/riskAccidentProduct/delete?riskAccidentIdList=".concat(id.toString()), 14));
  },
  /**
   * 获取非车险使用工号列表
   * @param {*} id 
   */
  getAccidentUseWorkNumber: function getAccidentUseWorkNumber(id) {
    return request.get(getUrl("/riskAccidentProduct/getAccidentUseWorkNumber?accidentIdList=".concat(id.toString()), 14));
  },
  /**
   * 通过驾意险分类和产品获取已关联的工号
   * @param {*} data 
   */
  getChannelAccident: function getChannelAccident(data) {
    return request.get(getUrl("/insCorpChannelAccident/getChannelAccident", 14), {
      params: data
    });
  },
  /**
   * 通过保司编码获取工号列表
   * @param {*} insCorpCode 
   */
  getBizChannelList: function getBizChannelList(insCorpCode) {
    return request.get(getUrl("/insCorpChannel/getBizChannelList?insCorpCode=".concat(insCorpCode), 14));
  },
  /**
   * 批量创建工号非车险关系
   * @param {*} data 
   */
  createBatchWorkNumber: function createBatchWorkNumber(data) {
    return request.post(getUrl("/insCorpChannelAccident/createBatch", 14), data);
  }
};
export default objApi;