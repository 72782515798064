import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import _createForOfIteratorHelper from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.regexp.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import dict from '@/mixins/dict';
import customersRequest from '@/api/customers';
import Pagination from '@/components/Pagination';
import OemSelect from '@/components/OemSelect';
import TaskCluesDistribution from './dialog/taskCluesDistribution';
import FollowUpDialog from '@/views/leadManagement/dialog/followUpDialog';
import { character, commonExport, getUserOrgInfo } from '@/utils/common';
export default {
  name: 'AssignmentManage',
  mixins: [initHeight, dict],
  components: {
    Pagination: Pagination,
    OemSelect: OemSelect,
    FollowUpDialog: FollowUpDialog,
    TaskCluesDistribution: TaskCluesDistribution
  },
  data: function data() {
    return {
      drawer: false,
      renewalTaskIds: [],
      bizType: localStorage.getItem("bizType"),
      searchForm: {
        taskTabType: "",
        includeSubOrgan: true,
        ownerName: "",
        plateNo: "",
        vin: "",
        startOverdueDate: "",
        endOverdueDate: "",
        overdueDays: "",
        noBzLastYearEndDate: false,
        noBsLastYearEndDate: false,
        startCreatedDate: '',
        endCreatedDate: '',
        salesOrgCode: '',
        salesOrgName: '',
        ownerBy: '',
        ownerByName: '',
        startRegisterDate: '',
        endRegisterDate: '',
        ownerMobile: ''
      },
      orgForm: {
        salesOrgCode: '',
        salesOrgName: '',
        salesOrgType: ''
      },
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      multipleSelection: [],
      overdueStartEndTime: [],
      createdStartEndTime: [],
      registerStartEndTime: [],
      noMaturityDate: [],
      loading: false,
      showDistributionDialog: false,
      customersIds: [],
      users: [],
      taskTabType: 'All',
      opType: '',
      OverdueTrack: 0,
      Subscribe: 0,
      tablist: [],
      showAddTracksDialog: false,
      customerItem: {},
      userInfo: JSON.parse(localStorage.getItem('userInfo')) || '',
      TaskByOrglist: [],
      dealerName: ""
    };
  },
  created: function created() {
    this.getDefaultValue();
    this.getTaskByOrg();
    if (localStorage.getItem('userInfo')) {
      var userInfo = JSON.parse(localStorage.getItem('userInfo'));
      this.getCustomerRenewal(userInfo);
      this.tablist = [];
      this.getSummaryTask();
    }
    this.loadData();
  },
  methods: {
    getDefaultValue: function getDefaultValue() {
      if (this.bizType !== 'OEM') {
        var orgObj = getUserOrgInfo() || {};
        this.searchForm.orgCode = orgObj.orgCode;
        this.orgForm = {
          salesOrgCode: orgObj.orgCode,
          salesOrgName: orgObj.fullName
        };
        this.dealerName = orgObj.fullName;
        this.$refs['oemSelectRef'].getUsers(orgObj.orgCode);
      }
    },
    getTaskByOrg: function getTaskByOrg() {
      var _this = this;
      var data = {};
      if (this.userInfo.data && this.userInfo.data.orgCode) {
        data = {
          bizType: this.userInfo.bizType ? this.userInfo.bizType : '',
          orgCode: this.userInfo.data && this.userInfo.data.orgCode ? this.userInfo.data.orgCode : ''
        };
      }
      customersRequest.getTaskByOrg(data).then(function (res) {
        if (res.code == 200) {
          if (res.data) {
            _this.TaskByOrglist = res.data;
          }
        }
      });
    },
    getCustomerRenewal: function getCustomerRenewal(userInfo) {
      var _this2 = this;
      var data = {};
      if (userInfo.data && userInfo.data.organization.orgCode) {
        data = {
          bizType: 'BROKER',
          orgCode: userInfo.data && userInfo.data.organization.orgCode ? userInfo.data.organization.orgCode : ''
        };
      }
      customersRequest.getCustomerRenewal(data).then(function (res) {
        if (res.code == 200) {
          _this2.tablist = res.data.configItemList ? res.data.configItemList : [];
          _this2.getSummaryTask();
        } else {
          _this2.tablist = [];
          _this2.getSummaryTask();
        }
      });
    },
    breakData: function breakData() {
      this.loadData();
    },
    // 导出
    exportFn: function exportFn() {
      var exporParams = {
        total: this.total,
        fileName: '任务管理列表',
        exportUrl: '/vi-core-service/customerRenewal/searchTask',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    // 列表行选择事件
    getUsers: function getUsers(val) {
      this.users = val;
    },
    nodeClick: function nodeClick(data) {
      this.searchForm.ownerBy = '';
      this.searchForm.ownerByName = '';
      this.searchForm.ownerOrgCode = data.salesOrgCode;
      this.searchForm.ownerOrgName = data.salesOrgName;
      this.searchForm.areaType = data.salesOrgType;
      if (!data.salesOrgCode && !data.salesOrgName) {
        this.users = [];
      }
    },
    // 自动将车牌号码转为大写
    inputPlateNo: function inputPlateNo(val) {
      this.searchForm.plateNo = _.toUpper(val);
    },
    // 监听输入事件
    inputvin: function inputvin(val) {
      if (val) {
        val = val.replace(/i/g, '').replace(/I/g, '').replace(/o/g, '').replace(/O/g, '').replace(/q/g, '').replace(/Q/g, '');
        this.searchForm.vin = character(_.toUpper(val));
        this.$forceUpdate();
      }
    },
    // 监听选择业务员事件
    salemanNameChange: function salemanNameChange(val) {
      if (val) {
        var _user = _.find(this.users, function (item) {
          return item.userId == val;
        });
        if (_user) {
          this.searchForm.ownerBy = _user.userId;
          this.searchForm.ownerByName = _user.userName;
          this.$forceUpdate();
        }
      } else {
        this.searchForm.ownerBy = '';
        this.searchForm.ownerByName = '';
      }
    },
    // tab切换事件
    handleTabClick: function handleTabClick(tab, event) {
      this.listQuery = {
        pageNum: 1,
        pageSize: 20
      };
      this.searchForm.taskTabType = tab.name == 'All' ? 'All' : tab.name;
      this.taskTabType = tab.name;
      this.loadData();
    },
    // 日期选择事件
    dateChange: function dateChange(start, end, val, type) {
      if (val && val.length > 1) {
        this.searchForm[start] = val[0];
        this.searchForm[end] = val[1];
      } else {
        if (type === 'overdueStartEnd') {
          this.searchForm.startOverdueDate = '';
          this.searchForm.endOverdueDate = '';
        } else if (type === 'createdStartEnd') {
          this.searchForm.startCreatedDate = '';
          this.searchForm.endCreatedDate = '';
        } else if (type === 'registerStartEnd') {
          this.searchForm.startRegisterDate = '';
          this.searchForm.endRegisterDate = '';
        }
      }
    },
    ChangeNoMaturityDate: function ChangeNoMaturityDate(val) {
      if (val.length) {
        this.searchForm.noBzLastYearEndDate = val.includes('noBzLastYearEndDate');
        this.searchForm.noBsLastYearEndDate = val.includes('noBsLastYearEndDate');
      } else {
        this.searchForm.noBzLastYearEndDate = false;
        this.searchForm.noBsLastYearEndDate = false;
      }
    },
    // 查看客户详情
    viewDetail: function viewDetail(item) {
      this.$router.push({
        path: '/customers/detail',
        query: {
          customerId: item.customerId,
          // plateNo: item.plateNo,
          // vin: item.vin
          tabName: "\u5BA2\u6237\u8BE6\u60C5-".concat(item.ownerName, "(").concat(item.vin, ")")
        }
      });
    },
    // 报价
    toQuote: function toQuote(item) {
      sessionStorage.removeItem("quoateForm");
      var router_path = this.$route.path;
      this.$router.push({
        path: "/proposal/add",
        query: {
          back: router_path,
          plateNo: item.plateNo,
          vin: item.vin,
          ownerCertificateNo: item.ownerCertificateNo,
          ownerMobile: item.ownerMobile,
          engineNo: item.engineNo,
          ownerOtherMobile: item.ownerMobile
        }
      });
    },
    // 跟进
    customerTracks: function customerTracks(item) {
      this.showAddTracksDialog = true;
      this.customerItem = item;
    },
    // 分配
    DistributionTracks: function DistributionTracks(item) {
      var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      this.opType = type;
      this.customersIds = [];
      // 判断是否多选
      if (type) {
        if (this.multipleSelection.length === 0) {
          this.$message.warning('请选择要分配的客户');
        } else {
          var showDistribution = false;
          // 判断是否有不同机构
          for (var i = 0; i < this.multipleSelection.length; i++) {
            for (var j = 0; j < this.multipleSelection.length; j++) {
              if (this.multipleSelection[i].orgCode == this.multipleSelection[j].orgCode) {
                showDistribution = true;
              } else {
                showDistribution = false;
                console.log('不允许跨机构分配');
              }
            }
          }
          // console.log('选的是否是同一机构下的客户', showDistribution)
          if (showDistribution) {
            var list = [];
            var _iterator = _createForOfIteratorHelper(this.multipleSelection),
              _step;
            try {
              for (_iterator.s(); !(_step = _iterator.n()).done;) {
                var _i = _step.value;
                list.push({
                  customerId: _i.customerId,
                  customerOrgCode: _i.orgCode
                });
              }
            } catch (err) {
              _iterator.e(err);
            } finally {
              _iterator.f();
            }
            this.showDistributionDialog = true;
            this.customersIds = list;
          } else {
            this.showDistributionDialog = false;
            this.$message.warning('不允许跨机构分配');
          }
        }
      } else {
        this.showDistributionDialog = true;
        this.customersIds = [{
          customerId: item.customerId,
          customerOrgCode: item.orgCode
        }];
      }
    },
    activation: function activation(row) {
      var _this3 = this;
      if (row && row.customerId) {
        customersRequest.activationCustomer({
          'customerId': row.customerId
        }).then(function (res) {
          if (res.code == 200) {
            _this3.loadData();
            _this3.$message.success('操作成功');
          }
        });
      } else {
        this.$message.error('操作失败');
      }
    },
    // 重置搜索表单
    resetSearch: function resetSearch() {
      this.renewalTaskIds = [];
      this.searchForm = {
        taskTabType: this.searchForm.taskTabType,
        ownerName: "",
        plateNo: "",
        vin: "",
        startOverdueDate: "",
        endOverdueDate: "",
        overdueDays: "",
        noBzLastYearEndDate: false,
        noBsLastYearEndDate: false,
        startCreatedDate: '',
        endCreatedDate: '',
        salesOrgCode: '',
        salesOrgName: '',
        includeSubOrgan: true,
        ownerBy: '',
        ownerByName: '',
        startRegisterDate: '',
        endRegisterDate: '',
        ownerMobile: ''
      };
      this.orgForm = {
        salesOrgName: '',
        salesOrgType: '',
        salesOrgCode: ''
      };
      this.overdueStartEndTime = [];
      this.createdStartEndTime = [];
      this.registerStartEndTime = [];
      this.noMaturityDate = [];
      this.users = [];
      this.getDefaultValue();
    },
    // 搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    // 双击打开详情
    dbSelected: function dbSelected(row) {
      this.viewDetail(row);
    },
    // 列表行选择事件
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    getSummaryTask: function getSummaryTask() {
      var _this4 = this;
      if (this.searchForm && this.searchForm.endOverdueDate) {
        this.searchForm.endOverdueDate = this.searchForm.endOverdueDate.split(' ')[0] + ' 23:59:59';
      }
      if (this.searchForm && this.searchForm.endCreatedDate) {
        this.searchForm.endCreatedDate = this.searchForm.endCreatedDate.split(' ')[0] + ' 23:59:59';
      }
      if (this.searchForm && this.searchForm.endRegisterDate) {
        this.searchForm.endRegisterDate = this.searchForm.endRegisterDate.split(' ')[0] + ' 23:59:59';
      }
      this.searchForm.taskTabType = this.searchForm.taskTabType.toString();
      var data = _objectSpread({
        pageNum: this.listQuery.pageNum,
        pageSize: this.listQuery.pageSize
      }, this.searchForm);
      customersRequest.getSummaryTask(data).then(function (res) {
        if (res.code == 200) {
          for (var i in res.data) {
            var _iterator2 = _createForOfIteratorHelper(_this4.tablist),
              _step2;
            try {
              for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
                var j = _step2.value;
                if (i == j.activeId) {
                  j.Invalid = res.data[i];
                  _this4.$forceUpdate();
                }
              }
            } catch (err) {
              _iterator2.e(err);
            } finally {
              _iterator2.f();
            }
          }
          _this4.OverdueTrack = res.data.OverdueTrack;
          _this4.Subscribe = res.data.Subscribe;
        }
      });
    },
    // 获取列表数据
    loadData: function loadData() {
      var _this5 = this;
      var isDialogNoRefresh = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.showDistributionDialog = false;
      this.showAddTracksDialog = false;
      if (isDialogNoRefresh) return;
      if (this.searchForm && this.searchForm.endOverdueDate) {
        this.searchForm.endOverdueDate = this.searchForm.endOverdueDate.split(' ')[0] + ' 23:59:59';
      }
      if (this.searchForm && this.searchForm.endCreatedDate) {
        this.searchForm.endCreatedDate = this.searchForm.endCreatedDate.split(' ')[0] + ' 23:59:59';
      }
      if (this.searchForm && this.searchForm.endRegisterDate) {
        this.searchForm.endRegisterDate = this.searchForm.endRegisterDate.split(' ')[0] + ' 23:59:59';
      }
      this.drawer = false;
      this.searchForm.taskTabType = this.searchForm.taskTabType.toString();
      this.searchForm.renewalTaskIds = this.renewalTaskIds;
      this.searchForm.renewalTaskIds = this.searchForm.renewalTaskIds.toString();
      var data = _objectSpread({
        pageNum: this.listQuery.pageNum,
        pageSize: this.listQuery.pageSize
      }, this.searchForm);
      this.loading = true;
      customersRequest.getTaskManageList(data).then(function (res) {
        _this5.loading = false;
        _this5.tableData = res.data.list;
        //总条数
        _this5.total = res.data.page.recordsTotal;
        _this5.getSummaryTask();
      }).catch(function (err) {
        _this5.loading = false;
      });
    }
  }
};