import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import userCenterApi from '@/api/userCenter';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    currentId: {
      type: String,
      default: ''
    },
    typeList: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      form: {
        netUserId: '',
        netUserTypeIdList: []
      },
      formLoading: false,
      allNetUserTypeList: []
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
      ;
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    // 初始化数据
    initData: function initData() {
      var _this = this;
      this.getAllNetUserTypeList();
      this.form = {
        netUserId: this.currentId,
        netUserTypeIdList: []
      };
      this.typeList.forEach(function (ele) {
        _this.form.netUserTypeIdList.push(ele.netUserTypeId);
      });
      this.allNetUserTypeList = [];
    },
    // 获取所有启用的客户类型
    getAllNetUserTypeList: function getAllNetUserTypeList() {
      var _this2 = this;
      this.formLoading = true;
      userCenterApi.getAllNetUserTypeList().then(function (res) {
        _this2.formLoading = false;
        if (res.code === 200) {
          _this2.allNetUserTypeList = res.data || [];
        }
        ;
      }).catch(function (err) {
        _this2.formLoading = false;
      });
    },
    handleCheckedChange: function handleCheckedChange(val) {
      console.log(val, 'val');
    },
    // 保存数据
    save: function save() {
      var _this3 = this;
      this.formLoading = true;
      userCenterApi.updateNetUserLabel(this.form).then(function (res) {
        _this3.formLoading = false;
        if (res.code === 200) {
          _this3.$message.success(res.msg);
          _this3.closePage(false);
        }
        ;
      }).catch(function (err) {
        _this3.formLoading = false;
      });
    },
    // 关闭弹窗
    closePage: function closePage() {
      var boolean = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      this.$emit('closePage', boolean);
    }
  }
};