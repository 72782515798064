var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", [_vm._t("header")], 2),
    _c(
      "div",
      {
        style: {
          "max-height": _vm.pageHeight,
          overflowY: "auto",
          overflowX: "hidden",
        },
      },
      [_vm._t("main")],
      2
    ),
    _c("div", [_vm._t("footer-btn")], 2),
    _c(
      "div",
      { staticStyle: { "min-height": "30px", "border-top": "1px solid #eee" } },
      [_vm._t("footer")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }