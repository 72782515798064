import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.splice.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '../../mixins/initHeight';
import dict from '../../mixins/dict';
import Dialog from '@/components/Dialog';
import Pagination from '@/components/Pagination';
import request from '../../api/insurancerisk';
import commonRequest from '../../api/common';
import SYX_RISK from '@/assets/json/SYX_RISK';
import dictRequest from '@/api/dictionary';
import { limitInput } from '@/utils/common';
export default {
  name: 'InsuranceTypeConfig',
  components: {
    Pagination: Pagination,
    Dialog: Dialog
  },
  mixins: [initHeight, dict],
  computed: {},
  data: function data() {
    return {
      drawer: false,
      searchForm: {
        riskType: '',
        riskkindShortName: '',
        isMaster: null,
        enabled: null,
        energyType: ''
      },
      formLoading: false,
      inputVisible: false,
      inputValue: '',
      rules: {
        riskkindName: [{
          required: true,
          message: '请输入险别全称',
          trigger: ['blur', 'change']
        }],
        riskkindShortName: [{
          required: true,
          message: '请输入险别简称',
          trigger: ['blur', 'change']
        }],
        riskkindCode: [{
          required: true,
          message: '请选择险别',
          trigger: 'change'
        }],
        isMaster: [{
          required: true,
          message: '请选择是否主险',
          trigger: 'change'
        }],
        amountUnit: [{
          required: true,
          message: '请选择保额单位',
          trigger: 'change'
        }],
        energyType: [{
          required: true,
          message: '请选择能源类型',
          trigger: 'change'
        }]
      },
      form: {
        riskType: '1',
        isMaster: false,
        enabled: false,
        hasInsuranceAmount: false,
        unitAmountOptions: [],
        riskkindShortName: '',
        energyType: '',
        // isNewEnergy:false,
        // newEnergy:'',
        sort: 0
      },
      showDialog: false,
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      multipleSelection: [],
      opType: '',
      allParentDuty: [],
      allclause: [],
      isMainDuty: false,
      allclauseItems: [],
      dutyAllCode: [],
      dutyItem: {},
      isClick: false,
      loading: false,
      newEnergyData: []
    };
  },
  created: function created() {
    this.loadData();
    this.getAllParentDuty();
    this.getriskkindCode();
  },
  mounted: function mounted() {
    var _this = this;
    //获取新能源状态
    dictRequest.getDictItemsByCode("ENERGY_TYPE").then(function (res) {
      if (res.data) {
        _this.newEnergyData = res.data;
      }
    });
  },
  methods: {
    breakData: function breakData() {
      // this.resetSearch();
      this.loadData();
    },
    changeAmount: function changeAmount(val) {
      this.form.hasInsuranceAmount = val;
      this.$forceUpdate();
    },
    onInput: function onInput(num, min, max, limit) {
      //对输入内容做精度校验
      if (min && num < min) {
        num = min;
      }
      if (max && num > max) {
        num = max;
      }
      return limitInput(num, limit);
    },
    getriskkindCode: function getriskkindCode() {
      //获取商业险险别
      this.dutyAllCode = SYX_RISK;
    },
    splitStr: function splitStr(val) {
      //分割字符串
      var arr = [];
      if (val) {
        var _arr = val.split('|');
        var sum = '';
        for (var i = 0; i <= _arr.length - 1; i++) {
          //a.将 数组中的 元素 加入 字符串
          if (_arr[i]) {
            if (i == [_arr.length - 2]) {
              sum += _arr[i];
            } else {
              sum += _arr[i] + ',';
            }
          }
        }
        return sum;
      }
    },
    inputriskkindCode: function inputriskkindCode(val) {
      var _this2 = this;
      //监听险别变化
      if (val) {
        this.dutyAllCode.forEach(function (item) {
          if (val == item.dictCode) {
            _this2.form.riskkindName = item.dictName;
            _this2.form.riskkindShortName = item.simpleName;
          }
        });
        this.form.riskkindCode = _.toUpper(this.form.riskkindCode);
      } else {
        this.form.riskkindName = '';
        this.form.riskkindShortName = '';
      }
    },
    selectMasterDuty: function selectMasterDuty(val) {
      //选择主险
      this.isMainDuty = val;
      if (val) this.form.masterCodes = [];
    },
    removeItems: function removeItems(item) {
      var _this3 = this;
      //删除
      var ids = [];
      if (!_.isEmpty(item)) {
        ids.push(item.riskkindId);
      } else {
        if (!_.isEmpty(this.multipleSelection)) {
          ids = _.map(this.multipleSelection, 'riskkindId');
        }
      }
      if (_.isEmpty(ids)) return;
      this.$confirm('是否确认删除险别项？确认删除后不能恢复。', '温馨提示', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        type: 'warning'
      }).then(function () {
        request.delete(ids).then(function (res) {
          if (res.code == RESPONSE_SUCCESS) {
            _this3.$message({
              type: 'success',
              message: res.msg
            });
            _this3.loadData();
          }
        });
      });
    },
    getAllParentDuty: function getAllParentDuty() {
      var _this4 = this;
      //获取险种列表
      commonRequest.getAllParentDuty().then(function (res) {
        if (res.code == RESPONSE_SUCCESS) {
          _this4.allParentDuty = [];
          res.data.forEach(function (item) {
            var obj = {
              value: item.riskkindCode,
              label: item.riskkindShortName
            };
            _this4.allParentDuty.push(obj);
          });
        }
      });
    },
    viewDetail: function viewDetail(type, item) {
      var _this5 = this;
      //查看详情
      this.opType = type;
      request.getDetail(item.riskkindId).then(function (res) {
        if (res.data && res.code == RESPONSE_SUCCESS) {
          _this5.form = res.data;
          _this5.showDialog = true;
        }
      }).catch(function (err) {});
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    showInput: function showInput() {
      var _this6 = this;
      //新增输入框
      this.inputVisible = true;
      this.$nextTick(function (_) {
        _this6.$refs.saveTagInput.$refs.input.focus();
      });
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {
        riskType: '',
        riskkindShortName: '',
        isMaster: null,
        enabled: null,
        energyType: ''
      };
    },
    handleInputConfirm: function handleInputConfirm() {
      //输入框事件
      var newInputValue = this.inputValue;
      if (newInputValue) {
        this.form.unitAmountOptions.push(newInputValue);
      }
      this.inputVisible = false;
      this.inputValue = '';
    },
    addItem: function addItem() {
      //新增
      this.form = {
        riskType: '1',
        isMaster: false,
        enabled: false,
        hasInsuranceAmount: false,
        unitAmountOptions: [],
        riskkindShortName: '',
        energyType: 'NOT_NEW_ENERGY',
        // isNewEnergy:false,
        sort: 0
      };
      this.opType = '';
      this.showDialog = true;
    },
    handleTagClose: function handleTagClose(tag) {
      //tag关闭事件
      this.form.unitAmountOptions.splice(this.form.unitAmountOptions.indexOf(tag), 1);
    },
    handleRowChange: function handleRowChange(row, event, column) {
      //列表行点击事件
      this.$refs.multipleTable.toggleRowSelection(row); // 点击选中
    },
    loadData: function loadData() {
      var _this7 = this;
      //获取列表数据
      this.loading = true;
      this.drawer = false;
      this.tableData = [];
      request.getList(this.listQuery.pageNum, this.listQuery.pageSize, this.searchForm).then(function (res) {
        _this7.loading = false;
        if (!_.isEmpty(res.data)) {
          _this7.tableData = res.data.list;
          //总条数
          _this7.total = res.data.page.recordsTotal;
        }
      }).catch(function (err) {
        _this7.loading = false;
      });
    },
    submit: function submit() {
      var _this8 = this;
      // 验证表单
      this.$refs['form'].validate(function (valid) {
        if (valid) {
          _this8.isClick = true;
          _this8.formLoading = true;
          var localRequest = _this8.opType == 'update' ? request.update(_this8.form.riskkindId, _this8.form) : request.create(_this8.form);
          localRequest.then(function (res) {
            _this8.isClick = false;
            _this8.formLoading = false;
            if (res.code == RESPONSE_SUCCESS) {
              _this8.$message({
                type: 'success',
                message: res.msg
              });
              _this8.showDialog = false;
              _this8.$refs['form'].resetFields();
              _this8.loadData();
            }
          }).catch(function (err) {
            _this8.formLoading = false;
            _this8.isClick = false;
          });
        }
      });
    },
    updateItem: function updateItem(item) {
      var _this9 = this;
      //编辑
      var postData = {
        enabled: item.enabled
      };
      if (item.isClick) return;
      item.isClick = true;
      request.update(item.riskkindId, postData).then(function (res) {
        item.isClick = false;
        if (res.code == RESPONSE_SUCCESS) {
          _this9.$message({
            type: 'success',
            message: res.msg
          });
          _this9.showDialog = false;
          _this9.loadData();
        } else {
          item.enabled = false;
        }
      }).catch(function (err) {
        item.isClick = false;
        item.enabled = false;
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      //列表行选择事件
      this.multipleSelection = val;
    },
    cancel: function cancel() {
      //关闭弹窗
      this.form = {
        riskType: '1',
        isMaster: false,
        enabled: false,
        hasInsuranceAmount: false,
        unitAmountOptions: [],
        riskkindShortName: ''
      };
      this.$refs['form'].resetFields();
      this.showDialog = false;
    }
  }
};