import "core-js/modules/es.object.keys.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import recognitionApi from '@/api/recognition';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    invokeMonitorId: {
      type: String,
      default: {}
    }
  },
  data: function data() {
    return {
      form: {},
      formLoading: false
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
      ;
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    // 剪贴板回调函数
    onCopy: function onCopy(e) {
      this.$message({
        type: 'success',
        message: '跟踪ID复制成功！'
      });
    },
    // 剪贴板回调函数
    onError: function onError(e) {
      this.$message({
        type: 'error',
        message: '跟踪ID复制失败！'
      });
    },
    // 初始化数据
    initData: function initData() {
      var _this = this;
      if (!this.invokeMonitorId) return;
      this.formLoading = true;
      recognitionApi.getPolicyIdentifyMonitorDetail({
        id: this.invokeMonitorId
      }).then(function (res) {
        _this.formLoading = false;
        if (res.code == 200 && res.data) {
          _this.form = res.data;
          if (res.data.result) {
            try {
              _this.form.result = JSON.parse(res.data.result);
            } catch (error) {
              _this.form.result = '';
            }
          }
        }
      }).catch(function (err) {
        _this.formLoading = false;
      });
    },
    closePage: function closePage(needRefresh) {
      //关闭弹窗
      this.$emit('closePage', {
        needRefresh: needRefresh
      });
    }
  }
};