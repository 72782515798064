import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import dict from '@/mixins/dict';
import Pagination from '@/components/Pagination';
import userCenterApi from '@/api/userCenter';
import { character, commonExport } from '@/utils/common';
export default {
  name: 'VehicleManagement',
  components: {
    Pagination: Pagination
  },
  mixins: [initHeight, dict],
  data: function data() {
    return {
      drawer: false,
      searchForm: {},
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      loading: false,
      showDialog: false,
      opType: "",
      vehicleForm: {},
      vehicleFormLoading: false,
      netUserVehicleId: null
    };
  },
  watch: {
    showDialog: function showDialog(val) {
      if (val) {
        this.getVehicleById();
      }
      ;
    }
  },
  created: function created() {
    this.loadData();
  },
  methods: {
    // 导出
    exportExcels: function exportExcels() {
      var exporParams = {
        total: this.total,
        fileName: '车辆管理',
        exportUrl: '/vi-vehicle-service/netUser/pc/vehicle/search',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    // 获取详情
    getVehicleById: function getVehicleById() {
      var _this = this;
      this.vehicleFormLoading = true;
      userCenterApi.getVehicleById(this.netUserVehicleId).then(function (res) {
        _this.vehicleFormLoading = false;
        if (res.code == 200) {
          _this.vehicleForm = res.data;
        }
        ;
      });
    },
    // 去除车架号中的i、o、q
    checkValue: function checkValue(val) {
      if (val) {
        val = val.replace(/i/g, '').replace(/I/g, '').replace(/o/g, '').replace(/O/g, '').replace(/q/g, '').replace(/Q/g, '');
      }
      this.searchForm.vin = character(_.toUpper(val));
    },
    // 自动将车牌号码转为大写
    inputPlateNo: function inputPlateNo(val) {
      this.searchForm.plateNo = _.toUpper(val);
    },
    breakData: function breakData() {
      this.loadData();
    },
    // 编辑
    viewDetail: function viewDetail(type, item) {
      this.opType = type;
      this.netUserVehicleId = item.netUserVehicleId;
      this.showDialog = true;
    },
    // 关闭弹窗
    closePage: function closePage() {
      this.showDialog = false;
    },
    // 重置搜索表单
    resetSearch: function resetSearch() {
      this.searchForm = {};
    },
    // 搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    // 获取列表数据
    loadData: function loadData() {
      var _this2 = this;
      this.loading = true;
      this.drawer = false;
      this.showDialog = false;
      userCenterApi.getList(_objectSpread(_objectSpread({}, this.listQuery), this.searchForm)).then(function (res) {
        _this2.loading = false;
        if (res.code === 200) {
          _this2.tableData = res.data.list;
          _this2.total = res.data.page.recordsTotal;
        }
        ;
      }).catch(function (err) {
        _this2.loading = false;
      });
    },
    // 跳转用户管理详情
    quoteDetail: function quoteDetail(row) {
      this.$router.push({
        path: '/userCenter/userManagementDetail',
        query: {
          netUserId: row.netUserId,
          opType: 'view',
          tabName: row.ownerName ? "\u7528\u6237\u7BA1\u7406\u8BE6\u60C5-".concat(row.ownerName) : ''
        }
      });
    }
  }
};