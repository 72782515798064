import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import dict from '@/mixins/dict';
import Pagination from '@/components/Pagination';
import NonAutoRequest from '@/api/nonAutoRequest';
import { commonExport } from '@/utils/common';
export default {
  name: 'auditRecords',
  components: {
    Pagination: Pagination
  },
  mixins: [initHeight, dict],
  computed: {},
  data: function data() {
    return {
      timeoutForExport: null,
      timeoutForRefresh: null,
      searchForm: {},
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      loading: false
    };
  },
  mounted: function mounted() {
    this.loadData();
  },
  methods: {
    //获取列表数据
    loadData: function loadData() {
      var _this = this;
      this.loading = true;
      var data = _objectSpread(_objectSpread({}, this.listQuery), this.searchForm);
      NonAutoRequest.getInsCorpAuditList(data).then(function (res) {
        _this.loading = false;
        if (res.code == 200) {
          _this.tableData = res.data.list ? res.data.list : [];
          _this.total = res.data.page ? res.data.page.recordsTotal : 0;
        } else {
          _this.tableData = [];
          _this.total = 0;
        }
      });
    },
    // 导出防抖
    exportFn: function exportFn() {
      var _this2 = this;
      if (this.timeoutForExport) {
        clearTimeout(this.timeoutForExport);
      }
      this.timeoutForExport = setTimeout(function () {
        _this2.exportFn1();
      }, 500);
    },
    // 导出
    exportFn1: function exportFn1() {
      var exporParams = {
        total: this.total,
        fileName: '注销审核记录',
        exportUrl: '/biz-nonauto-connect-adapter-ftms-service/nonauto/insCorpAudit/search',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    // 刷新防抖
    refreshClicked: function refreshClicked() {
      var _this3 = this;
      if (this.timeoutForRefresh) {
        clearTimeout(this.timeoutForRefresh);
      }
      this.timeoutForRefresh = setTimeout(function () {
        _this3.refreshClicked1();
      }, 500);
    },
    // 刷新
    refreshClicked1: function refreshClicked1() {
      this.searchData();
    },
    // 重新推送
    pushAgainCLicked: function pushAgainCLicked(type, item) {
      var _this4 = this;
      var data = {
        auditRecordId: item && item.auditRecordId ? item.auditRecordId : ''
      };
      NonAutoRequest.againPushInform(data).then(function (res) {
        if (res.code == 200) {
          _this4.$message.success(res.msg);
        }
      });
    },
    //重置
    resetSearch: function resetSearch() {
      this.searchForm = {};
    },
    //搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    }
  }
};