var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.formLoading,
          expression: "formLoading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: { "max-height": "550px" },
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "500px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "detail",
                  staticStyle: { "margin-bottom": "-20px" },
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-width": "115px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "服务名称：",
                                prop: "serviceCode",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: "请选择服务名称",
                                    clearable: "",
                                    filterable: "",
                                  },
                                  on: { change: _vm.changeServiceCode },
                                  model: {
                                    value: _vm.form.serviceCode,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "serviceCode", $$v)
                                    },
                                    expression: "form.serviceCode",
                                  },
                                },
                                _vm._l(_vm.serviceList, function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.itemName,
                                      value: item.itemCode,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "通知方式：",
                                prop: "notifyTypeCode",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: "请选择通知方式",
                                    clearable: "",
                                    filterable: "",
                                  },
                                  on: { change: _vm.changeNotifyTypeCode },
                                  model: {
                                    value: _vm.form.notifyTypeCode,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "notifyTypeCode", $$v)
                                    },
                                    expression: "form.notifyTypeCode",
                                  },
                                },
                                _vm._l(
                                  _vm.notifyTypeList,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.itemName,
                                        value: item.itemCode,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "配置编码：",
                                prop: "notifyConfigCode",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入配置编码",
                                  clearable: "",
                                },
                                on: { input: _vm.inputNotifyConfigCode },
                                model: {
                                  value: _vm.form.notifyConfigCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "notifyConfigCode", $$v)
                                  },
                                  expression: "form.notifyConfigCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "配置名称：",
                                prop: "notifyConfigName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入配置名称",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.notifyConfigName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "notifyConfigName", $$v)
                                  },
                                  expression: "form.notifyConfigName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  !_vm._.isEmpty(_vm.form.notifyConfigParameterList)
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            "margin-top": "15px",
                            "padding-bottom": "15px",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { height: "15px" } },
                            [
                              _c(
                                "el-divider",
                                { attrs: { "content-position": "left" } },
                                [_vm._v(_vm._s(_vm.configName))]
                              ),
                            ],
                            1
                          ),
                          _vm._l(
                            _vm.form.notifyConfigParameterList,
                            function (item, index) {
                              return _c(
                                "el-form-item",
                                {
                                  key: index,
                                  staticStyle: {
                                    "line-height": "40px",
                                    width: "100%",
                                  },
                                  attrs: {
                                    "label-width": "130px",
                                    label: item.display,
                                    prop:
                                      "notifyConfigParameterList." +
                                      index +
                                      ".storeValue",
                                    rules: {
                                      required: item.required,
                                      message: "请输入" + item.display,
                                      trigger: "blur",
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { display: "flex" } },
                                    [
                                      (_vm._.toLower(item.inputType) ==
                                        "text" ||
                                        _vm._.toLower(item.inputType) ==
                                          "email") &&
                                      _vm._.toLower(item.inputType) !=
                                        "number" &&
                                      _vm._.toLower(item.inputType) != "select"
                                        ? _c("el-input", {
                                            attrs: {
                                              maxlength: "40",
                                              clearable: "",
                                              size: "mini",
                                            },
                                            model: {
                                              value: item.storeValue,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item,
                                                  "storeValue",
                                                  $$v
                                                )
                                              },
                                              expression: "item.storeValue",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._.toLower(item.inputType) == "number"
                                        ? _c("el-input", {
                                            attrs: {
                                              maxlength: "40",
                                              clearable: "",
                                              size: "mini",
                                            },
                                            on: {
                                              input: function ($event) {
                                                item.storeValue =
                                                  item.storeValue.replace(
                                                    /[^\d]/g,
                                                    ""
                                                  )
                                              },
                                            },
                                            model: {
                                              value: item.storeValue,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item,
                                                  "storeValue",
                                                  $$v
                                                )
                                              },
                                              expression: "item.storeValue",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._.toLower(item.inputType) ==
                                      "textarea"
                                        ? _c("el-input", {
                                            attrs: {
                                              maxlength: "200",
                                              clearable: "",
                                              size: "mini",
                                              type: "textarea",
                                              rows: 5,
                                            },
                                            model: {
                                              value: item.storeValue,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item,
                                                  "storeValue",
                                                  $$v
                                                )
                                              },
                                              expression: "item.storeValue",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._.toLower(item.inputType) == "select"
                                        ? _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                placeholder: "请选择",
                                                size: "mini",
                                                filterable: "",
                                                clearable: "",
                                              },
                                              model: {
                                                value: item.storeValue,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "storeValue",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.storeValue",
                                              },
                                            },
                                            _vm._l(
                                              item.options,
                                              function (_item, _index) {
                                                return _c("el-option", {
                                                  key: _index,
                                                  attrs: {
                                                    label: _item.name,
                                                    value: _item.value,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._.toLower(item.inputType) == "file"
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: { display: "flex" },
                                            },
                                            [
                                              !item.storeValue
                                                ? _c(
                                                    "el-upload",
                                                    {
                                                      ref: "upload",
                                                      refInFor: true,
                                                      attrs: {
                                                        "before-remove":
                                                          function () {
                                                            return (item.storeValue =
                                                              "")
                                                          },
                                                        "show-file-list": false,
                                                        limit: 1,
                                                        action: "",
                                                        "http-request":
                                                          function (param) {
                                                            return _vm.uploadFile(
                                                              param,
                                                              item,
                                                              index
                                                            )
                                                          },
                                                        "file-list":
                                                          _vm.fileList,
                                                        accept: ".xlsx",
                                                        "list-type": "text",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            size: "mini",
                                                            plain: "",
                                                          },
                                                        },
                                                        [_vm._v("选择文件")]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _c("div", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "file-box",
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "file-text",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "文件id：" +
                                                                _vm._s(
                                                                  item.storeValue
                                                                )
                                                            ),
                                                          ]
                                                        ),
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-close file-btn",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              item.storeValue =
                                                                ""
                                                            },
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "justify-content": "space-between",
                                      },
                                    },
                                    [
                                      _vm.ENV_TITLE != "prd"
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                color: "#999",
                                                "line-height": "20px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  "[" + item.storeKey + "]"
                                                )
                                              ),
                                            ]
                                          )
                                        : _c("div", {
                                            staticStyle: {
                                              color: "#999",
                                              "line-height": "20px",
                                            },
                                          }),
                                      item.description
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                color: "red",
                                                "line-height": "20px",
                                                "margin-left": "10px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  "[" + item.description + "]"
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              )
                            }
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-check",
                type: "primary",
                plain: "",
                size: "mini",
              },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: {
                click: function ($event) {
                  return _vm.closePage(true)
                },
              },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }