import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import dealerInformation from '@/api/dealerInformation';
import Rules from '@/utils/rules';
import OemSelect from '@/components/OemSelect';
import orgChildrenApi from '@/api/orgChildren';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    opType: {
      type: String,
      default: ''
    },
    dealerId: {
      type: String,
      default: ''
    }
  },
  components: {
    OemSelect: OemSelect
  },
  data: function data() {
    return {
      form: {
        dealerName: '',
        dealerCode: '',
        companyName: '',
        certificateNo: '',
        vehicleBrandId: '',
        detailAddr: '',
        contactName: '',
        contactPhone: '',
        companyPhone: '',
        districtCode: '',
        cityData: [],
        districtData: [],
        dealerType: '',
        dealerOperateMode: '',
        parentDealerCode: ''
      },
      value: true,
      rules: {
        dealerName: [{
          required: true,
          message: '请输入经销商名称',
          trigger: 'blur'
        }],
        dealerCode: [{
          required: true,
          message: '请输入经销商编码',
          trigger: 'blur'
        }],
        companyName: [{
          required: true,
          message: '请输入公司名称',
          trigger: 'blur'
        }],
        certificateNo: [{
          required: true,
          message: '请输入统一社会信用代码',
          trigger: 'blur'
        }],
        cityData: [{
          required: true,
          message: '请选择所在地址',
          trigger: 'blur'
        }],
        detailAddr: [{
          required: true,
          message: '请输入详细地址',
          trigger: 'blur'
        }],
        contactName: [{
          required: true,
          message: '请输入联系人',
          trigger: 'blur'
        }, {
          min: 2,
          max: 50,
          pattern: Rules['NAME'].value,
          message: '联系人格式有误',
          trigger: ['blur', 'change']
        }],
        contactPhone: [{
          required: true,
          message: '请输入联系电话',
          trigger: 'blur'
        }, {
          pattern: Rules['MOBILE_RULE'].value,
          message: '联系电话格式有误',
          trigger: ['blur', 'change']
        }],
        companyPhone: [{
          required: true,
          message: '请输入公司电话',
          trigger: 'blur'
        }, {
          pattern: Rules['LANDLINE_RULE'].value,
          message: '电话格式有误',
          trigger: ['blur', 'change']
        }],
        dealerType: [{
          required: true,
          message: '请选择经销商类型',
          trigger: 'change'
        }],
        dealerOperateMode: [{
          required: true,
          message: '请选择运营模式',
          trigger: 'change'
        }],
        parentDealerCode: [{
          required: true,
          message: '请选择上级经销商',
          trigger: 'change'
        }]
      },
      listLoading: false,
      cityOption: JSON.parse(localStorage.getItem('VI_CityAreaTree')) || [],
      cityList: JSON.parse(localStorage.getItem('VI_CityPcTree')) || [],
      dealerTypeOption: [{
        value: 'LEVEL_1',
        label: '一级店'
      }, {
        value: 'LEVEL_2',
        label: '二级店'
      }],
      operateModeOption: [{
        value: 'SELF_OPERATED',
        label: '自营店'
      }, {
        value: 'COOPERATION',
        label: '授权店'
      }],
      parentDealerOption: [],
      orgForm: {
        salesOrgCode: "",
        salesOrgName: ""
      }
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
        this.$refs.detail.resetFields();
      }
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    // 切换机构
    nodeClick: function nodeClick(val) {
      var _this = this;
      this.form.parentDealerCode = val.salesOrgCode;
      orgChildrenApi.dealerQuery(this.form.parentDealerCode).then(function (res) {
        _this.form.cityData = [res.data.provinceCode, res.data.cityCode];
        _this.form.provinceCode = res.data.provinceCode;
        _this.form.provinceName = res.data.provinceName;
        _this.form.cityCode = res.data.cityCode;
        _this.form.cityName = res.data.cityName;
        _this.form.districtData = _this.getDistrictList(_this.cityOption, res.data.cityCode)[0];
        _this.form.districtCode = _this.form.districtData[0].value;
        _this.form.districtName = _this.form.districtData[0].label;
      });
    },
    getDistrictList: function getDistrictList(list, id) {
      var arr = [];
      for (var i = 0; i < list.length; i++) {
        if (list[i].children && list[i].children.length) {
          for (var j = 0; j < list[i].children.length; j++) {
            if (list[i].children[j].value == id) {
              if (list[i].children[j].children) {
                arr.push(list[i].children[j].children);
              }
            }
            ;
          }
          ;
        } else {
          continue;
        }
        ;
      }
      ;
      return arr;
    },
    initData: function initData() {
      var _this2 = this;
      this.orgForm = {
        salesOrgCode: "",
        salesOrgName: ""
      };
      if (this.opType === "add") {
        this.form = {
          dealerName: '',
          dealerCode: '',
          companyName: '',
          vehicleBrandId: '',
          detailAddr: '',
          contactName: '',
          contactPhone: '',
          companyPhone: '',
          districtCode: '',
          cityData: [],
          districtData: [],
          dealerType: '',
          dealerOperateMode: '',
          parentDealerCode: ''
        };
      } else {
        dealerInformation.updateSingle(this.dealerId).then(function (res) {
          if (res.code === 200) {
            _this2.form = res.data;
            _this2.$set(_this2.form, 'cityData', _this2.cityData());
            if (_this2.form.districtCode) {
              _this2.form.districtData = _this2.getDistrictList(_this2.cityOption, res.data.cityCode)[0];
              _this2.$forceUpdate();
            }
            ;
            _this2.orgForm = {
              salesOrgCode: _this2.form.parentDealerCode,
              salesOrgName: _this2.form.parentDealerName
            };
          }
          ;
        });
      }
    },
    // 省市区
    cityData: function cityData() {
      var city = [];
      if (this.form.provinceCode) {
        city.push(this.form.provinceCode);
        if (this.form.cityCode) {
          city.push(this.form.cityCode);
          // if (this.form.districtCode) {
          //     city.push(this.form.districtCode)
          // }
        }
      }

      return city;
    },
    districtChange: function districtChange(val) {
      console.log(val);
      if (!val) return;
      var obj = _.find(this.form.districtData, function (item) {
        return item.value == val;
      });
      console.log(obj);
      if (obj) {
        this.form.districtCode = obj.value;
        this.form.districtName = obj.label;
      }
    },
    save: function save() {
      var _this3 = this;
      //保存数据
      this.$refs.detail.validate(function (valid) {
        if (valid) {
          var req = _this3.opType === 'add' ? dealerInformation.add(_this3.form) : dealerInformation.edit(_this3.dealerId, _this3.form);
          req.then(function (res) {
            if (res.code === 200) {
              _this3.$message({
                message: res.msg,
                type: 'success'
              });
              _this3.closePage();
            }
          });
        }
      });
    },
    //关闭弹窗
    closePage: function closePage() {
      this.form = {};
      this.$emit('closePage');
    },
    areaCodeChange: function areaCodeChange(val) {
      if (!val) return;
      this.form.districtData = [];
      this.form.districtCode = '';
      this.form.districtName = '';
      if (_.isArray(val) && val.length > 0) {
        if (this.getDistrictList(this.cityOption, val[1]) && this.getDistrictList(this.cityOption, val[1]).length != 0) {
          this.form.districtData = this.getDistrictList(this.cityOption, val[1])[0];
          this.form.districtCode = this.form.districtData[0].value;
          this.form.districtName = this.form.districtData[0].label;
          this.$forceUpdate();
        }
        if (this.$refs.addrssCascader.$refs.panel.checkedValue && this.$refs.addrssCascader.$refs.panel.checkedValue != val[2]) {
          // 清空选中的节点
          this.$refs.addrssCascader.$refs.panel.clearCheckedNodes();
          // 设置为空可以让节点不高亮显示
          this.$refs.addrssCascader.$refs.panel.activePath = [];
          this.form.cityData = val;
          this.$forceUpdate();
        }

        //获取地区名称
        var province = _.find(this.cityOption, function (item) {
          return item.value == val[0];
        });
        console.log(province);
        if (province) {
          this.form.provinceCode = province.value;
          this.form.provinceName = province.label;
          var city = _.find(province.children, function (item) {
            return item.value == val[1];
          });
          console.log(city);
          if (city) {
            this.form.cityCode = city.value;
            this.form.cityName = city.label;
            var area = _.find(city.children, function (item) {
              return item.value == val[2];
            });
            // if (area) {
            //     this.form.districtCode = area.value;
            //     this.form.districtName = area.label;
            // };
          }
          ;
        }
        ;
        this.form.cityData = val;
      }
    },
    dealerTypeChange: function dealerTypeChange(val) {
      this.orgForm = {
        salesOrgCode: "",
        salesOrgName: ""
      };
      this.form.parentDealerCode = '';
      this.form.cityData = [];
      this.form.districtData = [];
      this.form.provinceCode = '';
      this.form.provinceName = '';
      this.form.cityCode = '';
      this.form.cityName = '';
      this.form.districtCode = '';
      this.form.districtName = '';
    }
  }
};