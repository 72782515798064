var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.formLoading,
          expression: "formLoading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: {},
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "500px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "addForm",
                  staticStyle: { "margin-bottom": "-20px" },
                  attrs: {
                    model: _vm.addForm,
                    rules: _vm.formRules,
                    "label-width": "100px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "产品编码：",
                                prop: "packSchemeCode",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入产品编码",
                                  disabled: _vm.DialogType == "view",
                                  maxlength: "40",
                                  clearable: "",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.inputvin(
                                      _vm.addForm.packSchemeCode,
                                      "packSchemeCode"
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.addForm.packSchemeCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addForm, "packSchemeCode", $$v)
                                  },
                                  expression: "addForm.packSchemeCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "产品名称：",
                                prop: "packSchemeName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入产品名称",
                                  disabled: _vm.DialogType == "view",
                                  maxlength: "60",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.addForm.packSchemeName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addForm, "packSchemeName", $$v)
                                  },
                                  expression: "addForm.packSchemeName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "保险公司：",
                                prop: "insCorpCode",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    filterable: "",
                                    disabled: _vm.DialogType == "view",
                                    placeholder: "请选择保险公司",
                                    clearable: "",
                                  },
                                  on: { change: _vm.getAccidentType },
                                  model: {
                                    value: _vm.addForm.insCorpCode,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.addForm, "insCorpCode", $$v)
                                    },
                                    expression: "addForm.insCorpCode",
                                  },
                                },
                                _vm._l(_vm.supplyers, function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.shortName,
                                      value: item.code,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "品牌车型：", prop: "modelName" },
                            },
                            [
                              _c("CarBrands", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  widthNum: "100%",
                                  carTypeValue: _vm.carTypeVal,
                                },
                                on: { carHandleChanged: _vm.CarHandleChange },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "实际价格：",
                                prop: "actualPrice",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入实际价格",
                                  maxlength: "60",
                                  disabled: "",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.addForm.actualPrice,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addForm, "actualPrice", $$v)
                                  },
                                  expression: "addForm.actualPrice",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "优惠价格：",
                                prop: "preferentialPrice",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入优惠价格",
                                  maxlength: "60",
                                  disabled: "",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.addForm.preferentialPrice,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.addForm,
                                      "preferentialPrice",
                                      $$v
                                    )
                                  },
                                  expression: "addForm.preferentialPrice",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "适用类型：" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    filterable: "",
                                    placeholder: "请选择适用类型",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.addForm.suitableType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.addForm, "suitableType", $$v)
                                    },
                                    expression: "addForm.suitableType",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "新车", value: "NEW" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "车险类型：",
                                prop: "insuranceType",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    filterable: "",
                                    placeholder: "请选择车险类型",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.addForm.insuranceType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.addForm,
                                        "insuranceType",
                                        $$v
                                      )
                                    },
                                    expression: "addForm.insuranceType",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: {
                                      label: "固定保费",
                                      value: "FIXED_PREMIUM",
                                    },
                                  }),
                                  _c("el-option", {
                                    attrs: {
                                      label: "动态保费",
                                      value: "DYNAMIC_PREMIUM",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "支付方式：",
                                prop: "paymentMethod",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    filterable: "",
                                    placeholder: "请选择支付方式",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.addForm.paymentMethod,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.addForm,
                                        "paymentMethod",
                                        $$v
                                      )
                                    },
                                    expression: "addForm.paymentMethod",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: {
                                      label: "独立支付",
                                      value: "SPEARATE_PAYMENT",
                                    },
                                  }),
                                  _c("el-option", {
                                    attrs: {
                                      label: "合并支付",
                                      value: "COMBINED_PAYMENT",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "产品封面：" } },
                            [
                              _vm.fileList.length == 0
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "el-upload",
                                        {
                                          ref: "upload",
                                          attrs: {
                                            limit: 1,
                                            "before-remove": function () {
                                              return (_vm.formData = "")
                                            },
                                            action: "",
                                            "show-file-list": false,
                                            "http-request": _vm.uploadFile,
                                            "file-list": _vm.fileList,
                                            accept: "*",
                                            "list-type": "text",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                icon: "el-icon-upload2",
                                                size: "mini",
                                                type: "primary",
                                                plain: "",
                                              },
                                            },
                                            [_vm._v("上传文件")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _c("div", [
                                    _c("img", {
                                      staticStyle: {
                                        width: "100px",
                                        "max-height": "100px",
                                      },
                                      attrs: {
                                        src: _vm.fileList[0].fileUrl,
                                        alt: "",
                                      },
                                    }),
                                  ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "产品说明：",
                                prop: "packSchemeNote",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入产品说明",
                                  disabled: _vm.DialogType == "view",
                                  type: "textarea",
                                  rows: 3,
                                  maxlength: "60",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.addForm.packSchemeNote,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addForm, "packSchemeNote", $$v)
                                  },
                                  expression: "addForm.packSchemeNote",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-divider",
                    {
                      staticStyle: { margin: "5px 0" },
                      attrs: { "content-position": "left" },
                    },
                    [_vm._v("车险报价方案")]
                  ),
                  _c("div", { staticStyle: { width: "100%", height: "20px" } }),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "车险方案：",
                                prop: "insuranceSchemeCode",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    filterable: "",
                                    placeholder: "请选择车险方案",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.addForm.insuranceSchemeCode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.addForm,
                                        "insuranceSchemeCode",
                                        $$v
                                      )
                                    },
                                    expression: "addForm.insuranceSchemeCode",
                                  },
                                },
                                _vm._l(
                                  _vm.insuranceProductsData,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.insuranceSchemeName,
                                        value: item.insuranceSchemeCode,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-divider",
                    {
                      staticStyle: { margin: "5px 0" },
                      attrs: { "content-position": "left" },
                    },
                    [_vm._v("驾意险报价方案")]
                  ),
                  _c("div", { staticStyle: { width: "100%", height: "20px" } }),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "驾意险方案：",
                                prop: "publicInsuranceNo",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    filterable: "",
                                    placeholder: "请选择驾意险方案",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.addForm.publicInsuranceNo,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.addForm,
                                        "publicInsuranceNo",
                                        $$v
                                      )
                                    },
                                    expression: "addForm.publicInsuranceNo",
                                  },
                                },
                                _vm._l(
                                  _vm.drivingProductsData,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.accidentSchemeName,
                                        value: item.accidentSchemeCode,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-divider",
                    {
                      staticStyle: { margin: "5px 0" },
                      attrs: { "content-position": "left" },
                    },
                    [_vm._v("增值服务方案")]
                  ),
                  _c("div", { staticStyle: { width: "100%", height: "20px" } }),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini" },
                              on: { click: _vm.addZZFW },
                            },
                            [_vm._v("添加增值服务")]
                          ),
                        ],
                        1
                      ),
                      _vm.ZZFWData
                        ? _c(
                            "el-col",
                            {
                              staticClass: "insuranceCodeListBox",
                              attrs: { span: 24 },
                            },
                            [
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-table",
                                        {
                                          ref: "multipleTable",
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            size: "mini",
                                            "row-class-name":
                                              _vm.tableRowClassName,
                                            border: "",
                                            data: _vm.ZZFWData,
                                            "header-cell-style": {
                                              background: "#F7F7F7",
                                            },
                                            "highlight-current-row": "",
                                          },
                                        },
                                        [
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "productName",
                                              label: "产品名称",
                                              "min-width": "100",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "productNo",
                                              label: "产品编码",
                                              "min-width": "100",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "productTypeName",
                                              label: "产品类型",
                                              "min-width": "100",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "salesWayDisplay",
                                              label: "销售方式",
                                              "min-width": "100",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "adviceUnitPrice",
                                              label: "保费金额",
                                              align: "right",
                                              "min-width": "100",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "valueaddedSchemePrice",
                                              label: "优惠金额",
                                              align: "right",
                                              "min-width": "100",
                                              "show-overflow-tooltip": "",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c("el-input", {
                                                        model: {
                                                          value:
                                                            scope.row
                                                              .valueaddedSchemePrice,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              scope.row,
                                                              "valueaddedSchemePrice",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "scope.row.valueaddedSchemePrice",
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              3618228666
                                            ),
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "",
                                              label: "操作",
                                              "min-width": "100",
                                              align: "center",
                                              fixed: "right",
                                              "show-overflow-tooltip": "",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            size: "mini",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.DelZZFW(
                                                                scope.row
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("删除")]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              2756772938
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-divider",
                    {
                      staticStyle: { margin: "5px 0" },
                      attrs: { "content-position": "left" },
                    },
                    [_vm._v("赠品服务方案")]
                  ),
                  _c("div", { staticStyle: { width: "100%", height: "20px" } }),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini" },
                              on: { click: _vm.addZP },
                            },
                            [_vm._v("添加赠品服务")]
                          ),
                        ],
                        1
                      ),
                      _vm.ZPData
                        ? _c(
                            "el-col",
                            {
                              staticClass: "insuranceCodeListBox",
                              attrs: { span: 24 },
                            },
                            [
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-table",
                                        {
                                          ref: "multipleTable",
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            size: "mini",
                                            "row-class-name":
                                              _vm.tableRowClassName,
                                            border: "",
                                            data: _vm.ZPData,
                                            "header-cell-style": {
                                              background: "#F7F7F7",
                                            },
                                            "highlight-current-row": "",
                                          },
                                        },
                                        [
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "cardVoucherName",
                                              label: "产品名称",
                                              "min-width": "100",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "cardVoucherCode",
                                              label: "产品编码",
                                              "min-width": "100",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "cardVoucherTypeDisplay",
                                              label: "产品类型",
                                              "min-width": "100",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "quantity",
                                              label: "数量",
                                              "min-width": "100",
                                              "show-overflow-tooltip": "",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c("el-input", {
                                                        model: {
                                                          value:
                                                            scope.row.quantity,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              scope.row,
                                                              "quantity",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "scope.row.quantity",
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              873410578
                                            ),
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "unitPrice",
                                              label: "单价",
                                              "min-width": "100",
                                              align: "right",
                                              "show-overflow-tooltip": "",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c("el-input", {
                                                        model: {
                                                          value:
                                                            scope.row.unitPrice,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              scope.row,
                                                              "unitPrice",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "scope.row.unitPrice",
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              1848891202
                                            ),
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "value",
                                              label: "价值",
                                              "min-width": "100",
                                              align: "right",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "guaranteeSchemePrice",
                                              label: "优惠金额",
                                              "min-width": "100",
                                              align: "right",
                                              "show-overflow-tooltip": "",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c("el-input", {
                                                        model: {
                                                          value:
                                                            scope.row
                                                              .guaranteeSchemePrice,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              scope.row,
                                                              "guaranteeSchemePrice",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "scope.row.guaranteeSchemePrice",
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              3965997035
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("div", { staticStyle: { width: "100%", height: "10px" } }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-check",
                type: "primary",
                plain: "",
                size: "mini",
                disabled: _vm.DialogType == "view",
              },
              on: { click: _vm.saveClicked },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: {
                click: function ($event) {
                  return _vm.closePage(true)
                },
              },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
      _c(
        "Dialog",
        {
          attrs: { title: "查看增值服务" },
          on: {
            handleClose: function ($event) {
              _vm.showZZFWDialog = false
            },
          },
          model: {
            value: _vm.showZZFWDialog,
            callback: function ($$v) {
              _vm.showZZFWDialog = $$v
            },
            expression: "showZZFWDialog",
          },
        },
        [
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _c(
                "el-row",
                {
                  staticStyle: { "margin-top": "20px", "min-height": "800px" },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-table",
                        {
                          ref: "ZZFWmultipleTable",
                          staticStyle: { width: "100%" },
                          attrs: {
                            size: "mini",
                            border: "",
                            data: _vm.ZZFWTableData,
                            "header-cell-style": { background: "#F7F7F7" },
                            "highlight-current-row": "",
                          },
                          on: { "selection-change": _vm.handleSelectionChange },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              type: "selection",
                              width: "55",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "productName",
                              label: "产品名称",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "productNo",
                              label: "产品编码",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "productTypeName",
                              label: "产品类别",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "brandName",
                              label: "品牌",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "carTypeName",
                              label: "车型",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "adviceUnitPrice",
                              label: "零售价",
                              align: "right",
                              "show-overflow-tooltip": "",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    type: "primary",
                    plain: "",
                    icon: "el-icon-check",
                    disabled: _vm.ZZFWSaveDisabled,
                  },
                  on: { click: _vm.ZZFWSaveClicked },
                },
                [_vm._v(" 保存 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", icon: "el-icon-close" },
                  on: {
                    click: function ($event) {
                      _vm.showZZFWDialog = false
                    },
                  },
                },
                [_vm._v(" 取消 ")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "Dialog",
        {
          attrs: { title: "查看赠品服务" },
          on: {
            handleClose: function ($event) {
              _vm.showZPDialog = false
            },
          },
          model: {
            value: _vm.showZPDialog,
            callback: function ($$v) {
              _vm.showZPDialog = $$v
            },
            expression: "showZPDialog",
          },
        },
        [
          _c(
            "div",
            { attrs: { slot: "content" }, slot: "content" },
            [
              _c(
                "el-row",
                {
                  staticStyle: { "margin-top": "20px", "min-height": "800px" },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-table",
                        {
                          ref: "multipleTable",
                          staticStyle: { width: "100%" },
                          attrs: {
                            size: "mini",
                            border: "",
                            data: _vm.ZPTableData,
                            "header-cell-style": { background: "#F7F7F7" },
                            "highlight-current-row": "",
                          },
                          on: {
                            "selection-change": _vm.handleZPSelectionChange,
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              type: "selection",
                              width: "55",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "cardVoucherName",
                              label: "产品名称",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "cardVoucherCode",
                              label: "产品编码",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "cardVoucherTypeDisplay",
                              label: "产品类别",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "validityDay",
                              label: "有效期(天)",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "equities",
                              label: "权益说明",
                              "show-overflow-tooltip": "",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    type: "primary",
                    plain: "",
                    icon: "el-icon-check",
                  },
                  on: { click: _vm.ZPSave },
                },
                [_vm._v(" 保存 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", icon: "el-icon-close" },
                  on: {
                    click: function ($event) {
                      _vm.showZPDialog = false
                    },
                  },
                },
                [_vm._v(" 取消 ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }