import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import dict from '@/mixins/dict';
import Pagination from '@/components/Pagination';
import DatePicker from '@/components/DatePicker';
import OrgSelect from '@/components/OrgSelect';
import VehicleSalesDialog from './dialog/vehicleSalesDialog';
import vehicleSalesRequest from '@/api/vehicleSalesMaintain';
import storageRequest from '@/api/storage';
import OemSelect from '@/components/OemSelect';
import CarBrands from '@/components/CarBrands/index';
import { saveFile, character, getUserOrgInfoDealerOem, commonExport, getBeforeDate, getCurrentDate } from '@/utils/common';
export default {
  name: 'VehicleSalesAll',
  components: {
    Pagination: Pagination,
    OrgSelect: OrgSelect,
    VehicleSalesDialog: VehicleSalesDialog,
    OemSelect: OemSelect,
    CarBrands: CarBrands,
    DatePicker: DatePicker
  },
  mixins: [initHeight, dict],
  computed: {},
  data: function data() {
    return {
      carTypeVal: [],
      drawer: false,
      searchForm: {
        vin: '',
        //车架号
        beginSalesDate: getBeforeDate(6),
        //销售开始日期
        endSalesDate: getCurrentDate(0),
        //销售结束日期
        contractNo: '',
        //合同编号
        status: '',
        //车销状态,可用值:ADD,CANCEL	
        beginCancelDate: '',
        //退车开始日期
        endCancelDate: '',
        //退车结束日期
        containOrgCode: '',
        //包含组织机构编码(多个组织机构英文逗号间隔)	
        excludeOrgCode: '',
        //排除组织机构编码(多个组织机构英文逗号间隔)	
        insureStatus: '' //投保状态,可用值:UNINSURED,INSURED	
      },

      // calcTotalCount	是否计算总行数
      orgForm: {
        salesOrgCode: '',
        salesOrgName: '',
        salesOrgType: ''
      },
      uploadOrgForm: {
        salesOrgCode: '',
        salesOrgName: ''
      },
      formRules: {
        ownerOrgCode: [{
          required: true,
          message: '请选择所属机构',
          trigger: 'change'
        }]
      },
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      loading: false,
      multipleSelection: [],
      showDialog: false,
      fileList: [],
      opType: "",
      showAddDialog: false,
      bizType: localStorage.getItem("bizType") || '',
      userInfo: JSON.parse(localStorage.getItem("userInfo")) || {},
      saleObj: {},
      salesStartEndTime: [getBeforeDate(6), getCurrentDate(0)],
      uploadForm: {
        storageId: '',
        fileName: '',
        ownerOrgCode: '',
        ownerOrgName: ''
      },
      importLoading: false,
      insureStatusList: [{
        value: 'INSURED',
        label: '在线出单'
      }, {
        value: 'IMPORT',
        label: '批量导入'
      }, {
        value: 'ADD',
        label: '手工录入'
      }, {
        value: 'UNINSURED',
        label: '未投保'
      }],
      carSalesStatusList: [{
        value: 'ADD',
        label: '已销售'
      }, {
        value: 'CANCEL',
        label: '已退车'
      }],
      cancelStartEndTime: []
    };
  },
  created: function created() {
    if (this.bizType === "DEALER") {
      var orgObj = getUserOrgInfoDealerOem(this.bizType);
      this.searchForm.ownerOrgCode = orgObj.salesOrgCode;
      this.searchForm.ownerOrgName = orgObj.salesOrgName;
      this.uploadForm.ownerOrgCode = orgObj.salesOrgCode;
      this.uploadForm.ownerOrgName = orgObj.salesOrgName;
    }
  },
  mounted: function mounted() {
    this.loadData();
  },
  watch: {
    showDialog: function showDialog(newValue) {
      var _this = this;
      if (newValue) {
        this.fileList = [];
        this.uploadForm = {
          storageId: '',
          fileName: '',
          ownerOrgCode: '',
          ownerOrgName: ''
        };
        this.uploadOrgForm = {
          salesOrgCode: '',
          salesOrgName: ''
        };
        if (this.bizType === "DEALER") {
          var orgObj = getUserOrgInfoDealerOem(this.bizType);
          this.uploadForm.ownerOrgCode = orgObj.salesOrgCode;
          this.uploadForm.ownerOrgName = orgObj.salesOrgName;
        }
        this.$nextTick(function () {
          _this.$refs.detail.resetFields();
        });
      }
    }
  },
  methods: {
    CarHandleChange: function CarHandleChange(val) {
      this.searchForm.cjVhcBrandNo = val[0]; // 厂家品牌编号
      this.searchForm.cjVhcSeriesNo = val[1]; //   厂家车系编号
      this.carTypeVal = val;
      this.$forceUpdate();
    },
    //日期选择事件
    getPickersDate: function getPickersDate(name, val) {
      if (val && val.length > 1) {
        this[name] = val;
      } else {
        this[name] = [];
      }
    },
    // 切换机构
    nodeClick: function nodeClick(val) {
      if (this.bizType === "BROKER") {
        this.searchForm.ownerOrgCode = val.salesOrgCode;
        this.searchForm.ownerOrgName = val.salesOrgName;
      } else if (this.bizType === "OEM") {
        this.searchForm.ownerOrgCode = val.salesOrgCode;
        this.searchForm.ownerOrgName = val.salesOrgName;
        this.searchForm.areaType = val.salesOrgType;
      }
    },
    // 切换机构(上传)
    uploadNodeClick: function uploadNodeClick(val) {
      this.uploadForm.ownerOrgCode = val.salesOrgCode;
      this.uploadForm.ownerOrgName = val.salesOrgName;
    },
    breakData: function breakData() {
      // this.resetSearch()
      this.loadData();
    },
    inputvin: function inputvin(val) {
      //监听输入事件
      if (val) {
        val = val.replace(/i/g, '').replace(/I/g, '').replace(/o/g, '').replace(/O/g, '').replace(/q/g, '').replace(/Q/g, '');
        this.searchForm.vin = character(_.toUpper(val));
        this.$forceUpdate();
      }
    },
    editItem: function editItem(row) {
      this.opType = "edit";
      this.saleObj = row;
      this.showAddDialog = true;
    },
    addItem: function addItem() {
      this.opType = "add";
      this.showAddDialog = true;
    },
    toQuote: function toQuote(item) {
      //报价
      var router_path = this.$route.path;
      this.$router.push({
        path: "/proposal/add",
        query: {
          back: router_path,
          plateNo: item.plateNo,
          vin: item.vin,
          ownerOrgCode: item.ownerOrgCode
        }
      });
    },
    checkValue: function checkValue(val) {
      //输入的车架号中的字母自动转大写，并去除i，o，q
      if (val) {
        val = val.replace(/i/g, '').replace(/I/g, '').replace(/o/g, '').replace(/O/g, '').replace(/q/g, '').replace(/Q/g, '');
      }
      this.searchForm.vin = character(_.toUpper(val));
    },
    //监听上传文件的变化
    handleUploadChange: function handleUploadChange(file, fileList) {
      this.fileList = fileList;
    },
    importExcel: function importExcel() {
      this.showDialog = true;
      this.fileList = [];
    },
    // 上传文件
    uploadFile: function uploadFile(param) {
      var _this2 = this;
      var formData = new FormData();
      formData.append('file', param.file);
      storageRequest.uploadFile(formData).then(function (res) {
        if (res.code === 200) {
          _this2.$message.success(res.msg);
          setTimeout(function () {
            _this2.uploadForm.storageId = res.data.fileStorageId;
            _this2.uploadForm.fileUrl = res.data.fileUrl;
            _this2.uploadForm.fileName = param.file.name;
          }, 1000);
        }
      });
    },
    beforeAvatarUpload: function beforeAvatarUpload(file) {
      //判断文件类型
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      var extension = testmsg === "xls";
      var extension2 = testmsg === "xlsx";
      if (!extension && !extension2) {
        this.$message.error("上传文件只能为xls或xlsx格式!");
        return false;
      }
    },
    downloadTemplate: function downloadTemplate() {
      //下载模板
      vehicleSalesRequest.download().then(function (res) {
        var fileName = "新车销售模板.xlsx";
        var contentType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        saveFile(res, contentType, fileName);
      });
    },
    // 附件校验
    validate: function validate() {
      var flag = true;
      if (!this.uploadForm.storageId) {
        this.$message.warning('请上传文件');
        flag = false;
        return flag;
      }
      return flag;
    },
    saveUpload: function saveUpload() {
      var _this3 = this;
      this.$refs["detail"].validate(function (valid) {
        if (valid) {
          if (!_this3.validate()) return;
          _this3.importLoading = true;
          vehicleSalesRequest.importList(_this3.uploadForm).then(function (res) {
            _this3.importLoading = false;
            if (res.code === 200) {
              _this3.fileList = [];
              _this3.uploadForm = {
                storageId: '',
                fileName: '',
                ownerOrgCode: '',
                ownerOrgName: ''
              };
              _this3.$message.success(res.msg);
              _this3.loadData();
            }
          }, function (err) {}).catch(function (err) {
            _this3.importLoading = false;
          });
        }
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      // 列表行单选事件
      this.multipleSelection = val;
    },
    removeItems: function removeItems(item) {
      var _this4 = this;
      //删除列表数据
      var ids = [];
      if (!_.isEmpty(item)) {
        ids.push(item.salesId);
      } else {
        if (!_.isEmpty(this.multipleSelection)) {
          ids = _.map(this.multipleSelection, 'salesId');
        }
      }
      if (_.isEmpty(ids)) return;
      this.$confirm("\u662F\u5426\u786E\u8BA4\u5220\u9664".concat(!_.isEmpty(item) ? '该条记录' : '所选中的记录', ", \u786E\u8BA4\u5220\u9664\u540E\u4E0D\u80FD\u6062\u590D\u3002"), '温馨提示', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        type: 'warning'
      }).then(function () {
        vehicleSalesRequest.delete(ids).then(function (res) {
          if (res.code == RESPONSE_SUCCESS) {
            _this4.$message({
              type: 'success',
              message: res.msg
            });
            _this4.loadData();
          }
        });
      }).catch(function () {});
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {
        ownerName: '',
        ownerMobile: '',
        vin: '',
        ownerOrgCode: this.bizType === "DEALER" ? this.searchForm.ownerOrgCode : "",
        ownerOrgName: this.bizType === "DEALER" ? this.searchForm.ownerOrgName : "",
        salemanName: '',
        salesDate: '',
        beginSalesDate: getBeforeDate(6),
        endSalesDate: getCurrentDate(0),
        status: '',
        beginCancelDate: '',
        endCancelDate: '',
        cjVhcBrandNo: '',
        cjVhcSeriesNo: ''
      };
      this.orgForm = {
        salesOrgCode: '',
        salesOrgName: this.bizType === 'OEM' ? '全部' : '',
        salesOrgType: ''
      };
      this.carTypeVal = [];
      this.salesStartEndTime = [getBeforeDate(6), getCurrentDate(0)];
      this.cancelStartEndTime = [];
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    loadData: function loadData() {
      var _this5 = this;
      //获取列表数据
      this.loading = true;
      this.drawer = false;
      this.showDialog = false;
      this.showAddDialog = false;
      if (this.searchForm.salesOrgCode) {
        this.searchForm.ownerOrgCode = this.searchForm.salesOrgCode;
      }
      if (this.cancelStartEndTime) {
        this.searchForm.beginCancelDate = this.cancelStartEndTime[0] ? this.cancelStartEndTime[0].split(' ')[0] + ' 00:00:00' : null;
        this.searchForm.endCancelDate = this.cancelStartEndTime[1] ? this.cancelStartEndTime[1].split(' ')[0] + ' 23:59:59' : null;
      }
      if (this.salesStartEndTime) {
        this.searchForm.beginSalesDate = this.salesStartEndTime[0] ? this.salesStartEndTime[0].split(' ')[0] + ' 00:00:00' : null;
        this.searchForm.endSalesDate = this.salesStartEndTime[1] ? this.salesStartEndTime[1].split(' ')[0] + ' 23:59:59' : null;
      }
      var data = _objectSpread(_objectSpread({}, this.listQuery), this.searchForm);
      vehicleSalesRequest.getVehicleSalesAll(data).then(function (res) {
        if (res.code === 200) {
          _this5.tableData = res.data.list;
          _this5.loading = false;
          _this5.total = res.data.page.recordsTotal;
        }
      }).catch(function (err) {
        _this5.loading = false;
      });
    },
    // 详情
    viewDetail: function viewDetail(item) {
      this.$router.push({
        path: '/vehicleSalesDetail',
        query: {
          salesId: item.salesId,
          plateNo: item.plateNo,
          vin: item.vin
        }
      });
    },
    exportExcels: function exportExcels() {
      //导出
      var exporParams = {
        total: this.total,
        limitNum: 200000,
        fileName: '车辆销售清单列表(无隔离)',
        exportUrl: '/vi-vehicle-service/vehicle/sales/all',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    // 刷新承保
    refreshInsured: function refreshInsured(item) {
      var _this6 = this;
      vehicleSalesRequest.refreshInsured(item.vin).then(function (res) {
        if (res.code === 200) {
          _this6.$message.success(res.msg);
          _this6.loadData();
        }
      });
    }
  }
};