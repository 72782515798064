import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.join.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FormLabel from '@/components/FormSubLabel';
import storageRequest from '@/api/storage';
import business from '@/api/insuranceBusiness';
import Rules from '@/utils/rules';
import PageContainer from "@/components/PageContainer";
import { getSupplyers, downFile } from '@/utils/common';
export default {
  components: {
    FormLabel: FormLabel,
    PageContainer: PageContainer
  },
  data: function data() {
    return {
      cityList: JSON.parse(localStorage.getItem('VI_CityAreaTree')) || [],
      form: {
        dealerCode: '',
        dealerName: '',
        provinceCode: '',
        provinceName: '',
        cityCode: '',
        cityName: '',
        districtCode: '',
        districtName: '',
        address: '',
        contactName: '',
        contactPhone: '',
        wxId: '',
        cooperationBusinessType: '',
        insCorpCode: '',
        insCorpName: '',
        fileCheckboxAft08: false,
        file: []
      },
      manageForm: {
        applyIds: [],
        auditStatus: 'AUDIT_SUCCEEDED',
        rejectCause: ''
      },
      attachment: {},
      activeName: '',
      fileList: [],
      name: '',
      applyId: '',
      controlFile: '',
      // 控制保险需要的文件
      applyStatus: '',
      formRules: {
        contactName: [{
          required: true,
          message: '请输入联系人',
          trigger: 'blur'
        }, {
          min: 2,
          max: 50,
          pattern: Rules['NAME'].value,
          message: '联系人格式有误',
          trigger: ['blur', 'change']
        }],
        districtCode: [{
          required: true,
          message: "请选择省市区",
          trigger: "change"
        }],
        address: [{
          required: true,
          message: "请完善详细地址",
          trigger: "blur"
        }],
        contactPhone: [{
          required: true,
          message: '请输入联系方式',
          trigger: 'blur'
        }, {
          pattern: Rules['MOBILE_RULE'].value,
          message: '联系方式格式有误',
          trigger: ['blur', 'change']
        }],
        wxId: [{
          required: true,
          message: '请输入微信号',
          trigger: 'blur'
        }, {
          pattern: Rules['WX'].value,
          message: '微信号格式有误',
          trigger: ['blur', 'change']
        }],
        cooperationBusinessType: {
          required: true,
          message: '请选择合作业务类型',
          trigger: 'change'
        }
      },
      bizType: localStorage.getItem("bizType") || '',
      userInfo: {},
      supplyers: []
    };
  },
  watch: {
    $route: function $route(to, form) {
      this.name = this.$route.query.name ? this.$route.query.name : '';
      this.applyId = this.$route.query.applyId ? this.$route.query.applyId : '';
      console.log(this.name, 'name');
      this.getDetails();
    }
  },
  created: function created() {
    this.supplyers = getSupplyers();
    if (this.bizType === 'DEALER') {
      this.userInfo = JSON.parse(localStorage.getItem("userInfo")) || {};
      this.form.dealerCode = this.userInfo.data.organization.dealerCode;
      this.form.dealerName = this.userInfo.data.organization.dealerName;
      this.form.provinceCode = this.userInfo.data.organization.provinceCode;
      this.form.provinceName = this.userInfo.data.organization.provinceName;
      this.form.cityCode = this.userInfo.data.organization.cityCode;
      this.form.cityName = this.userInfo.data.organization.cityName;
      this.form.districtCode = this.userInfo.data.organization.districtCode;
      this.form.districtName = this.userInfo.data.organization.districtName;
      this.form.address = this.userInfo.data.organization.detailAddr;
      this.form.contactName = this.userInfo.data.organization.contactName;
      this.form.contactPhone = this.userInfo.data.organization.contactPhone;
    }
    this.controlFile = this.$route.query.insCorpCode ? this.$route.query.insCorpCode : '';
    this.form.insCorpCode = this.$route.query.insCorpCode ? this.$route.query.insCorpCode : '';
    this.form.insCorpName = this.$route.query.insCorpName ? this.$route.query.insCorpName : '';
    this.name = this.$route.query.name ? this.$route.query.name : '';
    this.applyId = this.$route.query.applyId ? this.$route.query.applyId : '';
    if (this.applyId) {
      this.getDetails();
      this.manageForm.applyIds = [this.applyId];
    }
  },
  methods: {
    // 获取保司全称
    getInsCorpFullName: function getInsCorpFullName(insCorpCode) {
      var supplyer = _.find(this.supplyers, function (item) {
        return _.toUpper(item.code) == _.toUpper(insCorpCode);
      });
      return supplyer && supplyer.fullName;
    },
    // 省市区监听事件
    handleChange: function handleChange(val) {
      if (!val) return;
      this.form.provinceCode = val[0];
      this.form.cityCode = val[1];
      this.form.districtCode = val[2];
      //获取地区名称
      var regionName = '';
      var province = _.find(this.cityList, function (item) {
        return item.value == val[0];
      });
      if (province) {
        regionName = province.label;
        this.form.provinceName = province.label;
        var city = _.find(province.children, function (item) {
          return item.value == val[1];
        });
        if (city) {
          regionName = regionName + ' ' + city.label;
          this.form.cityName = city.label;
          var area = _.find(city.children, function (item) {
            return item.value == val[2];
          });
          if (area) {
            regionName = regionName + ' ' + area.label;
            this.form.districtName = area.label;
          }
        }
      }
      this.$forceUpdate();
    },
    // 获取详情数据
    getDetails: function getDetails() {
      var _this = this;
      business.query(this.applyId).then(function (res) {
        if (res.code === 200) {
          _this.form = res.data;
          _this.applyStatus = _this.form ? _this.form.applyStatus : '';
          _this.manageForm.rejectCause = _this.form ? _this.form.rejectCause : '';
          var fileList = res.data.fileItemVOS ? res.data.fileItemVOS : [];
          _this.$set(_this.form, 'file', fileList);
        }
      }).catch(function (err) {});
    },
    // 上传文件
    uploadFile: function uploadFile(param, type) {
      var _this2 = this;
      var formData = new FormData();
      formData.append('file', param.file);
      storageRequest.uploadFile(formData).then(function (res) {
        if (res.code === 200) {
          _this2.$message.success(res.msg);
        }
        var obj = {
          contentType: param.file.type,
          fileStorageId: res.data.fileStorageId.toString(),
          fileName: param.file.name,
          fileType: type
        };
        _this2.form.file.push(obj);
      });
    },
    // 点击使用文件地址下载文件
    handle: function handle(row) {
      if (row.fileUrl) {
        downFile(row.fileUrl, row.fileName);
      }
    },
    // 下载授权书模板
    downFileTemplateAFT03: function downFileTemplateAFT03() {
      var _this3 = this;
      storageRequest.downFileTemplate("".concat(this.controlFile, "-AFT03")).then(function (res) {
        if (res.data && res.data.fileStorageId) {
          downFile(res.data.fileUrl, res.data.fileName);
        } else {
          _this3.$message.warning('暂无模板，请联系客服！');
        }
        ;
      });
    },
    downFileTemplateAFT04: function downFileTemplateAFT04() {
      var _this4 = this;
      //下载门店渠道信息收集表
      storageRequest.downFileTemplate("".concat(this.controlFile, "-AFT04")).then(function (res) {
        if (res.data && res.data.fileStorageId) {
          downFile(res.data.fileUrl, res.data.fileName);
        } else {
          _this4.$message.warning('暂无模板，请联系客服！');
        }
        ;
      });
    },
    downFileTemplateAFT05: function downFileTemplateAFT05() {
      var _this5 = this;
      //下载经销商同意使用系统告知书
      storageRequest.downFileTemplate("".concat(this.controlFile, "-AFT05")).then(function (res) {
        if (res.data && res.data.fileStorageId) {
          downFile(res.data.fileUrl, res.data.fileName);
        } else {
          _this5.$message.warning('暂无模板，请联系客服！');
        }
        ;
      });
    },
    downFileTemplateAFT06: function downFileTemplateAFT06() {
      var _this6 = this;
      //下载平台使用需求函
      storageRequest.downFileTemplate("".concat(this.controlFile, "-AFT06")).then(function (res) {
        if (res.data && res.data.fileStorageId) {
          downFile(res.data.fileUrl, res.data.fileName);
        } else {
          _this6.$message.warning('暂无模板，请联系客服！');
        }
        ;
      });
    },
    downFileTemplateAFT10: function downFileTemplateAFT10() {
      var _this7 = this;
      //下载中介机构同意使用系统告知书
      storageRequest.downFileTemplate("".concat(this.controlFile, "-AFT10")).then(function (res) {
        if (res.data && res.data.fileStorageId) {
          downFile(res.data.fileUrl, res.data.fileName);
        } else {
          _this7.$message.warning('暂无模板，请联系客服！');
        }
        ;
      });
    },
    //删除文件
    ondelete: function ondelete(index) {
      this.form.file.splice(index, 1);
    },
    // 附件校验
    validate: function validate() {
      var flag = true;
      if (!this.form.file.length) {
        this.$message.warning('请上传附件');
        flag = false;
        return flag;
      }
      if (this.controlFile !== 'QITA') {
        if (!this.form.file.some(function (val) {
          return val.fileType == 'BUSINESS_LICENSE';
        }) && this.controlFile !== 'PICC') {
          this.$message.warning('请上传本店营业执照');
          flag = false;
          return flag;
        }
        if (!this.form.file.some(function (val) {
          return val.fileType == 'OPERATING_LICENSE';
        }) && (!this.form.fileCheckboxAft08 && this.controlFile === 'CPIC' || !['CPIC', 'PICC', 'TMNF'].includes(this.controlFile))) {
          this.$message.warning('请上传本店兼业代理许可证');
          flag = false;
          return flag;
        }
        if (!this.form.file.some(function (val) {
          return val.fileType == 'AFT05';
        }) && this.controlFile === 'CPIC') {
          this.$message.warning('请上传经销商同意使用系统告知书');
          flag = false;
          return flag;
        }
        if (!this.form.file.some(function (val) {
          return val.fileType == 'AFT08';
        }) && !this.form.fileCheckboxAft08 && this.controlFile === 'CPIC') {
          this.$message.warning('请上传太平洋财险与经销商(本店)的兼业代理合作协议');
          flag = false;
          return flag;
        }
        if (!this.form.file.some(function (val) {
          return val.fileType == 'AFT11';
        }) && this.form.fileCheckboxAft08 && this.controlFile === 'CPIC') {
          this.$message.warning('请上传中介机构营业执照');
          flag = false;
          return flag;
        }
        if (!this.form.file.some(function (val) {
          return val.fileType == 'AFT12';
        }) && this.form.fileCheckboxAft08 && this.controlFile === 'CPIC') {
          this.$message.warning('请上传中介机构保险中介许可证');
          flag = false;
          return flag;
        }
        if (!this.form.file.some(function (val) {
          return val.fileType == 'AFT13';
        }) && this.form.fileCheckboxAft08 && this.controlFile === 'CPIC') {
          this.$message.warning('请上传中介机构与经销商(本店)的代理合作协议');
          flag = false;
          return flag;
        }
        if (!this.form.file.some(function (val) {
          return val.fileType == 'AFT14';
        }) && this.form.fileCheckboxAft08 && this.controlFile === 'CPIC') {
          this.$message.warning('请上传太平洋财险与经销商(本店)的专代代理合作协议');
          flag = false;
          return flag;
        }
        if (!this.form.file.some(function (val) {
          return val.fileType == 'AFT06';
        }) && this.controlFile === 'CPIC') {
          this.$message.warning('请上传平台使用需求函');
          flag = false;
          return flag;
        }
        if (!this.form.file.some(function (val) {
          return val.fileType == 'AUTHORIZATION_CERTIFICATE';
        }) && (this.controlFile === 'PAIC' || this.controlFile === 'PICC')) {
          this.$message.warning('请上传授权证书');
          flag = false;
          return flag;
        }
        if (!this.form.file.some(function (val) {
          return val.fileType == 'STORE_CHANNEL_INFORMATION_TABLE';
        })) {
          this.$message.warning('请上传配置信息收集表');
          flag = false;
          return flag;
        }
      }
      return flag;
    },
    // 保存数据
    save: function save() {
      var _this8 = this;
      this.$refs.detail.validate(function (valid) {
        if (valid) {
          if (!_this8.validate()) return;
          business.create(_this8.form).then(function (res) {
            if (res.code === 200) {
              _this8.$message.success(res.msg);
              _this8.closePage();
            }
          });
        }
      });
    },
    // 保司处理确定按钮
    ondetermine: function ondetermine() {
      var _this9 = this;
      business.update(this.manageForm).then(function (res) {
        if (res.code === 200) {
          _this9.$message.success(res.msg);
          _this9.closePage();
        }
      });
    },
    // 关闭页面
    closePage: function closePage() {
      this.$router.go(-1);
    },
    // 省市区地址拼接
    cityData: function cityData() {
      var city = [];
      if (this.form.provinceName) {
        city.push(this.form.provinceName);
        if (this.form.cityName) {
          city.push(this.form.cityName);
          if (this.form.districtName) {
            city.push(this.form.districtName);
          }
        }
      }
      return city.join('-');
    },
    // 重新申请
    reapply: function reapply(type, item) {
      this.$router.push({
        path: '/submitBusiness',
        query: {
          applyId: item.applyId,
          name: type,
          insCorpCode: item.insCorpCode,
          insCorpName: item.insCorpName
        }
      });
    },
    // 移除文件
    changeVal: function changeVal() {
      for (var i = this.form.file.length - 1; i >= 0; i--) {
        if (["OPERATING_LICENSE", "AFT08", 'AFT10', 'AFT11', 'AFT12', 'AFT13', 'AFT14'].includes(this.form.file[i].fileType)) {
          this.form.file.splice(i, 1);
        }
      }
    }
  }
};