var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
    },
    [
      _c("PageContainer", [
        _c("div", { attrs: { slot: "main" }, slot: "main" }, [
          _c(
            "div",
            {
              staticClass: "app-m-cls",
              staticStyle: { "min-height": "calc(100vh - 130px)" },
            },
            [
              _c(
                "div",
                { ref: "header", staticClass: "header-cls" },
                [
                  _c("el-card", { attrs: { shadow: "never" } }, [
                    _c("div", { staticStyle: { padding: "10px" } }, [
                      _c(
                        "div",
                        [
                          _c("span", { staticStyle: { "font-size": "30px" } }, [
                            _vm._v(_vm._s(_vm.form.productClassName || "-")),
                          ]),
                          _c("el-divider", {
                            attrs: { direction: "vertical" },
                          }),
                          _vm.form.policyProduct &&
                          _vm.form.policyProduct.productName
                            ? _c(
                                "span",
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.form.policyProduct &&
                                          _vm.form.policyProduct.productName
                                          ? _vm.form.policyProduct.productName
                                          : ""
                                      ) +
                                      " "
                                  ),
                                  _c("el-divider", {
                                    attrs: { direction: "vertical" },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("span", [
                            _vm._v(_vm._s(_vm.form.targetOwnerName || "-")),
                          ]),
                          _c("el-divider", {
                            attrs: { direction: "vertical" },
                          }),
                          _c("span", [
                            _vm._v(
                              "保单状态： " +
                                _vm._s(_vm.form.policyStatusDisplay || "-")
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "margin-top": "10px",
                            "font-size": "14px",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { "line-height": "25px" } },
                            [
                              _vm.form.productClassCode == "DBC"
                                ? _c("svg-icon", {
                                    attrs: {
                                      "icon-class": _vm.form.supplierCode
                                        ? _vm.form.supplierCode.split("_")[1] &&
                                          _vm.form.supplierCode
                                            .split("_")[1]
                                            .toLowerCase()
                                        : "-",
                                    },
                                  })
                                : _vm._e(),
                              _vm.form.productClassCode == "DBC"
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getInsCorpName(
                                          _vm.form.supplierCode.split("_")[1] &&
                                            _vm.form.supplierCode
                                              .split("_")[1]
                                              .toLowerCase()
                                        )
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.form.productClassCode == "DBC"
                                ? _c("el-divider", {
                                    attrs: { direction: "vertical" },
                                  })
                                : _vm._e(),
                              _c("span", [
                                _vm._v(
                                  "保单号: " + _vm._s(_vm.form.policyNo || "-")
                                ),
                              ]),
                              _c("el-divider", {
                                attrs: { direction: "vertical" },
                              }),
                              _c("span", [
                                _vm._v(
                                  "生效日期：" +
                                    _vm._s(_vm.form.effectiveDate || "-") +
                                    " "
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticStyle: { "line-height": "25px" } },
                            [
                              _c("span", [
                                _vm._v(
                                  "创建时间：" +
                                    _vm._s(_vm.form.createdTime) +
                                    " "
                                ),
                              ]),
                              _c("el-divider", {
                                attrs: { direction: "vertical" },
                              }),
                              _c("span", [
                                _vm._v(
                                  "更新时间：" +
                                    _vm._s(_vm.form.modifiedTime) +
                                    " "
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm.form && _vm.form.productClassCode == "TCWY"
                    ? _c(
                        "el-card",
                        {
                          staticStyle: { "margin-top": "10px" },
                          attrs: { shadow: "never" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "title",
                              staticStyle: { "line-height": "25px" },
                            },
                            [
                              _c("FormLabel", {
                                attrs: { "form-label": "车辆信息" },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            { attrs: { gutter: 0 } },
                            [
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 12,
                                    sm: 12,
                                    md: 8,
                                    lg: 8,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c("div", { staticClass: "card_item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "card_item_label" },
                                      [_vm._v("车牌号码：")]
                                    ),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.form.targetPlateNo
                                            ? _vm.form.targetPlateNo
                                            : "-"
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 12,
                                    sm: 12,
                                    md: 8,
                                    lg: 8,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c("div", { staticClass: "card_item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "card_item_label" },
                                      [_vm._v("车架号：")]
                                    ),
                                    _c("div", [
                                      _vm._v(_vm._s(_vm.form.targetVin || "-")),
                                    ]),
                                  ]),
                                ]
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 12,
                                    sm: 12,
                                    md: 8,
                                    lg: 8,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c("div", { staticClass: "card_item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "card_item_label" },
                                      [_vm._v("厂家品牌车系：")]
                                    ),
                                    _c(
                                      "div",
                                      { staticStyle: { "text-align": "left" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.form.targetVhcBrandName || "-"
                                          ) +
                                            "/" +
                                            _vm._s(
                                              _vm.form.targetVhcSeriesName ||
                                                "-"
                                            )
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 12,
                                    sm: 12,
                                    md: 8,
                                    lg: 8,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c("div", { staticClass: "card_item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "card_item_label" },
                                      [_vm._v("第一车网品牌车系：")]
                                    ),
                                    _c(
                                      "div",
                                      { staticStyle: { "text-align": "left" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.form.targetCarNetSeriesName ||
                                              "-"
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 12,
                                    sm: 12,
                                    md: 8,
                                    lg: 8,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c("div", { staticClass: "card_item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "card_item_label" },
                                      [_vm._v("购车发票价(元)：")]
                                    ),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.form.targetInvoicePrice
                                            ? _vm.form.targetInvoicePrice.toFixed(
                                                2
                                              )
                                            : "-"
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 12,
                                    sm: 12,
                                    md: 8,
                                    lg: 8,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c("div", { staticClass: "card_item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "card_item_label" },
                                      [_vm._v("使用性质：")]
                                    ),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.form.targetUseAttributeDisplay ||
                                            "-"
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 12,
                                    sm: 12,
                                    md: 8,
                                    lg: 8,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c("div", { staticClass: "card_item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "card_item_label" },
                                      [_vm._v("购车日期：")]
                                    ),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.form.targetVhcSalesDate
                                            ? _vm.form.targetVhcSalesDate.split(
                                                " "
                                              )[0]
                                            : "-"
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c(
                        "el-card",
                        {
                          staticStyle: { "margin-top": "10px" },
                          attrs: { shadow: "never" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "title",
                              staticStyle: { "line-height": "25px" },
                            },
                            [
                              _c("FormLabel", {
                                attrs: { "form-label": "车辆信息" },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            { attrs: { gutter: 0 } },
                            [
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 12,
                                    sm: 12,
                                    md: 8,
                                    lg: 8,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c("div", { staticClass: "card_item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "card_item_label" },
                                      [_vm._v("车牌号码：")]
                                    ),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.form.targetPlateNo
                                            ? _vm.form.targetPlateNo
                                            : "新车"
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 12,
                                    sm: 12,
                                    md: 8,
                                    lg: 8,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c("div", { staticClass: "card_item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "card_item_label" },
                                      [_vm._v("车架号：")]
                                    ),
                                    _c("div", [
                                      _vm._v(_vm._s(_vm.form.targetVin || "-")),
                                    ]),
                                  ]),
                                ]
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 12,
                                    sm: 12,
                                    md: 8,
                                    lg: 8,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c("div", { staticClass: "card_item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "card_item_label" },
                                      [_vm._v("厂家品牌/车系：")]
                                    ),
                                    _vm.form &&
                                    _vm.form.productClassCode != "BZHG"
                                      ? _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "text-align": "left",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.form.targetVhcBrandName ||
                                                  "-"
                                              ) +
                                                "/" +
                                                _vm._s(
                                                  _vm.form
                                                    .targetVhcSeriesName || "-"
                                                )
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "text-align": "left",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.form.targetVhcModelName
                                                  ? _vm.form.targetVhcModelName
                                                  : _vm.form.targetVhcSeriesNo
                                                  ? _vm.form.targetVhcSeriesNo
                                                  : "-"
                                              ) +
                                                "/" +
                                                _vm._s(
                                                  _vm.form.targetVhcModelName
                                                    ? _vm.form
                                                        .targetVhcModelName
                                                    : _vm.form.targetVhcModelNo
                                                    ? _vm.form.targetVhcModelNo
                                                    : "-"
                                                )
                                            ),
                                          ]
                                        ),
                                  ]),
                                ]
                              ),
                              _vm._l(_vm.attributeList, function (item, index) {
                                return _c(
                                  "el-col",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          (_vm.form &&
                                            _vm.form.productClassCode == "SB" &&
                                            (item.attributeName == "三保方案" ||
                                              item.attributeName ==
                                                "保障范围" ||
                                              item.attributeName ==
                                                "行驶里程数" ||
                                              item.attributeName ==
                                                "置换险方案")) ||
                                          (_vm.form &&
                                            _vm.form.productClassCode ==
                                              "DBC" &&
                                            item.attributeName ==
                                              "购车发票日期")
                                            ? false
                                            : true,
                                        expression:
                                          "(form&&form.productClassCode=='SB' && (item.attributeName == '三保方案' || item.attributeName == '保障范围' || item.attributeName == '行驶里程数' || item.attributeName == '置换险方案')) || (form&&form.productClassCode=='DBC'&&item.attributeName == '购车发票日期')?false:true",
                                      },
                                    ],
                                    key: index,
                                    attrs: {
                                      xs: 12,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 6,
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "card_item" }, [
                                      _c(
                                        "div",
                                        { staticClass: "card_item_label" },
                                        [
                                          _vm._v(
                                            _vm._s(item.attributeName) + "："
                                          ),
                                        ]
                                      ),
                                      _vm.form.productClassCode == "BZHG"
                                        ? _c("div", [
                                            item.attributeName == "燃料类型" ||
                                            item.attributeName == "能源类型"
                                              ? _c("div", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.form
                                                          .targetFuelTypeDisplay ||
                                                          "-"
                                                      )
                                                  ),
                                                ])
                                              : item.attributeName == "使用性质"
                                              ? _c("div", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.form
                                                          .targetUseAttributeDisplay ||
                                                          "-"
                                                      )
                                                  ),
                                                ])
                                              : item.attributeName ==
                                                "购车发票日期"
                                              ? _c("div", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        (item.attributeValue &&
                                                          item.attributeValue.split(
                                                            " "
                                                          )[0]) ||
                                                          "-"
                                                      )
                                                  ),
                                                ])
                                              : item.attributeName ==
                                                "新车销售日期"
                                              ? _c("div", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        (item.attributeValue &&
                                                          item.attributeValue.split(
                                                            " "
                                                          )[0]) ||
                                                          "-"
                                                      )
                                                  ),
                                                ])
                                              : item.attributeName ==
                                                "商业险起期"
                                              ? _c("div", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        (item.attributeValue &&
                                                          item.attributeValue.split(
                                                            " "
                                                          )[0] + " 0时") ||
                                                          "-"
                                                      )
                                                  ),
                                                ])
                                              : item.attributeName ==
                                                "商业险止期"
                                              ? _c("div", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        (item.attributeValue &&
                                                          item.attributeValue.split(
                                                            " "
                                                          )[0] + " 24时") ||
                                                          "-"
                                                      )
                                                  ),
                                                ])
                                              : _c("div", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.attributeValue ||
                                                          "-"
                                                      )
                                                  ),
                                                ]),
                                          ])
                                        : _c("div", [
                                            item.attributeName == "使用性质"
                                              ? _c("div", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.form
                                                          .targetUseAttributeDisplay ||
                                                          "-"
                                                      )
                                                  ),
                                                ])
                                              : item.attributeName ==
                                                "购车发票日期"
                                              ? _c("div", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        (item.attributeValue &&
                                                          item.attributeValue.split(
                                                            " "
                                                          )[0]) ||
                                                          "-"
                                                      )
                                                  ),
                                                ])
                                              : item.attributeName ==
                                                "新车销售日期"
                                              ? _c("div", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        (item.attributeValue &&
                                                          item.attributeValue.split(
                                                            " "
                                                          )[0]) ||
                                                          "-"
                                                      )
                                                  ),
                                                ])
                                              : item.attributeName == "车辆类型"
                                              ? _c("div", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.form
                                                          .targetVehicleTypeDisplay ||
                                                          "-"
                                                      )
                                                  ),
                                                ])
                                              : _c("div", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.attributeValue ||
                                                          "-"
                                                      )
                                                  ),
                                                ]),
                                          ]),
                                    ]),
                                  ]
                                )
                              }),
                              _vm.tabCode &&
                              (_vm.tabCode == "XXAX" || _vm.tabCode == "DXWY")
                                ? _c(
                                    "el-col",
                                    {
                                      attrs: {
                                        xs: 12,
                                        sm: 12,
                                        md: 8,
                                        lg: 8,
                                        xl: 6,
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "card_item" }, [
                                        _c(
                                          "div",
                                          { staticClass: "card_item_label" },
                                          [_vm._v("使用性质：")]
                                        ),
                                        _c("div", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.form.targetUseAttributeDisplay
                                                ? _vm.form
                                                    .targetUseAttributeDisplay
                                                : "-"
                                            )
                                          ),
                                        ]),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                  _c(
                    "el-card",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { shadow: "never" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "title",
                          staticStyle: { "line-height": "25px" },
                        },
                        [
                          _c("FormLabel", {
                            attrs: { "form-label": "客户信息" },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 0 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 } },
                            [
                              _c("div", { staticClass: "card_item" }, [
                                _c("div", { staticClass: "card_item_label" }, [
                                  _vm._v("客户名称："),
                                ]),
                                _c(
                                  "div",
                                  { staticStyle: { "text-align": "left" } },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.form.targetOwnerName || "-")
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "el-col",
                            { attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 } },
                            [
                              _c("div", { staticClass: "card_item" }, [
                                _c("div", { staticClass: "card_item_label" }, [
                                  _vm._v("证件类型："),
                                ]),
                                _c(
                                  "div",
                                  { staticStyle: { "text-align": "left" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.form
                                          .targetOwnerCertificateTypeDisplay ||
                                          "-"
                                      )
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "el-col",
                            { attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 } },
                            [
                              _c("div", { staticClass: "card_item" }, [
                                _c("div", { staticClass: "card_item_label" }, [
                                  _vm._v("证件号："),
                                ]),
                                _c(
                                  "div",
                                  { staticStyle: { "text-align": "left" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.form.targetOwnerCertificateNo || "-"
                                      )
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "el-col",
                            { attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 } },
                            [
                              _c("div", { staticClass: "card_item" }, [
                                _c("div", { staticClass: "card_item_label" }, [
                                  _vm._v("联系电话："),
                                ]),
                                _c(
                                  "div",
                                  { staticStyle: { "text-align": "left" } },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.form.targetOwnerMobile || "-")
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "el-col",
                            { attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 } },
                            [
                              _c("div", { staticClass: "card_item" }, [
                                _c("div", { staticClass: "card_item_label" }, [
                                  _vm._v("电子邮箱："),
                                ]),
                                _c(
                                  "div",
                                  { staticStyle: { "text-align": "left" } },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.form.targetOwnerEmail || "-")
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "el-col",
                            { attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 } },
                            [
                              _c("div", { staticClass: "card_item" }, [
                                _c("div", { staticClass: "card_item_label" }, [
                                  _vm._v("所在省市："),
                                ]),
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.form.targetOwnerProviceName
                                        ? _vm.form.targetOwnerProviceName
                                        : "-"
                                    ) +
                                      " " +
                                      _vm._s(
                                        _vm.form.targetOwnerCityName
                                          ? _vm.form.targetOwnerCityName
                                          : "-"
                                      )
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                          _c(
                            "el-col",
                            {
                              attrs: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
                            },
                            [
                              _c("div", { staticClass: "card_item" }, [
                                _c("div", { staticClass: "card_item_label" }, [
                                  _vm._v("详细地址："),
                                ]),
                                _c(
                                  "div",
                                  { staticStyle: { "text-align": "left" } },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.form.targetOwnerAddress || "-")
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.form && _vm.form.productClassCode != "SB"
                    ? _c(
                        "el-card",
                        {
                          staticStyle: { "margin-top": "10px" },
                          attrs: { shadow: "never" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "title",
                              staticStyle: { "line-height": "25px" },
                            },
                            [
                              _c("FormLabel", {
                                attrs: { "form-label": "服务信息" },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            { staticClass: "flexWarp", attrs: { gutter: 0 } },
                            [
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 12,
                                    sm: 12,
                                    md: 8,
                                    lg: 8,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c("div", { staticClass: "card_item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "card_item_label" },
                                      [_vm._v("服务产品编码：")]
                                    ),
                                    _c(
                                      "div",
                                      { staticStyle: { "text-align": "left" } },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.form.productCode || "-")
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 12,
                                    sm: 12,
                                    md: 8,
                                    lg: 8,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c("div", { staticClass: "card_item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "card_item_label" },
                                      [_vm._v("服务产品名称：")]
                                    ),
                                    _c(
                                      "div",
                                      { staticStyle: { "text-align": "left" } },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.form.productName || "-")
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                              _vm.form.productClassCode == "DBC"
                                ? _c(
                                    "el-col",
                                    {
                                      attrs: {
                                        xs: 12,
                                        sm: 12,
                                        md: 8,
                                        lg: 8,
                                        xl: 6,
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "card_item" }, [
                                        _c(
                                          "div",
                                          { staticClass: "card_item_label" },
                                          [_vm._v("累计最多赔偿天数(天)：")]
                                        ),
                                        _c("div", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.form
                                                .targetCumulateMaxCompensationDay
                                                ? _vm.form
                                                    .targetCumulateMaxCompensationDay
                                                : "-"
                                            )
                                          ),
                                        ]),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.form.productClassCode == "DBC"
                                ? _c(
                                    "el-col",
                                    {
                                      attrs: {
                                        xs: 12,
                                        sm: 12,
                                        md: 8,
                                        lg: 8,
                                        xl: 6,
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "card_item" }, [
                                        _c(
                                          "div",
                                          { staticClass: "card_item_label" },
                                          [_vm._v("每次事故最多赔偿天数(天)：")]
                                        ),
                                        _c("div", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.form
                                                .targetAccidentMaxCompensationDay
                                                ? _vm.form
                                                    .targetAccidentMaxCompensationDay
                                                : "-"
                                            )
                                          ),
                                        ]),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.form.productClassCode == "DBC"
                                ? _c(
                                    "el-col",
                                    {
                                      attrs: {
                                        xs: 12,
                                        sm: 12,
                                        md: 8,
                                        lg: 8,
                                        xl: 6,
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "card_item" }, [
                                        _c(
                                          "div",
                                          { staticClass: "card_item_label" },
                                          [_vm._v("最大赔付金额(元)：")]
                                        ),
                                        _c("div", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.form
                                                .targetMaxCompensationAmount
                                                ? _vm.form.targetMaxCompensationAmount.toFixed(
                                                    2
                                                  )
                                                : "-"
                                            )
                                          ),
                                        ]),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 12,
                                    sm: 12,
                                    md: 8,
                                    lg: 8,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c("div", { staticClass: "card_item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "card_item_label" },
                                      [_vm._v("服务保障期限：")]
                                    ),
                                    _c(
                                      "div",
                                      { staticStyle: { "text-align": "left" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.form.productTimeLimit || "-"
                                          ) + " 个月"
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 12,
                                    sm: 12,
                                    md: 8,
                                    lg: 8,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c("div", { staticClass: "card_item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "card_item_label" },
                                      [_vm._v("服务保障起期：")]
                                    ),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.form.startTime
                                            ? _vm.form.startTime.slice(0, 10)
                                            : "-"
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 12,
                                    sm: 12,
                                    md: 8,
                                    lg: 8,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c("div", { staticClass: "card_item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "card_item_label" },
                                      [_vm._v("服务保障止期：")]
                                    ),
                                    _vm.form &&
                                    _vm.form.productClassCode == "YB"
                                      ? _c("div", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.form &&
                                                _vm.form.targetGuaranteeRange ==
                                                  "双擎动力蓄电池"
                                                ? "终身"
                                                : _vm.form.endTime
                                                ? _vm.form.endTime.slice(0, 10)
                                                : "-"
                                            )
                                          ),
                                        ])
                                      : _c("div", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.form.endTime
                                                ? _vm.form.endTime.slice(0, 10)
                                                : "-"
                                            )
                                          ),
                                        ]),
                                  ]),
                                ]
                              ),
                              _vm.form.productClassCode === "YB"
                                ? _c(
                                    "el-col",
                                    {
                                      attrs: {
                                        xs: 12,
                                        sm: 12,
                                        md: 8,
                                        lg: 8,
                                        xl: 6,
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "card_item" }, [
                                        _c(
                                          "div",
                                          { staticClass: "card_item_label" },
                                          [_vm._v("附加赠送：")]
                                        ),
                                        _c("div", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.form.bonusPacksName
                                                ? _vm.form.bonusPacksName
                                                : "-"
                                            )
                                          ),
                                        ]),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 12,
                                    sm: 12,
                                    md: 8,
                                    lg: 8,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c("div", { staticClass: "card_item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "card_item_label" },
                                      [_vm._v("销售方式：")]
                                    ),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.form.salesMethodDisplay
                                            ? _vm.form.salesMethodDisplay
                                            : "-"
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 12,
                                    sm: 12,
                                    md: 8,
                                    lg: 8,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c("div", { staticClass: "card_item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "card_item_label" },
                                      [_vm._v("服务销售价(元)：")]
                                    ),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.form.targetSalesPrice
                                            ? _vm.form.targetSalesPrice.toFixed(
                                                2
                                              )
                                            : "-"
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c(
                        "el-card",
                        {
                          staticStyle: { "margin-top": "10px" },
                          attrs: { shadow: "never" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "title",
                              staticStyle: { "line-height": "25px" },
                            },
                            [
                              _c("FormLabel", {
                                attrs: { "form-label": "服务信息" },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            { staticClass: "flexWarp", attrs: { gutter: 0 } },
                            [
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 12,
                                    sm: 12,
                                    md: 8,
                                    lg: 8,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c("div", { staticClass: "card_item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "card_item_label" },
                                      [_vm._v("服务产品编码：")]
                                    ),
                                    _c(
                                      "div",
                                      { staticStyle: { "text-align": "left" } },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.form.productCode || "-")
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 12,
                                    sm: 12,
                                    md: 8,
                                    lg: 8,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c("div", { staticClass: "card_item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "card_item_label" },
                                      [_vm._v("服务产品名称：")]
                                    ),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(_vm.form.productName || "-")
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 12,
                                    sm: 12,
                                    md: 8,
                                    lg: 8,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c("div", { staticClass: "card_item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "card_item_label" },
                                      [_vm._v("销售方式：")]
                                    ),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.form.salesMethodDisplay
                                            ? _vm.form.salesMethodDisplay
                                            : "-"
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 12,
                                    sm: 12,
                                    md: 8,
                                    lg: 8,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c("div", { staticClass: "card_item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "card_item_label" },
                                      [_vm._v("服务销售价(元)：")]
                                    ),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.form.targetSalesPrice
                                            ? _vm.form.targetSalesPrice.toFixed(
                                                2
                                              )
                                            : "-"
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c("el-col", { attrs: { span: 24 } }, [
                                _c(
                                  "div",
                                  { staticClass: "card_item" },
                                  [
                                    _c(
                                      "el-divider",
                                      { attrs: { "content-position": "left" } },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              color: "#333",
                                              "font-size": "14px",
                                              "font-weight": "600",
                                            },
                                          },
                                          [_vm._v("三保方案")]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 12,
                                    sm: 12,
                                    md: 8,
                                    lg: 8,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c("div", { staticClass: "card_item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "card_item_label" },
                                      [_vm._v("原厂质保公里数：")]
                                    ),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.form.targetOriginalWarrantyMileage
                                            ? _vm.form
                                                .targetOriginalWarrantyMileage
                                            : "-"
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 12,
                                    sm: 12,
                                    md: 8,
                                    lg: 8,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c("div", { staticClass: "card_item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "card_item_label" },
                                      [_vm._v("原厂质保期限(年)：")]
                                    ),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.form.originalWarrantyPeriod
                                            ? _vm.form.originalWarrantyPeriod
                                            : "-"
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 12,
                                    sm: 12,
                                    md: 8,
                                    lg: 8,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c("div", { staticClass: "card_item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "card_item_label" },
                                      [_vm._v("原厂保修起期：")]
                                    ),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.form.originalStartTime
                                            ? _vm.form.originalStartTime.slice(
                                                0,
                                                10
                                              ) + " 0时"
                                            : "-"
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 12,
                                    sm: 12,
                                    md: 8,
                                    lg: 8,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c("div", { staticClass: "card_item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "card_item_label" },
                                      [_vm._v("原厂保修止期：")]
                                    ),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.form.originalEndTime
                                            ? _vm.form.originalEndTime.slice(
                                                0,
                                                10
                                              ) + " 24时"
                                            : "-"
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c("el-col", { attrs: { span: 24 } }, [
                                _c(
                                  "div",
                                  { staticClass: "card_item" },
                                  [
                                    _c(
                                      "el-divider",
                                      { attrs: { "content-position": "left" } },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              color: "#333",
                                              "font-size": "14px",
                                              "font-weight": "600",
                                            },
                                          },
                                          [_vm._v("延保方案")]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 12,
                                    sm: 12,
                                    md: 8,
                                    lg: 8,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c("div", { staticClass: "card_item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "card_item_label" },
                                      [_vm._v("延保保障范围：")]
                                    ),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.form.targetGuaranteeRange
                                            ? _vm.form.targetGuaranteeRange
                                            : "-"
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 12,
                                    sm: 12,
                                    md: 8,
                                    lg: 8,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c("div", { staticClass: "card_item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "card_item_label" },
                                      [_vm._v("延保方案：")]
                                    ),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.form.ternScheme
                                            ? _vm.form.ternScheme
                                            : "-"
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 12,
                                    sm: 12,
                                    md: 8,
                                    lg: 8,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c("div", { staticClass: "card_item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "card_item_label" },
                                      [_vm._v("延保起期：")]
                                    ),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.form.startTime
                                            ? _vm.form.startTime.slice(0, 10) +
                                                " 0时"
                                            : "-"
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 12,
                                    sm: 12,
                                    md: 8,
                                    lg: 8,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c("div", { staticClass: "card_item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "card_item_label" },
                                      [_vm._v("延保止期：")]
                                    ),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.form.endTime
                                            ? _vm.form.extensionScheme == "终身"
                                              ? "终身"
                                              : _vm.form.endTime.slice(0, 10) +
                                                " 24时"
                                            : "-"
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 12,
                                    sm: 12,
                                    md: 8,
                                    lg: 8,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c("div", { staticClass: "card_item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "card_item_label" },
                                      [_vm._v("已行驶里程：")]
                                    ),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.form.targetMileage
                                            ? _vm.form.targetMileage
                                            : "-"
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 12,
                                    sm: 12,
                                    md: 8,
                                    lg: 8,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c("div", { staticClass: "card_item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "card_item_label" },
                                      [_vm._v("延长公里数(万)：")]
                                    ),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.form.targetExtendMileage
                                            ? _vm.form.targetExtendMileage
                                            : "-"
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c("el-col", { attrs: { span: 24 } }, [
                                _c(
                                  "div",
                                  { staticClass: "card_item" },
                                  [
                                    _c(
                                      "el-divider",
                                      { attrs: { "content-position": "left" } },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              color: "#333",
                                              "font-size": "14px",
                                              "font-weight": "600",
                                            },
                                          },
                                          [_vm._v("代步车方案")]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 12,
                                    sm: 12,
                                    md: 8,
                                    lg: 8,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c("div", { staticClass: "card_item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "card_item_label" },
                                      [_vm._v("代步车年限(年)：")]
                                    ),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.form.targetScooterYearLimit ||
                                            _vm.form.targetScooterYearLimit ===
                                              0
                                            ? _vm.form.targetScooterYearLimit
                                            : "-"
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 12,
                                    sm: 12,
                                    md: 8,
                                    lg: 8,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c("div", { staticClass: "card_item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "card_item_label" },
                                      [_vm._v("代步车服务起期：")]
                                    ),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.form.dbcStartTime
                                            ? _vm.form.dbcStartTime.slice(
                                                0,
                                                10
                                              ) + " 0时"
                                            : "-"
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 12,
                                    sm: 12,
                                    md: 8,
                                    lg: 8,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c("div", { staticClass: "card_item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "card_item_label" },
                                      [_vm._v("代步车服务止期：")]
                                    ),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.form.dbcEndTime
                                            ? _vm.form.dbcEndTime.slice(0, 10) +
                                                " 24时"
                                            : "-"
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c("el-col", { attrs: { span: 24 } }, [
                                _c(
                                  "div",
                                  { staticClass: "card_item" },
                                  [
                                    _c(
                                      "el-divider",
                                      { attrs: { "content-position": "left" } },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              color: "#333",
                                              "font-size": "14px",
                                              "font-weight": "600",
                                            },
                                          },
                                          [_vm._v("车险置换方案")]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 12,
                                    sm: 12,
                                    md: 8,
                                    lg: 8,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c("div", { staticClass: "card_item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "card_item_label" },
                                      [_vm._v("补偿限额(万)：")]
                                    ),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.form.limitCompensation
                                            ? _vm.form.limitCompensation
                                            : "-"
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 12,
                                    sm: 12,
                                    md: 8,
                                    lg: 8,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c("div", { staticClass: "card_item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "card_item_label" },
                                      [_vm._v("置换年限(年)：")]
                                    ),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.form.targetScooterYearLimit ||
                                            _vm.form.targetScooterYearLimit ===
                                              0
                                            ? _vm.form.targetScooterYearLimit
                                            : "-"
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 12,
                                    sm: 12,
                                    md: 8,
                                    lg: 8,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c("div", { staticClass: "card_item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "card_item_label" },
                                      [_vm._v("置换服务起期：")]
                                    ),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.form.zhStartTime
                                            ? _vm.form.zhStartTime.slice(
                                                0,
                                                10
                                              ) + " 0时"
                                            : "-"
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 12,
                                    sm: 12,
                                    md: 8,
                                    lg: 8,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c("div", { staticClass: "card_item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "card_item_label" },
                                      [_vm._v("置换服务止期：")]
                                    ),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.form.zhEndTime
                                            ? _vm.form.zhEndTime.slice(0, 10) +
                                                " 24时"
                                            : "-"
                                        )
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                  _c(
                    "el-card",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { shadow: "never" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "title",
                          staticStyle: {
                            "line-height": "25px",
                            display: "flex",
                            "align-items": "center",
                            height: "40px",
                          },
                        },
                        [
                          _c("FormLabel", {
                            staticStyle: {
                              "font-size": "15px",
                              "line-height": "16px",
                            },
                            attrs: { "form-label": "服务协议附件信息" },
                          }),
                          _vm.ProtocolTepmfileList &&
                          _vm.ProtocolTepmfileList.length != 0
                            ? _c(
                                "el-button",
                                {
                                  staticStyle: { height: "28px" },
                                  attrs: {
                                    type: "primary",
                                    size: "mini",
                                    plain: "",
                                  },
                                  on: { click: _vm.batchDownloadContract },
                                },
                                [_vm._v("批量下载")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 0 } },
                        [
                          _vm.ProtocolTepmfileList &&
                          _vm.ProtocolTepmfileList.length != 0
                            ? _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                _vm._l(
                                  _vm.ProtocolTepmfileList,
                                  function (item, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass: "card_FileItem",
                                        staticStyle: { padding: "0 0 0  20px" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "card_file_img",
                                            staticStyle: {
                                              width: "auto",
                                              height: "20px",
                                              cursor: "pointer",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.XYdownloadFiles(
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(item.protocolName)
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm.form.protocolSignDate
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "card_file_label",
                                                staticStyle: {
                                                  "margin-top": "5px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "合同签署时间：" +
                                                    _vm._s(
                                                      _vm.form.protocolSignDate
                                                    )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  }
                                ),
                                0
                              )
                            : _c("el-col", { attrs: { span: 24 } }, [
                                _c("div", { staticClass: "card_FileItem" }, [
                                  _vm._v(" 暂无数据 "),
                                ]),
                              ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  (_vm.filesList && _vm.filesList.length != 0) ||
                  (_vm.form.productClassCode == "TCWY" &&
                    ((_vm.pzFileList && _vm.pzFileList.length != 0) ||
                      (_vm.clFileList && _vm.clFileList.length != 0)))
                    ? _c(
                        "el-card",
                        {
                          staticStyle: { "margin-top": "10px" },
                          attrs: { shadow: "never" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "title",
                              staticStyle: { "line-height": "25px" },
                            },
                            [
                              _c("FormLabel", {
                                staticStyle: {
                                  "font-size": "14px",
                                  "line-height": "16px",
                                },
                                attrs: { "form-label": "附件信息" },
                              }),
                              _vm.downloadlist && _vm.downloadlist.length != 0
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "primary",
                                        size: "mini",
                                        plain: "",
                                      },
                                      on: { click: _vm.batchDownloadFile },
                                    },
                                    [_vm._v("批量下载")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm.form.productClassCode == "TCWY"
                            ? _c(
                                "el-row",
                                { attrs: { gutter: 0 } },
                                [
                                  (_vm.pzFileList &&
                                    _vm.pzFileList.length != 0) ||
                                  (_vm.clFileList && _vm.clFileList.length != 0)
                                    ? _c(
                                        "el-col",
                                        {
                                          staticStyle: { "margin-top": "10px" },
                                          attrs: { span: 24 },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.pzFileList &&
                                                    _vm.pzFileList.length != 0,
                                                  expression:
                                                    "pzFileList&&pzFileList.length!=0",
                                                },
                                              ],
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "font-size": "14px",
                                                    "font-weight": "bold",
                                                  },
                                                },
                                                [_vm._v("车辆凭证：")]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                    "flex-direction": "row",
                                                    "justify-content":
                                                      "flex-start",
                                                    "align-items": "center",
                                                    "flex-wrap": "wrap",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.pzFileList,
                                                  function (fitem, index) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: index,
                                                        staticStyle: {
                                                          width: "100px",
                                                          height: "140px",
                                                          display: "flex",
                                                          "flex-direction":
                                                            "column",
                                                          "justify-content":
                                                            "flex-start",
                                                          "align-items":
                                                            "center",
                                                          "margin-right":
                                                            "10px",
                                                        },
                                                      },
                                                      [
                                                        _vm.getFileSuffix(
                                                          fitem.fileName
                                                        )
                                                          ? _c("el-image", {
                                                              staticStyle: {
                                                                width: "100px",
                                                                height: "100px",
                                                                cursor:
                                                                  "pointer",
                                                              },
                                                              attrs: {
                                                                src: fitem.fileUrl,
                                                                "preview-src-list":
                                                                  [
                                                                    fitem.fileUrl,
                                                                  ],
                                                              },
                                                            })
                                                          : _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  fitem.fileName
                                                                )
                                                              ),
                                                            ]),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              "margin-top":
                                                                "10px",
                                                              "font-size":
                                                                "10px",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                fitem.fileTypeName
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.clFileList &&
                                                    _vm.clFileList.length != 0,
                                                  expression:
                                                    "clFileList&&clFileList.length!=0",
                                                },
                                              ],
                                              staticStyle: {
                                                "margin-top": "10px",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "font-size": "14px",
                                                    "font-weight": "bold",
                                                  },
                                                },
                                                [_vm._v("车辆图片：")]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                    "flex-direction": "row",
                                                    "justify-content":
                                                      "flex-start",
                                                    "align-items": "center",
                                                    "flex-wrap": "wrap",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.clFileList,
                                                  function (fitem, index) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: index,
                                                        staticStyle: {
                                                          width: "100px",
                                                          height: "140px",
                                                          display: "flex",
                                                          "flex-direction":
                                                            "column",
                                                          "justify-content":
                                                            "flex-start",
                                                          "align-items":
                                                            "center",
                                                          "margin-right":
                                                            "10px",
                                                        },
                                                      },
                                                      [
                                                        _vm.getFileSuffix(
                                                          fitem.fileName
                                                        )
                                                          ? _c("el-image", {
                                                              staticStyle: {
                                                                width: "100px",
                                                                height: "100px",
                                                                cursor:
                                                                  "pointer",
                                                              },
                                                              attrs: {
                                                                src: fitem.fileUrl,
                                                                "preview-src-list":
                                                                  [
                                                                    fitem.fileUrl,
                                                                  ],
                                                              },
                                                            })
                                                          : _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  fitem.fileName
                                                                )
                                                              ),
                                                            ]),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              "margin-top":
                                                                "10px",
                                                              "font-size":
                                                                "10px",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                fitem.fileTypeName
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _c("el-col", { attrs: { span: 24 } }, [
                                        _c(
                                          "div",
                                          { staticClass: "card_FileItem" },
                                          [_vm._v(" 暂无数据 ")]
                                        ),
                                      ]),
                                ],
                                1
                              )
                            : _c(
                                "el-row",
                                { attrs: { gutter: 0 } },
                                [
                                  _vm.filesList && _vm.filesList.length != 0
                                    ? _c(
                                        "el-col",
                                        { attrs: { span: 24 } },
                                        _vm._l(
                                          _vm.filesList,
                                          function (item, index) {
                                            return _c(
                                              "div",
                                              {
                                                key: index,
                                                staticClass: "card_FileItem",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "card_file_label",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(item.typeName) +
                                                        "："
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "card_file_img",
                                                    staticStyle: {
                                                      display: "flex",
                                                      "flex-direction": "row",
                                                      "justify-content":
                                                        "flex-start",
                                                      "align-items": "center",
                                                      "flex-wrap": "wrap",
                                                    },
                                                  },
                                                  _vm._l(
                                                    item.fileLists,
                                                    function (fitem, findex) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: findex,
                                                          staticClass:
                                                            "card_file_img_box",
                                                          staticStyle: {
                                                            width: "100px",
                                                            height: "140px",
                                                            display: "flex",
                                                            "flex-direction":
                                                              "column",
                                                            "justify-content":
                                                              "flex-start",
                                                            "align-items":
                                                              "center",
                                                            "margin-right":
                                                              "10px",
                                                          },
                                                        },
                                                        [
                                                          _vm.getFileSuffix(
                                                            fitem.fileName
                                                          )
                                                            ? _c("el-image", {
                                                                staticStyle: {
                                                                  width:
                                                                    "100px",
                                                                  height:
                                                                    "100px",
                                                                  cursor:
                                                                    "pointer",
                                                                },
                                                                attrs: {
                                                                  src: fitem.fileUrl,
                                                                  "preview-src-list":
                                                                    [
                                                                      fitem.fileUrl,
                                                                    ],
                                                                },
                                                              })
                                                            : _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    fitem.fileName
                                                                  )
                                                                ),
                                                              ]),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : _c("el-col", { attrs: { span: 24 } }, [
                                        _c(
                                          "div",
                                          { staticClass: "card_FileItem" },
                                          [_vm._v(" 暂无数据 ")]
                                        ),
                                      ]),
                                ],
                                1
                              ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-card",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { shadow: "never" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "title",
                          staticStyle: { "line-height": "25px" },
                        },
                        [
                          _c("FormLabel", {
                            attrs: { "form-label": "出单信息" },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 0 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 } },
                            [
                              _c("div", { staticClass: "card_item" }, [
                                _c("div", { staticClass: "card_item_label" }, [
                                  _vm._v("出单机构："),
                                ]),
                                _c("div", [
                                  _vm._v(_vm._s(_vm.form.salesOrgName || "-")),
                                ]),
                              ]),
                            ]
                          ),
                          _c(
                            "el-col",
                            { attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 } },
                            [
                              _c("div", { staticClass: "card_item" }, [
                                _c("div", { staticClass: "card_item_label" }, [
                                  _vm._v("业务员："),
                                ]),
                                _c("div", [
                                  _vm._v(_vm._s(_vm.form.salemanName || "-")),
                                ]),
                              ]),
                            ]
                          ),
                          _c(
                            "el-col",
                            { attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 } },
                            [
                              _c("div", { staticClass: "card_item" }, [
                                _c("div", { staticClass: "card_item_label" }, [
                                  _vm._v("操作人："),
                                ]),
                                _c(
                                  "div",
                                  { staticStyle: { "text-align": "left" } },
                                  [_vm._v(_vm._s(_vm.form.creatorName || "-"))]
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ]),
        _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
          _c("div", { staticClass: "footer-btn" }, [
            _c("div", { staticClass: "footer-btn-list" }, [
              _c(
                "div",
                {
                  staticStyle: { display: "flex", "justify-content": "center" },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-close", size: "mini" },
                      on: { click: _vm.back },
                    },
                    [_vm._v("关闭")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }