//
//
//
//
//
//
//
//
//
//
//
//

import noVechileMapStatisticsBoard from './noVechileMapStatisticsBoard.vue';
export default {
  name: 'NonCarMapBoard',
  components: {
    noVechileMapStatisticsBoard: noVechileMapStatisticsBoard
  },
  data: function data() {
    return {
      title: process.env.VUE_APP_TITLE,
      isFullscreen: false,
      fullscreen: false
    };
  },
  beforeRouteLeave: function beforeRouteLeave(to, form, next) {
    var _this = this;
    if (form) {
      this.$nextTick(function () {
        _this.$refs.map2.clearTabTimer();
        _this.$refs.map2.clearGetDataTimer();
      });
      next();
    } else {
      next();
    }
  },
  beforeDestroy: function beforeDestroy() {
    // this.$nextTick(() => {
    this.$refs.map2.clearTabTimer();
    this.$refs.map2.clearGetDataTimer();
    // })
  },

  computed: {},
  mounted: function mounted() {
    var _this2 = this;
    this.$nextTick(function () {
      _this2.$refs.map2.handleRadio('day');
    });
    this.containerH = document.body.clientHeight - 135 + 'px';
    window.onresize = function () {
      if (!_this2.checkFull()) {
        // 退出全屏后要执行的动作
        _this2.isFullscreen = false;
        _this2.containerH = document.body.clientHeight - 135 + 'px';
        _this2.containerH = _this2.isFullscreen ? '95vh' : _this2.containerH;
        _this2.$forceUpdate();
      }
    };
    // this.switchScreen()
  },

  methods: {
    checkFull: function checkFull() {
      //全屏判断状态 取消
      //判断浏览器是否处于全屏状态 （需要考虑兼容问题）
      //火狐浏览器
      var isFull = document.mozFullScreen || document.fullScreen ||
      //谷歌浏览器及Webkit内核浏览器
      document.webkitIsFullScreen || document.webkitRequestFullScreen || document.mozRequestFullScreen || document.msFullscreenEnabled;
      if (isFull === undefined) {
        isFull = false;
      }
      return isFull;
    },
    switchScreen: function switchScreen() {
      var element = document.getElementById('map-container'); //设置后就是   id==con_lf_top_div 的容器全屏
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen();
        }
      }
      this.isFullscreen = !this.isFullscreen;
      if (this.isFullscreen) {
        this.containerH = '95vh';
      }
      this.$forceUpdate();
    }
  }
};