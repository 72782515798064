import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import archivesManagement from "@/api/archivesManagement";
import carService from "@/api/carService";
import Pagination from "@/components/Pagination";
import initHeight from "@/mixins/initHeight";
import OemSelect from '@/components/OemSelect';
import DatePicker from '@/components/DatePicker';
import { character, commonExport, getBeforeDate, getCurrentDate } from '@/utils/common';
export default {
  name: "insuranceArchives",
  mixins: [initHeight],
  data: function data() {
    return {
      drawer: false,
      total: 0,
      listQuery: {
        pageNum: 1,
        //页码
        pageSize: 20 //分页数
      },

      tableData: [],
      vehicleNameCode: [],
      vehicleModel: [],
      multipleSelection: [],
      createTime: [getBeforeDate(6), getCurrentDate()],
      loading: false,
      searchForm: {
        modelName: "" //车型code
      },

      bizType: localStorage.getItem('bizType') || '',
      userInfo: JSON.parse(localStorage.getItem('userInfo')) || {},
      orgForm: {
        salesOrgCode: '',
        salesOrgName: '',
        salesOrgType: ''
      },
      timeoutForExport: null
    };
  },
  components: {
    Pagination: Pagination,
    OemSelect: OemSelect,
    DatePicker: DatePicker
  },
  created: function created() {
    this.loadData();
  },
  methods: {
    //日期选择事件
    getPickersDate: function getPickersDate(name, val) {
      if (val && val.length > 1) {
        this[name] = val;
      } else {
        this[name] = [];
      }
    },
    // 导出防抖
    exportFn: function exportFn() {
      var _this = this;
      if (this.timeoutForExport) {
        clearTimeout(this.timeoutForExport);
      }
      this.timeoutForExport = setTimeout(function () {
        _this.exportFn1();
      }, 500);
    },
    // 导出
    exportFn1: function exportFn1() {
      var exporParams = {
        total: this.total,
        fileName: '保险档案列表',
        exportUrl: '/vi-core-service/coreCustomer/oem/insurance/record/search',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    nodeClick: function nodeClick(data, node, target) {
      // 机构树点击事件
      this.searchForm.dealerCode = data.salesOrgCode;
      // this.searchForm.salesOrgName = data.salesOrgName;
      this.searchForm.areaType = data.salesOrgType;
    },
    breakData: function breakData() {
      // this.resetSearch()
      this.loadData(false);
    },
    inputPlateNo: function inputPlateNo(val) {
      //输入的车牌号中的字母自动转大写
      if (!this.isNoPlateNo) {
        this.searchForm.plateNo = _.toUpper(val);
      }
    },
    checkValue: function checkValue(val) {
      //输入的车架号中的字母自动转大写，并去除i，o，q
      if (val) {
        val = val.replace(/i/g, '').replace(/I/g, '').replace(/o/g, '').replace(/O/g, '').replace(/q/g, '').replace(/Q/g, '');
      }
      this.searchForm.vin = character(_.toUpper(val));
    },
    getVehicleModelList: function getVehicleModelList() {
      var _this2 = this;
      // 获取所有车型列表
      carService.brandOptions().then(function (res) {
        if (res.code == 200) {
          _this2.vehicleModel = res.data;
        }
      }).catch(function (err) {
        _this2.$message({
          type: "error",
          message: err.msg
        });
      });
    },
    loadData: function loadData() {
      var _this3 = this;
      // 加载数据列表
      this.drawer = false;
      this.loading = true;
      if (!_.isEmpty(this.createTime)) {
        this.searchForm.createdTimeStart = this.createTime[0] ? this.createTime[0].split(' ')[0] + ' 00:00:00' : null;
        this.searchForm.createdTimeEnd = this.createTime[1] ? this.createTime[1].split(' ')[0] + ' 23:59:59' : null;
      }
      var postData = {};
      postData = Object.assign(postData, this.searchForm, this.listQuery);
      archivesManagement.getList(postData).then(function (res) {
        _this3.loading = false;
        if (res.code == 200) {
          _this3.tableData = res.data.list;
          _this3.total = res.data.page.recordsTotal;
        } else {
          _this3.$message({
            type: "error",
            message: res.msg
          });
        }
      }).catch(function (err) {
        _this3.loading = false;
      });
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {};
      this.createTime = [getBeforeDate(6), getCurrentDate()];
      this.orgForm = {
        salesOrgName: '全部',
        salesOrgType: '',
        salesOrgCode: ''
      };
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    viewDetail: function viewDetail(type, item) {
      //查看详情
      this.$router.push({
        path: '/ArchivesManagement/detail',
        query: {
          type: type,
          customerId: item.customerId
        }
      });
    }
  }
};