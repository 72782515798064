var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.formLoading,
          expression: "formLoading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: {},
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "500px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  staticStyle: {
                    "padding-bottom": "30px",
                    "margin-bottom": "-20px",
                  },
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-width": "110px",
                    size: "mini",
                  },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 18 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "版本号：",
                                prop: "packageVersion",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入版本号",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.packageVersion,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "packageVersion", $$v)
                                  },
                                  expression: "form.packageVersion",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { staticStyle: { padding: "6px" }, attrs: { span: 6 } },
                        [
                          _c("span", { staticStyle: { color: "#999" } }, [
                            _vm._v("格式：1.0.0.0"),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 18 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "安装包类型：",
                                prop: "packageType",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { placeholder: "请选择安装包类型" },
                                  model: {
                                    value: _vm.form.packageType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "packageType", $$v)
                                    },
                                    expression: "form.packageType",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: {
                                      label: "呼叫中心",
                                      value: "CALL_CENTER",
                                    },
                                  }),
                                  _c("el-option", {
                                    attrs: {
                                      label: "移动B端",
                                      value: "MOBILE_B",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { padding: "6px 0" },
                          attrs: { span: 18 },
                        },
                        [
                          _vm.form.packageType == "CALL_CENTER"
                            ? _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v(
                                  "*只允许上传压缩格式（zip|rar|7z）的文件"
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "justify-content": "flex-end",
                            },
                          },
                          [
                            _c(
                              "el-upload",
                              {
                                ref: "upload",
                                attrs: {
                                  "before-remove": function () {
                                    return (_vm.formData = "")
                                  },
                                  limit: 1,
                                  "show-file-list": false,
                                  action: "",
                                  "http-request": _vm.uploadFile,
                                  "file-list": _vm.fileList,
                                  accept: ".zip,.rar,.7z,.apk,.ipa",
                                  "list-type": "text",
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      size: "mini",
                                      plain: "",
                                      type: "primary",
                                    },
                                  },
                                  [_vm._v("选择安装包")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-table",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { data: _vm.form.file, border: "" },
                            },
                            [
                              _c("el-table-column", {
                                attrs: { prop: "packageName", label: "文件名" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "file-text",
                                            on: {
                                              click: function ($event) {
                                                return _vm.handle(scope.row)
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(scope.row.packageName)
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "packageSize",
                                  label: "大小",
                                  width: "80",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("filterType")(
                                                scope.row.packageSize
                                              )
                                            )
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "操作",
                                  width: "80",
                                  align: "center",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              size: "mini",
                                              type: "danger",
                                              plain: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleDelete(
                                                  scope.$index
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" 删除 ")]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-check",
                type: "primary",
                plain: "",
                size: "mini",
              },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: { click: _vm.closePage },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }