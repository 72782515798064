/**
 * 批单管理接口
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  //  **********************************续保公库************************************
  /**
   * 续保公库信息查询
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   */
  getRenewalInformation: function getRenewalInformation(data) {
    return request.get(getUrl("/renewalInformation/search", 1), {
      params: data
    });
  },
  /**
   * 续保信息删除
   * @param {*} data
   */
  deleteRenewalInformation: function deleteRenewalInformation(data) {
    return request.post(getUrl("/renewalInformation/delete", 1), data);
  },
  /**
   * 续保信息详情查询
   * @param {*} id
   */
  queryRenewalInformation: function queryRenewalInformation(id) {
    return request.get(getUrl("/renewalInformation/detail?id=".concat(id), 1));
  },
  //  **********************************在保查询************************************

  /**
   * 中保信保单查询
   * @param {*} data
   */
  queryCiitcInformation: function queryCiitcInformation(data) {
    return request.post(getUrl("/ciitcInformation/query", 1), data);
  },
  //  **********************************在保公库************************************

  /**
   * 在保公库信息查询
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   */
  searchInsurance: function searchInsurance(data) {
    return request.get(getUrl("/insurance/search", 1), {
      params: data
    });
  },
  /**
    * 批量删除
    * @param {*} ids
    */
  batchDeleteInsurance: function batchDeleteInsurance(ids) {
    return request.delete(getUrl("/insurance/batchDelete/".concat(ids), 1));
  }
};
export default objApi;