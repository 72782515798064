import _createForOfIteratorHelper from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import _defineProperty from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.regexp.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import Pagination from '@/components/Pagination';
import DatePicker from '@/components/DatePicker';
import NonAutoRequest from '@/api/nonAutoRequest';
import ApplyDialog from './applyDialog';
import customers from '@/api/customers';
import OemSelect from '@/components/OemSelect';
import dictionary from '@/api/dictionary';
import { getSupplyers, commonExport, getLastMonthDays, getBeforeDate, getCurrentDate } from '@/utils/common';
export default {
  name: 'claimsManage',
  mixins: [initHeight],
  components: {
    Pagination: Pagination,
    ApplyDialog: ApplyDialog,
    OemSelect: OemSelect,
    DatePicker: DatePicker
    // AuditDialog,
  },
  data: function data() {
    var _ref;
    return _ref = {
      createdDateTime: [getLastMonthDays(), getCurrentDate()],
      modifiedDateTime: [],
      drawer: false,
      loading: false,
      showAddDialog: false,
      showAuditDialog: false
    }, _defineProperty(_ref, "drawer", false), _defineProperty(_ref, "searchForm", {}), _defineProperty(_ref, "tableData", []), _defineProperty(_ref, "supplyers", []), _defineProperty(_ref, "total", 0), _defineProperty(_ref, "listQuery", {
      pageNum: 1,
      pageSize: 20
    }), _defineProperty(_ref, "orgForm", {
      salesOrgCode: '',
      salesOrgName: ''
    }), _defineProperty(_ref, "currentId", ''), _defineProperty(_ref, "policyCancelId", ''), _defineProperty(_ref, "claimStatusList", []), _defineProperty(_ref, "claimStatusLists", [{
      itemCode: 'CS01',
      itemName: '申请中'
    }, {
      itemCode: 'CS02',
      itemName: '已结案'
    }, {
      itemCode: 'CS03',
      itemName: '审核不通过'
    }, {
      itemCode: 'CS04',
      itemName: '已撤销'
    }, {
      itemCode: 'CS05',
      itemName: '提交失败'
    }, {
      itemCode: 'CS06',
      itemName: '暂存'
    }]), _defineProperty(_ref, "ClassifyLists", []), _defineProperty(_ref, "salesman", []), _defineProperty(_ref, "suppliersLists", []), _defineProperty(_ref, "activeName", ''), _defineProperty(_ref, "tabsList", []), _defineProperty(_ref, "bizType", localStorage.getItem("bizType") || ''), _defineProperty(_ref, "timeoutForExport", null), _defineProperty(_ref, "timeoutForRefresh", null), _ref;
  },
  created: function created() {
    var _this = this;
    this.getNonAutoClaimStatus();
    this.getInsuranceProductType();
    //保司
    this.supplyers = getSupplyers();
    // this.loadData();
    this.getClassList();
    if (localStorage.getItem("bizType") != 'OEM') {
      var data = JSON.parse(localStorage.getItem('userInfo')).data;
      this.searchForm.orgCode = data && data.organization.dealerCode ? data.organization.dealerCode : data.organization.orgCode;
      this.searchForm.salesOrgName = data && data.organization.dealerName ? data.organization.dealerName : data.organization.orgName;
    }
    if (localStorage.getItem('userInfo')) {
      var userInfo = JSON.parse(localStorage.getItem('userInfo'));
      if (userInfo.bizType == 'DEALER') {
        customers.getDealerCustomerList(userInfo.data.organization.dealerCode).then(function (res) {
          if (res.code == 200 && res.data) {
            _this.salesman = res.data ? res.data : [];
          }
        });
      }
    }
  },
  watch: {
    $route: function $route(to, from) {
      if (to) {
        this.drawer = false;
        this.showAddDialog = false;
        this.showAuditDialog = false;
        sessionStorage.setItem('claimsActiveName', this.activeName);
      }
    }
  },
  beforeDestroy: function beforeDestroy() {
    sessionStorage.setItem('claimsActiveName', this.activeName);
  },
  methods: {
    getNonAutoClaimStatus: function getNonAutoClaimStatus() {
      var _this2 = this;
      dictionary.getDictItemsByCode('NONAUTOCLAIMSTATUS').then(function (res) {
        if (res.code === 200) {
          _this2.claimStatusList = res.data ? res.data : _this2.claimStatusLists;
        }
      });
    },
    getPickersDate: function getPickersDate(name, val) {
      if (val && val.length > 1) {
        this[name] = val;
      } else {
        this[name] = [];
      }
    },
    //日期更改监听事件
    dateChange: function dateChange(start, end, val) {
      if (val && val.length > 1) {
        this.searchForm[start] = val[0] + ' 00:00:00';
        this.searchForm[end] = val[1] + ' 23:59:59';
      } else {
        this.searchForm[start] = null;
        this.searchForm[end] = null;
      }
    },
    // 机构树点击事件
    handleNodeClick: function handleNodeClick(data, node, target) {
      this.salesman = [];
      this.searchForm.orgCode = data.salesOrgCode;
      // this.searchForm.salesOrgName = data.salesOrgName;
      this.searchForm.areaType = data.salesOrgType;
    },
    oemGetUsers: function oemGetUsers(val) {
      this.searchForm.salemanName = null;
      this.salesman = val;
    },
    //监听选择业务员事件
    salemanNameChange: function salemanNameChange(val) {
      if (val) {
        var _user = _.find(this.salesman, function (item) {
          return item.userId == val;
        });
        if (_user) {
          this.searchForm.creatorBy = _user.userId;
          this.searchForm.creatorName = _user.userName;
          this.$forceUpdate();
        }
      }
    },
    handleTabClick: function handleTabClick() {
      // this.searchForm = {};
      this.listQuery.pageNum = 1;
      this.searchForm.classifyCode = this.activeName;
      sessionStorage.setItem('claimsActiveName', this.activeName);
      this.loadData();
      this.getSupplierLists();
    },
    //获取服务类型列表
    getInsuranceProductType: function getInsuranceProductType() {
      var _this3 = this;
      NonAutoRequest.getClassTab().then(function (res) {
        _this3.loading = false;
        if (res.code == 200) {
          var reslist = res.data ? res.data : [];
          if (sessionStorage.getItem('claimsActiveName')) {
            var claimsActiveName = sessionStorage.getItem('claimsActiveName');
            _this3.activeName = claimsActiveName;
          } else {
            if (reslist[0].classCode) {
              _this3.activeName = reslist[0].classCode;
            } else {
              _this3.activeName = '';
            }
          }
          _this3.searchForm.classifyCode = _this3.activeName;
          _this3.tabsList = reslist;
          _this3.loadData();
          _this3.getSupplierLists();
        }
      });
    },
    // 获取供应商
    getSupplierLists: function getSupplierLists() {
      var _this4 = this;
      NonAutoRequest.getSuppliersList(this.activeName).then(function (res) {
        if (res.code == 200) {
          _this4.suppliersLists = res.data ? res.data : [];
        }
      });
    },
    getClassList: function getClassList() {
      var _this5 = this;
      NonAutoRequest.getClassList().then(function (res) {
        if (res.code == 200) {
          _this5.ClassifyLists = res.data ? res.data.list : [];
        }
      });
    },
    addClicked: function addClicked() {
      this.showAddDialog = true;
    },
    //获取产品管理列表
    loadData: function loadData() {
      var _this6 = this;
      this.loading = true;
      this.drawer = false;
      this.showAddDialog = false;
      this.showAuditDialog = false;
      if (this.createdDateTime) {
        this.searchForm.createStartDate = this.createdDateTime[0] ? this.createdDateTime[0].split(' ')[0] + ' 00:00:00' : null;
        this.searchForm.createEndDate = this.createdDateTime[1] ? this.createdDateTime[1].split(' ')[0] + ' 23:59:59' : null;
      }
      if (this.modifiedDateTime) {
        this.searchForm.modifiedStartTime = this.modifiedDateTime[0] && this.modifiedDateTime[0].split(' ')[0] + ' 00:00:00' || null;
        this.searchForm.modifiedEndTime = this.modifiedDateTime[1] && this.modifiedDateTime[1].split(' ')[0] + ' 23:59:59' || null;
      }
      var data = _objectSpread(_objectSpread({}, this.listQuery), this.searchForm);
      NonAutoRequest.getClaimsList(data).then(function (res) {
        _this6.loading = false;
        if (res.code == 200) {
          _this6.tableData = res.data && res.data.list ? res.data.list : [];
          _this6.total = res.data && res.data.page && res.data.page.recordsTotal ? res.data.page.recordsTotal : 0;
          _this6.$nextTick(function () {
            if (_this6.$refs.multipleTable) _this6.$refs.multipleTable.doLayout();
          });
        } else {
          _this6.tableData = [];
          _this6.total = 0;
        }
        ;
      }).catch(function (err) {
        _this6.loading = false;
      });
    },
    // 编辑
    edit6: function edit6(row) {
      this.$router.push({
        path: "/claimsApply",
        query: {
          claimId: row.claimId ? row.claimId : '',
          policyId: row.policyId,
          applyAgain: row.claimStatus == 'CS06' ? '6' : '7',
          tabName: row.classifyName + '理赔申请'
        }
      });
    },
    // 重新提交申请
    edit5: function edit5(row) {
      this.$router.push({
        path: "/claimsApply",
        query: {
          claimId: row.claimId ? row.claimId : '',
          policyId: row.policyId,
          applyAgain: '5',
          tabName: row.classifyName + '理赔申请-重新申请'
        }
      });
    },
    deleteItem: function deleteItem(row) {
      var _this7 = this;
      this.$confirm('是否删除当前数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        NonAutoRequest.claimDelete(row.claimId.toString()).then(function (res) {
          if (res.code == 200) {
            _this7.$message.success(res.msg);
          }
          _this7.loadData();
        });
      }).catch(function () {});
    },
    // 查看
    view: function view(row) {
      this.$router.push({
        path: "/claimsManageDetail",
        query: {
          claimId: row.claimId ? row.claimId : '',
          isView: true,
          detailType: "detail",
          riskType: 2,
          // vin: row.claimNo,
          tabName: row.classifyName + '理赔详情' + row.claimNo
        }
      });
    },
    // 导出防抖
    exportFn: function exportFn() {
      var _this8 = this;
      if (this.timeoutForExport) {
        clearTimeout(this.timeoutForExport);
      }
      this.timeoutForExport = setTimeout(function () {
        _this8.exportFn1();
      }, 500);
    },
    // 导出
    exportFn1: function exportFn1() {
      var fileName = '';
      if (this.activeName) {
        var _iterator = _createForOfIteratorHelper(this.tabsList),
          _step;
        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var i = _step.value;
            if (i.classCode == this.activeName) {
              fileName = '非车险' + i.className + '理赔列表';
            }
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
      } else {
        fileName = '非车险理赔列表';
      }
      var exportData = {};
      if (this.activeName == 'DBC') {
        exportData = [{
          property: "claimNo",
          label: "理赔申请编号"
        }, {
          property: "reportNo",
          label: "报案号"
        }, {
          property: "policyNo",
          label: "保单号"
        }, {
          property: "classifyName",
          label: "服务类别"
        }, {
          property: "vin",
          label: "车架号"
        }, {
          property: "vhcSeriesName",
          label: "车系"
        }, {
          property: "supplierName",
          label: "保险公司名称"
        }, {
          property: "claimStatusDisplay",
          label: "理赔申请状态"
        }, {
          property: "compensateStatusDisplay",
          label: "赔付状态"
        }, {
          property: "ownerName",
          label: "客户名称"
        }, {
          property: "plateNo",
          label: "车牌号"
        }, {
          property: "compensateAmount",
          label: "赔付金额"
        }, {
          property: "compensationDays",
          label: "赔偿天数"
        }, {
          property: "accidentTime",
          label: "出险时间"
        }, {
          property: "operateOrgName",
          label: "出单机构"
        }, {
          property: "operateOrgCode",
          label: "出单机构编码"
        }, {
          property: "creatorName",
          label: "操作人"
        }, {
          property: "applyOrgName",
          label: "理赔机构"
        }, {
          property: "applyOrgCode",
          label: "理赔机构编码"
        }, {
          property: "createdTime",
          label: "申请时间"
        }, {
          property: "modifiedTime",
          label: "更新时间"
        }];
      } else if (this.activeName == 'SB') {
        exportData = [{
          property: "claimNo",
          label: "理赔申请编号"
        }, {
          property: "reportNo",
          label: "报案号"
        }, {
          property: "policyNo",
          label: "保单号"
        }, {
          property: "classifyName",
          label: "服务类别"
        }, {
          property: "vin",
          label: "车架号"
        }, {
          property: "vhcSeriesName",
          label: "车系"
        }, {
          property: "claimStatusDisplay",
          label: "理赔申请状态"
        }, {
          property: "planName",
          label: "理赔险种"
        }, {
          property: "compensateStatusDisplay",
          label: "赔付状态"
        }, {
          property: "compensateAmount",
          label: "赔付金额"
        }, {
          property: "ownerName",
          label: "客户名称"
        }, {
          property: "plateNo",
          label: "车牌号"
        }, {
          property: "operateOrgName",
          label: "出单机构"
        }, {
          property: "operateOrgCode",
          label: "出单机构编码"
        }, {
          property: "creatorName",
          label: "操作人"
        }, {
          property: "applyOrgName",
          label: "理赔机构"
        }, {
          property: "applyOrgCode",
          label: "理赔机构编码"
        }, {
          property: "createdTime",
          label: "申请时间"
        }, {
          property: "modifiedTime",
          label: "更新时间"
        }];
      } else {
        exportData = [{
          property: "claimNo",
          label: "理赔申请编号"
        }, {
          property: "reportNo",
          label: "报案号"
        }, {
          property: "policyNo",
          label: "保单号"
        }, {
          property: "classifyName",
          label: "服务类别"
        }, {
          property: "vin",
          label: "车架号"
        }, {
          property: "vhcSeriesName",
          label: "车系"
        }, {
          property: "repairOrderNo",
          label: "TACT维修工单号"
        }, {
          property: "claimStatusDisplay",
          label: "理赔申请状态"
        }, {
          property: "compensateStatusDisplay",
          label: "赔付状态"
        }, {
          property: "ownerName",
          label: "客户名称"
        }, {
          property: "plateNo",
          label: "车牌号"
        }, {
          property: "operateOrgName",
          label: "出单机构"
        }, {
          property: "operateOrgCode",
          label: "出单机构编码"
        }, {
          property: "creatorName",
          label: "操作人"
        }, {
          property: "applyOrgName",
          label: "理赔机构"
        }, {
          property: "applyOrgCode",
          label: "理赔机构编码"
        }, {
          property: "createdTime",
          label: "申请时间"
        }, {
          property: "modifiedTime",
          label: "更新时间"
        }];
      }
      var exporParams = {
        total: this.total,
        fileName: fileName ? fileName : '非车险理赔管理列表',
        exportUrl: '/biz-nonauto-service/claim/search',
        searchForm: this.searchForm,
        exportData: exportData
      };
      commonExport(exporParams);
    },
    // 刷新防抖
    refreshClicked: function refreshClicked() {
      var _this9 = this;
      if (this.timeoutForRefresh) {
        clearTimeout(this.timeoutForRefresh);
      }
      this.timeoutForRefresh = setTimeout(function () {
        _this9.refreshClicked1();
      }, 500);
    },
    // 刷新
    refreshClicked1: function refreshClicked1() {
      this.loadData();
    },
    // 重置
    resetSearch: function resetSearch() {
      if (this.bizType === 'OEM') {
        this.orgForm = {
          salesOrgName: '全部',
          salesOrgCode: ''
        };
        this.salesman = [];
        this.searchForm = {};
      } else {
        this.searchForm = {};
        var data = JSON.parse(localStorage.getItem('userInfo')).data;
        this.searchForm.orgCode = data && data.organization.dealerCode ? data.organization.dealerCode : data.organization.orgCode;
        this.searchForm.salesOrgName = data && data.organization.dealerName ? data.organization.dealerName : data.organization.orgName;
      }
      this.searchForm.classifyCode = this.activeName;
      this.modifiedDateTime = [];
      this.createdDateTime = [getLastMonthDays(), getCurrentDate()];
    },
    //搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    //新增
    add: function add() {
      this.showAddDialog = true;
    },
    //删除
    batchDelete: function batchDelete(item) {
      var _this10 = this;
      this.$confirm('您确定要删除该数据吗？', {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        NonAutoRequest.deleteNonAutoCancelPolicy(item.policyCancelId).then(function (res) {
          if (res.code === 200) {
            _this10.$message.success(res.msg);
            _this10.loadData();
          }
          ;
        });
      }).catch(function (err) {});
    }
  }
};