import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.fill.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PageContainer from "@/components/PageContainer";
import FormLabel from "@/components/FormSubLabel";
import dict from '@/mixins/dict';
import Pagination from '@/components/Pagination';
import activityRequest from '../../api/insuranceActivities';
import { commonExport } from '@/utils/common';
export default {
  name: "VerificationDetail",
  components: {
    PageContainer: PageContainer,
    FormLabel: FormLabel,
    Pagination: Pagination
  },
  mixins: [dict],
  data: function data() {
    return {
      isOnlyCheck: this.$route.query.check === 'second',
      form: {
        activeRequireList: new Array(1).fill().map(function (val) {
          return {
            vehicleNameCode: '',
            indicators: '',
            conditions: '',
            rate: '',
            relevanceStatus: []
          };
        })
      },
      vehicleList: [],
      indicatorsList: [{
        name: '新车投保占比',
        value: 'RATE1'
      }, {
        name: '综合续保率',
        value: 'RATE2'
      }, {
        name: '次新车续保率',
        value: 'RATE3'
      }, {
        name: '延保产品渗透率',
        value: 'RATE4'
      }, {
        name: '延保销售贡献度',
        value: 'RATE5'
      }, {
        name: '代步服务产品渗透率',
        value: 'RATE6'
      }, {
        name: '代步服务产品销售贡献度',
        value: 'RATE7'
      }],
      conditionsList: [{
        name: '大于',
        value: 'GT'
      }, {
        name: '小于',
        value: 'LT'
      }, {
        name: '等于',
        value: 'EQ'
      }],
      relevanceStatusTypeList: [{
        name: '与',
        value: '与'
      }, {
        name: '或',
        value: '或'
      }],
      activeOne: '1',
      activeTwo: '1',
      activeThree: '1',
      formLoading: false,
      searchForm: {
        dealerName: '',
        dealerCode: ''
      },
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      loading: false
    };
  },
  watch: {
    '$route': function $route(to, from) {
      //监听路由是否变化
      this.isOnlyCheck = to.query.check === 'second';
      this.initData();
    }
  },
  created: function created() {},
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    initData: function initData() {
      var _this = this;
      activityRequest.getVehicleModelsList().then(function (res) {
        // 获取车型列表
        if (res.code == RESPONSE_SUCCESS) {
          _this.vehicleList = res.data;
        }
      }).catch(function (err) {
        _this.loading = false;
      });
      if (this.$route.query.activityId) {
        // 获取活动详情
        this.formLoading = true;
        activityRequest.getCheckSalesInfo(this.$route.query.activityId).then(function (res) {
          _this.formLoading = false;
          if (res.code == RESPONSE_SUCCESS) {
            _this.form = res.data;
            _this.tableData = _this.form.activeInfoDealerPagination.list;
            _this.form.activeRequireList.forEach(function (item) {
              item.vehicleNameCode = item.vehicleNameCode.split(',');
              item.relevanceStatus = item.relevanceStatus.split(',');
            });
          }
        }).catch(function (err) {
          _this.loading = false;
        });
      }
    },
    exportFn: function exportFn() {
      var exporParams = {
        total: this.total,
        fileName: '活动核销详情',
        exportUrl: "/ps-ftms-service/activitys/check/sales/".concat(this.$route.query.activityId, "/dealer"),
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    cancel: function cancel() {
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.go(-1);
    },
    resetSearch: function resetSearch() {
      this.searchForm = {
        dealerName: '',
        dealerCode: ''
      };
      this.searchData();
    },
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.getDealerList();
    },
    getDealerList: function getDealerList() {
      var _this2 = this;
      this.loading = true;
      var params = {};
      params.pageNum = this.listQuery.pageNum;
      params.pageSize = this.listQuery.pageSize;
      params.dealerName = this.searchForm.dealerName;
      params.dealerCode = this.searchForm.dealerCode;
      activityRequest.getCheckSalesDealer(this.$route.query.activityId, params).then(function (res) {
        _this2.loading = false;
        if (!_.isEmpty(res.data)) {
          _this2.tableData = res.data.list;
          _this2.total = res.data.page.recordsTotal;
        }
      }).catch(function (err) {
        _this2.loading = false;
      });
    },
    checkItems: function checkItems(item) {
      var _this3 = this;
      //核销经销商
      this.$confirm("\u662F\u5426\u786E\u8BA4\u6838\u9500, \u786E\u8BA4\u6838\u9500\u540E\u4E0D\u80FD\u6062\u590D\u3002", '温馨提示', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        type: 'warning'
      }).then(function () {
        var dealerCodeList = [],
          params = {};
        dealerCodeList.push(item.dealerCode);
        params.dealerCodeList = dealerCodeList;
        _this3.loading = true;
        activityRequest.batchCheckSalesDealer(_this3.$route.query.activityId, dealerCodeList).then(function (res) {
          _this3.loading = false;
          if (res.code == RESPONSE_SUCCESS) {
            _this3.$message({
              type: 'success',
              message: '核销成功'
            });
            _this3.getDealerList();
          }
        }).catch(function (err) {
          _this3.loading = false;
          _this3.$message({
            type: 'error',
            message: res.msg
          });
        });
      });
    },
    viewDetail: function viewDetail(item) {
      // 查看某次活动某个经销商的客户
      var router_path = this.$route.path;
      this.$router.push({
        path: "/verification/specific",
        query: {
          activityId: this.$route.query.activityId,
          dealerName: item.dealerName,
          dealerCode: item.dealerCode,
          back: router_path
        }
      });
    }
  }
};