//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import requestApi from '@/api/smsManage';
import OemSelect from '@/components/OemSelect';
import { getUserOrgInfoDealerOem } from '@/utils/common';
export default {
  components: {
    OemSelect: OemSelect
  },
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    opType: {
      type: String,
      default: ""
    },
    currentId: {
      type: String,
      default: ""
    },
    operatorList: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      form: {
        mnoCode: '',
        messageType: '',
        orgCode: '',
        orgName: '',
        channelUrl: '',
        userName: '',
        password: '',
        msgPrefix: '',
        includeSignature: false,
        subordinate: true
      },
      rules: {
        mnoCode: {
          required: true,
          message: '请选择运营商',
          trigger: "change"
        },
        messageType: {
          required: true,
          message: '请选择通道类型',
          trigger: "change"
        },
        orgCode: {
          required: true,
          message: '请选择所属机构',
          trigger: "change"
        },
        channelUrl: {
          required: true,
          message: '请输入服务地址',
          trigger: "blur"
        },
        userName: {
          required: true,
          message: '请输入短信账号',
          trigger: "blur"
        },
        smsSignature: {
          required: true,
          message: '请输入短信签名',
          trigger: "blur"
        }
      },
      channelTypeList: [{
        value: 'MT01',
        label: '营销短信'
      }, {
        value: 'MT02',
        label: '标准短信'
      }],
      orgForm: {
        salesOrgCode: '',
        salesOrgName: ''
      },
      bizType: localStorage.getItem("bizType") || '',
      formLoading: false
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
      ;
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    initData: function initData() {
      var _this = this;
      //初始化数据
      this.form = {
        mnoCode: '',
        messageType: '',
        orgCode: '',
        orgName: '',
        channelUrl: '',
        userName: '',
        password: '',
        smsSignature: '',
        includeSignature: false,
        subordinate: true
      };
      this.orgForm = {
        salesOrgCode: '',
        salesOrgName: ''
      };
      if (this.opType === "add") {
        if (this.bizType === "DEALER" || this.bizType === "OEM") {
          var orgObj = getUserOrgInfoDealerOem(this.bizType);
          this.form.orgCode = orgObj.salesOrgCode;
          this.form.orgName = orgObj.salesOrgName;
        }
      } else {
        requestApi.queryMnoConfig(this.currentId).then(function (res) {
          if (res.code === 200) {
            _this.form = res.data;
            _this.orgForm = {
              salesOrgCode: _this.form.orgCode,
              salesOrgName: _this.form.orgName
            };
          }
          ;
        });
      }
      ;
      this.$refs.detail.resetFields();
    },
    handleNodeClick: function handleNodeClick(data, node, target) {
      // 机构树点击事件
      this.form.orgCode = data.salesOrgCode;
      this.form.orgName = data.salesOrgName;
    },
    save: function save() {
      var _this2 = this;
      //保存数据
      this.$refs.detail.validate(function (valid) {
        if (valid) {
          _this2.formLoading = true;
          _this2.form.bizType = _this2.bizType;
          var req = _this2.opType === 'add' ? requestApi.addMnoConfig(_this2.form) : requestApi.editMnoConfig(_this2.currentId, _this2.form);
          req.then(function (res) {
            _this2.formLoading = false;
            if (res.code === 200) {
              _this2.$message.success(res.msg);
              _this2.closePage();
            }
            ;
          }).catch(function (err) {
            _this2.formLoading = false;
          });
        }
        ;
      });
    },
    closePage: function closePage() {
      //关闭弹窗
      this.$emit('closePage');
    }
  }
};