import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import jobNumInteractive from '@/api/jobNumInteractive';
import request from '@/api/quoteFailures';
import commonRequest from "@/api/common";
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    invokeMonitorId: {
      type: String,
      default: {}
    },
    ids: {
      type: Array,
      default: []
    }
  },
  data: function data() {
    return {
      formLoading: false,
      showPreview: false,
      solutionSchemeLoading: false,
      previewUrl: "",
      addForm: {
        categoryId: '',
        errorAnalysisContent: ''
      },
      formRules: {
        categoryId: [{
          required: true,
          message: '请选择错误分类',
          trigger: 'change'
        }],
        errorAnalysisContent: [{
          required: true,
          message: '请输入错误分析',
          trigger: 'blur'
        }]
      },
      userInfo: JSON.parse(localStorage.getItem("userInfo")) || {},
      allCategory: [],
      canViewSolution: false
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
      ;
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    // 刷新解决方案
    refreshScheme: function refreshScheme() {
      var _this = this;
      this.solutionSchemeLoading = true;
      commonRequest.searchByMonitor({
        invokeMonitorId: this.addForm.invokeMonitorId
      }).then(function (res) {
        _this.solutionSchemeLoading = false;
        if (res.code == 200) {
          _this.addForm.solutionList = res.data;
        }
      }).catch(function (err) {
        _this.solutionSchemeLoading = false;
      });
    },
    // 查看解决方案
    viewSolutionScheme: function viewSolutionScheme(item) {
      this.showPreview = true;
      this.previewUrl = item.knowledgePageUrl;
    },
    save: function save() {
      var _this2 = this;
      this.$refs['addForm'].validate(function (valid) {
        if (valid) {
          _this2.formLoading = true;
          jobNumInteractive.update({
            "errorAnalysisContent": _this2.addForm.errorAnalysisContent,
            "categoryId": _this2.addForm.categoryId,
            "idList": !_.isEmpty(_this2.ids) ? _this2.ids : [_this2.invokeMonitorId],
            "errorAnalystName": _this2.userInfo.user.userName
          }).then(function (res) {
            _this2.formLoading = false;
            if (res.code == 200) {
              _this2.$message.success(res.msg);
              _this2.closePage(true);
            }
          });
        }
      });
    },
    onCopy: function onCopy(e) {
      //剪贴板回调函数
      this.$message({
        type: 'success',
        message: '错误堆栈复制成功！'
      });
    },
    onError: function onError(e) {
      //剪贴板回调函数
      this.$message({
        type: 'error',
        message: '错误堆栈复制失败！'
      });
    },
    onCopy1: function onCopy1(e) {
      //剪贴板回调函数
      this.$message({
        type: 'success',
        message: '请求ID复制成功！'
      });
    },
    onError1: function onError1(e) {
      //剪贴板回调函数
      this.$message({
        type: 'error',
        message: '请求ID复制失败！'
      });
    },
    initData: function initData() {
      var _this3 = this;
      //初始化数据
      this.$nextTick(function () {
        _this3.$refs['addForm'].clearValidate();
      });
      request.getAllErrorCategory().then(function (res) {
        if (res.data) {
          _this3.allCategory = res.data;
        }
      });
      if (!this.invokeMonitorId) return;
      this.formLoading = true;
      jobNumInteractive.getDetail(this.invokeMonitorId).then(function (res) {
        _this3.formLoading = false;
        if (res.code == 200) {
          _this3.addForm = res.data;
          var category = _.find(_this3.allCategory, function (item) {
            return item.categoryId == _this3.addForm.categoryId;
          });
          if (!category) _this3.addForm.categoryId = null;
        }
      }).catch(function (err) {
        _this3.formLoading = false;
      });
      commonRequest.getSolutionConfig().then(function (res) {
        _this3.canViewSolution = res.data || false;
      }).catch(function (err) {
        _this3.canViewSolution = false;
      });
    },
    closePage: function closePage(needRefresh) {
      //关闭弹窗
      this.$emit('closePage', {
        needRefresh: needRefresh
      });
    }
  }
};