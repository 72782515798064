var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageContainer", [
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.formLoading,
                expression: "formLoading",
              },
            ],
            attrs: { slot: "main" },
            slot: "main",
          },
          [
            _c(
              "div",
              {
                staticClass: "app-m-cls",
                staticStyle: { "min-height": "calc(100vh - 130px)" },
              },
              [
                _c(
                  "div",
                  { ref: "header", staticClass: "header-cls" },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "form",
                        attrs: {
                          model: _vm.form,
                          rules: _vm.formRules,
                          "label-width": "150px",
                          size: "mini",
                        },
                      },
                      [
                        _c(
                          "el-collapse",
                          {
                            staticStyle: { "margin-top": "10px" },
                            model: {
                              value: _vm.activeOne,
                              callback: function ($$v) {
                                _vm.activeOne = $$v
                              },
                              expression: "activeOne",
                            },
                          },
                          [
                            _c(
                              "el-collapse-item",
                              {
                                staticClass: "collapse-item-style",
                                attrs: { name: "1" },
                              },
                              [
                                _c("template", { slot: "title" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "title",
                                      staticStyle: { "line-height": "25px" },
                                    },
                                    [
                                      _c("FormLabel", {
                                        attrs: { "form-label": "活动信息" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "el-row",
                                      { attrs: { gutter: 0 } },
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "活动名称",
                                                  prop: "activityName",
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    placeholder:
                                                      "请输入活动名称",
                                                    disabled: _vm.isCheck,
                                                    clearable: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form.activityName,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "activityName",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.activityName",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "激励金额(元)",
                                                  prop: "stimulateAmount",
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    placeholder:
                                                      "请输入激励金额",
                                                    disabled: _vm.isCheck,
                                                    clearable: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form.stimulateAmount,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "stimulateAmount",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.stimulateAmount",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "保险公司",
                                                  prop: "insCorpCode",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    staticStyle: {
                                                      width: "100%",
                                                    },
                                                    attrs: {
                                                      filterable: "",
                                                      placeholder:
                                                        "请选择保险公司",
                                                      disabled: _vm.isCheck,
                                                      clearable: "",
                                                    },
                                                    on: {
                                                      change: _vm.insCorpChange,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form.insCorpCode,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "insCorpCode",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.insCorpCode",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.insuranceList,
                                                    function (item, index) {
                                                      return _c("el-option", {
                                                        key: index,
                                                        attrs: {
                                                          label: item.shortName,
                                                          value: item.code,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "最低商业保险金额(元)",
                                                  prop: "minBsAmount",
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    placeholder:
                                                      "请输入1-10位的金额",
                                                    oninput:
                                                      "value=value.replace(/[^0-9]{0,1}(\\d*(?:\\.\\d{0,2})?).*$/g, '$1')",
                                                    disabled: _vm.isCheck,
                                                    clearable: "",
                                                  },
                                                  model: {
                                                    value: _vm.form.minBsAmount,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "minBsAmount",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.minBsAmount",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "活动开始时间",
                                                  prop: "activityStartTime",
                                                },
                                              },
                                              [
                                                _c("el-date-picker", {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    "value-format":
                                                      "yyyy-MM-dd HH:mm:ss",
                                                    type: "date",
                                                    placeholder:
                                                      "请选择活动开始时间",
                                                    disabled: _vm.isCheck,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form
                                                        .activityStartTime,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "activityStartTime",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.activityStartTime",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "活动结束时间",
                                                  prop: "activityEndTime",
                                                },
                                              },
                                              [
                                                _c("el-date-picker", {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    "value-format":
                                                      "yyyy-MM-dd HH:mm:ss",
                                                    type: "date",
                                                    placeholder:
                                                      "请选择活动结束时间",
                                                    disabled: _vm.isCheck,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form.activityEndTime,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "activityEndTime",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.activityEndTime",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 16 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "活动描述",
                                                  prop: "description",
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    maxlength: "300",
                                                    "show-word-limit": "",
                                                    type: "textarea",
                                                    rows: 6,
                                                    placeholder:
                                                      "请输入活动描述",
                                                    disabled: _vm.isCheck,
                                                  },
                                                  model: {
                                                    value: _vm.form.description,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "description",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.description",
                                                  },
                                                }),
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      "font-size": "12px",
                                                      color: "#c0c4cc",
                                                    },
                                                  },
                                                  [_vm._v("注:限最大输入300字")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-collapse",
                          {
                            staticStyle: { "margin-top": "10px" },
                            model: {
                              value: _vm.activeTwo,
                              callback: function ($$v) {
                                _vm.activeTwo = $$v
                              },
                              expression: "activeTwo",
                            },
                          },
                          [
                            _c(
                              "el-collapse-item",
                              {
                                staticClass: "collapse-item-style",
                                attrs: { name: "1" },
                              },
                              [
                                _c("template", { slot: "title" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "title",
                                      staticStyle: { "line-height": "25px" },
                                    },
                                    [
                                      _c("FormLabel", {
                                        attrs: { "form-label": "活动要求" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "el-row",
                                      { attrs: { gutter: 20 } },
                                      _vm._l(
                                        _vm.form.activeRequireList,
                                        function (item, index) {
                                          return _c(
                                            "el-form-item",
                                            {
                                              key: index,
                                              attrs: {
                                                label: "指标" + (index + 1),
                                              },
                                            },
                                            [
                                              _c(
                                                "el-col",
                                                { attrs: { span: 5 } },
                                                [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      staticStyle: {
                                                        width: "100%",
                                                      },
                                                      attrs: {
                                                        filterable: "",
                                                        placeholder:
                                                          "请选择车型",
                                                        disabled: _vm.isCheck,
                                                        multiple: "",
                                                        "collapse-tags": "",
                                                        clearable: "",
                                                      },
                                                      on: {
                                                        change: function (val) {
                                                          return _vm.changeSelect(
                                                            val,
                                                            item
                                                          )
                                                        },
                                                        "remove-tag": function (
                                                          val
                                                        ) {
                                                          return _vm.removeTag(
                                                            val,
                                                            item
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          item.selectedArray,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "selectedArray",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.selectedArray",
                                                      },
                                                    },
                                                    [
                                                      _c("el-option", {
                                                        attrs: {
                                                          label: "全选",
                                                          value: "allVehicle",
                                                        },
                                                        nativeOn: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.selectAll(
                                                              item
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _vm._l(
                                                        _vm.vehicleList,
                                                        function (item, index) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: index,
                                                              attrs: {
                                                                label:
                                                                  item.seriesName,
                                                                value:
                                                                  item.seriesNo,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "el-col",
                                                { attrs: { span: 5 } },
                                                [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      staticStyle: {
                                                        width: "100%",
                                                      },
                                                      attrs: {
                                                        filterable: "",
                                                        placeholder:
                                                          "请选择指标",
                                                        disabled: _vm.isCheck,
                                                        clearable: "",
                                                      },
                                                      model: {
                                                        value: item.indicators,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "indicators",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.indicators",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.indicatorsList,
                                                      function (item, index) {
                                                        return _c("el-option", {
                                                          key: index,
                                                          attrs: {
                                                            label: item.name,
                                                            value: item.value,
                                                          },
                                                        })
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "el-col",
                                                { attrs: { span: 5 } },
                                                [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      staticStyle: {
                                                        width: "100%",
                                                      },
                                                      attrs: {
                                                        filterable: "",
                                                        placeholder:
                                                          "请选择条件",
                                                        disabled: _vm.isCheck,
                                                        clearable: "",
                                                      },
                                                      model: {
                                                        value: item.conditions,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "conditions",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.conditions",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.conditionsList,
                                                      function (item, index) {
                                                        return _c("el-option", {
                                                          key: index,
                                                          attrs: {
                                                            label: item.name,
                                                            value: item.value,
                                                          },
                                                        })
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "el-col",
                                                { attrs: { span: 5 } },
                                                [
                                                  _c(
                                                    "el-input",
                                                    {
                                                      attrs: {
                                                        maxlength: "5",
                                                        placeholder:
                                                          "请输入指标比例",
                                                        disabled: _vm.isCheck,
                                                        clearable: "",
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          return _vm.rateInput(
                                                            $event,
                                                            index
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value: item.rate,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "rate",
                                                            $$v
                                                          )
                                                        },
                                                        expression: "item.rate",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "i",
                                                        {
                                                          staticStyle: {
                                                            "font-style":
                                                              "normal",
                                                            "margin-right":
                                                              "10px",
                                                          },
                                                          attrs: {
                                                            slot: "suffix",
                                                          },
                                                          slot: "suffix",
                                                        },
                                                        [_vm._v("%")]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              index + 1 ===
                                              _vm.form.activeRequireList.length
                                                ? _c(
                                                    "el-col",
                                                    { attrs: { span: 2 } },
                                                    [
                                                      _c("el-button", {
                                                        attrs: {
                                                          type: "primary",
                                                          icon: "el-icon-plus",
                                                          disabled: _vm.isCheck,
                                                        },
                                                        on: {
                                                          click: _vm.addTarget,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "el-col",
                                                { attrs: { span: 2 } },
                                                [
                                                  _c("el-button", {
                                                    attrs: {
                                                      icon: "el-icon-minus",
                                                      disabled: _vm.isCheck,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.deleteTarget(
                                                          index
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm.form.activeRequireList
                                                .length &&
                                              index !==
                                                _vm.form.activeRequireList
                                                  .length -
                                                  1
                                                ? _c(
                                                    "el-col",
                                                    { attrs: { span: 5 } },
                                                    [
                                                      _c(
                                                        "el-checkbox-group",
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              _vm.isCheck,
                                                          },
                                                          on: {
                                                            change:
                                                              _vm.relevanceStatusChange,
                                                          },
                                                          model: {
                                                            value:
                                                              item.relevanceStatus,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "relevanceStatus",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.relevanceStatus",
                                                          },
                                                        },
                                                        _vm._l(
                                                          _vm.relevanceStatusTypeList,
                                                          function (
                                                            item,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "el-checkbox",
                                                              {
                                                                key: index,
                                                                attrs: {
                                                                  label:
                                                                    item.value,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.name
                                                                  ) + " "
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]
        ),
        _c(
          "span",
          {
            staticClass: "dialog-footer",
            staticStyle: {
              display: "flex",
              "justify-content": "center",
              "margin-top": "10px",
            },
            attrs: { slot: "footer" },
            slot: "footer",
          },
          [
            !_vm.$route.query.activityId
              ? _c(
                  "el-button",
                  {
                    attrs: {
                      size: "mini",
                      type: "primary",
                      disabled: _vm.isCheck,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.save("RELEASED")
                      },
                    },
                  },
                  [_vm._v(" 保存并发布 ")]
                )
              : _vm._e(),
            !_vm.$route.query.activityId
              ? _c(
                  "el-button",
                  {
                    attrs: {
                      size: "mini",
                      type: "primary",
                      disabled: _vm.isCheck,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.save("WAIT_RELEASED")
                      },
                    },
                  },
                  [_vm._v(" 保存 ")]
                )
              : _vm._e(),
            _vm.$route.query.activityId && !this.$route.query.check
              ? _c(
                  "el-button",
                  {
                    attrs: { size: "mini", type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.save("SUBMIT")
                      },
                    },
                  },
                  [_vm._v(" 提交 ")]
                )
              : _vm._e(),
            _c(
              "el-button",
              { attrs: { size: "mini" }, on: { click: _vm.cancel } },
              [_vm._v(" 关闭 ")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }