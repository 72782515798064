import "core-js/modules/es.array.concat.js";
/**
 * 协议管理接口
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 列表
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   */
  getList: function getList(pageNum, pageSize, data) {
    return request.get(getUrl("/agreements?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 1), {
      params: data
    });
  },
  /**
   * 新增数据
   * @param {*} data 内容
   */
  addData: function addData(data) {
    return request.post(getUrl("/agreements", 1), data);
  },
  /**
    * 查询数据
    * @param {*} id 
    */
  seachData: function seachData(id) {
    return request.get(getUrl("/agreements/".concat(id), 1));
  },
  /**
    * 更新数据
    * @param {*} id 
    * * @param {*} data 内容 
    */
  updateData: function updateData(id, data) {
    return request.put(getUrl("/agreements/".concat(id), 1), data);
  },
  /**
    * 删除数据
    * @param {*} id 
    */
  deleteData: function deleteData(id) {
    return request.delete(getUrl("/agreements/".concat(id), 1));
  },
  /**
    * 获取费率
    * @param {*} id 
    */
  getRateData: function getRateData(id) {
    return request.get(getUrl("/agreements/".concat(id, "/rate"), 1));
  },
  /**
   * 新增费率
   * @param {*} id 
   */
  updateRateData: function updateRateData(id, data) {
    return request.post(getUrl("/agreements/".concat(id, "/rate"), 1), data);
  }
};
export default objApi;