import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import provincesApi from '@/api/provinces';
import ProvincesDialog from './dialog/provincesDialog';
export default {
  name: 'ProvincesMaintenance',
  mixins: [initHeight],
  components: {
    ProvincesDialog: ProvincesDialog
  },
  data: function data() {
    return {
      tableData: [],
      loading: false,
      showDialog: false,
      currentType: '',
      currentStatus: '',
      dealerIdArr: [],
      currentDistrictId: '',
      userInfo: JSON.parse(localStorage.getItem("userInfo")) || {},
      maps: new Map()
    };
  },
  created: function created() {
    this.loadData();
  },
  methods: {
    //删除
    deleteArea: function deleteArea(item) {
      var _this = this;
      this.$confirm('您确定要删除该数据吗', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        type: 'warning'
      }).then(function () {
        provincesApi.deleteDistricts(item.districtId).then(function (res) {
          if (res.code === 200) {
            // // 懒加载刷新当前级
            // if(item.districtId && this.maps.get(item.districtId)) {
            //     const {tree,treeNode,resolve} = this.maps.get(item.districtId);
            //     this.load(tree,treeNode,resolve);
            // };
            // 懒加载刷新父级
            var parentId = _this.maps.get(item.parentId) ? _this.maps.get(item.parentId).tree.districtId : '';
            if (parentId && _this.maps.get(parentId)) {
              var _this$maps$get = _this.maps.get(parentId),
                tree = _this$maps$get.tree,
                treeNode = _this$maps$get.treeNode,
                resolve = _this$maps$get.resolve;
              _this.$set(_this.$refs.table.store.states.lazyTreeNodeMap, parentId, []);
              _this.load(tree, treeNode, resolve);
            }
            ;
            _this.$message.success(res.msg);
            _this.loadData();
          }
          ;
        });
      }).catch(function () {});
    },
    //编辑
    editArea: function editArea(status, type, item) {
      this.currentStatus = status;
      this.currentType = type;
      this.currentDistrictId = item.districtId.toString();
      this.showDialog = true;
    },
    //新增
    addArea: function addArea(status, type) {
      this.currentStatus = status;
      this.currentType = type;
      this.showDialog = true;
    },
    //获取列表数据
    loadData: function loadData() {
      var _this2 = this;
      this.loading = true;
      this.dealerIdArr = [];
      this.showDialog = false;
      provincesApi.getProvincesAndCities('PROVINCE').then(function (res) {
        if (res.code === 200) {
          _this2.loading = false;
          _this2.tableData = res.data.map(function (val) {
            _this2.$set(val, 'child', []);
            _this2.$set(val, 'hasChild', true);
            return val;
          });
        }
        ;
      }).catch(function (err) {
        _this2.loading = false;
      });
    },
    load: function load(tree, treeNode, resolve) {
      var _this3 = this;
      var id = tree.districtId;
      this.maps.set(id, {
        tree: tree,
        treeNode: treeNode,
        resolve: resolve
      });
      provincesApi.getSubCities(tree.districtId).then(function (res) {
        if (res.code === 200) {
          var child = res.data.map(function (val) {
            _this3.$set(val, 'child', []);
            _this3.$set(val, 'hasChild', val.type === 'DISTRICT' ? false : true);
            return val;
          });
          resolve(child);
        }
        ;
      });
    },
    // 刷新
    breakData: function breakData() {
      this.loadData();
    },
    // 弹框关闭回调
    refreshData: function refreshData(isRefresh, parentId) {
      if (isRefresh) {
        // 懒加载刷新父级
        if (parentId && this.maps.get(parentId)) {
          var _this$maps$get2 = this.maps.get(parentId),
            tree = _this$maps$get2.tree,
            treeNode = _this$maps$get2.treeNode,
            resolve = _this$maps$get2.resolve;
          this.$set(this.$refs.table.store.states.lazyTreeNodeMap, parentId, []);
          this.load(tree, treeNode, resolve);
        }
        ;
        this.loadData();
      } else {
        this.showDialog = false;
      }
      ;
    },
    changeCurrentType: function changeCurrentType(currentType) {
      switch (currentType) {
        case 'PROVINCE':
          return '省';
        case 'CITY':
          return '市';
        case 'DISTRICT':
          return '区';
      }
      ;
    }
  }
};