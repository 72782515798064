import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.includes.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Rules from "@/utils/rules";
import { character, getUserOrgInfoDealerOem } from '@/utils/common';
import vehicleSalesRequest from '@/api/vehicleSalesMaintain';
import OrgSelect from '@/components/OrgSelect';
import OemSelect from '@/components/OemSelect';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    opType: {
      type: String,
      default: ''
    },
    bizType: {
      type: String,
      default: ''
    },
    saleObj: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  components: {
    OrgSelect: OrgSelect,
    OemSelect: OemSelect
  },
  data: function data() {
    return {
      form: {
        ownerName: '',
        ownerMobile: '',
        vin: '',
        ownerOrgCode: "",
        ownerOrgName: "",
        salemanName: '',
        salesDate: '',
        engineNo: '',
        modelName: '',
        vehicleType: '',
        fuelType: '',
        wholeWeight: ''
      },
      orgForm: {
        salesOrgCode: "",
        salesOrgName: ""
      },
      rules: {
        ownerOrgCode: [{
          required: true,
          message: '请选择车销门店',
          trigger: ["blur", "change"]
        }],
        salesDate: [{
          required: true,
          message: '请选择车销日期',
          trigger: 'change'
        }],
        ownerMobile: [{
          required: true,
          message: "请输入联系电话",
          trigger: ["blur", "change"]
        }],
        ownerName: [{
          required: true,
          message: "请输入车主名",
          trigger: ["blur", "change"]
        }, {
          min: 2,
          max: 50,
          pattern: Rules["NAME"].value,
          message: "车主名格式有误",
          trigger: ["blur"]
        }],
        vin: [{
          required: true,
          message: "请输入车架号",
          trigger: ["blur", "change"]
        }, {
          min: 17,
          max: 17,
          message: "输入的车架号不等于17位",
          trigger: ["blur", "change"]
        }]
      },
      ENGRGY_TYPES: [],
      VEHICLE_KINDS: JSON.parse(localStorage.getItem("VI_VEHICLE_KIND")) || []
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    // 切换机构
    nodeClick: function nodeClick(val) {
      this.form.ownerOrgCode = val.salesOrgCode;
      this.form.ownerOrgName = val.salesOrgName;
    },
    mobileChange: function mobileChange(data) {
      //校验联系电话
      if (!data.mobile) return false;
      var mobile = Rules['MOBILE_RULE'].value;
      var phone = Rules['LANDLINE_RULE'].value;
      var flag = mobile.test(data.mobile) || phone.test(data.mobile);
      if (!flag) {
        this.$message({
          type: 'warning',
          message: '联系电话不满足校验规则，请修改！',
          offset: 20,
          customClass: 'zZindex'
        });
        this.$forceUpdate();
        return false;
      } else {
        return true;
      }
    },
    // 发动机号自动转大写
    inputEngineNo: function inputEngineNo(val) {
      val = val.replace(/[^A-Za-z0-9-]/g, '');
      this.form.engineNo = _.toUpper(val);
    },
    // 监听输入事件
    inputvin: function inputvin(val) {
      if (val) {
        val = val.replace(/i/g, '').replace(/I/g, '').replace(/o/g, '').replace(/O/g, '').replace(/q/g, '').replace(/Q/g, '');
        this.form.vin = character(_.toUpper(val));
        this.$forceUpdate();
      }
    },
    initData: function initData() {
      var engrgy = JSON.parse(localStorage.getItem("VI_ENGRGY_TYPE")) || [];
      this.ENGRGY_TYPES = _.filter(engrgy, function (o) {
        return ["0", "1", "2", "3", "4"].includes(o.dictCode);
      });
      this.form = {
        ownerName: '',
        ownerMobile: '',
        vin: '',
        ownerOrgCode: "",
        ownerOrgName: "",
        salemanName: '',
        salesDate: '',
        engineNo: '',
        modelName: '',
        vehicleType: '',
        fuelType: '',
        wholeWeight: ''
      };
      this.orgForm = {
        salesOrgCode: "",
        salesOrgName: ""
      };
      if (this.opType == 'add') {
        if (this.bizType === "DEALER") {
          var orgObj = getUserOrgInfoDealerOem(this.bizType);
          this.form.ownerOrgCode = orgObj.salesOrgCode;
          this.form.ownerOrgName = orgObj.salesOrgName;
        }
      } else {
        this.form = _.cloneDeep(this.saleObj) || {};
        this.orgForm = {
          salesOrgCode: this.form.ownerOrgCode,
          salesOrgName: this.form.ownerOrgName
        };
      }
      this.$refs.detail.resetFields();
    },
    save: function save() {
      var _this = this;
      //保存数据
      this.$refs.detail.validate(function (valid) {
        if (valid) {
          var req = _this.opType === 'add' ? vehicleSalesRequest.add(_this.form) : vehicleSalesRequest.edit(_this.form);
          req.then(function (res) {
            if (res.code === 200) {
              _this.$message({
                message: res.msg,
                type: 'success'
              });
              _this.closePage();
            }
          });
        }
      });
    },
    closePage: function closePage() {
      //关闭弹窗
      this.$emit('closePage');
    }
  }
};