import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '../../mixins/initHeight';
import dict from '../../mixins/dict';
import Pagination from '@/components/Pagination';
import DatePicker from '@/components/DatePicker';
import customersRequest from '../../api/customers';
import OrgSelect from '@/components/OrgSelect';
import OemSelect from '@/components/OemSelect';
import { character, getUserOrgInfoDealerOem, commonExport, getBeforeDate, getCurrentDate } from '@/utils/common';
export default {
  name: "customersTracks",
  components: {
    Pagination: Pagination,
    OrgSelect: OrgSelect,
    OemSelect: OemSelect,
    DatePicker: DatePicker
  },
  mixins: [initHeight, dict],
  computed: {},
  data: function data() {
    return {
      activeName: "All",
      drawer: false,
      searchForm: {},
      orgForm: {
        salesOrgCode: '',
        salesOrgName: '',
        salesOrgType: ''
      },
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      startEndTime: null,
      loading: false,
      highlightCurrent: true,
      users: [],
      summaryData: {
        DEFEAT: 0,
        INVALID: 0,
        TRACKING: 0,
        SUCCEED: 0
      },
      bizType: localStorage.getItem("bizType") || '',
      userInfo: JSON.parse(localStorage.getItem("userInfo")) || {},
      followUpStartEndTime: [getBeforeDate(6), getCurrentDate()]
    };
  },
  watch: {
    '$route': {
      handler: function handler(val) {
        if (this.bizType === 'DEALER') {
          var orgObj = getUserOrgInfoDealerOem(this.bizType);
          this.searchForm.ownerOrgCode = orgObj.salesOrgCode;
          this.searchForm.ownerOrgName = orgObj.salesOrgName;
          this.fetchEndUsers(this.bizType, this.userInfo.orgCode);
        }
        switch (val.path) {
          // 跟进明细
          case "/customers/tracks":
            this.searchForm.isMy = null;
            break;
          // 我的跟进
          case "/customers/myTracks":
            this.searchForm.isMy = true;
            break;
        }
        ;
        this.loadData();
      }
    },
    immediate: true
  },
  methods: {
    // 日期选择事件
    getPickersDate: function getPickersDate(name, val) {
      if (val && val.length > 1) {
        this[name] = val;
      } else {
        this[name] = [];
      }
    },
    // 机构树点击事件
    nodeClick: function nodeClick(data) {
      if (this.bizType === "BROKER") {
        this.searchForm.ownerOrgCode = data.salesOrgCode;
      }
      if (this.bizType === "OEM") {
        this.searchForm.ownerOrgCode = data.salesOrgCode;
        this.searchForm.ownerOrgName = data.salesOrgName;
        this.searchForm.areaType = data.salesOrgType;
      }
    },
    breakData: function breakData() {
      this.loadData();
    },
    // 导出
    exportFn: function exportFn() {
      var exporParams = {
        total: this.total,
        limitNum: 200000,
        fileName: '跟进明细',
        exportUrl: '/vi-core-service/customerTrackFail/followDetails',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    // tab切换事件
    handleTabClick: function handleTabClick(tab, event) {
      this.searchForm.tabType = tab.name;
      this.tabType = tab.name;
      this.listQuery = {
        pageNum: 1,
        pageSize: 20
      };
      this.loadData();
    },
    // 查看客户详情
    viewDetail: function viewDetail(item) {
      this.$router.push({
        path: '/customers/detail',
        query: {
          customerId: item.customerId,
          tabName: "\u5BA2\u6237\u8BE6\u60C5-".concat(item.ownerName, "(").concat(item.vin, ")")
        }
      });
    },
    // 重置搜索表单
    resetSearch: function resetSearch() {
      this.searchForm = {
        ownerOrgCode: this.bizType === 'OEM' ? '' : this.searchForm.ownerOrgCode,
        ownerOrgName: this.bizType === 'OEM' ? '' : this.searchForm.ownerOrgName,
        isMy: this.searchForm.isMy
      };
      this.orgForm = {
        salesOrgName: this.bizType === 'OEM' ? '全部' : '',
        salesOrgType: '',
        salesOrgCode: ''
      };
      if (this.bizType != 'DEALER') {
        this.users = [];
      }
      this.followUpStartEndTime = [getBeforeDate(6), getCurrentDate()];
      this.searchForm.tabType = this.tabType;
    },
    // 搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    // 双击打开详情
    dbSelected: function dbSelected(item) {
      this.viewDetail(item);
    },
    // 获取列表数据
    loadData: function loadData() {
      var _this = this;
      this.loading = true;
      this.drawer = false;
      if (this.followUpStartEndTime) {
        this.searchForm.trackTimeStart = this.followUpStartEndTime[0] && this.followUpStartEndTime[0].split(' ')[0] + ' 00:00:00' || null;
        this.searchForm.trackTimeEnd = this.followUpStartEndTime[1] && this.followUpStartEndTime[1].split(' ')[0] + ' 23:59:59' || null;
      }
      customersRequest.getfollowDetails(this.listQuery.pageNum, this.listQuery.pageSize, this.searchForm).then(function (res) {
        _this.loading = false;
        _this.tableData = res.data.list;
        if (res.data.summaryData) {
          _this.summaryData = res.data.summaryData;
        } else {
          _this.summaryData = {
            DEFEAT: 0,
            INVALID: 0,
            TRACKING: 0,
            SUCCEED: 0
          };
        }
        //总条数
        _this.total = res.data.page.recordsTotal;
      }).catch(function (err) {
        _this.loading = false;
      });
    },
    // 列表行选择事件
    getUsers: function getUsers(val) {
      this.users = val;
    },
    // 监听选择业务员事件
    salemanNameChange: function salemanNameChange(val) {
      if (val) {
        var _user = _.find(this.users, function (item) {
          return item.userId == val;
        });
        if (_user) {
          this.searchForm.ownerBy = _user.userId;
          this.searchForm.ownerByName = _user.userName;
          this.$forceUpdate();
        }
      }
    },
    // 监听输入事件
    inputvin: function inputvin(val) {
      if (val) {
        val = val.replace(/i/g, '').replace(/I/g, '').replace(/o/g, '').replace(/O/g, '').replace(/q/g, '').replace(/Q/g, '');
        this.searchForm.vin = character(_.toUpper(val));
        this.$forceUpdate();
      }
    },
    // 获取客户经理列表（经销商、主机厂）
    fetchEndUsers: function fetchEndUsers(type, code) {
      var _this2 = this;
      var rerequest = type === 'DEALER' ? customersRequest.getDealerCustomerList(code) : customersRequest.getOemCustomerList(code);
      rerequest.then(function (res) {
        if (res.code === 200 && res.data) {
          _this2.users = res.data;
        }
      });
    }
  }
};