var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.formLoading,
            expression: "formLoading",
          },
        ],
        staticClass: "app-m-cls",
      },
      [
        _c(
          "div",
          { staticClass: "table-cls normal-btn-cls" },
          [
            _c(
              "el-drawer",
              {
                attrs: {
                  title: "",
                  visible: _vm.drawer,
                  modal: false,
                  "append-to-body": true,
                  size: "350px",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.drawer = $event
                  },
                },
              },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.searchForm,
                      "label-width": "110px",
                      size: "mini",
                    },
                  },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "投保城市：" } },
                              [
                                _c("el-cascader", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    options: _vm.cityList,
                                    clearable: "",
                                    filterable: "",
                                  },
                                  model: {
                                    value: _vm.searchForm.cityCode,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchForm, "cityCode", $$v)
                                    },
                                    expression: "searchForm.cityCode",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "销售机构：" } },
                              [
                                _c("OrgSelect", {
                                  attrs: { searchForm: _vm.orgData },
                                  on: { nodeClick: _vm.nodeClick },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "服务包订单号：" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "40",
                                    placeholder: "请输入服务包订单号",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.searchForm.servPackOrderNo,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "servPackOrderNo",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.servPackOrderNo",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "订单状态：" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      filterable: "",
                                      placeholder: "请选择订单状态",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.searchForm.orderStatusList,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "orderStatusList",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.orderStatusList",
                                    },
                                  },
                                  _vm._l(
                                    _vm.orderStatusList,
                                    function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.dictName,
                                          value: item.dictCode,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "车架号：" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "17",
                                    placeholder: "请输入车架号",
                                    clearable: "",
                                  },
                                  on: { input: _vm.checkValue },
                                  model: {
                                    value: _vm.searchForm.vin,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchForm, "vin", $$v)
                                    },
                                    expression: "searchForm.vin",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "车牌号：" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "40",
                                    placeholder: "请输入车牌号",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.searchForm.plateNo,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchForm, "plateNo", $$v)
                                    },
                                    expression: "searchForm.plateNo",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "投保人名称：" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "40",
                                    placeholder: "请输入投保人名称",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.searchForm.applicantName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "applicantName",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.applicantName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "投保人证件号：" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "40",
                                    placeholder: "请输入投保人证件号",
                                    clearable: "",
                                  },
                                  model: {
                                    value:
                                      _vm.searchForm.applicantCertificateNo,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "applicantCertificateNo",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "searchForm.applicantCertificateNo",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "车主名称：" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "40",
                                    placeholder: "请输入车主名称",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.searchForm.ownerName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchForm, "ownerName", $$v)
                                    },
                                    expression: "searchForm.ownerName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "下单时间：" } },
                              [
                                _c("el-date-picker", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    "start-placeholder": "起期",
                                    "end-placeholder": "止期",
                                    type: "daterange",
                                    format: "yyyy-MM-dd",
                                    "value-format": "yyyy-MM-dd",
                                    "unlink-panels": "",
                                    "range-separator": "——",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.dateChange(
                                        "insuredStart",
                                        "insuredEnd",
                                        $event
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.startEndTime,
                                    callback: function ($$v) {
                                      _vm.startEndTime = $$v
                                    },
                                    expression: "startEndTime",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c("el-col", { attrs: { span: 24 } }, [
                          _c(
                            "div",
                            { staticClass: "searchBtnStyle" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    icon: "el-icon-refresh-left",
                                  },
                                  on: { click: _vm.resetSearch },
                                },
                                [_vm._v("重置")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    type: "primary",
                                    icon: "el-icon-search",
                                  },
                                  on: { click: _vm.searchData },
                                },
                                [_vm._v("查询")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "table-cls normal-btn-cls" },
          [
            _c(
              "el-card",
              { staticClass: "box-card", attrs: { shadow: "never" } },
              [
                _c(
                  "div",
                  {
                    staticClass: "search-box",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v("销售机构：")]),
                        _c("OrgSelect", {
                          attrs: { searchForm: _vm.orgData },
                          on: { nodeClick: _vm.nodeClick },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v("服务包订单号：")]),
                        _c("el-input", {
                          staticStyle: { width: "125px" },
                          attrs: {
                            maxlength: "40",
                            placeholder: "请输入订单号",
                            clearable: "",
                          },
                          model: {
                            value: _vm.searchForm.servPackOrderNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "servPackOrderNo", $$v)
                            },
                            expression: "searchForm.servPackOrderNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v("车架号：")]),
                        _c("el-input", {
                          staticStyle: { width: "120px" },
                          attrs: {
                            maxlength: "17",
                            placeholder: "请输入车架号",
                            clearable: "",
                          },
                          on: { input: _vm.checkValue },
                          model: {
                            value: _vm.searchForm.vin,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "vin", $$v)
                            },
                            expression: "searchForm.vin",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v("订单状态：")]),
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "125px" },
                            attrs: {
                              filterable: "",
                              placeholder: "请选择订单状态",
                              clearable: "",
                            },
                            model: {
                              value: _vm.searchForm.orderStatusList,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "orderStatusList", $$v)
                              },
                              expression: "searchForm.orderStatusList",
                            },
                          },
                          _vm._l(_vm.orderStatusList, function (item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: {
                                label: item.dictName,
                                value: item.dictCode,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v("下单时间：")]),
                        _c("el-date-picker", {
                          staticStyle: { width: "218px" },
                          attrs: {
                            "start-placeholder": "起期",
                            "end-placeholder": "止期",
                            type: "daterange",
                            format: "yyyy-MM-dd",
                            "value-format": "yyyy-MM-dd",
                            "unlink-panels": "",
                            "range-separator": "——",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.dateChange(
                                "insuredStart",
                                "insuredEnd",
                                $event
                              )
                            },
                          },
                          model: {
                            value: _vm.startEndTime,
                            callback: function ($$v) {
                              _vm.startEndTime = $$v
                            },
                            expression: "startEndTime",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              icon: "el-icon-refresh-left",
                            },
                            on: { click: _vm.resetSearch },
                          },
                          [_vm._v("重置")]
                        ),
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "8px" },
                            attrs: {
                              size: "mini",
                              type: "primary",
                              icon: "el-icon-search",
                            },
                            on: { click: _vm.searchData },
                          },
                          [_vm._v("查询")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "search-btn" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { icon: "el-icon-refresh", size: "mini" },
                            on: { click: _vm.breakData },
                          },
                          [_vm._v("刷新")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { icon: "el-icon-download", size: "mini" },
                            on: { click: _vm.exportExcel },
                          },
                          [_vm._v("导出")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { icon: "el-icon-search", size: "mini" },
                            on: {
                              click: function ($event) {
                                _vm.drawer = true
                              },
                            },
                          },
                          [_vm._v("搜索")]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    ref: "multipleTable",
                    staticStyle: { width: "100%" },
                    attrs: {
                      size: "mini",
                      border: "",
                      data: _vm.tableData,
                      height: _vm.tableHeightJD,
                      "header-cell-style": { background: "#F7F7F7" },
                      "highlight-current-row": "",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "servPackOrderNo",
                        label: "服务包订单号",
                        "min-width": "220",
                        fixed: "left",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "servPackCode",
                        label: "服务包方案编码",
                        "min-width": "150",
                        fixed: "left",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "servPackName",
                        label: "服务包方案名称",
                        "min-width": "120",
                        fixed: "left",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "supplierDisplay",
                        label: "服务包供应商",
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "plateNo",
                        label: "车牌号",
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "vin",
                        label: "车架号",
                        "min-width": "170",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "modelName",
                        label: "品牌型号",
                        "min-width": "230",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "insCityName",
                        label: "投保城市",
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "salemanName",
                        label: "业务员",
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "applicantName",
                        label: "投保人名称",
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "applicantCertificateNo",
                        label: "投保人证件号",
                        "min-width": "160",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "ownerName",
                        label: "车主名称",
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "ownerCertificateNo",
                        label: "车主证件号",
                        "min-width": "160",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "orderStatusDisplay",
                        label: "订单状态",
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", [
                                _c(
                                  "span",
                                  {
                                    staticStyle: { "margin-left": "3px" },
                                    style: {
                                      color: _vm.getStatusColor(
                                        scope.row.orderStatus
                                      ),
                                    },
                                  },
                                  [_vm._v(_vm._s(scope.row.orderStatusDisplay))]
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "salesOrgName",
                        label: "销售机构",
                        "min-width": "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "amount",
                        label: "订单金额",
                        align: "right",
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "receiptsAmount",
                        label: "实收金额",
                        align: "right",
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "effectiveStartTime",
                        label: "服务包生效时间",
                        width: "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "effectiveEndTime",
                        label: "服务包失效时间",
                        width: "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "orderTime",
                        label: "下单时间",
                        width: "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "操作",
                        align: "center",
                        width: "80",
                        fixed: "right",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.viewDetail("view", scope.row)
                                    },
                                  },
                                },
                                [_vm._v("查看")]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c("pagination", {
                  attrs: {
                    total: _vm.total,
                    page: _vm.listQuery.pageNum,
                    limit: _vm.listQuery.pageSize,
                  },
                  on: {
                    "update:page": function ($event) {
                      return _vm.$set(_vm.listQuery, "pageNum", $event)
                    },
                    "update:limit": function ($event) {
                      return _vm.$set(_vm.listQuery, "pageSize", $event)
                    },
                    pagination: function ($event) {
                      return _vm.loadData()
                    },
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "Dialog",
          {
            attrs: {
              title:
                "" +
                (_vm.opType == "view"
                  ? "查看"
                  : _vm.opType == "edit"
                  ? "编辑"
                  : "新增"),
              "form-loading": _vm.formLoading,
            },
            on: { handleClose: _vm.loadData },
            model: {
              value: _vm.showAddDialog,
              callback: function ($$v) {
                _vm.showAddDialog = $$v
              },
              expression: "showAddDialog",
            },
          },
          [
            _c(
              "el-form",
              {
                ref: "detail",
                staticClass: "formDialog",
                staticStyle: {
                  "margin-bottom": "-20px",
                  "min-height": "400px",
                },
                attrs: {
                  slot: "content",
                  model: _vm.form,
                  rules: _vm.formRules,
                  "label-width": "100px",
                  size: "mini",
                },
                slot: "content",
              },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            ref: "salemanName",
                            attrs: { label: "业务员：", prop: "salemanName" },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  filterable: "",
                                  placeholder: "请选择业务员",
                                  clearable: "",
                                },
                                on: { change: _vm.salemanNameChange },
                                model: {
                                  value: _vm.form.salemanName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "salemanName", $$v)
                                  },
                                  expression: "form.salemanName",
                                },
                              },
                              _vm._l(_vm.users, function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    label: item.userName,
                                    value: item.userId,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            ref: "insCityCode",
                            attrs: { label: "投保城市：", prop: "insCityCode" },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  filterable: "",
                                  placeholder: "请选择投保城市",
                                  clearable: false,
                                },
                                model: {
                                  value: _vm.form.insCityCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "insCityCode", $$v)
                                  },
                                  expression: "form.insCityCode",
                                },
                              },
                              _vm._l(
                                _vm.policyCityItems,
                                function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.insCityName,
                                      value: item.insCityCode,
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c("el-form-item", { attrs: { label: "车牌号：" } }, [
                          _c(
                            "div",
                            { staticStyle: { display: "flex" } },
                            [
                              _c("el-input", {
                                ref: "plateNo",
                                staticStyle: { "margin-right": "8px" },
                                attrs: {
                                  maxlength: "10",
                                  placeholder: "请输入车牌号",
                                  disabled: _vm.form.isNewCar,
                                  clearable: "",
                                  prop: "media.idcardFront",
                                },
                                model: {
                                  value: _vm.form.plateNo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "plateNo", $$v)
                                  },
                                  expression: "form.plateNo",
                                },
                              }),
                              _c(
                                "el-checkbox",
                                {
                                  attrs: { disabled: "" },
                                  on: { change: _vm.noPlateNoChange },
                                  model: {
                                    value: _vm.form.isNewCar,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "isNewCar", $$v)
                                    },
                                    expression: "form.isNewCar",
                                  },
                                },
                                [_vm._v("未上牌")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            ref: "vin",
                            staticStyle: { width: "100%" },
                            attrs: { label: "车架号：", prop: "vin" },
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                maxlength: "17",
                                placeholder: "请输入车架号",
                                clearable: "",
                              },
                              on: { input: _vm.inputvin },
                              model: {
                                value: _vm.form.vin,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "vin",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "form.vin",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "品牌车型：", prop: "BrandName" } },
                          [
                            _c("CarBrands", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                widthNum: "100%",
                                carTypeValue: _vm.carTypeVal,
                              },
                              on: { carHandleChanged: _vm.CarHandleChange },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { staticStyle: { "margin-top": "20px" } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            staticClass: "packageBox",
                            model: {
                              value: _vm.form.packSchemeId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "packSchemeId", $$v)
                              },
                              expression: "form.packSchemeId",
                            },
                          },
                          _vm._l(_vm.serviceTableData, function (item, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "packageItem" },
                              [
                                _c("img", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: item.introduceImage,
                                      expression: "item.introduceImage",
                                    },
                                  ],
                                  staticStyle: {
                                    width: "100px",
                                    height: "100px",
                                  },
                                  attrs: {
                                    src: item.introduceImageUrl,
                                    alt: "",
                                  },
                                }),
                                _c(
                                  "div",
                                  { staticStyle: { "margin-top": "10px" } },
                                  [
                                    _c(
                                      "el-radio",
                                      { attrs: { label: item.packSchemeId } },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(item.packSchemeName) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { attrs: { slot: "footer" }, slot: "footer" },
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      size: "mini",
                      type: "primary",
                      plain: "",
                      icon: "el-icon-check",
                    },
                    on: { click: _vm.submitAdd },
                  },
                  [_vm._v(" 新增投保 ")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { size: "mini", icon: "el-icon-close" },
                    on: { click: _vm.close },
                  },
                  [_vm._v(" 取消 ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }