import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.json.stringify.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '../../mixins/initHeight';
import dict from '../../mixins/dict';
import Pagination from '@/components/Pagination';
import DatePicker from '@/components/DatePicker';
import request from '../../api/insuranceslip';
import requestOrg from '../../api/orgChildren';
import OemSelect from '@/components/OemSelect';
import customers from '@/api/customers';
import { getCurrentDate, getBeforeDate, character, getCurrentOrganization, getUserOrgInfo, getSupplyers, getLastMonthDays } from '@/utils/common';
export default {
  name: 'ProposalManage',
  components: {
    Pagination: Pagination,
    OemSelect: OemSelect,
    DatePicker: DatePicker
  },
  mixins: [initHeight, dict],
  computed: {},
  data: function data() {
    return {
      supplyers: [],
      drawer: false,
      actionStyle: {
        right: 0
      },
      searchForm: {
        plateNo: '',
        quoteNo: '',
        issueOrgCode: '',
        salesOrgName: '',
        operateStartTime: null,
        operateEndTime: null,
        proposalStatus: null,
        isNotPlate: false
      },
      form: {},
      formLoading: false,
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      multipleSelection: [],
      opType: '',
      isNotPlate: false,
      startEndTime: [getLastMonthDays(), getCurrentDate()],
      orgData: [],
      defaultProps: {
        children: 'children',
        label: 'fullName'
      },
      highlightCurrent: true,
      defaultSelectOrg: {},
      loading: false,
      bizType: localStorage.getItem('bizType') || '',
      userInfo: JSON.parse(localStorage.getItem('userInfo')) || '',
      orgForm: {
        salesOrgCode: '',
        salesOrgName: '',
        salesOrgType: ''
      },
      salesman: [],
      timeoutForAdd: null
    };
  },
  created: function created() {
    var _this = this;
    //保司
    this.supplyers = getSupplyers();
    if (localStorage.getItem('userInfo')) {
      var userInfo = JSON.parse(localStorage.getItem('userInfo'));
      if (userInfo.bizType == 'DEALER') {
        customers.getDealerCustomerList(userInfo.data.organization.dealerCode).then(function (res) {
          if (res.code == 200 && res.data) {
            _this.salesman = res.data ? res.data : [];
          }
        });
      }
    }
  },
  mounted: function mounted() {
    var _orgInfo = getUserOrgInfo();
    if (_orgInfo && _orgInfo.organizationId) {
      this.searchForm.issueOrgCode = _orgInfo.orgCode;
      this.searchForm.salesOrgName = _orgInfo.fullName;
    }
    this.loadData();
    if (this.bizType === "BROKER") {
      this.initOrgData();
    }
  },
  methods: {
    getSalesman: function getSalesman(orgCode) {
      var _this2 = this;
      customers.getDealerCustomerList(orgCode).then(function (res) {
        if (res.code == 200 && res.data) {
          _this2.salesman = res.data ? res.data : [];
        }
      });
    },
    salemanNameChange: function salemanNameChange(val) {
      //监听选择业务员事件
      if (val) {
        var _user = _.find(this.salesman, function (item) {
          return item.userId == val;
        });
        if (_user) {
          this.searchForm.salemanBy = _user.userId;
          this.searchForm.salemanName = _user.userName;
          this.$forceUpdate();
        }
      }
    },
    breakData: function breakData() {
      // this.resetSearch()
      this.loadData(false);
    },
    checkQuoteNo: function checkQuoteNo(val) {//自动将业务单号转为大写
      // this.searchForm.quoteNo = character(_.toUpper(val));
    },
    getStatusColor: function getStatusColor(proposalStatus) {
      //获取投保状态对应的颜色值
      var color = '';
      switch (proposalStatus) {
        case '03':
        case '08':
        case '12':
          color = '#a0a0a0';
          break;
        case '01':
        case '02':
        case '04':
        case '05':
        case '06':
        case '07':
        case '09':
        case '10':
        case '13':
          color = '#e4a547';
          break;
        case '11':
          color = '#16c86c';
          break;
        default:
          color = '#515151';
          break;
      }
      return color;
    },
    selectItems: function selectItems(row, index) {
      //根据状态判断是否可以选中
      if (row.status) {
        return 0; //不可勾选
      } else {
        return 1; //可勾选
      }
    },
    inputPlateNo: function inputPlateNo(val) {
      //自动将车牌号码转为大写
      if (!this.searchForm.isNotPlate) {
        this.searchForm.plateNo = _.toUpper(val);
      }
    },
    checkValue: function checkValue(val) {
      //去除车架号中的i、o、q
      if (val) {
        val = val.replace(/i/g, '').replace(/I/g, '').replace(/o/g, '').replace(/O/g, '').replace(/q/g, '').replace(/Q/g, '');
      }
      this.searchForm.vin = character(_.toUpper(val));
    },
    initOrgData: function initOrgData() {
      var _this3 = this;
      //初始化组织机构树
      requestOrg.getTree(0).then(function (res) {
        if (res.data) {
          // this.orgData = res.data;
          // this.defaultSelectOrg = res.data[0];
          var _orgData = getCurrentOrganization([res.data]);
          _this3.orgData = [_orgData];
          _this3.defaultSelectOrg = _orgData;
        }
      });
    },
    orgFocus: function orgFocus(val) {
      //组织机构树焦点事件
      if (this.$refs.orgSelect && (this.$refs.orgSelect.query || this.$refs.orgSelect.query == '')) {
        this.$refs.tree.filter('');
        this.$forceUpdate();
      }
    },
    orgChange: function orgChange(val) {
      //组织机构树选择事件
      this.searchForm.salesOrgName = null;
      this.searchForm.issueOrgCode = null;
    },
    handleNodeClick: function handleNodeClick(data) {
      // 机构树点击事件
      if (this.bizType === "OEM") {
        this.searchForm.issueOrgCode = data.salesOrgCode;
        this.searchForm.salesOrgName = data.salesOrgName;
        this.searchForm.areaType = data.salesOrgType;
        this.salesman = [];
        this.searchForm.salemanBy = null;
        this.searchForm.salemanName = null;
        if (data.salesOrgCode) this.getSalesman(data.salesOrgCode);
      }
    },
    filterNode: function filterNode(value, data) {
      //机构树查询
      if (!value) return true;
      return data.fullName.indexOf(value) !== -1;
    },
    dataFilter: function dataFilter(val) {
      //机构树查询
      this.$refs.tree.filter(val);
    },
    //日期选择事件
    getPickersDate: function getPickersDate(name, val) {
      if (val && val.length > 1) {
        this[name] = val;
      } else {
        this[name] = [];
      }
    },
    noPlateNoChange: function noPlateNoChange(val) {
      //未上牌勾选事件
      if (val) {
        this.searchForm.isNotPlate = true;
        this.searchForm.plateNo = '';
      } else {
        this.searchForm.isNotPlate = false;
      }
    },
    removeItems: function removeItems(item) {
      var _this4 = this;
      //删除
      var ids = [];
      if (!_.isEmpty(item)) {
        ids.push(item.quoteId);
      } else {
        if (!_.isEmpty(this.multipleSelection)) {
          ids = _.map(this.multipleSelection, 'quoteId');
        }
      }
      if (_.isEmpty(ids)) return;
      this.$confirm("\u662F\u5426\u786E\u8BA4\u5220\u9664".concat(!_.isEmpty(item) ? '该暂存单' : '所选中的暂存单', ", \u786E\u8BA4\u5220\u9664\u540E\u4E0D\u80FD\u6062\u590D\u3002"), '温馨提示', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        type: 'warning'
      }).then(function () {
        request.delete(ids).then(function (res) {
          if (res.code == RESPONSE_SUCCESS) {
            _this4.$message({
              type: "success",
              message: res.msg
            });
            _this4.loadData();
          }
        });
      });
    },
    viewDetail: function viewDetail(type, item) {
      // 查看投保单详情
      this.opType = type;
      // let router_path = this.$route.path;
      this.$router.push({
        path: "/proposal/detail",
        query: {
          quoteId: item.quoteId,
          // back: router_path,
          plateNo: item.plateNo,
          vin: item.vin
        }
      });
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {
        plateNo: '',
        issueOrgCode: '',
        salesOrgName: '',
        operateStartTime: null,
        operateEndTime: null,
        proposalStatus: null,
        isNotPlate: false
      };
      this.orgForm = {
        salesOrgName: this.bizType === 'OEM' ? '全部' : '',
        salesOrgType: '',
        salesOrgCode: ''
      };
      sessionStorage.setItem('searchProposalForm', JSON.stringify(this.searchForm));
      this.startEndTime = [getLastMonthDays(), getCurrentDate()];
    },
    searchData: function searchData() {
      //搜索
      this.loadData(true);
    },
    // 列表新增防抖
    addItem: function addItem() {
      var _this5 = this;
      if (this.timeoutForAdd) {
        clearTimeout(this.timeoutForAdd);
      }
      this.timeoutForAdd = setTimeout(function () {
        _this5.addItem1();
      }, 500);
    },
    //列表新增
    addItem1: function addItem1() {
      sessionStorage.removeItem("quoateForm");
      var router_path = this.$route.path;
      this.$router.push({
        path: "/proposal/add",
        query: {
          back: router_path
        }
      });
    },
    dbSelected: function dbSelected(row) {
      //双击打开详情
      this.viewDetail('view', row);
    },
    loadData: function loadData(clickSearch) {
      //获取列表数据
      this.loading = true;
      this.drawer = false;
      if (this.startEndTime) {
        this.searchForm.operateStartTime = this.startEndTime[0] ? this.startEndTime[0].split(' ')[0] + ' 00:00:00' : null;
        this.searchForm.operateEndTime = this.startEndTime[1] ? this.startEndTime[1].split(' ')[0] + ' 23:59:59' : null;
      }
      this.listQuery.pageNum = clickSearch ? 1 : this.listQuery.pageNum;
      if (clickSearch) {
        sessionStorage.setItem('searchProposalForm', JSON.stringify(this.searchForm));
      }
      var self = this;
      request.getList(this.listQuery.pageNum, this.listQuery.pageSize, this.searchForm).then(function (res) {
        self.loading = false;
        if (!_.isEmpty(res.data)) {
          self.tableData = res.data.list;
          //总条数
          self.total = res.data.page.recordsTotal;
          self.$nextTick(function () {
            setTimeout(function () {
              self.$refs.multipleTable.doLayout();
            }, 500);
          });
        }
      }).catch(function (err) {
        self.loading = false;
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      //列表行选择事件
      this.multipleSelection = val;
    }
  }
};