import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getBeforeDate, getCurrentDate, getLastMonthDays } from '@/utils/common';
import dayjs from 'dayjs';
export default {
  props: {
    pickerDateTime: {
      type: Array,
      default: function _default() {
        return [getBeforeDate(6), getCurrentDate()];
      }
    },
    hasPickerOptions: {
      type: Boolean,
      default: true
    },
    noPickerOptions: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    },
    width: {
      type: String,
      default: '100%'
    },
    startDate: {
      type: String,
      default: ''
    },
    endDate: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'mini'
    }
  },
  data: function data() {
    return {
      pickerTime: this.pickerDateTime ? this.pickerDateTime : [getBeforeDate(6), getCurrentDate()],
      pickerOptionsFalse: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      nonpickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '本月',
          onClick: function onClick(picker) {
            var start = new Date(dayjs().startOf('M'));
            var end = new Date(dayjs().endOf('M'));
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            var y = new Date().getFullYear();
            var m = new Date().getMonth() + 1;
            var lastMonthDays = new Date(y, m, 0).getDate();
            console.log('lastMonthDays', lastMonthDays);
            start.setTime(start.getTime() - 3600 * 1000 * 24 * Number(lastMonthDays));
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近半年',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一年',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [{
          text: '最近一周',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '本月',
          onClick: function onClick(picker) {
            var start = new Date(dayjs().startOf('M'));
            var end = new Date(dayjs().endOf('M'));
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            var y = new Date().getFullYear();
            var m = new Date().getMonth() + 1;
            var lastMonthDays = new Date(y, m, 0).getDate();
            console.log('lastMonthDays', lastMonthDays);
            start.setTime(start.getTime() - 3600 * 1000 * 24 * Number(lastMonthDays));
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近半年',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一年',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
            picker.$emit('pick', [start, end]);
          }
        }]
      }
    };
  },
  watch: {
    pickerDateTime: function pickerDateTime(nval, oval) {
      if (nval) {
        this.pickerTime = nval ? nval : [getBeforeDate(6), getCurrentDate()];
      }
    }
  },
  created: function created() {
    this.pickerTime = this.pickerDateTime ? this.pickerDateTime : [getBeforeDate(6), getCurrentDate()];
  },
  methods: {
    //日期更改监听事件
    dateChange: function dateChange(start, end, val) {
      var searchForm = {};
      if (val && val.length > 1) {
        searchForm[start] = val[0] + ' 00:00:00';
        searchForm[end] = val[1] + ' 23:59:59';
      } else {
        searchForm[start] = '';
        searchForm[end] = '';
      }
      this.$emit('pickerDateChange', [searchForm[start], searchForm[end]]);
    }
  }
};