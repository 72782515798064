import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
/**
 * 机构出单渠道配置接口
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 列表
   * @param {*} orgCodeId 机构编码Id
   * @param {*} pageSize 每页显示条数
   * @param {*} data 内容
   */
  getList: function getList(orgCodeId) {
    return request.get(getUrl("/org-ins-corp-channel/getByOrgCode?orgCode=".concat(orgCodeId), 1));
  },
  /**
   * 详情
   * @param {*} id 查询参数
   */
  getDetail: function getDetail(id) {
    return request.get(getUrl("/org-ins-corp-channel/channel?orgInsCorpChannelId=".concat(id), 1));
  },
  /**
   * 新增
   * @param {*} data 内容
   */
  create: function create(data) {
    return request.post(getUrl("/org-ins-corp-channel/channel", 1), data);
  },
  /**
   * 修改
   * @param {*} id 查询参数
   * @param {*} data 内容
   */
  update: function update(id, data) {
    return request.put(getUrl("/org-ins-corp-channel/channel?orgInsCorpChannelId=".concat(id), 1), data);
  },
  /**
   * 删除
   * @param {*} ids 
   */
  delete: function _delete(ids) {
    return request.delete(getUrl("/org-ins-corp-channel/channel?orgInsCorpChannelIds=".concat(ids.toString()), 1));
  },
  /**
   * 获取渠道
   */
  getInsChannelByCode: function getInsChannelByCode(insCorpCode, insCityCode) {
    return request.get(getUrl("/insCorpChannel/getList", 1), {
      params: {
        insCorpCode: insCorpCode,
        insCityCode: insCityCode
      }
    });
  }
};
export default objApi;