import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FormLabel from '@/components/FormSubLabel';
import { character } from "@/utils/common";
import Rules from "@/utils/rules";
export default {
  name: 'QuoteIndex',
  components: {
    FormLabel: FormLabel
  },
  computed: {},
  watch: {},
  data: function data() {
    return {
      activeName: 'PLATENO',
      formData: {
        tabName: 'PLATENO'
      },
      formRules: {
        plateNo: [{
          required: true,
          message: '请输入车牌号',
          trigger: ['blur', 'change']
        }, {
          pattern: Rules["PLATENO_RULE"].value,
          message: "车牌号格式有误",
          trigger: ["blur", "change"]
        }],
        vin: [{
          required: false
        }, {
          min: 17,
          max: 17,
          message: "输入的车架号不等于17位",
          trigger: ["blur", "change"]
        }],
        insCityCode: [{
          required: true,
          message: '请选择投保地区',
          trigger: ['change']
        }]
      }
    };
  },
  mounted: function mounted() {
    if (sessionStorage.getItem('homeFormData')) {
      this.formData = JSON.parse(sessionStorage.getItem('homeFormData')) || {};
      this.activeName = this.formData.tabName || 'PLATENO';
      this.initFormRules();
    }
  },
  methods: {
    inputEngineNo: function inputEngineNo(val) {
      //发动机号自动转大写
      val = val.replace(/[^A-Za-z0-9-]/g, '');
      this.formData.engineNo = _.toUpper(val);
    },
    inputVin: function inputVin(val) {
      if (val) {
        val = val.replace(/i/g, '').replace(/I/g, '').replace(/o/g, '').replace(/O/g, '').replace(/q/g, '').replace(/Q/g, '');
        this.formData.vin = character(_.toUpper(val));
        this.$forceUpdate();
      }
    },
    handleTabClick: function handleTabClick(tab, event) {
      //tab切换事件
      var tabName = tab.name;
      this.formData.tabName = tabName;
      this.initFormRules();
    },
    initFormRules: function initFormRules() {
      if (this.formData.tabName == 'PLATENO') {
        this.formRules.plateNo = [{
          required: true,
          message: '请输入车牌号',
          trigger: ['blur', 'change']
        }, {
          pattern: Rules["PLATENO_RULE"].value,
          message: "车牌号格式有误",
          trigger: ["blur", "change"]
        }];
        this.formRules.vin = [{
          required: false
        }];
      } else {
        this.formRules.vin = [{
          required: true,
          message: '请输入车架号',
          trigger: ['blur', 'change']
        }, {
          min: 17,
          max: 17,
          message: "输入的车架号不等于17位",
          trigger: ["blur", "change"]
        }];
        this.formRules.plateNo = [{
          required: false
        }];
      }
    },
    quote: function quote() {
      var _this = this;
      // 验证表单
      this.$refs['form'].validate(function (valid, obj) {
        if (valid) {
          var router_path = _this.$route.path;
          var vin = _this.formData.vin;
          var plateNo = _this.formData.plateNo;
          sessionStorage.setItem('homeFormData', JSON.stringify(_this.formData));
          sessionStorage.removeItem("quoateForm");
          _this.$router.push({
            path: "/proposal/add",
            query: {
              back: router_path,
              vin: vin,
              plateNo: plateNo
            }
          });
        }
      });
    }
  }
};