import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '../../../mixins/initHeight';
import dict from '../../../mixins/dict';
import Pagination from '@/components/Pagination';
import TrackDialog from './../dialog/trackDialog';
import LossDialog from './../dialog/lossDialog';
import RepairDialog from './../dialog/repairDialog';
import CloseDialog from './../dialog/closeDialog';
import DetialDialog from './../dialog/detialDialog';
export default {
  name: 'orderMonitor',
  components: {
    Pagination: Pagination,
    TrackDialog: TrackDialog,
    LossDialog: LossDialog,
    RepairDialog: RepairDialog,
    CloseDialog: CloseDialog,
    DetialDialog: DetialDialog
  },
  mixins: [initHeight, dict],
  computed: {},
  data: function data() {
    return {
      activeName: "awaitTrack",
      drawer: false,
      searchForm: {},
      form: {},
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      defaultProps: {
        children: 'children',
        label: 'fullName'
      },
      tableData: [{
        id: "EA111",
        a: 1
      }, {
        id: "EA211",
        a: 2
      }],
      multipleSelection: [],
      opType: '',
      startEndTime: null,
      loading: false,
      orgData: [],
      highlightCurrent: true,
      showDistributionDialog: false,
      showTrackDialog: false,
      showEditDialog: false,
      showLossDialog: false,
      showRepairDialog: false,
      showCloseDialog: false,
      showDetialDialog: false,
      orderItem: {},
      dialogType: '',
      showAddDialog: false
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.loadData();
  },
  methods: {
    add: function add(val) {
      this.dialogType = val;
      this.showAddDialog = true;
    },
    dbSelected: function dbSelected(item) {
      //双击查看
      this.viewDetial(item);
    },
    viewDetial: function viewDetial(item) {
      //查看详情
      this.showDetialDialog = true;
      this.orderItem = item;
    },
    closeOrder: function closeOrder() {
      //关闭工单
      this.showCloseDialog = true;
    },
    repairVehicle: function repairVehicle() {
      //车辆维修
      this.showRepairDialog = true;
    },
    vehicleLoss: function vehicleLoss() {
      //车辆定损
      this.showLossDialog = true;
    },
    viewClicked: function viewClicked(row) {
      //编辑
      this.$router.push({
        name: 'orderMonitorDetail',
        query: {
          id: row.id
        }
      });
    },
    delClicked: function delClicked() {//删除
    },
    delAllClicked: function delAllClicked() {//批量删除
    },
    trackOrder: function trackOrder() {
      //跟进
      this.showTrackDialog = true;
    },
    distribution: function distribution() {
      //分配
      if (this.multipleSelection.length === 0) {
        this.$message.error('请选择要分配的工单');
      } else {
        this.showDistributionDialog = true;
      }
    },
    handleTabClick: function handleTabClick(tab, event) {//tab切换事件
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {};
    },
    searchData: function searchData() {
      //搜索
      this.loadData();
    },
    loadData: function loadData() {
      //获取列表数据
      this.showAddDialog = false;
      // this.loading = true;
      this.drawer = false;
      this.showDistributionDialog = false;
      this.showTrackDialog = false;
      this.showEditDialog = false;
      this.showLossDialog = false;
      this.showRepairDialog = false;
      this.showCloseDialog = false;
      this.showDetialDialog = false;
    },
    handleSelectionChange: function handleSelectionChange(val) {
      //列表行选择事件
      this.multipleSelection = val;
    }
  }
};