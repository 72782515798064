//
//
//
//
//
//
//
//
//

import commonApi from '@/api/common';
export default {
  name: 'SystemLog',
  data: function data() {
    return {
      iframeWidth: 'calc(100vw - 210px)',
      bdTokenUrl: '',
      loading: true
    };
  },
  computed: {
    sidebar: function sidebar() {
      return this.$store.state.app.sidebar;
    }
  },
  watch: {
    sidebar: {
      handler: function handler(newValue, oldValue) {
        if (newValue.opened) {
          this.iframeWidth = 'calc(100vw - 210px)';
        } else {
          this.iframeWidth = 'calc(100vw - 54px)';
        }
        this.$forceUpdate();
      },
      deep: true
    }
  },
  created: function created() {},
  mounted: function mounted() {
    this.getUrl();
  },
  methods: {
    /**
     * 获取-外部接口信息
     */
    getUrl: function getUrl() {
      var _this = this;
      var refreshUrl = window.location.href;
      commonApi.getConsoleUrl(encodeURIComponent(refreshUrl)).then(function (res) {
        if (res.code == 200) {
          _this.bdTokenUrl = res.data;
        }
        setTimeout(function () {
          _this.loading = false;
        }, 1000);
        if (_this.bdTokenUrl) window.open(_this.bdTokenUrl, '_blank');
      }).catch(function (err) {
        _this.loading = false;
      });
    }
  }
};