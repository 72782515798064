/**
 * 保单接口
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 车架号查询车辆信息
   * @param {*} vin 查询参数
   */
  vehicleByVin: function vehicleByVin(vin) {
    return request.get(getUrl("/policy/getPolicyByVin?vin=".concat(vin), 1));
  },
  /**
   * 保单录入
   * @param {*} data 内容
   */
  policyInput: function policyInput(data) {
    return request.post(getUrl("/policy/input", 1), data);
  },
  /**
   * 保单编辑
   * @param {*} data 内容
   */
  policyEdit: function policyEdit(data) {
    return request.post(getUrl("/policy/edit", 1), data);
  }
};
export default objApi;