//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PanThumb from '@/components/PanThumb';
export default {
  components: {
    PanThumb: PanThumb
  },
  props: {
    user: {
      type: Object,
      default: function _default() {
        return {
          name: '',
          email: '',
          avatar: '',
          roles: ''
        };
      }
    }
  }
};