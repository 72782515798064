import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.object.keys.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import dict from '@/mixins/dict';
import Pagination from '@/components/Pagination';
import OemSelect from '@/components/OemSelect';
import activityCenterRequest from '@/api/activityCenter';
import { commonExport } from '@/utils/common';
export default {
  name: "ActivityStatistics",
  components: {
    Pagination: Pagination,
    OemSelect: OemSelect
  },
  mixins: [initHeight, dict],
  computed: {},
  data: function data() {
    return {
      orgForm: {
        salesOrgCode: '',
        salesOrgName: ''
      },
      tableDetailLoading: false,
      tableDetailData: [],
      formLoading: false,
      showDialog: false,
      searchForm: {
        fieldBy: "",
        orderBy: ""
      },
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      loading: false,
      bizType: localStorage.getItem('bizType') || '',
      allActivity: []
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.getUserInfo();
    this.loadData();
    this.getAllActivityManagementList();
  },
  updated: function updated() {
    var _this = this;
    this.$nextTick(function () {
      _this.$refs['multipleTable'].doLayout();
    });
  },
  methods: {
    //  列表排序
    sortChange: function sortChange(_ref) {
      var prop = _ref.prop,
        order = _ref.order;
      this.searchForm.fieldBy = order ? prop : "";
      this.searchForm.orderBy = order == "descending" ? "desc" : order == "ascending" ? "asc" : "";
      this.loadData();
    },
    getAllActivityManagementList: function getAllActivityManagementList() {
      var _this2 = this;
      activityCenterRequest.getAllActivityManagementList().then(function (res) {
        if (res.code == 200) {
          _this2.allActivity = res.data.map(function (item) {
            return {
              dictName: item.couponActivityName,
              dictCode: item.id
            };
          });
        }
      });
    },
    getSummaries: function getSummaries(param) {
      var columns = param.columns,
        data = param.data;
      var sums = [];
      columns.forEach(function (col, index) {
        if (index == 0) {
          sums[index] = "合计";
          return;
        }
        var values = data.map(function (item) {
          return Number(item[col.property]);
        });
        if (!values.every(function (value) {
          return isNaN(value);
        }) && ["count", "totalAmount", "countPolicy", "countBaseData"].includes(col.property)) {
          sums[index] = values.reduce(function (prev, curr) {
            var value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] += "";
        } else {
          sums[index] = '';
        }
      });
      return sums;
    },
    viewDetail: function viewDetail(item) {
      this.showDialog = true;
      this.getActivityStatisticsDetailList(item.statisticsId);
    },
    getActivityStatisticsDetailList: function getActivityStatisticsDetailList(statisticsId) {
      var _this3 = this;
      if (statisticsId) {
        this.tableDetailLoading = true;
        activityCenterRequest.getActivityStatisticsDetailList({
          statisticsId: statisticsId
        }).then(function (res) {
          if (res.code == 200) {
            _this3.tableDetailData = res.data;
          }
          _this3.tableDetailLoading = false;
        });
      }
    },
    getUserInfo: function getUserInfo() {
      if (this.bizType === 'OEM') return;
      var useInfo = JSON.parse(localStorage.getItem("userInfo")) || {};
      if (!_.isEmpty(useInfo)) {
        this.searchForm.orgCode = useInfo.data.orgCode;
        // this.searchForm.orgName = useInfo.data.orgName;
        this.orgForm = {
          salesOrgCode: useInfo.data.orgCode,
          salesOrgName: useInfo.data.orgName
        };
      }
    },
    nodeClick: function nodeClick(data) {
      this.searchForm.orgCode = data.salesOrgCode;
      this.searchForm.areaType = data.salesOrgType;
      // this.searchForm.orgName = data.salesOrgName;
    },
    // 导出
    exportFn: function exportFn() {
      var exporParams = {
        total: this.total,
        limitNum: 200000,
        fileName: '政策统计',
        exportUrl: '/oc-core-service/couponstatistics/search',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {
        fieldBy: this.searchForm.fieldBy,
        orderBy: this.searchForm.orderBy
      };
      this.orgForm = {
        salesOrgCode: '',
        salesOrgName: ''
      };
      this.getUserInfo();
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    loadData: function loadData() {
      var _this4 = this;
      //获取列表数据
      this.loading = true;
      this.showImportDialog = false;
      this.showDialog = false;
      activityCenterRequest.getActivityStatisticsList(_objectSpread(_objectSpread({}, this.listQuery), this.searchForm)).then(function (res) {
        if (res.code === 200) {
          _this4.tableData = res.data.list;
          _this4.total = res.data.page.recordsTotal;
        }
        _this4.loading = false;
      }).catch(function (err) {
        _this4.loading = false;
      });
    },
    // 刷新
    breakData: function breakData() {
      // this.resetSearch()
      this.loadData();
    }
  }
};