var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-m-cls" },
    [
      _c(
        "Dialog",
        {
          attrs: {
            title: _vm.opType == "edit" ? "编辑" : "新增",
            width: "1000px",
            "form-loading": _vm.formLoading,
          },
          model: {
            value: _vm.showDialog,
            callback: function ($$v) {
              _vm.showDialog = $$v
            },
            expression: "showDialog",
          },
        },
        [
          _c(
            "div",
            {
              ref: "header",
              staticClass: "header-cls",
              staticStyle: { "max-height": "400px", overflow: "auto" },
              attrs: { slot: "content" },
              slot: "content",
            },
            [
              _c(
                "el-card",
                { attrs: { shadow: "never" } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "detail",
                      staticStyle: { "margin-bottom": "-20px" },
                      attrs: {
                        model: _vm.form,
                        rules: _vm.rules,
                        "label-width": "110px",
                        size: "mini",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "保险公司：",
                                    prop: "insCorpCode",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        filterable: "",
                                        placeholder: "请选择保险公司",
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.form.insCorpCode,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "insCorpCode", $$v)
                                        },
                                        expression: "form.insCorpCode",
                                      },
                                    },
                                    _vm._l(
                                      _vm.supplyers,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label: item.shortName,
                                            value: item.code,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "选择区域：" } },
                                [
                                  _c("el-cascader", {
                                    ref: "address",
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      filterable: "",
                                      props: { checkStrictly: true },
                                      placeholder: "请选择业务区域",
                                      options: _vm.regionOptions,
                                    },
                                    on: { change: _vm.handleChange },
                                    model: {
                                      value: _vm.address,
                                      callback: function ($$v) {
                                        _vm.address = $$v
                                      },
                                      expression: "address",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "险种：", prop: "riskType" },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        filterable: "",
                                        placeholder: "请选择险种",
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.form.riskType,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "riskType", $$v)
                                        },
                                        expression: "form.riskType",
                                      },
                                    },
                                    _vm._l(
                                      _vm.riskEnum,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label: item.text,
                                            value: item.code,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "模板名称：",
                                    prop: "templateName",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请输入模板名称",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.form.templateName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "templateName",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "form.templateName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "优先级：",
                                    prop: "priority",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请输入优先级",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.form.priority,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "priority",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "form.priority",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            {
                              staticStyle: { "margin-bottom": "30px" },
                              attrs: { span: 12 },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "上传文件：",
                                    prop: "pdfFileId",
                                  },
                                },
                                [
                                  _c(
                                    "el-upload",
                                    {
                                      ref: "upload",
                                      attrs: {
                                        "on-preview": _vm.downloadFile,
                                        "on-remove": _vm.fileRemove,
                                        limit: 1,
                                        "before-upload": _vm.beforeUpload,
                                        action: "",
                                        "http-request": _vm.uploadFile,
                                        "file-list": _vm.fileList,
                                        accept: ".xlsx",
                                        "list-type": "pdf",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        { attrs: { size: "mini", plain: "" } },
                                        [_vm._v("选择文件")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "center",
                "margin-top": "8px",
              },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini", plain: "", icon: "el-icon-check" },
                  on: { click: _vm.save },
                },
                [_vm._v("保存")]
              ),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-close", size: "mini" },
                  on: {
                    click: function ($event) {
                      return _vm.closePage(false)
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }