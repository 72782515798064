import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import Pagination from '@/components/Pagination';
import request from '@/api/message';
import NoticeDialog from './dialog/noticeDialog';
import { getUserOrgInfo } from '@/utils/common';
export default {
  name: "SystemBulletin",
  components: {
    Pagination: Pagination,
    NoticeDialog: NoticeDialog
  },
  mixins: [initHeight],
  data: function data() {
    return {
      searchForm: {},
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      total: 0,
      loading: false,
      tableData: [],
      currentType: '',
      currentId: '',
      showNoticeDialog: false,
      drawer: false,
      startEndDate: [],
      noticeLevelList: [{
        value: 'ORDINARY',
        label: '普通'
      }, {
        value: 'HIGH_LEVEL',
        label: '中级'
      }, {
        value: 'URGENT',
        label: '紧急'
      }],
      noticeRangeList: [{
        value: 'ALL',
        label: '全体用户'
      }, {
        value: 'ORG',
        label: '指定机构'
      }],
      readedList: [{
        value: true,
        label: '是'
      }, {
        value: false,
        label: '否'
      }],
      multipleSelection: []
    };
  },
  created: function created() {
    this.searchForm = {};
    this.loadData();
  },
  methods: {
    // 日期选择事件
    dateChange: function dateChange(start, end, val) {
      if (val && val.length > 1) {
        this.searchForm[start] = "".concat(val[0], " 00:00:00");
        this.searchForm[end] = "".concat(val[1], " 23:59:59");
      } else {
        this.searchForm[start] = '';
        this.searchForm[end] = '';
      }
      ;
    },
    // 刷新
    breakData: function breakData() {
      this.loadData();
    },
    // 重置搜索表单
    resetSearch: function resetSearch() {
      this.searchForm = {};
    },
    // 搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    //获取列表数据
    loadData: function loadData() {
      var _this = this;
      var isDialogNoRefresh = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.showNoticeDialog = false;
      this.drawer = false;
      if (isDialogNoRefresh) return;
      this.loading = true;
      var params = _objectSpread(_objectSpread({}, this.listQuery), this.searchForm);
      request.getUserNotice(params).then(function (res) {
        _this.loading = false;
        if (res.code === 200 && res.data) {
          _this.tableData = res.data.list;
          _this.total = res.data.page.recordsTotal;
          _this.$Bus.$emit('noticeLoadData');
        }
        ;
      }).catch(function (err) {
        _this.loading = false;
      });
    },
    // 详情公告
    toDetails: function toDetails(item) {
      this.$router.push({
        path: '/message/noticeDetailPage',
        query: {
          noticeId: item.noticeId,
          tabName: "\u516C\u544A\u8BE6\u60C5-".concat(item.noticeTitle)
        }
      });
    },
    // 列表行选择事件
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 是否可选
    selectableFn: function selectableFn(row) {
      return !row.isRead;
    },
    markRead: function markRead(boolean) {
      var _this2 = this;
      var ids = [];
      var _orgInfo = getUserOrgInfo();
      var postData = {
        readUserId: _orgInfo.userId,
        isAllRead: boolean
      };
      if (!boolean) {
        ids = _.map(this.multipleSelection, 'noticeId');
        postData.noticeIds = ids.toString();
      }
      ;
      request.batchCreateRead(postData).then(function (res) {
        if (res.code == 200) {
          _this2.$message.success(res.msg);
          _this2.loadData();
          _this2.$Bus.$emit('noticeLoadData');
        }
      });
    }
  }
};