import _createForOfIteratorHelper from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.to-fixed.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as echarts from "echarts";
import reportForm from "@/api/reportForm";
import QuarterDate from "@/components/QuarterDate";
import OemSelect from '@/components/OemSelect';
import InsuranceDialog from './dialog/insuranceDialog';
import VehicleSalesDialog from './dialog/vehicleSalesDialog';
import carService from '@/api/carService';
import { getCurrentDate, getDayOfMonth, getUserOrgInfoDealerOem, getPrevCurYear, getNowDate } from "@/utils/common";
export default {
  name: 'CheckReport',
  data: function data() {
    return {
      carTypeVal: [],
      CarOptions: [],
      containerH: '90%',
      userInfo: JSON.parse(localStorage.getItem('userInfo')) || {},
      bizType: localStorage.getItem('bizType') || "",
      searchForm: {
        statisticsReportDimensionality: "TIME",
        //tab维度
        statisticsReportTimeCycle: "MONTH",
        //月
        insCorpLimit: null,
        startDate: "".concat(new Date().getFullYear(), "-01-01 00:00:00"),
        // endDate: getPrevCurYear() + ' 23:59:59',
        endDate: getCurrentDate() + ' 23:59:59',
        curStartYear: "".concat(new Date().getFullYear()),
        curEndYear: "".concat(new Date().getFullYear())
      },
      startEndTime: ["".concat(new Date().getFullYear(), "-01-01"), getCurrentDate()],
      newCarChart: {},
      //柱状图
      newCarTitle: '新车投保率',
      renewalTitle: '综合续保率',
      littleOldCarTitle: '次新车投保率',
      NewCarLegend: ["上年同期新车投保量", "当期新车销量", "当期新车投保量", "当期新车投保率", "上年同期新保率"],
      Renewallegend: ["上年同期续保量", "上年同期投保总量", "当期续保量", "当期续保率", "上年同期续保率"],
      LittleOldCarlegend: ["上年同期次新车投保量", "上年同期新车投保量", "当期次新车投保量", "当期次新车投保率", "上年同期次新车投保率"],
      NewCarColor: ['#F6E3A2', '#92DCB1', '#5CC486', '#09A648', '#EDCB4F'],
      RenewalColor: ['#EDCB4F', '#A2BEF6', '#6B9BFA', '#CD72FB', '#EDCB4F'],
      LittleOldCarColor: ['#EDCB4F', '#F6E3A2', '#5CC486', '#09A648', '#EDCB4F'],
      // 新车
      newCarForm: {
        xAxisData: [],
        //新车投保率-xAxisData
        barData: [],
        //新车投保量-蓝区柱状图data
        lastBarData: [],
        //去年同期新车投保量-灰区柱状图data
        lineData: [],
        //新车投保占比-折线图(左侧刻度尺)
        lastLineData: [],
        //去年同期新车投保占比-折线图(左侧刻度尺)
        allInsuredData: [],
        //新车销售总量
        proportionData: [],
        // 占比率
        newCarTotal: {}
      },
      // 续保
      RenewalForm: {
        xAxisData: [],
        //综合续保率-xAxisData
        barData: [],
        //今年续保量-蓝区柱状图data
        lastBarData: [],
        //去年同期车辆续保量-灰区柱状图data
        lineData: [],
        //续保投占比-折线图(左侧刻度尺)
        lastLineData: [],
        //去年同期续保占比-折线图(左侧刻度尺)
        allInsuredData: [],
        //去年总投保量
        proportionData: [],
        // 占比率
        RenewalInsuranceTotal: {}
      },
      // 次新车
      littleOldCarForm: {
        xAxisData: [],
        // 次新车投保率-xAxisData
        barData: [],
        // 次新车投保量-蓝区柱状图data
        lastBarData: [],
        // 去年同期次新车投保量-灰区柱状图data
        lineData: [],
        // 次新车投占比-折线图(左侧刻度尺)
        lastLineData: [],
        // 去年同期次新车投保占比-折线图(左侧刻度尺)
        allInsuredData: [],
        //去年新车投保量
        proportionData: [],
        //去 占比率
        littleOldCarTotal: {}
      },
      orgForm: {
        salesOrgCode: '',
        salesOrgName: '',
        salesOrgType: ''
      },
      startPickerOptions: this.startOptions(),
      endPickerOptions: this.endOptions(),
      curPickerOptions: this.curOptions(),
      quarterArr: [],
      saveQuarter: null,
      quarterYear: '',
      newCarLoading: false,
      oldCarLoading: false,
      renewalLoading: false,
      loading: false,
      showInsuranceDialog: false,
      showVehicleSalesDialog: false,
      VUE_APP_FLAG: process.env.VUE_APP_FLAG
    };
  },
  components: {
    QuarterDate: QuarterDate,
    OemSelect: OemSelect,
    InsuranceDialog: InsuranceDialog,
    VehicleSalesDialog: VehicleSalesDialog
  },
  created: function created() {
    this.getCarBrandList();
  },
  mounted: function mounted() {
    if (this.bizType === "DEALER") {
      this.initData();
    }
    ;
    this.loadData();
    this.loadTatolList();
    var self = this;
    self.containerH = document.body.clientHeight - 232 + 'px';
    //柱状图自适应  resize
    window.onresize = function () {
      echarts.init(document.getElementById('newCarChart')).resize();
      echarts.init(document.getElementById('RenewalInsurance')).resize();
      echarts.init(document.getElementById('littleOldCarChart')).resize();
    };
  },
  methods: {
    //传入年份和月份 获取该年对应月份的天数
    getMonthDays: function getMonthDays(year, month) {
      var thisDate = new Date(year, month, 0); //当天数为0 js自动处理为上一月的最后一天
      return thisDate.getDate();
    },
    CarHandleChange: function CarHandleChange(val) {
      this.loadData();
      this.loadTatolList();
    },
    getCarBrandList: function getCarBrandList() {
      var _this = this;
      carService.brandList(1, 100).then(function (res) {
        if (res.code == 200 && res.data.list) {
          var brandLists = [];
          var _iterator = _createForOfIteratorHelper(res.data.list),
            _step;
          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var i = _step.value;
              brandLists.push({
                value: i.brandNo,
                label: i.brandName,
                children: []
              });
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }
          _this.CarOptions = brandLists;
          _this.getCarSeriesList();
        } else {
          _this.CarOptions = [];
        }
      });
    },
    getCarSeriesList: function getCarSeriesList() {
      var _this2 = this;
      carService.carSeriesList(1, 10000).then(function (res) {
        if (res.code == 200 && res.data.list) {
          var dataList = res.data.list ? res.data.list : [];
          for (var i = 0; i < dataList.length; i++) {
            for (var j = 0; j < _this2.CarOptions.length; j++) {
              if (dataList[i].brandNo == _this2.CarOptions[j].value) {
                _this2.CarOptions[j].children.push({
                  value: dataList[i].seriesNo,
                  label: dataList[i].seriesName
                });
              }
            }
          }
        }
      });
    },
    startOptions: function startOptions() {
      var self = this;
      return {
        disabledDate: function disabledDate(time) {
          if (self.searchForm && self.searchForm.curEndYear) {
            return time.getTime() > new Date(self.searchForm.curEndYear).getTime();
          }
          ;
          return false;
        }
      };
    },
    endOptions: function endOptions() {
      var self = this;
      return {
        disabledDate: function disabledDate(time) {
          if (self.searchForm && self.searchForm.curStartYear) {
            return time.getTime() < new Date(self.searchForm.curStartYear).getTime() || time.getTime() >= new Date(new Date().getTime()).getTime();
          }
          ;
          return false;
        }
      };
    },
    curOptions: function curOptions() {
      return {
        disabledDate: function disabledDate(time) {
          return time.getTime() >= new Date(new Date().getTime()).getTime();
        }
      };
    },
    // 初始化数据
    initData: function initData() {
      var orgObj = getUserOrgInfoDealerOem(this.bizType);
      this.searchForm.orgCode = orgObj.salesOrgCode;
      this.searchForm.orgName = orgObj.salesOrgName;
    },
    //清空
    clearForm: function clearForm() {
      var boolean = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.newCarForm = {
        xAxisData: [],
        barData: [],
        lastBarData: [],
        lineData: [],
        lastLineData: [],
        allInsuredData: [],
        proportionData: [],
        newCarTotal: this.newCarForm.newCarTotal
      };
      this.RenewalForm = {
        xAxisData: [],
        barData: [],
        lastBarData: [],
        lineData: [],
        lastLineData: [],
        allInsuredData: [],
        proportionData: [],
        RenewalInsuranceTotal: this.RenewalForm.RenewalInsuranceTotal
      };
      this.littleOldCarForm = {
        xAxisData: [],
        barData: [],
        lastBarData: [],
        lineData: [],
        lastLineData: [],
        allInsuredData: [],
        proportionData: [],
        littleOldCarTotal: this.littleOldCarForm.littleOldCarTotal
      };
      if (boolean) {
        this.orgForm = {
          salesOrgName: '全部',
          salesOrgType: '',
          salesOrgCode: ''
        };
        this.searchForm.areaType = '';
        this.searchForm.orgCode = this.bizType === 'DEALER' ? this.searchForm.orgCode : '';
        this.searchForm.startDate = '';
        this.searchForm.endDate = '';
        this.searchForm.insCorpLimit = null;
        this.startEndTime = [];
        this.quarterArr = [];
        this.quarterYear = '';
        this.searchForm.curStartYear = '';
        this.searchForm.curEndYear = '';
        this.saveQuarter = null;
      }
    },
    //tab切换
    handleTabClick: function handleTabClick(val) {
      if (!val) return;
      this.clearForm(true);
      this.searchForm.startDate = "".concat(new Date().getFullYear(), "-01-01 00:00:00");
      this.searchForm.endDate = getCurrentDate() + ' 23:59:59';
      this.searchForm.statisticsReportTimeCycle = 'MONTH';
      this.startEndTime = ["".concat(new Date().getFullYear(), "-01-01"), getCurrentDate()];
      this.loadData();
      this.loadTatolList();
    },
    //时间周期切换
    handleTimeTabClick: function handleTimeTabClick(val) {
      if (!val) return;
      this.clearForm(true);
      switch (val) {
        case 'MONTH':
          this.searchForm.startDate = "".concat(getCurrentDate().split('-')[0], "-01-01 00:00:00");
          this.searchForm.endDate = getCurrentDate() + ' 23:59:59';
          this.startEndTime = ["".concat(getCurrentDate().split('-')[0], "-01-01"), getCurrentDate()];
          break;
        case 'QUARTER':
          this.quarterArr = [1, 4];
          this.quarterYear = getCurrentDate().split('-')[0];
          this.searchForm.startDate = "".concat(new Date().getFullYear(), "-01-01 00:00:00");
          this.searchForm.endDate = getPrevCurYear() + ' 23:59:59';
          break;
        case 'YEARS':
          this.searchForm.startDate = "".concat(getCurrentDate().split('-')[0], "-01-01 00:00:00");
          this.searchForm.endDate = getCurrentDate() + ' 23:59:59';
          this.searchForm.curStartYear = "".concat(getCurrentDate().split('-')[0], "-01-01");
          this.searchForm.curEndYear = getCurrentDate();
          break;
      }
      this.loadData();
      this.loadTatolList();
    },
    // 月份选择
    montnChange: function montnChange(val) {
      this.clearForm();
      if (!val) {
        this.startEndTime = [];
        this.searchForm.startDate = "".concat(getCurrentDate().split('-')[0], "-01-01 00:00:00");
        this.searchForm.endDate = getCurrentDate() + ' 23:59:59';
      } else {
        this.searchForm.startDate = val[0] + ' 00:00:00';
        this.searchForm.endDate = getNowDate(val[1])[1] + ' 23:59:59';
      }
      ;
      this.loadData();
    },
    // 年度选择
    yearChange: function yearChange(val, type) {
      this.clearForm();
      if (type == 'start') {
        if (val) {
          this.searchForm.startDate = val + '-01-01 00:00:00';
        } else {
          this.searchForm.startDate = getCurrentDate().split('-')[0] + '-01-01 00:00:00';
        }
        ;
      } else {
        if (val) {
          if (val == getCurrentDate().split('-')[0]) {
            this.searchForm.endDate = "".concat(val).concat(getCurrentDate().slice(4), " 23:59:59");
          } else {
            this.searchForm.endDate = val + '-12-31 23:59:59';
          }
        } else {
          this.searchForm.endDate = getCurrentDate() + ' 23:59:59';
        }
        ;
      }
      ;
      this.loadData();
    },
    // 季度年切换
    quarterYearChange: function quarterYearChange(val) {
      this.clearForm();
      this.quarterYear = val;
      this.quarterChange(this.quarterArr);
    },
    // 季度改变
    quarterChange: function quarterChange(val) {
      if (val.length) {
        this.saveQuarter = val[0];
      } else {
        this.quarterArr = [this.saveQuarter];
      }
      ;
      this.quarterArr.sort();
      if (this.quarterArr.length == 1) {
        this.quarterChangeTime(true, this.quarterArr[0]);
      } else {
        this.quarterChangeTime(false, this.quarterArr[0], 'startDate');
        this.quarterChangeTime(false, this.quarterArr[1], 'endDate');
      }
      ;
      this.loadData();
    },
    quarterChangeTime: function quarterChangeTime(type, currentNum, status) {
      if (type) {
        switch (currentNum) {
          case 1:
            this.searchForm.startDate = "".concat(this.quarterYear, "-01-01 00:00:00");
            this.searchForm.endDate = "".concat(this.quarterYear, "-03-").concat(this.getMonthDays(this.quarterYear, 3), " 23:59:59");
            break;
          case 2:
            this.searchForm.startDate = "".concat(this.quarterYear, "-04-01 00:00:00");
            this.searchForm.endDate = "".concat(this.quarterYear, "-06-").concat(this.getMonthDays(this.quarterYear, 6), " 23:59:59");
            break;
          case 3:
            this.searchForm.startDate = "".concat(this.quarterYear, "-07-01 00:00:00");
            this.searchForm.endDate = "".concat(this.quarterYear, "-09-").concat(this.getMonthDays(this.quarterYear, 9), " 23:59:59");
            break;
          case 4:
            this.searchForm.startDate = "".concat(this.quarterYear, "-10-01 00:00:00");
            this.searchForm.endDate = "".concat(this.quarterYear, "-12-").concat(this.getMonthDays(this.quarterYear, 12), " 23:59:59");
            break;
        }
        ;
      } else {
        switch (currentNum) {
          case 1:
            this.searchForm[status] = status === 'startDate' ? "".concat(this.quarterYear, "-01-01 00:00:00") : "".concat(this.quarterYear, "-03-").concat(this.getMonthDays(this.quarterYear, 3), " 23:59:59");
            break;
          case 2:
            this.searchForm[status] = status === 'startDate' ? "".concat(this.quarterYear, "-04-01 00:00:00") : "".concat(this.quarterYear, "-06-").concat(this.getMonthDays(this.quarterYear, 6), " 23:59:59");
            break;
          case 3:
            this.searchForm[status] = status === 'startDate' ? "".concat(this.quarterYear, "-07-01 00:00:00") : "".concat(this.quarterYear, "-09-").concat(this.getMonthDays(this.quarterYear, 9), " 23:59:59");
            break;
          case 4:
            this.searchForm[status] = status === 'startDate' ? "".concat(this.quarterYear, "-10-01 00:00:00") : "".concat(this.quarterYear, "-12-").concat(this.getMonthDays(this.quarterYear, 12), " 23:59:59");
            break;
        }
        ;
      }
      ;
    },
    // 季度选择
    monthArr: function monthArr(val) {
      this.clearForm();
      this.searchForm.startDate = val[0];
      this.searchForm.endDate = val[1];
      this.loadData();
    },
    //保司选择
    insCorpChange: function insCorpChange(val) {
      this.clearForm();
      this.loadData();
      this.loadTatolList();
    },
    nodeClick: function nodeClick(val) {
      //切换机构
      this.clearForm();
      this.searchForm.orgCode = val.salesOrgCode;
      this.searchForm.areaType = val.salesOrgType;
      this.loadData();
      this.loadTatolList();
    },
    loadData: function loadData() {
      var _this3 = this;
      this.showInsuranceDialog = false;
      this.showVehicleSalesDialog = false;
      if (this.carTypeVal) {
        var _cjVhcSeriesNo = [];
        this.carTypeVal.forEach(function (item) {
          return _cjVhcSeriesNo.push(item[1]);
        });
        this.searchForm.cjVhcSeriesNo = _cjVhcSeriesNo.toString();
        console.log('cjVhcSeriesNo', this.searchForm.cjVhcSeriesNo);
      }
      this.loading = true;
      //新保
      reportForm.getReportList(this.searchForm).then(function (res) {
        if (res.code == '200' && res.data) {
          _this3.loading = false;
          _this3.clearForm();
          if (_.isEmpty(res.data)) {
            setTimeout(function () {
              if (_this3.searchForm.statisticsReportDimensionality == 'INS_CORP') {
                _this3.insuranceDrawLine('newCarChart', _this3.newCarForm, '新车投保量', ["当期新车投保量", "占比率"], ['#5CC486', '#f6e3a2']);
              } else {
                _this3.drawLine('newCarChart', _this3.newCarForm, _this3.newCarTitle, _this3.NewCarLegend, _this3.NewCarColor);
              }
            }, 1000);
            setTimeout(function () {
              if (_this3.searchForm.statisticsReportDimensionality == 'INS_CORP') {
                _this3.insuranceDrawLine('RenewalInsurance', _this3.RenewalForm, '综合续保量', ["当期续保量", "占比率"], ['#6B9BFA', '#f6e3a2']);
              } else {
                _this3.drawLine('RenewalInsurance', _this3.RenewalForm, _this3.renewalTitle, _this3.Renewallegend, _this3.RenewalColor);
              }
            }, 1000);
            setTimeout(function () {
              if (_this3.searchForm.statisticsReportDimensionality == 'INS_CORP') {
                _this3.insuranceDrawLine('littleOldCarChart', _this3.littleOldCarForm, '次新车投保量', ["当期次新车投保量", "占比率"], ['#5CC486', '#f6e3a2']);
              } else {
                _this3.drawLine('littleOldCarChart', _this3.littleOldCarForm, _this3.littleOldCarTitle, _this3.LittleOldCarlegend, _this3.LittleOldCarColor);
              }
            }, 1000);
          } else {
            res.data.forEach(function (item) {
              if (item.statisticsReportType === 'NEW_INSURANCE') {
                // 新保
                item.list.forEach(function (val) {
                  _this3.newCarForm.xAxisData.push(val.horizontalAxis); // 横轴数值
                  _this3.newCarForm.barData.push(val.insuredQT); // 新车数(投保/续保/次新车)
                  _this3.newCarForm.lastBarData.push(val.sameTimeInsuredQT); // 去年同期新车数(投保/续保/次新车)
                  _this3.newCarForm.lineData.push(val.insuredQTRate); // 新车占比(投保/续保/次新车)
                  _this3.newCarForm.lastLineData.push(val.sameTimeInsuredQTRate); // 去年同期新车占比(投保/续保/次新车)
                  _this3.newCarForm.allInsuredData.push(val.allInsuredQT); // 总投保量(新保/续保/次新车)
                  _this3.newCarForm.proportionData.push(val.proportion); // 占比率(新保/续保/次新车)
                });

                if (_this3.searchForm.statisticsReportDimensionality == 'INS_CORP') {
                  _this3.newCarForm = {
                    xAxisData: _this3.newCarForm.xAxisData,
                    barData: _this3.newCarForm.barData,
                    newCarTotal: _this3.newCarForm.newCarTotal,
                    proportionData: _this3.newCarForm.proportionData
                  };
                }
                setTimeout(function () {
                  if (_this3.searchForm.statisticsReportDimensionality == 'INS_CORP') {
                    _this3.insuranceDrawLine('newCarChart', _this3.newCarForm, '新车投保量', ["当期新车投保量", "占比率"], ['#5CC486', '#f6e3a2']);
                  } else {
                    _this3.drawLine('newCarChart', _this3.newCarForm, _this3.newCarTitle, _this3.NewCarLegend, _this3.NewCarColor);
                  }
                }, 1000);
              } else if (item.statisticsReportType === 'RENEWAL') {
                // 续保
                item.list.forEach(function (val) {
                  _this3.RenewalForm.xAxisData.push(val.horizontalAxis); // 横轴
                  _this3.RenewalForm.barData.push(val.insuredQT);
                  _this3.RenewalForm.lastBarData.push(val.sameTimeInsuredQT);
                  _this3.RenewalForm.lineData.push(val.insuredQTRate);
                  _this3.RenewalForm.lastLineData.push(val.sameTimeInsuredQTRate);
                  _this3.RenewalForm.allInsuredData.push(val.allInsuredQT);
                  _this3.RenewalForm.proportionData.push(val.proportion);
                });
                if (_this3.searchForm.statisticsReportDimensionality == 'INS_CORP') {
                  _this3.RenewalForm = {
                    xAxisData: _this3.RenewalForm.xAxisData,
                    barData: _this3.RenewalForm.barData,
                    newCarTotal: _this3.RenewalForm.newCarTotal,
                    proportionData: _this3.newCarForm.proportionData
                  };
                }
                setTimeout(function () {
                  if (_this3.searchForm.statisticsReportDimensionality == 'INS_CORP') {
                    _this3.insuranceDrawLine('RenewalInsurance', _this3.RenewalForm, '综合续保量', ["当期续保量", "占比率"], ['#6B9BFA', '#f6e3a2']);
                  } else {
                    _this3.drawLine('RenewalInsurance', _this3.RenewalForm, _this3.renewalTitle, _this3.Renewallegend, _this3.RenewalColor);
                  }
                }, 1000);
              } else if (item.statisticsReportType === 'LITTLE_OLD_CAR') {
                // 次新车
                item.list.forEach(function (val) {
                  _this3.littleOldCarForm.xAxisData.push(val.horizontalAxis); // 横轴
                  _this3.littleOldCarForm.barData.push(val.insuredQT);
                  _this3.littleOldCarForm.lastBarData.push(val.sameTimeInsuredQT);
                  _this3.littleOldCarForm.lineData.push(val.insuredQTRate);
                  _this3.littleOldCarForm.lastLineData.push(val.sameTimeInsuredQTRate);
                  _this3.littleOldCarForm.allInsuredData.push(val.allInsuredQT);
                  _this3.littleOldCarForm.proportionData.push(val.proportion);
                });
                if (_this3.searchForm.statisticsReportDimensionality == 'INS_CORP') {
                  _this3.littleOldCarForm = {
                    xAxisData: _this3.littleOldCarForm.xAxisData,
                    barData: _this3.littleOldCarForm.barData,
                    newCarTotal: _this3.littleOldCarForm.newCarTotal,
                    proportionData: _this3.newCarForm.proportionData
                  };
                }
                setTimeout(function () {
                  if (_this3.searchForm.statisticsReportDimensionality == 'INS_CORP') {
                    _this3.insuranceDrawLine('littleOldCarChart', _this3.littleOldCarForm, '次新车投保量', ["当期次新车投保量", "占比率"], ['#5CC486', '#f6e3a2']);
                  } else {
                    _this3.drawLine('littleOldCarChart', _this3.littleOldCarForm, _this3.littleOldCarTitle, _this3.LittleOldCarlegend, _this3.LittleOldCarColor);
                  }
                }, 1000);
              }
              ;
            });
          }
        }
        ;
      }).catch(function (err) {
        _this3.oldCarLoading = false;
      });
    },
    // 报表统计
    loadTatolList: function loadTatolList() {
      var _this4 = this;
      var params = {
        orgCode: this.searchForm.orgCode,
        areaType: this.searchForm.areaType,
        insCorpLimit: this.searchForm.insCorpLimit
      };
      if (this.carTypeVal) {
        var _cjVhcSeriesNo = [];
        this.carTypeVal.forEach(function (item) {
          return _cjVhcSeriesNo.push(item[1]);
        });
        params.cjVhcSeriesNo = _cjVhcSeriesNo.toString();
      }
      reportForm.getReportTotalList(params).then(function (res) {
        if (res.code == 200) {
          res.data && res.data.forEach(function (item) {
            if (item.statisticsReportType === 'NEW_INSURANCE') {
              _this4.newCarForm.newCarTotal = item;
            } else if (item.statisticsReportType === 'RENEWAL') {
              _this4.RenewalForm.RenewalInsuranceTotal = item;
            } else if (item.statisticsReportType === 'LITTLE_OLD_CAR') {
              _this4.littleOldCarForm.littleOldCarTotal = item;
            }
            ;
          });
        }
        ;
      });
    },
    // 柱形图  
    drawLine: function drawLine(idName, _data, _title, _legend, _colorArr) {
      var newCarChart = echarts.init(document.getElementById(idName));
      //标题
      var option = {
        grid: {
          padding: [50, 0]
        },
        title: {
          text: _title,
          left: "center",
          top: "0%",
          fontSize: "20",
          paddingBottom: 50
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999"
            }
          },
          formatter: function formatter(params) {
            var value = "".concat(params[0].name, "<br/>");
            params.forEach(function (val) {
              if (val.seriesName.indexOf('率') != -1) {
                value += "".concat(val.marker).concat(val.seriesName, "\uFF1A").concat(val.value == '-' ? val.value : val.value + '%', "<br/>");
              } else {
                value += "".concat(val.marker).concat(val.seriesName, "\uFF1A").concat(val.value, "<br/>");
              }
            });
            // let temp = `${params[0].name}<br/>
            // ${params[0].marker}${params[0].seriesName}：${params[0].value}<br/>
            // ${params[1].marker}${params[1].seriesName}：${params[1].value}<br/>
            // ${params[2].marker}${params[2].seriesName}：${params[2].value}<br/>
            // ${params[3].marker}${params[3].seriesName}：${params[3].value == '-' ? params[3].value : (params[3].value +'%')}<br/>
            // ${params[4].marker}${params[4].seriesName}：${params[4].value == '-' ? params[4].value : (params[4].value +'%')}`;
            return value;
          }
        },
        legend: {
          // data: _data.legendData,
          data: _legend,
          bottom: "-0.5%"
        },
        // x轴
        xAxis: [{
          type: "category",
          data: _data.xAxisData,
          axisPointer: {
            type: "shadow"
          },
          axisLine: {
            show: true // 隐藏x轴轴线
          },

          axisTick: {
            show: false,
            //是否隐藏x轴刻度线
            alignWithLabel: true
          },
          axisLabel: {
            //设置坐标轴样式
            fontSize: 10,
            interval: 0,
            rotate: 10
          }
        }],
        // y轴刻度尺
        yAxis: [{
          type: "value",
          min: 0,
          // max: function (value) {
          //     return value.max + (value.max * 0.5)
          // },
          axisLabel: {
            formatter: "{value}"
          },
          splitLine: {
            show: false //是否隐藏网格线
          }
        }, {
          type: "value",
          min: 0,
          max: 100,
          axisLabel: {
            formatter: function formatter(value) {
              return value.toFixed(2) + "%";
            }
          }
        }],
        series: [
        // 柱状图
        {
          name: _legend[0],
          type: "bar",
          barGap: "5%",
          data: _data.lastBarData,
          color: _colorArr[0]
        }, {
          name: _legend[1],
          type: "bar",
          data: _data.allInsuredData,
          color: _colorArr[1]
        }, {
          name: _legend[2],
          type: "bar",
          data: _data.barData,
          color: _colorArr[2]
        },
        // 折线图
        {
          name: _legend[3],
          type: "line",
          symbol: "circle",
          color: _colorArr[3],
          symbolSize: [10, 10],
          data: _data.lineData,
          yAxisIndex: 1,
          barGap: '50%'
        }, {
          name: _legend[4],
          type: "line",
          data: _data.lastLineData,
          hiddde: true,
          yAxisIndex: 1,
          symbolSize: [10, 10],
          symbol: "circle",
          color: _colorArr[4],
          // axisLabel: {
          //     interval: this.newcarTargetInterVal,
          //     formatter: (value) => value.toFixed(2) + "%",
          // },
          // 设置虚线图样式
          itemStyle: {
            normal: {
              lineStyle: {
                color: _colorArr[4],
                type: "dotted"
              }
            }
          }
        }]
      };
      option && newCarChart.setOption(option, true);
    },
    // 柱形图  
    insuranceDrawLine: function insuranceDrawLine(idName, _data, _title, _legend, _colorArr) {
      var newCarChart = echarts.init(document.getElementById(idName));
      //标题
      var option = {
        grid: {
          padding: [50, 0]
        },
        title: {
          text: _title,
          left: "center",
          top: "0%",
          fontSize: "20",
          paddingBottom: 50
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999"
            }
          },
          formatter: function formatter(params) {
            var temp = "".concat(params[0].name, "<br/>");
            // ${params[0].marker}${params[0].seriesName}：${params[0].value}<br/>`;
            params.forEach(function (val) {
              if (val.seriesName.indexOf('率') != -1) {
                temp += "".concat(val.marker).concat(val.seriesName, "\uFF1A").concat(val.value == '-' ? val.value : val.value + '%', "<br/>");
              } else {
                temp += "".concat(val.marker).concat(val.seriesName, "\uFF1A").concat(val.value, "<br/>");
              }
            });
            return temp;
            // let value = `${params[0].name}<br/>`;
            // params.forEach(val => {
            //     if(val.seriesName.indexOf('率') != -1) {
            //         value += `${val.marker}${val.seriesName}：${val.value == '-' ? val.value : (val.value +'%')}<br/>`
            //     } else {
            //         value += `${val.marker}${val.seriesName}：${val.value}<br/>`
            //     }
            // })
          }
        },

        legend: {
          data: _legend,
          bottom: "-0.5%"
        },
        // x轴
        xAxis: [{
          type: "category",
          data: _data.xAxisData,
          axisPointer: {
            type: "shadow"
          },
          axisLine: {
            show: true // 隐藏x轴轴线
          },

          axisTick: {
            show: false,
            //是否隐藏x轴刻度线
            alignWithLabel: true
          },
          axisLabel: {
            //设置坐标轴样式
            fontSize: 10,
            interval: 0,
            rotate: 10
          }
        }],
        // y轴刻度尺
        yAxis: [{
          type: "value",
          min: 0,
          // max: function (value) {
          //     return value.max + (value.max * 0.5)
          // },
          axisLabel: {
            formatter: "{value}"
          },
          splitLine: {
            show: false //是否隐藏网格线
          }
        }, {
          type: "value",
          min: 0,
          max: 100,
          axisLabel: {
            formatter: function formatter(value) {
              return value.toFixed(2) + "%";
            }
          }
        }],
        series: [
        // 柱状图
        {
          name: _legend[0],
          type: "bar",
          data: _data.barData,
          color: _colorArr[0]
        },
        // 折线图
        {
          name: _legend[1],
          type: "line",
          symbol: "circle",
          color: _colorArr[1],
          symbolSize: [10, 10],
          data: _data.proportionData,
          yAxisIndex: 1,
          barGap: '50%'
        }]
      };
      option && newCarChart.setOption(option, true);
    },
    // 保单统计
    insuranceStatistics: function insuranceStatistics() {
      this.showInsuranceDialog = true;
    },
    // 车辆销售统计
    vehicleSalesStatistics: function vehicleSalesStatistics() {
      this.showVehicleSalesDialog = true;
    },
    // 更新数据
    updateData: function updateData(value) {
      var _this5 = this;
      reportForm.updateData(value).then(function (res) {
        if (res.code == 200) {
          _this5.$message.success(res.msg);
          _this5.clearForm();
          _this5.loadData();
          _this5.loadTatolList();
        }
        ;
      });
    }
  }
};