import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import Pagination from '@/components/Pagination';
import OemSelect from '@/components/OemSelect';
import callApi from '@/api/callCenter';
import { getUserOrgInfoDealerOem, commonExport } from '@/utils/common';
export default {
  name: 'Telephone',
  components: {
    Pagination: Pagination,
    OemSelect: OemSelect
  },
  mixins: [initHeight],
  data: function data() {
    return {
      form: {
        orgCode: '',
        orgName: '',
        registrationCode: ''
      },
      searchForm: {},
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      loading: false,
      showAddDialog: false,
      showViewDialog: false,
      bizType: localStorage.getItem("bizType") || '',
      orgForm: {
        salesOrgCode: '',
        salesOrgName: '',
        salesOrgType: ''
      },
      installOrgForm: {
        salesOrgCode: '',
        salesOrgName: ''
      },
      rules: {
        orgCode: {
          required: true,
          message: '请选择所属机构',
          trigger: 'change'
        },
        registrationCode: {
          required: true,
          message: '请输入注册码',
          trigger: 'blur'
        }
      },
      viewForm: {},
      formLoading: false,
      multipleSelection: [],
      opType: '',
      currentDeviceId: ''
    };
  },
  created: function created() {
    this.loadData();
    if (this.bizType === "DEALER") {
      this.initData();
    }
    ;
  },
  watch: {
    showAddDialog: function showAddDialog(val) {
      var _this = this;
      if (!val) return;
      this.form = {
        orgCode: '',
        orgName: '',
        registrationCode: ''
      };
      this.installOrgForm = {
        salesOrgCode: '',
        salesOrgName: ''
      };
      if (this.bizType === "DEALER") {
        this.initData();
      }
      ;
      if (this.opType === 'edit') {
        callApi.queryDevice(this.currentDeviceId).then(function (res) {
          if (res.code === 200) {
            _this.form = res.data;
            _this.installOrgForm = {
              salesOrgCode: _this.form.orgCode,
              salesOrgName: _this.form.orgName
            };
          }
          ;
        });
      }
      ;
      this.$nextTick(function () {
        _this.$refs.form.resetFields();
      });
    }
  },
  methods: {
    // 初始化数据
    initData: function initData() {
      var orgObj = getUserOrgInfoDealerOem(this.bizType);
      this.searchForm.orgCode = orgObj.salesOrgCode;
      this.searchForm.orgName = orgObj.salesOrgName;
      this.form.orgCode = orgObj.salesOrgCode;
      this.form.orgName = orgObj.salesOrgName;
    },
    handleNodeClick: function handleNodeClick(data, node, target) {
      // 机构树点击事件
      this.searchForm.orgCode = data.salesOrgCode;
      this.searchForm.orgName = data.salesOrgName;
      this.searchForm.areaType = data.salesOrgType;
    },
    addHandleNodeClick: function addHandleNodeClick(data, node, target) {
      // 新增的机构树点击事件
      this.form.orgCode = data.salesOrgCode;
      this.form.orgName = data.salesOrgName;
    },
    add: function add(type) {
      //安装
      this.opType = type;
      this.showAddDialog = true;
    },
    edit: function edit(type, item) {
      //编辑
      this.opType = type;
      this.currentDeviceId = item.deviceId;
      this.showAddDialog = true;
    },
    viewDetail: function viewDetail(item) {
      // 查看详情
      var params = {
        checkKeepAlive: true,
        deviceId: item.deviceId,
        lineId: 1,
        seatUserId: item.userId
      };
      callApi.seatKeepAlive(params).then(function (res) {});
      this.viewForm = item;
      this.showViewDialog = true;
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {
        orgName: this.bizType === 'OEM' ? '' : this.searchForm.orgName,
        orgCode: this.bizType === 'OEM' ? '' : this.searchForm.orgCode
      };
      this.orgForm = {
        salesOrgName: this.bizType === 'OEM' ? '全部' : '',
        salesOrgType: '',
        salesOrgCode: ''
      };
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    // 刷新
    breakData: function breakData() {
      // this.resetSearch();
      this.loadData();
    },
    loadData: function loadData() {
      var _this2 = this;
      //获取列表数据
      this.loading = true;
      this.showAddDialog = false;
      this.showViewDialog = false;
      callApi.getDeviceList(this.listQuery.pageNum, this.listQuery.pageSize, this.searchForm).then(function (res) {
        _this2.loading = false;
        if (res.code === 200) {
          _this2.tableData = res.data.list;
          _this2.total = res.data.page.recordsTotal;
        }
        ;
      }).catch(function (err) {
        _this2.loading = false;
      });
    },
    exportExcels: function exportExcels() {
      //导出
      var exporParams = {
        total: this.total,
        fileName: '录音设备管理',
        exportUrl: '/oc-core-service/callCenter/device',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    // 保存
    save: function save() {
      var _this3 = this;
      this.$refs.form.validate(function (valid) {
        if (valid) {
          _this3.formLoading = true;
          var req = _this3.opType === 'add' ? callApi.addDevice(_this3.form) : callApi.editDevice(_this3.form.deviceId, _this3.form);
          req.then(function (res) {
            _this3.formLoading = false;
            _this3.$message.success(res.msg);
            _this3.loadData();
          }).catch(function (err) {
            _this3.formLoading = false;
          });
        }
        ;
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      //列表行选择事件
      this.multipleSelection = val;
    },
    batchDelete: function batchDelete(item) {
      var _this4 = this;
      //删除
      var ids = [];
      if (!_.isEmpty(item)) {
        ids.push(item.deviceId);
      } else {
        if (!_.isEmpty(this.multipleSelection)) {
          ids = _.map(this.multipleSelection, 'deviceId');
        }
        ;
      }
      ;
      this.$confirm('您确定要删除么？', {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        callApi.deleteDevice(ids).then(function (res) {
          if (res.code === 200) {
            _this4.$message.success(res.msg);
            _this4.loadData();
          }
          ;
        });
      });
    }
  }
};