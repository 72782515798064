//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import vehiclesApi from '@/api/vehiclesIntoShop';
import OemSelect from '@/components/OemSelect';
import { getUserOrgInfoDealerOem } from '@/utils/common';
export default {
  components: {
    OemSelect: OemSelect
  },
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    opType: {
      type: String,
      default: ''
    },
    cameraBlackId: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      form: {
        orgCode: '',
        orgName: ''
      },
      rules: {
        orgCode: {
          required: true,
          message: '请选择所属机构',
          trigger: "change"
        },
        groupName: {
          required: true,
          message: '请输入分组名称',
          trigger: "blur"
        },
        plateNoList: {
          required: true,
          message: '请输入车牌号',
          trigger: "blur"
        }
      },
      bizType: localStorage.getItem("bizType") || '',
      orgForm: {
        salesOrgCode: '',
        salesOrgName: ''
      },
      formLoading: false
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
      ;
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    initData: function initData() {
      var _this = this;
      this.form = {
        orgCode: '',
        orgName: '',
        groupName: '',
        plateNoList: ''
      };
      this.orgForm = {
        salesOrgCode: '',
        salesOrgName: ''
      };
      if (this.opType === "add") {
        if (this.bizType === "DEALER") {
          var orgObj = getUserOrgInfoDealerOem(this.bizType);
          this.form.orgCode = orgObj.salesOrgCode;
          this.form.orgName = orgObj.salesOrgName;
        }
        ;
      } else {
        vehiclesApi.getCameraBlackInfo(this.cameraBlackId).then(function (res) {
          if (res.code === 200) {
            _this.form = res.data;
            _this.orgForm = {
              salesOrgCode: _this.form.orgCode,
              salesOrgName: _this.form.orgName
            };
          }
          ;
        });
      }
      ;
      this.$refs.detail.resetFields();
    },
    // 保存数据
    save: function save() {
      var _this2 = this;
      this.$refs.detail.validate(function (valid) {
        if (valid) {
          _this2.formLoading = true;
          var req = _this2.opType === 'add' ? vehiclesApi.addCameraBlack(_this2.form) : vehiclesApi.editCameraBlack(_this2.cameraBlackId, _this2.form);
          req.then(function (res) {
            _this2.formLoading = false;
            if (res.code === 200) {
              _this2.$message.success(res.msg);
              _this2.closePage();
            }
            ;
          }).catch(function (err) {
            _this2.formLoading = false;
          });
        }
        ;
      });
    },
    // 机构树点击事件
    handleNodeClick: function handleNodeClick(data, node, target) {
      this.form.orgCode = data.salesOrgCode;
      this.form.orgName = data.salesOrgName;
    },
    // 关闭弹窗
    closePage: function closePage() {
      this.$emit('closePage');
    }
  }
};