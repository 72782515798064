import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import dict from '@/mixins/dict';
import Pagination from '@/components/Pagination';
import UpdataDialog from './dialog/updataDialog';
import OemSelect from '@/components/OemSelect';
import DatePicker from '@/components/DatePicker';
import FollowUpDialog from '@/views/leadManagement/dialog/followUpDialog';
import vehiclesApi from '@/api/vehiclesIntoShop';
import dictRequest from '@/api/dictionary';
import { getUserOrgInfoDealerOem, character, commonExport, getCurrentDate } from '@/utils/common';
export default {
  name: 'StoreRecord',
  components: {
    Pagination: Pagination,
    UpdataDialog: UpdataDialog,
    OemSelect: OemSelect,
    DatePicker: DatePicker,
    FollowUpDialog: FollowUpDialog
  },
  mixins: [initHeight, dict],
  data: function data() {
    return {
      drawer: false,
      searchForm: {},
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      multipleSelection: [],
      startEndTime: [getCurrentDate(), getCurrentDate()],
      loading: false,
      showAddTracksDialog: false,
      showUpdataDialog: false,
      orgForm: {
        salesOrgCode: '',
        salesOrgName: '',
        salesOrgType: ''
      },
      bizType: localStorage.getItem("bizType") || '',
      currentItem: '',
      customerCategoryList: [],
      renewalsStatusArr: [{
        label: '可续保',
        value: 'CAN_RENEWAL',
        color: '#99cc00'
      }, {
        label: '已脱保',
        value: 'INTERRUPT',
        color: '#fb3838'
      }, {
        label: '未到期',
        value: 'UN_EXPIRE',
        color: '#3894e4'
      }, {
        label: '未知续保状态',
        value: 'UNKNOWN',
        color: '#ffff00'
      }]
    };
  },
  created: function created() {
    this.getRenewalTypeList();
    this.loadData();
    if (this.bizType === "DEALER") {
      this.initData();
    }
    ;
  },
  methods: {
    // 获取续客户类别
    getRenewalTypeList: function getRenewalTypeList() {
      var _this = this;
      dictRequest.getDictItemsByCode("RENEWAL_TYPE").then(function (res) {
        if (res.data) {
          _this.customerCategoryList = res.data;
        }
      });
    },
    getColor: function getColor(renewalType) {
      var obj = {};
      if (!renewalType) {
        obj = this.renewalsStatusArr.find(function (val) {
          return val.value === 'UNKNOWN';
        });
      } else {
        obj = this.renewalsStatusArr.find(function (val) {
          return val.value === renewalType;
        });
      }
      return obj ? obj.color : '';
    },
    // 输入的车牌号中的字母自动转大写
    inputPlateNo: function inputPlateNo(val) {
      this.searchForm.plateNo = _.toUpper(val);
    },
    // 输入的车架号中的字母自动转大写，并去除i，o，q
    checkValue: function checkValue(val) {
      if (val) {
        val = val.replace(/i/g, '').replace(/I/g, '').replace(/o/g, '').replace(/O/g, '').replace(/q/g, '').replace(/Q/g, '');
      }
      this.searchForm.vin = character(_.toUpper(val));
    },
    // 初始化数据
    initData: function initData() {
      var orgObj = getUserOrgInfoDealerOem(this.bizType);
      this.searchForm.orgCode = orgObj.salesOrgCode;
      this.searchForm.orgName = orgObj.salesOrgName;
    },
    // 机构树点击事件
    handleNodeClick: function handleNodeClick(data, node, target) {
      this.searchForm.orgCode = data.salesOrgCode;
      this.searchForm.orgName = data.salesOrgName;
      this.searchForm.areaType = data.salesOrgType;
    },
    // 更新
    updata: function updata(item) {
      this.showUpdataDialog = true;
    },
    // 去报价
    toQuote: function toQuote(item) {
      var router_path = this.$route.path;
      this.$router.push({
        path: "/proposal/add",
        query: {
          back: router_path,
          plateNo: item.plateNo,
          vin: item.vin,
          ownerCertificateNo: item.ownerCertificateNo,
          ownerMobile: item.ownerMobile,
          engineNo: item.engineNo,
          ownerName: item.ownerName,
          modelName: item.modelName
        }
      });
    },
    // 查看
    viewDetail: function viewDetail(item) {
      this.$router.push({
        path: '/customers/detail',
        query: {
          customerId: item.customerId,
          tabName: "\u5BA2\u6237\u8BE6\u60C5-".concat(item.ownerName, "(").concat(item.vin, ")")
        }
      });
    },
    // 重置搜索表单
    resetSearch: function resetSearch() {
      this.searchForm = {
        orgName: this.bizType === 'OEM' ? '' : this.searchForm.orgName,
        orgCode: this.bizType === 'OEM' ? '' : this.searchForm.orgCode
      };
      this.orgForm = {
        salesOrgName: this.bizType === 'OEM' ? '全部' : '',
        salesOrgType: '',
        salesOrgCode: ''
      };
      this.startEndTime = [getCurrentDate(), getCurrentDate()];
    },
    // 搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    // 刷新
    breakData: function breakData() {
      this.loadData();
    },
    // 日期选择事件
    getPickersDate: function getPickersDate(name, val) {
      if (val && val.length > 1) {
        this[name] = val;
      } else {
        this[name] = [];
      }
    },
    // 获取列表数据
    loadData: function loadData() {
      var _this2 = this;
      this.loading = true;
      this.drawer = false;
      this.showAddTracksDialog = false;
      this.showUpdataDialog = false;
      if (this.startEndTime) {
        this.searchForm.startEntryTime = this.startEndTime[0] && this.startEndTime[0].split(' ')[0] + ' 00:00:00' || null;
        this.searchForm.endEntryTime = this.startEndTime[1] && this.startEndTime[1].split(' ')[0] + ' 23:59:59' || null;
      }
      vehiclesApi.getCustomerList(this.listQuery.pageNum, this.listQuery.pageSize, this.searchForm).then(function (res) {
        _this2.loading = false;
        if (res.code === 200) {
          _this2.tableData = res.data.list;
          _this2.total = res.data.page.recordsTotal;
        }
      }).catch(function (err) {
        _this2.loading = false;
      });
    },
    // 列表行选择事件
    handleSelectionChange: function handleSelectionChange(val) {
      var _this3 = this;
      this.multipleSelection = val;
      this.$nextTick(function () {
        _this3.$refs['multipleTable'].doLayout();
      });
    },
    // 导出
    exportExcels: function exportExcels() {
      var exporParams = {
        total: this.total,
        fileName: '进店客户',
        exportUrl: '/oc-core-service/callIn/cameraCustomer',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    tracks: function tracks(item) {
      this.showAddTracksDialog = true;
      this.currentItem = item;
    }
  }
};