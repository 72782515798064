var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-m-cls", staticStyle: {} }, [
    _c(
      "div",
      {
        ref: "header",
        staticClass: "header-cls",
        staticStyle: {
          "max-height": "540px",
          overflow: "auto",
          "padding-top": "10px",
        },
      },
      [
        _c(
          "el-card",
          { attrs: { shadow: "never" } },
          [
            _c(
              "el-form",
              {
                ref: "detail",
                staticStyle: { "margin-bottom": "-20px" },
                attrs: {
                  model: _vm.form,
                  "label-width": "100px",
                  size: "mini",
                },
              },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "车牌号" } },
                          [
                            _c("el-input", {
                              attrs: {
                                minlength: "7",
                                maxlength: "8",
                                placeholder: "请输入车牌号",
                                clearable: "",
                              },
                              model: {
                                value: _vm.form.plateNo,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "plateNo",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "form.plateNo",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "车架号" } },
                          [
                            _c("el-input", {
                              staticStyle: { "min-width": "230px" },
                              attrs: {
                                maxlength: "17",
                                placeholder: "请输入车架号",
                                clearable: "",
                              },
                              on: { input: _vm.inputvin },
                              nativeOn: {
                                "!paste": function ($event) {
                                  $event.preventDefault()
                                  return _vm.pastevin($event)
                                },
                              },
                              model: {
                                value: _vm.form.vin,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "vin",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "form.vin",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "发动机号" } },
                          [
                            _c("el-input", {
                              staticStyle: { "min-width": "230px" },
                              attrs: {
                                maxlength: "17",
                                placeholder: "请输入发动机号",
                                clearable: "",
                              },
                              model: {
                                value: _vm.form.engineNo,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "engineNo",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "form.engineNo",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "证件号后六位" } },
                          [
                            _c("el-input", {
                              staticStyle: { "min-width": "230px" },
                              attrs: {
                                maxlength: "6",
                                placeholder: "请输入证件号后六位",
                                clearable: "",
                              },
                              model: {
                                value: _vm.form.ownerCertificateNo,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "ownerCertificateNo",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "form.ownerCertificateNo",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "车主名" } },
                          [
                            _c("el-input", {
                              staticStyle: { "min-width": "230px" },
                              attrs: {
                                placeholder: "请输入车主名",
                                clearable: "",
                              },
                              model: {
                                value: _vm.form.ownerName,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "ownerName",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "form.ownerName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "被保人姓名" } },
                          [
                            _c("el-input", {
                              staticStyle: { "min-width": "230px" },
                              attrs: {
                                placeholder: "请输入被保人姓名",
                                clearable: "",
                              },
                              model: {
                                value: _vm.form.insuredName,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "insuredName",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "form.insuredName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "上年保单号" } },
                          [
                            _c("el-input", {
                              staticStyle: { "min-width": "230px" },
                              attrs: {
                                placeholder: "请输入上年保单号",
                                clearable: "",
                              },
                              model: {
                                value: _vm.form.lastPolicyNo,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "lastPolicyNo",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "form.lastPolicyNo",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "justify-content": "center",
                  "align-items": "center",
                  color: "red",
                  width: "100%",
                },
              },
              [
                _c("div", { staticStyle: { "text-align": "left" } }, [
                  _c("div", [_vm._v("*平安续保：车架号或车牌+证件号后六位*")]),
                  _c("div", [
                    _vm._v("*国寿续保：车牌号码或发动机号+被保人姓名**"),
                  ]),
                  _c("div", [
                    _vm._v(
                      "*众诚续保：车架号或跨机构被保人姓名+车牌号+发动机号**"
                    ),
                  ]),
                  _c("div", [_vm._v("*大地续保：只支持保单号查询*")]),
                  _c("div", [_vm._v("*太平续保：车架号+车主姓名*")]),
                  _c("div", [_vm._v("*其他保司：车架号*")]),
                ]),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticStyle: {
          display: "flex",
          "justify-content": "center",
          "margin-top": "8px",
        },
      },
      [
        _c(
          "el-button",
          {
            attrs: {
              icon: "el-icon-check",
              type: "primary",
              plain: "",
              size: "mini",
            },
            on: { click: _vm.save },
          },
          [_vm._v("保存")]
        ),
        _c(
          "el-button",
          {
            attrs: { icon: "el-icon-close", size: "mini" },
            on: { click: _vm.close },
          },
          [_vm._v("关闭")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }