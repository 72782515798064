import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.number.to-fixed.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import dictRequest from '@/api/dictionary';
import ChinaMapChart from './components/ChinaMapChartCopy.vue';
import initHeight from '../../mixins/initHeight';
import lineSmoothChart from './components/lineSmoothChart.vue';
import mapStatisticsRequest from '../../api/mapStatistics.js';
import barChart from './components/barChart.vue';
import pieCharts from './components/pieChartsCopy.vue';
import noVechileLineCharts from './components/noVechileLineCharts.vue';
import * as chinaCities from '@/assets/china-cities.json';
import dayjs from 'dayjs';
import dict from '@/mixins/dict';
var dayOfYear = require('dayjs/plugin/dayOfYear');
var WEEK = new Array("日", "一", "二", "三", "四", "五", "六");
import { getCurrentDate, getBeforeDate, getLastMonth, getLastWeekData, getLastYear } from '@/utils/common';
export default {
  mixins: [initHeight, dict],
  components: {
    ChinaMapChart: ChinaMapChart,
    lineSmoothChart: lineSmoothChart,
    barChart: barChart,
    pieCharts: pieCharts,
    noVechileLineCharts: noVechileLineCharts
  },
  props: {
    isNoCar: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      totalPremiumsNumleft: 0,
      totalQuantitysleft: 0,
      oldQuantity: 0,
      oldPremium: 0,
      animateTime: false,
      animateTime2: false,
      title: process.env.VUE_APP_TITLE,
      xlineData: [],
      seriesLineData: [{
        name: '本月',
        data: []
      }, {
        name: '本月',
        data: []
      }],
      seriesLineData1: [{
        name: '本月',
        data: []
      }, {
        name: '本月',
        data: []
      }],
      xAxisData: [],
      seriesData: {
        name: '出单量',
        data: [],
        values: []
      },
      seriesData1: {
        name: '总销售金额',
        data: [],
        values: []
      },
      premiumData: [],
      quantityData: [],
      defaultCity: '全国',
      currentCity: '',
      containerH: '100%',
      tabPosition: 'day',
      mapId: 'noVechileChinaMap',
      mapW: '100%',
      mapH: '700px',
      mapData: [],
      isFullscreen: false,
      areaCode: "",
      dataTime: {
        startDate: getCurrentDate(),
        endDate: getCurrentDate()
      },
      preDataTime: {
        startDate: getCurrentDate(),
        endDate: getCurrentDate()
      },
      totalPremium: "",
      totalQuantity: "",
      currentTab: "",
      vehiclemodelData: {
        "chartType": "Pie",
        "name": "车型维度",
        "values": []
      },
      servicepreiodData: {
        "chartType": "Pie",
        "name": "服务期限维度",
        "values": []
      },
      useattributeData: {
        "chartType": "Pie",
        "name": "使用性质维度",
        "values": []
      },
      premiumRateData: {
        "chartType": "Pie",
        "name": "保值换新保费规模占比",
        "values": []
      },
      organizationStoreTypeCode: '',
      productType: '',
      noVechileData: [],
      changeTabtimer: null,
      getTabDataTimer: null,
      timeNum2: 0,
      timeout1: null,
      dateList: [{
        id: 1,
        name: 'day'
      }, {
        id: 2,
        name: 'week'
      }, {
        id: 3,
        name: 'month'
      }, {
        id: 4,
        name: 'year'
      }],
      productTypeList: []
    };
  },
  filters: {
    cumulativeText: function cumulativeText(val) {
      switch (val) {
        case '':
          return '增值服务';
        case 'GA':
          return '保值换新';
        case 'EW':
          return '整车延保';
        case 'TY':
          return '轮胎保障';
        case 'CW':
          return '充电桩延保';
        case 'CY':
          return '充电桩意外保障';
        case 'WB':
          return '维修补偿';
        case 'EE':
          return '三电延保';
      }
      ;
    }
  },
  beforeDestroy: function beforeDestroy() {
    // console.log('子页面2销毁前')
    clearInterval(this.changeTabtimer);
    this.changeTabtimer = null;
    clearInterval(this.getTabDataTimer);
    this.getTabDataTimer = null;
  },
  computed: {},
  mounted: function mounted() {
    var _this = this;
    //获取服务类别
    dictRequest.getDictItemsByCode("NON_VEHICLE_PRODUCT_TYPE").then(function (res) {
      if (res.data) {
        _this.productTypeList = res.data;
      }
      ;
    });
    var self = this;
    self.containerH = document.body.clientHeight - 134 + 'px';
    window.onresize = function () {
      if (!self.checkFull()) {
        // 退出全屏后要执行的动作
        self.isFullscreen = false;
        self.containerH = document.body.clientHeight - 134 + 'px';
        self.containerH = this.isFullscreen ? '95vh' : self.containerH;
        self.$forceUpdate();
      }
    };
    // this.handleRadio('day');
  },

  methods: {
    clearTabTimer: function clearTabTimer() {
      // console.log('2清除切换tab定时器')
      clearInterval(this.changeTabtimer);
      // this.changeTabtimer = null
    },
    clearGetDataTimer: function clearGetDataTimer() {
      // console.log('2清除定时获取数据定时器')
      clearInterval(this.getTabDataTimer);
      // this.getTabDataTimer = null;
    },
    dateTabChangestart2: function dateTabChangestart2() {
      var _this2 = this;
      //切换tab的定时器
      // 循环时间列表
      var dateList = this.dateList;
      // let a = parseInt(this.timeNum2)
      this.changeTabtimer = setInterval(function () {
        _this2.timeNum2 = parseInt(_this2.timeNum2) + 1;
        if (_this2.timeNum2 == 5) {
          _this2.timeNum2 = 0;
        } else {
          // console.log('2在执行切换tab-------dateTabChangestart2', this.timeNum2)
          _this2.handleRadio(dateList[_this2.timeNum2 - 1].name);
          _this2.tabPosition = dateList[_this2.timeNum2 - 1].name;
        }
      }, 12000);
    },
    getIndexTabData: function getIndexTabData() {
      var _this3 = this;
      //获取数据的定时器
      this.getTabDataTimer = setInterval(function () {
        _this3.loadBoardData(_this3.currentTab);
      }, 10000);
    },
    changeStoreType: function changeStoreType() {
      this.loadBoardData(this.currentTab);
    },
    handleRadio: function handleRadio(val) {
      var _this4 = this;
      clearInterval(this.changeTabtimer);
      this.changeTabtimer = null;
      clearInterval(this.getTabDataTimer);
      this.getTabDataTimer = null;
      if (this.timeout1) {
        //防抖
        clearTimeout(this.timeout1);
      }
      this.timeout1 = setTimeout(function () {
        // console.log('切换',val)
        _this4.handleRadio1(val);
      }, 500);
    },
    handleRadio1: function handleRadio1(val) {
      switch (val) {
        case "day":
          //今天
          this.dataTime.startDate = getCurrentDate() + ' 00:00:00';
          this.dataTime.endDate = getCurrentDate() + ' 23:59:59';
          this.preDataTime.startDate = getBeforeDate(1) + ' 00:00:00';
          this.preDataTime.endDate = getBeforeDate(1) + ' 23:59:59';
          this.currentTab = 'DAY';
          this.timeNum2 = 1;
          break;
        case "week":
          //本周
          var day = dayjs().day();
          // 本周：周一 ~ 周日
          if (day == 0) {
            //0表示今天周日
            this.preDataTime.startDate = dayjs().day(-13).format('YYYY-MM-DD') + ' 00:00:00';
            this.preDataTime.endDate = dayjs().day(-7).format('YYYY-MM-DD') + ' 23:59:59';
            this.dataTime.startDate = dayjs().day(-6).format('YYYY-MM-DD') + ' 00:00:00';
            this.dataTime.endDate = dayjs().day(0).format('YYYY-MM-DD') + ' 23:59:59';
          } else {
            this.dataTime.startDate = dayjs().day(1).format('YYYY-MM-DD') + ' 00:00:00';
            this.dataTime.endDate = dayjs().day(7).format('YYYY-MM-DD') + ' 23:59:59';
            this.preDataTime.startDate = dayjs().day(-6).format('YYYY-MM-DD') + ' 00:00:00';
            this.preDataTime.endDate = dayjs().day(0).format('YYYY-MM-DD') + ' 23:59:59';
          }
          this.currentTab = 'week';
          this.timeNum2 = 2;
          break;
        case "month":
          //本月
          this.dataTime.startDate = dayjs().date(1).format('YYYY-MM-DD') + ' 00:00:00';
          var days = dayjs(getCurrentDate()).daysInMonth();
          this.dataTime.endDate = dayjs().date(days).format('YYYY-MM-DD') + ' 23:59:59';
          this.preDataTime.startDate = getLastMonth()[0] + ' 00:00:00';
          this.preDataTime.endDate = getLastMonth()[1] + ' 23:59:59';
          this.currentTab = 'month';
          this.timeNum2 = 3;
          break;
        case "year":
          //本年
          dayjs.extend(dayOfYear);
          this.dataTime.startDate = dayjs().dayOfYear(1).format('YYYY-MM-DD') + ' 00:00:00';
          this.dataTime.endDate = dayjs().dayOfYear(365).format('YYYY-MM-DD') + ' 23:59:59';
          this.preDataTime.startDate = getLastYear()[0] + ' 00:00:00';
          this.preDataTime.endDate = getLastYear()[1] + ' 23:59:59';
          this.currentTab = 'YEAR';
          this.timeNum2 = 4;
          break;
      }
      if (this.changeTabtimer) {
        clearInterval(this.changeTabtimer);
        this.changeTabtimer = null;
      } else {
        // console.log('需要重新开启定时器2')
        this.dateTabChangestart2();
      }
      this.loadBoardData(this.currentTab);
      if (this.getTabDataTimer) {
        clearInterval(this.getTabDataTimer);
        this.getTabDataTimer = null;
      } else {
        // console.log('重新开启子定时器2')
        this.getIndexTabData();
      }
    },
    loadBoardData: function loadBoardData(type) {
      //切换时间段
      var obj = {
        organizationStoreTypeCode: this.organizationStoreTypeCode,
        productType: this.productType
      };
      var data = Object.assign(obj, this.dataTime);
      this.getLeaderboards(data, this.areaCode);
      this.getInsCorpInsure(data);
      this.getMapStatistics(data, type);
      this.getCumulative(data);
      this.getAreaStatistics(data, type, 1);
      this.preDataTime.organizationStoreTypeCode = this.organizationStoreTypeCode;
      this.preDataTime.productType = this.productType;
      this.getAreaStatistics(this.preDataTime, type, 0);
    },
    getLeaderboards: function getLeaderboards(dateTime) {
      var _this5 = this;
      var areaCode = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
      //获取保费/保单排行榜  5:出单top5
      mapStatisticsRequest.noVechilegetLeaderboards(this.areaCode, 5, dateTime).then(function (res) {
        if (res.data && res.data.length != 0) {
          _this5.premiumData = res.data.premium; //保费
          _this5.quantityData = res.data.quantity; //出单量
        } else {
          _this5.clearGetDataTimer();
        }
      });
    },
    getInsCorpInsure: function getInsCorpInsure(dateTime) {
      var _this6 = this;
      var areaCode = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
      //保险公司出单业务
      var mapSummaryParam = _objectSpread(_objectSpread({}, dateTime), {}, {
        areaCode: this.areaCode
      });
      mapStatisticsRequest.noVechilegetInsCorpInsure(mapSummaryParam).then(function (res) {
        if (res.data && res.data.length != 0) {
          _this6.xAxisData = [];
          _this6.seriesData.data = [];
          _this6.seriesData1.data = [];
          _this6.premiumRateData.values = [];
          var productData = [];
          var productValues = [];
          var vehiclemodelvalues = [];
          var servicepreiodValues = [];
          var useattributeValues = [];
          res.data.forEach(function (element) {
            // 产品类型
            if (element.type === "PRODUCT" && element.renewalDataMapVOS) {
              var sortList = _.orderBy(element.renewalDataMapVOS, ['quantity'], ['desc']);
              sortList.forEach(function (item) {
                _this6.xAxisData.push(item.productName || '');
                productData.push(item.quantity);
                productValues.push(item.productName || '');
                _this6.$forceUpdate();
              });
              _this6.seriesData.data = productData;
              _this6.seriesData.values = productValues;
            }
            // 车型类型
            if (element.type === "VEHICLEMODEL" && element.renewalDataMapVOS) {
              var _sortList = _.orderBy(element.renewalDataMapVOS, ['quantity'], ['desc']);
              _sortList.forEach(function (item) {
                vehiclemodelvalues.push({
                  name: "".concat(item.policyVehicleBrandName || '', " ").concat(item.policyVehicleModelName || ''),
                  value: item.quantity
                });
                _this6.$forceUpdate();
              });
              _this6.vehiclemodelData.values = vehiclemodelvalues;
            }
            // 期限类型
            if (element.type === "SERVICEPERIOD" && element.renewalDataMapVOS) {
              var _sortList2 = _.orderBy(element.renewalDataMapVOS, ['quantity'], ['desc']);
              _sortList2.forEach(function (item) {
                servicepreiodValues.push({
                  name: "".concat(item.productServicePeriod || 0, "\u4E2A\u6708"),
                  value: item.quantity
                });
                _this6.$forceUpdate();
              });
              _this6.servicepreiodData.values = servicepreiodValues;
            }
            // 使用类型
            if (element.type === "USEATTRIBUTE" && element.renewalDataMapVOS) {
              var _sortList3 = _.orderBy(element.renewalDataMapVOS, ['quantity'], ['desc']);
              _sortList3.forEach(function (item) {
                useattributeValues.push({
                  name: "".concat(item.useAttributeName || item.useAttribute || ''),
                  value: item.quantity
                });
                _this6.$forceUpdate();
              });
              _this6.useattributeData.values = useattributeValues;
            }
          });
        } else {
          _this6.clearGetDataTimer();
        }
      });
    },
    getAreaStatistics: function getAreaStatistics(dateTime) {
      var _this7 = this;
      var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
      var num = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;
      var areaCode = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : "";
      //获取总销售金额/总出单
      var obj1 = {
        name: '本月',
        data: []
      };
      var obj2 = {
        name: '本月',
        data: []
      };
      switch (type) {
        case "DAY":
          //今天
          obj1.name = num === 1 ? '今天' : "昨天";
          obj2.name = num === 1 ? '今天' : "昨天";
          break;
        case "week":
          //本周
          obj1.name = num === 1 ? '本周' : "上周";
          obj2.name = num === 1 ? '本周' : "上周";
          break;
        case "month":
          //本月
          obj1.name = num === 1 ? '本月' : "上月";
          obj2.name = num === 1 ? '本月' : "上月";
          break;
        case "YEAR":
          //本年
          obj1.name = num === 1 ? '本年' : "去年";
          obj2.name = num === 1 ? '本年' : "去年";
          break;
      }
      mapStatisticsRequest.noVechilegetAreaStatistics(this.areaCode, type, dateTime).then(function (res) {
        if (res.data && res.data.length != 0) {
          if (num === 1) {
            var arr = [];
            _this7.xlineData = [];
            res.data[1].xaxis.forEach(function (item) {
              if (_this7.tabPosition === 'day') {
                arr.push(parseInt(item) + 'h');
              } else if (_this7.tabPosition === 'year') {
                arr.push(parseInt(item.substr(5)) + '月');
              } else if (_this7.tabPosition === 'week') {
                var y = new Date().getFullYear();
                var w = new Date(y + '-' + item.substr(5)).getDay();
                var title = "周" + WEEK[w] + " " + item.substr(5);
                arr.push(title);
              } else {
                arr.push(item.substr(5));
              }
            });
            _this7.xlineData = arr;
          }
          var data1 = res.data[1].series;
          obj1.title = res.data[1].title;
          var xaxis1 = res.data[1].xaxis;
          data1.forEach(function (item, index) {
            if (data1[index] == 0 && new Date(xaxis1[index]).getTime() > new Date(getCurrentDate()).getTime()) {
              data1[index] = '-';
            }
          });
          obj1.data = data1;
          _this7.$set(_this7.seriesLineData, num, obj1);
          var data2 = res.data[0].series;
          var xaxis2 = res.data[0].xaxis;
          data2.forEach(function (item, index) {
            if (data2[index] == 0 && new Date(xaxis2[index]).getTime() > new Date(getCurrentDate()).getTime()) {
              data2[index] = '-';
            }
          });
          obj2.data = data2;
          obj2.title = res.data[0].title;
          _this7.$set(_this7.seriesLineData1, num, obj2);
        } else {
          _this7.clearGetDataTimer();
        }
      });
    },
    checkNums: function checkNums(itemName, resDataNum) {
      var _this8 = this;
      if (sessionStorage.getItem(itemName)) {
        //是否有本地缓存
        if (parseInt(sessionStorage.getItem(itemName)) < parseInt(resDataNum)) {
          this.oldQuantity = parseInt(resDataNum) - parseInt(sessionStorage.getItem(itemName));
          this.animateTime = true;
          this.$nextTick(function () {
            // let width = this.$refs.totalQuantitysNum.offsetWidth;
            var length = parseInt(resDataNum) ? parseInt(resDataNum).toString().split('').length : 1;
            _this8.totalQuantitysleft = parseInt(14 * length) + 'px';
          });
          sessionStorage.setItem(itemName, resDataNum ? resDataNum : 0);
        } else {
          this.animateTime = false;
          sessionStorage.setItem(itemName, resDataNum ? resDataNum : 0);
        }
      } else {
        sessionStorage.setItem(itemName, resDataNum ? resDataNum : 0);
        this.animateTime = false;
      }
    },
    checkPremium: function checkPremium(itemName, resDataPremium) {
      var _this9 = this;
      if (sessionStorage.getItem(itemName)) {
        //是否有本地缓存
        if (parseInt(sessionStorage.getItem(itemName)) < parseInt(resDataPremium)) {
          this.oldPremium = parseInt(resDataPremium) - parseInt(sessionStorage.getItem(itemName));
          this.animateTime2 = true;
          this.$nextTick(function () {
            // let width = this.$refs.totalPremiumsNum.offsetWidth;
            var length = parseInt(resDataPremium) ? parseInt(resDataPremium).toString().split('').length + 1 : 1 + 1;
            _this9.totalPremiumsNumleft = parseInt(22 * length) + 'px';
          });
          sessionStorage.setItem(itemName, resDataPremium ? resDataPremium : 0);
        } else {
          this.animateTime2 = false;
          sessionStorage.setItem(itemName, resDataPremium ? resDataPremium : 0);
        }
      } else {
        sessionStorage.setItem(itemName, resDataPremium ? resDataPremium : 0);
        this.animateTime2 = false;
      }
    },
    getMapStatistics: function getMapStatistics(dateTime, type) {
      var _this10 = this;
      var areaCode = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "";
      //地图
      mapStatisticsRequest.noVechilegetMapStatistics(this.areaCode, dateTime).then(function (res) {
        if (res.data) {
          _this10.totalPremium = res.data.amount;
          _this10.totalQuantity = res.data.quantity;
          setTimeout(function () {
            _this10.totalPremium = res.data.amount ? res.data.amount : 0;
          }, 500);
          setTimeout(function () {
            _this10.totalQuantity = res.data.quantity ? parseInt(res.data.quantity) : 0;
          }, 500);
          if (type && type == 'DAY') {
            _this10.checkNums('oldNoVechileDayTotalQuantity', res.data.quantity);
            _this10.checkPremium('oldNoVechileDayTotalPremium', res.data.amount);
          }
          if (type && type == 'week') {
            _this10.checkNums('oldNoVechileWeekTotalQuantity', res.data.quantity);
            _this10.checkPremium('oldNoVechileWeekTotalPremium', res.data.amount);
          }
          if (type && type == 'month') {
            _this10.checkNums('oldNoVechileMonthTotalQuantity', res.data.quantity);
            _this10.checkPremium('oldNoVechileMonthTotalPremium', res.data.amount);
          }
          if (type && type == 'YEAR') {
            _this10.checkNums('oldNoVechileYearTotalQuantity', res.data.quantity);
            _this10.checkPremium('oldNoVechileYearTotalPremium', res.data.amount);
          }
          var _data = res.data.productOwnerCityMapRespons ? res.data.productOwnerCityMapRespons : [];
          _this10.mapData = [];
          if (!_.isEmpty(_data)) {
            _data.forEach(function (element) {
              var obj = {
                name: '',
                emphasis: {
                  label: {
                    show: true
                  }
                },
                value: []
              };
              var cityCode = element.ownerCityCode;
              if (element.ownerCityName) {
                //直辖市
                if (element.ownerCityName.indexOf('北京') != -1 || cityCode == '110100') {
                  cityCode = '110000';
                } else if (element.ownerCityName.indexOf('天津') != -1 || cityCode == '120100') {
                  cityCode = '120000';
                } else if (element.ownerCityName.indexOf('上海') != -1 || cityCode == '310100') {
                  cityCode = '310000';
                } else if (element.ownerCityName.indexOf('重庆') != -1 || cityCode == '500100') {
                  cityCode = '500000';
                }
              }

              //获取城市对应的经纬度；
              var _temp = _.find(chinaCities.features, function (item) {
                return item.id == cityCode;
              });
              if (_temp) {
                var _cp = [];
                _cp.push(_temp.properties.cp[0] || '');
                _cp.push(_temp.properties.cp[1] || '');
                _cp.push(element.amount);
                _cp.push(element.quantity);
                obj.name = _temp.properties.name;
                obj.value = _cp;
              }
              _this10.mapData.push(obj);
            });
          }
          setTimeout(function () {
            _this10.animateTime = false;
            _this10.animateTime2 = false;
          }, 2000);
        } else {
          _this10.clearGetDataTimer();
          _this10.totalPremium = 0;
          _this10.totalQuantity = 0;
          _this10.mapData = [];
        }
      });
    },
    switchMap: function switchMap(val) {
      if (this.$refs.noVechileChinaMap) {
        this.$refs.noVechileChinaMap.switchMap(val);
      }
    },
    selectedCity: function selectedCity(val) {
      if (!val) return;
      //刷新地图数据
      this.currentCity = val.name;
      var _areaCode = val.areaCode || '';
      this.areaCode = _areaCode ? _areaCode.substring(0, 2) : '';
      this.loadBoardData(this.currentTab);
    },
    checkFull: function checkFull() {
      //全屏判断状态 取消
      //判断浏览器是否处于全屏状态 （需要考虑兼容问题）
      //火狐浏览器
      var isFull = document.mozFullScreen || document.fullScreen ||
      //谷歌浏览器及Webkit内核浏览器
      document.webkitIsFullScreen || document.webkitRequestFullScreen || document.mozRequestFullScreen || document.msFullscreenEnabled;
      if (isFull === undefined) {
        isFull = false;
      }
      return isFull;
    },
    switchScreen: function switchScreen() {
      var element = document.getElementById('noVechileMap-container'); //设置后就是   id==con_lf_top_div 的容器全屏
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen();
        }
      }
      this.isFullscreen = !this.isFullscreen;
      if (this.isFullscreen) {
        this.containerH = '95vh';
      }
      this.$forceUpdate();
    },
    getCumulative: function getCumulative(dateTime) {
      var _this11 = this;
      // 增值服务累积图
      var params = _objectSpread(_objectSpread({}, dateTime), {}, {
        areaCode: this.areaCode
      });
      mapStatisticsRequest.noVechileCumulative(params).then(function (res) {
        if (res.data && res.data.length != 0) {
          var arr = [];
          res.data.map(function (item) {
            if (item.yaxisName === '出单量') {
              arr.push(item);
            }
          });
          _this11.noVechileData = arr.map(function (item) {
            item.series.forEach(function (val) {
              "".concat(val.toFixed(2));
            });
            return {
              name: item.title,
              value: item.series,
              title: item.yaxisName
            };
          });
        } else {
          _this11.clearGetDataTimer();
        }
      });
    }
  }
};