import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import Pagination from '@/components/Pagination';
import request from '@/api/message';
import NoticeDialog from './dialog/noticeDialog';
export default {
  name: "NoticeManagement",
  components: {
    Pagination: Pagination,
    NoticeDialog: NoticeDialog
  },
  mixins: [initHeight],
  data: function data() {
    return {
      searchForm: {},
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      total: 0,
      loading: false,
      tableData: [],
      showNoticeDialog: false,
      currentType: '',
      currentId: '',
      multipleSelection: [],
      drawer: false,
      noticeLevelList: [{
        value: 'ORDINARY',
        label: '普通'
      }, {
        value: 'HIGH_LEVEL',
        label: '中级'
      }, {
        value: 'URGENT',
        label: '紧急'
      }],
      noticeRangeList: [{
        value: 'ALL',
        label: '全体用户'
      }, {
        value: 'ORG',
        label: '指定机构'
      }],
      startEndDate: []
    };
  },
  created: function created() {
    this.loadData();
  },
  methods: {
    // 日期选择事件
    dateChange: function dateChange(start, end, val) {
      if (val && val.length > 1) {
        this.searchForm[start] = "".concat(val[0], " 00:00:00");
        this.searchForm[end] = "".concat(val[1], " 23:59:59");
      } else {
        this.searchForm[start] = '';
        this.searchForm[end] = '';
      }
      ;
    },
    // 刷新
    breakData: function breakData() {
      this.loadData();
    },
    // 重置搜索表单
    resetSearch: function resetSearch() {
      this.searchForm = {};
      this.startEndDate = [];
    },
    // 搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    //获取列表数据
    loadData: function loadData() {
      var _this = this;
      var isDialogNoRefresh = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.showNoticeDialog = false;
      this.drawer = false;
      if (isDialogNoRefresh) return;
      this.loading = true;
      var params = _objectSpread(_objectSpread({}, this.listQuery), this.searchForm);
      request.getNoticeList(params).then(function (res) {
        _this.loading = false;
        if (res.code === 200) {
          _this.tableData = res.data.list;
          _this.total = res.data.page.recordsTotal;
          _this.$Bus.$emit('noticeLoadData');
        }
        ;
      }).catch(function (err) {
        _this.loading = false;
      });
    },
    // 新增、编辑公告
    openNoticeDialog: function openNoticeDialog(type) {
      var item = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      this.currentType = type;
      if (type !== 'add') {
        this.currentId = item.noticeId.toString();
      }
      ;
      this.showNoticeDialog = true;
    },
    // 详情公告
    toDetails: function toDetails(item) {
      this.$router.push({
        path: '/message/noticeDetailPage',
        query: {
          noticeId: item.noticeId,
          tabName: "\u516C\u544A\u8BE6\u60C5-".concat(item.noticeTitle),
          from: 'noticeManagement'
        }
      });
    },
    // 列表行选择事件
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 删除
    batchDelete: function batchDelete(item) {
      var _this2 = this;
      var ids = [];
      if (!_.isEmpty(item)) {
        ids.push(item.noticeId);
      } else {
        if (!_.isEmpty(this.multipleSelection)) {
          ids = _.map(this.multipleSelection, 'noticeId');
        }
        ;
      }
      ;
      this.$confirm('您确定要删除么？', {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        request.batchDeleteNotice(ids).then(function (res) {
          if (res.code === 200) {
            _this2.$message.success(res.msg);
            _this2.loadData();
          }
          ;
        });
      });
    },
    // 是否启用
    publishedChange: function publishedChange(val) {
      var _this3 = this;
      this.$confirm("\u60A8\u786E\u5B9A\u8981".concat(val.published ? '关闭' : '发布', "\u8BE5\u516C\u544A\u5417\uFF1F"), {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        type: 'warning'
      }).then(function () {
        val.published = !val.published;
        request.noticePublish(val.noticeId, val.published).then(function (res) {
          if (res.code === 200) {
            _this3.$message.success(res.msg);
            _this3.loadData();
          }
          ;
        });
      }).catch(function () {});
    }
  }
};