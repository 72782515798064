//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import dict from '@/mixins/dict';
import Pagination from '@/components/Pagination';
import recognitionApi from '@/api/recognition';
import importPolicyDialog from './importPolicyDialog.vue';
import storageRequest from '@/api/storage';
import { commonExport, getBeforeDate, getCurrentDate, getSupplyers } from '@/utils/common';
export default {
  name: 'PDFBatchParse',
  components: {
    Pagination: Pagination,
    importPolicyDialog: importPolicyDialog
  },
  mixins: [initHeight, dict],
  computed: {},
  data: function data() {
    return {
      drawer: false,
      searchForm: {},
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      loading: false,
      createdDateTime: [getBeforeDate(6), getCurrentDate()],
      showImportDialog: false,
      supplyers: []
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.supplyers = getSupplyers();
    this.loadData();
  },
  methods: {
    // 查看文件
    viewFile: function viewFile(item) {
      downFile(item.fileUrl, item.fileName);
    },
    // 打开弹窗
    openDialog: function openDialog() {
      this.showImportDialog = true;
    },
    // 重试
    reTry: function reTry(item) {
      var _this = this;
      recognitionApi.policyIdentifyBatchRetry({
        batchIdList: [item.batchId]
      }).then(function (res) {
        if (res.code === 200) {
          _this.loadData();
        }
      }).catch(function (err) {});
    },
    // 日期更改监听事件
    dateChange: function dateChange(start, end, val) {
      if (val && val.length > 1) {
        this.searchForm[start] = val[0];
        this.searchForm[end] = val[1];
      } else {
        this.searchForm[start] = null;
        this.searchForm[end] = null;
      }
    },
    // 树形点击
    nodeClick: function nodeClick(data) {
      this.searchForm.callerOrgCode = data.salesOrgCode;
      this.searchForm.areaType = data.salesOrgType;
    },
    // 导出
    exportFn: function exportFn() {
      var exporParams = {
        total: this.total,
        fileName: '批量保单识别列表',
        exportUrl: '/tp-recognition-service/policy/identify/monitor/search',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    // 刷新
    breakData: function breakData() {
      this.loadData();
    },
    // 重置搜索表单
    resetSearch: function resetSearch() {
      this.searchForm = {
        callerOrgCode: null
      };
      this.orgForm = {};
      this.createdDateTime = [getBeforeDate(6), getCurrentDate()];
    },
    // 搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    // 获取列表数据
    loadData: function loadData(val) {
      var _this2 = this;
      this.showImportDialog = false;
      this.drawer = false;
      this.searchForm.pageSize = this.listQuery.pageSize;
      this.searchForm.pageNum = this.listQuery.pageNum;
      if (this.createdDateTime && this.createdDateTime[0]) {
        this.searchForm.createdTimeStart = this.createdDateTime[0].split(' ')[0] + ' 00:00:00';
      }
      if (this.createdDateTime && this.createdDateTime[1]) {
        this.searchForm.createdTimeEnd = this.createdDateTime[1].split(' ')[0] + ' 23:59:59';
      }
      if (val) return;
      this.loading = true;
      recognitionApi.getPolicyIdentifyBatchRecord(this.searchForm).then(function (res) {
        _this2.loading = false;
        if (res.code == 200 && res.data) {
          _this2.tableData = res.data.list || [];
          _this2.total = res.data.page && res.data.page.recordsTotal || 0;
        } else {
          _this2.tableData = [];
        }
      }).catch(function (err) {
        _this2.loading = false;
      });
    }
  }
};