import _createForOfIteratorHelper from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import contractManageRequest from '@/api/insuranceContractManage';
import request from "@/api/connectConfig";
import Pagination from "@/components/Pagination";
import dictRequest from '@/api/dictionary';
export default {
  components: {
    Pagination: Pagination
  },
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    DialogType: {
      type: String,
      default: {}
    },
    ChildChannelConfigId: {
      type: String,
      default: {}
    },
    ChildInsCorpCode: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 10
      },
      loading: false,
      addForm: {
        specialId: ''
      },
      formLoading: false,
      formRules: {
        specialId: [{
          required: true,
          message: '请选择特约编码',
          trigger: 'change'
        }]
      },
      routesData: [],
      routesList: [],
      specialConfig: [],
      specialcontent: {},
      specialShow: false,
      tableData: [],
      multipleSelection: [],
      searchForm: {},
      riskTypeList: []
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.getDictItemsByCode();
        this.searchForm = {
          insCorpCode: this.ChildInsCorpCode
        };
        this.getSpecialConfigList();
        this.specialShow = false;
      }
      ;
    }
  },
  mounted: function mounted() {
    this.getDictItemsByCode();
    this.searchForm = {
      insCorpCode: this.ChildInsCorpCode
    };
    this.getSpecialConfigList();
  },
  methods: {
    // 获取险种类型
    getDictItemsByCode: function getDictItemsByCode() {
      var _this = this;
      dictRequest.getDictItemsByCode("RISK_TYPE").then(function (res) {
        if (res.data) {
          _this.riskTypeList = res.data;
        }
      });
    },
    // 重置
    resetSearch: function resetSearch() {
      this.searchForm = {
        insCorpCode: this.ChildInsCorpCode
      };
    },
    // 查询
    searchData: function searchData() {
      this.getSpecialConfigList();
    },
    // 表格选中操作
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    getSpecialConfigList: function getSpecialConfigList() {
      var _this2 = this;
      this.loading = true;
      contractManageRequest.getSpecialConfigList(this.listQuery.pageNum, this.listQuery.pageSize, this.searchForm).then(function (res) {
        _this2.loading = false;
        if (res.code == 200) {
          _this2.tableData = res.data && res.data.list ? res.data.list : [];
          _this2.total = res.data.page.recordsTotal;
        }
      }).catch(function (err) {
        _this2.loading = false;
      });
    },
    save: function save() {
      var _this3 = this;
      //保存数据
      if (this.multipleSelection.length != 0) {
        var list = [];
        var _iterator = _createForOfIteratorHelper(this.multipleSelection),
          _step;
        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var i = _step.value;
            list.push(i.specialId);
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
        console.log(list);
        var data = {
          insCorpChannelId: parseInt(this.ChildChannelConfigId),
          specialIds: list
        };
        request.addChannelSpecial(data).then(function (res) {
          if (res.code == 200 && res.success == true) {
            _this3.formLoading = false;
            _this3.$message.success(res.msg);
            _this3.closePage();
          }
        });
      }
    },
    closePage: function closePage() {
      //关闭弹窗
      this.$emit('closePage');
    }
  }
};