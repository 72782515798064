//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    hasBeenAssociatedData: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      tableData: []
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.loadData();
      }
    }
  },
  mounted: function mounted() {
    this.loadData();
  },
  methods: {
    loadData: function loadData() {
      this.tableData = this.hasBeenAssociatedData;
    },
    closePage: function closePage() {
      //关闭弹窗
      this.$emit('closePage');
    }
  }
};