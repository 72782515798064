import "core-js/modules/es.array.concat.js";
/**
 * 角色管理接口
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 列表
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示数量
   * @param {*} data 参数
   */
  getList: function getList(pageNum, pageSize, data) {
    return request.get(getUrl("/roles?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 5), {
      params: data
    });
  },
  /**
   * 获取权限树
   */
  getMenuTree: function getMenuTree() {
    return request.patch(getUrl("/menu/tree/23", 5));
  },
  /**
   * 获取选中权限列表
   */
  getAllPermission: function getAllPermission() {
    return request.get(getUrl("/roles/permission/all", 5));
  },
  /**
   * 新增角色
   * @param {*} data 参数
   */
  addRole: function addRole(data) {
    return request.post(getUrl("/roles", 5), data);
  },
  /**
   * 查询归属与当前租户的所有角色信息
   * @param {*} id 当前租户的id
   */
  queryCurrentRole: function queryCurrentRole(id) {
    return request.get(getUrl("/roles/tenant/".concat(id), 5));
  },
  /**
   * 查询
   * @param {*} id 当前角色的id
   */
  queryRole: function queryRole(id) {
    return request.get(getUrl("/roles/".concat(id), 5));
  },
  /**
   * 更新应用
   * @param {*} data 参数
   * @param {*} id 当前数据的id
   */
  updateRole: function updateRole(id, data) {
    return request.put(getUrl("/roles/".concat(id), 5), data);
  },
  /**
   * 删除角色
   * @param {*} id 当前角色的id
   */
  deleteRoles: function deleteRoles(id) {
    return request.delete(getUrl("/roles/".concat(id), 5));
  },
  /**
   * 新增角色
   */
  recentCreate: function recentCreate(data) {
    return request.post(getUrl("/roles/recentCreate", 5), data);
  },
  /**
   * 查询角色
   * @param {*}
   */
  recentGetRole: function recentGetRole(id) {
    return request.get(getUrl("/roles/recentGetRole/".concat(id), 5));
  },
  /**
   * 修改角色
   * @param {*} 
   */
  recentUpdate: function recentUpdate(data) {
    return request.put(getUrl("/roles/recentUpdate?roleId=".concat(data.roleId), 5), data);
  },
  /**
   * 获取角色下拉框
   * @param {*} 
   */
  getRoleDropdownList: function getRoleDropdownList() {
    return request.get(getUrl("/roles/recentRoleAll", 5));
  },
  /**
   *  通过业务类型获取角色下拉框
   * 
   * @param {*} bizType 
   * @returns 
   */
  getRoleDropdownListByBizType: function getRoleDropdownListByBizType(bizType) {
    return request.get(getUrl("/roles/recentRole/".concat(bizType), 5));
  },
  /**
   * 修改密码
   * @param {*} 
   */
  changePassword: function changePassword(data) {
    return request.put(getUrl("/users/password?account=".concat(data.account), 5), data);
  },
  /**
   * 修改密码（图形验证码）
   * @param {*} 
   */
  changePasswordByCode: function changePasswordByCode(data) {
    return request.post(getUrl("/users/changePassword?account=".concat(data.account), 6), data);
  }
};
export default objApi;