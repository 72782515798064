//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
export default {
  mixins: [initHeight],
  props: {
    maintenanceInfoData: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      listLoading: false
    };
  },
  created: function created() {},
  methods: {}
};