import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '../../mixins/initHeight';
import dict from '../../mixins/dict';
import Pagination from '@/components/Pagination';
import DatePicker from '@/components/DatePicker';
import AgreementDialog from './dialog/agreementDialog';
import RateDialog from './dialog/rateDialog';
import agreementManagement from '../../api/agreementManagement';
import OemSelect from '@/components/OemSelect';
import { getSupplyers, getUserOrgInfoDealerOem } from "@/utils/common";
export default {
  name: 'AgreementManagement',
  components: {
    Pagination: Pagination,
    AgreementDialog: AgreementDialog,
    RateDialog: RateDialog,
    DatePicker: DatePicker,
    OemSelect: OemSelect
  },
  mixins: [initHeight, dict],
  computed: {},
  data: function data() {
    return {
      insuranceList: [],
      agreementStatusList: [{
        label: '已失效',
        value: 'FAILURE'
      }, {
        label: '已签订',
        value: 'SIGNED'
      }, {
        label: '生效中',
        value: 'EFFECTIVE'
      }],
      rateShowDialog: false,
      multipleSelection: [],
      drawer: true,
      searchForm: {},
      startEndTime: [],
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      defaultProps: {
        children: 'children',
        label: 'fullName'
      },
      tableData: [],
      loading: false,
      highlightCurrent: true,
      showDialog: false,
      opType: "",
      agreementItem: {},
      orgForm: {
        salesOrgCode: '',
        salesOrgName: '',
        salesOrgType: ''
      },
      bizType: localStorage.getItem("bizType") || ''
    };
  },
  created: function created() {},
  mounted: function mounted() {
    if (this.bizType === "DEALER") {
      this.initData();
    }
    ;
    this.loadData();
    this.getInsuranceData();
  },
  methods: {
    initData: function initData() {
      //初始
      var orgObj = getUserOrgInfoDealerOem(this.bizType);
      this.searchForm.salesOrgCode = orgObj.salesOrgCode;
      this.searchForm.salesOrgName = orgObj.salesOrgName;
    },
    breakData: function breakData() {
      // this.resetSearch()
      this.loadData();
    },
    // 日期选择事件
    getPickersDate: function getPickersDate(name, val) {
      if (val && val.length > 1) {
        this[name] = val;
      } else {
        this[name] = [];
      }
    },
    nodeClick: function nodeClick(val) {
      //切换机构
      this.searchForm.orgCode = val.salesOrgCode;
      this.searchForm.orgName = val.salesOrgName;
    },
    //获取保司列表
    getInsuranceData: function getInsuranceData() {
      this.insuranceList = getSupplyers();
    },
    //合作保险公司选择
    insCorpChange: function insCorpChange(val) {
      if (!val) return;
      var obj = {};
      obj = _.find(this.insuranceList, function (item) {
        return item.code == val;
      });
      this.searchForm.insCorpName = obj.shortName;
    },
    agreementStatusChange: function agreementStatusChange() {},
    removeItems: function removeItems(item) {
      var _this = this;
      //删除
      var ids = [];
      if (!_.isEmpty(item)) {
        ids.push(item.agreementId);
      } else {
        if (!_.isEmpty(this.multipleSelection)) {
          ids = _.map(this.multipleSelection, 'agreementId');
        }
      }
      if (_.isEmpty(ids)) return;
      this.$confirm('您确定要删除么？', {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        agreementManagement.deleteData(ids).then(function (res) {
          if (res.code === 200) {
            _this.$message({
              message: res.msg,
              type: 'success'
            });
            _this.loadData();
          }
        });
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      //列表行选择事件
      this.multipleSelection = val;
    },
    editConfig: function editConfig(type, item) {
      //编辑
      this.opType = type;
      this.showDialog = true;
      this.agreementItem = item;
    },
    addAgreement: function addAgreement(type) {
      //新增
      this.opType = type;
      this.showDialog = true;
    },
    addRate: function addRate(item) {
      //费率设定
      // this.opType = type;
      this.rateShowDialog = true;
      this.agreementItem = item;
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {};
      this.startEndTime = [];
      this.orgForm = {
        salesOrgCode: '',
        salesOrgName: '全部',
        salesOrgType: ''
      };
      this.listQuery.pageNum = 1;
      if (this.bizType === "DEALER") {
        this.initData();
      }
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    loadData: function loadData() {
      var _this2 = this;
      //获取列表数据
      this.loading = true;
      this.drawer = false;
      this.showDialog = false;
      this.rateShowDialog = false;
      if (this.startEndTime) {
        this.searchForm.startDate = this.startEndTime[0] ? this.startEndTime[0].split(' ')[0] + ' 00:00:00' : null;
        this.searchForm.endDate = this.startEndTime[1] ? this.startEndTime[1].split(' ')[0] + ' 23:59:59' : null;
      }
      agreementManagement.getList(this.listQuery.pageNum, this.listQuery.pageSize, this.searchForm).then(function (res) {
        if (res.code === 200) {
          _this2.tableData = res.data.list;
          _this2.loading = false;
          _this2.total = res.data.page.recordsTotal;
        }
      }).catch(function (err) {
        _this2.loading = false;
      });
    }
  }
};