var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("PageContainer", [
    _c("div", { attrs: { slot: "main" }, slot: "main" }, [
      _c(
        "div",
        {
          staticClass: "app-m-cls",
          staticStyle: { "min-height": "calc(100vh - 130px)" },
        },
        [
          _c(
            "div",
            { ref: "header", staticClass: "header-cls table-cls" },
            [
              false
                ? _c(
                    "el-collapse",
                    {
                      staticStyle: { "margin-top": "10px" },
                      model: {
                        value: _vm.activeName1,
                        callback: function ($$v) {
                          _vm.activeName1 = $$v
                        },
                        expression: "activeName1",
                      },
                    },
                    [
                      _c(
                        "el-collapse-item",
                        {
                          staticClass: "collapse-item-style",
                          attrs: { name: "1" },
                        },
                        [
                          _c("template", { slot: "title" }, [
                            _c(
                              "div",
                              {
                                staticClass: "title",
                                staticStyle: { "line-height": "25px" },
                              },
                              [
                                _c("FormLabel", {
                                  attrs: { "form-label": "车主信息" },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "el-form",
                            {
                              attrs: {
                                model: _vm.form,
                                "label-width": "120px",
                                size: "mini",
                              },
                            },
                            [
                              _c(
                                "el-row",
                                { staticStyle: { "margin-top": "10px" } },
                                [
                                  _c(
                                    "el-col",
                                    {
                                      attrs: {
                                        xs: 24,
                                        sm: 12,
                                        md: 8,
                                        lg: 8,
                                        xl: 8,
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "车主名称：" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.form.name || "-") +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    {
                                      attrs: {
                                        xs: 24,
                                        sm: 12,
                                        md: 8,
                                        lg: 8,
                                        xl: 8,
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "车主性质：" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.form
                                                  .relationshipAttribute || "-"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    {
                                      attrs: {
                                        xs: 24,
                                        sm: 12,
                                        md: 8,
                                        lg: 8,
                                        xl: 8,
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "证件类型：" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.form.certificateType || "-"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    {
                                      attrs: {
                                        xs: 24,
                                        sm: 12,
                                        md: 8,
                                        lg: 8,
                                        xl: 8,
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "证件号码：" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.form.certificateNo || "-"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    {
                                      attrs: {
                                        xs: 24,
                                        sm: 12,
                                        md: 8,
                                        lg: 8,
                                        xl: 8,
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "出生日期：" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.form.birthday
                                                  ? _vm.form.birthday.split(
                                                      " "
                                                    )[0]
                                                  : "-"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    {
                                      attrs: {
                                        xs: 24,
                                        sm: 12,
                                        md: 8,
                                        lg: 8,
                                        xl: 8,
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "性别：" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.form.sex || "-") +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    {
                                      attrs: {
                                        xs: 24,
                                        sm: 12,
                                        md: 8,
                                        lg: 8,
                                        xl: 8,
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "证件起期：" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.form.ownerCertStartDate
                                                  ? _vm.form.ownerCertStartDate.split(
                                                      " "
                                                    )[0]
                                                  : "-"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    {
                                      attrs: {
                                        xs: 24,
                                        sm: 12,
                                        md: 8,
                                        lg: 8,
                                        xl: 8,
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "证件止期：" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.form.ownerCertEndDate
                                                  ? _vm.form.ownerCertEndDate.split(
                                                      " "
                                                    )[0]
                                                  : "-"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    {
                                      attrs: {
                                        xs: 24,
                                        sm: 12,
                                        md: 8,
                                        lg: 8,
                                        xl: 8,
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "联系电话：" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.form.mobile || "-") +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    {
                                      attrs: {
                                        xs: 24,
                                        sm: 12,
                                        md: 8,
                                        lg: 8,
                                        xl: 8,
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "电子邮箱：" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.form.email || "-") +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    {
                                      attrs: {
                                        xs: 24,
                                        sm: 12,
                                        md: 8,
                                        lg: 8,
                                        xl: 8,
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "所在地区：" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.form.regionCode || "-"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    {
                                      attrs: {
                                        xs: 24,
                                        sm: 12,
                                        md: 12,
                                        lg: 12,
                                        xl: 12,
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "详细地址：" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.form.address || "-") +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-collapse",
                {
                  staticStyle: { "margin-top": "10px" },
                  model: {
                    value: _vm.activeName2,
                    callback: function ($$v) {
                      _vm.activeName2 = $$v
                    },
                    expression: "activeName2",
                  },
                },
                [
                  _c(
                    "el-collapse-item",
                    {
                      staticClass: "collapse-item-style",
                      attrs: { name: "1" },
                    },
                    [
                      _c("template", { slot: "title" }, [
                        _c(
                          "div",
                          {
                            staticClass: "title",
                            staticStyle: { "line-height": "25px" },
                          },
                          [
                            _c("FormLabel", {
                              attrs: { "form-label": "车辆信息" },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "el-form",
                        {
                          attrs: {
                            model: _vm.form,
                            "label-width": "120px",
                            size: "mini",
                          },
                        },
                        [
                          _c(
                            "el-row",
                            { staticStyle: { "margin-top": "10px" } },
                            [
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 12,
                                    md: 8,
                                    lg: 8,
                                    xl: 8,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "plateNo",
                                        label: "车牌号：",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.form.plateNo || "-") +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 12,
                                    md: 8,
                                    lg: 8,
                                    xl: 8,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: { label: "车架号：", prop: "vin" },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.form.vin || "-") + " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 12,
                                    md: 8,
                                    lg: 8,
                                    xl: 8,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "发动机号：" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.form.engineNo ||
                                              _vm.form.vhcEngine ||
                                              "-"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 12,
                                    md: 8,
                                    lg: 8,
                                    xl: 8,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "厂牌车型：" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.form.cjVhcBrandName &&
                                              _vm.form.cjVhcSeriesName
                                              ? _vm.form.cjVhcBrandName +
                                                  " / " +
                                                  _vm.form.cjVhcSeriesName
                                              : _vm.form.vehicleName || "-"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 12,
                                    md: 8,
                                    lg: 8,
                                    xl: 8,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "品牌型号：" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.form.modelName || "-") +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 12,
                                    md: 8,
                                    lg: 8,
                                    xl: 8,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "车型价格：" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.form.vehiclePrice || "-") +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 12,
                                    md: 8,
                                    lg: 8,
                                    xl: 8,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "号牌种类：" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.form.plateTypeDisplay || "-"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 12,
                                    md: 8,
                                    lg: 8,
                                    xl: 8,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "号牌颜色：" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.form.plateColorDisplay || "-"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 12,
                                    md: 8,
                                    lg: 8,
                                    xl: 8,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "行驶证车辆类型：" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.form.vehicleTypeDisplay || "-"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 12,
                                    md: 8,
                                    lg: 8,
                                    xl: 8,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "使用性质：" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.form.useAttributeDisplay || "-"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 12,
                                    md: 8,
                                    lg: 8,
                                    xl: 8,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "能源类型：" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.form.fuelTypeDisplay || "-"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 12,
                                    md: 8,
                                    lg: 8,
                                    xl: 8,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "整备质量(吨)：" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.form.wholeWeight || "-") +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 12,
                                    md: 8,
                                    lg: 8,
                                    xl: 8,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "核定载质量(吨)：" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.form.tonNumber || "-") +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 12,
                                    md: 8,
                                    lg: 8,
                                    xl: 8,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "座位数：" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.form.seats || "-") +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 12,
                                    md: 8,
                                    lg: 8,
                                    xl: 8,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "排量(升)：" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.form.displacement || "-") +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 12,
                                    md: 8,
                                    lg: 8,
                                    xl: 8,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "注册日期：" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.form.registerDate
                                              ? _vm.form.registerDate.split(
                                                  " "
                                                )[0]
                                              : "-"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 12,
                                    md: 8,
                                    lg: 8,
                                    xl: 8,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "是否过户车：" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.form.transferFlag || "-") +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm.form.transferFlag
                                ? _c(
                                    "el-col",
                                    {
                                      attrs: {
                                        xs: 24,
                                        sm: 12,
                                        md: 8,
                                        lg: 8,
                                        xl: 8,
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "过户日期：" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.form.transferDate
                                                  ? _vm.form.transferDate.split(
                                                      " "
                                                    )[0]
                                                  : "-"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 12,
                                    md: 8,
                                    lg: 8,
                                    xl: 8,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "是否大客户：" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.form.keyCustomersStamp || "-"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 12,
                                    md: 8,
                                    lg: 8,
                                    xl: 8,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "销售日期：" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.form.salesDate
                                              ? _vm.form.salesDate.split(" ")[0]
                                              : "-"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 12,
                                    md: 8,
                                    lg: 8,
                                    xl: 8,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "交车日期：" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.form.deliveryDate
                                              ? _vm.form.deliveryDate.split(
                                                  " "
                                                )[0]
                                              : "-"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ]),
    _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
      _c("div", { staticClass: "footer-btn" }, [
        _c(
          "div",
          { staticClass: "footer-btn-list" },
          [
            _c(
              "el-button",
              {
                attrs: { size: "mini", icon: "el-icon-back" },
                on: { click: _vm.goBack },
              },
              [_vm._v("返回")]
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }