import _toConsumableArray from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import OemSelect from '@/components/OemSelect';
import customersRequest from '@/api/customers';
import dict from '@/mixins/dict';
import DatePicker from '@/components/DatePicker';
import { getCurrentDate, commonExport, getUserOrgInfo, getBeforeDate } from '@/utils/common';
export default {
  name: "FollowUpStatistics",
  components: {
    OemSelect: OemSelect,
    DatePicker: DatePicker
  },
  mixins: [dict, initHeight],
  computed: {},
  watch: {},
  data: function data() {
    return {
      activeName: "Employee",
      searchForm: {
        orgCode: '',
        category: null
      },
      orgData: {
        salesOrgName: "",
        salesOrgCode: ""
      },
      tableData: [],
      createdDateTime: [getBeforeDate(30), getCurrentDate()],
      loading: false,
      trackingDetailsList: [],
      trackingDefeatList: []
    };
  },
  created: function created() {
    this.initData();
  },
  updated: function updated() {
    var _this = this;
    this.$nextTick(function () {
      _this.$refs['multipleTable'].doLayout();
    });
  },
  methods: {
    getQuantity: function getQuantity(list, item) {
      return _.find(list, function (eitem) {
        return eitem.itemCode == item.itemCode;
      }) || {};
    },
    //日期选择事件
    getPickersDate: function getPickersDate(name, val) {
      if (val && val.length > 1) {
        this[name] = val;
        this.searchForm.startTime = val[0];
        this.searchForm.endTime = val[1];
      } else {
        this[name] = [];
        this.searchForm.startTime = "";
        this.searchForm.endTime = "";
      }
    },
    // 初始化
    initData: function initData() {
      var _orgInfo = getUserOrgInfo();
      if (_orgInfo && _orgInfo.organizationId) {
        this.searchForm.orgCode = _orgInfo.orgCode;
        this.orgData.salesOrgCode = _orgInfo.orgCode;
        this.orgData.salesOrgName = _orgInfo.fullName;
      }
      this.handleTabClick();
    },
    // tab切换事件
    handleTabClick: function handleTabClick(tab, event) {
      this.searchForm.category = this.activeName;
      this.loadData();
    },
    // 导出
    exportFn: function exportFn() {
      var exporParams = {
        total: this.tableData.length,
        fileName: "\u8DDF\u8FDB\u7EDF\u8BA1",
        exportUrl: "/vi-core-service/renewal/statistical/statisticalList",
        searchForm: this.searchForm,
        exportData: []
      };
      [].concat(_toConsumableArray(this.trackingDetailsList), _toConsumableArray(this.trackingDefeatList)).forEach(function (item) {
        exporParams.exportData.push({
          label: item.itemName,
          property: 'quantity'
        });
      });
      // console.log(exporParams.exportData)
      commonExport(exporParams, this.$refs.multipleTable);
    },
    // 日期选择事件
    dateChange: function dateChange(val) {
      if (val) {
        this.searchForm.endDate = "".concat(val, " 23:59:59");
      } else {
        this.searchForm.endDate = null;
      }
    },
    // 机构选择事件
    nodeClick: function nodeClick(val) {
      this.searchForm.orgCode = val.salesOrgCode;
      this.searchForm.bizType = val.bizType || 'BROKER';
      this.searchForm.areaType = val.salesOrgType;
      ;
    },
    // 获取列表数据
    loadData: function loadData() {
      var _this2 = this;
      this.loading = true;
      if (!_.isEmpty(this.createdDateTime)) {
        this.searchForm.startTime = this.createdDateTime[0].split(" ")[0] + " 00:00:00";
        this.searchForm.endTime = this.createdDateTime[1].split(" ")[0] + " 23:59:59";
      }
      customersRequest.getFollowUpStatisticeList(this.searchForm).then(function (res) {
        _this2.loading = false;
        if (res.code === 200 && !_.isEmpty(res.data)) {
          _this2.tableData = res.data;
          _this2.trackingDetailsList = res.data[0].trackingDetailsList;
          _this2.trackingDefeatList = res.data[0].trackingDefeatList;
        } else {
          _this2.tableData = [];
          _this2.trackingDetailsList = [];
          _this2.trackingDefeatList = [];
        }
        ;
      }).catch(function (err) {
        _this2.loading = false;
      });
    }
  }
};