import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '../../mixins/initHeight';
import dict from '../../mixins/dict';
import Pagination from '@/components/Pagination';
import request from '../../api/message';
import DatePicker from '@/components/DatePicker';
import SystemMessageDialog from './dialog/systemMessageDialog';
import AnnounceDetail from './dialog/announceDetail';
import { commonExport, getCurrentDate, getBeforeDate } from '@/utils/common';
export default {
  name: "PersonManagement",
  components: {
    Pagination: Pagination,
    SystemMessageDialog: SystemMessageDialog,
    AnnounceDetail: AnnounceDetail,
    DatePicker: DatePicker
  },
  mixins: [initHeight, dict],
  data: function data() {
    return {
      msgAnnouncementStatus: 'announcementStatus',
      msgStatus: '',
      total: 0,
      listLoading: true,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      createdDateTime: [getBeforeDate(6), getCurrentDate()],
      tableData: [],
      announcementTableData: [],
      multipleSelection: [],
      loading: false,
      showAddDialog: false,
      showAnnounceDialog: false,
      currentId: '',
      currentData: {},
      opType: '',
      timeoutForExport: null,
      searchForm: {
        messageSendDatetimeStart: getBeforeDate(6) + ' 00:00:00',
        messageSendDatetimeEnd: getCurrentDate() + ' 23:59:59'
      }
    };
  },
  watch: {
    '$route': function $route(newValue) {
      this.listQuery = {
        pageNum: 1,
        pageSize: 20
      };
      this.searchForm = {
        messageSendDatetimeStart: getBeforeDate(6) + ' 00:00:00',
        messageSendDatetimeEnd: getCurrentDate() + ' 23:59:59'
      };
      this.createdDateTime = [getBeforeDate(6), getCurrentDate()];
      this.loadData();
    }
  },
  computed: {
    msgType: function msgType() {
      var curData = '';
      if (this.$route.path === "/message/person") {
        curData = "INDIVIDUAL_MESSAGE";
      } else {
        curData = "SYSTEM_MESSAGE";
      }
      return curData;
    },
    canAdd: function canAdd() {
      return this.$route.path != "/message/view";
    }
  },
  mounted: function mounted() {
    var userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.userId = userInfo.userId;
    // this.loadData();
    this.loadAnnouncemenData();
  },
  methods: {
    //日期选择事件
    getPickersDate: function getPickersDate(name, val) {
      if (val && val.length > 1) {
        this[name] = val;
        this.searchForm.messageSendDatetimeStart = val[0];
        this.searchForm.messageSendDatetimeEnd = val[1];
      } else {
        this[name] = [];
        this.searchForm.messageSendDatetimeStart = "";
        this.searchForm.messageSendDatetimeEnd = "";
      }
    },
    // 导出防抖
    exportFn: function exportFn() {
      var _this = this;
      if (this.timeoutForExport) {
        clearTimeout(this.timeoutForExport);
      }
      this.timeoutForExport = setTimeout(function () {
        _this.exportFn1();
      }, 500);
    },
    // 导出
    exportFn1: function exportFn1() {
      var exporParams = {
        total: this.total,
        limitNum: 200000,
        fileName: '公告管理列表',
        exportUrl: '/tp-message-service/message/v2/listReceivedMessage',
        searchForm: {
          messageReadState: 'UNREAD',
          messageBusinessGroup: 'NOTICE',
          pageNum: 1,
          pageSize: 9999
        }
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    closeFn: function closeFn() {
      if (this.msgAnnouncementStatus == 'announcementStatus') {
        this.loadAnnouncemenData();
      } else {
        this.loadData();
      }
    },
    handleTabClicks: function handleTabClicks(tab) {
      this.msgAnnouncementStatus = tab;
      this.msgStatus = '';
      this.listQuery = {
        pageNum: 1,
        pageSize: 20
      };
      if (this.msgAnnouncementStatus == 'announcementStatus') {
        this.loadAnnouncemenData();
      } else {
        this.loadData();
      }
    },
    selectableFn: function selectableFn(row) {
      //是否可选
      return !row.status;
    },
    handleTabClick: function handleTabClick(tab, event) {
      //tab切换事件
      this.msgStatus = tab.name;
      this.listQuery = {
        pageNum: 1,
        pageSize: 20
      };
      this.loadData();
    },
    addAndViewtMsg: function addAndViewtMsg(type, row) {
      this.showAnnounceDialog = true;
      this.currentId = row.messageReadLogId.toString();
    },
    loadAnnouncemenData: function loadAnnouncemenData() {
      var _this2 = this;
      //获取公告列表数据
      this.loading = true;
      this.showAnnounceDialog = false;
      this.showAddDialog = false;
      request.getAnnouncementList(this.listQuery.pageNum, this.listQuery.pageSize, this.searchForm).then(function (res) {
        _this2.loading = false;
        _this2.announcementTableData = res.data.list;
        //总条数
        _this2.total = res.data.page.recordsTotal;
        _this2.$Bus.$emit('noticeLoadData');
        _this2.$forceUpdate();
      }).catch(function (err) {
        _this2.loading = false;
      });
    },
    loadData: function loadData() {
      //获取业务通知列表数据
      this.loadAnnouncemenData();
      return;
      // this.showAddDialog = false
      // this.loading = true
      // request.getMessage(this.listQuery.pageNum, this.listQuery.pageSize, this.userId, this.msgType, this.msgStatus).then(res => {
      //     this.loading = false;
      //     this.tableData = res.data.list;
      //     //总条数
      //     this.total = res.data.page.recordsTotal;
      //     this.$forceUpdate()

      // }).catch((err) => {
      //     this.loading = false;
      // });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      //列表行选择事件
      this.multipleSelection = val;
    },
    updateMessage: function updateMessage(val) {
      var _this3 = this;
      //已读
      var ids = [];
      if (val == "all") {} else {
        ids = _.map(this.multipleSelection, 'id');
      }
      var postData = {
        "userId": this.userId,
        "category": this.msgType,
        "ids": ids
      };
      request.updateMessage(postData).then(function (res) {
        if (res.code == 200) {
          _this3.$message({
            type: "success",
            message: res.msg
          });
          _this3.loadData();
          _this3.$Bus.$emit('loadData');
        }
      });
    },
    // 刷新
    breakData: function breakData() {
      // this.listQuery.pageNum = 1;
      if (this.msgAnnouncementStatus == 'announcementStatus') {
        this.loadAnnouncemenData();
      } else {
        this.loadData();
      }
    },
    // 重置搜索表单
    resetSearch: function resetSearch() {
      this.searchForm = {
        messageSendDatetimeStart: getBeforeDate(6) + ' 00:00:00',
        messageSendDatetimeEnd: getCurrentDate() + ' 23:59:59'
      };
      this.createdDateTime = [getBeforeDate(6), getCurrentDate()];
    },
    // 搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    //编辑
    editMsg: function editMsg(type, item) {
      this.opType = type;
      this.showAddDialog = true;
      this.currentData = item;
    },
    //新增
    addMsg: function addMsg(type) {
      this.opType = type;
      this.showAddDialog = true;
    },
    // 删除
    deleteMsg: function deleteMsg(item) {
      var _this4 = this;
      this.$confirm('您确定要删除么？', {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        request.deleteMessageOnsite([item.messageId]).then(function (res) {
          if (res.code === 200) {
            _this4.$message.success(res.msg);
            _this4.loadAnnouncemenData();
          }
          ;
        });
      });
    }
  }
};