//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import Pagination from '@/components/Pagination';
import requestApi from '@/api/interactPlatform';
import DatePicker from '@/components/DatePicker';
import NewAccessMonitorDetails from './dialog/newAccessMonitorDetails';
import { getCurrentDate } from '@/utils/common';
export default {
  name: 'ExternalAccessMonitor',
  components: {
    Pagination: Pagination,
    NewAccessMonitorDetails: NewAccessMonitorDetails,
    DatePicker: DatePicker
  },
  mixins: [initHeight],
  data: function data() {
    return {
      searchForm: {
        requestTimeStart: null,
        requestTimeEnd: null
      },
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      loading: false,
      showDialog: false,
      currentItem: {},
      drawer: false,
      requestStartEndTime: [getCurrentDate(), getCurrentDate()],
      emptyText: '默认不加载数据，请点击查询进行搜索'
    };
  },
  created: function created() {
    // this.loadData();
  },
  methods: {
    // 日期更改监听事件
    getPickersDate: function getPickersDate(name, val) {
      if (val && val.length > 1) {
        this[name] = val;
      } else {
        this[name] = [];
      }
    },
    // 重置搜索表单
    resetSearch: function resetSearch() {
      this.searchForm = {
        requestTimeStart: getCurrentDate(),
        requestTimeEnd: getCurrentDate()
      };
      this.requestStartEndTime = [getCurrentDate(), getCurrentDate()];
    },
    // 搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    // 刷新
    breakData: function breakData() {
      this.loadData();
    },
    // 获取列表数据
    loadData: function loadData() {
      var _this = this;
      this.loading = true;
      this.showDialog = false;
      this.drawer = false;
      if (this.requestStartEndTime) {
        this.searchForm.requestTimeStart = this.requestStartEndTime[0] ? this.requestStartEndTime[0].split(' ')[0] + ' 00:00:00' : null;
        this.searchForm.requestTimeEnd = this.requestStartEndTime[1] ? this.requestStartEndTime[1].split(' ')[0] + ' 23:59:59' : null;
      }
      requestApi.getDuiprouteinputrecordList(this.listQuery.pageNum, this.listQuery.pageSize, this.searchForm).then(function (res) {
        _this.loading = false;
        if (res.code === 200) {
          _this.tableData = res.data.list;
          if (_.isEmpty(_this.tableData)) _this.emptyText = "暂无数据";
          _this.total = res.data.page.recordsTotal;
        } else {
          _this.emptyText = "暂无数据";
        }
      }).catch(function (err) {
        _this.loading = false;
      });
    },
    view: function view(item) {
      this.showDialog = true;
      this.currentItem = item;
    }
  }
};