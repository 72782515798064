/**
 * 历史车销售清单
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 列表
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   * @param {*} data 内容
   */
  getList: function getList(data) {
    return request.get(getUrl("/tact/historyVehicleSales/search", 11), {
      params: data
    });
  },
  /**
   * 新增
   * @param {*} data 内容
   */
  getDetailById: function getDetailById(id) {
    return request.get(getUrl("/tact/historyVehicleSales/get?historyVehicleSalesId=".concat(id), 11));
  }
};
export default objApi;