var state = {
  sidebar: {
    opened: sessionStorage.getItem('sidebarStatus') ? !!+sessionStorage.getItem('sidebarStatus') : true,
    withoutAnimation: false
  },
  device: 'desktop',
  size: sessionStorage.getItem('size') || 'medium',
  msg: []
};
var mutations = {
  TOGGLE_SIDEBAR: function TOGGLE_SIDEBAR(state) {
    state.sidebar.opened = !state.sidebar.opened;
    state.sidebar.withoutAnimation = false;
    if (state.sidebar.opened) {
      sessionStorage.setItem('sidebarStatus', 1);
    } else {
      sessionStorage.setItem('sidebarStatus', 0);
    }
  },
  CLOSE_SIDEBAR: function CLOSE_SIDEBAR(state, withoutAnimation) {
    sessionStorage.setItem('sidebarStatus', 0);
    state.sidebar.opened = false;
    state.sidebar.withoutAnimation = withoutAnimation;
  },
  TOGGLE_DEVICE: function TOGGLE_DEVICE(state, device) {
    state.device = device;
  },
  SET_SIZE: function SET_SIZE(state, size) {
    state.size = size;
    sessionStorage.setItem('size', size);
  },
  SET_MSG: function SET_MSG(state, msg) {
    state.msg = msg;
  }
};
var actions = {
  toggleSideBar: function toggleSideBar(_ref) {
    var commit = _ref.commit;
    commit('TOGGLE_SIDEBAR');
  },
  closeSideBar: function closeSideBar(_ref2, _ref3) {
    var commit = _ref2.commit;
    var withoutAnimation = _ref3.withoutAnimation;
    commit('CLOSE_SIDEBAR', withoutAnimation);
  },
  toggleDevice: function toggleDevice(_ref4, device) {
    var commit = _ref4.commit;
    commit('TOGGLE_DEVICE', device);
  },
  setSize: function setSize(_ref5, size) {
    var commit = _ref5.commit;
    commit('SET_SIZE', size);
  },
  setMsg: function setMsg(_ref6, msg) {
    var commit = _ref6.commit;
    commit('SET_MSG', msg);
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};