/**
 * PDF识别
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 识别字典列表
   */
  getConfigDictList: function getConfigDictList() {
    return request.get(getUrl("/ocr/policy/config/list", 17));
  },
  /**
   * 识别字典新增
   * @param {*} data 内容
   */
  ConfigDictAdd: function ConfigDictAdd(data) {
    return request.post(getUrl("/ocr/policy/config/save", 17), data);
  },
  /**
   * 识别字典编辑
   * @param {*} data 内容
   */
  ConfigDictUpdate: function ConfigDictUpdate(data) {
    return request.post(getUrl("/ocr/policy/config/update", 17), data);
  },
  /**
   * 识别字典删除
   * @param {*} data 内容
   */
  ConfigDictDel: function ConfigDictDel(configId) {
    return request.post(getUrl("/ocr/policy/config/delete/".concat(configId), 17));
  },
  /**
   * 识别列表
   * @param {*} data 内容
   */
  SearchTemplateList: function SearchTemplateList(data) {
    return request.get(getUrl("/ocr/policy/template/search", 17), {
      params: data
    });
  },
  /**
   * 解析记录列表
   * @param {*} data 内容
   */
  RecordList: function RecordList(data) {
    return request.get(getUrl("/ocr/policy/analysis/record/search", 17), {
      params: data
    });
  },
  /**
   * 新增识别模板
   * @param {*} data 内容
   */
  addTemplate: function addTemplate(data) {
    return request.post(getUrl("/ocr/policy/template/add", 17), data);
  },
  /**
   * 编辑识别模板
   * @param {*} data 内容
   */
  updateTemplate: function updateTemplate(data) {
    return request.post(getUrl("/ocr/policy/template/update", 17), data);
  },
  /**
   * 识别模板详情
   * @param {*} data 内容
   */
  getTemplateDetail: function getTemplateDetail(templateId) {
    return request.get(getUrl("/ocr/policy/template/detail/".concat(templateId), 17));
  },
  /**
   * 删除识别模板
   * @param {*} data 内容
   */
  delTemplate: function delTemplate(templateId) {
    return request.post(getUrl("/ocr/policy/template/delete/".concat(templateId), 17));
  },
  /**
   * 识别文件
   * @param {*} data 内容
   */
  getText: function getText(id) {
    return request.post(getUrl("/ocr/policy/analysis/".concat(id, "/txt"), 17));
  },
  /**
   * pdf识别为json 解析验证
   * @param {*} data 内容
   */
  analysisJson: function analysisJson(data) {
    return request.post(getUrl("/policy/identify/recognition", 17), data);
  },
  /**
   * 保单识别详情
   * @param {*} data 内容
   */
  policyAnalysisDetail: function policyAnalysisDetail(id) {
    return request.get(getUrl("/policyAnalysis/detail/".concat(id), 1));
  },
  /**
   * 保单识别供应商
   * @param {*} data 内容
   */
  getSupplierList: function getSupplierList() {
    return request.get(getUrl("/policy/identify/config/getSupplierList", 17));
  },
  /**
   * 保单识别配置列表
   * @param {*} data 内容
   */
  getPolicyIdentifyConfig: function getPolicyIdentifyConfig(data) {
    return request.get(getUrl("/policy/identify/config/search", 17), {
      params: data
    });
  },
  /**
   * 保单识别动态配置项
   * @param {*} data 内容
   */
  getPolicyIdentifyConfigSchema: function getPolicyIdentifyConfigSchema(data) {
    return request.get(getUrl("/policy/identify/config/getConfigSchema", 17), {
      params: data
    });
  },
  /**
   * 保单识别配置项详情
   * @param {*} data 内容
   */
  getPolicyIdentifyConfigDetail: function getPolicyIdentifyConfigDetail(data) {
    return request.get(getUrl("/policy/identify/config/get", 17), {
      params: data
    });
  },
  /**
   * 创建保单识别配置
   * @param {*} data 内容
   */
  createPolicyIdentifyConfig: function createPolicyIdentifyConfig(data) {
    return request.post(getUrl("/policy/identify/config/create", 17), data);
  },
  /**
   * 编辑保单识别配置
   * @param {*} configId
   */
  updatePolicyIdentifyConfig: function updatePolicyIdentifyConfig(data) {
    return request.post(getUrl("/policy/identify/config/update?configId=".concat(data.configId), 17), data);
  },
  /**
   * 禁用或启用保单识别配置
   * @param {*} configId
   */
  enableConfig: function enableConfig(configId) {
    return request.post(getUrl("/policy/identify/config/enabled?configId=".concat(configId), 17), {});
  },
  /**
   * 删除保单识别配置
   * @param {*} configId
   */
  removeConfig: function removeConfig(configId) {
    return request.post(getUrl("/policy/identify/config/batchDelete?configIds=".concat(configId), 17), {});
  },
  /**
   * 删除保单识别配置
   * @param {*} configId
   */
  getSupportInsCorpList: function getSupportInsCorpList() {
    return request.get(getUrl("/policy/identify/getSupportInsCorpList", 17), {});
  },
  /**
   * 保单识别记录
   * @param {*} data
   */
  getPolicyIdentifyRecord: function getPolicyIdentifyRecord(data) {
    return request.get(getUrl("/policy/identify/monitor/search", 17), {
      params: data
    });
  },
  /**
   * 保单识别记录
   * @param {*} data
   */
  getPolicyIdentifyMonitorDetail: function getPolicyIdentifyMonitorDetail(data) {
    return request.get(getUrl("/policy/identify/monitor/get", 17), {
      params: data
    });
  },
  /**
   * 批量保单识别记录
   * @param {*} data
   */
  getPolicyIdentifyBatchRecord: function getPolicyIdentifyBatchRecord(data) {
    return request.get(getUrl("/policy/identify/batch/search", 1), {
      params: data
    });
  },
  /**
   * 批量保单识别
   * @param {*} data
   */
  policyIdentifyBatchUpload: function policyIdentifyBatchUpload(data) {
    return request.post(getUrl("/policy/identify/batch/upload", 1), data);
  },
  /**
   * 批量保单重新识别
   * @param {*} data
   */
  policyIdentifyBatchRetry: function policyIdentifyBatchRetry(data) {
    return request.post(getUrl("/policy/identify/batch/retry", 1), data);
  }
};
export default objApi;