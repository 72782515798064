//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '../../mixins/initHeight';
import dict from '../../mixins/dict';
export default {
  name: 'customerReport',
  mixins: [initHeight, dict],
  computed: {},
  data: function data() {
    return {
      activeName: "subordinateOrg",
      drawer: false,
      actionStyle: {
        right: 0
      },
      searchForm: {},
      form: {},
      total: 0,
      tableData: [],
      multipleSelection: [],
      opType: '',
      startEndTime: [],
      loading: false
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.loadData();
  },
  methods: {
    handleTabClick: function handleTabClick() {//tab切换
    },
    resetSearch: function resetSearch() {//重置搜索表单
    },
    searchData: function searchData() {
      //搜索
      this.loadData(true);
    },
    loadData: function loadData(clickSearch) {
      //获取列表数据
      // this.loading = true;
      this.drawer = false;
    },
    handleSelectionChange: function handleSelectionChange(val) {
      //列表行选择事件
      this.multipleSelection = val;
    }
  }
};