import _defineProperty from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import Pagination from '@/components/Pagination';
import OemSelect from '@/components/OemSelect';
import request from '@/api/finance';
import exportApi from '@/api/export';
import dict from '@/mixins/dict';
import { getSupplyers } from '@/utils/common';
export default {
  components: {
    Pagination: Pagination,
    OemSelect: OemSelect
  },
  mixins: [initHeight, dict],
  data: function data() {
    return {
      drawer: false,
      searchForm: {},
      total: 0,
      orgData: {
        salesOrgName: "",
        salesOrgCode: ""
      },
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      loading: false,
      supplyers: [],
      multipleSelection: [],
      settlementStatusList: [{
        value: 'NONE_SETTLE',
        label: '未结算'
      }, {
        value: 'IN_SETTLE',
        label: '结算中'
      }, {
        value: 'ALL_SETTLE',
        label: '已结算'
      }]
    };
  },
  created: function created() {},
  mounted: function mounted() {
    //保司
    this.supplyers = getSupplyers();
    this.loadData();
  },
  methods: {
    // 导出
    exportFn: function exportFn() {
      var _fields,
        _this = this;
      var postData = {
        "fields": (_fields = {
          "insCorpName": "保险公司",
          "insCityName": "投保城市",
          "policyTypeDisplay": "单证类型",
          "policyNo": "保单",
          "riskTypeName": "保单类型",
          "plateNo": "车牌号",
          "insurantName": "被保人",
          "insuredTime": "承保日期",
          "premium": "保费(元)",
          "commissionProportion": "佣金费率(%)",
          "commissionAmount": "佣金(元)",
          "salesOrgName": "费用所属机构",
          "costPolicyName": "政策规则名称",
          "practiceNo": "执业证号"
        }, _defineProperty(_fields, "salesOrgName", "业务机构"), _defineProperty(_fields, "salemanName", "出单员"), _fields),
        "name": "机构服务费结算",
        "params": this.searchForm,
        "url": process.env.VUE_APP_APIDOMAIN + "/vi-core-service/finance/settlement/searchSalesOrgCommission"
      };
      exportApi.generateExcel(postData).then(function (res) {
        _this.$confirm("".concat(res.msg), '提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: "前往查看",
          showCancelButton: false
        }).then(function () {
          _this.$router.push({
            path: '/meExportLog'
          });
        }).catch(function () {});
      });
    },
    nodeClick: function nodeClick(val) {
      this.searchForm.orgCode = val.salesOrgCode;
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {};
      this.orgData = {};
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    // 刷新
    breakData: function breakData() {
      this.searchData();
    },
    loadData: function loadData() {
      var _this2 = this;
      //获取列表数据
      this.loading = true;
      this.drawer = false;
      var postData = {};
      postData = Object.assign(postData, this.searchForm, this.listQuery);
      request.searchSalesOrgCommission(postData).then(function (res) {
        _this2.loading = false;
        _this2.tableData = res.data.list;
        _this2.total = res.data.page.recordsTotal;
      }).catch(function (err) {
        _this2.loading = false;
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      //列表行选择事件
      this.multipleSelection = val;
    },
    batchSettlement: function batchSettlement() {
      var _this3 = this;
      var ids = [];
      if (!_.isEmpty(this.multipleSelection)) {
        ids = _.map(this.multipleSelection, 'reconciliationDetailsId');
      }
      ;
      this.$confirm('您确定要结算这些单吗？', '温馨提示', {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        request.batchSettlement('SETTLE_MAN', ids).then(function (res) {
          if (res.code === 200) {
            _this3.$message.success(res.msg);
            _this3.loadData();
          }
          ;
        });
      }).catch(function () {});
    },
    selected: function selected(row, index) {
      if (row.settlementStatus !== "NONE_SETTLE") {
        return false;
      } else {
        return true;
      }
      ;
    },
    viewDetail: function viewDetail(item) {
      //查看保单详情
      var router_path = this.$route.path;
      this.$router.push({
        path: "/policy/detail",
        query: {
          policyId: item.policyId,
          policyNo: item.policyNo,
          back: router_path,
          plateNo: item.plateNo
        }
      });
    }
  }
};