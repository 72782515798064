var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "PageContainer",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.fullscreenLoading,
              expression: "fullscreenLoading",
            },
          ],
        },
        [
          _c("div", { attrs: { slot: "main" }, slot: "main" }, [
            _c(
              "div",
              {
                staticClass: "app-m-cls",
                staticStyle: { "min-height": "calc(100vh - 130px)" },
              },
              [
                _c(
                  "div",
                  { ref: "header", staticClass: "header-cls" },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "addForm",
                        staticStyle: { "margin-bottom": "-20px" },
                        attrs: {
                          model: _vm.addForm,
                          rules: _vm.formRules,
                          "label-width": "130px",
                          size: "mini",
                        },
                      },
                      [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              {
                                staticStyle: { display: "flex" },
                                attrs: { span: 24 },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "line-height": "46px",
                                      "font-size": "14px",
                                      "font-weight": "bold",
                                    },
                                  },
                                  [_vm._v("产品服务类别:")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "70%",
                                      "margin-left": "10px",
                                      "line-height": "46px",
                                      color: "#3c3c3c",
                                      "font-size": "16px",
                                      "font-weight": "bold",
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.productsTypeName))]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-collapse",
                          {
                            staticStyle: { "margin-top": "10px" },
                            model: {
                              value: _vm.activeOne,
                              callback: function ($$v) {
                                _vm.activeOne = $$v
                              },
                              expression: "activeOne",
                            },
                          },
                          [
                            _c(
                              "el-collapse-item",
                              {
                                staticClass: "collapse-item-style",
                                attrs: { name: "1" },
                              },
                              [
                                _c("template", { slot: "title" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "title",
                                      staticStyle: { "line-height": "25px" },
                                    },
                                    [
                                      _c("FormLabel", {
                                        staticStyle: { "font-size": "14px" },
                                        attrs: { "form-label": "产品基础信息" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c(
                                  "el-row",
                                  {
                                    staticStyle: {
                                      "justify-content": "flex-start",
                                      display: "flex",
                                      "flex-wrap": "wrap",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "产品名称:",
                                              prop: "productName",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                placeholder: "请输入产品名称",
                                                maxlength: "100",
                                                disabled:
                                                  _vm.dataType == "detail",
                                                clearable: "",
                                              },
                                              model: {
                                                value: _vm.addForm.productName,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.addForm,
                                                    "productName",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "addForm.productName",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "产品编码:",
                                              prop: "productCode",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                placeholder: "请输入产品编码",
                                                maxlength: "100",
                                                disabled:
                                                  _vm.dataType == "detail",
                                                clearable: "",
                                              },
                                              model: {
                                                value: _vm.addForm.productCode,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.addForm,
                                                    "productCode",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "addForm.productCode",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "保险公司:",
                                              prop: "supplierCode",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  disabled:
                                                    _vm.dataType == "detail",
                                                  size: "mini",
                                                  clearable: "",
                                                  placeholder: "请选择供应商",
                                                },
                                                model: {
                                                  value:
                                                    _vm.addForm.supplierCode,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.addForm,
                                                      "supplierCode",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "addForm.supplierCode",
                                                },
                                              },
                                              _vm._l(
                                                _vm.suppliersLists,
                                                function (item, index) {
                                                  return _c("el-option", {
                                                    key: index,
                                                    attrs: {
                                                      label: item.supplierName,
                                                      value: item.supplierCode,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "品牌车系:",
                                              prop: "carTypeVal",
                                            },
                                          },
                                          [
                                            _vm.AddType == "BZHG"
                                              ? _c("CarBrandsModel", {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    carTypeValue:
                                                      _vm.addForm.carTypeVal,
                                                    disabled:
                                                      _vm.dataType == "detail",
                                                  },
                                                  on: {
                                                    carHandleChanged:
                                                      _vm.CarHandleChange,
                                                  },
                                                })
                                              : _c("CarBrands", {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    carTypeValue:
                                                      _vm.addForm.carTypeVal,
                                                    disabled:
                                                      _vm.dataType == "detail",
                                                  },
                                                  on: {
                                                    carHandleChanged:
                                                      _vm.CarHandleChange,
                                                  },
                                                }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "保司车系中文名:",
                                              prop: "supplierSeriesChineseName",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                placeholder:
                                                  "请输入保险公司车系中文名",
                                                maxlength: "40",
                                                disabled:
                                                  _vm.dataType == "detail",
                                                clearable: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.addForm
                                                    .supplierSeriesChineseName,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.addForm,
                                                    "supplierSeriesChineseName",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "addForm.supplierSeriesChineseName",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: { label: "产品有效期起期:" },
                                          },
                                          [
                                            _c("el-date-picker", {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                placeholder: "证件有效起期",
                                                disabled:
                                                  _vm.dataType == "detail",
                                                type: "date",
                                                format: "yyyy-MM-dd",
                                                "value-format":
                                                  "yyyy-MM-dd HH:mm:ss",
                                                "picker-options":
                                                  _vm.relationshipDisabledStart,
                                              },
                                              model: {
                                                value: _vm.effectiveStartDate,
                                                callback: function ($$v) {
                                                  _vm.effectiveStartDate = $$v
                                                },
                                                expression:
                                                  "effectiveStartDate",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: { label: "产品有效期止期:" },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                },
                                              },
                                              [
                                                _c("el-date-picker", {
                                                  staticStyle: { width: "80%" },
                                                  attrs: {
                                                    placeholder: "证件有效止期",
                                                    type: "date",
                                                    format: "yyyy-MM-dd",
                                                    "value-format":
                                                      "yyyy-MM-dd HH:mm:ss",
                                                    "picker-options":
                                                      _vm.relationshipDisabledEnd,
                                                    disabled:
                                                      _vm.addForm.isLong ||
                                                      _vm.effectiveEndDate ===
                                                        "9999-12-31 23:59:59",
                                                  },
                                                  model: {
                                                    value: _vm.effectiveEndDate,
                                                    callback: function ($$v) {
                                                      _vm.effectiveEndDate = $$v
                                                    },
                                                    expression:
                                                      "effectiveEndDate",
                                                  },
                                                }),
                                                _c(
                                                  "el-checkbox",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    on: {
                                                      change: function (val) {
                                                        return _vm.dataValueChange(
                                                          val
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value: _vm.addForm.isLong,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.addForm,
                                                          "isLong",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "addForm.isLong",
                                                    },
                                                  },
                                                  [_vm._v("长期")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "费用类型:",
                                              "label-width": "130px",
                                              prop: "costType",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  disabled:
                                                    _vm.dataType == "detail",
                                                  size: "mini",
                                                  clearable: "",
                                                  placeholder: "请选择费用类型",
                                                },
                                                model: {
                                                  value: _vm.addForm.costType,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.addForm,
                                                      "costType",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "addForm.costType",
                                                },
                                              },
                                              [
                                                _c("el-option", {
                                                  attrs: {
                                                    label: "见费",
                                                    value: "COST",
                                                  },
                                                }),
                                                _c("el-option", {
                                                  attrs: {
                                                    label: "非见费",
                                                    value: "NO_COST",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm.AddType == "DBC" ||
                                    _vm.AddType == "YB" ||
                                    _vm.AddType == "SB"
                                      ? _c(
                                          "el-col",
                                          { attrs: { span: 8 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "产品类型名称:",
                                                  "label-width": "130px",
                                                  prop: "productTypeName",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    staticStyle: {
                                                      width: "100%",
                                                    },
                                                    attrs: {
                                                      disabled:
                                                        _vm.dataType ==
                                                        "detail",
                                                      size: "mini",
                                                      clearable: "",
                                                      placeholder:
                                                        "请选择产品类型名称",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.addForm
                                                          .productTypeName,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.addForm,
                                                          "productTypeName",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "addForm.productTypeName",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.productTypeList,
                                                    function (item, index) {
                                                      return _c("el-option", {
                                                        key: index,
                                                        attrs: {
                                                          label: item.itemName,
                                                          value: item.itemCode,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-collapse",
                          {
                            staticStyle: { "margin-top": "10px" },
                            model: {
                              value: _vm.activeTwo,
                              callback: function ($$v) {
                                _vm.activeTwo = $$v
                              },
                              expression: "activeTwo",
                            },
                          },
                          [
                            _c(
                              "el-collapse-item",
                              {
                                staticClass: "collapse-item-style",
                                attrs: { name: "1" },
                              },
                              [
                                _c("template", { slot: "title" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "title",
                                      staticStyle: { "line-height": "25px" },
                                    },
                                    [
                                      _c("FormLabel", {
                                        staticStyle: { "font-size": "14px" },
                                        attrs: { "form-label": "产品服务信息" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c(
                                  "el-row",
                                  _vm._l(
                                    _vm.attributeList,
                                    function (item, index) {
                                      return _c(
                                        "el-col",
                                        {
                                          key: index,
                                          attrs: {
                                            span:
                                              item.inputType == "SELECT_BUTTON"
                                                ? 8
                                                : 8,
                                          },
                                        },
                                        [
                                          item.inputType == "TEXT"
                                            ? _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label:
                                                      item.attributeName + ":",
                                                    prop: item.attributeCode,
                                                    rules: [
                                                      {
                                                        required:
                                                          item.requiredState,
                                                        message:
                                                          "请输入" +
                                                          item.attributeName,
                                                        trigger: "blur",
                                                      },
                                                    ],
                                                    "label-width": "160px",
                                                  },
                                                },
                                                [
                                                  item.dateType == "INTEGER"
                                                    ? _c("el-input", {
                                                        staticStyle: {
                                                          width: "100%",
                                                        },
                                                        attrs: {
                                                          disabled:
                                                            _vm.dataType ==
                                                            "detail",
                                                          placeholder:
                                                            "请输入" +
                                                            item.attributeName,
                                                          maxlength:
                                                            item.maxValue,
                                                          clearable: "",
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            _vm.addForm[
                                                              item.attributeCode
                                                            ] = _vm.addForm[
                                                              item.attributeCode
                                                            ].replace(
                                                              /[^\d]/g,
                                                              ""
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.addForm[
                                                              item.attributeCode
                                                            ],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.addForm,
                                                              item.attributeCode,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "addForm[item.attributeCode]",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  item.dateType == "DECIMALS"
                                                    ? _c("el-input", {
                                                        staticStyle: {
                                                          width: "100%",
                                                        },
                                                        attrs: {
                                                          disabled:
                                                            _vm.dataType ==
                                                            "detail",
                                                          placeholder:
                                                            "请输入" +
                                                            item.attributeName,
                                                          maxlength:
                                                            item.maxValue,
                                                          clearable: "",
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            return _vm.limitInput(
                                                              $event,
                                                              item.attributeCode
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.addForm[
                                                              item.attributeCode
                                                            ],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.addForm,
                                                              item.attributeCode,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "addForm[item.attributeCode]",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  item.dateType == "CHARACTER"
                                                    ? _c("el-input", {
                                                        staticStyle: {
                                                          width: "100%",
                                                        },
                                                        attrs: {
                                                          disabled:
                                                            _vm.dataType ==
                                                            "detail",
                                                          placeholder:
                                                            "请输入" +
                                                            item.attributeName,
                                                          maxlength:
                                                            item.maxValue,
                                                          clearable: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.addForm[
                                                              item.attributeCode
                                                            ],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.addForm,
                                                              item.attributeCode,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "addForm[item.attributeCode]",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          item.inputType == "TEXTAREA"
                                            ? _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label:
                                                      item.attributeName + ":",
                                                    prop: item.attributeCode,
                                                    rules: [
                                                      {
                                                        required:
                                                          item.requiredState,
                                                        message:
                                                          "请输入" +
                                                          item.attributeName,
                                                        trigger: "blur",
                                                      },
                                                    ],
                                                    "label-width": "160px",
                                                  },
                                                },
                                                [
                                                  _c("el-input", {
                                                    staticStyle: {
                                                      width: "100%",
                                                    },
                                                    attrs: {
                                                      disabled:
                                                        _vm.dataType ==
                                                        "detail",
                                                      type: "textarea",
                                                      maxlength: item.maxValue,
                                                      placeholder:
                                                        "请输入" +
                                                        item.attributeName,
                                                      "show-word-limit": "",
                                                      clearable: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.addForm[
                                                          item.attributeCode
                                                        ],
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.addForm,
                                                          item.attributeCode,
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "addForm[item.attributeCode]",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          item.inputType == "SELECT"
                                            ? _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label:
                                                      item.attributeName + ":",
                                                    prop: item.attributeCode,
                                                    rules: [
                                                      {
                                                        required:
                                                          item.requiredState,
                                                        message:
                                                          "请选择" +
                                                          item.attributeName,
                                                        trigger: "change",
                                                      },
                                                    ],
                                                    "label-width": "160px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      staticStyle: {
                                                        width: "100%",
                                                      },
                                                      attrs: {
                                                        disabled:
                                                          _vm.dataType ==
                                                          "detail",
                                                        size: "mini",
                                                        clearable: "",
                                                        placeholder:
                                                          "请选择" +
                                                          item.attributeName,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.addForm[
                                                            item.attributeCode
                                                          ],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.addForm,
                                                            item.attributeCode,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "addForm[item.attributeCode]",
                                                      },
                                                    },
                                                    _vm._l(
                                                      item.options,
                                                      function (citem) {
                                                        return _c("el-option", {
                                                          key: citem.itemId,
                                                          attrs: {
                                                            label:
                                                              citem.itemName,
                                                            value:
                                                              item.attributeCode ==
                                                                "extensionScheme" ||
                                                              item.attributeCode ==
                                                                "guaranteeRange" ||
                                                              item.attributeCode ==
                                                                "ternScheme" ||
                                                              item.attributeCode ==
                                                                "replacementScheme"
                                                                ? citem.itemName
                                                                : citem.itemCode,
                                                          },
                                                        })
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          item.inputType == "MULTI_SELECT"
                                            ? _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label:
                                                      item.attributeName + ":",
                                                    prop: item.attributeCode,
                                                    rules: [
                                                      {
                                                        required:
                                                          item.requiredState,
                                                        message:
                                                          "请选择" +
                                                          item.attributeName,
                                                        trigger: "change",
                                                      },
                                                    ],
                                                    "label-width": "160px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      staticStyle: {
                                                        width: "100%",
                                                      },
                                                      attrs: {
                                                        disabled:
                                                          _vm.dataType ==
                                                          "detail",
                                                        multiple: "",
                                                        size: "mini",
                                                        clearable: "",
                                                        placeholder:
                                                          "请选择" +
                                                          item.attributeName,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.addForm[
                                                            item.attributeCode
                                                          ],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.addForm,
                                                            item.attributeCode,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "addForm[item.attributeCode]",
                                                      },
                                                    },
                                                    _vm._l(
                                                      item.options,
                                                      function (citem) {
                                                        return _c("el-option", {
                                                          key: citem.itemId,
                                                          attrs: {
                                                            label:
                                                              citem.itemName,
                                                            value:
                                                              citem.itemCode,
                                                          },
                                                        })
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          item.inputType == "DATE"
                                            ? _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label:
                                                      item.attributeName + ":",
                                                    prop: item.attributeCode,
                                                    rules: [
                                                      {
                                                        required:
                                                          item.requiredState,
                                                        message:
                                                          "请选择" +
                                                          item.attributeName,
                                                        trigger: "change",
                                                      },
                                                    ],
                                                    "label-width": "160px",
                                                  },
                                                },
                                                [
                                                  _c("el-date-picker", {
                                                    staticStyle: {
                                                      width: "100%",
                                                    },
                                                    attrs: {
                                                      disabled:
                                                        _vm.dataType ==
                                                        "detail",
                                                      format: "yyyy-MM-dd",
                                                      "value-format":
                                                        "yyyy-MM-dd",
                                                      type: "date",
                                                      placeholder:
                                                        "请选择" +
                                                        item.attributeName,
                                                      clearable: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.addForm[
                                                          item.attributeCode
                                                        ],
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.addForm,
                                                          item.attributeCode,
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "addForm[item.attributeCode]",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          item.inputType == "SELECT_BUTTON"
                                            ? _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label:
                                                      item.attributeName + ":",
                                                    prop: item.attributeCode,
                                                    rules: [
                                                      {
                                                        required:
                                                          item.requiredState,
                                                        message:
                                                          "请选择" +
                                                          item.attributeName,
                                                        trigger: "change",
                                                      },
                                                    ],
                                                    "label-width": "160px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-radio-group",
                                                    {
                                                      staticStyle: {
                                                        width: "100%",
                                                      },
                                                      attrs: {
                                                        disabled:
                                                          _vm.dataType ==
                                                          "detail",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.addForm[
                                                            item.attributeCode
                                                          ],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.addForm,
                                                            item.attributeCode,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "addForm[item.attributeCode]",
                                                      },
                                                    },
                                                    _vm._l(
                                                      item.options,
                                                      function (citem, index) {
                                                        return _c(
                                                          "el-radio",
                                                          {
                                                            key: index,
                                                            attrs: {
                                                              label:
                                                                citem.itemCode,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                citem.itemName
                                                              )
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-collapse",
                          {
                            staticStyle: { "margin-top": "10px" },
                            model: {
                              value: _vm.activeThree,
                              callback: function ($$v) {
                                _vm.activeThree = $$v
                              },
                              expression: "activeThree",
                            },
                          },
                          [
                            _c(
                              "el-collapse-item",
                              {
                                staticClass: "collapse-item-style",
                                attrs: { name: "1" },
                              },
                              [
                                _c("template", { slot: "title" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "title",
                                      staticStyle: { "line-height": "25px" },
                                    },
                                    [
                                      _c("FormLabel", {
                                        staticStyle: { "font-size": "14px" },
                                        attrs: { "form-label": "产品费用信息" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              "label-width": "180px",
                                              label: "成本价-含税(元):",
                                              prop: "costPrice",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                placeholder: "请输入价格",
                                                disabled:
                                                  _vm.dataType == "detail",
                                                maxlength: "10",
                                                clearable: "",
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.limitInput(
                                                    $event,
                                                    "costPrice"
                                                  )
                                                },
                                              },
                                              model: {
                                                value: _vm.addForm.costPrice,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.addForm,
                                                    "costPrice",
                                                    $$v
                                                  )
                                                },
                                                expression: "addForm.costPrice",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "DLR采购价-含税(元):",
                                              "label-width": "180px",
                                              prop: "purchasePrice",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                placeholder: "请输入价格",
                                                disabled:
                                                  _vm.dataType == "detail",
                                                maxlength: "10",
                                                clearable: "",
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.limitInput(
                                                    $event,
                                                    "purchasePrice"
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.addForm.purchasePrice,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.addForm,
                                                    "purchasePrice",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "addForm.purchasePrice",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              "label-width": "180px",
                                              label: "DLR建议零售价-含税(元):",
                                              prop: "retailPrice",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                placeholder: "请输入价格",
                                                disabled:
                                                  _vm.dataType == "detail",
                                                maxlength: "10",
                                                clearable: "",
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.limitInput(
                                                    $event,
                                                    "retailPrice"
                                                  )
                                                },
                                              },
                                              model: {
                                                value: _vm.addForm.retailPrice,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.addForm,
                                                    "retailPrice",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "addForm.retailPrice",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "DLR折扣率(%):",
                                              "label-width": "180px",
                                              prop: "discountRate",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                placeholder: "请输入折扣率",
                                                disabled:
                                                  _vm.dataType == "detail",
                                                maxlength: "4",
                                                clearable: "",
                                              },
                                              on: {
                                                blur: function ($event) {
                                                  return _vm.overHundred(
                                                    _vm.addForm.discountRate
                                                  )
                                                },
                                                input: function ($event) {
                                                  return _vm.limitInput(
                                                    $event,
                                                    "discountRate"
                                                  )
                                                },
                                              },
                                              model: {
                                                value: _vm.addForm.discountRate,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.addForm,
                                                    "discountRate",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "addForm.discountRate",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 24 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "销售方式:",
                                              "label-width": "180px",
                                              prop: "salesMethod",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-checkbox-group",
                                              {
                                                staticClass: "checkSaleWay",
                                                attrs: {
                                                  disabled:
                                                    _vm.dataType == "detail",
                                                },
                                                model: {
                                                  value:
                                                    _vm.addForm.salesMethod,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.addForm,
                                                      "salesMethod",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "addForm.salesMethod",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-checkbox",
                                                  { attrs: { label: "SM01" } },
                                                  [_vm._v("经销商销售")]
                                                ),
                                                _c(
                                                  "el-checkbox",
                                                  { attrs: { label: "SM02" } },
                                                  [_vm._v("经销商赠送")]
                                                ),
                                                _c(
                                                  "el-checkbox",
                                                  { attrs: { label: "SM03" } },
                                                  [
                                                    _vm._v(
                                                      "厂家赠送(先扣费，再返款)"
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "el-checkbox",
                                                  { attrs: { label: "SM04" } },
                                                  [_vm._v("厂家赠送(不扣费)")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
            _c("div", { staticClass: "footer-btn" }, [
              _c(
                "div",
                { staticClass: "footer-btn-list" },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.dataType != "detail",
                          expression: "dataType != 'detail'",
                        },
                      ],
                      attrs: {
                        icon: "el-icon-check",
                        type: "primary",
                        plain: "",
                        size: "mini",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.saveClicked("addForm")
                        },
                      },
                    },
                    [_vm._v("保存")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-close", size: "mini" },
                      on: { click: _vm.closePage },
                    },
                    [_vm._v("关闭")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }