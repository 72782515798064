import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import dict from '@/mixins/dict';
import Pagination from '@/components/Pagination';
import OemSelect from '@/components/OemSelect';
import underWarrantyQueryRequest from '@/api/underWarrantyQuery';
import { getBeforeDate, getCurrentDate, commonExport, getUserOrgInfo } from '@/utils/common';
export default {
  name: 'UnderWarrantyQueryResult',
  components: {
    Pagination: Pagination,
    OemSelect: OemSelect,
    getBeforeDate: getBeforeDate,
    getCurrentDate: getCurrentDate
  },
  mixins: [initHeight, dict],
  computed: {},
  data: function data() {
    return {
      drawer: false,
      searchForm: {},
      createDateTime: [getBeforeDate(30), getCurrentDate()],
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      loading: false,
      highlightCurrent: true,
      detailLoading: false,
      activeName: " ",
      orgForm: {
        salesOrgCode: "",
        salesOrgName: ""
      },
      bizType: localStorage.getItem("bizType"),
      dealerName: ""
    };
  },
  mounted: function mounted() {
    this.getDefaultValue();
    this.loadData();
  },
  methods: {
    getDefaultValue: function getDefaultValue() {
      if (this.bizType !== 'OEM') {
        var orgObj = getUserOrgInfo();
        this.searchForm.creatorOrgCode = orgObj.orgCode;
        this.orgForm = {
          salesOrgCode: orgObj.orgCode,
          salesOrgName: orgObj.fullName
        };
        this.dealerName = orgObj.fullName;
      }
    },
    nodeClick: function nodeClick(data) {
      // 树形点击
      this.searchForm.creatorOrgCode = data.salesOrgCode;
    },
    // 重查
    retry: function retry(item) {
      var _this = this;
      underWarrantyQueryRequest.retry(item.itemId).then(function (res) {
        if (res.code == 200) {
          _this.loadData();
          _this.$message.success(res.msg);
        }
      });
    },
    //日期更改监听事件
    dateChange: function dateChange(start, end, val) {
      var searchForm = {};
      if (val && val.length > 1) {
        searchForm[start] = val[0] + ' 00:00:00';
        searchForm[end] = val[1] + ' 23:59:59';
      } else {
        searchForm[start] = '';
        searchForm[end] = '';
      }
      this.$emit('pickerDateChange', [searchForm[start], searchForm[end]]);
    },
    breakData: function breakData() {
      this.loadData();
    },
    // 导出
    exportFn: function exportFn() {
      var exporParams = {
        total: this.total,
        fileName: '续保结果',
        exportUrl: '/vi-core-service/batch/ciitc/resultSearch',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    //重置搜索表单
    resetSearch: function resetSearch() {
      this.searchForm = {};
      this.createDateTime = [getBeforeDate(30), getCurrentDate()];
      this.orgForm = {
        salesOrgCode: "",
        salesOrgName: ""
      };
      this.getDefaultValue();
    },
    //搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    //获取列表数据
    loadData: function loadData() {
      var _this2 = this;
      if (!_.isEmpty(this.createDateTime)) {
        this.searchForm.createdTimeStart = this.createDateTime[0] + " 00:00:00";
        this.searchForm.createdTimeEnd = this.createDateTime[1] + " 23:59:59";
      }
      this.loading = true;
      this.drawer = false;
      underWarrantyQueryRequest.getunderWarrantyResultList(_objectSpread(_objectSpread(_objectSpread({}, this.listQuery), this.searchForm), {}, {
        queryStatus: this.activeName
      })).then(function (res) {
        if (res.code === 200) {
          _this2.tableData = res.data.list;
          _this2.loading = false;
          _this2.total = res.data.page.recordsTotal;
        }
      }).catch(function (err) {
        _this2.loading = false;
      });
    }
  }
};