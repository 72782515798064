import _defineProperty from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import leadManagementApi from '@/api/leadManagement';
import orgRequest from '@/api/orgChildren';
import employeeRequest from '@/api/employee';
import customersRequest from '@/api/customers';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    opType: {
      type: Boolean,
      default: false
    },
    customerIdArr: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    var _form;
    return {
      form: (_form = {
        customerIdList: [],
        salesOrgName: '',
        salesOrgCode: ''
      }, _defineProperty(_form, "customerIdList", []), _defineProperty(_form, "salemanName", ''), _defineProperty(_form, "userList", []), _form),
      rules: {
        salesOrgName: [{
          required: true,
          message: '请选择所属机构',
          trigger: 'change'
        }],
        salemanName: [{
          required: true,
          message: "请添加续保专员",
          trigger: "change"
        }]
      },
      orgData: [],
      users: [],
      defaultProps: {
        children: 'children',
        label: 'fullName'
      }
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    initData: function initData() {
      // 初始化数据
      this.users = [];
      this.form = {
        salesOrgName: '',
        salesOrgCode: '',
        customerIdList: this.customerIdArr,
        salemanName: '',
        userList: []
      };
      if (this.opType) {
        this.form = {
          salesOrgName: '',
          salesOrgCode: '',
          customerIdList: this.customerIdArr,
          salemanName: [],
          userList: [],
          distributionType: 'CUSTOM'
        };
      }
      this.getOrgTree();
      this.$refs.detail.resetFields();
    },
    getOrgTree: function getOrgTree() {
      var _this = this;
      orgRequest.getTree(0).then(function (res) {
        if (res.data) {
          _this.orgData = [res.data];
        }
      });
    },
    getUsers: function getUsers(orgCode) {
      var _this2 = this;
      //获取机构人员列表
      if (!orgCode) return;
      employeeRequest.salesmanByOrgCode(orgCode).then(function (result) {
        var data = result.data || [];
        _this2.users = data;
      });
    },
    dataFilter: function dataFilter(val) {
      // 机构树查询
      this.$refs.tree.filter(val);
    },
    orgChange: function orgChange(val) {
      //组织机构树选择监听
      this.users = [];
    },
    filterNode: function filterNode(value, data) {
      // 机构树查询
      if (!value) return true;
      return data.fullName.indexOf(value) !== -1;
    },
    orgFocus: function orgFocus(val) {
      //组织机构树焦点监听
      if (this.$refs.orgSelect && (this.$refs.orgSelect.query || this.$refs.orgSelect.query == '')) {
        this.$refs.tree.filter('');
        this.$forceUpdate();
      }
    },
    visibleChange: function visibleChange(val) {
      //出单机构树下拉列表是否可见监听
      if (this.$refs.orgSelect && (this.$refs.orgSelect.query || this.$refs.orgSelect.query == '') && this.$refs.tree && !this.form.salesOrgCode) {
        this.changeTreeNodeStatus(this.$refs.tree.store.root, val);
      }
    },
    changeTreeNodeStatus: function changeTreeNodeStatus(node, expandAll) {
      //改变节点的自身expanded状态
      node.expanded = expandAll;
      for (var i = 0; i < node.childNodes.length; i++) {
        // 改变节点的自身expanded状态
        node.childNodes[i].expanded = expandAll;
      }
    },
    handleNodeClick: function handleNodeClick(data, node, target) {
      // 机构树点击事件
      this.getUsers(data.orgCode);
      this.form.salesOrgCode = data.orgCode;
      this.form.salesOrgName = data.fullName;
      this.form.salesOrgId = data.organizationId;
      this.form.salemanName = [];
      this.$refs.orgSelect.blur();
    },
    salemanNameChange: function salemanNameChange(val, type) {
      var _this3 = this;
      // 监听选择业务员事件
      if (type && val.length || !type && val) {
        var arr = [];
        this.users.forEach(function (item) {
          if (type && val.includes(item.userId)) {
            arr.push(item);
          } else if (!type && val == item.userId) {
            arr.push(item);
          }
        });
        this.form.userList = arr.map(function (data, index) {
          return {
            name: data.userName,
            userId: data.userId,
            customerQuantity: _this3.opType ? 1 : 1
          };
        });
      } else {
        this.form.userList = [];
      }
    },
    valChange: function valChange(val) {
      if (val === 'AVERAGE') {
        this.form.userList.forEach(function (val) {
          val.customerQuantity = 1;
        });
      }
    },
    validate: function validate() {
      var _this4 = this;
      // 分配人员校验
      var flag = false;
      var sum = 0;
      this.form.userList.some(function (val, index) {
        sum += val.customerQuantity;
        if (!val.customerQuantity && val.customerQuantity !== 0) {
          _this4.$message.warning("\u8BF7\u7ED9".concat(val.name, "\u5206\u914D\u4EFB\u52A1"));
          flag = true;
          return flag;
        }
      });
      if (sum !== this.form.customerIdList.length && this.form.distributionType === 'CUSTOM') {
        this.$message.warning('分配任务要等于客户信息');
        flag = true;
      }
      return flag;
    },
    aaa: function aaa(e, i) {
      i.customerQuantity = Number(e) ? Number(e) : 1;
    },
    // 保存数据
    save: function save() {
      var _this5 = this;
      this.$refs.detail.validate(function (valid) {
        if (valid) {
          if (_this5.validate()) return;
          customersRequest.taskDistribution(_this5.form).then(function (res) {
            if (res.code === 200) {
              _this5.$message.success(res.msg);
              _this5.closePage(false);
            }
          });
        }
      });
    },
    //关闭弹窗
    closePage: function closePage() {
      var _this6 = this;
      var boolean = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      this.$emit('closePage', boolean);
      setTimeout(function () {
        if (_this6.opType) {
          _this6.form.salemanName = [];
        } else {
          _this6.form.salemanName = '';
        }
        _this6.form.customerIdList = [];
      }, 1000);
    }
  }
};