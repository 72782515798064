//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '../../mixins/initHeight';
import dict from '../../mixins/dict';
import Pagination from '@/components/Pagination';
import TackDialog from './dialog/tackDialog';
import TackDetailDialog from './dialog/tackDetailDialog';
export default {
  name: 'RenewalManagement',
  components: {
    Pagination: Pagination,
    TackDialog: TackDialog,
    TackDetailDialog: TackDetailDialog
  },
  mixins: [initHeight, dict],
  computed: {},
  data: function data() {
    return {
      activeName: "all",
      drawer: false,
      searchForm: {},
      form: {},
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      defaultProps: {
        children: 'children',
        label: 'fullName'
      },
      value1: "",
      tableData: [],
      multipleSelection: [],
      opType: '',
      startEndTime: null,
      loading: false,
      orgData: [],
      highlightCurrent: true,
      showTackDialog: false,
      showTackDetailDialog: false
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.loadData();
  },
  methods: {
    tackDetail: function tackDetail() {
      //明细
      this.showTackDetailDialog = true;
    },
    editTack: function editTack(type) {
      //编辑
      this.opType = type;
      this.showTackDialog = true;
    },
    addTask: function addTask() {
      //新增
      this.opType = "add";
      this.showTackDialog = true;
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {};
    },
    searchData: function searchData() {
      //搜索
      this.loadData();
    },
    loadData: function loadData() {
      //获取列表数据
      // this.loading = true;
      this.drawer = false;
      this.showTackDialog = false;
      this.showTackDetailDialog = false;
    },
    handleSelectionChange: function handleSelectionChange(val) {
      //列表行选择事件
      this.multipleSelection = val;
    }
  }
};