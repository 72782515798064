import "core-js/modules/es.array.concat.js";
/**
 * 系统导出日志
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 导出日志分页查询
   * 
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   * @param {*} data 内容
   */
  getList: function getList(pageNum, pageSize, data) {
    return request.get(getUrl("/export-log?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 9), {
      params: data
    });
  },
  /**
   * 下载详细信息分页查询
   * 
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   * @param {*} exportId 导出id
   */
  getDetailList: function getDetailList(pageNum, pageSize, exportId) {
    return request.get(getUrl("/export-log/detail/".concat(exportId, "?pageNum=").concat(pageNum, "&pageSize=").concat(pageSize), 9));
  },
  /**
    * 我的导出日志分页查询
    * 
    * @param {*} pageNum 当前页
    * @param {*} pageSize 每页显示条数
    * @param {*} data 内容
    */
  getMeList: function getMeList(pageNum, pageSize, data) {
    return request.get(getUrl("/export-log/me?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 9), {
      params: data
    });
  }
};
export default objApi;