//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import insCorpVerificationRequest from '@/api/insCorpVerification';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    currentVerificatonItem: {
      type: Object,
      default: function _default() {}
    },
    isQuotePage: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      verificationForm: {},
      verificationLoading: false,
      isloading: false,
      isSend: false,
      verificationRlues: {
        dynamicCode: {
          required: true,
          message: '请输入验证码',
          trigger: "blur"
        }
      },
      message: "60秒后重发"
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
      ;
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    setBtnMessage: function setBtnMessage() {
      var _this = this;
      var time = 59;
      var id = setInterval(function () {
        if (time == 0) {
          _this.isSend = false;
          clearInterval(id);
        }
        _this.message = "".concat(time, "\u79D2\u540E\u91CD\u53D1");
        time--;
      }, 1000);
    },
    // 保存
    saveDynamicCode: function saveDynamicCode() {
      var _this2 = this;
      this.$refs['verificationDetail'].validate(function (valid) {
        if (valid) {
          _this2.verificationLoading = true;
          // 报价页面和保司页面调用的不是同一个接口
          if (_this2.isQuotePage) {
            var postData = {
              flowId: _this2.currentVerificatonItem.flowId,
              flowNode: _this2.currentVerificatonItem.flowNode,
              insCorpChannel: {
                insCityCode: _this2.currentVerificatonItem.insCityCode,
                insCorpCode: _this2.currentVerificatonItem.insCorpCode,
                insChannelName: _this2.currentVerificatonItem.insChannelName
              }
            };
            insCorpVerificationRequest.quoteAndSaveDynamicCode(postData).then(function (res) {
              _this2.verificationLoading = false;
              if (res.code == 200) {
                _this2.$message.success(res.msg);
                _this2.closePage();
              }
            }).catch(function (err) {
              return _this2.verificationLoading = false;
            });
          } else {
            var _postData = {
              dynamicCode: _this2.verificationForm.dynamicCode,
              insCorpChannel: {
                insCityCode: _this2.currentVerificatonItem.insChannelCityCode,
                insCorpCode: _this2.currentVerificatonItem.insChannelCorpCode,
                insChannelName: _this2.currentVerificatonItem.insChannelName
              }
            };
            insCorpVerificationRequest.saveDynamicCode(_postData).then(function (res) {
              _this2.verificationLoading = false;
              if (res.code == 200) {
                _this2.$message.success(res.msg);
                _this2.closePage();
              }
            }).catch(function (err) {
              return _this2.verificationLoading = false;
            });
          }
        }
      });
    },
    // 发送验证码
    sendDynamicCode: function sendDynamicCode() {
      var _this3 = this;
      this.isloading = true;
      var postData = {
        insCorpChannel: {
          insCityCode: this.currentVerificatonItem.insChannelCityCode,
          insCorpCode: this.currentVerificatonItem.insChannelCorpCode,
          insChannelName: this.currentVerificatonItem.insChannelName
        }
      };
      insCorpVerificationRequest.sendDynamicCode(postData).then(function (res) {
        _this3.isloading = false;
        if (res.code == 200) {
          _this3.$message.success(res.msg);
          _this3.isSend = true;
          _this3.setBtnMessage();
        }
      }).catch(function (err) {
        return _this3.isloading = false;
      });
    },
    // 关闭弹窗
    closePage: function closePage() {
      var isRefresh = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      this.$emit('closePage', isRefresh);
    },
    //初始化
    initData: function initData() {
      var _this4 = this;
      this.verificationForm = {};
      this.$nextTick(function () {
        _this4.$refs['verificationDetail'].clearValidate();
      });
    }
  }
};