import "core-js/modules/es.array.concat.js";
/**
 * 保险活动接口
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 分页查询活动表
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   *
   */
  getActivitysList: function getActivitysList(data) {
    return request.get(getUrl("/activitys", 11), {
      params: data
    });
  },
  /**
   * 分页查询活动表店端
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   *
   */
  getActivitysDdList: function getActivitysDdList(data) {
    return request.get(getUrl("/activitys/dealer/list", 11), {
      params: data
    });
  },
  /**
   * 新增活动表数据
   *
   */
  addActivitys: function addActivitys(data) {
    return request.post(getUrl("/activitys", 11), data);
  },
  /**
   * 分页查询活动核销
   *
   */
  getCheckSales: function getCheckSales(data) {
    return request.get(getUrl("/activitys/check/sales", 11), {
      params: data
    });
  },
  /**
   * 批量核销活动
   *
   */
  batchCheckSales: function batchCheckSales(data) {
    return request.post(getUrl("/activitys/check/sales", 11), data);
  },
  /**
   * 查询活动核销详情
   *
   */
  getCheckSalesInfo: function getCheckSalesInfo(activityId) {
    return request.get(getUrl("/activitys/check/sales/".concat(activityId), 11));
  },
  /**
   * 批量核销经销商
   *
   */
  batchCheckSalesDealer: function batchCheckSalesDealer(activityId, data) {
    return request.post(getUrl("/activitys/check/sales/".concat(activityId), 11), data);
  },
  /**
   * 分页查询活动经销商
   *
   */
  getCheckSalesDealer: function getCheckSalesDealer(activityId, data) {
    return request.get(getUrl("/activitys/check/sales/".concat(activityId, "/dealer"), 11), {
      params: data
    });
  },
  /**
   * 查询某一条活动客户表数据
   *
   */
  getCustomerInfo: function getCustomerInfo(activityCustomerId) {
    return request.get(getUrl("/activitys/customer/".concat(activityCustomerId), 11));
  },
  /**
   * 分页查询活动参与进度
   *
   */
  getDealerList: function getDealerList(data) {
    return request.get(getUrl("/activitys/dealer", 11), {
      params: data
    });
  },
  /**
   * 经销商查看活动详情
   *
   */
  getDealerDetail: function getDealerDetail(activityId, dealerCode) {
    return request.get(getUrl("/activitys/dealer/".concat(activityId, "/").concat(dealerCode, "/detail"), 11));
  },
  /**
   * 分页查询某次活动某个经销商的客户
   *
   */
  getDealerActivityCustomers: function getDealerActivityCustomers(activityId, dealerCode, data) {
    return request.get(getUrl("/activitys/".concat(activityId, "/").concat(dealerCode, "/customer"), 11), {
      params: data
    });
  },
  /**
   * 参与活动
   *
   */
  joinActivity: function joinActivity(activityId, dealerCode, data) {
    return request.post(getUrl("/activitys/".concat(activityId, "/").concat(dealerCode, "/customer"), 11), data);
  },
  /**
   * 批量删除活动表数据
   *
   */
  batchActivityDelete: function batchActivityDelete(ids) {
    return request.delete(getUrl("/activitys/".concat(ids), 11));
  },
  /**
   * 查询某一条活动表数据
   *
   */
  getActivityDetail: function getActivityDetail(id) {
    return request.get(getUrl("/activitys/".concat(id), 11));
  },
  /**
   * 更新活动表数据
   */
  updateActivity: function updateActivity(id, data) {
    return request.put(getUrl("/activitys/".concat(id), 11), data);
  },
  /**
   * 发布活动
   */
  releasedActivity: function releasedActivity(data) {
    return request.post(getUrl("/activitys/released", 11), data);
  },
  /**
   * 撤回活动
   */
  undoActivity: function undoActivity(data) {
    return request.post(getUrl("/activitys/undo", 11), data);
  },
  /**
   * 获取所有车型
   *
   */
  getVehicleModelsList: function getVehicleModelsList() {
    return request.get(getUrl("/vehicle-models/list", 11));
  },
  /**
   * 规则配置查看接口
   *
   */
  getMarketingRuleView: function getMarketingRuleView() {
    return request.get(getUrl("/marketing/rule/view", 1));
  },
  /**
   * 规则配置修改接口
   *
   */
  marketingRuleUpdate: function marketingRuleUpdate(data) {
    return request.put(getUrl("/marketing/rule/update", 1), data);
  }
};
export default objApi;