import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import dict from '@/mixins/dict';
import Pagination from '@/components/Pagination';
import activityCenterRequest from '@/api/activityCenter';
import dictiRequest from "@/api/dictionary";
import { limitInput, commonExport } from '@/utils/common';
export default {
  name: "CouponProduct",
  components: {
    Pagination: Pagination
  },
  mixins: [initHeight, dict],
  computed: {},
  watch: {
    showDialog: function showDialog(val) {
      var _this = this;
      if (val) {
        if (this.opType == 'add') {
          this.form = {
            enable: true
          };
        } else {
          this.getDetailById();
        }
        this.$nextTick(function () {
          return _this.$refs['detail'].clearValidate();
        });
      }
    }
  },
  data: function data() {
    return {
      formLoading: false,
      searchForm: {},
      form: {},
      formRules: {
        couponProductName: {
          required: true,
          message: '请输入产品名称',
          trigger: 'blur'
        },
        couponProductCode: {
          required: true,
          message: '请输入产品编码',
          trigger: 'blur'
        },
        couponProductType: {
          required: true,
          message: '请选择产品类别',
          trigger: 'change'
        },
        remark: {
          required: true,
          message: '请输入权益说明',
          trigger: 'blur'
        },
        couponProductMaxNum: {
          required: true,
          message: '请输入最大发放数量',
          trigger: 'blur'
        },
        couponProductCost: {
          required: true,
          message: '请输入成本',
          trigger: 'blur'
        },
        couponProductPrice: {
          required: true,
          message: '请输入价值',
          trigger: 'blur'
        }
      },
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      loading: false,
      showDialog: false,
      opType: "",
      couponProductId: null,
      couponProductTypeList: []
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.loadData();
    this.getDict();
  },
  methods: {
    handleSwitch: function handleSwitch(val, item) {
      var _this2 = this;
      item.enable = !val;
      this.$confirm("\u60A8\u786E\u5B9A\u8981".concat(val ? '启用' : '禁用', "\u8BE5\u4EA7\u54C1\uFF1F"), {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        type: 'warning'
      }).then(function () {
        activityCenterRequest.updateCouponProduct(_objectSpread(_objectSpread({}, item), {}, {
          couponProductId: item.couponProductId,
          enable: val
        })).then(function (res) {
          if (res.code == 200) {
            _this2.$message.success(res.msg);
            _this2.loadData();
          }
        });
      }).catch(function () {});
    },
    getDetailById: function getDetailById() {
      var _this3 = this;
      this.formLoading = true;
      activityCenterRequest.getDetailByCouponproductId(this.couponProductId).then(function (res) {
        if (res.code == 200) {
          _this3.form = res.data;
        }
        _this3.formLoading = false;
      });
    },
    getDict: function getDict() {
      var _this4 = this;
      dictiRequest.getDictItemsByCode("COUPON_PRODUCT_TYPE").then(function (res) {
        if (res.code == 200) {
          _this4.couponProductTypeList = res.data.map(function (item) {
            return {
              dictName: item.itemName,
              dictCode: item.itemCode
            };
          });
        }
      });
    },
    onInput: function onInput(num, limit) {
      this.$forceUpdate();
      //对输入内容做精度校验
      return limitInput(num, limit);
    },
    close: function close() {
      this.showDialog = false;
    },
    saveProduct: function saveProduct() {
      var _this5 = this;
      this.$refs['detail'].validate(function (valid) {
        if (valid) {
          _this5.formLoading = true;
          var req = _this5.opType == 'add' ? activityCenterRequest.createCouponProduct(_this5.form) : activityCenterRequest.updateCouponProduct(_objectSpread({
            couponProductId: _this5.couponProductId
          }, _this5.form));
          req.then(function (res) {
            if (res.code == 200) {
              _this5.showDialog = false;
              _this5.$message.success(res.msg);
              _this5.loadData();
            }
            _this5.formLoading = false;
          });
        }
      });
    },
    // 导出
    exportFn: function exportFn() {
      var exporParams = {
        total: this.total,
        limitNum: 200000,
        fileName: '权益管理',
        exportUrl: '/oc-core-service/couponproduct/search',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    deleteProduct: function deleteProduct(item) {
      var _this6 = this;
      //删除
      this.$confirm("\u60A8\u786E\u5B9A\u8981\u5C06\u8BE5\u4EA7\u54C1\u5220\u9664\uFF1F ", {
        distinguishCancelAndClose: true,
        dangerouslyUseHTMLString: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        activityCenterRequest.deleteCouponProduct(item.couponProductId).then(function (res) {
          if (res.code === 200) {
            _this6.$message({
              message: res.msg,
              type: 'success'
            });
            _this6.loadData();
          }
        });
      }).catch(function () {});
    },
    editProduct: function editProduct(type, item) {
      //编辑
      this.opType = type;
      this.showDialog = true;
      this.couponProductId = item.couponProductId;
    },
    createProduct: function createProduct(type) {
      //新增
      this.opType = type;
      this.showDialog = true;
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {};
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    loadData: function loadData() {
      var _this7 = this;
      //获取列表数据
      this.loading = true;
      this.showImportDialog = false;
      this.showDialog = false;
      activityCenterRequest.getCouponProductList(_objectSpread(_objectSpread({}, this.listQuery), this.searchForm)).then(function (res) {
        if (res.code === 200) {
          _this7.tableData = res.data.list;
          _this7.loading = false;
          _this7.total = res.data.page.recordsTotal;
        }
      }).catch(function (err) {
        _this7.loading = false;
      });
    },
    // 刷新
    breakData: function breakData() {
      // this.resetSearch()
      this.loadData();
    }
  }
};