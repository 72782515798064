var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageContainer", [
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.formLoading,
                expression: "formLoading",
              },
            ],
            attrs: { slot: "main" },
            slot: "main",
          },
          [
            _c(
              "div",
              {
                staticClass: "app-m-cls",
                staticStyle: { "min-height": "calc(100vh - 130px)" },
              },
              [
                _c(
                  "div",
                  { staticClass: "customer header-cls" },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "form",
                        attrs: {
                          model: _vm.form,
                          rules: _vm.formRules,
                          "label-width": "120px",
                          size: "mini",
                        },
                      },
                      [
                        _c(
                          "el-collapse",
                          {
                            model: {
                              value: _vm.activeOne,
                              callback: function ($$v) {
                                _vm.activeOne = $$v
                              },
                              expression: "activeOne",
                            },
                          },
                          [
                            _c(
                              "el-collapse-item",
                              {
                                staticClass: "collapse-item-style",
                                attrs: { name: "1" },
                              },
                              [
                                _c("template", { slot: "title" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "title",
                                      staticStyle: { "line-height": "25px" },
                                    },
                                    [
                                      _c("FormLabel", {
                                        attrs: { "form-label": "批单信息" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c(
                                  "el-row",
                                  [
                                    _vm.bizType === "OEM"
                                      ? _c(
                                          "el-col",
                                          { attrs: { span: 6 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  prop: "orgCode",
                                                  label: "组织机构",
                                                },
                                              },
                                              [
                                                _c("OemSelect", {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    isGetUserList: true,
                                                    searchForm: _vm.orgForm,
                                                    isContainOem: false,
                                                  },
                                                  on: {
                                                    getCurrentOrgUsers:
                                                      _vm.oemGetUsers,
                                                    nodeClick:
                                                      _vm.handleNodeClick,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.bizType === "DEALER"
                                      ? _c(
                                          "el-col",
                                          { attrs: { span: 8 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              { attrs: { label: "组织机构" } },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    disabled: "",
                                                    placeholder: "",
                                                  },
                                                  model: {
                                                    value: _vm.form.orgName,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "orgName",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "form.orgName",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 6 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop: "salemanBy",
                                              label: "业务员",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  filterable: "",
                                                  placeholder: "请选择业务员",
                                                },
                                                on: {
                                                  change: _vm.salemanNameChange,
                                                },
                                                model: {
                                                  value: _vm.form.salemanBy,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "salemanBy",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "form.salemanBy",
                                                },
                                              },
                                              _vm._l(
                                                _vm.salesman,
                                                function (item, index) {
                                                  return _c("el-option", {
                                                    key: index,
                                                    attrs: {
                                                      label: item.userName,
                                                      value: item.userId,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 6 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "批单号",
                                              prop: "correctNo",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              ref: "compulsoryRef",
                                              on: {
                                                input: function ($event) {
                                                  return _vm.checkPdRiskNo(
                                                    _vm.form.correctNo
                                                  )
                                                },
                                              },
                                              model: {
                                                value: _vm.form.correctNo,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "correctNo",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.correctNo",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 6 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "批单日期",
                                              prop: "correctDate",
                                            },
                                          },
                                          [
                                            _c("el-date-picker", {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                "value-format":
                                                  "yyyy-MM-dd HH:mm:ss",
                                                type: "date",
                                                placeholder: "请选择日期",
                                              },
                                              model: {
                                                value: _vm.form.correctDate,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "correctDate",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.correctDate",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-collapse",
                          {
                            staticClass: "collapse-item-style",
                            model: {
                              value: _vm.activeOne,
                              callback: function ($$v) {
                                _vm.activeOne = $$v
                              },
                              expression: "activeOne",
                            },
                          },
                          [
                            _c(
                              "el-collapse-item",
                              {
                                staticClass: "collapse-item-style",
                                attrs: { name: "1" },
                              },
                              [
                                _c("template", { slot: "title" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "title",
                                      staticStyle: { "line-height": "25px" },
                                    },
                                    [
                                      _c("FormLabel", {
                                        attrs: { "form-label": "险种信息" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 24 } },
                                      [
                                        _c(
                                          "el-radio-group",
                                          {
                                            model: {
                                              value: _vm.form.riskType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "riskType",
                                                  $$v
                                                )
                                              },
                                              expression: "form.riskType",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-radio",
                                              { attrs: { label: "BZ" } },
                                              [_vm._v("交强险")]
                                            ),
                                            _c(
                                              "el-radio",
                                              { attrs: { label: "BS" } },
                                              [_vm._v("商业险")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm.form.riskType === "BZ"
                                  ? _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 6 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              { attrs: { label: "批改类型" } },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    staticStyle: {
                                                      width: "100%",
                                                    },
                                                    attrs: {
                                                      size: "mini",
                                                      clearable: "",
                                                      placeholder:
                                                        "请选择批改类型",
                                                    },
                                                    on: {
                                                      change:
                                                        _vm.correctingChange,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form.correctType,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "correctType",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.correctType",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.correctingList,
                                                    function (item, index) {
                                                      return _c("el-option", {
                                                        key: index,
                                                        attrs: {
                                                          placeholder:
                                                            "请输入投保公司",
                                                          label: item.label,
                                                          value: item.value,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 6 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              { attrs: { label: "批改保费" } },
                                              [
                                                _c("el-input", {
                                                  ref: "compulsoryRef",
                                                  attrs: {
                                                    oninput:
                                                      "value=value.replace(/[^0-9]{0,1}(\\d*(?:\\.\\d{0,2})?).*$/g, '$1')",
                                                  },
                                                  model: {
                                                    value: _vm.form.premium,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "premium",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "form.premium",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.form.riskType === "BS"
                                  ? _c(
                                      "el-row",
                                      [
                                        _c("el-col", { attrs: { span: 24 } }, [
                                          _c(
                                            "table",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: { border: "1" },
                                            },
                                            [
                                              _c("colgroup", [
                                                _c("col", {
                                                  staticStyle: { width: "30%" },
                                                }),
                                                _c("col"),
                                                _c("col", {
                                                  staticStyle: { width: "15%" },
                                                }),
                                                _c("col", {
                                                  staticStyle: { width: "15%" },
                                                }),
                                                _c("col", {
                                                  staticStyle: { width: "5%" },
                                                }),
                                              ]),
                                              _c(
                                                "thead",
                                                {
                                                  staticStyle: {
                                                    "background-color": "#eeee",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "tr",
                                                    {
                                                      staticStyle: {
                                                        "font-size": "13px",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "th",
                                                        {
                                                          attrs: {
                                                            scope: "col",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "redDot",
                                                            },
                                                            [_vm._v("*")]
                                                          ),
                                                          _vm._v("险别名称"),
                                                        ]
                                                      ),
                                                      _c(
                                                        "th",
                                                        {
                                                          attrs: {
                                                            scope: "col",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "redDot",
                                                            },
                                                            [_vm._v("*")]
                                                          ),
                                                          _vm._v("批改保额(¥)"),
                                                        ]
                                                      ),
                                                      _c(
                                                        "th",
                                                        {
                                                          attrs: {
                                                            scope: "col",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "redDot",
                                                            },
                                                            [_vm._v("*")]
                                                          ),
                                                          _vm._v("批改保费(¥)"),
                                                        ]
                                                      ),
                                                      _c(
                                                        "th",
                                                        {
                                                          attrs: {
                                                            scope: "col",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "redDot",
                                                            },
                                                            [_vm._v("*")]
                                                          ),
                                                          _vm._v("批改类型"),
                                                        ]
                                                      ),
                                                      _c(
                                                        "th",
                                                        {
                                                          attrs: {
                                                            scope: "col",
                                                          },
                                                        },
                                                        [_vm._v("操作")]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "tbody",
                                                [
                                                  _vm._l(
                                                    _vm.form.riskKinds,
                                                    function (item, index) {
                                                      return _c(
                                                        "tr",
                                                        {
                                                          key: index,
                                                          staticClass:
                                                            "tableTr",
                                                        },
                                                        [
                                                          _c(
                                                            "td",
                                                            [
                                                              _c(
                                                                "el-form-item",
                                                                {
                                                                  attrs: {
                                                                    "label-width":
                                                                      "0px",
                                                                    prop:
                                                                      "riskKinds." +
                                                                      index +
                                                                      ".riskkindCode",
                                                                    rules: [
                                                                      {
                                                                        required: true,
                                                                        message:
                                                                          "请选择险别名称",
                                                                        trigger:
                                                                          "change",
                                                                      },
                                                                    ],
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "el-select",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          width:
                                                                            "100%",
                                                                        },
                                                                      attrs: {
                                                                        filterable:
                                                                          "",
                                                                        placeholder:
                                                                          "请选择险别名称",
                                                                        clearable:
                                                                          "",
                                                                      },
                                                                      on: {
                                                                        change:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.handleRiskItem(
                                                                              item
                                                                            )
                                                                          },
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          item.riskkindCode,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              item,
                                                                              "riskkindCode",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "item.riskkindCode",
                                                                      },
                                                                    },
                                                                    _vm._l(
                                                                      _vm.dutyAllCode,
                                                                      function (
                                                                        item,
                                                                        index
                                                                      ) {
                                                                        return _c(
                                                                          "el-option",
                                                                          {
                                                                            key: index,
                                                                            attrs:
                                                                              {
                                                                                label:
                                                                                  item.dictCode +
                                                                                  "-" +
                                                                                  item.dictName,
                                                                                value:
                                                                                  item.dictCode,
                                                                              },
                                                                          }
                                                                        )
                                                                      }
                                                                    ),
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c("td", [
                                                            item.riskkindCode
                                                              ? _c(
                                                                  "div",
                                                                  [
                                                                    item.riskkindCode ==
                                                                    "B"
                                                                      ? _c(
                                                                          "el-form-item",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                "label-width":
                                                                                  "0px",
                                                                                prop:
                                                                                  "riskKinds." +
                                                                                  index +
                                                                                  ".amount",
                                                                                rules:
                                                                                  [
                                                                                    {
                                                                                      required: true,
                                                                                      message:
                                                                                        "请选择保险金额",
                                                                                      trigger:
                                                                                        "change",
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "el-select",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    width:
                                                                                      "100%",
                                                                                  },
                                                                                attrs:
                                                                                  {
                                                                                    filterable:
                                                                                      "",
                                                                                    "default-first-option":
                                                                                      "",
                                                                                    placeholder:
                                                                                      "请选择保额",
                                                                                  },
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      item.amount,
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          item,
                                                                                          "amount",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                    expression:
                                                                                      "item.amount",
                                                                                  },
                                                                              },
                                                                              _vm._l(
                                                                                _vm.SZX_OPTIONS,
                                                                                function (
                                                                                  _item,
                                                                                  indexs
                                                                                ) {
                                                                                  return _c(
                                                                                    "el-option",
                                                                                    {
                                                                                      key: indexs,
                                                                                      attrs:
                                                                                        {
                                                                                          label:
                                                                                            _item.value,
                                                                                          value:
                                                                                            _item.value,
                                                                                        },
                                                                                    }
                                                                                  )
                                                                                }
                                                                              ),
                                                                              1
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                    item.riskkindCode ==
                                                                    "C"
                                                                      ? _c(
                                                                          "el-form-item",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                "label-width":
                                                                                  "0px",
                                                                                prop:
                                                                                  "riskKinds." +
                                                                                  index +
                                                                                  ".amount",
                                                                                rules:
                                                                                  [
                                                                                    {
                                                                                      required: true,
                                                                                      message:
                                                                                        "请选择保险金额",
                                                                                      trigger:
                                                                                        "change",
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "el-select",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    width:
                                                                                      "100%",
                                                                                  },
                                                                                attrs:
                                                                                  {
                                                                                    filterable:
                                                                                      "",
                                                                                    "allow-create":
                                                                                      "",
                                                                                    placeholder:
                                                                                      "请选择或输入保额",
                                                                                  },
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      item.amount,
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          item,
                                                                                          "amount",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                    expression:
                                                                                      "item.amount",
                                                                                  },
                                                                              },
                                                                              _vm._l(
                                                                                _vm.CKXRX_OPTIONS,
                                                                                function (
                                                                                  _item,
                                                                                  indexs
                                                                                ) {
                                                                                  return _c(
                                                                                    "el-option",
                                                                                    {
                                                                                      key: indexs,
                                                                                      attrs:
                                                                                        {
                                                                                          label:
                                                                                            _item.value,
                                                                                          value:
                                                                                            _item.value,
                                                                                        },
                                                                                    }
                                                                                  )
                                                                                }
                                                                              ),
                                                                              1
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                    item.riskkindCode ==
                                                                      "A05" ||
                                                                    item.riskkindCode ==
                                                                      "B03" ||
                                                                    item.riskkindCode ==
                                                                      "C01" ||
                                                                    item.riskkindCode ==
                                                                      "D01"
                                                                      ? _c(
                                                                          "el-form-item",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                "label-width":
                                                                                  "0px",
                                                                                prop:
                                                                                  "riskKinds." +
                                                                                  index +
                                                                                  ".amount",
                                                                                rules:
                                                                                  [
                                                                                    {
                                                                                      required: true,
                                                                                      message:
                                                                                        "请选择保险金额",
                                                                                      trigger:
                                                                                        "change",
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "el-select",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    width:
                                                                                      "100%",
                                                                                  },
                                                                                attrs:
                                                                                  {
                                                                                    filterable:
                                                                                      "",
                                                                                    placeholder:
                                                                                      "请选择保额",
                                                                                  },
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      item.amount,
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          item,
                                                                                          "amount",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                    expression:
                                                                                      "item.amount",
                                                                                  },
                                                                              },
                                                                              _vm._l(
                                                                                _vm.RATE_OPTIONS,
                                                                                function (
                                                                                  _item,
                                                                                  indexs
                                                                                ) {
                                                                                  return _c(
                                                                                    "el-option",
                                                                                    {
                                                                                      key: indexs,
                                                                                      attrs:
                                                                                        {
                                                                                          label:
                                                                                            _item.value,
                                                                                          value:
                                                                                            _item.value,
                                                                                        },
                                                                                    }
                                                                                  )
                                                                                }
                                                                              ),
                                                                              1
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                    item.riskkindCode ==
                                                                    "D"
                                                                      ? _c(
                                                                          "el-form-item",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                "label-width":
                                                                                  "0px",
                                                                                prop:
                                                                                  "riskKinds." +
                                                                                  index +
                                                                                  ".amount",
                                                                                rules:
                                                                                  [
                                                                                    {
                                                                                      required: true,
                                                                                      message:
                                                                                        "请选择保险金额",
                                                                                      trigger:
                                                                                        [
                                                                                          "blur",
                                                                                          "change",
                                                                                        ],
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "el-select",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    width:
                                                                                      "36%",
                                                                                  },
                                                                                attrs:
                                                                                  {
                                                                                    filterable:
                                                                                      "",
                                                                                    placeholder:
                                                                                      "请选择保额",
                                                                                  },
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      item.amount,
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          item,
                                                                                          "amount",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                    expression:
                                                                                      "item.amount",
                                                                                  },
                                                                              },
                                                                              _vm._l(
                                                                                _vm.CKXRX_OPTIONS,
                                                                                function (
                                                                                  _item,
                                                                                  indexs
                                                                                ) {
                                                                                  return _c(
                                                                                    "el-option",
                                                                                    {
                                                                                      key: indexs,
                                                                                      attrs:
                                                                                        {
                                                                                          label:
                                                                                            _item.value,
                                                                                          value:
                                                                                            _item.value,
                                                                                        },
                                                                                    }
                                                                                  )
                                                                                }
                                                                              ),
                                                                              1
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    "font-size":
                                                                                      "13px",
                                                                                    margin:
                                                                                      "0 3px",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "元/座 x "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "el-input",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    width:
                                                                                      "36%",
                                                                                  },
                                                                                attrs:
                                                                                  {
                                                                                    maxlength:
                                                                                      "10",
                                                                                    placeholder:
                                                                                      "请输入座位数",
                                                                                    oninput:
                                                                                      "value=value.replace(/[^0-9]{0,1}(\\d*(?:\\.\\d{0,2})?).*$/g, '$1')",
                                                                                    clearable:
                                                                                      "",
                                                                                  },
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      item.quantity,
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          item,
                                                                                          "quantity",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                    expression:
                                                                                      "item.quantity",
                                                                                  },
                                                                              }
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    "font-size":
                                                                                      "13px",
                                                                                    margin:
                                                                                      "0 3px",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "座"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                    item.riskkindCode ==
                                                                    "A04"
                                                                      ? _c(
                                                                          "el-form-item",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                "label-width":
                                                                                  "0px",
                                                                                prop:
                                                                                  "riskKinds." +
                                                                                  index +
                                                                                  ".amount",
                                                                                rules:
                                                                                  [
                                                                                    {
                                                                                      required: true,
                                                                                      message:
                                                                                        "请输入保险金额",
                                                                                      trigger:
                                                                                        "blur",
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "el-input",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    width:
                                                                                      "36%",
                                                                                  },
                                                                                attrs:
                                                                                  {
                                                                                    maxlength:
                                                                                      "10",
                                                                                    placeholder:
                                                                                      "请输入保险金额",
                                                                                    oninput:
                                                                                      "value=value.replace(/[^0-9]{0,1}(\\d*(?:\\.\\d{0,2})?).*$/g, '$1')",
                                                                                    clearable:
                                                                                      "",
                                                                                  },
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      item.amount,
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          item,
                                                                                          "amount",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                    expression:
                                                                                      "item.amount",
                                                                                  },
                                                                              }
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    "font-size":
                                                                                      "13px",
                                                                                    margin:
                                                                                      "0 3px",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "元/天 x "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "el-input",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    width:
                                                                                      "36%",
                                                                                  },
                                                                                attrs:
                                                                                  {
                                                                                    maxlength:
                                                                                      "10",
                                                                                    placeholder:
                                                                                      "请输入天数",
                                                                                    oninput:
                                                                                      "value=value.replace(/[^0-9]{0,1}(\\d*(?:\\.\\d{0,2})?).*$/g, '$1')",
                                                                                    clearable:
                                                                                      "",
                                                                                  },
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      item.quantity,
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          item,
                                                                                          "quantity",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                    expression:
                                                                                      "item.quantity",
                                                                                  },
                                                                              }
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    "font-size":
                                                                                      "13px",
                                                                                    margin:
                                                                                      "0 3px",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "天"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                    _vm._.indexOf(
                                                                      [
                                                                        "B",
                                                                        "C",
                                                                        "D",
                                                                        "D01",
                                                                        "B03",
                                                                        "C01",
                                                                        "A04",
                                                                        "A05",
                                                                      ],
                                                                      item.riskkindCode
                                                                    ) == -1
                                                                      ? _c(
                                                                          "el-form-item",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                "label-width":
                                                                                  "0px",
                                                                                prop:
                                                                                  "riskKinds." +
                                                                                  index +
                                                                                  ".amount",
                                                                                rules:
                                                                                  [
                                                                                    {
                                                                                      required: true,
                                                                                      message:
                                                                                        "请输入保险金额",
                                                                                      trigger:
                                                                                        "blur",
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "el-input",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    maxlength:
                                                                                      "10",
                                                                                    placeholder:
                                                                                      "" +
                                                                                      (_vm._.indexOf(
                                                                                        [
                                                                                          "FZ01",
                                                                                          "FZ02",
                                                                                          "FZ03",
                                                                                          "FZ04",
                                                                                        ],
                                                                                        item.riskkindCode
                                                                                      ) !=
                                                                                      -1
                                                                                        ? "请输入次数"
                                                                                        : "请输入保险金额"),
                                                                                    oninput:
                                                                                      "value=value.replace(/[^0-9]{0,1}(\\d*(?:\\.\\d{0,2})?).*$/g, '$1')",
                                                                                    clearable:
                                                                                      "",
                                                                                  },
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      item.amount,
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          item,
                                                                                          "amount",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                    expression:
                                                                                      "item.amount",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                          ]),
                                                          _c(
                                                            "td",
                                                            [
                                                              _c(
                                                                "el-form-item",
                                                                {
                                                                  attrs: {
                                                                    "label-width":
                                                                      "0px",
                                                                    prop:
                                                                      "riskKinds." +
                                                                      index +
                                                                      ".premium",
                                                                    rules: [
                                                                      {
                                                                        required: true,
                                                                        message:
                                                                          "请输入保费",
                                                                        trigger:
                                                                          "blur",
                                                                      },
                                                                    ],
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "el-input",
                                                                    {
                                                                      attrs: {
                                                                        maxlength:
                                                                          "10",
                                                                        placeholder:
                                                                          "请输入保费",
                                                                        oninput:
                                                                          "value=value.replace(/[^0-9]{0,1}(\\d*(?:\\.\\d{0,2})?).*$/g, '$1')",
                                                                        clearable:
                                                                          "",
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          item.premium,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              item,
                                                                              "premium",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "item.premium",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "td",
                                                            {
                                                              attrs: {
                                                                width: "200",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "el-form-item",
                                                                {
                                                                  attrs: {
                                                                    prop:
                                                                      "riskKinds." +
                                                                      index +
                                                                      ".correctType",
                                                                    rules: [
                                                                      {
                                                                        required: true,
                                                                        message:
                                                                          "请选择批改类型",
                                                                        trigger:
                                                                          "change",
                                                                      },
                                                                    ],
                                                                    "label-width":
                                                                      "0px",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "el-select",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          width:
                                                                            "100%",
                                                                        },
                                                                      attrs: {
                                                                        size: "mini",
                                                                        clearable:
                                                                          "",
                                                                        placeholder:
                                                                          "请输入批改类型",
                                                                      },
                                                                      on: {
                                                                        change:
                                                                          _vm.correctingChange,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          item.correctType,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              item,
                                                                              "correctType",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "item.correctType",
                                                                      },
                                                                    },
                                                                    _vm._l(
                                                                      _vm.correctingList,
                                                                      function (
                                                                        item,
                                                                        index
                                                                      ) {
                                                                        return _c(
                                                                          "el-option",
                                                                          {
                                                                            key: index,
                                                                            attrs:
                                                                              {
                                                                                label:
                                                                                  item.label,
                                                                                value:
                                                                                  item.value,
                                                                              },
                                                                          }
                                                                        )
                                                                      }
                                                                    ),
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c("td", [
                                                            _c("i", {
                                                              staticClass:
                                                                "el-icon-remove-outline deleteIcon",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.deleteInsurance(
                                                                      "one",
                                                                      index
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                          ]),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  _c("tr", [
                                                    _c(
                                                      "td",
                                                      {
                                                        attrs: { colspan: "5" },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "add_clo",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.addInsurance(
                                                                  "one"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "el-icon-circle-plus-outline addIcon",
                                                            }),
                                                            _c("span", [
                                                              _vm._v(
                                                                "添加险种"
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]),
                                                ],
                                                2
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-collapse",
                          {
                            model: {
                              value: _vm.activeTwo,
                              callback: function ($$v) {
                                _vm.activeTwo = $$v
                              },
                              expression: "activeTwo",
                            },
                          },
                          [
                            _c(
                              "el-collapse-item",
                              {
                                staticClass: "collapse-item-style",
                                attrs: { name: "1" },
                              },
                              [
                                _c("template", { slot: "title" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "title",
                                      staticStyle: { "line-height": "25px" },
                                    },
                                    [
                                      _c("FormLabel", {
                                        attrs: { "form-label": "保单信息" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 6 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop: "policyNo",
                                              label: "保单号",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              ref: "",
                                              attrs: {
                                                maxlength: "40",
                                                placeholder: "请输入保单号",
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.checkRiskNo(
                                                    _vm.form.policyNo
                                                  )
                                                },
                                              },
                                              model: {
                                                value: _vm.form.policyNo,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "policyNo",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.policyNo",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm.form.riskType === "01"
                                      ? _c(
                                          "el-col",
                                          { attrs: { span: 6 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  prop: "policyNo",
                                                  label: "交强险保单号",
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  ref: "",
                                                  attrs: {
                                                    oninput:
                                                      "value=value.replace(/[^A-Za-z0-9]/g, '')",
                                                    maxlength: "40",
                                                    placeholder:
                                                      "请输入交强险保单号",
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      return _vm.checkBzRiskNo(
                                                        _vm.form.policyNo
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: _vm.form.policyNo,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "policyNo",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "form.policyNo",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.form.riskType === "01"
                                      ? _c(
                                          "el-col",
                                          { attrs: { span: 6 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  prop: "jqxzje",
                                                  label: "交强险金额",
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  ref: "",
                                                  attrs: {
                                                    maxlength: "10",
                                                    oninput:
                                                      "value=value.replace(/[^0-9]{0,1}(\\d*(?:\\.\\d{0,2})?).*$/g, '$1')",
                                                    placeholder:
                                                      "请输入交强险金额",
                                                  },
                                                  model: {
                                                    value: _vm.form.jqxzje,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "jqxzje",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "form.jqxzje",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.form.riskType === "02"
                                      ? _c(
                                          "el-col",
                                          { attrs: { span: 6 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  prop: "policyNo",
                                                  label: "商业险保单号",
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  ref: "",
                                                  attrs: {
                                                    oninput:
                                                      "value=value.replace(/[^A-Za-z0-9]/g, '')",
                                                    maxlength: "40",
                                                    placeholder:
                                                      "请输入商业险保单号",
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      return _vm.checkBsRiskNo(
                                                        _vm.form.policyNo
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: _vm.form.policyNo,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "policyNo",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "form.policyNo",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.form.riskType === "02"
                                      ? _c(
                                          "el-col",
                                          { attrs: { span: 6 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  prop: "premium",
                                                  label: "商业险总金额",
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  ref: "",
                                                  attrs: {
                                                    maxlength: "10",
                                                    oninput:
                                                      "value=value.replace(/[^0-9]{0,1}(\\d*(?:\\.\\d{0,2})?).*$/g, '$1')",
                                                    placeholder:
                                                      "请输入商业险总金额",
                                                  },
                                                  model: {
                                                    value: _vm.form.premium,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "premium",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "form.premium",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 6 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop: "insCorpCode",
                                              label: "投保公司",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  size: "mini",
                                                  filterable: "",
                                                  clearable: "",
                                                  placeholder: "请输入投保公司",
                                                },
                                                on: {
                                                  change: _vm.insCorpChange,
                                                },
                                                model: {
                                                  value: _vm.form.insCorpCode,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "insCorpCode",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.insCorpCode",
                                                },
                                              },
                                              _vm._l(
                                                _vm.insuranceList,
                                                function (item, index) {
                                                  return _c("el-option", {
                                                    key: index,
                                                    attrs: {
                                                      placeholder:
                                                        "请输入投保公司",
                                                      label: item.shortName,
                                                      value: item.code,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 6 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop: "insCityCode",
                                              label: "投保地区",
                                            },
                                          },
                                          [
                                            _c("el-cascader", {
                                              ref: "addrssCascader",
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                clearable: "",
                                                placeholder: "请选择省市",
                                                options: _vm.policyCityItems,
                                                filterable: "",
                                              },
                                              on: {
                                                change: _vm.cityHandleChange,
                                              },
                                              model: {
                                                value: _vm.form.insCityCode,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "insCityCode",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.insCityCode",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 6 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop: "ownerName",
                                              label: "车主名称",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              ref: "",
                                              attrs: {
                                                placeholder: "请输入车主名称",
                                              },
                                              model: {
                                                value: _vm.form.ownerName,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "ownerName",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.ownerName",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 5 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop: "plateNo",
                                              label: "车牌号",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                disabled: _vm.isNewCar,
                                                minlength: "7",
                                                maxlength: "8",
                                                placeholder: "请输入车牌号",
                                                clearable: "",
                                              },
                                              on: { input: _vm.inputPlateNo },
                                              model: {
                                                value: _vm.form.plateNo,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "plateNo",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.plateNo",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 1 } },
                                      [
                                        _c(
                                          "el-checkbox",
                                          {
                                            staticStyle: {
                                              "margin-left": "10px",
                                            },
                                            on: { change: _vm.noPlateNoChange },
                                            model: {
                                              value: _vm.isNewCar,
                                              callback: function ($$v) {
                                                _vm.isNewCar = $$v
                                              },
                                              expression: "isNewCar",
                                            },
                                          },
                                          [_vm._v("未上牌")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 6 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop: "vin",
                                              label: "车架号",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              ref: "",
                                              attrs: {
                                                placeholder: "请输入车架号",
                                              },
                                              on: { input: _vm.inputvin },
                                              model: {
                                                value: _vm.form.vin,
                                                callback: function ($$v) {
                                                  _vm.$set(_vm.form, "vin", $$v)
                                                },
                                                expression: "form.vin",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 6 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop: "applicantName",
                                              label: "投保人名称",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              ref: "",
                                              attrs: {
                                                placeholder: "请输入投保人名称",
                                              },
                                              model: {
                                                value: _vm.form.applicantName,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "applicantName",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.applicantName",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 6 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop: "insurantName",
                                              label: "被保人名称",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              ref: "",
                                              attrs: {
                                                placeholder: "请输入被保人名称",
                                              },
                                              model: {
                                                value: _vm.form.insurantName,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "insurantName",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.insurantName",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]
        ),
        _c(
          "span",
          {
            staticClass: "dialog-footer",
            staticStyle: {
              display: "flex",
              "justify-content": "center",
              "margin-top": "10px",
            },
            attrs: { slot: "footer" },
            slot: "footer",
          },
          [
            _c(
              "el-button",
              {
                attrs: {
                  size: "mini",
                  loading: _vm.loadingSave,
                  type: "primary",
                },
                on: { click: _vm.save },
              },
              [_vm._v("保存")]
            ),
            _c(
              "el-button",
              { attrs: { size: "mini" }, on: { click: _vm.goBack } },
              [_vm._v("返回")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }