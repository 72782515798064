//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import requestApi from '@/api/sysSetting';
import dictRequest from "@/api/dictionary";
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    opType: {
      type: String,
      default: ''
    },
    currentId: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      form: {},
      rules: {
        maskFieldName: {
          required: true,
          message: '请输入脱敏字段',
          trigger: "blur"
        },
        maskType: {
          required: true,
          message: '请选择脱敏类型',
          trigger: "change"
        },
        serviceCode: {
          required: true,
          message: '请选择归属服务',
          trigger: "change"
        },
        path: {
          required: true,
          message: '请输入接口地址',
          trigger: "blur"
        }
      },
      maskTypeData: [{
        itemCode: 'ID_CARD',
        itemName: '身份证'
      }, {
        itemCode: 'MOBILE',
        itemName: '手机号'
      }],
      serviceList: []
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      this.fileList = [];
      if (newValue) {
        this.initData();
      }
      ;
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    // 初始化数据
    initData: function initData() {
      var _this = this;
      dictRequest.getDictItemsByCode("SYSTEM_MICRO_SERVICE").then(function (res) {
        if (res.code == 200 && res.data) {
          _this.serviceList = res.data;
        }
      });
      dictRequest.getDictItemsByCode("DATA_MASK_TYPE").then(function (res) {
        if (res.code == 200 && res.data) {
          _this.maskTypeData = res.data;
        }
      });
      this.form = {
        maskFieldName: '',
        maskType: '',
        path: '',
        serviceCode: ''
      };
      if (this.opType === "edit") {
        requestApi.getExportMaskInfo(this.currentId).then(function (res) {
          if (res.code === 200 && res.data) {
            _this.form = res.data;
          }
        });
      }
      ;
      this.$refs.detail.resetFields();
    },
    // 保存数据
    save: function save() {
      var _this2 = this;
      this.$refs.detail.validate(function (valid) {
        if (valid) {
          var req = _this2.opType === 'add' ? requestApi.addExportMask(_this2.form) : requestApi.updateExportMask(_this2.form);
          req.then(function (res) {
            if (res.code === 200) {
              _this2.$message.success(res.msg);
              _this2.closePage();
            }
            ;
          });
        }
        ;
      });
    },
    // 关闭弹窗
    closePage: function closePage() {
      this.$emit('closePage');
    }
  }
};