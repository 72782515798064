//
//
//
//
//
//
//
//

export default {
  name: 'AppDownload',
  data: function data() {
    return {};
  }
};