var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "PageContainer",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.applyLoading,
              expression: "applyLoading",
            },
          ],
        },
        [
          _c("div", { attrs: { slot: "main" }, slot: "main" }, [
            _c(
              "div",
              {
                staticClass: "app-m-cls",
                staticStyle: { "min-height": "calc(100vh - 150px)" },
              },
              [
                _c(
                  "div",
                  { ref: "header", staticClass: "header-cls" },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "form",
                        attrs: {
                          model: _vm.policydetail,
                          "label-width": "180px",
                          size: "mini",
                        },
                      },
                      [
                        _c(
                          "el-collapse",
                          {
                            staticStyle: { "margin-top": "10px" },
                            model: {
                              value: _vm.activeZero,
                              callback: function ($$v) {
                                _vm.activeZero = $$v
                              },
                              expression: "activeZero",
                            },
                          },
                          [
                            _c(
                              "el-collapse-item",
                              {
                                staticClass: "collapse-item-style",
                                attrs: { name: "1" },
                              },
                              [
                                _c("template", { slot: "title" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "title",
                                      staticStyle: { "line-height": "25px" },
                                    },
                                    [
                                      _c("FormLabel", {
                                        attrs: { "form-label": "订单信息" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 12,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 6,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "订单编号：" } },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.policydetail.orderNo ||
                                                    "-"
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 12,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 6,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "保单号：" } },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.policydetail.policyNo ||
                                                    "-"
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 12,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 6,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "投保单号：" } },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.policydetail
                                                    .applyPolicyNo || "-"
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 12,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 6,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "出单机构：" } },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.policydetail
                                                    .salesOrgName || "-"
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 12,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 6,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "业务员：" } },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.policydetail
                                                    .salemanName || "-"
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 12,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 6,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "操作员：" } },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.policydetail
                                                    .creatorName || "-"
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 12,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 6,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: { label: "服务销售日期：" },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.policydetail
                                                    .orderSalesTime || "-"
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 12,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 6,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "承保时间：" } },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.policydetail
                                                    .insuredTime || "-"
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-collapse",
                          {
                            staticStyle: { "margin-top": "10px" },
                            model: {
                              value: _vm.activeOne,
                              callback: function ($$v) {
                                _vm.activeOne = $$v
                              },
                              expression: "activeOne",
                            },
                          },
                          [
                            _c(
                              "el-collapse-item",
                              {
                                staticClass: "collapse-item-style",
                                attrs: { name: "1" },
                              },
                              [
                                _c("template", { slot: "title" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "title",
                                      staticStyle: { "line-height": "25px" },
                                    },
                                    [
                                      _c("FormLabel", {
                                        attrs: { "form-label": "客户信息" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 12,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 6,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "客户名称：" } },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.policydetail
                                                    .targetOwnerName || "-"
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 12,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 6,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "联系电话：" } },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.policydetail
                                                    .targetOwnerMobile || "-"
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 12,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 6,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "证件类型：" } },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.policydetail
                                                    .targetOwnerCertificateTypeDisplay ||
                                                    "-"
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 12,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 6,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "证件号码：" } },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.policydetail
                                                    .targetOwnerCertificateNo ||
                                                    "-"
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 12,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 6,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "所在省市：" } },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.policydetail
                                                    .targetOwnerProviceName ||
                                                    "-"
                                                ) +
                                                "/" +
                                                _vm._s(
                                                  _vm.policydetail
                                                    .targetOwnerCityName || "-"
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 12,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 6,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "地址：" } },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.policydetail
                                                    .targetOwnerAddress || "-"
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 12,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 6,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "电子邮箱：" } },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.policydetail
                                                    .targetOwnerEmail || "-"
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-collapse",
                          {
                            staticStyle: { "margin-top": "10px" },
                            model: {
                              value: _vm.activeOne1,
                              callback: function ($$v) {
                                _vm.activeOne1 = $$v
                              },
                              expression: "activeOne1",
                            },
                          },
                          [
                            _c(
                              "el-collapse-item",
                              {
                                staticClass: "collapse-item-style",
                                attrs: { name: "1" },
                              },
                              [
                                _c("template", { slot: "title" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "title",
                                      staticStyle: { "line-height": "25px" },
                                    },
                                    [
                                      _c("FormLabel", {
                                        attrs: { "form-label": "服务信息" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm.policydetail.productClassCode != "SB"
                                  ? _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          {
                                            attrs: {
                                              xs: 12,
                                              sm: 12,
                                              md: 8,
                                              lg: 8,
                                              xl: 6,
                                            },
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: { label: "服务名称：" },
                                              },
                                              [
                                                _vm.policydetail
                                                  .productClassCode == "BZHG"
                                                  ? _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.policydetail &&
                                                              _vm.policydetail
                                                                .productName
                                                              ? _vm.policydetail
                                                                  .productName
                                                              : _vm.policydetail
                                                                  .policyProduct &&
                                                                _vm.policydetail
                                                                  .policyProduct
                                                                  .productName
                                                              ? _vm.policydetail
                                                                  .policyProduct
                                                                  .productName
                                                              : "-"
                                                          ) +
                                                          " "
                                                      ),
                                                    ])
                                                  : _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            (_vm.policydetail &&
                                                              _vm.policydetail
                                                                .productName) ||
                                                              "-"
                                                          ) +
                                                          " "
                                                      ),
                                                    ]),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm.policydetail.productClassCode ==
                                        "DBC"
                                          ? _c(
                                              "el-col",
                                              {
                                                attrs: {
                                                  xs: 12,
                                                  sm: 12,
                                                  md: 8,
                                                  lg: 8,
                                                  xl: 6,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "card_item" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card_item_label",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "累计最多赔偿天数(天)："
                                                        ),
                                                      ]
                                                    ),
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.policydetail
                                                            .targetCumulateMaxCompensationDay
                                                            ? _vm.policydetail
                                                                .targetCumulateMaxCompensationDay
                                                            : "-"
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.policydetail.productClassCode ==
                                        "DBC"
                                          ? _c(
                                              "el-col",
                                              {
                                                attrs: {
                                                  xs: 12,
                                                  sm: 12,
                                                  md: 8,
                                                  lg: 8,
                                                  xl: 6,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "card_item" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card_item_label",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "每次事故最多赔偿天数(天)："
                                                        ),
                                                      ]
                                                    ),
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.policydetail
                                                            .targetAccidentMaxCompensationDay
                                                            ? _vm.policydetail
                                                                .targetAccidentMaxCompensationDay
                                                            : "-"
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.policydetail.productClassCode ==
                                        "DBC"
                                          ? _c(
                                              "el-col",
                                              {
                                                attrs: {
                                                  xs: 12,
                                                  sm: 12,
                                                  md: 8,
                                                  lg: 8,
                                                  xl: 6,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "card_item" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card_item_label",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "最大赔付金额(元)："
                                                        ),
                                                      ]
                                                    ),
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.policydetail
                                                            .targetMaxCompensationAmount
                                                            ? _vm.policydetail.targetMaxCompensationAmount.toFixed(
                                                                2
                                                              )
                                                            : "-"
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "el-col",
                                          {
                                            attrs: {
                                              xs: 12,
                                              sm: 12,
                                              md: 8,
                                              lg: 8,
                                              xl: 6,
                                            },
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "服务保障期限(月)：",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.policydetail
                                                        .productTimeLimit || "-"
                                                    ) +
                                                    " "
                                                ),
                                                _vm.policydetail
                                                  .productTimeLimit
                                                  ? _c("span", [_vm._v("个月")])
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          {
                                            attrs: {
                                              xs: 12,
                                              sm: 12,
                                              md: 8,
                                              lg: 8,
                                              xl: 6,
                                            },
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "服务生效起期：",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.policydetail.startTime
                                                        ? _vm.policydetail.startTime.slice(
                                                            0,
                                                            10
                                                          )
                                                        : "-"
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          {
                                            attrs: {
                                              xs: 12,
                                              sm: 12,
                                              md: 8,
                                              lg: 8,
                                              xl: 6,
                                            },
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "服务生效止期：",
                                                },
                                              },
                                              [
                                                _vm.policydetail &&
                                                _vm.policydetail
                                                  .productClassCode == "YB"
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.policydetail &&
                                                            _vm.policydetail
                                                              .targetGuaranteeRange ==
                                                              "双擎动力蓄电池"
                                                            ? "终身"
                                                            : _vm.policydetail
                                                                .endTime
                                                            ? _vm.policydetail.endTime.slice(
                                                                0,
                                                                10
                                                              )
                                                            : "-"
                                                        )
                                                      ),
                                                    ])
                                                  : _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.policydetail
                                                            .endTime
                                                            ? _vm.policydetail.endTime.slice(
                                                                0,
                                                                10
                                                              )
                                                            : "-"
                                                        )
                                                      ),
                                                    ]),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          {
                                            attrs: {
                                              xs: 12,
                                              sm: 12,
                                              md: 8,
                                              lg: 8,
                                              xl: 6,
                                            },
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: { label: "销售方式：" },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.policydetail
                                                        .salesMethodDisplay ||
                                                        "-"
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          {
                                            attrs: {
                                              xs: 12,
                                              sm: 12,
                                              md: 8,
                                              lg: 8,
                                              xl: 6,
                                            },
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "服务销售价(元)：",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.policydetail
                                                        .productRetailPrice
                                                        ? parseFloat(
                                                            _vm.policydetail
                                                              .productRetailPrice
                                                          ).toFixed(2)
                                                        : 0
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "div",
                                      [
                                        _c(
                                          "el-row",
                                          {
                                            staticClass: "flexWarp",
                                            attrs: { gutter: 0 },
                                          },
                                          [
                                            _c(
                                              "el-col",
                                              {
                                                attrs: {
                                                  xs: 12,
                                                  sm: 12,
                                                  md: 8,
                                                  lg: 8,
                                                  xl: 6,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "card_item" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card_item_label",
                                                      },
                                                      [_vm._v("服务名称：")]
                                                    ),
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.policydetail
                                                            .productName
                                                            ? _vm.policydetail
                                                                .productName
                                                            : "-"
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "el-col",
                                              {
                                                attrs: {
                                                  xs: 12,
                                                  sm: 12,
                                                  md: 8,
                                                  lg: 8,
                                                  xl: 6,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "card_item" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card_item_label",
                                                      },
                                                      [_vm._v("销售方式：")]
                                                    ),
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.policydetail
                                                            .salesMethodDisplay
                                                            ? _vm.policydetail
                                                                .salesMethodDisplay
                                                            : "-"
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "el-col",
                                              {
                                                attrs: {
                                                  xs: 12,
                                                  sm: 12,
                                                  md: 8,
                                                  lg: 8,
                                                  xl: 6,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "card_item" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card_item_label",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "服务销售价(元)："
                                                        ),
                                                      ]
                                                    ),
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.policydetail
                                                            .targetSalesPrice
                                                            ? _vm.policydetail.targetSalesPrice.toFixed(
                                                                2
                                                              )
                                                            : "-"
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 24 } },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "card_item" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card_item_label",
                                                        staticStyle: {
                                                          color: "#333",
                                                          "font-size": "14px",
                                                          "text-align": "right",
                                                          "font-weight": "600",
                                                          "max-width": "100px",
                                                        },
                                                      },
                                                      [_vm._v("三保方案")]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "el-col",
                                              {
                                                attrs: {
                                                  xs: 12,
                                                  sm: 12,
                                                  md: 8,
                                                  lg: 8,
                                                  xl: 6,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "card_item" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card_item_label",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "原厂质保公里数(万)："
                                                        ),
                                                      ]
                                                    ),
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.policydetail
                                                            .targetOriginalWarrantyMileage
                                                            ? _vm.policydetail
                                                                .targetOriginalWarrantyMileage
                                                            : "-"
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "el-col",
                                              {
                                                attrs: {
                                                  xs: 12,
                                                  sm: 12,
                                                  md: 8,
                                                  lg: 8,
                                                  xl: 6,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "card_item" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card_item_label",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "原厂质保期限(年)："
                                                        ),
                                                      ]
                                                    ),
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.policydetail
                                                            .originalWarrantyPeriod
                                                            ? _vm.policydetail
                                                                .originalWarrantyPeriod
                                                            : "-"
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "el-col",
                                              {
                                                attrs: {
                                                  xs: 12,
                                                  sm: 12,
                                                  md: 8,
                                                  lg: 8,
                                                  xl: 6,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "card_item" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card_item_label",
                                                      },
                                                      [_vm._v("原厂保修起期：")]
                                                    ),
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.policydetail
                                                            .originalStartTime
                                                            ? _vm.policydetail.originalStartTime.slice(
                                                                0,
                                                                10
                                                              ) + " 0时"
                                                            : "-"
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "el-col",
                                              {
                                                attrs: {
                                                  xs: 12,
                                                  sm: 12,
                                                  md: 8,
                                                  lg: 8,
                                                  xl: 6,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "card_item" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card_item_label",
                                                      },
                                                      [_vm._v("原厂保修止期：")]
                                                    ),
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.policydetail
                                                            .originalEndTime
                                                            ? _vm.policydetail.originalEndTime.slice(
                                                                0,
                                                                10
                                                              ) + " 24时"
                                                            : "-"
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 24 } },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "card_item" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card_item_label",
                                                        staticStyle: {
                                                          color: "#333",
                                                          "font-size": "14px",
                                                          "text-align": "right",
                                                          "font-weight": "600",
                                                          "max-width": "100px",
                                                        },
                                                      },
                                                      [_vm._v("延保方案")]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "el-col",
                                              {
                                                attrs: {
                                                  xs: 12,
                                                  sm: 12,
                                                  md: 8,
                                                  lg: 8,
                                                  xl: 6,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "card_item" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card_item_label",
                                                      },
                                                      [_vm._v("延保保障范围：")]
                                                    ),
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.policydetail
                                                            .targetGuaranteeRange
                                                            ? _vm.policydetail
                                                                .targetGuaranteeRange
                                                            : "-"
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "el-col",
                                              {
                                                attrs: {
                                                  xs: 12,
                                                  sm: 12,
                                                  md: 8,
                                                  lg: 8,
                                                  xl: 6,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "card_item" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card_item_label",
                                                      },
                                                      [_vm._v("延保方案：")]
                                                    ),
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.policydetail
                                                            .ternScheme
                                                            ? _vm.policydetail
                                                                .ternScheme
                                                            : "-"
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "el-col",
                                              {
                                                attrs: {
                                                  xs: 12,
                                                  sm: 12,
                                                  md: 8,
                                                  lg: 8,
                                                  xl: 6,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "card_item" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card_item_label",
                                                      },
                                                      [_vm._v("延保起期：")]
                                                    ),
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.policydetail
                                                            .startTime
                                                            ? _vm.policydetail.startTime.slice(
                                                                0,
                                                                10
                                                              ) + " 0时"
                                                            : "-"
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "el-col",
                                              {
                                                attrs: {
                                                  xs: 12,
                                                  sm: 12,
                                                  md: 8,
                                                  lg: 8,
                                                  xl: 6,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "card_item" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card_item_label",
                                                      },
                                                      [_vm._v("延保止期：")]
                                                    ),
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.policydetail
                                                            .endTime
                                                            ? _vm.policydetail.endTime.slice(
                                                                0,
                                                                10
                                                              ) + " 24时"
                                                            : "-"
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "el-col",
                                              {
                                                attrs: {
                                                  xs: 12,
                                                  sm: 12,
                                                  md: 8,
                                                  lg: 8,
                                                  xl: 6,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "card_item" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card_item_label",
                                                      },
                                                      [_vm._v("已行驶里程：")]
                                                    ),
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.policydetail
                                                            .targetMileage
                                                            ? _vm.policydetail
                                                                .targetMileage
                                                            : "-"
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "el-col",
                                              {
                                                attrs: {
                                                  xs: 12,
                                                  sm: 12,
                                                  md: 8,
                                                  lg: 8,
                                                  xl: 6,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "card_item" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card_item_label",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "延长公里数(万)："
                                                        ),
                                                      ]
                                                    ),
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.policydetail
                                                            .targetExtendMileage
                                                            ? _vm.policydetail
                                                                .targetExtendMileage
                                                            : "-"
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 24 } },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "card_item" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card_item_label",
                                                        staticStyle: {
                                                          color: "#333",
                                                          "font-size": "14px",
                                                          "text-align": "right",
                                                          "font-weight": "600",
                                                          "max-width": "100px",
                                                        },
                                                      },
                                                      [_vm._v("代步车方案")]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "el-col",
                                              {
                                                attrs: {
                                                  xs: 12,
                                                  sm: 12,
                                                  md: 8,
                                                  lg: 8,
                                                  xl: 6,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "card_item" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card_item_label",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "代步车年限(年)："
                                                        ),
                                                      ]
                                                    ),
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.policydetail
                                                            .targetScooterYearLimit ||
                                                            _vm.policydetail
                                                              .targetScooterYearLimit ===
                                                              0
                                                            ? _vm.policydetail
                                                                .targetScooterYearLimit
                                                            : "-"
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "el-col",
                                              {
                                                attrs: {
                                                  xs: 12,
                                                  sm: 12,
                                                  md: 8,
                                                  lg: 8,
                                                  xl: 6,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "card_item" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card_item_label",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "代步车服务起期："
                                                        ),
                                                      ]
                                                    ),
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.policydetail
                                                            .dbcStartTime
                                                            ? _vm.policydetail.dbcStartTime.slice(
                                                                0,
                                                                10
                                                              ) + " 0时"
                                                            : "-"
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "el-col",
                                              {
                                                attrs: {
                                                  xs: 12,
                                                  sm: 12,
                                                  md: 8,
                                                  lg: 8,
                                                  xl: 6,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "card_item" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card_item_label",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "代步车服务止期："
                                                        ),
                                                      ]
                                                    ),
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.policydetail
                                                            .dbcEndTime
                                                            ? _vm.policydetail.dbcEndTime.slice(
                                                                0,
                                                                10
                                                              ) + " 24时"
                                                            : "-"
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 24 } },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "card_item" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card_item_label",
                                                        staticStyle: {
                                                          color: "#333",
                                                          "font-size": "14px",
                                                          "text-align": "right",
                                                          "font-weight": "600",
                                                          "max-width": "100px",
                                                        },
                                                      },
                                                      [_vm._v("车险置换方案")]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "el-col",
                                              {
                                                attrs: {
                                                  xs: 12,
                                                  sm: 12,
                                                  md: 8,
                                                  lg: 8,
                                                  xl: 6,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "card_item" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card_item_label",
                                                      },
                                                      [_vm._v("补偿限额(万)：")]
                                                    ),
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.policydetail
                                                            .limitCompensation
                                                            ? _vm.policydetail
                                                                .limitCompensation
                                                            : "-"
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "el-col",
                                              {
                                                attrs: {
                                                  xs: 12,
                                                  sm: 12,
                                                  md: 8,
                                                  lg: 8,
                                                  xl: 6,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "card_item" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card_item_label",
                                                      },
                                                      [_vm._v("置换年限(年)：")]
                                                    ),
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.policydetail
                                                            .targetScooterYearLimit ||
                                                            _vm.policydetail
                                                              .targetScooterYearLimit ===
                                                              0
                                                            ? _vm.policydetail
                                                                .targetScooterYearLimit
                                                            : "-"
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "el-col",
                                              {
                                                attrs: {
                                                  xs: 12,
                                                  sm: 12,
                                                  md: 8,
                                                  lg: 8,
                                                  xl: 6,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "card_item" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card_item_label",
                                                      },
                                                      [_vm._v("置换服务起期：")]
                                                    ),
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.policydetail
                                                            .zhStartTime
                                                            ? _vm.policydetail.zhStartTime.slice(
                                                                0,
                                                                10
                                                              ) + " 0时"
                                                            : "-"
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "el-col",
                                              {
                                                attrs: {
                                                  xs: 12,
                                                  sm: 12,
                                                  md: 8,
                                                  lg: 8,
                                                  xl: 6,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "card_item" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card_item_label",
                                                      },
                                                      [_vm._v("置换服务止期：")]
                                                    ),
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.policydetail
                                                            .zhEndTime
                                                            ? _vm.policydetail.zhEndTime.slice(
                                                                0,
                                                                10
                                                              ) + " 24时"
                                                            : "-"
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-collapse",
                          {
                            staticStyle: { "margin-top": "10px" },
                            model: {
                              value: _vm.activeTwo,
                              callback: function ($$v) {
                                _vm.activeTwo = $$v
                              },
                              expression: "activeTwo",
                            },
                          },
                          [
                            _c(
                              "el-collapse-item",
                              {
                                staticClass: "collapse-item-style",
                                attrs: { name: "1" },
                              },
                              [
                                _c("template", { slot: "title" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "title",
                                      staticStyle: { "line-height": "25px" },
                                    },
                                    [
                                      _c("FormLabel", {
                                        attrs: { "form-label": "车辆信息" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 12,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 6,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "车牌号：",
                                              "label-width": "160px",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  ref: "plateNo",
                                                  attrs: {
                                                    minlength: "7",
                                                    maxlength: "8",
                                                    disabled: _vm.claimDisabled,
                                                    clearable: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.policydetail
                                                        .targetPlateNo,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.policydetail,
                                                        "targetPlateNo",
                                                        typeof $$v === "string"
                                                          ? $$v.trim()
                                                          : $$v
                                                      )
                                                    },
                                                    expression:
                                                      "policydetail.targetPlateNo",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 12,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 6,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "车架号：",
                                              "label-width": "160px",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.policydetail
                                                        .targetVin || "-"
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 12,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 6,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "厂家品牌/车系：",
                                              "label-width": "160px",
                                            },
                                          },
                                          [
                                            _vm.policydetail.productClassCode ==
                                            "BZHG"
                                              ? _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.policydetail
                                                          .targetVhcBrandName ||
                                                          "-"
                                                      ) +
                                                      "/" +
                                                      _vm._s(
                                                        _vm.policydetail
                                                          .targetVhcSeriesName ||
                                                          "-"
                                                      ) +
                                                      "/" +
                                                      _vm._s(
                                                        _vm.policydetail
                                                          .targetVhcModelName ||
                                                          "-"
                                                      ) +
                                                      " "
                                                  ),
                                                ])
                                              : _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.policydetail
                                                          .targetVhcBrandName ||
                                                          "-"
                                                      ) +
                                                      "/" +
                                                      _vm._s(
                                                        _vm.policydetail
                                                          .targetVhcSeriesName
                                                      ) +
                                                      " "
                                                  ),
                                                  _vm.policydetail
                                                    .targetVhcSeriesChineseName
                                                    ? _c("span", [
                                                        _vm._v(
                                                          "(" +
                                                            _vm._s(
                                                              _vm.policydetail
                                                                .targetVhcSeriesChineseName ||
                                                                "-"
                                                            ) +
                                                            ")"
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._l(_vm.attributeList, function (item) {
                                      return _c(
                                        "el-col",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.policydetail
                                                  .productClassCode != "DBC" &&
                                                item.attributeName ==
                                                  "购车发票日期",
                                              expression:
                                                "policydetail.productClassCode != 'DBC' && item.attributeName == '购车发票日期'",
                                            },
                                          ],
                                          key: item.attributeName,
                                          attrs: {
                                            xs: 12,
                                            sm: 12,
                                            md: 8,
                                            lg: 8,
                                            xl: 6,
                                          },
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label:
                                                  item.attributeName + "：",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                  },
                                                },
                                                [
                                                  item.attributeName ==
                                                  "使用性质"
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.policydetail
                                                              .targetUseAttributeDisplay ||
                                                              "-"
                                                          )
                                                        ),
                                                      ])
                                                    : item.attributeName ==
                                                      "能源类型"
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.policydetail
                                                              .targetFuelTypeDisplay ||
                                                              "-"
                                                          )
                                                        ),
                                                      ])
                                                    : item.attributeName ==
                                                      "车辆类型"
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.policydetail
                                                              .targetVehicleTypeDisplay ||
                                                              "-"
                                                          )
                                                        ),
                                                      ])
                                                    : item.attributeName ==
                                                      "购车发票日期"
                                                    ? _c("span", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              (item.attributeValue &&
                                                                item.attributeValue.split(
                                                                  " "
                                                                )[0]) ||
                                                                "-"
                                                            )
                                                        ),
                                                      ])
                                                    : item.attributeName ==
                                                      "新车销售日期"
                                                    ? _c("span", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              (item.attributeValue &&
                                                                item.attributeValue.split(
                                                                  " "
                                                                )[0]) ||
                                                                "-"
                                                            )
                                                        ),
                                                      ])
                                                    : _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.attributeValue ||
                                                              "-"
                                                          )
                                                        ),
                                                      ]),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    }),
                                    _vm.policydetail.productClassCode == "TCWY"
                                      ? _c(
                                          "el-col",
                                          {
                                            attrs: {
                                              xs: 12,
                                              sm: 12,
                                              md: 8,
                                              lg: 8,
                                              xl: 6,
                                            },
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: { label: "使用性质：" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "flex",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.policydetail
                                                            .targetUseAttributeDisplay ||
                                                            "-"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-collapse",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  (_vm.claimInsureFormlist &&
                                    _vm.claimInsureFormlist.length != 0) ||
                                  (_vm.claimTargetFormlist &&
                                    _vm.claimTargetFormlist.length != 0),
                                expression:
                                  "(claimInsureFormlist&&claimInsureFormlist.length!=0)||(claimTargetFormlist&&claimTargetFormlist.length!=0)",
                              },
                            ],
                            staticStyle: { "margin-top": "10px" },
                            model: {
                              value: _vm.activeThree,
                              callback: function ($$v) {
                                _vm.activeThree = $$v
                              },
                              expression: "activeThree",
                            },
                          },
                          [
                            _c(
                              "el-collapse-item",
                              {
                                staticClass: "collapse-item-style",
                                attrs: { name: "1" },
                              },
                              [
                                _c("template", { slot: "title" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "title",
                                      staticStyle: { "line-height": "25px" },
                                    },
                                    [
                                      _c("FormLabel", {
                                        attrs: { "form-label": "理赔信息" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c(
                                  "el-row",
                                  [
                                    _vm._l(
                                      _vm.claimTargetFormlist,
                                      function (item) {
                                        return _c(
                                          "el-col",
                                          {
                                            key: item.attributeCode,
                                            attrs: {
                                              xs: 12,
                                              sm: 12,
                                              md: 8,
                                              lg: 8,
                                              xl: 6,
                                            },
                                          },
                                          [
                                            item.inputType == "TEXT"
                                              ? _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label:
                                                        item.attributeName +
                                                        ":",
                                                      "label-width": "160px",
                                                      prop: item.attributeCode,
                                                      rules:
                                                        (_vm.policydetail
                                                          .productClassCode ==
                                                          "XXAX" ||
                                                          _vm.policydetail
                                                            .productClassCode ==
                                                            "DXWY") &&
                                                        item.attributeCode !=
                                                          "applyAmount"
                                                          ? [
                                                              {
                                                                min:
                                                                  item.attributeCode ==
                                                                  "repairOrderNo"
                                                                    ? 12
                                                                    : null,
                                                                max:
                                                                  item.attributeCode ==
                                                                  "repairOrderNo"
                                                                    ? 12
                                                                    : null,
                                                                required:
                                                                  item.requiredState ||
                                                                  (item.attributeCode ==
                                                                    "insuranceReportNo" &&
                                                                    _vm
                                                                      .policydetail[
                                                                      "claimCondition"
                                                                    ] !=
                                                                      "CC01"),
                                                                message:
                                                                  item.attributeCode ==
                                                                  "repairOrderNo"
                                                                    ? "请填写完整TACT维修工单号"
                                                                    : "请输入" +
                                                                      item.attributeName,
                                                                trigger: "blur",
                                                              },
                                                            ]
                                                          : [
                                                              {
                                                                required:
                                                                  item.requiredState,
                                                                message:
                                                                  "请输入" +
                                                                  item.attributeName,
                                                                trigger: "blur",
                                                              },
                                                            ],
                                                    },
                                                  },
                                                  [
                                                    item.attributeCode ==
                                                      "informantMobile" &&
                                                    (_vm.policydetail
                                                      .productClassCode ==
                                                      "DBC" ||
                                                      _vm.policydetail
                                                        .productClassCode ==
                                                        "YB")
                                                      ? _c(
                                                          "el-tooltip",
                                                          {
                                                            attrs: {
                                                              placement:
                                                                "bottom",
                                                              effect: "light",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  "font-size":
                                                                    "10px",
                                                                },
                                                                attrs: {
                                                                  slot: "content",
                                                                },
                                                                slot: "content",
                                                              },
                                                              [
                                                                _c("div", [
                                                                  _vm._v(
                                                                    "请填写经销商门店理赔专员电话"
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                            _c("el-input", {
                                                              directives: [
                                                                {
                                                                  name: "show",
                                                                  rawName:
                                                                    "v-show",
                                                                  value:
                                                                    item.dateType ==
                                                                    "INTEGER",
                                                                  expression:
                                                                    "item.dateType=='INTEGER'",
                                                                },
                                                              ],
                                                              staticStyle: {
                                                                width: "100%",
                                                              },
                                                              attrs: {
                                                                placeholder:
                                                                  "请输入" +
                                                                  item.attributeName,
                                                                disabled:
                                                                  item.readonly ||
                                                                  _vm.claimDisabled,
                                                                maxlength:
                                                                  item.maxValue &&
                                                                  item.maxValue.toString()
                                                                    .length,
                                                                clearable: "",
                                                              },
                                                              on: {
                                                                blur: function (
                                                                  $event
                                                                ) {
                                                                  return _vm.DECIMALS_maxValueInput(
                                                                    item
                                                                  )
                                                                },
                                                                clear:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.inputClear(
                                                                      item
                                                                    )
                                                                  },
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.limitInput3(
                                                                      $event,
                                                                      item.attributeCode
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .policydetail[
                                                                    item
                                                                      .attributeCode
                                                                  ],
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.policydetail,
                                                                      item.attributeCode,
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "policydetail[item.attributeCode]",
                                                              },
                                                            }),
                                                            _c("el-input", {
                                                              directives: [
                                                                {
                                                                  name: "show",
                                                                  rawName:
                                                                    "v-show",
                                                                  value:
                                                                    item.dateType ==
                                                                    "DECIMALS",
                                                                  expression:
                                                                    "item.dateType=='DECIMALS'",
                                                                },
                                                              ],
                                                              staticStyle: {
                                                                width: "100%",
                                                              },
                                                              attrs: {
                                                                placeholder:
                                                                  "请输入" +
                                                                  item.attributeName,
                                                                disabled:
                                                                  item.readonly ||
                                                                  _vm.claimDisabled,
                                                                maxlength:
                                                                  item.maxValue &&
                                                                  item.maxValue.toString()
                                                                    .length,
                                                                clearable: "",
                                                              },
                                                              on: {
                                                                blur: function (
                                                                  $event
                                                                ) {
                                                                  return _vm.DECIMALS_maxValueInput(
                                                                    item
                                                                  )
                                                                },
                                                                clear:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.inputClear(
                                                                      item
                                                                    )
                                                                  },
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.limitInput2(
                                                                      $event,
                                                                      item.attributeCode
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .policydetail[
                                                                    item
                                                                      .attributeCode
                                                                  ],
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.policydetail,
                                                                      item.attributeCode,
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "policydetail[item.attributeCode]",
                                                              },
                                                            }),
                                                            _c("el-input", {
                                                              directives: [
                                                                {
                                                                  name: "show",
                                                                  rawName:
                                                                    "v-show",
                                                                  value:
                                                                    item.dateType ==
                                                                    "CHARACTER",
                                                                  expression:
                                                                    "item.dateType=='CHARACTER'",
                                                                },
                                                              ],
                                                              staticStyle: {
                                                                width: "100%",
                                                              },
                                                              attrs: {
                                                                placeholder:
                                                                  "请输入" +
                                                                  item.attributeName,
                                                                disabled:
                                                                  item.readonly ||
                                                                  _vm.claimDisabled,
                                                                maxlength:
                                                                  item.maxValue &&
                                                                  parseInt(
                                                                    item.maxValue
                                                                  ),
                                                                clearable: "",
                                                              },
                                                              on: {
                                                                blur: function (
                                                                  $event
                                                                ) {
                                                                  return _vm.DECIMALS_maxValueInput(
                                                                    item
                                                                  )
                                                                },
                                                                clear:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.inputClear(
                                                                      item
                                                                    )
                                                                  },
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.checkValue(
                                                                      "policydetail",
                                                                      $event,
                                                                      item.attributeCode
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .policydetail[
                                                                    item
                                                                      .attributeCode
                                                                  ],
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.policydetail,
                                                                      item.attributeCode,
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "policydetail[item.attributeCode]",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        )
                                                      : _c(
                                                          "span",
                                                          [
                                                            _c("el-input", {
                                                              directives: [
                                                                {
                                                                  name: "show",
                                                                  rawName:
                                                                    "v-show",
                                                                  value:
                                                                    item.dateType ==
                                                                    "INTEGER",
                                                                  expression:
                                                                    "item.dateType=='INTEGER'",
                                                                },
                                                              ],
                                                              staticStyle: {
                                                                width: "100%",
                                                              },
                                                              attrs: {
                                                                placeholder:
                                                                  "请输入" +
                                                                  item.attributeName,
                                                                disabled:
                                                                  item.readonly ||
                                                                  _vm.claimDisabled ||
                                                                  (item.attributeCode ==
                                                                    "insuranceReportNo" &&
                                                                    _vm
                                                                      .policydetail[
                                                                      "claimCondition"
                                                                    ] ==
                                                                      "CC01"),
                                                                maxlength:
                                                                  item.maxValue &&
                                                                  item.maxValue.toString()
                                                                    .length,
                                                                clearable: "",
                                                              },
                                                              on: {
                                                                blur: function (
                                                                  $event
                                                                ) {
                                                                  return _vm.DECIMALS_maxValueInput(
                                                                    item
                                                                  )
                                                                },
                                                                clear:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.inputClear(
                                                                      item
                                                                    )
                                                                  },
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.limitInput3(
                                                                      $event,
                                                                      item.attributeCode
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .policydetail[
                                                                    item
                                                                      .attributeCode
                                                                  ],
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.policydetail,
                                                                      item.attributeCode,
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "policydetail[item.attributeCode]",
                                                              },
                                                            }),
                                                            _c("el-input", {
                                                              directives: [
                                                                {
                                                                  name: "show",
                                                                  rawName:
                                                                    "v-show",
                                                                  value:
                                                                    item.dateType ==
                                                                    "DECIMALS",
                                                                  expression:
                                                                    "item.dateType=='DECIMALS'",
                                                                },
                                                              ],
                                                              staticStyle: {
                                                                width: "100%",
                                                              },
                                                              attrs: {
                                                                placeholder:
                                                                  "请输入" +
                                                                  item.attributeName,
                                                                disabled:
                                                                  item.readonly ||
                                                                  _vm.claimDisabled ||
                                                                  (item.attributeCode ==
                                                                    "insuranceReportNo" &&
                                                                    _vm
                                                                      .policydetail[
                                                                      "claimCondition"
                                                                    ] ==
                                                                      "CC01"),
                                                                maxlength:
                                                                  item.maxValue &&
                                                                  item.maxValue.toString()
                                                                    .length,
                                                                clearable: "",
                                                              },
                                                              on: {
                                                                blur: function (
                                                                  $event
                                                                ) {
                                                                  return _vm.DECIMALS_maxValueInput(
                                                                    item
                                                                  )
                                                                },
                                                                clear:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.inputClear(
                                                                      item
                                                                    )
                                                                  },
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.limitInput2(
                                                                      $event,
                                                                      item.attributeCode
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .policydetail[
                                                                    item
                                                                      .attributeCode
                                                                  ],
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.policydetail,
                                                                      item.attributeCode,
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "policydetail[item.attributeCode]",
                                                              },
                                                            }),
                                                            _c("el-input", {
                                                              directives: [
                                                                {
                                                                  name: "show",
                                                                  rawName:
                                                                    "v-show",
                                                                  value:
                                                                    item.dateType ==
                                                                    "CHARACTER",
                                                                  expression:
                                                                    "item.dateType=='CHARACTER'",
                                                                },
                                                              ],
                                                              staticStyle: {
                                                                width: "100%",
                                                              },
                                                              attrs: {
                                                                placeholder:
                                                                  "请输入" +
                                                                  item.attributeName,
                                                                disabled:
                                                                  item.readonly ||
                                                                  _vm.claimDisabled ||
                                                                  (item.attributeCode ==
                                                                    "insuranceReportNo" &&
                                                                    _vm
                                                                      .policydetail[
                                                                      "claimCondition"
                                                                    ] ==
                                                                      "CC01"),
                                                                maxlength:
                                                                  item.maxValue &&
                                                                  parseInt(
                                                                    item.maxValue
                                                                  ),
                                                                clearable: "",
                                                              },
                                                              on: {
                                                                blur: function (
                                                                  $event
                                                                ) {
                                                                  return _vm.DECIMALS_maxValueInput(
                                                                    item
                                                                  )
                                                                },
                                                                clear:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.inputClear(
                                                                      item
                                                                    )
                                                                  },
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.checkValue(
                                                                      "policydetail",
                                                                      $event,
                                                                      item.attributeCode
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .policydetail[
                                                                    item
                                                                      .attributeCode
                                                                  ],
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.policydetail,
                                                                      item.attributeCode,
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "policydetail[item.attributeCode]",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            item.inputType == "TEXTAREA"
                                              ? _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label:
                                                        item.attributeName +
                                                        ":",
                                                      "label-width": "160px",
                                                      prop: item.attributeCode,
                                                      rules: [
                                                        {
                                                          required:
                                                            item.requiredState,
                                                          message:
                                                            "请输入" +
                                                            item.attributeName,
                                                          trigger: "blur",
                                                        },
                                                      ],
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticStyle: {
                                                        width: "100%",
                                                      },
                                                      attrs: {
                                                        disabled:
                                                          item.readonly ||
                                                          _vm.claimDisabled,
                                                        type: "textarea",
                                                        maxlength:
                                                          item.maxValue,
                                                        placeholder:
                                                          "请输入" +
                                                          item.attributeName,
                                                        "show-word-limit": "",
                                                        clearable: "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.policydetail[
                                                            item.attributeCode
                                                          ],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.policydetail,
                                                            item.attributeCode,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "policydetail[item.attributeCode]",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            item.inputType == "SELECT"
                                              ? _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label:
                                                        item.attributeName +
                                                        ":",
                                                      "label-width": "160px",
                                                      prop: item.attributeCode,
                                                      rules: [
                                                        {
                                                          required:
                                                            item.requiredState,
                                                          message:
                                                            "请选择" +
                                                            item.attributeName,
                                                          trigger: "change",
                                                        },
                                                      ],
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        staticStyle: {
                                                          width: "100%",
                                                        },
                                                        attrs: {
                                                          disabled:
                                                            item.readonly ||
                                                            _vm.claimDisabled,
                                                          size: "mini",
                                                          clearable: "",
                                                          placeholder:
                                                            "请选择" +
                                                            item.attributeName,
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.changeAttribute(
                                                              $event,
                                                              item.attributeCode
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.policydetail[
                                                              item.attributeCode
                                                            ],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.policydetail,
                                                              item.attributeCode,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "policydetail[item.attributeCode]",
                                                        },
                                                      },
                                                      _vm._l(
                                                        item.options,
                                                        function (citem) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: citem.itemId,
                                                              attrs: {
                                                                label:
                                                                  citem.itemName,
                                                                value:
                                                                  citem.itemCode,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            item.inputType == "MULTI_SELECT"
                                              ? _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label:
                                                        item.attributeName +
                                                        ":",
                                                      "label-width": "160px",
                                                      prop: item.attributeCode,
                                                      rules: [
                                                        {
                                                          required:
                                                            item.requiredState,
                                                          message:
                                                            "请选择" +
                                                            item.attributeName,
                                                          trigger: "change",
                                                        },
                                                      ],
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        staticStyle: {
                                                          width: "100%",
                                                        },
                                                        attrs: {
                                                          disabled:
                                                            item.readonly ||
                                                            _vm.claimDisabled,
                                                          multiple: "",
                                                          size: "mini",
                                                          clearable: "",
                                                          placeholder:
                                                            "请选择" +
                                                            item.attributeName,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.policydetail[
                                                              item.attributeCode
                                                            ],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.policydetail,
                                                              item.attributeCode,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "policydetail[item.attributeCode]",
                                                        },
                                                      },
                                                      _vm._l(
                                                        item.options,
                                                        function (citem) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: citem.itemId,
                                                              attrs: {
                                                                label:
                                                                  citem.itemName,
                                                                value:
                                                                  citem.itemCode,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            item.inputType == "DATE"
                                              ? _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label:
                                                        item.attributeName +
                                                        ":",
                                                      "label-width": "160px",
                                                      prop: item.attributeCode,
                                                      rules: [
                                                        {
                                                          required:
                                                            item.requiredState,
                                                          message:
                                                            "请选择" +
                                                            item.attributeName,
                                                          trigger: "change",
                                                        },
                                                      ],
                                                    },
                                                  },
                                                  [
                                                    _c("el-date-picker", {
                                                      staticStyle: {
                                                        width: "100%",
                                                      },
                                                      attrs: {
                                                        disabled:
                                                          item.readonly ||
                                                          _vm.claimDisabled,
                                                        format:
                                                          "yyyy-MM-dd HH:mm:ss",
                                                        "value-format":
                                                          "yyyy-MM-dd HH:mm:ss",
                                                        type: "datetime",
                                                        placeholder:
                                                          "请选择" +
                                                          item.attributeName,
                                                        clearable: "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.policydetail[
                                                            item.attributeCode
                                                          ],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.policydetail,
                                                            item.attributeCode,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "policydetail[item.attributeCode]",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            item.inputType == "SELECT_BUTTON"
                                              ? _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label:
                                                        item.attributeName +
                                                        ":",
                                                      "label-width": "160px",
                                                      prop: item.attributeCode,
                                                      rules: [
                                                        {
                                                          required:
                                                            item.requiredState,
                                                          message:
                                                            "请选择" +
                                                            item.attributeName,
                                                          trigger: "change",
                                                        },
                                                      ],
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-radio-group",
                                                      {
                                                        staticStyle: {
                                                          width: "100%",
                                                        },
                                                        attrs: {
                                                          disabled:
                                                            item.readonly ||
                                                            _vm.claimDisabled,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.policydetail[
                                                              item.attributeCode
                                                            ],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.policydetail,
                                                              item.attributeCode,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "policydetail[item.attributeCode]",
                                                        },
                                                      },
                                                      _vm._l(
                                                        item.options,
                                                        function (
                                                          citem,
                                                          index
                                                        ) {
                                                          return _c(
                                                            "el-radio",
                                                            {
                                                              key: index,
                                                              attrs: {
                                                                label:
                                                                  citem.itemCode,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  citem.itemName
                                                                )
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    _vm._l(
                                      _vm.claimInsureFormlist,
                                      function (item) {
                                        return _c(
                                          "el-col",
                                          {
                                            key: item.attributeCode,
                                            attrs: {
                                              xs: 12,
                                              sm: 12,
                                              md: 8,
                                              lg: 8,
                                              xl: 6,
                                            },
                                          },
                                          [
                                            item.inputType == "TEXT"
                                              ? _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label:
                                                        item.attributeName +
                                                        ":",
                                                      "label-width": "160px",
                                                      prop: item.attributeCode,
                                                      rules:
                                                        (_vm.policydetail
                                                          .productClassCode ==
                                                          "XXAX" ||
                                                          _vm.policydetail
                                                            .productClassCode ==
                                                            "DXWY") &&
                                                        item.attributeCode !=
                                                          "applyAmount"
                                                          ? [
                                                              {
                                                                min:
                                                                  item.attributeCode ==
                                                                  "repairOrderNo"
                                                                    ? 12
                                                                    : null,
                                                                max:
                                                                  item.attributeCode ==
                                                                  "repairOrderNo"
                                                                    ? 12
                                                                    : null,
                                                                required:
                                                                  item.requiredState ||
                                                                  (item.attributeCode ==
                                                                    "insuranceReportNo" &&
                                                                    _vm
                                                                      .policydetail[
                                                                      "claimCondition"
                                                                    ] !=
                                                                      "CC01"),
                                                                message:
                                                                  item.attributeCode ==
                                                                  "repairOrderNo"
                                                                    ? "请填写完整TACT维修工单号"
                                                                    : "请输入" +
                                                                      item.attributeName,
                                                                trigger: "blur",
                                                              },
                                                            ]
                                                          : [
                                                              {
                                                                required:
                                                                  item.requiredState,
                                                                message:
                                                                  "请输入" +
                                                                  item.attributeName,
                                                                trigger: "blur",
                                                              },
                                                            ],
                                                    },
                                                  },
                                                  [
                                                    item.attributeCode ==
                                                      "informantMobile" &&
                                                    (_vm.policydetail
                                                      .productClassCode ==
                                                      "DBC" ||
                                                      _vm.policydetail
                                                        .productClassCode ==
                                                        "YB")
                                                      ? _c(
                                                          "el-tooltip",
                                                          {
                                                            attrs: {
                                                              placement:
                                                                "bottom",
                                                              effect: "light",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  "font-size":
                                                                    "10px",
                                                                },
                                                                attrs: {
                                                                  slot: "content",
                                                                },
                                                                slot: "content",
                                                              },
                                                              [
                                                                _c("div", [
                                                                  _vm._v(
                                                                    "请填写经销商门店理赔专员电话"
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                            _c("el-input", {
                                                              directives: [
                                                                {
                                                                  name: "show",
                                                                  rawName:
                                                                    "v-show",
                                                                  value:
                                                                    item.dateType ==
                                                                    "INTEGER",
                                                                  expression:
                                                                    "item.dateType=='INTEGER'",
                                                                },
                                                              ],
                                                              staticStyle: {
                                                                width: "100%",
                                                              },
                                                              attrs: {
                                                                placeholder:
                                                                  "请输入" +
                                                                  item.attributeName,
                                                                disabled:
                                                                  item.readonly ||
                                                                  _vm.claimDisabled,
                                                                maxlength:
                                                                  item.maxValue &&
                                                                  item.maxValue.toString()
                                                                    .length,
                                                                clearable: "",
                                                              },
                                                              on: {
                                                                blur: function (
                                                                  $event
                                                                ) {
                                                                  return _vm.DECIMALS_maxValueInput(
                                                                    item
                                                                  )
                                                                },
                                                                clear:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.inputClear(
                                                                      item
                                                                    )
                                                                  },
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.limitInput3(
                                                                      $event,
                                                                      item.attributeCode
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .policydetail[
                                                                    item
                                                                      .attributeCode
                                                                  ],
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.policydetail,
                                                                      item.attributeCode,
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "policydetail[item.attributeCode]",
                                                              },
                                                            }),
                                                            _c("el-input", {
                                                              directives: [
                                                                {
                                                                  name: "show",
                                                                  rawName:
                                                                    "v-show",
                                                                  value:
                                                                    item.dateType ==
                                                                    "DECIMALS",
                                                                  expression:
                                                                    "item.dateType=='DECIMALS'",
                                                                },
                                                              ],
                                                              staticStyle: {
                                                                width: "100%",
                                                              },
                                                              attrs: {
                                                                placeholder:
                                                                  "请输入" +
                                                                  item.attributeName,
                                                                disabled:
                                                                  item.readonly ||
                                                                  _vm.claimDisabled,
                                                                maxlength:
                                                                  item.maxValue &&
                                                                  item.maxValue.toString()
                                                                    .length,
                                                                clearable: "",
                                                              },
                                                              on: {
                                                                blur: function (
                                                                  $event
                                                                ) {
                                                                  return _vm.DECIMALS_maxValueInput(
                                                                    item
                                                                  )
                                                                },
                                                                clear:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.inputClear(
                                                                      item
                                                                    )
                                                                  },
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.limitInput2(
                                                                      $event,
                                                                      item.attributeCode
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .policydetail[
                                                                    item
                                                                      .attributeCode
                                                                  ],
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.policydetail,
                                                                      item.attributeCode,
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "policydetail[item.attributeCode]",
                                                              },
                                                            }),
                                                            _c("el-input", {
                                                              directives: [
                                                                {
                                                                  name: "show",
                                                                  rawName:
                                                                    "v-show",
                                                                  value:
                                                                    item.dateType ==
                                                                    "CHARACTER",
                                                                  expression:
                                                                    "item.dateType=='CHARACTER'",
                                                                },
                                                              ],
                                                              staticStyle: {
                                                                width: "100%",
                                                              },
                                                              attrs: {
                                                                placeholder:
                                                                  "请输入" +
                                                                  item.attributeName,
                                                                disabled:
                                                                  item.readonly ||
                                                                  _vm.claimDisabled,
                                                                maxlength:
                                                                  item.maxValue &&
                                                                  parseInt(
                                                                    item.maxValue
                                                                  ),
                                                                clearable: "",
                                                              },
                                                              on: {
                                                                blur: function (
                                                                  $event
                                                                ) {
                                                                  return _vm.DECIMALS_maxValueInput(
                                                                    item
                                                                  )
                                                                },
                                                                clear:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.inputClear(
                                                                      item
                                                                    )
                                                                  },
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.checkValue(
                                                                      "policydetail",
                                                                      $event,
                                                                      item.attributeCode
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .policydetail[
                                                                    item
                                                                      .attributeCode
                                                                  ],
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.policydetail,
                                                                      item.attributeCode,
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "policydetail[item.attributeCode]",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        )
                                                      : _c(
                                                          "span",
                                                          [
                                                            _c("el-input", {
                                                              directives: [
                                                                {
                                                                  name: "show",
                                                                  rawName:
                                                                    "v-show",
                                                                  value:
                                                                    item.dateType ==
                                                                    "INTEGER",
                                                                  expression:
                                                                    "item.dateType=='INTEGER'",
                                                                },
                                                              ],
                                                              staticStyle: {
                                                                width: "100%",
                                                              },
                                                              attrs: {
                                                                placeholder:
                                                                  "请输入" +
                                                                  item.attributeName,
                                                                disabled:
                                                                  item.readonly ||
                                                                  _vm.claimDisabled ||
                                                                  (item.attributeCode ==
                                                                    "insuranceReportNo" &&
                                                                    _vm
                                                                      .policydetail[
                                                                      "claimCondition"
                                                                    ] ==
                                                                      "CC01"),
                                                                maxlength:
                                                                  item.maxValue &&
                                                                  item.maxValue.toString()
                                                                    .length,
                                                                clearable: "",
                                                              },
                                                              on: {
                                                                blur: function (
                                                                  $event
                                                                ) {
                                                                  return _vm.DECIMALS_maxValueInput(
                                                                    item
                                                                  )
                                                                },
                                                                clear:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.inputClear(
                                                                      item
                                                                    )
                                                                  },
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.limitInput3(
                                                                      $event,
                                                                      item.attributeCode
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .policydetail[
                                                                    item
                                                                      .attributeCode
                                                                  ],
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.policydetail,
                                                                      item.attributeCode,
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "policydetail[item.attributeCode]",
                                                              },
                                                            }),
                                                            _c("el-input", {
                                                              directives: [
                                                                {
                                                                  name: "show",
                                                                  rawName:
                                                                    "v-show",
                                                                  value:
                                                                    item.dateType ==
                                                                    "DECIMALS",
                                                                  expression:
                                                                    "item.dateType=='DECIMALS'",
                                                                },
                                                              ],
                                                              staticStyle: {
                                                                width: "100%",
                                                              },
                                                              attrs: {
                                                                placeholder:
                                                                  "请输入" +
                                                                  item.attributeName,
                                                                disabled:
                                                                  item.readonly ||
                                                                  _vm.claimDisabled ||
                                                                  (item.attributeCode ==
                                                                    "insuranceReportNo" &&
                                                                    _vm
                                                                      .policydetail[
                                                                      "claimCondition"
                                                                    ] ==
                                                                      "CC01"),
                                                                maxlength:
                                                                  item.maxValue &&
                                                                  item.maxValue.toString()
                                                                    .length,
                                                                clearable: "",
                                                              },
                                                              on: {
                                                                blur: function (
                                                                  $event
                                                                ) {
                                                                  return _vm.DECIMALS_maxValueInput(
                                                                    item
                                                                  )
                                                                },
                                                                clear:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.inputClear(
                                                                      item
                                                                    )
                                                                  },
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.limitInput2(
                                                                      $event,
                                                                      item.attributeCode
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .policydetail[
                                                                    item
                                                                      .attributeCode
                                                                  ],
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.policydetail,
                                                                      item.attributeCode,
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "policydetail[item.attributeCode]",
                                                              },
                                                            }),
                                                            _c("el-input", {
                                                              directives: [
                                                                {
                                                                  name: "show",
                                                                  rawName:
                                                                    "v-show",
                                                                  value:
                                                                    item.dateType ==
                                                                    "CHARACTER",
                                                                  expression:
                                                                    "item.dateType=='CHARACTER'",
                                                                },
                                                              ],
                                                              staticStyle: {
                                                                width: "100%",
                                                              },
                                                              attrs: {
                                                                placeholder:
                                                                  "请输入" +
                                                                  item.attributeName,
                                                                disabled:
                                                                  item.readonly ||
                                                                  _vm.claimDisabled ||
                                                                  (item.attributeCode ==
                                                                    "insuranceReportNo" &&
                                                                    _vm
                                                                      .policydetail[
                                                                      "claimCondition"
                                                                    ] ==
                                                                      "CC01"),
                                                                maxlength:
                                                                  item.maxValue &&
                                                                  parseInt(
                                                                    item.maxValue
                                                                  ),
                                                                clearable: "",
                                                              },
                                                              on: {
                                                                blur: function (
                                                                  $event
                                                                ) {
                                                                  return _vm.DECIMALS_maxValueInput(
                                                                    item
                                                                  )
                                                                },
                                                                clear:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.inputClear(
                                                                      item
                                                                    )
                                                                  },
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.checkValue(
                                                                      "policydetail",
                                                                      $event,
                                                                      item.attributeCode
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .policydetail[
                                                                    item
                                                                      .attributeCode
                                                                  ],
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.policydetail,
                                                                      item.attributeCode,
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "policydetail[item.attributeCode]",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            item.inputType == "TEXTAREA"
                                              ? _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label:
                                                        item.attributeName +
                                                        ":",
                                                      "label-width": "160px",
                                                      prop: item.attributeCode,
                                                      rules: [
                                                        {
                                                          required:
                                                            item.requiredState,
                                                          message:
                                                            "请输入" +
                                                            item.attributeName,
                                                          trigger: "blur",
                                                        },
                                                      ],
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticStyle: {
                                                        width: "100%",
                                                      },
                                                      attrs: {
                                                        disabled:
                                                          item.readonly ||
                                                          _vm.claimDisabled,
                                                        type: "textarea",
                                                        maxlength:
                                                          item.maxValue,
                                                        placeholder:
                                                          "请输入" +
                                                          item.attributeName,
                                                        "show-word-limit": "",
                                                        clearable: "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.policydetail[
                                                            item.attributeCode
                                                          ],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.policydetail,
                                                            item.attributeCode,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "policydetail[item.attributeCode]",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            item.inputType == "SELECT"
                                              ? _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label:
                                                        item.attributeName +
                                                        ":",
                                                      "label-width": "160px",
                                                      prop: item.attributeCode,
                                                      rules: [
                                                        {
                                                          required:
                                                            item.requiredState,
                                                          message:
                                                            "请选择" +
                                                            item.attributeName,
                                                          trigger: "change",
                                                        },
                                                      ],
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          display: "flex",
                                                          width: "100%",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "el-select",
                                                          {
                                                            staticStyle: {
                                                              width: "100%",
                                                            },
                                                            attrs: {
                                                              disabled:
                                                                item.readonly ||
                                                                _vm.claimDisabled,
                                                              filterable: "",
                                                              size: "mini",
                                                              clearable: "",
                                                              placeholder:
                                                                "请选择" +
                                                                item.attributeName,
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                return _vm.changeAttribute(
                                                                  $event,
                                                                  item.attributeCode
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .policydetail[
                                                                  item
                                                                    .attributeCode
                                                                ],
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.policydetail,
                                                                    item.attributeCode,
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "policydetail[item.attributeCode]",
                                                            },
                                                          },
                                                          _vm._l(
                                                            item.options,
                                                            function (citem) {
                                                              return _c(
                                                                "el-option",
                                                                {
                                                                  key: citem.itemId,
                                                                  attrs: {
                                                                    label:
                                                                      citem.itemName,
                                                                    value:
                                                                      citem.itemCode,
                                                                  },
                                                                }
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm.policydetail
                                                      .productClassCode == "SB"
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              display: "flex",
                                                              width: "100%",
                                                              "justify-content":
                                                                "flex-start",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  color: "red",
                                                                  "line-height":
                                                                    "20px",
                                                                  "font-size":
                                                                    "12px",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "更换理赔险种会清空已上传的非通用索赔附件"
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                )
                                              : _vm._e(),
                                            item.inputType == "MULTI_SELECT"
                                              ? _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label:
                                                        item.attributeName +
                                                        ":",
                                                      "label-width": "160px",
                                                      prop: item.attributeCode,
                                                      rules: [
                                                        {
                                                          required:
                                                            item.requiredState,
                                                          message:
                                                            "请选择" +
                                                            item.attributeName,
                                                          trigger: "change",
                                                        },
                                                      ],
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        staticStyle: {
                                                          width: "100%",
                                                        },
                                                        attrs: {
                                                          disabled:
                                                            item.readonly ||
                                                            _vm.claimDisabled,
                                                          multiple: "",
                                                          size: "mini",
                                                          clearable: "",
                                                          placeholder:
                                                            "请选择" +
                                                            item.attributeName,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.policydetail[
                                                              item.attributeCode
                                                            ],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.policydetail,
                                                              item.attributeCode,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "policydetail[item.attributeCode]",
                                                        },
                                                      },
                                                      _vm._l(
                                                        item.options,
                                                        function (citem) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: citem.itemId,
                                                              attrs: {
                                                                label:
                                                                  citem.itemName,
                                                                value:
                                                                  citem.itemCode,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            item.inputType == "DATE"
                                              ? _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label:
                                                        item.attributeName +
                                                        ":",
                                                      "label-width": "160px",
                                                      prop: item.attributeCode,
                                                      rules: [
                                                        {
                                                          required:
                                                            item.requiredState,
                                                          message:
                                                            "请选择" +
                                                            item.attributeName,
                                                          trigger: "change",
                                                        },
                                                      ],
                                                    },
                                                  },
                                                  [
                                                    _c("el-date-picker", {
                                                      staticStyle: {
                                                        width: "100%",
                                                      },
                                                      attrs: {
                                                        disabled:
                                                          item.readonly ||
                                                          _vm.claimDisabled,
                                                        format:
                                                          "yyyy-MM-dd HH:mm:ss",
                                                        "value-format":
                                                          "yyyy-MM-dd HH:mm:ss",
                                                        type: "datetime",
                                                        placeholder:
                                                          "请选择" +
                                                          item.attributeName,
                                                        clearable: "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.policydetail[
                                                            item.attributeCode
                                                          ],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.policydetail,
                                                            item.attributeCode,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "policydetail[item.attributeCode]",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            item.inputType == "SELECT_BUTTON"
                                              ? _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label:
                                                        item.attributeName +
                                                        ":",
                                                      "label-width": "160px",
                                                      prop: item.attributeCode,
                                                      rules: [
                                                        {
                                                          required:
                                                            item.requiredState,
                                                          message:
                                                            "请选择" +
                                                            item.attributeName,
                                                          trigger: "change",
                                                        },
                                                      ],
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-radio-group",
                                                      {
                                                        staticStyle: {
                                                          width: "100%",
                                                        },
                                                        attrs: {
                                                          disabled:
                                                            item.readonly ||
                                                            _vm.claimDisabled,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.policydetail[
                                                              item.attributeCode
                                                            ],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.policydetail,
                                                              item.attributeCode,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "policydetail[item.attributeCode]",
                                                        },
                                                      },
                                                      _vm._l(
                                                        item.options,
                                                        function (
                                                          citem,
                                                          index
                                                        ) {
                                                          return _c(
                                                            "el-radio",
                                                            {
                                                              key: index,
                                                              attrs: {
                                                                label:
                                                                  citem.itemCode,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  citem.itemName
                                                                )
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-dialog",
                          {
                            staticClass: "pageDialog",
                            attrs: {
                              visible: _vm.showPreview,
                              "close-on-click-modal": false,
                              width: "1024px",
                              "append-to-body": true,
                            },
                            on: {
                              "update:visible": function ($event) {
                                _vm.showPreview = $event
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "dialog-footer",
                                attrs: { slot: "title" },
                                slot: "title",
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-size": "15px",
                                      "font-weight": "700",
                                    },
                                  },
                                  [_vm._v("在线预览")]
                                ),
                              ]
                            ),
                            _c("div", [
                              _c("iframe", {
                                style: {
                                  width: "1000px",
                                  height: "calc(100vh - 210px)",
                                },
                                attrs: {
                                  id: "previewIframe",
                                  src: _vm.previewUrl,
                                  frameborder: "0",
                                },
                              }),
                            ]),
                          ]
                        ),
                        _c(
                          "el-collapse",
                          {
                            staticStyle: { "margin-top": "10px" },
                            model: {
                              value: _vm.activeTen,
                              callback: function ($$v) {
                                _vm.activeTen = $$v
                              },
                              expression: "activeTen",
                            },
                          },
                          [
                            _c(
                              "el-collapse-item",
                              {
                                staticClass: "collapse-item-style",
                                attrs: { name: "1" },
                              },
                              [
                                _c("template", { slot: "title" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "title",
                                      staticStyle: {
                                        "line-height": "25px",
                                        display: "flex",
                                        "flex-direction": "row",
                                        "align-items": "center",
                                      },
                                    },
                                    [
                                      _c("FormLabel", {
                                        attrs: { "form-label": "附件列表" },
                                      }),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.policydetail
                                                  .productClassCode == "SB" ||
                                                _vm.policydetail
                                                  .productClassCode == "TCWY",
                                              expression:
                                                "policydetail.productClassCode == 'SB' || policydetail.productClassCode == 'TCWY'",
                                            },
                                          ],
                                          staticStyle: {
                                            color: "red",
                                            "font-size": "14px",
                                            "font-weight": "none",
                                            display: "flex",
                                            "flex-direction": "row",
                                            "align-items": "center",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "(请按图示类型上传，附件大小不超过4M)"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _c(
                                  "el-row",
                                  { attrs: { gutter: 0 } },
                                  [
                                    _c("el-col", { attrs: { span: 24 } }, [
                                      _vm.policydetail.productClassCode == "SB"
                                        ? _c(
                                            "div",
                                            { staticClass: "dialog-content" },
                                            _vm._l(
                                              _vm.filestypelists,
                                              function (item, index) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: index,
                                                    staticClass:
                                                      "dialogContentHeader",
                                                  },
                                                  [
                                                    item.documentType === "ty"
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              "font-size":
                                                                "16px",
                                                              "font-weight":
                                                                "bold",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "附件类型：" +
                                                                _vm._s(
                                                                  item.documentName
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      : _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              "font-size":
                                                                "16px",
                                                              "font-weight":
                                                                "bold",
                                                              width: "100%",
                                                              "border-top":
                                                                "1px solid #ddd",
                                                              "line-height":
                                                                "40px",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "附件类型：" +
                                                                _vm._s(
                                                                  item.documentName
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                    _c(
                                                      "el-row",
                                                      {
                                                        staticClass:
                                                          "dialogContentHeader_box",
                                                        attrs: { gutter: 0 },
                                                      },
                                                      _vm._l(
                                                        item.smallMediaTypes,
                                                        function (
                                                          sitem,
                                                          sindex
                                                        ) {
                                                          return _c(
                                                            "el-col",
                                                            {
                                                              key: sindex,
                                                              staticClass:
                                                                "dialogContentHeader1",
                                                              attrs: {
                                                                xs: 24,
                                                                sm: 24,
                                                                md: 12,
                                                                lg: 12,
                                                                xl: 8,
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "filesLists",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "fileBox",
                                                                      staticStyle:
                                                                        {
                                                                          "flex-direction":
                                                                            "column",
                                                                        },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "el-upload",
                                                                        {
                                                                          staticClass:
                                                                            "upload-demo",
                                                                          attrs:
                                                                            {
                                                                              limit: 100,
                                                                              "before-remove":
                                                                                function () {
                                                                                  return (_vm.formData =
                                                                                    "")
                                                                                },
                                                                              action:
                                                                                "",
                                                                              multiple:
                                                                                "",
                                                                              "show-file-list": false,
                                                                              "http-request":
                                                                                function (
                                                                                  val
                                                                                ) {
                                                                                  return _vm.HTuploadFile(
                                                                                    val,
                                                                                    sitem.documentType,
                                                                                    sitem.documentName
                                                                                  )
                                                                                },
                                                                              "before-upload":
                                                                                function (
                                                                                  val
                                                                                ) {
                                                                                  return _vm.HTbeforeUpload(
                                                                                    val,
                                                                                    sitem.documentType
                                                                                  )
                                                                                },
                                                                              "file-list":
                                                                                _vm.fileList,
                                                                              accept:
                                                                                _vm.uploadType,
                                                                              "list-type":
                                                                                "text",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "el-button",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  size: "mini",
                                                                                  plain:
                                                                                    "",
                                                                                  type: "primary",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "点击上传"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              display:
                                                                                "flex",
                                                                              "flex-direction":
                                                                                "row",
                                                                            },
                                                                        },
                                                                        [
                                                                          sitem.documentType !=
                                                                            "FT74" &&
                                                                          sitem.documentType !=
                                                                            "FT52"
                                                                            ? _c(
                                                                                "span",
                                                                                {
                                                                                  staticStyle:
                                                                                    {
                                                                                      color:
                                                                                        "red",
                                                                                      width:
                                                                                        "8px",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "*"
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  sitem.documentName
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _vm._l(
                                                                    _vm.fileList,
                                                                    function (
                                                                      fitem,
                                                                      findex
                                                                    ) {
                                                                      return _c(
                                                                        "div",
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "show",
                                                                                rawName:
                                                                                  "v-show",
                                                                                value:
                                                                                  fitem.fileType ==
                                                                                  sitem.documentType,
                                                                                expression:
                                                                                  "fitem.fileType == sitem.documentType",
                                                                              },
                                                                            ],
                                                                          key: findex,
                                                                          staticClass:
                                                                            "fileBox imgFileBox",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            [
                                                                              _vm.getFileSuffix(
                                                                                fitem.fileExtension
                                                                              )
                                                                                ? _c(
                                                                                    "el-image",
                                                                                    {
                                                                                      staticStyle:
                                                                                        {
                                                                                          width:
                                                                                            "100px",
                                                                                          height:
                                                                                            "100px",
                                                                                          cursor:
                                                                                            "pointer",
                                                                                        },
                                                                                      attrs:
                                                                                        {
                                                                                          src: fitem.fileUrl,
                                                                                          "preview-src-list":
                                                                                            [
                                                                                              fitem.fileUrl,
                                                                                            ],
                                                                                        },
                                                                                    }
                                                                                  )
                                                                                : _c(
                                                                                    "span",
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          fitem.fileName
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "deleteIcon",
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.delFileList(
                                                                                      fitem
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "i",
                                                                                {
                                                                                  staticClass:
                                                                                    "el-icon-circle-close",
                                                                                }
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                ],
                                                                2
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        : _vm.policydetail.productClassCode ==
                                            "XXAX" ||
                                          _vm.policydetail.productClassCode ==
                                            "DXWY"
                                        ? _c(
                                            "div",
                                            { staticClass: "dialog-content" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "font-size": "16px",
                                                    "font-weight": "bold",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "附件类型：" +
                                                      _vm._s(
                                                        _vm.policydetail
                                                          .productClassName
                                                      )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "el-row",
                                                {
                                                  staticClass:
                                                    "dialogContentHeaderBox",
                                                  attrs: { gutter: 0 },
                                                },
                                                _vm._l(
                                                  _vm.filestypelists,
                                                  function (item, index) {
                                                    return _c(
                                                      "el-col",
                                                      {
                                                        key: index,
                                                        staticClass:
                                                          "dialogContentHeader2",
                                                        attrs: {
                                                          xs: 24,
                                                          sm: 24,
                                                          md: 12,
                                                          lg: 12,
                                                          xl: 8,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "filesLists",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "fileBox",
                                                                staticStyle: {
                                                                  "flex-direction":
                                                                    "column",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "el-upload",
                                                                  {
                                                                    staticClass:
                                                                      "upload-demo",
                                                                    attrs: {
                                                                      limit: 100,
                                                                      "before-remove":
                                                                        function () {
                                                                          return (_vm.formData =
                                                                            "")
                                                                        },
                                                                      action:
                                                                        "",
                                                                      multiple:
                                                                        "",
                                                                      "show-file-list": false,
                                                                      "http-request":
                                                                        function (
                                                                          val
                                                                        ) {
                                                                          return _vm.HTuploadFile(
                                                                            val,
                                                                            item.fileType,
                                                                            item.fileTypeDisplay
                                                                          )
                                                                        },
                                                                      "before-upload":
                                                                        function (
                                                                          val
                                                                        ) {
                                                                          return _vm.HTbeforeUpload(
                                                                            val,
                                                                            item.code
                                                                          )
                                                                        },
                                                                      "file-list":
                                                                        _vm.fileList,
                                                                      accept:
                                                                        _vm.uploadType,
                                                                      "list-type":
                                                                        "text",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-button",
                                                                      {
                                                                        attrs: {
                                                                          size: "mini",
                                                                          plain:
                                                                            "",
                                                                          type: "primary",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "点击上传"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c("span", [
                                                                  item.required
                                                                    ? _c(
                                                                        "span",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              color:
                                                                                "red",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "*"
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        item.fileTypeDisplay
                                                                      )
                                                                  ),
                                                                ]),
                                                                [
                                                                  "FT36",
                                                                ].includes(
                                                                  item.fileType
                                                                )
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "file-text",
                                                                        attrs: {
                                                                          title:
                                                                            "预览",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.showPreviewTemplate(
                                                                                item
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "预览"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                [
                                                                  "FT58",
                                                                ].includes(
                                                                  item.fileType
                                                                )
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "file-text",
                                                                        attrs: {
                                                                          title:
                                                                            "下载模板",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.downloadTemplate(
                                                                                item
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " 下载模板"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            ),
                                                            _vm._l(
                                                              _vm.fileList,
                                                              function (
                                                                fitem,
                                                                findex
                                                              ) {
                                                                return _c(
                                                                  "div",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "show",
                                                                          rawName:
                                                                            "v-show",
                                                                          value:
                                                                            fitem.fileType ==
                                                                            item.fileType,
                                                                          expression:
                                                                            "fitem.fileType == item.fileType",
                                                                        },
                                                                      ],
                                                                    key: findex,
                                                                    staticClass:
                                                                      "fileBox imgFileBox",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      [
                                                                        _vm.getFileSuffix(
                                                                          fitem.fileExtension
                                                                        )
                                                                          ? _c(
                                                                              "el-image",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    width:
                                                                                      "100px",
                                                                                    height:
                                                                                      "100px",
                                                                                    cursor:
                                                                                      "pointer",
                                                                                  },
                                                                                attrs:
                                                                                  {
                                                                                    src: fitem.fileUrl,
                                                                                    "preview-src-list":
                                                                                      [
                                                                                        fitem.fileUrl,
                                                                                      ],
                                                                                  },
                                                                              }
                                                                            )
                                                                          : _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    fitem.fileName
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "deleteIcon",
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.delFileList(
                                                                                fitem,
                                                                                item.fileType
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "i",
                                                                          {
                                                                            staticClass:
                                                                              "el-icon-circle-close",
                                                                          }
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm.policydetail.productClassCode ==
                                          "YB"
                                        ? _c(
                                            "div",
                                            { staticClass: "dialog-content" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "font-size": "16px",
                                                    "font-weight": "bold",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "附件类型：" +
                                                      _vm._s(
                                                        _vm.policydetail
                                                          .productClassName
                                                      )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "el-row",
                                                {
                                                  staticClass:
                                                    "dialogContentHeaderBox",
                                                  attrs: { gutter: 0 },
                                                },
                                                _vm._l(
                                                  _vm.filestypelists,
                                                  function (item, index) {
                                                    return _c(
                                                      "el-col",
                                                      {
                                                        key: index,
                                                        staticClass:
                                                          "dialogContentHeader2",
                                                        attrs: {
                                                          xs: 24,
                                                          sm: 24,
                                                          md: 12,
                                                          lg: 12,
                                                          xl: 8,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "filesLists",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "fileBox",
                                                                staticStyle: {
                                                                  "flex-direction":
                                                                    "column",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "el-upload",
                                                                  {
                                                                    staticClass:
                                                                      "upload-demo",
                                                                    attrs: {
                                                                      limit: 100,
                                                                      "before-remove":
                                                                        function () {
                                                                          return (_vm.formData =
                                                                            "")
                                                                        },
                                                                      action:
                                                                        "",
                                                                      multiple:
                                                                        "",
                                                                      "show-file-list": false,
                                                                      "http-request":
                                                                        function (
                                                                          val
                                                                        ) {
                                                                          return _vm.HTuploadFile(
                                                                            val,
                                                                            item.code,
                                                                            item.fileTypeDisplay
                                                                          )
                                                                        },
                                                                      "before-upload":
                                                                        function (
                                                                          val
                                                                        ) {
                                                                          return _vm.HTbeforeUpload(
                                                                            val,
                                                                            item.code
                                                                          )
                                                                        },
                                                                      "file-list":
                                                                        _vm.fileList,
                                                                      accept:
                                                                        _vm.uploadType,
                                                                      "list-type":
                                                                        "text",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-button",
                                                                      {
                                                                        attrs: {
                                                                          size: "mini",
                                                                          plain:
                                                                            "",
                                                                          type: "primary",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "点击上传"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.description
                                                                    )
                                                                  ),
                                                                ]),
                                                                [
                                                                  "FT36",
                                                                ].includes(
                                                                  item.code
                                                                )
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "file-text",
                                                                        attrs: {
                                                                          title:
                                                                            "预览",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.showPreviewTemplate(
                                                                                item
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " 预览"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            ),
                                                            _vm._l(
                                                              _vm.fileList,
                                                              function (
                                                                fitem,
                                                                findex
                                                              ) {
                                                                return _c(
                                                                  "div",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "show",
                                                                          rawName:
                                                                            "v-show",
                                                                          value:
                                                                            fitem.fileType ==
                                                                            item.code,
                                                                          expression:
                                                                            "fitem.fileType == item.code",
                                                                        },
                                                                      ],
                                                                    key: findex,
                                                                    staticClass:
                                                                      "fileBox imgFileBox",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      [
                                                                        _vm.getFileSuffix(
                                                                          fitem.fileExtension
                                                                        )
                                                                          ? _c(
                                                                              "el-image",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    width:
                                                                                      "100px",
                                                                                    height:
                                                                                      "100px",
                                                                                    cursor:
                                                                                      "pointer",
                                                                                  },
                                                                                attrs:
                                                                                  {
                                                                                    src: fitem.fileUrl,
                                                                                    "preview-src-list":
                                                                                      [
                                                                                        fitem.fileUrl,
                                                                                      ],
                                                                                  },
                                                                              }
                                                                            )
                                                                          : _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    fitem.fileName
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "deleteIcon",
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.delFileList(
                                                                                fitem,
                                                                                item.code
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "i",
                                                                          {
                                                                            staticClass:
                                                                              "el-icon-circle-close",
                                                                          }
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "div",
                                            { staticClass: "dialog-content" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "font-size": "16px",
                                                    "font-weight": "bold",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "附件类型：" +
                                                      _vm._s(
                                                        _vm.policydetail
                                                          .productClassName
                                                      )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "el-row",
                                                {
                                                  staticClass:
                                                    "dialogContentHeaderBox",
                                                  attrs: { gutter: 0 },
                                                },
                                                _vm._l(
                                                  _vm.filestypelists,
                                                  function (item, index) {
                                                    return _c(
                                                      "el-col",
                                                      {
                                                        key: index,
                                                        staticClass:
                                                          "dialogContentHeader2",
                                                        attrs: {
                                                          xs: 24,
                                                          sm: 24,
                                                          md: 12,
                                                          lg: 12,
                                                          xl: 8,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "filesLists",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "fileBox",
                                                                staticStyle: {
                                                                  "flex-direction":
                                                                    "column",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "el-upload",
                                                                  {
                                                                    staticClass:
                                                                      "upload-demo",
                                                                    attrs: {
                                                                      limit: 100,
                                                                      "before-remove":
                                                                        function () {
                                                                          return (_vm.formData =
                                                                            "")
                                                                        },
                                                                      action:
                                                                        "",
                                                                      multiple:
                                                                        "",
                                                                      "show-file-list": false,
                                                                      "http-request":
                                                                        function (
                                                                          val
                                                                        ) {
                                                                          return _vm.HTuploadFile(
                                                                            val,
                                                                            item.code,
                                                                            item.fileTypeDisplay
                                                                          )
                                                                        },
                                                                      "before-upload":
                                                                        function (
                                                                          val
                                                                        ) {
                                                                          return _vm.HTbeforeUpload(
                                                                            val,
                                                                            item.code
                                                                          )
                                                                        },
                                                                      "file-list":
                                                                        _vm.fileList,
                                                                      accept:
                                                                        _vm.uploadType,
                                                                      "list-type":
                                                                        "text",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-button",
                                                                      {
                                                                        attrs: {
                                                                          size: "mini",
                                                                          plain:
                                                                            "",
                                                                          type: "primary",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "点击上传"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.description
                                                                    )
                                                                  ),
                                                                ]),
                                                                _vm.policydetail
                                                                  .productClassCode ==
                                                                  "DBC" &&
                                                                [
                                                                  "FT36",
                                                                  "FT58",
                                                                ].includes(
                                                                  item.code
                                                                )
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "file-text",
                                                                        attrs: {
                                                                          title:
                                                                            "下载模板",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.downloadTemplate(
                                                                                item
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " 下载模板"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            ),
                                                            _vm._l(
                                                              _vm.fileList,
                                                              function (
                                                                fitem,
                                                                findex
                                                              ) {
                                                                return _c(
                                                                  "div",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "show",
                                                                          rawName:
                                                                            "v-show",
                                                                          value:
                                                                            fitem.fileType ==
                                                                            item.code,
                                                                          expression:
                                                                            "fitem.fileType == item.code",
                                                                        },
                                                                      ],
                                                                    key: findex,
                                                                    staticClass:
                                                                      "fileBox imgFileBox",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      [
                                                                        _vm.getFileSuffix(
                                                                          fitem.fileExtension
                                                                        )
                                                                          ? _c(
                                                                              "el-image",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    width:
                                                                                      "100px",
                                                                                    height:
                                                                                      "100px",
                                                                                    cursor:
                                                                                      "pointer",
                                                                                  },
                                                                                attrs:
                                                                                  {
                                                                                    src: fitem.fileUrl,
                                                                                    "preview-src-list":
                                                                                      [
                                                                                        fitem.fileUrl,
                                                                                      ],
                                                                                  },
                                                                              }
                                                                            )
                                                                          : _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    fitem.fileName
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "deleteIcon",
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.delFileList(
                                                                                fitem,
                                                                                item.code
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "i",
                                                                          {
                                                                            staticClass:
                                                                              "el-icon-circle-close",
                                                                          }
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
            _c("div", { staticClass: "footer-btn" }, [
              _c(
                "div",
                { staticClass: "footer-btn-list" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-close", size: "mini" },
                      on: { click: _vm.closePage },
                    },
                    [_vm._v("关闭")]
                  ),
                  _vm.applyAgain != "7"
                    ? _c(
                        "el-button",
                        {
                          attrs: { size: "mini", icon: "el-icon-check" },
                          on: { click: _vm.ApplySave },
                        },
                        [_vm._v(" 暂存 ")]
                      )
                    : _vm._e(),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "mini",
                        icon: "el-icon-check",
                        type: "primary",
                      },
                      on: { click: _vm.saveSubmit },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.applyAgain == "7" ? "重新理赔" : "提交申请"
                          )
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }