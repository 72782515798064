import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import customersRequest from '@/api/customers';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    types: {
      type: String,
      default: ''
    },
    opType: {
      type: String,
      default: ''
    },
    configId: {
      type: Number,
      default: null
    },
    currentItem: {
      ttype: Object,
      default: function _default() {
        return {};
      }
    },
    selectList: {
      ttype: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      form: {
        configId: '',
        distinguishName: '',
        defeatTo: '',
        successTo: ''
      },
      rules: {
        distinguishName: {
          required: true,
          message: '请输入类别名称',
          trigger: "blur"
        }
      },
      formLoading: false
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
      ;
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    initData: function initData() {
      this.form = {
        configId: this.configId,
        distinguishName: '',
        defeatTo: '',
        successTo: ''
      };
      if (this.opType === "add") {} else {
        this.form.distinguishName = this.currentItem.distinguishName;
        this.form.defeatTo = this.currentItem.defeatTo;
        this.form.successTo = this.currentItem.successTo;
      }
      ;
      this.$refs.detail.resetFields();
    },
    // 保存数据
    save: function save() {
      var _this = this;
      this.$refs.detail.validate(function (valid) {
        if (valid) {
          _this.formLoading = true;
          var data = _this.types == 'type' ? _this.form : _this.form;
          console.log(data);
          var req = _this.opType === 'add' ? customersRequest.createDistinguish(_this.form) : customersRequest.updateDistinguish(_this.currentItem.customerDistinguishId, _this.form);
          req.then(function (res) {
            _this.formLoading = false;
            if (res.code === 200) {
              _this.$message.success(res.msg);
              _this.closePage(false);
            }
            ;
          }).catch(function (err) {
            _this.formLoading = false;
          });
        }
        ;
      });
    },
    //关闭弹窗
    closePage: function closePage() {
      var boolean = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      this.$emit('closePage', boolean);
    }
  }
};