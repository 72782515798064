import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
/**
 * 系统设置
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 列表
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   * @param {*} data 内容
   * 
   */
  getList: function getList(pageNum, pageSize, data) {
    return request.get(getUrl("/operation/log/list?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 5), {
      params: data
    });
  },
  /**
   * 查询用户密码配置信息
   */
  getPwdConfig: function getPwdConfig() {
    return request.get(getUrl("/users/getUserPwdConfig", 5));
  },
  /**
   * 保存用户密码配置信息
   * @param {*} data 内容
   */
  savePwdConfig: function savePwdConfig(data) {
    return request.post(getUrl("/users/saveUserPwdConfig", 5), data);
  },
  //  =========================================文件模板管理================================================================================

  /**
     * 列表
     * @param {*} pageNum 当前页
     * @param {*} pageSize 每页显示条数
     */
  getFileTemplateList: function getFileTemplateList(pageNum, pageSize, data) {
    return request.get(getUrl("/fileTemplate/search?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 3), {
      params: data
    });
  },
  /**
  * 新增
  * @param {*} data 内容
  */
  addFileTemplate: function addFileTemplate(data) {
    return request.post(getUrl("/fileTemplate/create", 3), data);
  },
  /**
  * 修改
  * @param {*} data 内容
  */
  updateFileTemplate: function updateFileTemplate(data) {
    return request.post(getUrl("/fileTemplate/update", 3), data);
  },
  /**
  * 查询数据
  * @param {*} id 
  */
  getFileTemplateInfo: function getFileTemplateInfo(id) {
    return request.get(getUrl("/fileTemplate/get?id=".concat(id), 3));
  },
  /**
  * 删除
  * @param {*} ids
  */
  deleteFileTemplate: function deleteFileTemplate(ids) {
    return request.delete(getUrl("/fileTemplate/batchDelete/".concat(ids.toString()), 3));
  },
  //  =========================================导出脱敏配置================================================================================
  /**
     * 列表
     * @param {*} pageNum 当前页
     * @param {*} pageSize 每页显示条数
     */
  getExportMaskList: function getExportMaskList(pageNum, pageSize, data) {
    return request.get(getUrl("/exportMask/search?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 9), {
      params: data
    });
  },
  /**
  * 新增
  * @param {*} data 内容
  */
  addExportMask: function addExportMask(data) {
    return request.post(getUrl("/exportMask/create", 9), data);
  },
  /**
  * 修改
  * @param {*} data 内容
  */
  updateExportMask: function updateExportMask(data) {
    return request.post(getUrl("/exportMask/update", 9), data);
  },
  /**
  * 查询数据
  * @param {*} id 
  */
  getExportMaskInfo: function getExportMaskInfo(id) {
    return request.get(getUrl("/exportMask/get?id=".concat(id), 9));
  },
  /**
  * 删除
  * @param {*} ids
  */
  deleteExportMask: function deleteExportMask(ids) {
    return request.post(getUrl("/exportMask/batchDelete?ids=".concat(ids.toString()), 9), {});
  }
};
export default objApi;