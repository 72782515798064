var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.upStyleloading,
          expression: "upStyleloading",
        },
      ],
      staticClass: "paic-accommodation-node",
    },
    [
      _c(
        "el-form",
        {
          ref: "detail",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            "label-width": "120px",
            size: "mini",
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 11 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "通融紧急程度：", prop: "urgency" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.form.urgency,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "urgency", $$v)
                            },
                            expression: "form.urgency",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "1" } }, [
                            _vm._v("普通"),
                          ]),
                          _c("el-radio", { attrs: { label: "2" } }, [
                            _vm._v("紧急"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 13 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "自主定价系数：", prop: "selfPriceRate" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "bsDiscount-style",
                            attrs: {
                              type: "text",
                              placeholder: "请输入自主定价系数",
                              clearable: false,
                            },
                            on: {
                              input: function ($event) {
                                _vm.form.selfPriceRate = _vm.onInput(
                                  _vm.form.selfPriceRate,
                                  0,
                                  100,
                                  2
                                )
                              },
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                _vm.form.selfPriceRate = _vm.onInput(
                                  _vm.form.selfPriceRate,
                                  0,
                                  100,
                                  2
                                )
                              },
                            },
                            model: {
                              value: _vm.form.selfPriceRate,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "selfPriceRate",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.selfPriceRate",
                            },
                          }),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "margin-left": "10px",
                                color: "red",
                              },
                            },
                            [_vm._v("(可调整范围：0.65 ~ 1.35)")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "通融原因：", prop: "reason" } },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "500",
                          type: "textarea",
                          placeholder: "请输入通融原因",
                          autosize: { minRows: 4, maxRows: 4 },
                          resize: "none",
                        },
                        model: {
                          value: _vm.form.reason,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "reason", $$v)
                          },
                          expression: "form.reason",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "must-star",
                      attrs: { label: "文件列表：" },
                    },
                    [
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { flex: "1" },
                              attrs: {
                                filterable: "",
                                placeholder: "选择证件类型",
                                size: "mini",
                              },
                              on: { change: _vm.imgPeopleType },
                              model: {
                                value: _vm.form.businessType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "businessType", $$v)
                                },
                                expression: "form.businessType",
                              },
                            },
                            _vm._l(_vm.mediaTypeList, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.name, value: item.code },
                              })
                            }),
                            1
                          ),
                          _c(
                            "el-select",
                            {
                              staticStyle: { "margin-left": "5px", flex: "1" },
                              attrs: {
                                filterable: "",
                                placeholder: "选择证件类型",
                                size: "mini",
                              },
                              on: { change: _vm.imgIdType },
                              model: {
                                value: _vm.form.ID,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "ID", $$v)
                                },
                                expression: "form.ID",
                              },
                            },
                            _vm._l(_vm.subTypeList, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.name, value: item.code },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "flex-wrap": "wrap",
                            "max-height": "400px",
                            "overflow-y": "auto",
                            padding: "5px",
                          },
                        },
                        _vm._l(_vm.imageInsList, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticStyle: {
                                border: "1px solid #e0e0e0",
                                width: "200px",
                                margin: "10px",
                                "border-radius": "6px",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    padding: "5px 2px",
                                    display: "flex",
                                    "justify-content": "space-between",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "mini-font-size",
                                      staticStyle: {
                                        "word-break": "break-word",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(item.mediaTypeName) + " "
                                      ),
                                    ]
                                  ),
                                  _vm.handleImgReturn(item)
                                    ? _c("i", {
                                        staticClass: "el-icon-close",
                                        on: {
                                          click: function ($event) {
                                            return _vm.imgInsClose(index)
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              ),
                              _c(
                                "el-upload",
                                {
                                  staticClass: "avatar-uploader",
                                  attrs: {
                                    multiple: "",
                                    drag: "",
                                    action: "1",
                                    "show-file-list": false,
                                    "http-request": _vm.uploadImg,
                                    "auto-upload": false,
                                    "on-change": function (val) {
                                      _vm.uploadImgChange(val, item)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "flex-wrap": "wrap",
                                      },
                                    },
                                    [
                                      _c(
                                        "draggable",
                                        _vm._b(
                                          {
                                            key: "1",
                                            staticClass:
                                              "board-column-content-upload",
                                            attrs: {
                                              list: item.imageList,
                                              group: "mission",
                                              move: _vm.rightOnMove,
                                              animation: "300",
                                            },
                                            on: { sort: _vm.rightSort },
                                          },
                                          "draggable",
                                          _vm.$attrs,
                                          false
                                        ),
                                        [
                                          _vm._l(
                                            item.imageList,
                                            function (eitem, eindex) {
                                              return _c(
                                                "div",
                                                {
                                                  key: eindex,
                                                  staticStyle: {
                                                    width: "64px",
                                                    height: "64px",
                                                    position: "relative",
                                                    margin: "5px",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.preventDefault()
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("el-image", {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value:
                                                              _vm._.toLower(
                                                                eitem.imageName
                                                              ).indexOf(
                                                                ".pdf"
                                                              ) == -1,
                                                            expression:
                                                              "(_.toLower(eitem.imageName)).indexOf('.pdf') == -1",
                                                          },
                                                        ],
                                                        staticClass: "avatar",
                                                        staticStyle: {
                                                          width: "65px",
                                                          height: "65px",
                                                          display: "flex",
                                                          "align-items":
                                                            "center",
                                                          "justify-content":
                                                            "center",
                                                        },
                                                        attrs: {
                                                          src: eitem.imgUrl,
                                                          "preview-src-list": [
                                                            eitem.imgUrl,
                                                          ],
                                                        },
                                                        on: {
                                                          mouseover: function (
                                                            $event
                                                          ) {
                                                            return _vm.mouseOver(
                                                              "right" +
                                                                eindex +
                                                                index
                                                            )
                                                          },
                                                          mouseleave: function (
                                                            $event
                                                          ) {
                                                            return _vm.mouseLeave(
                                                              "right" +
                                                                eindex +
                                                                index
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _c("svg-icon", {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value:
                                                              _vm._.toLower(
                                                                eitem.imageName
                                                              ).indexOf(
                                                                ".pdf"
                                                              ) != -1,
                                                            expression:
                                                              "(_.toLower(eitem.imageName)).indexOf('.pdf') != -1",
                                                          },
                                                        ],
                                                        staticStyle: {
                                                          width: "65px",
                                                          height: "65px",
                                                        },
                                                        attrs: {
                                                          "icon-class": "pdf",
                                                        },
                                                        on: {
                                                          mouseover: function (
                                                            $event
                                                          ) {
                                                            return _vm.mouseOver(
                                                              "right" +
                                                                eindex +
                                                                index
                                                            )
                                                          },
                                                          mouseleave: function (
                                                            $event
                                                          ) {
                                                            return _vm.mouseLeave(
                                                              "right" +
                                                                eindex +
                                                                index
                                                            )
                                                          },
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.openPdfFile(
                                                              eitem
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  !_vm._.isNull(
                                                    eitem.documentId
                                                  )
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            border:
                                                              "1px solid #2150A3",
                                                            "background-color":
                                                              "rgba(255,255,255,0.8)",
                                                            position:
                                                              "absolute",
                                                            top: "0",
                                                            left: "0",
                                                            "border-radius":
                                                              "50%",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-tooltip",
                                                            {
                                                              staticClass:
                                                                "item",
                                                              attrs: {
                                                                effect: "dark",
                                                                content:
                                                                  "已上传到保司",
                                                                placement:
                                                                  "top",
                                                              },
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "el-icon-upload",
                                                                staticStyle: {
                                                                  color:
                                                                    "#2150A3",
                                                                  "font-size":
                                                                    "13px",
                                                                  margin: "0",
                                                                  "line-height":
                                                                    "0px",
                                                                  padding:
                                                                    "3px 1px",
                                                                  opacity: "1",
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._.isNull(eitem.documentId)
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            "background-color":
                                                              "rgba(255,255,255)",
                                                            position:
                                                              "absolute",
                                                            top: "-5px",
                                                            right: "-5px",
                                                            "border-radius":
                                                              "50%",
                                                            display: "none",
                                                            width: "17px",
                                                            height: "17px",
                                                            cursor: "pointer",
                                                            "line-height":
                                                              "17px",
                                                          },
                                                          attrs: {
                                                            id:
                                                              "right" +
                                                              eindex +
                                                              index,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return _vm.delRightImgItem(
                                                                eitem,
                                                                eindex,
                                                                item
                                                              )
                                                            },
                                                            mouseover:
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.mouseOver(
                                                                  "right" +
                                                                    eindex +
                                                                    index
                                                                )
                                                              },
                                                            mouseleave:
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.mouseLeave(
                                                                  "right" +
                                                                    eindex +
                                                                    index
                                                                )
                                                              },
                                                          },
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-error",
                                                            staticStyle: {
                                                              color: "red",
                                                              "font-size":
                                                                "17px",
                                                            },
                                                          }),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              )
                                            }
                                          ),
                                          !item.imageList.length
                                            ? _c("i", {
                                                staticClass:
                                                  "el-icon-plus avatar-uploader-icon",
                                              })
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm.imageUrl
                                ? _c("img", {
                                    staticClass: "avatar",
                                    attrs: { src: _vm.imageUrl },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "15px",
          },
        },
        [
          _vm.showPayBtn
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini", plain: "" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.save($event)
                    },
                  },
                },
                [_vm._v(" 确认并继续报价 ")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }