//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import dict from "../../../mixins/dict";
// import initHeight from "../../../mixins/initHeight";
// import Pagination from "@/components/Pagination";
// import request from "../../../api/finance";

export default {
  props: {
    importIt: {
      type: String,
      default: {}
    }
  },
  // mixins: [initHeight, dict],
  // components: {
  //     Pagination,
  // },
  data: function data() {
    return {
      formData: '',
      form: {},
      uploadForm: {},
      fileList: [],
      file: null
    };
  },
  watch: {},
  mounted: function mounted() {
    console.log(this.importIt);
    console.log('这是品牌', this.importIt === 'automobileBrandMaintenance');
    console.log('这是车系', this.importIt === 'brandMaintenance');
    console.log('这是车型', this.importIt === 'vehicleModelMaintenance');
  },
  methods: {
    uploadFile: function uploadFile(param) {
      //上传文件
      var formData = new FormData();
      formData.append("file", param.file);
      this.formData = formData;
    },
    selectFile: function selectFile() {
      //上传点击
      // request.importModul(this.formData).then((res) => {
      //     if (res.code === 200) {
      //         this.$message({
      //             message: res.msg,
      //             type: "success",
      //         });
      //         this.$emit("closePage");
      //     }
      // });
    },
    closePage: function closePage() {
      //关闭弹窗
      this.$emit("closePage");
    }
  }
};