//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import operateManageApi from '@/api/operateManage';
import dictRequest from '@/api/dictionary';
import { limitInput } from "@/utils/common";
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    opType: {
      type: String,
      default: ''
    },
    currentObj: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      form: {
        vehicleId: '',
        makerModelCode: '',
        vehicleName: '',
        brandName: '',
        familyName: '',
        purchasePrice: '',
        yearPattern: '',
        displacement: '',
        engineDesc: '',
        gearboxType: '',
        seat: '',
        seatMax: 0,
        seatMin: 0,
        configName: '',
        vehicleImport: '',
        powerType: '',
        vehicleClass: '',
        marketDate: '',
        guidePrice: '',
        fullWeightMax: '',
        fullWeightMin: ''
      },
      rules: {
        vehicleName: {
          required: true,
          message: '请输入车型名称',
          trigger: "blur"
        },
        brandName: {
          required: true,
          message: '请输入品牌名称',
          trigger: "blur"
        },
        familyName: {
          required: true,
          message: '请输入车系名称',
          trigger: "blur"
        },
        purchasePrice: {
          required: true,
          message: '请输入新车购置价',
          trigger: "blur"
        },
        yearPattern: {
          required: true,
          message: '请输入年款',
          trigger: "blur"
        },
        displacement: {
          required: true,
          message: '请输入排量',
          trigger: "blur"
        },
        engineDesc: {
          required: true,
          message: '请输入排量描述',
          trigger: "blur"
        },
        gearboxType: {
          required: true,
          message: '请输入变速器类型',
          trigger: "blur"
        },
        seat: {
          required: true,
          message: '请输入额定座位数',
          trigger: "blur"
        },
        configName: {
          required: true,
          message: '请输入配置名称',
          trigger: "blur"
        },
        vehicleImport: {
          required: true,
          message: '请输入系别名称',
          trigger: "blur"
        },
        powerType: {
          required: true,
          message: '请选择燃料种类',
          trigger: "change"
        },
        vehicleClass: {
          required: true,
          message: '请选择车型分类',
          trigger: "change"
        },
        marketDate: {
          required: true,
          message: '请输入上市时间',
          trigger: "blur"
        },
        guidePrice: {
          required: true,
          message: '请输入厂商指导价',
          trigger: "blur"
        },
        fullWeightMax: {
          required: true,
          message: '请输入装备质量(最大)',
          trigger: "blur"
        },
        fullWeightMin: {
          required: true,
          message: '请输入装备质量(最小)',
          trigger: "blur"
        }
      },
      formLoading: false,
      powerTypeList: [],
      vehicleClassList: []
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
      ;
    }
  },
  mounted: function mounted() {
    this.getPowerTypeList();
    this.getVehicleClassList();
    this.initData();
  },
  methods: {
    //获取燃料种类
    getPowerTypeList: function getPowerTypeList() {
      var _this = this;
      dictRequest.getDictItemsByCode("JY_FUEL_TYPE").then(function (res) {
        if (res.data) {
          _this.powerTypeList = res.data;
        }
        ;
      });
    },
    //获取车型分类
    getVehicleClassList: function getVehicleClassList() {
      var _this2 = this;
      dictRequest.getDictItemsByCode("JY_CAR_MODEL").then(function (res) {
        if (res.data) {
          _this2.vehicleClassList = res.data;
        }
        ;
      });
    },
    initData: function initData() {
      //初始化数据
      this.form = {
        vehicleId: '',
        makerModelCode: '',
        vehicleName: '',
        brandName: '',
        familyName: '',
        purchasePrice: '',
        yearPattern: '',
        displacement: '',
        engineDesc: '',
        gearboxType: '',
        seat: '',
        seatMax: 0,
        seatMin: 0,
        configName: '',
        vehicleImport: '',
        powerType: '',
        vehicleClass: '',
        marketDate: '',
        guidePrice: '',
        fullWeightMax: '',
        fullWeightMin: ''
      };
      if (this.opType === "edit") {
        this.form = _.cloneDeep(this.currentObj);
      }
      ;
      this.$refs.detail.resetFields();
    },
    // 保存数据
    save: function save() {
      var _this3 = this;
      this.$refs.detail.validate(function (valid) {
        if (valid) {
          if (_this3.form.seatMax < _this3.form.seatMin) {
            _this3.$message.warning('额定座位数(最大)不能小于额定座位数(最小)');
            return;
          }
          ;
          if (_this3.form.fullWeightMax < _this3.form.fullWeightMin) {
            _this3.$message.warning('装备质量(最大)不能小于装备质量(最小)');
            return;
          }
          ;
          _this3.form.seatMax = _this3.form.seatMax || 0;
          _this3.form.seatMin = _this3.form.seatMin || 0;
          _this3.formLoading = true;
          var req = _this3.opType === 'add' ? operateManageApi.addJyVehicle(_this3.form) : operateManageApi.editJyVehicle(_this3.currentObj.jyModelId, _this3.form);
          req.then(function (res) {
            _this3.formLoading = false;
            if (res.code === 200) {
              _this3.$message.success(res.msg);
              _this3.closePage();
            }
            ;
          }).catch(function (err) {
            _this3.formLoading = false;
          });
        }
        ;
      });
    },
    closePage: function closePage() {
      //关闭弹窗
      this.$emit('closePage');
    },
    onInput: function onInput(num, min, max, limit) {
      //对输入内容做精度校验
      this.$forceUpdate();
      if (limit > 0) {
        if (min && parseFloat(num) < parseFloat(min)) {
          num = min;
        }
        ;
        if (max && parseFloat(num) > parseFloat(max)) {
          num = max;
        }
        ;
      } else {
        if (min && num < min) {
          num = min;
        }
        ;
        if (max && num > max) {
          num = max;
        }
        ;
      }
      ;
      return limitInput(num, limit);
    }
  }
};