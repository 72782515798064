import "core-js/modules/es.array.concat.js";
/**
 * 车辆进店
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  //  **********************************进店客户************************************
  /**
   * 查询车辆进店客户信息分页列表
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   * @param {*} data 内容
   */
  getCustomerList: function getCustomerList(pageNum, pageSize, data) {
    return request.get(getUrl("/callIn/cameraCustomer?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 15), {
      params: data
    });
  },
  //  **********************************屏蔽清单************************************
  /**
   * 查询摄影头屏蔽信息分页列表
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   * @param {*} data 内容
   */
  getCameraBlackList: function getCameraBlackList(pageNum, pageSize, data) {
    return request.get(getUrl("/callIn/cameraBlack?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 15), {
      params: data
    });
  },
  /**
   * 新增摄影头屏蔽信息 
   * @param {*} data 内容
   */
  addCameraBlack: function addCameraBlack(data) {
    return request.post(getUrl("/callIn/cameraBlack", 15), data);
  },
  /**
   * 获取摄影头屏蔽信息
   * @param {*} cameraBlackId
   */
  getCameraBlackInfo: function getCameraBlackInfo(cameraBlackId) {
    return request.get(getUrl("/callIn/cameraBlack/".concat(cameraBlackId), 15));
  },
  /**
   * 修改摄影头屏蔽信息
   * @param {*} cameraBlackId
   * @param {*} data 内容
   */
  editCameraBlack: function editCameraBlack(cameraBlackId, data) {
    return request.put(getUrl("/callIn/cameraBlack/".concat(cameraBlackId), 15), data);
  },
  /**
   * 删除摄影头屏蔽信息
   * @param {*} ids
   */
  deleteCameraBlack: function deleteCameraBlack(ids) {
    return request.delete(getUrl("/callIn/cameraBlack?ids=".concat(ids), 15));
  },
  //  **********************************设备管理************************************
  /**
   * 查询摄影头设备信息分页列表
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   * @param {*} data 内容
   */
  getCameraDeviceList: function getCameraDeviceList(pageNum, pageSize, data) {
    return request.get(getUrl("/callIn/cameraDevice?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 15), {
      params: data
    });
  },
  /**
   * 新增摄影头设备信息 
   * @param {*} data 内容
   */
  addCameraDevice: function addCameraDevice(data) {
    return request.post(getUrl("/callIn/cameraDevice", 15), data);
  },
  /**
   * 获取摄影头设备信息
   * @param {*} cameraDeviceId
   */
  getCameraDeviceInfo: function getCameraDeviceInfo(cameraDeviceId) {
    return request.get(getUrl("/callIn/cameraDevice/".concat(cameraDeviceId), 15));
  },
  /**
   * 修改摄影头设备信息
   * @param {*} cameraDeviceId
   * @param {*} data 内容
   */
  editCameraDevice: function editCameraDevice(cameraDeviceId, data) {
    return request.put(getUrl("/callIn/cameraDevice/".concat(cameraDeviceId), 15), data);
  },
  /**
   * 删除摄影头设备信息
   * @param {*} ids
   */
  deleteCameraDevice: function deleteCameraDevice(ids) {
    return request.delete(getUrl("/callIn/cameraDevice?ids=".concat(ids), 15));
  },
  //  **********************************识别记录************************************
  /**
   * 查询摄影头识别记录信息分页列表
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   * @param {*} data 内容
   */
  getCameraRecordList: function getCameraRecordList(pageNum, pageSize, data) {
    return request.get(getUrl("/callIn/cameraRecord?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 15), {
      params: data
    });
  },
  /**
   * 获取摄影头识别记录信息详情
   * @param {*} cameraRecordId
   */
  getCameraRecordInfo: function getCameraRecordInfo(cameraRecordId) {
    return request.get(getUrl("/callIn/cameraRecord/detail/".concat(cameraRecordId), 15));
  }
};
export default objApi;