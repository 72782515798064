import _defineProperty from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/defineProperty.js";
var _objApi;
import "core-js/modules/es.array.concat.js";
/**
 * 地图统计看板
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = (_objApi = {
  /**
   * 出单/保费top
   * @param {*} areaCode 城市
   * @param {*} count 例如：前5名
   * @param {*} data 参数
   */
  getLeaderboards: function getLeaderboards(areaCode, count, data) {
    return request.get(getUrl("/map/getPremiumLeaderboards?areaCode=".concat(areaCode, "&count=").concat(count), 1), {
      params: data
    });
  },
  /**
   * 总出单/总保费走势
   * @param {*} areaCode 城市
   * @param {*} data 参数
   */
  getAreaStatistics: function getAreaStatistics(areaCode, dateType, data) {
    return request.get(getUrl("/map/getAreaStatistics?areaCode=".concat(areaCode, "&dateType=").concat(dateType), 1), {
      params: data
    });
  },
  /**
   * 保险公司出单/保费业务
   * @param {*} areaCode 城市
   * @param {*} data 参数
   */
  getInsCorpInsure: function getInsCorpInsure(areaCode, data) {
    return request.get(getUrl("/map/getInsCorpInsure?areaCode=".concat(areaCode), 1), {
      params: data
    });
  },
  /**
   * 地图分析
   * @param {*} areaCode 城市
   * @param {*} data 参数
   */
  getMapStatistics: function getMapStatistics(areaCode, data) {
    return request.get(getUrl("/map/getMapStatistics?areaCode=".concat(areaCode), 1), {
      params: data
    });
  },
  /**
   * 按承保日期统计
   *
   */
  getSummaryAnalysis: function getSummaryAnalysis(data) {
    return request.get(getUrl("/map/getSummaryAnalysis", 1), {
      params: data
    });
  },
  /**
   * 按保司分析
   *
   */
  getInsCorpInsures: function getInsCorpInsures(data) {
    return request.get(getUrl("/map/getInsCorpInsure", 1), {
      params: data
    });
  },
  // ============================================ 2023-02-20 调整后的接口 ===================================
  /**
  * 地图看板4合1接口
  *
  */
  getQueryMapAggregateStats: function getQueryMapAggregateStats(topInsCorpRanking, topOrganRanking, data) {
    return request.post(getUrl("/policy/stats/queryMapAggregateStats?topInsCorpRanking=".concat(topInsCorpRanking, "&topOrganRanking=").concat(topOrganRanking), 1), data);
  },
  /**
   * 按地区统计出单/保费 ------ 地区
   *
   */
  getQueryStatsByArea: function getQueryStatsByArea(data) {
    return request.post(getUrl("/policy/stats/queryMapAreaStats", 1), data);
  },
  /**
   * 按时间统计出单/保费走势图 ------- 时间
   *
   */
  getQueryStatsTrendChartByTime: function getQueryStatsTrendChartByTime(data) {
    return request.post(getUrl("/policy/stats/queryMapStatsTrendChart", 1), data);
  },
  /**
   * 按保司统计出单/保费排名 ------ 保司
   *
   */
  getQueryStatsRankingByInsCorp: function getQueryStatsRankingByInsCorp(top, data) {
    return request.post(getUrl("/policy/stats/queryMapStatsInsCorpRanking?top=".concat(top), 1), data);
  },
  /**
   * 按机构统计出单/保费排名 ------ 机构
   *
   */
  getQueryStatsRankingByOrgan: function getQueryStatsRankingByOrgan(top, data) {
    return request.post(getUrl("/policy/stats/queryMapStatsOrganRanking?top=".concat(top), 1), data);
  },
  /**
   * 车险业务统计页面 ------ 出单/保费 (时间)
   *
   */
  getQueryOrganStatsTrendChart: function getQueryOrganStatsTrendChart(data) {
    return request.post(getUrl("/policy/stats/queryOrganStatsTrendChart", 1), data);
  },
  /**
   * 车险业务统计页面 ------ 出单/保费 (保司)
   *
   */
  getQueryOrganStatsInsCorpRanking: function getQueryOrganStatsInsCorpRanking(top, data) {
    return request.post(getUrl("/policy/stats/queryOrganStatsInsCorpRanking?top=".concat(top), 1), data);
  },
  /**
   * 车险业务统计页面 ------ 出单/保费 (保司)
   *
   */
  getPolicySearch: function getPolicySearch(data) {
    return request.get(getUrl("/policy/stats/search", 1), {
      params: data
    });
  },
  /**
   * 报价统计车辆数
   */
  getQuoteCarCount: function getQuoteCarCount(data) {
    return request.post(getUrl("/insure/stats/queryQuoteCarCount", 1), data);
  },
  /**
   * 报价统计车辆数
   */
  getQuoteCount: function getQuoteCount(data) {
    return request.post(getUrl("/insure/stats/queryQuoteCount", 1), data);
  },
  /**
  * 渠道交互看板统计接口
  *
  */
  getChannelStatistics: function getChannelStatistics(topHandlerRanking, topInsCorpRanking, topMethodsRanking, data) {
    return request.post(getUrl("/insCorpChannelAnalysis/interactiveAnalysis?topHandlerRanking=".concat(topHandlerRanking, "&topInsCorpRanking=").concat(topInsCorpRanking, "&topMethodsRanking=").concat(topMethodsRanking), 14), data);
  },
  // 增值服务看板（哪吒的服务，需要后台把服务迁移过去）
  /**
    * 保险公司出单/保费业务
    * @param {*} areaCode 城市
    * @param {*} data 参数
    */
  noVechilegetInsCorpInsure: function noVechilegetInsCorpInsure(data) {
    return request.get(getUrl("/renewal/map/getInsCorpInsure", 11), {
      params: data
    });
  },
  /**
   * 总出单/总保费走势
   * @param {*} areaCode 城市
   * @param {*} data 参数
   */
  noVechilegetAreaStatistics: function noVechilegetAreaStatistics(areaCode, dateType, data) {
    return request.get(getUrl("/renewal/map/getCoreGapProductOrderStatistics?areaCode=".concat(areaCode, "&dateType=").concat(dateType), 11), {
      params: data
    });
  },
  /**
       * 地图分析
       * @param {*} areaCode 城市
       * @param {*} data 参数
       */
  noVechilegetMapStatistics: function noVechilegetMapStatistics(areaCode, data) {
    return request.get(getUrl("/renewal/map/mapStatistics?areaCode=".concat(areaCode), 11), {
      params: data
    });
  },
  /**
  * 出单/保费top
  * @param {*} areaCode 城市
  * @param {*} count 例如：前5名
  * @param {*} data 参数
  */
  noVechilegetLeaderboards: function noVechilegetLeaderboards(areaCode, count, data) {
    return request.get(getUrl("/renewal/map/getCoreGapProductOrderTop?areaCode=".concat(areaCode, "&count=").concat(count), 11), {
      params: data
    });
  }
}, _defineProperty(_objApi, "noVechilegetInsCorpInsure", function noVechilegetInsCorpInsure(data) {
  return request.get(getUrl("/renewal/map/getInsCorpInsure", 11), {
    params: data
  });
}), _defineProperty(_objApi, "noVechileCumulative", function noVechileCumulative(data) {
  return request.get(getUrl("/renewal/map/cumulative/month", 11), {
    params: data
  });
}), _objApi);
export default objApi;