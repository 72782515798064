/**
 * 续保配置接口
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 续保配置--根据地区编码查询续保城市周期配置
   * @param {*} districtCode 内容
   */
  searchItemCustomerImport: function searchItemCustomerImport(data) {
    return request.get(getUrl("/renewalPeriodConfig/getByDistrictCode", 1), {
      params: data
    });
  },
  /**
   * 续保配置--查询续保城市周期配置
   * @param {*} data 内容
   */
  searchRenewalConfig: function searchRenewalConfig(data) {
    return request.get(getUrl("/renewalPeriodConfig/searchRenewalConfig", 1), {
      params: data
    });
  },
  /**
   * 续保配置--新增续保城市周期配置
   * @param {*} data 内容
   */
  addRenewalConfig: function addRenewalConfig(data) {
    return request.post(getUrl("/renewalPeriodConfig/addRenewalConfig", 1), data);
  },
  /**
   * 续保配置--修改续保城市周期配置
   * @param {*} data 内容
   */
  updateRenewalConfig: function updateRenewalConfig(data) {
    return request.put(getUrl("/renewalPeriodConfig/updateRenewalConfig", 1), data);
  },
  /**
   * 续保配置--删除续保城市周期配置
   * @param {*} data 内容
   */
  deleteRenewalConfig: function deleteRenewalConfig(id) {
    return request.delete(getUrl("/renewalPeriodConfig/deleteRenewalConfig/".concat(id), 1));
  }
};
export default objApi;