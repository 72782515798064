var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-m-cls insurance-dialog", staticStyle: {} },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "500px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "div",
                {
                  staticClass: "search-box",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [
                  _c("div", [
                    _vm._v(" 数据刷新时间: " + _vm._s(_vm.modifiedTime) + " "),
                  ]),
                  _c(
                    "div",
                    { staticClass: "search-btn" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: { click: _vm.exportExcels },
                        },
                        [_vm._v("导出")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-table",
                {
                  ref: "multipleTable",
                  staticStyle: { width: "100%" },
                  attrs: {
                    size: "mini",
                    border: "",
                    data: _vm.tableData,
                    height: "380px",
                    "header-cell-style": { background: "#F7F7F7" },
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "orgCode",
                      label: "组织机构编码",
                      "min-width": "100",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "orgName",
                      label: "组织机构名称",
                      "min-width": "120",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "insCityName",
                      label: "市级名称",
                      "min-width": "120",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "insCorpName",
                      label: "保司名称",
                      "min-width": "120",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "statisticsDate",
                      label: "创建时间",
                      width: "150",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "cjVhcSeriesNo",
                      label: "车系编码",
                      "min-width": "100",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "newNarInsuredNum",
                      label: "新车投保数量",
                      "min-width": "100",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "renewalInsuredNum",
                      label: "续保投保数量",
                      "min-width": "100",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "littleOldCarInsuredNum",
                      label: "次新车投保数量",
                      "min-width": "120",
                      "show-overflow-tooltip": "",
                    },
                  }),
                ],
                1
              ),
              _c("pagination", {
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.pageNum,
                  limit: _vm.listQuery.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageNum", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageSize", $event)
                  },
                  pagination: function ($event) {
                    return _vm.loadData()
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: { click: _vm.closePage },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }