import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '../../../mixins/initHeight';
import dict from '../../../mixins/dict';
import Pagination from '@/components/Pagination';
import AddDialog from '../repairOrder/add';
import repairApi from '../../../api/repair';
export default {
  name: 'clueManage',
  components: {
    Pagination: Pagination,
    AddDialog: AddDialog
  },
  mixins: [initHeight, dict],
  computed: {},
  data: function data() {
    return {
      dialogType: '',
      showAddDialog: false,
      activeName: "all",
      drawer: false,
      searchForm: {},
      form: {},
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      defaultProps: {
        children: 'children',
        label: 'fullName'
      },
      tableData: [],
      multipleSelection: [],
      opType: '',
      startEndTime: null,
      loading: false,
      orgData: [],
      highlightCurrent: true,
      cluesId: ''
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.loadData();
  },
  methods: {
    reload: function reload() {},
    handleTabClick: function handleTabClick(tab, event) {
      //tab切换事件
      this.$forceUpdate();
    },
    add: function add(item) {
      // 创建维修工单
      this.showAddDialog = true;
      this.cluesId = item.cluesId.toString();
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {};
    },
    searchData: function searchData() {
      //搜索
      this.loadData();
    },
    loadData: function loadData() {
      var _this = this;
      //获取列表数据
      // this.loading = true;
      this.showAddDialog = false;
      this.form = this.listQuery;
      repairApi.getCluesList(this.form).then(function (res) {
        if (res.code == 200 && res.data && res.data.list.length != 0) {
          _this.tableData = res.data.list;
        }
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      //列表行选择事件
      this.multipleSelection = val;
    }
  }
};