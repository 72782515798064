var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.importLoading,
            expression: "importLoading",
          },
        ],
        staticClass: "app-m-cls",
        staticStyle: {},
      },
      [
        _c(
          "div",
          {
            ref: "header",
            staticClass: "header-cls",
            staticStyle: { "max-height": "500px", overflow: "auto" },
          },
          [
            _c("el-card", { attrs: { shadow: "never" } }, [
              _c(
                "div",
                [
                  _c(
                    "el-form",
                    {
                      ref: "detail",
                      attrs: {
                        model: _vm.uploadForm,
                        "label-width": "110px",
                        size: "mini",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "must-star",
                          attrs: { label: "经销商列表：" },
                        },
                        [
                          _c(
                            "el-upload",
                            {
                              ref: "upload",
                              attrs: {
                                "before-remove": function () {
                                  return (_vm.uploadForm.storageId = "")
                                },
                                limit: 1,
                                action: "",
                                "http-request": _vm.uploadFile,
                                "file-list": _vm.fileList,
                                accept: ".xlsx",
                                "list-type": "text",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                { attrs: { size: "mini", plain: "" } },
                                [_vm._v("选择文件")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ],
          1
        ),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "justify-content": "center",
              "margin-top": "8px",
            },
          },
          [
            _c(
              "el-button",
              {
                attrs: {
                  icon: "el-icon-download",
                  type: "primary",
                  plain: "",
                  size: "mini",
                },
                on: { click: _vm.download },
              },
              [_vm._v("下载模板")]
            ),
            _c(
              "el-button",
              {
                attrs: {
                  icon: "el-icon-upload2",
                  size: "mini",
                  type: "primary",
                  plain: "",
                  disabled: !_vm.uploadForm.storageId,
                },
                on: { click: _vm.importFile },
              },
              [_vm._v("导入")]
            ),
            _c(
              "el-button",
              {
                attrs: { icon: "el-icon-close", size: "mini" },
                on: { click: _vm.closePage },
              },
              [_vm._v("关闭")]
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }