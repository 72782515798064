import _createForOfIteratorHelper from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.array.push.js";
//
//
//
//

import carService from '@/api/carService';
export default {
  props: {
    carTypeValue: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    labelWidth: {
      type: String,
      default: '100%'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      carTypeVal: [],
      CarOptions: []
    };
  },
  watch: {
    carTypeValue: function carTypeValue(newVal, oldVal) {
      var _this = this;
      this.$nextTick(function () {
        _this.carTypeVal = newVal;
      });
    }
  },
  created: function created() {
    this.initData();
  },
  mounted: function mounted() {
    this.carTypeVal = this.carTypeValue;
  },
  methods: {
    CarHandleChange: function CarHandleChange(val) {
      if (val) {
        this.$emit('carHandleChanged', val, this.CarOptions);
      } else {
        this.$emit('carHandleChanged', [], this.CarOptions);
      }
      this.$forceUpdate();
    },
    initData: function initData() {
      this.getCarSeriesList();
    },
    // 获取品牌
    getCarBrandList: function getCarBrandList() {
      var _this2 = this;
      carService.brandList(1, 100).then(function (res) {
        if (res.code == 200 && res.data.list) {
          var brandLists = [];
          var _iterator = _createForOfIteratorHelper(res.data.list),
            _step;
          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var i = _step.value;
              brandLists.push({
                value: i.brandNo,
                label: i.brandName,
                children: []
              });
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }
          _this2.CarOptions = brandLists;
          _this2.getCarSeriesList();
        } else {
          _this2.CarOptions = [];
        }
      });
    },
    // 获取车系
    getCarSeriesList: function getCarSeriesList() {
      var _this3 = this;
      carService.getAllVehicle().then(function (res) {
        if (res.code == 200 && res.data) {
          var dataList = res.data ? _.cloneDeep(res.data) : [];
          // 车系匹配
          var _iterator2 = _createForOfIteratorHelper(dataList),
            _step2;
          try {
            for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
              var i = _step2.value;
              _this3.CarOptions.push({
                value: i.seriesNo,
                label: i.seriesName + '(' + i.seriesChineseName + ')',
                children: []
              });
            }
            // 车型匹配
          } catch (err) {
            _iterator2.e(err);
          } finally {
            _iterator2.f();
          }
          var _iterator3 = _createForOfIteratorHelper(_this3.CarOptions),
            _step3;
          try {
            for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
              var j = _step3.value;
              var _iterator4 = _createForOfIteratorHelper(dataList),
                _step4;
              try {
                for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
                  var _i = _step4.value;
                  if (j.value && j.value == _i.seriesNo) {
                    var _iterator5 = _createForOfIteratorHelper(_i.childrenList),
                      _step5;
                    try {
                      for (_iterator5.s(); !(_step5 = _iterator5.n()).done;) {
                        var l = _step5.value;
                        j.children.push({
                          value: l.modelNo,
                          label: l.gradecName
                        });
                      }
                    } catch (err) {
                      _iterator5.e(err);
                    } finally {
                      _iterator5.f();
                    }
                  }
                }
              } catch (err) {
                _iterator4.e(err);
              } finally {
                _iterator4.f();
              }
            }
          } catch (err) {
            _iterator3.e(err);
          } finally {
            _iterator3.f();
          }
        }
      });
    },
    // 获取车型
    getModelList: function getModelList() {
      var _this4 = this;
      carService.getList(1, 100000).then(function (res) {
        if (res.code == 200 && res.data.list) {
          var dataList = res.data.list ? res.data.list : [];
          for (var i = 0; i < dataList.length; i++) {
            for (var j = 0; j < _this4.CarOptions.length; j++) {
              if (dataList[i].brandNo == _this4.CarOptions[j].value) {
                for (var k = 0; k < _this4.CarOptions[j].children.length; k++) {
                  if (dataList[i].seriesNo == _this4.CarOptions[j].children[k].value) {
                    _this4.CarOptions[j].children[k].children.push({
                      value: dataList[i].modelNo,
                      label: dataList[i].modelName
                    });
                  }
                }
              }
            }
          }
        }
      });
    }
  }
};