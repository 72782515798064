var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.customerOperationLog.length
      ? _c(
          "div",
          [
            _c(
              "el-timeline",
              { staticClass: "remove-style" },
              _vm._l(_vm.customerOperationLog, function (item, index) {
                return _c(
                  "el-timeline-item",
                  {
                    key: index,
                    attrs: {
                      timestamp: item.createdTime || "",
                      color: "#2150A3",
                      placement: "top",
                    },
                  },
                  [
                    _c(
                      "el-card",
                      _vm._l(
                        item.behaviors,
                        function (behaviorsItem, behaviorsIndex) {
                          return _c("div", { key: behaviorsIndex }, [
                            _c(
                              "div",
                              {
                                staticStyle: { "font-weight": "700" },
                                attrs: { title: behaviorsItem.description },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(behaviorsItem.description) + " "
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "text-nowrap",
                                attrs: { title: behaviorsItem.description },
                              },
                              [
                                _vm._v(
                                  " 更新前：" +
                                    _vm._s(behaviorsItem.oldValue) +
                                    "， 更新后：" +
                                    _vm._s(behaviorsItem.newValue) +
                                    " "
                                ),
                              ]
                            ),
                          ])
                        }
                      ),
                      0
                    ),
                  ],
                  1
                )
              }),
              1
            ),
          ],
          1
        )
      : _c(
          "div",
          {
            staticStyle: {
              "line-height": "100px",
              "text-align": "center",
              color: "#909099",
            },
          },
          [_vm._v(" 暂无数据 ")]
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }