//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import requestApi from '@/api/interactPlatform';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    opType: {
      type: String,
      default: ''
    },
    dataConfigId: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      form: {
        dataSource: '',
        dataConfigName: '',
        dataConfigCode: ''
      },
      rules: {
        dataSource: {
          required: true,
          message: '请选择数据来源',
          trigger: "change"
        },
        dataConfigName: {
          required: true,
          message: '请输入数据名称',
          trigger: "blur"
        },
        dataConfigCode: {
          required: true,
          message: '请输入数据编码',
          trigger: "blur"
        }
      }
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
      ;
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    initData: function initData() {
      var _this = this;
      this.form = {
        dataSource: '',
        dataConfigName: '',
        dataConfigCode: ''
      };
      if (this.opType === "edit" || this.opType === 'copy') {
        requestApi.getData(this.dataConfigId).then(function (res) {
          if (res.code === 200) {
            _this.form = res.data;
          }
          ;
        });
      }
      ;
      this.$refs.detail.resetFields();
    },
    // 保存数据
    save: function save() {
      var _this2 = this;
      this.$refs.detail.validate(function (valid) {
        if (valid) {
          var data = {};
          if (_this2.opType == 'edit') {
            data = {
              dataConfigId: _this2.dataConfigId,
              dataConfigCode: _this2.form.dataConfigCode,
              dataConfigName: _this2.form.dataConfigName,
              dataSource: _this2.form.dataSource
            };
          }
          ;
          var req = _this2.opType === 'add' || _this2.opType === 'copy' ? requestApi.addData(_this2.form) : requestApi.updateData(data);
          req.then(function (res) {
            if (res.code === 200) {
              _this2.$message({
                message: res.msg,
                type: 'success'
              });
              _this2.closePage();
            }
            ;
          });
        }
        ;
      });
    },
    //关闭弹窗
    closePage: function closePage() {
      this.$emit('closePage');
    }
  }
};