import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import pagination from '@/components/Pagination';
import DatePicker from '@/components/DatePicker';
import leadManagementApi from '@/api/leadManagement';
import FollowUpDialog from './dialog/followUpDialog';
import archivesManagement from "@/api/archivesManagement";
import dictRequest from '@/api/dictionary';
import { character, commonExport, getBeforeDate, getCurrentDate, getSupplyers } from '@/utils/common';
export default {
  name: 'InsuranceFollowTask',
  mixins: [initHeight],
  components: {
    pagination: pagination,
    FollowUpDialog: FollowUpDialog,
    DatePicker: DatePicker
  },
  data: function data() {
    return {
      createdStartEndTime: [getBeforeDate(6), getCurrentDate(0)],
      drawer: false,
      searchForm: {
        customerTrackCode: "",
        ownerName: null,
        plateNo: null,
        vin: null,
        modelName: null,
        sellDate: null,
        commencementDateInsurance: null,
        expiryDateInsurance: null,
        daysInsuranceDue: null,
        daysInsuranceDueMin: null,
        daysInsuranceDueMax: null,
        startCreatedDate: "".concat(getBeforeDate(6), " 00:00:00"),
        endCreatedDate: "".concat(getCurrentDate(0), " 23:59:59")
      },
      customerTrackCode: 'ALL',
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      options: [{
        value: '选项1',
        label: '黄金糕'
      }, {
        value: '选项2',
        label: '双皮奶'
      }],
      tableData: [],
      showDialog: false,
      rulesList: [],
      customerItem: {},
      vehicleOptions: [],
      startEndTime: [],
      renewalTypeList: [],
      loading: false,
      supplyers: []
    };
  },
  created: function created() {
    //保司
    this.supplyers = getSupplyers();
    this.getVehicleModelList();
    this.loadData();
    this.getRenewalTypeList();
  },
  beforeUpdate: function beforeUpdate() {
    var _this = this;
    this.$nextTick(function () {
      //在数据加载完，重新渲染表格,防止切换标头抖动
      _this.$refs.multipleTable.doLayout();
      ;
    });
  },
  methods: {
    sortByDate: function sortByDate(val) {
      if (val) {
        if (val.order) {
          var fieldBy = val.prop;
          var orderBy = val.order == 'ascending' ? 'asc' : 'desc';
          this.searchForm.fieldBy = fieldBy;
          this.searchForm.orderBy = orderBy;
        } else {
          this.searchForm.fieldBy = null;
          this.searchForm.orderBy = null;
        }
        this.loadData();
      }
    },
    getRenewalTypeList: function getRenewalTypeList() {
      var _this2 = this;
      //获取续客户类别
      dictRequest.getDictItemsByCode("RENEWAL_TYPE").then(function (res) {
        if (res.data) {
          _this2.renewalTypeList = res.data;
        }
      });
    },
    breakData: function breakData() {
      // this.resetSearch()
      this.loadData();
    },
    handleTabClick: function handleTabClick(tab, event) {
      // tab切换事件
      // this.startEndTime = [],
      // this.searchForm = {}
      this.searchForm.customerTrackCode = tab.name !== 'ALL' ? tab.name : '';
      this.customerTrackCode = tab.name;
      this.listQuery = {
        pageNum: 1,
        pageSize: 20
      }, this.loadData();
    },
    // 获取所有车型列表
    getVehicleModelList: function getVehicleModelList() {
      var _this3 = this;
      archivesManagement.getVehicleModel().then(function (res) {
        if (res.code == 200) {
          _this3.vehicleOptions = res.data;
        } else {
          _this3.$message({
            type: "error",
            message: res.msg
          });
        }
      }).catch(function (err) {
        _this3.$message({
          type: "error",
          message: err.msg
        });
      });
    },
    // 获取列表数据
    loadData: function loadData() {
      var _this4 = this;
      this.drawer = false;
      this.showDialog = false;
      this.loading = true;
      if (this.startEndTime) {
        this.searchForm.commencementDateInsurance = this.startEndTime[0] && this.startEndTime[0].split(' ')[0] + ' 00:00:00' || null;
        this.searchForm.expiryDateInsurance = this.startEndTime[1] && this.startEndTime[1].split(' ')[0] + ' 23:59:59' || null;
      }
      if (this.createdStartEndTime) {
        this.searchForm.startCreatedDate = this.createdStartEndTime[0] && this.createdStartEndTime[0].split(' ')[0] + ' 00:00:00' || null;
        this.searchForm.endCreatedDate = this.createdStartEndTime[1] && this.createdStartEndTime[1].split(' ')[0] + ' 23:59:59' || null;
      }
      leadManagementApi.getTaskPoolList(this.listQuery.pageNum, this.listQuery.pageSize, this.searchForm).then(function (res) {
        _this4.loading = false;
        if (res.code === 200 && res.data) {
          _this4.tableData = res.data.customerCluesManageList.list;
          _this4.rulesList = res.data.marketingRuleClueList;
          _this4.total = res.data.customerCluesManageList.page.recordsTotal;
        }
      }).catch(function (err) {
        _this4.loading = false;
      });
    },
    inputvin: function inputvin(val) {
      //监听输入事件
      if (val) {
        val = val.replace(/i/g, '').replace(/I/g, '').replace(/o/g, '').replace(/O/g, '').replace(/q/g, '').replace(/Q/g, '');
        this.searchForm.vin = character(_.toUpper(val));
        this.$forceUpdate();
      }
    },
    //日期选择事件
    getPickersDate: function getPickersDate(name, val) {
      if (val && val.length > 1) {
        this[name] = val;
      } else {
        this[name] = [];
      }
    },
    resetSearch: function resetSearch() {
      // 重置搜索表单
      this.startEndTime = [];
      this.createdStartEndTime = [getBeforeDate(6), getCurrentDate(0)];
      this.searchForm = {
        customerTrackCode: this.searchForm.customerTrackCode,
        ownerName: null,
        plateNo: null,
        vin: null,
        modelName: null,
        sellDate: null,
        commencementDateInsurance: null,
        expiryDateInsurance: null,
        daysInsuranceDue: null,
        daysInsuranceDueMin: null,
        daysInsuranceDueMax: null,
        startCreatedDate: "".concat(getBeforeDate(6), " 00:00:00"),
        endCreatedDate: "".concat(getCurrentDate(0), " 23:59:59")
      };
    },
    searchData: function searchData() {
      // 搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      // 复选框数据
      console.log(val, 'val');
    },
    toView: function toView(row) {
      // 查看
      this.$router.push({
        path: '/cluesDetail',
        query: {
          customerId: row.customerId,
          plateNo: row.plateNo,
          vin: row.vin
        }
      });
    },
    exportFn: function exportFn() {
      // 导出
      var exporParams = {
        total: this.total,
        limitNum: 200000,
        fileName: '续保跟进任务池导出',
        exportUrl: '/vi-core-service/customer/clues/follow/list/exp',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    customerTracks: function customerTracks(row) {
      // 跟进
      this.showDialog = true;
      this.customerItem = row;
    },
    toQuote: function toQuote(item) {
      // 报价
      sessionStorage.removeItem("quoateForm");
      var router_path = this.$route.path;
      this.$router.push({
        path: "/proposal/add",
        query: {
          back: router_path,
          // plateNo: item.plateNo,
          // vin: item.vin,
          customerId: item.customerId
        }
      });
    }
  }
};