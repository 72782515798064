import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import Pagination from '@/components/Pagination';
import userCenterApi from '@/api/userCenter';
import NetChannelDialog from './dialog/netChannelDialog';
import { commonExport, getBeforeDate, getCurrentDate } from '@/utils/common';
export default {
  name: 'ChannelConfiguration',
  components: {
    Pagination: Pagination,
    NetChannelDialog: NetChannelDialog
  },
  mixins: [initHeight],
  data: function data() {
    return {
      searchForm: {
        createdTimeStart: "".concat(getBeforeDate(30), " 00:00:00"),
        createdTimeEnd: "".concat(getCurrentDate(), " 23:59:59")
      },
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      loading: false,
      drawer: false,
      showNetChannelDialog: false,
      multipleSelection: [],
      opType: '',
      currentId: '',
      startEndTime: [getBeforeDate(30), getCurrentDate()]
    };
  },
  created: function created() {
    this.loadData();
  },
  methods: {
    // 日期更改监听事件
    dateChange: function dateChange(start, end, val) {
      if (val && val.length > 1) {
        this.searchForm[start] = "".concat(val[0], " 00:00:00");
        this.searchForm[end] = "".concat(val[1], " 23:59:59");
      } else {
        this.searchForm[start] = null;
        this.searchForm[end] = null;
      }
      ;
    },
    // 监听输入事件
    inputCorpId: function inputCorpId(val) {
      this.searchForm.corpId = val.replace(/[^A-Za-z0-9_-]/g, '');
    },
    // 新增
    add: function add(type) {
      this.opType = type;
      this.showNetChannelDialog = true;
    },
    // 编辑
    edit: function edit(type, item) {
      this.opType = type;
      this.currentId = item.netChannelId.toString();
      this.showNetChannelDialog = true;
    },
    // 重置搜索表单
    resetSearch: function resetSearch() {
      this.searchForm = {
        createdTimeStart: "".concat(getBeforeDate(30), " 00:00:00"),
        createdTimeEnd: "".concat(getCurrentDate(), " 23:59:59")
      };
      this.startEndTime = [getBeforeDate(30), getCurrentDate()];
    },
    // 搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    // 刷新
    breakData: function breakData() {
      this.loadData();
    },
    // 弹窗关闭
    closePage: function closePage(val) {
      this.showNetChannelDialog = false;
      if (!val) {
        this.loadData();
      }
    },
    // 获取列表数据
    loadData: function loadData() {
      var _this = this;
      this.loading = true;
      this.drawer = false;
      var data = _objectSpread(_objectSpread({}, this.listQuery), this.searchForm);
      userCenterApi.getNetChannelList(data).then(function (res) {
        _this.loading = false;
        if (res.code === 200) {
          _this.tableData = res.data.list;
          _this.total = res.data.page.recordsTotal;
        }
        ;
      }).catch(function (err) {
        _this.loading = false;
      });
    },
    // 导出
    exportExcels: function exportExcels() {
      var exporParams = {
        total: this.total,
        fileName: '渠道配置',
        exportUrl: '/tp-core-service/netChannel/search',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    // 列表行选择事件
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 删除
    batchDelete: function batchDelete(item) {
      var _this2 = this;
      var ids = [];
      if (!_.isEmpty(item)) {
        ids.push(item.netChannelId);
      } else {
        if (!_.isEmpty(this.multipleSelection)) {
          ids = _.map(this.multipleSelection, 'netChannelId');
        }
        ;
      }
      ;
      this.$confirm('您确定要删除么？', {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        userCenterApi.deleteNetChannel(ids).then(function (res) {
          if (res.code === 200) {
            _this2.$message.success(res.msg);
            _this2.loadData();
          }
          ;
        });
      });
    }
  }
};