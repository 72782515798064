var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.formLoading,
          expression: "formLoading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: {},
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "500px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "addForm",
                  staticStyle: { "margin-bottom": "-20px" },
                  attrs: {
                    model: _vm.addForm,
                    rules: _vm.formRules,
                    "label-width": "90px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _vm.DialogType == "edit"
                        ? _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "应用ID:" } },
                                [_c("div", [_vm._v(_vm._s(_vm.addForm.appId))])]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.DialogType == "edit"
                        ? _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "应用密钥:" } },
                                [
                                  _c("div", [
                                    _vm._v(_vm._s(_vm.addForm.appSecret)),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "应用名称:", prop: "name" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入应用名称",
                                  maxlength: "40",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.addForm.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addForm, "name", $$v)
                                  },
                                  expression: "addForm.name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "是否启用:", prop: "enabled" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.addForm.enabled,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.addForm, "enabled", $$v)
                                    },
                                    expression: "addForm.enabled",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: true } }, [
                                    _vm._v("是"),
                                  ]),
                                  _c("el-radio", { attrs: { label: false } }, [
                                    _vm._v("否"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "是否加密:",
                                prop: "enabledEncrypt",
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.addForm.enabledEncrypt,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.addForm,
                                        "enabledEncrypt",
                                        $$v
                                      )
                                    },
                                    expression: "addForm.enabledEncrypt",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: true } }, [
                                    _vm._v("是"),
                                  ]),
                                  _c("el-radio", { attrs: { label: false } }, [
                                    _vm._v("否"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "是否监控:", prop: "monitor" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.addForm.monitor,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.addForm, "monitor", $$v)
                                    },
                                    expression: "addForm.monitor",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: true } }, [
                                    _vm._v("是"),
                                  ]),
                                  _c("el-radio", { attrs: { label: false } }, [
                                    _vm._v("否"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "签名版本号:",
                                prop: "signatureVersion",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: "请选择签名版本号",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.addForm.signatureVersion,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.addForm,
                                        "signatureVersion",
                                        $$v
                                      )
                                    },
                                    expression: "addForm.signatureVersion",
                                  },
                                },
                                _vm._l(
                                  _vm.signatureVersionList,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.itemName,
                                        value: item.itemCode,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c(
                          "div",
                          { staticStyle: { margin: "0 20px 20px" } },
                          [
                            _c(
                              "el-checkbox",
                              {
                                staticStyle: { "margin-bottom": "10px" },
                                on: { change: _vm.noLoginChange },
                                model: {
                                  value: _vm.addForm.noLogin,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addForm, "noLogin", $$v)
                                  },
                                  expression: "addForm.noLogin",
                                },
                              },
                              [_vm._v("允许不需要登陆就能直接访问的路由组")]
                            ),
                            _vm.addForm.noLogin
                              ? _c("el-transfer", {
                                  staticClass: "flexCenter",
                                  attrs: {
                                    filterable: "",
                                    titles: ["可选路由组", "已选路由组"],
                                    props: { key: "value", label: "desc" },
                                    data: _vm.routeGroupsData,
                                  },
                                  model: {
                                    value:
                                      _vm.addForm.noUserCheckApiGroupIdList,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.addForm,
                                        "noUserCheckApiGroupIdList",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "addForm.noUserCheckApiGroupIdList",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c(
                          "div",
                          { staticStyle: { margin: "0 20px 20px" } },
                          [
                            _c(
                              "el-checkbox",
                              {
                                staticStyle: { "margin-bottom": "10px" },
                                on: { change: _vm.loginFirstChange },
                                model: {
                                  value: _vm.addForm.loginFirst,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addForm, "loginFirst", $$v)
                                  },
                                  expression: "addForm.loginFirst",
                                },
                              },
                              [_vm._v("允许先登陆才能访问的路由组")]
                            ),
                            _vm.addForm.loginFirst
                              ? _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "登陆模式:" } },
                                          [
                                            _c(
                                              "el-radio-group",
                                              {
                                                on: {
                                                  change: _vm.bindUserChange,
                                                },
                                                model: {
                                                  value: _vm.addForm.bindUser,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.addForm,
                                                      "bindUser",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "addForm.bindUser",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-radio",
                                                  { attrs: { label: false } },
                                                  [_vm._v("多用户")]
                                                ),
                                                _c(
                                                  "el-radio",
                                                  { attrs: { label: true } },
                                                  [_vm._v("绑定单用户")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm.addForm.bindUser
                                      ? _c(
                                          "el-col",
                                          { attrs: { span: 12 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              { attrs: { label: "绑定账号:" } },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    placeholder:
                                                      "请输入绑定账号",
                                                    maxlength: "40",
                                                    clearable: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.addForm
                                                        .bindUserAccount,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.addForm,
                                                        "bindUserAccount",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "addForm.bindUserAccount",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.addForm.loginFirst
                              ? _c("el-transfer", {
                                  staticClass: "flexCenter",
                                  attrs: {
                                    filterable: "",
                                    titles: ["可选路由组", "已选路由组"],
                                    props: { key: "value", label: "desc" },
                                    data: _vm.routeGroupsData,
                                  },
                                  model: {
                                    value: _vm.addForm.apiGroupIdList,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.addForm,
                                        "apiGroupIdList",
                                        $$v
                                      )
                                    },
                                    expression: "addForm.apiGroupIdList",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "IP白名单:" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder:
                                    "只有白名单ip可以访问，默认不限制",
                                  type: "textarea",
                                  maxlength: "1000",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.addForm.whiteIpAddresses,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.addForm,
                                      "whiteIpAddresses",
                                      $$v
                                    )
                                  },
                                  expression: "addForm.whiteIpAddresses",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "域名白名单:" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder:
                                    "只有白名单域名可以访问，默认不限制",
                                  type: "textarea",
                                  maxlength: "1000",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.addForm.whiteDomains,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addForm, "whiteDomains", $$v)
                                  },
                                  expression: "addForm.whiteDomains",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "免签名域名:" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder:
                                    "这个域名来的请求不检查签名，默认全部检查签名",
                                  type: "textarea",
                                  maxlength: "1000",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.addForm.signFreeDomains,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.addForm,
                                      "signFreeDomains",
                                      $$v
                                    )
                                  },
                                  expression: "addForm.signFreeDomains",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-check",
                type: "primary",
                plain: "",
                size: "mini",
              },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: {
                click: function ($event) {
                  return _vm.closePage(true)
                },
              },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }