//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import provincesApi from '@/api/provinces';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    currentType: {
      type: String,
      default: ''
    },
    currentStatus: {
      type: String,
      default: ''
    },
    currentId: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      form: {
        code: '',
        name: '',
        parentId: '',
        type: '',
        ftmsCode: '',
        ftmsName: '',
        ftmsType: '',
        NoNeedProvinceId: ''
      },
      rules: {
        code: [{
          required: true,
          message: '请输入编码',
          trigger: 'blur'
        }],
        ftmsType: [{
          required: true,
          message: '请选择tact类型',
          trigger: 'change'
        }],
        ftmsCode: [{
          required: true,
          message: '请输入tact编码',
          trigger: 'blur'
        }],
        name: [{
          required: true,
          message: '请输入名称',
          trigger: 'blur'
        }],
        ftmsName: [{
          required: true,
          message: '请输入tact名称',
          trigger: 'blur'
        }],
        parentId: [{
          required: true,
          message: '请选择省',
          trigger: 'change'
        }],
        NoNeedProvinceId: [{
          required: true,
          message: '请选择省',
          trigger: 'change'
        }]
      },
      provinceOption: [],
      cityOption: []
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
      ;
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    // 初始化数据
    initData: function initData() {
      var _this = this;
      this.form = {
        code: '',
        name: '',
        parentId: '',
        type: '',
        ftmsCode: '',
        ftmsName: '',
        ftmsType: '',
        NoNeedProvinceId: ''
      };
      if (this.currentType !== 'PROVINCE') {
        this.fetchProvince('PROVINCE');
      }
      ;
      this.$refs.detail.resetFields();
      if (this.currentStatus === "add") {
        this.form.type = this.currentType;
        if (this.currentType === 'PROVINCE') {
          this.form.parentId = 0;
        } else {
          this.form.parentId = '';
        }
        ;
      } else {
        provincesApi.getYfDistrict(this.currentId).then(function (res) {
          if (res.code === 200) {
            // this.form = res.data;
            // if(this.currentType === 'PROVINCE') {
            //     this.form.NoNeedProvinceId = '';
            // } else if(this.currentType === 'CITY') {
            //     this.form.NoNeedProvinceId = '';
            // } else {
            //     this.form.NoNeedProvinceId = res.data.provinceId;
            //     this.fetchCity(res.data.provinceId);
            // };
            if (_this.currentType === 'PROVINCE') {
              _this.form = {
                code: res.data.provinceCode,
                name: res.data.provinceName,
                parentId: 0,
                NoNeedProvinceId: '',
                ftmsCode: res.data.ftmsprovinceCode,
                ftmsName: res.data.ftmsProvinceName,
                ftmsType: res.data.ftmsProvinceType
              };
            } else if (_this.currentType === 'CITY') {
              _this.form = {
                code: res.data.cityCode,
                name: res.data.cityName,
                parentId: res.data.provinceId,
                NoNeedProvinceId: '',
                ftmsCode: res.data.ftmsCityCode,
                ftmsName: res.data.ftmsCityName,
                ftmsType: res.data.ftmsCityType
              };
            } else {
              _this.form = {
                code: res.data.districtCode,
                name: res.data.districtName,
                parentId: res.data.cityId,
                NoNeedProvinceId: res.data.provinceId,
                ftmsCode: res.data.ftmsDistrictCode,
                ftmsName: res.data.ftmsDistrictName,
                ftmsType: res.data.ftmsDistrictType
              };
              _this.fetchCity(res.data.provinceId);
            }
            ;
            _this.form.type = _this.currentType;
          }
          ;
        });
      }
    },
    // 获取省级下拉框
    fetchProvince: function fetchProvince(type) {
      var _this2 = this;
      provincesApi.getYfProvincesAndCities(type).then(function (res) {
        if (res.code === 200) {
          _this2.provinceOption = res.data;
        }
        ;
      });
    },
    // 获取市级下拉框
    fetchCity: function fetchCity(id) {
      var _this3 = this;
      provincesApi.getYfSubCities(id).then(function (res) {
        if (res.code === 200) {
          _this3.cityOption = res.data;
        }
        ;
      });
    },
    //关闭弹窗
    closePage: function closePage() {
      this.$emit('closePage');
    },
    // 省级切换
    provinceChange: function provinceChange(val) {
      this.form.parentId = '';
      this.fetchCity(val);
    },
    // 保存
    save: function save() {
      var _this4 = this;
      //保存数据
      this.$refs.detail.validate(function (valid) {
        if (valid) {
          if (_this4.currentStatus === "add") {
            // 新增区域
            provincesApi.addYfDistrict(_this4.form).then(function (res) {
              if (res.code === 200) {
                _this4.$message.success(res.msg);
                _this4.closePage();
              }
              ;
            });
          } else {
            // 编辑区域
            provincesApi.editYfDistrict(_this4.currentId, _this4.form).then(function (res) {
              if (res.code === 200) {
                _this4.$message.success(res.msg);
                _this4.closePage();
              }
              ;
            });
          }
          ;
        }
        ;
      });
    }
  }
};