//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import reportRequest from '@/api/reportForm';
export default {
  props: {
    refresh: {
      type: Boolean
    },
    bizType: {
      type: String,
      default: ''
    },
    currentType: {
      type: String,
      default: ''
    },
    currentId: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      currentTypeArr: ['PROFIT', 'BALANCE', 'ORG'],
      tableData: [],
      loading: false
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    // 初始化数据
    initData: function initData() {
      this.loadData(this.currentType);
    },
    //获取列表数据
    loadData: function loadData(type) {
      var _this = this;
      this.loading = true;
      var request = '';
      switch (type) {
        // 利润表
        case "PROFIT":
          request = reportRequest.incomeStatementDetails(this.currentId);
          break;
        // 资产负债表
        case "BALANCE":
          request = reportRequest.assetsLiabilitiesDetails(this.currentId);
          break;
        // 机构情况表
        case "ORG":
          request = reportRequest.institutionalSituationDetails(this.currentId);
          break;
        // 产险业务表
        case "INSURANCE":
          request = reportRequest.productInsuranceDetails(this.currentId);
          break;
      }
      request.then(function (res) {
        _this.loading = false;
        if (res.code === 200) {
          _this.tableData = res.data || [];
        }
      }).catch(function (err) {
        _this.loading = false;
      });
    },
    // 关闭弹窗
    closePage: function closePage() {
      this.$emit("closePage");
    }
  }
};