import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import dict from '@/mixins/dict';
import initHeight from '@/mixins/initHeight';
import Pagination from '@/components/Pagination';
import recognitionApi from '@/api/recognition';
export default {
  mixins: [initHeight, dict],
  components: {
    Pagination: Pagination
  },
  props: {
    refresh: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      showConfigDictDialog: false,
      listLoading: false,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      form: {
        code: '',
        name: '',
        regular: '',
        checkStatus: false
      },
      total: 0,
      timeout1: null
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    AddClicked: function AddClicked() {
      this.form = {
        code: '',
        name: '',
        regular: '',
        checkStatus: false
      };
      this.showConfigDictDialog = true;
    },
    cancelClicked: function cancelClicked() {
      this.showConfigDictDialog = false;
    },
    getTemplatelist: function getTemplatelist() {
      var _this = this;
      recognitionApi.getConfigDictList().then(function (res) {
        console.log(res);
        if (res.code == 200) {
          _this.tableData = res.data ? res.data : [];
        }
      });
    },
    deleteConfigDict: function deleteConfigDict(index, row) {
      var _this2 = this;
      //删除配置字典
      this.$confirm('是否删除该数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        if (row.configId) {
          // this.tableData.splice(index, 1);
          recognitionApi.ConfigDictDel(row.configId).then(function (res) {
            if (res.code == 200) {
              _this2.$message({
                message: res.msg,
                type: 'success'
              });
              _this2.getTemplatelist();
            }
          });
        }
      }).catch(function () {});
      // if (row.configId) {
      //     this.tableData.splice(index, 1);
      //     recognitionApi.ConfigDictDel(row.configId).then(res => {
      //         if (res.code == 200) {
      //             this.$message({
      //                 message: res.msg,
      //                 type: 'success'
      //             });
      //         }
      //     })
      //     this.getTemplatelist()
      // } else {
      //     this.tableData.splice(index, 1);
      //     // this.getTemplatelist()
      // }
    },
    addConfigDict: function addConfigDict() {
      //添加配置字典
      this.tableData.push({
        code: '',
        name: '',
        regular: '',
        checkStatus: false
      });
    },
    initData: function initData() {
      //初始化数据
      console.log('initData');
      this.getTemplatelist();
    },
    loadData: function loadData() {//加载数据
    },
    saveClicked: function saveClicked() {
      var _this3 = this;
      console.log(this.form);
      // return
      if (this.form.configId) {
        recognitionApi.ConfigDictUpdate(this.form).then(function (res) {
          if (res.code == 200) {
            _this3.$message.success(res.msg);
            _this3.showConfigDictDialog = false;
            _this3.getTemplatelist();
          }
        });
      } else {
        recognitionApi.ConfigDictAdd(this.form).then(function (res) {
          if (res.code == 200) {
            _this3.$message.success(res.msg);
            _this3.showConfigDictDialog = false;
            _this3.getTemplatelist();
          }
        });
      }
    },
    saveConfigDict: function saveConfigDict(index, row) {
      // console.log('row', row)
      if (row.configId) {
        this.form = {
          configId: row.configId ? row.configId : '',
          code: row.code ? row.code : '',
          name: row.name ? row.name : '',
          regular: row.regular ? row.regular : '',
          checkStatus: row.checkStatus ? row.checkStatus : false
        };
        this.showConfigDictDialog = true;
      }
    },
    save: function save() {
      var _this4 = this;
      if (this.timeout1) {
        //防抖
        clearTimeout(this.timeout1);
      }
      this.timeout1 = setTimeout(function () {
        _this4.save1();
      }, 800);
    },
    save1: function save1() {
      var _this5 = this;
      //保存数据
      this.closePage();
      var configList = this.tableData;
      repairApi.addTemplate(configList).then(function (res) {
        if (res.code == 200) {
          _this5.$message({
            message: res.msg,
            type: 'success'
          });
        }
      });
    },
    closePage: function closePage() {
      //关闭弹窗
      this.$emit('closePage');
    }
  }
};