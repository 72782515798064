import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import customersRequest from '@/api/customers';
import dictRequest from '@/api/dictionary';
import leadManagementApi from '@/api/leadManagement';
import { getSupplyers, getAfterDate } from '@/utils/common';
import dayjs from 'dayjs';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    customerItem: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    customerId: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      pickerOptions: this.processMinDate(),
      isDisable: false,
      form: {
        trackClassify: 'TRACKING',
        trackStatusCode: '',
        trackStatusName: '',
        trackSubscribeTime: '',
        trackContent: '',
        insuredMethod: "0"
      },
      trackTimeRadio: '',
      rules: {
        trackClassify: {
          required: true,
          message: '请选择跟进分类',
          trigger: 'change'
        },
        trackStatusCode: {
          required: true,
          message: '请选择跟进状态',
          trigger: 'change'
        },
        trackSubscribeTime: {
          required: true,
          message: '请选择下次跟进日期',
          trigger: 'change'
        },
        insuredMethod: {
          required: true,
          message: '请选择出单方式',
          trigger: 'change'
        },
        insuredInsCorpCode: {
          required: true,
          message: '请选择保险公司',
          trigger: 'change'
        },
        bzEffectiveDateEnd: {
          required: true,
          message: '请选择交强险到期日',
          trigger: 'change'
        },
        bsEffectiveDateEnd: {
          required: true,
          message: '请选择商业险到期日',
          trigger: 'change'
        }
      },
      description: [{
        dictCode: "TRACKING",
        dictName: "跟进中"
      }, {
        dictCode: "SUCCEED",
        dictName: "成功出单"
      }, {
        dictCode: "DEFEAT",
        dictName: "战败"
      }, {
        dictCode: "INVALID",
        dictName: "无效"
      }],
      trackList: [],
      supplyers: [],
      followHistory: [],
      loading: false
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
    }
  },
  mounted: function mounted() {
    this.initData();
    this.supplyers = getSupplyers();
  },
  methods: {
    getHistoryData: function getHistoryData() {
      var _this = this;
      leadManagementApi.checkDetails(this.customerItem.customerId).then(function (res) {
        if (res.code === 200) {
          _this.followHistory = res.data.followHistory;
        }
      });
    },
    processMinDate: function processMinDate() {
      return {
        disabledDate: function disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      };
    },
    initData: function initData() {
      var boolean = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      var val = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
      this.form = {
        trackClassify: 'TRACKING',
        trackStatusName: '',
        trackStatusCode: '',
        trackSubscribeTime: '',
        trackContent: '',
        insuredMethod: "0",
        bsEffectiveDateEnd: "",
        bzEffectiveDateEnd: "",
        insuredInsCorpCode: ""
      };
      if (val == 'SUCCEED') {
        this.form.bsEffectiveDateEnd = this.customerItem.bzLastYearEndDate ? dayjs(this.customerItem.bzLastYearEndDate).add(1, "y").format("YYYY-MM-DD") : "";
        this.form.bzEffectiveDateEnd = this.customerItem.bsLastYearEndDate ? dayjs(this.customerItem.bsLastYearEndDate).add(1, "y").format("YYYY-MM-DD") : "";
      }
      this.trackTimeRadio = "";
      this.$refs.detail.resetFields();
      if (boolean) {
        this.trackList = [];
        this.followHistory = [];
        this.fetchDictItems(this.form.trackClassify);
        this.getHistoryData();
      }
    },
    fetchDictItems: function fetchDictItems(code) {
      var _this2 = this;
      this.loading = true;
      dictRequest.getDictItemsByCode(code).then(function (res) {
        _this2.loading = false;
        if (res.code === 200) {
          _this2.trackList = res.data;
        }
      }).catch(function (err) {
        _this2.loading = false;
      });
    },
    // 跟进分类
    handleTrack: function handleTrack(val) {
      this.trackList = [];
      this.initData(false, val);
      this.form.trackClassify = val;
      var enumList = {
        "SUCCEED": "TRACK_SUCCEED",
        "DEFEAT": "TRACK_DEFEAT",
        "INVALID": "TRACK_INVALID"
      };
      this.fetchDictItems(enumList[val] || val);
      if (this.form.trackClassify === 'INVALID') {
        this.form.trackStatusCode = 'TRACKINVALID';
      } else if (this.form.trackClassify === 'SUCCEED') {
        this.form.trackStatusCode = 'SUCCEED';
      }
      ;
    },
    timeChange: function timeChange(val) {
      if (!val) {
        this.trackTimeRadio = "";
      }
    },
    // 单选时间
    handldTrackTime: function handldTrackTime(val) {
      this.form.trackSubscribeTime = "";
      switch (val) {
        case "明天":
          this.form.trackSubscribeTime = getAfterDate(1);
          break;
        case "后天":
          this.form.trackSubscribeTime = getAfterDate(2);
          break;
        case "三天后":
          this.form.trackSubscribeTime = getAfterDate(3);
          break;
        case "五天后":
          this.form.trackSubscribeTime = getAfterDate(5);
          break;
      }
    },
    endble: function endble() {
      var _this3 = this;
      setTimeout(function () {
        _this3.isDisable = false;
      }, 1000);
    },
    // 保存数据
    save: function save() {
      var _this4 = this;
      this.$refs.detail.validate(function (valid) {
        if (valid) {
          _this4.isDisable = true;
          _this4.loading = true;
          _this4.endble();
          _this4.form.customerId = _this4.customerItem.customerId || _this4.customerId;
          var temp = _.find(_this4.trackList, function (item) {
            return item.itemCode === _this4.form.trackStatusCode;
          });
          _this4.form.trackStatusName = temp.itemName;
          _this4.form.trackStatusCode = temp.itemCode;
          _this4.form.trackSubscribeFlag = true;
          if (_this4.form.trackSubscribeTime) {
            _this4.form.trackSubscribeTime = _this4.form.trackSubscribeTime.split(" ")[0] + " 23:59:59";
          }
          customersRequest.addTracks(_this4.form).then(function (res) {
            _this4.loading = false;
            if (res.code === 200) {
              _this4.$message.success(res.msg);
              _this4.closePage(false);
            }
          }).catch(function (err) {
            _this4.loading = false;
            _this4.isDisable = false;
          });
        }
      });
    },
    //关闭弹窗
    closePage: function closePage() {
      var boolean = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      this.$emit('closePage', boolean);
    }
  }
};