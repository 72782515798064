import "core-js/modules/es.function.name.js";
//
//
//
//

import * as echarts from 'echarts';
require('echarts/theme/macarons'); // echarts theme
import resize from './mixins/resize';
import { getBeforeOfDate, getDayOfMonth, isLeapYear } from '@/utils/common';
export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '300px'
    },
    xAxis: {
      type: Array,
      default: []
    },
    series: {
      type: Array,
      default: function _default() {
        return [{
          name: "",
          data: []
        }];
      }
    }
  },
  data: function data() {
    return {
      chart: null
    };
  },
  watch: {
    series: {
      deep: true,
      handler: function handler(val) {
        this.setOptions(val);
      }
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      _this.initChart();
    });
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      this.chart = echarts.init(this.$el, 'macarons');
      this.setOptions();
    },
    setOptions: function setOptions() {
      var self = this;
      self.chart.setOption({
        tooltip: {
          trigger: 'axis',
          position: function position(p) {
            return [p[0] + 0, p[1] - 90];
          },
          confine: true,
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          },

          backgroundColor: 'rgb(0,0,0,0.5)',
          // 提示框浮层的背景颜色。
          borderColor: 'rgb(255,255,255,0.3)',
          // 提示框浮层的边框颜色。
          borderWidth: 0,
          // 提示框浮层的边框宽。
          textStyle: {
            // 提示框浮层的文本样式。
            color: '#fff'
          }
        },
        xAxis: {
          type: 'category',
          data: self.xAxis,
          boundaryGap: true,
          show: true,
          axisLabel: {
            show: true,
            textStyle: {
              color: '#ffffff'
            }
          }
        },
        yAxis: {
          type: 'value',
          minInterval: 1,
          show: true,
          splitArea: {
            show: false,
            areaStyle: {
              color: []
            }
          },
          axisLabel: {
            show: true,
            formatter: function formatter(value) {
              if (value < 10000) {
                //  if(value <= 0){
                return value;
                //  }else{
                //    return value + '次'
                //  }
              } else {
                return "".concat(value / 10000) + '万';
              }
            },
            textStyle: {
              color: '#ffffff'
            }
          },
          axisLine: {
            show: false // Y轴线
          },

          splitLine: {
            show: true,
            lineStyle: {
              color: ['rgb(255,255,255,0.15)'],
              type: "dashed",
              width: 1
            }
          }
        },
        grid: {
          left: 0,
          top: 20,
          right: 12,
          bottom: 40,
          containLabel: true
        },
        series: [{
          name: self.series[0].name || '',
          data: self.series[0].data || [],
          type: 'line',
          barWidth: 10,
          smooth: true,
          symbol: "none",
          itemStyle: {
            normal: {
              color: 'rgba(177,177,177,0.7)',
              lineStyle: {
                width: 2 //设置线条粗细
              }
            }
          }
        }]
      });
    }
  }
};