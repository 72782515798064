import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.join.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import UserCard from './components/UserCard';
import Activity from './components/Activity';
import Timeline from './components/Timeline';
import Account from './components/Account';
export default {
  name: 'Profile',
  components: {
    UserCard: UserCard,
    Activity: Activity,
    Timeline: Timeline,
    Account: Account
  },
  data: function data() {
    return {
      user: {},
      activeTab: 'activity'
    };
  },
  computed: _objectSpread({}, mapGetters(['name', 'avatar', 'roles'])),
  created: function created() {
    this.getUser();
  },
  methods: {
    getUser: function getUser() {
      this.user = {
        name: this.name,
        role: this.roles.join(' | '),
        email: 'admin@test.com',
        avatar: this.avatar
      };
    }
  }
};