var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.importLoading,
            expression: "importLoading",
          },
        ],
        staticClass: "app-m-cls",
        staticStyle: {},
      },
      [
        _c(
          "div",
          {
            ref: "header",
            staticClass: "header-cls",
            staticStyle: { "max-height": "500px", overflow: "auto" },
          },
          [
            _c("el-card", { attrs: { shadow: "never" } }, [
              _c(
                "div",
                [
                  _c(
                    "el-form",
                    {
                      ref: "detail",
                      attrs: {
                        rules: _vm.formRules,
                        model: _vm.uploadForm,
                        "label-width": "140px",
                        size: "mini",
                      },
                    },
                    [
                      _vm.bizType === "OEM"
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "所属机构：", prop: "orgCode" } },
                            [
                              _c("OemSelect", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  searchForm: _vm.orgForm,
                                  isGetUserList: true,
                                  isContainOem: false,
                                },
                                on: {
                                  getCurrentOrgUsers: _vm.getUsers,
                                  nodeClick: _vm.handleNodeClick,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.bizType === "DEALER"
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "所属机构：", prop: "orgCode" } },
                            [
                              _c("el-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.uploadForm.orgName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.uploadForm, "orgName", $$v)
                                  },
                                  expression: "uploadForm.orgName",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        { attrs: { label: "预设业务员：", prop: "salemanId" } },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "label" }, slot: "label" },
                            [
                              _c(
                                "el-tooltip",
                                { attrs: { placement: "top" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "content" },
                                      slot: "content",
                                    },
                                    [
                                      _vm._v(
                                        "预设目的是导入模板文件该字段为空或者不正确情况下使用预设值进行导入，否则以模板文件为准。"
                                      ),
                                    ]
                                  ),
                                  _c("i", {
                                    staticClass: "el-icon-warning-outline",
                                    staticStyle: { "font-size": "14px" },
                                  }),
                                ]
                              ),
                              _vm._v(" 预设业务员： "),
                            ],
                            1
                          ),
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                filterable: "",
                                placeholder: "请选择预设业务员",
                                clearable: "",
                              },
                              model: {
                                value: _vm.uploadForm.salemanId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.uploadForm, "salemanId", $$v)
                                },
                                expression: "uploadForm.salemanId",
                              },
                            },
                            _vm._l(_vm.users, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: {
                                  label: item.userName,
                                  value: item.userId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "预设投保城市：",
                            prop: "insCityCode",
                          },
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "label" }, slot: "label" },
                            [
                              _c(
                                "el-tooltip",
                                { attrs: { placement: "top" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "content" },
                                      slot: "content",
                                    },
                                    [
                                      _vm._v(
                                        "预设目的是导入模板文件该字段为空或者不正确情况下使用预设值进行导入，否则以模板文件为准。"
                                      ),
                                    ]
                                  ),
                                  _c("i", {
                                    staticClass: "el-icon-warning-outline",
                                    staticStyle: { "font-size": "14px" },
                                  }),
                                ]
                              ),
                              _vm._v(" 预设投保城市： "),
                            ],
                            1
                          ),
                          _c("el-cascader", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              placeholder: "请选择预设投保城市",
                              options: _vm.cityList,
                              clearable: "",
                              filterable: "",
                            },
                            model: {
                              value: _vm.uploadForm.insCityCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.uploadForm, "insCityCode", $$v)
                              },
                              expression: "uploadForm.insCityCode",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "预设保司：", prop: "insCorpCode" } },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "label" }, slot: "label" },
                            [
                              _c(
                                "el-tooltip",
                                { attrs: { placement: "top" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "content" },
                                      slot: "content",
                                    },
                                    [
                                      _vm._v(
                                        "预设目的是导入模板文件该字段为空或者不正确情况下使用预设值进行导入，否则以模板文件为准。"
                                      ),
                                    ]
                                  ),
                                  _c("i", {
                                    staticClass: "el-icon-warning-outline",
                                    staticStyle: { "font-size": "14px" },
                                  }),
                                ]
                              ),
                              _vm._v(" 预设保司： "),
                            ],
                            1
                          ),
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                filterable: "",
                                placeholder: "请选择预设保司",
                                clearable: "",
                              },
                              model: {
                                value: _vm.uploadForm.insCorpCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.uploadForm, "insCorpCode", $$v)
                                },
                                expression: "uploadForm.insCorpCode",
                              },
                            },
                            _vm._l(_vm.supplyers, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: {
                                  label: item.shortName,
                                  value: item.code,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "导入方案：",
                            prop: "importSchemaId",
                          },
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "label" }, slot: "label" },
                            [_vm._v(" 导入方案： ")]
                          ),
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                filterable: "",
                                placeholder: "请选择导入方案",
                                clearable: "",
                              },
                              model: {
                                value: _vm.uploadForm.importSchemaId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.uploadForm,
                                    "importSchemaId",
                                    $$v
                                  )
                                },
                                expression: "uploadForm.importSchemaId",
                              },
                            },
                            _vm._l(_vm.importSchemas, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: {
                                  label: item.templateName,
                                  value: item.templateId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "must-star",
                          attrs: { label: "文件上传：" },
                        },
                        [
                          _c(
                            "el-upload",
                            {
                              ref: "upload",
                              attrs: {
                                "before-remove": function () {
                                  return (_vm.uploadForm.fileId = "")
                                },
                                limit: 1,
                                action: "",
                                "http-request": _vm.uploadFile,
                                "file-list": _vm.fileList,
                                accept: ".xlsx",
                                "list-type": "text",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                { attrs: { size: "mini", plain: "" } },
                                [_vm._v("选择文件")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ],
          1
        ),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "justify-content": "center",
              "margin-top": "8px",
            },
          },
          [
            _c(
              "el-button",
              {
                attrs: {
                  icon: "el-icon-upload2",
                  size: "mini",
                  type: "primary",
                  disabled: _vm.disableSave,
                },
                on: { click: _vm.saveUpload },
              },
              [_vm._v("上传并导入")]
            ),
            _c(
              "el-button",
              {
                attrs: { icon: "el-icon-close", size: "mini" },
                on: { click: _vm.closePage },
              },
              [_vm._v("关闭")]
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }