import _defineProperty from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/defineProperty.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '../../mixins/initHeight';
import dict from '../../mixins/dict';
import Pagination from '@/components/Pagination';
import OrgSelect from '@/components/OrgSelect';
import { getCurrentDate, getAfterDate, getLastMonth, getUserOrgInfo } from '@/utils/common';
import dayjs from 'dayjs';
export default {
  name: 'settlementReport',
  components: {
    Pagination: Pagination,
    OrgSelect: OrgSelect
  },
  mixins: [initHeight, dict],
  computed: {},
  data: function data() {
    return _defineProperty({
      activeName: "all",
      drawer: false,
      searchForm: {
        timeSelect: "now",
        salesOrgCode: '',
        salesOrgName: ''
      },
      form: {},
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      defaultProps: {
        children: 'children',
        label: 'fullName'
      },
      value1: "",
      tableData: [],
      multipleSelection: [],
      opType: '',
      startEndTime: null,
      loading: false,
      orgData: [],
      highlightCurrent: true,
      isDataTime: true
    }, "startEndTime", [getCurrentDate(), getCurrentDate()]);
  },
  created: function created() {},
  mounted: function mounted() {
    var _orgInfo = getUserOrgInfo();
    if (_orgInfo && _orgInfo.organizationId) {
      this.searchForm.salesOrgCode = _orgInfo.orgCode;
      this.searchForm.salesOrgName = _orgInfo.fullName;
    }
    this.loadData();
  },
  methods: {
    handleNodeClick: function handleNodeClick(val) {
      this.loadData();
    },
    handleTabClick: function handleTabClick(tab, event) {
      //tab切换事件
      this.$forceUpdate();
    },
    add: function add(item) {// 创建维修工单
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {};
    },
    searchData: function searchData() {
      //搜索
      this.loadData();
    },
    loadData: function loadData() {
      //获取列表数据
      // this.loading = true;
      this.drawer = false;
    },
    handleSelectionChange: function handleSelectionChange(val) {
      //列表行选择事件
      this.multipleSelection = val;
    },
    dateChange: function dateChange(start, end, val) {
      //日期选择事件
      if (val && val.length > 1) {
        this.searchForm[start] = val[0];
        this.searchForm[end] = val[1];
      } else {
        this.searchForm[start] = null;
        this.searchForm[end] = null;
      }
    },
    handleTime: function handleTime(val) {
      switch (val) {
        case "now":
          this.startEndTime = [getCurrentDate(), getCurrentDate()];
          this.isDataTime = true;
          break;
        case "yesterday":
          this.startEndTime = [getAfterDate(-1), getAfterDate(-1)];
          this.isDataTime = true;
          break;
        case "sevenDays":
          this.startEndTime = [getAfterDate(-6), getCurrentDate()];
          this.isDataTime = true;
          break;
        case "thirtyDays":
          this.startEndTime = [getAfterDate(-29), getCurrentDate()];
          this.isDataTime = true;
          break;
        case "lastMonth":
          this.startEndTime = getLastMonth();
          this.isDataTime = true;
          break;
        case "currentMonth":
          var startDate = dayjs().startOf('M').format("YYYY-MM-DD");
          var endDate = dayjs().endOf('M').format("YYYY-MM-DD");
          this.startEndTime = [startDate, endDate];
          this.isDataTime = true;
          break;
        case "custom":
          this.isDataTime = false;
          this.$refs.input.focus();
          break;
      }
    }
  }
};