//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  // mixins: [dict],
  props: {
    refresh: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      form: {}
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    initData: function initData() {//初始化数据
    },
    save: function save() {
      //保存数据
      this.closePage();
    },
    closePage: function closePage() {
      //关闭弹窗
      this.$emit('closePage');
    }
  }
};