var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "app-m-cls" }, [
        _c(
          "div",
          { staticClass: "table-cls normal-btn-cls" },
          [
            _c(
              "el-drawer",
              {
                attrs: {
                  title: "",
                  visible: _vm.drawer,
                  modal: false,
                  "append-to-body": true,
                  size: "350px",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.drawer = $event
                  },
                },
              },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.searchForm,
                      "label-width": "110px",
                      size: "mini",
                    },
                  },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "保单类型" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      placeholder: "请选择保单类型",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.searchForm.riskType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "riskType",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.riskType",
                                    },
                                  },
                                  _vm._l(
                                    _vm.riskTypeList,
                                    function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.itemName,
                                          value: item.itemCode,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "被保人" } },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: "请输入被保人",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.searchForm.insurantName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "insurantName",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.insurantName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "保单号" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "请输入保单号",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.searchForm.policyNo,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchForm, "policyNo", $$v)
                                    },
                                    expression: "searchForm.policyNo",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c("el-col", { attrs: { span: 24 } }, [
                          _c(
                            "div",
                            { staticClass: "searchBtnStyle" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    icon: "el-icon-refresh-left",
                                  },
                                  on: { click: _vm.resetSearch },
                                },
                                [_vm._v("重置")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    type: "primary",
                                    icon: "el-icon-search",
                                  },
                                  on: { click: _vm.searchData },
                                },
                                [_vm._v("查询")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "table-cls normal-btn-cls" },
          [
            _c(
              "el-card",
              { staticClass: "box-card", attrs: { shadow: "never" } },
              [
                _c(
                  "div",
                  {
                    staticStyle: { padding: "10px", "font-size": "13px" },
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [
                    _c("span", [
                      _vm._v(
                        "结账批次单号：" +
                          _vm._s(_vm.reconciliationBatchNo) +
                          " "
                      ),
                    ]),
                    _c("span", { staticStyle: { "margin-left": "8px" } }, [
                      _vm._v(
                        "保单笔数：" +
                          _vm._s(_vm.platformPolicyQuantity) +
                          "笔 "
                      ),
                    ]),
                    _c("span", { staticStyle: { "margin-left": "8px" } }, [
                      _vm._v(
                        " 应收保费：" + _vm._s(_vm.platformTotalPremium) + "元 "
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  { staticStyle: { position: "relative" } },
                  [
                    _c(
                      "el-tabs",
                      {
                        on: { "tab-click": _vm.handleTabClick },
                        model: {
                          value: _vm.activeName,
                          callback: function ($$v) {
                            _vm.activeName = $$v
                          },
                          expression: "activeName",
                        },
                      },
                      [
                        _c(
                          "el-tab-pane",
                          { attrs: { label: "对账单", name: "bill" } },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  color: "#999",
                                  margin: "10px 0",
                                  "font-size": "13px",
                                },
                              },
                              [
                                _c("div", [
                                  _vm._v(
                                    "1：保险公司手续费均认为是“含税”费用；请检查和标记为“不含税”后重新对账"
                                  ),
                                ]),
                                _c("div", [
                                  _vm._v("2："),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "background-color": "#6699ff",
                                      },
                                    },
                                    [_vm._v("内")]
                                  ),
                                  _vm._v("标记平台内有外部导入无的单, "),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "background-color": "#00ce8b",
                                      },
                                    },
                                    [_vm._v("外")]
                                  ),
                                  _vm._v("标记平台内无外部导入有的单"),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "margin-bottom": "10px",
                                },
                              },
                              [
                                _c("div", [_vm._v("筛选：")]),
                                _c(
                                  "el-checkbox",
                                  {
                                    attrs: { label: "" },
                                    on: { change: _vm.loadData },
                                    model: {
                                      value: _vm.searchForm.integrity,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "integrity",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.integrity",
                                    },
                                  },
                                  [_vm._v("已匹配到得单")]
                                ),
                                _c(
                                  "el-checkbox",
                                  {
                                    attrs: { label: "" },
                                    on: { change: _vm.loadData },
                                    model: {
                                      value: _vm.searchForm.lackOuter,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "lackOuter",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.lackOuter",
                                    },
                                  },
                                  [_vm._v("平台有导入无")]
                                ),
                                _c(
                                  "el-checkbox",
                                  {
                                    attrs: { label: "" },
                                    on: { change: _vm.loadData },
                                    model: {
                                      value: _vm.searchForm.lackWithin,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "lackWithin",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.lackWithin",
                                    },
                                  },
                                  [_vm._v("平台无导入有")]
                                ),
                              ],
                              1
                            ),
                            _c("div", { staticStyle: { display: "flex" } }, [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "69%",
                                    "margin-right": "2%",
                                  },
                                },
                                [
                                  _c(
                                    "el-table",
                                    {
                                      directives: [
                                        {
                                          name: "loading",
                                          rawName: "v-loading",
                                          value: _vm.loading,
                                          expression: "loading",
                                        },
                                      ],
                                      ref: "multipleTable",
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        size: "mini",
                                        border: "",
                                        data: _vm.tableData,
                                        height: _vm.tableHeightL,
                                        "header-cell-style": {
                                          background: "#F7F7F7",
                                        },
                                        "highlight-current-row": "",
                                      },
                                      on: {
                                        "selection-change":
                                          _vm.handleSelectionChange,
                                      },
                                    },
                                    [
                                      _c("el-table-column", {
                                        attrs: {
                                          type: "selection",
                                          width: "55",
                                          align: "center",
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "policyNo",
                                          label: "保单号",
                                          "show-overflow-tooltip": "",
                                          fixed: "left",
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "riskTypeName",
                                          label: "保单类型",
                                          "show-overflow-tooltip": "",
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "plateNo",
                                          label: "车牌号",
                                          "show-overflow-tooltip": "",
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "insurantName",
                                          label: "被保人",
                                          "min-width": "100",
                                          "show-overflow-tooltip": "",
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "invoicePremium",
                                          label: "保司保费(元)",
                                          "min-width": "140",
                                          align: "right",
                                          "show-overflow-tooltip": "",
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "invoicePoundageAmount",
                                          label: "保司手续费(元)",
                                          "min-width": "150",
                                          align: "right",
                                          "show-overflow-tooltip": "",
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "invoicePoundageProportion",
                                          label: "保司手续费率(%)",
                                          "min-width": "150",
                                          align: "right",
                                          "show-overflow-tooltip": "",
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "platformPremium",
                                          label: "平台保费(元)",
                                          "min-width": "140",
                                          align: "right",
                                          "show-overflow-tooltip": "",
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "platformPoundageAmount",
                                          label: "平台手续费(元,含税)",
                                          "min-width": "140",
                                          align: "right",
                                          "show-overflow-tooltip": "",
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "platformPoundageAmountWithoutTax",
                                          label: "平台手续费(元,不含税)",
                                          "min-width": "160",
                                          align: "right",
                                          "show-overflow-tooltip": "",
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "platformPoundageProportion",
                                          label: "平台手续费率(%)",
                                          "min-width": "140",
                                          align: "right",
                                          "show-overflow-tooltip": "",
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "insuredTime",
                                          label: "承保日期",
                                          "min-width": "150",
                                          "show-overflow-tooltip": "",
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "remark",
                                          label: "备注",
                                          "min-width": "100",
                                          "show-overflow-tooltip": "",
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "操作",
                                          align: "center",
                                          width: "140",
                                          fixed: "right",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      size: "mini",
                                                      disabled:
                                                        _vm.reportStatus !=
                                                          "NOREPORT" &&
                                                        _vm.reportStatus !=
                                                          "REPORTFAIL",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.editDetail(
                                                          scope.row
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("修改")]
                                                ),
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: { size: "mini" },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.remarkDetail(
                                                          scope.row
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("备注")]
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("pagination", {
                                    attrs: {
                                      total: _vm.total,
                                      page: _vm.listQuery.pageNum,
                                      limit: _vm.listQuery.pageSize,
                                    },
                                    on: {
                                      "update:page": function ($event) {
                                        return _vm.$set(
                                          _vm.listQuery,
                                          "pageNum",
                                          $event
                                        )
                                      },
                                      "update:limit": function ($event) {
                                        return _vm.$set(
                                          _vm.listQuery,
                                          "pageSize",
                                          $event
                                        )
                                      },
                                      pagination: function ($event) {
                                        return _vm.loadData()
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  style: {
                                    height: _vm.tableHeightL,
                                    width: "29%",
                                    overflowY: "auto",
                                  },
                                },
                                [
                                  _vm.srcList.length
                                    ? _c(
                                        "div",
                                        { staticClass: "demo-image__preview" },
                                        _vm._l(
                                          _vm.form.files,
                                          function (item, index) {
                                            return _c("div", { key: index }, [
                                              _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "loading",
                                                      rawName: "v-loading",
                                                      value: _vm.srcList[index]
                                                        ? false
                                                        : true,
                                                      expression:
                                                        "srcList[index] ? false : true",
                                                    },
                                                  ],
                                                  staticClass: "image",
                                                  attrs: {
                                                    "element-loading-spinner":
                                                      "el-icon-loading",
                                                    "element-loading-background":
                                                      "rgba(0, 0, 0, 0.9)",
                                                  },
                                                },
                                                [
                                                  _c("el-image", {
                                                    staticStyle: {
                                                      width: "100%",
                                                    },
                                                    attrs: {
                                                      src: _vm.srcList[index]
                                                        ? _vm.srcList[index]
                                                        : "",
                                                      "preview-src-list":
                                                        _vm.srcList,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ])
                                          }
                                        ),
                                        0
                                      )
                                    : _c("div", { staticClass: "no-file" }, [
                                        _vm._v(" 无附件 "),
                                      ]),
                                ]
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "el-tab-pane",
                          { attrs: { label: "附件", name: "enclosure" } },
                          [
                            _c(
                              "div",
                              { style: { height: _vm.cardHeight } },
                              [
                                _c(
                                  "el-upload",
                                  {
                                    ref: "upload",
                                    attrs: {
                                      "before-remove": function () {
                                        return (_vm.formData = "")
                                      },
                                      action: "",
                                      "http-request": _vm.uploadFile,
                                      "file-list": _vm.fileList,
                                      accept: ".jpg,.gif,.png,.jpeg",
                                      "list-type": "text",
                                      "show-file-list": false,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "small",
                                          type: "primary",
                                        },
                                      },
                                      [_vm._v("上传图片")]
                                    ),
                                  ],
                                  1
                                ),
                                _vm.srcList.length
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "demo-image__preview img-box",
                                      },
                                      _vm._l(
                                        _vm.form.files,
                                        function (item, index) {
                                          return _c("div", { key: index }, [
                                            _c(
                                              "div",
                                              {
                                                directives: [
                                                  {
                                                    name: "loading",
                                                    rawName: "v-loading",
                                                    value: item.url
                                                      ? false
                                                      : true,
                                                    expression:
                                                      "item.url ? false : true",
                                                  },
                                                ],
                                                staticClass: "image",
                                                attrs: {
                                                  "element-loading-spinner":
                                                    "el-icon-loading",
                                                  "element-loading-background":
                                                    "rgba(0, 0, 0, 0.9)",
                                                },
                                              },
                                              [
                                                _c("el-image", {
                                                  staticStyle: {
                                                    height: "146px",
                                                    width: "146px",
                                                    margin: "0 10px 10px 0",
                                                  },
                                                  attrs: {
                                                    src: item.url,
                                                    "preview-src-list":
                                                      _vm.srcList,
                                                  },
                                                }),
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-close clear",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleDelete(
                                                        item.fileStorageId,
                                                        index
                                                      )
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ])
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticStyle: { "text-align": "center" } },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      size: "small",
                                      type: "primary",
                                      plain: "",
                                    },
                                    on: { click: _vm.save },
                                  },
                                  [_vm._v("保存")]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm.activeName !== "enclosure"
                      ? _c(
                          "div",
                          {
                            staticClass: "table-btn-cls",
                            staticStyle: {
                              position: "absolute",
                              right: "0",
                              top: "4px",
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: { click: _vm.breakData },
                              },
                              [_vm._v("刷新")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: {
                                  click: function ($event) {
                                    _vm.drawer = true
                                  },
                                },
                              },
                              [_vm._v("搜索")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showSignDialog,
            "close-on-click-modal": false,
            width: "540px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showSignDialog = $event
            },
            handleClose: function ($event) {
              _vm.showDistributionDialog = false
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("批量标记")]
              ),
            ]
          ),
          _c("div", { staticClass: "app-m-cls", staticStyle: {} }, [
            _c(
              "div",
              {
                ref: "header",
                staticClass: "header-cls",
                staticStyle: { "max-height": "500px", overflow: "auto" },
              },
              [
                _c(
                  "el-card",
                  { attrs: { shadow: "never" } },
                  [
                    _c("p", { staticStyle: { color: "#ccc" } }, [
                      _vm._v("标记保险公司手续费为："),
                    ]),
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.curSignData.taxInclusiveStatus,
                          callback: function ($$v) {
                            _vm.$set(_vm.curSignData, "taxInclusiveStatus", $$v)
                          },
                          expression: "curSignData.taxInclusiveStatus",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: "INCLUDE" } }, [
                          _vm._v("含税"),
                        ]),
                        _c("el-radio", { attrs: { label: "EXCLUDE" } }, [
                          _vm._v("不含税"),
                        ]),
                      ],
                      1
                    ),
                    _c("p", { staticStyle: { color: "#ccc" } }, [
                      _vm._v("确认更改后，系统将自动重新对账"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "justify-content": "center",
                  "margin-top": "8px",
                },
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      icon: "el-icon-check",
                      type: "primary",
                      plain: "",
                      size: "mini",
                    },
                    on: { click: _vm.saveSign },
                  },
                  [_vm._v("保存")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { icon: "el-icon-close", size: "mini" },
                    on: { click: _vm.loadData },
                  },
                  [_vm._v("关闭")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showRemarkDialog,
            "close-on-click-modal": false,
            width: "540px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showRemarkDialog = $event
            },
            handleClose: function ($event) {
              _vm.showDistributionDialog = false
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("备注")]
              ),
            ]
          ),
          _c("div", { staticClass: "app-m-cls", staticStyle: {} }, [
            _c(
              "div",
              {
                ref: "header",
                staticClass: "header-cls",
                staticStyle: { "max-height": "500px", overflow: "auto" },
              },
              [
                _c("el-input", {
                  attrs: {
                    type: "textarea",
                    placeholder: "请输入内容",
                    "show-word-limit": "",
                    resize: "none",
                    rows: "5",
                  },
                  model: {
                    value: _vm.markText,
                    callback: function ($$v) {
                      _vm.markText = $$v
                    },
                    expression: "markText",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "justify-content": "center",
                  "margin-top": "8px",
                },
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      icon: "el-icon-check",
                      type: "primary",
                      plain: "",
                      size: "mini",
                    },
                    on: { click: _vm.remarkFn },
                  },
                  [_vm._v("保存")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { icon: "el-icon-close", size: "mini" },
                    on: { click: _vm.loadData },
                  },
                  [_vm._v("关闭")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showEditDialog,
            "close-on-click-modal": false,
            width: "540px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showEditDialog = $event
            },
            handleClose: function ($event) {
              _vm.showEditDialog = false
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("修改数据")]
              ),
            ]
          ),
          _c("div", { staticClass: "app-m-cls", staticStyle: {} }, [
            _c(
              "div",
              {
                ref: "header",
                staticClass: "header-cls",
                staticStyle: { "max-height": "500px", overflow: "auto" },
              },
              [
                _c(
                  "el-card",
                  { attrs: { shadow: "never" } },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "form",
                        attrs: {
                          model: _vm.curEditData,
                          "label-width": "120px",
                          size: "mini",
                        },
                      },
                      [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 24 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "保单号:" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.curEditData.policyNo,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.curEditData,
                                            "policyNo",
                                            $$v
                                          )
                                        },
                                        expression: "curEditData.policyNo",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 24 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "车牌号:" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.curEditData.plateNo,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.curEditData,
                                            "plateNo",
                                            $$v
                                          )
                                        },
                                        expression: "curEditData.plateNo",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 24 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "被保人:" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.curEditData.insurantName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.curEditData,
                                            "insurantName",
                                            $$v
                                          )
                                        },
                                        expression: "curEditData.insurantName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 24 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "承保日期:" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.curEditData.insuredTime,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.curEditData,
                                            "insuredTime",
                                            $$v
                                          )
                                        },
                                        expression: "curEditData.insuredTime",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 24 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "保司保费(元):" } },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        maxlength: "40",
                                        type: "number",
                                        placeholder: "请输入保司保费(元)",
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.curEditData.invoicePremium,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.curEditData,
                                            "invoicePremium",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "curEditData.invoicePremium",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 24 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "保司手续费率(%):" } },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        maxlength: "40",
                                        type: "number",
                                        placeholder: "请输入保司手续费率(%)",
                                        clearable: "",
                                      },
                                      model: {
                                        value:
                                          _vm.curEditData
                                            .invoicePoundageProportion,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.curEditData,
                                            "invoicePoundageProportion",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "curEditData.invoicePoundageProportion",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 24 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "保司手续费(元):" } },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        maxlength: "40",
                                        type: "number",
                                        placeholder: "请输入保司手续费(元)",
                                        clearable: "",
                                      },
                                      model: {
                                        value:
                                          _vm.curEditData.invoicePoundageAmount,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.curEditData,
                                            "invoicePoundageAmount",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "curEditData.invoicePoundageAmount",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "justify-content": "center",
                  "margin-top": "8px",
                },
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      icon: "el-icon-check",
                      type: "primary",
                      plain: "",
                      size: "mini",
                    },
                    on: { click: _vm.saveEditData },
                  },
                  [_vm._v("保存")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { icon: "el-icon-close", size: "mini" },
                    on: { click: _vm.loadData },
                  },
                  [_vm._v("关闭")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }