var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.listLoading,
          expression: "listLoading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: {},
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "540px", overflow: "auto" },
        },
        [
          _c(
            "el-form",
            {
              ref: "detail",
              staticStyle: { "margin-bottom": "-20px" },
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "110px",
                size: "mini",
              },
            },
            [
              _c(
                "el-card",
                { attrs: { shadow: "never" } },
                [
                  _c("span", [_vm._v("人员基本信息")]),
                  _c(
                    "el-row",
                    { staticStyle: { "margin-top": "10px" } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 18 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "人员姓名：", prop: "userId" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: "请选择人员",
                                    disabled: _vm.opType == "edit",
                                  },
                                  model: {
                                    value: _vm.form.userId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "userId", $$v)
                                    },
                                    expression: "form.userId",
                                  },
                                },
                                _vm._l(_vm.selectOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: item.userName,
                                      value: item.userId,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-card", { attrs: { shadow: "never" } }, [
                _c(
                  "div",
                  [
                    _c("span", [_vm._v("本月薪酬信息")]),
                    _c("hr"),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "月份",
                                  prop: "remunerationDate",
                                },
                              },
                              [
                                _c("el-date-picker", {
                                  attrs: {
                                    type: "month",
                                    placeholder: "请选择月份",
                                    "value-format": "yyyy-MM",
                                  },
                                  model: {
                                    value: _vm.form.remunerationDate,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "remunerationDate",
                                        $$v
                                      )
                                    },
                                    expression: "form.remunerationDate",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "基本薪酬：",
                                  prop: "basicRemuneration",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "40",
                                    type: "number",
                                    placeholder: "请输入基本薪酬",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.form.basicRemuneration,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "basicRemuneration",
                                        _vm._n($$v)
                                      )
                                    },
                                    expression: "form.basicRemuneration",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            staticStyle: {
                              "line-height": "35px",
                              color: "#999",
                            },
                            attrs: { span: 4 },
                          },
                          [_c("span", [_vm._v("元")])]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "薪酬加扣：",
                                  prop: "rewardPunishAmount",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "10",
                                    type: "number",
                                    placeholder: "请输入",
                                    clearable: "",
                                  },
                                  on: { blur: _vm.outInput },
                                  model: {
                                    value: _vm.form.rewardPunishAmount,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "rewardPunishAmount",
                                        _vm._n($$v)
                                      )
                                    },
                                    expression: "form.rewardPunishAmount",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            staticStyle: {
                              "line-height": "35px",
                              color: "#999",
                            },
                            attrs: { span: 10 },
                          },
                          [
                            _c("span", [
                              _vm._v("元，正数为加薪酬,负数为扣薪酬"),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "本月奖金：", prop: "bonus" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "10",
                                    type: "number",
                                    placeholder: "请输入",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.form.bonus,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "bonus", _vm._n($$v))
                                    },
                                    expression: "form.bonus",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            staticStyle: {
                              "line-height": "35px",
                              color: "#999",
                            },
                            attrs: { span: 4 },
                          },
                          [_c("span", [_vm._v("元")])]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "本月薪酬：",
                                  prop: "currentMonthRemuneration",
                                },
                              },
                              [
                                _c("span", {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.currentMonthRemuneration
                                    ),
                                  },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-check",
                type: "primary",
                plain: "",
                size: "mini",
              },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: { click: _vm.closePage },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }