var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "verifyBox" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "mask",
      },
      [
        _c("div", {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: true,
              expression: "true",
            },
          ],
          staticStyle: { width: "100%", height: "100%" },
        }),
      ]
    ),
    _c(
      "div",
      { staticClass: "rowBox" },
      [
        _c(
          "el-row",
          { staticStyle: { width: "100%" } },
          [
            _c(
              "el-col",
              { attrs: { xs: 24, sm: 12, md: 10, lg: 8, xl: 6 } },
              [
                _c(
                  "el-card",
                  {
                    staticClass: "box-card",
                    staticStyle: {
                      "border-top": "0",
                      "border-left": "0",
                      "border-right": "0",
                    },
                  },
                  [
                    _c("div", { staticClass: "headerTitle" }, [
                      _vm._v(" 注销审核(" + _vm._s(_vm.approverName) + ") "),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _c(
              "el-col",
              { attrs: { xs: 23, sm: 12, md: 10, lg: 8, xl: 6 } },
              [
                _c("el-card", { staticClass: "box-card" }, [
                  _c("div", { staticClass: "itemText_header" }, [
                    _c("div", { staticClass: "itemTitle" }, [
                      _vm._v("注销信息"),
                    ]),
                  ]),
                  _c("div", { staticClass: "itemText" }, [
                    _c("div", { staticClass: "itemTitle" }, [
                      _vm._v("注销申请编号"),
                    ]),
                    _c("div", { staticClass: "itemContent" }, [
                      _vm._v(
                        _vm._s(
                          _vm.terminateInfo ? _vm.terminateInfo.cancelNo : "-"
                        )
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "itemText" }, [
                    _c("div", { staticClass: "itemTitle" }, [
                      _vm._v("申请时间"),
                    ]),
                    _c("div", { staticClass: "itemContent" }, [
                      _vm._v(
                        _vm._s(
                          _vm.terminateInfo && _vm.terminateInfo.cancelApplyTime
                        )
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "itemText" }, [
                    _c("div", { staticClass: "itemTitle" }, [_vm._v("审核人")]),
                    _c("div", { staticClass: "itemContent" }, [
                      _vm._v(_vm._s(_vm.approverName)),
                    ]),
                  ]),
                  _c("div", { staticClass: "itemText" }, [
                    _c("div", { staticClass: "itemTitle" }, [
                      _vm._v("审核机构"),
                    ]),
                    _vm.terminateInfo.supplierName
                      ? _c("div", { staticClass: "itemContent" }, [
                          _vm._v(_vm._s(_vm.terminateInfo.supplierName)),
                        ])
                      : _vm.terminateInfo.supplierCode
                      ? _c("div", { staticClass: "itemContent" }, [
                          _vm._v(
                            _vm._s(
                              _vm.filterSupplierCode(
                                _vm.terminateInfo.supplierCode
                              )
                            )
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _c("div", { staticClass: "itemText" }, [
                    _c("div", { staticClass: "itemTitle" }, [_vm._v("保单号")]),
                    _c("div", { staticClass: "itemContent" }, [
                      _vm._v(
                        _vm._s(
                          _vm.policyInfo.policyNo
                            ? _vm.policyInfo.policyNo
                            : "-"
                        )
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "itemText" }, [
                    _c("div", { staticClass: "itemTitle" }, [
                      _vm._v("服务销售日期"),
                    ]),
                    _c("div", { staticClass: "itemContent" }, [
                      _vm._v(
                        _vm._s(
                          _vm.policyInfo.orderSalesTime
                            ? _vm.policyInfo.orderSalesTime
                            : "-"
                        )
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "itemText" }, [
                    _c("div", { staticClass: "itemTitle" }, [
                      _vm._v("经销商全称"),
                    ]),
                    _c("div", { staticClass: "itemContent" }, [
                      _vm._v(_vm._s(_vm.policyInfo.salesOrgName || "-")),
                    ]),
                  ]),
                  _c("div", { staticClass: "itemText" }, [
                    _c("div", { staticClass: "itemTitle" }, [
                      _vm._v("服务产品类型"),
                    ]),
                    _c("div", { staticClass: "itemContent" }, [
                      _vm._v(_vm._s(_vm.terminateInfo.productClassName || "-")),
                    ]),
                  ]),
                ]),
              ],
              1
            ),
            _c(
              "el-col",
              { attrs: { xs: 23, sm: 12, md: 10, lg: 8, xl: 6 } },
              [
                _c("el-card", { staticClass: "box-card" }, [
                  _c("div", { staticClass: "itemText_header" }, [
                    _c("div", { staticClass: "itemTitle" }, [
                      _vm._v("车辆信息"),
                    ]),
                  ]),
                  _c("div", { staticClass: "itemText" }, [
                    _c("div", { staticClass: "itemTitle" }, [_vm._v("车牌号")]),
                    _c("div", { staticClass: "itemContent" }, [
                      _vm._v(
                        _vm._s(
                          _vm.policyInfo.targetPlateNo
                            ? _vm.policyInfo.targetPlateNo
                            : "-"
                        )
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "itemText" }, [
                    _c("div", { staticClass: "itemTitle" }, [_vm._v("车架号")]),
                    _c("div", { staticClass: "itemContent" }, [
                      _vm._v(
                        _vm._s(
                          _vm.policyInfo.targetVin
                            ? _vm.policyInfo.targetVin
                            : "-"
                        )
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "itemText" }, [
                    _c("div", { staticClass: "itemTitle" }, [
                      _vm._v("品牌车型"),
                    ]),
                    _c("div", { staticClass: "itemContent" }, [
                      _vm._v(
                        _vm._s(
                          _vm.policyInfo.targetVhcBrandName
                            ? _vm.policyInfo.targetVhcBrandName
                            : "-"
                        ) +
                          "/" +
                          _vm._s(
                            _vm.policyInfo.targetVhcSeriesName
                              ? _vm.policyInfo.targetVhcSeriesName
                              : "-"
                          )
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "itemText" }, [
                    _c("div", { staticClass: "itemTitle" }, [
                      _vm._v("使用性质"),
                    ]),
                    _c("div", { staticClass: "itemContent" }, [
                      _vm._v(
                        _vm._s(
                          _vm.policyInfo.targetUseAttributeDisplay
                            ? _vm.policyInfo.targetUseAttributeDisplay
                            : "-"
                        )
                      ),
                    ]),
                  ]),
                ]),
              ],
              1
            ),
            _c(
              "el-col",
              { attrs: { xs: 23, sm: 12, md: 10, lg: 8, xl: 6 } },
              [
                _c("el-card", { staticClass: "box-card" }, [
                  _c("div", { staticClass: "itemText_header" }, [
                    _c("div", { staticClass: "itemTitle" }, [
                      _vm._v("客户信息"),
                    ]),
                  ]),
                  _c("div", { staticClass: "itemText" }, [
                    _c("div", { staticClass: "itemTitle" }, [
                      _vm._v("客户姓名"),
                    ]),
                    _c("div", { staticClass: "itemContent" }, [
                      _vm._v(
                        _vm._s(
                          _vm.policyInfo.targetOwnerName
                            ? _vm.policyInfo.targetOwnerName
                            : "-"
                        )
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "itemText" }, [
                    _c("div", { staticClass: "itemTitle" }, [
                      _vm._v("证件类型"),
                    ]),
                    _c("div", { staticClass: "itemContent" }, [
                      _vm._v(
                        _vm._s(
                          _vm.policyInfo.targetOwnerCertificateTypeDisplay
                            ? _vm.policyInfo.targetOwnerCertificateTypeDisplay
                            : "-"
                        )
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "itemText" }, [
                    _c("div", { staticClass: "itemTitle" }, [
                      _vm._v("证件号码"),
                    ]),
                    _c("div", { staticClass: "itemContent" }, [
                      _vm._v(
                        _vm._s(
                          _vm.policyInfo.targetOwnerCertificateNo
                            ? _vm.policyInfo.targetOwnerCertificateNo
                            : "-"
                        )
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "itemText" }, [
                    _c("div", { staticClass: "itemTitle" }, [
                      _vm._v("所在省市"),
                    ]),
                    _c("div", { staticClass: "itemContent" }, [
                      _vm._v(
                        _vm._s(
                          _vm.policyInfo.targetOwnerProviceName
                            ? _vm.policyInfo.targetOwnerProviceName
                            : "-"
                        ) +
                          "/" +
                          _vm._s(
                            _vm.policyInfo.targetOwnerCityName
                              ? _vm.policyInfo.targetOwnerCityName
                              : "-"
                          )
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "itemText" }, [
                    _c("div", { staticClass: "itemTitle" }, [
                      _vm._v("联系电话"),
                    ]),
                    _c("div", { staticClass: "itemContent" }, [
                      _vm._v(
                        _vm._s(
                          _vm.policyInfo.targetOwnerMobile
                            ? _vm.policyInfo.targetOwnerMobile
                            : "-"
                        )
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "itemText" }, [
                    _c("div", { staticClass: "itemTitle" }, [
                      _vm._v("电子邮箱"),
                    ]),
                    _c("div", { staticClass: "itemContent" }, [
                      _vm._v(
                        _vm._s(
                          _vm.policyInfo.targetOwnerEmail
                            ? _vm.policyInfo.targetOwnerEmail
                            : "-"
                        )
                      ),
                    ]),
                  ]),
                ]),
              ],
              1
            ),
            _c(
              "el-col",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.policyFileList && _vm.policyFileList.length != 0,
                    expression: "policyFileList&&policyFileList.length != 0",
                  },
                ],
                attrs: { xs: 23, sm: 12, md: 10, lg: 8, xl: 6 },
              },
              [
                _c(
                  "el-card",
                  { staticClass: "box-card" },
                  [
                    _c("div", { staticClass: "itemText_header" }, [
                      _c("div", { staticClass: "itemTitle" }, [
                        _vm._v("注销情况说明"),
                      ]),
                    ]),
                    _vm._l(_vm.policyFileList, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "itemText",
                          staticStyle: { height: "120px" },
                        },
                        [
                          _vm.getFileSuffix(item.fileName)
                            ? _c("img", {
                                staticStyle: {
                                  width: "100px",
                                  height: "100px",
                                },
                                attrs: { src: item.fileUrl, alt: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.getImgUrl(item)
                                  },
                                },
                              })
                            : _c("span", [_vm._v(_vm._s(item.fileName))]),
                        ]
                      )
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showViewImg,
                    expression: "showViewImg",
                  },
                ],
                staticClass: "viewMaskImg",
                on: { click: _vm.closeViewImg },
              },
              [
                _c("img", { attrs: { src: _vm.ViewImgUrl, alt: "" } }),
                _c("span", { staticStyle: { "margin-top": "20px" } }, [
                  _vm._v("点击任意空白处即可关闭预览"),
                ]),
              ]
            ),
            _c(
              "el-col",
              { attrs: { xs: 23, sm: 12, md: 10, lg: 8, xl: 6 } },
              [
                _c("el-card", { staticClass: "box-card" }, [
                  _c("div", { staticClass: "itemText" }, [
                    _c("div", { staticClass: "itemTitle" }, [
                      _vm._v("注销原因"),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "itemContent",
                        staticStyle: { color: "#303133" },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.terminateInfo.cancelDesc
                              ? _vm.terminateInfo.cancelDesc
                              : ""
                          )
                        ),
                      ]
                    ),
                  ]),
                  _vm.policyAuditStatus && _vm.policyAuditStatus == "IAS01"
                    ? _c("div", { staticClass: "itemText" }, [
                        _c("div", { staticClass: "itemTitle" }, [
                          _vm._v("审核意见"),
                        ]),
                        _c(
                          "div",
                          { staticClass: "itemContent" },
                          [
                            _c(
                              "el-radio",
                              {
                                attrs: { label: "AT01" },
                                model: {
                                  value: _vm.auditType,
                                  callback: function ($$v) {
                                    _vm.auditType = $$v
                                  },
                                  expression: "auditType",
                                },
                              },
                              [_vm._v("通过")]
                            ),
                            _c(
                              "el-radio",
                              {
                                attrs: { label: "AT02" },
                                model: {
                                  value: _vm.auditType,
                                  callback: function ($$v) {
                                    _vm.auditType = $$v
                                  },
                                  expression: "auditType",
                                },
                              },
                              [_vm._v("不通过")]
                            ),
                          ],
                          1
                        ),
                      ])
                    : _c("div", { staticClass: "itemText" }, [
                        _c("div", { staticClass: "itemTitle" }, [
                          _vm._v("审核意见"),
                        ]),
                        _c("div", { staticClass: "itemContent" }, [
                          _vm._v(
                            " " + _vm._s(_vm.policyAuditStatusDisplay) + " "
                          ),
                        ]),
                      ]),
                  _c(
                    "div",
                    {
                      staticClass: "itemTextarea",
                      staticStyle: { "border-bottom": "0" },
                    },
                    [
                      _c("div", { staticClass: "itemTitle" }, [
                        _vm._v("审核备注"),
                      ]),
                      _c("div", { staticClass: "itemContent" }),
                    ]
                  ),
                  _c("div", { staticClass: "itemTextarea" }, [
                    _c(
                      "div",
                      {
                        staticClass: "itemContent",
                        staticStyle: { width: "100%" },
                      },
                      [
                        _vm.policyAuditStatus &&
                        _vm.policyAuditStatus == "IAS01"
                          ? _c("el-input", {
                              attrs: {
                                type: "textarea",
                                rows: 3,
                                "show-word-limit": "",
                                maxlength: "200",
                                placeholder: "请输入内容",
                              },
                              model: {
                                value: _vm.reason,
                                callback: function ($$v) {
                                  _vm.reason = $$v
                                },
                                expression: "reason",
                              },
                            })
                          : _c("el-input", {
                              attrs: {
                                type: "textarea",
                                disabled: "",
                                rows: 3,
                                "show-word-limit": "",
                                maxlength: "200",
                                placeholder: "请输入内容",
                              },
                              model: {
                                value: _vm.auditResult,
                                callback: function ($$v) {
                                  _vm.auditResult = $$v
                                },
                                expression: "auditResult",
                              },
                            }),
                      ],
                      1
                    ),
                  ]),
                  _vm.policyAuditStatus && _vm.policyAuditStatus == "IAS01"
                    ? _c(
                        "div",
                        { staticClass: "itemText_footer" },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "submitButton",
                              attrs: { size: "small", type: "primary" },
                              on: { click: _vm.submit },
                            },
                            [_vm._v("提交")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }