import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//

import resize from './mixins/resize';
import * as chinaJson from '@/assets/china.json';
import * as echarts from 'echarts';
require('echarts/theme/macarons'); // echarts theme
//34个省、市、自治区的名字拼音映射数组
var provinces = {
  //23个省
  "台湾": "taiwan",
  "河北": "hebei",
  "山西": "shanxi",
  "辽宁": "liaoning",
  "吉林": "jilin",
  "黑龙江": "heilongjiang",
  "江苏": "jiangsu",
  "浙江": "zhejiang",
  "安徽": "anhui",
  "福建": "fujian",
  "江西": "jiangxi",
  "山东": "shandong",
  "河南": "henan",
  "湖北": "hubei",
  "湖南": "hunan",
  "广东": "guangdong",
  "海南": "hainan",
  "四川": "sichuan",
  "贵州": "guizhou",
  "云南": "yunnan",
  "陕西": "shanxi1",
  "甘肃": "gansu",
  "青海": "qinghai",
  //5个自治区
  "新疆": "xinjiang",
  "广西": "guangxi",
  "内蒙古": "neimenggu",
  "宁夏": "ningxia",
  "西藏": "xizang",
  //4个直辖市
  "北京": "beijing",
  "天津": "tianjin",
  "上海": "shanghai",
  "重庆": "chongqing",
  //2个特别行政区
  "香港": "xianggang",
  "澳门": "aomen"
};
export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    id: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '200px'
    },
    height: {
      type: String,
      default: '200px'
    },
    chartData: {
      type: Array,
      default: []
    },
    seriesName: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    chartData: {
      handler: function handler(val) {
        //更新数据
        if (this.chart && this.currentCity) {
          var option = this.chart.getOption();
          option.series[0].data = val;
          this.chart.setOption(option);
        } else {
          this.drawChina('china', chinaJson.default);
        }
      },
      deep: true
    }
  },
  data: function data() {
    return {
      chart: '',
      isDrag: false,
      //拖拽
      currentCity: ''
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      _this.drawChina('china', chinaJson.default);
    });
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    drawChina: function drawChina(area, json) {
      //绘制地图
      var that = this;
      echarts.registerMap(area, json);
      var ele = document.getElementById(this.id);
      that.chart = echarts.init(ele);
      that.chart.showLoading();
      var option = {
        title: {
          text: '',
          subtext: '',
          left: 'center',
          textStyle: {
            color: 'red',
            fontSize: 20
          },
          subtextStyle: {
            fontSize: 15
          },
          top: 100
        },
        tooltip: {
          trigger: 'item',
          backgroundColor: 'rgb(0,0,0,0.5)',
          // 提示框浮层的背景颜色。
          borderColor: 'rgb(255,255,255,0.3)',
          // 提示框浮层的边框颜色。
          borderWidth: 0,
          // 提示框浮层的边框宽。
          textStyle: {
            // 提示框浮层的文本样式。
            color: '#fff'
          },
          formatter: function formatter(d) {
            var t = d.data.name + '</br>' + '总金额：' + (d.data.value[2] ? d.data.value[2].toLocaleString('zh', {
              style: 'currency',
              currency: 'CNY',
              minimumFractionDigits: 2
            }) : '0.00') + '</br>' + '出单量：' + (d.data.value[3] ? d.data.value[3].toLocaleString() : '0');
            return t;
          }
        },
        geo: {
          map: area,
          zoom: 1.1,
          //当前视角的缩放比例
          roam: true,
          //是否开启平游或缩放
          scaleLimit: {
            //滚轮缩放的极限控制
            min: 1,
            max: 10
          },
          label: {
            normal: {
              show: true,
              textStyle: {
                color: '#3ebee6' //省份字体颜色
              }
            },

            emphasis: {
              textStyle: {
                color: '#fff' //省份字体颜色
              }
            }
          },

          itemStyle: {
            normal: {
              areaColor: '#4278f136',
              // 配置地图背景为半透明蓝色
              borderColor: '#2150A3b3',
              //省与省之间的分割线颜色
              shadowColor: 'rgba(0, 0, 0, 0.8)',
              //
              borderWidth: '1' //
            },

            emphasis: {
              areaColor: '#6f561542',
              label: {
                textStyle: {
                  color: '#fff'
                }
              }
            }
          }
        },
        series: [{
          type: 'effectScatter',
          coordinateSystem: 'geo',
          data: [],
          symbolSize: function symbolSize(val) {
            if (val && val[3] > 0) {
              if (val[3] > 9) {
                return 9;
              } else {
                return val[3] < 5 ? 5 : val[3];
              }
            }
            return 5;
          },
          showEffectOn: 'render',
          rippleEffect: {
            brushType: 'stroke'
          },
          hoverAnimation: true,
          label: {
            normal: {
              color: '#f80',
              formatter: '{b}',
              position: 'right',
              show: false
            },
            emphasis: {
              show: true
            }
          },
          itemStyle: {
            normal: {
              color: '#46bee9'
            }
          }
          // zlevel: 1
        }]
      };

      option.series[0].data = that.chartData;
      that.chart.setOption(option, true);
      that.chart.hideLoading();
      that.chart.off("click"); //绑定点击事件前先关闭再开启，防止执行两次
      that.chart.on('click', function (params) {
        that.currentCity = params.name;
        if (params.componentSubType === 'effectScatter') {
          params.event.stop();
          return;
        }
        // that.chartData = [];
        that.switchMap(params.name);
        params.event.stop();
      });
      var offsetX, offsetY;
      that.chart.on('mousedown', function (params) {
        offsetX = params.event.offsetX;
        offsetY = params.event.offsetY;
      });
      that.chart.on('mouseup', function (params) {
        if (offsetX !== params.event.offsetX || offsetY !== params.event.offsetY) {
          that.isDrag = true;
        } else {
          that.isDrag = false;
        }
      });
    },
    mapClick: function mapClick() {
      this.isDrag = false;
    },
    switchMap: function switchMap(name) {
      var _this2 = this;
      //切换地图
      var self = this;
      if (provinces[name]) {
        require.ensure([], function () {
          var mod = require('../../../assets/province/' + provinces[name] + '.json');
          self.chart.dispose();
          //获取省份信息
          var province = _.find(chinaJson.features, function (item) {
            if (item.properties && item.properties.name == name) {
              return item;
            }
          });
          self.$emit('selectedCity', {
            name: _this2.currentCity,
            areaCode: province ? province.id : ''
          });
          self.drawChina(name, mod);
        });
      } else {
        this.chart.dispose();
        self.$emit('selectedCity', {
          name: '',
          areaCode: ''
        });
        this.drawChina('china', chinaJson.default);
      }
    }
  }
};