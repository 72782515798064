import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PageContainer from "@/components/PageContainer";
import FormLabel from "@/components/FormSubLabel";
import Rules from '@/utils/rules';
import initHeight from '@/mixins/initHeight';
import FollowUpDialog from './dialog/followUpDialog';
import leadManagementApi from '@/api/leadManagement';
import CustomerTracks from '@/views/customers/detail/customerTracks.vue';
import { character, idCardValidate, getSupplyers } from '@/utils/common';
export default {
  name: 'CluesDetail',
  mixins: [initHeight],
  components: {
    PageContainer: PageContainer,
    FormLabel: FormLabel,
    FollowUpDialog: FollowUpDialog,
    CustomerTracks: CustomerTracks
  },
  data: function data() {
    return {
      activeName1: '1',
      activeName2: '1',
      activeName3: '1',
      activeName4: '1',
      activeName5: '1',
      activeName6: '1',
      activeName7: '1',
      isNewCar: false,
      insuranceList: [],
      form: {
        risksData: {},
        vehicleOwner: {},
        vehicle: {},
        insuranceList: [],
        insuredList: [],
        proposal: [],
        followHistory: []
      },
      showDialog: false,
      VI_CERTIFICATE_TYPE: JSON.parse(localStorage.getItem('VI_CERTIFICATE_TYPE')) || [],
      USE_ATTRIBUTE: JSON.parse(localStorage.getItem("VI_USE_ATTRIBUTE")) || [],
      customerId: '',
      formRules: {
        ownerName: {
          required: true,
          message: '请输入车主名称',
          trigger: 'blur'
        },
        ownerMobile: {
          required: true,
          pattern: Rules['MOBILE_RULE'].value,
          message: '手机号格式有误',
          trigger: 'blur'
        },
        plateNo: {
          required: true,
          pattern: Rules["PLATENO_RULE"].value,
          message: '请输入车牌号',
          trigger: 'blur'
        },
        vin: [{
          required: true,
          pattern: Rules["VIN_RULE"].value,
          message: '请输入车架号',
          trigger: 'blur'
        }, {
          pattern: Rules["VIN_RULE"].value,
          message: "车架号格式有误",
          trigger: ["blur", "change"]
        }],
        certificateNo: [{
          required: true,
          message: '请输入证件号',
          trigger: 'blur'
        }, {
          pattern: Rules['ID_CARD'].value,
          message: "证件号格式有误",
          trigger: ["blur", "change"]
        }],
        bzLastYearEndDate: [{
          required: true,
          message: '请输入交强险到期日',
          trigger: 'blur'
        }],
        bsLastYearEndDate: [{
          required: true,
          message: '请输入商业险到期日',
          trigger: 'blur'
        }]
      },
      disabledLessThan: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > Date.now();
        }
      }
    };
  },
  watch: {
    '$route': function $route(to, from) {
      //监听路由是否变化）
      this.initView();
    }
  },
  created: function created() {
    this.initView();
  },
  methods: {
    initView: function initView() {
      this.customerId = this.$route.query.customerId ? this.$route.query.customerId.toString() : '';
      this.getDetails();
      this.getInsuranceData();
    },
    //获取保司列表
    getInsuranceData: function getInsuranceData() {
      this.insuranceList = getSupplyers();
    },
    // 未上牌勾选事件
    noPlateNoChange: function noPlateNoChange(val) {
      if (val) {
        this.form.vehicle.plateNo = '';
        this.formRules.plateNo.required = false;
      } else {
        this.formRules.plateNo.required = true;
      }
      this.$forceUpdate();
    },
    back: function back() {
      this.$router.back();
    },
    getDetails: function getDetails() {
      var _this = this;
      this.showDialog = false;
      leadManagementApi.checkDetails(this.customerId).then(function (res) {
        if (res.code === 200) {
          _this.form = res.data;
          _this.form.proposal = res.data.proposal ? [res.data.proposal] : [];
          if (_this.form.vehicle && _this.form.vehicle.plateNo && _this.form.vehicle.plateNo !== '未上牌') {
            _this.isNewCar = false;
            _this.formRules.plateNo.required = true;
          } else {
            _this.isNewCar = true;
            _this.form.vehicle.plateNo = '';
            _this.formRules.plateNo.required = false;
          }
        }
      });
    },
    // 车架号监听输入事件
    inputvin: function inputvin(val) {
      if (val) {
        val = val.replace(/i/g, '').replace(/I/g, '').replace(/o/g, '').replace(/O/g, '').replace(/q/g, '').replace(/Q/g, '');
        this.form.vehicle.vin = character(_.toUpper(val));
        this.$forceUpdate();
      }
    },
    // 发动机号自动转大写
    inputEngineNo: function inputEngineNo(val) {
      val = val.replace(/[^A-Za-z0-9-]/g, '');
      this.form.vehicle.engineNo = _.toUpper(val);
    },
    // 车牌号自动转大写
    inputPlateNo: function inputPlateNo(val) {
      if (val) {
        this.form.vehicle.plateNo = _.toUpper(val);
      }
    },
    // 跟进
    customerTracks: function customerTracks() {
      this.showDialog = true;
    },
    // 保费总计
    premiumTotal: function premiumTotal(type) {
      if (this.form.insuranceList.length) {
        var singleObj = this.form.insuranceList.find(function (val) {
          return val.riskType === type;
        });
        return singleObj ? singleObj.actualPremium : '';
      }
    },
    // 获取商业险的数据
    fetchBusinessRisks: function fetchBusinessRisks() {
      if (this.form.insuranceList.length) {
        var singleObj = this.form.insuranceList.find(function (val) {
          return val.riskType === 'BS';
        });
        return singleObj ? singleObj.risks : [];
      }
    },
    // 保险报价
    toQuote: function toQuote() {
      sessionStorage.removeItem("quoateForm");
      var router_path = this.$route.path;
      this.$router.push({
        path: "/proposal/add",
        query: {
          back: router_path,
          customerId: this.customerId
        }
      });
    },
    // 查看保险档案
    viewDetail: function viewDetail() {
      this.$router.push({
        path: '/ArchivesManagement/detail',
        query: {
          customerId: this.customerId
        }
      });
    },
    // 查看投保单详情
    historyViewDetail: function historyViewDetail(item) {
      var router_path = this.$route.path;
      this.$router.push({
        path: "/proposal/detail",
        query: {
          quoteId: item.quoteId,
          back: router_path
        }
      });
    },
    // 客户信息校验
    customerInformationValidate: function customerInformationValidate() {
      var flag = false;
      if (this.form.vehicleOwner.ownerName && !Rules["NAME"].value.test(this.form.vehicleOwner.ownerName)) {
        this.$message.warning('车主名称格式有误');
        flag = true;
        return flag;
      }
      if (this.form.vehicleOwner.ownerMobile && !Rules["MOBILE_RULE"].value.test(this.form.vehicleOwner.ownerMobile)) {
        this.$message.warning('手机号格式有误');
        flag = true;
        return flag;
      }
      return flag;
    },
    // 车辆信息校验
    vehicleInformationValidate: function vehicleInformationValidate() {
      var flag = false;
      if (this.form.vehicle.vin && !Rules["VIN_RULE"].value.test(this.form.vehicle.vin)) {
        this.$message.warning('车架号格式有误');
        flag = true;
        return flag;
      }
      if (this.form.vehicle.engineNo && !/^[a-zA-Z0-9\-\/\\]+$/g.test(this.form.vehicle.engineNo)) {
        this.$message.warning('发动机号格式有误');
        flag = true;
        return flag;
      }
      if (this.form.vehicle.plateNo && !Rules["PLATENO_RULE"].value.test(this.form.vehicle.plateNo)) {
        this.$message.warning('车牌号格式有误');
        flag = true;
        return flag;
      }
      return flag;
    },
    BsBzInsuranceValidate: function BsBzInsuranceValidate() {
      var flag = false;
      if (this.form.risksData.bsLastYearInsCorpName && this.form.risksData.bsLastYearEndDate == null) {
        this.$message.warning('请填写商业险到期日');
        flag = true;
        return flag;
      }
      if (this.form.risksData.bsLastYearEndDate && this.form.risksData.bsLastYearInsCorpName == "") {
        this.$message.warning('请填写商业险名称');
        flag = true;
        return flag;
      }
      if (this.form.risksData.bzLastYearInsCorpName && this.form.risksData.bzLastYearEndDate == null) {
        this.$message.warning('请填写交强险到期日');
        flag = true;
        return flag;
      }
      if (this.form.risksData.bzLastYearEndDate && this.form.risksData.bzLastYearInsCorpName == "") {
        this.$message.warning('请填写交强险名称');
        flag = true;
        return flag;
      }
      return flag;
    },
    // 投保线索校验
    forCluesValidate: function forCluesValidate() {
      var _this2 = this;
      var flag = false;
      this.form.insuredList.some(function (val, index) {
        if (val.name && !Rules["NAME"].value.test(val.name)) {
          _this2.$message.warning("".concat(val.relationshipFlagDisplay, "\u683C\u5F0F\u6709\u8BEF"));
          flag = true;
          return flag;
        }
        if (val.mobile && !Rules["MOBILE_RULE"].value.test(val.mobile)) {
          _this2.$message.warning("".concat(val.relationshipFlagDisplay, "\u624B\u673A\u53F7\u683C\u5F0F\u6709\u8BEF"));
          flag = true;
          return flag;
        }
        if ((val.certificateType === 'P01' || !val.certificateType) && val.certificateNo && !Rules['ID_CARD'].value.test(val.certificateNo)) {
          if (!idCardValidate(val.certificateNo)) {
            _this2.$message.warning("".concat(val.relationshipFlagDisplay, "\u8BC1\u4EF6\u53F7\u7801\u6709\u8BEF"));
            flag = true;
            return flag;
          }
        }
      });
      return flag;
    },
    save: function save(type) {
      var _this3 = this;
      if (type === 'customer') {
        // if (this.customerInformationValidate()) return;
        this.$refs['customerRef'].validate(function (valid, obj) {
          if (valid) {
            leadManagementApi.editOwnerInformation(_this3.form.vehicleOwner).then(function (res) {
              if (res.code === 200) {
                _this3.$message.success(res.msg);
                _this3.getDetails();
              }
            });
          }
        });
      } else if (type === 'car') {
        if (this.vehicleInformationValidate()) return;
        this.$refs['carRef'].validate(function (valid, obj) {
          if (valid) {
            var data = _objectSpread(_objectSpread({}, _this3.form.vehicle), {}, {
              customerId: _this3.customerId,
              useAttributeCode: _this3.form.vehicle.useAttributeType
            });
            leadManagementApi.editVehicleInformation(data).then(function (res) {
              if (res.code === 200) {
                _this3.$message.success(res.msg);
                _this3.getDetails();
              }
            });
          }
        });
      } else if (type === 'forClues') {
        if (this.forCluesValidate()) return;
        this.form.insuredList.forEach(function (val) {
          val.customerId = _this3.customerId;
        });
        leadManagementApi.editForClues(this.form.insuredList).then(function (res) {
          if (res.code === 200) {
            _this3.$message.success(res.msg);
            _this3.getDetails();
          }
        });
      } else if (type === 'insuranceLast') {
        if (this.BsBzInsuranceValidate()) return;
        var data = _objectSpread(_objectSpread({}, this.form.risksData), {}, {
          customerId: this.customerId
        });
        leadManagementApi.editInsuranceLast(data).then(function (res) {
          if (res.code === 200) {
            _this3.$message.success(res.msg);
            _this3.getDetails();
          }
        });
      }
    },
    inputCardNo: function inputCardNo(val) {
      if (val) {
        this.form.vehicleOwner.certificateNo = character(_.toUpper(val));
      }
    }
  }
};