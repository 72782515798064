import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import apiRequest from '@/api/economicEnd';
import training from '@/api/training'; //用于获取所有员工
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    opType: {
      type: String,
      default: {}
    },
    insCorpItem: {
      type: Object,
      default: {}
    },
    selectOptions: {
      type: Array,
      default: []
    }
  },
  data: function data() {
    return {
      options: [],
      fileList: [],
      formData: '',
      form: {
        rewardPunishAmount: 0,
        basicRemuneration: 0,
        bonus: 0
      },
      listLoading: false,
      rules: {
        userId: [{
          required: true,
          message: '请选择人员',
          trigger: ["change"]
        }],
        remunerationDate: [{
          required: true,
          message: '请选择',
          trigger: ["change"]
        }],
        rewardPunishAmount: [{
          required: true,
          message: '请输入',
          trigger: 'blur'
        }],
        basicRemuneration: [{
          required: true,
          message: '请输入',
          trigger: 'blur'
        }],
        bonus: [{
          required: true,
          message: '请输入',
          trigger: 'blur'
        }]
      }
    };
  },
  mounted: function mounted() {
    if (this.opType == 'add') {
      this.$refs['detail'].resetFields();
    }
    // this.getOrgCodeList()
  },

  computed: {
    currentMonthRemuneration: function currentMonthRemuneration() {
      return this.form.rewardPunishAmount + this.form.basicRemuneration + this.form.bonus;
    }
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        if (this.opType == 'add') {
          this.$refs['detail'].resetFields();
        }
        // console.log(newValue)
        // this.getOrgCodeList()
      }
    },

    insCorpItem: {
      handler: function handler(val, oldName) {
        // console.log(_.isEmpty(val))
        if (_.isEmpty(val)) {
          this.form = {
            userId: "",
            remunerationDate: "",
            rewardPunishAmount: 0,
            basicRemuneration: 0,
            bonus: 0
          };
        } else {
          this.form = JSON.parse(JSON.stringify(val));
          this.form.userId = parseInt(val.userId);
        }
      },
      immediate: true
    }
  },
  methods: {
    outInput: function outInput() {
      if (!this.form.rewardPunishAmount) {
        this.form.rewardPunishAmount = 0;
      }
    },
    save: function save() {
      var _this = this;
      //保存数据  
      this.$refs.detail.validate(function (valid) {
        if (valid) {
          _this.form.remunerationDate = _this.form.remunerationDate + "-01 00:00:00";
          _this.form.currentMonthRemuneration = _this.currentMonthRemuneration;
          _this.form.rewardPunishAmount = _this.form.rewardPunishAmount.toString();
          var apiFn = _this.opType == 'edit' ? apiRequest.update(_this.form) : apiRequest.add(_this.form);
          apiFn.then(function (res) {
            if (res.code == 200) {
              _this.$message.success(res.msg);
              _this.closePage();
            }
          });
        }
      });
    },
    closePage: function closePage() {
      //关闭弹窗
      this.$refs['detail'].resetFields();
      this.$emit('closePage');
    }
  }
};