import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import request from '@/api/message';
import storageRequest from '@/api/storage';
import userRequest from "@/api/user";
import { downFile } from '@/utils/common';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    msgAnnouncementStatus: {
      type: String,
      default: ''
    },
    currentData: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    opType: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      fileList: [],
      formLoading: false,
      uploadStatus: '',
      form: {
        category: 'SYSTEM_MESSAGE',
        content: '',
        status: 0,
        title: '',
        userId: '',
        file: []
      },
      rules: {
        title: [{
          required: true,
          message: '请输入标题',
          triannouncementStatuser: 'blur'
        }],
        content: [{
          required: true,
          message: '请输入消息内容',
          triannouncementStatuser: 'blur'
        }]
      }
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    //删除文件
    ondelete: function ondelete(index) {
      this.form.file.splice(index, 1);
      this.$refs['upload'].clearFiles();
    },
    //点击，使用id下载文件
    handle: function handle(row) {
      if (row.id) {
        downFile(row.fileUrl, row.fileName);
      }
    },
    //上传文件
    uploadFile: function uploadFile(param) {
      var _this = this;
      var formData = new FormData();
      formData.append('file', param.file);
      storageRequest.uploadFile(formData).then(function (res) {
        if (res.code === 200) {
          _this.$message.success(res.msg);
          _this.uploadStatus = 'SUCCESS';
        } else {
          _this.uploadStatus = 'UFAILURE';
        }
        var obj = {
          id: res.data.fileStorageId,
          name: param.file.name
        };
        _this.form.file.push(obj);
      });
    },
    initData: function initData() {
      this.form = {
        category: 'SYSTEM_MESSAGE',
        content: '',
        status: 0,
        title: '',
        userId: '',
        file: []
      };
      if (this.opType === "edit") {
        console.log(this.currentData, 'currentData');
        this.form = {
          title: this.currentData.messageTitle,
          messageRange: this.currentData.messageRange,
          messageCategory: this.currentData.messageCategory,
          content: this.currentData.messageContentTranslated,
          file: this.currentData.messageFile,
          messageBusinessGroup: this.currentData.messageBusinessGroup
        };
      }
      this.$refs.detail.resetFields();
    },
    save: function save() {
      var _this2 = this;
      //保存数据
      this.$refs.detail.validate(function (valid) {
        if (valid) {
          if (_this2.msgAnnouncementStatus == 'announcementStatus') {
            // if (_.isEmpty(this.form.file)) {
            //     this.$message({
            //         message: '请上传附件',
            //         type: 'warning'
            //     });
            //     return
            // }
            _this2.formLoading = true;
            var postData = {};
            postData = {
              messageTitle: _this2.form.title,
              messageRange: _this2.opType === "edit" ? _this2.form.messageRange : 'ALL',
              messageCategory: _this2.opType === "edit" ? _this2.form.messageCategory : "TEXT",
              messageContent: _this2.form.content,
              file: _this2.form.file,
              messageBusinessGroup: _this2.opType === "edit" ? _this2.form.messageBusinessGroup : 'NOTICE'
            };
            if (_this2.opType === "edit") {
              postData.messageId = _this2.currentData.messageId;
            }
            var req = _this2.opType === 'add' ? request.addMessageOnsite(postData) : request.updateMessageOnsite(postData);
            req.then(function (res) {
              _this2.formLoading = false;
              if (res.code === 200) {
                _this2.$message({
                  message: res.msg,
                  type: 'success'
                });
                setTimeout(function () {
                  userRequest.getReceivingNoticeData().then(function (res) {});
                }, 1000);
                _this2.closePage();
              }
            }).catch(function (err) {
              _this2.formLoading = false;
            });
          } else {
            _this2.closePage();
            return;
            // request.addSystemMessage(this.form).then(res => {
            //     this.formLoading = false;
            //     if (res.code === 200) {
            //         this.$message({
            //             message: res.msg,
            //             type: 'success'
            //         })
            //         this.closePage()
            //     }
            // }).catch(err => {
            //     this.formLoading = false;
            // })
          }
        }
      });
    },
    //关闭弹窗
    closePage: function closePage() {
      this.$emit('closePage');
    }
  }
};