//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '../../mixins/initHeight';
import Pagination from '@/components/Pagination';
import dict from '../../mixins/dict';
import MonitorDetailDialog from './dialog/monitorDetailDialog';
export default {
  name: 'InsChannelEvent',
  mixins: [initHeight, dict],
  components: {
    Pagination: Pagination,
    MonitorDetailDialog: MonitorDetailDialog
  },
  computed: {},
  data: function data() {
    return {
      activeName: "all",
      drawer: false,
      searchForm: {},
      form: {},
      tableData: [],
      multipleSelection: [],
      opType: '',
      startEndTime: [],
      loading: false,
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      showTackDetailDialog: false,
      showDialog: false
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.loadData();
  },
  methods: {
    orgHandle: function orgHandle() {
      //批次处理
      this.showDialog = true;
    },
    viewDetail: function viewDetail() {
      //详情
      this.showTackDetailDialog = true;
    },
    handleTabClick: function handleTabClick(tab, event) {//tab切换事件
    },
    dateChange: function dateChange(start, end, val) {
      //日期选择事件
      if (val && val.length > 1) {
        this.searchForm[start] = val[0];
        this.searchForm[end] = val[1];
      } else {
        this.searchForm.operateStartTime = null;
        this.searchForm.operateEndTime = null;
      }
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {};
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData(true);
    },
    loadData: function loadData(clickSearch) {
      //获取列表数据
      this.drawer = false;
      this.showTackDetailDialog = false;
      this.showDialog = false;
    },
    handleSelectionChange: function handleSelectionChange(val) {
      //列表行选择事件
      this.multipleSelection = val;
    }
  }
};