var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: { "max-height": "550px" },
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "500px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "detailForm",
                  attrs: { "label-width": "110px", size: "mini" },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "OCR功能名称：" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.details.ocrFunctionDisplay) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "OCR供应商：" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.details.ocrSupplierDisplay) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "图片链接：" } },
                            [_vm._v(" " + _vm._s(_vm.details.fileUrl) + " ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("el-form-item", { attrs: { label: "图片ID：" } }, [
                            _vm._v(" " + _vm._s(_vm.details.fileId) + " "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "调用时间：" } },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.details.invokeDatetime) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "识别结果：" } },
                            [
                              _c("pre", { staticClass: "errorStack" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.parseData(_vm.details.ocrParseResult)
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "调用返回内容：" } },
                            [
                              _c("pre", { staticClass: "errorStack" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.parseData(_vm.details.ocrInvokeResponse)
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: {
                click: function ($event) {
                  return _vm.closePage(true)
                },
              },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }