/**
 * 批量报价
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 列表
   * @param {*} pageNum 当前页
   */
  getList: function getList(data) {
    return request.get(getUrl("/batch/quote/search", 1), {
      params: data
    });
  },
  /**
   * 新增
   * @param {*} data 内容
   */
  createBatchQuote: function createBatchQuote(data) {
    return request.post(getUrl("/batch/quote/create", 1), data);
  },
  /**
   * 详情列表
   * @param {*} pageNum 当前页
   */
  getBatchDetailList: function getBatchDetailList(data) {
    return request.get(getUrl("/batch/quote/itemSearch", 1), {
      params: data
    });
  }
};
export default objApi;