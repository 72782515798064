import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.splice.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '../../mixins/initHeight';
import Pagination from '@/components/Pagination';
import request from '../../api/finance';
import dictRequest from '@/api/dictionary';
import storageRequest from '@/api/storage';
export default {
  name: "brokerageDetail",
  components: {
    Pagination: Pagination
  },
  mixins: [initHeight],
  computed: {
    cardHeight: function cardHeight() {
      return document.body.clientHeight - 270 + 'px';
    }
  },
  data: function data() {
    return {
      reconciliationBatchNo: 0,
      platformPolicyQuantity: 0,
      platformTotalPremium: 0,
      markText: "",
      checkOutScreen: [],
      checkOutList: [],
      activeName: "bill",
      drawer: false,
      searchForm: {
        isShowDifference: false,
        lackOuter: false,
        lackWithin: false,
        integrity: false
      },
      total: 0,
      listLoading: true,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      multipleSelection: [],
      loading: false,
      showSignDialog: false,
      dialogVisible: false,
      dialogImageUrl: '',
      riskTypeList: [],
      showRemarkDialog: false,
      reconciliationId: '',
      reportStatus: '',
      form: {
        files: [],
        reconciliationId: ''
      },
      srcList: [],
      fileList: [],
      fileUrlList: [],
      showEditDialog: false,
      curEditData: {},
      curSignData: {
        ids: [],
        taxInclusiveStatus: 'INCLUDE'
      }
    };
  },
  watch: {
    '$route': function $route(to, from) {
      //监听路由是否变化）
      this.initView();
    }
  },
  created: function created() {},
  mounted: function mounted() {
    this.initView();
  },
  methods: {
    initView: function initView() {
      var _this = this;
      //获取险种类型
      dictRequest.getDictItemsByCode("RISK_TYPE").then(function (res) {
        if (res.data) {
          _this.riskTypeList = res.data;
        }
      });
      this.reconciliationId = this.$route.query.reconciliationId;
      this.reportStatus = this.$route.query.reportStatus;
      this.searchForm.reconciliationId = this.$route.query.reconciliationId;
      this.loadData();
    },
    handlePictureCardPreview: function handlePictureCardPreview(file) {
      this.srcList = [];
      this.dialogImageUrl = file.url;
      this.srcList.push(file.url);
      this.dialogVisible = true;
    },
    allSign: function allSign(item) {
      //全部标记
      this.showSignDialog = true;
      this.reconciliationId = item.reconciliationDetailsId;
    },
    saveSign: function saveSign() {
      var _this2 = this;
      //标记
      this.curSignData.ids = [this.reconciliationId];
      request.batchMarkTax(this.curSignData).then(function (res) {
        if (res.code == 200) {
          _this2.$message.success(res.msg);
          _this2.showSignDialog = false;
          _this2.loadData();
        }
      });
    },
    save: function save() {
      var _this3 = this;
      //保存
      this.form.reconciliationId = this.reconciliationId;
      request.saveFiles(this.form).then(function (res) {
        if (res.code == 200) {
          _this3.$message.success(res.msg);
        }
      });
    },
    handleRemove: function handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handleTabClick: function handleTabClick(tab, event) {
      //tab切换事件
      this.searchData();
    },
    // 修改
    editDetail: function editDetail(item) {
      this.showEditDialog = true;
      this.curEditData = _.cloneDeep(item);
    },
    saveEditData: function saveEditData() {
      var _this4 = this;
      request.updateRate(this.curEditData).then(function (res) {
        if (res.code == 200) {
          _this4.$message.success(res.msg);
          _this4.showEditDialog = false;
          _this4.loadData();
        }
      });
    },
    remarkDetail: function remarkDetail(item) {
      this.showRemarkDialog = true;
      this.reconciliationDetailsId = item.reconciliationDetailsId;
      this.markText = item.remark;
    },
    remarkFn: function remarkFn() {
      var _this5 = this;
      var postData = {
        reconciliationDetailsId: this.reconciliationDetailsId,
        remark: this.markText
      };
      request.updateRemark(postData).then(function (res) {
        if (res.code == 200) {
          _this5.showRemarkDialog = false;
          _this5.loadData();
        }
      }).catch(function (err) {});
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {
        isShowDifference: false,
        lackOuter: false,
        lackWithin: false,
        integrity: false,
        reconciliationId: this.reconciliationId
      };
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    // 刷新
    breakData: function breakData() {
      this.loadData();
    },
    loadData: function loadData() {
      var _this6 = this;
      //获取列表数据
      this.loading = true;
      this.drawer = false;
      this.showRemarkDialog = false;
      this.showSignDialog = false;
      this.showEditDialog = false;
      this.markText = '';
      this.form.files = [];
      this.srcList = [];
      var postData = {};
      postData = Object.assign(postData, this.searchForm, this.listQuery);
      request.searchDetails(postData).then(function (res) {
        _this6.loading = false;
        _this6.tableData = res.data.detailsInfo.list;
        //总条数
        _this6.total = res.data.detailsInfo.page.recordsTotal;
        _this6.reconciliationBatchNo = res.data.reconciliationBatchNo;
        _this6.platformPolicyQuantity = res.data.platformPolicyQuantity;
        _this6.platformTotalPremium = res.data.platformTotalPremium;
        res.data.files.forEach(function (val) {
          _this6.downloadFile({
            fileStorageId: val.fileStorageId,
            fileName: val.fileName,
            fileUrl: val.fileUrl
          });
        });
      }).catch(function (err) {
        _this6.loading = false;
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      //列表行选择事件
      this.multipleSelection = val;
    },
    // 上传图片
    uploadFile: function uploadFile(param) {
      var _this7 = this;
      var formData = new FormData();
      formData.append('file', param.file);
      console.log(param, 'param');
      storageRequest.uploadFile(formData).then(function (res) {
        if (res.code === 200 && res.data) {
          _this7.$message.success(res.msg);
          setTimeout(function () {
            var obj = {
              fileStorageId: res.data.fileStorageId,
              fileName: param.file.name,
              fileUrl: res.data.fileUrl
            };
            _this7.downloadFile(obj);
          }, 1000);
        }
        ;
      });
    },
    downloadFile: function downloadFile(obj) {
      if (!obj.fileStorageId) return;
      this.viewUrl = obj.fileUrl;
      obj.url = this.viewUrl;
      this.form.files.push(obj);
      this.srcList = this.form.files.map(function (item) {
        return item.url;
      });
    },
    //文件删除
    handleDelete: function handleDelete(item, index) {
      var j = this.form.files.findIndex(function (val) {
        return item == val.fileStorageId;
      });
      this.form.files.splice(j, 1);
      if (this.form.files.length) {
        this.srcList = this.form.files.map(function (item) {
          return item.url;
        });
      } else {
        this.srcList = [];
      }
      ;
    }
  }
};