var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.formLoading,
          expression: "formLoading",
        },
      ],
      staticClass: "app-m-cls clues-distribution",
      staticStyle: {},
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "min-height": "150px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "detail",
                  staticStyle: { "margin-bottom": "-20px" },
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-width": "110px",
                    size: "mini",
                  },
                },
                [
                  _vm.bizType === "OEM"
                    ? _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "组织机构" } },
                                [
                                  _c("OemSelect", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      searchForm: _vm.orgForm,
                                      isGetUserList: true,
                                      isContainOem: false,
                                    },
                                    on: {
                                      getCurrentOrgUsers: _vm.getUsers,
                                      nodeClick: _vm.nodeClick,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.bizType === "DEALER"
                    ? _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "组织机构" } },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: { disabled: "" },
                                    model: {
                                      value: _vm.form.salesOrgName,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "salesOrgName", $$v)
                                      },
                                      expression: "form.salesOrgName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "续保专员：",
                                prop: "salemanName",
                              },
                            },
                            [
                              _vm.opType
                                ? _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        filterable: "",
                                        multiple: "",
                                        placeholder: "请添加续保专员",
                                        clearable: "",
                                      },
                                      on: {
                                        change: function (val) {
                                          return _vm.salemanNameChange(
                                            val,
                                            true
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.form.salemanName,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "salemanName", $$v)
                                        },
                                        expression: "form.salemanName",
                                      },
                                    },
                                    _vm._l(_vm.users, function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.userName,
                                          value: item.userId,
                                        },
                                      })
                                    }),
                                    1
                                  )
                                : _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        filterable: "",
                                        placeholder: "请添加续保专员",
                                        clearable: "",
                                      },
                                      on: {
                                        change: function (val) {
                                          return _vm.salemanNameChange(
                                            val,
                                            false
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.form.salemanName,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "salemanName", $$v)
                                        },
                                        expression: "form.salemanName",
                                      },
                                    },
                                    _vm._l(_vm.users, function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.userName,
                                          value: item.userId,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _vm.opType
                            ? _c("el-form-item", { attrs: { label: "" } }, [
                                _vm._v(
                                  " 共选择了 " +
                                    _vm._s(_vm.customerIdArr.length) +
                                    " 条客户信息 "
                                ),
                              ])
                            : _c("el-form-item", { attrs: { label: "" } }, [
                                _vm._v(" 共选择了 1 条客户信息 "),
                              ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.form.salemanName.length !== 0 &&
                  _vm.form.distributionType === "CUSTOM"
                    ? _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            {
                              staticStyle: { height: "auto" },
                              attrs: { span: 24 },
                            },
                            _vm._l(_vm.form.userList, function (item, index) {
                              return _c(
                                "el-form-item",
                                {
                                  key: index,
                                  staticClass: "must-star",
                                  attrs: { label: "分配给：" },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: {
                                      width: "40%",
                                      "margin-right": "10px",
                                    },
                                    attrs: { disabled: "" },
                                    model: {
                                      value: item.name,
                                      callback: function ($$v) {
                                        _vm.$set(item, "name", $$v)
                                      },
                                      expression: "item.name",
                                    },
                                  }),
                                  _vm.opType
                                    ? _c("el-input", {
                                        staticStyle: { width: "40%" },
                                        attrs: {
                                          oninput:
                                            "value=value.replace(/[^\\d]/g,'')",
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.customerQuantityChange(
                                              $event,
                                              item
                                            )
                                          },
                                        },
                                        model: {
                                          value: item.customerQuantity,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              item,
                                              "customerQuantity",
                                              $$v
                                            )
                                          },
                                          expression: "item.customerQuantity",
                                        },
                                      })
                                    : _c("el-input", {
                                        staticStyle: { width: "40%" },
                                        attrs: { disabled: "" },
                                        model: {
                                          value: item.customerQuantity,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              item,
                                              "customerQuantity",
                                              $$v
                                            )
                                          },
                                          expression: "item.customerQuantity",
                                        },
                                      }),
                                  _vm._v(" 条任务 "),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.opType
                    ? _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "" } },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      on: { change: _vm.valChange },
                                      model: {
                                        value: _vm.form.distributionType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "distributionType",
                                            $$v
                                          )
                                        },
                                        expression: "form.distributionType",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-radio",
                                        { attrs: { label: "AVERAGE" } },
                                        [_vm._v("平均分布")]
                                      ),
                                      _c(
                                        "el-radio",
                                        { attrs: { label: "CUSTOM" } },
                                        [_vm._v("自定义分配数量")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-check",
                type: "primary",
                plain: "",
                size: "mini",
              },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: { click: _vm.closePage },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }