import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { limitInput, getSupplyers } from '@/utils/common';
export default {
  props: {
    data: {
      type: Array,
      required: false
    }
  },
  data: function data() {
    return {
      form: {
        lists: []
      }
    };
  },
  computed: {},
  watch: {
    data: function data(n, o) {
      this.form.lists = n;
      this.supplyers = getSupplyers();
    }
  },
  created: function created() {},
  mounted: function mounted() {
    this.form.lists = this.data;
    this.supplyers = getSupplyers();
  },
  methods: {
    getSupplyerName: function getSupplyerName(code) {
      //获取保司中文名称
      var supplyer = _.find(this.supplyers, function (item) {
        return _.toLower(item.code) == _.toLower(code);
      });
      return supplyer ? supplyer.shortName : "";
    },
    onInput: function onInput(num, min, max, limit, insCorpCode, risk) {
      this.$forceUpdate();
      //对输入内容做精度校验
      if (min && num < min) {
        num = min;
      }
      if (max && num > max) {
        num = max;
      }
      var res = limitInput(num, limit);
      if (!_.isEmpty(risk.serviceItems) && insCorpCode == 'gpic' && risk.riskCode == 'FZ02') {
        risk.serviceItems.forEach(function (item) {
          item.serviceTimes = res;
        });
      }
      return res;
    },
    handleChange: function handleChange(serviceItems, serviceCode) {
      //默认未选中
      serviceItems.forEach(function (eitem) {
        return eitem.checked = false;
      });
      //单选效果
      serviceItems.forEach(function (eitem) {
        return eitem.serviceCode == serviceCode ? eitem.checked = true : eitem.checked = false;
      });
      this.$forceUpdate();
    },
    handleGpicChange: function handleGpicChange(serviceItems, serviceCode) {
      //国寿财车辆安全检测特约服务复选框
      serviceItems.forEach(function (eitem) {
        if (eitem.serviceCode == serviceCode) {
          eitem.selected == true;
        }
      });
      this.$forceUpdate();
    }
  }
};