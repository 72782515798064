import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
/**
 * 投保管理接口
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 详情
   * @param {*} id 查询参数
   */
  getDetail: function getDetail(id) {
    return request.get(getUrl("/insure/coreQuote?id=".concat(id), 1));
  },
  /**
   * 暂存/修改
   * @param {*} data 内容
   */
  save: function save(data) {
    var headers = {
      showLoading: true,
      loadingText: encodeURIComponent("暂存中...")
    };
    return request.post(getUrl("/insure/coreQuote", 1), data, {
      headers: headers
    });
  },
  /**
   * 列表
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   * @param {*} data 内容
   */
  getList: function getList(pageNum, pageSize, data) {
    return request.get(getUrl("/insure/search?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 1), {
      params: data
    });
  },
  /**
   * 删除
   * @param {*} ids 
   */
  delete: function _delete(ids) {
    return request.delete(getUrl("/insure/coreQuote?ids=".concat(ids.toString()), 1));
  },
  /**
   * 提交报价
   * @param {*} data 内容
   */
  quote: function quote(data) {
    var headers = {
      showLoading: true,
      loadingText: encodeURIComponent("报价中...")
    };
    return request.post(getUrl("/insure/quote", 1), data, {
      headers: headers
    });
  },
  /**
   * 报价回调
   * @param {*} proposalIds ID
   */
  getProposal: function getProposal(ids) {
    return request.post(getUrl("/insure/getProposal", 1), ids);
  },
  /**
   * 核保回调
   * @param {*} proposalIds ID
   */
  apply: function apply(data) {
    return request.post(getUrl("/insure/apply", 1), data);
  },
  /**
   * 报价号获取投保单明细
   * @param {*} quoteId ID
   */
  quoteGetProposal: function quoteGetProposal(quoteId) {
    return request.get(getUrl("/insure/quote/getProposal?quoteId=".concat(quoteId), 1));
  },
  /**
   * 机构编码获取机构信息
   * @param {*} orgCode 机构编码
   */
  getChannels: function getChannels(data) {
    return request.get(getUrl("/insure/getChannels", 1), {
      params: data
    });
  },
  /**
   * 调整商业险折扣或者车损险保额
   * 
   */
  reQuote: function reQuote(data) {
    return request.post(getUrl("/insure/adjust", 1), data);
  },
  /**
   * 查车辆
   * 
   */
  queryVechileData: function queryVechileData(data) {
    return request.get(getUrl("/insure/vehicle/query", 1), {
      params: data
    });
  },
  /**
   * 查车辆
   * 
   */
  queryVechileDataBySourceId: function queryVechileDataBySourceId(data) {
    return request.get(getUrl("/insure/vehicle/queryBySource", 1), {
      params: data
    });
  },
  /**
   * 查车型
   * 
   */
  queryVechileModel: function queryVechileModel(data) {
    return request.post(getUrl("/insure/vehicle/queryVehicleModel", 1), data);
  },
  /**
   * 客户信息采集验证码回收接口
   * 
   */
  customerCecycle: function customerCecycle(data) {
    return request.post(getUrl("/insure/customer/cecycle", 1), data);
  },
  /**
   * 客户信息采集接口
   * 
   */
  customerCollect: function customerCollect(data) {
    return request.post(getUrl("/insure/customer/collect", 1), data);
  },
  /**
   * 保存特约
   * 
   */
  saveSpecial: function saveSpecial(data) {
    return request.post(getUrl("/insure/saveSpecial", 1), data);
  },
  /**
   * 根据车架号查询续保信息（检查续保）
   * @param {*} id
   */
  getRenewalDetail: function getRenewalDetail(data) {
    return request.post(getUrl("/renewalInformation/renewalQuery", 1), data);
  },
  /**
   * 根据车架号_机构编码查询线索详情
   * @param {*} data
   */
  getCluesDetails: function getCluesDetails(data) {
    return request.get(getUrl("/customer/clues/query/clues/details", 1), {
      params: data
    });
  },
  /**
   * 获取影像上传业务类型
   */
  getMediaTypes: function getMediaTypes(proposalId) {
    return request.get(getUrl("/insure/getMediaTypeList?proposalId=".concat(proposalId), 1));
  }
};
export default objApi;