import "core-js/modules/es.object.keys.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import requestApi from '@/api/interactPlatform';
import EncryptPackets from './encryptPackets';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    outputRecordId: {
      type: String,
      default: ''
    },
    outputRecordFlowNo: {
      type: String,
      default: ''
    }
  },
  components: {
    EncryptPackets: EncryptPackets
  },
  data: function data() {
    return {
      form: {},
      showDialog: false,
      pushLoglist: [],
      activeName: '1',
      loading: false
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
        this.getDuipoutputrecordlog();
      }
      ;
    }
  },
  mounted: function mounted() {
    this.initData();
    this.getDuipoutputrecordlog();
  },
  methods: {
    getDuipoutputrecordlog: function getDuipoutputrecordlog() {
      var _this = this;
      requestApi.getDuipoutputrecordlog(1, 100, {
        outputRecordFlowNo: this.outputRecordFlowNo
      }).then(function (res) {
        if (res.code == 200) {
          _this.pushLoglist = res.data.list;
        }
      });
    },
    initData: function initData() {
      var _this2 = this;
      this.loading = true;
      requestApi.getDuipoutputconfigData(this.outputRecordId).then(function (res) {
        _this2.loading = false;
        if (res.code === 200) {
          _this2.form = res.data;
        }
        ;
      }).catch(function (err) {
        _this2.loading = false;
      });
    },
    checkMessage: function checkMessage() {
      this.showDialog = true;
    },
    // 关闭弹窗
    closePage: function closePage() {
      this.$emit('closePage');
    },
    parseData: function parseData(data) {
      var jsonData = null;
      try {
        jsonData = JSON.parse(data);
      } catch (error) {
        jsonData = data;
      }
      return jsonData;
    }
  }
};