//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import request from '@/api/finance';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    currentItem: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      form: {
        bankCardNo: null,
        payCode: null,
        payType: null,
        remark: null
      },
      rules: {
        payType: {
          required: true,
          message: '请选择付款方式',
          trigger: 'change'
        },
        bankCardNo: {
          required: true,
          message: '请选择付款账号',
          trigger: 'change'
        }
      },
      formLoading: false
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
      ;
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    initData: function initData() {
      //初始化数据
      this.form = {
        bankCardNo: this.currentItem.bankCardNo,
        payCode: this.currentItem.payCode,
        payType: 'OFFLINE_MANUAL',
        remark: this.currentItem.remark
      };
      this.$refs.form.resetFields();
    },
    save: function save() {
      var _this = this;
      //保存数据
      this.$refs.form.validate(function (valid) {
        if (valid) {
          _this.formLoading = true;
          request.doPay(_this.form).then(function (res) {
            _this.formLoading = false;
            if (res.code === 200) {
              _this.$message.success(res.msg);
              _this.closePage();
            }
            ;
          }).catch(function (err) {
            _this.formLoading = false;
          });
        }
        ;
      });
    },
    closePage: function closePage() {
      //关闭弹窗
      this.$emit('closePage');
    }
  }
};