import "core-js/modules/es.object.keys.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import RenewalActivities from './components/renewalActivities';
import CustomerTypeNames from './components/customerTypeNames';
import CustomerType from './components/customerType';
import TaskFollowConfig from './components/taskFollowConfig';
import customersRequest from '@/api/customers';
import { getUserOrgInfo } from "@/utils/common";
export default {
  name: 'RenewalConfig',
  mixins: [initHeight],
  components: {
    RenewalActivities: RenewalActivities,
    CustomerType: CustomerType,
    CustomerTypeNames: CustomerTypeNames,
    TaskFollowConfig: TaskFollowConfig
  },
  data: function data() {
    return {
      activeName: '1',
      renewalConfigParams: {
        bizType: 'BROKER',
        orgCode: ''
      },
      form: {
        configItemList: [],
        coreCustomerRenewalTaskConfigId: '',
        defaulted: true,
        distinguishConversionList: []
      },
      loading: false,
      userInfo: JSON.parse(localStorage.getItem('userInfo')) || ''
    };
  },
  created: function created() {
    this.getOrgRenewalConfig();
  },
  methods: {
    clearCache: function clearCache() {
      var _this = this;
      var data = {};
      if (this.userInfo.orgCode) {
        data = {
          bizType: this.userInfo.bizType,
          orgCode: this.userInfo.orgCode
        };
      }
      ;
      customersRequest.clearOrgCache(data).then(function (res) {
        if (res.code == 200) {
          _this.$message.success(res.msg);
          _this.getOrgRenewalConfig();
        }
      });
    },
    getOrgRenewalConfig: function getOrgRenewalConfig() {
      var _this2 = this;
      var _orgInfo = getUserOrgInfo();
      if (_orgInfo && _orgInfo.organizationId) {
        this.renewalConfigParams.orgCode = _orgInfo.orgCode;
      }
      ;
      var data = this.renewalConfigParams.orgCode ? this.renewalConfigParams : {};
      this.loading = true;
      customersRequest.getByOrg(data).then(function (res) {
        _this2.loading = false;
        if (res.code === 200) {
          _this2.form = res.data;
        }
        ;
      });
    },
    defaultedChange: function defaultedChange() {
      var _this3 = this;
      customersRequest.createConfig(this.renewalConfigParams).then(function (res) {
        if (res.code === 200) {
          _this3.$message.success(res.msg);
          _this3.getOrgRenewalConfig();
        }
        ;
      });
    }
  }
};