import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.find.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import dict from "../../../mixins/dict";
import initHeight from "../../../mixins/initHeight";
import customersRequest from "../../../api/customers";
import orgRequest from "@/api/orgChildren";
import employeeRequest from "@/api/employee";
import storageRequest from '@/api/storage';
import OemSelect from '@/components/OemSelect';
import { downFile } from '@/utils/common';
export default {
  props: {
    refresh: {
      type: Boolean
    },
    bizType: {
      type: String,
      default: ''
    },
    dealerUsers: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  mixins: [initHeight, dict],
  components: {
    OemSelect: OemSelect
  },
  data: function data() {
    return {
      needUser: false,
      form: {},
      uploadForm: {
        fileId: '',
        ownerOrgCode: null,
        ownerOrgName: null,
        userId: null,
        userName: null
      },
      formRules: {
        ownerOrgName: [{
          required: true,
          message: '请选择业务机构',
          trigger: 'change'
        }]
      },
      orgData: [],
      defaultProps: {
        children: "children",
        label: "fullName"
      },
      fileList: [],
      users: [],
      importLoading: false,
      orgForm: {
        salesOrgCode: '',
        salesOrgName: ''
      },
      userInfo: JSON.parse(localStorage.getItem("userInfo")) || {}
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.loadData();
        this.$refs.detail.resetFields();
      }
    }
  },
  mounted: function mounted() {
    this.loadData();
  },
  methods: {
    getOemUsers: function getOemUsers(val) {
      //列表行选择事件
      this.uploadForm.userId = '';
      this.uploadForm.userName = '';
      this.users = val;
    },
    endQuery: function endQuery() {
      var _this = this;
      orgRequest.dealerQuery(this.userInfo.orgCode).then(function (res) {
        if (res.data) {
          _this.uploadForm.ownerOrgCode = res.data.dealerCode;
          _this.uploadForm.ownerOrgName = res.data.dealerName;
        }
      });
    },
    // 附件校验
    validate: function validate() {
      var flag = true;
      if (!this.uploadForm.fileId) {
        this.$message.warning('请上传文件');
        flag = false;
        return flag;
      }
      return flag;
    },
    saveUpload: function saveUpload() {
      var _this2 = this;
      this.$refs["detail"].validate(function (valid) {
        if (valid) {
          if (!_this2.validate()) return;
          _this2.importLoading = true;
          customersRequest.importModul(_this2.uploadForm).then(function (res) {
            _this2.importLoading = false;
            if (res.code === 200) {
              _this2.$confirm("".concat(res.data.failures ? '文件模板失败数据' + res.data.failures + '条，点击打开文件，重新上传导入，谢谢！' : '导入成功', " "), '提示', {
                showCancelButton: false,
                confirmButtonText: "".concat(res.data.failures ? '打开文件' : "确定"),
                type: "".concat(res.data.failures ? 'warning' : 'success')
              }).then(function () {
                if (res.data.failures) {
                  downFile(res.data.fileUrl, res.data.fileName);
                  _this2.fileList = [];
                  _this2.uploadForm.fileId = '';
                } else {
                  _this2.fileList = [];
                  _this2.closePage(false);
                }
              }).catch(function () {});
            }
          }, function (err) {}).catch(function () {
            _this2.importLoading = false;
          });
        }
      });
    },
    filterNode: function filterNode(value, data) {
      //机构树查询
      if (!value) return true;
      return data.fullName.indexOf(value) !== -1;
    },
    dataFilter: function dataFilter(val) {
      //机构树查询
      this.$refs.tree.filter(val);
    },
    visibleChange: function visibleChange(val) {
      //出单机构树下拉列表是否可见监听
      if (this.$refs.orgSelect && (this.$refs.orgSelect.query || this.$refs.orgSelect.query == "") && this.$refs.tree && !this.form.ownerOrgName) {
        this.changeTreeNodeStatus(this.$refs.tree.store.root, val);
      }
    },
    changeTreeNodeStatus: function changeTreeNodeStatus(node, expandAll) {
      //改变节点的自身expanded状态
      node.expanded = expandAll;
      for (var i = 0; i < node.childNodes.length; i++) {
        // 改变节点的自身expanded状态
        node.childNodes[i].expanded = expandAll;
      }
    },
    orgFocus: function orgFocus(val) {
      //组织机构树焦点监听
      if (this.$refs.orgSelect && (this.$refs.orgSelect.query || this.$refs.orgSelect.query == "")) {
        this.$refs.tree.filter("");
        this.$forceUpdate();
      }
    },
    orgChange: function orgChange(val) {
      //组织机构树选择监听
      this.users = [];
      this.$refs["detail"].resetFields();
      this.selectInsuranceCompanys = [];
      this.$forceUpdate();
    },
    getOrgTree: function getOrgTree() {
      var _this3 = this;
      var parentId = 0;
      orgRequest.getTree(parentId).then(function (res) {
        if (res.data) {
          _this3.orgData = [res.data];
        }
      });
    },
    getUsers: function getUsers(orgCode) {
      var _this4 = this;
      if (!orgCode) return;
      //获取机构人员列表
      employeeRequest.salesmanByOrgCode(orgCode).then(function (res) {
        if (res.data) {
          _this4.users = res.data;
        }
      });
    },
    handleNodeClick: function handleNodeClick(data, node, target) {
      //节点点击事件
      if (this.bizType === "BROKER") {
        this.form.proposalStatus = "";
        this.orgChange();
        this.getUsers(data.orgCode);
        this.uploadForm.ownerOrgName = data.fullName;
        this.uploadForm.ownerOrgCode = data.orgCode;
        this.form.operateOrgId = data.organizationId;
        this.$refs["orgSelect"].blur();
        this.$forceUpdate();
      }
      if (this.bizType === "OEM") {
        this.uploadForm.ownerOrgCode = data.salesOrgCode;
        this.uploadForm.ownerOrgName = data.salesOrgName;
      }
    },
    salemanNameChange: function salemanNameChange(val) {
      //监听选择业务员事件
      if (val) {
        var _user = _.find(this.users, function (item) {
          return item.userId == val;
        });
        if (_user) {
          this.uploadForm.userId = _user.userId;
          this.uploadForm.userName = _user.userName;
          if (this.bizType !== 'BROKER') {
            this.uploadForm.ownerOrgCode = _user.dealerCode;
          }
          this.$forceUpdate();
        }
      }
    },
    // 上传文件
    uploadFile: function uploadFile(param) {
      var _this5 = this;
      var formData = new FormData();
      formData.append('file', param.file);
      storageRequest.uploadFile(formData).then(function (res) {
        if (res.code === 200) {
          _this5.$message.success(res.msg);
          setTimeout(function () {
            _this5.uploadForm.fileId = res.data.fileStorageId;
            _this5.uploadForm.fileUrl = res.data.fileUrl;
          }, 1000);
        }
      });
    },
    // 下载模板
    download: function download() {
      var _this6 = this;
      storageRequest.downFileTemplate("VI-CUSTOMER-IMPORT-TEMPLATE").then(function (res) {
        if (res.data && res.data.fileStorageId) {
          downFile(res.data.fileUrl, res.data.fileName);
        } else {
          _this6.$message.warning('暂无模板，请联系客服！');
        }
        ;
      });
    },
    // 加载数据
    loadData: function loadData() {
      this.uploadForm.fileId = '';
      this.uploadForm.ownerOrgCode = null;
      this.uploadForm.ownerOrgName = null;
      this.uploadForm.userId = null;
      this.uploadForm.userName = null;
      this.fileList = [];
      this.orgForm = {
        salesOrgCode: '',
        salesOrgName: ''
      };
      if (this.bizType === 'BROKER') {
        this.getOrgTree();
      } else if (this.bizType === 'DEALER') {
        this.users = this.dealerUsers;
      }
      if (this.bizType === 'DEALER') this.endQuery();
      if (this.$route.path === "/customers/index") {
        this.needUser = true;
        this.uploadForm.exportType = 1;
      } else {
        this.needUser = false;
        var userInfo = JSON.parse(localStorage.getItem("userInfo"));
        this.uploadForm.ownerOrgCode = userInfo.organization ? userInfo.organization.orgCode : '';
        this.uploadForm.ownerOrgName = userInfo.organization ? userInfo.organization.fullName : '';
        this.uploadForm.userId = userInfo.user.userId;
        this.uploadForm.userName = userInfo.user.userName;
        this.uploadForm.exportType = 2;
      }
    },
    // 关闭弹窗
    closePage: function closePage(val) {
      this.$emit("closePage", val);
    }
  }
};