import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import _defineProperty from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/defineProperty.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '../../mixins/initHeight';
import Pagination from '@/components/Pagination';
import request from '@/api/finance';
import { limitInput } from '@/utils/common';
export default {
  components: {
    Pagination: Pagination
  },
  mixins: [initHeight],
  data: function data() {
    var _ref;
    return _ref = {
      drawer: false,
      searchForm: {},
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      startEndTime: null,
      loading: false,
      orgData: {
        salesOrgName: "",
        salesOrgCode: ""
      },
      toBillAmount: null
    }, _defineProperty(_ref, "startEndTime", []), _defineProperty(_ref, "showPolicyFactorsDialog", false), _defineProperty(_ref, "formLoading", false), _defineProperty(_ref, "PolicyFactorsForm", {}), _defineProperty(_ref, "PolicyFactorsRules", {
      factorName: [{
        required: true,
        message: '请输入因子名称',
        trigger: 'blur'
      }],
      factorCode: [{
        required: true,
        message: '请输入因子编码',
        trigger: 'blur'
      }],
      sortBy: [{
        required: true,
        message: '请输入序号',
        trigger: 'blur'
      }],
      tagData: [{
        required: true,
        message: '请输入页面控件内容',
        trigger: 'blur'
      }],
      tagType: [{
        required: true,
        message: '请输入页面控件类型',
        trigger: 'blur'
      }]
    }), _defineProperty(_ref, "tagTypeList", [{
      dictName: "多选",
      dictCode: "MULTI_SELECT"
    }, {
      dictName: "单选",
      dictCode: "SINGLE_SELECT"
    }, {
      dictName: "下拉",
      dictCode: "DROP_DOWN"
    }, {
      dictName: "数字输入框",
      dictCode: "NUMBER_INPUT"
    }, {
      dictName: "文本输入框",
      dictCode: "TEXT_INPUT"
    }, {
      dictName: "时间范围",
      dictCode: "TIME_RANGE"
    }, {
      dictName: "数字范围",
      dictCode: "NUMBER_RANGE"
    }]), _defineProperty(_ref, "costPolicyFactorId", null), _defineProperty(_ref, "opType", ""), _ref;
  },
  watch: {
    showPolicyFactorsDialog: function showPolicyFactorsDialog(val) {
      var _this = this;
      if (val) {
        this.$nextTick(function () {
          return _this.$refs['PolicyFactorsForm'].clearValidate();
        });
      }
    }
  },
  created: function created() {
    //保司
    this.loadData();
  },
  methods: {
    // 校验输入
    onInput: function onInput(val) {
      var num = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
      return limitInput(val, num);
    },
    getDetailByFactorId: function getDetailByFactorId() {
      var _this2 = this;
      this.formLoading = true;
      request.getDetailByFactorId(this.costPolicyFactorId).then(function (res) {
        _this2.formLoading = false;
        if (res.code == 200) {
          _this2.PolicyFactorsForm = res.data;
        }
      });
    },
    handlerSwitch: function handlerSwitch(val, item) {
      var _this3 = this;
      request.editPolicyFactors(item.costPolicyFactorId, {
        enabled: val
      }).then(function (res) {
        if (res.code == 200) {
          _this3.$message.success(res.msg);
          _this3.loadData();
        }
      });
    },
    createFactors: function createFactors() {
      this.opType = 'add';
      this.showPolicyFactorsDialog = true;
      this.PolicyFactorsForm = {
        enabled: true
      };
    },
    ediPolicyFactorst: function ediPolicyFactorst(type, item) {
      this.opType = type;
      this.showPolicyFactorsDialog = true;
      this.costPolicyFactorId = item.costPolicyFactorId;
      this.getDetailByFactorId();
    },
    deletePolicyFactors: function deletePolicyFactors(item) {
      var _this4 = this;
      this.$confirm('您确定要将该政策因子删除？', {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        request.deletePolicyFactors(item.costPolicyFactorId).then(function (res) {
          if (res.code === 200) {
            _this4.$message({
              message: res.msg,
              type: 'success'
            });
            _this4.loadData();
          }
        });
      });
    },
    save: function save() {
      var _this5 = this;
      this.$refs['PolicyFactorsForm'].validate(function (valid) {
        if (valid) {
          _this5.formLoading = true;
          var req = _this5.opType == 'add' ? request.createPolicyFactor(_this5.PolicyFactorsForm) : request.editPolicyFactors(_this5.costPolicyFactorId, _this5.PolicyFactorsForm);
          req.then(function (res) {
            if (res.code == 200) {
              _this5.$message.success(res.msg);
              _this5.loadData();
            }
            _this5.formLoading = false;
          });
        }
      });
    },
    closeShowDialog: function closeShowDialog() {
      this.showPolicyFactorsDialog = false;
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {};
      this.orgData = {};
      this.startEndTime = [];
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    // 刷新
    breakData: function breakData() {
      this.searchData();
    },
    loadData: function loadData() {
      var _this6 = this;
      //获取列表数据
      this.showPolicyFactorsDialog = false;
      this.loading = true;
      this.drawer = false;
      if (this.searchForm && this.searchForm.billTimeEnd) {
        this.searchForm.billTimeEnd = this.searchForm.billTimeEnd.split(' ')[0] + ' 23:59:59';
      }
      ;
      var postData = _objectSpread(_objectSpread({}, this.searchForm), this.listQuery);
      request.getPolicyFactorList(postData).then(function (res) {
        _this6.loading = false;
        _this6.tableData = res.data.list;
        //总条数
        _this6.total = res.data.page.recordsTotal;
        _this6.toBillAmount = res.data.toBillAmount || 0;
      }).catch(function (err) {
        _this6.loading = false;
      });
    }
  }
};