import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import giftApi from '@/api/giftManagement';
import storageRequest from '@/api/storage';
import { limitInput, downFile } from '@/utils/common';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    currentObj: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    formParams: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      form: {
        vin: '',
        cardVoucherCode: '',
        verificationNumber: '',
        verificationCost: '',
        cardVoucherIssuedId: '',
        fileJson: []
      },
      rules: {
        verificationNumber: {
          required: true,
          message: '请输入数量',
          trigger: "blur"
        }
      },
      formLoading: false,
      fileList: []
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
      ;
    }
  },
  filters: {
    filterType: function filterType(val) {
      if (val === 0) return '0 B';
      var k = 1024;
      var sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      var i = Math.floor(Math.log(val) / Math.log(k));
      return (val / Math.pow(k, i)).toPrecision(3) + " " + sizes[i];
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    // 上传文件
    uploadFile: function uploadFile(param) {
      var _this = this;
      var formData = new FormData();
      formData.append('file', param.file);
      this.formLoading = true;
      storageRequest.uploadFile(formData).then(function (res) {
        _this.formLoading = false;
        if (res.code === 200) {
          _this.$message.success(res.msg);
          setTimeout(function () {
            var obj = {
              fileId: res.data.fileStorageId,
              fileNmae: param.file.name,
              fileSize: param.file.size
            };
            _this.form.fileJson.push(obj);
          }, 1000);
        }
        ;
      }).catch(function (err) {
        _this.formLoading = false;
      });
    },
    // 文件删除
    handleDelete: function handleDelete(index) {
      this.form.fileJson.splice(index, 1);
    },
    // 点击，使用id下载文件
    handle: function handle(row) {
      if (row.fileId) {
        downFile(row.fileUrl, row.fileName);
      }
    },
    // 对输入内容做精度校验
    onInput: function onInput(num, min, max, limit) {
      if (min && num < min) {
        num = min;
      }
      ;
      if (max && num > max) {
        num = max;
      }
      ;
      return limitInput(num, limit);
    },
    // 初始化数据
    initData: function initData() {
      this.form = {
        vin: this.currentObj.vin,
        cardVoucherCode: this.currentObj.cardVoucherCode,
        verificationNumber: 1,
        verificationCost: '',
        cardVoucherIssuedId: this.currentObj.cardVoucherIssuedId,
        fileJson: [],
        businessType: this.formParams.businessType,
        productType: this.formParams.productType,
        // orderNo: this.formParcurrentObjams.orderNo,
        orderNo: this.currentObj.orderNo
      };
      this.$refs.detail.resetFields();
    },
    // 关闭弹窗
    closePage: function closePage() {
      var boolean = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var noCloseF = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      this.$emit('closePage', boolean, noCloseF);
    },
    // 附件校验
    validate: function validate() {
      var flag = true;
      if (!Number(this.form.verificationNumber)) {
        this.$message.warning('数量不能为0');
        flag = false;
        return flag;
      }
      ;
      if (!this.form.fileJson.length) {
        this.$message.warning('请上传附件');
        flag = false;
        return flag;
      }
      ;
      return flag;
    },
    // 保存
    save: function save() {
      var _this2 = this;
      this.$refs.detail.validate(function (valid) {
        if (valid) {
          if (!_this2.validate()) return;
          _this2.formLoading = true;
          giftApi.addGiftWriteOff(_this2.form).then(function (res) {
            _this2.formLoading = false;
            if (res.code === 200) {
              _this2.$message.success(res.msg);
              _this2.closePage(true, false);
            }
            ;
          }).catch(function (err) {
            _this2.formLoading = false;
          });
        }
      });
    }
  }
};