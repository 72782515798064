var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "app-m-cls" }, [
        _c(
          "div",
          { staticClass: "table-cls normal-btn-cls" },
          [
            _c(
              "el-drawer",
              {
                attrs: {
                  title: "",
                  visible: _vm.drawer,
                  modal: false,
                  "append-to-body": true,
                  size: "370px",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.drawer = $event
                  },
                },
              },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.searchForm,
                      "label-width": "120px",
                      size: "mini",
                    },
                  },
                  [
                    _vm.bizType === "BROKER"
                      ? _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 22 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "出单网点" } },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        ref: "orgSelect",
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          filterable: "",
                                          "filter-method": _vm.dataFilter,
                                          placeholder: "请选择出单网点",
                                          clearable: "",
                                        },
                                        on: {
                                          change: _vm.orgChange,
                                          focus: _vm.orgFocus,
                                        },
                                        model: {
                                          value: _vm.searchForm.salesOrgName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.searchForm,
                                              "salesOrgName",
                                              $$v
                                            )
                                          },
                                          expression: "searchForm.salesOrgName",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-option",
                                          {
                                            staticStyle: {
                                              height: "auto",
                                              "background-color": "white",
                                            },
                                            attrs: {
                                              value:
                                                _vm.searchForm.salesOrgCode,
                                            },
                                          },
                                          [
                                            _c("el-tree", {
                                              ref: "tree",
                                              staticClass: "filter-tree",
                                              attrs: {
                                                "node-key": "code",
                                                "highlight-current":
                                                  _vm.highlightCurrent,
                                                data: _vm.orgData,
                                                props: _vm.defaultProps,
                                                "filter-node-method":
                                                  _vm.filterNode,
                                              },
                                              on: {
                                                "node-click":
                                                  _vm.handleNodeClick,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-row",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.bizType === "OEM",
                            expression: "bizType === 'OEM'",
                          },
                        ],
                      },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "所属机构" } },
                              [
                                _c("OemSelect", {
                                  ref: "oemSelect",
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    searchForm: _vm.orgForm,
                                    canClickArea: true,
                                  },
                                  on: { nodeClick: _vm.handleNodeClick },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.bizType === "DEALER",
                            expression: "bizType === 'DEALER'",
                          },
                        ],
                      },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "所属机构" } },
                              [
                                _c("el-input", {
                                  attrs: { disabled: "" },
                                  model: {
                                    value: _vm.searchForm.salesOrgName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "salesOrgName",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.salesOrgName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "保险公司" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      filterable: "",
                                      placeholder: "请选择保险公司",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.searchForm.insuranceAgencyCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "insuranceAgencyCode",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "searchForm.insuranceAgencyCode",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: { label: "全部", value: null },
                                    }),
                                    _vm._l(
                                      _vm.supplyers,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label: item.shortName,
                                            value: item.code,
                                          },
                                        })
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "车主名称" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "40",
                                    placeholder: "请输入车主名称",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.searchForm.ownerCustName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "ownerCustName",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.ownerCustName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c("el-form-item", { attrs: { label: "车牌号" } }, [
                              _c(
                                "div",
                                { staticStyle: { display: "flex" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "margin-right": "10px",
                                        flex: "1",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: { "margin-right": "10px" },
                                        attrs: {
                                          maxlength: "10",
                                          placeholder: "请输入车牌号",
                                          clearable: "",
                                          disabled: _vm.searchForm.noPlateNo,
                                        },
                                        on: { input: _vm.inputPlateNo },
                                        model: {
                                          value: _vm.searchForm.plateNo,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.searchForm,
                                              "plateNo",
                                              $$v
                                            )
                                          },
                                          expression: "searchForm.plateNo",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-checkbox",
                                    {
                                      on: { change: _vm.noPlateNoChange },
                                      model: {
                                        value: _vm.searchForm.noPlateNo,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "noPlateNo",
                                            $$v
                                          )
                                        },
                                        expression: "searchForm.noPlateNo",
                                      },
                                    },
                                    [_vm._v("未上牌")]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "车架号" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "17",
                                    placeholder: "请输入车架号",
                                    clearable: "",
                                  },
                                  on: { input: _vm.checkValue },
                                  model: {
                                    value: _vm.searchForm.vin,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchForm, "vin", $$v)
                                    },
                                    expression: "searchForm.vin",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "厂牌车型" } },
                              [
                                _c("CarBrands", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    multipleBool: true,
                                    carTypeValue: _vm.carTypeVal,
                                  },
                                  on: { carHandleChanged: _vm.CarHandleChange },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "是否过户" } },
                              [
                                _c(
                                  "el-checkbox-group",
                                  {
                                    model: {
                                      value: _vm.searchForm.transferFlag,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "transferFlag",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.transferFlag",
                                    },
                                  },
                                  _vm._l(
                                    _vm.transferFlagData,
                                    function (item, index) {
                                      return _c(
                                        "el-checkbox",
                                        {
                                          key: index,
                                          attrs: { label: item.value },
                                        },
                                        [_vm._v(_vm._s(item.label))]
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "车辆标识" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      filterable: "",
                                      multiple: "",
                                      placeholder: "请选择车辆标识",
                                      clearable: "",
                                    },
                                    on: { change: _vm.vehicleStampChange },
                                    model: {
                                      value: _vm.vehicleStampList,
                                      callback: function ($$v) {
                                        _vm.vehicleStampList = $$v
                                      },
                                      expression: "vehicleStampList",
                                    },
                                  },
                                  [
                                    _vm._l(
                                      _vm.dictSource["CAR_STATUS"],
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label: item.dictName,
                                            value: item.dictCode,
                                          },
                                        })
                                      }
                                    ),
                                    _c("el-option", {
                                      attrs: { label: "无", value: null },
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "保单号" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "40",
                                    placeholder: "请输入保单号",
                                    clearable: "",
                                  },
                                  on: { input: _vm.checkpolicyCode },
                                  model: {
                                    value: _vm.searchForm.policyCode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "policyCode",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.policyCode",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "保单类型" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      filterable: "",
                                      placeholder: "请选择保单类型",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.searchForm.productType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "productType",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.productType",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: { label: "全部", value: null },
                                    }),
                                    _vm._l(
                                      _vm.dictSource["INSURANCE_TYPE"],
                                      function (dict, ind) {
                                        return _c("el-option", {
                                          key: ind,
                                          attrs: {
                                            label: dict.dictName,
                                            value: dict.dictCode,
                                          },
                                        })
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "保单来源" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      filterable: "",
                                      placeholder: "请选择保单来源",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.searchForm.source,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.searchForm, "source", $$v)
                                      },
                                      expression: "searchForm.source",
                                    },
                                  },
                                  _vm._l(_vm.sourceData, function (item) {
                                    return _c("el-option", {
                                      key: item.itemCode,
                                      attrs: {
                                        label: item.itemName,
                                        value: item.itemCode,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "操作人" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "30",
                                    placeholder: "请输入操作人",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.searchForm.operatorName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "operatorName",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "searchForm.operatorName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "承保日期" } },
                              [
                                _c("DatePicker", {
                                  attrs: {
                                    pickerDateTime: _vm.startEndTime,
                                    startDate: "signedDateBegin",
                                    endDate: "signedDateEnd",
                                    width: "100%",
                                  },
                                  on: {
                                    pickerDateChange: function ($event) {
                                      return _vm.getPickersDate(
                                        "startEndTime",
                                        $event
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "创建日期" } },
                              [
                                _c("DatePicker", {
                                  attrs: {
                                    pickerDateTime: _vm.createdDateTime,
                                    startDate: "createdTimeBegin",
                                    endDate: "createdTimeEnd",
                                    width: "100%",
                                  },
                                  on: {
                                    pickerDateChange: function ($event) {
                                      return _vm.getPickersDate(
                                        "createdDateTime",
                                        $event
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "保单验证结果" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      filterable: "",
                                      placeholder: "请选择保单验证结果",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.searchForm.policyVerifyResult,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "policyVerifyResult",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "searchForm.policyVerifyResult",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: { label: "通过", value: "PVR01" },
                                    }),
                                    _c("el-option", {
                                      attrs: { label: "失败", value: "PVR02" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "是否参加厂家政策" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      filterable: "",
                                      placeholder: "请选择是否参加厂家政策",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.searchForm.joinCouponAct,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "joinCouponAct",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.joinCouponAct",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: { label: "是", value: true },
                                    }),
                                    _c("el-option", {
                                      attrs: { label: "否", value: false },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c("el-col", { attrs: { span: 24 } }, [
                          _c(
                            "div",
                            { staticClass: "searchBtnStyle" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    icon: "el-icon-refresh-left",
                                  },
                                  on: { click: _vm.resetSearch },
                                },
                                [_vm._v("重置")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    type: "primary",
                                    icon: "el-icon-search",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.loadData(true)
                                    },
                                  },
                                },
                                [_vm._v("查询")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "table-cls normal-btn-cls" },
          [
            _c(
              "el-card",
              { staticClass: "box-card", attrs: { shadow: "never" } },
              [
                _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                  _c("div", { staticClass: "search-box" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.bizType === "OEM",
                            expression: "bizType === 'OEM'",
                          },
                        ],
                      },
                      [
                        _c("span", [_vm._v("所属机构：")]),
                        _c("OemSelect", {
                          ref: "oemSelect",
                          staticStyle: {
                            "max-width": "150px",
                            display: "inline-block",
                          },
                          attrs: {
                            searchForm: _vm.orgForm,
                            canClickArea: true,
                          },
                          on: { nodeClick: _vm.handleNodeClick },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.bizType === "DEALER",
                            expression: "bizType === 'DEALER'",
                          },
                        ],
                      },
                      [
                        _c("span", [_vm._v("所属机构：")]),
                        _c("el-input", {
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.searchForm.salesOrgName,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "salesOrgName", $$v)
                            },
                            expression: "searchForm.salesOrgName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v("车主名称：")]),
                        _c("el-input", {
                          staticStyle: { "max-width": "120px" },
                          attrs: {
                            placeholder: "请输入车主名称",
                            clearable: "",
                          },
                          model: {
                            value: _vm.searchForm.ownerCustName,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "ownerCustName", $$v)
                            },
                            expression: "searchForm.ownerCustName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v("车牌号：")]),
                        _c("el-input", {
                          staticStyle: { "max-width": "120px" },
                          attrs: {
                            maxlength: "10",
                            placeholder: "请输入车牌号",
                            clearable: "",
                            disabled: _vm.searchForm.isNotPlate,
                          },
                          on: { input: _vm.inputPlateNo },
                          model: {
                            value: _vm.searchForm.plateNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "plateNo", $$v)
                            },
                            expression: "searchForm.plateNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "div",
                          {
                            staticStyle: { width: "65px", "font-size": "13px" },
                          },
                          [_vm._v("车架号：")]
                        ),
                        _c("el-input", {
                          staticStyle: { "max-width": "200px" },
                          attrs: {
                            maxlength: "17",
                            placeholder: "请输入车架号",
                            clearable: "",
                          },
                          on: { input: _vm.checkValue },
                          model: {
                            value: _vm.searchForm.vin,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "vin", $$v)
                            },
                            expression: "searchForm.vin",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v("厂牌车型：")]),
                        _c("CarBrands", {
                          attrs: {
                            carTypeValue: _vm.carTypeVal,
                            multipleBool: true,
                          },
                          on: { carHandleChanged: _vm.CarHandleChange },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v("创建日期：")]),
                        _c("DatePicker", {
                          attrs: {
                            pickerDateTime: _vm.createdDateTime,
                            startDate: "createdTimeBegin",
                            endDate: "createdTimeEnd",
                            width: "240px",
                          },
                          on: {
                            pickerDateChange: function ($event) {
                              return _vm.getPickersDate(
                                "createdDateTime",
                                $event
                              )
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              icon: "el-icon-refresh-left",
                            },
                            on: { click: _vm.resetSearch },
                          },
                          [_vm._v("重置")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "primary",
                              icon: "el-icon-search",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.loadData(true)
                              },
                            },
                          },
                          [_vm._v("查询")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "search-btn" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: { click: _vm.breakData },
                          },
                          [_vm._v("刷新")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              disabled: _vm._.isEmpty(_vm.tableData),
                            },
                            on: { click: _vm.exportExcels },
                          },
                          [_vm._v("导出")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: { click: _vm.policyImport },
                          },
                          [_vm._v("导入")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: {
                              click: function ($event) {
                                _vm.drawer = true
                              },
                            },
                          },
                          [_vm._v("搜索")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.downloading,
                        expression: "downloading",
                      },
                    ],
                    ref: "multipleTable",
                    staticStyle: { width: "100%" },
                    attrs: {
                      border: "",
                      "element-loading-text": _vm.loadingText,
                      size: "mini",
                      data: _vm.tableData,
                      height: _vm.tableHeightJ,
                      "header-cell-style": { background: "#F7F7F7" },
                      "highlight-current-row": "",
                    },
                    on: { "selection-change": _vm.handleSelectionChange },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "policyNo",
                        label: "保单号",
                        width: "200",
                        "show-overflow-tooltip": "",
                        fixed: "left",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "insCorpName",
                        label: "保险公司",
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "riskTypeName",
                        label: "保单类型",
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "vin",
                        label: "车架号",
                        "min-width": "200",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "vehicleStampDisplay",
                        label: "车辆标识",
                        width: "80",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "renewalTypeDisplay",
                        label: "续保标识",
                        width: "80",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "ownerName",
                        label: "车主名称",
                        width: "120",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "ownerMobile",
                        label: "车主电话",
                        width: "100",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    scope.row.ownerMobile
                                      ? scope.row.ownerMobile.replace(
                                          /^(.{3})(?:\d+)(.{2})$/,
                                          "$1******$2"
                                        )
                                      : ""
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "ownerCertificateNo",
                        label: "车主证件号",
                        "min-width": "180",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    scope.row.ownerCertificateNo
                                      ? scope.row.ownerCertificateNo.replace(
                                          /^(.{10})(?:\d+)(.{2})$/,
                                          "$1******$2"
                                        )
                                      : ""
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "plateNo",
                        label: "车牌号",
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "engineNo",
                        label: "发动机号",
                        width: "120",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "effectiveStartTime",
                        label: "起保日期",
                        width: "100",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    scope.row.effectiveStartTime &&
                                      scope.row.effectiveStartTime.split(" ")[0]
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "effectiveEndTime",
                        label: "截止日期",
                        width: "100",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    scope.row.effectiveEndTime &&
                                      scope.row.effectiveEndTime.split(" ")[0]
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "actualPremium",
                        label: "总保费",
                        "min-width": "100",
                        align: "right",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    scope.row.actualPremium
                                      ? scope.row.actualPremium.toLocaleString()
                                      : "-"
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "insurantName",
                        label: "被保险人",
                        width: "120",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "applicantName",
                        label: "投保人",
                        width: "120",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "applicantMobile",
                        label: "投保人电话",
                        width: "100",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    scope.row.applicantMobile
                                      ? scope.row.applicantMobile.replace(
                                          /^(.{3})(?:\d+)(.{2})$/,
                                          "$1******$2"
                                        )
                                      : ""
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "registerDate",
                        label: "注册日期",
                        width: "100",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    scope.row.registerDate &&
                                      scope.row.registerDate.split(" ")[0]
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "modelName",
                        label: "品牌型号",
                        "min-width": "200",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "cjVhcSeriesName",
                        label: "厂牌车型",
                        "min-width": "200",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(scope.row.cjVhcBrandNanme)),
                              ]),
                              _c("span", [
                                _vm._v(_vm._s(scope.row.cjVhcSeriesName)),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "vehicleTypeName",
                        label: "行驶证车辆类型",
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "useAttributeName",
                        label: "使用性质",
                        width: "80",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "riskCode",
                        label: "险种编码",
                        width: "80",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "threeAmount",
                        label: "三者保额",
                        width: "120",
                        align: "right",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    scope.row.threeAmount
                                      ? scope.row.threeAmount
                                      : "-"
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "carDamagePremium",
                        label: "车损险",
                        width: "120",
                        align: "right",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    scope.row.carDamagePremium
                                      ? scope.row.carDamagePremium.toLocaleString()
                                      : "-"
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "carDamageAmount",
                        label: "车损保额",
                        width: "120",
                        align: "right",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    scope.row.carDamageAmount
                                      ? scope.row.carDamageAmount.toLocaleString()
                                      : "-"
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "medicalAmount",
                        label: "医保外医疗费用责任险(三者)",
                        width: "190",
                        align: "right",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    scope.row.medicalAmount
                                      ? scope.row.medicalAmount.toLocaleString()
                                      : "-"
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "driverAmount",
                        label: "车上人员责任险(司机)",
                        width: "150",
                        align: "right",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    scope.row.driverAmount
                                      ? scope.row.driverAmount.toLocaleString()
                                      : "-"
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "passengerAmount",
                        label: "车上人员责任险(乘客)",
                        width: "150",
                        align: "right",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "discountFactor",
                        label: "折扣系数",
                        align: "right",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "poundageRate",
                        label: "手续费率(%)",
                        width: "100",
                        align: "right",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    scope.row.poundageRate
                                      ? scope.row.poundageRate
                                      : "-"
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "poundageAmount",
                        label: "手续费金额",
                        width: "120",
                        align: "right",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    scope.row.poundageAmount
                                      ? scope.row.poundageAmount.toLocaleString()
                                      : "-"
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "couponActivityRuleName",
                        label: "规则名称",
                        width: "100",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "couponActivityRuleCode",
                        label: "规则编码",
                        width: "100",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "idNo",
                        label: "业务员证件号",
                        width: "120",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    scope.row.idNo
                                      ? scope.row.idNo.replace(
                                          /^(.{10})(?:\d+)(.{2})$/,
                                          "$1******$2"
                                        )
                                      : ""
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "practiceNo",
                        label: "业务员执业证号",
                        width: "120",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "vehicleTaxPremium",
                        label: "车船税(元)",
                        align: "right",
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    scope.row.riskType == "0" &&
                                      scope.row.vehicleTaxPremium
                                      ? scope.row.vehicleTaxPremium.toLocaleString()
                                      : "-"
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "sourceName",
                        label: "保单来源",
                        width: "80",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "insuredTime",
                        label: "承保日期",
                        width: "100",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    scope.row.insuredTime &&
                                      scope.row.insuredTime.split(" ")[0]
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "joinCouponActDisplay",
                        label: "是否参加厂家政策",
                        "min-width": "160",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "salesOrgName",
                        label: "所属机构",
                        "min-width": "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "salesOrgCode",
                        label: "经销商编码",
                        width: "100",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "insCityName",
                        label: "投保城市",
                        width: "120",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "salemanName",
                        label: "业务员",
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "operatorName",
                        label: "操作人",
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "createdTime",
                        label: "创建时间",
                        "min-width": "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "modifiedTime",
                        label: "更新时间",
                        "min-width": "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "操作",
                        fixed: "right",
                        align: "left",
                        width: "" + (_vm.viewFlag ? 285 : 225),
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.viewDetail(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("查看")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.editPolicy(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("编辑")]
                              ),
                              _vm.viewFlag
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "danger",
                                        plain: "",
                                        size: "mini",
                                        disabled: _vm.currentMonth(scope.row),
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.deletePolicy(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  )
                                : _vm._e(),
                              !["picc", "cpic"].includes(
                                _vm._.toLower(scope.row.insCorpCode)
                              ) && _vm._.toUpper(scope.row.source) != "IMPORT"
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.download(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("电子保单")]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c("pagination", {
                  attrs: {
                    total: _vm.total,
                    page: _vm.listQuery.pageNum,
                    limit: _vm.listQuery.pageSize,
                  },
                  on: {
                    "update:page": function ($event) {
                      return _vm.$set(_vm.listQuery, "pageNum", $event)
                    },
                    "update:limit": function ($event) {
                      return _vm.$set(_vm.listQuery, "pageSize", $event)
                    },
                    pagination: function ($event) {
                      return _vm.loadData()
                    },
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showCustomerDialog,
            "close-on-click-modal": false,
            width: "520px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showCustomerDialog = $event
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("导入保单")]
              ),
            ]
          ),
          _c("ImportDialog", {
            attrs: { refresh: _vm.showCustomerDialog, bizType: _vm.bizType },
            on: { closePage: _vm.loadData },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showPolicyImportDialog,
            "close-on-click-modal": false,
            width: "950px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showPolicyImportDialog = $event
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("保单任务导入")]
              ),
            ]
          ),
          _c("PolicyImportListDialog", {
            attrs: {
              refresh: _vm.showPolicyImportDialog,
              bizType: _vm.bizType,
            },
            on: { closePage: _vm.loadData },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }