import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import storageRequest from '@/api/storage';
import callApi from '@/api/callCenter';
import { downFile } from '@/utils/common';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      form: {
        packageVersion: '',
        file: []
      },
      rules: {
        packageVersion: {
          required: true,
          message: '请输入版本号',
          trigger: 'blur'
        },
        packageType: {
          required: true,
          message: '请选择安装包类型',
          trigger: 'change'
        }
      },
      fileList: [],
      formLoading: false
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
      ;
    }
  },
  filters: {
    filterType: function filterType(val) {
      if (val === 0) return '0 B';
      var k = 1024;
      var sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      var i = Math.floor(Math.log(val) / Math.log(k));
      return (val / Math.pow(k, i)).toPrecision(3) + " " + sizes[i];
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    // 初始化数据
    initData: function initData() {
      this.form = {
        packageVersion: '',
        file: []
      };
      this.$refs.form.resetFields();
      this.$refs['upload'].clearFiles();
    },
    // 上传文件
    uploadFile: function uploadFile(param) {
      var _this = this;
      var isLt50M = param.file.size / 1024 / 1024 < 50;
      if (!isLt50M) {
        this.fileList = [];
        return this.$message.warning("上传文件的大小不能超过 50MB!");
      }
      ;
      var formData = new FormData();
      formData.append('file', param.file);
      this.formLoading = true;
      storageRequest.uploadFile(formData).then(function (res) {
        _this.formLoading = false;
        if (res.code === 200) {
          _this.$message.success(res.msg);
          setTimeout(function () {
            var obj = {
              packageStorageId: res.data.fileStorageId,
              packageName: param.file.name,
              packageSize: param.file.size
            };
            _this.form.file.push(obj);
          }, 1000);
        }
        ;
      }).catch(function (err) {
        _this.formLoading = false;
      });
    },
    //文件删除
    handleDelete: function handleDelete(index) {
      this.$refs['upload'].clearFiles();
      this.form.file.splice(index, 1);
    },
    //点击，使用id下载文件
    handle: function handle(row) {
      if (row.packageStorageId) {
        downFile(row.fileUrl, row.fileName);
      }
    },
    // 附件校验
    validate: function validate() {
      var flag = true;
      if (!this.form.file.length) {
        this.$message.warning('请上传文件');
        flag = false;
        return flag;
      }
      ;
      return flag;
    },
    save: function save() {
      var _this2 = this;
      //保存数据
      this.$refs.form.validate(function (valid) {
        if (valid) {
          if (!_this2.validate()) return;
          _this2.formLoading = true;
          var params = _objectSpread({
            packageVersion: _this2.form.packageVersion,
            packageType: _this2.form.packageType
          }, _this2.form.file[0]);
          callApi.addPackage(params).then(function (res) {
            _this2.formLoading = false;
            if (res.code == 200) {
              _this2.$message.success(res.msg);
              _this2.closePage();
            }
          }).catch(function (err) {
            _this2.formLoading = false;
          });
        }
        ;
      });
    },
    closePage: function closePage() {
      //关闭弹窗
      this.$emit('closePage');
    }
  }
};