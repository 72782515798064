import _createForOfIteratorHelper from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.regexp.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import dict from '@/mixins/dict';
import dictionary from '@/api/dictionary';
import Pagination from '@/components/Pagination';
import DatePicker from '@/components/DatePicker';
import mapStatisticsRequest from '@/api/mapStatistics.js';
import OemSelect from '@/components/OemSelect';
import { getBeforeDate, getLastMonthDays, getCurrentDate, commonExport, getSupplyers } from '@/utils/common';
export default {
  name: 'vehicleInsurancePolicyMonitor',
  components: {
    Pagination: Pagination,
    OemSelect: OemSelect,
    DatePicker: DatePicker
  },
  mixins: [initHeight, dict],
  computed: {},
  data: function data() {
    return {
      orgForm: {
        salesOrgCode: '',
        salesOrgName: ''
      },
      bizType: localStorage.getItem("bizType") || '',
      drawer: false,
      searchForm: {},
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      statisticsStartEndDate: [getLastMonthDays(), getCurrentDate()],
      loading: false,
      supplyers: [],
      vehicleStampList: [{
        itemName: '新车',
        itemCode: 'NEW_CAR'
      }, {
        itemName: '次新车',
        itemCode: 'LITTLE_OLD_CAR'
      }, {
        itemName: '旧车',
        itemCode: 'OLD_CAR'
      }],
      PolicySourceList: [],
      renewalTypeList: []
    };
  },
  created: function created() {
    this.getPolicySourceByCode();
    this.getRenewalTypeList();
    this.supplyers = getSupplyers();
    if (localStorage.getItem('userInfo')) {
      var userInfo = JSON.parse(localStorage.getItem('userInfo'));
      if (userInfo.bizType == 'DEALER') {
        this.searchForm.salesOrgCode = userInfo.data && userInfo.data.organization.dealerCode ? userInfo.data.organization.dealerCode : userInfo.data.organization.orgCode;
        this.searchForm.salesOrgName = userInfo.data && userInfo.data.organization.dealerName ? userInfo.data.organization.dealerName : userInfo.data.organization.orgName;
      }
    }
  },
  mounted: function mounted() {
    this.loadData();
  },
  methods: {
    getSummaries: function getSummaries(param) {
      var columns = param.columns,
        data = param.data;
      var sums = [];
      columns.forEach(function (column, index) {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        var values = data.map(function (item) {
          return Number(item[column.property]);
        });
        if (!values.every(function (value) {
          return isNaN(value);
        }) && ["bzPolicyQty", "bzPremium", "bsPolicyQty", "bsPremium", "fcPolicyQty", "fcPremium"].includes(column.property)) {
          sums[index] = values.reduce(function (prev, curr) {
            var value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          if (column.property == 'bzPolicyQty' || column.property == 'bsPolicyQty' || column.property == 'fcPolicyQty') {
            sums[index] = parseInt(sums[index]);
          } else {
            sums[index] = parseFloat(sums[index]).toFixed(2);
          }
        } else {
          sums[index] = '';
        }
      });
      return sums;
    },
    // 机构树点击事件
    handleNodeClick: function handleNodeClick(data, node, target) {
      this.searchForm.salesOrgCode = data.salesOrgCode;
      this.searchForm.salesOrgName = data.salesOrgName;
      this.searchForm.areaType = data.salesOrgType;
    },
    // 获取保单来源枚举
    getPolicySourceByCode: function getPolicySourceByCode() {
      var _this = this;
      dictionary.getDictItemsByCode('SOURCE').then(function (res) {
        if (res.code === 200) {
          _this.PolicySourceList = res.data ? res.data : [];
        }
      });
    },
    //获取保单类型
    getRenewalTypeList: function getRenewalTypeList() {
      var _this2 = this;
      dictionary.getDictItemsByCode("RENEWAL_TYPE").then(function (res) {
        if (res.data) {
          _this2.renewalTypeList = res.data;
        }
      });
    },
    exportFn: function exportFn() {
      var data = _objectSpread({}, this.searchForm);
      if (data.renewalTypeList) {
        data.renewalTypeList = data.renewalTypeList.toString();
      }
      if (data.sourceList) {
        data.sourceList = data.sourceList.toString();
      }
      if (data.vehicleStampList) {
        data.vehicleStampList = data.vehicleStampList.toString();
      }
      var exporParams = {
        total: this.total,
        limitNum: 200000,
        fileName: '车险保单汇总表',
        exportUrl: '/vi-core-service/policy/stats/search',
        searchForm: data
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    // 日期选择事件
    getPickersDate: function getPickersDate(name, val) {
      if (val && val.length > 1) {
        this[name] = val;
      } else {
        this[name] = [];
      }
    },
    //重置搜索表单
    resetSearch: function resetSearch() {
      if (this.bizType == 'OEM') {
        this.searchForm = {};
        this.orgForm = {
          salesOrgName: '全部',
          salesOrgType: '',
          salesOrgCode: ''
        };
      } else {
        this.searchForm = {};
      }
      this.statisticsStartEndDate = [getLastMonthDays(), getCurrentDate()];
    },
    //搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    //获取列表数据
    loadData: function loadData() {
      var _this3 = this;
      this.loading = true;
      this.drawer = false;
      if (this.statisticsStartEndDate) {
        this.searchForm.statisticsStartDate = this.statisticsStartEndDate[0] && this.statisticsStartEndDate[0].split(' ')[0] + ' 00:00:00' || null;
        this.searchForm.statisticsEndDate = this.statisticsStartEndDate[1] && this.statisticsStartEndDate[1].split(' ')[0] + ' 23:59:59' || null;
      }
      var data = _objectSpread({}, this.searchForm);
      if (data.renewalTypeList) {
        data.renewalTypeList = data.renewalTypeList.toString();
      }
      if (data.sourceList) {
        data.sourceList = data.sourceList.toString();
      }
      if (data.vehicleStampList) {
        data.vehicleStampList = data.vehicleStampList.toString();
      }
      mapStatisticsRequest.getPolicySearch(_objectSpread(_objectSpread({}, this.listQuery), data)).then(function (res) {
        // this.loading = false;
        if (res.data) {
          var resData = res.data.list ? res.data.list : [];
          var _iterator = _createForOfIteratorHelper(resData),
            _step;
          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var i = _step.value;
              i.statisticsDate = i.statisticsDate.split(' ')[0];
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }
          _this3.tableData = resData;
          //总条数
          _this3.total = res.data.page.recordsTotal;
        }
        _this3.$nextTick(function () {
          _this3.loading = false;
          if (_this3.$refs.multipleTable) {
            _this3.$refs.multipleTable.doLayout();
          }
        });
      }).catch(function (err) {
        _this3.loading = false;
      });
    }
  }
};