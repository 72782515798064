import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import request from '@/api/dataCenter';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    currentId: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      form: {
        insCorpCode: '',
        insCorpName: '',
        insuredList: [],
        ownerVehicle: {},
        riskList: [],
        riskListBS: {}
      },
      loading: false
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    initData: function initData() {
      var _this = this;
      this.form = {
        insCorpCode: '',
        insCorpName: '',
        insuredList: [],
        ownerVehicle: {},
        riskList: [],
        riskListBS: []
      };
      this.loading = true;
      request.queryRenewalInformation(this.currentId).then(function (res) {
        _this.loading = false;
        if (res.code === 200) {
          _this.form = res.data;
          _this.form.riskListBS = _this.form.riskList.find(function (val) {
            return val.riskType == 'BS';
          });
        }
        ;
      }).catch(function (err) {
        _this.loading = false;
      });
    },
    // 拼接车型价格展示字符串
    getProposalVehicleType: function getProposalVehicleType(item) {
      if (!item || !item.modelName) return "";
      //车型展示规则
      var modelCode = item.makerModelCode ? item.makerModelCode : item.industryModelCode;
      if (!modelCode) return "";
      var remark = item.remark ? item.remark : item.vehicleAlias;
      // let name = _.join([modelCode, item.modelName, item.modelName, remark, `${item.seats}座`, item.purchasePrice, item.yearPattern], "/");
      var name = modelCode ? _.join([modelCode, item.modelName], '/') : item.modelName;
      // name = item.modelName ? _.join([name, item.modelName], '/') : name;
      name = remark ? _.join([name, remark], '/') : name;
      name = item.seats ? _.join([name, "".concat(item.seats, "\u5EA7")], '/') : name;
      name = item.purchasePrice ? _.join([name, item.purchasePrice], '/') : name;
      name = item.yearPattern ? _.join([name, item.yearPattern], '/') : name;
      return name;
    },
    // 拼接增值服务次数
    getQuantity: function getQuantity(item) {
      var text = '';
      if (!_.isEmpty(item.serviceTermList)) {
        item.serviceTermList.forEach(function (item) {
          text = text + item.serviceText + ':' + item.serviceTimes + ',';
        });
      }
      if (text && text.lastIndexOf(',') != -1) {
        text = text.substr(0, text.length - 1);
      } else {
        text = item.quantity;
      }
      return text;
    },
    // 格式化保额
    formatOption: function formatOption(amount) {
      if (amount) {
        var divideValue = _.divide(amount, 10000);
        var text = amount;
        if (divideValue >= 1) {
          text = divideValue + '万';
        }
        return text;
      }
    },
    // 关闭弹窗
    closePage: function closePage() {
      this.$emit('closePage', true);
    }
  }
};