import _defineProperty from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/defineProperty.js";
import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.object.keys.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PageContainer from '@/components/PageContainer';
import FormLabel from '@/components/FormSubLabel';
import request from '@/api/vehicleSalesMaintain';
import historyVehicleSalesMaintainRequest from '@/api/historyVehicleSalesMaintain';
export default {
  name: 'VehicleSalesDetail',
  components: {
    FormLabel: FormLabel,
    PageContainer: PageContainer
  },
  data: function data() {
    return {
      activeName1: '1',
      activeName2: '1',
      form: {},
      currentId: ''
    };
  },
  watch: {
    '$route': function $route(to, from) {
      //监听路由是否变化）
      this.initView();
    }
  },
  created: function created() {
    this.initView();
  },
  methods: {
    initView: function initView() {
      this.currentId = this.$route.query.salesId || '';
      var entry = this.$route.query.entry;
      if (entry == 'historyVehicle') {
        this.getHistoryDetails();
      } else {
        this.getDetails();
      }
    },
    getHistoryDetails: function getHistoryDetails() {
      var _this = this;
      historyVehicleSalesMaintainRequest.getDetailById(this.currentId).then(function (res) {
        if (res.code == 200) {
          var _objectSpread2;
          var _res$data = res.data,
            buyerCityName = _res$data.buyerCityName,
            buyerProvName = _res$data.buyerProvName,
            buyerAreaName = _res$data.buyerAreaName;
          var areas = [buyerProvName, buyerCityName, buyerAreaName];
          // 车主性质
          var CUST_NATUER = [{
            dictName: "个人",
            dictCode: "1"
          }, {
            dictName: "公司",
            dictCode: "2"
          }, {
            dictName: "机关",
            dictCode: "3"
          }, {
            dictName: "组织",
            dictCode: "4"
          }, {
            dictName: "其他",
            dictCode: "5"
          }];
          var ownAttribute = _this.getEnum(CUST_NATUER, "dictCode", res.data.buyerPart);
          _this.form = _objectSpread(_objectSpread({}, res.data), {}, (_objectSpread2 = {
            name: res.data.buyerName,
            mobile: res.data.buyerTel1,
            vin: res.data.frameNo,
            seats: res.data.seating,
            email: res.data.buyerEmail,
            displacement: res.data.vhcExhaust,
            deliveryDate: res.data.deliveryEndDate
          }, _defineProperty(_objectSpread2, "seats", res.data.seating), _defineProperty(_objectSpread2, "birthday", res.data.buyerBirthday), _defineProperty(_objectSpread2, "mobileHolderCertificate", res.data.buyerCertiType), _defineProperty(_objectSpread2, "address", res.data.buyerAddress), _defineProperty(_objectSpread2, "vehiclePrice", res.data.vhcBodyPrice), _defineProperty(_objectSpread2, "modelName", res.data.model), _defineProperty(_objectSpread2, "sex", res.data.buyerSex), _defineProperty(_objectSpread2, "regionCode", ""), _defineProperty(_objectSpread2, "certificateNo", res.data.buyerId), _defineProperty(_objectSpread2, "relationshipAttribute", ownAttribute && ownAttribute.dictName), _objectSpread2));
          _this.form.regionCode = areas.join("-");
        }
      });
    },
    // 获取枚举值
    getEnum: function getEnum(soureData, v1, v2) {
      return _.find(soureData, function (item) {
        return item[v1] === v2;
      });
    },
    getDetails: function getDetails() {
      var _this2 = this;
      request.getVehicleSalesDeatils(this.currentId).then(function (res) {
        if (res.code === 200) {
          var _res$data2 = res.data,
            ownerProvinceName = _res$data2.ownerProvinceName,
            ownerCityName = _res$data2.ownerCityName,
            ownerDistrictName = _res$data2.ownerDistrictName;
          var areas = [ownerProvinceName, ownerCityName, ownerDistrictName];
          // 车主性质
          var CUST_NATUER = JSON.parse(localStorage.getItem('VI_CUST_NATUER')) || [];
          var ownAttribute = _this2.getEnum(CUST_NATUER, "dictCode", res.data.ownAttribute);
          // 证件类型
          var VI_CERTIFICATE_TYPE = JSON.parse(localStorage.getItem('VI_CERTIFICATE_TYPE')) || [];
          var certificate = _this2.getEnum(VI_CERTIFICATE_TYPE, "dictCode", res.data.ownerCertificateType);
          // 号牌颜色
          var PLATE_COLOR = JSON.parse(localStorage.getItem("VI_PLATE_COLOR")) || [];
          var plateColor = _this2.getEnum(PLATE_COLOR, "dictCode", res.data.plateColor);
          // 号牌种类
          var PLATE_TYPE = JSON.parse(localStorage.getItem("VI_PLATE_TYPE")) || [];
          var plateTypeDisplay = _this2.getEnum(PLATE_TYPE, "dictCode", res.data.plateType);
          // 使用性质
          var USE_ATTRIBUTE = JSON.parse(localStorage.getItem("VI_USE_ATTRIBUTE")) || [];
          var useAttributeDisplay = _this2.getEnum(USE_ATTRIBUTE, "dictCode", res.data.useAttribute);
          // 行驶证车辆类型
          var VEHICLE_KINDS = _this2.VEHICLE_KIND = JSON.parse(localStorage.getItem("VI_VEHICLE_KIND")) || [];
          var vehicle = _this2.getEnum(VEHICLE_KINDS, "dictCode", res.data.vehicleType);
          // 能源类型
          var VI_ENGRGY_TYPE = _this2.VEHICLE_KIND = JSON.parse(localStorage.getItem("VI_ENGRGY_TYPE")) || [];
          var engrgy = _this2.getEnum(VI_ENGRGY_TYPE, "dictCode", res.data.fuelType);
          _this2.form = _objectSpread(_objectSpread({}, res.data), {}, {
            name: res.data.ownerName,
            relationshipAttribute: ownAttribute && ownAttribute.dictName,
            certificateType: certificate && certificate.dictName,
            plateColorDisplay: plateColor && plateColor.dictName,
            plateTypeDisplay: plateTypeDisplay && plateTypeDisplay.dictName,
            useAttributeDisplay: useAttributeDisplay && useAttributeDisplay.dictName,
            vehicleTypeDisplay: vehicle && vehicle.dictName,
            fuelTypeDisplay: engrgy && engrgy.dictName,
            certificateNo: res.data.ownerCertificateNo,
            ownerCertStartDate: res.data.ownerCertStartDate,
            ownerCertEndDate: res.data.ownerCertEndDate,
            mobile: res.data.ownerMobile,
            email: res.data.ownerEmail,
            regionCode: areas.join("-"),
            birthday: res.data.ownerBirthday,
            sex: res.data.ownerSex == "M" ? '男' : '女',
            plateNo: res.data.plateNo,
            vehicleType: res.data.vehicleType,
            fuelType: res.data.fuelType,
            wholeWeight: res.data.wholeWeight,
            tonNumber: res.data.tonNumber,
            seats: res.data.seats,
            displacement: res.data.displacement,
            registerDate: res.data.registerDate,
            transferFlag: res.data.transferFlag ? "是" : "",
            keyCustomersStamp: res.data.keyCustomersStamp
          });
        }
        ;
      });
    },
    // 返回
    goBack: function goBack() {
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.go(-1);
    }
  }
};