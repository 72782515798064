import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Pagination from '@/components/Pagination';
import customersRequest from '@/api/customers';
export default {
  components: {
    Pagination: Pagination
  },
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    currentItem: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      searchForm: {
        importTaskId: '',
        lastUpdateDate: '',
        taskEndDate: '',
        taskStartDate: ''
      },
      tableData: [],
      loading: false,
      currentId: ''
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.loadData();
      }
      ;
    }
  },
  mounted: function mounted() {
    this.loadData();
  },
  methods: {
    // 获取列表数据
    loadData: function loadData() {
      var _this = this;
      this.tableData = [];
      this.loading = true;
      this.searchForm.importTaskId = this.currentItem.importTaskId;
      this.searchForm.lastUpdateDate = this.currentItem.lastUpdateDate;
      this.searchForm.taskEndDate = this.currentItem.taskEndDate;
      this.searchForm.taskStartDate = this.currentItem.taskStartDate;
      var data = _objectSpread(_objectSpread({}, this.searchForm), this.listQuery);
      customersRequest.quoteDetails(data).then(function (res) {
        _this.loading = false;
        if (res.code === 200) {
          _this.tableData = res.data.list;
          _this.total = res.data.page.recordsTotal;
        }
        ;
      }).catch(function (err) {
        _this.loading = false;
      });
    },
    // 关闭弹窗
    closePage: function closePage() {
      this.$emit('closePage');
    },
    // 详情
    toDetails: function toDetails(item) {
      this.closePage();
      var router_path = this.$route.path;
      this.$router.push({
        path: "/proposal/detail",
        query: {
          quoteId: item.quoteId,
          back: router_path,
          plateNo: item.plateNo,
          vin: item.vin
        }
      });
    },
    getStatusColor: function getStatusColor(proposalStatus) {
      //获取投保状态对应的颜色值
      var color = '';
      switch (proposalStatus) {
        case '03':
        case '08':
        case '12':
          color = '#a0a0a0';
          break;
        case '01':
        case '02':
        case '04':
        case '05':
        case '06':
        case '07':
        case '09':
        case '10':
          color = '#e4a547';
          break;
        case '11':
          color = '#16c86c';
          break;
        default:
          color = '#515151';
          break;
      }
      return color;
    }
  }
};