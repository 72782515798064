import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import Pagination from '@/components/Pagination';
import ListingDialog from './dialog/listingDialog';
import OemSelect from '@/components/OemSelect';
import vehiclesApi from '@/api/vehiclesIntoShop';
import { getUserOrgInfoDealerOem, commonExport } from '@/utils/common';
export default {
  name: 'BlockList',
  components: {
    Pagination: Pagination,
    ListingDialog: ListingDialog,
    OemSelect: OemSelect
  },
  mixins: [initHeight],
  data: function data() {
    return {
      searchForm: {},
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      multipleSelection: [],
      loading: false,
      showDialog: false,
      orgForm: {
        salesOrgCode: '',
        salesOrgName: '',
        salesOrgType: ''
      },
      bizType: localStorage.getItem("bizType") || '',
      opType: '',
      currentCameraBlackId: ''
    };
  },
  created: function created() {
    this.loadData();
    if (this.bizType === "DEALER") {
      this.initData();
    }
    ;
  },
  methods: {
    // 初始化数据
    initData: function initData() {
      var orgObj = getUserOrgInfoDealerOem(this.bizType);
      this.searchForm.orgCode = orgObj.salesOrgCode;
      this.searchForm.orgName = orgObj.salesOrgName;
    },
    // 机构树点击事件
    handleNodeClick: function handleNodeClick(data, node, target) {
      this.searchForm.orgCode = data.salesOrgCode;
      this.searchForm.orgName = data.salesOrgName;
      this.searchForm.areaType = data.salesOrgType;
    },
    // 删除
    batchDelete: function batchDelete(item) {
      var _this = this;
      var ids = [];
      if (!_.isEmpty(item)) {
        ids.push(item.cameraBlackId);
      } else {
        if (!_.isEmpty(this.multipleSelection)) {
          ids = _.map(this.multipleSelection, 'cameraBlackId');
        }
        ;
      }
      ;
      this.$confirm('您确定要删除么？', {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        vehiclesApi.deleteCameraBlack(ids).then(function (res) {
          if (res.code === 200) {
            _this.$message.success(res.msg);
            _this.loadData();
          }
          ;
        });
      });
    },
    // 重置搜索表单
    resetSearch: function resetSearch() {
      this.searchForm = {
        orgName: this.bizType === 'OEM' ? '' : this.searchForm.orgName,
        orgCode: this.bizType === 'OEM' ? '' : this.searchForm.orgCode
      };
      this.orgForm = {
        salesOrgName: this.bizType === 'OEM' ? '全部' : '',
        salesOrgType: '',
        salesOrgCode: ''
      };
    },
    // 搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    // 刷新
    breakData: function breakData() {
      this.loadData();
    },
    // 获取列表数据
    loadData: function loadData() {
      var _this2 = this;
      this.loading = true;
      this.showDialog = false;
      vehiclesApi.getCameraBlackList(this.listQuery.pageNum, this.listQuery.pageSize, this.searchForm).then(function (res) {
        _this2.loading = false;
        if (res.code === 200) {
          _this2.tableData = res.data.list;
          _this2.total = res.data.page.recordsTotal;
        }
        ;
      }).catch(function (err) {
        _this2.loading = false;
      });
    },
    // 列表行选择事件
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 导出
    exportExcels: function exportExcels() {
      var exporParams = {
        total: this.total,
        fileName: '屏蔽清单',
        exportUrl: '/oc-core-service/callIn/cameraBlack',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    // 编辑
    edit: function edit(type, item) {
      this.opType = type;
      this.showDialog = true;
      this.currentCameraBlackId = item.cameraBlackId.toString();
    },
    // 新增
    add: function add(type) {
      this.opType = type;
      this.showDialog = true;
    }
  }
};