import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.concat.js";
import axios from 'axios';
import { Message, Notification } from 'element-ui';
import store from '@/store';
import router from '@/router/modules';
import { showLoading, hideLoading } from "@/utils/globalLoading";
// 创建axios实例
var service = axios.create({
  // baseURL: process.env.BASE_API, // api 的 base_url
  timeout: 3 * 60000 // 请求超时时间
});

var openPostRequest = true;
var configChange = null;

// request拦截器
service.interceptors.request.use(function (config) {
  //protocol更新
  var locationProtocol = window.location.protocol;
  if (window.location.hostname !== 'localhost') config.url = locationProtocol + '//' + config.url.split('//')[1];
  var requestURL = config.url;
  // 普通token
  var token = localStorage.getItem('token') || "";
  config.headers['authorization'] = token.replace(/"/g, ""); // 保留字段 ？？？？？ Authorization
  // 非车H5保司审核页面接口token处理 （accessToken）
  var auditList = ["http://openapi.test.bandins.cn/open/", //测试环境 webTest
  "https://openapi.test.bandins.cn/open/", //测试环境 webTest https
  "http://bxopenapitest.ftms.com.cn/open/", //一丰UAT环境 
  "https://bxopenapitest.ftms.com.cn/open/", //一丰UAT环境  https
  "http://bxopenapi.ftms.com.cn/open/", //一丰生产环境
  "https://bxopenapi.ftms.com.cn/open/" //一丰生产环境 https
  ];
  var auditUrl = _.find(auditList, function (item) {
    return requestURL.indexOf(item) !== -1;
  });
  if (auditUrl) {
    var _token = sessionStorage.getItem('verifyAccessToken') || "";
    config.headers['accessToken'] = _token.replace(/"/g, "");
  }

  // 地图看板和车险业务统计接口header处理列表 专用 (application/json;charset:utf-8)
  var MapWhiteList = ["".concat(process.env.VUE_APP_APIDOMAIN, "/vi-core-service/policy/stats/queryMapAggregateStats"), //4合1接口
  "".concat(process.env.VUE_APP_APIDOMAIN, "/vi-core-service/policy/stats/queryMapAreaStats"), //按地区统计出单/保费 ------ 地区
  "".concat(process.env.VUE_APP_APIDOMAIN, "/vi-core-service/policy/stats/queryMapStatsTrendChart"), //按时间统计出单/保费走势图 ------- 时间
  "".concat(process.env.VUE_APP_APIDOMAIN, "/vi-core-service/policy/stats/queryMapStatsInsCorpRanking"), //按保司统计出单/保费排名 ------ 保司
  "".concat(process.env.VUE_APP_APIDOMAIN, "/vi-core-service/policy/stats/queryMapStatsOrganRanking"), //按机构统计出单/保费排名 ------ 机构
  "".concat(process.env.VUE_APP_APIDOMAIN, "/vi-core-service/policy/stats/queryOrganStatsTrendChart"), //车险业务统计页面 ------ 出单/保费 (时间) 
  "".concat(process.env.VUE_APP_APIDOMAIN, "/vi-core-service/policy/stats/queryOrganStatsInsCorpRanking") //车险业务统计页面 ------ 出单/保费 (保司)
  ];
  var mapUrl = _.find(MapWhiteList, function (item) {
    return requestURL.indexOf(item) !== -1;
  });
  if (mapUrl) {
    config.headers['Content-Type'] = 'application/json;charset:utf-8';
  }
  //防抖接口白名单处理
  var url = requestURL && requestURL.indexOf('?') !== -1 ? requestURL.split('?')[0] : requestURL;
  var whiteList = ["".concat(process.env.VUE_APP_APIDOMAIN, "/biz-nonauto-service/order/save"), // 代步车出单
  "".concat(process.env.VUE_APP_APIDOMAIN, "/vi-core-service/insure/acciden/product"), // 查询驾意险产品(旧)
  "".concat(process.env.VUE_APP_APIDOMAIN, "/vi-core-service/insure/accident/getProductList"), // 查询驾意险产品列表(新)
  "".concat(process.env.VUE_APP_APIDOMAIN, "/tp-auth-service/captcha/getImage"), "".concat(process.env.VUE_APP_APIDOMAIN, "/vi-core-service/insure/media/storageMediaToProposal"), "".concat(process.env.VUE_APP_APIDOMAIN, "/vi-core-service/insure/media/storageMediaTodInsuranceAgency"), "".concat(process.env.VUE_APP_APIDOMAIN, "/vi-core-service/insure/getProposal"), "".concat(process.env.VUE_APP_APIDOMAIN, "/tp-message-service/message/v2/recivingMessageAndReturn"), "".concat(process.env.VUE_APP_APIDOMAIN, "/tp-storage-service/storage/upload"), "".concat(process.env.VUE_APP_APIDOMAIN, "/vi-core-service/insure/media/storageMedia"), "".concat(process.env.VUE_APP_APIDOMAIN, "/tp-storage-service/storage/upload/result"), "".concat(process.env.VUE_APP_APIDOMAIN, "/tp-recognition-service/ocr/doOcr")].concat(MapWhiteList);
  if (config.method.toLowerCase() === 'post' || config.method.toLowerCase() === 'put') {
    if (_.indexOf(whiteList, url) == -1 && config.headers.showLoading) {
      showLoading(config.headers && config.headers.loadingText || '');
      configChange = config;
    }
  }
  if (config.method.toLowerCase() === 'post' && _.indexOf(whiteList, url) == -1) {
    if (openPostRequest) {
      openPostRequest = false;
      return config;
    } else {
      // axios.cancelToken(); 
    }
  } else {
    return config;
  }
}, function (error) {
  Promise.reject(error);
});
// response 拦截器
service.interceptors.response.use(function (response) {
  setTimeout(function () {
    if (response.config.method.toLowerCase() === 'post') {
      openPostRequest = true;
    }
  }, 1000);
  // 关闭loading
  if (configChange) {
    hideLoading();
  }
  if (response) {
    var requestURL = response.config.url;
    // 非车H5保司审核页面接口400处理
    var auditList = ["http://openapi.test.bandins.cn/open/", //测试环境 webTest
    "https://openapi.test.bandins.cn/open/", //测试环境 webTest https
    "http://bxopenapitest.ftms.com.cn/open/", //一丰UAT环境 
    "https://bxopenapitest.ftms.com.cn/open/", //一丰UAT环境  https
    "http://bxopenapi.ftms.com.cn/open/", //一丰生产环境
    "https://bxopenapi.ftms.com.cn/open/" //一丰生产环境 https
    ];
    var auditUrl = _.find(auditList, function (item) {
      return requestURL.indexOf(item) !== -1;
    });
    if (auditUrl) {
      if (response.data.code == 400) {
        router.replace({
          path: "/verifyLogin"
        });
      }
    }
    if (requestURL && requestURL.indexOf('job/execTask') != -1) {
      if (response.data.code == 400) {
        Notification({
          type: 'warning',
          title: '提示',
          message: response.data.msg,
          duration: 0
        });
        return;
      }
    }
  }
  // 网络请求响应
  if (response.status != RESPONSE_SUCCESS) {
    // console.log('RESPONSE_SUCCESS')
    var errMsg = response.status == '400' ? response.msg : "网络环境异常，请稍后重试；如5分钟后还出现同样问题，请联系一店一群小助手解决；";
    Message({
      dangerouslyUseHTMLString: true,
      message: errMsg,
      type: response.status == '500' ? 'error' : 'warning',
      duration: 5 * 1000,
      customClass: 'zZindex'
    });
    return response;
  } else {
    // console.log('不是RESPONSE_SUCCESS')
    var res = response.data;
    if (res.code == undefined) {
      // 文件下载，没有code
      return res;
    } else {
      // code 非'200'
      var requestUrl = response.request.responseURL;
      if (res.code != RESPONSE_SUCCESS && res.code != '401' && res.code != '403') {
        var _errMsg = res.msg;
        if (res.code == '500') {
          _errMsg = '网络环境异常，请稍后重试；如5分钟后还出现同样问题，请联系一店一群小助手解决；';
        }
        if (requestUrl && requestUrl.indexOf('http://openapi.test.bandins.cn') != -1) {
          Message({
            dangerouslyUseHTMLString: true,
            message: _errMsg,
            type: res.code == '500' ? 'error' : 'warning',
            duration: 5 * 1000,
            customClass: 'zZindex',
            center: true
          });
        } else {
          if (requestUrl && requestUrl.indexOf('/insure/quote/getProposal') == -1 && requestUrl.indexOf('/insCorpApiConfig/getFunctionFeature') == -1) {
            var showChangePasswordMsg = requestUrl.indexOf('/token') != -1 && res.code == '400106';
            Message({
              dangerouslyUseHTMLString: true,
              message: showChangePasswordMsg ? '您的密码已过期，请修改密码！' : _errMsg,
              type: res.code == '500' ? 'error' : 'warning',
              duration: 5 * 1000,
              customClass: 'zZindex'
            });
          }
        }
      } else {
        if (res.code == '401' || res.code == '403') {
          if (requestUrl && requestUrl.indexOf('/token/refresh') == -1 && requestUrl.indexOf('/suppliers/getByType') == -1) {
            Message({
              dangerouslyUseHTMLString: true,
              message: "您的登录状态已过期，请重新登录！",
              type: 'warning',
              duration: 3 * 1000,
              customClass: 'zZindex'
            });
          }
          store.dispatch('user/logout');
          store.dispatch('tagsView/delAllCachedViews', router);
          // router.replace({ path: '/login?redirect=/workbench' });
          router.replace({
            path: '/login'
          });
        }
      }
      return res;
    }
  }
}, function (error) {
  setTimeout(function () {
    openPostRequest = true;
  }, 1000);
  // 关闭loading
  if (configChange) {
    hideLoading();
  }
  return Promise.reject(error);
});
export default service;