//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "tableForm",
  props: {
    couponActivityRuleCode: {
      type: String,
      default: ""
    },
    couponActivityRuleName: {
      type: String,
      default: ""
    },
    couponActivityName: {
      type: String,
      default: ""
    }
  }
};