//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import operateManageApi from '@/api/operateManage';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    opType: {
      type: String,
      default: ''
    },
    currentObj: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      form: {
        requestUrl: '',
        userName: '',
        pwd: '',
        enable: true,
        configCode: '',
        configExplain: ''
      },
      rules: {
        requestUrl: {
          required: true,
          message: '请输入请求地址',
          trigger: "blur"
        },
        userName: {
          required: true,
          message: '请输入用户名',
          trigger: "blur"
        },
        pwd: {
          required: true,
          message: '请输入用户密码',
          trigger: "blur"
        },
        enable: {
          required: true,
          message: '请选择是否启用',
          trigger: "change"
        },
        configCode: {
          required: true,
          message: '请输入配置编码',
          trigger: "blur"
        }
      },
      formLoading: false
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
      ;
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    initData: function initData() {
      //初始化数据
      this.form = {
        requestUrl: '',
        userName: '',
        pwd: '',
        enable: true,
        configCode: '',
        configExplain: ''
      };
      if (this.opType === "edit") {
        this.form = _.cloneDeep(this.currentObj);
      }
      ;
      this.$refs.detail.resetFields();
    },
    // 保存数据
    save: function save() {
      var _this = this;
      this.$refs.detail.validate(function (valid) {
        if (valid) {
          _this.formLoading = true;
          var req = _this.opType === 'add' ? operateManageApi.addJyAccount(_this.form) : operateManageApi.editJyAccount(_this.currentObj.id, _this.form);
          req.then(function (res) {
            _this.formLoading = false;
            if (res.code === 200) {
              _this.$message.success(res.msg);
              _this.closePage();
            }
            ;
          }).catch(function (err) {
            _this.formLoading = false;
          });
        }
        ;
      });
    },
    closePage: function closePage() {
      //关闭弹窗
      this.$emit('closePage');
    }
  }
};