//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import repairApi from '@/api/repair';
export default {
  // mixins: [dict],
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    DistributeType: {
      type: Array,
      default: []
    },
    SubDealerList: {
      type: Array,
      default: []
    },
    sendRuleId: {
      type: String,
      default: ''
    },
    opType: {
      type: String,
      default: ""
    }
  },
  data: function data() {
    return {
      form: {
        useOrgCode: '',
        priority: '',
        distributionType: '',
        conductorOrgCode: '',
        conductorUserIdList: []
      },
      Rules: {
        useOrgCode: [{
          required: true,
          message: '请选择应用机构',
          trigger: 'change'
        }],
        priority: [{
          required: true,
          message: '请输入优先级',
          trigger: 'blur'
        }],
        distributionType: [{
          required: true,
          message: '请选择分配方式',
          trigger: 'change'
        }]
      },
      highlightCurrent: true,
      orgData: [],
      defaultProps: {
        children: 'children',
        label: 'fullName'
      },
      Dealerlist: []
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      var _this = this;
      if (newValue) {
        this.initData();
        this.$nextTick(function () {
          _this.$refs['detail'].resetFields();
        });
      }
    }
  },
  mounted: function mounted() {
    var _this2 = this;
    this.initData();
    this.$nextTick(function () {
      _this2.$refs['detail'].resetFields();
    });
  },
  methods: {
    initData: function initData() {
      //初始化数据
      if (this.opType == 'edit' || this.opType == 'copy') {
        this.getSendRuleDetail();
      }
    },
    getSendRuleDetail: function getSendRuleDetail() {
      var _this3 = this;
      repairApi.getSendRuleDetail(this.sendRuleId).then(function (res) {
        if (res.code == 200) {
          _this3.getDealerlist(res.data.conductorOrgCode);
          _this3.form = res.data ? res.data : {};
        }
      });
    },
    getDealer: function getDealer(val) {
      this.Dealerlist = [];
      this.getDealerlist(val);
    },
    getDealerlist: function getDealerlist(code) {
      var _this4 = this;
      var data = {
        dealerCode: code,
        employeeStatus: 'REGULAR',
        employeeType: ''
      };
      repairApi.getDealerlist(data).then(function (res) {
        if (res.code == 200) {
          _this4.Dealerlist = res.data ? res.data : [];
        }
      });
    },
    save: function save() {
      var _this5 = this;
      //保存数据
      this.$refs['detail'].validate(function (valid) {
        if (valid) {
          console.log(_this5.form);
          _this5.form.bizType = 'DEALER';
          // updateSendRule//编辑
          var request = _this5.opType == 'edit' ? repairApi.updateSendRule(_this5.sendRuleId, _this5.form) : repairApi.addSendRule(_this5.form);
          request.then(function (res) {
            if (res.code == 200) {
              _this5.$message({
                message: res.msg,
                type: 'success'
              });
              _this5.closePage();
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    closePage: function closePage() {
      //关闭弹窗
      this.$refs['detail'].resetFields();
      this.$emit('closePage');
    }
  }
};