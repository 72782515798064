var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "app-m-cls" }, [
        _c(
          "div",
          { staticClass: "table-cls normal-btn-cls" },
          [
            _c(
              "el-card",
              { staticClass: "box-card", attrs: { shadow: "never" } },
              [
                _c(
                  "div",
                  {
                    staticClass: "table-btn-cls",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [
                    _c(
                      "el-button",
                      { attrs: { size: "mini" }, on: { click: _vm.breakData } },
                      [_vm._v("刷新")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.addArea("add", "PROVINCE")
                          },
                        },
                      },
                      [_vm._v("添加省")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.addArea("add", "CITY")
                          },
                        },
                      },
                      [_vm._v("添加市")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.addArea("add", "DISTRICT")
                          },
                        },
                      },
                      [_vm._v("添加区")]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    ref: "table",
                    staticStyle: { width: "100%" },
                    attrs: {
                      size: "mini",
                      border: "",
                      data: _vm.tableData,
                      height: _vm.tableHeightD,
                      "header-cell-style": { background: "#F7F7F7" },
                      "highlight-current-row": "",
                      "row-key": "districtId",
                      lazy: "",
                      load: _vm.load,
                      "tree-props": {
                        children: "child",
                        hasChildren: "hasChild",
                      },
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "name",
                        label: "区域名称",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "typeDisplay",
                        label: "区域类型",
                        width: "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "操作",
                        align: "center",
                        width: "140",
                        fixed: "right",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.editArea(
                                        "edit",
                                        scope.row.type,
                                        scope.row
                                      )
                                    },
                                  },
                                },
                                [_vm._v("编辑")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "danger",
                                    plain: "",
                                    size: "mini",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.deleteArea(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showDialog,
            "close-on-click-modal": false,
            width: "540px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [
                  _vm._v(
                    _vm._s(_vm.currentStatus == "add" ? "新增" : "编辑") +
                      _vm._s(_vm.changeCurrentType(_vm.currentType))
                  ),
                ]
              ),
            ]
          ),
          _c("ProvincesDialog", {
            attrs: {
              currentType: _vm.currentType,
              currentStatus: _vm.currentStatus,
              refresh: _vm.showDialog,
              currentId: _vm.currentDistrictId,
            },
            on: { closePage: _vm.refreshData },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }