var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-m-cls view-dialog" }, [
    _c(
      "div",
      { staticClass: "table-cls normal-btn-cls" },
      [
        _c(
          "el-card",
          { staticClass: "box-card", attrs: { shadow: "never" } },
          [
            _vm.currentTypeArr.includes(_vm.currentType)
              ? _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    ref: "table",
                    staticClass: "index-center",
                    staticStyle: { width: "100%" },
                    attrs: {
                      size: "mini",
                      border: "",
                      data: _vm.tableData,
                      height: "450",
                      "header-cell-style": { background: "#F7F7F7" },
                      "highlight-current-row": "",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "projectName",
                        label: "项目",
                        "min-width": "200",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "sort", label: "行次", width: "50" },
                    }),
                    _vm.currentType === "PROFIT"
                      ? _c("el-table-column", {
                          key: Math.random(),
                          attrs: {
                            prop: "currentMonth",
                            label: "本月数",
                            width: "150",
                            "show-overflow-tooltip": "",
                          },
                        })
                      : _vm._e(),
                    _vm.currentType === "PROFIT"
                      ? _c("el-table-column", {
                          key: Math.random(),
                          attrs: {
                            prop: "currentYear",
                            label: "本年数",
                            width: "150",
                            "show-overflow-tooltip": "",
                          },
                        })
                      : _vm._e(),
                    _vm.currentType === "BALANCE"
                      ? _c("el-table-column", {
                          key: Math.random(),
                          attrs: {
                            prop: "yearStart",
                            label: "年初数",
                            width: "150",
                            "show-overflow-tooltip": "",
                          },
                        })
                      : _vm._e(),
                    _vm.currentType === "BALANCE"
                      ? _c("el-table-column", {
                          key: Math.random(),
                          attrs: {
                            prop: "yearEnd",
                            label: "期末数",
                            width: "150",
                            "show-overflow-tooltip": "",
                          },
                        })
                      : _vm._e(),
                    _vm.currentType === "ORG"
                      ? _c("el-table-column", {
                          key: Math.random(),
                          attrs: {
                            prop: "quantity",
                            label: "数值",
                            width: "150",
                            "show-overflow-tooltip": "",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm.currentType === "INSURANCE"
              ? _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    ref: "table",
                    staticStyle: { width: "100%" },
                    attrs: {
                      size: "mini",
                      border: "",
                      data: _vm.tableData,
                      height: "450",
                      "header-cell-style": { background: "#F7F7F7" },
                      "highlight-current-row": "",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "insuranceName",
                        label: "产险业务",
                        "min-width": "200",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "sort", label: "行次", width: "50" },
                    }),
                    _c(
                      "el-table-column",
                      { attrs: { label: "保费金额", align: "right" } },
                      [
                        _c("el-table-column", {
                          attrs: {
                            prop: "standardPremium",
                            align: "right",
                            label: "累计",
                            width: "100",
                            "show-overflow-tooltip": "",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-table-column",
                      { attrs: { label: "应付保费", align: "right" } },
                      [
                        _c("el-table-column", {
                          attrs: {
                            prop: "actualPremium",
                            align: "right",
                            label: "累计",
                            width: "100",
                            "show-overflow-tooltip": "",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-table-column",
                      { attrs: { label: "经纪佣金", align: "right" } },
                      [
                        _c("el-table-column", {
                          attrs: {
                            prop: "commission",
                            align: "right",
                            label: "累计",
                            width: "100",
                            "show-overflow-tooltip": "",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-table-column",
                      {
                        attrs: {
                          label: "其中自营网络平台渠道",
                          align: "right",
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            prop: "autotrophyPremium",
                            align: "right",
                            label: "保费金额",
                            width: "100",
                            "show-overflow-tooltip": "",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "autotrophyCommission",
                            align: "right",
                            label: "经纪佣金",
                            width: "100",
                            "show-overflow-tooltip": "",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-table-column",
                      {
                        attrs: {
                          label: "其中第三方网络平台渠道",
                          align: "right",
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            prop: "thirdpartyPremium",
                            align: "right",
                            label: "保费金额",
                            width: "100",
                            "show-overflow-tooltip": "",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "thirdpartyCommission",
                            align: "right",
                            label: "经纪佣金",
                            width: "100",
                            "show-overflow-tooltip": "",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "justify-content": "center",
              "margin-top": "8px",
            },
          },
          [
            _c(
              "el-button",
              {
                attrs: { icon: "el-icon-close", size: "mini" },
                on: { click: _vm.closePage },
              },
              [_vm._v("关闭")]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }