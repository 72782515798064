var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.followHistory.length
      ? _c(
          "div",
          [
            _c(
              "el-timeline",
              { staticClass: "remove-style" },
              _vm._l(_vm.followHistory, function (item, index) {
                return _c(
                  "el-timeline-item",
                  {
                    key: index,
                    attrs: {
                      timestamp: item.createdTime
                        ? item.createdTime.slice(0, 10)
                        : "",
                      color: "#2150A3",
                      placement: "top",
                    },
                  },
                  [
                    _c("el-card", [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "16px",
                            "font-weight": "700",
                          },
                        },
                        [_vm._v(_vm._s(item.trackClassifyName))]
                      ),
                      _c("div", { attrs: { title: item.trackStatusName } }, [
                        _vm._v(" " + _vm._s(item.trackStatusName) + " "),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "text-nowrap",
                          attrs: { title: item.trackContent },
                        },
                        [
                          _vm._v(
                            " 本次跟进内容：" + _vm._s(item.trackContent) + " "
                          ),
                        ]
                      ),
                      _c("div", {
                        staticStyle: { "line-height": "20px" },
                        domProps: { innerHTML: _vm._s(item.contentSupplement) },
                      }),
                      item.trackClassifyCode === "TRACKING"
                        ? _c(
                            "div",
                            { attrs: { title: item.trackSubscribeTime } },
                            [
                              _vm._v(
                                " 下次跟进时间：" +
                                  _vm._s(
                                    item.trackSubscribeTime
                                      ? item.trackSubscribeTime.slice(0, 10)
                                      : ""
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ],
                  1
                )
              }),
              1
            ),
          ],
          1
        )
      : _c(
          "div",
          {
            staticStyle: {
              "line-height": "100px",
              "text-align": "center",
              color: "#909099",
            },
          },
          [_vm._v(" 暂无数据 ")]
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }