import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Pagination from '@/components/Pagination';
import customersRequest from '@/api/customers';
import ImportDetailDialog from './importDetailDialog';
export default {
  components: {
    Pagination: Pagination,
    ImportDetailDialog: ImportDetailDialog
  },
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    currentId: {
      type: Number,
      default: null
    }
  },
  data: function data() {
    return {
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      searchForm: {
        mainId: ''
      },
      tableData: [],
      loading: false,
      statusList: []
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
      ;
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    initData: function initData() {
      this.searchForm.mainId = this.currentId;
      this.loadData();
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {};
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    breakData: function breakData() {
      // 刷新
      // this.resetSearch();
      this.loadData();
    },
    // 获取列表数据
    loadData: function loadData() {
      var _this = this;
      this.loading = true;
      var data = _objectSpread(_objectSpread({}, this.searchForm), this.listQuery);
      customersRequest.searchItemCustomerImport(data).then(function (res) {
        _this.loading = false;
        if (res.code === 200) {
          _this.tableData = res.data.list;
          _this.total = res.data.page.recordsTotal;
        }
        ;
      }).catch(function (err) {
        _this.loading = false;
      });
    },
    // 关闭弹窗
    closePage: function closePage() {
      var boolean = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      this.$emit('closePage', boolean);
    }
  }
};