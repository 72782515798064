var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("PageContainer", [
    _c("div", { attrs: { slot: "main" }, slot: "main" }, [
      _c(
        "div",
        {
          staticClass: "app-m-cls",
          staticStyle: { "min-height": "calc(100vh - 130px)" },
        },
        [
          _c(
            "div",
            { ref: "header", staticClass: "header-cls" },
            [
              _c("el-card", { attrs: { shadow: "never" } }, [
                _c(
                  "div",
                  {
                    staticClass: "title",
                    staticStyle: { "line-height": "25px" },
                  },
                  [
                    _c("FormLabel", {
                      attrs: { "form-label": "历史保单管理详情" },
                    }),
                    _c(
                      "el-form",
                      {
                        staticStyle: { "margin-top": "10px" },
                        attrs: { model: _vm.form, "label-width": "140px" },
                      },
                      [
                        _c(
                          "el-row",
                          { attrs: { gutter: 0 } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "总店经销店：" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.form.headdealercode,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "headdealercode",
                                            $$v
                                          )
                                        },
                                        expression: "form.headdealercode",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "经销商简称：" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.form.dealer_abbr,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "dealer_abbr", $$v)
                                        },
                                        expression: "form.dealer_abbr",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "经销店编码：" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.form.dealercode,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "dealercode", $$v)
                                        },
                                        expression: "form.dealercode",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "分店编号：" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.form.branchcompanyno,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "branchcompanyno",
                                            $$v
                                          )
                                        },
                                        expression: "form.branchcompanyno",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "投保人：" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.form.applicant,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "applicant", $$v)
                                        },
                                        expression: "form.applicant",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "被保险人：" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.form.assured,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "assured", $$v)
                                        },
                                        expression: "form.assured",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "是否按揭：" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value:
                                          _vm.form.mortgageornotDescription,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "mortgageornotDescription",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.mortgageornotDescription",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "车型：" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.form.vhccodeDescription,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "vhccodeDescription",
                                            $$v
                                          )
                                        },
                                        expression: "form.vhccodeDescription",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "其他车型：" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.form.othervhccode,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "othervhccode",
                                            $$v
                                          )
                                        },
                                        expression: "form.othervhccode",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "新车销售时间：" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.form.vhcsalesdate,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "vhcsalesdate",
                                            $$v
                                          )
                                        },
                                        expression: "form.vhcsalesdate",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "车辆使用性质：" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.form.carusetypeDescription,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "carusetypeDescription",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.carusetypeDescription",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "车架号：" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.form.vinno,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "vinno", $$v)
                                        },
                                        expression: "form.vinno",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "投保类型：" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value:
                                          _vm.form.insurancetypeDescription,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "insurancetypeDescription",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.insurancetypeDescription",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "交强险保单号：" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.form.saliid,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "saliid", $$v)
                                        },
                                        expression: "form.saliid",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "交强险保险公司：" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.form.salicompanyname,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "salicompanyname",
                                            $$v
                                          )
                                        },
                                        expression: "form.salicompanyname",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "交强险有效起期：" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.form.salistarttime,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "salistarttime",
                                            $$v
                                          )
                                        },
                                        expression: "form.salistarttime",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "交强险有效止期：" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.form.saliendtime,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "saliendtime", $$v)
                                        },
                                        expression: "form.saliendtime",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "交强险签单日期：" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.form.salibilltime,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "salibilltime",
                                            $$v
                                          )
                                        },
                                        expression: "form.salibilltime",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "交强险保费：" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.form.salibill,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "salibill", $$v)
                                        },
                                        expression: "form.salibill",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "交强险渠道名称：" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.form.salichannel,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "salichannel", $$v)
                                        },
                                        expression: "form.salichannel",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "交强险车商代码：" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.form.salisalecode,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "salisalecode",
                                            $$v
                                          )
                                        },
                                        expression: "form.salisalecode",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "交强险车商名称：" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.form.salisalename,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "salisalename",
                                            $$v
                                          )
                                        },
                                        expression: "form.salisalename",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "交强险认证结果：" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value:
                                          _vm.form
                                            .salichannel_check_statusDescription,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "salichannel_check_statusDescription",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.salichannel_check_statusDescription",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "商业险投保方式：" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.form.cimethodsDescription,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "cimethodsDescription",
                                            $$v
                                          )
                                        },
                                        expression: "form.cimethodsDescription",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "商业险保险公司：" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.form.cicompanyname,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "cicompanyname",
                                            $$v
                                          )
                                        },
                                        expression: "form.cicompanyname",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "商业险保单号：" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.form.ciid,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "ciid", $$v)
                                        },
                                        expression: "form.ciid",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "商业险签单日期：" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.form.cibilltime,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "cibilltime", $$v)
                                        },
                                        expression: "form.cibilltime",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "商业险有效起期：" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.form.cistarttime,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "cistarttime", $$v)
                                        },
                                        expression: "form.cistarttime",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "商业险有效止期：" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.form.ciendtime,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "ciendtime", $$v)
                                        },
                                        expression: "form.ciendtime",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "商业险保费：" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.form.cibill,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "cibill", $$v)
                                        },
                                        expression: "form.cibill",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "商业险渠道名称：" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.form.cichannel,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "cichannel", $$v)
                                        },
                                        expression: "form.cichannel",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "商业险车商代码：" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.form.cisalecode,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "cisalecode", $$v)
                                        },
                                        expression: "form.cisalecode",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "商业险车商名称：" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.form.cisalename,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "cisalename", $$v)
                                        },
                                        expression: "form.cisalename",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "商业险认证结果：" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value:
                                          _vm.form
                                            .salichannel_check_statusDescription,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "salichannel_check_statusDescription",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.salichannel_check_statusDescription",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "里程险(元)：" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.form.mileage_insurance,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "mileage_insurance",
                                            $$v
                                          )
                                        },
                                        expression: "form.mileage_insurance",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "车损险保额(元)：" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.form.dlwcoverage,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "dlwcoverage", $$v)
                                        },
                                        expression: "form.dlwcoverage",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "三者险保额(万元)：" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.form.crcoverageDescription,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "crcoverageDescription",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.crcoverageDescription",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "全车盗抢险购买：" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.form.wcriornotDescription,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "wcriornotDescription",
                                            $$v
                                          )
                                        },
                                        expression: "form.wcriornotDescription",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "乘客保额(万元)：" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value:
                                          _vm.form
                                            .potcipassengerbillDescription,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "potcipassengerbillDescription",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.potcipassengerbillDescription",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "司机保额(万元)：" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value:
                                          _vm.form.potcidiverbillDescription,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "potcidiverbillDescription",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.potcidiverbillDescription",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "其他1：" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.form.others1,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "others1", $$v)
                                        },
                                        expression: "form.others1",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "其他2：" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.form.others2,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "others2", $$v)
                                        },
                                        expression: "form.others2",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "其他3：" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.form.others3,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "others3", $$v)
                                        },
                                        expression: "form.others3",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 16 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "备注栏：" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.form.remarks,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "remarks", $$v)
                                        },
                                        expression: "form.remarks",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "录入员：" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.form.entryclerk,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "entryclerk", $$v)
                                        },
                                        expression: "form.entryclerk",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "系统注册日期：" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.form.sysregtime,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "sysregtime", $$v)
                                        },
                                        expression: "form.sysregtime",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "保险状态：" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value:
                                          _vm.form.insurancestateDescription,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "insurancestateDescription",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.insurancestateDescription",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "是否要发送认证：" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.form.is_sendDescription,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "is_sendDescription",
                                            $$v
                                          )
                                        },
                                        expression: "form.is_sendDescription",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "保单认证申请时间：" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.form.insurance_apply_time,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "insurance_apply_time",
                                            $$v
                                          )
                                        },
                                        expression: "form.insurance_apply_time",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "保单认证时间：" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.form.insurance_auth_time,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "insurance_auth_time",
                                            $$v
                                          )
                                        },
                                        expression: "form.insurance_auth_time",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "活动店内申报1：" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.form.activity_dealer1,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "activity_dealer1",
                                            $$v
                                          )
                                        },
                                        expression: "form.activity_dealer1",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "活动店内申报1时间：" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.form.activity_dealer1_time,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "activity_dealer1_time",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.activity_dealer1_time",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "活动店内申报2：" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.form.activity_dealer2,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "activity_dealer2",
                                            $$v
                                          )
                                        },
                                        expression: "form.activity_dealer2",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "活动店内申报2时间：" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.form.activity_dealer2_time,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "activity_dealer2_time",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.activity_dealer2_time",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "活动厂家反馈1：" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.form.activity_feedback1,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "activity_feedback1",
                                            $$v
                                          )
                                        },
                                        expression: "form.activity_feedback1",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "活动厂家反馈1时间：" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.form.activity_feedback1_time,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "activity_feedback1_time",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.activity_feedback1_time",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "活动厂家反馈2：" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.form.activity_feedback2,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "activity_feedback2",
                                            $$v
                                          )
                                        },
                                        expression: "form.activity_feedback2",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "活动厂家反馈2时间：" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.form.activity_feedback2_time,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "activity_feedback2_time",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.activity_feedback2_time",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "厂家活动支付：" } },
                                  [
                                    _c("el-input", {
                                      attrs: { value: "", disabled: "" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ]
      ),
    ]),
    _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
      _c("div", { staticClass: "footer-btn" }, [
        _c(
          "div",
          { staticClass: "footer-btn-list" },
          [
            _c(
              "el-button",
              {
                attrs: { size: "mini", icon: "el-icon-back" },
                on: { click: _vm.closePage },
              },
              [_vm._v("返回")]
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }