var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "PageContainer",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading",
            },
          ],
        },
        [
          _c("div", { attrs: { slot: "main" }, slot: "main" }, [
            _c(
              "div",
              {
                staticClass: "app-m-cls",
                staticStyle: { "min-height": "calc(100vh - 130px)" },
              },
              [
                _c(
                  "div",
                  { ref: "header", staticClass: "header-cls" },
                  [
                    _c("el-card", { attrs: { shadow: "never" } }, [
                      _c("div", { staticStyle: { padding: "10px" } }, [
                        _c(
                          "div",
                          [
                            _c("svg-icon", {
                              staticStyle: { "font-size": "24px" },
                              attrs: {
                                "icon-class": _vm.reportData.insCorpCode
                                  ? _vm.reportData.insCorpCode.toLowerCase()
                                  : "",
                              },
                            }),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-size": "20px",
                                  "margin-left": "10px",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.reportData.insCorpName || "-")
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c("div", { staticStyle: { "margin-top": "10px" } }, [
                          _c(
                            "div",
                            { staticStyle: { "margin-bottom": "10px" } },
                            [
                              _vm._v(
                                "报案方式: " +
                                  _vm._s(
                                    _vm.reportData.reportTypeDisplay || "-"
                                  )
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticStyle: { "margin-bottom": "10px" } },
                            [
                              _vm._v(
                                "报案状态：" +
                                  _vm._s(
                                    _vm.reportData.reportStatusDisplay || "-"
                                  )
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticStyle: { "margin-bottom": "10px" } },
                            [
                              _vm._v(
                                "报案编号：" +
                                  _vm._s(_vm.reportData.bizReportNo || "-")
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticStyle: { "margin-bottom": "10px" } },
                            [
                              _vm._v(
                                "报案时间：" +
                                  _vm._s(_vm.reportData.createdTime || "-")
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticStyle: { "margin-bottom": "10px" } },
                            [
                              _vm._v(
                                "保单所属机构：" +
                                  _vm._s(_vm.reportData.policyOrgName || "-")
                              ),
                            ]
                          ),
                          _c("div", [
                            _vm._v(
                              "保单号：" +
                                _vm._s(_vm.reportData.policyNo || "-")
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c(
                      "el-collapse",
                      {
                        staticStyle: { "margin-top": "10px" },
                        model: {
                          value: _vm.activeNames,
                          callback: function ($$v) {
                            _vm.activeNames = $$v
                          },
                          expression: "activeNames",
                        },
                      },
                      [
                        _c(
                          "el-collapse-item",
                          {
                            staticClass: "collapse-item-style",
                            attrs: { name: "1" },
                          },
                          [
                            _c("template", { slot: "title" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "title",
                                  staticStyle: { "line-height": "25px" },
                                },
                                [
                                  _c("FormLabel", {
                                    attrs: { "form-label": "报案信息" },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 12,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "card_item" }, [
                                      _c(
                                        "div",
                                        { staticClass: "card_item_label" },
                                        [_vm._v("车牌号码：")]
                                      ),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.reportData.plateNo || "-")
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 12,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "card_item" }, [
                                      _c(
                                        "div",
                                        { staticClass: "card_item_label" },
                                        [_vm._v("车架号：")]
                                      ),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.reportData.vin || "-")
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 12,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "card_item" }, [
                                      _c(
                                        "div",
                                        { staticClass: "card_item_label" },
                                        [_vm._v("品牌型号：")]
                                      ),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.reportData.modelName || "-"
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 12,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "card_item" }, [
                                      _c(
                                        "div",
                                        { staticClass: "card_item_label" },
                                        [_vm._v("报案人姓名：")]
                                      ),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.reportData.reporterName || "-"
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 12,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "card_item" }, [
                                      _c(
                                        "div",
                                        { staticClass: "card_item_label" },
                                        [_vm._v("报案人电话：")]
                                      ),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.reportData.reporterMobile || "-"
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 12,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "card_item" }, [
                                      _c(
                                        "div",
                                        { staticClass: "card_item_label" },
                                        [_vm._v("事故发生地址：")]
                                      ),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.reportData.accidentAddress ||
                                              "-"
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 12,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "card_item" }, [
                                      _c(
                                        "div",
                                        { staticClass: "card_item_label" },
                                        [_vm._v("是否在城市快速或高速路：")]
                                      ),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.reportData.isHighwayDisplay ||
                                              "-"
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 12,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "card_item" }, [
                                      _c(
                                        "div",
                                        { staticClass: "card_item_label" },
                                        [_vm._v("事故发生时间：")]
                                      ),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.reportData.accidentTime || "-"
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 12,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "card_item" }, [
                                      _c(
                                        "div",
                                        { staticClass: "card_item_label" },
                                        [_vm._v("是否在现场：")]
                                      ),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.reportData.isSiteDisplay || "-"
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 12,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "card_item" }, [
                                      _c(
                                        "div",
                                        { staticClass: "card_item_label" },
                                        [_vm._v("事故发生地经纬度：")]
                                      ),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.reportData.accidentLongitude
                                              ? Number(
                                                  _vm.reportData
                                                    .accidentLongitude
                                                ).toFixed(3)
                                              : "-"
                                          ) +
                                            "，" +
                                            _vm._s(
                                              _vm.reportData.accidentLatitude
                                                ? Number(
                                                    _vm.reportData
                                                      .accidentLatitude
                                                  ).toFixed(3)
                                                : "-"
                                            )
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 12,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "card_item" }, [
                                      _c(
                                        "div",
                                        { staticClass: "card_item_label" },
                                        [_vm._v("维修机构门店：")]
                                      ),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.reportData.orgName || "-")
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 12,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "card_item" }, [
                                      _c(
                                        "div",
                                        { staticClass: "card_item_label" },
                                        [_vm._v("报案信息来源：")]
                                      ),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.reportData.netChannelName || "-"
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                                _c("el-col", { attrs: { span: 24 } }, [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        color: "#808080",
                                        "font-size": "14px",
                                        margin: "20px 0 10px 0",
                                      },
                                    },
                                    [_vm._v("具体事故情况")]
                                  ),
                                ]),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 12,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "card_item" }, [
                                      _c(
                                        "div",
                                        { staticClass: "card_item_label" },
                                        [_vm._v("是否仅本车受损：")]
                                      ),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.reportData
                                              .isSelfVehDamagedDisplay || "-"
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 12,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "card_item" }, [
                                      _c(
                                        "div",
                                        { staticClass: "card_item_label" },
                                        [_vm._v("事故类型：")]
                                      ),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.reportData
                                              .accidentTypeDisplay || "-"
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 12,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "card_item" }, [
                                      _c(
                                        "div",
                                        { staticClass: "card_item_label" },
                                        [_vm._v("责任划分：")]
                                      ),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.reportData
                                              .responsibilityDisplay || "-"
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 12,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "card_item" }, [
                                      _c(
                                        "div",
                                        { staticClass: "card_item_label" },
                                        [_vm._v("是否有人伤：")]
                                      ),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.reportData
                                              .isHumanInjuryDisplay || "-"
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 12,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "card_item" }, [
                                      _c(
                                        "div",
                                        { staticClass: "card_item_label" },
                                        [_vm._v("是否伤到他人：")]
                                      ),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.reportData.isHurtOtherDisplay ||
                                              "-"
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 12,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "card_item" }, [
                                      _c(
                                        "div",
                                        { staticClass: "card_item_label" },
                                        [_vm._v("车辆是否可行驶：")]
                                      ),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.reportData
                                              .isNormalDrivingDisplay || "-"
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-collapse",
                      {
                        staticStyle: { "margin-top": "10px" },
                        model: {
                          value: _vm.activeNames,
                          callback: function ($$v) {
                            _vm.activeNames = $$v
                          },
                          expression: "activeNames",
                        },
                      },
                      [
                        _c(
                          "el-collapse-item",
                          {
                            staticClass: "collapse-item-style",
                            attrs: { name: "2" },
                          },
                          [
                            _c("template", { slot: "title" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "title",
                                  staticStyle: { "line-height": "25px" },
                                },
                                [
                                  _c("FormLabel", {
                                    attrs: { "form-label": "转化为推送修工单" },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 12,
                                      md: 12,
                                      lg: 8,
                                      xl: 8,
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "card_item" }, [
                                      _c(
                                        "div",
                                        { staticClass: "card_item_label" },
                                        [_vm._v("已生成推送修工单：")]
                                      ),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.reportData
                                              .isTransformRepairDisplay || "-"
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                                _vm.reportData.isTransformRepair
                                  ? _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 12,
                                          lg: 8,
                                          xl: 8,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "card_item" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "card_item_label",
                                              },
                                              [_vm._v("生成工单时间：")]
                                            ),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.reportData
                                                    .transformRepairTime
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.reportData.isTransformRepair
                                  ? _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 12,
                                          lg: 8,
                                          xl: 8,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "card_item" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "card_item_label",
                                              },
                                              [_vm._v("推送修工单编号：")]
                                            ),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(_vm.reportData.repairNo)
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.reportData.transformRepairStatus ==
                                "TRANSITIONING"
                                  ? _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 24,
                                          md: 24,
                                          lg: 24,
                                          xl: 24,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              icon: "el-icon-refresh",
                                              size: "mini",
                                              plain: "",
                                              type: "primary",
                                              loading: _vm.loadingRepairStatus,
                                            },
                                            on: {
                                              click: _vm.refreshRepairStatus,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.loadingRepairStatus
                                                  ? "刷新中..."
                                                  : "刷新结果"
                                              )
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
            _c("div", { staticClass: "footer-btn" }, [
              _c("div", { staticClass: "footer-btn-list" }, [
                _c(
                  "div",
                  {
                    staticClass: "dialog-footer",
                    staticStyle: {
                      display: "flex",
                      "justify-content": "center",
                    },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { icon: "el-icon-back", size: "mini" },
                        on: { click: _vm.back },
                      },
                      [_vm._v("返回")]
                    ),
                    !_vm.reportData.transformRepairStatus &&
                    _vm.reportData.reportStatus
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              icon: "el-icon-check",
                              size: "mini",
                              type: "primary",
                            },
                            on: {
                              click: function ($event) {
                                _vm.showWorkOrderDialog = true
                              },
                            },
                          },
                          [_vm._v("创建推送修工单")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showWorkOrderDialog,
            "close-on-click-modal": false,
            width: "700px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showWorkOrderDialog = $event
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("创建推送修工单")]
              ),
            ]
          ),
          _c("WorkOrderDialog", {
            attrs: {
              refresh: _vm.showWorkOrderDialog,
              currentForm: _vm.reportData,
            },
            on: { closePage: _vm.getCaseDetails },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }