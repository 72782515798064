import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import dict from '@/mixins/dict';
import Pagination from '@/components/Pagination';
import batchCheckRenewalRequest from '@/api/batchCheckRenewal';
import underWarrantyQueryRequest from '@/api/underWarrantyQuery';
import { getBeforeDate, getCurrentDate, commonExport, getUserOrgInfo } from '@/utils/common';
import dayjs from 'dayjs';
export default {
  name: 'RenewalQueryResult',
  components: {
    Pagination: Pagination,
    getBeforeDate: getBeforeDate,
    getCurrentDate: getCurrentDate
  },
  mixins: [initHeight, dict],
  computed: {},
  data: function data() {
    return {
      drawer: false,
      searchForm: {},
      createDateTime: [getBeforeDate(30), getCurrentDate()],
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      loading: false,
      highlightCurrent: true,
      detailLoading: false,
      activeName: " ",
      multipleSelection: []
    };
  },
  mounted: function mounted() {
    this.loadData();
  },
  methods: {
    selected: function selected(row, index) {
      return row.vin;
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 检查在保
    queryUnderWarranty: function queryUnderWarranty() {
      var _this = this;
      var orgObj = getUserOrgInfo();
      this.$confirm('您确定要执行该操作吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var postData = {
          updateFlag: true,
          creatorOrgCode: orgObj.orgCode,
          creatorBizType: orgObj.bizType,
          taskName: dayjs().format('YYYYMMDDHHmmss')
        };
        postData.vinList = _.map(_this.multipleSelection, 'vin');
        underWarrantyQueryRequest.createUnderWarranty(postData).then(function (res) {
          if (res.code === 200) {
            _this.$message.success("".concat(res.msg, ",\u8BF7\u5230\u5728\u4FDD\u7ED3\u679C\u67E5\u770B\u67E5\u8BE2\u7ED3\u679C"));
          }
          ;
        });
      }).catch(function () {});
    },
    // 重查
    retry: function retry(item) {
      var _this2 = this;
      batchCheckRenewalRequest.retry(item.itemId).then(function (res) {
        if (res.code == 200) {
          _this2.loadData();
          _this2.$message.success(res.msg);
        }
      });
    },
    //日期更改监听事件
    dateChange: function dateChange(start, end, val) {
      var searchForm = {};
      if (val && val.length > 1) {
        searchForm[start] = val[0] + ' 00:00:00';
        searchForm[end] = val[1] + ' 23:59:59';
      } else {
        searchForm[start] = '';
        searchForm[end] = '';
      }
      this.$emit('pickerDateChange', [searchForm[start], searchForm[end]]);
    },
    breakData: function breakData() {
      this.loadData();
    },
    // 导出
    exportFn: function exportFn() {
      var exporParams = {
        total: this.total,
        fileName: '续保结果',
        exportUrl: '/vi-core-service/batch/renewal/resultSearch',
        searchForm: _objectSpread(_objectSpread({}, this.searchForm), {}, {
          queryStatus: this.activeName
        })
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    //重置搜索表单
    resetSearch: function resetSearch() {
      this.searchForm = {};
      this.createDateTime = [getBeforeDate(30), getCurrentDate()];
      this.getDefaultValue();
    },
    //搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    //获取列表数据
    loadData: function loadData() {
      var _this3 = this;
      if (!_.isEmpty(this.createDateTime)) {
        this.searchForm.createdTimeStart = this.createDateTime[0] + " 00:00:00";
        this.searchForm.createdTimeEnd = this.createDateTime[1] + " 23:59:59";
      }
      this.loading = true;
      this.drawer = false;
      batchCheckRenewalRequest.getRenewalResultList(_objectSpread(_objectSpread(_objectSpread({}, this.listQuery), this.searchForm), {}, {
        queryStatus: this.activeName
      })).then(function (res) {
        _this3.loading = false;
        if (res.code === 200) {
          _this3.tableData = res.data.list;
          _this3.total = res.data.page.recordsTotal;
        }
      }).catch(function (err) {
        _this3.loading = false;
      });
    }
  }
};