import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import dict from '@/mixins/dict';
import Pagination from '@/components/Pagination';
import carService from '@/api/carService';
import CarsModelDialog from './dialog/carsModelDialog';
import CarsSeriesDialog from './dialog/carsSeriesDialog';
import CarsBrandDialog from './dialog/carsBrandDialog';
import NonCarsModelDialog from './dialog/nonCarsModelDialog';
import { commonExport } from '@/utils/common';
export default {
  name: "brandModelConfig",
  components: {
    Pagination: Pagination,
    CarsModelDialog: CarsModelDialog,
    CarsSeriesDialog: CarsSeriesDialog,
    CarsBrandDialog: CarsBrandDialog,
    NonCarsModelDialog: NonCarsModelDialog
  },
  mixins: [initHeight, dict],
  computed: {},
  data: function data() {
    return {
      carType: "CarBrand",
      searchForm: {},
      loading: false,
      total: 0,
      CarSeriesTotal: 0,
      CarBrandTotal: 0,
      NonVehicleModelTotal: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      CarSerieslistQuery: {
        pageNum: 1,
        pageSize: 20
      },
      CarBrandlistQuery: {
        pageNum: 1,
        pageSize: 20
      },
      NonVehiclelistQuery: {
        pageNum: 1,
        pageSize: 20
      },
      drawer: false,
      CarModelList: [],
      CarSeriesList: [],
      CarBrandList: [],
      NonVehicleModelList: [],
      showNonCarModelDialog: false,
      //非车车型弹窗
      showCarModelDialog: false,
      //车型弹窗
      showCarSeriesDialog: false,
      //车系弹窗
      showCarBrandDialog: false,
      //品牌弹窗
      NonVehicleModelDialogType: "",
      CarModelDialogType: "",
      CarSeriesDialogType: "",
      CarBrandDialogType: "",
      NonCarModelInsCorpItem: {},
      CarModelInsCorpItem: {},
      CarSeriesInsCorpItem: {},
      CarBrandInsCorpItem: {}
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.loadData();
  },
  methods: {
    // 省/市/区选择
    handleChange: function handleChange(val) {
      var _this = this;
      this.$nextTick(function () {
        _this.searchForm.districtCode = val[1];
      });
    },
    // 导出
    exportFn: function exportFn() {
      var fileName = '';
      var exportUrl = '';
      var total = 0;
      var ele = '';
      if (this.carType && this.carType == 'CarBrand') {
        exportUrl = '/vi-vehicle-service/vehicleBrand/searchBrands';
        fileName = '汽车品牌列表';
        total = this.CarBrandTotal;
        ele = this.$refs.multipleTable;
      } else if (this.carType && this.carType == 'CarSeries') {
        exportUrl = '/vi-vehicle-service/vehicleBrandSeries/searchBrands';
        fileName = '品牌车系列表';
        total = this.CarSeriesTotal;
        ele = this.$refs.multipleTable2;
      } else if (this.carType && this.carType == 'CarModel') {
        exportUrl = '/vi-vehicle-service/vehicleBrandModel/searchBrands';
        fileName = '品牌车型列表';
        total = this.total;
        ele = this.$refs.multipleTable3;
      } else if (this.carType && this.carType == 'nonVehicleModel') {
        exportUrl = '/vi-vehicle-service/nonauto/brand/search';
        fileName = '非车车型版本';
        total = this.NonVehicleModelTotal;
        ele = this.$refs.multipleTable4;
      }
      var exporParams = {
        total: total,
        limitNum: 200000,
        fileName: fileName || "厂牌车型列表",
        exportUrl: exportUrl,
        searchForm: this.searchForm
      };
      commonExport(exporParams, ele);
    },
    // 树形点击
    nodeClick: function nodeClick(data) {
      this.searchForm.orgCode = data.salesOrgCode;
    },
    reload: function reload() {
      this.loadData();
      this.$forceUpdate();
    },
    // tab切换事件
    handleTabClick: function handleTabClick(tab, event) {
      this.listQuery.pageNum = 1;
      this.NonVehiclelistQuery.pageNum = 1;
      this.CarSerieslistQuery.pageNum = 1;
      this.NonVehicleModelList = [];
      this.CarBrandList = [];
      this.CarModelList = [];
      this.CarSeriesList = [];
      this.carType = tab.name;
      this.loadData();
      this.$forceUpdate();
    },
    // 新增
    toAdd: function toAdd(type) {
      if (this.carType == 'CarModel') {
        this.CarModelDialogType = type;
        this.showCarModelDialog = true;
      } else if (this.carType == 'CarSeries') {
        this.CarSeriesDialogType = type;
        this.showCarSeriesDialog = true;
      } else if (this.carType == 'CarBrand') {
        this.CarBrandDialogType = type;
        this.showCarBrandDialog = true;
      } else {
        this.NonVehicleModelDialogType = type;
        this.showNonCarModelDialog = true;
      }
    },
    toEdit: function toEdit(type, item) {
      if (this.carType == 'CarModel') {
        this.CarModelDialogType = type;
        this.showCarModelDialog = true;
        this.CarModelInsCorpItem = item;
      } else if (this.carType == 'CarSeries') {
        this.CarSeriesDialogType = type;
        this.showCarSeriesDialog = true;
        this.CarSeriesInsCorpItem = item;
      } else if (this.carType == 'CarBrand') {
        this.CarBrandDialogType = type;
        this.showCarBrandDialog = true;
        this.CarBrandInsCorpItem = item;
      } else {
        this.NonVehicleModelDialogType = type;
        this.showNonCarModelDialog = true;
        this.NonCarModelInsCorpItem = item;
      }
    },
    BrandDel: function BrandDel(item) {
      var _this2 = this;
      this.$confirm("\u60A8\u786E\u5B9A\u8981\u5C06<span style='color:red'>\u201C".concat(item.brandName, "\u201D</span>\u54C1\u724C\u5220\u9664\uFF1F \u5220\u9664<span style='color:red'>\u201C").concat(item.brandName, "\u201D</span>\u54C1\u724C\u5C06\u4F1A\u5220\u9664\u6240\u6709\u4E0E<span style='color:red'>\u201C").concat(item.brandName, "\u201D</span>\u54C1\u724C\u6709\u5173\u7684\u8F66\u7CFB\u8F66\u578B"), {
        distinguishCancelAndClose: true,
        dangerouslyUseHTMLString: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        carService.brandDelete(item.vehicleBrandId).then(function (res) {
          if (res.code === 200) {
            _this2.$message({
              message: res.msg,
              type: 'success'
            });
            _this2.loadData();
          }
        });
      }).catch(function () {});
    },
    SeriesDel: function SeriesDel(item) {
      var _this3 = this;
      this.$confirm("\u60A8\u786E\u5B9A\u8981\u5C06<span style='color:red'>\u201C".concat(item.seriesName, "\u201D</span>\u8F66\u7CFB\u5220\u9664\uFF1F \u5220\u9664<span style='color:red'>\u201C").concat(item.seriesName, "\u201D</span>\u8F66\u7CFB\u5C06\u4F1A\u5220\u9664\u6240\u6709\u4E0E<span style='color:red'>\u201C").concat(item.seriesName, "\u201D</span>\u8F66\u7CFB\u6709\u5173\u7684\u8F66\u578B"), {
        distinguishCancelAndClose: true,
        dangerouslyUseHTMLString: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        carService.carSeriesDelete(item.vehicleBrandSeriesId).then(function (res) {
          if (res.code === 200) {
            _this3.$message({
              message: res.msg,
              type: 'success'
            });
            _this3.loadData();
          }
        });
      }).catch(function () {});
    },
    CarModelDel: function CarModelDel(item) {
      var _this4 = this;
      this.$confirm("\u60A8\u786E\u5B9A\u8981\u5C06<span style='color:red'>\u201C".concat(item.modelName, "\u201D</span>\u8F66\u578B\u5220\u9664\uFF1F "), {
        distinguishCancelAndClose: true,
        dangerouslyUseHTMLString: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        carService.delete(item.vehicleBrandModelId).then(function (res) {
          if (res.code === 200) {
            _this4.$message({
              message: res.msg,
              type: 'success'
            });
            _this4.loadData();
          }
        });
      }).catch(function () {});
    },
    nonCarModelDel: function nonCarModelDel(item) {
      var _this5 = this;
      this.$confirm("\u60A8\u786E\u5B9A\u8981\u5C06\u975E\u8F66\u8F66\u578B\u7248\u672C<span style='color:red'>\u201C".concat(item.modelName, "\u201D</span>\u5220\u9664\uFF1F "), {
        distinguishCancelAndClose: true,
        dangerouslyUseHTMLString: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        carService.deleteNonBrand(item.vehicleNonautoBrand).then(function (res) {
          if (res.code === 200) {
            _this5.$message({
              message: res.msg,
              type: 'success'
            });
            _this5.loadData();
          }
        });
      }).catch(function () {});
    },
    detail: function detail(item) {
      var _this6 = this;
      this.showViewDialog = true;
      OCRRequest.getDetail(item.ocrFunctionInvokeRecordId).then(function (res) {
        console.log(res);
        if (res.code == 200) {
          _this6.details = res.data ? res.data : {};
        }
      });
    },
    // 重置搜索表单
    resetSearch: function resetSearch() {
      this.listQuery.pageNum = 1;
      this.searchForm = {
        InsuranceCode: null,
        insCorpCode: null
      };
      this.cascaderAddr = [];
    },
    // 搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.CarBrandlistQuery.pageNum = 1;
      this.CarSerieslistQuery.pageNum = 1;
      this.NonVehiclelistQuery.pageNum = 1;
      this.loadData();
    },
    getNonVehicleModelList: function getNonVehicleModelList() {
      var _this7 = this;
      carService.getNonVehicleModelList(this.NonVehiclelistQuery.pageNum, this.NonVehiclelistQuery.pageSize, this.searchForm).then(function (res) {
        _this7.loading = false;
        if (res.code == 200) {
          _this7.NonVehicleModelList = res.data && res.data.list ? res.data.list : [];
          _this7.NonVehicleModelTotal = res.data && res.data.page ? res.data.page.recordsTotal : 0;
        }
      });
    },
    getCarBrandList: function getCarBrandList() {
      var _this8 = this;
      carService.brandList(this.CarBrandlistQuery.pageNum, this.CarBrandlistQuery.pageSize, this.searchForm).then(function (res) {
        _this8.loading = false;
        if (res.code == 200) {
          _this8.CarBrandList = res.data && res.data.list ? res.data.list : [];
          _this8.CarBrandTotal = res.data && res.data.page ? res.data.page.recordsTotal : 0;
        }
      });
    },
    getCarModelList: function getCarModelList() {
      var _this9 = this;
      carService.getList(this.listQuery.pageNum, this.listQuery.pageSize, this.searchForm).then(function (res) {
        _this9.loading = false;
        if (res.code == 200) {
          _this9.CarModelList = res.data && res.data.list ? res.data.list : [];
          _this9.total = res.data && res.data.page ? res.data.page.recordsTotal : 0;
        }
      });
    },
    getCarSeriesListList: function getCarSeriesListList() {
      var _this10 = this;
      carService.carSeriesList(this.CarSerieslistQuery.pageNum, this.CarSerieslistQuery.pageSize, this.searchForm).then(function (res) {
        _this10.loading = false;
        if (res.code == 200) {
          _this10.CarSeriesList = res.data && res.data.list ? res.data.list : [];
          _this10.CarSeriesTotal = res.data && res.data.page ? res.data.page.recordsTotal : 0;
        }
      });
    },
    // 获取列表数据
    loadData: function loadData() {
      this.loading = true;
      this.showNonCarModelDialog = false;
      this.showCarModelDialog = false;
      this.showCarSeriesDialog = false;
      this.showCarBrandDialog = false;
      if (this.carType == 'CarModel') {
        this.getCarModelList();
      } else if (this.carType == 'CarSeries') {
        this.getCarSeriesListList();
      } else if (this.carType == 'CarBrand') {
        this.getCarBrandList();
      } else {
        this.getNonVehicleModelList();
      }
    },
    // 列表行选择事件
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    }
  }
};