var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: {},
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "500px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "detail",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.formRules,
                    "label-width": "110px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "角色名称：", prop: "roleName" },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  disabled: _vm.opType != "add",
                                  placeholder: "请输入角色名称",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.roleName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "roleName",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form.roleName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "业务端类型", prop: "bizType" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    filterable: "",
                                    placeholder: "请选择业务端类型",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.form.bizType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "bizType", $$v)
                                    },
                                    expression: "form.bizType",
                                  },
                                },
                                _vm._l(
                                  _vm.dictSource["BIZ_TYPE"],
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.dictName,
                                        value: item.dictCode,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-bottom": "5px" },
                      attrs: { label: "系统菜单：" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "custom-div",
                          staticStyle: {
                            "margin-bottom": "10px",
                            border: "1px dashed #eee",
                          },
                        },
                        [
                          _c("el-tree", {
                            ref: "tree",
                            attrs: {
                              "default-expand-all": true,
                              "node-key": "code",
                              props: _vm.props,
                              data: _vm.treeData,
                              "show-checkbox": "",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "角色描述：" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "输入角色描述",
                              clearable: "",
                              type: "textarea",
                              rows: 3,
                            },
                            model: {
                              value: _vm.form.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "remark", $$v)
                              },
                              expression: "form.remark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "line-height": "46px",
            height: "46px",
            padding: "8px 0px",
            "margin-top": "5px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-check",
                type: "primary",
                plain: "",
                size: "mini",
              },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: { click: _vm.closePage },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }