var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.listLoading,
          expression: "listLoading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: { "max-height": "550px" },
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "600px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "detail",
                  staticStyle: { "margin-bottom": "-20px" },
                  attrs: {
                    model: _vm.form,
                    "label-width": "110px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "保险公司：" } },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.DialogData.companyName) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "投保确认码：" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.DialogData.insuranceConfirmCode) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("el-form-item", { attrs: { label: "保单号：" } }, [
                            _vm._v(" " + _vm._s(_vm.DialogData.policyNo) + " "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "保单类型：" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.DialogData.policyTypeDisplay) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "车牌号码：" } },
                            [_vm._v(" " + _vm._s(_vm.DialogData.plateNo) + " ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "号牌种类：" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.DialogData.licenceTypeDisplay) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("el-form-item", { attrs: { label: "车架号：" } }, [
                            _vm._v(" " + _vm._s(_vm.DialogData.vin) + " "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "发动机号：" } },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.DialogData.engineNo) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "起保日期：" } },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.DialogData.beginDate) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "到保日期：" } },
                            [_vm._v(" " + _vm._s(_vm.DialogData.endDate) + " ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "签单日期：" } },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.DialogData.insuredTime) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "投保地区：" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.DialogData.insCityName ||
                                      _vm.DialogData.insAreaCode
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "投保险种：" } },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.DialogData.riskInfo) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "", "label-width": "90px" } },
                            [
                              _c("el-checkbox", {
                                attrs: {
                                  label: "交强险到保日期与商业险一致",
                                  name: "type",
                                },
                                model: {
                                  value: _vm.checkbzLastYearEndDate,
                                  callback: function ($$v) {
                                    _vm.checkbzLastYearEndDate = $$v
                                  },
                                  expression: "checkbzLastYearEndDate",
                                },
                              }),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "font-size": "12px",
                                    color: "#F56C6C",
                                  },
                                },
                                [
                                  _vm._v(
                                    " *勾选此项只为便捷填充交强险到保日期，并不代表此日期为真实交强险到保日期，投保时请再次确认。 "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-check",
                type: "primary",
                plain: "",
                size: "mini",
              },
              on: { click: _vm.save },
            },
            [_vm._v("更新信息")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: { click: _vm.closePage },
            },
            [_vm._v("不更新")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }