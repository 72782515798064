import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Pagination from '@/components/Pagination';
import AddSpecialDialog from './addSpecialDialog.vue';
import insuranceContractManageRequest from '@/api/insuranceContractManage';
import request from "@/api/connectConfig";
export default {
  props: {
    channelConfigId: {
      type: String,
      default: ''
    },
    ChildInsCorpCode: {
      type: String,
      default: ''
    },
    listRefresh: {
      type: Boolean,
      default: true
    }
  },
  components: {
    Pagination: Pagination,
    AddSpecialDialog: AddSpecialDialog
  },
  data: function data() {
    return {
      DialogType: 'add',
      showAddDialog: false,
      loading: false,
      tableData: [],
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      specialConfig: [],
      channelConfigData: []
    };
  },
  created: function created() {
    this.initData();
  },
  watch: {
    listRefresh: function listRefresh(newValue) {
      if (newValue) {
        this.initData();
      }
      ;
    }
  },
  methods: {
    // 关闭弹窗
    loadData: function loadData() {
      this.showAddDialog = false;
      this.DialogType = null;
      this.initData();
    },
    initData: function initData() {
      var _this = this;
      var channelConfigId = this.channelConfigId.toString();
      request.getByChannelId(channelConfigId).then(function (res) {
        if (res.code == 200) {
          _this.channelConfigData = res.data ? res.data : [];
        }
      });
    },
    breakData: function breakData() {
      this.initData();
    },
    getSpecialConfigList: function getSpecialConfigList() {
      var _this2 = this;
      insuranceContractManageRequest.getSpecialConfigList(1, 10).then(function (res) {
        if (res.code == 200) {
          _this2.loading = false;
          _this2.specialConfig = res.data && res.data.list ? res.data.list : [];
        }
      });
    },
    addItem: function addItem(type) {
      this.showAddDialog = true;
      this.DialogType = type;
      this.getSpecialConfigList();
    },
    removeItems: function removeItems(row) {
      var _this3 = this;
      this.$confirm('是否删除当前数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this3.deleteData([row.channelSpecialRelationId]);
      }).catch(function () {});
    },
    deleteData: function deleteData(ids) {
      var _this4 = this;
      request.delChannelSpecial(ids).then(function (res) {
        if (res.code == 200) {
          _this4.$message.success(res.msg);
        }
        _this4.initData();
      });
    },
    closelog: function closelog() {
      this.$emit('closeList');
    }
  }
};