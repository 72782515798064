import "core-js/modules/es.array.concat.js";
/**
 * 奖惩接口
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 奖惩列表
   */
  getRegimenList: function getRegimenList(data) {
    return request.get(getUrl("/regulation/regimen/search", 12), {
      params: data
    });
  },
  /**
   * 奖惩申请
   */
  regimenApply: function regimenApply(data) {
    return request.put(getUrl("/regulation/regimen/create", 12), data);
  },
  /**
   * 查看奖惩
   */
  regimenView: function regimenView(regimenId) {
    return request.get(getUrl("/regulation/regimen/".concat(regimenId), 12));
  },
  /**
   * 奖惩删除
   */
  employeeAgainDelete: function employeeAgainDelete(data) {
    return request.put(getUrl("/regulation/again/delete", 12), data);
  },
  /**
   * 奖惩审核
   */
  changeRegimenAudit: function changeRegimenAudit(leaveOfficeId, auditStatus) {
    return request.put(getUrl("/regulation/regimen/audit/".concat(leaveOfficeId, "?auditStatus=").concat(auditStatus), 12));
  }
};
export default objApi;