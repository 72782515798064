import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.function.name.js";
//
//
//
//

import * as echarts from 'echarts';
require('echarts/theme/macarons'); // echarts theme
import resize from './mixins/resize';
var animationDuration = 6000;
export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '30px'
    },
    chartData: {
      type: String,
      default: 0
    },
    fillColor: {
      type: String,
      default: "#ffcb17"
    }
  },
  data: function data() {
    return {
      chart: null
    };
  },
  watch: {
    chartData: {
      deep: true,
      handler: function handler(val) {
        this.setOptions(val);
      }
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      _this.initChart();
    });
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      this.chart = echarts.init(this.$el, 'macarons');
      this.setOptions(this.chartData.values, this.chartData.name);
    },
    setOptions: function setOptions() {
      this.chart.setOption({
        title: {
          show: true,
          text: this.chartData + "%",
          x: "center",
          y: "center",
          // 通过x,y将标题(进度)定位在圆环中心
          textStyle: {
            fontSize: "13",
            color: "#333333",
            fontWeight: "400"
            //fontFamily: "DINPro, DINPro-Regular",
          }
        },

        tooltip: {
          trigger: "item",
          formatter: "{d}%",
          show: false,
          backgroundColor: 'rgb(0,0,0,0.5)',
          // 提示框浮层的背景颜色。
          borderColor: 'rgb(255,255,255,0.3)',
          // 提示框浮层的边框颜色。
          borderWidth: 0,
          // 提示框浮层的边框宽。
          textStyle: {
            // 提示框浮层的文本样式。
            color: '#fff'
          }
        },
        legend: {
          orient: "vertical",
          x: "left",
          show: false
        },
        series: {
          name: "",
          type: "pie",
          radius: ["75%", "95%"],
          avoidLabelOverlap: true,
          hoverAnimation: false,
          label: {
            normal: {
              show: false,
              position: "center"
            },
            emphasis: {
              show: false
            }
          },
          labelLine: {
            normal: {
              show: false
            }
          },
          data: [{
            value: this.chartData,
            name: "",
            itemStyle: {
              color: this.fillColor
            }
          }, {
            value: 100 - parseFloat(this.chartData),
            name: "",
            itemStyle: {
              color: "#efefef"
            }
          }]
        }
      });
    }
  }
};