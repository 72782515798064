var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.formLoading,
          expression: "formLoading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: {},
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "500px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "addForm",
                  staticStyle: { "margin-bottom": "-20px" },
                  attrs: {
                    model: _vm.addForm,
                    rules: _vm.formRules,
                    "label-width": "100px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "保险公司:",
                                prop: "insCorpCode",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    filterable: "",
                                    disabled: _vm.dialogType == "view",
                                    placeholder: "请选择保险公司",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.addForm.insCorpCode,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.addForm, "insCorpCode", $$v)
                                    },
                                    expression: "addForm.insCorpCode",
                                  },
                                },
                                _vm._l(_vm.supplyers, function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.shortName,
                                      value: item.code,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "特约简称:",
                                prop: "specialShortName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入特约简称",
                                  maxlength: "40",
                                  disabled: _vm.dialogType == "view",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.addForm.specialShortName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.addForm,
                                      "specialShortName",
                                      $$v
                                    )
                                  },
                                  expression: "addForm.specialShortName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "特约编码:",
                                prop: "specialCode",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入特约编码",
                                  maxlength: "40",
                                  disabled: _vm.dialogType == "view",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.addForm.specialCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addForm, "specialCode", $$v)
                                  },
                                  expression: "addForm.specialCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "险种类别:", prop: "riskType" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: "请选择险种类别",
                                    disabled: _vm.dialogType == "view",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.addForm.riskType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.addForm, "riskType", $$v)
                                    },
                                    expression: "addForm.riskType",
                                  },
                                },
                                _vm._l(
                                  _vm.riskTypeList,
                                  function (item, index) {
                                    return _c("el-option", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: item.itemName != "非车险",
                                          expression:
                                            "item.itemName != '非车险'",
                                        },
                                      ],
                                      key: index,
                                      attrs: {
                                        label: item.itemName,
                                        value: item.itemCode,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "特约排序号:",
                                prop: "specialIndex",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.dialogType == "view",
                                  placeholder: "请输入特约排序号",
                                  maxlength: "10",
                                  oninput: "value=value.replace(/[^\\d]/g,'')",
                                },
                                model: {
                                  value: _vm.addForm.specialIndex,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addForm, "specialIndex", $$v)
                                  },
                                  expression: "addForm.specialIndex",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "特约类型:",
                                prop: "specialType",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: "请选择特约类型",
                                    disabled: _vm.dialogType == "view",
                                  },
                                  on: { change: _vm.specialTypeChange },
                                  model: {
                                    value: _vm.addForm.specialType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.addForm, "specialType", $$v)
                                    },
                                    expression: "addForm.specialType",
                                  },
                                },
                                _vm._l(
                                  _vm.specialTypelist,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.value,
                                        value: item.key,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "特约内容:",
                                prop:
                                  "" +
                                  (_vm.addForm.specialType == "CUSTOMIZE"
                                    ? ""
                                    : "specialName"),
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入特约内容",
                                  type: "textarea",
                                  disabled: _vm.dialogType == "view",
                                  "show-word-limit": "",
                                  maxlength: "200",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.addForm.specialName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addForm, "specialName", $$v)
                                  },
                                  expression: "addForm.specialName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _vm.dialogType != "view"
            ? _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-check",
                    type: "primary",
                    plain: "",
                    size: "mini",
                  },
                  on: { click: _vm.save },
                },
                [_vm._v("保存")]
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: { click: _vm.closePage },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }