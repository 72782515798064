//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import Pagination from '@/components/Pagination';
import UploadBillDialog from './dialog/uploadBillDialog';
import OemSelect from '@/components/OemSelect';
import request from '@/api/finance';
import { getSupplyers, getUserOrgInfoDealerOem, commonExport } from '@/utils/common';
export default {
  name: "StandingBook",
  components: {
    Pagination: Pagination,
    UploadBillDialog: UploadBillDialog,
    OemSelect: OemSelect
  },
  mixins: [initHeight],
  data: function data() {
    return {
      drawer: false,
      searchForm: {},
      total: 0,
      orgData: {
        salesOrgName: "",
        salesOrgCode: ""
      },
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      defaultProps: {
        children: 'children',
        label: 'fullName'
      },
      tableData: [],
      loading: false,
      supplyers: [],
      bizType: localStorage.getItem("bizType") || ''
    };
  },
  created: function created() {},
  mounted: function mounted() {
    //保司
    if (this.bizType === "DEALER") {
      this.initData();
    }
    ;
    this.supplyers = getSupplyers();
    this.loadData();
  },
  methods: {
    initData: function initData() {
      var orgObj = getUserOrgInfoDealerOem(this.bizType);
      this.searchForm.salesOrgCode = orgObj.salesOrgCode;
      this.searchForm.salesOrgName = orgObj.salesOrgName;
    },
    // 导出
    exportFn: function exportFn() {
      var exporParams = {
        total: this.total,
        fileName: '财务台账列表',
        exportUrl: '/vi-core-service/financereconciliationdetails/searchUpStandingBook',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    nodeClick: function nodeClick(val) {
      this.searchForm.orgCode = val.salesOrgCode;
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {};
      this.orgData = {};
      this.listQuery.pageNum = 1;
      if (this.bizType === "DEALER") {
        this.initData();
      }
      ;
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    // 刷新
    breakData: function breakData() {
      // this.resetSearch();
      this.loadData();
    },
    loadData: function loadData() {
      var _this = this;
      //获取列表数据
      this.loading = true;
      this.drawer = false;
      var postData = {};
      postData = Object.assign(postData, this.searchForm, this.listQuery);
      request.searchUpStandingBook(postData).then(function (res) {
        _this.loading = false;
        _this.tableData = res.data.list;
        _this.total = res.data.page.recordsTotal;
      }).catch(function (err) {
        _this.loading = false;
      });
    }
  }
};