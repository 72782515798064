import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import dict from '@/mixins/dict';
import Pagination from '@/components/Pagination';
import DatePicker from '@/components/DatePicker';
import OrgSelect from '@/components/OrgSelect';
import VehicleSalesDialog from './dialog/vehicleSalesDialog';
import historyVehicleSalesMaintainRequest from '@/api/historyVehicleSalesMaintain';
import OemSelect from '@/components/OemSelect';
import { character, commonExport, getBeforeDate, getCurrentDate, getUserOrgInfoDealerOem } from '@/utils/common';
export default {
  name: 'HistoryVehicleSalesMaintain',
  components: {
    Pagination: Pagination,
    OrgSelect: OrgSelect,
    VehicleSalesDialog: VehicleSalesDialog,
    OemSelect: OemSelect,
    DatePicker: DatePicker
  },
  mixins: [initHeight, dict],
  computed: {},
  data: function data() {
    return {
      searchForm: {},
      orgForm: {
        salesOrgCode: '',
        salesOrgName: '',
        salesOrgType: ''
      },
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      loading: false,
      showDialog: false,
      opType: "",
      showAddDialog: false,
      bizType: localStorage.getItem("bizType") || '',
      userInfo: JSON.parse(localStorage.getItem("userInfo")) || {},
      salesStartEndTime: [],
      cancelStartEndTime: []
    };
  },
  created: function created() {
    if (this.bizType === "DEALER") {
      var orgObj = getUserOrgInfoDealerOem(this.bizType);
      this.searchForm.dealerCode = orgObj.salesOrgCode;
      this.searchForm.ownerOrgName = orgObj.salesOrgName;
      this.orgForm = {
        salesOrgCode: orgObj.salesOrgCode,
        salesOrgName: orgObj.salesOrgName,
        salesOrgType: ''
      };
    }
  },
  mounted: function mounted() {
    // this.loadData();
  },
  watch: {
    showDialog: function showDialog(newValue) {
      var _this = this;
      if (newValue) {
        this.$nextTick(function () {
          _this.$refs.detail.resetFields();
        });
      }
    }
  },
  methods: {
    //日期选择事件
    getPickersDate: function getPickersDate(name, val) {
      if (val && val.length > 1) {
        this[name] = val;
      } else {
        this[name] = [];
      }
    },
    // 切换机构
    nodeClick: function nodeClick(val) {
      if (this.bizType === "BROKER") {
        this.searchForm.dealerCode = val.salesOrgCode;
        this.searchForm.ownerOrgName = val.salesOrgName;
      } else if (this.bizType === "OEM") {
        this.searchForm.dealerCode = val.salesOrgCode;
        this.searchForm.ownerOrgName = val.salesOrgName;
        this.searchForm.areaType = val.salesOrgType;
      }
    },
    // 切换机构(上传)
    uploadNodeClick: function uploadNodeClick(val) {
      this.uploadForm.ownerOrgCode = val.salesOrgCode;
      this.uploadForm.ownerOrgName = val.salesOrgName;
    },
    breakData: function breakData() {
      // this.resetSearch()
      this.loadData();
    },
    inputvin: function inputvin(val) {
      //监听输入事件
      if (val) {
        val = val.replace(/i/g, '').replace(/I/g, '').replace(/o/g, '').replace(/O/g, '').replace(/q/g, '').replace(/Q/g, '');
        this.searchForm.vin = character(_.toUpper(val));
        this.$forceUpdate();
      }
    },
    checkValue: function checkValue(val) {
      //输入的车架号中的字母自动转大写，并去除i，o，q
      if (val) {
        val = val.replace(/i/g, '').replace(/I/g, '').replace(/o/g, '').replace(/O/g, '').replace(/q/g, '').replace(/Q/g, '');
      }
      this.searchForm.frameNo = character(_.toUpper(val));
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {};
      this.orgForm = {
        salesOrgCode: '',
        salesOrgName: '',
        salesOrgType: ''
      };
      this.salesStartEndTime = [];
      this.cancelStartEndTime = [];
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    loadData: function loadData() {
      var _this2 = this;
      //获取列表数据
      this.loading = true;
      this.drawer = false;
      this.showDialog = false;
      if (this.searchForm.salesOrgCode) {
        this.searchForm.dealerCode = this.searchForm.salesOrgCode;
      }
      if (this.cancelStartEndTime) {
        this.searchForm.beginCancelDate = this.cancelStartEndTime[0] ? this.cancelStartEndTime[0].split(' ')[0] + ' 00:00:00' : null;
        this.searchForm.endCancelDate = this.cancelStartEndTime[1] ? this.cancelStartEndTime[1].split(' ')[0] + ' 23:59:59' : null;
      }
      if (this.salesStartEndTime) {
        this.searchForm.salesStartDate = this.salesStartEndTime[0] ? this.salesStartEndTime[0].split(' ')[0] + ' 00:00:00' : null;
        this.searchForm.salesEndDate = this.salesStartEndTime[1] ? this.salesStartEndTime[1].split(' ')[0] + ' 23:59:59' : null;
      }
      historyVehicleSalesMaintainRequest.getList(_objectSpread(_objectSpread({}, this.listQuery), this.searchForm)).then(function (res) {
        if (res.code === 200) {
          _this2.tableData = res.data.list;
          _this2.loading = false;
          _this2.total = res.data.page.recordsTotal;
        }
      }).catch(function (err) {
        _this2.loading = false;
      });
    },
    // 详情
    viewDetail: function viewDetail(item) {
      this.$router.push({
        path: '/vehicleSalesDetail',
        query: {
          salesId: item.historyVehicleSalesId,
          vin: item.frameNo,
          entry: "historyVehicle"
        }
      });
    },
    exportExcels: function exportExcels() {
      //导出
      var exporParams = {
        total: this.total,
        limitNum: 200000,
        fileName: '历史车辆销售清单列表',
        exportUrl: '/ps-ftms-service/tact/historyVehicleSales/search',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    }
  }
};