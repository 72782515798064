import _createForOfIteratorHelper from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import gatewayConfigRequest from '@/api/gatewayConfig';
import initHeight from '@/mixins/initHeight';
import Pagination from '@/components/Pagination';
import DatePicker from '@/components/DatePicker';
import RouteGroupDialog from './add';
import NonAutoRequest from '@/api/nonAutoRequest';
import CarBrands from '@/components/CarBrands/index';
import CarBrandsModel from '@/components/CarBrands/index_model'; // Secondary package based on el-pagination
import { commonExport } from '@/utils/common';
export default {
  name: 'NonAutoInsuranceProduct',
  mixins: [initHeight],
  components: {
    Pagination: Pagination,
    RouteGroupDialog: RouteGroupDialog,
    CarBrands: CarBrands,
    CarBrandsModel: CarBrandsModel,
    DatePicker: DatePicker
  },
  data: function data() {
    return {
      tabsList: [],
      activeName: '',
      addType: null,
      DialogType: null,
      DialogId: null,
      drawer: false,
      loading: true,
      showAddDialog: false,
      productDateTime: [],
      routerTypeList: [{
        routerName: 'GET'
      }, {
        routerName: 'POST'
      }, {
        routerName: 'PUT'
      }, {
        routerName: 'DELETE'
      }],
      searchForm: {
        // supplierCode: "",
        productCode: '',
        productName: ''
      },
      carTypeValue: [],
      tableData: [],
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      multipleSelection: [],
      suppliersLists: [],
      productTypeList: [],
      timeoutForExport: null,
      timeoutForRefresh: null
    };
  },
  created: function created() {
    this.getInsuranceProductType(); //获取服务类型列表
  },
  beforeDestroy: function beforeDestroy() {
    sessionStorage.setItem('productActiveName', this.activeName);
  },
  methods: {
    getServiceProductTypeData: function getServiceProductTypeData() {
      var _this = this;
      var data = {
        'classCode': this.activeName ? this.activeName : ''
      };
      NonAutoRequest.getProductTypeDict(data).then(function (res) {
        if (res.code == 200 && res.data) {
          _this.productTypeList = res.data ? res.data : [];
        }
      });
    },
    // 日期选择事件
    getPickersDate: function getPickersDate(name, val) {
      if (val && val.length > 1) {
        this[name] = val;
      } else {
        this[name] = [];
      }
    },
    getSupplierLists: function getSupplierLists() {
      var _this2 = this;
      NonAutoRequest.getSuppliersList(this.activeName).then(function (res) {
        if (res.code == 200) {
          _this2.suppliersLists = res.data ? res.data : [];
          _this2.getProductslist();
        }
      });
    },
    // 选择车型
    BZHGCarHandleChange: function BZHGCarHandleChange(val) {
      if (val) {
        this.searchForm.vhcBrandNo = 'FTMS';
        this.searchForm.vhcSeriesNo = val[0];
        this.searchForm.vhcModelNo = val[1];
        this.carTypeValue = val;
        this.$forceUpdate();
      }
    },
    // 选择车型
    CarHandleChange: function CarHandleChange(val) {
      if (val) {
        this.searchForm.vhcBrandNo = val[0];
        this.searchForm.vhcSeriesNo = val[1];
        this.carTypeValue = val;
        this.$forceUpdate();
      }
    },
    filterSupplierCode: function filterSupplierCode(val) {
      var SupplierName = '';
      if (val) {
        switch (val) {
          case 'YQFT_TPIC':
            SupplierName = '太平财险';
            break;
          case 'YQFT_PAIC':
            SupplierName = '平安财险';
            break;
          case 'YQFT_CPIC':
            SupplierName = '太平洋财险';
            break;
          case 'YQFT_PICC':
            SupplierName = '人保财险';
            break;
          case 'YQFT_XAIC':
            SupplierName = '鑫安汽车保险';
            break;
        }
      }
      return SupplierName;
    },
    updateStatus: function updateStatus(row, val) {
      var _this3 = this;
      var data = {
        productId: row.productId,
        status: val ? 'PUTAWAY' : 'SHELVE'
      };
      NonAutoRequest.getProductChangeStatus(data).then(function (res) {
        if (res.code == 200) {
          _this3.$message.success(res.msg);
        }
        _this3.getProductslist();
      });
    },
    //获取服务类型列表
    getInsuranceProductType: function getInsuranceProductType() {
      var _this4 = this;
      NonAutoRequest.getClassTab().then(function (res) {
        _this4.loading = false;
        if (res.code == 200) {
          var reslist = res.data ? res.data : [];
          if (sessionStorage.getItem('productActiveName')) {
            var productActiveName = sessionStorage.getItem('productActiveName');
            _this4.activeName = productActiveName;
          } else {
            if (reslist[0].classCode) {
              // sessionStorage.setItem('productActiveName', reslist[0].classCode)
              _this4.activeName = reslist[0].classCode;
            } else {
              _this4.activeName = '';
            }
          }
          _this4.searchForm.classCode = _this4.activeName;
          _this4.tabsList = reslist;
          _this4.getProductslist();
          _this4.getSupplierLists(); //获取供应商列表
          if (_this4.activeName && (_this4.activeName === 'DBC' || _this4.activeName === 'SB' || _this4.activeName === 'YB')) {
            _this4.getServiceProductTypeData(); //获取服务产品类型列表
          }
        }
      });
    },
    //获取产品管理列表
    getProductslist: function getProductslist() {
      var _this5 = this;
      this.loading = true;
      if (this.productDateTime) {
        this.searchForm.effectiveStartTime = this.productDateTime[0] ? this.productDateTime[0].split(' ')[0] + ' 00:00:00' : null;
        this.searchForm.effectiveEndTime = this.productDateTime[1] ? this.productDateTime[1].split(' ')[0] + ' 23:59:59' : null;
      }
      var data = _objectSpread(_objectSpread({}, this.listQuery), this.searchForm);
      NonAutoRequest.getProductList(data).then(function (res) {
        _this5.loading = false;
        if (res.code == 200) {
          var resData = res.data ? res.data.list : [];
          var _iterator = _createForOfIteratorHelper(resData),
            _step;
          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var i = _step.value;
              i.status = i.status == 'PUTAWAY' ? true : false;
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }
          _this5.tableData = resData ? resData : [];
          _this5.total = res.data && res.data.page ? res.data.page.recordsTotal : 0;
        } else {
          _this5.tableData = [];
          _this5.total = 0;
          _this5.loading = false;
        }
        //在数据加载完，重新渲染表格,防止切换标头抖动
        _this5.$nextTick(function () {
          _this5.$refs.multipleTable.doLayout();
        });
      });
    },
    handleTabClick: function handleTabClick() {
      this.searchForm = {};
      this.searchForm.classCode = this.activeName;
      sessionStorage.setItem('productActiveName', this.activeName);
      this.getSupplierLists(); //获取供应商列表
      if (this.activeName && (this.activeName === 'DBC' || this.activeName === 'SB' || this.activeName === 'YB')) {
        this.getServiceProductTypeData(); //获取服务产品类型列表
      }
    },
    // 导出防抖
    exportFn: function exportFn() {
      var _this6 = this;
      if (this.timeoutForExport) {
        clearTimeout(this.timeoutForExport);
      }
      this.timeoutForExport = setTimeout(function () {
        _this6.exportFn1();
      }, 500);
    },
    // 导出
    exportFn1: function exportFn1() {
      var fileName = '';
      if (this.activeName) {
        var _iterator2 = _createForOfIteratorHelper(this.tabsList),
          _step2;
        try {
          for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
            var i = _step2.value;
            if (i.classCode == this.activeName) {
              fileName = '非车' + i.className + '产品管理列表';
            }
          }
        } catch (err) {
          _iterator2.e(err);
        } finally {
          _iterator2.f();
        }
      } else {
        fileName = '非车产品管理列表';
      }
      var exporParams = {
        total: this.total,
        limitNum: 200000,
        fileName: fileName || '非车产品管理列表',
        exportUrl: '/biz-nonauto-service/product/search',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    //表格选中操作
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //批量删除
    batchDeletion: function batchDeletion() {
      var _this7 = this;
      this.$confirm('是否确认删除, 确认删除后不能恢复?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        if (_this7.multipleSelection) {
          var ids = [];
          _this7.multipleSelection.map(function (item) {
            ids.push(item.nonautoInsuranceProductId);
          });
          _this7.deleteApi(ids);
        }
        // 暂时缺接口
      }).catch(function () {});
    },
    // 重置
    resetSearch: function resetSearch() {
      this.searchForm = {};
      this.carTypeValue = [];
      this.productDateTime = [];
      this.searchForm.classCode = this.activeName;
    },
    // 刷新防抖
    refreshClicked: function refreshClicked() {
      var _this8 = this;
      if (this.timeoutForRefresh) {
        clearTimeout(this.timeoutForRefresh);
      }
      this.timeoutForRefresh = setTimeout(function () {
        _this8.refreshClicked1();
      }, 500);
    },
    // 刷新
    refreshClicked1: function refreshClicked1() {
      this.getProductslist();
    },
    // 查询
    searchData: function searchData() {
      this.drawer = false;
      this.getProductslist();
    },
    //新增
    showAddDialogClicked: function showAddDialogClicked(type) {
      this.$router.push({
        name: 'NonAutoInsuranceAdd',
        query: {
          "dataType": type,
          "addType": this.activeName
        }
      });
    },
    //修改类型
    ChangeAddType: function ChangeAddType(type) {
      this.addType = type;
    },
    // 详情
    detailConfig: function detailConfig(type, row) {
      this.$router.push({
        name: 'NonAutoInsuranceEdit',
        query: {
          "dataType": type,
          "addType": this.activeName,
          'productId': row.productId,
          tabName: "".concat(row.className, "\u4EA7\u54C1\u8BE6\u60C5")
        }
      });
    },
    // 编辑
    editConfig: function editConfig(type, row) {
      this.$router.push({
        name: 'NonAutoInsuranceEdit',
        query: {
          "dataType": type,
          "addType": this.activeName,
          'productId': row.productId,
          tabName: "".concat(row.className, "\u4EA7\u54C1\u7F16\u8F91")
        }
      });
    },
    //是否启用
    updateItem: function updateItem(row) {
      var _this9 = this;
      var data = {
        id: row.apiGroupId,
        enabled: row.enabled,
        name: row.name
      };
      gatewayConfigRequest.updateRouteGroup(data).then(function (res) {
        if (res.code == 200) {
          _this9.$message.success(res.msg);
        }
        _this9.getProductslist();
      });
    },
    // 删除二次确认
    deleteRoute: function deleteRoute(row) {
      var _this10 = this;
      this.$confirm('是否删除当前数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this10.deleteApi(row.productId);
      }).catch(function () {});
    },
    // 删除api
    deleteApi: function deleteApi(ids) {
      var _this11 = this;
      NonAutoRequest.getProductDel(ids.toString()).then(function (res) {
        if (res.code == 200) {
          _this11.$message.success(res.msg);
        }
        _this11.getProductslist();
      });
    },
    // 关闭弹窗
    loadData: function loadData() {
      this.showAddDialog = false;
      this.DialogType = null;
      this.getProductslist();
    }
  }
};