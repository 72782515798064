import _defineProperty from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/defineProperty.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '../../mixins/initHeight';
import dict from '../../mixins/dict';
import OrgSelect from '@/components/OrgSelect';
import statisticsRequest from '@/api/statistics';
import dayjs from 'dayjs';
import { getCurrentDate, getAfterDate, getLastMonth, getUserOrgInfo, commonExport } from '@/utils/common';
export default {
  name: 'PerformanceReport',
  components: {
    OrgSelect: OrgSelect
  },
  mixins: [initHeight, dict],
  computed: {},
  data: function data() {
    var _ref;
    return _ref = {
      activeName: "SellerInsured",
      drawer: false,
      searchForm: {},
      form: {},
      tableData: [],
      multipleSelection: [],
      opType: '',
      timeSelect: 'thirtyDays'
    }, _defineProperty(_ref, "searchForm", {
      category: 'SellerInsured',
      startDate: getAfterDate(-29),
      endDate: getCurrentDate(),
      salesOrgCode: '',
      salesOrgName: ''
    }), _defineProperty(_ref, "isDataTime", true), _defineProperty(_ref, "startEndTime", [getAfterDate(-29), getCurrentDate()]), _defineProperty(_ref, "loading", false), _ref;
  },
  created: function created() {},
  mounted: function mounted() {
    var _orgInfo = getUserOrgInfo();
    if (_orgInfo && _orgInfo.organizationId) {
      this.searchForm.salesOrgCode = _orgInfo.orgCode;
      this.searchForm.salesOrgName = _orgInfo.fullName;
    }
    this.loadData();
  },
  methods: {
    //导出
    exportFn: function exportFn() {
      var searchForm = {
        category: this.searchForm.category,
        startDate: this.searchForm.startDate,
        endDate: this.searchForm.endDate,
        orgCode: this.searchForm.salesOrgCode,
        orgName: this.searchForm.salesOrgName
      };
      var exporParams = {
        total: this.total,
        fileName: '出单业绩',
        exportUrl: '/vi-core-service/statistics/statisticAchievements',
        searchForm: searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    handleNodeClick: function handleNodeClick(val) {
      this.loadData();
    },
    handleTabClick: function handleTabClick(tab, event) {
      //tab切换事件
      this.searchForm.category = this.activeName;
      this.loadData();
    },
    handleTime: function handleTime(val) {
      switch (val) {
        case "now":
          this.startEndTime = [getCurrentDate(), getCurrentDate()];
          this.isDataTime = true;
          break;
        case "yesterday":
          this.startEndTime = [getAfterDate(-1), getAfterDate(-1)];
          this.isDataTime = true;
          break;
        case "sevenDays":
          this.startEndTime = [getAfterDate(-6), getCurrentDate()];
          this.isDataTime = true;
          break;
        case "thirtyDays":
          this.startEndTime = [getAfterDate(-29), getCurrentDate()];
          this.isDataTime = true;
          break;
        case "lastMonth":
          this.startEndTime = getLastMonth();
          this.isDataTime = true;
          break;
        case "currentMonth":
          var startDate = dayjs().startOf('M').format("YYYY-MM-DD");
          var endDate = dayjs().endOf('M').format("YYYY-MM-DD");
          this.startEndTime = [startDate, endDate];
          this.isDataTime = true;
          break;
        case "custom":
          this.isDataTime = false;
          this.$refs.input.focus();
          break;
      }
      this.searchForm.startDate = this.startEndTime[0];
      this.searchForm.endDate = this.startEndTime[1];
      this.loadData();
    },
    dateChange: function dateChange(start, end, val) {
      //日期选择事件
      if (val && val.length > 1) {
        this.searchForm[start] = val[0];
        this.searchForm[end] = val[1];
      } else {
        this.searchForm[start] = null;
        this.searchForm[end] = null;
      }
    },
    searchData: function searchData() {
      //搜索
      this.loadData();
    },
    loadData: function loadData() {
      var _this = this;
      //获取列表数据
      this.drawer = false;
      var postData = {
        category: this.searchForm.category,
        startDate: this.searchForm.startDate,
        endDate: this.searchForm.endDate,
        orgCode: this.searchForm.salesOrgCode,
        orgName: this.searchForm.salesOrgName
      };
      this.loading = true;
      statisticsRequest.getStatisticAchievements(postData).then(function (res) {
        _this.loading = false;
        if (res.data) {
          _this.tableData = res.data;
          _this.$nextTick(function () {
            _this.$refs['multipleTable'].doLayout();
          });
        }
      }).catch(function (err) {
        _this.loading = false;
      });
    }
  }
};