var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.listLoading,
          expression: "listLoading",
        },
      ],
    },
    [
      _c(
        "el-form",
        {
          ref: "form_sub",
          attrs: { model: _vm.form, rules: _vm.formRules, "label-width": "0" },
        },
        [
          !_vm._.isEmpty(_vm.allData)
            ? _c(
                "div",
                { staticStyle: { "max-height": "450px", overflow: "auto" } },
                _vm._l(_vm.allData, function (content, index) {
                  return _c(
                    "div",
                    { key: index, staticStyle: { "margin-bottom": "10px" } },
                    [
                      _c(
                        "el-card",
                        { staticClass: "box-card", attrs: { shadow: "never" } },
                        [
                          _c(
                            "div",
                            { staticClass: "insurance-company-header" },
                            [
                              _c("svg-icon", {
                                staticStyle: { "font-size": "24px" },
                                attrs: {
                                  "icon-class":
                                    "" +
                                    _vm._.toLower(
                                      content.insCorpChannel.insCorpCode
                                    ),
                                },
                              }),
                              _c(
                                "span",
                                { staticStyle: { "padding-left": "10px" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      content.insCorpChannel.insCorpName +
                                        " - " +
                                        content.insCorpChannel.insChannelName
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          !_vm._.isEmpty(content.formData)
                            ? _c(
                                "div",
                                { staticStyle: { "margin-top": "10px" } },
                                _vm._l(
                                  content.formData,
                                  function (item, index) {
                                    return _c("div", { key: index }, [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "line-height": "35px",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-checkbox",
                                            {
                                              on: {
                                                change: _vm.categoryChange,
                                              },
                                              model: {
                                                value: item.selected,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "selected",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.selected",
                                              },
                                            },
                                            [_vm._v(_vm._s(item.categoryName))]
                                          ),
                                        ],
                                        1
                                      ),
                                      item.selected
                                        ? _c(
                                            "div",
                                            { staticClass: "novehicle-box" },
                                            [
                                              item.group == "0"
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "line-height": "30px",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-checkbox-group",
                                                        {
                                                          key: index,
                                                          staticStyle: {
                                                            display: "flex",
                                                            "flex-wrap": "wrap",
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleCheckBoxChange(
                                                                content
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              item.currentCheckedItems,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "currentCheckedItems",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.currentCheckedItems",
                                                          },
                                                        },
                                                        _vm._l(
                                                          item.categoryTypeList,
                                                          function (
                                                            categoryType,
                                                            cindex
                                                          ) {
                                                            return _c(
                                                              "el-checkbox",
                                                              {
                                                                key: cindex,
                                                                staticClass:
                                                                  "novehicle-select",
                                                                attrs: {
                                                                  label:
                                                                    categoryType.categoryTypeCode,
                                                                  title:
                                                                    categoryType.categoryTypeName,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    categoryType.categoryTypeName
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              item.group == "1"
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "el-radio-group",
                                                        {
                                                          key: index,
                                                          staticStyle: {
                                                            display: "flex",
                                                            "flex-wrap": "wrap",
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleRadioGroupChange(
                                                                content
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              item.currentRadioItem,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "currentRadioItem",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.currentRadioItem",
                                                          },
                                                        },
                                                        _vm._l(
                                                          item.categoryTypeList,
                                                          function (
                                                            categoryType,
                                                            cindex
                                                          ) {
                                                            return _c(
                                                              "el-radio",
                                                              {
                                                                key: cindex,
                                                                staticClass:
                                                                  "novehicle-select",
                                                                staticStyle: {
                                                                  margin:
                                                                    "5px 0",
                                                                },
                                                                attrs: {
                                                                  label:
                                                                    categoryType.categoryTypeCode,
                                                                  title:
                                                                    categoryType.categoryTypeName,
                                                                },
                                                                model: {
                                                                  value:
                                                                    item.currentRadioItem,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        item,
                                                                        "currentRadioItem",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "item.currentRadioItem",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    categoryType.categoryTypeName
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              !_vm._.isEmpty(item.multipleItems)
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "table",
                                                        {
                                                          staticStyle: {
                                                            width: "100%",
                                                          },
                                                          attrs: {
                                                            border: "0",
                                                          },
                                                        },
                                                        [
                                                          _c("colgroup", [
                                                            _c("col", {
                                                              staticStyle: {
                                                                width: "35%",
                                                              },
                                                            }),
                                                            _c("col", {
                                                              staticStyle: {
                                                                width: "15%",
                                                              },
                                                            }),
                                                            _c("col", {
                                                              staticStyle: {
                                                                width: "25%",
                                                              },
                                                            }),
                                                            _c("col", {
                                                              staticStyle: {
                                                                width: "25%",
                                                              },
                                                            }),
                                                          ]),
                                                          _c(
                                                            "thead",
                                                            {
                                                              staticStyle: {
                                                                background:
                                                                  "#f9f9f9",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "tr",
                                                                {
                                                                  staticStyle: {
                                                                    "font-size":
                                                                      "13px",
                                                                    color:
                                                                      "#515151",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "th",
                                                                    {
                                                                      attrs: {
                                                                        scope:
                                                                          "col",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "档次"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "th",
                                                                    {
                                                                      attrs: {
                                                                        scope:
                                                                          "col",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "份数"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "th",
                                                                    {
                                                                      attrs: {
                                                                        scope:
                                                                          "col",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "保费：(份数*每份保费" +
                                                                          _vm._s(
                                                                            item.includedPeoplesCalculate
                                                                              ? "*座位数"
                                                                              : ""
                                                                          ) +
                                                                          ")"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "th",
                                                                    {
                                                                      attrs: {
                                                                        scope:
                                                                          "col",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "单份套餐"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._l(
                                                        item.multipleItems,
                                                        function (
                                                          eitem,
                                                          index
                                                        ) {
                                                          return _c(
                                                            "div",
                                                            { key: index },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticStyle: {
                                                                    display:
                                                                      "flex",
                                                                    height:
                                                                      "36px",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          width:
                                                                            "35%",
                                                                          "line-height":
                                                                            "36px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "el-form-item",
                                                                        [
                                                                          _c(
                                                                            "el-select",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  width:
                                                                                    "100%",
                                                                                },
                                                                              attrs:
                                                                                {
                                                                                  size: "mini",
                                                                                  filterable:
                                                                                    "",
                                                                                  placeholder:
                                                                                    "请选择档次",
                                                                                  clearable:
                                                                                    "",
                                                                                },
                                                                              on: {
                                                                                change:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.handleInsuranceChange(
                                                                                      item.categoryName,
                                                                                      eitem
                                                                                    )
                                                                                  },
                                                                              },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    eitem.insuranceId,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        eitem,
                                                                                        "insuranceId",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "eitem.insuranceId",
                                                                                },
                                                                            },
                                                                            _vm._l(
                                                                              eitem.insuranceList,
                                                                              function (
                                                                                option
                                                                              ) {
                                                                                return _c(
                                                                                  "el-option",
                                                                                  {
                                                                                    key: option.insuranceId,
                                                                                    attrs:
                                                                                      {
                                                                                        label:
                                                                                          "" +
                                                                                          (option.insuranceName +
                                                                                            " (¥" +
                                                                                            option.unitAmount +
                                                                                            ")"),
                                                                                        value:
                                                                                          option.insuranceId,
                                                                                      },
                                                                                  }
                                                                                )
                                                                              }
                                                                            ),
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          width:
                                                                            "15%",
                                                                          margin:
                                                                            "0 5px",
                                                                          "line-height":
                                                                            "36px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "el-form-item",
                                                                        [
                                                                          _c(
                                                                            "el-select",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  width:
                                                                                    "100%",
                                                                                },
                                                                              attrs:
                                                                                {
                                                                                  size: "mini",
                                                                                  filterable:
                                                                                    "",
                                                                                  placeholder:
                                                                                    "请选择份数",
                                                                                  clearable:
                                                                                    "",
                                                                                },
                                                                              on: {
                                                                                change:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.handleSelNumChange(
                                                                                      eitem
                                                                                    )
                                                                                  },
                                                                              },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    eitem.selNum,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        eitem,
                                                                                        "selNum",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "eitem.selNum",
                                                                                },
                                                                            },
                                                                            _vm._l(
                                                                              eitem.selNumList,
                                                                              function (
                                                                                option,
                                                                                index
                                                                              ) {
                                                                                return _c(
                                                                                  "el-option",
                                                                                  {
                                                                                    key: index,
                                                                                    attrs:
                                                                                      {
                                                                                        label:
                                                                                          option,
                                                                                        value:
                                                                                          option,
                                                                                      },
                                                                                  }
                                                                                )
                                                                              }
                                                                            ),
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          width:
                                                                            "25%",
                                                                          margin:
                                                                            "0 5px",
                                                                          "line-height":
                                                                            "36px",
                                                                          display:
                                                                            "flex",
                                                                          "justify-content":
                                                                            "center",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            eitem.amount ||
                                                                              "0"
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          width:
                                                                            "25%",
                                                                          "line-height":
                                                                            "36px",
                                                                          display:
                                                                            "flex",
                                                                          "justify-content":
                                                                            "center",
                                                                          "font-size":
                                                                            "13px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.collapse(
                                                                                  eitem,
                                                                                  content.insCorpChannel
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " 保额及责任"
                                                                          ),
                                                                          _c(
                                                                            "el-link",
                                                                            {
                                                                              staticClass:
                                                                                "expanded-button",
                                                                              attrs:
                                                                                {
                                                                                  type: "primary",
                                                                                  size: "mini",
                                                                                  underline: false,
                                                                                  icon: eitem.headerCollapse
                                                                                    ? "el-icon-arrow-up"
                                                                                    : "el-icon-arrow-down",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                              eitem.headerCollapse
                                                                ? _c("div", [
                                                                    _c(
                                                                      "table",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            width:
                                                                              "100%",
                                                                          },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "colgroup",
                                                                          [
                                                                            _c(
                                                                              "col",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    width:
                                                                                      "40%",
                                                                                  },
                                                                              }
                                                                            ),
                                                                            _c(
                                                                              "col",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    width:
                                                                                      "40%",
                                                                                  },
                                                                              }
                                                                            ),
                                                                            _c(
                                                                              "col",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    width:
                                                                                      "20%",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "thead",
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                background:
                                                                                  "#f9f9f9",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "tr",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    "font-size":
                                                                                      "13px",
                                                                                    color:
                                                                                      "#808080",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "th",
                                                                                  {
                                                                                    staticClass:
                                                                                      "table_border",
                                                                                    attrs:
                                                                                      {
                                                                                        scope:
                                                                                          "col",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "险种"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "th",
                                                                                  {
                                                                                    staticClass:
                                                                                      "table_border",
                                                                                    attrs:
                                                                                      {
                                                                                        scope:
                                                                                          "col",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "保险责任"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "th",
                                                                                  {
                                                                                    staticClass:
                                                                                      "table_border",
                                                                                    attrs:
                                                                                      {
                                                                                        scope:
                                                                                          "col",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "保险金额"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._l(
                                                                          eitem.accidentClauseList,
                                                                          function (
                                                                            titem,
                                                                            index
                                                                          ) {
                                                                            return _c(
                                                                              "tbody",
                                                                              {
                                                                                key: index,
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "tr",
                                                                                  {
                                                                                    staticStyle:
                                                                                      {
                                                                                        "font-size":
                                                                                          "13px",
                                                                                        color:
                                                                                          "#808080",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "td",
                                                                                      {
                                                                                        staticClass:
                                                                                          "table_border",
                                                                                        attrs:
                                                                                          {
                                                                                            scope:
                                                                                              "col",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            titem.name
                                                                                          )
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "td",
                                                                                      {
                                                                                        staticClass:
                                                                                          "table_border",
                                                                                        attrs:
                                                                                          {
                                                                                            scope:
                                                                                              "col",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            titem.description
                                                                                          )
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "td",
                                                                                      {
                                                                                        staticClass:
                                                                                          "table_border",
                                                                                        attrs:
                                                                                          {
                                                                                            scope:
                                                                                              "col",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            titem.limitAmount
                                                                                          )
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            )
                                                                          }
                                                                        ),
                                                                      ],
                                                                      2
                                                                    ),
                                                                  ])
                                                                : _vm._e(),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                    ],
                                                    2
                                                  )
                                                : _vm._e(),
                                            ]
                                          )
                                        : _vm._e(),
                                    ])
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                          _vm._.isEmpty(content.formData)
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    padding: "15px",
                                    "min-height": "100px",
                                    color: "red",
                                    display: "flex",
                                    "align-items": "center",
                                    "justify-content": "center",
                                  },
                                },
                                [_vm._v(" 暂无可选方案！ ")]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e(),
          _vm._.isEmpty(_vm.allData)
            ? _c(
                "div",
                {
                  staticStyle: {
                    padding: "15px",
                    "min-height": "100px",
                    color: "red",
                    display: "flex",
                    "align-items": "center",
                    "justify-content": "center",
                  },
                },
                [_vm._v(" 暂无可选方案！ ")]
              )
            : _vm._e(),
        ]
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _vm.opType != "view" && !_vm._.isEmpty(_vm.allData)
            ? _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-check",
                    type: "primary",
                    plain: "",
                    size: "mini",
                  },
                  on: { click: _vm.save },
                },
                [_vm._v("保存")]
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: { click: _vm.closePage },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }