var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.formLoading,
            expression: "formLoading",
          },
        ],
        staticClass: "app-m-cls",
        staticStyle: {},
      },
      [
        _c(
          "div",
          {
            ref: "header",
            staticClass: "header-cls",
            staticStyle: { "max-height": "500px", overflow: "auto" },
          },
          [
            _c(
              "el-card",
              { attrs: { shadow: "never" } },
              [
                _c(
                  "el-form",
                  {
                    attrs: {
                      model: _vm.uploadForm,
                      "label-width": "80px",
                      size: "mini",
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "must-star",
                        attrs: { label: "保单文件：" },
                      },
                      [
                        _c(
                          "el-upload",
                          {
                            ref: "upload",
                            attrs: {
                              multiple: "",
                              action: "",
                              "http-request": _vm.uploadFile,
                              "file-list": _vm.fileList,
                              accept: ".pdf,.PDF",
                              "list-type": "text",
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  size: "mini",
                                  plain: "",
                                },
                              },
                              [_vm._v("选择文件")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "justify-content": "center",
              "margin-top": "8px",
            },
          },
          [
            _c(
              "el-button",
              {
                attrs: {
                  icon: "el-icon-check",
                  size: "mini",
                  type: "primary",
                  disabled: _vm._.isEmpty(_vm.uploadForm.mediaList),
                  plain: "",
                },
                on: { click: _vm.save },
              },
              [_vm._v("上传识别")]
            ),
            _c(
              "el-button",
              {
                attrs: { icon: "el-icon-close", size: "mini" },
                on: {
                  click: function ($event) {
                    return _vm.closePage(true)
                  },
                },
              },
              [_vm._v("关闭")]
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }