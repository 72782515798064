import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '../../mixins/initHeight';
import dict from '../../mixins/dict';
import Pagination from '@/components/Pagination';
import DatePicker from '@/components/DatePicker';
import OemSelect from '@/components/OemSelect';
import request from '../../api/finance';
import dictRequest from '@/api/dictionary';
import importDialog from './dialog/importDialog.vue';
import { getSupplyers, getNowDate, getUserOrgInfoDealerOem, commonExport, getBeforeDate, getCurrentDate } from '@/utils/common';
export default {
  name: "Commissions",
  components: {
    Pagination: Pagination,
    OemSelect: OemSelect,
    importDialog: importDialog,
    DatePicker: DatePicker
  },
  mixins: [initHeight, dict],
  data: function data() {
    return {
      drawer: false,
      showImportDialog: false,
      searchForm: {
        insuredTimeStart: getNowDate()[0],
        insuredTimeEnd: getNowDate()[1]
      },
      DList: [],
      form: {},
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      orgData: {
        salesOrgCode: '',
        salesOrgName: '',
        organizationId: ''
      },
      tableData: [],
      multipleSelection: [],
      loading: false,
      startEndTime: [getBeforeDate(6), getCurrentDate()],
      showRematchDialog: false,
      showEditDialog: false,
      settleAccountsStatus: [],
      supplyers: [],
      poundageData: {},
      bizType: localStorage.getItem("bizType") || ''
    };
  },
  mounted: function mounted() {
    var _this = this;
    if (this.bizType === "DEALER") {
      this.initData();
    }
    ;
    //保司
    this.supplyers = getSupplyers();
    //获取结算状态
    dictRequest.getDictItemsByCode("SETTLE_ACCOUNT_SSTATUS").then(function (res) {
      if (res.data) {
        _this.settleAccountsStatus = res.data;
      }
      ;
    });
    this.loadData();
  },
  methods: {
    initData: function initData() {
      var orgObj = getUserOrgInfoDealerOem(this.bizType);
      this.searchForm.salesOrgCode = orgObj.salesOrgCode;
      this.searchForm.salesOrgName = orgObj.salesOrgName;
    },
    nodeClick: function nodeClick(val) {
      this.searchForm.salesOrgCode = val.salesOrgCode;
    },
    // 日期选择事件
    getPickersDate: function getPickersDate(name, val) {
      if (val && val.length > 1) {
        this[name] = val;
      } else {
        this[name] = [];
      }
    },
    exportFn: function exportFn() {
      var exporParams = {
        total: this.total,
        limitNum: 200000,
        fileName: '财险台账列表',
        exportUrl: '/vi-core-service/financestandingbook/search',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    rematch: function rematch() {
      //重新匹配
      this.showRematchDialog = true;
    },
    edit: function edit(row) {
      //编辑
      this.showEditDialog = true;
      var list = [];
      list.push({
        name: row.salesOrgName,
        proportion: row.upPoundageProportion,
        code: "upPoundage"
      });
      list.push({
        name: row.salemanName,
        proportion: row.downPoundageProportion,
        code: "downPoundage"
      });
      this.DList = list;
      this.poundageData.financeId = row.financeId;
    },
    editFn: function editFn() {
      var _this2 = this;
      //保单
      this.poundageData.upPoundage = this.DList[0].proportion;
      this.poundageData.downPoundage = this.DList[1].proportion;
      request.standingbookApi(this.poundageData).then(function (res) {
        if (res.code == 200) {
          _this2.loadData();
        }
        ;
      });
    },
    viewDetail: function viewDetail(item) {
      //查看保单详情
      var router_path = this.$route.path;
      this.$router.push({
        path: "/policy/detail",
        query: {
          policyId: item.financeId,
          policyNo: item.policyNo,
          back: router_path,
          plateNo: item.plateNo,
          vin: item.vin
        }
      });
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {
        insuredTimeStart: getNowDate()[0],
        insuredTimeEnd: getNowDate()[1]
      };
      this.orgData = {};
      this.startEndTime = [getBeforeDate(6), getCurrentDate()];
      this.listQuery.pageNum = 1;
      if (this.bizType === "DEALER") {
        this.initData();
      }
      ;
    },
    // 刷新
    breakData: function breakData() {
      // this.resetSearch();
      this.loadData();
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    importFn: function importFn() {
      this.showImportDialog = true;
    },
    loadData: function loadData() {
      var _this3 = this;
      //获取列表数据
      this.loading = true;
      this.drawer = false;
      this.showRematchDialog = false;
      this.showEditDialog = false;
      this.showImportDialog = false;
      var postData = {};
      if (this.startEndTime) {
        this.searchForm.insuredTimeStart = this.startEndTime[0] && this.startEndTime[0].split(' ')[0] + ' 00:00:00' || null;
        this.searchForm.insuredTimeEnd = this.startEndTime[1] && this.startEndTime[1].split(' ')[0] + ' 23:59:59' || null;
      }
      postData = Object.assign(postData, this.searchForm, this.listQuery);
      request.getFinanceStandingBookList(postData).then(function (res) {
        _this3.loading = false;
        _this3.tableData = res.data.list;
        //总条数
        _this3.total = res.data.page.recordsTotal;
      }).catch(function (err) {
        _this3.loading = false;
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      //列表行选择事件
      this.multipleSelection = val;
    }
  }
};