var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.fullLoading,
          expression: "fullLoading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: { background: "transparent" },
    },
    [
      _c(
        "div",
        { ref: "header", staticClass: "header-cls" },
        [
          _c(
            "el-card",
            { staticStyle: { height: "100%" }, attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "detail",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-width": "140px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    { staticClass: "topRow" },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "服务类型编码：",
                                prop: "classCode",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入服务类型编码",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.classCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "classCode", $$v)
                                  },
                                  expression: "form.classCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "服务类型名称：",
                                prop: "className",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入服务类型名称",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.className,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "className", $$v)
                                  },
                                  expression: "form.className",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { "margin-bottom": "0px" },
                              attrs: {
                                label: "是否支持注销：",
                                prop: "allowCancel",
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.form.allowCancel,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "allowCancel", $$v)
                                    },
                                    expression: "form.allowCancel",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: true } }, [
                                    _vm._v("是"),
                                  ]),
                                  _c("el-radio", { attrs: { label: false } }, [
                                    _vm._v("否"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.form.allowCancel
                        ? _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "注销审核方式：" } },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      model: {
                                        value: _vm.form.isAutomaticAudit,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "isAutomaticAudit",
                                            $$v
                                          )
                                        },
                                        expression: "form.isAutomaticAudit",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-radio",
                                        { attrs: { label: true } },
                                        [_vm._v("自动")]
                                      ),
                                      _c(
                                        "el-radio",
                                        { attrs: { label: false } },
                                        [_vm._v("人工")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "是否支持多次理赔：",
                                prop: "allowMultipleClaims",
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.form.allowMultipleClaims,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "allowMultipleClaims",
                                        $$v
                                      )
                                    },
                                    expression: "form.allowMultipleClaims",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: true } }, [
                                    _vm._v("是"),
                                  ]),
                                  _c("el-radio", { attrs: { label: false } }, [
                                    _vm._v("否"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.form.allowCancel
                        ? _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "注销规则：",
                                    prop: "cancelRules",
                                  },
                                },
                                [
                                  _c(
                                    "el-checkbox-group",
                                    {
                                      model: {
                                        value: _vm.form.cancelRules,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "cancelRules", $$v)
                                        },
                                        expression: "form.cancelRules",
                                      },
                                    },
                                    _vm._l(
                                      _vm.cancelRulesList,
                                      function (item, index) {
                                        return _c(
                                          "el-checkbox",
                                          {
                                            key: index,
                                            attrs: { label: item.itemCode },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.itemName) + " "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("el-row", [
                    _c(
                      "div",
                      [
                        _c(
                          "el-tabs",
                          {
                            on: { "tab-click": _vm.handleClick },
                            model: {
                              value: _vm.activeName,
                              callback: function ($$v) {
                                _vm.activeName = $$v
                              },
                              expression: "activeName",
                            },
                          },
                          [
                            _c("el-tab-pane", {
                              attrs: { label: "产品服务字段", name: "product" },
                            }),
                            _c("el-tab-pane", {
                              attrs: { label: "车辆信息字段", name: "order" },
                            }),
                            _c("el-tab-pane", {
                              attrs: { label: "出单附件类型", name: "file" },
                            }),
                            _c("el-tab-pane", {
                              attrs: {
                                label: "理赔车辆信息字段",
                                name: "claimsTarget",
                              },
                            }),
                            _c("el-tab-pane", {
                              attrs: {
                                label: "理赔投保字段",
                                name: "claimsApply",
                              },
                            }),
                            _c("el-tab-pane", {
                              attrs: {
                                label: "理赔附件类型",
                                name: "claimsFile",
                              },
                            }),
                            _c("el-tab-pane", {
                              attrs: {
                                label: "服务产品类型",
                                name: "ServiceProductType",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.activeName == "product",
                            expression: "activeName == 'product'",
                          },
                        ],
                        staticClass: "tableBox",
                      },
                      [
                        _c(
                          "el-table",
                          {
                            ref: "multipleTable",
                            staticStyle: { width: "100%" },
                            attrs: {
                              size: "mini",
                              border: "",
                              height: "430",
                              data: _vm.productInfotList,
                              "header-cell-style": { background: "#F7F7F7" },
                              "highlight-current-row": "",
                            },
                            on: {
                              select: _vm.cellClick,
                              "selection-change":
                                _vm.productHandleSelectionChange,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                type: "selection",
                                width: "55",
                                align: "center",
                                fixed: "left",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "attributeName",
                                label: "产品属性名称",
                                width: "120",
                                fixed: "left",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "sort",
                                label: "排序",
                                width: "80",
                                fixed: "left",
                                align: "center",
                                "show-overflow-tooltip": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("el-input", {
                                        staticStyle: { width: "100%" },
                                        attrs: { size: "mini", clearable: "" },
                                        on: {
                                          input: function ($event) {
                                            scope.row.sort =
                                              scope.row.sort.replace(
                                                /[^\d]/g,
                                                ""
                                              )
                                          },
                                        },
                                        model: {
                                          value: scope.row.sort,
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, "sort", $$v)
                                          },
                                          expression: "scope.row.sort",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "requiredState",
                                label: "是否必填",
                                width: "80",
                                align: "center",
                                "show-overflow-tooltip": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("el-switch", {
                                        attrs: {
                                          "active-color": "#409EFF",
                                          "inactive-color": "#C0CCDA",
                                        },
                                        model: {
                                          value: scope.row.requiredState,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "requiredState",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.requiredState",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "readonly",
                                label: "是否只读",
                                width: "80",
                                align: "center",
                                "show-overflow-tooltip": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("el-switch", {
                                        attrs: {
                                          "active-color": "#409EFF",
                                          "inactive-color": "#C0CCDA",
                                        },
                                        model: {
                                          value: scope.row.readonly,
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, "readonly", $$v)
                                          },
                                          expression: "scope.row.readonly",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "inputType",
                                label: "控件类型",
                                width: "140",
                                align: "center",
                                "show-overflow-tooltip": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.dateType == "CHARACTER" ||
                                      scope.row.dateType == "DECIMALS" ||
                                      scope.row.dateType == "INTEGER"
                                        ? _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                size: "mini",
                                                clearable: "",
                                                placeholder: "请选择控件类型",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.clearSourceType(
                                                    scope.row,
                                                    "sourceType",
                                                    "sourceMethod"
                                                  )
                                                },
                                              },
                                              model: {
                                                value: scope.row.inputType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "inputType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.inputType",
                                              },
                                            },
                                            _vm._l(
                                              _vm.textTypes,
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item.value,
                                                  attrs: {
                                                    label: item.name,
                                                    value: item.value,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          )
                                        : _vm._e(),
                                      scope.row.dateType == "DATE"
                                        ? _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                size: "mini",
                                                clearable: "",
                                                placeholder: "请选择控件类型",
                                              },
                                              model: {
                                                value: scope.row.inputType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "inputType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.inputType",
                                              },
                                            },
                                            _vm._l(
                                              _vm.dateTypes,
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item.value,
                                                  attrs: {
                                                    label: item.name,
                                                    value: item.value,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          )
                                        : _vm._e(),
                                      scope.row.dateType == "BOOLEAN"
                                        ? _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                size: "mini",
                                                clearable: "",
                                                placeholder: "请选择控件类型",
                                              },
                                              model: {
                                                value: scope.row.inputType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "inputType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.inputType",
                                              },
                                            },
                                            _vm._l(
                                              _vm.BooleanTypes,
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item.value,
                                                  attrs: {
                                                    label: item.name,
                                                    value: item.value,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "maxValue",
                                label: "最小(大)值",
                                width: "220",
                                align: "center",
                                "show-overflow-tooltip": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("el-input", {
                                        staticStyle: { width: "46%" },
                                        attrs: {
                                          size: "mini",
                                          clearable: "",
                                          placeholder: "最小值",
                                        },
                                        on: {
                                          input: function ($event) {
                                            scope.row.minValue =
                                              scope.row.minValue.replace(
                                                /[^\d]/g,
                                                ""
                                              )
                                          },
                                        },
                                        model: {
                                          value: scope.row.minValue,
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, "minValue", $$v)
                                          },
                                          expression: "scope.row.minValue",
                                        },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            width: "8%",
                                            display: "inline-block",
                                          },
                                        },
                                        [_vm._v("~")]
                                      ),
                                      _c("el-input", {
                                        staticStyle: { width: "46%" },
                                        attrs: {
                                          size: "mini",
                                          clearable: "",
                                          placeholder: "最大值",
                                        },
                                        on: {
                                          input: function ($event) {
                                            scope.row.maxValue =
                                              scope.row.maxValue.replace(
                                                /[^\d]/g,
                                                ""
                                              )
                                          },
                                        },
                                        model: {
                                          value: scope.row.maxValue,
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, "maxValue", $$v)
                                          },
                                          expression: "scope.row.maxValue",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "sourceType",
                                label: "数据来源",
                                width: "120",
                                "show-overflow-tooltip": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            disabled:
                                              scope.row.inputType != "SELECT" &&
                                              scope.row.inputType !=
                                                "MULTI_SELECT" &&
                                              scope.row.inputType !=
                                                "SELECT_BUTTON",
                                            size: "mini",
                                            clearable: "",
                                            placeholder: "请选择数据来源",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.clearChanged(
                                                scope.row,
                                                "sourceType"
                                              )
                                            },
                                          },
                                          model: {
                                            value: scope.row.sourceType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "sourceType",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.sourceType",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: {
                                              label: "字典",
                                              value: "DICTIONARY",
                                            },
                                          }),
                                          _c("el-option", {
                                            attrs: {
                                              label: "车型",
                                              value: "VEHICLE_MODEL",
                                            },
                                          }),
                                          _c("el-option", {
                                            attrs: {
                                              label: "自定义",
                                              value: "CUSTOM",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "sourceMethod",
                                label: "数据来源方法",
                                width: "auto",
                                align: "center",
                                "show-overflow-tooltip": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("el-input", {
                                        attrs: {
                                          size: "mini",
                                          clearable: "",
                                          placeholder: "请输入数据来源方法",
                                        },
                                        model: {
                                          value: scope.row.sourceMethod,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "sourceMethod",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.sourceMethod",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.activeName == "order",
                            expression: "activeName == 'order'",
                          },
                        ],
                        staticClass: "tableBox",
                      },
                      [
                        _c(
                          "el-table",
                          {
                            ref: "orderMultipleTable",
                            staticStyle: { width: "100%" },
                            attrs: {
                              size: "mini",
                              border: "",
                              data: _vm.orderInfotList,
                              height: "430",
                              "header-cell-style": { background: "#F7F7F7" },
                              "highlight-current-row": "",
                            },
                            on: {
                              select: _vm.cellClick,
                              "selection-change":
                                _vm.OrderHandleSelectionChange,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                type: "selection",
                                width: "55",
                                align: "center",
                                fixed: "left",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "attributeName",
                                label: "产品属性名称",
                                width: "120",
                                fixed: "left",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "sort",
                                label: "排序",
                                width: "80",
                                fixed: "left",
                                align: "center",
                                "show-overflow-tooltip": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("el-input", {
                                        staticStyle: { width: "100%" },
                                        attrs: { size: "mini", clearable: "" },
                                        on: {
                                          input: function ($event) {
                                            scope.row.sort =
                                              scope.row.sort.replace(
                                                /[^\d]/g,
                                                ""
                                              )
                                          },
                                        },
                                        model: {
                                          value: scope.row.sort,
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, "sort", $$v)
                                          },
                                          expression: "scope.row.sort",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "requiredState",
                                label: "是否必填",
                                width: "80",
                                align: "center",
                                "show-overflow-tooltip": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("el-switch", {
                                        attrs: {
                                          "active-color": "#409EFF",
                                          "inactive-color": "#C0CCDA",
                                        },
                                        model: {
                                          value: scope.row.requiredState,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "requiredState",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.requiredState",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "readonly",
                                label: "是否只读",
                                width: "80",
                                align: "center",
                                "show-overflow-tooltip": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("el-switch", {
                                        attrs: {
                                          "active-color": "#409EFF",
                                          "inactive-color": "#C0CCDA",
                                        },
                                        model: {
                                          value: scope.row.readonly,
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, "readonly", $$v)
                                          },
                                          expression: "scope.row.readonly",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "inputType",
                                label: "控件类型",
                                width: "140",
                                align: "center",
                                "show-overflow-tooltip": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.dateType == "CHARACTER" ||
                                      scope.row.dateType == "DECIMALS" ||
                                      scope.row.dateType == "INTEGER"
                                        ? _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                size: "mini",
                                                clearable: "",
                                                placeholder: "请选择控件类型",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.clearSourceType(
                                                    scope.row,
                                                    "sourceType",
                                                    "sourceMethod"
                                                  )
                                                },
                                              },
                                              model: {
                                                value: scope.row.inputType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "inputType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.inputType",
                                              },
                                            },
                                            _vm._l(
                                              _vm.textTypes,
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item.value,
                                                  attrs: {
                                                    label: item.name,
                                                    value: item.value,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          )
                                        : _vm._e(),
                                      scope.row.dateType == "DATE"
                                        ? _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                size: "mini",
                                                clearable: "",
                                                placeholder: "请选择控件类型",
                                              },
                                              model: {
                                                value: scope.row.inputType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "inputType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.inputType",
                                              },
                                            },
                                            _vm._l(
                                              _vm.dateTypes,
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item.value,
                                                  attrs: {
                                                    label: item.name,
                                                    value: item.value,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          )
                                        : _vm._e(),
                                      scope.row.dateType == "BOOLEAN"
                                        ? _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                size: "mini",
                                                clearable: "",
                                                placeholder: "请选择控件类型",
                                              },
                                              model: {
                                                value: scope.row.inputType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "inputType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.inputType",
                                              },
                                            },
                                            _vm._l(
                                              _vm.BooleanTypes,
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item.value,
                                                  attrs: {
                                                    label: item.name,
                                                    value: item.value,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "maxValue",
                                label: "最小(大)值",
                                width: "220",
                                align: "center",
                                "show-overflow-tooltip": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("el-input", {
                                        staticStyle: { width: "46%" },
                                        attrs: {
                                          size: "mini",
                                          clearable: "",
                                          placeholder: "最小值",
                                        },
                                        on: {
                                          input: function ($event) {
                                            scope.row.minValue =
                                              scope.row.minValue.replace(
                                                /[^\d]/g,
                                                ""
                                              )
                                          },
                                        },
                                        model: {
                                          value: scope.row.minValue,
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, "minValue", $$v)
                                          },
                                          expression: "scope.row.minValue",
                                        },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            width: "8%",
                                            display: "inline-block",
                                          },
                                        },
                                        [_vm._v("~")]
                                      ),
                                      _c("el-input", {
                                        staticStyle: { width: "46%" },
                                        attrs: {
                                          size: "mini",
                                          clearable: "",
                                          placeholder: "最大值",
                                        },
                                        on: {
                                          input: function ($event) {
                                            scope.row.maxValue =
                                              scope.row.maxValue.replace(
                                                /[^\d]/g,
                                                ""
                                              )
                                          },
                                        },
                                        model: {
                                          value: scope.row.maxValue,
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, "maxValue", $$v)
                                          },
                                          expression: "scope.row.maxValue",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "sourceType",
                                label: "数据来源",
                                width: "120",
                                "show-overflow-tooltip": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            disabled:
                                              scope.row.inputType != "SELECT" &&
                                              scope.row.inputType !=
                                                "MULTI_SELECT" &&
                                              scope.row.inputType !=
                                                "SELECT_BUTTON",
                                            size: "mini",
                                            clearable: "",
                                            placeholder: "请选择数据来源",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.clearChanged(
                                                scope.row,
                                                "sourceType"
                                              )
                                            },
                                          },
                                          model: {
                                            value: scope.row.sourceType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "sourceType",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.sourceType",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: {
                                              label: "字典",
                                              value: "DICTIONARY",
                                            },
                                          }),
                                          _c("el-option", {
                                            attrs: {
                                              label: "车型",
                                              value: "VEHICLE_MODEL",
                                            },
                                          }),
                                          _c("el-option", {
                                            attrs: {
                                              label: "自定义",
                                              value: "CUSTOM",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "sourceMethod",
                                label: "数据来源方法",
                                width: "auto",
                                align: "center",
                                "show-overflow-tooltip": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("el-input", {
                                        attrs: {
                                          size: "mini",
                                          clearable: "",
                                          placeholder: "请输入数据来源方法",
                                        },
                                        model: {
                                          value: scope.row.sourceMethod,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "sourceMethod",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.sourceMethod",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.activeName == "file",
                            expression: "activeName == 'file'",
                          },
                        ],
                        staticClass: "tableBox",
                      },
                      [
                        _c("el-transfer", {
                          attrs: {
                            filterable: "",
                            "filter-placeholder": "请输入文件类型名称",
                            titles: ["可选类型", "已选类型"],
                            props: { key: "itemCode", label: "itemName" },
                            data: _vm.transferData,
                          },
                          model: {
                            value: _vm.fileTypeList,
                            callback: function ($$v) {
                              _vm.fileTypeList = $$v
                            },
                            expression: "fileTypeList",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.activeName == "claimsTarget",
                            expression: "activeName == 'claimsTarget'",
                          },
                        ],
                        staticClass: "tableBox",
                      },
                      [
                        _c(
                          "el-table",
                          {
                            ref: "claimsTargetMultipleSelection",
                            staticStyle: { width: "100%" },
                            attrs: {
                              size: "mini",
                              border: "",
                              data: _vm.claimsTargetList,
                              height: "430",
                              "header-cell-style": { background: "#F7F7F7" },
                              "highlight-current-row": "",
                            },
                            on: {
                              select: _vm.claimsCellClick,
                              "selection-change":
                                _vm.claimsTargetHandleSelectionChange,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                type: "selection",
                                width: "55",
                                align: "center",
                                fixed: "left",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "attributeName",
                                label: "产品属性名称",
                                width: "120",
                                fixed: "left",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "sort",
                                label: "排序",
                                width: "80",
                                fixed: "left",
                                align: "center",
                                "show-overflow-tooltip": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("el-input", {
                                        staticStyle: { width: "100%" },
                                        attrs: { size: "mini", clearable: "" },
                                        on: {
                                          input: function ($event) {
                                            scope.row.sort =
                                              scope.row.sort.replace(
                                                /[^\d]/g,
                                                ""
                                              )
                                          },
                                        },
                                        model: {
                                          value: scope.row.sort,
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, "sort", $$v)
                                          },
                                          expression: "scope.row.sort",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "requiredState",
                                label: "是否必填",
                                width: "80",
                                align: "center",
                                "show-overflow-tooltip": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("el-switch", {
                                        attrs: {
                                          "active-color": "#409EFF",
                                          "inactive-color": "#C0CCDA",
                                        },
                                        model: {
                                          value: scope.row.requiredState,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "requiredState",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.requiredState",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "readonly",
                                label: "是否只读",
                                width: "80",
                                align: "center",
                                "show-overflow-tooltip": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("el-switch", {
                                        attrs: {
                                          "active-color": "#409EFF",
                                          "inactive-color": "#C0CCDA",
                                        },
                                        model: {
                                          value: scope.row.readonly,
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, "readonly", $$v)
                                          },
                                          expression: "scope.row.readonly",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "inputType",
                                label: "控件类型",
                                width: "140",
                                align: "center",
                                "show-overflow-tooltip": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.dateType == "CHARACTER" ||
                                      scope.row.dateType == "DECIMALS" ||
                                      scope.row.dateType == "INTEGER"
                                        ? _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                size: "mini",
                                                clearable: "",
                                                placeholder: "请选择控件类型",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.clearSourceType(
                                                    scope.row,
                                                    "sourceType",
                                                    "sourceMethod"
                                                  )
                                                },
                                              },
                                              model: {
                                                value: scope.row.inputType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "inputType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.inputType",
                                              },
                                            },
                                            _vm._l(
                                              _vm.textTypes,
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item.value,
                                                  attrs: {
                                                    label: item.name,
                                                    value: item.value,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          )
                                        : _vm._e(),
                                      scope.row.dateType == "DATE"
                                        ? _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                size: "mini",
                                                clearable: "",
                                                placeholder: "请选择控件类型",
                                              },
                                              model: {
                                                value: scope.row.inputType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "inputType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.inputType",
                                              },
                                            },
                                            _vm._l(
                                              _vm.dateTypes,
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item.value,
                                                  attrs: {
                                                    label: item.name,
                                                    value: item.value,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          )
                                        : _vm._e(),
                                      scope.row.dateType == "BOOLEAN"
                                        ? _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                size: "mini",
                                                clearable: "",
                                                placeholder: "请选择控件类型",
                                              },
                                              model: {
                                                value: scope.row.inputType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "inputType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.inputType",
                                              },
                                            },
                                            _vm._l(
                                              _vm.BooleanTypes,
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item.value,
                                                  attrs: {
                                                    label: item.name,
                                                    value: item.value,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "maxValue",
                                label: "最小(大)值",
                                width: "220",
                                align: "center",
                                "show-overflow-tooltip": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("el-input", {
                                        staticStyle: { width: "46%" },
                                        attrs: {
                                          size: "mini",
                                          clearable: "",
                                          placeholder: "最小值",
                                        },
                                        on: {
                                          input: function ($event) {
                                            scope.row.minValue =
                                              scope.row.minValue.replace(
                                                /[^\d]/g,
                                                ""
                                              )
                                          },
                                        },
                                        model: {
                                          value: scope.row.minValue,
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, "minValue", $$v)
                                          },
                                          expression: "scope.row.minValue",
                                        },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            width: "8%",
                                            display: "inline-block",
                                          },
                                        },
                                        [_vm._v("~")]
                                      ),
                                      _c("el-input", {
                                        staticStyle: { width: "46%" },
                                        attrs: {
                                          size: "mini",
                                          clearable: "",
                                          placeholder: "最大值",
                                        },
                                        on: {
                                          input: function ($event) {
                                            scope.row.maxValue =
                                              scope.row.maxValue.replace(
                                                /[^\d]/g,
                                                ""
                                              )
                                          },
                                        },
                                        model: {
                                          value: scope.row.maxValue,
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, "maxValue", $$v)
                                          },
                                          expression: "scope.row.maxValue",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "sourceType",
                                label: "数据来源",
                                width: "120",
                                "show-overflow-tooltip": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            disabled:
                                              scope.row.inputType != "SELECT" &&
                                              scope.row.inputType !=
                                                "MULTI_SELECT" &&
                                              scope.row.inputType !=
                                                "SELECT_BUTTON",
                                            size: "mini",
                                            clearable: "",
                                            placeholder: "请选择数据来源",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.clearChanged(
                                                scope.row,
                                                "sourceType"
                                              )
                                            },
                                          },
                                          model: {
                                            value: scope.row.sourceType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "sourceType",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.sourceType",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: {
                                              label: "字典",
                                              value: "DICTIONARY",
                                            },
                                          }),
                                          _c("el-option", {
                                            attrs: {
                                              label: "车型",
                                              value: "VEHICLE_MODEL",
                                            },
                                          }),
                                          _c("el-option", {
                                            attrs: {
                                              label: "自定义",
                                              value: "CUSTOM",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "sourceMethod",
                                label: "数据来源方法",
                                width: "auto",
                                align: "center",
                                "show-overflow-tooltip": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("el-input", {
                                        attrs: {
                                          size: "mini",
                                          clearable: "",
                                          placeholder: "请输入数据来源方法",
                                        },
                                        model: {
                                          value: scope.row.sourceMethod,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "sourceMethod",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.sourceMethod",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.activeName == "claimsApply",
                            expression: "activeName == 'claimsApply'",
                          },
                        ],
                        staticClass: "tableBox",
                      },
                      [
                        _c(
                          "el-table",
                          {
                            ref: "claimsApplyMultipleSelection",
                            staticStyle: { width: "100%" },
                            attrs: {
                              size: "mini",
                              border: "",
                              data: _vm.claimsApplyList,
                              height: "430",
                              "header-cell-style": { background: "#F7F7F7" },
                              "highlight-current-row": "",
                            },
                            on: {
                              select: _vm.claimsCellClick,
                              "selection-change":
                                _vm.claimsApplyHandleSelectionChange,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                type: "selection",
                                width: "55",
                                align: "center",
                                fixed: "left",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "attributeName",
                                label: "产品属性名称",
                                width: "120",
                                fixed: "left",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "sort",
                                label: "排序",
                                width: "80",
                                fixed: "left",
                                align: "center",
                                "show-overflow-tooltip": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("el-input", {
                                        staticStyle: { width: "100%" },
                                        attrs: { size: "mini", clearable: "" },
                                        on: {
                                          input: function ($event) {
                                            scope.row.sort =
                                              scope.row.sort.replace(
                                                /[^\d]/g,
                                                ""
                                              )
                                          },
                                        },
                                        model: {
                                          value: scope.row.sort,
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, "sort", $$v)
                                          },
                                          expression: "scope.row.sort",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "requiredState",
                                label: "是否必填",
                                width: "80",
                                align: "center",
                                "show-overflow-tooltip": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("el-switch", {
                                        attrs: {
                                          "active-color": "#409EFF",
                                          "inactive-color": "#C0CCDA",
                                        },
                                        model: {
                                          value: scope.row.requiredState,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "requiredState",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.requiredState",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "readonly",
                                label: "是否只读",
                                width: "80",
                                align: "center",
                                "show-overflow-tooltip": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("el-switch", {
                                        attrs: {
                                          "active-color": "#409EFF",
                                          "inactive-color": "#C0CCDA",
                                        },
                                        model: {
                                          value: scope.row.readonly,
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, "readonly", $$v)
                                          },
                                          expression: "scope.row.readonly",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "inputType",
                                label: "控件类型",
                                width: "140",
                                align: "center",
                                "show-overflow-tooltip": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.dateType == "CHARACTER" ||
                                      scope.row.dateType == "DECIMALS" ||
                                      scope.row.dateType == "INTEGER"
                                        ? _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                size: "mini",
                                                clearable: "",
                                                placeholder: "请选择控件类型",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.clearSourceType(
                                                    scope.row,
                                                    "sourceType",
                                                    "sourceMethod"
                                                  )
                                                },
                                              },
                                              model: {
                                                value: scope.row.inputType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "inputType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.inputType",
                                              },
                                            },
                                            _vm._l(
                                              _vm.textTypes,
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item.value,
                                                  attrs: {
                                                    label: item.name,
                                                    value: item.value,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          )
                                        : _vm._e(),
                                      scope.row.dateType == "DATE"
                                        ? _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                size: "mini",
                                                clearable: "",
                                                placeholder: "请选择控件类型",
                                              },
                                              model: {
                                                value: scope.row.inputType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "inputType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.inputType",
                                              },
                                            },
                                            _vm._l(
                                              _vm.dateTypes,
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item.value,
                                                  attrs: {
                                                    label: item.name,
                                                    value: item.value,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          )
                                        : _vm._e(),
                                      scope.row.dateType == "BOOLEAN"
                                        ? _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                size: "mini",
                                                clearable: "",
                                                placeholder: "请选择控件类型",
                                              },
                                              model: {
                                                value: scope.row.inputType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "inputType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.inputType",
                                              },
                                            },
                                            _vm._l(
                                              _vm.BooleanTypes,
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item.value,
                                                  attrs: {
                                                    label: item.name,
                                                    value: item.value,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "maxValue",
                                label: "最小(大)值",
                                width: "220",
                                align: "center",
                                "show-overflow-tooltip": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("el-input", {
                                        staticStyle: { width: "46%" },
                                        attrs: {
                                          size: "mini",
                                          clearable: "",
                                          placeholder: "最小值",
                                        },
                                        on: {
                                          input: function ($event) {
                                            scope.row.minValue =
                                              scope.row.minValue.replace(
                                                /[^\d]/g,
                                                ""
                                              )
                                          },
                                        },
                                        model: {
                                          value: scope.row.minValue,
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, "minValue", $$v)
                                          },
                                          expression: "scope.row.minValue",
                                        },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            width: "8%",
                                            display: "inline-block",
                                          },
                                        },
                                        [_vm._v("~")]
                                      ),
                                      _c("el-input", {
                                        staticStyle: { width: "46%" },
                                        attrs: {
                                          size: "mini",
                                          clearable: "",
                                          placeholder: "最大值",
                                        },
                                        on: {
                                          input: function ($event) {
                                            scope.row.maxValue =
                                              scope.row.maxValue.replace(
                                                /[^\d]/g,
                                                ""
                                              )
                                          },
                                        },
                                        model: {
                                          value: scope.row.maxValue,
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, "maxValue", $$v)
                                          },
                                          expression: "scope.row.maxValue",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "sourceType",
                                label: "数据来源",
                                width: "120",
                                "show-overflow-tooltip": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            disabled:
                                              scope.row.inputType != "SELECT" &&
                                              scope.row.inputType !=
                                                "MULTI_SELECT" &&
                                              scope.row.inputType !=
                                                "SELECT_BUTTON",
                                            size: "mini",
                                            clearable: "",
                                            placeholder: "请选择数据来源",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.clearChanged(
                                                scope.row,
                                                "sourceType"
                                              )
                                            },
                                          },
                                          model: {
                                            value: scope.row.sourceType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "sourceType",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.sourceType",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: {
                                              label: "字典",
                                              value: "DICTIONARY",
                                            },
                                          }),
                                          _c("el-option", {
                                            attrs: {
                                              label: "车型",
                                              value: "VEHICLE_MODEL",
                                            },
                                          }),
                                          _c("el-option", {
                                            attrs: {
                                              label: "自定义",
                                              value: "CUSTOM",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "sourceMethod",
                                label: "数据来源方法",
                                width: "auto",
                                align: "center",
                                "show-overflow-tooltip": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("el-input", {
                                        attrs: {
                                          size: "mini",
                                          clearable: "",
                                          placeholder: "请输入数据来源方法",
                                        },
                                        model: {
                                          value: scope.row.sourceMethod,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "sourceMethod",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.sourceMethod",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.activeName == "claimsFile",
                            expression: "activeName == 'claimsFile'",
                          },
                        ],
                        staticClass: "tableBox",
                      },
                      [
                        _c("el-transfer", {
                          attrs: {
                            filterable: "",
                            "filter-placeholder": "请选择理赔附件类型",
                            titles: ["可选类型", "已选类型"],
                            props: { key: "itemCode", label: "itemName" },
                            data: _vm.claimsTransferData,
                          },
                          model: {
                            value: _vm.claimsFileTypeList,
                            callback: function ($$v) {
                              _vm.claimsFileTypeList = $$v
                            },
                            expression: "claimsFileTypeList",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.activeName == "ServiceProductType",
                            expression: "activeName == 'ServiceProductType'",
                          },
                        ],
                        staticClass: "tableBox",
                      },
                      [
                        _c("el-transfer", {
                          attrs: {
                            filterable: "",
                            "filter-placeholder": "请选择服务产品类型",
                            titles: ["可选类型", "已选类型"],
                            props: { key: "itemCode", label: "itemName" },
                            data: _vm.ServiceProductData,
                          },
                          model: {
                            value: _vm.productTypeNameList,
                            callback: function ($$v) {
                              _vm.productTypeNameList = $$v
                            },
                            expression: "productTypeNameList",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-check",
                type: "primary",
                plain: "",
                size: "mini",
              },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: { click: _vm.closePage },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }