var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-m-cls", style: { height: _vm.elHeight } },
    [_vm._m(0)]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "qrClass" }, [
      _c("p", { staticClass: "titleClass" }, [
        _vm._v("请扫描企业微信二维码咨询"),
      ]),
      _c("img", {
        attrs: { src: require("./../../assets/images/qr_code.png") },
      }),
      _c("p", { staticClass: "titleClass-bottom" }, [
        _vm._v("添加时请备注：（例如：华南-广东-深圳XX店-经销商代码）"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }