var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { staticStyle: { "margin-bottom": "10px" }, attrs: { gutter: 20 } },
        _vm._l(_vm.menuList, function (item, index) {
          return _c(
            "el-col",
            { key: index, staticClass: "col_style", attrs: { span: 6 } },
            [
              _c(
                "div",
                {
                  on: {
                    click: function ($event) {
                      return _vm.toNext(item)
                    },
                  },
                },
                [
                  _c("svg-icon", {
                    staticClass: "svgIcon",
                    attrs: { "icon-class": item.icon },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: { "padding-top": "10px", "font-size": "12px" },
                  on: {
                    click: function ($event) {
                      return _vm.toNext(item)
                    },
                  },
                },
                [_vm._v(_vm._s(item.name))]
              ),
            ]
          )
        }),
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        _vm._l(_vm.menuListData, function (item, index) {
          return _c(
            "el-col",
            { key: index, staticClass: "col_style", attrs: { span: 6 } },
            [
              _c(
                "div",
                {
                  on: {
                    click: function ($event) {
                      return _vm.toNext(item)
                    },
                  },
                },
                [
                  _c("svg-icon", {
                    staticClass: "svgIcon",
                    attrs: { "icon-class": item.icon },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: { "padding-top": "10px", "font-size": "12px" },
                  on: {
                    click: function ($event) {
                      return _vm.toNext(item)
                    },
                  },
                },
                [_vm._v(_vm._s(item.name))]
              ),
            ]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }