var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-m-cls", staticStyle: {} }, [
    _c(
      "div",
      {
        ref: "header",
        staticClass: "header-cls",
        staticStyle: { "max-height": "500px", overflow: "auto" },
      },
      [
        _c(
          "el-card",
          { attrs: { shadow: "never" } },
          [
            _c(
              "el-form",
              {
                ref: "detail",
                staticStyle: { "margin-bottom": "-20px" },
                attrs: {
                  model: _vm.form,
                  "label-width": "110px",
                  size: "mini",
                },
              },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "方案名称：" } },
                          [
                            _c("el-select", {
                              staticStyle: { width: "100%" },
                              attrs: { placeholder: "请选择方案名称" },
                              model: {
                                value: _vm.form.ownerCustName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "ownerCustName", $$v)
                                },
                                expression: "form.ownerCustName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "应用机构：", prop: "val3" } },
                          [
                            _c(
                              "el-select",
                              {
                                ref: "orgSelect",
                                staticStyle: { width: "100%" },
                                attrs: {
                                  filterable: "",
                                  placeholder: "请选择所属机构",
                                },
                                model: {
                                  value: _vm.form.salesOrgName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "salesOrgName", $$v)
                                  },
                                  expression: "form.salesOrgName",
                                },
                              },
                              [
                                _c(
                                  "el-option",
                                  {
                                    staticStyle: {
                                      height: "auto",
                                      "background-color": "white",
                                    },
                                    attrs: { value: _vm.form.operateOrgId },
                                  },
                                  [
                                    _c("el-tree", {
                                      ref: "tree",
                                      attrs: {
                                        "expand-on-click-node": false,
                                        "node-key": "code",
                                        "highlight-current":
                                          _vm.highlightCurrent,
                                        data: _vm.orgData,
                                        props: _vm.defaultProps,
                                        "default-expand-all": "",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "导入类别：", prop: "val4" } },
                          [
                            _c("el-select", {
                              staticStyle: { width: "100%" },
                              attrs: { placeholder: "请选择导入类别" },
                              model: {
                                value: _vm.form.ownerCustName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "ownerCustName", $$v)
                                },
                                expression: "form.ownerCustName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "保险公司：", prop: "val5" } },
                          [
                            _c("el-select", {
                              staticStyle: { width: "100%" },
                              attrs: { placeholder: "请选择保险公司" },
                              model: {
                                value: _vm.form.ownerCustName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "ownerCustName", $$v)
                                },
                                expression: "form.ownerCustName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "映射方案：", prop: "val5" } },
                      [
                        _c(
                          "el-table",
                          {
                            ref: "multipleTable",
                            staticStyle: { width: "100%" },
                            attrs: {
                              size: "mini",
                              border: "",
                              data: _vm.tableData,
                              "header-cell-style": { background: "#F7F7F7" },
                              "highlight-current-row": "",
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "",
                                label: "字段",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "",
                                label: "必录字段",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "",
                                label: "取值方式",
                                "min-width": "120",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "",
                                label: "标题名称/表达式/运算公式",
                                "min-width": "160",
                                "show-overflow-tooltip": "",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticStyle: {
          display: "flex",
          "justify-content": "center",
          "margin-top": "8px",
        },
      },
      [
        _c(
          "el-button",
          {
            attrs: {
              icon: "el-icon-check",
              type: "primary",
              plain: "",
              size: "mini",
            },
            on: { click: _vm.save },
          },
          [_vm._v("保存")]
        ),
        _c(
          "el-button",
          {
            attrs: { icon: "el-icon-close", size: "mini" },
            on: { click: _vm.closePage },
          },
          [_vm._v("关闭")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }