import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.keys.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import Pagination from '@/components/Pagination';
import renewalConfigRequest from '@/api/renewalConfig';
import AddDialog from './add';
import inscorpdiffconfigRequest from '@/api/inscorpdiffconfig';
import { commonExport } from '@/utils/common';
export default {
  name: 'OperatRenewalConfig',
  mixins: [initHeight],
  components: {
    Pagination: Pagination,
    AddDialog: AddDialog
  },
  data: function data() {
    return {
      proList: [],
      cascaderAddr: [],
      cityOptions: JSON.parse(localStorage.getItem("VI_CityPcTree")) || [],
      districtTypeList: [{
        name: "省",
        code: 'province'
      }, {
        name: "市",
        code: 'city'
      }],
      showAddDialog: false,
      dialogType: '',
      dialogItem: {},
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      searchForm: {
        districtType: '',
        districtCode: ''
      },
      tableData: [],
      loading: false,
      orgForm: {
        salesOrgCode: '',
        salesOrgName: '',
        salesOrgType: ''
      },
      showDialog: false,
      showImportDialog: false,
      currentId: ''
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.loadData();
      }
    }
  },
  mounted: function mounted() {
    this.getDistricts();
    this.loadData();
  },
  methods: {
    districtTypeChange: function districtTypeChange() {
      this.searchForm.districtCode = null;
    },
    //导出
    exportExcels: function exportExcels() {
      var searchForm = _objectSpread(_objectSpread({}, this.searchForm), this.listQuery);
      var exporParams = {
        total: this.total,
        fileName: '续保配置',
        exportUrl: '/vi-core-service/renewalPeriodConfig/searchRenewalConfig',
        searchForm: searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    // 省/市/区选择
    handleChange: function handleChange(val) {
      this.searchForm.districtCode = val[1];
    },
    // 获取省份
    getDistricts: function getDistricts() {
      var _this = this;
      inscorpdiffconfigRequest.getDistricts({
        type: "PROVINCE"
      }).then(function (res) {
        _this.proList = res.data;
      });
    },
    addClicked: function addClicked(type) {
      this.showAddDialog = true;
      this.dialogType = type;
    },
    deleteItem: function deleteItem(item) {
      var _this2 = this;
      this.$confirm("您确定删除该数据？", "温馨提示", {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: "warning"
      }).then(function () {
        console.log(item);
        renewalConfigRequest.deleteRenewalConfig(item.renewalPeriodConfigId).then(function (res) {
          if (res.code === 200) {
            _this2.$message({
              type: "success",
              message: res.msg
            });
            _this2.loadData();
          }
        });
      });
    },
    toEdit: function toEdit(type, row) {
      this.showAddDialog = true;
      this.dialogItem = row;
      this.dialogType = type;
    },
    // 重置搜索表单
    resetSearch: function resetSearch() {
      this.searchForm = {
        districtType: '',
        districtCode: ''
      };
      this.cascaderAddr = [];
    },
    // 搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    // 刷新
    breakData: function breakData() {
      this.loadData();
    },
    // 获取列表数据
    loadData: function loadData() {
      var _this3 = this;
      var isDialogNoRefresh = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.showAddDialog = false;
      this.showImportDialog = false;
      if (isDialogNoRefresh) return;
      this.loading = true;
      this.searchForm.lookSelf = this.currentType === 'myCustomer' ? true : false;
      var data = _objectSpread(_objectSpread({}, this.searchForm), this.listQuery);
      renewalConfigRequest.searchRenewalConfig(data).then(function (res) {
        _this3.loading = false;
        if (res.code === 200) {
          _this3.tableData = res.data.list;
          _this3.total = res.data.page.recordsTotal;
        }
        ;
      }).catch(function (err) {
        _this3.loading = false;
      });
    }
  }
};