var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    [
      _vm.bizType === "OEM"
        ? _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "业务机构：", prop: "salesOrgName" } },
                [
                  _c("OemSelect", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      searchForm: _vm.orgForm,
                      isGetUserList: true,
                      isContainOem: false,
                    },
                    on: {
                      nodeClick: _vm.handleNodeClick,
                      getCurrentOrgUsers: _vm.oemGetUsers,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.bizType === "DEALER"
        ? _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "业务机构：" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "", placeholder: "" },
                    model: {
                      value: _vm.form.salesOrgName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "salesOrgName", $$v)
                      },
                      expression: "form.salesOrgName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-col",
        { attrs: { span: 8 } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "业务员：", prop: "salemanBy" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { filterable: "", placeholder: "请选择业务员" },
                  on: { change: _vm.salemanNameChange },
                  model: {
                    value: _vm.form.salemanBy,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "salemanBy", $$v)
                    },
                    expression: "form.salemanBy",
                  },
                },
                _vm._l(_vm.salesman, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.userName, value: item.userId },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-col",
        { attrs: { span: 8 } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "产品类型：", prop: "productType" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    filterable: "",
                    disabled: "",
                    placeholder: "请选择产品类型",
                  },
                  model: {
                    value: _vm.form.productType,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "productType", $$v)
                    },
                    expression: "form.productType",
                  },
                },
                _vm._l(_vm.ProductTypeList, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.className, value: item.classCode },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }