//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import carService from '@/api/carService';
import { limitInput } from '@/utils/common';
export default {
  // mixins: [dict],
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    DialogType: {
      type: String,
      default: {}
    },
    insCorpItem: {
      type: Object,
      default: {}
    }
  },
  data: function data() {
    return {
      options: [],
      form: {
        "gradecName": "",
        "guidePrice": 0,
        "modelNo": "",
        "modelName": "",
        "seriesChineseName": "",
        "seriesNo": "",
        "seriesName": ""
      },
      listLoading: false,
      rules: {
        seriesNo: [{
          required: true,
          message: '请输入车系编号',
          trigger: 'blur'
        }],
        seriesName: [{
          required: true,
          message: '请输入车系名称',
          trigger: 'blur'
        }],
        seriesChineseName: [{
          required: true,
          message: '请输入车系中文名称',
          trigger: 'blur'
        }],
        modelNo: [{
          required: true,
          message: '请输入车型编号',
          trigger: 'blur'
        }],
        modelName: [{
          required: true,
          message: '请输入车型名称',
          trigger: 'blur'
        }],
        gradecName: [{
          required: true,
          message: '请输入车型版本',
          trigger: 'blur'
        }],
        guidePrice: [{
          required: true,
          message: '请输入新车指导价',
          trigger: 'blur'
        }]
      }
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    // 对输入内容做精度校验
    onInput: function onInput(num, limit, min, max) {
      if (min && num < min) {
        num = min;
      }
      if (max && num > max) {
        num = max;
      }
      var res = limitInput(num, limit);
      return res;
    },
    checkChange: function checkChange(val) {
      this.form.vehicleNonautoBrand = null;
    },
    initData: function initData() {
      if (this.DialogType === "add") {
        this.form = {
          "gradecName": "",
          "guidePrice": 0,
          "modelNo": "",
          "modelName": "",
          "seriesChineseName": "",
          "seriesNo": "",
          "seriesName": ""
        };
      } else {
        this.form = this.insCorpItem;
      }
    },
    // 保存数据
    save: function save() {
      var _this = this;
      this.$refs.detail.validate(function (valid) {
        if (valid) {
          var req = _this.DialogType === "add" ? carService.addNonBrand(_this.form) : carService.updateNonBrand(_this.form);
          req.then(function (res) {
            if (res.code === 200) {
              _this.$message({
                message: res.msg,
                type: 'success'
              });
              _this.closePage();
            }
          });
        }
      });
    },
    // 关闭弹窗
    closePage: function closePage() {
      this.$refs['detail'].resetFields();
      this.$emit('closePage');
    }
  }
};