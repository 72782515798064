import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.find.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import PageContainer from "@/components/PageContainer";
import FormLabel from "@/components/FormSubLabel";
import userCenterApi from '@/api/userCenter';
import VehicleInfo from './details/vehicleInfo';
import BusinessInfo from './details/businessInfo';
import OrgInfo from './details/orgInfo';
import UserBehavior from './details/userBehavior';
import NetUserDialog from './dialog/netUserDialog';
import UserSource from './details/userSource';
import CorrelationDialog from './dialog/correlationDialog';
export default {
  components: {
    PageContainer: PageContainer,
    FormLabel: FormLabel,
    VehicleInfo: VehicleInfo,
    BusinessInfo: BusinessInfo,
    OrgInfo: OrgInfo,
    UserBehavior: UserBehavior,
    NetUserDialog: NetUserDialog,
    UserSource: UserSource,
    CorrelationDialog: CorrelationDialog
  },
  mixins: [initHeight],
  data: function data() {
    return {
      regionData: JSON.parse(localStorage.getItem('VI_CityAreaTree')) || [],
      opType: null,
      dialogType: null,
      formLoading: false,
      form: {},
      activeNames: ['1'],
      tabActiveName: 'VEHICLEINFO',
      netUserId: '',
      showNetUserDialog: false,
      showCorrelationDialog: false
    };
  },
  watch: {
    '$route': {
      handler: function handler(val) {
        this.initData();
      },
      immediate: true
    }
  },
  computed: {
    // 计算卡片高度
    scrollerHeight: function scrollerHeight() {
      return document.body.clientHeight - 400 + 'px';
    }
  },
  methods: {
    // 初始化数据
    initData: function initData() {
      this.opType = this.$route.query.opType || null;
      this.netUserId = this.$route.query.netUserId ? this.$route.query.netUserId.toString() : '';
      if (this.netUserId) this.getNetUserInfo();
    },
    getNetUserInfo: function getNetUserInfo() {
      var _this = this;
      var isDialogNoRefresh = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.showNetUserDialog = false;
      this.showCorrelationDialog = false;
      if (isDialogNoRefresh) return;
      this.formLoading = true;
      userCenterApi.getNetUserInfo(this.netUserId).then(function (res) {
        _this.formLoading = false;
        if (res.code === 200) {
          _this.form = res.data;
          // this.form.cascaderAddr = [];
          // if(res.data.provinceCode){
          //     this.form.cascaderAddr.push(res.data.provinceCode);
          // }
          // if(res.data.cityCode){
          //     this.form.cascaderAddr.push(res.data.cityCode);
          // }
          // if(res.data.districtCode){
          //     this.form.cascaderAddr.push(res.data.districtCode);
          // }
          var regionName = '';
          //获取地区名称
          var province = _.find(_this.regionData, function (item) {
            return item.value == res.data.provinceCode;
          });
          if (province) {
            // 省
            regionName = province.label;
            _this.form.provinceName = province.label;
            var city = _.find(province.children, function (item) {
              return item.value == res.data.cityCode;
            });
            if (city) {
              // 市
              regionName = regionName === city.label ? regionName : regionName + city.label;
              _this.form.cityName = city.label;
              var area = _.find(city.children, function (item) {
                return item.value == res.data.districtCode;
              });
              if (area) {
                // 区
                regionName = regionName + area.label;
                _this.form.districtName = area.label;
              }
            }
            ;
          }
          ;
          _this.form.regionName = regionName;
        }
        ;
      }).catch(function (err) {
        _this.formLoading = false;
      });
    },
    // 编辑
    edit: function edit(type) {
      this.dialogType = type;
      this.showNetUserDialog = true;
    },
    // 关联
    correlation: function correlation() {
      this.showCorrelationDialog = true;
    },
    // 关闭
    closePage: function closePage() {
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.go(-1);
    }
  }
};