var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-m-cls renewal-config" }, [
    _c(
      "div",
      { staticClass: "table-cls normal-btn-cls" },
      [
        _c(
          "el-card",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticClass: "box-card",
            attrs: { shadow: "never" },
          },
          [
            _c(
              "div",
              { staticStyle: { "margin-bottom": "10px" } },
              [
                _vm._v(" 是否默认配置： "),
                _c(
                  "el-radio",
                  {
                    attrs: {
                      disabled:
                        (!_vm.form.defaulted &&
                          !!_vm.renewalConfigParams.orgCode) ||
                        (_vm.form.defaulted &&
                          !_vm.renewalConfigParams.orgCode),
                      label: true,
                    },
                    on: { change: _vm.defaultedChange },
                    model: {
                      value: _vm.form.defaulted,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "defaulted", $$v)
                      },
                      expression: "form.defaulted",
                    },
                  },
                  [_vm._v("默认配置")]
                ),
                _c(
                  "el-radio",
                  {
                    attrs: {
                      disabled:
                        (!_vm.form.defaulted &&
                          !!_vm.renewalConfigParams.orgCode) ||
                        (_vm.form.defaulted &&
                          !_vm.renewalConfigParams.orgCode),
                      label: false,
                    },
                    on: { change: _vm.defaultedChange },
                    model: {
                      value: _vm.form.defaulted,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "defaulted", $$v)
                      },
                      expression: "form.defaulted",
                    },
                  },
                  [_vm._v("机构配置")]
                ),
                _c(
                  "el-button",
                  {
                    staticStyle: { "margin-left": "10px" },
                    on: { click: _vm.clearCache },
                  },
                  [_vm._v("清除缓存")]
                ),
              ],
              1
            ),
            _c(
              "el-tabs",
              {
                style: { height: _vm.containerHeighST },
                attrs: { "tab-position": "left" },
                model: {
                  value: _vm.activeName,
                  callback: function ($$v) {
                    _vm.activeName = $$v
                  },
                  expression: "activeName",
                },
              },
              [
                _c(
                  "el-tab-pane",
                  { attrs: { label: "续保活动配置", name: "1" } },
                  [
                    _vm.form.coreCustomerRenewalTaskConfigId
                      ? _c("RenewalActivities", {
                          attrs: {
                            currentObj: _vm.form,
                            curRenewalConfigParams: _vm.renewalConfigParams,
                            defaulted: _vm.form.defaulted,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "el-tab-pane",
                  { attrs: { label: "网点客户类型", name: "2" } },
                  [
                    _vm.form.coreCustomerRenewalTaskConfigId
                      ? _c("CustomerTypeNames", {
                          attrs: {
                            currentObj: _vm.form,
                            curRenewalConfigParams: _vm.renewalConfigParams,
                            defaulted: _vm.form.defaulted,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "el-tab-pane",
                  { attrs: { label: "客户类型转换", name: "3" } },
                  [
                    _vm.form.coreCustomerRenewalTaskConfigId
                      ? _c("CustomerType", {
                          attrs: {
                            currentObj: _vm.form,
                            curRenewalConfigParams: _vm.renewalConfigParams,
                            defaulted: _vm.form.defaulted,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "el-tab-pane",
                  { attrs: { label: "任务跟进配置", name: "4" } },
                  [
                    _vm.form.coreCustomerRenewalTaskConfigId
                      ? _c("TaskFollowConfig", {
                          attrs: {
                            currentObj: _vm.form,
                            curRenewalConfigParams: _vm.renewalConfigParams,
                            defaulted: _vm.form.defaulted,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }