import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.json.stringify.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import Dialog from '@/components/Dialog';
import Pagination from '@/components/Pagination'; // Secondary package based on el-pagination
import connectReq from '@/api/connectConfig';
import dict from '@/mixins/dict';
import Rules from '@/utils/rules';
import NonAutoInsurance from '../channelConfig/NonAutoInsurance';
import dictRequest from '@/api/dictionary';
import { getSupplyers } from '@/utils/common';
import { provinceAndCityData } from 'element-china-area-data';
export default {
  name: 'ApiConfig',
  components: {
    Pagination: Pagination,
    Dialog: Dialog,
    NonAutoInsurance: NonAutoInsurance
  },
  mixins: [initHeight, dict],
  computed: {},
  data: function data() {
    return {
      nonAutoInsuranceShow: false,
      drawer: false,
      cityOptions: provinceAndCityData,
      formLoading: false,
      rules: {
        apiConfig: {},
        insCorpCode: [{
          required: true,
          message: '请选择保险公司',
          trigger: 'change'
        }],
        apiConfigName: [{
          required: true,
          message: '请输入配置名称',
          trigger: 'blur'
        }],
        apiConfigType: [{
          required: true,
          message: '请选择接口类型',
          trigger: 'change'
        }],
        apiConfigCode: [{
          required: true,
          message: '请输入配置编码',
          trigger: 'blur'
        }],
        tenantId: [{
          required: true,
          message: '请选择租户',
          trigger: 'change'
        }],
        serviceConfigCode: [{
          required: true,
          message: '请选择对接服务',
          trigger: 'change'
        }],
        supportedNotifyTypes: [{
          required: true,
          message: '请选择接口回调类型',
          trigger: 'change'
        }],
        functionFeature: [{
          required: true,
          message: '请选择保司功能',
          trigger: 'change'
        }]
      },
      //重置搜索表单
      searchForm: {
        apiConfigName: null,
        insCorpCode: null,
        apiConfigType: null
      },
      form: {
        insCorpCode: '',
        serviceConfigCode: '',
        serviceConfigId: '',
        apiConfig: [],
        apiConfigName: '',
        apiConfigCode: '',
        apiConfigType: 'INSURED',
        //默认承保接口,
        supportedNotifyTypes: [],
        functionFeature: []
      },
      showDialog: false,
      list: null,
      listLoading: true,
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      multipleSelection: [],
      opType: '',
      apiConfig: [],
      API_CONFIG_TYPE: [],
      supplyers: [],
      tenantData: [{
        tenantId: '100001',
        tenantName: '默认租户'
      }],
      loading: false,
      showCallbackDialog: false,
      callbackData: [],
      allApiSchema: [],
      serviceConfigs: [],
      API_NOTIFY_TYPE: [{
        dictCode: 'APPLY',
        dictName: '人工核保回调'
      }, {
        dictCode: 'INSURED',
        dictName: '出单承保回调'
      }],
      childsInsCorpCode: null,
      nonAutoInsuranceConfigId: null,
      apiConfigData: [],
      featureEnums: []
    };
  },
  created: function created() {
    this.API_CONFIG_TYPE = this.dictSource['API_CONFIG_TYPE'];
    this.loadData();
    this.supplyers = getSupplyers();
  },
  methods: {
    // 获取中文名称
    getFeatureTypeName: function getFeatureTypeName(types) {
      var name = '';
      types.forEach(function (item) {
        name = name + ',' + item.description;
      });
      if (name && name.indexOf(',') == 0) {
        name = name.slice(1, name.length);
      }
      return name;
    },
    // 获取保司功能字典
    getFeatureEnums: function getFeatureEnums() {
      var _this = this;
      dictRequest.getDictItemsByCode("API_CONFIGURE_FUNCTIONFEATUREENUM").then(function (res) {
        _this.featureEnums = res.data || [];
      });
    },
    // 刷新列表
    breakData: function breakData() {
      this.loadData();
    },
    // 剪贴板回调函数
    onCopy: function onCopy(e) {
      this.$message({
        type: 'success',
        message: '回调地址复制成功！'
      });
    },
    // 剪贴板回调函数
    onError: function onError(e) {
      this.$message({
        type: 'error',
        message: '回调地址复制失败！'
      });
    },
    // 获取保司中文名称
    getSupplyerName: function getSupplyerName(code) {
      var supplyer = _.find(this.supplyers, function (item) {
        return _.toLower(item.code) == _.toLower(code);
      });
      return supplyer ? supplyer.shortName : "";
    },
    // 校验联系电话
    inputValue: function inputValue(item) {
      if (!item.storeValue) return;
      if (_.toLower(item.inputType) == 'email') {
        var emailRule = Rules['EMAIL'].value;
        var flag = emailRule.test(item.storeValue);
        if (!flag) {
          this.$message({
            type: 'error',
            message: "".concat(item.display, "\u4E0D\u6EE1\u8DB3\u6821\u9A8C\u89C4\u5219\uFF0C\u8BF7\u4FEE\u6539\uFF01")
          });
        }
      } else if (_.toLower(item.inputType) == 'number') {
        if (item.minValue != item.maxValue && (item.value < item.minValue || item.value > item.maxValue)) {
          this.$message({
            type: 'error',
            message: "".concat(item.display, "\u7684\u53EF\u8C03\u6574\u8303\u56F4\u5728").concat(item.minValue, " \u5230 ").concat(item.maxValue, " \u4E4B\u95F4\uFF0C\u8BF7\u91CD\u65B0\u8F93\u5165\uFF01")
          });
        }
      }
      this.$forceUpdate();
    },
    // 监听保险变化
    selectInsCorp: function selectInsCorp(val) {
      var _this2 = this;
      this.form.serviceConfigId = this.opType != 'view' ? null : this.form.serviceConfigId;
      this.form.apiConfig = [];
      this.form.serviceConfigCode = null;
      if (val) {
        this.serviceConfigs = [];
        connectReq.getServiceConfigByInsCorpCode(val).then(function (res) {
          _this2.serviceConfigs = res.data || [];
        });
      }
    },
    // 监听接口类型
    selectConfigApiType: function selectConfigApiType(val) {
      if (this.form.serviceConfigCode && this.form.insCorpCode && this.form.apiConfigType) {
        this.getApiConfig();
      }
    },
    // 监听对接服务
    selectConfigType: function selectConfigType(val, _item) {
      if (val && this.form.insCorpCode && this.form.apiConfigType) {
        this.getApiConfig(_item);
      } else {
        this.form.apiConfig = [];
      }
    },
    // 获取配置
    getApiConfig: function getApiConfig(_item) {
      var _this3 = this;
      this.form.apiConfig = [];
      connectReq.getApiSchemaConfig(this.form.insCorpCode, this.form.serviceConfigCode, this.form.apiConfigType).then(function (res) {
        if (res.success && res.code == RESPONSE_SUCCESS) {
          var dataList = res.data.items;
          if (!_.isEmpty(_item)) {
            dataList.forEach(function (item) {
              for (var key in _item) {
                if (item.storeKey == key) {
                  if (_.toUpper(item.inputType) == 'MULTI_SELECT') {
                    if (_item[key]) item.storeValue = _item[key].split(',');
                  } else {
                    item.storeValue = _item[key];
                  }
                }
              }
            });
          }
          _this3.form.apiConfig = dataList;
          _this3.$forceUpdate();
        } else {
          _this3.form.apiConfig = [];
        }
      }).catch(function (err) {
        _this3.form.apiConfig = [];
      });
    },
    // 重置搜索表单
    resetSearch: function resetSearch() {
      this.searchForm = {
        apiConfigName: null,
        insCorpCode: null,
        apiConfigType: null
      };
    },
    // 搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    // 关闭驾意险维护弹窗
    nonAutoCloseList: function nonAutoCloseList() {
      this.nonAutoInsuranceShow = false;
    },
    // 打开驾意险维护弹窗
    showNonAutoInsurance: function showNonAutoInsurance(row) {
      this.nonAutoInsuranceShow = true;
      this.childsInsCorpCode = row.insCorpCode.toString();
      this.nonAutoInsuranceConfigId = row.apiConfigId.toString();
    },
    // 查看回调地址
    viewCallback: function viewCallback(item) {
      var _this4 = this;
      this.callbackData = [];
      this.formLoading = true;
      this.showCallbackDialog = true;
      connectReq.getApiConfigCallbackUrl(item.apiConfigId).then(function (res) {
        _this4.formLoading = false;
        if (res.code == RESPONSE_SUCCESS) {
          _this4.callbackData = res.data || [];
        }
      }).catch(function (err) {
        _this4.formLoading = false;
      });
    },
    // 查看详情
    viewDetail: function viewDetail(type, item) {
      var _this5 = this;
      this.formLoading = true;
      this.opType = type;
      this.getFeatureEnums();
      connectReq.getApiConfigById(item.apiConfigId).then(function (res) {
        if (res.data && res.code == RESPONSE_SUCCESS) {
          var resData = res.data;
          _this5.form.insCorpCode = _.toUpper(resData.insCorpCode);
          _this5.form.apiConfigType = resData.apiConfigType;
          _this5.form.apiConfigCode = resData.apiConfigCode;
          _this5.form.apiConfigName = resData.apiConfigName;
          _this5.form.serviceConfigCode = resData.serviceConfigCode;
          _this5.form.serviceConfigId = resData.serviceConfigId;
          _this5.form.apiConfigId = resData.apiConfigId;
          _this5.form.tenantId = resData.tenantId;
          _this5.form.supportedNotifyTypes = resData.supportedNotifyTypes && _.split(resData.supportedNotifyTypes, ',');
          _this5.form.functionFeature = [];
          if (!_.isEmpty(resData.featureEnumList)) {
            resData.featureEnumList.forEach(function (item) {
              return _this5.form.functionFeature.push(item.code);
            });
          }
          if (_this5.form.insCorpCode) {
            connectReq.getServiceConfigByInsCorpCode(_this5.form.insCorpCode).then(function (res) {
              _this5.formLoading = false;
              if (res.data) {
                _this5.serviceConfigs = res.data;
                var temp = _.find(res.data, function (item) {
                  return item.serviceConfigId == resData.serviceConfigId;
                });
                if (temp) {
                  _this5.form.serviceConfigCode = temp.serviceCode;
                  var currentApiConfig = JSON.parse(resData.apiConfig);
                  _this5.selectConfigType(_this5.form.serviceConfigCode, currentApiConfig);
                }
              }
            });
          } else {
            _this5.formLoading = false;
          }
          _this5.$nextTick(function () {
            _this5.$refs["form"].clearValidate();
          });
          _this5.showDialog = true;
        } else {
          _this5.formLoading = false;
        }
      }).catch(function (err) {
        _this5.formLoading = false;
      });
    },
    // 启用或禁用
    enableItem: function enableItem(item) {
      var _this6 = this;
      if (item.isClick) return;
      item.isClick = true;
      connectReq.changeApiConfigStatus(item.apiConfigId, item.enabled).then(function (res) {
        item.isClick = false;
        if (res.code == RESPONSE_SUCCESS) {
          _this6.$message({
            type: 'success',
            message: res.msg
          });
          // this.loadData();
        } else {
          item.enabled = false;
        }
      }).catch(function (err) {
        item.isClick = false;
        item.enabled = false;
      });
    },
    // 删除
    removeItems: function removeItems(item) {
      var _this7 = this;
      var ids = [];
      if (!_.isEmpty(item)) {
        ids.push(item.apiConfigId);
      } else {
        if (!_.isEmpty(this.multipleSelection)) {
          ids = _.map(this.multipleSelection, 'apiConfigId');
        }
      }
      if (_.isEmpty(ids)) return;
      this.$confirm('是否确认删除该接口配置？确认删除后不能恢复。', '温馨提示', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        type: 'warning'
      }).then(function () {
        connectReq.deleteApiConfig(ids).then(function (res) {
          if (res.code == RESPONSE_SUCCESS) {
            _this7.$message({
              type: 'success',
              message: res.msg
            });
            _this7.showDialog = false;
            _this7.loadData();
          }
        });
      });
    },
    // 新增
    addItem: function addItem() {
      this.form = {
        insCorpCode: '',
        serviceConfigCode: '',
        serviceConfigId: '',
        apiConfig: [],
        apiConfigName: '',
        apiConfigCode: '',
        apiConfigType: 'INSURED',
        //默认承保接口
        supportedNotifyTypes: [],
        functionFeature: []
      };
      this.opType = 'add';
      this.showDialog = true;
      this.getFeatureEnums();
      this.$forceUpdate();
    },
    // 列表行点击事件
    handleRowChange: function handleRowChange(row, event, column) {
      // 点击选中
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    // 获取列表数据
    loadData: function loadData() {
      var _this8 = this;
      this.loading = true;
      this.tableData = [];
      this.drawer = false;
      connectReq.getApiConfigList(this.listQuery.pageNum, this.listQuery.pageSize, this.searchForm).then(function (res) {
        _this8.loading = false;
        if (!_.isEmpty(res.data)) {
          _this8.tableData = res.data.list;
          // 总条数
          _this8.total = res.data.page.recordsTotal;
        }
      }).catch(function (err) {
        _this8.loading = false;
      });
    },
    // 验证表单
    submit: function submit() {
      var _this9 = this;
      this.$refs['form'].validate(function (valid) {
        if (valid) {
          _this9.formLoading = true;
          var settingItem = {};
          _this9.form.apiConfig.forEach(function (item) {
            if (item.storeValue) {
              var newField = item.storeKey;
              if (_.toUpper(item.inputType) == 'MULTI_SELECT') {
                settingItem[newField] = item.storeValue.toString();
              } else {
                settingItem[newField] = item.storeValue;
              }
            }
          });
          _this9.form.tenantId = '100001';
          if (!_.isEmpty(_this9.tenantData)) {
            var tenant = _.find(_this9.tenantData, function (item) {
              return item.tenantId == _this9.form.tenantId;
            });
            if (tenant) _this9.form.tenantName = tenant.tenantName;
          }
          if (!_.isEmpty(_this9.form.insCorpCode)) {
            var insCorp = _.find(_this9.supplyers, function (item) {
              return _.toLower(item.code) == _.toLower(_this9.form.insCorpCode);
            });
            if (insCorp) {
              _this9.form.insCorpName = insCorp.shortName;
            }
          }
          var postData = _.pick(_this9.form, ['serviceConfigId', 'serviceConfigCode', 'tenantId', 'tenantName', 'apiConfig', 'apiConfigName', 'apiConfigType', 'apiConfigCode', 'insCorpCode', 'apiConfigId', 'insCorpName', 'supportedNotifyTypes']);
          var temp = _.find(_this9.serviceConfigs, function (item) {
            return item.serviceCode == postData.serviceConfigCode;
          });
          postData.serviceConfigId = temp && temp.serviceConfigId;
          postData.apiConfig = JSON.stringify(settingItem);
          postData.supportedNotifyTypes = _this9.form.supportedNotifyTypes.toString();
          postData.functionFeature = _this9.form.functionFeature;
          var localRequest = _this9.opType == 'update' ? connectReq.updateApiConfig(postData.apiConfigId, postData) : connectReq.createApiConfig(postData);
          localRequest.then(function (res) {
            if (res.code == RESPONSE_SUCCESS) {
              _this9.$refs['form'].resetFields();
              _this9.showDialog = false;
              _this9.loadData();
            }
            _this9.formLoading = false;
          }).catch(function (err) {
            _this9.formLoading = false;
          });
        }
      });
    },
    // 列表行选择事件
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 关闭弹窗
    cancel: function cancel() {
      this.form = {
        insCorpCode: '',
        serviceConfigCode: '',
        serviceConfigId: '',
        apiConfig: [],
        apiConfigName: '',
        apiConfigCode: '',
        apiConfigType: 'INSURED',
        //默认承保接口
        supportedNotifyTypes: [],
        functionFeature: []
      };
      this.$refs['form'].resetFields();
      this.showDialog = false;
    }
  }
};