//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PageContainer from '@/components/PageContainer';
import FormLabel from '@/components/FormSubLabel';
import request from '@/api/insurancepolicy';
export default {
  name: 'HistoryPolicyDetail',
  components: {
    FormLabel: FormLabel,
    PageContainer: PageContainer
  },
  data: function data() {
    return {
      form: {},
      currentId: ''
    };
  },
  watch: {
    '$route': function $route(to, from) {
      //监听路由是否变化）
      this.initView();
    }
  },
  created: function created() {
    this.initView();
  },
  methods: {
    initView: function initView() {
      this.currentId = this.$route.query.id || '';
      if (this.currentId) this.getDetails();
    },
    getDetails: function getDetails() {
      var _this = this;
      request.getHistoryPolicyDetail(this.currentId).then(function (res) {
        if (res.code === 200) {
          _this.form = res.data;
        }
        ;
      });
    },
    // 返回
    closePage: function closePage() {
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.go(-1);
    }
  }
};