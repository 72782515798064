var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.formLoading,
          expression: "formLoading",
        },
      ],
      staticClass: "msg-detail-page",
    },
    [
      _c("PageContainer", [
        _c("div", { attrs: { slot: "main" }, slot: "main" }, [
          _c(
            "div",
            {
              staticClass: "app-m-cls",
              staticStyle: {
                "min-height": "calc(100vh - 130px)",
                padding: "10px",
                "font-size": "15px",
              },
            },
            [
              _c("el-card", { attrs: { shadow: "never" } }, [
                _c(
                  "div",
                  {
                    staticStyle: { "font-size": "20px", "font-weight": "700" },
                  },
                  [_vm._v(_vm._s(_vm.form.messageTypeName))]
                ),
                _c("div", { staticStyle: { margin: "20px 0" } }, [
                  _vm._v("发送时间：" + _vm._s(_vm.form.createdTime)),
                ]),
                _c("div", [
                  _vm._v(
                    "消息内容：" +
                      _vm._s(
                        _vm.form.messageContent &&
                          _vm.form.messageContent.content
                      )
                  ),
                ]),
              ]),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }