var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.formLoading,
          expression: "formLoading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: {},
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "540px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "detail",
                  staticStyle: { "margin-bottom": "-20px" },
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-width": "120px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "接入名称：",
                                prop: "inputConfigName",
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入接入名称",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.inputConfigName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "inputConfigName", $$v)
                                  },
                                  expression: "form.inputConfigName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "接入代码：",
                                prop: "inputConfigCode",
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入接入标识代码",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.inputConfigCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "inputConfigCode", $$v)
                                  },
                                  expression: "form.inputConfigCode",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "推送数据：",
                                prop: "dataConfigCode",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { placeholder: "请选择推送数据" },
                                  model: {
                                    value: _vm.form.dataConfigCode,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "dataConfigCode", $$v)
                                    },
                                    expression: "form.dataConfigCode",
                                  },
                                },
                                _vm._l(
                                  _vm.pushDataList,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.dataConfigName,
                                        value: item.dataConfigCode,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "是否启用：",
                                prop: "inputConfigState",
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.form.inputConfigState,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "inputConfigState",
                                        $$v
                                      )
                                    },
                                    expression: "form.inputConfigState",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: true } }, [
                                    _vm._v("是"),
                                  ]),
                                  _c("el-radio", { attrs: { label: false } }, [
                                    _vm._v("否"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "是否实时：",
                                prop: "realTimeFlag",
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.form.realTimeFlag,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "realTimeFlag", $$v)
                                    },
                                    expression: "form.realTimeFlag",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: true } }, [
                                    _vm._v("是"),
                                  ]),
                                  _c("el-radio", { attrs: { label: false } }, [
                                    _vm._v("否"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "处理接入的服务名：",
                                prop: "serviceName",
                                "label-width": "150px",
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入服务名",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.serviceName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "serviceName", $$v)
                                  },
                                  expression: "form.serviceName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "处理接入方法的url：",
                                prop: "serviceUri",
                                "label-width": "150px",
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  placeholder: "请输入方法url",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.serviceUri,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "serviceUri", $$v)
                                  },
                                  expression: "form.serviceUri",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-check",
                type: "primary",
                plain: "",
                size: "mini",
              },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: { click: _vm.closePage },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }