import _createForOfIteratorHelper from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CarBrands from '@/components/CarBrands/index';
import InsuranceServicePlanRequest from '@/api/InsuranceServicePlan';
import servicePlanRequest from '@/api/InsuranceServicePlan';
import dictRequest from '@/api/dictionary';
import carService from '@/api/carService';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    dialogType: {
      type: String,
      default: 'add'
    },
    DialogItem: {
      type: Object,
      default: {}
    }
    // DialogId: {
    //     type: String,
    //     default: ''
    // },
  },

  components: {
    CarBrands: CarBrands
  },
  data: function data() {
    return {
      carTypelist: [],
      carTypeVal: [],
      AllAmount: 0,
      modellist: [],
      carCheckList: [],
      JYXcheckList: [],
      ZZFWcheckList: [],
      resDatalist: [],
      form: {
        modelNo: "",
        insuranceSchemeId: "",
        insuranceServiceRisk: []
      },
      createPlanRules: {
        modelNo: [{
          required: true,
          message: '请选择车型',
          trigger: 'change'
        }],
        insuranceSchemeId: [{
          required: true,
          message: '请选择方案名称',
          trigger: 'change'
        }]
      },
      conductorUserlist: [],
      listQuery: {
        pageNum: 1,
        pageSize: 1000
      },
      InsuranceTypes: [],
      InsuranceschemeList: [],
      insuranceSchemeRiskList: []
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.getInsuranceschemeList();
        this.getModels();
        this.getInsuranceTypes();
        if (this.dialogType == 'add') {
          this.form = {
            modelNo: "",
            insuranceSchemeId: "",
            insuranceServiceRisk: []
          };
          this.insuranceSchemeRiskList = [];
          this.$refs['createPlan'].resetFields();
          this.carCheckList = [];
        } else {
          this.initData();
        }
      }
    },
    'insuranceSchemeRiskList': {
      handler: function handler(newValue) {
        if (newValue) {
          var amount = 0;
          newValue.forEach(function (item) {
            if (item.riskPremium) {
              amount = parseFloat(amount) + parseFloat(item.riskPremium);
            }
          });
          this.AllAmount = amount.toFixed(2);
        }
      },
      deep: true
    }
  },
  mounted: function mounted() {
    this.getInsuranceschemeList();
    this.getModels();
    if (this.dialogType == 'add') {
      this.insuranceSchemeRiskList = [];
      this.$refs['createPlan'].resetFields();
      this.carCheckList = [];
    } else {
      this.initData();
    }
    // this.getInsuranceTypes();
  },

  methods: {
    // 监听数据变化
    CarHandleChange: function CarHandleChange(val, list) {
      var mlist = _.find(list, function (item) {
        return item.value == val[0];
      });
      var mitem = {};
      mitem = _.find(mlist.children, function (item) {
        return item.value == val[1];
      });
      this.form.modelNo = mitem.label;
      this.carTypeVal = val;
    },
    limitInput: function limitInput(value, row, name) {
      var index = _.findIndex(this.insuranceSchemeRiskList, function (i) {
        return i.riskCode == row.riskCode;
      });
      this.insuranceSchemeRiskList[index][name] = ('' + value // 第一步：转成字符串
      ).replace(/[^\d^\.]+/g, '') // 第二步：把不是数字，不是小数点的过滤掉
      .replace(/^0+(\d)/, '$1') // 第三步：第一位0开头，0后面为数字，则过滤掉，取后面的数字
      .replace(/^\./, '0.') // 第四步：如果输入的第一位为小数点，则替换成 0. 实现自动补全
      .match(/^\d*(\.?\d{0,2})/g)[0] || ''; // 第五步：最终匹配得到结果 以数字开头，只有一个小数点，	而且小数点后面只能有0到2位小数
    },
    getPlanDetail: function getPlanDetail(val) {
      var _this = this;
      var id = _.find(this.InsuranceschemeList, function (i) {
        return i.insuranceSchemeId == val;
      });
      InsuranceServicePlanRequest.getDetail(id.insuranceSchemeId).then(function (res) {
        if (res.code == 200) {
          _this.insuranceSchemeRiskList = res.data.insuranceSchemeRiskList ? res.data.insuranceSchemeRiskList : [];
        }
      });
    },
    getInsuranceschemeList: function getInsuranceschemeList() {
      var _this2 = this;
      //获取数据列表
      this.loading = true;
      var data = {
        pageNum: 1,
        pageSize: 100
      };
      InsuranceServicePlanRequest.getInsuranceschemeList(data).then(function (res) {
        _this2.loading = false;
        if (res.code == 200) {
          _this2.InsuranceschemeList = res.data && res.data.list ? res.data.list : [];
        }
      }).catch(function (err) {
        _this2.loading = false;
      });
    },
    getModels: function getModels() {
      var _this3 = this;
      carService.brandOptions().then(function (res) {
        if (res.code === 200) {
          // console.log(res)
          var list = res.data ? res.data : [];
          var id = '';
          _this3.carTypelist = list;
          var _iterator = _createForOfIteratorHelper(list),
            _step;
          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var i = _step.value;
              if (i.brandNo == "FTMS" || i.brandName == "一汽丰田") {
                id = i.vehicleBrandId;
              }
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }
          if (id) {
            carService.Options(id).then(function (res) {
              if (res.code === 200) {
                _this3.modellist = res.data;
              }
            });
          }
        }
      });
    },
    deleteItem: function deleteItem(item) {
      var _this4 = this;
      //删除险别
      console.log('item=>:', item);
      this.$confirm('您确定要删除么？', {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        servicePlanRequest.deleteRisk(item).then(function (res) {
          _this4.initData();
        });
      });
    },
    getInsuranceTypes: function getInsuranceTypes() {
      var _this5 = this;
      //获取险别
      dictRequest.getDictItemsByCode("TYPES_OF_INSURANCE").then(function (res) {
        if (res.data) {
          var _iterator2 = _createForOfIteratorHelper(res.data),
            _step2;
          try {
            for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
              var i = _step2.value;
              i.modelNo = '';
              i.riskPremium = '';
            }
          } catch (err) {
            _iterator2.e(err);
          } finally {
            _iterator2.f();
          }
          _this5.InsuranceTypes = res.data ? res.data : [];
        }
      });
    },
    initData: function initData() {
      var _this6 = this;
      //初始化数据
      this.insuranceSchemeRiskList = [];
      if (this.dialogType == 'add') {
        this.form = {
          modelNo: "",
          insuranceSchemeId: "",
          insuranceServiceRisk: []
        };
        this.insuranceSchemeRiskList = [];
        // console.log()
      } else {
        // console.log(this.DialogItem)
        InsuranceServicePlanRequest.getInsuranceserviceDetail(this.DialogItem.serviceId).then(function (res) {
          console.log(res);
          if (res.code == 200) {
            var amount = 0;
            res.data.insuranceServiceRiskList.forEach(function (item) {
              if (item.riskPremium) {
                amount = parseFloat(amount) + parseFloat(item.riskPremium);
              }
            });
            _this6.AllAmount = amount.toFixed(2);
            _this6.form = res.data;
            _this6.insuranceSchemeRiskList = res.data.insuranceServiceRiskList;
          }
        });
      }
    },
    save: function save() {
      var _this7 = this;
      //保存数据
      this.$refs['createPlan'].validate(function (valid) {
        if (valid) {
          if (_this7.dialogType == 'add') {
            console.log(_this7.insuranceSchemeRiskList);
            var list = [];
            var _iterator3 = _createForOfIteratorHelper(_this7.insuranceSchemeRiskList),
              _step3;
            try {
              for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
                var i = _step3.value;
                list.push({
                  riskCode: i.riskCode ? i.riskCode : "",
                  // riskName: i.riskName ? i.riskName : "",
                  riskPremium: i.riskPremium ? i.riskPremium : ""
                  // serviceProjectId: i.serviceProjectId ? i.serviceProjectId : "",
                  // sort: i.sort ? i.sort : ""
                });
              }
            } catch (err) {
              _iterator3.e(err);
            } finally {
              _iterator3.f();
            }
            _this7.form.insuranceServiceRisk = list; //insuranceServiceRisk
            console.log(_this7.form);
            InsuranceServicePlanRequest.createInsuranceservice(_this7.form).then(function (res) {
              if (res.code == 200) {
                _this7.$message({
                  message: res.msg,
                  type: 'success'
                });
                _this7.closePage();
              }
            });
          } else {
            var data = {
              serviceId: _this7.DialogItem.serviceId,
              insuranceSchemeId: _this7.form.insuranceSchemeId,
              modelNo: _this7.form.modelNo,
              insuranceServiceRisk: _this7.insuranceSchemeRiskList
            };
            // console.log(this.form)
            console.log(data);
            // return
            InsuranceServicePlanRequest.updateInsuranceservice(data).then(function (res) {
              if (res.code == 200) {
                _this7.$message({
                  message: res.msg,
                  type: 'success'
                });
                _this7.closePage();
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    closePage: function closePage() {
      //关闭弹窗
      this.$refs['createPlan'].resetFields();
      this.carCheckList = [];
      this.$emit('closePage');
    }
  }
};