import _defineProperty from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.keys.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Dialog from '@/components/Dialog';
import request from '../../api/inscorpdiffconfig';
import FormLabel from '@/components/FormSubLabel';
import { limitInput } from '@/utils/common';
export default {
  name: 'InsCorpDiffConfig',
  components: {
    Dialog: Dialog,
    FormLabel: FormLabel
  },
  computed: {
    containerHeight: function containerHeight() {
      return document.body.clientHeight - 132;
    }
  },
  watch: {
    filterText: function filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  data: function data() {
    var _ref;
    return _ref = {
      formRegion: {},
      formRules: {
        riskCode: [{
          required: true,
          message: "请选择保险公司",
          trigger: "change"
        }]
      },
      formRegionRules: {
        insCorpCode: [{
          required: true,
          message: "请选择保险公司",
          trigger: "change"
        }],
        PROVINCE: [{
          required: true,
          message: "请选择省份",
          trigger: "change"
        }],
        insCityCode: [{
          required: true,
          message: "请选择城市",
          trigger: "change"
        }]
      },
      opType: 'add',
      duyList: [{
        code: 'FZ01',
        name: '道路救援服务'
      }, {
        code: 'FZ02',
        name: '车辆安全检测'
      }, {
        code: 'FZ03',
        name: '代为驾驶服务'
      }, {
        code: 'FZ04',
        name: '代为送检服务'
      }],
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      inputValue: "",
      inputVisible: false,
      activeName: 'first',
      tableData: [],
      multipleSelection: [],
      loading: false,
      defaultProps: {
        children: 'city',
        label: 'shortName'
      },
      filterText: '',
      form: {
        defaulted: false,
        multiple: true,
        serviceitems: [{
          checked: false,
          serviceName: "",
          serviceCode: "",
          serviceTimes: '',
          readonly: false,
          options: []
        }]
      }
    }, _defineProperty(_ref, "opType", ''), _defineProperty(_ref, "formLoading", false), _defineProperty(_ref, "showDialog", false), _defineProperty(_ref, "rules", {}), _defineProperty(_ref, "highlightCurrent", true), _defineProperty(_ref, "defaultCheckedKeys", []), _defineProperty(_ref, "currentNodeData", {}), _defineProperty(_ref, "defaultSelectOrg", {
      label: '阳光财险',
      code: 'ygbx',
      children: null
    }), _defineProperty(_ref, "currentInsCropData", {}), _defineProperty(_ref, "insCropDataList", []), _defineProperty(_ref, "currentSecNode", {}), _defineProperty(_ref, "isClick", false), _defineProperty(_ref, "errHeightData", {}), _defineProperty(_ref, "showRegionDialog", false), _defineProperty(_ref, "proList", []), _defineProperty(_ref, "citylist", []), _defineProperty(_ref, "loading", false), _ref;
  },
  mounted: function mounted() {
    this.getListDiff();
    this.getDistricts();
    if (this.insCropDataList && this.insCropDataList.length > 0) {
      this.currentNodeData = this.defaultSelectOrg;
      this.form.insCorpCode = this.currentNodeData.code;
      this.initSelectOrg(this.currentNodeData.code);
      this.loadData();
    }
  },
  methods: {
    copyDefaultConfig: function copyDefaultConfig() {
      var _this = this;
      //复制默认配置
      if (!this.currentNodeData) return;
      request.copyConfig({
        insCorpCode: this.currentNodeData.code,
        insCityCode: this.currentNodeData.insCityCode
      }).then(function (res) {
        if (res.code == 200) {
          _this.loadData();
        }
      });
    },
    getDistricts: function getDistricts() {
      var _this2 = this;
      //获取省份
      request.getDistricts({
        type: "PROVINCE"
      }).then(function (res) {
        _this2.proList = res.data;
      });
    },
    getCity: function getCity(name) {
      var _this3 = this;
      //获取城市
      request.getCity({
        districtId: name
      }).then(function (res) {
        _this3.citylist = res.data;
      });
    },
    addCity: function addCity() {
      var _this4 = this;
      //添加地区
      this.$refs['formRegion'].validate(function (valid) {
        if (valid) {
          request.addCity({
            "insCityCode": _this4.formRegion.insCityCode,
            "insCorpCode": _this4.formRegion.insCorpCode
          }).then(function (res) {
            if (res.code == 200) {
              _this4.cancelRegion();
              _this4.getListDiff();
            }
          });
        }
      });
    },
    showRegion: function showRegion() {
      this.showRegionDialog = true;
    },
    getListDiff: function getListDiff() {
      var _this5 = this;
      //获取险别列表
      this.loading = true;
      request.getListDiff({
        type: "INSURANCE"
      }).then(function (res) {
        _this5.loading = false;
        if (res.data) {
          _this5.insCropDataList = res.data.map(function (item) {
            if (item.city) {
              item.city = item.city.map(function (subItem) {
                subItem.shortName = subItem.insCityName;
                subItem.code = item.code;
                return subItem;
              });
              _.remove(item.city, function (o) {
                return !o.insCityCode && !o.insCityName;
              });
            }
            return item;
          });
        }
      }).catch(function (err) {
        _this5.loading = false;
      });
    },
    inputBlur: function inputBlur(val) {
      var _this6 = this;
      //校验当前表单
      this.$refs['form'].validateField(val, function (msg) {
        if (msg) {
          _this6.errHeightData[val] = true;
        } else {
          _this6.errHeightData[val] = false;
        }
      });
      this.$forceUpdate();
    },
    viewServiceCode: function viewServiceCode(val) {
      //如果增值服务名称编码出现同样的，给出提示
      var serviceitems = _.filter(this.form.serviceitems, function (o) {
        return o.serviceCode == val;
      });
      if (!_.isEmpty(serviceitems) && serviceitems.length > 1) {
        this.$message({
          type: 'error',
          message: "\u7F16\u7801".concat(val, "\u5DF2\u5B58\u5728,\u8BF7\u91CD\u65B0\u8F93\u5165\uFF01")
        });
        this.isClick = true;
        return;
      }
      this.isClick = false;
    },
    onInput: function onInput(num, min, max, limit) {
      //对输入内容做精度校验
      if (min && num < min) {
        num = min;
      }
      if (max && num > max) {
        num = max;
      }
      return limitInput(num, limit);
    },
    setEllipsis: function setEllipsis(value, len) {
      //内容过长时显示'...'
      if (!value) return '';
      if (value.length > len) {
        return value.slice(0, len) + '...';
      }
      return value;
    },
    removeItems: function removeItems(item) {
      var _this7 = this;
      //删除
      var ids = [];
      if (!_.isEmpty(item)) {
        ids.push(item.insCorpDiffServicetermsId);
      } else {
        if (!_.isEmpty(this.multipleSelection)) {
          ids = _.map(this.multipleSelection, 'insCorpDiffServicetermsId');
        }
      }
      if (_.isEmpty(ids)) return;
      this.$confirm('是否确认删除该增值服务配置？确认删除后不能恢复。', '温馨提示', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        type: 'warning'
      }).then(function () {
        request.delete(ids).then(function (res) {
          if (res.code == RESPONSE_SUCCESS) {
            _this7.$message({
              type: 'success',
              message: res.msg
            });
            _this7.loadData();
          }
        });
      });
    },
    getChangeNode: function getChangeNode(data, node, target) {},
    initSelectOrg: function initSelectOrg(code) {
      var _this8 = this;
      //初始化机构树
      this.defaultCheckedKeys.push(code);
      this.$nextTick(function () {
        _this8.$refs.tree.setCurrentKey(code);
      });
    },
    handleInputConfirm: function handleInputConfirm(item, index) {
      //输入框监听
      var newInputValue = this.inputValue;
      if (newInputValue) {
        this.form.serviceitems[index].options.push(newInputValue);
      }
      item.inputVisible = false;
      this.inputValue = '';
    },
    showInput: function showInput(item) {
      //显示输入框
      this.$forceUpdate();
      item.inputVisible = true;
    },
    handleTagClose: function handleTagClose(tag, index) {
      //关闭tag
      this.form.serviceitems[index].options.splice(this.form.serviceitems[index].options.indexOf(tag), 1);
    },
    submit: function submit(type) {
      var _this9 = this;
      //表单提交
      this.$refs['form'].validate(function (valid, obj) {
        if (valid) {
          _this9.isClick = true;
          var itemName = _.find(_this9.duyList, function (item) {
            return item.code == _this9.form.riskCode;
          });
          _this9.form.riskName = itemName.name;
          //创建模板 
          var resData = {
            insCorpCode: _this9.currentNodeData.code,
            insCityCode: _this9.currentNodeData.insCityCode || '',
            insCorpDiffId: _this9.form.insCorpDiffId,
            insCorpDiffServicetermsId: type == 'update' ? _this9.form.insCorpDiffServicetermsId : null,
            multiple: _this9.form.multiple,
            riskCode: _this9.form.riskCode,
            riskName: _this9.form.riskName,
            riskRemark: _this9.form.riskRemark,
            serviceItems: _this9.form.serviceitems,
            defaulted: _this9.form.defaulted
          };
          var requestCommon = type == 'update' ? request.update(resData) : request.create(resData);
          requestCommon.then(function (res) {
            _this9.isClick = false;
            if (res.code == RESPONSE_SUCCESS) {
              _this9.$message({
                type: 'success',
                message: res.msg
              });
              _this9.loadData();
              _this9.showDialog = false;
              _this9.$refs['form'].resetFields();
            }
          }).catch(function (error) {
            _this9.isClick = false;
          });
        }
      });
    },
    cancelRegion: function cancelRegion() {
      this.showRegionDialog = false;
      this.formRegion = {};
      this.$refs['formRegion'].resetFields();
    },
    cancel: function cancel() {
      //关闭弹窗
      this.form = {
        insCorpCode: this.currentNodeData.code,
        insCorpName: this.currentNodeData.label,
        multiple: true,
        defaulted: false,
        serviceitems: [{
          checked: false,
          serviceName: "",
          serviceCode: "",
          serviceTimes: '',
          readonly: false,
          options: []
        }]
      };
      this.$refs['form'].resetFields();
      this.showDialog = false;
    },
    addServiceitem: function addServiceitem() {
      //新增服务项目
      var serviceitems = {
        checked: false,
        serviceName: "",
        serviceCode: "",
        serviceTimes: '',
        readonly: false,
        options: []
      };
      this.form.serviceitems.push(serviceitems);
    },
    deleteServiceitem: function deleteServiceitem(index) {
      //删除服务项目
      this.form.serviceitems.splice(index, 1);
      if (this.form.serviceitems.length == 0) {
        this.addServiceitem();
      }
    },
    updateValue: function updateValue(val) {
      //刷新视图
      this.$forceUpdate();
    },
    handleNodeClick: function handleNodeClick(data, node, target) {
      //组织机构树点击事件
      this.currentNodeData = data;
      this.loadData();
    },
    filterNode: function filterNode(value, data) {
      //树节点过滤
      if (!value) return true;
      return data.shortName && data.shortName.indexOf(value) !== -1;
    },
    handleRowChange: function handleRowChange(row, event, column) {
      //列表行点击事件
      this.$refs.multipleTable.toggleRowSelection(row); // 点击选中
    },
    handleSelectionChange: function handleSelectionChange(val) {
      //列表行选择事件
      this.multipleSelection = val;
    },
    updateItem: function updateItem(item) {
      //更新险别配置
      this.showDialog = true;
    },
    viewDetail: function viewDetail(item) {
      //查看详情
      this.opType = 'update';
      this.showDialog = true;
      var serviceitems = {
        checked: false,
        serviceName: "",
        serviceCode: "",
        serviceTimes: '',
        readonly: false,
        options: []
      };
      var newItem = _.cloneDeep(item);
      var itemServe = newItem.serviceitems ? newItem.serviceitems : newItem.serviceitems.push(serviceitems);
      newItem.serviceitems = itemServe;
      this.form = newItem;
      this.form.multiple = item.multiple;
      this.form.insCorpDiffId = item.insCorpDiffId;
      this.form.insCorpDiffServicetermsId = item.insCorpDiffServicetermsId;
      this.form.insCorpCode = this.currentNodeData.code;
      this.form.insCorpName = this.currentNodeData.label;
      this.form.defaulted = item.defaulted;
    },
    loadData: function loadData() {
      var _this10 = this;
      //获取险别列表
      request.getList(this.listQuery.pageNum, this.listQuery.pageSize, {
        insCorpCode: this.currentNodeData.code,
        insCityCode: this.currentNodeData.insCityCode || ''
      }).then(function (res) {
        if (res.code == RESPONSE_SUCCESS) {
          _this10.currentInsCropData = res.data;
          if (_this10.currentInsCropData && !_.isEmpty(_this10.currentInsCropData.list)) {
            _this10.currentInsCropData.list.forEach(function (item) {
              item.serviceitems = JSON.parse(item.serviceitems);
            });
          }
        }
      });
    },
    addItem: function addItem() {
      //新增增值服务配置
      this.opType = 'add';
      this.form = {
        insCorpCode: this.currentNodeData.code,
        insCorpName: this.currentNodeData.label,
        multiple: true,
        defaulted: false,
        serviceitems: [{
          checked: false,
          serviceName: "",
          serviceCode: "",
          serviceTimes: '',
          readonly: false,
          options: []
        }]
      };
      this.showDialog = true;
    }
  }
};