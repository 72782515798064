var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.listLoading,
          expression: "listLoading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: {},
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "600px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "detail",
                  staticStyle: { "margin-bottom": "-20px" },
                  attrs: {
                    model: _vm.form,
                    "label-width": "110px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "联系人：" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入联系人",
                                  maxlength: "40",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.ownerName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "ownerName", $$v)
                                  },
                                  expression: "form.ownerName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "联系电话：" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入联系电话",
                                  maxlength: "11",
                                  oninput: "value=value.replace(/[^\\d]/g,'')",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.ownerMobile,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "ownerMobile", $$v)
                                  },
                                  expression: "form.ownerMobile",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "其他电话：" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入其他电话",
                                  maxlength: "11",
                                  oninput: "value=value.replace(/[^\\d]/g,'')",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.ownerOtherMobile,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "ownerOtherMobile", $$v)
                                  },
                                  expression: "form.ownerOtherMobile",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "座机号：" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入座机号",
                                  maxlength: "15",
                                  oninput: "value=value.replace(/[^\\d-]/g,'')",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.ownerLandline,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "ownerLandline", $$v)
                                  },
                                  expression: "form.ownerLandline",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "电子邮箱：" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入电子邮箱",
                                  maxlength: "40",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.email,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "email", $$v)
                                  },
                                  expression: "form.email",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "所在地区：" } },
                            [
                              _c("el-cascader", {
                                ref: "cascaderAddr",
                                staticStyle: { width: "100%" },
                                attrs: { options: _vm.options },
                                on: { change: _vm.handleChange },
                                model: {
                                  value: _vm.cascaderAddr,
                                  callback: function ($$v) {
                                    _vm.cascaderAddr = $$v
                                  },
                                  expression: "cascaderAddr",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "联系地址：" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入联系地址",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.ownerAddress,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "ownerAddress", $$v)
                                  },
                                  expression: "form.ownerAddress",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c(
                          "p",
                          {
                            staticStyle: {
                              "text-align": "left",
                              "font-size": "14px",
                              "font-weight": "bold",
                            },
                          },
                          [_vm._v("上年投保信息")]
                        ),
                      ]),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "商业险保司名称:" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "215px" },
                                  attrs: {
                                    filterable: "",
                                    clearable: "",
                                    placeholder: "请选择保司类型",
                                  },
                                  model: {
                                    value: _vm.form.bsLastYearInsCorpName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "bsLastYearInsCorpName",
                                        $$v
                                      )
                                    },
                                    expression: "form.bsLastYearInsCorpName",
                                  },
                                },
                                _vm._l(
                                  _vm.insuranceList,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.shortName,
                                        value: item.shortName,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "商业险到期日：" } },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  type: "date",
                                  placeholder: "请输入商业险到期日",
                                },
                                model: {
                                  value: _vm.form.bsLastYearEndDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "bsLastYearEndDate", $$v)
                                  },
                                  expression: "form.bsLastYearEndDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "交强险保司名称:" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "215px" },
                                  attrs: {
                                    filterable: "",
                                    clearable: "",
                                    placeholder: "请选择保司类型",
                                  },
                                  model: {
                                    value: _vm.form.bzLastYearInsCorpName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "bzLastYearInsCorpName",
                                        $$v
                                      )
                                    },
                                    expression: "form.bzLastYearInsCorpName",
                                  },
                                },
                                _vm._l(
                                  _vm.insuranceList,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.shortName,
                                        value: item.shortName,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "交强险到期日：" } },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  type: "date",
                                  placeholder: "请输入交强险到期日",
                                },
                                model: {
                                  value: _vm.form.bzLastYearEndDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "bzLastYearEndDate", $$v)
                                  },
                                  expression: "form.bzLastYearEndDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "客户类别：" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "215px" },
                                  attrs: {
                                    filterable: "",
                                    clearable: "",
                                    placeholder: "请选择客户类别",
                                  },
                                  model: {
                                    value: _vm.form.renewalType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "renewalType", $$v)
                                    },
                                    expression: "form.renewalType",
                                  },
                                },
                                _vm._l(
                                  _vm.renewalTypeList,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.itemName,
                                        value: item.itemCode,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-check",
                type: "primary",
                plain: "",
                size: "mini",
              },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: { click: _vm.closePage },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }