var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-m-cls", staticStyle: {} }, [
    _c(
      "div",
      {
        ref: "header",
        staticClass: "header-cls",
        staticStyle: { "max-height": "500px", overflow: "auto" },
      },
      [
        _c(
          "el-card",
          { attrs: { shadow: "never" } },
          [
            _c(
              "el-table",
              {
                ref: "multipleTable",
                staticStyle: { width: "100%" },
                attrs: {
                  size: "mini",
                  border: "",
                  data: _vm.tableData,
                  height: 380,
                  "header-cell-style": { background: "#F7F7F7" },
                  "highlight-current-row": "",
                },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    prop: "",
                    label: "名称",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "",
                    label: "机器名称",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "",
                    label: "创建时间",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "",
                    label: "失效时间",
                    "show-overflow-tooltip": "",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticStyle: {
          display: "flex",
          "justify-content": "center",
          "margin-top": "8px",
        },
      },
      [
        _c(
          "el-button",
          {
            attrs: {
              icon: "el-icon-check",
              type: "primary",
              plain: "",
              size: "mini",
            },
            on: { click: _vm.save },
          },
          [_vm._v("保存")]
        ),
        _c(
          "el-button",
          {
            attrs: { icon: "el-icon-close", size: "mini" },
            on: { click: _vm.closePage },
          },
          [_vm._v("关闭")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }