var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.formLoading,
          expression: "formLoading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: {},
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "500px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "detail",
                  staticStyle: { "margin-bottom": "-20px" },
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-width": "115px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "渠道代码：",
                                prop: "netChannelCode",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "100",
                                  placeholder: "请输入渠道代码",
                                  clearable: "",
                                  disabled: _vm.opType !== "add",
                                },
                                on: { input: _vm.inputNetChannelCode },
                                model: {
                                  value: _vm.form.netChannelCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "netChannelCode", $$v)
                                  },
                                  expression: "form.netChannelCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "渠道名称：",
                                prop: "netChannelName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "100",
                                  placeholder: "请输入渠道名称",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.netChannelName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "netChannelName", $$v)
                                  },
                                  expression: "form.netChannelName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "是否启用：" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "form-item-cls",
                                  attrs: {
                                    filterable: "",
                                    placeholder: "请选择是否启用",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.form.enabled,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "enabled", $$v)
                                    },
                                    expression: "form.enabled",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "是", value: true },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "否", value: false },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "是否手动注册：" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "form-item-cls",
                                  attrs: {
                                    filterable: "",
                                    placeholder: "请选择是否手动注册",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.form.isManualRegister,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "isManualRegister",
                                        $$v
                                      )
                                    },
                                    expression: "form.isManualRegister",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "是", value: true },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "否", value: false },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-check",
                type: "primary",
                plain: "",
                size: "mini",
              },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: {
                click: function ($event) {
                  return _vm.closePage(true)
                },
              },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }