var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "app-m-cls customer-import-list-dialog",
      staticStyle: {},
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "table-cls normal-btn-cls",
          staticStyle: { overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _c("div", { staticClass: "search-box" }, [
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v("地区类型：")]),
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: "请选择地区类型",
                            clearable: "",
                          },
                          on: { change: _vm.districtTypeChange },
                          model: {
                            value: _vm.searchForm.districtType,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "districtType", $$v)
                            },
                            expression: "searchForm.districtType",
                          },
                        },
                        _vm._l(_vm.districtTypeList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.name, value: item.code },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.searchForm.districtType == "province"
                    ? _c(
                        "div",
                        [
                          _c(
                            "span",
                            { staticStyle: { "min-width": "100px" } },
                            [_vm._v("请选择省/市：")]
                          ),
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { placeholder: "请选择省", clearable: "" },
                              model: {
                                value: _vm.searchForm.districtCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.searchForm, "districtCode", $$v)
                                },
                                expression: "searchForm.districtCode",
                              },
                            },
                            _vm._l(_vm.proList, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.name, value: item.code },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        [
                          _c(
                            "span",
                            { staticStyle: { "min-width": "100px" } },
                            [_vm._v("请选择省/市：")]
                          ),
                          _c("el-cascader", {
                            ref: "cascaderAddr",
                            staticStyle: { width: "100%" },
                            attrs: { options: _vm.cityOptions },
                            on: { change: _vm.handleChange },
                            model: {
                              value: _vm.cascaderAddr,
                              callback: function ($$v) {
                                _vm.cascaderAddr = $$v
                              },
                              expression: "cascaderAddr",
                            },
                          }),
                        ],
                        1
                      ),
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { width: "72px", height: "32px" },
                          attrs: { size: "mini", icon: "el-icon-refresh-left" },
                          on: { click: _vm.resetSearch },
                        },
                        [_vm._v("重置")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticStyle: { width: "72px", height: "32px" },
                          attrs: {
                            size: "mini",
                            type: "primary",
                            icon: "el-icon-search",
                          },
                          on: { click: _vm.searchData },
                        },
                        [_vm._v("查询")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "search-btn" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: { click: _vm.breakData },
                        },
                        [_vm._v("刷新")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: {
                            click: function ($event) {
                              return _vm.addClicked("add")
                            },
                          },
                        },
                        [_vm._v("新增")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            size: "mini",
                            disabled: _vm._.isEmpty(_vm.tableData),
                          },
                          on: { click: _vm.exportExcels },
                        },
                        [_vm._v("导出")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _c(
                "el-table",
                {
                  ref: "multipleTable",
                  staticStyle: { width: "100%" },
                  attrs: {
                    size: "mini",
                    border: "",
                    data: _vm.tableData,
                    height: _vm.tableHeightJD,
                    "header-cell-style": { background: "#F7F7F7" },
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      label: "",
                      width: "50",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "districtName",
                      label: "地区名称",
                      "min-width": "300",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "districtTypeDisplay",
                      label: "地区类型",
                      width: "80",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "days",
                      label: "提前续保天数",
                      align: "right",
                      width: "100",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "createdTime",
                      label: "创建时间",
                      width: "150",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "modifiedTime",
                      label: "修改时间",
                      width: "150",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      width: "150",
                      fixed: "right",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.toEdit("edit", scope.row)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "danger",
                                  plain: "",
                                  size: "mini",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.deleteItem(scope.row)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("pagination", {
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.pageNum,
                  limit: _vm.listQuery.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageNum", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageSize", $event)
                  },
                  pagination: function ($event) {
                    return _vm.loadData()
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showAddDialog,
            "close-on-click-modal": false,
            width: "40%",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showAddDialog = $event
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v(_vm._s(_vm.dialogType == "add" ? "新增" : "编辑"))]
              ),
            ]
          ),
          _c("AddDialog", {
            attrs: {
              dialogItem: _vm.dialogItem,
              dialogType: _vm.dialogType,
              refresh: _vm.showAddDialog,
              currentId: _vm.currentId,
            },
            on: { closePage: _vm.loadData },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }