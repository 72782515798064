var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.formLoading,
          expression: "formLoading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: { "max-height": "550px" },
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "500px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "detail",
                  staticStyle: { "margin-bottom": "-20px" },
                  attrs: {
                    model: _vm.form,
                    "label-width": "115px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "消息类型：" } },
                            [
                              _c("el-input", {
                                attrs: { maxlength: "40", disabled: "" },
                                model: {
                                  value: _vm.form.messageTypeName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "messageTypeName", $$v)
                                  },
                                  expression: "form.messageTypeName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "消息模板编码：" } },
                            [
                              _c("el-input", {
                                attrs: { maxlength: "40", disabled: "" },
                                model: {
                                  value: _vm.form.messageTemplateCode,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "messageTemplateCode",
                                      $$v
                                    )
                                  },
                                  expression: "form.messageTemplateCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "消息级别：" } },
                            [
                              _c("el-input", {
                                attrs: { maxlength: "40", disabled: "" },
                                model: {
                                  value: _vm.form.messageLevelDisplay,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "messageLevelDisplay",
                                      $$v
                                    )
                                  },
                                  expression: "form.messageLevelDisplay",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "接收人用户名：" } },
                            [
                              _c("el-input", {
                                attrs: { maxlength: "40", disabled: "" },
                                model: {
                                  value: _vm.form.receiverUserName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "receiverUserName", $$v)
                                  },
                                  expression: "form.receiverUserName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "是否已读：" } },
                            [
                              _c("el-input", {
                                attrs: { maxlength: "40", disabled: "" },
                                model: {
                                  value: _vm.form.readedDisplay,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "readedDisplay", $$v)
                                  },
                                  expression: "form.readedDisplay",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "读取时间：" } },
                            [
                              _c("el-input", {
                                attrs: { maxlength: "40", disabled: "" },
                                model: {
                                  value: _vm.form.readTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "readTime", $$v)
                                  },
                                  expression: "form.readTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "发送时间：" } },
                            [
                              _c("el-input", {
                                attrs: { maxlength: "40", disabled: "" },
                                model: {
                                  value: _vm.form.createdTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "createdTime", $$v)
                                  },
                                  expression: "form.createdTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "消息内容：" } },
                            [
                              _c("pre", { staticClass: "errorStack" }, [
                                _vm._v(
                                  _vm._s(_vm.parseData(_vm.form.messageContent))
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: {
                click: function ($event) {
                  return _vm.closePage(true)
                },
              },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }