import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.json.stringify.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '../../mixins/initHeight';
import dict from '../../mixins/dict';
import Pagination from '@/components/Pagination';
import request from '../../api/insuranceslip';
import OemSelect from '@/components/OemSelect';
import { getCurrentDate, getBeforeDate, character, getUserOrgInfo, getSupplyers } from '@/utils/common';
export default {
  name: 'NetProposalManage',
  components: {
    Pagination: Pagination,
    OemSelect: OemSelect
  },
  mixins: [initHeight, dict],
  computed: {},
  data: function data() {
    return {
      drawer: false,
      searchForm: {
        plateNo: '',
        quoteNo: '',
        insCorpCode: null,
        insCityCode: '',
        issueOrgCode: '',
        salesOrgName: '',
        operateStartTime: getBeforeDate(6),
        operateEndTime: getCurrentDate(),
        proposalStatus: null,
        isNotPlate: false
      },
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      multipleSelection: [],
      opType: '',
      isNotPlate: false,
      startEndTime: [getBeforeDate(6), getCurrentDate()],
      loading: false,
      supplyers: [],
      cityList: JSON.parse(localStorage.getItem("VI_CityPcTree")) || [],
      orgForm: {
        salesOrgCode: '',
        salesOrgName: ''
      },
      bizType: localStorage.getItem("bizType"),
      dealerName: ""
    };
  },
  created: function created() {},
  mounted: function mounted() {
    //保司
    this.supplyers = getSupplyers();
    this.getDefaultValue();
    this.loadData();
  },
  methods: {
    getDefaultValue: function getDefaultValue() {
      if (this.bizType !== 'OEM') {
        var orgObj = getUserOrgInfo();
        this.searchForm.issueOrgCode = orgObj.orgCode;
        this.searchForm.salesOrgName = orgObj.bizType;
        this.orgForm = {
          salesOrgCode: orgObj.orgCode,
          salesOrgName: orgObj.fullName
        };
        this.dealerName = orgObj.fullName;
      }
    },
    breakData: function breakData() {
      this.loadData(false);
    },
    checkQuoteNo: function checkQuoteNo(val) {//自动将业务单号转为大写
      // this.searchForm.quoteNo = character(_.toUpper(val));
    },
    getStatusColor: function getStatusColor(proposalStatus) {
      //获取投保状态对应的颜色值
      var color = '';
      switch (proposalStatus) {
        case '03':
        case '08':
        case '12':
          color = '#a0a0a0';
          break;
        case '01':
        case '02':
        case '04':
        case '05':
        case '06':
        case '07':
        case '09':
        case '10':
        case '13':
          color = '#e4a547';
          break;
        case '11':
          color = '#16c86c';
          break;
        default:
          color = '#515151';
          break;
      }
      return color;
    },
    selectItems: function selectItems(row, index) {
      //根据状态判断是否可以选中
      if (row.status) {
        return 0; //不可勾选
      } else {
        return 1; //可勾选
      }
    },
    inputPlateNo: function inputPlateNo(val) {
      //自动将车牌号码转为大写
      if (!this.searchForm.isNotPlate) {
        this.searchForm.plateNo = _.toUpper(val);
      }
    },
    checkValue: function checkValue(val) {
      //去除车架号中的i、o、q
      if (val) {
        val = val.replace(/i/g, '').replace(/I/g, '').replace(/o/g, '').replace(/O/g, '').replace(/q/g, '').replace(/Q/g, '');
      }
      this.searchForm.vin = character(_.toUpper(val));
    },
    handleNodeClick: function handleNodeClick(data) {
      // 机构树点击事件
      this.searchForm.issueOrgCode = data.salesOrgCode;
      this.searchForm.salesOrgName = data.salesOrgName;
    },
    dateChange: function dateChange(start, end, val) {
      //日期选择事件
      if (val && val.length > 1) {
        this.searchForm[start] = val[0];
        this.searchForm[end] = val[1];
      } else {
        this.searchForm.operateStartTime = null;
        this.searchForm.operateEndTime = null;
      }
    },
    noPlateNoChange: function noPlateNoChange(val) {
      //未上牌勾选事件
      if (val) {
        this.searchForm.isNotPlate = true;
        this.searchForm.plateNo = '';
      } else {
        this.searchForm.isNotPlate = false;
      }
    },
    removeItems: function removeItems(item) {
      var _this = this;
      //删除
      var ids = [];
      if (!_.isEmpty(item)) {
        ids.push(item.quoteId);
      } else {
        if (!_.isEmpty(this.multipleSelection)) {
          ids = _.map(this.multipleSelection, 'quoteId');
        }
      }
      if (_.isEmpty(ids)) return;
      this.$confirm("\u662F\u5426\u786E\u8BA4\u5220\u9664".concat(!_.isEmpty(item) ? '该暂存单' : '所选中的暂存单', ", \u786E\u8BA4\u5220\u9664\u540E\u4E0D\u80FD\u6062\u590D\u3002"), '温馨提示', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        type: 'warning'
      }).then(function () {
        request.delete(ids).then(function (res) {
          if (res.code == RESPONSE_SUCCESS) {
            _this.$message({
              type: "success",
              message: res.msg
            });
            _this.loadData();
          }
        });
      });
    },
    viewDetail: function viewDetail(type, item) {
      // 查看投保单详情
      this.opType = type;
      // let router_path = this.$route.path;
      this.$router.push({
        path: "/proposal/detail",
        query: {
          quoteId: item.quoteId,
          // back: router_path,
          plateNo: item.plateNo,
          vin: item.vin
        }
      });
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {
        plateNo: '',
        insCorpCode: null,
        insCityCode: '',
        issueOrgCode: '',
        salesOrgName: '',
        operateStartTime: getBeforeDate(6),
        operateEndTime: getCurrentDate(),
        proposalStatus: null,
        isNotPlate: false
      };
      sessionStorage.setItem('searchProposalForm', JSON.stringify(this.searchForm));
      this.startEndTime = [getBeforeDate(6), getCurrentDate()];
      this.orgForm = {
        salesOrgCode: '',
        salesOrgName: ''
      };
      this.getDefaultValue();
    },
    searchData: function searchData() {
      //搜索
      this.loadData(true);
    },
    addItem: function addItem() {
      //列表新增
      sessionStorage.removeItem("quoateForm");
      var router_path = this.$route.path;
      this.$router.push({
        path: "/proposal/add",
        query: {
          back: router_path
        }
      });
    },
    dbSelected: function dbSelected(row) {
      //双击打开详情
      this.viewDetail('view', row);
    },
    loadData: function loadData(clickSearch) {
      //获取列表数据
      this.loading = true;
      this.drawer = false;
      if (_.isArray(this.searchForm.insCityCode)) {
        this.searchForm.insCityCode = this.searchForm.insCityCode[1];
      }
      if (this.searchForm && this.searchForm.operateStartTime) {
        this.searchForm.operateStartTime = this.searchForm.operateStartTime.split(' ')[0] + ' 00:00:00';
      }
      if (this.searchForm && this.searchForm.operateEndTime) {
        this.searchForm.operateEndTime = this.searchForm.operateEndTime.split(' ')[0] + ' 23:59:59';
      }
      this.listQuery.pageNum = clickSearch ? 1 : this.listQuery.pageNum;
      if (clickSearch) {
        sessionStorage.setItem('searchProposalForm', JSON.stringify(this.searchForm));
      }
      var self = this;
      this.searchForm.quoteType = 'NET_QUOTE';
      request.getList(this.listQuery.pageNum, this.listQuery.pageSize, this.searchForm).then(function (res) {
        self.loading = false;
        if (!_.isEmpty(res.data)) {
          self.tableData = res.data.list;
          //总条数
          self.total = res.data.page.recordsTotal;
          self.$nextTick(function () {
            setTimeout(function () {
              self.$refs.multipleTable.doLayout();
            }, 500);
          });
        }
      }).catch(function (err) {
        self.loading = false;
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      //列表行选择事件
      this.multipleSelection = val;
    }
  }
};