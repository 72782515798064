import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import servicePackageApi from "@/api/servicePackage";
import Rules from '@/utils/rules';
import { character } from "@/utils/common";
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    opType: {
      type: String,
      default: ''
    },
    currentId: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      form: {
        vin: '',
        servPackProCode: '',
        servPackProName: '',
        servPackProPrice: '',
        itemCode: '',
        itemName: '',
        itemAmount: '',
        itemRemark: ''
      },
      rules: {
        vin: [{
          required: true,
          pattern: Rules["VIN_RULE"].value,
          message: '请输入车架号',
          trigger: 'blur'
        }, {
          pattern: Rules["VIN_RULE"].value,
          message: "车架号格式有误",
          trigger: ["blur", "change"]
        }]
      },
      formLoading: false
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
      ;
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    inputvin: function inputvin(val) {
      //监听输入事件
      if (val) {
        val = val.replace(/i/g, '').replace(/I/g, '').replace(/o/g, '').replace(/O/g, '').replace(/q/g, '').replace(/Q/g, '');
        this.form.vin = character(_.toUpper(val));
        this.$forceUpdate();
      }
    },
    initData: function initData() {
      var _this = this;
      //初始化数据
      this.form = {
        vin: '',
        servPackProCode: '',
        servPackProName: '',
        servPackProPrice: '',
        itemCode: '',
        itemName: '',
        itemAmount: '',
        itemRemark: ''
      };
      if (this.opType === "edit") {
        servicePackageApi.queryTempServicePacks(this.currentId).then(function (res) {
          if (res.code === 200) {
            _this.form = res.data;
          }
          ;
        });
      }
      ;
      this.$refs.detail.resetFields();
    },
    // 保存数据
    save: function save() {
      var _this2 = this;
      this.$refs['detail'].validate(function (valid, obj) {
        if (valid) {
          _this2.formLoading = true;
          var req = _this2.opType === 'add' ? servicePackageApi.addTempServicePacks(_this2.form) : servicePackageApi.editTempServicePacks(_this2.form);
          req.then(function (res) {
            _this2.formLoading = false;
            if (res.code === 200) {
              _this2.$message.success(res.msg);
              _this2.closePage(false);
            }
            ;
          }).catch(function (err) {
            _this2.formLoading = false;
          });
        }
      });
    },
    closePage: function closePage() {
      var boolean = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      //关闭弹窗
      this.$emit('closePage', boolean);
    }
  }
};