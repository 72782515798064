import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
export var neiMonggol = function neiMonggol(addressDetail, cityAreaTree) {
  var addressInfo = _.cloneDeep(addressDetail);
  var fullAddress = [];
  var regionCode = [];
  var excludeMunicipalityStr = null;
  if (addressInfo.indexOf('内蒙古自治区') !== -1) {
    excludeMunicipalityStr = addressInfo.split('内蒙古自治区')[1];
  } else {
    excludeMunicipalityStr = addressInfo.split('内蒙古')[1];
  }
  // console.log('除自治区以外字符串', excludeMunicipalityStr)
  // 获取省下面的城市
  var pItem = _.find(cityAreaTree, {
    label: '内蒙古自治区'
  });
  fullAddress.push(pItem.value);
  // 土默特左旗单独处理
  if (pItem && excludeMunicipalityStr.indexOf('土默特左旗') !== -1) {
    var cItem = _.find(pItem.children, {
      label: '呼和浩特市'
    });
    fullAddress.push(cItem && cItem.value);
    var districtCountyItem = _.find(cItem.children, {
      label: '土默特左旗'
    });
    fullAddress.push(districtCountyItem.value);
    regionCode = districtCountyItem.value;
  }
  // 判断城市的后缀名：市，盟
  if (pItem && excludeMunicipalityStr.indexOf('市') !== -1) {
    var cityName = excludeMunicipalityStr.split('市')[0];
    // console.log('结尾为市的城市cityName', cityName)
    // 霍林郭勒单独处理
    if (cityName == '霍林郭勒') {
      var _cItem = _.find(pItem.children, {
        label: '通辽市'
      });
      fullAddress.push(_cItem && _cItem.value);
      var _districtCountyItem = _.find(_cItem.children, {
        label: cityName + '市'
      });
      fullAddress.push(_districtCountyItem.value);
      regionCode = _districtCountyItem.value;
    } else {
      var _cItem2 = _.find(pItem.children, {
        label: cityName + '市'
      });
      fullAddress.push(_cItem2 && _cItem2.value);
      var countyLevelCityName = null;
      if (excludeMunicipalityStr.split('市') && excludeMunicipalityStr.split('市').length > 2) {
        countyLevelCityName = excludeMunicipalityStr.split('市')[1] + '市';
      } else {
        countyLevelCityName = excludeMunicipalityStr.split('市')[1];
      }
      // console.log('cItem', cItem)
      // console.log('市区后面字符串', countyLevelCityName)
      var countyNameList = ['市', '区', '右旗', '左旗', '前旗', '中旗', '后旗', '旗', '县'];
      var districtCountylist = [];
      for (var _i = 0, _countyNameList = countyNameList; _i < _countyNameList.length; _i++) {
        var countyName = _countyNameList[_i];
        if (_cItem2 && countyLevelCityName.indexOf(countyName) !== -1) {
          var districtCountyName = countyLevelCityName.split(countyName)[0];
          var _districtCountyItem2 = _.find(_cItem2.children, {
            label: districtCountyName + countyName
          });
          // console.log('districtCountyItem', districtCountyItem)
          // 存在 '中旗'，'后旗','旗' 三个结尾的重复的，取第一个就行
          if (_districtCountyItem2) {
            districtCountylist.push(_districtCountyItem2);
          }
        }
      }
      if (!_.isEmpty(districtCountylist)) {
        fullAddress.push(districtCountylist[0].value);
        regionCode = districtCountylist[0].value;
      }
    }
  }
  // 以盟结尾的市级
  if (pItem && excludeMunicipalityStr.indexOf('盟') !== -1) {
    var _cityName = excludeMunicipalityStr.split('盟')[0];
    // console.log('结尾为盟的城市cityName', cityName)
    var _cItem3 = _.find(pItem.children, {
      label: _cityName + '盟'
    });
    // console.log('cItem', cItem)
    fullAddress.push(_cItem3 && _cItem3.value);
    // console.log('盟后面字符串', excludeMunicipalityStr.split('盟')[1])
    var _countyNameList2 = ['区', '右旗', '左旗', '前旗', '中旗', '后旗', '旗', '县', '市'];
    var allianceCountylist = [];
    for (var _i2 = 0, _countyNameList3 = _countyNameList2; _i2 < _countyNameList3.length; _i2++) {
      var _countyName = _countyNameList3[_i2];
      if (_cItem3 && excludeMunicipalityStr.split('盟')[1].indexOf(_countyName) !== -1) {
        var allianceCountyName = excludeMunicipalityStr.split('盟')[1].split(_countyName)[0];
        // console.log('allianceCountyName', allianceCountyName)
        var allianceCountyItem = _.find(_cItem3.children, {
          label: allianceCountyName + _countyName
        });
        // console.log('allianceCountyItem', allianceCountyItem)
        // 存在 '中旗'，'后旗','旗' 三个结尾的重复的，取第一个就行
        if (allianceCountyItem) {
          allianceCountylist.push(allianceCountyItem);
        }
      }
    }
    if (!_.isEmpty(allianceCountylist)) {
      fullAddress.push(allianceCountylist[0].value);
      regionCode = allianceCountylist[0].value;
    }
  }
  return {
    fullAddress: fullAddress,
    regionCode: regionCode
  };
};