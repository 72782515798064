import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import NonAutoRequest from '@/api/nonAutoRequest';
import Rules from '@/utils/rules';
import { character } from '@/utils/common';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    currentId: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      form: {
        vin: ''
      },
      rules: {
        vin: [{
          required: true,
          message: '请输入车架号',
          trigger: "blur"
        }, {
          pattern: Rules["VIN_RULE"].value,
          message: "车架号格式有误",
          trigger: ["blur", "change"]
        }]
      },
      formLoading: false,
      tableData: [],
      currentObj: {}
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
      ;
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    initData: function initData() {
      //初始化数据
      this.form = {
        vin: ''
      };
      this.tableData = [];
      this.$refs.detail.resetFields();
    },
    closePage: function closePage() {
      //关闭弹窗
      this.$emit('closePage');
    },
    checkValue: function checkValue(val) {
      //输入的车架号中的字母自动转大写，并去除i，o，q
      if (val) {
        val = val.replace(/i/g, '').replace(/I/g, '').replace(/o/g, '').replace(/O/g, '').replace(/q/g, '').replace(/Q/g, '');
      }
      ;
      this.form.vin = character(_.toUpper(val));
    },
    vincheck: function vincheck() {
      var vinNumber = /^\d{17}$/;
      var vinLetter = /^[A-Z]{17}$/;
      if (vinNumber.test(this.form.vin) || vinLetter.test(this.form.vin)) {
        this.$message({
          type: 'error',
          message: '车架号不能纯数字或纯字母，请修改！'
        });
        this.$forceUpdate();
        return false;
      }
      ;
      return true;
    },
    // 检索
    queryCustomerInfo: function queryCustomerInfo() {
      var _this = this;
      if (!this.vincheck()) return;
      this.$refs.detail.validate(function (valid) {
        if (valid) {
          _this.formLoading = true;
          NonAutoRequest.getPolicySelectByVin(_this.form.vin).then(function (res) {
            _this.formLoading = false;
            if (res.code === 200 && res.data.length) {
              _this.tableData = res.data;
              _this.$message.success(res.msg);
            } else {
              _this.$message.warning("未查到该车辆的投保信息。");
              _this.tableData = [];
            }
            ;
          }).catch(function (err) {
            _this.formLoading = false;
          });
        }
        ;
      });
    },
    // 注销申请
    chargebackApply: function chargebackApply(item) {
      // console.log(item)
      this.$emit('CloseDialog');
      this.$router.push({
        name: "claimsApply",
        query: {
          policyId: item.policyId,
          tabName: item.productClassName + '理赔申请'
        }
      });
    }
  }
};