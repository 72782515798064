/**
 * 机构管理
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 获取机构列表(新接口)
   * @param {*} id 
   */
  getOrgList: function getOrgList() {
    return request.get(getUrl("/dealer/info/getDealerList", 12));
  },
  /**
   * 获取机构人员(新接口)
   * @param {*} id 
   */
  getOrgPerson: function getOrgPerson(code) {
    return request.get(getUrl("/dealer/employee/getOrgCodeList/list?dealerCode=".concat(code), 12));
  },
  /**
   * 获取机构人员(新接口)--报价页面专用
   * @param {*} id 
   */
  getOrgPersonForQuote: function getOrgPersonForQuote(data) {
    return request.get(getUrl("/dealer/employee/getOrgCodeList/list", 12), {
      params: data
    });
  },
  /**
   * 机构树
   * @param {*} id 
   */
  getTree: function getTree(id) {
    return request.get(getUrl("/organization/tree", 4));
  },
  /**
   * 查询
   * @param {*} id 
   */
  query: function query(id) {
    return request.get(getUrl("/organization/getByCode?orgCode=".concat(id), 4));
  },
  /**
   * 根据父id查询下级机构
   * @param {*} id 
   */
  queryList: function queryList(id) {
    return request.get(getUrl("/organization/getSubOrgById?organizationId=".concat(id), 4));
  },
  /**
   * 新增
   * @param {*} data 内容
   */
  add: function add(data) {
    return request.post(getUrl("/organization", 4), data);
  },
  /**
   * 修改
   * @param {*} id 查询参数
   * @param {*} data 内容
   */
  update: function update(id, data) {
    return request.put(getUrl("/organization/".concat(id), 4), data);
  },
  /**
   * 删除
   * @param {*} id
   */
  delete: function _delete(id) {
    return request.delete(getUrl("/organization/".concat(id), 4));
  },
  /**
   * 查询（经销商）
   * @param {*} code 编码 
   */
  dealerQuery: function dealerQuery(code) {
    return request.get(getUrl("/dealer/info/getByCode?dealerCode=".concat(code), 12));
  },
  /**
   * 根据主机厂编码获取经销商树形结构
   * @param {*} code 编码
   */
  getOemTree: function getOemTree(code) {
    return request.get(getUrl("/area/oem/".concat(code, "/tree"), 13));
  },
  /**
   * 根据主机厂或区域查询经销商列表
   * @param {*} data
   */
  getOemAndAreaUserList: function getOemAndAreaUserList(data) {
    return request.post(getUrl("/oem/dealer/dealer/employee/list", 13), data);
  },
  /**
   * 编辑经销商信息
   * @param {*} id 查询参数
   * @param {*} data 内容
   */
  dealerEdit: function dealerEdit(id, data) {
    return request.put(getUrl("/dealer/info/update?dealerId=".concat(id), 12), data);
  },
  /**
   * 根据主机厂编码获取经销商树形结构(过滤二网)
   * @param {*} code 编码
   * @param {*} data
   */
  getSecondNetTree: function getSecondNetTree(code, data) {
    return request.get(getUrl("/area/oem/".concat(code, "/tree"), 13), {
      params: data
    });
  }
};
export default objApi;