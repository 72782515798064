//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '../../mixins/initHeight';
import dict from '../../mixins/dict';
import Pagination from '@/components/Pagination';
import AddInvoiceDialog from './dialog/addInvoiceDialog';
import OemSelect from '@/components/OemSelect';
export default {
  name: "Invoice",
  components: {
    Pagination: Pagination,
    AddInvoiceDialog: AddInvoiceDialog,
    OemSelect: OemSelect
  },
  mixins: [initHeight, dict],
  computed: {},
  data: function data() {
    return {
      activeName: "all",
      drawer: false,
      searchForm: {},
      form: {},
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      defaultProps: {
        children: 'children',
        label: 'fullName'
      },
      value1: "",
      tableData: [],
      multipleSelection: [],
      opType: '',
      startEndTime: null,
      loading: false,
      orgData: [],
      highlightCurrent: true,
      value2: '',
      showAddInvoiceDialog: false
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.loadData();
  },
  methods: {
    addInvoice: function addInvoice() {
      //开票
      this.showAddInvoiceDialog = true;
    },
    handleTabClick: function handleTabClick(tab, event) {
      //tab切换事件
      this.$forceUpdate();
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {};
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    loadData: function loadData() {
      //获取列表数据
      // this.loading = true;
      this.drawer = false;
      this.showAddInvoiceDialog = false;
    },
    handleSelectionChange: function handleSelectionChange(val) {
      //列表行选择事件
      this.multipleSelection = val;
    },
    // 刷新
    breakData: function breakData() {
      // this.resetSearch();
      this.loadData();
    }
  }
};