import _createForOfIteratorHelper from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import _defineProperty from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/es.set.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.join.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import dict from '@/mixins/dict';
import Dialog from '@/components/Dialog';
import Pagination from '@/components/Pagination';
import employeeRequest from '@/api/employee';
import rolesRequest from '@/api/roles';
import requestOrg from '@/api/orgChildren';
import Rules from '@/utils/rules';
import request from '@/api/dictionary';
import dealerRegional from '@/api/dealerRegional';
import OemSelect from '@/components/OemSelect';
import storageRequest from "@/api/storage";
import userRequest from "@/api/user";
import common from "@/api/common";
var delay = function () {
  var _this = this;
  var timeout;
  return function (callback, ms) {
    if (timeout) clearTimeout(timeout);
    var callNow = !timeout;
    timeout = setTimeout(function () {
      timeout = undefined;
    }, ms);
    if (callNow) callback.apply(_this, [callback, ms]);
  };
}();
import { downFile } from "@/utils/common";
export default {
  name: 'Employee',
  components: {
    Pagination: Pagination,
    Dialog: Dialog,
    OemSelect: OemSelect
  },
  mixins: [initHeight, dict],
  computed: {},
  filters: {
    attachmentTypeDisplayFilters: function attachmentTypeDisplayFilters(val) {
      var attachmentTypeDisplayString = null;
      switch (val && val) {
        case 'HT':
          attachmentTypeDisplayString = '合同';
          break;
        case 'ZYZ':
          attachmentTypeDisplayString = '执业证';
          break;
        case 'YHK':
          attachmentTypeDisplayString = '银行卡';
          break;
        case 'SFZ':
          attachmentTypeDisplayString = '身份证';
          break;
        case 'QT':
          attachmentTypeDisplayString = '其他';
          break;
        default:
          attachmentTypeDisplayString = '合同';
      }
      return attachmentTypeDisplayString;
    }
  },
  data: function data() {
    var _ref;
    var validatePassword = function validatePassword(rule, value, callback) {
      var passwordreg = /^(((?=.*[0-9])(?=.*[a-zA-Z])|(?=.*[0-9])(?=.*[^\s0-9a-zA-Z])|(?=.*[a-zA-Z])(?=.*[^\s0-9a-zA-Z]))[^\s]+)$/;
      if (value.length < 8) {
        callback(new Error('请输入8-16位'));
      } else if (!passwordreg.test(value)) {
        callback(new Error('密码必须包含数字、字母、特殊字符中至少两种'));
      } else {
        callback();
      }
    };
    return _ref = {
      EMPLOYEE_STATES: [],
      cityList: [],
      hidden: true,
      activeName: 'HT',
      tabType: 'HT',
      drawer: false,
      searchForm: {
        orgCode: '',
        areaType: ''
      },
      form: {
        employeeType: 'OFFICE_WORK',
        salesman: {
          idNo: ''
        },
        fullName: "",
        sex: "MALE",
        employeeStatus: "REGULAR",
        roleIds: [],
        areaId: []
      },
      bizType: localStorage.getItem('bizType') || '',
      total: 0,
      formLoading: false,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      defaultProps: {
        children: 'children',
        label: 'fullName'
      },
      tableData: [],
      multipleSelection: [],
      showEmployeeDialog: false,
      opType: 'add',
      loading: false
    }, _defineProperty(_ref, "formLoading", false), _defineProperty(_ref, "highlightCurrent", true), _defineProperty(_ref, "orgData", []), _defineProperty(_ref, "employeeItem", {}), _defineProperty(_ref, "props", {
      multiple: true,
      emitPath: false,
      value: 'id',
      label: 'name',
      children: 'child'
    }), _defineProperty(_ref, "formRules", {
      fullName: [{
        required: true,
        message: '请选择所属机构',
        trigger: ["change"]
      }],
      account: [{
        required: true,
        message: '请输入登录账号',
        trigger: 'blur'
      }, {
        min: 4,
        max: 32,
        message: '登录账号长度需要在4和32之间',
        trigger: 'blur'
      }],
      jobNo: [{
        required: false,
        message: '请输入工号',
        trigger: 'blur'
      }],
      userName: [{
        required: true,
        message: '请输入姓名',
        trigger: 'blur'
      }],
      sex: [{
        required: true,
        message: '请选择性别',
        trigger: 'blur'
      }],
      phone: [{
        required: true,
        message: '请输入联系电话',
        trigger: 'blur'
      }, {
        pattern: Rules['MOBILE_RULE'].value,
        message: '手机号格式有误',
        trigger: ['blur', 'change']
      }],
      employeeStatus: [{
        required: true,
        message: '请选择员工状态',
        trigger: 'change'
      }],
      password: [{
        required: true,
        trigger: 'blur',
        validator: validatePassword
      }],
      employeeType: [{
        required: true,
        message: '请选择员工类型',
        trigger: 'change'
      }],
      salesman: {
        idTypeCode: [{
          required: true,
          message: '请输入证件类型',
          trigger: ["change"]
        }],
        idNo: [{
          required: false,
          message: '请输入证件号',
          trigger: 'blur'
        }, {
          pattern: Rules['ID_CARD'].value,
          message: '证件号格式有误',
          trigger: ['blur', 'change']
        }],
        hiredate: [{
          required: false,
          message: '请输入入职日期',
          trigger: 'blur'
        }],
        nationality: [{
          required: false,
          message: '请输入国籍',
          trigger: 'blur'
        }],
        birthday: [{
          required: false,
          message: '请选择生日',
          trigger: 'blur'
        }],
        address: [{
          required: false,
          message: '请输入地址',
          trigger: 'blur'
        }],
        education: [{
          required: false,
          message: '请输入学历',
          trigger: 'blur'
        }],
        maritalStatus: [{
          required: false,
          message: '请输入婚姻状态',
          trigger: 'blur'
        }],
        contactName: [{
          required: false,
          message: '请输入紧急联系人',
          trigger: 'blur'
        }],
        contactPhone: [{
          required: false,
          message: '请输入紧急联系人',
          trigger: 'blur'
        }, {
          pattern: Rules['MOBILE_RULE'].value,
          message: '手机号格式有误',
          trigger: ['blur', 'change']
        }],
        email: [{
          required: false,
          message: '请输入邮箱',
          trigger: 'blur'
        }, {
          pattern: Rules['EMAIL'].value,
          message: '邮箱格式有误',
          trigger: ['blur', 'change']
        }],
        bankCardNo: [{
          required: false,
          message: '请输入银行卡号',
          trigger: 'blur'
        }],
        bankName: [{
          required: false,
          message: '请输入开户银行',
          trigger: 'blur'
        }],
        bankSubBranch: [{
          required: false,
          message: '请输入开户支行',
          trigger: 'blur'
        }]
      },
      roleIds: [{
        required: true,
        message: '请选择系统角色',
        trigger: "[change]"
      }],
      areaId: [{
        required: false,
        message: '请选择关联区域',
        trigger: "[change]"
      }]
    }), _defineProperty(_ref, "roles", []), _defineProperty(_ref, "fileDate", []), _defineProperty(_ref, "fileList", []), _defineProperty(_ref, "viewDisabled", false), _defineProperty(_ref, "contract", []), _defineProperty(_ref, "contractType", 'CONTRACT'), _defineProperty(_ref, "VI_CERTIFICATE_TYPE", []), _defineProperty(_ref, "userInfo", JSON.parse(localStorage.getItem("userInfo")) || {}), _defineProperty(_ref, "orgName", 'OEM'), _defineProperty(_ref, "orgForm", {
      salesOrgCode: '',
      salesOrgName: '',
      salesOrgType: ''
    }), _defineProperty(_ref, "setWidth", '350'), _defineProperty(_ref, "allFileList", []), _defineProperty(_ref, "editNewObjList", []), _defineProperty(_ref, "adminRoles", []), _defineProperty(_ref, "dealerRoles", []), _ref;
  },
  watch: {
    showEmployeeDialog: function showEmployeeDialog(newValue) {
      if (newValue) {
        if (this.$refs['detail']) this.$refs['detail'].resetFields();
        if (this.opType == 'add') {
          this.form = {
            employeeType: 'OFFICE_WORK',
            sex: "MALE",
            employeeStatus: "REGULAR",
            salesman: {
              idNo: ''
            },
            fullName: "",
            roleIds: [],
            areaId: []
          };
        }
      }
    }
  },
  created: function created() {},
  mounted: function mounted() {
    var _this2 = this;
    if (!this.bizType) {
      var _userInfo = JSON.parse(localStorage.getItem('userInfo')) || null;
      if (_userInfo) {
        this.bizType = _userInfo.user && _userInfo.user.bizType;
        if (this.bizType) localStorage.setItem('bizType', this.bizType);
      }
    }
    request.getDictItemsByCode(this.contractType).then(function (res) {
      if (res.code === 200) {
        _this2.contract = res.data;
      }
    });
    if (this.bizType == "OEM") {
      this.getBigCityList();
    }
    ;
    this.loadData();
    this.getOrgTree();
    this.VI_CERTIFICATE_TYPE = JSON.parse(localStorage.getItem('VI_CERTIFICATE_TYPE')) || [];
    this.$forceUpdate();
  },
  methods: {
    // 下载 使用id下载文件
    handleDownload: function handleDownload(row) {
      if (row.attachmentIds) {
        downFile(row.fileUrl, row.fileName);
      }
    },
    nodeClick: function nodeClick(data) {
      this.searchForm.orgCode = data.salesOrgCode;
      this.searchForm.areaType = data.salesOrgType;
    },
    orgTabClick: function orgTabClick(tab, event) {
      this.searchForm = {
        orgCode: '',
        areaType: ''
      };
      this.listQuery = {
        pageNum: 1,
        pageSize: 20
      };
      this.orgForm = {
        salesOrgCode: '',
        salesOrgName: '全部',
        salesOrgType: ''
      };
      this.orgName = tab.name;
      this.setWidth = this.orgName === 'OEM' ? '350' : '230';
      this.loadData();
    },
    // 省市选择事件
    cityHandleChange: function cityHandleChange(val) {
      if (!val) return;
      this.$forceUpdate();
    },
    // 获取大区列表
    getBigCityList: function getBigCityList() {
      var _this3 = this;
      var oemCode = this.userInfo.data.orgCode;
      dealerRegional.getList(oemCode).then(function (res) {
        if (res.code === 200) {
          _this3.cityList = res.data;
        }
      }).catch(function (err) {});
    },
    breakData: function breakData() {
      this.loadData();
    },
    // tab切换事件
    handleTabClick: function handleTabClick(tab, event) {
      this.searchForm = {};
      this.searchForm.tabType = tab.name;
      this.tabType = tab.name;
      var list = _.filter(this.allFileList, function (o) {
        return o.attachmentTypeDisplay == tab.name;
      });
      this.fileList = list;
    },
    // 重置密码
    resetPassword: function resetPassword(item) {
      var _this4 = this;
      this.$confirm('您确定要将该用户的密码重置吗?', {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        var orgRequest = _this4.bizType === "DEALER" || _this4.orgName == 'DEALER' ? employeeRequest.dealerResetPW(item.employeeId) : _this4.bizType === 'OEM' || _this4.orgName == 'OEM' ? employeeRequest.oemResetPW(item.employeeId) : _this4.bizType === "BROKER" ? employeeRequest.brokerResetPW(item.employeeId) : '';
        orgRequest.then(function (res) {
          if (res.code === 200) {
            _this4.$message.success(res.msg);
            _this4.$confirm("\u8BE5\u7528\u6237\u7684\u5BC6\u7801\u4E3A ".concat(res.data), {
              showCancelButton: false,
              confirmButtonText: "确定",
              type: 'success'
            }).then(function () {
              _this4.loadData();
            }).catch();
          }
        });
      });
    },
    // 匿名登录
    login: function login(item) {
      var _this5 = this;
      this.$confirm('您确定要以此用户身份登录吗?', {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        var tokenData = null;
        userRequest.loginVest(item.account).then(function (res) {
          if (res.code === 200 && res.data) {
            tokenData = res.data.accessToken;
            sessionStorage.clear();
            sessionStorage.setItem('template_token', tokenData);
            if (tokenData) {
              if (_this5.orgName) localStorage.setItem('bizType', _this5.orgName);
              localStorage.setItem("token", tokenData);
              common.getUserRoleMenus().then(function (res) {
                if (res.data) {
                  localStorage.removeItem('_routes');
                  localStorage.setItem("roleData", JSON.stringify(res.data));
                }
              });
              var userInfo = null;
              if (_this5.bizType === 'BROKER') {
                // 经纪端
                userInfo = common.getUserInfo();
              } else if (_this5.bizType === 'OEM') {
                // 主机厂
                userInfo = common.getOemUserInfo();
              } else if (_this5.bizType === 'DEALER') {
                // 经销商
                userInfo = common.getDealerUserInfo();
              }
              userInfo.then(function (res) {
                if (res.data) {
                  localStorage.setItem("userInfo", JSON.stringify(res.data));
                  _this5.$router.push({
                    path: "/workbench"
                  });
                  window.location.reload();
                }
              });
            }
          }
        });
      });
    },
    // 关闭
    close: function close() {
      this.fileList = [];
      this.fileTypeList = [];
      this.editNewObjList = [];
      this.viewDisabled = false;
      this.showEmployeeDialog = false;
    },
    // 保存
    save: function save() {
      var _this6 = this;
      delay(function () {
        _this6.$refs["detail"].validate(function (valid) {
          if (valid) {
            var _orgInfo = JSON.parse(localStorage.getItem('userInfo'));
            _this6.form.dealerCode = _orgInfo.orgCode;
            _this6.form.salesman.orgCode = _orgInfo.orgCode;
            _this6.form.bizType = _this6.bizType;
            _this6.form.tenantId = '100001';
            if (_this6.form.hiredate) {
              _this6.form.hiredate = _this6.form.hiredate.split(" ")[0] + " 00:00:00";
            }
            if (_this6.form.salesman && _this6.form.salesman.idTypeCode) {
              var _temp = _.find(_this6.VI_CERTIFICATE_TYPE, function (item) {
                return item.dictCode == _this6.form.salesman.idTypeCode;
              });
              if (_temp) _this6.form.salesman.idTypeName = _temp.dictName;
            }
            _this6.formLoading = true;
            if (_this6.bizType == 'OEM') {
              _this6.form.areaType = 'CITY';
            }
            if (_this6.form.salesman && _this6.fileTypeList) {
              _this6.form.salesman.fileTypeList = _this6.fileTypeList;
            }
            _this6.form.roleId = _this6.form.roleIds;
            if (_this6.opType === "edit") {
              var orgRequest = _this6.bizType === "DEALER" ? employeeRequest.dealerUpdate(_this6.employeeItem.employeeId, _this6.form) : _this6.bizType === 'OEM' ? employeeRequest.oemUpdate(_this6.employeeItem.employeeId, _this6.form) : _this6.bizType === "BROKER" ? employeeRequest.brokerUpdate(_this6.employeeItem.employeeId, _this6.form) : '';
              orgRequest.then(function (res) {
                _this6.formLoading = false;
                if (res.code == 200) {
                  _this6.showEmployeeDialog = false;
                  _this6.loadData();
                  _this6.fileList = [];
                  _this6.fileTypeList = [];
                }
              }).catch(function (err) {
                _this6.formLoading = false;
              });
            }
            if (_this6.opType === "add") {
              var _orgRequest = _this6.bizType === "DEALER" ? employeeRequest.dealerAdd(_this6.form) : _this6.bizType === 'OEM' ? employeeRequest.oemAdd(_this6.form) : _this6.bizType === "BROKER" ? employeeRequest.brokerAdd(_this6.form) : '';
              _orgRequest.then(function (res) {
                _this6.formLoading = false;
                if (res.code == 200) {
                  _this6.showEmployeeDialog = false;
                  _this6.loadData();
                }
              }).catch(function (err) {
                _this6.formLoading = false;
              });
            }
          }
        });
      }, 1000);
    },
    getOrgTree: function getOrgTree() {
      var _this7 = this;
      //获取机构数
      var parentId = 0; // 0为管理员
      requestOrg.getTree(parentId).then(function (res) {
        if (res.data) {
          _this7.orgData = [res.data];
        }
      });
    },
    // 机构树查询
    filterNode: function filterNode(value, data) {
      if (!value) return true;
      return data.fullName.indexOf(value) !== -1;
    },
    // 机构树查询
    dataFilter: function dataFilter(val) {
      this.$refs.tree.filter(val);
    },
    // 节点点击事件
    handleNodeClick: function handleNodeClick(data, node, target) {
      this.form.fullName = data.fullName;
      this.form.orgCode = data.orgCode;
      this.$refs.orgSelect.blur();
      this.$forceUpdate();
    },
    // 机构树查询
    filterNodeS: function filterNodeS(value, data) {
      if (!value) return true;
      return data.fullName.indexOf(value) !== -1;
    },
    // 机构树查询
    dataFilterS: function dataFilterS(val) {
      this.$refs.trees.filter(val);
    },
    // 节点点击事件
    handleNodeClickS: function handleNodeClickS(data, node, target) {
      this.searchForm.fullName = data.fullName;
      this.searchForm.orgCode = data.orgCode;
      this.$refs.orgSelect1.blur();
      this.$forceUpdate();
    },
    // 新增
    add: function add(type) {
      this.fileList = [];
      this.fileTypeList = [];
      this.viewDisabled = false;
      this.opType = type;
      this.showEmployeeDialog = true;
      this.EMPLOYEE_STATES = _.filter(this.dictSource['EMPLOYEESTATE'], function (o) {
        return o.dictCode == 'REGULAR';
      });
      if (this.bizType === 'BROKER') {
        this.formRules.fullName = [{
          required: true,
          message: '请选择所属机构',
          trigger: ["change"]
        }];
      }
    },
    // 删除人员
    deleteEmployee: function deleteEmployee(item) {
      var _this8 = this;
      this.$confirm('您确定要将该人员删除？', '温馨提示', {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        var orgRequest = _this8.bizType === "DEALER" ? employeeRequest.dealerDelete(item.employeeId) : _this8.bizType === 'OEM' ? employeeRequest.oemDelete(item.employeeId) : _this8.bizType === "BROKER" ? employeeRequest.brokerDelete(item.employeeId) : '';
        orgRequest.then(function (res) {
          if (res.code === 200) {
            _this8.$message({
              type: "success",
              message: res.msg
            });
            _this8.loadData();
          }
        });
      });
    },
    // 编辑内勤
    viewDetail: function viewDetail(type, item) {
      var _this9 = this;
      this.fileList = [];
      this.fileTypeList = [];
      this.editNewObjList = [];
      this.opType = type;
      this.showEmployeeDialog = true;
      this.employeeItem = item;
      this.viewDisabled = type == 'view' ? true : false;
      this.EMPLOYEE_STATES = this.dictSource['EMPLOYEESTATE'];
      if (this.opType !== "add") {
        if (this.bizType === 'BROKER') {
          this.formRules.fullName = {
            required: false
          };
        }
        var orgRequest = this.bizType === "DEALER" || this.orgName == 'DEALER' ? employeeRequest.dealerVetail(item.employeeId) : this.bizType === 'OEM' && this.orgName == 'OEM' ? employeeRequest.oemVetail(item.employeeId) : this.bizType === "BROKER" ? employeeRequest.brokerVetail(item.employeeId) : '';
        orgRequest.then(function (res) {
          if ((type == 'edit' || type == 'view') && res.data.salesman && res.data.salesman.fileTypes && res.data.salesman.fileTypes.length != 0) {
            _this9.activeName = 'HT';
            var fileTypeslist = res.data.salesman.fileTypes;
            var objlist = [];
            var _iterator = _createForOfIteratorHelper(fileTypeslist),
              _step;
            try {
              for (_iterator.s(); !(_step = _iterator.n()).done;) {
                var i = _step.value;
                objlist.push({
                  attachmentIds: i.attachmentIds,
                  fileName: i.fileName,
                  storageType: i.storageType,
                  type: "上传成功"
                });
              }
            } catch (err) {
              _iterator.e(err);
            } finally {
              _iterator.f();
            }
            var _loop = function _loop() {
              var i = _objlist[_i];
              if (i.fileName && i.fileName.length != 0) {
                i.fileName.map(function (item, index) {
                  _this9.editNewObjList.push({
                    attachmentIds: i.attachmentIds[index],
                    name: item,
                    attachmentTypeDisplay: i.storageType,
                    type: "上传成功"
                  });
                });
              }
            };
            for (var _i = 0, _objlist = objlist; _i < _objlist.length; _i++) {
              _loop();
            }
            _this9.fileList = _this9.editNewObjList;
            _this9.allFileList = _this9.editNewObjList;
            var getfists = _this9.getfist(_this9.allFileList);
            _this9.fileTypeList = getfists;
            var list = _.filter(_this9.allFileList, function (o) {
              return o.attachmentTypeDisplay == 'HT';
            });
            _this9.fileList = list;
          }
          _this9.form = res.data;
          _this9.form.roleNames = _this9.getRoleNames(res.data.roleVOS);
          if (res.data.areaId) {
            _this9.form.areaId = res.data.areaId;
            _this9.$forceUpdate();
          }
          if (res.data.roleIds) {
            _this9.form.roleIds = res.data.roleIds;
            _this9.$forceUpdate();
          } else {
            _this9.$set(_this9.form, roleIds, []);
          }
          if (!_.isEmpty(res.data.salesman)) {
            _this9.salesman = res.data.salesman;
          } else {
            _this9.form.salesman = {
              idNo: ''
            };
          }
          if (!res.data.roleIds) {
            _this9.form.roleIds = [];
          }
          // 获取业务机构
          if (_this9.bizType === "BROKER") {
            requestOrg.query(res.data.orgCode).then(function (data) {
              _this9.form.fullName = data.data.fullName;
              _this9.$forceUpdate();
            });
          }
        });
      }
    },
    // 上传文件
    uploadFile: function uploadFile(param) {
      var _this10 = this;
      var that = this;
      var formData = new FormData();
      formData.append("file", param.file);
      storageRequest.uploadFile(formData).then(function (res) {
        if (res.code === 200) {
          that.$message.succes;
          var obj = {
            attachmentIds: res.data.fileStorageId,
            name: param.file.name,
            attachmentTypeDisplay: that.activeName,
            type: "上传成功"
          };
          that.fileList.push(obj);
          that.allFileList.push(obj);
          var getfists = _this10.getfist(that.allFileList);
          that.fileTypeList = getfists;
        }
      });
    },
    getfist: function getfist(list) {
      var fileTypeList = [];
      var HTObj = {
        attachmentIds: [],
        fileName: [],
        storageType: ""
      };
      var ZYZObj = {
        attachmentIds: [],
        fileName: [],
        storageType: ""
      };
      var LZFJObj = {
        attachmentIds: [],
        fileName: [],
        storageType: ""
      };
      var YHKObj = {
        attachmentIds: [],
        fileName: [],
        storageType: ""
      };
      var SFZObj = {
        attachmentIds: [],
        fileName: [],
        storageType: ""
      };
      var QTObj = {
        attachmentIds: [],
        fileName: [],
        storageType: ""
      };
      var _iterator2 = _createForOfIteratorHelper(list),
        _step2;
      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var i = _step2.value;
          if (i.attachmentTypeDisplay == "HT" && i.attachmentIds) {
            HTObj.storageType = "HT";
            HTObj.attachmentIds.push(i.attachmentIds);
            HTObj.fileName.push(i.name);
            fileTypeList.push(HTObj);
          }
          if (i.attachmentTypeDisplay == "ZYZ" && i.attachmentIds) {
            ZYZObj.storageType = "ZYZ";
            ZYZObj.attachmentIds.push(i.attachmentIds);
            ZYZObj.fileName.push(i.name);
            fileTypeList.push(ZYZObj);
          }
          if (i.attachmentTypeDisplay == "LZFJ" && i.attachmentIds) {
            LZFJObj.storageType = "LZFJ";
            LZFJObj.attachmentIds.push(i.attachmentIds);
            LZFJObj.fileName.push(i.name);
            fileTypeList.push(LZFJObj);
          }
          if (i.attachmentTypeDisplay == "YHK" && i.attachmentIds) {
            YHKObj.storageType = "YHK";
            YHKObj.attachmentIds.push(i.attachmentIds);
            YHKObj.fileName.push(i.name);
            fileTypeList.push(YHKObj);
          }
          if (i.attachmentTypeDisplay == "SFZ" && i.attachmentIds) {
            SFZObj.storageType = "SFZ";
            SFZObj.attachmentIds.push(i.attachmentIds);
            SFZObj.fileName.push(i.name);
            fileTypeList.push(SFZObj);
          }
          if (i.attachmentTypeDisplay == "QT" && i.attachmentIds) {
            QTObj.storageType = "QT";
            QTObj.attachmentIds.push(i.attachmentIds);
            QTObj.fileName.push(i.name);
            fileTypeList.push(QTObj);
          }
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }
      return Array.from(new Set(fileTypeList));
    },
    // 删除文件
    deleteFile: function deleteFile(row) {
      var uid = row.attachmentIds;
      this.fileList.splice(this.fileList.findIndex(function (item) {
        return item.attachmentIds === uid;
      }), 1);
      this.allFileList.splice(this.allFileList.findIndex(function (item) {
        return item.attachmentIds === uid;
      }), 1);
      var getfists = this.getfist(this.allFileList);
      this.fileTypeList = getfists;
    },
    // 重置搜索表单
    resetSearch: function resetSearch() {
      this.searchForm = {
        orgCode: '',
        areaType: ''
      };
      this.orgForm = {
        salesOrgCode: '',
        salesOrgName: '全部',
        salesOrgType: ''
      };
    },
    // 搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    // 获取列表数据
    loadData: function loadData() {
      var _this11 = this;
      this.drawer = false;
      this.loading = true;
      this.showEmployeeDialog = false;
      var params = '';
      if (this.bizType === 'OEM') {
        params = this.orgName;
      } else {
        params = this.bizType;
      }
      rolesRequest.getRoleDropdownListByBizType(params).then(function (res) {
        _this11.roles = _this11.dealerRoles = res.data;
        if (params === 'DEALER') {
          _this11.roles = _this11.dealerRoles.filter(function (val) {
            return val.roleId != 81;
          });
        }
      });
      var postData = {};
      postData = Object.assign(postData, this.listQuery, this.searchForm);
      var orgRequest = null;
      if (this.bizType === "DEALER") {
        orgRequest = employeeRequest.getDealerList(postData);
      } else if (this.bizType === 'OEM' && this.orgName === 'OEM') {
        orgRequest = employeeRequest.getOemList(postData);
      } else if (this.bizType === 'OEM' && this.orgName === 'DEALER') {
        orgRequest = employeeRequest.getOemSubordinateList(postData);
      } else if (this.bizType === "BROKER") {
        orgRequest = employeeRequest.getBrokerList(postData);
      }
      orgRequest.then(function (res) {
        _this11.tableData = res.data && res.data.list || [];
        _this11.adminRoles = _.filter(_this11.tableData, function (o) {
          return _.find(o.roleVOS, function (item) {
            return item.roleName && item.roleName.indexOf('管理员') != -1;
          });
        });
        // 总条数
        _this11.total = res.data && res.data.page && res.data.page.recordsTotal || 0;
        _this11.loading = false;
      }).catch(function (err) {
        _this11.loading = false;
      });
    },
    getRoleNames: function getRoleNames(row) {
      var nameArr = [];
      if (row) {
        nameArr = row.map(function (val) {
          return val.roleName;
        });
      }
      return nameArr.join(',');
    }
  }
};