import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '../../mixins/initHeight';
import dict from '../../mixins/dict';
import Dialog from '@/components/Dialog';
import Pagination from '@/components/Pagination';
import request from '../../api/quoteFailures';
import { limitInput, commonExport } from '@/utils/common';
export default {
  name: 'quoteFailures',
  components: {
    Pagination: Pagination,
    Dialog: Dialog
  },
  mixins: [initHeight, dict],
  computed: {},
  data: function data() {
    return {
      drawer: false,
      searchForm: {
        insuredFlowType: null,
        failureTemplate: ''
      },
      searchCategoryForm: {
        categoryName: null
      },
      formLoading: false,
      inputVisible: false,
      inputValue: '',
      rules: {
        orderBy: [{
          required: true,
          message: '请输入优先级',
          trigger: 'blur'
        }],
        failureTemplate: [{
          required: true,
          message: '请输入原提示信息',
          trigger: 'blur'
        }],
        translationContent: [{
          required: true,
          message: '请输入优化显示信息',
          trigger: 'blur'
        }],
        categoryId: [{
          required: true,
          message: '请选择错误分类',
          trigger: 'change'
        }],
        categoryName: [{
          required: true,
          message: '请输入分类名称',
          trigger: 'blur'
        }]
      },
      form: {
        orderBy: '',
        failureTemplate: '',
        translationContent: '',
        categoryId: null
      },
      showDialog: false,
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      listItemQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      multipleSelection: [],
      opType: '',
      isClick: false,
      loading: false,
      failureTranslationId: null,
      showCategoryDialog: false,
      categoryData: [],
      showAddErrorDialog: false,
      categoryForm: {
        categoryName: '',
        personFlag: true
      },
      categoryTotal: 0,
      allCategory: [],
      categoryLoading: false
    };
  },
  created: function created() {
    this.loadData();
  },
  methods: {
    exportExcels: function exportExcels() {
      //导出
      var searchForm = _objectSpread(_objectSpread(_objectSpread({}, this.searchForm), this.listQuery), {}, {
        count: false
      });
      var exporParams = {
        total: this.total,
        limitNum: 200000,
        fileName: '投保异常配置',
        exportUrl: '/vi-connect-configuration-service/failureTranslation/search',
        searchForm: searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    onInput: function onInput(num, min, max, limit) {
      //对输入内容做精度校验
      this.$forceUpdate();
      if (min && num < min) {
        num = min;
      }
      if (max && num > max) {
        num = max;
      }
      return limitInput(num, limit);
    },
    removeItems: function removeItems(item) {
      var _this = this;
      //删除
      var ids = [];
      if (!_.isEmpty(item)) {
        ids.push(item.failureTranslationId);
      } else {
        if (!_.isEmpty(this.multipleSelection)) {
          ids = _.map(this.multipleSelection, 'failureTranslationId');
        }
      }
      if (_.isEmpty(ids)) return;
      this.$confirm("\u662F\u5426\u786E\u8BA4\u5220\u9664".concat(!_.isEmpty(item) ? '该配置项' : '所选中的配置项', ", \u786E\u8BA4\u5220\u9664\u540E\u4E0D\u80FD\u6062\u590D\u3002"), '温馨提示', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        type: 'warning'
      }).then(function () {
        request.delete(ids).then(function (res) {
          if (res.code == RESPONSE_SUCCESS) {
            _this.$message({
              type: 'success',
              message: res.msg
            });
            _this.loadData();
          }
        });
      });
    },
    viewDetail: function viewDetail(type, item) {
      var _this2 = this;
      //查看详情
      this.opType = type;
      this.failureTranslationId = item.failureTranslationId;
      this.showDialog = true;
      this.formLoading = true;
      request.getAllErrorCategory().then(function (res) {
        if (res.data) {
          _this2.allCategory = res.data;
        }
      });
      request.getDetail(item.failureTranslationId).then(function (res) {
        if (res.data && res.code == RESPONSE_SUCCESS) {
          _this2.form = res.data;
          var category = _.find(_this2.allCategory, function (item) {
            return item.categoryId == _this2.form.categoryId;
          });
          if (!category) _this2.form.categoryId = null;
          _this2.formLoading = false;
        }
      }).catch(function (err) {});
    },
    searchData: function searchData() {
      //搜索
      this.loadData(true);
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {
        failureTemplate: '',
        translationContent: ''
      };
    },
    resetCategorySearch: function resetCategorySearch() {
      //重置搜索表单
      this.searchCategoryForm = {
        categoryName: null
      };
    },
    openCategoryDialog: function openCategoryDialog() {
      //打开错误分类配置列表
      this.showCategoryDialog = true;
      this.loadCategoryData();
    },
    addCategory: function addCategory() {
      var _this3 = this;
      //打开新增错误分类弹窗
      this.opType = 'add';
      this.showAddErrorDialog = true;
      this.categoryForm = {
        categoryName: '',
        personFlag: true
      };
      this.$nextTick(function () {
        _this3.$refs['categoryForm'].clearValidate();
      });
    },
    saveCategory: function saveCategory() {
      var _this4 = this;
      //保存或修改错误分类
      this.$refs['categoryForm'].validate(function (valid) {
        if (valid) {
          _this4.categoryLoading = true;
          var localRequest = _this4.opType == 'update' ? request.updateCategory(_objectSpread(_objectSpread({}, _this4.categoryForm), {}, {
            categoryId: _this4.categoryForm.categoryId
          })) : request.createCategory(_this4.categoryForm);
          localRequest.then(function (res) {
            _this4.showAddErrorDialog = false;
            _this4.categoryLoading = false;
            if (res.code == RESPONSE_SUCCESS) {
              _this4.$message({
                type: 'success',
                message: res.msg
              });
              _this4.showDialog = false;
              _this4.$refs['categoryForm'].resetFields();
              _this4.loadCategoryData();
            }
          }).catch(function (err) {
            _this4.categoryLoading = false;
          });
        }
      });
    },
    updateCategory: function updateCategory(item) {
      var _this5 = this;
      //打开编辑错误分类弹窗
      this.opType = 'update';
      this.showAddErrorDialog = true;
      this.categoryLoading = true;
      this.$nextTick(function () {
        _this5.$refs['categoryForm'].clearValidate();
      });
      request.getCategoryDetail(item.categoryId).then(function (res) {
        if (res.data && res.code == RESPONSE_SUCCESS) {
          _this5.categoryForm = res.data;
          _this5.categoryLoading = false;
        }
      }).catch(function (err) {});
    },
    removeCategory: function removeCategory(item) {
      var _this6 = this;
      //删除错误分类
      var ids = [];
      if (!_.isEmpty(item)) {
        ids.push(item.categoryId);
      } else {
        if (!_.isEmpty(this.multipleSelection)) {
          ids = _.map(this.multipleSelection, 'categoryId');
        }
      }
      if (_.isEmpty(ids)) return;
      this.$confirm("\u662F\u5426\u786E\u8BA4\u5220\u9664".concat(!_.isEmpty(item) ? '该错误分类' : '所选中的错误分类', ", \u786E\u8BA4\u5220\u9664\u540E\u4E0D\u80FD\u6062\u590D\u3002"), '温馨提示', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        type: 'warning'
      }).then(function () {
        request.deleteCategory(ids).then(function (res) {
          if (res.code == RESPONSE_SUCCESS) {
            _this6.$message({
              type: 'success',
              message: res.msg
            });
            _this6.loadCategoryData();
          }
        });
      });
    },
    loadCategoryData: function loadCategoryData() {
      var _this7 = this;
      //获取错误分类列表数据
      this.loading = true;
      this.categoryData = [];
      request.getCategoryList(this.listItemQuery.pageNum, this.listItemQuery.pageSize, this.searchCategoryForm).then(function (res) {
        _this7.loading = false;
        if (!_.isEmpty(res.data)) {
          _this7.categoryData = res.data.list;
          //总条数
          _this7.categoryTotal = res.data.page.recordsTotal;
        }
      }).catch(function (err) {
        _this7.loading = false;
      });
    },
    handleCategoryRowChange: function handleCategoryRowChange(row, event, column) {
      //错误分类列表行点击事件
      this.$refs.multipleTable1.toggleRowSelection(row); // 点击选中
    },
    addItem: function addItem() {
      var _this8 = this;
      //新增
      this.form = {
        orderBy: '',
        failureTemplate: '',
        translationContent: ''
      };
      this.opType = '';
      this.showDialog = true;
      request.getAllErrorCategory().then(function (res) {
        if (res.data) {
          _this8.allCategory = res.data;
        }
      });
    },
    handleRowChange: function handleRowChange(row, event, column) {
      //列表行点击事件
      this.$refs.multipleTable.toggleRowSelection(row); // 点击选中
    },
    loadData: function loadData(clickSearch) {
      var _this9 = this;
      //获取列表数据
      this.loading = true;
      this.drawer = false;
      this.tableData = [];
      this.listQuery.pageNum = clickSearch ? 1 : this.listQuery.pageNum;
      request.getList(this.listQuery.pageNum, this.listQuery.pageSize, this.searchForm).then(function (res) {
        _this9.loading = false;
        if (!_.isEmpty(res.data)) {
          _this9.tableData = res.data.list;
          //总条数
          _this9.total = res.data.page.recordsTotal;
        }
      }).catch(function (err) {
        _this9.loading = false;
      });
    },
    submit: function submit() {
      var _this10 = this;
      //表单提交
      // 验证表单
      this.$refs['form'].validate(function (valid) {
        if (valid) {
          _this10.isClick = true;
          _this10.formLoading = true;
          var localRequest = _this10.opType == 'update' ? request.update(_objectSpread(_objectSpread({}, _this10.form), {}, {
            failureTranslationId: _this10.failureTranslationId
          })) : request.create(_this10.form);
          localRequest.then(function (res) {
            _this10.isClick = false;
            _this10.formLoading = false;
            if (res.code == RESPONSE_SUCCESS) {
              _this10.$message({
                type: 'success',
                message: res.msg
              });
              _this10.showDialog = false;
              _this10.$refs['form'].resetFields();
              _this10.loadData();
            }
          }).catch(function (err) {
            _this10.formLoading = false;
            _this10.isClick = false;
          });
        }
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      //列表行选择事件
      this.multipleSelection = val;
    },
    cancel: function cancel() {
      //关闭弹窗
      this.$refs['form'].resetFields();
      this.showDialog = false;
    }
  }
};