var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: {},
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "540px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  attrs: {
                    model: _vm.form,
                    "label-width": "120px",
                    size: "mini",
                  },
                },
                [
                  _vm.form.riskList.length &&
                  _vm.form.riskList.some(function (val) {
                    return val.riskType == "BZ"
                  })
                    ? _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "承保险企(交)：" } },
                                [_vm._v(_vm._s(_vm.form.insCorpName || ""))]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._l(_vm.form.riskList, function (item, index) {
                    return _c("div", { key: index + "BZ" }, [
                      item.riskType == "BZ"
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "交强险日期：" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              item.effectiveStartTime || ""
                                            ) +
                                              " 至 " +
                                              _vm._s(
                                                item.effectiveEndTime || ""
                                              )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "交强险保单号：" } },
                                        [_vm._v(_vm._s(item.policyNo || ""))]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ])
                  }),
                  _vm.form.riskList.length &&
                  _vm.form.riskList.some(function (val) {
                    return val.riskType == "BS"
                  })
                    ? _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "承保险企(商)：" } },
                                [_vm._v(_vm._s(_vm.form.insCorpName || ""))]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._l(_vm.form.riskList, function (item, index) {
                    return _c("div", { key: index + "BS" }, [
                      item.riskType == "BS"
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "商业险日期：" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              item.effectiveStartTime || ""
                                            ) +
                                              " 至 " +
                                              _vm._s(
                                                item.effectiveEndTime || ""
                                              )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "商业险保单号：" } },
                                        [_vm._v(_vm._s(item.policyNo || ""))]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ])
                  }),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("el-form-item", { attrs: { label: "车牌号：" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.form.ownerVehicle &&
                                  _vm.form.ownerVehicle.plateNo
                                  ? _vm.form.ownerVehicle.plateNo
                                  : ""
                              )
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("el-form-item", { attrs: { label: "车架号：" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.form.ownerVehicle &&
                                  _vm.form.ownerVehicle.vin
                                  ? _vm.form.ownerVehicle.vin
                                  : ""
                              )
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "发动机号：" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.form.ownerVehicle &&
                                    _vm.form.ownerVehicle.engineNo
                                    ? _vm.form.ownerVehicle.engineNo
                                    : ""
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "车主名称：" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.form.ownerVehicle &&
                                    _vm.form.ownerVehicle.ownerName
                                    ? _vm.form.ownerVehicle.ownerName
                                    : ""
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "证件类型：" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.form.ownerVehicle &&
                                    _vm.form.ownerVehicle
                                      .ownerCertificateTypeDisplay
                                    ? _vm.form.ownerVehicle
                                        .ownerCertificateTypeDisplay
                                    : ""
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "证件号码：" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.form.ownerVehicle &&
                                    _vm.form.ownerVehicle.ownerCertificateNo
                                    ? _vm.form.ownerVehicle.ownerCertificateNo
                                    : ""
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "核定载客量：" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.form.ownerVehicle &&
                                    _vm.form.ownerVehicle.seats
                                    ? _vm.form.ownerVehicle.seats
                                    : ""
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "新车购置价：" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.form.ownerVehicle &&
                                    _vm.form.ownerVehicle.purchasePrice
                                    ? _vm.form.ownerVehicle.purchasePrice
                                    : ""
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "注册日期：" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.form.ownerVehicle &&
                                    _vm.form.ownerVehicle.registerDate
                                    ? _vm.form.ownerVehicle.registerDate
                                    : ""
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "品牌型号：" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.form.ownerVehicle &&
                                    _vm.form.ownerVehicle.modelName
                                    ? _vm.form.ownerVehicle.modelName
                                    : ""
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "投保车型：" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.getProposalVehicleType(
                                    _vm.form.ownerVehicle
                                  )
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _vm.form.riskListBS
                ? _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c(
                          "table",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { border: "1" },
                          },
                          [
                            _c("col", { staticStyle: { width: "50%" } }),
                            _c("col", { staticStyle: { width: "50%" } }),
                            _c("thead", [
                              _c(
                                "tr",
                                {
                                  staticStyle: { "background-color": "#eeee" },
                                },
                                [
                                  _c("th", [_vm._v("险种/险别")]),
                                  _c(
                                    "th",
                                    { staticStyle: { "text-align": "right" } },
                                    [_vm._v("保额/限额")]
                                  ),
                                ]
                              ),
                            ]),
                            _vm.form.riskListBS
                              ? _c(
                                  "tbody",
                                  _vm._l(
                                    _vm.form.riskListBS.riskList,
                                    function (item, index) {
                                      return _c("tr", { key: index }, [
                                        _c("td", [
                                          _vm._v(_vm._s(item.riskKindName)),
                                        ]),
                                        _c(
                                          "td",
                                          {
                                            staticStyle: {
                                              "text-align": "right",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                  "justify-content": "flex-end",
                                                },
                                              },
                                              [
                                                item.riskKindCode == "A"
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          display: "flex",
                                                          "justify-content":
                                                            "space-between",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              "text-align":
                                                                "left",
                                                              display: "block",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.amount
                                                                  ? item.amount
                                                                  : 0
                                                              ) + " 元"
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              "text-align":
                                                                "right",
                                                              display: "block",
                                                              "margin-left":
                                                                "10px",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "绝对免赔额：" +
                                                                _vm._s(
                                                                  item.absoluteDeductibleAmount
                                                                    ? item.absoluteDeductibleAmount
                                                                    : 0
                                                                ) +
                                                                " 元"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                item.riskKindCode == "D" ||
                                                item.riskKindCode == "D02" ||
                                                (item.riskKindCode == "D03" &&
                                                  !item.shareAmount)
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          (item.unitAmount > 0
                                                            ? _vm.formatOption(
                                                                item.unitAmount
                                                              )
                                                            : "0") +
                                                            "元" +
                                                            " × " +
                                                            item.quantity +
                                                            "座 "
                                                        )
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                                _vm._.indexOf(
                                                  [
                                                    "FZ01",
                                                    "FZ02",
                                                    "FZ03",
                                                    "FZ04",
                                                  ],
                                                  item.riskKindCode
                                                ) != -1
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.getQuantity(
                                                            item
                                                          ) + "次"
                                                        )
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                                _vm._.indexOf(
                                                  [
                                                    "A04",
                                                    "A05",
                                                    "D",
                                                    "A",
                                                    "FZ01",
                                                    "FZ02",
                                                    "FZ03",
                                                    "FZ04",
                                                    "B05",
                                                    "C02",
                                                    "A02",
                                                    "A08",
                                                    "D02",
                                                    "D03",
                                                    "A11",
                                                    "C03",
                                                    "B04",
                                                  ],
                                                  item.riskKindCode
                                                ) == -1 ||
                                                (["C03", "B04"].includes(
                                                  item.riskKindCode
                                                ) &&
                                                  !item.shareAmount)
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.absoluteDeductible
                                                            ? item.absoluteDeductible +
                                                                "%"
                                                            : item.amount
                                                            ? _vm.formatOption(
                                                                item.amount
                                                              ) + "元"
                                                            : item.amount + "元"
                                                        )
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                                item.riskKindCode == "A04"
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          (item.unitAmount > 0
                                                            ? _vm.formatOption(
                                                                item.unitAmount
                                                              )
                                                            : "0") +
                                                            "元" +
                                                            " × " +
                                                            item.quantity +
                                                            "天"
                                                        )
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                                item.riskKindCode == "A05"
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          (item.absoluteDeductible ||
                                                            "0") + "%"
                                                        )
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                                item.riskKindCode == "B05" ||
                                                item.riskKindCode == "C02" ||
                                                item.riskKindCode == "A11"
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          (item.unitAmount > 0
                                                            ? _vm.formatOption(
                                                                item.unitAmount
                                                              )
                                                            : "0") + "元"
                                                        )
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                                item.riskKindCode == "A02"
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.unitAmount.toFixed(
                                                            2
                                                          ) + "元"
                                                        )
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                                item.riskKindCode == "A08"
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.unitAmount > 0
                                                            ? item.unitAmount
                                                            : item.amount || 0
                                                        ) + "元"
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                                ["C03", "D03", "B04"].includes(
                                                  item.riskKindCode
                                                ) && item.shareAmount
                                                  ? _c("span", [
                                                      _vm._v("共享保额"),
                                                    ])
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ])
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: { click: _vm.closePage },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }