import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import dict from '@/mixins/dict';
import Dialog from '@/components/Dialog';
import Pagination from '@/components/Pagination';
import request from '@/api/dictionary';
export default {
  name: 'DictManage',
  components: {
    Pagination: Pagination,
    Dialog: Dialog
  },
  mixins: [initHeight, dict],
  computed: {},
  data: function data() {
    return {
      storage: '',
      //临时存储第二层编辑数据

      drawer: false,
      searchForm: {
        dictName: ''
      },
      form: {
        code: '',
        name: ''
      },
      rules: {
        code: {
          required: true,
          message: '请输入字典编码',
          trigger: 'change'
        },
        name: {
          required: true,
          message: '请输入字典名称',
          trigger: 'change'
        }
      },
      dicForm: {
        code: '',
        name: '',
        sortBy: ''
      },
      dicRules: {
        code: {
          required: true,
          message: '请输入字典编码',
          trigger: 'change'
        },
        name: {
          required: true,
          message: '请输入字典名称',
          trigger: 'change'
        },
        sortBy: {
          required: true,
          message: '请输入排序',
          trigger: 'change'
        }
      },
      formLoading: false,
      inputVisible: false,
      inputValue: '',
      showDialog: false,
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      showDetailDialog: false,
      detailDialogLoading: false,
      opType: '',
      dicOpType: '',
      showDicItemDialog: false,
      FormDicItemLoading: false,
      isClick: false,
      tableItemData: [],
      itemTotal: 0,
      listItemQuery: {
        pageNum: 1,
        pageSize: 20
      },
      dictId: '',
      dictCode: '',
      loading: false
    };
  },
  created: function created() {
    this.loadData();
  },
  methods: {
    breakData: function breakData() {
      // this.resetSearch()
      this.loadData(false);
    },
    // 是否启用
    updateItem: function updateItem(item) {
      var _this = this;
      request.update(item.dictId, item).then(function (res) {
        if (res.code == RESPONSE_SUCCESS) {
          _this.$message({
            type: 'success',
            message: res.msg
          });
          _this.showDialog = false;
          _this.loadData();
        } else {}
      }).catch(function (err) {});
    },
    // 字典单条删除
    removeItems: function removeItems(item) {
      var _this2 = this;
      //删除
      this.$confirm("\u662F\u5426\u786E\u8BA4\u5220\u9664, \u786E\u8BA4\u5220\u9664\u540E\u4E0D\u80FD\u6062\u590D\u3002", '温馨提示', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        type: 'warning'
      }).then(function () {
        request.delete(item.dictId).then(function (res) {
          if (res.code == RESPONSE_SUCCESS) {
            _this2.$message({
              type: 'success',
              message: res.msg
            });
            _this2.loadData();
          }
        });
      });
    },
    // 字典条目单条删除
    removeDicItems: function removeDicItems(item) {
      var _this3 = this;
      //删除
      this.$confirm("\u662F\u5426\u786E\u8BA4\u5220\u9664, \u786E\u8BA4\u5220\u9664\u540E\u4E0D\u80FD\u6062\u590D\u3002", '温馨提示', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        type: 'warning'
      }).then(function () {
        request.ditcItemDelete(item.itemId).then(function (res) {
          if (res.code == RESPONSE_SUCCESS) {
            _this3.$message({
              type: 'success',
              message: res.msg
            });
            _this3.loadItemData();
          }
        });
      });
    },
    formCodeNo: function formCodeNo(val) {
      //输入的字典编码的字母自动转大写
      // let _temp = _.toUpper(val);
      this.form.code = val.replace(/[^A-Za-z0-9_-]/g, '');
    },
    dicFormCodeNo: function dicFormCodeNo(val) {
      //输入的字典条目编码的字母自动转大写
      // let _temp = _.toUpper(val);
      this.dicForm.code = val.replace(/[^A-Za-z0-9_-]/g, '');
    },
    searchData: function searchData() {
      //搜索
      this.loadData(true);
    },
    viewDetail: function viewDetail(item) {
      //查看详情
      this.dictId = item.dictId;
      this.dictCode = item.dictCode;
      this.showDetailDialog = true;
      this.loadItemData();
    },
    updateDetail: function updateDetail(item) {
      var _this4 = this;
      this.opType = 'update';
      request.getDetail(item.dictId).then(function (res) {
        if (res.data && res.code == RESPONSE_SUCCESS) {
          _this4.form.code = res.data.dictCode;
          _this4.form.name = res.data.dictName;
          _this4.form.dictId = res.data.dictId;
          _this4.form.dictCode = res.data.dictCode;
          _this4.showDialog = true;
        }
      }).catch(function (err) {});
    },
    addDicItem: function addDicItem() {
      this.dicOpType = '';
      this.dicForm.dictId = this.dictId;
      this.showDicItemDialog = true;
    },
    //编辑>字典项
    updateDicDetail: function updateDicDetail(item) {
      var _this5 = this;
      this.dicOpType = 'update';
      request.getDitcItemDetail(item.itemId).then(function (res) {
        if (res.data && res.code == RESPONSE_SUCCESS) {
          _this5.showDicItemDialog = true;
          _this5.storage = res.data;
          _this5.dicForm.code = res.data.itemCode;
          _this5.dicForm.name = res.data.itemName;
        }
      }).catch(function (err) {});
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {
        dictName: ''
      };
    },
    addItem: function addItem() {
      //新增
      this.opType = '';
      this.showDialog = true;
    },
    handleRowChange: function handleRowChange(row, event, column) {
      //列表行点击事件
      this.$refs.multipleTable.toggleRowSelection(row); // 点击选中
    },
    // 关闭字典详情
    dicDetailcancel: function dicDetailcancel() {
      this.dictId = '';
      this.dictCode = '';
      this.showDetailDialog = false;
    },
    loadData: function loadData(clickSearch) {
      var _this6 = this;
      //获取列表数据
      this.loading = true;
      this.drawer = false;
      this.tableData = [];
      this.listQuery.pageNum = clickSearch ? 1 : this.listQuery.pageNum;
      request.getList(this.listQuery.pageNum, this.listQuery.pageSize, this.searchForm).then(function (res) {
        _this6.loading = false;
        if (!_.isEmpty(res.data)) {
          _this6.tableData = res.data.list;
          //总条数
          _this6.total = res.data.page.recordsTotal;
        }
      }).catch(function (err) {
        _this6.loading = false;
      });
    },
    loadItemData: function loadItemData(clickSearch) {
      var _this7 = this;
      //获取列表数据
      this.detailDialogLoading = true;
      this.tableItemData = [];
      this.listItemQuery.pageNum = clickSearch ? 1 : this.listItemQuery.pageNum;
      request.getDitcItemList(this.listItemQuery.pageNum, this.listItemQuery.pageSize, {
        dictId: this.dictId,
        dictCode: this.dictCode
      }).then(function (res) {
        _this7.detailDialogLoading = false;
        if (!_.isEmpty(res.data)) {
          _this7.tableItemData = res.data.list;
          //总条数
          _this7.itemTotal = res.data.page.recordsTotal;
        }
      }).catch(function (err) {
        _this7.detailDialogLoading = false;
      });
    },
    cancel: function cancel() {
      //关闭弹窗
      this.form = {
        name: '',
        code: ''
      };
      this.$refs['form'].resetFields();
      this.showDialog = false;
    },
    // 关闭字典条目详情
    dicItemDiccancel: function dicItemDiccancel() {
      this.dicForm = {
        code: '',
        name: '',
        sortBy: ''
      };
      this.$refs['dicForm'].resetFields();
      this.showDialog = false;
      this.showDicItemDialog = false;
    },
    submit: function submit() {
      var _this8 = this;
      //表单提交
      // 验证表单
      this.$refs['form'].validate(function (valid) {
        if (valid) {
          _this8.isClick = true;
          _this8.formLoading = true;
          var parameter = {
            dictCode: _this8.form.code,
            dictName: _this8.form.name
          };
          var localRequest = _this8.opType == 'update' ? request.update(_this8.form.dictId, parameter) : request.create(parameter);
          localRequest.then(function (res) {
            _this8.isClick = false;
            _this8.formLoading = false;
            if (res.code == RESPONSE_SUCCESS) {
              _this8.$message({
                type: 'success',
                message: res.msg
              });
              _this8.showDialog = false;
              _this8.$refs['form'].resetFields();
              _this8.loadData();
            }
          }).catch(function (err) {
            _this8.formLoading = false;
            _this8.isClick = false;
          }).finally(function () {
            if (_this8.opType == 'update') {
              _this8.form.name = '';
            } else {
              _this8.form = {
                code: '',
                name: ''
              };
            }
          });
        }
      });
    },
    itemSubmit: function itemSubmit() {
      var _this9 = this;
      this.$refs['dicForm'].validate(function (valid) {
        if (valid) {
          _this9.isClick = true;
          _this9.FormDicItemLoading = true;
          var parameter = {
            dictId: _this9.dictId,
            dictCode: _this9.dictCode,
            itemCode: _this9.dicForm.code,
            itemName: _this9.dicForm.name,
            sortBy: _this9.dicForm.sortBy
          };
          var editor = {
            dictId: _this9.dictId,
            dictCode: _this9.dictCode,
            itemCode: _this9.dicForm.code,
            itemName: _this9.dicForm.name,
            sortBy: _this9.dicForm.sortBy
          };
          var localRequest = _this9.dicOpType == 'update' ? request.ditcItemUpdate(_this9.storage.itemId, editor) : request.ditcItemCreate(parameter);
          localRequest.then(function (res) {
            _this9.isClick = false;
            _this9.FormDicItemLoading = false;
            if (res.code == RESPONSE_SUCCESS) {
              _this9.showDicItemDialog = false;
              _this9.$message({
                type: 'success',
                message: res.msg
              });
              _this9.$refs['dicForm'].resetFields();
              _this9.loadItemData();
            }
          }).catch(function (err) {
            _this9.FormDicItemLoading = false;
            _this9.isClick = false;
          }).finally(function () {
            if (_this9.dicOpType == 'update') {
              _this9.dicForm.name = '';
              _this9.dicForm.sortBy = '';
            } else {
              _this9.dicForm = {
                code: '',
                name: '',
                sortBy: ''
              };
            }
          });
        }
      });
    }
  }
};