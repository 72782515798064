import "core-js/modules/es.array.concat.js";
/**
 * 短信管理
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  // ========================================短信模板类型=========================================================================

  /**
   * 列表
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   * @param {*} data 内容
   */
  getTemplateTypeList: function getTemplateTypeList(pageNum, pageSize, data) {
    return request.get(getUrl("/template/type/search?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 8), {
      params: data
    });
  },
  /**
   * 新增 
   * @param {*} data 内容
   */
  addTemplateType: function addTemplateType(data) {
    return request.post(getUrl("/template/type", 8), data);
  },
  /**
   * 获取单条数据
   * @param {*} id 
   */
  queryTemplateType: function queryTemplateType(id) {
    return request.get(getUrl("/template/type/".concat(id), 8));
  },
  /**
   * 修改
   * @param {*} data 内容
   */
  editTemplateType: function editTemplateType(id, data) {
    return request.put(getUrl("/template/type/".concat(id), 8), data);
  },
  /**
   * 批量删除
   * @param {*} ids
   */
  deleteTemplateType: function deleteTemplateType(ids) {
    return request.delete(getUrl("/template/type/".concat(ids), 8));
  },
  // ========================================短信帐号配置=========================================================================

  /**
   * 列表
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   * @param {*} data 内容
   */
  getMnoConfigList: function getMnoConfigList(pageNum, pageSize, data) {
    return request.get(getUrl("/mno/config/search?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 8), {
      params: data
    });
  },
  /**
   * 新增 
   * @param {*} data 内容
   */
  addMnoConfig: function addMnoConfig(data) {
    return request.post(getUrl("/mno/config", 8), data);
  },
  /**
   * 获取单条数据
   * @param {*} id 
   */
  queryMnoConfig: function queryMnoConfig(id) {
    return request.get(getUrl("/mno/config/".concat(id), 8));
  },
  /**
   * 修改
   * @param {*} data 内容
   */
  editMnoConfig: function editMnoConfig(id, data) {
    return request.put(getUrl("/mno/config/".concat(id), 8), data);
  },
  /**
   * 批量删除
   * @param {*} ids
   */
  deleteMnoConfig: function deleteMnoConfig(ids) {
    return request.delete(getUrl("/mno/config?accountConfigId=".concat(ids), 8));
  },
  // ========================================短信模板维护=========================================================================

  /**
   * 列表
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   * @param {*} data 内容
   */
  getTemplateList: function getTemplateList(pageNum, pageSize, data) {
    return request.get(getUrl("/template/search?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 8), {
      params: data
    });
  },
  /**
   * 新增 
   * @param {*} data 内容
   */
  addTemplate: function addTemplate(data) {
    return request.post(getUrl("/template", 8), data);
  },
  /**
   * 获取单条数据
   * @param {*} id 
   */
  queryTemplate: function queryTemplate(id) {
    return request.get(getUrl("/template/".concat(id), 8));
  },
  /**
   * 修改
   * @param {*} data 内容
   */
  editTemplate: function editTemplate(id, data) {
    return request.put(getUrl("/template/".concat(id), 8), data);
  },
  /**
   * 批量删除
   * @param {*} ids
   */
  deleteTemplate: function deleteTemplate(ids) {
    return request.delete(getUrl("/template?templateId=".concat(ids), 8));
  },
  /**
   * 短信模板类型列表
   * @param {*} id 
   */
  getTemplateType: function getTemplateType() {
    return request.get(getUrl("/template/type/list", 8));
  },
  /**
   * 短信模板类型根据ID查询接口
   * @param {*} id 
   */
  getTemplateInfo: function getTemplateInfo(id) {
    return request.get(getUrl("/template/type/".concat(id), 8));
  },
  // ========================================短信发送记录=========================================================================

  /**
   * 列表
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   * @param {*} data 内容
   */
  getSmsSendList: function getSmsSendList(pageNum, pageSize, data) {
    return request.get(getUrl("/sms/send/search?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 8), {
      params: data
    });
  }
};
export default objApi;