import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import dict from "../../mixins/dict";
import initHeight from "../../mixins/initHeight";
import request from '../../api/insurancepolicy';
import OrgSelect from '@/components/OrgSelect';
import requestOrg from '@/api/orgChildren';
import storageRequest from '@/api/storage';
import OemSelect from '@/components/OemSelect';
import commonReq from '@/api/common';
import { downFile } from '@/utils/common';
export default {
  props: {
    refresh: {
      type: Boolean
    },
    bizType: {
      type: String,
      default: ''
    }
  },
  mixins: [initHeight, dict],
  components: {
    OrgSelect: OrgSelect,
    OemSelect: OemSelect
  },
  data: function data() {
    return {
      showDialog: false,
      uploadForm: {
        ownerOrgCode: '',
        ownerOrgName: '',
        ownerOrgId: '',
        storageId: '',
        fileName: ''
      },
      fileList: [],
      users: [],
      orgForm: {
        salesOrgCode: '',
        salesOrgName: ''
      },
      formRules: {
        ownerOrgCode: [{
          required: true,
          message: '请选择业务机构',
          trigger: 'change'
        }],
        importSchemaId: [{
          required: true,
          message: '请选择导入方案',
          trigger: 'change'
        }]
      },
      userInfo: JSON.parse(localStorage.getItem("userInfo")) || {},
      importLoading: false,
      importSchemas: []
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
      ;
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    initData: function initData() {
      this.uploadForm = {
        ownerOrgCode: '',
        ownerOrgName: '',
        ownerOrgId: '',
        storageId: '',
        fileName: ''
      };
      this.orgForm = {
        salesOrgCode: '',
        salesOrgName: ''
      };
      this.fileList = [];
      this.$refs.detail.resetFields();
      if (this.bizType === 'DEALER') this.endQuery();
    },
    endQuery: function endQuery() {
      var _this = this;
      requestOrg.dealerQuery(this.userInfo.orgCode).then(function (res) {
        if (res.data) {
          _this.uploadForm.ownerOrgCode = res.data.dealerCode;
          _this.uploadForm.ownerOrgName = res.data.dealerName;
          _this.uploadForm.ownerOrgId = res.data.dealerId;
        }
        ;
      });
    },
    nodeClick: function nodeClick(data) {
      var _this2 = this;
      if (this.bizType === "BROKER") {
        this.uploadForm.ownerOrgCode = data.salesOrgCode;
        this.uploadForm.ownerOrgName = data.salesOrgName;
        this.uploadForm.ownerOrgId = data.organizationId;
      }
      ;
      if (this.bizType === "OEM") {
        this.uploadForm.ownerOrgCode = data.salesOrgCode;
        this.uploadForm.ownerOrgName = data.salesOrgName;
      }
      ;
      commonReq.getTemplateByOrg({
        bizType: data.bizType,
        importCategory: 'POLICY',
        orgCode: data.salesOrgCode
      }).then(function (res) {
        if (res.data) {
          _this2.importSchemas = res.data;
        }
      });
    },
    // 上传文件
    uploadFile: function uploadFile(param) {
      var _this3 = this;
      var formData = new FormData();
      formData.append('file', param.file);
      storageRequest.uploadFile(formData).then(function (res) {
        if (res.code === 200) {
          _this3.$message.success('模板上传完成，请进行导入');
          setTimeout(function () {
            _this3.uploadForm.storageId = res.data.fileStorageId;
            _this3.uploadForm.fileName = param.file.name;
            _this3.uploadForm.fileUrl = res.data.fileUrl;
          }, 1000);
        }
        ;
      });
    },
    // 下载模板
    download: function download() {
      var _this4 = this;
      storageRequest.downFileTemplate("VI-POLICY-IMPORT-TEMPLATE").then(function (res) {
        if (res.data && res.data.fileStorageId) {
          downFile(res.data.fileUrl, res.data.fileName);
        } else {
          _this4.$message.warning('暂无模板，请联系客服！');
        }
        ;
      });
    },
    closePage: function closePage() {
      this.$refs.upload.clearFiles();
      //关闭弹窗
      this.$emit("closePage");
    },
    // 查看历史导入记录
    viewHistoricalRecords: function viewHistoricalRecords() {
      this.closePage();
      this.$router.push("/historicalImportRecords");
    },
    // 附件校验
    validate: function validate() {
      var flag = true;
      if (!this.uploadForm.storageId) {
        this.$message.warning('请上传文件');
        flag = false;
        return flag;
      }
      ;
      return flag;
    },
    saveUpload: function saveUpload() {
      var _this5 = this;
      this.$refs["detail"].validate(function (valid) {
        if (valid) {
          if (!_this5.validate()) return;
          _this5.importLoading = true;
          var importSchema = _.find(_this5.importSchemas, function (item) {
            return item.templateId == _this5.uploadForm.importSchemaId;
          });
          if (importSchema) _this5.uploadForm.importSchemaCode = importSchema.templateCode;
          request.importM(_this5.uploadForm).then(function (res) {
            _this5.importLoading = false;
            if (res.code === 200) {
              _this5.$confirm("".concat(res.data.failures ? '文件模板失败数据' + (res.data.failures || 0) + '条，点击打开文件，重新上传导入，谢谢！' : '导入成功', " "), '提示', {
                showCancelButton: false,
                confirmButtonText: "".concat(res.data.failures ? '打开文件' : "确定"),
                type: "".concat(res.data.failures ? 'warning' : 'success')
              }).then(function () {
                if (res.data.failures) {
                  downFile(res.data.fileUrl, res.data.fileName);
                  _this5.fileList = [];
                  _this5.uploadForm.storageId = '';
                  _this5.uploadForm.fileName = '';
                } else {
                  _this5.fileList = [];
                  _this5.closePage();
                }
                ;
              }).catch(function () {});
            }
            ;
          }).catch(function (err) {
            _this5.importLoading = false;
          });
        }
        ;
      });
    }
  }
};