import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.join.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import Pagination from '@/components/Pagination';
import OemSelect from '@/components/OemSelect';
import OrgSelect from '@/components/OrgSelect';
import vehicleSalesRequest from '@/api/vehicleSalesMaintain';
import ImportConfigurationDialog from './dialog/ImportConfigurationDialog';
import dictRequest from '@/api/dictionary';
import { getSupplyers, getUserOrgInfoDealerOem } from '@/utils/common';
export default {
  name: 'ImportConfiguration',
  components: {
    Pagination: Pagination,
    OemSelect: OemSelect,
    OrgSelect: OrgSelect,
    ImportConfigurationDialog: ImportConfigurationDialog
  },
  mixins: [initHeight],
  data: function data() {
    return {
      bizType: localStorage.getItem("bizType") || '',
      userInfo: JSON.parse(localStorage.getItem("userInfo")) || {},
      searchForm: {
        bizType: localStorage.getItem("bizType") || '',
        orgCode: '',
        insCorpCode: null,
        importCategory: '',
        templateName: '',
        areaType: ''
      },
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      orgForm: {
        salesOrgCode: "",
        salesOrgName: "",
        salesOrgType: ""
      },
      loading: false,
      supplyers: [],
      importCategoryList: [],
      showDialog: false,
      currentType: '',
      drawer: false,
      currentTemplateId: '',
      templateIdArr: []
    };
  },
  created: function created() {
    if (this.bizType === "DEALER") {
      var orgObj = getUserOrgInfoDealerOem(this.bizType);
      this.searchForm.orgCode = orgObj.salesOrgCode;
      this.searchForm.orgName = orgObj.salesOrgName;
    }
    ;
  },
  mounted: function mounted() {
    // 获取保险公司列表
    this.supplyers = getSupplyers();
    this.getImportCategoryList();
    this.loadData();
  },
  methods: {
    //获取续客户类别
    getImportCategoryList: function getImportCategoryList() {
      var _this = this;
      dictRequest.getDictItemsByCode("IMPORT_TEMPATE_CATEGORY").then(function (res) {
        if (res.data) {
          _this.importCategoryList = res.data;
        }
        ;
      });
    },
    // 树形点击
    nodeClick: function nodeClick(data) {
      if (this.bizType === "BROKER") {
        this.searchForm.orgCode = data.salesOrgCode;
      }
      ;
      if (this.bizType === "OEM") {
        this.searchForm.orgCode = data.salesOrgCode;
        this.searchForm.areaType = data.salesOrgType;
      }
      ;
    },
    // 刷新
    breakData: function breakData() {
      // this.resetSearch();
      this.loadData();
    },
    // 重置搜索表单
    resetSearch: function resetSearch() {
      this.searchForm = {
        areaType: '',
        bizType: this.searchForm.bizType,
        orgCode: this.bizType === 'DEALER' ? this.searchForm.orgCode : '',
        orgName: this.bizType === 'DEALER' ? this.searchForm.orgName : '',
        insCorpCode: null,
        importCategory: '',
        templateName: ''
      };
      this.orgForm = {
        salesOrgCode: "",
        salesOrgName: this.bizType === 'OEM' ? "全部" : '',
        salesOrgType: ""
      };
    },
    // 搜索
    searchData: function searchData() {
      this.loadData();
    },
    //获取列表数据
    loadData: function loadData() {
      var _this2 = this;
      this.loading = true;
      this.showDialog = false;
      this.drawer = false;
      this.templateIdArr = [];
      vehicleSalesRequest.getConfigureList(this.listQuery.pageNum, this.listQuery.pageSize, this.searchForm).then(function (res) {
        _this2.loading = false;
        if (res.code === 200) {
          _this2.tableData = res.data.list;
          _this2.total = res.data.page.recordsTotal;
        }
        ;
      }).catch(function (err) {
        _this2.loading = false;
      });
    },
    // 勾选复选框
    handleSelectionChange: function handleSelectionChange(val) {
      this.templateIdArr = val.map(function (val) {
        return val.templateId;
      });
    },
    // 新增/复制/编辑
    addConfigure: function addConfigure(type) {
      var item = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      this.currentType = type;
      if (this.currentType === 'add') {
        this.showDialog = true;
      } else if (this.currentType === 'edit') {
        this.currentTemplateId = item.templateId.toString();
        this.showDialog = true;
      } else if (this.currentType === 'copy') {
        if (!this.templateIdArr.length) {
          this.$message.warning('请先选择要复制的记录');
          return;
        }
        ;
        this.templateIdArr = this.templateIdArr.sort();
        this.currentTemplateId = this.templateIdArr[0].toString();
        this.showDialog = true;
      }
      ;
    },
    remove: function remove() {
      var _this3 = this;
      if (!this.templateIdArr.length) {
        this.$message.warning('请选择需要删除的数据');
        return;
      }
      this.$confirm('您确定要删除选中的数据吗', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        type: 'warning'
      }).then(function () {
        vehicleSalesRequest.removeConfigure(_this3.templateIdArr.join(',')).then(function (res) {
          if (res.code === 200) {
            _this3.$message.success(res.msg);
            _this3.loadData();
          }
          ;
        });
      }).catch(function () {});
    }
  }
};