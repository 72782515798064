import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import Pagination from '@/components/Pagination';
import DatePicker from '@/components/DatePicker';
import OemSelect from '@/components/OemSelect';
import vehiclesApi from '@/api/vehiclesIntoShop';
import { getCurrentDate, getUserOrgInfoDealerOem, downFile, commonExport } from '@/utils/common';
export default {
  name: 'IdentifyRecord',
  components: {
    Pagination: Pagination,
    OemSelect: OemSelect,
    DatePicker: DatePicker
  },
  mixins: [initHeight],
  data: function data() {
    return {
      searchForm: {},
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      loading: false,
      bizType: localStorage.getItem("bizType") || '',
      orgForm: {
        salesOrgCode: '',
        salesOrgName: '',
        salesOrgType: ''
      },
      entryTime: [getCurrentDate(), getCurrentDate()],
      showViewDialog: false,
      viewForm: {
        imageIdList: []
      },
      srcList: []
    };
  },
  filters: {
    filterType: function filterType(val) {
      if (val === 0) return '0 B';
      var k = 1024;
      var sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      var i = Math.floor(Math.log(val) / Math.log(k));
      return (val / Math.pow(k, i)).toPrecision(3) + " " + sizes[i];
    }
  },
  created: function created() {
    this.loadData();
    if (this.bizType === "DEALER") {
      this.initData();
    }
    ;
  },
  methods: {
    // 初始化数据
    initData: function initData() {
      var orgObj = getUserOrgInfoDealerOem(this.bizType);
      this.searchForm.orgCode = orgObj.salesOrgCode;
      this.searchForm.orgName = orgObj.salesOrgName;
    },
    // 机构树点击事件
    handleNodeClick: function handleNodeClick(data, node, target) {
      this.searchForm.orgCode = data.salesOrgCode;
      this.searchForm.orgName = data.salesOrgName;
      this.searchForm.areaType = data.salesOrgType;
    },
    // 日期选择事件
    getPickersDate: function getPickersDate(name, val) {
      if (val && val.length > 1) {
        this[name] = val;
      } else {
        this[name] = [];
      }
    },
    // 重置搜索表单
    resetSearch: function resetSearch() {
      this.searchForm = {
        orgName: this.bizType === 'OEM' ? '' : this.searchForm.orgName,
        orgCode: this.bizType === 'OEM' ? '' : this.searchForm.orgCode
      };
      this.orgForm = {
        salesOrgName: this.bizType === 'OEM' ? '全部' : '',
        salesOrgType: '',
        salesOrgCode: ''
      };
      this.entryTime = [getCurrentDate(), getCurrentDate()];
    },
    // 搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    // 刷新
    breakData: function breakData() {
      this.loadData();
    },
    // 获取列表数据
    loadData: function loadData() {
      var _this = this;
      if (this.entryTime) {
        this.searchForm.startEntryTime = this.entryTime[0] && this.entryTime[0].split(' ')[0] + ' 00:00:00' || null;
        this.searchForm.endEntryTime = this.entryTime[1] && this.entryTime[1].split(' ')[0] + ' 23:59:59' || null;
      }
      this.loading = true;
      this.showViewDialog = false;
      vehiclesApi.getCameraRecordList(this.listQuery.pageNum, this.listQuery.pageSize, this.searchForm).then(function (res) {
        _this.loading = false;
        if (res.code === 200) {
          _this.tableData = res.data.list;
          _this.total = res.data.page.recordsTotal;
        }
        ;
      }).catch(function (err) {
        _this.loading = false;
      });
    },
    // 导出
    exportExcels: function exportExcels() {
      var exporParams = {
        total: this.total,
        fileName: '识别记录',
        exportUrl: '/oc-core-service/callIn/cameraRecord',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    // 查看
    viewDetail: function viewDetail(item) {
      this.showViewDialog = true;
      this.viewForm = {
        imageIdList: []
      };
      this.srcList = [];
      this.getDetails(item.cameraRecordId);
    },
    getDetails: function getDetails(id) {
      var _this2 = this;
      vehiclesApi.getCameraRecordInfo(id).then(function (res) {
        if (res.code === 200 && res.data) {
          _this2.viewForm = res.data;
          _this2.$set(_this2.viewForm, 'imgBox', []);
          _this2.viewForm.imageIdList.forEach(function (val) {
            _this2.downloadFile({
              fileStorageId: val.fileStorageId,
              fileUrl: val.fileUrl
            });
          });
        }
        ;
      });
    },
    downloadFile: function downloadFile(obj) {
      if (!obj.fileStorageId) return;
      this.viewUrl = obj.fileUrl;
      obj.url = this.viewUrl;
      this.viewForm.imgBox.push(obj);
      this.srcList = this.viewForm.imgBox.map(function (item) {
        return item.url;
      });
    },
    // 报价
    offer: function offer(item) {
      var router_path = this.$route.path;
      this.$router.push({
        path: "/proposal/add",
        query: {
          back: router_path,
          plateNo: item.plateNo,
          vin: item.vin
        }
      });
    },
    // 点击，使用id下载文件
    handle: function handle(row) {
      if (row.fileId) {
        downFile(row.fileUrl, row.fileName);
      }
    }
  }
};