var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "app-m-cls " }, [
        _c(
          "div",
          { staticClass: "table-cls normal-btn-cls" },
          [
            _c(
              "el-card",
              { staticClass: "box-card", attrs: { shadow: "never" } },
              [
                _c(
                  "div",
                  {
                    staticClass: "search-box",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v("所属机构：")]),
                        _c("OemSelect", {
                          attrs: {
                            canClickArea: true,
                            disabled: _vm.bizType !== "OEM",
                            searchForm: _vm.orgForm,
                          },
                          on: { nodeClick: _vm.nodeClick },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v("政策名称：")]),
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "请选择",
                            },
                            model: {
                              value: _vm.searchForm.couponActivityName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.searchForm,
                                  "couponActivityName",
                                  $$v
                                )
                              },
                              expression: "searchForm.couponActivityName",
                            },
                          },
                          _vm._l(_vm.allActivity, function (item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: {
                                label: item.dictName,
                                value: item.dictName,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v("发放状态：")]),
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "请选择",
                            },
                            model: {
                              value: _vm.searchForm.hitResult,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "hitResult", $$v)
                              },
                              expression: "searchForm.hitResult",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "通过", value: true },
                            }),
                            _c("el-option", {
                              attrs: { label: "不通过", value: false },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v("规则编码：")]),
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入规则编码",
                            clearable: "",
                          },
                          model: {
                            value: _vm.searchForm.couponActivityRuleCode,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.searchForm,
                                "couponActivityRuleCode",
                                $$v
                              )
                            },
                            expression: "searchForm.couponActivityRuleCode",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              icon: "el-icon-refresh-left",
                            },
                            on: { click: _vm.resetSearch },
                          },
                          [_vm._v("重置")]
                        ),
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "8px" },
                            attrs: {
                              size: "mini",
                              type: "primary",
                              icon: "el-icon-search",
                            },
                            on: { click: _vm.searchData },
                          },
                          [_vm._v("查询")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "search-btn" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: { click: _vm.breakData },
                          },
                          [_vm._v("刷新")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: { click: _vm.exportFn },
                          },
                          [_vm._v("导出")]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    ref: "multipleTable",
                    staticStyle: { width: "100%" },
                    attrs: {
                      "summary-method": _vm.getSummaries,
                      "show-summary": "",
                      size: "mini",
                      border: "",
                      data: _vm.tableData,
                      height: _vm.tableHeightJD,
                      "header-cell-style": { background: "#F7F7F7" },
                      "highlight-current-row": "",
                    },
                    on: { "sort-change": _vm.sortChange },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "couponActivityName",
                        width: "260px",
                        label: "政策名称",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "120px",
                        prop: "couponActivityRuleCode",
                        label: "规则编码",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        width: "120px",
                        prop: "couponActivityRuleName",
                        label: "规则名称",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm.bizType === "OEM"
                      ? _c("el-table-column", {
                          attrs: {
                            prop: "orgCode",
                            label: "机构编码",
                            "show-overflow-tooltip": "",
                          },
                        })
                      : _vm._e(),
                    _vm.bizType === "OEM"
                      ? _c("el-table-column", {
                          attrs: {
                            prop: "orgName",
                            width: "100px",
                            label: "机构名称",
                            "show-overflow-tooltip": "",
                          },
                        })
                      : _vm._e(),
                    _c("el-table-column", {
                      attrs: {
                        prop: "couponReceiveWayDisplay",
                        label: "领取方式",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "orgCountTotal",
                        label: "上限数量",
                        align: "right",
                        width: "120px",
                        "show-overflow-tooltip": "",
                        sortable: "custom",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(scope.row.orgCountTotal || "-") +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "orgCountLeft",
                        label: "剩余数量",
                        align: "right",
                        width: "120px",
                        "show-overflow-tooltip": "",
                        sortable: "custom",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(scope.row.orgCountLeft || "-") +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "count",
                        label: "已用数量",
                        align: "right",
                        width: "120px",
                        "show-overflow-tooltip": "",
                        sortable: "custom",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " + _vm._s(scope.row.count || "-") + " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c(
                      "el-table-column",
                      {
                        attrs: {
                          prop: "usedRate",
                          align: "right",
                          width: "140px",
                          "show-overflow-tooltip": "",
                          sortable: "custom",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " + _vm._s(scope.row.usedRate || "-") + " "
                                ),
                              ]
                            },
                          },
                        ]),
                      },
                      [
                        _c(
                          "template",
                          { slot: "header" },
                          [
                            _vm._v(" 已使用占比 "),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  placement: "top",
                                  content:
                                    "已使用占比=（已用数量/上限数量）*100%",
                                },
                              },
                              [_c("i", { staticClass: "el-icon-info" })]
                            ),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                    _c("el-table-column", {
                      attrs: {
                        prop: "totalAmount",
                        label: "总金额(元)",
                        width: "120px",
                        align: "right",
                        "show-overflow-tooltip": "",
                        sortable: "custom",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " + _vm._s(scope.row.totalAmount || "-") + " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "countPolicy",
                        label: "保单数量",
                        align: "right",
                        width: "120px",
                        "show-overflow-tooltip": "",
                        sortable: "custom",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " + _vm._s(scope.row.countPolicy || "-") + " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "countBaseData",
                        label: "基盘数量",
                        align: "right",
                        width: "120px",
                        "show-overflow-tooltip": "",
                        sortable: "custom",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(scope.row.countBaseData || "-") +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "newCarRate",
                        label: "新车投保率%",
                        align: "right",
                        width: "130px",
                        "show-overflow-tooltip": "",
                        sortable: "custom",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " + _vm._s(scope.row.newCarRate || "-") + " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "littleOldCarRate",
                        align: "right",
                        label: "次新车投保率%",
                        width: "140px",
                        "show-overflow-tooltip": "",
                        sortable: "custom",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(scope.row.littleOldCarRate || "-") +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "rateIncreaseSameTime",
                        align: "right",
                        label: "续保率同比增长%",
                        width: "150px",
                        "show-overflow-tooltip": "",
                        sortable: "custom",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    scope.row.rateIncreaseSameTime || "-"
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "renewalRate",
                        align: "right",
                        label: "综合续保率%",
                        width: "130px",
                        "show-overflow-tooltip": "",
                        sortable: "custom",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " + _vm._s(scope.row.renewalRate || "-") + " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "统计时间",
                        width: "150px",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " + _vm._s(scope.row.operateTime || "-") + " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "发放状态",
                        fixed: "right",
                        width: "90px",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.hitResult
                                ? _c(
                                    "span",
                                    { staticStyle: { color: "green" } },
                                    [_vm._v("通过")]
                                  )
                                : _c(
                                    "span",
                                    { staticStyle: { color: "red" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          scope.row.couponReceiveWay !== "CAR"
                                            ? "不通过"
                                            : ""
                                        )
                                      ),
                                    ]
                                  ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "操作",
                        align: "center",
                        width: "80px",
                        fixed: "right",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.couponActivityTarget == "DEALER" &&
                              scope.row.couponReceiveWay == "CAR"
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.viewDetail(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("详情")]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c("pagination", {
                  attrs: {
                    total: _vm.total,
                    page: _vm.listQuery.pageNum,
                    limit: _vm.listQuery.pageSize,
                  },
                  on: {
                    "update:page": function ($event) {
                      return _vm.$set(_vm.listQuery, "pageNum", $event)
                    },
                    "update:limit": function ($event) {
                      return _vm.$set(_vm.listQuery, "pageSize", $event)
                    },
                    pagination: function ($event) {
                      return _vm.loadData()
                    },
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showDialog,
            "close-on-click-modal": false,
            width: "1000px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
            handleClose: function ($event) {
              _vm.showDialog = false
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("详情")]
              ),
            ]
          ),
          _c("div", { staticClass: "app-m-cls" }, [
            _c(
              "div",
              { staticClass: "table-cls normal-btn-cls" },
              [
                _c(
                  "el-card",
                  { staticClass: "box-card", attrs: { shadow: "never" } },
                  [
                    _c("div", { attrs: { slot: "header" }, slot: "header" }),
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.tableDetailLoading,
                            expression: "tableDetailLoading",
                          },
                        ],
                        ref: "multipleTable",
                        staticStyle: { width: "100%" },
                        attrs: {
                          size: "mini",
                          border: "",
                          data: _vm.tableDetailData,
                          height: 500,
                          "header-cell-style": { background: "#F7F7F7" },
                          "highlight-current-row": "",
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            width: "120px",
                            label: "大车型续保数量",
                            align: "right",
                            "show-overflow-tooltip": "",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        scope.row.countLittleOldRenewalBig ||
                                          "-"
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            width: "120px",
                            label: "小车型续保数量",
                            align: "right",
                            "show-overflow-tooltip": "",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        scope.row.countLittleOldRenewalLittle ||
                                          "-"
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            width: "100px",
                            label: "续保合计",
                            align: "right",
                            "show-overflow-tooltip": "",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        scope.row.countLittleOldRenewalTotal ||
                                          "-"
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            width: "100px",
                            label: "政策基础台数",
                            align: "right",
                            "show-overflow-tooltip": "",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(scope.row.numPayBase || "-") +
                                      " "
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            width: "110px",
                            label: "最大政策台数",
                            align: "right",
                            "show-overflow-tooltip": "",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(scope.row.numPayMax || "-") +
                                      " "
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "政策上限台数",
                            width: "110px",
                            align: "right",
                            "show-overflow-tooltip": "",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(scope.row.numPayLimit || "-") +
                                      " "
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "大车型占比%",
                            width: "100px",
                            align: "right",
                            "show-overflow-tooltip": "",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(scope.row.rateNumPayBig || "-") +
                                      " "
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "小车型占比%",
                            width: "100px",
                            align: "right",
                            "show-overflow-tooltip": "",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        scope.row.rateNumPayLittle || "-"
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "大车型合计",
                            width: "90px",
                            align: "right",
                            "show-overflow-tooltip": "",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(scope.row.numPayBig || "-") +
                                      " "
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "小车型合计",
                            align: "right",
                            width: "90px",
                            "show-overflow-tooltip": "",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(scope.row.numPayLittle || "-") +
                                      " "
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "合计",
                            align: "right",
                            width: "60px",
                            "show-overflow-tooltip": "",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(scope.row.numPayMax || "-") +
                                      " "
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            align: "right",
                            label: "单台大车型政策金额",
                            width: "140px",
                            "show-overflow-tooltip": "",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(scope.row.amountBig || "-") +
                                      " "
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            align: "right",
                            label: "单台小车型政策金额",
                            width: "140px",
                            "show-overflow-tooltip": "",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(scope.row.amountLittle || "-") +
                                      " "
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            align: "right",
                            label: "大车型政策金额合计",
                            width: "140px",
                            "show-overflow-tooltip": "",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(scope.row.amountTotalBig || "-") +
                                      " "
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            align: "right",
                            label: "小车型政策金额合计",
                            width: "140px",
                            "show-overflow-tooltip": "",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        scope.row.amountTotalLittle || "-"
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "统计时间",
                            width: "150px",
                            "show-overflow-tooltip": "",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(scope.row.operateTime || "-") +
                                      " "
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }