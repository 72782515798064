import _toConsumableArray from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.find.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import dealerRegional from '@/api/dealerRegional';
import inscorpdiffconfig from '@/api/inscorpdiffconfig';
import { isEmptyObject } from '@/utils/common';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    currentType: {
      type: String,
      default: ''
    },
    currentStatus: {
      type: String,
      default: ''
    },
    currentData: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      form: {
        areaName: '',
        provinceCode: '',
        provinceList: [],
        cityList: []
      },
      rules: {
        provinceCode: [{
          required: true,
          message: '请选择所属省份',
          trigger: 'change'
        }],
        provinceList: [{
          required: true,
          message: '请选择下属省份',
          trigger: 'change'
        }],
        cityList: [{
          required: true,
          message: '请选择下属城市',
          trigger: 'change'
        }]
      },
      provinceOption: [],
      provinceManageOption: [],
      userInfo: JSON.parse(localStorage.getItem('userInfo')) || {},
      bizType: localStorage.getItem('bizType') || '',
      cityOption: []
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    // 初始化数据
    initData: function initData() {
      var _this = this;
      this.form = {
        areaName: '',
        provinceCode: '',
        provinceList: [],
        cityList: []
      };
      this.provinceOption = [];
      this.provinceManageOption = [];
      this.cityOption = [];
      this.$refs.detail.resetFields();
      if (this.currentStatus === "add") {
        // this.$refs.detail.resetFields();
      } else {
        if (this.currentType === 'province') {
          this.form.areaName = this.currentData.name;
          this.form.areaId = this.currentData.id;
          this.currentData.child.forEach(function (item) {
            _this.form.provinceList.push(item.id);
          });
        } else {
          this.form.areaName = this.currentData.name;
          this.form.areaId = this.currentData.id;
          this.fetchCitySuperior(this.form.areaId);
          this.currentData.child.forEach(function (item) {
            _this.form.cityList.push(item.id);
          });
        }
      }
      if (this.currentType === 'province') {
        this.fetchProvince();
      } else {
        this.fetchProvinceManage();
      }
    },
    // 获取省级区域的省级列表
    fetchProvince: function fetchProvince() {
      var _this2 = this;
      var oemCode = this.userInfo.orgCode || '';
      dealerRegional.getProvinceList(oemCode).then(function (res) {
        if (res.code === 200) {
          _this2.provinceOption = res.data;
          var fatherprovinceData = [];
          if (_this2.currentStatus === "edit") {
            fatherprovinceData = _this2.currentData.child.map(function (item) {
              return {
                provinceCode: item.id,
                provinceName: item.name
              };
            });
          }
          _this2.provinceOption = [].concat(_toConsumableArray(fatherprovinceData), _toConsumableArray(_this2.provinceOption));
        }
      });
    },
    // 获取市级区域的市级列表
    fetchCity: function fetchCity(val, isEdit) {
      var _this3 = this;
      dealerRegional.getCityList({
        provinceCode: val
      }).then(function (res) {
        if (res.code === 200) {
          _this3.cityOption = res.data;
          var fatherCityData = [];
          if (_this3.currentStatus === "edit" && isEdit) {
            fatherCityData = _this3.currentData.child.map(function (item) {
              return {
                cityCode: item.id,
                cityName: item.name
              };
            });
          }
          _this3.cityOption = [].concat(_toConsumableArray(fatherCityData), _toConsumableArray(_this3.cityOption));
        }
      });
    },
    // 获取市级区域的省级
    fetchProvinceManage: function fetchProvinceManage() {
      var _this4 = this;
      inscorpdiffconfig.getCity({
        districtId: 0
      }).then(function (res) {
        if (res.code === 200) {
          _this4.provinceManageOption = res.data;
        }
      });
    },
    // 获取市级区域的上级列表
    fetchCitySuperior: function fetchCitySuperior(id) {
      var _this5 = this;
      dealerRegional.getCitySuperior({
        areaId: id
      }).then(function (res) {
        if (res.code === 200) {
          _this5.form.provinceCode = res.data.find(function (val) {
            return val.superiorStatus;
          }).provinceCode;
          _this5.provinceChange(_this5.form.provinceCode, true);
        }
      });
    },
    //关闭弹窗
    closePage: function closePage() {
      this.$emit('closePage');
    },
    // 省级切换
    provinceChange: function provinceChange(val) {
      var isEdit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      if (!isEdit) {
        this.form.cityList = [];
      }
      if (val) {
        this.fetchCity(val, isEdit);
      } else {
        this.cityOption = [];
      }
    },
    // 保存
    save: function save() {
      var _this6 = this;
      //保存数据
      this.$refs.detail.validate(function (valid) {
        if (valid) {
          if (_this6.bizType === 'OEM' && !_.isEmpty(_this6.userInfo)) {
            _this6.form.oemCode = _this6.userInfo.orgCode;
          }
          if (_this6.currentStatus === "add") {
            // 新增省市区域
            var req = _this6.currentType === 'province' ? dealerRegional.addProvince(_this6.form) : dealerRegional.addCity(_this6.form);
            req.then(function (res) {
              if (res.code === 200) {
                _this6.$message.success(res.msg);
                _this6.closePage();
              }
            });
          } else {
            // 编辑省市区域
            var _req = _this6.currentType === 'province' ? dealerRegional.updateProvince(_this6.form) : dealerRegional.updateCity(_this6.form);
            _req.then(function (res) {
              if (res.code === 200) {
                _this6.$message.success(res.msg);
                _this6.closePage();
              }
            });
          }
        }
      });
    }
  }
};