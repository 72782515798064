//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '../../../mixins/initHeight';
import dict from '../../../mixins/dict';
import proposalRequest from '@/api/insuranceslip';
export default {
  mixins: [initHeight, dict],
  props: {
    vin: {
      type: String,
      default: null
    }
  },
  watch: {
    vin: {
      handler: function handler(newValue, oldValue) {
        this.fetchData();
      },
      deep: true
    }
  },
  data: function data() {
    return {
      listData: [],
      listLoading: true,
      total: 0,
      pageNum: 1,
      pageSize: 20
    };
  },
  created: function created() {},
  mounted: function mounted() {
    // this.fetchData();
  },
  methods: {
    getStatusColor: function getStatusColor(proposalStatus) {
      //获取投保状态对应的颜色值
      var color = '';
      switch (proposalStatus) {
        case '03':
        case '08':
        case '12':
          color = '#a0a0a0';
          break;
        case '01':
        case '02':
        case '04':
        case '05':
        case '06':
        case '07':
        case '09':
        case '10':
          color = '#e4a547';
          break;
        case '11':
          color = '#16c86c';
          break;
        default:
          color = '#515151';
          break;
      }
      return color;
    },
    handleSizeChange: function handleSizeChange(val) {
      //分页查询事件
      this.pageNum = 1;
      this.pageSize = val;
      this.fetchData();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      //分页查询事件
      this.pageNum = val;
      this.fetchData();
    },
    fetchData: function fetchData() {
      var _this = this;
      //初始化数据列表
      this.listLoading = false;
      proposalRequest.getList(this.pageNum, this.pageSize, {
        frameNo: this.vin
      }).then(function (res) {
        _this.listLoading = false;
        if (!_.isEmpty(res.data)) {
          _this.listData = res.data.list;
          //总条数
          _this.total = res.data.page.recordsTotal;
        }
      }).catch(function (err) {
        _this.listLoading = false;
      });
    }
  }
};