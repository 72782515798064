import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import Pagination from '@/components/Pagination';
import requestApi from '@/api/interactPlatform';
import AddAccessConfig from './dialog/addAccessConfig';
export default {
  name: 'ExternalAccessConfig',
  components: {
    Pagination: Pagination,
    AddAccessConfig: AddAccessConfig
  },
  mixins: [initHeight],
  data: function data() {
    return {
      searchForm: {},
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      multipleSelection: [],
      loading: false,
      showDialog: false,
      opType: '',
      currentId: '',
      pushDataList: [],
      dataConverterList: []
    };
  },
  created: function created() {
    this.loadData();
    this.getDataConverterList();
    this.getPushDataList();
  },
  methods: {
    getDataConverterList: function getDataConverterList() {
      var _this = this;
      requestApi.getDataConverter().then(function (res) {
        if (res.code == 200) {
          _this.dataConverterList = res.data;
        }
        ;
      });
    },
    getPushDataList: function getPushDataList() {
      var _this2 = this;
      requestApi.getPushData().then(function (res) {
        if (res.code == 200) {
          _this2.pushDataList = res.data;
        }
        ;
      });
    },
    //删除
    batchDelete: function batchDelete(item) {
      var _this3 = this;
      var ids = [];
      if (!_.isEmpty(item)) {
        ids.push(item.inputConfigId);
      } else {
        if (!_.isEmpty(this.multipleSelection)) {
          ids = _.map(this.multipleSelection, 'inputConfigId');
        }
        ;
      }
      ;
      this.$confirm('您确定要删除么？', {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        requestApi.deleteInputconfig(ids).then(function (res) {
          if (res.code === 200) {
            _this3.$message.success(res.msg);
            _this3.loadData();
          }
          ;
        });
      });
    },
    //重置搜索表单
    resetSearch: function resetSearch() {
      this.searchForm = {};
    },
    //搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    // 刷新
    breakData: function breakData() {
      this.loadData();
    },
    //获取列表数据
    loadData: function loadData() {
      var _this4 = this;
      this.loading = true;
      this.showDialog = false;
      requestApi.getInputconfigList(this.listQuery.pageNum, this.listQuery.pageSize, this.searchForm).then(function (res) {
        _this4.loading = false;
        if (res.code === 200) {
          _this4.tableData = res.data.list;
          _this4.total = res.data.page.recordsTotal;
        }
        ;
      }).catch(function (err) {
        _this4.loading = false;
      });
    },
    //列表行选择事件
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //编辑
    editClicked: function editClicked(type, item) {
      this.opType = type;
      this.showDialog = true;
      this.currentId = item.inputConfigId.toString();
    },
    //新增
    add: function add(type) {
      this.opType = type;
      this.showDialog = true;
    }
  }
};