var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login" }, [
    _c(
      "div",
      { staticClass: "rowBox" },
      [
        _c(
          "el-row",
          [
            _c("el-col", { attrs: { span: 24 } }, [
              _c("div", { staticClass: "title" }, [_vm._v(" 注销审核 ")]),
            ]),
            _c(
              "el-form",
              {
                ref: "ruleForm",
                staticClass: "demo-ruleForm",
                attrs: {
                  model: _vm.ruleForm,
                  rules: _vm.rules,
                  "label-width": "110px",
                },
              },
              [
                _c(
                  "el-col",
                  { attrs: { xs: 22, lg: 24 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "验证码:",
                          align: "left",
                          prop: "verifyCode",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            size: "mini",
                            placeholder: "请在邮件中查看验证码",
                            maxlength: "4",
                          },
                          model: {
                            value: _vm.ruleForm.verifyCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "verifyCode", $$v)
                            },
                            expression: "ruleForm.verifyCode",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { xs: 22, lg: 24 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "审核人姓名:", prop: "verifyName" } },
                      [
                        _c("el-input", {
                          attrs: {
                            size: "mini",
                            placeholder: "请输入审核人姓名",
                          },
                          model: {
                            value: _vm.ruleForm.verifyName,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "verifyName", $$v)
                            },
                            expression: "ruleForm.verifyName",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { xs: 22, lg: 24 } },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: "审核人手机号:", prop: "verifyPhone" },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            size: "mini",
                            placeholder: "请输入审核人手机号",
                            maxlength: "11",
                          },
                          model: {
                            value: _vm.ruleForm.verifyPhone,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "verifyPhone", $$v)
                            },
                            expression: "ruleForm.verifyPhone",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { staticClass: "buttonBox", attrs: { xs: 22, lg: 24 } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.save("ruleForm")
                          },
                        },
                      },
                      [_vm._v("确认进入")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }