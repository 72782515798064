//
//
//
//
//
//
//
//
//
//
//

import request from '@/api/message';
import storageRequest from '@/api/storage';
import { saveFile } from '@/utils/common';
export default {
  props: {
    currentId: {
      type: String,
      default: ''
    },
    refresh: {
      type: Boolean,
      default: true
    },
    msgAnnouncementStatus: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      fileList: [],
      formLoading: false,
      uploadStatus: '',
      form: {
        messageTitle: '',
        createDatetime: '',
        messageContent: '',
        messageFile: []
      }
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
    }
  },
  mounted: function mounted() {},
  created: function created() {
    // this.initData();
  },
  methods: {
    initData: function initData() {},
    //关闭弹窗
    closePage: function closePage() {
      this.$emit('closePage');
    }
  }
};