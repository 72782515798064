import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import _createForOfIteratorHelper from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import InsuranceServicePlanRequest from '@/api/InsuranceServicePlan';
import initHeight from '@/mixins/initHeight';
import Pagination from '@/components/Pagination';
import addDiadlog from './add';
import dictRequest from '@/api/dictionary';
export default {
  name: "InsuranceServiceSetting",
  mixins: [initHeight],
  components: {
    Pagination: Pagination,
    addDiadlog: addDiadlog
  },
  data: function data() {
    return {
      DialogType: null,
      SpecialId: null,
      DialogId: null,
      drawer: true,
      loading: true,
      showAddDialog: false,
      routerTypeList: [{
        routerName: 'GET'
      }, {
        routerName: 'POST'
      }, {
        routerName: 'PUT'
      }, {
        routerName: 'DELETE'
      }],
      searchForm: {},
      tableData: [],
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      DialogItem: {},
      multipleSelection: [],
      riskTypeList: [],
      insuranceSchemeRiskList: []
    };
  },
  created: function created() {
    this.getInsuranceSchemeRiskList(); //获取方案列表
  },
  mounted: function mounted() {
    this.getInsuranceFeeList(); //获取费用列表
    this.getDictItemsByCode();
  },
  methods: {
    getInsuranceSchemeRiskList: function getInsuranceSchemeRiskList() {
      var _this = this;
      var data = {
        pageNum: 1,
        pageSize: 100
      };
      InsuranceServicePlanRequest.getInsuranceschemeList(data).then(function (res) {
        _this.loading = false;
        if (res.code == 200) {
          _this.insuranceSchemeRiskList = res.data.list ? res.data.list : [];
        }
      }).catch(function (err) {
        _this.loading = false;
      });
    },
    filterInsuranceSchemeId: function filterInsuranceSchemeId(id) {
      var _this2 = this;
      var schemeName = '';
      setTimeout(function () {
        var _iterator = _createForOfIteratorHelper(_this2.insuranceSchemeRiskList),
          _step;
        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var i = _step.value;
            if (i.insuranceSchemeId == id) {
              console.log(i);
              schemeName = i.schemeName;
            }
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
      }, 100);
      return schemeName;
    },
    getDictItemsByCode: function getDictItemsByCode() {
      var _this3 = this;
      //获取险种类型
      dictRequest.getDictItemsByCode("RISK_TYPE").then(function (res) {
        if (res.data) {
          _this3.riskTypeList = res.data;
        }
      });
    },
    getInsuranceFeeList: function getInsuranceFeeList() {
      var _this4 = this;
      //获取数据列表
      this.loading = true;
      var data = _objectSpread(_objectSpread({}, this.listQuery), this.searchForm);
      InsuranceServicePlanRequest.searchDetail(data).then(function (res) {
        _this4.loading = false;
        if (res.code == 200) {
          _this4.tableData = res.data && res.data.list ? res.data.list : [];
          _this4.total = res.data.page.recordsTotal;
        }
      }).catch(function (err) {
        _this4.loading = false;
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      //表格选中操作
      this.multipleSelection = val;
    },
    batchDeletion: function batchDeletion() {
      var _this5 = this;
      //批量删除
      this.$confirm('是否确认删除, 确认删除后不能恢复?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        if (_this5.multipleSelection) {
          var ids = [];
          _this5.multipleSelection.map(function (item) {
            ids.push(item.insuranceSchemeId);
          });
          _this5.deleteData(ids);
        }
        // 暂时缺接口
      }).catch(function () {});
    },
    resetSearch: function resetSearch() {
      // 重置
      this.searchForm = {
        modelNo: ''
      };
      this.listQuery.pageNum = 1;
      this.getInsuranceFeeList();
    },
    searchData: function searchData() {
      // 查询
      this.getInsuranceFeeList();
    },
    showAddDialogClicked: function showAddDialogClicked(type) {
      //新增
      this.showAddDialog = true;
      this.DialogType = type;
    },
    // 查看
    toView: function toView(type, row) {
      this.showAddDialog = true;
      this.DialogType = type;
      this.SpecialId = row.specialId.toString();
    },
    // 编辑
    editConfig: function editConfig(type, row) {
      this.showAddDialog = true;
      this.DialogType = type;
      // this.DialogId = row.insuranceSchemeId.toString();
      this.DialogItem = row;
    },
    updateItem: function updateItem(row) {//是否启用
    },
    deleteRoute: function deleteRoute(row) {
      var _this6 = this;
      // 删除二次确认
      this.$confirm('是否删除当前数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this6.deleteData(row.serviceId);
      }).catch(function () {});
    },
    deleteData: function deleteData(ids) {
      var _this7 = this;
      // 删除api
      InsuranceServicePlanRequest.delAllSetting(ids).then(function (res) {
        if (res.code == 200) {
          _this7.$message.success(res.msg);
        }
        _this7.getInsuranceFeeList();
      });
    },
    // 关闭弹窗
    loadData: function loadData() {
      this.showAddDialog = false;
      this.DialogType = null;
      // this.listQuery.pageNum = 1;
      this.getInsuranceFeeList();
    }
  }
};