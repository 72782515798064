import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from "@/mixins/initHeight";
import dict from "@/mixins/dict";
import Pagination from "@/components/Pagination";
import DatePicker from "@/components/DatePicker";
import activityCenterRequest from "@/api/activityCenter";
import { commonExport } from '@/utils/common';
export default {
  name: "ActivityManagement",
  components: {
    Pagination: Pagination,
    DatePicker: DatePicker
  },
  mixins: [initHeight, dict],
  computed: {},
  data: function data() {
    return {
      searchForm: {},
      form: {},
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      couponActivityTime: [],
      tableData: [],
      loading: false,
      showDialog: false,
      opType: "",
      multipleSelection: [],
      isShow: true,
      bizType: localStorage.getItem('bizType') || ''
    };
  },
  watch: {
    '$route': {
      handler: function handler() {
        this.isShow = this.$route.name == 'ActivityManagement';
      },
      immediate: true
    }
  },
  mounted: function mounted() {
    this.loadData();
  },
  methods: {
    // 结算活动
    settlementActivities: function settlementActivities(item) {
      var _this = this;
      var req = item.couponActivitySettlementState !== 'NONE' ? activityCenterRequest.withdrawSettlementActivities([item.couponActivityId]) : activityCenterRequest.settlementActivities([item.couponActivityId]);
      req.then(function (res) {
        if (res.code == 200) {
          _this.loadData();
        }
      });
    },
    handlePolicySelectionChange: function handlePolicySelectionChange(val) {
      this.multiplePolicySelection = val;
    },
    selectable: function selectable(row) {
      return !row.enable;
    },
    //撤回
    withdraw: function withdraw(item) {
      var _this2 = this;
      this.$confirm('是否确认撤回该政策?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        activityCenterRequest.withdraw([item.couponActivityId]).then(function (res) {
          if (res.code == 200) {
            _this2.$message.success(res.msg);
            _this2.loadData();
          }
        });
      }).catch(function () {});
    },
    //多选
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //发布
    releaseActivity: function releaseActivity() {
      var _this3 = this;
      var ids = _.map(this.multipleSelection, "couponActivityId");
      activityCenterRequest.releaseActivity(ids).then(function (res) {
        if (res.code == 200) {
          _this3.$message.success(res.msg);
          _this3.loadData();
        }
      });
    },
    // 日期选择事件
    getPickersDate: function getPickersDate(name, val) {
      if (val && val.length > 1) {
        this[name] = val;
      } else {
        this[name] = [];
      }
    },
    // 导出
    exportFn: function exportFn() {
      if (this.couponActivityTime) {
        this.searchForm.couponActivityStartTimeStart = this.couponActivityTime[0] ? this.couponActivityTime[0].split(' ')[0] + ' 00:00:00' : null;
        this.searchForm.couponActivityStartTimeEnd = this.couponActivityTime[1] ? this.couponActivityTime[1].split(' ')[0] + ' 23:59:59' : null;
      }
      var exporParams = {
        total: this.total,
        limitNum: 200000,
        fileName: '政策管理',
        exportUrl: '/oc-core-service/couponactivity/search',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    //查看详情
    viewDetail: function viewDetail(type, item) {
      this.opType = type;
      this.$router.push({
        path: "/activityCenter/activityManagement/detail/view",
        query: {
          id: item.couponActivityId,
          opType: this.opType,
          tabName: "政策详情-" + item.couponActivityName,
          isShow: this.isShow
        }
      });
    },
    //新增
    createActivity: function createActivity(type) {
      this.opType = type;
      this.$router.push({
        path: "/activityCenter/activityManagement/detail",
        query: {
          opType: this.opType,
          tabName: "新增政策",
          isShow: this.isShow
        }
      });
      sessionStorage.removeItem("activityDetailForm");
    },
    //重置搜索表单
    resetSearch: function resetSearch() {
      this.searchForm = {};
      this.couponActivityTime = [];
    },
    //搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    //获取列表数据
    loadData: function loadData() {
      var _this4 = this;
      this.loading = true;
      this.showImportDialog = false;
      this.showDialog = false;
      if (this.couponActivityTime) {
        this.searchForm.couponActivityStartTimeStart = this.couponActivityTime[0] ? this.couponActivityTime[0].split(' ')[0] + ' 00:00:00' : null;
        this.searchForm.couponActivityStartTimeEnd = this.couponActivityTime[1] ? this.couponActivityTime[1].split(' ')[0] + ' 23:59:59' : null;
      }
      activityCenterRequest.getActivityManagementList(_objectSpread(_objectSpread({}, this.searchForm), this.listQuery)).then(function (res) {
        if (res.code === 200) {
          _this4.tableData = res.data.list;
          _this4.loading = false;
          _this4.total = res.data.page.recordsTotal;
        }
      }).catch(function (err) {
        _this4.loading = false;
      });
    },
    // 刷新
    breakData: function breakData() {
      // this.resetSearch()
      this.loadData();
    }
  }
};