var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-m-cls", staticStyle: {} }, [
    _c(
      "div",
      {
        ref: "header",
        staticClass: "header-cls",
        staticStyle: { "max-height": "500px", overflow: "auto" },
      },
      [
        _c(
          "el-card",
          { attrs: { shadow: "never" } },
          [
            _c(
              "el-form",
              {
                ref: "detail",
                staticStyle: { "margin-bottom": "-10px" },
                attrs: {
                  model: _vm.form,
                  rules: _vm.Rules,
                  "label-width": "110px",
                  size: "mini",
                },
              },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "应用机构", prop: "useOrgCode" } },
                      [
                        _c(
                          "el-select",
                          {
                            ref: "orgSelect",
                            staticStyle: { width: "100%" },
                            attrs: {
                              filterable: "",
                              placeholder: "请选择应用机构",
                            },
                            model: {
                              value: _vm.form.useOrgCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "useOrgCode", $$v)
                              },
                              expression: "form.useOrgCode",
                            },
                          },
                          _vm._l(_vm.SubDealerList, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: {
                                label: item.dealerName,
                                value: item.dealerCode,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "优先级：", prop: "priority" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入优先级", clearable: "" },
                          model: {
                            value: _vm.form.priority,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "priority", $$v)
                            },
                            expression: "form.priority",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "分配方式：",
                          prop: "distributionType",
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { placeholder: "请选择分配方式" },
                            model: {
                              value: _vm.form.distributionType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "distributionType", $$v)
                              },
                              expression: "form.distributionType",
                            },
                          },
                          _vm._l(_vm.DistributeType, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: {
                                label: item.itemName,
                                value: item.itemCode,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _vm.form.distributionType == "ALTERNATE_DISTRIBUTION"
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "接车机构：", prop: "val4" } },
                          [
                            _c(
                              "el-select",
                              {
                                ref: "orgSelect",
                                staticStyle: { width: "100%" },
                                attrs: {
                                  filterable: "",
                                  placeholder: "请选择接车机构",
                                },
                                on: { change: _vm.getDealer },
                                model: {
                                  value: _vm.form.conductorOrgCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "conductorOrgCode", $$v)
                                  },
                                  expression: "form.conductorOrgCode",
                                },
                              },
                              _vm._l(_vm.SubDealerList, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.dealerName,
                                    value: item.dealerCode,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _vm.form.distributionType == "ALTERNATE_DISTRIBUTION"
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "接车员：", prop: "val4" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  multiple: "",
                                  clearable: "",
                                  placeholder: "请选择接车员",
                                },
                                model: {
                                  value: _vm.form.conductorUserIdList,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "conductorUserIdList",
                                      $$v
                                    )
                                  },
                                  expression: "form.conductorUserIdList",
                                },
                              },
                              _vm._l(_vm.Dealerlist, function (item) {
                                return _c("el-option", {
                                  key: item.userId,
                                  attrs: {
                                    label: item.userName,
                                    value: item.userId.toString(),
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticStyle: {
          display: "flex",
          "justify-content": "center",
          "margin-top": "8px",
        },
      },
      [
        _c(
          "el-button",
          {
            attrs: {
              icon: "el-icon-check",
              type: "primary",
              plain: "",
              size: "mini",
            },
            on: { click: _vm.save },
          },
          [_vm._v("保存")]
        ),
        _c(
          "el-button",
          {
            attrs: { icon: "el-icon-close", size: "mini" },
            on: { click: _vm.closePage },
          },
          [_vm._v("关闭")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }