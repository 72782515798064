var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _c(
      "div",
      {
        staticStyle: {
          padding: "15px",
          "max-height": "400px",
          "overflow-y": "auto",
        },
      },
      [
        _c(
          "div",
          { ref: "repIns", staticStyle: { padding: "10px" } },
          _vm._l(_vm.data.flowNode.productInfoList, function (item, index) {
            return _c("div", { key: index }, [
              _c("div", [
                _c("span", [
                  _vm._v(
                    "系统检测到最新的 " + _vm._s(item.productName) + "起保日期("
                  ),
                ]),
                _c("span", { staticStyle: { color: "#f45050" } }, [
                  _vm._v(
                    _vm._s(item.effectiveEndTime ? item.effectiveEndTime : "")
                  ),
                ]),
                _c("span", [_vm._v("),是否确定修改")]),
                _c("span", { staticStyle: { color: "#f45050" } }, [
                  _vm._v("起保日期"),
                ]),
                _c("span", [_vm._v("重新报价?")]),
              ]),
              item.vin
                ? _c("div", { staticStyle: { padding: "15px 0" } }, [
                    _c("span", [_vm._v("往年保单信息：")]),
                  ])
                : _vm._e(),
              item.vin
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        "background-color": "#f9f9f9",
                        padding: "15px 0",
                      },
                    },
                    [
                      _c("el-row", { attrs: { gutter: 0 } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "line-height": "30px",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  width: "40%",
                                  "text-align": "right",
                                  color: "#8f9696",
                                },
                              },
                              [_vm._v("车牌号：")]
                            ),
                            _c("div", { staticStyle: { width: "60%" } }, [
                              _vm._v(_vm._s(item.plateNo ? item.plateNo : "")),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "line-height": "30px",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  width: "40%",
                                  "text-align": "right",
                                  color: "#8f9696",
                                },
                              },
                              [_vm._v("车架号：")]
                            ),
                            _c("div", { staticStyle: { width: "60%" } }, [
                              _vm._v(_vm._s(item.vin ? item.vin : "")),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "line-height": "30px",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  width: "40%",
                                  "text-align": "right",
                                  color: "#8f9696",
                                },
                              },
                              [_vm._v("发动机号：")]
                            ),
                            _c("div", { staticStyle: { width: "60%" } }, [
                              _vm._v(
                                _vm._s(item.engineNo ? item.engineNo : "")
                              ),
                            ]),
                          ]
                        ),
                        item.confirmCode
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "line-height": "30px",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "40%",
                                      "text-align": "right",
                                      color: "#8f9696",
                                    },
                                  },
                                  [_vm._v("投保确认码：")]
                                ),
                                _c("div", { staticStyle: { width: "60%" } }, [
                                  _vm._v(
                                    _vm._s(
                                      item.confirmCode ? item.confirmCode : ""
                                    )
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "line-height": "30px",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  width: "40%",
                                  "text-align": "right",
                                  color: "#8f9696",
                                },
                              },
                              [_vm._v("投保地区：")]
                            ),
                            _c("div", { staticStyle: { width: "60%" } }, [
                              _vm._v(
                                _vm._s(
                                  item.insuranceArea ? item.insuranceArea : ""
                                )
                              ),
                            ]),
                          ]
                        ),
                        item.policyNo
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "line-height": "30px",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "40%",
                                      "text-align": "right",
                                      color: "#8f9696",
                                    },
                                  },
                                  [_vm._v("保单号：")]
                                ),
                                _c("div", { staticStyle: { width: "60%" } }, [
                                  _vm._v(
                                    _vm._s(item.policyNo ? item.policyNo : "")
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        item.signedDate
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "line-height": "30px",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "40%",
                                      "text-align": "right",
                                      color: "#8f9696",
                                    },
                                  },
                                  [_vm._v("签单日期：")]
                                ),
                                _c("div", { staticStyle: { width: "60%" } }, [
                                  _vm._v(
                                    _vm._s(
                                      item.signedDate
                                        ? item.signedDate.split(" ")[0]
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        !_vm._.isEmpty(item.riskName)
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "line-height": "30px",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "40%",
                                      "text-align": "right",
                                      color: "#8f9696",
                                    },
                                  },
                                  [_vm._v("投保险别：")]
                                ),
                                _c(
                                  "div",
                                  { staticStyle: { width: "60%" } },
                                  _vm._l(item.riskName, function (risk, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticStyle: { "text-align": "left" },
                                      },
                                      [_c("span", [_vm._v(_vm._s(risk))])]
                                    )
                                  }),
                                  0
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ])
          }),
          0
        ),
      ]
    ),
    _c(
      "div",
      { staticStyle: { padding: "10px 20px" } },
      [
        _c(
          "el-checkbox",
          {
            model: {
              value: _vm.isScreenshot,
              callback: function ($$v) {
                _vm.isScreenshot = $$v
              },
              expression: "isScreenshot",
            },
          },
          [
            _vm._v("将重复投保信息保存为图片上传 "),
            _c("span", { staticStyle: { color: "#e8cc9f" } }, [
              _vm._v("(核保上传影像时，可查看选择)"),
            ]),
          ]
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticStyle: {
          display: "flex",
          "justify-content": "center",
          "line-height": "46px",
          height: "70px",
          padding: "20px 0px",
        },
      },
      [
        _c(
          "el-button",
          {
            attrs: { type: "primary", size: "mini" },
            on: { click: _vm.toImage },
          },
          [_vm._v("确 定")]
        ),
        _c(
          "el-button",
          { attrs: { size: "mini" }, on: { click: _vm.closePage } },
          [_vm._v("取 消")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { padding: "10px 20px" } }, [
      _c("span", { staticStyle: { "font-size": "14px" } }, [
        _vm._v("建议投保信息调整"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }