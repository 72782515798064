var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-m-cls", staticStyle: {} }, [
    _c(
      "div",
      {
        ref: "header",
        staticClass: "header-cls",
        staticStyle: { "max-height": "500px", overflow: "auto" },
      },
      [
        _c(
          "el-card",
          { attrs: { shadow: "never" } },
          [
            _c(
              "el-form",
              {
                ref: "createPlan",
                staticStyle: { "margin-bottom": "-20px" },
                attrs: {
                  model: _vm.form,
                  rules: _vm.createPlanRules,
                  "label-width": "100px",
                  size: "mini",
                },
              },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "方案名称：", prop: "schemeName" },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "请输入方案名称",
                                clearable: "",
                              },
                              model: {
                                value: _vm.form.schemeName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "schemeName", $$v)
                                },
                                expression: "form.schemeName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "方案编码：", prop: "schemeCode" },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "请输入方案编码",
                                clearable: "",
                              },
                              model: {
                                value: _vm.form.schemeCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "schemeCode", $$v)
                                },
                                expression: "form.schemeCode",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "优先级：", prop: "sort" } },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "请输入优先级",
                                oninput: "value=value.replace(/[^\\d]/g,'')",
                                clearable: "",
                              },
                              model: {
                                value: _vm.form.sort,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "sort", $$v)
                                },
                                expression: "form.sort",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "方案简述：" } },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "请输入方案简述",
                                type: "textarea",
                                maxlength: "200",
                                rows: 3,
                                "show-word-limit": "",
                                clearable: "",
                              },
                              model: {
                                value: _vm.form.schemeResume,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "schemeResume", $$v)
                                },
                                expression: "form.schemeResume",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "服务项目：" } },
                          [
                            _vm.dialogType == "add"
                              ? _c(
                                  "el-checkbox-group",
                                  {
                                    model: {
                                      value: _vm.carCheckList,
                                      callback: function ($$v) {
                                        _vm.carCheckList = $$v
                                      },
                                      expression: "carCheckList",
                                    },
                                  },
                                  _vm._l(
                                    _vm.InsuranceTypes,
                                    function (item, index) {
                                      return _c(
                                        "el-checkbox",
                                        {
                                          key: index,
                                          attrs: { label: item.itemCode },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(item.itemName) + " "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  1
                                )
                              : _c(
                                  "el-checkbox-group",
                                  {
                                    model: {
                                      value: _vm.carCheckList,
                                      callback: function ($$v) {
                                        _vm.carCheckList = $$v
                                      },
                                      expression: "carCheckList",
                                    },
                                  },
                                  _vm._l(
                                    _vm.InsuranceTypes,
                                    function (item, index) {
                                      return _c(
                                        "el-checkbox",
                                        {
                                          key: index,
                                          attrs: { label: item.itemCode },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(item.itemName) + " "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  1
                                ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "服务注解：" } },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "请输入服务注解",
                                type: "textarea",
                                maxlength: "200",
                                rows: 3,
                                "show-word-limit": "",
                                clearable: "",
                              },
                              model: {
                                value: _vm.form.serviceAnnotation,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "serviceAnnotation", $$v)
                                },
                                expression: "form.serviceAnnotation",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticStyle: {
          display: "flex",
          "justify-content": "center",
          "margin-top": "8px",
        },
      },
      [
        _c(
          "el-button",
          {
            attrs: {
              icon: "el-icon-check",
              type: "primary",
              plain: "",
              size: "mini",
            },
            on: { click: _vm.save },
          },
          [_vm._v("保存")]
        ),
        _c(
          "el-button",
          {
            attrs: { icon: "el-icon-close", size: "mini" },
            on: { click: _vm.closePage },
          },
          [_vm._v("关闭")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }