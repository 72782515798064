import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import dict from '@/mixins/dict';
import Pagination from '@/components/Pagination';
import roleDialog from './dialog';
import rolesRequest from '@/api/roles';
export default {
  name: 'RoleManage',
  components: {
    Pagination: Pagination,
    roleDialog: roleDialog
  },
  mixins: [initHeight, dict],
  computed: {},
  data: function data() {
    return {
      drawer: false,
      searchForm: {},
      form: {},
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      multipleSelection: [],
      opType: '',
      loading: false,
      fileList: [],
      showDialog: false,
      itemId: '',
      bizType: null
    };
  },
  created: function created() {
    this.loadData();
  },
  methods: {
    breakData: function breakData() {
      // this.resetSearch()
      this.loadData();
    },
    // 是否启用
    updateItem: function updateItem(item) {
      var _this = this;
      rolesRequest.recentUpdate(item).then(function (res) {
        if (res.code == RESPONSE_SUCCESS) {
          _this.$message({
            type: 'success',
            message: res.msg
          });
        }
      }).catch(function (err) {});
    },
    addRole: function addRole(type) {
      //新增
      this.opType = type;
      this.showDialog = true;
    },
    editRole: function editRole(type, item) {
      //编辑
      this.opType = type;
      this.showDialog = true;
      this.itemId = item.roleId.toString();
      this.$forceUpdate();
    },
    deleteRole: function deleteRole(item) {
      var _this2 = this;
      //删除
      this.$confirm('您确定要将该角色删除吗？', '温馨提示', {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        rolesRequest.deleteRoles(item.roleId).then(function (res) {
          if (res.code == RESPONSE_SUCCESS) {
            _this2.$message({
              type: 'success',
              message: res.msg
            });
            _this2.loadData();
          }
        }).catch(function (err) {});
      });
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {};
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    loadData: function loadData() {
      var _this3 = this;
      //获取列表数据
      this.loading = true;
      this.drawer = false;
      this.showDialog = false;
      rolesRequest.getList(this.listQuery.pageNum, this.listQuery.pageSize, this.searchForm).then(function (res) {
        _this3.loading = false;
        if (res.data) {
          _this3.tableData = res.data.list || [];
          _this3.total = res.data.page ? res.data.page.recordsTotal : 0;
        }
      });
    }
  }
};