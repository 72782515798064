import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
/**
 *培训 接口
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 获取数据
   * @param {*} id 查询参数
   */
  getlist: function getlist(pageNum, pageSize, data) {
    return request.get(getUrl("/employeetrain/search?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 12), {
      params: data
    });
  },
  /**
   * 获取员工列表
  //   * @param {*} data 内容
  //   */
  getOrgCodeList: function getOrgCodeList(data) {
    //  return request.get(getUrl(`/dealer/employee/getOrgCodeList/list?dealerCode=${data.id}`, 12))
    return request.get(getUrl("/dealer/employee/byDealerCode/list", 12), {
      params: data
    });
  },
  /**
   * 新增
  //   * @param {*} data 内容
  //   */
  // update: function(data) {
  //      return request.post(getUrl(`/employeetrain/create`, 4), data)
  //  },
  /**
   * 新增
  //   * @param {*} data 内容
  //   */
  update: function update(data) {
    return request.post(getUrl("/employeetrain/create", 12), data);
  },
  //  /**
  //   * 
  //   * @param {*} id 查询参数
  //   * @param {*} data 内容
  //   */
  // editList: function(id,data) {
  //      return request.put(getUrl(`/employeetrain/update?trainId=${id}`, 4), data )
  //  },
  editList: function editList(data) {
    return request.put(getUrl("/employeetrain/update", 12), data);
  },
  /**
   * 列表
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   * @param {*} data 查询参数
   */
  //  getList: function(data) {
  //      return request.post(getUrl(`/employee/offer/takeoffer`, 4),data )
  //  },
  //  /**
  //   * 详情
  //   * @param {*} ids 
  //   */
  // details: function(ids) {
  //      return request.get(getUrl(`/employeetrain/${ids.toString()}`, 12))
  //  },
  details: function details(trainId) {
    return request.get(getUrl("/employeetrain/".concat(trainId), 12));
  },
  //  /**
  //   * 导入模板
  //   * @param {*} ids 
  //   */
  template: function template() {
    return request({
      method: 'get',
      url: getUrl("/employeetrain/formwork", 12),
      responseType: 'blob'
    });
  },
  /**
  * 导入
  *  @param {*} data 内容
  */
  importModul: function importModul(data) {
    return request({
      method: 'post',
      url: getUrl("/train/import ", 12),
      data: data
    });
  },
  /**
  * 删除
  * @param {*} ids 
  */
  delete: function _delete(ids) {
    return request.delete(getUrl("/employeetrain/".concat(ids.toString()), 12));
  }
};
export default objApi;