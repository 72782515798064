//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import dict from "../../../../mixins/dict";
import initHeight from "../../../../mixins/initHeight";
import Pagination from "@/components/Pagination";
import training from "@/api/training";
import storageRequest from '@/api/storage';
import gatewayConfigRequest from '@/api/gatewayConfig';
import { saveFile } from '@/utils/common';
export default {
  props: {
    refresh: {
      type: Boolean
    }
  },
  mixins: [initHeight, dict],
  components: {
    Pagination: Pagination
  },
  data: function data() {
    return {
      formData: '',
      uploadForm: {
        storageId: ''
      },
      fileList: [],
      importLoading: false
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    initData: function initData() {
      this.uploadForm = {
        storageId: ''
      };
      this.fileList = [];
    },
    // 下载模板
    download: function download() {
      training.template().then(function (res) {
        var fileName = "培训管理.xlsx";
        var contentType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        saveFile(res, contentType, fileName);
      });
    },
    // 上传文件
    uploadFile: function uploadFile(param) {
      var _this = this;
      var formData = new FormData();
      formData.append("file", param.file);
      storageRequest.uploadFile(formData).then(function (res) {
        if (res.code === 200) {
          _this.$message.success(res.msg);
          setTimeout(function () {
            _this.uploadForm.storageId = res.data.fileStorageId;
            _this.uploadForm.fileUrl = res.data.fileUrl;
          }, 1000);
        }
      });
    },
    // 附件校验
    validate: function validate() {
      var flag = true;
      if (!this.uploadForm.storageId) {
        this.$message.warning('请上传文件');
        flag = false;
        return flag;
      }
      return flag;
    },
    // 上传点击
    selectFile: function selectFile() {
      var _this2 = this;
      if (!this.validate()) return;
      this.importLoading = true;
      gatewayConfigRequest.batchImport(this.uploadForm.storageId).then(function (res) {
        _this2.importLoading = false;
        if (res.code === 200) {
          _this2.closePage();
        }
      }).catch(function () {
        _this2.importLoading = false;
      });
    },
    closePage: function closePage() {
      //关闭弹窗
      this.$emit("closePage");
    }
  }
};