import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PageContainer from "@/components/PageContainer";
import archivesManagement from "@/api/archivesManagement";
import FormLabel from "@/components/FormSubLabel";
export default {
  data: function data() {
    return {
      disabled: true,
      customForm: {},
      //客户信息
      voitureInfoForm: {},
      //车量信息
      cardTicketData: [],
      //卡券信息
      compulsoryInsurnaceForm: {},
      //交强保信息
      businessInsuranceForm: {},
      //商业保信息
      carriageNum: '',
      //车架号
      compulsoryRiskType: '',
      //交强险riskType  1
      businessRiskType: '',
      //商业险riskType 0
      activeOne: '1',
      activeTwo: '1',
      activeThree: '1',
      activeFour: '1',
      activeFive: '1'
    };
  },
  components: {
    FormLabel: FormLabel,
    PageContainer: PageContainer
  },
  watch: {
    '$route': function $route(to, from) {
      //监听路由是否变化）
      this.getCustomList();
    }
  },
  created: function created() {
    this.getCustomList();
  },
  methods: {
    goBack: function goBack() {
      //返回到上一页
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.go(-1);
    },
    //加载客户信息
    getCustomList: function getCustomList() {
      var _this = this;
      archivesManagement.getCustomDetail(this.$route.query.customerId).then(function (res) {
        if (res.code == 200) {
          _this.customForm = res.data.vehicleOwner; //客户信息
          _this.voitureInfoForm = res.data.vehicle; //车辆信息
          _this.carriageNum = res.data.vehicle.vin; //车架号
          res.data.insuranceList.map(function (item) {
            if (item.riskType == 'BZ') {
              //交强险
              _this.compulsoryInsurnaceForm = item;
            } else {
              //商业险
              _this.businessInsuranceForm = item;
            }
          });
        }
      }).catch(function (err) {});
    },
    //往年交交强险/商业险信息查看
    insuranceInfoDetail: function insuranceInfoDetail(type, riskType) {
      if (type == 'insuranInfo') {
        riskType = this.$refs.compulsoryRef.value;
      } else {
        riskType = this.$refs.businessRef.value;
      }
      if (riskType !== undefined) {
        this.$router.push({
          path: "/ArchivesManagement/insuranceBillList",
          query: {
            type: type,
            riskType: riskType,
            vin: this.carriageNum
          }
        });
      } else {
        this.$message({
          type: "warning",
          message: "暂无数据"
        });
      }
    }
  }
};