import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from "@/mixins/initHeight";
import Dialog from "@/components/Dialog";
import Pagination from "@/components/Pagination";
import request from "@/api/connectConfig";
import SpecialListDialog from './specialListDialog';
import NonAutoInsurance from './NonAutoInsurance';
import { getSupplyers, commonExport } from "@/utils/common";
export default {
  name: "ChannelConfig",
  components: {
    Pagination: Pagination,
    Dialog: Dialog,
    SpecialListDialog: SpecialListDialog,
    NonAutoInsurance: NonAutoInsurance
  },
  mixins: [initHeight],
  computed: {},
  data: function data() {
    return {
      nonAutoInsuranceConfigId: null,
      nonAutoInsuranceShow: false,
      ListDialogShow: false,
      searchForm: {
        insCorpCode: null,
        insCityCode: null
      },
      formLoading: false,
      rules: {
        name: [{
          required: true,
          message: "请输入名称",
          trigger: "blur"
        }]
      },
      form: {},
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      multipleSelection: [],
      cityList: [],
      supplyers: [],
      tenantData: [{
        tenantId: '100001',
        tenantName: '默认租户'
      }],
      loading: false,
      channelConfigData: [],
      channelConfigId: null,
      ChildInsCorpCode: null,
      childsInsCorpCode: null
    };
  },
  created: function created() {
    this.loadData();
    this.cityList = JSON.parse(localStorage.getItem("VI_CityPcTree")) || [];
    this.supplyers = getSupplyers();
  },
  methods: {
    // 导出
    exportExcels: function exportExcels() {
      var searchForm = _objectSpread(_objectSpread(_objectSpread({}, this.searchForm), this.listQuery), {}, {
        count: false
      });
      var exporParams = {
        total: this.total,
        fileName: '保司工号配置',
        exportUrl: '/vi-connect-configuration-service/insCorpChannel/search',
        searchForm: searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    closeList: function closeList() {
      this.ListDialogShow = false;
    },
    // 关闭驾意险
    nonAutoCloseList: function nonAutoCloseList() {
      this.nonAutoInsuranceShow = false;
    },
    // 显示驾意险
    showNonAutoInsurance: function showNonAutoInsurance(row) {
      this.nonAutoInsuranceShow = true;
      this.childsInsCorpCode = row.insCorpCode.toString();
      this.nonAutoInsuranceConfigId = row.channelConfigId.toString();
    },
    // 获取渠道信息
    getByChannelId: function getByChannelId(row) {
      this.ListDialogShow = true;
      this.ChildInsCorpCode = row.insCorpCode.toString();
      this.channelConfigId = row.channelConfigId.toString();
    },
    // 刷新列表
    breakData: function breakData() {
      // this.resetSearch();
      this.loadData();
    },
    // 获取保司中文名称
    getSupplyerName: function getSupplyerName(code) {
      var supplyer = _.find(this.supplyers, function (item) {
        return _.toLower(item.code) == _.toLower(code);
      });
      return supplyer ? supplyer.shortName : "";
    },
    // 获取城市中文名称
    getCityName: function getCityName(code) {
      var cityName = "";
      this.cityList.forEach(function (element) {
        var city = _.find(element.children, function (item) {
          return item.value == code;
        });
        if (city) {
          cityName = city.label;
        }
      });
      return cityName;
    },
    // 重置搜索条件
    resetSearch: function resetSearch() {
      this.searchForm = {
        insCorpCode: null,
        insCityCode: null
      };
    },
    // 搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    // 删除
    removeItems: function removeItems(item) {
      var _this = this;
      var ids = [];
      if (!_.isEmpty(item)) {
        ids.push(item.channelConfigId);
      } else {
        if (!_.isEmpty(this.multipleSelection)) {
          ids = _.map(this.multipleSelection, "channelConfigId");
        }
      }
      if (_.isEmpty(ids)) return;
      this.$confirm("是否确认删除保司工号配置项？确认删除后不能恢复。", "温馨提示", {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        request.deleteChannelConfig(ids).then(function (res) {
          if (res.code == RESPONSE_SUCCESS) {
            _this.$message({
              type: 'success',
              message: res.msg
            });
            _this.loadData();
          }
        });
      });
    },
    // 编辑
    updateItem: function updateItem(item) {
      this.$router.push({
        path: "/channelConfig/detail",
        query: {
          type: "update",
          channelId: item.channelConfigId,
          tabName: "编辑保司工号配置-" + item.channelConfigId
        }
      });
    },
    // 启用或禁用
    enableItem: function enableItem(item) {
      var _this2 = this;
      if (item.isClick) return;
      item.isClick = true;
      request.changeChannelStatus(item.channelConfigId, item.enabled).then(function (res) {
        item.isClick = false;
        if (res.code == RESPONSE_SUCCESS) {
          _this2.$message({
            type: 'success',
            message: res.msg
          });
        } else {
          item.enabled = false;
        }
      }).catch(function (err) {
        item.isClick = false;
        item.enabled = false;
      });
    },
    // 查看详情
    viewDetail: function viewDetail(item) {
      this.$router.push({
        path: "/channelConfig/detail",
        query: {
          type: "view",
          channelId: item.channelConfigId,
          tabName: "保司工号配置详情-" + item.channelConfigId
        }
      });
    },
    // 新增
    addItem: function addItem() {
      this.$router.push({
        path: "/channelConfig/detail",
        query: {
          type: "add",
          tabName: "新增保司工号配置"
        }
      });
    },
    // 双击打开详情
    dbSelected: function dbSelected(row) {
      this.viewDetail(row);
    },
    // 获取列表
    loadData: function loadData() {
      var _this3 = this;
      this.loading = true;
      this.tableData = [];
      if (_.isArray(this.searchForm.insCityCode)) {
        this.searchForm.insCityCode = this.searchForm.insCityCode[1];
      }
      request.getChannelConfigList(this.listQuery.pageNum, this.listQuery.pageSize, this.searchForm).then(function (res) {
        _this3.loading = false;
        if (!_.isEmpty(res.data)) {
          _this3.tableData = res.data.list;
          // 总条数
          _this3.total = res.data.page.recordsTotal;
        }
      }).catch(function (err) {
        _this3.loading = false;
      });
    }
  }
};