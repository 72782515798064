var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.initLoading || _vm.loadingVehicle,
          expression: "initLoading || loadingVehicle",
        },
      ],
    },
    [
      _c("PageContainer", [
        _c("div", { attrs: { slot: "main" }, slot: "main" }, [
          _c(
            "div",
            {
              staticClass: "app-m-cls follow-box",
              staticStyle: { "min-height": "calc(100vh - 130px)" },
            },
            [
              _c(
                "div",
                { ref: "header", staticClass: "header-cls" },
                [
                  _c(
                    "el-drawer",
                    {
                      attrs: {
                        title: "跟进历史",
                        visible: _vm.followUpDrawer,
                        modal: false,
                        size: "400px",
                      },
                      on: {
                        "update:visible": function ($event) {
                          _vm.followUpDrawer = $event
                        },
                      },
                    },
                    [
                      _c("CustomerTracks", {
                        attrs: { followHistory: _vm.followUpData },
                      }),
                      _c(
                        "div",
                        { staticClass: "demo-drawer__footer follow-btn" },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                height: "28px",
                                "line-height": "0",
                              },
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  _vm.showFollowDialog = true
                                },
                              },
                            },
                            [_vm._v("跟进")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.form,
                        rules: _vm.formRules,
                        "label-width": "120px",
                        size: "mini",
                      },
                    },
                    [
                      _c(
                        "el-collapse",
                        {
                          staticStyle: { "margin-top": "10px" },
                          model: {
                            value: _vm.activeOne,
                            callback: function ($$v) {
                              _vm.activeOne = $$v
                            },
                            expression: "activeOne",
                          },
                        },
                        [
                          _c(
                            "el-collapse-item",
                            {
                              staticClass: "collapse-item-style",
                              attrs: { name: "1" },
                            },
                            [
                              _c("template", { slot: "title" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "title",
                                    staticStyle: { "line-height": "25px" },
                                  },
                                  [
                                    _c("FormLabel", {
                                      attrs: { "form-label": "基础信息" },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "el-form",
                                {
                                  ref: "baseForm",
                                  attrs: {
                                    model: _vm.form,
                                    rules: _vm.formRules,
                                    "label-width": "120px",
                                    size: "mini",
                                  },
                                },
                                [
                                  _c(
                                    "el-row",
                                    { attrs: { gutter: 0 } },
                                    [
                                      _vm.bizType === "OEM"
                                        ? _c(
                                            "el-col",
                                            { attrs: { span: 8 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  ref: "salesOrgName",
                                                  attrs: {
                                                    label: "组织机构",
                                                    prop: "salesOrgName",
                                                  },
                                                },
                                                [
                                                  _c("OemSelect", {
                                                    staticStyle: {
                                                      width: "100%",
                                                    },
                                                    attrs: {
                                                      isQuotePage: true,
                                                      searchForm: _vm.orgForm,
                                                      isGetUserList: true,
                                                      isContainOem: false,
                                                      disabled:
                                                        _vm.opType ==
                                                          "underwriting" ||
                                                        (_vm.currentInsCrop &&
                                                          _vm.currentInsCrop
                                                            .proposalId > 0),
                                                    },
                                                    on: {
                                                      nodeClick:
                                                        _vm.handleNodeClick,
                                                      getCurrentOrgUsers:
                                                        _vm.oemGetUsers,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.bizType === "DEALER"
                                        ? _c(
                                            "el-col",
                                            { attrs: { span: 8 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: { label: "组织机构" },
                                                },
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      disabled: "",
                                                      placeholder: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form.salesOrgName,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "salesOrgName",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.salesOrgName",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 8 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              ref: "salemanName",
                                              attrs: {
                                                label: "业务员",
                                                prop: "salemanName",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    filterable: "",
                                                    placeholder: "请选择业务员",
                                                    clearable: false,
                                                    disabled:
                                                      _vm.opType ==
                                                        "underwriting" ||
                                                      (_vm.currentInsCrop &&
                                                        _vm.currentInsCrop
                                                          .proposalId > 0),
                                                  },
                                                  on: {
                                                    change:
                                                      _vm.salemanNameChange,
                                                  },
                                                  model: {
                                                    value: _vm.form.salemanName,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "salemanName",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.salemanName",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.users,
                                                  function (item, index) {
                                                    return _c("el-option", {
                                                      key: index,
                                                      attrs: {
                                                        label: item.userName,
                                                        value: item.userId,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 8 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              ref: "insCityCode",
                                              attrs: {
                                                label: "投保城市",
                                                prop: "insCityCode",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    filterable: "",
                                                    placeholder:
                                                      "请选择投保城市",
                                                    clearable: false,
                                                    disabled:
                                                      _vm.opType ==
                                                        "underwriting" ||
                                                      (_vm.currentInsCrop &&
                                                        _vm.currentInsCrop
                                                          .proposalId > 0),
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.selectCityItem(
                                                        _vm.form.insCityCode,
                                                        null,
                                                        true
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: _vm.form.insCityCode,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "insCityCode",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.insCityCode",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.policyCityItems,
                                                  function (item, index) {
                                                    return _c("el-option", {
                                                      key: index,
                                                      attrs: {
                                                        label: item.label,
                                                        value: item.value,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _vm.opType == "insure"
                        ? _c(
                            "el-collapse",
                            {
                              staticStyle: { "margin-top": "10px" },
                              model: {
                                value: _vm.activeSix,
                                callback: function ($$v) {
                                  _vm.activeSix = $$v
                                },
                                expression: "activeSix",
                              },
                            },
                            [
                              _c(
                                "el-collapse-item",
                                {
                                  staticClass: "collapse-item-style",
                                  attrs: { name: "1" },
                                },
                                [
                                  _c("template", { slot: "title" }, [
                                    _c(
                                      "div",
                                      {
                                        key: "insCorpRef",
                                        staticClass: "title",
                                        staticStyle: { "line-height": "25px" },
                                        attrs: { id: "insCorpRef" },
                                      },
                                      [
                                        _c("FormLabel", {
                                          attrs: {
                                            "form-label": "选择投保保险公司",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                  !_vm._.isEmpty(_vm.setectInsuranceCompanyList)
                                    ? _c(
                                        "div",
                                        {
                                          ref: "insCorpListRef",
                                          staticStyle: {
                                            display: "flex",
                                            "flex-wrap": "wrap",
                                            width: "95%",
                                            "text-align": "center",
                                            margin: "0 auto",
                                            overflow: "hidden",
                                          },
                                        },
                                        _vm._l(
                                          _vm.setectInsuranceCompanyList,
                                          function (item, index) {
                                            return _c(
                                              "div",
                                              {
                                                key: index,
                                                staticClass:
                                                  "dialog-insurance-company",
                                                staticStyle: {
                                                  width: "200px",
                                                  cursor: "pointer",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.insuranceCompanyItem(
                                                      item,
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "dialog-insurance-company-name",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          "line-height": "27px",
                                                          display: "flex",
                                                        },
                                                      },
                                                      [
                                                        _c("svg-icon", {
                                                          staticStyle: {
                                                            "font-size": "30px",
                                                          },
                                                          attrs: {
                                                            "icon-class":
                                                              "" +
                                                              _vm._.toLower(
                                                                item.insCorpCode
                                                              ),
                                                          },
                                                        }),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              "text-align":
                                                                "left",
                                                              "padding-left":
                                                                "10px",
                                                              width: "120px",
                                                              overflow:
                                                                "hidden",
                                                            },
                                                          },
                                                          [
                                                            _c("div", [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    color:
                                                                      "#515151",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.insCorpName
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]),
                                                            _c(
                                                              "div",
                                                              [
                                                                _c(
                                                                  "el-tooltip",
                                                                  {
                                                                    staticClass:
                                                                      "item",
                                                                    attrs: {
                                                                      effect:
                                                                        "dark",
                                                                      content:
                                                                        item.insChannelNickname ||
                                                                        item.insChannelName,
                                                                      placement:
                                                                        "bottom",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "channel_name",
                                                                        staticStyle:
                                                                          {
                                                                            color:
                                                                              "#666",
                                                                            "font-size":
                                                                              "12px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            item.insChannelNickname ||
                                                                              item.insChannelName
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c("div", [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-success",
                                                        style: {
                                                          color:
                                                            "" +
                                                            (item.check
                                                              ? "#1dc371"
                                                              : "#ccc"),
                                                          fontSize: "24px",
                                                          lineHeight: "15px",
                                                        },
                                                      }),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            color: "#909399",
                                            "text-align": "center",
                                          },
                                        },
                                        [_c("p", [_vm._v("暂无数据")])]
                                      ),
                                  _vm.isShowSwitch
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "text-align": "right",
                                            margin: "10px 0",
                                            background: "#fff",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-link",
                                            {
                                              attrs: {
                                                type: "primary",
                                                underline: false,
                                              },
                                              on: {
                                                click: _vm.toggleInsCorpClick,
                                              },
                                            },
                                            [
                                              _c("i", {
                                                class: _vm.isToggleInsCorp
                                                  ? "el-icon-arrow-up"
                                                  : "el-icon-arrow-down",
                                              }),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.isToggleInsCorp
                                                      ? "收起"
                                                      : "展开"
                                                  ) +
                                                  "保险公司 "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-collapse",
                        {
                          staticStyle: { "margin-top": "10px" },
                          model: {
                            value: _vm.activeTwo,
                            callback: function ($$v) {
                              _vm.activeTwo = $$v
                            },
                            expression: "activeTwo",
                          },
                        },
                        [
                          _c(
                            "el-collapse-item",
                            {
                              staticClass: "collapse-item-style carInfo",
                              attrs: { name: "1" },
                            },
                            [
                              _c("template", { slot: "title" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "title",
                                    staticStyle: {
                                      flex: "1",
                                      "line-height": "25px",
                                      display: "flex",
                                      "flex-direction": "row",
                                      "justify-content": "space-between",
                                      "align-items": "center",
                                    },
                                  },
                                  [
                                    _c("FormLabel", {
                                      attrs: { "form-label": "车辆信息" },
                                    }),
                                    _vm.opType == "insure"
                                      ? _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "margin-right": "10px",
                                              position: "relative",
                                              "z-index": "9",
                                            },
                                            on: {
                                              mouseenter: function ($event) {
                                                _vm.iconVehicleShow = false
                                              },
                                              mouseleave: function ($event) {
                                                _vm.iconVehicleShow = true
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  size: "mini",
                                                  type: "primary",
                                                  plain: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.identificationClicked(
                                                      "vehicle"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("svg-icon", {
                                                  staticStyle: {
                                                    "font-size": "14px",
                                                  },
                                                  attrs: {
                                                    "class-name": "ident-icon",
                                                    "icon-class":
                                                      _vm.iconVehicleShow
                                                        ? "identification"
                                                        : "identificationHover",
                                                  },
                                                }),
                                                _vm._v(" 证件识别 "),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "el-row",
                                { attrs: { gutter: 0 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.form.quoteVehicle.isNewCar,
                                              expression:
                                                "form.quoteVehicle.isNewCar",
                                            },
                                          ],
                                          attrs: { label: "车牌号" },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: { display: "flex" },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "margin-right": "10px",
                                                  },
                                                },
                                                [
                                                  _c("el-input", {
                                                    ref: "plateNo",
                                                    staticStyle: {
                                                      "margin-right": "10px",
                                                    },
                                                    attrs: {
                                                      maxlength: "10",
                                                      placeholder:
                                                        "请输入车牌号",
                                                      clearable: "",
                                                      prop: "media.idcardFront",
                                                      disabled:
                                                        _vm.form.quoteVehicle
                                                          .isNewCar ||
                                                        _vm.opType ==
                                                          "underwriting",
                                                    },
                                                    on: {
                                                      input: _vm.changeValue,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form.quoteVehicle
                                                          .plateNo,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form.quoteVehicle,
                                                          "plateNo",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.quoteVehicle.plateNo",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "el-checkbox",
                                                {
                                                  attrs: {
                                                    disabled:
                                                      _vm.opType ==
                                                      "underwriting",
                                                  },
                                                  on: {
                                                    change: _vm.noPlateNoChange,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form.quoteVehicle
                                                        .isNewCar,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form.quoteVehicle,
                                                        "isNewCar",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.quoteVehicle.isNewCar",
                                                  },
                                                },
                                                [_vm._v("未上牌")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      !_vm.form.quoteVehicle.isNewCar
                                        ? _c(
                                            "el-form-item",
                                            {
                                              ref: "quoteVehicle.plateNo",
                                              attrs: {
                                                label: "车牌号",
                                                prop: "quoteVehicle.plateNo",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "margin-right": "10px",
                                                      },
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        ref: "plateNo",
                                                        staticStyle: {
                                                          "margin-right":
                                                            "10px",
                                                        },
                                                        attrs: {
                                                          minlength: "7",
                                                          maxlength: "10",
                                                          placeholder:
                                                            "请输入车牌号",
                                                          clearable: "",
                                                          prop: "media.idcardFront",
                                                          disabled:
                                                            _vm.form
                                                              .quoteVehicle
                                                              .isNewCar ||
                                                            _vm.opType ==
                                                              "underwriting",
                                                        },
                                                        on: {
                                                          input:
                                                            _vm.inputPlateNo,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form
                                                              .quoteVehicle
                                                              .plateNo,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form
                                                                .quoteVehicle,
                                                              "plateNo",
                                                              typeof $$v ===
                                                                "string"
                                                                ? $$v.trim()
                                                                : $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form.quoteVehicle.plateNo",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-checkbox",
                                                    {
                                                      attrs: {
                                                        disabled:
                                                          _vm.opType ==
                                                          "underwriting",
                                                      },
                                                      on: {
                                                        change:
                                                          _vm.noPlateNoChange,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form.quoteVehicle
                                                            .isNewCar,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form
                                                              .quoteVehicle,
                                                            "isNewCar",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.quoteVehicle.isNewCar",
                                                      },
                                                    },
                                                    [_vm._v("未上牌")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  !_vm.form.quoteVehicle.isNewCar
                                    ? _c("el-col", { attrs: { span: 8 } }, [
                                        _c(
                                          "div",
                                          { staticStyle: { display: "flex" } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                ref: "quoteVehicle.vin",
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  label: "车架号",
                                                  prop: "quoteVehicle.vin",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "row_width_style special_width_row",
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticStyle: {
                                                        "min-width": "230px",
                                                      },
                                                      attrs: {
                                                        maxlength: "17",
                                                        placeholder:
                                                          "请输入车架号",
                                                        clearable: "",
                                                        disabled:
                                                          _vm.opType ==
                                                          "underwriting",
                                                      },
                                                      on: {
                                                        input: _vm.inputvin,
                                                      },
                                                      nativeOn: {
                                                        "!paste": function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return _vm.pastevin(
                                                            $event
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form.quoteVehicle
                                                            .vin,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form
                                                              .quoteVehicle,
                                                            "vin",
                                                            typeof $$v ===
                                                              "string"
                                                              ? $$v.trim()
                                                              : $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.quoteVehicle.vin",
                                                      },
                                                    }),
                                                    !_vm.source
                                                      ? _c(
                                                          "el-button",
                                                          {
                                                            staticStyle: {
                                                              "margin-left":
                                                                "10px",
                                                              height: "28px",
                                                            },
                                                            attrs: {
                                                              type: "primary",
                                                              icon: "el-icon-search",
                                                              size: "mini",
                                                              disabled:
                                                                _vm.opType ==
                                                                  "underwriting" ||
                                                                (!_vm.form
                                                                  .quoteVehicle
                                                                  .vin &&
                                                                  !_vm.form
                                                                    .quoteVehicle
                                                                    .plateNo),
                                                              plain: "",
                                                              loading:
                                                                _vm.loadingVehicle,
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.queryVechile(
                                                                  _vm.form
                                                                    .quoteVehicle
                                                                    .plateNo,
                                                                  _vm.form
                                                                    .quoteVehicle
                                                                    .vin
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.loadingVehicle
                                                                  ? "查询中..."
                                                                  : "查车辆"
                                                              )
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    !_vm.source
                                                      ? _c(
                                                          "el-button",
                                                          {
                                                            staticStyle: {
                                                              "margin-left":
                                                                "10px",
                                                              height: "28px",
                                                            },
                                                            attrs: {
                                                              type: "primary",
                                                              icon: "el-icon-search",
                                                              size: "mini",
                                                              disabled:
                                                                _vm.opType ==
                                                                  "underwriting" ||
                                                                !_vm.form
                                                                  .quoteVehicle
                                                                  .vin,
                                                              plain: "",
                                                              loading:
                                                                _vm.loadingVehicle,
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.queryRenewal(
                                                                  _vm.form
                                                                    .quoteVehicle
                                                                    .vin,
                                                                  "checkRenewal"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("检查续保")]
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm.form.quoteVehicle.isNewCar
                                    ? _c("el-col", { attrs: { span: 8 } }, [
                                        _c(
                                          "div",
                                          { staticStyle: { display: "flex" } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                ref: "quoteVehicle.vin",
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  label: "车架号",
                                                  prop: "quoteVehicle.vin",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "row_width_style special_width_row",
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticStyle: {
                                                        "min-width": "230px",
                                                      },
                                                      attrs: {
                                                        maxlength: "17",
                                                        placeholder:
                                                          "请输入车架号",
                                                        clearable: "",
                                                        disabled:
                                                          _vm.opType ==
                                                          "underwriting",
                                                      },
                                                      on: {
                                                        input: _vm.inputvin,
                                                      },
                                                      nativeOn: {
                                                        "!paste": function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return _vm.pastevin(
                                                            $event
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form.quoteVehicle
                                                            .vin,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form
                                                              .quoteVehicle,
                                                            "vin",
                                                            typeof $$v ===
                                                              "string"
                                                              ? $$v.trim()
                                                              : $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.quoteVehicle.vin",
                                                      },
                                                    }),
                                                    !_vm.source
                                                      ? _c(
                                                          "el-button",
                                                          {
                                                            staticStyle: {
                                                              "margin-left":
                                                                "10px",
                                                              height: "28px",
                                                            },
                                                            attrs: {
                                                              type: "primary",
                                                              icon: "el-icon-search",
                                                              size: "mini",
                                                              disabled:
                                                                _vm.opType ==
                                                                  "underwriting" ||
                                                                !_vm.form
                                                                  .quoteVehicle
                                                                  .vin,
                                                              plain: "",
                                                              loading:
                                                                _vm.loadingVehicle,
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.queryVechile(
                                                                  _vm.form
                                                                    .quoteVehicle
                                                                    .plateNo,
                                                                  _vm.form
                                                                    .quoteVehicle
                                                                    .vin
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.loadingVehicle
                                                                  ? "查询中..."
                                                                  : "查车辆"
                                                              )
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    !_vm.source
                                                      ? _c(
                                                          "el-button",
                                                          {
                                                            staticStyle: {
                                                              "margin-left":
                                                                "10px",
                                                              height: "28px",
                                                            },
                                                            attrs: {
                                                              type: "primary",
                                                              icon: "el-icon-search",
                                                              size: "mini",
                                                              disabled:
                                                                _vm.opType ==
                                                                  "underwriting" ||
                                                                !_vm.form
                                                                  .quoteVehicle
                                                                  .vin,
                                                              plain: "",
                                                              loading:
                                                                _vm.loadingVehicle,
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.queryRenewal(
                                                                  _vm.form
                                                                    .quoteVehicle
                                                                    .vin,
                                                                  "checkRenewal"
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("检查续保")]
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                { attrs: { gutter: 0 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          ref: "quoteVehicle.engineNo",
                                          attrs: {
                                            label: "发动机号",
                                            prop: "quoteVehicle.engineNo",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              maxlength: "30",
                                              placeholder: "请输入发动机号",
                                              clearable: "",
                                              disabled:
                                                _vm.opType == "underwriting",
                                            },
                                            on: { input: _vm.inputEngineNo },
                                            model: {
                                              value:
                                                _vm.form.quoteVehicle.engineNo,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form.quoteVehicle,
                                                  "engineNo",
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                                )
                                              },
                                              expression:
                                                "form.quoteVehicle.engineNo",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 16 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          ref: "quoteVehicle.modelName",
                                          staticClass: "special_row_content",
                                          staticStyle: { width: "100%" },
                                          style: {
                                            "max-width":
                                              _vm.brandLabelLength + "px",
                                          },
                                          attrs: {
                                            label: "品牌型号",
                                            prop: "quoteVehicle.modelName",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "row_width_style special_width_row",
                                            },
                                            [
                                              _c("el-input", {
                                                staticStyle: {
                                                  "margin-right": "10px",
                                                },
                                                attrs: {
                                                  maxlength: "40",
                                                  placeholder: "请输入品牌型号",
                                                  clearable: "",
                                                  disabled:
                                                    _vm.opType ==
                                                    "underwriting",
                                                },
                                                on: {
                                                  input: _vm.modelNameChange,
                                                },
                                                model: {
                                                  value:
                                                    _vm.form.quoteVehicle
                                                      .modelName,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form.quoteVehicle,
                                                      "modelName",
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.quoteVehicle.modelName",
                                                },
                                              }),
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: "primary",
                                                    icon: "el-icon-search",
                                                    size: "mini",
                                                    disabled:
                                                      _vm.loadingVehicleModel ||
                                                      _vm.opType ==
                                                        "underwriting" ||
                                                      !_vm.form.quoteVehicle
                                                        .modelName ||
                                                      _vm.form.quoteVehicle
                                                        .modelName.length < 5,
                                                    plain: "",
                                                    loading:
                                                      _vm.loadingVehicleModel,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.queryVechileModel(
                                                        _vm.form.quoteVehicle
                                                          .modelName
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.loadingVehicleModel
                                                        ? "查询中..."
                                                        : "查车型"
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                { attrs: { gutter: 0 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          ref: "vehiclePrice",
                                          staticClass: "special_row_content",
                                          style: {
                                            "max-width":
                                              _vm.vehicleTypeLabelLength + "px",
                                          },
                                          attrs: {
                                            label: "车型价格",
                                            prop: "vehiclePrice",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              ref: "vehicleSelect",
                                              staticClass: "special_col",
                                              attrs: {
                                                filterable: "",
                                                placeholder: "请选择车型价格",
                                                clearable: false,
                                                disabled:
                                                  _vm.opType == "underwriting",
                                              },
                                              on: {
                                                change: _vm.vehiclePriceChange,
                                              },
                                              model: {
                                                value: _vm.form.vehiclePrice,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "vehiclePrice",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.vehiclePrice",
                                              },
                                            },
                                            _vm._l(
                                              _vm.vehiclePrices,
                                              function (item, index) {
                                                return _c("el-option", {
                                                  key: index,
                                                  attrs: {
                                                    label: item.modelFullName,
                                                    value: item.insModelId,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              false
                                ? _c(
                                    "el-row",
                                    { attrs: { gutter: 0 } },
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 8 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: { label: "精友车型编码" },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "row_width_style special_width_row",
                                                },
                                                [
                                                  _c("el-input", {
                                                    staticStyle: {
                                                      "margin-right": "10px",
                                                    },
                                                    attrs: {
                                                      maxlength: "40",
                                                      clearable: "",
                                                      disabled:
                                                        _vm.opType ==
                                                        "underwriting",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form.quoteVehicle
                                                          .makerModelCode,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form.quoteVehicle,
                                                          "makerModelCode",
                                                          typeof $$v ===
                                                            "string"
                                                            ? $$v.trim()
                                                            : $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.quoteVehicle.makerModelCode",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 8 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: { label: "上市年份" },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "row_width_style special_width_row",
                                                },
                                                [
                                                  _c("el-input", {
                                                    staticStyle: {
                                                      "margin-right": "10px",
                                                    },
                                                    attrs: {
                                                      maxlength: "6",
                                                      type: "number",
                                                      oninput:
                                                        "if(value.length>6)value=value.slice(0,6)",
                                                      clearable: "",
                                                      disabled:
                                                        _vm.opType ==
                                                        "underwriting",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form.quoteVehicle
                                                          .yearPattern,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form.quoteVehicle,
                                                          "yearPattern",
                                                          typeof $$v ===
                                                            "string"
                                                            ? $$v.trim()
                                                            : $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.quoteVehicle.yearPattern",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "el-row",
                                { attrs: { gutter: 0 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          ref: "quoteVehicle.plateType",
                                          attrs: {
                                            label: "号牌种类",
                                            prop: "quoteVehicle.plateType",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                filterable: "",
                                                placeholder: "请选择号牌种类",
                                                clearable: false,
                                                disabled:
                                                  _vm.opType == "underwriting",
                                              },
                                              on: {
                                                change: _vm.plateTypeChange,
                                              },
                                              model: {
                                                value:
                                                  _vm.form.quoteVehicle
                                                    .plateType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form.quoteVehicle,
                                                    "plateType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.quoteVehicle.plateType",
                                              },
                                            },
                                            _vm._l(
                                              _vm.PLATE_TYPE,
                                              function (item, index) {
                                                return _c("el-option", {
                                                  key: index,
                                                  attrs: {
                                                    label: item.dictName,
                                                    value: item.dictCode,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          ref: "quoteVehicle.plateColor",
                                          attrs: {
                                            label: "号牌颜色",
                                            prop: "quoteVehicle.plateColor",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                filterable: "",
                                                placeholder: "请选择号牌颜色",
                                                clearable: false,
                                                disabled:
                                                  _vm.opType == "underwriting",
                                              },
                                              model: {
                                                value:
                                                  _vm.form.quoteVehicle
                                                    .plateColor,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form.quoteVehicle,
                                                    "plateColor",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.quoteVehicle.plateColor",
                                              },
                                            },
                                            _vm._l(
                                              _vm.PLATE_COLOR,
                                              function (item, index) {
                                                return _c("el-option", {
                                                  key: index,
                                                  attrs: {
                                                    label: item.dictName,
                                                    value: item.dictCode,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          ref: "quoteVehicle.vehicleType",
                                          attrs: {
                                            label: "行驶证车辆类型",
                                            prop: "quoteVehicle.vehicleType",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              ref: "vehicleType",
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                filterable: "",
                                                "filter-method":
                                                  _vm.vehicleTypeFilter,
                                                placeholder:
                                                  "请选择行驶证车辆类型",
                                                clearable: false,
                                                disabled:
                                                  _vm.opType == "underwriting",
                                              },
                                              on: {
                                                change: _vm.vehicleTypeChange,
                                                focus: _vm.vehicleTypeFocus,
                                              },
                                              model: {
                                                value:
                                                  _vm.form.quoteVehicle
                                                    .vehicleType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form.quoteVehicle,
                                                    "vehicleType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.quoteVehicle.vehicleType",
                                              },
                                            },
                                            _vm._l(
                                              _vm.VEHICLE_KINDS,
                                              function (item, index) {
                                                return _c("el-option", {
                                                  key: index,
                                                  attrs: {
                                                    label: item.dictName,
                                                    value: item.dictCode,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                { attrs: { gutter: 0 } },
                                [
                                  _c(
                                    "el-col",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: !(
                                            _vm.form.quoteVehicle
                                              .useAttribute == "1" &&
                                            _vm.showAttributDetail
                                          ),
                                          expression:
                                            "!(form.quoteVehicle.useAttribute == '1' && showAttributDetail)",
                                        },
                                      ],
                                      attrs: { span: 8 },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          ref: "quoteVehicle.useAttribute",
                                          attrs: {
                                            label: "使用性质",
                                            prop: "quoteVehicle.useAttribute",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: { display: "flex" },
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    filterable: "",
                                                    placeholder: "请选择",
                                                    clearable: false,
                                                    disabled:
                                                      _vm.opType ==
                                                      "underwriting",
                                                  },
                                                  on: {
                                                    change:
                                                      _vm.changeUseAttribute,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form.quoteVehicle
                                                        .useAttribute,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form.quoteVehicle,
                                                        "useAttribute",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.quoteVehicle.useAttribute",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.USE_ATTRIBUTE,
                                                  function (item, index) {
                                                    return _c("el-option", {
                                                      key: index,
                                                      attrs: {
                                                        label: item.dictName,
                                                        value: item.dictCode,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.form.quoteVehicle.useAttribute == "1" &&
                                  _vm.showAttributDetail
                                    ? _c(
                                        "el-col",
                                        { attrs: { span: 8 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              ref: "quoteVehicle.useAttributeDetail",
                                              attrs: {
                                                label: "使用性质",
                                                prop: "quoteVehicle.useAttributeDetail",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      staticStyle: {
                                                        width: "50%",
                                                      },
                                                      attrs: {
                                                        filterable: "",
                                                        placeholder: "请选择",
                                                        clearable: false,
                                                        disabled:
                                                          _vm.opType ==
                                                          "underwriting",
                                                      },
                                                      on: {
                                                        change:
                                                          _vm.changeUseAttribute,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form.quoteVehicle
                                                            .useAttribute,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form
                                                              .quoteVehicle,
                                                            "useAttribute",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.quoteVehicle.useAttribute",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.USE_ATTRIBUTE,
                                                      function (item, index) {
                                                        return _c("el-option", {
                                                          key: index,
                                                          attrs: {
                                                            label:
                                                              item.dictName,
                                                            value:
                                                              item.dictCode,
                                                          },
                                                        })
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                  _vm.form.quoteVehicle
                                                    .useAttribute == "1" &&
                                                  _vm.showAttributDetail
                                                    ? _c(
                                                        "el-select",
                                                        {
                                                          staticStyle: {
                                                            width: "50%",
                                                            "margin-left":
                                                              "3px",
                                                          },
                                                          attrs: {
                                                            filterable: "",
                                                            placeholder:
                                                              "请选择",
                                                            clearable: "",
                                                            disabled:
                                                              _vm.opType ==
                                                              "underwriting",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.form
                                                                .quoteVehicle
                                                                .useAttributeDetail,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.form
                                                                  .quoteVehicle,
                                                                "useAttributeDetail",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "form.quoteVehicle.useAttributeDetail",
                                                          },
                                                        },
                                                        _vm._l(
                                                          _vm.USE_ATTRIBUTE_BRANCH,
                                                          function (
                                                            item,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "el-option",
                                                              {
                                                                key: index,
                                                                attrs: {
                                                                  label:
                                                                    item.dictName,
                                                                  value:
                                                                    item.dictCode,
                                                                },
                                                              }
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          ref: "quoteVehicle.fuelType",
                                          attrs: {
                                            label: "能源类型",
                                            prop: "quoteVehicle.fuelType",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                filterable: "",
                                                placeholder: "请选择能源类型",
                                                clearable: false,
                                                disabled:
                                                  _vm.opType == "underwriting",
                                              },
                                              on: {
                                                change: _vm.changeFuelType,
                                              },
                                              model: {
                                                value:
                                                  _vm.form.quoteVehicle
                                                    .fuelType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form.quoteVehicle,
                                                    "fuelType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.quoteVehicle.fuelType",
                                              },
                                            },
                                            _vm._l(
                                              _vm.ENGRGY_TYPES,
                                              function (item, index) {
                                                return _c("el-option", {
                                                  key: index,
                                                  attrs: {
                                                    label: item.dictName,
                                                    value: item.dictCode,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          ref: "quoteVehicle.wholeWeight",
                                          attrs: {
                                            label: "整备质量(吨)",
                                            prop: "quoteVehicle.wholeWeight",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              type: "text",
                                              placeholder: "请输入整备质量(吨)",
                                              clearable: "",
                                              disabled:
                                                _vm.opType == "underwriting",
                                            },
                                            on: {
                                              input: function ($event) {
                                                _vm.form.quoteVehicle.wholeWeight =
                                                  _vm.onInput(
                                                    _vm.form.quoteVehicle
                                                      .wholeWeight,
                                                    0,
                                                    1000,
                                                    3
                                                  )
                                              },
                                            },
                                            nativeOn: {
                                              keyup: function ($event) {
                                                _vm.form.quoteVehicle.wholeWeight =
                                                  _vm.onInput(
                                                    _vm.form.quoteVehicle
                                                      .wholeWeight,
                                                    0,
                                                    1000,
                                                    3
                                                  )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.form.quoteVehicle
                                                  .wholeWeight,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form.quoteVehicle,
                                                  "wholeWeight",
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                                )
                                              },
                                              expression:
                                                "form.quoteVehicle.wholeWeight",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                { attrs: { gutter: 0 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          ref: "quoteVehicle.tonNumber",
                                          attrs: {
                                            label: "核定载质量(吨)",
                                            prop: "quoteVehicle.tonNumber",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            ref: "quoteVehicleTonNumber",
                                            attrs: {
                                              type: "text",
                                              placeholder:
                                                "请输入核定载质量(吨)",
                                              clearable: "",
                                              disabled:
                                                _vm.opType == "underwriting",
                                            },
                                            on: {
                                              input: function ($event) {
                                                _vm.form.quoteVehicle.tonNumber =
                                                  _vm.onInput(
                                                    _vm.form.quoteVehicle
                                                      .tonNumber,
                                                    0,
                                                    1000,
                                                    3
                                                  )
                                              },
                                            },
                                            nativeOn: {
                                              keyup: function ($event) {
                                                _vm.form.quoteVehicle.tonNumber =
                                                  _vm.onInput(
                                                    _vm.form.quoteVehicle
                                                      .tonNumber,
                                                    0,
                                                    1000,
                                                    3
                                                  )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.form.quoteVehicle.tonNumber,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form.quoteVehicle,
                                                  "tonNumber",
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                                )
                                              },
                                              expression:
                                                "form.quoteVehicle.tonNumber",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          ref: "quoteVehicle.seats",
                                          attrs: {
                                            label: "座位数",
                                            prop: "quoteVehicle.seats",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              type: "text",
                                              placeholder: "请输入座位数",
                                              clearable: "",
                                              disabled:
                                                _vm.opType == "underwriting",
                                            },
                                            on: {
                                              input: function ($event) {
                                                _vm.form.quoteVehicle.seats =
                                                  _vm.onInput(
                                                    _vm.form.quoteVehicle.seats,
                                                    null,
                                                    100,
                                                    0
                                                  )
                                              },
                                            },
                                            nativeOn: {
                                              keyup: function ($event) {
                                                _vm.form.quoteVehicle.seats =
                                                  _vm.onInput(
                                                    _vm.form.quoteVehicle.seats,
                                                    null,
                                                    100,
                                                    0
                                                  )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.form.quoteVehicle.seats,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form.quoteVehicle,
                                                  "seats",
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                                )
                                              },
                                              expression:
                                                "form.quoteVehicle.seats",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          ref: "quoteVehicle.displacement",
                                          attrs: {
                                            label: "排量(升)",
                                            prop: "quoteVehicle.displacement",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            ref: "quoteVehicleDisplacement",
                                            attrs: {
                                              type: "text",
                                              placeholder: "请输入排量(升)",
                                              clearable: "",
                                              disabled:
                                                _vm.opType == "underwriting",
                                            },
                                            on: {
                                              input: function ($event) {
                                                _vm.form.quoteVehicle.displacement =
                                                  _vm.onInput(
                                                    _vm.form.quoteVehicle
                                                      .displacement,
                                                    0,
                                                    100,
                                                    3
                                                  )
                                              },
                                            },
                                            nativeOn: {
                                              keyup: function ($event) {
                                                _vm.form.quoteVehicle.displacement =
                                                  _vm.onInput(
                                                    _vm.form.quoteVehicle
                                                      .displacement,
                                                    0,
                                                    100,
                                                    3
                                                  )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.form.quoteVehicle
                                                  .displacement,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form.quoteVehicle,
                                                  "displacement",
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                                )
                                              },
                                              expression:
                                                "form.quoteVehicle.displacement",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                { attrs: { gutter: 0 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          ref: "quoteVehicle.registerDate",
                                          attrs: {
                                            label: "注册日期",
                                            prop: "quoteVehicle.registerDate",
                                          },
                                        },
                                        [
                                          _c("el-date-picker", {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              "value-format": "yyyy-MM-dd",
                                              type: "date",
                                              placeholder: "选择日期",
                                              disabled:
                                                _vm.opType == "underwriting",
                                              "picker-options":
                                                _vm.disabledLessThan,
                                            },
                                            on: {
                                              change: _vm.registerDateChange,
                                            },
                                            model: {
                                              value:
                                                _vm.form.quoteVehicle
                                                  .registerDate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form.quoteVehicle,
                                                  "registerDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.quoteVehicle.registerDate",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          ref: "quoteVehicle.transferFlag",
                                          attrs: {
                                            label: "是否过户车",
                                            prop: "quoteVehicle.transferFlag",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio-group",
                                            {
                                              attrs: {
                                                disabled:
                                                  _vm.opType == "underwriting",
                                              },
                                              model: {
                                                value:
                                                  _vm.form.quoteVehicle
                                                    .transferFlag,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form.quoteVehicle,
                                                    "transferFlag",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.quoteVehicle.transferFlag",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-radio",
                                                { attrs: { label: true } },
                                                [_vm._v("是")]
                                              ),
                                              _c(
                                                "el-radio",
                                                { attrs: { label: false } },
                                                [_vm._v("否")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.form.quoteVehicle.transferFlag
                                    ? _c(
                                        "el-col",
                                        { attrs: { span: 8 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              ref: "quoteVehicle.transferDate",
                                              attrs: {
                                                label: "过户日期",
                                                prop: "quoteVehicle.transferDate",
                                              },
                                            },
                                            [
                                              _c("el-date-picker", {
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  "value-format": "yyyy-MM-dd",
                                                  type: "date",
                                                  placeholder: "选择日期",
                                                  disabled:
                                                    _vm.opType ==
                                                    "underwriting",
                                                  "picker-options":
                                                    _vm.disabledLessThan,
                                                },
                                                model: {
                                                  value:
                                                    _vm.form.quoteVehicle
                                                      .transferDate,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form.quoteVehicle,
                                                      "transferDate",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.quoteVehicle.transferDate",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "功率(KW)",
                                            prop: "quoteVehicle.power",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              type: "text",
                                              placeholder: "请输入功率(KW)",
                                              clearable: "",
                                              disabled:
                                                _vm.opType == "underwriting",
                                            },
                                            on: {
                                              input: function ($event) {
                                                _vm.form.quoteVehicle.power =
                                                  _vm.onInput(
                                                    _vm.form.quoteVehicle.power,
                                                    0,
                                                    1000,
                                                    3
                                                  )
                                              },
                                            },
                                            nativeOn: {
                                              keyup: function ($event) {
                                                _vm.form.quoteVehicle.power =
                                                  _vm.onInput(
                                                    _vm.form.quoteVehicle.power,
                                                    0,
                                                    1000,
                                                    3
                                                  )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.form.quoteVehicle.power,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form.quoteVehicle,
                                                  "power",
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                                )
                                              },
                                              expression:
                                                "form.quoteVehicle.power",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.showCertificate
                                ? _c(
                                    "el-row",
                                    { attrs: { gutter: 0 } },
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 8 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              ref: "quoteVehicle.certificateType",
                                              attrs: {
                                                label: "车辆来历凭证种类",
                                                prop: "quoteVehicle.certificateType",
                                                "label-width": "130px",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    filterable: "",
                                                    placeholder:
                                                      "请选择车辆来历凭证种类",
                                                    clearable: false,
                                                    disabled:
                                                      _vm.opType ==
                                                      "underwriting",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form.quoteVehicle
                                                        .certificateType,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form.quoteVehicle,
                                                        "certificateType",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.quoteVehicle.certificateType",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.CERTIFICATE_TYPE,
                                                  function (item, index) {
                                                    return _c("el-option", {
                                                      key: index,
                                                      attrs: {
                                                        label: item.dictName,
                                                        value: item.dictCode,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 8 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              ref: "quoteVehicle.certificateNo",
                                              attrs: {
                                                label: "车辆来历凭证编号",
                                                prop: "quoteVehicle.certificateNo",
                                                "label-width": "130px",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  maxlength: "40",
                                                  placeholder:
                                                    "请输入车辆来历凭证编号",
                                                  clearable: "",
                                                  disabled:
                                                    _vm.opType ==
                                                    "underwriting",
                                                },
                                                on: { input: _vm.inputOrigin },
                                                model: {
                                                  value:
                                                    _vm.form.quoteVehicle
                                                      .certificateNo,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form.quoteVehicle,
                                                      "certificateNo",
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.quoteVehicle.certificateNo",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 8 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              ref: "quoteVehicle.certificateDate",
                                              attrs: {
                                                label: "车辆来历所载日期",
                                                prop: "quoteVehicle.certificateDate",
                                                "label-width": "130px",
                                              },
                                            },
                                            [
                                              _c("el-date-picker", {
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  "value-format":
                                                    "yyyy-MM-dd HH:mm:ss",
                                                  type: "date",
                                                  placeholder:
                                                    "请选择车辆来历所载日期",
                                                  disabled:
                                                    _vm.opType ==
                                                    "underwriting",
                                                  "picker-options":
                                                    _vm.disabledLessThan,
                                                },
                                                model: {
                                                  value:
                                                    _vm.form.quoteVehicle
                                                      .certificateDate,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form.quoteVehicle,
                                                      "certificateDate",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.quoteVehicle.certificateDate",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "el-row",
                                { attrs: { gutter: 0 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          ref: "quoteVehicle.issueDate",
                                          attrs: {
                                            label: "行驶证发证日期",
                                            prop: "quoteVehicle.issueDate",
                                            "label-width": "130px",
                                          },
                                        },
                                        [
                                          _c("el-date-picker", {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              "value-format":
                                                "yyyy-MM-dd HH:mm:ss",
                                              type: "date",
                                              placeholder:
                                                "请选择行驶证发证日期",
                                              disabled:
                                                _vm.opType == "underwriting",
                                              "picker-options":
                                                _vm.disabledLessThan,
                                            },
                                            model: {
                                              value:
                                                _vm.form.quoteVehicle.issueDate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form.quoteVehicle,
                                                  "issueDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.quoteVehicle.issueDate",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.insuranceCompanyList.some(function (ele) {
                                    return ele.insCorpCode === "PAIC"
                                  })
                                    ? _c(
                                        "el-col",
                                        { attrs: { span: 8 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              ref: "temporaryQuoteExtend.offerLastPolicyFlag",
                                              attrs: {
                                                label: "已提供上年商业险保单",
                                                prop: "temporaryQuoteExtend.offerLastPolicyFlag",
                                                "label-width": "155px",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  attrs: { slot: "label" },
                                                  slot: "label",
                                                },
                                                [
                                                  _c("div", [
                                                    _vm._v(
                                                      "已提供上年商业险保单"
                                                    ),
                                                  ]),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        color: "red",
                                                        "line-height": "2px",
                                                      },
                                                    },
                                                    [_vm._v("(平安专用)")]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "el-radio-group",
                                                {
                                                  attrs: {
                                                    disabled:
                                                      _vm.opType ==
                                                      "underwriting",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form
                                                        .temporaryQuoteExtend
                                                        .offerLastPolicyFlag,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form
                                                          .temporaryQuoteExtend,
                                                        "offerLastPolicyFlag",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.temporaryQuoteExtend.offerLastPolicyFlag",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-radio",
                                                    { attrs: { label: "Y" } },
                                                    [_vm._v("是")]
                                                  ),
                                                  _c(
                                                    "el-radio",
                                                    { attrs: { label: "N" } },
                                                    [_vm._v("否")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.insuranceCompanyList.some(function (ele) {
                                    return ele.insCorpCode === "PAIC"
                                  })
                                    ? _c(
                                        "el-col",
                                        { attrs: { span: 8 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              ref: "temporaryQuoteExtend.isOfferLocalUseProof",
                                              attrs: {
                                                label: "已提供本地使用证明",
                                                prop: "temporaryQuoteExtend.isOfferLocalUseProof",
                                                "label-width": "140px",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  attrs: { slot: "label" },
                                                  slot: "label",
                                                },
                                                [
                                                  _c("div", [
                                                    _vm._v(
                                                      "已提供本地使用证明"
                                                    ),
                                                  ]),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        color: "red",
                                                        "line-height": "2px",
                                                      },
                                                    },
                                                    [_vm._v("(平安专用)")]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "el-radio-group",
                                                {
                                                  attrs: {
                                                    disabled:
                                                      _vm.opType ==
                                                      "underwriting",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form
                                                        .temporaryQuoteExtend
                                                        .isOfferLocalUseProof,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form
                                                          .temporaryQuoteExtend,
                                                        "isOfferLocalUseProof",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.temporaryQuoteExtend.isOfferLocalUseProof",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-radio",
                                                    { attrs: { label: "Y" } },
                                                    [_vm._v("是")]
                                                  ),
                                                  _c(
                                                    "el-radio",
                                                    { attrs: { label: "N" } },
                                                    [_vm._v("否")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm.selectInsuranceCompanys.some(function (ele) {
                                return _vm._.toLower(ele.insCorpCode) === "picc"
                              }) &&
                              _vm.form.quoteExtend &&
                              _vm.form.quoteExtend.PICC
                                ? _c(
                                    "el-row",
                                    { attrs: { gutter: 0 } },
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 8 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              ref: "quoteExtend.PICC.isEcdemic",
                                              staticClass: "isEcdemic",
                                              attrs: {
                                                label: "是否外地车",
                                                prop: "quoteExtend.PICC.isEcdemic",
                                                rules: [
                                                  {
                                                    required:
                                                      _vm.selectInsuranceCompanys.some(
                                                        function (ele) {
                                                          return (
                                                            _vm._.toLower(
                                                              ele.insCorpCode
                                                            ) === "picc"
                                                          )
                                                        }
                                                      ) &&
                                                      _vm.form.quoteExtend &&
                                                      _vm.form.quoteExtend
                                                        .PICC &&
                                                      _vm.opType == "insure",
                                                    message:
                                                      "请选择是否是外地车",
                                                    trigger: "change",
                                                  },
                                                ],
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  attrs: { slot: "label" },
                                                  slot: "label",
                                                },
                                                [
                                                  _c("div", [
                                                    _vm._v("是否外地车"),
                                                  ]),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        color: "red",
                                                        "line-height": "2px",
                                                      },
                                                    },
                                                    [_vm._v("(人保专用)")]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "el-radio-group",
                                                {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    placeholder:
                                                      "请选择是否是外地车",
                                                    clearable: "",
                                                    disabled:
                                                      _vm.opType ==
                                                      "underwriting",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form.quoteExtend.PICC
                                                        .isEcdemic,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form.quoteExtend
                                                          .PICC,
                                                        "isEcdemic",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.quoteExtend.PICC.isEcdemic",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-radio",
                                                    { attrs: { label: 1 } },
                                                    [_vm._v("是")]
                                                  ),
                                                  _c(
                                                    "el-radio",
                                                    { attrs: { label: 0 } },
                                                    [_vm._v("否")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 8 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              ref: "quoteExtend.PICC.localUse",
                                              staticClass: "localUse",
                                              attrs: {
                                                label: "本地使用",
                                                prop: "quoteExtend.PICC.localUse",
                                                rules: [
                                                  {
                                                    required:
                                                      _vm.selectInsuranceCompanys.some(
                                                        function (ele) {
                                                          return (
                                                            _vm._.toLower(
                                                              ele.insCorpCode
                                                            ) === "picc"
                                                          )
                                                        }
                                                      ) &&
                                                      _vm.form.quoteExtend &&
                                                      _vm.form.quoteExtend
                                                        .PICC &&
                                                      _vm.opType == "insure",
                                                    message:
                                                      "请选择是否本地使用",
                                                    trigger: "change",
                                                  },
                                                ],
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  attrs: { slot: "label" },
                                                  slot: "label",
                                                },
                                                [
                                                  _c("div", [
                                                    _vm._v("本地使用"),
                                                  ]),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        color: "red",
                                                        "line-height": "2px",
                                                      },
                                                    },
                                                    [_vm._v("(人保专用)")]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "el-radio-group",
                                                {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    placeholder:
                                                      "请选择是否本地使用",
                                                    clearable: "",
                                                    disabled:
                                                      _vm.opType ==
                                                      "underwriting",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form.quoteExtend.PICC
                                                        .localUse,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form.quoteExtend
                                                          .PICC,
                                                        "localUse",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.quoteExtend.PICC.localUse",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-radio",
                                                    { attrs: { label: 1 } },
                                                    [_vm._v("是")]
                                                  ),
                                                  _c(
                                                    "el-radio",
                                                    { attrs: { label: 0 } },
                                                    [_vm._v("否")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 8 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              ref: "quoteExtend.PICC.localPlateNo",
                                              staticClass: "localPlateNo",
                                              attrs: {
                                                label: "本地上牌",
                                                prop: "quoteExtend.PICC.localPlateNo",
                                                "label-width": "130px",
                                                rules: [
                                                  {
                                                    required:
                                                      _vm.selectInsuranceCompanys.some(
                                                        function (ele) {
                                                          return (
                                                            _vm._.toLower(
                                                              ele.insCorpCode
                                                            ) === "picc"
                                                          )
                                                        }
                                                      ) &&
                                                      _vm.form.quoteExtend &&
                                                      _vm.form.quoteExtend
                                                        .PICC &&
                                                      _vm.opType == "insure",
                                                    message:
                                                      "请选择是否本地上牌",
                                                    trigger: "change",
                                                  },
                                                ],
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  attrs: { slot: "label" },
                                                  slot: "label",
                                                },
                                                [
                                                  _c("div", [
                                                    _vm._v("本地上牌"),
                                                  ]),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        color: "red",
                                                        "line-height": "2px",
                                                      },
                                                    },
                                                    [_vm._v("(人保专用)")]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "el-radio-group",
                                                {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    placeholder:
                                                      "请选择是否本地使用",
                                                    clearable: "",
                                                    disabled:
                                                      _vm.opType ==
                                                      "underwriting",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form.quoteExtend.PICC
                                                        .localPlateNo,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form.quoteExtend
                                                          .PICC,
                                                        "localPlateNo",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.quoteExtend.PICC.localPlateNo",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-radio",
                                                    { attrs: { label: 1 } },
                                                    [_vm._v("是")]
                                                  ),
                                                  _c(
                                                    "el-radio",
                                                    { attrs: { label: 0 } },
                                                    [_vm._v("否")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "el-row",
                                { attrs: { gutter: 0 } },
                                [
                                  _vm.insuranceCompanyList.some(function (ele) {
                                    return ele.insCorpCode === "PICC"
                                  }) &&
                                  _vm.form.quoteExtend &&
                                  _vm.form.quoteExtend.PICC
                                    ? _c(
                                        "el-col",
                                        { attrs: { span: 8 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              ref: "quoteExtend.PICC.projectCode",
                                              attrs: {
                                                label: "项目代码",
                                                prop: "quoteExtend.PICC.projectCode",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  attrs: { slot: "label" },
                                                  slot: "label",
                                                },
                                                [
                                                  _c("div", [
                                                    _vm._v("项目代码"),
                                                  ]),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        color: "red",
                                                        "line-height": "2px",
                                                      },
                                                    },
                                                    [_vm._v("(人保专用)")]
                                                  ),
                                                ]
                                              ),
                                              _c("el-input", {
                                                attrs: {
                                                  maxlength: "40",
                                                  placeholder: "请输入项目代码",
                                                  clearable: "",
                                                  disabled:
                                                    _vm.opType ==
                                                    "underwriting",
                                                },
                                                nativeOn: {
                                                  keyup: function ($event) {
                                                    _vm.form.quoteExtend.PICC.projectCode =
                                                      _vm.form.quoteExtend.PICC.projectCode.replace(
                                                        /[\W]/g,
                                                        ""
                                                      )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.form.quoteExtend.PICC
                                                      .projectCode,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form.quoteExtend.PICC,
                                                      "projectCode",
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.quoteExtend.PICC.projectCode",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-collapse",
                        {
                          staticStyle: { "margin-top": "10px" },
                          model: {
                            value: _vm.activeThree,
                            callback: function ($$v) {
                              _vm.activeThree = $$v
                            },
                            expression: "activeThree",
                          },
                        },
                        [
                          _c(
                            "el-collapse-item",
                            {
                              staticClass: "collapse-item-style",
                              attrs: { name: "1" },
                            },
                            [
                              _c("template", { slot: "title" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "title",
                                    staticStyle: {
                                      flex: "1",
                                      "line-height": "25px",
                                      display: "flex",
                                      "flex-direction": "row",
                                      "justify-content": "space-between",
                                      "align-items": "center",
                                    },
                                  },
                                  [
                                    _c("FormLabel", {
                                      attrs: { "form-label": "车主信息" },
                                    }),
                                    _vm.opType == "insure"
                                      ? _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "margin-right": "10px",
                                              position: "relative",
                                              "z-index": "9",
                                            },
                                            on: {
                                              mouseenter: function ($event) {
                                                _vm.iconOwnerShow = false
                                              },
                                              mouseleave: function ($event) {
                                                _vm.iconOwnerShow = true
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  size: "mini",
                                                  type: "primary",
                                                  plain: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.identificationOfIDCardClicked(
                                                      "owner"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("svg-icon", {
                                                  staticStyle: {
                                                    "font-size": "12px",
                                                    "margin-right": "2px",
                                                  },
                                                  attrs: {
                                                    "class-name":
                                                      "ident-icon_owner",
                                                    "icon-class":
                                                      _vm.iconOwnerShow
                                                        ? "identificationPerson"
                                                        : "identificationPersonHover",
                                                  },
                                                }),
                                                _vm._v(" 证件识别 "),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]),
                              _c("CustomerBaseInfo", {
                                ref: "owner",
                                attrs: {
                                  sourceId: _vm.sourceId,
                                  label: "车主",
                                  data: _vm.form.owner,
                                  "insurance-area": _vm.form.insCityCode,
                                  formRules: _vm.formRules,
                                  isDisabled: _vm.opType == "underwriting",
                                },
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-collapse",
                        {
                          staticStyle: { "margin-top": "10px" },
                          model: {
                            value: _vm.activeFour,
                            callback: function ($$v) {
                              _vm.activeFour = $$v
                            },
                            expression: "activeFour",
                          },
                        },
                        [
                          _c(
                            "el-collapse-item",
                            {
                              staticClass: "collapse-item-style",
                              attrs: { name: "1" },
                            },
                            [
                              _c("template", { slot: "title" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "title",
                                    staticStyle: { "line-height": "25px" },
                                  },
                                  [
                                    _c("FormLabel", {
                                      attrs: { "form-label": "关系人信息" },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "flex-direction": "row",
                                    "justify-content": "space-between",
                                    "align-items": "center",
                                    "line-height": "40px",
                                    "padding-left": "18px",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "flex-direction": "row",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "font-size": "13px",
                                            "font-weight": "bold",
                                            "margin-right": "8px",
                                          },
                                        },
                                        [_vm._v("被保人")]
                                      ),
                                      _c(
                                        "el-checkbox",
                                        {
                                          attrs: {
                                            disabled:
                                              _vm.opType == "underwriting",
                                          },
                                          on: {
                                            change: _vm.applicantOrInsureChange,
                                          },
                                          model: {
                                            value: _vm.form.insured.sameAsOwner,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form.insured,
                                                "sameAsOwner",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.insured.sameAsOwner",
                                          },
                                        },
                                        [_vm._v("同车主")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.opType == "insure" &&
                                  !_vm.form.insured.sameAsOwner
                                    ? _c(
                                        "div",
                                        {
                                          on: {
                                            mouseenter: function ($event) {
                                              _vm.iconInsuredShow = false
                                            },
                                            mouseleave: function ($event) {
                                              _vm.iconInsuredShow = true
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                size: "mini",
                                                type: "primary",
                                                plain: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.identificationOfIDCardClicked(
                                                    "insured"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("svg-icon", {
                                                staticStyle: {
                                                  "font-size": "14px",
                                                  "margin-right": "2px",
                                                },
                                                attrs: {
                                                  "class-name":
                                                    "ident-icon_insured",
                                                  "icon-class":
                                                    _vm.iconInsuredShow
                                                      ? "identificationPerson"
                                                      : "identificationPersonHover",
                                                },
                                              }),
                                              _vm._v(" 证件识别 "),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _c("CustomerBaseInfo", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.form.insured.sameAsOwner,
                                    expression: "!form.insured.sameAsOwner",
                                  },
                                ],
                                ref: "insured",
                                attrs: {
                                  sourceId: _vm.sourceId,
                                  label: "被保人",
                                  data: _vm.form.insured,
                                  formRules: _vm.formRules,
                                  "insurance-area": _vm.form.insCityCode,
                                  isDisabled: _vm.opType == "underwriting",
                                },
                              }),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "flex-direction": "row",
                                    "justify-content": "space-between",
                                    "align-items": "center",
                                    "line-height": "40px",
                                    "padding-left": "18px",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { display: "flex" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "font-size": "13px",
                                            "font-weight": "bold",
                                            "margin-right": "8px",
                                          },
                                        },
                                        [_vm._v("投保人")]
                                      ),
                                      !_vm.form.applicant.sameAsInsurer
                                        ? _c(
                                            "el-checkbox",
                                            {
                                              attrs: {
                                                disabled:
                                                  _vm.opType == "underwriting",
                                              },
                                              on: {
                                                change:
                                                  _vm.applicantOrInsureChange,
                                              },
                                              model: {
                                                value:
                                                  _vm.form.applicant
                                                    .applicantSameAsOwner,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form.applicant,
                                                    "applicantSameAsOwner",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.applicant.applicantSameAsOwner",
                                              },
                                            },
                                            [_vm._v("同车主")]
                                          )
                                        : _vm._e(),
                                      !_vm.form.applicant.applicantSameAsOwner
                                        ? _c(
                                            "el-checkbox",
                                            {
                                              attrs: {
                                                disabled:
                                                  _vm.opType == "underwriting",
                                              },
                                              on: {
                                                change:
                                                  _vm.applicantOrInsureChange,
                                              },
                                              model: {
                                                value:
                                                  _vm.form.applicant
                                                    .sameAsInsurer,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form.applicant,
                                                    "sameAsInsurer",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.applicant.sameAsInsurer",
                                              },
                                            },
                                            [_vm._v("同被保人")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _vm.opType == "insure" &&
                                  !_vm.form.applicant.applicantSameAsOwner &&
                                  !_vm.form.applicant.sameAsInsurer
                                    ? _c(
                                        "div",
                                        {
                                          on: {
                                            mouseenter: function ($event) {
                                              _vm.iconApplicantShow = false
                                            },
                                            mouseleave: function ($event) {
                                              _vm.iconApplicantShow = true
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              staticClass: "identButton",
                                              attrs: {
                                                size: "mini",
                                                type: "primary",
                                                plain: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.identificationOfIDCardClicked(
                                                    "applicant"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("svg-icon", {
                                                staticStyle: {
                                                  "font-size": "14px",
                                                },
                                                attrs: {
                                                  "class-name":
                                                    "ident-icon_applicant",
                                                  "icon-class":
                                                    _vm.iconApplicantShow
                                                      ? "identificationPerson"
                                                      : "identificationPersonHover",
                                                },
                                              }),
                                              _vm._v(" 证件识别 "),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _c("CustomerBaseInfo", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      !_vm.form.applicant
                                        .applicantSameAsOwner &&
                                      !_vm.form.applicant.sameAsInsurer,
                                    expression:
                                      "!form.applicant.applicantSameAsOwner && !form.applicant.sameAsInsurer",
                                  },
                                ],
                                ref: "applicant",
                                attrs: {
                                  sourceId: _vm.sourceId,
                                  label: "投保人",
                                  data: _vm.form.applicant,
                                  formRules: _vm.formRules,
                                  "insurance-area": _vm.form.insCityCode,
                                  isDisabled: _vm.opType == "underwriting",
                                },
                              }),
                              _c("OperatorBaseInfo", {
                                ref: "operator",
                                attrs: {
                                  opType: _vm.opType,
                                  sourceId: _vm.sourceId,
                                  showOperator:
                                    (_vm.form.owner.relationshipAttribute &&
                                      _vm.form.owner.relationshipAttribute !=
                                        "0") ||
                                    (_vm.form.insured.relationshipAttribute &&
                                      _vm.form.insured.relationshipAttribute !=
                                        "0") ||
                                    (_vm.form.applicant.relationshipAttribute &&
                                      _vm.form.applicant
                                        .relationshipAttribute != "0"),
                                  "insurance-area": _vm.form.insCityCode,
                                  label: "经办人",
                                  operatorData: _vm.form.operator,
                                  formRules: _vm.formRules,
                                  isDisabled: _vm.opType == "underwriting",
                                },
                                on: {
                                  setOperatorName: _vm.setOperatorName,
                                  operatorIdentificationOfIDCardClicked:
                                    function ($event) {
                                      return _vm.identificationOfIDCardClicked(
                                        "operator"
                                      )
                                    },
                                },
                              }),
                              _vm.canNeedAML
                                ? _c(
                                    "div",
                                    {
                                      key: "amlInfo",
                                      staticStyle: {
                                        background: "#f5f5f5",
                                        padding: "10px",
                                      },
                                      attrs: { id: "amlInfo" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "font-size": "13px",
                                            "font-weight": "bold",
                                          },
                                        },
                                        [_vm._v("反洗钱信息")]
                                      ),
                                      _c(
                                        "el-row",
                                        { attrs: { gutter: 0 } },
                                        [
                                          _vm.form.applicant
                                            .relationshipAttribute != "0"
                                            ? _c(
                                                "el-col",
                                                { attrs: { span: 24 } },
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        label: "经营范围",
                                                        prop: "businessScope",
                                                      },
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        attrs: {
                                                          maxlength: "300",
                                                          type: "textarea",
                                                          row: "5",
                                                          placeholder:
                                                            "请输入经营范围",
                                                          clearable: "",
                                                          disabled:
                                                            _vm.opType !=
                                                            "insure",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form
                                                              .businessScope,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form,
                                                              "businessScope",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form.businessScope",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.form.applicant
                                            .relationshipAttribute == "0"
                                            ? _c(
                                                "el-col",
                                                { attrs: { span: 12 } },
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        label: "职业：",
                                                        prop: "profession",
                                                      },
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        attrs: {
                                                          maxlength: "100",
                                                          type: "text",
                                                          placeholder:
                                                            "请输入职业",
                                                          clearable: "",
                                                          disabled:
                                                            _vm.opType !=
                                                            "insure",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form.profession,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form,
                                                              "profession",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form.profession",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.form.applicant
                                            .relationshipAttribute == "0"
                                            ? _c(
                                                "el-col",
                                                { attrs: { span: 12 } },
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        label: "工作单位：",
                                                        prop: "workAddress",
                                                      },
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        attrs: {
                                                          maxlength: "100",
                                                          type: "text",
                                                          placeholder:
                                                            "请输入工作单位",
                                                          clearable: "",
                                                          disabled:
                                                            _vm.opType !=
                                                            "insure",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form
                                                              .workAddress,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form,
                                                              "workAddress",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form.workAddress",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _vm.opType == "insure"
                        ? _c(
                            "el-collapse",
                            {
                              staticStyle: { "margin-top": "10px" },
                              model: {
                                value: _vm.activeFive,
                                callback: function ($$v) {
                                  _vm.activeFive = $$v
                                },
                                expression: "activeFive",
                              },
                            },
                            [
                              _c(
                                "el-collapse-item",
                                {
                                  staticClass: "collapse-item-style",
                                  attrs: { name: "1" },
                                },
                                [
                                  _c("template", { slot: "title" }, [
                                    _c(
                                      "div",
                                      {
                                        key: "selectIns",
                                        staticClass: "title",
                                        staticStyle: { "line-height": "25px" },
                                        attrs: { id: "selectIns" },
                                      },
                                      [
                                        _c("FormLabel", {
                                          attrs: { "form-label": "投保方案" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c(
                                    "el-row",
                                    { attrs: { gutter: 0 } },
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 4 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              staticClass:
                                                "special_row_content",
                                              attrs: { "label-width": "15px" },
                                            },
                                            [
                                              _c(
                                                "el-checkbox",
                                                {
                                                  attrs: {
                                                    disabled:
                                                      _vm.opType ==
                                                      "underwriting",
                                                  },
                                                  model: {
                                                    value: _vm.form.isBz,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "isBz",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "form.isBz",
                                                  },
                                                },
                                                [_vm._v("交强险(车船税)")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 20 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              staticClass:
                                                "special_row_content",
                                              attrs: { "label-width": "130px" },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                    "justify-content":
                                                      "flex-end",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "margin-left": "5px",
                                                        "margin-right": "5px",
                                                        color: "#515151",
                                                        "font-size": "13px",
                                                      },
                                                    },
                                                    [_vm._v("保险期间:")]
                                                  ),
                                                  _c("el-date-picker", {
                                                    staticClass:
                                                      "time-select-class",
                                                    staticStyle: {
                                                      width: "135px",
                                                      color: "#515151",
                                                      "font-size": "13px",
                                                    },
                                                    attrs: {
                                                      "value-format":
                                                        "yyyy-MM-dd",
                                                      disabled: !_vm.form.isBz,
                                                      "picker-options":
                                                        _vm.disabledBzPickerOptions,
                                                      type: "date",
                                                      placeholder: "起保日期",
                                                      clearable: false,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.datePickerChange(
                                                          _vm.form,
                                                          false,
                                                          true
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form
                                                          .bzEffectiveStartTime,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "bzEffectiveStartTime",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.bzEffectiveStartTime",
                                                    },
                                                  }),
                                                  _c("el-time-select", {
                                                    staticClass:
                                                      "time-select-class",
                                                    staticStyle: {
                                                      "margin-left": "2px",
                                                      width: "95px",
                                                      color: "#515151",
                                                      "font-size": "13px",
                                                    },
                                                    attrs: {
                                                      disabled:
                                                        !_vm.form.isBz ||
                                                        _vm.form.insCityCode ==
                                                          "110100",
                                                      "picker-options":
                                                        _vm.defaultBzTimeOptions,
                                                      placeholder: "时间",
                                                      clearable: false,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.timePickerChange(
                                                          _vm.form
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form.bzStartTime,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "bzStartTime",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.bzStartTime",
                                                    },
                                                  }),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "margin-left": "5px",
                                                        "margin-right": "5px",
                                                        "font-size": "13px",
                                                      },
                                                    },
                                                    [_vm._v("~")]
                                                  ),
                                                  _c("el-date-picker", {
                                                    staticClass:
                                                      "time-select-class",
                                                    staticStyle: {
                                                      width: "135px",
                                                      color: "#515151",
                                                      "font-size": "13px",
                                                    },
                                                    attrs: {
                                                      "value-format":
                                                        "yyyy-MM-dd",
                                                      disabled: !_vm.form.isBz,
                                                      "picker-options":
                                                        _vm.endDateBzPicker,
                                                      type: "date",
                                                      placeholder: "终保日期",
                                                      clearable: false,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form
                                                          .bzEffectiveEndTime,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "bzEffectiveEndTime",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.bzEffectiveEndTime",
                                                    },
                                                  }),
                                                  _c("el-time-select", {
                                                    staticClass:
                                                      "time-select-class",
                                                    staticStyle: {
                                                      "margin-left": "2px",
                                                      width: "95px",
                                                      color: "#515151",
                                                      "font-size": "13px",
                                                    },
                                                    attrs: {
                                                      disabled:
                                                        !_vm.form.isBz ||
                                                        _vm.form.insCityCode ==
                                                          "110100",
                                                      "picker-options":
                                                        _vm.defaultTimeOptions,
                                                      placeholder: "时间",
                                                      clearable: false,
                                                    },
                                                    model: {
                                                      value: _vm.form.bzEndTime,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "bzEndTime",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.bzEndTime",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.form.isBz && _vm.quoteType == "1"
                                    ? _c(
                                        "el-row",
                                        { attrs: { gutter: 0 } },
                                        [
                                          _c(
                                            "el-col",
                                            { attrs: { span: 24 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticClass:
                                                    "special_row_content",
                                                  attrs: {
                                                    label: "纳税类型",
                                                    "label-width": "105px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-radio-group",
                                                    {
                                                      attrs: {
                                                        disabled:
                                                          _vm.opType ==
                                                          "underwriting",
                                                      },
                                                      on: {
                                                        change: _vm.paymentType,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form
                                                            .quoteVehicleTax
                                                            .taxType,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form
                                                              .quoteVehicleTax,
                                                            "taxType",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.quoteVehicleTax.taxType",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.dictSource[
                                                        "TAX_TYPE"
                                                      ],
                                                      function (item, index) {
                                                        return _c(
                                                          "el-radio",
                                                          {
                                                            key: index,
                                                            style: {
                                                              display:
                                                                item.dictCode ==
                                                                  "05" &&
                                                                _vm.form
                                                                  .insCityCode !=
                                                                  "310100"
                                                                  ? "none"
                                                                  : null,
                                                            },
                                                            attrs: {
                                                              label:
                                                                item.dictCode,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.dictName
                                                              )
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.form.isBz &&
                                  _vm.quoteType == "1" &&
                                  _vm.form.quoteVehicleTax.taxType !=
                                    _vm.dictSource["TAX_TYPE"][0].dictCode &&
                                  _vm.form.quoteVehicleTax.taxType !=
                                    _vm.dictSource["TAX_TYPE"][4].dictCode
                                    ? _c(
                                        "div",
                                        {
                                          key: "quoteVehicleTax",
                                          staticStyle: {
                                            "background-color": "#f9f9f9",
                                            padding: "10px 10px 0 10px",
                                          },
                                          attrs: { id: "quoteVehicleTax" },
                                        },
                                        [
                                          _c(
                                            "el-row",
                                            { attrs: { gutter: 0 } },
                                            [
                                              _vm.form.quoteVehicleTax
                                                .taxType ==
                                                _vm.dictSource["TAX_TYPE"][1]
                                                  .dictCode ||
                                              _vm.form.quoteVehicleTax
                                                .taxType ==
                                                _vm.dictSource["TAX_TYPE"][2]
                                                  .dictCode
                                                ? _c(
                                                    "el-col",
                                                    { attrs: { span: 8 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            prop: "quoteVehicleTax.deductionDueCode",
                                                            "lable-width":
                                                              "130px",
                                                            label:
                                                              (_vm.form
                                                                .quoteVehicleTax
                                                                .taxType ==
                                                              _vm.dictSource[
                                                                "TAX_TYPE"
                                                              ][1].dictCode
                                                                ? "减"
                                                                : "免") +
                                                              "税原因：",
                                                            "label-width":
                                                              "120px",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-select",
                                                            {
                                                              staticStyle: {
                                                                width: "100%",
                                                              },
                                                              attrs: {
                                                                filterable: "",
                                                                placeholder:
                                                                  "请选择" +
                                                                  (_vm.form
                                                                    .quoteVehicleTax
                                                                    .taxType ==
                                                                  _vm
                                                                    .dictSource[
                                                                    "TAX_TYPE"
                                                                  ][1].dictCode
                                                                    ? "减"
                                                                    : "免") +
                                                                  "税原因",
                                                                clearable: false,
                                                                disabled:
                                                                  _vm.opType ==
                                                                  "underwriting",
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.dictChange(
                                                                      $event
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.form
                                                                    .quoteVehicleTax
                                                                    .deductionDueCode,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.form
                                                                        .quoteVehicleTax,
                                                                      "deductionDueCode",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "form.quoteVehicleTax.deductionDueCode",
                                                              },
                                                            },
                                                            _vm._l(
                                                              _vm.TAX_REASON,
                                                              function (
                                                                item,
                                                                index
                                                              ) {
                                                                return _c(
                                                                  "el-option",
                                                                  {
                                                                    key: index,
                                                                    attrs: {
                                                                      label:
                                                                        item.dictName,
                                                                      value:
                                                                        item.dictCode,
                                                                    },
                                                                  }
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.form.quoteVehicleTax
                                                .taxType ==
                                              _vm.dictSource["TAX_TYPE"][3]
                                                .dictCode
                                                ? _c(
                                                    "el-col",
                                                    { attrs: { span: 8 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            label:
                                                              "完税凭证号：",
                                                            "label-width":
                                                              "130px",
                                                            prop: "quoteVehicleTax.taxPayNo",
                                                          },
                                                        },
                                                        [
                                                          _c("el-input", {
                                                            attrs: {
                                                              maxlength: "40",
                                                              placeholder:
                                                                "请输入完税凭证号",
                                                              clearable: "",
                                                              disabled:
                                                                _vm.opType ==
                                                                "underwriting",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.form
                                                                  .quoteVehicleTax
                                                                  .taxPayNo,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.form
                                                                      .quoteVehicleTax,
                                                                    "taxPayNo",
                                                                    typeof $$v ===
                                                                      "string"
                                                                      ? $$v.trim()
                                                                      : $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "form.quoteVehicleTax.taxPayNo",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "el-col",
                                                { attrs: { span: 8 } },
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        rules: _vm.isFormRules
                                                          ? _vm.formRules
                                                              .quoteVehicleTax
                                                              .taxPayNo
                                                          : [
                                                              {
                                                                required: false,
                                                              },
                                                            ],
                                                        label: "税务机关代码：",
                                                        "label-width": "120px",
                                                        prop: "quoteVehicleTax.taxPaidOrgCode",
                                                      },
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        staticStyle: {
                                                          "margin-right":
                                                            "10px",
                                                        },
                                                        attrs: {
                                                          maxlength: "40",
                                                          "lable-width":
                                                            "130px",
                                                          placeholder:
                                                            "请输入税务机关代码",
                                                          clearable: "",
                                                          disabled:
                                                            _vm.opType ==
                                                            "underwriting",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form
                                                              .quoteVehicleTax
                                                              .taxPaidOrgCode,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form
                                                                .quoteVehicleTax,
                                                              "taxPaidOrgCode",
                                                              typeof $$v ===
                                                                "string"
                                                                ? $$v.trim()
                                                                : $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form.quoteVehicleTax.taxPaidOrgCode",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "el-col",
                                                { attrs: { span: 8 } },
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      attrs: {
                                                        rules: _vm.isFormRules
                                                          ? _vm.formRules
                                                              .quoteVehicleTax
                                                              .taxPayNo
                                                          : [
                                                              {
                                                                required: false,
                                                              },
                                                            ],
                                                        label: "税务机关名称：",
                                                        "label-width": "120px",
                                                        prop: "quoteVehicleTax.taxPaidOrgName",
                                                      },
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        staticStyle: {
                                                          "margin-right":
                                                            "10px",
                                                        },
                                                        attrs: {
                                                          maxlength: "40",
                                                          "lable-width":
                                                            "130px",
                                                          placeholder:
                                                            "请输入税务机关名称",
                                                          clearable: "",
                                                          disabled:
                                                            _vm.opType ==
                                                            "underwriting",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form
                                                              .quoteVehicleTax
                                                              .taxPaidOrgName,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form
                                                                .quoteVehicleTax,
                                                              "taxPaidOrgName",
                                                              typeof $$v ===
                                                                "string"
                                                                ? $$v.trim()
                                                                : $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form.quoteVehicleTax.taxPaidOrgName",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm.form.quoteVehicleTax
                                                .taxType ==
                                              _vm.dictSource["TAX_TYPE"][3]
                                                .dictCode
                                                ? _c(
                                                    "el-col",
                                                    { attrs: { span: 8 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value:
                                                                !_vm.needCheckTaxDocumentDate,
                                                              expression:
                                                                "!needCheckTaxDocumentDate",
                                                            },
                                                          ],
                                                          ref: "quoteVehicleTax.taxPaidAreaCode",
                                                          attrs: {
                                                            label:
                                                              "完税凭证地区代码：",
                                                            "lable-width":
                                                              "130px",
                                                            "label-width":
                                                              "130px",
                                                          },
                                                        },
                                                        [
                                                          _c("el-input", {
                                                            attrs: {
                                                              maxlength: "40",
                                                              placeholder:
                                                                "请输入完税凭证地区代码",
                                                              clearable: "",
                                                              disabled:
                                                                _vm.opType ==
                                                                "underwriting",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.form
                                                                  .quoteVehicleTax
                                                                  .taxPaidAreaCode,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.form
                                                                      .quoteVehicleTax,
                                                                    "taxPaidAreaCode",
                                                                    typeof $$v ===
                                                                      "string"
                                                                      ? $$v.trim()
                                                                      : $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "form.quoteVehicleTax.taxPaidAreaCode",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _vm.needCheckTaxDocumentDate
                                                        ? _c(
                                                            "el-form-item",
                                                            {
                                                              ref: "quoteVehicleTax.taxPaidAreaCode",
                                                              attrs: {
                                                                label:
                                                                  "完税凭证地区代码：",
                                                                "lable-width":
                                                                  "130px",
                                                                prop: "quoteVehicleTax.taxPaidAreaCode",
                                                                "label-width":
                                                                  "130px",
                                                              },
                                                            },
                                                            [
                                                              _c("el-input", {
                                                                attrs: {
                                                                  maxlength:
                                                                    "40",
                                                                  placeholder:
                                                                    "请输入完税凭证地区代码",
                                                                  clearable: "",
                                                                  disabled:
                                                                    _vm.opType ==
                                                                    "underwriting",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.form
                                                                      .quoteVehicleTax
                                                                      .taxPaidAreaCode,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.form
                                                                          .quoteVehicleTax,
                                                                        "taxPaidAreaCode",
                                                                        typeof $$v ===
                                                                          "string"
                                                                          ? $$v.trim()
                                                                          : $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "form.quoteVehicleTax.taxPaidAreaCode",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.form.quoteVehicleTax
                                                .taxType ==
                                              _vm.dictSource["TAX_TYPE"][3]
                                                .dictCode
                                                ? _c(
                                                    "el-col",
                                                    { attrs: { span: 8 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value:
                                                                !_vm.needCheckTaxDocumentDate,
                                                              expression:
                                                                "!needCheckTaxDocumentDate",
                                                            },
                                                          ],
                                                          ref: "quoteVehicleTax.taxDocumentDate",
                                                          attrs: {
                                                            label:
                                                              "完税凭证填发日期：",
                                                            "label-width":
                                                              "130px",
                                                          },
                                                        },
                                                        [
                                                          _c("el-date-picker", {
                                                            staticStyle: {
                                                              width: "100%",
                                                            },
                                                            attrs: {
                                                              "value-format":
                                                                "yyyy-MM-dd HH:mm:ss",
                                                              type: "date",
                                                              placeholder:
                                                                "请选择完税凭证填发日期",
                                                              disabled:
                                                                _vm.opType ==
                                                                "underwriting",
                                                              "picker-options":
                                                                _vm.disabledLessThan,
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.form
                                                                  .quoteVehicleTax
                                                                  .taxDocumentDate,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.form
                                                                      .quoteVehicleTax,
                                                                    "taxDocumentDate",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "form.quoteVehicleTax.taxDocumentDate",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _vm.needCheckTaxDocumentDate
                                                        ? _c(
                                                            "el-form-item",
                                                            {
                                                              ref: "quoteVehicleTax.taxDocumentDate",
                                                              attrs: {
                                                                label:
                                                                  "完税凭证填发日期：",
                                                                prop: "quoteVehicleTax.taxDocumentDate",
                                                                "label-width":
                                                                  "130px",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "el-date-picker",
                                                                {
                                                                  staticStyle: {
                                                                    width:
                                                                      "100%",
                                                                  },
                                                                  attrs: {
                                                                    "value-format":
                                                                      "yyyy-MM-dd HH:mm:ss",
                                                                    type: "date",
                                                                    placeholder:
                                                                      "请选择完税凭证填发日期",
                                                                    disabled:
                                                                      _vm.opType ==
                                                                      "underwriting",
                                                                    "picker-options":
                                                                      _vm.disabledLessThan,
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.form
                                                                        .quoteVehicleTax
                                                                        .taxDocumentDate,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm
                                                                            .form
                                                                            .quoteVehicleTax,
                                                                          "taxDocumentDate",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "form.quoteVehicleTax.taxDocumentDate",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.form.quoteVehicleTax
                                                .taxType ==
                                                _vm.dictSource["TAX_TYPE"][1]
                                                  .dictCode ||
                                              _vm.form.quoteVehicleTax
                                                .taxType ==
                                                _vm.dictSource["TAX_TYPE"][2]
                                                  .dictCode
                                                ? _c(
                                                    "el-col",
                                                    { attrs: { span: 8 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            rules:
                                                              _vm.isFormRules
                                                                ? _vm.formRules
                                                                    .quoteVehicleTax
                                                                    .taxPayNo
                                                                : [
                                                                    {
                                                                      required: false,
                                                                    },
                                                                  ],
                                                            label:
                                                              "减免凭证号：",
                                                            "label-width":
                                                              "130px",
                                                            prop: "quoteVehicleTax.taxPayNo",
                                                          },
                                                        },
                                                        [
                                                          _c("el-input", {
                                                            attrs: {
                                                              maxlength: "40",
                                                              placeholder:
                                                                "请输入减免凭证号",
                                                              clearable: "",
                                                              disabled:
                                                                _vm.opType ==
                                                                "underwriting",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.form
                                                                  .quoteVehicleTax
                                                                  .taxPayNo,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.form
                                                                      .quoteVehicleTax,
                                                                    "taxPayNo",
                                                                    typeof $$v ===
                                                                      "string"
                                                                      ? $$v.trim()
                                                                      : $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "form.quoteVehicleTax.taxPayNo",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.form.quoteVehicleTax
                                                .taxType ==
                                              _vm.dictSource["TAX_TYPE"][1]
                                                .dictCode
                                                ? _c(
                                                    "el-col",
                                                    { attrs: { span: 8 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            label: "减税方案：",
                                                            "label-width":
                                                              "130px",
                                                            prop: "quoteVehicleTax.deductionDueType",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-select",
                                                            {
                                                              staticStyle: {
                                                                width: "100%",
                                                              },
                                                              attrs: {
                                                                filterable: "",
                                                                placeholder:
                                                                  "请选择减税方案",
                                                                clearable: "",
                                                                disabled:
                                                                  _vm.opType ==
                                                                  "underwriting",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.form
                                                                    .quoteVehicleTax
                                                                    .deductionDueType,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.form
                                                                        .quoteVehicleTax,
                                                                      "deductionDueType",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "form.quoteVehicleTax.deductionDueType",
                                                              },
                                                            },
                                                            [
                                                              _c("el-option", {
                                                                attrs: {
                                                                  label:
                                                                    "减税比例",
                                                                  value: "P",
                                                                },
                                                              }),
                                                              _c("el-option", {
                                                                attrs: {
                                                                  label:
                                                                    "减税金额",
                                                                  value: "A",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.form.quoteVehicleTax
                                                .taxType ==
                                              _vm.dictSource["TAX_TYPE"][1]
                                                .dictCode
                                                ? _c(
                                                    "el-col",
                                                    { attrs: { span: 8 } },
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value:
                                                                _vm.form
                                                                  .quoteVehicleTax
                                                                  .deductionDueType ==
                                                                "P",
                                                              expression:
                                                                "form.quoteVehicleTax.deductionDueType == 'P'",
                                                            },
                                                          ],
                                                          ref: "quoteVehicleTax.deductionDueProportion",
                                                          attrs: {
                                                            label:
                                                              "减税比例(%)：",
                                                            "label-width":
                                                              "130px",
                                                            prop: "quoteVehicleTax.deductionDueProportion",
                                                          },
                                                        },
                                                        [
                                                          _c("el-input", {
                                                            staticStyle: {
                                                              "margin-right":
                                                                "10px",
                                                            },
                                                            attrs: {
                                                              type: "text",
                                                              placeholder:
                                                                "请输入减税比例",
                                                              clearable: "",
                                                              disabled:
                                                                _vm.opType ==
                                                                "underwriting",
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                _vm.form.quoteVehicleTax.deductionDueProportion =
                                                                  _vm.onInput(
                                                                    _vm.form
                                                                      .quoteVehicleTax
                                                                      .deductionDueProportion,
                                                                    0,
                                                                    100,
                                                                    0
                                                                  )
                                                              },
                                                            },
                                                            nativeOn: {
                                                              keyup: function (
                                                                $event
                                                              ) {
                                                                _vm.form.quoteVehicleTax.deductionDueProportion =
                                                                  _vm.onInput(
                                                                    _vm.form
                                                                      .quoteVehicleTax
                                                                      .deductionDueProportion,
                                                                    0,
                                                                    100,
                                                                    0
                                                                  )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.form
                                                                  .quoteVehicleTax
                                                                  .deductionDueProportion,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.form
                                                                      .quoteVehicleTax,
                                                                    "deductionDueProportion",
                                                                    typeof $$v ===
                                                                      "string"
                                                                      ? $$v.trim()
                                                                      : $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "form.quoteVehicleTax.deductionDueProportion",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value:
                                                                _vm.form
                                                                  .quoteVehicleTax
                                                                  .deductionDueType ==
                                                                "A",
                                                              expression:
                                                                "form.quoteVehicleTax.deductionDueType == 'A'",
                                                            },
                                                          ],
                                                          ref: "quoteVehicleTax.reductionAmount",
                                                          attrs: {
                                                            label:
                                                              "减税金额(元)：",
                                                            "label-width":
                                                              "130px",
                                                            prop: "quoteVehicleTax.reductionAmount",
                                                          },
                                                        },
                                                        [
                                                          _c("el-input", {
                                                            staticStyle: {
                                                              "margin-right":
                                                                "10px",
                                                            },
                                                            attrs: {
                                                              type: "text",
                                                              placeholder:
                                                                "请输入减税金额",
                                                              clearable: "",
                                                              disabled:
                                                                _vm.opType ==
                                                                "underwriting",
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                _vm.form.quoteVehicleTax.reductionAmount =
                                                                  _vm.onInput(
                                                                    _vm.form
                                                                      .quoteVehicleTax
                                                                      .reductionAmount,
                                                                    0,
                                                                    99999999,
                                                                    2
                                                                  )
                                                              },
                                                            },
                                                            nativeOn: {
                                                              keyup: function (
                                                                $event
                                                              ) {
                                                                _vm.form.quoteVehicleTax.reductionAmount =
                                                                  _vm.onInput(
                                                                    _vm.form
                                                                      .quoteVehicleTax
                                                                      .reductionAmount,
                                                                    0,
                                                                    99999999,
                                                                    2
                                                                  )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.form
                                                                  .quoteVehicleTax
                                                                  .reductionAmount,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.form
                                                                      .quoteVehicleTax,
                                                                    "reductionAmount",
                                                                    typeof $$v ===
                                                                      "string"
                                                                      ? $$v.trim()
                                                                      : $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "form.quoteVehicleTax.reductionAmount",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "el-row",
                                    { attrs: { gutter: 0 } },
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 4 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              staticClass:
                                                "special_row_content",
                                              attrs: { "label-width": "15px" },
                                            },
                                            [
                                              _c(
                                                "el-checkbox",
                                                {
                                                  attrs: {
                                                    disabled:
                                                      _vm.opType ==
                                                      "underwriting",
                                                  },
                                                  on: {
                                                    change: _vm.handleBSChange,
                                                  },
                                                  model: {
                                                    value: _vm.form.isBs,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "isBs",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "form.isBs",
                                                  },
                                                },
                                                [_vm._v("商业险")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 20 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              staticClass:
                                                "special_row_content",
                                              attrs: { "label-width": "130px" },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                    "justify-content":
                                                      "flex-end",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "margin-left": "5px",
                                                        "margin-right": "5px",
                                                        color: "#515151",
                                                        "font-size": "13px",
                                                      },
                                                    },
                                                    [_vm._v("保险期间:")]
                                                  ),
                                                  _c("el-date-picker", {
                                                    staticClass:
                                                      "time-select-class",
                                                    staticStyle: {
                                                      width: "135px",
                                                      color: "#515151",
                                                      "font-size": "13px",
                                                    },
                                                    attrs: {
                                                      "value-format":
                                                        "yyyy-MM-dd",
                                                      disabled: !_vm.form.isBs,
                                                      "picker-options":
                                                        _vm.disabledBsPickerOptions,
                                                      type: "date",
                                                      placeholder: "起保日期",
                                                      clearable: false,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.datePickerChange(
                                                          _vm.form,
                                                          false,
                                                          false
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form
                                                          .bsEffectiveStartTime,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "bsEffectiveStartTime",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.bsEffectiveStartTime",
                                                    },
                                                  }),
                                                  _c("el-time-select", {
                                                    staticClass:
                                                      "time-select-class",
                                                    staticStyle: {
                                                      "margin-left": "2px",
                                                      width: "95px",
                                                      color: "#515151",
                                                      "font-size": "13px",
                                                    },
                                                    attrs: {
                                                      disabled: !_vm.form.isBs,
                                                      "picker-options":
                                                        _vm.defaultBzTimeOptions,
                                                      placeholder: "时间",
                                                      clearable: false,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.timePickerChange(
                                                          _vm.form
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form.bsStartTime,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "bsStartTime",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.bsStartTime",
                                                    },
                                                  }),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "margin-left": "5px",
                                                        "margin-right": "5px",
                                                        "font-size": "13px",
                                                      },
                                                    },
                                                    [_vm._v("~")]
                                                  ),
                                                  _c("el-date-picker", {
                                                    staticClass:
                                                      "time-select-class",
                                                    staticStyle: {
                                                      width: "135px",
                                                      color: "#515151",
                                                      "font-size": "13px",
                                                    },
                                                    attrs: {
                                                      "value-format":
                                                        "yyyy-MM-dd",
                                                      disabled: !_vm.form.isBs,
                                                      "picker-options":
                                                        _vm.endDateBsPicker,
                                                      type: "date",
                                                      placeholder: "终保日期",
                                                      clearable: false,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form
                                                          .bsEffectiveEndTime,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "bsEffectiveEndTime",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.bsEffectiveEndTime",
                                                    },
                                                  }),
                                                  _c("el-time-select", {
                                                    staticClass:
                                                      "time-select-class",
                                                    staticStyle: {
                                                      "margin-left": "2px",
                                                      width: "95px",
                                                      color: "#515151",
                                                      "font-size": "13px",
                                                    },
                                                    attrs: {
                                                      disabled: !_vm.form.isBs,
                                                      "picker-options":
                                                        _vm.defaultTimeOptions,
                                                      placeholder: "时间",
                                                      clearable: false,
                                                    },
                                                    model: {
                                                      value: _vm.form.bsEndTime,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "bsEndTime",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.bsEndTime",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.form.isBs && _vm.quoteType == "1"
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "div",
                                            {
                                              key: "dutyPackage",
                                              staticStyle: { display: "flex" },
                                              attrs: { id: "dutyPackage" },
                                            },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticClass:
                                                    "special_row_content",
                                                  attrs: {
                                                    label: "保险配置方案: ",
                                                    "label-width": "100px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-radio-group",
                                                    {
                                                      attrs: {
                                                        disabled:
                                                          _vm.opType ==
                                                          "underwriting",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form
                                                            .insuranceType,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form,
                                                            "insuranceType",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.insuranceType",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-radio",
                                                        {
                                                          attrs: { label: "" },
                                                          on: {
                                                            change:
                                                              _vm.customScheme,
                                                          },
                                                        },
                                                        [_vm._v("自定义方案")]
                                                      ),
                                                      _vm._l(
                                                        _vm.aLLDutyPackage,
                                                        function (item, index) {
                                                          return _c(
                                                            "el-radio",
                                                            {
                                                              key: index,
                                                              attrs: {
                                                                label:
                                                                  item.riskkindPackageId,
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.selectDutyPackage(
                                                                      item.riskkindPackageId
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.name
                                                                )
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("InsuranceRiskForm", {
                                            attrs: {
                                              setectInsuranceCompanyList:
                                                _vm.setectInsuranceCompanyList,
                                              newEnergy: _vm.form.newEnergy,
                                              fuelType:
                                                _vm.form.quoteVehicle.fuelType,
                                              purchasePrice:
                                                _vm.form.quoteVehicle
                                                  .purchasePrice,
                                              effectiveStartTime:
                                                _vm.form.bsEffectiveStartTime,
                                              "is-main": true,
                                              "register-date":
                                                _vm.form.quoteVehicle
                                                  .registerDate,
                                              "no-main-duty":
                                                _vm.selectNoMainDuty,
                                              "main-duty": _vm.selectMainDuty,
                                              label: "主险",
                                              "cust-attribute":
                                                _vm.form.owner
                                                  .relationshipAttribute,
                                              "use-attribute":
                                                _vm.form.quoteVehicle
                                                  .useAttribute,
                                              "vehicle-type":
                                                _vm.form.quoteVehicle
                                                  .vehicleType,
                                              seats:
                                                _vm.form.quoteVehicle &&
                                                _vm.form.quoteVehicle.seats
                                                  ? (
                                                      _vm.form.quoteVehicle
                                                        .seats - 1
                                                    ).toString()
                                                  : "",
                                              "duty-list":
                                                _vm.mainInsuranceData,
                                              "check-duty-list":
                                                _vm.checkDutyList,
                                              isDisabled:
                                                _vm.opType == "underwriting",
                                            },
                                            on: {
                                              exportValue:
                                                _vm.getMainInsuranceData,
                                            },
                                          }),
                                          _c("InsuranceRiskForm", {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.noMainDuty &&
                                                  _vm.noMainDuty.length > 0,
                                                expression:
                                                  "noMainDuty && noMainDuty.length > 0",
                                              },
                                            ],
                                            attrs: {
                                              setectInsuranceCompanyList:
                                                _vm.setectInsuranceCompanyList,
                                              newEnergy: _vm.form.newEnergy,
                                              fuelType:
                                                _vm.form.quoteVehicle.fuelType,
                                              purchasePrice:
                                                _vm.form.quoteVehicle
                                                  .purchasePrice,
                                              effectiveStartTime:
                                                _vm.form.bsEffectiveStartTime,
                                              "is-main": false,
                                              "register-date":
                                                _vm.form.quoteVehicle
                                                  .registerDate,
                                              "no-main-duty":
                                                _vm.selectNoMainDuty,
                                              "main-duty": _vm.selectMainDuty,
                                              label: "附加险",
                                              "cust-attribute":
                                                _vm.form.owner
                                                  .relationshipAttribute,
                                              "use-attribute":
                                                _vm.form.quoteVehicle
                                                  .useAttribute,
                                              "vehicle-type":
                                                _vm.form.quoteVehicle
                                                  .vehicleType,
                                              isNewAddItem: true,
                                              "duty-list": _vm.noMainDuty,
                                              "check-duty-list":
                                                _vm.checkDutyList,
                                              isDisabled:
                                                _vm.opType == "underwriting",
                                              seats:
                                                _vm.form.quoteVehicle &&
                                                _vm.form.quoteVehicle.seats
                                                  ? (
                                                      _vm.form.quoteVehicle
                                                        .seats - 1
                                                    ).toString()
                                                  : "",
                                            },
                                            on: {
                                              exportValue:
                                                _vm.getNoMainInsuranceData,
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.opType == "underwriting"
                        ? _c(
                            "el-collapse",
                            {
                              staticStyle: { "margin-top": "10px" },
                              model: {
                                value: _vm.activeFive,
                                callback: function ($$v) {
                                  _vm.activeFive = $$v
                                },
                                expression: "activeFive",
                              },
                            },
                            [
                              _c(
                                "el-collapse-item",
                                {
                                  staticClass: "collapse-item-style",
                                  attrs: { name: "1" },
                                },
                                [
                                  _c(
                                    "template",
                                    { slot: "title" },
                                    [
                                      _c("FormLabel", {
                                        staticStyle: {
                                          flex: "1",
                                          "line-height": "25px",
                                        },
                                        attrs: { "form-label": "投保方案" },
                                      }),
                                      _vm.isShowUpDateInsure &&
                                      !_vm.loading &&
                                      _vm.currentInsCrop.proposalStatus !=
                                        _vm.dictSource[
                                          "INSURED_STATUS_OTHER"
                                        ][1].dictCode
                                        ? _c(
                                            "el-button",
                                            {
                                              staticStyle: {
                                                "margin-right": "15px",
                                                "z-index": "999",
                                              },
                                              attrs: {
                                                type: "primary",
                                                plain: "",
                                                size: "mini",
                                                icon: "el-icon-edit",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.upDateInsure(
                                                    $event
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(" 修改报价方案 ")]
                                          )
                                        : _vm._e(),
                                      _c("div", {
                                        staticStyle: { height: "10px" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "min-height": "150px",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: { "min-width": "200px" },
                                        },
                                        [
                                          !_vm._.isEmpty(_vm.proposalFormList)
                                            ? _c(
                                                "div",
                                                {
                                                  key: "insInfo",
                                                  attrs: { id: "insInfo" },
                                                },
                                                _vm._l(
                                                  _vm.proposalFormList,
                                                  function (item, index) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: index,
                                                        class: {
                                                          "insurance-company-s":
                                                            !item.selected,
                                                          "insurance-company-selected-s":
                                                            item.selected,
                                                        },
                                                        staticStyle: {
                                                          cursor: "pointer",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.switchQuoteReslut(
                                                              item,
                                                              true
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "insurance-company-header-s",
                                                            staticStyle: {
                                                              position:
                                                                "relative",
                                                            },
                                                          },
                                                          [
                                                            _c("div", [
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "svg-icon",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          "font-size":
                                                                            "25px",
                                                                        },
                                                                      attrs: {
                                                                        "icon-class":
                                                                          "" +
                                                                          _vm._.toLower(
                                                                            item.insCorpCode
                                                                          ),
                                                                      },
                                                                    }
                                                                  ),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          "padding-left":
                                                                            "5px",
                                                                          "line-height":
                                                                            "24px",
                                                                          "font-weight":
                                                                            "bold",
                                                                          "font-size":
                                                                            "16px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          item.insCorpName
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "el-tooltip",
                                                                    {
                                                                      staticClass:
                                                                        "item",
                                                                      attrs: {
                                                                        effect:
                                                                          "dark",
                                                                        content:
                                                                          item.insChannelNickname ||
                                                                          item.insChannelName,
                                                                        placement:
                                                                          "bottom",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "channel_name",
                                                                          staticStyle:
                                                                            {
                                                                              "padding-left":
                                                                                "10px",
                                                                              "line-height":
                                                                                "24px",
                                                                              "font-weight":
                                                                                "bold",
                                                                              "font-size":
                                                                                "12px",
                                                                              color:
                                                                                "#666",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              item.insChannelNickname ||
                                                                                item.insChannelName
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]),
                                                            _vm._.find(
                                                              item.coreProposalProductVOList,
                                                              function (eitem) {
                                                                return (
                                                                  eitem.renewalStatus ==
                                                                  "3"
                                                                )
                                                              }
                                                            )
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        position:
                                                                          "absolute",
                                                                        right:
                                                                          "10px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "svg-icon",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "font-size":
                                                                              "23px",
                                                                          },
                                                                        attrs: {
                                                                          "icon-class":
                                                                            "continue",
                                                                        },
                                                                      }
                                                                    ),
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            position:
                                                                              "absolute",
                                                                            right:
                                                                              "5px",
                                                                            color:
                                                                              "#fff",
                                                                            "font-size":
                                                                              "13px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "续"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "insurance-company-start",
                                                          },
                                                          [
                                                            item.proposalStatus !=
                                                              _vm.dictSource[
                                                                "INSURED_STATUS_OTHER"
                                                              ][0].dictCode &&
                                                            item.proposalStatus !=
                                                              _vm.dictSource[
                                                                "INSURED_STATUS_DROPDOWN"
                                                              ][2].dictCode &&
                                                            item.proposalStatus !=
                                                              _vm.dictSource[
                                                                "INSURED_STATUS_DROPDOWN"
                                                              ][3].dictCode &&
                                                            item.proposalStatus !=
                                                              _vm.dictSource[
                                                                "INSURED_STATUS_DROPDOWN"
                                                              ][12].dictCode
                                                              ? _c("div", [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          color:
                                                                            "#e63f3f",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "￥ " +
                                                                          _vm._s(
                                                                            item.totalActualPremium
                                                                              ? item.totalActualPremium.toLocaleString()
                                                                              : 0
                                                                          )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ])
                                                              : _vm._e(),
                                                            item.proposalStatus ==
                                                            "loading"
                                                              ? _c("div", [
                                                                  _c("i", {
                                                                    staticClass:
                                                                      "el-icon-loading",
                                                                  }),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          color:
                                                                            "#999",
                                                                          "font-size":
                                                                            "12px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "核保..."
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ])
                                                              : _vm._e(),
                                                            item.proposalStatus ==
                                                            _vm.dictSource[
                                                              "INSURED_STATUS_OTHER"
                                                            ][0].dictCode
                                                              ? _c("div", [
                                                                  _c("i", {
                                                                    staticClass:
                                                                      "el-icon-loading",
                                                                  }),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          color:
                                                                            "#999",
                                                                          "font-size":
                                                                            "12px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "报价中..."
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ])
                                                              : _vm._e(),
                                                            item.proposalStatus ==
                                                            _vm.dictSource[
                                                              "INSURED_STATUS_DROPDOWN"
                                                            ][2].dictCode
                                                              ? _c("div", [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          color:
                                                                            "#999",
                                                                          "font-size":
                                                                            "12px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "报价修改"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ])
                                                              : _vm._e(),
                                                            item.proposalStatus ==
                                                            _vm.dictSource[
                                                              "INSURED_STATUS_OTHER"
                                                            ][1].dictCode
                                                              ? _c("div", [
                                                                  _c("i", {
                                                                    staticClass:
                                                                      "el-icon-loading",
                                                                  }),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          color:
                                                                            "#999",
                                                                          "font-size":
                                                                            "12px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "核保中..."
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ])
                                                              : _vm._e(),
                                                            item.proposalStatus ==
                                                            _vm.dictSource[
                                                              "INSURED_STATUS_DROPDOWN"
                                                            ][3].dictCode
                                                              ? _c("div", [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          color:
                                                                            "#999",
                                                                          "font-size":
                                                                            "12px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "报价失败"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ])
                                                              : _vm._e(),
                                                            item.proposalStatus ==
                                                            _vm.dictSource[
                                                              "INSURED_STATUS_DROPDOWN"
                                                            ][12].dictCode
                                                              ? _c("div", [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          color:
                                                                            "#e4a547",
                                                                          "font-size":
                                                                            "12px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "重复投保"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ])
                                                              : _vm._e(),
                                                            item.proposalStatus ==
                                                            _vm.dictSource[
                                                              "INSURED_STATUS_DROPDOWN"
                                                            ][4].dictCode
                                                              ? _c("div", [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          color:
                                                                            "#999",
                                                                          "font-size":
                                                                            "12px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "待核保"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ])
                                                              : _vm._e(),
                                                            item.proposalStatus ==
                                                            _vm.dictSource[
                                                              "INSURED_STATUS_DROPDOWN"
                                                            ][8].dictCode
                                                              ? _c("div", [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          padding:
                                                                            "3px",
                                                                          "background-color":
                                                                            "#28d98f",
                                                                          "border-radius":
                                                                            "50%",
                                                                          color:
                                                                            "white",
                                                                          "font-size":
                                                                            "13px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "核"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ])
                                                              : _vm._e(),
                                                            item.proposalStatus ==
                                                            _vm.dictSource[
                                                              "INSURED_STATUS_DROPDOWN"
                                                            ][7].dictCode
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        position:
                                                                          "relative",
                                                                      },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            color:
                                                                              "red",
                                                                            position:
                                                                              "absolute",
                                                                            right:
                                                                              "4px",
                                                                            top: "-4px",
                                                                            "font-size":
                                                                              "12px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "核"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "svg-icon",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "font-size":
                                                                              "20px",
                                                                          },
                                                                        attrs: {
                                                                          "icon-class":
                                                                            "forbid",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                            item.proposalStatus ==
                                                            _vm.dictSource[
                                                              "INSURED_STATUS_DROPDOWN"
                                                            ][5].dictCode
                                                              ? _c("div", [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          color:
                                                                            "#999",
                                                                          "font-size":
                                                                            "12px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "核保修改"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ])
                                                              : _vm._e(),
                                                            item.proposalStatus ==
                                                            _vm.dictSource[
                                                              "INSURED_STATUS_DROPDOWN"
                                                            ][9].dictCode
                                                              ? _c("div", [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          color:
                                                                            "#1dc371",
                                                                          "font-size":
                                                                            "12px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "已承保"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ])
                                                              : _vm._e(),
                                                            item.proposalStatus ==
                                                            _vm.dictSource[
                                                              "INSURED_STATUS_DROPDOWN"
                                                            ][11].dictCode
                                                              ? _c("div", [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          color:
                                                                            "#a0a0a0",
                                                                          "font-size":
                                                                            "12px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "待承保"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ])
                                                              : _vm._e(),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "loading",
                                              rawName: "v-loading",
                                              value: _vm.loading,
                                              expression: "loading",
                                            },
                                          ],
                                          staticStyle: {
                                            padding: "0 10px",
                                            flex: "1",
                                            background: "#f7f7f7",
                                          },
                                          attrs: {
                                            "element-loading-text": "报价中...",
                                          },
                                        },
                                        [
                                          !_vm.loading
                                            ? _c("div", [
                                                _vm.currentInsCrop
                                                  .proposalStatus !=
                                                  _vm.dictSource[
                                                    "INSURED_STATUS_OTHER"
                                                  ][0].dictCode &&
                                                _vm.currentInsCrop
                                                  .proposalStatus !=
                                                  _vm.dictSource[
                                                    "INSURED_STATUS_DROPDOWN"
                                                  ][2].dictCode &&
                                                _vm.currentInsCrop
                                                  .proposalStatus !=
                                                  _vm.dictSource[
                                                    "INSURED_STATUS_DROPDOWN"
                                                  ][12].dictCode
                                                  ? _c("div", [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            "background-color":
                                                              "#f6f8ff",
                                                            padding:
                                                              "10px 15px 10px 5px",
                                                            "border-radius":
                                                              "5px",
                                                            display: "flex",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            [
                                                              _c("svg-icon", {
                                                                staticStyle: {
                                                                  "font-size":
                                                                    "18px",
                                                                },
                                                                attrs: {
                                                                  "icon-class":
                                                                    "car",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                "margin-left":
                                                                  "5px",
                                                              },
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  " 当前投保车型："
                                                                ),
                                                              ]),
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.getProposalVehicleType(
                                                                      _vm
                                                                        .currentInsCrop
                                                                        .coreProposalVehicleVO
                                                                    )
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            "margin-top":
                                                              "10px",
                                                          },
                                                        },
                                                        [
                                                          _vm.currentBzInsItem &&
                                                          _vm.currentBzInsItem
                                                            .effectiveStartTime
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticStyle: {
                                                                    color:
                                                                      "#999",
                                                                    "font-size":
                                                                      "13px",
                                                                    display:
                                                                      "flex",
                                                                    "align-items":
                                                                      "center",
                                                                  },
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "交强险保险期间：" +
                                                                        _vm._s(
                                                                          _vm.currentBzInsItem &&
                                                                            _vm
                                                                              .currentBzInsItem
                                                                              .effectiveStartTime
                                                                            ? _vm.getEffectiveStartTime(
                                                                                _vm
                                                                                  .currentBzInsItem
                                                                                  .effectiveStartTime
                                                                              )
                                                                            : ""
                                                                        ) +
                                                                        " 至 " +
                                                                        _vm._s(
                                                                          _vm.currentBzInsItem &&
                                                                            _vm
                                                                              .currentBzInsItem
                                                                              .effectiveEndTime
                                                                            ? _vm.getEffectiveEndTime(
                                                                                _vm
                                                                                  .currentBzInsItem
                                                                                  .effectiveEndTime
                                                                              )
                                                                            : ""
                                                                        )
                                                                    ),
                                                                  ]),
                                                                  _vm
                                                                    .currentBzInsItem
                                                                    .renewalStatus &&
                                                                  _vm
                                                                    .currentBzInsItem
                                                                    .renewalStatus !=
                                                                    "0"
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              "margin-left":
                                                                                "15px",
                                                                              width:
                                                                                "16px",
                                                                              height:
                                                                                "16px",
                                                                              "border-radius":
                                                                                "50%",
                                                                              display:
                                                                                "flex",
                                                                              "align-items":
                                                                                "center",
                                                                              "justify-content":
                                                                                "center",
                                                                            },
                                                                          style:
                                                                            {
                                                                              border:
                                                                                _vm
                                                                                  .currentBzInsItem
                                                                                  .renewalStatus ==
                                                                                "1"
                                                                                  ? "1px solid red"
                                                                                  : _vm
                                                                                      .currentBzInsItem
                                                                                      .renewalStatus ==
                                                                                    "2"
                                                                                  ? "1px solid #ec9f55"
                                                                                  : "1px solid #28d98f",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "el-tooltip",
                                                                            {
                                                                              staticClass:
                                                                                "item",
                                                                              attrs:
                                                                                {
                                                                                  effect:
                                                                                    "dark",
                                                                                  content:
                                                                                    "新保",
                                                                                  placement:
                                                                                    "top",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm
                                                                                .currentBzInsItem
                                                                                .renewalStatus ==
                                                                              "1"
                                                                                ? _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticStyle:
                                                                                        {
                                                                                          color:
                                                                                            "red",
                                                                                          "font-size":
                                                                                            "12px",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "新"
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "el-tooltip",
                                                                            {
                                                                              staticClass:
                                                                                "item",
                                                                              attrs:
                                                                                {
                                                                                  effect:
                                                                                    "dark",
                                                                                  content:
                                                                                    "转保",
                                                                                  placement:
                                                                                    "top",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm
                                                                                .currentBzInsItem
                                                                                .renewalStatus ==
                                                                              "2"
                                                                                ? _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticStyle:
                                                                                        {
                                                                                          color:
                                                                                            "#ec9f55",
                                                                                          "font-size":
                                                                                            "12px",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "转"
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "el-tooltip",
                                                                            {
                                                                              staticClass:
                                                                                "item",
                                                                              attrs:
                                                                                {
                                                                                  effect:
                                                                                    "dark",
                                                                                  content:
                                                                                    "续保",
                                                                                  placement:
                                                                                    "top",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm
                                                                                .currentBzInsItem
                                                                                .renewalStatus ==
                                                                              "3"
                                                                                ? _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticStyle:
                                                                                        {
                                                                                          color:
                                                                                            "#28d98f",
                                                                                          "font-size":
                                                                                            "12px",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "续"
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm.currentBsInsItem &&
                                                          _vm.currentBsInsItem
                                                            .effectiveStartTime
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticStyle: {
                                                                    color:
                                                                      "#999",
                                                                    "font-size":
                                                                      "13px",
                                                                    display:
                                                                      "flex",
                                                                    "align-items":
                                                                      "center",
                                                                  },
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "商业险保险期间：" +
                                                                        _vm._s(
                                                                          _vm.currentBsInsItem &&
                                                                            _vm
                                                                              .currentBsInsItem
                                                                              .effectiveStartTime
                                                                            ? _vm.getEffectiveStartTime(
                                                                                _vm
                                                                                  .currentBsInsItem
                                                                                  .effectiveStartTime
                                                                              )
                                                                            : ""
                                                                        ) +
                                                                        " 至 " +
                                                                        _vm._s(
                                                                          _vm.currentBsInsItem &&
                                                                            _vm
                                                                              .currentBsInsItem
                                                                              .effectiveEndTime
                                                                            ? _vm.getEffectiveEndTime(
                                                                                _vm
                                                                                  .currentBsInsItem
                                                                                  .effectiveEndTime
                                                                              )
                                                                            : ""
                                                                        )
                                                                    ),
                                                                  ]),
                                                                  _vm
                                                                    .currentBsInsItem
                                                                    .renewalStatus &&
                                                                  _vm
                                                                    .currentBsInsItem
                                                                    .renewalStatus !=
                                                                    "0"
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              "margin-left":
                                                                                "15px",
                                                                              width:
                                                                                "16px",
                                                                              height:
                                                                                "16px",
                                                                              "border-radius":
                                                                                "50%",
                                                                              display:
                                                                                "flex",
                                                                              "align-items":
                                                                                "center",
                                                                              "justify-content":
                                                                                "center",
                                                                            },
                                                                          style:
                                                                            {
                                                                              border:
                                                                                _vm
                                                                                  .currentBsInsItem
                                                                                  .renewalStatus ==
                                                                                "1"
                                                                                  ? "1px solid red"
                                                                                  : _vm
                                                                                      .currentBsInsItem
                                                                                      .renewalStatus ==
                                                                                    "2"
                                                                                  ? "1px solid #ec9f55"
                                                                                  : "1px solid #28d98f",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "el-tooltip",
                                                                            {
                                                                              staticClass:
                                                                                "item",
                                                                              attrs:
                                                                                {
                                                                                  effect:
                                                                                    "dark",
                                                                                  content:
                                                                                    "新保",
                                                                                  placement:
                                                                                    "top",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm
                                                                                .currentBsInsItem
                                                                                .renewalStatus ==
                                                                              "1"
                                                                                ? _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticStyle:
                                                                                        {
                                                                                          color:
                                                                                            "red",
                                                                                          "font-size":
                                                                                            "12px",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "新"
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "el-tooltip",
                                                                            {
                                                                              staticClass:
                                                                                "item",
                                                                              attrs:
                                                                                {
                                                                                  effect:
                                                                                    "dark",
                                                                                  content:
                                                                                    "转保",
                                                                                  placement:
                                                                                    "top",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm
                                                                                .currentBsInsItem
                                                                                .renewalStatus ==
                                                                              "2"
                                                                                ? _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticStyle:
                                                                                        {
                                                                                          color:
                                                                                            "#ec9f55",
                                                                                          "font-size":
                                                                                            "12px",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "转"
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "el-tooltip",
                                                                            {
                                                                              staticClass:
                                                                                "item",
                                                                              attrs:
                                                                                {
                                                                                  effect:
                                                                                    "dark",
                                                                                  content:
                                                                                    "续保",
                                                                                  placement:
                                                                                    "top",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm
                                                                                .currentBsInsItem
                                                                                .renewalStatus ==
                                                                              "3"
                                                                                ? _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticStyle:
                                                                                        {
                                                                                          color:
                                                                                            "#28d98f",
                                                                                          "font-size":
                                                                                            "12px",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "续"
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                                !_vm.loading
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          padding: "15px 0 0",
                                                          "font-size": "13px",
                                                          color: "black",
                                                        },
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v("投保状态: "),
                                                        ]),
                                                        _vm.currentInsCrop
                                                          .proposalStatus ==
                                                        _vm.dictSource[
                                                          "INSURED_STATUS_DROPDOWN"
                                                        ][11].dictCode
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  color:
                                                                    "#a0a0a0",
                                                                },
                                                              },
                                                              [_vm._v("待承保")]
                                                            )
                                                          : _vm._e(),
                                                        _vm.currentInsCrop
                                                          .proposalStatus ==
                                                          _vm.dictSource[
                                                            "INSURED_STATUS_OTHER"
                                                          ][0].dictCode ||
                                                        _vm.currentInsCrop
                                                          .proposalStatus ==
                                                          _vm.dictSource[
                                                            "INSURED_STATUS_OTHER"
                                                          ][1].dictCode
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  color:
                                                                    "#1dc371",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm
                                                                      .currentInsCrop
                                                                      .proposalStatusName
                                                                      ? _vm
                                                                          .currentInsCrop
                                                                          .proposalStatusName
                                                                      : ""
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm.currentInsCrop
                                                          .proposalStatus ==
                                                        "success"
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  color:
                                                                    "#ec9f55",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "核保成功"
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm.currentInsCrop
                                                          .proposalStatus ==
                                                          _vm.dictSource[
                                                            "INSURED_STATUS_DROPDOWN"
                                                          ][3].dictCode ||
                                                        _vm.currentInsCrop
                                                          .proposalStatus ==
                                                          _vm.dictSource[
                                                            "INSURED_STATUS_DROPDOWN"
                                                          ][12].dictCode ||
                                                        _vm.currentInsCrop
                                                          .proposalStatus ==
                                                          _vm.dictSource[
                                                            "INSURED_STATUS_DROPDOWN"
                                                          ][7].dictCode ||
                                                        _vm.currentInsCrop
                                                          .proposalStatus ==
                                                          _vm.dictSource[
                                                            "INSURED_STATUS_DROPDOWN"
                                                          ][5].dictCode ||
                                                        _vm.currentInsCrop
                                                          .proposalStatus ==
                                                          _vm.dictSource[
                                                            "INSURED_STATUS_DROPDOWN"
                                                          ][2].dictCode ||
                                                        _vm.currentInsCrop
                                                          .proposalStatus ==
                                                          _vm.dictSource[
                                                            "INSURED_STATUS_DROPDOWN"
                                                          ][6].dictCode ||
                                                        _vm.currentInsCrop
                                                          .proposalStatus ==
                                                          _vm.dictSource[
                                                            "INSURED_STATUS_OTHER"
                                                          ][2].dictCode
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  color: "red",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm
                                                                      .currentInsCrop
                                                                      .proposalStatusName
                                                                      ? _vm
                                                                          .currentInsCrop
                                                                          .proposalStatusName
                                                                      : ""
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        [
                                                          "picc",
                                                          "cpic",
                                                        ].includes(
                                                          _vm._.toLower(
                                                            _vm.currentInsCrop
                                                              .insCorpCode
                                                          )
                                                        ) &&
                                                        _vm.currentInsCrop &&
                                                        _vm.currentInsCrop
                                                          .proposalResult &&
                                                        _vm.currentInsCrop.proposalResult.indexOf(
                                                          "保司系统返回"
                                                        ) !== -1 &&
                                                        _vm.currentInsCrop
                                                          .proposalStatus ==
                                                          _vm.dictSource[
                                                            "INSURED_STATUS_DROPDOWN"
                                                          ][3].dictCode
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  color:
                                                                    "#959595",
                                                                  margin:
                                                                    "0 10px",
                                                                  "font-size":
                                                                    "12px",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "温馨提醒：支持 "
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "insCorpJumpLink",
                                                                    on: {
                                                                      click:
                                                                        _vm.jumpInsCorp,
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "点击此处"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(
                                                                  "， 或右侧“保”我们将帮助您快速跳转到保险公司系统。"
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm.currentInsCrop
                                                          .proposalStatus ==
                                                          _vm.dictSource[
                                                            "INSURED_STATUS_DROPDOWN"
                                                          ][4].dictCode ||
                                                        _vm.currentInsCrop
                                                          .proposalStatus ==
                                                          _vm.dictSource[
                                                            "INSURED_STATUS_DROPDOWN"
                                                          ][8].dictCode ||
                                                        _vm.currentInsCrop
                                                          .proposalStatus ==
                                                          _vm.dictSource[
                                                            "INSURED_STATUS_DROPDOWN"
                                                          ][9].dictCode
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  color:
                                                                    "#1dc371",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm
                                                                      .currentInsCrop
                                                                      .proposalStatusName
                                                                      ? _vm
                                                                          .currentInsCrop
                                                                          .proposalStatusName
                                                                      : ""
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm.currentInsCrop
                                                          .proposalStatus !=
                                                          _vm.dictSource[
                                                            "INSURED_STATUS_DROPDOWN"
                                                          ][9].dictCode &&
                                                        ((_vm.currentBzInsItem &&
                                                          _vm.currentBzInsItem
                                                            .proposalNo) ||
                                                          (_vm.currentBsInsItem &&
                                                            _vm.currentBsInsItem
                                                              .proposalNo))
                                                          ? _c(
                                                              "el-button",
                                                              {
                                                                attrs: {
                                                                  type: "text",
                                                                  size: "small",
                                                                  icon: "el-icon-refresh",
                                                                  plain: "",
                                                                  loading:
                                                                    _vm.loadingStatus,
                                                                },
                                                                on: {
                                                                  click:
                                                                    _vm.refreshStatus,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.loadingStatus
                                                                      ? "正在刷新..."
                                                                      : "刷新状态"
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _c("div", [
                                                  _vm.currentInsCrop
                                                    .proposalStatus ==
                                                  _vm.dictSource[
                                                    "INSURED_STATUS_DROPDOWN"
                                                  ][2].dictCode
                                                    ? _c(
                                                        "div",
                                                        {
                                                          key: "currentFlowNode",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                border:
                                                                  "1px solid #fbe8c7",
                                                                "border-radius":
                                                                  "5px",
                                                                width: "100%",
                                                                "background-color":
                                                                  "#fff7e7",
                                                                padding: "10px",
                                                                "font-size":
                                                                  "13px",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "svg-icon",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          "font-size":
                                                                            "18px",
                                                                        },
                                                                      attrs: {
                                                                        "icon-class":
                                                                          "warning",
                                                                      },
                                                                    }
                                                                  ),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          color:
                                                                            "#a49270",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "重要提示"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticStyle: {
                                                                    padding:
                                                                      "10px",
                                                                    "font-size":
                                                                      "12px",
                                                                  },
                                                                },
                                                                [
                                                                  _vm.currentFlowNode &&
                                                                  _vm
                                                                    .currentFlowNode
                                                                    .code ==
                                                                    "PaicAccommodationNode" &&
                                                                  _vm
                                                                    .currentFlowNode
                                                                    .errMsg
                                                                    ? _c(
                                                                        "div",
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm
                                                                                  .currentFlowNode
                                                                                  .errMsg
                                                                                  ? _vm
                                                                                      .currentFlowNode
                                                                                      .errMsg
                                                                                  : ""
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm.currentFlowNode &&
                                                                  _vm
                                                                    .currentFlowNode
                                                                    .code !=
                                                                    "SuggestEffectiveTime" &&
                                                                  _vm
                                                                    .currentFlowNode
                                                                    .message
                                                                    ? _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm
                                                                                  .currentFlowNode
                                                                                  .message
                                                                                  ? _vm
                                                                                      .currentFlowNode
                                                                                      .message
                                                                                  : ""
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm.currentFlowNode &&
                                                                  _vm
                                                                    .currentFlowNode
                                                                    .code ==
                                                                    "SuggestEffectiveTime"
                                                                    ? _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                !_vm._.isEmpty(
                                                                                  _vm
                                                                                    .currentFlowNode
                                                                                    .productInfoList
                                                                                )
                                                                                  ? _vm
                                                                                      .currentFlowNode
                                                                                      .productInfoList[0]
                                                                                      .metadata
                                                                                  : ""
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _vm.currentInsCrop
                                                            .proposalStatus ==
                                                            _vm.dictSource[
                                                              "INSURED_STATUS_DROPDOWN"
                                                            ][2].dictCode &&
                                                          _vm.currentFlowNode &&
                                                          _vm.currentFlowNode
                                                            .code !=
                                                            "SuggestEffectiveTime"
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  key: "insFlowNode",
                                                                  staticStyle: {
                                                                    border:
                                                                      "1px solid #ddd",
                                                                    "border-radius":
                                                                      "5px",
                                                                    padding:
                                                                      "10px",
                                                                    "margin-top":
                                                                      "10px",
                                                                  },
                                                                  attrs: {
                                                                    id: "insFlowNode",
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .currentInsCrop
                                                                    .flowNode &&
                                                                  _vm
                                                                    .currentInsCrop
                                                                    .proposalStatus ==
                                                                    _vm
                                                                      .dictSource[
                                                                      "INSURED_STATUS_DROPDOWN"
                                                                    ][2]
                                                                      .dictCode &&
                                                                  _vm.currentFlowNode &&
                                                                  _vm
                                                                    .currentFlowNode
                                                                    .code ==
                                                                    "VehicleConfirm"
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          key: "VehicleConfirm",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            [
                                                                              _c(
                                                                                "el-radio-group",
                                                                                {
                                                                                  ref: "vehicleRadioGroup",
                                                                                  on: {
                                                                                    change:
                                                                                      _vm.changeRadioItem,
                                                                                  },
                                                                                  model:
                                                                                    {
                                                                                      value:
                                                                                        _vm
                                                                                          .selectVehiceItems
                                                                                          .selectVehiceItem,
                                                                                      callback:
                                                                                        function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            _vm.selectVehiceItems,
                                                                                            "selectVehiceItem",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                      expression:
                                                                                        "selectVehiceItems.selectVehiceItem",
                                                                                    },
                                                                                },
                                                                                _vm._l(
                                                                                  _vm
                                                                                    .currentFlowNode
                                                                                    .vehicleList,
                                                                                  function (
                                                                                    _item,
                                                                                    _index
                                                                                  ) {
                                                                                    return _c(
                                                                                      "div",
                                                                                      {
                                                                                        key: _index,
                                                                                        staticStyle:
                                                                                          {
                                                                                            display:
                                                                                              "flex",
                                                                                            padding:
                                                                                              "10px",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "el-radio",
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                label:
                                                                                                  _item,
                                                                                              },
                                                                                            on: {
                                                                                              change:
                                                                                                _vm.changeRadio,
                                                                                            },
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                _vm.getProposalVehicleType(
                                                                                                  _item
                                                                                                )
                                                                                              )
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  }
                                                                                ),
                                                                                0
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  display:
                                                                                    "flex",
                                                                                  "justify-content":
                                                                                    "center",
                                                                                  "margin-top":
                                                                                    "15px",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm.showPayBtn
                                                                                ? _c(
                                                                                    "el-button",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          type: "primary",
                                                                                          size: "mini",
                                                                                          plain:
                                                                                            "",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            $event.stopPropagation()
                                                                                            return _vm.upDateInsureQuote(
                                                                                              $event
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " 确认选择并继续报价 "
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm
                                                                    .currentInsCrop
                                                                    .flowNode &&
                                                                  _vm
                                                                    .currentInsCrop
                                                                    .proposalStatus ==
                                                                    _vm
                                                                      .dictSource[
                                                                      "INSURED_STATUS_DROPDOWN"
                                                                    ][2]
                                                                      .dictCode &&
                                                                  _vm.currentFlowNode &&
                                                                  (_vm
                                                                    .currentFlowNode
                                                                    .code ==
                                                                    "PaicDecisionSchemes" ||
                                                                    _vm
                                                                      .currentFlowNode
                                                                      .code ==
                                                                      "DecisionSchemes")
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          key: "PaicDecisionSchemes",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            [
                                                                              _c(
                                                                                "el-radio-group",
                                                                                {
                                                                                  ref: "vehicleRadioGroup",
                                                                                  on: {
                                                                                    change:
                                                                                      _vm.changeRadioItem,
                                                                                  },
                                                                                  model:
                                                                                    {
                                                                                      value:
                                                                                        _vm
                                                                                          .selectSchemeList
                                                                                          .schemeItem,
                                                                                      callback:
                                                                                        function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            _vm.selectSchemeList,
                                                                                            "schemeItem",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                      expression:
                                                                                        "selectSchemeList.schemeItem",
                                                                                    },
                                                                                },
                                                                                _vm._l(
                                                                                  _vm
                                                                                    .currentFlowNode
                                                                                    .schemeList,
                                                                                  function (
                                                                                    _item,
                                                                                    _index
                                                                                  ) {
                                                                                    return _c(
                                                                                      "div",
                                                                                      {
                                                                                        key: _index,
                                                                                        staticStyle:
                                                                                          {
                                                                                            display:
                                                                                              "flex",
                                                                                            padding:
                                                                                              "10px",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "el-radio",
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                label:
                                                                                                  _item,
                                                                                              },
                                                                                            on: {
                                                                                              change:
                                                                                                _vm.changeRadio,
                                                                                            },
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                _vm.getSchemeType(
                                                                                                  _item
                                                                                                )
                                                                                              )
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  }
                                                                                ),
                                                                                0
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  display:
                                                                                    "flex",
                                                                                  "justify-content":
                                                                                    "center",
                                                                                  "margin-top":
                                                                                    "15px",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm.showPayBtn
                                                                                ? _c(
                                                                                    "el-button",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          type: "primary",
                                                                                          size: "mini",
                                                                                          plain:
                                                                                            "",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            $event.stopPropagation()
                                                                                            return _vm.selectSchemeToQuote(
                                                                                              $event
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " 确认选择并继续报价 "
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm
                                                                    .currentInsCrop
                                                                    .flowNode &&
                                                                  _vm
                                                                    .currentInsCrop
                                                                    .proposalStatus ==
                                                                    _vm
                                                                      .dictSource[
                                                                      "INSURED_STATUS_DROPDOWN"
                                                                    ][2]
                                                                      .dictCode &&
                                                                  _vm.currentFlowNode &&
                                                                  (_vm
                                                                    .currentFlowNode
                                                                    .code ==
                                                                    "ImageVerifyCode" ||
                                                                    _vm
                                                                      .currentFlowNode
                                                                      .code ==
                                                                      "bjVehicleQuery")
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              padding:
                                                                                "20px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  display:
                                                                                    "flex",
                                                                                  "justify-content":
                                                                                    "center",
                                                                                  "padding-bottom":
                                                                                    "20px",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _vm._v(
                                                                                    "请输入下图中的字符，不区分大小写"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm
                                                                            .currentFlowNode
                                                                            .code ==
                                                                            "ImageVerifyCode" &&
                                                                          _vm
                                                                            .form
                                                                            .currentFlowNode &&
                                                                          _vm
                                                                            .form
                                                                            .currentFlowNode
                                                                            .imageVerifyCode
                                                                            ? _c(
                                                                                "div",
                                                                                {
                                                                                  key: "ImageVerifyCode",
                                                                                },
                                                                                _vm._l(
                                                                                  _vm
                                                                                    .form
                                                                                    .currentFlowNode
                                                                                    .imageVerifyCode,
                                                                                  function (
                                                                                    item,
                                                                                    index
                                                                                  ) {
                                                                                    return _c(
                                                                                      "div",
                                                                                      {
                                                                                        key: index,
                                                                                        staticStyle:
                                                                                          {
                                                                                            margin:
                                                                                              "0 20px",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        item.imageBase64
                                                                                          ? _c(
                                                                                              "div",
                                                                                              [
                                                                                                _c(
                                                                                                  "div",
                                                                                                  {
                                                                                                    staticStyle:
                                                                                                      {
                                                                                                        display:
                                                                                                          "flex",
                                                                                                        "justify-content":
                                                                                                          "center",
                                                                                                      },
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "el-form-item",
                                                                                                      {
                                                                                                        ref:
                                                                                                          "currentFlowNode.imageVerifyCode." +
                                                                                                          index +
                                                                                                          ".verifyCode",
                                                                                                        refInFor: true,
                                                                                                        staticClass:
                                                                                                          "special_row_content",
                                                                                                        attrs:
                                                                                                          {
                                                                                                            label:
                                                                                                              (item.verifyCodeId &&
                                                                                                              item.verifyCodeId.split(
                                                                                                                "|"
                                                                                                              )[0]
                                                                                                                ? item.verifyCodeId.split(
                                                                                                                    "|"
                                                                                                                  )[0] ==
                                                                                                                  "1"
                                                                                                                  ? "商业险"
                                                                                                                  : "交强险"
                                                                                                                : "") +
                                                                                                              "验证码",
                                                                                                            prop:
                                                                                                              "currentFlowNode.imageVerifyCode." +
                                                                                                              index +
                                                                                                              ".verifyCode",
                                                                                                            rules:
                                                                                                              {
                                                                                                                required: true,
                                                                                                                message:
                                                                                                                  "请输入验证码",
                                                                                                                trigger:
                                                                                                                  "blur",
                                                                                                              },
                                                                                                          },
                                                                                                      },
                                                                                                      [
                                                                                                        _c(
                                                                                                          "div",
                                                                                                          {
                                                                                                            staticStyle:
                                                                                                              {
                                                                                                                display:
                                                                                                                  "flex",
                                                                                                                "align-items":
                                                                                                                  "center",
                                                                                                              },
                                                                                                          },
                                                                                                          [
                                                                                                            _c(
                                                                                                              "el-input",
                                                                                                              {
                                                                                                                staticStyle:
                                                                                                                  {
                                                                                                                    "margin-right":
                                                                                                                      "2px",
                                                                                                                  },
                                                                                                                attrs:
                                                                                                                  {
                                                                                                                    clearable:
                                                                                                                      "",
                                                                                                                  },
                                                                                                                model:
                                                                                                                  {
                                                                                                                    value:
                                                                                                                      item.verifyCode,
                                                                                                                    callback:
                                                                                                                      function (
                                                                                                                        $$v
                                                                                                                      ) {
                                                                                                                        _vm.$set(
                                                                                                                          item,
                                                                                                                          "verifyCode",
                                                                                                                          typeof $$v ===
                                                                                                                            "string"
                                                                                                                            ? $$v.trim()
                                                                                                                            : $$v
                                                                                                                        )
                                                                                                                      },
                                                                                                                    expression:
                                                                                                                      "item.verifyCode",
                                                                                                                  },
                                                                                                              }
                                                                                                            ),
                                                                                                            _c(
                                                                                                              "div",
                                                                                                              {
                                                                                                                staticStyle:
                                                                                                                  {
                                                                                                                    display:
                                                                                                                      "flex",
                                                                                                                    "justify-content":
                                                                                                                      "center",
                                                                                                                  },
                                                                                                              },
                                                                                                              [
                                                                                                                _c(
                                                                                                                  "img",
                                                                                                                  {
                                                                                                                    staticStyle:
                                                                                                                      {
                                                                                                                        width:
                                                                                                                          "100px",
                                                                                                                        height:
                                                                                                                          "28px",
                                                                                                                      },
                                                                                                                    attrs:
                                                                                                                      {
                                                                                                                        src:
                                                                                                                          "data:image/png;base64," +
                                                                                                                          item.imageBase64,
                                                                                                                        alt: "",
                                                                                                                      },
                                                                                                                  }
                                                                                                                ),
                                                                                                              ]
                                                                                                            ),
                                                                                                          ],
                                                                                                          1
                                                                                                        ),
                                                                                                      ]
                                                                                                    ),
                                                                                                  ],
                                                                                                  1
                                                                                                ),
                                                                                              ]
                                                                                            )
                                                                                          : _vm._e(),
                                                                                      ]
                                                                                    )
                                                                                  }
                                                                                ),
                                                                                0
                                                                              )
                                                                            : _vm._e(),
                                                                          _vm
                                                                            .currentFlowNode
                                                                            .code ==
                                                                          "bjVehicleQuery"
                                                                            ? _c(
                                                                                "div",
                                                                                {
                                                                                  key: "bjVehicleQuery",
                                                                                },
                                                                                _vm._l(
                                                                                  _vm
                                                                                    .form
                                                                                    .currentFlowNode
                                                                                    .imageVerifyCode,
                                                                                  function (
                                                                                    item,
                                                                                    index
                                                                                  ) {
                                                                                    return _c(
                                                                                      "div",
                                                                                      {
                                                                                        key: index,
                                                                                        staticStyle:
                                                                                          {
                                                                                            margin:
                                                                                              "0 20px",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        item.imageBase64
                                                                                          ? _c(
                                                                                              "div",
                                                                                              [
                                                                                                _c(
                                                                                                  "div",
                                                                                                  {
                                                                                                    staticStyle:
                                                                                                      {
                                                                                                        display:
                                                                                                          "flex",
                                                                                                        "justify-content":
                                                                                                          "center",
                                                                                                      },
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "el-form-item",
                                                                                                      {
                                                                                                        ref:
                                                                                                          "currentFlowNode.imageVerifyCode." +
                                                                                                          index +
                                                                                                          ".checkCode",
                                                                                                        refInFor: true,
                                                                                                        staticClass:
                                                                                                          "special_row_content",
                                                                                                        attrs:
                                                                                                          {
                                                                                                            "label-width":
                                                                                                              "165px",
                                                                                                            label:
                                                                                                              "" +
                                                                                                              (_vm
                                                                                                                .form
                                                                                                                .currentFlowNode
                                                                                                                .name ||
                                                                                                                "验证码"),
                                                                                                            prop:
                                                                                                              "currentFlowNode.imageVerifyCode." +
                                                                                                              index +
                                                                                                              ".checkCode",
                                                                                                            rules:
                                                                                                              {
                                                                                                                required: true,
                                                                                                                message:
                                                                                                                  "请输入验证码",
                                                                                                                trigger:
                                                                                                                  "blur",
                                                                                                              },
                                                                                                          },
                                                                                                      },
                                                                                                      [
                                                                                                        _c(
                                                                                                          "div",
                                                                                                          {
                                                                                                            staticStyle:
                                                                                                              {
                                                                                                                display:
                                                                                                                  "flex",
                                                                                                                "align-items":
                                                                                                                  "center",
                                                                                                              },
                                                                                                          },
                                                                                                          [
                                                                                                            _c(
                                                                                                              "el-input",
                                                                                                              {
                                                                                                                staticStyle:
                                                                                                                  {
                                                                                                                    "margin-right":
                                                                                                                      "2px",
                                                                                                                  },
                                                                                                                attrs:
                                                                                                                  {
                                                                                                                    clearable:
                                                                                                                      "",
                                                                                                                  },
                                                                                                                model:
                                                                                                                  {
                                                                                                                    value:
                                                                                                                      item.checkCode,
                                                                                                                    callback:
                                                                                                                      function (
                                                                                                                        $$v
                                                                                                                      ) {
                                                                                                                        _vm.$set(
                                                                                                                          item,
                                                                                                                          "checkCode",
                                                                                                                          typeof $$v ===
                                                                                                                            "string"
                                                                                                                            ? $$v.trim()
                                                                                                                            : $$v
                                                                                                                        )
                                                                                                                      },
                                                                                                                    expression:
                                                                                                                      "item.checkCode",
                                                                                                                  },
                                                                                                              }
                                                                                                            ),
                                                                                                            _c(
                                                                                                              "div",
                                                                                                              {
                                                                                                                staticStyle:
                                                                                                                  {
                                                                                                                    display:
                                                                                                                      "flex",
                                                                                                                    "justify-content":
                                                                                                                      "center",
                                                                                                                  },
                                                                                                              },
                                                                                                              [
                                                                                                                _c(
                                                                                                                  "img",
                                                                                                                  {
                                                                                                                    staticStyle:
                                                                                                                      {
                                                                                                                        width:
                                                                                                                          "100px",
                                                                                                                        height:
                                                                                                                          "28px",
                                                                                                                      },
                                                                                                                    attrs:
                                                                                                                      {
                                                                                                                        src:
                                                                                                                          "data:image/png;base64," +
                                                                                                                          item.imageBase64,
                                                                                                                        alt: "",
                                                                                                                      },
                                                                                                                  }
                                                                                                                ),
                                                                                                              ]
                                                                                                            ),
                                                                                                          ],
                                                                                                          1
                                                                                                        ),
                                                                                                      ]
                                                                                                    ),
                                                                                                  ],
                                                                                                  1
                                                                                                ),
                                                                                              ]
                                                                                            )
                                                                                          : _vm._e(),
                                                                                      ]
                                                                                    )
                                                                                  }
                                                                                ),
                                                                                0
                                                                              )
                                                                            : _vm._e(),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  display:
                                                                                    "flex",
                                                                                  "justify-content":
                                                                                    "center",
                                                                                  "margin-top":
                                                                                    "10px",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm.showPayBtn
                                                                                ? _c(
                                                                                    "el-button",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          type: "primary",
                                                                                          size: "mini",
                                                                                          plain:
                                                                                            "",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            $event.stopPropagation()
                                                                                            return _vm.verifyCodeUpDateInsure(
                                                                                              $event
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " 确认并重新报价 "
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm
                                                                    .currentInsCrop
                                                                    .flowNode &&
                                                                  _vm
                                                                    .currentInsCrop
                                                                    .proposalStatus ==
                                                                    _vm
                                                                      .dictSource[
                                                                      "INSURED_STATUS_DROPDOWN"
                                                                    ][2]
                                                                      .dictCode &&
                                                                  _vm.currentFlowNode &&
                                                                  _vm
                                                                    .currentFlowNode
                                                                    .code ==
                                                                    "AdjustSelfFactor"
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          key: "AdjustSelfFactor",
                                                                          staticStyle:
                                                                            {
                                                                              padding:
                                                                                "20px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  display:
                                                                                    "flex",
                                                                                  "justify-content":
                                                                                    "center",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "el-form-item",
                                                                                {
                                                                                  ref: "currentFlowNode.AdjustSelfFactor",
                                                                                  staticClass:
                                                                                    "special_row_content",
                                                                                  attrs:
                                                                                    {
                                                                                      label:
                                                                                        "请输入折扣系数",
                                                                                      required: true,
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticStyle:
                                                                                        {
                                                                                          display:
                                                                                            "flex",
                                                                                          "align-items":
                                                                                            "center",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "el-input",
                                                                                        {
                                                                                          staticStyle:
                                                                                            {
                                                                                              "margin-right":
                                                                                                "2px",
                                                                                            },
                                                                                          attrs:
                                                                                            {
                                                                                              type: "text",
                                                                                              clearable:
                                                                                                "",
                                                                                            },
                                                                                          on: {
                                                                                            input:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                _vm.form.currentFlowNode.AdjustSelfFactor =
                                                                                                  _vm.onInput(
                                                                                                    _vm
                                                                                                      .form
                                                                                                      .currentFlowNode
                                                                                                      .AdjustSelfFactor,
                                                                                                    0,
                                                                                                    100,
                                                                                                    4
                                                                                                  )
                                                                                              },
                                                                                          },
                                                                                          nativeOn:
                                                                                            {
                                                                                              keyup:
                                                                                                function (
                                                                                                  $event
                                                                                                ) {
                                                                                                  _vm.form.currentFlowNode.AdjustSelfFactor =
                                                                                                    _vm.onInput(
                                                                                                      _vm
                                                                                                        .form
                                                                                                        .currentFlowNode
                                                                                                        .AdjustSelfFactor,
                                                                                                      0,
                                                                                                      100,
                                                                                                      4
                                                                                                    )
                                                                                                },
                                                                                            },
                                                                                          model:
                                                                                            {
                                                                                              value:
                                                                                                _vm
                                                                                                  .form
                                                                                                  .currentFlowNode
                                                                                                  .AdjustSelfFactor,
                                                                                              callback:
                                                                                                function (
                                                                                                  $$v
                                                                                                ) {
                                                                                                  _vm.$set(
                                                                                                    _vm
                                                                                                      .form
                                                                                                      .currentFlowNode,
                                                                                                    "AdjustSelfFactor",
                                                                                                    typeof $$v ===
                                                                                                      "string"
                                                                                                      ? $$v.trim()
                                                                                                      : $$v
                                                                                                  )
                                                                                                },
                                                                                              expression:
                                                                                                "form.currentFlowNode.AdjustSelfFactor",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  display:
                                                                                    "flex",
                                                                                  "justify-content":
                                                                                    "center",
                                                                                  "margin-top":
                                                                                    "10px",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm.showPayBtn
                                                                                ? _c(
                                                                                    "el-button",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          type: "primary",
                                                                                          size: "mini",
                                                                                          plain:
                                                                                            "",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            $event.stopPropagation()
                                                                                            return _vm.verifyCodeUpDateInsure(
                                                                                              $event
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " 确认并重新报价 "
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm
                                                                    .currentInsCrop
                                                                    .flowNode &&
                                                                  _vm
                                                                    .currentInsCrop
                                                                    .proposalStatus ==
                                                                    _vm
                                                                      .dictSource[
                                                                      "INSURED_STATUS_DROPDOWN"
                                                                    ][2]
                                                                      .dictCode &&
                                                                  _vm.currentFlowNode &&
                                                                  _vm
                                                                    .currentFlowNode
                                                                    .code ==
                                                                    "PaicAccommodationNode"
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          key: "PaicAccommodationNode",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "PaicAccommodationNode",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  quoteId:
                                                                                    _vm.quoteId.toString(),
                                                                                  showPayBtn:
                                                                                    _vm.showPayBtn,
                                                                                  proposalId:
                                                                                    _vm.currentInsCrop &&
                                                                                    _vm
                                                                                      .currentInsCrop
                                                                                      .proposalId,
                                                                                  businessTypeList:
                                                                                    _vm.businessTypeList,
                                                                                },
                                                                              on: {
                                                                                callBack:
                                                                                  _vm.accommodationToQuote,
                                                                              },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm
                                                                    .currentInsCrop
                                                                    .flowNode &&
                                                                  _vm
                                                                    .currentInsCrop
                                                                    .proposalStatus ==
                                                                    _vm
                                                                      .dictSource[
                                                                      "INSURED_STATUS_DROPDOWN"
                                                                    ][2]
                                                                      .dictCode &&
                                                                  _vm.currentFlowNode &&
                                                                  _vm
                                                                    .currentFlowNode
                                                                    .code ==
                                                                    "QueryTrafficManagement"
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          key: "QueryTrafficManagement",
                                                                          staticStyle:
                                                                            {
                                                                              padding:
                                                                                "20px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  display:
                                                                                    "flex",
                                                                                  "justify-content":
                                                                                    "center",
                                                                                  "padding-bottom":
                                                                                    "20px",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      (_vm.currentFlowNode &&
                                                                                        _vm
                                                                                          .currentFlowNode
                                                                                          .message) ||
                                                                                        ""
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  display:
                                                                                    "flex",
                                                                                  "justify-content":
                                                                                    "center",
                                                                                  "margin-top":
                                                                                    "10px",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm.showPayBtn
                                                                                ? _c(
                                                                                    "el-button",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          type: "primary",
                                                                                          size: "mini",
                                                                                          plain:
                                                                                            "",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            $event.stopPropagation()
                                                                                            return _vm.verifyCodeUpDateInsure(
                                                                                              $event
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " 确认并重新报价 "
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm.currentInsCrop
                                                    .proposalStatus ==
                                                    _vm.dictSource[
                                                      "INSURED_STATUS_DROPDOWN"
                                                    ][7].dictCode ||
                                                  _vm.currentInsCrop
                                                    .proposalStatus ==
                                                    _vm.dictSource[
                                                      "INSURED_STATUS_DROPDOWN"
                                                    ][3].dictCode ||
                                                  _vm.currentInsCrop
                                                    .proposalStatus ==
                                                    _vm.dictSource[
                                                      "INSURED_STATUS_DROPDOWN"
                                                    ][6].dictCode ||
                                                  _vm.currentInsCrop
                                                    .proposalStatus ==
                                                    _vm.dictSource[
                                                      "INSURED_STATUS_DROPDOWN"
                                                    ][5].dictCode ||
                                                  _vm.currentInsCrop
                                                    .proposalStatus ==
                                                    _vm.dictSource[
                                                      "INSURED_STATUS_DROPDOWN"
                                                    ][11].dictCode ||
                                                  _vm.currentInsCrop
                                                    .proposalStatus ==
                                                    _vm.dictSource[
                                                      "INSURED_STATUS_DROPDOWN"
                                                    ][12].dictCode
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            border:
                                                              "1px solid #fbe8c7",
                                                            "border-radius":
                                                              "5px",
                                                            width: "100%",
                                                            "background-color":
                                                              "#fff7e7",
                                                            padding: "10px",
                                                            "font-size": "13px",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            [
                                                              _c("svg-icon", {
                                                                staticStyle: {
                                                                  "font-size":
                                                                    "18px",
                                                                },
                                                                attrs: {
                                                                  "icon-class":
                                                                    "warning",
                                                                },
                                                              }),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    color:
                                                                      "#a49270",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm
                                                                        .currentInsCrop
                                                                        .proposalStatus ==
                                                                        _vm
                                                                          .dictSource[
                                                                          "INSURED_STATUS_DROPDOWN"
                                                                        ][7]
                                                                          .dictCode
                                                                        ? "核保失败原因"
                                                                        : _vm
                                                                            .currentInsCrop
                                                                            .proposalStatus ==
                                                                            _vm
                                                                              .dictSource[
                                                                              "INSURED_STATUS_DROPDOWN"
                                                                            ][5]
                                                                              .dictCode ||
                                                                          _vm
                                                                            .currentInsCrop
                                                                            .proposalStatus ==
                                                                            _vm
                                                                              .dictSource[
                                                                              "INSURED_STATUS_DROPDOWN"
                                                                            ][6]
                                                                              .dictCode ||
                                                                          _vm
                                                                            .currentInsCrop
                                                                            .proposalStatus ==
                                                                            _vm
                                                                              .dictSource[
                                                                              "INSURED_STATUS_DROPDOWN"
                                                                            ][5]
                                                                              .dictCode ||
                                                                          _vm
                                                                            .currentInsCrop
                                                                            .proposalStatus ==
                                                                            _vm
                                                                              .dictSource[
                                                                              "INSURED_STATUS_DROPDOWN"
                                                                            ][11]
                                                                              .dictCode
                                                                        ? "重要提示"
                                                                        : "失败原因"
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              !_vm._.isEmpty(
                                                                _vm.proposalSolutionList
                                                              ) &&
                                                              _vm.canViewSolution
                                                                ? _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "insCorpJumpLink",
                                                                      staticStyle:
                                                                        {
                                                                          "margin-left":
                                                                            "10px",
                                                                        },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.viewSolution(
                                                                              _vm
                                                                                .proposalSolutionList[0]
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "点此查看解决方案"
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              _vm._.isEmpty(
                                                                _vm.proposalSolutionList
                                                              ) &&
                                                              _vm.canViewSolution
                                                                ? _c(
                                                                    "el-button",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          "margin-left":
                                                                            "10px",
                                                                        },
                                                                      attrs: {
                                                                        type: "text",
                                                                        size: "small",
                                                                        icon: "el-icon-refresh",
                                                                        plain:
                                                                          "",
                                                                        loading:
                                                                          _vm.loadingStatus,
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.refreshSolution(
                                                                              _vm.currentInsCrop
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.loadingStatus
                                                                            ? "正在获取..."
                                                                            : "获取解决方案"
                                                                        )
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              !_vm._.isEmpty(
                                                                _vm.proposalSolutionList
                                                              ) &&
                                                              _vm.canViewSolution
                                                                ? _c(
                                                                    "el-button",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          "margin-left":
                                                                            "10px",
                                                                        },
                                                                      attrs: {
                                                                        type: "text",
                                                                        size: "small",
                                                                        icon: "el-icon-refresh",
                                                                        plain:
                                                                          "",
                                                                        loading:
                                                                          _vm.loadingStatus,
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.refreshSolution(
                                                                              _vm.currentInsCrop
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.loadingStatus
                                                                            ? "正在刷新..."
                                                                            : "刷新解决方案"
                                                                        )
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                padding: "10px",
                                                                "font-size":
                                                                  "12px",
                                                              },
                                                            },
                                                            [
                                                              _vm.currentInsCrop
                                                                .proposalResult
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          "word-break":
                                                                            "break-all",
                                                                        },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm
                                                                                .currentInsCrop
                                                                                .proposalResult
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _c(
                                                                    "div",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          "word-break":
                                                                            "break-all",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm.currentFlowNode &&
                                                                      _vm
                                                                        .currentFlowNode
                                                                        .message
                                                                        ? _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    _vm
                                                                                      .currentFlowNode
                                                                                      .message
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _c(
                                                                            "div",
                                                                            {
                                                                              domProps:
                                                                                {
                                                                                  innerHTML:
                                                                                    _vm._s(
                                                                                      _vm
                                                                                        .currentInsCrop
                                                                                        .flowNode
                                                                                    ),
                                                                                },
                                                                            }
                                                                          ),
                                                                    ]
                                                                  ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]),
                                                _vm.currentInsCrop
                                                  .proposalStatus !=
                                                  _vm.dictSource[
                                                    "INSURED_STATUS_OTHER"
                                                  ][0].dictCode &&
                                                _vm.currentInsCrop
                                                  .proposalStatus !=
                                                  _vm.dictSource[
                                                    "INSURED_STATUS_DROPDOWN"
                                                  ][2].dictCode &&
                                                _vm.currentInsCrop
                                                  .proposalStatus !=
                                                  _vm.dictSource[
                                                    "INSURED_STATUS_DROPDOWN"
                                                  ][12].dictCode
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          "margin-top": "10px",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              display: "flex",
                                                            },
                                                          },
                                                          [
                                                            _vm.currentInsCrop
                                                              .proposalStatus !=
                                                            _vm.dictSource[
                                                              "INSURED_STATUS_DROPDOWN"
                                                            ][9].dictCode
                                                              ? _c("div", [
                                                                  _vm.currentBzInsItem &&
                                                                  _vm
                                                                    .currentBzInsItem
                                                                    .proposalNo
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              color:
                                                                                "#999",
                                                                              "font-size":
                                                                                "12px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "交强险投保单号："
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm
                                                                                    .currentBzInsItem
                                                                                    .proposalNo
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm.currentBsInsItem &&
                                                                  _vm
                                                                    .currentBsInsItem
                                                                    .proposalNo
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              color:
                                                                                "#999",
                                                                              "font-size":
                                                                                "12px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "商业险投保单号："
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm
                                                                                    .currentBsInsItem
                                                                                    .proposalNo
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm.currentNonInsItem &&
                                                                  _vm
                                                                    .currentNonInsItem
                                                                    .proposalNo
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              color:
                                                                                "#999",
                                                                              "font-size":
                                                                                "12px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "驾意险投保单号："
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm
                                                                                    .currentNonInsItem
                                                                                    .proposalNo ||
                                                                                    "-"
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ])
                                                              : _vm._e(),
                                                            _vm.currentInsCrop
                                                              .proposalStatus ==
                                                            _vm.dictSource[
                                                              "INSURED_STATUS_DROPDOWN"
                                                            ][9].dictCode
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        "margin-left":
                                                                          "20px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm.currentBzInsItem &&
                                                                    _vm
                                                                      .currentBzInsItem
                                                                      .policyNo
                                                                      ? _c(
                                                                          "div",
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                color:
                                                                                  "#999",
                                                                                "font-size":
                                                                                  "12px",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  "交强险保单号："
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm
                                                                                      .currentBzInsItem
                                                                                      .policyNo
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    _vm.currentBsInsItem &&
                                                                    _vm
                                                                      .currentBsInsItem
                                                                      .policyNo
                                                                      ? _c(
                                                                          "div",
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                color:
                                                                                  "#999",
                                                                                "font-size":
                                                                                  "12px",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  "商业险保单号："
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm
                                                                                      .currentBsInsItem
                                                                                      .policyNo
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    _vm.currentNonInsItem &&
                                                                    _vm
                                                                      .currentNonInsItem
                                                                      .policyNo
                                                                      ? _c(
                                                                          "div",
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                color:
                                                                                  "#999",
                                                                                "font-size":
                                                                                  "12px",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  "驾意险保单号："
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm
                                                                                      .currentNonInsItem
                                                                                      .policyNo ||
                                                                                      "-"
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        ),
                                                        _vm.currentInsCrop
                                                          .proposalStatus !=
                                                        _vm.dictSource[
                                                          "INSURED_STATUS_DROPDOWN"
                                                        ][3].dictCode
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "reminder_msg",
                                                              },
                                                              [
                                                                !_vm._.isEmpty(
                                                                  _vm.currentBzInsItem
                                                                )
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            margin:
                                                                              "0px 5px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              "交强险折扣系数："
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.currentBzInsItem &&
                                                                                  _vm
                                                                                    .currentBzInsItem
                                                                                    .discountFactor
                                                                                  ? _vm
                                                                                      .currentBzInsItem
                                                                                      .discountFactor
                                                                                  : "--"
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                !_vm._.isEmpty(
                                                                  _vm.currentBzInsItem
                                                                )
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            margin:
                                                                              "0px 5px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              "交强险平台信息："
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._.isEmpty(
                                                                          _vm
                                                                            .currentBzInsItem
                                                                            .proposalProductPlatformList
                                                                        )
                                                                          ? _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  "无"
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                        !_vm._.isEmpty(
                                                                          _vm
                                                                            .currentBzInsItem
                                                                            .proposalProductPlatformList
                                                                        )
                                                                          ? _c(
                                                                              "el-popover",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    placement:
                                                                                      "bottom",
                                                                                    width:
                                                                                      "240",
                                                                                    trigger:
                                                                                      "click",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "table",
                                                                                  {
                                                                                    staticStyle:
                                                                                      {
                                                                                        width:
                                                                                          "100%",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "colgroup",
                                                                                      [
                                                                                        _c(
                                                                                          "col",
                                                                                          {
                                                                                            staticStyle:
                                                                                              {
                                                                                                width:
                                                                                                  "70%",
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                        _c(
                                                                                          "col",
                                                                                          {
                                                                                            staticStyle:
                                                                                              {
                                                                                                width:
                                                                                                  "30%",
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "thead",
                                                                                      {
                                                                                        staticStyle:
                                                                                          {
                                                                                            "background-color":
                                                                                              "#f5f5f5",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "tr",
                                                                                          {
                                                                                            staticStyle:
                                                                                              {
                                                                                                "font-size":
                                                                                                  "13px",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "th",
                                                                                              {
                                                                                                attrs:
                                                                                                  {
                                                                                                    scope:
                                                                                                      "col",
                                                                                                  },
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "名称"
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                            _c(
                                                                                              "th",
                                                                                              {
                                                                                                attrs:
                                                                                                  {
                                                                                                    scope:
                                                                                                      "col",
                                                                                                  },
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "值"
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "tbody",
                                                                                      _vm._l(
                                                                                        _vm
                                                                                          .currentBzInsItem
                                                                                          .proposalProductPlatformList,
                                                                                        function (
                                                                                          pitem,
                                                                                          index
                                                                                        ) {
                                                                                          return _c(
                                                                                            "tr",
                                                                                            {
                                                                                              key: index,
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "td",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      pitem.platformName
                                                                                                    )
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                              _c(
                                                                                                "td",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      pitem.platformValue
                                                                                                    )
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          )
                                                                                        }
                                                                                      ),
                                                                                      0
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "i",
                                                                                  {
                                                                                    staticClass:
                                                                                      "el-icon-warning-outline",
                                                                                    staticStyle:
                                                                                      {
                                                                                        "font-size":
                                                                                          "16px",
                                                                                        margin:
                                                                                          "0 5px",
                                                                                        cursor:
                                                                                          "pointer",
                                                                                      },
                                                                                    attrs:
                                                                                      {
                                                                                        slot: "reference",
                                                                                      },
                                                                                    slot: "reference",
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                                !_vm._.isEmpty(
                                                                  _vm.currentBsInsItem
                                                                )
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            margin:
                                                                              "0px 5px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              "商业险总折扣系数："
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.currentBsInsItem &&
                                                                                  _vm
                                                                                    .currentBsInsItem
                                                                                    .discountFactor
                                                                                  ? _vm
                                                                                      .currentBsInsItem
                                                                                      .discountFactor
                                                                                  : "--"
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "el-popover",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                placement:
                                                                                  "bottom",
                                                                                width:
                                                                                  "200",
                                                                                trigger:
                                                                                  "click",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "table",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    width:
                                                                                      "100%",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "colgroup",
                                                                                  [
                                                                                    _c(
                                                                                      "col",
                                                                                      {
                                                                                        staticStyle:
                                                                                          {
                                                                                            width:
                                                                                              "70%",
                                                                                          },
                                                                                      }
                                                                                    ),
                                                                                    _c(
                                                                                      "col",
                                                                                      {
                                                                                        staticStyle:
                                                                                          {
                                                                                            width:
                                                                                              "30%",
                                                                                          },
                                                                                      }
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "thead",
                                                                                  {
                                                                                    staticStyle:
                                                                                      {
                                                                                        "background-color":
                                                                                          "#f5f5f5",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "tr",
                                                                                      {
                                                                                        staticStyle:
                                                                                          {
                                                                                            "font-size":
                                                                                              "13px",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "th",
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                scope:
                                                                                                  "col",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "因子"
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "th",
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                scope:
                                                                                                  "col",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "数值"
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _vm.currentBsInsItem &&
                                                                                _vm
                                                                                  .currentBsInsItem
                                                                                  .proposalProductFactorUpdateVOList
                                                                                  ? _c(
                                                                                      "tbody",
                                                                                      _vm._l(
                                                                                        _vm
                                                                                          .currentBsInsItem
                                                                                          .proposalProductFactorUpdateVOList,
                                                                                        function (
                                                                                          item,
                                                                                          index
                                                                                        ) {
                                                                                          return _c(
                                                                                            "tr",
                                                                                            {
                                                                                              key: index,
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "td",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      item.factorName
                                                                                                    )
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                              _c(
                                                                                                "td",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      item.factorRate
                                                                                                    )
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          )
                                                                                        }
                                                                                      ),
                                                                                      0
                                                                                    )
                                                                                  : _vm._e(),
                                                                              ]
                                                                            ),
                                                                            _vm.currentBsInsItem &&
                                                                            _vm
                                                                              .currentBsInsItem
                                                                              .proposalProductFactorUpdateVOList
                                                                              ? _c(
                                                                                  "i",
                                                                                  {
                                                                                    staticClass:
                                                                                      "el-icon-warning-outline",
                                                                                    staticStyle:
                                                                                      {
                                                                                        "font-size":
                                                                                          "16px",
                                                                                        margin:
                                                                                          "0 5px",
                                                                                        cursor:
                                                                                          "pointer",
                                                                                      },
                                                                                    attrs:
                                                                                      {
                                                                                        slot: "reference",
                                                                                      },
                                                                                    slot: "reference",
                                                                                  }
                                                                                )
                                                                              : _vm._e(),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                                !_vm._.isEmpty(
                                                                  _vm.currentBsInsItem
                                                                )
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            margin:
                                                                              "0px 5px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              "商业险平台信息："
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._.isEmpty(
                                                                          _vm
                                                                            .currentBsInsItem
                                                                            .proposalProductPlatformList
                                                                        )
                                                                          ? _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  "无"
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                        !_vm._.isEmpty(
                                                                          _vm
                                                                            .currentBsInsItem
                                                                            .proposalProductPlatformList
                                                                        )
                                                                          ? _c(
                                                                              "el-popover",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    placement:
                                                                                      "bottom",
                                                                                    width:
                                                                                      "240",
                                                                                    trigger:
                                                                                      "click",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "table",
                                                                                  {
                                                                                    staticStyle:
                                                                                      {
                                                                                        width:
                                                                                          "100%",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "colgroup",
                                                                                      [
                                                                                        _c(
                                                                                          "col",
                                                                                          {
                                                                                            staticStyle:
                                                                                              {
                                                                                                width:
                                                                                                  "70%",
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                        _c(
                                                                                          "col",
                                                                                          {
                                                                                            staticStyle:
                                                                                              {
                                                                                                width:
                                                                                                  "30%",
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "thead",
                                                                                      {
                                                                                        staticStyle:
                                                                                          {
                                                                                            "background-color":
                                                                                              "#f5f5f5",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "tr",
                                                                                          {
                                                                                            staticStyle:
                                                                                              {
                                                                                                "font-size":
                                                                                                  "13px",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "th",
                                                                                              {
                                                                                                attrs:
                                                                                                  {
                                                                                                    scope:
                                                                                                      "col",
                                                                                                  },
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "名称"
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                            _c(
                                                                                              "th",
                                                                                              {
                                                                                                attrs:
                                                                                                  {
                                                                                                    scope:
                                                                                                      "col",
                                                                                                  },
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "值"
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "tbody",
                                                                                      _vm._l(
                                                                                        _vm
                                                                                          .currentBsInsItem
                                                                                          .proposalProductPlatformList,
                                                                                        function (
                                                                                          pitem,
                                                                                          index
                                                                                        ) {
                                                                                          return _c(
                                                                                            "tr",
                                                                                            {
                                                                                              key: index,
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "td",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      pitem.platformName
                                                                                                    )
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                              _c(
                                                                                                "td",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      pitem.platformValue
                                                                                                    )
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          )
                                                                                        }
                                                                                      ),
                                                                                      0
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "i",
                                                                                  {
                                                                                    staticClass:
                                                                                      "el-icon-warning-outline",
                                                                                    staticStyle:
                                                                                      {
                                                                                        "font-size":
                                                                                          "16px",
                                                                                        margin:
                                                                                          "0 5px",
                                                                                        cursor:
                                                                                          "pointer",
                                                                                      },
                                                                                    attrs:
                                                                                      {
                                                                                        slot: "reference",
                                                                                      },
                                                                                    slot: "reference",
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                                _vm.currentInsCrop &&
                                                                _vm
                                                                  .currentInsCrop
                                                                  .extendInfo
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            margin:
                                                                              "0px 5px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              "交通违法信息："
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._.isEmpty(
                                                                          _vm.violationExtendResult
                                                                        )
                                                                          ? _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  "无"
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                        !_vm._.isEmpty(
                                                                          _vm.violationExtendResult
                                                                        )
                                                                          ? _c(
                                                                              "el-popover",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    placement:
                                                                                      "bottom",
                                                                                    width:
                                                                                      "500",
                                                                                    trigger:
                                                                                      "click",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "table",
                                                                                  {
                                                                                    staticStyle:
                                                                                      {
                                                                                        width:
                                                                                          "100%",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "colgroup",
                                                                                      [
                                                                                        _c(
                                                                                          "col",
                                                                                          {
                                                                                            staticStyle:
                                                                                              {
                                                                                                width:
                                                                                                  "35%",
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                        _c(
                                                                                          "col",
                                                                                          {
                                                                                            staticStyle:
                                                                                              {
                                                                                                width:
                                                                                                  "65%",
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "thead",
                                                                                      {
                                                                                        staticStyle:
                                                                                          {
                                                                                            "background-color":
                                                                                              "#f5f5f5",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "tr",
                                                                                          {
                                                                                            staticStyle:
                                                                                              {
                                                                                                "font-size":
                                                                                                  "13px",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "th",
                                                                                              {
                                                                                                attrs:
                                                                                                  {
                                                                                                    scope:
                                                                                                      "col",
                                                                                                  },
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "名称"
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                            _c(
                                                                                              "th",
                                                                                              {
                                                                                                attrs:
                                                                                                  {
                                                                                                    scope:
                                                                                                      "col",
                                                                                                  },
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "值"
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _vm._l(
                                                                                      _vm.violationExtendResult,
                                                                                      function (
                                                                                        pitem,
                                                                                        index
                                                                                      ) {
                                                                                        return _c(
                                                                                          "tbody",
                                                                                          {
                                                                                            key: index,
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "tr",
                                                                                              [
                                                                                                _c(
                                                                                                  "td",
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "违法行为"
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                                _c(
                                                                                                  "td",
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      _vm._s(
                                                                                                        pitem.violationRecordTypeCode +
                                                                                                          " " +
                                                                                                          pitem.violationRecordTypeEsc ||
                                                                                                          "-"
                                                                                                      )
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                            _c(
                                                                                              "tr",
                                                                                              [
                                                                                                _c(
                                                                                                  "td",
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "违法地点"
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                                _c(
                                                                                                  "td",
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      _vm._s(
                                                                                                        pitem.violationPlace ||
                                                                                                          "-"
                                                                                                      )
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                            _c(
                                                                                              "tr",
                                                                                              [
                                                                                                _c(
                                                                                                  "td",
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "违法时间"
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                                _c(
                                                                                                  "td",
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      _vm._s(
                                                                                                        pitem.violationTime ||
                                                                                                          "-"
                                                                                                      )
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                            _c(
                                                                                              "tr",
                                                                                              [
                                                                                                _c(
                                                                                                  "td",
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "违法处理时间"
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                                _c(
                                                                                                  "td",
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      _vm._s(
                                                                                                        pitem.recognitionDate ||
                                                                                                          "-"
                                                                                                      )
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                            _c(
                                                                                              "tr",
                                                                                              [
                                                                                                _c(
                                                                                                  "td",
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "其他"
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                                _c(
                                                                                                  "td",
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      _vm._s(
                                                                                                        _vm.otherPropertyJoin(
                                                                                                          pitem.otherPropertyList
                                                                                                        )
                                                                                                      )
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                            _c(
                                                                                              "tr",
                                                                                              [
                                                                                                _c(
                                                                                                  "td",
                                                                                                  {
                                                                                                    staticStyle:
                                                                                                      {
                                                                                                        background:
                                                                                                          "#f0f0f0",
                                                                                                      },
                                                                                                    attrs:
                                                                                                      {
                                                                                                        colspan:
                                                                                                          "2",
                                                                                                      },
                                                                                                  }
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        )
                                                                                      }
                                                                                    ),
                                                                                  ],
                                                                                  2
                                                                                ),
                                                                                _c(
                                                                                  "i",
                                                                                  {
                                                                                    staticClass:
                                                                                      "el-icon-warning-outline",
                                                                                    staticStyle:
                                                                                      {
                                                                                        "font-size":
                                                                                          "16px",
                                                                                        margin:
                                                                                          "0 5px",
                                                                                        cursor:
                                                                                          "pointer",
                                                                                      },
                                                                                    attrs:
                                                                                      {
                                                                                        slot: "reference",
                                                                                      },
                                                                                    slot: "reference",
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                                _vm.currentInsCrop &&
                                                                _vm
                                                                  .currentInsCrop
                                                                  .extendInfo
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            margin:
                                                                              "0px 5px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              "出险理赔信息："
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._.isEmpty(
                                                                          _vm.claimsExtendResult
                                                                        )
                                                                          ? _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  "无"
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                        !_vm._.isEmpty(
                                                                          _vm.claimsExtendResult
                                                                        )
                                                                          ? _c(
                                                                              "el-popover",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    placement:
                                                                                      "bottom",
                                                                                    width:
                                                                                      "500",
                                                                                    trigger:
                                                                                      "click",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "table",
                                                                                  {
                                                                                    staticStyle:
                                                                                      {
                                                                                        width:
                                                                                          "100%",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "colgroup",
                                                                                      [
                                                                                        _c(
                                                                                          "col",
                                                                                          {
                                                                                            staticStyle:
                                                                                              {
                                                                                                width:
                                                                                                  "35%",
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                        _c(
                                                                                          "col",
                                                                                          {
                                                                                            staticStyle:
                                                                                              {
                                                                                                width:
                                                                                                  "65%",
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "thead",
                                                                                      {
                                                                                        staticStyle:
                                                                                          {
                                                                                            "background-color":
                                                                                              "#f5f5f5",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "tr",
                                                                                          {
                                                                                            staticStyle:
                                                                                              {
                                                                                                "font-size":
                                                                                                  "13px",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "th",
                                                                                              {
                                                                                                attrs:
                                                                                                  {
                                                                                                    scope:
                                                                                                      "col",
                                                                                                  },
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "名称"
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                            _c(
                                                                                              "th",
                                                                                              {
                                                                                                attrs:
                                                                                                  {
                                                                                                    scope:
                                                                                                      "col",
                                                                                                  },
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "值"
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _vm._l(
                                                                                      _vm.claimsExtendResult,
                                                                                      function (
                                                                                        pitem,
                                                                                        index
                                                                                      ) {
                                                                                        return _c(
                                                                                          "tbody",
                                                                                          {
                                                                                            key: index,
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "tr",
                                                                                              [
                                                                                                _c(
                                                                                                  "td",
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "险别"
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                                _c(
                                                                                                  "td",
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      _vm._s(
                                                                                                        pitem.riskTypeDisplay ||
                                                                                                          "-"
                                                                                                      )
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                            _c(
                                                                                              "tr",
                                                                                              [
                                                                                                _c(
                                                                                                  "td",
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "保险公司"
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                                _c(
                                                                                                  "td",
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      _vm._s(
                                                                                                        pitem.insurer ||
                                                                                                          "-"
                                                                                                      )
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                            _c(
                                                                                              "tr",
                                                                                              [
                                                                                                _c(
                                                                                                  "td",
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "保单号"
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                                _c(
                                                                                                  "td",
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      _vm._s(
                                                                                                        pitem.policyNo ||
                                                                                                          "-"
                                                                                                      )
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                            _c(
                                                                                              "tr",
                                                                                              [
                                                                                                _c(
                                                                                                  "td",
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "出险时间"
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                                _c(
                                                                                                  "td",
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      _vm._s(
                                                                                                        pitem.lossTime ||
                                                                                                          "-"
                                                                                                      )
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                            _c(
                                                                                              "tr",
                                                                                              [
                                                                                                _c(
                                                                                                  "td",
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "结案时间"
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                                _c(
                                                                                                  "td",
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      _vm._s(
                                                                                                        pitem.endCaseTime ||
                                                                                                          "-"
                                                                                                      )
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                            _c(
                                                                                              "tr",
                                                                                              [
                                                                                                _c(
                                                                                                  "td",
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "赔款总额(元)"
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                                _c(
                                                                                                  "td",
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      _vm._s(
                                                                                                        pitem.claimAmount ||
                                                                                                          "-"
                                                                                                      )
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                            _c(
                                                                                              "tr",
                                                                                              [
                                                                                                _c(
                                                                                                  "td",
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "其他"
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                                _c(
                                                                                                  "td",
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      _vm._s(
                                                                                                        _vm.otherPropertyJoin(
                                                                                                          pitem.otherPropertyList
                                                                                                        )
                                                                                                      )
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                            _c(
                                                                                              "tr",
                                                                                              [
                                                                                                _c(
                                                                                                  "td",
                                                                                                  {
                                                                                                    staticStyle:
                                                                                                      {
                                                                                                        background:
                                                                                                          "#f0f0f0",
                                                                                                      },
                                                                                                    attrs:
                                                                                                      {
                                                                                                        colspan:
                                                                                                          "2",
                                                                                                      },
                                                                                                  }
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        )
                                                                                      }
                                                                                    ),
                                                                                  ],
                                                                                  2
                                                                                ),
                                                                                _c(
                                                                                  "i",
                                                                                  {
                                                                                    staticClass:
                                                                                      "el-icon-warning-outline",
                                                                                    staticStyle:
                                                                                      {
                                                                                        "font-size":
                                                                                          "16px",
                                                                                        margin:
                                                                                          "0 5px",
                                                                                        cursor:
                                                                                          "pointer",
                                                                                      },
                                                                                    attrs:
                                                                                      {
                                                                                        slot: "reference",
                                                                                      },
                                                                                    slot: "reference",
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        !_vm._.isEmpty(
                                                          _vm.currentInsCrop
                                                            .proposalOtherPropertyVOList
                                                        )
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  "background-color":
                                                                    "#fff7e7",
                                                                  "margin-bottom":
                                                                    "10px",
                                                                  "border-radius":
                                                                    "5px",
                                                                  border:
                                                                    "1px solid #eee",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        "padding-left":
                                                                          "10px",
                                                                        color:
                                                                          "#a49270",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "附属信息："
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "el-descriptions",
                                                                  {
                                                                    attrs: {
                                                                      title: "",
                                                                      column: 3,
                                                                      border:
                                                                        "",
                                                                      labelStyle:
                                                                        {
                                                                          width:
                                                                            "300px",
                                                                        },
                                                                      contentStyle:
                                                                        {
                                                                          width:
                                                                            "300px",
                                                                        },
                                                                    },
                                                                  },
                                                                  _vm._l(
                                                                    _vm
                                                                      .currentInsCrop
                                                                      .proposalOtherPropertyVOList,
                                                                    function (
                                                                      item
                                                                    ) {
                                                                      return _c(
                                                                        "el-descriptions-item",
                                                                        {
                                                                          key: item.proposalOtherPropertyId,
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                item.propertyName,
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  item
                                                                                    .propertyValue
                                                                                    .length >
                                                                                    16
                                                                                    ? item.propertyValue.substring(
                                                                                        0,
                                                                                        16
                                                                                      ) +
                                                                                        "..."
                                                                                    : item.propertyValue
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          item
                                                                            .propertyValue
                                                                            .length >
                                                                          16
                                                                            ? _c(
                                                                                "el-tooltip",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      placement:
                                                                                        "top",
                                                                                      content:
                                                                                        item.propertyValue,
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticStyle:
                                                                                        {
                                                                                          color:
                                                                                            "blue",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "详情"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                        ],
                                                                        1
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.currentInsCrop
                                                  .proposalStatus !=
                                                  _vm.dictSource[
                                                    "INSURED_STATUS_OTHER"
                                                  ][0].dictCode &&
                                                _vm.currentInsCrop
                                                  .proposalStatus !=
                                                  _vm.dictSource[
                                                    "INSURED_STATUS_DROPDOWN"
                                                  ][2].dictCode &&
                                                _vm.currentInsCrop
                                                  .proposalStatus !=
                                                  _vm.dictSource[
                                                    "INSURED_STATUS_DROPDOWN"
                                                  ][12].dictCode
                                                  ? _c("div", [
                                                      _vm.currentInsCrop
                                                        .proposalStatus !=
                                                      _vm.dictSource[
                                                        "INSURED_STATUS_DROPDOWN"
                                                      ][3].dictCode
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                display: "flex",
                                                                "justify-content":
                                                                  "space-between",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticStyle: {
                                                                    display:
                                                                      "flex",
                                                                    "align-items":
                                                                      "center",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          "font-size":
                                                                            "18px",
                                                                          "font-weight":
                                                                            "bold",
                                                                        },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "总保费："
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              color:
                                                                                "red",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.currentInsCrop &&
                                                                                _vm
                                                                                  .currentInsCrop
                                                                                  .totalActualPremium
                                                                                ? _vm.currentInsCrop.totalActualPremium.toLocaleString()
                                                                                : 0
                                                                            ) +
                                                                              "元"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                              (_vm
                                                                .currentInsCrop
                                                                .proposalStatus ==
                                                                _vm.dictSource[
                                                                  "INSURED_STATUS_DROPDOWN"
                                                                ][4].dictCode ||
                                                                _vm
                                                                  .currentInsCrop
                                                                  .proposalStatus ==
                                                                  _vm
                                                                    .dictSource[
                                                                    "INSURED_STATUS_DROPDOWN"
                                                                  ][5]
                                                                    .dictCode ||
                                                                _vm
                                                                  .currentInsCrop
                                                                  .proposalStatus ==
                                                                  _vm
                                                                    .dictSource[
                                                                    "INSURED_STATUS_DROPDOWN"
                                                                  ][7]
                                                                    .dictCode) &&
                                                              !_vm._.isEmpty(
                                                                _vm.currentBsInsItem
                                                              ) &&
                                                              _vm.showPayBtn
                                                                ? _c(
                                                                    "div",
                                                                    [
                                                                      !_vm._.isEmpty(
                                                                        _vm
                                                                          .currentBsInsItem
                                                                          .proposalProductFactorUpdateVOList
                                                                      )
                                                                        ? _c(
                                                                            "el-popover",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  placement:
                                                                                    "bottom",
                                                                                  width:
                                                                                    "230",
                                                                                  trigger:
                                                                                    "hover",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.currentBsInsItem &&
                                                                                        _vm
                                                                                          .currentBsInsItem
                                                                                          .adjustDiscountFactor
                                                                                        ? "上次调整自主定价系数" +
                                                                                            _vm
                                                                                              .currentBsInsItem
                                                                                              .adjustDiscountFactor +
                                                                                            "未通过，当前自主定价系数为" +
                                                                                            _vm._.find(
                                                                                              _vm
                                                                                                .currentBsInsItem
                                                                                                .proposalProductFactorUpdateVOList,
                                                                                              function (
                                                                                                item
                                                                                              ) {
                                                                                                return (
                                                                                                  item.factorCode ==
                                                                                                    "02" ||
                                                                                                  item.factorCode ==
                                                                                                    "F02"
                                                                                                )
                                                                                              }
                                                                                            )
                                                                                              .factorRate
                                                                                        : 0
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _vm.currentBsInsItem &&
                                                                              _vm
                                                                                .currentBsInsItem
                                                                                .adjustDiscountFactor &&
                                                                              _vm
                                                                                .currentBsInsItem
                                                                                .adjustDiscountFactor !=
                                                                                _vm._.find(
                                                                                  _vm
                                                                                    .currentBsInsItem
                                                                                    .proposalProductFactorUpdateVOList,
                                                                                  function (
                                                                                    item
                                                                                  ) {
                                                                                    return (
                                                                                      item.factorCode ==
                                                                                        "02" ||
                                                                                      item.factorCode ==
                                                                                        "F02"
                                                                                    )
                                                                                  }
                                                                                )
                                                                                  .factorRate
                                                                                ? _c(
                                                                                    "i",
                                                                                    {
                                                                                      staticClass:
                                                                                        "el-icon-warning-outline",
                                                                                      staticStyle:
                                                                                        {
                                                                                          "font-size":
                                                                                            "16px",
                                                                                          margin:
                                                                                            "0 5px",
                                                                                          cursor:
                                                                                            "pointer",
                                                                                          color:
                                                                                            "red",
                                                                                        },
                                                                                      attrs:
                                                                                        {
                                                                                          slot: "reference",
                                                                                        },
                                                                                      slot: "reference",
                                                                                    }
                                                                                  )
                                                                                : _vm._e(),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm
                                                                        .currentInsCrop
                                                                        .insCorpCode &&
                                                                      _vm._.toLower(
                                                                        _vm
                                                                          .currentInsCrop
                                                                          .insCorpCode
                                                                      ) !=
                                                                        "picc" &&
                                                                      _vm.canModifyRate
                                                                        ? _c(
                                                                            "el-button",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  "margin-right":
                                                                                    "5px",
                                                                                },
                                                                              attrs:
                                                                                {
                                                                                  plain:
                                                                                    "",
                                                                                  type: "primary",
                                                                                  size: "mini",
                                                                                  icon: "el-icon-edit",
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    $event.stopPropagation()
                                                                                    return _vm.applyInsuranceBsDiscount(
                                                                                      $event
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " 申请商业险折扣 "
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            display: "flex",
                                                            "align-items":
                                                              "center",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                width: "65px",
                                                                color: "#999",
                                                                "font-size":
                                                                  "13px",
                                                              },
                                                            },
                                                            [_vm._v("报价明细")]
                                                          ),
                                                          _c("el-divider"),
                                                        ],
                                                        1
                                                      ),
                                                      _vm.currentInsCrop
                                                        .proposalStatus !=
                                                      _vm.dictSource[
                                                        "INSURED_STATUS_DROPDOWN"
                                                      ][3].dictCode
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                "font-size":
                                                                  "13px",
                                                              },
                                                            },
                                                            [
                                                              _vm.currentBzInsItem &&
                                                              _vm
                                                                .currentBzInsItem
                                                                .actualPremium &&
                                                              !_vm._.isEmpty(
                                                                _vm.currentBzInsItem
                                                              )
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          "line-height":
                                                                            "25px",
                                                                          "font-weight":
                                                                            "bold",
                                                                          "font-size":
                                                                            "14px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "交强险保费："
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              color:
                                                                                "red",
                                                                              "font-weight":
                                                                                "bold",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.currentBzInsItem &&
                                                                                _vm
                                                                                  .currentBzInsItem
                                                                                  .actualPremium
                                                                                ? _vm.currentBzInsItem.actualPremium.toLocaleString()
                                                                                : 0
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "元"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              _vm.currentBzInsItem &&
                                                              !_vm._.isEmpty(
                                                                _vm.currentBzInsItem
                                                              )
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          "line-height":
                                                                            "25px",
                                                                          "font-weight":
                                                                            "bold",
                                                                          "font-size":
                                                                            "14px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "车船税："
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              color:
                                                                                "red",
                                                                              "font-weight":
                                                                                "bold",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.currentBzInsItem &&
                                                                                _vm
                                                                                  .currentBzInsItem
                                                                                  .vehicleTaxPremium
                                                                                ? _vm.currentBzInsItem.vehicleTaxPremium.toLocaleString()
                                                                                : 0
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "元"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm.currentBzInsItem &&
                                                                      _vm
                                                                        .currentBzInsItem
                                                                        .proposalProductVehicleTaxVO
                                                                        ? _c(
                                                                            "span",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  color:
                                                                                    "#999",
                                                                                  "font-weight":
                                                                                    "bold",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " (当年应缴：" +
                                                                                  _vm._s(
                                                                                    _vm
                                                                                      .currentBzInsItem
                                                                                      .proposalProductVehicleTaxVO
                                                                                      .curYearTax
                                                                                  ) +
                                                                                  "元, 往年欠缴：" +
                                                                                  _vm._s(
                                                                                    _vm
                                                                                      .currentBzInsItem
                                                                                      .proposalProductVehicleTaxVO
                                                                                      .lastYearPay
                                                                                  ) +
                                                                                  "元, 滞纳金：" +
                                                                                  _vm._s(
                                                                                    _vm
                                                                                      .currentBzInsItem
                                                                                      .proposalProductVehicleTaxVO
                                                                                      .delayPayMoney
                                                                                  ) +
                                                                                  "元"
                                                                              ),
                                                                              _vm
                                                                                .currentBzInsItem
                                                                                .proposalProductVehicleTaxVO
                                                                                .taxType
                                                                                ? _c(
                                                                                    "span",
                                                                                    [
                                                                                      _vm._v(
                                                                                        ", 纳税类型：" +
                                                                                          _vm._s(
                                                                                            _vm.getTaxTypeName(
                                                                                              _vm
                                                                                                .currentBzInsItem
                                                                                                .proposalProductVehicleTaxVO
                                                                                                .taxType
                                                                                            )
                                                                                          )
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                              _vm._v(
                                                                                ") "
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              !_vm._.isEmpty(
                                                                _vm.currentBsInsItem
                                                              )
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          "line-height":
                                                                            "25px",
                                                                          "font-weight":
                                                                            "bold",
                                                                          "font-size":
                                                                            "14px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "商业险保费小计："
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              color:
                                                                                "red",
                                                                              "font-weight":
                                                                                "bold",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.currentBsInsItem &&
                                                                                _vm
                                                                                  .currentBsInsItem
                                                                                  .actualPremium
                                                                                ? _vm.currentBsInsItem.actualPremium.toLocaleString()
                                                                                : 0
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "元"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm.currentBsInsItem &&
                                                      _vm.currentBsInsItem
                                                        .coreProposalProductDutyVO &&
                                                      _vm.currentBsInsItem
                                                        .coreProposalProductDutyVO
                                                        .length > 0
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                "margin-top":
                                                                  "15px",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "InsuranceDetail",
                                                                {
                                                                  attrs: {
                                                                    currentInsCrop:
                                                                      _vm.currentInsCrop,
                                                                    title:
                                                                      "险别",
                                                                    "duty-data":
                                                                      _vm
                                                                        .currentBsInsItem
                                                                        .coreProposalProductDutyVO,
                                                                    showModifyButton:
                                                                      (_vm
                                                                        .currentInsCrop
                                                                        .proposalStatus ==
                                                                        _vm
                                                                          .dictSource[
                                                                          "INSURED_STATUS_DROPDOWN"
                                                                        ][4]
                                                                          .dictCode ||
                                                                        _vm
                                                                          .currentInsCrop
                                                                          .proposalStatus ==
                                                                          _vm
                                                                            .dictSource[
                                                                            "INSURED_STATUS_DROPDOWN"
                                                                          ][5]
                                                                            .dictCode ||
                                                                        _vm
                                                                          .currentInsCrop
                                                                          .proposalStatus ==
                                                                          _vm
                                                                            .dictSource[
                                                                            "INSURED_STATUS_DROPDOWN"
                                                                          ][7]
                                                                            .dictCode) &&
                                                                      _vm.showPayBtn &&
                                                                      _vm.canModifyAmount,
                                                                  },
                                                                  on: {
                                                                    modifyAmount:
                                                                      _vm.applyModifyDutyAmount,
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm.currentNonInsItem &&
                                                      _vm.currentNonInsItem
                                                        .proposalRiskAccidentVO &&
                                                      _vm.currentNonInsItem
                                                        .proposalRiskAccidentVO
                                                        .length > 0
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                "margin-top":
                                                                  "15px",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    "font-size":
                                                                      "14px",
                                                                    "font-weight":
                                                                      "bold",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "驾意险" +
                                                                      _vm._s(
                                                                        _vm
                                                                          .currentInsCrop
                                                                          .proposalStatus !=
                                                                          _vm
                                                                            .dictSource[
                                                                            "INSURED_STATUS_DROPDOWN"
                                                                          ][3]
                                                                            .dictCode
                                                                          ? "保费小计"
                                                                          : "投保方案"
                                                                      ) +
                                                                      "："
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm.currentInsCrop
                                                                .proposalStatus !=
                                                              _vm.dictSource[
                                                                "INSURED_STATUS_DROPDOWN"
                                                              ][3].dictCode
                                                                ? _c(
                                                                    "span",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          color:
                                                                            "red",
                                                                          "font-size":
                                                                            "14px",
                                                                          "font-weight":
                                                                            "bold",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.currentNonInsItem &&
                                                                            _vm
                                                                              .currentNonInsItem
                                                                              .actualPremium
                                                                            ? _vm
                                                                                .currentNonInsItem
                                                                                .actualPremium
                                                                            : 0
                                                                        )
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              _vm.currentInsCrop
                                                                .proposalStatus !=
                                                              _vm.dictSource[
                                                                "INSURED_STATUS_DROPDOWN"
                                                              ][3].dictCode
                                                                ? _c(
                                                                    "span",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          "font-size":
                                                                            "14px",
                                                                          "font-weight":
                                                                            "bold",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "元"
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              _c(
                                                                "NoVehicleInsuranceDetail",
                                                                {
                                                                  attrs: {
                                                                    title:
                                                                      "险别",
                                                                    proposalStatus:
                                                                      _vm
                                                                        .currentInsCrop
                                                                        .proposalStatus,
                                                                    insCorpCode:
                                                                      _vm
                                                                        .currentInsCrop
                                                                        .insCorpCode,
                                                                    currentNonInsDetail:
                                                                      _vm.currentNonInsItem,
                                                                    "list-data":
                                                                      _vm.currentNonInsItem &&
                                                                      _vm
                                                                        .currentNonInsItem
                                                                        .proposalRiskAccidentVO,
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm.currentInsCrop
                                                        .proposalStatus !=
                                                      _vm.dictSource[
                                                        "INSURED_STATUS_DROPDOWN"
                                                      ][3].dictCode
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                "margin-top":
                                                                  "15px",
                                                              },
                                                            },
                                                            [
                                                              _vm.currentBzInsItem ||
                                                              _vm.currentBsInsItem
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          "line-height":
                                                                            "35px",
                                                                          "font-weight":
                                                                            "700",
                                                                          "font-size":
                                                                            "14px",
                                                                          display:
                                                                            "flex",
                                                                          "align-items":
                                                                            "center",
                                                                        },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "65px",
                                                                              "font-weight":
                                                                                "700",
                                                                              "font-size":
                                                                                "14px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "特别约定"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "el-divider"
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _vm.currentBzInsItem
                                                                ? _c("div", [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "line-height":
                                                                              "38px",
                                                                            height:
                                                                              "38px",
                                                                            "font-weight":
                                                                              "600",
                                                                            display:
                                                                              "flex",
                                                                            "justify-content":
                                                                              "space-between",
                                                                          },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              "交强险特别约定"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          [
                                                                            _vm
                                                                              .currentInsCrop
                                                                              .proposalStatus ==
                                                                              "04" ||
                                                                            _vm
                                                                              .currentInsCrop
                                                                              .proposalStatus ==
                                                                              "06" ||
                                                                            _vm
                                                                              .currentInsCrop
                                                                              .proposalStatus ==
                                                                              "08"
                                                                              ? _c(
                                                                                  "el-button",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        size: "mini",
                                                                                        type: "primary",
                                                                                        plain:
                                                                                          "",
                                                                                        icon: "el-icon-edit",
                                                                                      },
                                                                                    on: {
                                                                                      click:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.addSpecial(
                                                                                            "bz"
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "添加交强险特别约定"
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm.bzSpecialpromiseUpdateVOList &&
                                                                    _vm
                                                                      .bzSpecialpromiseUpdateVOList
                                                                      .length >
                                                                      0
                                                                      ? _c(
                                                                          "div",
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                background:
                                                                                  "#f6f8ff",
                                                                                padding:
                                                                                  "0 10px",
                                                                              },
                                                                          },
                                                                          _vm._l(
                                                                            _vm.bzSpecialpromiseUpdateVOList,
                                                                            function (
                                                                              item,
                                                                              index
                                                                            ) {
                                                                              return _c(
                                                                                "div",
                                                                                {
                                                                                  key: index,
                                                                                  staticStyle:
                                                                                    {
                                                                                      background:
                                                                                        "#f6f8ff",
                                                                                      padding:
                                                                                        "10px",
                                                                                      "border-bottom":
                                                                                        "1px solid #dcdfe6",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  item.specialTermsCode ||
                                                                                  item.specialTermsName
                                                                                    ? _c(
                                                                                        "div",
                                                                                        [
                                                                                          _c(
                                                                                            "span",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "【"
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                          _c(
                                                                                            "span",
                                                                                            {
                                                                                              staticStyle:
                                                                                                {
                                                                                                  "font-weight":
                                                                                                    "700",
                                                                                                },
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                _vm._s(
                                                                                                  item.specialTermsCode
                                                                                                    ? item.specialTermsCode
                                                                                                    : ""
                                                                                                ) +
                                                                                                  _vm._s(
                                                                                                    item.specialTermsName
                                                                                                      ? item.specialTermsName
                                                                                                      : ""
                                                                                                  )
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                          _c(
                                                                                            "span",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "】"
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      )
                                                                                    : _vm._e(),
                                                                                  _c(
                                                                                    "div",
                                                                                    [
                                                                                      _c(
                                                                                        "span",
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              item.specialTermsText
                                                                                                ? item.specialTermsText
                                                                                                : ""
                                                                                            )
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            }
                                                                          ),
                                                                          0
                                                                        )
                                                                      : _c(
                                                                          "div",
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                background:
                                                                                  "#f6f8ff",
                                                                                padding:
                                                                                  "0 10px",
                                                                                "text-align":
                                                                                  "center",
                                                                                "line-height":
                                                                                  "60px",
                                                                                color:
                                                                                  "#666",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " 暂无特别约定 "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                  ])
                                                                : _vm._e(),
                                                              _vm.currentBsInsItem
                                                                ? _c("div", [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "line-height":
                                                                              "38px",
                                                                            height:
                                                                              "38px",
                                                                            "font-weight":
                                                                              "600",
                                                                            display:
                                                                              "flex",
                                                                            "justify-content":
                                                                              "space-between",
                                                                          },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              "商业险特别约定"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          [
                                                                            _vm
                                                                              .currentInsCrop
                                                                              .proposalStatus ==
                                                                              "04" ||
                                                                            _vm
                                                                              .currentInsCrop
                                                                              .proposalStatus ==
                                                                              "06" ||
                                                                            _vm
                                                                              .currentInsCrop
                                                                              .proposalStatus ==
                                                                              "08"
                                                                              ? _c(
                                                                                  "el-button",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        size: "mini",
                                                                                        type: "primary",
                                                                                        plain:
                                                                                          "",
                                                                                        icon: "el-icon-edit",
                                                                                      },
                                                                                    on: {
                                                                                      click:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.addSpecial(
                                                                                            "bs"
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "添加商业险特别约定"
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm.bsSpecialpromiseUpdateVOList &&
                                                                    _vm
                                                                      .bsSpecialpromiseUpdateVOList
                                                                      .length >
                                                                      0
                                                                      ? _c(
                                                                          "div",
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                background:
                                                                                  "#f6f8ff",
                                                                                padding:
                                                                                  "0 10px",
                                                                              },
                                                                          },
                                                                          _vm._l(
                                                                            _vm.bsSpecialpromiseUpdateVOList,
                                                                            function (
                                                                              item,
                                                                              index
                                                                            ) {
                                                                              return _c(
                                                                                "div",
                                                                                {
                                                                                  key: index,
                                                                                  staticStyle:
                                                                                    {
                                                                                      "border-bottom":
                                                                                        "1px solid #dcdfe6",
                                                                                      padding:
                                                                                        "10px 0px",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  item.specialTermsCode ||
                                                                                  item.specialTermsName
                                                                                    ? _c(
                                                                                        "div",
                                                                                        [
                                                                                          _c(
                                                                                            "span",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "【"
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                          _c(
                                                                                            "span",
                                                                                            {
                                                                                              staticStyle:
                                                                                                {
                                                                                                  "font-weight":
                                                                                                    "700",
                                                                                                },
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                _vm._s(
                                                                                                  item.specialTermsCode
                                                                                                    ? item.specialTermsCode
                                                                                                    : ""
                                                                                                ) +
                                                                                                  _vm._s(
                                                                                                    item.specialTermsName
                                                                                                      ? item.specialTermsName
                                                                                                      : ""
                                                                                                  )
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                          _c(
                                                                                            "span",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "】"
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      )
                                                                                    : _vm._e(),
                                                                                  _c(
                                                                                    "div",
                                                                                    [
                                                                                      _c(
                                                                                        "span",
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              item.specialTermsText
                                                                                                ? item.specialTermsText
                                                                                                : ""
                                                                                            )
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            }
                                                                          ),
                                                                          0
                                                                        )
                                                                      : _c(
                                                                          "div",
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                background:
                                                                                  "#f6f8ff",
                                                                                padding:
                                                                                  "0 10px",
                                                                                "text-align":
                                                                                  "center",
                                                                                "line-height":
                                                                                  "60px",
                                                                                color:
                                                                                  "#666",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " 暂无特别约定 "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                  ])
                                                                : _vm._e(),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm.currentInsCrop
                                                        .proposalStatus !=
                                                      _vm.dictSource[
                                                        "INSURED_STATUS_DROPDOWN"
                                                      ][3].dictCode
                                                        ? _c(
                                                            "div",
                                                            {
                                                              key: "extendDiv",
                                                              attrs: {
                                                                id: "extendDiv",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticStyle: {
                                                                    "line-height":
                                                                      "38px",
                                                                    height:
                                                                      "38px",
                                                                    "font-weight":
                                                                      "bold",
                                                                    display:
                                                                      "flex",
                                                                    "justify-content":
                                                                      "space-between",
                                                                  },
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "补充核保信息"
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                              _c(
                                                                "el-form",
                                                                {
                                                                  ref: "extendInfo",
                                                                  staticStyle: {
                                                                    background:
                                                                      "#f6f8ff",
                                                                    padding:
                                                                      "0 10px",
                                                                  },
                                                                  attrs: {
                                                                    model:
                                                                      _vm.extendInfo,
                                                                    rules:
                                                                      _vm.extendInfoRules,
                                                                    "label-width":
                                                                      "130px",
                                                                  },
                                                                },
                                                                [
                                                                  _vm.currentFlowNode &&
                                                                  _vm
                                                                    .currentFlowNode
                                                                    .code ==
                                                                    "SzShuangLu" &&
                                                                  _vm
                                                                    .currentFlowNode
                                                                    .message
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              padding:
                                                                                "10px",
                                                                              "font-size":
                                                                                "14px",
                                                                              color:
                                                                                "red",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "i",
                                                                            {
                                                                              staticClass:
                                                                                "el-icon-warning-outline",
                                                                              staticStyle:
                                                                                {
                                                                                  "font-size":
                                                                                    "14px",
                                                                                  margin:
                                                                                    "0 5px",
                                                                                  cursor:
                                                                                    "pointer",
                                                                                },
                                                                              attrs:
                                                                                {
                                                                                  slot: "reference",
                                                                                },
                                                                              slot: "reference",
                                                                            }
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "深圳地区需要进行双录，请输入双录人信息后，点击“重新核保” "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _c(
                                                                    "el-row",
                                                                    {
                                                                      attrs: {
                                                                        gutter: 0,
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm
                                                                        .currentInsCrop
                                                                        .insCityCode ==
                                                                        "440300" &&
                                                                      _vm.currentFlowNode &&
                                                                      _vm
                                                                        .currentFlowNode
                                                                        .code ==
                                                                        "SzShuangLu"
                                                                        ? _c(
                                                                            "el-col",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  span: 8,
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "el-form-item",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      label:
                                                                                        "双录人姓名：",
                                                                                      prop: "dualRecordSalesName",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "el-input",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          maxlength:
                                                                                            "40",
                                                                                          placeholder:
                                                                                            "请输入双录人姓名",
                                                                                          clearable:
                                                                                            "",
                                                                                        },
                                                                                      model:
                                                                                        {
                                                                                          value:
                                                                                            _vm
                                                                                              .extendInfo
                                                                                              .dualRecordSalesName,
                                                                                          callback:
                                                                                            function (
                                                                                              $$v
                                                                                            ) {
                                                                                              _vm.$set(
                                                                                                _vm.extendInfo,
                                                                                                "dualRecordSalesName",
                                                                                                $$v
                                                                                              )
                                                                                            },
                                                                                          expression:
                                                                                            "extendInfo.dualRecordSalesName",
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm
                                                                        .currentInsCrop
                                                                        .insCityCode ==
                                                                        "440300" &&
                                                                      _vm.currentFlowNode &&
                                                                      _vm
                                                                        .currentFlowNode
                                                                        .code ==
                                                                        "SzShuangLu"
                                                                        ? _c(
                                                                            "el-col",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  span: 8,
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "el-form-item",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      label:
                                                                                        "双录人证件号：",
                                                                                      prop: "dualRecordSalesIdCard",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "el-input",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          maxlength:
                                                                                            "18",
                                                                                          placeholder:
                                                                                            "请输入双录人证件号",
                                                                                          clearable:
                                                                                            "",
                                                                                        },
                                                                                      on: {
                                                                                        input:
                                                                                          _vm.idCardNoCheck,
                                                                                      },
                                                                                      model:
                                                                                        {
                                                                                          value:
                                                                                            _vm
                                                                                              .extendInfo
                                                                                              .dualRecordSalesIdCard,
                                                                                          callback:
                                                                                            function (
                                                                                              $$v
                                                                                            ) {
                                                                                              _vm.$set(
                                                                                                _vm.extendInfo,
                                                                                                "dualRecordSalesIdCard",
                                                                                                $$v
                                                                                              )
                                                                                            },
                                                                                          expression:
                                                                                            "extendInfo.dualRecordSalesIdCard",
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm
                                                                        .currentInsCrop
                                                                        .insCityCode ==
                                                                        "440300" &&
                                                                      _vm.extendInfo &&
                                                                      _vm
                                                                        .extendInfo
                                                                        .dualRecordSalesName &&
                                                                      !_vm.currentFlowNode
                                                                        ? _c(
                                                                            "el-col",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  span: 8,
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "el-form-item",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      label:
                                                                                        "双录人姓名：",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "el-input",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          maxlength:
                                                                                            "40",
                                                                                          disabled:
                                                                                            "",
                                                                                          placeholder:
                                                                                            "请输入双录人姓名",
                                                                                          clearable:
                                                                                            "",
                                                                                        },
                                                                                      model:
                                                                                        {
                                                                                          value:
                                                                                            _vm
                                                                                              .extendInfo
                                                                                              .dualRecordSalesName,
                                                                                          callback:
                                                                                            function (
                                                                                              $$v
                                                                                            ) {
                                                                                              _vm.$set(
                                                                                                _vm.extendInfo,
                                                                                                "dualRecordSalesName",
                                                                                                $$v
                                                                                              )
                                                                                            },
                                                                                          expression:
                                                                                            "extendInfo.dualRecordSalesName",
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm
                                                                        .currentInsCrop
                                                                        .insCityCode ==
                                                                        "440300" &&
                                                                      _vm.extendInfo &&
                                                                      _vm
                                                                        .extendInfo
                                                                        .dualRecordSalesIdCard &&
                                                                      !_vm.currentFlowNode
                                                                        ? _c(
                                                                            "el-col",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  span: 8,
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "el-form-item",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      label:
                                                                                        "双录人证件号：",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "el-input",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          maxlength:
                                                                                            "18",
                                                                                          disabled:
                                                                                            "",
                                                                                          placeholder:
                                                                                            "请输入双录人证件号",
                                                                                          clearable:
                                                                                            "",
                                                                                        },
                                                                                      on: {
                                                                                        input:
                                                                                          _vm.idCardNoCheck,
                                                                                      },
                                                                                      model:
                                                                                        {
                                                                                          value:
                                                                                            _vm
                                                                                              .extendInfo
                                                                                              .dualRecordSalesIdCard,
                                                                                          callback:
                                                                                            function (
                                                                                              $$v
                                                                                            ) {
                                                                                              _vm.$set(
                                                                                                _vm.extendInfo,
                                                                                                "dualRecordSalesIdCard",
                                                                                                $$v
                                                                                              )
                                                                                            },
                                                                                          expression:
                                                                                            "extendInfo.dualRecordSalesIdCard",
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm.currentInsCrop &&
                                                                      _vm._.toLower(
                                                                        _vm
                                                                          .currentInsCrop
                                                                          .insCorpCode
                                                                      ) ==
                                                                        "cpic"
                                                                        ? _c(
                                                                            "el-col",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  span: 8,
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "el-form-item",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      label:
                                                                                        "验车码：",
                                                                                      prop: "checkCarNumber",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "el-input",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          maxlength:
                                                                                            "40",
                                                                                          placeholder:
                                                                                            "请输入验车码",
                                                                                          clearable:
                                                                                            "",
                                                                                          disabled:
                                                                                            !(
                                                                                              _vm
                                                                                                .currentInsCrop
                                                                                                .proposalStatus ==
                                                                                                _vm
                                                                                                  .dictSource[
                                                                                                  "INSURED_STATUS_DROPDOWN"
                                                                                                ][4]
                                                                                                  .dictCode &&
                                                                                              _vm.showPayBtn
                                                                                            ),
                                                                                        },
                                                                                      model:
                                                                                        {
                                                                                          value:
                                                                                            _vm
                                                                                              .extendInfo
                                                                                              .checkCarNumber,
                                                                                          callback:
                                                                                            function (
                                                                                              $$v
                                                                                            ) {
                                                                                              _vm.$set(
                                                                                                _vm.extendInfo,
                                                                                                "checkCarNumber",
                                                                                                $$v
                                                                                              )
                                                                                            },
                                                                                          expression:
                                                                                            "extendInfo.checkCarNumber",
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm.currentInsCrop &&
                                                                      _vm._.toLower(
                                                                        _vm
                                                                          .currentInsCrop
                                                                          .insCorpCode
                                                                      ) ==
                                                                        "picc" &&
                                                                      _vm.form
                                                                        .quoteVehicle
                                                                        .transferFlag
                                                                        ? _c(
                                                                            "el-col",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  span: 8,
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "el-form-item",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      label:
                                                                                        "二手车发票金额：",
                                                                                      prop: "userdCarInvoiceAmount",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "el-input",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          maxlength:
                                                                                            "40",
                                                                                          placeholder:
                                                                                            "请输入二手车发票金额",
                                                                                          clearable:
                                                                                            "",
                                                                                          disabled:
                                                                                            !(
                                                                                              _vm
                                                                                                .currentInsCrop
                                                                                                .proposalStatus ==
                                                                                                _vm
                                                                                                  .dictSource[
                                                                                                  "INSURED_STATUS_DROPDOWN"
                                                                                                ][4]
                                                                                                  .dictCode &&
                                                                                              _vm.showPayBtn
                                                                                            ),
                                                                                        },
                                                                                      on: {
                                                                                        input:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            _vm.extendInfo.userdCarInvoiceAmount =
                                                                                              _vm.onInput(
                                                                                                _vm
                                                                                                  .extendInfo
                                                                                                  .userdCarInvoiceAmount,
                                                                                                0,
                                                                                                10000000,
                                                                                                2
                                                                                              )
                                                                                          },
                                                                                      },
                                                                                      nativeOn:
                                                                                        {
                                                                                          keyup:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              _vm.extendInfo.userdCarInvoiceAmount =
                                                                                                _vm.onInput(
                                                                                                  _vm
                                                                                                    .extendInfo
                                                                                                    .userdCarInvoiceAmount,
                                                                                                  0,
                                                                                                  10000000,
                                                                                                  2
                                                                                                )
                                                                                            },
                                                                                        },
                                                                                      model:
                                                                                        {
                                                                                          value:
                                                                                            _vm
                                                                                              .extendInfo
                                                                                              .userdCarInvoiceAmount,
                                                                                          callback:
                                                                                            function (
                                                                                              $$v
                                                                                            ) {
                                                                                              _vm.$set(
                                                                                                _vm.extendInfo,
                                                                                                "userdCarInvoiceAmount",
                                                                                                $$v
                                                                                              )
                                                                                            },
                                                                                          expression:
                                                                                            "extendInfo.userdCarInvoiceAmount",
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm.currentInsCrop &&
                                                                      _vm._.toLower(
                                                                        _vm
                                                                          .currentInsCrop
                                                                          .insCorpCode
                                                                      ) ==
                                                                        "picc"
                                                                        ? _c(
                                                                            "el-col",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  span: 24,
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "el-form-item",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      label:
                                                                                        "承保意见：",
                                                                                      prop: "underwritingOpinion",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "el-input",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          maxlength:
                                                                                            "200",
                                                                                          placeholder:
                                                                                            "请输入承保意见",
                                                                                          clearable:
                                                                                            "",
                                                                                          type: "textarea",
                                                                                          autosize:
                                                                                            {
                                                                                              minRows: 4,
                                                                                              maxRows: 4,
                                                                                            },
                                                                                          resize:
                                                                                            "none",
                                                                                          disabled:
                                                                                            !(
                                                                                              _vm
                                                                                                .currentInsCrop
                                                                                                .proposalStatus ==
                                                                                                _vm
                                                                                                  .dictSource[
                                                                                                  "INSURED_STATUS_DROPDOWN"
                                                                                                ][4]
                                                                                                  .dictCode &&
                                                                                              _vm.showPayBtn
                                                                                            ),
                                                                                        },
                                                                                      model:
                                                                                        {
                                                                                          value:
                                                                                            _vm
                                                                                              .extendInfo
                                                                                              .underwritingOpinion,
                                                                                          callback:
                                                                                            function (
                                                                                              $$v
                                                                                            ) {
                                                                                              _vm.$set(
                                                                                                _vm.extendInfo,
                                                                                                "underwritingOpinion",
                                                                                                $$v
                                                                                              )
                                                                                            },
                                                                                          expression:
                                                                                            "extendInfo.underwritingOpinion",
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm.currentInsCrop &&
                                                                      [
                                                                        "paic",
                                                                        "picc",
                                                                      ].includes(
                                                                        _vm._.toLower(
                                                                          _vm
                                                                            .currentInsCrop
                                                                            .insCorpCode
                                                                        )
                                                                      ) &&
                                                                      _vm
                                                                        .currentInsCrop
                                                                        .insCityCode !=
                                                                        "110100"
                                                                        ? _c(
                                                                            "el-col",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  span: 24,
                                                                                  disabled:
                                                                                    !(
                                                                                      _vm
                                                                                        .currentInsCrop
                                                                                        .proposalStatus ==
                                                                                        _vm
                                                                                          .dictSource[
                                                                                          "INSURED_STATUS_DROPDOWN"
                                                                                        ][4]
                                                                                          .dictCode &&
                                                                                      _vm.showPayBtn
                                                                                    ),
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "el-form-item",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      label:
                                                                                        "投保单类型：",
                                                                                      prop: "isElectronicApplyPolicy",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "el-radio-group",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          disabled:
                                                                                            !(
                                                                                              _vm
                                                                                                .currentInsCrop
                                                                                                .proposalStatus ==
                                                                                                _vm
                                                                                                  .dictSource[
                                                                                                  "INSURED_STATUS_DROPDOWN"
                                                                                                ][4]
                                                                                                  .dictCode &&
                                                                                              _vm.showPayBtn
                                                                                            ),
                                                                                        },
                                                                                      model:
                                                                                        {
                                                                                          value:
                                                                                            _vm
                                                                                              .extendInfo
                                                                                              .isElectronicApplyPolicy,
                                                                                          callback:
                                                                                            function (
                                                                                              $$v
                                                                                            ) {
                                                                                              _vm.$set(
                                                                                                _vm.extendInfo,
                                                                                                "isElectronicApplyPolicy",
                                                                                                $$v
                                                                                              )
                                                                                            },
                                                                                          expression:
                                                                                            "extendInfo.isElectronicApplyPolicy",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "el-radio",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              label: true,
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "电子投保单"
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                      _c(
                                                                                        "el-radio",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              label: false,
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "纸质投保单"
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e(),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm.currentInsCrop
                                                        .proposalStatus !=
                                                      _vm.dictSource[
                                                        "INSURED_STATUS_DROPDOWN"
                                                      ][3].dictCode
                                                        ? _c("div", [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "activeDom",
                                                              },
                                                              [
                                                                _c(
                                                                  "el-form",
                                                                  {
                                                                    ref: "activeForm",
                                                                    attrs: {
                                                                      model:
                                                                        _vm.activeForm,
                                                                      rules:
                                                                        _vm.activeFormRule,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-form-item",
                                                                      {
                                                                        ref: "joinCouponAct",
                                                                        attrs: {
                                                                          label:
                                                                            "是否参加厂家政策：",
                                                                          prop: "joinCouponAct",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "el-radio-group",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                disabled:
                                                                                  !(
                                                                                    _vm
                                                                                      .currentInsCrop
                                                                                      .proposalStatus ==
                                                                                      _vm
                                                                                        .dictSource[
                                                                                        "INSURED_STATUS_DROPDOWN"
                                                                                      ][4]
                                                                                        .dictCode &&
                                                                                    _vm.showPayBtn
                                                                                  ),
                                                                              },
                                                                            on: {
                                                                              change:
                                                                                _vm.setJoinCouponAct,
                                                                            },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm
                                                                                    .activeForm
                                                                                    .joinCouponAct,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      _vm.activeForm,
                                                                                      "joinCouponAct",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "activeForm.joinCouponAct",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "el-radio",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    label: true,
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "是 "
                                                                                ),
                                                                                _c(
                                                                                  "span",
                                                                                  {
                                                                                    directives:
                                                                                      [
                                                                                        {
                                                                                          name: "show",
                                                                                          rawName:
                                                                                            "v-show",
                                                                                          value:
                                                                                            _vm
                                                                                              .activeForm
                                                                                              .joinCouponAct,
                                                                                          expression:
                                                                                            "activeForm.joinCouponAct",
                                                                                        },
                                                                                      ],
                                                                                    staticStyle:
                                                                                      {
                                                                                        color:
                                                                                          "#FF0000",
                                                                                        "font-size":
                                                                                          "13px",
                                                                                        "font-weight":
                                                                                          "400",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "(请勾选下方表格所需政策)"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "el-radio",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    label: false,
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "否"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm.activeForm
                                                                  .joinCouponAct
                                                                  ? _c(
                                                                      "div",
                                                                      [
                                                                        _c(
                                                                          "el-form",
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                padding:
                                                                                  "0 10px",
                                                                              },
                                                                            attrs:
                                                                              {
                                                                                "label-width":
                                                                                  "130px",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "el-form-item",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    label:
                                                                                      "厂牌型号：",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticStyle:
                                                                                      {
                                                                                        display:
                                                                                          "flex",
                                                                                        "align-items":
                                                                                          "center",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "el-input",
                                                                                      {
                                                                                        staticStyle:
                                                                                          {
                                                                                            width:
                                                                                              "25%",
                                                                                          },
                                                                                        attrs:
                                                                                          {
                                                                                            disabled:
                                                                                              "",
                                                                                          },
                                                                                        model:
                                                                                          {
                                                                                            value:
                                                                                              _vm.carTypeVal,
                                                                                            callback:
                                                                                              function (
                                                                                                $$v
                                                                                              ) {
                                                                                                _vm.carTypeVal =
                                                                                                  $$v
                                                                                              },
                                                                                            expression:
                                                                                              "carTypeVal",
                                                                                          },
                                                                                      }
                                                                                    ),
                                                                                    _c(
                                                                                      "el-button",
                                                                                      {
                                                                                        staticStyle:
                                                                                          {
                                                                                            "margin-left":
                                                                                              "5px",
                                                                                          },
                                                                                        attrs:
                                                                                          {
                                                                                            disabled:
                                                                                              !(
                                                                                                _vm
                                                                                                  .currentInsCrop
                                                                                                  .proposalStatus ==
                                                                                                  _vm
                                                                                                    .dictSource[
                                                                                                    "INSURED_STATUS_DROPDOWN"
                                                                                                  ][4]
                                                                                                    .dictCode &&
                                                                                                _vm.showPayBtn
                                                                                              ),
                                                                                            loading:
                                                                                              _vm.vehicleSalesLoading,
                                                                                            plain:
                                                                                              "",
                                                                                            type: "primary",
                                                                                            size: "mini",
                                                                                          },
                                                                                        on: {
                                                                                          click:
                                                                                            _vm.getVehicleSalesByVin,
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "获取车销"
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                                _vm.activeForm
                                                                  .joinCouponAct
                                                                  ? _c(
                                                                      "el-table",
                                                                      {
                                                                        ref: "multipleActiveTable",
                                                                        staticClass:
                                                                          "expandEable",
                                                                        staticStyle:
                                                                          {
                                                                            width:
                                                                              "100%",
                                                                          },
                                                                        attrs: {
                                                                          size: "mini",
                                                                          border:
                                                                            "",
                                                                          data: _vm.activeTableData,
                                                                          "header-cell-style":
                                                                            {
                                                                              background:
                                                                                "#F7F7F7",
                                                                            },
                                                                          "highlight-current-row":
                                                                            "",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "el-table-column",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                type: "expand",
                                                                                width:
                                                                                  "1",
                                                                              },
                                                                            scopedSlots:
                                                                              _vm._u(
                                                                                [
                                                                                  {
                                                                                    key: "default",
                                                                                    fn: function (
                                                                                      props
                                                                                    ) {
                                                                                      return [
                                                                                        _c(
                                                                                          "el-table",
                                                                                          {
                                                                                            staticStyle:
                                                                                              {
                                                                                                width:
                                                                                                  "100%",
                                                                                              },
                                                                                            attrs:
                                                                                              {
                                                                                                "header-cell-style":
                                                                                                  {
                                                                                                    background:
                                                                                                      "#F7F7F7",
                                                                                                  },
                                                                                                data: props
                                                                                                  .row
                                                                                                  .couponRuleTableData,
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "el-table-column",
                                                                                              {
                                                                                                attrs:
                                                                                                  {
                                                                                                    prop: "couponActivityRuleName",
                                                                                                    label:
                                                                                                      "规则名称",
                                                                                                    "show-overflow-tooltip":
                                                                                                      "",
                                                                                                  },
                                                                                              }
                                                                                            ),
                                                                                            _c(
                                                                                              "el-table-column",
                                                                                              {
                                                                                                attrs:
                                                                                                  {
                                                                                                    prop: "couponActivityRuleCode",
                                                                                                    width:
                                                                                                      "120px",
                                                                                                    align:
                                                                                                      "center",
                                                                                                    label:
                                                                                                      "规则编码",
                                                                                                    "show-overflow-tooltip":
                                                                                                      "",
                                                                                                  },
                                                                                              }
                                                                                            ),
                                                                                            _c(
                                                                                              "el-table-column",
                                                                                              {
                                                                                                attrs:
                                                                                                  {
                                                                                                    prop: "couponActivityRuleAmount",
                                                                                                    width:
                                                                                                      "120px",
                                                                                                    align:
                                                                                                      "center",
                                                                                                    label:
                                                                                                      "政策权益(元)",
                                                                                                    "show-overflow-tooltip":
                                                                                                      "",
                                                                                                  },
                                                                                              }
                                                                                            ),
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                      ]
                                                                                    },
                                                                                  },
                                                                                ],
                                                                                null,
                                                                                false,
                                                                                3812791287
                                                                              ),
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "el-table-column",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                width:
                                                                                  "55",
                                                                                align:
                                                                                  "center",
                                                                              },
                                                                            scopedSlots:
                                                                              _vm._u(
                                                                                [
                                                                                  {
                                                                                    key: "default",
                                                                                    fn: function (
                                                                                      scope
                                                                                    ) {
                                                                                      return [
                                                                                        _c(
                                                                                          "el-radio",
                                                                                          {
                                                                                            staticClass:
                                                                                              "activeFormClass",
                                                                                            attrs:
                                                                                              {
                                                                                                disabled:
                                                                                                  !(
                                                                                                    _vm
                                                                                                      .currentInsCrop
                                                                                                      .proposalStatus ==
                                                                                                      _vm
                                                                                                        .dictSource[
                                                                                                        "INSURED_STATUS_DROPDOWN"
                                                                                                      ][4]
                                                                                                        .dictCode &&
                                                                                                    _vm.showPayBtn
                                                                                                  ),
                                                                                                label: true,
                                                                                              },
                                                                                            on: {
                                                                                              change:
                                                                                                function (
                                                                                                  $event
                                                                                                ) {
                                                                                                  return _vm.selectActivity(
                                                                                                    $event,
                                                                                                    scope.$index
                                                                                                  )
                                                                                                },
                                                                                            },
                                                                                            model:
                                                                                              {
                                                                                                value:
                                                                                                  scope
                                                                                                    .row
                                                                                                    .active,
                                                                                                callback:
                                                                                                  function (
                                                                                                    $$v
                                                                                                  ) {
                                                                                                    _vm.$set(
                                                                                                      scope.row,
                                                                                                      "active",
                                                                                                      $$v
                                                                                                    )
                                                                                                  },
                                                                                                expression:
                                                                                                  "scope.row.active",
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                      ]
                                                                                    },
                                                                                  },
                                                                                ],
                                                                                null,
                                                                                false,
                                                                                3335413086
                                                                              ),
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "el-table-column",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                prop: "couponActivityName",
                                                                                label:
                                                                                  "政策名称",
                                                                                "show-overflow-tooltip":
                                                                                  "",
                                                                              },
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "el-table-column",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                prop: "couponActivityTargetDisplay",
                                                                                label:
                                                                                  "适用范围",
                                                                                "show-overflow-tooltip":
                                                                                  "",
                                                                              },
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "el-table-column",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                label:
                                                                                  "开始时间",
                                                                                "show-overflow-tooltip":
                                                                                  "",
                                                                                width:
                                                                                  "100px",
                                                                              },
                                                                            scopedSlots:
                                                                              _vm._u(
                                                                                [
                                                                                  {
                                                                                    key: "default",
                                                                                    fn: function (
                                                                                      scope
                                                                                    ) {
                                                                                      return [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              scope
                                                                                                .row
                                                                                                .couponActivityStartTime &&
                                                                                                scope.row.couponActivityStartTime.split(
                                                                                                  " "
                                                                                                )[0]
                                                                                            ) +
                                                                                            " "
                                                                                        ),
                                                                                      ]
                                                                                    },
                                                                                  },
                                                                                ],
                                                                                null,
                                                                                false,
                                                                                3162372534
                                                                              ),
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "el-table-column",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                label:
                                                                                  "结束时间",
                                                                                "show-overflow-tooltip":
                                                                                  "",
                                                                                width:
                                                                                  "100px",
                                                                              },
                                                                            scopedSlots:
                                                                              _vm._u(
                                                                                [
                                                                                  {
                                                                                    key: "default",
                                                                                    fn: function (
                                                                                      scope
                                                                                    ) {
                                                                                      return [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              scope
                                                                                                .row
                                                                                                .couponActivityEndTime &&
                                                                                                scope.row.couponActivityEndTime.split(
                                                                                                  " "
                                                                                                )[0]
                                                                                            ) +
                                                                                            " "
                                                                                        ),
                                                                                      ]
                                                                                    },
                                                                                  },
                                                                                ],
                                                                                null,
                                                                                false,
                                                                                2433448854
                                                                              ),
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "el-table-column",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                width:
                                                                                  "100",
                                                                                align:
                                                                                  "center",
                                                                                label:
                                                                                  "操作",
                                                                              },
                                                                            scopedSlots:
                                                                              _vm._u(
                                                                                [
                                                                                  {
                                                                                    key: "default",
                                                                                    fn: function (
                                                                                      scope
                                                                                    ) {
                                                                                      return [
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            on: {
                                                                                              click:
                                                                                                function (
                                                                                                  $event
                                                                                                ) {
                                                                                                  return _vm.collapseActivvityDetail(
                                                                                                    scope.row,
                                                                                                    scope.$index
                                                                                                  )
                                                                                                },
                                                                                            },
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              " 政策详情"
                                                                                            ),
                                                                                            _c(
                                                                                              "el-link",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "expanded-button",
                                                                                                attrs:
                                                                                                  {
                                                                                                    type: "primary",
                                                                                                    size: "mini",
                                                                                                    underline: false,
                                                                                                    icon: scope
                                                                                                      .row
                                                                                                      .isCollapse
                                                                                                      ? "el-icon-arrow-up"
                                                                                                      : "el-icon-arrow-down",
                                                                                                  },
                                                                                              }
                                                                                            ),
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                      ]
                                                                                    },
                                                                                  },
                                                                                ],
                                                                                null,
                                                                                false,
                                                                                968622049
                                                                              ),
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            ),
                                                          ])
                                                        : _vm._e(),
                                                    ])
                                                  : _vm._e(),
                                              ])
                                            : _vm._e(),
                                          _vm.loading
                                            ? _c("div", {
                                                staticStyle: {
                                                  height: "200px",
                                                  width: "100%",
                                                },
                                              })
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-dialog",
                        {
                          class: "car-insurance-ui-dialog",
                          attrs: {
                            visible: _vm.dialogRenewalInsurance,
                            "close-on-click-modal": false,
                            width: "500px",
                            "before-close": _vm.handleClose,
                            "modal-append-to-body": false,
                          },
                          on: {
                            "update:visible": function ($event) {
                              _vm.dialogRenewalInsurance = $event
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "dialog-footer",
                              attrs: { slot: "title" },
                              slot: "title",
                            },
                            [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "14px" } },
                                [_vm._v("车辆续保信息查询")]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "special_row_content",
                                  attrs: { label: "证件号" },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { maxlength: "18", clearable: "" },
                                    model: {
                                      value: _vm.form.versionNo,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "versionNo",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "form.versionNo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "dialog-footer",
                              staticStyle: {
                                display: "flex",
                                "justify-content": "center",
                              },
                              attrs: { slot: "footer" },
                              slot: "footer",
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      _vm.dialogRenewalInsurance = false
                                    },
                                  },
                                },
                                [_vm._v("确 定")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      _vm.dialogRenewalInsurance = false
                                    },
                                  },
                                },
                                [_vm._v("取 消")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "el-dialog",
                        {
                          class: "car-insurance-ui-dialog",
                          attrs: {
                            visible: _vm.dialogInsuranceMsg,
                            width: "560px",
                            "before-close": _vm.handleProposalClose,
                            "modal-append-to-body": false,
                          },
                          on: {
                            "update:visible": function ($event) {
                              _vm.dialogInsuranceMsg = $event
                            },
                          },
                        },
                        [
                          _c("screenshot", {
                            attrs: { data: _vm.repetitionInsure },
                            on: {
                              closeProposalS: _vm.closeProposalS,
                              exportTime: _vm.exportTime,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-dialog",
                        {
                          class: "car-insurance-ui-dialog",
                          attrs: {
                            visible: _vm.dialogInsuranceDataReplenish,
                            "close-on-click-modal": false,
                            width: "540px",
                            "before-close":
                              _vm.handleCloseInsuranceDataReplenish,
                            "modal-append-to-body": false,
                          },
                          on: {
                            "update:visible": function ($event) {
                              _vm.dialogInsuranceDataReplenish = $event
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "title",
                              attrs: { slot: "title" },
                              slot: "title",
                            },
                            [
                              _c("FormLabel", {
                                attrs: { "form-label": "补充投保资料" },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticStyle: { padding: "0 25px 5px" } },
                            [
                              _c(
                                "el-form",
                                {
                                  ref: "invoiceForm",
                                  attrs: {
                                    model: _vm.invoiceForm,
                                    rules: _vm.invoiceFormRules,
                                  },
                                },
                                [
                                  _c(
                                    "el-row",
                                    {
                                      staticStyle: { height: "46px" },
                                      attrs: { gutter: 0 },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticClass: "special_row_content",
                                          attrs: {
                                            label: "开票类型：",
                                            "label-width": "130px",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio-group",
                                            {
                                              model: {
                                                value:
                                                  _vm.invoiceForm.invoiceDTO
                                                    .invoiceType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.invoiceForm.invoiceDTO,
                                                    "invoiceType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "invoiceForm.invoiceDTO.invoiceType",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-radio",
                                                { attrs: { label: "5" } },
                                                [_vm._v("普通电子发票")]
                                              ),
                                              _c(
                                                "el-radio",
                                                { attrs: { label: "2" } },
                                                [_vm._v("增值税专用发票")]
                                              ),
                                              _vm._.toLower(
                                                _vm.currentInsCrop.insCorpCode
                                              ) == "paic"
                                                ? _c(
                                                    "el-radio",
                                                    { attrs: { label: "3" } },
                                                    [_vm._v("无需打印")]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.invoiceForm.invoiceDTO.invoiceType != "3"
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "background-color": "#f9f9f9",
                                            padding: "15px 0 5px",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-row",
                                            { attrs: { gutter: 0 } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                    "min-height": "30px",
                                                    "font-size": "13px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        width: "130px",
                                                        "text-align": "right",
                                                        "padding-right": "12px",
                                                      },
                                                    },
                                                    [_vm._v("纳税人名称：")]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        flex: "1",
                                                        display: "flex",
                                                        "padding-right": "30px",
                                                        "line-height": "1.4",
                                                      },
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.invoiceForm
                                                              .invoiceDTO
                                                              .taxpayerName
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                    "min-height": "30px",
                                                    "font-size": "13px",
                                                    "margin-top": "5px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        width: "130px",
                                                        "text-align": "right",
                                                        "padding-right": "12px",
                                                      },
                                                    },
                                                    [_vm._v("纳税人识别号：")]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        flex: "1",
                                                        display: "flex",
                                                        "padding-right": "30px",
                                                        "line-height": "1.4",
                                                      },
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.invoiceForm
                                                              .invoiceDTO
                                                              .taxpayerCertificateNo
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm.invoiceForm.invoiceDTO
                                                .invoiceType == "2"
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "el-col",
                                                        { attrs: { span: 24 } },
                                                        [
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              staticClass:
                                                                "special_row_content invoice_height",
                                                              attrs: {
                                                                label:
                                                                  "联系电话：",
                                                                "label-width":
                                                                  "130px",
                                                                prop:
                                                                  "" +
                                                                  (_vm._.toLower(
                                                                    _vm
                                                                      .currentInsCrop
                                                                      .insCorpCode
                                                                  ) != "tmnf"
                                                                    ? "invoiceDTO.taxpayerPhone"
                                                                    : ""),
                                                              },
                                                            },
                                                            [
                                                              _c("el-input", {
                                                                staticStyle: {
                                                                  "margin-right":
                                                                    "10px",
                                                                  "max-width":
                                                                    "320px",
                                                                },
                                                                attrs: {
                                                                  type: "number",
                                                                  placeholder:
                                                                    "请输入座机(例如: 010XXXXXXXX) 或 手机号",
                                                                  oninput:
                                                                    "if(value.length>13)value=value.slice(0,13)",
                                                                  clearable: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .invoiceForm
                                                                      .invoiceDTO
                                                                      .taxpayerPhone,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .invoiceForm
                                                                          .invoiceDTO,
                                                                        "taxpayerPhone",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "invoiceForm.invoiceDTO.taxpayerPhone",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "el-col",
                                                        { attrs: { span: 24 } },
                                                        [
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              staticClass:
                                                                "special_row_content invoice_height",
                                                              attrs: {
                                                                label:
                                                                  "联系地址：",
                                                                "label-width":
                                                                  "130px",
                                                                prop:
                                                                  "" +
                                                                  (_vm._.toLower(
                                                                    _vm
                                                                      .currentInsCrop
                                                                      .insCorpCode
                                                                  ) != "tmnf"
                                                                    ? "invoiceDTO.taxpayerAddress"
                                                                    : ""),
                                                              },
                                                            },
                                                            [
                                                              _c("el-input", {
                                                                staticStyle: {
                                                                  "margin-right":
                                                                    "10px",
                                                                  "max-width":
                                                                    "320px",
                                                                },
                                                                attrs: {
                                                                  maxlength:
                                                                    "30",
                                                                  placeholder:
                                                                    "请输入联系地址",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .invoiceForm
                                                                      .invoiceDTO
                                                                      .taxpayerAddress,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .invoiceForm
                                                                          .invoiceDTO,
                                                                        "taxpayerAddress",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "invoiceForm.invoiceDTO.taxpayerAddress",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "el-col",
                                                        { attrs: { span: 24 } },
                                                        [
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              staticClass:
                                                                "special_row_content invoice_height",
                                                              attrs: {
                                                                label:
                                                                  "开户银行名称：",
                                                                "label-width":
                                                                  "130px",
                                                                prop:
                                                                  "" +
                                                                  (_vm._.toLower(
                                                                    _vm
                                                                      .currentInsCrop
                                                                      .insCorpCode
                                                                  ) != "tmnf"
                                                                    ? "invoiceDTO.taxpayerBankName"
                                                                    : ""),
                                                              },
                                                            },
                                                            [
                                                              _c("el-input", {
                                                                staticStyle: {
                                                                  "margin-right":
                                                                    "10px",
                                                                  "max-width":
                                                                    "320px",
                                                                },
                                                                attrs: {
                                                                  maxlength:
                                                                    "40",
                                                                  placeholder:
                                                                    "请输入开户银行名称",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .invoiceForm
                                                                      .invoiceDTO
                                                                      .taxpayerBankName,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .invoiceForm
                                                                          .invoiceDTO,
                                                                        "taxpayerBankName",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "invoiceForm.invoiceDTO.taxpayerBankName",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "el-col",
                                                        { attrs: { span: 24 } },
                                                        [
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              staticClass:
                                                                "special_row_content invoice_height",
                                                              attrs: {
                                                                label:
                                                                  "银行账号：",
                                                                "label-width":
                                                                  "130px",
                                                                prop:
                                                                  "" +
                                                                  (_vm._.toLower(
                                                                    _vm
                                                                      .currentInsCrop
                                                                      .insCorpCode
                                                                  ) != "tmnf"
                                                                    ? "invoiceDTO.taxpayerBankAccount"
                                                                    : ""),
                                                              },
                                                            },
                                                            [
                                                              _c("el-input", {
                                                                staticStyle: {
                                                                  "margin-right":
                                                                    "10px",
                                                                  "max-width":
                                                                    "320px",
                                                                },
                                                                attrs: {
                                                                  maxlength:
                                                                    "30",
                                                                  placeholder:
                                                                    "请输入银行账号",
                                                                  type: "text",
                                                                },
                                                                on: {
                                                                  input:
                                                                    _vm.inputBankNo,
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .invoiceForm
                                                                      .invoiceDTO
                                                                      .taxpayerBankAccount,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .invoiceForm
                                                                          .invoiceDTO,
                                                                        "taxpayerBankAccount",
                                                                        typeof $$v ===
                                                                          "string"
                                                                          ? $$v.trim()
                                                                          : $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "invoiceForm.invoiceDTO.taxpayerBankAccount",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "dialog-footer",
                              staticStyle: {
                                display: "flex",
                                "justify-content": "center",
                              },
                              attrs: { slot: "footer" },
                              slot: "footer",
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    size: "mini",
                                    plain: "",
                                    disabled: _vm.isClickUnderwriting,
                                  },
                                  on: {
                                    click: _vm.confirmInsuranceDataReplenish,
                                  },
                                },
                                [_vm._v("提交核保")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini" },
                                  on: {
                                    click:
                                      _vm.handleCloseInsuranceDataReplenish,
                                  },
                                },
                                [_vm._v("取 消")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "el-dialog",
                        {
                          class: "car-insurance-ui-dialog",
                          attrs: {
                            visible: _vm.dialogInsuranceBsDiscount,
                            "close-on-click-modal": false,
                            width: "450px",
                            "before-close": _vm.handleBsDiscountClose,
                            "modal-append-to-body": false,
                          },
                          on: {
                            "update:visible": function ($event) {
                              _vm.dialogInsuranceBsDiscount = $event
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "title",
                              attrs: { slot: "title" },
                              slot: "title",
                            },
                            [
                              _c("FormLabel", {
                                attrs: { "form-label": "申请调整自主定价系数" },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticStyle: { padding: "10px 15px 0px" } },
                            [
                              _vm.currentInsCrop.proposalStatus ==
                              _vm.dictSource["INSURED_STATUS_DROPDOWN"][8]
                                .dictCode
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        border: "1px solid #eee",
                                        padding: "10px",
                                        color: "red",
                                        "border-radius": "5px",
                                        margin: "0 20px",
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          "调整后重新报价会覆盖之前的报价单，请确认是否继续。"
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                { staticStyle: { padding: "5px 20px" } },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { height: "28px" } },
                                    [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#808080" } },
                                        [_vm._v("当前自主定价系数为：")]
                                      ),
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#1890ff" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.bsFactor.factorRate || "0"
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-form",
                                    {
                                      ref: "factorForm",
                                      attrs: { model: _vm.factorForm },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticClass: "special_row_content",
                                          attrs: {
                                            prop: "currentfactorRate",
                                            rules: [
                                              {
                                                required: true,
                                                message: "请输入折扣系数",
                                                trigger: "blur",
                                              },
                                            ],
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "align-items": "center",
                                              },
                                            },
                                            [
                                              _c("span", [_vm._v("调整为：")]),
                                              _c("el-input", {
                                                staticClass: "bsDiscount-style",
                                                attrs: {
                                                  type: "text",
                                                  clearable: false,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    _vm.factorForm.currentfactorRate =
                                                      _vm.onInput(
                                                        _vm.factorForm
                                                          .currentfactorRate,
                                                        0,
                                                        100,
                                                        4
                                                      )
                                                  },
                                                },
                                                nativeOn: {
                                                  keyup: function ($event) {
                                                    _vm.factorForm.currentfactorRate =
                                                      _vm.onInput(
                                                        _vm.factorForm
                                                          .currentfactorRate,
                                                        0,
                                                        100,
                                                        4
                                                      )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.factorForm
                                                      .currentfactorRate,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.factorForm,
                                                      "currentfactorRate",
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                    )
                                                  },
                                                  expression:
                                                    "factorForm.currentfactorRate",
                                                },
                                              }),
                                              ((["paic"].includes(
                                                _vm._.toLower(
                                                  _vm.currentInsCrop.insCorpCode
                                                )
                                              ) &&
                                                !["110100", "310100"].includes(
                                                  _vm.currentInsCrop.insCityCode
                                                )) ||
                                                (["tmnf"].includes(
                                                  _vm._.toLower(
                                                    _vm.currentInsCrop
                                                      .insCorpCode
                                                  )
                                                ) &&
                                                  ["110100"].includes(
                                                    _vm.currentInsCrop
                                                      .insCityCode
                                                  ))) &&
                                              _vm.form.quoteVehicle.fuelType ==
                                                "0"
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "margin-left": "10px",
                                                        color: "red",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "(可调整范围：0.55 ~ 1.5)"
                                                      ),
                                                    ]
                                                  )
                                                : _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "margin-left": "10px",
                                                        color: "red",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "(可调整范围：" +
                                                          _vm._s(
                                                            _vm.bsFactor
                                                              .factorRateMin ||
                                                              "0"
                                                          ) +
                                                          " ~ " +
                                                          _vm._s(
                                                            _vm.bsFactor
                                                              .factorRateMax ||
                                                              "0"
                                                          ) +
                                                          ")"
                                                      ),
                                                    ]
                                                  ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "dialog-footer",
                              staticStyle: {
                                display: "flex",
                                "justify-content": "center",
                                "line-height": "40px",
                                padding: "0px 10px 8px",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    size: "mini",
                                    plain: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.confirmInsuranceBsDiscount(
                                        _vm.factorForm.currentfactorRate
                                      )
                                    },
                                  },
                                },
                                [_vm._v("确定并重新报价")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      _vm.dialogInsuranceBsDiscount = false
                                    },
                                  },
                                },
                                [_vm._v("取 消")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "el-dialog",
                        {
                          class: "car-insurance-ui-dialog",
                          attrs: {
                            visible: _vm.dialogModifyDutyAmount,
                            "close-on-click-modal": false,
                            width: "450px",
                            "before-close": _vm.handleDutyAmountClose,
                            "modal-append-to-body": false,
                          },
                          on: {
                            "update:visible": function ($event) {
                              _vm.dialogModifyDutyAmount = $event
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "title",
                              attrs: { slot: "title" },
                              slot: "title",
                            },
                            [
                              _c("FormLabel", {
                                attrs: { "form-label": "调整车损险保额" },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticStyle: { padding: "10px 15px 0px" } },
                            [
                              _vm.currentInsCrop.proposalStatus ==
                              _vm.dictSource["INSURED_STATUS_DROPDOWN"][8]
                                .dictCode
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        border: "1px solid #eee",
                                        padding: "10px",
                                        color: "red",
                                        "border-radius": "5px",
                                        margin: "0 20px",
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          "调整后重新报价会覆盖之前的报价单，请确认是否继续。"
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                { staticStyle: { padding: "5px 20px" } },
                                [
                                  _c("div", [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "#808080" } },
                                      [_vm._v("车辆实际价值为：")]
                                    ),
                                    _c(
                                      "span",
                                      { staticStyle: { color: "#1890ff" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            "￥" +
                                              ((_vm.bsCSX.amount &&
                                                _vm.bsCSX.amount.toLocaleString()) ||
                                                "0.00")
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c(
                                    "el-form",
                                    {
                                      ref: "csxForm",
                                      attrs: { model: _vm.csxForm },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticClass: "special_row_content",
                                          attrs: {
                                            prop: "amount",
                                            rules: [
                                              {
                                                required: true,
                                                message: "请输入车损保额",
                                                trigger: "blur",
                                              },
                                            ],
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "align-items": "center",
                                              },
                                            },
                                            [
                                              _c("span", [_vm._v("调整为：")]),
                                              _c("el-input", {
                                                staticClass: "bsDiscount-style",
                                                attrs: {
                                                  maxlength: "10",
                                                  type: "text",
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    _vm.csxForm.amount =
                                                      _vm.onInput(
                                                        _vm.csxForm.amount,
                                                        null,
                                                        _vm.bsCSX.max,
                                                        2
                                                      )
                                                  },
                                                },
                                                nativeOn: {
                                                  keyup: function ($event) {
                                                    _vm.csxForm.amount =
                                                      _vm.onInput(
                                                        _vm.csxForm.amount,
                                                        null,
                                                        _vm.bsCSX.max,
                                                        2
                                                      )
                                                  },
                                                },
                                                model: {
                                                  value: _vm.csxForm.amount,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.csxForm,
                                                      "amount",
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                    )
                                                  },
                                                  expression: "csxForm.amount",
                                                },
                                              }),
                                              _vm.csxForm.amount &&
                                              (parseFloat(_vm.csxForm.amount) >
                                                parseFloat(_vm.bsCSX.max) ||
                                                parseFloat(_vm.csxForm.amount) <
                                                  parseFloat(_vm.bsCSX.min))
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "margin-left": "10px",
                                                        color: "red",
                                                        "font-size": "12px",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "您输入的保额超出范围，请修改."
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "align-items": "center",
                                                "margin-top": "5px",
                                                height: "28px",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    color: "#6e6e6e",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "(可调整幅度限±30%：" +
                                                      _vm._s(
                                                        "￥" +
                                                          (_vm.bsCSX.minTxt ||
                                                            "0.00")
                                                      ) +
                                                      " ~ " +
                                                      _vm._s(
                                                        "￥" +
                                                          (_vm.bsCSX.maxTxt ||
                                                            "0.00")
                                                      ) +
                                                      ")"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "dialog-footer",
                              staticStyle: {
                                display: "flex",
                                "justify-content": "center",
                                "line-height": "40px",
                                padding: "0px 10px 8px",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    disabled:
                                      !_vm.csxForm.amount ||
                                      parseFloat(_vm.csxForm.amount) <
                                        _vm.bsCSX.min,
                                    size: "mini",
                                    plain: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.modifyDutyAmount(
                                        _vm.csxForm.amount
                                      )
                                    },
                                  },
                                },
                                [_vm._v("确定并重新报价")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      _vm.dialogModifyDutyAmount = false
                                    },
                                  },
                                },
                                [_vm._v("取 消")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "el-dialog",
                        {
                          class: "car-insurance-ui-dialog",
                          attrs: {
                            visible: _vm.dialogUploading,
                            "close-on-click-modal": false,
                            width: "825px",
                            "before-close": _vm.dialogUploadingClose,
                            "modal-append-to-body": false,
                          },
                          on: {
                            "update:visible": function ($event) {
                              _vm.dialogUploading = $event
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "dialog-footer",
                              attrs: { slot: "title" },
                              slot: "title",
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "font-size": "14px",
                                    "font-weight": "bold",
                                  },
                                },
                                [_vm._v("上传影像")]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.upStyleloading,
                                  expression: "upStyleloading",
                                },
                              ],
                              staticStyle: {
                                display: "flex",
                                margin: "10px 15px 0px 15px",
                                border: "1px solid #e0e0e0",
                                "border-radius": "5px",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    flex: "1",
                                    "min-height": "300px",
                                  },
                                },
                                [
                                  _vm.isUploadAndUnderwriting
                                    ? _c("div", {
                                        staticStyle: {
                                          padding: "10px",
                                          color: "red",
                                          "font-size": "13px",
                                          border: "1px solid #eee",
                                          "margin-right": "10px",
                                          "border-radius": "5px",
                                        },
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.currentFlowNode &&
                                              _vm.currentFlowNode.message
                                              ? _vm.currentFlowNode.message
                                              : ""
                                          ),
                                        },
                                      })
                                    : _vm._e(),
                                  _c("div", [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          padding: "5px 15px 0px 15px",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "font-size": "15px",
                                              "line-height": "28px",
                                              "font-weight": "600",
                                            },
                                          },
                                          [_vm._v("选择上传资料类型：")]
                                        ),
                                        _vm.currentInsCrop &&
                                        _vm._.toLower(
                                          _vm.currentInsCrop.insCorpCode
                                        ) == "cpic"
                                          ? _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  color: "red",
                                                  "font-size": "12px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "(上传附件大小不超过500KB)"
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          padding: "5px 15px 10px",
                                          "border-bottom": "1px solid #eee",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: {
                                              filterable: "",
                                              placeholder: "选择证件类型",
                                              size: "mini",
                                            },
                                            on: { change: _vm.imgPeopleType },
                                            model: {
                                              value: _vm.form.businessType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "businessType",
                                                  $$v
                                                )
                                              },
                                              expression: "form.businessType",
                                            },
                                          },
                                          _vm._l(
                                            _vm.businessTypeList,
                                            function (item, index) {
                                              return _c("el-option", {
                                                key: index,
                                                attrs: {
                                                  label: item.name,
                                                  value: item.code,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: {
                                              "margin-left": "5px",
                                            },
                                            attrs: {
                                              filterable: "",
                                              placeholder: "选择证件类型",
                                              size: "mini",
                                            },
                                            on: { change: _vm.imgIdType },
                                            model: {
                                              value: _vm.form.ID,
                                              callback: function ($$v) {
                                                _vm.$set(_vm.form, "ID", $$v)
                                              },
                                              expression: "form.ID",
                                            },
                                          },
                                          _vm._l(
                                            _vm.subTypeList,
                                            function (item, index) {
                                              return _c("el-option", {
                                                key: index,
                                                attrs: {
                                                  label: item.name,
                                                  value: item.code,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          "flex-wrap": "wrap",
                                          "max-height": "400px",
                                          "overflow-y": "auto",
                                          padding: "5px",
                                        },
                                      },
                                      _vm._l(
                                        _vm.imageInsList,
                                        function (item, index) {
                                          return _c(
                                            "div",
                                            {
                                              key: index,
                                              staticStyle: {
                                                border: "1px solid #e0e0e0",
                                                width: "200px",
                                                margin: "10px",
                                                "border-radius": "6px",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    padding: "5px 2px",
                                                    display: "flex",
                                                    "justify-content":
                                                      "space-between",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "mini-font-size",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(item.name) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm.handleImgReturn(item)
                                                    ? _c("i", {
                                                        staticClass:
                                                          "el-icon-close",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.imgInsClose(
                                                              index
                                                            )
                                                          },
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ]
                                              ),
                                              _c(
                                                "el-upload",
                                                {
                                                  staticClass:
                                                    "avatar-uploader",
                                                  attrs: {
                                                    multiple: "",
                                                    drag: "",
                                                    action: "1",
                                                    "show-file-list": false,
                                                    "http-request":
                                                      _vm.uploadImg,
                                                    "auto-upload": false,
                                                    "on-success":
                                                      _vm.handleAvatarSuccess,
                                                    "before-upload":
                                                      _vm.beforeAvatarUpload,
                                                    "on-change": function (
                                                      val
                                                    ) {
                                                      _vm.uploadImgChange(
                                                        val,
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        display: "flex",
                                                        "flex-wrap": "wrap",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "draggable",
                                                        _vm._b(
                                                          {
                                                            key: "1",
                                                            staticClass:
                                                              "board-column-content-upload",
                                                            attrs: {
                                                              list: item.imageList,
                                                              group: "mission",
                                                              "set-data":
                                                                _vm.setData,
                                                              move: _vm.rightOnMove,
                                                              animation: "300",
                                                            },
                                                            on: {
                                                              end: _vm.rightDrag,
                                                              add: function (
                                                                $event
                                                              ) {
                                                                return _vm.rightDragAdd(
                                                                  item
                                                                )
                                                              },
                                                              sort: _vm.rightSort,
                                                            },
                                                          },
                                                          "draggable",
                                                          _vm.$attrs,
                                                          false
                                                        ),
                                                        [
                                                          _vm._l(
                                                            item.imageList,
                                                            function (
                                                              eitem,
                                                              eindex
                                                            ) {
                                                              return _c(
                                                                "div",
                                                                {
                                                                  key: eindex,
                                                                  staticStyle: {
                                                                    width:
                                                                      "64px",
                                                                    height:
                                                                      "64px",
                                                                    position:
                                                                      "relative",
                                                                    margin:
                                                                      "5px",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        $event.preventDefault()
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            $event.stopPropagation()
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "el-image",
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "show",
                                                                                rawName:
                                                                                  "v-show",
                                                                                value:
                                                                                  _vm._.toLower(
                                                                                    eitem.imageName
                                                                                  ).indexOf(
                                                                                    ".pdf"
                                                                                  ) ==
                                                                                  -1,
                                                                                expression:
                                                                                  "(_.toLower(eitem.imageName)).indexOf('.pdf') == -1",
                                                                              },
                                                                            ],
                                                                          staticClass:
                                                                            "avatar",
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "65px",
                                                                              height:
                                                                                "65px",
                                                                              display:
                                                                                "flex",
                                                                              "align-items":
                                                                                "center",
                                                                              "justify-content":
                                                                                "center",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              src: eitem.imgUrl,
                                                                              "preview-src-list":
                                                                                [
                                                                                  eitem.imgUrl,
                                                                                ],
                                                                            },
                                                                          on: {
                                                                            mouseover:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.mouseOver(
                                                                                  "right" +
                                                                                    eindex +
                                                                                    index
                                                                                )
                                                                              },
                                                                            mouseleave:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.mouseLeave(
                                                                                  "right" +
                                                                                    eindex +
                                                                                    index
                                                                                )
                                                                              },
                                                                          },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "svg-icon",
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "show",
                                                                                rawName:
                                                                                  "v-show",
                                                                                value:
                                                                                  _vm._.toLower(
                                                                                    eitem.imageName
                                                                                  ).indexOf(
                                                                                    ".pdf"
                                                                                  ) !=
                                                                                  -1,
                                                                                expression:
                                                                                  "(_.toLower(eitem.imageName)).indexOf('.pdf') != -1",
                                                                              },
                                                                            ],
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "65px",
                                                                              height:
                                                                                "65px",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              "icon-class":
                                                                                "pdf",
                                                                            },
                                                                          on: {
                                                                            mouseover:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.mouseOver(
                                                                                  "right" +
                                                                                    eindex +
                                                                                    index
                                                                                )
                                                                              },
                                                                            mouseleave:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.mouseLeave(
                                                                                  "right" +
                                                                                    eindex +
                                                                                    index
                                                                                )
                                                                              },
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.openPdfFile(
                                                                                  eitem
                                                                                )
                                                                              },
                                                                          },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  !_vm._.isNull(
                                                                    eitem.documentId
                                                                  )
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              border:
                                                                                "1px solid #2150A3",
                                                                              "background-color":
                                                                                "rgba(255,255,255,0.8)",
                                                                              position:
                                                                                "absolute",
                                                                              top: "0",
                                                                              left: "0",
                                                                              "border-radius":
                                                                                "50%",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "el-tooltip",
                                                                            {
                                                                              staticClass:
                                                                                "item",
                                                                              attrs:
                                                                                {
                                                                                  effect:
                                                                                    "dark",
                                                                                  content:
                                                                                    "已上传到保司",
                                                                                  placement:
                                                                                    "top",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "i",
                                                                                {
                                                                                  staticClass:
                                                                                    "el-icon-upload",
                                                                                  staticStyle:
                                                                                    {
                                                                                      color:
                                                                                        "#2150A3",
                                                                                      "font-size":
                                                                                        "13px",
                                                                                      margin:
                                                                                        "0",
                                                                                      "line-height":
                                                                                        "0px",
                                                                                      padding:
                                                                                        "3px 1px",
                                                                                      opacity:
                                                                                        "1",
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm._.isNull(
                                                                    eitem.documentId
                                                                  )
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              "background-color":
                                                                                "rgba(255,255,255)",
                                                                              position:
                                                                                "absolute",
                                                                              top: "-5px",
                                                                              right:
                                                                                "-5px",
                                                                              "border-radius":
                                                                                "50%",
                                                                              display:
                                                                                "none",
                                                                              width:
                                                                                "17px",
                                                                              height:
                                                                                "17px",
                                                                              cursor:
                                                                                "pointer",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              id:
                                                                                "right" +
                                                                                eindex +
                                                                                index,
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                $event.stopPropagation()
                                                                                return _vm.delRightImgItem(
                                                                                  eitem,
                                                                                  eindex,
                                                                                  item
                                                                                )
                                                                              },
                                                                            mouseover:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.mouseOver(
                                                                                  "right" +
                                                                                    eindex +
                                                                                    index
                                                                                )
                                                                              },
                                                                            mouseleave:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.mouseLeave(
                                                                                  "right" +
                                                                                    eindex +
                                                                                    index
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "i",
                                                                            {
                                                                              staticClass:
                                                                                "el-icon-error",
                                                                              staticStyle:
                                                                                {
                                                                                  color:
                                                                                    "red",
                                                                                  "font-size":
                                                                                    "17px",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          !item.imageList.length
                                                            ? _c("i", {
                                                                staticClass:
                                                                  "el-icon-plus avatar-uploader-icon",
                                                              })
                                                            : _vm._e(),
                                                        ],
                                                        2
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _vm.imageUrl
                                                ? _c("img", {
                                                    staticClass: "avatar",
                                                    attrs: {
                                                      src: _vm.imageUrl,
                                                    },
                                                  })
                                                : _vm._e(),
                                              false
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        display: "flex",
                                                        "justify-content":
                                                          "space-between",
                                                        padding: "3px",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            display: "flex",
                                                            "align-items":
                                                              "center",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "mini-font-size",
                                                            },
                                                            [_vm._v("有效期至")]
                                                          ),
                                                          _c("el-date-picker", {
                                                            staticClass:
                                                              "validityDate",
                                                            attrs: {
                                                              "prefix-icon":
                                                                "validityDateIcon",
                                                              disabled:
                                                                item.isLongTerm,
                                                              type: "date",
                                                              placeholder:
                                                                "选择日期",
                                                              size: "mini",
                                                              clearable: false,
                                                            },
                                                            model: {
                                                              value: item.time,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "time",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item.time",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "div",
                                                        [
                                                          _c(
                                                            "el-checkbox",
                                                            {
                                                              staticClass:
                                                                "mini-font-size",
                                                              attrs: {
                                                                size: "mini",
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.hasLongTerm(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  item.isLongTerm,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "isLongTerm",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "item.isLongTerm",
                                                              },
                                                            },
                                                            [_vm._v("长期")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    background: "#eee",
                                    width: "280px",
                                    padding: "10px",
                                    "border-radius": "0 5px 5px 0",
                                  },
                                },
                                [
                                  _c(
                                    "el-upload",
                                    {
                                      ref: "uploadMutil",
                                      staticClass: "upload-demo",
                                      attrs: {
                                        "list-type": "picture-card",
                                        multiple: "",
                                        drag: "",
                                        action: "",
                                        "http-request": _vm.uploadMutilFile,
                                        "auto-upload": false,
                                        "show-file-list": false,
                                        "on-change": function (val) {
                                          _vm.uploadImgChange(val, null)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "i",
                                        {
                                          staticClass: "el-icon-plus",
                                          staticStyle: { "margin-top": "25px" },
                                        },
                                        [
                                          _c("br"),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "font-size": "10px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "将图片拖到此处或点击批量上传"
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm.hasImgItems
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "font-size": "12px",
                                            color: "#666",
                                            padding: "10px 0px 0px 5px",
                                            "font-weight": "600",
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              "以下图片可直接拖入左侧影像分类中"
                                            ),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                  !_vm._.isEmpty(_vm.imgItems)
                                    ? _c(
                                        "div",
                                        { staticClass: "upload_draggable" },
                                        _vm._l(
                                          _vm.imgItems,
                                          function (item, index) {
                                            return _c(
                                              "div",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      item.fileTypeList &&
                                                      item.fileTypeList
                                                        .length != 0,
                                                    expression:
                                                      "item.fileTypeList&&item.fileTypeList.length != 0",
                                                  },
                                                ],
                                                key: index,
                                                staticStyle: {
                                                  display: "flex",
                                                  "flex-wrap": "wrap",
                                                  "max-height": "440px",
                                                  "overflow-y": "auto",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "fileTypeName",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(item.fileTypeName)
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "draggable",
                                                  _vm._b(
                                                    {
                                                      key: "2",
                                                      staticClass:
                                                        "board-column-content",
                                                      attrs: {
                                                        list: item.fileTypeList,
                                                        move: _vm.leftOnMove,
                                                        options: {
                                                          group: {
                                                            name: "mission",
                                                            pull: "clone",
                                                          },
                                                          sort: true,
                                                        },
                                                        "set-data": _vm.setData,
                                                        animation: "300",
                                                      },
                                                      on: {
                                                        end: _vm.leftDrag,
                                                        add: _vm.leftDragAdd,
                                                      },
                                                    },
                                                    "draggable",
                                                    _vm.$attrs,
                                                    false
                                                  ),
                                                  _vm._l(
                                                    item.fileTypeList,
                                                    function (fitem, findex) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: findex,
                                                          staticStyle: {
                                                            width: "100px",
                                                            height: "100px",
                                                            position:
                                                              "relative",
                                                            margin: "5px",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("el-image", {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value:
                                                                  _vm._.toLower(
                                                                    fitem.imageName
                                                                  ).indexOf(
                                                                    ".pdf"
                                                                  ) == -1,
                                                                expression:
                                                                  "(_.toLower(fitem.imageName)).indexOf('.pdf') == -1",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "avatar",
                                                            staticStyle: {
                                                              width: "100px",
                                                              height: "100px",
                                                              display: "flex",
                                                              "align-items":
                                                                "center",
                                                              "justify-content":
                                                                "center",
                                                            },
                                                            attrs: {
                                                              src: fitem.imgUrl,
                                                              "preview-src-list":
                                                                [fitem.imgUrl],
                                                            },
                                                            on: {
                                                              mouseover:
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.mouseOver(
                                                                    item.fileType +
                                                                      "Left" +
                                                                      findex
                                                                  )
                                                                },
                                                              mouseleave:
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.mouseLeave(
                                                                    item.fileType +
                                                                      "Left" +
                                                                      findex
                                                                  )
                                                                },
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                              },
                                                            },
                                                          }),
                                                          _c("svg-icon", {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value:
                                                                  _vm._.toLower(
                                                                    fitem.imageName
                                                                  ).indexOf(
                                                                    ".pdf"
                                                                  ) != -1,
                                                                expression:
                                                                  "(_.toLower(fitem.imageName)).indexOf('.pdf') != -1",
                                                              },
                                                            ],
                                                            staticStyle: {
                                                              width: "100px",
                                                              height: "100px",
                                                              "font-size":
                                                                "30px",
                                                            },
                                                            attrs: {
                                                              "icon-class":
                                                                "pdf",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.openPdfFile(
                                                                  fitem
                                                                )
                                                              },
                                                              mouseover:
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.mouseOver(
                                                                    item.fileType +
                                                                      "Left" +
                                                                      findex
                                                                  )
                                                                },
                                                              mouseleave:
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.mouseLeave(
                                                                    item.fileType +
                                                                      "Left" +
                                                                      findex
                                                                  )
                                                                },
                                                            },
                                                          }),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                "background-color":
                                                                  "red",
                                                                position:
                                                                  "absolute",
                                                                top: "-5px",
                                                                right: "-5px",
                                                                "border-radius":
                                                                  "50%",
                                                                display: "none",
                                                                border:
                                                                  "2px solid rgba(255,255,255)",
                                                                width: "19px",
                                                                height: "19px",
                                                                cursor:
                                                                  "pointer",
                                                              },
                                                              attrs: {
                                                                id:
                                                                  item.fileType +
                                                                  "Left" +
                                                                  findex,
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.delImgItem(
                                                                      index,
                                                                      fitem,
                                                                      findex
                                                                    )
                                                                  },
                                                                mouseover:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.mouseOver(
                                                                      item.fileType +
                                                                        "Left" +
                                                                        findex
                                                                    )
                                                                  },
                                                                mouseleave:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.mouseLeave(
                                                                      "OTHERLeft" +
                                                                        findex
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "el-icon-delete",
                                                                staticStyle: {
                                                                  "font-size":
                                                                    "13px",
                                                                  color: "#fff",
                                                                  display:
                                                                    "flex",
                                                                  "justify-content":
                                                                    "center",
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "dialog-footer",
                              staticStyle: {
                                display: "flex",
                                "justify-content": "center",
                              },
                              attrs: { slot: "footer" },
                              slot: "footer",
                            },
                            [
                              _vm.isUploadAndUnderwriting
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "primary",
                                        size: "mini",
                                        disabled:
                                          !_vm.imageInsList ||
                                          _vm.imageInsList.length == 0 ||
                                          _vm.upStyleloading,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.uploadApply(true)
                                        },
                                      },
                                    },
                                    [_vm._v("上传并核保")]
                                  )
                                : _vm._e(),
                              !_vm.isUploadAndUnderwriting
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "mini",
                                        disabled:
                                          !_vm.imageInsList ||
                                          _vm.imageInsList.length == 0 ||
                                          _vm.upStyleloading,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.uploadApply(false, "store")
                                        },
                                      },
                                    },
                                    [_vm._v("暂 存")]
                                  )
                                : _vm._e(),
                              !_vm.isUploadAndUnderwriting &&
                              ((_vm.currentBzInsItem &&
                                _vm.currentBzInsItem.proposalNo) ||
                                (_vm.currentBsInsItem &&
                                  _vm.currentBsInsItem.proposalNo))
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "primary",
                                        size: "mini",
                                        disabled:
                                          !_vm.imageInsList ||
                                          _vm.imageInsList.length == 0 ||
                                          _vm.upStyleloading,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.uploadApply(
                                            false,
                                            "upload"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("上传到保司")]
                                  )
                                : _vm._e(),
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini" },
                                  on: { click: _vm.closeDialogUploading },
                                },
                                [_vm._v("关 闭")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "el-dialog",
                        {
                          class: "car-insurance-ui-dialog",
                          attrs: {
                            visible: _vm.showDialog,
                            width: "900px",
                            "close-on-click-modal": false,
                            "before-close": _vm.dialogExtendClose,
                            "modal-append-to-body": false,
                          },
                          on: {
                            "update:visible": function ($event) {
                              _vm.showDialog = $event
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "dialog-footer",
                              attrs: { slot: "title" },
                              slot: "title",
                            },
                            [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "14px" } },
                                [_vm._v("增值服务")]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.quoteExpandShow,
                                  expression: "quoteExpandShow",
                                },
                              ],
                              staticStyle: {
                                padding: "15px",
                                "min-height": "300px",
                                "max-height": "500px",
                                overflow: "auto",
                                background: "#f5f5f5",
                              },
                              attrs: { "element-loading-text": "加载中" },
                            },
                            [
                              _c("VASForm", {
                                attrs: { data: _vm.form.quoteExpand },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "justify-content": "center",
                                padding: "8px",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    type: "primary",
                                    icon: "el-icon-check",
                                    disabled: _vm._.isEmpty(
                                      _vm.form.quoteExpand
                                    ),
                                  },
                                  on: { click: _vm.confirmExtend },
                                },
                                [_vm._v(" 确定 ")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    icon: "el-icon-close",
                                  },
                                  on: { click: _vm.dialogExtendClose },
                                },
                                [_vm._v(" 取消 ")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "el-dialog",
                        {
                          staticClass: "pay_dialog",
                          attrs: {
                            visible: _vm.showPayDialog,
                            "close-on-click-modal": false,
                            title: "支付",
                            width: "720px",
                            "modal-append-to-body": false,
                            center: "",
                          },
                          on: {
                            "update:visible": function ($event) {
                              _vm.showPayDialog = $event
                            },
                            handleClose: _vm.cancel,
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "title payTitle",
                              attrs: { slot: "title" },
                              slot: "title",
                            },
                            [
                              _c("FormLabel", {
                                attrs: { "form-label": "支付" },
                              }),
                              _c("el-divider"),
                            ],
                            1
                          ),
                          _c("Payment", {
                            attrs: {
                              sourceId: _vm.sourceId,
                              data: _vm.currentInsCrop,
                              refresh: _vm.showPayDialog,
                            },
                            on: { closePay: _vm.closePayDialog },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-dialog",
                        {
                          class: "car-insurance-ui-dialog",
                          attrs: {
                            visible: _vm.showPrintDialog,
                            "close-on-click-modal": false,
                            title:
                              "" +
                              (_vm.underwritingConfirm
                                ? "核保信息确认"
                                : "报价单"),
                            width: "1000px",
                            "modal-append-to-body": false,
                          },
                          on: {
                            "update:visible": function ($event) {
                              _vm.showPrintDialog = $event
                            },
                            handleClose: _vm.cancel,
                          },
                        },
                        [
                          _c("PrintInsureProposal", {
                            attrs: {
                              data: _vm.currentInsCrop,
                              "use-attribute":
                                _vm.form.quoteVehicle.useAttribute,
                              "cust-attribute":
                                _vm.form.owner.relationshipAttribute,
                              refresh: _vm.showPrintDialog,
                              underwritingConfirm: _vm.underwritingConfirm,
                            },
                            on: {
                              closeProposal: _vm.closePrintDialog,
                              closeUnderwriting: _vm.closeUnderwriting,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-dialog",
                        {
                          class: "car-insurance-ui-dialog",
                          attrs: {
                            visible: _vm.showRepealDialog,
                            width: "900px",
                            "close-on-click-modal": false,
                            "before-close": _vm.hideRepealProposal,
                            "modal-append-to-body": false,
                          },
                          on: {
                            "update:visible": function ($event) {
                              _vm.showRepealDialog = $event
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "title",
                              attrs: { slot: "title" },
                              slot: "title",
                            },
                            [
                              _c("FormLabel", {
                                attrs: { "form-label": "撤销投保" },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.isRepealProposalClick,
                                  expression: "isRepealProposalClick",
                                },
                              ],
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    margin: "15px 25px 0",
                                    border: "1px solid #DCDFE6",
                                    padding: "10px",
                                    "border-radius": "4px",
                                    color: "red",
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.showRepealMsg)),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    padding: "25px",
                                    "max-height": "500px",
                                  },
                                },
                                [
                                  _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 24 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              staticClass:
                                                "special_row_content",
                                              attrs: {
                                                label: "撤销原因：",
                                                "label-width": "80px",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticStyle: {
                                                  "margin-right": "10px",
                                                },
                                                attrs: {
                                                  type: "textarea",
                                                  rows: 3,
                                                  placeholder: "请输入撤销原因",
                                                  clearable: "",
                                                  maxlength: "100",
                                                },
                                                model: {
                                                  value: _vm.repealReason,
                                                  callback: function ($$v) {
                                                    _vm.repealReason = $$v
                                                  },
                                                  expression: "repealReason",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "dialog-footer",
                              staticStyle: {
                                display: "flex",
                                "justify-content": "center",
                              },
                              attrs: { slot: "footer" },
                              slot: "footer",
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    type: "primary",
                                    icon: "el-icon-check",
                                    disabled: _vm.isRepealProposalClick,
                                  },
                                  on: { click: _vm.confirmRepealProposal },
                                },
                                [_vm._v(" 确定 ")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    icon: "el-icon-close",
                                  },
                                  on: { click: _vm.hideRepealProposal },
                                },
                                [_vm._v(" 取消 ")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "el-dialog",
                        {
                          staticClass: "special_dialog",
                          attrs: {
                            visible: _vm.showSpecial,
                            "close-on-click-modal": false,
                            title: "添加/编辑特约",
                            width: "1024px",
                            "modal-append-to-body": false,
                            center: "",
                          },
                          on: {
                            "update:visible": function ($event) {
                              _vm.showSpecial = $event
                            },
                            handleClose: _vm.closeSpecial,
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "title",
                              attrs: { slot: "title" },
                              slot: "title",
                            },
                            [
                              _c("FormLabel", {
                                attrs: { "form-label": _vm.specialTitle },
                              }),
                            ],
                            1
                          ),
                          _c("Specialterms", {
                            attrs: {
                              "product-id": _vm.proposalRiskId,
                              "special-list": _vm.specialList,
                              refresh: _vm.showSpecial,
                            },
                            on: { closeSpecial: _vm.closeSpecial },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-dialog",
                        {
                          staticClass: "special_dialog",
                          attrs: {
                            visible: _vm.showVerifySmsDialog,
                            "close-on-click-modal": false,
                            title: "投保人实名认证",
                            width: "500px",
                            "modal-append-to-body": false,
                            center: "",
                          },
                          on: {
                            "update:visible": function ($event) {
                              _vm.showVerifySmsDialog = $event
                            },
                            handleClose: function ($event) {
                              _vm.showVerifySmsDialog = false
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "title",
                              attrs: { slot: "title" },
                              slot: "title",
                            },
                            [
                              _c("FormLabel", {
                                attrs: { "form-label": "投保人实名认证" },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                padding: "20px 50px 10px",
                                "min-height": "100px",
                                "background-color": "#fff",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticStyle: { "text-align": "center" } },
                                [
                                  _c("div", [
                                    _c("p", [
                                      _c("span", [
                                        _vm._v(
                                          '保司系统已发送手机验证码给"' +
                                            _vm._s(
                                              _vm.form.applicant.mobile
                                                ? _vm.form.applicant.mobile
                                                : _vm.form.insured.mobile
                                            ) +
                                            '"，请注意查收！'
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: { margin: "15px 0px 20px" },
                                    },
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 24 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              staticClass:
                                                "special_row_content",
                                              attrs: {
                                                label: "验证码：",
                                                "label-width": "110px",
                                                prop: "issueCode",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticStyle: {
                                                  "margin-right": "10px",
                                                },
                                                attrs: {
                                                  maxlength: "10",
                                                  placeholder: "请输入验证码",
                                                  clearable: "",
                                                },
                                                model: {
                                                  value: _vm.verifySmsCode,
                                                  callback: function ($$v) {
                                                    _vm.verifySmsCode = $$v
                                                  },
                                                  expression: "verifySmsCode",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.verifySmsMsg
                                        ? _c(
                                            "p",
                                            {
                                              staticStyle: {
                                                "line-height": "40px",
                                                height: "40px",
                                                "font-size": "13px",
                                                "text-align": "center",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: { color: "red" },
                                                },
                                                [
                                                  _vm._v(
                                                    "注：" +
                                                      _vm._s(_vm.verifySmsMsg)
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "dialog-footer",
                              staticStyle: {
                                display: "flex",
                                "justify-content": "center",
                                "line-height": "40px",
                                padding: "8px 0px",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    type: "primary",
                                    icon: "el-icon-check",
                                    disabled: !_vm.verifySmsCode,
                                  },
                                  on: { click: _vm.confirmVerifyMsg },
                                },
                                [_vm._v(" 认证并核保 ")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    icon: "el-icon-close",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.showVerifySmsDialog = false
                                    },
                                  },
                                },
                                [_vm._v(" 取消 ")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "el-dialog",
                        {
                          staticClass: "novehicle_dialog",
                          attrs: {
                            visible: _vm.showNoVehicleDialog,
                            "close-on-click-modal": false,
                            title: "管理驾意险",
                            width: "900px",
                            "modal-append-to-body": false,
                          },
                          on: {
                            "update:visible": function ($event) {
                              _vm.showNoVehicleDialog = $event
                            },
                            handleClose: _vm.cancel,
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "title",
                              attrs: { slot: "title" },
                              slot: "title",
                            },
                            [
                              _c("FormLabel", {
                                attrs: { "form-label": "管理驾意险" },
                              }),
                            ],
                            1
                          ),
                          _c("NoVehicleInsurance", {
                            attrs: {
                              needGetNewNonVehicleInsList:
                                _vm.needGetNewNonVehicleInsList,
                              params: _vm.queryAccidenProductParam,
                              refresh: _vm.showNoVehicleDialog,
                            },
                            on: { closeNoVehicle: _vm.closeNoVehicleDialog },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-dialog",
                        {
                          class: "car-insurance-ui-dialog",
                          attrs: {
                            visible: _vm.showImgVerifyDialog,
                            "close-on-click-modal": false,
                            title: "图形验证码",
                            width: "640px",
                            "modal-append-to-body": false,
                          },
                          on: {
                            "update:visible": function ($event) {
                              _vm.showImgVerifyDialog = $event
                            },
                            handleClose: _vm.cancel,
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "title",
                              attrs: { slot: "title" },
                              slot: "title",
                            },
                            [
                              _c("FormLabel", {
                                attrs: { "form-label": "图形验证码" },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "justify-content": "center",
                                "padding-bottom": "20px",
                              },
                            },
                            [
                              _c("span", [
                                _vm._v("请输入下图中的字符，不区分大小写"),
                              ]),
                            ]
                          ),
                          _vm.currentFlowNode &&
                          _vm.currentFlowNode.code == "ImageVerifyCode" &&
                          _vm.form.currentFlowNode &&
                          _vm.form.currentFlowNode.imageVerifyCode
                            ? _c(
                                "div",
                                { key: "ImageVerifyCode" },
                                _vm._l(
                                  _vm.form.currentFlowNode.imageVerifyCode,
                                  function (item, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticStyle: { margin: "0 20px" },
                                      },
                                      [
                                        item.imageBase64
                                          ? _c("div", [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                    "justify-content": "center",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      ref:
                                                        "currentFlowNode.imageVerifyCode." +
                                                        index +
                                                        ".verifyCode",
                                                      refInFor: true,
                                                      staticClass:
                                                        "special_row_content",
                                                      attrs: {
                                                        label:
                                                          (item.verifyCodeId &&
                                                          item.verifyCodeId.split(
                                                            "|"
                                                          )[0]
                                                            ? item.verifyCodeId.split(
                                                                "|"
                                                              )[0] == "1"
                                                              ? "商业险"
                                                              : "交强险"
                                                            : "") + "验证码",
                                                        prop:
                                                          "currentFlowNode.imageVerifyCode." +
                                                          index +
                                                          ".verifyCode",
                                                        rules: {
                                                          required: true,
                                                          message:
                                                            "请输入验证码",
                                                          trigger: "blur",
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            display: "flex",
                                                            "align-items":
                                                              "center",
                                                          },
                                                        },
                                                        [
                                                          _c("el-input", {
                                                            staticStyle: {
                                                              "margin-right":
                                                                "2px",
                                                            },
                                                            attrs: {
                                                              clearable: "",
                                                            },
                                                            model: {
                                                              value:
                                                                item.verifyCode,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "verifyCode",
                                                                    typeof $$v ===
                                                                      "string"
                                                                      ? $$v.trim()
                                                                      : $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item.verifyCode",
                                                            },
                                                          }),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                display: "flex",
                                                                "justify-content":
                                                                  "center",
                                                              },
                                                            },
                                                            [
                                                              _c("img", {
                                                                staticStyle: {
                                                                  width:
                                                                    "100px",
                                                                  height:
                                                                    "28px",
                                                                },
                                                                attrs: {
                                                                  src:
                                                                    "data:image/png;base64," +
                                                                    item.imageBase64,
                                                                  alt: "",
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    )
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              staticClass: "dialog-footer",
                              staticStyle: {
                                display: "flex",
                                "justify-content": "center",
                                "line-height": "40px",
                                padding: "8px 0px",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    type: "primary",
                                    icon: "el-icon-check",
                                    disabled:
                                      _vm.form.currentFlowNode &&
                                      _vm.form.currentFlowNode
                                        .imageVerifyCode &&
                                      !_vm.form.currentFlowNode
                                        .imageVerifyCode[0].verifyCode,
                                  },
                                  on: { click: _vm.confirmImgVerify },
                                },
                                [_vm._v(" 确认并核保 ")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    icon: "el-icon-close",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.showImgVerifyDialog = false
                                    },
                                  },
                                },
                                [_vm._v(" 取消 ")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "el-dialog",
                        {
                          class: "car-insurance-ui-dialog",
                          attrs: {
                            visible: _vm.showConfirmQuoteDialog,
                            width: "640px",
                            "close-on-click-modal": false,
                            "modal-append-to-body": false,
                          },
                          on: {
                            "update:visible": function ($event) {
                              _vm.showConfirmQuoteDialog = $event
                            },
                            handleClose: _vm.cancel,
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "title",
                              attrs: { slot: "title" },
                              slot: "title",
                            },
                            [
                              _c("FormLabel", {
                                attrs: { "form-label": "投保信息确认" },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                padding: "0px 45px",
                                "max-height": "500px",
                                "line-height": "2",
                                color: "#333",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "font-size": "16px",
                                    display: "flex",
                                    "justify-content": "space-between",
                                    "align-items": "center",
                                  },
                                },
                                [
                                  _c("div", [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "background-color": "red",
                                          color: "#fff",
                                          "font-size": "18px",
                                          padding: "5px",
                                          "margin-right": "5px",
                                          "font-weight": "600",
                                        },
                                      },
                                      [_vm._v("重要")]
                                    ),
                                    _c(
                                      "span",
                                      { staticStyle: { "font-weight": "800" } },
                                      [
                                        _vm._v(
                                          "请确认以下信息是否正确，如果有误，将影响报价;"
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _vm.cutDown > 0
                                    ? _c(
                                        "div",
                                        { staticStyle: { color: "red" } },
                                        [
                                          _vm._v("确认倒计时："),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "font-weight": "700",
                                              },
                                            },
                                            [_vm._v(_vm._s(_vm.cutDown))]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "font-size": "14px",
                                              },
                                            },
                                            [_vm._v("s")]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _c("div", [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                                _c(
                                  "span",
                                  { staticStyle: { "font-weight": "800" } },
                                  [_vm._v("车架号：")]
                                ),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.form.quoteVehicle.vin || "-")
                                  ),
                                ]),
                              ]),
                              _c("div", [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                                _c(
                                  "span",
                                  { staticStyle: { "font-weight": "800" } },
                                  [_vm._v("能源类型：")]
                                ),
                                _vm._v(
                                  _vm._s(
                                    _vm.form.quoteVehicle.fuelTypeName || "燃油"
                                  )
                                ),
                              ]),
                              _c("div", [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                                _c(
                                  "span",
                                  { staticStyle: { "font-weight": "800" } },
                                  [_vm._v("注册日期：")]
                                ),
                                _vm._v(
                                  _vm._s(
                                    _vm.form.quoteVehicle.registerDate &&
                                      _vm.form.quoteVehicle.registerDate.split(
                                        " "
                                      )[0]
                                  )
                                ),
                              ]),
                              _c("div", [
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v("*"),
                                ]),
                                _c(
                                  "span",
                                  { staticStyle: { "font-weight": "800" } },
                                  [_vm._v("车型价格：")]
                                ),
                                _vm._v(
                                  _vm._s(
                                    _vm.getProposalVehicleType(
                                      _vm.form.quoteVehicle
                                    )
                                  )
                                ),
                              ]),
                              _vm.form.isBz
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        color: "red",
                                        "font-size": "16px",
                                        "font-weight": "800",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "*交强险保险期间：" +
                                          _vm._s(
                                            _vm.form.bzEffectiveStartTime
                                              .length > 10
                                              ? _vm.form.bzEffectiveStartTime
                                              : _vm.form.bzEffectiveStartTime +
                                                  " " +
                                                  _vm.form.bzStartTime
                                          ) +
                                          " ~ " +
                                          _vm._s(
                                            _vm.form.bzEffectiveEndTime.length >
                                              10
                                              ? _vm.form.bzEffectiveEndTime
                                              : _vm.form.bzEffectiveEndTime +
                                                  " " +
                                                  _vm.form.bzEndTime
                                          )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.form.isBs
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        color: "red",
                                        "font-size": "16px",
                                        "font-weight": "800",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "*商业险保险期间：" +
                                          _vm._s(
                                            _vm.form.bsEffectiveStartTime
                                              .length > 10
                                              ? _vm.form.bsEffectiveStartTime
                                              : _vm.form.bsEffectiveStartTime +
                                                  " " +
                                                  _vm.form.bsStartTime
                                          ) +
                                          " ~ " +
                                          _vm._s(
                                            _vm.form.bsEffectiveEndTime.length >
                                              10
                                              ? _vm.form.bsEffectiveEndTime
                                              : _vm.form.bsEffectiveEndTime +
                                                  " " +
                                                  _vm.form.bsEndTime
                                          )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "dialog-footer",
                              staticStyle: {
                                display: "flex",
                                "justify-content": "center",
                              },
                              attrs: { slot: "footer" },
                              slot: "footer",
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    type: "primary",
                                    icon: "el-icon-check",
                                  },
                                  on: { click: _vm.confirmQuote },
                                },
                                [_vm._v(" 确认 ")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    icon: "el-icon-close",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.showConfirmQuoteDialog = false
                                    },
                                  },
                                },
                                [_vm._v(" 取消 ")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm.isShowFollowBtn && _vm.opType != "insure"
            ? _c(
                "div",
                { staticClass: "suspension-btn-box" },
                [
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content: "录入跟进",
                        placement: "left",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "followUpBtn",
                          on: {
                            click: function ($event) {
                              _vm.showFollowDialog = true
                            },
                          },
                        },
                        [
                          _c("svg-icon", {
                            staticStyle: { "font-size": "20px" },
                            attrs: { "icon-class": "genjin" },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._.toLower(_vm.currentInsCrop.insCorpCode) == "picc" ||
                  _vm._.toLower(_vm.currentInsCrop.insCorpCode) == "cpic"
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: _vm.currentInsCrop.insCorpName,
                                placement: "left",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "followUpBtn",
                                  on: { click: _vm.jumpInsCorp },
                                },
                                [
                                  _c("svg-icon", {
                                    staticStyle: { "font-size": "22px" },
                                    attrs: { "icon-class": "baoxian" },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ]),
        _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
          _vm.opType == "insure"
            ? _c("div", { staticClass: "footer-btn" }, [
                _c(
                  "div",
                  { staticClass: "footer-btn-list" },
                  [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.open != "dialog",
                            expression: "open != 'dialog'",
                          },
                        ],
                        attrs: { size: "mini", icon: "el-icon-back" },
                        on: { click: _vm.goBack },
                      },
                      [_vm._v(" 返回 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.open == "dialog",
                            expression: "open == 'dialog'",
                          },
                        ],
                        attrs: { size: "mini", icon: "el-icon-close" },
                        on: { click: _vm.closeDialog },
                      },
                      [_vm._v(" 关闭 ")]
                    ),
                    _vm.showUpDateInsureBtn
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              disabled: _vm.isClick == "save",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.save(false)
                              },
                            },
                          },
                          [_vm._v(" 暂存 ")]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "mini",
                          plain: "",
                          disabled: !_vm.form.quoteVehicle.vehicleType,
                        },
                        on: { click: _vm.openNoVehicleDialog },
                      },
                      [_vm._v(" 管理驾意险 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", plain: "", type: "primary" },
                        on: { click: _vm.viewRenewal },
                      },
                      [_vm._v(" 查看续保 ")]
                    ),
                    _vm.showUpDateInsureBtn
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "primary",
                              disabled: _vm.isClick == "submitQuote",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.submitQuote("init")
                              },
                            },
                          },
                          [_vm._v("立即报价")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.opType != "insure"
            ? _c("div", { staticClass: "footer-btn" }, [
                _c(
                  "div",
                  { staticClass: "footer-btn-list" },
                  [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.open != "dialog",
                            expression: "open != 'dialog'",
                          },
                        ],
                        attrs: { size: "mini", icon: "el-icon-back" },
                        on: { click: _vm.goBack },
                      },
                      [_vm._v(" 返回 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.open == "dialog",
                            expression: "open == 'dialog'",
                          },
                        ],
                        attrs: { size: "mini", icon: "el-icon-close" },
                        on: { click: _vm.closeDialog },
                      },
                      [_vm._v(" 关闭 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "primary", plain: "" },
                        on: { click: _vm.viewRenewal },
                      },
                      [_vm._v(" 查看续保 ")]
                    ),
                    (_vm.currentInsCrop.proposalStatus ==
                      _vm.dictSource["INSURED_STATUS_DROPDOWN"][4].dictCode ||
                      _vm.currentInsCrop.proposalStatus ==
                        _vm.dictSource["INSURED_STATUS_DROPDOWN"][8].dictCode ||
                      _vm.currentInsCrop.proposalStatus ==
                        _vm.dictSource["INSURED_STATUS_DROPDOWN"][6].dictCode ||
                      _vm.currentInsCrop.proposalStatus ==
                        _vm.dictSource["INSURED_STATUS_OTHER"][2].dictCode) &&
                    _vm.showPayBtn
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary", plain: "" },
                            on: { click: _vm.toPrint },
                          },
                          [_vm._v("生成报价单")]
                        )
                      : _vm._e(),
                    _vm.currentInsCrop.proposalStatus ==
                      _vm.dictSource["INSURED_STATUS_DROPDOWN"][5].dictCode &&
                    _vm.currentInsCrop.proposalStatus !=
                      _vm.dictSource["INSURED_STATUS_DROPDOWN"][3].dictCode &&
                    _vm.currentInsCrop.proposalStatus !=
                      _vm.dictSource["INSURED_STATUS_DROPDOWN"][9].dictCode &&
                    _vm.showPayBtn
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary", plain: "" },
                            on: {
                              click: function ($event) {
                                return _vm.uploading(true)
                              },
                            },
                          },
                          [_vm._v(" 上传影像 ")]
                        )
                      : _vm._e(),
                    _vm.currentInsCrop.proposalStatus !=
                      _vm.dictSource["INSURED_STATUS_DROPDOWN"][5].dictCode &&
                    _vm.currentInsCrop.proposalStatus !=
                      _vm.dictSource["INSURED_STATUS_DROPDOWN"][3].dictCode &&
                    _vm.currentInsCrop.proposalStatus !=
                      _vm.dictSource["INSURED_STATUS_DROPDOWN"][9].dictCode
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary", plain: "" },
                            on: {
                              click: function ($event) {
                                return _vm.uploading(false)
                              },
                            },
                          },
                          [_vm._v(" 上传影像 ")]
                        )
                      : _vm._e(),
                    _vm.currentInsCrop &&
                    _vm._.toLower(_vm.currentInsCrop.insCorpCode) == "paic" &&
                    (_vm.currentInsCrop.proposalStatus ==
                      _vm.dictSource["INSURED_STATUS_DROPDOWN"][3].dictCode ||
                      _vm.currentInsCrop.proposalStatus ==
                        _vm.dictSource["INSURED_STATUS_DROPDOWN"][4].dictCode)
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary", plain: "" },
                            on: {
                              click: function ($event) {
                                _vm.showTongRongDialog = true
                              },
                            },
                          },
                          [_vm._v(" 承保条件变更申请 ")]
                        )
                      : _vm._e(),
                    _vm.currentInsCrop &&
                    _vm.currentInsCrop.proposalStatus ==
                      _vm.dictSource["INSURED_STATUS_DROPDOWN"][3].dictCode &&
                    _vm.showPayBtn
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.reQuote()
                              },
                            },
                          },
                          [_vm._v("重新报价")]
                        )
                      : _vm._e(),
                    _vm._.toLower(_vm.currentInsCrop.insCorpCode) == "picc" &&
                    ((_vm.currentBzInsItem &&
                      !_vm.currentBzInsItem.proposalNo) ||
                      (_vm.currentBsInsItem &&
                        !_vm.currentBsInsItem.proposalNo)) &&
                    _vm.currentInsCrop.proposalStatus ==
                      _vm.dictSource["INSURED_STATUS_DROPDOWN"][4].dictCode &&
                    _vm.showPayBtn
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "primary",
                              disabled: _vm.isClickUnderwriting,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.openUnderwriting(false, true)
                              },
                            },
                          },
                          [_vm._v("转投保")]
                        )
                      : _vm._e(),
                    _vm.currentInsCrop.proposalStatus ==
                      _vm.dictSource["INSURED_STATUS_DROPDOWN"][4].dictCode &&
                    _vm.showPayBtn
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "primary",
                              disabled: _vm.isClickUnderwriting,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.openUnderwriting(false)
                              },
                            },
                          },
                          [_vm._v("去核保")]
                        )
                      : _vm._e(),
                    (_vm._.toLower(_vm.currentInsCrop.insCorpCode) != "picc" ||
                      (_vm._.toLower(_vm.currentInsCrop.insCorpCode) ==
                        "picc" &&
                        _vm.currentInsCrop.insCityCode == "440300" &&
                        _vm.currentFlowNode &&
                        _vm.currentFlowNode.code == "SzShuangLu")) &&
                    ((_vm.currentInsCrop.proposalStatus ==
                      _vm.dictSource["INSURED_STATUS_DROPDOWN"][5].dictCode &&
                      _vm.currentFlowNode &&
                      _vm.currentFlowNode.code != "ImageUpload") ||
                      _vm.currentInsCrop.proposalStatus ==
                        _vm.dictSource["INSURED_STATUS_DROPDOWN"][7]
                          .dictCode) &&
                    _vm.showPayBtn
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary" },
                            on: { click: _vm.reUnderwriting },
                          },
                          [_vm._v("重新核保")]
                        )
                      : _vm._e(),
                    ((_vm.currentBzInsItem &&
                      _vm.currentBzInsItem.proposalNo) ||
                      (_vm.currentBsInsItem &&
                        _vm.currentBsInsItem.proposalNo)) &&
                    _vm.currentInsCrop.proposalStatus !=
                      _vm.dictSource["INSURED_STATUS_DROPDOWN"][10].dictCode &&
                    _vm.showPayBtn
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "danger",
                              plain: "",
                              disabled:
                                _vm._.toLower(_vm.currentInsCrop.insCorpCode) ==
                                  "tmnf" &&
                                _vm.currentInsCrop.proposalStatus !=
                                  _vm.dictSource["INSURED_STATUS_DROPDOWN"][6]
                                    .dictCode &&
                                _vm.currentInsCrop.proposalStatus !=
                                  _vm.dictSource["INSURED_STATUS_DROPDOWN"][8]
                                    .dictCode,
                            },
                            on: { click: _vm.repealProposal },
                          },
                          [_vm._v("撤销投保")]
                        )
                      : _vm._e(),
                    (_vm.currentInsCrop.proposalStatus ==
                      _vm.dictSource["INSURED_STATUS_DROPDOWN"][8].dictCode ||
                      _vm.currentInsCrop.proposalStatus ==
                        _vm.dictSource["INSURED_STATUS_OTHER"][2].dictCode) &&
                    _vm.showPayBtn
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "danger" },
                            on: { click: _vm.toPay },
                          },
                          [_vm._v("去付款")]
                        )
                      : _vm._e(),
                    (_vm._.toLower(_vm.currentInsCrop.insCorpCode) == "picc" ||
                      _vm._.toLower(_vm.currentInsCrop.insCorpCode) ==
                        "cpic") &&
                    _vm.currentInsCrop.proposalStatus ==
                      _vm.dictSource["INSURED_STATUS_DROPDOWN"][9].dictCode &&
                    _vm.canDownloadPolicy
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "5px" },
                            attrs: {
                              slot: "reference",
                              plain: "",
                              size: "mini",
                              type: "primary",
                            },
                            on: { click: _vm.viewProposalVOList },
                            slot: "reference",
                          },
                          [_vm._v(" 查看/下载电子单证 ")]
                        )
                      : _c(
                          "el-popover",
                          {
                            attrs: { placement: "top-start", trigger: "click" },
                          },
                          [
                            _vm._l(_vm.proposalVOList, function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticStyle: {
                                    width: "100%",
                                    display: "flex",
                                    "justify-content": "center",
                                    margin: "5px 0",
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            ((item.riskType == "2" &&
                                              _vm.canDownloadNonPolicy) ||
                                              item.riskType != "2") &&
                                            item.policyNo,
                                          expression:
                                            "((item.riskType == '2' && canDownloadNonPolicy) || (item.riskType != '2')) && item.policyNo",
                                        },
                                      ],
                                      attrs: {
                                        icon: "el-icon-view",
                                        plain: "",
                                        size: "mini",
                                        type: "primary",
                                        loading: item.loading,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.view(item)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            item.loading ? "正在打开" : "查看"
                                          ) +
                                          _vm._s(
                                            item.riskType == "0"
                                              ? "交强险"
                                              : item.riskType == "1"
                                              ? "商业险"
                                              : "驾意险"
                                          ) +
                                          "保单 "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            }),
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.currentInsCrop.proposalStatus ==
                                        _vm.dictSource[
                                          "INSURED_STATUS_DROPDOWN"
                                        ][9].dictCode && _vm.canDownloadPolicy,
                                    expression:
                                      "(currentInsCrop.proposalStatus == dictSource['INSURED_STATUS_DROPDOWN'][9].dictCode) && canDownloadPolicy",
                                  },
                                ],
                                staticStyle: { "margin-left": "5px" },
                                attrs: {
                                  slot: "reference",
                                  size: "mini",
                                  type: "primary",
                                },
                                on: { click: _vm.viewProposalVOList },
                                slot: "reference",
                              },
                              [_vm._v(" 查看/下载电子单证 ")]
                            ),
                          ],
                          2
                        ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]),
      ]),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showViewRenewalDialog,
            "close-on-click-modal": false,
            width: "1000px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showViewRenewalDialog = $event
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "16px", "font-weight": "700" } },
                [_vm._v("续保保单详情")]
              ),
            ]
          ),
          _c("ViewRenewalDialog", {
            attrs: {
              refresh: _vm.showViewRenewalDialog,
              viewRenewalId: _vm.viewRenewalId,
              viewRenewalFormData: _vm.viewRenewalFormData,
            },
            on: {
              closePage: function ($event) {
                _vm.showViewRenewalDialog = false
              },
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showFollowDialog,
            "close-on-click-modal": false,
            width: "640px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showFollowDialog = $event
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("跟进新增")]
              ),
            ]
          ),
          _c("FollowUpDialog", {
            attrs: {
              refresh: _vm.showFollowDialog,
              customerId: _vm.followCustomerId,
            },
            on: { closePage: _vm.fetchCluesId },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showQueryRenewalDialog,
            "close-on-click-modal": false,
            width: "740px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showQueryRenewalDialog = $event
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("检查续保")]
              ),
            ]
          ),
          _c("QueryRenewalDialog", {
            attrs: {
              orgCode: _vm.form.salesOrgCode,
              insCityCode: _vm.form.insCityCode,
              vehicle: _vm.form.quoteVehicle,
              refresh: _vm.showQueryRenewalDialog,
            },
            on: {
              submitRenewalData: _vm.submitRenewalData,
              close: function ($event) {
                _vm.showQueryRenewalDialog = false
              },
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "special_dialog",
          attrs: {
            visible: _vm.showUnderwritingSpecial,
            "close-on-click-modal": false,
            width: "1024px",
            "modal-append-to-body": false,
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showUnderwritingSpecial = $event
            },
            handleClose: function ($event) {
              _vm.showUnderwritingSpecial = false
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "title", attrs: { slot: "title" }, slot: "title" },
            [_c("FormLabel", { attrs: { "form-label": "补充特别约定" } })],
            1
          ),
          _c("UnderwritingSpecialterms", {
            attrs: {
              "special-list": _vm.underwritingSpecial,
              refresh: _vm.showUnderwritingSpecial,
            },
            on: { closeSpecial: _vm.closeUnderwritingSpecial },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showTongRongDialog,
            "close-on-click-modal": false,
            width: "900px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showTongRongDialog = $event
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("承保条件变更申请")]
              ),
            ]
          ),
          _c(
            "div",
            { staticStyle: { padding: "15px 5px" } },
            [
              _c("PaicAccommodationNode", {
                staticStyle: { background: "white" },
                attrs: {
                  quoteId: _vm.quoteId && _vm.quoteId.toString(),
                  refresh: _vm.showTongRongDialog,
                  proposalId:
                    _vm.currentInsCrop && _vm.currentInsCrop.proposalId,
                  showPayBtn: _vm.showPayBtn,
                },
                on: { callBack: _vm.accommodationToQuote },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            title: "温馨提示",
            visible: _vm.downloadPolicy,
            width: "45%",
            "close-on-click-modal": false,
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.downloadPolicy = $event
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("温馨提示")]
              ),
            ]
          ),
          _c(
            "div",
            { staticStyle: { padding: "10px 0px" } },
            [
              _c("el-card", { attrs: { shadow: "never" } }, [
                _c("div", {
                  domProps: { innerHTML: _vm._s(_vm.downloadPolicyTips) },
                }),
              ]),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "center",
                    "margin-top": "10px",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini", plain: "" },
                      on: {
                        click: function ($event) {
                          _vm.downloadPolicy = false
                        },
                      },
                    },
                    [_vm._v("好的，我知道了")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showPreview,
            "close-on-click-modal": false,
            width: "1024px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showPreview = $event
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("查看解决方案")]
              ),
            ]
          ),
          _c("div", [
            _c("iframe", {
              style: { width: "1000px", height: "calc(100vh - 210px)" },
              attrs: {
                id: "previewIframe",
                src: _vm.previewUrl,
                frameborder: "0",
              },
            }),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showIdentificationDialog,
            "close-on-click-modal": false,
            width: "800px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showIdentificationDialog = $event
            },
            close: _vm.closeIdentificationDialog,
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("证件识别")]
              ),
            ]
          ),
          _c("IdentificationDialog", {
            attrs: {
              OrgOCRFunctionList: _vm.OrgOCRFunctionList,
              orgCode: _vm.form.salesOrgCode,
              relationshipAttribute: _vm.formRelationshipAttribute,
              allOCRFunctionList: _vm.allOCRFunctionList,
              IdentificationType: _vm.IdentificationType,
              editType: "add",
              FormSalesOrgCode: _vm.form.salesOrgCode,
              refresh: _vm.showIdentificationDialog,
            },
            on: {
              getFormData: _vm.getFormsData,
              close: _vm.closeIdentificationDialog,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }