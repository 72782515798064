import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import dict from '@/mixins/dict';
import Pagination from '@/components/Pagination';
import DatePicker from '@/components/DatePicker';
import customersRequest from '@/api/customers';
import CustomerImportListDialog from './dialog/customerImportListDialog';
import request from '@/api/dictionary';
import FollowUpDialog from '@/views/leadManagement/dialog/followUpDialog';
import AddDialog from './dialog/addDialog';
import dictRequest from '@/api/dictionary';
import CarBrands from '@/components/CarBrands/index';
import { getCurrentDate, getBeforeDate, character, commonExport, getSupplyers } from '@/utils/common';
export default {
  name: "MyCustomers",
  components: {
    Pagination: Pagination,
    CustomerImportListDialog: CustomerImportListDialog,
    FollowUpDialog: FollowUpDialog,
    AddDialog: AddDialog,
    CarBrands: CarBrands,
    DatePicker: DatePicker
  },
  mixins: [initHeight, dict],
  watch: {
    drawer: function drawer(val) {
      if (val) {
        this.supplyers = getSupplyers();
      }
    }
  },
  computed: {},
  data: function data() {
    return {
      createdStartEndTime: [getBeforeDate(6), getCurrentDate(0)],
      carTypeVal: [],
      codeList: [{
        code: "TRACKING",
        name: "跟进中"
      }, {
        code: "TRACK_SUCCEED",
        name: "成功出单"
      }, {
        code: "TRACK_DEFEAT",
        name: "战败"
      }, {
        code: "TRACK_INVALID",
        name: "无效"
      }],
      supplyers: [],
      renewalTypeList: [],
      trackStatuslist: [],
      activeName: "All",
      drawer: false,
      searchForm: {
        trackTabType: 'All',
        noBzLastYearEndDate: false,
        noBsLastYearEndDate: false
      },
      form: {},
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      multipleSelection: [],
      opType: '',
      startEndTime: [getBeforeDate(30), getCurrentDate()],
      loading: false,
      showAddTracksDialog: false,
      customerItem: {},
      showAddDialog: false,
      showCustomerDialog: false,
      overdueStartEndTime: [],
      noMaturityDate: [],
      newestTrackStartEndTime: [],
      newestSubscribeTime: [],
      registerStartEndTime: [],
      NextTrackNum: 0,
      NoTrackNum: 0,
      OverdueDisposedNum: 0,
      OverdueTrackNum: 0,
      TodayTrackNum: 0,
      bizType: localStorage.getItem("bizType") || ''
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.getMyCustomerSummary();
    this.loadData();
    this.getRenewalTypeList();
  },
  methods: {
    getMyCustomerSummary: function getMyCustomerSummary() {
      var _this = this;
      if (this.searchForm && this.searchForm.startCreatedDate) {
        this.searchForm.startCreatedDate = this.searchForm.startCreatedDate.split(' ')[0] + ' 00:00:00';
      }
      if (this.searchForm && this.searchForm.endCreatedDate) {
        this.searchForm.endCreatedDate = this.searchForm.endCreatedDate.split(' ')[0] + ' 23:59:59';
      }
      if (this.searchForm && this.searchForm.startOverdueDate) {
        this.searchForm.startOverdueDate = this.searchForm.startOverdueDate.split(' ')[0] + ' 00:00:00';
      }
      if (this.searchForm && this.searchForm.endOverdueDate) {
        this.searchForm.endOverdueDate = this.searchForm.endOverdueDate.split(' ')[0] + ' 23:59:59';
      }
      if (this.searchForm && this.searchForm.startNewestTrackTime) {
        this.searchForm.startNewestTrackTime = this.searchForm.startNewestTrackTime.split(' ')[0] + ' 00:00:00';
      }
      if (this.searchForm && this.searchForm.endNewestTrackTime) {
        this.searchForm.endNewestTrackTime = this.searchForm.endNewestTrackTime.split(' ')[0] + ' 23:59:59';
      }
      if (this.searchForm && this.searchForm.startRegisterDate) {
        this.searchForm.startRegisterDate = this.searchForm.startRegisterDate.split(' ')[0] + ' 00:00:00';
      }
      if (this.searchForm && this.searchForm.endRegisterDate) {
        this.searchForm.endRegisterDate = this.searchForm.endRegisterDate.split(' ')[0] + ' 23:59:59';
      }
      this.loading = true;
      this.drawer = false;
      this.showCustomerDialog = false;
      this.showAddTracksDialog = false;
      this.showAddDialog = false;
      customersRequest.getMyCustomerSummary(this.listQuery.pageNum, this.listQuery.pageSize, this.searchForm).then(function (res) {
        _this.loading = false;
        if (res.code == 200) {
          _this.NextTrackNum = res.data && res.data.NextTrack;
          _this.NoTrackNum = res.data && res.data.NoTrack;
          _this.OverdueDisposedNum = res.data && res.data.OverdueDisposed;
          _this.OverdueTrackNum = res.data && res.data.OverdueTrack;
          _this.TodayTrackNum = res.data && res.data.TodayTrack;
        }
      }).catch(function (err) {
        _this.loading = false;
      });
    },
    // 监听数据变化
    CarHandleChange: function CarHandleChange(val) {
      this.searchForm.cjVhcBrandNo = val[0]; // 厂家品牌编号
      this.searchForm.cjVhcSeriesNo = val[1]; // 厂家车型编号
      this.carTypeVal = val;
      // cjVhcModelNo: val[2], //  厂家车型编号
    },
    //获取续客户类别
    getRenewalTypeList: function getRenewalTypeList() {
      var _this2 = this;
      dictRequest.getDictItemsByCode("RENEWAL_TYPE").then(function (res) {
        if (res.data) {
          _this2.renewalTypeList = res.data;
        }
      });
    },
    breakData: function breakData() {
      // this.resetSearch();
      this.loadData();
    },
    // 导出
    exportFn: function exportFn() {
      var exporParams = {
        total: this.total,
        limitNum: 200000,
        fileName: '我的客户',
        exportUrl: '/vi-core-service/coreCustomer/customers',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    customerImport: function customerImport() {
      //客户导入
      this.showCustomerDialog = true;
    },
    inputPlateNo: function inputPlateNo(val) {
      //自动将车牌号码转为大写
      this.searchForm.plateNo = _.toUpper(val);
    },
    inputvin: function inputvin(val) {
      //监听输入事件
      if (val) {
        val = val.replace(/i/g, '').replace(/I/g, '').replace(/o/g, '').replace(/O/g, '').replace(/q/g, '').replace(/Q/g, '');
        this.searchForm.vin = character(_.toUpper(val));
        this.$forceUpdate();
      }
    },
    toQuote: function toQuote(item) {
      //报价
      sessionStorage.removeItem("quoateForm");
      var router_path = this.$route.path;
      this.$router.push({
        path: "/proposal/add",
        query: {
          back: router_path,
          plateNo: item.plateNo,
          vin: item.vin,
          ownerCertificateNo: item.ownerCertificateNo,
          ownerMobile: item.ownerMobile,
          engineNo: item.engineNo,
          ownerOtherMobile: item.ownerMobile,
          modelName: item.modelName,
          ownerName: item.ownerName,
          customerId: item.customerId || null
        }
      });
    },
    // 呼叫
    callCustomer: function callCustomer(item) {},
    // 跟进
    customerTracks: function customerTracks(item) {
      this.showAddTracksDialog = true;
      this.customerItem = item;
    },
    // tab切换事件
    handleTabClick: function handleTabClick(tab, event) {
      this.listQuery = {
        pageNum: 1,
        pageSize: 20
      };
      this.loadData();
    },
    getPickersDate: function getPickersDate(name, val) {
      if (val && val.length > 1) {
        this[name] = val;
      } else {
        this[name] = [];
      }
    },
    // 日期选择事件
    dateChange: function dateChange(start, end, val, type) {
      if (val && val.length > 1) {
        this.searchForm[start] = val[0];
        this.searchForm[end] = val[1];
      } else {
        if (type === 'overdueStartEnd') {
          // 保险到期日
          this.searchForm.startOverdueDate = '';
          this.searchForm.endOverdueDate = '';
        } else if (type === 'newestTrackStartEnd') {
          // 最新跟进时间
          this.searchForm.startNewestTrackTime = '';
          this.searchForm.endNewestTrackTime = '';
        } else if (type === 'registerStartEnd') {
          // 注册日期
          this.searchForm.startRegisterDate = '';
          this.searchForm.endRegisterDate = '';
        } else if (type === 'newestSubscribeTime') {
          // 下次跟进时间
          this.searchForm.startNewestSubscribeTime = null;
          this.searchForm.endNewestSubscribeTime = null;
        }
      }
    },
    ChangeNoMaturityDate: function ChangeNoMaturityDate(val) {
      if (val.length) {
        this.searchForm.noBzLastYearEndDate = val.includes('noBzLastYearEndDate');
        this.searchForm.noBsLastYearEndDate = val.includes('noBsLastYearEndDate');
      } else {
        this.searchForm.noBzLastYearEndDate = false;
        this.searchForm.noBsLastYearEndDate = false;
      }
    },
    // 查看客户详情
    viewDetail: function viewDetail(item) {
      this.$router.push({
        path: '/customers/detail',
        query: {
          customerId: item.customerId,
          tabName: "\u5BA2\u6237\u8BE6\u60C5-".concat(item.ownerName, "(").concat(item.vin, ")"),
          currentType: 'my'
        }
      });
    },
    // 重置搜索表单
    resetSearch: function resetSearch() {
      this.searchForm = {
        trackTabType: 'All',
        noBzLastYearEndDate: false,
        noBsLastYearEndDate: false
      };
      this.carTypeVal = [];
      this.overdueStartEndTime = [];
      this.newestTrackStartEndTime = [];
      this.newestSubscribeTime = [];
      this.registerStartEndTime = [];
      this.createdStartEndTime = [getBeforeDate(6), getCurrentDate(0)];
      this.noMaturityDate = [];
    },
    // 搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    // 双击打开详情
    dbSelected: function dbSelected(item) {
      this.viewDetail(item);
    },
    // 获取列表数据
    loadData: function loadData() {
      var _this3 = this;
      if (this.newestTrackStartEndTime) {
        this.searchForm.startNewestTrackTime = this.newestTrackStartEndTime[0] ? this.newestTrackStartEndTime[0].split(' ')[0] + ' 00:00:00' : null;
        this.searchForm.endNewestTrackTime = this.newestTrackStartEndTime[1] ? this.newestTrackStartEndTime[1].split(' ')[0] + ' 23:59:59' : null;
      }
      if (this.newestSubscribeTime) {
        this.searchForm.startNewestSubscribeTime = this.newestSubscribeTime[0] ? this.newestSubscribeTime[0].split(' ')[0] + ' 00:00:00' : null;
        this.searchForm.endNewestSubscribeTime = this.newestSubscribeTime[1] ? this.newestSubscribeTime[1].split(' ')[0] + ' 23:59:59' : null;
      }
      if (this.registerStartEndTime) {
        this.searchForm.startRegisterDate = this.registerStartEndTime[0] ? this.registerStartEndTime[0].split(' ')[0] + ' 00:00:00' : null;
        this.searchForm.endRegisterDate = this.registerStartEndTime[1] ? this.registerStartEndTime[1].split(' ')[0] + ' 23:59:59' : null;
      }
      if (this.overdueStartEndTime) {
        this.searchForm.startOverdueDate = this.overdueStartEndTime[0] ? this.overdueStartEndTime[0].split(' ')[0] + ' 00:00:00' : null;
        this.searchForm.endOverdueDate = this.overdueStartEndTime[1] ? this.overdueStartEndTime[1].split(' ')[0] + ' 23:59:59' : null;
      }
      if (this.createdStartEndTime) {
        this.searchForm.startCreatedDate = this.createdStartEndTime[0] ? this.createdStartEndTime[0].split(' ')[0] + ' 00:00:00' : null;
        this.searchForm.endCreatedDate = this.createdStartEndTime[1] ? this.createdStartEndTime[1].split(' ')[0] + ' 23:59:59' : null;
      }
      this.loading = true;
      this.drawer = false;
      this.showCustomerDialog = false;
      this.showAddTracksDialog = false;
      this.showAddDialog = false;
      customersRequest.getList(this.listQuery.pageNum, this.listQuery.pageSize, this.searchForm).then(function (res) {
        _this3.loading = false;
        _this3.tableData = res.data.list;
        // 总条数
        _this3.total = res.data.page.recordsTotal;
      }).catch(function (err) {
        _this3.loading = false;
      });
    },
    searchOpen: function searchOpen() {
      this.drawer = true;
      this.getTrackStatuslist();
    },
    // 列表行选择事件
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    getTrackStatuslist: function getTrackStatuslist() {
      var _this4 = this;
      var reqList = [];
      this.codeList.forEach(function (item) {
        reqList.push(request.getDictItemsByCode(item.code));
      });
      request.requestAll(reqList).then(function (resData) {
        _this4.trackStatuslist = resData.map(function (item, index) {
          var obj = {
            label: '',
            options: []
          };
          obj.label = _this4.codeList[index].name;
          obj.options = item.data;
          return obj;
        });
      });
    },
    // 弹窗关闭回调
    closeDialog: function closeDialog(val) {
      this.showAddDialog = false;
      this.showAddTracksDialog = false;
      this.showCustomerDialog = false;
      if (!val) {
        this.loadData();
      }
    }
  }
};