//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PremiumBarChart from './components/premiumBarChart';
import issueLineChart from './components/issueLineChart.vue';
import lineCharts from './components/lineCharts.vue';
import pieCharts from './components/pieCharts.vue';
import initHeight from '../../mixins/initHeight';
export default {
  name: "insuranceAnalysis",
  mixins: [initHeight],
  components: {
    lineCharts: lineCharts,
    PremiumBarChart: PremiumBarChart,
    issueLineChart: issueLineChart,
    pieCharts: pieCharts
  },
  data: function data() {
    return {
      val5: [{
        "chartType": "Pie",
        "name": "保险公司出单量占比",
        "values": [{
          "name": "人保财险",
          "value": 342
        }, {
          "name": "平安财险",
          "value": 656
        }, {
          "name": "阳光财险",
          "value": 13
        }, {
          "name": "中国大地财险",
          "value": 23
        }, {
          "name": "中华联合财险",
          "value": 28
        }, {
          "name": "国寿财险",
          "value": 10
        }, {
          "name": "众诚财险",
          "value": 1391
        }, {
          "name": "太平洋财险",
          "value": 278
        }]
      }, {
        "chartType": "Pie",
        "name": "保险公司保费规模占比",
        "values": [{
          "name": "人保财险",
          "value": 1158158.06
        }, {
          "name": "平安财险",
          "value": 2075748.94
        }, {
          "name": "阳光财险",
          "value": 52479.72
        }, {
          "name": "中国大地财险",
          "value": 102646.57
        }, {
          "name": "中华联合财险",
          "value": 139488.99
        }, {
          "name": "国寿财险",
          "value": 49325.63
        }, {
          "name": "众诚财险",
          "value": 5109974.15
        }, {
          "name": "太平洋财险",
          "value": 5402609.37
        }]
      }],
      val4: [{
        "chartType": "Line",
        "name": "近12个月保费规模趋势",
        "values": [{
          "timeline": "202009",
          "value": 0
        }, {
          "timeline": "202010",
          "value": 0
        }, {
          "timeline": "202011",
          "value": 0
        }, {
          "timeline": "202012",
          "value": 0
        }, {
          "timeline": "202101",
          "value": 0
        }, {
          "timeline": "202102",
          "value": 723.24
        }, {
          "timeline": "202103",
          "value": 0
        }, {
          "timeline": "202104",
          "value": 2601078.71
        }, {
          "timeline": "202105",
          "value": 3206361.9
        }, {
          "timeline": "202106",
          "value": 7523703.2
        }, {
          "timeline": "202107",
          "value": 541422.16
        }, {
          "timeline": "202108",
          "value": 151428.03
        }]
      }, {
        "chartType": "Line",
        "name": "近12个月出单量趋势",
        "values": [{
          "timeline": "202009",
          "value": 0
        }, {
          "timeline": "202010",
          "value": 0
        }, {
          "timeline": "202011",
          "value": 0
        }, {
          "timeline": "202012",
          "value": 0
        }, {
          "timeline": "202101",
          "value": 0
        }, {
          "timeline": "202102",
          "value": 1
        }, {
          "timeline": "202103",
          "value": 0
        }, {
          "timeline": "202104",
          "value": 840
        }, {
          "timeline": "202105",
          "value": 869
        }, {
          "timeline": "202106",
          "value": 847
        }, {
          "timeline": "202107",
          "value": 124
        }, {
          "timeline": "202108",
          "value": 41
        }]
      }],
      val1: [{
        "timeline": "2021-07-31",
        "value": 4
      }, {
        "timeline": "2021-08-01",
        "value": 2
      }, {
        "timeline": "2021-08-02",
        "value": 2
      }, {
        "timeline": "2021-08-03",
        "value": 4
      }, {
        "timeline": "2021-08-04",
        "value": 2
      }, {
        "timeline": "2021-08-05",
        "value": 0
      }, {
        "timeline": "2021-08-06",
        "value": 3
      }, {
        "timeline": "2021-08-07",
        "value": 0
      }, {
        "timeline": "2021-08-08",
        "value": 0
      }, {
        "timeline": "2021-08-09",
        "value": 1
      }, {
        "timeline": "2021-08-10",
        "value": 2
      }, {
        "timeline": "2021-08-11",
        "value": 1
      }, {
        "timeline": "2021-08-12",
        "value": 0
      }, {
        "timeline": "2021-08-13",
        "value": 2
      }, {
        "timeline": "2021-08-14",
        "value": 0
      }, {
        "timeline": "2021-08-15",
        "value": 0
      }, {
        "timeline": "2021-08-16",
        "value": 3
      }, {
        "timeline": "2021-08-17",
        "value": 0
      }, {
        "timeline": "2021-08-18",
        "value": 1
      }, {
        "timeline": "2021-08-19",
        "value": 2
      }, {
        "timeline": "2021-08-20",
        "value": 5
      }, {
        "timeline": "2021-08-21",
        "value": 2
      }, {
        "timeline": "2021-08-22",
        "value": 0
      }, {
        "timeline": "2021-08-23",
        "value": 0
      }, {
        "timeline": "2021-08-24",
        "value": 0
      }, {
        "timeline": "2021-08-25",
        "value": 0
      }, {
        "timeline": "2021-08-26",
        "value": 1
      }, {
        "timeline": "2021-08-27",
        "value": 2
      }, {
        "timeline": "2021-08-28",
        "value": 3
      }, {
        "timeline": "2021-08-29",
        "value": 1
      }, {
        "timeline": "2021-08-30",
        "value": 1
      }, {
        "timeline": "2021-08-31",
        "value": 1
      }],
      val2: [{
        "timeline": "2021-07-31",
        "value": 343.07
      }, {
        "timeline": "2021-08-01",
        "value": 0
      }, {
        "timeline": "2021-08-02",
        "value": 0
      }, {
        "timeline": "2021-08-03",
        "value": 0
      }, {
        "timeline": "2021-08-04",
        "value": 0
      }, {
        "timeline": "2021-08-05",
        "value": 0
      }, {
        "timeline": "2021-08-06",
        "value": 0
      }, {
        "timeline": "2021-08-07",
        "value": 0
      }, {
        "timeline": "2021-08-08",
        "value": 0
      }, {
        "timeline": "2021-08-09",
        "value": 0
      }, {
        "timeline": "2021-08-10",
        "value": 0
      }, {
        "timeline": "2021-08-11",
        "value": 0
      }, {
        "timeline": "2021-08-12",
        "value": 0
      }, {
        "timeline": "2021-08-13",
        "value": 0
      }, {
        "timeline": "2021-08-14",
        "value": 0
      }, {
        "timeline": "2021-08-15",
        "value": 0
      }, {
        "timeline": "2021-08-16",
        "value": 0
      }, {
        "timeline": "2021-08-17",
        "value": 0
      }, {
        "timeline": "2021-08-18",
        "value": 0
      }, {
        "timeline": "2021-08-19",
        "value": 0
      }, {
        "timeline": "2021-08-20",
        "value": 0
      }, {
        "timeline": "2021-08-21",
        "value": 0
      }, {
        "timeline": "2021-08-22",
        "value": 0
      }, {
        "timeline": "2021-08-23",
        "value": 0
      }, {
        "timeline": "2021-08-24",
        "value": 0
      }, {
        "timeline": "2021-08-25",
        "value": 0
      }, {
        "timeline": "2021-08-26",
        "value": 0
      }, {
        "timeline": "2021-08-27",
        "value": 0
      }, {
        "timeline": "2021-08-28",
        "value": 0
      }, {
        "timeline": "2021-08-29",
        "value": 0
      }, {
        "timeline": "2021-08-30",
        "value": 0
      }, {
        "timeline": "2021-08-31",
        "value": 0
      }],
      val3: [{
        key: "总进店车辆数",
        value: 0
      }, {
        key: "总报价次数",
        value: 0
      }, {
        key: "总报价车辆数",
        value: 0
      }, {
        key: "总留修工单数",
        value: 0
      }]
    };
  },
  computed: {
    scrollerHeight: function scrollerHeight() {
      //计算高度 
      return document.body.clientHeight - 100 + 'px';
    }
  }
};