import { debounce } from "./fDebounce";
import { Loading } from 'element-ui'; // 装包方式这样引入
// loading对象
var loading;

// 当前正在请求的数量
var needLoadingRequestCount = 0;

// 显示loading
export function showLoading(loaddingText) {
  // 后面这个判断很重要，因为关闭时加了抖动，此时loading对象可能还存在，
  // 但needLoadingRequestCount已经变成0.避免这种情况下会重新创建个loading
  console.log(needLoadingRequestCount, loading);
  if (needLoadingRequestCount === 0 && !loading) {
    // loading = window.Element.Loading.service({ // 通过cdn引入的element-ui
    loading = Loading.service({
      // 装包方式这样引入
      lock: true,
      text: decodeURIComponent(loaddingText) || "加载中...",
      background: "rgba(255, 255, 255, 0.8)",
      target: "#appMain"
    });
  }
  needLoadingRequestCount++;
}

// 隐藏loading
export function hideLoading() {
  needLoadingRequestCount--;
  needLoadingRequestCount = Math.max(needLoadingRequestCount, 0); // 做个保护
  if (needLoadingRequestCount === 0) {
    // 关闭loading
    toHideLoading();
  }
}

// 防抖：将 300ms 间隔内的关闭 loading 便合并为一次。防止连续请求时， loading闪烁的问题。
var toHideLoading = debounce(function () {
  if (loading) loading.close();
  loading = null;
}, 300);