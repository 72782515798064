var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.form, "label-width": "0" } },
        _vm._l(_vm.form.lists, function (item, index) {
          return _c(
            "div",
            { key: index, staticStyle: { "margin-bottom": "10px" } },
            [
              _c(
                "div",
                { staticClass: "insurance-company-header" },
                [
                  _c("svg-icon", {
                    staticStyle: { "font-size": "24px" },
                    attrs: {
                      "icon-class": "" + _vm._.toLower(item.insCorpCode),
                    },
                  }),
                  _c("span", { staticStyle: { "padding-left": "10px" } }, [
                    _vm._v(
                      _vm._s(
                        item.insCorpName ||
                          _vm.getSupplyerName(item.insCorpCode)
                      )
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "table",
                {
                  staticStyle: { width: "100%", background: "#fff" },
                  attrs: { border: "1" },
                },
                [
                  _c("colgroup", [
                    _c("col", { staticStyle: { width: "35%" } }),
                    _c("col", { staticStyle: { width: "35%" } }),
                    _c("col", { staticStyle: { width: "30%" } }),
                    _c("col"),
                  ]),
                  _c(
                    "thead",
                    { staticStyle: { "background-color": "#eeee" } },
                    [
                      _c("tr", { staticStyle: { "font-size": "13px" } }, [
                        _c("th", { attrs: { scope: "col" } }, [_vm._v("服务")]),
                        _c("th", { attrs: { scope: "col" } }, [_vm._v("项目")]),
                        _c("th", { attrs: { scope: "col" } }, [_vm._v("次数")]),
                      ]),
                    ]
                  ),
                  _vm._l(item.risks, function (risk, i) {
                    return _c(
                      "tbody",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm._.isEmpty(item.risks),
                            expression: "!_.isEmpty(item.risks)",
                          },
                        ],
                        key: i,
                      },
                      [
                        _c("tr", [
                          _c(
                            "td",
                            {
                              attrs: {
                                rowspan: "" + (risk.serviceItems.length + 1),
                              },
                            },
                            [
                              _c(
                                "span",
                                [
                                  _vm._v(_vm._s(risk.riskName) + " "),
                                  risk.riskRemark
                                    ? _c(
                                        "el-popover",
                                        {
                                          attrs: {
                                            placement: "right-end",
                                            width: "550",
                                            trigger: "click",
                                          },
                                        },
                                        [
                                          _c("div", {
                                            staticStyle: {
                                              "max-height": "300px",
                                              overflow: "auto",
                                            },
                                            domProps: {
                                              innerHTML: _vm._s(
                                                risk.riskRemark
                                              ),
                                            },
                                          }),
                                          _c("i", {
                                            staticClass:
                                              "el-icon-warning-outline",
                                            staticStyle: {
                                              "font-size": "16px",
                                              margin: "0 5px",
                                            },
                                            attrs: { slot: "reference" },
                                            slot: "reference",
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]),
                        _vm._l(risk.serviceItems, function (serviceItem, j) {
                          return _c("tr", { key: j }, [
                            _c(
                              "td",
                              { staticStyle: { "text-align": "left" } },
                              [
                                "gpic" == _vm._.toLower(item.insCorpCode) &&
                                risk.riskCode == "FZ02"
                                  ? _c(
                                      "el-checkbox",
                                      {
                                        on: {
                                          change: function ($event) {
                                            return _vm.handleGpicChange(
                                              risk.serviceItems,
                                              serviceItem.serviceCode
                                            )
                                          },
                                        },
                                        model: {
                                          value: serviceItem.selected,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              serviceItem,
                                              "selected",
                                              $$v
                                            )
                                          },
                                          expression: "serviceItem.selected",
                                        },
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(serviceItem.serviceName)
                                          ),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                                "gpic" == _vm._.toLower(item.insCorpCode) &&
                                risk.riskCode != "FZ02"
                                  ? _c(
                                      "span",
                                      { staticStyle: { "margin-left": "5px" } },
                                      [_vm._v(_vm._s(serviceItem.serviceName))]
                                    )
                                  : _vm._e(),
                                "gpic" != _vm._.toLower(item.insCorpCode)
                                  ? _c(
                                      "span",
                                      { staticStyle: { "margin-left": "5px" } },
                                      [_vm._v(_vm._s(serviceItem.serviceName))]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "td",
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          !_vm._.isEmpty(serviceItem.options) &&
                                          risk.multiple,
                                        expression:
                                          "!_.isEmpty(serviceItem.options) && risk.multiple",
                                      },
                                    ],
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          size: "mini",
                                          placeholder: "请选择",
                                          clearable: false,
                                          disabled: serviceItem.readonly,
                                        },
                                        model: {
                                          value: serviceItem.serviceTimes,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              serviceItem,
                                              "serviceTimes",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "serviceItem.serviceTimes",
                                        },
                                      },
                                      _vm._l(
                                        serviceItem.options,
                                        function (option, k) {
                                          return _c("el-option", {
                                            key: k,
                                            attrs: {
                                              label: option,
                                              value: option,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm._.isEmpty(serviceItem.options) &&
                                          risk.multiple,
                                        expression:
                                          "_.isEmpty(serviceItem.options) && risk.multiple",
                                      },
                                    ],
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        size: "mini",
                                        type: "text",
                                        clearable: false,
                                        disabled: serviceItem.readonly,
                                      },
                                      on: {
                                        input: function ($event) {
                                          serviceItem.serviceTimes =
                                            _vm.onInput(
                                              serviceItem.serviceTimes,
                                              0,
                                              1000,
                                              0,
                                              _vm._.toLower(item.insCorpCode),
                                              risk
                                            )
                                        },
                                      },
                                      model: {
                                        value: serviceItem.serviceTimes,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            serviceItem,
                                            "serviceTimes",
                                            $$v
                                          )
                                        },
                                        expression: "serviceItem.serviceTimes",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm._.isEmpty(serviceItem.options) &&
                                          !risk.multiple,
                                        expression:
                                          "_.isEmpty(serviceItem.options) && !risk.multiple",
                                      },
                                    ],
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "text-align": "left",
                                          display: "flex",
                                          "align-items": "center",
                                          "justify-items": "flex-start",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-checkbox",
                                          {
                                            attrs: { label: "次数" },
                                            on: {
                                              change: function ($event) {
                                                return _vm.handleChange(
                                                  risk.serviceItems,
                                                  serviceItem.serviceCode
                                                )
                                              },
                                            },
                                            model: {
                                              value: serviceItem.checked,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  serviceItem,
                                                  "checked",
                                                  $$v
                                                )
                                              },
                                              expression: "serviceItem.checked",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(serviceItem.serviceTimes) +
                                                " 次"
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ])
                        }),
                      ],
                      2
                    )
                  }),
                  _c(
                    "tbody",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm._.isEmpty(item.risks),
                          expression: "_.isEmpty(item.risks)",
                        },
                      ],
                    },
                    [
                      _c("tr", [
                        _c("td", { attrs: { colspan: "3" } }, [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                padding: "15px",
                                "min-height": "60px",
                                "line-height": "60px",
                                color: "red",
                                "text-align": "center",
                              },
                            },
                            [
                              _vm._v(
                                " 暂无可选增值服务项目，请联系管理员配置!!! "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ],
                2
              ),
            ]
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }