var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "app-m-cls",
        staticStyle: { "min-height": "calc(100vh - 84px)" },
      },
      [
        _c(
          "div",
          { staticClass: "table-cls normal-btn-cls" },
          [
            _c(
              "el-card",
              { staticClass: "box-card", attrs: { shadow: "never" } },
              [
                _c(
                  "el-form",
                  {
                    ref: "rulesForm",
                    attrs: {
                      model: _vm.rulesForm,
                      "label-width": "260px",
                      size: "mini",
                    },
                  },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 16 } },
                          [
                            _c("el-form-item", {
                              attrs: {
                                label: "续保跟进客户范围",
                                "label-width": "160px",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { staticStyle: { position: "relative" } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "距保险到期天数" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "请输入距保险到期天数",
                                    clearable: "",
                                  },
                                  on: {
                                    input: function ($event) {
                                      _vm.rulesForm.expireDay = _vm.onInput(
                                        _vm.rulesForm.expireDay,
                                        0,
                                        99999,
                                        0,
                                        "expireDay"
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.rulesForm.expireDay,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.rulesForm, "expireDay", $$v)
                                    },
                                    expression: "rulesForm.expireDay",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 1 } },
                          [
                            _c("el-form-item", {
                              attrs: { label: "内", "label-width": "30px" },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            staticStyle: {
                              position: "absolute",
                              left: "260px",
                              top: "22px",
                              color: "#999",
                            },
                            attrs: { span: 24 },
                          },
                          [
                            _c("p", { staticStyle: { "font-size": "12px" } }, [
                              _vm._v(
                                "当前时间距离最近到期保险时间间隔，超过这个时间的客户不会自动产生待分配续保任务(默认90天，商业险可提前90天报价)"
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 16 } },
                          [
                            _c("el-form-item", {
                              attrs: {
                                label: "续保提醒设置",
                                "label-width": "160px",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "超期未跟进提醒天数" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "请输入超期未跟进提醒天数",
                                    clearable: "",
                                  },
                                  on: {
                                    input: function ($event) {
                                      _vm.rulesForm.exceedFollowDay =
                                        _vm.onInput(
                                          _vm.rulesForm.exceedFollowDay,
                                          0,
                                          99999,
                                          0
                                        )
                                    },
                                  },
                                  model: {
                                    value: _vm.rulesForm.exceedFollowDay,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.rulesForm,
                                        "exceedFollowDay",
                                        $$v
                                      )
                                    },
                                    expression: "rulesForm.exceedFollowDay",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            staticStyle: {
                              position: "absolute",
                              left: "260px",
                              top: "22px",
                              color: "#999",
                            },
                            attrs: { span: 24 },
                          },
                          [
                            _c("p", { staticStyle: { "font-size": "12px" } }, [
                              _vm._v(
                                "系统默认设置3天，即对任务下发3天还未完成一次线索跟进的，线索状态将变为“超期”，超期的线索管理人员可以回收线索"
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c("el-form-item", {
                              attrs: {
                                label: "续保到期客户线索标签",
                                "label-width": "160px",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          {
                            staticStyle: {
                              display: "flex",
                              "flex-direction": "row",
                              "justify-content": "flex-start",
                              "align-items": "flex-start",
                              "flex-wrap": "wrap",
                            },
                            attrs: { span: 20 },
                          },
                          [
                            _vm._l(
                              _vm.rulesForm.marketingRuleClueVoList,
                              function (item, index) {
                                return _c(
                                  "el-form-item",
                                  {
                                    key: index,
                                    staticStyle: { "margin-bottom": "5px" },
                                    attrs: {
                                      label:
                                        index === 0
                                          ? "请设置续保线索标签周期"
                                          : "",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "marketingRuleClueVoItem",
                                      },
                                      [
                                        _c(
                                          "div",
                                          [
                                            _c("el-color-picker", {
                                              attrs: {
                                                disabled:
                                                  !_vm.rulesForm.expireDay,
                                              },
                                              model: {
                                                value: item.clueColor,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "clueColor",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.clueColor",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "tag-circle-style" },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                placeholder: "请输入备注",
                                                disabled:
                                                  !_vm.rulesForm.expireDay,
                                                clearable: "",
                                              },
                                              model: {
                                                value: item.remark,
                                                callback: function ($$v) {
                                                  _vm.$set(item, "remark", $$v)
                                                },
                                                expression: "item.remark",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "tag-circle-style startsDay",
                                          },
                                          [
                                            _c(
                                              "el-input",
                                              {
                                                attrs: {
                                                  placeholder: "开始天数",
                                                  disabled:
                                                    !_vm.rulesForm.expireDay,
                                                  clearable: "",
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    item.startDay =
                                                      _vm.startDayOnInput(
                                                        item.startDay,
                                                        0,
                                                        99999,
                                                        0
                                                      )
                                                  },
                                                },
                                                model: {
                                                  value: item.startDay,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "startDay",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "item.startDay",
                                                },
                                              },
                                              [
                                                _c(
                                                  "template",
                                                  { slot: "append" },
                                                  [_vm._v("天")]
                                                ),
                                              ],
                                              2
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("div", [_vm._v("—")]),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "tag-circle-style startsDay",
                                          },
                                          [
                                            _c(
                                              "el-input",
                                              {
                                                attrs: {
                                                  placeholder: "结束天数",
                                                  disabled:
                                                    !_vm.rulesForm.expireDay,
                                                  clearable: "",
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    item.endDay =
                                                      _vm.endDayOnInput(
                                                        item.endDay,
                                                        0,
                                                        99999,
                                                        0
                                                      )
                                                  },
                                                },
                                                model: {
                                                  value: item.endDay,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "endDay",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "item.endDay",
                                                },
                                              },
                                              [
                                                _c(
                                                  "template",
                                                  { slot: "append" },
                                                  [_vm._v("天")]
                                                ),
                                              ],
                                              2
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          [
                                            _vm.rulesForm
                                              .marketingRuleClueVoList
                                              .length ===
                                              index + 1 && index !== 0
                                              ? _c("el-button", {
                                                  attrs: {
                                                    icon: "el-icon-minus",
                                                    disabled:
                                                      !_vm.rulesForm.expireDay,
                                                  },
                                                  on: { click: _vm.deleteTag },
                                                })
                                              : _vm._e(),
                                            _vm.rulesForm
                                              .marketingRuleClueVoList
                                              .length ===
                                            index + 1
                                              ? _c("el-button", {
                                                  attrs: {
                                                    type: "primary",
                                                    icon: "el-icon-plus",
                                                  },
                                                  on: { click: _vm.addTag },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              }
                            ),
                            _c(
                              "div",
                              { staticStyle: { "margin-left": "260px" } },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-size": "12px",
                                      color: "#999",
                                    },
                                  },
                                  [
                                    _vm._v("注： 请先输入"),
                                    _c(
                                      "span",
                                      { staticStyle: { color: "#F53C3C" } },
                                      [_vm._v("距保险到期天数，")]
                                    ),
                                    _vm._v(
                                      " 续保线索标签周期开始天数需要小于结束天数，并结束天数不得大于距保险到期天数"
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 16 } },
                          [
                            _c("el-form-item", {
                              attrs: {
                                label: "续保线索分配规则",
                                "label-width": "160px",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "" } },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    model: {
                                      value: _vm.rulesForm.allocationRule,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.rulesForm,
                                          "allocationRule",
                                          $$v
                                        )
                                      },
                                      expression: "rulesForm.allocationRule",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-radio",
                                      { attrs: { label: "AR01" } },
                                      [_vm._v("自动分配")]
                                    ),
                                    _c(
                                      "el-radio",
                                      { attrs: { label: "AR02" } },
                                      [_vm._v("手动分配")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            staticStyle: {
                              position: "absolute",
                              left: "260px",
                              top: "22px",
                              color: "#999",
                            },
                            attrs: { span: 24 },
                          },
                          [
                            _c("p", { staticStyle: { "font-size": "12px" } }, [
                              _vm._v(
                                "系统默认自动分配，即当产生续保线索时，将自动遵循上年投保专员优先原则进行配置，余下线索执行平均分配逻辑"
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "table-btn-cls",
                    staticStyle: { "margin-top": "24px" },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { width: "80px" },
                        attrs: { size: "mini", type: "primary" },
                        on: { click: _vm.save },
                      },
                      [_vm._v("保存")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }