/**
 * 配置编译环境
 *
 */

// 可以多个接口域名，按需添加
var envInfo = process.env;
var _ref = [envInfo.VUE_APP_APIDOMAIN, envInfo.VUE_APP_CORE_SERVER, envInfo.VUE_APP_RESOURCE_SERVER, envInfo.VUE_APP_ORG_SERVER, envInfo.VUE_APP_TP_CORE, envInfo.VUE_APP_TP_AUTH, envInfo.VUE_APP_STORAGE_SERVER, envInfo.VUE_APP_TP_MESSAGE_SERVICE, envInfo.VUE_APP_PROCESS_SERVICE, envInfo.VUE_APP_VEHICLE_SERVICE, envInfo.VUE_APP_TOYOTA_CUSTOMIZED_SERVICES, envInfo.VUE_APP_CONNECT_CONFIG_SERVER, envInfo.VUE_APP_OC_CORE_SERVER, envInfo.VUE_APP_BIZ_NONAUTO_SERVICE, envInfo.VUE_APP_BIZ_NONAUTO_CONNECT_CONFIGURATION_SERVICE, envInfo.VUE_APP_BIZ_NONAUTO_CONNECT_ADAPTER_FTMS_SERVICE, envInfo.VUE_APP_TP_RECOGNITION_SERVICE],
  apiDomain = _ref[0],
  viCoreServer = _ref[1],
  resourceServer = _ref[2],
  orgServer = _ref[3],
  tpCoreServer = _ref[4],
  tpAuthServer = _ref[5],
  tpStorageService = _ref[6],
  tpMessageService = _ref[7],
  processService = _ref[8],
  vehicleService = _ref[9],
  psFtmsService = _ref[10],
  viConnectConfigServer = _ref[11],
  ocCoreService = _ref[12],
  bizNonautoService = _ref[13],
  bizNonautoConnectConfigurationService = _ref[14],
  bizNonautoConnectAdapterFTMSService = _ref[15],
  tpRecognitionService = _ref[16];
/**
 *
 */
export default {
  apiDomain: apiDomain,
  // 主域名
  viCoreServer: viCoreServer,
  //出单微服务名称
  resourceServer: resourceServer,
  //资源微服务名称
  orgServer: orgServer,
  //机构微服务名称
  tpCoreServer: tpCoreServer,
  //tp-core服务名称
  tpAuthServer: tpAuthServer,
  //tp-auth服务名称
  tpStorageService: tpStorageService,
  //存储服务
  tpMessageService: tpMessageService,
  //站内消息
  processService: processService,
  //导出文件
  vehicleService: vehicleService,
  //车服务
  psFtmsService: psFtmsService,
  //一丰定制化服务
  viConnectConfigServer: viConnectConfigServer,
  //车险对接配置服务
  ocCoreService: ocCoreService,
  //呼叫中心服务
  bizNonautoService: bizNonautoService,
  //非车险服务
  bizNonautoConnectConfigurationService: bizNonautoConnectConfigurationService,
  //非车险供应商配置服务
  bizNonautoConnectAdapterFTMSService: bizNonautoConnectAdapterFTMSService,
  //非车对接适配服务
  tpRecognitionService: tpRecognitionService //数据识别服务
};