var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.formLoading,
          expression: "formLoading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: {},
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "500px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "addForm",
                  staticStyle: { "margin-bottom": "-20px" },
                  attrs: {
                    model: _vm.addForm,
                    rules: _vm.formRules,
                    "label-width": "120px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "保险公司:",
                                prop: "insCorpCode",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    filterable: "",
                                    disabled: _vm.DialogType == "view",
                                    placeholder: "请选择保险公司",
                                    clearable: "",
                                  },
                                  on: { change: _vm.getAccidentType },
                                  model: {
                                    value: _vm.addForm.insCorpCode,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.addForm, "insCorpCode", $$v)
                                    },
                                    expression: "addForm.insCorpCode",
                                  },
                                },
                                _vm._l(_vm.supplyers, function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.shortName,
                                      value: item.code,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "驾意险类型编码:",
                                prop: "accidentTypeCode",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入驾意险类型编码",
                                  disabled: _vm.DialogType == "view",
                                  maxlength: "40",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.addForm.accidentTypeCode,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.addForm,
                                      "accidentTypeCode",
                                      $$v
                                    )
                                  },
                                  expression: "addForm.accidentTypeCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "驾意险类型名称:",
                                prop: "accidentType",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入驾意险类型名称",
                                  disabled: _vm.DialogType == "view",
                                  maxlength: "40",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.addForm.accidentType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addForm, "accidentType", $$v)
                                  },
                                  expression: "addForm.accidentType",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "是否启用:", prop: "enabled" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  attrs: { disabled: _vm.DialogType == "view" },
                                  model: {
                                    value: _vm.addForm.enabled,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.addForm, "enabled", $$v)
                                    },
                                    expression: "addForm.enabled",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: true } }, [
                                    _vm._v("是"),
                                  ]),
                                  _c("el-radio", { attrs: { label: false } }, [
                                    _vm._v("否"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "是否多选:", prop: "group" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  attrs: { disabled: _vm.DialogType == "view" },
                                  model: {
                                    value: _vm.addForm.group,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.addForm, "group", $$v)
                                    },
                                    expression: "addForm.group",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: true } }, [
                                    _vm._v("是"),
                                  ]),
                                  _c("el-radio", { attrs: { label: false } }, [
                                    _vm._v("否"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticStyle: { width: "100%", height: "10px" } }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-check",
                type: "primary",
                plain: "",
                size: "mini",
                disabled: _vm.DialogType == "view",
              },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: { click: _vm.closePage },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }