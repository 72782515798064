import "core-js/modules/es.array.concat.js";
/**
 * 二次入职管理接口
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 二次入职列表
   */
  employeeAgainList: function employeeAgainList(data) {
    return request.get(getUrl("/regulation/again/search", 12), {
      params: data
    });
  },
  /**
   * 二次入职申请
   */
  employeeAgainApply: function employeeAgainApply(employeeId, data) {
    return request.put(getUrl("/regulation/again/apply/".concat(employeeId), 12), data);
  },
  /**
   * 申请记录删除
   */
  employeeAgainDelete: function employeeAgainDelete(data) {
    return request.put(getUrl("/regulation/again/delete", 12), data);
  },
  /**
   * 申请记录单个查看
   */
  employeeAgainView: function employeeAgainView(employeeAgainEntryId) {
    return request.get(getUrl("/regulation/again/entry/".concat(employeeAgainEntryId), 12));
  },
  /**
   * 申请审核
   */
  employeeAgainUpdate: function employeeAgainUpdate(employeeAgainEntryId, data) {
    return request.put(getUrl("/regulation/again/audit/".concat(employeeAgainEntryId, "?auditStatus=").concat(data), 12));
  }
};
export default objApi;