import "core-js/modules/es.array.concat.js";
/**
 * 经销商维护
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 经销商信息维护列表
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   * @param {*} data 内容
   */
  getList: function getList(pageNum, pageSize, data) {
    return request.get(getUrl("/dealer/info?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 12), {
      params: data
    });
  },
  /**
   * 新增经销商数据
   * @param {*} data 内容
   */
  add: function add(data) {
    return request.post(getUrl("/oem/dealer", 13), data);
  },
  // 查询某一条经销商数据
  updateSingle: function updateSingle(id) {
    return request.get(getUrl("/dealer/info/get?dealerId=".concat(id), 12));
  },
  /**
   * 更新经销商数据
   * @param {*} data 内容
   */
  edit: function edit(id, data) {
    return request.put(getUrl("/oem/dealer/".concat(id), 13), data);
  },
  /**
   * 批量删除经销商数据
   * @param {*} ids 删除的id组合
   */
  delete: function _delete(ids) {
    return request.delete(getUrl("/dealer/info/".concat(ids), 12));
  },
  // 下载excel模板
  downloadTemplate: function downloadTemplate() {
    return request({
      method: 'get',
      url: getUrl("/dealer/info/template", 12),
      responseType: 'blob'
    });
  },
  /**
   * 导入模板判断
   *  @param {*} data 内容
   */
  bulkImport: function bulkImport(data) {
    return request({
      method: 'post',
      url: getUrl("/oem/dealer/import/judge", 13),
      data: data
    });
  },
  /**
   * 导入模板 （覆盖）
   *  @param {*} code 编码
   */
  coverBulkImport: function coverBulkImport(code) {
    return request.post(getUrl("/oem/dealer/import/".concat(code, "?flag=true"), 13));
  },
  /**
   * 经销商禁用或启用
   *  @param {*} data 数据
   */
  changeDealerState: function changeDealerState(data) {
    return request.post(getUrl("/dealer/info/disabled", 12), data);
  }
};
export default objApi;