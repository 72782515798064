import _defineProperty from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import NonAutoRequest from '@/api/nonAutoRequest';
export default {
  data: function data() {
    var _ref;
    return _ref = {
      timeout1: null,
      auditType: '',
      auditRecordId: '',
      reason: '',
      policyInfo: {},
      approverName: "",
      policyAuditStatus: "",
      policyAuditStatusDisplay: ""
    }, _defineProperty(_ref, "auditRecordId", ""), _defineProperty(_ref, "auditResult", ""), _defineProperty(_ref, "policyFileList", []), _defineProperty(_ref, "terminateInfo", {}), _defineProperty(_ref, "supplierList", [{
      supplierCode: "YQFT_PAIC",
      supplierName: "平安财险"
    }, {
      supplierCode: "YQFT_TPIC",
      supplierName: "太平财险"
    }, {
      supplierCode: "YQFT_XAIC",
      supplierName: "鑫安汽车保险"
    }, {
      supplierCode: "YQFT_CPIC",
      supplierName: "太平洋财险"
    }, {
      supplierCode: "YQFT_PICC",
      supplierName: "人保财险"
    }]), _defineProperty(_ref, "showViewImg", false), _defineProperty(_ref, "ViewImgUrl", ''), _defineProperty(_ref, "loading", false), _ref;
  },
  created: function created() {
    document.title = '注销审核';
    if (this.$route.query) {
      if (this.$route.query.auditRecordId) {
        this.auditRecordId = this.$route.query.auditRecordId;
        this.getCancelDetailDetail(this.$route.query.auditRecordId);
      }
    }
  },
  methods: {
    // 获取文件后缀
    getFileSuffix: function getFileSuffix(fileName) {
      if (!fileName) return false;
      var _suffix = fileName.substring(fileName.lastIndexOf(".") + 1);
      return _suffix == 'JPEG' || _suffix == 'PNG' || _suffix == 'JPG' || _suffix == 'GIF' || _suffix == 'jpeg' || _suffix == 'png' || _suffix == 'jpg' || _suffix == 'gif';
    },
    filterSupplierCode: function filterSupplierCode(code) {
      var supplierName = "";
      if (code) {
        var supplierObj = _.find(this.supplierList, function (item) {
          return item.supplierCode == code;
        });
        supplierName = supplierObj.supplierName;
      }
      return supplierName;
    },
    getImgUrl: function getImgUrl(row) {
      this.showViewImg = true;
      this.ViewImgUrl = row.fileUrl ? row.fileUrl : '';
    },
    closeViewImg: function closeViewImg() {
      this.showViewImg = false;
      this.ViewImgUrl = '';
    },
    // 审核详情
    getCancelDetailDetail: function getCancelDetailDetail(id) {
      var _this = this;
      this.loading = true;
      NonAutoRequest.insCorpAuditCancelDetail(id).then(function (res) {
        setTimeout(function () {
          _this.loading = false;
        }, 1000);
        if (res.code == 200 && res.data) {
          _this.auditRecordId = res.data.auditRecordId;
          _this.auditResult = res.data && res.data.auditResult ? res.data.auditResult : '';
          _this.approverName = res.data && res.data.approverName ? res.data.approverName : '';
          _this.policyAuditStatus = res.data && res.data.auditStatus ? res.data.auditStatus : false;
          _this.policyAuditStatusDisplay = res.data && res.data.auditStatusDisplay ? res.data.auditStatusDisplay : '';
          var policyInfo = res.data && res.data.policyInfo ? res.data.policyInfo : {};
          _this.policyInfo = policyInfo;
          if (res.data.terminateInfo.policyCancelFileList) {
            var policyCancelFileList = res.data.terminateInfo.policyCancelFileList ? res.data.terminateInfo.policyCancelFileList : [];
            _this.policyFileList = policyCancelFileList ? policyCancelFileList : [];
          }
          if (res.data.terminateInfo) {
            _this.terminateInfo = res.data ? res.data.terminateInfo : [];
          }
        }
      });
    },
    // 提交审核防抖
    submit: function submit(formName) {
      var _this2 = this;
      if (this.timeout1) {
        clearTimeout(this.timeout1);
      }
      this.timeout1 = setTimeout(function () {
        _this2.submit1(formName);
      }, 800);
    },
    // 提交审核
    submit1: function submit1() {
      var _this3 = this;
      if (!this.auditType) {
        this.$message({
          type: 'warning',
          message: '请选择审核意见!',
          center: true
        });
        return;
      }
      this.loading = true;
      var data = {
        auditRecordId: this.auditRecordId ? this.auditRecordId : '',
        auditType: this.auditType ? this.auditType : '',
        reason: this.reason ? this.reason : ''
      };
      NonAutoRequest.CancelDetailAudit(data).then(function (res) {
        setTimeout(function () {
          _this3.loading = false;
        }, 1000);
        if (res.code == 200) {
          _this3.getCancelDetailDetail(_this3.$route.query.auditRecordId);
          _this3.$message({
            type: 'success',
            message: res.msg,
            center: true
          });
          _this3.$router.replace({
            name: 'verifyChargebackList'
          });
        } else {
          setTimeout(function () {
            _this3.loading = false;
          }, 1000);
        }
      });
    }
  }
};