import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import dict from '@/mixins/dict';
import Pagination from '@/components/Pagination';
import Detail from "./detail";
import taskAllocationRequest from '@/api/taskAllocation';
import OemSelect from '@/components/OemSelect';
import { getBeforeDate, getCurrentDate, getUserOrgInfo } from '@/utils/common';
export default {
  name: 'taskAllocation',
  components: {
    Pagination: Pagination,
    Detail: Detail,
    OemSelect: OemSelect
  },
  mixins: [initHeight, dict],
  computed: {},
  data: function data() {
    return {
      drawer: false,
      searchForm: {},
      createDateTime: [getBeforeDate(30), getCurrentDate()],
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      loading: false,
      highlightCurrent: true,
      orgForm: {
        salesOrgCode: '',
        salesOrgName: '',
        salesOrgType: ''
      },
      showDetailDialog: false,
      opType: "add",
      bizType: localStorage.getItem('bizType') || '',
      showDialog: false,
      tableDetailData: [],
      tableloading: false,
      dealerName: ""
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.loadData();
    this.getDefaultValue();
  },
  methods: {
    getDefaultValue: function getDefaultValue() {
      if (this.bizType != 'OEM') {
        var userInfo = getUserOrgInfo() || {};
        this.searchForm.creatorOrgCode = userInfo.orgCode;
        this.dealerName = userInfo.dealerName;
      }
    },
    // 查看详情
    viewDetail: function viewDetail(item) {
      var _this = this;
      this.showDetailDialog = true;
      var postData = {
        monitorId: item.monitorId
      };
      this.tableloading = true;
      taskAllocationRequest.getDistributionDetailList(postData).then(function (res) {
        _this.tableloading = false;
        if (res.code == 200) {
          _this.tableDetailData = res.data;
        }
      }).catch(function (err) {
        return _this.tableloading = false;
      });
    },
    // 关闭页面
    closePage: function closePage(isRefresh) {
      this.showDialog = false;
      if (isRefresh) {
        this.loadData();
      }
    },
    //日期更改监听事件
    dateChange: function dateChange(start, end, val) {
      var searchForm = {};
      if (val && val.length > 1) {
        searchForm[start] = val[0] + ' 00:00:00';
        searchForm[end] = val[1] + ' 23:59:59';
      } else {
        searchForm[start] = '';
        searchForm[end] = '';
      }
      this.$emit('pickerDateChange', [searchForm[start], searchForm[end]]);
    },
    // 机构树点击事件
    handleNodeClick: function handleNodeClick(data) {
      this.searchForm.creatorOrgCode = data.salesOrgCode;
    },
    // 删除
    deleteDistribution: function deleteDistribution(item) {
      var _this2 = this;
      this.$confirm("您确定要将该数据删除", {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        taskAllocationRequest.deleteDistribution(item.monitorId).then(function (res) {
          if (res.code === 200) {
            _this2.$message.success(res.msg);
            _this2.loadData();
          }
        });
      });
    },
    // 打开弹窗
    openDialog: function openDialog(type) {
      this.opType = type;
      this.showDialog = true;
    },
    breakData: function breakData() {
      this.loadData();
    },
    // 导出
    exportFn: function exportFn() {
      var exporParams = {
        total: this.total,
        fileName: '任务分配',
        exportUrl: '/vi-core-service/renewal/task/distribution/monitor/search',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    //重置搜索表单
    resetSearch: function resetSearch() {
      this.searchForm = {};
      this.createDateTime = [getBeforeDate(30), getCurrentDate()];
      this.getDefaultValue();
    },
    //搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    //获取列表数据
    loadData: function loadData() {
      var _this3 = this;
      if (this.createDateTime) {
        this.searchForm.createdTimeStart = this.createDateTime[0] && this.createDateTime[0].split(' ')[0] + ' 00:00:00' || null;
        this.searchForm.createdTimeEnd = this.createDateTime[1] && this.createDateTime[1].split(' ')[0] + ' 23:59:59' || null;
      }
      this.loading = true;
      this.drawer = false;
      taskAllocationRequest.getList(_objectSpread(_objectSpread({}, this.listQuery), this.searchForm)).then(function (res) {
        if (res.code === 200) {
          _this3.tableData = res.data.list;
          _this3.loading = false;
          _this3.total = res.data.page.recordsTotal;
        }
      }).catch(function (err) {
        _this3.loading = false;
      });
    }
  }
};