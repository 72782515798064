import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import Pagination from '@/components/Pagination';
import InstallDialog from './dialog/installDialog';
import callApi from '@/api/callCenter';
import { saveFile } from '@/utils/common';
export default {
  name: 'InstallVersion',
  components: {
    Pagination: Pagination,
    InstallDialog: InstallDialog
  },
  mixins: [initHeight],
  data: function data() {
    return {
      searchForm: {},
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      multipleSelection: [],
      loading: false,
      releaseStateList: [{
        label: '已发布',
        value: 'HAVE_ANNOUNCED'
      }, {
        label: '未发布',
        value: 'TO_BE_ANNOUNCED'
      }],
      showUploadDialog: false
    };
  },
  filters: {
    filterType: function filterType(val) {
      if (val === 0) return '0 B';
      var k = 1024;
      var sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      var i = Math.floor(Math.log(val) / Math.log(k));
      return (val / Math.pow(k, i)).toPrecision(3) + " " + sizes[i];
    }
  },
  created: function created() {
    this.loadData();
  },
  methods: {
    batchDelete: function batchDelete(item) {
      var _this = this;
      //删除
      var ids = [];
      if (!_.isEmpty(item)) {
        ids.push(item.packageId);
      } else {
        if (!_.isEmpty(this.multipleSelection)) {
          ids = _.map(this.multipleSelection, 'packageId');
        }
        ;
      }
      ;
      this.$confirm('您确定要删除么？', {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        callApi.deletePackage(ids).then(function (res) {
          if (res.code === 200) {
            _this.$message.success(res.msg);
            _this.loadData();
          }
          ;
        });
      });
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {};
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    loadData: function loadData() {
      var _this2 = this;
      //获取列表数据
      this.loading = true;
      this.showUploadDialog = false;
      callApi.getPackageList(this.listQuery.pageNum, this.listQuery.pageSize, this.searchForm).then(function (res) {
        _this2.loading = false;
        if (res.code === 200) {
          _this2.tableData = res.data.list;
          _this2.total = res.data.page.recordsTotal;
        }
      }).catch(function (err) {
        _this2.loading = false;
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      //列表行选择事件
      this.multipleSelection = val;
    },
    // 刷新
    breakData: function breakData() {
      // this.resetSearch();
      this.loadData();
    },
    // 发布
    publish: function publish(item) {
      var _this3 = this;
      if (item.issueStatus === 'HAVE_ANNOUNCED') {
        this.$message.warning('该版本已经发布！');
        return;
      }
      ;
      this.$confirm('您确定要发布吗？', {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        item.issueStatus = 'HAVE_ANNOUNCED';
        callApi.editPackage(item.packageId, item.issueStatus).then(function (res) {
          if (res.code === 200) {
            _this3.$message.success(res.msg);
            _this3.loadData();
          }
          ;
        });
      });
    },
    // 下架
    shelves: function shelves(item) {
      var _this4 = this;
      if (item.issueStatus === 'TO_BE_ANNOUNCED') {
        this.$message.warning('该版本未发布！');
        return;
      }
      ;
      this.$confirm('您确定要下架吗？', {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        item.issueStatus = 'TO_BE_ANNOUNCED';
        callApi.editPackage(item.packageId, item.issueStatus).then(function (res) {
          if (res.code === 200) {
            _this4.$message.success(res.msg);
            _this4.loadData();
          }
          ;
        });
      });
    },
    // 上传
    add: function add() {
      this.showUploadDialog = true;
    },
    // 下载
    handle: function handle(row) {
      var _this5 = this;
      if (row.issueStatus === 'TO_BE_ANNOUNCED') {
        this.$message.warning('该版本还没有发布，不允许下载！');
        return;
      }
      ;
      callApi.downloadPackage(row.packageId).then(function (res) {
        var fileName = row.packageName;
        var contentType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        saveFile(res, contentType, fileName);
        _this5.loadData();
      });
    }
  }
};