//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import reportForm from "@/api/reportForm";
import Pagination from '@/components/Pagination';
import { commonExport } from '@/utils/common';
export default {
  components: {
    Pagination: Pagination
  },
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    searchForm: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      modifiedTime: ''
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.loadData();
        this.getTime();
      }
      ;
    }
  },
  mounted: function mounted() {
    this.loadData();
    this.getTime();
  },
  methods: {
    getTime: function getTime() {
      var _this = this;
      reportForm.getPolicyModifiedTime(this.listQuery.pageNum, this.listQuery.pageSize, this.searchForm).then(function (res) {
        if (res.code === 200) {
          _this.modifiedTime = res.data;
        }
        ;
      }).catch(function (err) {});
    },
    loadData: function loadData() {
      var _this2 = this;
      //获取列表数据
      reportForm.getPolicyDetalsList(this.listQuery.pageNum, this.listQuery.pageSize, this.searchForm).then(function (res) {
        if (res.code === 200) {
          _this2.tableData = res.data.list;
          _this2.total = res.data.page.recordsTotal;
        }
        ;
      }).catch(function (err) {});
    },
    closePage: function closePage() {
      //关闭弹窗
      this.$emit('closePage');
    },
    exportExcels: function exportExcels() {
      //导出
      var exporParams = {
        total: this.total,
        fileName: '保单统计列表',
        exportUrl: '/vi-core-service/statistics/report/policy/detals',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    }
  }
};