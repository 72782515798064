import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
/**
 *  保司特约管理接口
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 列表
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   */
  getSpecialConfigList: function getSpecialConfigList(pageNum, pageSize, data) {
    return request.get(getUrl("/specialConfig/search?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 14), {
      params: data
    });
  },
  /**
   * 新增数据
   * @param {*} data 内容
   */
  addData: function addData(data) {
    return request.post(getUrl("/specialConfig/create", 14), data);
  },
  /**
   * 查看数据
   * @param {*} data 内容
   */
  viewData: function viewData(data) {
    return request.get(getUrl("/specialConfig/get", 14), {
      params: data
    });
  },
  /**
   * 删除数据
   * @param {*} id 
   */
  deleteData: function deleteData(ids) {
    return request.delete(getUrl("/specialConfig/delete?specialIdList=".concat(ids.toString()), 14));
  },
  /**
   * 编辑数据
   * @param {*} data 
   */
  updateData: function updateData(data) {
    return request.put(getUrl("/specialConfig/update?specialId=".concat(data.specialId), 14), data);
  },
  /**
   * 导入
   * @param {*} data 
   */
  importSpecialAgreement: function importSpecialAgreement(data) {
    return request.post(getUrl("/specialConfig/importSpecialAgreement?objStorageId=".concat(data.storageId), 14), {});
  },
  /**
   * 搜索
   * @param {*} id 
   */
  getBySpecialCode: function getBySpecialCode(ids) {
    return request.get(getUrl("/specialConfig/getBySpecialCode?specialCode=".concat(ids), 14));
  },
  /**
   * 通过工号ID获取工号特约关系维护列表
   * @param {*} id 
   */
  getInsCorpChannelSpecialList: function getInsCorpChannelSpecialList(ids) {
    return request.get(getUrl("/insCorpChannelSpecial/getByChannelId?insCorpChannelId=".concat(ids), 14));
  },
  /**
   * 通过工号ID获取工号非车险关系维护列表
   * @param {*} id 
   */
  getByChannelId: function getByChannelId(ids) {
    return request.get(getUrl("/insCorpChannelAccident/getByChannelId?insCorpChannelId=".concat(ids), 14));
  },
  /**
   * 创建工号非车险关系
   * @param {*} id 
   */
  addNonAutoInsurance: function addNonAutoInsurance(data) {
    return request.post(getUrl("/insCorpChannelAccident/create", 14), data);
  },
  /**
   * 根据非车险分类id获取产品
   * @param {*} id 
   */
  getCateGoryIdList: function getCateGoryIdList(id) {
    return request.get(getUrl("/riskAccidentProduct/getCateGoryIdList?cateGoryId=".concat(id), 14));
  },
  /**
   * 删除工号非车险关系维护
   * @param {*} id 
   */
  delCateGoryIdList: function delCateGoryIdList(ids) {
    return request.delete(getUrl("/insCorpChannelAccident/delete?accidentIdList=".concat(ids.toString()), 14));
  },
  /**
   * 通过工号ID获取接口非车险关系维护列表
   * @param {*} id 
   */
  getByApiConfigId: function getByApiConfigId(ids) {
    return request.get(getUrl("/insCorpApiConfigAccident/getByInsCorpId?insCorpApiId=".concat(ids), 14));
  },
  /**
   * 创建接口非车险关系
   * @param {*} id 
   */
  addApiNonAutoInsurance: function addApiNonAutoInsurance(data) {
    return request.post(getUrl("/insCorpApiConfigAccident/create", 14), data);
  },
  /**
   * 删除接口非车险关系维护
   * @param {*} id 
   */
  delApiCateGoryIdList: function delApiCateGoryIdList(ids) {
    return request.delete(getUrl("/insCorpApiConfigAccident/delete?accidentIdList=".concat(ids.toString()), 14));
  }
};
export default objApi;