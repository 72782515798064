import _createForOfIteratorHelper from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import gatewayConfigRequest from '@/api/gatewayConfig';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    DialogType: {
      type: String,
      default: {}
    },
    DialogAppId: {
      type: String,
      default: {}
    },
    DialogId: {
      type: String,
      default: {}
    }
  },
  data: function data() {
    var generateData = function generateData(_) {
      var data = [];
      for (var i = 1; i <= 15; i++) {
        data.push({
          value: i,
          desc: "\u5907\u9009\u9879 ".concat(i),
          disabled: i % 4 === 0
        });
      }
      return data;
    };
    return {
      data: generateData(),
      routeGroupsData: [],
      //路由组数据
      apiRouteIdList: [],
      addForm: {
        name: '',
        //配置名称
        whiteIpAddresses: '',
        whiteDomains: '',
        signFreeDomains: '',
        enabled: true,
        enabledEncrypt: false,
        bindUser: false,
        monitor: false,
        noLogin: false,
        loginFirst: false,
        bindUserAccount: '',
        apiGroupIdList: [],
        //接口分组Id列表
        noUserCheckApiGroupIdList: [],
        signatureVersion: ''
      },
      formLoading: false,
      formRules: {
        name: [{
          required: true,
          message: '请输入应用名称',
          trigger: 'blur'
        }],
        enabled: [{
          required: true,
          message: '请选择是否启用',
          trigger: 'change'
        }],
        enabledEncrypt: [{
          required: true,
          message: '请选择是否加密',
          trigger: 'change'
        }],
        signatureVersion: [{
          required: true,
          message: '请选择签名版本号',
          trigger: 'change'
        }],
        bindUser: [{
          required: true,
          message: '请选择用户鉴权',
          trigger: 'change'
        }]
      },
      signatureVersionList: [{
        itemCode: '1.0',
        itemName: 'v1.0'
      }, {
        itemCode: '2.0',
        itemName: 'v2.0'
      }]
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
    }
  },
  mounted: function mounted() {
    this.initData();
    this.getRouteGroupList();
  },
  methods: {
    getRouteGroupList: function getRouteGroupList() {
      var _this = this;
      //获取路由组数据列表
      var data = {
        pageNum: 1,
        pageSize: 2147483647
      };
      gatewayConfigRequest.RouteGroupLists(data).then(function (res) {
        if (res.code == 200) {
          if (res.data && res.data.list) {
            var resList = res.data.list;
            _this.routesList = resList;
            var _data = [];
            var _iterator = _createForOfIteratorHelper(resList),
              _step;
            try {
              for (_iterator.s(); !(_step = _iterator.n()).done;) {
                var i = _step.value;
                _data.push({
                  value: i.apiGroupId,
                  desc: i.name,
                  disabled: !i.enabled
                });
              }
            } catch (err) {
              _iterator.e(err);
            } finally {
              _iterator.f();
            }
            _this.routeGroupsData = _data;
          }
        }
      });
    },
    initData: function initData() {
      var _this2 = this;
      //初始化数据
      this.addForm = {
        name: '',
        //配置名称
        whiteIpAddresses: '',
        whiteDomains: '',
        signFreeDomains: '',
        enabled: true,
        enabledEncrypt: false,
        bindUser: false,
        monitor: false,
        noLogin: false,
        loginFirst: false,
        bindUserAccount: '',
        apiGroupIdList: [],
        //接口分组Id列表
        noUserCheckApiGroupIdList: [],
        signatureVersion: ''
      };
      if (this.DialogType == 'add') {} else {
        //编辑获取单个数据详情
        gatewayConfigRequest.getOneApplication({
          appId: this.DialogAppId
        }).then(function (res) {
          if (res.code == 200) {
            _this2.addForm = res.data;
            _this2.$set(_this2.addForm, 'noLogin', _this2.addForm.noUserCheckApiGroupIdList.length ? true : false);
            _this2.$set(_this2.addForm, 'loginFirst', _this2.addForm.apiGroupIdList.length || _this2.addForm.bindUser ? true : false);
          }
        });
      }
      this.$refs.addForm.resetFields();
    },
    bindUserChange: function bindUserChange(val) {
      this.addForm.bindUserAccount = '';
    },
    // validate() {
    //     let flag = true
    //     if (!this.addForm.noUserCheckApiGroupIdList.length) {
    //         this.$message.warning('请选择用户鉴权路由组')
    //         flag = false
    //         return flag
    //     }
    //     return flag
    // },
    save: function save() {
      var _this3 = this;
      //保存数据
      this.$refs['addForm'].validate(function (valid) {
        if (valid) {
          // if(!this.validate()) return;
          _this3.formLoading = true;
          // let data = JSON.parse(JSON.stringify(this.addForm));
          // data.ipAddresses = data.ipAddresses ? data.ipAddresses.split(',') : [];
          if (_this3.DialogType == 'add') {
            gatewayConfigRequest.createApplication(_this3.addForm).then(function (res) {
              if (res.code == 200 && res.success == true) {
                _this3.formLoading = false;
                _this3.$message.success(res.msg);
                _this3.closePage(false);
              }
            });
          } else {
            gatewayConfigRequest.updateApplication(_this3.addForm).then(function (res) {
              if (res.code == 200 && res.success == true) {
                _this3.formLoading = false;
                _this3.$message.success(res.msg);
                _this3.closePage(false);
              }
            });
          }
        }
      });
    },
    closePage: function closePage() {
      var boolean = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      //关闭弹窗
      this.$emit('closePage', boolean);
    },
    noLoginChange: function noLoginChange(val) {
      if (!val) this.addForm.noUserCheckApiGroupIdList = [];
    },
    loginFirstChange: function loginFirstChange(val) {
      if (!val) {
        this.addForm.apiGroupIdList = [];
        this.addForm.bindUser = false;
        this.addForm.bindUserAccount = '';
      }
      ;
    }
  }
};