var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.formLoading,
          expression: "formLoading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: {},
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "540px", overflow: "auto" },
        },
        [
          _c("el-card", { attrs: { shadow: "never" } }, [
            _c("h3", [_vm._v(_vm._s(_vm.form.messageTitle))]),
            _c("p", { staticStyle: { "font-size": "12px", color: "#999" } }, [
              _vm._v("发布时间 : " + _vm._s(_vm.form.messageSendDatetime)),
            ]),
            _c(
              "div",
              {
                staticStyle: {
                  "line-height": "25px",
                  "font-size": "15px",
                  "white-space": "pre-wrap",
                },
              },
              [_vm._v(" " + _vm._s(_vm.form.messageContent) + " ")]
            ),
            _c(
              "div",
              [
                _c(
                  "p",
                  { staticStyle: { color: "#999", "font-size": "13px" } },
                  [_vm._v("附件列表：")]
                ),
                _vm._l(_vm.form.messageFile, function (file, i) {
                  return _c("div", { key: i }, [
                    _c("div", { staticClass: "file-box" }, [
                      _c(
                        "span",
                        {
                          staticClass: "file-text",
                          attrs: { title: file.name },
                          on: {
                            click: function ($event) {
                              return _vm.handle(file)
                            },
                          },
                        },
                        [_vm._v(_vm._s(file.name))]
                      ),
                    ]),
                  ])
                }),
              ],
              2
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: { click: _vm.closePage },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }