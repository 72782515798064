var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.formLoading,
          expression: "formLoading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: {},
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "500px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "detail",
                  staticStyle: { "margin-bottom": "-20px" },
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-width": "126px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "模板类型：",
                                prop: "templateTypeId",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { placeholder: "请选择模板类型" },
                                  on: { change: _vm.templateChange },
                                  model: {
                                    value: _vm.form.templateTypeId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "templateTypeId", $$v)
                                    },
                                    expression: "form.templateTypeId",
                                  },
                                },
                                _vm._l(
                                  _vm.templateTypeList,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.typeName,
                                        value: item.templateTypeId,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.bizType === "OEM"
                        ? _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "所属机构：",
                                    prop: "orgCode",
                                  },
                                },
                                [
                                  _c("OemSelect", {
                                    attrs: {
                                      searchForm: _vm.orgForm,
                                      isContainOem: false,
                                    },
                                    on: { nodeClick: _vm.handleNodeClick },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.bizType === "DEALER"
                        ? _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "所属机构：",
                                    prop: "orgCode",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: { disabled: "" },
                                    model: {
                                      value: _vm.form.orgName,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "orgName", $$v)
                                      },
                                      expression: "form.orgName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.tableData.length
                        ? _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "模板参数：" } },
                                [
                                  _c(
                                    "el-table",
                                    {
                                      ref: "multipleTable",
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        size: "mini",
                                        border: "",
                                        data: _vm.tableData,
                                        "header-cell-style": {
                                          background: "#F7F7F7",
                                        },
                                        "highlight-current-row": "",
                                      },
                                    },
                                    [
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "key",
                                          label: "参数名",
                                          "show-overflow-tooltip": "",
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "value",
                                          label: "描述",
                                          "show-overflow-tooltip": "",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.tableData.length
                        ? _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "参数示例：" } },
                                [_vm._v(" {参数名}，您好。 ")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "短信类型：",
                                prop: "messageType",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { placeholder: "请选择短信类型" },
                                  model: {
                                    value: _vm.form.messageType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "messageType", $$v)
                                    },
                                    expression: "form.messageType",
                                  },
                                },
                                _vm._l(
                                  _vm.messageTypeList,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.label,
                                        value: item.value,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "模板描述：" } },
                            [
                              _c("el-input", {
                                attrs: { readonly: true },
                                model: {
                                  value: _vm.typeDescribe,
                                  callback: function ($$v) {
                                    _vm.typeDescribe = $$v
                                  },
                                  expression: "typeDescribe",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "模板名称：",
                                prop: "templateName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入模板名称",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.templateName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "templateName", $$v)
                                  },
                                  expression: "form.templateName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "供应商模板编码：",
                                prop: "supplierTemplateCode",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入供应商模板编码",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.supplierTemplateCode,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "supplierTemplateCode",
                                      $$v
                                    )
                                  },
                                  expression: "form.supplierTemplateCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "模板内容：",
                                prop: "templateContent",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "1000",
                                  placeholder:
                                    "内容长度不能超过1000个字（含短信签名）",
                                  clearable: "",
                                  type: "textarea",
                                  autosize: { minRows: 4, maxRows: 4 },
                                  resize: "none",
                                },
                                model: {
                                  value: _vm.form.templateContent,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "templateContent", $$v)
                                  },
                                  expression: "form.templateContent",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "el-checkbox",
                                {
                                  model: {
                                    value: _vm.form.enabled,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "enabled", $$v)
                                    },
                                    expression: "form.enabled",
                                  },
                                },
                                [_vm._v("是否立即启用模板")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "el-checkbox",
                                {
                                  model: {
                                    value: _vm.form.subordinate,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "subordinate", $$v)
                                    },
                                    expression: "form.subordinate",
                                  },
                                },
                                [_vm._v("允许下级机构使用")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-check",
                type: "primary",
                plain: "",
                size: "mini",
              },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: { click: _vm.closePage },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }