import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Pagination from '@/components/Pagination';
import policyRequest from '@/api/insurancepolicy';
import OrgSelect from '@/components/OrgSelect';
import ImportDetailDialog from './importDetailDialog';
import CustomerImportDialog from './customerImportDialog';
import storageRequest from '@/api/storage';
import OemSelect from '@/components/OemSelect';
import { downFile, getUserOrgInfoDealerOem } from "@/utils/common";
export default {
  components: {
    Pagination: Pagination,
    OrgSelect: OrgSelect,
    ImportDetailDialog: ImportDetailDialog,
    CustomerImportDialog: CustomerImportDialog,
    OemSelect: OemSelect
  },
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    bizType: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      searchForm: {},
      tableData: [],
      loading: false,
      orgForm: {
        salesOrgCode: '',
        salesOrgName: '',
        salesOrgType: ''
      },
      showDialog: false,
      showImportDialog: false,
      currentId: '',
      importStatusList: [{
        value: 'UPDATED',
        label: '已上传'
      }, {
        value: 'INQUEUE',
        label: '排队中'
      }, {
        value: 'INPROGRESS',
        label: '进行中'
      }, {
        value: 'DONE',
        label: '已完成'
      }, {
        value: 'PAUSED',
        label: '已暂停'
      }, {
        value: 'CANCELED',
        label: '已取消'
      }],
      drawer: false,
      createdDateTime: [],
      timeoutForDownloadTemplate: null
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.resetSearch();
        this.loadData();
      }
      ;
    }
  },
  created: function created() {
    if (this.bizType === "DEALER") {
      this.initData();
    }
    ;
    this.loadData();
  },
  methods: {
    dateChange: function dateChange(start, end, val, type) {
      //日期选择事件
      if (val && val.length > 1) {
        this.searchForm[start] = val[0];
        this.searchForm[end] = val[1];
      } else {
        this.searchForm[start] = null;
        this.searchForm[end] = null;
      }
      ;
    },
    // 初始化数据
    initData: function initData() {
      var orgObj = getUserOrgInfoDealerOem(this.bizType);
      this.searchForm.orgCode = orgObj.salesOrgCode;
      this.searchForm.orgName = orgObj.salesOrgName;
    },
    handleNodeClick: function handleNodeClick(data) {
      // 机构树点击事件
      this.searchForm.orgCode = data.salesOrgCode;
      this.searchForm.orgName = data.salesOrgName;
      this.searchForm.areaType = data.salesOrgType;
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {
        orgName: this.bizType === 'OEM' ? '' : this.searchForm.orgName,
        orgCode: this.bizType === 'OEM' ? '' : this.searchForm.orgCode
      };
      this.orgForm = {
        salesOrgName: this.bizType === 'OEM' ? '全部' : '',
        salesOrgType: '',
        salesOrgCode: ''
      };
      this.createdDateTime = [];
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    breakData: function breakData() {
      // 刷新
      // this.resetSearch();
      this.loadData();
    },
    // 获取列表数据
    loadData: function loadData() {
      var _this = this;
      this.showDialog = false;
      this.showImportDialog = false;
      this.loading = true;
      this.drawer = false;
      if (this.searchForm && this.searchForm.createdTimeEnd) {
        this.searchForm.createdTimeEnd = this.searchForm.createdTimeEnd.split(' ')[0] + ' 23:59:59';
      }
      var data = _objectSpread(_objectSpread({}, this.searchForm), this.listQuery);
      policyRequest.getPolicyImportList(data).then(function (res) {
        _this.loading = false;
        if (res.code === 200) {
          _this.tableData = res.data.list;
          _this.total = res.data.page.recordsTotal;
        }
        ;
      }).catch(function (err) {
        _this.loading = false;
      });
    },
    // 关闭弹窗
    closePage: function closePage() {
      this.$emit('closePage');
    },
    // 详情
    toDetails: function toDetails(item) {
      this.currentId = item.policyImportId;
      this.showDialog = true;
    },
    // 导入
    importFile: function importFile(item) {
      var _this2 = this;
      policyRequest.policyImportExec(item.policyImportId).then(function (res) {
        if (res.code === 200) {
          _this2.$message.success("\u5BFC\u5165\u6210\u529F\uFF0C\u540E\u53F0\u6B63\u5728\u6267\u884C\u5BFC\u5165\u4EFB\u52A1\uFF0C\u8BF7\u8010\u5FC3\u7B49\u5F85\u3002");
          _this2.loadData();
        }
        ;
      });
    },
    // 下载模板防抖
    downloadTemplate: function downloadTemplate() {
      var _this3 = this;
      if (this.timeoutForDownloadTemplate) {
        clearTimeout(this.timeoutForDownloadTemplate);
      }
      this.timeoutForDownloadTemplate = setTimeout(function () {
        _this3.downloadTemplate1();
      }, 500);
    },
    // 下载模板
    downloadTemplate1: function downloadTemplate1() {
      var _this4 = this;
      storageRequest.downFileTemplate("VI-POLICY-IMPORT-TEMPLATE").then(function (res) {
        if (res.data && res.data.fileStorageId) {
          downFile(res.data.fileUrl, res.data.fileName);
        } else {
          _this4.$message.warning('暂无模板，请联系客服！');
        }
        ;
      });
    },
    // 打开文件
    openFile: function openFile(item) {
      downFile(item.fileUrl, item.fileName);
    },
    // 上传文件
    uploadFile: function uploadFile() {
      this.showImportDialog = true;
    },
    // 暂停、恢复、取消
    operation: function operation(item, type) {
      var _this5 = this;
      this.$confirm('您确定要执行该操作吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        policyRequest.policyImportOperate(item.policyImportId, type).then(function (res) {
          if (res.code === 200) {
            _this5.$message.success(res.msg);
            _this5.loadData();
          }
          ;
        });
      }).catch(function () {});
    }
  }
};