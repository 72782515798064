var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.formLoading,
          expression: "formLoading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: {},
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "500px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "addForm",
                  staticStyle: { "margin-bottom": "-20px" },
                  attrs: {
                    model: _vm.form,
                    rules: _vm.formRules,
                    "label-width": "110px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "地区类型:",
                                prop: "districtType",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: "请选择地区类型",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.form.districtType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "districtType", $$v)
                                    },
                                    expression: "form.districtType",
                                  },
                                },
                                _vm._l(
                                  _vm.districtTypeList,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.name,
                                        value: item.code,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.form.districtType == "province"
                        ? _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "请选择省:",
                                    prop: "districtCode",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        placeholder: "请选择省",
                                        clearable: "",
                                      },
                                      on: { change: _vm.getdistrictName },
                                      model: {
                                        value: _vm.form.districtCode,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "districtCode",
                                            $$v
                                          )
                                        },
                                        expression: "form.districtCode",
                                      },
                                    },
                                    _vm._l(_vm.proList, function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.name,
                                          value: item.code,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "请选择市:",
                                    prop: "districtCode",
                                  },
                                },
                                [
                                  _c("el-cascader", {
                                    ref: "cascaderAddr",
                                    staticStyle: { width: "100%" },
                                    attrs: { options: _vm.cityOptions },
                                    on: { change: _vm.handleChange },
                                    model: {
                                      value: _vm.cascaderAddr,
                                      callback: function ($$v) {
                                        _vm.cascaderAddr = $$v
                                      },
                                      expression: "cascaderAddr",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "提前续保天数:", prop: "days" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入提前续保天数",
                                  type: "text",
                                  clearable: "",
                                },
                                on: {
                                  input: function ($event) {
                                    _vm.form.days = _vm.onInput(
                                      _vm.form.days,
                                      0,
                                      365,
                                      0
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.form.days,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "days", $$v)
                                  },
                                  expression: "form.days",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-check",
                type: "primary",
                plain: "",
                size: "mini",
              },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: {
                click: function ($event) {
                  return _vm.closePage(true)
                },
              },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }