import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//

import { mapGetters } from "vuex";
import adminDashboard from "./admin";
export default {
  name: "Dashboard",
  components: {
    adminDashboard: adminDashboard
  },
  data: function data() {
    return {
      currentRole: "adminDashboard"
    };
  },
  computed: _objectSpread({}, mapGetters(["permission_routes"])),
  created: function created() {},
  mounted: function mounted() {
    var _this = this;
    this.permission_routes.forEach(function (element) {
      if (element.redirect == '/workbench') {
        var child = _.find(element.children, function (item) {
          return item.name == 'MapStatistics';
        });
        if (child && !child.hidden) {
          _this.$store.dispatch('tagsView/delView', _this.$route);
          _this.$router.push({
            path: child.path
          });
        }
      }
    });
  }
};