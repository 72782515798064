import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.concat.js";
/**
 * 报表接口
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 获取下级经销商列表
   */
  getSubDealerList: function getSubDealerList(data) {
    return request.get(getUrl("/dealer/info/getSubDealerList", 13), {
      params: data
    });
  },
  /**
   * 分页查询线索
   */
  getCluesList: function getCluesList(data) {
    return request.get(getUrl("/repair/clues", 15), {
      params: data
    });
  },
  /**
   * 查询工单
   */
  getOrderlist: function getOrderlist(data) {
    return request.get(getUrl("/repair/order", 15), {
      params: data
    });
  },
  /**
   * 我的工单
   */
  getMyOrderlist: function getMyOrderlist(data) {
    return request.get(getUrl("/repair/order/myOrder", 15), {
      params: data
    });
  },
  /**
   * 获取工单详情
   */
  getOrderDetail: function getOrderDetail(orderId) {
    return request.get(getUrl("/repair/order/".concat(orderId), 15));
  },
  /**
   * 创建工单
   */
  createOrder: function createOrder(data) {
    return request.post(getUrl("/repair/order", 15), data);
  },
  /**
   * 修改工单
   */
  updateOrder: function updateOrder(orderId, data) {
    return request.put(getUrl("/repair/order/".concat(orderId), 15), data);
  },
  /**
   * 工单维修
   */
  repairOrder: function repairOrder(orderId, data) {
    return request.put(getUrl("/repair/order/repair/".concat(orderId), 15), data);
  },
  /**
   * 交车
   */
  payOrder: function payOrder(orderId, data) {
    return request.put(getUrl("/repair/order/pay/".concat(orderId), 15), data);
  },
  /**
   * 工单分配
   */
  distributionOrder: function distributionOrder(orderId, data) {
    return request.post(getUrl("/repair/order/distribution/".concat(orderId), 15), data);
  },
  /**
   * 工单关闭
   */
  colseOrder: function colseOrder(orderId, data) {
    return request.put(getUrl("/repair/order/close/".concat(orderId), 15), data);
  },
  /**
   * 工单定损
   */
  fiexdOrder: function fiexdOrder(orderId, data) {
    return request.post(getUrl("/repair/order/fiexd/".concat(orderId), 15), data);
  },
  /**
   * 跟进工单
   */
  trackOrder: function trackOrder(orderId, data) {
    return request.post(getUrl("/repair/order/track/".concat(orderId), 15), data);
  },
  //  =======================================救援机构=======================================
  /**
   * 获取救援机构列表
   */
  getAid: function getAid(data) {
    return request.get(getUrl("/repair/aid", 15), {
      params: data
    });
  },
  /**
   * 新增救援机构信息
   */
  addAid: function addAid(data) {
    return request.post(getUrl("/repair/aid", 15), data);
  },
  /**
   * 获取救援机构信息
   */
  getAidDetail: function getAidDetail(aidId) {
    return request.get(getUrl("/repair/aid/".concat(aidId), 15));
  },
  /**
   * 修改救援机构信息
   */
  updateAid: function updateAid(aidId, data) {
    return request.put(getUrl("/repair/aid/".concat(aidId), 15), data);
  },
  /**
   * 删除救援机构信息
   */
  deleteAid: function deleteAid(ids) {
    return request.delete(getUrl("/repair/aid?ids=".concat(ids.toString()), 15));
  },
  //  =======================================派单规则=======================================
  /**
   * 查询派单规则信息分页列表
   */
  getSendRulelist: function getSendRulelist(data) {
    return request.get(getUrl("/repair/setting/sendRule", 15), {
      params: data
    });
  },
  /**
   * 新增派单规则信息
   */
  addSendRule: function addSendRule(data) {
    return request.post(getUrl("/repair/setting/sendRule", 15), data);
  },
  /**
   * 启用禁用派单规则信息
   */
  enabledSendRule: function enabledSendRule(sendRuleId, data) {
    return request.put(getUrl("/repair/setting/sendRule/enabled/".concat(sendRuleId, "?enabled=").concat(data), 15));
  },
  /**
   * 根据经销商编码查询员工列表
   */
  getDealerlist: function getDealerlist(data) {
    return request.get(getUrl("/dealer/employee/byDealerCode/list", 13), {
      params: data
    });
  },
  /**
   * 获取派单规则信息
   */
  getSendRuleDetail: function getSendRuleDetail(sendRuleId) {
    return request.get(getUrl("/repair/setting/sendRule/".concat(sendRuleId), 15));
  },
  /**
   * 修改派单规则信息
   */
  updateSendRule: function updateSendRule(sendRuleId, data) {
    return request.put(getUrl("/repair/setting/sendRule/".concat(sendRuleId), 15), data);
  },
  /**
   * 批量删除派单规则信息
   */
  delSendRule: function delSendRule(ids) {
    return request.delete(getUrl("/repair/setting/sendRule?ids=".concat(ids.toString()), 15));
  },
  /**
   * 启用禁用派单规则信息
   */
  closeSendRule: function closeSendRule(templateId, data) {
    return request.put(getUrl("/repair/template/enabled/".concat(templateId), 15), data);
  },
  //  =======================================构虚拟号=======================================
  /**
   * 查询机构虚拟号信息分页列表
   */
  virtualPhone: function virtualPhone(data) {
    return request.get(getUrl("/repair/setting/virtualPhone", 15), {
      params: data
    });
  },
  /**
   * 新增机构虚拟号信息
   */
  addVirtualPhone: function addVirtualPhone(data) {
    return request.post(getUrl("/repair/setting/virtualPhone", 15), data);
  },
  /**
   * 获取机构虚拟号信息
   */
  getVirtualPhoneInfo: function getVirtualPhoneInfo(id) {
    return request.get(getUrl("/repair/setting/virtualPhone/".concat(id), 15));
  },
  /**
   * 修改机构虚拟号信息
   */
  updateVirtualPhone: function updateVirtualPhone(id, data) {
    return request.put(getUrl("/repair/setting/virtualPhone/".concat(id), 15), data);
  },
  /**
   * 批量删除机构虚拟号信息
   */
  delVirtualPhone: function delVirtualPhone(ids) {
    return request.delete(getUrl("/repair/setting/virtualPhone?ids=".concat(ids.toString()), 15));
  },
  // =======================================模板配置=======================================
  /**
   * 查询模板配置分页列表
   */
  getTemplatelist: function getTemplatelist() {
    return request.get(getUrl("/repair/template/config/list", 15));
  },
  /**
   * 模板配置（新增修改）
   */
  addTemplate: function addTemplate(data) {
    return request.post(getUrl("/repair/template/config/save", 15), data);
  },
  /**
   * 批量删除模板信息
   */
  delTemplate: function delTemplate(ids) {
    return request.delete(getUrl("/repair/template?ids=".concat(ids.toString()), 15));
  },
  /**
   * 查询模板信息分页列表
   */
  getTemplate: function getTemplate(ids) {
    return request.get(getUrl("/repair/template", 15));
  },
  /**
   * 新增模板信息
   */
  createTemplate: function createTemplate(data) {
    return request.post(getUrl("/repair/template", 15), data);
  },
  /**
   * 启用/禁用模板信息
   */
  Template: function Template(templateId, enabled) {
    return request.put(getUrl("/repair/template/enabled/".concat(templateId, "?enabled=").concat(enabled), 15));
  },
  /**
   * 获取模板信息详情
   */
  getTemplateInfo: function getTemplateInfo(templateId) {
    return request.get(getUrl("/repair/template/".concat(templateId), 15));
  },
  /**
   * 启用/禁用模板信息
   */
  updateTemplate: function updateTemplate(templateId, data) {
    return request.put(getUrl("/repair/template/".concat(templateId), 15), data);
  },
  /**
   * 解析线索模板
   */
  TemplateParse: function TemplateParse(data) {
    return request.get(getUrl("/repair/template/parse", 15), {
      params: data
    });
  }
};
export default objApi;