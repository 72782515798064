import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from "../../mixins/initHeight";
import Pagination from "@/components/Pagination";
import OrgSelect from "@/components/OrgSelect";
import request from "@/api/batchSync";
import employeeRequest from '@/api/employee';
import { getSupplyers, getBeforeDate, getCurrentDate, commonExport } from "@/utils/common";
export default {
  name: "syncResult",
  components: {
    Pagination: Pagination,
    OrgSelect: OrgSelect
  },
  mixins: [initHeight],
  data: function data() {
    return {
      status: {
        'WAITING': '等待中',
        'QUERYING': '查询中',
        'NORESULT': '查无结果',
        'SUCCESS': '待分配',
        'FAILED': '查询失败',
        'ASSIGNED': '已分配',
        'ASSIGNFAILED': '分配失败',
        'DISPOSE': '失败已处理'
      },
      drawer: false,
      searchForm: {
        tabType: "All",
        createdTimeStart: getBeforeDate(6),
        createdTimeEnd: getCurrentDate()
      },
      startEndTime: [getBeforeDate(6), getCurrentDate()],
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      loading: false,
      orgData: {
        salesOrgName: "",
        salesOrgCode: ""
      },
      formLoading: false,
      polcyForm: {},
      showDistributionDialog: false,
      syncPolicyItemId: null,
      distributionFormRules: {
        salesId: [{
          required: true,
          message: "请选择业务员",
          trigger: "change"
        }]
      },
      distributionForm: {},
      tabTypeList: [],
      jobConfigCode: null,
      opType: "",
      employeeList: [],
      supplyers: [],
      regionOptions: [],
      channels: [],
      placeholder: "\u5F55\u5165\u8F66\u724C\u53F7\u7801\uFF0C\u8F66\u67B6\u53F7\uFF0C\u4E00\u6761\u4E00\u884C\n\u793A\u4F8B\uFF1A\n\u7CA4B12345\n\u7CA4B123456\nLGBG42E06LY300882\n            "
    };
  },
  watch: {},
  created: function created() {
    this.loadData();
    this.supplyers = getSupplyers();
    var city = JSON.parse(localStorage.getItem("VI_CityAreaTree")) || [];
    var partialAddress = [];
    city.forEach(function (item, index) {
      partialAddress.push({
        label: item.label,
        value: item.value,
        children: item.children ? [] : null
      });
      if (item.children) {
        item.children.forEach(function (eitem) {
          partialAddress[index].children.push({
            label: eitem.label == "市辖区" ? item.label : eitem.label,
            value: eitem.value
          });
        });
      }
    });
    this.regionOptions = partialAddress;
  },
  methods: {
    // 导出
    exportExcels: function exportExcels() {
      var searchForm = _objectSpread(_objectSpread(_objectSpread({}, this.searchForm), this.listQuery), {}, {
        count: false
      });
      var exporParams = {
        total: this.total,
        fileName: '批量同步结果列表',
        exportUrl: '/vi-core-service/syncPolicy/searchResult',
        searchForm: searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    // 日期选择事件
    dateChange: function dateChange(start, end, val) {
      if (val && val.length > 1) {
        this.searchForm[start] = val[0];
        this.searchForm[end] = val[1];
      } else {
        this.searchForm.createdTimeStart = null;
        this.searchForm.createdTimeStart = null;
      }
    },
    // 分配
    distribution: function distribution(row) {
      var _this = this;
      this.showDistributionDialog = true;
      this.syncPolicyItemId = row.syncPolicyItemId;
      employeeRequest.salesmanByOrgCode(row.orgCode).then(function (result) {
        _this.employeeList = result.data;
      });
    },
    distributionOrSearch: function distributionOrSearch(status, param) {
      var _this2 = this;
      request.distributionOrSearch(status, param).then(function (res) {
        if (res.code == 200) {
          _this2.showDistributionDialog = false;
          _this2.$message.success(res.msg);
          _this2.loadData();
        }
      });
    },
    saveDistribution: function saveDistribution() {
      var _this3 = this;
      this.$refs["distributionForm"].validate(function (valid) {
        if (valid) {
          var postData = _objectSpread({
            resultIdList: [_this3.syncPolicyItemId]
          }, _this3.distributionForm);
          _this3.distributionOrSearch('ASSIGN', postData);
        }
      });
    },
    // 重置搜索表单
    resetSearch: function resetSearch() {
      this.searchForm = {
        tabType: "All",
        createdTimeStart: getBeforeDate(6),
        createdTimeEnd: getCurrentDate()
      };
      this.startEndTime = [getBeforeDate(6), getCurrentDate()];
      this.orgData = {};
    },
    // 搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    // 刷新
    breakData: function breakData() {
      this.searchData();
    },
    // 获取列表数据
    loadData: function loadData() {
      var _this4 = this;
      this.showJobConfigDialog = false;
      this.loading = true;
      this.drawer = false;
      if (this.searchForm.createdTimeStart) {
        this.searchForm.createdTimeStart = this.searchForm.createdTimeStart.split(' ')[0] + ' 00:00:00';
      }
      if (this.searchForm.createdTimeEnd) {
        this.searchForm.createdTimeEnd = this.searchForm.createdTimeEnd.split(' ')[0] + ' 23:59:59';
      }
      request.getResultList(_objectSpread(_objectSpread({}, this.searchForm), this.listQuery)).then(function (res) {
        _this4.loading = false;
        _this4.tableData = res.data.list;
        //总条数
        _this4.total = res.data.page.recordsTotal;
      }).catch(function (err) {
        _this4.loading = false;
      });
      request.getAllQuantity(_objectSpread(_objectSpread({}, this.searchForm), this.listQuery)).then(function (res) {
        _this4.tabTypeList = [{
          dictName: "\u67E5\u8BE2\u5931\u8D25(".concat(res.data.FAILED, ")"),
          dictCode: 'FAILED'
        }, {
          dictName: "\u67E5\u65E0\u7ED3\u679C(".concat(res.data.NORESULT, ")"),
          dictCode: 'NORESULT'
        }, {
          dictName: "\u5206\u914D\u5931\u8D25(".concat(res.data.ASSIGNFAILED, ")"),
          dictCode: 'ASSIGNFAILED'
        }, {
          dictName: "\u5F85\u5206\u914D(".concat(res.data.SUCCESS, ")"),
          dictCode: 'SUCCESS'
        }, {
          dictName: "\u5DF2\u5206\u914D(".concat(res.data.ASSIGNED, ")"),
          dictCode: 'ASSIGNED'
        }, {
          dictName: "\u5931\u8D25\u5DF2\u5904\u7406(".concat(res.data.DISPOSE, ")"),
          dictCode: 'DISPOSE'
        }, {
          dictName: "\u5168\u90E8",
          dictCode: 'All'
        }];
      });
    }
  }
};