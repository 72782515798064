var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: {
            size: "mini",
            data: _vm.tableData,
            height: _vm.tableHeightUC,
            "header-cell-style": { background: "#F7F7F7" },
            "element-loading-text": "加载中...",
            border: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "netChannelCode",
              label: "C端渠道代码",
              "min-width": "150",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "netChannelUserId",
              label: "C端渠道用户标识",
              "min-width": "150",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "createdTime",
              label: "创建时间",
              width: "150",
              "show-overflow-tooltip": "",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }