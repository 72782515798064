import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import requestApi from '@/api/sysSetting';
import storageRequest from '@/api/storage';
import { downFile } from "@/utils/common";
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    opType: {
      type: String,
      default: ''
    },
    currentId: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      form: {
        fileCode: '',
        fileName: '',
        fileStorageId: '',
        fileType: '',
        remark: '',
        sortWeight: ''
      },
      rules: {
        fileCode: {
          required: true,
          message: '请输入文件编码',
          trigger: "blur"
        }
      },
      fileList: []
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      this.fileList = [];
      if (newValue) {
        this.initData();
      }
      ;
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    initData: function initData() {
      var _this = this;
      this.form = {
        fileCode: '',
        fileName: '',
        fileStorageId: '',
        fileType: '',
        remark: '',
        sortWeight: ''
      };
      if (this.opType === "edit") {
        requestApi.getFileTemplateInfo(this.currentId).then(function (res) {
          if (res.code === 200) {
            _this.form = res.data;
            _this.downloadFile(_this.form);
          }
          ;
        });
      }
      ;
      this.$refs.detail.resetFields();
    },
    // 上传文件
    uploadFile: function uploadFile(param) {
      var _this2 = this;
      var formData = new FormData();
      formData.append('file', param.file);
      storageRequest.uploadFile(formData).then(function (res) {
        if (res.code === 200) {
          setTimeout(function () {
            _this2.$message.success(res.msg);
            _this2.form.fileStorageId = res.data.fileStorageId;
            _this2.form.fileUrl = res.data.fileUrl;
            _this2.form.fileType = param.file.type;
            _this2.form.fileName = param.file.name;
          }, 1000);
        }
        ;
      });
    },
    clearData: function clearData() {
      this.form.fileStorageId = '';
      this.form.fileType = '';
      this.form.fileName = '';
    },
    // 附件校验
    validate: function validate() {
      var flag = true;
      if (!this.form.fileStorageId) {
        this.$message.warning('请上传文件');
        flag = false;
        return flag;
      }
      ;
      return flag;
    },
    // 保存数据
    save: function save() {
      var _this3 = this;
      this.$refs.detail.validate(function (valid) {
        if (valid) {
          if (!_this3.validate()) return;
          var req = _this3.opType === 'add' ? requestApi.addFileTemplate(_this3.form) : requestApi.updateFileTemplate(_this3.form);
          req.then(function (res) {
            if (res.code === 200) {
              _this3.$message.success(res.msg);
              _this3.closePage();
            }
            ;
          });
        }
        ;
      });
    },
    //关闭弹窗
    closePage: function closePage() {
      this.$emit('closePage');
    },
    downloadFile: function downloadFile(obj) {
      if (!obj.fileStorageId) return;
      this.viewUrl = obj.fileUrl;
      this.fileList.push({
        url: this.viewUrl,
        name: obj.fileName,
        id: obj.fileStorageId,
        status: 'ready'
      });
    },
    handlePreview: function handlePreview(file) {
      if (this.form.fileStorageId) {
        downFile(this.form.fileUrl, this.form.fileName);
      }
    }
  }
};