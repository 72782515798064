import _defineProperty from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PageContainer from "@/components/PageContainer";
import FormLabel from "@/components/FormSubLabel";
import OemSelect from '@/components/OemSelect';
import insuranceManagement from '@/api/insuranceManagement';
import SYX_RISK from '@/assets/json/SYX_RISK'; //获取商业险险种
import Rules from '@/utils/rules';
import leadManagementApi from '@/api/leadManagement';
import { character, getSupplyers } from "@/utils/common";
export default {
  data: function data() {
    var _formRules;
    return {
      insuranceList: [],
      dutyAllCode: [],
      policyCityItems: JSON.parse(localStorage.getItem('VI_CityPcTree')) || [],
      SZX_OPTIONS: [{
        text: "10万",
        value: "100000"
      }, {
        text: "15万",
        value: "150000"
      }, {
        text: "20万",
        value: "200000"
      }, {
        text: "30万",
        value: "300000"
      }, {
        text: "50万",
        value: "500000"
      }, {
        text: "100万",
        value: "1000000"
      }, {
        text: "150万",
        value: "1500000"
      }, {
        text: "200万",
        value: "2000000"
      }, {
        text: "300万",
        value: "3000000"
      }, {
        text: "400万",
        value: "4000000"
      }, {
        text: "500万",
        value: "5000000"
      }, {
        text: "1000万",
        value: "10000000"
      }, {
        text: "4000万",
        value: "40000000"
      }],
      CKXRX_OPTIONS: [{
        text: "1万",
        value: "10000"
      }, {
        text: "2万",
        value: "20000"
      }, {
        text: "3万",
        value: "30000"
      }, {
        text: "5万",
        value: "50000"
      }, {
        text: "10万",
        value: "100000"
      }, {
        text: "15万",
        value: "150000"
      }, {
        text: "20万",
        value: "200000"
      }, {
        text: "30万",
        value: "300000"
      }],
      RATE_OPTIONS: [{
        text: "5%",
        value: "5"
      }, {
        text: "10%",
        value: "10"
      }, {
        text: "15%",
        value: "15"
      }, {
        text: "20%",
        value: "20"
      }],
      disabled: true,
      isNewCar: false,
      loadingInsurance: false,
      loadingSave: false,
      formLoading: false,
      bizType: localStorage.getItem("bizType") || '',
      userInfo: JSON.parse(localStorage.getItem('userInfo')) || {},
      activeOne: '1',
      activeTwo: '1',
      activeThree: '1',
      correctingList: [{
        label: '加保',
        value: 'ADD'
      }, {
        label: '减保',
        value: 'REDUCE'
      }, {
        label: '退保',
        value: 'SECEDE'
      }],
      form: {
        riskType: 'BS',
        riskKinds: [],
        // bsRiskKind: {}, //商业险
        orgName: '',
        orgCode: '',
        salemanBy: ''
      },
      formRules: (_formRules = {
        orgCode: {
          required: true,
          message: '请选择组织机构',
          trigger: ["blur", "change"]
        },
        salemanBy: [{
          required: true,
          message: '请选择业务员',
          trigger: ["change"]
        }],
        correctNo: [{
          required: true,
          message: '请输入批单号',
          trigger: 'blur'
        }],
        correctDate: [{
          required: true,
          message: '请选择批单日期',
          trigger: 'change'
        }],
        policyNo: [{
          required: true,
          message: '请输入保单号',
          trigger: 'blur'
        }],
        insCorpCode: [{
          required: true,
          message: '请选择投保公司',
          trigger: 'change'
        }],
        insCityCode: [{
          required: true,
          message: '请选择投保地区',
          trigger: 'change'
        }],
        plateNo: [{
          required: true,
          message: '请输入车牌号',
          trigger: 'blur'
        }, {
          min: 7,
          max: 8,
          message: "输入的车牌号长度小于7位",
          trigger: ["blur", "change"]
        }, {
          pattern: Rules["PLATENO_RULE"].value,
          message: "车牌号格式有误",
          trigger: ["blur", "change"]
        }],
        vin: [{
          required: true,
          message: '请输入车架号',
          trigger: 'blur'
        }, {
          pattern: Rules["VIN_RULE"].value,
          message: "车架号格式有误",
          trigger: ["blur", "change"]
        }],
        ownerName: [{
          required: true,
          message: '请输入车主名',
          trigger: "blur"
        }],
        applicantName: [{
          required: true,
          message: '请输入车主名',
          trigger: "blur"
        }]
      }, _defineProperty(_formRules, "applicantName", [{
        required: true,
        message: '请输入车主名',
        trigger: "blur"
      }]), _defineProperty(_formRules, "insurantName", [{
        required: true,
        message: '请输入被保人名称',
        trigger: "blur"
      }]), _defineProperty(_formRules, "policyNo", [{
        required: false,
        message: '请输入交强险保单号',
        trigger: "blur"
      }]), _defineProperty(_formRules, "jqxzje", [{
        required: false,
        message: '请输入交强险金额',
        trigger: "blur"
      }]), _defineProperty(_formRules, "premium", [{
        required: false,
        message: '请输入商业险保单号',
        trigger: "blur"
      }]), _defineProperty(_formRules, "premium", [{
        required: false,
        message: '请输入商业险总金额',
        trigger: "blur"
      }]), _formRules),
      salesman: [],
      orgForm: {
        salesOrgCode: '',
        salesOrgName: '',
        salesOrgType: ''
      }
    };
  },
  components: {
    FormLabel: FormLabel,
    PageContainer: PageContainer,
    OemSelect: OemSelect
  },
  created: function created() {
    this.dutyAllCode = SYX_RISK; //获取商业险险别
  },
  mounted: function mounted() {
    this.getInsuranceData();
    if (this.bizType === "DEALER") {
      this.fetchUsers();
      this.form.orgCode = this.userInfo.data.organization.dealerCode;
      this.form.orgName = this.userInfo.data.organization.dealerName;
    }
  },
  methods: {
    fetchUsers: function fetchUsers() {
      var _this = this;
      // 经销商获取业务员列表(组织机构)
      // employeeStatus	employeeStatus,可用值:REGULAR,DIMISSION
      // employeeType,可用值:OFFICE_WORK,SALESMAN
      var data = {
        employeeStatus: 'REGULAR',
        employeeType: 'SALESMAN'
      };
      leadManagementApi.dealerEmployees(data).then(function (res) {
        if (res.code && res.data) {
          _this.salesman = res.data;
          if (!_.isEmpty(_this.salesman)) {
            _this.form.salemanBy = _this.salesman[0].userId;
            _this.form.salemanName = _this.salesman[0].userName;
          }
        }
      });
    },
    queryInsurance: function queryInsurance(policyNo) {
      //保单号查询
      this.loadingInsurance = true;
    },
    cityHandleChange: function cityHandleChange(val) {
      //省市选择事件
      if (!val) return;
      this.form.insProvinceCode = val[0];
      this.form.insCityCode = val[1];
      //获取地区名称
      var regionName = '';
      var province = _.find(this.policyCityItems, function (item) {
        return item.value == val[0];
      });
      if (province) {
        regionName = province.label;
        this.form.insProvinceName = province.label;
        var city = _.find(province.children, function (item) {
          return item.value == val[1];
        });
        if (city) {
          regionName = regionName + ' ' + city.label;
          this.form.insCityName = city.label;
        }
      }
      this.$forceUpdate();
    },
    handleRiskItem: function handleRiskItem(item) {
      //险种选择
      item.premium = '';
    },
    addInsurance: function addInsurance(type) {
      //添加行
      if (type === 'one') {
        this.form.riskKinds.push({
          riskkindName: '',
          amount: '',
          premium: '',
          correctType: ''
        });
      }
    },
    deleteInsurance: function deleteInsurance(type, i) {
      //删除行
      if (type === 'one') {
        this.form.riskKinds.splice(i, 1);
      }
    },
    correctingChange: function correctingChange(val) {
      //批改类型选择事件
      if (!val) return;
    },
    noPlateNoChange: function noPlateNoChange(val) {
      // 未上牌勾选事件
      if (val) {
        this.form.plateNo = '';
        this.formRules.plateNo[0].required = false;
      } else {
        this.formRules.plateNo[0].required = true;
      }
      this.$forceUpdate();
    },
    inputPlateNo: function inputPlateNo(val) {
      // 车牌号自动转大写
      if (val) {
        this.form.plateNo = _.toUpper(val);
      }
    },
    inputvin: function inputvin(val) {
      // 车架号监听输入事件
      if (val) {
        val = val.replace(/i/g, '').replace(/I/g, '').replace(/o/g, '').replace(/O/g, '').replace(/q/g, '').replace(/Q/g, '');
        this.form.vin = character(_.toUpper(val));
        this.$forceUpdate();
      }
    },
    checkRiskNo: function checkRiskNo(val) {
      //自动将业务单号转为大写(保单号)
      this.form.policyNo = character(_.toUpper(val));
    },
    checkBzRiskNo: function checkBzRiskNo(val) {
      //自动将业务单号转为大写(交强险险保单号)
      this.form.policyNo = character(_.toUpper(val));
    },
    checkBsRiskNo: function checkBsRiskNo(val) {
      //自动将业务单号转为大写(商业险保单号)
      this.form.premium = character(_.toUpper(val));
    },
    checkPdRiskNo: function checkPdRiskNo(val) {
      //自动将业务单号转为大写(批单号)
      this.form.correctNo = character(_.toUpper(val));
    },
    getInsuranceData: function getInsuranceData() {
      //获取保司列表
      this.insuranceList = getSupplyers();
      if (this.insuranceList) {
        _.remove(this.insuranceList, function (o) {
          return o.code == 'QITA' || o.code == 'qita';
        });
      }
    },
    handleNodeClick: function handleNodeClick(val) {
      //组织机构监听事件
      if (!val) return;
      this.salesman = [];
      this.form.salemanBy = '';
      this.form.orgCode = val.salesOrgCode;
      this.form.orgName = val.salesOrgName;
      this.$forceUpdate();
    },
    oemGetUsers: function oemGetUsers(val) {
      //业务员赋值
      if (!val) return;
      this.form.salemanBy = '';
      this.form.salemanName = '';
      this.salesman = val;
    },
    salemanNameChange: function salemanNameChange(val) {
      //业务员监听事件
      if (!val) return;
      this.form.salemanBy = val;
      var obj = {};
      obj = _.find(this.salesman, function (item) {
        return item.userId == val;
      });
      this.form.salemanName = obj.userName;
      this.$forceUpdate();
    },
    insCorpChange: function insCorpChange(val) {
      //保险公司选择
      if (!val) return;
      var obj = {};
      obj = _.find(this.insuranceList, function (item) {
        return item.code == val;
      });
      this.form.insCorpName = obj.shortName;
    },
    goBack: function goBack() {
      //返回到上一页
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.go(-1);
    },
    save: function save() {
      var _this2 = this;
      //保存
      //交强险
      if (this.form.riskType == 'BZ') {
        var postData = {};
        postData = {
          correctType: this.form.correctType,
          premium: this.form.premium
        };
        this.form.riskKinds.push(postData);
      }
      //检查是否添加了重复险别
      if (!_.isEmpty(this.form.riskKinds) && this.form.riskType == 'BS') {
        this.form.riskKinds.forEach(function (duty) {
          var risk = _.find(_this2.dutyAllCode, function (item) {
            return item.dictCode == duty.riskkindCode;
          });
          if (risk) duty.riskkindName = risk.dictName;
        });
        for (var i = 0; i < this.form.riskKinds.length - 1; i++) {
          for (var j = i + 1; j < this.form.riskKinds.length; j++) {
            if (this.form.riskKinds[i].riskkindCode === this.form.riskKinds[j].riskkindCode) {
              this.$message({
                type: "error",
                message: "\u60A8\u6DFB\u52A0\u4E86\u91CD\u590D\u7684\u3010".concat(this.form.riskKinds[j].riskkindName, "\u3011\uFF0C\u8BF7\u4ED4\u7EC6\u6838\u5BF9\uFF01")
              });
              return;
            }
          }
        }
      }
      this.$refs['form'].validate(function (valid, obj) {
        if (valid) {
          if (_.isEmpty(_this2.form.riskKinds)) {
            _this2.$message({
              type: "warning",
              message: "请选择要批改的险种信息"
            });
            return;
          }
          _this2.formLoading = true;
          _this2.loadingSave = true;
          insuranceManagement.addData(_this2.form).then(function (res) {
            if (res.code === 200) {
              _this2.$message({
                type: "success",
                message: res.msg
              });
              _this2.loadingSave = false;
              _this2.formLoading = false;
              setTimeout(function () {
                _this2.goBack();
              }, 300);
            }
          }).catch(function (err) {
            _this2.loadingSave = false;
            _this2.formLoading = false;
          });
        }
      });
    }
  }
};