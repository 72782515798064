import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import dict from '../../mixins/dict';
import initHeight from '@/mixins/initHeight';
import Pagination from '@/components/Pagination';
import DatePicker from '@/components/DatePicker';
import activityRequest from '../../api/insuranceActivities';
import { commonExport } from '@/utils/common';
export default {
  name: 'ActivityList',
  components: {
    Pagination: Pagination,
    DatePicker: DatePicker
  },
  mixins: [dict, initHeight],
  computed: {
    tableHeight: function tableHeight() {
      return document.body.clientHeight - 215 - 107 + 'px';
    }
  },
  data: function data() {
    return {
      activityTime: [],
      searchForm: {
        activityName: ''
      },
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      multipleSelection: [],
      loading: false
    };
  },
  watch: {
    '$route': function $route(to, from) {
      //监听路由是否变化
      this.searchForm = {
        activityName: '',
        startEndTime: []
      };
      this.loadData();
    }
  },
  mounted: function mounted() {
    this.loadData();
  },
  methods: {
    // 日期选择事件
    getPickersDate: function getPickersDate(name, val) {
      if (val && val.length > 1) {
        this[name] = val;
      } else {
        this[name] = [];
      }
    },
    exportFn: function exportFn() {
      if (this.activityTime) {
        this.searchForm.activityStartTime = this.activityTime[0] ? this.activityTime[0].split(' ')[0] + ' 00:00:00' : null;
        this.searchForm.activityEndTime = this.activityTime[1] ? this.activityTime[1].split(' ')[0] + ' 23:59:59' : null;
      }
      var searchForm = {
        activityName: this.searchForm.activityName,
        activityStartTime: this.searchForm.activityStartTime,
        activityEndTime: this.searchForm.activityEndTime
      };
      var exporParams = {
        total: this.total,
        fileName: '活动列表',
        exportUrl: '/ps-ftms-service/activitys',
        searchForm: searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    dateChange: function dateChange(val) {
      //日期选择事件
      if (val && val.length > 1) {
        this.searchForm.startEndTime = val;
      } else {
        this.searchForm.startEndTime = [];
      }
      this.loadData();
    },
    viewDetail: function viewDetail(item) {
      // 查看活动详情
      var router_path = this.$route.path;
      this.$router.push({
        path: "/activityD/detail",
        query: {
          activityId: item.activityId,
          back: router_path
        }
      });
    },
    breakData: function breakData() {
      // this.searchForm = {
      //     activityName: '',
      //     startEndTime: []
      // }
      this.loadData();
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {
        activityName: ''
      };
      this.activityTime = [];
      // this.loadData();
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    loadData: function loadData() {
      var _this = this;
      //获取列表数据
      this.loading = true;
      if (this.activityTime) {
        this.searchForm.activityStartTime = this.activityTime[0] ? this.activityTime[0].split(' ')[0] + ' 00:00:00' : null;
        this.searchForm.activityEndTime = this.activityTime[1] ? this.activityTime[1].split(' ')[0] + ' 23:59:59' : null;
      }
      var params = {
        pageNum: this.listQuery.pageNum,
        pageSize: this.listQuery.pageSize,
        activityName: this.searchForm.activityName,
        activityStartTime: this.searchForm.activityStartTime,
        activityEndTime: this.searchForm.activityEndTime
      };
      activityRequest.getActivitysDdList(params).then(function (res) {
        _this.loading = false;
        if (!_.isEmpty(res.data)) {
          _this.tableData = res.data.list;
          _this.total = res.data.page.recordsTotal;
        }
      }).catch(function (err) {
        _this.loading = false;
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      //列表行选择事件
      this.multipleSelection = val;
    }
  }
};