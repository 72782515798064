import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PageContainer from '@/components/PageContainer';
import FormLabel from '@/components/FormSubLabel';
import request from '@/api/customers';
import { getSupplyers } from '@/utils/common';
export default {
  name: 'MaintainRecordDetail',
  components: {
    FormLabel: FormLabel,
    PageContainer: PageContainer
  },
  data: function data() {
    return {
      supplyers: [],
      // 客户类别枚举
      customerTypesList: [{
        value: 'NEW',
        label: '新保'
      }, {
        value: 'RENEWAL',
        label: '续保'
      }, {
        value: 'NON_POLICY',
        label: '在修不在保'
      }],
      form: {
        maintenanceList: [],
        partsList: []
      },
      currentId: '',
      activeOne: "1",
      activeTwo: "1",
      activeThree: "1",
      activeFour: "1",
      activeFive: "1",
      activeSex: "1",
      activeSeven: "1",
      tabType: 'REPAIR',
      orderStatusList: [{
        value: '1',
        label: '等待零件'
      }, {
        value: '2',
        label: '整备中'
      }, {
        value: '3',
        label: '已完成销售'
      }],
      CertiType: [{
        value: '0',
        label: '身份证'
      }, {
        value: '1',
        label: '其他证件'
      }, {
        value: '2',
        label: '军官证'
      }, {
        value: '3',
        label: '护照'
      }],
      CustomIdType: [{
        value: '1',
        label: '私人'
      }, {
        value: '2',
        label: '公司'
      }, {
        value: '3',
        label: '机关'
      }, {
        value: '4',
        label: '组织'
      }, {
        value: '5',
        label: '其他'
      }],
      loading: false,
      timeout1: null
    };
  },
  watch: {
    $route: function $route() {
      this.initView();
    }
  },
  created: function created() {
    this.supplyers = getSupplyers();
    this.initView();
  },
  methods: {
    // 初始化
    initView: function initView() {
      this.currentId = this.$route.query.id || '';
      this.getDetails();
    },
    // 获取详情
    getDetails: function getDetails() {
      var _this = this;
      this.loading = true;
      request.getDuringRepairDetail(this.currentId).then(function (res) {
        _this.loading = false;
        if (res.code === 200) {
          _this.form = res.data;
          if (res.data.orderStatus) {
            var orderStatus = _.find(_this.orderStatusList, function (item) {
              return item.value == res.data.orderStatus;
            });
            if (orderStatus) _this.form.orderStatus = orderStatus.label;
          }
          //购车人证件类型
          if (res.data.customerCertiType) {
            var _customerCertiType = _.find(_this.CertiType, function (item) {
              return item.value == res.data.customerCertiType;
            });
            if (_customerCertiType) _this.form.customerCertiType = _customerCertiType.label;
          }
          // 修车人证件类型
          if (res.data.srvCustomerCertiType) {
            var _srvCustomerCertiType = _.find(_this.CertiType, function (item) {
              return item.value == res.data.srvCustomerCertiType;
            });
            if (_srvCustomerCertiType) _this.form.srvCustomerCertiType = _srvCustomerCertiType.label;
          }
          // 修车人种类
          if (res.data.srvCustomIdType) {
            var _srvCustomIdType = _.find(_this.CustomIdType, function (item) {
              return item.value == res.data.srvCustomIdType;
            });
            if (_srvCustomIdType) _this.form.srvCustomIdType = _srvCustomIdType.label;
          }
          // 购车人种类
          if (res.data.customerIdType) {
            var _customerIdType = _.find(_this.CustomIdType, function (item) {
              return item.value == res.data.customerIdType;
            });
            if (_customerIdType) _this.form.customerIdType = _customerIdType.label;
          }
        }
        ;
      }).catch(function (err) {
        _this.loading = false;
      });
    },
    // 清空操作
    clearChanged: function clearChanged(code) {
      this.form[code] = null;
    },
    // //保存提交防抖
    onSave: function onSave() {
      var _this2 = this;
      if (!this.currentId) return;
      if (this.timeout1) {
        clearTimeout(this.timeout1);
      }
      this.timeout1 = setTimeout(function () {
        _this2.onSave1();
      }, 500);
    },
    // 保存
    onSave1: function onSave1() {
      var _this3 = this;
      var data = {
        id: this.currentId,
        //订单id
        customerType: this.form.customerType ? this.form.customerType : null,
        //客户类别
        bzExpireDate: this.form.bzExpireDate ? this.form.bzExpireDate.split(' ')[0] + ' 00:00:00' : null,
        //交强险到期时间
        bzInsCorpCode: this.form.bzInsCorpCode ? this.form.bzInsCorpCode : null,
        //交强保险公司编码
        bsExpireDate: this.form.bsExpireDate ? this.form.bsExpireDate.split(' ')[0] + ' 00:00:00' : null,
        //商业险到期时间
        bsInsCorpCode: this.form.bsInsCorpCode ? this.form.bsInsCorpCode : null //商业保险公司编码
      };

      request.updateDuringRepairDetail(data).then(function (res) {
        if (res.code == 200) {
          _this3.$message.success('操作成功!');
          _this3.$router.go(-1);
        }
      });
    },
    // 返回上一页
    goBack: function goBack() {
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.go(-1);
    }
  }
};