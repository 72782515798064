import _interopRequireWildcard from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/interopRequireWildcard.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);
import Layout from '@/layout';
import appMain from '@/layout/components/AppMain';
export var constantRoutes = [{
  path: '/redirect',
  component: Layout,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/redirect/index'));
      });
    }
  }]
}, {
  path: '/login',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/login/index'));
    });
  },
  hidden: true
}, {
  path: '/auth-redirect',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/login/auth-redirect'));
    });
  },
  hidden: true
}, {
  path: '/verifyLogin',
  name: 'verifyLogin',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/NonAutoInsurance/verify/login'));
    });
  },
  hidden: true
}, {
  path: '/verifyChargebackList',
  name: 'verifyChargebackList',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/NonAutoInsurance/verify/list'));
    });
  },
  hidden: true
}, {
  path: '/verifyChargeback',
  name: 'verifyChargeback',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/NonAutoInsurance/verify/index'));
    });
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/error-page/404'));
    });
  },
  hidden: true
}, {
  path: '/401',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/error-page/401'));
    });
  },
  hidden: true
},
// {
//     path: '/register',
//     component: () =>
//         import('@/views/login/register'),
//     hidden: true
// },
{
  path: '/ssoLogin',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/login/ssoLogin'));
    });
  },
  hidden: true
}, {
  path: '/',
  component: Layout,
  meta: {
    title: '工作台',
    icon: 'workbench'
  },
  redirect: '/welcome',
  children: [{
    path: '/welcome',
    name: 'Welcome',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/dashboard/index'));
      });
    },
    meta: {
      title: '首页',
      icon: 'el-icon-s-home',
      affix: false,
      keepAlive: true
    },
    hidden: true
  }, {
    path: '/workbench',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/shortcut/index'));
      });
    },
    name: 'Workbench',
    meta: {
      title: '我的工作台',
      icon: 'workbench',
      keepAlive: true
    }
  }, {
    path: '/dashboard',
    name: 'MapStatistics',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/analysis/mapStatistics'));
      });
    },
    meta: {
      title: '车险地图看板',
      icon: 'el-icon-s-marketing',
      affix: false,
      keepAlive: true
    }
    // hidden: process.env.VUE_APP_FLAG == 'dev' ? false : true,
  }, {
    path: '/mapStatisticsBoard',
    name: 'mapStatisticsBoard',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/analysis/mapStatisticsBoard'));
      });
    },
    meta: {
      title: '车险数据看板',
      icon: 'el-icon-s-marketing',
      affix: false
    },
    hidden: true
  }, {
    path: '/insuranceAnalysis',
    name: 'insuranceAnalysis',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/analysis/insuranceAnalysis'));
      });
    },
    meta: {
      title: '车险数据分析看板',
      icon: 'dashboard',
      keepAlive: true
    },
    hidden: true
  }, {
    path: '/channelStatistics',
    name: 'channelStatistics',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/analysis/channelStatistics'));
      });
    },
    meta: {
      title: '渠道交互看板',
      icon: 'dashboard',
      keepAlive: true
    }
  }, {
    path: '/statistics/board',
    name: 'BusinessStatisticsBoard',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/analysis/businessStatisticsBoard'));
      });
    },
    meta: {
      title: '车险业务统计',
      icon: 'el-icon-s-data',
      keepAlive: true
    }
  }, {
    path: '/statistics/board/new',
    name: 'NewBusinessStatisticsBoard',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/analysis/newBusinessStatisticsBoard'));
      });
    },
    meta: {
      title: '车险业务统计(新)',
      icon: 'el-icon-s-data',
      keepAlive: true
    }
  }, {
    path: '/statistics/nonVehicleReport',
    name: 'nonVehicleReport',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/reports/nonVehicleReport'));
      });
    },
    meta: {
      title: '非车险业务统计',
      icon: 'el-icon-s-data',
      keepAlive: true
    }
    // hidden: process.env.VUE_APP_FLAG != 'prd' ? false : true,
  }, {
    path: '/statistics/organization',
    name: 'BusinessStatistics',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/analysis/businessStatistics'));
      });
    },
    meta: {
      title: '机构业务统计',
      icon: 'el-icon-tickets',
      keepAlive: false
    }
  }, {
    path: '/statistics/employee',
    name: 'EmployeeBusinessStatistics',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/analysis/businessStatistics'));
      });
    },
    meta: {
      title: '员工业务统计',
      icon: 'el-icon-tickets',
      keepAlive: false
    }
  }, {
    path: '/governance/org',
    name: 'GovernanceOrgStatistics',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/analysis/governanceStatistics'));
      });
    },
    meta: {
      title: '数据治理统计(机构)',
      icon: 'el-icon-tickets',
      keepAlive: true
    }
  }, {
    path: '/noVechileMap',
    name: 'NoVechileMapStatistics',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/analysis/noVechileMapStatistics'));
      });
    },
    meta: {
      title: '增值服务地图看板',
      icon: 'el-icon-s-marketing',
      keepAlive: true
    }
  }, {
    path: '/nonCarMapBoard',
    name: 'NonCarMapBoard',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/analysis/nonCarMapBoard'));
      });
    },
    meta: {
      title: '非车险驾驶舱',
      icon: 'el-icon-s-marketing',
      keepAlive: false
    }
  }, {
    path: '/governance/owner',
    name: 'GovernanceOwnerStatistics',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/analysis/governanceStatistics'));
      });
    },
    meta: {
      title: '数据治理统计(员工)',
      icon: 'el-icon-tickets',
      keepAlive: true
    }
  }, {
    path: '/renewalTaskKanban',
    name: 'RenewalTaskKanban',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/analysis/renewalTaskKanban'));
      });
    },
    meta: {
      title: '续保任务看板',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/taskDetail',
    name: 'TaskDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/analysis/dialog/taskDetail'));
      });
    },
    meta: {
      title: '续保任务详情',
      icon: 'list'
    },
    hidden: true
  }, {
    path: '/salesmanTaskKanban',
    name: 'SalesmanTaskKanban',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/analysis/salesmanTaskKanban'));
      });
    },
    meta: {
      title: '业务员任务看板',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/generalMapBoard',
    name: 'GeneralMapBoard',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/analysis/generalMapBoard'));
      });
    },
    meta: {
      title: '综合数据看板',
      icon: 'el-icon-s-marketing',
      keepAlive: false
    },
    hidden: true
  }, {
    path: '/meExportLog',
    name: 'MyExportLog',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/monitor/exportLog'));
      });
    },
    meta: {
      title: '我的导出',
      icon: 'list',
      keepAlive: true
    }
  }]
}, {
  path: '/customerManagement',
  component: Layout,
  redirect: '/customers/index',
  meta: {
    title: '客户管理',
    icon: 'user'
  },
  children: [{
    path: '/customers/index',
    name: 'Customers',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/customers/index'));
      });
    },
    meta: {
      title: '客户资源',
      icon: 'peoples',
      keepAlive: true
    }
  }, {
    path: '/customers/my',
    name: 'MyCustomers',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/customers/my'));
      });
    },
    meta: {
      title: '我的客户',
      icon: 'peoples',
      keepAlive: true
    }
  }, {
    path: '/customers/detail',
    name: 'customersDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/customers/detail'));
      });
    },
    meta: {
      title: '客户详情',
      icon: 'peoples'
    },
    hidden: true
  }, {
    path: '/customers/tracks',
    name: 'customersTracks',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/customers/tracks'));
      });
    },
    meta: {
      title: '跟进明细',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/customers/myTracks',
    name: 'CustomersMyTracks',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/customers/tracks'));
      });
    },
    meta: {
      title: '我的跟进',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/customers/renewalQuery',
    name: 'RenewalQuery',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/customers/renewalQuery'));
      });
    },
    meta: {
      title: '续保查询',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/customers/renewalQueryResult',
    name: 'RenewalQueryResult',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/customers/renewalQueryResult'));
      });
    },
    meta: {
      title: '续保结果',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/customers/underWarrantyQuery',
    name: 'UnderWarrantyQuery',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/customers/underWarrantyQuery'));
      });
    },
    meta: {
      title: '在保任务',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/customers/underWarrantyQueryResult',
    name: 'UnderWarrantyQueryResult',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/customers/underWarrantyQueryResult'));
      });
    },
    meta: {
      title: '在保结果',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/customers/failure',
    name: 'failure',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/customers/failure'));
      });
    },
    meta: {
      title: '战败管理',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/customers/import',
    name: 'customersImport',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/customers/import'));
      });
    },
    meta: {
      title: '批量导入',
      icon: 'list',
      keepAlive: true
    },
    hidden: true
  }, {
    path: '/customers/maintainRecordDetail',
    name: 'maintainRecordDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/customers/detail/maintainRecordDetail'));
      });
    },
    meta: {
      title: '维修明细详情',
      icon: 'form'
    },
    hidden: true
  }, {
    path: '/taskAllocation',
    name: 'taskAllocation',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/taskAllocation'));
      });
    },
    meta: {
      title: '任务分配',
      icon: 'form'
    }
  }, {
    path: '/customers/renewalConfig',
    name: 'RenewalConfig',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/customers/renewalConfig'));
      });
    },
    meta: {
      title: '续保配置',
      icon: 'list',
      keepAlive: true
    }
    // hidden: true
  }]
}, {
  path: '/leadManagement',
  component: Layout,
  redirect: '/leadManagement',
  meta: {
    title: '线索管理',
    icon: 'component'
  },
  children: [{
    path: '/leadManagement',
    name: 'InsuranceRenewalClue',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/leadManagement/insuranceRenewalClue'));
      });
    },
    meta: {
      title: '续保线索池',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/insuranceFollowTask',
    name: 'InsuranceFollowTask',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/leadManagement/insuranceFollowTask'));
      });
    },
    meta: {
      title: '续保跟进任务池',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/cluesDetail',
    name: 'CluesDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/leadManagement/cluesDetail'));
      });
    },
    meta: {
      title: '线索详情',
      icon: 'list'
    },
    hidden: true
  }]
}, {
  path: '/businessManage',
  component: Layout,
  redirect: '/proposalManage',
  meta: {
    title: '车险业务',
    icon: 'component'
  },
  children: [{
    path: '/quoteIndex',
    name: 'QuoteIndex',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/proposal/quoteIndex'));
      });
    },
    meta: {
      title: '车险出单',
      icon: 'form'
    },
    hidden: true
  }, {
    path: '/proposalManage',
    name: 'ProposalManage',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/proposal'));
      });
    },
    meta: {
      title: '在线投保',
      icon: 'el-icon-s-order',
      keepAlive: true
    }
  }, {
    path: '/proposal/add',
    name: 'proposalAdd',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/proposal/detail'));
      });
    },
    meta: {
      title: '新增投保',
      icon: 'form'
    },
    hidden: true
  }, {
    path: '/proposalManage/net',
    name: 'NetProposalManage',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/proposal/netIndex'));
      });
    },
    meta: {
      title: 'C端报价记录',
      icon: 'el-icon-s-order',
      keepAlive: true
    }
  }, {
    path: '/proposal/batchSync',
    name: 'BatchSync',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/proposal/batchSync'));
      });
    },
    meta: {
      title: '批量同步',
      icon: 'form',
      keepAlive: true
    },
    hidden: process.env.VUE_APP_FLAG == 'dev' ? false : true
  }, {
    path: '/proposal/syncResult',
    name: 'syncResult',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/proposal/syncResult'));
      });
    },
    meta: {
      title: '同步结果',
      icon: 'form',
      keepAlive: true
    },
    hidden: process.env.VUE_APP_FLAG == 'dev' ? false : true
  }, {
    path: '/insCorpVerification',
    name: 'InsCorpVerification',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/insCorpVerification'));
      });
    },
    meta: {
      title: '保司验证',
      icon: 'form',
      keepAlive: true
    },
    hidden: process.env.VUE_APP_FLAG == 'dev' ? false : true
  }, {
    path: '/proposal/batchQuote',
    name: 'BatchQuote',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/batchQuote'));
      });
    },
    meta: {
      title: '批量报价',
      icon: 'form'
    }
  }, {
    path: '/proposal/detail',
    name: 'proposalDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/proposal/detail'));
      });
    },
    meta: {
      title: '投保单详情',
      icon: 'form'
    },
    hidden: true
  }, {
    path: '/policy/add',
    name: 'InsurancePolicyAdd',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/insurancePolicy/add'));
      });
    },
    meta: {
      title: '保单录入',
      icon: 'el-icon-s-order'
    }
  }, {
    path: '/policy/edit',
    name: 'InsurancePolicyEdit',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/insurancePolicy/add'));
      });
    },
    meta: {
      title: '编辑保单',
      icon: 'el-icon-s-order'
    },
    hidden: true
  }, {
    path: '/policyManage',
    name: 'PolicyManage',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/insurancePolicy/index'));
      });
    },
    meta: {
      title: '保单管理',
      icon: 'policy-manage',
      keepAlive: true
    }
  }, {
    path: '/myPolicy',
    name: 'MyPolicy',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/insurancePolicy/myPolicy'));
      });
    },
    meta: {
      title: '我的保单',
      icon: 'policy-manage',
      keepAlive: true
    }
  }, {
    path: '/policyView',
    name: 'PolicyView',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/insurancePolicy/indexForView'));
      });
    },
    meta: {
      title: '管理保单',
      icon: 'policy-manage',
      keepAlive: true
    }
  }, {
    path: '/policy/detail',
    name: 'InsurancePolicyDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/insurancePolicy/detail'));
      });
    },
    meta: {
      title: '保单详情',
      icon: 'form'
    },
    hidden: true
  }, {
    path: '/payment',
    name: 'payment',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/payment/index'));
      });
    },
    meta: {
      title: '缴费',
      icon: 'form'
    },
    hidden: true
  }, {
    path: '/printInsureProposal',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/printInsureProposal/index'));
      });
    },
    meta: {
      title: '生成报价单',
      icon: 'form'
    },
    hidden: true
  }, {
    path: '/policy/order',
    name: 'InsurancePolicyOrder',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/insurancePolicy/sync'));
      });
    },
    meta: {
      title: '保单同步',
      icon: 'form',
      keepAlive: true
    },
    hidden: true
  }, {
    path: '/insuranceManagement',
    name: 'InsuranceManagement',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/insurancePolicy/insuranceManagement'));
      });
    },
    meta: {
      title: '批单管理',
      icon: 'policy-manage',
      keepAlive: true
    }
  }, {
    path: '/insuranceManagement/detail',
    name: 'insuranceManaAdd',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/insurancePolicy/insuranceManaAdd'));
      });
    },
    meta: {
      title: '新增批单',
      icon: 'list',
      keepAlive: true
    },
    hidden: true
  }, {
    path: '/vehicleSales',
    name: 'VehicleSales',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/operate/vehicleSalesMaintain'));
      });
    },
    meta: {
      title: '车辆销售清单',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/maintenanceOrderRecord',
    name: 'MaintenanceOrderRecord',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/monitor/maintenanceOrderRecord'));
      });
    },
    meta: {
      title: '维修订单记录',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/vehicleSalesAll',
    name: 'VehicleSalesAll',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/operate/vehicleSalesMaintainAll'));
      });
    },
    meta: {
      title: '车辆销售清单(运维)',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/historyPolicyManage',
    name: 'HistoryPolicyManage',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/insurancePolicy/historyPolicyManage'));
      });
    },
    meta: {
      title: '历史保单管理',
      icon: 'policy-manage',
      keepAlive: true
    }
  }, {
    path: '/historyVehicleSalesMaintain',
    name: 'HistoryVehicleSalesMaintain',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/operate/historyVehicleSalesMaintain'));
      });
    },
    meta: {
      title: '历史车销清单',
      icon: 'policy-manage',
      keepAlive: true
    }
    // hidden:true,
  }, {
    path: '/PDFparse/batchParse',
    name: 'PDFBatchParse',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/PDFparse/batchParse'));
      });
    },
    meta: {
      title: '保单批量识别',
      icon: 'form',
      keepAlive: true
    },
    hidden: process.env.VUE_APP_FLAG == 'dev' ? false : true
  }, {
    path: '/historyPolicyDetail',
    name: 'HistoryPolicyDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/insurancePolicy/detail/historyPolicyDetail'));
      });
    },
    meta: {
      title: '历史保单管理详情',
      icon: 'policy-manage',
      keepAlive: true
    },
    hidden: true
  }]
}, {
  path: '/insuranceProducts',
  component: Layout,
  redirect: '/insuranceProducts',
  meta: {
    title: '服务包管理',
    icon: 'added-service'
  },
  // hidden: true,
  children: [{
    path: '/insuranceProducts',
    name: 'InsuranceProducts',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/servicePackManage/insuranceProducts/index'));
      });
    },
    meta: {
      title: '车险产品',
      icon: 'form',
      keepAlive: true
    }
  }, {
    path: '/drivingAccidentInsurancePproducts',
    name: 'DrivingAccidentInsurancePproducts',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/servicePackManage/drivingAccidentInsurancePproducts/index'));
      });
    },
    meta: {
      title: '驾意险产品',
      icon: 'form',
      keepAlive: true
    }
  }, {
    path: '/servicePackageProducts',
    name: 'ServicePackageProducts',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/servicePackManage/servicePackageProducts/index'));
      });
    },
    meta: {
      title: '服务包产品',
      icon: 'form',
      keepAlive: true
    }
  }, {
    path: '/serviceOrder',
    name: 'ServiceOrder',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/servicePackManage/serviceOrder/index'));
      });
    },
    meta: {
      title: '服务包投保',
      icon: 'form',
      keepAlive: true
    }
  }, {
    path: '/serviceOrderDetail',
    name: 'ServiceOrderDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/servicePackManage/serviceOrder/detail'));
      });
    },
    meta: {
      title: '投保详情',
      icon: 'form',
      keepAlive: true
    },
    hidden: true
  }, {
    path: '/servicePackWriteOff',
    name: 'ServicePackWriteOff',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/servicePackManage/servicePackWriteOff'));
      });
    },
    meta: {
      title: '服务包核销',
      icon: 'form',
      keepAlive: true
    }
  }, {
    path: '/servicePackWriteOffRecords',
    name: 'ServicePackWriteOffRecords',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/servicePackManage/servicePackWriteOff'));
      });
    },
    meta: {
      title: '服务包核销记录',
      icon: 'form',
      keepAlive: true
    }
  }, {
    path: '/servicePackageOutside',
    name: 'ServicePackageOutside',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/servicePackManage/servicePackageOutside'));
      });
    },
    meta: {
      title: '服务包产品(外)',
      icon: 'form',
      keepAlive: true
    }
  }, {
    path: '/servicePackageOrder',
    name: 'ServicePackageOrder',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/servicePackManage/servicePackageOrder'));
      });
    },
    meta: {
      title: '服务包台账',
      icon: 'form',
      keepAlive: true
    }
  }]
}, {
  path: '/giftManagement',
  component: Layout,
  redirect: '/giftMaintain',
  meta: {
    title: '赠品管理',
    icon: 'present'
  },
  // hidden: true,
  children: [{
    path: '/giftMaintain',
    name: 'GiftMaintain',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/giftManagement/giftMaintain'));
      });
    },
    meta: {
      title: '赠品维护',
      icon: 'form',
      keepAlive: true
    }
  }, {
    path: '/giftIssue',
    name: 'GiftIssue',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/giftManagement/giftIssue'));
      });
    },
    meta: {
      title: '赠品发放',
      icon: 'form',
      keepAlive: true
    }
  }, {
    path: '/giftWriteOff',
    name: 'GiftWriteOff',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/giftManagement/giftWriteOff'));
      });
    },
    meta: {
      title: '赠品核销',
      icon: 'form',
      keepAlive: true
    }
  }, {
    path: '/giftWriteOffRecords',
    name: 'GiftWriteOffRecords',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/giftManagement/giftWriteOff'));
      });
    },
    meta: {
      title: '核销记录',
      icon: 'form',
      keepAlive: true
    }
  }]
}, {
  path: '/nonAutoInsurance',
  component: Layout,
  redirect: '/nonAutoInsurance',
  meta: {
    title: '非车险服务',
    icon: 'component'
  },
  children: [{
    path: '/productField',
    name: 'productField',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/NonAutoInsurance/productField/index'));
      });
    },
    meta: {
      title: '产品字段管理',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/serviceType',
    name: 'serviceType',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/NonAutoInsurance/serviceType/index'));
      });
    },
    meta: {
      title: '服务类型管理',
      icon: 'list',
      keepAlive: false
    }
  }, {
    path: '/NonAutoInsuranceProduct',
    name: 'NonAutoInsuranceProduct',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/NonAutoInsurance/product/index'));
      });
    },
    meta: {
      title: '产品管理',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/NonAutoInsuranceAdd',
    name: 'NonAutoInsuranceAdd',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/NonAutoInsurance/product/add'));
      });
    },
    meta: {
      title: '新增产品',
      icon: 'list'
    },
    hidden: true
  }, {
    path: '/NonAutoInsuranceEdit',
    name: 'NonAutoInsuranceEdit',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/NonAutoInsurance/product/add'));
      });
    },
    meta: {
      title: '编辑产品',
      icon: 'list',
      keepAlive: false
    },
    hidden: true
  }, {
    //小修安心出单
    path: '/minorRepairs',
    name: 'minorRepairs',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/NonAutoInsurance/replacementOrder/minorRepairs'));
      });
    },
    meta: {
      title: '小修安心出单',
      icon: 'form'
    }
    // hidden:process.env.VUE_APP_FLAG == 'dev' ? false : true
  }, {
    //小修安心暂存
    path: '/minorRepairsZC',
    name: 'minorRepairsZC',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/NonAutoInsurance/replacementOrder/minorRepairsZC'));
      });
    },
    meta: {
      title: '小修安心出单',
      icon: 'form'
    },
    hidden: true
  }, {
    //小修安心重新出单
    path: '/minorRepairsCXCD',
    name: 'minorRepairsCXCD',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/NonAutoInsurance/replacementOrder/minorRepairsZC'));
      });
    },
    meta: {
      title: '小修安心出单',
      icon: 'form'
    },
    hidden: true
  }, {
    //大修无忧出单
    path: '/overhaul',
    name: 'overhaul',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/NonAutoInsurance/replacementOrder/overhaul'));
      });
    },
    meta: {
      title: '大修无忧出单',
      icon: 'form'
    }
  }, {
    //大修无忧暂存
    path: '/overhaulZC',
    name: 'overhaulZC',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/NonAutoInsurance/replacementOrder/overhaulZC'));
      });
    },
    meta: {
      title: '大修无忧出单',
      icon: 'form'
    },
    hidden: true
  }, {
    //大修无忧重新出单
    path: '/overhaulCXCD',
    name: 'overhaulCXCD',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/NonAutoInsurance/replacementOrder/overhaulZC'));
      });
    },
    meta: {
      title: '大修无忧出单',
      icon: 'form'
    },
    hidden: true
  }, {
    //保值回购出单
    path: '/repurchase',
    name: 'repurchase',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/NonAutoInsurance/replacementOrder/repurchase'));
      });
    },
    meta: {
      title: '保值回购出单',
      icon: 'form'
    }
    // hidden:process.env.VUE_APP_FLAG == 'dev' ? false : true
  }, {
    //保值回购暂存
    path: '/repurchaseZC',
    name: 'repurchaseZC',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/NonAutoInsurance/replacementOrder/repurchaseZC'));
      });
    },
    meta: {
      title: '保值回购出单',
      icon: 'form'
    },
    hidden: true
  }, {
    //保值回购重新出单
    path: '/repurchaseCXCD',
    name: 'repurchaseCXCD',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/NonAutoInsurance/replacementOrder/repurchaseZC'));
      });
    },
    meta: {
      title: '保值回购出单',
      icon: 'form'
    },
    hidden: true
  }, {
    //延保出单
    path: '/extendedWarranty',
    name: 'extendedWarranty',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/NonAutoInsurance/replacementOrder/extendedWarranty'));
      });
    },
    meta: {
      title: '延保服务出单',
      icon: 'form'
    }
    // hidden:process.env.VUE_APP_FLAG == 'dev' ? false : true
  }, {
    //延保暂存
    path: '/extendedWarrantyZC',
    name: 'extendedWarrantyZC',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/NonAutoInsurance/replacementOrder/extendedWarrantyZC'));
      });
    },
    meta: {
      title: '延保服务出单',
      icon: 'form'
    },
    hidden: true
  }, {
    //延保重新出单
    path: '/extendedWarrantyCXCD',
    name: 'extendedWarrantyCXCD',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/NonAutoInsurance/replacementOrder/extendedWarrantyZC'));
      });
    },
    meta: {
      title: '延保服务出单',
      icon: 'form'
    },
    hidden: true
  }, {
    ////三保出单
    path: '/threeTypesInsurance',
    name: 'threeTypesInsurance',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/NonAutoInsurance/replacementOrder/threeTypesInsurance'));
      });
    },
    meta: {
      title: '三保服务出单',
      icon: 'form'
    }
    // hidden:process.env.VUE_APP_FLAG == 'dev' ? false : true
  }, {
    //三保暂存
    path: '/threeTypesInsuranceZC',
    name: 'threeTypesInsuranceZC',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/NonAutoInsurance/replacementOrder/threeTypesInsuranceZC'));
      });
    },
    meta: {
      title: '三保服务出单',
      icon: 'form'
    },
    hidden: true
  }, {
    //三保重新出单
    path: '/threeTypesInsuranceCXCD',
    name: 'threeTypesInsuranceCXCD',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/NonAutoInsurance/replacementOrder/threeTypesInsuranceZC'));
      });
    },
    meta: {
      title: '三保服务出单',
      icon: 'form'
    },
    hidden: true
  }, {
    //代步车服务出单
    path: '/scooterInsurance',
    name: 'scooterInsurance',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/NonAutoInsurance/replacementOrder/scooterInsurance'));
      });
    },
    meta: {
      title: '代步车服务出单',
      icon: 'form'
    }
    // hidden:process.env.VUE_APP_FLAG == 'dev' ? false : true
  }, {
    //代步车服务暂存
    path: '/scooterInsuranceZC',
    name: 'scooterInsuranceZC',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/NonAutoInsurance/replacementOrder/scooterInsuranceZC'));
      });
    },
    meta: {
      title: '代步车服务出单',
      icon: 'form'
    },
    hidden: true
  }, {
    //代步车服务重新出单
    path: '/scooterInsuranceCXCD',
    name: 'scooterInsuranceCXCD',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/NonAutoInsurance/replacementOrder/scooterInsuranceZC'));
      });
    },
    meta: {
      title: '代步车服务出单',
      icon: 'form'
    },
    hidden: true
  },
  // {
  //     path: '/SecondHandCar',
  //     name: 'SecondHandCar',
  //     component: () =>
  //         import ('@/views/NonAutoInsurance/replacementOrder/SecondHandCar'),
  //     meta: { title: '退车无忧服务出单', icon: 'form', keepAlive: false },
  //     hidden:true
  // },
  {
    path: '/NonAutoInsuranceOrder',
    name: 'NonAutoInsuranceOrder',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/NonAutoInsurance/orderManagement/index'));
      });
    },
    meta: {
      title: '订单管理',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/NonAutoInsuranceOrderAll',
    name: 'NonAutoInsuranceOrderAll',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/NonAutoInsurance/orderManagement/OemList'));
      });
    },
    meta: {
      title: '订单管理(运维端)',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/NonAutoInsuranceOrderRepair',
    name: 'NonAutoInsuranceOrderRepair',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/NonAutoInsurance/orderManagement/repairList'));
      });
    },
    meta: {
      title: '订单修复管理(运维端)',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/NonAutoInsuranceOrderDetail',
    name: 'NonAutoInsuranceOrderDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/NonAutoInsurance/orderManagement/detail'));
      });
    },
    meta: {
      title: '订单详情',
      icon: 'list',
      keepAlive: false
    },
    hidden: true
  }, {
    path: '/policyManagement',
    name: 'policyManagement',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/NonAutoInsurance/policyManagement/index'));
      });
    },
    meta: {
      title: '保单管理',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/oemPolicyManagement',
    name: 'oemPolicyManagement',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/NonAutoInsurance/policyManagement/OemPolicyList'));
      });
    },
    meta: {
      title: '保单管理(F端)',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/policyManagementDetail',
    name: 'policyManagementDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/NonAutoInsurance/policyManagement/detail'));
      });
    },
    meta: {
      title: '保单详情',
      icon: 'list',
      keepAlive: false
    },
    hidden: true
  }, {
    path: '/chargeBackList',
    name: 'chargeBackList',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/NonAutoInsurance/chargeBack/index'));
      });
    },
    meta: {
      title: '注销管理',
      icon: 'list',
      keepAlive: true
    }
  },
  // {
  //     path: '/chargeBackAuditList',
  //     name: 'chargeBackAuditList',
  //     component: () =>
  //         import ('@/views/NonAutoInsurance/chargeBackAudit/index'),
  //     meta: { title: '注销审核管理', icon: 'list', keepAlive: false },
  // },
  // {
  //     path: '/chargeBackAuditDetail',
  //     name: 'chargeBackAuditDetail',
  //     component: () =>
  //         import('@/views/NonAutoInsurance/chargeBackAudit/detail'),
  //     meta: { title: '审核详情', icon: 'list', keepAlive: false },
  //     hidden: true,
  // },
  {
    path: '/auditRecords',
    name: 'auditRecords',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/NonAutoInsurance/auditRecords/index'));
      });
    },
    meta: {
      title: '注销审核记录',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/auditors',
    name: 'auditors',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/NonAutoInsurance/auditors/index'));
      });
    },
    meta: {
      title: '保司复核人员管理',
      icon: 'list',
      keepAlive: false
    }
  }, {
    path: '/chargebackApply',
    name: 'ChargebackApply',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/NonAutoInsurance/chargeBack/chargebackApply'));
      });
    },
    meta: {
      title: '注销申请',
      icon: 'list',
      keepAlive: false
    },
    hidden: true
  }, {
    path: '/chargebackDetail',
    name: 'chargebackDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/NonAutoInsurance/chargeBack/chargebackDetail'));
      });
    },
    meta: {
      title: '注销申请详情',
      icon: 'list',
      keepAlive: false
    },
    hidden: true
  }, {
    path: '/claimsManage',
    name: 'claimsManage',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/NonAutoInsurance/claimsManage/index'));
      });
    },
    meta: {
      title: '理赔管理',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/claimsApply',
    name: 'claimsApply',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/NonAutoInsurance/claimsManage/claimsApply'));
      });
    },
    meta: {
      title: '理赔申请',
      icon: 'list',
      keepAlive: false
    },
    hidden: true
  }, {
    path: '/claimsManageDetail',
    name: 'claimsManageDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/NonAutoInsurance/claimsManage/detail'));
      });
    },
    meta: {
      title: '理赔详情',
      icon: 'list',
      keepAlive: false
    },
    hidden: true
  }, {
    path: '/monitorIssue',
    name: 'monitorIssue',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/NonAutoInsurance/monitor/issue'));
      });
    },
    meta: {
      title: '代步车赠送出单',
      icon: 'list',
      keepAlive: true
    }
    // hidden: process.env.VUE_APP_FLAG != 'prd' ? false : true,
  }]
}, {
  path: '/archivesManagement',
  component: Layout,
  redirect: '/archivesManagement',
  meta: {
    title: '档案管理',
    icon: 'component'
  },
  children: [{
    path: '/archivesManagement',
    name: 'insuranceArchives',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/archivesManagement/insuranceArchives'));
      });
    },
    meta: {
      title: '保险档案',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/archivesManagement/detail',
    name: 'insuranceDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/archivesManagement/detail'));
      });
    },
    meta: {
      title: '查看详情',
      icon: 'list'
    },
    hidden: true
  }, {
    path: '/archivesManagement/insuranceBillList',
    name: 'insuranceBillList',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/archivesManagement/insuranceBillList'));
      });
    },
    meta: {
      title: '车险保单信息',
      icon: 'list',
      keepAlive: true
    },
    hidden: true
  }]
}, {
  path: '/insuranceActivitiesF',
  component: Layout,
  redirect: '/activityList',
  meta: {
    title: '保险活动F端',
    icon: 'component'
  },
  children: [{
    path: '/activityList',
    name: 'ActivityList',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/insuranceActivitiesF'));
      });
    },
    meta: {
      title: '活动列表',
      icon: 'el-icon-s-order',
      keepAlive: true
    }
  }, {
    path: '/activity/edit',
    name: 'ActivityEdit',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/insuranceActivitiesF/add'));
      });
    },
    meta: {
      title: '新建保险活动',
      icon: 'el-icon-s-order'
    },
    hidden: true
  }, {
    path: '/verification',
    name: 'ActivityVerification',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/insuranceActivitiesF/activityVerification'));
      });
    },
    meta: {
      title: '活动核销',
      icon: 'policy-manage',
      keepAlive: true
    }
  }, {
    path: '/verification/detail',
    name: 'VerificationDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/insuranceActivitiesF/verificationDetail'));
      });
    },
    meta: {
      title: '活动核销详情',
      icon: 'el-icon-s-order'
    },
    hidden: true
  }, {
    path: '/verification/specific',
    name: 'VerificationSpecific',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/insuranceActivitiesF/verificationSpecific'));
      });
    },
    meta: {
      title: '经销店核销明细',
      icon: 'el-icon-s-order'
    },
    hidden: true
  }]
}, {
  path: '/insuranceActivitiesD',
  component: Layout,
  redirect: '/activityDList',
  meta: {
    title: '保险活动店端',
    icon: 'component'
  },
  children: [{
    path: '/activityDList',
    name: 'ActivityDList',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/insuranceActivitiesD'));
      });
    },
    meta: {
      title: '活动列表',
      icon: 'el-icon-s-order',
      keepAlive: true
    }
  }, {
    path: '/activityD/detail',
    name: 'ActivityDDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/insuranceActivitiesD/detail'));
      });
    },
    meta: {
      title: '查看活动信息详情',
      icon: 'el-icon-s-order'
    },
    hidden: true
  }, {
    path: '/activityDProgress',
    name: 'ActivityDProgress',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/insuranceActivitiesD/activityProgress'));
      });
    },
    meta: {
      title: '参与的活动',
      icon: 'policy-manage',
      keepAlive: true
    }
  }, {
    path: '/activityD/progressDetail',
    name: 'ProgressDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/insuranceActivitiesD/progressDetail'));
      });
    },
    meta: {
      title: '活动核销详情',
      icon: 'policy-manage'
    },
    hidden: true
  }]
}, {
  path: '/activityCenter',
  component: Layout,
  redirect: '/activityCenter',
  meta: {
    title: '厂家政策',
    icon: 'component'
  },
  children: [{
    path: 'couponProduct',
    name: 'CouponProduct',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/activityCenter/couponProduct'));
      });
    },
    meta: {
      title: '权益服务',
      icon: 'list',
      keepAlive: true
    },
    hidden: true
  }, {
    path: 'activityRules',
    name: 'ActivityRules',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/activityCenter/activityRules'));
      });
    },
    meta: {
      title: '政策规则',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: 'activityManagement',
    name: 'ActivityManagement',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/activityCenter/activityManagement/index'));
      });
    },
    meta: {
      title: '政策管理',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: 'activityManagement/detail',
    name: 'ActivityManagementDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/activityCenter/activityManagement/detail'));
      });
    },
    meta: {
      title: '政策详情',
      icon: 'list'
    },
    hidden: true
  }, {
    path: 'activityManagement/detail/view',
    name: 'ActivityManagementDetailView',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/activityCenter/activityManagement/detail'));
      });
    },
    meta: {
      title: '政策详情查看',
      icon: 'list'
    },
    hidden: true
  }, {
    path: 'grant',
    name: 'Grant',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/activityCenter/grant'));
      });
    },
    meta: {
      title: '发放明细',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: 'activityStatistics',
    name: 'ActivityStatistics',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/activityCenter/activityStatistics'));
      });
    },
    meta: {
      title: '统计明细',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: 'baseDataManagement',
    name: 'BaseDataManagement',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/activityCenter/baseDataManagement'));
      });
    },
    meta: {
      title: '数据管理',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: 'myActivity',
    name: 'myActivity',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/activityCenter/activityManagement/index'));
      });
    },
    meta: {
      title: '政策查看',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: 'verificationData',
    name: 'VerificationData',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/activityCenter/verificationData'));
      });
    },
    meta: {
      title: '政策核对',
      icon: 'list',
      keepAlive: true
    }
  }]
}, {
  path: '/marketingRules',
  component: Layout,
  redirect: '/marketingRulesSet',
  // meta: { title: '营销规则设置', icon: 'component' },
  meta: {
    title: '续保到期提醒配置',
    icon: 'component'
  },
  children: [{
    path: '/marketingRulesSet',
    name: 'MarketingRulesSet',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/marketingRulesSet'));
      });
    },
    meta: {
      title: '规则配置',
      icon: 'el-icon-s-order',
      keepAlive: true
    }
  }]
}, {
  path: '/profile',
  component: Layout,
  redirect: '/profile/index',
  hidden: true,
  children: [{
    path: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/profile/index'));
      });
    },
    name: 'Profile',
    meta: {
      title: 'Profile',
      icon: 'user',
      noCache: true
    }
  }]
}, {
  path: '/vehicleEntryStore',
  component: Layout,
  redirect: '/entryStorRecord',
  meta: {
    title: '摄像头进店',
    icon: 'component'
  },
  children: [{
    path: '/entryStorRecord',
    name: 'StoreRecord',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/vehicleEntryStore/entryStorRecord'));
      });
    },
    meta: {
      title: '进店客户',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/blockList',
    name: 'BlockList',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/vehicleEntryStore/blockList'));
      });
    },
    meta: {
      title: '屏蔽清单',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/cameras',
    name: 'CamerasManage',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/vehicleEntryStore/cameras'));
      });
    },
    meta: {
      title: '设备管理',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/identifyRecord',
    name: 'IdentifyRecord',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/vehicleEntryStore/identifyRecord'));
      });
    },
    meta: {
      title: '识别记录',
      icon: 'list',
      keepAlive: true
    }
  }]
}, {
  path: '/userCenter',
  component: Layout,
  redirect: '/userCenter/userManagement',
  meta: {
    title: '用户中心',
    icon: 'peoples'
  },
  children: [{
    path: '/userCenter/userManagement',
    name: 'UserManagement',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/userCenter/userManagement'));
      });
    },
    meta: {
      title: '用户管理',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/userCenter/userManagementAdd',
    name: 'UserManagementAdd',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/userCenter/userManagement/add'));
      });
    },
    meta: {
      title: '用户管理新增',
      icon: 'list',
      keepAlive: true
    },
    hidden: true
  }, {
    path: '/userCenter/userManagementDetail',
    name: 'UserManagementDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/userCenter/userManagement/detail'));
      });
    },
    meta: {
      title: '用户管理详情',
      icon: 'list',
      keepAlive: true
    },
    hidden: true
  }, {
    path: '/userCenter/vehicleManagement',
    name: 'VehicleManagement',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/userCenter/vehicleManagement'));
      });
    },
    meta: {
      title: '车辆管理',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/userCenter/userType',
    name: 'UserType',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/userCenter/userType'));
      });
    },
    meta: {
      title: '用户类型',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/userCenter/channelConfiguration',
    name: 'ChannelConfiguration',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/userCenter/channelConfiguration'));
      });
    },
    meta: {
      title: '渠道配置',
      icon: 'list',
      keepAlive: true
    }
  }]
}, {
  path: '/callCenter',
  component: Layout,
  redirect: '/callRecord',
  meta: {
    title: '呼叫中心',
    icon: 'component'
  },
  hidden: process.env.VUE_APP_FLAG == 'dev' ? false : true,
  children: [{
    path: '/callRecord',
    name: 'CallRecord',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/callCenter/callRecord'));
      });
    },
    meta: {
      title: '通话记录',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/telephone',
    name: 'TelephoneManage',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/callCenter/telephone'));
      });
    },
    meta: {
      title: '录音设备管理',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/callReport',
    name: 'CallReport',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/callCenter/callReport'));
      });
    },
    meta: {
      title: '话务报表',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/appDownload',
    name: 'appDownload',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/callCenter/appDownload'));
      });
    },
    meta: {
      title: '下载安装包',
      icon: 'list',
      keepAlive: true
    },
    hidden: true
  }, {
    path: '/installVersion',
    name: 'InstallVersion',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/callCenter/installVersion'));
      });
    },
    meta: {
      title: '安装包版本',
      icon: 'list',
      keepAlive: true
    }
  }]
}, {
  path: '/repair',
  component: Layout,
  redirect: '/myOrder',
  meta: {
    title: '留修管理',
    icon: 'component'
  },
  hidden: process.env.VUE_APP_FLAG == 'dev' ? false : true,
  children: [{
    path: '/pushSms',
    name: 'clueManage',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/repair/pushSms/index'));
      });
    },
    meta: {
      title: '线索管理',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/reportClaim',
    name: 'ReportClaim',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/claimManage/reportClaim'));
      });
    },
    meta: {
      title: '车主报案线索',
      icon: 'form'
    }
  }, {
    path: '/reportClaim/detail',
    name: 'ReportClaimDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/claimManage/reportClaim/detail'));
      });
    },
    meta: {
      title: '车主报案详情',
      icon: 'form'
    },
    hidden: true
  }, {
    path: '/repair/inscorpClue',
    name: 'InscorpClue',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/repair/inscorpClue/index'));
      });
    },
    meta: {
      title: '保司线索',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/myOrder',
    name: 'MyOrder',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/repair/myOrder/index'));
      });
    },
    meta: {
      title: '我的工单',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/myOrderDetail',
    name: 'myOrderDetail',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/repair/myOrder/detail'));
      });
    },
    meta: {
      title: '查看工单',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/repairOrder',
    name: 'repairOrder',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/repair/repairOrder/index'));
      });
    },
    meta: {
      title: '工单管理',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/rescueAgency',
    name: 'rescueAgency',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/repair/rescueAgency/index'));
      });
    },
    meta: {
      title: '救援机构',
      icon: 'list',
      keepAlive: true
    }
  }, {
    hidden: true,
    path: '/checkIn',
    name: 'checkIn',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/repair/checkIn/index'));
      });
    },
    meta: {
      title: '签到打卡',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/orderMonitor',
    name: 'orderMonitor',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/repair/orderMonitor/index'));
      });
    },
    meta: {
      title: '工单监控',
      icon: 'list',
      keepAlive: true
    },
    hidden: true
  }, {
    path: '/orderMonitorDetail',
    name: 'orderMonitorDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/repair/orderMonitor/detail'));
      });
    },
    meta: {
      title: '查看工单',
      icon: 'list',
      keepAlive: true
    },
    hidden: true
  }, {
    path: '/repairReport',
    name: 'repairReport',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/repair/repairReport/index'));
      });
    },
    meta: {
      title: '留修统计',
      icon: 'list',
      keepAlive: true
    },
    hidden: true
  }, {
    path: '/outputValue',
    name: 'outputValue',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/repair/outputValue/index'));
      });
    },
    meta: {
      title: '产值统计',
      icon: 'list',
      keepAlive: true
    },
    hidden: true
  }, {
    path: '/achievement',
    name: 'achievement',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/repair/achievement/index'));
      });
    },
    meta: {
      title: '业绩统计',
      icon: 'list',
      keepAlive: true
    },
    hidden: true
  }, {
    path: '/analysis',
    name: 'analysis',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/repair/analysis/index'));
      });
    },
    meta: {
      title: '赔付分析',
      icon: 'list',
      keepAlive: true
    },
    hidden: true
  }, {
    path: '/smsMatchTemp',
    name: 'SMSMatchTemplet',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/repair/smsMatchTemp/index'));
      });
    },
    meta: {
      title: '线索模板',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/configure',
    name: 'configure',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/repair/configure/index'));
      });
    },
    meta: {
      title: '留修配置',
      icon: 'list',
      keepAlive: true
    }
  }]
}, {
  path: '/claimManage',
  component: Layout,
  redirect: '/claimManage',
  meta: {
    title: '理赔管理',
    icon: 'component'
  },
  hidden: process.env.VUE_APP_FLAG == 'dev' ? false : true,
  children: [{
    path: '/claimManage',
    name: 'claimManage',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/claimManage/claimList/index'));
      });
    },
    meta: {
      title: '理赔工单',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/myClaim',
    name: 'myClaim',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/claimManage/myClaimList'));
      });
    },
    meta: {
      title: '我的工单',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/survey',
    name: 'survey',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/claimManage/survey/index'));
      });
    },
    meta: {
      title: '查勘机构',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/claimSetting',
    name: 'claimSetting',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/claimManage/claimSetting'));
      });
    },
    meta: {
      title: '理赔配置',
      icon: 'list',
      keepAlive: true
    }
  }]
}, {
  path: '/brokerageManagement',
  component: Layout,
  redirect: '/brokerage',
  meta: {
    title: '财务管理',
    icon: 'money'
  },
  children: [{
    path: '/commissions',
    name: 'Commissions',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/brokerage/commissions'));
      });
    },
    meta: {
      title: '业务台账',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/detailedList',
    name: 'detailedList',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/brokerage/detailedList'));
      });
    },
    meta: {
      title: '保司开票清单',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/brokerage',
    name: 'brokerageManagement',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/brokerage'));
      });
    },
    meta: {
      title: '对账/结账',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/standingBook',
    name: 'StandingBook',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/brokerage/standingBook'));
      });
    },
    meta: {
      title: '财务台账',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/invoice',
    name: 'Invoice',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/brokerage/invoice'));
      });
    },
    meta: {
      title: '开票管理',
      icon: 'list',
      keepAlive: true
    },
    hidden: true
  }, {
    path: '/cllection',
    name: 'cllection',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/brokerage/cllection'));
      });
    },
    meta: {
      title: '回款管理',
      icon: 'list',
      keepAlive: true
    },
    hidden: true
  }, {
    path: '/brokerage/detail',
    name: 'brokerageDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/brokerage/brokerageDetail'));
      });
    },
    meta: {
      title: '对账详情',
      icon: 'list'
    },
    hidden: true
  }, {
    path: '/settlementSettings',
    name: 'SettlementSettings',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/brokerage/settlementSettings'));
      });
    },
    meta: {
      title: '结算设置',
      icon: 'list',
      keepAlive: true
    },
    hidden: false
  }, {
    path: '/brokerage/feePolicyConfig',
    name: 'FeePolicyConfig',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/brokerage/brokerageFeePolicyConfig'));
      });
    },
    meta: {
      title: '费用政策配置',
      icon: 'list'
    },
    hidden: false
  }, {
    path: '/brokerage/policyFactors',
    name: 'policyFactors',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/brokerage/policyFactors'));
      });
    },
    meta: {
      title: '费用政策因子',
      icon: 'list'
    },
    hidden: false
  }, {
    path: '/commissionParameter',
    name: 'CommissionParameter',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/brokerage/commissionParameter'));
      });
    },
    meta: {
      title: '佣金台账',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/orgServiceFeeSettlement',
    name: 'OrgServiceFeeSettlement',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/brokerage/orgServiceFeeSettlement'));
      });
    },
    meta: {
      title: '机构服务费',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/costStatementsManage',
    name: 'CostStatementsManage',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/brokerage/costStatementsManage'));
      });
    },
    meta: {
      title: '费用结算单管理',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/paymentOrderManage',
    name: 'PaymentOrderManage',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/brokerage/paymentOrderManage'));
      });
    },
    meta: {
      title: '付款单管理',
      icon: 'list',
      keepAlive: true
    }
  }]
}, {
  path: '/reportsManage',
  component: Layout,
  redirect: '/report/performance',
  meta: {
    title: '报表管理',
    icon: 'chart'
  },
  children: [{
    path: '/report/performance',
    name: 'PerformanceReport',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/reports/performanceReport'));
      });
    },
    meta: {
      title: '出单业绩表',
      icon: 'list',
      keepAlive: true
    },
    hidden: true
  }, {
    path: '/statistics/followUpStatistics',
    name: 'FollowUpStatistics',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/analysis/followUpStatistics'));
      });
    },
    meta: {
      title: '跟进结果统计',
      icon: 'el-icon-tickets',
      keepAlive: true
    }
  }, {
    path: '/report/sellerInsure',
    name: 'SellerInsureReport',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/reports/sellerInsureReport'));
      });
    },
    meta: {
      title: '经营业绩表',
      icon: 'list',
      keepAlive: true
    },
    hidden: true
  }, {
    path: '/report/customer',
    name: 'customerReport',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/reports/customerReport'));
      });
    },
    meta: {
      title: '续保跟进表',
      icon: 'list',
      keepAlive: true
    },
    hidden: true
  }, {
    path: '/report/settlement',
    name: 'settlementReport',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/reports/settlementReport'));
      });
    },
    meta: {
      title: '险企结算表',
      icon: 'list',
      keepAlive: true
    },
    hidden: true
  }, {
    path: '/report/checkReport',
    name: 'CheckReport',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/reports/checkReport'));
      });
    },
    meta: {
      title: '报表查看',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/superviseReport',
    component: appMain,
    redirect: '/superviseReport/incomeStatement',
    meta: {
      title: '监管报表',
      icon: 'list',
      keepAlive: true
    },
    children: [{
      path: '/superviseReport/incomeStatement',
      name: 'IncomeStatement',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/reports/superviseReport/incomeStatement'));
        });
      },
      meta: {
        title: '利润表',
        icon: 'list',
        keepAlive: true
      }
    }, {
      path: '/superviseReport/assetsLiabilities',
      name: 'AssetsLiabilities',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/reports/superviseReport/incomeStatement'));
        });
      },
      meta: {
        title: '资产负债表',
        icon: 'list',
        keepAlive: true
      }
    }, {
      path: '/superviseReport/institutionalSituation',
      name: 'InstitutionalSituation',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/reports/superviseReport/incomeStatement'));
        });
      },
      meta: {
        title: '机构情况表',
        icon: 'list',
        keepAlive: true
      }
    }, {
      path: '/superviseReport/productInsurance',
      name: 'ProductInsurance',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/reports/superviseReport/incomeStatement'));
        });
      },
      meta: {
        title: '产险业务表',
        icon: 'list',
        keepAlive: true
      }
    }]
  }]
}, {
  path: '/consult',
  component: Layout,
  redirect: '/consultContent',
  meta: {
    title: '资讯管理',
    icon: 'component'
  },
  hidden: true,
  children: [{
    path: '/consultContent',
    name: 'ConsultContent',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/consult/consultContent'));
      });
    },
    meta: {
      title: '资讯内容',
      icon: 'list',
      keepAlive: true
    }
  }]
}, {
  path: '/messageManagement',
  component: Layout,
  redirect: '/message/index',
  meta: {
    title: '消息管理',
    icon: 'message'
  },
  children: [{
    path: '/message/noticeManagement',
    name: 'NoticeManagement',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/message/noticeManagement'));
      });
    },
    meta: {
      title: '公告管理',
      icon: 'message',
      keepAlive: true
    }
    // hidden: true
  }, {
    path: '/message/systemBulletin',
    name: 'SystemBulletin',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/message/systemBulletin'));
      });
    },
    meta: {
      title: '系统公告',
      icon: 'message'
    }
    // hidden: true
  }, {
    path: '/message/noticeDetailPage',
    name: 'NoticeDetailPage',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/message/noticeDetailPage'));
      });
    },
    meta: {
      title: '公告详情',
      icon: 'form'
    },
    hidden: true
  }, {
    path: '/message/system',
    name: 'SystemMessage',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/message/personSys'));
      });
    },
    meta: {
      title: '公告管理',
      icon: 'message',
      keepAlive: true
    }
  }, {
    path: '/message/view',
    name: 'ViewMessage',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/message/personSys'));
      });
    },
    meta: {
      title: '系统公告',
      icon: 'message',
      keepAlive: true
    }
  }, {
    path: '/message/person',
    name: 'PersonManagement',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/message/personSys'));
      });
    },
    meta: {
      title: '个人消息',
      icon: 'message',
      keepAlive: true
    },
    hidden: true
  }, {
    path: '/messageNotification',
    name: 'MessageNotification',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/message/messageNotification'));
      });
    },
    meta: {
      title: '消息模板',
      icon: 'message',
      keepAlive: true
    },
    hidden: true
  }, {
    path: '/messageOnSite',
    name: 'MessageOnSite',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/message/messageOnSite'));
      });
    },
    meta: {
      title: '消息管理',
      icon: 'message',
      keepAlive: true
    }
  }, {
    path: '/messageReceived',
    name: 'MessageReceived',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/message/messageReceived'));
      });
    },
    meta: {
      title: '我的消息',
      icon: 'message',
      keepAlive: true
    }
  }]
}, {
  path: '/insCorpManage',
  component: Layout,
  redirect: '/insCorps',
  meta: {
    title: '运营管理',
    icon: 'operation'
  },
  children: [{
    path: '/insCorps',
    name: 'InsCorpManage',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/operate/insCorpMaintain'));
      });
    },
    meta: {
      title: '保司维护',
      icon: 'insurance-company',
      keepAlive: true
    }
  }, {
    path: '/banksManage',
    name: 'banksManage',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/operate/banksManage'));
      });
    },
    meta: {
      title: '银行维护',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/OperationsManagement',
    name: 'DealerInformationMaintenance',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/OperationsManagement/dealerInformationMaintenance'));
      });
    },
    meta: {
      title: '经销商信息维护',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/regionalMaintenance',
    name: 'RegionalMaintenance',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/regionalMaintenance/dealerRegionalMaintenance'));
      });
    },
    meta: {
      title: '区域维护',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/provincesMaintenance',
    name: 'ProvincesMaintenance',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/provincesMaintenance/index'));
      });
    },
    meta: {
      title: '省市区维护',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/agreementManagement',
    name: 'AgreementManagement',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/operate/agreementManagement'));
      });
    },
    meta: {
      title: '协议管理',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/smsMessage',
    component: appMain,
    redirect: '/smsAccountConfig',
    meta: {
      title: '短信管理',
      icon: 'component'
    },
    hidden: process.env.VUE_APP_FLAG == 'dev' ? false : true,
    children: [{
      path: '/smsAccountConfig',
      name: 'SmsAccountConfig',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/sms/smsAccountConfig'));
        });
      },
      meta: {
        title: '短信账号维护',
        icon: 'list',
        keepAlive: true
      }
    }, {
      path: '/smsTemplateList',
      name: 'smsTemplateList',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/sms/smsTemplateList'));
        });
      },
      meta: {
        title: '短信模板维护',
        icon: 'list',
        keepAlive: true
      }
    }, {
      path: '/smsManager',
      name: 'SmsManager',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/sms/smsManager'));
        });
      },
      meta: {
        title: '短信发送记录',
        icon: 'list',
        keepAlive: true
      }
    }, {
      path: '/delivedSmsManager',
      name: 'DelivedSmsManager',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/sms/delivedSmsManager'));
        });
      },
      meta: {
        title: '短信接收记录',
        icon: 'list',
        keepAlive: true
      },
      hidden: true
    }, {
      path: '/smsStatistics',
      name: 'SmsStatistics',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/sms/smsStatistics'));
        });
      },
      meta: {
        title: '短信发送量统计',
        icon: 'list',
        keepAlive: true
      },
      hidden: true
    }, {
      path: '/smsTemplateType',
      name: 'SmsTemplateType',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/sms/smsTemplateType'));
        });
      },
      meta: {
        title: '短信模板类型',
        icon: 'list',
        keepAlive: true
      }
    }]
  }, {
    path: '/importConfiguration',
    name: 'ImportConfiguration',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/operate/importConfiguration'));
      });
    },
    meta: {
      title: '导入数据配置',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/vehicleSalesDetail',
    name: 'VehicleSalesDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/operate/detail/vehicleSalesDetail'));
      });
    },
    meta: {
      title: '查看详情'
    },
    hidden: true
  }]
}, {
  path: '/operateManage',
  component: Layout,
  redirect: '/insuranceTypeConfig',
  meta: {
    title: '运维管理',
    icon: 'monitoring'
  },
  children: [{
    path: '/insuranceTypeConfig',
    name: 'InsuranceTypeConfig',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/insuranceTypeConfig/index'));
      });
    },
    meta: {
      title: '险种维护',
      icon: 'riskinfo',
      keepAlive: true
    }
  }, {
    path: '/brandModelConfig',
    name: 'brandModelConfig',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/brandModelConfig/index'));
      });
    },
    meta: {
      title: '厂牌车型配置',
      icon: 'list',
      keepAlive: true
    }
    // hidden: process.env.VUE_APP_FLAG == 'dev' ? false : true
  }, {
    path: '/InsuranceServicePlan',
    name: 'InsuranceServicePlan',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/InsuranceServicePlan/index'));
      });
    },
    meta: {
      title: '保险方案配置',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/InsuranceServiceSetting',
    name: 'InsuranceServiceSetting',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/InsuranceServiceSetting/index'));
      });
    },
    meta: {
      title: '保险保费配置',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/doubleRecord',
    name: 'DoubleRecord',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/doubleRecord/index'));
      });
    },
    meta: {
      title: '深保通账号',
      icon: 'list',
      keepAlive: true
    },
    hidden: true
  }, {
    path: '/serviceConfig',
    name: 'ServiceConfig',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/connectConfig/serviceConfig/index'));
      });
    },
    meta: {
      title: '对接服务配置',
      icon: 'apiconfig',
      keepAlive: true
    },
    hidden: false
  }, {
    path: '/NonVehicleSupplierServiceConfig',
    name: 'NonVehicleSupplierServiceConfig',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/NonAutoInsurance/supplierServiceConfig/index'));
      });
    },
    meta: {
      title: '非车险供应商配置',
      icon: 'apiconfig',
      keepAlive: true
    }
    // hidden:process.env.VUE_APP_FLAG == 'dev' ? false : true
  }, {
    path: '/NonVehicleSupplierProductConfig',
    name: 'NonVehicleSupplierProductConfig',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/NonAutoInsurance/supplierProductConfig/index'));
      });
    },
    meta: {
      title: '非车险供应商产品配置',
      icon: 'apiconfig',
      keepAlive: true
    }
    // hidden:process.env.VUE_APP_FLAG == 'dev' ? false : true
  }, {
    path: '/NonVehicleSupplierProductConfigAdd',
    name: 'NonVehicleSupplierProductConfigAdd',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/NonAutoInsurance/supplierProductConfig/detail'));
      });
    },
    meta: {
      title: '供应商产品新增',
      icon: 'apiconfig',
      keepAlive: true
    },
    hidden: true
  }, {
    path: '/NonVehicleSupplierProductConfigEdit',
    name: 'NonVehicleSupplierProductConfigEdit',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/NonAutoInsurance/supplierProductConfig/detail'));
      });
    },
    meta: {
      title: '供应商产品编辑',
      icon: 'apiconfig',
      keepAlive: true
    },
    hidden: true
  }, {
    path: '/apiConfig',
    name: 'ApiConfig',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/connectConfig/apiConfig/index'));
      });
    },
    meta: {
      title: '保司接口配置',
      icon: 'apiconfig',
      keepAlive: true
    },
    hidden: false
  }, {
    path: '/channelConfig',
    name: 'ChannelConfig',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/connectConfig/channelConfig/index'));
      });
    },
    meta: {
      title: '保司工号配置',
      icon: 'channelconfig',
      keepAlive: true
    },
    hidden: false
  }, {
    path: '/channelConfig/detail',
    name: 'ChannelConfigDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/connectConfig/channelConfig/detail'));
      });
    },
    meta: {
      title: '保司工号详情',
      icon: 'form'
    },
    hidden: true
  }, {
    path: '/insCorpDiffConfig',
    name: 'InsCorpDiffConfig',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/insCorpDiffConfig/index'));
      });
    },
    meta: {
      title: '增值服务配置',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/insuranceContractManage',
    name: 'insuranceContractManage',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/insuranceContractManagement/index'));
      });
    },
    meta: {
      title: '保司特约管理',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/drivingAccidentInsuranceManage',
    name: 'drivingAccidentInsuranceManage',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/drivingAccidentInsuranceManage/index'));
      });
    },
    meta: {
      title: '保司驾意险管理',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/drivingInsuranceClassify',
    name: 'drivingInsuranceClassify',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/drivingInsuranceClassify/index'));
      });
    },
    meta: {
      title: '保司驾意险分类',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/quoteFailures',
    name: 'quoteFailures',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/quoteFailures/index'));
      });
    },
    meta: {
      title: '投保异常配置',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/importSchemaConfig',
    name: 'ImportSchemaConfig',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/operate/importSchemaConfig'));
      });
    },
    meta: {
      title: '数据导入配置',
      icon: 'list',
      keepAlive: true
    },
    hidden: true
  }, {
    path: '/provincesMaintenanceYf',
    name: 'ProvincesMaintenanceYf',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/provincesMaintenanceYf'));
      });
    },
    meta: {
      title: 'TACT省市区维护',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/jobConfig',
    name: 'jobConfig',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/jobConfig/index'));
      });
    },
    meta: {
      title: '计划任务配置',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/OCRSupplier',
    name: 'OCRSupplier',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/OCR/supplier/index'));
      });
    },
    meta: {
      title: 'OCR供应商配置',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/OCRIdentificationConfig',
    name: 'OCRIdentificationConfig',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/OCR/identificationConfig/index'));
      });
    },
    meta: {
      title: 'OCR机构关联配置',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/OCRIdentifyCityConfig',
    name: 'OCRIdentifyCityConfig',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/OCR/identificationCityConfig/index'));
      });
    },
    meta: {
      title: 'OCR识别地区转换',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/PDFparse',
    name: 'PDFparse',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/PDFparse/index'));
      });
    },
    meta: {
      title: '保单识别管理',
      icon: 'list',
      keepAlive: true
    },
    hidden: true
  }, {
    path: '/PDFparse/add',
    name: 'PDFparseAdd',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/PDFparse/add'));
      });
    },
    meta: {
      title: '新增保单识别',
      icon: 'list',
      keepAlive: true
    },
    hidden: true
  }, {
    path: '/policyIdentifyConfig',
    name: 'PolicyIdentifyConfig',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/PDFparse/config'));
      });
    },
    meta: {
      title: '保单识别配置',
      icon: 'list',
      keepAlive: true
    },
    hidden: process.env.VUE_APP_FLAG == 'dev' ? false : true
  }, {
    path: '/operateManage/jyAccountManagement',
    name: 'JyAccountManagement',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/operate/jyAccountManagement'));
      });
    },
    meta: {
      title: '精友账户管理',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/operateManage/jyVehicleManagement',
    name: 'JyVehicleManagement',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/operate/jyVehicleManagement'));
      });
    },
    meta: {
      title: '精友车型管理',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/operateManage/syncJyVehicle',
    name: 'SyncJyVehicle',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/operate/syncJyVehicle'));
      });
    },
    meta: {
      title: '同步精友车型',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/operatRenewalConfig',
    name: 'OperatRenewalConfig',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/renewalConfig/index'));
      });
    },
    meta: {
      title: '续保配置',
      icon: 'apiconfig',
      keepAlive: true
    }
  }, {
    path: '/customers/assignmentImport',
    name: 'AssignmentImport',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/customers/assignmentImport'));
      });
    },
    meta: {
      title: '任务导入',
      icon: 'list',
      keepAlive: true
    }
    // hidden: true
  }, {
    path: '/customers/assignmentManage',
    name: 'AssignmentManage',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/customers/assignmentManage'));
      });
    },
    meta: {
      title: '任务管理',
      icon: 'list',
      keepAlive: true
    }
    // hidden: true
  }, {
    path: '/channelConfiguration',
    name: 'ChannelConfiguration',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/channelConfiguration'));
      });
    },
    meta: {
      title: '渠道配置',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/piccVehicleManagement',
    name: 'PICCVehicleManagement',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/operate/piccVehicleManagement'));
      });
    },
    meta: {
      title: '人保车型管理',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/exportMask',
    name: 'ExportMask',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/sys/exportMask'));
      });
    },
    meta: {
      title: '数据导出脱敏配置',
      icon: 'list',
      keepAlive: true
    }
  }]
}, {
  path: '/userManagement',
  component: Layout,
  redirect: '/users/organization',
  meta: {
    title: '组织架构',
    icon: 'tree-table'
  },
  children: [{
    path: '/users/organization',
    name: 'Organization',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/users/organization'));
      });
    },
    meta: {
      title: '机构管理',
      icon: 'tree-table',
      keepAlive: true
    }
  }, {
    path: '/users/employee',
    name: 'Employee',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/users/employee'));
      });
    },
    meta: {
      title: '用户管理',
      icon: 'peoples',
      keepAlive: true
    }
  }, {
    path: '/users/leaveManagement',
    name: 'LeaveManagement',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/users/leaveManagement'));
      });
    },
    meta: {
      title: '离职管理',
      icon: 'peoples',
      keepAlive: true
    }
  }, {
    path: '/users/secondEntry',
    name: 'secondEntry',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/users/secondEntry/index'));
      });
    },
    meta: {
      title: '二次入司',
      icon: 'peoples',
      keepAlive: true
    }
  }, {
    path: '/users/theContractManagement',
    name: 'TheContractManagement',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/users/theContractManagement'));
      });
    },
    meta: {
      title: '合同管理',
      icon: 'peoples',
      keepAlive: true
    }
  }, {
    path: '/users/salaryAdministration',
    name: 'SalaryAdministration',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/users/salaryAdministration'));
      });
    },
    meta: {
      title: '薪酬管理',
      icon: 'peoples',
      keepAlive: true
    }
  }, {
    path: '/users/incentiveMechanisms',
    name: 'incentiveMechanisms',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/users/incentiveMechanisms/index'));
      });
    },
    meta: {
      title: '奖惩管理',
      icon: 'peoples',
      keepAlive: true
    }
  }, {
    path: '/users/trainingManagement',
    name: 'TrainingManagement',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/users/trainingManagement'));
      });
    },
    meta: {
      title: '培训管理',
      icon: 'peoples',
      keepAlive: true
    }
  }]
}, {
  path: '/monitor',
  component: Layout,
  redirect: '/sysLog',
  meta: {
    title: '运行监控',
    icon: 'component'
  },
  children: [{
    path: '/jobNumInteractive',
    name: 'jobNumInteractive',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/monitor/jobNumInteractive/index'));
      });
    },
    meta: {
      title: '工号交互监控',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/tactPushLog',
    name: 'TactPushLog',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/monitor/tactPushLog'));
      });
    },
    meta: {
      title: 'TACT数据推送记录',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/vehicleInsurancePolicyMonitor',
    name: 'vehicleInsurancePolicyMonitor',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/monitor/vehicleInsurancePolicyMonitor'));
      });
    },
    meta: {
      title: '车险保单汇总表',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/policyPush',
    name: 'PolicyPush',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/monitor/policyPush'));
      });
    },
    meta: {
      title: '保单推送监控',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/modelFile',
    name: 'ModelFile',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/monitor/modelFile'));
      });
    },
    meta: {
      title: '车型文件监控',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/repairSms',
    name: 'RepairSms',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/monitor/repairSms'));
      });
    },
    meta: {
      title: '留修短信监控',
      icon: 'list',
      keepAlive: true
    },
    hidden: true
  }, {
    path: '/bankPayment',
    name: 'BankPayment',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/monitor/payment'));
      });
    },
    meta: {
      title: '银行付款记录',
      icon: 'list',
      keepAlive: true
    },
    hidden: true
  }, {
    path: '/renewalManagement',
    name: 'RenewalManagement',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/monitor/renewalManagement'));
      });
    },
    meta: {
      title: '在保任务管理',
      icon: 'list',
      keepAlive: true
    },
    hidden: true
  }, {
    path: '/ocr',
    name: 'OCR',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/monitor/OCR'));
      });
    },
    meta: {
      title: 'OCR识别记录',
      icon: 'list',
      keepAlive: true
    },
    hidden: true
  }, {
    path: '/OCRIdentificationRecord',
    name: 'OCRIdentificationRecord',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/OCR/identificationRecord/index'));
      });
    },
    meta: {
      title: 'OCR识别记录',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/OCRIdentificationReports',
    name: 'OCRIdentificationReports',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/OCR/reports/index'));
      });
    },
    meta: {
      title: 'OCR识别报表统计',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/PDFparse/record',
    name: 'PDFparseRecord',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/PDFparse/recordList'));
      });
    },
    meta: {
      title: '保单识别记录',
      icon: 'list',
      keepAlive: true
    },
    hidden: process.env.VUE_APP_FLAG == 'dev' ? false : true
  }, {
    path: '/jobs',
    name: 'Jobs',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/monitor/jobs'));
      });
    },
    meta: {
      title: '工作任务队列',
      icon: 'list',
      keepAlive: true
    },
    hidden: true
  }, {
    path: '/insChannelEvent',
    name: 'InsChannelEvent',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/monitor/insChannelEvent'));
      });
    },
    meta: {
      title: '渠道交互监控',
      icon: 'list',
      keepAlive: true
    },
    hidden: true
  }, {
    path: '/taskLog',
    name: 'TaskLog',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/monitor/taskLog'));
      });
    },
    meta: {
      title: '计划任务监控',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/exportLog',
    name: 'ExportLog',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/monitor/exportLog'));
      });
    },
    meta: {
      title: '系统导出监控',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/fixingFileMonitor',
    name: 'FixingFileMonitor',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/monitor/fixingFileMonitor'));
      });
    },
    meta: {
      title: '维修文件监控',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/DBCDocumentMonitor',
    name: 'DBCDocumentMonitor',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/NonAutoInsurance/monitor/document'));
      });
    },
    meta: {
      title: '代步车赠送文件监控',
      icon: 'list',
      keepAlive: true
    }
    // hidden: process.env.VUE_APP_FLAG != 'prd' ? false : true,
  }, {
    path: '/sysLog',
    name: 'SysLog',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/monitor/sysLog'));
      });
    },
    meta: {
      title: '操作日志',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/systemLog',
    name: 'SystemLog',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/monitor/systemLog'));
      });
    },
    meta: {
      title: '系统日志',
      icon: 'list',
      keepAlive: true
    }
  }]
}, {
  path: '/vehicleMaintenance',
  component: Layout,
  redirect: '/Maintenance',
  meta: {
    title: '车系维护',
    icon: 'component'
  },
  hidden: true,
  children: [{
    path: '/Maintenance',
    name: 'AutomobileBrandMaintenance',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/vehicleMaintenance/automobileBrandMaintenance'));
      });
    },
    meta: {
      title: '品牌维护',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/brandMaintenance',
    name: 'DrandMaintenance',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/vehicleMaintenance/brandMaintenance'));
      });
    },
    meta: {
      title: '车系维护',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/vehicleModelMaintenance',
    name: 'VehicleModelMaintenance',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/vehicleMaintenance/vehicleModelMaintenance'));
      });
    },
    meta: {
      title: '车型维护',
      icon: 'list',
      keepAlive: true
    }
  }]
}, {
  path: '/insuranceBusiness',
  component: Layout,
  redirect: '/insuranceBusiness',
  meta: {
    title: '保险业务开通',
    icon: 'component'
  },
  children: [{
    path: '/insuranceBusiness',
    name: 'BusinessProcedures',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/insuranceBusiness/businessProcedures'));
      });
    },
    meta: {
      title: '业务申请',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/submitBusiness',
    name: 'SubmitBusiness',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/insuranceBusiness/dialog/submitBusiness'));
      });
    },
    meta: {
      title: '保险业务开通',
      icon: 'form'
    },
    hidden: true
  }, {
    path: '/businessManagement',
    name: 'BusinessManagement',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/insuranceBusiness/businessManagement'));
      });
    },
    meta: {
      title: '业务管理',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/serviceOpeningDetails',
    name: 'ServiceOpeningDetails',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/insuranceBusiness/serviceOpeningDetails'));
      });
    },
    meta: {
      title: '业务开通明细',
      icon: 'list',
      keepAlive: true
    }
  }]
}, {
  path: '/policyRcognition',
  component: Layout,
  meta: {
    title: '保单识别管理',
    icon: 'list'
  },
  name: 'PolicyRcognition',
  redirect: '/policyRcognition/template',
  children: [{
    path: '/policyRcognition/template',
    name: 'policyRcognitionTemplate',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/policyRcognition'));
      });
    },
    meta: {
      title: '保单识别模板配置',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/policyRcognition/template/detail',
    name: 'policyRcognitionTemplateDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/policyRcognition/configList.vue'));
      });
    },
    meta: {
      title: '保单识别模板详情',
      icon: 'list'
    },
    hidden: true
  }]
}, {
  path: '/insuranceSchemeConfig',
  component: Layout,
  redirect: '/schemeConfig',
  meta: {
    title: '保险方案配置',
    icon: 'component'
  },
  children: [{
    path: '/schemeConfig',
    name: 'InsuranceSchemeConfig',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/insuranceSchemeConfig/index'));
      });
    },
    meta: {
      title: '保险方案配置',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/schemeConfig/detail',
    name: 'InsuranceSchemeDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/insuranceSchemeConfig/detail'));
      });
    },
    meta: {
      title: '保险方案详情',
      icon: 'form'
    },
    hidden: true
  }]
}, {
  path: '/sysManage',
  component: Layout,
  redirect: '/dict',
  meta: {
    title: '系统管理',
    icon: 'setting'
  },
  children: [{
    path: '/dict',
    name: 'DictManage',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/sys/dict/index'));
      });
    },
    meta: {
      title: '字典管理',
      icon: 'dicts',
      keepAlive: true
    }
  }, {
    path: '/role',
    name: 'RoleManage',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/sys/role/list'));
      });
    },
    meta: {
      title: '角色管理',
      icon: 'list',
      keepAlive: true
    }
  }, {
    path: '/sysSetting',
    name: 'SysSetting',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/sys/index'));
      });
    },
    meta: {
      title: '系统设置',
      icon: 'setting',
      keepAlive: true
    }
  }, {
    path: '/documentTemplate',
    name: 'DocumentTemplate',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/sys/file/list'));
      });
    },
    meta: {
      title: '文件模板管理',
      icon: 'list',
      keepAlive: true
    }
  }]
}, {
  path: '/contactService',
  component: Layout,
  redirect: '/contactService',
  meta: {
    title: '联系客服',
    icon: 'user'
  },
  children: [{
    path: '/contactService',
    name: 'contactService',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/contactService'));
      });
    },
    meta: {
      title: '联系客服',
      icon: 'peoples'
    }
  }]
}, {
  path: '/technologyPlatform',
  component: Layout,
  redirect: '/technologyPlatform/componentPlatform',
  meta: {
    title: '技术平台',
    icon: 'setting'
  },
  children: [{
    path: '/technologyPlatform/componentPlatform',
    component: appMain,
    redirect: '/componentPlatform/storageService',
    meta: {
      title: '组件平台',
      icon: 'component'
    },
    children: [{
      path: '/componentPlatform/storageService',
      name: 'storageService',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/sys/storageService/index'));
        });
      },
      meta: {
        title: '存储服务配置',
        icon: 'list',
        keepAlive: true
      }
    }]
  }, {
    path: '/technologyPlatform/openPlatform',
    component: appMain,
    redirect: '/openPlatform/SSOConfigure',
    meta: {
      title: '开放平台',
      icon: 'component'
    },
    children: [{
      path: '/openPlatform/SSOConfigure',
      name: 'SSOConfigure',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/sys/SSOConfigure/index'));
        });
      },
      meta: {
        title: 'SSO接入管理',
        icon: 'list',
        keepAlive: true
      }
    }, {
      path: '/openPlatform/routeManage',
      name: 'routeManage',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/gatewayConfig/routeManage'));
        });
      },
      meta: {
        title: '路由管理',
        icon: 'list',
        keepAlive: true
      }
    }, {
      path: '/openPlatform/routingGroupManage',
      name: 'routingGroupManage',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/gatewayConfig/routingGroupManage'));
        });
      },
      meta: {
        title: '路由组管理',
        icon: 'list',
        keepAlive: true
      }
    }, {
      path: '/openPlatform/accessApplicationManage',
      name: 'accessApplicationManage',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/gatewayConfig/accessApplicationManage'));
        });
      },
      meta: {
        title: '接入应用管理',
        icon: 'list',
        keepAlive: true
      }
    }, {
      path: '/openPlatform/externalAccessMonitor',
      name: 'ExternalAccessMonitor',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/interactionPlatform/newExternalAccessMonitor'));
        });
      },
      meta: {
        title: '接入应用监控',
        icon: 'list',
        keepAlive: true
      }
    }]
  }, {
    path: '/technologyPlatform/dataInteractionPlatform',
    component: appMain,
    redirect: '/dataInteractionPlatform/pushDataConfig',
    meta: {
      title: '数据交互平台',
      icon: 'component'
    },
    children: [{
      path: '/dataInteractionPlatform/pushDataConfig',
      name: 'PushDataConfig',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/interactionPlatform/pushDataConfig'));
        });
      },
      meta: {
        title: '数据类型配置',
        icon: 'list',
        keepAlive: true
      }
    }, {
      path: '/dataInteractionPlatform/subscribeConfig',
      name: 'SubscribeConfig',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/interactionPlatform/subscripConfig/index'));
        });
      },
      meta: {
        title: '订阅配置',
        icon: 'list',
        keepAlive: true
      },
      hidden: process.env.VUE_APP_FLAG == 'prd' ? true : false
    }, {
      path: '/dataInteractionPlatform/externalSubscripConfig',
      name: 'ExternalSubscripConfig',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/interactionPlatform/externalSubscripConfig'));
        });
      },
      meta: {
        title: '外部数据源配置',
        icon: 'list',
        keepAlive: true
      }
    }, {
      path: '/externalAccessConfig',
      name: 'ExternalAccessConfig',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/interactionPlatform/externalAccessConfig'));
        });
      },
      meta: {
        title: '外部接入配置',
        icon: 'list',
        keepAlive: true
      },
      hidden: true
    }, {
      path: '/dataInteractionPlatform/externalSubscripMonitor',
      name: 'ExternalSubscripMonitor',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/interactionPlatform/externalSubscripMonitor'));
        });
      },
      meta: {
        title: '外部交互监控',
        icon: 'list',
        keepAlive: true
      }
    }, {
      path: '/dataInteractionPlatform/internalSubscripConfig',
      name: 'InternalSubscripConfig',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/interactionPlatform/internalSubscripConfig'));
        });
      },
      meta: {
        title: '内部数据源配置',
        icon: 'list',
        keepAlive: true
      },
      hidden: true
    }, {
      path: '/dataInteractionPlatform/internalSubscripMonitor',
      name: 'InternalSubscripMonitor',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/interactionPlatform/internalSubscripMonitor'));
        });
      },
      meta: {
        title: '内部交互监控',
        icon: 'list',
        keepAlive: true
      },
      hidden: true
    }]
  }, {
    path: '/technologyPlatform/messagePlatform',
    component: appMain,
    redirect: '/messagePlatform/messageTypeConfig',
    meta: {
      title: '消息平台',
      icon: 'added-service'
    },
    children: [{
      path: '/messagePlatform/messageTypeConfig',
      name: 'MessageTypeConfig',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/messagePlatform/messageTypeConfig'));
        });
      },
      meta: {
        title: '消息类型配置',
        icon: 'form',
        keepAlive: true
      }
    }, {
      path: '/messagePlatform/messageNotifyChannel',
      name: 'MessageNotifyChannel',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/messagePlatform/messageNotifyChannel'));
        });
      },
      meta: {
        title: '消息通知渠道配置',
        icon: 'form',
        keepAlive: true
      }
    }, {
      path: '/messagePlatform/messageTemplateManage',
      name: 'MessageTemplateManage',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/messagePlatform/messageTemplateManage'));
        });
      },
      meta: {
        title: '消息模板管理',
        icon: 'form',
        keepAlive: true
      }
    }, {
      path: '/messagePlatform/messageTemplateManageAdd',
      name: 'MessageTemplateManageAdd',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/messagePlatform/messageTemplateManageAdd'));
        });
      },
      meta: {
        title: '新增消息模板管理',
        icon: 'form'
      },
      hidden: true
    }, {
      path: '/messagePlatform/messageTable',
      name: 'MessageTable',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/messagePlatform/messageTable'));
        });
      },
      meta: {
        title: '系统消息记录',
        icon: 'form',
        keepAlive: true
      }
    }, {
      path: '/messagePlatform/msgDetailPage',
      name: 'MsgDetailPage',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/messagePlatform/msgDetailPage'));
        });
      },
      meta: {
        title: '消息详情',
        icon: 'form'
      },
      hidden: true
    }]
  }, {
    path: '/dataCenter',
    component: Layout,
    redirect: '/renewPublicLibrary',
    meta: {
      title: '数据中心',
      icon: 'component'
    },
    children: [{
      path: '/renewPublicLibrary',
      name: 'RenewPublicLibrary',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/dataCenter/renewPublicLibrary'));
        });
      },
      meta: {
        title: '续保公库',
        icon: 'list'
      }
    }, {
      path: '/warrantyPublicLibrary',
      name: 'WarrantyPublicLibrary',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/dataCenter/warrantyPublicLibrary'));
        });
      },
      meta: {
        title: '在保公库',
        icon: 'list'
      }
    }]
  }]
}];
export var asyncRoutes = [{
  path: '*',
  redirect: '/404',
  hidden: true
}];
var createRouter = function createRouter() {
  return new Router({
    base: "".concat(process.env.VUE_APP_ROOT_PATH),
    mode: 'history',
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();
export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;