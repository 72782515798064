var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.isMultiple
        ? _c(
            "el-select",
            {
              ref: "orgSelect",
              staticStyle: { width: "100%" },
              attrs: {
                size: "mini",
                disabled: _vm.disabled,
                filterable: "",
                "filter-method": _vm.dataFilter,
                placeholder: "请选择业务机构",
                clearable: _vm.clearable,
              },
              on: { change: _vm.orgChange, focus: _vm.orgFocus },
              model: {
                value: _vm.searchForm.salesOrgName,
                callback: function ($$v) {
                  _vm.$set(_vm.searchForm, "salesOrgName", $$v)
                },
                expression: "searchForm.salesOrgName",
              },
            },
            [
              _c(
                "el-option",
                {
                  staticStyle: { height: "auto", "background-color": "white" },
                  attrs: { value: _vm.searchForm.salesOrgCode },
                },
                [
                  _c("el-tree", {
                    ref: "tree",
                    staticClass: "filter-tree",
                    attrs: {
                      "node-key": "organizationId",
                      "show-checkbox": _vm.isMultiple,
                      "highlight-current": _vm.highlightCurrent,
                      data: _vm.orgData,
                      props: _vm.defaultProps,
                      "filter-node-method": _vm.filterNode,
                    },
                    on: { "node-click": _vm.handleNodeClick },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.isMultiple
        ? _c(
            "div",
            [
              _c(
                "el-popover",
                {
                  attrs: { placement: "bottom", width: "360" },
                  model: {
                    value: _vm.showPop,
                    callback: function ($$v) {
                      _vm.showPop = $$v
                    },
                    expression: "showPop",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: { "max-height": "175px", overflow: "auto" },
                    },
                    [
                      _c("el-tree", {
                        ref: "tree",
                        staticClass: "filter-tree",
                        attrs: {
                          size: "mini",
                          "node-key": "organizationId",
                          "show-checkbox": "",
                          "highlight-current": _vm.highlightCurrent,
                          data: _vm.orgData,
                          props: _vm.defaultProps,
                          "filter-node-method": _vm.filterNode,
                        },
                        on: { "node-click": _vm.handleNodeClick },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { "text-align": "right", margin: "0" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: {
                            click: function ($event) {
                              _vm.showPop = false
                            },
                          },
                        },
                        [_vm._v("取消")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", plain: "", size: "mini" },
                          on: { click: _vm.handleCheckChange },
                        },
                        [_vm._v("确定")]
                      ),
                    ],
                    1
                  ),
                  _c("el-input", {
                    attrs: {
                      slot: "reference",
                      placeholder: "请选择账单机构",
                      readonly: true,
                    },
                    slot: "reference",
                    model: {
                      value: _vm.salesOrgName,
                      callback: function ($$v) {
                        _vm.salesOrgName = $$v
                      },
                      expression: "salesOrgName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }