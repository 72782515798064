import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import request from '@/api/insurancepolicy';
import Pagination from '@/components/Pagination';
import DatePicker from '@/components/DatePicker';
import initHeight from '../../mixins/initHeight';
import { character } from '@/utils/common';
export default {
  name: 'HistoryPolicyManage',
  components: {
    Pagination: Pagination,
    DatePicker: DatePicker
  },
  mixins: [initHeight],
  data: function data() {
    return {
      bizType: localStorage.getItem("bizType") || '',
      drawer: false,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      total: 0,
      searchForm: {},
      tableData: [],
      loading: false,
      sysregStartEndTime: [],
      cibillStartEndTime: [],
      salibillStartEndTime: []
    };
  },
  created: function created() {
    this.loadData();
    if (this.bizType === 'DEALER') {
      var userInfo = JSON.parse(localStorage.getItem('userInfo'));
      this.searchForm.dealercode = userInfo.data && userInfo.data.orgCode;
    }
  },
  methods: {
    // 刷新
    breakData: function breakData() {
      this.loadData();
    },
    //搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    //搜索条件重置
    resetSearch: function resetSearch() {
      this.searchForm = {};
      this.sysregStartEndTime = [];
      this.cibillStartEndTime = [];
      this.salibillStartEndTime = [];
    },
    //获取历史保单列表
    loadData: function loadData() {
      var _this = this;
      if (this.sysregStartEndTime) {
        this.searchForm.sysregtimeStart = this.sysregStartEndTime[0] ? this.sysregStartEndTime[0].split(' ')[0] + ' 00:00:00' : null;
        this.searchForm.sysregtimeEnd = this.sysregStartEndTime[1] ? this.sysregStartEndTime[1].split(' ')[0] + ' 23:59:59' : null;
      }
      if (this.cibillStartEndTime) {
        this.searchForm.cibilltimeStart = this.cibillStartEndTime[0] ? this.cibillStartEndTime[0].split(' ')[0] + ' 00:00:00' : null;
        this.searchForm.cibilltimeEnd = this.cibillStartEndTime[1] ? this.cibillStartEndTime[1].split(' ')[0] + ' 23:59:59' : null;
      }
      if (this.salibillStartEndTime) {
        this.searchForm.salibilltimeStart = this.salibillStartEndTime[0] ? this.salibillStartEndTime[0].split(' ')[0] + ' 00:00:00' : null;
        this.searchForm.cibilltsalibilltimeEndimeEnd = this.salibillStartEndTime[1] ? this.salibillStartEndTime[1].split(' ')[0] + ' 23:59:59' : null;
      }
      this.drawer = false;
      this.loading = true;
      request.getHistoryPolicyList(this.listQuery.pageNum, this.listQuery.pageSize, this.searchForm).then(function (res) {
        _this.loading = false;
        if (!_.isEmpty(res.data)) {
          _this.tableData = res.data.list;
          _this.total = res.data.page.recordsTotal;
        }
        ;
      }).catch(function (err) {
        _this.loading = false;
      });
    },
    //输入的车牌号中的字母自动转大写
    inputPlateNo: function inputPlateNo(val) {
      this.searchForm.registerno = _.toUpper(val);
    },
    //输入的车架号中的字母自动转大写，并去除i，o，q
    checkValue: function checkValue(val) {
      if (val) {
        val = val.replace(/i/g, '').replace(/I/g, '').replace(/o/g, '').replace(/O/g, '').replace(/q/g, '').replace(/Q/g, '');
      }
      ;
      this.searchForm.vinno = character(_.toUpper(val));
    },
    //日期选择事件
    getPickersDate: function getPickersDate(name, val) {
      if (val && val.length > 1) {
        this[name] = val;
      } else {
        this[name] = [];
      }
    },
    // 详情
    viewDetail: function viewDetail(item) {
      this.$router.push({
        path: '/historyPolicyDetail',
        query: {
          id: item.id
        }
      });
    }
  }
};