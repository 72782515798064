var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.listLoading,
          expression: "listLoading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: {},
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "540px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "detail",
                  staticStyle: { "margin-bottom": "-20px" },
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-width": "90px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "div",
                    { staticStyle: { width: "100%", height: "30px" } },
                    [_vm._v("基本信息")]
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "人员姓名：", prop: "userId" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择人员",
                                  },
                                  on: { change: _vm.getUserId },
                                  model: {
                                    value: _vm.form.userId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "userId", $$v)
                                    },
                                    expression: "form.userId",
                                  },
                                },
                                _vm._l(_vm.selectOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: item.userName,
                                      value: item.userId,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "申请日期：", prop: "applyDate" },
                            },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  clearable: "",
                                  type: "date",
                                  placeholder: "选择日期",
                                  format: "yyyy 年 MM 月 dd 日",
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                },
                                model: {
                                  value: _vm.form.applyDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "applyDate", $$v)
                                  },
                                  expression: "form.applyDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "申请原因：",
                                prop: "applyReason",
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "textarea",
                                  "show-word-limit": "",
                                  maxlength: "200",
                                  placeholder: "请输入申请原因",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.applyReason,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "applyReason", $$v)
                                  },
                                  expression: "form.applyReason",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "备注信息：",
                                prop: "applyRemarks",
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "textarea",
                                  "show-word-limit": "",
                                  maxlength: "200",
                                  placeholder: "请输入需要备注的信息",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.applyRemarks,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "applyRemarks", $$v)
                                  },
                                  expression: "form.applyRemarks",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.hidden
        ? _c(
            "el-card",
            {
              staticStyle: { "max-width": "648px", margin: "10px auto" },
              attrs: { shadow: "never" },
            },
            [
              _c(
                "el-form",
                {
                  staticStyle: { margin: "10px 0" },
                  attrs: {
                    model: _vm.form,
                    "label-width": "110px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 20 } }, [
                        _c("span", [_vm._v("申请附件")]),
                      ]),
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c(
                            "el-upload",
                            {
                              ref: "upload",
                              attrs: {
                                limit: 3,
                                "before-remove": function () {
                                  return (_vm.formData = "")
                                },
                                disabled: !_vm.form.userId,
                                action: "",
                                "show-file-list": false,
                                "http-request": _vm.uploadFile,
                                "file-list": _vm.fileList,
                                accept: "*",
                                "list-type": "text",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    icon: "el-icon-upload2",
                                    size: "mini",
                                    disabled: !_vm.form.userId,
                                    plain: "",
                                  },
                                },
                                [_vm._v("选择文件")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-table",
                            {
                              attrs: {
                                size: "mini",
                                border: "",
                                data: _vm.fileList,
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  prop: "name",
                                  label: "文件名",
                                  width: "auto",
                                  align: "center",
                                  "show-overflow-tooltip": "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass: " itemclass",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handle(scope.row)
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    color: "#1890ff",
                                                    cursor: "pointer",
                                                  },
                                                },
                                                [_vm._v(_vm._s(scope.row.name))]
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  14538989
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "status",
                                  label: "状态",
                                  "min-width": "120",
                                  "show-overflow-tooltip": "",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "操作",
                                  align: "center",
                                  width: "110",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                size: "mini",
                                                type: "danger",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.ondelete(
                                                    scope.$index
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("删除")]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3026273355
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-check",
                type: "primary",
                plain: "",
                size: "mini",
              },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: { click: _vm.closePage },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }