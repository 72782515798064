var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "app-m-cls", staticStyle: {} }, [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "540px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "detail",
                  staticStyle: { "margin-bottom": "-20px" },
                  attrs: {
                    model: _vm.currentItem,
                    "label-width": "110px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "请求流水号：" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.currentItem.routeInputRecordFlowNo
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "应用名称：" } },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.currentItem.appName) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c("el-form-item", { attrs: { label: "应用ID：" } }, [
                            _vm._v(" " + _vm._s(_vm.currentItem.appId) + " "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "路由名称：" } },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.currentItem.routeName) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 16 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "路由路径：" } },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.currentItem.routePath) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c("el-form-item", { attrs: { label: "请求ip：" } }, [
                            _vm._v(
                              " " + _vm._s(_vm.currentItem.remoteIp) + " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 16 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "请求地址：" } },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.currentItem.requestUrl) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "请求时间：" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.currentItem.requestTimeSss) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "响应时间：" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.currentItem.responseTimeSss) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c("el-form-item", { attrs: { label: "响应码：" } }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.currentItem.responseHttpStatusCode) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "请求参数：" } },
                            [
                              _c("pre", { staticClass: "errorStack" }, [
                                _vm._v(
                                  _vm._s(_vm.parseData(_vm.form.uriQuery))
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c("el-form-item", { attrs: { label: "请求头：" } }, [
                            _c("pre", { staticClass: "errorStack" }, [
                              _vm._v(
                                _vm._s(_vm.parseData(_vm.form.requestHeaders))
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c("el-form-item", { attrs: { label: "请求体：" } }, [
                            _c("pre", { staticClass: "errorStack" }, [
                              _vm._v(
                                _vm._s(_vm.parseData(_vm.form.requestBody))
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c("el-form-item", { attrs: { label: "响应体：" } }, [
                            _c("pre", { staticClass: "errorStack" }, [
                              _vm._v(
                                _vm._s(_vm.parseData(_vm.form.responseBody))
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: { click: _vm.closePage },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }