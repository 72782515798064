//
//
//
//
//
//
//
//
//
//
//

import 'driver.js/dist/driver.min.css'; // import driver.js css
import Driver from 'driver.js'; // import driver.js
import steps from '../steps';
export default {
  name: 'DashboardAdmin',
  data: function data() {
    return {
      title: process.env.VUE_APP_TITLE,
      driver: null
    };
  },
  mounted: function mounted() {
    this.driver = new Driver({
      className: 'scoped-class',
      prevBtnText: '上一步',
      nextBtnText: '下一步',
      doneBtnText: '我知道了',
      closeBtnText: '关闭',
      allowClose: false
    });
    // this.guide();
  },

  methods: {
    guide: function guide() {
      this.driver.defineSteps(steps);
      this.driver.start();
    }
  }
};