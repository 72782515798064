import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import html2canvas from 'html2canvas';
import dict from '../../mixins/dict';
import commonRequest from '../../api/common';
import { getProvinceItem } from '@/utils/common';
export default {
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  mixins: [dict],
  data: function data() {
    return {
      proposalData: {},
      imgData: '',
      loading: false,
      isScreenshot: false,
      cityItem: '',
      insuranceTime: {},
      bsInsItem: {},
      bzInsItem: {}
    };
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    getCityName: function getCityName(code) {
      //获取城市中文名称
      var cityItem = getProvinceItem(code);
      return cityItem;
    },
    closePage: function closePage() {
      //页面关闭回调函数
      this.$emit('closeProposalS', false);
      this.hasImg = false;
      this.imgData = null;
    },
    toImage: function toImage() {
      //生成图片
      if (this.isScreenshot) {
        var self = this;
        this.$refs.repIns.pageYOffset = 0;
        document.documentElement.scrollTop = 0;
        this.$refs.repIns.scrollTop = 0;
        this.loading = true;
        setTimeout(function () {
          html2canvas(self.$refs.repIns, {
            height: self.$refs.repIns.scrollHeight,
            windowHeight: self.$refs.repIns.scrollHeight,
            scrollY: 50,
            scrollX: 0,
            backgroundColor: '#fff',
            useCORS: true,
            scale: 1
          }).then(function (canvas) {
            self.loading = false;
            var dataURL = canvas.toDataURL("image/png");
            self.$forceUpdate();
            self.setBaseImg(dataURL);
          });
        });
      }
      this.exportValue();
    },
    setBaseImg: function setBaseImg(base) {
      //提交base64图片
      commonRequest.base64Storage(this.data.quoteId, [base]).then(function (res) {});
    },
    exportValue: function exportValue() {
      //传递表单数据
      this.bsInsItem = _.find(this.data.flowNode.productInfoList, function (child) {
        return child.productType == '1';
      });
      this.bzInsItem = _.find(this.data.flowNode.productInfoList, function (child) {
        return child.productType == '0';
      });
      this.insuranceTime = {
        bzTime: this.bzInsItem && this.bzInsItem.effectiveEndTime ? this.bzInsItem.effectiveEndTime : '',
        bsTime: this.bsInsItem && this.bsInsItem.effectiveEndTime ? this.bsInsItem.effectiveEndTime : ''
      };
      this.$emit('exportTime', this.insuranceTime);
      this.closePage();
    }
  }
};