import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.fill.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PageContainer from "@/components/PageContainer";
import FormLabel from "@/components/FormSubLabel";
import dict from '@/mixins/dict';
import activityRequest from '../../api/insuranceActivities';
export default {
  name: "ActivityDDetail",
  mixins: [dict],
  data: function data() {
    return {
      form: {
        activeRequireList: new Array(1).fill().map(function (val) {
          return {
            vehicleNameCode: '',
            indicators: '',
            conditions: '',
            rate: '',
            relevanceStatus: []
          };
        })
      },
      vehicleList: [],
      indicatorsList: [{
        name: '新车投保占比',
        value: 'RATE1'
      }, {
        name: '综合续保率',
        value: 'RATE2'
      }, {
        name: '次新车续保率',
        value: 'RATE3'
      }, {
        name: '延保产品渗透率',
        value: 'RATE4'
      }, {
        name: '延保销售贡献度',
        value: 'RATE5'
      }, {
        name: '代步服务产品渗透率',
        value: 'RATE6'
      }, {
        name: '代步服务产品销售贡献度',
        value: 'RATE7'
      }],
      conditionsList: [{
        name: '大于',
        value: 'GT'
      }, {
        name: '小于',
        value: 'LT'
      }, {
        name: '等于',
        value: 'EQ'
      }],
      relevanceStatusTypeList: [{
        name: '与',
        value: '与'
      }, {
        name: '或',
        value: '或'
      }],
      activeOne: '1',
      activeTwo: '1',
      formLoading: false
    };
  },
  components: {
    PageContainer: PageContainer,
    FormLabel: FormLabel
  },
  watch: {
    '$route': function $route(to, from) {
      //监听路由是否变化）
      this.initData();
    }
  },
  created: function created() {},
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    initData: function initData() {
      var _this = this;
      activityRequest.getVehicleModelsList().then(function (res) {
        // 获取车型列表
        if (res.code == RESPONSE_SUCCESS) {
          _this.vehicleList = res.data;
        }
      }).catch(function (err) {
        _this.loading = false;
      });
      if (this.$route.query.activityId) {
        // 获取活动详情
        this.formLoading = true;
        activityRequest.getActivityDetail(this.$route.query.activityId).then(function (res) {
          _this.formLoading = false;
          if (res.code == RESPONSE_SUCCESS) {
            _this.form = res.data;
            _this.form.activeRequireList.forEach(function (item) {
              item.vehicleNameCode = item.vehicleNameCode.split(',');
              item.relevanceStatus = item.relevanceStatus.split(',');
            });
          }
        }).catch(function (err) {
          _this.loading = false;
        });
      }
    },
    cancel: function cancel() {
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.go(-1);
    }
  }
};