import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import Pagination from '@/components/Pagination';
import OemSelect from '@/components/OemSelect';
import configDialog from './configDialog';
import recoReq from '@/api/recognition';
import { commonExport, getSupplyers, getUserOrgInfoDealerOem } from '@/utils/common';
export default {
  name: 'ImportConfiguration',
  components: {
    Pagination: Pagination,
    OemSelect: OemSelect,
    configDialog: configDialog
  },
  mixins: [initHeight],
  data: function data() {
    return {
      bizType: localStorage.getItem("bizType") || '',
      userInfo: JSON.parse(localStorage.getItem("userInfo")) || {},
      searchForm: {
        applyBizType: this.bizType,
        applyOrgCode: null,
        supportInsCorp: null,
        supplierCode: null,
        allowInherit: null
      },
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      orgForm: {
        salesOrgCode: "",
        salesOrgName: "",
        salesOrgType: ""
      },
      loading: false,
      supplyers: [],
      supplierList: [],
      showDialog: false,
      currentType: '',
      drawer: false,
      currentConfigId: '',
      templateIdArr: []
    };
  },
  created: function created() {
    var orgObj = getUserOrgInfoDealerOem(this.bizType);
    this.searchForm.applyOrgCode = orgObj.salesOrgCode;
    this.searchForm.applyOrgName = orgObj.salesOrgName;
  },
  mounted: function mounted() {
    // 获取保险公司列表
    this.supplyers = getSupplyers();
    this.getSupplierList();
    this.loadData();
  },
  methods: {
    // 导出
    exportFn: function exportFn() {
      var exporParams = {
        total: this.total,
        fileName: '保单识别配置',
        exportUrl: '/tp-recognition-service/policy/identify/config/search',
        searchForm: _objectSpread(_objectSpread({}, this.listQuery), this.searchForm)
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    // 获取供应商列表
    getSupplierList: function getSupplierList() {
      var _this = this;
      recoReq.getSupplierList().then(function (res) {
        if (res.data) {
          _this.supplierList = res.data || [];
        }
        ;
      });
    },
    // 树形点击
    nodeClick: function nodeClick(data) {
      this.searchForm.applyOrgCode = data.salesOrgCode;
      this.searchForm.applyOrgName = data.salesOrgName;
      this.searchForm.applyBizType = data.bizType;
      this.searchForm.areaType = data.salesOrgType;
    },
    // 刷新
    breakData: function breakData() {
      this.loadData();
    },
    // 重置搜索表单
    resetSearch: function resetSearch() {
      this.searchForm = {
        applyBizType: this.bizType,
        applyOrgCode: null,
        applyOrgName: null,
        supportInsCorp: null,
        supplierCode: null,
        allowInherit: null
      };
      this.orgForm = {
        salesOrgCode: "",
        salesOrgName: '',
        salesOrgType: ""
      };
    },
    // 搜索
    searchData: function searchData() {
      this.loadData();
    },
    //获取列表数据
    loadData: function loadData(val) {
      var _this2 = this;
      this.showDialog = false;
      this.drawer = false;
      this.templateIdArr = [];
      if (val) return;
      this.loading = true;
      recoReq.getPolicyIdentifyConfig(_objectSpread({
        pageNum: this.listQuery.pageNum,
        pageSize: this.listQuery.pageSize
      }, this.searchForm)).then(function (res) {
        _this2.loading = false;
        if (res.code === 200) {
          _this2.tableData = res.data.list;
          _this2.total = res.data.page.recordsTotal;
        }
        ;
      }).catch(function (err) {
        _this2.loading = false;
      });
    },
    // 勾选复选框
    handleSelectionChange: function handleSelectionChange(val) {
      this.templateIdArr = val.map(function (val) {
        return val.configId;
      });
    },
    // 是否启用
    enableItem: function enableItem(row) {
      var _this3 = this;
      var data = row;
      data.enabled = row.enabled;
      recoReq.enableConfig(data.configId).then(function (res) {
        if (res.code == 200) {
          _this3.$message.success(res.msg);
        }
        _this3.loadData();
      });
    },
    // 新增/编辑
    addConfigure: function addConfigure(type) {
      var item = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      this.currentType = type;
      if (this.currentType === 'add') {
        this.showDialog = true;
      } else if (this.currentType === 'edit') {
        this.currentConfigId = item.configId.toString();
        this.showDialog = true;
      }
    },
    // 删除
    deleteItem: function deleteItem(item) {
      var _this4 = this;
      if (!this.templateIdArr.length && !item) {
        this.$message.warning('请选择需要删除的数据');
        return;
      }
      this.$confirm('您确定要删除选中的数据吗', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        type: 'warning'
      }).then(function () {
        var postData = _.isEmpty(_this4.templateIdArr) ? item.configId : _this4.templateIdArr.toString();
        recoReq.removeConfig(postData).then(function (res) {
          if (res.code === 200) {
            _this4.$message.success(res.msg);
            _this4.loadData();
          }
          ;
        });
      }).catch(function () {});
    }
  }
};