import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.json.stringify.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import dict from '../../mixins/dict';
import activityRequest from '../../api/insuranceActivities';
import { limitInput } from '@/utils/common';
export default {
  name: 'MarketingRulesSet',
  mixins: [dict],
  data: function data() {
    return {
      loading: false,
      rulesForm: {
        expireDay: '',
        exceedFollowDay: '',
        marketingRuleClueVoList: [{
          clueColor: '',
          remark: '',
          startDay: '',
          endDay: ''
        }],
        allocationRule: 'AR01'
      }
    };
  },
  watch: {
    '$route': function $route(to, from) {
      //监听路由是否变化
      this.loadData();
    }
  },
  mounted: function mounted() {
    this.loadData();
  },
  methods: {
    addTag: function addTag() {
      var obj = this.rulesForm.marketingRuleClueVoList[this.rulesForm.marketingRuleClueVoList.length - 1],
        flag = true;
      if (this.rulesForm.expireDay && obj.endDay) {
        if (Number(obj.endDay) > Number(this.rulesForm.expireDay) || Number(obj.endDay) === Number(this.rulesForm.expireDay)) {
          this.$message.error('结束天数已达到最大距保险到期天数');
          return;
        }
      }
      for (var i in obj) {
        if (!String(obj[i])) {
          flag = false;
        }
      }
      if (!flag) {
        this.$message.error('请完整填写后再新增标签周期');
        return;
      }
      if (Number(obj.startDay) >= Number(obj.endDay)) {
        this.$message.error('结束天数不小于开始天数');
        return;
      }
      this.rulesForm.marketingRuleClueVoList.push({
        clueColor: '',
        remark: '',
        startDay: Number(this.rulesForm.marketingRuleClueVoList[this.rulesForm.marketingRuleClueVoList.length - 1].endDay) + 1,
        endDay: ''
      });
    },
    deleteTag: function deleteTag() {
      this.rulesForm.marketingRuleClueVoList.pop();
    },
    loadData: function loadData() {
      var _this = this;
      //获取列表数据
      this.loading = true;
      activityRequest.getMarketingRuleView().then(function (res) {
        _this.loading = false;
        if (res.code == RESPONSE_SUCCESS && res.data) {
          _this.rulesForm = res.data;
        }
      }).catch(function (err) {
        _this.loading = false;
      });
    },
    vaildate: function vaildate() {
      var _this2 = this;
      var flag = false;
      this.rulesForm.marketingRuleClueVoList.some(function (val) {
        if (Number(val.startDay) >= Number(val.endDay)) {
          _this2.$message.warning("".concat(val.remark, "\u7684\u5468\u671F\u5929\u6570\u5DE6\u8FB9\u4E0D\u80FD\u5927\u4E8E\u53F3\u8FB9"));
          flag = true;
          return flag;
        }
        ;
      });
      return flag;
    },
    save: function save() {
      var _this3 = this;
      var flag = true;
      for (var i = 0; i < this.rulesForm.marketingRuleClueVoList.length; i++) {
        var item = this.rulesForm.marketingRuleClueVoList[i];
        for (var j in item) {
          if (!String(item[j])) {
            flag = false;
            break;
          }
        }
      }
      if (flag) {
        if (this.vaildate()) return;
        this.loading = true;
        var params = JSON.parse(JSON.stringify(this.rulesForm));
        params.marketingRuleClueParamList = this.rulesForm.marketingRuleClueVoList;
        delete params.marketingRuleClueVoList;
        activityRequest.marketingRuleUpdate(params).then(function (res) {
          _this3.loading = false;
          if (res.code == RESPONSE_SUCCESS) {
            _this3.$message({
              type: 'success',
              message: '保存成功'
            });
          }
        }).catch(function (err) {
          _this3.loading = false;
          _this3.$message({
            type: 'error',
            message: res.msg
          });
        });
      } else {
        this.$message.error('请完整填写信息后再保存');
      }
    },
    //对输入内容做精度校验
    onInput: function onInput(num, min, max, limit) {
      var val = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : '';
      // 删除保险到期天数时清空标签周期
      // if (val && val === 'expireDay' && (!num || num === 0) && (this.rulesForm.marketingRuleClueVoList && this.rulesForm.marketingRuleClueVoList.length !== 0)) {
      //     this.rulesForm.marketingRuleClueVoList.length = 1;
      //     this.rulesForm.marketingRuleClueVoList.map((item) => {
      //         item.clueColor = null;
      //         item.endDay = null;
      //         item.remark = null;
      //         item.startDay = null;
      //     })
      // }
      if (min || min === 0 && num < min) {
        num = min;
      }
      if (max && num > max) {
        num = max;
      }
      return limitInput(num, limit);
    },
    //对开始天数做精度校验
    startDayOnInput: function startDayOnInput(num, min, max, limit) {
      if (Number(num) > Number(this.rulesForm.expireDay) || Number(num) === Number(this.rulesForm.expireDay)) {
        this.$message.error('开始天数不得大于或等于距保险到期天数');
        return;
      }
      if (min || min === 0 && num < min) {
        num = min;
      }
      if (max && num > max) {
        num = max;
      }
      return limitInput(num, limit);
    },
    //对结束天数做精度校验
    endDayOnInput: function endDayOnInput(num, min, max, limit) {
      if (Number(num) > Number(this.rulesForm.expireDay)) {
        this.$message.error('结束天数不得大于距保险到期天数');
        return;
      }
      if (min || min === 0 && num < min) {
        num = min;
      }
      if (max && num > max) {
        num = max;
      }
      return limitInput(num, limit);
    }
  }
};