import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.array.splice.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PageContainer from "@/components/PageContainer";
import FormLabel from "@/components/FormSubLabel";
import requestOrg from '@/api/orgChildren';
import requestPolicy from '@/api/policy';
import SYX_RISK from '@/assets/json/SYX_RISK'; //获取商业险险种
import dict from '@/mixins/dict';
import Rules from '@/utils/rules';
import policyRequest from '@/api/insurancepolicy';
import recognitionRequest from '@/api/recognition';
import storageRequest from '@/api/storage';
import customersRequest from '@/api/customers';
import OemSelect from '@/components/OemSelect';
import commonRequest from "@/api/common";
import leadManagementApi from '@/api/leadManagement';
import { getSupplyers, character, idCardValidate, getNextYear, getUserOrgInfoDealerOem, downFile, getCityItem, getBeforeOfDate, limitInput } from '@/utils/common';
import CarBrands from '@/components/CarBrands/index'; // Secondary package based on el-pagination
export default {
  name: "InsurancePolicyAdd",
  mixins: [dict],
  components: {
    PageContainer: PageContainer,
    FormLabel: FormLabel,
    OemSelect: OemSelect,
    CarBrands: CarBrands
  },
  data: function data() {
    return {
      identitySearchForm: {
        insCorpCode: ''
      },
      bzIdentityJson: "",
      bsIdentityJson: "",
      bzIdentityData: null,
      bsIdentityData: null,
      hasInsCorpCode: false,
      showDeleteIcon: true,
      showBzFilesListUpload: true,
      showBsFilesListUpload: true,
      bzFilesList: [],
      bsFilesList: [],
      currentBzFilesList: [],
      currentBsFilesList: [],
      policyIdentityDialog: false,
      searchSalesDateByVinLoading: false,
      quoteVehicle: {
        cjVhcBrandNo: '',
        // 厂家品牌编号
        cjVhcSeriesNo: '' //   厂家车系编号
        // cjVhcModelNo: '', //  厂家车型编号
      },

      carTypeVal: [],
      regionData: JSON.parse(localStorage.getItem('VI_CityPcTree')) || [],
      isDisable: false,
      bizType: localStorage.getItem("bizType") || '',
      value: '',
      dictList: [],
      disabledLessThan: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      disabledInsuredTime: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > Date.now() || time.getTime() < new Date(new Date("".concat(new Date().getFullYear() - 1, "-").concat(new Date().getMonth() + 1, "-").concat(new Date().getDate()))).getTime();
        }
      },
      disabledEffectiveTime: this.effectiveOptions(),
      form: {},
      formRules: {
        salesOrgName: {
          required: true,
          message: '请选择业务机构',
          trigger: 'change'
        },
        salemanBy: {
          required: true,
          message: '请选择业务员',
          trigger: 'change'
        },
        insCorpCode: {
          required: true,
          message: '请选择投保公司',
          trigger: 'change'
        },
        address: {
          required: true,
          message: '请选择投保地区',
          trigger: 'change'
        },
        insuredTime: {
          required: true,
          message: '请选择承保日期',
          trigger: 'change'
        },
        insuredType: {
          required: true,
          message: '请选择出单方式',
          trigger: 'change'
        },
        bzRiskNo: [{
          required: true,
          message: '请输入保单号',
          trigger: 'blur'
        }, {
          min: 6,
          max: 40,
          message: "请检查保单号是否正确",
          trigger: ["blur", "change"]
        }],
        policyNo: {
          required: true,
          message: '请输入保单号',
          trigger: 'blur'
        },
        bzPremium: {
          required: true,
          message: '请输入交强险金额',
          trigger: 'blur'
        },
        bsRiskNo: [{
          required: true,
          message: '请输入保单号',
          trigger: 'blur'
        }, {
          min: 6,
          max: 40,
          message: "请检查保单号是否正确",
          trigger: ["blur", "change"]
        }],
        fcBsRiskNo: [{
          required: true,
          message: '请输入保单号',
          trigger: 'blur'
        }, {
          min: 10,
          max: 40,
          message: "请检查保单号是否正确",
          trigger: ["blur", "change"]
        }],
        renewalType: {
          required: true,
          message: '请选择续保标识',
          trigger: 'change'
        },
        internetStamp: {
          required: true,
          message: '请选择互联网标识',
          trigger: 'change'
        },
        agricultureStamp: {
          required: true,
          message: '请选择涉农标识',
          trigger: 'change'
        },
        matchPolicyStatus: {
          required: true,
          message: '请选择匹配政策',
          trigger: 'change'
        },
        quoteVehicle: {
          vin: [{
            required: true,
            pattern: Rules["VIN_RULE"].value,
            message: '请输入车架号',
            trigger: 'blur'
          }, {
            pattern: Rules["VIN_RULE"].value,
            message: "车架号格式有误",
            trigger: ["blur", "change"]
          }],
          ownerName: [{
            required: true,
            message: '请输入车主名 ',
            trigger: 'blur'
          }, {
            min: 2,
            max: 50,
            pattern: Rules["NAME"].value,
            message: "车主名称格式有误",
            trigger: ["blur", "change"]
          }],
          ownerCertificateType: {
            required: true,
            message: '请选择证件类型',
            trigger: 'change'
          },
          ownerCertificateNo: {
            required: true,
            message: '请输入车主证件号',
            trigger: 'blur'
          },
          ownerMobile: [{
            required: true,
            message: '请输入车主联系方式',
            trigger: 'blur'
          }, {
            pattern: Rules["MOBILE_RULE"].value,
            message: "联系电话格式有误",
            trigger: ["blur", "change"]
          }],
          plateNo: [{
            required: true,
            message: '请输入车牌号',
            trigger: 'blur'
          }, {
            min: 7,
            max: 8,
            message: "输入的车牌号长度小于7位",
            trigger: ["blur", "change"]
          }, {
            pattern: Rules["PLATENO_RULE"].value,
            message: "车牌号格式有误",
            trigger: ["blur", "change"]
          }],
          registerDate: {
            required: true,
            message: '请选择注册日期',
            trigger: 'change'
          },
          transferDate: {
            required: true,
            message: '请选择过户日期',
            trigger: 'change'
          },
          vehicleType: {
            required: false,
            message: '请选择行驶车辆类型',
            trigger: 'change'
          },
          useAttribute: {
            required: true,
            message: '请选择使用性质',
            trigger: 'change'
          },
          plateType: {
            required: false,
            message: '请选择号牌种类',
            trigger: 'change'
          }
        },
        applicant: {
          name: [{
            required: true,
            message: '请输入投保人名称',
            trigger: 'blur'
          }, {
            min: 2,
            max: 50,
            pattern: Rules["NAME"].value,
            message: "投保人名称格式有误",
            trigger: ["blur", "change"]
          }],
          certificateType: {
            required: true,
            message: '请选择证件类型',
            trigger: 'change'
          },
          certificateNo: {
            required: true,
            message: '请输入证件号',
            trigger: 'blur'
          },
          mobile: {
            required: true,
            message: '请输入联系方式',
            trigger: 'blur'
          }
        },
        insurant: {
          name: [{
            required: true,
            message: '请输入被保人名称',
            trigger: 'blur'
          }, {
            min: 2,
            max: 50,
            pattern: Rules["NAME"].value,
            message: "被保人名称格式有误",
            trigger: ["blur", "change"]
          }],
          certificateType: {
            required: true,
            message: '请选择证件类型',
            trigger: 'change'
          },
          certificateNo: {
            required: true,
            message: '请输入证件号',
            trigger: 'blur'
          },
          mobile: {
            required: true,
            message: '请输入联系方式',
            trigger: 'blur'
          }
        },
        bzRiskKind: {
          effectiveStartTime: {
            required: true,
            message: '请选择起保日期',
            trigger: 'change'
          },
          effectiveEndTime: {
            required: true,
            message: '请选择终保日期',
            trigger: 'change'
          },
          poundageRate: {
            required: true,
            message: '请输入费率',
            trigger: 'blur'
          }
        },
        bsRiskKind: {
          effectiveStartTime: {
            required: true,
            message: '请选择起保日期',
            trigger: 'change'
          },
          effectiveEndTime: {
            required: true,
            message: '请选择终保日期',
            trigger: 'change'
          },
          poundageRate: {
            required: true,
            message: '请输入费率',
            trigger: 'blur'
          }
        },
        fcRiskKind: {
          effectiveStartTime: {
            required: true,
            message: '请选择起保日期',
            trigger: 'change'
          },
          effectiveEndTime: {
            required: true,
            message: '请选择终保日期',
            trigger: 'change'
          },
          poundageRate: {
            required: true,
            message: '请输入费率',
            trigger: 'blur'
          }
        },
        quoteVehicleTax: {
          curYearTax: {
            required: true,
            message: '请输入车船税当年应缴',
            trigger: 'blur'
          },
          lastYearPay: {
            required: false,
            message: '请输入车船税往年补缴',
            trigger: 'blur'
          },
          delayPayMoney: {
            required: false,
            message: '请输入车船税滞纳金',
            trigger: 'blur'
          }
        }
      },
      activeOne: '1',
      activeTwo: '1',
      activeThree: '1',
      activeFour: '1',
      activeFive: '1',
      activeSix: '1',
      activeSeven: '1',
      activeEight: '1',
      activeNine: '1',
      activeTen: '1',
      highlightCurrent: true,
      policyCityItems: [],
      orgData: [],
      defaultProps: {
        children: 'children',
        label: 'fullName'
      },
      VEHICLE_KINDS: [],
      PLATE_TYPE: [],
      ENGRGY_TYPES: [],
      USE_ATTRIBUTE: [],
      VI_CERTIFICATE_TYPE: [],
      supplyers: [],
      salesman: [],
      regionOptions: [],
      brandSeriesList: [],
      brandModeList: [],
      showInsuranceType: "",
      dutyAllCode: [],
      userInfo: {},
      userName: '',
      //账号用户
      SZX_OPTIONS: [],
      CKXRX_OPTIONS: [],
      RATE_OPTIONS: [],
      policyId: null,
      riskType: '',
      formLoading: false,
      canEdit: false,
      fileList: [],
      uploadType: '.pdf,.PDF',
      orgForm: {
        salesOrgCode: '',
        salesOrgName: ''
      },
      transferFlagData: [{
        value: true,
        label: '是'
      }, {
        value: false,
        label: '否'
      }],
      allDisabled: false,
      accidentName: '',
      bzName: '',
      bsName: '',
      identityBsLoading: false,
      identityBzLoading: false,
      supportSupplyers: [],
      policyForView: false,
      // true 管理保单 false 保单管理 
      saveTmpStartTime: '',
      saveTmpEndTime: ''
    };
  },
  watch: {
    '$route': function $route(to, from) {
      var _this = this;
      //监听路由是否变化
      this.policyForView = this.$route.query.policyForView;
      this.policyId = this.$route.query.policyId;
      this.riskType = this.$route.query.riskType;
      var source = this.$route.query.source;
      this.canEdit = ['add', 'import'].includes(_.toLower(source));
      this.initForm();
      this.initData();
      if (this.$route.query.policyAnalysisId) {
        this.policyIndetityDetail(this.$route.query.policyAnalysisId);
      }
      this.$nextTick(function () {
        _this.$refs['form'].clearValidate();
      });
    },
    'form.quoteDutys': {
      handler: function handler(newValue) {
        if (newValue) {
          if (this.showInsuranceType != '2') {
            var amount = 0;
            newValue.forEach(function (item) {
              if (item.amount) {
                amount = parseFloat(amount) + parseFloat(item.amount);
              }
            });
            this.form.bsPremium = amount.toFixed(2);
            this.form.totalPremium = parseFloat(this.form.fcPremium || 0) + parseFloat(this.form.bsPremium || 0) + parseFloat(this.form.bzPremium || 0) + parseFloat(this.form.quoteVehicleTax.curYearTax || 0);
            this.form.totalPremium = this.form.totalPremium.toFixed(2);
          }
        }
      },
      deep: true
    },
    'form.bzPremium': {
      handler: function handler(newValue) {
        this.form.totalPremium = parseFloat(this.form.fcPremium || 0) + parseFloat(this.form.bsPremium || 0) + parseFloat(newValue || 0) + parseFloat(this.form.quoteVehicleTax.curYearTax || 0);
        this.form.totalPremium = this.form.totalPremium.toFixed(2);
      },
      deep: true
    },
    'form.fcPremium': {
      handler: function handler(newValue) {
        if (newValue) {
          var fcTotalPremium = parseFloat(newValue || 0) + parseFloat(this.form.bsPremium || 0) + parseFloat(this.form.bzPremium || 0) + parseFloat(this.form.quoteVehicleTax.curYearTax || 0);
          this.form.totalPremium = fcTotalPremium.toFixed(2);
        }
      },
      deep: true
    },
    'form.quoteVehicleTax.curYearTax': {
      handler: function handler(newValue) {
        this.form.totalPremium = parseFloat(this.form.bzPremium || 0) + parseFloat(newValue || 0) + parseFloat(this.form.bsPremium || 0);
        this.form.totalPremium = this.form.totalPremium.toFixed(2);
      },
      deep: true
    },
    'form.insuredType': {
      handler: function handler(newValue) {
        this.bsName = '';
        this.bzName = '';
        this.accidentName = '';
        switch (newValue) {
          case '01':
            this.bsName = '商业险';
            this.bzName = '交强险';
            break;
          case '3':
            this.bsName = '商业险';
            this.bzName = '交强险';
            this.accidentName = '非车险';
            break;
          case '0':
            this.bsName = '商业险';
            break;
          case '1':
            this.bzName = '交强险';
            break;
          case '2':
            this.accidentName = '非车险';
            break;
        }
      },
      deep: true
    }
  },
  created: function created() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo')) || {};
    this.initForm();
    this.dutyAllCode = SYX_RISK; //获取商业险险别
    this.policyId = this.$route.query.policyId;
    this.riskType = this.$route.query.riskType;
    var source = this.$route.query.source;
    this.canEdit = ['add', 'import'].includes(_.toLower(source));
    if (this.$route.query) {
      this.policyForView = this.$route.query.policyForView;
    }
    // 从保单批量识别入口进来
    if (this.$route.query && this.$route.query.policyAnalysisId) {
      this.policyIndetityDetail(this.$route.query.policyAnalysisId);
    }
  },
  mounted: function mounted() {
    this.initData();
    if (this.bizType && this.bizType === 'DEALER') {
      var userInfo = JSON.parse(localStorage.getItem('userInfo'));
      var organization = userInfo && userInfo.organization && userInfo.organization.data || null;
      if (organization) {
        this.form.salesOrgType = organization.bizType || this.bizType;
      } else {
        this.form.salesOrgType = this.bizType;
      }
    }
  },
  beforeRouteLeave: function beforeRouteLeave(to, form, next) {
    var _this2 = this;
    if (this.form && form.path == '/policy/add' && !this.policyId) {
      if (!_.isEmpty(this.form.quoteDutys)) {
        this.form.quoteDutys.forEach(function (duty) {
          var risk = _.find(_this2.dutyAllCode, function (item) {
            return item.dictCode == duty.riskkindCode;
          });
          if (risk) duty.riskkindName = risk.dictName;
        });
      }
      sessionStorage.setItem("policyForm", JSON.stringify(this.form));
    }
    next();
  },
  methods: {
    // 获取所有险别配置
    getAllDuty: function getAllDuty() {
      var _this3 = this;
      commonRequest.getAllDuty().then(function (res) {
        if (res.code == RESPONSE_SUCCESS && res.data) {
          var allDutys = res.data;
          // 三者险保额
          var szx = _.find(allDutys, function (item) {
            return item.riskkindCode == 'B';
          });
          if (szx && szx.unitAmountOptions) {
            _this3.SZX_OPTIONS = [];
            szx.unitAmountOptions.forEach(function (element) {
              _this3.SZX_OPTIONS.push({
                text: _this3.formatOption(element),
                value: element
              });
            });
          }
          // 司机乘客险保额
          var ckx = _.find(allDutys, function (item) {
            return ['C', 'D'].includes(item.riskkindCode);
          });
          if (ckx && ckx.unitAmountOptions) {
            _this3.CKXRX_OPTIONS = [];
            ckx.unitAmountOptions.forEach(function (element) {
              _this3.CKXRX_OPTIONS.push({
                text: _this3.formatOption(element),
                value: element
              });
            });
          }
          // 免赔率
          var rate = _.find(allDutys, function (item) {
            return ['A05', 'B03', 'D01'].includes(item.riskkindCode);
          });
          if (rate && rate.unitAmountOptions) {
            _this3.RATE_OPTIONS = [];
            rate.unitAmountOptions.forEach(function (element) {
              _this3.RATE_OPTIONS.push({
                text: element + '%',
                value: element
              });
            });
          }
        }
      }).catch(function (error) {});
    },
    // 格式化保额
    formatOption: function formatOption(amount) {
      if (amount) {
        var divideValue = _.divide(amount, 10000);
        var text = amount;
        if (divideValue >= 1) {
          text = divideValue + '万';
        }
        return text;
      }
    },
    insuredTimeChange: function insuredTimeChange(val) {
      this.form.bzRiskKind.effectiveStartTime = '';
      this.form.bzRiskKind.effectiveEndTime = '';
      this.form.bsRiskKind.effectiveStartTime = '';
      this.form.bsRiskKind.effectiveEndTime = '';
      this.form.fcRiskKind.effectiveStartTime = '';
      this.form.fcRiskKind.effectiveEndTime = '';
      this.$forceUpdate();
    },
    effectiveOptions: function effectiveOptions() {
      var self = this;
      return {
        disabledDate: function disabledDate(time) {
          if (self.form && self.form.insuredTime) {
            return time.getTime() < new Date("".concat(self.form.insuredTime.split(" ")[0], " 00:00:00")).getTime() || time.getTime() >= new Date("".concat(new Date(new Date(self.form.insuredTime + ' 00:00:00').getTime() + 1000 * 60 * 60 * 24 * 90))).getTime();
          }
          ;
          return false;
        }
      };
    },
    filterData: function filterData(index) {
      //险种保额下拉框只能输入纯数字
      if (this.$refs["riskItem".concat(index)] && this.$refs["riskItem".concat(index)][0]) {
        var str = this.$refs["riskItem".concat(index)][0].$data.selectedLabel;
        str = str.replace(/[^\d]/g, "");
        this.$refs["riskItem".concat(index)][0].$data.selectedLabel = str;
        this.$forceUpdate();
      }
    },
    setFormVal: function setFormVal(form, formKey, val) {
      return this.$set(form, formKey, val ? val : '');
    },
    setQuoteVehicleVal: function setQuoteVehicleVal(formKey, val) {
      return this.$set(this.form.quoteVehicle, formKey, val ? val : '');
    },
    policyIndetityDetail: function policyIndetityDetail(id) {
      var _this4 = this;
      recognitionRequest.policyAnalysisDetail(id).then(function (res) {
        if (res.code == 200) {
          if (res.data) {
            var resData = res.data;
            // 保司信息
            _this4.form.insCorpCode = resData.insCorpCode ? resData.insCorpCode : ""; //保司
            // 车辆信息
            _this4.setQuoteVehicleVal('plateNo', resData.plateNo); //车牌号
            if (!resData.plateNo || resData.plateNo == '新车' || resData.plateNo == '未上牌') {
              _this4.form.quoteVehicle.isNewCar = true;
            }
            _this4.setQuoteVehicleVal('vin', resData.vin); //车架号
            _this4.setQuoteVehicleVal('engineNo', resData.engineNo); //发动机号
            _this4.setQuoteVehicleVal('registerDate', resData.registerDate); //注册日期
            _this4.setQuoteVehicleVal('modelName', resData.modelName); //品牌型号
            _this4.setQuoteVehicleVal('seats', resData.seats); //座位数
            _this4.setQuoteVehicleVal('tonNumber', resData.tonNumber); //核定载质量
            _this4.setQuoteVehicleVal('wholeWeight', resData.wholeWeight); //整备质量
            _this4.setQuoteVehicleVal('power', resData.power); //功率
            _this4.setQuoteVehicleVal('displacement', resData.displacement); //尾气排量

            _this4.setQuoteVehicleVal('useAttribute', resData.useAttribute && resData.useAttribute == 'YING_YE' ? '1' : '2'); //使用性质

            // 车主信息 (先判断交强险有没有车主信息，在判断商业险有么有，两个都没有的话取空)
            _this4.setQuoteVehicleVal('ownerName', resData.ownerName); //车主姓名
            _this4.setQuoteVehicleVal('ownerCertificateType', resData.insurantCertificateType); //证件类型
            _this4.setQuoteVehicleVal('ownerCertificateNo', resData.insurantCertificateNo); //车主证件号
            _this4.setQuoteVehicleVal('ownerMobile', resData.insurantMobile); //车主手机号
            if (resData && resData.riskType && resData.riskType == 'BZ') {
              // 交强险保单信息
              _this4.setFormVal(_this4.form, 'bzRiskNo', resData.policyNo); //交强险保单号
              _this4.setFormVal(_this4.form.bzRiskKind, 'effectiveStartTime', resData.effectiveStartTime); //交强险起保日期
              _this4.setFormVal(_this4.form.bzRiskKind, 'effectiveEndTime', resData.effectiveEndTime); //交强险终保日期
              _this4.setFormVal(_this4.form, 'bzPremium', resData.actualPremium); //交强险金额
              _this4.setFormVal(_this4.form.quoteVehicleTax, 'curYearTax', resData.vehicleTaxPremium); //车船税金额
            }

            if (resData && resData.riskType && resData.riskType == 'BS') {
              // 商业险保单信息
              _this4.setFormVal(_this4.form, 'bsRiskNo', resData.policyNo); //商业险保单号
              _this4.setFormVal(_this4.form.bsRiskKind, 'effectiveStartTime', resData.effectiveStartTime); //商业险起保日期
              _this4.setFormVal(_this4.form.bsRiskKind, 'effectiveEndTime', resData.effectiveEndTime); //商业险终保日期
              _this4.setFormVal(_this4.form, 'bsPremium', resData.actualPremium); //商业险总金额
              if (resData && resData.analysisRiskkind && resData.analysisRiskkind.length != 0) {
                var quoteDutylist = [];
                resData.analysisRiskkind.map(function (item) {
                  quoteDutylist.push({
                    absoluteDeductible: item.absoluteDeductible,
                    amount: item.actualPremium ? item.actualPremium : 0,
                    riskkindCode: item.riskkindCode,
                    riskkindName: item.riskkindName,
                    unitAmount: _.indexOf(['FZ01', 'FZ02', 'FZ03', 'FZ04'], item.riskkindCode) != -1 ? item.quantity : item.unitAmount || 0,
                    quantity: item.quantity
                  });
                });
                _this4.form.quoteDutys = quoteDutylist;
              }
            }
          }
        }
      });
    },
    setInsCorpCode: function setInsCorpCode(val) {
      if (val) {
        this.hasInsCorpCode = true;
      } else {
        this.identitySearchForm.insCorpCode = null;
        this.hasInsCorpCode = false;
        this.deleteBzFile();
        this.deleteBsFile();
      }
    },
    onChangeUpload: function onChangeUpload() {
      if (!this.identitySearchForm.insCorpCode) {
        this.hasInsCorpCode = false;
        this.$message.warning("请先选择承保保险公司");
        return;
      } else {
        this.hasInsCorpCode = true;
      }
    },
    // 判断文件类型
    bzBeforeUpload: function bzBeforeUpload(file) {
      if (this.bzFilesList && this.bzFilesList.length != 0) {
        this.bzFilesList = [];
      }
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      var arr = ['pdf', 'PDF'];
      if (!arr.includes(testmsg)) {
        this.$message.warning("仅支持 PDF 文件");
        return false;
      }
    },
    // 判断文件类型
    bsBeforeUpload: function bsBeforeUpload(file) {
      if (this.bsFilesList && this.bsFilesList.length != 0) {
        this.bsFilesList = [];
      }
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      var arr = ['pdf', 'PDF'];
      if (!arr.includes(testmsg)) {
        this.$message.warning("仅支持 PDF 文件");
        return false;
      }
    },
    // 删除文件
    deleteBzFile: function deleteBzFile() {
      this.showBzFilesListUpload = true;
      this.currentBzFilesList = [];
      this.bzIdentityJson = '';
    },
    // 删除文件
    deleteBsFile: function deleteBsFile() {
      this.showBsFilesListUpload = true;
      this.currentBsFilesList = [];
      this.bsIdentityJson = '';
    },
    // 识别文件
    analysisJson: function analysisJson(data, type) {
      var _this5 = this;
      recognitionRequest.analysisJson(data).then(function (res) {
        if (res.code == 200) {
          if (type == 'BZ') {
            _this5.identityBzLoading = false;
            _this5.bzIdentityJson = res.data && JSON.stringify(res.data) !== '{}' ? res.data : null;
            _this5.bzIdentityData = res.data && JSON.stringify(res.data) !== '{}' ? res.data : null;
            _this5.currentBzFilesList = [];
          } else {
            _this5.identityBsLoading = false;
            _this5.bsIdentityJson = res.data && JSON.stringify(res.data) !== '{}' ? res.data : null;
            _this5.bsIdentityData = res.data && JSON.stringify(res.data) !== '{}' ? res.data : null;
            _this5.currentBsFilesList = [];
          }
        } else {
          if (type == 'BZ') {
            _this5.identityBzLoading = false;
            _this5.bzIdentityJson = null;
          } else {
            _this5.identityBsLoading = false;
            _this5.bsIdentityJson = null;
          }
        }
      }).catch(function (err) {
        if (type == 'BZ') {
          _this5.identityBzLoading = false;
          _this5.bzIdentityJson = null;
        } else {
          _this5.identityBsLoading = false;
          _this5.bsIdentityJson = null;
        }
      });
    },
    // 上传交强险保单文件
    bzUploadFileClicked: function bzUploadFileClicked(param, type) {
      var _this6 = this;
      this.identityBzLoading = true;
      var formData = new FormData();
      formData.append('file', param.file);
      storageRequest.uploadFile(formData).then(function (res) {
        if (res.code === 200) {
          _this6.$message.success(res.msg);
        }
        var _fileName = param.file.name;
        var fileNameLen = _fileName.length;
        var suffix = _fileName.substring(_fileName.lastIndexOf('.'));
        if (fileNameLen > 40) {
          _fileName = _fileName.substring(0, 40 - suffix.length) + suffix;
        }
        var obj = {
          fileExtension: param.file.name.split('.')[1],
          fileId: res.data.fileStorageId.toString(),
          fileGroup: type,
          fileName: _fileName,
          name: _fileName,
          //	文件名
          fileSize: parseFloat(param.file.size / 1024).toFixed(2) + 'kb'
        };
        _this6.bzFilesList.push(obj);
        _this6.showBzFilesListUpload = false;
        setTimeout(function () {
          var data = {
            "fileStorageId": res.data.fileStorageId.toString(),
            "insCorpCode": _this6.identitySearchForm.insCorpCode,
            "riskType": type,
            "bizType": _this6.form.salesOrgType,
            "fileName": param.file.name
          };
          _this6.analysisJson(data, type);
        }, 1000);
      }).catch(function (err) {
        _this6.identityBzLoading = false;
      });
    },
    // 上传商业险保单文件
    bsUploadFileClicked: function bsUploadFileClicked(param, type) {
      var _this7 = this;
      this.identityBsLoading = true;
      var formData = new FormData();
      formData.append('file', param.file);
      storageRequest.uploadFile(formData).then(function (res) {
        if (res.code === 200) {
          _this7.$message.success(res.msg);
        }
        var _fileName = param.file.name;
        var fileNameLen = _fileName.length;
        var suffix = _fileName.substring(_fileName.lastIndexOf('.'));
        if (fileNameLen > 40) {
          _fileName = _fileName.substring(0, 40 - suffix.length) + suffix;
        }
        var obj = {
          fileExtension: param.file.name.split('.')[1],
          fileId: res.data.fileStorageId.toString(),
          fileGroup: type,
          fileName: _fileName,
          name: _fileName,
          //	文件名
          fileSize: parseFloat(param.file.size / 1024).toFixed(2) + 'kb'
        };
        _this7.bsFilesList.push(obj);
        _this7.showBsFilesListUpload = false;
        setTimeout(function () {
          var data = {
            "fileStorageId": res.data.fileStorageId.toString(),
            "insCorpCode": _this7.identitySearchForm.insCorpCode,
            "riskType": type,
            "bizType": _this7.form.salesOrgType,
            "fileName": param.file.name
          };
          _this7.analysisJson(data, type);
        }, 1000);
      }).catch(function (err) {
        _this7.identityBsLoading = false;
      });
    },
    // 打开保单识别窗口
    policyIdentity: function policyIdentity() {
      var _this8 = this;
      this.policyIdentityDialog = true;
      recognitionRequest.getSupportInsCorpList().then(function (res) {
        if (res.code == 200) {
          _this8.supportSupplyers = res.data || [];
        }
      });
      this.reSetIdentityForm();
    },
    // 关闭弹窗
    closePage: function closePage() {
      this.policyIdentityDialog = false;
      this.reSetIdentityForm();
    },
    // 重置识别表单
    reSetIdentityForm: function reSetIdentityForm() {
      this.identitySearchForm.insCorpCode = null;
      this.hasInsCorpCode = false;
      this.deleteBzFile();
      this.deleteBsFile();
    },
    // 车主信息赋值
    setVehicleValInfo: function setVehicleValInfo(policyData) {
      this.setQuoteVehicleVal('ownerName', policyData.ownerName); //车主姓名
      this.setQuoteVehicleVal('ownerCertificateType', policyData.insurantCertificateType); //证件类型
      this.setQuoteVehicleVal('ownerCertificateNo', policyData.insurantCertificateNo); //车主证件号
      this.setQuoteVehicleVal('ownerMobile', policyData.insurantMobile); //车主手机号
    },
    // 投保人信息赋值
    setApplicantInfo: function setApplicantInfo(policyData) {
      this.setFormVal(this.form.applicant, 'name', policyData.applicantName); //投保人姓名
      this.setFormVal(this.form.applicant, 'certificateType', policyData.insurantCertificateType); //投保人姓名
      this.setFormVal(this.form.applicant, 'certificateNo', policyData.insurantCertificateNo); //投保人姓名
      this.setFormVal(this.form.applicant, 'mobile', policyData.insurantMobile); //投保人姓名
    },
    // 被保人信息赋值
    setInsurantInfo: function setInsurantInfo(policyData) {
      this.setFormVal(this.form.insurant, 'name', policyData.insurantName); //被保人姓名
      this.setFormVal(this.form.insurant, 'certificateType', policyData.insurantCertificateType); //被保人证件类型
      this.setFormVal(this.form.insurant, 'certificateNo', policyData.insurantCertificateNo); //被保人证件号
      this.setFormVal(this.form.insurant, 'mobile', policyData.insurantMobile); //被保人手机号
    },
    // 设置三个不是同一个人的情况
    setNotSameOwnerName: function setNotSameOwnerName(policyData) {
      if (policyData.ownerName == policyData.insurantName) {
        // console.log('车主 == 被保人')
        // 车主和被保人一致
        this.setFormVal(this.form.applicant, 'applicantSameAsOwner', false); //同车主
        this.setFormVal(this.form.insurant, 'sameAsOwner', true); //同车主
        this.setFormVal(this.form.insurant, 'sameAsInsurer', false); //同投保人 
        // 车主
        this.setVehicleValInfo(policyData);
        //投保人姓名
        this.setFormVal(this.form.applicant, 'name', policyData.applicantName);
        this.setFormVal(this.form.applicant, 'certificateType', null); //投保人姓名
        this.setFormVal(this.form.applicant, 'certificateNo', null); //投保人姓名
        this.setFormVal(this.form.applicant, 'mobile', null); //投保人姓名
        //被保人
        this.setInsurantInfo(policyData);
      } else if (policyData.applicantName == policyData.insurantName) {
        // console.log('投保人 == 被保人')
        // 被保人和投保人一致
        this.setFormVal(this.form.applicant, 'applicantSameAsOwner', false); //同车主;
        this.setFormVal(this.form.insurant, 'sameAsOwner', false); //同车主;
        this.setFormVal(this.form.insurant, 'sameAsInsurer', true); //同投保人;
        // 车主
        this.setQuoteVehicleVal('ownerName', policyData.ownerName); //车主姓名;
        this.setQuoteVehicleVal('ownerCertificateType', null); //证件类型
        this.setQuoteVehicleVal('ownerCertificateNo', null); //车主证件号
        this.setQuoteVehicleVal('ownerMobile', null); //车主手机号
        // 投保人
        this.setApplicantInfo(policyData);
        //被保人
        this.setInsurantInfo(policyData);
      } else {
        // console.log('三个都不一致')
        // 三个都不一致或者其他一个、两个数据不全的情况，只有被保人完全赋值，其他返回什么赋值什么
        this.setFormVal(this.form.applicant, 'applicantSameAsOwner', false); //同车主
        this.setFormVal(this.form.insurant, 'sameAsOwner', false); //同车主
        this.setFormVal(this.form.insurant, 'sameAsInsurer', false); //同投保人 
        // 车主
        this.setQuoteVehicleVal('ownerName', policyData.ownerName);
        this.setQuoteVehicleVal('ownerCertificateType', null); //证件类型
        this.setQuoteVehicleVal('ownerCertificateNo', null); //车主证件号
        this.setQuoteVehicleVal('ownerMobile', null); //车主手机号
        // 投保人
        this.setFormVal(this.form.applicant, 'name', policyData.applicantName);
        this.setFormVal(this.form.applicant, 'certificateType', null); //投保人姓名
        this.setFormVal(this.form.applicant, 'certificateNo', null); //投保人姓名
        this.setFormVal(this.form.applicant, 'mobile', null); //投保人姓名
        // 被保人
        this.setInsurantInfo(policyData);
      }
    },
    // 识别结果赋值
    setIdentifyResult: function setIdentifyResult(policyData) {
      // 判断三个名字是否是一致，如果是一致说明车主投保人被保人是同一个人，如果不一致另做判断
      // ownerName  车主
      // applicantName  投保人
      // insurantName  被保人
      if (policyData.ownerName && policyData.insurantName && policyData.applicantName) {
        // 三个一致是同一个人
        if (policyData.ownerName == policyData.insurantName && policyData.ownerName == policyData.applicantName && policyData.applicantName == policyData.insurantName) {
          // console.log('同一个人')
          this.setFormVal(this.form.applicant, 'applicantSameAsOwner', true); //同车主
          this.setFormVal(this.form.insurant, 'sameAsOwner', true); //同车主
          this.setFormVal(this.form.insurant, 'sameAsInsurer', false); //同投保人 
          // 车主
          this.setVehicleValInfo(policyData);
          //投保人
          this.setApplicantInfo(policyData);
          //被保人
          this.setInsurantInfo(policyData);
        } else {
          this.setNotSameOwnerName(policyData);
        }
      } else {
        // console.log('没有车主或者投保人导致三个都不一致')
        this.setNotSameOwnerName(policyData);
      }
    },
    // 自动填充
    autoFillData: function autoFillData() {
      if (this.bzIdentityJson && !this.bsIdentityJson) {
        var bzData = this.bzIdentityJson;
        // this.form.insCorpCode = this.bzIdentityJson.insCorpCode ? this.bzIdentityJson.insCorpCode : ""; //保司
        this.setFormVal(this.form, 'insCorpCode', bzData.insCorpCode); //保司
        this.setFormVal(this.form, 'insuredTime', bzData.insuredTime); //承保日期
        // 车辆信息
        this.setQuoteVehicleVal('plateNo', bzData.plateNo); //车牌号
        if (!bzData.plateNo || bzData.plateNo == '新车' || bzData.plateNo == '未上牌') {
          this.form.quoteVehicle.isNewCar = true;
        }
        this.setQuoteVehicleVal('vin', bzData.vin); //车架号
        this.setQuoteVehicleVal('engineNo', bzData.engineNo); //发动机号
        this.setQuoteVehicleVal('registerDate', bzData.registerDate); //注册日期
        this.setQuoteVehicleVal('modelName', bzData.modelName); //品牌型号
        this.setQuoteVehicleVal('seats', bzData.seats); //座位数
        this.setQuoteVehicleVal('tonNumber', bzData.tonNumber); //核定载质量
        this.setQuoteVehicleVal('wholeWeight', bzData.wholeWeight); //整备质量
        this.setQuoteVehicleVal('power', bzData.power); //功率
        this.setQuoteVehicleVal('displacement', bzData.displacement); //尾气排量

        this.setQuoteVehicleVal('useAttribute', bzData.useAttribute && bzData.useAttribute == 'YING_YE' ? '1' : '2'); //使用性质

        // 车主信息
        this.setIdentifyResult(bzData);
        // 交强险保单信息
        this.setFormVal(this.form, 'bzRiskNo', bzData.policyNo); //交强险保单号
        this.setFormVal(this.form.bzRiskKind, 'effectiveStartTime', bzData.effectiveStartTime); //交强险起保日期
        this.setFormVal(this.form.bzRiskKind, 'effectiveEndTime', bzData.effectiveEndTime); //交强险终保日期
        this.setFormVal(this.form, 'bzPremium', bzData.actualPremium); //交强险金额
        this.setFormVal(this.form.quoteVehicleTax, 'curYearTax', bzData.vehicleTaxPremium); //车船税金额
      } else if (this.bsIdentityJson && !this.bzIdentityJson) {
        var bsData = this.bsIdentityJson;
        this.setFormVal(this.form, 'insCorpCode', bsData.insCorpCode); //保司
        this.setFormVal(this.form, 'insuredTime', bsData.insuredTime); //承保日期
        // 车辆信息
        this.setQuoteVehicleVal('plateNo', bsData.plateNo); //车牌号
        if (!bsData.plateNo || bsData.plateNo == '新车' || bsData.plateNo == '未上牌') {
          this.form.quoteVehicle.isNewCar = true;
        }
        this.setQuoteVehicleVal('vin', bsData.vin); //车架号
        this.setQuoteVehicleVal('engineNo', bsData.engineNo); //发动机号
        this.setQuoteVehicleVal('registerDate', bsData.registerDate); //注册日期
        this.setQuoteVehicleVal('modelName', bsData.modelName); //品牌型号
        this.setQuoteVehicleVal('seats', bsData.seats); //座位数
        this.setQuoteVehicleVal('tonNumber', bsData.tonNumber); //核定载质量
        this.setQuoteVehicleVal('wholeWeight', bsData.wholeWeight); //整备质量
        this.setQuoteVehicleVal('power', bsData.power); //功率
        this.setQuoteVehicleVal('displacement', bsData.displacement); //尾气排量

        this.setQuoteVehicleVal('useAttribute', bsData.useAttribute && bsData.useAttribute == 'YING_YE' ? '1' : '2'); //使用性质

        // 车主信息
        this.setIdentifyResult(bsData);
        // 商业险保单信息
        this.setFormVal(this.form, 'bsRiskNo', bsData.policyNo); //交强险保单号
        this.setFormVal(this.form.bsRiskKind, 'effectiveStartTime', bsData.effectiveStartTime); //交强险起保日期
        this.setFormVal(this.form.bsRiskKind, 'effectiveEndTime', bsData.effectiveEndTime); //交强险终保日期
        this.setFormVal(this.form, 'bsPremium', bsData.actualPremium); //交强险金额
        if (bsData && bsData.riskList && bsData.riskList.length != 0) {
          var quoteDutylist = [];
          bsData.riskList.map(function (item) {
            quoteDutylist.push({
              absoluteDeductible: item.absoluteDeductible,
              amount: item.actualPremium ? item.actualPremium : 0,
              riskkindCode: item.riskkindCode,
              riskkindName: item.riskkindName,
              unitAmount: _.indexOf(['FZ01', 'FZ02', 'FZ03', 'FZ04'], item.riskkindCode) != -1 ? item.quantity : item.unitAmount || 0,
              quantity: item.quantity
            });
          });
          this.form.quoteDutys = quoteDutylist;
        }
      } else if (this.bzIdentityJson && this.bsIdentityJson) {
        if (this.bzIdentityJson.vin != this.bsIdentityJson.vin) {
          this.$message.warning('上传的保单不是同一辆车');
          return;
        } else {
          // 此处：先判断交强险有没有车主信息，在判断商业险有么有，两个都没有的话取空
          var _bzData = this.bzIdentityJson;
          var _bsData = this.bsIdentityJson;
          // 保司信息
          this.setFormVal(this.form, 'insCorpCode', _bzData && _bzData.insCorpCode ? _bzData.insCorpCode : _bsData && _bsData.insCorpCode ? _bsData.insCorpCode : ''); //保司
          this.setFormVal(this.form, 'insuredTime', _bzData && _bzData.insuredTime ? _bzData.insuredTime : _bsData && _bsData.insuredTime ? _bsData.insuredTime : ''); //承保日期

          // 车辆信息
          this.setQuoteVehicleVal('plateNo', _bzData && _bzData.plateNo ? _bzData.plateNo : _bsData && _bsData.plateNo ? _bsData.plateNo : ''); //车牌号
          if (!_bzData.plateNo || _bzData.plateNo == '新车' || _bzData.plateNo == '未上牌') {
            this.form.quoteVehicle.isNewCar = true;
          }
          this.setQuoteVehicleVal('vin', _bzData && _bzData.vin ? _bzData.vin : _bsData && _bsData.vin ? _bsData.vin : ''); //车架号
          this.setQuoteVehicleVal('engineNo', _bzData && _bzData.engineNo ? _bzData.engineNo : _bsData && _bsData.engineNo ? _bsData.engineNo : ''); //发动机号
          //识别出来的注册日期不一致时，注册日期置空，需用户自主确认
          if (_bzData.registerDate != _bsData.registerDate) {
            this.setQuoteVehicleVal('registerDate', null); //注册日期
          } else {
            this.setQuoteVehicleVal('registerDate', _bzData.registerDate); //注册日期
          }

          this.setQuoteVehicleVal('modelName', _bzData ? _bzData.modelName : _bsData && _bsData.modelName ? _bsData.modelName : ''); //品牌型号
          this.setQuoteVehicleVal('seats', _bzData && _bzData.seats ? _bzData.seats : _bsData && _bsData.seats ? _bsData.seats : ''); //座位数
          this.setQuoteVehicleVal('tonNumber', _bzData && _bzData.tonNumber ? _bzData.tonNumber : _bsData && _bsData.tonNumber ? _bsData.tonNumber : ''); //尾气排量
          this.setQuoteVehicleVal('wholeWeight', _bzData ? _bzData.wholeWeight : _bsData ? _bsData.wholeWeight : ''); //整备质量
          this.setQuoteVehicleVal('power', _bzData && _bzData.power ? _bzData.power : _bsData && _bsData.power ? _bsData.power : ''); //功率
          this.setQuoteVehicleVal('displacement', _bzData && _bzData.displacement ? _bzData.displacement : _bsData && _bsData.displacement ? _bsData.displacement : ''); //尾气排量

          var _userAttribute = _bzData && _bzData.useAttribute ? _bzData.useAttribute : _bsData && _bsData.useAttribute ? _bsData.useAttribute : '';
          this.setQuoteVehicleVal('useAttribute', _userAttribute && _userAttribute == 'YING_YE' ? '1' : '2'); //使用性质

          // 车主信息 (交商都有取商业，只有一个有什么取什么)
          // 如果商业险没有证件号码（insurantCertificateNo）有可能是部分保司部分地区商业险确实没有被保人的证件号码之类的信息只有一个名字，所以需要取交强险的被保人信息
          if (_bzData && _bsData) {
            // 商业险没证件号，但是交强险有，取交强险
            if (!_bsData.insurantCertificateNo && _bzData.insurantCertificateNo) {
              this.setIdentifyResult(_bzData);
            }
            // 商业险有，但是交强险没有
            if (_bsData.insurantCertificateNo && !_bzData.insurantCertificateNo) {
              this.setIdentifyResult(_bsData);
            }
            // 商业险有,交强险也有，以商业险为准
            if (_bsData.insurantCertificateNo && _bzData.insurantCertificateNo) {
              this.setIdentifyResult(_bsData);
            }
          } else {
            if (_bzData) {
              this.setIdentifyResult(_bzData);
            }
            if (_bsData) {
              this.setIdentifyResult(_bsData);
            }
          }
          // this.setQuoteVehicleVal('ownerName', bzData && bzData.ownerName ? bzData.ownerName : bsData && bsData.ownerName ? bsData.ownerName : '') //车主姓名
          // this.setQuoteVehicleVal('ownerCertificateType', bzData && bzData.insurantCertificateType ? bzData.insurantCertificateType : bsData && bsData.insurantCertificateType ? bsData.insurantCertificateType : '') //证件类型
          // this.setQuoteVehicleVal('ownerCertificateNo', bzData && bzData.insurantCertificateNo ? bzData.insurantCertificateNo : bsData && bsData.insurantCertificateNo ? bsData.insurantCertificateNo : '') //车主证件号
          // this.setQuoteVehicleVal('ownerMobile', bzData && bzData.insurantMobile ? bzData.insurantMobile : bsData && bsData.insurantMobile ? bsData.insurantMobile : '') //车主手机号
          // 交强险保单信息
          this.setFormVal(this.form, 'bzRiskNo', _bzData.policyNo); //交强险保单号
          this.setFormVal(this.form.bzRiskKind, 'effectiveStartTime', _bzData.effectiveStartTime); //交强险起保日期
          this.setFormVal(this.form.bzRiskKind, 'effectiveEndTime', _bzData.effectiveEndTime); //交强险终保日期
          this.setFormVal(this.form, 'bzPremium', _bzData.actualPremium); //交强险金额
          this.setFormVal(this.form.quoteVehicleTax, 'curYearTax', _bzData.vehicleTaxPremium); //车船税金额
          // 商业险保单信息
          this.setFormVal(this.form, 'bsRiskNo', _bsData.policyNo); //商业险保单号
          this.setFormVal(this.form.bsRiskKind, 'effectiveStartTime', _bsData.effectiveStartTime); //商业险起保日期
          this.setFormVal(this.form.bsRiskKind, 'effectiveEndTime', _bsData.effectiveEndTime); //商业险终保日期
          this.setFormVal(this.form, 'bsPremium', _bsData.actualPremium); //商业险金额
          if (_bsData && _bsData.riskList && _bsData.riskList.length != 0) {
            var _quoteDutylist = [];
            _bsData.riskList.map(function (item) {
              _quoteDutylist.push({
                absoluteDeductible: item.absoluteDeductible,
                amount: item.actualPremium ? item.actualPremium : 0,
                riskkindCode: item.riskkindCode,
                riskkindName: item.riskkindName,
                unitAmount: _.indexOf(['FZ01', 'FZ02', 'FZ03', 'FZ04'], item.riskkindCode) != -1 ? item.quantity : item.unitAmount || 0,
                quantity: item.quantity
              });
            });
            this.form.quoteDutys = _quoteDutylist;
          }
        }
      } else {
        this.$message.warning('未能识别到保单');
        return;
      }
      this.policyIdentityDialog = false;
    },
    // 监听厂牌型号数据变化
    CarHandleChange: function CarHandleChange(val) {
      this.quoteVehicle = {
        cjVhcBrandNo: val[0],
        // 厂家品牌编号
        cjVhcSeriesNo: val[1] //   厂家车系编号
        // cjVhcModelNo: val[2], //  厂家车型编号
      };

      this.carTypeVal = val;
    },
    onInput: function onInput(num, min, max, limit) {
      this.$forceUpdate();
      //对输入内容做精度校验
      if (min && num < min) {
        num = min;
      }
      if (max && num > max) {
        num = max;
      }
      return limitInput(num, limit);
    },
    oemGetUsers: function oemGetUsers(val) {
      this.form.salemanBy = '';
      this.form.salemanName = '';
      this.form.insCityCode = '';
      this.form.insCityName = '';
      this.policyCityItems = [];
      this.salesman = val;
    },
    endble: function endble() {
      var _this9 = this;
      setTimeout(function () {
        _this9.isDisable = false;
      }, 1000);
    },
    // 获取续保专员列表(组织机构)
    fetchUsers: function fetchUsers() {
      var _this10 = this;
      var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var data = {
        employeeStatus: 'REGULAR',
        employeeType: 'SALESMAN'
      };
      leadManagementApi.dealerEmployees(data).then(function (res) {
        if (res.code === 200 && res.data) {
          _this10.salesman = res.data;
          _this10.salesman.forEach(function (item) {
            if (item.userId == _this10.userInfo.userId) {
              _this10.form.salemanBy = item.userId;
              _this10.form.salemanName = item.userName;
            }
          });
          // 设置默认值
          _this10.salemanNameChange(_this10.form.salemanBy);
        }
      });
    },
    // 获取客户经理列表(经销商、主机厂)
    fetchEndUsers: function fetchEndUsers(type, code) {
      var _this11 = this;
      var rerequest = type === 'DEALER' ? customersRequest.getDealerCustomerList(code) : customersRequest.getOemCustomerList(code);
      rerequest.then(function (res) {
        if (res.code === 200 && res.data) {
          _this11.salesman = res.data;
          _this11.salesman.forEach(function (item) {
            if (item.userId == _this11.userInfo.userId) {
              _this11.form.salemanBy = item.userId;
              _this11.form.salemanName = item.userName;
            }
          });
          // 设置默认值
          _this11.salemanNameChange(_this11.form.salemanBy);
        }
      });
    },
    initData: function initData() {
      var _this12 = this;
      this.getAllDuty();
      this.bizType = localStorage.getItem('bizType');
      // 设置出单方式默认值
      if (this.$route.query.riskTypes) {
        this.$set(this.form, 'insuredType', this.$route.query.riskTypes.toString());
      } else {
        if (this.dictSource['ORDER']) {
          this.form.insuredType = this.dictSource['ORDER'][0].dictCode;
          this.showInsuranceType = this.form.insuredType;
        }
      }
      var partialAddress = [];
      this.regionData.forEach(function (item, index) {
        partialAddress.push({
          label: item.label,
          value: item.value,
          children: item.children ? [] : null
        });
        if (item.children) {
          item.children.forEach(function (eitem) {
            partialAddress[index].children.push({
              label: eitem.label == '市辖区' ? item.label : eitem.label,
              value: eitem.value
            });
          });
        }
      });
      this.regionOptions = partialAddress;

      // 保司
      this.supplyers = getSupplyers();
      this.supplyers = _.orderBy(this.supplyers, ['sortBy'], ['desc']);
      if (this.supplyers) {
        _.remove(this.supplyers, function (o) {
          return o.code == 'QITA' || o.code == 'qita';
        });
      }
      this.VEHICLE_KINDS = this.VEHICLE_KIND = JSON.parse(localStorage.getItem("VI_VEHICLE_KIND")) || [];
      this.PLATE_TYPE = JSON.parse(localStorage.getItem("VI_PLATE_TYPE")) || [];
      var engrgy = JSON.parse(localStorage.getItem("VI_ENGRGY_TYPE")) || [];
      this.ENGRGY_TYPES = this.ENGRGY_TYPE = _.filter(engrgy, function (o) {
        return ["0", "1", "2", "3", "4"].includes(o.dictCode);
      });
      this.USE_ATTRIBUTE = JSON.parse(localStorage.getItem("VI_USE_ATTRIBUTE")) || [];
      this.VI_CERTIFICATE_TYPE = JSON.parse(localStorage.getItem('VI_CERTIFICATE_TYPE')) || [];
      if (this.bizType === "DEALER") {
        this.fetchUsers();
      }
      this.initUserInfo();
      if (this.riskType) {
        this.form.insuredType = this.showInsuranceType = this.riskType == '0' ? '1' : this.riskType == '1' ? '0' : '2';
      }
      if (this.policyId) {
        this.viewDetail(this.policyId);
      } else {
        if (sessionStorage.getItem("policyForm")) {
          //读取上次填写缓存
          this.form = JSON.parse(sessionStorage.getItem("policyForm"));
          if (this.bizType === "OEM") {
            this.orgForm = {
              salesOrgCode: '',
              salesOrgName: ''
            };
            if (!this.form.salesOrgCode) return;
            requestOrg.getOrgPerson(this.form.salesOrgCode).then(function (result) {
              _this12.salesman = result.data || [];
              _this12.salemanNameChange(_this12.form.salemanBy);
            });
          } else {
            this.orgForm = {
              salesOrgCode: this.form.salesOrgCode,
              salesOrgName: this.form.salesOrgName
            };
          }
          this.showInsuranceType = this.form.insuredType ? this.form.insuredType : null;
        }
        //获取当前登录人员所在机构的城市
        if (this.bizType === 'DEALER') {
          var userInfo = JSON.parse(localStorage.getItem('userInfo')) || {};
          if (userInfo && userInfo.data && userInfo.data.organization) {
            this.form.insCityCode = userInfo.data.organization.cityCode;
            this.form.insCityName = userInfo.data.organization.cityName;
            if (this.form.insCityCode) {
              var temp = {};
              this.regionData.map(function (item) {
                if (item.children) item.children.map(function (eitem) {
                  if (eitem.value == _this12.form.insCityCode) temp = item;
                });
              });
              this.form.address = [temp ? temp.value : '', this.form.insCityCode];
            }
          }
        }
      }
      if (this.$route.query.riskTypes) {
        this.form.insuredType = this.$route.query.riskTypes.toString();
        this.showInsuranceType = this.form.insuredType ? this.form.insuredType : null;
      }
      ;
      this.$forceUpdate();
    },
    // 获取保单详情
    viewDetail: function viewDetail(id) {
      var _this13 = this;
      this.formLoading = true;
      policyRequest.getDetail(id).then(function (res) {
        _this13.formLoading = false;
        if (res.data) {
          var resData = res.data;
          if (resData.policyVehicle.cjVhcBrandNo && resData.policyVehicle.cjVhcSeriesNo) {
            _this13.carTypeVal = [resData.policyVehicle.cjVhcBrandNo, resData.policyVehicle.cjVhcSeriesNo];
          } else {
            _this13.carTypeVal = [];
          }
          if (!_.isEmpty(resData.policyDuties) && _.toUpper(resData.source) == 'SYNC') {
            resData.policyDuties.forEach(function (item) {
              item.unitAmount = _.indexOf(['FZ01', 'FZ02', 'FZ03', 'FZ04'], item.riskkindCode) != -1 ? item.quantity : item.unitAmount || 0;
            });
          }
          _this13.form = {
            riskNo: resData.relationPolicyNo,
            policyId: resData.policyId,
            bsRiskNo: resData.riskType == '1' || resData.riskType == '2' ? resData.policyNo : null,
            fcBsRiskNo: resData.riskType == '2' || resData.riskType == '3' ? resData.policyNo : null,
            bsPremium: resData.riskType == '1' || resData.riskType == '2' ? resData.actualPremium : 0,
            fcPremium: resData.riskType == '1' || resData.riskType == '2' || resData.riskType == '3' ? resData.actualPremium : 0,
            bsDiscountFactor: resData.riskType == '1' ? resData.discountFactor : null,
            bzRiskNo: resData.riskType == '0' || resData.riskType == '2' ? resData.policyNo : null,
            bzPremium: resData.riskType == '0' || resData.riskType == '2' ? resData.actualPremium : null,
            bzDiscountFactor: resData.riskType == '0' ? resData.discountFactor : null,
            insCityCode: resData.insCityCode,
            insCityName: resData.insCityName,
            insCorpCode: resData.insCorpCode,
            insCorpName: resData.insCorpName,
            insuredTime: resData.insuredTime,
            insuredType: resData.riskType == '1' ? '0' : resData.riskType == '0' ? '1' : '2',
            address: [],
            totalPremium: resData.actualPremium,
            renewalType: resData.renewalType || null,
            internetStamp: resData.internetStamp,
            agricultureStamp: resData.agricultureStamp,
            matchPolicyStatus: resData.matchPolicyStatus,
            quoteDutys: resData.policyDuties,
            fcQuoteDutys: resData.policyDuties,
            noCarInsurance: [],
            uploadFiles: [],
            salesOrgName: resData.salesOrgName,
            salesOrgCode: resData.salesOrgCode,
            salesOrgId: resData.salesOrgId,
            salesOrgType: resData.salesOrgType || 'DEALER',
            salemanBy: resData.salemanBy,
            salemanName: resData.salemanName,
            ownerName: resData.policyVehicle.ownerName,
            quoteVehicle: resData.policyVehicle,
            quoteInsureds: resData.policyInsureds,
            //报价关系人信息
            quoteProducts: [],
            //报价险种信息
            quoteExpand: [],
            //报价单增值服务信息
            quoteVehicleTax: resData.policyVehicleTax || {
              curYearTax: 0,
              lastYearPay: 0,
              delayPayMoney: 0
            },
            //交强险-车船税信息
            bsRiskKind: {
              effectiveStartTime: resData.riskType == '1' || resData.riskType == '2' ? resData.effectiveStartTime : null,
              effectiveEndTime: resData.riskType == '1' || resData.riskType == '2' ? resData.effectiveEndTime : null,
              poundageRate: resData.riskType == '1' || resData.riskType == '2' ? resData.poundageRate || 0 : 0
            },
            //商业险
            bzRiskKind: {
              effectiveStartTime: resData.riskType == '0' || resData.riskType == '2' ? resData.effectiveStartTime : null,
              effectiveEndTime: resData.riskType == '0' || resData.riskType == '2' ? resData.effectiveEndTime : null,
              poundageRate: resData.riskType == '0' || resData.riskType == '2' ? resData.poundageRate || 0 : 0
            },
            fcRiskKind: {
              effectiveStartTime: resData.riskType == '0' || resData.riskType == '2' ? resData.effectiveStartTime : null,
              effectiveEndTime: resData.riskType == '0' || resData.riskType == '2' ? resData.effectiveEndTime : null,
              poundageRate: resData.riskType == '0' || resData.riskType == '2' ? resData.poundageRate || 0 : 0
            },
            applicant: _.find(resData.policyInsureds, function (item) {
              return item.relationshipFlag == 'Applicant' || item.relationshipFlag == 'applicant';
            }) || {
              relationshipFlag: "Applicant",
              applicantSameAsOwner: true //被保人同车主标识
            },

            //投保人
            insurant: _.find(resData.policyInsureds, function (item) {
              return item.relationshipFlag == 'Insurant' || item.relationshipFlag == 'insurant';
            }) || {
              relationshipFlag: "Insurant",
              sameAsOwner: true,
              //被保人同车主标识
              sameAsInsurer: false //被保人同投保人标识
            },

            //被保人
            source: resData.source,
            sourceName: resData.sourceName,
            policyDocumentsBs: _this13.form.insuredType == '0' ? resData.policyDocuments : [],
            policyDocumentsBz: _this13.form.insuredType == '1' ? resData.policyDocuments : [],
            policyDocumentsAccident: _this13.form.insuredType == '2' ? resData.policyDocuments : []
          };
          _this13.saveTmpStartTime = resData.effectiveStartTime;
          _this13.saveTmpEndTime = resData.effectiveEndTime;
          _this13.showInsuranceType = resData.riskType == '0' ? '1' : resData.riskType == '1' ? '0' : '2';
          if (!_.isEmpty(_this13.form.quoteInsureds)) {
            _this13.form.quoteInsureds.forEach(function (item) {
              item.quoteInsuredId = item.policyInsuredId;
            });
          }
          _this13.form.quoteVehicle.quoteVehicleId = _this13.form.quoteVehicle.policyVehicleId;
          _this13.form.quoteVehicleTax.quoteVehicleTaxId = _this13.form.quoteVehicleTax.policyVehicleTaxId;
          _this13.form.quoteVehicle.isNewCar = !resData.policyVehicle.plateNo || resData.policyVehicle.plateNo == '未上牌' || resData.policyVehicle.plateNo == '新车';
          if (_this13.form && _this13.form.salesOrgCode) {
            _this13.orgForm = {
              salesOrgCode: _this13.form.salesOrgCode,
              salesOrgName: _this13.form.salesOrgName
            };
            _this13.fetchUsers();
          }
          if (_this13.form.applicant && _this13.form.applicant.name == _this13.form.quoteVehicle.ownerName && _this13.form.applicant.certificateNo == _this13.form.quoteVehicle.ownerCertificateNo) {
            _this13.form.applicant.applicantSameAsOwner = true;
          }
          if (_this13.form.insurant && _this13.form.insurant.name == _this13.form.quoteVehicle.ownerName && _this13.form.insurant.certificateNo == _this13.form.quoteVehicle.ownerCertificateNo) {
            _this13.form.insurant.sameAsOwner = true;
          } else {
            if (_this13.form.insurant && _this13.form.insurant.name == _this13.form.applicant.name && _this13.form.insurant.certificateNo == _this13.form.applicant.certificateNo) {
              _this13.form.insurant.sameAsInsurer = true;
            }
          }
          if (_this13.form.insCityCode) {
            var _code = _this13.form.insCityCode;
            var temp = {};
            _this13.regionData.map(function (item) {
              if (item.children) item.children.map(function (eitem) {
                if (eitem.value == _code) temp = item;
              });
            });
            _this13.form.address = [temp ? temp.value : '', _code];
          }
          if (!_.isEmpty(_this13.form.quoteDutys)) {
            _this13.form.quoteDutys.forEach(function (item) {
              item.amount = item.actualPremium;
            });
          }
          if (!_.isEmpty(_this13.form.fcQuoteDutys)) {
            _this13.form.fcQuoteDutys.forEach(function (item) {
              item.amount = item.actualPremium;
            });
          }
          _this13.$forceUpdate();
        }
      }).catch(function (err) {
        _this13.formLoading = false;
      });
    },
    initUserInfo: function initUserInfo() {
      if (localStorage.getItem("userInfo")) {
        this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
        this.userName = this.userInfo.user.userName;
      }
      var _getUserInfo = getUserOrgInfoDealerOem(this.bizType);
      this.form = Object.assign(this.form, _getUserInfo);
    },
    // 保险期间日期设置 --- 交强
    bzDatePickerChange: function bzDatePickerChange(form) {
      var formData = _.cloneDeep(form);
      //交强险终保日期
      var _effectiveEndTime = getNextYear(formData.bzRiskKind.effectiveStartTime);
      formData.bzRiskKind.effectiveEndTime = getBeforeOfDate(_effectiveEndTime, 1);
      this.form = formData;
    },
    // 保险期间日期设置 --- 商业
    bsDatePickerChange: function bsDatePickerChange(form) {
      var formData = _.cloneDeep(form);
      //商业险终保日期
      var _effectiveEndTime = getNextYear(formData.bsRiskKind.effectiveStartTime);
      formData.bsRiskKind.effectiveEndTime = getBeforeOfDate(_effectiveEndTime, 1);
      this.form = formData;
    },
    // 保险期间日期设置 --- 非车
    fcDatePickerChange: function fcDatePickerChange(form) {
      var formData = _.cloneDeep(form);
      //商业险终保日期
      var _effectiveEndTime = getNextYear(formData.fcRiskKind.effectiveStartTime);
      formData.fcRiskKind.effectiveEndTime = getBeforeOfDate(_effectiveEndTime, 1);
      this.form = formData;
    },
    // 发动机号自动转大写
    inputEngineNo: function inputEngineNo(val) {
      val = val.replace(/[^A-Za-z0-9-]/g, '');
      this.form.quoteVehicle.engineNo = _.toUpper(val);
    },
    // 自动将业务单号转为大写
    checkBzRiskNo: function checkBzRiskNo(val) {
      this.form.bzRiskNo = character(_.toUpper(val));
    },
    // 自动将业务单号转为大写
    checkPolicyNo: function checkPolicyNo(val) {
      this.form.policyNo = character(_.toUpper(val));
    },
    // 自动将业务单号转为大写
    checkBsRiskNo: function checkBsRiskNo(val) {
      this.form.bsRiskNo = character(_.toUpper(val));
    },
    // 自动将业务单号转为大写
    fcCheckBsRiskNo: function fcCheckBsRiskNo(val) {
      this.form.fcBsRiskNo = character(_.toUpper(val));
    },
    // 自动将业务单号转为大写
    checkRiskNo: function checkRiskNo(val) {
      this.form.riskNo = character(_.toUpper(val));
    },
    initForm: function initForm() {
      this.form = {
        bsRiskNo: "",
        fcBsRiskNo: "",
        bzRiskNo: "",
        bsDiscountFactor: "",
        bsPremium: 0,
        fcPremium: 0,
        bzDiscountFactor: "",
        bzPremium: null,
        insCityCode: "",
        insCorpCode: "",
        insCorpName: "",
        insuredTime: '',
        insuredType: "",
        address: [],
        insCityName: "",
        //投保地区
        totalPremium: 0,
        quoteDutys: [{
          absoluteDeductible: 0,
          riskkindName: '',
          riskkindCode: 'A',
          unitAmount: 0,
          amount: 0
        }, {
          absoluteDeductible: 0,
          riskkindName: '',
          riskkindCode: 'B',
          unitAmount: 0,
          amount: 0
        }, {
          absoluteDeductible: 0,
          riskkindName: '',
          riskkindCode: 'C',
          unitAmount: 0,
          amount: 0
        }, {
          absoluteDeductible: 0,
          riskkindName: '',
          riskkindCode: 'D',
          unitAmount: 0,
          amount: 0
        }],
        fcQuoteDutys: [{
          absoluteDeductible: 0,
          riskkindName: '',
          riskkindCode: '',
          unitAmount: 0,
          amount: 0
        }],
        noCarInsurance: [],
        uploadFiles: [],
        salesOrgName: "",
        salesOrgCode: "",
        salesOrgType: "DEALER",
        salesOrgId: "",
        salemanBy: "",
        salemanName: "",
        ownerName: "",
        quoteVehicle: {
          plateNo: '',
          vin: '',
          engineNo: '',
          registerDate: '',
          modelName: '',
          seats: '',
          displacement: '',
          tonNumber: '',
          wholeWeight: '',
          power: '',
          ownerName: '',
          ownerCertificateType: '',
          ownerCertificateNo: '',
          ownerMobile: ''
        },
        quoteInsureds: [],
        //报价关系人信息
        quoteProducts: [],
        //报价险种信息
        quoteExpand: [],
        //报价单增值服务信息
        quoteVehicleTax: {
          curYearTax: 0,
          lastYearPay: 0,
          delayPayMoney: 0
        },
        //交强险-车船税信息
        bsRiskKind: {
          effectiveStartTime: '',
          effectiveEndTime: '',
          poundageRate: 0
        },
        //商业险
        bzRiskKind: {
          effectiveStartTime: '',
          effectiveEndTime: '',
          poundageRate: 0
        },
        //交强险
        fcRiskKind: {
          effectiveStartTime: '',
          effectiveEndTime: '',
          poundageRate: 0
        },
        //非车险
        applicant: {
          relationshipFlag: "Applicant",
          applicantSameAsOwner: true //投保人同车主标识
        },

        //投保人
        insurant: {
          relationshipFlag: "Insurant",
          sameAsOwner: true,
          //被保人同车主标识
          sameAsInsurer: false //被保人同投保人标识
        },

        //被保人
        policyDocumentsBs: [],
        // 商业险电子保单
        policyDocumentsBz: [],
        // 交强险电子保单
        policyDocumentsAccident: [],
        // 非车险电子保单
        renewalType: null,
        internetStamp: false,
        agricultureStamp: false,
        matchPolicyStatus: false
      };
      this.form.fcQuoteDutys = [];
      this.orgForm = {
        salesOrgCode: '',
        salesOrgName: ''
      };
      if (this.dictSource['ORDER']) {
        this.form.insuredType = this.dictSource['ORDER'][0].dictCode;
        this.showInsuranceType = this.form.insuredType;
      }
    },
    // 去除车架号中的i、o、q
    checkValue: function checkValue(val) {
      if (val) {
        val = val.replace(/i/g, '').replace(/I/g, '').replace(/o/g, '').replace(/O/g, '').replace(/q/g, '').replace(/Q/g, '');
      }
      this.carTypeVal = [];
      this.quoteVehicle.cjVhcBrandNo = null;
      this.quoteVehicle.cjVhcSeriesNo = null;
      this.form.quoteVehicle.vin = character(_.toUpper(val));
    },
    vincheck: function vincheck() {
      var vinNumber = /^\d{17}$/;
      var vinLetter = /^[A-Z]{17}$/;
      if (vinNumber.test(this.form.quoteVehicle.vin) || vinLetter.test(this.form.quoteVehicle.vin)) {
        this.$message({
          type: 'warning',
          message: '车架号不能纯数字或纯字母，请修改！'
        });
        this.$forceUpdate();
        return false;
      }
      ;
      return true;
    },
    certificateLimitInput: function certificateLimitInput(value, name) {
      this.form.quoteVehicle[name] = ('' + value).replace(/[^\a-\z\A-\Z0-9]/g, '');
    },
    // 判断证件类型
    certificateNoChange: function certificateNoChange() {
      if (this.form.quoteVehicle.ownerCertificateType == 'P01' && this.form.quoteVehicle.ownerCertificateNo) {
        var certificateNo = Rules['ID_CARD'].value;
        var flag = certificateNo.test(this.form.quoteVehicle.ownerCertificateNo);
        var isValidate = idCardValidate(this.form.quoteVehicle.ownerCertificateNo);
        if (!flag || !isValidate) {
          this.$message({
            type: 'warning',
            message: '身份证号码不满足校验规则，请修改！'
          });
          this.$forceUpdate();
          return false;
        }
      }
      return true;
    },
    // 被保人
    applicantCertificateNoChange: function applicantCertificateNoChange() {
      if (this.form.applicant.certificateType == 'P01' && this.form.applicant.certificateNo) {
        var certificateNo = Rules['ID_CARD'].value;
        var flag = certificateNo.test(this.form.applicant.certificateNo);
        var isValidate = idCardValidate(this.form.applicant.certificateNo);
        if (!flag || !isValidate) {
          this.$message({
            type: 'warning',
            message: '身份证号码不满足校验规则，请修改！'
          });
          this.$forceUpdate();
          return false;
        }
      }
      return true;
    },
    // 投保人
    insurantCertificateNoChange: function insurantCertificateNoChange() {
      if (this.form.applicant.certificateType == 'P01' && this.form.applicant.certificateNo) {
        var certificateNo = Rules['ID_CARD'].value;
        var flag = certificateNo.test(this.form.applicant.certificateNo);
        var isValidate = idCardValidate(this.form.applicant.certificateNo);
        if (!flag || !isValidate) {
          this.$message({
            type: 'warning',
            message: '身份证号码不满足校验规则，请修改！'
          });
          this.$forceUpdate();
          return false;
        }
      }
      return true;
    },
    // 险种选择
    handleRiskItem: function handleRiskItem(item) {
      if (_.indexOf(['C03', 'B04', 'D02', 'D03'], item.riskkindCode) != -1) {
        item.unitAmount = 0;
      } else {
        item.unitAmount = '';
      }
    },
    // 选择出单方式
    selectInsuranceType: function selectInsuranceType(val) {
      this.$refs['form'].clearValidate();
      this.showInsuranceType = val;
      this.reSetIdentityForm();
      this.form.quoteVehicleTax = {
        curYearTax: 0,
        lastYearPay: 0,
        delayPayMoney: 0
      };
      this.form.bzPremium = 0;
      this.form.bsPremium = 0;
      this.form.fcPremium = 0;
      this.form.totalPremium = 0;
      if (['01', '0', '3'].includes(val)) {
        this.form.quoteDutys = [{
          absoluteDeductible: 0,
          riskkindName: '',
          riskkindCode: 'A',
          unitAmount: 0,
          amount: 0
        }, {
          absoluteDeductible: 0,
          riskkindName: '',
          riskkindCode: 'B',
          unitAmount: 0,
          amount: 0
        }, {
          absoluteDeductible: 0,
          riskkindName: '',
          riskkindCode: 'C',
          unitAmount: 0,
          amount: 0
        }, {
          absoluteDeductible: 0,
          riskkindName: '',
          riskkindCode: 'D',
          unitAmount: 0,
          amount: 0
        }];
      } else {
        this.form.quoteDutys = [];
        this.form.fcQuoteDutys = [];
      }
    },
    // 是否同车主
    handleCheckedCarOwner: function handleCheckedCarOwner(val, type) {
      if (!this.form.quoteVehicle.ownerName) {
        this.form.applicant.applicantSameAsOwner = false;
        this.form.insurant.sameAsOwner = false;
        this.$message({
          type: "warning",
          message: "车主名不能为空"
        });
      } else {
        // 投保人同车主
        if (type == 'applicant') {
          this.form.applicant.applicantSameAsOwner = val;
        }
        // 被投保人同车主
        if (type == 'insurant') {
          this.form.insurant.sameAsOwner = val;
        }
        this.$forceUpdate();
      }
    },
    // 是否同投保人
    handleCheckedApplicant: function handleCheckedApplicant() {
      if (!this.form.applicant.name) {
        this.form.insurant.sameAsInsurer = false;
        this.$message({
          type: "warning",
          message: "投保人姓名不能为空"
        });
      }
    },
    // 厂牌
    brandSeriesByBrand: function brandSeriesByBrand(val) {
      this.brandSeriesList = [];
      this.brandSeriesList.push({
        dictCode: "0",
        dictName: '其他'
      });
    },
    // 厂牌车系
    brandModeByBrandSeries: function brandModeByBrandSeries() {
      this.brandModeList = [];
      this.brandModeList.push({
        dictCode: "0",
        dictName: '其他车型'
      });
    },
    // 选择省/市/区
    handleChange: function handleChange(val) {
      if (!val) return;
      this.form.insCityCode = val[1];
      var address = this.$refs['address'].getCheckedNodes()[0].pathLabels;
      this.form.insCityName = address[1];
      this.form.address = val;
    },
    // 监听选择业务员事件
    salemanNameChange: function salemanNameChange(val) {
      if (val) {
        var _user = _.find(this.salesman, function (item) {
          return item.userId == val;
        });
        if (_user) {
          this.form.salemanBy = _user.userId;
          this.form.salemanName = _user.userName;
        }
      }
    },
    // 投保地区监听事件
    selectCityItem: function selectCityItem(val) {
      this.form.insCityCode = val;
      var cityItem = getCityItem(val);
      this.form.insCityName = cityItem ? cityItem.label : null;
    },
    orgChange: function orgChange() {
      this.salesman = [];
      this.form.salemanName = null;
      this.form.salemanBy = null;
      this.$forceUpdate();
    },
    // 节点点击事件
    handleNodeClick: function handleNodeClick(data, node, target) {
      if (this.bizType === "OEM") {
        this.salesman = [];
        this.form.salemanBy = '';
        this.form.salesOrgCode = data.salesOrgCode;
        this.form.salesOrgName = data.salesOrgName;
        if (data.cityCode) {
          this.form.address = data.cityCode;
        } else {
          this.form.address = "";
        }
      }
      this.form.salesOrgType = data.bizType || this.bizType;
    },
    // 机构树查询
    dataFilter: function dataFilter(val) {
      this.$refs.tree.filter(val);
    },
    // 机构树查询
    filterNode: function filterNode(value, data) {
      if (!value) return true;
      return data.fullName.indexOf(value) !== -1;
    },
    // 业务机构树下拉列表是否可见监听
    visibleChange: function visibleChange(val) {
      if (this.$refs.orgSelect && (this.$refs.orgSelect.query || this.$refs.orgSelect.query == '') && this.$refs.tree && !this.form.salesOrgCode) {
        this.changeTreeNodeStatus(this.$refs.tree.store.root, val);
      }
    },
    // 改变节点的自身expanded状态
    changeTreeNodeStatus: function changeTreeNodeStatus(node, expandAll) {
      node.expanded = expandAll;
      for (var i = 0; i < node.childNodes.length; i++) {
        // 改变节点的自身expanded状态
        node.childNodes[i].expanded = expandAll;
      }
    },
    // 组织机构树焦点监听
    orgFocus: function orgFocus(val) {
      if (this.$refs.orgSelect && (this.$refs.orgSelect.query || this.$refs.orgSelect.query == '')) {
        this.$refs.tree.filter('');
        this.$forceUpdate();
      }
    },
    // 未上牌勾选事件
    noPlateNoChange: function noPlateNoChange(val) {
      if (val) {
        this.form.quoteVehicle.plateNo = '';
      }
      this.$forceUpdate();
    },
    // 刷新视图
    changeValue: function changeValue(val) {
      this.$forceUpdate();
    },
    // 车牌号自动转大写
    inputPlateNo: function inputPlateNo(val) {
      if (!this.form.quoteVehicle.isNewCar) {
        this.form.quoteVehicle.plateNo = _.toUpper(val);
      }
    },
    addInsurance: function addInsurance(type) {
      if (type === 'one') {
        this.form.quoteDutys.push({
          absoluteDeductible: 0,
          riskkindName: '',
          riskkindCode: '',
          unitAmount: 0,
          amount: 0
        });
      } else {
        this.form.noCarInsurance.push({
          productName: '',
          productCode: '',
          policyNo: '',
          amount: 0,
          unitAmount: 0,
          premium: 0
        });
      }
    },
    FCaddInsurance: function FCaddInsurance(type) {
      if (type == 'FC') {
        this.form.fcQuoteDutys.push({
          absoluteDeductible: 0,
          riskkindName: '',
          riskkindCode: '',
          unitAmount: 0,
          amount: 0
        });
      }
    },
    deleteInsurance: function deleteInsurance(type, i, fileArr) {
      if (type === 'one') {
        this.form.quoteDutys.splice(i, 1);
      } else if (type === 'two') {
        this.form.noCarInsurance.splice(i, 1);
      } else {
        this.form[fileArr].splice(i, 1);
      }
    },
    fcDeleteInsurance: function fcDeleteInsurance(type, i) {
      if (type === 'FC') {
        this.form.fcQuoteDutys.splice(i, 1);
      }
    },
    // 上传电子保单
    uploadFile: function uploadFile(param, type) {
      var _this14 = this;
      var formData = new FormData();
      formData.append('file', param.file);
      storageRequest.uploadFile(formData).then(function (res) {
        if (res.code === 200) {
          _this14.$message.success(res.msg);
        }
        var _fileName = param.file.name;
        var fileNameLen = _fileName.length;
        var suffix = _fileName.substring(_fileName.lastIndexOf('.'));
        if (fileNameLen > 40) {
          _fileName = _fileName.substring(0, 40 - suffix.length) + suffix;
        }
        var obj = {
          fileName: _fileName,
          fileStorageId: res.data.fileStorageId.toString(),
          fileType: '01',
          fileTypeName: '电子保单',
          fileMediaType: '01',
          fileMediaTypeName: '电子保单'
        };
        _this14.form[type].push(obj);
      });
    },
    // 下载电子保单
    handle: function handle(row) {
      if (row.fileStorageId) {
        if (row.fileUrl) downFile(row.fileUrl, row.fileName);
      }
    },
    // 判断文件类型
    beforeUpload: function beforeUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      var arr = ['pdf'];
      if (!arr.includes(testmsg)) {
        this.$message.warning("目前支持的类型有：[.pdf]");
        return false;
      }
    },
    cancel: function cancel() {
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.go(-1);
    },
    // 保存
    saveClicked: function saveClicked() {
      var _this15 = this;
      // console.log('this.form', this.form);
      if (this.bzIdentityData && this.bzIdentityData.vin && this.bsIdentityData && this.bsIdentityData.vin) {
        if (this.bzIdentityData.vin != this.bsIdentityData.vin) {
          this.$message.warning('上传的保单不是同一辆车');
          return;
        }
      }
      // return
      if (!this.certificateNoChange()) return; //车主信息证件号判断
      if (!this.applicantCertificateNoChange()) return; //投保人信息证件号判断
      if (!this.insurantCertificateNoChange()) return; //被保人信息证件号判断
      if (!this.vincheck()) return;
      this.$refs['form'].validate(function (valid, obj) {
        if (valid) {
          _this15.isDisable = true;
          _this15.endble();
          _this15.form.quoteInsureds = [];
          var ownerInfo = {
            //车主信息
            name: _this15.form.quoteVehicle.ownerName,
            certificateType: _this15.form.quoteVehicle.ownerCertificateType,
            certificateNo: _this15.form.quoteVehicle.ownerCertificateNo,
            mobile: _this15.form.quoteVehicle.ownerMobile
          };
          if (_this15.form.applicant.applicantSameAsOwner) {
            //投保人同车主
            _this15.form.quoteInsureds.push(_.assign(_this15.form.applicant, ownerInfo));
          } else {
            _this15.form.quoteInsureds.push(_this15.form.applicant);
          }
          if (_this15.form.insurant.sameAsOwner) {
            //被保人同车主
            _this15.form.quoteInsureds.push(_.assign(_this15.form.insurant, ownerInfo));
          }
          if (_this15.form.insurant.sameAsInsurer) {
            //被保人同投保人
            var applicant = _.cloneDeep(_this15.form.applicant);
            var _obj = _.assign(applicant, _this15.form.insurant);
            _this15.form.quoteInsureds.push(_.omit(_obj, ["applicantSameAsOwner"]));
          }
          if (!_this15.form.insurant.sameAsInsurer && !_this15.form.insurant.sameAsOwner) {
            _this15.form.quoteInsureds.push(_this15.form.insurant);
          }
          _this15.form.quoteVehicle.familyName = _this15.form.quoteVehicle.brand + _this15.form.quoteVehicle.brandSeries; //品牌车系
          if (_this15.form.quoteVehicle.registerDate) {
            _this15.form.quoteVehicle.registerDate = _this15.form.quoteVehicle.registerDate.split(" ")[0] + " 00:00:00";
          }
          if (_this15.form.bsRiskKind.effectiveStartTime) {
            //商业开始时间
            _this15.form.bsRiskKind.effectiveStartTime = _this15.saveTmpStartTime == _this15.form.bsRiskKind.effectiveStartTime ? _this15.saveTmpStartTime : _this15.form.bsRiskKind.effectiveStartTime.split(" ")[0] + " 00:00:00";
          }
          if (_this15.form.bsRiskKind.effectiveEndTime) {
            //商业 结束时间
            _this15.form.bsRiskKind.effectiveEndTime = _this15.saveTmpEndTime == _this15.form.bsRiskKind.effectiveEndTime ? _this15.saveTmpEndTime : _this15.form.bsRiskKind.effectiveEndTime.split(" ")[0] + " 23:59:59";
          }
          if (_this15.form.bzRiskKind.effectiveStartTime) {
            //交强 开始时间
            _this15.form.bzRiskKind.effectiveStartTime = _this15.saveTmpStartTime == _this15.form.bzRiskKind.effectiveStartTime ? _this15.saveTmpStartTime : _this15.form.bzRiskKind.effectiveStartTime.split(" ")[0] + " 00:00:00";
          }
          if (_this15.form.bzRiskKind.effectiveEndTime) {
            //交强 结束时间
            _this15.form.bzRiskKind.effectiveEndTime = _this15.saveTmpEndTime == _this15.form.bzRiskKind.effectiveEndTime ? _this15.saveTmpEndTime : _this15.form.bzRiskKind.effectiveEndTime.split(" ")[0] + " 23:59:59";
          }
          if (_this15.form.fcRiskKind.effectiveStartTime) {
            //非车 开始时间
            _this15.form.fcRiskKind.effectiveStartTime = _this15.saveTmpStartTime == _this15.form.fcRiskKind.effectiveStartTime ? _this15.saveTmpStartTime : _this15.form.fcRiskKind.effectiveStartTime.split(" ")[0] + " 00:00:00";
          }
          if (_this15.form.fcRiskKind.effectiveEndTime) {
            //非车 结束时间
            _this15.form.fcRiskKind.effectiveEndTime = _this15.saveTmpEndTime == _this15.form.fcRiskKind.effectiveEndTime ? _this15.saveTmpEndTime : _this15.form.fcRiskKind.effectiveEndTime.split(" ")[0] + " 23:59:59";
          }
          _this15.form.quoteProducts = [];
          if (_this15.showInsuranceType !== '0' && _this15.showInsuranceType != '2') {
            //出单方式 01 交商同出 0 单出商业险 1 单出交强险

            _this15.form.quoteProducts.push({
              riskType: 0,
              //险种（险种）类型（0：交强险，1：商业险）
              riskTypeName: "交强险",
              quoteProductId: _this15.policyId || null,
              quoteVehicleTax: {
                curYearTax: _this15.form.quoteVehicleTax.curYearTax || 0,
                lastYearPay: _this15.form.quoteVehicleTax.lastYearPay || 0,
                delayPayMoney: _this15.form.quoteVehicleTax.delayPayMoney || 0
              },
              effectiveStartTime: _this15.form.bzRiskKind.effectiveStartTime,
              effectiveEndTime: _this15.form.bzRiskKind.effectiveEndTime,
              poundageRate: _this15.form.bzRiskKind.poundageRate || 0,
              premium: _this15.form.bzPremium,
              policyNo: _this15.form.bzRiskNo,
              discountFactor: _this15.form.bzDiscountFactor
            });
          }
          if (_this15.showInsuranceType !== '1' && _this15.showInsuranceType != '2') {
            if (_.isEmpty(_this15.form.quoteDutys) || _this15.form.bsPremium <= 0) {
              _this15.$message({
                type: "warning",
                message: '请补充商业险险别信息!'
              });
              return;
            }
            if (_this15.form.bsPremium > 100000) {
              _this15.$message({
                type: "warning",
                message: '商业险总金额不能大于100000元!'
              });
              return;
            }
            if (!_.isEmpty(_this15.form.quoteDutys)) {
              _this15.form.quoteDutys.forEach(function (duty) {
                var risk = _.find(_this15.dutyAllCode, function (item) {
                  return item.dictCode == duty.riskkindCode;
                });
                if (risk) duty.riskkindName = risk.dictName;
              });
            }
            //检查是否添加了重复险别
            for (var i = 0; i < _this15.form.quoteDutys.length - 1; i++) {
              for (var j = i + 1; j < _this15.form.quoteDutys.length; j++) {
                if (_this15.form.quoteDutys[i].riskkindCode === _this15.form.quoteDutys[j].riskkindCode) {
                  _this15.$message({
                    type: "warning",
                    message: "\u60A8\u6DFB\u52A0\u4E86\u91CD\u590D\u7684\u9669\u522B\u3010".concat(_this15.form.quoteDutys[i].riskkindName, "\u3011\uFF0C\u8BF7\u4ED4\u7EC6\u6838\u5BF9\uFF01")
                  });
                  return;
                }
              }
            }
            _this15.form.quoteProducts.push({
              riskType: 1,
              //险种（险种）类型（0：交强险，1：商业险）
              riskTypeName: "商业险",
              quoteProductId: _this15.policyId || null,
              quoteDutys: _this15.form.quoteDutys,
              effectiveStartTime: _this15.form.bsRiskKind.effectiveStartTime,
              effectiveEndTime: _this15.form.bsRiskKind.effectiveEndTime,
              poundageRate: _this15.form.bsRiskKind.poundageRate || 0,
              premium: _this15.form.bsPremium,
              policyNo: _this15.form.bsRiskNo,
              discountFactor: _this15.form.bsDiscountFactor
            });
          }
          // 非车险
          if (_this15.showInsuranceType == '2' || _this15.showInsuranceType == '3') {
            //检查是否添加了重复险别
            for (var i = 0; i < _this15.form.fcQuoteDutys.length - 1; i++) {
              for (var j = i + 1; j < _this15.form.fcQuoteDutys.length; j++) {
                if (_this15.form.fcQuoteDutys[i].riskkindName === _this15.form.fcQuoteDutys[j].riskkindName) {
                  _this15.$message({
                    type: "warning",
                    message: "\u60A8\u6DFB\u52A0\u4E86\u91CD\u590D\u7684\u9669\u522B\u3010".concat(_this15.form.fcQuoteDutys[i].riskkindName, "\u3011\uFF0C\u8BF7\u4ED4\u7EC6\u6838\u5BF9\uFF01")
                  });
                  return;
                }
              }
            }
            _this15.form.quoteProducts.push({
              riskType: 2,
              //险种（险种）类型（0：交强险，1：商业险）
              riskTypeName: "非车险",
              quoteProductId: _this15.policyId || null,
              quoteDutys: _this15.form.fcQuoteDutys,
              effectiveStartTime: _this15.form.fcRiskKind.effectiveStartTime,
              effectiveEndTime: _this15.form.fcRiskKind.effectiveEndTime,
              poundageRate: _this15.form.fcRiskKind.poundageRate || 0,
              premium: _this15.form.fcPremium,
              policyNo: _this15.form.fcBsRiskNo,
              discountFactor: ''
            });
          }
          if (_this15.form.quoteVehicle.plateType) {
            //号牌种类名称
            var lable = _.find(_this15.PLATE_TYPE, function (item) {
              return item.dictCode === _this15.form.quoteVehicle.plateType;
            });
            _this15.form.quoteVehicle.plateTypeName = lable.dictName;
          }
          if (_this15.form.quoteVehicle.useAttribute) {
            //车辆使用性质名称
            var lable1 = _.find(_this15.USE_ATTRIBUTE, function (item) {
              return item.dictCode === _this15.form.quoteVehicle.useAttribute;
            });
            _this15.form.quoteVehicle.useAttributeName = lable1.dictName;
          }
          if (_this15.form.quoteVehicle.vehicleType) {
            //行驶证车辆类型名称
            var lable2 = _.find(_this15.VEHICLE_KINDS, function (item) {
              return item.dictCode === _this15.form.quoteVehicle.vehicleType;
            });
            _this15.form.quoteVehicle.vehicleTypeName = lable2.dictName;
          }
          _this15.form.quoteVehicle.ownAttribute = 0; //车主性质编码
          _this15.form.quoteVehicle.ownAttributeName = 0; //车主性质编码名称

          _this15.form.quoteVehicle.cjVhcBrandNo = _this15.carTypeVal[0];
          _this15.form.quoteVehicle.cjVhcSeriesNo = _this15.carTypeVal[1];
          // this.form.quoteVehicle.cjVhcModelNo = this.carTypeVal[2];
          if (_this15.form.insuredTime) {
            _this15.form.insuredTime = _this15.form.insuredTime.split(" ")[0] + " 00:00:00";
          }
          if (_this15.form.insuredTime) {
            _this15.form.insuredTime = _this15.form.insuredTime.split(" ")[0] + " 00:00:00";
          }
          _this15.form.operatorBy = _this15.userInfo.user.userId;
          _this15.form.operatorName = _this15.userInfo.user.userName;
          _this15.form.tenantId = _this15.userInfo.tenantId;
          if (_this15.form.insCorpCode) {
            _this15.form.insCorpName = _.find(_this15.supplyers, function (item) {
              return item.code == _this15.form.insCorpCode;
            }).shortName;
          }
          _this15.form.renewalType = _this15.form.renewalType || null;
          _this15.form.source = _this15.form.source || 'ADD';
          _this15.form.sourceName = _this15.form.sourceName || '手工录入';
          _this15.form.salesOrgType = _this15.form.salesOrgType || 'DEALER';
          var request = _this15.policyId ? requestPolicy.policyEdit(_this15.form) : requestPolicy.policyInput(_this15.form);
          _this15.formLoading = true;
          request.then(function (res) {
            _this15.formLoading = false;
            if (res.code === 200) {
              _this15.$message({
                type: "success",
                message: res.msg
              });
              if (_this15.policyId) {
                _this15.cancel();
              } else {
                sessionStorage.removeItem('policyForm');
                _this15.$refs['form'].resetFields();
                _this15.initForm();
                _this15.initData();
              }
            }
          }).catch(function (err) {
            _this15.formLoading = false;
          });
        } else {
          _this15.isClick = false;
          _this15.scrollView(obj);
        }
      });
    },
    scrollView: function scrollView(object) {
      // 滚动到固定地方
      this.openPanel();
      for (var i in object) {
        var dom = this.$refs[i];
        // 这里是针对遍历的情况（多个输入框），取值为数组
        if (Object.prototype.toString.call(dom) !== "[object Object]" && dom) {
          if (dom.length) {
            dom = dom[0];
          }
        }
        // 第一种方法（包含动画效果）
        if (dom) dom.$el.scrollIntoView({
          // 滚动到指定节点
          // 值有start,center,end，nearest，当前显示在视图区域中间
          block: "end",
          // 值有auto、instant,smooth，缓动动画（当前是慢速的）
          behavior: "smooth"
        });
        break; // 因为我们只需要检测一项,所以就可以跳出循环了
      }
    },
    openPanel: function openPanel() {
      //打开面板
      this.activeOne = "1";
      this.activeTwo = "1";
      this.activeThree = "1";
      this.activeFour = "1";
      this.activeFive = "1";
    },
    transferChange: function transferChange() {
      this.form.quoteVehicle.transferDate = '';
    }
  }
};