import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '../../mixins/initHeight';
import Pagination from '@/components/Pagination';
import ViewRenewalDialog from './dialog/viewRenewalDialog';
import RefreshRenewal from './dialog/refreshRenewal';
import request from '@/api/dataCenter';
import { getSupplyers, character, commonExport } from '@/utils/common';
export default {
  components: {
    Pagination: Pagination,
    ViewRenewalDialog: ViewRenewalDialog,
    RefreshRenewal: RefreshRenewal
  },
  mixins: [initHeight],
  data: function data() {
    return {
      drawer: false,
      searchForm: {},
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      loading: false,
      supplyers: [],
      multipleSelection: [],
      showRenewalDetailsDialog: false,
      showCacheDialog: false,
      cacheForm: {},
      showRenewalDialog: false,
      currentId: ''
    };
  },
  created: function created() {
    //保司
    this.supplyers = getSupplyers();
    this.loadData();
  },
  watch: {
    showCacheDialog: function showCacheDialog(val) {
      var _this = this;
      if (val) {
        this.cacheForm = {
          vin: ''
        };
        this.$nextTick(function () {
          _this.$refs.cacheForm.resetFields();
        });
      }
      ;
    }
  },
  methods: {
    // 重置搜索表单
    resetSearch: function resetSearch() {
      this.searchForm = {};
    },
    // 搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    // 刷新
    breakData: function breakData() {
      this.searchData();
    },
    // 获取列表数据
    loadData: function loadData(val) {
      var _this2 = this;
      this.drawer = false;
      this.showRenewalDetailsDialog = false;
      this.showCacheDialog = false;
      this.showRenewalDialog = false;
      if (val) return;
      this.loading = true;
      var postData = {};
      postData = Object.assign(postData, this.searchForm, this.listQuery);
      request.getRenewalInformation(postData).then(function (res) {
        _this2.loading = false;
        if (res.code === 200) {
          _this2.tableData = res.data.list;
          _this2.total = res.data.page.recordsTotal;
        }
        ;
      }).catch(function (err) {
        _this2.loading = false;
      });
    },
    // 导出
    exportFn: function exportFn() {
      var exporParams = {
        total: this.total,
        fileName: '续保公库',
        exportUrl: '/vi-core-service/renewalInformation/search',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    // 查看
    view: function view(item) {
      this.currentId = item.renewalPolicyRecordId.toString();
      this.showRenewalDetailsDialog = true;
    },
    // 列表行选择事件
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 删除
    batchDelete: function batchDelete(item) {
      var _this3 = this;
      var ids = [];
      if (!_.isEmpty(item)) {
        ids.push(item.renewalPolicyRecordId);
      } else {
        if (!_.isEmpty(this.multipleSelection)) {
          ids = _.map(this.multipleSelection, 'renewalPolicyRecordId');
        }
        ;
      }
      ;
      this.$confirm('您确定要删除么？', {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        request.deleteRenewalInformation(ids).then(function (res) {
          if (res.code === 200) {
            _this3.$message.success(res.msg);
            _this3.loadData();
          }
          ;
        });
      });
    },
    // 清理缓存
    clearCache: function clearCache() {
      this.showCacheDialog = true;
    },
    // 缓存保存
    saveCache: function saveCache() {
      this.$refs['cacheForm'].validate(function (valid) {
        if (valid) {
          // console.log(666);
        }
      });
    },
    // 刷新续保
    refreshRenewal: function refreshRenewal() {
      this.showRenewalDialog = true;
    },
    // 自动将车牌号码转为大写
    inputPlateNo: function inputPlateNo(val) {
      this.searchForm.plateNo = _.toUpper(val);
    },
    // 输入的车架号中的字母自动转大写，并去除i，o，q
    checkValue: function checkValue(val) {
      if (val) {
        val = val.replace(/i/g, '').replace(/I/g, '').replace(/o/g, '').replace(/O/g, '').replace(/q/g, '').replace(/Q/g, '');
      }
      this.searchForm.vin = character(_.toUpper(val));
    }
  }
};