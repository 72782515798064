var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: {
            size: "mini",
            data: _vm.listData,
            height: _vm.tableHeightT,
            "header-cell-style": { background: "#F7F7F7" },
            "element-loading-text": "加载中...",
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "业务单号",
              "min-width": "120",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(" " + _vm._s(scope.row.quoteNo) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "报价时间", width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(" " + _vm._s(scope.row.updateTime) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "车主名",
              "min-width": "150",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(" " + _vm._s(scope.row.ownerName) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "投保状态", "show-overflow-tooltip": "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.status
                      ? _c(
                          "div",
                          _vm._l(scope.row.status, function (item, index) {
                            return _c("div", { key: index }, [
                              _c(
                                "span",
                                { staticStyle: { "margin-left": "5px" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      item.insCorpName
                                        ? item.insCorpName + ":"
                                        : ""
                                    )
                                  ),
                                ]
                              ),
                              _c(
                                "span",
                                {
                                  staticStyle: { "margin-left": "3px" },
                                  style: {
                                    color: _vm.getStatusColor(
                                      item.proposalStatus
                                    ),
                                  },
                                },
                                [_vm._v(_vm._s(item.statusDescription))]
                              ),
                            ])
                          }),
                          0
                        )
                      : _c("div", { staticStyle: { "text-align": "center" } }, [
                          _c("span", [_vm._v("暂存")]),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "操作员", "show-overflow-tooltip": "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(" " + _vm._s(scope.row.operatorName) + " ")]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "flex-end",
            "align-items": "center",
            height: "40px",
          },
        },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.pageNum,
              "page-sizes": [10, 20, 30, 50, 100, 200, 300, 500],
              "page-size": _vm.pageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }