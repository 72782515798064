import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '../../../mixins/initHeight';
import dict from '../../../mixins/dict';
import Pagination from '@/components/Pagination';
import ConfigDictDialog from './../dialog/configDictDialog';
import SMSDialog from './../dialog/SMSDialog';
import repairApi from '@/api/repair';
export default {
  name: 'SMSMatchTemplet',
  components: {
    Pagination: Pagination,
    ConfigDictDialog: ConfigDictDialog,
    SMSDialog: SMSDialog
  },
  mixins: [initHeight, dict],
  computed: {},
  data: function data() {
    return {
      activeName: "all",
      drawer: false,
      searchForm: {},
      form: {},
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      defaultProps: {
        children: 'children',
        label: 'fullName'
      },
      value1: "",
      tableData: [],
      multipleSelection: [],
      opType: '',
      startEndTime: null,
      loading: false,
      orgData: [],
      highlightCurrent: true,
      showConfigDictDialog: false,
      showSMSDialog: false,
      templatelist: [],
      templateId: '',
      ShowVerifyDialog: false,
      VerifyForm: {
        smsContent: ''
      },
      VerifyFormTemplateContent: '',
      VerifyRules: {
        smsContent: [{
          required: true,
          message: '请输入短信内容',
          trigger: 'blur'
        }]
      }
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.loadData();
  },
  methods: {
    verifyClicked: function verifyClicked() {
      this.ShowVerifyDialog = true;
      this.$refs['VerifyDetail'].resetFields();
    },
    // 解析
    save: function save() {
      var _this = this;
      this.$refs['VerifyDetail'].validate(function (valid) {
        if (valid) {
          var userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {};
          _this.VerifyForm.orgCode = userInfo.orgCode;
          _this.VerifyForm.bizType = 'DEALER';
          repairApi.TemplateParse(_this.VerifyForm).then(function (res) {
            if (res.code == 200) {
              _this.VerifyFormTemplateContent = res.data ? res.data : '';
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    closeShowVerifyDialog: function closeShowVerifyDialog() {
      this.ShowVerifyDialog = false;
    },
    configDict: function configDict() {
      //配置字典
      this.showConfigDictDialog = true;
    },
    addSMS: function addSMS() {
      //新增模板
      this.opType = "";
      this.showSMSDialog = true;
    },
    deleteSMS: function deleteSMS(row) {
      var _this2 = this;
      // 删除短信
      this.$confirm('是否删除该救援机构?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        repairApi.delTemplate(row.templateId).then(function (res) {
          _this2.$message({
            message: res.msg,
            type: 'success'
          });
          _this2.loadData();
        });
      }).catch(function () {});
    },
    disabledSMS: function disabledSMS(item) {// 禁用短信
    },
    editSMS: function editSMS(type, item) {
      // 编辑短信
      this.opType = type;
      this.showSMSDialog = true;
      this.templateId = item.templateId.toString();
    },
    changeEnabled: function changeEnabled(row) {
      var _this3 = this;
      console.log(row);
      // row.enabled
      // row.templateId
      repairApi.enabledTemplate(row.templateId, row.enabled).then(function (res) {
        if (res.code == 200) {
          _this3.$message({
            message: res.msg,
            type: 'success'
          });
          _this3.loadData();
        }
      });
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {};
    },
    searchData: function searchData() {
      //搜索
      this.loadData();
    },
    getTemplate: function getTemplate() {
      var _this4 = this;
      this.searchForm.pageNum = this.listQuery.pageNum;
      this.searchForm.pageSize = this.listQuery.pageSize;
      repairApi.getTemplate(this.searchForm).then(function (res) {
        if (res.code == 200) {
          _this4.tableData = res.data.list ? res.data.list : [];
        }
      });
    },
    loadData: function loadData() {
      //获取列表数据
      // this.loading = true;
      this.drawer = false;
      this.showConfigDictDialog = false;
      this.showSMSDialog = false;
      this.getTemplate();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      //列表行选择事件
      this.multipleSelection = val;
    }
  }
};