var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-m-cls",
      style: { height: _vm.scrollerHeight, overflow: "auto" },
    },
    [
      _c(
        "div",
        { staticClass: "table-cls normal-btn-cls" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  staticStyle: { "margin-bottom": "10px" },
                  attrs: { xs: 24, sm: 24, md: 24, lg: 10, xl: 10 },
                },
                [
                  _c(
                    "el-card",
                    { staticClass: "box-card", attrs: { shadow: "never" } },
                    [
                      _c("div", { staticClass: "header" }, [
                        _vm._v("快捷方式"),
                      ]),
                      _vm.showShortcut
                        ? _c(
                            "el-carousel",
                            {
                              attrs: {
                                height: "190px",
                                "indicator-position": "outside",
                                autoplay: false,
                              },
                            },
                            _vm._l(_vm.listData, function (item, index) {
                              return _c(
                                "el-carousel-item",
                                { key: index },
                                [
                                  _c("stirtcut", {
                                    attrs: {
                                      "menu-list": item.menuList,
                                      "menu-list-data": item.menuListData,
                                    },
                                  }),
                                ],
                                1
                              )
                            }),
                            1
                          )
                        : _vm._e(),
                      !_vm.showShortcut
                        ? _c(
                            "div",
                            {
                              staticClass: "content",
                              staticStyle: { height: "215px" },
                            },
                            [
                              _c(
                                "el-row",
                                {
                                  staticStyle: { "line-height": "30px" },
                                  attrs: { gutter: 20 },
                                },
                                [_vm._v(" 暂无！ ")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                {
                  staticStyle: { "margin-bottom": "10px" },
                  attrs: { xs: 24, sm: 24, md: 12, lg: 7, xl: 7 },
                },
                [
                  _c(
                    "el-card",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loadingNotice,
                          expression: "loadingNotice",
                        },
                      ],
                      staticClass: "box-card",
                      staticStyle: { "min-height": "280px" },
                      attrs: { shadow: "never" },
                    },
                    [
                      _c("div", { staticClass: "header" }, [
                        _c("div", [_vm._v(" 最新公告 ")]),
                        _c(
                          "div",
                          {
                            staticClass: "bottom",
                            on: { click: _vm.notifyViewDetail },
                          },
                          [_c("span", [_vm._v("查看更多...")])]
                        ),
                      ]),
                      _vm.notifyTableData && _vm.notifyTableData.length !== 0
                        ? _c(
                            "div",
                            { staticClass: "content msglist" },
                            _vm._l(_vm.notifyTableData, function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass: "contentItem",
                                  on: {
                                    click: function ($event) {
                                      return _vm.getDetail(item)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: { flex: "1" },
                                      attrs: { title: item.messageTitle },
                                    },
                                    [_vm._v(_vm._s(item.messageTitle))]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "flex-direction": "row",
                                        "justify-content": "flex-end",
                                        "align-items": "center",
                                        width: "170px",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticStyle: { color: "#acacac" } },
                                        [
                                          _vm._v(
                                            _vm._s(item.messageSendDatetime)
                                          ),
                                          _c("i", {
                                            staticClass: "el-icon-arrow-right",
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }),
                            0
                          )
                        : _c(
                            "div",
                            { staticClass: "content msglist" },
                            [
                              _c(
                                "el-row",
                                {
                                  staticStyle: { "line-height": "30px" },
                                  attrs: { gutter: 0 },
                                },
                                [_c("span", [_vm._v("暂无公告！")])]
                              ),
                            ],
                            1
                          ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { xs: 24, sm: 24, md: 12, lg: 7, xl: 7 } },
                [
                  _c(
                    "el-card",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loadingMsg,
                          expression: "loadingMsg",
                        },
                      ],
                      staticClass: "box-card",
                      staticStyle: { "min-height": "280px" },
                      attrs: { shadow: "never" },
                    },
                    [
                      _c("div", { staticClass: "header" }, [
                        _c("div", [_vm._v(" 最新消息 ")]),
                        _c(
                          "div",
                          {
                            staticClass: "bottom",
                            on: { click: _vm.msgViewDetail },
                          },
                          [_c("span", [_vm._v("查看更多...")])]
                        ),
                      ]),
                      _vm.messageTableData && _vm.messageTableData.length !== 0
                        ? _c(
                            "div",
                            { staticClass: "content msglist" },
                            _vm._l(
                              _vm.messageTableData,
                              function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass: "contentItem",
                                    on: {
                                      click: function ($event) {
                                        return _vm.getDetail(item)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { flex: "1" },
                                        attrs: { title: item.messageTitle },
                                      },
                                      [_vm._v(_vm._s(item.messageTitle))]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          "flex-direction": "row",
                                          "justify-content": "flex-end",
                                          "align-items": "center",
                                          width: "170px",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticStyle: { color: "#acacac" } },
                                          [
                                            _vm._v(
                                              _vm._s(item.messageSendDatetime)
                                            ),
                                            _c("i", {
                                              staticClass:
                                                "el-icon-arrow-right",
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          )
                        : _c(
                            "div",
                            {
                              staticClass: "content msglist",
                              staticStyle: { "line-height": "30px" },
                            },
                            [_vm._v(" 暂无消息！ ")]
                          ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-row",
                    { staticStyle: { "margin-top": "10px", width: "100%" } },
                    [
                      _c(
                        "el-card",
                        {
                          staticClass: "box-card tabs",
                          staticStyle: { width: "100%" },
                          attrs: { shadow: "never" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                                "justify-content": "space-between",
                              },
                            },
                            [
                              _c("div", { staticClass: "header" }, [
                                _vm._v("工作量统计"),
                              ]),
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      on: { change: _vm.handleRadio },
                                      model: {
                                        value: _vm.tabPosition,
                                        callback: function ($$v) {
                                          _vm.tabPosition = $$v
                                        },
                                        expression: "tabPosition",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-radio-button",
                                        { attrs: { label: "day" } },
                                        [_vm._v("今天")]
                                      ),
                                      _c(
                                        "el-radio-button",
                                        { attrs: { label: "yesterday" } },
                                        [_vm._v("昨天")]
                                      ),
                                      _c(
                                        "el-radio-button",
                                        { attrs: { label: "week" } },
                                        [_vm._v("本周")]
                                      ),
                                      _c(
                                        "el-radio-button",
                                        { attrs: { label: "lastWeek" } },
                                        [_vm._v("上周")]
                                      ),
                                      _c(
                                        "el-radio-button",
                                        { attrs: { label: "month" } },
                                        [_vm._v("本月")]
                                      ),
                                      _c(
                                        "el-radio-button",
                                        { attrs: { label: "lastMonth" } },
                                        [_vm._v("上月")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { gutter: 20 },
                    },
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { "margin-bottom": "10px" },
                          attrs: { xs: 24, sm: 12, md: 12, lg: 6, xl: 6 },
                        },
                        [
                          _c(
                            "el-card",
                            {
                              staticClass: "box-card",
                              attrs: { shadow: "never" },
                            },
                            [
                              _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "header" },
                                  [
                                    _c("div", [_vm._v("车险出单数量")]),
                                    _c("el-tag", { attrs: { type: "info" } }, [
                                      _vm._v("单"),
                                    ]),
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "content" }, [
                                  _c("div", { staticClass: "statistics" }, [
                                    _vm._v(
                                      _vm._s(_vm.dataIssuance.quantity || 0)
                                    ),
                                  ]),
                                  _c(
                                    "p",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "justify-content": "space-between",
                                      },
                                    },
                                    [
                                      _c("span", [_vm._v("上期数据：")]),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.dataIssuance.quantityPre)
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "p",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "justify-content": "space-between",
                                      },
                                    },
                                    [
                                      _c("span", [_vm._v("环比上期：")]),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.dataIssuance.quantityRatio)
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          staticStyle: { "margin-bottom": "10px" },
                          attrs: { xs: 24, sm: 12, md: 12, lg: 6, xl: 6 },
                        },
                        [
                          _c(
                            "el-card",
                            {
                              staticClass: "box-card",
                              attrs: { shadow: "never" },
                            },
                            [
                              _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "header" },
                                  [
                                    _c("div", [_vm._v("车险出单保费")]),
                                    _c(
                                      "el-tag",
                                      { attrs: { type: "success" } },
                                      [_vm._v("元")]
                                    ),
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "content" }, [
                                  _c("div", { staticClass: "statistics" }, [
                                    _vm._v(
                                      _vm._s(_vm.dataIssuance.premium || 0)
                                    ),
                                  ]),
                                  _c(
                                    "p",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "justify-content": "space-between",
                                      },
                                    },
                                    [
                                      _c("span", [_vm._v("上期数据：")]),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.dataIssuance.premiumPre || 0
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "p",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "justify-content": "space-between",
                                      },
                                    },
                                    [
                                      _c("span", [_vm._v("环比上期：")]),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.dataIssuance.premiumRatio ||
                                              "--"
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          staticStyle: { "margin-bottom": "10px" },
                          attrs: { xs: 24, sm: 12, md: 12, lg: 6, xl: 6 },
                        },
                        [
                          _c(
                            "el-card",
                            {
                              staticClass: "box-card",
                              attrs: { shadow: "never" },
                            },
                            [
                              _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "header" },
                                  [
                                    _c("div", [_vm._v("报价车辆数")]),
                                    _c(
                                      "el-tag",
                                      { attrs: { type: "danger" } },
                                      [_vm._v("辆")]
                                    ),
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "content" }, [
                                  _c("div", { staticClass: "statistics" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dataQuotation.numberVehicles || 0
                                      )
                                    ),
                                  ]),
                                  _c(
                                    "p",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "justify-content": "space-between",
                                      },
                                    },
                                    [
                                      _c("span", [_vm._v("上期数据：")]),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.dataQuotation.numberPreVehicles
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "p",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "justify-content": "space-between",
                                      },
                                    },
                                    [
                                      _c("span", [_vm._v("环比上期：")]),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.dataQuotation.vehiclesRatio
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          staticStyle: { "margin-bottom": "10px" },
                          attrs: { xs: 24, sm: 12, md: 12, lg: 6, xl: 6 },
                        },
                        [
                          _c(
                            "el-card",
                            {
                              staticClass: "box-card",
                              attrs: { shadow: "never" },
                            },
                            [
                              _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "header" },
                                  [
                                    _c("div", [_vm._v("车险报价次数")]),
                                    _c(
                                      "el-tag",
                                      { attrs: { type: "warning" } },
                                      [_vm._v("人")]
                                    ),
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "content" }, [
                                  _c("div", { staticClass: "statistics" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.dataQuotation.numberQuotes || 0
                                      )
                                    ),
                                  ]),
                                  _c(
                                    "p",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "justify-content": "space-between",
                                      },
                                    },
                                    [
                                      _c("span", [_vm._v("上期数据：")]),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.dataQuotation.numberPreQuotes
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "p",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "justify-content": "space-between",
                                      },
                                    },
                                    [
                                      _c("span", [_vm._v("环比上期：")]),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.dataQuotation.quotesRatio)
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "msgDialog",
          attrs: {
            visible: _vm.showMsgDialog,
            "close-on-click-modal": false,
            width: "720px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showMsgDialog = $event
            },
            close: function ($event) {
              _vm.showMsgDialog = false
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c("div", { staticClass: "dialogFooterTitle" }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      width: "18px",
                      height: "18px",
                      "margin-right": "10px",
                    },
                  },
                  [
                    _c("svg-icon", {
                      staticStyle: {
                        "font-size": "18px",
                        "margin-right": "10px",
                        color: "#edcb4f",
                      },
                      attrs: { "icon-class": "notice" },
                    }),
                  ],
                  1
                ),
                _c("div", { staticStyle: { "font-size": "15px" } }, [
                  _vm._v(_vm._s(_vm.dialogTitle)),
                ]),
              ]),
            ]
          ),
          _c("div", {
            staticStyle: {
              "max-height": "300px",
              "min-height": "120px",
              "overflow-y": "auto",
              "line-height": "30px",
              "white-space": "pre-wrap",
            },
            domProps: { innerHTML: _vm._s(_vm.dialogContent) },
          }),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "flex-start",
                "margin-top": "10px",
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "flex-direction": "row",
                    "justify-content": "flex-start",
                    "align-items": "center",
                  },
                },
                [
                  _c("i", {
                    staticClass: "el-icon-paperclip",
                    staticStyle: { "font-size": "18px", color: "#acacac" },
                  }),
                  _vm.dialogTime
                    ? _c("span", { staticStyle: { "font-size": "12px" } }, [
                        _vm._v("发送时间：" + _vm._s(_vm.dialogTime)),
                      ])
                    : _vm._e(),
                ]
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }