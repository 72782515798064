import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import dict from '@/mixins/dict';
import Pagination from '@/components/Pagination';
import NonAutoRequest from '@/api/nonAutoRequest';
import addDialog from './add';
import { commonExport } from '@/utils/common';
export default {
  name: 'auditors',
  components: {
    Pagination: Pagination,
    addDialog: addDialog
  },
  mixins: [initHeight, dict],
  computed: {},
  data: function data() {
    return {
      orgForm: {
        salesOrgCode: "",
        salesOrgName: ""
      },
      dialogType: '',
      dialogId: '',
      showAddDialog: false,
      showViewDialog: false,
      searchForm: {},
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      loading: false,
      details: {},
      timeoutForExport: null,
      timeoutForRefresh: null
    };
  },
  mounted: function mounted() {
    this.loadData();
  },
  methods: {
    //获取列表数据
    loadData: function loadData() {
      var _this = this;
      this.loading = true;
      this.showAddDialog = false;
      var data = _objectSpread(_objectSpread({}, this.listQuery), this.searchForm);
      NonAutoRequest.getAuditorsList(data).then(function (res) {
        _this.loading = false;
        if (res.code == 200) {
          _this.tableData = res.data.list ? res.data.list : [];
          _this.total = res.data.page ? res.data.page.recordsTotal : 0;
        } else {
          _this.tableData = [];
          _this.total = 0;
        }
      });
    },
    // 导出防抖
    exportFn: function exportFn() {
      var _this2 = this;
      if (this.timeoutForExport) {
        clearTimeout(this.timeoutForExport);
      }
      this.timeoutForExport = setTimeout(function () {
        _this2.exportFn1();
      }, 500);
    },
    // 导出
    exportFn1: function exportFn1() {
      var exporParams = {
        total: this.total,
        fileName: '非车服保司复核人员管理列表',
        exportUrl: '/biz-nonauto-connect-adapter-ftms-service/inscorpAuditorConfig/search',
        searchForm: this.listQuery
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    // 刷新防抖
    refreshClicked: function refreshClicked() {
      var _this3 = this;
      if (this.timeoutForRefresh) {
        clearTimeout(this.timeoutForRefresh);
      }
      this.timeoutForRefresh = setTimeout(function () {
        _this3.refreshClicked1();
      }, 500);
    },
    // 刷新
    refreshClicked1: function refreshClicked1() {
      this.searchData();
    },
    // 新增
    addClicked: function addClicked(type) {
      this.dialogType = type;
      this.showAddDialog = true;
    },
    // 编辑
    editCLicked: function editCLicked(type, item) {
      this.dialogType = type;
      this.dialogId = item.insCorpConfigId.toString();
      this.showAddDialog = true;
    },
    // 启用禁用
    enableCLicked: function enableCLicked(item) {
      var _this4 = this;
      var data = {
        enable: item.enable,
        insCorpConfigId: item.insCorpConfigId
      };
      NonAutoRequest.AuditorsEnable(data).then(function (res) {
        if (res.code == 200) {
          _this4.$message.success(res.msg);
          _this4.loadData();
        }
      });
    },
    // 删除
    delClicked: function delClicked(item) {
      var _this5 = this;
      this.$confirm('是否删除当前数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        NonAutoRequest.AuditorsDel(item.insCorpConfigId).then(function (res) {
          if (res.code == 200) {
            _this5.$message.success('删除成功');
            _this5.loadData();
          }
        });
      }).catch(function () {});
    },
    detail: function detail(item) {
      this.showViewDialog = true;
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    }
  }
};