import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import dict from '@/mixins/dict';
import Pagination from '@/components/Pagination';
import repairApi from '@/api/repair';
import { commonExport, getSupplyers, character, getBeforeDate, getCurrentDate } from '@/utils/common';
export default {
  name: 'InscorpClue',
  components: {
    Pagination: Pagination
  },
  mixins: [initHeight, dict],
  data: function data() {
    return {
      drawer: false,
      searchForm: {
        createdTimeStart: "".concat(getBeforeDate(30), " 00:00:00"),
        createdTimeEnd: "".concat(getCurrentDate(), " 23:59:59")
      },
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      multipleSelection: [],
      loading: false,
      supplyers: getSupplyers() || [],
      createdStartEndTime: [getBeforeDate(30), getCurrentDate()],
      reportStartEndTime: [],
      accidentStartEndTime: [],
      vehicleTypeList: [{
        value: 'CCVT01',
        label: '本车'
      }, {
        value: 'CCVT02',
        label: '三者车'
      }],
      repairTypeList: [{
        value: 'REWORK',
        label: '返修'
      }, {
        value: 'REPAIR',
        label: '送修'
      }, {
        value: 'OTHER',
        label: '其它'
      }]
    };
  },
  created: function created() {
    this.loadData();
  },
  methods: {
    // 日期更改监听事件
    dateChange: function dateChange(start, end, val) {
      if (val && val.length > 1) {
        this.searchForm[start] = val[0] + ' 00:00:00';
        this.searchForm[end] = val[1] + ' 23:59:59';
      } else {
        this.searchForm[start] = null;
        this.searchForm[end] = null;
      }
      ;
    },
    // 监听输入事件
    inputvin: function inputvin(val) {
      if (val) {
        val = val.replace(/i/g, '').replace(/I/g, '').replace(/o/g, '').replace(/O/g, '').replace(/q/g, '').replace(/Q/g, '');
        this.searchForm.vin = character(_.toUpper(val));
      }
    },
    // 车牌号自动转大写
    inputPlateNo: function inputPlateNo(val) {
      this.searchForm.plateNo = _.toUpper(val);
    },
    // 导出
    exportFn: function exportFn() {
      var exporParams = {
        total: this.total,
        fileName: '保司线索',
        exportUrl: '/oc-core-service/repair/inscorp/clue/search',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    // 重置搜索表单
    resetSearch: function resetSearch() {
      this.searchForm = {
        createdTimeStart: "".concat(getBeforeDate(30), " 00:00:00"),
        createdTimeEnd: "".concat(getCurrentDate(), " 23:59:59")
      };
      this.createdStartEndTime = [getBeforeDate(30), getCurrentDate()];
      this.reportStartEndTime = [];
      this.accidentStartEndTime = [];
    },
    // 搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    // 列表刷新
    breakData: function breakData() {
      this.loadData();
    },
    // 获取列表数据
    loadData: function loadData() {
      var _this = this;
      var isDialogNoRefresh = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      if (isDialogNoRefresh) return;
      this.drawer = false;
      this.loading = true;
      var data = _objectSpread(_objectSpread({}, this.searchForm), this.listQuery);
      repairApi.getInscorpClueList(data).then(function (res) {
        _this.loading = false;
        if (res.code == 200) {
          _this.tableData = res.data.list;
          _this.total = res.data.page.recordsTotal;
        }
      }).catch(function (err) {
        _this.loading = false;
      });
    },
    // 列表行选择事件
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    }
  }
};