//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '../../mixins/initHeight';
import Pagination from '@/components/Pagination';
import DatePicker from '@/components/DatePicker';
import UploadBillDialog from './dialog/uploadBillDialog';
import request from '../../api/finance';
import ImportBillListDialog from './dialog/importBillListDialog';
import { saveFile } from '@/utils/common';
export default {
  name: 'detailedList',
  components: {
    Pagination: Pagination,
    UploadBillDialog: UploadBillDialog,
    ImportBillListDialog: ImportBillListDialog,
    DatePicker: DatePicker
  },
  mixins: [initHeight],
  data: function data() {
    return {
      drawer: false,
      searchForm: {},
      form: {},
      total: 0,
      listLoading: true,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      multipleSelection: [],
      startEndTime: [],
      loading: false,
      showThresholdDialog: false,
      showUploadBillDialog: false,
      showBillImportDialog: false,
      bizType: localStorage.getItem("bizType") || ''
    };
  },
  mounted: function mounted() {
    this.loadData();
  },
  methods: {
    download: function download() {
      //下载模板
      request.download().then(function (res) {
        var fileName = "保司台账模板.xlsx";
        var contentType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        saveFile(res, contentType, fileName);
      });
    },
    // 日期选择事件
    getPickersDate: function getPickersDate(name, val) {
      if (val && val.length > 1) {
        this[name] = val;
      } else {
        this[name] = [];
      }
    },
    uploadBill: function uploadBill() {
      //上传账单
      this.showUploadBillDialog = true;
    },
    deleteBill: function deleteBill() {
      //删除账单
      if (this.multipleSelection.length === 0) {
        this.$message({
          message: "请选择要删除的账单",
          type: "error"
        });
      }
      ;
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {};
      this.startEndTime = [];
    },
    // 刷新
    breakData: function breakData() {
      // this.resetSearch();
      this.loadData();
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    loadData: function loadData() {
      var _this = this;
      //获取列表数据
      this.loading = true;
      this.drawer = false;
      this.showUploadBillDialog = false;
      this.showBillImportDialog = false;
      if (this.startEndTime) {
        this.searchForm.insuredTimeStart = this.startEndTime[0] && this.startEndTime[0].split(' ')[0] + ' 00:00:00' || null;
        this.searchForm.insuredTimeEnd = this.startEndTime[1] && this.startEndTime[1].split(' ')[0] + ' 23:59:59' || null;
      }
      var postData = {};
      postData = Object.assign(postData, this.searchForm, this.listQuery);
      request.getListInvoice(postData).then(function (res) {
        _this.loading = false;
        _this.tableData = res.data.list;
        _this.total = res.data.page.recordsTotal;
      }).catch(function (err) {
        _this.loading = false;
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      //列表行选择事件
      this.multipleSelection = val;
    }
  }
};