var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "app-m-cls" }, [
        _c(
          "div",
          { staticClass: "table-cls normal-btn-cls " },
          [
            _c(
              "el-card",
              { staticClass: "box-card", attrs: { shadow: "never" } },
              [
                _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                  _c("div", { staticClass: "search-box" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.searchForm.bizType === "OEM",
                            expression: "searchForm.bizType === 'OEM'",
                          },
                        ],
                      },
                      [
                        _c("span", [_vm._v("机构：")]),
                        _c("OemSelect", {
                          staticStyle: {
                            "max-width": "150px",
                            display: "inline-block",
                          },
                          attrs: {
                            searchForm: _vm.orgData,
                            canClickArea: true,
                          },
                          on: { nodeClick: _vm.nodeClick },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.searchForm.bizType === "DEALER",
                            expression: "searchForm.bizType === 'DEALER'",
                          },
                        ],
                      },
                      [
                        _c("span", [_vm._v("机构：")]),
                        _c("el-input", {
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.orgData.salesOrgName,
                            callback: function ($$v) {
                              _vm.$set(_vm.orgData, "salesOrgName", $$v)
                            },
                            expression: "orgData.salesOrgName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: "请选择时间",
                              clearable: false,
                            },
                            on: { change: _vm.handleTime },
                            model: {
                              value: _vm.searchForm.timeSelect,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "timeSelect", $$v)
                              },
                              expression: "searchForm.timeSelect",
                            },
                          },
                          _vm._l(
                            _vm.dictSource["TIME_SELECT"],
                            function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: {
                                  label: item.dictName,
                                  value: item.dictCode,
                                },
                              })
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("el-date-picker", {
                          ref: "input",
                          staticStyle: { width: "320px" },
                          attrs: {
                            disabled: _vm.isDataTime,
                            "start-placeholder": "起期",
                            "end-placeholder": "止期",
                            type: "daterange",
                            format: "yyyy-MM-dd",
                            "value-format": "yyyy-MM-dd",
                            "unlink-panels": "",
                            "range-separator": "——",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.dateChange(
                                "startDate",
                                "endDate",
                                $event
                              )
                            },
                          },
                          model: {
                            value: _vm.startEndTime,
                            callback: function ($$v) {
                              _vm.startEndTime = $$v
                            },
                            expression: "startEndTime",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              disabled:
                                _vm.isDisabled || !_vm.searchForm.orgCode,
                            },
                            on: { click: _vm.recount },
                          },
                          [_vm._v("重新统计")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: { click: _vm.exportFn },
                          },
                          [_vm._v("导出")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c(
                  "div",
                  { staticStyle: { display: "flex", "flex-wrap": "wrap" } },
                  _vm._l(_vm.sumList, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticStyle: {
                          "line-height": "30px",
                          margin: "5px",
                          "background-color": "#edf4fb",
                          width: "15.5%",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              padding: "10px 15px",
                              "border-radius": "2px",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "font-size": "14px",
                                  color: "#999",
                                },
                              },
                              [_vm._v(_vm._s(item.displayText))]
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "font-weight": "bold",
                                  "font-size": "20px",
                                  color: "#0d3386",
                                },
                              },
                              [_vm._v(_vm._s(item.displayValue))]
                            ),
                          ]
                        ),
                      ]
                    )
                  }),
                  0
                ),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    ref: "multipleTable",
                    staticStyle: { width: "100%" },
                    attrs: {
                      size: "mini",
                      border: "",
                      data: _vm.tableData,
                      height:
                        !_vm.sumList || _vm.sumList.length == 0
                          ? _vm.tableHeightDF
                          : _vm.tableHeightDS,
                      "header-cell-style": { background: "#F7F7F7" },
                      "highlight-current-row": "",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "taskName",
                        label: "任务",
                        "min-width": "470",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-link",
                                {
                                  staticStyle: {
                                    "text-decoration": "underline",
                                  },
                                  attrs: { type: "primary", underline: false },
                                  on: {
                                    click: function ($event) {
                                      return _vm.taskDetails(scope.row)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(scope.row.taskName))]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "importDate",
                        label: "导入时间",
                        width: "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "importCount",
                        label: "任务数量",
                        "min-width": "100",
                        align: "right",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "quoteCount",
                        label: "报价数量",
                        "min-width": "100",
                        align: "right",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-link",
                                {
                                  staticStyle: {
                                    "text-decoration": "underline",
                                  },
                                  attrs: { type: "primary", underline: false },
                                  on: {
                                    click: function ($event) {
                                      return _vm.quoteDetail(scope.row)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(scope.row.quoteCount))]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "quoteRate",
                        label: "报价率",
                        "min-width": "100",
                        align: "right",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "renewalCount",
                        label: "续保数量",
                        "min-width": "100",
                        align: "right",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-link",
                                {
                                  staticStyle: {
                                    "text-decoration": "underline",
                                  },
                                  attrs: { type: "primary", underline: false },
                                  on: {
                                    click: function ($event) {
                                      return _vm.policyDetail(scope.row, false)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(scope.row.renewalCount))]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "renewalRate",
                        label: "续保率",
                        "min-width": "100",
                        align: "right",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "policyCount",
                        label: "保单数量",
                        "min-width": "100",
                        align: "right",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-link",
                                {
                                  staticStyle: {
                                    "text-decoration": "underline",
                                  },
                                  attrs: { type: "primary", underline: false },
                                  on: {
                                    click: function ($event) {
                                      return _vm.policyDetail(scope.row, false)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(scope.row.policyCount))]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "renewalPremium",
                        label: "保费",
                        "min-width": "100",
                        align: "right",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "onLinePolicyCount",
                        label: "在线出单量",
                        "min-width": "100",
                        align: "right",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-link",
                                {
                                  staticStyle: {
                                    "text-decoration": "underline",
                                  },
                                  attrs: { type: "primary", underline: false },
                                  on: {
                                    click: function ($event) {
                                      return _vm.policyDetail(scope.row, true)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(scope.row.onLinePolicyCount))]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "onLinePolicyPremium",
                        label: "在线保费",
                        "min-width": "100",
                        align: "right",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "drainCount",
                        label: "流失量",
                        "min-width": "100",
                        align: "right",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "drainRate",
                        label: "流失率",
                        "min-width": "100",
                        align: "right",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "firstTrackCount",
                        label: "首次跟进量",
                        "min-width": "120",
                        align: "right",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "firstTrackRate",
                        label: "首次跟进率",
                        "min-width": "120",
                        align: "right",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "trackCount",
                        label: "跟进次数",
                        "min-width": "100",
                        align: "right",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "avgTrackCount",
                        label: "平均跟进次数",
                        "min-width": "100",
                        align: "right",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "successTrackCount",
                        label: "成功跟进量",
                        "min-width": "100",
                        align: "right",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "successNonPolicyCount",
                        label: "成功未录单量",
                        "min-width": "100",
                        align: "right",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "defeatCount",
                        label: "战败次数",
                        "min-width": "100",
                        align: "right",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "defeatRate",
                        label: "战败率",
                        "min-width": "100",
                        align: "right",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "trackingCount",
                        label: "跟进中&未跟进量",
                        "min-width": "130",
                        align: "right",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "trackingRate",
                        label: "跟进中&未跟进率",
                        "min-width": "130",
                        align: "right",
                        "show-overflow-tooltip": "",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showQuoteDialog,
            "close-on-click-modal": false,
            width: "80%",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showQuoteDialog = $event
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("查看报价详情")]
              ),
            ]
          ),
          _c("QuoteDetailDialog", {
            attrs: {
              refresh: _vm.showQuoteDialog,
              currentItem: _vm.currentItem,
            },
            on: {
              closePage: function ($event) {
                _vm.showQuoteDialog = false
              },
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showPolicyDialog,
            "close-on-click-modal": false,
            width: "80%",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showPolicyDialog = $event
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [
                  _vm._v(
                    "查看" + _vm._s(_vm.isOnline ? "在线" : "") + "保单详情"
                  ),
                ]
              ),
            ]
          ),
          _c("PolicyDetailDialog", {
            attrs: {
              refresh: _vm.showPolicyDialog,
              currentItem: _vm.currentItem,
              isOnline: _vm.isOnline,
            },
            on: {
              closePage: function ($event) {
                _vm.showPolicyDialog = false
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }