import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import Pagination from '@/components/Pagination';
import insuranceConfig from '@/api/insuranceConfig';
export default {
  name: 'SchemeConfig',
  components: {
    Pagination: Pagination
  },
  mixins: [initHeight],
  computed: {},
  data: function data() {
    return {
      drawer: false,
      searchForm: {
        name: ''
      },
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      multipleSelection: [],
      loading: false
    };
  },
  created: function created() {
    this.loadData();
  },
  methods: {
    removeItems: function removeItems(item) {
      var _this = this;
      //删除
      var ids = [];
      if (!_.isEmpty(item)) {
        ids.push(item.riskkindPackageId);
      } else {
        if (!_.isEmpty(this.multipleSelection)) {
          ids = _.map(this.multipleSelection, 'riskkindPackageId');
        }
      }
      if (_.isEmpty(ids)) return;
      this.$confirm('是否确认删除险别项？确认删除后不能恢复。', '温馨提示', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        type: 'warning'
      }).then(function () {
        insuranceConfig.insuranceDelete(ids).then(function (res) {
          if (res.code == 200) {
            _this.$message({
              type: 'success',
              message: res.msg
            });
            _this.loadData();
          } else {
            _this.$message({
              type: 'error',
              message: res.msg
            });
          }
        });
      });
    },
    viewDetail: function viewDetail(type, item) {
      //查看详情

      this.$router.push({
        path: "/schemeConfig/detail",
        query: {
          type: type,
          schemeId: item.riskkindPackageId
        }
      });
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {
        name: ''
      };
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    addSchemeItem: function addSchemeItem() {
      //新增
      this.$router.push({
        path: "/schemeConfig/detail",
        query: {
          type: 'add'
        }
      });
    },
    dbSelected: function dbSelected(row) {
      //双击打开详情
      this.viewDetail('view', row);
    },
    breakData: function breakData() {
      // this.resetSearch();
      this.loadData();
    },
    loadData: function loadData() {
      var _this2 = this;
      //获取列表数据
      // this.loading = true;
      this.drawer = false;
      var postdata = {};
      postdata = Object.assign(postdata, this.searchForm, this.listQuery);
      insuranceConfig.insuranceGetData(postdata).then(function (res) {
        if (res.code == 200) {
          _this2.tableData = res.data.list;
          _this2.total = res.data.page.recordsTotal;
        }
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      //列表行选择事件
      this.multipleSelection = val;
    }
  }
};