import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import dict from '@/mixins/dict';
import Pagination from '@/components/Pagination';
import OrgSelect from '@/components/OrgSelect';
import activityCenterRequest from '@/api/activityCenter';
export default {
  name: 'RenewalQueryResult',
  components: {
    Pagination: Pagination,
    OrgSelect: OrgSelect
  },
  mixins: [initHeight, dict],
  computed: {},
  data: function data() {
    return {
      drawer: false,
      searchForm: {},
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      loading: false,
      highlightCurrent: true,
      detailLoading: false,
      sumList: [{
        displayText: "明细总数",
        displayValue: 0
      }, {
        displayText: "保单总数",
        displayValue: 0
      }, {
        displayText: "更新时间",
        displayValue: ''
      }],
      activeName: "All",
      allTableData: []
    };
  },
  mounted: function mounted() {
    this.loadData();
  },
  methods: {
    handleClick: function handleClick() {
      var _this = this;
      if (this.activeName !== 'All') {
        this.tableData = this.allTableData.filter(function (item) {
          return item.routingInspectionType == _this.activeName;
        });
      } else {
        this.tableData = _.cloneDeep(this.allTableData);
      }
    },
    // 重查
    retry: function retry(item) {
      var _this2 = this;
      activityCenterRequest.retry(item.itemId).then(function (res) {
        if (res.code == 200) {
          _this2.loadData();
          _this2.$message.success(res.msg);
        }
      });
    },
    breakData: function breakData() {
      this.loadData();
    },
    // 导出
    exportFn: function exportFn() {
      var exporParams = {
        total: this.total,
        fileName: '政策核对',
        exportUrl: '/oc-core-service/couponRoutingInspection/get',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    //重置搜索表单
    resetSearch: function resetSearch() {
      this.searchForm = {};
    },
    //搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    //获取列表数据
    loadData: function loadData() {
      var _this3 = this;
      this.loading = true;
      this.drawer = false;
      activityCenterRequest.getVerficationList().then(function (res) {
        if (res.code === 200) {
          _this3.allTableData = res.data.list;
          _this3.sumList = [{
            displayText: "明细总数",
            displayValue: res.data.detailTotal || 0
          }, {
            displayText: "保单总数",
            displayValue: res.data.policyTotal || 0
          }, {
            displayText: "更新时间",
            displayValue: res.data.modifiedTime || ""
          }];
          _this3.loading = false;
          _this3.handleClick();
          // this.total = res.data.page.recordsTotal;
        }
      }).catch(function (err) {
        _this3.loading = false;
      });
    }
  }
};