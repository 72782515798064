var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "app-m-cls" }, [
      _c(
        "div",
        { staticClass: "table-cls normal-btn-cls" },
        [
          _c(
            "el-tabs",
            {
              staticClass: "TopTabList",
              staticStyle: { background: "#fff" },
              on: { "tab-click": _vm.handleTabClick },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            _vm._l(_vm.tabsList, function (item, index) {
              return _c("el-tab-pane", {
                key: index,
                attrs: { label: item.className, name: item.classCode },
              })
            }),
            1
          ),
          _c(
            "el-drawer",
            {
              attrs: {
                title: "",
                visible: _vm.drawer,
                modal: false,
                "append-to-body": true,
                size: "370px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.drawer = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  staticStyle: { "margin-bottom": "15px" },
                  attrs: {
                    model: _vm.searchForm,
                    "label-width": "110px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 22 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "产品名称" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入产品名称",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.searchForm.productName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchForm, "productName", $$v)
                                  },
                                  expression: "searchForm.productName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 22 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "产品编码" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入产品编码",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.searchForm.productCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchForm, "productCode", $$v)
                                  },
                                  expression: "searchForm.productCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.activeName === "DBC" ||
                      _vm.activeName === "YB" ||
                      _vm.activeName === "SB"
                        ? _c(
                            "el-col",
                            { attrs: { span: 22 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "产品类型名称" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        size: "mini",
                                        clearable: "",
                                        placeholder: "请选择产品类型名称",
                                      },
                                      model: {
                                        value: _vm.searchForm.productTypeName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "productTypeName",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "searchForm.productTypeName",
                                      },
                                    },
                                    _vm._l(
                                      _vm.productTypeList,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label: item.itemName,
                                            value: item.itemCode,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-col",
                        { attrs: { span: 22 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "品牌车系" } },
                            [
                              _vm.activeName != "BZHG"
                                ? _c("CarBrands", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      carTypeValue: _vm.carTypeValue,
                                      labelWidth: "120px",
                                    },
                                    on: {
                                      carHandleChanged: _vm.CarHandleChange,
                                    },
                                  })
                                : _c("CarBrandsModel", {
                                    staticStyle: { width: "100%" },
                                    attrs: { carTypeValue: _vm.carTypeValue },
                                    on: {
                                      carHandleChanged: _vm.BZHGCarHandleChange,
                                    },
                                  }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 22 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "是否启用" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    size: "mini",
                                    clearable: "",
                                    placeholder: "请选择是否启用",
                                  },
                                  model: {
                                    value: _vm.searchForm.status,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchForm, "status", $$v)
                                    },
                                    expression: "searchForm.status",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "是", value: "PUTAWAY" },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "否", value: "SHELVE" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 22 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "保险公司" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    size: "mini",
                                    clearable: "",
                                    placeholder: "请选择保险公司",
                                  },
                                  model: {
                                    value: _vm.searchForm.supplierCode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "supplierCode",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.supplierCode",
                                  },
                                },
                                _vm._l(
                                  _vm.suppliersLists,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.supplierName,
                                        value: item.supplierCode,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 22 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "产品生效日期" } },
                            [
                              _c("DatePicker", {
                                attrs: {
                                  pickerDateTime: _vm.productDateTime,
                                  startDate: "effectiveStartTime",
                                  endDate: "effectiveEndTime",
                                  width: "100%",
                                },
                                on: {
                                  pickerDateChange: function ($event) {
                                    return _vm.getPickersDate(
                                      "productDateTime",
                                      $event
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c(
                          "div",
                          { staticClass: "searchBtnStyle" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "mini",
                                  icon: "el-icon-refresh-left",
                                },
                                on: { click: _vm.resetSearch },
                              },
                              [_vm._v("重置")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "mini",
                                  type: "primary",
                                  icon: "el-icon-search",
                                },
                                on: { click: _vm.searchData },
                              },
                              [_vm._v("查询")]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-card",
            { staticClass: "box-card", attrs: { shadow: "never" } },
            [
              _c(
                "div",
                {
                  staticClass: "search-box",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v("产品名称：")]),
                      _c("el-input", {
                        ref: "plateNo",
                        staticStyle: { width: "180px" },
                        attrs: {
                          size: "mini",
                          maxlength: "40",
                          placeholder: "请输入产品名称",
                          clearable: "",
                        },
                        model: {
                          value: _vm.searchForm.productName,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchForm, "productName", $$v)
                          },
                          expression: "searchForm.productName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v("产品编码：")]),
                      _c("el-input", {
                        ref: "plateNo",
                        staticStyle: { width: "180px" },
                        attrs: {
                          size: "mini",
                          maxlength: "40",
                          placeholder: "请输入产品编码",
                          clearable: "",
                        },
                        model: {
                          value: _vm.searchForm.productCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchForm, "productCode", $$v)
                          },
                          expression: "searchForm.productCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v("品牌车系：")]),
                      _vm.activeName != "BZHG"
                        ? _c("CarBrands", {
                            attrs: {
                              carTypeValue: _vm.carTypeValue,
                              labelWidth: "120px",
                            },
                            on: { carHandleChanged: _vm.CarHandleChange },
                          })
                        : _c("CarBrandsModel", {
                            attrs: { carTypeValue: _vm.carTypeValue },
                            on: { carHandleChanged: _vm.BZHGCarHandleChange },
                          }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", icon: "el-icon-refresh-left" },
                          on: { click: _vm.resetSearch },
                        },
                        [_vm._v("重置")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            size: "mini",
                            type: "primary",
                            icon: "el-icon-search",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.searchData()
                            },
                          },
                        },
                        [_vm._v("查询")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "search-btn" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: { click: _vm.refreshClicked },
                        },
                        [_vm._v("刷新")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: {
                            click: function ($event) {
                              return _vm.showAddDialogClicked("add")
                            },
                          },
                        },
                        [_vm._v("新增")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: { click: _vm.exportFn },
                        },
                        [_vm._v("导出")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: {
                            click: function ($event) {
                              _vm.drawer = true
                            },
                          },
                        },
                        [_vm._v("搜索")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  ref: "multipleTable",
                  staticStyle: { width: "100%" },
                  attrs: {
                    size: "mini",
                    border: "",
                    data: _vm.tableData,
                    height: _vm.tableHeightS,
                    "header-cell-style": { background: "#F7F7F7" },
                    "highlight-current-row": "",
                  },
                  on: { "selection-change": _vm.handleSelectionChange },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "productName",
                      label: "产品名称",
                      width: "180",
                      fixed: "left",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "productCode",
                      label: "产品编码",
                      "min-width": "140",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "supplierName",
                      label: "保险公司",
                      "min-width": "140",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.supplierName
                              ? _c("span", [
                                  _vm._v(
                                    " " + _vm._s(scope.row.supplierName) + " "
                                  ),
                                ])
                              : _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        scope.row.supplierCode
                                          ? _vm.filterSupplierCode(
                                              scope.row.supplierCode
                                            )
                                          : ""
                                      ) +
                                      " "
                                  ),
                                ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "vhcSeriesNo",
                      label: "车系",
                      "min-width": "140",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(" " + _vm._s(scope.row.vhcBrandNo) + "/"),
                            scope.row.vhcSeriesNo
                              ? _c("span", [
                                  _vm._v(_vm._s(scope.row.vhcSeriesNo)),
                                ])
                              : _vm._e(),
                            scope.row.vhcSeriesChineseName
                              ? _c("span", [
                                  _vm._v(
                                    "(" +
                                      _vm._s(scope.row.vhcSeriesChineseName) +
                                      ")"
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "effectiveStartTime",
                      label: "产品生效日期",
                      "min-width": "150",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "effectiveEndTime",
                      label: "产品失效日期",
                      "min-width": "150",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "",
                      label: "是否启用",
                      width: "100",
                      align: "center",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-switch", {
                              attrs: {
                                "active-color": "#409EFF",
                                "inactive-color": "#C0CCDA",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.updateStatus(
                                    scope.row,
                                    scope.row.status
                                  )
                                },
                              },
                              model: {
                                value: scope.row.status,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "status", $$v)
                                },
                                expression: "scope.row.status",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "",
                      label: "销售方式",
                      "min-width": "140",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return _vm._l(
                            scope.row.salesMethod,
                            function (item, index) {
                              return _c("span", { key: index }, [
                                _vm._v(_vm._s(item.description) + ","),
                              ])
                            }
                          )
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "nonautoTimeLimit",
                      label: "服务期限（月）",
                      "min-width": "140",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "scooterRent",
                      label: "代步车租金",
                      "min-width": "140",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.scooterRent
                                    ? parseFloat(scope.row.scooterRent).toFixed(
                                        2
                                      )
                                    : 0
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "costPrice",
                      label: "成本价-含税(元)",
                      align: "right",
                      "min-width": "160",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.costPrice
                                    ? parseFloat(scope.row.costPrice).toFixed(2)
                                    : 0
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "purchasePrice",
                      label: "DLR采购价-含税(元)",
                      align: "right",
                      "min-width": "140",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.purchasePrice
                                    ? parseFloat(
                                        scope.row.purchasePrice
                                      ).toFixed(2)
                                    : 0
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "retailPrice",
                      label: "DLR建议零售价-含税(元)",
                      align: "right",
                      "min-width": "180",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.retailPrice
                                    ? parseFloat(scope.row.retailPrice).toFixed(
                                        2
                                      )
                                    : 0
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "discountRate",
                      label: "DLR折扣率(%)",
                      "min-width": "140",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "createdTime",
                      label: "创建时间",
                      "min-width": "150",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      align: "center",
                      width: "150",
                      fixed: "right",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.status
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.detailConfig(
                                          "detail",
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("详情")]
                                )
                              : _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.editConfig("edit", scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("编辑")]
                                ),
                            !scope.row.status
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "danger",
                                      plain: "",
                                      size: "mini",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.deleteRoute(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("pagination", {
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.pageNum,
                  limit: _vm.listQuery.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageNum", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageSize", $event)
                  },
                  pagination: function ($event) {
                    return _vm.loadData()
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }