import "core-js/modules/es.array.concat.js";
/**
 * 批量查续保
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 批量检查续保
   */
  batchQueryRenewalTask: function batchQueryRenewalTask(data) {
    return request.post(getUrl("/batch/renewal/create", 1), data);
  },
  /**
   * 批次列表
   */
  getBatchList: function getBatchList(data) {
    return request.get(getUrl("/batch/renewal/search", 1), {
      params: data
    });
  },
  /**
   * 批次详情
   */
  getBatchDetailList: function getBatchDetailList(data) {
    return request.get(getUrl("/batch/renewal/itemSearch", 1), {
      params: data
    });
  },
  /**
   * 改变续保任务状态
   */
  changeRenewalState: function changeRenewalState(taskId, operate) {
    return request.post(getUrl("/batch/renewal/operate?taskId=".concat(taskId, "&operate=").concat(operate), 1));
  },
  /**
   * 重查
   */
  retry: function retry(itemId) {
    return request.post(getUrl("/batch/renewal/retry?itemId=".concat(itemId), 1));
  },
  /**
   * 续保结果列表
   */
  getRenewalResultList: function getRenewalResultList(data) {
    return request.get(getUrl("/batch/renewal/resultSearch", 1), {
      params: data
    });
  },
  /**
   * 新增
   */
  createRenewal: function createRenewal(data) {
    return request.post(getUrl("/batch/renewal/createByVin", 1), data);
  }
};
export default objApi;