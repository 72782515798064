var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("table", { staticStyle: { width: "100%" } }, [
        _c("colgroup", [
          _c("col", { staticStyle: { width: "35%" } }),
          _c("col", { staticStyle: { width: "35%" } }),
          _vm.currentInsCrop &&
          _vm.currentInsCrop.proposalStatus !=
            _vm.dictSource["INSURED_STATUS_DROPDOWN"][3].dictCode
            ? _c("col")
            : _vm._e(),
        ]),
        _c("thead", { staticStyle: { "background-color": "#e0efff" } }, [
          _vm.formRiskType && _vm.formRiskType == 2
            ? _c("tr", { staticStyle: { "font-size": "13px" } }, [
                _c("th", { attrs: { scope: "col" } }, [
                  _c("span", [_vm._v(" " + _vm._s(_vm.title) + " ")]),
                ]),
                _vm.currentInsCrop &&
                _vm.currentInsCrop.proposalStatus !=
                  _vm.dictSource["INSURED_STATUS_DROPDOWN"][3].dictCode
                  ? _c("th", { attrs: { scope: "col" } }, [
                      _vm._v("保险金额(¥)"),
                    ])
                  : _vm._e(),
              ])
            : _c("tr", { staticStyle: { "font-size": "13px" } }, [
                _c("th", { attrs: { scope: "col" } }, [
                  _c("span", [_vm._v(" " + _vm._s(_vm.title) + " ")]),
                ]),
                _c("th", { attrs: { scope: "col" } }, [_vm._v("保额/限额")]),
                _vm.currentInsCrop &&
                _vm.currentInsCrop.proposalStatus !=
                  _vm.dictSource["INSURED_STATUS_DROPDOWN"][3].dictCode
                  ? _c("th", { attrs: { scope: "col" } }, [_vm._v("保费(元)")])
                  : _vm._e(),
              ]),
        ]),
        _c(
          "tbody",
          _vm._l(_vm.checkedList, function (item, index) {
            return _c("tr", { key: index }, [
              _c("td", [_vm._v(_vm._s(item.riskkindName))]),
              !_vm.formRiskType || _vm.formRiskType != 2
                ? _c("td", [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "space-between",
                        },
                      },
                      [
                        item.riskkindCode == "A02"
                          ? _c(
                              "div",
                              { staticStyle: { width: "100%" } },
                              [
                                (
                                  _vm.policy
                                    ? _vm._.isEmpty(item.policyDutyDevices)
                                    : _vm._.isEmpty(
                                        item.proposalProductDutyDeviceUpdateVOList
                                      )
                                )
                                  ? _c("span", [
                                      _vm._v(_vm._s(item.unitAmount + "元")),
                                    ])
                                  : _vm._e(),
                                (
                                  _vm.policy
                                    ? !_vm._.isEmpty(item.policyDutyDevices)
                                    : !_vm._.isEmpty(
                                        item.proposalProductDutyDeviceUpdateVOList
                                      )
                                )
                                  ? _c(
                                      "el-popover",
                                      {
                                        attrs: {
                                          placement: "top",
                                          width: "600",
                                          trigger: "click",
                                        },
                                      },
                                      [
                                        _c(
                                          "table",
                                          { staticStyle: { width: "100%" } },
                                          [
                                            _c("colgroup", [
                                              _c("col", {
                                                staticStyle: { width: "25%" },
                                              }),
                                              _c("col", {
                                                staticStyle: { width: "15%" },
                                              }),
                                              _c("col", {
                                                staticStyle: { width: "15%" },
                                              }),
                                              _c("col", {
                                                staticStyle: { width: "22.5%" },
                                              }),
                                              _c("col", {
                                                staticStyle: { width: "22.5%" },
                                              }),
                                            ]),
                                            _c(
                                              "thead",
                                              {
                                                staticStyle: {
                                                  "background-color": "#eeee",
                                                },
                                              },
                                              [
                                                _c(
                                                  "tr",
                                                  {
                                                    staticStyle: {
                                                      "font-size": "13px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "th",
                                                      {
                                                        attrs: { scope: "col" },
                                                      },
                                                      [_vm._v("设备名称")]
                                                    ),
                                                    _c(
                                                      "th",
                                                      {
                                                        attrs: { scope: "col" },
                                                      },
                                                      [_vm._v("产地")]
                                                    ),
                                                    _c(
                                                      "th",
                                                      {
                                                        attrs: { scope: "col" },
                                                      },
                                                      [_vm._v("数量")]
                                                    ),
                                                    _c(
                                                      "th",
                                                      {
                                                        attrs: { scope: "col" },
                                                      },
                                                      [_vm._v("单价(元)")]
                                                    ),
                                                    _c(
                                                      "th",
                                                      {
                                                        attrs: { scope: "col" },
                                                      },
                                                      [_vm._v("购买日期")]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "tbody",
                                              _vm._l(
                                                _vm.policy
                                                  ? item.policyDutyDevices
                                                  : item.proposalProductDutyDeviceUpdateVOList,
                                                function (pitem, index) {
                                                  return _c(
                                                    "tr",
                                                    { key: index },
                                                    [
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            pitem.deviceName
                                                          )
                                                        ),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            pitem.madeChina
                                                              ? "国产"
                                                              : "进口"
                                                          )
                                                        ),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(pitem.quantity)
                                                        ),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            pitem.unitPrice
                                                          )
                                                        ),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            pitem.buyDate
                                                              ? pitem.buyDate.slice(
                                                                  0,
                                                                  10
                                                                )
                                                              : ""
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            attrs: { slot: "reference" },
                                            slot: "reference",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                  "align-items": "center",
                                                  "justify-content":
                                                    "space-between",
                                                },
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.unitAmount + "元"
                                                    )
                                                  ),
                                                ]),
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      color: "#2150A3",
                                                      cursor: "pointer",
                                                    },
                                                  },
                                                  [_vm._v("设备详情...")]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        item.riskkindCode == "A09" || item.riskkindCode == "B07"
                          ? _c(
                              "div",
                              { staticStyle: { width: "100%" } },
                              [
                                (
                                  _vm.policy
                                    ? _vm._.isEmpty(item.policyChargers)
                                    : _vm._.isEmpty(
                                        item.proposalProductChargerUpdateVOList
                                      )
                                )
                                  ? _c("span", [
                                      _vm._v(_vm._s(item.unitAmount + "元")),
                                    ])
                                  : _vm._e(),
                                (
                                  _vm.policy
                                    ? !_vm._.isEmpty(item.policyChargers)
                                    : !_vm._.isEmpty(
                                        item.proposalProductChargerUpdateVOList
                                      )
                                )
                                  ? _c(
                                      "el-popover",
                                      {
                                        attrs: {
                                          placement: "top",
                                          width: "720",
                                          trigger: "click",
                                        },
                                      },
                                      [
                                        _c(
                                          "table",
                                          { staticStyle: { width: "100%" } },
                                          [
                                            _c("colgroup", [
                                              _c("col", {
                                                staticStyle: { width: "20%" },
                                              }),
                                              _c("col", {
                                                staticStyle: { width: "10%" },
                                              }),
                                              _c("col", {
                                                staticStyle: { width: "10%" },
                                              }),
                                              _c("col", {
                                                staticStyle: { width: "10%" },
                                              }),
                                              _c("col", {
                                                staticStyle: { width: "20%" },
                                              }),
                                              _c("col", {
                                                staticStyle: { width: "10%" },
                                              }),
                                              _c("col", {
                                                staticStyle: { width: "10%" },
                                              }),
                                              _c("col", {
                                                staticStyle: { width: "10%" },
                                              }),
                                            ]),
                                            _c(
                                              "thead",
                                              {
                                                staticStyle: {
                                                  "background-color": "#eeee",
                                                },
                                              },
                                              [
                                                _c(
                                                  "tr",
                                                  {
                                                    staticStyle: {
                                                      "font-size": "13px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "th",
                                                      {
                                                        attrs: { scope: "col" },
                                                      },
                                                      [_vm._v("设备名称")]
                                                    ),
                                                    _c(
                                                      "th",
                                                      {
                                                        attrs: { scope: "col" },
                                                      },
                                                      [_vm._v("设备型号")]
                                                    ),
                                                    _c(
                                                      "th",
                                                      {
                                                        attrs: { scope: "col" },
                                                      },
                                                      [_vm._v("设备编码")]
                                                    ),
                                                    _c(
                                                      "th",
                                                      {
                                                        attrs: { scope: "col" },
                                                      },
                                                      [_vm._v("地址类型")]
                                                    ),
                                                    _c(
                                                      "th",
                                                      {
                                                        attrs: { scope: "col" },
                                                      },
                                                      [_vm._v("安装地址")]
                                                    ),
                                                    _c(
                                                      "th",
                                                      {
                                                        attrs: { scope: "col" },
                                                      },
                                                      [_vm._v("设备种类")]
                                                    ),
                                                    _c(
                                                      "th",
                                                      {
                                                        attrs: { scope: "col" },
                                                      },
                                                      [_vm._v("使用年限")]
                                                    ),
                                                    _c(
                                                      "th",
                                                      {
                                                        attrs: { scope: "col" },
                                                      },
                                                      [_vm._v("投保金额")]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "tbody",
                                              _vm._l(
                                                _vm.policy
                                                  ? item.policyChargers
                                                  : item.proposalProductChargerUpdateVOList,
                                                function (pitem, index) {
                                                  return _c(
                                                    "tr",
                                                    { key: index },
                                                    [
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            pitem.chargerName
                                                          )
                                                        ),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            pitem.chargerModel
                                                          )
                                                        ),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            pitem.chargerCode
                                                          )
                                                        ),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            pitem.chargerAddressTypeDisplay
                                                          )
                                                        ),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            pitem.chargerAddress
                                                          )
                                                        ),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            pitem.chargerKindDisplay
                                                          )
                                                        ),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            pitem.chargingPostYearLimit
                                                          )
                                                        ),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            pitem.amount.toLocaleString()
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            attrs: { slot: "reference" },
                                            slot: "reference",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                  "align-items": "center",
                                                  "justify-content":
                                                    "space-between",
                                                },
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.unitAmount + "元"
                                                    )
                                                  ),
                                                ]),
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      color: "#2150A3",
                                                      cursor: "pointer",
                                                    },
                                                  },
                                                  [_vm._v("充电桩详情...")]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        item.riskkindCode == "A"
                          ? _c(
                              "div",
                              [
                                item.riskkindCode == "A"
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          item.amount
                                            ? item.amount.toLocaleString()
                                            : item.unitAmount || 0
                                        ) + " 元"
                                      ),
                                    ])
                                  : _vm._e(),
                                item.riskkindCode == "A" && _vm.showModifyButton
                                  ? _c(
                                      "el-button",
                                      {
                                        staticStyle: { "margin-left": "5px" },
                                        attrs: {
                                          type: "primary",
                                          icon: "el-icon-edit",
                                          plain: "",
                                          size: "mini",
                                        },
                                        on: { click: _vm.modifyAmount },
                                      },
                                      [_vm._v("调整保额")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        item.riskkindCode == "A"
                          ? _c("span", [
                              _vm._v(
                                "绝对免赔额 " +
                                  _vm._s(
                                    item.absoluteDeductibleAmount
                                      ? item.absoluteDeductibleAmount
                                      : 0
                                  ) +
                                  " 元"
                              ),
                            ])
                          : _vm._e(),
                        item.riskkindCode == "D" ||
                        item.riskkindCode == "D02" ||
                        (item.riskkindCode == "D03" &&
                          (!item.shareAmount ||
                            (_vm.currentInsCrop &&
                              _vm._.toLower(_vm.currentInsCrop.insCorpCode) ==
                                "picc" &&
                              !(
                                ["310100"].includes(
                                  _vm.currentInsCrop.insCityCode
                                ) ||
                                _vm.currentInsCrop.insCityCode.indexOf("34") ==
                                  0 ||
                                _vm.currentInsCrop.insCityCode.indexOf("35") ==
                                  0 ||
                                _vm.currentInsCrop.insCityCode.indexOf("43") ==
                                  0 ||
                                _vm.currentInsCrop.insCityCode.indexOf("44") ==
                                  0 ||
                                _vm.currentInsCrop.insCityCode.indexOf("36") ==
                                  0 ||
                                _vm.currentInsCrop.insCityCode.indexOf("46") ==
                                  0 ||
                                _vm.currentInsCrop.insCityCode.indexOf("51") ==
                                  0 ||
                                _vm.currentInsCrop.insCityCode.indexOf("64") ==
                                  0
                              ))))
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  (item.unitAmount > 0
                                    ? _vm.formatOption(item.unitAmount)
                                    : "0") +
                                    "元" +
                                    " × " +
                                    item.quantity +
                                    "座 "
                                )
                              ),
                            ])
                          : _vm._e(),
                        _vm._.indexOf(
                          ["FZ01", "FZ02", "FZ03", "FZ04"],
                          item.riskkindCode
                        ) != -1
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._.toUpper(_vm.source) == "ADD" ||
                                    _vm._.toUpper(_vm.source) == "INSURED"
                                    ? _vm.getPolicyDutyServicetermses(item)
                                    : _vm.getInsureDutyServicetermses(item)
                                )
                              ),
                            ])
                          : _vm._e(),
                        _vm._.indexOf(
                          ["D01", "B03", "C01", "A05"],
                          item.riskkindCode
                        ) != -1
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._.toUpper(_vm.source) == "ADD"
                                    ? item.unitAmount
                                      ? item.unitAmount + "%"
                                      : ""
                                    : item.absoluteDeductible
                                    ? item.absoluteDeductible + "%"
                                    : ""
                                )
                              ),
                            ])
                          : _vm._e(),
                        item.riskkindCode == "A04"
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  (item.unitAmount > 0
                                    ? _vm.formatOption(item.unitAmount)
                                    : "0") +
                                    "元" +
                                    " × " +
                                    item.quantity +
                                    "天"
                                )
                              ),
                            ])
                          : _vm._e(),
                        (_vm._.indexOf(
                          ["FZ01", "FZ02", "FZ03", "FZ04"],
                          item.riskkindCode
                        ) == -1 &&
                          _vm._.indexOf(
                            [
                              "D01",
                              "B03",
                              "C01",
                              "A02",
                              "A04",
                              "A05",
                              "A08",
                              "A09",
                              "B07",
                              "C03",
                              "D03",
                              "B04",
                              "D02",
                              "A03",
                            ],
                            item.riskkindCode
                          ) == -1 &&
                          item.riskkindCode != "A" &&
                          item.riskkindCode != "D") ||
                        (["C03", "B04"].includes(item.riskkindCode) &&
                          (!item.shareAmount ||
                            (_vm.currentInsCrop &&
                              _vm._.toLower(_vm.currentInsCrop.insCorpCode) ==
                                "picc" &&
                              !(
                                ["310100"].includes(
                                  _vm.currentInsCrop.insCityCode
                                ) ||
                                _vm.currentInsCrop.insCityCode.indexOf("34") ==
                                  0 ||
                                _vm.currentInsCrop.insCityCode.indexOf("35") ==
                                  0 ||
                                _vm.currentInsCrop.insCityCode.indexOf("43") ==
                                  0 ||
                                _vm.currentInsCrop.insCityCode.indexOf("44") ==
                                  0 ||
                                _vm.currentInsCrop.insCityCode.indexOf("36") ==
                                  0 ||
                                _vm.currentInsCrop.insCityCode.indexOf("46") ==
                                  0 ||
                                _vm.currentInsCrop.insCityCode.indexOf("51") ==
                                  0 ||
                                _vm.currentInsCrop.insCityCode.indexOf("64") ==
                                  0
                              ))))
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  (item.unitAmount > 0
                                    ? _vm.formatOption(item.unitAmount)
                                    : "0") + "元"
                                )
                              ),
                            ])
                          : _vm._e(),
                        _vm._.toUpper(_vm.source) != "ADD" &&
                        ["C03", "D03", "B04"].includes(item.riskkindCode) &&
                        item.shareAmount &&
                        _vm.currentInsCrop &&
                        _vm._.toLower(_vm.currentInsCrop.insCorpCode) != "picc"
                          ? _c("span", [_vm._v("共享保额")])
                          : _vm._e(),
                        _vm._.toUpper(_vm.source) != "ADD" &&
                        ["C03", "D03", "B04"].includes(item.riskkindCode) &&
                        item.shareAmount &&
                        _vm.currentInsCrop &&
                        _vm._.toLower(_vm.currentInsCrop.insCorpCode) ==
                          "picc" &&
                        (["310100"].includes(_vm.currentInsCrop.insCityCode) ||
                          _vm.currentInsCrop.insCityCode.indexOf("34") == 0 ||
                          _vm.currentInsCrop.insCityCode.indexOf("35") == 0 ||
                          _vm.currentInsCrop.insCityCode.indexOf("43") == 0 ||
                          _vm.currentInsCrop.insCityCode.indexOf("44") == 0 ||
                          _vm.currentInsCrop.insCityCode.indexOf("36") == 0 ||
                          _vm.currentInsCrop.insCityCode.indexOf("46") == 0 ||
                          _vm.currentInsCrop.insCityCode.indexOf("51") == 0 ||
                          _vm.currentInsCrop.insCityCode.indexOf("64") == 0)
                          ? _c("span", [_vm._v("共享保额")])
                          : _vm._e(),
                        _vm._.toUpper(_vm.source) == "ADD" &&
                        ["C03", "D03", "B04"].includes(item.riskkindCode) &&
                        item.shareAmount
                          ? _c("span", [_vm._v("共享保额")])
                          : _vm._e(),
                        item.riskkindCode == "A08"
                          ? _c("span", [_vm._v("同车损保额")])
                          : _vm._e(),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm.currentInsCrop &&
              _vm.currentInsCrop.proposalStatus !=
                _vm.dictSource["INSURED_STATUS_DROPDOWN"][3].dictCode
                ? _c("td", [
                    _vm._v(_vm._s(item.actualPremium.toLocaleString())),
                  ])
                : _vm._e(),
            ])
          }),
          0
        ),
      ]),
      !_vm._.isEmpty(_vm.dutyData.additionalRisk) &&
      _vm.dutyData.additionalRisk.mainRisk.length > 0
        ? _c("table-form", {
            staticClass: "table--border-top",
            attrs: { dutyData: _vm.dutyData.additionalRisk },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }