import _createForOfIteratorHelper from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.splice.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import storageRequest from '@/api/storage'; //上传文件
import repairApi from '@/api/repair';
export default {
  // mixins: [dict],
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    vehicleLossId: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      form: {
        fixedAmount: '',
        fixedItem: ''
      },
      fileList: []
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    uploadFile: function uploadFile(param) {
      var _this = this;
      //上传文件
      var formData = new FormData();
      formData.append("file", param.file);
      storageRequest.uploadFile(formData).then(function (res) {
        if (res.code === 200) {
          _this.$message({
            message: res.msg,
            type: 'success'
          });
          var obj = {
            name: param.file.name,
            //	文件名
            url: res.data.fileUrl,
            id: res.data.fileStorageId
          };
          _this.fileList.push(obj);
        }
      });
    },
    handleRemove: function handleRemove(file, fileList) {
      var index = _.findIndex(this.fileList, {
        'uid': file.uid
      });
      this.fileList.splice(index, 1);
    },
    handlePreview: function handlePreview(file) {
      console.log(file);
    },
    initData: function initData() {
      //初始化数据
      this.$refs['detail'].resetFields();
    },
    save: function save() {
      var _this2 = this;
      //保存数据
      this.$refs['detail'].validate(function (valid) {
        if (valid) {
          var fileLists = [];
          var _iterator = _createForOfIteratorHelper(_this2.fileList),
            _step;
          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var i = _step.value;
              fileLists.push(i.id);
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }
          _this2.form.fileList = fileLists;
          repairApi.fiexdOrder(_this2.vehicleLossId, _this2.form).then(function (res) {
            if (res.code == 200) {
              _this2.$message({
                message: res.msg,
                type: 'success'
              });
              _this2.closePage();
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    closePage: function closePage() {
      //关闭弹窗
      this.$refs['detail'].resetFields();
      this.$emit('closePage');
    }
  }
};