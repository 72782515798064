import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
export var guangXi = function guangXi(addressDetail, cityAreaTree) {
  var addressInfo = _.cloneDeep(addressDetail);
  var fullAddress = [];
  var regionCode = [];
  var excludeMunicipalityStr = null;
  if (addressInfo.indexOf('广西壮族自治区') !== -1) {
    excludeMunicipalityStr = addressInfo.split('广西壮族自治区')[1];
  } else {
    excludeMunicipalityStr = addressInfo.split('广西')[1];
  }
  // console.log('除自治区以外字符串', excludeMunicipalityStr)
  // return
  // 获取省下面的城市
  var pItem = _.find(cityAreaTree, {
    label: '广西壮族自治区'
  });
  fullAddress.push(pItem.value);

  // 获取省下面的市级，县级市
  if (pItem && excludeMunicipalityStr.indexOf('市') !== -1) {
    var cityName = excludeMunicipalityStr.split('市')[0];
    // console.log('结尾为市的城市cityName', cityName)
    var cItem = _.find(pItem.children, {
      label: cityName + '市'
    });
    fullAddress.push(cItem && cItem.value);
    // console.log('cItem', cItem)
    // console.log('市区后面字符串1', excludeMunicipalityStr.split('市')[0])
    // console.log('市区后面字符串2', excludeMunicipalityStr.split('市')[1])
    var countyNameList = ['市', '区', '县'];
    var countyLevelCityName = null;
    // 市需要单独处理
    if (excludeMunicipalityStr.split('市') && excludeMunicipalityStr.split('市').length > 2) {
      countyLevelCityName = excludeMunicipalityStr.split('市')[1] + '市';
    } else {
      countyLevelCityName = excludeMunicipalityStr.split('市')[1];
    }
    var districtCountylist = [];
    for (var _i = 0, _countyNameList = countyNameList; _i < _countyNameList.length; _i++) {
      var countyName = _countyNameList[_i];
      if (cItem && countyLevelCityName.indexOf(countyName) !== -1) {
        var districtCountyName = countyLevelCityName.split(countyName)[0];
        // console.log('districtCountyName', districtCountyName)
        var districtCountyItem = _.find(cItem.children, {
          label: districtCountyName + countyName
        });
        // console.log('districtCountyItem', districtCountyItem)
        // 存在 '中旗'，'后旗','旗' 三个结尾的重复的，取第一个就行
        if (districtCountyItem) {
          districtCountylist.push(districtCountyItem);
        }
      }
    }
    if (!_.isEmpty(districtCountylist)) {
      fullAddress.push(districtCountylist[0].value);
      regionCode = districtCountylist[0].value;
    }
  }
  return {
    fullAddress: fullAddress,
    regionCode: regionCode
  };
};