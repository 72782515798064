var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "table",
      { staticStyle: { width: "100%" }, attrs: { border: "1" } },
      [
        _c("colgroup", [
          _c("col", { staticStyle: { width: "10%" } }),
          _vm.insCorpCode &&
          _vm._.toLower(_vm.insCorpCode) != "picc" &&
          _vm._.toLower(_vm.insCorpCode) != "cpic"
            ? _c("col", { staticStyle: { width: "10%" } })
            : _vm._e(),
          _c("col", { staticStyle: { width: "15%" } }),
          _vm.insCorpCode &&
          _vm._.toLower(_vm.insCorpCode) != "cpic" &&
          _vm._.toLower(_vm.insCorpCode) != "paic"
            ? _c("col", { staticStyle: { width: "10%" } })
            : _vm._e(),
          _c("col", { staticStyle: { width: "20%" } }),
          _c("col", { staticStyle: { width: "5%" } }),
          _c("col", { staticStyle: { width: "5%" } }),
          _vm.proposalStatus &&
          _vm.proposalStatus !=
            _vm.dictSource["INSURED_STATUS_DROPDOWN"][3].dictCode
            ? _c("col", { staticStyle: { width: "8%" } })
            : _vm._e(),
          _c("col", { staticStyle: { width: "18%" } }),
        ]),
        _c("thead", { staticStyle: { "background-color": "#e0efff" } }, [
          _c("tr", { staticStyle: { "font-size": "13px" } }, [
            _c("th", { attrs: { scope: "col" } }, [_vm._v("分类名称")]),
            _vm.insCorpCode &&
            _vm._.toLower(_vm.insCorpCode) != "picc" &&
            _vm._.toLower(_vm.insCorpCode) != "cpic"
              ? _c("th", { attrs: { scope: "col" } }, [_vm._v("险种类型编码")])
              : _vm._e(),
            _c("th", { attrs: { scope: "col" } }, [_vm._v("险种类型名称")]),
            _vm.insCorpCode &&
            _vm._.toLower(_vm.insCorpCode) != "cpic" &&
            _vm._.toLower(_vm.insCorpCode) != "paic"
              ? _c("th", { attrs: { scope: "col" } }, [_vm._v("非车产品编码")])
              : _vm._e(),
            _c("th", { attrs: { scope: "col" } }, [_vm._v("非车产品名称")]),
            _c(
              "th",
              {
                staticStyle: { "text-align": "left" },
                attrs: { scope: "col" },
              },
              [_vm._v("份数")]
            ),
            _c(
              "th",
              {
                staticStyle: { "text-align": "left" },
                attrs: { scope: "col" },
              },
              [_vm._v("单价")]
            ),
            _vm.proposalStatus &&
            _vm.proposalStatus !=
              _vm.dictSource["INSURED_STATUS_DROPDOWN"][3].dictCode
              ? _c(
                  "th",
                  {
                    staticStyle: { "text-align": "left" },
                    attrs: { scope: "col" },
                  },
                  [_vm._v("保费")]
                )
              : _vm._e(),
            _c("th", { attrs: { scope: "col" } }, [_vm._v("驾意险起止日期")]),
          ]),
        ]),
        _vm._l(_vm.listData, function (item, index) {
          return _c("tbody", { key: index }, [
            _c("tr", [
              _c("td", [_vm._v(_vm._s(item.categoryName))]),
              _vm.insCorpCode &&
              _vm._.toLower(_vm.insCorpCode) != "picc" &&
              _vm._.toLower(_vm.insCorpCode) != "cpic"
                ? _c("td", [_vm._v(_vm._s(item.categoryTypeCode))])
                : _vm._e(),
              _c("td", [_vm._v(_vm._s(item.categoryTypeName))]),
              _vm.insCorpCode &&
              _vm._.toLower(_vm.insCorpCode) != "cpic" &&
              _vm._.toLower(_vm.insCorpCode) != "paic"
                ? _c("td", [_vm._v(_vm._s(item.productCode))])
                : _vm._e(),
              _c(
                "td",
                [
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        placement: "bottom",
                        width: "500",
                        trigger: "click",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            color: "#1890ff",
                            "text-align": "center",
                            "font-weight": "bold",
                            "font-size": "15px",
                            "padding-bottom": "5px",
                          },
                        },
                        [_vm._v("(" + _vm._s(item.productName) + ") 保障明细")]
                      ),
                      !_vm._.isEmpty(item.detailList)
                        ? _c("table", { staticStyle: { width: "100%" } }, [
                            _c("colgroup", [
                              _c("col", { staticStyle: { width: "80%" } }),
                              _c("col", { staticStyle: { width: "20%" } }),
                            ]),
                            _c(
                              "thead",
                              { staticStyle: { background: "#f9f9f9" } },
                              [
                                _c(
                                  "tr",
                                  {
                                    staticStyle: {
                                      "font-size": "13px",
                                      color: "#808080",
                                    },
                                  },
                                  [
                                    _c(
                                      "th",
                                      {
                                        staticStyle: { "text-align": "left" },
                                        attrs: { scope: "col" },
                                      },
                                      [_vm._v("险种名称")]
                                    ),
                                    _c(
                                      "th",
                                      {
                                        staticStyle: { "text-align": "left" },
                                        attrs: { scope: "col" },
                                      },
                                      [_vm._v("保险金额")]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "tbody",
                              _vm._l(item.detailList, function (titem, index) {
                                return _c(
                                  "tr",
                                  {
                                    key: index,
                                    staticStyle: {
                                      "font-size": "13px",
                                      color: "#808080",
                                    },
                                  },
                                  [
                                    _c(
                                      "td",
                                      {
                                        staticStyle: { "text-align": "left" },
                                        attrs: { scope: "col" },
                                      },
                                      [_vm._v(_vm._s(titem.name))]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticStyle: { "text-align": "left" },
                                        attrs: { scope: "col" },
                                      },
                                      [_vm._v(_vm._s(titem.amount))]
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                          ])
                        : _c(
                            "div",
                            {
                              staticStyle: {
                                color: "#808080",
                                "text-align": "center",
                              },
                            },
                            [_vm._v(" 暂无数据 ")]
                          ),
                      _c(
                        "span",
                        {
                          staticStyle: { color: "#1890ff", cursor: "pointer" },
                          attrs: { slot: "reference" },
                          slot: "reference",
                        },
                        [
                          _vm._v(_vm._s(item.productName) + " "),
                          _c("i", {
                            staticClass: "el-icon-warning-outline",
                            staticStyle: {
                              "font-size": "16px",
                              margin: "0 5px",
                              cursor: "pointer",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c("td", { staticStyle: { "text-align": "left" } }, [
                _vm._v(_vm._s(item.quantity)),
              ]),
              _c("td", { staticStyle: { "text-align": "left" } }, [
                _vm._v(_vm._s(item.unitPremium)),
              ]),
              _vm.proposalStatus &&
              _vm.proposalStatus !=
                _vm.dictSource["INSURED_STATUS_DROPDOWN"][3].dictCode
                ? _c("td", { staticStyle: { "text-align": "left" } }, [
                    _vm._v(
                      _vm._s(
                        (
                          parseInt(item.quantity) * parseFloat(item.unitPremium)
                        ).toFixed(2)
                      )
                    ),
                  ])
                : _vm._e(),
              _c("td", [
                _vm._v(
                  _vm._s(
                    _vm.getEffectiveStartTime(
                      _vm.currentNonInsDetail.effectiveStartTime
                    )
                  ) +
                    "-" +
                    _vm._s(
                      _vm.getEffectiveEndTime(
                        _vm.currentNonInsDetail.effectiveEndTime
                      )
                    )
                ),
              ]),
            ]),
          ])
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }