import "core-js/modules/es.array.concat.js";
/**
 * 新车销售清单
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 列表
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   * @param {*} data 内容
   */
  getList: function getList(pageNum, pageSize, data) {
    return request.get(getUrl("/vehicle/sales/list?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 10), {
      params: data
    });
  },
  /**
   * 新增 
   * @param {*} data 内容
   */
  add: function add(data) {
    return request.post(getUrl("/vehicle/sales/add", 10), data);
  },
  /**
   * 修改
   * @param {*} data 内容
   */
  edit: function edit(data) {
    return request.post(getUrl("/vehicle/sales/update", 10), data);
  },
  /**
   * 批量删除
   * @param {*} ids
   */
  delete: function _delete(ids) {
    return request.post(getUrl("/vehicle/sales/deletes?salesIds=".concat(ids), 10));
  },
  /**
   * 上传
   */
  importExcel: function importExcel(data) {
    return request({
      method: 'post',
      url: getUrl("/vehicle/sales/export", 10),
      headers: {
        'content-type': 'multipart/form-data'
      },
      data: data
    });
  },
  /**
   * 下载excel模板
   */
  download: function download() {
    return request({
      method: 'get',
      url: getUrl("/vehicle/sales/downloadTemplate", 10),
      responseType: 'blob'
    });
  },
  /**
   * 根据车架号查询信息
   * @param {*} orgCode 组织机构编码
   * @param {*} vin 车架号
   */
  getNewCarSales: function getNewCarSales(orgCode, vin) {
    return request.get(getUrl("/vehicle/sales/vin?orgCode=".concat(orgCode, "&vin=").concat(vin), 10));
  },
  /**
   * 批量导入
   * @param {*} data 内容
   */
  importList: function importList(data) {
    return request.post(getUrl("/vehicle/sales/import", 10), data);
  },
  /**
   * 分页查询导入模板表
   * @param {*} data 内容
   */
  getConfigureList: function getConfigureList(pageNum, pageSize, data) {
    return request.get(getUrl("/import/templates?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 9), {
      params: data
    });
  },
  /**
   * 新增导入模板表数据
   * @param {*} data 内容
   */
  addConfigure: function addConfigure(data) {
    return request.post(getUrl("/import/templates", 9), data);
  },
  /**
   * 获取指定导入类别的模板属性
   * @param {*} type
   */
  getImportTemplates: function getImportTemplates(type) {
    return request.get(getUrl("/import/templates/default/attr?importCategory=".concat(type), 9));
  },
  /**
   * 查询某一条导入模板表数据
   * @param {*} id
   */
  viewConfigure: function viewConfigure(id) {
    return request.get(getUrl("/import/templates/".concat(id), 9));
  },
  /**
   * 更新导入模板表数据
   * @param {*} id
   * @param {*} data 内容
   */
  editConfigure: function editConfigure(id, data) {
    return request.put(getUrl("/import/templates/".concat(id), 9), data);
  },
  /**
   * 批量删除导入模板表数据
   * @param {*} ids
   */
  removeConfigure: function removeConfigure(ids) {
    return request.delete(getUrl("/import/templates/".concat(ids), 9));
  },
  /**
   * 根据id查询新车销售数据
   * @param {*} id
   */
  getVehicleSalesDeatils: function getVehicleSalesDeatils(id) {
    return request.get(getUrl("/vehicle/sales/get/".concat(id), 10));
  },
  /**
   * 刷新承保接口
   * @param {*} vin
   */
  refreshInsured: function refreshInsured(vin) {
    return request.get(getUrl("/new/policy/pushByVin?vin=".concat(vin), 1));
  },
  // ==========================================无隔离数据接口==========================================
  /**
   * 获取车辆销售记录列表分页(无隔离)
   * @param {*} vin
   */
  getVehicleSalesAll: function getVehicleSalesAll(data) {
    return request.get(getUrl("/vehicle/sales/all", 10), {
      params: data
    });
  }
};
export default objApi;