import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.find.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PageContainer from "@/components/PageContainer";
import FormLabel from "@/components/FormSubLabel";
import requestOrg from '@/api/orgChildren';
import requestPolicy from '@/api/policy';
import SYX_RISK from '@/assets/json/SYX_RISK'; //获取商业险险种
import dict from '@/mixins/dict';
import { getSupplyers } from '@/utils/common';
export default {
  mixins: [dict],
  data: function data() {
    return {
      value: '',
      dictList: [],
      disabledLessThan: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      form: {},
      formRules: {
        salesOrgName: {
          required: true,
          message: '请选择业务机构',
          trigger: 'change'
        },
        salemanName: {
          required: true,
          message: '请选择业务员',
          trigger: 'change'
        },
        insCorpCode: {
          required: true,
          message: '请选择投保公司',
          trigger: 'change'
        },
        address: {
          required: true,
          message: '请选择投保地区',
          trigger: 'change'
        },
        insuredTime: {
          required: true,
          message: '请选择承保日期',
          trigger: 'change'
        },
        insuredType: {
          required: true,
          message: '请选择出单方式',
          trigger: 'change'
        },
        bzRiskNo: {
          required: true,
          message: '请输入交强险保单号',
          trigger: 'blur'
        },
        bzPremium: {
          required: true,
          message: '请输入交强险金额',
          trigger: 'blur'
        },
        bsRiskNo: {
          required: true,
          message: '请输入商业险保单号',
          trigger: 'blur'
        },
        bsPremium: {
          required: true,
          message: '请输入商业险总金额',
          trigger: 'blur'
        },
        renewalType: {
          required: true,
          message: '请选择续保标识',
          trigger: 'change'
        },
        quoteVehicle: {
          ownerName: {
            required: true,
            message: '请输入车主名',
            trigger: 'blur'
          },
          ownerCertificateType: {
            required: true,
            message: '请选择证件类型',
            trigger: 'change'
          },
          ownerCertificateNo: {
            required: true,
            message: '请输入车主证件号',
            trigger: 'blur'
          },
          ownerMobile: {
            required: true,
            message: '请输入车主联系方式',
            trigger: 'blur'
          },
          plateNo: {
            required: true,
            message: '请输入车牌号',
            trigger: 'blur'
          },
          registerDate: {
            required: true,
            message: '请选择注册日期',
            trigger: 'change'
          },
          vehicleType: {
            required: true,
            message: '请选择行驶车辆类型',
            trigger: 'change'
          },
          useAttribute: {
            required: true,
            message: '请选择使用性质',
            trigger: 'change'
          },
          plateType: {
            required: true,
            message: '请选择号牌种类',
            trigger: 'change'
          }
        },
        applicant: {
          name: {
            required: true,
            message: '请输入名称',
            trigger: 'blur'
          },
          certificateType: {
            required: true,
            message: '请选择证件类型',
            trigger: 'change'
          },
          certificateNo: {
            required: true,
            message: '请输入证件号',
            trigger: 'blur'
          },
          mobile: {
            required: true,
            message: '请输入联系方式',
            trigger: 'blur'
          }
        },
        insurant: {
          name: {
            required: true,
            message: '请输入名称',
            trigger: 'blur'
          },
          certificateType: {
            required: true,
            message: '请选择证件类型',
            trigger: 'change'
          },
          certificateNo: {
            required: true,
            message: '请输入证件号',
            trigger: 'blur'
          },
          mobile: {
            required: true,
            message: '请输入联系方式',
            trigger: 'blur'
          }
        },
        bzRiskKind: {
          effectiveStartTime: {
            required: true,
            message: '请选择交强险起保日期',
            trigger: 'change'
          },
          effectiveEndTime: {
            required: true,
            message: '请选择交强险终保日期',
            trigger: 'change'
          }
        },
        bsRiskKind: {
          effectiveStartTime: {
            required: true,
            message: '请选择商业险起保日期',
            trigger: 'change'
          },
          effectiveEndTime: {
            required: true,
            message: '请选择商业险终保日期',
            trigger: 'change'
          }
        },
        quoteVehicleTax: {
          curYearTax: {
            required: true,
            message: '请输入车船税当年应缴',
            trigger: 'blur'
          },
          lastYearPay: {
            required: true,
            message: '请输入车船税往年补缴',
            trigger: 'blur'
          },
          delayPayMoney: {
            required: true,
            message: '请输入车船税往年补缴',
            trigger: 'blur'
          }
        }
      },
      activeOne: '1',
      activeTwo: '1',
      activeThree: '1',
      activeFour: '1',
      activeFive: '1',
      activeSix: '1',
      activeSeven: '1',
      activeEight: '1',
      highlightCurrent: true,
      orgData: [],
      defaultProps: {
        children: 'children',
        label: 'fullName'
      },
      VEHICLE_KINDS: [],
      PLATE_TYPE: [],
      ENGRGY_TYPES: [],
      USE_ATTRIBUTE: [],
      VI_CERTIFICATE_TYPE: [],
      supplyers: [],
      personList: [],
      regionOptions: JSON.parse(localStorage.getItem('VI_CityAreaTree')) || [],
      brandSeriesList: [],
      brandModeList: [],
      showInsuranceType: "",
      dutyAllCode: []
    };
  },
  components: {
    PageContainer: PageContainer,
    FormLabel: FormLabel
  },
  created: function created() {
    this.initForm();
    this.dutyAllCode = SYX_RISK; //获取商业险险别
  },
  mounted: function mounted() {
    var _this = this;
    //假数据
    this.supplyers = getSupplyers();
    if (this.supplyers) {
      _.remove(this.supplyers, function (o) {
        return o.code == 'QITA' || o.code == 'qita';
      });
    }
    this.VEHICLE_KINDS = this.VEHICLE_KIND = JSON.parse(localStorage.getItem("VI_VEHICLE_KIND")) || [];
    this.PLATE_TYPE = JSON.parse(localStorage.getItem("VI_PLATE_TYPE")) || [];
    var engrgy = JSON.parse(localStorage.getItem("VI_ENGRGY_TYPE")) || [];
    this.ENGRGY_TYPES = this.ENGRGY_TYPE = _.filter(engrgy, function (o) {
      return ["0", "1", "2", "3", "4"].includes(o.dictCode);
    });
    this.USE_ATTRIBUTE = JSON.parse(localStorage.getItem("VI_USE_ATTRIBUTE")) || [];
    this.VI_CERTIFICATE_TYPE = JSON.parse(localStorage.getItem('VI_CERTIFICATE_TYPE')) || [];
    var parentId = 0; //0为管理员
    requestOrg.getTree(parentId).then(function (res) {
      if (res.data) {
        _this.orgData = [res.data];
      }
    });
  },
  methods: {
    initForm: function initForm() {
      this.form = {
        bsRiskNo: "",
        bzRiskNo: "",
        bsDiscountFactor: "",
        bsPremium: "",
        bzDiscountFactor: "",
        bzPremium: "",
        insCityCode: "",
        insCorpCode: "",
        insCorpName: "",
        insuredTime: "",
        insuredType: "",
        address: [],
        insCityName: "",
        //投保地区
        quoteDutys: [],
        noCarInsurance: [],
        uploadFiles: [],
        salesOrgName: "",
        ownerName: "",
        quoteVehicle: {
          isNewCar: false
        },
        quoteInsureds: [],
        //报价关系人信息
        quoteProducts: [],
        //报价险种信息
        quoteExpand: [],
        //报价单增值服务信息
        quoteVehicleTax: {},
        //交强险-车船税信息
        bsRiskKind: {},
        //商业险
        bzRiskKind: {},
        //交强险
        applicant: {
          relationshipFlag: "Applicant",
          applicantSameAsOwner: false //投保人同车主标识
        },

        //投保人
        insurant: {
          relationshipFlag: "Insurant",
          sameAsOwner: false,
          //被保人同车主标识
          sameAsInsurer: false //被保人同投保人标识
        } //被保人
      };
    },
    handleRiskItem: function handleRiskItem(item) {
      //险种选择
      item.unitAmount = '';
    },
    selectInsuranceType: function selectInsuranceType(val) {
      //选择出单方式
      this.showInsuranceType = val;
    },
    handleCheckedCarOwner: function handleCheckedCarOwner() {
      //是否同车主
      if (!this.form.quoteVehicle.ownerName) {
        this.form.applicant.applicantSameAsOwner = false;
        this.form.insurant.sameAsOwner = false;
        this.$message({
          type: "error",
          message: "车主名不能为空"
        });
      }
    },
    handleCheckedApplicant: function handleCheckedApplicant() {
      //是否同投保人
      if (!this.form.applicant.name) {
        this.form.insurant.sameAsInsurer = false;
        this.$message({
          type: "error",
          message: "投保人姓名不能为空"
        });
      }
    },
    brandSeriesByBrand: function brandSeriesByBrand(val) {
      //厂牌
      this.brandSeriesList = [];
      this.brandSeriesList.push({
        dictCode: "0",
        dictName: '其他'
      });
    },
    brandModeByBrandSeries: function brandModeByBrandSeries() {
      //厂牌车系
      this.brandModeList = [];
      this.brandModeList.push({
        dictCode: "0",
        dictName: '其他车型'
      });
    },
    handleChange: function handleChange(val) {
      //选择省/市/区
      var address = this.$refs['address'].getCheckedNodes()[0].pathLabels;
      this.form.insCityName = address[0];
      this.form.address = val;
    },
    handleNodeClick: function handleNodeClick(data, node, target) {
      //节点点击事件
      this.form.salesOrgName = data.fullName;
      this.form.operateOrgId = data.organizationId;
      this.$refs['orgSelect'].blur();
      this.$forceUpdate();
      //假数据
      this.personList = [];
      for (var i = 0; i < 5; i++) {
        this.personList.push({
          dictName: "\u4E1A\u52A1\u5458".concat(i + 1),
          dictCode: i
        });
      }
    },
    // 未上牌勾选事件
    noPlateNoChange: function noPlateNoChange(val) {
      this.$forceUpdate();
    },
    changeValue: function changeValue(val) {
      //刷新视图
      this.$forceUpdate();
    },
    inputPlateNo: function inputPlateNo(val) {
      //车牌号自动转大写
      if (!this.form.quoteVehicle.isNewCar) {
        this.form.quoteVehicle.plateNo = _.toUpper(val);
      }
    },
    addInsurance: function addInsurance(type) {
      if (type === 'one') {
        this.form.quoteDutys.push({
          riskkindName: '',
          unitAmount: '',
          amount: ''
        });
      } else {
        this.form.noCarInsurance.push({
          productName: '',
          productCode: '',
          policyNo: '',
          amount: '',
          unitAmount: '',
          premium: ''
        });
      }
    },
    deleteInsurance: function deleteInsurance(type, i) {
      if (type === 'one') {
        this.form.quoteDutys.splice(i, 1);
      } else if (type === 'two') {
        this.form.noCarInsurance.splice(i, 1);
      } else {
        this.form.uploadFiles.splice(i, 1);
      }
    },
    handleSuccess: function handleSuccess(res, file) {
      this.form.uploadFiles.push({
        fileName: file.name,
        uplaodStatus: ''
      });
    },
    cancel: function cancel() {
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.go(-1);
    },
    save: function save() {
      var _this2 = this;
      this.form.quoteInsureds = [];
      var ownerInfo = {
        //车主信息
        name: this.form.quoteVehicle.ownerName,
        certificateType: this.form.quoteVehicle.ownerCertificateType,
        certificateNo: this.form.quoteVehicle.ownerCertificateNo,
        mobile: this.form.quoteVehicle.ownerMobile
      };
      if (this.form.applicant.applicantSameAsOwner) {
        //投保人同车主
        this.form.quoteInsureds.push(_.assign(this.form.applicant, ownerInfo));
      } else {
        this.form.quoteInsureds.push(this.form.applicant);
      }
      if (this.form.insurant.sameAsOwner) {
        //被保人同车主
        this.form.quoteInsureds.push(_.assign(this.form.insurant, ownerInfo));
      }
      if (this.form.insurant.sameAsInsurer) {
        //被保人同投保人
        var applicant = _.cloneDeep(this.form.applicant);
        var obj = _.assign(applicant, this.form.insurant);
        this.form.quoteInsureds.push(_.omit(obj, ["applicantSameAsOwner"]));
      }
      if (!this.form.insurant.sameAsInsurer && !this.form.insurant.sameAsOwner) {
        this.form.quoteInsureds.push(this.form.insurant);
      }
      this.form.quoteVehicle.familyName = this.form.quoteVehicle.brand + this.form.quoteVehicle.brandSeries; //品牌车系
      if (this.form.quoteVehicle.registerDate) {
        this.form.quoteVehicle.registerDate = this.form.quoteVehicle.registerDate.split(" ")[0] + " 00:00:00";
      }
      if (this.form.bsRiskKind.effectiveStartTime) {
        this.form.bsRiskKind.effectiveStartTime = this.form.bsRiskKind.effectiveStartTime.split(" ")[0] + " 00:00:00";
      }
      if (this.form.bsRiskKind.effectiveEndTime) {
        this.form.bsRiskKind.effectiveEndTime = this.form.bsRiskKind.effectiveEndTime.split(" ")[0] + " 00:00:00";
      }
      if (this.form.bzRiskKind.effectiveStartTime) {
        this.form.bzRiskKind.effectiveStartTime = this.form.bzRiskKind.effectiveStartTime.split(" ")[0] + " 00:00:00";
      }
      if (this.form.bzRiskKind.effectiveEndTime) {
        this.form.bzRiskKind.effectiveEndTime = this.form.bzRiskKind.effectiveEndTime.split(" ")[0] + " 00:00:00";
      }
      this.form.quoteProducts = [];
      if (this.showInsuranceType !== '0') {
        //出单方式 01 交商同出 0 单出商业险 1 单出交强险
        this.form.quoteProducts.push({
          riskType: 0,
          //险种（险种）类型（0：交强险，1：商业险）
          riskTypeName: "交强险",
          quoteVehicleTax: this.form.quoteVehicleTax,
          effectiveStartTime: this.form.bzRiskKind.effectiveStartTime,
          effectiveEndTime: this.form.bzRiskKind.effectiveEndTime
        });
      }
      if (this.showInsuranceType !== '1') {
        this.form.quoteProducts.push({
          riskType: 1,
          //险种（险种）类型（0：交强险，1：商业险）
          riskTypeName: "商业险",
          quoteDutys: this.form.quoteDutys,
          effectiveStartTime: this.form.bsRiskKind.effectiveStartTime,
          effectiveEndTime: this.form.bsRiskKind.effectiveEndTime
        });
      }
      console.log(this.form.quoteProducts);
      if (this.form.quoteVehicle.plateType) {
        //号牌种类名称
        var lable = _.find(this.PLATE_TYPE, function (item) {
          return item.dictCode === _this2.form.quoteVehicle.plateType;
        });
        this.form.quoteVehicle.plateTypeName = lable.dictName;
      }
      if (this.form.quoteVehicle.useAttribute) {
        //车辆使用性质名称
        var lable1 = _.find(this.USE_ATTRIBUTE, function (item) {
          return item.dictCode === _this2.form.quoteVehicle.useAttribute;
        });
        this.form.quoteVehicle.useAttributeName = lable1.dictName;
      }
      if (this.form.quoteVehicle.vehicleType) {
        //行驶证车辆类型名称
        var lable2 = _.find(this.VEHICLE_KINDS, function (item) {
          return item.dictCode === _this2.form.quoteVehicle.vehicleType;
        });
        this.form.quoteVehicle.vehicleTypeName = lable2.dictName;
      }
      this.form.quoteVehicle.ownAttribute = 0; //车主性质编码
      this.form.quoteVehicle.ownAttributeName = 0; //车主性质编码名称

      if (this.form.insuredTime) {
        this.form.insuredTime = this.form.insuredTime.split(" ")[0] + " 00:00:00";
      }
      if (this.form.insuredTime) {
        this.form.insuredTime = this.form.insuredTime.split(" ")[0] + " 00:00:00";
      }
      this.$refs['form'].validate(function (valid) {
        if (valid) {
          requestPolicy.policyInput(_this2.form).then(function (res) {
            if (res.code === 200) {
              _this2.$message({
                type: "success",
                message: res.msg
              });
            }
          });
        }
      });
    }
  }
};