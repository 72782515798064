//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import dict from '@/mixins/dict';
import Pagination from '@/components/Pagination';
import carService from '@/api/carService';
import VehicleEditor from './dialog/vehicleEditor';
import importDialog from './dialog/importDialog.vue';
import { commonExport } from '@/utils/common';
export default {
  name: 'VehicleModelMaintenance',
  components: {
    Pagination: Pagination,
    VehicleEditor: VehicleEditor,
    importDialog: importDialog
  },
  mixins: [initHeight, dict],
  computed: {},
  data: function data() {
    return {
      showImport: 'vehicleModelMaintenance',
      showImportDialog: false,
      searchForm: {},
      form: {},
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      defaultProps: {
        children: 'children',
        label: 'fullName'
      },
      tableData: [],
      loading: false,
      highlightCurrent: true,
      showDialog: false,
      opType: "",
      insCorpItem: {}
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.loadData();
  },
  methods: {
    //导入
    importFn: function importFn() {
      this.showImportDialog = true;
    },
    // 导出
    exportFn: function exportFn() {
      var exporParams = {
        total: this.total,
        limitNum: 200000,
        fileName: '车型维护',
        exportUrl: '/vi-vehicle-service/vehicleBrandModel/searchBrands',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    deleteInsCorp: function deleteInsCorp(item) {
      var _this = this;
      //删除车型
      this.$confirm("\u60A8\u786E\u5B9A\u8981\u5C06<span style='color:red'>\u201C".concat(item.modelName, "\u201D</span>\u8F66\u578B\u5220\u9664\uFF1F "), {
        distinguishCancelAndClose: true,
        dangerouslyUseHTMLString: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        carService.delete(item.vehicleBrandModelId).then(function (res) {
          if (res.code === 200) {
            _this.$message({
              message: res.msg,
              type: 'success'
            });
            _this.loadData();
          }
        });
      }).catch(function () {});
    },
    editConfig: function editConfig(type, item) {
      //编辑
      this.opType = type;
      this.showDialog = true;
      this.insCorpItem = item;
    },
    addInscorp: function addInscorp(type) {
      //新增
      this.opType = type;
      this.showDialog = true;
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {};
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    loadData: function loadData() {
      var _this2 = this;
      //获取列表数据
      this.loading = true;
      this.showImportDialog = false;
      this.showDialog = false;
      carService.getList(this.listQuery.pageNum, this.listQuery.pageSize, this.searchForm).then(function (res) {
        if (res.code === 200) {
          _this2.tableData = res.data.list;
          _this2.loading = false;
          _this2.total = res.data.page.recordsTotal;
        }
      }).catch(function (err) {
        _this2.loading = false;
      });
    },
    // 刷新
    breakData: function breakData() {
      // this.resetSearch()
      this.loadData();
    }
  }
};