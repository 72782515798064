var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.formLoading,
          expression: "formLoading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: { "max-height": "600px" },
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "500px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "addForm",
                  staticStyle: { "margin-bottom": "-20px" },
                  attrs: {
                    model: _vm.addForm,
                    rules: _vm.formRules,
                    "label-width": "100px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "产品编码：",
                                prop: "accidentSchemeCode",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入产品编码",
                                  disabled: _vm.DialogType == "view",
                                  maxlength: "40",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.addForm.accidentSchemeCode,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.addForm,
                                      "accidentSchemeCode",
                                      $$v
                                    )
                                  },
                                  expression: "addForm.accidentSchemeCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "产品名称：",
                                prop: "accidentSchemeName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入产品名称",
                                  disabled: _vm.DialogType == "view",
                                  maxlength: "60",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.addForm.accidentSchemeName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.addForm,
                                      "accidentSchemeName",
                                      $$v
                                    )
                                  },
                                  expression: "addForm.accidentSchemeName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "保险公司：",
                                prop: "insCorpCode",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    filterable: "",
                                    disabled: _vm.DialogType == "view",
                                    placeholder: "请选择保险公司",
                                    clearable: "",
                                  },
                                  on: { change: _vm.getAccidentType },
                                  model: {
                                    value: _vm.addForm.insCorpCode,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.addForm, "insCorpCode", $$v)
                                    },
                                    expression: "addForm.insCorpCode",
                                  },
                                },
                                _vm._l(_vm.supplyers, function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.shortName,
                                      value: item.code,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "是否启用：" } },
                            [
                              _c("el-switch", {
                                model: {
                                  value: _vm.addForm.enabled,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addForm, "enabled", $$v)
                                  },
                                  expression: "addForm.enabled",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "产品说明：" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  autocomplete: "off",
                                  maxlength: "200",
                                  "show-word-limit": "",
                                  type: "textarea",
                                  rows: 2,
                                  placeholder: "请输入产品说明",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.addForm.accidentSchemeOutlining,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.addForm,
                                      "accidentSchemeOutlining",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "addForm.accidentSchemeOutlining",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "套餐编码：",
                                prop: "packageCode",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入套餐编码",
                                  maxlength: "40",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.addForm.packageCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addForm, "packageCode", $$v)
                                  },
                                  expression: "addForm.packageCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "套餐名称：",
                                prop: "packageName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入套餐名称",
                                  maxlength: "40",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.addForm.packageName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addForm, "packageName", $$v)
                                  },
                                  expression: "addForm.packageName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "保费：",
                                prop: "packagePremium",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入保费",
                                  oninput:
                                    "value=value.replace(/[^0-9]{0,1}(\\d*(?:\\.\\d{0,2})?).*$/g, '$1')",
                                  disabled: _vm.DialogType == "view",
                                  maxlength: "8",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.addForm.packagePremium,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addForm, "packagePremium", $$v)
                                  },
                                  expression: "addForm.packagePremium",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "保额：", prop: "packageAmount" },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入保额",
                                  oninput:
                                    "value=value.replace(/[^0-9]{0,1}(\\d*(?:\\.\\d{0,2})?).*$/g, '$1')",
                                  disabled: _vm.DialogType == "view",
                                  maxlength: "8",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.addForm.packageAmount,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.addForm, "packageAmount", $$v)
                                  },
                                  expression: "addForm.packageAmount",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "套餐说明：" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  autocomplete: "off",
                                  maxlength: "200",
                                  "show-word-limit": "",
                                  type: "textarea",
                                  rows: 2,
                                  placeholder: "请输入产品说明",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.addForm.packageTips,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.addForm,
                                      "packageTips",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "addForm.packageTips",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticStyle: { width: "100%", height: "10px" } }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-check",
                type: "primary",
                plain: "",
                size: "mini",
                disabled: _vm.DialogType == "view",
              },
              on: { click: _vm.saveClicked },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: {
                click: function ($event) {
                  return _vm.closePage(true)
                },
              },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }