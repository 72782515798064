import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import Pagination from '@/components/Pagination';
import messageApi from '@/api/messagePlatform';
import MessageTableDialog from './dialog/messageTableDialog';
import { commonExport } from '@/utils/common';
export default {
  name: 'MessageTable',
  components: {
    Pagination: Pagination,
    MessageTableDialog: MessageTableDialog
  },
  mixins: [initHeight],
  data: function data() {
    return {
      searchForm: {
        readed: null,
        receiverUserId: null
      },
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      loading: false,
      drawer: false,
      showAddDialog: false,
      currentId: '',
      messageTypeList: [],
      messageLevelList: [{
        label: '通知',
        value: 'INFO'
      }, {
        label: '警告',
        value: 'WARNING'
      }, {
        label: '错误',
        value: 'ERROR'
      }, {
        label: '成功',
        value: 'SUCCESS'
      }],
      readedList: [{
        value: null,
        label: '全部'
      }, {
        value: true,
        label: '已读'
      }, {
        value: false,
        label: '未读'
      }],
      createdStartEndTime: [],
      readStartEndTime: [],
      userInfo: JSON.parse(localStorage.getItem("userInfo")) || {}
    };
  },
  watch: {
    '$route': {
      handler: function handler(val) {
        switch (val.path) {
          // 系统消息记录
          case "/messagePlatform/messageTable":
            break;
          // 我的消息
          case "/myMessageTable":
            this.searchForm.receiverUserId = this.userInfo.userId;
            break;
          default:
            return;
        }
        ;
        this.getMessageTypeList();
        this.loadData();
      },
      immediate: true
    }
  },
  methods: {
    // 获取消息类型列表
    getMessageTypeList: function getMessageTypeList() {
      var _this = this;
      messageApi.getNoPageMessageTypeList().then(function (res) {
        if (res.code === 200) {
          _this.messageTypeList = res.data || [];
        }
        ;
      });
    },
    // 日期更改监听事件
    dateChange: function dateChange(start, end, val) {
      if (val && val.length > 1) {
        this.searchForm[start] = "".concat(val[0], " 00:00:00");
        this.searchForm[end] = "".concat(val[1], " 23:59:59");
      } else {
        this.searchForm[start] = null;
        this.searchForm[end] = null;
      }
      ;
    },
    // 重置搜索表单
    resetSearch: function resetSearch() {
      this.searchForm = {
        readed: null,
        receiverUserId: this.searchForm.receiverUserId || null
      };
      this.createdStartEndTime = [];
      this.readStartEndTime = [];
    },
    // 搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    // 刷新
    breakData: function breakData() {
      this.loadData();
    },
    // 获取列表数据
    loadData: function loadData() {
      var _this2 = this;
      var isDialogNoRefresh = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      // this.showAddDialog = false;
      if (isDialogNoRefresh) return;
      this.loading = true;
      this.drawer = false;
      var data = _objectSpread(_objectSpread({}, this.listQuery), this.searchForm);
      messageApi.getMessageList(data).then(function (res) {
        _this2.loading = false;
        if (res.code === 200) {
          _this2.tableData = res.data.list;
          _this2.total = res.data.page.recordsTotal;
        }
        ;
      }).catch(function (err) {
        _this2.loading = false;
      });
    },
    // 导出
    exportExcels: function exportExcels() {
      var exporParams = {
        total: this.total,
        fileName: this.searchForm.receiverUserId ? '我的消息' : '系统消息记录',
        exportUrl: '/tp-message-service/message/search',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    // 查看
    view: function view(item) {
      // this.showAddDialog = true;
      // this.currentId = item.messageId.toString();
      // if(!item.readed) {
      //     // 读取状态
      //     messageApi.readMessage(item.messageId).then(res => {
      //         if (res.code === 200) {
      //             this.loadData()
      //         };
      //     });
      // };
      this.$router.push({
        path: '/messagePlatform/msgDetailPage',
        query: {
          messageId: item.messageId
        }
      });
    }
  }
};