import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import dict from '@/mixins/dict';
import Pagination from '@/components/Pagination';
import carService from '@/api/carService';
import InsCorpDialog from './dialog/insCorpDialog';
import importDialog from './dialog/importDialog.vue';
import { commonExport } from '@/utils/common';
export default {
  name: 'DrandMaintenance',
  components: {
    Pagination: Pagination,
    InsCorpDialog: InsCorpDialog,
    importDialog: importDialog
  },
  mixins: [initHeight, dict],
  computed: {},
  data: function data() {
    return {
      showImport: 'brandMaintenance',
      showImportDialog: false,
      searchForm: {},
      form: {},
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      defaultProps: {
        children: 'children',
        label: 'fullName'
      },
      tableData: [],
      loading: false,
      highlightCurrent: true,
      showDialog: false,
      opType: "",
      insCorpItem: {}
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.loadData();
  },
  methods: {
    importFn: function importFn() {
      this.showImportDialog = true;
    },
    // 导出
    exportFn: function exportFn() {
      var exporParams = {
        total: this.total,
        limitNum: 200000,
        fileName: '车系维护',
        exportUrl: '/vi-vehicle-service/vehicleBrandSeries/searchBrands',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    deleteInsCorp: function deleteInsCorp(item) {
      var _this = this;
      //删除车系
      this.$confirm("\u60A8\u786E\u5B9A\u8981\u5C06<span style='color:red'>\u201C".concat(item.seriesName, "\u201D</span>\u8F66\u7CFB\u5220\u9664\uFF1F \u5220\u9664<span style='color:red'>\u201C").concat(item.seriesName, "\u201D</span>\u8F66\u7CFB\u5C06\u4F1A\u5220\u9664\u6240\u6709\u4E0E<span style='color:red'>\u201C").concat(item.seriesName, "\u201D</span>\u8F66\u7CFB\u6709\u5173\u7684\u8F66\u578B"), {
        distinguishCancelAndClose: true,
        dangerouslyUseHTMLString: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        carService.carSeriesDelete(item.vehicleBrandSeriesId).then(function (res) {
          if (res.code === 200) {
            _this.$message({
              message: res.msg,
              type: 'success'
            });
            _this.loadData();
          }
        });
      }).catch(function () {});
    },
    editConfig: function editConfig(type, item) {
      //编辑
      this.opType = type;
      this.showDialog = true;
      this.insCorpItem = item;
    },
    addInscorp: function addInscorp(type) {
      //新增
      this.opType = type;
      this.showDialog = true;
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {};
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    loadData: function loadData() {
      var _this2 = this;
      //获取列表数据
      this.loading = true;
      this.showImportDialog = false;
      this.showDialog = false;
      carService.carSeriesList(this.listQuery.pageNum, this.listQuery.pageSize, this.searchForm).then(function (res) {
        if (res.code === 200) {
          _this2.tableData = res.data.list;
          _this2.loading = false;
          _this2.total = res.data.page.recordsTotal;
        }
      }).catch(function (err) {
        _this2.loading = false;
      });
      // insCorpRequest.getAllSuppliers('INSURANCE').then(res => {
      //     if (res && !_.isEmpty(res.data)) {
      //         localStorage.removeItem('VI_PROP_SUPPLYER');
      //         localStorage.setItem('VI_PROP_SUPPLYER', JSON.stringify(res.data));
      //     }
      // })
    },
    // 刷新
    breakData: function breakData() {
      // this.resetSearch()
      this.loadData();
    }
  }
};