/**
 * 省市区维护接口
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  //  **********************************省市区维护************************************

  /**
  * 列表
  * @param {*} pageNum 当前页
  * @param {*} pageSize 每页显示条数
  * @param {*} data 内容
  */
  getList: function getList() {
    return request.get(getUrl("/districts/tree/all", 3));
  },
  /**
    * 新增地区
    * @param {*} data 内容
    */
  addDistricts: function addDistricts(data) {
    return request.post(getUrl("/districts", 3), data);
  },
  /**
   * 删除地区
   * @param {*} id
   */
  deleteDistricts: function deleteDistricts(id) {
    return request.delete(getUrl("/districts/".concat(id), 3));
  },
  /**
   * 获取区域对象（名称,类型）
   * @param {*} type
   */
  getProvincesAndCities: function getProvincesAndCities(type) {
    return request.get(getUrl("/districts/getByType?type=".concat(type), 3));
  },
  /**
   * 获取地区
   * @param {*} id
   */
  getDistricts: function getDistricts(id) {
    return request.get(getUrl("/districts/detail/".concat(id), 3));
  },
  /**
   * 更新地区
   * @param {*} id
   * @param {*} data
   */
  editDistricts: function editDistricts(id, data) {
    return request.put(getUrl("/districts/".concat(id), 3), data);
  },
  /**
   * 获取区域子列表(主键)
   * @param {*} id
   */
  getSubCities: function getSubCities(id) {
    return request.get(getUrl("/districts/getSubDistricts?districtId=".concat(id), 3));
  },
  //  **********************************tack省市区维护************************************

  /**
   * 列表
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   * @param {*} data 内容
   */
  getYfDistrictList: function getYfDistrictList() {
    return request.get(getUrl("/district/tree/all", 11));
  },
  /**
    * 新增地区
    * @param {*} data 内容
    */
  addYfDistrict: function addYfDistrict(data) {
    return request.post(getUrl("/district", 11), data);
  },
  /**
   * 删除地区
   * @param {*} id
   */
  deleteYfDistrict: function deleteYfDistrict(id) {
    return request.delete(getUrl("/district/".concat(id), 11));
  },
  /**
   * 获取地区
   * @param {*} id
   */
  getYfDistrict: function getYfDistrict(id) {
    return request.get(getUrl("/district/detail/".concat(id), 11));
  },
  /**
   * 更新地区
   * @param {*} id
   * @param {*} data
   */
  editYfDistrict: function editYfDistrict(id, data) {
    return request.put(getUrl("/district/".concat(id), 11), data);
  },
  /**
   * 获取区域对象（类型）
   * @param {*} type
   */
  getYfProvincesAndCities: function getYfProvincesAndCities(type) {
    return request.get(getUrl("/district/getByType?type=".concat(type), 11));
  },
  /**
   * 获取区域子列表(主键)
   * @param {*} id
   */
  getYfSubCities: function getYfSubCities(id) {
    return request.get(getUrl("/district/getSubDistricts?districtId=".concat(id), 11));
  }
};
export default objApi;