var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.listLoading,
          expression: "listLoading",
        },
      ],
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            rules: _vm.formRules,
            "label-width": "110px",
            size: "mini",
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "车主名：", prop: "ownerName" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入车主名",
                          disabled: _vm.isDisabled,
                          clearable: "",
                        },
                        model: {
                          value: _vm.form.ownerName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "ownerName", $$v)
                          },
                          expression: "form.ownerName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "车牌号：", prop: "plateNo" } },
                    [
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "10",
                              disabled: _vm.checked,
                              placeholder: "请输入车牌号",
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.plateNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "plateNo", $$v)
                              },
                              expression: "form.plateNo",
                            },
                          }),
                          _c(
                            "div",
                            { staticStyle: { "padding-left": "10px" } },
                            [
                              _c(
                                "el-checkbox",
                                {
                                  on: { change: _vm.noPlateNoChange },
                                  model: {
                                    value: _vm.checked,
                                    callback: function ($$v) {
                                      _vm.checked = $$v
                                    },
                                    expression: "checked",
                                  },
                                },
                                [_vm._v("未上牌")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "证件类型：" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            filterable: "",
                            disabled: _vm.isDisabled,
                            placeholder: "请选择证件类型",
                            clearable: "",
                          },
                          model: {
                            value: _vm.form.ownerCertificateType,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "ownerCertificateType", $$v)
                            },
                            expression: "form.ownerCertificateType",
                          },
                        },
                        _vm._l(_vm.VI_CERTIFICATE_TYPE, function (group) {
                          return _c(
                            "el-option-group",
                            { key: group.label, attrs: { label: group.label } },
                            _vm._l(group.options, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: {
                                  label: item.dictName,
                                  value: item.dictCode,
                                },
                              })
                            }),
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "证件号：", prop: "ownerCertificateNo" },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "18",
                          disabled: _vm.isDisabled,
                          placeholder: "请输入证件号",
                          clearable: "",
                        },
                        model: {
                          value: _vm.form.ownerCertificateNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "ownerCertificateNo", $$v)
                          },
                          expression: "form.ownerCertificateNo",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "车架号：", prop: "vin" } },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "17",
                          disabled: _vm.isDisabled,
                          placeholder: "请输入车架号",
                          clearable: "",
                        },
                        model: {
                          value: _vm.form.vin,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "vin", $$v)
                          },
                          expression: "form.vin",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "发动机号：", prop: "engineNo" } },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "20",
                          disabled: _vm.isDisabled,
                          placeholder: "请输入发动机号",
                          clearable: "",
                        },
                        on: { input: _vm.inputEngineNo },
                        model: {
                          value: _vm.form.engineNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "engineNo", $$v)
                          },
                          expression: "form.engineNo",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "厂牌车型：" } },
                    [
                      _c("CarBrands", {
                        staticStyle: { width: "100%" },
                        attrs: { carTypeValue: _vm.carTypeVal },
                        on: { carHandleChanged: _vm.CarHandleChange },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "注册日期：" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          disabled: _vm.isDisabled,
                          placeholder: "请选择注册日期",
                          format: "yyyy-MM-dd",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          "unlink-panels": "",
                          "picker-options": _vm.disabledLessThan,
                        },
                        model: {
                          value: _vm.form.registerDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "registerDate", $$v)
                          },
                          expression: "form.registerDate",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "使用性质：" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            disabled: _vm.isDisabled,
                            placeholder: "请选择使用性质",
                            clearable: "",
                          },
                          model: {
                            value: _vm.form.useAttributeName,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "useAttributeName", $$v)
                            },
                            expression: "form.useAttributeName",
                          },
                        },
                        _vm._l(_vm.USE_ATTRIBUTE, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: {
                              label: item.dictName,
                              value: item.dictCode,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "品牌型号：" } },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "40",
                          placeholder: "请输入品牌型号",
                          disabled: _vm.isDisabled,
                          clearable: "",
                        },
                        model: {
                          value: _vm.form.modelName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "modelName", $$v)
                          },
                          expression: "form.modelName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "车辆类型：" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            disabled: _vm.isDisabled,
                            placeholder: "请选择车辆类型",
                            clearable: "",
                          },
                          model: {
                            value: _vm.form.vehicleTypeName,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "vehicleTypeName", $$v)
                            },
                            expression: "form.vehicleTypeName",
                          },
                        },
                        _vm._l(_vm.VEHICLE_KINDS, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: {
                              label: item.dictName,
                              value: item.dictCode,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "号牌种类：" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            disabled: _vm.isDisabled,
                            placeholder: "请选择号牌种类",
                            clearable: "",
                          },
                          model: {
                            value: _vm.form.plateType,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "plateType", $$v)
                            },
                            expression: "form.plateType",
                          },
                        },
                        _vm._l(_vm.PLATE_TYPE, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: {
                              label: item.dictName,
                              value: item.dictCode,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "能源类型：" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            disabled: _vm.isDisabled,
                            placeholder: "请选择能源类型",
                            clearable: "",
                          },
                          model: {
                            value: _vm.form.fuelTypeName,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "fuelTypeName", $$v)
                            },
                            expression: "form.fuelTypeName",
                          },
                        },
                        _vm._l(_vm.ENGRGY_TYPES, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: {
                              label: item.dictName,
                              value: item.dictCode,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "整备质量(吨)：" } },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "40",
                          placeholder: "请输入整备质量",
                          disabled: _vm.isDisabled,
                          clearable: "",
                        },
                        on: {
                          input: function ($event) {
                            _vm.form.wholeWeight = _vm.onInput(
                              _vm.form.wholeWeight,
                              0,
                              1000,
                              3
                            )
                          },
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            _vm.form.wholeWeight = _vm.onInput(
                              _vm.form.wholeWeight,
                              0,
                              1000,
                              3
                            )
                          },
                        },
                        model: {
                          value: _vm.form.wholeWeight,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "wholeWeight", $$v)
                          },
                          expression: "form.wholeWeight",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c("el-form-item", { attrs: { label: "车销日期：" } }, [
                    _c(
                      "div",
                      { staticStyle: { display: "flex" } },
                      [
                        _c("el-date-picker", {
                          staticStyle: {
                            width: "100%",
                            "margin-right": "10px",
                          },
                          attrs: {
                            disabled: "",
                            placeholder: "",
                            format: "yyyy-MM-dd",
                            "value-format": "yyyy-MM-dd HH:mm:ss",
                            "unlink-panels": "",
                          },
                          model: {
                            value: _vm.form.sellDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "sellDate", $$v)
                            },
                            expression: "form.sellDate",
                          },
                        }),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              icon: "el-icon-refresh",
                              loading: _vm.refreshing,
                            },
                            on: { click: _vm.getVehicleSalesData },
                          },
                          [_vm._v("获取车销日期")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "功率(KW)：" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "text",
                          placeholder: "请输入功率(KW)",
                          clearable: "",
                          disabled: _vm.isDisabled,
                        },
                        on: {
                          input: function ($event) {
                            _vm.form.power = _vm.onInput(
                              _vm.form.power,
                              0,
                              1000,
                              3
                            )
                          },
                        },
                        model: {
                          value: _vm.form.power,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form,
                              "power",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.power",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { display: "flex", "justify-content": "right" } },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", plain: "", size: "mini" },
              on: { click: _vm.save },
            },
            [_vm._v("保存车辆信息")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }