import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import Pagination from '@/components/Pagination';
import syncJyVehicleRequest from '@/api/syncJyVehicle';
import { commonExport } from '@/utils/common';
export default {
  name: 'JyAccountManagement',
  components: {
    Pagination: Pagination
  },
  mixins: [initHeight],
  data: function data() {
    return {
      searchForm: {},
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      loading: false,
      showDialog: false,
      opType: '',
      formLoading: false,
      jyAccountList: [],
      form: {
        enableVehicle: true
      },
      showDetailDialog: false,
      tableDetail: [],
      rules: {
        vehicleName: [{
          required: true,
          message: '请输入请输入品牌型号',
          trigger: 'blur'
        }],
        jyAccount: [{
          required: true,
          message: '请选择精友账号',
          trigger: 'change'
        }]
      }
    };
  },
  watch: {
    showDialog: function showDialog(val) {
      if (val) {
        this.form = {
          enableVehicle: true
        };
        this.$nextTick(this.$refs["detail"].clearValidate());
      }
    }
  },
  created: function created() {
    this.loadData();
    this.getJyAccountList();
  },
  methods: {
    // 查看详情
    viewDetail: function viewDetail(item) {
      this.showDetailDialog = true;
      this.getDetailByBatchNo(item.syncBatchNo);
    },
    getDetailByBatchNo: function getDetailByBatchNo(no) {
      var _this = this;
      syncJyVehicleRequest.getDetailByBatchNo({
        batchNo: no
      }).then(function (res) {
        if (res.code == 200) {
          _this.tableDetail = res.data.syncBatchDetail;
        }
      });
    },
    // 保存
    save: function save() {
      var _this2 = this;
      this.$refs["detail"].validate(function (valid) {
        if (valid) {
          syncJyVehicleRequest.syncJyVehiceData(_this2.form).then(function (res) {
            _this2.formLoading = false;
            if (res.code == 200) {
              _this2.$message.success(res.msg);
              _this2.loadData();
            }
          });
        }
      });
    },
    // 关闭
    closePage: function closePage() {
      this.showDialog = false;
    },
    // 查询精友用户列表
    getJyAccountList: function getJyAccountList() {
      var _this3 = this;
      syncJyVehicleRequest.getJyAccountList().then(function (res) {
        if (res.code == 200) {
          _this3.jyAccountList = res.data.map(function (item) {
            return {
              dictName: item.userName,
              dictCode: item.configCode
            };
          });
        }
      });
    },
    // 导出
    exportFn: function exportFn() {
      var exporParams = {
        total: this.total,
        fileName: '同步精友车型',
        exportUrl: '/vi-connect-configuration-service/jyVehicle/getJyList',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    //同步
    syncVehicle: function syncVehicle(type) {
      this.opType = type;
      this.showDialog = true;
    },
    breakData: function breakData() {
      // this.resetSearch();
      this.loadData();
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {};
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    //获取列表数据
    loadData: function loadData() {
      var _this4 = this;
      this.loading = true;
      this.showDialog = false;
      var data = _objectSpread(_objectSpread({}, this.listQuery), this.searchForm);
      syncJyVehicleRequest.getList(data).then(function (res) {
        _this4.loading = false;
        if (res.code === 200) {
          _this4.tableData = res.data.list;
          _this4.total = res.data.page.recordsTotal;
        }
        ;
      }).catch(function (err) {
        _this4.loading = false;
      });
    }
  }
};