import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import renewalConfigRequest from '@/api/renewalConfig';
import inscorpdiffconfigRequest from '@/api/inscorpdiffconfig';
import { limitInput } from '@/utils/common';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    dialogType: {
      type: String,
      default: ''
    },
    dialogItem: {
      type: Object,
      default: {}
    }
  },
  data: function data() {
    return {
      districtTypeList: [{
        name: "省",
        code: 'province'
      }, {
        name: "市",
        code: 'city'
      }],
      formLoading: false,
      proList: [],
      cascaderAddr: [],
      options: JSON.parse(localStorage.getItem('VI_CityAreaTree')) || [],
      cityOptions: JSON.parse(localStorage.getItem("VI_CityPcTree")) || [],
      form: {
        days: "",
        districtCode: "",
        districtName: "",
        districtType: ""
      },
      bizType: localStorage.getItem("bizType") || '',
      userInfo: JSON.parse(localStorage.getItem('userInfo')) || {},
      formRules: {
        districtType: [{
          required: true,
          message: '请选择地区类型',
          trigger: 'change'
        }],
        districtCode: [{
          required: true,
          message: '请选择省/市',
          trigger: 'change'
        }],
        days: [{
          required: true,
          message: '请输入提前续保天数',
          trigger: 'blur'
        }]
      }
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        if (this.dialogType == 'add') {
          this.initData();
          this.getDistricts();
        } else {
          this.getDistricts();
        }
        this.$refs.addForm.resetFields();
      }
    }
  },
  mounted: function mounted() {
    if (this.dialogType == 'add') {
      this.initData();
      this.getDistricts();
    } else {
      this.getDistricts();
    }
  },
  methods: {
    onInput: function onInput(num, min, max, limit) {
      this.$forceUpdate();
      //对输入内容做精度校验
      if (min && num < min) {
        num = min;
      }
      if (max && num > max) {
        num = max;
      }
      return limitInput(num, limit);
    },
    // 获取省份
    getDistricts: function getDistricts() {
      var _this = this;
      inscorpdiffconfigRequest.getDistricts({
        type: "PROVINCE"
      }).then(function (res) {
        _this.proList = res.data;
        _this.initData();
      });
    },
    // 初始化数据
    initData: function initData() {
      this.cascaderAddr = [];
      if (this.dialogType == 'add') {
        this.form = {
          days: "",
          districtCode: "",
          districtName: "",
          districtType: ""
        };
      } else {
        var dcode = this.dialogItem.districtCode;
        var item = _.find(this.proList, function (i) {
          return i.code.substr(0, 3) == dcode.substr(0, 3);
        });
        this.cascaderAddr = [item.code, this.dialogItem.districtCode];
        this.form = {
          days: this.dialogItem.days,
          districtCode: this.dialogItem.districtCode,
          districtName: this.dialogItem.districtName,
          districtType: this.dialogItem.districtType,
          renewalPeriodConfigId: this.dialogItem.renewalPeriodConfigId
        };
      }
    },
    // 省/市/区选择
    handleChange: function handleChange(val) {
      this.$nextTick(function () {
        var cascaderAddr = this.$refs['cascaderAddr'].getCheckedNodes()[0].pathLabels;
        this.form.districtName = cascaderAddr[1];
        this.form.districtCode = val[1];
      });
    },
    getdistrictName: function getdistrictName(val) {
      var item = _.find(this.proList, function (i) {
        return i.code == val;
      });
      this.form.districtName = item.name;
    },
    // 保存数据
    save: function save() {
      var _this2 = this;
      this.$refs['addForm'].validate(function (valid) {
        if (valid) {
          var request = '';
          if (_this2.dialogType == 'add') {
            request = renewalConfigRequest.addRenewalConfig(_this2.form);
          } else {
            request = renewalConfigRequest.updateRenewalConfig(_this2.form);
          }
          request.then(function (res) {
            _this2.formLoading = false;
            _this2.$message.success(res.msg);
            _this2.closePage(false);
          }).catch(function (err) {
            _this2.formLoading = false;
          });
        }
      });
    },
    // 关闭弹窗
    closePage: function closePage() {
      var boolean = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      this.$emit('closePage', boolean);
    }
  }
};