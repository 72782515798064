import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
/**
 *离职申请 接口
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 获取员工
   * @param {*} id 查询参数
   */
  getDetail: function getDetail(orgCode) {
    return request.get(getUrl("/employee/".concat(orgCode, "/org"), 4));
  },
  contractList: function contractList(pageNum, pageSize, data) {
    return request.get(getUrl("/info/search?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 4), {
      params: data
    });
  },
  /**
   * 申请离职
   * @param {*} id 查询参数
   * @param {*} data 内容/employee/offer/ceapplyfor
   */
  leaveOfferApply: function leaveOfferApply(data) {
    return request.put(getUrl("/regulation/leave/offer/create", 12), data);
  },
  /**
   * 审核状态
   * @param {*} id 查询参数
   */
  changeAudit: function changeAudit(id, data) {
    return request.put(getUrl("/regulation/leave/offer/audit/".concat(id, "?auditStatus=").concat(data.auditStatus), 12));
  },
  /**
   * 列表
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   * @param {*} data 查询参数
   */
  getLeaveOfferList: function getLeaveOfferList(data) {
    return request.get(getUrl("/regulation/leave/offer/search", 12), {
      params: data
    });
  },
  /**
   * 详情
   * @param {*} ids 
   */
  details: function details(leaveOfficeId) {
    return request.get(getUrl("/regulation/leave/offer/".concat(leaveOfficeId.toString()), 12));
  },
  /**
   * 删除
   * @param {*} ids 
   */
  delete: function _delete(data) {
    return request.put(getUrl("/regulation/leave/offer/delete", 12), data);
  },
  /**
   * 上传文件
   * @param {*} data
   */
  uploadFile: function uploadFile(data) {
    return request.post(getUrl("/info/create", 4), data);
  },
  /**
   * 更新员工合同
   * @param {*} id
   */
  updateContract: function updateContract(data) {
    return request.post(getUrl("/info/create", 4), data);
  },
  /**
   * 查询员工合同
   * @param {*} id
   */
  queryContract: function queryContract(id, type) {
    return request.get(getUrl("/info/".concat(id, "?infoType=").concat(type), 4));
  },
  /**
   * 删除员工合同
   * @param {*} data
   */
  deleteContract: function deleteContract(data) {
    return request.put(getUrl("/info/delete", 4), data);
  }
};
export default objApi;