var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.formLoading,
          expression: "formLoading",
        },
      ],
    },
    [
      _c("PageContainer", [
        _c(
          "div",
          {
            staticClass: "app-m-cls",
            staticStyle: { height: "100%" },
            attrs: { slot: "main" },
            slot: "main",
          },
          [
            _c(
              "div",
              { ref: "header", staticClass: "header-cls" },
              [
                _c(
                  "el-card",
                  {
                    staticStyle: { "margin-top": "10px" },
                    attrs: { shadow: "never" },
                  },
                  [
                    _c("div", { staticStyle: { padding: "10px" } }, [
                      _c(
                        "div",
                        [
                          _c("span", { staticStyle: { "font-size": "30px" } }, [
                            _vm._v(
                              _vm._s(_vm.orderDetail.productClassName || "-")
                            ),
                          ]),
                          _c("el-divider", {
                            attrs: { direction: "vertical" },
                          }),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-weight": "bold",
                                "font-size": "15px",
                              },
                            },
                            [
                              _vm._v(
                                "订单状态：" +
                                  _vm._s(
                                    _vm.orderDetail.statusDisplay
                                      ? _vm.orderDetail.statusDisplay
                                      : "-"
                                  )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "margin-top": "10px",
                            "font-size": "14px",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "line-height": "28px",
                              },
                            },
                            [
                              _c("div", [_vm._v("订单编号：")]),
                              _c(
                                "div",
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.orderDetail.orderNo
                                          ? _vm.orderDetail.orderNo
                                          : "-"
                                      ) +
                                      " "
                                  ),
                                  _vm.orderDetail.status == "MANUAL_APPLY"
                                    ? _c(
                                        "el-button",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            icon: "el-icon-refresh",
                                            disabled: _vm.refreshing,
                                            loading: _vm.refreshing,
                                            size: "mini",
                                            type: "primary",
                                            plain: "",
                                          },
                                          on: { click: _vm.orderRefresh },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.refreshing
                                                  ? "刷新中"
                                                  : "刷新"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticStyle: { "line-height": "28px" } },
                            [
                              _c("span", [
                                _vm._v(
                                  "出单结果：" +
                                    _vm._s(
                                      _vm.orderDetail.issueResult
                                        ? _vm.orderDetail.issueResult
                                        : "-"
                                    )
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            { staticStyle: { "line-height": "28px" } },
                            [
                              _vm.orderDetail.productClassCode == "DBC"
                                ? _c("svg-icon", {
                                    attrs: {
                                      "icon-class": _vm.orderDetail.supplierCode
                                        ? _vm.orderDetail.supplierCode.split(
                                            "_"
                                          )[1] &&
                                          _vm.orderDetail.supplierCode
                                            .split("_")[1]
                                            .toLowerCase()
                                        : "-",
                                    },
                                  })
                                : _vm._e(),
                              _vm.orderDetail.productClassCode == "DBC"
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getInsCorpName(
                                          _vm.orderDetail.supplierCode.split(
                                            "_"
                                          )[1] &&
                                            _vm.orderDetail.supplierCode
                                              .split("_")[1]
                                              .toLowerCase()
                                        )
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.orderDetail.productClassCode == "DBC"
                                ? _c("el-divider", {
                                    attrs: { direction: "vertical" },
                                  })
                                : _vm._e(),
                              _c("span", [
                                _vm._v(
                                  "保单号：" +
                                    _vm._s(
                                      _vm.orderDetail.policyNo
                                        ? _vm.orderDetail.policyNo
                                        : "-"
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("el-divider", {
                                attrs: { direction: "vertical" },
                              }),
                              _c("span", [
                                _vm._v(
                                  "投保单号：" +
                                    _vm._s(
                                      _vm.orderDetail.applyPolicyNo
                                        ? _vm.orderDetail.applyPolicyNo
                                        : "-"
                                    ) +
                                    " "
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticStyle: { "line-height": "28px" } },
                            [
                              _c("span", [
                                _vm._v(
                                  "出单机构：" +
                                    _vm._s(
                                      _vm.orderDetail.salesOrgName
                                        ? _vm.orderDetail.salesOrgName
                                        : "-"
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("el-divider", {
                                attrs: { direction: "vertical" },
                              }),
                              _c("span", [
                                _vm._v(
                                  "业务员：" +
                                    _vm._s(
                                      _vm.orderDetail.salemanName
                                        ? _vm.orderDetail.salemanName
                                        : "-"
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("el-divider", {
                                attrs: { direction: "vertical" },
                              }),
                              _c("span", [
                                _vm._v(
                                  "操作人：" +
                                    _vm._s(
                                      _vm.orderDetail.creatorName
                                        ? _vm.orderDetail.creatorName
                                        : "-"
                                    ) +
                                    " "
                                ),
                              ]),
                            ],
                            1
                          ),
                          _vm.orderDetail.productClassCode == "TCWY"
                            ? _c(
                                "div",
                                { staticStyle: { "line-height": "28px" } },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "成交日期：" +
                                        _vm._s(
                                          _vm.orderDetail.fixtureDate
                                            ? _vm.orderDetail.fixtureDate.split(
                                                " "
                                              )[0]
                                            : "-"
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _c("el-divider", {
                                    attrs: { direction: "vertical" },
                                  }),
                                  _c("span", [
                                    _vm._v(
                                      "过户日期：" +
                                        _vm._s(
                                          _vm.orderDetail.transferDate
                                            ? _vm.orderDetail.transferDate.split(
                                                " "
                                              )[0]
                                            : "-"
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { staticStyle: { "line-height": "28px" } },
                            [
                              _c("span", [
                                _vm._v(
                                  "服务销售日期：" +
                                    _vm._s(
                                      _vm.orderDetail.createdTime
                                        ? _vm.orderDetail.createdTime
                                        : "-"
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("el-divider", {
                                attrs: { direction: "vertical" },
                              }),
                              _c("span", [
                                _vm._v(
                                  "承保时间：" +
                                    _vm._s(
                                      _vm.orderDetail.insuredTime
                                        ? _vm.orderDetail.insuredTime
                                        : "-"
                                    ) +
                                    " "
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                  ]
                ),
                _vm.orderDetail.productClassCode == "TCWY"
                  ? _c(
                      "el-card",
                      {
                        staticStyle: { "margin-top": "10px" },
                        attrs: { shadow: "never" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "title",
                            staticStyle: { "line-height": "25px" },
                          },
                          [
                            _c("FormLabel", {
                              staticStyle: {
                                "font-size": "15px",
                                "line-height": "16px",
                              },
                              attrs: { "form-label": "车辆信息" },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          { staticClass: "flexWarp", attrs: { gutter: 0 } },
                          [
                            _c(
                              "el-col",
                              {
                                attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 },
                              },
                              [
                                _c("div", { staticClass: "card_item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "card_item_label" },
                                    [_vm._v("车牌号：")]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.orderDetail.targetPlateNo
                                          ? _vm.orderDetail.targetPlateNo
                                          : "-"
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                            _c(
                              "el-col",
                              {
                                attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 },
                              },
                              [
                                _c("div", { staticClass: "card_item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "card_item_label" },
                                    [_vm._v("车架号：")]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.orderDetail.targetVin
                                          ? _vm.orderDetail.targetVin
                                          : "-"
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                            _c(
                              "el-col",
                              {
                                attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 },
                              },
                              [
                                _c("div", { staticClass: "card_item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "card_item_label" },
                                    [_vm._v("厂家品牌/车系：")]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.orderDetail.targetVhcBrandName
                                          ? _vm.orderDetail.targetVhcBrandName
                                          : "-"
                                      ) +
                                        "/" +
                                        _vm._s(
                                          _vm.orderDetail.targetVhcSeriesName
                                            ? _vm.orderDetail
                                                .targetVhcSeriesName
                                            : "-"
                                        )
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                            _c(
                              "el-col",
                              {
                                attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 },
                              },
                              [
                                _c("div", { staticClass: "card_item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "card_item_label" },
                                    [_vm._v("第一车网品牌/车系：")]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.orderDetail.targetCarNetSeriesName
                                          ? _vm.orderDetail
                                              .targetCarNetSeriesName
                                          : "-"
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                            _c(
                              "el-col",
                              {
                                attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 },
                              },
                              [
                                _c("div", { staticClass: "card_item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "card_item_label" },
                                    [_vm._v("二手车发票价(元)：")]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.orderDetail.targetInvoicePrice
                                          ? _vm.orderDetail.targetInvoicePrice.toFixed(
                                              2
                                            )
                                          : "-"
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                            _c(
                              "el-col",
                              {
                                attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 },
                              },
                              [
                                _c("div", { staticClass: "card_item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "card_item_label" },
                                    [_vm._v("使用性质：")]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.orderDetail
                                          .targetUseAttributeDisplay
                                          ? _vm.orderDetail
                                              .targetUseAttributeDisplay
                                          : "-"
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                            _c(
                              "el-col",
                              {
                                attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 },
                              },
                              [
                                _c("div", { staticClass: "card_item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "card_item_label" },
                                    [_vm._v("车辆当前里程：")]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.orderDetail.targetMileage
                                          ? _vm.orderDetail.targetMileage
                                          : "-"
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _c(
                      "el-card",
                      {
                        staticStyle: { "margin-top": "10px" },
                        attrs: { shadow: "never" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "title",
                            staticStyle: { "line-height": "25px" },
                          },
                          [
                            _c("FormLabel", {
                              staticStyle: {
                                "font-size": "15px",
                                "line-height": "16px",
                              },
                              attrs: { "form-label": "车辆信息" },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          { staticClass: "flexWarp", attrs: { gutter: 0 } },
                          [
                            _c(
                              "el-col",
                              {
                                attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 },
                              },
                              [
                                _c("div", { staticClass: "card_item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "card_item_label" },
                                    [_vm._v("车牌号：")]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.orderDetail.targetPlateNo
                                          ? _vm.orderDetail.targetPlateNo
                                          : "新车"
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                            _c(
                              "el-col",
                              {
                                attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 },
                              },
                              [
                                _c("div", { staticClass: "card_item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "card_item_label" },
                                    [_vm._v("车架号：")]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.orderDetail.targetVin
                                          ? _vm.orderDetail.targetVin
                                          : "-"
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                            _c(
                              "el-col",
                              {
                                attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 },
                              },
                              [
                                _c("div", { staticClass: "card_item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "card_item_label" },
                                    [_vm._v("厂家品牌/车系：")]
                                  ),
                                  _vm.orderDetail.productClassCode != "BZHG"
                                    ? _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.orderDetail.targetVhcBrandName
                                              ? _vm.orderDetail
                                                  .targetVhcBrandName
                                              : "-"
                                          ) +
                                            "/" +
                                            _vm._s(
                                              _vm.orderDetail
                                                .targetVhcSeriesName
                                                ? _vm.orderDetail
                                                    .targetVhcSeriesName
                                                : "-"
                                            )
                                        ),
                                      ])
                                    : _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.orderDetail.targetVhcSeriesName
                                              ? _vm.orderDetail
                                                  .targetVhcSeriesName
                                              : _vm.orderDetail
                                                  .targetVhcSeriesNo
                                              ? _vm.orderDetail
                                                  .targetVhcSeriesNo
                                              : "-"
                                          ) +
                                            "/" +
                                            _vm._s(
                                              _vm.orderDetail.targetVhcModelName
                                                ? _vm.orderDetail
                                                    .targetVhcModelName
                                                : _vm.orderDetail
                                                    .targetVhcModelNo
                                                ? _vm.orderDetail
                                                    .targetVhcModelNo
                                                : "-"
                                            )
                                        ),
                                      ]),
                                ]),
                              ]
                            ),
                            _vm._l(_vm.attributeList, function (item, index) {
                              return _c(
                                "el-col",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        (_vm.orderDetail.productClassCode ==
                                          "SB" &&
                                          (item.attributeName == "三保方案" ||
                                            item.attributeName == "保障范围" ||
                                            item.attributeName ==
                                              "行驶里程数" ||
                                            item.attributeName ==
                                              "置换险方案")) ||
                                        (_vm.orderDetail.productClassCode ==
                                          "DBC" &&
                                          item.attributeName == "购车发票日期")
                                          ? false
                                          : true,
                                      expression:
                                        "(orderDetail.productClassCode =='SB' && (item.attributeName == '三保方案' || item.attributeName == '保障范围' || item.attributeName == '行驶里程数'|| item.attributeName == '置换险方案')) || (orderDetail.productClassCode == 'DBC' && item.attributeName == '购车发票日期')?false:true",
                                    },
                                  ],
                                  key: index,
                                  attrs: {
                                    xs: 12,
                                    sm: 12,
                                    md: 8,
                                    lg: 8,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c("div", { staticClass: "card_item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "card_item_label" },
                                      [
                                        _vm._v(
                                          _vm._s(item.attributeName) + "："
                                        ),
                                      ]
                                    ),
                                    _vm.orderDetail.productClassCode == "BZHG"
                                      ? _c("div", [
                                          item.attributeName == "燃料类型" ||
                                          item.attributeName == "能源类型"
                                            ? _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.orderDetail
                                                        .targetFuelTypeDisplay ||
                                                        "-"
                                                    )
                                                ),
                                              ])
                                            : item.attributeName == "使用性质"
                                            ? _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.orderDetail
                                                        .targetUseAttributeDisplay ||
                                                        "-"
                                                    )
                                                ),
                                              ])
                                            : item.attributeName ==
                                              "购车发票日期"
                                            ? _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      (item.attributeValue &&
                                                        item.attributeValue.split(
                                                          " "
                                                        )[0]) ||
                                                        "-"
                                                    )
                                                ),
                                              ])
                                            : item.attributeName ==
                                              "新车销售日期"
                                            ? _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      (item.attributeValue &&
                                                        item.attributeValue.split(
                                                          " "
                                                        )[0]) ||
                                                        "-"
                                                    )
                                                ),
                                              ])
                                            : item.attributeName == "商业险起期"
                                            ? _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      (item.attributeValue &&
                                                        item.attributeValue.split(
                                                          " "
                                                        )[0] + " 0时") ||
                                                        "-"
                                                    )
                                                ),
                                              ])
                                            : item.attributeName == "商业险止期"
                                            ? _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      (item.attributeValue &&
                                                        item.attributeValue.split(
                                                          " "
                                                        )[0] + " 24时") ||
                                                        "-"
                                                    )
                                                ),
                                              ])
                                            : _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      item.attributeValue || "-"
                                                    )
                                                ),
                                              ]),
                                        ])
                                      : _c("div", [
                                          item.attributeName == "使用性质"
                                            ? _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.orderDetail
                                                        .targetUseAttributeDisplay ||
                                                        "-"
                                                    )
                                                ),
                                              ])
                                            : item.attributeName ==
                                              "购车发票日期"
                                            ? _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      (item.attributeValue &&
                                                        item.attributeValue.split(
                                                          " "
                                                        )[0]) ||
                                                        "-"
                                                    )
                                                ),
                                              ])
                                            : item.attributeName ==
                                              "新车销售日期"
                                            ? _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      (item.attributeValue &&
                                                        item.attributeValue.split(
                                                          " "
                                                        )[0]) ||
                                                        "-"
                                                    )
                                                ),
                                              ])
                                            : item.attributeName == "车辆类型"
                                            ? _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.orderDetail
                                                        .targetVehicleTypeDisplay ||
                                                        "-"
                                                    )
                                                ),
                                              ])
                                            : _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      item.attributeValue || "-"
                                                    )
                                                ),
                                              ]),
                                        ]),
                                  ]),
                                ]
                              )
                            }),
                            _vm.tabCode &&
                            (_vm.tabCode == "XXAX" || _vm.tabCode == "DXWY")
                              ? _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 12,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 6,
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "card_item" }, [
                                      _c(
                                        "div",
                                        { staticClass: "card_item_label" },
                                        [_vm._v("使用性质：")]
                                      ),
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.orderDetail
                                              .targetUseAttributeDisplay
                                              ? _vm.orderDetail
                                                  .targetUseAttributeDisplay
                                              : "-"
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                _c(
                  "el-card",
                  {
                    staticStyle: { "margin-top": "10px" },
                    attrs: { shadow: "never" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "title",
                        staticStyle: { "line-height": "25px" },
                      },
                      [
                        _c("FormLabel", {
                          staticStyle: {
                            "font-size": "15px",
                            "line-height": "16px",
                          },
                          attrs: { "form-label": "客户信息" },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { staticClass: "flexWarp", attrs: { gutter: 0 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 } },
                          [
                            _c("div", { staticClass: "card_item" }, [
                              _c("div", { staticClass: "card_item_label" }, [
                                _vm._v("车主名称："),
                              ]),
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.orderDetail.targetOwnerName
                                      ? _vm.orderDetail.targetOwnerName
                                      : "-"
                                  )
                                ),
                              ]),
                            ]),
                          ]
                        ),
                        _c(
                          "el-col",
                          { attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 } },
                          [
                            _c("div", { staticClass: "card_item" }, [
                              _c("div", { staticClass: "card_item_label" }, [
                                _vm._v("证件类型："),
                              ]),
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.orderDetail
                                      .targetOwnerCertificateTypeDisplay
                                      ? _vm.orderDetail
                                          .targetOwnerCertificateTypeDisplay
                                      : "-"
                                  )
                                ),
                              ]),
                            ]),
                          ]
                        ),
                        _c(
                          "el-col",
                          { attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 } },
                          [
                            _c("div", { staticClass: "card_item" }, [
                              _c("div", { staticClass: "card_item_label" }, [
                                _vm._v("证件号码："),
                              ]),
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.orderDetail.targetOwnerCertificateNo
                                      ? _vm.orderDetail.targetOwnerCertificateNo
                                      : "-"
                                  )
                                ),
                              ]),
                            ]),
                          ]
                        ),
                        _c(
                          "el-col",
                          { attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 } },
                          [
                            _c("div", { staticClass: "card_item" }, [
                              _c("div", { staticClass: "card_item_label" }, [
                                _vm._v("联系电话："),
                              ]),
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.orderDetail.targetOwnerMobile
                                      ? _vm.orderDetail.targetOwnerMobile
                                      : "-"
                                  )
                                ),
                              ]),
                            ]),
                          ]
                        ),
                        _c(
                          "el-col",
                          { attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 } },
                          [
                            _c("div", { staticClass: "card_item" }, [
                              _c("div", { staticClass: "card_item_label" }, [
                                _vm._v("电子邮箱："),
                              ]),
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.orderDetail.targetOwnerEmail
                                      ? _vm.orderDetail.targetOwnerEmail
                                      : "-"
                                  )
                                ),
                              ]),
                            ]),
                          ]
                        ),
                        _c(
                          "el-col",
                          { attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 } },
                          [
                            _c("div", { staticClass: "card_item" }, [
                              _c("div", { staticClass: "card_item_label" }, [
                                _vm._v("所在省市："),
                              ]),
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.orderDetail.targetOwnerProvinceName
                                      ? _vm.orderDetail.targetOwnerProvinceName
                                      : "-"
                                  ) +
                                    " " +
                                    _vm._s(
                                      _vm.orderDetail.targetOwnerCityName
                                        ? _vm.orderDetail.targetOwnerCityName
                                        : "-"
                                    )
                                ),
                              ]),
                            ]),
                          ]
                        ),
                        _c(
                          "el-col",
                          { attrs: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 } },
                          [
                            _c("div", { staticClass: "card_item" }, [
                              _c("div", { staticClass: "card_item_label" }, [
                                _vm._v("详细地址："),
                              ]),
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.orderDetail.targetOwnerAddress
                                      ? _vm.orderDetail.targetOwnerAddress
                                      : "-"
                                  )
                                ),
                              ]),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.orderDetail.productClassCode != "SB"
                  ? _c(
                      "el-card",
                      {
                        staticStyle: { "margin-top": "10px" },
                        attrs: { shadow: "never" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "title",
                            staticStyle: { "line-height": "25px" },
                          },
                          [
                            _c("FormLabel", {
                              staticStyle: {
                                "font-size": "15px",
                                "line-height": "16px",
                              },
                              attrs: { "form-label": "服务信息" },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          { staticClass: "flexWarp", attrs: { gutter: 0 } },
                          [
                            _c(
                              "el-col",
                              {
                                attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 },
                              },
                              [
                                _c("div", { staticClass: "card_item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "card_item_label" },
                                    [_vm._v("服务产品编码：")]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.orderDetail.productCode
                                          ? _vm.orderDetail.productCode
                                          : "-"
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                            _c(
                              "el-col",
                              {
                                attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 },
                              },
                              [
                                _c("div", { staticClass: "card_item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "card_item_label" },
                                    [_vm._v("服务产品名称：")]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.orderDetail.productName
                                          ? _vm.orderDetail.productName
                                          : "-"
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                            _vm.orderDetail.productClassCode == "DBC"
                              ? _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 12,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 6,
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "card_item" }, [
                                      _c(
                                        "div",
                                        { staticClass: "card_item_label" },
                                        [_vm._v("累计最多赔偿天数(天)：")]
                                      ),
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.orderDetail
                                              .targetCumulateMaxCompensationDay
                                              ? _vm.orderDetail
                                                  .targetCumulateMaxCompensationDay
                                              : "-"
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _vm.orderDetail.productClassCode == "DBC"
                              ? _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 12,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 6,
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "card_item" }, [
                                      _c(
                                        "div",
                                        { staticClass: "card_item_label" },
                                        [_vm._v("每次事故最多赔偿天数(天)：")]
                                      ),
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.orderDetail
                                              .targetAccidentMaxCompensationDay
                                              ? _vm.orderDetail
                                                  .targetAccidentMaxCompensationDay
                                              : "-"
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _vm.orderDetail.productClassCode == "DBC"
                              ? _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 12,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 6,
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "card_item" }, [
                                      _c(
                                        "div",
                                        { staticClass: "card_item_label" },
                                        [_vm._v("最大赔付金额(元)：")]
                                      ),
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.orderDetail
                                              .targetMaxCompensationAmount
                                              ? _vm.orderDetail.targetMaxCompensationAmount.toFixed(
                                                  2
                                                )
                                              : "-"
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "el-col",
                              {
                                attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 },
                              },
                              [
                                _c("div", { staticClass: "card_item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "card_item_label" },
                                    [_vm._v("服务保障期限(月)：")]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.orderDetail.productTimeLimit
                                          ? _vm.orderDetail.productTimeLimit
                                          : "-"
                                      ) + " 个月"
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                            _c(
                              "el-col",
                              {
                                attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 },
                              },
                              [
                                _c("div", { staticClass: "card_item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "card_item_label" },
                                    [_vm._v("服务生效起期：")]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.orderDetail.startTime
                                          ? _vm.orderDetail.startTime.slice(
                                              0,
                                              10
                                            ) + " 0时"
                                          : "-"
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                            _c(
                              "el-col",
                              {
                                attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 },
                              },
                              [
                                _c("div", { staticClass: "card_item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "card_item_label" },
                                    [_vm._v("服务生效止期：")]
                                  ),
                                  _vm.orderDetail &&
                                  _vm.orderDetail.productClassCode == "YB"
                                    ? _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.orderDetail
                                              .targetGuaranteeRange ==
                                              "双擎动力蓄电池"
                                              ? "终身"
                                              : _vm.orderDetail.endTime
                                              ? _vm.orderDetail
                                                  .extensionScheme == "终身"
                                                ? "终身"
                                                : _vm.orderDetail.endTime.slice(
                                                    0,
                                                    10
                                                  ) + " 24时"
                                              : "-"
                                          )
                                        ),
                                      ])
                                    : _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.orderDetail.endTime
                                              ? _vm.orderDetail
                                                  .extensionScheme == "终身"
                                                ? "终身"
                                                : _vm.orderDetail.endTime.slice(
                                                    0,
                                                    10
                                                  ) + " 24时"
                                              : "-"
                                          )
                                        ),
                                      ]),
                                ]),
                              ]
                            ),
                            _vm.orderDetail.productClassCode === "YB"
                              ? _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 12,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 6,
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "card_item" }, [
                                      _c(
                                        "div",
                                        { staticClass: "card_item_label" },
                                        [_vm._v("附加赠送：")]
                                      ),
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.orderDetail.bonusPacksName
                                              ? _vm.orderDetail.bonusPacksName
                                              : "-"
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "el-col",
                              {
                                attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 },
                              },
                              [
                                _c("div", { staticClass: "card_item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "card_item_label" },
                                    [_vm._v("销售方式：")]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.orderDetail.salesMethodDisplay
                                          ? _vm.orderDetail.salesMethodDisplay
                                          : "-"
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                            _c(
                              "el-col",
                              {
                                attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 },
                              },
                              [
                                _c("div", { staticClass: "card_item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "card_item_label" },
                                    [_vm._v("服务销售价(元)：")]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.orderDetail.targetSalesPrice
                                          ? _vm.orderDetail.targetSalesPrice.toFixed(
                                              2
                                            )
                                          : "-"
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _c(
                      "el-card",
                      {
                        staticStyle: { "margin-top": "10px" },
                        attrs: { shadow: "never" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "title",
                            staticStyle: { "line-height": "25px" },
                          },
                          [
                            _c("FormLabel", {
                              staticStyle: {
                                "font-size": "15px",
                                "line-height": "16px",
                              },
                              attrs: { "form-label": "服务信息" },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          { staticClass: "flexWarp", attrs: { gutter: 0 } },
                          [
                            _c(
                              "el-col",
                              {
                                attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 },
                              },
                              [
                                _c("div", { staticClass: "card_item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "card_item_label" },
                                    [_vm._v("服务产品编码：")]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.orderDetail.productCode
                                          ? _vm.orderDetail.productCode
                                          : "-"
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                            _c(
                              "el-col",
                              {
                                attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 },
                              },
                              [
                                _c("div", { staticClass: "card_item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "card_item_label" },
                                    [_vm._v("服务产品名称：")]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.orderDetail.productName
                                          ? _vm.orderDetail.productName
                                          : "-"
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                            _c(
                              "el-col",
                              {
                                attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 },
                              },
                              [
                                _c("div", { staticClass: "card_item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "card_item_label" },
                                    [_vm._v("销售方式：")]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.orderDetail.salesMethodDisplay
                                          ? _vm.orderDetail.salesMethodDisplay
                                          : "-"
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                            _c(
                              "el-col",
                              {
                                attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 },
                              },
                              [
                                _c("div", { staticClass: "card_item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "card_item_label" },
                                    [_vm._v("服务销售价(元)：")]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.orderDetail.targetSalesPrice
                                          ? _vm.orderDetail.targetSalesPrice.toFixed(
                                              2
                                            )
                                          : "-"
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          [
                            _c("el-col", { attrs: { span: 24 } }, [
                              _c(
                                "div",
                                { staticClass: "card_item" },
                                [
                                  _c(
                                    "el-divider",
                                    { attrs: { "content-position": "left" } },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            color: "#333",
                                            "font-size": "14px",
                                            "font-weight": "600",
                                          },
                                        },
                                        [_vm._v("三保方案")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c(
                              "el-col",
                              {
                                attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 },
                              },
                              [
                                _c("div", { staticClass: "card_item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "card_item_label" },
                                    [_vm._v("原厂质保公里数(万)：")]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.orderDetail
                                          .targetOriginalWarrantyMileage
                                          ? _vm.orderDetail
                                              .targetOriginalWarrantyMileage
                                          : "-"
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                            _c(
                              "el-col",
                              {
                                attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 },
                              },
                              [
                                _c("div", { staticClass: "card_item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "card_item_label" },
                                    [_vm._v("原厂质保期限(年)：")]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.orderDetail.originalWarrantyPeriod
                                          ? _vm.orderDetail
                                              .originalWarrantyPeriod
                                          : "-"
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                            _c(
                              "el-col",
                              {
                                attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 },
                              },
                              [
                                _c("div", { staticClass: "card_item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "card_item_label" },
                                    [_vm._v("原厂保修起期：")]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.orderDetail.originalStartTime
                                          ? _vm.orderDetail.originalStartTime.slice(
                                              0,
                                              10
                                            ) + " 0时"
                                          : "-"
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                            _c(
                              "el-col",
                              {
                                attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 },
                              },
                              [
                                _c("div", { staticClass: "card_item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "card_item_label" },
                                    [_vm._v("原厂保修止期：")]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.orderDetail.originalEndTime
                                          ? _vm.orderDetail.originalEndTime.slice(
                                              0,
                                              10
                                            ) + " 24时"
                                          : "-"
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          [
                            _c("el-col", { attrs: { span: 24 } }, [
                              _c(
                                "div",
                                { staticClass: "card_item" },
                                [
                                  _c(
                                    "el-divider",
                                    { attrs: { "content-position": "left" } },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            color: "#333",
                                            "font-size": "14px",
                                            "font-weight": "600",
                                          },
                                        },
                                        [_vm._v("延保方案")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c(
                              "el-col",
                              {
                                attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 },
                              },
                              [
                                _c("div", { staticClass: "card_item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "card_item_label" },
                                    [_vm._v("延保保障范围：")]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.orderDetail.targetGuaranteeRange
                                          ? _vm.orderDetail.targetGuaranteeRange
                                          : "-"
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                            _c(
                              "el-col",
                              {
                                attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 },
                              },
                              [
                                _c("div", { staticClass: "card_item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "card_item_label" },
                                    [_vm._v("延保方案：")]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.orderDetail.ternScheme
                                          ? _vm.orderDetail.ternScheme
                                          : "-"
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                            _c(
                              "el-col",
                              {
                                attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 },
                              },
                              [
                                _c("div", { staticClass: "card_item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "card_item_label" },
                                    [_vm._v("延保起期：")]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.orderDetail.owStartTime
                                          ? _vm.orderDetail.owStartTime.slice(
                                              0,
                                              10
                                            ) + " 0时"
                                          : "-"
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                            _c(
                              "el-col",
                              {
                                attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 },
                              },
                              [
                                _c("div", { staticClass: "card_item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "card_item_label" },
                                    [_vm._v("延保止期：")]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.orderDetail.owEndTime
                                          ? _vm.orderDetail.extensionScheme ==
                                            "终身"
                                            ? "终身"
                                            : _vm.orderDetail.owEndTime.slice(
                                                0,
                                                10
                                              ) + " 24时"
                                          : "-"
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                            _c(
                              "el-col",
                              {
                                attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 },
                              },
                              [
                                _c("div", { staticClass: "card_item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "card_item_label" },
                                    [_vm._v("已行驶里程：")]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.orderDetail.targetMileage
                                          ? _vm.orderDetail.targetMileage
                                          : "-"
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                            _c(
                              "el-col",
                              {
                                attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 },
                              },
                              [
                                _c("div", { staticClass: "card_item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "card_item_label" },
                                    [_vm._v("延长公里数(万)：")]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.orderDetail.targetExtendMileage
                                          ? _vm.orderDetail.targetExtendMileage
                                          : "-"
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          [
                            _c("el-col", { attrs: { span: 24 } }, [
                              _c(
                                "div",
                                { staticClass: "card_item" },
                                [
                                  _c(
                                    "el-divider",
                                    { attrs: { "content-position": "left" } },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            color: "#333",
                                            "font-size": "14px",
                                            "font-weight": "600",
                                          },
                                        },
                                        [_vm._v("代步车方案")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c(
                              "el-col",
                              {
                                attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 },
                              },
                              [
                                _c("div", { staticClass: "card_item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "card_item_label" },
                                    [_vm._v("代步车年限(年)：")]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.orderDetail
                                          .targetScooterYearLimit ||
                                          _vm.orderDetail
                                            .targetScooterYearLimit === 0
                                          ? _vm.orderDetail
                                              .targetScooterYearLimit
                                          : "-"
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                            _c(
                              "el-col",
                              {
                                attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 },
                              },
                              [
                                _c("div", { staticClass: "card_item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "card_item_label" },
                                    [_vm._v("代步车服务起期：")]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.orderDetail.dbcStartTime
                                          ? _vm.orderDetail.dbcStartTime.slice(
                                              0,
                                              10
                                            ) + " 0时"
                                          : "-"
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                            _c(
                              "el-col",
                              {
                                attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 },
                              },
                              [
                                _c("div", { staticClass: "card_item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "card_item_label" },
                                    [_vm._v("代步车服务止期：")]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.orderDetail.dbcEndTime
                                          ? _vm.orderDetail.dbcEndTime.slice(
                                              0,
                                              10
                                            ) + " 24时"
                                          : "-"
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          [
                            _c("el-col", { attrs: { span: 24 } }, [
                              _c(
                                "div",
                                { staticClass: "card_item" },
                                [
                                  _c(
                                    "el-divider",
                                    { attrs: { "content-position": "left" } },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            color: "#333",
                                            "font-size": "14px",
                                            "font-weight": "600",
                                          },
                                        },
                                        [_vm._v("车险置换方案")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c(
                              "el-col",
                              {
                                attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 },
                              },
                              [
                                _c("div", { staticClass: "card_item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "card_item_label" },
                                    [_vm._v("补偿限额(万)：")]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.orderDetail.limitCompensation
                                          ? _vm.orderDetail.limitCompensation
                                          : "-"
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                            _c(
                              "el-col",
                              {
                                attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 },
                              },
                              [
                                _c("div", { staticClass: "card_item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "card_item_label" },
                                    [_vm._v("置换年限(年)：")]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.orderDetail
                                          .targetScooterYearLimit ||
                                          _vm.orderDetail
                                            .targetScooterYearLimit === 0
                                          ? _vm.orderDetail
                                              .targetScooterYearLimit
                                          : "-"
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                            _c(
                              "el-col",
                              {
                                attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 },
                              },
                              [
                                _c("div", { staticClass: "card_item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "card_item_label" },
                                    [_vm._v("置换服务起期：")]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.orderDetail.zhStartTime
                                          ? _vm.orderDetail.zhStartTime.slice(
                                              0,
                                              10
                                            ) + " 0时"
                                          : "-"
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                            _c(
                              "el-col",
                              {
                                attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 },
                              },
                              [
                                _c("div", { staticClass: "card_item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "card_item_label" },
                                    [_vm._v("置换服务止期：")]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.orderDetail.zhEndTime
                                          ? _vm.orderDetail.zhEndTime.slice(
                                              0,
                                              10
                                            ) + " 24时"
                                          : "-"
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                _vm.orderDetail && _vm.orderDetail.status == "ISSUED"
                  ? _c(
                      "el-card",
                      {
                        staticStyle: { "margin-top": "10px" },
                        attrs: { shadow: "never" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "title",
                            staticStyle: {
                              "line-height": "25px",
                              display: "flex",
                              "align-items": "center",
                              height: "40px",
                            },
                          },
                          [
                            _c("FormLabel", {
                              staticStyle: {
                                "font-size": "15px",
                                "line-height": "16px",
                              },
                              attrs: { "form-label": "服务合同信息" },
                            }),
                            _c(
                              "el-button",
                              {
                                staticStyle: { height: "28px" },
                                attrs: {
                                  type: "primary",
                                  size: "mini",
                                  plain: "",
                                },
                                on: { click: _vm.showPreviewClicked },
                              },
                              [_vm._v("服务合同预览")]
                            ),
                          ],
                          1
                        ),
                        _vm.uploadProtocolTepmfileList &&
                        _vm.uploadProtocolTepmfileList.length < 6
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-upload",
                                  {
                                    staticClass: "upload-demo",
                                    attrs: {
                                      limit: 6,
                                      "before-remove": function () {
                                        return (_vm.formData = "")
                                      },
                                      action: "",
                                      multiple: "",
                                      "show-file-list": false,
                                      "http-request": function (val) {
                                        return _vm.XYuploadFile(val, "protocol")
                                      },
                                      "before-upload": function (val) {
                                        return _vm.XYbeforeUpload(
                                          val,
                                          "protocol"
                                        )
                                      },
                                      "file-list":
                                        _vm.uploadProtocolTepmfileList,
                                      accept: _vm.uploadType,
                                      "list-type": "text",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          width: "50px",
                                          height: "50px",
                                          "border-radius": "2px",
                                          "margin-left": "20px",
                                          display: "flex",
                                          "flex-direction": "row",
                                          "justify-content": "center",
                                          "align-items": "center",
                                          border: "1px solid #ddd",
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-plus avatar-uploader-icon",
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "el-row",
                          { attrs: { gutter: 0 } },
                          [
                            _vm.uploadProtocolTepmfileList &&
                            _vm.uploadProtocolTepmfileList.length != 0
                              ? _c(
                                  "el-col",
                                  { attrs: { span: 24 } },
                                  _vm._l(
                                    _vm.uploadProtocolTepmfileList,
                                    function (item, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass: "card_FileItem",
                                          staticStyle: {
                                            padding: "0 0 0  20px",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "card_file_img",
                                              staticStyle: {
                                                width: "auto",
                                                height: "20px",
                                                cursor: "pointer",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.XYdownloadFiles(
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "XYdownloadActice",
                                                      staticStyle: {
                                                        color: "#2150A3",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.protocolName
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "deleteIcon",
                                                  staticStyle: {
                                                    "margin-left": "10px",
                                                    cursor: "pointer",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.XYdelFileList(
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-circle-close",
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      "margin-left": "20px",
                                      "margin-top": "10px",
                                    },
                                    attrs: {
                                      size: "mini",
                                      type: "primary",
                                      plain: "",
                                    },
                                    on: { click: _vm.uploadProtocolTepmList },
                                  },
                                  [_vm._v("合同上传")]
                                ),
                                _vm.protocolSignDate
                                  ? _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          color: "blue",
                                          "margin-left": "5px",
                                          "font-size": "10px",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "(合同上传于：" +
                                            _vm._s(_vm.protocolSignDate) +
                                            ")"
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-dialog",
                  {
                    staticClass: "pageDialog",
                    attrs: {
                      visible: _vm.showPreview,
                      "close-on-click-modal": false,
                      width: "1024px",
                      "append-to-body": true,
                    },
                    on: {
                      "update:visible": function ($event) {
                        _vm.showPreview = $event
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "dialog-footer",
                        attrs: { slot: "title" },
                        slot: "title",
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-size": "15px",
                              "font-weight": "700",
                            },
                          },
                          [_vm._v("在线预览")]
                        ),
                      ]
                    ),
                    _c("div", [
                      _c("iframe", {
                        style: {
                          width: "1000px",
                          height: "calc(100vh - 210px)",
                        },
                        attrs: {
                          id: "previewIframe",
                          src: _vm.previewUrl,
                          frameborder: "0",
                        },
                      }),
                    ]),
                  ]
                ),
                (_vm.filesList && _vm.filesList.length != 0) ||
                (_vm.orderDetail.productClassCode == "TCWY" &&
                  ((_vm.pzFileList && _vm.pzFileList.length != 0) ||
                    (_vm.clFileList && _vm.clFileList.length != 0)))
                  ? _c(
                      "el-card",
                      {
                        staticStyle: { "margin-top": "10px" },
                        attrs: { shadow: "never" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "title",
                            staticStyle: {
                              "line-height": "25px",
                              display: "flex",
                              "align-items": "center",
                              height: "40px",
                            },
                          },
                          [
                            _c("FormLabel", {
                              staticStyle: {
                                "font-size": "15px",
                                "line-height": "16px",
                              },
                              attrs: { "form-label": "出单附件信息" },
                            }),
                            _vm.downloadlist && _vm.downloadlist.length != 0
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: { height: "28px" },
                                    attrs: {
                                      type: "primary",
                                      size: "mini",
                                      plain: "",
                                    },
                                    on: { click: _vm.batchDownload },
                                  },
                                  [_vm._v("批量下载")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm.orderDetail.productClassCode == "TCWY"
                          ? _c(
                              "el-row",
                              { attrs: { gutter: 0 } },
                              [
                                (_vm.pzFileList &&
                                  _vm.pzFileList.length != 0) ||
                                (_vm.clFileList && _vm.clFileList.length != 0)
                                  ? _c(
                                      "el-col",
                                      {
                                        staticStyle: { "margin-top": "10px" },
                                        attrs: { span: 24 },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.pzFileList &&
                                                  _vm.pzFileList.length != 0,
                                                expression:
                                                  "pzFileList&&pzFileList.length!=0",
                                              },
                                            ],
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "font-size": "14px",
                                                  "font-weight": "bold",
                                                },
                                              },
                                              [_vm._v("车辆凭证：")]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                  "flex-direction": "row",
                                                  "justify-content":
                                                    "flex-start",
                                                  "align-items": "center",
                                                  "flex-wrap": "wrap",
                                                },
                                              },
                                              _vm._l(
                                                _vm.pzFileList,
                                                function (fitem, index) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: index,
                                                      staticStyle: {
                                                        width: "100px",
                                                        height: "140px",
                                                        display: "flex",
                                                        "flex-direction":
                                                          "column",
                                                        "justify-content":
                                                          "flex-start",
                                                        "align-items": "center",
                                                        "margin-right": "10px",
                                                      },
                                                    },
                                                    [
                                                      _vm.getFileSuffix(
                                                        fitem.fileName
                                                      )
                                                        ? _c("el-image", {
                                                            staticStyle: {
                                                              width: "100px",
                                                              height: "100px",
                                                              cursor: "pointer",
                                                            },
                                                            attrs: {
                                                              src: fitem.fileUrl,
                                                              "preview-src-list":
                                                                [fitem.fileUrl],
                                                            },
                                                          })
                                                        : _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                fitem.fileName
                                                              )
                                                            ),
                                                          ]),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            "margin-top":
                                                              "10px",
                                                            "font-size": "10px",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              fitem.fileTypeName
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.clFileList &&
                                                  _vm.clFileList.length != 0,
                                                expression:
                                                  "clFileList&&clFileList.length!=0",
                                              },
                                            ],
                                            staticStyle: {
                                              "margin-top": "10px",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "font-size": "14px",
                                                  "font-weight": "bold",
                                                },
                                              },
                                              [_vm._v("车辆图片：")]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                  "flex-direction": "row",
                                                  "justify-content":
                                                    "flex-start",
                                                  "align-items": "center",
                                                  "flex-wrap": "wrap",
                                                },
                                              },
                                              _vm._l(
                                                _vm.clFileList,
                                                function (fitem, index) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: index,
                                                      staticStyle: {
                                                        width: "100px",
                                                        height: "140px",
                                                        display: "flex",
                                                        "flex-direction":
                                                          "column",
                                                        "justify-content":
                                                          "flex-start",
                                                        "align-items": "center",
                                                        "margin-right": "10px",
                                                      },
                                                    },
                                                    [
                                                      _vm.getFileSuffix(
                                                        fitem.fileName
                                                      )
                                                        ? _c("el-image", {
                                                            staticStyle: {
                                                              width: "100px",
                                                              height: "100px",
                                                              cursor: "pointer",
                                                            },
                                                            attrs: {
                                                              src: fitem.fileUrl,
                                                              "preview-src-list":
                                                                [fitem.fileUrl],
                                                            },
                                                          })
                                                        : _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                fitem.fileName
                                                              )
                                                            ),
                                                          ]),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            "margin-top":
                                                              "10px",
                                                            "font-size": "10px",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              fitem.fileTypeName
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c("el-col", { attrs: { span: 24 } }, [
                                      _c(
                                        "div",
                                        { staticClass: "card_FileItem" },
                                        [_vm._v(" 暂无数据 ")]
                                      ),
                                    ]),
                              ],
                              1
                            )
                          : _c(
                              "el-row",
                              { attrs: { gutter: 0 } },
                              [
                                _vm.filesList && _vm.filesList.length != 0
                                  ? _c(
                                      "el-col",
                                      { attrs: { span: 24 } },
                                      _vm._l(
                                        _vm.filesList,
                                        function (item, index) {
                                          return _c(
                                            "div",
                                            {
                                              key: index,
                                              staticClass: "card_FileItem",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "font-size": "14px",
                                                    "font-weight": "bold",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(item.typeName) + "："
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                    "flex-direction": "row",
                                                    "justify-content":
                                                      "flex-start",
                                                    "align-items": "center",
                                                    "flex-wrap": "wrap",
                                                  },
                                                },
                                                _vm._l(
                                                  item.fileLists,
                                                  function (fitem, findex) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: findex,
                                                        staticStyle: {
                                                          width: "100px",
                                                          height: "140px",
                                                          display: "flex",
                                                          "flex-direction":
                                                            "column",
                                                          "justify-content":
                                                            "flex-start",
                                                          "align-items":
                                                            "center",
                                                          "margin-right":
                                                            "10px",
                                                        },
                                                      },
                                                      [
                                                        _vm.getFileSuffix(
                                                          fitem.fileName
                                                        )
                                                          ? _c("el-image", {
                                                              staticStyle: {
                                                                width: "100px",
                                                                height: "100px",
                                                                cursor:
                                                                  "pointer",
                                                              },
                                                              attrs: {
                                                                src: fitem.fileUrl,
                                                                "preview-src-list":
                                                                  [
                                                                    fitem.fileUrl,
                                                                  ],
                                                              },
                                                            })
                                                          : _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  fitem.fileName
                                                                )
                                                              ),
                                                            ]),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  : _c("el-col", { attrs: { span: 24 } }, [
                                      _c(
                                        "div",
                                        { staticClass: "card_FileItem" },
                                        [_vm._v(" 暂无数据 ")]
                                      ),
                                    ]),
                              ],
                              1
                            ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
        _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
          _c("div", { staticClass: "footer-btn" }, [
            _c("div", { staticClass: "footer-btn-list" }, [
              _c(
                "div",
                {
                  staticStyle: { display: "flex", "justify-content": "center" },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-close", size: "mini" },
                      on: { click: _vm.closePage },
                    },
                    [_vm._v("关闭")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }