import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import Pagination from '@/components/Pagination';
import ImportDialog from './dialog/importDialog';
import ViewDialog from './dialog/viewDialog';
import reportRequest from '@/api/reportForm';
import OemSelect from '@/components/OemSelect';
import OrgSelect from '@/components/OrgSelect';
import { getUserOrgInfoDealerOem, commonExport } from '@/utils/common';
export default {
  name: 'IncomeStatement',
  components: {
    Pagination: Pagination,
    ImportDialog: ImportDialog,
    ViewDialog: ViewDialog,
    OemSelect: OemSelect,
    OrgSelect: OrgSelect
  },
  mixins: [initHeight],
  data: function data() {
    return {
      bizType: localStorage.getItem("bizType") || '',
      userInfo: JSON.parse(localStorage.getItem("userInfo")) || {},
      tableData: [],
      searchForm: {
        orgCode: '',
        statisticsType: '',
        areaType: ''
      },
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      loading: false,
      showImportDialog: false,
      showViewDialog: false,
      currentStatisticsId: '',
      currentName: '',
      surfaceIds: [],
      orgForm: {
        salesOrgCode: "",
        salesOrgName: "",
        salesOrgType: ""
      }
    };
  },
  watch: {
    '$route': {
      handler: function handler(val) {
        switch (val.path) {
          // 利润表
          case "/superviseReport/incomeStatement":
            this.searchForm.statisticsType = 'PROFIT';
            this.currentName = '利润表';
            break;
          // 资产负债表
          case "/superviseReport/assetsLiabilities":
            this.searchForm.statisticsType = 'BALANCE';
            this.currentName = '资产负债表';
            break;
          // 机构情况表
          case "/superviseReport/institutionalSituation":
            this.searchForm.statisticsType = 'ORG';
            this.currentName = '机构情况表';
            break;
          // 产险业务表
          case "/superviseReport/productInsurance":
            this.searchForm.statisticsType = 'INSURANCE';
            this.currentName = '产险业务表';
            break;
        }
        this.initData();
      },
      immediate: true
    }
  },
  methods: {
    // 初始化数据
    initData: function initData() {
      if (this.bizType === "DEALER") {
        var orgObj = getUserOrgInfoDealerOem(this.bizType);
        this.searchForm.orgCode = orgObj.salesOrgCode;
        this.searchForm.orgName = orgObj.salesOrgName;
      }
      this.loadData();
    },
    // 树形点击
    nodeClick: function nodeClick(data) {
      if (this.bizType === "BROKER") {
        this.searchForm.orgCode = data.salesOrgCode;
      }
      if (this.bizType === "OEM") {
        this.searchForm.orgCode = data.salesOrgCode;
        this.searchForm.areaType = data.salesOrgType;
      }
    },
    // 导出
    exportFn: function exportFn(row) {
      var postData = [];
      var name = '';
      var url = '';
      switch (row.statisticsType) {
        // 利润表
        case "PROFIT":
          postData = [{
            property: 'projectName',
            label: '项目'
          }, {
            property: 'sort',
            label: '行次'
          }, {
            property: 'currentMonth',
            label: '本月数'
          }, {
            property: 'currentYear',
            label: '本年数'
          }];
          name = "".concat(row.statisticsTypeDisplay, "-").concat(row.orgName);
          url = "/vi-core-service/supervises/".concat(row.statisticsId, "/profit");
          break;

        // 资产负债表
        case "BALANCE":
          postData = [{
            property: 'projectName',
            label: '项目'
          }, {
            property: 'sort',
            label: '行次'
          }, {
            property: 'yearStart',
            label: '年初数'
          }, {
            property: 'yearEnd',
            label: '期末数'
          }];
          name = "".concat(row.statisticsTypeDisplay, "-").concat(row.orgName);
          url = "/vi-core-service/supervises/".concat(row.statisticsId, "/balance");
          break;

        // 机构情况表
        case "ORG":
          postData = [{
            property: 'projectName',
            label: '项目'
          }, {
            property: 'sort',
            label: '行次'
          }, {
            property: 'quantity',
            label: '数值'
          }];
          name = "".concat(row.statisticsTypeDisplay, "-").concat(row.orgName);
          url = "/vi-core-service/supervises/".concat(row.statisticsId, "/org");
          break;
        // 产险业务表
        case "INSURANCE":
          postData = [{
            property: 'insuranceName',
            label: '产险业务'
          }, {
            property: 'sort',
            label: '行次'
          }, {
            property: 'standardPremium',
            label: '保费金额累计'
          }, {
            property: 'actualPremium',
            label: '应付保费累计'
          }, {
            property: 'commission',
            label: '经纪佣金累计'
          }, {
            property: 'autotrophyPremium',
            label: '其中自营网络平台渠道保费金额'
          }, {
            property: 'autotrophyCommission',
            label: '其中自营网络平台渠道经纪佣金'
          }, {
            property: 'thirdpartyPremium',
            label: '其中第三方网络平台渠道保费金额'
          }, {
            property: 'thirdpartyCommission',
            label: '其中第三方网络平台渠道经纪佣金'
          }];
          name = "".concat(row.statisticsTypeDisplay, "-").concat(row.orgName);
          url = "/vi-core-service/supervises/".concat(row.statisticsId, "/insurance");
          break;
      }
      var exporParams = {
        total: 29999,
        fileName: name,
        exportUrl: url,
        exportData: postData
      };
      commonExport(exporParams);
    },
    // 导入
    importFn: function importFn() {
      this.showImportDialog = true;
    },
    // 刷新
    breakData: function breakData() {
      // this.resetSearch()
      this.loadData();
    },
    // 重置搜索表单
    resetSearch: function resetSearch() {
      this.searchForm = {
        statisticsType: this.searchForm.statisticsType,
        orgCode: this.bizType === 'DEALER' ? this.searchForm.orgCode : '',
        orgName: this.bizType === 'DEALER' ? this.searchForm.orgName : '',
        areaType: ''
      };
      this.orgForm = {
        salesOrgCode: "",
        salesOrgName: this.bizType === 'OEM' ? "全部" : '',
        salesOrgType: ""
      };
    },
    // 搜索
    searchData: function searchData() {
      this.loadData();
    },
    //获取列表数据
    loadData: function loadData() {
      var _this = this;
      this.loading = true;
      this.showImportDialog = false;
      this.showViewDialog = false;
      this.surfaceIds = [];
      reportRequest.getSuperviseList(this.listQuery.pageNum, this.listQuery.pageSize, this.searchForm).then(function (res) {
        _this.loading = false;
        if (res.code === 200) {
          _this.tableData = res.data.list;
          _this.total = res.data.page.recordsTotal;
        }
      }).catch(function (err) {
        _this.loading = false;
      });
    },
    // 勾选复选框
    handleSelectionChange: function handleSelectionChange(val) {
      this.surfaceIds = val.map(function (val) {
        return val.statisticsId;
      });
    },
    // 删除
    remove: function remove(row) {
      var _this2 = this;
      var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      if (type) {
        if (!this.surfaceIds.length) {
          this.$message.warning('请勾选要删除的数据');
        } else {
          this.$confirm("您确定删除选中的数据吗?", '提示', {
            distinguishCancelAndClose: true,
            confirmButtonText: "确定",
            type: 'warning'
          }).then(function () {
            _this2.removePublic(_this2.surfaceIds);
          }).catch(function () {});
        }
      } else {
        this.$confirm("您确定删除该数据吗?", '提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: "确定",
          type: 'warning'
        }).then(function () {
          _this2.removePublic([row.statisticsId]);
        }).catch(function () {});
      }
    },
    // 删除方法
    removePublic: function removePublic(idArr) {
      var _this3 = this;
      var data = {
        idList: idArr,
        statisticsType: this.searchForm.statisticsType
      };
      reportRequest.deleteSuperviseList(data).then(function (res) {
        if (res.code === 200) {
          _this3.$message.success(res.msg);
          _this3.loadData();
        }
      });
    },
    // 明细
    toView: function toView(row) {
      this.showViewDialog = true;
      this.currentStatisticsId = row.statisticsId.toString();
    }
  }
};