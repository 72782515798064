import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import dict from '@/mixins/dict';
import Pagination from '@/components/Pagination';
import business from '@/api/insuranceBusiness';
import { getSupplyers } from '@/utils/common';
export default {
  components: {
    Pagination: Pagination
  },
  mixins: [initHeight, dict],
  computed: {},
  data: function data() {
    return {
      applyData: [{
        label: "未申请",
        value: 'NOT_APPLY'
      }, {
        label: "待审核",
        value: 'WAIT_APPLY'
      }, {
        label: "申请驳回",
        value: 'PENDING_AUDIT'
      }, {
        label: "已开通",
        value: 'AUDIT_SUCCEEDED'
      }, {
        label: "禁用",
        value: 'DISABLE'
      }],
      drawer: false,
      showImport: 'automobileBrandMaintenance',
      searchForm: {},
      form: {},
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      loading: false,
      highlightCurrent: true,
      showDialog: false,
      opType: '',
      insCorpItem: {},
      showImportDialog: false,
      supplyers: []
    };
  },
  created: function created() {},
  mounted: function mounted() {
    //保司
    this.supplyers = getSupplyers();
    this.loadData();
  },
  methods: {
    getInsCorpFullName: function getInsCorpFullName(insCorpCode) {
      var supplyer = _.find(this.supplyers, function (item) {
        return _.toUpper(item.code) == _.toUpper(insCorpCode);
      });
      return supplyer && supplyer.fullName;
    },
    breakData: function breakData() {
      // this.resetSearch()
      this.loadData();
    },
    // 提交申请、申请详情
    toDetails: function toDetails(type, item) {
      this.$router.push({
        path: '/submitBusiness',
        query: {
          applyId: item.applyId,
          name: type,
          insCorpCode: item.insCorpCode,
          insCorpName: item.insCorpName
        }
      });
    },
    addInscorp: function addInscorp(type) {
      //新增
      this.opType = type;
      this.showDialog = true;
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {};
    },
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      //搜索
      this.loadData();
    },
    loadData: function loadData() {
      var _this = this;
      //获取列表数据
      // this.loading = true;
      this.showDialog = false;
      this.showImportDialog = false;
      this.drawer = false;
      business.getList(this.listQuery.pageNum, this.listQuery.pageSize, this.searchForm).then(function (res) {
        if (res.code === 200) {
          _this.tableData = res.data.list;
          _this.loading = false;
          _this.total = res.data.page.recordsTotal;
        }
      }).catch(function (err) {
        _this.loading = false;
      });
    }
  }
};