import _defineProperty from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/defineProperty.js";
var _methods;
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.find.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import orgRequest from '@/api/orgChildren';
import employeeRequest from '@/api/employee';
import customersRequest from '@/api/customers';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    customersIds: {
      type: Array,
      default: []
    },
    bizType: {
      type: String,
      default: ''
    },
    dealerOemUsers: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      form: {
        ownerOrgName: "",
        ownerBy: ""
      },
      listData: [],
      listLoading: false,
      salesman: [],
      orgData: [],
      users: [],
      defaultProps: {
        children: 'children',
        label: 'fullName'
      },
      rules: {
        ownerOrgName: [{
          required: true,
          message: '请选择业务机构',
          trigger: 'change'
        }],
        ownerBy: [{
          required: true,
          message: '请选择业务经理',
          trigger: 'change'
        }]
      }
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.getOrgTree();
        this.form = {
          ownerOrgName: "",
          ownerBy: ""
        };
        this.$refs.detail.resetFields();
      }
    }
  },
  mounted: function mounted() {
    this.initData();
    if (this.bizType === 'BROKER') {
      this.getOrgTree();
    } else {
      this.users = this.dealerOemUsers;
    }
  },
  methods: (_methods = {
    visibleChange: function visibleChange(val) {
      if (val) {
        // this.form.ownerOrgName = "";
      }
    },
    filterNode: function filterNode(value, data) {
      //机构树查询
      if (!value) return true;
      return data.fullName.indexOf(value) !== -1;
    },
    dataFilter: function dataFilter(val) {
      //机构树查询
      this.$refs.tree.filter(val);
    }
  }, _defineProperty(_methods, "visibleChange", function visibleChange(val) {
    //出单机构树下拉列表是否可见监听
    if (this.$refs.orgSelect && (this.$refs.orgSelect.query || this.$refs.orgSelect.query == '') && this.$refs.tree && !this.form.ownerOrgName) {
      this.changeTreeNodeStatus(this.$refs.tree.store.root, val);
    }
  }), _defineProperty(_methods, "changeTreeNodeStatus", function changeTreeNodeStatus(node, expandAll) {
    //改变节点的自身expanded状态
    node.expanded = expandAll;
    for (var i = 0; i < node.childNodes.length; i++) {
      // 改变节点的自身expanded状态
      node.childNodes[i].expanded = expandAll;
      // 遍历子节点
      // if (node.childNodes[i].childNodes.length > 0) {
      //     this.changeTreeNodeStatus(node.childNodes[i], expandAll);
      // }
    }
  }), _defineProperty(_methods, "orgFocus", function orgFocus(val) {
    //组织机构树焦点监听
    if (this.$refs.orgSelect && (this.$refs.orgSelect.query || this.$refs.orgSelect.query == '')) {
      this.$refs.tree.filter('');
      this.$forceUpdate();
    }
  }), _defineProperty(_methods, "orgChange", function orgChange(val) {
    //组织机构树选择监听
    this.users = [];
    this.$refs['detail'].resetFields();
    this.selectInsuranceCompanys = [];
    this.$forceUpdate();
  }), _defineProperty(_methods, "getOrgTree", function getOrgTree() {
    var _this = this;
    var parentId = 0;
    orgRequest.getTree(parentId).then(function (res) {
      if (res.data) {
        _this.orgData = [res.data];
      }
    });
  }), _defineProperty(_methods, "getUsers", function getUsers(orgCode) {
    var _this2 = this;
    //获取机构人员列表
    if (!orgCode) return;
    employeeRequest.salesmanByOrgCode(orgCode).then(function (res) {
      if (res.data) {
        _this2.users = res.data;
      }
    });
  }), _defineProperty(_methods, "handleNodeClick", function handleNodeClick(data, node, target) {
    //节点点击事件
    this.orgChange();
    this.getUsers(data.orgCode);
    this.form.ownerOrgName = data.fullName;
    this.form.ownerOrgCode = data.orgCode;
    this.$refs['orgSelect'].blur();
    this.$forceUpdate();
  }), _defineProperty(_methods, "salemanNameChange", function salemanNameChange(val) {
    //监听选择业务员事件
    if (val) {
      var _user = _.find(this.users, function (item) {
        return item.userId == val;
      });
      if (_user) {
        // this.form.salemanBy = _user.userId;
        this.form.ownerByName = _user.userName;
        if (this.bizType !== 'BROKER') {
          this.form.ownerOrgCode = _user.dealerCode;
        }
        this.$forceUpdate();
      }
    }
  }), _defineProperty(_methods, "initData", function initData() {//初始化数据
  }), _defineProperty(_methods, "loadData", function loadData() {//加载数据
  }), _defineProperty(_methods, "save", function save() {
    var _this3 = this;
    //保存数据
    // this.rules.ownerBy = { required: true, message: '请选择业务经理', trigger: 'change' };
    this.$refs.detail.validate(function (valid) {
      if (valid) {
        _this3.form.customerIds = _this3.customersIds;
        customersRequest.distribution(_this3.form).then(function (res) {
          if (res.code === 200) {
            _this3.$message({
              message: res.msg,
              type: 'success'
            });
            _this3.closePage();
          }
        });
      }
    });
  }), _defineProperty(_methods, "closePage", function closePage() {
    //关闭弹窗
    this.$emit('closePage');
  }), _methods)
};