var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "app-m-cls",
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "500px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.formRules,
                    "label-width": "130px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "供应商名称：",
                                        prop: "ocrSupplier",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            size: "mini",
                                            disabled:
                                              _vm.dialogType == "view" ||
                                              _vm.dialogType == "edit",
                                            clearable: "",
                                            placeholder: "请选择规则类型",
                                          },
                                          on: {
                                            change: _vm.getOCRSupplierType,
                                          },
                                          model: {
                                            value: _vm.form.ocrSupplier,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "ocrSupplier",
                                                $$v
                                              )
                                            },
                                            expression: "form.ocrSupplier",
                                          },
                                        },
                                        _vm._l(
                                          _vm.suppliers,
                                          function (item, index) {
                                            return _c("el-option", {
                                              key: index,
                                              attrs: {
                                                label: item.itemName,
                                                value: item.itemCode,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "供应商账户编码：",
                                        prop: "ocrSupplierCode",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          maxlength: "40",
                                          disabled:
                                            _vm.dialogType == "view" ||
                                            _vm.dialogType == "edit",
                                          placeholder: "请输入供应商账户编码",
                                        },
                                        model: {
                                          value: _vm.form.ocrSupplierCode,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "ocrSupplierCode",
                                              $$v
                                            )
                                          },
                                          expression: "form.ocrSupplierCode",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "供应商账户名称：",
                                        prop: "ocrSupplierName",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          maxlength: "40",
                                          disabled: _vm.dialogType == "view",
                                          placeholder: "请输入供应商账户名称",
                                        },
                                        model: {
                                          value: _vm.form.ocrSupplierName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "ocrSupplierName",
                                              $$v
                                            )
                                          },
                                          expression: "form.ocrSupplierName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "供应商功能：",
                                        prop: "ocrFunctions",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-checkbox-group",
                                        {
                                          attrs: {
                                            disabled: _vm.dialogType == "view",
                                          },
                                          model: {
                                            value: _vm.form.ocrFunctions,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "ocrFunctions",
                                                $$v
                                              )
                                            },
                                            expression: "form.ocrFunctions",
                                          },
                                        },
                                        _vm._l(
                                          _vm.OCRFunction,
                                          function (item, index) {
                                            return _c(
                                              "el-checkbox",
                                              {
                                                key: index,
                                                attrs: { label: item.itemCode },
                                              },
                                              [_vm._v(_vm._s(item.itemName))]
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.form.ocrSupplier
                            ? _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-divider",
                                        {
                                          attrs: { "content-position": "left" },
                                        },
                                        [_vm._v("供应商应用配置")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.form.ocrSupplier
                            ? _c(
                                "el-row",
                                _vm._l(_vm.itemList, function (item, index) {
                                  return _c(
                                    "el-col",
                                    { key: index, attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "line-height": "40px",
                                          },
                                          attrs: {
                                            "label-width": "130px",
                                            label: item.display,
                                            prop: item.storeKey,
                                            rules: {
                                              required:
                                                _vm.dialogType == "view"
                                                  ? false
                                                  : item.required,
                                              message: "请输入" + item.display,
                                              trigger: ["blur", "change"],
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "max-width": "100%",
                                              },
                                            },
                                            [
                                              _vm._.toLower(item.inputType) ==
                                                "text" ||
                                              _vm._.toLower(item.inputType) ==
                                                "number"
                                                ? _c("el-input", {
                                                    attrs: {
                                                      clearable: "",
                                                      size: "mini",
                                                      disabled:
                                                        _vm.dialogType ==
                                                        "view",
                                                      type: _vm._.toLower(
                                                        item.inputType
                                                      ),
                                                      placeholder:
                                                        _vm._.toLower(
                                                          item.inputType
                                                        ) != "select" &&
                                                        _vm._.toLower(
                                                          item.inputType
                                                        ) != "multi_ select" &&
                                                        _vm._.toLower(
                                                          item.inputType
                                                        ) != "datetime"
                                                          ? "请输入" +
                                                            item.display
                                                          : "请选择" +
                                                            item.display,
                                                    },
                                                    on: {
                                                      input: _vm.inputValue,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form[item.storeKey],
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          item.storeKey,
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form[item.storeKey]",
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm._.toLower(item.inputType) ==
                                                "select" ||
                                              _vm._.toLower(item.inputType) ==
                                                "multi_ select"
                                                ? _c(
                                                    "el-select",
                                                    {
                                                      staticStyle: {
                                                        width: "100%",
                                                      },
                                                      attrs: {
                                                        placeholder: "请选择",
                                                        multiple:
                                                          _vm._.toLower(
                                                            item.inputType
                                                          ) == "multi_ select",
                                                        size: "mini",
                                                        clearable: "",
                                                        disabled:
                                                          _vm.dialogType ==
                                                          "view",
                                                      },
                                                      on: {
                                                        change: _vm.changeValue,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form[
                                                            item.storeKey
                                                          ],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form,
                                                            item.storeKey,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form[item.storeKey]",
                                                      },
                                                    },
                                                    _vm._l(
                                                      item.options,
                                                      function (_item, _index) {
                                                        return _c("el-option", {
                                                          key: _index,
                                                          attrs: {
                                                            label: _item.name,
                                                            value: _item.value,
                                                          },
                                                        })
                                                      }
                                                    ),
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm._.toLower(item.inputType) ==
                                                "datetime" ||
                                              _vm._.toLower(item.inputType) ==
                                                "date"
                                                ? _c("el-date-picker", {
                                                    staticStyle: {
                                                      width: "100%",
                                                    },
                                                    attrs: {
                                                      disabled:
                                                        _vm.dialogType ==
                                                        "view",
                                                      clearable: "",
                                                      size: "mini",
                                                      type:
                                                        _vm._.toLower(
                                                          item.inputType
                                                        ) == "datetime"
                                                          ? "datetime"
                                                          : _vm._.toLower(
                                                              item.inputType
                                                            ),
                                                      format:
                                                        _vm._.toLower(
                                                          item.inputType
                                                        ) == "datetime"
                                                          ? "yyyy-MM-dd HH:mm:ss"
                                                          : "yyyy-MM-dd",
                                                      "value-format":
                                                        _vm._.toLower(
                                                          item.inputType
                                                        ) == "datetime"
                                                          ? "yyyy-MM-dd HH:mm:ss"
                                                          : "yyyy-MM-dd",
                                                      placeholder:
                                                        _vm._.toLower(
                                                          item.inputType
                                                        ) == "datetime"
                                                          ? "请选择" +
                                                            item.display
                                                          : "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form[item.storeKey],
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          item.storeKey,
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form[item.storeKey]",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "max-width": "100%",
                                                "justify-content":
                                                  "space-between",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    color: "#999",
                                                    "line-height": "20px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      "[" + item.storeKey + "]"
                                                    ) + " "
                                                  ),
                                                ]
                                              ),
                                              item.description
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        color: "red",
                                                        "line-height": "20px",
                                                        "margin-left": "10px",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          "[" +
                                                            item.description +
                                                            "]"
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "line-height": "46px",
            height: "46px",
            padding: "8px 0px",
            "margin-top": "5px",
          },
        },
        [
          _vm.dialogType !== "view"
            ? _c(
                "el-button",
                {
                  staticStyle: { width: "80px" },
                  attrs: {
                    size: "mini",
                    disabled: _vm.dialogType == "view",
                    type: "primary",
                    plain: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.confirmCorpId("ruleForm")
                    },
                  },
                },
                [_vm._v("确认")]
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              staticStyle: { width: "80px" },
              attrs: { sizi: "mini" },
              on: { click: _vm.closeRuleDialog },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }