var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-m-cls" },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          attrs: { slot: "content" },
          slot: "content",
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "div",
                { attrs: { slot: "header" }, slot: "header" },
                [
                  _c(
                    "Search",
                    {
                      on: {
                        searchData: _vm.searchData,
                        resetSearch: _vm.resetSearch,
                        breakData: _vm.breakData,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "btnRigth",
                          fn: function () {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openDialog("add", {})
                                    },
                                  },
                                },
                                [_vm._v("新增")]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c("span", [_vm._v("字段编码：")]),
                      _c("el-input", {
                        attrs: {
                          size: "mini",
                          placeholder: "请输入字段编码",
                          clearable: "",
                        },
                        model: {
                          value: _vm.searchForm.fieldCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchForm, "fieldCode", $$v)
                          },
                          expression: "searchForm.fieldCode",
                        },
                      }),
                      _c("span", [_vm._v("字段名称：")]),
                      _c("el-input", {
                        attrs: {
                          size: "mini",
                          placeholder: "请输入字段名称",
                          clearable: "",
                        },
                        model: {
                          value: _vm.searchForm.fieldName,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchForm, "fieldName", $$v)
                          },
                          expression: "searchForm.fieldName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  ref: "multipleTable",
                  staticStyle: { width: "100%" },
                  attrs: {
                    size: "mini",
                    border: "",
                    data: _vm.tableData,
                    height: _vm.tableHeightSS,
                    "header-cell-style": { background: "#F7F7F7" },
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "fieldCode",
                      width: "180px",
                      label: "字段编码",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "fieldName",
                      width: "180px",
                      label: "字段名称",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "methodName",
                      label: "识别方式",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "pageIndex",
                      label: "所在页码",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        label: "取值定位配置",
                        "show-overflow-tooltip": "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "supportTypeDisplay",
                          label: "判断方式",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "readTarget",
                          label: "目标文本",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "readValue",
                          label: "取值文本",
                          "show-overflow-tooltip": "",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        label: "表格相关配置",
                        "show-overflow-tooltip": "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "getCellTypeDisplay",
                          label: "定位方式",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "cellRowIndex",
                          label: "行号",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "cellColIndex",
                          label: "列号",
                          "show-overflow-tooltip": "",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("el-table-column", {
                    attrs: {
                      prop: "convertDataType",
                      label: "数据转换",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        label: "字符串截取",
                        "show-overflow-tooltip": "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "start",
                          label: "起",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "end",
                          label: "止",
                          "show-overflow-tooltip": "",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        label: "字符串替换",
                        "show-overflow-tooltip": "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "regex",
                          label: "正则表达式",
                          width: "100",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "replacement",
                          label: "替换字符",
                          "show-overflow-tooltip": "",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        label: "循环查询区间参数",
                        "show-overflow-tooltip": "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "text",
                          label: "文本",
                          width: "100",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "minI",
                          label: "minI",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "maxI",
                          label: "maxI",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "minJ",
                          label: "minJ",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "maxJ",
                          label: "maxJ",
                          "show-overflow-tooltip": "",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-table-column",
                    {
                      attrs: { label: "方框参数", "show-overflow-tooltip": "" },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "rectangleLeft",
                          label: "left",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "rectangleTop",
                          label: "top",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "rectangleWidth",
                          label: "width",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "rectangleHeight",
                          label: "height",
                          "show-overflow-tooltip": "",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-table-column",
                    {
                      attrs: { label: "险种配置", "show-overflow-tooltip": "" },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "riskTypeList",
                          label: "列表形式",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "riskTypeIndex",
                          label: "列号",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "riskTypeEndTag",
                          label: "结束标志",
                          "show-overflow-tooltip": "",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("el-table-column", {
                    attrs: {
                      prop: "createdTime",
                      label: "创建时间",
                      width: "150px",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      align: "center",
                      width: "150",
                      fixed: "right",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.openDialog("edit", scope.row)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.deleteTemplateConfig(scope.row)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("pagination", {
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.pageNum,
                  limit: _vm.listQuery.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageNum", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageSize", $event)
                  },
                  pagination: function ($event) {
                    return _vm.loadData()
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { size: "mini" },
              on: {
                click: function ($event) {
                  return _vm.closePage(false)
                },
              },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
      _c("ConfigDetail", {
        attrs: {
          templateId: _vm.templateId,
          opType: _vm.configOpType,
          itemForm: _vm.configItemForm,
        },
        on: { closePage: _vm.closeConfigPage },
        model: {
          value: _vm.showConfigDialog,
          callback: function ($$v) {
            _vm.showConfigDialog = $$v
          },
          expression: "showConfigDialog",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }