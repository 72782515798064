var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.formLoading,
          expression: "formLoading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: {},
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "540px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "detail",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-width": "120px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c(
                          "div",
                          { staticStyle: { "margin-top": "20px 0 10px" } },
                          [
                            _c(
                              "el-radio-group",
                              {
                                attrs: { size: "medium" },
                                model: {
                                  value: _vm.form.auditType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "auditType", $$v)
                                  },
                                  expression: "form.auditType",
                                },
                              },
                              [
                                _c(
                                  "el-radio",
                                  {
                                    staticStyle: { margin: "10px 70px" },
                                    attrs: { label: "AT01" },
                                  },
                                  [_vm._v("通过")]
                                ),
                                _c("el-radio", { attrs: { label: "AT02" } }, [
                                  _vm._v("不通过"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm.form.auditType == "AT02"
                    ? _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "审批说明：",
                                    prop: "auditDesc",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      maxlength: "200",
                                      clearable: "",
                                      "show-word-limit": "",
                                      rows: 3,
                                      placeholder: "请输入审批说明",
                                    },
                                    model: {
                                      value: _vm.form.auditDesc,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "auditDesc", $$v)
                                      },
                                      expression: "form.auditDesc",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "font-size": "12px",
                                    color: "#c0c4cc",
                                  },
                                },
                                [_vm._v("注:限最大输入200字")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-check",
                type: "primary",
                plain: "",
                size: "mini",
              },
              on: { click: _vm.save },
            },
            [_vm._v("确定")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: { click: _vm.closePage },
            },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }