/**
 * 赠品管理
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  //  **********************************赠品维护************************************
  /**
   * 赠品维护分页列表
   * @param {*} data 数据
   */
  getGiftMaintainList: function getGiftMaintainList(data) {
    return request.get(getUrl("/cardVoucher", 15), {
      params: data
    });
  },
  /**
   * 新增赠品维护
   * @param {*} data 数据
   */
  addGiftMaintain: function addGiftMaintain(data) {
    return request.post(getUrl("/cardVoucher", 15), data);
  },
  /**
   * 根据id修改赠品维护信息
   * @param {*} data 数据
   * @param {*} id
   */
  editGiftMaintain: function editGiftMaintain(id, data) {
    return request.put(getUrl("/cardVoucher/".concat(id), 15), data);
  },
  /**
   * 根据id删除赠品维护信息
   * @param {*} id
   */
  deleteGiftMaintain: function deleteGiftMaintain(id) {
    return request.delete(getUrl("/cardVoucher?cardVoucherId=".concat(id), 15));
  },
  /**
   * 根据id获取赠品维护信息
   * @param {*} data 数据
   * @param {*} id
   */
  getGiftMaintainInfo: function getGiftMaintainInfo(id, data) {
    return request.get(getUrl("/cardVoucher/".concat(id), 15), {
      params: data
    });
  },
  //  **********************************赠品发放************************************

  /**
   * 赠品发放列表查询
   * @param {*} data 数据
   */
  getGiftIssueList: function getGiftIssueList(data) {
    return request.get(getUrl("/cardVoucherIssued", 15), {
      params: data
    });
  },
  /**
   * 根据卡券编码批量获取
   */
  getCardVoucherCodeList: function getCardVoucherCodeList() {
    return request.get(getUrl("/cardVoucher/getCardVoucherCodeList", 15));
  },
  /**
   * 新增赠品发放
   * @param {*} data 数据
   */
  addGiftIssue: function addGiftIssue(data) {
    return request.post(getUrl("/cardVoucherIssued", 15), data);
  },
  /**
   * 根据保单号或车架号查询客户信息
   * @param {*} data 数据
   */
  getPolicyInformation: function getPolicyInformation(data) {
    return request.get(getUrl("/cardVoucherIssued/getPolicyInformation", 15), {
      params: data
    });
  },
  /**
   * 卡券发放记录删除
   * @param {*} ids
   */
  deleteGiftIssue: function deleteGiftIssue(ids) {
    return request.delete(getUrl("/cardVoucherIssued?ids=".concat(ids), 15));
  },
  //  **********************************赠品核销************************************

  /**
   * 赠品核销分页列表
   * @param {*} data 数据
   */
  getGiftWriteOffList: function getGiftWriteOffList(data) {
    return request.get(getUrl("/cardVoucherVerification", 15), {
      params: data
    });
  },
  /**
   * 核销记录分页列表
   * @param {*} data 数据
   */
  getGiftWriteOffRecordsList: function getGiftWriteOffRecordsList(data) {
    return request.get(getUrl("/cardVoucherVerification/issuedSearch", 15), {
      params: data
    });
  },
  /**
   * 新增赠品核销
   * @param {*} data 数据
   */
  addGiftWriteOff: function addGiftWriteOff(data) {
    return request.post(getUrl("/cardVoucherVerification", 15), data);
  },
  /**
   * 根据车架号搜索赠品发放记录
   * @param {*} data 数据
   */
  queryGiftIssueInfo: function queryGiftIssueInfo(data) {
    return request.get(getUrl("/cardVoucherVerification/getCardVoucherVerification", 15), {
      params: data
    });
  }
};
export default objApi;