var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-m-cls", staticStyle: {} }, [
    _c(
      "div",
      {
        ref: "header",
        staticClass: "header-cls",
        staticStyle: { "max-height": "500px", overflow: "auto" },
      },
      [
        _c(
          "el-card",
          { attrs: { shadow: "never" } },
          [
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  { attrs: { span: 10 } },
                  [
                    _c(
                      "el-table",
                      {
                        ref: "multipleTable",
                        staticStyle: { width: "100%" },
                        attrs: {
                          size: "mini",
                          border: "",
                          data: _vm.tableData,
                          "header-cell-style": { background: "#F7F7F7" },
                          "highlight-current-row": "",
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            prop: "matchCode",
                            label: "编码",
                            "show-overflow-tooltip": "",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "matchName",
                            label: "占位符",
                            "show-overflow-tooltip": "",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        "{{" + scope.row.matchName + "}}"
                                      ),
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 14 } },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "detail",
                        staticStyle: { "margin-bottom": "-20px" },
                        attrs: {
                          model: _vm.form,
                          rules: _vm.rules,
                          "label-width": "110px",
                          size: "mini",
                        },
                      },
                      [
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "保险公司：",
                                  prop: "insCorpCode",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: { placeholder: "请选择保险公司" },
                                    model: {
                                      value: _vm.form.insCorpCode,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "insCorpCode", $$v)
                                      },
                                      expression: "form.insCorpCode",
                                    },
                                  },
                                  _vm._l(_vm.supplyers, function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.shortName,
                                        value: item.code,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "短信内容：",
                                  prop: "smsContent",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "请输入短信内容",
                                    clearable: "",
                                    type: "textarea",
                                    rows: 3,
                                  },
                                  model: {
                                    value: _vm.form.smsContent,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "smsContent", $$v)
                                    },
                                    expression: "form.smsContent",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "模板内容：",
                                  prop: "templateContent",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "请输入模板内容",
                                    clearable: "",
                                    type: "textarea",
                                    rows: 3,
                                  },
                                  model: {
                                    value: _vm.form.templateContent,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "templateContent", $$v)
                                    },
                                    expression: "form.templateContent",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-form-item",
                              {
                                staticStyle: { "margin-bottom": "0" },
                                attrs: { label: "" },
                              },
                              [
                                _c("div", [
                                  _vm._v("变化的内容用"),
                                  _c(
                                    "span",
                                    { staticStyle: { color: "red" } },
                                    [_vm._v("占位符")]
                                  ),
                                  _vm._v("代替"),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "自定义标签：" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "请输入自定义标签",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.form.tags,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "tags", $$v)
                                    },
                                    expression: "form.tags",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticStyle: {
          display: "flex",
          "justify-content": "center",
          "margin-top": "8px",
        },
      },
      [
        _c(
          "el-button",
          {
            attrs: {
              icon: "el-icon-check",
              type: "primary",
              plain: "",
              size: "mini",
            },
            on: { click: _vm.save },
          },
          [_vm._v("保存")]
        ),
        _c(
          "el-button",
          {
            attrs: { icon: "el-icon-close", size: "mini" },
            on: { click: _vm.closePage },
          },
          [_vm._v("关闭")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }