//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import customersRequest from '@/api/customers';
import { limitInput } from '@/utils/common';
export default {
  mixins: [initHeight],
  props: {
    currentObj: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    curRenewalConfigParams: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      loading: false,
      form: {
        configId: null,
        leadTimeMonth: null,
        postTimeMonth: null
      },
      formRules: {
        leadTimeMonth: {
          required: true,
          message: '请输入前置日期',
          trigger: 'blur'
        },
        postTimeMonth: {
          required: true,
          message: '请输入后置日期',
          trigger: 'blur'
        }
      }
    };
  },
  watch: {
    currentObj: {
      handler: function handler(val) {
        this.form.configId = val.coreCustomerRenewalTaskConfigId;
        this.form.leadTimeMonth = val.trackConfig ? val.trackConfig.leadTimeMonth : null;
        this.form.postTimeMonth = val.trackConfig ? val.trackConfig.postTimeMonth : null;
      },
      deep: true
    }
  },
  created: function created() {
    this.form.configId = this.currentObj.coreCustomerRenewalTaskConfigId;
    this.form.leadTimeMonth = this.currentObj.trackConfig ? this.currentObj.trackConfig.leadTimeMonth : null;
    this.form.postTimeMonth = this.currentObj.trackConfig ? this.currentObj.trackConfig.postTimeMonth : null;
  },
  methods: {
    // 保存
    save: function save() {
      var _this = this;
      this.$refs['form'].validate(function (valid, obj) {
        if (valid) {
          _this.loading = true;
          customersRequest.trackConfig(_this.form).then(function (res) {
            _this.loading = false;
            if (res.code === 200) {
              _this.$message.success(res.msg);
            }
            ;
          }).catch(function (err) {
            _this.loading = false;
          });
        }
        ;
      });
    },
    onInput: function onInput(num, min, max, limit) {
      //对输入内容做精度校验
      if (min && num < min) {
        num = min;
      }
      ;
      if (max && num > max) {
        num = max;
      }
      ;
      return limitInput(num, limit);
    }
  }
};