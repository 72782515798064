import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '../../../mixins/initHeight';
import dict from '../../../mixins/dict';
import Pagination from '@/components/Pagination';
import RuleEditDialog from './../dialog/ruleEditDialog';
import repairApi from '@/api/repair';
import dictRequest from '@/api/dictionary';
export default {
  name: 'configure',
  components: {
    Pagination: Pagination,
    RuleEditDialog: RuleEditDialog
  },
  mixins: [initHeight, dict],
  computed: {},
  data: function data() {
    return {
      activeName: "rule",
      drawer: false,
      searchForm: {},
      form: {},
      PhoneForm: {
        orgCode: '',
        secretNo: '',
        bindNo: '',
        remark: ''
      },
      phoneRules: {
        orgCode: [{
          required: true,
          message: '请选择所属机构',
          trigger: 'change'
        }],
        secretNo: [{
          required: true,
          message: '请输入虚拟号',
          trigger: 'blur'
        }]
      },
      list: null,
      total: 0,
      phoneTotal: 0,
      listLoading: true,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      PhonelistQuery: {
        pageNum: 1,
        pageSize: 20
      },
      defaultProps: {
        children: 'children',
        label: 'fullName'
      },
      tableData: [],
      PhoneTableData: [],
      multipleSelection: [],
      PhoneMultipleSelection: [],
      opType: '',
      PhoneOpType: '',
      startEndTime: null,
      loading: false,
      orgData: [],
      highlightCurrent: true,
      showRuleEditDialog: false,
      showPhoneDialog: false,
      tabName: "rule",
      SubDealerList: [],
      virtualPhoneId: "",
      sendRuleId: "",
      DistributeType: [],
      timeout1: null //防抖
    };
  },

  filters: {
    FilterUseOrgCode: function FilterUseOrgCode(val, list) {
      var dealerName = '';
      var dealerNameObj = _.find(list, {
        'dealerCode': val
      });
      dealerName = dealerNameObj ? dealerNameObj.dealerName : '';
      return dealerName;
    }
  },
  created: function created() {},
  mounted: function mounted() {
    this.getDistributeType();
    this.loadData();
    if (localStorage.getItem('userInfo')) {
      var orgCode = JSON.parse(localStorage.getItem('userInfo')).orgCode;
      this.getSubDealerList(orgCode);
    }
  },
  methods: {
    changeEnabled: function changeEnabled(row) {
      var _this = this;
      //启用、禁用
      repairApi.enabledSendRule(row.sendRuleId, row.status).then(function (res) {
        if (res.code == 200) {
          _this.$message({
            message: res.msg,
            type: 'success'
          });
          _this.loadData();
        }
      });
    },
    reloadData: function reloadData() {
      //刷新
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    getDistributeType: function getDistributeType() {
      var _this2 = this;
      //获取结算状态
      dictRequest.getDictItemsByCode("DISTRIBUTE_TYPE").then(function (res) {
        if (res.data) {
          _this2.DistributeType = res.data;
        }
      });
    },
    getSendRulelist: function getSendRulelist() {
      var _this3 = this;
      //派单规则列表
      this.searchForm.pageNum = this.listQuery.pageNum;
      this.searchForm.pageSize = this.listQuery.pageSize;
      repairApi.getSendRulelist(this.searchForm).then(function (res) {
        if (res.code == 200) {
          _this3.tableData = res.data.list ? res.data.list : [];
          _this3.total = res.data.page.recordsTotal ? res.data.page.recordsTotal : 0;
        }
      });
    },
    getVirtualPhone: function getVirtualPhone() {
      var _this4 = this;
      //获取号码设置列表
      this.searchForm.pageNum = this.PhonelistQuery.pageNum;
      this.searchForm.pageSize = this.PhonelistQuery.pageSize;
      repairApi.virtualPhone(this.searchForm).then(function (res) {
        if (res.code == 200) {
          _this4.PhoneTableData = res.data.list ? res.data.list : [];
          _this4.phoneTotal = res.data.page.recordsTotal ? res.data.page.recordsTotal : 0;
        }
      });
    },
    getSubDealerList: function getSubDealerList(orgCode) {
      var _this5 = this;
      //获取经销商下级机构
      var data = {
        dealerCode: orgCode
      };
      repairApi.getSubDealerList(data).then(function (res) {
        console.log('获取下级机构', res);
        if (res.code == 200) {
          _this5.SubDealerList = res.data ? res.data : [];
        }
      });
    },
    addSMS: function addSMS() {
      var _this6 = this;
      //新增
      this.opType = '';
      this.PhoneOpType = '';
      if (this.tabName == 'rule') {
        this.showRuleEditDialog = true;
      } else {
        this.showPhoneDialog = true;
        this.$nextTick(function () {
          _this6.PhoneForm = {
            orgCode: '',
            secretNo: '',
            bindNo: '',
            remark: ''
          };
          _this6.$refs['phoneDetail'].resetFields();
        });
      }
    },
    invalid: function invalid(row) {
      //无效
      // 启用禁用
      if (row.status) {}
      // closeSendRule
    },
    copy: function copy(val, row) {
      //复制
      console.log(row);
      this.opType = val;
      this.showRuleEditDialog = true;
      this.sendRuleId = row.sendRuleId.toString();
    },
    deleteConfig: function deleteConfig(row) {
      var _this7 = this;
      //删除规则
      this.$confirm('是否确认删除, 确认删除后不能恢复?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this7.delSendRule(row.sendRuleId);
      }).catch(function () {});
    },
    delSendRule: function delSendRule(ids) {
      var _this8 = this;
      repairApi.delSendRule(ids).then(function (res) {
        if (res.code == 200) {
          _this8.$message({
            type: 'success',
            message: '删除成功!'
          });
          _this8.getSendRulelist();
        }
      });
    },
    save: function save() {
      var _this9 = this;
      if (this.timeout1) {
        //防抖
        clearTimeout(this.timeout1);
      }
      this.timeout1 = setTimeout(function () {
        _this9.save1();
      }, 800);
    },
    // 号码保存设置
    save1: function save1() {
      var _this10 = this;
      this.$refs['phoneDetail'].validate(function (valid) {
        if (valid) {
          _this10.PhoneForm.bizType = 'DEALER';
          var request = _this10.PhoneOpType == 'edit' ? repairApi.updateVirtualPhone(_this10.virtualPhoneId, _this10.PhoneForm) : repairApi.addVirtualPhone(_this10.PhoneForm);
          request.then(function (res) {
            if (res.code == 200) {
              _this10.$message({
                message: res.msg,
                type: 'success'
              });
              _this10.showPhoneDialog = false;
              _this10.getVirtualPhone();
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 关闭号码设置
    closeShowPhoneDialog: function closeShowPhoneDialog() {
      this.$refs['phoneDetail'].resetFields();
      this.showPhoneDialog = false;
    },
    // 获取详情
    getVirtualPhoneDetail: function getVirtualPhoneDetail(id) {
      var _this11 = this;
      repairApi.getVirtualPhoneInfo(id).then(function (res) {
        if (res.code == 200) {
          _this11.PhoneForm = res.data;
          _this11.showPhoneDialog = true;
        }
      });
    },
    editPhone: function editPhone(val, row) {
      //编辑号码
      this.PhoneOpType = val;
      this.getVirtualPhoneDetail(row.virtualPhoneId);
      this.virtualPhoneId = row.virtualPhoneId;
    },
    deletePhone: function deletePhone(row) {
      var _this12 = this;
      //删除
      this.$confirm('是否确认删除, 确认删除后不能恢复?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this12.delVirtualPhone(row.virtualPhoneId);
        // 暂时缺接口
      }).catch(function () {});
    },
    delVirtualPhone: function delVirtualPhone(ids) {
      var _this13 = this;
      repairApi.delVirtualPhone(ids).then(function (res) {
        if (res.code == 200) {
          _this13.$message({
            type: 'success',
            message: '删除成功!'
          });
          _this13.getVirtualPhone();
        }
      });
    },
    editSMS: function editSMS(type, row) {
      //编辑
      this.opType = type;
      this.showRuleEditDialog = true;
      this.sendRuleId = row.sendRuleId.toString();
    },
    handleTabClick: function handleTabClick(tab, event) {
      //tab切换事件
      console.log(tab._props.name);
      this.tabName = tab._props.name;
      this.$forceUpdate();
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {};
    },
    searchData: function searchData() {
      //搜索
      this.loadData();
    },
    loadData: function loadData() {
      //获取列表数据
      // this.loading = true;
      this.drawer = false;
      this.showRuleEditDialog = false;
      if (this.tabName == 'phone') {
        this.searchForm.useOrgCode = null;
      } else {
        this.searchForm.orgCode = null;
      }
      this.getSendRulelist();
      this.getVirtualPhone();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      //列表行选择事件
      this.multipleSelection = val;
    }
  }
};