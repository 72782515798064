import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FormLabel from '@/components/FormSubLabel';
export default {
  components: {
    FormLabel: FormLabel
  },
  data: function data() {
    return {
      showDamageDialog: false,
      showStepDialog: false,
      activeName: '1',
      DamageStepList: [],
      stepList: []
    };
  },
  created: function created() {},
  mounted: function mounted() {
    console.log(this.$route);
  },
  methods: {
    goBack: function goBack() {
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.replace({
        name: 'orderMonitor'
      });
    },
    toview: function toview(val) {
      if (val == 'history') {
        this.showStepDialog = true;
      } else {
        this.showDamageDialog = true;
      }
    },
    closePage: function closePage(val) {
      if (val == 'history') {
        this.showStepDialog = false;
      } else {
        this.showDamageDialog = false;
      }
    }
  }
};