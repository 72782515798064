import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from "@/mixins/initHeight";
import Dialog from "@/components/Dialog";
import Pagination from "@/components/Pagination";
import request from "@/api/connectConfig";
import { getSupplyers } from "@/utils/common";
export default {
  name: "ServiceConfig",
  components: {
    Pagination: Pagination,
    Dialog: Dialog
  },
  mixins: [initHeight],
  computed: {},
  data: function data() {
    return {
      drawer: false,
      showDialog: false,
      searchForm: {
        serviceCode: null,
        serviceName: null,
        supportInsCorpCode: null
      },
      formLoading: false,
      rules: {
        serviceType: [{
          required: true,
          message: "请选择服务类型",
          trigger: "change"
        }],
        serviceCode: [{
          required: true,
          message: "请输入服务编码",
          trigger: "blur"
        }],
        serviceName: [{
          required: true,
          message: "请输入服务名称",
          trigger: "blur"
        }],
        serviceAddress: [{
          required: true,
          message: "请输入服务地址",
          trigger: "blur"
        }],
        supportInsCorpCodes: [{
          required: true,
          message: "请选择保险公司",
          trigger: "change"
        }]
      },
      form: {
        serviceType: null,
        serviceCode: null,
        serviceName: null,
        serviceAddress: null,
        supportInsCorpCodes: []
      },
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      multipleSelection: [],
      supplyers: [],
      loading: false,
      opType: 'add'
    };
  },
  created: function created() {
    this.loadData();
    this.supplyers = getSupplyers();
  },
  methods: {
    getInsName: function getInsName(items) {
      var names = [];
      if (items) {
        items = _.toArray(items);
        items.forEach(function (item) {
          names.push(item);
        });
      }
      return names.toString();
    },
    serviceTypeChange: function serviceTypeChange() {
      //切换服务类型
      if (this.form.serviceType == 'INSIDE') this.form.serviceAddress = "http://" + (this.form.serviceCode || '');
    },
    breakData: function breakData() {
      //刷新
      // this.resetSearch();
      this.loadData();
    },
    getSupplyerName: function getSupplyerName(code) {
      //获取保司中文名称
      var supplyer = _.find(this.supplyers, function (item) {
        return _.toLower(item.code) == _.toLower(code);
      });
      return supplyer ? supplyer.shortName : "";
    },
    resetSearch: function resetSearch() {
      //重置搜索条件
      this.searchForm = {
        serviceCode: null,
        serviceName: null,
        supportInsCorpCode: null
      };
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    removeItems: function removeItems(item) {
      var _this = this;
      //删除
      var ids = [];
      if (!_.isEmpty(item)) {
        ids.push(item.serviceConfigId);
      } else {
        if (!_.isEmpty(this.multipleSelection)) {
          ids = _.map(this.multipleSelection, "serviceConfigId");
        }
      }
      if (_.isEmpty(ids)) return;
      this.$confirm("是否确认删除对接服务配置项？确认删除后不能恢复。", "温馨提示", {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        request.deleteServiceConfig(ids).then(function (res) {
          if (res.code == RESPONSE_SUCCESS) {
            _this.$message({
              type: 'success',
              message: res.msg
            });
            _this.loadData();
          }
        });
      });
    },
    updateItem: function updateItem(item) {
      //编辑
      this.opType = 'edit';
      this.showDialog = true;
      this.form = _.cloneDeep(item);
      this.form.supportInsCorpCodes = item.supportInsCorpCodes && _.toUpper(item.supportInsCorpCodes).split(',');
      this.$forceUpdate();
    },
    enableItem: function enableItem(item) {
      var _this2 = this;
      //启用或禁用
      if (item.isClick) return;
      item.isClick = true;
      request.changeServiceConfigStatus(item.serviceConfigId, item.enabled).then(function (res) {
        item.isClick = false;
        if (res.code == RESPONSE_SUCCESS) {
          _this2.$message({
            type: 'success',
            message: res.msg
          });
          // this.loadData();
        } else {
          item.enabled = false;
        }
      }).catch(function (err) {
        item.isClick = false;
        item.enabled = false;
      });
    },
    addItem: function addItem() {
      //新增
      this.showDialog = true;
      this.opType = 'add';
      this.form = {
        serviceType: null,
        serviceCode: null,
        serviceName: null,
        serviceAddress: null,
        supportInsCorpCodes: []
      };
      if (this.$refs["form"]) this.$refs["form"].resetFields();
    },
    loadData: function loadData() {
      var _this3 = this;
      //获取列表
      this.loading = true;
      this.drawer = false;
      this.tableData = [];
      var queryParams = _.cloneDeep(this.searchForm);
      queryParams.supportInsCorpCodes = queryParams.supportInsCorpCodes && queryParams.supportInsCorpCodes.toString();
      request.getServiceConfigList(this.listQuery.pageNum, this.listQuery.pageSize, queryParams).then(function (res) {
        _this3.loading = false;
        if (!_.isEmpty(res.data)) {
          _this3.tableData = res.data.list;
          //总条数
          _this3.total = res.data.page.recordsTotal;
        }
      }).catch(function (err) {
        _this3.loading = false;
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      //列表行选择事件
      this.multipleSelection = val;
    },
    cancel: function cancel() {
      //关闭弹窗
      this.showDialog = false;
      if (this.$refs["form"]) this.$refs["form"].resetFields();
    },
    submit: function submit() {
      var _this4 = this;
      //保存配置
      // 验证表单
      this.$refs["form"].validate(function (valid) {
        if (valid) {
          var postData = _.cloneDeep(_this4.form);
          postData.supportInsCorpCodes = postData.supportInsCorpCodes && postData.supportInsCorpCodes.toString();
          var localRequest = _this4.opType == 'edit' ? request.updateServiceConfig(postData.serviceConfigId, postData) : request.createServiceConfig(postData);
          _this4.formLoading = true;
          localRequest.then(function (res) {
            if (res.code == RESPONSE_SUCCESS) {
              _this4.showDialog = false;
              _this4.$refs['form'].resetFields();
              _this4.showDialog = false;
              _this4.loadData();
            }
            _this4.formLoading = false;
          }).catch(function (err) {
            _this4.formLoading = false;
          });
        }
      });
    }
  }
};