var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "app-m-cls", staticStyle: { background: "#f5f5f5" } },
      [
        _c(
          "div",
          { ref: "PDFDom", staticClass: "table-cls", attrs: { id: "PDFDom" } },
          [
            _c(
              "div",
              {
                staticClass: "search",
                attrs: { slot: "header" },
                slot: "header",
              },
              [
                _c(
                  "div",
                  {
                    staticStyle: {
                      flex: "7",
                      display: "flex",
                      "flex-wrap": "wrap",
                      "flex-direction": "row",
                      "align-items": "center",
                    },
                  },
                  [
                    _vm.bizType === "OEM"
                      ? _c(
                          "div",
                          {
                            staticClass: "car-type",
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                            },
                          },
                          [
                            _c("span", [_vm._v("组织机构：")]),
                            _c("OemSelect", {
                              staticClass: "zuzhijigou",
                              staticStyle: { width: "140px" },
                              attrs: {
                                searchForm: _vm.orgForm,
                                clearable: "",
                                canClickArea: true,
                                isGetUserList: true,
                                isContainOem: true,
                              },
                              on: { nodeClick: _vm.handleNodeClick },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.bizType === "DEALER"
                      ? _c(
                          "div",
                          { staticClass: "car-type" },
                          [
                            _c("span", [_vm._v("组织机构：")]),
                            _c("el-input", {
                              staticStyle: { width: "140px" },
                              attrs: { disabled: "", placeholder: "" },
                              model: {
                                value: _vm.searchPolicyForm.salesOrgName,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.searchPolicyForm,
                                    "salesOrgName",
                                    $$v
                                  )
                                },
                                expression: "searchPolicyForm.salesOrgName",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass: "car-type",
                        staticStyle: { "margin-left": "5px" },
                      },
                      [
                        _c("span", [_vm._v("服务类型：")]),
                        _c(
                          "el-select",
                          {
                            staticClass: "tagWidth",
                            staticStyle: { width: "140px" },
                            attrs: {
                              size: "mini",
                              multiple: "",
                              "collapse-tags": "",
                              clearable: "",
                              placeholder: "请选择服务类型",
                            },
                            on: { change: _vm.handleTabClick },
                            model: {
                              value: _vm.activeName,
                              callback: function ($$v) {
                                _vm.activeName = $$v
                              },
                              expression: "activeName",
                            },
                          },
                          _vm._l(_vm.tabsList, function (item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: {
                                label: item.className,
                                value: item.classCode,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.activeName.includes("DBC")
                      ? _c(
                          "div",
                          {
                            staticClass: "car-type1",
                            staticStyle: { "margin-left": "5px" },
                          },
                          [
                            _c("span", [_vm._v("保险公司：")]),
                            _c(
                              "el-select",
                              {
                                staticClass: "tag1Width",
                                staticStyle: { width: "160px" },
                                attrs: {
                                  size: "mini",
                                  multiple: "",
                                  "collapse-tags": "",
                                  clearable: "",
                                  placeholder: "请选择供应商",
                                },
                                model: {
                                  value: _vm.searchForm.supplierCode,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.searchForm,
                                      "supplierCode",
                                      $$v
                                    )
                                  },
                                  expression: "searchForm.supplierCode",
                                },
                              },
                              _vm._l(
                                _vm.suppliersLists,
                                function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.supplierName,
                                      value: item.supplierCode,
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticStyle: { margin: "5px 3px 0 5px" } },
                      [
                        _c("span", [_vm._v("厂牌车系：")]),
                        _c("CarReportBrands", {
                          attrs: {
                            multiple: true,
                            collapseTags: true,
                            carTypeValue: _vm.carTypeVal,
                          },
                          on: { carHandleChanged: _vm.CarHandleChange },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "margin-left": "5px",
                          display: "flex",
                          "align-items": "center",
                        },
                      },
                      [
                        _c("span", [_vm._v("时间范围：")]),
                        _c("DatePicker", {
                          attrs: {
                            pickerDateTime: _vm.createdDateTime,
                            clearable: false,
                            startDate: "startTime",
                            endDate: "endTime",
                            width: "240px",
                          },
                          on: {
                            pickerDateChange: function ($event) {
                              return _vm.getPickersDate(
                                "createdDateTime",
                                $event
                              )
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticStyle: { "text-align": "right", "margin-top": "5px" },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "primary" },
                        on: { click: _vm.searchClicked },
                      },
                      [_vm._v("查询")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.downloadIMGClicked(
                              "PDFDom",
                              "非车险业务统计"
                            )
                          },
                        },
                      },
                      [_vm._v("PNG下载")]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                staticClass: "bottomMain",
                style: { height: _vm.containerH },
              },
              [
                _c(
                  "el-card",
                  {
                    staticClass: "box-card",
                    staticStyle: { "margin-top": "10px" },
                  },
                  [
                    _c(
                      "div",
                      {
                        style: {
                          "overflow-y": "auto",
                          padding: "10px",
                          "overflow-y": "auto",
                        },
                      },
                      [
                        _c("div", { staticClass: "toplist_title" }, [
                          _vm._v("出单总览"),
                        ]),
                        _c("div", [
                          _c("div", { staticClass: "AllTotal" }, [
                            _c("div", { staticClass: "issuedTotal" }, [
                              _c("div", { staticClass: "total_left" }, [
                                _c("img", {
                                  attrs: { src: _vm.orderIcon, alt: "" },
                                }),
                              ]),
                              _c("div", { staticClass: "total_right" }, [
                                _c("div", { staticClass: "totalRight_top" }, [
                                  _c("div", [_vm._v("出单总量")]),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.totalOrdersIssued
                                          ? _vm.totalOrdersIssued.toLocaleString()
                                          : 0
                                      )
                                    ),
                                  ]),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "totalRight_bottom" },
                                  [
                                    _c("div", [
                                      _c("div", [_vm._v("环比上期")]),
                                      _c("div", [
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  parseFloat(
                                                    _vm.allOrderLastMonthRingRatio
                                                  ) > 100,
                                                expression:
                                                  "parseFloat(allOrderLastMonthRingRatio)>100",
                                              },
                                            ],
                                            staticClass: "increase",
                                          },
                                          [_vm._v("↑")]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  parseFloat(
                                                    _vm.allOrderLastMonthRingRatio
                                                  ) < 100,
                                                expression:
                                                  "parseFloat(allOrderLastMonthRingRatio)<100",
                                              },
                                            ],
                                            staticClass: "decline",
                                          },
                                          [_vm._v("↓")]
                                        ),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.allOrderLastMonthRingRatio
                                            ) +
                                            "% "
                                        ),
                                      ]),
                                    ]),
                                    _c("div", [
                                      _c("div", [_vm._v("同比上期")]),
                                      _c("div", [
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  parseFloat(
                                                    _vm.allOrderLastYearRatio
                                                  ) > 100,
                                                expression:
                                                  "parseFloat(allOrderLastYearRatio)>100",
                                              },
                                            ],
                                            staticClass: "increase",
                                          },
                                          [_vm._v("↑")]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  parseFloat(
                                                    _vm.allOrderLastYearRatio
                                                  ) < 100,
                                                expression:
                                                  "parseFloat(allOrderLastYearRatio)<100",
                                              },
                                            ],
                                            staticClass: "decline",
                                          },
                                          [_vm._v("↓")]
                                        ),
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.allOrderLastYearRatio) +
                                            "%"
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "premiumTotal" }, [
                              _c("div", { staticClass: "total_left" }, [
                                _c("img", {
                                  attrs: { src: _vm.costIcon, alt: "" },
                                }),
                              ]),
                              _c("div", { staticClass: "total_right" }, [
                                _c("div", { staticClass: "totalRight_top" }, [
                                  _c("div", [_vm._v("F总服务收入")]),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.totalPremium
                                          ? _vm.totalPremium.toLocaleString(
                                              "zh",
                                              { minimumFractionDigits: 2 }
                                            )
                                          : "0.00"
                                      )
                                    ),
                                  ]),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "totalRight_bottom" },
                                  [
                                    _c("div", [
                                      _c("div", [_vm._v("环比上期")]),
                                      _c("div", [
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  parseFloat(
                                                    _vm.allPremiumLastMonthRingRatio
                                                  ) > 100,
                                                expression:
                                                  "parseFloat(allPremiumLastMonthRingRatio)>100",
                                              },
                                            ],
                                            staticClass: "increase",
                                          },
                                          [_vm._v("↑")]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  parseFloat(
                                                    _vm.allPremiumLastMonthRingRatio
                                                  ) < 100,
                                                expression:
                                                  "parseFloat(allPremiumLastMonthRingRatio)<100",
                                              },
                                            ],
                                            staticClass: "decline",
                                          },
                                          [_vm._v("↓")]
                                        ),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.allPremiumLastMonthRingRatio
                                            ) +
                                            "%"
                                        ),
                                      ]),
                                    ]),
                                    _c("div", [
                                      _c("div", [_vm._v("同比上期")]),
                                      _c("div", [
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  parseFloat(
                                                    _vm.allPremiumLastYearRatio
                                                  ) > 100,
                                                expression:
                                                  "parseFloat(allPremiumLastYearRatio)>100",
                                              },
                                            ],
                                            staticClass: "increase",
                                          },
                                          [_vm._v("↑")]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  parseFloat(
                                                    _vm.allPremiumLastYearRatio
                                                  ) < 100,
                                                expression:
                                                  "parseFloat(allPremiumLastYearRatio)<100",
                                              },
                                            ],
                                            staticClass: "decline",
                                          },
                                          [_vm._v("↓")]
                                        ),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.allPremiumLastYearRatio
                                            ) +
                                            "%"
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "profitTotal" }, [
                              _c("div", { staticClass: "total_left" }, [
                                _c("img", {
                                  attrs: { src: _vm.profitIcon, alt: "" },
                                }),
                              ]),
                              _c("div", { staticClass: "total_right" }, [
                                _c("div", { staticClass: "totalRight_top" }, [
                                  _c("div", [_vm._v("F总收益")]),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.totalIncome
                                          ? _vm.totalIncome.toLocaleString(
                                              "zh",
                                              { minimumFractionDigits: 2 }
                                            )
                                          : "0.00"
                                      )
                                    ),
                                  ]),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "totalRight_bottom" },
                                  [
                                    _c("div", [
                                      _c("div", [_vm._v("环比上期")]),
                                      _c("div", [
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  parseFloat(
                                                    _vm.allProfitLastMonthRingRatio
                                                  ) > 100,
                                                expression:
                                                  "parseFloat(allProfitLastMonthRingRatio)>100",
                                              },
                                            ],
                                            staticClass: "increase",
                                          },
                                          [_vm._v("↑")]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  parseFloat(
                                                    _vm.allProfitLastMonthRingRatio
                                                  ) < 100,
                                                expression:
                                                  "parseFloat(allProfitLastMonthRingRatio)<100",
                                              },
                                            ],
                                            staticClass: "decline",
                                          },
                                          [_vm._v("↓")]
                                        ),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.allProfitLastMonthRingRatio
                                            ) +
                                            "%"
                                        ),
                                      ]),
                                    ]),
                                    _c("div", [
                                      _c("div", [_vm._v("同比上期")]),
                                      _c("div", [
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  parseFloat(
                                                    _vm.allProfitLastYearRingRatio
                                                  ) > 100,
                                                expression:
                                                  "parseFloat(allProfitLastYearRingRatio)>100",
                                              },
                                            ],
                                            staticClass: "increase",
                                          },
                                          [_vm._v("↑")]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  parseFloat(
                                                    _vm.allProfitLastYearRingRatio
                                                  ) < 100,
                                                expression:
                                                  "parseFloat(allProfitLastYearRingRatio)<100",
                                              },
                                            ],
                                            staticClass: "decline",
                                          },
                                          [_vm._v("↓")]
                                        ),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.allProfitLastYearRingRatio
                                            ) +
                                            "%"
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "profitTotal" }, [
                              _c("div", { staticClass: "total_left" }, [
                                _c("img", {
                                  attrs: { src: _vm.DprofitIcon, alt: "" },
                                }),
                              ]),
                              _c("div", { staticClass: "total_right" }, [
                                _c("div", { staticClass: "totalRight_top" }, [
                                  _c("div", [_vm._v("D总收益")]),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.policyProfitD
                                          ? _vm.policyProfitD.toLocaleString(
                                              "zh",
                                              { minimumFractionDigits: 2 }
                                            )
                                          : "0.00"
                                      )
                                    ),
                                  ]),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "totalRight_bottom" },
                                  [
                                    _c("div", [
                                      _c("div", [_vm._v("环比上期")]),
                                      _c("div", [
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  parseFloat(
                                                    _vm.policyProfitDRelativeRatio
                                                  ) > 100,
                                                expression:
                                                  "parseFloat(policyProfitDRelativeRatio)>100",
                                              },
                                            ],
                                            staticClass: "increase",
                                          },
                                          [_vm._v("↑")]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  parseFloat(
                                                    _vm.policyProfitDRelativeRatio
                                                  ) < 100,
                                                expression:
                                                  "parseFloat(policyProfitDRelativeRatio)<100",
                                              },
                                            ],
                                            staticClass: "decline",
                                          },
                                          [_vm._v("↓")]
                                        ),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.policyProfitDRelativeRatio
                                            ) +
                                            "% "
                                        ),
                                      ]),
                                    ]),
                                    _c("div", [
                                      _c("div", [_vm._v("同比上期")]),
                                      _c("div", [
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  parseFloat(
                                                    _vm.policyProfitDSamePeriodRatio
                                                  ) > 100,
                                                expression:
                                                  "parseFloat(policyProfitDSamePeriodRatio)>100",
                                              },
                                            ],
                                            staticClass: "increase",
                                          },
                                          [_vm._v("↑")]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  parseFloat(
                                                    _vm.policyProfitDSamePeriodRatio
                                                  ) < 100,
                                                expression:
                                                  "parseFloat(policyProfitDSamePeriodRatio)<100",
                                              },
                                            ],
                                            staticClass: "decline",
                                          },
                                          [_vm._v("↓")]
                                        ),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.policyProfitDSamePeriodRatio
                                            ) +
                                            "% "
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "chartsTabBox" },
                            [
                              _c(
                                "el-tabs",
                                {
                                  attrs: { type: "card" },
                                  on: { "tab-click": _vm.handleChartsTabClick },
                                  model: {
                                    value: _vm.chartsTabName,
                                    callback: function ($$v) {
                                      _vm.chartsTabName = $$v
                                    },
                                    expression: "chartsTabName",
                                  },
                                },
                                [
                                  _c("el-tab-pane", {
                                    attrs: {
                                      label: "服务收入/收益趋势",
                                      name: "1",
                                    },
                                  }),
                                  _c("el-tab-pane", {
                                    attrs: { label: "理赔趋势", name: "2" },
                                  }),
                                ],
                                1
                              ),
                              _vm.chartsTabName == "1"
                                ? _c("div", {
                                    attrs: { id: "littleOldCarChart" },
                                  })
                                : _vm._e(),
                              _vm.chartsTabName == "2"
                                ? _c("div", {
                                    attrs: { id: "compensateChart" },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
                _c(
                  "el-card",
                  {
                    staticClass: "box-card",
                    staticStyle: {
                      "margin-top": "10px",
                      padding: "10px 10px 20px 10px",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "toplist_title",
                        staticStyle: {
                          display: "flex",
                          "flex-direction": "row",
                          "justify-content": "flex-start",
                          "align-items": "center",
                        },
                      },
                      [_vm._v(" TOP5排行榜 ")]
                    ),
                    _c("div", { staticClass: "topList" }, [
                      _c(
                        "div",
                        { staticClass: "toplist_left boxShadow" },
                        [
                          _c("div", { staticClass: "num_title" }, [
                            _vm._v(" 保单量 "),
                          ]),
                          _vm._l(_vm.topCountList, function (item, index) {
                            return _c(
                              "ul",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.topCountList &&
                                      _vm.topCountList.length !== 0,
                                    expression:
                                      "topCountList&&topCountList.length !== 0",
                                  },
                                ],
                                key: index,
                                staticClass: "list_num",
                              },
                              [
                                _c(
                                  "li",
                                  [
                                    _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          placement: "bottom-end",
                                          effect: "dark",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "font-size": "10px",
                                            },
                                            attrs: { slot: "content" },
                                            slot: "content",
                                          },
                                          [
                                            _c("div", [
                                              _vm._v(_vm._s(item.orgName)),
                                            ]),
                                          ]
                                        ),
                                        _c("div", [
                                          _vm._v(
                                            " " + _vm._s(item.orgName) + " "
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            item.policyCount
                                              ? item.policyCount
                                              : 0
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            )
                          }),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    !_vm.topCountList ||
                                    _vm.topCountList.length === 0,
                                  expression:
                                    "!topCountList || topCountList.length === 0",
                                },
                              ],
                              staticClass: "list_num",
                            },
                            [_vm._v(" 暂无数据 ")]
                          ),
                        ],
                        2
                      ),
                      _c(
                        "div",
                        { staticClass: "toplist_right boxShadow" },
                        [
                          _c("div", { staticClass: "num_title" }, [
                            _vm._v(" F收益贡献 "),
                          ]),
                          _vm._l(_vm.topListProfitF, function (item, index) {
                            return _c(
                              "ul",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.topListProfitF &&
                                      _vm.topListProfitF.length !== 0,
                                    expression:
                                      "topListProfitF && topListProfitF.length !== 0",
                                  },
                                ],
                                key: index,
                                staticClass: "list_num",
                              },
                              [
                                _c(
                                  "li",
                                  [
                                    _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          placement: "bottom-end",
                                          effect: "dark",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "font-size": "10px",
                                            },
                                            attrs: { slot: "content" },
                                            slot: "content",
                                          },
                                          [
                                            _c("div", [
                                              _vm._v(_vm._s(item.orgName)),
                                            ]),
                                          ]
                                        ),
                                        _c("div", [
                                          _vm._v(
                                            " " + _vm._s(item.orgName) + " "
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c("div", [
                                      _vm._v(
                                        " ￥" +
                                          _vm._s(
                                            item.policyPremium
                                              ? item.policyPremium.toLocaleString(
                                                  "zh",
                                                  { minimumFractionDigits: 2 }
                                                )
                                              : "0.00"
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            )
                          }),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    !_vm.topListProfitF ||
                                    _vm.topListProfitF.length === 0,
                                  expression:
                                    "!topListProfitF || topListProfitF.length === 0",
                                },
                              ],
                              staticClass: "list_num",
                            },
                            [_vm._v(" 暂无数据 ")]
                          ),
                        ],
                        2
                      ),
                      _c(
                        "div",
                        { staticClass: "toplist_right boxShadow" },
                        [
                          _c("div", { staticClass: "num_title" }, [
                            _vm._v(" D收益 "),
                          ]),
                          _vm._l(_vm.topListProfitD, function (item, index) {
                            return _c(
                              "ul",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.topListProfitD &&
                                      _vm.topListProfitD.length !== 0,
                                    expression:
                                      "topListProfitD && topListProfitD.length !== 0",
                                  },
                                ],
                                key: index,
                                staticClass: "list_num",
                              },
                              [
                                _c(
                                  "li",
                                  [
                                    _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          placement: "bottom-end",
                                          effect: "dark",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "font-size": "10px",
                                            },
                                            attrs: { slot: "content" },
                                            slot: "content",
                                          },
                                          [
                                            _c("div", [
                                              _vm._v(_vm._s(item.orgName)),
                                            ]),
                                          ]
                                        ),
                                        _c("div", [
                                          _vm._v(
                                            " " + _vm._s(item.orgName) + " "
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c("div", [
                                      _vm._v(
                                        " ￥" +
                                          _vm._s(
                                            item.policyPremium
                                              ? item.policyPremium.toLocaleString(
                                                  "zh",
                                                  { minimumFractionDigits: 2 }
                                                )
                                              : "0.00"
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            )
                          }),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    !_vm.topListProfitD ||
                                    _vm.topListProfitD.length === 0,
                                  expression:
                                    "!topListProfitD || topListProfitD.length === 0",
                                },
                              ],
                              staticClass: "list_num",
                            },
                            [_vm._v(" 暂无数据 ")]
                          ),
                        ],
                        2
                      ),
                    ]),
                  ]
                ),
                _c(
                  "el-card",
                  {
                    staticClass: "box-card",
                    staticStyle: { "margin-top": "10px" },
                  },
                  [
                    _c("div", { staticClass: "toplist_title" }, [
                      _vm._v("服务类型业务统计"),
                    ]),
                    _c(
                      "div",
                      { staticClass: "table_Box" },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.tableDataLoading,
                                expression: "tableDataLoading",
                              },
                            ],
                            ref: "multipleTable",
                            staticStyle: {
                              width: "100%",
                              "margin-bottom": "20px",
                            },
                            attrs: {
                              data: _vm.tableData,
                              "row-class-name": _vm.tableRowClassName,
                              "summary-method": _vm.getSummaries,
                              "show-summary": "",
                              border: "",
                              "row-key": "name",
                              "default-expand-all": "",
                              "tree-props": {
                                children: "children",
                                hasChildren: "hasChildren",
                              },
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "name",
                                label: "服务类型",
                                width: "150",
                                "class-name": "first-box",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "penetrationRatio",
                                label: "渗透率",
                                width: "100",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.productClassCode == "TCWY"
                                        ? _c("span", [_vm._v("-")])
                                        : _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  scope.row.penetrationRatio
                                                    ? parseFloat(
                                                        scope.row
                                                          .penetrationRatio
                                                      ).toFixed(2) + "%"
                                                    : "-"
                                                ) +
                                                " "
                                            ),
                                          ]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "compensateRate",
                                label: "赔付率",
                                width: "100",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.productClassCode == "XXAX" ||
                                      scope.row.productClassCode == "DXWY" ||
                                      scope.row.productClassCode == "YB"
                                        ? _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  scope.row.compensateRate
                                                    ? parseFloat(
                                                        scope.row.compensateRate
                                                      ).toFixed(2) + "%"
                                                    : "-"
                                                ) +
                                                " "
                                            ),
                                          ])
                                        : _c("span", [_vm._v("-")]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "policyCount",
                                label: "出单量",
                                "min-width": "auto",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            scope.row.policyCount
                                              ? scope.row.policyCount
                                              : "-"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "policyCountRatio",
                                label: "出单占比",
                                "min-width": "auto",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            scope.row.policyCountRatio
                                              ? parseFloat(
                                                  scope.row.policyCountRatio
                                                ).toFixed(2) + "%"
                                              : "-"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "policyCountRelativeRatio",
                                label: "环比上期",
                                "min-width": "auto",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                parseFloat(
                                                  scope.row
                                                    .policyCountRelativeRatio
                                                ) > 100,
                                              expression:
                                                "parseFloat(scope.row.policyCountRelativeRatio)>100",
                                            },
                                          ],
                                          staticClass: "increase",
                                        },
                                        [_vm._v("↑")]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                parseFloat(
                                                  scope.row
                                                    .policyCountRelativeRatio
                                                ) < 100,
                                              expression:
                                                "parseFloat(scope.row.policyCountRelativeRatio)<100",
                                            },
                                          ],
                                          staticClass: "decline",
                                        },
                                        [_vm._v("↓")]
                                      ),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            scope.row.policyCountRelativeRatio
                                              ? parseFloat(
                                                  scope.row
                                                    .policyCountRelativeRatio
                                                ).toFixed(2) + "%"
                                              : "-"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "policyCountSamePeriodRatio",
                                label: "同比上期",
                                "min-width": "auto",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                parseFloat(
                                                  scope.row
                                                    .policyCountSamePeriodRatio
                                                ) > 100,
                                              expression:
                                                "parseFloat(scope.row.policyCountSamePeriodRatio)>100",
                                            },
                                          ],
                                          staticClass: "increase",
                                        },
                                        [_vm._v("↑")]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                parseFloat(
                                                  scope.row
                                                    .policyCountSamePeriodRatio
                                                ) < 100,
                                              expression:
                                                "parseFloat(scope.row.policyCountSamePeriodRatio)<100",
                                            },
                                          ],
                                          staticClass: "decline",
                                        },
                                        [_vm._v("↓")]
                                      ),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            scope.row.policyCountSamePeriodRatio
                                              ? parseFloat(
                                                  scope.row
                                                    .policyCountSamePeriodRatio
                                                ).toFixed(2) + "%"
                                              : "-"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "policyProfit",
                                label: "F收益",
                                "min-width": "auto",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            scope.row.policyProfit
                                              ? scope.row.policyProfit
                                              : "-"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "policyProfitRatio",
                                label: "F收益占比",
                                "min-width": "auto",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            scope.row.policyProfitRatio
                                              ? parseFloat(
                                                  scope.row.policyProfitRatio
                                                ).toFixed(2) + "%"
                                              : "-"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "policyProfitRelativeRatio",
                                label: "环比上期",
                                "min-width": "auto",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                parseFloat(
                                                  scope.row
                                                    .policyProfitRelativeRatio
                                                ) > 100,
                                              expression:
                                                "parseFloat(scope.row.policyProfitRelativeRatio)>100",
                                            },
                                          ],
                                          staticClass: "increase",
                                        },
                                        [_vm._v("↑")]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                parseFloat(
                                                  scope.row
                                                    .policyProfitRelativeRatio
                                                ) < 100,
                                              expression:
                                                "parseFloat(scope.row.policyProfitRelativeRatio)<100",
                                            },
                                          ],
                                          staticClass: "decline",
                                        },
                                        [_vm._v("↓")]
                                      ),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            scope.row.policyProfitRelativeRatio
                                              ? parseFloat(
                                                  scope.row
                                                    .policyProfitRelativeRatio
                                                ).toFixed(2) + "%"
                                              : "-"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "policyProfitSamePeriodRatio",
                                label: "同比上期",
                                "min-width": "auto",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                parseFloat(
                                                  scope.row
                                                    .policyProfitSamePeriodRatio
                                                ) > 100,
                                              expression:
                                                "parseFloat(scope.row.policyProfitSamePeriodRatio)>100",
                                            },
                                          ],
                                          staticClass: "increase",
                                        },
                                        [_vm._v("↑")]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                parseFloat(
                                                  scope.row
                                                    .policyProfitSamePeriodRatio
                                                ) < 100,
                                              expression:
                                                "parseFloat(scope.row.policyProfitSamePeriodRatio)<100",
                                            },
                                          ],
                                          staticClass: "decline",
                                        },
                                        [_vm._v("↓")]
                                      ),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            scope.row
                                              .policyProfitSamePeriodRatio
                                              ? parseFloat(
                                                  scope.row
                                                    .policyProfitSamePeriodRatio
                                                ).toFixed(2) + "%"
                                              : "-"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "policyProfitD",
                                label: "D收益",
                                "min-width": "auto",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            scope.row.policyProfitD
                                              ? scope.row.policyProfitD
                                              : "-"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "policyProfitDRatio",
                                label: "D收益占比",
                                "min-width": "auto",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            scope.row.policyProfitDRatio
                                              ? parseFloat(
                                                  scope.row.policyProfitDRatio
                                                ).toFixed(2) + "%"
                                              : "-"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }