var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-m-cls", staticStyle: { background: "transparent" } },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "80vh", "overflow-y": "scroll" },
        },
        [
          _c(
            "el-card",
            { staticStyle: { height: "100%" }, attrs: { shadow: "never" } },
            [
              _c(
                "el-row",
                {
                  staticStyle: { padding: "10px", "box-sizing": "border-box" },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 14 } },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "detail",
                          staticStyle: { "margin-bottom": "-20px" },
                          attrs: {
                            model: _vm.form,
                            rules: _vm.rules,
                            "label-width": "90px",
                            size: "mini",
                          },
                        },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "险种类型：" } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            filterable: "",
                                            placeholder: "请选择险种类型",
                                          },
                                          model: {
                                            value: _vm.form.riskType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "riskType",
                                                $$v
                                              )
                                            },
                                            expression: "form.riskType",
                                          },
                                        },
                                        _vm._l(
                                          _vm.InsuranceList,
                                          function (item, index) {
                                            return _c("el-option", {
                                              key: index,
                                              attrs: {
                                                label: item.name,
                                                value: item.code,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "保险公司：",
                                        prop: "insCorpCode",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            placeholder: "请选择保险公司",
                                          },
                                          on: { change: _vm.getInsCorpName },
                                          model: {
                                            value: _vm.form.insCorpCode,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "insCorpCode",
                                                $$v
                                              )
                                            },
                                            expression: "form.insCorpCode",
                                          },
                                        },
                                        _vm._l(
                                          _vm.supplyers,
                                          function (item, index) {
                                            return _c("el-option", {
                                              key: index,
                                              attrs: {
                                                label: item.shortName,
                                                value: item.code,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "地区类型：" } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: {
                                            width: "100%",
                                            "margin-right": "8px",
                                          },
                                          attrs: {
                                            placeholder: "请选择地区类型",
                                            clearable: "",
                                          },
                                          on: {
                                            change: _vm.ChangeDistrictType,
                                          },
                                          model: {
                                            value: _vm.form.districtType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "districtType",
                                                $$v
                                              )
                                            },
                                            expression: "form.districtType",
                                          },
                                        },
                                        _vm._l(
                                          _vm.districtTypeList,
                                          function (item, index) {
                                            return _c("el-option", {
                                              key: index,
                                              attrs: {
                                                label: item.name,
                                                value: item.code,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "地区：" } },
                                    [
                                      _vm.form.districtType == "province"
                                        ? _c(
                                            "el-select",
                                            {
                                              staticStyle: {
                                                width: "100%",
                                                "margin-right": "8px",
                                              },
                                              attrs: {
                                                placeholder: "请选择省",
                                                clearable: "",
                                              },
                                              on: {
                                                change: _vm.setInsCityName,
                                              },
                                              model: {
                                                value: _vm.form.insCityCode,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "insCityCode",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.insCityCode",
                                              },
                                            },
                                            _vm._l(
                                              _vm.proList,
                                              function (item, index) {
                                                return _c("el-option", {
                                                  key: index,
                                                  attrs: {
                                                    label: item.name,
                                                    value: item.code,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          )
                                        : _c("el-cascader", {
                                            ref: "cascaderAddr",
                                            staticStyle: {
                                              width: "100%",
                                              "margin-right": "8px",
                                            },
                                            attrs: { options: _vm.cityOptions },
                                            on: { change: _vm.handleChange },
                                            model: {
                                              value: _vm.cascaderAddr,
                                              callback: function ($$v) {
                                                _vm.cascaderAddr = $$v
                                              },
                                              expression: "cascaderAddr",
                                            },
                                          }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "条款：" } },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: "请输入条款",
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.form.clause,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "clause", $$v)
                                          },
                                          expression: "form.clause",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "是否默认：" } },
                                    [
                                      _c(
                                        "el-radio-group",
                                        {
                                          model: {
                                            value: _vm.form.defaultStatus,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "defaultStatus",
                                                $$v
                                              )
                                            },
                                            expression: "form.defaultStatus",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio",
                                            { attrs: { label: true } },
                                            [_vm._v("是")]
                                          ),
                                          _c(
                                            "el-radio",
                                            { attrs: { label: false } },
                                            [_vm._v("否")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "是否启用：" } },
                                    [
                                      _c(
                                        "el-radio-group",
                                        {
                                          model: {
                                            value: _vm.form.enableStatus,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "enableStatus",
                                                $$v
                                              )
                                            },
                                            expression: "form.enableStatus",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio",
                                            { attrs: { label: true } },
                                            [_vm._v("启用")]
                                          ),
                                          _c(
                                            "el-radio",
                                            { attrs: { label: false } },
                                            [_vm._v("禁用")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "解析内容：" } },
                                [
                                  _c(
                                    "el-upload",
                                    {
                                      ref: "upload",
                                      attrs: {
                                        "before-remove": _vm.removeFile,
                                        limit: 1,
                                        action: "",
                                        "http-request": _vm.uploadFile,
                                        "file-list": _vm.fileList,
                                        accept: ".pdf",
                                        "list-type": "text",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        { attrs: { size: "mini", plain: "" } },
                                        [_vm._v("选择文件")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { "margin-top": "10px" },
                                      attrs: {
                                        size: "mini",
                                        type: "primary",
                                        plain: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.copyAddress1(
                                            _vm.PDFContent
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("点击复制")]
                                  ),
                                  _c("el-input", {
                                    staticStyle: { "margin-top": "10px" },
                                    attrs: {
                                      disabled: "",
                                      clearable: "",
                                      type: "textarea",
                                      rows: 12,
                                    },
                                    model: {
                                      value: _vm.PDFContent,
                                      callback: function ($$v) {
                                        _vm.PDFContent = $$v
                                      },
                                      expression: "PDFContent",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "模板内容：",
                                    prop: "templateContent",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请输入模板内容",
                                      clearable: "",
                                      type: "textarea",
                                      rows: 12,
                                    },
                                    model: {
                                      value: _vm.form.templateContent,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "templateContent",
                                          $$v
                                        )
                                      },
                                      expression: "form.templateContent",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: { "margin-bottom": "0" },
                                  attrs: { label: "" },
                                },
                                [
                                  _c("div", [
                                    _vm._v("变化的内容用"),
                                    _c(
                                      "span",
                                      { staticStyle: { color: "red" } },
                                      [_vm._v("占位符")]
                                    ),
                                    _vm._v("代替"),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("el-col", { attrs: { span: 1 } }),
                  _c(
                    "el-col",
                    { attrs: { span: 9 } },
                    [
                      _c(
                        "el-table",
                        {
                          ref: "multipleTable",
                          staticStyle: { width: "100%" },
                          attrs: {
                            size: "mini",
                            border: "",
                            data: _vm.tableData,
                            height: "600px",
                            "header-cell-style": { background: "#F7F7F7" },
                            "highlight-current-row": "",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "code",
                              label: "编码",
                              "show-overflow-tooltip": "",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "checkStatus",
                              label: "是否校验",
                              "show-overflow-tooltip": "",
                              width: "80",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          scope.row.checkStatus ? "是" : "否"
                                        ) +
                                        " "
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "name",
                              label: "占位符(点击复制)",
                              "show-overflow-tooltip": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("span", {
                                      staticStyle: { cursor: "pointer" },
                                      domProps: {
                                        textContent: _vm._s(
                                          "{{" + scope.row.name + "}}"
                                        ),
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.copyAddress(scope.row.name)
                                        },
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-check",
                type: "primary",
                plain: "",
                size: "mini",
              },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: { click: _vm.closePage },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }