import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.find.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DatePicker from '@/components/DatePicker';
import taskAllocationRequest from '@/api/taskAllocation';
import leadManagementApi from '@/api/leadManagement';
import { getUserOrgInfo, limitInput } from '@/utils/common';
export default {
  // mixins: [dict],
  props: {
    refresh: {
      type: Boolean,
      default: false
    },
    opType: {
      type: String,
      default: ""
    }
  },
  components: {
    DatePicker: DatePicker
  },
  data: function data() {
    return {
      form: {},
      formRules: {
        effectiveStartTime: [{
          required: true,
          message: '请选择保险到期日',
          trigger: 'change'
        }]
      },
      startEndTime: [],
      formLoading: false,
      bizType: localStorage.getItem('bizType') || "",
      customersTypeList: [],
      distributionType: "LAST",
      tableData: [],
      multipleSelection: [],
      lastFlag: false,
      currentCustomerSys: {},
      ownerBySituation: [],
      tableLoading: false,
      isShow: false
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    // 限制只能输入数字
    onInput: function onInput(val) {
      var limit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      return limitInput(val, limit);
    },
    // 多选事件
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 选择分配客户类型
    selectCustomersType: function selectCustomersType(item) {
      this.isShow = true;
      this.currentCustomerSys = item;
      this.getDealerEmployees();
    },
    // 获取分配类型
    getDistributionType: function getDistributionType() {
      var _this = this;
      this.$refs['detail'].validate(function (valid) {
        if (valid) {
          var userInfo = getUserOrgInfo() || {};
          var postData = {
            bizType: _this.bizType,
            effectiveEndTime: "",
            effectiveStartTime: "",
            orgCode: userInfo.orgCode
          };
          if (!_.isEmpty(_this.startEndTime)) {
            postData.effectiveStartTime = _this.startEndTime[0].split(' ')[0] + ' 00:00:00' || "";
            postData.effectiveEndTime = _this.startEndTime[1].split(' ')[0] + ' 23:59:59' || "";
          }
          taskAllocationRequest.getDistributionType(postData).then(function (res) {
            if (res.code == 200) {
              _this.customersTypeList = res.data;
              _this.ownerBySituation = res.data.ownerBySituation;
            }
          });
        }
      });
    },
    //日期选择事件
    getPickersDate: function getPickersDate(name, val) {
      if (val && val.length > 1) {
        this[name] = val;
        this.form.effectiveStartTime = name[1];
      } else {
        this[name] = [];
        this.form.effectiveStartTime = "";
      }
    },
    // 初始化数据
    initData: function initData() {
      var _this2 = this;
      this.customersTypeList = [];
      this.startEndTime = [];
      this.form = {
        effectiveStartTime: ""
      };
      this.isShow = false;
      this.$nextTick(function () {
        return _this2.$refs['detail'].clearValidate();
      });
    },
    // 获取详情
    getDetailById: function getDetailById() {
      var _this3 = this;
      this.formLoading = true;
      taskAllocationRequest.getDetailById(this.groupId).then(function (res) {
        _this3.formLoading = false;
        if (res.code == 200) {
          _this3.form = res.data;
        }
      }).catch(function (err) {
        return _this3.formLoading = false;
      });
    },
    // 保存数据
    save: function save() {
      var _this4 = this;
      if (_.isEmpty(this.multipleSelection)) {
        this.$message.warning("请选择要分配的续保专员");
        return;
      }
      // 平均分配
      if (this.distributionType == 'NUMBER') {
        var distributionCnt = 0;
        this.multipleSelection.forEach(function (item) {
          distributionCnt += parseInt(item.paramProportion);
        });
        if (distributionCnt !== this.customersTypeList.cnt) {
          this.$message.warning("已分配任务不能小于或大于分配任务总数");
          return;
        }
      }
      var userInfo = getUserOrgInfo() || {};
      var postData = {
        bizType: this.bizType,
        customerSysType: this.currentCustomerSys.customerTypeEnum,
        distributionItem: [],
        distributionType: this.distributionType,
        effectiveEndTime: "",
        effectiveStartTime: "",
        lastFlag: this.lastFlag,
        orgCode: userInfo.orgCode
      };
      if (!_.isEmpty(this.startEndTime)) {
        postData.effectiveStartTime = this.startEndTime[0].split(' ')[0] + ' 00:00:00' || "";
        postData.effectiveEndTime = this.startEndTime[1].split(' ')[0] + ' 23:59:59' || "";
      }
      postData.distributionItem = this.multipleSelection.map(function (item) {
        return {
          ownerBy: item.userId,
          paramProportion: _this4.distributionType == 'NUMBER' ? item.paramProportion : ''
        };
      });
      taskAllocationRequest.createDistribution(postData).then(function (res) {
        if (res.code == 200) {
          _this4.$message.success(res.msg);
          _this4.closePage(true);
        }
      });
    },
    // 关闭弹窗
    closePage: function closePage(isRefresh) {
      this.$emit('closePage', isRefresh);
    },
    // 获取续保专员列表
    getDealerEmployees: function getDealerEmployees() {
      var _this5 = this;
      var postData = {
        employeeStatus: 'REGULAR',
        employeeType: 'SALESMAN'
      };
      this.tableLoading = true;
      leadManagementApi.dealerEmployees(postData).then(function (res) {
        _this5.tableLoading = false;
        if (res.code && res.data) {
          _this5.tableData = res.data.map(function (item) {
            var owner = _.find(_this5.ownerBySituation, function (eitem) {
              return eitem.ownerBy == item.userId;
            }) || {};
            return _objectSpread(_objectSpread({}, item), {}, {
              paramProportion: "",
              cnt: owner.cnt
            });
          });
        }
      }).catch(function (err) {
        return _this5.tableLoading = false;
      });
    }
  }
};