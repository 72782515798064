var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "customer-type" },
    [
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "space-between",
            "align-items": "center",
            margin: "5px 0",
          },
        },
        [
          _c("b", [_vm._v("客户类型")]),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                disabled:
                  _vm.currentObj &&
                  _vm.currentObj.defaulted &&
                  !!_vm.curRenewalConfigParams.orgCode,
                icon: "el-icon-plus",
                plain: "",
                size: "mini",
              },
              on: {
                click: function ($event) {
                  return _vm.add("add")
                },
              },
            },
            [_vm._v("新增")]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          style: { height: _vm.tableHeightN, overflow: "auto" },
        },
        [
          _c(
            "table",
            { style: { width: "100%" }, attrs: { border: "1" } },
            [
              _vm._m(0),
              _vm._m(1),
              _vm._l(_vm.tableData, function (item, index) {
                return _c("tbody", { key: index }, [
                  _c("tr", [
                    _c("td", { staticClass: "p9", attrs: { rowspan: "2" } }, [
                      _vm._v(_vm._s(item.distinguishName)),
                    ]),
                    _vm._m(2, true),
                    _c("td", { staticClass: "p9" }, [
                      _vm._v(_vm._s(item.successName)),
                    ]),
                    _c(
                      "td",
                      { staticClass: "p9", attrs: { rowspan: "2" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              disabled:
                                _vm.currentObj &&
                                _vm.currentObj.defaulted &&
                                !!_vm.curRenewalConfigParams.orgCode,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.edit("edit", item)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("tr", [
                    _vm._m(3, true),
                    _c("td", { staticClass: "p9" }, [
                      _vm._v(_vm._s(item.defeatName)),
                    ]),
                  ]),
                ])
              }),
            ],
            2
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showDialog,
            "close-on-click-modal": false,
            width: "600px",
            "append-to-body": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [
                  _vm._v(
                    _vm._s(_vm.opType === "edit" ? "编辑" : "新增") + "客户类别"
                  ),
                ]
              ),
            ]
          ),
          _c("CustomerTypeDialog", {
            attrs: {
              types: "type",
              opType: _vm.opType,
              refresh: _vm.showDialog,
              currentItem: _vm.currentItem,
              configId: _vm.configId,
              selectList: _vm.tableData,
            },
            on: { closePage: _vm.refreshData },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "30%", "min-width": "140px" } }),
      _c("col", { staticStyle: { width: "35%", "min-width": "200px" } }),
      _c("col", { staticStyle: { width: "30%", "min-width": "140px" } }),
      _c("col", { staticStyle: { width: "5%", "min-width": "140px" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", { staticStyle: { "background-color": "#f7f7f7" } }, [
      _c("tr", [
        _c("th", { staticClass: "p9", attrs: { scope: "col" } }, [
          _vm._v("原客户类型"),
        ]),
        _c("th", { staticClass: "p9", attrs: { scope: "col" } }, [
          _vm._v("操作类别"),
        ]),
        _c("th", { staticClass: "p9", attrs: { scope: "col" } }, [
          _vm._v("现客户类型"),
        ]),
        _c("th", { staticClass: "p9", attrs: { scope: "col" } }, [
          _vm._v("操作"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { staticClass: "p9" }, [
      _c(
        "div",
        {
          staticStyle: { display: "flex", "justify-content": "space-between" },
        },
        [
          _c("span", [_vm._v("通过")]),
          _c("span", [_vm._v("成功出单")]),
          _c("span", [_vm._v("流转为")]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { staticClass: "p9" }, [
      _c(
        "div",
        {
          staticStyle: { display: "flex", "justify-content": "space-between" },
        },
        [
          _c("span", [_vm._v("通过")]),
          _c("span", [_vm._v("战败")]),
          _c("span", [_vm._v("流转为")]),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }