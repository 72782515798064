import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import dict from "@/mixins/dict";
import initHeight from "@/mixins/initHeight";
import policyRequest from "@/api/insurancepolicy";
import storageRequest from '@/api/storage';
import OemSelect from '@/components/OemSelect';
import requestOrg from '@/api/orgChildren';
import commonReq from '@/api/common';
import { getSupplyers, getUserOrgInfoDealerOem, downFile } from "@/utils/common";
export default {
  components: {
    OemSelect: OemSelect
  },
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    bizType: {
      type: String,
      default: ''
    }
  },
  mixins: [initHeight, dict],
  data: function data() {
    return {
      uploadForm: {
        bizType: 'DEALER',
        insCityCode: [],
        orgCode: '',
        orgName: '',
        salemanId: '',
        insCorpCode: '',
        checked: true
      },
      formRules: {
        orgCode: [{
          required: true,
          message: '请选择所属机构',
          trigger: 'change'
        }],
        importSchemaId: [{
          required: true,
          message: '请选择导入方案',
          trigger: 'change'
        }]
      },
      fileList: [],
      users: [],
      importLoading: false,
      disableSave: true,
      orgForm: {
        salesOrgCode: '',
        salesOrgName: ''
      },
      cityList: JSON.parse(localStorage.getItem("VI_CityPcTree")) || [],
      supplyers: [],
      importSchemas: []
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
        this.$refs.detail.resetFields();
      }
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    // 初始数据
    initData: function initData() {
      var _this = this;
      this.orgForm = {
        salesOrgCode: '',
        salesOrgName: ''
      };
      this.uploadForm = {
        bizType: 'DEALER',
        insCityCode: [],
        orgCode: '',
        orgName: '',
        salemanId: '',
        insCorpCode: '',
        checked: true
      };
      this.users = [];
      this.fileList = [];
      this.supplyers = getSupplyers();
      if (this.supplyers) {
        _.remove(this.supplyers, function (o) {
          return o.code == 'QITA' || o.code == 'qita';
        });
      }
      if (this.bizType === 'DEALER') {
        var orgObj = getUserOrgInfoDealerOem(this.bizType);
        this.uploadForm.orgCode = orgObj.salesOrgCode;
        this.uploadForm.orgName = orgObj.salesOrgName;
        this.uploadForm.bizType = orgObj.bizType;
        this.uploadForm.salesOrgType = orgObj.bizType;
        commonReq.getTemplateByOrg({
          bizType: orgObj.bizType,
          importCategory: 'POLICY',
          orgCode: orgObj.salesOrgCode
        }).then(function (res) {
          if (res.data) {
            _this.importSchemas = res.data;
            if (!_.isEmpty(_this.importSchemas) && _this.importSchemas.length == 1) {
              _this.uploadForm.importSchemaId = _this.importSchemas[0].templateId;
              _this.uploadForm.templateCode = _this.importSchemas[0].templateCode;
            }
          }
        });
        this.dealerGetUsers(this.uploadForm.orgCode);
      }
    },
    // 获取续保专员列表(经销商)
    dealerGetUsers: function dealerGetUsers(orgCode) {
      var _this2 = this;
      requestOrg.getOrgPerson(orgCode).then(function (res) {
        if (res.code && res.data) {
          _this2.users = res.data;
        }
      });
    },
    handleNodeClick: function handleNodeClick(data) {
      var _this3 = this;
      // 机构树点击事件
      this.uploadForm.orgCode = data.salesOrgCode;
      this.uploadForm.orgName = data.salesOrgName;
      this.uploadForm.bizType = data.bizType;
      this.uploadForm.salesOrgType = data.bizType;
      this.uploadForm.salemanId = null;
      this.users = null;
      commonReq.getTemplateByOrg({
        bizType: data.bizType,
        importCategory: 'POLICY',
        orgCode: data.salesOrgCode
      }).then(function (res) {
        if (res.data) {
          _this3.importSchemas = res.data;
          if (!_.isEmpty(_this3.importSchemas) && _this3.importSchemas.length == 1) {
            _this3.uploadForm.importSchemaId = _this3.importSchemas[0].templateId;
            _this3.uploadForm.templateCode = _this3.importSchemas[0].templateCode;
          }
        }
      });
    },
    getUsers: function getUsers(val) {
      //列表行选择事件
      this.users = val;
    },
    // 上传文件
    uploadFile: function uploadFile(param) {
      var _this4 = this;
      this.disableSave = true;
      var formData = new FormData();
      formData.append('file', param.file);
      storageRequest.uploadFile(formData).then(function (res) {
        setTimeout(function () {
          _this4.disableSave = false;
        }, 1000);
        if (res.code === 200) {
          _this4.$message.success(res.msg);
          setTimeout(function () {
            _this4.uploadForm.fileId = res.data.fileStorageId;
            _this4.uploadForm.fileName = param.file.name;
            _this4.uploadForm.fileUrl = res.data.fileUrl;
          }, 1000);
        }
        ;
      });
    },
    // 附件校验
    validate: function validate() {
      var flag = true;
      if (!this.uploadForm.fileId) {
        this.$message.warning('请上传文件');
        flag = false;
        return flag;
      }
      return flag;
    },
    saveUpload: function saveUpload() {
      var _this5 = this;
      this.$refs["detail"].validate(function (valid) {
        if (valid) {
          if (!_this5.validate()) return;
          if (_.isArray(_this5.uploadForm.insCityCode)) {
            _this5.uploadForm.insCityCode = _this5.uploadForm.insCityCode[1] || '';
          }
          var importSchema = _.find(_this5.importSchemas, function (item) {
            return item.templateId == _this5.uploadForm.importSchemaId;
          });
          if (importSchema) _this5.uploadForm.importSchemaCode = importSchema.templateCode;
          _this5.importLoading = true;
          policyRequest.policyImportUpload(_this5.uploadForm).then(function (res) {
            _this5.importLoading = false;
            if (res.code === 200) {
              _this5.$confirm("".concat(res.data.failures ? '文件模板失败数据' + res.data.failures + '条，点击打开文件，重新上传导入，谢谢！' : '导入成功', " "), '温馨提示', {
                showCancelButton: false,
                showClose: false,
                closeOnClickModal: false,
                closeOnPressEscape: false,
                confirmButtonText: "".concat(res.data.failures ? '打开文件' : "确定"),
                type: "".concat(res.data.failures ? 'warning' : 'success')
              }).then(function () {
                if (res.data.failures) {
                  downFile(res.data.fileUrl, res.data.fileName);
                  _this5.fileList = [];
                  _this5.uploadForm.fileId = '';
                } else {
                  if (res.data) {
                    _this5.$message.success("\u5BFC\u5165\u6210\u529F\uFF0C\u540E\u53F0\u6B63\u5728\u6267\u884C\u5BFC\u5165\u4EFB\u52A1\uFF0C\u8BF7\u8010\u5FC3\u7B49\u5F85\u3002");
                    _this5.fileList = [];
                    _this5.closePage();
                  } else {
                    _this5.$message.warning("\u5BFC\u5165\u5931\u8D25\uFF0C\u8BF7\u7A0D\u540E\u91CD\u8BD5\uFF01");
                  }
                }
              }).catch(function () {});
            }
          }, function (err) {}).catch(function () {
            _this5.importLoading = false;
          });
        }
      });
    },
    // 关闭弹窗
    closePage: function closePage() {
      this.$emit("closePage");
    }
  }
};