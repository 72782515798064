//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import carService from '@/api/carService';
export default {
  // mixins: [dict],
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    DialogType: {
      type: String,
      default: {}
    },
    insCorpItem: {
      type: Object,
      default: {}
    }
  },
  data: function data() {
    return {
      form: {
        brandNo: "",
        brandName: "",
        "sortBy": 0,
        "vehicleBrandId": 0,
        'deleted': 0
      },
      listLoading: false,
      rules: {
        brandNo: [{
          required: true,
          message: '请输入品牌编号',
          trigger: 'blur'
        }],
        brandName: [{
          required: true,
          message: '请输入品牌名称',
          trigger: 'blur'
        }]
      }
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    initData: function initData() {
      if (this.DialogType === "add") {
        this.form = {};
      } else {
        this.form = this.insCorpItem;
      }
    },
    save: function save() {
      var _this = this;
      //保存数据【新增  或  编辑】
      this.$refs.detail.validate(function (valid) {
        if (valid) {
          var req = _this.DialogType === "add" ? carService.brandBrand(_this.form) : carService.updateBrand(_this.insCorpItem.vehicleBrandId, _this.form);
          req.then(function (res) {
            if (res.code === 200) {
              _this.$message({
                message: res.msg,
                type: 'success'
              });
              _this.closePage();
            }
          });
        }
      });
    },
    closePage: function closePage() {
      //关闭弹窗
      this.$emit('closePage');
      this.$refs['detail'].resetFields();
    },
    loadData: function loadData() {//加载数据
    }
  }
};