var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "app-m-cls" },
      [
        _c(
          "div",
          { staticClass: "table-cls normal-btn-cls" },
          [
            _c(
              "el-drawer",
              {
                attrs: {
                  title: "",
                  visible: _vm.drawer,
                  modal: false,
                  "append-to-body": false,
                  size: "350px",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.drawer = $event
                  },
                },
              },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    staticStyle: { "margin-bottom": "15px" },
                    attrs: {
                      model: _vm.searchForm,
                      "label-width": "110px",
                      size: "mini",
                    },
                  },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "车牌号：" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "40",
                                    placeholder: "请输入车牌号",
                                    clearable: "",
                                  },
                                  on: { input: _vm.inputPlateNo },
                                  model: {
                                    value: _vm.searchForm.plateNo,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchForm, "plateNo", $$v)
                                    },
                                    expression: "searchForm.plateNo",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "车架号：" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "40",
                                    placeholder: "请输入车架号",
                                    clearable: "",
                                  },
                                  on: { input: _vm.inputvin },
                                  model: {
                                    value: _vm.searchForm.vin,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchForm, "vin", $$v)
                                    },
                                    expression: "searchForm.vin",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "保险到期日：" } },
                              [
                                _c("el-date-picker", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    "start-placeholder": "起期",
                                    "end-placeholder": "止期",
                                    type: "daterange",
                                    format: "yyyy-MM-dd",
                                    "value-format": "yyyy-MM-dd HH:mm:ss",
                                    "unlink-panels": "",
                                    "range-separator": "——",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.dateChange(
                                        "startOverdueDate",
                                        "endOverdueDate",
                                        $event
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.overdueStartEndTime,
                                    callback: function ($$v) {
                                      _vm.overdueStartEndTime = $$v
                                    },
                                    expression: "overdueStartEndTime",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "保险到期天数：" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "40",
                                    placeholder: "请输入保险到期天数",
                                    oninput:
                                      "value=value.replace(/[^\\d]/g,'')",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.searchForm.daysInsuranceDue,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "daysInsuranceDue",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.daysInsuranceDue",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "无到期日：" } },
                              [
                                _c(
                                  "el-checkbox-group",
                                  {
                                    on: { change: _vm.ChangeNoMaturityDate },
                                    model: {
                                      value: _vm.noMaturityDate,
                                      callback: function ($$v) {
                                        _vm.noMaturityDate = $$v
                                      },
                                      expression: "noMaturityDate",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        attrs: { label: "noBzLastYearEndDate" },
                                      },
                                      [_vm._v("交强险")]
                                    ),
                                    _c(
                                      "el-checkbox",
                                      {
                                        attrs: { label: "noBsLastYearEndDate" },
                                      },
                                      [_vm._v("商业险")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "客户跟进状态：" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      filterable: "",
                                      placeholder: "请选择客户跟进状态",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.searchForm.trackStatus,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "trackStatus",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.trackStatus",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: {
                                        label: "全部",
                                        value: "ALL_TRACK",
                                      },
                                    }),
                                    _c("el-option", {
                                      attrs: {
                                        label: "未跟进",
                                        value: "NAE_TRACK",
                                      },
                                    }),
                                    _c("el-option", {
                                      attrs: {
                                        label: "已跟进",
                                        value: "ALREADY_TRACK",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "创建日期：" } },
                              [
                                _c("el-date-picker", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    "start-placeholder": "起期",
                                    "end-placeholder": "止期",
                                    type: "daterange",
                                    format: "yyyy-MM-dd",
                                    "value-format": "yyyy-MM-dd HH:mm:ss",
                                    "unlink-panels": "",
                                    "range-separator": "——",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.dateChange(
                                        "startCreatedDate",
                                        "endCreatedDate",
                                        $event
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.createdStartEndTime,
                                    callback: function ($$v) {
                                      _vm.createdStartEndTime = $$v
                                    },
                                    expression: "createdStartEndTime",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.bizType == "OEM"
                      ? _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 22 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "所属机构：" } },
                                  [
                                    _c("OemSelect", {
                                      attrs: {
                                        searchForm: _vm.orgForm,
                                        isGetUserList: true,
                                      },
                                      on: {
                                        getCurrentOrgUsers: _vm.getUsers,
                                        nodeClick: _vm.nodeClick,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "客户经理：" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      filterable: "",
                                      placeholder: "请选择客户经理",
                                      clearable: "",
                                    },
                                    on: { change: _vm.salemanNameChange },
                                    model: {
                                      value: _vm.searchForm.ownerBy,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.searchForm, "ownerBy", $$v)
                                      },
                                      expression: "searchForm.ownerBy",
                                    },
                                  },
                                  _vm._l(_vm.users, function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.userName,
                                        value: item.userId,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "注册日期：" } },
                              [
                                _c("el-date-picker", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    "start-placeholder": "起期",
                                    "end-placeholder": "止期",
                                    type: "daterange",
                                    format: "yyyy-MM-dd",
                                    "value-format": "yyyy-MM-dd HH:mm:ss",
                                    "unlink-panels": "",
                                    "range-separator": "——",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.dateChange(
                                        "startRegisterDate",
                                        "endRegisterDate",
                                        $event
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.registerStartEndTime,
                                    callback: function ($$v) {
                                      _vm.registerStartEndTime = $$v
                                    },
                                    expression: "registerStartEndTime",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "车主手机号：" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "40",
                                    placeholder: "请输入车主手机号",
                                    clearable: "",
                                    oninput:
                                      "value=value.replace(/[^\\d]/g,'')",
                                  },
                                  model: {
                                    value: _vm.searchForm.ownerMobile,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "ownerMobile",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.ownerMobile",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c("el-col", { attrs: { span: 24 } }, [
                          _c(
                            "div",
                            { staticClass: "searchBtnStyle" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    icon: "el-icon-refresh-left",
                                  },
                                  on: { click: _vm.resetSearch },
                                },
                                [_vm._v("重置")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    type: "primary",
                                    icon: "el-icon-search",
                                  },
                                  on: { click: _vm.searchData },
                                },
                                [_vm._v("查询")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "table-cls normal-btn-cls" },
          [
            _c(
              "el-card",
              { staticClass: "box-card", attrs: { shadow: "never" } },
              [
                _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                  _c("div", { staticClass: "search-box" }, [
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v("所属机构：")]),
                        _c("OemSelect", {
                          ref: "oemSelectRef",
                          attrs: {
                            disabled: _vm.bizType != "OEM",
                            searchForm: _vm.orgForm,
                            isGetUserList: true,
                          },
                          on: {
                            getCurrentOrgUsers: _vm.getUsers,
                            nodeClick: _vm.nodeClick,
                          },
                        }),
                        _c("span", [_vm._v("任务名称：")]),
                        _c(
                          "el-select",
                          {
                            staticClass: "renewalsTaskId",
                            staticStyle: { width: "200px" },
                            attrs: {
                              "collapse-tags": "",
                              filterable: "",
                              clearable: "",
                              multiple: "",
                              placeholder: "请选择任务名称",
                            },
                            model: {
                              value: _vm.renewalTaskIds,
                              callback: function ($$v) {
                                _vm.renewalTaskIds = $$v
                              },
                              expression: "renewalTaskIds",
                            },
                          },
                          _vm._l(_vm.TaskByOrglist, function (item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: {
                                label: item.taskName,
                                value: item.taskId,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              icon: "el-icon-refresh-left",
                            },
                            on: { click: _vm.resetSearch },
                          },
                          [_vm._v("重置")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "primary",
                              icon: "el-icon-search",
                            },
                            on: { click: _vm.searchData },
                          },
                          [_vm._v("查询")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "search-btn" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: { click: _vm.breakData },
                          },
                          [_vm._v("刷新")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.DistributionTracks()
                              },
                            },
                          },
                          [_vm._v("批量分配")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: { click: _vm.exportFn },
                          },
                          [_vm._v("导出")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: {
                              click: function ($event) {
                                _vm.drawer = true
                              },
                            },
                          },
                          [_vm._v("搜索")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c(
                  "div",
                  { staticStyle: { position: "relative" } },
                  [
                    _c(
                      "el-tabs",
                      {
                        on: { "tab-click": _vm.handleTabClick },
                        model: {
                          value: _vm.taskTabType,
                          callback: function ($$v) {
                            _vm.taskTabType = $$v
                          },
                          expression: "taskTabType",
                        },
                      },
                      [
                        _c("el-tab-pane", {
                          attrs: { label: "全部", name: "All" },
                        }),
                        _vm._l(_vm.tablist, function (item, index) {
                          return _c(
                            "el-tab-pane",
                            {
                              key: index,
                              attrs: { name: item.activeId.toString() },
                            },
                            [
                              _c("template", { slot: "label" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(item.activeName) +
                                    "(" +
                                    _vm._s(item.minDay) +
                                    "-" +
                                    _vm._s(item.maxDay) +
                                    "天到保)(" +
                                    _vm._s(item.Invalid) +
                                    ") "
                                ),
                              ]),
                            ],
                            2
                          )
                        }),
                        _c(
                          "el-tab-pane",
                          { attrs: { name: "Subscribe" } },
                          [
                            _c("template", { slot: "label" }, [
                              _vm._v(
                                " 预约待跟进(" + _vm._s(_vm.Subscribe) + ") "
                              ),
                            ]),
                          ],
                          2
                        ),
                        _c(
                          "el-tab-pane",
                          { attrs: { name: "OverdueTrack" } },
                          [
                            _c("template", { slot: "label" }, [
                              _vm._v(
                                " 逾期待跟进(" + _vm._s(_vm.OverdueTrack) + ") "
                              ),
                            ]),
                          ],
                          2
                        ),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    ref: "multipleTable",
                    staticStyle: { width: "100%" },
                    attrs: {
                      size: "mini",
                      border: "",
                      data: _vm.tableData,
                      height: _vm.tableHeightS,
                      "header-cell-style": { background: "#F7F7F7" },
                      "highlight-current-row": "",
                    },
                    on: {
                      "row-dblclick": _vm.dbSelected,
                      "selection-change": _vm.handleSelectionChange,
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        type: "selection",
                        width: "55",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "ownerByName",
                        label: "客户经理",
                        width: "180",
                        "show-overflow-tooltip": "",
                        fixed: "left",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "plateNo",
                        label: "车牌号",
                        width: "110",
                        "show-overflow-tooltip": "",
                        fixed: "left",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(scope.row.plateNo || "未上牌") +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "vin",
                        label: "车架号",
                        width: "180",
                        "show-overflow-tooltip": "",
                        fixed: "left",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "ownerName",
                        label: "车主名称",
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "ownerMobile",
                        label: "车主手机号",
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "modelName",
                        label: "品牌型号",
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "registerDate",
                        label: "注册日期",
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    scope.row.registerDate &&
                                      scope.row.registerDate.split(" ")[0]
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "bsLastYearInsCorpName",
                        label: "保险公司",
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "bzLastYearEndDate",
                        label: "交强险到期日",
                        "min-width": "150",
                        sortable: "",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    scope.row.bzLastYearEndDate &&
                                      scope.row.bzLastYearEndDate.split(" ")[0]
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "bsLastYearEndDate",
                        label: "商业险到期日",
                        "min-width": "150",
                        sortable: "",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    scope.row.bsLastYearEndDate &&
                                      scope.row.bsLastYearEndDate.split(" ")[0]
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "bzOverDueDays",
                        label: "交强险到期天数",
                        "min-width": "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "bsOverDueDays",
                        label: "商业险到期天数",
                        "min-width": "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "newestTrackClassifyName",
                        label: "最新跟进分类",
                        width: "100",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "newestTrackStatusName",
                        label: "最新跟进状态",
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "newestTrackTime",
                        label: "最新跟进时间",
                        width: "150",
                        sortable: "",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "newestSubscribeTime",
                        label: "下次跟进时间",
                        width: "150",
                        sortable: "",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    scope.row.newestSubscribeTime &&
                                      scope.row.newestSubscribeTime.split(
                                        " "
                                      )[0]
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "sourceDisplay",
                        label: "客户来源",
                        width: "180",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "customerSysTypeDisplay",
                        label: "系统客户类型",
                        width: "180",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "distinguishName",
                        label: "网点客户类型",
                        width: "180",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "orgName",
                        label: "所属机构",
                        width: "180",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "createdTime",
                        label: "创建时间",
                        width: "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "modifiedTime",
                        label: "更新时间",
                        width: "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "newestIntoShopTime",
                        label: "最新进店时间",
                        width: "150",
                        sortable: "",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "操作",
                        align: "center",
                        width: "260",
                        fixed: "right",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.viewDetail(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("查看")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.toQuote(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("报价")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.customerTracks(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("跟进")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.DistributionTracks(
                                        scope.row,
                                        false
                                      )
                                    },
                                  },
                                },
                                [_vm._v("分配")]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c("pagination", {
                  attrs: {
                    total: _vm.total,
                    page: _vm.listQuery.pageNum,
                    limit: _vm.listQuery.pageSize,
                  },
                  on: {
                    "update:page": function ($event) {
                      return _vm.$set(_vm.listQuery, "pageNum", $event)
                    },
                    "update:limit": function ($event) {
                      return _vm.$set(_vm.listQuery, "pageSize", $event)
                    },
                    pagination: function ($event) {
                      return _vm.loadData()
                    },
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-dialog",
          {
            staticClass: "pageDialog",
            attrs: {
              visible: _vm.showAddTracksDialog,
              "close-on-click-modal": false,
              width: "550px",
              "append-to-body": false,
            },
            on: {
              "update:visible": function ($event) {
                _vm.showAddTracksDialog = $event
              },
              handleClose: function ($event) {
                _vm.showDistributeDialog = false
              },
            },
          },
          [
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "title" },
                slot: "title",
              },
              [
                _c(
                  "span",
                  {
                    staticStyle: { "font-size": "15px", "font-weight": "700" },
                  },
                  [_vm._v("新增跟进")]
                ),
              ]
            ),
            _c("FollowUpDialog", {
              attrs: {
                "customer-item": _vm.customerItem,
                refresh: _vm.showAddTracksDialog,
              },
              on: { closePage: _vm.loadData },
            }),
          ],
          1
        ),
        _c(
          "el-dialog",
          {
            staticClass: "pageDialog",
            attrs: {
              visible: _vm.showDistributionDialog,
              "close-on-click-modal": false,
              width: "540px",
              "append-to-body": false,
            },
            on: {
              "update:visible": function ($event) {
                _vm.showDistributionDialog = $event
              },
              handleClose: function ($event) {
                _vm.showDistributeDialog = false
              },
            },
          },
          [
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "title" },
                slot: "title",
              },
              [
                _c(
                  "span",
                  {
                    staticStyle: { "font-size": "15px", "font-weight": "700" },
                  },
                  [_vm._v("分配")]
                ),
              ]
            ),
            _c("TaskCluesDistribution", {
              attrs: {
                refresh: _vm.showDistributionDialog,
                opType: _vm.opType,
                customerIdArr: _vm.customersIds,
              },
              on: { closePage: _vm.loadData },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }