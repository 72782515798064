var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.listLoading,
          expression: "listLoading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: {},
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "500px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "detail",
                  staticStyle: { "margin-bottom": "-20px" },
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-width": "110px",
                    size: "mini",
                  },
                },
                [
                  _vm.bizType === "BROKER"
                    ? _c(
                        "el-form-item",
                        {
                          attrs: { label: "所属机构：", prop: "ownerOrgName" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              ref: "orgSelect",
                              staticStyle: { width: "100%" },
                              attrs: {
                                filterable: "",
                                "filter-method": _vm.dataFilter,
                                placeholder: "请选择所属机构",
                              },
                              on: {
                                change: _vm.orgChange,
                                focus: _vm.orgFocus,
                                "visible-change": _vm.visibleChange,
                              },
                              model: {
                                value: _vm.form.ownerOrgName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "ownerOrgName", $$v)
                                },
                                expression: "form.ownerOrgName",
                              },
                            },
                            [
                              _c(
                                "el-option",
                                {
                                  staticStyle: {
                                    height: "auto",
                                    "background-color": "white",
                                  },
                                  attrs: { value: _vm.form.ownerOrgCode },
                                },
                                [
                                  _c("el-tree", {
                                    ref: "tree",
                                    staticClass: "filter-tree",
                                    attrs: {
                                      "node-key": "organizationId",
                                      "highlight-current": true,
                                      data: _vm.orgData,
                                      props: _vm.defaultProps,
                                      "filter-node-method": _vm.filterNode,
                                    },
                                    on: { "node-click": _vm.handleNodeClick },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-bottom": "35px" },
                      attrs: { label: "业务经理：", prop: "ownerBy" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            filterable: "",
                            placeholder: "请选择业务经理",
                            clearable: "",
                          },
                          on: { change: _vm.salemanNameChange },
                          model: {
                            value: _vm.form.ownerBy,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "ownerBy", $$v)
                            },
                            expression: "form.ownerBy",
                          },
                        },
                        _vm._l(_vm.users, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.userName, value: item.userId },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-check",
                type: "primary",
                plain: "",
                size: "mini",
              },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: { click: _vm.closePage },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }