var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-m-cls", staticStyle: {} }, [
    _c(
      "div",
      {
        ref: "header",
        staticClass: "header-cls",
        staticStyle: { "max-height": "540px", overflow: "auto" },
      },
      [
        _c(
          "el-card",
          { attrs: { shadow: "never" } },
          [
            _c(
              "el-form",
              {
                attrs: {
                  model: _vm.form,
                  "label-width": "120px",
                  size: "mini",
                },
              },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "交强险保险公司：" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.bzProduct
                                  ? _vm.getInsCorpName(_vm.bzProduct)
                                  : "-"
                              )
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "交强险保单号：" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.bzProduct
                                  ? _vm.bzProduct.policyNo || ""
                                  : "-"
                              )
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "交强险日期：" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.bzProduct
                                  ? _vm.bzProduct.effectiveStartTime || ""
                                  : ""
                              ) +
                                " 至 " +
                                _vm._s(
                                  _vm.bzProduct
                                    ? _vm.bzProduct.effectiveEndTime || ""
                                    : "-"
                                )
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "商业险保险公司：" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.bsProduct
                                  ? _vm.getInsCorpName(_vm.bsProduct)
                                  : "-"
                              )
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "商业险保单号：" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.bsProduct
                                  ? _vm.bsProduct.policyNo || ""
                                  : "-"
                              )
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "商业险日期：" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.bsProduct
                                  ? _vm.bsProduct.effectiveStartTime || ""
                                  : ""
                              ) +
                                " 至 " +
                                _vm._s(
                                  _vm.bsProduct
                                    ? _vm.bsProduct.effectiveEndTime || ""
                                    : "-"
                                )
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c("el-form-item", { attrs: { label: "车牌号：" } }, [
                          _vm._v(_vm._s(_vm.form.basicInformation.plateNo)),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c("el-form-item", { attrs: { label: "车架号：" } }, [
                          _vm._v(_vm._s(_vm.form.basicInformation.vin)),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c("el-form-item", { attrs: { label: "发动机号：" } }, [
                          _vm._v(_vm._s(_vm.form.basicInformation.engineNo)),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c("el-form-item", { attrs: { label: "车主名称：" } }, [
                          _vm._v(_vm._s(_vm.form.basicInformation.ownerName)),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c("el-form-item", { attrs: { label: "证件类型：" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.form.basicInformation.ownerCertificateTypeName
                            )
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c("el-form-item", { attrs: { label: "证件号码：" } }, [
                          _vm._v(
                            _vm._s(_vm.form.basicInformation.ownerCertificateNo)
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "核定载客量：" } },
                          [_vm._v(_vm._s(_vm.form.basicInformation.seats))]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "新车购置价：" } },
                          [
                            _vm._v(
                              _vm._s(_vm.form.basicInformation.purchasePrice)
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c("el-form-item", { attrs: { label: "注册日期：" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.form.basicInformation.registerDate &&
                                _vm.form.basicInformation.registerDate.split(
                                  " "
                                )[0]
                            )
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c("el-form-item", { attrs: { label: "品牌型号：" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.form.basicInformation.brandName ||
                                _vm.form.basicInformation.modelName
                            )
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c("el-form-item", { attrs: { label: "投保车型：" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.getProposalVehicleType(
                                _vm.form.basicInformation
                              )
                            )
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  { attrs: { span: 24 } },
                  [
                    _c(
                      "el-table",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          size: "mini",
                          border: "",
                          data: _vm.form.bslist,
                          "header-cell-style": { background: "#F7F7F7" },
                          "highlight-current-row": "",
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            prop: "riskkindName",
                            label: "险种",
                            "show-overflow-tooltip": "",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "amount",
                            label: "保额(元)",
                            align: "right",
                            "show-overflow-tooltip": "",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticStyle: {
          display: "flex",
          "justify-content": "center",
          "margin-top": "8px",
        },
      },
      [
        _c(
          "el-button",
          {
            attrs: { icon: "el-icon-close", size: "mini" },
            on: { click: _vm.closePage },
          },
          [_vm._v("关闭")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }