import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    valueArr: {
      default: function _default() {
        return ['01-03', '04-06', '07-09', '10-12'];
      },
      type: Array
    },
    getValue: {
      default: function _default() {},
      type: Function
    },
    defaultValue: {
      default: '',
      type: String
    }
  },
  data: function data() {
    return {
      showSeason: false,
      season: '',
      year: new Date().getFullYear(),
      quarterVal: '',
      monthVal: '',
      monthArr: [],
      quaterModel: ''
    };
  },
  created: function created() {
    if (this.defaultValue) {
      var value = this.defaultValue;
      var arr = value.split('-');
      this.year = arr[0].slice(0, 4);
      var str = arr[0].slice(4, 6) + '-' + arr[1].slice(4, 6);
      var arrAll = this.valueArr;
      this.quarterVal = "".concat(this.year, "\u5E74").concat(arrAll.indexOf(str) + 1, "\u5B63\u5EA6");
      this.quaterModel = "".concat(this.year, "\u5E74").concat(arrAll.indexOf(str) + 1, "\u5B63\u5EA6");
    }
  },
  methods: {
    one: function one() {
      this.showSeason = false;
    },
    prev: function prev() {
      this.year = this.year * 1 - 1;
    },
    next: function next() {
      this.year = this.year * 1 + 1;
    },
    selectSeason: function selectSeason(i) {
      var that = this;
      that.season = i + 1;
      // if ( that.season < 10) {
      //      that.season = '0' +  that.season;
      // }
      var arr = that.valueArr[i].split('-');
      that.getValue(that.year + arr[0] + '-' + that.year + arr[1]);
      that.showSeason = false;
      this.quaterModel = "".concat(this.year, "\u5E74").concat(this.season, "\u5B63\u5EA6");
      this.monthArr = [];
      if (this.season == '1') {
        this.quarterVal = "".concat(this.year, "-01-01 00:00:00");
        this.monthVal = "".concat(this.year, "-03-31 23:59:59");
        this.monthArr.push(this.quarterVal, this.monthVal);
      }
      if (this.season == '2') {
        this.quarterVal = "".concat(this.year, "-04-01 00:00:00");
        this.monthVal = "".concat(this.year, "-06-31 23:59:59");
        this.monthArr.push(this.quarterVal, this.monthVal);
      }
      if (this.season == '3') {
        this.quarterVal = "".concat(this.year, "-07-01 00:00:00");
        this.monthVal = "".concat(this.year, "-09-31 23:59:59");
        this.monthArr.push(this.quarterVal, this.monthVal);
      }
      if (this.season == '4') {
        this.quarterVal = "".concat(this.year, "-10-01 00:00:00");
        this.monthVal = "".concat(this.year, "-12-31 23:59:59");
        this.monthArr.push(this.quarterVal, this.monthVal);
      }
      this.$emit('monthArr', this.monthArr);
    }
  }
};