import "core-js/modules/es.array.splice.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import drivingInsuranceClassify from '@/api/drivingInsuranceClassify.js';
import { getSupplyers } from '@/utils/common';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    DialogType: {
      type: String,
      default: {}
    },
    viewData: {
      type: Object,
      default: {}
    },
    riskTypeList: {
      type: Array,
      default: []
    }
  },
  data: function data() {
    return {
      accidentList: [],
      addForm: {
        insCorpCode: '',
        accidentTypeCode: '',
        accidentType: '',
        enabled: false,
        group: false
      },
      formLoading: false,
      formRules: {
        insCorpCode: [{
          required: true,
          message: '请选择保险公司',
          trigger: 'change'
        }],
        accidentTypeCode: [{
          required: true,
          message: '请输入驾意险类型编码',
          trigger: 'blur'
        }],
        accidentType: [{
          required: true,
          message: '请输入驾意险类型名称',
          trigger: 'blur'
        }],
        risProductName: [{
          required: true,
          message: '请输入产品名称',
          trigger: 'blur'
        }]
      },
      routesData: [],
      routesList: [],
      supplyers: []
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        //保司
        this.addForm = this.viewData;
        if (this.DialogType == 'edit' || this.DialogType == 'view') {
          this.initData(); //初始化数据
        }
        // else {
        //     this.$refs.addForm.resetFields();
        // }
      }
      ;
    }
  },
  mounted: function mounted() {
    //保司
    this.supplyers = getSupplyers();
    if (this.DialogType == 'edit' || this.DialogType == 'view') {
      this.initData(); //初始化数据
    }
  },

  methods: {
    delList: function delList(index) {
      if (index != 0) {
        this.riskAccidentPackageSaveParams.splice(index, 1);
      }
    },
    delriskList: function delriskList(val, index) {
      if (this.DialogType != 'view') {
        if (this.riskAccidentPackageSaveParams[index].riskList.length != 1) {
          this.riskAccidentPackageSaveParams[index].riskList.splice(val.index, 1);
        }
      }
    },
    getAccidentType: function getAccidentType(val) {
      var _this = this;
      drivingInsuranceClassify.getAccidentTypeList({
        insCorpCode: val
      }).then(function (res) {
        if (res.code == 200 && res.data) {
          _this.accidentList = res.data;
        }
      });
    },
    initData: function initData() {
      //初始化数据
      this.addForm = this.viewData;
      // this.formLoading = true;
      // drivingInsuranceClassify.getRiskProductDetail({
      //     riskProductId: this.riskProductId
      // }).then(res => {
      //     this.formLoading = false;
      //     if (res.code == 200 && res.data) {
      //         this.addForm = res.data ? res.data : {};
      //         this.riskAccidentPackageSaveParams = res.data.riskAccidentPackageSaveParams ? res.data.riskAccidentPackageSaveParams : [];
      //         this.getAccidentType(this.addForm.insCorpCode)
      //     }
      // }).catch(err => {
      //     this.formLoading = false;
      // })
    },
    save: function save() {
      var _this2 = this;
      //保存数据
      this.$refs['addForm'].validate(function (valid) {
        if (valid) {
          if (_this2.DialogType == 'add') {
            drivingInsuranceClassify.addData(_this2.addForm).then(function (res) {
              if (res.code == 200 && res.success == true) {
                _this2.formLoading = false;
                _this2.$message.success(res.msg);
                _this2.closePage();
              }
            });
          } else {
            var data = {
              accidentTypeCode: _this2.addForm.accidentTypeCode,
              accidentType: _this2.addForm.accidentType,
              insCorpCode: _this2.addForm.insCorpCode,
              group: _this2.addForm.group,
              enabled: _this2.addForm.enabled
            };
            drivingInsuranceClassify.updateData(_this2.addForm.accidentId, data).then(function (res) {
              if (res.code == 200 && res.success == true) {
                _this2.formLoading = false;
                _this2.$message.success(res.msg);
                _this2.closePage();
              }
            });
          }
        }
      });
    },
    closePage: function closePage() {
      //关闭弹窗
      this.$refs.addForm.resetFields();
      this.riskAccidentPackageSaveParams = [{
        insuranceCode: "",
        //套餐编码
        insuranceName: "",
        //套餐名称
        minSeat: '',
        //最小座位数
        maxSeat: '',
        //最小座位数
        maxNumber: '',
        //最大购买份数
        minInsureAge: '',
        //最小投保年龄
        maxInsureAge: '',
        //最大投保年龄
        whetherCalculate: false,
        //是否加入乘客计算
        insuredAmount: '',
        //保额
        premium: '',
        //保费
        riskList: [{
          riskCode: "",
          //代码
          riskName: "",
          //险种
          riskType: "",
          //类别
          riskAmount: "" //保额
        }]
      }], this.$emit('closePage');
    }
  }
};