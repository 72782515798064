import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import provincesApi from '@/api/provinces';
import ProvincesDialog from './dialog/provincesDialog';
export default {
  name: 'ProvincesMaintenanceYf',
  mixins: [initHeight],
  components: {
    ProvincesDialog: ProvincesDialog
  },
  data: function data() {
    return {
      tableData: [],
      loading: false,
      showDialog: false,
      currentType: '',
      currentStatus: '',
      dealerIdArr: [],
      currentDistrictId: ''
    };
  },
  created: function created() {
    this.loadData();
  },
  methods: {
    //删除
    deleteArea: function deleteArea(item) {
      var _this = this;
      this.$confirm('您确定要删除该数据吗', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        type: 'warning'
      }).then(function () {
        provincesApi.deleteYfDistrict(item.districtId).then(function (res) {
          if (res.code === 200) {
            _this.$message.success(res.msg);
            _this.loadData();
          }
          ;
        });
      }).catch(function () {});
    },
    //编辑
    editArea: function editArea(status, type, item) {
      this.currentStatus = status;
      this.currentType = type;
      this.currentDistrictId = item.districtId.toString();
      this.showDialog = true;
    },
    //新增
    addArea: function addArea(status, type) {
      this.currentStatus = status;
      this.currentType = type;
      this.showDialog = true;
    },
    //获取列表数据
    loadData: function loadData() {
      var _this2 = this;
      this.loading = true;
      this.dealerIdArr = [];
      this.showDialog = false;
      provincesApi.getYfDistrictList().then(function (res) {
        if (res.code === 200) {
          _this2.loading = false;
          _this2.tableData = res.data;
        }
        ;
      }).catch(function (err) {
        _this2.loading = false;
      });
    },
    // 刷新
    breakData: function breakData() {
      this.loadData();
    },
    changeCurrentType: function changeCurrentType(currentType) {
      switch (currentType) {
        case 'PROVINCE':
          return '省';
        case 'CITY':
          return '市';
        case 'DISTRICT':
          return '区';
      }
      ;
    }
  }
};