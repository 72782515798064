var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-m-cls area-dialog", staticStyle: {} }, [
    _c(
      "div",
      {
        ref: "header",
        staticClass: "header-cls",
        staticStyle: { "max-height": "540px", overflow: "auto" },
      },
      [
        _c(
          "el-card",
          { attrs: { shadow: "never" } },
          [
            _c(
              "el-form",
              {
                ref: "detail",
                staticStyle: { "margin-bottom": "-20px" },
                attrs: {
                  model: _vm.form,
                  rules: _vm.rules,
                  "label-width": "114px",
                  size: "mini",
                },
                nativeOn: {
                  submit: function ($event) {
                    $event.preventDefault()
                  },
                },
              },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _vm.currentType == "province"
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "省级区域名称：",
                                  prop: "areaName",
                                  rules: [
                                    {
                                      required: true,
                                      message: "请输入省级区域名称",
                                      trigger: "blur",
                                    },
                                  ],
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "请输入省级区域名称",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.form.areaName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "areaName",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "form.areaName",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.currentType == "city"
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "市级区域名称：",
                                  prop: "areaName",
                                  rules: [
                                    {
                                      required: true,
                                      message: "请输入市级区域名称",
                                      trigger: "blur",
                                    },
                                  ],
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "请输入市级区域名称",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.form.areaName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "areaName",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "form.areaName",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.currentType == "city"
                  ? _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "所属省份：",
                                  prop: "provinceCode",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      placeholder: "请选择所属省份",
                                      clearable: "",
                                      filterable: "",
                                    },
                                    on: { change: _vm.provinceChange },
                                    model: {
                                      value: _vm.form.provinceCode,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "provinceCode", $$v)
                                      },
                                      expression: "form.provinceCode",
                                    },
                                  },
                                  _vm._l(
                                    _vm.provinceManageOption,
                                    function (item) {
                                      return _c("el-option", {
                                        key: item.code,
                                        attrs: {
                                          label: item.name,
                                          value: item.code,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _vm.currentType == "province"
                          ? _c("el-form-item", {
                              staticClass: "must-box",
                              attrs: { label: "选择下属省份：" },
                            })
                          : _vm._e(),
                        _vm.currentType == "city"
                          ? _c("el-form-item", {
                              staticClass: "must-box",
                              attrs: { label: "选择下属城市：" },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _vm.currentType == "province"
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "provinceList",
                                  "label-width": "0",
                                },
                              },
                              [
                                _c(
                                  "el-checkbox-group",
                                  {
                                    model: {
                                      value: _vm.form.provinceList,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "provinceList", $$v)
                                      },
                                      expression: "form.provinceList",
                                    },
                                  },
                                  _vm._l(
                                    _vm.provinceOption,
                                    function (city, index) {
                                      return _c(
                                        "el-checkbox",
                                        {
                                          key: index,
                                          attrs: {
                                            label: city.provinceCode,
                                            title: city.provinceName,
                                          },
                                        },
                                        [_vm._v(_vm._s(city.provinceName))]
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.currentType == "city"
                          ? _c(
                              "el-form-item",
                              {
                                attrs: { prop: "cityList", "label-width": "0" },
                              },
                              [
                                _c(
                                  "el-checkbox-group",
                                  {
                                    model: {
                                      value: _vm.form.cityList,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "cityList", $$v)
                                      },
                                      expression: "form.cityList",
                                    },
                                  },
                                  _vm._l(
                                    _vm.cityOption,
                                    function (city, index) {
                                      return _c(
                                        "el-checkbox",
                                        {
                                          key: index,
                                          attrs: {
                                            label: city.cityCode,
                                            title: city.cityName,
                                          },
                                        },
                                        [_vm._v(_vm._s(city.cityName))]
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticStyle: {
          display: "flex",
          "justify-content": "center",
          "margin-top": "8px",
        },
      },
      [
        _c(
          "el-button",
          {
            attrs: {
              icon: "el-icon-check",
              type: "primary",
              plain: "",
              size: "mini",
            },
            on: { click: _vm.save },
          },
          [_vm._v("保存")]
        ),
        _c(
          "el-button",
          {
            attrs: { icon: "el-icon-close", size: "mini" },
            on: { click: _vm.closePage },
          },
          [_vm._v("关闭")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }