//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    formLabel: {
      type: String,
      required: true
    }
  },
  data: function data() {
    return {};
  },
  computed: {},
  mounted: function mounted() {
    this.init();
  },
  methods: {
    init: function init() {}
  }
};