import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.number.to-fixed.js";
//
//
//
//

import * as echarts from 'echarts';
require('echarts/theme/macarons'); // echarts theme
import resize from './mixins/resize';
import { getBeforeOfDate, getDayOfMonth, isLeapYear } from '@/utils/common';
export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '300px'
    },
    xAxis: {
      type: Array,
      default: []
    },
    series: {
      type: Array,
      default: [{
        name: "",
        data: []
      }, {
        name: "",
        data: []
      }]
    },
    filterType: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      chart: null
    };
  },
  watch: {
    series: {
      deep: true,
      handler: function handler(val) {
        this.setOptions(val);
      }
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      _this.initChart();
    });
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      this.chart = echarts.init(this.$el, 'macarons');
      this.setOptions();
    },
    setOptions: function setOptions() {
      var _this2 = this;
      var self = this;
      var _xAxis = [];
      var _xAxis1 = [];
      if (self.xAxis) {
        self.xAxis.forEach(function (item) {
          var x = item.split(' ')[0];
          var y = item.split(' ')[1];
          _xAxis.push(x);
          _xAxis1.push(y);
        });
      } else {
        _xAxis = self.xAxis;
      }
      self.chart.setOption({
        tooltip: {
          trigger: 'axis',
          position: function position(p) {
            return [p[0] + 0, p[1] - 90];
          },
          confine: true,
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          },

          backgroundColor: 'rgb(0,0,0,0.5)',
          // 提示框浮层的背景颜色。
          borderColor: 'rgb(255,255,255,0.3)',
          // 提示框浮层的边框颜色。
          borderWidth: 0,
          // 提示框浮层的边框宽。
          textStyle: {
            // 提示框浮层的文本样式。
            color: '#fff'
          },
          formatter: function formatter(params) {
            var seriesName1 = params[0] && params[0].seriesName;
            var title = '';
            var _name = params[0].name;
            var curr = "";
            var last = "";
            var res = "";
            if (seriesName1 && seriesName1.indexOf('周') != -1) {
              title = _name + '<br/>';
              curr = _xAxis1[parseInt(params[0].dataIndex)];
              last = getBeforeOfDate(curr, 7).substr(5);
              res = "<p style='height:10px;'>" + title + "</p>";
            } else if (seriesName1 && seriesName1.indexOf('月') != -1) {
              res = "";
              var m = _name.split('-')[0];
              curr = m + '月' + _name.split('-')[1] + '日';
              var days = 30;
              if (parseInt(m) == 3) {
                if (isLeapYear(new Date().getFullYear())) {
                  days = 29;
                } else {
                  days = 28;
                }
              } else {
                var mm = parseInt(m) - 1;
                mm = mm == 0 ? 12 : mm;
                days = getDayOfMonth(mm);
              }
              last = getBeforeOfDate(_name, days).substr(5);
              last = last.split('-')[0] + '月' + last.split('-')[1] + '日';
            } else if (seriesName1 && seriesName1.indexOf('年') != -1) {
              res = "";
              var y = new Date().getFullYear();
              var _month = _name.split('月')[0];
              _month = _month < 10 ? '0' + _month : _month;
              curr = y.toString().substr(2) + '年' + _month + '月';
              last = getBeforeOfDate(y + '-' + _month, 365);
              last = last.split('-')[0].substr(2) + '年' + last.split('-')[1] + '月';
            } else {
              title = _name + '<br/>';
              res = "<p style='height:10px;'>" + title + "</p>";
            }
            for (var i = 0; i < params.length; i++) {
              if (params[i].data != undefined) {
                res += "<p style='height:10px;'>" + params[i].marker + params[i].seriesName + (curr && last ? '(' + (i == 0 ? curr : last) + ")" : '') + ": " + (_this2.series[0].title.indexOf('出单') !== -1 ? '' : '￥ ') + (params[i].data ? Number(params[i].data.toFixed(0)).toLocaleString() : 0) + "</p>";
              }
            }
            return res;
          }
        },
        xAxis: {
          type: 'category',
          data: _xAxis,
          boundaryGap: true,
          show: true,
          axisLabel: {
            show: true,
            textStyle: {
              color: '#ffffff'
            }
          }
        },
        yAxis: {
          type: 'value',
          minInterval: 1,
          show: true,
          splitArea: {
            show: false,
            areaStyle: {
              color: []
            }
          },
          axisLabel: {
            show: true,
            formatter: function formatter(value) {
              if (self.series[0].title == "出单") {
                if (value === 0 || value < 1) {
                  return value;
                } else {
                  return "".concat(value) + '单';
                }
              } else {
                if (value < 10000) {
                  return value;
                } else {
                  return "".concat(value / 10000) + '万';
                }
              }
            },
            textStyle: {
              color: '#ffffff'
            }
          },
          axisLine: {
            show: false // Y轴线
          },

          splitLine: {
            show: true,
            lineStyle: {
              color: ['rgb(255,255,255,0.15)'],
              type: "dashed",
              width: 1
            }
          }
        },
        grid: {
          left: 0,
          top: 20,
          right: 12,
          bottom: 40,
          containLabel: true
        },
        series: [{
          name: self.series[0].name || '',
          data: self.series[0].data || [],
          type: 'line',
          barWidth: 10,
          smooth: true,
          symbol: "none",
          itemStyle: {
            normal: {
              color: 'rgba(177,177,177,0.7)',
              lineStyle: {
                width: 2 //设置线条粗细
              }
            }
          }
        }, {
          name: self.series[1].name || '',
          data: self.series[1].data || [],
          type: 'line',
          barWidth: 10,
          smooth: true,
          symbol: "none",
          itemStyle: {
            normal: {
              color: '#699dec',
              lineStyle: {
                width: 2 //设置线条粗细
              }
            }
          }
        }]
      });
    }
  }
};