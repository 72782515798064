var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.isMultiple
        ? _c(
            "el-select",
            {
              ref: "orgSelect",
              staticStyle: { width: "100%" },
              attrs: {
                disabled: _vm.disabled,
                filterable: "",
                "filter-method": _vm.dataFilter,
                title: _vm.searchForm.salesOrgName,
                placeholder: "请选择",
                clearable: _vm.clearable,
              },
              on: { clear: _vm.handleClear, focus: _vm.orgFocus },
              model: {
                value: _vm.searchForm.salesOrgName,
                callback: function ($$v) {
                  _vm.$set(_vm.searchForm, "salesOrgName", $$v)
                },
                expression: "searchForm.salesOrgName",
              },
            },
            [
              _c(
                "el-option",
                {
                  staticStyle: { height: "auto", "background-color": "white" },
                  attrs: { value: _vm.searchForm.salesOrgCode },
                },
                [
                  _c("el-tree", {
                    ref: "tree",
                    staticClass: "filter-tree",
                    attrs: {
                      "default-expanded-keys": _vm.treeExpandData,
                      "node-key": "id",
                      "highlight-current": _vm.highlightCurrent,
                      data: _vm.orgData,
                      props: _vm.defaultProps,
                      "filter-node-method": _vm.filterNode,
                      "expand-on-click-node": false,
                    },
                    on: { "node-click": _vm.handleNodeClick },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "div",
            [
              !_vm.disabled
                ? _c(
                    "el-popover",
                    {
                      attrs: {
                        disabled: _vm.disabled,
                        placement: "bottom",
                        width: "360",
                      },
                      model: {
                        value: _vm.showPop,
                        callback: function ($$v) {
                          _vm.showPop = $$v
                        },
                        expression: "showPop",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "max-height": "175px",
                            overflow: "auto",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "输入关键字进行过滤",
                              clearable: "",
                            },
                            model: {
                              value: _vm.filterText,
                              callback: function ($$v) {
                                _vm.filterText = $$v
                              },
                              expression: "filterText",
                            },
                          }),
                          _c("el-tree", {
                            ref: "tree",
                            staticClass: "filter-tree",
                            attrs: {
                              "node-key": "id",
                              "show-checkbox": "",
                              "highlight-current": _vm.highlightCurrent,
                              "default-checked-keys": _vm.defaultCode,
                              data: _vm.orgData,
                              props: _vm.defaultProps,
                              "filter-node-method": _vm.filterNode,
                              "check-strictly": "",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { "text-align": "right", margin: "0" } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini" },
                              on: {
                                click: function ($event) {
                                  _vm.showPop = false
                                },
                              },
                            },
                            [_vm._v("取消")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                plain: "",
                                size: "mini",
                              },
                              on: { click: _vm.handleCheckChange },
                            },
                            [_vm._v("确定")]
                          ),
                        ],
                        1
                      ),
                      _c("el-input", {
                        attrs: {
                          slot: "reference",
                          placeholder: "请选择",
                          readonly: "",
                        },
                        slot: "reference",
                        model: {
                          value: _vm.showOrgName,
                          callback: function ($$v) {
                            _vm.showOrgName = $$v
                          },
                          expression: "showOrgName",
                        },
                      }),
                    ],
                    1
                  )
                : _c("el-input", {
                    attrs: { disabled: "", placeholder: "请选择" },
                    model: {
                      value: _vm.showOrgName,
                      callback: function ($$v) {
                        _vm.showOrgName = $$v
                      },
                      expression: "showOrgName",
                    },
                  }),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }