var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "app-m-cls", staticStyle: { padding: "13px 15px" } },
      [
        _c(
          "div",
          {
            staticStyle: {
              height: "85vh",
              "background-color": "white",
              padding: "10px 15px",
            },
          },
          [
            _c(
              "el-tabs",
              {
                on: { "tab-click": _vm.handleTabClick },
                model: {
                  value: _vm.activeName,
                  callback: function ($$v) {
                    _vm.activeName = $$v
                  },
                  expression: "activeName",
                },
              },
              [
                _c("el-tab-pane", {
                  attrs: { label: "车牌号", name: "PLATENO" },
                }),
                _c("el-tab-pane", { attrs: { label: "车架号", name: "VIN" } }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "justify-content": "center",
                  "margin-top": "50px",
                },
              },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.formData,
                      "label-width": "110px",
                      rules: _vm.formRules,
                    },
                  },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "投保地区：", prop: "insCityCode" },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  placeholder: "投保地区",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.formData.insCityCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "insCityCode", $$v)
                                  },
                                  expression: "formData.insCityCode",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "广州", value: "440100" },
                                }),
                                _c("el-option", {
                                  attrs: { label: "深圳", value: "440300" },
                                }),
                                _c("el-option", {
                                  attrs: { label: "东莞", value: "441900" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.activeName == "PLATENO",
                                expression: "activeName == 'PLATENO'",
                              },
                            ],
                            attrs: { label: "车牌号：", prop: "plateNo" },
                          },
                          [
                            _c("el-input", {
                              ref: "plateNo",
                              attrs: {
                                maxlength: "40",
                                placeholder: "车牌号",
                                clearable: "",
                              },
                              model: {
                                value: _vm.formData.plateNo,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formData,
                                    "plateNo",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "formData.plateNo",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm.activeName == "PLATENO"
                          ? _c(
                              "el-form-item",
                              { attrs: { label: "车主证件号：" } },
                              [
                                _c("el-input", {
                                  ref: "idCardNo",
                                  attrs: {
                                    maxlength: "40",
                                    placeholder: "车主证件号",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.formData.idCardNo,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "idCardNo",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "formData.idCardNo",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "el-form-item",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.activeName != "PLATENO",
                                expression: "activeName != 'PLATENO'",
                              },
                            ],
                            attrs: { label: "车架号：", prop: "vin" },
                          },
                          [
                            _c("el-input", {
                              ref: "vin",
                              attrs: {
                                maxlength: "17",
                                placeholder: "车架号",
                                clearable: "",
                              },
                              on: { input: _vm.inputVin },
                              model: {
                                value: _vm.formData.vin,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formData,
                                    "vin",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "formData.vin",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.activeName != "PLATENO",
                                expression: "activeName != 'PLATENO'",
                              },
                            ],
                            attrs: { label: "发动机号：" },
                          },
                          [
                            _c("el-input", {
                              ref: "engineNo",
                              attrs: {
                                maxlength: "40",
                                placeholder: "发动机号",
                                clearable: "",
                              },
                              on: { input: _vm.inputEngineNo },
                              model: {
                                value: _vm.formData.engineNo,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formData,
                                    "engineNo",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "formData.engineNo",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("el-row", [
                      _c(
                        "div",
                        { staticStyle: { "margin-top": "30px" } },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: { width: "320px" },
                              attrs: { type: "primary" },
                              on: { click: _vm.quote },
                            },
                            [_vm._v("立即报价")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            color: "#F64A3F",
                            "font-size": "12px",
                            "text-align": "center",
                            "margin-top": "5px",
                          },
                        },
                        [_vm._v("仅支持9座以下非营运客车报价出单")]
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }