import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import request from '@/api/finance';
import initHeight from '@/mixins/initHeight';
export default {
  mixins: [initHeight],
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    currentPayCode: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      tableData: [],
      loading: false
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
      ;
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    initData: function initData() {
      //初始化数据
      this.getTableData();
    },
    getTableData: function getTableData() {
      var _this = this;
      this.loading = true;
      request.getByPayCode(this.currentPayCode).then(function (res) {
        _this.loading = false;
        if (res.code === 200) {
          _this.tableData = res.data;
        }
        ;
      }).catch(function (err) {
        _this.loading = false;
      });
      ;
    },
    closePage: function closePage() {
      //关闭弹窗
      this.$emit('closePage');
    },
    // 费用结算明细
    viewDetail: function viewDetail(item) {
      this.$router.push({
        path: "/costSettlementDetail",
        query: {
          settlementId: item.settlementId
        }
      });
      this.closePage();
    }
  }
};