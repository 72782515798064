import _defineProperty from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/defineProperty.js";
var _objApi;
/**
 * 档案管理接口
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = (_objApi = {
  /**
   * 保险档案管理列表
   * @param {*} data 内容
   */
  getList: function getList(data) {
    return request.get(getUrl("/coreCustomer/oem/insurance/record/search", 1), {
      params: data
    });
  }
}, _defineProperty(_objApi, "getList", function getList(data) {
  return request.get(getUrl("/coreCustomer/oem/insurance/record/search", 1), {
    params: data
  });
}), _defineProperty(_objApi, "getVehicleModel", function getVehicleModel() {
  return request.get(getUrl("/vehicle-models/list", 11));
}), _defineProperty(_objApi, "getCustomDetail", function getCustomDetail(customerId) {
  return request.get(getUrl("/coreCustomer/oem/insurance/record/detail/".concat(customerId), 1));
}), _defineProperty(_objApi, "getInsuranceTypeList", function getInsuranceTypeList(data) {
  return request.get(getUrl("/coreCustomer/oem/insurance/record/risk/list", 1), {
    params: data
  });
}), _objApi);
export default objApi;