var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showTitle
        ? _c(
            "div",
            {
              staticClass: "insurance-list-header",
              staticStyle: { "background-color": "#e0efff" },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    width: "38px",
                    height: "14px",
                    padding: "11px",
                    display: "flex",
                    "justify-content": "center",
                  },
                },
                [
                  _c(
                    "el-tooltip",
                    {
                      staticStyle: { margin: "4px" },
                      attrs: {
                        effect: "dark",
                        content: "" + (_vm.checkAll ? "取消全选" : "全选"),
                        placement: "top-start",
                      },
                    },
                    [
                      _c("el-checkbox", {
                        attrs: {
                          indeterminate: _vm.isIndeterminate,
                          disabled: _vm.isDisabled,
                        },
                        on: { change: _vm.handleCheckAllChange },
                        model: {
                          value: _vm.checkAll,
                          callback: function ($$v) {
                            _vm.checkAll = $$v
                          },
                          expression: "checkAll",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    flex: "1",
                    padding: "5px 10px",
                    color: "#515151",
                    "font-weight": "bold",
                    "font-size": "13px",
                  },
                },
                [
                  _vm._v(" " + _vm._s(_vm.label) + "简称 "),
                  _c("span", { staticClass: "duty-title" }, [
                    _vm._v(" " + _vm._s(_vm.label ? _vm.label[0] : "") + " "),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    flex: "2",
                    width: "100%",
                    color: "#515151",
                    "font-weight": "bold",
                    "font-size": "13px",
                  },
                },
                [_vm._v("保额/限额")]
              ),
            ]
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "table-cls normal-btn-cls" },
        [
          !_vm._.isEmpty(_vm.formData)
            ? _c(
                "el-checkbox-group",
                {
                  attrs: { disabled: _vm.isDisabled },
                  on: { change: _vm.handleItemChanged },
                  nativeOn: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return (function () {})($event)
                    },
                  },
                  model: {
                    value: _vm.checkedList,
                    callback: function ($$v) {
                      _vm.checkedList = $$v
                    },
                    expression: "checkedList",
                  },
                },
                _vm._l(_vm.formData, function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "insurance-list" },
                    [
                      _c(
                        "el-checkbox",
                        { attrs: { label: item, name: item.riskkindCode } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                                width: "100%",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "insurance-label",
                                  staticStyle: {
                                    display: "flex",
                                    "justify-content": "space-between",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        cursor: "pointer",
                                        "line-height": "40px",
                                        flex: "1",
                                      },
                                    },
                                    [_vm._v(_vm._s(item.riskkindShortName))]
                                  ),
                                  _c("div", {
                                    staticStyle: {
                                      color: "white",
                                      flex: "1",
                                      "line-height": "40px",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                      },
                                    },
                                  }),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    padding: "7px",
                                    "padding-left": "10px",
                                    flex: "2",
                                    color: "#606266",
                                    "min-height": "40px",
                                    "border-left": "1px #eee solid",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                    },
                                  },
                                },
                                [
                                  item.hasInsuranceAmount
                                    ? _c(
                                        "div",
                                        { staticClass: "widthDisplayFlex" },
                                        [
                                          !item.unitAmountOptions ||
                                          item.unitAmountOptions.length == 0
                                            ? _c(
                                                "div",
                                                { staticClass: "displayFlex" },
                                                [
                                                  item.amountUnit == "元" &&
                                                  item.riskkindCode != "A02" &&
                                                  item.riskkindCode != "A04" &&
                                                  item.riskkindCode != "A09" &&
                                                  item.riskkindCode != "B07"
                                                    ? _c("el-input", {
                                                        staticClass:
                                                          "priceInput",
                                                        class: {
                                                          error_color:
                                                            (item.check &&
                                                              !item.insuredAmount) ||
                                                            item.insuredAmount ==
                                                              0,
                                                        },
                                                        attrs: {
                                                          size: "mini",
                                                          type: "text",
                                                          maxlength: "8",
                                                          disabled:
                                                            _vm.isDisabled ||
                                                            !item.check,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            item.insuredAmount =
                                                              _vm.onInput(
                                                                item.insuredAmount,
                                                                0,
                                                                99999999,
                                                                0
                                                              )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            item.insuredAmount,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "insuredAmount",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.insuredAmount",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  item.amountUnit == "%"
                                                    ? _c("el-input", {
                                                        class: {
                                                          error_color:
                                                            (item.check &&
                                                              !item.permiumRate) ||
                                                            item.permiumRate ==
                                                              0,
                                                        },
                                                        attrs: {
                                                          type: "text",
                                                          maxlength: "8",
                                                          size: "mini",
                                                          disabled:
                                                            _vm.isDisabled ||
                                                            !item.check,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            item.permiumRate =
                                                              _vm.onInput(
                                                                item.permiumRate,
                                                                0,
                                                                99999999,
                                                                0
                                                              )
                                                          },
                                                        },
                                                        nativeOn: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                            return _vm.inputValue(
                                                              $event
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value:
                                                            item.permiumRate,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "permiumRate",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.permiumRate",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  item.riskkindCode == "A04"
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "displayFlex",
                                                          staticStyle: {
                                                            "align-items":
                                                              "center",
                                                          },
                                                        },
                                                        [
                                                          _c("el-input", {
                                                            class: {
                                                              error_color:
                                                                (item.check &&
                                                                  !item.unitAmount) ||
                                                                item.unitAmount ==
                                                                  0,
                                                            },
                                                            attrs: {
                                                              maxlength: "8",
                                                              type: "text",
                                                              size: "mini",
                                                              disabled:
                                                                _vm.isDisabled ||
                                                                !item.check,
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                item.unitAmount =
                                                                  _vm.onInput(
                                                                    item.unitAmount,
                                                                    0,
                                                                    99999999,
                                                                    0
                                                                  )
                                                              },
                                                            },
                                                            nativeOn: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                return _vm.inputValue(
                                                                  $event
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                item.unitAmount,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "unitAmount",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item.unitAmount",
                                                            },
                                                          }),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                margin: "0 3px",
                                                              },
                                                            },
                                                            [_vm._v("元")]
                                                          ),
                                                          _c("svg-icon", {
                                                            staticStyle: {
                                                              color: "#555",
                                                              "font-size":
                                                                "22px",
                                                            },
                                                            attrs: {
                                                              "icon-class":
                                                                "multiplication",
                                                            },
                                                          }),
                                                          _c("el-input", {
                                                            class: {
                                                              error_color:
                                                                (item.check &&
                                                                  !item.quantity) ||
                                                                item.quantity ==
                                                                  0,
                                                            },
                                                            staticStyle: {
                                                              width: "150px",
                                                            },
                                                            attrs: {
                                                              size: "mini",
                                                              type: "text",
                                                              maxlength: "3",
                                                              disabled:
                                                                _vm.isDisabled ||
                                                                !item.check,
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                item.quantity =
                                                                  _vm.onInput(
                                                                    item.quantity,
                                                                    0,
                                                                    365,
                                                                    0
                                                                  )
                                                              },
                                                            },
                                                            nativeOn: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                return _vm.inputValue(
                                                                  $event
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                item.quantity,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "quantity",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item.quantity",
                                                            },
                                                          }),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                "margin-left":
                                                                  "10px",
                                                              },
                                                            },
                                                            [_vm._v("天")]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  item.riskkindCode != "A02" &&
                                                  item.riskkindCode != "D02" &&
                                                  item.riskkindCode != "D03" &&
                                                  item.riskkindCode != "A04" &&
                                                  item.riskkindCode != "A09" &&
                                                  item.riskkindCode != "B07"
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            "line-height":
                                                              "28px",
                                                            "margin-left":
                                                              "10px",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.amountUnit
                                                            )
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  item.riskkindCode == "A02" &&
                                                  _vm.isNewAddItem
                                                    ? _c(
                                                        "div",
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              style: {
                                                                color:
                                                                  (!item.insuredAmount ||
                                                                    item.insuredAmount ==
                                                                      0) &&
                                                                  item.check
                                                                    ? "#F56C6C"
                                                                    : "#515151",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.insuredAmount
                                                                    ? item.insuredAmount
                                                                    : 0
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c("span", [
                                                            _vm._v("元"),
                                                          ]),
                                                          _c(
                                                            "el-button",
                                                            {
                                                              staticStyle: {
                                                                "margin-left":
                                                                  "10px",
                                                              },
                                                              attrs: {
                                                                type: "primary",
                                                                icon: "el-icon-plus",
                                                                disabled:
                                                                  _vm.isXZSBSS ||
                                                                  !item.check,
                                                                size: "mini",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.addXZSBItem(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("添加设备")]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  item.riskkindCode == "D02" ||
                                                  item.riskkindCode == "D03"
                                                    ? _c(
                                                        "div",
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                margin: "0 3px",
                                                              },
                                                            },
                                                            [_vm._v("元")]
                                                          ),
                                                          _c("svg-icon", {
                                                            staticStyle: {
                                                              color: "#555",
                                                              "font-size":
                                                                "12px",
                                                            },
                                                            attrs: {
                                                              "icon-class":
                                                                "multiplication",
                                                            },
                                                          }),
                                                          _c("el-input", {
                                                            staticStyle: {
                                                              width: "80px",
                                                            },
                                                            attrs: {
                                                              type: "text",
                                                              maxlength: "3",
                                                              size: "mini",
                                                              disabled: true,
                                                            },
                                                            model: {
                                                              value: _vm.seats,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.seats =
                                                                    $$v
                                                                },
                                                              expression:
                                                                "seats",
                                                            },
                                                          }),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                "margin-left":
                                                                  "10px",
                                                              },
                                                            },
                                                            [_vm._v("座")]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  (item.riskkindCode == "D03" ||
                                                    item.riskkindCode ==
                                                      "B04" ||
                                                    item.riskkindCode ==
                                                      "C03") &&
                                                  _vm.canViewShareAmount
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "checkboxDisplayFlex",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return (function () {})(
                                                                $event
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-radio-group",
                                                            {
                                                              staticClass:
                                                                "radioFlex",
                                                              attrs: {
                                                                disabled:
                                                                  _vm.isDisabled ||
                                                                  !item.check,
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.changeRadios(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  item.shareAmount,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "shareAmount",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "item.shareAmount",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "el-radio",
                                                                {
                                                                  attrs: {
                                                                    label: true,
                                                                  },
                                                                },
                                                                [_vm._v("是")]
                                                              ),
                                                              _c(
                                                                "el-radio",
                                                                {
                                                                  attrs: {
                                                                    label: false,
                                                                  },
                                                                },
                                                                [_vm._v("否")]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "cursorPointer",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return (function () {})(
                                                                      $event
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "是否共享保额"
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  (item.riskkindCode == "A09" ||
                                                    item.riskkindCode ==
                                                      "B07") &&
                                                  _vm.isNewAddItem
                                                    ? _c(
                                                        "div",
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getCDZAmount(
                                                                  item
                                                                ) || 0
                                                              ) +
                                                              "元 ： "
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              style: {
                                                                color:
                                                                  !item.quoteRiskkindChargers &&
                                                                  item.check
                                                                    ? "#F56C6C"
                                                                    : "#515151",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.quoteRiskkindChargers
                                                                    ? item
                                                                        .quoteRiskkindChargers
                                                                        .length
                                                                    : 0
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                "margin-left":
                                                                  "3px",
                                                              },
                                                            },
                                                            [_vm._v("台")]
                                                          ),
                                                          _c(
                                                            "el-button",
                                                            {
                                                              staticStyle: {
                                                                "margin-left":
                                                                  "10px",
                                                              },
                                                              attrs: {
                                                                type: "primary",
                                                                icon: "el-icon-plus",
                                                                disabled:
                                                                  _vm.isXZCDZ ||
                                                                  !item.check,
                                                                size: "mini",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.openCDZItem(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "添加充电桩"
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          (item.riskkindCode == "B04" ||
                                            item.riskkindCode == "D03" ||
                                            item.riskkindCode == "C03") &&
                                          _vm.canViewShareAmount &&
                                          item.shareAmount
                                            ? _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    padding: "5px 0px",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.selectedCompany,
                                                  function (com, index) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: index,
                                                        staticStyle: {
                                                          margin: "2px 0px",
                                                          display: "flex",
                                                          "align-items":
                                                            "center",
                                                        },
                                                      },
                                                      [
                                                        (_vm._.toLower(
                                                          com.insCorpCode
                                                        ) == "picc" &&
                                                          (["310100"].includes(
                                                            com.insCityCode
                                                          ) ||
                                                            com.insCityCode.indexOf(
                                                              "34"
                                                            ) == 0 ||
                                                            com.insCityCode.indexOf(
                                                              "35"
                                                            ) == 0 ||
                                                            com.insCityCode.indexOf(
                                                              "43"
                                                            ) == 0 ||
                                                            com.insCityCode.indexOf(
                                                              "44"
                                                            ) == 0 ||
                                                            com.insCityCode.indexOf(
                                                              "36"
                                                            ) == 0 ||
                                                            com.insCityCode.indexOf(
                                                              "46"
                                                            ) == 0 ||
                                                            com.insCityCode.indexOf(
                                                              "51"
                                                            ) == 0 ||
                                                            com.insCityCode.indexOf(
                                                              "64"
                                                            ) == 0)) ||
                                                        _vm._.toLower(
                                                          com.insCorpCode
                                                        ) == "paic"
                                                          ? _c("div", [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    "min-width":
                                                                      "80px",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      com.insCorpName ||
                                                                        ""
                                                                    ) +
                                                                      _vm._s(
                                                                        _vm._.toLower(
                                                                          com.insCorpCode
                                                                        ) ==
                                                                          "picc"
                                                                          ? "(" +
                                                                              com.insCityName +
                                                                              ")"
                                                                          : ""
                                                                      ) +
                                                                      "："
                                                                  ),
                                                                ]
                                                              ),
                                                              _c("span", [
                                                                _vm._v(
                                                                  "共享保额"
                                                                ),
                                                              ]),
                                                            ])
                                                          : _vm._e(),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _vm._e(),
                                          item.unitAmountOptions &&
                                          item.unitAmountOptions.length > 0
                                            ? _c("div", [
                                                item.riskkindCode != "D"
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "displayFlex",
                                                      },
                                                      [
                                                        _c("div", [
                                                          item.riskkindCode ==
                                                          "A"
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "displayFlex",
                                                                  staticStyle: {
                                                                    "align-items":
                                                                      "center",
                                                                    "min-width":
                                                                      "150px",
                                                                    "margin-right":
                                                                      "5px",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "el-input",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          "min-width":
                                                                            "180px",
                                                                        },
                                                                      attrs: {
                                                                        size: "mini",
                                                                        placeholder:
                                                                          "选填",
                                                                        maxlength:
                                                                          "10",
                                                                        type: "text",
                                                                        disabled:
                                                                          !item.check,
                                                                      },
                                                                      on: {
                                                                        input:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            item.csxAmount =
                                                                              _vm.onInput(
                                                                                item.csxAmount,
                                                                                0,
                                                                                parseFloat(
                                                                                  _vm.csxDefaultCoverage
                                                                                ) *
                                                                                  1.3,
                                                                                2
                                                                              )
                                                                          },
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          item.csxAmount,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              item,
                                                                              "csxAmount",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "item.csxAmount",
                                                                      },
                                                                    }
                                                                  ),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          margin:
                                                                            "0px 10px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "元"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  item.riskkindCode ==
                                                                  "A"
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              "line-height":
                                                                                "28px",
                                                                              "margin-right":
                                                                                "15px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "绝对免赔额"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  item.amountUnit ==
                                                                    "元" &&
                                                                  item.riskkindCode ==
                                                                    "A"
                                                                    ? _c(
                                                                        "el-select",
                                                                        {
                                                                          ref:
                                                                            "searchSelect_" +
                                                                            item.riskkindCode,
                                                                          refInFor: true,
                                                                          staticClass:
                                                                            "minWidthAuto",
                                                                          class:
                                                                            {
                                                                              error_color:
                                                                                (item.check &&
                                                                                  !item.insuredAmountText) ||
                                                                                (item.insuredAmountText ==
                                                                                  0 &&
                                                                                  item.riskkindCode !=
                                                                                    "A"),
                                                                            },
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "100%",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              "allow-create":
                                                                                item.riskkindCode !=
                                                                                  "A03" &&
                                                                                item.riskkindCode !=
                                                                                  "A" &&
                                                                                item.riskkindCode !=
                                                                                  "B",
                                                                              "default-first-option":
                                                                                "",
                                                                              filterable:
                                                                                item.riskkindCode !=
                                                                                "A03",
                                                                              size: "mini",
                                                                              disabled:
                                                                                _vm.isDisabled ||
                                                                                !item.check,
                                                                              placeholder:
                                                                                "请选择保额",
                                                                            },
                                                                          on: {
                                                                            change:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.changeValue(
                                                                                  item
                                                                                )
                                                                              },
                                                                          },
                                                                          nativeOn:
                                                                            {
                                                                              input:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return (function (
                                                                                    val
                                                                                  ) {
                                                                                    _vm.filterData(
                                                                                      val,
                                                                                      item.riskkindCode
                                                                                    )
                                                                                  })(
                                                                                    $event
                                                                                  )
                                                                                },
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  $event.preventDefault()
                                                                                  return _vm.preventSuffix(
                                                                                    $event
                                                                                  )
                                                                                },
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                item.insuredAmountText,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    item,
                                                                                    "insuredAmountText",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "item.insuredAmountText",
                                                                            },
                                                                        },
                                                                        _vm._l(
                                                                          item.options,
                                                                          function (
                                                                            _item,
                                                                            indexs
                                                                          ) {
                                                                            return _c(
                                                                              "el-option",
                                                                              {
                                                                                key: indexs,
                                                                                attrs:
                                                                                  {
                                                                                    label:
                                                                                      _item.text,
                                                                                    value:
                                                                                      _item.value,
                                                                                  },
                                                                              }
                                                                            )
                                                                          }
                                                                        ),
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                          item.riskkindCode ==
                                                            "A" &&
                                                          parseFloat(
                                                            _vm.csxDefaultCoverage
                                                          ) > 0
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticStyle: {
                                                                    "line-height":
                                                                      "28px",
                                                                    "margin-right":
                                                                      "15px",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " (车身价调整范围："
                                                                  ),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          "font-weight":
                                                                            "700",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          parseFloat(
                                                                            _vm.csxDefaultCoverage
                                                                          ) *
                                                                            0.7
                                                                            ? _vm.getCoverage(
                                                                                "min",
                                                                                _vm.csxDefaultCoverage
                                                                              )
                                                                            : _vm.getCoverage(
                                                                                "min",
                                                                                _vm.purchasePrice
                                                                              )
                                                                        ) +
                                                                          " ~ " +
                                                                          _vm._s(
                                                                            parseFloat(
                                                                              _vm.csxDefaultCoverage
                                                                            ) *
                                                                              1.3
                                                                              ? _vm.getCoverage(
                                                                                  "max",
                                                                                  _vm.csxDefaultCoverage
                                                                                )
                                                                              : _vm.getCoverage(
                                                                                  "max",
                                                                                  _vm.purchasePrice
                                                                                )
                                                                          )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" ("),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          color:
                                                                            "#333",
                                                                          "font-size":
                                                                            "12px",
                                                                          "font-weight":
                                                                            "700",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "不填写则以保司为准"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(")) "),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]),
                                                        item.amountUnit ==
                                                          "元" &&
                                                        item.riskkindCode != "A"
                                                          ? _c(
                                                              "el-select",
                                                              {
                                                                ref:
                                                                  "searchSelect_" +
                                                                  item.riskkindCode,
                                                                refInFor: true,
                                                                staticClass:
                                                                  "minWidthAuto",
                                                                class: {
                                                                  error_color:
                                                                    (item.check &&
                                                                      !item.insuredAmountText) ||
                                                                    (item.insuredAmountText ==
                                                                      0 &&
                                                                      item.riskkindCode !=
                                                                        "A"),
                                                                },
                                                                staticStyle: {
                                                                  width: "100%",
                                                                },
                                                                attrs: {
                                                                  "allow-create":
                                                                    item.riskkindCode !=
                                                                      "A03" &&
                                                                    item.riskkindCode !=
                                                                      "A" &&
                                                                    item.riskkindCode !=
                                                                      "B",
                                                                  "default-first-option":
                                                                    "",
                                                                  filterable:
                                                                    item.riskkindCode !=
                                                                    "A03",
                                                                  size: "mini",
                                                                  disabled:
                                                                    _vm.isDisabled ||
                                                                    !item.check,
                                                                  placeholder:
                                                                    "请选择保额",
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.changeValue(
                                                                        item
                                                                      )
                                                                    },
                                                                },
                                                                nativeOn: {
                                                                  input:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return (function (
                                                                        val
                                                                      ) {
                                                                        _vm.filterData(
                                                                          val,
                                                                          item.riskkindCode
                                                                        )
                                                                      })($event)
                                                                    },
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      return _vm.preventSuffix(
                                                                        $event
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    item.insuredAmountText,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        item,
                                                                        "insuredAmountText",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "item.insuredAmountText",
                                                                },
                                                              },
                                                              _vm._l(
                                                                item.options,
                                                                function (
                                                                  _item,
                                                                  indexs
                                                                ) {
                                                                  return _c(
                                                                    "el-option",
                                                                    {
                                                                      key: indexs,
                                                                      attrs: {
                                                                        label:
                                                                          _item.text,
                                                                        value:
                                                                          _item.value,
                                                                      },
                                                                    }
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        item.amountUnit == "%"
                                                          ? _c(
                                                              "el-select",
                                                              {
                                                                ref:
                                                                  "searchSelect_" +
                                                                  item.riskkindCode,
                                                                refInFor: true,
                                                                class: {
                                                                  error_color:
                                                                    (item.check &&
                                                                      !item.permiumRate) ||
                                                                    item.permiumRate ==
                                                                      0,
                                                                },
                                                                staticStyle: {
                                                                  width: "100%",
                                                                },
                                                                attrs: {
                                                                  "allow-create":
                                                                    "",
                                                                  "default-first-option":
                                                                    "",
                                                                  filterable:
                                                                    "",
                                                                  size: "mini",
                                                                  disabled:
                                                                    _vm.isDisabled ||
                                                                    !item.check,
                                                                  placeholder:
                                                                    "请选择费率",
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.changeValue(
                                                                        item
                                                                      )
                                                                    },
                                                                },
                                                                nativeOn: {
                                                                  input:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return (function (
                                                                        val
                                                                      ) {
                                                                        _vm.filterData(
                                                                          val,
                                                                          item.riskkindCode,
                                                                          "rate"
                                                                        )
                                                                      })($event)
                                                                    },
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      return _vm.preventSuffix(
                                                                        $event
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    item.permiumRate,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        item,
                                                                        "permiumRate",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "item.permiumRate",
                                                                },
                                                              },
                                                              _vm._l(
                                                                item.options,
                                                                function (
                                                                  _item,
                                                                  indexs
                                                                ) {
                                                                  return _c(
                                                                    "el-option",
                                                                    {
                                                                      key: indexs,
                                                                      attrs: {
                                                                        label:
                                                                          _item.text,
                                                                        value:
                                                                          _item.value,
                                                                      },
                                                                    }
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              "line-height":
                                                                "28px",
                                                              "margin-left":
                                                                "10px",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.amountUnit
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                item.riskkindCode == "D"
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "displayFlex",
                                                        staticStyle: {
                                                          "align-items":
                                                            "center",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "el-select",
                                                          {
                                                            ref:
                                                              "searchSelect_" +
                                                              item.riskkindCode,
                                                            refInFor: true,
                                                            class: {
                                                              error_color:
                                                                (item.check &&
                                                                  !item.insuredAmountText) ||
                                                                item.insuredAmountText ==
                                                                  0,
                                                            },
                                                            staticStyle: {
                                                              width: "100%",
                                                            },
                                                            attrs: {
                                                              "default-first-option":
                                                                "",
                                                              "allow-create":
                                                                "",
                                                              filterable: "",
                                                              size: "mini",
                                                              disabled:
                                                                _vm.isDisabled ||
                                                                !item.check,
                                                              placeholder:
                                                                "请选择保额",
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                return _vm.changeValue(
                                                                  item
                                                                )
                                                              },
                                                            },
                                                            nativeOn: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                return (function (
                                                                  val
                                                                ) {
                                                                  _vm.filterData(
                                                                    val,
                                                                    item.riskkindCode
                                                                  )
                                                                })($event)
                                                              },
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                return _vm.preventSuffix(
                                                                  $event
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                item.insuredAmountText,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "insuredAmountText",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item.insuredAmountText",
                                                            },
                                                          },
                                                          _vm._l(
                                                            item.options,
                                                            function (
                                                              _item,
                                                              indexs
                                                            ) {
                                                              return _c(
                                                                "el-option",
                                                                {
                                                                  key: indexs,
                                                                  attrs: {
                                                                    label:
                                                                      _item.text,
                                                                    value:
                                                                      _item.value,
                                                                  },
                                                                }
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        ),
                                                        _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  margin:
                                                                    "0 3px",
                                                                },
                                                              },
                                                              [_vm._v("元")]
                                                            ),
                                                            _c("svg-icon", {
                                                              staticStyle: {
                                                                color: "#555",
                                                                "font-size":
                                                                  "12px",
                                                              },
                                                              attrs: {
                                                                "icon-class":
                                                                  "multiplication",
                                                              },
                                                            }),
                                                            _c("el-input", {
                                                              staticStyle: {
                                                                width: "80px",
                                                              },
                                                              attrs: {
                                                                type: "text",
                                                                maxlength: "3",
                                                                size: "mini",
                                                                disabled: true,
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.seats,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.seats =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "seats",
                                                              },
                                                            }),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  "margin-left":
                                                                    "10px",
                                                                },
                                                              },
                                                              [_vm._v("座")]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ])
                                            : _vm._e(),
                                        ]
                                      )
                                    : _vm._e(),
                                  item.riskkindCode == "A08" ||
                                  item.riskkindCode == "B02"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "displayFlex",
                                          staticStyle: {
                                            "align-items": "center",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              size: "mini",
                                              type: "text",
                                              disabled: "",
                                            },
                                            model: {
                                              value: item.insuredAmountText,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item,
                                                  "insuredAmountText",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "item.insuredAmountText",
                                            },
                                          }),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "margin-left": "10px",
                                              },
                                            },
                                            [_vm._v("元")]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                }),
                0
              )
            : _c("div", { staticClass: "no-data" }, [_vm._v(" 暂无数据 ")]),
        ],
        1
      ),
      _c(
        "Dialog",
        {
          attrs: {
            title: "添加新增设备",
            width: "900px",
            "form-loading": _vm.formLoading,
          },
          on: { handleClose: _vm.cancel },
          model: {
            value: _vm.showDeviceDialog,
            callback: function ($$v) {
              _vm.showDeviceDialog = $$v
            },
            expression: "showDeviceDialog",
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { slot: "content", model: _vm.form, "label-width": "0" },
              slot: "content",
            },
            [
              _c(
                "table",
                { staticStyle: { width: "100%" }, attrs: { border: "1" } },
                [
                  _c("colgroup", [
                    _c("col", { staticStyle: { width: "20%" } }),
                    _c("col", { staticStyle: { width: "18%" } }),
                    _c("col", { staticStyle: { width: "15%" } }),
                    _c("col", { staticStyle: { width: "19%" } }),
                    _c("col", { staticStyle: { width: "18%" } }),
                    _c("col", { staticStyle: { width: "10%" } }),
                    _c("col"),
                  ]),
                  _c(
                    "thead",
                    { staticStyle: { "background-color": "#eeee" } },
                    [
                      _c("tr", { staticStyle: { "font-size": "13px" } }, [
                        _c("th", { attrs: { scope: "col" } }, [
                          _vm._v("设备名称"),
                        ]),
                        _c("th", { attrs: { scope: "col" } }, [_vm._v("产地")]),
                        _c("th", { attrs: { scope: "col" } }, [_vm._v("数量")]),
                        _c("th", { attrs: { scope: "col" } }, [
                          _vm._v("单价(元)"),
                        ]),
                        _c("th", { attrs: { scope: "col" } }, [
                          _vm._v("购买日期"),
                        ]),
                        _c("th", { attrs: { scope: "col" } }),
                      ]),
                    ]
                  ),
                  _c(
                    "tbody",
                    _vm._l(_vm.form.xzsbItems, function (item, index) {
                      return _c("tr", { key: index }, [
                        _c(
                          "td",
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "xzsbItems." + index + ".deviceName",
                                  rules: {
                                    required: true,
                                    message: "请输入设备名称",
                                    trigger: "[blur,change]",
                                  },
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "30",
                                    size: "mini",
                                    clearable: "",
                                  },
                                  model: {
                                    value: item.deviceName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        item,
                                        "deviceName",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "item.deviceName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "xzsbItems." + index + ".madeChina",
                                  rules: {
                                    required: true,
                                    message: "请选择产地",
                                    trigger: "blur",
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      size: "mini",
                                      placeholder: "请选择产地",
                                      clearable: "",
                                    },
                                    model: {
                                      value: item.madeChina,
                                      callback: function ($$v) {
                                        _vm.$set(item, "madeChina", $$v)
                                      },
                                      expression: "item.madeChina",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: { label: "国产", value: true },
                                    }),
                                    _c("el-option", {
                                      attrs: { label: "进口", value: false },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "xzsbItems." + index + ".quantity",
                                  rules: [
                                    {
                                      required: true,
                                      message: "请输入数量",
                                      trigger: "blur",
                                    },
                                    {
                                      pattern:
                                        /^([1-9]\d*(\.\d*[1-9][0-9])?)|(0\.\d*[1-9][0-9])|(0\.\d*[1-9])$/,
                                      message: "请输入正确的数量",
                                      trigger: "change",
                                    },
                                  ],
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    size: "mini",
                                    type: "text",
                                    clearable: "",
                                  },
                                  on: {
                                    input: function ($event) {
                                      item.quantity = _vm.onInput(
                                        item.quantity,
                                        0,
                                        999999,
                                        0
                                      )
                                    },
                                  },
                                  model: {
                                    value: item.quantity,
                                    callback: function ($$v) {
                                      _vm.$set(item, "quantity", $$v)
                                    },
                                    expression: "item.quantity",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "xzsbItems." + index + ".unitPrice",
                                  rules: [
                                    {
                                      required: true,
                                      message: "请输入价格",
                                      trigger: "blur",
                                    },
                                    {
                                      pattern:
                                        /^([1-9]\d*(\.\d*[1-9][0-9])?)|(0\.\d*[1-9][0-9])|(0\.\d*[1-9])$/,
                                      message: "请输入正确的价格",
                                      trigger: "change",
                                    },
                                  ],
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    size: "mini",
                                    type: "text",
                                    clearable: "",
                                  },
                                  on: {
                                    input: function ($event) {
                                      item.unitPrice = _vm.onInput(
                                        item.unitPrice,
                                        0,
                                        99999999,
                                        2
                                      )
                                    },
                                  },
                                  model: {
                                    value: item.unitPrice,
                                    callback: function ($$v) {
                                      _vm.$set(item, "unitPrice", $$v)
                                    },
                                    expression: "item.unitPrice",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "xzsbItems." + index + ".buyDate",
                                  rules: {
                                    required: true,
                                    message: "请选择日期",
                                    trigger: "change",
                                  },
                                },
                              },
                              [
                                _c("el-date-picker", {
                                  staticClass: "tiem_sbx",
                                  attrs: {
                                    type: "date",
                                    clearable: "",
                                    "value-format": "yyyy-MM-dd",
                                    "picker-options": _vm.pickerOptions,
                                  },
                                  model: {
                                    value: item.buyDate,
                                    callback: function ($$v) {
                                      _vm.$set(item, "buyDate", $$v)
                                    },
                                    expression: "item.buyDate",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("td", [
                          _c("i", {
                            staticClass: "el-icon-circle-plus-outline",
                            staticStyle: {
                              "font-size": "24px",
                              color: "#356ef6",
                            },
                            on: { click: _vm.addXZItem },
                          }),
                          _c("i", {
                            staticClass: "el-icon-remove-outline",
                            staticStyle: {
                              "font-size": "24px",
                              color: "#ed6262",
                              "margin-left": "8px",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.deleteXZItem(index)
                              },
                            },
                          }),
                        ]),
                      ])
                    }),
                    0
                  ),
                ]
              ),
            ]
          ),
          _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
            _c("div", { staticClass: "footer-btn" }, [
              _c(
                "div",
                { staticClass: "footer-btn-list" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "mini",
                        type: "primary",
                        icon: "el-icon-check",
                      },
                      on: { click: _vm.submit },
                    },
                    [_vm._v("确定")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", icon: "el-icon-close" },
                      on: { click: _vm.cancel },
                    },
                    [_vm._v(" 取消 ")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "Dialog",
        {
          attrs: {
            title: "添加充电桩",
            width: "1024px",
            "form-loading": _vm.formLoading,
          },
          on: { handleClose: _vm.cancelCDZ },
          model: {
            value: _vm.showChargingDialog,
            callback: function ($$v) {
              _vm.showChargingDialog = $$v
            },
            expression: "showChargingDialog",
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { slot: "content", model: _vm.form, "label-width": "0" },
              slot: "content",
            },
            [
              _c(
                "table",
                { staticStyle: { width: "100%" }, attrs: { border: "1" } },
                [
                  _c("colgroup", [
                    _c("col", { staticStyle: { width: "10%" } }),
                    _c("col", { staticStyle: { width: "10%" } }),
                    _c("col", { staticStyle: { width: "10%" } }),
                    _c("col", { staticStyle: { width: "10%" } }),
                    _c("col", { staticStyle: { width: "15%" } }),
                    _c("col", { staticStyle: { width: "10%" } }),
                    _c("col", { staticStyle: { width: "8%" } }),
                    _c("col", { staticStyle: { width: "10%" } }),
                    _c("col", { staticStyle: { width: "10%" } }),
                    _c("col"),
                  ]),
                  _c(
                    "thead",
                    { staticStyle: { "background-color": "#eeee" } },
                    [
                      _c("tr", { staticStyle: { "font-size": "13px" } }, [
                        _c("th", { attrs: { scope: "col" } }, [
                          _vm._v("设备名称"),
                        ]),
                        _c("th", { attrs: { scope: "col" } }, [
                          _vm._v("设备型号"),
                        ]),
                        _c("th", { attrs: { scope: "col" } }, [
                          _vm._v("设备编码"),
                        ]),
                        _c("th", { attrs: { scope: "col" } }, [
                          _vm._v("地址类型"),
                        ]),
                        _c("th", { attrs: { scope: "col" } }, [
                          _vm._v("安装地址"),
                        ]),
                        _c("th", { attrs: { scope: "col" } }, [
                          _vm._v("设备种类"),
                        ]),
                        _c("th", { attrs: { scope: "col" } }, [
                          _vm._v("使用年限"),
                        ]),
                        _c("th", { attrs: { scope: "col" } }, [
                          _vm._v("损失险保额"),
                        ]),
                        _c("th", { attrs: { scope: "col" } }, [
                          _vm._v("责任险保额"),
                        ]),
                        _c("th", { attrs: { scope: "col" } }),
                      ]),
                    ]
                  ),
                  _c(
                    "tbody",
                    _vm._l(_vm.form.cdzItems, function (item, index) {
                      return _c("tr", { key: index }, [
                        _c(
                          "td",
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "cdzItems." + index + ".chargerName",
                                  rules: {
                                    required: true,
                                    message: "请输入设备名称",
                                    trigger: "blur",
                                  },
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "30",
                                    size: "mini",
                                    clearable: "",
                                  },
                                  model: {
                                    value: item.chargerName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        item,
                                        "chargerName",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "item.chargerName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "cdzItems." + index + ".chargerModel",
                                  rules: {
                                    required: true,
                                    message: "请输入型号",
                                    trigger: "blur",
                                  },
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "30",
                                    size: "mini",
                                    clearable: "",
                                  },
                                  model: {
                                    value: item.chargerModel,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        item,
                                        "chargerModel",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "item.chargerModel",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "cdzItems." + index + ".chargerCode",
                                  rules: {
                                    required: true,
                                    message: "请输入编码",
                                    trigger: "blur",
                                  },
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "30",
                                    size: "mini",
                                    clearable: "",
                                  },
                                  model: {
                                    value: item.chargerCode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        item,
                                        "chargerCode",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "item.chargerCode",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop:
                                    "cdzItems." + index + ".chargerAddressType",
                                  rules: {
                                    required: true,
                                    message: "请选择类型",
                                    trigger: "change",
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      size: "mini",
                                      placeholder: "请选择类型",
                                    },
                                    model: {
                                      value: item.chargerAddressType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          item,
                                          "chargerAddressType",
                                          $$v
                                        )
                                      },
                                      expression: "item.chargerAddressType",
                                    },
                                  },
                                  _vm._l(
                                    _vm.CHARGING_ADDRESS_TYPE,
                                    function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.dictName,
                                          value: item.dictCode,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "cdzItems." + index + ".chargerAddress",
                                  rules: {
                                    required: true,
                                    message: "请输入安装地址",
                                    trigger: "blur",
                                  },
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "40",
                                    size: "mini",
                                    clearable: "",
                                  },
                                  model: {
                                    value: item.chargerAddress,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        item,
                                        "chargerAddress",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "item.chargerAddress",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "cdzItems." + index + ".chargerKind",
                                  rules: {
                                    required: true,
                                    message: "请选择种类",
                                    trigger: "change",
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      size: "mini",
                                      placeholder: "请选择种类",
                                    },
                                    model: {
                                      value: item.chargerKind,
                                      callback: function ($$v) {
                                        _vm.$set(item, "chargerKind", $$v)
                                      },
                                      expression: "item.chargerKind",
                                    },
                                  },
                                  _vm._l(
                                    _vm.CHARGING_KIND,
                                    function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.dictName,
                                          value: item.dictCode,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop:
                                    "cdzItems." +
                                    index +
                                    ".chargingPostYearLimit",
                                  rules: {
                                    required: true,
                                    message: "请输入年限",
                                    trigger: "blur",
                                  },
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "30",
                                    size: "mini",
                                    clearable: "",
                                  },
                                  on: {
                                    input: function ($event) {
                                      item.chargingPostYearLimit = _vm.onInput(
                                        item.chargingPostYearLimit,
                                        0,
                                        100,
                                        0
                                      )
                                    },
                                  },
                                  model: {
                                    value: item.chargingPostYearLimit,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        item,
                                        "chargingPostYearLimit",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "item.chargingPostYearLimit",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "cdzItems." + index + ".ssxAmount",
                                  rules: {
                                    required: true,
                                    message: "请选择金额",
                                    trigger: "change",
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      size: "mini",
                                      placeholder: "请选择金额",
                                    },
                                    model: {
                                      value: item.ssxAmount,
                                      callback: function ($$v) {
                                        _vm.$set(item, "ssxAmount", $$v)
                                      },
                                      expression: "item.ssxAmount",
                                    },
                                  },
                                  _vm._l(
                                    _vm.CHARGING_A09_AMOUNT,
                                    function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.dictName,
                                          value: item.dictCode,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "cdzItems." + index + ".zrxAmount",
                                  rules: {
                                    required: true,
                                    message: "请选择金额",
                                    trigger: "change",
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      size: "mini",
                                      placeholder: "请选择金额",
                                    },
                                    model: {
                                      value: item.zrxAmount,
                                      callback: function ($$v) {
                                        _vm.$set(item, "zrxAmount", $$v)
                                      },
                                      expression: "item.zrxAmount",
                                    },
                                  },
                                  _vm._l(
                                    _vm.CHARGING_B07_AMOUNT,
                                    function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.dictName,
                                          value: item.dictCode,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("td", [
                          _c("i", {
                            staticClass: "el-icon-circle-plus-outline",
                            staticStyle: {
                              "font-size": "24px",
                              color: "#356ef6",
                            },
                            on: { click: _vm.addCDZItem },
                          }),
                          _c("i", {
                            staticClass: "el-icon-remove-outline",
                            staticStyle: {
                              "font-size": "24px",
                              color: "#ed6262",
                              "margin-left": "8px",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.deleteCDZItem(index)
                              },
                            },
                          }),
                        ]),
                      ])
                    }),
                    0
                  ),
                ]
              ),
            ]
          ),
          _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
            _c("div", { staticClass: "footer-btn" }, [
              _c(
                "div",
                { staticClass: "footer-btn-list" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "mini",
                        type: "primary",
                        icon: "el-icon-check",
                      },
                      on: { click: _vm.submitCharging },
                    },
                    [_vm._v("确定")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", icon: "el-icon-close" },
                      on: { click: _vm.cancelCDZ },
                    },
                    [_vm._v(" 取消 ")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }