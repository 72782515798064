import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import claimManageApi from '@/api/claimManage';
import Rules from '@/utils/rules';
import { character, getSupplyers } from '@/utils/common';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    currentForm: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      form: {},
      rules: {
        plateNo: [{
          required: false,
          message: '请输入车牌号',
          trigger: 'blur'
        }, {
          pattern: Rules["PLATENO_RULE"].value,
          message: "车牌号格式有误",
          trigger: ["blur", "change"]
        }],
        vin: [{
          required: true,
          message: '请输入车架号',
          trigger: 'blur'
        }, {
          pattern: Rules["VIN_RULE"].value,
          message: "车架号格式有误",
          trigger: ["blur", "change"]
        }],
        modelName: {
          required: true,
          message: '请输入品牌型号',
          trigger: "blur"
        },
        reporterName: [{
          required: true,
          message: '请输入报案人姓名',
          trigger: 'blur'
        }, {
          min: 2,
          max: 50,
          pattern: Rules["NAME"].value,
          message: "报案人姓名格式有误",
          trigger: ["blur", "change"]
        }],
        reporterMobile: [{
          required: true,
          message: '请输入报案人电话',
          trigger: 'blur'
        }, {
          pattern: Rules["MOBILE_RULE"].value,
          message: "报案人电话格式有误",
          trigger: ["blur", "change"]
        }],
        accidentTime: {
          required: true,
          message: '请选择事故发生时间',
          trigger: "change"
        },
        accidentAddress: {
          required: true,
          message: '请输入事故发生地址',
          trigger: "blur"
        },
        isSite: {
          required: true,
          message: '请选择是否在现场',
          trigger: "change"
        },
        isDriving: {
          required: true,
          message: '请选择是否可行驶',
          trigger: "change"
        }
      },
      formLoading: false,
      supplyers: getSupplyers() || [],
      checked: false
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
      ;
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    // 初始化数据
    initData: function initData() {
      this.form = {
        reportId: this.currentForm.reportId,
        insCorpCode: this.currentForm.insCorpCode,
        plateNo: this.currentForm.plateNo && this.currentForm.plateNo !== '未上牌' ? this.currentForm.plateNo : '',
        vin: this.currentForm.vin,
        modelName: this.currentForm.modelName,
        reporterName: this.currentForm.reporterName,
        reporterMobile: this.currentForm.reporterMobile,
        accidentTime: this.currentForm.accidentTime,
        accidentAddress: this.currentForm.accidentAddress,
        isSite: this.currentForm.isSite,
        isDriving: this.currentForm.isNormalDriving
      };
      if (this.form.plateNo && this.form.plateNo !== '未上牌') {
        this.checked = false;
        this.rules.plateNo[0].required = true;
      } else {
        this.checked = true;
        this.rules.plateNo[0].required = false;
      }
      ;
      this.form.plateNo = this.form.plateNo == '未上牌' || this.form.plateNo == '新车' ? '' : this.form.plateNo;
      this.$refs.detail.resetFields();
    },
    noPlateNoChange: function noPlateNoChange(val) {
      if (val) {
        this.form.plateNo = '';
        this.rules.plateNo[0].required = false;
      } else {
        this.rules.plateNo[0].required = true;
      }
      ;
      this.$forceUpdate();
    },
    // 监听输入事件
    inputvin: function inputvin(val) {
      if (val) {
        val = val.replace(/i/g, '').replace(/I/g, '').replace(/o/g, '').replace(/O/g, '').replace(/q/g, '').replace(/Q/g, '');
        this.form.vin = character(_.toUpper(val));
      }
    },
    // 保存数据
    save: function save() {
      var _this = this;
      this.$refs.detail.validate(function (valid) {
        if (valid) {
          _this.formLoading = true;
          claimManageApi.transformRepair(_this.form).then(function (res) {
            _this.formLoading = false;
            if (res.code === 200) {
              _this.$message.success(res.msg);
              _this.closePage(false);
            }
            ;
          }).catch(function (err) {
            _this.formLoading = false;
          });
        }
        ;
      });
    },
    // 关闭弹窗
    closePage: function closePage() {
      var boolean = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      this.$emit('closePage', boolean);
    }
  }
};