var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "identity",
          attrs: {
            model: _vm.formData,
            rules: _vm.formRules,
            "label-width": "120px",
            size: "mini",
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
            },
            [
              _c("div", { staticClass: "identity_class" }, [
                !_vm._.isEmpty(_vm.formData.cust_holder) &&
                _vm.identityStatus == "initIdentity"
                  ? _c("div", [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "background-color": "#fff",
                            padding: "15px 10px 10px 20px",
                            "border-radius": "5px",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-size": "14px",
                                "padding-left": "15px",
                                "font-weight": "600",
                                "line-height": "30px",
                              },
                            },
                            [_vm._v("投保人身份信息采集：")]
                          ),
                          _c("div", [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "line-height": "28px",
                                },
                              },
                              [
                                _c("span", { staticClass: "form-title" }, [
                                  _vm._v("姓名："),
                                ]),
                                _c("span", { staticClass: "form-content" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formData.cust_holder.name
                                        ? _vm.formData.cust_holder.name
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "line-height": "28px",
                                },
                              },
                              [
                                _c("span", { staticClass: "form-title" }, [
                                  _vm._v("身份证号："),
                                ]),
                                _c("span", { staticClass: "form-content" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formData.cust_holder.certificateNo
                                          ? _vm.formData.cust_holder
                                              .certificateNo
                                          : ""
                                      )
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "line-height": "28px",
                                },
                              },
                              [
                                _c("span", { staticClass: "form-title" }, [
                                  _vm._v("性别："),
                                ]),
                                _c("span", { staticClass: "form-content" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formData.cust_holder.sex == "M"
                                          ? "男"
                                          : "女"
                                      )
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "line-height": "28px",
                                },
                              },
                              [
                                _c("span", { staticClass: "form-title" }, [
                                  _vm._v("出生日期："),
                                ]),
                                _c("span", { staticClass: "form-content" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formData.cust_holder.birthday
                                          ? _vm.formData.cust_holder.birthday.split(
                                              " "
                                            )[0]
                                          : ""
                                      )
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                          _c(
                            "el-row",
                            {
                              staticClass: "identity_row",
                              attrs: { gutter: 0 },
                            },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "民族：",
                                        "label-width": "120px",
                                        prop: "cust_holder.nation",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            filterable: "",
                                            placeholder: "请选择民族",
                                            clearable: "",
                                          },
                                          model: {
                                            value:
                                              _vm.formData.cust_holder.nation,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formData.cust_holder,
                                                "nation",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formData.cust_holder.nation",
                                          },
                                        },
                                        _vm._l(
                                          _vm.VI_NATIONAL_SOURCE,
                                          function (zitem, zindex) {
                                            return _c("el-option", {
                                              key: zindex,
                                              attrs: {
                                                label: zitem,
                                                value: zitem,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            {
                              staticClass: "identity_row",
                              attrs: { gutter: 0 },
                            },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "签发机构：",
                                        "label-width": "120px",
                                        prop: "cust_holder.issuingAgency",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          maxlength: "40",
                                          placeholder: "请输入签发机构",
                                          clearable: "",
                                        },
                                        model: {
                                          value:
                                            _vm.formData.cust_holder
                                              .issuingAgency,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formData.cust_holder,
                                              "issuingAgency",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "formData.cust_holder.issuingAgency",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            {
                              staticClass: "identity_row",
                              attrs: { gutter: 0 },
                            },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "证件有效起期：",
                                        "label-width": "120px",
                                        prop: "cust_holder.certStartDate",
                                      },
                                    },
                                    [
                                      _c("el-date-picker", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          "value-format": "yyyy-MM-dd HH:mm:ss",
                                          type: "date",
                                          placeholder: "请选择证件有效起期",
                                          "picker-options":
                                            _vm.disabledHolderMoreThan,
                                          clearable: "",
                                        },
                                        model: {
                                          value:
                                            _vm.formData.cust_holder
                                              .certStartDate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formData.cust_holder,
                                              "certStartDate",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "formData.cust_holder.certStartDate",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            {
                              staticClass: "identity_row",
                              attrs: { gutter: 0 },
                            },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "证件有效止期：",
                                        "label-width": "120px",
                                        prop: "cust_holder.certEndDate",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticStyle: { display: "flex" } },
                                        [
                                          _c("el-date-picker", {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              disabled: _vm.holderChecked,
                                              "value-format":
                                                "yyyy-MM-dd HH:mm:ss",
                                              type: "date",
                                              "picker-options":
                                                _vm.disabledHolderLessThan,
                                              placeholder: "请选择证件有效止期",
                                              clearable: "",
                                            },
                                            model: {
                                              value:
                                                _vm.formData.cust_holder
                                                  .certEndDate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formData.cust_holder,
                                                  "certEndDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "formData.cust_holder.certEndDate",
                                            },
                                          }),
                                          _c("el-checkbox", {
                                            staticStyle: {
                                              "margin-left": "5px",
                                            },
                                            attrs: { label: "长期" },
                                            on: { change: _vm.holderChange },
                                            model: {
                                              value: _vm.holderChecked,
                                              callback: function ($$v) {
                                                _vm.holderChecked = $$v
                                              },
                                              expression: "holderChecked",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm.formData.cust_holder.certificateNo !=
                  _vm.formData.cust_insured.certificateNo &&
                !_vm._.isEmpty(_vm.formData.cust_insured) &&
                _vm.identityStatus == "initIdentity"
                  ? _c("div", [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "background-color": "#fff",
                            padding: "15px 10px 15px 20px",
                            "border-radius": "5px",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-size": "14px",
                                "padding-left": "15px",
                                "font-weight": "600",
                                "line-height": "30px",
                              },
                            },
                            [_vm._v("被保人身份信息采集：")]
                          ),
                          _c("div", [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "line-height": "28px",
                                },
                              },
                              [
                                _c("span", { staticClass: "form-title" }, [
                                  _vm._v("姓名："),
                                ]),
                                _c("span", { staticClass: "form-content" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formData.cust_insured.name
                                        ? _vm.formData.cust_insured.name
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "line-height": "28px",
                                },
                              },
                              [
                                _c("span", { staticClass: "form-title" }, [
                                  _vm._v("身份证号："),
                                ]),
                                _c("span", { staticClass: "form-content" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formData.cust_insured.certificateNo
                                          ? _vm.formData.cust_insured
                                              .certificateNo
                                          : ""
                                      )
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "line-height": "28px",
                                },
                              },
                              [
                                _c("span", { staticClass: "form-title" }, [
                                  _vm._v("性别："),
                                ]),
                                _c("span", { staticClass: "form-content" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formData.cust_insured.sex == "M"
                                          ? "男"
                                          : "女"
                                      )
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "line-height": "28px",
                                },
                              },
                              [
                                _c("span", { staticClass: "form-title" }, [
                                  _vm._v("出生日期："),
                                ]),
                                _c("span", { staticClass: "form-content" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formData.cust_insured.birthday
                                          ? _vm.formData.cust_insured.birthday.split(
                                              " "
                                            )[0]
                                          : ""
                                      )
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                          _c(
                            "el-row",
                            {
                              staticClass: "identity_row",
                              attrs: { gutter: 0 },
                            },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "民族：",
                                        "label-width": "120px",
                                        prop: "cust_insured.nation",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            placeholder: "请选择民族",
                                            filterable: "",
                                            clearable: "",
                                          },
                                          model: {
                                            value:
                                              _vm.formData.cust_insured.nation,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formData.cust_insured,
                                                "nation",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formData.cust_insured.nation",
                                          },
                                        },
                                        _vm._l(
                                          _vm.VI_NATIONAL_SOURCE,
                                          function (zitem, zindex) {
                                            return _c("el-option", {
                                              key: zindex,
                                              attrs: {
                                                label: zitem,
                                                value: zitem,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            {
                              staticClass: "identity_row",
                              attrs: { gutter: 0 },
                            },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "签发机构：",
                                        "label-width": "120px",
                                        prop: "cust_insured.issuingAgency",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          maxlength: "40",
                                          placeholder: "请输入签发机构",
                                          clearable: "",
                                        },
                                        model: {
                                          value:
                                            _vm.formData.cust_insured
                                              .issuingAgency,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formData.cust_insured,
                                              "issuingAgency",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "formData.cust_insured.issuingAgency",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            {
                              staticClass: "identity_row",
                              attrs: { gutter: 0 },
                            },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "证件有效起期：",
                                        "label-width": "120px",
                                        prop: "cust_insured.certStartDate",
                                      },
                                    },
                                    [
                                      _c("el-date-picker", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          "value-format": "yyyy-MM-dd HH:mm:ss",
                                          type: "date",
                                          placeholder: "请选择证件有效起期",
                                          "picker-options":
                                            _vm.disabledInsureMoreThan,
                                          clearable: "",
                                        },
                                        model: {
                                          value:
                                            _vm.formData.cust_insured
                                              .certStartDate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formData.cust_insured,
                                              "certStartDate",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "formData.cust_insured.certStartDate",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            {
                              staticClass: "identity_row",
                              attrs: { gutter: 0 },
                            },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "证件有效止期：",
                                        "label-width": "120px",
                                        prop: "cust_insured.certEndDate",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticStyle: { display: "flex" } },
                                        [
                                          _c("el-date-picker", {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              disabled: _vm.insureChecked,
                                              "value-format":
                                                "yyyy-MM-dd HH:mm:ss",
                                              "picker-options":
                                                _vm.disabledInsureLessThan,
                                              type: "date",
                                              placeholder: "请选择证件有效止期",
                                              clearable: "",
                                            },
                                            model: {
                                              value:
                                                _vm.formData.cust_insured
                                                  .certEndDate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formData.cust_insured,
                                                  "certEndDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "formData.cust_insured.certEndDate",
                                            },
                                          }),
                                          _c("el-checkbox", {
                                            staticStyle: {
                                              "margin-left": "5px",
                                            },
                                            attrs: { label: "长期" },
                                            on: { change: _vm.insureChange },
                                            model: {
                                              value: _vm.insureChecked,
                                              callback: function ($$v) {
                                                _vm.insureChecked = $$v
                                              },
                                              expression: "insureChecked",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm.identityStatus == "verification"
                  ? _c(
                      "div",
                      {
                        staticStyle: {
                          padding: "20px 50px 10px",
                          "min-height": "100px",
                        },
                      },
                      [
                        _c("div", { staticStyle: { "text-align": "center" } }, [
                          _c("div", [
                            _c("span", [
                              _vm._v(
                                '保司系统发送手机验证码给"' +
                                  _vm._s(
                                    _vm.formData.cust_holder.mobile
                                      ? _vm.formData.cust_holder.mobile
                                      : _vm.formData.cust_insured.mobile
                                  ) +
                                  '",请查收'
                              ),
                            ]),
                          ]),
                          _c(
                            "div",
                            { staticStyle: { margin: "15px 0px 20px" } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "special_row_content",
                                      attrs: {
                                        label: "验证码：",
                                        "label-width": "120px",
                                        prop: "issueCode",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: { "margin-right": "10px" },
                                        attrs: {
                                          maxlength: "10",
                                          placeholder: "请输入验证码",
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.formData.issueCode,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "issueCode",
                                              $$v
                                            )
                                          },
                                          expression: "formData.issueCode",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "center",
                    "line-height": "46px",
                    height: "46px",
                    padding: "8px 0px",
                    "margin-top": "5px",
                  },
                },
                [
                  _vm.identityStatus == "initIdentity"
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            size: "mini",
                            plain: "",
                            disabled: _vm.isClick,
                          },
                          on: { click: _vm.confirmIdentityInfo },
                        },
                        [_vm._v("提 交")]
                      )
                    : _vm._e(),
                  _vm.identityStatus == "initIdentity"
                    ? _c(
                        "el-button",
                        { attrs: { size: "mini" }, on: { click: _vm.close } },
                        [_vm._v("取 消")]
                      )
                    : _vm._e(),
                  _vm.identityStatus == "verification"
                    ? _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: { click: _vm.returnKey },
                        },
                        [_vm._v("返 回")]
                      )
                    : _vm._e(),
                  _vm.identityStatus == "verification"
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            size: "mini",
                            plain: "",
                            disabled: _vm.isClick,
                          },
                          on: { click: _vm.confirmIdentityVerifyInfo },
                        },
                        [_vm._v("确 定")]
                      )
                    : _vm._e(),
                  _vm.identityStatus == "verification"
                    ? _c(
                        "el-button",
                        { attrs: { size: "mini" }, on: { click: _vm.close } },
                        [_vm._v("取 消")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }