import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.join.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getSupplyers } from '@/utils/common';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    viewRenewalId: {
      type: String,
      default: ''
    },
    viewRenewalFormData: {
      type: Object,
      default: function _default() {
        return {
          basicInformation: {},
          bslist: [],
          coreQuoteResList: [],
          underwritingData: []
        };
      }
    }
  },
  data: function data() {
    return {
      form: {
        basicInformation: {},
        bslist: [],
        coreQuoteResList: [],
        underwritingData: []
      },
      bzProduct: null,
      bsProduct: null
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
    }
  },
  mounted: function mounted() {
    this.supplyers = getSupplyers();
    this.initData();
  },
  methods: {
    // 获取保险公司中文名称
    getInsCorpName: function getInsCorpName(product) {
      if (!product) return '';
      if (product.insCorpName) {
        return product.insCorpName;
      } else {
        var insName = _.filter(this.supplyers, function (o) {
          return _.find([product.insCorpCode], function (item) {
            return _.toLower(item) == _.toLower(o.code);
          });
        });
        var inCorpName = [];
        if (!_.isEmpty(insName)) insName.forEach(function (item) {
          return inCorpName.push(item.shortName);
        });
        return !_.isEmpty(inCorpName) ? inCorpName.toString() : "";
      }
    },
    initData: function initData() {
      this.form = this.viewRenewalFormData;
      if (!_.isEmpty(this.form.underwritingData)) {
        this.bzProduct = _.find(this.form.underwritingData, function (item) {
          return item.riskType == '0';
        });
        this.bsProduct = _.find(this.form.underwritingData, function (item) {
          return item.riskType == '1';
        });
        if (!_.isEmpty(this.form.bslist)) {
          this.form.bslist = _.sortBy(this.form.bslist, function (o) {
            return o.riskkindCode || o.riskkindName;
          });
        }
      }
    },
    getProposalVehicleType: function getProposalVehicleType(item) {
      //拼接车型价格展示字符串
      if (!item || !item.modelName) return "";
      //车型展示规则
      var modelCode = item.makerModelCode ? item.makerModelCode : item.industryModelCode;
      // if (!modelCode) return "";
      var remark = item.remark ? item.remark : item.vehicleAlias;

      // let name = _.join([modelCode, item.modelName, item.brandName, remark, `${item.seats}座`, item.purchasePrice, item.yearPattern], "/");
      var name = modelCode ? _.join([modelCode, item.modelName], '/') : item.modelName;
      name = item.brandName ? _.join([name, item.brandName], '/') : name;
      name = remark ? _.join([name, remark], '/') : name;
      name = item.seats ? _.join([name, "".concat(item.seats, "\u5EA7")], '/') : name;
      name = item.purchasePrice ? _.join([name, item.purchasePrice], '/') : name;
      name = item.yearPattern ? _.join([name, item.yearPattern], '/') : name;
      return name;
    },
    //关闭弹窗
    closePage: function closePage() {
      this.$emit('closePage');
    }
  }
};