import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import OemSelect from '@/components/OemSelect';
import OrgSelect from '@/components/OrgSelect';
import vehicleSalesRequest from '@/api/vehicleSalesMaintain';
import dictRequest from '@/api/dictionary';
import { getUserOrgInfoDealerOem } from '@/utils/common';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    opType: {
      type: String,
      default: ''
    },
    currentTemplateId: {
      type: String,
      default: ''
    },
    bizType: {
      type: String,
      default: ''
    },
    importCategoryList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    supplyers: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  components: {
    OemSelect: OemSelect,
    OrgSelect: OrgSelect
  },
  data: function data() {
    return {
      form: {
        templateName: '',
        templateCode: '',
        orgCode: '',
        orgName: '',
        orgId: '',
        importCategory: '',
        insCorpCode: null,
        insCorpName: null,
        attrList: []
      },
      rules: {
        templateName: [{
          required: true,
          message: '请输入方案名称',
          trigger: 'blur'
        }],
        templateCode: [{
          required: true,
          message: '请输入模板编码',
          trigger: 'blur'
        }],
        orgCode: [{
          required: true,
          message: '请选择应用机构',
          trigger: 'change'
        }],
        importCategory: [{
          required: true,
          message: '请选择导入模板',
          trigger: 'change'
        }],
        insCorpCode: [{
          required: true,
          message: '请选择保险公司',
          trigger: 'change'
        }]
      },
      orgForm: {
        salesOrgCode: "",
        salesOrgName: ""
      },
      valueTypeList: [],
      isShowInsCorp: false,
      listLoading: false,
      userInfo: JSON.parse(localStorage.getItem("userInfo")) || {}
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
        this.$refs.detail.resetFields();
      }
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    //获取取值方式数据
    getValueTypeList: function getValueTypeList() {
      var _this = this;
      dictRequest.getDictItemsByCode("VALUE_TYPE").then(function (res) {
        if (res.data) {
          _this.valueTypeList = res.data || [];
        }
      });
    },
    codeChange: function codeChange(val) {
      this.form.insCorpName = this.supplyers.find(function (item) {
        return item.code === val;
      }).shortName;
    },
    categoryChange: function categoryChange(val) {
      this.form.insCorpCode = null;
      this.form.insCorpName = null;
      this.getImportTemplates(val);
    },
    getImportTemplates: function getImportTemplates(type) {
      var _this2 = this;
      vehicleSalesRequest.getImportTemplates(type).then(function (res) {
        if (res.code == 200 && res.data) {
          if (_this2.opType != 'add') {
            // 模版内的字段集
            var attrs = res.data.attrList;
            // 已配置的字段集
            var _attrList = _this2.form.attrList;
            if (!_.isEmpty(attrs)) {
              _this2.form.attrList = [];
              // 取并集
              attrs.forEach(function (attr) {
                // 查找是否已配置
                var _attr = _.find(_attrList, function (item) {
                  return item.attrCode == attr.attrCode;
                });
                if (_attr) {
                  _this2.form.attrList.push(_attr);
                } else {
                  _this2.form.attrList.push(attr);
                }
              });
            }
          } else {
            _this2.form.attrList = res.data.attrList || [];
          }
          _this2.isShowInsCorp = res.data.isSelectnInsCorp;
        }
      });
    },
    // 树形点击
    nodeClick: function nodeClick(data) {
      if (this.bizType === "BROKER") {
        this.form.orgCode = data.salesOrgCode;
        this.form.orgName = data.salesOrgName;
        this.form.orgId = data.organizationId;
      }
      if (this.bizType === "OEM") {
        this.form.orgCode = data.salesOrgCode;
        this.form.orgName = data.salesOrgName;
        this.form.orgId = orgObj.salesOrgId;
      }
    },
    // 初始化数据
    initData: function initData() {
      var _this3 = this;
      this.getValueTypeList();
      this.form = {
        templateName: '',
        templateCode: '',
        orgCode: '',
        orgName: '',
        orgId: '',
        importCategory: '',
        insCorpCode: null,
        insCorpName: null,
        attrList: []
      };
      this.orgForm = {
        salesOrgCode: "",
        salesOrgName: ""
      };
      if (this.opType === "add") {
        // if (this.bizType === "DEALER") { // 获取经销商信息
        var _orgObj = getUserOrgInfoDealerOem(this.bizType);
        this.form.orgCode = _orgObj.salesOrgCode;
        this.form.orgName = _orgObj.salesOrgName;
        this.form.orgId = _orgObj.salesOrgId;
        // };
      } else {
        vehicleSalesRequest.viewConfigure(this.currentTemplateId).then(function (res) {
          if (res.code === 200) {
            _this3.form = res.data;
            _this3.categoryChange(res.data.importCategory);
            _this3.orgForm = {
              salesOrgCode: _this3.form.orgCode,
              salesOrgName: _this3.form.orgName
            };
          }
          ;
        });
      }
      ;
    },
    // 附件校验
    validate: function validate() {
      var _this4 = this;
      var flag = true;
      if (!this.form.attrList.length) {
        this.$message.warning('映射方案数据不能为空');
        flag = false;
        return flag;
      }
      ;
      if (this.form.attrList.length) {
        this.form.attrList.some(function (item) {
          if (!item.valueContent) {
            _this4.$message.warning("\u5B57\u6BB5\u3010".concat(item.attrName, "\u3011\u7684\u201C\u6807\u9898\u540D\u79F0/\u8868\u8FBE\u5F0F/\u8FD0\u7B97\u516C\u5F0F\u201D\u4E0D\u5141\u8BB8\u4E3A\u7A7A"));
            flag = false;
            return true;
          }
          ;
        });
      }
      ;
      return flag;
    },
    // 保存数据
    save: function save() {
      var _this5 = this;
      this.$refs.detail.validate(function (valid) {
        if (valid) {
          if (!_this5.validate()) return;
          _this5.listLoading = true;
          var req = _this5.opType !== 'edit' ? vehicleSalesRequest.addConfigure(_this5.form) : vehicleSalesRequest.editConfigure(_this5.currentTemplateId, _this5.form);
          req.then(function (res) {
            _this5.listLoading = false;
            if (res.code === 200) {
              _this5.$message.success(res.msg);
              _this5.closePage();
            }
            ;
          }).catch(function (err) {
            _this5.listLoading = false;
          });
        }
        ;
      });
    },
    //关闭弹窗
    closePage: function closePage() {
      this.$emit('closePage');
    }
  }
};