import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import Pagination from '@/components/Pagination';
import ScoreDialog from './dialog/scoreDialog';
import VehicleDialog from './dialog/vehicleDialog';
import OemSelect from '@/components/OemSelect';
import callApi from '@/api/callCenter';
import { getUserOrgInfoDealerOem, getCurrentDate, getBeforeDate, commonExport } from '@/utils/common';
export default {
  name: 'CallRecord',
  components: {
    Pagination: Pagination,
    ScoreDialog: ScoreDialog,
    VehicleDialog: VehicleDialog,
    OemSelect: OemSelect
  },
  mixins: [initHeight],
  data: function data() {
    return {
      drawer: false,
      searchForm: {
        callType: null,
        callStatus: null,
        callStartDate: getBeforeDate(6),
        callEndDate: getCurrentDate(),
        customerName: null
      },
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      multipleSelection: [],
      loading: false,
      showScoreDialog: false,
      showRemarkDialog: false,
      showVehicleDialog: false,
      callTypeList: [{
        value: null,
        label: '全部'
      }, {
        value: 'CALL_OUT',
        label: '呼出'
      }, {
        value: 'CALL_IN',
        label: '呼入'
      }],
      callStateList: [{
        value: null,
        label: '全部'
      }, {
        value: 'NOT_TAKE_CALL',
        label: '未接'
      }, {
        value: 'END_OF_CALL',
        label: '通话结束'
      }],
      startEndTime: [getBeforeDate(6), getCurrentDate()],
      orgForm: {
        salesOrgCode: '',
        salesOrgName: '',
        salesOrgType: ''
      },
      bizType: localStorage.getItem("bizType") || '',
      currentScore: null,
      currenRemark: null,
      currentData: {},
      isPlay: false
    };
  },
  created: function created() {
    this.initData();
    this.loadData();
  },
  methods: {
    // 初始化数据
    initData: function initData() {
      var orgObj = getUserOrgInfoDealerOem(this.bizType);
      this.searchForm.orgCode = orgObj.orgCode;
      this.searchForm.orgName = orgObj.fullName;
      this.orgForm.salesOrgCode = orgObj.orgCode;
      this.orgForm.salesOrgName = orgObj.fullName;
    },
    // 机构树点击事件
    handleNodeClick: function handleNodeClick(data) {
      this.searchForm.orgCode = data.salesOrgCode;
      this.searchForm.orgName = data.salesOrgName;
      this.searchForm.areaType = data.salesOrgType;
    },
    // 打分
    score: function score(item) {
      this.currentScore = item.score ? item.score : 0;
      this.currentData = item;
      this.showScoreDialog = true;
    },
    // 备注
    remark: function remark(item) {
      this.currenRemark = item.remark;
      this.showRemarkDialog = true;
    },
    // 查看车辆
    viewVehicle: function viewVehicle(item) {
      this.showVehicleDialog = true;
    },
    // 删除
    batchDelete: function batchDelete(item) {
      var _this = this;
      var ids = [];
      if (!_.isEmpty(item)) {
        ids.push(item.recordId);
      } else {
        if (!_.isEmpty(this.multipleSelection)) {
          ids = _.map(this.multipleSelection, 'recordId');
        }
        ;
      }
      ;
      this.$confirm('您确定要删除么？', {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        callApi.deleteRecord(ids).then(function (res) {
          if (res.code === 200) {
            _this.$message.success(res.msg);
            _this.loadData();
          }
          ;
        });
      });
    },
    // 重置搜索表单
    resetSearch: function resetSearch() {
      this.searchForm = {
        orgName: '',
        orgCode: '',
        callType: null,
        callStatus: null,
        callStartDate: getBeforeDate(6),
        callEndDate: getCurrentDate()
      };
      this.orgForm = {
        salesOrgName: '',
        salesOrgType: '',
        salesOrgCode: ''
      };
      this.initData();
      this.startEndTime = [getBeforeDate(6), getCurrentDate()];
    },
    // 搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    // 获取列表数据
    loadData: function loadData() {
      var _this2 = this;
      var isDialogNoRefresh = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.showScoreDialog = false;
      this.showRemarkDialog = false;
      this.showVehicleDialog = false;
      if (isDialogNoRefresh) return;
      if (this.searchForm && this.searchForm.callStartDate) {
        this.searchForm.callStartDate = this.searchForm.callStartDate.split(' ')[0] + ' 00:00:00';
      }
      if (this.searchForm && this.searchForm.callEndDate) {
        this.searchForm.callEndDate = this.searchForm.callEndDate.split(' ')[0] + ' 23:59:59';
      }
      this.loading = true;
      this.drawer = false;
      this.isPlay = false;
      callApi.getRecordList(this.listQuery.pageNum, this.listQuery.pageSize, this.searchForm).then(function (res) {
        _this2.loading = false;
        if (res.code === 200) {
          _this2.tableData = res.data.list;
          _this2.total = res.data.page.recordsTotal;
          _this2.tableData.forEach(function (res, index) {
            _this2.$set(res, 'url', '');
            _this2.$set(res, 'isPlay', false);
          });
        }
      }).catch(function (err) {
        _this2.loading = false;
      });
    },
    // 列表行选择事件
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 刷新
    breakData: function breakData() {
      this.loadData();
    },
    // 日期更改监听事件
    dateChange: function dateChange(start, end, val) {
      if (val && val.length > 1) {
        this.searchForm[start] = val[0];
        this.searchForm[end] = val[1];
      } else {
        this.searchForm[start] = null;
        this.searchForm[end] = null;
      }
      ;
    },
    // 导出
    exportExcels: function exportExcels() {
      var exporParams = {
        total: this.total,
        fileName: '通话记录',
        exportUrl: '/oc-core-service/callCenter/record',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    loadUrl: function loadUrl(item, index) {
      var audio = document.getElementById("audio".concat(index));
      if (item.url) {
        if (audio.paused) {
          audio.play();
          item.isPlay = true;
          this.isPlay = true;
        } else {
          audio.pause();
          item.isPlay = false;
          this.isPlay = false;
        }
      } else {
        item.url = item.fileUrl;
        item.isPlay = true;
        this.isPlay = true;
        this.$nextTick(function () {
          audio.play();
        });
      }
    },
    timeupdate: function timeupdate(e, item, index) {
      var audio = document.getElementById("audio".concat(index));
      var value = e.target.currentTime / e.target.duration;
      if (value === 1) {
        audio.pause();
        this.isPlay = false;
        item.isPlay = false;
      }
    },
    // 暂停函数
    pauseAll: function pauseAll(audio) {
      var self = this;
      var audios = document.getElementsByTagName("audio");
      [].forEach.call(audios, function (i) {
        // 将audios中其他的audio全部暂停
        if (audio !== i) {
          i !== self && i.pause();
        }
      });
    },
    playChange: function playChange(val, index) {
      var self = this;
      var audios = document.getElementsByTagName("audio");
      var audio = document.getElementById("audio".concat(index));
      // 给play事件绑定暂停函数
      [].forEach.call(audios, function (i) {
        if (audio !== i) {
          i.addEventListener("play", self.pauseAll.bind(audio, i));
        }
      });
    }
  }
};