var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "clues-detail" },
    [
      _c("PageContainer", [
        _c("div", { attrs: { slot: "main" }, slot: "main" }, [
          _c(
            "div",
            {
              staticClass: "app-m-cls",
              staticStyle: { "min-height": "calc(100vh - 130px)" },
            },
            [
              _c(
                "div",
                { ref: "header", staticClass: "header-cls table-cls" },
                [
                  _c(
                    "el-collapse",
                    {
                      staticStyle: { "margin-top": "10px" },
                      model: {
                        value: _vm.activeName1,
                        callback: function ($$v) {
                          _vm.activeName1 = $$v
                        },
                        expression: "activeName1",
                      },
                    },
                    [
                      _c(
                        "el-collapse-item",
                        {
                          staticClass: "collapse-item-style",
                          attrs: { name: "1" },
                        },
                        [
                          _c("template", { slot: "title" }, [
                            _c(
                              "div",
                              {
                                staticClass: "title",
                                staticStyle: { "line-height": "25px" },
                              },
                              [
                                _c("FormLabel", {
                                  attrs: { "form-label": "客户信息" },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "table-btn-cls" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.save("customer")
                                    },
                                  },
                                },
                                [_vm._v("保存")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form",
                            {
                              ref: "customerRef",
                              attrs: {
                                rules: _vm.formRules,
                                model: _vm.form.vehicleOwner,
                                "label-width": "120px",
                                size: "mini",
                              },
                            },
                            [
                              _c(
                                "el-row",
                                { staticStyle: { "margin-top": "10px" } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "车主名称",
                                            prop: "ownerName",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              maxlength: "40",
                                              placeholder: "请输入车主名称",
                                              clearable: "",
                                            },
                                            model: {
                                              value:
                                                _vm.form.vehicleOwner.ownerName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form.vehicleOwner,
                                                  "ownerName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.vehicleOwner.ownerName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "手机号",
                                            prop: "ownerMobile",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入手机号",
                                              maxlength: "11",
                                              clearable: "",
                                            },
                                            model: {
                                              value:
                                                _vm.form.vehicleOwner
                                                  .ownerMobile,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form.vehicleOwner,
                                                  "ownerMobile",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.vehicleOwner.ownerMobile",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "证件号",
                                            prop: "certificateNo",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入证件号",
                                              maxlength: "20",
                                              clearable: "",
                                            },
                                            on: { input: _vm.inputCardNo },
                                            model: {
                                              value:
                                                _vm.form.vehicleOwner
                                                  .certificateNo,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form.vehicleOwner,
                                                  "certificateNo",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.vehicleOwner.certificateNo",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-collapse",
                    {
                      staticStyle: { "margin-top": "10px" },
                      model: {
                        value: _vm.activeName2,
                        callback: function ($$v) {
                          _vm.activeName2 = $$v
                        },
                        expression: "activeName2",
                      },
                    },
                    [
                      _c(
                        "el-collapse-item",
                        {
                          staticClass: "collapse-item-style",
                          attrs: { name: "1" },
                        },
                        [
                          _c("template", { slot: "title" }, [
                            _c(
                              "div",
                              {
                                staticClass: "title",
                                staticStyle: { "line-height": "25px" },
                              },
                              [
                                _c("FormLabel", {
                                  attrs: { "form-label": "车辆信息" },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "table-btn-cls" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.save("car")
                                    },
                                  },
                                },
                                [_vm._v("保存")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form",
                            {
                              ref: "carRef",
                              attrs: {
                                rules: _vm.formRules,
                                model: _vm.form.vehicle,
                                "label-width": "120px",
                                size: "mini",
                              },
                            },
                            [
                              _c(
                                "el-row",
                                { staticStyle: { "margin-top": "10px" } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop: "plateNo",
                                            label: "车牌号",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              disabled: _vm.isNewCar,
                                              minlength: "7",
                                              maxlength: "8",
                                              placeholder: "请输入车牌号",
                                              clearable: "",
                                            },
                                            on: { input: _vm.inputPlateNo },
                                            model: {
                                              value: _vm.form.vehicle.plateNo,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form.vehicle,
                                                  "plateNo",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.vehicle.plateNo",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 2 } },
                                    [
                                      _c(
                                        "el-checkbox",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          on: { change: _vm.noPlateNoChange },
                                          model: {
                                            value: _vm.isNewCar,
                                            callback: function ($$v) {
                                              _vm.isNewCar = $$v
                                            },
                                            expression: "isNewCar",
                                          },
                                        },
                                        [_vm._v("未上牌")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "车架号",
                                            prop: "vin",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              maxlength: "17",
                                              placeholder: "请输入车架号",
                                              clearable: "",
                                            },
                                            on: { input: _vm.inputvin },
                                            model: {
                                              value: _vm.form.vehicle.vin,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form.vehicle,
                                                  "vin",
                                                  $$v
                                                )
                                              },
                                              expression: "form.vehicle.vin",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "发动机号" } },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              maxlength: "40",
                                              placeholder: "请输入发动机号",
                                              clearable: "",
                                            },
                                            on: { input: _vm.inputEngineNo },
                                            model: {
                                              value: _vm.form.vehicle.engineNo,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form.vehicle,
                                                  "engineNo",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.vehicle.engineNo",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "品牌型号" } },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入品牌型号",
                                              clearable: "",
                                            },
                                            model: {
                                              value: _vm.form.vehicle.modelName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form.vehicle,
                                                  "modelName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.vehicle.modelName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "使用性质" } },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                filterable: "",
                                                placeholder: "请选择",
                                                clearable: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.form.vehicle
                                                    .useAttributeType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form.vehicle,
                                                    "useAttributeType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.vehicle.useAttributeType",
                                              },
                                            },
                                            _vm._l(
                                              _vm.USE_ATTRIBUTE,
                                              function (item, index) {
                                                return _c("el-option", {
                                                  key: index,
                                                  attrs: {
                                                    label: item.dictName,
                                                    value: item.dictCode,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "注册日期：" } },
                                        [
                                          _c("el-date-picker", {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              placeholder: "请选择注册日期",
                                              format: "yyyy-MM-dd",
                                              "value-format":
                                                "yyyy-MM-dd HH:mm:ss",
                                              "unlink-panels": "",
                                              "picker-options":
                                                _vm.disabledLessThan,
                                            },
                                            model: {
                                              value:
                                                _vm.form.vehicle.registerDate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form.vehicle,
                                                  "registerDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.vehicle.registerDate",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-collapse",
                    {
                      staticStyle: { "margin-top": "10px" },
                      model: {
                        value: _vm.activeName7,
                        callback: function ($$v) {
                          _vm.activeName7 = $$v
                        },
                        expression: "activeName7",
                      },
                    },
                    [
                      _c(
                        "el-collapse-item",
                        {
                          staticClass: "collapse-item-style",
                          attrs: { name: "1" },
                        },
                        [
                          _c("template", { slot: "title" }, [
                            _c(
                              "div",
                              {
                                staticClass: "title",
                                staticStyle: { "line-height": "25px" },
                              },
                              [
                                _c("FormLabel", {
                                  attrs: { "form-label": "上一年投保信息" },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "table-btn-cls" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.save("insuranceLast")
                                    },
                                  },
                                },
                                [_vm._v("保存")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form",
                            {
                              ref: "insuranceLastRef",
                              attrs: {
                                rules: _vm.formRules,
                                model: _vm.form.risksData,
                                "label-width": "120px",
                                size: "mini",
                              },
                            },
                            [
                              _c(
                                "el-row",
                                { staticStyle: { "margin-top": "10px" } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "商业险保司名称" } },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                filterable: "",
                                                clearable: "",
                                                placeholder: "请选择保司类型",
                                              },
                                              model: {
                                                value:
                                                  _vm.form.risksData
                                                    .bsLastYearInsCorpName,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form.risksData,
                                                    "bsLastYearInsCorpName",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.risksData.bsLastYearInsCorpName",
                                              },
                                            },
                                            _vm._l(
                                              _vm.insuranceList,
                                              function (item, index) {
                                                return _c("el-option", {
                                                  key: index,
                                                  attrs: {
                                                    label: item.shortName,
                                                    value: item.shortName,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "商业险到期日" } },
                                        [
                                          _c("el-date-picker", {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              "value-format":
                                                "yyyy-MM-dd HH:mm:ss",
                                              type: "date",
                                              placeholder: "请输入商业险到期日",
                                            },
                                            model: {
                                              value:
                                                _vm.form.risksData
                                                  .bsLastYearEndDate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form.risksData,
                                                  "bsLastYearEndDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.risksData.bsLastYearEndDate",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "交强险保司名称" } },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                filterable: "",
                                                clearable: "",
                                                placeholder: "请选择保司类型",
                                              },
                                              model: {
                                                value:
                                                  _vm.form.risksData
                                                    .bzLastYearInsCorpName,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form.risksData,
                                                    "bzLastYearInsCorpName",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.risksData.bzLastYearInsCorpName",
                                              },
                                            },
                                            _vm._l(
                                              _vm.insuranceList,
                                              function (item, index) {
                                                return _c("el-option", {
                                                  key: index,
                                                  attrs: {
                                                    label: item.shortName,
                                                    value: item.shortName,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "交强险到期日" } },
                                        [
                                          _c("el-date-picker", {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              "value-format":
                                                "yyyy-MM-dd HH:mm:ss",
                                              type: "date",
                                              placeholder: "请输入交强险到期日",
                                            },
                                            model: {
                                              value:
                                                _vm.form.risksData
                                                  .bzLastYearEndDate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form.risksData,
                                                  "bzLastYearEndDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.risksData.bzLastYearEndDate",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("el-row"),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-collapse",
                    {
                      staticStyle: { "margin-top": "10px" },
                      model: {
                        value: _vm.activeName3,
                        callback: function ($$v) {
                          _vm.activeName3 = $$v
                        },
                        expression: "activeName3",
                      },
                    },
                    [
                      _c(
                        "el-collapse-item",
                        {
                          staticClass: "collapse-item-style",
                          attrs: { name: "1" },
                        },
                        [
                          _c("template", { slot: "title" }, [
                            _c(
                              "div",
                              {
                                staticClass: "title",
                                staticStyle: { "line-height": "25px" },
                              },
                              [
                                _c("FormLabel", {
                                  attrs: { "form-label": "最近一次投保信息" },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm.form.insuranceList.length
                            ? _c(
                                "div",
                                [
                                  _vm._l(
                                    _vm.form.insuranceList,
                                    function (item, index) {
                                      return _c(
                                        "el-row",
                                        {
                                          key: index,
                                          staticStyle: { "margin-top": "10px" },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "last-year-information",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(item.riskTypeDisplay) +
                                                  "信息"
                                              ),
                                            ]
                                          ),
                                          _c("el-col", { attrs: { span: 8 } }, [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.riskTypeDisplay) +
                                                "保险公司：" +
                                                _vm._s(item.insCorpName) +
                                                " "
                                            ),
                                          ]),
                                          _c("el-col", { attrs: { span: 8 } }, [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.riskTypeDisplay) +
                                                "到期日：" +
                                                _vm._s(item.effectiveEndTime) +
                                                " "
                                            ),
                                          ]),
                                          _c("el-col", { attrs: { span: 8 } }, [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.riskTypeDisplay) +
                                                "保单号：" +
                                                _vm._s(item.policyNo) +
                                                " "
                                            ),
                                          ]),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  _c("el-row", [
                                    _c(
                                      "table",
                                      {
                                        staticStyle: {
                                          width: "100%",
                                          "margin-top": "10px",
                                        },
                                        attrs: { border: "1" },
                                      },
                                      [
                                        _c("colgroup", [
                                          _c("col", {
                                            staticStyle: { width: "25%" },
                                          }),
                                          _c("col", {
                                            staticStyle: { width: "25%" },
                                          }),
                                          _c("col", {
                                            staticStyle: { width: "25%" },
                                          }),
                                          _c("col", {
                                            staticStyle: { width: "25%" },
                                          }),
                                        ]),
                                        _c(
                                          "thead",
                                          {
                                            staticStyle: {
                                              "background-color": "#eeee",
                                            },
                                          },
                                          [
                                            _c(
                                              "tr",
                                              {
                                                staticStyle: {
                                                  "font-size": "13px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "col" } },
                                                  [_vm._v("险种险别")]
                                                ),
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "col" } },
                                                  [_vm._v("保额/限额(元)")]
                                                ),
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "col" } },
                                                  [_vm._v("保费(元)")]
                                                ),
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "col" } },
                                                  [_vm._v("不计免赔保额(元)")]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "tbody",
                                          [
                                            _vm._l(
                                              _vm.fetchBusinessRisks(),
                                              function (item, index) {
                                                return _c(
                                                  "tr",
                                                  { key: index },
                                                  [
                                                    _c("td", [
                                                      _c(
                                                        "span",
                                                        {
                                                          attrs: {
                                                            title:
                                                              item.riskkindName,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.riskkindName
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]),
                                                    _c("td", [
                                                      _c(
                                                        "span",
                                                        {
                                                          attrs: {
                                                            title: item.amount,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.amount)
                                                          ),
                                                        ]
                                                      ),
                                                    ]),
                                                    _c("td", [
                                                      _c(
                                                        "span",
                                                        {
                                                          attrs: {
                                                            title:
                                                              item.actualPremium,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.actualPremium
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]),
                                                    _c("td", [
                                                      _c(
                                                        "span",
                                                        {
                                                          attrs: {
                                                            title:
                                                              item.absoluteDeductibleAmount,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.absoluteDeductibleAmount
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]),
                                                  ]
                                                )
                                              }
                                            ),
                                            _c("tr", [
                                              _c(
                                                "td",
                                                {
                                                  staticStyle: {
                                                    "text-align": "center",
                                                  },
                                                  attrs: { colspan: "2" },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("商业险保费总计"),
                                                  ]),
                                                ]
                                              ),
                                              _c("td", [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.premiumTotal("BS")
                                                    )
                                                  ),
                                                ]),
                                              ]),
                                              _c("td"),
                                            ]),
                                            _c("tr", [
                                              _c(
                                                "td",
                                                {
                                                  staticStyle: {
                                                    "text-align": "center",
                                                  },
                                                  attrs: { colspan: "2" },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("交强险保费总计"),
                                                  ]),
                                                ]
                                              ),
                                              _c("td", [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.premiumTotal("BZ")
                                                    )
                                                  ),
                                                ]),
                                              ]),
                                              _c("td"),
                                            ]),
                                          ],
                                          2
                                        ),
                                      ]
                                    ),
                                  ]),
                                ],
                                2
                              )
                            : _c(
                                "div",
                                {
                                  staticStyle: {
                                    "line-height": "100px",
                                    "text-align": "center",
                                    color: "#909099",
                                  },
                                },
                                [_vm._v(" 暂无数据 ")]
                              ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-collapse",
                    {
                      staticStyle: { "margin-top": "10px" },
                      model: {
                        value: _vm.activeName4,
                        callback: function ($$v) {
                          _vm.activeName4 = $$v
                        },
                        expression: "activeName4",
                      },
                    },
                    [
                      _c(
                        "el-collapse-item",
                        {
                          staticClass: "collapse-item-style",
                          attrs: { name: "1" },
                        },
                        [
                          _c("template", { slot: "title" }, [
                            _c(
                              "div",
                              {
                                staticClass: "title",
                                staticStyle: { "line-height": "25px" },
                              },
                              [
                                _c("FormLabel", {
                                  attrs: { "form-label": "投保线索" },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm.form.insuredList.length
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "div",
                                    { staticClass: "table-btn-cls" },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "primary" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.save("forClues")
                                            },
                                          },
                                        },
                                        [_vm._v("保存")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form",
                                    {
                                      attrs: {
                                        model: {},
                                        "label-width": "120px",
                                        size: "mini",
                                      },
                                    },
                                    _vm._l(
                                      _vm.form.insuredList,
                                      function (item, index) {
                                        return _c(
                                          "div",
                                          { key: index },
                                          [
                                            _c(
                                              "el-row",
                                              {
                                                staticStyle: {
                                                  "margin-top": "10px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 10 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        attrs: {
                                                          label:
                                                            "" +
                                                            item.relationshipFlagDisplay,
                                                        },
                                                      },
                                                      [
                                                        _c("el-input", {
                                                          attrs: {
                                                            maxlength: "40",
                                                            placeholder:
                                                              "请输入" +
                                                              item.name,
                                                            clearable: "",
                                                          },
                                                          model: {
                                                            value: item.name,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "name",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.name",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 10 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        attrs: {
                                                          label:
                                                            item.relationshipFlagDisplay +
                                                            "手机号",
                                                        },
                                                      },
                                                      [
                                                        _c("el-input", {
                                                          attrs: {
                                                            maxlength: "11",
                                                            placeholder:
                                                              "请输入" +
                                                              item.relationshipFlagDisplay +
                                                              "手机号",
                                                            clearable: "",
                                                          },
                                                          model: {
                                                            value: item.mobile,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "mobile",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.mobile",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-row",
                                              [
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 10 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        attrs: {
                                                          label:
                                                            item.relationshipFlagDisplay +
                                                            "证件类型",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "el-select",
                                                          {
                                                            staticStyle: {
                                                              width: "100%",
                                                            },
                                                            attrs: {
                                                              placeholder:
                                                                "请选择" +
                                                                item.relationshipFlagDisplay +
                                                                "证件类型",
                                                              clearable: "",
                                                            },
                                                            model: {
                                                              value:
                                                                item.certificateType,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "certificateType",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item.certificateType",
                                                            },
                                                          },
                                                          _vm._l(
                                                            _vm.VI_CERTIFICATE_TYPE,
                                                            function (
                                                              item,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "el-option",
                                                                {
                                                                  key: index,
                                                                  attrs: {
                                                                    label:
                                                                      item.dictName,
                                                                    value:
                                                                      item.dictCode,
                                                                  },
                                                                }
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 10 } },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        attrs: {
                                                          label:
                                                            item.relationshipFlagDisplay +
                                                            "证件号码",
                                                        },
                                                      },
                                                      [
                                                        _c("el-input", {
                                                          attrs: {
                                                            maxlength: "25",
                                                            placeholder:
                                                              "请输入" +
                                                              item.relationshipFlagDisplay +
                                                              "证件号码",
                                                            clearable: "",
                                                          },
                                                          model: {
                                                            value:
                                                              item.certificateNo,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "certificateNo",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.certificateNo",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ],
                                1
                              )
                            : _c(
                                "div",
                                {
                                  staticStyle: {
                                    "line-height": "100px",
                                    "text-align": "center",
                                    color: "#909099",
                                  },
                                },
                                [_vm._v(" 暂无数据 ")]
                              ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-collapse",
                    {
                      staticStyle: { "margin-top": "10px" },
                      model: {
                        value: _vm.activeName5,
                        callback: function ($$v) {
                          _vm.activeName5 = $$v
                        },
                        expression: "activeName5",
                      },
                    },
                    [
                      _c(
                        "el-collapse-item",
                        {
                          staticClass: "collapse-item-style",
                          attrs: { name: "1" },
                        },
                        [
                          _c("template", { slot: "title" }, [
                            _c(
                              "div",
                              {
                                staticClass: "title",
                                staticStyle: { "line-height": "25px" },
                              },
                              [
                                _c("FormLabel", {
                                  attrs: { "form-label": "报价历史" },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "el-row",
                            { staticStyle: { "margin-top": "10px" } },
                            [
                              _c(
                                "el-table",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    size: "mini",
                                    border: "",
                                    data: _vm.form.proposal,
                                    "header-cell-style": {
                                      background: "#F7F7F7",
                                    },
                                    "highlight-current-row": "",
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      type: "index",
                                      label: "序号",
                                      width: "50",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "insCorpName",
                                      label: "保险公司",
                                      "show-overflow-tooltip": "",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "bzPremium",
                                      label: "交强险保费(元)",
                                      align: "right",
                                      "show-overflow-tooltip": "",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "bsPremium",
                                      label: "商业险保费(元)",
                                      align: "right",
                                      "show-overflow-tooltip": "",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "quoteTime",
                                      label: "报价时间",
                                      "show-overflow-tooltip": "",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "操作",
                                      align: "center",
                                      width: "100",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "text",
                                                  size: "mini",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.historyViewDetail(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("查看详情")]
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-collapse",
                    {
                      staticStyle: { "margin-top": "10px" },
                      model: {
                        value: _vm.activeName6,
                        callback: function ($$v) {
                          _vm.activeName6 = $$v
                        },
                        expression: "activeName6",
                      },
                    },
                    [
                      _c(
                        "el-collapse-item",
                        {
                          staticClass: "collapse-item-style",
                          attrs: { name: "1" },
                        },
                        [
                          _c("template", { slot: "title" }, [
                            _c(
                              "div",
                              {
                                staticClass: "title",
                                staticStyle: { "line-height": "25px" },
                              },
                              [
                                _c("FormLabel", {
                                  attrs: { "form-label": "跟进历史" },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "table-btn-cls" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: { click: _vm.customerTracks },
                                },
                                [_vm._v("跟进")]
                              ),
                            ],
                            1
                          ),
                          _c("CustomerTracks", {
                            attrs: { followHistory: _vm.form.followHistory },
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ]),
        _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
          _c("div", { staticClass: "footer-btn" }, [
            _c(
              "div",
              { staticClass: "footer-btn-list" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { size: "mini", type: "primary" },
                    on: { click: _vm.viewDetail },
                  },
                  [_vm._v("查看保险档案")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { size: "mini", type: "primary" },
                    on: { click: _vm.toQuote },
                  },
                  [_vm._v("保险报价")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { size: "mini", icon: "el-icon-back" },
                    on: { click: _vm.back },
                  },
                  [_vm._v("返回")]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showDialog,
            "close-on-click-modal": false,
            width: "540px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("跟进新增")]
              ),
            ]
          ),
          _c("FollowUpDialog", {
            attrs: { refresh: _vm.showDialog, customerId: _vm.customerId },
            on: { closePage: _vm.getDetails },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }