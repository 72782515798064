var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.listLoading,
          expression: "listLoading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: {},
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "540px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "detail",
                  staticStyle: { "margin-bottom": "-20px" },
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-width": "120px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 20 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "车系编号：", prop: "seriesNo" },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入车系编号",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.seriesNo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "seriesNo", $$v)
                                  },
                                  expression: "form.seriesNo",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 20 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "车系名称：",
                                prop: "seriesName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入车系名称",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.seriesName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "seriesName", $$v)
                                  },
                                  expression: "form.seriesName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 20 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "车系中文名称：",
                                prop: "seriesChineseName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入车系中文名称",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.seriesChineseName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "seriesChineseName", $$v)
                                  },
                                  expression: "form.seriesChineseName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 20 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "车型编号：", prop: "modelNo" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入车型编号",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.modelNo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "modelNo", $$v)
                                  },
                                  expression: "form.modelNo",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 20 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "车型名称：", prop: "modelName" },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入车型名称",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.modelName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "modelName", $$v)
                                  },
                                  expression: "form.modelName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 20 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "车型版本：",
                                prop: "gradecName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入车型版本",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.gradecName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "gradecName", $$v)
                                  },
                                  expression: "form.gradecName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 20 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "官方指导价：",
                                prop: "guidePrice",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入官方指导价",
                                  clearable: "",
                                },
                                on: {
                                  input: function ($event) {
                                    _vm.form.guidePrice = _vm.onInput(
                                      _vm.form.guidePrice,
                                      3,
                                      0,
                                      99999999
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.form.guidePrice,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "guidePrice", $$v)
                                  },
                                  expression: "form.guidePrice",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-check",
                type: "primary",
                plain: "",
                size: "mini",
              },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: { click: _vm.closePage },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }