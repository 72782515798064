import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import dict from '../../../mixins/dict';
import initHeight from '../../../mixins/initHeight';
import Pagination from '@/components/Pagination';
import repairApi from '@/api/repair';
export default {
  mixins: [initHeight, dict],
  components: {
    Pagination: Pagination
  },
  props: {
    refresh: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      listLoading: false,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      total: 0
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    getTemplatelist: function getTemplatelist() {
      var _this = this;
      repairApi.getTemplatelist().then(function (res) {
        if (res.code == 200) {
          _this.tableData = res.data ? res.data : [];
        }
      });
    },
    deleteConfigDict: function deleteConfigDict(index, row) {
      //删除配置字典
      this.tableData.splice(index, 1);
      // repairApi.delTemplate(row.templateConfigId).then(res => {
      //     this.$message({
      //         message: res.msg,
      //         type: 'success'
      //     });
      //     this.getTemplatelist()
      // })
    },
    addConfigDict: function addConfigDict() {
      //添加配置字典
      this.tableData.push({
        matchCode: "",
        matchName: "",
        matchValue: ""
      });
    },
    initData: function initData() {
      //初始化数据
      this.getTemplatelist();
    },
    loadData: function loadData() {//加载数据
    },
    save: function save() {
      var _this2 = this;
      //保存数据
      this.closePage();
      var configList = this.tableData;
      console.log(configList);
      repairApi.addTemplate(configList).then(function (res) {
        if (res.code == 200) {
          _this2.$message({
            message: res.msg,
            type: 'success'
          });
        }
      });
    },
    closePage: function closePage() {
      //关闭弹窗
      this.$emit('closePage');
    }
  }
};