/**
 * 精友车型同步
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 列表
   * @param {*} data 参数
   */
  getList: function getList(data) {
    return request.get(getUrl("/vehicle/sync/search", 14), {
      params: data
    });
  },
  /**
   * 查询精友用户列表
   */
  getJyAccountList: function getJyAccountList() {
    return request.get(getUrl("/jyVehicle/getAccountList", 14));
  },
  /**
   * 同步精友车型数据
   * @param {*} data 内容
   */
  syncJyVehiceData: function syncJyVehiceData(data) {
    return request.post(getUrl("/vehicle/sync/synchronousData", 14), data);
  },
  /**
   * 根据批次号查询同步精友车型数据
   * @param {*} data 批次号
   */
  getDetailByBatchNo: function getDetailByBatchNo(data) {
    return request.get(getUrl("/vehicle/sync/getSynchronousData", 14), {
      params: data
    });
  }
};
export default objApi;