var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.listLoading,
          expression: "listLoading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: {},
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "540px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "detail",
                  staticStyle: { "margin-bottom": "-20px" },
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-width": "100px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 20 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "车型编号：", prop: "modelNo" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入车型编号",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.modelNo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "modelNo", $$v)
                                  },
                                  expression: "form.modelNo",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 20 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "车型名称：", prop: "modelName" },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入车型名称",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.modelName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "modelName", $$v)
                                  },
                                  expression: "form.modelName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 20 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "新车价格：", prop: "newPrice" },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入新车价格",
                                  clearable: "",
                                },
                                on: {
                                  input: function ($event) {
                                    _vm.form.newPrice = _vm.onInput(
                                      _vm.form.newPrice,
                                      3,
                                      0,
                                      99999999
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.form.newPrice,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "newPrice", $$v)
                                  },
                                  expression: "form.newPrice",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 20 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "座位数：", prop: "seatCount" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入座位数",
                                  maxlength: "3",
                                  clearable: "",
                                },
                                on: {
                                  input: function ($event) {
                                    _vm.form.seatCount = _vm.onInput(
                                      _vm.form.seatCount,
                                      0
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.form.seatCount,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "seatCount", $$v)
                                  },
                                  expression: "form.seatCount",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 20 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "车辆年份：", prop: "year" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入车辆年份",
                                  maxlength: "4",
                                  clearable: "",
                                },
                                on: {
                                  input: function ($event) {
                                    _vm.form.year = _vm.onInput(
                                      _vm.form.year,
                                      0
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.form.year,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "year", $$v)
                                  },
                                  expression: "form.year",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 20 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "品牌：",
                                prop: "vehicleBrandId",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { placeholder: "请选择品牌" },
                                  on: { change: _vm.checkChange },
                                  model: {
                                    value: _vm.form.vehicleBrandId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "vehicleBrandId", $$v)
                                    },
                                    expression: "form.vehicleBrandId",
                                  },
                                },
                                _vm._l(_vm.options, function (item) {
                                  return _c("el-option", {
                                    key: item.vehicleBrandId,
                                    attrs: {
                                      label: item.brandName,
                                      value: item.vehicleBrandId,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 20 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "车系：",
                                prop: "vehicleBrandSeriesId",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { placeholder: "请选择车系" },
                                  model: {
                                    value: _vm.form.vehicleBrandSeriesId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "vehicleBrandSeriesId",
                                        $$v
                                      )
                                    },
                                    expression: "form.vehicleBrandSeriesId",
                                  },
                                },
                                _vm._l(_vm.carsOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.vehicleBrandSeriesId,
                                    attrs: {
                                      label: item.seriesName,
                                      value: item.vehicleBrandSeriesId,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-check",
                type: "primary",
                plain: "",
                size: "mini",
              },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: { click: _vm.closePage },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }