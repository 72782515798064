var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { margin: "0px 20px" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticStyle: { "background-color": "#fff", "margin-bottom": "20px" },
      },
      [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.hasImg && !_vm.underwritingConfirm,
                expression: "!hasImg&&!underwritingConfirm",
              },
            ],
            staticStyle: { "font-size": "14px", "line-height": "40px" },
          },
          [_vm._v(" 生成报价单图片后可以复制图片发送给客户或者保存到本机。 ")]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.hasImg && !_vm.underwritingConfirm,
                expression: "hasImg&&!underwritingConfirm",
              },
            ],
            staticStyle: {
              "font-size": "14px",
              "line-height": "40px",
              color: "red",
            },
          },
          [
            _vm._v(
              ' 图片已生成，您可以右键点击图片"复制"到与客户微信聊天的窗口或者将“图片另存为”保存到本机。 '
            ),
          ]
        ),
        _vm.underwritingConfirm
          ? _c(
              "div",
              {
                staticStyle: {
                  padding: "0px 10px",
                  "line-height": "40px",
                  "font-size": "16px",
                  display: "flex",
                  "justify-content": "space-between",
                  "align-items": "center",
                },
              },
              [
                _vm._m(0),
                _vm.cutDown > 0
                  ? _c("div", { staticStyle: { color: "red" } }, [
                      _vm._v("确认倒计时："),
                      _c("span", { staticStyle: { "font-weight": "700" } }, [
                        _vm._v(_vm._s(_vm.cutDown)),
                      ]),
                      _c("span", { staticStyle: { "font-size": "14px" } }, [
                        _vm._v("s"),
                      ]),
                    ])
                  : _vm._e(),
              ]
            )
          : _vm._e(),
        _c(
          "div",
          { staticStyle: { "max-height": "640px", "overflow-y": "auto" } },
          [
            !_vm.hasImg
              ? _c(
                  "div",
                  {
                    ref: "proposal",
                    staticStyle: { padding: "0px 10px 10px 10px" },
                    attrs: { id: "proposal" },
                  },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.underwritingConfirm,
                            expression: "!underwritingConfirm",
                          },
                        ],
                        staticStyle: {
                          display: "flex",
                          "font-size": "24px",
                          height: "46px",
                          "border-bottom": "2px solid #1890ff",
                        },
                      },
                      [
                        _c("div", { staticStyle: { "padding-top": "15px" } }, [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-size": "14px",
                                "font-weight": "600",
                              },
                            },
                            [
                              _vm._v(
                                "业务单号：" + _vm._s(_vm.proposalData.quoteNo)
                              ),
                            ]
                          ),
                        ]),
                        _vm._m(1),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.underwritingConfirm,
                            expression: "!underwritingConfirm",
                          },
                        ],
                        staticStyle: {
                          "font-size": "14px",
                          "line-height": "40px",
                          color: "#808080",
                        },
                      },
                      [
                        _vm._v(
                          " 报价日期: " +
                            _vm._s(_vm.proposalData.quoteTime) +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      "table",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { border: "1" },
                      },
                      [
                        _vm._m(2),
                        _c("tbody", [
                          _c("tr", [
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  "text-align": "left",
                                  "line-height": "36px",
                                },
                                attrs: { colspan: "2" },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "vertical-align": "middle",
                                      "font-size": "20px",
                                      display: "flex",
                                      "align-items": "center",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: {
                                        width: "30px",
                                        height: "30px",
                                      },
                                      attrs: { src: _vm.imgUrl, alt: "" },
                                    }),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "margin-left": "10px" },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.proposalData.insCorpName
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c("th", [_vm._v("投保城市")]),
                            _c("td", [
                              _c("span", [
                                _vm._v(_vm._s(_vm.proposalData.insCityName)),
                              ]),
                            ]),
                          ]),
                          _c("tr", [
                            _c("th", [_vm._v("车牌号")]),
                            _c("td", [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.proposalData.quoteVehicle &&
                                      _vm.proposalData.quoteVehicle.plateNo
                                      ? _vm.proposalData.quoteVehicle.plateNo
                                      : ""
                                  )
                                ),
                              ]),
                            ]),
                            _c("th", [_vm._v("车架号")]),
                            _c("td", [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.proposalData.quoteVehicle &&
                                      _vm.proposalData.quoteVehicle.vin
                                      ? _vm.proposalData.quoteVehicle.vin
                                      : ""
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                          _c("tr", [
                            _c("th", [_vm._v("发动机号")]),
                            _c("td", [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.proposalData.quoteVehicle &&
                                      _vm.proposalData.quoteVehicle.engineNo
                                      ? _vm.proposalData.quoteVehicle.engineNo
                                      : ""
                                  )
                                ),
                              ]),
                            ]),
                            _c("th", [_vm._v("注册日期")]),
                            _c("td", [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.proposalData.quoteVehicle &&
                                      _vm.proposalData.quoteVehicle.registerDate
                                      ? _vm.proposalData.quoteVehicle.registerDate.split(
                                          " "
                                        )[0]
                                      : ""
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                          _c("tr", [
                            _c("th", [_vm._v("品牌型号")]),
                            _c("td", { attrs: { colspan: "3" } }, [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.proposalData.quoteVehicle &&
                                      _vm.proposalData.quoteVehicle.modelName
                                      ? _vm.proposalData.quoteVehicle.modelName
                                      : ""
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                          _c("tr", [
                            _c("th", [_vm._v("行驶证车辆类型")]),
                            _c("td", [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.proposalData.quoteVehicle &&
                                      _vm.proposalData.quoteVehicle.vehicleType
                                      ? _vm.proposalData.quoteVehicle
                                          .vehicleType
                                      : ""
                                  )
                                ),
                              ]),
                            ]),
                            _c("th", [_vm._v("使用性质")]),
                            _c("td", [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.proposalData.quoteVehicle &&
                                      _vm.proposalData.quoteVehicle.useAttribute
                                      ? _vm.proposalData.quoteVehicle
                                          .useAttribute
                                      : ""
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                          _c("tr", [
                            _c("th", [_vm._v("车主名称")]),
                            _c("td", [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.proposalData.quoteVehicle &&
                                      _vm.proposalData.quoteVehicle.ownerName
                                      ? _vm.proposalData.quoteVehicle.ownerName
                                      : ""
                                  )
                                ),
                              ]),
                            ]),
                            _c("th", [_vm._v("投保人名称")]),
                            _c("td", [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.proposalData.applicant &&
                                      _vm.proposalData.applicant.name
                                      ? _vm.proposalData.applicant.name
                                      : ""
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                          _c("tr", [
                            _c("th", [_vm._v("车主性质")]),
                            _c("td", [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.proposalData.quoteVehicle &&
                                      _vm.proposalData.quoteVehicle.ownAttribute
                                      ? _vm.proposalData.quoteVehicle
                                          .ownAttribute
                                      : ""
                                  )
                                ),
                              ]),
                            ]),
                            _c("th", [_vm._v("被保人名称")]),
                            _c("td", [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.proposalData.insured &&
                                      _vm.proposalData.insured.name
                                      ? _vm.proposalData.insured.name
                                      : ""
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                          _vm.proposalData.hasBs
                            ? _c("tr", [
                                _c("th", [_vm._v("商业险期限")]),
                                _c("td", [
                                  _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.getEffectiveStartTime(
                                            _vm.proposalData.bsProduct
                                              .effectiveStartTime
                                          )
                                        ) +
                                        " 至 " +
                                        _vm._s(
                                          _vm.getEffectiveEndTime(
                                            _vm.proposalData.bsProduct
                                              .effectiveEndTime
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]),
                                _c("th", [_vm._v("商业险折扣率")]),
                                _c("td", [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.proposalData.bsProduct
                                          .discountFactor
                                      )
                                    ),
                                  ]),
                                ]),
                              ])
                            : _vm._e(),
                          _vm.proposalData.hasBz
                            ? _c("tr", [
                                _c("th", [_vm._v("交强险期限")]),
                                _c("td", [
                                  _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.getEffectiveStartTime(
                                            _vm.proposalData.bzProduct
                                              .effectiveStartTime
                                          )
                                        ) +
                                        " 至 " +
                                        _vm._s(
                                          _vm.getEffectiveEndTime(
                                            _vm.proposalData.bzProduct
                                              .effectiveEndTime
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]),
                                _c("th", [_vm._v("交强险折扣率")]),
                                _c("td", [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.proposalData.bzProduct
                                          .discountFactor
                                      )
                                    ),
                                  ]),
                                ]),
                              ])
                            : _vm._e(),
                        ]),
                      ]
                    ),
                    _vm.proposalData.hasBs
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              "font-size": "14px",
                              "line-height": "40px",
                              color: "#808080",
                            },
                          },
                          [_vm._v(" 商业险费用明细 ")]
                        )
                      : _vm._e(),
                    _vm.proposalData.hasBs
                      ? _c(
                          "table",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { border: "1" },
                          },
                          [
                            _c("col", { staticStyle: { width: "50%" } }),
                            _c("col", { staticStyle: { width: "30%" } }),
                            _c("col", { staticStyle: { width: "20%" } }),
                            _vm._m(3),
                            _vm.proposalData.hasBs &&
                            !_vm._.isEmpty(_vm.proposalData.bsProduct) &&
                            !_vm._.isEmpty(
                              _vm.proposalData.bsProduct
                                .coreProposalProductDutyVO
                            )
                              ? _c(
                                  "tbody",
                                  _vm._l(
                                    _vm.proposalData.bsProduct
                                      .coreProposalProductDutyVO,
                                    function (item, index) {
                                      return _c("tr", { key: index }, [
                                        _c("td", [
                                          _vm._v(_vm._s(item.riskkindName)),
                                        ]),
                                        _c(
                                          "td",
                                          {
                                            staticStyle: {
                                              "text-align": "right",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                  "justify-content": "flex-end",
                                                },
                                              },
                                              [
                                                item.riskkindCode == "A"
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          display: "flex",
                                                          "justify-content":
                                                            "space-between",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              "text-align":
                                                                "left",
                                                              display: "block",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.amount
                                                                  ? item.amount.toLocaleString()
                                                                  : 0
                                                              ) + " 元"
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              "text-align":
                                                                "right",
                                                              display: "block",
                                                              "margin-left":
                                                                "10px",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "绝对免赔额：" +
                                                                _vm._s(
                                                                  item.absoluteDeductibleAmount
                                                                    ? item.absoluteDeductibleAmount
                                                                    : 0
                                                                ) +
                                                                " 元"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                item.riskkindCode == "D" ||
                                                item.riskkindCode == "D02" ||
                                                (item.riskkindCode == "D03" &&
                                                  (!item.shareAmount ||
                                                    (_vm.proposalData &&
                                                      _vm._.toLower(
                                                        _vm.proposalData
                                                          .insCorpCode
                                                      ) == "picc" &&
                                                      !(
                                                        ["310100"].includes(
                                                          _vm.proposalData
                                                            .insCityCode
                                                        ) ||
                                                        _vm.proposalData.insCityCode.indexOf(
                                                          "34"
                                                        ) == 0 ||
                                                        _vm.proposalData.insCityCode.indexOf(
                                                          "35"
                                                        ) == 0 ||
                                                        _vm.proposalData.insCityCode.indexOf(
                                                          "43"
                                                        ) == 0 ||
                                                        _vm.proposalData.insCityCode.indexOf(
                                                          "44"
                                                        ) == 0 ||
                                                        _vm.proposalData.insCityCode.indexOf(
                                                          "36"
                                                        ) == 0 ||
                                                        _vm.proposalData.insCityCode.indexOf(
                                                          "46"
                                                        ) == 0 ||
                                                        _vm.proposalData.insCityCode.indexOf(
                                                          "51"
                                                        ) == 0 ||
                                                        _vm.proposalData.insCityCode.indexOf(
                                                          "64"
                                                        ) == 0
                                                      ))))
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          (item.unitAmount > 0
                                                            ? _vm.formatOption(
                                                                item.unitAmount
                                                              )
                                                            : "0") +
                                                            "元" +
                                                            " × " +
                                                            item.quantity +
                                                            "座 "
                                                        )
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                                _vm._.indexOf(
                                                  [
                                                    "FZ01",
                                                    "FZ02",
                                                    "FZ03",
                                                    "FZ04",
                                                  ],
                                                  item.riskkindCode
                                                ) != -1
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.getQuantity(
                                                            item
                                                          ) + "次"
                                                        )
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                                _vm._.indexOf(
                                                  [
                                                    "A04",
                                                    "A05",
                                                    "D",
                                                    "A",
                                                    "FZ01",
                                                    "FZ02",
                                                    "FZ03",
                                                    "FZ04",
                                                    "B05",
                                                    "C02",
                                                    "A02",
                                                    "A08",
                                                    "D02",
                                                    "D03",
                                                    "A11",
                                                    "C03",
                                                    "B04",
                                                    "D02",
                                                    "A03",
                                                  ],
                                                  item.riskkindCode
                                                ) == -1 ||
                                                (["C03", "B04"].includes(
                                                  item.riskkindCode
                                                ) &&
                                                  !item.shareAmount)
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.absoluteDeductible
                                                            ? item.absoluteDeductible +
                                                                "%"
                                                            : item.amount
                                                            ? _vm.formatOption(
                                                                item.amount
                                                              ) + "元"
                                                            : item.amount + "元"
                                                        )
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                                item.riskkindCode == "A04"
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          (item.unitAmount > 0
                                                            ? _vm.formatOption(
                                                                item.unitAmount
                                                              )
                                                            : "0") +
                                                            "元" +
                                                            " × " +
                                                            item.quantity +
                                                            "天"
                                                        )
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                                item.riskkindCode == "A05"
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          (item.absoluteDeductible ||
                                                            "0") + "%"
                                                        )
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                                item.riskkindCode == "B05" ||
                                                item.riskkindCode == "C02" ||
                                                item.riskkindCode == "A11"
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          (item.unitAmount > 0
                                                            ? _vm.formatOption(
                                                                item.unitAmount
                                                              )
                                                            : "0") + "元"
                                                        )
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                                item.riskkindCode == "A02"
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.unitAmount.toFixed(
                                                            2
                                                          ) + "元"
                                                        )
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                                ["C03", "D03", "B04"].includes(
                                                  item.riskkindCode
                                                ) &&
                                                item.shareAmount &&
                                                _vm.proposalData &&
                                                _vm._.toLower(
                                                  _vm.proposalData.insCorpCode
                                                ) != "picc"
                                                  ? _c("span", [
                                                      _vm._v("共享保额"),
                                                    ])
                                                  : _vm._e(),
                                                ["C03", "D03", "B04"].includes(
                                                  item.riskkindCode
                                                ) &&
                                                item.shareAmount &&
                                                _vm.proposalData &&
                                                _vm._.toLower(
                                                  _vm.proposalData.insCorpCode
                                                ) == "picc" &&
                                                (["310100"].includes(
                                                  _vm.proposalData.insCityCode
                                                ) ||
                                                  _vm.proposalData.insCityCode.indexOf(
                                                    "34"
                                                  ) == 0 ||
                                                  _vm.proposalData.insCityCode.indexOf(
                                                    "35"
                                                  ) == 0 ||
                                                  _vm.proposalData.insCityCode.indexOf(
                                                    "43"
                                                  ) == 0 ||
                                                  _vm.proposalData.insCityCode.indexOf(
                                                    "44"
                                                  ) == 0 ||
                                                  _vm.proposalData.insCityCode.indexOf(
                                                    "36"
                                                  ) == 0 ||
                                                  _vm.proposalData.insCityCode.indexOf(
                                                    "46"
                                                  ) == 0 ||
                                                  _vm.proposalData.insCityCode.indexOf(
                                                    "51"
                                                  ) == 0 ||
                                                  _vm.proposalData.insCityCode.indexOf(
                                                    "64"
                                                  ) == 0)
                                                  ? _c("span", [
                                                      _vm._v("共享保额"),
                                                    ])
                                                  : _vm._e(),
                                                item.riskkindCode == "A08"
                                                  ? _c("span", [
                                                      _vm._v("同车损保额"),
                                                    ])
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          {
                                            staticStyle: {
                                              "text-align": "right",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                item.actualPremium.toLocaleString()
                                              )
                                            ),
                                          ]
                                        ),
                                      ])
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                          ]
                        )
                      : _vm._e(),
                    _vm.proposalData.hasNon
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              "font-size": "14px",
                              "line-height": "40px",
                              color: "#808080",
                            },
                          },
                          [_vm._v(" 驾意险费用明细 ")]
                        )
                      : _vm._e(),
                    _vm.proposalData.hasNon &&
                    !_vm._.isEmpty(_vm.proposalData.nonProduct) &&
                    !_vm._.isEmpty(
                      _vm.proposalData.nonProduct.proposalRiskAccidentVO
                    )
                      ? _c(
                          "table",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { border: "1" },
                          },
                          _vm._l(
                            _vm.proposalData.nonProduct.proposalRiskAccidentVO,
                            function (item, index) {
                              return _c("tbody", { key: index }, [
                                _c("tr", [
                                  _c(
                                    "td",
                                    {
                                      staticStyle: {
                                        "text-align": "center",
                                        background: "#eee",
                                        "font-weight": "bold",
                                      },
                                      attrs: { colspan: "7" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(item.categoryName) +
                                          " - " +
                                          _vm._s(item.categoryTypeName) +
                                          " - " +
                                          _vm._s(item.productName) +
                                          ", 共:" +
                                          _vm._s(item.quantity) +
                                          "份,保费共:" +
                                          _vm._s(
                                            parseInt(item.quantity) *
                                              parseInt(item.unitPremium)
                                          ) +
                                          " 元 "
                                      ),
                                    ]
                                  ),
                                ]),
                                !_vm._.isEmpty(item.detailList)
                                  ? _c("tr", [
                                      _c(
                                        "td",
                                        {
                                          staticStyle: { padding: "0" },
                                          attrs: { colspan: "7" },
                                        },
                                        [
                                          _c(
                                            "table",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: { border: "0" },
                                            },
                                            [
                                              _vm._m(4, true),
                                              _c(
                                                "tbody",
                                                _vm._l(
                                                  item.detailList,
                                                  function (ditem, dindex) {
                                                    return _c(
                                                      "tr",
                                                      { key: dindex },
                                                      [
                                                        _c("td", [
                                                          _vm._v(
                                                            _vm._s(ditem.name)
                                                          ),
                                                        ]),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              "text-align":
                                                                "right",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                ditem.amount
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                              ])
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                    _c(
                      "table",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { border: "1" },
                      },
                      [
                        _c("col", { staticStyle: { width: "50%" } }),
                        _c("col", { staticStyle: { width: "30%" } }),
                        _c("col", { staticStyle: { width: "20%" } }),
                        _c("tbody", [
                          _vm._m(5),
                          _vm.proposalData.hasBs
                            ? _c("tr", [
                                _c("td", { attrs: { colspan: "3" } }, [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "justify-content": "space-between",
                                      },
                                    },
                                    [
                                      _c("span", [_vm._v("商业险保费合计")]),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.proposalData.bsProduct.actualPremium.toLocaleString()
                                          ) + "元"
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _vm.proposalData.hasBz
                            ? _c("tr", [
                                _c("td", { attrs: { colspan: "3" } }, [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "justify-content": "space-between",
                                      },
                                    },
                                    [
                                      _c("span", [_vm._v("交强险保费合计")]),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.proposalData.bzProduct.actualPremium.toLocaleString()
                                          ) + "元"
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _vm.proposalData.hasBz
                            ? _c("tr", [
                                _c("td", { attrs: { colspan: "3" } }, [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "justify-content": "space-between",
                                      },
                                    },
                                    [
                                      _c("span", [_vm._v("车船税税款合计")]),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.proposalData.bzProduct
                                              ? _vm.proposalData.bzProduct.vehicleTaxPremium.toLocaleString()
                                              : "0.00"
                                          ) + "元"
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _vm.proposalData.hasNon
                            ? _c("tr", [
                                _c("td", { attrs: { colspan: "3" } }, [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "justify-content": "space-between",
                                      },
                                    },
                                    [
                                      _c("span", [_vm._v("驾意险保费合计")]),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.proposalData.nonProduct.actualPremium.toLocaleString()
                                          ) + "元"
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _c("tr", [
                            _c("td", { attrs: { colspan: "3" } }, [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "justify-content": "space-between",
                                  },
                                },
                                [
                                  _c("span", [_vm._v("总保费及税款合计")]),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.proposalData.amount.toLocaleString()
                                      ) + "元"
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "font-size": "14px",
                          "line-height": "40px",
                          color: "#808080",
                        },
                      },
                      [
                        _vm._v(
                          " 本报价单仅作为投保参考，具体约定以保单及条款为准。 "
                        ),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
            _vm.hasImg
              ? _c(
                  "div",
                  {
                    ref: "proposalImg",
                    staticStyle: { padding: "0px 10px 10px 10px" },
                  },
                  [
                    _c("img", {
                      attrs: { src: _vm.imgData, alt: "", width: "100%" },
                    }),
                  ]
                )
              : _vm._e(),
          ]
        ),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "justify-content": "center",
              "line-height": "46px",
              height: "46px",
              padding: "8px 0px",
            },
          },
          [
            _vm.underwritingConfirm
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary", plain: "", size: "mini" },
                    on: { click: _vm.closeUnderwriting },
                  },
                  [_vm._v(" 去核保 ")]
                )
              : _vm._e(),
            !_vm.hasImg && !_vm.underwritingConfirm
              ? _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      plain: "",
                      size: "mini",
                      loading: _vm.loading,
                      disabled: _vm.loading,
                    },
                    on: { click: _vm.toImg },
                  },
                  [_vm._v(" 生成报价单图片 ")]
                )
              : _vm._e(),
            _c(
              "el-button",
              {
                attrs: { size: "mini", icon: "el-icon-close" },
                on: { click: _vm.closePage },
              },
              [_vm._v(" 关闭 ")]
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "span",
        {
          staticStyle: {
            "background-color": "red",
            color: "#fff",
            "font-size": "18px",
            padding: "5px",
            "margin-right": "5px",
            "font-weight": "600",
          },
        },
        [_vm._v("重要")]
      ),
      _c("span", { staticStyle: { "font-weight": "bold" } }, [
        _vm._v(
          "请仔细核对确认以下信息是否正确，如发现有误，及时修改信息重新提交报价，未修改将影响承保及理赔;"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          flex: "1",
          "text-align": "left",
          "padding-left": "10px",
          "padding-top": "11px",
        },
      },
      [_c("span", [_vm._v("车险报价单")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "20%" } }),
      _c("col", { staticStyle: { width: "30%" } }),
      _c("col", { staticStyle: { width: "20%" } }),
      _c("col", { staticStyle: { width: "30%" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", { staticStyle: { "background-color": "#eeee" } }, [
        _c("th", [_vm._v("险种/险别")]),
        _c("th", { staticStyle: { "text-align": "right" } }, [
          _vm._v("保额/限额"),
        ]),
        _c("th", { staticStyle: { "text-align": "right" } }, [
          _vm._v("保费(元)"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", { staticStyle: { "background-color": "#eeee" } }, [
        _c("th", { attrs: { scope: "col" } }, [_vm._v("险种名称")]),
        _c(
          "th",
          { staticStyle: { "text-align": "right" }, attrs: { scope: "col" } },
          [_vm._v("保险金额(元)")]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticStyle: { "background-color": "#eeee" } }, [
      _c("td", { staticStyle: { height: "20px" }, attrs: { colspan: "3" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }