var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.formLoading,
          expression: "formLoading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: {},
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "500px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "detail",
                  staticStyle: { "margin-bottom": "-20px" },
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-width": "110px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "机器编码：",
                                prop: "cameraCode",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入机器编码",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.cameraCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "cameraCode", $$v)
                                  },
                                  expression: "form.cameraCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.bizType === "OEM"
                        ? _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "所属机构：",
                                    prop: "orgCode",
                                  },
                                },
                                [
                                  _c("OemSelect", {
                                    attrs: {
                                      searchForm: _vm.orgForm,
                                      isContainOem: false,
                                    },
                                    on: { nodeClick: _vm.handleNodeClick },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.bizType === "DEALER"
                        ? _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "所属机构：",
                                    prop: "orgCode",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: { disabled: "" },
                                    model: {
                                      value: _vm.form.orgName,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "orgName", $$v)
                                      },
                                      expression: "form.orgName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "品牌型号：" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入品牌型号",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.cameraModel,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "cameraModel", $$v)
                                  },
                                  expression: "form.cameraModel",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "安装时间：" } },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  type: "date",
                                  placeholder: "请选择安装时间",
                                },
                                model: {
                                  value: _vm.form.installTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "installTime", $$v)
                                  },
                                  expression: "form.installTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "安装位置：" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入安装位置",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.installPosition,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "installPosition", $$v)
                                  },
                                  expression: "form.installPosition",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "图片资料：" } },
                            [
                              _c(
                                "el-upload",
                                {
                                  ref: "upload",
                                  attrs: {
                                    "before-remove": function () {
                                      return (_vm.formData = "")
                                    },
                                    action: "",
                                    "http-request": _vm.uploadFile,
                                    "file-list": _vm.fileList,
                                    accept: ".jpg,.gif,.png,.jpeg",
                                    "list-type": "text",
                                    "show-file-list": false,
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    { attrs: { size: "mini", plain: "" } },
                                    [_vm._v("上传图片")]
                                  ),
                                ],
                                1
                              ),
                              _vm.form.imageIdList.length
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "demo-image__preview img-box",
                                    },
                                    _vm._l(
                                      _vm.form.imgBox,
                                      function (item, index) {
                                        return _c("div", { key: index }, [
                                          _c(
                                            "div",
                                            { staticClass: "image" },
                                            [
                                              _c("el-image", {
                                                staticStyle: {
                                                  width: "100px",
                                                  height: "100px",
                                                },
                                                attrs: {
                                                  src: item.fileUrl,
                                                  "preview-src-list": [
                                                    item.fileUrl,
                                                  ],
                                                },
                                              }),
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-close clear",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleDelete(
                                                      item.fileStorageId,
                                                      index
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ])
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-check",
                type: "primary",
                plain: "",
                size: "mini",
              },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: { click: _vm.closePage },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }