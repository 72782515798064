var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "app-m-cls" },
      [
        _c(
          "div",
          { staticClass: "table-cls normal-btn-cls" },
          [
            _c(
              "el-drawer",
              {
                attrs: {
                  title: "",
                  visible: _vm.drawer,
                  modal: false,
                  "append-to-body": true,
                  size: "370px",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.drawer = $event
                  },
                },
              },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    staticStyle: { "margin-bottom": "15px" },
                    attrs: {
                      model: _vm.searchForm,
                      "label-width": "110px",
                      size: "mini",
                    },
                  },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "车主名称" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "40",
                                    placeholder: "请输入车主名称",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.searchForm.ownerName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchForm, "ownerName", $$v)
                                    },
                                    expression: "searchForm.ownerName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "车牌号" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "10",
                                    placeholder: "请输入车牌号",
                                    clearable: "",
                                  },
                                  on: { input: _vm.inputPlateNo },
                                  model: {
                                    value: _vm.searchForm.plateNo,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchForm, "plateNo", $$v)
                                    },
                                    expression: "searchForm.plateNo",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "车架号" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "17",
                                    placeholder: "请输入车架号",
                                    clearable: "",
                                  },
                                  on: { input: _vm.inputvin },
                                  model: {
                                    value: _vm.searchForm.vin,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchForm, "vin", $$v)
                                    },
                                    expression: "searchForm.vin",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "保险到期天数" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "请输入保险到期天数",
                                    oninput:
                                      "value=value.replace(/[^\\d]/g,'')",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.searchForm.daysInsuranceDue,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "daysInsuranceDue",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.daysInsuranceDue",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "无到期日" } },
                              [
                                _c(
                                  "el-checkbox-group",
                                  {
                                    on: { change: _vm.ChangeNoMaturityDate },
                                    model: {
                                      value: _vm.noMaturityDate,
                                      callback: function ($$v) {
                                        _vm.noMaturityDate = $$v
                                      },
                                      expression: "noMaturityDate",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        attrs: { label: "noBzLastYearEndDate" },
                                      },
                                      [_vm._v("交强险")]
                                    ),
                                    _c(
                                      "el-checkbox",
                                      {
                                        attrs: { label: "noBsLastYearEndDate" },
                                      },
                                      [_vm._v("商业险")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.bizType === "BROKER"
                      ? _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 22 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "所属机构" } },
                                  [
                                    _c("OrgSelect", {
                                      attrs: {
                                        searchForm: _vm.orgForm,
                                        isGetUserList: true,
                                      },
                                      on: {
                                        getCurrentOrgUsers: _vm.getUsers,
                                        nodeClick: _vm.nodeClick,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.bizType === "OEM"
                      ? _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 22 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "所属机构" } },
                                  [
                                    _c("OemSelect", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        searchForm: _vm.orgForm,
                                        isGetUserList: true,
                                        canClickArea: true,
                                      },
                                      on: {
                                        getCurrentOrgUsers: _vm.getUsers,
                                        nodeClick: _vm.nodeClick,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "客户经理" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      filterable: "",
                                      placeholder: "请选择客户经理",
                                      clearable: "",
                                    },
                                    on: { change: _vm.salemanNameChange },
                                    model: {
                                      value: _vm.searchForm.ownerBy,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.searchForm, "ownerBy", $$v)
                                      },
                                      expression: "searchForm.ownerBy",
                                    },
                                  },
                                  _vm._l(_vm.users, function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.userName,
                                        value: item.userId,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "车主手机号" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "40",
                                    placeholder: "请输入车主手机号",
                                    clearable: "",
                                    oninput:
                                      "value=value.replace(/[^\\d]/g,'')",
                                  },
                                  model: {
                                    value: _vm.searchForm.ownerMobile,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "ownerMobile",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.ownerMobile",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "客户类别" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      filterable: "",
                                      clearable: "",
                                      placeholder: "请选择客户类别",
                                    },
                                    model: {
                                      value: _vm.searchForm.renewalType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "renewalType",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.renewalType",
                                    },
                                  },
                                  _vm._l(
                                    _vm.renewalTypeList,
                                    function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.itemName,
                                          value: item.itemCode,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "商业险保司" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      placeholder: "请选择商业险保司",
                                      filterable: "",
                                      clearable: "",
                                    },
                                    model: {
                                      value:
                                        _vm.searchForm.bsLastYearInsCorpCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "bsLastYearInsCorpCode",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "searchForm.bsLastYearInsCorpCode",
                                    },
                                  },
                                  _vm._l(_vm.supplyers, function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.shortName,
                                        value: item.code,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "交强险保司" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      placeholder: "请选择交强险保司",
                                      filterable: "",
                                      clearable: "",
                                    },
                                    model: {
                                      value:
                                        _vm.searchForm.bzLastYearInsCorpCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "bzLastYearInsCorpCode",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "searchForm.bzLastYearInsCorpCode",
                                    },
                                  },
                                  _vm._l(_vm.supplyers, function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.shortName,
                                        value: item.code,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "保险到期日" } },
                              [
                                _c("DatePicker", {
                                  attrs: {
                                    pickerDateTime: _vm.overdueStartEndTime,
                                    noPickerOptions: true,
                                    startDate: "startOverdueDate",
                                    endDate: "endOverdueDate",
                                    width: "100%",
                                  },
                                  on: {
                                    pickerDateChange: function ($event) {
                                      return _vm.getPickersDate(
                                        "overdueStartEndTime",
                                        $event
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "注册日期" } },
                              [
                                _c("DatePicker", {
                                  attrs: {
                                    pickerDateTime: _vm.registerStartEndTime,
                                    noPickerOptions: true,
                                    startDate: "startRegisterDate",
                                    endDate: "endRegisterDate",
                                    width: "100%",
                                  },
                                  on: {
                                    pickerDateChange: function ($event) {
                                      return _vm.getPickersDate(
                                        "registerStartEndTime",
                                        $event
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "最新跟进状态" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请选择",
                                    },
                                    model: {
                                      value:
                                        _vm.searchForm.newestTrackStatusCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "newestTrackStatusCode",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "searchForm.newestTrackStatusCode",
                                    },
                                  },
                                  _vm._l(
                                    _vm.trackStatuslist,
                                    function (group, index) {
                                      return _c(
                                        "el-option-group",
                                        {
                                          key: index,
                                          attrs: { label: group.label },
                                        },
                                        _vm._l(group.options, function (item) {
                                          return _c("el-option", {
                                            key: item.itemCode,
                                            attrs: {
                                              label: item.itemName,
                                              value: item.itemCode,
                                            },
                                          })
                                        }),
                                        1
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "创建日期" } },
                              [
                                _c("DatePicker", {
                                  attrs: {
                                    pickerDateTime: _vm.createdStartEndTime,
                                    startDate: "startCreatedDate",
                                    endDate: "endCreatedDate",
                                    width: "220px",
                                  },
                                  on: {
                                    pickerDateChange: function ($event) {
                                      return _vm.getPickersDate(
                                        "createdStartEndTime",
                                        $event
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c("el-col", { attrs: { span: 24 } }, [
                          _c(
                            "div",
                            { staticClass: "searchBtnStyle" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    icon: "el-icon-refresh-left",
                                  },
                                  on: { click: _vm.resetSearch },
                                },
                                [_vm._v("重置")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    type: "primary",
                                    icon: "el-icon-search",
                                  },
                                  on: { click: _vm.searchData },
                                },
                                [_vm._v("查询")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticClass: "table-cls normal-btn-cls",
          },
          [
            _c(
              "el-card",
              { staticClass: "box-card", attrs: { shadow: "never" } },
              [
                _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                  _c("div", { staticClass: "search-box" }, [
                    _vm.bizType === "BROKER"
                      ? _c(
                          "div",
                          [
                            _c("span", [_vm._v("所属机构：")]),
                            _c("OrgSelect", {
                              staticStyle: {
                                "max-width": "150px",
                                display: "inline-block",
                              },
                              attrs: {
                                searchForm: _vm.orgForm,
                                isGetUserList: true,
                              },
                              on: {
                                getCurrentOrgUsers: _vm.getUsers,
                                nodeClick: _vm.nodeClick,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.bizType === "OEM"
                      ? _c(
                          "div",
                          [
                            _c("span", [_vm._v("所属机构：")]),
                            _c("OemSelect", {
                              staticStyle: {
                                "max-width": "150px",
                                display: "inline-block",
                              },
                              attrs: {
                                searchForm: _vm.orgForm,
                                isGetDealerList: true,
                                isGetUserList: true,
                                canClickArea: true,
                              },
                              on: {
                                getCurrentOrgUsers: _vm.getUsers,
                                nodeClick: _vm.nodeClick,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v("客户经理：")]),
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              filterable: "",
                              placeholder: "请选择客户经理",
                              clearable: "",
                            },
                            on: { change: _vm.salemanNameChange },
                            model: {
                              value: _vm.searchForm.ownerBy,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "ownerBy", $$v)
                              },
                              expression: "searchForm.ownerBy",
                            },
                          },
                          _vm._l(_vm.users, function (item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: {
                                label: item.userName,
                                value: item.userId,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v("车牌号：")]),
                        _c("el-input", {
                          attrs: {
                            maxlength: "10",
                            placeholder: "请输入车牌号",
                            clearable: "",
                          },
                          model: {
                            value: _vm.searchForm.plateNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "plateNo", $$v)
                            },
                            expression: "searchForm.plateNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "div",
                          {
                            staticStyle: { width: "65px", "font-size": "13px" },
                          },
                          [_vm._v("车架号：")]
                        ),
                        _c("el-input", {
                          attrs: {
                            maxlength: "17",
                            placeholder: "请输入车架号",
                            clearable: "",
                          },
                          on: { input: _vm.inputvin },
                          model: {
                            value: _vm.searchForm.vin,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "vin", $$v)
                            },
                            expression: "searchForm.vin",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v("创建日期：")]),
                        _c("DatePicker", {
                          attrs: {
                            pickerDateTime: _vm.createdStartEndTime,
                            startDate: "startCreatedDate",
                            endDate: "endCreatedDate",
                            width: "240px",
                          },
                          on: {
                            pickerDateChange: function ($event) {
                              return _vm.getPickersDate(
                                "createdStartEndTime",
                                $event
                              )
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "search-btn" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              icon: "el-icon-refresh-left",
                            },
                            on: { click: _vm.resetSearch },
                          },
                          [_vm._v("重置")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "primary",
                              icon: "el-icon-search",
                            },
                            on: { click: _vm.searchData },
                          },
                          [_vm._v("查询")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c(
                  "div",
                  { staticStyle: { position: "relative" } },
                  [
                    _c(
                      "el-tabs",
                      {
                        on: { "tab-click": _vm.handleTabClick },
                        model: {
                          value: _vm.distributionStatus,
                          callback: function ($$v) {
                            _vm.distributionStatus = $$v
                          },
                          expression: "distributionStatus",
                        },
                      },
                      [
                        _c(
                          "el-tab-pane",
                          { attrs: { name: "Assigned" } },
                          [
                            _c("template", { slot: "label" }, [
                              _vm._v(" 已分配(" + _vm._s(_vm.Assigned) + ") "),
                            ]),
                          ],
                          2
                        ),
                        _c(
                          "el-tab-pane",
                          { attrs: { label: "未分配", name: "Unassigned" } },
                          [
                            _c("template", { slot: "label" }, [
                              _vm._v(
                                " 未分配(" + _vm._s(_vm.Unassigned) + ") "
                              ),
                            ]),
                          ],
                          2
                        ),
                        _c(
                          "el-tab-pane",
                          { attrs: { label: "无效", name: "Invalid" } },
                          [
                            _c("template", { slot: "label" }, [
                              _vm._v(" 无效(" + _vm._s(_vm.Invalid) + ") "),
                            ]),
                          ],
                          2
                        ),
                        _c("el-tab-pane", {
                          attrs: { label: "全部", name: "all" },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "table-btn-cls",
                        staticStyle: {
                          position: "absolute",
                          right: "0",
                          bottom: "26px",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: { click: _vm.breakData },
                          },
                          [_vm._v("刷新")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              disabled: _vm._.isEmpty(_vm.multipleSelection),
                            },
                            on: { click: _vm.removeItems },
                          },
                          [_vm._v("批量删除")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.distribution()
                              },
                            },
                          },
                          [_vm._v("分配")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: {
                              click: function ($event) {
                                _vm.showAddDialog = true
                              },
                            },
                          },
                          [_vm._v("新增")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: { click: _vm.customerImport },
                          },
                          [_vm._v("导入")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: { click: _vm.exportFn },
                          },
                          [_vm._v("导出")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: {
                              click: function ($event) {
                                _vm.drawer = true
                              },
                            },
                          },
                          [_vm._v("搜索")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-table",
                  {
                    ref: "multipleTable",
                    staticStyle: { width: "100%" },
                    attrs: {
                      size: "mini",
                      border: "",
                      data: _vm.tableData,
                      height: _vm.tableHeightS,
                      "header-cell-style": { background: "#F7F7F7" },
                      "highlight-current-row": "",
                    },
                    on: {
                      "row-dblclick": _vm.dbSelected,
                      "selection-change": _vm.handleSelectionChange,
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        type: "selection",
                        width: "55",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "plateNo",
                        label: "车牌号",
                        width: "110",
                        "show-overflow-tooltip": "",
                        fixed: "left",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(scope.row.plateNo || "未上牌") +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "vin",
                        label: "车架号",
                        width: "180",
                        "show-overflow-tooltip": "",
                        fixed: "left",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "ownerName",
                        label: "车主名称",
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                        fixed: "left",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "ownerMobile",
                        label: "车主手机号",
                        width: "110",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    scope.row.ownerMobile
                                      ? scope.row.ownerMobile.replace(
                                          /^(.{3})(?:\d+)(.{2})$/,
                                          "$1******$2"
                                        )
                                      : ""
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "bsLastYearEndDate",
                        label: "商业险到期日",
                        "min-width": "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "",
                        label: "距商业险到期天数",
                        "min-width": "130",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(scope.row.bsOverDueDays)),
                              ]),
                              scope.row.bsOverDueDays &&
                              scope.row.bsEndDateAutoFixFlag
                                ? _c(
                                    "span",
                                    { staticStyle: { padding: "0 3px" } },
                                    [
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            effect: "dark",
                                            content:
                                              "根据历史保单时间推算: " +
                                              (scope.row.adjBsLastYearEndDate
                                                ? scope.row.adjBsLastYearEndDate.split(
                                                    " "
                                                  )[0]
                                                : "-"),
                                            placement: "top",
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-warning",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "bsLastYearInsCorpName",
                        label: "商业险保司名称",
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "bzLastYearEndDate",
                        label: "交强险到期日",
                        "min-width": "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "",
                        label: "距交强险到期天数",
                        "min-width": "130",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(scope.row.bzOverDueDays)),
                              ]),
                              scope.row.bzOverDueDays &&
                              scope.row.bzEndDateAutoFixFlag
                                ? _c(
                                    "span",
                                    { staticStyle: { padding: "0 3px" } },
                                    [
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            effect: "dark",
                                            content:
                                              "根据历史保单时间推算: " +
                                              (scope.row.adjBzLastYearEndDate
                                                ? scope.row.adjBzLastYearEndDate.split(
                                                    " "
                                                  )[0]
                                                : "-"),
                                            placement: "top",
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-warning",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "bzLastYearInsCorpName",
                        label: "交强险保司名称",
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "ownerCertificateNo",
                        label: "车主证件号",
                        width: "180",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    scope.row.ownerCertificateNo
                                      ? scope.row.ownerCertificateNo.replace(
                                          /^(.{10})(?:\d+)(.{2})$/,
                                          "$1******$2"
                                        )
                                      : ""
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "modelName",
                        label: "品牌型号",
                        "min-width": "200",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "engineNo",
                        label: "发动机号",
                        width: "100",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "registerDate",
                        label: "注册日期",
                        width: "110",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    scope.row.registerDate &&
                                      scope.row.registerDate.split(" ")[0]
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "sellDate",
                        label: "车销日期",
                        width: "110",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    scope.row.sellDate &&
                                      scope.row.sellDate.split(" ")[0]
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "newestTrackClassifyName",
                        label: "最新跟进分类",
                        width: "100",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "newestTrackStatusName",
                        label: "最新跟进状态",
                        width: "100",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "newestTrackContent",
                        label: "最新跟进内容",
                        width: "100",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "newestTrackTime",
                        label: "最新跟进时间",
                        "min-width": "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "下次跟进时间",
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    scope.row.newestSubscribeTime &&
                                      scope.row.newestSubscribeTime.split(
                                        " "
                                      )[0]
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "distinguishName",
                        label: "网点客户类型",
                        "min-width": "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "customerSysTypeDisplay",
                        label: "系统客户类型",
                        "min-width": "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "ownerByName",
                        label: "跟进人",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "renewalTypeDisplay",
                        label: "客户类别",
                        "min-width": "110",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "sourceDisplay",
                        label: "客户来源",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "ownerProvinceName",
                        label: "所在地区",
                        width: "180",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (scope.row.ownerProvinceName || "") +
                                      "" +
                                      (scope.row.ownerCityName || "") +
                                      "" +
                                      (scope.row.ownerDistrictName || "")
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "ownerByName",
                        label: "客户经理",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "orgName",
                        label: "所属机构",
                        width: "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "createdTime",
                        label: "创建时间",
                        "min-width": "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "modifiedTime",
                        label: "更新时间",
                        "min-width": "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "操作",
                        align: "center",
                        width: "140",
                        fixed: "right",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.viewDetail(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("查看")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.distribution(scope.row, false)
                                    },
                                  },
                                },
                                [_vm._v("分配")]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c("pagination", {
                  attrs: {
                    total: _vm.total,
                    page: _vm.listQuery.pageNum,
                    limit: _vm.listQuery.pageSize,
                  },
                  on: {
                    "update:page": function ($event) {
                      return _vm.$set(_vm.listQuery, "pageNum", $event)
                    },
                    "update:limit": function ($event) {
                      return _vm.$set(_vm.listQuery, "pageSize", $event)
                    },
                    pagination: function ($event) {
                      return _vm.loadData()
                    },
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-dialog",
          {
            staticClass: "pageDialog",
            attrs: {
              visible: _vm.showCustomerDialog,
              "close-on-click-modal": false,
              width: "80%",
              "append-to-body": true,
            },
            on: {
              "update:visible": function ($event) {
                _vm.showCustomerDialog = $event
              },
              handleClose: function ($event) {
                _vm.showDistributeDialog = false
              },
            },
          },
          [
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "title" },
                slot: "title",
              },
              [
                _c(
                  "span",
                  {
                    staticStyle: { "font-size": "15px", "font-weight": "700" },
                  },
                  [_vm._v("导入客户")]
                ),
              ]
            ),
            _c("CustomerImportListDialog", {
              attrs: {
                currentType: "customerResource",
                refresh: _vm.showCustomerDialog,
                bizType: _vm.bizType,
              },
              on: { closePage: _vm.loadData },
            }),
          ],
          1
        ),
        _c(
          "el-dialog",
          {
            staticClass: "pageDialog",
            attrs: {
              visible: _vm.showDistributionDialog,
              "close-on-click-modal": false,
              width: "540px",
              "append-to-body": true,
            },
            on: {
              "update:visible": function ($event) {
                _vm.showDistributionDialog = $event
              },
              handleClose: function ($event) {
                _vm.showDistributeDialog = false
              },
            },
          },
          [
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "title" },
                slot: "title",
              },
              [
                _c(
                  "span",
                  {
                    staticStyle: { "font-size": "15px", "font-weight": "700" },
                  },
                  [_vm._v("分配")]
                ),
              ]
            ),
            _c("CluesDistribution", {
              attrs: {
                refresh: _vm.showDistributionDialog,
                opType: _vm.opType,
                customerIdArr: _vm.customersIds,
              },
              on: { closePage: _vm.loadData },
            }),
          ],
          1
        ),
        _c(
          "el-dialog",
          {
            staticClass: "pageDialog",
            attrs: {
              visible: _vm.showAddDialog,
              "close-on-click-modal": false,
              width: "950px",
              "append-to-body": true,
            },
            on: {
              "update:visible": function ($event) {
                _vm.showAddDialog = $event
              },
              handleClose: function ($event) {
                _vm.showAddDialog = false
              },
            },
          },
          [
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "title" },
                slot: "title",
              },
              [
                _c(
                  "span",
                  {
                    staticStyle: { "font-size": "15px", "font-weight": "700" },
                  },
                  [_vm._v("新增")]
                ),
              ]
            ),
            _c("AddDialog", {
              attrs: { refresh: _vm.showAddDialog },
              on: { closePage: _vm.loadData },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }