/**
 * 运维管理接口
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  // **********************************精友用户配置接口*****************************
  /**
   * 查询精友用户列表
   * @param {*} data 内容
   */
  getJyAccountList: function getJyAccountList(data) {
    return request.get(getUrl("/jyVehicle/getJyAccountList", 14), {
      params: data
    });
  },
  /**
   * 新增精友用户
   * @param {*} data 内容
   */
  addJyAccount: function addJyAccount(data) {
    return request.post(getUrl("/jyVehicle/createAccount", 14), data);
  },
  /**
   * 修改精友用户
   * @param {*} data 内容
   * @param {*} id
   */
  editJyAccount: function editJyAccount(id, data) {
    return request.put(getUrl("/jyVehicle/updateAccount?id=".concat(id), 14), data);
  },
  /**
   * 删除精友用户
   * @param {*} ids
   */
  deleteJyAccount: function deleteJyAccount(ids) {
    return request.delete(getUrl("/jyVehicle/deleteAccount?ids=".concat(ids), 14));
  },
  // **********************************精友车型接口*****************************
  /**
   * 查询精友车型列表
   * @param {*} data 内容
   */
  getJyVehicleList: function getJyVehicleList(data) {
    return request.get(getUrl("/jyVehicle/searchJyVehicle", 14), {
      params: data
    });
  },
  /**
   * 新增精友车型
   * @param {*} data 内容
   */
  addJyVehicle: function addJyVehicle(data) {
    return request.post(getUrl("/jyVehicle/createJyVehicle", 14), data);
  },
  /**
   * 修改精友车型
   * @param {*} data 内容
   * @param {*} id
   */
  editJyVehicle: function editJyVehicle(id, data) {
    return request.put(getUrl("/jyVehicle/updateJyVehicle?id=".concat(id), 14), data);
  },
  /**
   * 删除精友用户
   * @param {*} ids
   */
  deleteJyVehicle: function deleteJyVehicle(ids) {
    return request.delete(getUrl("/jyVehicle/deleteJyVehicle?ids=".concat(ids), 14));
  },
  /**
   * 查询PICC车型列表
   * @param {*} data 内容
   */
  getPICCVehicleList: function getPICCVehicleList(data) {
    return request.get(getUrl("/piccVehicleMode/search", 14), {
      params: data
    });
  },
  /**
   * 新增PICC车型
   * @param {*} data 内容
   */
  addPiccVehicle: function addPiccVehicle(data) {
    return request.post(getUrl("/piccVehicleMode/create", 14), data);
  },
  /**
   * 修改PICC车型
   * @param {*} data 内容
   * @param {*} id
   */
  editPiccVehicle: function editPiccVehicle(id, data) {
    return request.put(getUrl("/piccVehicleMode/update?id=".concat(id), 14), data);
  },
  /**
   * 删除PICC车型
   * @param {*} ids
   */
  deletePiccVehicle: function deletePiccVehicle(ids) {
    return request.delete(getUrl("/piccVehicleMode/delete?ids=".concat(ids), 14));
  }
};
export default objApi;