/**
 * OCR识别功能接口
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  // **********************************OCR*****************************
  /**
   * 获取OCR供应商配置列表
   * @param {*} data 内容
   */
  getOCRSupplierList: function getOCRSupplierList(data) {
    return request.get(getUrl("/functionsupplierorg/search", 17), {
      params: data
    });
  },
  /**
   * 获取供应商设置
   * @param {*} data 内容
   */
  getSupplierSchema: function getSupplierSchema(data) {
    return request.get(getUrl("/configSchema/getSupplierSchema", 17), {
      params: data
    });
  },
  /**
   * 新建供应商配置
   * @param {*} data 内容
   */
  createSupplierORG: function createSupplierORG(data) {
    return request.post(getUrl("/functionsupplierorg/create", 17), data);
  },
  /**
   * 供应商配置详情
   * @param {*} data 内容
   */
  getSupplierORGDetail: function getSupplierORGDetail(id) {
    return request.get(getUrl("/functionsupplierorg/get?id=".concat(id), 17));
  },
  /**
   * 编辑供应商配置
   * @param {*} data 内容
   */
  updateSupplierORG: function updateSupplierORG(data) {
    return request.post(getUrl("/functionsupplierorg/update", 17), data);
  },
  /**
   * 删除供应商配置
   * @param {*} data 内容
   */
  batchDeleteSupplierORG: function batchDeleteSupplierORG(ids) {
    return request.post(getUrl("/functionsupplierorg/batchDelete?ids=".concat(ids), 17));
  },
  /**
   * 获取供应商配置不分页列表
   * @param {*} data 内容
   */
  getSupplierOrgList: function getSupplierOrgList() {
    return request.get(getUrl("/functionsupplierorg/getSupplierOrgList", 17));
  },
  /**
   * 获取供应商配置和组织机构关联列表
   * @param {*} data 内容
   */
  getOCRWidthORGList: function getOCRWidthORGList(data) {
    return request.get(getUrl("/functionsupplierorgdetail/search", 17), {
      params: data
    });
  },
  /**
   * 创建供应商配置和组织机构关联
   * @param {*} data 内容
   */
  createOCRWidthORG: function createOCRWidthORG(data) {
    return request.post(getUrl("/functionsupplierorgdetail/create", 17), data);
  },
  /**
   * 查询一条供应商配置和组织机构关联
   * @param {*} data 内容
   */
  getOCRWidthORG: function getOCRWidthORG(id) {
    return request.get(getUrl("/functionsupplierorgdetail/get?id=".concat(id), 17));
  },
  /**
   * 根据机构编码查询对应的OCR识别功能
   * @param {*} data 内容
   */
  getOrgCodeConfigurationList: function getOrgCodeConfigurationList(code) {
    return request.get(getUrl("/functionsupplierorgdetail/getOrgCodeConfigurationList?orgCode=".concat(code), 17));
  },
  /**
   * ocr识别接口
   * @param {*} data 内容
   */
  OCRdoOcr: function OCRdoOcr(data) {
    return request.post(getUrl("/ocr/doOcr", 17), data);
  },
  /**
   * 批量删除供应商配置和组织机构关联
   * @param {*} ids
   */
  deleteOCRWidthORG: function deleteOCRWidthORG(ids) {
    return request.post(getUrl("/functionsupplierorgdetail/batchDelete?ids=".concat(ids), 17));
  },
  /**
   * 更新供应商配置和组织机构关联
   * @param {*} data
   */
  updateOCRWidthORG: function updateOCRWidthORG(data) {
    return request.post(getUrl("/functionsupplierorgdetail/updateAndReturn", 17), data);
  },
  /**
  * 识别记录列表
  * @param {*} pageNum 当前页
  * @param {*} pageSize 每页显示条数
  */
  getRecordList: function getRecordList(data) {
    return request.get(getUrl("/functioninvokerecord/search", 17), {
      params: data
    });
  },
  /**
   * 识别记录详情数据
   * @param {*} data 内容
   */
  getDetail: function getDetail(id) {
    return request.get(getUrl("/functioninvokerecord/get?id=".concat(id), 17));
  },
  /**
   * 统计分页报表
   * @param {*} data 内容
   */
  getSearchTotal: function getSearchTotal(data) {
    return request.get(getUrl("/functioninvokerecord/searchTotal", 17), {
      params: data
    });
  },
  /**
   * 客户资源-详情-客户影像
   * @param {*} data 内容
   */
  getCustomerIdentificationRecord: function getCustomerIdentificationRecord(data) {
    return request.get(getUrl("/coreCustomer/file/search", 1), {
      params: data
    });
  },
  /**
   * 创建OCR识别地市转换
   * @param {*} data 内容
   */
  createOCRCityConfig: function createOCRCityConfig(data) {
    return request.post(getUrl("/ocr/cityConfig/create", 17), data);
  },
  /**
   * 查询OCR识别地市转换
   * @param {*} data 内容
   */
  searchOCRCityConfig: function searchOCRCityConfig(data) {
    return request.get(getUrl("/ocr/cityConfig/search", 17), {
      params: data
    });
  },
  /**
   * 创建OCR识别地市转换
   * @param {*} data 内容
   */
  deleteOCRCityConfig: function deleteOCRCityConfig(id) {
    return request.post(getUrl("/ocr/cityConfig/delete?id=".concat(id), 17));
  }
};
export default objApi;