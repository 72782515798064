import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import Pagination from '@/components/Pagination';
import operateManageApi from '@/api/operateManage';
import PICCVehicleManagementDialog from './dialog/piccVehicleManagementDialog';
import { commonExport } from '@/utils/common';
export default {
  name: 'PICCVehicleManagement',
  components: {
    Pagination: Pagination,
    PICCVehicleManagementDialog: PICCVehicleManagementDialog
  },
  mixins: [initHeight],
  data: function data() {
    return {
      searchForm: {},
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      loading: false,
      showDialog: false,
      opType: '',
      currentObj: {},
      multipleSelection: []
    };
  },
  created: function created() {
    this.loadData();
  },
  methods: {
    batchDelete: function batchDelete(item) {
      var _this = this;
      //删除
      var ids = [];
      if (!_.isEmpty(item)) {
        ids.push(item.piccVehicleModelId);
      } else {
        if (!_.isEmpty(this.multipleSelection)) {
          ids = _.map(this.multipleSelection, 'piccVehicleModelId');
        }
        ;
      }
      ;
      this.$confirm('您确定要删除么？', {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        operateManageApi.deletePiccVehicle(ids).then(function (res) {
          if (res.code === 200) {
            _this.$message.success(res.msg);
            _this.loadData();
          }
          ;
        });
      });
    },
    //编辑
    edit: function edit(type, item) {
      this.opType = type;
      this.showDialog = true;
      this.currentObj = item;
    },
    //新增
    add: function add(type) {
      this.opType = type;
      this.showDialog = true;
    },
    // 导出
    exportFn: function exportFn() {
      var exporParams = {
        total: this.total,
        fileName: '人保车型管理',
        exportUrl: '/vi-connect-configuration-service/piccVehicleMode/search',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    breakData: function breakData() {
      // this.resetSearch();
      this.loadData();
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {};
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    //获取列表数据
    loadData: function loadData() {
      var _this2 = this;
      this.loading = true;
      this.showDialog = false;
      var data = _objectSpread(_objectSpread({}, this.listQuery), this.searchForm);
      operateManageApi.getPICCVehicleList(data).then(function (res) {
        _this2.loading = false;
        if (res.code === 200) {
          _this2.tableData = res.data.list;
          _this2.total = res.data.page.recordsTotal;
        }
        ;
      }).catch(function (err) {
        _this2.loading = false;
      });
    },
    // 复选框数据
    handleSelectionChange: function handleSelectionChange(val) {
      //列表行选择事件
      this.multipleSelection = val;
    }
  }
};