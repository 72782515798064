import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.join.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '../../mixins/initHeight';
import dict from '../../mixins/dict';
import Pagination from '@/components/Pagination';
import OrgSelect from '@/components/OrgSelect';
import business from '@/api/insuranceBusiness';
import PopupWindow from './dialog/popupWindow';
import OemSelect from '@/components/OemSelect';
import { getSupplyers, commonExport } from '@/utils/common';
export default {
  name: 'ServiceOpeningDetails',
  components: {
    Pagination: Pagination,
    OrgSelect: OrgSelect,
    PopupWindow: PopupWindow,
    OemSelect: OemSelect
  },
  mixins: [initHeight, dict],
  computed: {},
  data: function data() {
    return {
      drawer: false,
      searchForm: {
        applyStatus: 'AUDIT_SUCCEEDED',
        dealerCode: ''
      },
      orgForm: {
        salesOrgCode: '',
        salesOrgName: '',
        salesOrgType: ''
      },
      total: 0,
      listLoading: true,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      startEndTime: null,
      highlightCurrent: true,
      insCorpItem: {},
      supplyers: []
    };
  },
  mounted: function mounted() {
    //保司
    this.supplyers = getSupplyers();
    this.loadData();
  },
  methods: {
    nodeClick: function nodeClick(val) {
      this.searchForm.areaType = val.salesOrgType;
      this.searchForm.areaNo = val.salesOrgType ? val.salesOrgCode : '';
      this.searchForm.dealerCode = val.salesOrgType ? this.searchForm.dealerCode : val.salesOrgCode;
    },
    //导出
    exportFn: function exportFn() {
      var exporParams = {
        total: this.total,
        limitNum: 200000,
        fileName: '业务开通明细导出',
        exportUrl: '/ps-ftms-service/business/page/admin',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    // 查看
    toView: function toView(type, item) {
      this.$router.push({
        path: '/submitBusiness',
        query: {
          applyId: item.applyId,
          name: type,
          applyStatus: item.applyStatus,
          insCorpCode: item.insCorpCode
        }
      });
    },
    //获取列表数据
    loadData: function loadData() {
      var _this = this;
      this.listLoading = true;
      business.managementList(this.listQuery.pageNum, this.listQuery.pageSize, this.searchForm).then(function (res) {
        if (res.code === 200) {
          _this.tableData = res.data.list;
          if (res.data.summaryData) {
            _this.summaryData = res.data.summaryData;
          }
          _this.total = res.data.page.recordsTotal;
        }
      });
    },
    cityData: function cityData(row) {
      var city = [];
      if (row.provinceName) {
        city.push(row.provinceName);
        if (row.cityName) {
          city.push(row.cityName);
          if (row.districtName) {
            city.push(row.districtName);
          }
        }
      }
      return city.join('-');
    },
    // 是否启用
    enableChange: function enableChange(val) {
      var _this2 = this;
      this.$confirm("\u60A8\u786E\u5B9A".concat(val.channelSetStatus == 'CSS01' ? '已检测' : '未检测', "\u5168\u90E8\u6E20\u9053\u8BBE\u7F6E\uFF1F"), {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        type: 'warning'
      }).then(function () {
        if (val.channelSetStatus == 'CSS01') {
          val.channelSetStatus = 'CSS02';
        } else {
          val.channelSetStatus = 'CSS01';
        }
        business.updateChannel(val.applyId, val.channelSetStatus).then(function (res) {
          if (res.code === 200) {
            _this2.$message.success(res.msg);
            _this2.loadData();
          }
        });
      }).catch(function () {});
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {
        applyStatus: this.searchForm.applyStatus,
        dealerCode: ''
      };
      this.orgForm = {
        salesOrgName: '全部',
        salesOrgType: '',
        salesOrgCode: ''
      };
    },
    //搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    // 刷新
    breakData: function breakData() {
      // this.resetSearch();
      this.loadData();
    }
  }
};