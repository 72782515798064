import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import storageServiceRequest from "@/api/storageService";
import dict from '@/mixins/dict';
export default {
  props: {
    itemId: {
      type: Number,
      default: null
    },
    opType: {
      type: String,
      default: ""
    },
    refresh: {
      type: Boolean,
      default: true
    }
  },
  mixins: [dict],
  data: function data() {
    return {
      form: {
        accessKey: '',
        category: '',
        remarks: '',
        secret: '',
        storageAddress: '',
        storageName: '',
        storagePublicAddress: '',
        storagePublicAddressSupportSsl: false,
        usedSelfPublicAddress: false
      },
      loading: false,
      categoryList: [{
        categoryCode: 'ALI',
        categoryName: '阿里云OSS'
      }, {
        categoryCode: 'TENCENT',
        categoryName: '腾讯云OSS'
      }, {
        categoryCode: 'MIN_IO',
        categoryName: 'MinIO OSS'
      }, {
        categoryCode: 'QINIU',
        categoryName: '七牛云OSS'
      }, {
        categoryCode: 'HUAWEI',
        categoryName: '华为云OBS'
      }],
      formRules: {
        accessKey: [{
          required: true,
          message: '请输入存储访问Key',
          trigger: 'blur'
        }],
        category: [{
          required: true,
          message: '请选择存储平台名称',
          trigger: 'change'
        }],
        secret: [{
          required: true,
          message: '请输入存储访问密钥',
          trigger: 'blur'
        }],
        storageAddress: [{
          required: true,
          message: '请输入存储地址',
          trigger: 'blur'
        }],
        storageName: [{
          required: true,
          message: '请输入存储桶名称',
          trigger: 'blur'
        }],
        storagePublicAddress: [{
          required: true,
          message: '请输入公网下载地址',
          trigger: 'blur'
        }]
      }
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        if (this.opType != 'add') {
          this.loadData();
        } else {
          this.form = {
            accessKey: '',
            category: '',
            remarks: '',
            secret: '',
            storageAddress: '',
            storageName: '',
            storagePublicAddress: '',
            storagePublicAddressSupportSsl: false,
            usedSelfPublicAddress: false
          };
          this.$refs['detail'].resetFields();
        }
      }
    }
  },
  created: function created() {
    if (this.opType == 'edit') {
      this.loadData();
    }
  },
  mounted: function mounted() {},
  methods: {
    // 获取详情
    loadData: function loadData() {
      var _this = this;
      if (!this.itemId) return;
      this.loading = true;
      storageServiceRequest.getData(this.itemId).then(function (res) {
        _this.loading = false;
        _this.form = res.data;
      }).catch(function (err) {
        _this.loading = false;
        _this.form = {};
      });
    },
    // 保存
    save: function save() {
      var _this2 = this;
      this.$refs["detail"].validate(function (valid, object) {
        if (valid) {
          var saveData = _this2.opType == 'add' ? storageServiceRequest.addData(_this2.form) : storageServiceRequest.editData(_this2.itemId, _this2.form);
          saveData.then(function (res) {
            if (res.code == 200) {
              _this2.$message({
                type: "success",
                message: res.msg
              });
              _this2.closePage();
            }
          });
        }
      });
    },
    // 关闭弹窗
    closePage: function closePage() {
      this.form = {
        roleName: '',
        roleMenu: ''
      };
      this.$refs['detail'].resetFields();
      this.$emit('closePage');
    }
  }
};