import _defineProperty from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/defineProperty.js";
var _methods;
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import Pagination from '@/components/Pagination';
import request from '@/api/message';
import MessageReceivedDialog from './dialog/messageReceivedDialog';
import DatePicker from '@/components/DatePicker';
import { getCurrentDate, getBeforeDate } from '@/utils/common';
export default {
  name: "MessageReceived",
  components: {
    Pagination: Pagination,
    MessageReceivedDialog: MessageReceivedDialog,
    DatePicker: DatePicker
  },
  mixins: [initHeight],
  data: function data() {
    return {
      searchForm: {
        messageReadState: '',
        messageSendDatetimeStart: getBeforeDate(6) + ' 00:00:00',
        messageSendDatetimeEnd: getCurrentDate() + ' 23:59:59'
      },
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      createdDateTime: [getBeforeDate(6), getCurrentDate()],
      total: 0,
      loading: false,
      tableData: [],
      classifyList: [],
      rangeList: [],
      showAddDialog: true,
      currentType: '',
      msgIdArr: [],
      currentId: '',
      msgStatus: 'all',
      multipleSelection: []
    };
  },
  created: function created() {
    this.loadData();
    this.getClassifyList();
    this.getRangeList();
  },
  methods: (_methods = {
    //日期选择事件
    getPickersDate: function getPickersDate(name, val) {
      if (val && val.length > 1) {
        this[name] = val;
        this.searchForm.messageSendDatetimeStart = val[0];
        this.searchForm.messageSendDatetimeEnd = val[1];
      } else {
        this[name] = [];
        this.searchForm.messageSendDatetimeStart = "";
        this.searchForm.messageSendDatetimeEnd = "";
      }
    },
    // 获取分类数据
    getClassifyList: function getClassifyList() {
      var _this = this;
      request.getClassifyList().then(function (res) {
        if (res.data) {
          _this.classifyList = res.data;
        }
        ;
      });
    },
    // 获取范围数据
    getRangeList: function getRangeList() {
      var _this2 = this;
      request.getRangeList().then(function (res) {
        if (res.data) {
          _this2.rangeList = res.data;
        }
        ;
      });
    },
    // 刷新
    breakData: function breakData() {
      // this.resetSearch();
      this.loadData();
    },
    // 重置搜索表单
    resetSearch: function resetSearch() {
      this.searchForm = {
        messageReadState: this.searchForm.messageReadState,
        messageSendDatetimeStart: getBeforeDate(6) + ' 00:00:00',
        messageSendDatetimeEnd: getCurrentDate() + ' 23:59:59'
      };
      this.createdDateTime = [getBeforeDate(6), getCurrentDate()];
    },
    // 搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    //获取列表数据
    loadData: function loadData() {
      var _this3 = this;
      this.loading = true;
      this.showAddDialog = false;
      this.msgIdArr = [];
      request.getMessageReceivedList(this.listQuery.pageNum, this.listQuery.pageSize, this.searchForm).then(function (res) {
        _this3.loading = false;
        if (res.code === 200) {
          _this3.tableData = res.data.list;
          _this3.total = res.data.page.recordsTotal;
          _this3.$Bus.$emit('loadData');
        }
        ;
      }).catch(function (err) {
        _this3.loading = false;
      });
    },
    // 新增编辑消息
    addAndViewtMsg: function addAndViewtMsg(type) {
      var item = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      this.currentType = type;
      if (type == 'viewDetail') {
        this.currentId = item.messageReadLogId.toString();
      }
      this.showAddDialog = true;
    },
    // 勾选复选框
    handleSelectionChange: function handleSelectionChange(val) {
      this.msgIdArr = val.map(function (val) {
        return val.messageReadLogId;
      });
    },
    // 是否启用
    updateItem: function updateItem(item) {
      // request.是否启用接口(item).then(res => {
      //     if (res.code == 200) {
      //         this.$message.success(res.msg);
      //         this.loadData();
      //     } else {}
      // }).catch(err => {})
    },
    // 删除
    remove: function remove(row) {
      var _this4 = this;
      var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      if (type) {
        if (!this.msgIdArr.length) {
          this.$message.warning('请勾选要删除的数据');
        } else {
          this.$confirm("您确定删除选中的数据吗?", '提示', {
            distinguishCancelAndClose: true,
            confirmButtonText: "确定",
            type: 'warning'
          }).then(function () {
            _this4.removePublic(_this4.msgIdArr);
          }).catch(function () {});
        }
        ;
      } else {
        this.$confirm("您确定删除该数据吗?", '提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: "确定",
          type: 'warning'
        }).then(function () {
          _this4.removePublic([row.messageReadLogId]);
        }).catch(function () {});
      }
      ;
    },
    // 删除方法
    removePublic: function removePublic(idArr) {
      var _this5 = this;
      request.deleteMessageReceived(idArr).then(function (res) {
        if (res.code === 200) {
          _this5.$message.success(res.msg);
          _this5.loadData();
        }
        ;
      });
    },
    handleTabClick: function handleTabClick(tab, event) {
      //tab切换事件
      this.searchForm.messageReadState = tab.name !== 'all' ? tab.name : '';
      this.msgStatus = tab.name;
      this.listQuery = {
        pageNum: 1,
        pageSize: 20
      };
      this.loadData();
    },
    selectableFn: function selectableFn(row) {
      //是否可选
      return !(row.messageReadState === 'READ');
    }
  }, _defineProperty(_methods, "handleSelectionChange", function handleSelectionChange(val) {
    //列表行选择事件
    this.multipleSelection = val;
  }), _defineProperty(_methods, "updateMessage", function updateMessage(val) {
    var _this6 = this;
    //已读
    var ids = [];
    if (val == "all") {} else {
      ids = _.map(this.multipleSelection, 'messageReadLogId');
    }
    var params = ids && ids.length ? ids : 'all';
    request.viewMessageReceived(params).then(function (res) {
      if (res.code == 200) {
        _this6.$message({
          type: "success",
          message: res.msg
        });
        _this6.loadData();
      }
    });
  }), _defineProperty(_methods, "toTab", function toTab(item) {
    // this.$router.push(item.messageContentTranslated);
  }), _methods)
};