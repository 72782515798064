import _defineProperty from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/defineProperty.js";
var _computed;
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.json.stringify.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import OemSelect from '@/components/OemSelect';
import customersRequest from '@/api/customers';
import dict from '@/mixins/dict';
import { getCurrentDate, commonExport, getUserOrgInfoDealerOem } from '@/utils/common';
export default {
  name: "GovernanceOrgStatistics",
  components: {
    OemSelect: OemSelect
  },
  mixins: [dict, initHeight],
  computed: (_computed = {
    tableHeightDF: function tableHeightDF() {
      return document.body.clientHeight - 178 + 'px';
    },
    tableHeightDSF: function tableHeightDSF() {
      return document.body.clientHeight - 358 + 'px';
    },
    tableHeightDFS: function tableHeightDFS() {
      return document.body.clientHeight - 232 + 'px'; //复合表头的高度
    }
  }, _defineProperty(_computed, "tableHeightDSF", function tableHeightDSF() {
    return document.body.clientHeight - 358 + 'px'; //复合表头的高度
  }), _defineProperty(_computed, "tableHeightDSS", function tableHeightDSS() {
    return document.body.clientHeight - 410 + 'px'; //复合表头的高度
  }), _defineProperty(_computed, "isOwner", function isOwner() {
    return this.$route.path === "/governance/owner";
  }), _computed),
  watch: {
    isOwner: {
      handler: function handler(val) {
        this.searchForm.category = val ? this.activeName : null;
        this.loading = false;
        if (sessionStorage.getItem('G_' + (this.isOwner ? 'All' : this.activeName) + "_TableData")) {
          this.tableData = JSON.parse(sessionStorage.getItem('G_' + (this.isOwner ? 'All' : this.activeName) + "_TableData"));
        } else {
          this.initData();
        }
      },
      immediate: true
    }
  },
  data: function data() {
    return {
      activeName: "Organization",
      bizType: localStorage.getItem('bizType'),
      searchForm: {
        // bizType: this.bizType,
        orgCode: '',
        endDate: "".concat(getCurrentDate(), " 23:59:59"),
        category: null
      },
      orgData: {
        salesOrgName: "",
        salesOrgCode: ""
      },
      tableData: [],
      loading: false,
      sumList: [],
      isDisabled: false,
      disabledLessThan: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > Date.now();
        }
      }
    };
  },
  beforeRouteLeave: function beforeRouteLeave(to, form, next) {
    sessionStorage.setItem('G_' + (this.isOwner ? 'All' : this.activeName) + "_TableData", JSON.stringify(this.tableData));
    next();
  },
  created: function created() {
    this.initData();
  },
  methods: {
    // 初始化
    initData: function initData() {
      var _orgInfo = getUserOrgInfoDealerOem();
      if (_orgInfo) {
        this.searchForm.orgCode = _orgInfo.salesOrgCode;
        // this.searchForm.bizType = _orgInfo.bizType;
        this.searchForm.areaType = _orgInfo.salesOrgType;
        this.orgData.salesOrgCode = _orgInfo.salesOrgCode;
        this.orgData.salesOrgName = _orgInfo.salesOrgName;
      }
      this.loadData();
    },
    // tab切换事件
    handleTabClick: function handleTabClick(tab, event) {
      this.searchForm.category = this.activeName;
      this.loadData();
    },
    // 导出
    exportFn: function exportFn() {
      var exporParams = {
        total: this.tableData.length,
        fileName: "\u6570\u636E\u6CBB\u7406\u7EDF\u8BA1(".concat(this.isOwner ? '员工' : '机构', ")"),
        exportUrl: "/vi-core-service/customer/governance/".concat(this.isOwner ? 'owner' : 'org'),
        searchForm: this.searchForm,
        exportData: [{
          property: this.isOwner ? 'ownerName' : 'orgName',
          label: this.isOwner ? '客户经理' : '机构名称'
        }, {
          property: 'count',
          label: '客户总数'
        }, {
          property: 'renewalCount',
          label: '续保量'
        }, {
          property: 'renewalRate',
          label: '续保率'
        }, {
          property: 'ciitcCount',
          label: '在保量'
        }, {
          property: 'ciitcRate',
          label: '在保率'
        }, {
          property: 'totalCount',
          label: '续保在保量'
        }, {
          property: 'totalRate',
          label: '续保在保率'
        }]
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    // 日期选择事件
    dateChange: function dateChange(val) {
      if (val) {
        this.searchForm.endDate = "".concat(val, " 23:59:59");
      } else {
        this.searchForm.endDate = null;
      }
    },
    // 机构选择事件
    nodeClick: function nodeClick(val) {
      this.searchForm.orgCode = val.salesOrgCode;
      // this.searchForm.bizType = val.bizType || 'DEALER';
      this.searchForm.areaType = val.salesOrgType;
      ;
    },
    // 获取列表数据
    loadData: function loadData() {
      var _this = this;
      if (!this.searchForm.orgCode) return;
      this.loading = true;
      this.isDisabled = true;
      var req = this.isOwner ? customersRequest.getGovernanceOwnerStatistics(this.searchForm) : customersRequest.getGovernanceOrgStatistics(this.searchForm);
      req.then(function (res) {
        _this.loading = false;
        _this.isDisabled = false;
        if (res.code === 200) {
          _this.sumList = res.data.head;
          _this.tableData = res.data.body;
        }
        ;
      }).catch(function (err) {
        _this.loading = false;
        _this.isDisabled = false;
      });
    }
  }
};