//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import insuranceContractManageRequest from '@/api/insuranceContractManage';
import { getSupplyers } from '@/utils/common';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    dialogType: {
      type: String,
      default: {}
    },
    specialId: {
      type: String,
      default: ''
    },
    riskTypeList: {
      type: Array,
      default: []
    }
  },
  data: function data() {
    return {
      addForm: {
        insCorpCode: '',
        specialCode: '',
        specialIndex: '',
        specialType: 'EDIT',
        specialName: '',
        riskType: ''
      },
      specialTypelist: [{
        key: "FIXED",
        value: "必选特约"
      }, {
        key: "EDIT",
        value: "可编辑特约"
      }, {
        key: "CUSTOMIZE",
        value: "自定义特约"
      }
      // {
      //     key: "AUTONOMY",
      //     value: "自主特约",
      // }
      ],

      formLoading: false,
      formRules: {
        insCorpCode: [{
          required: true,
          message: '请输入保司编码',
          trigger: 'blur'
        }],
        riskType: [{
          required: true,
          message: '请选择险种类别',
          trigger: 'change'
        }],
        specialCode: [{
          required: true,
          message: '请输入特约编码',
          trigger: 'blur'
        }],
        specialShortName: [{
          required: true,
          message: '请输入特约简称',
          trigger: 'blur'
        }],
        specialName: [{
          required: true,
          message: '请输入特约内容',
          trigger: 'blur'
        }]
      },
      routesData: [],
      routesList: [],
      supplyers: []
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initView();
      }
      ;
    }
  },
  mounted: function mounted() {
    this.initView();
  },
  methods: {
    // 初始化
    initView: function initView() {
      //保司
      this.supplyers = getSupplyers();
      if (this.dialogType != 'add') {
        this.initData(); //初始化数据
      } else {
        this.addForm = {
          insCorpCode: '',
          specialCode: '',
          specialIndex: '',
          specialType: 'EDIT',
          specialName: '',
          riskType: ''
        };
        this.$refs.addForm.resetFields();
      }
    },
    // 初始化数据
    initData: function initData() {
      var _this = this;
      this.formLoading = true;
      insuranceContractManageRequest.viewData({
        specialId: this.specialId
      }).then(function (res) {
        _this.formLoading = false;
        if (res.code == 200) {
          _this.addForm = res.data;
          _this.addForm.insCorpCode = _.toUpper(_this.addForm.insCorpCode);
        }
      }).catch(function (err) {
        _this.formLoading = false;
      });
    },
    // 保存数据
    save: function save() {
      var _this2 = this;
      this.$refs['addForm'].validate(function (valid) {
        if (valid) {
          var configReq = _this2.dialogType == 'add' ? insuranceContractManageRequest.addData(_this2.addForm) : insuranceContractManageRequest.updateData(_this2.addForm);
          configReq.then(function (res) {
            if (res.code == 200 && res.success == true) {
              _this2.formLoading = false;
              _this2.$message.success(res.msg);
              _this2.closePage();
            }
          });
        }
      });
    },
    // 关闭弹窗
    closePage: function closePage() {
      this.$refs.addForm.resetFields();
      this.$emit('closePage');
    },
    specialTypeChange: function specialTypeChange() {
      this.$refs.addForm.clearValidate();
    }
  }
};