import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import dict from '@/mixins/dict';
import Pagination from '@/components/Pagination';
import DatePicker from '@/components/DatePicker';
import LocklistlDialog from './dialog/locklistlDialog';
import EditTaskDialog from './dialog/editTaskDialog';
import request from '@/api/jobConfig';
import { getBeforeDate, getCurrentDate, commonExport } from '@/utils/common';
export default {
  name: 'TaskLog',
  components: {
    Pagination: Pagination,
    LocklistlDialog: LocklistlDialog,
    EditTaskDialog: EditTaskDialog,
    DatePicker: DatePicker
  },
  mixins: [initHeight, dict],
  computed: {},
  data: function data() {
    return {
      drawer: false,
      searchForm: {},
      form: {},
      list: null,
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      multipleSelection: [],
      startEndTime: [],
      startStartTime: [getCurrentDate(), getCurrentDate()],
      opType: '',
      loading: false,
      showLocklistDialog: false,
      showDialog: false
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.loadData();
  },
  methods: {
    exportFn: function exportFn() {
      var exporParams = {
        total: this.total,
        fileName: '计划任务监控',
        exportUrl: '/tp-core-service/job/log/search',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    // 日期选择事件
    getPickersDate: function getPickersDate(name, val) {
      if (val && val.length > 1) {
        this[name] = val;
      } else {
        this[name] = [];
      }
    },
    viewLocklist: function viewLocklist() {
      //分布式锁列表
      this.showLocklistDialog = true;
    },
    editTask: function editTask() {
      //编辑
      this.showDialog = true;
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {};
      this.startEndTime = [];
      this.startStartTime = [getCurrentDate(), getCurrentDate()];
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    loadData: function loadData() {
      var _this = this;
      //获取列表数据
      this.loading = true;
      this.drawer = false;
      this.showLocklistDialog = false;
      this.showDialog = false;
      if (this.startStartTime) {
        this.searchForm.startTimeStart = this.startStartTime[0] && this.startStartTime[0].split(' ')[0] + ' 00:00:00' || null;
        this.searchForm.startTimeEnd = this.startStartTime[1] && this.startStartTime[1].split(' ')[0] + ' 23:59:59' || null;
      }
      if (this.startEndTime) {
        this.searchForm.endTimeStart = this.startEndTime[0] && this.startEndTime[0].split(' ')[0] + ' 00:00:00' || null;
        this.searchForm.endTimeEnd = this.startEndTime[1] && this.startEndTime[1].split(' ')[0] + ' 23:59:59' || null;
      }
      request.getJobLogList(_objectSpread(_objectSpread({}, this.searchForm), this.listQuery)).then(function (res) {
        _this.loading = false;
        _this.tableData = res.data.list;
        //总条数
        _this.total = res.data.page.recordsTotal;
      }).catch(function (err) {
        _this.loading = false;
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      //列表行选择事件
      this.multipleSelection = val;
    }
  }
};