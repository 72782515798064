import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.json.stringify.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '../../mixins/initHeight';
import dict from '../../mixins/dict';
import OrgSelect from '@/components/OrgSelect';
import OemSelect from '@/components/OemSelect';
import DatePicker from '@/components/DatePicker';
import dictionary from '@/api/dictionary';
import statisticsRequest from '@/api/statistics';
import dayjs from 'dayjs';
import { getCurrentDate, getAfterDate, getLastMonth, getUserOrgInfo, commonExport } from '@/utils/common';
export default {
  name: "BusinessStatistics",
  components: {
    OrgSelect: OrgSelect,
    OemSelect: OemSelect,
    DatePicker: DatePicker
  },
  mixins: [dict, initHeight],
  computed: {
    isEmployee: function isEmployee() {
      return this.$route.path === "/statistics/employee";
    }
  },
  watch: {
    isEmployee: {
      handler: function handler(val) {
        if (val) {
          this.activeName = "Employee";
        } else {
          this.activeName = "Organization";
        }
        this.searchForm.category = this.activeName;
        this.loading = false;
        this.drawer = false;
        if (sessionStorage.getItem(this.activeName + "_TableData")) {
          this.tableData = JSON.parse(sessionStorage.getItem(this.activeName + "_TableData"));
        } else {
          this.tableData = [];
        }
        if (sessionStorage.getItem(this.activeName + "_SearchForm")) {
          this.searchForm = JSON.parse(sessionStorage.getItem(this.activeName + "_SearchForm"));
          this.startEndTime = [this.searchForm.startDate, this.searchForm.endDate];
        } else {
          this.searchForm = {
            timeSelect: "now",
            salesOrgCode: '',
            salesOrgName: '',
            startDate: getCurrentDate() + " 00:00:00",
            endDate: getCurrentDate() + " 23:59:59",
            source: ''
          };
        }
        if (sessionStorage.getItem(this.activeName + "_OrgForm")) {
          this.orgForm = JSON.parse(sessionStorage.getItem(this.activeName + "_OrgForm"));
        } else {
          this.orgForm = {
            salesOrgCode: '',
            salesOrgName: '',
            salesOrgType: ''
          };
        }
        if (sessionStorage.getItem(this.activeName + "_IsDataTime")) {
          this.isDataTime = JSON.parse(sessionStorage.getItem(this.activeName + "_IsDataTime"));
        }
      },
      immediate: true
    }
  },
  data: function data() {
    var _this = this;
    return {
      sourceData: [],
      activeName: "Organization",
      drawer: false,
      searchForm: {
        timeSelect: "now",
        salesOrgCode: '',
        salesOrgName: '',
        startDate: getCurrentDate() + " 00:00:00",
        endDate: getCurrentDate() + " 23:59:59",
        source: ''
      },
      list: null,
      total: 0,
      listLoading: true,
      tableData: [],
      multipleSelection: [],
      startEndTime: [getCurrentDate(), getCurrentDate()],
      loading: false,
      isDataTime: true,
      bizType: localStorage.getItem('bizType') || '',
      userInfo: JSON.parse(localStorage.getItem('userInfo')) || {},
      orgForm: {
        salesOrgCode: '',
        salesOrgName: '',
        salesOrgType: ''
      },
      emptyText: '默认不统计数据，请点击统计按钮进行查询',
      choiceDate0: '',
      pickerOptions: {
        // 设置不能选择的日期
        onPick: function onPick(_ref) {
          var maxDate = _ref.maxDate,
            minDate = _ref.minDate;
          _this.choiceDate0 = minDate.getTime();
          if (maxDate) {
            _this.choiceDate0 = '';
          }
        },
        disabledDate: function disabledDate(time) {
          var choiceDateTime = new Date(_this.choiceDate0).getTime();
          var minTime = new Date(choiceDateTime).setMonth(new Date(choiceDateTime).getMonth() - 6);
          var maxTime = new Date(choiceDateTime).setMonth(new Date(choiceDateTime).getMonth() + 6);
          var min = minTime;
          var newDate = new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1;
          var max = newDate < maxTime ? newDate : maxTime;
          //如果已经选中一个日期 则 返回 该日期前后三个月时间可选
          if (_this.choiceDate0) {
            return time.getTime() < min || time.getTime() > max;
          }
          //若一个日期也没选中 则 返回 当前日期以前日期可选
          return time.getTime() > newDate;
        }
      }
    };
  },
  mounted: function mounted() {
    var _orgInfo = getUserOrgInfo();
    if (_orgInfo && _orgInfo.organizationId) {
      this.searchForm.salesOrgCode = _orgInfo.orgCode;
      this.searchForm.salesOrgName = _orgInfo.fullName;
    }
    if (this.bizType === "DEALER") {
      this.searchForm.salesOrgCode = this.userInfo.orgCode;
      // this.loadData()
    }

    if (sessionStorage.getItem(this.activeName + "_SearchForm")) {
      this.searchForm = JSON.parse(sessionStorage.getItem(this.activeName + "_SearchForm"));
      this.startEndTime = [this.searchForm.startDate, this.searchForm.endDate];
    } else {
      this.searchForm = {
        timeSelect: "now",
        salesOrgCode: '',
        salesOrgName: '',
        startDate: getCurrentDate() + " 00:00:00",
        endDate: getCurrentDate() + " 23:59:59",
        source: ''
      };
    }
    if (sessionStorage.getItem(this.activeName + "_OrgForm")) {
      this.orgForm = JSON.parse(sessionStorage.getItem(this.activeName + "_OrgForm"));
    } else {
      this.orgForm = {
        salesOrgCode: '',
        salesOrgName: '',
        salesOrgType: ''
      };
    }
    if (sessionStorage.getItem(this.activeName + "_IsDataTime")) {
      this.isDataTime = JSON.parse(sessionStorage.getItem(this.activeName + "_IsDataTime"));
    }
    this.getSourceData();
  },
  beforeRouteLeave: function beforeRouteLeave(to, form, next) {
    sessionStorage.setItem(this.activeName + "_TableData", JSON.stringify(this.tableData));
    sessionStorage.setItem(this.activeName + "_SearchForm", JSON.stringify(this.searchForm));
    sessionStorage.setItem(this.activeName + "_OrgForm", JSON.stringify(this.orgForm));
    sessionStorage.setItem(this.activeName + "_IsDataTime", JSON.stringify(this.isDataTime));
    next();
  },
  methods: {
    getSourceData: function getSourceData() {
      var _this2 = this;
      dictionary.getDictItemsByCode('SOURCE').then(function (res) {
        if (res.code === 200) {
          _this2.sourceData = res.data;
        }
      });
    },
    nodeClick: function nodeClick(val) {
      //切换机构
      this.searchForm.salesOrgCode = val.salesOrgCode;
      this.searchForm.salesOrgName = val.salesOrgName;
      this.searchForm.areaType = val.salesOrgType;
      // this.loadData();
    },
    handleTime: function handleTime(val) {
      switch (val) {
        case "now":
          this.startEndTime = [getCurrentDate(), getCurrentDate()];
          this.isDataTime = true;
          break;
        case "yesterday":
          this.startEndTime = [getAfterDate(-1), getAfterDate(-1)];
          this.isDataTime = true;
          break;
        case "sevenDays":
          this.startEndTime = [getAfterDate(-6), getCurrentDate()];
          this.isDataTime = true;
          break;
        case "thirtyDays":
          this.startEndTime = [getAfterDate(-29), getCurrentDate()];
          this.isDataTime = true;
          break;
        case "lastMonth":
          this.startEndTime = getLastMonth();
          this.isDataTime = true;
          break;
        case "currentMonth":
          var startDate = dayjs().startOf('M').format("YYYY-MM-DD");
          var endDate = dayjs().endOf('M').format("YYYY-MM-DD");
          this.startEndTime = [startDate, endDate];
          this.isDataTime = true;
          break;
        case "custom":
          this.isDataTime = false;
          this.$refs.input.focus();
          break;
      }
      this.searchForm.startDate = this.startEndTime[0] + " 00:00:00";
      this.searchForm.endDate = this.startEndTime[1] + " 23:59:59";
      // if (this.isDataTime) {
      //     this.loadData();
      // }
    },
    getPickersDate: function getPickersDate(name, val) {
      if (val && val.length > 1) {
        this[name] = val;
      } else {
        this[name] = [];
      }
    },
    dateChange: function dateChange(start, end, val) {
      //日期选择事件
      if (val && val.length > 1) {
        this.searchForm[start] = val[0] + " 00:00:00";
        this.searchForm[end] = val[1] + " 23:59:59";
      } else {
        this.searchForm.startDate = null;
        this.searchForm.endDate = null;
      }

      // this.loadData();
    },
    handleTabClick: function handleTabClick(tab, event) {
      //tab切换事件
      this.searchForm.category = this.activeName;
      this.loadData();
    },
    loadData: function loadData() {
      var _this3 = this;
      //获取列表数据
      this.drawer = false;
      if (this.startEndTime) {
        this.searchForm.startDate = this.startEndTime[0] && this.startEndTime[0].split(' ')[0] + ' 00:00:00' || null, this.searchForm.endDate = this.startEndTime[1] && this.startEndTime[1].split(' ')[0] + ' 23:59:59' || null;
      }
      var postData = {
        category: this.searchForm.category,
        startDate: this.searchForm.startDate,
        endDate: this.searchForm.endDate,
        orgCode: this.searchForm.salesOrgCode,
        areaType: this.searchForm.areaType,
        source: this.searchForm.source
        // orgName: this.searchForm.salesOrgName
      };

      this.loading = true;
      var req = this.isEmployee ? statisticsRequest.getEmployeeStatistics(postData) : statisticsRequest.getOrgStatistics(postData);
      req.then(function (res) {
        _this3.loading = false;
        if (res.code === 200) {
          _this3.tableData = res.data;
          if (_.isEmpty(_this3.tableData)) _this3.emptyText = "暂无数据";
          _this3.$nextTick(function () {
            _this3.$refs['multipleTable'].doLayout();
          });
        } else {
          _this3.emptyText = "暂无数据";
        }
      });
    },
    // 导出
    exportExcels: function exportExcels() {
      var exporParams = {
        total: this.total,
        limitNum: 200000,
        fileName: '员工业务统计列表',
        exportUrl: '/vi-core-service/statistics/employeeTableData',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    }
  }
};