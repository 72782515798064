/**
 * SSO配置接口
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 列表
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   * @param {*} data 内容
   */
  getSSOList: function getSSOList(data) {
    return request.get(getUrl("/ssoSetting/search", 9), {
      params: data
    });
  },
  /**
   * 新增 
   * @param {*} data 内容
   */
  add: function add(data) {
    return request.post(getUrl("/ssoSetting/create", 9), data);
  },
  /**
   * 修改
   * @param {*} data 内容
   */
  edit: function edit(data) {
    return request.post(getUrl("/ssoSetting/update", 9), data);
  },
  /**
   * 查看
   * @param {*} data 内容
   */
  getDetail: function getDetail(data) {
    return request.get(getUrl("/ssoSetting/get", 9), {
      params: data
    });
  },
  /**
   * 查看
   * @param {*} data 内容
   */
  getListSsoStrategy: function getListSsoStrategy(data) {
    return request.get(getUrl("/ssoSetting/listSsoStrategy", 9), {
      params: data
    });
  },
  /**
   * 查看
   * @param {*} data 内容
   */
  getAllList: function getAllList(data) {
    return request.get(getUrl("/oem/info/getAllList", 4));
  },
  /**
   * 批量删除
   * @param {*} ids
   */
  delete: function _delete(ids) {
    return request.delete(getUrl("/ssoSetting/delete/".concat(ids), 9));
  }
};
export default objApi;