import "core-js/modules/es.object.keys.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import requestApi from '@/api/interactPlatform';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    currentItem: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      showDialog: false,
      form: {}
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
      ;
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    getProcessLogList: function getProcessLogList() {
      var _this = this;
      requestApi.getDuiprouteinputrecordlog(this.currentItem.routeInputRecordFlowNo).then(function (res) {
        if (res.code === 200) {
          _this.form = res.data;
        }
        ;
        console.log(res, 'res');
      });
    },
    initData: function initData() {
      this.getProcessLogList();
    },
    //关闭弹窗
    closePage: function closePage() {
      this.$emit('closePage');
    },
    parseData: function parseData(data) {
      var jsonData = null;
      try {
        jsonData = JSON.parse(data);
      } catch (error) {
        jsonData = data;
      }
      return jsonData;
    }
  }
};