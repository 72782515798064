var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "app-m-cls" },
      [
        _c(
          "div",
          { staticClass: "table-cls normal-btn-cls" },
          [
            _c(
              "el-drawer",
              {
                attrs: {
                  title: "",
                  visible: _vm.drawer,
                  modal: false,
                  "append-to-body": true,
                  size: "350px",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.drawer = $event
                  },
                },
              },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.searchForm,
                      "label-width": "110px",
                      size: "mini",
                    },
                  },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "所属机构" } },
                              [
                                _c("OrgSelect", {
                                  staticStyle: { width: "100%" },
                                  attrs: { searchForm: _vm.orgForm },
                                  on: { nodeClick: _vm.nodeClick },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c("el-col", { attrs: { span: 24 } }, [
                          _c(
                            "div",
                            { staticClass: "searchBtnStyle" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    icon: "el-icon-refresh-left",
                                  },
                                  on: { click: _vm.resetSearch },
                                },
                                [_vm._v("重置")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    type: "primary",
                                    icon: "el-icon-search",
                                  },
                                  on: { click: _vm.searchData },
                                },
                                [_vm._v("查询")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "table-cls normal-btn-cls" },
          [
            _c(
              "el-card",
              { staticClass: "box-card", attrs: { shadow: "never" } },
              [
                _c("div", { staticClass: "search-box" }, [
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v("险种类型：")]),
                      _c(
                        "el-select",
                        {
                          staticStyle: { "margin-right": "8px" },
                          attrs: {
                            filterable: "",
                            placeholder: "请选择险种类型",
                          },
                          model: {
                            value: _vm.searchForm.riskType,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "riskType", $$v)
                            },
                            expression: "searchForm.riskType",
                          },
                        },
                        _vm._l(_vm.InsuranceList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.name, value: item.code },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v("保险公司：")]),
                      _c(
                        "el-select",
                        {
                          staticStyle: { "margin-right": "8px" },
                          attrs: {
                            filterable: "",
                            placeholder: "请选择保险公司",
                            clearable: "",
                          },
                          on: { change: _vm.getAccidentType },
                          model: {
                            value: _vm.searchForm.insCorpCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "insCorpCode", $$v)
                            },
                            expression: "searchForm.insCorpCode",
                          },
                        },
                        _vm._l(_vm.supplyers, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.shortName, value: item.code },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v("地区类型:")]),
                      _c(
                        "el-select",
                        {
                          staticStyle: {
                            width: "100px",
                            "margin-left": "8px",
                            "margin-right": "8px",
                          },
                          attrs: {
                            placeholder: "请选择地区类型",
                            clearable: "",
                          },
                          on: { change: _vm.ChangeDistrictType },
                          model: {
                            value: _vm.districtType,
                            callback: function ($$v) {
                              _vm.districtType = $$v
                            },
                            expression: "districtType",
                          },
                        },
                        _vm._l(_vm.districtTypeList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.name, value: item.code },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v("地区：")]),
                      _vm.districtType == "province"
                        ? _c(
                            "el-select",
                            {
                              staticStyle: {
                                width: "120px",
                                "margin-right": "8px",
                              },
                              attrs: { placeholder: "请选择省", clearable: "" },
                              model: {
                                value: _vm.searchForm.insCityCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.searchForm, "insCityCode", $$v)
                                },
                                expression: "searchForm.insCityCode",
                              },
                            },
                            _vm._l(_vm.proList, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.name, value: item.code },
                              })
                            }),
                            1
                          )
                        : _c("el-cascader", {
                            ref: "cascaderAddr",
                            staticStyle: {
                              width: "160px",
                              "margin-right": "8px",
                            },
                            attrs: { clearable: "", options: _vm.cityOptions },
                            on: { change: _vm.handleChange },
                            model: {
                              value: _vm.cascaderAddr,
                              callback: function ($$v) {
                                _vm.cascaderAddr = $$v
                              },
                              expression: "cascaderAddr",
                            },
                          }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", icon: "el-icon-refresh-left" },
                          on: { click: _vm.resetSearch },
                        },
                        [_vm._v("重置")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            size: "mini",
                            type: "primary",
                            icon: "el-icon-search",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.searchData()
                            },
                          },
                        },
                        [_vm._v("查询")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "search-btn" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: { click: _vm.verification },
                        },
                        [_vm._v("验证")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: { click: _vm.configDict },
                        },
                        [_vm._v("配置匹配字典")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: {
                            click: function ($event) {
                              return _vm.toAdd("add")
                            },
                          },
                        },
                        [_vm._v("新增")]
                      ),
                      _c(
                        "el-button",
                        { attrs: { size: "mini" }, on: { click: _vm.reload } },
                        [_vm._v("刷新")]
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    ref: "multipleTable",
                    staticStyle: { width: "100%" },
                    attrs: {
                      size: "mini",
                      border: "",
                      data: _vm.tableData,
                      height: _vm.tableHeightJD,
                      "header-cell-style": { background: "#F7F7F7" },
                      "highlight-current-row": "",
                    },
                    on: { "selection-change": _vm.handleSelectionChange },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        type: "selection",
                        width: "55",
                        align: "center",
                        fixed: "left",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "riskTypeDisplay",
                        label: "险种类型",
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "insCorpName",
                        label: "保险公司",
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "insCityName",
                        label: "地区",
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "clause",
                        label: "条款",
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "defaultStatus",
                        label: "是否默认",
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    scope.row.defaultStatus ? "是" : "否"
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "enableStatus",
                        label: "是否启用",
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(scope.row.enableStatus ? "是" : "否") +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "操作",
                        align: "center",
                        width: "160",
                        fixed: "right",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    plain: "",
                                    size: "mini",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.edit("edit", scope.row)
                                    },
                                  },
                                },
                                [_vm._v("编辑")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "danger",
                                    plain: "",
                                    size: "mini",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.delClicked(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c("pagination", {
                  attrs: {
                    total: _vm.total,
                    page: _vm.listQuery.pageNum,
                    limit: _vm.listQuery.pageSize,
                  },
                  on: {
                    "update:page": function ($event) {
                      return _vm.$set(_vm.listQuery, "pageNum", $event)
                    },
                    "update:limit": function ($event) {
                      return _vm.$set(_vm.listQuery, "pageSize", $event)
                    },
                    pagination: function ($event) {
                      return _vm.loadData()
                    },
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-dialog",
          {
            staticClass: "pageDialog",
            attrs: {
              visible: _vm.showVerificationDialog,
              "close-on-click-modal": false,
              width: "900px",
              "append-to-body": true,
            },
            on: {
              "update:visible": function ($event) {
                _vm.showVerificationDialog = $event
              },
            },
          },
          [
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "title" },
                slot: "title",
              },
              [
                _c(
                  "span",
                  {
                    staticStyle: { "font-size": "15px", "font-weight": "700" },
                  },
                  [_vm._v("验证保单识别")]
                ),
              ]
            ),
            _c("VerificationDialog", {
              attrs: { refresh: _vm.showVerificationDialog },
              on: { closePage: _vm.loadData },
            }),
          ],
          1
        ),
        _c(
          "el-dialog",
          {
            staticClass: "pageDialog",
            attrs: {
              visible: _vm.showConfigDictDialog,
              "close-on-click-modal": false,
              width: "800px",
              "append-to-body": true,
            },
            on: {
              "update:visible": function ($event) {
                _vm.showConfigDictDialog = $event
              },
            },
          },
          [
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "title" },
                slot: "title",
              },
              [
                _c(
                  "span",
                  {
                    staticStyle: { "font-size": "15px", "font-weight": "700" },
                  },
                  [_vm._v("配置匹配字典")]
                ),
              ]
            ),
            _c("ConfigDictDialog", {
              attrs: { refresh: _vm.showConfigDictDialog },
              on: { closePage: _vm.loadData },
            }),
          ],
          1
        ),
        _c(
          "el-dialog",
          {
            staticClass: "pageDialog",
            attrs: {
              visible: _vm.showAddDialog,
              "close-on-click-modal": false,
              width: "720px",
              "append-to-body": true,
            },
            on: {
              "update:visible": function ($event) {
                _vm.showAddDialog = $event
              },
            },
          },
          [
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "title" },
                slot: "title",
              },
              [
                _c(
                  "span",
                  {
                    staticStyle: { "font-size": "15px", "font-weight": "700" },
                  },
                  [_vm._v("详情")]
                ),
              ]
            ),
            _vm.dialogType == "add"
              ? _c("addDialog", {
                  attrs: {
                    dialogType: _vm.dialogType,
                    refresh: _vm.showAddDialog,
                  },
                  on: { closePage: _vm.loadData },
                })
              : _c("addDialog", {
                  attrs: {
                    dialogType: _vm.dialogType,
                    ocrFunctionSupplierOrgId: _vm.ocrFunctionSupplierOrgId,
                    refresh: _vm.showAddDialog,
                  },
                  on: { closePage: _vm.loadData },
                }),
          ],
          1
        ),
        _c(
          "el-dialog",
          {
            staticClass: "pageDialog",
            attrs: {
              visible: _vm.showViewDialog,
              "close-on-click-modal": false,
              width: "720px",
              "append-to-body": true,
            },
            on: {
              "update:visible": function ($event) {
                _vm.showViewDialog = $event
              },
            },
          },
          [
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "title" },
                slot: "title",
              },
              [
                _c(
                  "span",
                  {
                    staticStyle: { "font-size": "15px", "font-weight": "700" },
                  },
                  [_vm._v("详情")]
                ),
              ]
            ),
            _c("detailDialog", {
              attrs: { refresh: _vm.showViewDialog, details: _vm.details },
              on: { closePage: _vm.loadData },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }