var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "app-m-cls",
    },
    [
      _c(
        "div",
        { ref: "header", staticClass: "table-cls normal-btn-cls" },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-between",
                    "align-items": "center",
                  },
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [
                  _c("div", [
                    _vm._v("任务编码：" + _vm._s(_vm.currentItem.taskCode)),
                  ]),
                  _c(
                    "el-button",
                    { attrs: { size: "mini" }, on: { click: _vm.exportFn } },
                    [_vm._v("导出")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { display: "flex", "flex-wrap": "wrap" } },
                _vm._l(_vm.sumList, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      style: {
                        "line-height": "30px",
                        margin: item.prop !== "ownerByName" ? "5px" : "0",
                        "background-color": "#edf4fb",
                        width: item.prop !== "ownerByName" ? "15.5%" : "0",
                      },
                    },
                    [
                      item.prop !== "ownerByName"
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                padding: "10px 15px",
                                "border-radius": "2px",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "font-size": "14px",
                                    color: "#999",
                                  },
                                },
                                [_vm._v(_vm._s(item.label))]
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "font-weight": "bold",
                                    "font-size": "20px",
                                    color: "#0d3386",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.currentItem[item.prop]))]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  )
                }),
                0
              ),
              _c(
                "el-table",
                {
                  ref: "multipleTable",
                  staticStyle: { width: "100%" },
                  attrs: {
                    size: "mini",
                    border: "",
                    data: _vm.tableData,
                    height:
                      !_vm.sumList || _vm.sumList.length == 0
                        ? _vm.tableHeightDF
                        : _vm.tableHeightDS,
                    "header-cell-style": { background: "#F7F7F7" },
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "ownerByName",
                      label: "客户经理",
                      "min-width": "100",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "importCount",
                      label: "任务数量",
                      "min-width": "100",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "quoteCount",
                      label: "报价数量",
                      "min-width": "100",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "quoteRate",
                      label: "报价率",
                      "min-width": "100",
                      align: "right",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "renewalCount",
                      label: "续保数量",
                      "min-width": "100",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "renewalRate",
                      label: "续保率",
                      "min-width": "100",
                      align: "right",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "policyCount",
                      label: "保单数量",
                      "min-width": "100",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "renewalPremium",
                      label: "保费",
                      "min-width": "100",
                      align: "right",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "onLinePolicyCount",
                      label: "在线出单量",
                      "min-width": "100",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "onLinePolicyPremium",
                      label: "在线保费",
                      "min-width": "100",
                      align: "right",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "drainCount",
                      label: "流失量",
                      "min-width": "100",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "drainRate",
                      label: "流失率",
                      "min-width": "100",
                      align: "right",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "firstTrackCount",
                      label: "首次跟进量",
                      "min-width": "120",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "firstTrackRate",
                      label: "首次跟进率",
                      "min-width": "120",
                      align: "right",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "trackCount",
                      label: "跟进次数",
                      "min-width": "100",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "avgTrackCount",
                      label: "平均跟进次数",
                      "min-width": "100",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "successTrackCount",
                      label: "成功跟进量",
                      "min-width": "100",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "successNonPolicyCount",
                      label: "成功未录单量",
                      "min-width": "100",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "defeatCount",
                      label: "战败次数",
                      "min-width": "100",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "defeatRate",
                      label: "战败率",
                      "min-width": "100",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "trackingCount",
                      label: "跟进中&未跟进量",
                      "min-width": "130",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "trackingRate",
                      label: "跟进中&未跟进率",
                      "min-width": "130",
                      "show-overflow-tooltip": "",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }