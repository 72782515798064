var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderMonitorBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c("FormLabel", { attrs: { "form-label": "留修工单" } }),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "card_item" }, [
                  _c("div", { staticClass: "card_item_label" }, [
                    _vm._v("工单号："),
                  ]),
                  _c("span"),
                ]),
              ]),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "card_item" }, [
                  _c("div", { staticClass: "card_item_label" }, [
                    _vm._v("工单来源："),
                  ]),
                  _c("span"),
                ]),
              ]),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "card_item" }, [
                  _c("div", { staticClass: "card_item_label" }, [
                    _vm._v("创建人："),
                  ]),
                  _c("span"),
                ]),
              ]),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "card_item" }, [
                  _c("div", { staticClass: "card_item_label" }, [
                    _vm._v("创建时间："),
                  ]),
                  _c("span", [_vm._v("2022-01-23 14:32:34")]),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "box-card", staticStyle: { "margin-top": "10px" } },
        [
          _c("FormLabel", { attrs: { "form-label": "保险留修信息" } }),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c("div", { staticClass: "card_item" }, [
                  _c("div", { staticClass: "card_item_label" }, [
                    _vm._v("线索短信："),
                  ]),
                  _c("span"),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "card_item" }, [
                  _c("div", { staticClass: "card_item_label" }, [
                    _vm._v("保险公司："),
                  ]),
                  _c("span"),
                ]),
              ]),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "card_item" }, [
                  _c("div", { staticClass: "card_item_label" }, [
                    _vm._v("车牌号："),
                  ]),
                  _c("span"),
                ]),
              ]),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "card_item" }, [
                  _c("div", { staticClass: "card_item_label" }, [
                    _vm._v("车架号："),
                  ]),
                  _c("span"),
                ]),
              ]),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "card_item" }, [
                  _c("div", { staticClass: "card_item_label" }, [
                    _vm._v("报案号："),
                  ]),
                  _c("span"),
                ]),
              ]),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "card_item" }, [
                  _c("div", { staticClass: "card_item_label" }, [
                    _vm._v("报案时间："),
                  ]),
                  _c("span"),
                ]),
              ]),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "card_item" }, [
                  _c("div", { staticClass: "card_item_label" }, [
                    _vm._v("客户名称："),
                  ]),
                  _c("span"),
                ]),
              ]),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "card_item" }, [
                  _c("div", { staticClass: "card_item_label" }, [
                    _vm._v("客户电话："),
                  ]),
                  _c("span"),
                ]),
              ]),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "card_item" }, [
                  _c("div", { staticClass: "card_item_label" }, [
                    _vm._v("车辆现状："),
                  ]),
                  _c("span"),
                ]),
              ]),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "card_item" }, [
                  _c("div", { staticClass: "card_item_label" }, [
                    _vm._v("车型："),
                  ]),
                  _c("span"),
                ]),
              ]),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "card_item" }, [
                  _c("div", { staticClass: "card_item_label" }, [
                    _vm._v("留修类型："),
                  ]),
                  _c("span"),
                ]),
              ]),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "card_item" }, [
                  _c("div", { staticClass: "card_item_label" }, [
                    _vm._v("报案地址："),
                  ]),
                  _c("span"),
                ]),
              ]),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "card_item" }, [
                  _c("div", { staticClass: "card_item_label" }, [
                    _vm._v("碰撞视频1："),
                  ]),
                  _c("span", [_vm._v("11")]),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "box-card", staticStyle: { "margin-top": "10px" } },
        [
          _c("FormLabel", { attrs: { "form-label": "服务信息" } }),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "card_item" }, [
                  _c("div", { staticClass: "card_item_label" }, [
                    _vm._v("接车员："),
                  ]),
                  _c("span"),
                ]),
              ]),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "card_item" }, [
                  _c("div", { staticClass: "card_item_label" }, [
                    _vm._v("电话："),
                  ]),
                  _c("span"),
                ]),
              ]),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "card_item" }, [
                  _c("div", { staticClass: "card_item_label" }, [
                    _vm._v("分配时间："),
                  ]),
                  _c("span"),
                ]),
              ]),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "card_item" }, [
                  _c("div", { staticClass: "card_item_label" }, [
                    _vm._v("跟进人："),
                  ]),
                  _c("span"),
                ]),
              ]),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "card_item" }, [
                  _c("div", { staticClass: "card_item_label" }, [
                    _vm._v("跟进状态："),
                  ]),
                  _c("span"),
                ]),
              ]),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "card_item" }, [
                  _c("div", { staticClass: "card_item_label" }, [
                    _vm._v("跟进时间："),
                  ]),
                  _c("span"),
                ]),
              ]),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "card_item" }, [
                  _c("div", { staticClass: "card_item_label" }, [
                    _vm._v("跟进历史："),
                  ]),
                  _c(
                    "span",
                    {
                      staticClass: "pointer",
                      on: {
                        click: function ($event) {
                          return _vm.toview("history")
                        },
                      },
                    },
                    [_vm._v("点击查看")]
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "box-card", staticStyle: { "margin-top": "10px" } },
        [
          _c("FormLabel", { attrs: { "form-label": "定损信息" } }),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "card_item" }, [
                  _c("div", { staticClass: "card_item_label" }, [
                    _vm._v("定损金额："),
                  ]),
                  _c("span"),
                ]),
              ]),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "card_item" }, [
                  _c("div", { staticClass: "card_item_label" }, [
                    _vm._v("定损时间："),
                  ]),
                  _c("span"),
                ]),
              ]),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "card_item" }, [
                  _c("div", { staticClass: "card_item_label" }, [
                    _vm._v("定损详情："),
                  ]),
                  _c(
                    "span",
                    {
                      staticClass: "pointer",
                      on: {
                        click: function ($event) {
                          return _vm.toview("Damage")
                        },
                      },
                    },
                    [_vm._v("点击查看")]
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "box-card", staticStyle: { "margin-top": "10px" } },
        [
          _c("FormLabel", { attrs: { "form-label": "维修信息" } }),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "card_item" }, [
                  _c("div", { staticClass: "card_item_label" }, [
                    _vm._v("维修状态："),
                  ]),
                  _c("span"),
                ]),
              ]),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "card_item" }, [
                  _c("div", { staticClass: "card_item_label" }, [
                    _vm._v("更新时间："),
                  ]),
                  _c("span"),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "footer", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c("div", { staticClass: "footer-btn" }, [
            _c(
              "div",
              { staticClass: "footer-btn-list" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { size: "mini", icon: "el-icon-back" },
                    on: { click: _vm.goBack },
                  },
                  [_vm._v("返回")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showDamageDialog,
            "close-on-click-modal": false,
            width: "600px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDamageDialog = $event
            },
            handleClose: function ($event) {
              _vm.showDamageDialog = false
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("定损详情")]
              ),
            ]
          ),
          _c("div", { staticClass: "app-m-cls", staticStyle: {} }, [
            _c(
              "div",
              {
                ref: "header",
                staticClass: "header-cls",
                staticStyle: { "max-height": "400px", overflow: "auto" },
              },
              [
                _c(
                  "el-card",
                  {
                    staticStyle: { height: "100%" },
                    attrs: { shadow: "never" },
                  },
                  [
                    _c(
                      "el-tabs",
                      {
                        on: { "tab-click": _vm.handleClick },
                        model: {
                          value: _vm.activeName,
                          callback: function ($$v) {
                            _vm.activeName = $$v
                          },
                          expression: "activeName",
                        },
                      },
                      [
                        _c("el-tab-pane", {
                          attrs: { label: "定损项目", name: "1" },
                        }),
                        _c("el-tab-pane", {
                          attrs: { label: "定损变更记录", name: "2" },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "tabPaneMain" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.activeName == "1",
                              expression: "activeName == '1'",
                            },
                          ],
                        },
                        [
                          _c(
                            "el-row",
                            [
                              _c("el-col", { attrs: { span: 24 } }, [
                                _c("div", { staticClass: "card_item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "card_item_label" },
                                    [_vm._v("定损金额：")]
                                  ),
                                  _c("span"),
                                ]),
                              ]),
                              _c("el-col", { attrs: { span: 24 } }, [
                                _c("div", { staticClass: "card_item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "card_item_label" },
                                    [_vm._v("定损项目：")]
                                  ),
                                  _c("span"),
                                ]),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.activeName == "2",
                              expression: "activeName == '2'",
                            },
                          ],
                        },
                        [
                          _c(
                            "el-timeline",
                            {
                              staticClass: "DamageBox",
                              attrs: { reverse: false },
                            },
                            _vm._l(
                              _vm.DamageStepList,
                              function (activity, index) {
                                return _c("el-timeline-item", { key: index }, [
                                  activity.status
                                    ? _c("div", { staticClass: "title" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(activity.status) +
                                            " " +
                                            _vm._s(activity.time) +
                                            " " +
                                            _vm._s(activity.manger) +
                                            " "
                                        ),
                                      ])
                                    : _vm._e(),
                                  activity.DamageAmount
                                    ? _c(
                                        "div",
                                        { staticClass: "description" },
                                        [
                                          _vm._v(
                                            " 定损金额：" +
                                              _vm._s(activity.DamageAmount) +
                                              " "
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            " 定损项目：" +
                                              _vm._s(activity.DamageDetail) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ])
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "justify-content": "center",
                  "margin-top": "8px",
                },
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: { icon: "el-icon-close", size: "mini" },
                    on: {
                      click: function ($event) {
                        return _vm.closePage("Damage")
                      },
                    },
                  },
                  [_vm._v("关闭")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showStepDialog,
            "close-on-click-modal": false,
            width: "600px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showStepDialog = $event
            },
            handleClose: function ($event) {
              _vm.showStepDialog = false
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("跟进状态详情")]
              ),
            ]
          ),
          _c("div", { staticClass: "app-m-cls", staticStyle: {} }, [
            _c(
              "div",
              {
                ref: "header",
                staticClass: "header-cls",
                staticStyle: { "max-height": "400px", overflow: "auto" },
              },
              [
                _c(
                  "el-card",
                  {
                    staticStyle: { height: "100%" },
                    attrs: { shadow: "never" },
                  },
                  [
                    _c(
                      "el-timeline",
                      { staticClass: "timelineBox", attrs: { reverse: true } },
                      _vm._l(_vm.stepList, function (activity, index) {
                        return _c("el-timeline-item", { key: index }, [
                          _c("div", { staticClass: "title" }, [
                            _vm._v(" " + _vm._s(activity.status) + " "),
                            _c(
                              "span",
                              { staticStyle: { "font-size": "12px" } },
                              [_vm._v(_vm._s(activity.time))]
                            ),
                          ]),
                          _c("div", { staticClass: "description" }, [
                            _vm._v(" " + _vm._s(activity.msg) + " "),
                          ]),
                        ])
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "justify-content": "center",
                  "margin-top": "8px",
                },
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: { icon: "el-icon-close", size: "mini" },
                    on: {
                      click: function ($event) {
                        return _vm.closePage("history")
                      },
                    },
                  },
                  [_vm._v("关闭")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }