import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
/**
 * 存储服务配置接口
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 列表
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   */
  getStorageList: function getStorageList(pageNum, pageSize, data) {
    return request.get(getUrl("/objStorage/search?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 7), {
      params: data
    });
  },
  /**
   * 新增数据
   * @param {*} data 内容
   */
  addData: function addData(data) {
    return request.post(getUrl("/objStorage/create", 7), data);
  },
  /**
   * 编辑数据
   * @param {*} data 内容
   */
  editData: function editData(id, data) {
    return request.post(getUrl("/objStorage/update?objStorageId=".concat(id), 7), data);
  },
  /**
   * 根据ID获取对象存储
   * @param {*} data 内容
   */
  getData: function getData(id) {
    return request.get(getUrl("/objStorage/get?objStorageId=".concat(id), 7));
  },
  /**
   * 删除对象存储
   * @param {*} data 内容
   */
  delData: function delData(id) {
    return request.post(getUrl("/objStorage/delete?objStorageId=".concat(id.toString()), 7), {});
  },
  /**
   * 设置默认对象存储
   * @param {*} data 内容
   */
  default: function _default(id) {
    return request.post(getUrl("/objStorage/default?objStorageId=".concat(id), 7), {});
  },
  /**
   * 启用对象存储
   * @param {*} data 内容
   */
  enable: function enable(id) {
    return request.post(getUrl("/objStorage/enable?objStorageId=".concat(id), 7), {});
  },
  /**
   * 禁用对象存储
   * @param {*} data 内容
   */
  disable: function disable(id) {
    return request.post(getUrl("/objStorage/disable?objStorageId=".concat(id), 7), {});
  },
  /**
   * 更新秘钥
   * @param {*} data 内容
   */
  updateSecret: function updateSecret(id, data) {
    return request.post(getUrl("/objStorage/updateSecret?objStorageId=".concat(id), 7), data);
  }
};
export default objApi;