import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import dict from '@/mixins/dict';
import Pagination from '@/components/Pagination';
import DatePicker from '@/components/DatePicker';
import commonReq from '@/api/common';
import { downFile, getBeforeDate, getCurrentDate, commonExport } from '@/utils/common';
export default {
  name: 'FixingFileMonitor',
  components: {
    Pagination: Pagination,
    DatePicker: DatePicker
  },
  mixins: [initHeight, dict],
  computed: {},
  data: function data() {
    return {
      searchForm: {},
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      loading: false,
      startEndTime: [getBeforeDate(6), getCurrentDate()],
      statusList: [{
        value: 'TO_PULL',
        label: '待拉取'
      }, {
        value: 'PULL_FAIL',
        label: '拉取失败'
      }, {
        value: 'TO_SAVE',
        label: '待保存'
      }, {
        value: 'SAVE_SUCCEED',
        label: '保存成功'
      }, {
        value: 'SAVE_FAIL',
        label: '保存失败'
      }]
    };
  },
  created: function created() {
    this.loadData();
  },
  methods: {
    // 导出
    exportFn: function exportFn() {
      var exporParams = {
        total: this.total,
        limitNum: 200000,
        fileName: '维修文件监控',
        exportUrl: '/ps-ftms-service/duringRepair/file/search',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    // 日期选择事件
    getPickersDate: function getPickersDate(name, val) {
      if (val && val.length > 1) {
        this[name] = val;
      } else {
        this[name] = [];
      }
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {};
      this.startEndTime = [getBeforeDate(6), getCurrentDate()];
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    breakData: function breakData() {
      // this.resetSearch();
      this.loadData();
    },
    loadData: function loadData() {
      var _this = this;
      //获取列表数据
      this.loading = true;
      if (this.startEndTime) {
        this.searchForm.startDate = this.startEndTime[0] && this.startEndTime[0].split(' ')[0] + ' 00:00:00' || null;
        this.searchForm.endDate = this.startEndTime[1] && this.startEndTime[1].split(' ')[0] + ' 23:59:59' || null;
      }
      var data = _objectSpread(_objectSpread({}, this.listQuery), this.searchForm);
      commonReq.getDuringRepairFileList(data).then(function (res) {
        _this.loading = false;
        if (res.code === 200) {
          _this.tableData = res.data.list;
          _this.total = res.data.page.recordsTotal;
        }
        ;
      }).catch(function (err) {
        _this.loading = false;
      });
    },
    downloadFile: function downloadFile(item) {
      downFile(item.fileUrl, item.fileName);
    }
  }
};