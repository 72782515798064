import _createForOfIteratorHelper from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import userCenterApi from '@/api/userCenter';
import Rules from '@/utils/rules';
import { character, idCardValidate } from '@/utils/common';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    opType: {
      type: String,
      default: ''
    },
    currentId: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      form: {
        address: '',
        certificateNo: '',
        certificateType: '',
        cityCode: '',
        districtCode: '',
        email: '',
        mobile: '',
        name: '',
        provinceCode: '',
        addressCode: []
      },
      rules: {
        name: [{
          required: true,
          message: '请输入姓名',
          trigger: 'blur'
        }, {
          min: 2,
          max: 50,
          pattern: Rules["NAME"].value,
          message: "姓名格式有误",
          trigger: ["blur", "change"]
        }],
        certificateType: {
          required: true,
          message: '请选择证件类型',
          trigger: 'change'
        },
        certificateNo: [{
          required: true,
          message: '请输入证件号码',
          trigger: 'blur'
        }],
        mobile: [{
          required: true,
          message: '请输入手机号',
          trigger: 'blur'
        }, {
          pattern: Rules["MOBILE_RULE"].value,
          message: "手机号格式有误",
          trigger: ["blur", "change"]
        }],
        email: [{
          required: true,
          message: '请输入邮箱',
          trigger: 'blur'
        }, {
          pattern: Rules["EMAIL"].value,
          message: "邮箱格式有误",
          trigger: ["blur", "change"]
        }],
        addressCode: {
          required: true,
          message: '请选择所在省市区',
          trigger: ['blur', 'change']
        },
        address: {
          required: true,
          message: '请输入详细地址',
          trigger: ["blur", "change"]
        }
      },
      formLoading: false,
      regionOptions: JSON.parse(localStorage.getItem('VI_CityAreaTree')) || [],
      cardTypeOptions: JSON.parse(localStorage.getItem('VI_CERTIFICATE_TYPE')) || []
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
      ;
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    // 证件类型切换
    selectCertificate: function selectCertificate() {
      this.form.certificateNo = "";
    },
    // 判断证件类型
    certificateNoChange: function certificateNoChange() {
      if (this.form.certificateType == 'P01' && this.form.certificateNo) {
        var certificateNo = Rules['ID_CARD'].value;
        var flag = certificateNo.test(this.form.certificateNo);
        var isValidate = idCardValidate(this.form.certificateNo);
        if (!flag || !isValidate) {
          this.$message({
            type: 'error',
            message: '证件号码不满足校验规则，请修改！'
          });
          return false;
        }
        ;
      }
      ;
      return true;
    },
    inputCardNo: function inputCardNo(val) {
      if (val) {
        if (this.form.certificateType == 'P01') {
          this.form.certificateNo = character(_.toUpper(val));
        } else {
          val = val.replace(/[^A-Za-z0-9\（\）\(\)]/g, '');
          this.form.certificateNo = _.toUpper(val);
        }
      }
    },
    // 选择省/市/区
    handleChange: function handleChange(val) {
      var _this = this;
      this.form.provinceCode = val && val[0]; //省
      this.form.cityCode = val && val[1]; //市
      this.form.districtCode = val && val[2]; //区
      this.form.address = '';
      this.regionOptions.map(function (province) {
        if (province.value == val[0]) {
          _this.form.address = province.label;
          var _iterator = _createForOfIteratorHelper(province.children),
            _step;
          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var city = _step.value;
              if (city.value == val[1]) {
                _this.form.address = _this.form.address + city.label;
                var _iterator2 = _createForOfIteratorHelper(city.children),
                  _step2;
                try {
                  for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
                    var area = _step2.value;
                    if (area.value == val[2]) {
                      _this.form.address = _this.form.address + area.label;
                    }
                  }
                } catch (err) {
                  _iterator2.e(err);
                } finally {
                  _iterator2.f();
                }
              }
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }
        }
      });
    },
    // 初始化数据
    initData: function initData() {
      var _this2 = this;
      this.form = {
        address: '',
        certificateNo: '',
        certificateType: '',
        cityCode: '',
        districtCode: '',
        email: '',
        mobile: '',
        name: '',
        provinceCode: '',
        addressCode: []
      };
      if (this.opType === "edit") {
        this.formLoading = true;
        userCenterApi.getNetUserInfo(this.currentId).then(function (res) {
          _this2.formLoading = false;
          if (res.code === 200) {
            _this2.form = res.data;
            _this2.$set(_this2.form, 'addressCode', []);
            if (res.data.provinceCode) {
              _this2.form.addressCode.push(res.data.provinceCode);
            }
            if (res.data.cityCode) {
              _this2.form.addressCode.push(res.data.cityCode);
            }
            if (res.data.districtCode) {
              _this2.form.addressCode.push(res.data.districtCode);
            }
            _this2.$refs.detail.resetFields();
          }
          ;
        }).catch(function (err) {
          _this2.formLoading = false;
        });
      }
      ;
      this.$nextTick(function () {
        _this2.$refs.detail.resetFields();
      });
    },
    // 监听输入事件
    inputNetChannelCode: function inputNetChannelCode(val) {
      this.form.netChannelCode = val.replace(/[^A-Za-z0-9_-]/g, '');
    },
    // 保存数据
    save: function save() {
      var _this3 = this;
      // 证件号码判断
      if (!this.certificateNoChange()) return;
      this.$refs.detail.validate(function (valid) {
        if (valid) {
          _this3.formLoading = true;
          var req = userCenterApi.updateNetUser(_this3.currentId, _this3.form);
          req.then(function (res) {
            _this3.formLoading = false;
            if (res.code === 200) {
              _this3.$message.success(res.msg);
              _this3.closePage(false);
            }
            ;
          }).catch(function (err) {
            _this3.formLoading = false;
          });
        }
        ;
      });
    },
    closePage: function closePage() {
      var boolean = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      //关闭弹窗
      this.$emit('closePage', boolean);
    }
  }
};