var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "app-m-cls app-download" }, [
      _c(
        "div",
        { staticStyle: { "text-align": "center", "line-height": "100px" } },
        [_c("span", [_vm._v("此功能正在开发中...")])]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }