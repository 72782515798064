import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import Pagination from '@/components/Pagination';
import userCenterApi from '@/api/userCenter';
import NetUserDialog from './dialog/netUserDialog';
import { commonExport, getBeforeDate, getCurrentDate } from '@/utils/common';
export default {
  name: 'UserManagement',
  components: {
    Pagination: Pagination,
    NetUserDialog: NetUserDialog
  },
  mixins: [initHeight],
  data: function data() {
    return {
      searchForm: {
        createdTimeStart: "".concat(getBeforeDate(30), " 00:00:00"),
        createdTimeEnd: "".concat(getCurrentDate(), " 23:59:59")
      },
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      loading: false,
      drawer: false,
      createdStartEndTime: [getBeforeDate(30), getCurrentDate()],
      multipleSelection: [],
      opType: '',
      currentId: '',
      childData: []
    };
  },
  created: function created() {
    this.loadData();
  },
  methods: {
    // 日期更改监听事件
    dateChange: function dateChange(start, end, val) {
      if (val && val.length > 1) {
        this.searchForm[start] = "".concat(val[0], " 00:00:00");
        this.searchForm[end] = "".concat(val[1], " 23:59:59");
      } else {
        this.searchForm[start] = null;
        this.searchForm[end] = null;
      }
      ;
    },
    // 监听输入事件
    inputValue: function inputValue(val, type) {
      this.searchForm[type] = val.replace(/[^A-Za-z0-9_-]/g, '');
    },
    // 重置搜索表单
    resetSearch: function resetSearch() {
      this.searchForm = {
        createdTimeStart: "".concat(getBeforeDate(30), " 00:00:00"),
        createdTimeEnd: "".concat(getCurrentDate(), " 23:59:59")
      };
      this.createdStartEndTime = [getBeforeDate(30), getCurrentDate()];
    },
    // 搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    // 刷新
    breakData: function breakData() {
      this.loadData();
    },
    // 获取列表数据
    loadData: function loadData() {
      var _this = this;
      this.loading = true;
      this.drawer = false;
      var data = _objectSpread(_objectSpread({}, this.listQuery), this.searchForm);
      userCenterApi.getNetUserList(data).then(function (res) {
        _this.loading = false;
        if (res.code === 200) {
          _this.tableData = res.data.list;
          _this.total = res.data.page.recordsTotal;
        }
        ;
      }).catch(function (err) {
        _this.loading = false;
      });
    },
    // 展开行
    expandChange: function expandChange(row, expandedRows) {
      var _this2 = this;
      var _row = _.find(expandedRows, function (item) {
        return item.netUserId == row.netUserId;
      });
      if (_row) {
        // 通过$set属性可设置loading实现实时加载loading效果(经过测试,通过$set直接给父层数据声明子层数据时会出现报错,所以才在获取父层表格数据时声明子层表格数据)
        this.$set(row, 'loading', true);
        userCenterApi.getNetUserChannelAccountList(row['netUserId']).then(function (res) {
          if (res.code == 200 && res.data) {
            var resData = res.data;
            // 遍历父层表格数据
            _this2.tableData.forEach(function (item, index) {
              // 找到当前展开的行，把获取到的数据赋值进去
              if (item.netUserId === row.netUserId) {
                _this2.tableData[index].childrenData = resData || [];
              }
            });
          }
          _this2.$set(row, 'loading', false);
        }, function (err) {
          _this2.$set(row, 'loading', false);
        });
      }
    },
    // 导出
    exportExcels: function exportExcels() {
      var exporParams = {
        total: this.total,
        fileName: '用户管理',
        exportUrl: '/tp-core-service/netUser/search',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    // 列表行选择事件
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 删除
    batchDelete: function batchDelete(item) {
      var _this3 = this;
      var ids = [];
      if (!_.isEmpty(item)) {
        ids.push(item.netUserId);
      } else {
        if (!_.isEmpty(this.multipleSelection)) {
          ids = _.map(this.multipleSelection, 'netUserId');
        }
        ;
      }
      ;
      this.$confirm('您确定要删除么？', {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        userCenterApi.deleteNetUser(ids).then(function (res) {
          if (res.code === 200) {
            _this3.$message.success(res.msg);
            _this3.loadData();
          }
          ;
        });
      });
    },
    // 新增
    add: function add() {
      this.$router.push({
        path: '/userCenter/userManagementAdd',
        query: {
          opType: 'add'
        }
      });
    },
    // 查看
    view: function view(type, row) {
      this.$router.push({
        path: '/userCenter/userManagementDetail',
        query: {
          netUserId: row.netUserId,
          opType: type,
          tabName: "\u7528\u6237\u7BA1\u7406\u8BE6\u60C5-".concat(row.name)
        }
      });
    }
  }
};