/**
 * 统一导出接口
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 导出
   * @param {*} data 内容
   */
  exportExcel: function exportExcel(data) {
    return request({
      method: 'get',
      url: getUrl("/excel/export/".concat(data), 9),
      responseType: 'blob'
    });
  },
  /**
   * 生成导出文件
   * 
   * @param {*} data 内容
   */
  generateExcel: function generateExcel(data) {
    var headers = {
      showLoading: true
    };
    return request.post(getUrl("/excel/generateFile", 9), data, {
      headers: headers
    });
  }
};
export default objApi;