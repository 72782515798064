import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import dict from '../../mixins/dict';
import initHeight from '@/mixins/initHeight';
import Pagination from '@/components/Pagination';
import DatePicker from '@/components/DatePicker';
import activityRequest from '../../api/insuranceActivities';
import { commonExport } from '@/utils/common';
export default {
  name: 'ActivityList',
  components: {
    Pagination: Pagination,
    DatePicker: DatePicker
  },
  mixins: [dict, initHeight],
  computed: {
    tableHeight: function tableHeight() {
      return document.body.clientHeight - 215 - 107 + 'px';
    }
  },
  data: function data() {
    return {
      activityTime: [],
      searchForm: {
        activityName: ''
      },
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      multipleSelection: [],
      loading: false,
      timeout1: null,
      timeout2: null,
      timeout3: null,
      timeout4: null
    };
  },
  watch: {
    '$route': function $route(to, from) {
      //监听路由是否变化
      this.searchForm = {
        activityName: '',
        startEndTime: []
      };
      this.loadData();
    }
  },
  mounted: function mounted() {
    this.loadData();
  },
  methods: {
    // 日期选择事件
    getPickersDate: function getPickersDate(name, val) {
      if (val && val.length > 1) {
        this[name] = val;
      } else {
        this[name] = [];
      }
    },
    exportFn: function exportFn() {
      var _this = this;
      // 防抖
      if (this.timeout1) {
        clearTimeout(this.timeout1);
      }
      this.timeout1 = setTimeout(function () {
        _this.exportFn1();
      }, 500);
    },
    exportFn1: function exportFn1() {
      var searchForm = {
        activityName: this.searchForm.activityName,
        activityStartTime: this.searchForm.startEndTime ? this.searchForm.startEndTime[0] : '',
        activityEndTime: this.searchForm.startEndTime ? this.searchForm.startEndTime[1] : ''
      };
      var exporParams = {
        total: this.total,
        fileName: '活动列表',
        exportUrl: '/ps-ftms-service/activitys',
        searchForm: searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    selectable: function selectable(row, index) {
      return row.releaseStatus !== 'RELEASED';
    },
    dateChange: function dateChange(val) {
      //日期选择事件
      if (val && val.length > 1) {
        this.searchForm.startEndTime = val;
      } else {
        this.searchForm.startEndTime = [];
      }
      this.loadData();
    },
    recallItem: function recallItem(item) {
      var _this2 = this;
      this.$confirm("\u662F\u5426\u786E\u8BA4\u64A4\u56DE\u6240\u9009\u6D3B\u52A8?", '温馨提示', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var idArr = [];
        idArr.push(item.activityId);
        _this2.loading = true;
        activityRequest.undoActivity(idArr).then(function (res) {
          _this2.loading = false;
          if (res.code == RESPONSE_SUCCESS) {
            _this2.$message({
              type: 'success',
              message: '撤回活动成功'
            });
            _this2.loadData();
          }
        }).catch(function (err) {
          _this2.loading = false;
          _this2.$message({
            type: 'error',
            message: res.msg
          });
        });
      });
    },
    editDetail: function editDetail(item) {
      var _this3 = this;
      // 防抖
      if (this.timeout2) {
        clearTimeout(this.timeout2);
      }
      this.timeout2 = setTimeout(function () {
        _this3.editDetail1(item);
      }, 500);
    },
    editDetail1: function editDetail1(item) {
      // 编辑活动
      var router_path = this.$route.path;
      this.$router.push({
        path: "/activity/edit",
        query: {
          activityId: item.activityId,
          back: router_path
        }
      });
    },
    viewDetail: function viewDetail(item) {
      var _this4 = this;
      // 防抖
      if (this.timeout3) {
        clearTimeout(this.timeout3);
      }
      this.timeout3 = setTimeout(function () {
        _this4.viewDetail1(item);
      }, 500);
    },
    viewDetail1: function viewDetail1(item) {
      // 查看活动详情
      var router_path = this.$route.path;
      this.$router.push({
        path: "/activity/edit",
        query: {
          activityId: item.activityId,
          check: true,
          back: router_path
        }
      });
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {
        activityName: ''
      };
      this.activityTime = [];
      // this.loadData();
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    addItem: function addItem() {
      var _this5 = this;
      // 防抖
      if (this.timeout4) {
        clearTimeout(this.timeout4);
      }
      this.timeout4 = setTimeout(function () {
        _this5.addItem1();
      }, 500);
    },
    addItem1: function addItem1() {
      // 新建活动
      var router_path = this.$route.path;
      this.$router.push({
        path: "/activity/edit",
        query: {
          back: router_path
        }
      });
    },
    breakData: function breakData() {
      //   this.searchForm = {
      //         activityName: '',
      //         startEndTime: []
      //     }
      this.loadData();
    },
    releasedItem: function releasedItem() {
      var _this6 = this;
      this.$confirm("\u662F\u5426\u786E\u8BA4\u53D1\u5E03\u6240\u9009\u6D3B\u52A8?", '温馨提示', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var idArr = [];
        _this6.multipleSelection.forEach(function (item) {
          idArr.push(item.activityId);
        });
        _this6.loading = true;
        activityRequest.releasedActivity(idArr).then(function (res) {
          _this6.loading = false;
          if (res.code == RESPONSE_SUCCESS) {
            _this6.$message({
              type: 'success',
              message: '发布活动成功'
            });
            _this6.loadData();
          }
        }).catch(function (err) {
          _this6.loading = false;
          _this6.$message({
            type: 'error',
            message: res.msg
          });
        });
      });
    },
    loadData: function loadData() {
      var _this7 = this;
      //获取列表数据
      this.loading = true;
      if (this.activityTime) {
        this.searchForm.activityStartTime = this.activityTime[0] ? this.activityTime[0].split(' ')[0] + ' 00:00:00' : null;
        this.searchForm.activityEndTime = this.activityTime[1] ? this.activityTime[1].split(' ')[0] + ' 23:59:59' : null;
      }
      var params = {
        pageNum: this.listQuery.pageNum,
        pageSize: this.listQuery.pageSize,
        activityName: this.searchForm.activityName,
        activityStartTime: this.searchForm.activityStartTime,
        activityEndTime: this.searchForm.activityEndTime
      };
      activityRequest.getActivitysList(params).then(function (res) {
        _this7.loading = false;
        if (!_.isEmpty(res.data)) {
          _this7.tableData = res.data.list;
          _this7.total = res.data.page.recordsTotal;
        }
      }).catch(function (err) {
        _this7.loading = false;
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      //列表行选择事件
      this.multipleSelection = val;
    }
  }
};