var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "app-m-cls" },
      [
        _c(
          "div",
          { staticClass: "table-cls normal-btn-cls" },
          [
            _c(
              "el-card",
              { attrs: { shadow: "never" } },
              [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "align-items": "center",
                      "justify-content": "space-between",
                      "margin-bottom": "3px",
                    },
                  },
                  [
                    _c("div", [
                      _vm._v(
                        "客户信息(" +
                          _vm._s(
                            _vm.customersInfo.plateNo &&
                              _vm.customersInfo.plateNo !== "未上牌"
                              ? _vm.customersInfo.plateNo
                              : "未上牌"
                          ) +
                          ")"
                      ),
                    ]),
                    _c(
                      "div",
                      [
                        _vm.isToQuote
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  plain: "",
                                  size: "mini",
                                },
                                on: { click: _vm.toQuote },
                              },
                              [_vm._v("去报价")]
                            )
                          : _vm._e(),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", plain: "", size: "mini" },
                            on: {
                              click: function ($event) {
                                _vm.showDistributeDialog = true
                              },
                            },
                          },
                          [_vm._v("分配")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", plain: "", size: "mini" },
                            on: {
                              click: function ($event) {
                                _vm.showEditDialog = true
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      "margin-bottom": "8px",
                      "font-size": "14px",
                    },
                  },
                  [
                    _c("span", [
                      _vm._v(
                        "交强险公司：" +
                          _vm._s(_vm.customersInfo.bzLastYearInsCorpName)
                      ),
                    ]),
                    _c(
                      "span",
                      { staticStyle: { margin: "0 5px", color: "#ccc" } },
                      [_vm._v("|")]
                    ),
                    _c("span", [_vm._v("交强险到期日：")]),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.customersInfo.bzLastYearEndDate
                            ? _vm.customersInfo.bzLastYearEndDate.split(" ")[0]
                            : ""
                        )
                      ),
                    ]),
                    _c(
                      "span",
                      { staticStyle: { margin: "0 5px", color: "#ccc" } },
                      [_vm._v("|")]
                    ),
                    _c("span", [
                      _vm._v(
                        "商业险公司：" +
                          _vm._s(_vm.customersInfo.bsLastYearInsCorpName)
                      ),
                    ]),
                    _c(
                      "span",
                      { staticStyle: { margin: "0 5px", color: "#ccc" } },
                      [_vm._v("|")]
                    ),
                    _c("span", [_vm._v("商业险到期日：")]),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.customersInfo.bsLastYearEndDate
                            ? _vm.customersInfo.bsLastYearEndDate.split(" ")[0]
                            : ""
                        )
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          plain: "",
                          size: "mini",
                          loading: _vm.renewalLoading,
                        },
                        on: { click: _vm.checkRenewal },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.renewalLoading ? "检查中" : "检查续保")
                        ),
                      ]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          plain: "",
                          size: "mini",
                          loading: _vm.insuranceLoading,
                        },
                        on: { click: _vm.checkInsurance },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.insuranceLoading ? "检查中" : "检查在保")
                        ),
                      ]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", plain: "", size: "mini" },
                        on: { click: _vm.InsuranceDateDialog },
                      },
                      [_vm._v("更新到期日")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", plain: "", size: "mini" },
                        on: { click: _vm.toQuote },
                      },
                      [_vm._v("一键报价")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      "margin-bottom": "8px",
                      "margin-top": "5px",
                      "font-size": "13px",
                    },
                  },
                  [
                    _c("span", [_vm._v("跟进状态：")]),
                    _c("span", { staticStyle: { "margin-right": "10px" } }, [
                      _vm._v(_vm._s(_vm.customersInfo.newestTrackStatusName)),
                    ]),
                    _c("span", { staticStyle: { "margin-left": "10px" } }, [
                      _c("span", [_vm._v("下次跟进时间：")]),
                      _c("span", { staticStyle: { "margin-right": "10px" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.customersInfo.newestSubscribeTime &&
                              _vm.customersInfo.newestSubscribeTime.split(
                                " "
                              )[0]
                          )
                        ),
                      ]),
                    ]),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", plain: "", size: "mini" },
                        on: {
                          click: function ($event) {
                            _vm.showAddTracksDialog = true
                          },
                        },
                      },
                      [_vm._v("新增跟进")]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-descriptions",
                  {
                    staticClass: "margin-top",
                    attrs: {
                      title: "",
                      column: 3,
                      border: "",
                      labelStyle: { width: "110px" },
                      contentStyle: { width: "300px" },
                    },
                  },
                  [
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "联系电话" } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.customersInfo && _vm.customersInfo.ownerMobile
                                ? _vm.customersInfo.ownerMobile.replace(
                                    /(\d{3})(\d{4})(\d{4})/,
                                    "$1****$3"
                                  )
                                : ""
                            ) +
                            " "
                        ),
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              content:
                                "" + ("拨打：" + _vm.customersInfo.ownerMobile),
                              placement: "top",
                            },
                          },
                          [
                            _c("svg-icon", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.customersInfo.ownerMobile,
                                  expression: "customersInfo.ownerMobile",
                                },
                              ],
                              staticStyle: {
                                "font-size": "20px",
                                cursor: "pointer",
                              },
                              attrs: { "icon-class": "phone" },
                              on: { click: _vm.openCall },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "其他电话" } },
                      [_vm._v(_vm._s(_vm.customersInfo.ownerOtherMobile) + " ")]
                    ),
                    _c(
                      "el-descriptions-item",
                      [
                        _c(
                          "template",
                          { slot: "label" },
                          [
                            _c("span", [_vm._v("系统客户类型")]),
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  effect: "dark",
                                  placement: "top",
                                  content: "系统默认客户类型",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-warning",
                                  staticStyle: { "margin-left": "5px" },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(
                          " " +
                            _vm._s(_vm.customersInfo.customerSysTypeDisplay) +
                            " "
                        ),
                      ],
                      2
                    ),
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "电子邮箱" } },
                      [_vm._v(_vm._s(_vm.customersInfo.email))]
                    ),
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "客户经理" } },
                      [_vm._v(_vm._s(_vm.customersInfo.ownerByName))]
                    ),
                    _c(
                      "el-descriptions-item",
                      [
                        _c(
                          "template",
                          { slot: "label" },
                          [
                            _c("span", [_vm._v("网点客户类型")]),
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  effect: "dark",
                                  placement: "top",
                                  content: "网点自定义客户类型",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-warning",
                                  staticStyle: { "margin-left": "5px" },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(
                          " " + _vm._s(_vm.customersInfo.distinguishName) + " "
                        ),
                      ],
                      2
                    ),
                    _c(
                      "el-descriptions-item",
                      { attrs: { label: "联系地址" } },
                      [_vm._v(_vm._s(_vm.address))]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "table-cls normal-btn-cls" },
          [
            _c(
              "el-card",
              {
                style: { height: _vm.scrollerHeight, overflow: "auto" },
                attrs: { shadow: "never" },
              },
              [
                _c(
                  "el-tabs",
                  {
                    on: { "tab-click": _vm.handleClick },
                    model: {
                      value: _vm.activeName,
                      callback: function ($$v) {
                        _vm.activeName = $$v
                      },
                      expression: "activeName",
                    },
                  },
                  [
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "车辆信息", name: "VEHICLEINFO" } },
                      [
                        _c("VehicleInfo", {
                          attrs: { currentData: _vm.customersInfo },
                          on: { callback: _vm.loadData },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "车险保单", name: "INSURANCEPOLICY" } },
                      [
                        _c("InsurancePolicy", {
                          attrs: { vin: _vm.customersInfo.vin },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "报价历史", name: "PROPOSALHISTORY" } },
                      [
                        _c("ProposalHistory", {
                          attrs: { vin: _vm.customersInfo.vin },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "客户跟进", name: "CUSTOMERTRACKS" } },
                      [
                        _c("CustomerTracks", {
                          attrs: { followHistory: _vm.followHistory },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "影像资料", name: "Attachments" } },
                      [
                        _c("Attachments", {
                          attrs: {
                            customerId:
                              _vm.customerId && _vm.customerId.toString(),
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "用户画像", name: "Persona" } },
                      [_c("Attachments")],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-dialog",
          {
            staticClass: "pageDialog",
            attrs: {
              visible: _vm.showDistributeDialog,
              "close-on-click-modal": false,
              width: "500px",
              "append-to-body": false,
            },
            on: {
              "update:visible": function ($event) {
                _vm.showDistributeDialog = $event
              },
              handleClose: function ($event) {
                _vm.showDistributeDialog = false
              },
            },
          },
          [
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "title" },
                slot: "title",
              },
              [
                _c(
                  "span",
                  {
                    staticStyle: { "font-size": "15px", "font-weight": "700" },
                  },
                  [_vm._v("分配")]
                ),
              ]
            ),
            _c("DistributeDialog", {
              attrs: {
                refresh: _vm.showDistributeDialog,
                "customers-ids": _vm.customersIds,
              },
              on: { closePage: _vm.loadData },
            }),
          ],
          1
        ),
        _c(
          "el-dialog",
          {
            staticClass: "pageDialog",
            attrs: {
              visible: _vm.showEditDialog,
              "close-on-click-modal": false,
              width: "720px",
              "append-to-body": false,
            },
            on: {
              "update:visible": function ($event) {
                _vm.showEditDialog = $event
              },
              handleClose: function ($event) {
                _vm.showDistributeDialog = false
              },
            },
          },
          [
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "title" },
                slot: "title",
              },
              [
                _c(
                  "span",
                  {
                    staticStyle: { "font-size": "15px", "font-weight": "700" },
                  },
                  [_vm._v("编辑")]
                ),
              ]
            ),
            _c("EditDialog", {
              attrs: {
                refresh: _vm.showEditDialog,
                customersInfo: _vm.customersInfo,
              },
              on: { closePage: _vm.loadData },
            }),
          ],
          1
        ),
        _c(
          "el-dialog",
          {
            staticClass: "pageDialog",
            attrs: {
              visible: _vm.showInsuranceDateDialog,
              "close-on-click-modal": false,
              width: "550px",
              "append-to-body": false,
            },
            on: {
              "update:visible": function ($event) {
                _vm.showInsuranceDateDialog = $event
              },
              handleClose: function ($event) {
                _vm.showDistributeDialog = false
              },
            },
          },
          [
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "title" },
                slot: "title",
              },
              [
                _c(
                  "span",
                  {
                    staticStyle: { "font-size": "15px", "font-weight": "700" },
                  },
                  [_vm._v("更新保险到期日")]
                ),
              ]
            ),
            _c("InsuranceDateDialog", {
              attrs: {
                refresh: _vm.showInsuranceDateDialog,
                customerId: _vm.customerId,
              },
              on: { closePage: _vm.loadData },
            }),
          ],
          1
        ),
        _c(
          "el-dialog",
          {
            staticClass: "pageDialog",
            attrs: {
              visible: _vm.showAddTracksDialog,
              "close-on-click-modal": false,
              width: "550px",
              "append-to-body": false,
            },
            on: {
              "update:visible": function ($event) {
                _vm.showAddTracksDialog = $event
              },
              handleClose: function ($event) {
                _vm.showDistributeDialog = false
              },
            },
          },
          [
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "title" },
                slot: "title",
              },
              [
                _c(
                  "span",
                  {
                    staticStyle: { "font-size": "15px", "font-weight": "700" },
                  },
                  [_vm._v("新增跟进")]
                ),
              ]
            ),
            _c("FollowUpDialog", {
              attrs: {
                "customer-item": _vm.customersInfo,
                refresh: _vm.showAddTracksDialog,
              },
              on: { closePage: _vm.loadData },
            }),
          ],
          1
        ),
        _c(
          "el-dialog",
          {
            staticClass: "pageDialog",
            attrs: {
              visible: _vm.ShowInsuranceDialog,
              "close-on-click-modal": false,
              width: "800px",
              "append-to-body": false,
            },
            on: {
              "update:visible": function ($event) {
                _vm.ShowInsuranceDialog = $event
              },
              handleClose: function ($event) {
                _vm.ShowInsuranceDialog = false
              },
            },
          },
          [
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "title" },
                slot: "title",
              },
              [
                _c(
                  "span",
                  {
                    staticStyle: { "font-size": "15px", "font-weight": "700" },
                  },
                  [_vm._v("最新投保信息")]
                ),
              ]
            ),
            _c("InInsuranceDialog", {
              attrs: {
                customerId: _vm.customerId,
                DialogData: _vm.DialogData,
                refresh: _vm.ShowInsuranceDialog,
              },
              on: { closePage: _vm.loadData },
            }),
          ],
          1
        ),
        _c(
          "el-dialog",
          {
            staticClass: "pageDialog",
            attrs: {
              visible: _vm.callDialog,
              "close-on-click-modal": false,
              "close-on-press-escape": false,
              "show-close": false,
              width: "500px",
              "append-to-body": false,
            },
            on: {
              "update:visible": function ($event) {
                _vm.callDialog = $event
              },
            },
          },
          [
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "title" },
                slot: "title",
              },
              [
                _c(
                  "span",
                  {
                    staticStyle: { "font-size": "15px", "font-weight": "700" },
                  },
                  [
                    _vm._v(
                      "即将呼叫 " +
                        _vm._s(
                          _vm.customersInfo.ownerName
                            ? _vm.customersInfo.ownerName
                            : _vm.customersInfo.plateNo &&
                              _vm.customersInfo.plateNo !== "未上牌"
                            ? _vm.customersInfo.plateNo
                            : _vm.customersInfo.vin
                            ? _vm.customersInfo.vin
                            : ""
                        )
                    ),
                  ]
                ),
              ]
            ),
            _c("CallDialog", {
              attrs: { refresh: _vm.callDialog, currentObj: _vm.currentObj },
              on: {
                closePage: function ($event) {
                  _vm.callDialog = false
                },
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }