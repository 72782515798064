var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.formLoading,
          expression: "formLoading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: {},
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "500px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "detail",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-width": "115px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24, props: "" } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "姓名：", prop: "name" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入姓名",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "name", $$v)
                                  },
                                  expression: "form.name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "证件类型：",
                                prop: "certificateType",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "260px" },
                                  attrs: {
                                    filterable: "",
                                    placeholder: "请选择证件类型",
                                    clearable: "",
                                  },
                                  on: { change: _vm.selectCertificate },
                                  model: {
                                    value: _vm.form.certificateType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "certificateType", $$v)
                                    },
                                    expression: "form.certificateType",
                                  },
                                },
                                _vm._l(
                                  _vm.cardTypeOptions,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.dictName,
                                        value: item.dictCode,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "证件号码：",
                                prop: "certificateNo",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "22",
                                  placeholder: "请输入证件号码",
                                  clearable: "",
                                },
                                on: {
                                  blur: _vm.certificateNoChange,
                                  input: _vm.inputCardNo,
                                },
                                model: {
                                  value: _vm.form.certificateNo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "certificateNo", $$v)
                                  },
                                  expression: "form.certificateNo",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "手机号：", prop: "mobile" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "11",
                                  placeholder: "请输入手机号",
                                  clearable: "",
                                },
                                on: {
                                  input: function ($event) {
                                    _vm.form.mobile = _vm.form.mobile.replace(
                                      /[^\d]/g,
                                      ""
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.form.mobile,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "mobile", $$v)
                                  },
                                  expression: "form.mobile",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "邮箱：", prop: "email" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "100",
                                  placeholder: "请输入邮箱",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.email,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "email", $$v)
                                  },
                                  expression: "form.email",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "所在省市区：",
                                prop: "addressCode",
                              },
                            },
                            [
                              _c("el-cascader", {
                                ref: "address",
                                staticStyle: { width: "100%" },
                                attrs: {
                                  filterable: "",
                                  clearable: "",
                                  placeholder: "请选择所在省市",
                                  options: _vm.regionOptions,
                                },
                                on: { change: _vm.handleChange },
                                model: {
                                  value: _vm.form.addressCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "addressCode", $$v)
                                  },
                                  expression: "form.addressCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "详细地址：", prop: "address" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "100",
                                  type: "textarea",
                                  placeholder: "请输入详细地址",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.address,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "address", $$v)
                                  },
                                  expression: "form.address",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-check",
                type: "primary",
                plain: "",
                size: "mini",
              },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: {
                click: function ($event) {
                  return _vm.closePage(true)
                },
              },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }