import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import Pagination from '@/components/Pagination';
import servicePackageApi from "@/api/servicePackage";
import { getSupplyers, character, commonExport } from '@/utils/common';
export default {
  name: 'ServicePackageOrder',
  mixins: [initHeight],
  components: {
    Pagination: Pagination
  },
  data: function data() {
    return {
      searchForm: {},
      tableData: [],
      drawer: false,
      loading: false,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      total: 0,
      bzStartEndTime: [],
      bsStartEndTime: [],
      startEndTime: [],
      supplyers: getSupplyers() || []
    };
  },
  created: function created() {
    this.loadData();
  },
  methods: {
    checkValue: function checkValue(val) {
      //移除车架号中的i,o,q
      if (val) {
        val = val.replace(/i/g, '').replace(/I/g, '').replace(/o/g, '').replace(/O/g, '').replace(/q/g, '').replace(/Q/g, '');
      }
      ;
      this.searchForm.vin = character(_.toUpper(val));
    },
    // 导出
    exportFn: function exportFn() {
      var exporParams = {
        total: this.total,
        fileName: '服务包台账',
        exportUrl: '/oc-core-service/servPack/account/search',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    // 日期选择事件
    dateChange: function dateChange(start, end, val) {
      if (val && val.length > 1) {
        this.searchForm[start] = val[0];
        this.searchForm[end] = val[1];
      } else {
        this.searchForm[start] = null;
        this.searchForm[end] = null;
      }
      ;
    },
    // 重置
    resetSearch: function resetSearch() {
      this.searchForm = {};
      this.bzStartEndTime = [];
      this.bsStartEndTime = [];
      this.startEndTime = [];
    },
    // 搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    breakData: function breakData() {
      this.loadData();
    },
    // 获取列表数据
    loadData: function loadData() {
      var _this = this;
      if (this.searchForm && this.searchForm.bzEffectiveStartTimeEnd) {
        this.searchForm.bzEffectiveStartTimeEnd = this.searchForm.bzEffectiveStartTimeEnd.split(' ')[0] + ' 23:59:59';
      }
      ;
      if (this.searchForm && this.searchForm.bsEffectiveStartTimeEnd) {
        this.searchForm.bsEffectiveStartTimeEnd = this.searchForm.bsEffectiveStartTimeEnd.split(' ')[0] + ' 23:59:59';
      }
      ;
      if (this.searchForm && this.searchForm.effectiveStartTimeEnd) {
        this.searchForm.effectiveStartTimeEnd = this.searchForm.effectiveStartTimeEnd.split(' ')[0] + ' 23:59:59';
      }
      ;
      this.loading = true;
      this.drawer = false;
      var data = _objectSpread(_objectSpread({}, this.listQuery), this.searchForm);
      servicePackageApi.getServicePackAccountList(data).then(function (res) {
        _this.loading = false;
        if (res.code === 200) {
          _this.tableData = res.data.list;
          _this.total = res.data.page.recordsTotal;
        }
        ;
      }).catch(function (err) {
        _this.loading = false;
      });
    }
  }
};