import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.regexp.test.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import giftApi from '@/api/giftManagement';
import VouchersWriteOff from './vouchersWriteOff';
import Rules from '@/utils/rules';
import dictRequest from '@/api/dictionary';
import { character } from '@/utils/common';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    opType: {
      type: String,
      default: ''
    },
    currentId: {
      type: String,
      default: ''
    }
  },
  components: {
    VouchersWriteOff: VouchersWriteOff
  },
  data: function data() {
    return {
      form: {
        businessType: 'CX',
        productType: null,
        vin: null
        // orderNo: null,
      },

      rules: {
        businessType: {
          required: true,
          message: '请选择业务类别',
          trigger: "change"
        },
        productType: {
          required: true,
          message: '请选择服务类别',
          trigger: "change"
        },
        vin: [{
          required: true,
          message: '请输入车架号',
          trigger: "blur"
        }, {
          pattern: Rules["VIN_RULE"].value,
          message: "车架号格式有误",
          trigger: ["blur", "change"]
        }]
        // orderNo: {
        //     required: true,
        //     message: '请输入合同编号',
        //     trigger: 'blur',
        // },
      },

      formLoading: false,
      tableData: [],
      showDialog: false,
      currentObj: {},
      productTypeList: []
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
      ;
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    // 初始化数据
    initData: function initData() {
      this.getProductTypeList();
      this.form = {
        businessType: 'CX',
        productType: null,
        vin: null
        // orderNo: null,
      };

      this.showDialog = false;
      this.tableData = [];
      this.$refs.detail.resetFields();
    },
    // 关闭弹窗
    closePage: function closePage() {
      var isRefresh = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      var noCloseF = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      this.showDialog = false;
      if (isRefresh) {
        this.$emit('closePage', noCloseF);
      }
      ;
    },
    // 输入的车架号中的字母自动转大写，并去除i，o，q
    checkValue: function checkValue(val) {
      if (val) {
        val = val.replace(/i/g, '').replace(/I/g, '').replace(/o/g, '').replace(/O/g, '').replace(/q/g, '').replace(/Q/g, '');
      }
      ;
      this.form.vin = character(_.toUpper(val));
    },
    vincheck: function vincheck() {
      var vinNumber = /^\d{17}$/;
      var vinLetter = /^[A-Z]{17}$/;
      if (vinNumber.test(this.form.vin) || vinLetter.test(this.form.vin)) {
        this.$message({
          type: 'error',
          message: '车架号不能纯数字或纯字母，请修改！'
        });
        this.$forceUpdate();
        return false;
      }
      ;
      return true;
    },
    // 检索
    queryCustomerInfo: function queryCustomerInfo() {
      var _this = this;
      if (this.form.businessType == 'CX' && !this.vincheck()) return;
      this.$refs.detail.validate(function (valid) {
        if (valid) {
          _this.formLoading = true;
          giftApi.queryGiftIssueInfo(_this.form).then(function (res) {
            _this.formLoading = false;
            if (res.code === 200 && res.data.length) {
              _this.tableData = res.data;
              _this.$message.success(res.msg);
            } else {
              _this.tableData = [];
              _this.$message.warning("未查到该车辆有效的赠品信息。");
            }
            ;
          }).catch(function (err) {
            _this.formLoading = false;
          });
        }
        ;
      });
    },
    // 核销
    openWriteOff: function openWriteOff(item) {
      this.showDialog = true;
      this.currentObj = item;
      console.log(this.currentObj, 'this.currentObj');
    },
    // 获取服务类别列表
    getProductTypeList: function getProductTypeList() {
      var _this2 = this;
      dictRequest.getDictItemsByCode("NON_VEHICLE_PRODUCT_TYPE").then(function (res) {
        if (res.code === 200) {
          _this2.productTypeList = res.data || [];
        }
        ;
      }).catch(function (err) {});
    },
    // 输入的保单号/合同编号中的字母自动转大写
    checkPolicy: function checkPolicy(val, type) {
      this.form[type] = character(_.toUpper(val));
    },
    businessTypeChange: function businessTypeChange(val) {
      if (val == 'CX') {
        this.form.productType = null;
        // this.form.orderNo = null;
        this.form.vin = null;
      } else if (val == 'ZZ') {
        this.form.vin = null;
      }
      ;
      this.tableData = [];
      // this.$refs['detail'].clearValidate(['productType', 'orderNo', 'vin']);
      this.$refs['detail'].clearValidate(['productType', 'vin']);
    }
  }
};