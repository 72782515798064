import "core-js/modules/es.array.slice.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getBeforeOfDate } from "@/utils/common";
import dict from "@/mixins/dict";
export default {
  name: "NoVehicleInsuranceDetail",
  mixins: [dict],
  props: {
    listData: {
      type: Array,
      required: true
    },
    currentNonInsDetail: {
      type: Object,
      default: function _default() {}
    },
    title: {
      type: String,
      required: true
    },
    insCorpCode: {
      type: String,
      required: true
    },
    proposalStatus: {
      type: String,
      required: false
    }
  },
  data: function data() {
    return {};
  },
  computed: {},
  watch: {
    listData: {
      handler: function handler(newValue) {},
      deep: true
    }
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    getEffectiveStartTime: function getEffectiveStartTime(time) {
      //起保日期即时起保
      if (!time) return "-";
      if (time.indexOf("00:00") != -1 || time.indexOf("00:00:00") != -1) {
        return time.slice(0, 13) + "时";
      }
      return time.slice(0, 13) + "时" + time.slice(14, 16) + '分';
    },
    getEffectiveEndTime: function getEffectiveEndTime(time) {
      //终保日期默认为前一天的24时
      if (!time) return "";
      if (time.indexOf("00:00") != -1 || time.indexOf("00:00:00") != -1) {
        var timeStr = time.substring(0, 10);
        var hour = time.substring(11, 13);
        var min = time.substring(14, 16);
        var date = getBeforeOfDate(timeStr, 1);
        if (hour != '00' || min != '00') {
          date = getBeforeOfDate(timeStr, 0);
        }
        var endTime = hour != '00' ? date + ' ' + hour + '时' : date + " 24时";
        return endTime;
      }
      return time.slice(0, 13) + "时" + time.slice(14, 16) + '分';
    }
  }
};