var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageContainer", [
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.formLoading,
                expression: "formLoading",
              },
            ],
            attrs: { slot: "main" },
            slot: "main",
          },
          [
            _c(
              "div",
              {
                staticClass: "app-m-cls",
                staticStyle: { "min-height": "calc(100vh - 130px)" },
              },
              [
                _c(
                  "div",
                  { ref: "header", staticClass: "header-cls" },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "form",
                        attrs: {
                          model: _vm.form,
                          rules: _vm.formRules,
                          "label-width": "120px",
                          size: "mini",
                        },
                      },
                      [
                        _c(
                          "el-collapse",
                          {
                            staticStyle: { "margin-top": "10px" },
                            model: {
                              value: _vm.activeOne,
                              callback: function ($$v) {
                                _vm.activeOne = $$v
                              },
                              expression: "activeOne",
                            },
                          },
                          [
                            _c(
                              "el-collapse-item",
                              {
                                staticClass: "collapse-item-style",
                                attrs: { name: "1" },
                              },
                              [
                                _c("template", { slot: "title" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "title",
                                      staticStyle: { "line-height": "25px" },
                                    },
                                    [
                                      _c("FormLabel", {
                                        attrs: { "form-label": "出单信息" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c(
                                  "el-row",
                                  { attrs: { gutter: 0 } },
                                  [
                                    _vm.bizType === "OEM"
                                      ? _c(
                                          "el-col",
                                          { attrs: { span: 6 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "组织机构",
                                                  prop: "salesOrgName",
                                                },
                                              },
                                              [
                                                _c("OemSelect", {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    isQuotePage: true,
                                                    searchForm: _vm.orgForm,
                                                    isGetUserList: true,
                                                    isContainOem: false,
                                                  },
                                                  on: {
                                                    nodeClick:
                                                      _vm.handleNodeClick,
                                                    getCurrentOrgUsers:
                                                      _vm.oemGetUsers,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.bizType === "DEALER"
                                      ? _c(
                                          "el-col",
                                          { attrs: { span: 6 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              { attrs: { label: "组织机构" } },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    disabled: "",
                                                    placeholder: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form.salesOrgName,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "salesOrgName",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.salesOrgName",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "el-col",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !_vm.policyId || _vm.canEdit,
                                            expression: "!policyId || canEdit",
                                          },
                                        ],
                                        attrs: { span: 6 },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "业务员",
                                              prop: "salemanBy",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  filterable: "",
                                                  placeholder: "请选择业务员",
                                                },
                                                on: {
                                                  change: _vm.salemanNameChange,
                                                },
                                                model: {
                                                  value: _vm.form.salemanBy,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "salemanBy",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "form.salemanBy",
                                                },
                                              },
                                              _vm._l(
                                                _vm.salesman,
                                                function (item, index) {
                                                  return _c("el-option", {
                                                    key: index,
                                                    attrs: {
                                                      label: item.userName,
                                                      value: item.userId,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 6 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "投保公司",
                                              prop: "insCorpCode",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  filterable: "",
                                                  placeholder: "请选择投保公司",
                                                  clearable: "",
                                                },
                                                model: {
                                                  value: _vm.form.insCorpCode,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "insCorpCode",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.insCorpCode",
                                                },
                                              },
                                              _vm._l(
                                                _vm.supplyers,
                                                function (item, index) {
                                                  return _c("el-option", {
                                                    key: index,
                                                    attrs: {
                                                      label: item.shortName,
                                                      value: item.code,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 6 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "投保地区",
                                              prop: "address",
                                            },
                                          },
                                          [
                                            _c("el-cascader", {
                                              ref: "address",
                                              staticStyle: {
                                                width: "100%",
                                                "max-width": "320px",
                                              },
                                              attrs: {
                                                placeholder: "请选择投保地区",
                                                options: _vm.regionOptions,
                                              },
                                              on: { change: _vm.handleChange },
                                              model: {
                                                value: _vm.form.address,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "address",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.address",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 6 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "承保日期",
                                              prop: "insuredTime",
                                            },
                                          },
                                          [
                                            _c("el-date-picker", {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                "value-format": "yyyy-MM-dd",
                                                type: "date",
                                                placeholder: "请选择承保日期",
                                                "picker-options":
                                                  _vm.disabledInsuredTime,
                                              },
                                              on: {
                                                change: _vm.insuredTimeChange,
                                              },
                                              model: {
                                                value: _vm.form.insuredTime,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "insuredTime",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.insuredTime",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !_vm.policyId,
                                            expression: "!policyId",
                                          },
                                        ],
                                        attrs: { span: 6 },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "出单方式",
                                              prop: "insuredType",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  filterable: "",
                                                  placeholder: "请选择出单方式",
                                                  clearable: "",
                                                },
                                                on: {
                                                  change:
                                                    _vm.selectInsuranceType,
                                                },
                                                model: {
                                                  value: _vm.form.insuredType,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "insuredType",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.insuredType",
                                                },
                                              },
                                              _vm._l(
                                                _vm.dictSource["ORDER"],
                                                function (item, index) {
                                                  return _c("el-option", {
                                                    key: index,
                                                    attrs: {
                                                      label: item.dictName,
                                                      value: item.dictCode,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 6 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "录单人" } },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                maxlength: "40",
                                                placeholder: "请输入录单人",
                                                clearable: "",
                                                disabled: "",
                                              },
                                              model: {
                                                value: _vm.userName,
                                                callback: function ($$v) {
                                                  _vm.userName = $$v
                                                },
                                                expression: "userName",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-collapse",
                          {
                            staticStyle: { "margin-top": "10px" },
                            model: {
                              value: _vm.activeThree,
                              callback: function ($$v) {
                                _vm.activeThree = $$v
                              },
                              expression: "activeThree",
                            },
                          },
                          [
                            _c(
                              "el-collapse-item",
                              {
                                staticClass: "collapse-item-style",
                                attrs: { name: "1" },
                              },
                              [
                                _c("template", { slot: "title" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "title",
                                      staticStyle: { "line-height": "25px" },
                                    },
                                    [
                                      _c("FormLabel", {
                                        attrs: { "form-label": "投保车辆" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c(
                                  "el-row",
                                  { attrs: { gutter: 0 } },
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 6 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.form.quoteVehicle
                                                    .isNewCar,
                                                expression:
                                                  "form.quoteVehicle.isNewCar",
                                              },
                                            ],
                                            attrs: { label: "车牌号" },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "margin-right": "10px",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      ref: "plateNo",
                                                      staticStyle: {
                                                        "margin-right": "10px",
                                                      },
                                                      attrs: {
                                                        disabled:
                                                          _vm.form.quoteVehicle
                                                            .isNewCar,
                                                        minlength: "7",
                                                        maxlength: "8",
                                                        placeholder:
                                                          "例如：粤A12345",
                                                        clearable: "",
                                                      },
                                                      on: {
                                                        input: _vm.changeValue,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form.quoteVehicle
                                                            .plateNo,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form
                                                              .quoteVehicle,
                                                            "plateNo",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.quoteVehicle.plateNo",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "el-checkbox",
                                                  {
                                                    on: {
                                                      change:
                                                        _vm.noPlateNoChange,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form.quoteVehicle
                                                          .isNewCar,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form.quoteVehicle,
                                                          "isNewCar",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.quoteVehicle.isNewCar",
                                                    },
                                                  },
                                                  [_vm._v("未上牌")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        !_vm.form.quoteVehicle.isNewCar
                                          ? _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "车牌号",
                                                  prop: "quoteVehicle.plateNo",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "flex",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          "margin-right":
                                                            "10px",
                                                        },
                                                      },
                                                      [
                                                        _c("el-input", {
                                                          ref: "plateNo",
                                                          staticStyle: {
                                                            "margin-right":
                                                              "10px",
                                                          },
                                                          attrs: {
                                                            minlength: "7",
                                                            maxlength: "8",
                                                            placeholder:
                                                              "例如：粤A12345",
                                                            clearable: "",
                                                          },
                                                          on: {
                                                            input:
                                                              _vm.inputPlateNo,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.form
                                                                .quoteVehicle
                                                                .plateNo,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.form
                                                                  .quoteVehicle,
                                                                "plateNo",
                                                                typeof $$v ===
                                                                  "string"
                                                                  ? $$v.trim()
                                                                  : $$v
                                                              )
                                                            },
                                                            expression:
                                                              "form.quoteVehicle.plateNo",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "el-checkbox",
                                                      {
                                                        on: {
                                                          change:
                                                            _vm.noPlateNoChange,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form
                                                              .quoteVehicle
                                                              .isNewCar,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form
                                                                .quoteVehicle,
                                                              "isNewCar",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form.quoteVehicle.isNewCar",
                                                        },
                                                      },
                                                      [_vm._v("未上牌")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 6 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "车架号",
                                              prop: "quoteVehicle.vin",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                maxlength: "17",
                                                placeholder: "请输入车架号",
                                                clearable: "",
                                              },
                                              on: {
                                                input: _vm.checkValue,
                                                blur: _vm.vincheck,
                                              },
                                              model: {
                                                value:
                                                  _vm.form.quoteVehicle.vin,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form.quoteVehicle,
                                                    "vin",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.quoteVehicle.vin",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 6 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "发动机号" } },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                minlength: "6",
                                                maxlength: "20",
                                                placeholder: "请输入发动机号",
                                                clearable: "",
                                              },
                                              on: { input: _vm.inputEngineNo },
                                              model: {
                                                value:
                                                  _vm.form.quoteVehicle
                                                    .engineNo,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form.quoteVehicle,
                                                    "engineNo",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.quoteVehicle.engineNo",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 6 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "注册日期",
                                              prop: "quoteVehicle.registerDate",
                                            },
                                          },
                                          [
                                            _c("el-date-picker", {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                "value-format": "yyyy-MM-dd",
                                                type: "date",
                                                placeholder: "请选择注册日期",
                                                "picker-options":
                                                  _vm.disabledLessThan,
                                              },
                                              model: {
                                                value:
                                                  _vm.form.quoteVehicle
                                                    .registerDate,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form.quoteVehicle,
                                                    "registerDate",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.quoteVehicle.registerDate",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 6 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "品牌型号" } },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                maxlength: "40",
                                                placeholder: "请输入品牌型号",
                                                clearable: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.form.quoteVehicle
                                                    .modelName,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form.quoteVehicle,
                                                    "modelName",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.quoteVehicle.modelName",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 6 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "是否过户车",
                                              prop: "",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  filterable: "",
                                                  placeholder:
                                                    "请选择是否过户车",
                                                },
                                                on: {
                                                  change: _vm.transferChange,
                                                },
                                                model: {
                                                  value:
                                                    _vm.form.quoteVehicle
                                                      .transferFlag,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form.quoteVehicle,
                                                      "transferFlag",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.quoteVehicle.transferFlag",
                                                },
                                              },
                                              _vm._l(
                                                _vm.transferFlagData,
                                                function (item, index) {
                                                  return _c("el-option", {
                                                    key: index,
                                                    attrs: {
                                                      label: item.label,
                                                      value: item.value,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm.form.quoteVehicle.transferFlag
                                      ? _c(
                                          "el-col",
                                          { attrs: { span: 6 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "过户日期",
                                                  prop: "quoteVehicle.transferDate",
                                                },
                                              },
                                              [
                                                _c("el-date-picker", {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    "value-format":
                                                      "yyyy-MM-dd HH:mm:ss",
                                                    type: "date",
                                                    placeholder: "选择日期",
                                                    "picker-options":
                                                      _vm.disabledLessThan,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form.quoteVehicle
                                                        .transferDate,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form.quoteVehicle,
                                                        "transferDate",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.quoteVehicle.transferDate",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 6 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "座位数",
                                              prop: "",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                maxlength: "3",
                                                placeholder: "请输入座位数",
                                                clearable: "",
                                              },
                                              on: {
                                                input: function ($event) {
                                                  _vm.form.quoteVehicle.seats =
                                                    _vm.onInput(
                                                      _vm.form.quoteVehicle
                                                        .seats,
                                                      0,
                                                      100,
                                                      0
                                                    )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.form.quoteVehicle.seats,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form.quoteVehicle,
                                                    "seats",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.quoteVehicle.seats",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 6 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "尾气排量",
                                              prop: "",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                maxlength: "10",
                                                placeholder: "请输入尾气排量",
                                                oninput:
                                                  "value=value.replace(/[^0-9]{0,1}(\\d*(?:\\.\\d{0,2})?).*$/g, '$1')",
                                                clearable: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.form.quoteVehicle
                                                    .displacement,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form.quoteVehicle,
                                                    "displacement",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.quoteVehicle.displacement",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 6 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "销售价格",
                                              prop: "",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                maxlength: "10",
                                                placeholder: "请输入销售价格",
                                                oninput:
                                                  "value=value.replace(/[^0-9]{0,1}(\\d*(?:\\.\\d{0,2})?).*$/g, '$1')",
                                                clearable: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.form.quoteVehicle
                                                    .purchasePrice,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form.quoteVehicle,
                                                    "purchasePrice",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.quoteVehicle.purchasePrice",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 6 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "行驶证车辆类型",
                                              prop: "quoteVehicle.vehicleType",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  filterable: "",
                                                  placeholder:
                                                    "请选择行驶证车辆类型",
                                                  clearable: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.form.quoteVehicle
                                                      .vehicleType,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form.quoteVehicle,
                                                      "vehicleType",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.quoteVehicle.vehicleType",
                                                },
                                              },
                                              _vm._l(
                                                _vm.VEHICLE_KINDS,
                                                function (item, index) {
                                                  return _c("el-option", {
                                                    key: index,
                                                    attrs: {
                                                      label: item.dictName,
                                                      value: item.dictCode,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 6 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "使用性质",
                                              prop: "quoteVehicle.useAttribute",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  filterable: "",
                                                  placeholder: "请选择使用性质",
                                                  clearable: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.form.quoteVehicle
                                                      .useAttribute,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form.quoteVehicle,
                                                      "useAttribute",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.quoteVehicle.useAttribute",
                                                },
                                              },
                                              _vm._l(
                                                _vm.USE_ATTRIBUTE,
                                                function (item, index) {
                                                  return _c("el-option", {
                                                    key: index,
                                                    attrs: {
                                                      label: item.dictName,
                                                      value: item.dictCode,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 6 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "号牌种类",
                                              prop: "quoteVehicle.plateType",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  filterable: "",
                                                  placeholder: "请选择号牌种类",
                                                  clearable: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.form.quoteVehicle
                                                      .plateType,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form.quoteVehicle,
                                                      "plateType",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.quoteVehicle.plateType",
                                                },
                                              },
                                              _vm._l(
                                                _vm.PLATE_TYPE,
                                                function (item, index) {
                                                  return _c("el-option", {
                                                    key: index,
                                                    attrs: {
                                                      label: item.dictName,
                                                      value: item.dictCode,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 6 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "能源类型",
                                              prop: "",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  filterable: "",
                                                  placeholder: "请选择能源类型",
                                                  clearable: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.form.quoteVehicle
                                                      .fuelType,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form.quoteVehicle,
                                                      "fuelType",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.quoteVehicle.fuelType",
                                                },
                                              },
                                              _vm._l(
                                                _vm.ENGRGY_TYPES,
                                                function (item, index) {
                                                  return _c("el-option", {
                                                    key: index,
                                                    attrs: {
                                                      label: item.dictName,
                                                      value: item.dictCode,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 6 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "核定载质量(吨)",
                                              prop: "",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                maxlength: "10",
                                                placeholder: "请输入核定载质量",
                                                oninput:
                                                  "value=value.replace(/[^0-9]{0,1}(\\d*(?:\\.\\d{0,2})?).*$/g, '$1')",
                                                clearable: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.form.quoteVehicle
                                                    .tonNumber,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form.quoteVehicle,
                                                    "tonNumber",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.quoteVehicle.tonNumber",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 6 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "整备质量(吨)",
                                              prop: "",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                maxlength: "10",
                                                placeholder: "请输入整备质量",
                                                oninput:
                                                  "value=value.replace(/[^0-9]{0,1}(\\d*(?:\\.\\d{0,2})?).*$/g, '$1')",
                                                clearable: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.form.quoteVehicle
                                                    .wholeWeight,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form.quoteVehicle,
                                                    "wholeWeight",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.quoteVehicle.wholeWeight",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 6 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "功率(KW)",
                                              prop: "",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                maxlength: "10",
                                                placeholder: "请输入功率",
                                                oninput:
                                                  "value=value.replace(/[^0-9]{0,1}(\\d*(?:\\.\\d{0,2})?).*$/g, '$1')",
                                                clearable: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.form.quoteVehicle.power,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form.quoteVehicle,
                                                    "power",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.quoteVehicle.power",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "厂牌车型",
                                              prop: "",
                                            },
                                          },
                                          [
                                            _c("CarBrands", {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                carTypeValue: _vm.carTypeVal,
                                              },
                                              on: {
                                                carHandleChanged:
                                                  _vm.CarHandleChange,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-collapse",
                          {
                            staticStyle: { "margin-top": "10px" },
                            model: {
                              value: _vm.activeTwo,
                              callback: function ($$v) {
                                _vm.activeTwo = $$v
                              },
                              expression: "activeTwo",
                            },
                          },
                          [
                            _c(
                              "el-collapse-item",
                              {
                                staticClass: "collapse-item-style",
                                attrs: { name: "1" },
                              },
                              [
                                _c("template", { slot: "title" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "title",
                                      staticStyle: { "line-height": "25px" },
                                    },
                                    [
                                      _c("FormLabel", {
                                        attrs: { "form-label": "车主信息" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c(
                                  "el-row",
                                  { attrs: { gutter: 0 } },
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 6 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "车主名",
                                              prop: "quoteVehicle.ownerName",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                maxlength: "40",
                                                placeholder: "请输入车主名",
                                                clearable: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.form.quoteVehicle
                                                    .ownerName,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form.quoteVehicle,
                                                    "ownerName",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.quoteVehicle.ownerName",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 6 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "证件类型",
                                              prop: "quoteVehicle.ownerCertificateType",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  filterable: "",
                                                  placeholder: "请选择证件类型",
                                                  clearable: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.form.quoteVehicle
                                                      .ownerCertificateType,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form.quoteVehicle,
                                                      "ownerCertificateType",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.quoteVehicle.ownerCertificateType",
                                                },
                                              },
                                              _vm._l(
                                                _vm.VI_CERTIFICATE_TYPE,
                                                function (item, index) {
                                                  return _c("el-option", {
                                                    key: index,
                                                    attrs: {
                                                      label: item.dictName,
                                                      value: item.dictCode,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 6 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "车主证件号",
                                              prop: "quoteVehicle.ownerCertificateNo",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                maxlength: "18",
                                                placeholder: "请输入车主证件号",
                                                clearable: "",
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.certificateLimitInput(
                                                    $event,
                                                    "ownerCertificateNo"
                                                  )
                                                },
                                                blur: _vm.certificateNoChange,
                                              },
                                              model: {
                                                value:
                                                  _vm.form.quoteVehicle
                                                    .ownerCertificateNo,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form.quoteVehicle,
                                                    "ownerCertificateNo",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.quoteVehicle.ownerCertificateNo",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 6 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "车主联系方式",
                                              prop: "quoteVehicle.ownerMobile",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                placeholder:
                                                  "请输入车主联系方式",
                                                maxlength: "11",
                                                oninput:
                                                  "value=value.replace(/[^\\d]/g,'')",
                                                clearable: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.form.quoteVehicle
                                                    .ownerMobile,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form.quoteVehicle,
                                                    "ownerMobile",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.quoteVehicle.ownerMobile",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-collapse",
                          {
                            staticStyle: { "margin-top": "10px" },
                            model: {
                              value: _vm.activeFour,
                              callback: function ($$v) {
                                _vm.activeFour = $$v
                              },
                              expression: "activeFour",
                            },
                          },
                          [
                            _c(
                              "el-collapse-item",
                              {
                                staticClass: "collapse-item-style",
                                attrs: { name: "1" },
                              },
                              [
                                _c("template", { slot: "title" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "title",
                                      staticStyle: { "line-height": "25px" },
                                    },
                                    [
                                      _c("FormLabel", {
                                        attrs: { "form-label": "关系人信息" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "line-height": "40px",
                                      "padding-left": "18px",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "font-size": "13px",
                                          "font-weight": "bold",
                                          "margin-right": "8px",
                                        },
                                      },
                                      [_vm._v("投保人")]
                                    ),
                                    _c(
                                      "el-checkbox",
                                      {
                                        on: {
                                          change: function (val) {
                                            _vm.handleCheckedCarOwner(
                                              val,
                                              "applicant"
                                            )
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.form.applicant
                                              .applicantSameAsOwner,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.applicant,
                                              "applicantSameAsOwner",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "form.applicant.applicantSameAsOwner",
                                        },
                                      },
                                      [_vm._v("同车主")]
                                    ),
                                  ],
                                  1
                                ),
                                !_vm.form.applicant.applicantSameAsOwner
                                  ? _c(
                                      "el-row",
                                      { attrs: { gutter: 0 } },
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 6 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "名称",
                                                  prop: "applicant.name",
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    maxlength: "40",
                                                    placeholder: "请输入名称",
                                                    clearable: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form.applicant.name,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form.applicant,
                                                        "name",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.applicant.name",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 6 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "证件类型",
                                                  prop: "applicant.certificateType",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    staticStyle: {
                                                      width: "100%",
                                                    },
                                                    attrs: {
                                                      filterable: "",
                                                      placeholder:
                                                        "请选择证件类型",
                                                      clearable: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form.applicant
                                                          .certificateType,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form.applicant,
                                                          "certificateType",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.applicant.certificateType",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.VI_CERTIFICATE_TYPE,
                                                    function (item, index) {
                                                      return _c("el-option", {
                                                        key: index,
                                                        attrs: {
                                                          label: item.dictName,
                                                          value: item.dictCode,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 6 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "证件号",
                                                  prop: "applicant.certificateNo",
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    maxlength: "25",
                                                    placeholder: "请输入证件号",
                                                    clearable: "",
                                                  },
                                                  on: {
                                                    blur: _vm.applicantCertificateNoChange,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form.applicant
                                                        .certificateNo,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form.applicant,
                                                        "certificateNo",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.applicant.certificateNo",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 6 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "联系方式",
                                                  prop: "applicant.mobile",
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    placeholder:
                                                      "请输入投保人手机号",
                                                    maxlength: "11",
                                                    clearable: "",
                                                    oninput:
                                                      "value=value.replace(/[^\\d]/g,'')",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form.applicant.mobile,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form.applicant,
                                                        "mobile",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.applicant.mobile",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "line-height": "40px",
                                      "padding-left": "18px",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "font-size": "13px",
                                          "font-weight": "bold",
                                          "margin-right": "8px",
                                        },
                                      },
                                      [_vm._v("被保人")]
                                    ),
                                    !_vm.form.insurant.sameAsInsurer
                                      ? _c(
                                          "el-checkbox",
                                          {
                                            on: {
                                              change: function (val) {
                                                _vm.handleCheckedCarOwner(
                                                  val,
                                                  "insurant"
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.form.insurant.sameAsOwner,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form.insurant,
                                                  "sameAsOwner",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.insurant.sameAsOwner",
                                            },
                                          },
                                          [_vm._v("同车主")]
                                        )
                                      : _vm._e(),
                                    !_vm.form.insurant.sameAsOwner
                                      ? _c(
                                          "el-checkbox",
                                          {
                                            on: {
                                              change:
                                                _vm.handleCheckedApplicant,
                                            },
                                            model: {
                                              value:
                                                _vm.form.insurant.sameAsInsurer,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form.insurant,
                                                  "sameAsInsurer",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.insurant.sameAsInsurer",
                                            },
                                          },
                                          [_vm._v("同投保人")]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                !_vm.form.insurant.sameAsOwner &&
                                !_vm.form.insurant.sameAsInsurer
                                  ? _c(
                                      "el-row",
                                      { attrs: { gutter: 0 } },
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 6 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "名称",
                                                  prop: "insurant.name",
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    maxlength: "40",
                                                    placeholder: "请输入名称",
                                                    clearable: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form.insurant.name,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form.insurant,
                                                        "name",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.insurant.name",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 6 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "证件类型",
                                                  prop: "insurant.certificateType",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    staticStyle: {
                                                      width: "100%",
                                                    },
                                                    attrs: {
                                                      filterable: "",
                                                      placeholder:
                                                        "请选择证件类型",
                                                      clearable: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form.insurant
                                                          .certificateType,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form.insurant,
                                                          "certificateType",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.insurant.certificateType",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.VI_CERTIFICATE_TYPE,
                                                    function (item, index) {
                                                      return _c("el-option", {
                                                        key: index,
                                                        attrs: {
                                                          label: item.dictName,
                                                          value: item.dictCode,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 6 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "证件号",
                                                  prop: "insurant.certificateNo",
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    maxlength: "25",
                                                    placeholder: "请输入证件号",
                                                    clearable: "",
                                                  },
                                                  on: {
                                                    blur: _vm.insurantCertificateNoChange,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form.insurant
                                                        .certificateNo,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form.insurant,
                                                        "certificateNo",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.insurant.certificateNo",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 6 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "联系方式",
                                                  prop: "insurant.mobile",
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    placeholder:
                                                      "请输入联系方式",
                                                    maxlength: "11",
                                                    clearable: "",
                                                    oninput:
                                                      "value=value.replace(/[^\\d]/g,'')",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form.insurant.mobile,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form.insurant,
                                                        "mobile",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.insurant.mobile",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _vm.showInsuranceType !== "2"
                          ? _c(
                              "el-collapse",
                              {
                                staticStyle: { "margin-top": "10px" },
                                model: {
                                  value: _vm.activeFive,
                                  callback: function ($$v) {
                                    _vm.activeFive = $$v
                                  },
                                  expression: "activeFive",
                                },
                              },
                              [
                                _c(
                                  "el-collapse-item",
                                  {
                                    staticClass: "collapse-item-style",
                                    attrs: { name: "1" },
                                  },
                                  [
                                    _c("template", { slot: "title" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "title",
                                          staticStyle: {
                                            "line-height": "25px",
                                          },
                                        },
                                        [
                                          _c("FormLabel", {
                                            attrs: { "form-label": "保单信息" },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c(
                                      "el-row",
                                      { attrs: { gutter: 0 } },
                                      [
                                        _vm.showInsuranceType !== "0"
                                          ? _c(
                                              "el-col",
                                              { attrs: { span: 6 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "交强险保单号",
                                                      prop: "bzRiskNo",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      attrs: {
                                                        maxlength: "40",
                                                        placeholder:
                                                          "请输入交强险保单号",
                                                        clearable: "",
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          return _vm.checkBzRiskNo(
                                                            _vm.form.bzRiskNo
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form.bzRiskNo,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form,
                                                            "bzRiskNo",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.bzRiskNo",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.showInsuranceType !== "0"
                                          ? _c(
                                              "el-col",
                                              { attrs: { span: 6 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "交强险起保日期",
                                                      prop: "bzRiskKind.effectiveStartTime",
                                                    },
                                                  },
                                                  [
                                                    _c("el-date-picker", {
                                                      staticStyle: {
                                                        width: "100%",
                                                      },
                                                      attrs: {
                                                        "value-format":
                                                          "yyyy-MM-dd",
                                                        type: "date",
                                                        placeholder:
                                                          "请选择交强险起保日期",
                                                        "picker-options":
                                                          _vm.disabledEffectiveTime,
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.bzDatePickerChange(
                                                            _vm.form
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form.bzRiskKind
                                                            .effectiveStartTime,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form.bzRiskKind,
                                                            "effectiveStartTime",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.bzRiskKind.effectiveStartTime",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.showInsuranceType !== "0"
                                          ? _c(
                                              "el-col",
                                              { attrs: { span: 6 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "交强险终保日期",
                                                      prop: "bzRiskKind.effectiveEndTime",
                                                    },
                                                  },
                                                  [
                                                    _c("el-date-picker", {
                                                      staticStyle: {
                                                        width: "100%",
                                                      },
                                                      attrs: {
                                                        "value-format":
                                                          "yyyy-MM-dd",
                                                        type: "date",
                                                        placeholder:
                                                          "请选择交强险终保日期",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form.bzRiskKind
                                                            .effectiveEndTime,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form.bzRiskKind,
                                                            "effectiveEndTime",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.bzRiskKind.effectiveEndTime",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.showInsuranceType !== "0"
                                          ? _c(
                                              "el-col",
                                              { attrs: { span: 6 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "交强险折扣系数",
                                                      prop: "",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      attrs: {
                                                        maxlength: "10",
                                                        placeholder:
                                                          "请输入交强险折扣系数",
                                                        oninput:
                                                          "value=value.replace(/[^0-9]{0,1}(\\d*(?:\\.\\d{0,2})?).*$/g, '$1')",
                                                        clearable: "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form
                                                            .bzDiscountFactor,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form,
                                                            "bzDiscountFactor",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.bzDiscountFactor",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.showInsuranceType !== "0"
                                          ? _c(
                                              "el-col",
                                              { attrs: { span: 6 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "交强险费率",
                                                      prop: "bzRiskKind.poundageRate",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        attrs: {
                                                          slot: "label",
                                                        },
                                                        slot: "label",
                                                      },
                                                      [
                                                        _c(
                                                          "el-tooltip",
                                                          {
                                                            attrs: {
                                                              placement: "top",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                attrs: {
                                                                  slot: "content",
                                                                },
                                                                slot: "content",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "优先取费用政策配置的费率，政策如未配置，取保险公司协议约定的最高费率"
                                                                ),
                                                              ]
                                                            ),
                                                            _c("i", {
                                                              staticClass:
                                                                "el-icon-warning-outline",
                                                              staticStyle: {
                                                                "font-size":
                                                                  "14px",
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                        _vm._v(" 交强险费率 "),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "el-input",
                                                      {
                                                        attrs: {
                                                          maxlength: "10",
                                                          placeholder:
                                                            "请输入交强险费率",
                                                          oninput:
                                                            "value=value.replace(/[^0-9]{0,1}(\\d*(?:\\.\\d{0,2})?).*$/g, '$1')",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form.bzRiskKind
                                                              .poundageRate,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form
                                                                .bzRiskKind,
                                                              "poundageRate",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form.bzRiskKind.poundageRate",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "i",
                                                          {
                                                            staticStyle: {
                                                              "font-style":
                                                                "normal",
                                                              "margin-right":
                                                                "4px",
                                                              "font-size":
                                                                "14px",
                                                            },
                                                            attrs: {
                                                              slot: "suffix",
                                                            },
                                                            slot: "suffix",
                                                          },
                                                          [_vm._v("%")]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.showInsuranceType !== "0"
                                          ? _c(
                                              "el-col",
                                              { attrs: { span: 6 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "交强险金额",
                                                      prop: "bzPremium",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      attrs: {
                                                        maxlength: "10",
                                                        placeholder:
                                                          "请输入交强险金额",
                                                        clearable: "",
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          _vm.form.bzPremium =
                                                            _vm.onInput(
                                                              _vm.form
                                                                .bzPremium,
                                                              0,
                                                              5000,
                                                              2
                                                            )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form.bzPremium,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form,
                                                            "bzPremium",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.bzPremium",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.showInsuranceType !== "0"
                                          ? _c(
                                              "el-col",
                                              { attrs: { span: 6 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "车船税当年应缴",
                                                      prop: "quoteVehicleTax.curYearTax",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      attrs: {
                                                        maxlength: "10",
                                                        placeholder:
                                                          "请输入车船税当年应缴",
                                                        oninput:
                                                          "value=value.replace(/[^0-9]{0,1}(\\d*(?:\\.\\d{0,2})?).*$/g, '$1')",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form
                                                            .quoteVehicleTax
                                                            .curYearTax,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form
                                                              .quoteVehicleTax,
                                                            "curYearTax",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.quoteVehicleTax.curYearTax",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.showInsuranceType !== "0"
                                          ? _c(
                                              "el-col",
                                              { attrs: { span: 6 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "车船税往年补缴",
                                                      prop: "quoteVehicleTax.lastYearPay",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      attrs: {
                                                        maxlength: "10",
                                                        placeholder:
                                                          "请输入车船税往年补缴",
                                                        oninput:
                                                          "value=value.replace(/[^0-9]{0,1}(\\d*(?:\\.\\d{0,2})?).*$/g, '$1')",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form
                                                            .quoteVehicleTax
                                                            .lastYearPay,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form
                                                              .quoteVehicleTax,
                                                            "lastYearPay",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.quoteVehicleTax.lastYearPay",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.showInsuranceType !== "0"
                                          ? _c(
                                              "el-col",
                                              { attrs: { span: 6 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "车船税滞纳金",
                                                      prop: "quoteVehicleTax.delayPayMoney",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      attrs: {
                                                        maxlength: "10",
                                                        placeholder:
                                                          "请输入车船税滞纳金",
                                                        oninput:
                                                          "value=value.replace(/[^0-9]{0,1}(\\d*(?:\\.\\d{0,2})?).*$/g, '$1')",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form
                                                            .quoteVehicleTax
                                                            .delayPayMoney,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form
                                                              .quoteVehicleTax,
                                                            "delayPayMoney",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.quoteVehicleTax.delayPayMoney",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.showInsuranceType == "01" ||
                                        _vm.showInsuranceType == "3"
                                          ? _c("el-col", {
                                              staticStyle: {
                                                width: "100%",
                                                "min-height": "1px",
                                              },
                                              attrs: { span: 24 },
                                            })
                                          : _vm._e(),
                                        _vm.showInsuranceType !== "1"
                                          ? _c(
                                              "el-col",
                                              { attrs: { span: 6 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "商业险保单号",
                                                      prop: "bsRiskNo",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      attrs: {
                                                        maxlength: "40",
                                                        placeholder:
                                                          "请输入商业险保单号",
                                                        clearable: "",
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          return _vm.checkBsRiskNo(
                                                            _vm.form.bsRiskNo
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form.bsRiskNo,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form,
                                                            "bsRiskNo",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.bsRiskNo",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.showInsuranceType !== "1"
                                          ? _c(
                                              "el-col",
                                              { attrs: { span: 6 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "商业险起保日期",
                                                      prop: "bsRiskKind.effectiveStartTime",
                                                    },
                                                  },
                                                  [
                                                    _c("el-date-picker", {
                                                      staticStyle: {
                                                        width: "100%",
                                                      },
                                                      attrs: {
                                                        "value-format":
                                                          "yyyy-MM-dd",
                                                        type: "date",
                                                        placeholder:
                                                          "请选择商业险起保日期",
                                                        "picker-options":
                                                          _vm.disabledEffectiveTime,
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.bsDatePickerChange(
                                                            _vm.form
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form.bsRiskKind
                                                            .effectiveStartTime,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form.bsRiskKind,
                                                            "effectiveStartTime",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.bsRiskKind.effectiveStartTime",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.showInsuranceType !== "1"
                                          ? _c(
                                              "el-col",
                                              { attrs: { span: 6 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "商业险终保日期",
                                                      prop: "bsRiskKind.effectiveEndTime",
                                                    },
                                                  },
                                                  [
                                                    _c("el-date-picker", {
                                                      staticStyle: {
                                                        width: "100%",
                                                      },
                                                      attrs: {
                                                        "value-format":
                                                          "yyyy-MM-dd",
                                                        type: "date",
                                                        placeholder:
                                                          "请选择商业险终保日期",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form.bsRiskKind
                                                            .effectiveEndTime,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form.bsRiskKind,
                                                            "effectiveEndTime",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.bsRiskKind.effectiveEndTime",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.showInsuranceType !== "1"
                                          ? _c(
                                              "el-col",
                                              { attrs: { span: 6 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "商业险折扣系数",
                                                      prop: "bsDiscountFactor",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      attrs: {
                                                        maxlength: "10",
                                                        placeholder:
                                                          "请输入商业险折扣系数",
                                                        oninput:
                                                          "value=value.replace(/[^0-9]{0,1}(\\d*(?:\\.\\d{0,2})?).*$/g, '$1')",
                                                        clearable: "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form
                                                            .bsDiscountFactor,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form,
                                                            "bsDiscountFactor",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.bsDiscountFactor",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.showInsuranceType !== "1"
                                          ? _c(
                                              "el-col",
                                              { attrs: { span: 6 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "商业险费率",
                                                      prop: "bsRiskKind.poundageRate",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        attrs: {
                                                          slot: "label",
                                                        },
                                                        slot: "label",
                                                      },
                                                      [
                                                        _c(
                                                          "el-tooltip",
                                                          {
                                                            attrs: {
                                                              placement: "top",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                attrs: {
                                                                  slot: "content",
                                                                },
                                                                slot: "content",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "优先取费用政策配置的费率，政策如未配置，取保险公司协议约定的最高费率"
                                                                ),
                                                              ]
                                                            ),
                                                            _c("i", {
                                                              staticClass:
                                                                "el-icon-warning-outline",
                                                              staticStyle: {
                                                                "font-size":
                                                                  "14px",
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                        _vm._v(" 商业险费率 "),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "el-input",
                                                      {
                                                        attrs: {
                                                          maxlength: "10",
                                                          placeholder:
                                                            "请输入商业险费率",
                                                          oninput:
                                                            "value=value.replace(/[^0-9]{0,1}(\\d*(?:\\.\\d{0,2})?).*$/g, '$1')",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form.bsRiskKind
                                                              .poundageRate,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form
                                                                .bsRiskKind,
                                                              "poundageRate",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form.bsRiskKind.poundageRate",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "i",
                                                          {
                                                            staticStyle: {
                                                              "font-style":
                                                                "normal",
                                                              "margin-right":
                                                                "4px",
                                                              "font-size":
                                                                "14px",
                                                            },
                                                            attrs: {
                                                              slot: "suffix",
                                                            },
                                                            slot: "suffix",
                                                          },
                                                          [_vm._v("%")]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.showInsuranceType !== "1"
                                          ? _c(
                                              "el-col",
                                              { attrs: { span: 6 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "商业险总金额",
                                                      prop: "bsPremium",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      attrs: {
                                                        maxlength: "10",
                                                        placeholder:
                                                          "请输入商业险总金额",
                                                        disabled: "",
                                                        oninput:
                                                          "value=value.replace(/[^0-9]{0,1}(\\d*(?:\\.\\d{0,2})?).*$/g, '$1')",
                                                        clearable: "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form.bsPremium,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form,
                                                            "bsPremium",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.bsPremium",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.showInsuranceType == "01" ||
                                        _vm.showInsuranceType == "3"
                                          ? _c("el-col", {
                                              staticStyle: {
                                                width: "100%",
                                                "min-height": "1px",
                                              },
                                              attrs: { span: 24 },
                                            })
                                          : _vm._e(),
                                        _vm.showInsuranceType == "3"
                                          ? _c(
                                              "el-col",
                                              { attrs: { span: 6 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "驾意险保单号",
                                                      prop: "fcBsRiskNo",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      attrs: {
                                                        maxlength: "40",
                                                        placeholder:
                                                          "请输入驾意险保单号",
                                                        clearable: "",
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          return _vm.fcCheckBsRiskNo(
                                                            _vm.form.fcBsRiskNo
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form.fcBsRiskNo,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form,
                                                            "fcBsRiskNo",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.fcBsRiskNo",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.showInsuranceType == "3"
                                          ? _c(
                                              "el-col",
                                              { attrs: { span: 6 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "驾意险起保日期",
                                                      prop: "fcRiskKind.effectiveStartTime",
                                                    },
                                                  },
                                                  [
                                                    _c("el-date-picker", {
                                                      staticStyle: {
                                                        width: "100%",
                                                      },
                                                      attrs: {
                                                        "value-format":
                                                          "yyyy-MM-dd",
                                                        type: "date",
                                                        placeholder:
                                                          "请选择驾意险起保日期",
                                                        "picker-options":
                                                          _vm.disabledEffectiveTime,
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.fcDatePickerChange(
                                                            _vm.form
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form.fcRiskKind
                                                            .effectiveStartTime,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form.fcRiskKind,
                                                            "effectiveStartTime",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.fcRiskKind.effectiveStartTime",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.showInsuranceType == "3"
                                          ? _c(
                                              "el-col",
                                              { attrs: { span: 6 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "驾意险终保日期",
                                                      prop: "fcRiskKind.effectiveEndTime",
                                                    },
                                                  },
                                                  [
                                                    _c("el-date-picker", {
                                                      staticStyle: {
                                                        width: "100%",
                                                      },
                                                      attrs: {
                                                        "value-format":
                                                          "yyyy-MM-dd",
                                                        type: "date",
                                                        placeholder:
                                                          "请选择驾意险终保日期",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form.fcRiskKind
                                                            .effectiveEndTime,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form.fcRiskKind,
                                                            "effectiveEndTime",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.fcRiskKind.effectiveEndTime",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.showInsuranceType == "3"
                                          ? _c(
                                              "el-col",
                                              { attrs: { span: 6 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "驾意险费率",
                                                      prop: "fcRiskKind.poundageRate",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        attrs: {
                                                          slot: "label",
                                                        },
                                                        slot: "label",
                                                      },
                                                      [
                                                        _c(
                                                          "el-tooltip",
                                                          {
                                                            attrs: {
                                                              placement: "top",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                attrs: {
                                                                  slot: "content",
                                                                },
                                                                slot: "content",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "优先取费用政策配置的费率，政策如未配置，取保险公司协议约定的最高费率"
                                                                ),
                                                              ]
                                                            ),
                                                            _c("i", {
                                                              staticClass:
                                                                "el-icon-warning-outline",
                                                              staticStyle: {
                                                                "font-size":
                                                                  "14px",
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                        _vm._v(" 驾意险费率 "),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "el-input",
                                                      {
                                                        attrs: {
                                                          maxlength: "10",
                                                          placeholder:
                                                            "请输入驾意险费率",
                                                          oninput:
                                                            "value=value.replace(/[^0-9]{0,1}(\\d*(?:\\.\\d{0,2})?).*$/g, '$1')",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form.fcRiskKind
                                                              .poundageRate,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form
                                                                .fcRiskKind,
                                                              "poundageRate",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form.fcRiskKind.poundageRate",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "i",
                                                          {
                                                            staticStyle: {
                                                              "font-style":
                                                                "normal",
                                                              "margin-right":
                                                                "4px",
                                                              "font-size":
                                                                "14px",
                                                            },
                                                            attrs: {
                                                              slot: "suffix",
                                                            },
                                                            slot: "suffix",
                                                          },
                                                          [_vm._v("%")]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.showInsuranceType == "3"
                                          ? _c(
                                              "el-col",
                                              { attrs: { span: 6 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "驾意险总金额",
                                                      prop: "fcPremium",
                                                      rules: [
                                                        {
                                                          required: true,
                                                          message:
                                                            "请输入驾意险总金额",
                                                          trigger: "blur",
                                                        },
                                                      ],
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      attrs: {
                                                        maxlength: "10",
                                                        placeholder:
                                                          "请输入驾意险总金额",
                                                        clearable: "",
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          _vm.form.fcPremium =
                                                            _vm.onInput(
                                                              _vm.form
                                                                .fcPremium,
                                                              0,
                                                              10000,
                                                              2
                                                            )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form.fcPremium,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form,
                                                            "fcPremium",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.fcPremium",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.showInsuranceType == "01" ||
                                        _vm.showInsuranceType == "3"
                                          ? _c("el-col", {
                                              staticStyle: {
                                                width: "100%",
                                                "min-height": "1px",
                                              },
                                              attrs: { span: 24 },
                                            })
                                          : _vm._e(),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 6 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              { attrs: { label: "总保单号" } },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    maxlength: "40",
                                                    placeholder:
                                                      "请输入总保单号",
                                                    clearable: "",
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      return _vm.checkRiskNo(
                                                        _vm.form.riskNo
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: _vm.form.riskNo,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "riskNo",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "form.riskNo",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 6 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              { attrs: { label: "总保费" } },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    maxlength: "10",
                                                    disabled: "",
                                                    placeholder: "请输入总保费",
                                                    oninput:
                                                      "value=value.replace(/[^0-9]{0,1}(\\d*(?:\\.\\d{0,2})?).*$/g, '$1')",
                                                    clearable: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form.totalPremium,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "totalPremium",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.totalPremium",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 6 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "续保标识",
                                                  prop: "renewalType",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    staticStyle: {
                                                      width: "100%",
                                                    },
                                                    attrs: {
                                                      filterable: "",
                                                      placeholder:
                                                        "请选择续保标识",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form.renewalType,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "renewalType",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.renewalType",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.dictSource[
                                                      "RENEWAL_STATUS"
                                                    ],
                                                    function (item, index) {
                                                      return _c("el-option", {
                                                        key: index,
                                                        attrs: {
                                                          label: item.dictName,
                                                          value: item.dictCode,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 6 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "互联网标识",
                                                  prop: "internetStamp",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    staticStyle: {
                                                      width: "100%",
                                                    },
                                                    attrs: {
                                                      filterable: "",
                                                      placeholder:
                                                        "请选择互联网标识",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form.internetStamp,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "internetStamp",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.internetStamp",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.transferFlagData,
                                                    function (item, index) {
                                                      return _c("el-option", {
                                                        key: index,
                                                        attrs: {
                                                          label: item.label,
                                                          value: item.value,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 6 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "涉农标识",
                                                  prop: "agricultureStamp",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    staticStyle: {
                                                      width: "100%",
                                                    },
                                                    attrs: {
                                                      filterable: "",
                                                      placeholder:
                                                        "请选择涉农标识",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form
                                                          .agricultureStamp,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "agricultureStamp",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.agricultureStamp",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.transferFlagData,
                                                    function (item, index) {
                                                      return _c("el-option", {
                                                        key: index,
                                                        attrs: {
                                                          label: item.label,
                                                          value: item.value,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  2
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.showInsuranceType !== "1" &&
                        _vm.showInsuranceType !== "2"
                          ? _c(
                              "el-collapse",
                              {
                                staticStyle: { "margin-top": "10px" },
                                model: {
                                  value: _vm.activeSix,
                                  callback: function ($$v) {
                                    _vm.activeSix = $$v
                                  },
                                  expression: "activeSix",
                                },
                              },
                              [
                                _c(
                                  "el-collapse-item",
                                  {
                                    staticClass: "collapse-item-style",
                                    attrs: { name: "1" },
                                  },
                                  [
                                    _c("template", { slot: "title" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "title",
                                          staticStyle: {
                                            "line-height": "25px",
                                          },
                                        },
                                        [
                                          _c("FormLabel", {
                                            attrs: {
                                              "form-label": "商业险险别信息",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c(
                                      "table",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: { border: "1" },
                                      },
                                      [
                                        _c("colgroup", [
                                          _c("col", {
                                            staticStyle: { width: "30%" },
                                          }),
                                          _c("col"),
                                          _c("col", {
                                            staticStyle: { width: "30%" },
                                          }),
                                          _c("col", {
                                            staticStyle: { width: "5%" },
                                          }),
                                        ]),
                                        _c(
                                          "thead",
                                          {
                                            staticStyle: {
                                              "background-color": "#eeee",
                                            },
                                          },
                                          [
                                            _c(
                                              "tr",
                                              {
                                                staticStyle: {
                                                  "font-size": "13px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "col" } },
                                                  [
                                                    _c(
                                                      "span",
                                                      { staticClass: "redDot" },
                                                      [_vm._v("*")]
                                                    ),
                                                    _vm._v("险别名称"),
                                                  ]
                                                ),
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "col" } },
                                                  [
                                                    _c(
                                                      "span",
                                                      { staticClass: "redDot" },
                                                      [_vm._v("*")]
                                                    ),
                                                    _vm._v(
                                                      "保险金额/赔偿限额(¥)"
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "col" } },
                                                  [
                                                    _c(
                                                      "span",
                                                      { staticClass: "redDot" },
                                                      [_vm._v("*")]
                                                    ),
                                                    _vm._v("保费(¥)"),
                                                  ]
                                                ),
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "col" } },
                                                  [_vm._v("操作")]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "tbody",
                                          [
                                            _vm._l(
                                              _vm.form.quoteDutys,
                                              function (item, index) {
                                                return _c(
                                                  "tr",
                                                  {
                                                    key: index,
                                                    staticClass: "tableTr",
                                                  },
                                                  [
                                                    _c(
                                                      "td",
                                                      [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              "label-width":
                                                                "0px",
                                                              prop:
                                                                "quoteDutys." +
                                                                index +
                                                                ".riskkindCode",
                                                              rules: [
                                                                {
                                                                  required: true,
                                                                  message:
                                                                    "请选择险别名称",
                                                                  trigger:
                                                                    "change",
                                                                },
                                                              ],
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-select",
                                                              {
                                                                staticStyle: {
                                                                  width: "100%",
                                                                },
                                                                attrs: {
                                                                  filterable:
                                                                    "",
                                                                  placeholder:
                                                                    "请选择险别名称",
                                                                  clearable: "",
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleRiskItem(
                                                                        item
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    item.riskkindCode,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        item,
                                                                        "riskkindCode",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "item.riskkindCode",
                                                                },
                                                              },
                                                              _vm._l(
                                                                _vm.dutyAllCode,
                                                                function (
                                                                  item,
                                                                  index
                                                                ) {
                                                                  return _c(
                                                                    "el-option",
                                                                    {
                                                                      key: index,
                                                                      attrs: {
                                                                        label:
                                                                          item.dictCode +
                                                                          "-" +
                                                                          item.dictName,
                                                                        value:
                                                                          item.dictCode,
                                                                      },
                                                                    }
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c("td", [
                                                      item.riskkindCode
                                                        ? _c(
                                                            "div",
                                                            [
                                                              item.riskkindCode ==
                                                              "B"
                                                                ? _c(
                                                                    "el-form-item",
                                                                    {
                                                                      attrs: {
                                                                        "label-width":
                                                                          "0px",
                                                                        prop:
                                                                          "quoteDutys." +
                                                                          index +
                                                                          ".unitAmount",
                                                                        rules: [
                                                                          {
                                                                            required: true,
                                                                            message:
                                                                              "请选择保险金额",
                                                                            trigger:
                                                                              "change",
                                                                          },
                                                                        ],
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "el-select",
                                                                        {
                                                                          ref:
                                                                            "riskItem" +
                                                                            index,
                                                                          refInFor: true,
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "100%",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              filterable:
                                                                                "",
                                                                              "allow-create":
                                                                                "",
                                                                              "default-first-option":
                                                                                "",
                                                                              placeholder:
                                                                                "请选择保额",
                                                                            },
                                                                          nativeOn:
                                                                            {
                                                                              input:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.filterData(
                                                                                    index
                                                                                  )
                                                                                },
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                item.unitAmount,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    item,
                                                                                    "unitAmount",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "item.unitAmount",
                                                                            },
                                                                        },
                                                                        _vm._l(
                                                                          _vm.SZX_OPTIONS,
                                                                          function (
                                                                            _item,
                                                                            indexs
                                                                          ) {
                                                                            return _c(
                                                                              "el-option",
                                                                              {
                                                                                key: indexs,
                                                                                attrs:
                                                                                  {
                                                                                    label:
                                                                                      _item.value,
                                                                                    value:
                                                                                      _item.value,
                                                                                  },
                                                                              }
                                                                            )
                                                                          }
                                                                        ),
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              item.riskkindCode ==
                                                              "C"
                                                                ? _c(
                                                                    "el-form-item",
                                                                    {
                                                                      attrs: {
                                                                        "label-width":
                                                                          "0px",
                                                                        prop:
                                                                          "quoteDutys." +
                                                                          index +
                                                                          ".unitAmount",
                                                                        rules: [
                                                                          {
                                                                            required: true,
                                                                            message:
                                                                              "请选择保险金额",
                                                                            trigger:
                                                                              "change",
                                                                          },
                                                                        ],
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "el-select",
                                                                        {
                                                                          ref:
                                                                            "riskItem" +
                                                                            index,
                                                                          refInFor: true,
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "100%",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              filterable:
                                                                                "",
                                                                              "allow-create":
                                                                                "",
                                                                              placeholder:
                                                                                "请选择或输入保额",
                                                                            },
                                                                          nativeOn:
                                                                            {
                                                                              input:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.filterData(
                                                                                    index
                                                                                  )
                                                                                },
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                item.unitAmount,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    item,
                                                                                    "unitAmount",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "item.unitAmount",
                                                                            },
                                                                        },
                                                                        _vm._l(
                                                                          _vm.CKXRX_OPTIONS,
                                                                          function (
                                                                            _item,
                                                                            indexs
                                                                          ) {
                                                                            return _c(
                                                                              "el-option",
                                                                              {
                                                                                key: indexs,
                                                                                attrs:
                                                                                  {
                                                                                    label:
                                                                                      _item.value,
                                                                                    value:
                                                                                      _item.value,
                                                                                  },
                                                                              }
                                                                            )
                                                                          }
                                                                        ),
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              item.riskkindCode ==
                                                                "A05" ||
                                                              item.riskkindCode ==
                                                                "B03" ||
                                                              item.riskkindCode ==
                                                                "C01" ||
                                                              item.riskkindCode ==
                                                                "D01"
                                                                ? _c(
                                                                    "el-form-item",
                                                                    {
                                                                      attrs: {
                                                                        "label-width":
                                                                          "0px",
                                                                        prop:
                                                                          "quoteDutys." +
                                                                          index +
                                                                          ".unitAmount",
                                                                        rules: [
                                                                          {
                                                                            required: true,
                                                                            message:
                                                                              "请选择保险金额",
                                                                            trigger:
                                                                              "change",
                                                                          },
                                                                        ],
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "el-select",
                                                                        {
                                                                          ref:
                                                                            "riskItem" +
                                                                            index,
                                                                          refInFor: true,
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "100%",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              filterable:
                                                                                "",
                                                                              "allow-create":
                                                                                "",
                                                                              placeholder:
                                                                                "请选择保额",
                                                                            },
                                                                          nativeOn:
                                                                            {
                                                                              input:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.filterData(
                                                                                    index
                                                                                  )
                                                                                },
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                item.unitAmount,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    item,
                                                                                    "unitAmount",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "item.unitAmount",
                                                                            },
                                                                        },
                                                                        _vm._l(
                                                                          _vm.RATE_OPTIONS,
                                                                          function (
                                                                            _item,
                                                                            indexs
                                                                          ) {
                                                                            return _c(
                                                                              "el-option",
                                                                              {
                                                                                key: indexs,
                                                                                attrs:
                                                                                  {
                                                                                    label:
                                                                                      _item.value,
                                                                                    value:
                                                                                      _item.value,
                                                                                  },
                                                                              }
                                                                            )
                                                                          }
                                                                        ),
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              item.riskkindCode ==
                                                                "D" ||
                                                              item.riskkindCode ==
                                                                "D02" ||
                                                              item.riskkindCode ==
                                                                "D03"
                                                                ? _c(
                                                                    "el-form-item",
                                                                    {
                                                                      attrs: {
                                                                        "label-width":
                                                                          "0px",
                                                                        prop:
                                                                          "quoteDutys." +
                                                                          index +
                                                                          ".unitAmount",
                                                                        rules: [
                                                                          {
                                                                            required: true,
                                                                            message:
                                                                              "请选择保险金额",
                                                                            trigger:
                                                                              [
                                                                                "blur",
                                                                                "change",
                                                                              ],
                                                                          },
                                                                        ],
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "el-select",
                                                                        {
                                                                          ref:
                                                                            "riskItem" +
                                                                            index,
                                                                          refInFor: true,
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "36%",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              filterable:
                                                                                "",
                                                                              "allow-create":
                                                                                "",
                                                                              placeholder:
                                                                                "请选择保额",
                                                                            },
                                                                          nativeOn:
                                                                            {
                                                                              input:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.filterData(
                                                                                    index
                                                                                  )
                                                                                },
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                item.unitAmount,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    item,
                                                                                    "unitAmount",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "item.unitAmount",
                                                                            },
                                                                        },
                                                                        _vm._l(
                                                                          _vm.CKXRX_OPTIONS,
                                                                          function (
                                                                            _item,
                                                                            indexs
                                                                          ) {
                                                                            return _c(
                                                                              "el-option",
                                                                              {
                                                                                key: indexs,
                                                                                attrs:
                                                                                  {
                                                                                    label:
                                                                                      _item.value,
                                                                                    value:
                                                                                      _item.value,
                                                                                  },
                                                                              }
                                                                            )
                                                                          }
                                                                        ),
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              "font-size":
                                                                                "13px",
                                                                              margin:
                                                                                "0 3px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "元/座 x "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "el-input",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "36%",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              maxlength:
                                                                                "3",
                                                                              placeholder:
                                                                                "请输入座位数",
                                                                              clearable:
                                                                                "",
                                                                            },
                                                                          on: {
                                                                            input:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                item.quantity =
                                                                                  _vm.onInput(
                                                                                    item.quantity,
                                                                                    0,
                                                                                    100,
                                                                                    0
                                                                                  )
                                                                              },
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                item.quantity,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    item,
                                                                                    "quantity",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "item.quantity",
                                                                            },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              "font-size":
                                                                                "13px",
                                                                              margin:
                                                                                "0 3px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "座"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      item.riskkindCode ==
                                                                        "D03" &&
                                                                      [
                                                                        "paic",
                                                                        "picc",
                                                                        "ygbx",
                                                                        "ccic",
                                                                      ].includes(
                                                                        _vm._.toLower(
                                                                          _vm
                                                                            .form
                                                                            .insCorpCode
                                                                        )
                                                                      )
                                                                        ? _c(
                                                                            "el-checkbox",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  width:
                                                                                    "20%",
                                                                                },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    item.shareAmount,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        item,
                                                                                        "shareAmount",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "item.shareAmount",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "是否共享保额"
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              item.riskkindCode ==
                                                              "A04"
                                                                ? _c(
                                                                    "el-form-item",
                                                                    {
                                                                      attrs: {
                                                                        "label-width":
                                                                          "0px",
                                                                        prop:
                                                                          "quoteDutys." +
                                                                          index +
                                                                          ".unitAmount",
                                                                        rules: [
                                                                          {
                                                                            required: true,
                                                                            message:
                                                                              "请输入保险金额",
                                                                            trigger:
                                                                              "blur",
                                                                          },
                                                                        ],
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "el-input",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "36%",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              maxlength:
                                                                                "10",
                                                                              placeholder:
                                                                                "请输入保险金额",
                                                                              oninput:
                                                                                "value=value.replace(/[^0-9]{0,1}(\\d*(?:\\.\\d{0,2})?).*$/g, '$1')",
                                                                              clearable:
                                                                                "",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                item.unitAmount,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    item,
                                                                                    "unitAmount",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "item.unitAmount",
                                                                            },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              "font-size":
                                                                                "13px",
                                                                              margin:
                                                                                "0 3px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "元/天 x "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "el-input",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "36%",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              maxlength:
                                                                                "10",
                                                                              placeholder:
                                                                                "请输入天数",
                                                                              oninput:
                                                                                "value=value.replace(/[^0-9]{0,1}(\\d*(?:\\.\\d{0,2})?).*$/g, '$1')",
                                                                              clearable:
                                                                                "",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                item.quantity,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    item,
                                                                                    "quantity",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "item.quantity",
                                                                            },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              "font-size":
                                                                                "13px",
                                                                              margin:
                                                                                "0 3px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "天"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _vm._.indexOf(
                                                                ["C03", "B04"],
                                                                item.riskkindCode
                                                              ) != -1
                                                                ? _c(
                                                                    "el-form-item",
                                                                    {
                                                                      attrs: {
                                                                        "label-width":
                                                                          "0px",
                                                                        prop:
                                                                          "quoteDutys." +
                                                                          index +
                                                                          ".unitAmount",
                                                                        rules: [
                                                                          {
                                                                            required: true,
                                                                            message:
                                                                              "请输入保险金额",
                                                                            trigger:
                                                                              "blur",
                                                                          },
                                                                        ],
                                                                      },
                                                                    },
                                                                    [
                                                                      [
                                                                        "paic",
                                                                        "picc",
                                                                        "ygbx",
                                                                        "ccic",
                                                                      ].includes(
                                                                        _vm._.toLower(
                                                                          _vm
                                                                            .form
                                                                            .insCorpCode
                                                                        )
                                                                      ) ||
                                                                      ([
                                                                        "ccic",
                                                                      ].includes(
                                                                        _vm._.toLower(
                                                                          _vm
                                                                            .form
                                                                            .insCorpCode
                                                                        )
                                                                      ) &&
                                                                        item.riskkindCode ==
                                                                          "B04")
                                                                        ? _c(
                                                                            "el-input",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  maxlength:
                                                                                    "10",
                                                                                  placeholder:
                                                                                    "" +
                                                                                    "请输入保险金额",
                                                                                  oninput:
                                                                                    "value=value.replace(/[^0-9]{0,1}(\\d*(?:\\.\\d{0,2})?).*$/g, '$1')",
                                                                                  clearable:
                                                                                    "",
                                                                                },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    item.unitAmount,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        item,
                                                                                        "unitAmount",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "item.unitAmount",
                                                                                },
                                                                            }
                                                                          )
                                                                        : _c(
                                                                            "el-input",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  width:
                                                                                    "100%",
                                                                                },
                                                                              attrs:
                                                                                {
                                                                                  maxlength:
                                                                                    "10",
                                                                                  placeholder:
                                                                                    "" +
                                                                                    "请输入保险金额",
                                                                                  oninput:
                                                                                    "value=value.replace(/[^0-9]{0,1}(\\d*(?:\\.\\d{0,2})?).*$/g, '$1')",
                                                                                  clearable:
                                                                                    "",
                                                                                },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    item.unitAmount,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        item,
                                                                                        "unitAmount",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "item.unitAmount",
                                                                                },
                                                                            }
                                                                          ),
                                                                      [
                                                                        "paic",
                                                                        "picc",
                                                                        "ygbx",
                                                                        "ccic",
                                                                      ].includes(
                                                                        _vm._.toLower(
                                                                          _vm
                                                                            .form
                                                                            .insCorpCode
                                                                        )
                                                                      ) ||
                                                                      ([
                                                                        "ccic",
                                                                      ].includes(
                                                                        _vm._.toLower(
                                                                          _vm
                                                                            .form
                                                                            .insCorpCode
                                                                        )
                                                                      ) &&
                                                                        item.riskkindCode ==
                                                                          "B04")
                                                                        ? _c(
                                                                            "el-checkbox",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  width:
                                                                                    "20%",
                                                                                },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    item.shareAmount,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        item,
                                                                                        "shareAmount",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "item.shareAmount",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "是否共享保额"
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._.indexOf(
                                                                    [
                                                                      "B",
                                                                      "C",
                                                                      "D",
                                                                      "D01",
                                                                      "D02",
                                                                      "D03",
                                                                      "B03",
                                                                      "C01",
                                                                      "A04",
                                                                      "A05",
                                                                    ],
                                                                    item.riskkindCode
                                                                  ) == -1
                                                                ? _c(
                                                                    "el-form-item",
                                                                    {
                                                                      attrs: {
                                                                        "label-width":
                                                                          "0px",
                                                                        prop:
                                                                          "quoteDutys." +
                                                                          index +
                                                                          ".unitAmount",
                                                                        rules: [
                                                                          {
                                                                            required: true,
                                                                            message:
                                                                              "请输入保险金额",
                                                                            trigger:
                                                                              "blur",
                                                                          },
                                                                        ],
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "el-input",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              maxlength:
                                                                                "10",
                                                                              placeholder:
                                                                                "" +
                                                                                (_vm._.indexOf(
                                                                                  [
                                                                                    "FZ01",
                                                                                    "FZ02",
                                                                                    "FZ03",
                                                                                    "FZ04",
                                                                                  ],
                                                                                  item.riskkindCode
                                                                                ) !=
                                                                                -1
                                                                                  ? "请输入次数"
                                                                                  : "请输入保险金额"),
                                                                              oninput:
                                                                                "value=value.replace(/[^0-9]{0,1}(\\d*(?:\\.\\d{0,2})?).*$/g, '$1')",
                                                                              clearable:
                                                                                "",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                item.unitAmount,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    item,
                                                                                    "unitAmount",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "item.unitAmount",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]),
                                                    _c(
                                                      "td",
                                                      [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              "label-width":
                                                                "0px",
                                                              prop:
                                                                "quoteDutys." +
                                                                index +
                                                                ".amount",
                                                              rules: [
                                                                {
                                                                  required: true,
                                                                  message:
                                                                    "请输入保费",
                                                                  trigger:
                                                                    "blur",
                                                                },
                                                              ],
                                                            },
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                maxlength: "10",
                                                                placeholder:
                                                                  "请输入保费",
                                                                clearable: "",
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    item.amount =
                                                                      _vm.onInput(
                                                                        item.amount,
                                                                        -1000,
                                                                        30000,
                                                                        2
                                                                      )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  item.amount,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "amount",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "item.amount",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm.allDisabled
                                                      ? _c("td", [
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-remove-outline deleteIcon",
                                                          }),
                                                        ])
                                                      : _c("td", [
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-remove-outline deleteIcon",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.deleteInsurance(
                                                                  "one",
                                                                  index
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ]),
                                                  ]
                                                )
                                              }
                                            ),
                                            _c("tr", [
                                              _vm.allDisabled
                                                ? _c(
                                                    "td",
                                                    { attrs: { colspan: "4" } },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "add_clo",
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-circle-plus-outline addIcon",
                                                          }),
                                                          _c("span", [
                                                            _vm._v("添加险种"),
                                                          ]),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _c(
                                                    "td",
                                                    { attrs: { colspan: "4" } },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "add_clo",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.addInsurance(
                                                                "one"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-circle-plus-outline addIcon",
                                                          }),
                                                          _c("span", [
                                                            _vm._v("添加险种"),
                                                          ]),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                            ]),
                                          ],
                                          2
                                        ),
                                      ]
                                    ),
                                  ],
                                  2
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.showInsuranceType == "2"
                          ? _c(
                              "el-collapse",
                              {
                                staticStyle: { "margin-top": "10px" },
                                model: {
                                  value: _vm.activeFive,
                                  callback: function ($$v) {
                                    _vm.activeFive = $$v
                                  },
                                  expression: "activeFive",
                                },
                              },
                              [
                                _c(
                                  "el-collapse-item",
                                  {
                                    staticClass: "collapse-item-style",
                                    attrs: { name: "1" },
                                  },
                                  [
                                    _c("template", { slot: "title" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "title",
                                          staticStyle: {
                                            "line-height": "25px",
                                          },
                                        },
                                        [
                                          _c("FormLabel", {
                                            attrs: { "form-label": "保单信息" },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c(
                                      "el-row",
                                      { attrs: { gutter: 0 } },
                                      [
                                        _vm.showInsuranceType == "2"
                                          ? _c(
                                              "el-col",
                                              { attrs: { span: 6 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "驾意险保单号",
                                                      prop: "fcBsRiskNo",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      attrs: {
                                                        maxlength: "40",
                                                        placeholder:
                                                          "请输入驾意险保单号",
                                                        clearable: "",
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          return _vm.fcCheckBsRiskNo(
                                                            _vm.form.fcBsRiskNo
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form.fcBsRiskNo,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form,
                                                            "fcBsRiskNo",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.fcBsRiskNo",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.showInsuranceType == "2"
                                          ? _c(
                                              "el-col",
                                              { attrs: { span: 6 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "驾意险起保日期",
                                                      prop: "fcRiskKind.effectiveStartTime",
                                                    },
                                                  },
                                                  [
                                                    _c("el-date-picker", {
                                                      staticStyle: {
                                                        width: "100%",
                                                      },
                                                      attrs: {
                                                        "value-format":
                                                          "yyyy-MM-dd",
                                                        type: "date",
                                                        placeholder:
                                                          "请选择驾意险起保日期",
                                                        "picker-options":
                                                          _vm.disabledEffectiveTime,
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.fcDatePickerChange(
                                                            _vm.form
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form.fcRiskKind
                                                            .effectiveStartTime,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form.fcRiskKind,
                                                            "effectiveStartTime",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.fcRiskKind.effectiveStartTime",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.showInsuranceType == "2"
                                          ? _c(
                                              "el-col",
                                              { attrs: { span: 6 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "驾意险终保日期",
                                                      prop: "fcRiskKind.effectiveEndTime",
                                                    },
                                                  },
                                                  [
                                                    _c("el-date-picker", {
                                                      staticStyle: {
                                                        width: "100%",
                                                      },
                                                      attrs: {
                                                        "value-format":
                                                          "yyyy-MM-dd",
                                                        type: "date",
                                                        placeholder:
                                                          "请选择驾意险终保日期",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form.fcRiskKind
                                                            .effectiveEndTime,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form.fcRiskKind,
                                                            "effectiveEndTime",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.fcRiskKind.effectiveEndTime",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.showInsuranceType == "2"
                                          ? _c(
                                              "el-col",
                                              { attrs: { span: 6 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "驾意险费率",
                                                      prop: "fcRiskKind.poundageRate",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        attrs: {
                                                          slot: "label",
                                                        },
                                                        slot: "label",
                                                      },
                                                      [
                                                        _c(
                                                          "el-tooltip",
                                                          {
                                                            attrs: {
                                                              placement: "top",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                attrs: {
                                                                  slot: "content",
                                                                },
                                                                slot: "content",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "优先取费用政策配置的费率，政策如未配置，取保险公司协议约定的最高费率"
                                                                ),
                                                              ]
                                                            ),
                                                            _c("i", {
                                                              staticClass:
                                                                "el-icon-warning-outline",
                                                              staticStyle: {
                                                                "font-size":
                                                                  "14px",
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                        _vm._v(" 驾意险费率 "),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "el-input",
                                                      {
                                                        attrs: {
                                                          maxlength: "10",
                                                          placeholder:
                                                            "请输入驾意险费率",
                                                          oninput:
                                                            "value=value.replace(/[^0-9]{0,1}(\\d*(?:\\.\\d{0,2})?).*$/g, '$1')",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form.fcRiskKind
                                                              .poundageRate,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form
                                                                .fcRiskKind,
                                                              "poundageRate",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form.fcRiskKind.poundageRate",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "i",
                                                          {
                                                            staticStyle: {
                                                              "font-style":
                                                                "normal",
                                                              "margin-right":
                                                                "4px",
                                                              "font-size":
                                                                "14px",
                                                            },
                                                            attrs: {
                                                              slot: "suffix",
                                                            },
                                                            slot: "suffix",
                                                          },
                                                          [_vm._v("%")]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.showInsuranceType == "2"
                                          ? _c(
                                              "el-col",
                                              { attrs: { span: 6 } },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "驾意险总金额",
                                                      prop: "fcPremium",
                                                      rules: [
                                                        {
                                                          required: true,
                                                          message:
                                                            "请输入驾意险总金额",
                                                          trigger: "blur",
                                                        },
                                                      ],
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      attrs: {
                                                        maxlength: "10",
                                                        placeholder:
                                                          "请输入驾意险总金额",
                                                        clearable: "",
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          _vm.form.fcPremium =
                                                            _vm.onInput(
                                                              _vm.form
                                                                .fcPremium,
                                                              0,
                                                              10000,
                                                              2
                                                            )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form.fcPremium,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form,
                                                            "fcPremium",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.fcPremium",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 6 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              { attrs: { label: "总保单号" } },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    maxlength: "40",
                                                    placeholder:
                                                      "请输入总保单号",
                                                    clearable: "",
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      return _vm.checkRiskNo(
                                                        _vm.form.riskNo
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: _vm.form.riskNo,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "riskNo",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "form.riskNo",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  2
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.showInsuranceType == "2" ||
                        _vm.showInsuranceType == "3"
                          ? _c(
                              "el-collapse",
                              {
                                staticStyle: { "margin-top": "10px" },
                                model: {
                                  value: _vm.activeSix,
                                  callback: function ($$v) {
                                    _vm.activeSix = $$v
                                  },
                                  expression: "activeSix",
                                },
                              },
                              [
                                _c(
                                  "el-collapse-item",
                                  {
                                    staticClass: "collapse-item-style",
                                    attrs: { name: "1" },
                                  },
                                  [
                                    _c("template", { slot: "title" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "title",
                                          staticStyle: {
                                            "line-height": "25px",
                                          },
                                        },
                                        [
                                          _c("FormLabel", {
                                            attrs: {
                                              "form-label": "非车险险别信息",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c(
                                      "table",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: { border: "1" },
                                      },
                                      [
                                        _c("colgroup", [
                                          _c("col", {
                                            staticStyle: { width: "30%" },
                                          }),
                                          _c("col", {
                                            staticStyle: { width: "30%" },
                                          }),
                                          _c("col", {
                                            staticStyle: { width: "5%" },
                                          }),
                                        ]),
                                        _c(
                                          "thead",
                                          {
                                            staticStyle: {
                                              "background-color": "#eeee",
                                            },
                                          },
                                          [
                                            _c(
                                              "tr",
                                              {
                                                staticStyle: {
                                                  "font-size": "13px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "col" } },
                                                  [
                                                    _c("span", {
                                                      staticClass: "redDot",
                                                    }),
                                                    _vm._v("险别名称"),
                                                  ]
                                                ),
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "col" } },
                                                  [
                                                    _c("span", {
                                                      staticClass: "redDot",
                                                    }),
                                                    _vm._v("保险金额(¥)"),
                                                  ]
                                                ),
                                                _c(
                                                  "th",
                                                  { attrs: { scope: "col" } },
                                                  [_vm._v("操作")]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "tbody",
                                          [
                                            _vm._l(
                                              _vm.form.fcQuoteDutys,
                                              function (item, index) {
                                                return _c(
                                                  "tr",
                                                  {
                                                    key: index,
                                                    staticClass: "tableTr",
                                                  },
                                                  [
                                                    _c(
                                                      "td",
                                                      [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              "label-width":
                                                                "0px",
                                                            },
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                maxlength: "20",
                                                                placeholder:
                                                                  "请输入险别名称",
                                                                clearable: "",
                                                              },
                                                              model: {
                                                                value:
                                                                  item.riskkindName,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "riskkindName",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "item.riskkindName",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "td",
                                                      [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              "label-width":
                                                                "0px",
                                                            },
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              attrs: {
                                                                maxlength: "10",
                                                                placeholder:
                                                                  "请输入保险金额",
                                                                oninput:
                                                                  "value=value.replace(/[^0-9]{0,1}(\\d*(?:\\.\\d{0,2})?).*$/g, '$1')",
                                                                clearable: "",
                                                              },
                                                              model: {
                                                                value:
                                                                  item.amount,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "amount",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "item.amount",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm.allDisabled
                                                      ? _c("td", [
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-remove-outline deleteIcon",
                                                          }),
                                                        ])
                                                      : _c("td", [
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-remove-outline deleteIcon",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.fcDeleteInsurance(
                                                                  "FC",
                                                                  index
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ]),
                                                  ]
                                                )
                                              }
                                            ),
                                            _c("tr", [
                                              _vm.allDisabled
                                                ? _c(
                                                    "td",
                                                    { attrs: { colspan: "4" } },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "add_clo",
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-circle-plus-outline addIcon",
                                                          }),
                                                          _c("span", [
                                                            _vm._v("添加险种"),
                                                          ]),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _c(
                                                    "td",
                                                    { attrs: { colspan: "4" } },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "add_clo",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.FCaddInsurance(
                                                                "FC"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-circle-plus-outline addIcon",
                                                          }),
                                                          _c("span", [
                                                            _vm._v("添加险种"),
                                                          ]),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                            ]),
                                          ],
                                          2
                                        ),
                                      ]
                                    ),
                                  ],
                                  2
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.bsName
                          ? _c(
                              "el-collapse",
                              {
                                staticStyle: { "margin-top": "10px" },
                                model: {
                                  value: _vm.activeEight,
                                  callback: function ($$v) {
                                    _vm.activeEight = $$v
                                  },
                                  expression: "activeEight",
                                },
                              },
                              [
                                _c(
                                  "el-collapse-item",
                                  {
                                    staticClass: "collapse-item-style",
                                    attrs: { name: "1" },
                                  },
                                  [
                                    _c("template", { slot: "title" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "title",
                                          staticStyle: {
                                            "line-height": "25px",
                                          },
                                        },
                                        [
                                          _c("FormLabel", {
                                            attrs: {
                                              "form-label":
                                                "商业险电子保单列表",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c(
                                      "el-row",
                                      { attrs: { gutter: 0 } },
                                      [
                                        _c(
                                          "el-upload",
                                          {
                                            staticClass: "upload-demo",
                                            attrs: {
                                              "before-remove": function () {
                                                return (_vm.formData = "")
                                              },
                                              action: "",
                                              multiple: "",
                                              "show-file-list": false,
                                              "http-request": function (val) {
                                                return _vm.uploadFile(
                                                  val,
                                                  "policyDocumentsBs"
                                                )
                                              },
                                              "before-upload": _vm.beforeUpload,
                                              "file-list": _vm.fileList,
                                              accept: _vm.uploadType,
                                              "list-type": "text",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  size: "mini",
                                                  type: "primary",
                                                },
                                              },
                                              [_vm._v("选择文件")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "table",
                                          {
                                            staticStyle: {
                                              width: "100%",
                                              "margin-top": "10px",
                                            },
                                            attrs: { border: "1" },
                                          },
                                          [
                                            _c("colgroup", [
                                              _c("col", {
                                                staticStyle: { width: "47.5%" },
                                              }),
                                              _c("col", {
                                                staticStyle: { width: "5%" },
                                              }),
                                            ]),
                                            _c(
                                              "thead",
                                              {
                                                staticStyle: {
                                                  "background-color": "#eeee",
                                                },
                                              },
                                              [
                                                _c(
                                                  "tr",
                                                  {
                                                    staticStyle: {
                                                      "font-size": "13px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "th",
                                                      {
                                                        attrs: { scope: "col" },
                                                      },
                                                      [_vm._v("文件名")]
                                                    ),
                                                    _c(
                                                      "th",
                                                      {
                                                        attrs: { scope: "col" },
                                                      },
                                                      [_vm._v("操作")]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "tbody",
                                              _vm._l(
                                                _vm.form.policyDocumentsBs,
                                                function (item, index) {
                                                  return _c(
                                                    "tr",
                                                    { key: index },
                                                    [
                                                      _c(
                                                        "td",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handle(
                                                                item
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "file-text",
                                                              attrs: {
                                                                title:
                                                                  item.fileName,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.fileName
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c("td", [
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-remove-outline",
                                                          staticStyle: {
                                                            "font-size": "24px",
                                                            color:
                                                              "rgb(237, 98, 98)",
                                                            cursor: "pointer",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.deleteInsurance(
                                                                "three",
                                                                index,
                                                                "policyDocumentsBs"
                                                              )
                                                            },
                                                          },
                                                        }),
                                                      ]),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  2
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.bzName
                          ? _c(
                              "el-collapse",
                              {
                                staticStyle: { "margin-top": "10px" },
                                model: {
                                  value: _vm.activeNine,
                                  callback: function ($$v) {
                                    _vm.activeNine = $$v
                                  },
                                  expression: "activeNine",
                                },
                              },
                              [
                                _c(
                                  "el-collapse-item",
                                  {
                                    staticClass: "collapse-item-style",
                                    attrs: { name: "1" },
                                  },
                                  [
                                    _c("template", { slot: "title" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "title",
                                          staticStyle: {
                                            "line-height": "25px",
                                          },
                                        },
                                        [
                                          _c("FormLabel", {
                                            attrs: {
                                              "form-label":
                                                "交强险电子保单列表",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c(
                                      "el-row",
                                      { attrs: { gutter: 0 } },
                                      [
                                        _c(
                                          "el-upload",
                                          {
                                            staticClass: "upload-demo",
                                            attrs: {
                                              "before-remove": function () {
                                                return (_vm.formData = "")
                                              },
                                              action: "",
                                              multiple: "",
                                              "show-file-list": false,
                                              "http-request": function (val) {
                                                return _vm.uploadFile(
                                                  val,
                                                  "policyDocumentsBz"
                                                )
                                              },
                                              "before-upload": _vm.beforeUpload,
                                              "file-list": _vm.fileList,
                                              accept: _vm.uploadType,
                                              "list-type": "text",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  size: "mini",
                                                  type: "primary",
                                                },
                                              },
                                              [_vm._v("选择文件")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "table",
                                          {
                                            staticStyle: {
                                              width: "100%",
                                              "margin-top": "10px",
                                            },
                                            attrs: { border: "1" },
                                          },
                                          [
                                            _c("colgroup", [
                                              _c("col", {
                                                staticStyle: { width: "47.5%" },
                                              }),
                                              _c("col", {
                                                staticStyle: { width: "5%" },
                                              }),
                                            ]),
                                            _c(
                                              "thead",
                                              {
                                                staticStyle: {
                                                  "background-color": "#eeee",
                                                },
                                              },
                                              [
                                                _c(
                                                  "tr",
                                                  {
                                                    staticStyle: {
                                                      "font-size": "13px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "th",
                                                      {
                                                        attrs: { scope: "col" },
                                                      },
                                                      [_vm._v("文件名")]
                                                    ),
                                                    _c(
                                                      "th",
                                                      {
                                                        attrs: { scope: "col" },
                                                      },
                                                      [_vm._v("操作")]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "tbody",
                                              _vm._l(
                                                _vm.form.policyDocumentsBz,
                                                function (item, index) {
                                                  return _c(
                                                    "tr",
                                                    { key: index },
                                                    [
                                                      _c(
                                                        "td",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handle(
                                                                item
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "file-text",
                                                              attrs: {
                                                                title:
                                                                  item.fileName,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.fileName
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c("td", [
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-remove-outline",
                                                          staticStyle: {
                                                            "font-size": "24px",
                                                            color:
                                                              "rgb(237, 98, 98)",
                                                            cursor: "pointer",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.deleteInsurance(
                                                                "three",
                                                                index,
                                                                "policyDocumentsBz"
                                                              )
                                                            },
                                                          },
                                                        }),
                                                      ]),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  2
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.accidentName
                          ? _c(
                              "el-collapse",
                              {
                                staticStyle: { "margin-top": "10px" },
                                model: {
                                  value: _vm.activeTen,
                                  callback: function ($$v) {
                                    _vm.activeTen = $$v
                                  },
                                  expression: "activeTen",
                                },
                              },
                              [
                                _c(
                                  "el-collapse-item",
                                  {
                                    staticClass: "collapse-item-style",
                                    attrs: { name: "1" },
                                  },
                                  [
                                    _c("template", { slot: "title" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "title",
                                          staticStyle: {
                                            "line-height": "25px",
                                          },
                                        },
                                        [
                                          _c("FormLabel", {
                                            attrs: {
                                              "form-label":
                                                "非车险电子保单列表",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c(
                                      "el-row",
                                      { attrs: { gutter: 0 } },
                                      [
                                        _c(
                                          "el-upload",
                                          {
                                            staticClass: "upload-demo",
                                            attrs: {
                                              "before-remove": function () {
                                                return (_vm.formData = "")
                                              },
                                              action: "",
                                              multiple: "",
                                              "show-file-list": false,
                                              "http-request": function (val) {
                                                return _vm.uploadFile(
                                                  val,
                                                  "policyDocumentsAccident"
                                                )
                                              },
                                              "before-upload": _vm.beforeUpload,
                                              "file-list": _vm.fileList,
                                              accept: _vm.uploadType,
                                              "list-type": "text",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  size: "mini",
                                                  type: "primary",
                                                },
                                              },
                                              [_vm._v("选择文件")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "table",
                                          {
                                            staticStyle: {
                                              width: "100%",
                                              "margin-top": "10px",
                                            },
                                            attrs: { border: "1" },
                                          },
                                          [
                                            _c("colgroup", [
                                              _c("col", {
                                                staticStyle: { width: "47.5%" },
                                              }),
                                              _c("col", {
                                                staticStyle: { width: "5%" },
                                              }),
                                            ]),
                                            _c(
                                              "thead",
                                              {
                                                staticStyle: {
                                                  "background-color": "#eeee",
                                                },
                                              },
                                              [
                                                _c(
                                                  "tr",
                                                  {
                                                    staticStyle: {
                                                      "font-size": "13px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "th",
                                                      {
                                                        attrs: { scope: "col" },
                                                      },
                                                      [_vm._v("文件名")]
                                                    ),
                                                    _c(
                                                      "th",
                                                      {
                                                        attrs: { scope: "col" },
                                                      },
                                                      [_vm._v("操作")]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "tbody",
                                              _vm._l(
                                                _vm.form
                                                  .policyDocumentsAccident,
                                                function (item, index) {
                                                  return _c(
                                                    "tr",
                                                    { key: index },
                                                    [
                                                      _c(
                                                        "td",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handle(
                                                                item
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "file-text",
                                                              attrs: {
                                                                title:
                                                                  item.fileName,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.fileName
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c("td", [
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-remove-outline",
                                                          staticStyle: {
                                                            "font-size": "24px",
                                                            color:
                                                              "rgb(237, 98, 98)",
                                                            cursor: "pointer",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.deleteInsurance(
                                                                "three",
                                                                index,
                                                                "policyDocumentsAccident"
                                                              )
                                                            },
                                                          },
                                                        }),
                                                      ]),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  2
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]
        ),
        _c(
          "span",
          {
            staticClass: "dialog-footer",
            staticStyle: {
              display: "flex",
              "justify-content": "center",
              "margin-top": "10px",
            },
            attrs: { slot: "footer" },
            slot: "footer",
          },
          [
            _c(
              "el-button",
              { attrs: { size: "mini" }, on: { click: _vm.cancel } },
              [_vm._v(" 关闭 ")]
            ),
            _vm.showInsuranceType != "2"
              ? _c(
                  "el-button",
                  {
                    attrs: {
                      disabled: _vm.isDisable,
                      size: "mini",
                      type: "primary",
                      plain: "",
                    },
                    on: { click: _vm.policyIdentity },
                  },
                  [_vm._v(" 保单识别 ")]
                )
              : _vm._e(),
            _c(
              "el-button",
              {
                attrs: {
                  disabled: _vm.isDisable,
                  size: "mini",
                  type: "primary",
                },
                on: { click: _vm.saveClicked },
              },
              [_vm._v(" 保存 ")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.policyIdentityDialog,
            "close-on-click-modal": false,
            width: "1000px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.policyIdentityDialog = $event
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("保单识别")]
              ),
            ]
          ),
          _c("div", { staticClass: "app-m-cls customer-import-list-dialog" }, [
            _c(
              "div",
              {
                ref: "header",
                staticClass: "table-cls normal-btn-cls",
                style: {
                  "max-height": ["0", "1"].includes(_vm.showInsuranceType)
                    ? "300px"
                    : "600px",
                  overflow: "auto",
                },
              },
              [
                _c("el-card", { attrs: { shadow: "never" } }, [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "flex-start",
                      },
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                color: "red",
                                "line-height": "32px",
                              },
                            },
                            [_vm._v("* ")]
                          ),
                          _c("div", [_vm._v("承保保险公司：")]),
                          _c(
                            "el-select",
                            {
                              staticStyle: { "margin-left": "8px" },
                              attrs: {
                                filterable: "",
                                placeholder: "请选择承保保险公司",
                                clearable: "",
                              },
                              on: { change: _vm.setInsCorpCode },
                              model: {
                                value: _vm.identitySearchForm.insCorpCode,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.identitySearchForm,
                                    "insCorpCode",
                                    $$v
                                  )
                                },
                                expression: "identitySearchForm.insCorpCode",
                              },
                            },
                            _vm._l(
                              _vm.supportSupplyers,
                              function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    label: item.insCorpName,
                                    value: item.insCorpCode,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "uploadBox",
                      style: {
                        "min-height": ["0", "1"].includes(_vm.showInsuranceType)
                          ? "210px"
                          : "420px",
                      },
                    },
                    [
                      _vm.hasInsCorpCode &&
                      !["0", "2"].includes(_vm.showInsuranceType)
                        ? _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.identityBzLoading,
                                  expression: "identityBzLoading",
                                },
                              ],
                              staticClass: "bzUpload",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "identityUpload" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "font-size": "14px",
                                        color: "#808080",
                                        "margin-bottom": "5px",
                                      },
                                    },
                                    [_vm._v("交强险保单")]
                                  ),
                                  _c(
                                    "el-upload",
                                    {
                                      staticClass: "upload-demo",
                                      attrs: {
                                        drag: "",
                                        "before-remove": function () {
                                          return (_vm.formData = "")
                                        },
                                        limit: 1,
                                        "show-file-list": false,
                                        action: "",
                                        "http-request": function (val) {
                                          return _vm.bzUploadFileClicked(
                                            val,
                                            "BZ"
                                          )
                                        },
                                        "before-upload": _vm.bzBeforeUpload,
                                        "file-list": _vm.currentBzFilesList,
                                        accept: _vm.uploadType,
                                      },
                                    },
                                    [
                                      _vm.showBzFilesListUpload
                                        ? _c("div", [
                                            _c("i", {
                                              staticClass: "el-icon-upload",
                                            }),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "el-upload__text",
                                              },
                                              [
                                                _vm._v(" 将文件拖到此处，或"),
                                                _c("em", [_vm._v("点击上传")]),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  color: "red",
                                                  "margin-top": "5px",
                                                },
                                                attrs: { slot: "tip" },
                                                slot: "tip",
                                              },
                                              [_vm._v("* 只能上传PDF文件")]
                                            ),
                                          ])
                                        : _c(
                                            "div",
                                            { staticClass: "fileDetail" },
                                            [
                                              _c("i", {
                                                staticClass: "el-icon-document",
                                                staticStyle: {
                                                  "font-size": "60px",
                                                  color: "#C3C4CC",
                                                },
                                              }),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "el-upload__text textIcon",
                                                  on: {
                                                    mouseenter: function (
                                                      $event
                                                    ) {
                                                      _vm.showDeleteIcon = false
                                                    },
                                                    mouseleave: function (
                                                      $event
                                                    ) {
                                                      _vm.showDeleteIcon = true
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.bzFilesList &&
                                                          _vm.bzFilesList
                                                            .length != 0
                                                          ? _vm.bzFilesList[0]
                                                              .name
                                                          : ""
                                                      ) +
                                                      " "
                                                  ),
                                                  _vm.showDeleteIcon
                                                    ? _c("i", {
                                                        staticClass:
                                                          "el-icon-circle-check",
                                                        staticStyle: {
                                                          color: "#67c23a",
                                                          "margin-left": "20px",
                                                        },
                                                      })
                                                    : _c("i", {
                                                        staticClass:
                                                          "el-icon-close",
                                                        staticStyle: {
                                                          color: "#f56c6c",
                                                          "margin-left": "20px",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.deleteBzFile(
                                                              $event
                                                            )
                                                          },
                                                        },
                                                      }),
                                                ]
                                              ),
                                            ]
                                          ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "identityContent" }, [
                                _vm.bzIdentityJson
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "hasJson",
                                        staticStyle: {
                                          "padding-right": "8px",
                                          "margin-top": "5px",
                                        },
                                      },
                                      [
                                        _c(
                                          "pre",
                                          { staticClass: "identityJson" },
                                          [_vm._v(_vm._s(_vm.bzIdentityJson))]
                                        ),
                                      ]
                                    )
                                  : _c("div", { staticClass: "noData" }, [
                                      _vm._v("暂无识别结果"),
                                    ]),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm.hasInsCorpCode &&
                      !["1", "2"].includes(_vm.showInsuranceType)
                        ? _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.identityBsLoading,
                                  expression: "identityBsLoading",
                                },
                              ],
                              staticClass: "bsUpload",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "identityUpload" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "font-size": "14px",
                                        color: "#808080",
                                        "margin-top": "10px",
                                        "margin-bottom": "5px",
                                      },
                                    },
                                    [_vm._v("商业险保单")]
                                  ),
                                  _c(
                                    "el-upload",
                                    {
                                      staticClass: "upload-demo",
                                      attrs: {
                                        drag: "",
                                        "before-remove": function () {
                                          return (_vm.formData = "")
                                        },
                                        action: "",
                                        "show-file-list": false,
                                        "http-request": function (val) {
                                          return _vm.bsUploadFileClicked(
                                            val,
                                            "BS"
                                          )
                                        },
                                        "before-upload": _vm.bsBeforeUpload,
                                        "file-list": _vm.currentBsFilesList,
                                        accept: _vm.uploadType,
                                      },
                                    },
                                    [
                                      _vm.showBsFilesListUpload
                                        ? _c("div", [
                                            _c("i", {
                                              staticClass: "el-icon-upload",
                                            }),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "el-upload__text",
                                              },
                                              [
                                                _vm._v(" 将文件拖到此处，或"),
                                                _c("em", [_vm._v("点击上传")]),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  color: "red",
                                                  "margin-top": "5px",
                                                },
                                                attrs: { slot: "tip" },
                                                slot: "tip",
                                              },
                                              [_vm._v("* 只能上传PDF文件")]
                                            ),
                                          ])
                                        : _c(
                                            "div",
                                            { staticClass: "fileDetail" },
                                            [
                                              _c("i", {
                                                staticClass: "el-icon-document",
                                                staticStyle: {
                                                  "font-size": "60px",
                                                  color: "#C3C4CC",
                                                },
                                              }),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "el-upload__text textIcon",
                                                  on: {
                                                    mouseenter: function (
                                                      $event
                                                    ) {
                                                      _vm.showDeleteIcon = false
                                                    },
                                                    mouseleave: function (
                                                      $event
                                                    ) {
                                                      _vm.showDeleteIcon = true
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.bsFilesList &&
                                                          _vm.bsFilesList
                                                            .length != 0
                                                          ? _vm.bsFilesList[0]
                                                              .name
                                                          : ""
                                                      ) +
                                                      " "
                                                  ),
                                                  _vm.showDeleteIcon
                                                    ? _c("i", {
                                                        staticClass:
                                                          "el-icon-circle-check",
                                                        staticStyle: {
                                                          color: "#67c23a",
                                                          "margin-left": "20px",
                                                        },
                                                      })
                                                    : _c("i", {
                                                        staticClass:
                                                          "el-icon-close",
                                                        staticStyle: {
                                                          color: "#f56c6c",
                                                          "margin-left": "20px",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.deleteBsFile(
                                                              $event
                                                            )
                                                          },
                                                        },
                                                      }),
                                                ]
                                              ),
                                            ]
                                          ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "identityContent" }, [
                                _vm.bsIdentityJson
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "hasJson",
                                        staticStyle: {
                                          "padding-right": "8px",
                                          "margin-top": "20px",
                                        },
                                      },
                                      [
                                        _c(
                                          "pre",
                                          { staticClass: "identityJson" },
                                          [_vm._v(_vm._s(_vm.bsIdentityJson))]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _c("div", { staticClass: "noData" }, [
                                  _vm._v("暂无识别结果"),
                                ]),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      !_vm.hasInsCorpCode
                        ? _c(
                            "div",
                            {
                              staticClass: "noData",
                              staticStyle: {
                                "font-size": "14px",
                                "min-height": "200px",
                                "line-height": "1.5",
                              },
                            },
                            [
                              _vm._v(" 温馨提示： "),
                              _c("br"),
                              _vm._v(
                                " 请选择承保保险公司后上传电子保单文件，可识别保单信息。 "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                ]),
              ],
              1
            ),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "justify-content": "center",
                  "margin-top": "8px",
                },
              },
              [
                _vm.hasInsCorpCode
                  ? _c(
                      "el-button",
                      {
                        attrs: {
                          icon: "el-icon-check",
                          type: "primary",
                          size: "mini",
                        },
                        on: { click: _vm.autoFillData },
                      },
                      [_vm._v("自动填充")]
                    )
                  : _vm._e(),
                _c(
                  "el-button",
                  {
                    attrs: { icon: "el-icon-close", size: "mini" },
                    on: { click: _vm.closePage },
                  },
                  [_vm._v("关闭")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }