export default {
  'VIN_RULE': {
    label: '车架号',
    value: /^[a-zA-Z0-9]{17}$/
  },
  'PLATENO_RULE': {
    label: '车牌号',
    value: /^(([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z](([0-9]{5}[DF])|([ABCDEFGHJK]([A-HJ-NP-Z0-9])[0-9]{4})))|([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z][A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳使领])|(临[A-Z0-9]{6}))$/
    // value: /^(([A-Z](([0-9]{5}[DF])|([DF]([A-HJ-NP-Z0-9])[0-9]{4})))|([A-Z][A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳使领])|(临[A-Z0-9]{6}))$/
  },

  'MOBILE_RULE': {
    label: '手机号',
    value: /^((0\d{2,3}\d{7,8})|(1[3456789]\d{9}))$/
  },
  'PHONE_RULE': {
    label: '手机号',
    value: /0\d{2,3}-\d{7,8}/
  },
  'ENGINENO_RULE': {
    label: '发动机号',
    value: /^[a-zA-Z0-9\-\/\\]{6,20}/g
  },
  'NAME': {
    label: '名称',
    value: /^([\u4e00-\u9fa5\u4DAE\（\）\(\)\·]{2,40}|[a-zA-Z.\s]{2,40})|([0-9])$/
  },
  'CAR_MODEL': {
    label: '车型',
    value: /^(([\u4e00-\u9fa5\u4DAE])|([a-zA-Z])|([0-9])|\_|\.){6,}$/
  },
  'LANDLINE_RULE': {
    label: '座机',
    value: /^(0\d{2,3}-[1-9]\d{6,7})$/
  },
  'ID_CARD': {
    label: '身份证号',
    value: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
  },
  'EMAIL': {
    label: '邮箱',
    // value: /^([a-zA-Z0-9_-]+[_|\_|\.]?)*[a-zA-Z0-9_-]+@([a-zA-Z0-9_-]+[_|\_|\.]?)*[a-zA-Z0-9_-]+\.[a-zA-Z]{2,3}$/
    // value: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
    value: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/
  },
  'GVIM': {
    label: '只能输入0-100的正整数',
    value: /^(?:[1-9]?\d|100)$/
  },
  'CARD': {
    label: '银行卡',
    value: /^([1-9]{1})(\d{14}|\d{18})$/
  },
  'WX': {
    label: '微信号',
    value: /^[a-zA-Z\d_]{5,}$/
  }
};