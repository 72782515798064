import config from '../config/index';
export default function getUrl(url, domainType) {
  //domainType 默认为1，取apiDomain；可自定义其他域名
  var domain = '';
  if (domainType == 15) {
    //呼叫中心服务
    domain = config.apiDomain + '/' + config.ocCoreService;
  } else if (domainType == 19) {
    //非车险服务
    domain = config.apiDomain + '/' + config.bizNonautoConnectAdapterFTMSService;
  } else if (domainType == 18) {
    //非车险服务
    domain = config.apiDomain + '/' + config.bizNonautoConnectConfigurationService;
  } else if (domainType == 17) {
    //数据识别服务
    domain = config.apiDomain + '/' + config.tpRecognitionService;
  } else if (domainType == 16) {
    //非车险服务
    domain = config.apiDomain + '/' + config.bizNonautoService;
  } else if (domainType == 14) {
    //车险对接配置服务
    domain = config.apiDomain + '/' + config.viConnectConfigServer;
  } else if (domainType == 13) {
    //主机厂组织服务
    domain = config.apiDomain + '/' + config.orgServer;
  } else if (domainType == 12) {
    //经销商组织服务
    domain = config.apiDomain + '/' + config.orgServer;
  } else if (domainType == 11) {
    //一丰定制化服务
    domain = config.apiDomain + '/' + config.psFtmsService;
  } else if (domainType == 10) {
    //车服务
    domain = config.apiDomain + '/' + config.vehicleService;
  } else if (domainType == 9) {
    //导出文件
    domain = config.apiDomain + '/' + config.processService;
  } else if (domainType == 8) {
    //消息服务
    domain = config.apiDomain + '/' + config.tpMessageService;
  } else if (domainType == 7) {
    //存储服务
    domain = config.apiDomain + '/' + config.tpStorageService;
  } else if (domainType == 6) {
    //认证服务
    domain = config.apiDomain + '/' + config.tpAuthServer;
  } else if (domainType == 5) {
    //核心服务
    domain = config.apiDomain + '/' + config.tpCoreServer;
  } else if (domainType == 4) {
    //组织机构微服务
    domain = config.apiDomain + '/' + config.orgServer;
  } else if (domainType == 3) {
    //字典微服务
    domain = config.apiDomain + '/' + config.resourceServer;
  } else if (domainType == 1) {
    //核心出单微服务
    domain = config.apiDomain + '/' + config.viCoreServer;
  } else {
    //其他
    domain = config.apiDomain;
  }
  return domain + url;
}