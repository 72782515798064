import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import dict from '../../mixins/dict';
import Dialog from '@/components/Dialog';
import policyTemplateRequest from "@/api/policyTemplate";
import dictRequest from "@/api/dictionary";
export default {
  mixins: [dict],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    opType: {
      type: String,
      default: "add"
    },
    itemForm: {
      type: Object,
      default: function _default() {}
    },
    templateId: {
      type: Number | String,
      default: ""
    }
  },
  components: {
    Dialog: Dialog
  },
  data: function data() {
    return {
      form: {},
      formLoading: false,
      supportTypeEnum: [],
      methodEnum: [],
      positionTypeEnum: [],
      policyFieidEnum: [],
      rules: {
        fieldCode: [{
          required: true,
          message: '请选择字段名称',
          trigger: 'change'
        }],
        pageIndex: [{
          required: true,
          message: '请输入所在页码',
          trigger: 'change'
        }],
        methodCode: [{
          required: true,
          message: '请选择识别方式',
          trigger: 'change'
        }],
        templateName: [{
          required: true,
          message: '请输入模板名称',
          trigger: 'blur'
        }],
        priority: [{
          required: true,
          message: '请输入优先级',
          trigger: 'blur'
        }],
        pdfFileId: [{
          required: true,
          message: '请选择上传文件',
          trigger: 'change'
        }]
      }
    };
  },
  watch: {
    value: function value(val) {
      var _this = this;
      if (val) {
        this.form = {
          enable: true,
          supportTag: true
        };
        this.$nextTick(function () {
          return _this.$refs["detail"].clearValidate();
        });
        this.getSupportTypeEnum();
        this.getRecoMethodEnum();
        this.getPositionTypeEnum();
        this.getDictByCode();
        if (this.opType != 'add') {
          this.getConfigDetailById();
        }
      }
    }
  },
  computed: {
    showDialog: {
      get: function get() {
        return this.value;
      },
      set: function set(val) {
        this.$emit('input', val);
      }
    }
  },
  mounted: function mounted() {},
  methods: {
    getDictByCode: function getDictByCode() {
      var _this2 = this;
      dictRequest.getDictItemsByCode("P_TEMPLATE_FIEID").then(function (res) {
        if (res.code == 200) {
          _this2.policyFieidEnum = res.data;
        }
      });
    },
    // 定位方式枚举
    getPositionTypeEnum: function getPositionTypeEnum() {
      var _this3 = this;
      policyTemplateRequest.getPositionTypeEnum().then(function (res) {
        if (res.code == 200) {
          _this3.positionTypeEnum = res.data;
        }
      });
    },
    //  判断方式枚举
    getSupportTypeEnum: function getSupportTypeEnum() {
      var _this4 = this;
      policyTemplateRequest.getSupportTypeEnum().then(function (res) {
        if (res.code == 200) {
          _this4.supportTypeEnum = res.data;
        }
      });
    },
    // 识别方式枚举
    getRecoMethodEnum: function getRecoMethodEnum() {
      var _this5 = this;
      policyTemplateRequest.getRecoMethodEnum().then(function (res) {
        if (res.code == 200) {
          _this5.methodEnum = res.data;
        }
      });
    },
    changeMethod: function changeMethod(val) {
      if (val) {
        policyTemplateRequest.getTemplatedata({
          templateId: this.templateId,
          dataType: val
        }).then(function (res) {
          if (res.code == 200) {}
        });
      }
    },
    //  查看详情
    getConfigDetailById: function getConfigDetailById() {
      var _this6 = this;
      this.formLoading = true;
      policyTemplateRequest.getConfigDetailById(this.itemForm.configId).then(function (res) {
        _this6.formLoading = false;
        if (res.code == 200) {
          _this6.form = res.data;
        }
      }).catch(function (err) {
        return _this6.formLoading = false;
      });
    },
    // 保存
    save: function save() {
      var _this7 = this;
      this.$refs["detail"].validate(function (valid) {
        if (valid) {
          var postData = _objectSpread(_objectSpread({}, _this7.form), {}, {
            templateId: _this7.templateId,
            fieldName: "",
            getCellType: _this7.form.getCellType || null,
            supportType: _this7.form.supportType || null
          });
          var policyFieId = _.find(_this7.policyFieidEnum, function (item) {
            return item.itemCode == _this7.form.fieldCode;
          }) || {};
          postData.fieldName = policyFieId.itemName;
          _this7.formLoading = true;
          var req = _this7.opType == 'add' ? policyTemplateRequest.createTemplateConfig(postData) : policyTemplateRequest.updataTemplateConfig(_objectSpread(_objectSpread({}, postData), {}, {
            configId: _this7.itemForm.configId
          }));
          req.then(function (res) {
            _this7.formLoading = false;
            if (res.code == 200) {
              _this7.$message.success(res.msg);
              _this7.closePage(true);
            }
          }).catch(function (err) {
            return _this7.formLoading = false;
          });
        }
      });
    },
    // 下载文件
    downloadFile: function downloadFile(file) {},
    closePage: function closePage(isRefresh) {
      //关闭弹窗
      this.$emit('closePage', isRefresh);
    }
  }
};