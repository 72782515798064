//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import storageRequest from '@/api/storage';
import configRequest from '@/api/insuranceContractManage';
import { downFile } from '@/utils/common';
export default {
  props: {
    refresh: {
      type: Boolean
    }
  },
  data: function data() {
    return {
      formData: '',
      uploadForm: {
        storageId: ''
      },
      fileList: [],
      importLoading: false
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    // 初始化
    initData: function initData() {
      this.uploadForm = {
        storageId: ''
      };
      this.fileList = [];
    },
    // 下载模板
    download: function download() {
      var _this = this;
      storageRequest.downFileTemplate("IMPORT_SPECIAL_AGREEMENT ").then(function (res) {
        if (res.data && res.data.fileStorageId) {
          downFile(res.data.fileUrl, res.data.fileName);
        } else {
          _this.$message.warning('暂无模板，请联系客服！');
        }
        ;
      });
    },
    // 上传文件
    uploadFile: function uploadFile(param) {
      var _this2 = this;
      var formData = new FormData();
      formData.append("file", param.file);
      storageRequest.uploadFile(formData).then(function (res) {
        if (res.code === 200) {
          _this2.$message.success(res.msg);
          setTimeout(function () {
            _this2.uploadForm.storageId = res.data.fileStorageId;
            _this2.uploadForm.fileUrl = res.data.fileUrl;
          }, 1000);
        }
      });
    },
    // 附件校验
    validate: function validate() {
      var flag = true;
      if (!this.uploadForm.storageId) {
        this.$message.warning('请上传文件');
        flag = false;
        return flag;
      }
      return flag;
    },
    // 上传点击
    selectFile: function selectFile() {
      var _this3 = this;
      if (!this.validate()) return;
      this.importLoading = true;
      configRequest.importSpecialAgreement(this.uploadForm).then(function (res) {
        _this3.importLoading = false;
        if (res.code === 200) {
          _this3.closePage();
        }
      }).catch(function () {
        _this3.importLoading = false;
      });
    },
    closePage: function closePage() {
      //关闭弹窗
      this.$emit("closePage");
    }
  }
};