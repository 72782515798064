import "core-js/modules/es.array.concat.js";
/**
 * 机构业务配置接口
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 列表
   * @param {*} orgCodeId 机构编码Id
   * @param {*} pageSize 每页显示条数
   * @param {*} data 内容
   */
  getList: function getList(data) {
    return request.get(getUrl("/org-biz-config/getBizSchema", 1), {
      params: data
    });
  },
  /**
   * 详情
   * @param {*} id 查询参数
   */
  getDetail: function getDetail(id) {
    return request.get(getUrl("/org-biz-config/get?orgBizConfigId=".concat(id), 1));
  },
  /**
   * 新增
   * @param {*} data 内容
   */
  create: function create(data) {
    return request.post(getUrl("/org-biz-config/save", 1), data);
  },
  /**
   * 修改
   * @param {*} id 查询参数
   * @param {*} data 内容
   */
  update: function update(data) {
    return request.post(getUrl("/org-biz-config/update", 1), data);
  },
  /**
   * 删除
   * @param {*} ids 
   */
  delete: function _delete(data) {
    return request.post(getUrl("/org-biz-config/delete?bizType=".concat(data.bizType, "&orgCode=").concat(data.orgCode, "&orgType=").concat(data.orgType), 1), {});
  }
};
export default objApi;