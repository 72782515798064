import "core-js/modules/es.object.keys.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import request from '@/api/message';
import { downFile } from '@/utils/common';
export default {
  props: {
    currentId: {
      type: String,
      default: ''
    },
    refresh: {
      type: Boolean,
      default: true
    },
    msgAnnouncementStatus: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      fileList: [],
      formLoading: false,
      uploadStatus: '',
      form: {
        messageTitle: '',
        messageSendDatetime: '',
        messageContent: '',
        messageFile: []
      }
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.sendMsg();
      }
    }
  },
  mounted: function mounted() {},
  created: function created() {
    this.sendMsg();
  },
  methods: {
    //点击，使用id下载文件
    handle: function handle(row) {
      if (row.id) {
        downFile(row.fileUrl, row.fileName);
      }
    },
    sendMsg: function sendMsg() {
      var _this = this;
      this.formLoading = true;
      this.form = {
        messageTitle: '',
        messageSendDatetime: '',
        messageContent: '',
        messageFile: []
      };
      request.viewMessageReceived(this.currentId).then(function (res) {
        _this.formLoading = false;
        if (res.code === 200 && res.data) {
          _this.form.messageSendDatetime = res.data.messageSendDatetime;
          _this.form.messageTitle = res.data.messageTitle;
          _this.form.messageContent = res.data.messageContentTranslated;
          _this.form.messageFile = res.data.messageFile ? JSON.parse(res.data.messageFile) : [];
        }
        ;
      }).catch(function (err) {
        _this.formLoading = false;
      });
    },
    //关闭弹窗
    closePage: function closePage() {
      this.$emit('closePage');
    }
  }
};