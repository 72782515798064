var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-m-cls", staticStyle: {} }, [
    _c(
      "div",
      {
        ref: "header",
        staticClass: "header-cls",
        staticStyle: { "max-height": "500px", overflow: "auto" },
      },
      [
        _c(
          "el-card",
          { attrs: { shadow: "never" } },
          [
            _c(
              "el-form",
              {
                ref: "detail",
                staticStyle: { "margin-bottom": "-20px" },
                attrs: {
                  model: _vm.form,
                  "label-width": "130px",
                  size: "mini",
                },
              },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c("el-form-item", {
                          staticStyle: { "margin-bottom": "0" },
                          attrs: { label: "结账保险公司：" },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c("el-form-item", {
                          staticStyle: { "margin-bottom": "0" },
                          attrs: { label: "结账批次：" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c("el-form-item", {
                          attrs: { label: "手续费总额：" },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c("el-form-item", {
                          attrs: { label: "需开票金额：" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c("el-form-item", {
                          attrs: { label: "已开票金额：" },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c("el-form-item", {
                          attrs: { label: "待开票金额：" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "请填写开票抬头：" } },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "请填写开票抬头",
                                readonly: "",
                              },
                              model: {
                                value: _vm.form.ownerCustName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "ownerCustName", $$v)
                                },
                                expression: "form.ownerCustName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "备注：" } },
                          [
                            _c("el-input", {
                              attrs: {
                                type: "textarea",
                                rows: 3,
                                placeholder: "填写本次开票理由，最多可输入80字",
                                maxlength: 80,
                              },
                              model: {
                                value: _vm.textarea,
                                callback: function ($$v) {
                                  _vm.textarea = $$v
                                },
                                expression: "textarea",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticStyle: { cursor: "pointer" },
                    on: {
                      click: function ($event) {
                        _vm.isInvoiceInfo = !_vm.isInvoiceInfo
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.isInvoiceInfo ? "开票登记明细" : "开票登记表单"
                      )
                    ),
                  ]
                ),
                _vm.isInvoiceInfo
                  ? _c(
                      "div",
                      {
                        staticStyle: {
                          margin: "15px 0",
                          height: "150px",
                          overflow: "auto",
                        },
                      },
                      [
                        _c(
                          "table",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { border: "1" },
                          },
                          [
                            _c("colgroup", [
                              _c("col", { staticStyle: { width: "23%" } }),
                              _c("col", { staticStyle: { width: "10%" } }),
                              _c("col", { staticStyle: { width: "7%" } }),
                              _c("col", { staticStyle: { width: "25%" } }),
                              _c("col", { staticStyle: { width: "15%" } }),
                              _c("col", { staticStyle: { width: "15%" } }),
                              _c("col", { staticStyle: { width: "5%" } }),
                            ]),
                            _c(
                              "thead",
                              { staticStyle: { "background-color": "#eeee" } },
                              [
                                _c(
                                  "tr",
                                  { staticStyle: { "font-size": "13px" } },
                                  [
                                    _c("th", { attrs: { scope: "col" } }, [
                                      _vm._v("开票金额(含税)"),
                                    ]),
                                    _c("th", { attrs: { scope: "col" } }, [
                                      _vm._v("税率(%)"),
                                    ]),
                                    _c("th", { attrs: { scope: "col" } }, [
                                      _vm._v("税额"),
                                    ]),
                                    _c("th", { attrs: { scope: "col" } }, [
                                      _vm._v("开票金额(不含税)"),
                                    ]),
                                    _c("th", { attrs: { scope: "col" } }, [
                                      _vm._v("发票号"),
                                    ]),
                                    _c("th", { attrs: { scope: "col" } }, [
                                      _vm._v("开票日期"),
                                    ]),
                                    _c("th", { attrs: { scope: "col" } }),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "tbody",
                              [
                                _vm._l(_vm.tableData, function (item, index) {
                                  return _c(
                                    "tr",
                                    { key: index, staticClass: "tableTr" },
                                    [
                                      _c(
                                        "td",
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "",
                                              clearable: "",
                                            },
                                            model: {
                                              value: item.val,
                                              callback: function ($$v) {
                                                _vm.$set(item, "val", $$v)
                                              },
                                              expression: "item.val",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "td",
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "",
                                              clearable: "",
                                            },
                                            model: {
                                              value: item.val1,
                                              callback: function ($$v) {
                                                _vm.$set(item, "val1", $$v)
                                              },
                                              expression: "item.val1",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "td",
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "",
                                              clearable: "",
                                            },
                                            model: {
                                              value: item.val2,
                                              callback: function ($$v) {
                                                _vm.$set(item, "val2", $$v)
                                              },
                                              expression: "item.val2",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "td",
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "",
                                              clearable: "",
                                            },
                                            model: {
                                              value: item.val3,
                                              callback: function ($$v) {
                                                _vm.$set(item, "val3", $$v)
                                              },
                                              expression: "item.val3",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "td",
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "",
                                              clearable: "",
                                            },
                                            model: {
                                              value: item.val4,
                                              callback: function ($$v) {
                                                _vm.$set(item, "val4", $$v)
                                              },
                                              expression: "item.val4",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "td",
                                        [
                                          _c("el-date-picker", {
                                            staticStyle: { width: "100%" },
                                            attrs: { type: "date" },
                                            model: {
                                              value: item.val5,
                                              callback: function ($$v) {
                                                _vm.$set(item, "val5", $$v)
                                              },
                                              expression: "item.val5",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("td", [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-remove-outline deleteIcon",
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteInvoiceInfo(
                                                index
                                              )
                                            },
                                          },
                                        }),
                                      ]),
                                    ]
                                  )
                                }),
                                _c("tr", [
                                  _c("td", { attrs: { colspan: "7" } }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "add_clo",
                                        on: { click: _vm.addInvoiceInfo },
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-circle-plus-outline addIcon",
                                        }),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ],
                              2
                            ),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                !_vm.isInvoiceInfo
                  ? _c(
                      "div",
                      {
                        staticStyle: {
                          margin: "15px 0",
                          height: "150px",
                          overflow: "auto",
                        },
                      },
                      [
                        _c(
                          "el-table",
                          {
                            ref: "multipleTable",
                            staticStyle: { width: "100%" },
                            attrs: {
                              size: "mini",
                              border: "",
                              data: _vm.tableData,
                              height: "150px",
                              "header-cell-style": { background: "#F7F7F7" },
                              "highlight-current-row": "",
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "",
                                label: "发票",
                                "min-width": "100",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "",
                                label: "开票金额(不含税)",
                                "min-width": "130",
                                align: "right",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "",
                                label: "税金",
                                align: "right",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "",
                                label: "税率",
                                align: "right",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "",
                                label: "开票金额(含税)",
                                "min-width": "120",
                                align: "right",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "",
                                label: "开票日期",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _c(
                              "el-table-column",
                              {
                                attrs: {
                                  label: "",
                                  align: "center",
                                  "show-overflow-tooltip": "",
                                  fixed: "right",
                                },
                              },
                              [
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "primary",
                                        size: "mini",
                                        plain: "",
                                      },
                                    },
                                    [_vm._v("废弃")]
                                  ),
                                ],
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticStyle: {
          display: "flex",
          "justify-content": "center",
          "margin-top": "8px",
        },
      },
      [
        _c(
          "el-button",
          {
            attrs: {
              icon: "el-icon-check",
              type: "primary",
              plain: "",
              size: "mini",
            },
            on: { click: _vm.save },
          },
          [_vm._v("保存")]
        ),
        _c(
          "el-button",
          {
            attrs: { icon: "el-icon-close", size: "mini" },
            on: { click: _vm.closePage },
          },
          [_vm._v("关闭")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }