var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-m-cls", staticStyle: {} }, [
    _c(
      "div",
      {
        ref: "header",
        staticClass: "header-cls",
        staticStyle: { "max-height": "540px", overflow: "auto" },
      },
      [
        _c(
          "el-card",
          { attrs: { shadow: "never" } },
          [
            _c(
              "el-form",
              {
                ref: "detail",
                staticStyle: { "margin-bottom": "-20px" },
                attrs: {
                  model: _vm.form,
                  rules: _vm.rules,
                  "label-width": "110px",
                  size: "mini",
                },
              },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "文件编码：", prop: "fileCode" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: "请输入文件编码",
                                maxlength: "40",
                                clearable: "",
                              },
                              model: {
                                value: _vm.form.fileCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "fileCode", $$v)
                                },
                                expression: "form.fileCode",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "排序权重：", prop: "sortWeight" },
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: "请输入排序权重",
                                maxlength: "40",
                                clearable: "",
                              },
                              model: {
                                value: _vm.form.sortWeight,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "sortWeight", $$v)
                                },
                                expression: "form.sortWeight",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "文件说明：", prop: "remark" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                type: "textarea",
                                autosize: { minRows: 2, maxRows: 4 },
                                resize: "none",
                                placeholder: "请输入文件说明",
                                maxlength: "40",
                                clearable: "",
                              },
                              model: {
                                value: _vm.form.remark,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "remark", $$v)
                                },
                                expression: "form.remark",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "must-star",
                            attrs: {
                              label: "上传文件：",
                              prop: "fileStorageId",
                            },
                          },
                          [
                            _c(
                              "el-upload",
                              {
                                ref: "upload",
                                attrs: {
                                  "before-remove": _vm.clearData,
                                  limit: 1,
                                  action: "",
                                  "http-request": _vm.uploadFile,
                                  "file-list": _vm.fileList,
                                  accept: "*",
                                  "list-type": "text",
                                  "on-preview": _vm.handlePreview,
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  { attrs: { size: "mini", plain: "" } },
                                  [_vm._v("选择文件")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticStyle: {
          display: "flex",
          "justify-content": "center",
          "margin-top": "8px",
        },
      },
      [
        _c(
          "el-button",
          {
            attrs: {
              icon: "el-icon-check",
              type: "primary",
              plain: "",
              size: "mini",
              disabled: !_vm.form.fileStorageId,
            },
            on: { click: _vm.save },
          },
          [_vm._v("保存")]
        ),
        _c(
          "el-button",
          {
            attrs: { icon: "el-icon-close", size: "mini" },
            on: { click: _vm.closePage },
          },
          [_vm._v("关闭")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }