import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import dict from '@/mixins/dict';
import Pagination from '@/components/Pagination';
import addDialog from './add';
import ConfigDictDialog from './dictDialog';
import VerificationDialog from './verificationDialog';
// import detailDialog from './detail'
import OrgSelect from '@/components/OrgSelect';
import recognitionApi from '@/api/recognition';
import inscorpdiffconfigRequest from '@/api/inscorpdiffconfig';
import { getSupplyers, commonExport } from '@/utils/common';
export default {
  name: 'PDFparse',
  components: {
    Pagination: Pagination,
    addDialog: addDialog,
    ConfigDictDialog: ConfigDictDialog,
    VerificationDialog: VerificationDialog,
    // detailDialog,
    OrgSelect: OrgSelect
  },
  mixins: [initHeight, dict],
  computed: {},
  data: function data() {
    return {
      districtType: 'city',
      proList: [],
      districtTypeList: [{
        name: "省",
        code: 'province'
      }, {
        name: "市",
        code: 'city'
      }],
      showVerificationDialog: false,
      showConfigDictDialog: false,
      supplyers: [],
      InsuranceList: [{
        code: "BZ",
        name: "交强险"
      }, {
        code: "BS",
        name: "商业险"
      }],
      cascaderAddr: [],
      cityOptions: JSON.parse(localStorage.getItem("VI_CityPcTree")) || [],
      orgForm: {
        salesOrgCode: "",
        salesOrgName: ""
      },
      dialogType: '',
      ocrFunctionSupplierOrgId: '',
      showAddDialog: false,
      showViewDialog: false,
      activeName: "all",
      drawer: false,
      searchForm: {
        insCityCode: "" //投保城市编码
        // insCorpCode: "", //	保险公司编码
        // riskType: "", //险种类型
      },

      form: {},
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      defaultProps: {
        children: 'children',
        label: 'fullName'
      },
      tableData: [],
      multipleSelection: [],
      opType: '',
      startEndTime: null,
      loading: false,
      orgData: [],
      highlightCurrent: true,
      cluesId: '',
      tabName: 'all',
      details: {}
    };
  },
  created: function created() {},
  mounted: function mounted() {
    // alert(11111)
    this.getDistricts();
    this.supplyers = getSupplyers();
    this.loadData();
    console.log('调用记录');
  },
  methods: {
    ChangeDistrictType: function ChangeDistrictType() {
      this.searchForm.insCityCode = '';
      this.cascaderAddr = [];
    },
    getDistricts: function getDistricts() {
      var _this = this;
      //获取省份
      inscorpdiffconfigRequest.getDistricts({
        type: "PROVINCE"
      }).then(function (res) {
        _this.proList = res.data;
      });
    },
    verification: function verification() {
      this.showVerificationDialog = true;
    },
    configDict: function configDict() {
      this.showConfigDictDialog = true;
    },
    getAccidentType: function getAccidentType() {},
    handleChange: function handleChange(val) {
      var _this2 = this;
      //省/市/区选择
      this.$nextTick(function () {
        // let cascaderAddr = this.$refs['cascaderAddr'].getCheckedNodes()[0].pathLabels;
        console.log(val);
        _this2.searchForm.insCityCode = val[1];
      });
    },
    // 导出
    exportFn: function exportFn() {
      var exporParams = {
        total: this.total,
        fileName: '线索管理列表',
        exportUrl: '/oc-core-service/repair/clues',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    nodeClick: function nodeClick(data) {
      // 树形点击
      // this.form.orgItems = data.salesOrgCode
      this.searchForm.orgCode = data.salesOrgCode;
      this.searchForm.areaType = data.salesOrgType;
    },
    reload: function reload() {
      this.searchData();
    },
    handleTabClick: function handleTabClick(tab, event) {
      //tab切换事件
      this.tabName = tab.name;
      this.listQuery.pageNum = 1;
      this.loadData();
      this.$forceUpdate();
    },
    toAdd: function toAdd(type) {
      // this.dialogType = type;
      this.$router.replace({
        name: "PDFparseAdd",
        query: {
          'type': type
        }
      });
      // this.showAddDialog = true;
    },
    edit: function edit(type, item) {
      // console.log(item)
      // this.dialogType = type;
      // this.ocrFunctionSupplierOrgId = item.ocrFunctionSupplierOrgId.toString()
      // this.showAddDialog = true;
      this.$router.replace({
        name: "PDFparseAdd",
        query: {
          'type': type,
          'id': item.templateId
        }
      });
    },
    delClicked: function delClicked(item) {
      var _this3 = this;
      this.$confirm('是否删除当前数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        recognitionApi.delTemplate(item.templateId).then(function (res) {
          if (res.code == 200) {
            _this3.$message.success('删除成功');
            _this3.loadData();
          }
        });
      }).catch(function () {});
    },
    detail: function detail(item) {
      this.showViewDialog = true;
      // this.cluesId = item.ocrFunctionInvokeRecordId.toString()
      // recognitionApi.getDetail(item.ocrFunctionInvokeRecordId).then(res => {
      //     console.log(res)
      //     if (res.code == 200) {
      //         this.details = res.data ? res.data : {}
      //     }
      // })
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.listQuery.pageNum = 1;
      this.searchForm = {
        InsuranceCode: null,
        insCorpCode: null
      };
      this.cascaderAddr = [];
      this.districtType = '';
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    loadData: function loadData() {
      var _this4 = this;
      //获取列表数据
      // this.loading = true;
      this.drawer = false;
      this.showVerificationDialog = false;
      this.showConfigDictDialog = false;
      this.showAddDialog = false;
      this.searchForm.pageSize = this.listQuery.pageSize;
      this.searchForm.pageNum = this.listQuery.pageNum;
      recognitionApi.SearchTemplateList(this.searchForm).then(function (res) {
        if (res.code == 200) {
          _this4.tableData = res.data.list ? res.data.list : [];
          _this4.total = res.data.page.recordsTotal;
        } else {
          _this4.tableData = [];
          _this4.total = 0;
        }
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      //列表行选择事件
      this.multipleSelection = val;
    }
  }
};