import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import userCenterApi from '@/api/userCenter';
export default {
  mixins: [initHeight],
  props: {
    netUserId: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      listLoading: false,
      tableData: []
    };
  },
  watch: {
    netUserId: {
      handler: function handler(newValue, oldValue) {
        this.loadData();
      },
      immediate: true
    }
  },
  methods: {
    loadData: function loadData() {
      var _this = this;
      this.listLoading = true;
      userCenterApi.getPolymerizeList(this.netUserId).then(function (res) {
        _this.listLoading = false;
        if (res.code === 200) {
          _this.tableData = res.data || [];
        }
        ;
      }).catch(function (err) {
        _this.listLoading = false;
      });
    },
    // 跳转对应业务的详情
    toDetails: function toDetails(item) {
      switch (item.bizDataCode) {
        // 车险保单
        case 'VI_CORE_INSURE_POLICY':
          this.$router.push({
            path: "/policy/detail",
            query: {
              policyId: item.bizDataId,
              policyNo: item.bizDataOrderNo
            }
          });
          break;
        // 增值服务
        case 'PS_HOZONAUTO_GAP_ORDER':
          this.$router.push({
            path: '/nonVehicleDetail',
            query: {
              productOrderNo: item.bizDataOrderNo
            }
          });
          break;
        // 车险报价
        case 'VI_CORE_INSURE_QUOTE':
          this.$router.push({
            path: '/proposal/detail',
            query: {
              quoteId: item.bizDataId
            }
          });
          break;
        default:
          break;
      }
    }
  }
};