import "core-js/modules/es.array.concat.js";
/**
 * 活动中心接口
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 获取权益服务列表
   * @param {*} data 内容
   */
  getCouponProductList: function getCouponProductList(data) {
    return request.get(getUrl("/couponproduct/search", 15), {
      params: data
    });
  },
  /**
   * 获取所有的权益服务列表
   */
  getAllCouponProductList: function getAllCouponProductList() {
    return request.get(getUrl("/couponproduct/list", 15));
  },
  /**
   * 新增权益服务列表
   * @param {*} data 内容
   */
  createCouponProduct: function createCouponProduct(data) {
    return request.post(getUrl("/couponproduct/create", 15), data);
  },
  /**
  * 编辑权益服务列表
  * @param {*} data 内容
  */
  updateCouponProduct: function updateCouponProduct(data) {
    return request.post(getUrl("/couponproduct/update", 15), data);
  },
  /**
   * 删除权益服务列表
   * @param {*} data 内容
   */
  deleteCouponProduct: function deleteCouponProduct(id) {
    return request.delete(getUrl("/couponproduct/delete/".concat(id), 15));
  },
  /**
   * 获取权益服务明细
   * @param {*} id 内容
   */
  getDetailByCouponproductId: function getDetailByCouponproductId(id) {
    return request.get(getUrl("/couponproduct/get?id=".concat(id), 15));
  },
  //----------------------------活动规则接口----------------------------------------
  /**
   * 获取活动规则列表
   * @param {*} data 内容
   */
  getActivityRuleList: function getActivityRuleList(data) {
    return request.get(getUrl("/couponrulefactor/search", 15), {
      params: data
    });
  },
  /**
  * 获取所有活动规则列表
  */
  getAllActivityRuleList: function getAllActivityRuleList() {
    return request.get(getUrl("/couponrulefactor/list", 15));
  },
  /**
   * 新增活动规则
   * @param {*} data 内容
   */
  createActivityRule: function createActivityRule(data) {
    return request.post(getUrl("/couponrulefactor/create", 15), data);
  },
  /**
   * 修改活动规则
   * @param {*} data 内容
   */
  updateActivityRule: function updateActivityRule(data) {
    return request.post(getUrl("/couponrulefactor/update", 15), data);
  },
  /**
   * 删除活动规则
   * @param {*} id 内容
   */
  deleteActivityRule: function deleteActivityRule(id) {
    return request.delete(getUrl("/couponrulefactor/delete/".concat(id), 15));
  },
  /**
  * 获取活动规则明细
  * @param {*} id 内容
  */
  getDetailByCouponRuleFactorId: function getDetailByCouponRuleFactorId(id) {
    return request.get(getUrl("/couponrulefactor/get?id=".concat(id), 15));
  },
  // ----------------------------------数据管理-------------------------------------------
  /**
   * 获取经销商管控数据列表
   * @param {*} data 内容
   */
  getDealerDateList: function getDealerDateList(data) {
    return request.get(getUrl("/couponruleorgcount/search", 15), {
      params: data
    });
  },
  /**
  * 导入经销商管控数据
  * @param {*} id 内容
  */
  exportDealerDate: function exportDealerDate(id, fileName) {
    return request.post(getUrl("/couponruleorgcount/importExcel?storageId=".concat(id, "&fileName=").concat(fileName), 15));
  },
  /**
  * 获取续保数据列表
  * @param {*} data 内容
  */
  getRenewalList: function getRenewalList(data) {
    return request.get(getUrl("/couponrenewalrate/search", 15), {
      params: data
    });
  },
  /**
  * 获取数据管理导入详情数据
  * @param {*} data 内容
  */
  getDealerDetailById: function getDealerDetailById(data) {
    return request.get(getUrl("/couponimportlogdata/list", 15), {
      params: data
    });
  },
  /**
  * 导入续保数据
  * @param {*} id 内容
  */
  exportRenewal: function exportRenewal(id) {
    return request.post(getUrl("/couponrenewalrate/importExcel?storageId=".concat(id), 15));
  },
  /**
  * 下载续保模板
  */
  downloadRenewalTemplate: function downloadRenewalTemplate() {
    return request({
      method: 'get',
      url: getUrl("/couponrenewalrate/excelTemplate", 15),
      responseType: 'blob'
    });
  },
  // ----------------------------------活动管理-------------------------------------------
  /**
  * 领卡券接口
  * @param {*} data 内容
  */
  getReceiveapi: function getReceiveapi(data) {
    return request.post(getUrl("/couponreceive/receiveapi", 15), data);
  },
  /**
   * 获取活动管理数据列表
   * @param {*} data 内容
   */
  getActivityManagementList: function getActivityManagementList(data) {
    return request.get(getUrl("/couponactivity/search", 15), {
      params: data
    });
  },
  /**
   * 获取全部活动管理数据列表
   */
  getAllActivityManagementList: function getAllActivityManagementList() {
    var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return request.get(getUrl("/couponactivity/list", 15), {
      params: data
    });
  },
  /**
   * 创建活动管理
   * @param {*} data 内容
   */
  createActivityManagement: function createActivityManagement(data) {
    return request.post(getUrl("/couponactivity/create", 15), data);
  },
  /**
   * 更新活动管理
   * @param {*} data 内容
   */
  updataActivityManagement: function updataActivityManagement(data) {
    return request.post(getUrl("/couponactivity/update", 15), data);
  },
  /**
   * 删除活动管理
   * @param {*} id 内容
   */
  deleteActivityManagement: function deleteActivityManagement(id) {
    return request.delete(getUrl("/couponactivity/delete/".concat(id), 15));
  },
  /**
  * 查询活动管理详情
  * @param {*} id 内容
  */
  getDetailByCouponActivityId: function getDetailByCouponActivityId(id) {
    return request.get(getUrl("/couponactivity/get?id=".concat(id), 15));
  },
  /**
  * 创建活动管理规则
  * @param {*} data 内容
  */
  createActivityManagementRule: function createActivityManagementRule(data) {
    return request.post(getUrl("/couponactivityrule/create", 15), data);
  },
  /**
  * 更新活动管理规则
  * @param {*} data 内容
  */
  updataActivityManagementRule: function updataActivityManagementRule(data) {
    return request.post(getUrl("/couponactivityrule/update", 15), data);
  },
  /**
  * 获取活动管理规则列表
  * @param {*} data 内容
  */
  getActivityManagementRuleList: function getActivityManagementRuleList(data) {
    return request.get(getUrl("/couponactivityrule/list", 15), {
      params: data
    });
  },
  /**
  * 删除活动管理规则列表
  * @param {*} id 内容
  */
  deleteActivityManagementRule: function deleteActivityManagementRule(id) {
    return request.delete(getUrl("/couponactivityrule/delete/".concat(id), 15));
  },
  /**
  * 获取活动管理规则详情
  * @param {*} id 内容
  */
  getDetailByCouponActivityRuleId: function getDetailByCouponActivityRuleId(id) {
    return request.get(getUrl("/couponactivityrule/get?id=".concat(id), 15));
  },
  /**
  * 发布
  * @param {*} ids 内容
  */
  releaseActivity: function releaseActivity(ids) {
    return request.post(getUrl("/couponactivity/enable", 15), ids);
  },
  /**
  * 撤回
  * @param {*} ids 内容
  */
  withdraw: function withdraw(ids) {
    return request.post(getUrl("/couponactivity/disable", 15), ids);
  },
  /**
  * 结算政策
  * @param {*} data 内容
  */
  settlementActivities: function settlementActivities(data) {
    return request.post(getUrl("/couponactivity/settle", 15), data);
  },
  /**
  * 撤销结算政策
  * @param {*} data 内容
  */
  withdrawSettlementActivities: function withdrawSettlementActivities(data) {
    return request.post(getUrl("/couponactivity/cancelsettle", 15), data);
  },
  // ----------------------------------活动发放-------------------------------------------
  /**
     * 获取活动发放数据列表
     * @param {*} data 内容
     */
  getReceivingRecordList: function getReceivingRecordList(data) {
    return request.get(getUrl("/couponreceive/search", 15), {
      params: data
    });
  },
  /**
     * 删除活动发放数据
     * @param {*} id 内容
     */
  deleteReceivingRecord: function deleteReceivingRecord(id) {
    return request.delete(getUrl("/couponreceive/delete/".concat(id), 15));
  },
  // ----------------------------------活动统计-------------------------------------------
  /**
     * 获取活动统计数据列表
     * @param {*} data 内容
     */
  getActivityStatisticsList: function getActivityStatisticsList(data) {
    return request.get(getUrl("/couponstatistics/search", 15), {
      params: data
    });
  },
  /**
     * 获取活动统计详情数据列表
     * @param {*} data 内容
     */
  getActivityStatisticsDetailList: function getActivityStatisticsDetailList(data) {
    return request.get(getUrl("/couponstatisticsdetail4/list", 15), {
      params: data
    });
  },
  /**
     * 获取导入记录列表
     * @param {*} data 内容
     */
  getImportLoglist: function getImportLoglist(data) {
    return request.get(getUrl("/couponimportlog/search", 15), {
      params: data
    });
  },
  // ----------------------------------政策核对-------------------------------------------
  /**
     * 获取政策核对列表
     * @param {*} data 内容
     */
  getVerficationList: function getVerficationList(data) {
    return request.get(getUrl("/couponRoutingInspection/get", 15), {
      params: data
    });
  }
};
export default objApi;