var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "dynamicForm",
          attrs: { model: _vm.formData, rules: _vm.rulesItems },
        },
        [
          _c(
            "el-row",
            _vm._l(_vm.form, function (item, index) {
              return _c(
                "el-col",
                { key: index, attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "line-height": "40px" },
                      attrs: {
                        label: item.name,
                        "label-width": "170px",
                        prop: item.code,
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          item.inputType == "text"
                            ? _c("el-input", {
                                staticStyle: { "margin-right": "10px" },
                                attrs: {
                                  disabled: _vm.isDisabled,
                                  clearable: "",
                                  size: "mini",
                                },
                                model: {
                                  value: item.value,
                                  callback: function ($$v) {
                                    _vm.$set(item, "value", $$v)
                                  },
                                  expression: "item.value",
                                },
                              })
                            : _c("el-select", {
                                staticStyle: { "margin-right": "10px" },
                                attrs: {
                                  disabled: _vm.isDisabled,
                                  options: item.options,
                                  placeholder: "请选择",
                                  size: "mini",
                                  clearable: "",
                                },
                                model: {
                                  value: item.insuranceType,
                                  callback: function ($$v) {
                                    _vm.$set(item, "insuranceType", $$v)
                                  },
                                  expression: "item.insuranceType",
                                },
                              }),
                          _c("div", { staticStyle: { color: "#999" } }, [
                            _vm._v(_vm._s("[" + item.code + "]")),
                          ]),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }