import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import dict from '@/mixins/dict';
import Pagination from '@/components/Pagination';
import servicePackageApi from "@/api/servicePackage";
export default {
  mixins: [initHeight, dict],
  components: {
    Pagination: Pagination
  },
  props: {
    vin: {
      type: String,
      default: null
    }
  },
  watch: {
    vin: {
      handler: function handler(newValue, oldValue) {
        this.loadData();
      },
      deep: true
    }
  },
  data: function data() {
    return {
      listData: [],
      listLoading: true,
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      }
    };
  },
  created: function created() {},
  mounted: function mounted() {
    // this.loadData();
  },
  methods: {
    loadData: function loadData() {
      var _this = this;
      //初始化数据列表
      this.listLoading = false;
      var params = _objectSpread(_objectSpread({}, this.listQuery), {}, {
        vin: this.vin
      });
      servicePackageApi.getServicePackOrderList(params).then(function (res) {
        _this.listLoading = false;
        if (!_.isEmpty(res.data)) {
          _this.listData = res.data.list;
          //总条数
          _this.total = res.data.page.recordsTotal;
        }
      }).catch(function (err) {
        _this.listLoading = false;
      });
    }
  }
};