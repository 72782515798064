import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
export var xinJiang = function xinJiang(addressDetail, cityAreaTree) {
  var addressInfo = _.cloneDeep(addressDetail);
  var fullAddress = [];
  var regionCode = [];
  var excludeMunicipalityStr = null;
  if (addressInfo.indexOf('新疆维吾尔自治区') !== -1) {
    excludeMunicipalityStr = addressInfo.split('新疆维吾尔自治区')[1];
  } else {
    excludeMunicipalityStr = addressInfo.split('新疆')[1];
  }
  // console.log('除自治区以外字符串', excludeMunicipalityStr)
  // return
  // 获取省下面的城市
  var pItem = _.find(cityAreaTree, {
    label: '新疆维吾尔自治区'
  });
  fullAddress.push(pItem.value);
  var cityNameList = ['市', '地区', '自治州'];
  var cItem = null;
  // 自治区直辖县级行政区划
  if (excludeMunicipalityStr.indexOf('自治区直辖县级行政区划') !== -1) {
    cItem = _.find(pItem.children, {
      label: '自治区直辖县级行政区划'
    });
    fullAddress.push(cItem && cItem.value);
    if (cItem && excludeMunicipalityStr.indexOf('市') !== -1) {
      var districtCountyName = excludeMunicipalityStr.split('自治区直辖县级行政区划')[1].split('市')[0];
      // console.log('districtCountyName', districtCountyName)
      var districtCountyItem = _.find(cItem.children, {
        label: districtCountyName + '市'
      });
      // console.log('自治区直辖县级行政区划districtCountyItem', districtCountyItem)
      if (districtCountyItem) {
        fullAddress.push(districtCountyItem.value);
        regionCode = districtCountyItem.value;
      }
    }
  }
  // 获取市级识别的级别（市，地区，自治州）
  var autonomousCityIndex = null;
  // 获取省下面的市级，地区,自治州
  for (var _i = 0, _cityNameList = cityNameList; _i < _cityNameList.length; _i++) {
    var cityName = _cityNameList[_i];
    if (pItem && excludeMunicipalityStr.indexOf(cityName) !== -1) {
      // console.log('cityName', cityName)
      var autonomousCityName = null;
      // 同有自治州、地区和市，自治州、地区优先级更高
      if (cityName == '自治州') {
        autonomousCityName = excludeMunicipalityStr.split('自治州')[0];
        autonomousCityIndex = '自治州';
      } else if (cityName == '地区') {
        autonomousCityName = excludeMunicipalityStr.split('地区')[0];
        autonomousCityIndex = '地区';
      } else {
        autonomousCityName = excludeMunicipalityStr.split(cityName)[0];
        autonomousCityIndex = '市';
      }
      // console.log('结尾为市的城市cityName', cityName)
      // console.log('截取市级，地区，自治州名称', excludeMunicipalityStr.split(cityName)[0])
      // console.log('截取完市级，地区，自治州后半段', excludeMunicipalityStr.split(cityName)[1])
      cItem = _.find(pItem.children, {
        label: autonomousCityName + cityName
      });
    }
  }
  // console.log('cItem', cItem)
  // console.log('autonomousCityIndex', autonomousCityIndex)
  fullAddress.push(cItem && cItem.value);
  // return
  // 新市区单独处理
  // console.log(excludeMunicipalityStr.indexOf('新市区'))
  if (cItem && excludeMunicipalityStr.indexOf('新市区') !== -1) {
    var _districtCountyItem = _.find(cItem.children, {
      label: '新市区'
    });
    fullAddress.push(_districtCountyItem.value);
    regionCode = _districtCountyItem.value;
  }
  if (cItem && autonomousCityIndex) {
    var countyNameList = ['市', '区', '县'];
    var countyLevelCityName = null;
    // 市需要单独处理
    if (excludeMunicipalityStr.split(autonomousCityIndex) && excludeMunicipalityStr.split(autonomousCityIndex).length > 2) {
      countyLevelCityName = excludeMunicipalityStr.split(autonomousCityIndex)[1] + autonomousCityIndex;
    } else {
      countyLevelCityName = excludeMunicipalityStr.split(autonomousCityIndex)[1];
    }
    // console.log('countyLevelCityName', countyLevelCityName)
    var districtCountylist = [];
    for (var _i2 = 0, _countyNameList = countyNameList; _i2 < _countyNameList.length; _i2++) {
      var countyName = _countyNameList[_i2];
      if (cItem && countyLevelCityName.indexOf(countyName) !== -1) {
        var _districtCountyName = countyLevelCityName.split(countyName)[0];
        // console.log('districtCountyName', districtCountyName)
        var _districtCountyItem2 = _.find(cItem.children, {
          label: _districtCountyName + countyName
        });
        // console.log('districtCountyItem', districtCountyItem)
        if (_districtCountyItem2) {
          districtCountylist.push(_districtCountyItem2);
        }
      }
    }
    if (!_.isEmpty(districtCountylist)) {
      fullAddress.push(districtCountylist[0].value);
      regionCode = districtCountylist[0].value;
    }
  }
  return {
    fullAddress: fullAddress,
    regionCode: regionCode
  };
};