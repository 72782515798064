var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.listLoading,
          expression: "listLoading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: {},
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "540px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "detail",
                  staticStyle: { "margin-bottom": "-20px" },
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-width": "110px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "方案名称：",
                                prop: "templateName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "200",
                                  placeholder: "请输入方案名称",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.templateName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "templateName", $$v)
                                  },
                                  expression: "form.templateName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "模板编码：",
                                prop: "templateCode",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "200",
                                  placeholder: "请输入模板编码",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.templateCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "templateCode", $$v)
                                  },
                                  expression: "form.templateCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "导入类别：",
                                prop: "importCategory",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    filterable: "",
                                    placeholder: "请选择导入类别",
                                  },
                                  on: { change: _vm.categoryChange },
                                  model: {
                                    value: _vm.form.importCategory,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "importCategory", $$v)
                                    },
                                    expression: "form.importCategory",
                                  },
                                },
                                _vm._l(
                                  _vm.importCategoryList,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.itemName,
                                        value: item.itemCode,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.isShowInsCorp
                        ? _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "保险公司：",
                                    prop: "insCorpCode",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        filterable: "",
                                        placeholder: "请选择保险公司",
                                      },
                                      on: { change: _vm.codeChange },
                                      model: {
                                        value: _vm.form.insCorpCode,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "insCorpCode", $$v)
                                        },
                                        expression: "form.insCorpCode",
                                      },
                                    },
                                    _vm._l(
                                      _vm.supplyers,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label: item.shortName,
                                            value: item.code,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "must-star",
                              attrs: { label: "映射方案：" },
                            },
                            [
                              _c(
                                "el-table",
                                {
                                  ref: "table",
                                  staticClass: "index-center",
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    size: "mini",
                                    border: "",
                                    data: _vm.form.attrList,
                                    "header-cell-style": {
                                      background: "#F7F7F7",
                                    },
                                    "highlight-current-row": "",
                                    "empty-text": "请选择 导入类别",
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "attrName",
                                      label: "字段",
                                      "min-width": "150",
                                      "show-overflow-tooltip": "",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "mandatory",
                                      label: "必录字段",
                                      align: "center",
                                      width: "100",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("el-switch", {
                                              attrs: {
                                                "active-value": true,
                                                "inactive-value": false,
                                              },
                                              model: {
                                                value: scope.row.mandatory,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "mandatory",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.mandatory",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "valueType",
                                      label: "取值方式",
                                      width: "150",
                                      "show-overflow-tooltip": "",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "el-select",
                                              {
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  filterable: "",
                                                  placeholder: "请选择取值方式",
                                                },
                                                model: {
                                                  value: scope.row.valueType,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "valueType",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.valueType",
                                                },
                                              },
                                              _vm._l(
                                                _vm.valueTypeList,
                                                function (item, index) {
                                                  return _c("el-option", {
                                                    key: index,
                                                    attrs: {
                                                      label: item.itemName,
                                                      value: item.itemCode,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "maxValue",
                                      label: "最大值",
                                      "min-width": "100",
                                      "show-overflow-tooltip": "",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("el-input", {
                                              attrs: {
                                                maxlength: "200",
                                                placeholder: "请输入最大值",
                                              },
                                              model: {
                                                value: scope.row.maxValue,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "maxValue",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.maxValue",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "valueContent",
                                      label: "标题名称/表达式/运算公式",
                                      "min-width": "200",
                                      "show-overflow-tooltip": "",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("el-input", {
                                              attrs: { maxlength: "200" },
                                              model: {
                                                value: scope.row.valueContent,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "valueContent",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.valueContent",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticStyle: { "margin-top": "10px" } },
                                [
                                  _c("b", [_vm._v("取值方式说明")]),
                                  _c("div", [
                                    _vm._v(
                                      "标题名称：据导入文件中的表格标题字段进行匹配。"
                                    ),
                                  ]),
                                  _c("div", [
                                    _vm._v(
                                      "条件表达式：根据表格中的标题名称，结合 "
                                    ),
                                    _c("b", [
                                      _vm._v(
                                        "IF， LEN， TRIM， REPLACE， EQUAL， GT， GE， LT， LE"
                                      ),
                                    ]),
                                    _vm._v(
                                      " 等表达式函数，进行条件运算。（鼠标移到表达式函数上有惊喜）"
                                    ),
                                  ]),
                                  _c("div", [
                                    _vm._v(
                                      "数学运算：根据表格中的标题名称，结合“+”，“-”，“*”，“/”进行四则运算。"
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-check",
                type: "primary",
                plain: "",
                size: "mini",
              },
              on: { click: _vm.save },
            },
            [_vm._v("确定")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: { click: _vm.closePage },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }