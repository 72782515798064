var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.formLoading,
          expression: "formLoading",
        },
      ],
      staticClass: "app-m-cls",
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "700px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    "label-width": "100px",
                    size: "mini",
                  },
                },
                [
                  _vm.invokeMonitorId
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "保单号:" } },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.form.policyNo) + " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "车牌号:" } },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.form.plateNo) + " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "车架号:" } },
                                    [_vm._v(" " + _vm._s(_vm.form.vin) + " ")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "调用机构:" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.form.callerOrgName) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "供应商:" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.form.identifySupplierDisplay
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "识别状态:" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          style: {
                                            color: _vm.form.identifyFlag
                                              ? "green"
                                              : "red",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.form.identifyFlagDisplay)
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "创建时间:" } },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.form.createdTime) + " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 16 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "跟踪ID:" } },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.form.traceId) + " "
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          directives: [
                                            {
                                              name: "clipboard",
                                              rawName: "v-clipboard:copy",
                                              value: _vm.form.traceId,
                                              expression: "form.traceId",
                                              arg: "copy",
                                            },
                                            {
                                              name: "clipboard",
                                              rawName: "v-clipboard:success",
                                              value: _vm.onCopy,
                                              expression: "onCopy",
                                              arg: "success",
                                            },
                                            {
                                              name: "clipboard",
                                              rawName: "v-clipboard:error",
                                              value: _vm.onError,
                                              expression: "onError",
                                              arg: "error",
                                            },
                                          ],
                                          attrs: { size: "mini" },
                                        },
                                        [_vm._v("复制")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          !_vm.form.identifyFlag
                            ? _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "错误信息:" } },
                                        [
                                          _c("div", [
                                            _vm._v(_vm._s(_vm.form.errorMsg)),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.form.identifyFlag
                            ? _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "识别结果:" } },
                                        [
                                          _c(
                                            "pre",
                                            { staticClass: "errorStack" },
                                            [_vm._v(_vm._s(_vm.form.result))]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: {
                click: function ($event) {
                  return _vm.closePage(false)
                },
              },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }