import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import dict from '@/mixins/dict';
import Pagination from '@/components/Pagination';
import OemSelect from '@/components/OemSelect';
import batchCheckRenewalRequest from '@/api/batchCheckRenewal';
import { getBeforeDate, getCurrentDate, commonExport, getUserOrgInfo } from '@/utils/common';
export default {
  name: 'RenewalQuery',
  components: {
    Pagination: Pagination,
    OemSelect: OemSelect
  },
  mixins: [initHeight, dict],
  computed: {},
  data: function data() {
    return {
      drawer: false,
      searchForm: {},
      createDateTime: [getBeforeDate(30), getCurrentDate()],
      total: 0,
      listLoading: true,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      listDetailQuery: {
        pageNum: 1,
        pageSize: 20
      },
      searchDetailForm: {},
      detailTotal: 0,
      detailTableData: [],
      tableData: [],
      loading: false,
      highlightCurrent: true,
      showDialog: false,
      detailLoading: false,
      taskId: null,
      showTaskDialog: false,
      taskForm: {},
      formLoading: false,
      searchFormOrgData: {
        salesOrgCode: '',
        salesOrgName: ''
      },
      rules: {
        vins: {
          required: true,
          message: '请输入车架号',
          trigger: "blur"
        }
      },
      placeholder: "\u8BF7\u5F55\u8F66\u67B6\u53F7\u3002\u4E00\u884C\u4E00\u4E2A\u8F66\u67B6\u53F7\n\u793A\u4F8B\uFF1A\nLFMCC1BR6P0627851\nLFMCC1BR6P0627852",
      activeName: " ",
      bizType: localStorage.getItem("bizType"),
      dealerName: ""
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.getDefaultValue();
    this.loadData();
  },
  methods: {
    getDefaultValue: function getDefaultValue() {
      if (this.bizType !== 'OEM') {
        var orgObj = getUserOrgInfo() || {};
        this.searchForm.orgCode = orgObj.orgCode;
        this.searchFormOrgData = {
          salesOrgCode: orgObj.orgCode,
          salesOrgName: orgObj.fullName
        };
        this.dealerName = orgObj.fullName;
      }
    },
    //机构点击事件
    nodeSearchFormClick: function nodeSearchFormClick(orgData) {
      this.searchForm.orgCode = orgData.salesOrgCode;
    },
    resetSearchDetail: function resetSearchDetail() {
      this.searchDetailForm = {};
    },
    // 打开弹窗
    openDialog: function openDialog() {
      var _this = this;
      this.showTaskDialog = true;
      this.taskForm = {
        vins: ""
      };
      this.$nextTick(function () {
        _this.$refs['detail'].clearValidate();
      });
    },
    // 保存
    save: function save() {
      var _this2 = this;
      this.$refs.detail.validate(function (valid) {
        if (valid) {
          _this2.formLoading = true;
          var postData = {
            vins: _this2.taskForm.vins.split("\n").filter(function (item) {
              if (item) {
                return item;
              }
            })
          };
          batchCheckRenewalRequest.createRenewal(postData).then(function (res) {
            _this2.formLoading = false;
            if (res.code === 200) {
              _this2.$message.success(res.msg);
              _this2.loadData();
            }
            ;
          }).catch(function (err) {
            _this2.formLoading = false;
          });
        }
      });
    },
    // 重查
    retry: function retry(item) {
      var _this3 = this;
      batchCheckRenewalRequest.retry(item.itemId).then(function (res) {
        if (res.code == 200) {
          _this3.getBatchDetailList();
          _this3.$message.success(res.msg);
        }
      });
    },
    // 改变续保状态
    changeRenewalState: function changeRenewalState(item, operate) {
      var _this4 = this;
      this.$confirm("您确定要执行该操作吗？", {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        batchCheckRenewalRequest.changeRenewalState(item.taskId, operate).then(function (res) {
          if (res.code == 200) {
            _this4.$message.success(res.msg);
            _this4.loadData();
          }
        });
      });
    },
    getBatchDetailList: function getBatchDetailList() {
      var _this5 = this;
      this.detailLoading = true;
      batchCheckRenewalRequest.getBatchDetailList(_objectSpread(_objectSpread({}, this.listDetailQuery), {}, {
        taskId: this.taskId
      }, this.searchDetailForm)).then(function (res) {
        _this5.detailLoading = false;
        if (res.code == 200) {
          _this5.detailTableData = res.data.list;
          _this5.detailTotal = res.data.page.recordsTotal;
        }
      }).catch(function (err) {
        return _this5.detailLoading = false;
      });
    },
    // 详情
    viewDetail: function viewDetail(item) {
      this.showDialog = true;
      this.taskId = item.taskId;
      this.listDetailQuery = {
        pageNum: 1,
        pageSize: 20
      };
      this.getBatchDetailList();
    },
    //日期更改监听事件
    dateChange: function dateChange(start, end, val) {
      var searchForm = {};
      if (val && val.length > 1) {
        searchForm[start] = val[0] + ' 00:00:00';
        searchForm[end] = val[1] + ' 23:59:59';
      } else {
        searchForm[start] = '';
        searchForm[end] = '';
      }
      this.$emit('pickerDateChange', [searchForm[start], searchForm[end]]);
    },
    breakData: function breakData() {
      this.loadData();
    },
    // 导出
    exportFn: function exportFn() {
      var exporParams = {
        total: this.total,
        fileName: '续保查询',
        exportUrl: '/vi-core-service/batch/renewal/search',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    //重置搜索表单
    resetSearch: function resetSearch() {
      this.searchForm = {};
      this.startEndTime = [];
      this.createDateTime = [getBeforeDate(30), getCurrentDate()];
      this.getDefaultValue();
    },
    //搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    //获取列表数据
    loadData: function loadData() {
      var _this6 = this;
      if (!_.isEmpty(this.createDateTime)) {
        this.searchForm.createdTimeStart = this.createDateTime[0] + " 00:00:00";
        this.searchForm.createdTimeEnd = this.createDateTime[1] + " 23:59:59";
      }
      this.showDialog = false;
      this.showTaskDialog = false;
      this.loading = true;
      this.drawer = false;
      batchCheckRenewalRequest.getBatchList(_objectSpread(_objectSpread(_objectSpread({}, this.listQuery), this.searchForm), {}, {
        renewalQueryStatus: this.activeName
      })).then(function (res) {
        if (res.code === 200) {
          _this6.tableData = res.data.list;
          _this6.loading = false;
          _this6.total = res.data.page.recordsTotal;
        }
      }).catch(function (err) {
        _this6.loading = false;
      });
    }
  }
};