import "core-js/modules/es.array.concat.js";
/**
 * 批单管理接口
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 列表
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   */
  getList: function getList(pageNum, pageSize, data) {
    return request.get(getUrl("/correct/policy/search?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 1), {
      params: data
    });
  },
  /**
   * 新增数据
   * @param {*} data 内容
   */
  addData: function addData(data) {
    return request.post(getUrl("/correct/policy/add", 1), data);
  },
  /**
   * 删除数据
   * @param {*} id 
   */
  deleteData: function deleteData(id) {
    return request.delete(getUrl("/correct/policy/delete/".concat(id), 1));
  }
};
export default objApi;