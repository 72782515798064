var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.formLoading,
          expression: "formLoading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: {},
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "500px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "detail",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-width": "115px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "业务机构：", prop: "orgCode" } },
                            [
                              _c("OemSelect", {
                                ref: "orgCode",
                                attrs: {
                                  searchForm: _vm.orgForm,
                                  canClickCompany: false,
                                },
                                on: { nodeClick: _vm.nodeClick },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "业务类别：",
                                prop: "businessType",
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  on: { change: _vm.businessTypeChange },
                                  model: {
                                    value: _vm.form.businessType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "businessType", $$v)
                                    },
                                    expression: "form.businessType",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: "CX" } }, [
                                    _vm._v("车险"),
                                  ]),
                                  _c("el-radio", { attrs: { label: "ZZ" } }, [
                                    _vm._v("增值服务"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.form.businessType == "CX"
                        ? _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  key: "vin",
                                  attrs: { label: "车架号：", prop: "vin" },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { display: "flex" } },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          maxlength: "17",
                                          placeholder: "请输入车架号",
                                          clearable: "",
                                        },
                                        on: {
                                          input: _vm.checkVin,
                                          blur: _vm.vincheck,
                                        },
                                        model: {
                                          value: _vm.form.vin,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "vin", $$v)
                                          },
                                          expression: "form.vin",
                                        },
                                      }),
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            size: "mini",
                                            type: "primary",
                                            disabled: !_vm.form.vin,
                                            plain: "",
                                          },
                                          on: { click: _vm.queryCustomerInfo },
                                        },
                                        [_vm._v("检索")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.form.businessType == "CX"
                        ? _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  key: "policyNo",
                                  attrs: {
                                    label: "保单号：",
                                    prop: "policyNo",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { display: "flex" } },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          maxlength: "40",
                                          placeholder: "请输入保单号",
                                          clearable: "",
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.checkPolicy(
                                              _vm.form.policyNo,
                                              "policyNo"
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.form.policyNo,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "policyNo", $$v)
                                          },
                                          expression: "form.policyNo",
                                        },
                                      }),
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            size: "mini",
                                            type: "primary",
                                            disabled: !_vm.form.policyNo,
                                            plain: "",
                                          },
                                          on: { click: _vm.queryCustomerInfo },
                                        },
                                        [_vm._v("检索")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.form.businessType == "ZZ"
                        ? _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  key: "productType",
                                  attrs: {
                                    label: "服务类别：",
                                    prop: "productType",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        placeholder: "请选择服务类别",
                                        clearable: "",
                                        filterable: "",
                                      },
                                      model: {
                                        value: _vm.form.productType,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "productType", $$v)
                                        },
                                        expression: "form.productType",
                                      },
                                    },
                                    _vm._l(
                                      _vm.productTypeList,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label: item.itemName,
                                            value: item.itemCode,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.form.businessType == "ZZ"
                        ? _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  key: "vin",
                                  attrs: { label: "车架号：", prop: "vin" },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { display: "flex" } },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          maxlength: "17",
                                          placeholder: "请输入车架号",
                                          clearable: "",
                                        },
                                        on: { input: _vm.checkVin },
                                        model: {
                                          value: _vm.form.vin,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "vin", $$v)
                                          },
                                          expression: "form.vin",
                                        },
                                      }),
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: {
                                            size: "mini",
                                            type: "primary",
                                            disabled: !(
                                              _vm.form.vin &&
                                              _vm.form.productType
                                            ),
                                            plain: "",
                                          },
                                          on: {
                                            click: _vm.zzQueryCustomerInfo,
                                          },
                                        },
                                        [_vm._v("检索")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.form.businessType == "ZZ"
                        ? _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  key: "orderNo",
                                  attrs: {
                                    label: "合同编号：",
                                    prop: "orderNo",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      maxlength: "40",
                                      placeholder: "请输入合同编号",
                                      clearable: "",
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.checkPolicy(
                                          _vm.form.orderNo,
                                          "orderNo"
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.form.orderNo,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "orderNo", $$v)
                                      },
                                      expression: "form.orderNo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              color: "#ee5252",
                              "font-size": "13px",
                            },
                          },
                          [
                            _c("i", { staticClass: "el-icon-warning-outline" }),
                            _vm._v(
                              " 输入" +
                                _vm._s(
                                  _vm.form.businessType == "CX"
                                    ? "车架号或保单号任一"
                                    : "合同编号"
                                ) +
                                "字段，点击【检索】按钮查询客户信息"
                            ),
                          ]
                        ),
                      ]),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "车主名：", prop: "ownerName" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入车主名",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.ownerName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "ownerName", $$v)
                                  },
                                  expression: "form.ownerName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "车主证件类型：",
                                prop: "ownerCertificateType",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    filterable: "",
                                    placeholder: "请选择车主证件类型",
                                  },
                                  model: {
                                    value: _vm.form.ownerCertificateType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "ownerCertificateType",
                                        $$v
                                      )
                                    },
                                    expression: "form.ownerCertificateType",
                                  },
                                },
                                _vm._l(
                                  _vm.VI_CERTIFICATE_TYPE,
                                  function (group) {
                                    return _c(
                                      "el-option-group",
                                      {
                                        key: group.label,
                                        attrs: { label: group.label },
                                      },
                                      _vm._l(
                                        group.options,
                                        function (item, index) {
                                          return _c("el-option", {
                                            key: index,
                                            attrs: {
                                              label: item.dictName,
                                              value: item.dictCode,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "车主证件号：",
                                prop: "ownerCertificate",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "20",
                                  placeholder: "请输入车主证件号",
                                  clearable: "",
                                },
                                on: {
                                  blur: _vm.certificateNoChange,
                                  input: _vm.inputCardNo,
                                },
                                model: {
                                  value: _vm.form.ownerCertificate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "ownerCertificate", $$v)
                                  },
                                  expression: "form.ownerCertificate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "联系电话：", prop: "phone" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "11",
                                  oninput: "value=value.replace(/[^\\d]/g,'')",
                                  placeholder: "请输入联系电话",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.phone,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "phone", $$v)
                                  },
                                  expression: "form.phone",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "p",
                        {
                          staticStyle: {
                            "text-align": "left",
                            "font-size": "14px",
                            "font-weight": "bold",
                          },
                        },
                        [_vm._v("赠品选择")]
                      ),
                      _vm._l(_vm.vouchersList, function (item, index) {
                        return _c("div", { key: index }, [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-bottom": "10px",
                                display: "flex",
                                "align-items": "flex-start",
                                "justify-content": "space-between",
                                "min-height": "32px",
                              },
                            },
                            [
                              _c(
                                "el-checkbox",
                                {
                                  staticStyle: {
                                    "margin-right": "50px",
                                    "margin-top": "5px",
                                  },
                                  attrs: { label: item.cardVoucherCode },
                                  on: {
                                    change: function ($event) {
                                      item.issueNumber = 1
                                    },
                                  },
                                  model: {
                                    value: item.isCheck,
                                    callback: function ($$v) {
                                      _vm.$set(item, "isCheck", $$v)
                                    },
                                    expression: "item.isCheck",
                                  },
                                },
                                [_vm._v(_vm._s(item.cardVoucherName))]
                              ),
                              item.isCheck
                                ? _c("div", [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          "align-items": "center",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticStyle: { width: "56px" } },
                                          [_vm._v("数量：")]
                                        ),
                                        _c("el-input", {
                                          attrs: {
                                            maxlength: "40",
                                            placeholder: "请输入数量",
                                            clearable: "",
                                            disabled: item.maxGiving == 1,
                                          },
                                          on: {
                                            input: function ($event) {
                                              item.issueNumber = _vm.onInput(
                                                item.issueNumber,
                                                0,
                                                item.maxGiving,
                                                0
                                              )
                                            },
                                          },
                                          model: {
                                            value: item.issueNumber,
                                            callback: function ($$v) {
                                              _vm.$set(item, "issueNumber", $$v)
                                            },
                                            expression: "item.issueNumber",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticStyle: { color: "red" } },
                                      [
                                        _vm._v(
                                          "(最大不超过" +
                                            _vm._s(item.maxGiving) +
                                            ")"
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                        ])
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-check",
                type: "primary",
                plain: "",
                size: "mini",
              },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: {
                click: function ($event) {
                  return _vm.closePage(true)
                },
              },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }