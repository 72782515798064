import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PageContainer from "@/components/PageContainer";
import FormLabel from "@/components/FormSubLabel";
import dict from '@/mixins/dict';
import Pagination from '@/components/Pagination';
import activityRequest from '../../api/insuranceActivities';
import { commonExport } from '@/utils/common';
export default {
  name: "VerificationSpecific",
  components: {
    PageContainer: PageContainer,
    FormLabel: FormLabel,
    Pagination: Pagination
  },
  mixins: [dict],
  computed: {
    tableHeight: function tableHeight() {
      return document.body.clientHeight - 416 + 'px';
    }
  },
  data: function data() {
    return {
      dealerTitle: "".concat(this.$route.query.dealerName, "\u2014").concat(this.$route.query.dealerCode),
      activeOne: '1',
      formLoading: false,
      searchForm: {
        customerName: '',
        vin: ''
      },
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      loading: false,
      multipleSelection: []
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.loadData();
  },
  methods: {
    exportFn: function exportFn() {
      var exporParams = {
        total: this.total,
        fileName: '活动核销详情客户列表',
        exportUrl: "/ps-ftms-service/activitys/".concat(this.$route.query.activityId, "/").concat(this.$route.query.dealerCode, "/customer"),
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    cancel: function cancel() {
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.go(-1);
    },
    resetSearch: function resetSearch() {
      this.searchForm = {
        customerName: '',
        vin: ''
      };
      this.searchData();
    },
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    loadData: function loadData() {
      var _this = this;
      //获取列表数据
      this.loading = true;
      var params = {};
      params.pageNum = this.listQuery.pageNum;
      params.pageSize = this.listQuery.pageSize;
      params.customerName = this.searchForm.customerName;
      params.vin = this.searchForm.vin;
      activityRequest.getDealerActivityCustomers(this.$route.query.activityId, this.$route.query.dealerCode, params).then(function (res) {
        _this.loading = false;
        if (!_.isEmpty(res.data)) {
          _this.tableData = res.data.list;
          _this.total = res.data.page.recordsTotal;
        }
      }).catch(function (err) {
        _this.loading = false;
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      //列表行选择事件
      this.multipleSelection = val;
    }
  }
};