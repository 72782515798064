var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "app-m-cls" },
      [
        _c(
          "div",
          { staticClass: "table-cls normal-btn-cls" },
          [
            _c(
              "el-drawer",
              {
                attrs: {
                  title: "",
                  visible: _vm.drawer,
                  modal: false,
                  "append-to-body": true,
                  size: "370px",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.drawer = $event
                  },
                },
              },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    staticStyle: { "margin-bottom": "15px" },
                    attrs: {
                      model: _vm.searchForm,
                      "label-width": "110px",
                      size: "mini",
                    },
                  },
                  [
                    _c(
                      "el-row",
                      [
                        _vm.bizType === "OEM"
                          ? _c(
                              "el-col",
                              { attrs: { span: 22 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "注销机构" } },
                                  [
                                    _c("OemSelect", {
                                      ref: "oemSelect",
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        searchForm: _vm.orgForm,
                                        clearable: "",
                                        canClickArea: true,
                                        isGetUserList: true,
                                        isContainOem: true,
                                      },
                                      on: {
                                        nodeClick: _vm.handleNodeClick,
                                        getCurrentOrgUsers: _vm.oemGetUsers,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.bizType === "DEALER"
                          ? _c(
                              "el-col",
                              { attrs: { span: 22 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "注销机构" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.searchForm.salesOrgName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "salesOrgName",
                                            $$v
                                          )
                                        },
                                        expression: "searchForm.salesOrgName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "操作人" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      filterable: "",
                                      size: "mini",
                                      clearable: "",
                                      placeholder: "请选择操作人",
                                    },
                                    on: {
                                      clear: function ($event) {
                                        return _vm.clearChange("creatorBy")
                                      },
                                      change: _vm.salemanNameChange,
                                    },
                                    model: {
                                      value: _vm.searchForm.creatorName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "creatorName",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.creatorName",
                                    },
                                  },
                                  _vm._l(_vm.salesman, function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.userName,
                                        value: item.userId,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "车架号" } },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    maxlength: "40",
                                    placeholder: "请输入车架号",
                                    clearable: "",
                                  },
                                  on: {
                                    clear: function ($event) {
                                      return _vm.clearChange("targetVin")
                                    },
                                  },
                                  model: {
                                    value: _vm.searchForm.targetVin,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchForm, "targetVin", $$v)
                                    },
                                    expression: "searchForm.targetVin",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.activeName == "DBC"
                          ? _c(
                              "el-col",
                              { attrs: { span: 22 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "保险公司" } },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          size: "mini",
                                          clearable: "",
                                          placeholder: "请选择供应商",
                                        },
                                        on: {
                                          clear: function ($event) {
                                            return _vm.clearChange(
                                              "supplierCode"
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.searchForm.supplierCode,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.searchForm,
                                              "supplierCode",
                                              $$v
                                            )
                                          },
                                          expression: "searchForm.supplierCode",
                                        },
                                      },
                                      _vm._l(
                                        _vm.suppliersLists,
                                        function (item, index) {
                                          return _c("el-option", {
                                            key: index,
                                            attrs: {
                                              label: item.supplierName,
                                              value: item.supplierCode,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "注销申请号" } },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    maxlength: "40",
                                    placeholder: "请输入注销申请号",
                                    clearable: "",
                                  },
                                  on: {
                                    clear: function ($event) {
                                      return _vm.clearChange("cancelNo")
                                    },
                                  },
                                  model: {
                                    value: _vm.searchForm.cancelNo,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchForm, "cancelNo", $$v)
                                    },
                                    expression: "searchForm.cancelNo",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "合同编号" } },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    maxlength: "40",
                                    placeholder: "请输入合同编号",
                                    clearable: "",
                                  },
                                  on: {
                                    clear: function ($event) {
                                      return _vm.clearChange("orderNo")
                                    },
                                  },
                                  model: {
                                    value: _vm.searchForm.orderNo,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchForm, "orderNo", $$v)
                                    },
                                    expression: "searchForm.orderNo",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "注销状态" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      placeholder: "请选择注销状态",
                                      clearable: "",
                                      filterable: "",
                                    },
                                    on: {
                                      clear: function ($event) {
                                        return _vm.clearChange(
                                          "terminateStatus"
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.searchForm.terminateStatus,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "terminateStatus",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.terminateStatus",
                                    },
                                  },
                                  _vm._l(
                                    _vm.cancelStatusList,
                                    function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.label,
                                          value: item.value,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "承保时间" } },
                              [
                                _c("DatePicker", {
                                  attrs: {
                                    pickerDateTime: _vm.policySaleTime,
                                    startDate: "policyStartSaleTime",
                                    endDate: "policyEndSaleTime",
                                    width: "240px",
                                  },
                                  on: {
                                    pickerDateChange: function ($event) {
                                      return _vm.getPickersDate(
                                        "policySaleTime",
                                        $event
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "申请时间" } },
                              [
                                _c("DatePicker", {
                                  attrs: {
                                    pickerDateTime: _vm.createdDateTime,
                                    startDate: "createStartDate",
                                    endDate: "createEndDate",
                                    width: "240px",
                                  },
                                  on: {
                                    pickerDateChange: function ($event) {
                                      return _vm.getPickersDate(
                                        "createdDateTime",
                                        $event
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "更新时间" } },
                              [
                                _c("DatePicker", {
                                  attrs: {
                                    pickerDateTime: _vm.modifiedDateTime,
                                    startDate: "modifiedStartTime",
                                    endDate: "modifiedEndTime",
                                    width: "100%",
                                  },
                                  on: {
                                    pickerDateChange: function ($event) {
                                      return _vm.getPickersDate(
                                        "modifiedDateTime",
                                        $event
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c("el-col", { attrs: { span: 24 } }, [
                          _c(
                            "div",
                            { staticClass: "searchBtnStyle" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    icon: "el-icon-refresh-left",
                                  },
                                  on: { click: _vm.resetSearch },
                                },
                                [_vm._v("重置")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    type: "primary",
                                    icon: "el-icon-search",
                                  },
                                  on: { click: _vm.searchData },
                                },
                                [_vm._v("查询")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "table-cls normal-btn-cls" },
          [
            _c(
              "el-tabs",
              {
                staticClass: "TopTabList",
                staticStyle: { background: "#fff" },
                on: { "tab-click": _vm.handleTabClick },
                model: {
                  value: _vm.activeName,
                  callback: function ($$v) {
                    _vm.activeName = $$v
                  },
                  expression: "activeName",
                },
              },
              _vm._l(_vm.tabsList, function (item, index) {
                return _c("el-tab-pane", {
                  key: index,
                  attrs: { label: item.className, name: item.classCode },
                })
              }),
              1
            ),
            _c(
              "el-card",
              { staticClass: "box-card", attrs: { shadow: "never" } },
              [
                _c(
                  "div",
                  {
                    staticClass: "search-box",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v("车架号：")]),
                        _c("el-input", {
                          staticStyle: { "max-width": "180px" },
                          attrs: {
                            size: "mini",
                            maxlength: "40",
                            placeholder: "请输入车架号",
                            clearable: "",
                          },
                          on: {
                            clear: function ($event) {
                              return _vm.clearChange("targetVin")
                            },
                          },
                          model: {
                            value: _vm.searchForm.targetVin,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "targetVin", $$v)
                            },
                            expression: "searchForm.targetVin",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v("注销状态：")]),
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: "请选择注销状态",
                              clearable: "",
                              filterable: "",
                            },
                            on: {
                              clear: function ($event) {
                                return _vm.clearChange("terminateStatus")
                              },
                            },
                            model: {
                              value: _vm.searchForm.terminateStatus,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "terminateStatus", $$v)
                              },
                              expression: "searchForm.terminateStatus",
                            },
                          },
                          _vm._l(_vm.cancelStatusList, function (item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: { label: item.label, value: item.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v("申请时间：")]),
                        _c("DatePicker", {
                          attrs: {
                            pickerDateTime: _vm.createdDateTime,
                            startDate: "createStartDate",
                            endDate: "createEndDate",
                            width: "240px",
                          },
                          on: {
                            pickerDateChange: function ($event) {
                              return _vm.getPickersDate(
                                "createdDateTime",
                                $event
                              )
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              icon: "el-icon-refresh-left",
                            },
                            on: { click: _vm.resetSearch },
                          },
                          [_vm._v("重置")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "primary",
                              icon: "el-icon-search",
                            },
                            on: { click: _vm.searchData },
                          },
                          [_vm._v("查询")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "search-btn",
                        staticStyle: { "margin-top": "5px" },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: { click: _vm.refreshClicked },
                          },
                          [_vm._v("刷新")]
                        ),
                        _vm.bizType !== "OEM"
                          ? _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: { click: _vm.add },
                              },
                              [_vm._v("新增注销申请")]
                            )
                          : _vm._e(),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: { click: _vm.exportFn },
                          },
                          [_vm._v("导出")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: {
                              click: function ($event) {
                                _vm.drawer = true
                              },
                            },
                          },
                          [_vm._v("搜索")]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.activeName === "DBC",
                        expression: "activeName === 'DBC'",
                      },
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    ref: "multipleTable",
                    staticStyle: { width: "100%" },
                    attrs: {
                      size: "mini",
                      border: "",
                      data: _vm.tableData,
                      height: _vm.tableHeightS,
                      "header-cell-style": { background: "#F7F7F7" },
                      "highlight-current-row": "",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "cancelNo",
                        label: "注销申请号",
                        "min-width": "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "orderNo",
                        label: "合同编号",
                        "min-width": "180",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "targetVin",
                        label: "车架号",
                        "min-width": "180",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "vhcSeriesName",
                        label: "车系",
                        "min-width": "180",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "productClassName",
                        label: "产品类别",
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "supplierName",
                        label: "保险公司名称",
                        width: "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "auditStatusDisplay",
                        label: "厂家审批状态",
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "cancelStatusDisplay",
                        label: "注销状态",
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "orgName",
                        label: "注销机构",
                        "min-width": "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "creatorName",
                        label: "操作人",
                        "min-width": "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "policySaleTime",
                        label: "承保时间",
                        width: "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "cancelApplyTime",
                        label: "申请时间",
                        width: "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "modifiedTime",
                        label: "更新时间",
                        width: "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "操作",
                        align: _vm.bizType == "OEM" ? "left" : "center",
                        width: _vm.bizType == "OEM" ? 140 : 200,
                        fixed: "right",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.view(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("查看")]
                              ),
                              _vm.bizType == "OEM" &&
                              scope.row.auditStatus == "CAS01"
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "mini",
                                        plain: "",
                                        type: "primary",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.checkClicked(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("审核")]
                                  )
                                : _vm._e(),
                              _vm.bizType == "DEALER" &&
                              scope.row.cancelStatus == "PTS05"
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "mini",
                                        plain: "",
                                        type: "primary",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.EnterNoticeClicked(
                                            scope.row
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("录入红色通知单")]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.activeName != "DBC",
                        expression: "activeName != 'DBC'",
                      },
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    ref: "NoDBCmultipleTable",
                    staticStyle: { width: "100%" },
                    attrs: {
                      size: "mini",
                      border: "",
                      data: _vm.tableData,
                      height: _vm.tableHeightS,
                      "header-cell-style": { background: "#F7F7F7" },
                      "highlight-current-row": "",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "cancelNo",
                        label: "注销申请号",
                        "min-width": "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "orderNo",
                        label: "合同编号",
                        "min-width": "180",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "targetVin",
                        label: "车架号",
                        "min-width": "180",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "vhcSeriesName",
                        label: "车系",
                        "min-width": "180",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "productClassName",
                        label: "产品类别",
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "auditStatusDisplay",
                        label: "厂家审批状态",
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "cancelStatusDisplay",
                        label: "注销状态",
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "orgName",
                        label: "注销机构",
                        "min-width": "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "creatorName",
                        label: "操作人",
                        "min-width": "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "policySaleTime",
                        label: "承保时间",
                        width: "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "cancelApplyTime",
                        label: "申请时间",
                        width: "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "modifiedTime",
                        label: "更新时间",
                        width: "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "操作",
                        align: _vm.bizType == "OEM" ? "left" : "center",
                        width: _vm.bizType == "OEM" ? 140 : 200,
                        fixed: "right",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.view(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("查看")]
                              ),
                              _vm.bizType == "OEM" &&
                              scope.row.auditStatus == "CAS01"
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "mini",
                                        plain: "",
                                        type: "primary",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.checkClicked(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("审核")]
                                  )
                                : _vm._e(),
                              _vm.bizType == "DEALER" &&
                              scope.row.cancelStatus == "PTS05"
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "mini",
                                        plain: "",
                                        type: "primary",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.EnterNoticeClicked(
                                            scope.row
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("录入红色通知单")]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c("pagination", {
                  attrs: {
                    total: _vm.total,
                    page: _vm.listQuery.pageNum,
                    limit: _vm.listQuery.pageSize,
                  },
                  on: {
                    "update:page": function ($event) {
                      return _vm.$set(_vm.listQuery, "pageNum", $event)
                    },
                    "update:limit": function ($event) {
                      return _vm.$set(_vm.listQuery, "pageSize", $event)
                    },
                    pagination: function ($event) {
                      return _vm.loadData()
                    },
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-dialog",
          {
            staticClass: "pageDialog",
            attrs: {
              visible: _vm.showAddDialog,
              "close-on-click-modal": false,
              width: "800px",
              "append-to-body": true,
            },
            on: {
              "update:visible": function ($event) {
                _vm.showAddDialog = $event
              },
            },
          },
          [
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "title" },
                slot: "title",
              },
              [
                _c(
                  "span",
                  {
                    staticStyle: { "font-size": "15px", "font-weight": "700" },
                  },
                  [_vm._v("新增注销申请")]
                ),
              ]
            ),
            _c("ChargebackDialog", {
              attrs: { refresh: _vm.showAddDialog },
              on: { closePage: _vm.loadData },
            }),
          ],
          1
        ),
        _c(
          "el-dialog",
          {
            staticClass: "pageDialog",
            attrs: {
              visible: _vm.showAuditDialog,
              "close-on-click-modal": false,
              width: "800px",
              "append-to-body": true,
            },
            on: {
              "update:visible": function ($event) {
                _vm.showAuditDialog = $event
              },
            },
          },
          [
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "title" },
                slot: "title",
              },
              [
                _c(
                  "span",
                  {
                    staticStyle: { "font-size": "15px", "font-weight": "700" },
                  },
                  [_vm._v("注销审核")]
                ),
              ]
            ),
            _c("AuditDialog", {
              attrs: {
                policyCancelId: _vm.policyCancelId,
                refresh: _vm.showAuditDialog,
              },
              on: { closePage: _vm.loadData },
            }),
          ],
          1
        ),
        _c(
          "el-dialog",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.TzdLoading,
                expression: "TzdLoading",
              },
            ],
            staticClass: "TzdPageDialog",
            attrs: {
              visible: _vm.showTzdDialog,
              "close-on-click-modal": false,
              width: "550px",
              "append-to-body": true,
            },
            on: {
              "update:visible": function ($event) {
                _vm.showTzdDialog = $event
              },
            },
          },
          [
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "title" },
                slot: "title",
              },
              [
                _c(
                  "span",
                  {
                    staticStyle: { "font-size": "15px", "font-weight": "700" },
                  },
                  [_vm._v("请填写红色通知单")]
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "redInvoiceNoBox" },
              [
                _c(
                  "el-form",
                  {
                    ref: "TzdRuleForm",
                    staticClass: "demo-ruleForm",
                    attrs: {
                      model: _vm.TzdRuleForm,
                      rules: _vm.TzdRules,
                      "label-width": "120px",
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "红色通知单号：", required: "" } },
                      [
                        _c("el-input", {
                          attrs: { maxlength: "16", "show-word-limit": "" },
                          model: {
                            value: _vm.TzdRuleForm.redInvoiceNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.TzdRuleForm, "redInvoiceNo", $$v)
                            },
                            expression: "TzdRuleForm.redInvoiceNo",
                          },
                        }),
                        _c(
                          "span",
                          {
                            staticStyle: { "font-size": "12px", color: "red" },
                          },
                          [
                            _vm._v(
                              "重要提示：提交信息后，请尽快将红色通知单邮寄至厂家！"
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "redInvoiceNoBox_bottom" },
              [
                _c("el-button", { on: { click: _vm.cancelTzd } }, [
                  _vm._v("取消"),
                ]),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.SendTzd()
                      },
                    },
                  },
                  [_vm._v("确认")]
                ),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }