import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.function.name.js";
//
//
//
//

import * as echarts from 'echarts';
require('echarts/theme/macarons'); // echarts theme
import resize from './mixins/resize';
var animationDuration = 6000;
export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '240px'
    },
    chartData: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    legendColor: {
      type: String,
      default: '#333'
    }
  },
  data: function data() {
    return {
      chart: null
    };
  },
  watch: {
    chartData: {
      deep: true,
      handler: function handler(val) {
        this.setOptions(val.values, val.name);
      }
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      _this.initChart();
    });
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      this.chart = echarts.init(this.$el, 'macarons');
      this.setOptions(this.chartData.values, this.chartData.name);
    },
    setOptions: function setOptions(item, name) {
      if (!this.chart) return;
      this.chart.setOption({
        title: {
          text: name,
          x: "center",
          textStyle: {
            fontSize: 16
          }
        },
        legend: {
          orient: 'vertical',
          left: 'left',
          textStyle: {
            color: this.legendColor
          },
          formatter: function formatter(params) {
            return params.split('-')[0];
          }
        },
        grid: {
          top: 20,
          left: 20,
          right: 20,
          bottom: 20,
          containLabel: true
        },
        tooltip: {
          trigger: 'item',
          backgroundColor: 'rgb(0,0,0,0.5)',
          // 提示框浮层的背景颜色。
          borderColor: 'rgb(255,255,255,0.3)',
          // 提示框浮层的边框颜色。
          borderWidth: 0,
          // 提示框浮层的边框宽。
          textStyle: {
            // 提示框浮层的文本样式。
            color: '#fff'
          },
          formatter: function formatter(params) {
            var temp = params.name + "</br>" + "数量：" + params.value;
            return temp;
          }
        },
        series: [{
          name: name,
          center: ['60%', '45%'],
          radius: ["50%"],
          type: 'pie',
          data: item,
          animationEasing: 'cubicInOut',
          label: {
            show: false,
            position: 'center'
          }
        }]
      });
    }
  }
};