import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  // mixins: [dict],
  props: {
    refresh: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      form: {},
      tableData: [],
      isInvoiceInfo: true,
      textarea: ""
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    deleteInvoiceInfo: function deleteInvoiceInfo(index) {
      //删除配置字典
      this.tableData.splice(index, 1);
    },
    addInvoiceInfo: function addInvoiceInfo() {
      //添加配置字典
      this.tableData.push({
        val: "",
        val1: "",
        val2: "",
        val3: "",
        val4: "",
        val5: ""
      });
    },
    initData: function initData() {//初始化数据
    },
    save: function save() {
      //保存数据
      this.closePage();
    },
    closePage: function closePage() {
      //关闭弹窗
      this.$emit('closePage');
    }
  }
};