import "core-js/modules/es.array.concat.js";
/**
 * 字典接口
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
import axios from 'axios';
var objApi = {
  /**
   * 详情
   * @param {*} id 查询参数
   */
  getDetail: function getDetail(id) {
    return request.get(getUrl("/dicts/".concat(id), 3));
  },
  /**
   * 新增
   * @param {*} data 内容
   */
  create: function create(data) {
    return request.post(getUrl("/dicts", 3), data);
  },
  /**
   * 修改
   * @param {*} id 查询参数
   * @param {*} data 内容
   */
  update: function update(id, data) {
    return request.put(getUrl("/dicts/".concat(id, "/"), 3), data);
  },
  /**
   * 列表
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   * @param {*} data 查询参数
   */
  getList: function getList(pageNum, pageSize, data) {
    return request.get(getUrl("/dicts?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 3), {
      params: data
    });
  },
  /**
   * 删除
   * @param {*} id
   */
  delete: function _delete(id) {
    return request.delete(getUrl("/dicts/".concat(id), 3));
  },
  /**
   * 字典条目列表
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   * @param {*} data 查询参数
   */
  getDitcItemList: function getDitcItemList(pageNum, pageSize, data) {
    return request.get(getUrl("/dicts/dictItems?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 3), {
      params: data
    });
  },
  /**
   * 字典条目详情
   * @param {*} id 查询参数
   */
  getDitcItemDetail: function getDitcItemDetail(id) {
    return request.get(getUrl("/dicts/dictItems/".concat(id), 3));
  },
  /**
   * 字典条目新增
   * @param {*} data 内容
   */
  ditcItemCreate: function ditcItemCreate(data) {
    return request.post(getUrl("/dicts/dictItems", 3), data);
  },
  /**
   * 字典条目修改
   * @param {*} id 查询参数
   * @param {*} data 内容dictItems
   */
  ditcItemUpdate: function ditcItemUpdate(id, data) {
    return request.put(getUrl("/dicts/dictItems/".concat(id), 3), data);
  },
  /**
   * 字典条目删除
   * @param {*} id 查询参数
   */
  ditcItemDelete: function ditcItemDelete(id) {
    return request.delete(getUrl("/dicts/dictItems/".concat(id), 3));
  },
  /**
   * 根据编码获取字典子项
   * @param {*} id 查询参数
   */
  getDictItemsByCode: function getDictItemsByCode(dictCode) {
    return request.get(getUrl("/dicts/getDictItemsByDictCode?dictCode=".concat(dictCode), 3));
  },
  /**
   * 多个接口
   * @param {*} list 
   */
  requestAll: function requestAll(list) {
    return axios.all(list).then(axios.spread(function () {
      for (var _len = arguments.length, resList = new Array(_len), _key = 0; _key < _len; _key++) {
        resList[_key] = arguments[_key];
      }
      return resList; // 拿到所有数据
    }));
  }
};

export default objApi;