var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "app-m-cls" }, [
        _c(
          "div",
          { staticClass: "table-cls normal-btn-cls" },
          [
            _c(
              "el-drawer",
              {
                attrs: {
                  title: "",
                  visible: _vm.drawer,
                  modal: false,
                  "append-to-body": true,
                  size: "350px",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.drawer = $event
                  },
                },
              },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.searchForm,
                      "label-width": "110px",
                      size: "mini",
                    },
                  },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "险别简称" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "40",
                                    placeholder: "请输入险别简称",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.searchForm.riskkindShortName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "riskkindShortName",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.riskkindShortName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "是否主险" } },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    model: {
                                      value: _vm.searchForm.isMaster,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "isMaster",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.isMaster",
                                    },
                                  },
                                  [
                                    _c("el-radio", { attrs: { label: null } }, [
                                      _vm._v("全部"),
                                    ]),
                                    _c("el-radio", { attrs: { label: true } }, [
                                      _vm._v("是"),
                                    ]),
                                    _c(
                                      "el-radio",
                                      { attrs: { label: false } },
                                      [_vm._v("否")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "是否启用" } },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    model: {
                                      value: _vm.searchForm.enabled,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.searchForm, "enabled", $$v)
                                      },
                                      expression: "searchForm.enabled",
                                    },
                                  },
                                  [
                                    _c("el-radio", { attrs: { label: null } }, [
                                      _vm._v("全部"),
                                    ]),
                                    _c("el-radio", { attrs: { label: true } }, [
                                      _vm._v("是"),
                                    ]),
                                    _c(
                                      "el-radio",
                                      { attrs: { label: false } },
                                      [_vm._v("否")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "是否新能源" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { placeholder: "请选择能源类型" },
                                    model: {
                                      value: _vm.searchForm.energyType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "energyType",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.energyType",
                                    },
                                  },
                                  _vm._l(_vm.newEnergyData, function (item) {
                                    return _c("el-option", {
                                      key: item.itemCode,
                                      attrs: {
                                        label: item.itemName,
                                        value: item.itemCode,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c("el-col", { attrs: { span: 24 } }, [
                          _c(
                            "div",
                            { staticClass: "searchBtnStyle" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    icon: "el-icon-refresh-left",
                                  },
                                  on: { click: _vm.resetSearch },
                                },
                                [_vm._v("重置")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    type: "primary",
                                    icon: "el-icon-search",
                                  },
                                  on: { click: _vm.searchData },
                                },
                                [_vm._v("查询")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "table-cls normal-btn-cls" },
          [
            _c(
              "el-card",
              { staticClass: "box-card", attrs: { shadow: "never" } },
              [
                _c(
                  "div",
                  {
                    staticClass: "search-box",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v("险别简称：")]),
                        _c("el-input", {
                          attrs: {
                            maxlength: "40",
                            placeholder: "请输入险别简称",
                            clearable: "",
                          },
                          model: {
                            value: _vm.searchForm.riskkindShortName,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "riskkindShortName", $$v)
                            },
                            expression: "searchForm.riskkindShortName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v("是否新能源：")]),
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择能源类型" },
                            model: {
                              value: _vm.searchForm.energyType,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "energyType", $$v)
                              },
                              expression: "searchForm.energyType",
                            },
                          },
                          _vm._l(_vm.newEnergyData, function (item) {
                            return _c("el-option", {
                              key: item.itemCode,
                              attrs: {
                                label: item.itemName,
                                value: item.itemCode,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v("是否主险：")]),
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.searchForm.isMaster,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "isMaster", $$v)
                              },
                              expression: "searchForm.isMaster",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: null } }, [
                              _vm._v("全部"),
                            ]),
                            _c("el-radio", { attrs: { label: true } }, [
                              _vm._v("是"),
                            ]),
                            _c("el-radio", { attrs: { label: false } }, [
                              _vm._v("否"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              icon: "el-icon-refresh-left",
                            },
                            on: { click: _vm.resetSearch },
                          },
                          [_vm._v("重置")]
                        ),
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "8px" },
                            attrs: {
                              size: "mini",
                              type: "primary",
                              icon: "el-icon-search",
                            },
                            on: { click: _vm.searchData },
                          },
                          [_vm._v("查询")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "search-btn" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: { click: _vm.breakData },
                          },
                          [_vm._v("刷新")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: { click: _vm.addItem },
                          },
                          [_vm._v("新增")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: {
                              click: function ($event) {
                                _vm.drawer = true
                              },
                            },
                          },
                          [_vm._v("搜索")]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    ref: "multipleTable",
                    staticStyle: { width: "100%" },
                    attrs: {
                      border: "",
                      size: "mini",
                      data: _vm.tableData,
                      height: _vm.tableHeightJD,
                      "header-cell-style": { background: "#F7F7F7" },
                      "highlight-current-row": "",
                    },
                    on: {
                      "row-click": _vm.handleRowChange,
                      "selection-change": _vm.handleSelectionChange,
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "riskkindShortName",
                        label: "险别简称",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "riskkindCode",
                        label: "险别编码",
                        width: "80",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "isMaster",
                        label: "是否主险",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(scope.row.isMaster ? "是" : "否")
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "energyTypeDisplay",
                        label: "是否新能源",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "unitAmountOptions",
                        label: "保额预设值",
                        align: "right",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.splitStr(scope.row.unitAmountOptions)
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "sort",
                        label: "排序",
                        width: "80",
                        sortable: "",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "enabled",
                        label: "是否启用",
                        align: "center",
                        width: "100",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("el-switch", {
                                attrs: { "active-value": true },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.updateItem(scope.row)
                                  },
                                },
                                model: {
                                  value: scope.row.enabled,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "enabled", $$v)
                                  },
                                  expression: "scope.row.enabled",
                                },
                              }),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { label: "操作", align: "center", width: "80px" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.viewDetail("update", scope.row)
                                    },
                                  },
                                },
                                [_vm._v("编辑")]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c("pagination", {
                  attrs: {
                    total: _vm.total,
                    page: _vm.listQuery.pageNum,
                    limit: _vm.listQuery.pageSize,
                  },
                  on: {
                    "update:page": function ($event) {
                      return _vm.$set(_vm.listQuery, "pageNum", $event)
                    },
                    "update:limit": function ($event) {
                      return _vm.$set(_vm.listQuery, "pageSize", $event)
                    },
                    pagination: _vm.loadData,
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "Dialog",
        {
          attrs: {
            title:
              _vm.opType == "view"
                ? "查看险别"
                : _vm.opType == "update"
                ? "编辑险别"
                : "新增险别",
            width: "900px",
            "form-loading": _vm.formLoading,
          },
          on: { handleClose: _vm.cancel },
          model: {
            value: _vm.showDialog,
            callback: function ($$v) {
              _vm.showDialog = $$v
            },
            expression: "showDialog",
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                slot: "content",
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "110px",
              },
              slot: "content",
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "险别", prop: "riskkindCode" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "form-item-cls",
                                  attrs: {
                                    filterable: "",
                                    placeholder: "请选择险别",
                                    clearable: "",
                                  },
                                  on: { change: _vm.inputriskkindCode },
                                  model: {
                                    value: _vm.form.riskkindCode,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "riskkindCode", $$v)
                                    },
                                    expression: "form.riskkindCode",
                                  },
                                },
                                _vm._l(_vm.dutyAllCode, function (dict, ind) {
                                  return _c("el-option", {
                                    key: ind,
                                    attrs: {
                                      label:
                                        dict.dictCode + "-" + dict.dictName,
                                      value: dict.dictCode,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "险别简称",
                                prop: "riskkindShortName",
                              },
                            },
                            [
                              _c("el-input", {
                                staticClass: "form-item-cls",
                                attrs: {
                                  maxlength: "30",
                                  disabled: _vm.opType == "view",
                                  placeholder: "请输入险别简称",
                                },
                                model: {
                                  value: _vm.form.riskkindShortName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "riskkindShortName", $$v)
                                  },
                                  expression: "form.riskkindShortName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "是否主险", prop: "isMaster" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  attrs: { disabled: _vm.opType == "view" },
                                  on: { change: _vm.selectMasterDuty },
                                  model: {
                                    value: _vm.form.isMaster,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "isMaster", $$v)
                                    },
                                    expression: "form.isMaster",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: true } }, [
                                    _vm._v("是"),
                                  ]),
                                  _c("el-radio", { attrs: { label: false } }, [
                                    _vm._v("否"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "是否启用" } },
                            [
                              _c("el-switch", {
                                attrs: { disabled: _vm.opType == "view" },
                                model: {
                                  value: _vm.form.enabled,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "enabled", $$v)
                                  },
                                  expression: "form.enabled",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "是否有保额",
                                prop: "hasInsuranceAmount",
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  attrs: { disabled: _vm.opType == "view" },
                                  on: { change: _vm.changeAmount },
                                  model: {
                                    value: _vm.form.hasInsuranceAmount,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "hasInsuranceAmount",
                                        $$v
                                      )
                                    },
                                    expression: "form.hasInsuranceAmount",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: true } }, [
                                    _vm._v("是"),
                                  ]),
                                  _c("el-radio", { attrs: { label: false } }, [
                                    _vm._v("否"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.form.hasInsuranceAmount
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "保额单位",
                                        prop: "amountUnit",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticClass: "form-item-cls",
                                          attrs: {
                                            placeholder: "请选择保额单位",
                                            clearable: "",
                                          },
                                          model: {
                                            value: _vm.form.amountUnit,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "amountUnit",
                                                $$v
                                              )
                                            },
                                            expression: "form.amountUnit",
                                          },
                                        },
                                        _vm._l(
                                          _vm.dictSource["INSURANCE_LIMIT"],
                                          function (dict, ind) {
                                            return _c("el-option", {
                                              key: ind,
                                              attrs: {
                                                label: dict.dictName,
                                                value: dict.dictCode,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.form.hasInsuranceAmount,
                              expression: "!form.hasInsuranceAmount",
                            },
                          ],
                        },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "保额单位" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "form-item-cls",
                                      attrs: {
                                        disabled: "",
                                        placeholder: "请选择保额单位",
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.form.amountUnit,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "amountUnit", $$v)
                                        },
                                        expression: "form.amountUnit",
                                      },
                                    },
                                    _vm._l(
                                      _vm.dictSource["INSURANCE_LIMIT"],
                                      function (dict, ind) {
                                        return _c("el-option", {
                                          key: ind,
                                          attrs: {
                                            label: dict.dictName,
                                            value: dict.dictCode,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "energyType",
                                label: "是否新能源",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    clearable: "",
                                    placeholder: "请选择能源类型",
                                  },
                                  model: {
                                    value: _vm.form.energyType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "energyType", $$v)
                                    },
                                    expression: "form.energyType",
                                  },
                                },
                                _vm._l(_vm.newEnergyData, function (item) {
                                  return _c("el-option", {
                                    key: item.itemCode,
                                    attrs: {
                                      label: item.itemName,
                                      value: item.itemCode,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "排序" } },
                            [
                              _c("el-input", {
                                staticClass: "form-item-cls",
                                attrs: {
                                  maxlength: "5",
                                  clearable: "",
                                  disabled: _vm.opType == "view",
                                  oninput:
                                    "value=value.replace(/[^0-9]{0,1}(\\d*(?:\\.\\d{0,2})?).*$/g, '$1')",
                                  placeholder: "请输入1-5位排序值",
                                },
                                model: {
                                  value: _vm.form.sort,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "sort", $$v)
                                  },
                                  expression: "form.sort",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "custom-div",
                  staticStyle: { "align-items": "center" },
                },
                [
                  _c("div", { staticClass: "inner-label" }, [
                    _vm._v("依赖主险"),
                  ]),
                  _c(
                    "div",
                    { staticClass: "tags-list-s inner-content" },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "form-item-cls",
                          attrs: {
                            disabled: _vm.form.isMaster,
                            placeholder: "请选择依赖主险",
                            multiple: "",
                            clearable: "",
                          },
                          model: {
                            value: _vm.form.masterCodes,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "masterCodes", $$v)
                            },
                            expression: "form.masterCodes",
                          },
                        },
                        _vm._l(_vm.allParentDuty, function (dict, ind) {
                          return _c("el-option", {
                            key: ind,
                            attrs: { label: dict.label, value: dict.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c("div", { staticClass: "custom-div" }, [
                _c(
                  "div",
                  { staticClass: "inner-label" },
                  [
                    _c("span", [_vm._v("预设值")]),
                    _c(
                      "el-tooltip",
                      { attrs: { placement: "top" } },
                      [
                        _c(
                          "div",
                          { attrs: { slot: "content" }, slot: "content" },
                          [_vm._v("保额预设值"), _c("br"), _vm._v("绝对免赔额")]
                        ),
                        _c("svg-icon", {
                          staticStyle: { "font-size": "14px" },
                          attrs: { "icon-class": "query" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "tags-list inner-content" },
                  [
                    _vm._l(_vm.form.unitAmountOptions, function (tag, index) {
                      return _c(
                        "el-tag",
                        {
                          key: index,
                          attrs: {
                            closable: _vm.opType != "view",
                            "disable-transitions": false,
                            size: "small",
                          },
                          on: {
                            close: function ($event) {
                              return _vm.handleTagClose(tag)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(tag) + " ")]
                      )
                    }),
                    _vm.inputVisible
                      ? _c("el-input", {
                          ref: "saveTagInput",
                          staticClass: "input-new-tag",
                          attrs: {
                            type: "text",
                            maxlength: "10",
                            disabled: _vm.opType == "view",
                            size: "small",
                          },
                          on: {
                            input: function ($event) {
                              _vm.inputValue = _vm.onInput(
                                _vm.inputValue,
                                0,
                                99999999,
                                0
                              )
                            },
                            blur: _vm.handleInputConfirm,
                          },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.handleInputConfirm($event)
                            },
                          },
                          model: {
                            value: _vm.inputValue,
                            callback: function ($$v) {
                              _vm.inputValue = $$v
                            },
                            expression: "inputValue",
                          },
                        })
                      : _c(
                          "el-button",
                          {
                            staticClass: "button-new-tag",
                            attrs: {
                              disabled: _vm.opType == "view",
                              size: "small",
                            },
                            on: { click: _vm.showInput },
                          },
                          [_vm._v("+ 添加")]
                        ),
                  ],
                  2
                ),
              ]),
              _c("div", { staticClass: "custom-div" }, [
                _c("div", { staticClass: "inner-label" }, [_vm._v("险别说明")]),
                _c(
                  "div",
                  { staticClass: "inner-content" },
                  [
                    _c("el-input", {
                      attrs: {
                        maxlength: "240",
                        disabled: _vm.opType == "view",
                        type: "textarea",
                        rows: 3,
                      },
                      model: {
                        value: _vm.form.description,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "description", $$v)
                        },
                        expression: "form.description",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _vm.opType != "view"
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        size: "mini",
                        type: "primary",
                        icon: "el-icon-check",
                        disabled: _vm.isClick,
                      },
                      on: { click: _vm.submit },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.opType == "update" ? "保存" : "保存") +
                          " "
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", icon: "el-icon-close" },
                  on: { click: _vm.cancel },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.opType == "view" ? "关闭" : "取消") + " "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }