var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.listLoading,
          expression: "listLoading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: {},
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "500px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "detail",
                  staticStyle: { "margin-bottom": "-20px" },
                  attrs: {
                    model: _vm.form,
                    "label-width": "110px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "封面" } },
                    [
                      _c(
                        "el-upload",
                        {
                          staticClass: "upload-demo",
                          attrs: { drag: "", action: "", multiple: "" },
                        },
                        [
                          _c("i", { staticClass: "el-icon-upload" }),
                          _c("div", { staticClass: "el-upload__text" }, [
                            _vm._v("将文件拖到此处，或"),
                            _c("em", [_vm._v("点击上传")]),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "类型" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择类型", clearable: "" },
                          model: {
                            value: _vm.value,
                            callback: function ($$v) {
                              _vm.value = $$v
                            },
                            expression: "value",
                          },
                        },
                        [_c("el-option", { attrs: { label: "13", value: 1 } })],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "类别" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "类别", clearable: "" },
                          model: {
                            value: _vm.value,
                            callback: function ($$v) {
                              _vm.value = $$v
                            },
                            expression: "value",
                          },
                        },
                        [_c("el-option", { attrs: { label: "13", value: 1 } })],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "标题" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入标题", clearable: "" },
                        model: {
                          value: _vm.form.ownerName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "ownerName", $$v)
                          },
                          expression: "form.ownerName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "描述" } },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea", rows: 5, resize: "none" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-check",
                type: "primary",
                plain: "",
                size: "mini",
              },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: { click: _vm.closePage },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }