var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-m-cls", staticStyle: { background: "transparent" } },
    [
      _c(
        "div",
        { ref: "header", staticClass: "header-cls" },
        [
          _c(
            "el-card",
            { staticStyle: { height: "100%" }, attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "detail",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-width": "150px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 22 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "审核人员姓名：", prop: "name" },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入审核人员姓名",
                                  maxlength: "15",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "name", $$v)
                                  },
                                  expression: "form.name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 22 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "审核人员手机号：",
                                prop: "mobile",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入审核人员手机号",
                                  maxlength: "11",
                                  oninput: "value=value.replace(/[^\\d]/g,'')",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.mobile,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "mobile", $$v)
                                  },
                                  expression: "form.mobile",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 22 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "审核人员邮箱：", prop: "email" },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入审核人员邮箱",
                                  clearable: "",
                                },
                                on: { blur: _vm.emailRule },
                                model: {
                                  value: _vm.form.email,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "email", $$v)
                                  },
                                  expression: "form.email",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 22 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "产品服务类型：",
                                prop: "productClassifyCode",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: "请选择产品服务类型",
                                    clearable: "",
                                  },
                                  on: { change: _vm.setProductClassifyName },
                                  model: {
                                    value: _vm.form.productClassifyCode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "productClassifyCode",
                                        $$v
                                      )
                                    },
                                    expression: "form.productClassifyCode",
                                  },
                                },
                                _vm._l(
                                  _vm.productClassLists,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.className,
                                        value: item.classCode,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 22 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "保险公司：",
                                prop: "insCorpCode",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: "请选择保险公司",
                                    clearable: "",
                                  },
                                  on: { change: _vm.setInsCorpName },
                                  model: {
                                    value: _vm.form.insCorpCode,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "insCorpCode", $$v)
                                    },
                                    expression: "form.insCorpCode",
                                  },
                                },
                                _vm._l(
                                  _vm.suppliersLists,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.supplierName,
                                        value: item.supplierCode,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-check",
                type: "primary",
                plain: "",
                size: "mini",
              },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: { click: _vm.closePage },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }