import "core-js/modules/es.object.keys.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import dict from "@/mixins/dict";
import { saveFile } from '@/utils/common';
import initHeight from "@/mixins/initHeight";
import reportRequest from '@/api/reportForm';
import storageRequest from '@/api/storage';
import OrgSelect from '@/components/OrgSelect';
import OemSelect from '@/components/OemSelect';
import requestOrg from '@/api/orgChildren';
export default {
  props: {
    refresh: {
      type: Boolean
    },
    bizType: {
      type: String,
      default: ''
    },
    currentType: {
      type: String,
      default: ''
    },
    currentName: {
      type: String,
      default: ''
    }
  },
  mixins: [initHeight, dict],
  components: {
    OrgSelect: OrgSelect,
    OemSelect: OemSelect
  },
  data: function data() {
    return {
      uploadForm: {
        orgCode: '',
        orgName: '',
        statisticsDate: '',
        statisticsType: '',
        storageId: ''
      },
      formRules: {
        orgCode: [{
          required: true,
          message: '请选择组织机构',
          trigger: 'change'
        }],
        statisticsDate: [{
          required: true,
          message: '请选择年月',
          trigger: 'change'
        }]
      },
      fileList: [],
      importLoading: false,
      orgForm: {
        salesOrgCode: '',
        salesOrgName: '',
        salesOrgType: ''
      },
      userInfo: JSON.parse(localStorage.getItem("userInfo")) || {}
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
        this.$refs.detail.resetFields();
      }
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    // 初始化数据
    initData: function initData() {
      this.uploadForm = {
        orgCode: '',
        orgName: '',
        statisticsDate: '',
        statisticsType: this.currentType,
        storageId: ''
      };
      this.orgForm = {
        salesOrgCode: '',
        salesOrgName: '',
        salesOrgType: ''
      };
      this.fileList = [];
      if (this.bizType === 'DEALER') this.endQuery();
    },
    endQuery: function endQuery() {
      var _this = this;
      requestOrg.dealerQuery(this.userInfo.orgCode).then(function (res) {
        if (res.data) {
          _this.uploadForm.orgCode = res.data.dealerCode;
          _this.uploadForm.orgName = res.data.dealerName;
        }
      });
    },
    // 树形点击
    nodeClick: function nodeClick(data) {
      if (this.bizType === "BROKER") {
        this.uploadForm.orgCode = data.salesOrgCode;
        this.uploadForm.orgName = data.salesOrgName;
      }
      if (this.bizType === "OEM") {
        this.uploadForm.orgCode = data.salesOrgCode;
        this.uploadForm.orgName = data.salesOrgName;
      }
    },
    // 上传文件
    uploadFile: function uploadFile(param) {
      var _this2 = this;
      var formData = new FormData();
      formData.append("file", param.file);
      storageRequest.uploadFile(formData).then(function (res) {
        if (res.code === 200) {
          _this2.$message.success(res.msg);
          setTimeout(function () {
            _this2.uploadForm.storageId = res.data.fileStorageId;
            _this2.uploadForm.fileUrl = res.data.fileUrl;
          }, 1000);
        }
      });
    },
    // 附件校验
    validate: function validate() {
      var flag = true;
      if (!this.uploadForm.storageId) {
        this.$message.warning('请上传文件');
        flag = false;
        return flag;
      }
      return flag;
    },
    importFile: function importFile() {
      var _this3 = this;
      this.$refs["detail"].validate(function (valid) {
        if (valid) {
          if (!_this3.validate()) return;
          _this3.importLoading = true;
          reportRequest.importSupervise(_this3.uploadForm).then(function (res) {
            _this3.importLoading = false;
            if (res.code === 200) {
              _this3.$message.success(res.msg);
              _this3.initData();
              _this3.closePage();
            }
          }, function (err) {}).catch(function (err) {
            _this3.importLoading = false;
          });
        }
      });
    },
    // 下载模板
    download: function download() {
      var _this4 = this;
      reportRequest.downloadSupervise(this.currentType).then(function (res) {
        var fileName = "".concat(_this4.currentName, ".xlsx");
        var contentType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        saveFile(res, contentType, fileName);
      });
    },
    // 关闭弹窗
    closePage: function closePage() {
      this.$emit("closePage");
    }
  }
};