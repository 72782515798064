//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import request from '@/api/message';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    currentType: {
      type: String,
      default: ''
    },
    currentId: {
      type: String,
      default: ''
    },
    rangeList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    classifyList: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      formLoading: false,
      form: {},
      rules: {
        messageTemplateCode: [{
          required: true,
          message: '请输入编码',
          trigger: 'blur'
        }],
        messageTemplateTitle: [{
          required: true,
          message: '请输入标题',
          trigger: 'blur'
        }],
        messageTemplateCategory: [{
          required: true,
          message: '请选择分类',
          trigger: 'change'
        }],
        messageTemplateRange: [{
          required: true,
          message: '请选择发送范围',
          trigger: 'change'
        }],
        messageTemplateContent: [{
          required: true,
          message: '请输入内容',
          trigger: 'change'
        }]
      }
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
        this.$refs.detail.resetFields();
      }
      ;
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    // 初始化数据
    initData: function initData() {
      var _this = this;
      this.form = {};
      if (this.currentType === 'edit') {
        request.viewSingleMessage(this.currentId).then(function (res) {
          if (res.code === 200 && res.data) {
            _this.form = res.data;
          }
          ;
        });
      }
      ;
    },
    // 保存数据
    save: function save() {
      var _this2 = this;
      this.$refs.detail.validate(function (valid) {
        if (valid) {
          _this2.formLoading = true;
          var req = _this2.currentType === 'add' ? request.addMessage(_this2.form) : request.editMessage(_this2.form);
          req.then(function (res) {
            _this2.formLoading = false;
            if (res.code === 200) {
              _this2.$message.success(res.msg);
              _this2.closePage();
            }
            ;
          }).catch(function (err) {
            _this2.formLoading = false;
          });
        }
        ;
      });
    },
    //关闭弹窗
    closePage: function closePage() {
      this.$emit('closePage');
    }
  }
};