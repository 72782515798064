import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.function.name.js";
//
//
//
//

import * as echarts from 'echarts';
require('echarts/theme/macarons'); // echarts theme
import resize from './mixins/resize';
export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '300px'
    },
    xAxis: {
      type: Array,
      default: []
    },
    series: {
      type: Object,
      default: {}
    }
  },
  data: function data() {
    return {
      chart: null
    };
  },
  watch: {
    series: {
      deep: true,
      handler: function handler(val) {
        this.setOptions(val);
      }
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      _this.initChart();
    });
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      this.chart = echarts.init(this.$el, 'macarons');
      this.setOptions();
    },
    setOptions: function setOptions() {
      var self = this;
      self.chart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            lineStyle: {
              // 直线指示器样式设置
              color: 'rgb(0,0,0,0.5)',
              width: 1,
              type: 'solid'
            }
          },
          backgroundColor: 'rgb(0,0,0,0.5)',
          // 提示框浮层的背景颜色。
          borderColor: 'rgb(255,255,255,0.3)',
          // 提示框浮层的边框颜色。
          borderWidth: 0,
          // 提示框浮层的边框宽。
          textStyle: {
            // 提示框浮层的文本样式。
            color: '#fff'
          },
          formatter: function formatter(params) {
            if (!_.isEmpty(self.series.values)) {
              return self.series.values[params[0].dataIndex] + '<br/>' + params[0].marker + params[0].seriesName + ': ' + (params[0].value ? params[0].value.toLocaleString() : 0.00);
            }
            return params[0].name + '<br/>' + params[0].marker + params[0].seriesName + ': ' + (params[0].value ? params[0].value.toLocaleString() : 0.00);
          }
        },
        grid: [{
          top: 20,
          right: 40,
          left: 20,
          bottom: 20,
          containLabel: true
        }],
        xAxis: [{
          type: 'category',
          data: this.xAxis,
          splitLine: {
            show: false
          },
          axisLine: {
            lineStyle: {
              color: '#f9f9f9',
              width: 1
            }
          },
          axisLabel: {
            show: true,
            interval: 0,
            rotate: -30,
            textStyle: {
              color: '#f9f9f9' //更改坐标轴文字颜色
            }
          }
        }],

        yAxis: [{
          type: 'value',
          minInterval: 1,
          splitNumber: 5,
          splitArea: {
            show: false,
            areaStyle: {
              color: []
            }
          },
          axisLabel: {
            show: true,
            formatter: function formatter(value) {
              if (value < 10000) {
                //  if(value <= 0){
                return value;
                //  }else{
                //    return value + '次'
                //  }
              } else {
                return "".concat(value / 10000) + '万';
              }
            },
            fontSize: '12',
            textStyle: {
              color: '#f9f9f9'
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ['rgb(255,255,255,0.15)'],
              type: "dashed",
              width: 1
            }
          },
          axisLine: {
            lineStyle: {
              color: '#f0f0f0',
              width: 0
            }
          }
        }],
        series: [{
          name: self.series.name,
          type: 'bar',
          data: self.series.data,
          barWidth: 10,
          //设置柱状图大小
          itemStyle: {
            normal: {
              color: '#3caaff'
            }
          }
        }]
      });
    }
  }
};