var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "listDialog" },
    [
      _c(
        "el-card",
        { staticClass: "box-card", attrs: { shadow: "never" } },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c("div", { staticClass: "search-box" }, [
              _c(
                "div",
                { staticClass: "search-btn" },
                [
                  _c(
                    "el-button",
                    { attrs: { size: "mini" }, on: { click: _vm.breakData } },
                    [_vm._v("刷新")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.addItem("add")
                        },
                      },
                    },
                    [_vm._v("新增")]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "multipleTable",
              staticStyle: { width: "100%", height: "400px", margin: "10px 0" },
              attrs: {
                size: "mini",
                border: "",
                data: _vm.channelConfigData,
                "header-cell-style": { background: "#F7F7F7" },
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "specialCode",
                  label: "特约编码",
                  "min-width": "100",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "specialShortName",
                  label: "特约简称",
                  "min-width": "100",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "riskTypeDisplay",
                  label: "险种类别",
                  "min-width": "100",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "specialName",
                  label: "特约内容",
                  "min-width": "200",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "specialIndex",
                  label: "特约排序号",
                  "min-width": "100",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center", width: "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "danger", plain: "", size: "mini" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.removeItems(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: { click: _vm.closelog },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showAddDialog,
            "close-on-click-modal": false,
            width: "1000px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showAddDialog = $event
            },
            handleClose: function ($event) {
              _vm.showAddDialog = false
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("新增特约")]
              ),
            ]
          ),
          _c("AddSpecialDialog", {
            attrs: {
              ChildInsCorpCode: _vm.ChildInsCorpCode,
              ChildChannelConfigId: _vm.channelConfigId,
              specialConfig: _vm.specialConfig,
              "dialog-type": _vm.DialogType,
              refresh: _vm.showAddDialog,
            },
            on: { closePage: _vm.loadData },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }