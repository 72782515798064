/**
 * 用户中心
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  // ******************************用户管理*******************************************
  /**
   * 分页查询用户信息
   * @param {*} data
   */
  getNetUserList: function getNetUserList(data) {
    return request.get(getUrl("/netUser/search", 5), {
      params: data
    });
  },
  /**
   * 根据主键查询用户
   * @param {*} netUserId
   */
  getNetUserInfo: function getNetUserInfo(netUserId) {
    return request.get(getUrl("/netUser/get?netUserId=".concat(netUserId), 5));
  },
  /**
   * 更新用户
   * @param {*} netChannelId
   * @param {*} data
   */
  updateNetUser: function updateNetUser(netUserId, data) {
    var headers = {
      showLoading: true
    };
    return request.put(getUrl("/netUser/update?netUserId=".concat(netUserId), 5), data, {
      headers: headers
    });
  },
  /**
   * 删除用户
   * @param {*} netUserId
   */
  deleteNetUser: function deleteNetUser(netUserId) {
    return request.delete(getUrl("/netUser/delete?netUserId=".concat(netUserId), 5));
  },
  /**
   * 获取渠道账户
   * @param {*} data
   */
  getNetUserChannelAccountList: function getNetUserChannelAccountList(netUserId) {
    return request.get(getUrl("/netUser/channelAccount/getList", 5), {
      params: {
        netUserId: netUserId
      }
    });
  },
  /**
   * 打客户类型标签
   * @param {*} data
   */
  updateNetUserLabel: function updateNetUserLabel(data) {
    return request.post(getUrl("/netUser/label", 5), data);
  },
  /**
   * 打客户类型标签
   * @param {*} netUserId
   */
  getPolymerizeList: function getPolymerizeList(netUserId) {
    return request.get(getUrl("/netUser/bizData/getPolymerizeList?netUserId=".concat(netUserId), 5));
  },
  // ******************************车辆管理*******************************************
  /**
   * 列表
   * @param {*} data
   */
  getList: function getList(data) {
    return request.get(getUrl("/netUser/pc/vehicle/search", 10), {
      params: data
    });
  },
  /**
   * 列表
   * @param {*} id
   */
  getVehicleById: function getVehicleById(id) {
    return request.get(getUrl("/netUser/pc/vehicle/get?id=".concat(id), 10));
  },
  // ******************************用户类型*******************************************
  /**
   * 分页查询客户类型
   * @param {*} data
   */
  getNetUserTypeList: function getNetUserTypeList(data) {
    return request.get(getUrl("/netUserType/search", 5), {
      params: data
    });
  },
  /**
   * 新增用户类型
   * @param {*} data
   */
  createNetUserType: function createNetUserType(data) {
    var headers = {
      showLoading: true
    };
    return request.post(getUrl("/netUserType/create", 5), data, {
      headers: headers
    });
  },
  /**
   * 根据id获取客户类型
   * @param {*} netUserTypeId
   */
  getNetUserTypeInfo: function getNetUserTypeInfo(netUserTypeId) {
    return request.get(getUrl("/netUserType/get?netUserTypeId=".concat(netUserTypeId), 5));
  },
  /**
   * 更新客户类型
   * @param {*} netUserTypeId
   * @param {*} data
   */
  updateNetUserType: function updateNetUserType(netUserTypeId, data) {
    var headers = {
      showLoading: true
    };
    return request.post(getUrl("/netUserType/update?netUserTypeId=".concat(netUserTypeId), 5), data, {
      headers: headers
    });
  },
  /**
   * 批量删除
   * @param {*} netUserTypeIds
   */
  deleteNetUserType: function deleteNetUserType(netUserTypeIds) {
    return request.post(getUrl("/netUserType/batchDelete?netUserTypeIds=".concat(netUserTypeIds), 5));
  },
  /**
   * 获取所有启用的客户类型
   * @param {*} data
   */
  getAllNetUserTypeList: function getAllNetUserTypeList(data) {
    return request.get(getUrl("/netUserType/all", 5), {
      params: data
    });
  },
  /**
   * 获取所有启用的客户类型
   * @param {*} netUserTypeId
   */
  changeNetUserTypeEnabled: function changeNetUserTypeEnabled(netUserTypeId) {
    return request.post(getUrl("/netUserType/enabled?netUserTypeId=".concat(netUserTypeId), 5));
  },
  // ******************************渠道配置*******************************************
  /**
   * 分页查询渠道信息
   * @param {*} data
   */
  getNetChannelList: function getNetChannelList(data) {
    return request.get(getUrl("/netChannel/search", 5), {
      params: data
    });
  },
  /**
   * 维护渠道
   * @param {*} data
   */
  createNetChannel: function createNetChannel(data) {
    var headers = {
      showLoading: true
    };
    return request.post(getUrl("/netChannel/create", 5), data, {
      headers: headers
    });
  },
  /**
   * 根据主键查询渠道
   * @param {*} netChannelId
   */
  getNetChannelInfo: function getNetChannelInfo(netChannelId) {
    return request.get(getUrl("/netChannel/get?netChannelId=".concat(netChannelId), 5));
  },
  /**
   * 更新渠道
   * @param {*} netChannelId
   * @param {*} data
   */
  updateNetChannel: function updateNetChannel(netChannelId, data) {
    var headers = {
      showLoading: true
    };
    return request.put(getUrl("/netChannel/update?netChannelId=".concat(netChannelId), 5), data, {
      headers: headers
    });
  },
  /**
   * 批量删除渠道
   * @param {*} netChannelId
   */
  deleteNetChannel: function deleteNetChannel(netChannelId) {
    return request.delete(getUrl("/netChannel/delete?netChannelId=".concat(netChannelId), 5));
  }
};
export default objApi;