var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "PageContainer",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
    },
    [
      _c("div", { attrs: { slot: "main" }, slot: "main" }, [
        _c(
          "div",
          {
            staticClass: "app-m-cls",
            staticStyle: { "min-height": "calc(100vh - 130px)" },
          },
          [
            _c(
              "div",
              { ref: "header", staticClass: "header-cls" },
              [
                _c(
                  "el-collapse",
                  {
                    staticStyle: { "margin-top": "10px" },
                    model: {
                      value: _vm.activeOrder,
                      callback: function ($$v) {
                        _vm.activeOrder = $$v
                      },
                      expression: "activeOrder",
                    },
                  },
                  [
                    _c(
                      "el-collapse-item",
                      {
                        staticClass: "collapse-item-style",
                        attrs: { name: "1" },
                      },
                      [
                        _c("template", { slot: "title" }, [
                          _c(
                            "div",
                            {
                              staticClass: "title",
                              staticStyle: { "line-height": "25px" },
                            },
                            [
                              _c("FormLabel", {
                                attrs: { "form-label": "订单信息" },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              {
                                attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 },
                              },
                              [
                                _c("div", { staticClass: "card_item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "card_item_label" },
                                    [_vm._v("订单编号：")]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.NonAutoPolicyDetail.orderNo || "-"
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                            _c(
                              "el-col",
                              {
                                attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 },
                              },
                              [
                                _c("div", { staticClass: "card_item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "card_item_label" },
                                    [_vm._v("保单号：")]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.NonAutoPolicyDetail.policyNo || "-"
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                            _c(
                              "el-col",
                              {
                                attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 },
                              },
                              [
                                _c("div", { staticClass: "card_item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "card_item_label" },
                                    [_vm._v("投保单号：")]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.NonAutoPolicyDetail.applyPolicyNo ||
                                          "-"
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                            _c(
                              "el-col",
                              {
                                attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 },
                              },
                              [
                                _c("div", { staticClass: "card_item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "card_item_label" },
                                    [_vm._v("出单机构：")]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.NonAutoPolicyDetail.salesOrgName ||
                                          "-"
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                            _c(
                              "el-col",
                              {
                                attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 },
                              },
                              [
                                _c("div", { staticClass: "card_item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "card_item_label" },
                                    [_vm._v("业务员：")]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.NonAutoPolicyDetail.salemanName ||
                                          "-"
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                            _c(
                              "el-col",
                              {
                                attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 },
                              },
                              [
                                _c("div", { staticClass: "card_item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "card_item_label" },
                                    [_vm._v("操作员：")]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.NonAutoPolicyDetail.creatorName ||
                                          "-"
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                            _c(
                              "el-col",
                              {
                                attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 },
                              },
                              [
                                _c("div", { staticClass: "card_item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "card_item_label" },
                                    [_vm._v("服务销售日期：")]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.NonAutoPolicyDetail
                                          .orderSalesTime || "-"
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                            _c(
                              "el-col",
                              {
                                attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 },
                              },
                              [
                                _c("div", { staticClass: "card_item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "card_item_label" },
                                    [_vm._v("承保时间：")]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.NonAutoPolicyDetail.insuredTime ||
                                          "-"
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _c(
                  "el-collapse",
                  {
                    staticStyle: { "margin-top": "10px" },
                    model: {
                      value: _vm.activeVehicle,
                      callback: function ($$v) {
                        _vm.activeVehicle = $$v
                      },
                      expression: "activeVehicle",
                    },
                  },
                  [
                    _c(
                      "el-collapse-item",
                      {
                        staticClass: "collapse-item-style",
                        attrs: { name: "1" },
                      },
                      [
                        _c("template", { slot: "title" }, [
                          _c(
                            "div",
                            {
                              staticClass: "title",
                              staticStyle: { "line-height": "25px" },
                            },
                            [
                              _c("FormLabel", {
                                attrs: { "form-label": "车辆信息" },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              {
                                attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 },
                              },
                              [
                                _c("div", { staticClass: "card_item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "card_item_label" },
                                    [_vm._v("车牌号：")]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.NonAutoPolicyDetail.targetPlateNo ||
                                          "新车"
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                            _c(
                              "el-col",
                              {
                                attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 },
                              },
                              [
                                _c("div", { staticClass: "card_item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "card_item_label" },
                                    [_vm._v("车架号：")]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.NonAutoPolicyDetail.targetVin || "-"
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                            _c(
                              "el-col",
                              {
                                attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 },
                              },
                              [
                                _c("div", { staticClass: "card_item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "card_item_label" },
                                    [_vm._v("厂家品牌/车系：")]
                                  ),
                                  _c("div", [
                                    _vm.NonAutoPolicyDetail.productClassCode ==
                                    "BZHG"
                                      ? _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.NonAutoPolicyDetail
                                                  .targetVhcBrandName || "-"
                                              ) +
                                              "/" +
                                              _vm._s(
                                                _vm.NonAutoPolicyDetail
                                                  .targetVhcSeriesName || "-"
                                              ) +
                                              "/" +
                                              _vm._s(
                                                _vm.NonAutoPolicyDetail
                                                  .targetVhcModelName || "-"
                                              ) +
                                              " "
                                          ),
                                        ])
                                      : _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.NonAutoPolicyDetail
                                                  .targetVhcBrandName || "-"
                                              ) +
                                              "/" +
                                              _vm._s(
                                                _vm.NonAutoPolicyDetail
                                                  .targetVhcSeriesName
                                              ) +
                                              " "
                                          ),
                                          _vm.NonAutoPolicyDetail
                                            .targetVhcSeriesChineseName
                                            ? _c("span", [
                                                _vm._v(
                                                  "(" +
                                                    _vm._s(
                                                      _vm.NonAutoPolicyDetail
                                                        .targetVhcSeriesChineseName ||
                                                        "-"
                                                    ) +
                                                    ")"
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]),
                                  ]),
                                ]),
                              ]
                            ),
                            _vm._l(_vm.attributeList, function (item) {
                              return _c(
                                "el-col",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.NonAutoPolicyDetail
                                          .productClassCode != "DBC" &&
                                        item.attributeName == "购车发票日期",
                                      expression:
                                        "NonAutoPolicyDetail.productClassCode != 'DBC' && item.attributeName == '购车发票日期'",
                                    },
                                  ],
                                  key: item.attributeName,
                                  attrs: {
                                    xs: 12,
                                    sm: 12,
                                    md: 8,
                                    lg: 8,
                                    xl: 6,
                                  },
                                },
                                [
                                  _c("div", { staticClass: "card_item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "card_item_label" },
                                      [
                                        _vm._v(
                                          _vm._s(item.attributeName + "：")
                                        ),
                                      ]
                                    ),
                                    _c("div", [
                                      item.attributeName == "使用性质"
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.NonAutoPolicyDetail
                                                  .targetUseAttributeDisplay ||
                                                  "-"
                                              )
                                            ),
                                          ])
                                        : item.attributeName == "能源类型"
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.NonAutoPolicyDetail
                                                  .targetFuelTypeDisplay || "-"
                                              )
                                            ),
                                          ])
                                        : item.attributeName == "车辆类型"
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.NonAutoPolicyDetail
                                                  .targetVehicleTypeDisplay ||
                                                  "-"
                                              )
                                            ),
                                          ])
                                        : item.attributeName == "购车发票日期"
                                        ? _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  (item.attributeValue &&
                                                    item.attributeValue.split(
                                                      " "
                                                    )[0]) ||
                                                    "-"
                                                )
                                            ),
                                          ])
                                        : item.attributeName == "新车销售日期"
                                        ? _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  (item.attributeValue &&
                                                    item.attributeValue.split(
                                                      " "
                                                    )[0]) ||
                                                    "-"
                                                )
                                            ),
                                          ])
                                        : _c("span", [
                                            _vm._v(
                                              _vm._s(item.attributeValue || "-")
                                            ),
                                          ]),
                                    ]),
                                  ]),
                                ]
                              )
                            }),
                            _vm.NonAutoPolicyDetail.productClassCode == "TCWY"
                              ? _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 12,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 6,
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "card_item" }, [
                                      _c(
                                        "div",
                                        { staticClass: "card_item_label" },
                                        [_vm._v("使用性质：")]
                                      ),
                                      _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.NonAutoPolicyDetail
                                                .targetUseAttributeDisplay ||
                                                "-"
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          2
                        ),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _c(
                  "el-collapse",
                  {
                    staticStyle: { "margin-top": "10px" },
                    model: {
                      value: _vm.activeCustomer,
                      callback: function ($$v) {
                        _vm.activeCustomer = $$v
                      },
                      expression: "activeCustomer",
                    },
                  },
                  [
                    _c(
                      "el-collapse-item",
                      {
                        staticClass: "collapse-item-style",
                        attrs: { name: "1" },
                      },
                      [
                        _c("template", { slot: "title" }, [
                          _c(
                            "div",
                            {
                              staticClass: "title",
                              staticStyle: { "line-height": "25px" },
                            },
                            [
                              _c("FormLabel", {
                                attrs: { "form-label": "客户信息" },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              {
                                attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 },
                              },
                              [
                                _c("div", { staticClass: "card_item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "card_item_label" },
                                    [_vm._v("客户名称：")]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.NonAutoPolicyDetail
                                          .targetOwnerName || "-"
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                            _c(
                              "el-col",
                              {
                                attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 },
                              },
                              [
                                _c("div", { staticClass: "card_item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "card_item_label" },
                                    [_vm._v("联系电话：")]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.NonAutoPolicyDetail
                                          .targetOwnerMobile || "-"
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                            _c(
                              "el-col",
                              {
                                attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 },
                              },
                              [
                                _c("div", { staticClass: "card_item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "card_item_label" },
                                    [_vm._v("证件类型：")]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.NonAutoPolicyDetail
                                          .targetOwnerCertificateTypeDisplay ||
                                          "-"
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                            _c(
                              "el-col",
                              {
                                attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 },
                              },
                              [
                                _c("div", { staticClass: "card_item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "card_item_label" },
                                    [_vm._v("证件号码：")]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.NonAutoPolicyDetail
                                          .targetOwnerCertificateNo || "-"
                                      )
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                            _c(
                              "el-col",
                              {
                                attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 },
                              },
                              [
                                _c("div", { staticClass: "card_item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "card_item_label" },
                                    [_vm._v("所在省市：")]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.NonAutoPolicyDetail
                                            .targetOwnerProviceName || "-"
                                        ) +
                                        "/" +
                                        _vm._s(
                                          _vm.NonAutoPolicyDetail
                                            .targetOwnerCityName || "-"
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                            _c(
                              "el-col",
                              {
                                attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 },
                              },
                              [
                                _c("div", { staticClass: "card_item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "card_item_label" },
                                    [_vm._v("详细地址：")]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.NonAutoPolicyDetail
                                            .targetOwnerAddress || "-"
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                            _c(
                              "el-col",
                              {
                                attrs: { xs: 12, sm: 12, md: 8, lg: 8, xl: 6 },
                              },
                              [
                                _c("div", { staticClass: "card_item" }, [
                                  _c(
                                    "div",
                                    { staticClass: "card_item_label" },
                                    [_vm._v("电子邮箱：")]
                                  ),
                                  _c("div", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.NonAutoPolicyDetail
                                            .targetOwnerEmail || "-"
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _c(
                  "el-collapse",
                  {
                    staticStyle: { "margin-top": "10px" },
                    model: {
                      value: _vm.activeService,
                      callback: function ($$v) {
                        _vm.activeService = $$v
                      },
                      expression: "activeService",
                    },
                  },
                  [
                    _c(
                      "el-collapse-item",
                      {
                        staticClass: "collapse-item-style",
                        attrs: { name: "1" },
                      },
                      [
                        _c("template", { slot: "title" }, [
                          _c(
                            "div",
                            {
                              staticClass: "title",
                              staticStyle: { "line-height": "25px" },
                            },
                            [
                              _c("FormLabel", {
                                attrs: { "form-label": "服务信息" },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm.NonAutoPolicyDetail.productClassCode != "SB"
                          ? _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 12,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 6,
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "card_item" }, [
                                      _c(
                                        "div",
                                        { staticClass: "card_item_label" },
                                        [_vm._v("服务产品编码：")]
                                      ),
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            (_vm.NonAutoPolicyDetail
                                              .policyProduct &&
                                              _vm.NonAutoPolicyDetail
                                                .policyProduct.productCode) ||
                                              "-"
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 12,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 6,
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "card_item" }, [
                                      _c(
                                        "div",
                                        { staticClass: "card_item_label" },
                                        [_vm._v("服务产品名称：")]
                                      ),
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            (_vm.NonAutoPolicyDetail
                                              .policyProduct &&
                                              _vm.NonAutoPolicyDetail
                                                .policyProduct.productName) ||
                                              "-"
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                                _vm.NonAutoPolicyDetail.productClassCode ==
                                "DBC"
                                  ? _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 12,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 6,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "card_item" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "card_item_label",
                                              },
                                              [_vm._v("累计最多赔偿天数(天)：")]
                                            ),
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.NonAutoPolicyDetail
                                                    .targetCumulateMaxCompensationDay
                                                    ? _vm.NonAutoPolicyDetail
                                                        .targetCumulateMaxCompensationDay
                                                    : "-"
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.NonAutoPolicyDetail.productClassCode ==
                                "DBC"
                                  ? _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 12,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 6,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "card_item" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "card_item_label",
                                              },
                                              [
                                                _vm._v(
                                                  "每次事故最多赔偿天数(天)："
                                                ),
                                              ]
                                            ),
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.NonAutoPolicyDetail
                                                    .targetAccidentMaxCompensationDay
                                                    ? _vm.NonAutoPolicyDetail
                                                        .targetAccidentMaxCompensationDay
                                                    : "-"
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.NonAutoPolicyDetail.productClassCode ==
                                "DBC"
                                  ? _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 12,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 6,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "card_item" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "card_item_label",
                                              },
                                              [_vm._v("最大赔付金额(元)：")]
                                            ),
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.NonAutoPolicyDetail
                                                    .targetMaxCompensationAmount
                                                    ? _vm.NonAutoPolicyDetail.targetMaxCompensationAmount.toFixed(
                                                        2
                                                      )
                                                    : "-"
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 12,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 6,
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "card_item" }, [
                                      _c(
                                        "div",
                                        { staticClass: "card_item_label" },
                                        [_vm._v("服务保障期限(月)：")]
                                      ),
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.NonAutoPolicyDetail
                                              .productTimeLimit || "-"
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 12,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 6,
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "card_item" }, [
                                      _c(
                                        "div",
                                        { staticClass: "card_item_label" },
                                        [_vm._v("服务生效起期：")]
                                      ),
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.NonAutoPolicyDetail.startTime
                                              ? _vm.NonAutoPolicyDetail.startTime.slice(
                                                  0,
                                                  10
                                                )
                                              : "-"
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 12,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 6,
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "card_item" }, [
                                      _c(
                                        "div",
                                        { staticClass: "card_item_label" },
                                        [_vm._v("服务生效止期：")]
                                      ),
                                      _vm.NonAutoPolicyDetail &&
                                      _vm.NonAutoPolicyDetail
                                        .productClassCode == "YB"
                                        ? _c("div", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.NonAutoPolicyDetail
                                                  .targetGuaranteeRange ==
                                                  "双擎动力蓄电池"
                                                  ? "终身"
                                                  : _vm.NonAutoPolicyDetail
                                                      .endTime
                                                  ? _vm.NonAutoPolicyDetail.endTime.slice(
                                                      0,
                                                      10
                                                    )
                                                  : "-"
                                              )
                                            ),
                                          ])
                                        : _c("div", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.NonAutoPolicyDetail.endTime
                                                  ? _vm.NonAutoPolicyDetail.endTime.slice(
                                                      0,
                                                      10
                                                    )
                                                  : "-"
                                              )
                                            ),
                                          ]),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 12,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 6,
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "card_item" }, [
                                      _c(
                                        "div",
                                        { staticClass: "card_item_label" },
                                        [_vm._v("销售方式：")]
                                      ),
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.NonAutoPolicyDetail
                                              .salesMethodDisplay || "-"
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _c(
                              "div",
                              [
                                _c(
                                  "el-row",
                                  {
                                    staticClass: "flexWarp",
                                    attrs: { gutter: 0 },
                                  },
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 12,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 6,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "card_item" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "card_item_label",
                                              },
                                              [_vm._v("服务产品编码：")]
                                            ),
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.NonAutoPolicyDetail
                                                    .productCode || "-"
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 12,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 6,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "card_item" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "card_item_label",
                                              },
                                              [_vm._v("服务产品名称：")]
                                            ),
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.NonAutoPolicyDetail
                                                    .productName || "-"
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 12,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 6,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "card_item" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "card_item_label",
                                              },
                                              [_vm._v("销售方式：")]
                                            ),
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.NonAutoPolicyDetail
                                                    .salesMethodDisplay
                                                    ? _vm.NonAutoPolicyDetail
                                                        .salesMethodDisplay
                                                    : "-"
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  [
                                    _c("el-col", { attrs: { span: 24 } }, [
                                      _c("div", { staticClass: "card_item" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "card_item_label",
                                            staticStyle: {
                                              color: "#333",
                                              "font-size": "14px",
                                              "text-align": "right",
                                              "font-weight": "600",
                                              "max-width": "100px",
                                            },
                                          },
                                          [_vm._v("三保方案")]
                                        ),
                                      ]),
                                    ]),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 12,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 6,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "card_item" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "card_item_label",
                                              },
                                              [_vm._v("原厂质保公里数(万)：")]
                                            ),
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.NonAutoPolicyDetail
                                                    .targetOriginalWarrantyMileage
                                                    ? _vm.NonAutoPolicyDetail
                                                        .targetOriginalWarrantyMileage
                                                    : "-"
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 12,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 6,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "card_item" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "card_item_label",
                                              },
                                              [_vm._v("原厂质保期限(年)：")]
                                            ),
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.NonAutoPolicyDetail
                                                    .originalWarrantyPeriod
                                                    ? _vm.NonAutoPolicyDetail
                                                        .originalWarrantyPeriod
                                                    : "-"
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 12,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 6,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "card_item" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "card_item_label",
                                              },
                                              [_vm._v("原厂保修起期：")]
                                            ),
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.NonAutoPolicyDetail
                                                    .originalStartTime
                                                    ? _vm.NonAutoPolicyDetail.originalStartTime.slice(
                                                        0,
                                                        10
                                                      ) + " 0时"
                                                    : "-"
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 12,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 6,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "card_item" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "card_item_label",
                                              },
                                              [_vm._v("原厂保修止期：")]
                                            ),
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.NonAutoPolicyDetail
                                                    .originalEndTime
                                                    ? _vm.NonAutoPolicyDetail.originalEndTime.slice(
                                                        0,
                                                        10
                                                      ) + " 24时"
                                                    : "-"
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  [
                                    _c("el-col", { attrs: { span: 24 } }, [
                                      _c("div", { staticClass: "card_item" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "card_item_label",
                                            staticStyle: {
                                              color: "#333",
                                              "font-size": "14px",
                                              "text-align": "right",
                                              "font-weight": "600",
                                              "max-width": "100px",
                                            },
                                          },
                                          [_vm._v("延保方案")]
                                        ),
                                      ]),
                                    ]),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 12,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 6,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "card_item" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "card_item_label",
                                              },
                                              [_vm._v("延保保障范围：")]
                                            ),
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.NonAutoPolicyDetail
                                                    .targetGuaranteeRange
                                                    ? _vm.NonAutoPolicyDetail
                                                        .targetGuaranteeRange
                                                    : "-"
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 12,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 6,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "card_item" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "card_item_label",
                                              },
                                              [_vm._v("延保方案：")]
                                            ),
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.NonAutoPolicyDetail
                                                    .ternScheme
                                                    ? _vm.NonAutoPolicyDetail
                                                        .ternScheme
                                                    : "-"
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 12,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 6,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "card_item" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "card_item_label",
                                              },
                                              [_vm._v("延保起期：")]
                                            ),
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.NonAutoPolicyDetail
                                                    .startTime
                                                    ? _vm.NonAutoPolicyDetail.startTime.slice(
                                                        0,
                                                        10
                                                      ) + " 0时"
                                                    : "-"
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 12,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 6,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "card_item" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "card_item_label",
                                              },
                                              [_vm._v("延保止期：")]
                                            ),
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.NonAutoPolicyDetail
                                                    .endTime
                                                    ? _vm.NonAutoPolicyDetail
                                                        .extensionScheme ==
                                                      "终身"
                                                      ? "终身"
                                                      : _vm.NonAutoPolicyDetail.endTime.slice(
                                                          0,
                                                          10
                                                        ) + " 24时"
                                                    : "-"
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 12,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 6,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "card_item" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "card_item_label",
                                              },
                                              [_vm._v("已行驶里程：")]
                                            ),
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.NonAutoPolicyDetail
                                                    .targetMileage
                                                    ? _vm.NonAutoPolicyDetail
                                                        .targetMileage
                                                    : "-"
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 12,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 6,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "card_item" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "card_item_label",
                                              },
                                              [_vm._v("延长公里数(万)：")]
                                            ),
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.NonAutoPolicyDetail
                                                    .targetExtendMileage
                                                    ? _vm.NonAutoPolicyDetail
                                                        .targetExtendMileage
                                                    : "-"
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  [
                                    _c("el-col", { attrs: { span: 24 } }, [
                                      _c("div", { staticClass: "card_item" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "card_item_label",
                                            staticStyle: {
                                              color: "#333",
                                              "font-size": "14px",
                                              "text-align": "right",
                                              "font-weight": "600",
                                              "max-width": "100px",
                                            },
                                          },
                                          [_vm._v("代步车方案")]
                                        ),
                                      ]),
                                    ]),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 12,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 6,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "card_item" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "card_item_label",
                                              },
                                              [_vm._v("代步车年限(年)：")]
                                            ),
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.NonAutoPolicyDetail
                                                    .targetScooterYearLimit ||
                                                    _vm.NonAutoPolicyDetail
                                                      .targetScooterYearLimit ===
                                                      0
                                                    ? _vm.NonAutoPolicyDetail
                                                        .targetScooterYearLimit
                                                    : "-"
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 12,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 6,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "card_item" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "card_item_label",
                                              },
                                              [_vm._v("代步车服务起期：")]
                                            ),
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.NonAutoPolicyDetail
                                                    .dbcStartTime
                                                    ? _vm.NonAutoPolicyDetail.dbcStartTime.slice(
                                                        0,
                                                        10
                                                      ) + " 0时"
                                                    : "-"
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 12,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 6,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "card_item" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "card_item_label",
                                              },
                                              [_vm._v("代步车服务止期：")]
                                            ),
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.NonAutoPolicyDetail
                                                    .dbcEndTime
                                                    ? _vm.NonAutoPolicyDetail.dbcEndTime.slice(
                                                        0,
                                                        10
                                                      ) + " 24时"
                                                    : "-"
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  [
                                    _c("el-col", { attrs: { span: 24 } }, [
                                      _c("div", { staticClass: "card_item" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "card_item_label",
                                            staticStyle: {
                                              color: "#333",
                                              "font-size": "14px",
                                              "text-align": "right",
                                              "font-weight": "600",
                                              "max-width": "100px",
                                            },
                                          },
                                          [_vm._v("车险置换方案")]
                                        ),
                                      ]),
                                    ]),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 12,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 6,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "card_item" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "card_item_label",
                                              },
                                              [_vm._v("补偿限额(万)：")]
                                            ),
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.NonAutoPolicyDetail
                                                    .limitCompensation
                                                    ? _vm.NonAutoPolicyDetail
                                                        .limitCompensation
                                                    : "-"
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 12,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 6,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "card_item" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "card_item_label",
                                              },
                                              [_vm._v("置换年限(年)：")]
                                            ),
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.NonAutoPolicyDetail
                                                    .targetScooterYearLimit ||
                                                    _vm.NonAutoPolicyDetail
                                                      .targetScooterYearLimit ===
                                                      0
                                                    ? _vm.NonAutoPolicyDetail
                                                        .targetScooterYearLimit
                                                    : "-"
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 12,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 6,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "card_item" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "card_item_label",
                                              },
                                              [_vm._v("置换服务起期：")]
                                            ),
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.NonAutoPolicyDetail
                                                    .zhStartTime
                                                    ? _vm.NonAutoPolicyDetail.zhStartTime.slice(
                                                        0,
                                                        10
                                                      ) + " 0时"
                                                    : "-"
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 12,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 6,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "card_item" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "card_item_label",
                                              },
                                              [_vm._v("置换服务止期：")]
                                            ),
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.NonAutoPolicyDetail
                                                    .zhEndTime
                                                    ? _vm.NonAutoPolicyDetail.zhEndTime.slice(
                                                        0,
                                                        10
                                                      ) + " 24时"
                                                    : "-"
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _c(
                  "el-collapse",
                  {
                    staticStyle: { "margin-top": "10px" },
                    model: {
                      value: _vm.activeAccount,
                      callback: function ($$v) {
                        _vm.activeAccount = $$v
                      },
                      expression: "activeAccount",
                    },
                  },
                  [
                    _c(
                      "el-collapse-item",
                      {
                        staticClass: "collapse-item-style",
                        attrs: { name: "1" },
                      },
                      [
                        _c("template", { slot: "title" }, [
                          _c(
                            "div",
                            {
                              staticClass: "title",
                              staticStyle: { "line-height": "25px" },
                            },
                            [
                              _c("FormLabel", {
                                staticStyle: { "font-size": "14px" },
                                attrs: { "form-label": "注销信息" },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c(
                          "el-form",
                          {
                            ref: "form",
                            staticStyle: { margin: "20px 0" },
                            attrs: {
                              model: _vm.form,
                              rules: _vm.rules,
                              "label-width": "180px",
                            },
                          },
                          [
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 24 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "申请原因：",
                                          prop: "cancelDesc",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            type: "textarea",
                                            maxlength: "100",
                                            row: "10",
                                            placeholder: "请输入申请原因",
                                          },
                                          model: {
                                            value: _vm.form.cancelDesc,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "cancelDesc",
                                                $$v
                                              )
                                            },
                                            expression: "form.cancelDesc",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 24 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticClass: "must-star",
                                        attrs: { label: "注销情况说明：" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "filesLists" },
                                          [
                                            _vm._l(
                                              _vm.fileList,
                                              function (fitem, findex) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: findex,
                                                    staticClass: "fileBox",
                                                  },
                                                  [
                                                    _c("el-image", {
                                                      staticStyle: {
                                                        width: "100px",
                                                        height: "100px",
                                                      },
                                                      attrs: {
                                                        src: fitem.fileUrl,
                                                        "preview-src-list": [
                                                          fitem.fileUrl,
                                                        ],
                                                      },
                                                    }),
                                                    !_vm.isView
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "deleteIcon",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                                return _vm.delFileList(
                                                                  fitem
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "el-icon-circle-close",
                                                            }),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            !_vm.isView &&
                                            _vm.fileList.length < 1
                                              ? _c(
                                                  "div",
                                                  { staticClass: "fileBox" },
                                                  [
                                                    _c(
                                                      "el-upload",
                                                      {
                                                        staticClass:
                                                          "upload-demo",
                                                        attrs: {
                                                          limit: 1,
                                                          "before-remove":
                                                            function () {
                                                              return (_vm.formData =
                                                                "")
                                                            },
                                                          action: "",
                                                          multiple: "",
                                                          "show-file-list": false,
                                                          "http-request":
                                                            function (val) {
                                                              return _vm.HTuploadFile(
                                                                val,
                                                                "FT04"
                                                              )
                                                            },
                                                          "before-upload":
                                                            function (val) {
                                                              return _vm.HTbeforeUpload(
                                                                val
                                                              )
                                                            },
                                                          "file-list":
                                                            _vm.fileList,
                                                          accept:
                                                            _vm.uploadType,
                                                          "list-type": "text",
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-plus avatar-uploader-icon",
                                                        }),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          2
                                        ),
                                        [
                                          "DBC",
                                          "YB",
                                          "SB",
                                          "BZHG",
                                          "XXAX",
                                          "DXWY",
                                        ].includes(
                                          _vm.NonAutoPolicyDetail
                                            .productClassCode
                                        )
                                          ? _c(
                                              "span",
                                              {
                                                staticClass: "file-text",
                                                attrs: {
                                                  title: "注销申请书模板下载",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.downloadTemplate()
                                                  },
                                                },
                                              },
                                              [_vm._v(" 注销申请书模板下载")]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: { "border-top": "1px solid #d7d7d7" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "footer-btn-list",
                                staticStyle: {
                                  "margin-top": "10px",
                                  "font-size": "16px",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticStyle: { "margin-right": "50px" } },
                                  [
                                    _vm._v(
                                      "服务销售价(元)：" +
                                        _vm._s(
                                          _vm.NonAutoPolicyDetail
                                            .targetSalesPrice || "-"
                                        )
                                    ),
                                  ]
                                ),
                                _c("span", [
                                  _vm._v(
                                    "DLR采购价-含税(元)：" +
                                      _vm._s(
                                        _vm.NonAutoPolicyDetail
                                          .productPurchasePrice || "-"
                                      )
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "h2",
                              {
                                staticStyle: {
                                  color: "red",
                                  display: "flex",
                                  "flex-direction": "row",
                                  "justify-content": "flex-start",
                                  "align-items": "center",
                                },
                              },
                              [
                                _vm.NonAutoPolicyDetail.productClassCode ==
                                  "SB" ||
                                _vm.NonAutoPolicyDetail.productClassCode ==
                                  "BZHG" ||
                                _vm.NonAutoPolicyDetail.productClassCode == "YB"
                                  ? _c(
                                      "span",
                                      [
                                        _vm._v(" 退款金额："),
                                        _c("el-input", {
                                          staticStyle: { width: "150px" },
                                          on: {
                                            input: function ($event) {
                                              return _vm.productPurchasePriceSet(
                                                _vm.cancelAmounts
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.cancelAmounts,
                                            callback: function ($$v) {
                                              _vm.cancelAmounts = $$v
                                            },
                                            expression: "cancelAmounts",
                                          },
                                        }),
                                        _vm._v(" 元 "),
                                      ],
                                      1
                                    )
                                  : _c("span", [
                                      _vm._v(
                                        "退款金额：" +
                                          _vm._s(
                                            _vm.cancelAmounts
                                              ? _vm.cancelAmounts
                                              : "-"
                                          ) +
                                          " 元"
                                      ),
                                    ]),
                              ]
                            ),
                          ]
                        ),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _vm.isView && _vm.policyCancelLogList.length
                  ? _c(
                      "el-card",
                      {
                        staticStyle: { "margin-top": "10px" },
                        attrs: { shadow: "never" },
                      },
                      [
                        _vm.isView && _vm.policyCancelLogList.length
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  "background-color": "#f2f2f2",
                                  padding: "20px",
                                  "border-radius": "10px",
                                  "margin-top": "15px",
                                },
                              },
                              [
                                _c("b", [_vm._v("注销处理记录：")]),
                                _vm._l(
                                  _vm.policyCancelLogList,
                                  function (item, index) {
                                    return _c("div", { key: index }, [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "font-size": "14px",
                                            "margin-top": "20px",
                                          },
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(_vm._s(item.operationTime)),
                                          ]),
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(item.operationAction)
                                            ),
                                          ]),
                                          item.operationResult === "COR03"
                                            ? _c("div", [
                                                _vm._v(
                                                  "不通过理由：" +
                                                    _vm._s(item.reason)
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      ),
                                    ])
                                  }
                                ),
                              ],
                              2
                            )
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
      ]),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c("div", { staticClass: "footer-btn" }, [
          _vm.NonAutoPolicyDetail.productClassCode == "BZHG"
            ? _c(
                "div",
                { staticClass: "footer-btn-list" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", icon: "el-icon-close" },
                      on: { click: _vm.back },
                    },
                    [_vm._v("关闭")]
                  ),
                  !_vm.isView
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            size: "mini",
                            type: "primary",
                            icon: "el-icon-check",
                          },
                          on: { click: _vm.save },
                        },
                        [_vm._v("确认提交")]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "footer-btn-list" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", icon: "el-icon-close" },
                      on: { click: _vm.back },
                    },
                    [_vm._v("关闭")]
                  ),
                  !_vm.isView
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            size: "mini",
                            type: "primary",
                            icon: "el-icon-check",
                          },
                          on: { click: _vm.submit },
                        },
                        [_vm._v("确认提交")]
                      )
                    : _vm._e(),
                ],
                1
              ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }