import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from "@/mixins/initHeight";
import Pagination from "@/components/Pagination";
import OemSelect from "@/components/OemSelect";
import request from "@/api/batchSync";
import slipRequest from "@/api/insuranceslip";
import { getSupplyers, getBeforeDate, getCurrentDate, getUserOrgInfo, commonExport } from "@/utils/common";
export default {
  name: "BatchSync",
  components: {
    Pagination: Pagination,
    OemSelect: OemSelect
  },
  mixins: [initHeight],
  data: function data() {
    return {
      status: {
        '0': '等待中',
        '1': '查无结果',
        '2': '查询失败',
        '3': '待分配',
        '4': '已分配',
        '5': '分配失败'
      },
      drawer: false,
      searchForm: {
        createdTimeStart: getBeforeDate(6),
        createdTimeEnd: getCurrentDate()
      },
      startEndTime: [getBeforeDate(6), getCurrentDate()],
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      loading: false,
      orgData: {
        salesOrgName: "",
        salesOrgCode: ""
      },
      distributionOrgData: {
        salesOrgName: "",
        salesOrgCode: ""
      },
      showBatchSyncDialog: false,
      showDistributionDialog: false,
      formLoading: false,
      polcyForm: {},
      distributionForm: {},
      dialogTotal: 0,
      dialogListQuery: {
        pageNum: 1,
        pageSize: 20
      },
      distributionFormRules: {
        salemanName: [{
          required: true,
          message: "请选择业务员",
          trigger: "change"
        }]
      },
      polcyFormRules: {
        orgCode: [{
          required: true,
          message: "请选择业务机构称",
          trigger: "change"
        }],
        insCorpCode: [{
          required: true,
          message: "请选择保险公司",
          trigger: "change"
        }],
        insCityCode: [{
          required: true,
          message: "请选择投保城市",
          trigger: "change"
        }],
        insChannelId: [{
          required: true,
          message: "请选择渠道",
          trigger: "change"
        }],
        queryParam: [{
          required: true,
          message: "请输入车牌/车架号",
          trigger: "blur"
        }]
      },
      jobConfigCode: null,
      employeeList: [],
      tableDetailData: [],
      supplyers: [],
      showDialog: false,
      channels: [],
      multipleSelection: [],
      batchNo: '',
      id: null,
      cityList: [],
      configOption: [],
      //配置项
      bizType: localStorage.getItem("bizType"),
      dealerName: "",
      placeholder: "\u5F55\u5165\u8F66\u724C\u53F7\u7801\uFF0C\u8F66\u67B6\u53F7\uFF0C\u4E00\u6761\u4E00\u884C\n\u793A\u4F8B\uFF1A\n\u7CA4B12345\n\u7CA4B123456\nLGBG42E06LY300882\n            "
    };
  },
  watch: {
    showBatchSyncDialog: function showBatchSyncDialog(val) {
      var _this = this;
      if (val) {
        this.getDefaultValue();
        this.$nextTick(function () {
          return _this.$refs["polcyForm"].clearValidate();
        });
      }
    }
  },
  created: function created() {
    this.supplyers = getSupplyers();
    this.loadData();
  },
  methods: {
    getDefaultValue: function getDefaultValue() {
      if (this.bizType !== 'OEM') {
        var orgObj = getUserOrgInfo() || {};
        this.searchForm.orgCode = orgObj.orgCode;
        this.dealerName = orgObj.fullName;
        this.nodeClick({
          salesOrgCode: orgObj.orgCode
        });
      }
    },
    // 导出
    exportExcels: function exportExcels() {
      var searchForm = _objectSpread(_objectSpread(_objectSpread({}, this.searchForm), this.listQuery), {}, {
        count: false
      });
      var exporParams = {
        total: this.total,
        fileName: '批量同步列表',
        exportUrl: '/vi-core-service/syncPolicy/search',
        searchForm: searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    getCurrentOrgUsers: function getCurrentOrgUsers(val) {
      this.employeeList = val;
    },
    // 日期选择事件
    dateChange: function dateChange(start, end, val) {
      if (val && val.length > 1) {
        this.searchForm[start] = val[0];
        this.searchForm[end] = val[1];
      } else {
        this.searchForm.createdTimeStart = null;
        this.searchForm.createdTimeStart = null;
      }
    },
    // 通过保司来选择城市
    filterCityByInsCorp: function filterCityByInsCorp(val) {
      var _this2 = this;
      this.cityList = [];
      var configOption = _.cloneDeep(this.configOption);
      configOption.forEach(function (item) {
        if (item.insCorpCode === val) {
          _this2.cityList.push(item);
        }
      });
      this.polcyForm.insChannelId = null;
      this.polcyForm.insCityCode = null;
      this.channels = [];
    },
    // 通过城市来选择渠道
    filterChannelsByCity: function filterChannelsByCity(val) {
      var _this3 = this;
      this.channels = [];
      var configOption = _.cloneDeep(this.configOption);
      configOption.forEach(function (item) {
        if (item.insCityCode === val && item.insCorpCode == _this3.polcyForm.insCorpCode) {
          _this3.channels.push(item);
        }
      });
      this.polcyForm.insChannelId = this.channels[0].channelId;
    },
    getAllChannnelsByOrgCode: function getAllChannnelsByOrgCode(orgCode) {
      var _this4 = this;
      slipRequest.getChannelsByFeature({
        featureType: 'POLICY_SYNC',
        orgCode: orgCode
      }).then(function (res) {
        if (res.code == 200 && res.data) {
          _this4.configOption = res.data;
          var insCorp = getSupplyers();
          var supplyers = [];
          _this4.supplyers = [];
          res.data.forEach(function (item) {
            insCorp.forEach(function (eitem) {
              if (_.toUpper(item.insCorpCode) == _.toUpper(eitem.code)) {
                supplyers.push(_objectSpread(_objectSpread({}, item), {}, {
                  insCorpName: eitem.shortName
                }));
              }
            });
          });
          _this4.supplyers = _.uniqBy(supplyers, 'insCorpCode');
        }
      });
    },
    selectable: function selectable(row) {
      if (row.status == "3" || row.status == "5") {
        return true;
      } else {
        return false;
      }
    },
    distribution: function distribution(item) {
      var _this5 = this;
      this.id = item.id;
      this.showDistributionDialog = true;
      this.distributionForm = {};
      this.distributionOrgData = {
        salesOrgName: "",
        salesOrgCode: ""
      };
      this.getDistributionOrgVal();
      this.$nextTick(function () {
        return _this5.$refs["distributionForm"].clearValidate();
      });
    },
    getDistributionOrgVal: function getDistributionOrgVal() {
      if (this.bizType !== 'OEM') {
        var orgObj = getUserOrgInfo() || {};
        this.distributionForm.orgCode = orgObj.orgCode;
        this.$refs["distributionOemRef"].getUsers(orgObj.orgCode);
      }
    },
    // 树点击事件
    nodeClick: function nodeClick(data) {
      this.polcyForm.orgCode = data.salesOrgCode;
      this.polcyForm.insChannelId = null;
      this.polcyForm.insCorpCode = null;
      this.polcyForm.insCityCode = null;
      this.channels = [];
      this.supplyers = [];
      this.cityList = [];
      this.getAllChannnelsByOrgCode(data.salesOrgCode);
    },
    // 树点击事件
    nodeDistributionClick: function nodeDistributionClick(data) {
      this.distributionForm.orgCode = data.salesOrgCode;
    },
    batchSync: function batchSync() {
      this.showBatchSyncDialog = true;
      this.polcyForm = {
        insChannelId: null,
        insCityCode: null,
        insCorpCode: null,
        orgCode: '',
        insChannelName: '',
        bizType: ''
      };
      this.orgData = {
        salesOrgName: "",
        salesOrgCode: ""
      };
    },
    viewDetail: function viewDetail(item) {
      this.showDialog = true;
      this.batchNo = item.batchNo;
      this.loadDistributionData();
    },
    loadDistributionData: function loadDistributionData() {
      var _this6 = this;
      this.showDistributionDialog = false;
      request.getDetailBybatchNo(_objectSpread({
        batchNo: this.batchNo
      }, this.dialogListQuery)).then(function (res) {
        _this6.tableDetailData = res.data.list;
        //总条数
        _this6.dialogTotal = res.data.page.recordsTotal;
      }).catch(function (err) {
        _this6.loading = false;
      });
    },
    save: function save() {
      var _this7 = this;
      this.$refs["polcyForm"].validate(function (valid) {
        if (valid) {
          var userInfo = JSON.parse(localStorage.getItem('userInfo'));
          var postData = _.cloneDeep(_this7.polcyForm);
          var queryParam = postData.queryParam.split("\n");
          var insChanne = _.find(_this7.channels, function (item) {
            return item.channelId == postData.insChannelId;
          });
          postData['insChannelName'] = insChanne && insChanne.insCorpChannel;
          postData['insChannelAlias'] = insChanne && insChanne.insCorpChannelNickname;
          postData['queryParam'] = queryParam;
          postData['bizType'] = userInfo && userInfo.bizType;
          request.createBatchSync(postData).then(function (res) {
            if (res.code == 200) {
              _this7.$message.success(res.msg);
              _this7.loadData();
            }
            _this7.formLoading = false;
          });
        }
      });
    },
    saveDistribution: function saveDistribution() {
      var _this8 = this;
      this.$refs["distributionForm"].validate(function (valid) {
        if (valid) {
          var postData = _objectSpread({
            ids: []
          }, _this8.distributionForm);
          var uesr = _.find(_this8.employeeList, function (item) {
            return item.userName == postData.salemanName;
          });
          postData["salemanBy"] = uesr && uesr.account;
          if (!_.isEmpty(_this8.multipleSelection)) {
            //多选
            postData.ids = _.map(_this8.multipleSelection, 'id');
          } else {
            postData.ids = [_this8.id];
          }
          request.distributionSalesman(postData).then(function (res) {
            if (res.code == 200) {
              _this8.$message.success(res.msg);
              _this8.loadDistributionData();
            }
          });
        }
      });
    },
    closeShowDialog: function closeShowDialog() {
      this.showBatchSyncDialog = false;
    },
    // 重置搜索表单
    resetSearch: function resetSearch() {
      this.searchForm = {
        createdTimeStart: getBeforeDate(6),
        createdTimeEnd: getCurrentDate()
      };
      this.startEndTime = [getBeforeDate(6), getCurrentDate()];
      this.orgData = {};
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    // 刷新
    breakData: function breakData() {
      this.searchData();
    },
    // 获取列表数据
    loadData: function loadData() {
      var _this9 = this;
      this.showBatchSyncDialog = false;
      this.loading = true;
      this.drawer = false;
      if (this.searchForm.createdTimeStart) {
        this.searchForm.createdTimeStart = this.searchForm.createdTimeStart.split(' ')[0] + ' 00:00:00';
      }
      if (this.searchForm.createdTimeEnd) {
        this.searchForm.createdTimeEnd = this.searchForm.createdTimeEnd.split(' ')[0] + ' 23:59:59';
      }
      request.getList(_objectSpread(_objectSpread({}, this.searchForm), this.listQuery)).then(function (res) {
        _this9.loading = false;
        if (res.data) {
          _this9.tableData = res.data.list || [];
          //总条数
          _this9.total = res.data.page.recordsTotal;
        }
      }).catch(function (err) {
        _this9.loading = false;
      });
    }
  }
};