import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'PageContainer',
  props: {
    footerBtnHeight: {
      type: Number,
      default: 0
    }
  },
  computed: {
    pageHeight: function pageHeight() {
      return document.documentElement.clientHeight - 130 - this.footerBtnHeight + 'px'; //底部功能区高度 
    }
  }
};