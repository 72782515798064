import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '../../../mixins/initHeight';
import dict from '../../../mixins/dict';
import Pagination from '@/components/Pagination';
import AddDialog from './add';
import EditDialog from './edit';
import dictRequest from '@/api/dictionary';
import repairApi from '@/api/repair';
export default {
  name: 'rescueAgency',
  components: {
    AddDialog: AddDialog,
    Pagination: Pagination,
    EditDialog: EditDialog
  },
  mixins: [initHeight, dict],
  computed: {},
  data: function data() {
    return {
      activeName: "awaitTrack",
      drawer: false,
      searchForm: {},
      form: {},
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      defaultProps: {
        children: 'children',
        label: 'fullName'
      },
      tableData: [],
      multipleSelection: [],
      opType: '',
      startEndTime: null,
      loading: false,
      orgData: [],
      highlightCurrent: true,
      dialogType: '',
      showAddDialog: false,
      showEditDialog: false,
      rescueType: [],
      AidId: ''
    };
  },
  created: function created() {
    this.getAidagencyType();
  },
  mounted: function mounted() {
    this.loadData();
    this.getAidagencyType();
  },
  filters: {
    filterAidType: function filterAidType(val, list) {
      var aidTypeName = '';
      var item = _.find(list, {
        'itemCode': val
      });
      aidTypeName = item.itemName;
      return aidTypeName;
    }
  },
  methods: {
    getAidagencyType: function getAidagencyType() {
      var _this = this;
      //获取结算状态
      dictRequest.getDictItemsByCode("AIDAGENCY_TYPE").then(function (res) {
        if (res.data) {
          _this.rescueType = res.data;
        }
      });
    },
    add: function add(val) {
      this.dialogType = val;
      this.showAddDialog = true;
    },
    dbSelected: function dbSelected(item) {
      //双击查看
      return;
    },
    editClicked: function editClicked(val, row) {
      //编辑
      this.dialogType = val;
      this.AidId = row.aidId;
      this.showEditDialog = true;
    },
    delClicked: function delClicked(row) {
      var _this2 = this;
      //删除
      this.$confirm('是否删除该救援机构?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this2.deleteList(row.aidId);
      }).catch(function () {});
    },
    deleteList: function deleteList(ids) {
      var _this3 = this;
      repairApi.deleteAid(ids).then(function (res) {
        if (res.code == 200) {
          _this3.$message({
            type: 'success',
            message: '删除成功!'
          });
          _this3.getAidLists();
        }
      });
    },
    delAllClicked: function delAllClicked() {
      var _this4 = this;
      //批量删除
      this.$confirm('是否确认删除, 确认删除后不能恢复?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        if (_this4.multipleSelection) {
          var ids = [];
          _this4.multipleSelection.map(function (item) {
            ids.push(item.aidId);
          });
          _this4.deleteList(ids);
        }
        // 暂时缺接口
      }).catch(function () {});
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {};
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    getAidLists: function getAidLists() {
      var _this5 = this;
      this.searchForm.pageNum = this.listQuery.pageNum;
      this.searchForm.pageSize = this.listQuery.pageSize;
      repairApi.getAid(this.searchForm).then(function (res) {
        // console.log(res)
        if (res.code == 200 && res.data && res.data.list.length != 0) {
          _this5.tableData = res.data.list;
          _this5.total = res.data.page.recordsTotal;
        }
      });
    },
    loadData: function loadData() {
      //获取列表数据
      this.getAidLists();
      // this.loading = true;
      this.drawer = false;
      this.showAddDialog = false;
      this.showEditDialog = false;
    },
    handleSelectionChange: function handleSelectionChange(val) {
      //列表行选择事件
      this.multipleSelection = val;
    }
  }
};