import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
/**
 * 投保异常配置接口
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 详情
   * @param {*} id
   */
  getDetail: function getDetail(id) {
    return request.get(getUrl("/failureTranslation/get?failureTranslationId=".concat(id), 14));
  },
  /**
   * 新增
   * @param {*} data
   */
  create: function create(data) {
    return request.post(getUrl("/failureTranslation/create", 14), data);
  },
  /**
   * 修改
   * @param {*} data
   */
  update: function update(data) {
    return request.post(getUrl("/failureTranslation/update?failureTranslationId=".concat(data.failureTranslationId), 14), data);
  },
  /**
   * 列表
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   * @param {*} data 查询参数
   */
  getList: function getList(pageNum, pageSize, data) {
    return request.get(getUrl("/failureTranslation/search?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 14), {
      params: data
    });
  },
  /**
   * 删除
   * @param {*} ids
   */
  delete: function _delete(ids) {
    return request.delete(getUrl("/failureTranslation/delete?failureTranslationIds=".concat(ids), 14));
  },
  /**
   * 创建错误分类
   * @param {*} data
   */
  createCategory: function createCategory(data) {
    return request.post(getUrl("/monitorFailureCategory/create", 14), data);
  },
  /**
   * 详情
   * @param {*} id
   */
  getCategoryDetail: function getCategoryDetail(id) {
    return request.get(getUrl("/monitorFailureCategory/get?id=".concat(id), 14));
  },
  /**
   * 修改错误分类
   * @param {*} data
   */
  updateCategory: function updateCategory(data) {
    return request.post(getUrl("/monitorFailureCategory/update?categoryId=".concat(data.categoryId), 14), data);
  },
  /**
   * 错误分类列表
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   * @param {*} data 查询参数
   */
  getCategoryList: function getCategoryList(pageNum, pageSize, data) {
    return request.get(getUrl("/monitorFailureCategory/search?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 14), {
      params: data
    });
  },
  /**
   * 删除错误分类
   * @param {*} ids
   */
  deleteCategory: function deleteCategory(ids) {
    return request.delete(getUrl("/monitorFailureCategory/batchDeleteList?ids=".concat(ids.toString()), 14));
  },
  /**
   * 获取所有错误分类
   */
  getAllErrorCategory: function getAllErrorCategory() {
    return request.get(getUrl("/monitorFailureCategory/allCategory", 14));
  }
};
export default objApi;