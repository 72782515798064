import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import Pagination from '@/components/Pagination';
import DetailDialog from './detail';
import DatePicker from '@/components/DatePicker';
import OrgSelect from '@/components/OrgSelect';
import OCRRequest from '@/api/OCR.js';
import dictionary from '@/api/dictionary';
import OemSelect from '@/components/OemSelect';
import { getBeforeDate, getCurrentDate, commonExport } from '@/utils/common';
export default {
  name: 'OCRCallRecord',
  components: {
    Pagination: Pagination,
    DetailDialog: DetailDialog,
    OrgSelect: OrgSelect,
    DatePicker: DatePicker,
    OemSelect: OemSelect
  },
  mixins: [initHeight],
  data: function data() {
    return {
      orgForm: {
        salesOrgCode: '',
        salesOrgName: '全部',
        salesOrgType: ''
      },
      bizType: localStorage.getItem('bizType') || "",
      showDetailDialog: false,
      searchForm: {},
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      loading: false,
      invokeRecordId: null,
      invokeDateTime: [getBeforeDate(30), getCurrentDate()],
      ocrSupplierlist: [],
      OCRAllList: [],
      timeoutForExport: null
    };
  },
  created: function created() {
    if (this.bizType && this.bizType == 'DEALER') {
      var userInfo = JSON.parse(localStorage.getItem('userInfo'));
      if (userInfo.data) {
        var organization = userInfo.data.organization || {};
        this.searchForm.orgCode = organization.dealerCode;
        this.searchForm.salesOrgName = organization.dealerName;
      }
    }
    this.getOCRSupplier();
    this.getOcrFunctionList();
    this.loadData();
  },
  methods: {
    // 树形节点点击事件
    handleNodeClick: function handleNodeClick(data, node, target) {
      if (this.bizType === "OEM") {
        this.searchForm.orgCode = data.salesOrgCode;
        this.searchForm.salesOrgName = data.salesOrgName;
        this.searchForm.areaType = data.salesOrgType;
      }
    },
    // 获取OCR供应商列表
    getOCRSupplier: function getOCRSupplier() {
      var _this = this;
      dictionary.getDictItemsByCode('OCRSUPPLIER').then(function (res) {
        if (res.code === 200) {
          _this.ocrSupplierlist = res.data || [];
        }
        ;
      });
    },
    // 日期选择事件
    getPickersDate: function getPickersDate(name, val) {
      if (val && val.length > 1) {
        this[name] = val;
      } else {
        this[name] = [];
      }
    },
    // 获取OCR配置的功能
    getOcrFunctionList: function getOcrFunctionList() {
      var _this2 = this;
      dictionary.getDictItemsByCode("OCR_FUNCTION").then(function (res) {
        _this2.OCRAllList = res.data ? res.data : [];
      });
    },
    // 导出防抖
    exportExcels: function exportExcels() {
      var _this3 = this;
      if (this.timeoutForExport) {
        clearTimeout(this.timeoutForExport);
      }
      this.timeoutForExport = setTimeout(function () {
        _this3.exportFn();
      }, 500);
    },
    // 导出
    exportFn: function exportFn() {
      var exporParams = {
        total: this.total,
        fileName: 'OCR识别记录',
        exportUrl: '/tp-recognition-service/functioninvokerecord/search',
        searchForm: _objectSpread(_objectSpread({}, this.searchForm), this.listQuery)
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    //日期选择事件
    dateChange: function dateChange(start, end, val) {
      if (val && val.length > 1) {
        this.searchForm[start] = val[0];
        this.searchForm[end] = val[1];
      } else {
        this.invokeDateTime = [];
      }
    },
    breakData: function breakData() {
      this.loadData();
    },
    // 查看详情
    viewDetail: function viewDetail(item) {
      this.showDetailDialog = true;
      this.invokeRecordId = item.ocrFunctionInvokeRecordId && item.ocrFunctionInvokeRecordId.toString();
    },
    // 重置搜索表单
    resetSearch: function resetSearch() {
      if (this.bizType == 'OEM') {
        this.orgForm = {
          salesOrgCode: '',
          salesOrgName: '全部',
          salesOrgType: ''
        };
      }
      ;
      this.searchForm = {};
      this.invokeDateTime = [getBeforeDate(30), getCurrentDate()];
    },
    // 搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    // 获取列表数据
    loadData: function loadData() {
      var _this4 = this;
      var isDialogNoRefresh = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.showDetailDialog = false;
      if (isDialogNoRefresh) return;
      this.loading = true;
      this.searchForm.pageSize = this.listQuery.pageSize;
      this.searchForm.pageNum = this.listQuery.pageNum;
      if (this.invokeDateTime) {
        this.searchForm.invokeDatetimeStart = this.invokeDateTime[0] && this.invokeDateTime[0].split(' ')[0] + ' 00:00:00' || null;
        this.searchForm.invokeDatetimeEnd = this.invokeDateTime[1] && this.invokeDateTime[1].split(' ')[0] + ' 23:59:59' || null;
      }
      OCRRequest.getRecordList(this.searchForm).then(function (res) {
        _this4.loading = false;
        if (res.code == 200) {
          _this4.tableData = res.data.list || [];
          _this4.total = res.data.page.recordsTotal || 0;
        }
        ;
      }).catch(function (err) {
        _this4.loading = false;
      });
    }
  }
};