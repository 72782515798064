var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "renewal-activities" },
    [
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "space-between",
            "align-items": "center",
            margin: "5px 0",
          },
        },
        [
          _c("b", [_vm._v("续保活动")]),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                disabled:
                  _vm.currentObj.defaulted &&
                  !!_vm.curRenewalConfigParams.orgCode,
                icon: "el-icon-plus",
                plain: "",
                size: "mini",
              },
              on: {
                click: function ($event) {
                  return _vm.add("add")
                },
              },
            },
            [_vm._v("新增")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "multipleTable",
          staticStyle: { width: "100%" },
          attrs: {
            size: "mini",
            border: "",
            data: _vm.tableData,
            height: _vm.tableHeightN,
            "header-cell-style": { background: "#F7F7F7" },
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "activeName",
              label: "跟进阶段",
              "min-width": "100",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "时间节点",
              "min-width": "200",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " 距离到期" +
                        _vm._s(scope.row.minDay) +
                        "~" +
                        _vm._s(scope.row.maxDay) +
                        "天 "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "140" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "mini",
                          disabled:
                            _vm.currentObj &&
                            _vm.currentObj.defaulted &&
                            !!_vm.curRenewalConfigParams.orgCode,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.edit("edit", scope.row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "mini",
                          disabled:
                            _vm.currentObj &&
                            _vm.currentObj.defaulted &&
                            !!_vm.curRenewalConfigParams.orgCode,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.deleteDate(scope.row)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showDialog,
            "close-on-click-modal": false,
            width: "600px",
            "append-to-body": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [
                  _vm._v(
                    _vm._s(_vm.opType === "edit" ? "编辑" : "新增") + "续保活动"
                  ),
                ]
              ),
            ]
          ),
          _c("RenewalActivitiesDialog", {
            attrs: {
              opType: _vm.opType,
              refresh: _vm.showDialog,
              currentItem: _vm.currentItem,
              configId: _vm.configId,
            },
            on: { closePage: _vm.refreshData },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }