//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import requestApi from '@/api/smsManage'

export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      form: {},
      rules: {
        smsTemplate: {
          required: true,
          message: '请选择短信模板',
          trigger: "change"
        },
        smsSignature: {
          required: true,
          message: '请输入短信签名',
          trigger: "blur"
        },
        smsContent: {
          required: true,
          message: '请输入短信内容',
          trigger: "blur"
        },
        phone: {
          required: true,
          message: '请输入添加接收人手机号',
          trigger: "blur"
        }
      },
      msgTemplateList: [],
      formLoading: false
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    initData: function initData() {
      //初始化数据
      this.form = {};
      this.$refs.detail.resetFields();
    },
    save: function save() {
      //保存数据
      this.$refs.detail.validate(function (valid) {
        if (valid) {}
        ;
      });
    },
    closePage: function closePage() {
      //关闭弹窗
      this.$emit('closePage');
    }
  }
};