import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import Pagination from '@/components/Pagination';
import giftApi from '@/api/giftManagement';
import GiftIssueDialog from './dialog/giftIssueDialog';
import OemSelect from '@/components/OemSelect';
import { character, commonExport } from '@/utils/common';
export default {
  name: 'GiftIssue',
  components: {
    Pagination: Pagination,
    GiftIssueDialog: GiftIssueDialog,
    OemSelect: OemSelect
  },
  mixins: [initHeight],
  data: function data() {
    return {
      searchForm: {},
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      loading: false,
      drawer: false,
      showAddDialog: false,
      opType: '',
      currentId: '',
      startEndTime: [],
      orgForm: {
        salesOrgCode: '',
        salesOrgName: ''
      }
    };
  },
  created: function created() {
    this.loadData();
  },
  methods: {
    // 机构树点击事件
    handleNodeClick: function handleNodeClick(data) {
      this.searchForm.orgCode = data.salesOrgCode;
      this.searchForm.orgName = data.salesOrgName;
      this.searchForm.bizType = 'BROKER';
    },
    // 输入的车架号中的字母自动转大写，并去除i，o，q
    checkValue: function checkValue(val) {
      if (val) {
        val = val.replace(/i/g, '').replace(/I/g, '').replace(/o/g, '').replace(/O/g, '').replace(/q/g, '').replace(/Q/g, '');
      }
      this.searchForm.vin = character(_.toUpper(val));
    },
    // 日期选择事件
    dateChange: function dateChange(start, end, val) {
      if (val && val.length > 1) {
        this.searchForm[start] = val[0];
        this.searchForm[end] = val[1];
      } else {
        this.searchForm[start] = '';
        this.searchForm[end] = '';
      }
      ;
    },
    // 新增
    add: function add(type) {
      this.opType = type;
      this.showAddDialog = true;
    },
    // 编辑
    edit: function edit(type, item) {
      this.opType = type;
      this.currentId = item.deviceId;
      this.showAddDialog = true;
    },
    // 重置搜索表单
    resetSearch: function resetSearch() {
      this.searchForm = {};
      this.startEndTime = [];
      this.orgForm = {
        salesOrgName: '',
        salesOrgCode: ''
      };
      this.searchForm.bizType = null;
    },
    // 搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    // 刷新
    breakData: function breakData() {
      this.loadData();
    },
    // 获取列表数据
    loadData: function loadData() {
      var _this = this;
      var isDialogNoRefresh = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.showAddDialog = false;
      if (isDialogNoRefresh) return;
      this.loading = true;
      this.drawer = false;
      if (this.searchForm && this.searchForm.endTime) {
        this.searchForm.endTime = this.searchForm.endTime.split(' ')[0] + ' 23:59:59';
      }
      ;
      var data = _objectSpread(_objectSpread({}, this.listQuery), this.searchForm);
      giftApi.getGiftIssueList(data).then(function (res) {
        _this.loading = false;
        if (res.code === 200) {
          _this.tableData = res.data.list;
          _this.total = res.data.page.recordsTotal;
        }
      }).catch(function (err) {
        _this.loading = false;
      });
    },
    // 导出
    exportExcels: function exportExcels() {
      var exporParams = {
        total: this.total,
        fileName: '赠品发放',
        exportUrl: '/oc-core-service/cardVoucherIssued',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    // 删除
    batchDelete: function batchDelete(item) {
      var _this2 = this;
      var ids = [];
      if (!_.isEmpty(item)) {
        ids.push(item.cardVoucherIssuedId);
      } else {
        if (!_.isEmpty(this.multipleSelection)) {
          ids = _.map(this.multipleSelection, 'cardVoucherIssuedId');
        }
        ;
      }
      ;
      this.$confirm('您确定要删除么？', {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        giftApi.deleteGiftIssue(ids).then(function (res) {
          if (res.code === 200) {
            _this2.$message.success(res.msg);
            _this2.loadData();
          }
          ;
        });
      });
    }
  }
};