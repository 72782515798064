var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.formLoading,
          expression: "formLoading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: {},
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "540px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.formRules,
                    "label-width": "120px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "业务机构：", prop: "orgCode" } },
                            [
                              _c("OrgSelect", {
                                attrs: { searchForm: _vm.orgData },
                                on: { nodeClick: _vm.nodeClick },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "当前续保渠道：",
                                prop: "insCityCode",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: "请选择当前续保渠道",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.form.insCityCode,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "insCityCode", $$v)
                                    },
                                    expression: "form.insCityCode",
                                  },
                                },
                                _vm._l(
                                  _vm.policyCityItems,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.label,
                                        value: item.value,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "车牌号：" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  minlength: "7",
                                  maxlength: "8",
                                  placeholder: "请输入车牌号",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.plateNo,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "plateNo",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form.plateNo",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "车架号：", prop: "vin" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "17",
                                  placeholder: "请输入车架号",
                                  clearable: "",
                                },
                                on: { input: _vm.inputvin },
                                nativeOn: {
                                  "!paste": function ($event) {
                                    $event.preventDefault()
                                    return _vm.pastevin($event)
                                  },
                                },
                                model: {
                                  value: _vm.form.vin,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "vin",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form.vin",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "发动机号：" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入发动机号",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.engineNo,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "engineNo",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form.engineNo",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "证件号后六位：" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "6",
                                  placeholder: "请输入证件号后六位",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.ownerCertificateNo,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "ownerCertificateNo",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form.ownerCertificateNo",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "车主名：" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入车主名",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.ownerName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "ownerName",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form.ownerName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "被保人姓名：" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入被保人姓名",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.insuredName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "insuredName",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form.insuredName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "上年投保单号：" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入上年投保单号",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.lastPolicyNo,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "lastPolicyNo",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form.lastPolicyNo",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c("el-form-item", { attrs: { label: "" } }, [
                            _c("div", { staticStyle: { color: "red" } }, [
                              _c("div", [
                                _vm._v(
                                  "*通过以下提示完善信息可提高续保带出率*"
                                ),
                              ]),
                              _c("div", [
                                _vm._v("*平安续保：车架号或车牌+证件号后六位*"),
                              ]),
                              _c("div", [
                                _vm._v(
                                  "*国寿续保：车牌号码或发动机号+被保人姓名*"
                                ),
                              ]),
                              _c("div", [
                                _vm._v(
                                  "*众诚续保：车架号或跨机构被保人姓名+车牌号+发动机号*"
                                ),
                              ]),
                              _c("div", [
                                _vm._v("*大地续保：只支持保单号查询*"),
                              ]),
                              _c("div", [
                                _vm._v("*太平续保：车架号+车主姓名*"),
                              ]),
                              _c("div", [_vm._v("*其他保司：车架号*")]),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: { click: _vm.closePage },
            },
            [_vm._v("关闭")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-check", type: "primary", size: "mini" },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }