//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FormLabel from '@/components/FormSubLabel';
// import html2canvas from 'html2canvas';

export default {
  components: {
    FormLabel: FormLabel
  },
  props: {
    title: {
      type: String,
      required: true
    },
    width: {
      type: String,
      default: '800px'
    },
    modal: {
      type: Boolean,
      default: true
    },
    appendToBody: {
      type: Boolean,
      default: true
    },
    fullscreen: {
      type: Boolean,
      default: false
    },
    value: {
      type: Boolean,
      required: true
    },
    formLoading: {
      type: Boolean,
      required: false
    }
  },
  data: function data() {
    return {
      visible: false,
      imgUrl: ''
    };
  },
  computed: {
    formHeight: function formHeight() {
      return document.documentElement.clientHeight - 260 + 'px';
    }
  },
  watch: {
    value: function value(newVal, oldVal) {
      this.visible = newVal;
    }
  },
  methods: {
    toImage: function toImage() {
      var _this = this;
      this.$refs.imageWrapper.scrollBottom = 0;
      setTimeout(function () {
        html2canvas(_this.$refs.imageWrapper).then(function (canvas) {
          var dataURL = canvas.toDataURL("image/png");
          _this.imgUrl = dataURL;
          console.log('dataURL', dataURL);
        });
      }, 500);
    },
    handleClose: function handleClose() {
      this.visible = false;
      this.$emit('input', false);
      this.$emit('handleClose');
    }
  }
};