import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.keys.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "SpecialForm",
  props: {
    specialList: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      formBzData: [],
      formBsData: [],
      formBz: {},
      formBs: {},
      checkedList: [],
      checkedBsList: []
    };
  },
  computed: {},
  watch: {
    specialList: function specialList() {
      this.initData();
    }
  },
  created: function created() {},
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    handleChecked: function handleChecked(row, index, type) {
      if (row.promiseStatus == 'REQUIRED') {
        row.selected = true;
      }
      if (type == 'Bz') {
        if (row.selected) {
          this.checkedList.push(row);
        } else {
          if (this.checkedList) {
            this.checkedList.splice(index, 1);
          } else {
            this.checkedList = [];
          }
        }
      }
      if (type == 'Bs') {
        if (row.selected) {
          this.checkedBsList.push(row);
        } else {
          if (this.checkedBsList) {
            this.checkedBsList.splice(index, 1);
          } else {
            this.checkedBsList = [];
          }
        }
      }
    },
    initData: function initData() {
      var _this = this;
      if (!_.isEmpty(this.specialList)) {
        var _specialList = _.cloneDeep(this.specialList);
        var bzData = _specialList.bzSpecialPromiseList;
        var newData = [];
        bzData.forEach(function (item) {
          item.newContent = item.originalPromiseText;
          var _item = _.find(bzData, function (eitem) {
            return eitem.promiseCode == item.promiseCode && eitem.selected && eitem.promiseStatus == '1';
          });
          // console.log(_item)
          if (_item) {
            item.fullPromiseText = _item.fullPromiseText;
            if (!item.selected) {
              item.selected = true;
              newData.push(item);
            }
          } else {
            item.fullPromiseText = "";
            newData.push(item);
          }
        });
        this.formBzData = newData;
        this.formBzData.forEach(function (item, index) {
          if (item.selected) _this.handleChecked(item, index, 'Bz');
        });
        var bsData = _specialList.bsSpecialPromiseList;
        newData = [];
        bsData.forEach(function (item) {
          item.newContent = item.originalPromiseText;
          var _item = _.find(bsData, function (eitem) {
            return eitem.promiseCode == item.promiseCode && eitem.selected && eitem.promiseStatus == '1';
          });
          // console.log(_item)
          if (_item) {
            item.fullPromiseText = _item.fullPromiseText;
            if (!item.selected) {
              item.selected = true;
              newData.push(item);
            }
          } else {
            item.fullPromiseText = "";
            newData.push(item);
          }
        });
        this.formBsData = newData;
        this.formBsData.forEach(function (item, index) {
          if (item.selected) _this.handleChecked(item, index, 'Bs');
        });
      } else {
        this.formBzData = [];
        this.formBsData = [];
      }
    },
    submit: function submit() {
      var selectBzItems = [];
      var selectBsItems = [];
      //获取输入框中的内容替换*号，组装新的条款内容；
      this.formBzData.forEach(function (item) {
        if (item.selected) {
          var texts = item.originalPromiseText.split('*');
          var newContent = '';
          var i = 0;
          var inputLen = 0;
          var childLen = 0;
          var inputData = [];
          texts.forEach(function (text) {
            var child = document.getElementsByClassName("item_".concat(item.promiseCode, "_").concat(i));
            if (!_.isEmpty(child)) {
              childLen++;
            }
            var value = child[0] ? child[0].value : null;
            if (value) {
              inputLen++;
              if (i < texts.length - 1) {
                inputData.push(child[0].value);
                newContent = newContent + text + child[0].value;
              } else {
                newContent = newContent + text;
              }
            } else {
              newContent = newContent + text;
            }
            i++;
          });
          item.isNull = childLen != inputLen;
          item.fullPromiseText = newContent;
          item.newContent = inputData ? JSON.stringify(inputData) : null;
          selectBzItems.push(item);
        }
      });
      this.formBsData.forEach(function (item) {
        if (item.selected) {
          var texts = item.originalPromiseText.split('*');
          var newContent = '';
          var i = 0;
          var inputLen = 0;
          var childLen = 0;
          var inputData = [];
          texts.forEach(function (text) {
            var child = document.getElementsByClassName("item_".concat(item.promiseCode, "_").concat(i));
            if (!_.isEmpty(child)) {
              childLen++;
            }
            var value = child[0] ? child[0].value : null;
            if (value) {
              inputLen++;
              if (i < texts.length - 1) {
                inputData.push(child[0].value);
                newContent = newContent + text + child[0].value;
              } else {
                newContent = newContent + text;
              }
            } else {
              newContent = newContent + text;
            }
            i++;
          });
          item.isNull = childLen != inputLen;
          item.fullPromiseText = newContent;
          item.newContent = inputData ? JSON.stringify(inputData) : null;
          selectBsItems.push(item);
        }
      });
      //判断是否全部都补充完整；
      var hasNullData = _.find(selectBzItems, function (item) {
        return item.isNull;
      });
      var hasBsNullData = _.find(selectBsItems, function (item) {
        return item.isNull;
      });
      if (hasNullData || hasBsNullData) {
        var promiseCode = hasNullData && hasNullData.promiseCode || hasBsNullData && hasBsNullData.promiseCode;
        this.$message({
          type: 'warning',
          message: "\u7279\u522B\u7EA6\u5B9A\u3010".concat(promiseCode, "\u3011\u7684\u5185\u5BB9\u4E0D\u5B8C\u6574\uFF0C\u8BF7\u8865\u5145\uFF01"),
          offset: 50,
          customClass: 'zZindex'
        });
      } else {
        this.$emit('closeSpecial', {
          bzSpecialPromiseList: selectBzItems,
          bsSpecialPromiseList: selectBsItems
        });
      }
    },
    cancel: function cancel() {
      this.$emit('closeSpecial');
    },
    reSetName: function reSetName(item) {
      //将含有固定占位符“***”的内容替换成输入框；
      var _content = item.originalPromiseText.split('*');
      var _newContent = '';
      var i = 0;
      var _contents = [];
      if (item.fullPromiseText) {
        try {
          _contents = JSON.parse(item.fullPromiseText);
        } catch (err) {
          _contents = [];
        }
        console.log('_contents=>:', _contents);
      }
      var inputLen = 0;
      _content.forEach(function (content) {
        if (i < _content.length - 1 && content) {
          var input = "<input type=\"text\" class='item_".concat(item.promiseCode, "_").concat(i, "' value='").concat(_contents && _contents.length > 0 ? _contents[inputLen] || '' : '', "' style=\"width:120px;border: 1px solid #d8d8d8;border-radius: 3px;padding: 3px 5px;margin:2px\">");
          _newContent = _newContent + content + input;
          inputLen++;
        } else {
          if (i == 0 && !content) {
            var input = "<input type=\"text\" class='item_".concat(item.promiseCode, "_").concat(i, "' value='").concat(_contents && _contents.length > 0 ? _contents[0] || '' : '', "' style=\"width:120px;border: 1px solid #d8d8d8;border-radius: 3px;padding: 3px 5px;margin:2px\">");
            _newContent = _newContent + content + input;
          } else {
            _newContent = _newContent + content;
          }
        }
        i++;
      });
      return _newContent;
    }
  }
};