import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.object.keys.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PageContainer from "@/components/PageContainer";
import messageApi from '@/api/messagePlatform';
export default {
  name: 'MsgDetailPage',
  components: {
    PageContainer: PageContainer
  },
  data: function data() {
    return {
      form: {},
      formLoading: false,
      messageId: ''
    };
  },
  watch: {
    // 监听路由是否变化
    $route: function $route(route) {
      switch (route.path) {
        case "/messagePlatform/msgDetailPage":
          this.initData();
          break;
        default:
          return;
      }
    }
  },
  created: function created() {
    this.initData();
  },
  methods: {
    // 初始化数据
    initData: function initData() {
      this.messageId = this.$route.query.messageId || '';
      this.getMsgDetail();
    },
    // 获取详情
    getMsgDetail: function getMsgDetail() {
      var _this = this;
      this.form = {};
      this.formLoading = true;
      messageApi.getMessageInfo(this.messageId).then(function (res) {
        _this.formLoading = false;
        if (res.code === 200) {
          _this.form = res.data || {};
          _this.form.messageContent = _this.form.messageContent.replace(/[\r|\n|\t]/g, '');
          _this.form.messageContent = JSON.parse(_this.form.messageContent);
          if (!_this.form.readed) {
            // 读取状态
            messageApi.readMessage(_this.form.messageId).then(function (res) {
              if (res.code === 200) {
                _this.$Bus.$emit('loadData');
              }
              ;
            });
          }
          ;
          if (_this.form.messageContent && _this.form.messageContent.redirectUrl) {
            _this.$store.dispatch('tagsView/delView', _this.$route);
            if (_this.$mainAppAction) {
              _this.$mainAppAction.closeTagView('tagsView/delView', _this.$route);
            }
            _this.$router.replace({
              path: _this.form.messageContent.redirectUrl
            });
          }
          ;
        }
        ;
      }).catch(function (err) {
        _this.loading = false;
      });
    }
  }
};