var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "PageContainer",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.fullscreenLoading,
              expression: "fullscreenLoading",
            },
          ],
        },
        [
          _c("div", { attrs: { slot: "main" }, slot: "main" }, [
            _c(
              "div",
              {
                staticClass: "app-m-cls",
                staticStyle: { "min-height": "calc(100vh - 130px)" },
              },
              [
                _c(
                  "div",
                  { ref: "header", staticClass: "header-cls" },
                  [
                    _c("el-card", { attrs: { shadow: "never" } }, [
                      _c("div", { staticStyle: { padding: "10px" } }, [
                        _c(
                          "div",
                          [
                            _c("svg-icon", {
                              staticStyle: { "font-size": "36px" },
                              attrs: {
                                "icon-class": _vm.form.insCorpCode
                                  ? _vm.form.insCorpCode.toLowerCase()
                                  : "-",
                              },
                            }),
                            _c(
                              "span",
                              { staticStyle: { "font-size": "30px" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.form.insCorpName
                                      ? _vm.form.insCorpName
                                      : "-"
                                  )
                                ),
                              ]
                            ),
                            _c("el-divider", {
                              attrs: { direction: "vertical" },
                            }),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.form.riskTypeName
                                    ? _vm.form.riskTypeName
                                    : "-"
                                ) + "保单"
                              ),
                            ]),
                            _c("el-divider", {
                              attrs: { direction: "vertical" },
                            }),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.form.insCityName
                                    ? _vm.form.insCityName
                                    : "-"
                                )
                              ),
                            ]),
                            _c("el-divider", {
                              attrs: { direction: "vertical" },
                            }),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.form.policyVehicle &&
                                    _vm.form.policyVehicle.plateNo
                                    ? _vm.form.policyVehicle.plateNo
                                    : "-"
                                )
                              ),
                            ]),
                            _c("el-divider", {
                              attrs: { direction: "vertical" },
                            }),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.form.policyVehicle &&
                                    _vm.form.policyVehicle.ownerName
                                    ? _vm.form.policyVehicle.ownerName
                                    : "-"
                                )
                              ),
                            ]),
                            _c("el-divider", {
                              attrs: { direction: "vertical" },
                            }),
                            _c("span", [
                              _vm._v(
                                "承保日期：" +
                                  _vm._s(
                                    _vm.form.insuredTime
                                      ? _vm.form.insuredTime.slice(0, 10)
                                      : "-"
                                  ) +
                                  " "
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-top": "10px",
                              "font-size": "14px",
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticStyle: { "line-height": "30px" } },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.form.riskTypeName
                                        ? _vm.form.riskTypeName
                                        : "-"
                                    ) +
                                      "保单号: " +
                                      _vm._s(
                                        _vm.form.policyNo
                                          ? _vm.form.policyNo
                                          : "-"
                                      )
                                  ),
                                ]),
                              ]
                            ),
                            _c("div", [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.form.riskTypeName
                                      ? _vm.form.riskTypeName
                                      : "-"
                                  ) +
                                    "生效日期: " +
                                    _vm._s(_vm.form.effectiveStartTime) +
                                    " 至 " +
                                    _vm._s(_vm.form.effectiveEndTime)
                                ),
                              ]),
                            ]),
                            _c(
                              "div",
                              { staticStyle: { "margin-top": "8px" } },
                              [
                                _c("span", [
                                  _vm._v(
                                    "创建时间：" +
                                      _vm._s(_vm.form.createdTime) +
                                      " "
                                  ),
                                ]),
                                _c("el-divider", {
                                  attrs: { direction: "vertical" },
                                }),
                                _c("span", [
                                  _vm._v(
                                    "更新时间：" +
                                      _vm._s(_vm.form.modifiedTime) +
                                      " "
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    _c(
                      "el-card",
                      {
                        staticStyle: { "margin-top": "10px" },
                        attrs: { shadow: "never" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "title",
                            staticStyle: { "line-height": "25px" },
                          },
                          [
                            _c("FormLabel", {
                              attrs: { "form-label": "投保车辆信息" },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          { attrs: { gutter: 0 } },
                          [
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c("div", { staticClass: "card_item" }, [
                                _c("div", { staticClass: "card_item_label" }, [
                                  _vm._v("车牌号码："),
                                ]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.form.policyVehicle &&
                                        _vm.form.policyVehicle.plateNo
                                        ? _vm.form.policyVehicle.plateNo
                                        : "-"
                                    )
                                  ),
                                ]),
                              ]),
                            ]),
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c("div", { staticClass: "card_item" }, [
                                _c("div", { staticClass: "card_item_label" }, [
                                  _vm._v("品牌型号："),
                                ]),
                                _c(
                                  "span",
                                  { staticStyle: { "text-align": "left" } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.form.policyVehicle &&
                                            _vm.form.policyVehicle.modelName
                                            ? _vm.form.policyVehicle.modelName
                                            : "-"
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c("div", { staticClass: "card_item" }, [
                                _c("div", { staticClass: "card_item_label" }, [
                                  _vm._v("是否过户："),
                                ]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.form.policyVehicle &&
                                        _vm.form.policyVehicle.transferFlag
                                        ? "是"
                                        : "否"
                                    )
                                  ),
                                ]),
                              ]),
                            ]),
                            _vm.form.policyVehicle &&
                            _vm.form.policyVehicle.transferFlag
                              ? _c("el-col", { attrs: { span: 8 } }, [
                                  _c("div", { staticClass: "card_item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "card_item_label" },
                                      [_vm._v("过户日期：")]
                                    ),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.form.policyVehicle &&
                                            _vm.form.policyVehicle.transferDate
                                            ? _vm.form.policyVehicle.transferDate.split(
                                                " "
                                              )[0]
                                            : "-"
                                        )
                                      ),
                                    ]),
                                  ]),
                                ])
                              : _vm._e(),
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c("div", { staticClass: "card_item" }, [
                                _c("div", { staticClass: "card_item_label" }, [
                                  _vm._v("车架号："),
                                ]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.form.policyVehicle &&
                                        _vm.form.policyVehicle.vin
                                        ? _vm.form.policyVehicle.vin
                                        : "-"
                                    )
                                  ),
                                ]),
                              ]),
                            ]),
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c("div", { staticClass: "card_item" }, [
                                _c("div", { staticClass: "card_item_label" }, [
                                  _vm._v("发动机号："),
                                ]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.form.policyVehicle &&
                                        _vm.form.policyVehicle.engineNo
                                        ? _vm.form.policyVehicle.engineNo
                                        : "-"
                                    )
                                  ),
                                ]),
                              ]),
                            ]),
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c("div", { staticClass: "card_item" }, [
                                _c("div", { staticClass: "card_item_label" }, [
                                  _vm._v("新车购置价："),
                                ]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.form.policyVehicle &&
                                        _vm.form.policyVehicle.purchasePrice
                                        ? "￥" +
                                            _vm.form.policyVehicle.purchasePrice.toLocaleString()
                                        : "-"
                                    )
                                  ),
                                ]),
                              ]),
                            ]),
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c("div", { staticClass: "card_item" }, [
                                _c("div", { staticClass: "card_item_label" }, [
                                  _vm._v("核定载客量："),
                                ]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.form.policyVehicle &&
                                        _vm.form.policyVehicle.seats
                                        ? _vm.form.policyVehicle.seats
                                        : "-"
                                    )
                                  ),
                                ]),
                              ]),
                            ]),
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c("div", { staticClass: "card_item" }, [
                                _c("div", { staticClass: "card_item_label" }, [
                                  _vm._v("排气量(L)："),
                                ]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.form.policyVehicle &&
                                        _vm.form.policyVehicle.displacement
                                        ? _vm.form.policyVehicle.displacement
                                        : "-"
                                    )
                                  ),
                                ]),
                              ]),
                            ]),
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c("div", { staticClass: "card_item" }, [
                                _c("div", { staticClass: "card_item_label" }, [
                                  _vm._v("注册日期："),
                                ]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.form.policyVehicle &&
                                        _vm.form.policyVehicle.registerDate
                                        ? _vm.form.policyVehicle.registerDate.split(
                                            " "
                                          )[0]
                                        : "-"
                                    )
                                  ),
                                ]),
                              ]),
                            ]),
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c("div", { staticClass: "card_item" }, [
                                _c("div", { staticClass: "card_item_label" }, [
                                  _vm._v("行驶证车辆类型："),
                                ]),
                                _vm.form.policyVehicle &&
                                _vm.form.policyVehicle.vehicleType
                                  ? _c(
                                      "span",
                                      { staticStyle: { "text-align": "left" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.form.policyVehicle
                                              .vehicleTypeName
                                              ? _vm.form.policyVehicle
                                                  .vehicleTypeName
                                              : _vm.getVehicleTypeName(
                                                  _vm.form.policyVehicle
                                                    .vehicleType
                                                )
                                          )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ]),
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c("div", { staticClass: "card_item" }, [
                                _c("div", { staticClass: "card_item_label" }, [
                                  _vm._v("使用性质："),
                                ]),
                                _vm.form.policyVehicle &&
                                _vm.form.policyVehicle.useAttribute
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.form.policyVehicle
                                            .useAttributeName
                                            ? _vm.form.policyVehicle
                                                .useAttributeName
                                            : _vm.getUseAttributeName(
                                                _vm.form.policyVehicle
                                                  .useAttribute
                                              )
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                            ]),
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c("div", { staticClass: "card_item" }, [
                                _c("div", { staticClass: "card_item_label" }, [
                                  _vm._v("使用性质细分："),
                                ]),
                                _vm.form.policyVehicle &&
                                _vm.form.policyVehicle.useAttributeDetail
                                  ? _c(
                                      "span",
                                      { staticStyle: { "text-align": "left" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.form.policyVehicle
                                              .useAttributeDetailName
                                              ? _vm.form.policyVehicle
                                                  .useAttributeDetailName
                                              : _vm.getUseAttributDetailCodeName(
                                                  _vm.form.policyVehicle
                                                    .useAttributDetailCode
                                                )
                                          )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ]),
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c("div", { staticClass: "card_item" }, [
                                _c("div", { staticClass: "card_item_label" }, [
                                  _vm._v("号牌种类："),
                                ]),
                                _vm.form.policyVehicle &&
                                _vm.form.policyVehicle.plateType
                                  ? _c(
                                      "span",
                                      { staticStyle: { "text-align": "left" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.form.policyVehicle &&
                                              _vm.form.policyVehicle
                                                .plateTypeName
                                              ? _vm.form.policyVehicle
                                                  .plateTypeName
                                              : _vm.getPlateTypeName(
                                                  _vm.form.policyVehicle
                                                    .plateType
                                                )
                                          )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ]),
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c("div", { staticClass: "card_item" }, [
                                _c("div", { staticClass: "card_item_label" }, [
                                  _vm._v("能源类型："),
                                ]),
                                _vm.form.policyVehicle &&
                                _vm.form.policyVehicle.fuelTypeName
                                  ? _c(
                                      "span",
                                      { staticStyle: { "text-align": "left" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.form.policyVehicle.fuelTypeName
                                              ? _vm.form.policyVehicle
                                                  .fuelTypeName
                                              : ""
                                          )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ]),
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c("div", { staticClass: "card_item" }, [
                                _c("div", { staticClass: "card_item_label" }, [
                                  _vm._v("核定载质量(吨)："),
                                ]),
                                _vm.form.policyVehicle
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.form.policyVehicle.tonNumber
                                            ? _vm.form.policyVehicle.tonNumber
                                            : 0
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                            ]),
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c("div", { staticClass: "card_item" }, [
                                _c("div", { staticClass: "card_item_label" }, [
                                  _vm._v("整备质量(吨)："),
                                ]),
                                _vm.form.policyVehicle
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.form.policyVehicle &&
                                            _vm.form.policyVehicle.wholeWeight
                                            ? _vm.form.policyVehicle.wholeWeight
                                            : 0
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                            ]),
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c("div", { staticClass: "card_item" }, [
                                _c("div", { staticClass: "card_item_label" }, [
                                  _vm._v("互联网标识："),
                                ]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.form.internetStamp ? "是" : "否")
                                  ),
                                ]),
                              ]),
                            ]),
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c("div", { staticClass: "card_item" }, [
                                _c("div", { staticClass: "card_item_label" }, [
                                  _vm._v("涉农标识："),
                                ]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.form.agricultureStamp ? "是" : "否"
                                    )
                                  ),
                                ]),
                              ]),
                            ]),
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c("div", { staticClass: "card_item" }, [
                                _c("div", { staticClass: "card_item_label" }, [
                                  _vm._v("车辆标识："),
                                ]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.form.vehicleStampDisplay
                                        ? _vm.form.vehicleStampDisplay
                                        : "-"
                                    )
                                  ),
                                ]),
                              ]),
                            ]),
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c("div", { staticClass: "card_item" }, [
                                _c("div", { staticClass: "card_item_label" }, [
                                  _vm._v("续保标识："),
                                ]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.form.renewalTypeDisplay
                                        ? _vm.form.renewalTypeDisplay
                                        : "-"
                                    )
                                  ),
                                ]),
                              ]),
                            ]),
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c("div", { staticClass: "card_item" }, [
                                _c("div", { staticClass: "card_item_label" }, [
                                  _vm._v("新能源标识："),
                                ]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.form.newEnergy ? "是" : "否")
                                  ),
                                ]),
                              ]),
                            ]),
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c("div", { staticClass: "card_item" }, [
                                _c("div", { staticClass: "card_item_label" }, [
                                  _vm._v("保险合同争议解决方式："),
                                ]),
                                _c("span", [_vm._v("诉讼")]),
                              ]),
                            ]),
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c("div", { staticClass: "card_item" }, [
                                _c("div", { staticClass: "card_item_label" }, [
                                  _vm._v("手续费费率："),
                                ]),
                                _vm.form.poundageRate
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.form.poundageRate % 1 == 0
                                              ? parseInt(_vm.form.poundageRate)
                                              : parseFloat(
                                                  _vm.form.poundageRate
                                                ).toFixed(2)
                                          ) +
                                          "%"
                                      ),
                                    ])
                                  : _c("span", [_vm._v("-")]),
                              ]),
                            ]),
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c("div", { staticClass: "card_item" }, [
                                _c("div", { staticClass: "card_item_label" }, [
                                  _vm._v("手续费金额："),
                                ]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.form.poundageAmount
                                        ? _vm.form.poundageAmount.toFixed(2)
                                        : "-"
                                    )
                                  ),
                                ]),
                              ]),
                            ]),
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c("div", { staticClass: "card_item" }, [
                                _c("div", { staticClass: "card_item_label" }, [
                                  _vm._v("匹配政策："),
                                ]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.form.matchPolicyStatus ? "是" : "否"
                                    )
                                  ),
                                ]),
                              ]),
                            ]),
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c("div", { staticClass: "card_item" }, [
                                _c("div", { staticClass: "card_item_label" }, [
                                  _vm._v("是否参加厂家政策："),
                                ]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.form.joinCouponActDisplay || "-")
                                  ),
                                ]),
                              ]),
                            ]),
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c("div", { staticClass: "card_item" }, [
                                _c("div", { staticClass: "card_item_label" }, [
                                  _vm._v("功率(KW)："),
                                ]),
                                _c(
                                  "span",
                                  { staticStyle: { "text-align": "left" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.form.policyVehicle &&
                                          _vm.form.policyVehicle.power
                                          ? _vm.form.policyVehicle.power
                                          : 0
                                      )
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c("div", { staticClass: "card_item" }, [
                                _c("div", { staticClass: "card_item_label" }, [
                                  _vm._v("厂牌车型："),
                                ]),
                                _c(
                                  "span",
                                  { staticStyle: { "text-align": "left" } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.form.policyVehicle &&
                                            _vm.form.policyVehicle
                                              .cjVhcBrandName
                                            ? _vm.form.policyVehicle
                                                .cjVhcBrandName
                                            : "-"
                                        ) +
                                        " " +
                                        _vm._s(
                                          _vm.form.policyVehicle &&
                                            _vm.form.policyVehicle
                                              .cjVhcSeriesName
                                            ? _vm.form.policyVehicle
                                                .cjVhcSeriesName
                                            : "-"
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c(
                                "div",
                                {
                                  staticClass: "card_item",
                                  staticStyle: { "align-items": "center" },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "card_item_label" },
                                    [_vm._v("车销日期：")]
                                  ),
                                  _c(
                                    "div",
                                    { staticStyle: { display: "flex" } },
                                    [
                                      _c("el-date-picker", {
                                        staticStyle: {
                                          width: "152px",
                                          "margin-right": "10px",
                                        },
                                        attrs: {
                                          disabled: "",
                                          placeholder: "",
                                          format: "yyyy-MM-dd",
                                          "value-format": "yyyy-MM-dd HH:mm:ss",
                                          "unlink-panels": "",
                                        },
                                        model: {
                                          value:
                                            _vm.form.policyVehicle.salesDate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form.policyVehicle,
                                              "salesDate",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "form.policyVehicle.salesDate",
                                        },
                                      }),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "mini",
                                            icon: "el-icon-refresh",
                                            loading: _vm.refreshing,
                                          },
                                          on: {
                                            click: _vm.getVehicleSalesData,
                                          },
                                        },
                                        [_vm._v("获取车销日期")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-card",
                      {
                        staticStyle: { "margin-top": "10px" },
                        attrs: { shadow: "never" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "title",
                            staticStyle: { "line-height": "25px" },
                          },
                          [
                            _c("FormLabel", {
                              attrs: { "form-label": "车主信息" },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          { attrs: { gutter: 0 } },
                          [
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c("div", { staticClass: "card_item" }, [
                                _c("div", { staticClass: "card_item_label" }, [
                                  _vm._v("车主名称："),
                                ]),
                                _c(
                                  "span",
                                  { staticStyle: { "text-align": "left" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.form.policyVehicle &&
                                          _vm.form.policyVehicle.ownerName
                                          ? _vm.form.policyVehicle.ownerName
                                          : "-"
                                      )
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c("div", { staticClass: "card_item" }, [
                                _c("div", { staticClass: "card_item_label" }, [
                                  _vm._v("证件类型："),
                                ]),
                                _vm.form.policyVehicle &&
                                _vm.form.policyVehicle.ownerCertificateType
                                  ? _c(
                                      "span",
                                      { staticStyle: { "text-align": "left" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getCertificateTypeName(
                                              _vm.form.policyVehicle
                                                .ownerCertificateType
                                            )
                                          )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ]),
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c("div", { staticClass: "card_item" }, [
                                _c("div", { staticClass: "card_item_label" }, [
                                  _vm._v("证件号："),
                                ]),
                                _c(
                                  "span",
                                  { staticStyle: { "text-align": "left" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.form.policyVehicle &&
                                          _vm.form.policyVehicle
                                            .ownerCertificateNo
                                          ? _vm.form.policyVehicle.ownerCertificateNo.replace(
                                              /^(.{10})(?:\d+)(.{2})$/,
                                              "$1******$2"
                                            )
                                          : "-"
                                      )
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          { attrs: { gutter: 0 } },
                          [
                            _vm.form.policyVehicle &&
                            _vm.form.policyVehicle.ownAttribute == "0"
                              ? _c("el-col", { attrs: { span: 8 } }, [
                                  _c("div", { staticClass: "card_item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "card_item_label" },
                                      [_vm._v("出生日期：")]
                                    ),
                                    _c(
                                      "span",
                                      { staticStyle: { "text-align": "left" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.form.policyVehicle &&
                                              _vm.form.policyVehicle
                                                .ownerBirthday
                                              ? _vm.form.policyVehicle.ownerBirthday.split(
                                                  " "
                                                )[0]
                                              : "-"
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm.form.policyVehicle &&
                            _vm.form.policyVehicle.ownAttribute == "0"
                              ? _c("el-col", { attrs: { span: 8 } }, [
                                  _c("div", { staticClass: "card_item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "card_item_label" },
                                      [_vm._v("性别：")]
                                    ),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.form.policyVehicle &&
                                            _vm.form.policyVehicle.ownerSex &&
                                            _vm.form.policyVehicle.ownerSex ==
                                              "M"
                                            ? "男"
                                            : _vm.form.policyVehicle.ownerSex ==
                                              "F"
                                            ? "女"
                                            : ""
                                        )
                                      ),
                                    ]),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm.form.policyVehicle
                              ? _c("el-col", { attrs: { span: 8 } }, [
                                  _c("div", { staticClass: "card_item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "card_item_label" },
                                      [_vm._v("联系电话：")]
                                    ),
                                    _c(
                                      "span",
                                      { staticStyle: { "text-align": "left" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.form.policyVehicle &&
                                              _vm.form.policyVehicle.ownerMobile
                                              ? _vm.form.policyVehicle
                                                  .ownerMobile
                                              : _vm.form.policyVehicle
                                                  .ownerLandline || "-"
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm.form.policyVehicle &&
                            _vm.form.policyVehicle.ownAttribute != "0"
                              ? _c("el-col", { attrs: { span: 16 } }, [
                                  _c("div", { staticClass: "card_item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "card_item_label" },
                                      [_vm._v("联系地址：")]
                                    ),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.form.policyVehicle &&
                                            _vm.form.policyVehicle
                                              .ownerDistrictName
                                            ? _vm.form.policyVehicle
                                                .ownerDistrictName
                                            : "-"
                                        )
                                      ),
                                    ]),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "margin-left": "15px" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.form.policyVehicle &&
                                              _vm.form.policyVehicle
                                                .ownerAddress
                                              ? _vm.form.policyVehicle
                                                  .ownerAddress
                                              : "-"
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm.form.policyVehicle &&
                        _vm.form.policyVehicle.ownAttribute == "0"
                          ? _c(
                              "el-row",
                              { attrs: { gutter: 0 } },
                              [
                                _c("el-col", { attrs: { span: 24 } }, [
                                  _c("div", { staticClass: "card_item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "card_item_label" },
                                      [_vm._v("联系地址：")]
                                    ),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.form.policyVehicle &&
                                            _vm.form.policyVehicle
                                              .ownerDistrictName
                                            ? _vm.form.policyVehicle
                                                .ownerDistrictName
                                            : "-"
                                        )
                                      ),
                                    ]),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "margin-left": "15px" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.form.policyVehicle &&
                                              _vm.form.policyVehicle
                                                .ownerAddress
                                              ? _vm.form.policyVehicle
                                                  .ownerAddress
                                              : "-"
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "el-card",
                      {
                        staticStyle: { "margin-top": "10px" },
                        attrs: { shadow: "never" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "title",
                            staticStyle: { "line-height": "25px" },
                          },
                          [
                            _c("FormLabel", {
                              attrs: { "form-label": "关系人信息" },
                            }),
                          ],
                          1
                        ),
                        _vm._l(_vm.form.policyInsureds, function (item, index) {
                          return _c(
                            "div",
                            { key: index },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "title",
                                  staticStyle: {
                                    "line-height": "25px",
                                    "padding-left": "30px",
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(item.relationshipFlagName)),
                                  ]),
                                ]
                              ),
                              _c(
                                "el-row",
                                { attrs: { gutter: 0 } },
                                [
                                  _c("el-col", { attrs: { span: 8 } }, [
                                    _c("div", { staticClass: "card_item" }, [
                                      _c(
                                        "div",
                                        { staticClass: "card_item_label" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm._.toLower(
                                                item.relationshipFlag
                                              ) == "applicant"
                                                ? "投保人"
                                                : "被保人"
                                            ) + "名称："
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: { "text-align": "left" },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(item.name ? item.name : "-")
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                  _c("el-col", { attrs: { span: 8 } }, [
                                    _c("div", { staticClass: "card_item" }, [
                                      _c(
                                        "div",
                                        { staticClass: "card_item_label" },
                                        [_vm._v("证件类型：")]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: { "text-align": "left" },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              item.certificateTypeName
                                                ? item.certificateTypeName
                                                : _vm.getCertificateTypeName(
                                                    item.certificateType
                                                  )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                  _c("el-col", { attrs: { span: 8 } }, [
                                    _c("div", { staticClass: "card_item" }, [
                                      _c(
                                        "div",
                                        { staticClass: "card_item_label" },
                                        [_vm._v("证件号：")]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: { "text-align": "left" },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              item.certificateNo
                                                ? item.certificateNo.replace(
                                                    /^(.{10})(?:\d+)(.{2})$/,
                                                    "$1******$2"
                                                  )
                                                : "-"
                                            )
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                { attrs: { gutter: 0 } },
                                [
                                  item.custAttribute == "0"
                                    ? _c("el-col", { attrs: { span: 8 } }, [
                                        _c(
                                          "div",
                                          { staticClass: "card_item" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "card_item_label",
                                              },
                                              [_vm._v("出生日期：")]
                                            ),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  item.birthDay
                                                    ? item.birthDay.split(
                                                        " "
                                                      )[0]
                                                    : "-"
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                  item.custAttribute == "0"
                                    ? _c("el-col", { attrs: { span: 8 } }, [
                                        _c(
                                          "div",
                                          { staticClass: "card_item" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "card_item_label",
                                              },
                                              [_vm._v("性别：")]
                                            ),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  item.sexName
                                                    ? item.sexName
                                                    : ""
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                  _c("el-col", { attrs: { span: 8 } }, [
                                    _c("div", { staticClass: "card_item" }, [
                                      _c(
                                        "div",
                                        { staticClass: "card_item_label" },
                                        [_vm._v("联系电话：")]
                                      ),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            item.mobile
                                              ? item.mobile
                                              : item.landline || "-"
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                  item.custAttribute != "0"
                                    ? _c("el-col", { attrs: { span: 16 } }, [
                                        _c(
                                          "div",
                                          { staticClass: "card_item" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "card_item_label",
                                              },
                                              [_vm._v("联系地址：")]
                                            ),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  item.districtName
                                                    ? item.districtName
                                                    : "-"
                                                )
                                              ),
                                            ]),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "margin-left": "15px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    item.address
                                                      ? item.address
                                                      : "-"
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                { attrs: { gutter: 0 } },
                                [
                                  item.custAttribute == "0"
                                    ? _c("el-col", { attrs: { span: 16 } }, [
                                        _c(
                                          "div",
                                          { staticClass: "card_item" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "card_item_label",
                                              },
                                              [_vm._v("联系地址：")]
                                            ),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  item.districtName
                                                    ? item.districtName
                                                    : "-"
                                                )
                                              ),
                                            ]),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "margin-left": "15px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    item.address
                                                      ? item.address
                                                      : "-"
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._.toLower(item.relationshipFlag) ==
                                  "applicant"
                                    ? _c("el-col", { attrs: { span: 8 } }, [
                                        _c(
                                          "div",
                                          { staticClass: "card_item" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "card_item_label",
                                              },
                                              [_vm._v("邮箱：")]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "text-align": "left",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    item.email
                                                      ? item.email
                                                      : "-"
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                      ],
                      2
                    ),
                    _vm.form.riskType == "0"
                      ? _c(
                          "el-card",
                          {
                            staticStyle: { "margin-top": "10px" },
                            attrs: { shadow: "never" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "title",
                                staticStyle: { "line-height": "25px" },
                              },
                              [
                                _c("FormLabel", {
                                  attrs: { "form-label": "投保险种及保费" },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "margin-top": "10px",
                                  "font-size": "14px",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "line-height": "30px",
                                      "padding-left": "15px",
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v("交强险保费: "),
                                      _c(
                                        "span",
                                        { staticClass: "amount-text-color" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              (_vm.form.actualPremium &&
                                                _vm.form.actualPremium.toLocaleString()) ||
                                                "0.00"
                                            )
                                          ),
                                        ]
                                      ),
                                      _vm._v("元 "),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "amount-description-color",
                                        },
                                        [
                                          _vm._v(
                                            " (" +
                                              _vm._s(
                                                _vm.form.riskTypeName || "-"
                                              ) +
                                              "折扣系数: " +
                                              _vm._s(_vm.form.discountFactor) +
                                              " "
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "padding-left": "10px",
                                              },
                                            },
                                            [_vm._v("交强险平台信息: ")]
                                          ),
                                          _vm._.isEmpty(
                                            _vm.form.policyPlatformList
                                          )
                                            ? _c("span", [_vm._v("无")])
                                            : _vm._e(),
                                          !_vm._.isEmpty(
                                            _vm.form.policyPlatformList
                                          )
                                            ? _c(
                                                "el-popover",
                                                {
                                                  attrs: {
                                                    placement: "bottom",
                                                    width: "240",
                                                    trigger: "click",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "table",
                                                    {
                                                      staticStyle: {
                                                        width: "100%",
                                                      },
                                                    },
                                                    [
                                                      _c("colgroup", [
                                                        _c("col", {
                                                          staticStyle: {
                                                            width: "70%",
                                                          },
                                                        }),
                                                        _c("col", {
                                                          staticStyle: {
                                                            width: "30%",
                                                          },
                                                        }),
                                                      ]),
                                                      _c(
                                                        "thead",
                                                        {
                                                          staticStyle: {
                                                            "background-color":
                                                              "#f5f5f5",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "tr",
                                                            {
                                                              staticStyle: {
                                                                "font-size":
                                                                  "13px",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "th",
                                                                {
                                                                  attrs: {
                                                                    scope:
                                                                      "col",
                                                                  },
                                                                },
                                                                [_vm._v("名称")]
                                                              ),
                                                              _c(
                                                                "th",
                                                                {
                                                                  attrs: {
                                                                    scope:
                                                                      "col",
                                                                  },
                                                                },
                                                                [_vm._v("值")]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "tbody",
                                                        _vm._l(
                                                          _vm.form
                                                            .policyPlatformList,
                                                          function (
                                                            pitem,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "tr",
                                                              { key: index },
                                                              [
                                                                _c("td", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      pitem.platformName
                                                                    )
                                                                  ),
                                                                ]),
                                                                _c("td", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      pitem.platformValue
                                                                    )
                                                                  ),
                                                                ]),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      ),
                                                    ]
                                                  ),
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-warning-outline",
                                                    staticStyle: {
                                                      "font-size": "16px",
                                                      margin: "0 5px",
                                                      cursor: "pointer",
                                                    },
                                                    attrs: {
                                                      slot: "reference",
                                                    },
                                                    slot: "reference",
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(") "),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticStyle: { "padding-left": "15px" } },
                                  [
                                    _c("span", [
                                      _vm._v("车船税："),
                                      _c(
                                        "span",
                                        { staticClass: "amount-text-color" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.form.vehicleTaxPremium
                                                ? _vm.form.vehicleTaxPremium.toLocaleString()
                                                : "0.00"
                                            )
                                          ),
                                        ]
                                      ),
                                      _vm._v("元 "),
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#797979" } },
                                        [
                                          _vm._v(
                                            "（当年应缴：" +
                                              _vm._s(
                                                _vm.form.policyVehicleTax &&
                                                  _vm.form.policyVehicleTax
                                                    .curYearTax
                                                  ? _vm.form.policyVehicleTax
                                                      .curYearTax
                                                  : "0.00"
                                              ) +
                                              "元 "
                                          ),
                                          _vm.form.policyVehicleTax &&
                                          _vm.form.policyVehicleTax.lastYearPay
                                            ? _c("span", [
                                                _vm._v(
                                                  "，往年欠缴：" +
                                                    _vm._s(
                                                      _vm.form
                                                        .policyVehicleTax &&
                                                        _vm.form
                                                          .policyVehicleTax
                                                          .lastYearPay
                                                        ? _vm.form
                                                            .policyVehicleTax
                                                            .lastYearPay
                                                        : "0.00"
                                                    ) +
                                                    "元"
                                                ),
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.form.policyVehicleTax &&
                                          _vm.form.policyVehicleTax
                                            .delayPayMoney
                                            ? _c("span", [
                                                _vm._v(
                                                  "，滞纳金：" +
                                                    _vm._s(
                                                      _vm.form
                                                        .policyVehicleTax &&
                                                        _vm.form
                                                          .policyVehicleTax
                                                          .delayPayMoney
                                                        ? _vm.form
                                                            .policyVehicleTax
                                                            .delayPayMoney
                                                        : "0.00"
                                                    ) +
                                                    "元 "
                                                ),
                                              ])
                                            : _vm._e(),
                                          _vm.form.policyVehicleTax &&
                                          _vm.form.policyVehicleTax.taxType
                                            ? _c("span", [
                                                _vm._v(
                                                  ", 纳税类型：" +
                                                    _vm._s(
                                                      _vm.getTaxTypeName(
                                                        _vm.form
                                                          .policyVehicleTax
                                                          .taxType
                                                      )
                                                    )
                                                ),
                                              ])
                                            : _vm._e(),
                                          _vm._v(")"),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.form.riskType == "1" ||
                    (_vm.form.couponActivityName &&
                      _vm.form.couponActivityRuleName &&
                      _vm.form.couponActivityRuleCode)
                      ? _c(
                          "el-card",
                          {
                            staticStyle: { "margin-top": "10px" },
                            attrs: { shadow: "never" },
                          },
                          [
                            _vm.form.riskType == "1"
                              ? _c("div", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "title",
                                      staticStyle: { "line-height": "25px" },
                                    },
                                    [
                                      _c("FormLabel", {
                                        attrs: {
                                          "form-label": "投保险种及保费",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "margin-top": "10px",
                                        "font-size": "14px",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "line-height": "30px",
                                            "padding-left": "15px",
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v("商业险保费: "),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "amount-text-color",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    (_vm.form.actualPremium &&
                                                      _vm.form.actualPremium.toLocaleString()) ||
                                                      "0.00"
                                                  )
                                                ),
                                              ]
                                            ),
                                            _vm._v("元 "),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "amount-description-color",
                                              },
                                              [
                                                _vm._v(
                                                  " (" +
                                                    _vm._s(
                                                      _vm.form.riskTypeName ||
                                                        "-"
                                                    ) +
                                                    "折扣系数:" +
                                                    _vm._s(
                                                      _vm.form.discountFactor ||
                                                        "-"
                                                    ) +
                                                    " "
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      "padding-left": "10px",
                                                    },
                                                  },
                                                  [_vm._v("商业险平台信息: ")]
                                                ),
                                                _vm._.isEmpty(
                                                  _vm.form.policyPlatformList
                                                )
                                                  ? _c("span", [_vm._v("无")])
                                                  : _vm._e(),
                                                !_vm._.isEmpty(
                                                  _vm.form.policyPlatformList
                                                )
                                                  ? _c(
                                                      "el-popover",
                                                      {
                                                        attrs: {
                                                          placement: "bottom",
                                                          width: "240",
                                                          trigger: "click",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "table",
                                                          {
                                                            staticStyle: {
                                                              width: "100%",
                                                            },
                                                          },
                                                          [
                                                            _c("colgroup", [
                                                              _c("col", {
                                                                staticStyle: {
                                                                  width: "70%",
                                                                },
                                                              }),
                                                              _c("col", {
                                                                staticStyle: {
                                                                  width: "30%",
                                                                },
                                                              }),
                                                            ]),
                                                            _c(
                                                              "thead",
                                                              {
                                                                staticStyle: {
                                                                  "background-color":
                                                                    "#f5f5f5",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "tr",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        "font-size":
                                                                          "13px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "th",
                                                                      {
                                                                        attrs: {
                                                                          scope:
                                                                            "col",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "名称"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "th",
                                                                      {
                                                                        attrs: {
                                                                          scope:
                                                                            "col",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "值"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "tbody",
                                                              _vm._l(
                                                                _vm.form
                                                                  .policyPlatformList,
                                                                function (
                                                                  pitem,
                                                                  index
                                                                ) {
                                                                  return _c(
                                                                    "tr",
                                                                    {
                                                                      key: index,
                                                                    },
                                                                    [
                                                                      _c("td", [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            pitem.platformName
                                                                          )
                                                                        ),
                                                                      ]),
                                                                      _c("td", [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            pitem.platformValue
                                                                          )
                                                                        ),
                                                                      ]),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              0
                                                            ),
                                                          ]
                                                        ),
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-warning-outline",
                                                          staticStyle: {
                                                            "font-size": "16px",
                                                            margin: "0 5px",
                                                            cursor: "pointer",
                                                          },
                                                          attrs: {
                                                            slot: "reference",
                                                          },
                                                          slot: "reference",
                                                        }),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(") "),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "margin-top": "10px",
                                        "padding-left": "15px",
                                        "padding-right": "15px",
                                        "font-size": "14px",
                                      },
                                    },
                                    [
                                      _c("InsuranceDetail", {
                                        attrs: {
                                          title: "险别",
                                          currentInsCrop: _vm.form,
                                          "duty-data": _vm.dutyList,
                                          policy: true,
                                          source: _vm.form.source,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                            _vm.form.couponActivityName ||
                            _vm.form.couponActivityRuleName ||
                            _vm.form.couponActivityRuleCode
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "margin-top": "10px",
                                      "padding-left": "15px",
                                      "padding-right": "15px",
                                      "font-size": "14px",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "margin-top": "10px",
                                          "font-size": "14px",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "line-height": "30px",
                                              "padding-left": "15px",
                                            },
                                          },
                                          [_c("span", [_vm._v("厂家政策信息")])]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "ActivityPolicy",
                                      _vm._b(
                                        {},
                                        "ActivityPolicy",
                                        _vm.form,
                                        false
                                      )
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        )
                      : _vm._e(),
                    _vm.form.riskType == "2"
                      ? _c(
                          "el-card",
                          {
                            staticStyle: { "margin-top": "10px" },
                            attrs: { shadow: "never" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "title",
                                staticStyle: { "line-height": "25px" },
                              },
                              [
                                _c("FormLabel", {
                                  attrs: { "form-label": "投保险种及保费" },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "margin-top": "10px",
                                  "font-size": "14px",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "line-height": "30px",
                                      "padding-left": "15px",
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v("驾意险保费: "),
                                      _c(
                                        "span",
                                        { staticClass: "amount-text-color" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              (_vm.form.actualPremium &&
                                                _vm.form.actualPremium.toLocaleString()) ||
                                                "0.00"
                                            )
                                          ),
                                        ]
                                      ),
                                      _vm._v("元 "),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "margin-top": "10px",
                                  "padding-left": "15px",
                                  "padding-right": "15px",
                                  "font-size": "14px",
                                },
                              },
                              [
                                _c("InsuranceDetail", {
                                  attrs: {
                                    title: "险别",
                                    "duty-data": _vm.dutyList,
                                    policy: true,
                                    formRiskType: _vm.form.riskType,
                                    source: _vm.form.source,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                    !_vm._.isEmpty(_vm.form.policySpecialterms)
                      ? _c(
                          "el-card",
                          {
                            staticStyle: { "margin-top": "10px" },
                            attrs: { shadow: "never" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "title",
                                staticStyle: { "line-height": "25px" },
                              },
                              [
                                _c("FormLabel", {
                                  attrs: { "form-label": "特别约定" },
                                }),
                              ],
                              1
                            ),
                            _c("el-row", { attrs: { gutter: 20 } }, [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    background: "#f6f8ff",
                                    padding: "0 10px",
                                    "font-size": "13px",
                                    color: "#515151",
                                    margin: "0px 15px",
                                  },
                                },
                                _vm._l(
                                  _vm.form.policySpecialterms,
                                  function (item, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticStyle: {
                                          "border-bottom": "1px solid #dcdfe6",
                                          padding: "10px 0px",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "margin-bottom": "5px",
                                            },
                                          },
                                          [
                                            _c("span", [_vm._v("【")]),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "font-weight": "700",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    item.specialTermCode
                                                      ? item.specialTermCode
                                                      : ""
                                                  ) +
                                                    _vm._s(
                                                      item.specialTermName
                                                        ? item.specialTermName
                                                        : ""
                                                    )
                                                ),
                                              ]
                                            ),
                                            _c("span", [_vm._v("】")]),
                                          ]
                                        ),
                                        _c("div", [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                item.specialTermText
                                                  ? item.specialTermText
                                                  : ""
                                              )
                                            ),
                                          ]),
                                        ]),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.form.policyVerifyResult
                      ? _c(
                          "el-card",
                          {
                            staticStyle: { "margin-top": "10px" },
                            attrs: { shadow: "never" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "title",
                                staticStyle: { "line-height": "25px" },
                              },
                              [
                                _c("FormLabel", {
                                  attrs: { "form-label": "保单验证结果" },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-row",
                              { attrs: { gutter: 0 } },
                              [
                                _c("el-col", { attrs: { span: 8 } }, [
                                  _c("div", { staticClass: "card_item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "card_item_label" },
                                      [_vm._v("保单验证结果：")]
                                    ),
                                    _c(
                                      "span",
                                      { staticStyle: { "text-align": "left" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.form
                                              .policyVerifyResultDisplay || "-"
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                                _vm.form.policyVerifyResult &&
                                _vm.form.policyVerifyResult == "PVR02"
                                  ? _c("el-col", { attrs: { span: 8 } }, [
                                      _c("div", { staticClass: "card_item" }, [
                                        _c(
                                          "div",
                                          { staticClass: "card_item_label" },
                                          [_vm._v("失败原因：")]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "text-align": "left",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.form.policyVerifyReason ||
                                                  "-"
                                              )
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-card",
                      {
                        staticStyle: { "margin-top": "10px" },
                        attrs: { shadow: "never" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "title",
                            staticStyle: { "line-height": "25px" },
                          },
                          [
                            _c("FormLabel", {
                              attrs: { "form-label": "出单信息" },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          { attrs: { gutter: 0 } },
                          [
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c("div", { staticClass: "card_item" }, [
                                _c("div", { staticClass: "card_item_label" }, [
                                  _vm._v("出单网点："),
                                ]),
                                _c(
                                  "span",
                                  { staticStyle: { "text-align": "left" } },
                                  [_vm._v(_vm._s(_vm.form.salesOrgName || "-"))]
                                ),
                              ]),
                            ]),
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c("div", { staticClass: "card_item" }, [
                                _c("div", { staticClass: "card_item_label" }, [
                                  _vm._v("出单人："),
                                ]),
                                _c(
                                  "span",
                                  { staticStyle: { "text-align": "left" } },
                                  [_vm._v(_vm._s(_vm.form.operatorName || "-"))]
                                ),
                              ]),
                            ]),
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c("div", { staticClass: "card_item" }, [
                                _c("div", { staticClass: "card_item_label" }, [
                                  _vm._v("业务员："),
                                ]),
                                _c(
                                  "span",
                                  { staticStyle: { "text-align": "left" } },
                                  [_vm._v(_vm._s(_vm.form.salemanName || "-"))]
                                ),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "el-row",
                          { attrs: { gutter: 0 } },
                          [
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c("div", { staticClass: "card_item" }, [
                                _c("div", { staticClass: "card_item_label" }, [
                                  _vm._v("承保日期："),
                                ]),
                                _c(
                                  "span",
                                  { staticStyle: { "text-align": "left" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.form.insuredTime
                                          ? _vm.form.insuredTime.split(" ")[0]
                                          : "-"
                                      )
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                            _c("el-col", { attrs: { span: 8 } }, [
                              _c("div", { staticClass: "card_item" }, [
                                _c("div", { staticClass: "card_item_label" }, [
                                  _vm._v("操作机构："),
                                ]),
                                _c(
                                  "span",
                                  { staticStyle: { "text-align": "left" } },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.form.operateOrgName || "-")
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-card",
                      {
                        staticStyle: { "margin-top": "10px" },
                        attrs: { shadow: "never" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "title",
                            staticStyle: { "line-height": "25px" },
                          },
                          [
                            _c("FormLabel", {
                              attrs: { "form-label": "保单列表" },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "table",
                          {
                            staticStyle: {
                              width: "100%",
                              "margin-top": "10px",
                            },
                            attrs: { border: "1" },
                          },
                          [
                            _c("colgroup", [
                              _c("col", { staticStyle: { width: "47.5%" } }),
                            ]),
                            _c(
                              "thead",
                              { staticStyle: { "background-color": "#eeee" } },
                              [
                                _c(
                                  "tr",
                                  { staticStyle: { "font-size": "13px" } },
                                  [
                                    _c("th", { attrs: { scope: "col" } }, [
                                      _vm._v("文件名"),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "tbody",
                              _vm._l(
                                _vm.form.policyDocuments,
                                function (item, index) {
                                  return _c("tr", { key: index }, [
                                    _c(
                                      "td",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.handle(item)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "file-text",
                                            attrs: { title: item.fileName },
                                          },
                                          [_vm._v(_vm._s(item.fileName))]
                                        ),
                                      ]
                                    ),
                                  ])
                                }
                              ),
                              0
                            ),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
            _c("div", { staticClass: "footer-btn" }, [
              _c(
                "div",
                { staticClass: "footer-btn-list" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", icon: "el-icon-back" },
                      on: { click: _vm.goBack },
                    },
                    [_vm._v("返回")]
                  ),
                  _vm._.toLower(_vm.form.insCorpCode) != "picc" &&
                  _vm._.toLower(_vm.form.insCorpCode) != "cpic"
                    ? _c(
                        "el-button",
                        {
                          staticStyle: { margin: "0px 5px" },
                          attrs: {
                            slot: "reference",
                            size: "mini",
                            type: "primary",
                          },
                          on: { click: _vm.download },
                          slot: "reference",
                        },
                        [_vm._v(" 查看/下载电子单证 ")]
                      )
                    : _vm._e(),
                  _vm.form && _vm.form.relationPolicyId
                    ? _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "primary" },
                          on: { click: _vm.viewInsPolicy },
                        },
                        [
                          _vm._v(
                            "查看" +
                              _vm._s(
                                _vm.form.riskType == "1" ? "交强险" : "商业险"
                              ) +
                              "保单"
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "el-popover",
                    { attrs: { placement: "top", trigger: "click" } },
                    [
                      _c(
                        "div",
                        { staticStyle: { width: "480px" } },
                        _vm._l(_vm.correctionList, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "coritem",
                              staticStyle: {
                                width: "100%",
                                display: "flex",
                                "justify-content": "space-between",
                                padding: "8px 5px",
                                cursor: "pointer",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.viewCorrectionItem(item)
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    color: "#2150A3",
                                    width: "310px",
                                    display: "flex",
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(item.endorsementCode)),
                                  ]),
                                  _vm._v(" ("),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        width: "60px",
                                        display: "block",
                                        overflow: "hidden",
                                        "text-overflow": "ellipsis",
                                        "white-space": "nowrap",
                                        "text-angli": "center",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            effect: "dark",
                                            content: item.endorsementTypeName,
                                            placement: "top-start",
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(item.endorsementTypeName)
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(") "),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    color: "#999",
                                    "font-size": "13px",
                                  },
                                },
                                [
                                  _c("span", [_vm._v("批改生效时间：")]),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        item.effectiveStartTime.slice(0, 10)
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                      _vm.correctionList && _vm.correctionList.length
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                slot: "reference",
                                size: "mini",
                                type: "primary",
                              },
                              on: { click: _vm.viewCorrection },
                              slot: "reference",
                            },
                            [_vm._v(" 查看批单 ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showImgDialog,
            "close-on-click-modal": false,
            title: "影像资料",
            width: "740px",
            "modal-append-to-body": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showImgDialog = $event
            },
            handleClose: _vm.cancel,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "16px", "font-weight": "700" } },
                [_vm._v("影像资料")]
              ),
            ]
          ),
          _c("ViewImgForm", {
            attrs: { data: _vm.imgList, refresh: _vm.showImgDialog },
            on: { closePage: _vm.cancel },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }