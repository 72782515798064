var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.formLoading,
          expression: "formLoading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: {},
    },
    [
      _c("div", {
        ref: "header",
        staticClass: "header-cls",
        staticStyle: { "max-height": "540px", overflow: "auto" },
      }),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "right",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: { click: _vm.closePage },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }