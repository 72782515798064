var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "mapBackgroundImg",
      staticStyle: { color: "white" },
      attrs: { id: "noVechileMap-container" },
    },
    [
      _c("div", { staticClass: "containerHeaderBox" }, [
        _c(
          "div",
          { staticClass: "headerLeft" },
          [
            _c(
              "el-radio-group",
              {
                staticClass: "radio_group",
                attrs: { fill: "#00579c" },
                on: { change: _vm.handleRadio },
                model: {
                  value: _vm.tabPosition,
                  callback: function ($$v) {
                    _vm.tabPosition = $$v
                  },
                  expression: "tabPosition",
                },
              },
              [
                _c("el-radio-button", { attrs: { label: "day" } }, [
                  _vm._v("今天"),
                ]),
                _c(
                  "el-radio-button",
                  {
                    staticStyle: { margin: "0 2px" },
                    attrs: { label: "week" },
                  },
                  [_vm._v("本周")]
                ),
                _c("el-radio-button", { attrs: { label: "month" } }, [
                  _vm._v("本月"),
                ]),
                _c(
                  "el-radio-button",
                  {
                    staticStyle: { margin: "0 2px" },
                    attrs: { label: "year" },
                  },
                  [_vm._v("本年")]
                ),
              ],
              1
            ),
            _c(
              "span",
              {
                staticStyle: {
                  margin: "0 3px",
                  color: "#f9f9f9",
                  opacity: "0.5",
                },
              },
              [_vm._v("|")]
            ),
            _c(
              "el-select",
              {
                staticClass: "select-style",
                staticStyle: { width: "35%", "margin-left": "10px" },
                attrs: { "popper-append-to-body": false },
                on: { change: _vm.changeStoreType },
                model: {
                  value: _vm.productType,
                  callback: function ($$v) {
                    _vm.productType = $$v
                  },
                  expression: "productType",
                },
              },
              [
                _c("el-option", {
                  attrs: { label: "非车险业务(全部)", value: "" },
                }),
                _vm._l(_vm.productTypeList, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.itemName, value: item.itemCode },
                  })
                }),
              ],
              2
            ),
          ],
          1
        ),
        _vm.isNoCar
          ? _c("div", { staticClass: "headerTitle" }, [_vm._v("非车险驾驶舱")])
          : _c("div", { staticClass: "headerTitle" }, [
              _vm._v("非车险业务业务看板"),
            ]),
        _c(
          "div",
          { staticClass: "headerRight" },
          [
            _c(
              "el-radio-group",
              {
                staticClass: "radio_group",
                attrs: { fill: "#00579c" },
                on: { change: _vm.changeStoreType },
                model: {
                  value: _vm.organizationStoreTypeCode,
                  callback: function ($$v) {
                    _vm.organizationStoreTypeCode = $$v
                  },
                  expression: "organizationStoreTypeCode",
                },
              },
              [
                _c(
                  "el-radio-button",
                  {
                    staticStyle: { "margin-left": "2px" },
                    attrs: { label: "" },
                  },
                  [_vm._v("全部")]
                ),
                _c(
                  "el-radio-button",
                  { staticStyle: { margin: "0 2px" }, attrs: { label: "1" } },
                  [_vm._v("直营店")]
                ),
                _c("el-radio-button", { attrs: { label: "2" } }, [
                  _vm._v("加盟店"),
                ]),
              ],
              1
            ),
            _c(
              "span",
              {
                staticStyle: {
                  margin: "0 10px",
                  color: "#f9f9f9",
                  opacity: "0.5",
                },
              },
              [_vm._v("|")]
            ),
            _c(
              "span",
              { staticStyle: { "margin-right": "15px" } },
              [
                _vm._v("当前位置： "),
                _c(
                  "el-link",
                  {
                    staticStyle: {
                      "font-weight": "normal",
                      color: "#f7f7f7",
                      "margin-bottom": "2px",
                    },
                    attrs: { underline: false },
                    on: {
                      click: function ($event) {
                        return _vm.switchMap(_vm.defaultCity)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.defaultCity))]
                ),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.currentCity,
                        expression: "currentCity",
                      },
                    ],
                  },
                  [_vm._v(" | ")]
                ),
                _c(
                  "el-link",
                  {
                    staticStyle: {
                      "font-weight": "normal",
                      color: "#f7f7f7",
                      "margin-bottom": "2px",
                    },
                    attrs: { underline: false },
                    on: {
                      click: function ($event) {
                        return _vm.switchMap(_vm.currentCity)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.currentCity))]
                ),
              ],
              1
            ),
            _c(
              "el-tooltip",
              {
                attrs: {
                  content: "" + (!_vm.isFullscreen ? "全屏" : "退出全屏"),
                  effect: "dark",
                  placement: "bottom",
                },
              },
              [_c("div", { staticStyle: { width: "20px", height: "20px" } })]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        {
          staticStyle: { position: "relative" },
          style: {
            "overflow-y": "auto",
            padding: "10px",
            "min-height": "100vh",
            "overflow-y": "auto",
          },
        },
        [
          _c(
            "el-row",
            {
              staticStyle: {
                width: "100%",
                position: "absolute",
                top: "0",
                left: "0%",
                "z-index": "1",
                display: "flex",
                "justify-content": "center",
                "align-items": "center",
              },
            },
            [
              _c(
                "el-col",
                { staticStyle: { position: "relative" }, attrs: { span: 24 } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "grid-content bg-purple-dark",
                      staticStyle: { color: "white," },
                    },
                    [
                      _c(
                        "el-row",
                        {
                          staticStyle: {
                            padding: "5px",
                            width: "40%",
                            margin: "10px auto",
                          },
                        },
                        [
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c(
                              "div",
                              { staticStyle: { "text-align": "center" } },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "font-size": "14px",
                                      opacity: "0.8",
                                      color: "#9bb6df",
                                    },
                                  },
                                  [_vm._v("出单总量")]
                                ),
                                _c("div", { staticClass: "QuantityBox" }, [
                                  _c(
                                    "div",
                                    {
                                      ref: "totalQuantitysNum",
                                      staticStyle: { position: "relative" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.totalQuantity
                                              ? _vm.totalQuantity.toLocaleString()
                                              : 0
                                          ) +
                                          " "
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "addQuantity animated",
                                          class: _vm.animateTime
                                            ? "fadeInUp"
                                            : "fadeOutUp",
                                          style: {
                                            left: _vm.totalQuantitysleft,
                                          },
                                        },
                                        [_vm._v("+" + _vm._s(_vm.oldQuantity))]
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                          _c(
                            "el-col",
                            { staticClass: "PremiumBox", attrs: { span: 12 } },
                            [
                              _c(
                                "div",
                                { staticStyle: { "text-align": "center" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "font-size": "14px",
                                        opacity: "0.8",
                                        color: "#9bb6df",
                                      },
                                    },
                                    [_vm._v("总营收(元)")]
                                  ),
                                  _c("div", { staticClass: "QuantityBox" }, [
                                    _c(
                                      "div",
                                      {
                                        ref: "totalPremiumsNum",
                                        staticStyle: { position: "relative" },
                                      },
                                      [
                                        _vm._v(
                                          " ￥ " +
                                            _vm._s(
                                              _vm.totalPremium
                                                ? Number(
                                                    _vm.totalPremium.toFixed(0)
                                                  ).toLocaleString()
                                                : "0"
                                            ) +
                                            " "
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "addQuantity animated",
                                            class: _vm.animateTime2
                                              ? "fadeInUp"
                                              : "fadeOutUp",
                                            style: {
                                              left: _vm.totalPremiumsNumleft,
                                            },
                                          },
                                          [_vm._v("+" + _vm._s(_vm.oldPremium))]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-card",
                    {
                      staticClass: "box-card",
                      staticStyle: {
                        border: "1px solid #173551",
                        background: "#163456",
                        height: "300px",
                        color: "white",
                      },
                      attrs: { shadow: "never" },
                    },
                    [
                      _c("div", { staticClass: "chart-wrapper" }, [
                        _c(
                          "div",
                          {
                            staticClass: "header",
                            staticStyle: { "font-weight": "700" },
                          },
                          [
                            _c("span", { staticClass: "headerLine" }),
                            _vm._v("总出单量走势"),
                          ]
                        ),
                        _c(
                          "div",
                          { staticStyle: { padding: "10px" } },
                          [
                            _c("line-smooth-chart", {
                              attrs: {
                                xAxis: _vm.xlineData,
                                series: _vm.seriesLineData,
                                height: "260px",
                                "series-name": "出单量",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-card",
                    {
                      staticClass: "box-card",
                      staticStyle: {
                        border: "1px solid #173551",
                        background: "#163456",
                        "margin-top": "30px",
                        height: "300px",
                        color: "white",
                      },
                      attrs: { shadow: "never" },
                    },
                    [
                      _c("div", { staticClass: "chart-wrapper" }, [
                        _c(
                          "div",
                          {
                            staticClass: "header",
                            staticStyle: { "font-weight": "700" },
                          },
                          [
                            _c("span", { staticClass: "headerLine" }),
                            _vm._v("总营收走势"),
                          ]
                        ),
                        _c(
                          "div",
                          { staticStyle: { padding: "10px" } },
                          [
                            _c("line-smooth-chart", {
                              attrs: {
                                xAxis: _vm.xlineData,
                                series: _vm.seriesLineData1,
                                height: "260px",
                                "series-name": "出单量",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                {
                  staticStyle: { position: "absolute", width: "100%" },
                  attrs: { span: 24 },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "grid-content bg-purple-dark",
                      staticStyle: { color: "white," },
                    },
                    [
                      _c("ChinaMapChart", {
                        ref: "noVechileChinaMap",
                        attrs: {
                          id: _vm.mapId,
                          "chart-data": _vm.mapData,
                          width: _vm.mapW,
                          height: _vm.mapH,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-col",
                {
                  staticStyle: {
                    "min-width": "300px",
                    position: "absolute",
                    right: "0px",
                    top: "0",
                  },
                  attrs: { span: 5 },
                },
                [
                  _c(
                    "el-card",
                    {
                      staticClass: "box-card",
                      staticStyle: {
                        border: "1px solid #173551",
                        background: "#163456",
                        height: "300px",
                        color: "white",
                      },
                      attrs: { shadow: "never" },
                    },
                    [
                      _c("div", { staticClass: "chart-wrapper" }, [
                        _c(
                          "div",
                          {
                            staticClass: "header",
                            staticStyle: { "font-weight": "700" },
                          },
                          [
                            _c("span", { staticClass: "headerLine" }),
                            _vm._v("营收TOP5"),
                          ]
                        ),
                        !_vm._.isEmpty(_vm.premiumData)
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  padding: "0 5px",
                                  "font-size": "12px",
                                  "overflow-y": "auto",
                                  "max-height": "260px",
                                },
                              },
                              _vm._l(_vm.premiumData, function (item, index) {
                                return _c("div", { key: index }, [
                                  _c(
                                    "p",
                                    {
                                      staticStyle: {
                                        padding: "6px 0",
                                        "border-bottom": "1px dashed #294664",
                                        display: "flex",
                                        "justify-content": "space-between",
                                      },
                                    },
                                    [
                                      _vm._v(_vm._s(item.orgName)),
                                      _c("span", [
                                        _vm._v(
                                          "￥ " +
                                            _vm._s(
                                              item.amount
                                                ? Number(
                                                    item.amount.toFixed(0)
                                                  ).toLocaleString()
                                                : "0"
                                            )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ])
                              }),
                              0
                            )
                          : _c(
                              "div",
                              {
                                staticStyle: {
                                  padding: "10px 5px",
                                  "box-sizing": "border-box",
                                  "font-size": "12px",
                                },
                              },
                              [_vm._v(" 暂无网点出单数据！ ")]
                            ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-card",
                    {
                      staticClass: "box-card",
                      staticStyle: {
                        border: "1px solid #173551",
                        background: "#163456",
                        "margin-top": "30px",
                        height: "300px",
                        color: "white",
                      },
                      attrs: { shadow: "never" },
                    },
                    [
                      _c("div", { staticClass: "chart-wrapper" }, [
                        _c(
                          "div",
                          {
                            staticClass: "header",
                            staticStyle: { "font-weight": "700" },
                          },
                          [
                            _c("span", { staticClass: "headerLine" }),
                            _vm._v("出单量TOP5"),
                          ]
                        ),
                        !_vm._.isEmpty(_vm.quantityData)
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  padding: "0 5px",
                                  "font-size": "12px",
                                  "overflow-y": "auto",
                                  "max-height": "260px",
                                },
                              },
                              _vm._l(_vm.quantityData, function (item, index) {
                                return _c("div", { key: index }, [
                                  _c(
                                    "p",
                                    {
                                      staticStyle: {
                                        padding: "6px 0",
                                        "border-bottom": "1px dashed #294664",
                                        display: "flex",
                                        "justify-content": "space-between",
                                      },
                                    },
                                    [
                                      _vm._v(_vm._s(item.orgName)),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            item.quantity
                                              ? item.quantity.toLocaleString()
                                              : "0"
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ])
                              }),
                              0
                            )
                          : _c(
                              "div",
                              {
                                staticStyle: {
                                  padding: "10px 5px",
                                  "box-sizing": "border-box",
                                  "font-size": "12px",
                                },
                              },
                              [_vm._v(" 暂无网点出单数据！ ")]
                            ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "box-card",
              staticStyle: {
                background: "#163456",
                border: "1px solid #173551",
                padding: "5px",
                "margin-top": "10px",
                "border-radius": "14px",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-weight": "700",
                            padding: "5px",
                            "line-height": "40px",
                            color: "white",
                            "font-size": "14px",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm._f("cumulativeText")(_vm.productType)) +
                              "累计出单"
                          ),
                        ]
                      ),
                      _c("noVechileLineCharts", {
                        attrs: {
                          chartData: _vm.noVechileData,
                          height: "280px",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-weight": "700",
                        padding: "5px",
                        "line-height": "40px",
                        color: "white",
                        "font-size": "14px",
                      },
                    },
                    [_vm._v("增值服务出单业务")]
                  ),
                  !["CW", "CY"].includes(_vm.productType)
                    ? _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c("pie-charts", {
                            attrs: {
                              legendColor: "#fff",
                              "chart-data": _vm.vehiclemodelData,
                              height: "260px",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "padding-left": "10px" },
                      attrs: { span: 6 },
                    },
                    [
                      _c("pie-charts", {
                        attrs: {
                          legendColor: "#fff",
                          "chart-data": _vm.servicepreiodData,
                          height: "260px",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }