var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.formLoading,
          expression: "formLoading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: { "max-height": "550px" },
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "500px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "detail",
                  staticStyle: { "margin-bottom": "-20px" },
                  attrs: {
                    model: _vm.form,
                    rules: _vm.formRules,
                    "label-width": "110px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 22 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "保险到期日：",
                                prop: "effectiveStartTime",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticStyle: { display: "flex" } },
                                [
                                  _c("DatePicker", {
                                    attrs: {
                                      noPickerOptions: "",
                                      pickerDateTime: _vm.startEndTime,
                                      startDate: "effectiveStartTime",
                                      endDate: "effectiveEndTime",
                                      width: "100%",
                                    },
                                    on: {
                                      pickerDateChange: function ($event) {
                                        return _vm.getPickersDate(
                                          "startEndTime",
                                          $event
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { "margin-left": "15px" },
                                      attrs: { plain: "" },
                                      on: { click: _vm.getDistributionType },
                                    },
                                    [_vm._v("批量任务分配")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._l(
                        _vm.customersTypeList.customerSysTypeSituation,
                        function (item, index) {
                          return _c(
                            "div",
                            { key: index },
                            [
                              _c(
                                "el-col",
                                {
                                  staticStyle: {
                                    margin: "15px",
                                    border: "1px solid #2150a3",
                                    color: "#2150a3",
                                    "border-radius": "5px",
                                    cursor: "pointer",
                                  },
                                  attrs: { span: 6 },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        padding: "5px 10px",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.selectCustomersType(item)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticStyle: { flex: "2" } },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-user",
                                            staticStyle: {
                                              "font-size": "30px",
                                            },
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticStyle: { flex: "4" } },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "font-size": "16px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  item.customerTypeEnumDisplay
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "padding-top": "5px",
                                              },
                                            },
                                            [_vm._v(_vm._s(item.cnt))]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        }
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm.isShow
                ? _c(
                    "el-row",
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            margin: "25px 0 15px 10px",
                          },
                        },
                        [
                          _c(
                            "span",
                            { staticStyle: { "margin-right": "10px" } },
                            [
                              _vm._v(
                                _vm._s(this.customersTypeList.cnt || 0) +
                                  "条任务的分配方式"
                              ),
                            ]
                          ),
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.distributionType,
                                callback: function ($$v) {
                                  _vm.distributionType = $$v
                                },
                                expression: "distributionType",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "LAST" } }, [
                                _vm._v("上年续保专员优先"),
                              ]),
                              _c("el-radio", { attrs: { label: "AVERAGE" } }, [
                                _vm._v("平均分配"),
                              ]),
                              _c("el-radio", { attrs: { label: "NUMBER" } }, [
                                _vm._v("数量分配"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      this.distributionType == "AVERAGE"
                        ? _c(
                            "el-checkbox",
                            {
                              staticStyle: { "margin-bottom": "10px" },
                              model: {
                                value: _vm.lastFlag,
                                callback: function ($$v) {
                                  _vm.lastFlag = $$v
                                },
                                expression: "lastFlag",
                              },
                            },
                            [
                              _vm._v(
                                "同时优先分配上年续保专员，（带有续保专员的任务将被直接分配给对应的续保专员，之后按平均分配）"
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.tableLoading,
                              expression: "tableLoading",
                            },
                          ],
                          ref: "multipleTable",
                          staticStyle: { width: "100%" },
                          attrs: {
                            "highlight-current-row": "",
                            size: "mini",
                            border: "",
                            data: _vm.tableData,
                            height: 500,
                            "header-cell-style": { background: "#F7F7F7" },
                          },
                          on: { "selection-change": _vm.handleSelectionChange },
                        },
                        [
                          _c("el-table-column", {
                            attrs: { type: "selection" },
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "", label: "序号", width: "100px" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm._v(
                                        " " + _vm._s(scope.$index + 1) + " "
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3056706777
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "userName", label: "续保专员" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            scope.row.userName +
                                              "(已有任务" +
                                              (scope.row.cnt || 0) +
                                              "条)"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              847768771
                            ),
                          }),
                          this.distributionType == "NUMBER"
                            ? _c("el-table-column", {
                                attrs: { label: "数量分配" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("el-input", {
                                            on: {
                                              input: function ($event) {
                                                scope.row.paramProportion =
                                                  _vm.onInput(
                                                    scope.row.paramProportion
                                                  )
                                              },
                                            },
                                            model: {
                                              value: scope.row.paramProportion,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "paramProportion",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.paramProportion",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2169733697
                                ),
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _vm.isShow
            ? _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-check",
                    type: "primary",
                    plain: "",
                    size: "mini",
                  },
                  on: { click: _vm.save },
                },
                [_vm._v("分配")]
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: {
                click: function ($event) {
                  return _vm.closePage(false)
                },
              },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }