var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.formLoading,
          expression: "formLoading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: {},
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "500px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "detail",
                  staticStyle: { "margin-bottom": "-20px" },
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-width": "105px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "车架号：", prop: "vin" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "17",
                                  placeholder: "请输入车架号",
                                  clearable: "",
                                },
                                on: { input: _vm.inputvin },
                                model: {
                                  value: _vm.form.vin,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "vin", $$v)
                                  },
                                  expression: "form.vin",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "服务包编码：",
                                prop: "servPackProCode",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入服务包编码",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.servPackProCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "servPackProCode", $$v)
                                  },
                                  expression: "form.servPackProCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "服务包名称：",
                                prop: "servPackProName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入服务包名称",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.servPackProName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "servPackProName", $$v)
                                  },
                                  expression: "form.servPackProName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "服务包价值：",
                                prop: "servPackProPrice",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入服务包价值",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.servPackProPrice,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "servPackProPrice", $$v)
                                  },
                                  expression: "form.servPackProPrice",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "项目编码：", prop: "itemCode" },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入项目编码",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.itemCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "itemCode", $$v)
                                  },
                                  expression: "form.itemCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "项目名称：", prop: "itemName" },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入项目名称",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.itemName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "itemName", $$v)
                                  },
                                  expression: "form.itemName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "项目额度：",
                                prop: "itemAmount",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入项目额度",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.itemAmount,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "itemAmount", $$v)
                                  },
                                  expression: "form.itemAmount",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "项目备注：",
                                prop: "itemRemark",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "100",
                                  type: "textarea",
                                  placeholder: "请输入项目备注",
                                  autosize: { minRows: 4, maxRows: 4 },
                                  resize: "none",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.itemRemark,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "itemRemark", $$v)
                                  },
                                  expression: "form.itemRemark",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-check",
                type: "primary",
                plain: "",
                size: "mini",
              },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: {
                click: function ($event) {
                  return _vm.closePage(true)
                },
              },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }