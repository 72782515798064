import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.keys.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import dict from '@/mixins/dict';
import Pagination from '@/components/Pagination';
import DatePicker from '@/components/DatePicker';
import OemSelect from '@/components/OemSelect';
import activityCenterRequest from '@/api/activityCenter';
import customersRequest from "@/api/customers";
import { commonExport, debounce } from '@/utils/common';
export default {
  name: "Grant",
  components: {
    Pagination: Pagination,
    DatePicker: DatePicker,
    OemSelect: OemSelect
  },
  mixins: [initHeight, dict],
  computed: {},
  data: function data() {
    return {
      orgForm: {
        salesOrgCode: '',
        salesOrgName: ''
      },
      receiveTime: [],
      searchForm: {},
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      loading: false,
      bizType: localStorage.getItem('bizType') || '',
      startEndTime: [],
      allActivity: []
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.getUserInfo();
    this.loadData();
    this.getAllActivityManagementList();
  },
  methods: {
    //获取车销
    getVehicleSalesByVin: function getVehicleSalesByVin(item) {
      var _this = this;
      item.isLoading = true;
      var vehicleData = {
        vin: item.vin || '',
        cacheSwitch: true
      };
      customersRequest.getVehicleSales(vehicleData).then(function (res) {
        if (res.code == 200) {
          var orgCode = item.orgCode,
            vin = item.vin,
            couponReceiveId = item.couponReceiveId;
          var postData = {
            orgCode: orgCode,
            vin: vin,
            couponReceiveId: couponReceiveId
          };
          activityCenterRequest.getReceiveapi(postData).then(function (result) {
            item.isLoading = false;
            if (result.code == 200) {
              _this.loadData();
              _this.$message.success(res.msg);
            }
          }).catch(function () {
            return item.isLoading = false;
          });
        } else {
          item.isLoading = false;
        }
      }).catch(function () {
        return item.isLoading = false;
      });
    },
    //参加政策
    reissueCoupon: function reissueCoupon(item) {
      var _this2 = this;
      item.isReissueLoading = true;
      var orgCode = item.orgCode,
        vin = item.vin,
        couponReceiveId = item.couponReceiveId;
      var postData = {
        orgCode: orgCode,
        vin: vin,
        couponReceiveId: couponReceiveId
      };
      activityCenterRequest.getReceiveapi(postData).then(function (result) {
        item.isReissueLoading = false;
        if (result.code == 200) {
          _this2.loadData();
          _this2.$message.success(res.msg);
        }
      }).catch(function () {
        return item.isReissueLoading = false;
      });
    },
    // 获取所有的政策
    getAllActivityManagementList: function getAllActivityManagementList() {
      var _this3 = this;
      activityCenterRequest.getAllActivityManagementList().then(function (res) {
        if (res.code == 200) {
          _this3.allActivity = res.data.map(function (item) {
            return {
              dictName: item.couponActivityName,
              dictCode: item.id
            };
          });
        }
      });
    },
    // 查看保单详情
    viewPolicyDetail: function viewPolicyDetail(item) {
      this.$router.push({
        path: "/policy/detail",
        query: {
          plateNo: item.plateNo,
          vin: item.vin,
          policyId: item.policyId,
          policyNo: item.policyNo
        }
      });
    },
    // 日期选择事件
    getPickersDate: function getPickersDate(name, val) {
      if (val && val.length > 1) {
        this[name] = val;
      } else {
        this[name] = [];
      }
    },
    getUserInfo: function getUserInfo() {
      if (this.bizType === 'OEM') return;
      var useInfo = JSON.parse(localStorage.getItem("userInfo")) || {};
      if (!_.isEmpty(useInfo)) {
        this.searchForm.orgCode = useInfo.data.orgCode;
        // this.searchForm.orgName = useInfo.data.orgName;
        this.orgForm = {
          salesOrgCode: useInfo.data.orgCode,
          salesOrgName: useInfo.data.orgName
        };
      }
    },
    nodeClick: function nodeClick(data) {
      this.searchForm.orgCode = data.salesOrgCode;
      this.searchForm.areaType = data.salesOrgType;
    },
    deleteReceivingRecord: function deleteReceivingRecord(item) {
      var _this4 = this;
      if (item.couponActivitySettlementState == 'SETTLED') {
        this.$message.warning("该厂家政策已结算，不支持撤回政策；请联系一店一群小助手。");
        return;
      }
      this.$confirm("\u60A8\u786E\u5B9A\u8981\u5BF9\u8BE5\u8BB0\u5F55\u64A4\u56DE\u653F\u7B56\u5417\uFF1F", {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        type: 'warning'
      }).then(function () {
        activityCenterRequest.deleteReceivingRecord(item.couponReceiveId).then(function (res) {
          if (res.code == 200) {
            _this4.$message.success(res.msg);
            _this4.loadData();
          }
        });
      }).catch(function () {});
    },
    // 导出
    exportFn: function exportFn() {
      var exporParams = {
        total: this.total,
        limitNum: 200000,
        fileName: '政策发放',
        exportUrl: '/oc-core-service/couponreceive/search',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {};
      this.orgForm = {
        salesOrgCode: '',
        salesOrgName: ''
      };
      this.startEndTime = [];
      this.receiveTime = [];
      this.getUserInfo();
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    loadData: function loadData() {
      var _this5 = this;
      //获取列表数据
      this.loading = true;
      this.showImportDialog = false;
      this.showDialog = false;
      if (this.receiveTime) {
        this.searchForm.receiveTimeStart = this.receiveTime[0] ? this.receiveTime[0].split(' ')[0] + ' 00:00:00' : null;
        this.searchForm.receiveTimeEnd = this.receiveTime[1] ? this.receiveTime[1].split(' ')[0] + ' 23:59:59' : null;
      }
      activityCenterRequest.getReceivingRecordList(_objectSpread(_objectSpread({}, this.searchForm), this.listQuery)).then(function (res) {
        if (res.code === 200) {
          _this5.tableData = res.data.list && res.data.list.map(function (item) {
            return _objectSpread(_objectSpread({}, item), {}, {
              isLoading: false,
              isReissueLoading: false
            });
          });
          _this5.loading = false;
          _this5.total = res.data.page.recordsTotal;
        }
      }).catch(function (err) {
        _this5.loading = false;
      });
    },
    // 刷新
    breakData: function breakData() {
      // this.resetSearch()
      this.loadData();
    }
  }
};