var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "customer",
          attrs: {
            model: _vm.data,
            rules: _vm.formRules,
            "label-width": "110px",
            size: "mini",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 0 } },
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      ref: "name",
                      attrs: { label: _vm.label + "名称", prop: "name" },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { "margin-right": "10px" },
                        attrs: {
                          maxlength: "40",
                          placeholder: "请输入" + _vm.label + "名称",
                          clearable: false,
                          disabled: _vm.isDisabled,
                        },
                        model: {
                          value: _vm.data.name,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.data,
                              "name",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "data.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 16 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "special_row_content",
                      attrs: { label: _vm.label + "性质" },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { disabled: _vm.isDisabled },
                          on: { change: _vm.handlerelationshipTypeChange },
                          model: {
                            value: _vm.data.relationshipAttribute,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "relationshipAttribute", $$v)
                            },
                            expression: "data.relationshipAttribute",
                          },
                        },
                        _vm._l(_vm.CUST_NATUER, function (item, index) {
                          return _c(
                            "el-radio",
                            { key: index, attrs: { label: item.dictCode } },
                            [_vm._v(_vm._s(item.dictName))]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 0 } },
            [
              _c(
                "el-col",
                { attrs: { span: 16 } },
                [
                  _c(
                    "el-form-item",
                    {
                      ref: "certificateNo",
                      staticClass: "special_row_content",
                      attrs: { label: "证件类型", prop: "certificateNo" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "special_col_one",
                          staticStyle: { display: "flex" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "double-input",
                              staticStyle: { width: "210px" },
                              attrs: {
                                filterable: "",
                                disabled: _vm.isDisabled,
                                placeholder: "请选择证件类型",
                                clearable: false,
                              },
                              on: { change: _vm.changeValue },
                              model: {
                                value: _vm.data.certificateType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.data, "certificateType", $$v)
                                },
                                expression: "data.certificateType",
                              },
                            },
                            _vm._l(_vm.cardTypeOptions, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: {
                                  label: item.dictName,
                                  value: item.dictCode,
                                },
                              })
                            }),
                            1
                          ),
                          _c("el-input", {
                            staticClass: "input-with-select",
                            style: { "max-width": _vm.idCardNoWidth + "px" },
                            attrs: {
                              maxlength: "20",
                              disabled: _vm.isDisabled,
                              placeholder: "请输入证件号码",
                              clearable: "",
                            },
                            on: {
                              blur: function ($event) {
                                return _vm.certificateNoChange(_vm.data)
                              },
                              input: _vm.inputCardNo,
                            },
                            model: {
                              value: _vm.data.certificateNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "certificateNo", $$v)
                              },
                              expression: "data.certificateNo",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm.data.relationshipFlag
                ? _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          ref: "mobile",
                          attrs: {
                            label:
                              _vm.data.relationshipAttribute == "0"
                                ? "联系电话"
                                : "座机",
                            prop: "mobile",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "special_col_one",
                              staticStyle: { display: "flex" },
                            },
                            [
                              _c("el-input", {
                                staticClass: "double-input",
                                staticStyle: {
                                  "margin-right": "10px",
                                  width: "100%",
                                },
                                attrs: {
                                  type: "text",
                                  onkeyup:
                                    "value=value.replace(/[^0-9a-zA-Z\\-.]+/,'')",
                                  maxlength: "13",
                                  placeholder:
                                    "" +
                                    (_vm.data.relationshipAttribute == "0"
                                      ? "请输入手机号"
                                      : "例如：区号-12345678"),
                                  clearable: false,
                                  disabled: _vm.isDisabled,
                                },
                                on: {
                                  blur: function ($event) {
                                    return _vm.mobileChange(_vm.data)
                                  },
                                },
                                model: {
                                  value: _vm.data.mobile,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "mobile", $$v)
                                  },
                                  expression: "data.mobile",
                                },
                              }),
                              _vm.data.relationshipAttribute == "0" &&
                              _vm.showMobileHolder
                                ? _c(
                                    "el-checkbox",
                                    {
                                      attrs: { disabled: _vm.isDisabled },
                                      on: { change: _vm.checkChange },
                                      model: {
                                        value: _vm.data.isNotSelf,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.data, "isNotSelf", $$v)
                                        },
                                        expression: "data.isNotSelf",
                                      },
                                    },
                                    [_vm._v("非本人手机号")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          !_vm.data.relationshipFlag
            ? _c(
                "el-row",
                { attrs: { gutter: 0 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          ref: "ownerCertStartDate",
                          attrs: {
                            label: "证件起止日期",
                            prop: "ownerCertStartDate",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { display: "flex" } },
                            [
                              _c(
                                "div",
                                { staticStyle: { display: "flex" } },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      placeholder: "证件有效起期",
                                      type: "date",
                                      format: "yyyy-MM-dd",
                                      "value-format": "yyyy-MM-dd HH:mm:ss",
                                      "picker-options": _vm.ownerDisabledStart,
                                      disabled: _vm.isDisabled,
                                    },
                                    model: {
                                      value: _vm.data.ownerCertStartDate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.data,
                                          "ownerCertStartDate",
                                          $$v
                                        )
                                      },
                                      expression: "data.ownerCertStartDate",
                                    },
                                  }),
                                  _c(
                                    "span",
                                    { staticStyle: { margin: "0 3px" } },
                                    [_vm._v("-")]
                                  ),
                                  _c("el-date-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      placeholder: "证件有效止期",
                                      type: "date",
                                      format: "yyyy-MM-dd",
                                      "value-format": "yyyy-MM-dd HH:mm:ss",
                                      "picker-options": _vm.ownerDisabledEnd,
                                      disabled:
                                        _vm.isDisabled ||
                                        _vm.data.ownerCertEndDate ===
                                          "9999-12-31 23:59:59",
                                    },
                                    model: {
                                      value: _vm.data.ownerCertEndDate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.data,
                                          "ownerCertEndDate",
                                          $$v
                                        )
                                      },
                                      expression: "data.ownerCertEndDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-checkbox",
                                {
                                  staticStyle: { "margin-left": "10px" },
                                  attrs: { disabled: _vm.isDisabled },
                                  on: {
                                    change: function (val) {
                                      return _vm.dataValueChange(val, true)
                                    },
                                  },
                                  model: {
                                    value: _vm.data.isLong,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.data, "isLong", $$v)
                                    },
                                    expression: "data.isLong",
                                  },
                                },
                                [_vm._v("长期")]
                              ),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "font-size": "13px",
                                    color: "red",
                                    "margin-left": "15px",
                                  },
                                },
                                [_vm._v("(人保请填写证件起止日期)")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.data.relationshipFlag
            ? _c(
                "el-row",
                { attrs: { gutter: 0 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          ref: "certStartDate",
                          attrs: {
                            label: "证件起止日期",
                            prop: "certStartDate",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { display: "flex" } },
                            [
                              _c(
                                "div",
                                { staticStyle: { display: "flex" } },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      placeholder: "证件有效起期",
                                      type: "date",
                                      format: "yyyy-MM-dd",
                                      "value-format": "yyyy-MM-dd HH:mm:ss",
                                      "picker-options":
                                        _vm.relationshipDisabledStart,
                                      disabled: _vm.isDisabled,
                                    },
                                    model: {
                                      value: _vm.data.certStartDate,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.data, "certStartDate", $$v)
                                      },
                                      expression: "data.certStartDate",
                                    },
                                  }),
                                  _c(
                                    "span",
                                    { staticStyle: { margin: "0 3px" } },
                                    [_vm._v("-")]
                                  ),
                                  _c("el-date-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      placeholder: "证件有效止期",
                                      type: "date",
                                      format: "yyyy-MM-dd",
                                      "value-format": "yyyy-MM-dd HH:mm:ss",
                                      "picker-options":
                                        _vm.relationshipDisabledEnd,
                                      disabled:
                                        _vm.isDisabled ||
                                        _vm.data.certEndDate ===
                                          "9999-12-31 23:59:59",
                                    },
                                    model: {
                                      value: _vm.data.certEndDate,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.data, "certEndDate", $$v)
                                      },
                                      expression: "data.certEndDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-checkbox",
                                {
                                  staticStyle: { "margin-left": "10px" },
                                  attrs: { disabled: _vm.isDisabled },
                                  on: {
                                    change: function (val) {
                                      return _vm.dataValueChange(val, false)
                                    },
                                  },
                                  model: {
                                    value: _vm.data.isLong,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.data, "isLong", $$v)
                                    },
                                    expression: "data.isLong",
                                  },
                                },
                                [_vm._v("长期")]
                              ),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "font-size": "13px",
                                    color: "red",
                                    "margin-left": "15px",
                                  },
                                },
                                [_vm._v("(人保请填写证件起止日期)")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          !_vm.data.relationshipFlag
            ? _c(
                "el-row",
                { attrs: { gutter: 0 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          ref: "mobile",
                          attrs: {
                            label:
                              _vm.data.relationshipAttribute == "0"
                                ? "联系电话"
                                : "座机",
                            prop: "mobile",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "special_col_one",
                              staticStyle: { display: "flex" },
                            },
                            [
                              _c("el-input", {
                                staticClass: "double-input",
                                staticStyle: {
                                  "margin-right": "10px",
                                  width: "100%",
                                },
                                attrs: {
                                  type: "text",
                                  onkeyup:
                                    "value=value.replace(/[^0-9a-zA-Z\\-.]+/,'')",
                                  maxlength: "13",
                                  placeholder:
                                    "" +
                                    (_vm.data.relationshipAttribute == "0"
                                      ? "请输入手机号"
                                      : "例如：区号-12345678"),
                                  clearable: false,
                                  disabled: _vm.isDisabled,
                                },
                                on: {
                                  blur: function ($event) {
                                    return _vm.mobileChange(_vm.data)
                                  },
                                },
                                model: {
                                  value: _vm.data.mobile,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "mobile", $$v)
                                  },
                                  expression: "data.mobile",
                                },
                              }),
                              _vm.data.relationshipAttribute == "0" &&
                              _vm.showMobileHolder
                                ? _c(
                                    "el-checkbox",
                                    {
                                      attrs: { disabled: _vm.isDisabled },
                                      on: { change: _vm.checkChange },
                                      model: {
                                        value: _vm.data.isNotSelf,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.data, "isNotSelf", $$v)
                                        },
                                        expression: "data.isNotSelf",
                                      },
                                    },
                                    [_vm._v("非本人手机号")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          ref: "email",
                          attrs: {
                            label: "电子邮箱",
                            "label-width": "110px",
                            prop: "email",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { display: "flex" } },
                            [
                              _c("el-input", {
                                staticStyle: {
                                  "margin-right": "10px",
                                  width: "55%",
                                },
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入电子邮箱",
                                  clearable: false,
                                  disabled: _vm.isDisabled,
                                },
                                model: {
                                  value: _vm.data.email,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.data,
                                      "email",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "data.email",
                                },
                              }),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "font-size": "12px",
                                    color: "#999",
                                  },
                                },
                                [
                                  _c("i", { staticClass: "el-icon-info" }),
                                  _vm._v(" 用于接收保险公司发送的电子保单"),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.data.isNotSelf &&
          _vm.data.relationshipAttribute == "0" &&
          _vm.showMobileHolder
            ? _c(
                "el-row",
                { staticClass: "mobile_holder", attrs: { gutter: 0 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          ref: "mobileHolderName",
                          attrs: {
                            label: "持有人姓名",
                            prop: "mobileHolderName",
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "double-input",
                            staticStyle: {
                              "margin-right": "10px",
                              width: "210px",
                            },
                            attrs: {
                              maxlength: "40",
                              placeholder: "请输入持有人姓名",
                              clearable: false,
                              disabled: _vm.isDisabled,
                            },
                            model: {
                              value: _vm.data.mobileHolderName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.data,
                                  "mobileHolderName",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "data.mobileHolderName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 16 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          ref: "mobileHolderCertificateNo",
                          staticClass: "special_row_content",
                          attrs: {
                            "label-width": "120px",
                            label: "持有人证件类型",
                            prop: "mobileHolderCertificateNo",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { display: "flex" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "double-input",
                                  staticStyle: { width: "210px" },
                                  attrs: {
                                    filterable: "",
                                    disabled: _vm.isDisabled,
                                    placeholder: "请选择持有人证件类型",
                                    clearable: false,
                                  },
                                  on: { change: _vm.changeValue },
                                  model: {
                                    value: _vm.data.mobileHolderCertificate,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.data,
                                        "mobileHolderCertificate",
                                        $$v
                                      )
                                    },
                                    expression: "data.mobileHolderCertificate",
                                  },
                                },
                                _vm._l(
                                  _vm.cardTypeOptions,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.dictName,
                                        value: item.dictCode,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                              _c("el-input", {
                                staticClass: "input-with-select",
                                staticStyle: { width: "210px" },
                                attrs: {
                                  maxlength: "20",
                                  disabled: _vm.isDisabled,
                                  placeholder: "请输入持有人证件号码",
                                  clearable: false,
                                },
                                on: {
                                  blur: function ($event) {
                                    return _vm.certificateNoChange(_vm.data)
                                  },
                                  input: _vm.inputHolderCardNo,
                                },
                                model: {
                                  value: _vm.data.mobileHolderCertificateNo,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.data,
                                      "mobileHolderCertificateNo",
                                      $$v
                                    )
                                  },
                                  expression: "data.mobileHolderCertificateNo",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-row",
            { attrs: { gutter: 0 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    {
                      ref: "regionCode",
                      staticClass: "special_row_content",
                      style: { "max-width": _vm.addressWidth + "px" },
                      attrs: { label: "联系地址", prop: "address" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "special_col_one",
                          staticStyle: { display: "flex" },
                        },
                        [
                          _c("el-cascader", {
                            ref: "addrssCascader",
                            staticClass: "double-input-city",
                            staticStyle: { width: "210px" },
                            attrs: {
                              disabled: _vm.isDisabled,
                              options: _vm.cityList,
                              clearable: false,
                              filterable: "",
                            },
                            on: { change: _vm.handleChange },
                            model: {
                              value: _vm.data.regionCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "regionCode", $$v)
                              },
                              expression: "data.regionCode",
                            },
                          }),
                          _c("el-input", {
                            staticClass: "input-with-select",
                            attrs: {
                              onkeyup:
                                "this.value = this.value.replace(/\\s+/g,'')",
                              maxlength: "100",
                              disabled: _vm.isDisabled,
                              placeholder: "请输入详细地址",
                            },
                            model: {
                              value: _vm.data.address,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.data,
                                  "address",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "data.address",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.data.relationshipAttribute == "0"
            ? _c(
                "el-row",
                { attrs: { gutter: 0 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          ref: "birthday",
                          attrs: { label: "出生日期", prop: "birthday" },
                        },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              "value-format": "yyyy-MM-dd",
                              disabled: _vm.isDisabled,
                              type: "date",
                              placeholder: "选择出生日期",
                              "picker-options": _vm.disabledLessThan,
                            },
                            on: { change: _vm.changeValue },
                            model: {
                              value: _vm.data.birthday,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "birthday", $$v)
                              },
                              expression: "data.birthday",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "性别" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { disabled: _vm.isDisabled },
                              model: {
                                value: _vm.data.sex,
                                callback: function ($$v) {
                                  _vm.$set(_vm.data, "sex", $$v)
                                },
                                expression: "data.sex",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "M" } }, [
                                _vm._v("男"),
                              ]),
                              _c("el-radio", { attrs: { label: "F" } }, [
                                _vm._v("女"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }