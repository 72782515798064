var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "customer-type" },
    [
      _c("div", { staticStyle: { margin: "5px 0" } }, [
        _c("b", [_vm._v("客户类型")]),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "justify-content": "space-between",
              "align-items": "center",
              "margin-top": "5px",
            },
          },
          [
            _c(
              "span",
              {
                staticStyle: {
                  color: "#c1272d",
                  "font-size": "13px",
                  "margin-right": "10px",
                },
              },
              [
                _vm._v(
                  "重要提示：自主定义网点类型，需要重新进行客户类型的初始化，您可以通过客户导入批量更新或者联系系统管理人员处理。"
                ),
              ]
            ),
            _c(
              "el-button",
              {
                attrs: {
                  type: "primary",
                  disabled:
                    _vm.currentObj &&
                    _vm.currentObj.defaulted &&
                    !!_vm.curRenewalConfigParams.orgCode,
                  icon: "el-icon-plus",
                  plain: "",
                  size: "mini",
                },
                on: {
                  click: function ($event) {
                    return _vm.add("add")
                  },
                },
              },
              [_vm._v("新增")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { style: { height: _vm.tableHeightP, overflow: "auto" } },
        [
          _c(
            "el-table",
            {
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: {
                size: "mini",
                border: "",
                data: _vm.tableData,
                "header-cell-style": { background: "#F7F7F7" },
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "distinguishName",
                  label: "客户类型名称",
                  width: "auto",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center", width: "140" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              disabled:
                                _vm.currentObj &&
                                _vm.currentObj.defaulted &&
                                !!_vm.curRenewalConfigParams.orgCode,
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.edit("edit", scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showDialog,
            "close-on-click-modal": false,
            width: "600px",
            "append-to-body": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [
                  _vm._v(
                    _vm._s(_vm.opType === "edit" ? "编辑" : "新增") + "客户类别"
                  ),
                ]
              ),
            ]
          ),
          _c("CustomerTypeDialog", {
            attrs: {
              types: "typeName",
              opType: _vm.opType,
              refresh: _vm.showDialog,
              currentItem: _vm.currentItem,
              configId: _vm.configId,
              selectList: _vm.tableData,
            },
            on: { closePage: _vm.refreshData },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }