import "core-js/modules/es.array.concat.js";
import axios from 'axios';
var isNewVersion = function isNewVersion() {
  var url = "//".concat(window.location.host, "/version.json?t=").concat(new Date().getTime());
  axios.get(url).then(function (res) {
    if (res.status === 200) {
      var vueVersion = res.data.version;
      var localVueVersion = localStorage.getItem('sysVersion');
      if (localVueVersion && localVueVersion != vueVersion) {
        localStorage.setItem('sysVersion', vueVersion);
        window.location.reload();
        return;
      } else {
        localStorage.setItem('sysVersion', vueVersion);
      }
    }
  });
};
export default {
  isNewVersion: isNewVersion
};