var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "app-m-cls cameras" }, [
        _c(
          "div",
          { staticClass: "table-cls normal-btn-cls" },
          [
            _c(
              "el-card",
              { staticClass: "box-card", attrs: { shadow: "never" } },
              [
                _c(
                  "div",
                  {
                    staticClass: "search-box",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [
                    _vm.bizType === "OEM"
                      ? _c(
                          "div",
                          [
                            _c("span", [_vm._v("所属机构：")]),
                            _c("OemSelect", {
                              staticStyle: {
                                "max-width": "150px",
                                display: "inline-block",
                              },
                              attrs: {
                                searchForm: _vm.orgForm,
                                canClickArea: true,
                              },
                              on: { nodeClick: _vm.handleNodeClick },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.bizType === "DEALER"
                      ? _c(
                          "div",
                          [
                            _c("span", [_vm._v("所属机构：")]),
                            _c("el-input", {
                              attrs: { disabled: "" },
                              model: {
                                value: _vm.searchForm.orgName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.searchForm, "orgName", $$v)
                                },
                                expression: "searchForm.orgName",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v("机器编码：")]),
                        _c("el-input", {
                          attrs: {
                            maxlength: "40",
                            placeholder: "请输入机器编码",
                            clearable: "",
                          },
                          model: {
                            value: _vm.searchForm.cameraCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "cameraCode", $$v)
                            },
                            expression: "searchForm.cameraCode",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v("型号：")]),
                        _c("el-input", {
                          attrs: {
                            maxlength: "40",
                            placeholder: "请输入型号",
                            clearable: "",
                          },
                          model: {
                            value: _vm.searchForm.cameraModel,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "cameraModel", $$v)
                            },
                            expression: "searchForm.cameraModel",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              icon: "el-icon-refresh-left",
                            },
                            on: { click: _vm.resetSearch },
                          },
                          [_vm._v("重置")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "primary",
                              icon: "el-icon-search",
                            },
                            on: { click: _vm.searchData },
                          },
                          [_vm._v("查询")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "search-btn",
                        staticStyle: { "margin-top": "0" },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: { click: _vm.breakData },
                          },
                          [_vm._v("刷新")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.add("add")
                              },
                            },
                          },
                          [_vm._v("新增")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              disabled: _vm._.isEmpty(_vm.multipleSelection),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.batchDelete()
                              },
                            },
                          },
                          [_vm._v("批量删除")]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    ref: "multipleTable",
                    staticStyle: { width: "100%" },
                    attrs: {
                      size: "mini",
                      border: "",
                      data: _vm.tableData,
                      height: _vm.tableHeightJD,
                      "header-cell-style": { background: "#F7F7F7" },
                      "highlight-current-row": "",
                    },
                    on: { "selection-change": _vm.handleSelectionChange },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        type: "selection",
                        width: "55",
                        align: "center",
                        fixed: "left",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "cameraCode",
                        label: "机器编码",
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "cameraModel",
                        label: "型号",
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "installTime",
                        label: "安装时间",
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    scope.row.installTime
                                      ? scope.row.installTime.slice(0, 10)
                                      : ""
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "orgName",
                        label: "所属机构",
                        "min-width": "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "installPosition",
                        label: "安装位置",
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "操作",
                        width: "80",
                        fixed: "right",
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.edit("edit", scope.row)
                                    },
                                  },
                                },
                                [_vm._v("编辑")]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c("pagination", {
                  attrs: {
                    total: _vm.total,
                    page: _vm.listQuery.pageNum,
                    limit: _vm.listQuery.pageSize,
                  },
                  on: {
                    "update:page": function ($event) {
                      return _vm.$set(_vm.listQuery, "pageNum", $event)
                    },
                    "update:limit": function ($event) {
                      return _vm.$set(_vm.listQuery, "pageSize", $event)
                    },
                    pagination: function ($event) {
                      return _vm.loadData()
                    },
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showDialog,
            "close-on-click-modal": false,
            width: "720px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [
                  _vm._v(
                    "摄像头" + _vm._s(_vm.opType === "edit" ? "编辑" : "新增")
                  ),
                ]
              ),
            ]
          ),
          _c("CamerasDialog", {
            attrs: {
              opType: _vm.opType,
              refresh: _vm.showDialog,
              cameraDeviceId: _vm.currentCameraDeviceId,
            },
            on: { closePage: _vm.loadData },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }