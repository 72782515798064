var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "app-m-cls" }, [
        _c(
          "div",
          { staticClass: "table-cls normal-btn-cls" },
          [
            _c(
              "el-drawer",
              {
                attrs: {
                  title: "",
                  visible: _vm.drawer,
                  modal: false,
                  "append-to-body": true,
                  size: "370px",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.drawer = $event
                  },
                },
              },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.searchForm,
                      "label-width": "110px",
                      size: "mini",
                    },
                  },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "车牌号" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "10",
                                    placeholder: "请输入车牌号",
                                    clearable: "",
                                  },
                                  on: { input: _vm.inputPlateNo },
                                  model: {
                                    value: _vm.searchForm.plateNo,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchForm, "plateNo", $$v)
                                    },
                                    expression: "searchForm.plateNo",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c("el-form-item", { attrs: { label: "车架号" } }, [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "margin-right": "10px",
                                    width: "100%",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      maxlength: "17",
                                      placeholder: "请输入车架号",
                                      clearable: "",
                                    },
                                    on: { input: _vm.inputvin },
                                    model: {
                                      value: _vm.searchForm.vin,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.searchForm, "vin", $$v)
                                      },
                                      expression: "searchForm.vin",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "商业险保司" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      filterable: "",
                                      placeholder: "请选择商业险保司",
                                      clearable: "",
                                    },
                                    model: {
                                      value:
                                        _vm.searchForm.bsLastYearInsCorpCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "bsLastYearInsCorpCode",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "searchForm.bsLastYearInsCorpCode",
                                    },
                                  },
                                  _vm._l(_vm.supplyers, function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.shortName,
                                        value: item.code,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "交强险保司" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      filterable: "",
                                      placeholder: "请选择交强险保司",
                                      clearable: "",
                                    },
                                    model: {
                                      value:
                                        _vm.searchForm.bzLastYearInsCorpCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "bzLastYearInsCorpCode",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "searchForm.bzLastYearInsCorpCode",
                                    },
                                  },
                                  _vm._l(_vm.supplyers, function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.shortName,
                                        value: item.code,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "车主名称" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "17",
                                    placeholder: "请输入车主名称",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.searchForm.ownerName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchForm, "ownerName", $$v)
                                    },
                                    expression: "searchForm.ownerName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "保险到期天数" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "请输入保险到期天数",
                                    oninput:
                                      "value=value.replace(/[^\\d]/g,'')",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.searchForm.daysInsuranceDue,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "daysInsuranceDue",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.daysInsuranceDue",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "无到期日" } },
                              [
                                _c(
                                  "el-checkbox-group",
                                  {
                                    on: { change: _vm.ChangeNoMaturityDate },
                                    model: {
                                      value: _vm.noMaturityDate,
                                      callback: function ($$v) {
                                        _vm.noMaturityDate = $$v
                                      },
                                      expression: "noMaturityDate",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        attrs: { label: "noBzLastYearEndDate" },
                                      },
                                      [_vm._v("交强险")]
                                    ),
                                    _c(
                                      "el-checkbox",
                                      {
                                        attrs: { label: "noBsLastYearEndDate" },
                                      },
                                      [_vm._v("商业险")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "最新跟进状态" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请选择",
                                    },
                                    model: {
                                      value:
                                        _vm.searchForm.newestTrackStatusCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "newestTrackStatusCode",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "searchForm.newestTrackStatusCode",
                                    },
                                  },
                                  _vm._l(
                                    _vm.trackStatuslist,
                                    function (group, index) {
                                      return _c(
                                        "el-option-group",
                                        {
                                          key: index,
                                          attrs: { label: group.label },
                                        },
                                        _vm._l(group.options, function (item) {
                                          return _c("el-option", {
                                            key: item.itemCode,
                                            attrs: {
                                              label: item.itemName,
                                              value: item.itemCode,
                                            },
                                          })
                                        }),
                                        1
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "保险到期日" } },
                              [
                                _c("DatePicker", {
                                  attrs: {
                                    pickerDateTime: _vm.overdueStartEndTime,
                                    noPickerOptions: true,
                                    startDate: "startOverdueDate",
                                    endDate: "endOverdueDate",
                                    width: "100%",
                                  },
                                  on: {
                                    pickerDateChange: function ($event) {
                                      return _vm.getPickersDate(
                                        "overdueStartEndTime",
                                        $event
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "最新跟进时间" } },
                              [
                                _c("DatePicker", {
                                  attrs: {
                                    pickerDateTime: _vm.newestTrackStartEndTime,
                                    noPickerOptions: true,
                                    startDate: "startNewestTrackTime",
                                    endDate: "endNewestTrackTime",
                                    width: "100%",
                                  },
                                  on: {
                                    pickerDateChange: function ($event) {
                                      return _vm.getPickersDate(
                                        "newestTrackStartEndTime",
                                        $event
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "下次跟进时间" } },
                              [
                                _c("DatePicker", {
                                  attrs: {
                                    pickerDateTime: _vm.newestSubscribeTime,
                                    noPickerOptions: true,
                                    startDate: "startNewestSubscribeTime",
                                    endDate: "endNewestSubscribeTime",
                                    width: "100%",
                                  },
                                  on: {
                                    pickerDateChange: function ($event) {
                                      return _vm.getPickersDate(
                                        "newestSubscribeTime",
                                        $event
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "车主手机号" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "11",
                                    placeholder: "请输入车主手机号",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.searchForm.ownerMobile,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "ownerMobile",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.ownerMobile",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "客户类别" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      filterable: "",
                                      clearable: "",
                                      placeholder: "请选择客户类别",
                                    },
                                    model: {
                                      value: _vm.searchForm.renewalType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "renewalType",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.renewalType",
                                    },
                                  },
                                  _vm._l(
                                    _vm.renewalTypeList,
                                    function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.itemName,
                                          value: item.itemCode,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "注册日期" } },
                              [
                                _c("DatePicker", {
                                  attrs: {
                                    pickerDateTime: _vm.registerStartEndTime,
                                    noPickerOptions: true,
                                    startDate: "startRegisterDate",
                                    endDate: "endRegisterDate",
                                    width: "100%",
                                  },
                                  on: {
                                    pickerDateChange: function ($event) {
                                      return _vm.getPickersDate(
                                        "registerStartEndTime",
                                        $event
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "创建日期" } },
                              [
                                _c("DatePicker", {
                                  attrs: {
                                    pickerDateTime: _vm.createdStartEndTime,
                                    startDate: "startCreatedDate",
                                    endDate: "endCreatedDate",
                                    width: "100%",
                                  },
                                  on: {
                                    pickerDateChange: function ($event) {
                                      return _vm.getPickersDate(
                                        "createdStartEndTime",
                                        $event
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c("el-col", { attrs: { span: 24 } }, [
                          _c(
                            "div",
                            { staticClass: "searchBtnStyle" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    icon: "el-icon-refresh-left",
                                  },
                                  on: { click: _vm.resetSearch },
                                },
                                [_vm._v("重置")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    type: "primary",
                                    icon: "el-icon-search",
                                  },
                                  on: { click: _vm.searchData },
                                },
                                [_vm._v("查询")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "table-cls normal-btn-cls" },
          [
            _c(
              "el-card",
              { staticClass: "box-card", attrs: { shadow: "never" } },
              [
                _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                  _c("div", { staticClass: "search-box" }, [
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v("车主名称：")]),
                        _c("el-input", {
                          attrs: {
                            maxlength: "40",
                            placeholder: "请输入车主名称",
                            clearable: "",
                          },
                          model: {
                            value: _vm.searchForm.ownerName,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "ownerName", $$v)
                            },
                            expression: "searchForm.ownerName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v("车牌号：")]),
                        _c("el-input", {
                          attrs: {
                            maxlength: "10",
                            placeholder: "请输入车牌号",
                            clearable: "",
                          },
                          model: {
                            value: _vm.searchForm.plateNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "plateNo", $$v)
                            },
                            expression: "searchForm.plateNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v("车架号：")]),
                        _c("el-input", {
                          attrs: {
                            maxlength: "17",
                            placeholder: "请输入车架号",
                            clearable: "",
                          },
                          on: { input: _vm.inputvin },
                          model: {
                            value: _vm.searchForm.vin,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "vin", $$v)
                            },
                            expression: "searchForm.vin",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v("客户类别：")]),
                        _c(
                          "el-select",
                          {
                            attrs: {
                              filterable: "",
                              clearable: "",
                              placeholder: "请选择客户类别",
                            },
                            model: {
                              value: _vm.searchForm.renewalType,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "renewalType", $$v)
                              },
                              expression: "searchForm.renewalType",
                            },
                          },
                          _vm._l(_vm.renewalTypeList, function (item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: {
                                label: item.itemName,
                                value: item.itemCode,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v("创建日期：")]),
                        _c("DatePicker", {
                          attrs: {
                            pickerDateTime: _vm.createdStartEndTime,
                            startDate: "startCreatedDate",
                            endDate: "endCreatedDate",
                            width: "220px",
                          },
                          on: {
                            pickerDateChange: function ($event) {
                              return _vm.getPickersDate(
                                "createdStartEndTime",
                                $event
                              )
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "search-btn" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              icon: "el-icon-refresh-left",
                            },
                            on: { click: _vm.resetSearch },
                          },
                          [_vm._v("重置")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "primary",
                              icon: "el-icon-search",
                            },
                            on: { click: _vm.searchData },
                          },
                          [_vm._v("查询")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c(
                  "div",
                  { staticStyle: { position: "relative" } },
                  [
                    _c(
                      "el-tabs",
                      {
                        on: { "tab-click": _vm.handleTabClick },
                        model: {
                          value: _vm.searchForm.trackTabType,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchForm, "trackTabType", $$v)
                          },
                          expression: "searchForm.trackTabType",
                        },
                      },
                      [
                        _c(
                          "el-tabs",
                          {
                            on: { "tab-click": _vm.handleTabClick },
                            model: {
                              value: _vm.searchForm.trackTabType,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "trackTabType", $$v)
                              },
                              expression: "searchForm.trackTabType",
                            },
                          },
                          [
                            _c(
                              "el-tab-pane",
                              { attrs: { name: "NoTrack" } },
                              [
                                _c("template", { slot: "label" }, [
                                  _vm._v(
                                    " 续保待跟进(" +
                                      _vm._s(_vm.NoTrackNum) +
                                      ") "
                                  ),
                                ]),
                              ],
                              2
                            ),
                            _c(
                              "el-tab-pane",
                              { attrs: { name: "NextTrack" } },
                              [
                                _c("template", { slot: "label" }, [
                                  _vm._v(
                                    " 续保跟进中(" +
                                      _vm._s(_vm.NextTrackNum) +
                                      ") "
                                  ),
                                ]),
                              ],
                              2
                            ),
                            _c(
                              "el-tab-pane",
                              { attrs: { name: "TodayTrack" } },
                              [
                                _c("template", { slot: "label" }, [
                                  _vm._v(
                                    " 今日待跟进(" +
                                      _vm._s(_vm.TodayTrackNum) +
                                      ") "
                                  ),
                                ]),
                              ],
                              2
                            ),
                            _c(
                              "el-tab-pane",
                              { attrs: { name: "OverdueTrack" } },
                              [
                                _c("template", { slot: "label" }, [
                                  _vm._v(
                                    " 逾期待跟进(" +
                                      _vm._s(_vm.OverdueTrackNum) +
                                      ") "
                                  ),
                                ]),
                              ],
                              2
                            ),
                            _c(
                              "el-tab-pane",
                              { attrs: { name: "OverdueDisposed" } },
                              [
                                _c("template", { slot: "label" }, [
                                  _vm._v(
                                    " 已脱保(" +
                                      _vm._s(_vm.OverdueDisposedNum) +
                                      ") "
                                  ),
                                ]),
                              ],
                              2
                            ),
                            _c("el-tab-pane", {
                              attrs: { label: "全部", name: "All" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "table-btn-cls",
                        staticStyle: {
                          position: "absolute",
                          right: "0",
                          bottom: "22px",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: { click: _vm.breakData },
                          },
                          [_vm._v("刷新")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: {
                              click: function ($event) {
                                _vm.showAddDialog = true
                              },
                            },
                          },
                          [_vm._v("新增")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: { click: _vm.customerImport },
                          },
                          [_vm._v("导入")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: { click: _vm.exportFn },
                          },
                          [_vm._v("导出")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: { click: _vm.searchOpen },
                          },
                          [_vm._v("搜索")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    ref: "multipleTable",
                    staticStyle: { width: "100%" },
                    attrs: {
                      size: "mini",
                      border: "",
                      data: _vm.tableData,
                      height: _vm.tableHeightS,
                      "header-cell-style": { background: "#F7F7F7" },
                      "highlight-current-row": "",
                    },
                    on: {
                      "row-dblclick": _vm.dbSelected,
                      "selection-change": _vm.handleSelectionChange,
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "plateNo",
                        label: "车牌号",
                        width: "110",
                        "show-overflow-tooltip": "",
                        fixed: "left",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(scope.row.plateNo || "未上牌") +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "vin",
                        label: "车架号",
                        width: "180",
                        "show-overflow-tooltip": "",
                        fixed: "left",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "ownerName",
                        label: "车主名称",
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                        fixed: "left",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "ownerMobile",
                        label: "车主手机号",
                        width: "110",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    scope.row.ownerMobile
                                      ? scope.row.ownerMobile.replace(
                                          /^(.{3})(?:\d+)(.{2})$/,
                                          "$1******$2"
                                        )
                                      : ""
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "bsLastYearEndDate",
                        label: "商业险到期日",
                        "min-width": "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "adjBsLastYearEndDate",
                        label: "调整商业险上年到期日期",
                        "min-width": "180",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "bsLastYearInsCorpName",
                        label: "商业险保司名称",
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "bzLastYearEndDate",
                        label: "交强险到期日",
                        "min-width": "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "adjBzLastYearEndDate",
                        label: "调整交强险上年到期日期",
                        "min-width": "180",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "bzLastYearInsCorpName",
                        label: "交强险保司名称",
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "ownerCertificateNo",
                        label: "车主证件号",
                        width: "180",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    scope.row.ownerCertificateNo
                                      ? scope.row.ownerCertificateNo.replace(
                                          /^(.{10})(?:\d+)(.{2})$/,
                                          "$1******$2"
                                        )
                                      : ""
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "modelName",
                        label: "品牌型号",
                        "min-width": "200",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "engineNo",
                        label: "发动机号",
                        width: "100",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "registerDate",
                        label: "注册日期",
                        width: "110",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    scope.row.registerDate &&
                                      scope.row.registerDate.split(" ")[0]
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "sellDate",
                        label: "车销日期",
                        width: "110",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    scope.row.sellDate &&
                                      scope.row.sellDate.split(" ")[0]
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "newestTrackClassifyName",
                        label: "最新跟进分类",
                        width: "120px",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "newestTrackStatusName",
                        label: "最新跟进状态",
                        width: "120px",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "newestTrackContent",
                        label: "最新跟进内容",
                        width: "100",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "newestTrackTime",
                        label: "最新跟进时间",
                        "min-width": "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c(
                      "el-table-column",
                      {
                        attrs: {
                          prop: "newestSubscribeTime",
                          label: "下次跟进时间",
                          "min-width": "100",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      scope.row.newestSubscribeTime &&
                                        scope.row.newestSubscribeTime.split(
                                          " "
                                        )[0]
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      },
                      [_vm._v("、 ")]
                    ),
                    _c("el-table-column", {
                      attrs: {
                        prop: "renewalTypeDisplay",
                        label: "客户类别",
                        "min-width": "110",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "sourceDisplay",
                        label: "客户来源",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "ownerProvinceName",
                        label: "所在地区",
                        width: "180",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (scope.row.ownerProvinceName || "") +
                                      "" +
                                      (scope.row.ownerCityName || "") +
                                      "" +
                                      (scope.row.ownerDistrictName || "")
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "orgName",
                        label: "所属机构",
                        width: "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "createdTime",
                        label: "创建时间",
                        "min-width": "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "modifiedTime",
                        label: "更新时间",
                        "min-width": "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "操作",
                        align: "center",
                        width: "200",
                        fixed: "right",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.viewDetail(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("查看")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.toQuote(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("报价")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.customerTracks(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("跟进")]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c("pagination", {
                  attrs: {
                    total: _vm.total,
                    page: _vm.listQuery.pageNum,
                    limit: _vm.listQuery.pageSize,
                  },
                  on: {
                    "update:page": function ($event) {
                      return _vm.$set(_vm.listQuery, "pageNum", $event)
                    },
                    "update:limit": function ($event) {
                      return _vm.$set(_vm.listQuery, "pageSize", $event)
                    },
                    pagination: function ($event) {
                      return _vm.loadData()
                    },
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showAddTracksDialog,
            "close-on-click-modal": false,
            width: "550px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showAddTracksDialog = $event
            },
            handleClose: function ($event) {
              _vm.showDistributeDialog = false
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("新增跟进")]
              ),
            ]
          ),
          _c("FollowUpDialog", {
            attrs: {
              "customer-item": _vm.customerItem,
              refresh: _vm.showAddTracksDialog,
            },
            on: { closePage: _vm.closeDialog },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showCustomerDialog,
            "close-on-click-modal": false,
            width: "80%",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showCustomerDialog = $event
            },
            handleClose: function ($event) {
              _vm.showDistributeDialog = false
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("导入我的客户")]
              ),
            ]
          ),
          _c("CustomerImportListDialog", {
            attrs: {
              refresh: _vm.showCustomerDialog,
              currentType: "myCustomer",
            },
            on: { closePage: _vm.closeDialog },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showAddDialog,
            "close-on-click-modal": false,
            width: "950px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showAddDialog = $event
            },
            handleClose: function ($event) {
              _vm.showAddDialog = false
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("新增")]
              ),
            ]
          ),
          _c("AddDialog", {
            attrs: { refresh: _vm.showAddDialog, from: "my" },
            on: { closePage: _vm.closeDialog },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }