import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import Pagination from '@/components/Pagination';
import Search from "@/components/Search";
import policyTemplateRequest from "@/api/policyTemplate";
import Detail from "./detail.vue";
// import ConfigList from "./configList.vue"
import storageRequest from '@/api/storage';
import { saveFile } from '@/utils/common';
import VI_PROP_SUPPLYER from '@/assets/json/VI_PROP_SUPPLYER';
export default {
  mixins: [initHeight],
  components: {
    Pagination: Pagination,
    Search: Search,
    Detail: Detail
    // ConfigList
  },
  data: function data() {
    return {
      opType: "",
      appId: null,
      loading: true,
      searchForm: {},
      tableData: [],
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      showDialog: false,
      itemForm: {},
      supplyers: [],
      riskEnum: [],
      showConfigDialog: false
    };
  },
  mounted: function mounted() {
    this.supplyers = VI_PROP_SUPPLYER;
    this.getRiskEnum();
    this.loadData(); //获取数据列表
  },

  methods: {
    // 启用禁用
    handleSwitch: function handleSwitch(val, item) {
      var _this = this;
      item.enable = !val;
      this.$confirm("\u60A8\u786E\u5B9A\u8981".concat(val ? '启用' : '禁用', "\u8BE5\u6A21\u677F\uFF1F"), {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        type: 'warning'
      }).then(function () {
        policyTemplateRequest.updataTemplate(_objectSpread(_objectSpread({}, item), {}, {
          templateId: item.templateId,
          enable: val
        })).then(function (res) {
          if (res.code == 200) {
            _this.$message.success(res.msg);
            _this.loadData();
          }
        });
      }).catch(function () {});
    },
    getRiskEnum: function getRiskEnum() {
      var _this2 = this;
      policyTemplateRequest.getRiskEnum().then(function (res) {
        if (res.code == 200) {
          _this2.riskEnum = res.data;
        }
      });
    },
    //  下载
    downloadFile: function downloadFile(item) {
      if (!item.pdfFileId) return;
      var contentType = "application/pdf";
      storageRequest.downloadFile(item.pdfFileId).then(function (res) {
        saveFile(res, contentType, item.pdfFileName);
      });
    },
    // 删除模板
    deleteTemplate: function deleteTemplate(item) {
      var _this3 = this;
      this.$confirm('是否删除当前数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        policyTemplateRequest.deleteTemplate(item.templateId).then(function (res) {
          if (res.code == 200) {
            _this3.$message.success(res.msg);
            _this3.loadData();
          }
        });
      });
    },
    closePage: function closePage(isRefresh) {
      this.showDialog = false;
      if (isRefresh) {
        this.loadData();
      }
    },
    openDialog: function openDialog(opType, item) {
      this.showDialog = true;
      this.opType = opType;
      this.itemForm = item;
    },
    // 刷新
    breakData: function breakData() {
      // this.resetSearch();
      this.searchData();
    },
    resetSearch: function resetSearch() {
      // 重置
      this.searchForm = {};
    },
    searchData: function searchData() {
      // 查询
      this.loadData();
    },
    // 查看
    viewConfigDetail: function viewConfigDetail(row) {
      // this.showConfigDialog = true;
      // this.itemForm = row;
      this.$router.push({
        path: "/policyRcognition/template/detail",
        query: {
          templateId: row.templateId,
          tabName: row.templateName + '-详情'
        }
      });
    },
    // 关闭弹窗
    loadData: function loadData() {
      var _this4 = this;
      this.showDialog = false;
      this.opType = "";
      this.loading = true;
      policyTemplateRequest.getList(_objectSpread(_objectSpread({}, this.listQuery), this.searchForm)).then(function (res) {
        _this4.loading = false;
        if (res.code == 200) {
          _this4.tableData = res.data.list;
          _this4.total = res.data.page.recordsTotal;
        }
      }).catch(function (err) {
        return _this4.loading = false;
      });
    }
  }
};