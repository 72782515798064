import { render, staticRenderFns } from "./giftMaintainDialog.vue?vue&type=template&id=e6844470&scoped=true&"
import script from "./giftMaintainDialog.vue?vue&type=script&lang=js&"
export * from "./giftMaintainDialog.vue?vue&type=script&lang=js&"
import style0 from "./giftMaintainDialog.vue?vue&type=style&index=0&id=e6844470&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.2@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e6844470",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_vue-hot-reload-api@2.3.4@vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e6844470')) {
      api.createRecord('e6844470', component.options)
    } else {
      api.reload('e6844470', component.options)
    }
    module.hot.accept("./giftMaintainDialog.vue?vue&type=template&id=e6844470&scoped=true&", function () {
      api.rerender('e6844470', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/giftManagement/dialog/giftMaintainDialog.vue"
export default component.exports