import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.keys.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import leadManagementApi from '@/api/leadManagement';
import requestOrg from '@/api/orgChildren';
import employeeRequest from '@/api/employee';
import Rules from '@/utils/rules';
import OemSelect from '@/components/OemSelect';
import { getUserOrgInfoDealerOem } from "@/utils/common";
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    opType: {
      type: Boolean,
      default: false
    },
    customerIdArr: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  components: {
    OemSelect: OemSelect
  },
  data: function data() {
    return {
      form: {
        salesOrgName: '',
        salesOrgCode: '',
        customerIdList: [],
        salemanName: '',
        userList: []
      },
      rules: {
        salesOrgCode: [{
          required: true,
          message: '请选择所属机构',
          trigger: 'change'
        }],
        salemanName: [{
          required: true,
          message: "请添加续保专员",
          trigger: "change"
        }]
      },
      users: [],
      bizType: localStorage.getItem("bizType") || '',
      orgForm: {
        salesOrgCode: '',
        salesOrgName: ''
      },
      formLoading: false
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    getUsers: function getUsers(val) {
      //列表行选择事件
      this.users = val;
    },
    nodeClick: function nodeClick(data) {
      this.form.salemanName = '';
      this.form.salesOrgCode = data.salesOrgCode;
      this.form.salesOrgName = data.salesOrgName;
      if (!data.salesOrgCode && !data.salesOrgName) {
        this.users = [];
      }
    },
    // 初始化数据
    initData: function initData() {
      this.users = [];
      this.form = {
        salesOrgName: '',
        salesOrgCode: '',
        customerIdList: this.customerIdArr,
        salemanName: '',
        userList: []
      };
      this.orgForm = {
        salesOrgCode: '',
        salesOrgName: ''
      };
      if (this.opType) {
        this.form = {
          salesOrgName: '',
          salesOrgCode: '',
          customerIdList: this.customerIdArr,
          salemanName: [],
          userList: [],
          distributionType: 'CUSTOM'
        };
      }
      if (this.bizType === 'DEALER') {
        var orgObj = getUserOrgInfoDealerOem(this.bizType);
        this.form.salesOrgCode = orgObj.salesOrgCode;
        this.form.salesOrgName = orgObj.salesOrgName;
        this.dealerGetUsers(this.form.salesOrgCode);
      }
      this.$refs.detail.resetFields();
    },
    // 获取续保专员列表(经销商)
    dealerGetUsers: function dealerGetUsers(orgCode) {
      var _this = this;
      requestOrg.getOrgPerson(orgCode).then(function (res) {
        if (res.code && res.data) {
          _this.users = res.data;
        }
      });
    },
    // 监听选择业务员事件
    salemanNameChange: function salemanNameChange(val, type) {
      var _this2 = this;
      if (type && val.length || !type && val) {
        var arr = [];
        this.users.forEach(function (item) {
          if (type && val.includes(item.userId)) {
            arr.push(item);
          } else if (!type && val == item.userId) {
            arr.push(item);
          }
        });
        this.form.userList = arr.map(function (data, index) {
          return {
            name: data.userName,
            userId: data.userId,
            customerQuantity: _this2.opType ? 1 : 1
          };
        });
      } else {
        this.form.userList = [];
      }
    },
    valChange: function valChange(val) {
      if (val === 'AVERAGE') {
        this.form.userList.forEach(function (val) {
          val.customerQuantity = 1;
        });
      }
    },
    // 分配人员校验
    validate: function validate() {
      var _this3 = this;
      var flag = false;
      var sum = 0;
      this.form.userList.some(function (val, index) {
        sum += val.customerQuantity;
        if (!val.customerQuantity && val.customerQuantity !== 0) {
          _this3.$message.warning("\u8BF7\u7ED9".concat(val.name, "\u5206\u914D\u4EFB\u52A1"));
          flag = true;
          return flag;
        }
      });
      if (sum !== this.form.customerIdList.length && this.form.distributionType === 'CUSTOM') {
        this.$message.warning('分配任务要等于客户信息');
        flag = true;
      }
      return flag;
    },
    customerQuantityChange: function customerQuantityChange(e, i) {
      i.customerQuantity = Number(e) ? Number(e) : 1;
    },
    // 保存数据
    save: function save() {
      var _this4 = this;
      this.$refs.detail.validate(function (valid) {
        if (valid) {
          if (_this4.validate()) return;
          if (JSON.parse(localStorage.getItem('userInfo')).bizType && JSON.parse(localStorage.getItem('userInfo')).bizType == "DEALER") {
            var userInfo = JSON.parse(localStorage.getItem('userInfo'));
            _this4.form.salesOrgCode = userInfo.data.orgCode ? userInfo.data.orgCode : userInfo.data.organization.dealerCode;
          }
          _this4.formLoading = true;
          leadManagementApi.batchDistribution(_this4.form).then(function (res) {
            _this4.formLoading = false;
            if (res.code === 200) {
              _this4.$message.success(res.msg);
              _this4.closePage();
            }
          }).catch(function (err) {
            _this4.formLoading = false;
          });
        }
      });
    },
    //关闭弹窗
    closePage: function closePage() {
      var _this5 = this;
      this.$emit('closePage');
      setTimeout(function () {
        if (_this5.opType) {
          _this5.form.salemanName = [];
        } else {
          _this5.form.salemanName = '';
        }
        _this5.form.customerIdList = [];
      }, 1000);
    }
  }
};