var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageContainer", [
        _c("div", { attrs: { slot: "main" }, slot: "main" }, [
          _c(
            "div",
            {
              staticClass: "app-m-cls",
              staticStyle: { "min-height": "calc(100vh - 130px)" },
            },
            [
              _c(
                "div",
                { ref: "header", staticClass: "header-cls" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.form,
                        rules: _vm.formRules,
                        "label-width": "120px",
                        size: "mini",
                      },
                    },
                    [
                      _c(
                        "el-collapse",
                        {
                          staticStyle: { "margin-top": "10px" },
                          model: {
                            value: _vm.activeOne,
                            callback: function ($$v) {
                              _vm.activeOne = $$v
                            },
                            expression: "activeOne",
                          },
                        },
                        [
                          _c(
                            "el-collapse-item",
                            {
                              staticClass: "collapse-item-style",
                              attrs: { name: "1" },
                            },
                            [
                              _c("template", { slot: "title" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "title",
                                    staticStyle: { "line-height": "25px" },
                                  },
                                  [
                                    _c("FormLabel", {
                                      attrs: { "form-label": "出单信息" },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "el-row",
                                { attrs: { gutter: 0 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "业务机构",
                                            prop: "salesOrgName",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              ref: "orgSelect",
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                filterable: "",
                                                placeholder: "请选择业务机构",
                                              },
                                              model: {
                                                value: _vm.form.salesOrgName,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "salesOrgName",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.salesOrgName",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-option",
                                                {
                                                  staticStyle: {
                                                    height: "auto",
                                                    "background-color": "white",
                                                  },
                                                  attrs: {
                                                    value:
                                                      _vm.form.operateOrgId,
                                                  },
                                                },
                                                [
                                                  _c("el-tree", {
                                                    ref: "tree",
                                                    attrs: {
                                                      "expand-on-click-node": false,
                                                      "node-key": "code",
                                                      "highlight-current":
                                                        _vm.highlightCurrent,
                                                      data: _vm.orgData,
                                                      props: _vm.defaultProps,
                                                      "default-expand-all": "",
                                                    },
                                                    on: {
                                                      "node-click":
                                                        _vm.handleNodeClick,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "业务员",
                                            prop: "salemanName",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                filterable: "",
                                                placeholder: "请选择业务员",
                                                clearable: "",
                                              },
                                              model: {
                                                value: _vm.form.salemanName,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "salemanName",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.salemanName",
                                              },
                                            },
                                            _vm._l(
                                              _vm.personList,
                                              function (item, index) {
                                                return _c("el-option", {
                                                  key: index,
                                                  attrs: {
                                                    label: item.dictName,
                                                    value: item.dictCode,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "投保公司",
                                            prop: "insCorpCode",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                filterable: "",
                                                placeholder: "请选择投保公司",
                                                clearable: "",
                                              },
                                              model: {
                                                value: _vm.form.insCorpCode,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "insCorpCode",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.insCorpCode",
                                              },
                                            },
                                            _vm._l(
                                              _vm.supplyers,
                                              function (item, index) {
                                                return _c("el-option", {
                                                  key: index,
                                                  attrs: {
                                                    label: item.shortName,
                                                    value: item.code,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "投保地区",
                                            prop: "address",
                                          },
                                        },
                                        [
                                          _c("el-cascader", {
                                            ref: "address",
                                            staticStyle: {
                                              width: "100%",
                                              "max-width": "320px",
                                            },
                                            attrs: {
                                              placeholder: "请选择投保地区",
                                              options: _vm.regionOptions,
                                            },
                                            on: { change: _vm.handleChange },
                                            model: {
                                              value: _vm.form.address,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "address",
                                                  $$v
                                                )
                                              },
                                              expression: "form.address",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                { attrs: { gutter: 0 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "承保日期",
                                            prop: "insuredTime",
                                          },
                                        },
                                        [
                                          _c("el-date-picker", {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              "value-format": "yyyy-MM-dd",
                                              type: "date",
                                              placeholder: "请选择承保日期",
                                              "picker-options":
                                                _vm.disabledLessThan,
                                            },
                                            model: {
                                              value: _vm.form.insuredTime,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "insuredTime",
                                                  $$v
                                                )
                                              },
                                              expression: "form.insuredTime",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "录单人" } },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入录单人",
                                              clearable: "",
                                            },
                                            model: {
                                              value: _vm.form.operatorName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "operatorName",
                                                  $$v
                                                )
                                              },
                                              expression: "form.operatorName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "出单方式",
                                            prop: "insuredType",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                filterable: "",
                                                placeholder: "请选择出单方式",
                                                clearable: "",
                                              },
                                              on: {
                                                change: _vm.selectInsuranceType,
                                              },
                                              model: {
                                                value: _vm.form.insuredType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "insuredType",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.insuredType",
                                              },
                                            },
                                            _vm._l(
                                              _vm.dictSource["ORDER"],
                                              function (item, index) {
                                                return _c("el-option", {
                                                  key: index,
                                                  attrs: {
                                                    label: item.dictName,
                                                    value: item.dictCode,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-collapse",
                        {
                          staticStyle: { "margin-top": "10px" },
                          model: {
                            value: _vm.activeTwo,
                            callback: function ($$v) {
                              _vm.activeTwo = $$v
                            },
                            expression: "activeTwo",
                          },
                        },
                        [
                          _c(
                            "el-collapse-item",
                            {
                              staticClass: "collapse-item-style",
                              attrs: { name: "1" },
                            },
                            [
                              _c("template", { slot: "title" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "title",
                                    staticStyle: { "line-height": "25px" },
                                  },
                                  [
                                    _c("FormLabel", {
                                      attrs: { "form-label": "车主信息" },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "el-row",
                                { attrs: { gutter: 0 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "车主名",
                                            prop: "quoteVehicle.ownerName",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入车主名",
                                              clearable: "",
                                            },
                                            model: {
                                              value:
                                                _vm.form.quoteVehicle.ownerName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form.quoteVehicle,
                                                  "ownerName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.quoteVehicle.ownerName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "证件类型",
                                            prop: "quoteVehicle.ownerCertificateType",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                filterable: "",
                                                placeholder: "请选择证件类型",
                                                clearable: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.form.quoteVehicle
                                                    .ownerCertificateType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form.quoteVehicle,
                                                    "ownerCertificateType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.quoteVehicle.ownerCertificateType",
                                              },
                                            },
                                            _vm._l(
                                              _vm.VI_CERTIFICATE_TYPE,
                                              function (item, index) {
                                                return _c("el-option", {
                                                  key: index,
                                                  attrs: {
                                                    label: item.dictName,
                                                    value: item.dictCode,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "车主证件号",
                                            prop: "quoteVehicle.ownerCertificateNo",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入车主证件号",
                                              clearable: "",
                                            },
                                            model: {
                                              value:
                                                _vm.form.quoteVehicle
                                                  .ownerCertificateNo,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form.quoteVehicle,
                                                  "ownerCertificateNo",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.quoteVehicle.ownerCertificateNo",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "车主联系方式",
                                            prop: "quoteVehicle.ownerMobile",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入车主联系方式",
                                              clearable: "",
                                            },
                                            model: {
                                              value:
                                                _vm.form.quoteVehicle
                                                  .ownerMobile,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form.quoteVehicle,
                                                  "ownerMobile",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.quoteVehicle.ownerMobile",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-collapse",
                        {
                          staticStyle: { "margin-top": "10px" },
                          model: {
                            value: _vm.activeThree,
                            callback: function ($$v) {
                              _vm.activeThree = $$v
                            },
                            expression: "activeThree",
                          },
                        },
                        [
                          _c(
                            "el-collapse-item",
                            {
                              staticClass: "collapse-item-style",
                              attrs: { name: "1" },
                            },
                            [
                              _c("template", { slot: "title" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "title",
                                    staticStyle: { "line-height": "25px" },
                                  },
                                  [
                                    _c("FormLabel", {
                                      attrs: { "form-label": "投保车辆" },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "el-row",
                                { attrs: { gutter: 0 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.form.quoteVehicle.isNewCar,
                                              expression:
                                                "form.quoteVehicle.isNewCar",
                                            },
                                          ],
                                          attrs: { label: "车牌号" },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: { display: "flex" },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "margin-right": "10px",
                                                  },
                                                },
                                                [
                                                  _c("el-input", {
                                                    ref: "plateNo",
                                                    staticStyle: {
                                                      "margin-right": "10px",
                                                    },
                                                    attrs: {
                                                      maxlength: "10",
                                                      placeholder:
                                                        "请输入车牌号",
                                                      clearable: "",
                                                      disabled: "",
                                                    },
                                                    on: {
                                                      input: _vm.changeValue,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form.quoteVehicle
                                                          .plateNo,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form.quoteVehicle,
                                                          "plateNo",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.quoteVehicle.plateNo",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "el-checkbox",
                                                {
                                                  on: {
                                                    change: _vm.noPlateNoChange,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form.quoteVehicle
                                                        .isNewCar,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form.quoteVehicle,
                                                        "isNewCar",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.quoteVehicle.isNewCar",
                                                  },
                                                },
                                                [_vm._v("未上牌")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      !_vm.form.quoteVehicle.isNewCar
                                        ? _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "车牌号",
                                                prop: "quoteVehicle.plateNo",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "margin-right": "10px",
                                                      },
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        ref: "plateNo",
                                                        staticStyle: {
                                                          "margin-right":
                                                            "10px",
                                                        },
                                                        attrs: {
                                                          maxlength: "10",
                                                          placeholder:
                                                            "请输入车牌号",
                                                          clearable: "",
                                                        },
                                                        on: {
                                                          input:
                                                            _vm.inputPlateNo,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form
                                                              .quoteVehicle
                                                              .plateNo,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form
                                                                .quoteVehicle,
                                                              "plateNo",
                                                              typeof $$v ===
                                                                "string"
                                                                ? $$v.trim()
                                                                : $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form.quoteVehicle.plateNo",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-checkbox",
                                                    {
                                                      on: {
                                                        change:
                                                          _vm.noPlateNoChange,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form.quoteVehicle
                                                            .isNewCar,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form
                                                              .quoteVehicle,
                                                            "isNewCar",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.quoteVehicle.isNewCar",
                                                      },
                                                    },
                                                    [_vm._v("未上牌")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "车架号" } },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入车架号",
                                              clearable: "",
                                            },
                                            model: {
                                              value: _vm.form.quoteVehicle.vin,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form.quoteVehicle,
                                                  "vin",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.quoteVehicle.vin",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "发动机号" } },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入发动机号",
                                              clearable: "",
                                            },
                                            model: {
                                              value:
                                                _vm.form.quoteVehicle.engineNo,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form.quoteVehicle,
                                                  "engineNo",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.quoteVehicle.engineNo",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "注册日期",
                                            prop: "quoteVehicle.registerDate",
                                          },
                                        },
                                        [
                                          _c("el-date-picker", {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              "value-format": "yyyy-MM-dd",
                                              type: "date",
                                              placeholder: "请选择注册日期",
                                              "picker-options":
                                                _vm.disabledLessThan,
                                            },
                                            model: {
                                              value:
                                                _vm.form.quoteVehicle
                                                  .registerDate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form.quoteVehicle,
                                                  "registerDate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.quoteVehicle.registerDate",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                { attrs: { gutter: 0 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "厂牌",
                                            prop: "brand",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                filterable: "",
                                                placeholder: "请选择厂牌",
                                              },
                                              on: {
                                                change: _vm.brandSeriesByBrand,
                                              },
                                              model: {
                                                value:
                                                  _vm.form.quoteVehicle.brand,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form.quoteVehicle,
                                                    "brand",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.quoteVehicle.brand",
                                              },
                                            },
                                            _vm._l(
                                              _vm.dictSource["BRAND"],
                                              function (item, index) {
                                                return _c("el-option", {
                                                  key: index,
                                                  attrs: {
                                                    label: item.dictName,
                                                    value: item.dictName,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "厂牌车系",
                                            prop: "brandSeries",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                filterable: "",
                                                placeholder: "请选择厂牌车系",
                                              },
                                              on: {
                                                change:
                                                  _vm.brandModeByBrandSeries,
                                              },
                                              model: {
                                                value:
                                                  _vm.form.quoteVehicle
                                                    .brandSeries,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form.quoteVehicle,
                                                    "brandSeries",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.quoteVehicle.brandSeries",
                                              },
                                            },
                                            _vm._l(
                                              _vm.brandSeriesList,
                                              function (item, index) {
                                                return _c("el-option", {
                                                  key: index,
                                                  attrs: {
                                                    label: item.dictName,
                                                    value: item.dictName,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "厂牌车型",
                                            prop: "brandMode",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                filterable: "",
                                                placeholder: "请选择厂牌车型",
                                                clearable: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.form.quoteVehicle
                                                    .brandMode,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form.quoteVehicle,
                                                    "brandMode",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.quoteVehicle.brandMode",
                                              },
                                            },
                                            _vm._l(
                                              _vm.brandModeList,
                                              function (item, index) {
                                                return _c("el-option", {
                                                  key: index,
                                                  attrs: {
                                                    label: item.dictName,
                                                    value: item.dictCode,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "品牌型号" } },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入品牌型号",
                                              clearable: "",
                                            },
                                            model: {
                                              value:
                                                _vm.form.quoteVehicle.modelName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form.quoteVehicle,
                                                  "modelName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.quoteVehicle.modelName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                { attrs: { gutter: 0 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "是否过户车",
                                            prop: "",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                filterable: "",
                                                placeholder: "请选择是否过户车",
                                              },
                                              model: {
                                                value: _vm.form.TransferCar,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "TransferCar",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.TransferCar",
                                              },
                                            },
                                            _vm._l(
                                              _vm.dictSource["TRANSFER_CAR"],
                                              function (item, index) {
                                                return _c("el-option", {
                                                  key: index,
                                                  attrs: {
                                                    label: item.dictName,
                                                    value: item.dictCode,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: { label: "座位数", prop: "" },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入座位数",
                                              clearable: "",
                                            },
                                            model: {
                                              value:
                                                _vm.form.quoteVehicle.seats,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form.quoteVehicle,
                                                  "seats",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.quoteVehicle.seats",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "尾气排量",
                                            prop: "",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入尾气排量",
                                              clearable: "",
                                            },
                                            model: {
                                              value:
                                                _vm.form.quoteVehicle
                                                  .displacement,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form.quoteVehicle,
                                                  "displacement",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.quoteVehicle.displacement",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "销售价格",
                                            prop: "",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入销售价格",
                                              clearable: "",
                                            },
                                            model: {
                                              value:
                                                _vm.form.quoteVehicle
                                                  .purchasePrice,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form.quoteVehicle,
                                                  "purchasePrice",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.quoteVehicle.purchasePrice",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                { attrs: { gutter: 0 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "车系名称",
                                            prop: "",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入车系名称",
                                              clearable: "",
                                            },
                                            model: {
                                              value:
                                                _vm.form.quoteVehicle
                                                  .vehicleAlias,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form.quoteVehicle,
                                                  "vehicleAlias",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.quoteVehicle.vehicleAlias",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "车辆备注",
                                            prop: "",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入车辆备注",
                                              clearable: "",
                                            },
                                            model: {
                                              value:
                                                _vm.form.quoteVehicle.remark,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form.quoteVehicle,
                                                  "remark",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.quoteVehicle.remark",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "行驶证车辆类型",
                                            prop: "quoteVehicle.vehicleType",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                filterable: "",
                                                placeholder:
                                                  "请选择行驶证车辆类型",
                                                clearable: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.form.quoteVehicle
                                                    .vehicleType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form.quoteVehicle,
                                                    "vehicleType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.quoteVehicle.vehicleType",
                                              },
                                            },
                                            _vm._l(
                                              _vm.VEHICLE_KINDS,
                                              function (item, index) {
                                                return _c("el-option", {
                                                  key: index,
                                                  attrs: {
                                                    label: item.dictName,
                                                    value: item.dictCode,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "使用性质",
                                            prop: "quoteVehicle.useAttribute",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                filterable: "",
                                                placeholder: "请选择使用性质",
                                                clearable: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.form.quoteVehicle
                                                    .useAttribute,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form.quoteVehicle,
                                                    "useAttribute",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.quoteVehicle.useAttribute",
                                              },
                                            },
                                            _vm._l(
                                              _vm.USE_ATTRIBUTE,
                                              function (item, index) {
                                                return _c("el-option", {
                                                  key: index,
                                                  attrs: {
                                                    label: item.dictName,
                                                    value: item.dictCode,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                { attrs: { gutter: 0 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "号牌种类",
                                            prop: "quoteVehicle.plateType",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                filterable: "",
                                                placeholder: "请选择号牌种类",
                                                clearable: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.form.quoteVehicle
                                                    .plateType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form.quoteVehicle,
                                                    "plateType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.quoteVehicle.plateType",
                                              },
                                            },
                                            _vm._l(
                                              _vm.PLATE_TYPE,
                                              function (item, index) {
                                                return _c("el-option", {
                                                  key: index,
                                                  attrs: {
                                                    label: item.dictName,
                                                    value: item.dictCode,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "能源类型",
                                            prop: "",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                filterable: "",
                                                placeholder: "请选择能源类型",
                                                clearable: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.form.quoteVehicle
                                                    .fuelType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form.quoteVehicle,
                                                    "fuelType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.quoteVehicle.fuelType",
                                              },
                                            },
                                            _vm._l(
                                              _vm.ENGRGY_TYPES,
                                              function (item, index) {
                                                return _c("el-option", {
                                                  key: index,
                                                  attrs: {
                                                    label: item.dictName,
                                                    value: item.dictCode,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "核定载质量(吨)",
                                            prop: "",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入核定载质量",
                                              clearable: "",
                                            },
                                            model: {
                                              value:
                                                _vm.form.quoteVehicle.tonNumber,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form.quoteVehicle,
                                                  "tonNumber",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.quoteVehicle.tonNumber",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "整备质量(吨)",
                                            prop: "",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入整备质量",
                                              clearable: "",
                                            },
                                            model: {
                                              value:
                                                _vm.form.quoteVehicle
                                                  .wholeWeight,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form.quoteVehicle,
                                                  "wholeWeight",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.quoteVehicle.wholeWeight",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-collapse",
                        {
                          staticStyle: { "margin-top": "10px" },
                          model: {
                            value: _vm.activeFour,
                            callback: function ($$v) {
                              _vm.activeFour = $$v
                            },
                            expression: "activeFour",
                          },
                        },
                        [
                          _c(
                            "el-collapse-item",
                            {
                              staticClass: "collapse-item-style",
                              attrs: { name: "1" },
                            },
                            [
                              _c("template", { slot: "title" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "title",
                                    staticStyle: { "line-height": "25px" },
                                  },
                                  [
                                    _c("FormLabel", {
                                      attrs: { "form-label": "关系人信息" },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "line-height": "40px",
                                    "padding-left": "18px",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "font-size": "13px",
                                        "font-weight": "bold",
                                        "margin-right": "8px",
                                      },
                                    },
                                    [_vm._v("投保人")]
                                  ),
                                  _c(
                                    "el-checkbox",
                                    {
                                      on: { change: _vm.handleCheckedCarOwner },
                                      model: {
                                        value:
                                          _vm.form.applicant
                                            .applicantSameAsOwner,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form.applicant,
                                            "applicantSameAsOwner",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.applicant.applicantSameAsOwner",
                                      },
                                    },
                                    [_vm._v("同车主")]
                                  ),
                                ],
                                1
                              ),
                              !_vm.form.applicant.applicantSameAsOwner
                                ? _c(
                                    "el-row",
                                    { attrs: { gutter: 0 } },
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 6 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "名称",
                                                prop: "applicant.name",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  placeholder: "请输入名称",
                                                  clearable: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.form.applicant.name,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form.applicant,
                                                      "name",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.applicant.name",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 6 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "证件类型",
                                                prop: "applicant.certificateType",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    filterable: "",
                                                    placeholder:
                                                      "请选择证件类型",
                                                    clearable: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form.applicant
                                                        .certificateType,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form.applicant,
                                                        "certificateType",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.applicant.certificateType",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.VI_CERTIFICATE_TYPE,
                                                  function (item, index) {
                                                    return _c("el-option", {
                                                      key: index,
                                                      attrs: {
                                                        label: item.dictName,
                                                        value: item.dictCode,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 6 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "证件号",
                                                prop: "applicant.certificateNo",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  placeholder: "请输入证件号",
                                                  clearable: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.form.applicant
                                                      .certificateNo,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form.applicant,
                                                      "certificateNo",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.applicant.certificateNo",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 6 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "联系方式",
                                                prop: "applicant.mobile",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  placeholder:
                                                    "请输入投保人手机号",
                                                  clearable: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.form.applicant.mobile,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form.applicant,
                                                      "mobile",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.applicant.mobile",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "line-height": "40px",
                                    "padding-left": "18px",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "font-size": "13px",
                                        "font-weight": "bold",
                                        "margin-right": "8px",
                                      },
                                    },
                                    [_vm._v("被保人")]
                                  ),
                                  !_vm.form.insurant.sameAsInsurer
                                    ? _c(
                                        "el-checkbox",
                                        {
                                          on: {
                                            change: _vm.handleCheckedCarOwner,
                                          },
                                          model: {
                                            value:
                                              _vm.form.insurant.sameAsOwner,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form.insurant,
                                                "sameAsOwner",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.insurant.sameAsOwner",
                                          },
                                        },
                                        [_vm._v("同车主")]
                                      )
                                    : _vm._e(),
                                  !_vm.form.insurant.sameAsOwner
                                    ? _c(
                                        "el-checkbox",
                                        {
                                          on: {
                                            change: _vm.handleCheckedApplicant,
                                          },
                                          model: {
                                            value:
                                              _vm.form.insurant.sameAsInsurer,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form.insurant,
                                                "sameAsInsurer",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.insurant.sameAsInsurer",
                                          },
                                        },
                                        [_vm._v("同投保人")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              !_vm.form.insurant.sameAsOwner &&
                              !_vm.form.insurant.sameAsInsurer
                                ? _c(
                                    "el-row",
                                    { attrs: { gutter: 0 } },
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 6 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "名称",
                                                prop: "insurant.name",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  placeholder: "请输入名称",
                                                  clearable: "",
                                                },
                                                model: {
                                                  value: _vm.form.insurant.name,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form.insurant,
                                                      "name",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.insurant.name",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 6 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "证件类型",
                                                prop: "insurant.certificateType",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    filterable: "",
                                                    placeholder:
                                                      "请选择证件类型",
                                                    clearable: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form.insurant
                                                        .certificateType,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form.insurant,
                                                        "certificateType",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.insurant.certificateType",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.VI_CERTIFICATE_TYPE,
                                                  function (item, index) {
                                                    return _c("el-option", {
                                                      key: index,
                                                      attrs: {
                                                        label: item.dictName,
                                                        value: item.dictCode,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 6 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "证件号",
                                                prop: "insurant.certificateNo",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  placeholder: "请输入证件号",
                                                  clearable: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.form.insurant
                                                      .certificateNo,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form.insurant,
                                                      "certificateNo",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.insurant.certificateNo",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 6 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "联系方式",
                                                prop: "insurant.mobile",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  placeholder: "请输入联系方式",
                                                  clearable: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.form.insurant.mobile,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form.insurant,
                                                      "mobile",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.insurant.mobile",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-collapse",
                        {
                          staticStyle: { "margin-top": "10px" },
                          model: {
                            value: _vm.activeFive,
                            callback: function ($$v) {
                              _vm.activeFive = $$v
                            },
                            expression: "activeFive",
                          },
                        },
                        [
                          _c(
                            "el-collapse-item",
                            {
                              staticClass: "collapse-item-style",
                              attrs: { name: "1" },
                            },
                            [
                              _c("template", { slot: "title" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "title",
                                    staticStyle: { "line-height": "25px" },
                                  },
                                  [
                                    _c("FormLabel", {
                                      attrs: { "form-label": "保单信息" },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _vm.showInsuranceType !== "0"
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "el-row",
                                        { attrs: { gutter: 0 } },
                                        [
                                          _c(
                                            "el-col",
                                            { attrs: { span: 6 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "交强险保单号",
                                                    prop: "bzRiskNo",
                                                  },
                                                },
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      placeholder:
                                                        "请输入交强险保单号",
                                                      clearable: "",
                                                    },
                                                    model: {
                                                      value: _vm.form.bzRiskNo,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "bzRiskNo",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.bzRiskNo",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 6 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "交强险起保日期",
                                                    prop: "bzRiskKind.effectiveStartTime",
                                                  },
                                                },
                                                [
                                                  _c("el-date-picker", {
                                                    staticStyle: {
                                                      width: "100%",
                                                    },
                                                    attrs: {
                                                      "value-format":
                                                        "yyyy-MM-dd",
                                                      type: "date",
                                                      placeholder:
                                                        "请选择交强险起保日期",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form.bzRiskKind
                                                          .effectiveStartTime,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form.bzRiskKind,
                                                          "effectiveStartTime",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.bzRiskKind.effectiveStartTime",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 6 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "交强险终保日期",
                                                    prop: "bzRiskKind.effectiveEndTime",
                                                  },
                                                },
                                                [
                                                  _c("el-date-picker", {
                                                    staticStyle: {
                                                      width: "100%",
                                                    },
                                                    attrs: {
                                                      "value-format":
                                                        "yyyy-MM-dd",
                                                      type: "date",
                                                      placeholder:
                                                        "请选择交强险终保日期",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form.bzRiskKind
                                                          .effectiveEndTime,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form.bzRiskKind,
                                                          "effectiveEndTime",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.bzRiskKind.effectiveEndTime",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 6 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "交强险折扣系数",
                                                    prop: "",
                                                  },
                                                },
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      placeholder:
                                                        "请输入交强险折扣系数",
                                                      clearable: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form
                                                          .bzDiscountFactor,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "bzDiscountFactor",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.bzDiscountFactor",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-row",
                                        { attrs: { gutter: 0 } },
                                        [
                                          _c(
                                            "el-col",
                                            { attrs: { span: 6 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "交强险金额",
                                                    prop: "bzPremium",
                                                  },
                                                },
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      placeholder:
                                                        "请输入交强险金额",
                                                      clearable: "",
                                                    },
                                                    model: {
                                                      value: _vm.form.bzPremium,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "bzPremium",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.bzPremium",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 6 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "车船税当年应缴",
                                                    prop: "quoteVehicleTax.curYearTax",
                                                  },
                                                },
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      placeholder:
                                                        "请输入车船税当年应缴",
                                                      clearable: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form.quoteVehicleTax
                                                          .curYearTax,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form
                                                            .quoteVehicleTax,
                                                          "curYearTax",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.quoteVehicleTax.curYearTax",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 6 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "车船税往年补缴",
                                                    prop: "quoteVehicleTax.lastYearPay",
                                                  },
                                                },
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      placeholder:
                                                        "请输入车船税往年补缴",
                                                      clearable: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form.quoteVehicleTax
                                                          .lastYearPay,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form
                                                            .quoteVehicleTax,
                                                          "lastYearPay",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.quoteVehicleTax.lastYearPay",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 6 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "车船税滞纳金",
                                                    prop: "quoteVehicleTax.delayPayMoney",
                                                  },
                                                },
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      placeholder:
                                                        "请输入车船税滞纳金",
                                                      clearable: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form.quoteVehicleTax
                                                          .delayPayMoney,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form
                                                            .quoteVehicleTax,
                                                          "delayPayMoney",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.quoteVehicleTax.delayPayMoney",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.showInsuranceType !== "1"
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "el-row",
                                        { attrs: { gutter: 0 } },
                                        [
                                          _c(
                                            "el-col",
                                            { attrs: { span: 6 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "商业险保单号",
                                                    prop: "bsRiskNo",
                                                  },
                                                },
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      placeholder:
                                                        "请输入商业险保单号",
                                                      clearable: "",
                                                    },
                                                    model: {
                                                      value: _vm.form.bsRiskNo,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "bsRiskNo",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.bsRiskNo",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 6 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "商业险起保日期",
                                                    prop: "bsRiskKind.effectiveStartTime",
                                                  },
                                                },
                                                [
                                                  _c("el-date-picker", {
                                                    staticStyle: {
                                                      width: "100%",
                                                    },
                                                    attrs: {
                                                      "value-format":
                                                        "yyyy-MM-dd",
                                                      type: "date",
                                                      placeholder:
                                                        "请选择商业险起保日期",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form.bsRiskKind
                                                          .effectiveStartTime,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form.bsRiskKind,
                                                          "effectiveStartTime",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.bsRiskKind.effectiveStartTime",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 6 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "商业险终保日期",
                                                    prop: "bsRiskKind.effectiveEndTime",
                                                  },
                                                },
                                                [
                                                  _c("el-date-picker", {
                                                    staticStyle: {
                                                      width: "100%",
                                                    },
                                                    attrs: {
                                                      "value-format":
                                                        "yyyy-MM-dd",
                                                      type: "date",
                                                      placeholder:
                                                        "请选择商业险终保日期",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form.bsRiskKind
                                                          .effectiveEndTime,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form.bsRiskKind,
                                                          "effectiveEndTime",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.bsRiskKind.effectiveEndTime",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 6 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "商业险折扣系数",
                                                  },
                                                },
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      placeholder:
                                                        "请输入商业险折扣系数",
                                                      clearable: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form
                                                          .bsDiscountFactor,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "bsDiscountFactor",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.bsDiscountFactor",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-row",
                                        { attrs: { gutter: 0 } },
                                        [
                                          _c(
                                            "el-col",
                                            { attrs: { span: 6 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "商业险总金额",
                                                    prop: "bsPremium",
                                                  },
                                                },
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      placeholder:
                                                        "请输入商业险总金额",
                                                      clearable: "",
                                                    },
                                                    model: {
                                                      value: _vm.form.bsPremium,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "bsPremium",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.bsPremium",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 6 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: { label: "总保单号" },
                                                },
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      placeholder:
                                                        "请输入总保单号",
                                                      clearable: "",
                                                    },
                                                    model: {
                                                      value: _vm.form.riskNo,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "riskNo",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "form.riskNo",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 6 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                { attrs: { label: "总保费" } },
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      placeholder:
                                                        "请输入总保费",
                                                      clearable: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form.totalPremium,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "totalPremium",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.totalPremium",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 6 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "续保标识",
                                                    prop: "renewalType",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      staticStyle: {
                                                        width: "100%",
                                                      },
                                                      attrs: {
                                                        filterable: "",
                                                        placeholder:
                                                          "请选择续保标识",
                                                        clearable: "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form.renewalType,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form,
                                                            "renewalType",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.renewalType",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.dictSource[
                                                        "RENEWAL_STATUS"
                                                      ],
                                                      function (item, index) {
                                                        return _c("el-option", {
                                                          key: index,
                                                          attrs: {
                                                            label:
                                                              item.dictName,
                                                            value:
                                                              item.dictCode,
                                                          },
                                                        })
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _vm.showInsuranceType !== "1"
                        ? _c(
                            "el-collapse",
                            {
                              staticStyle: { "margin-top": "10px" },
                              model: {
                                value: _vm.activeSix,
                                callback: function ($$v) {
                                  _vm.activeSix = $$v
                                },
                                expression: "activeSix",
                              },
                            },
                            [
                              _c(
                                "el-collapse-item",
                                {
                                  staticClass: "collapse-item-style",
                                  attrs: { name: "1" },
                                },
                                [
                                  _c("template", { slot: "title" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "title",
                                        staticStyle: { "line-height": "25px" },
                                      },
                                      [
                                        _c("FormLabel", {
                                          attrs: {
                                            "form-label": "商业险险别信息",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c(
                                    "table",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: { border: "1" },
                                    },
                                    [
                                      _c("colgroup", [
                                        _c("col", {
                                          staticStyle: { width: "30%" },
                                        }),
                                        _c("col"),
                                        _c("col", {
                                          staticStyle: { width: "30%" },
                                        }),
                                        _c("col", {
                                          staticStyle: { width: "5%" },
                                        }),
                                      ]),
                                      _c(
                                        "thead",
                                        {
                                          staticStyle: {
                                            "background-color": "#eeee",
                                          },
                                        },
                                        [
                                          _c(
                                            "tr",
                                            {
                                              staticStyle: {
                                                "font-size": "13px",
                                              },
                                            },
                                            [
                                              _c(
                                                "th",
                                                { attrs: { scope: "col" } },
                                                [
                                                  _c(
                                                    "span",
                                                    { staticClass: "redDot" },
                                                    [_vm._v("*")]
                                                  ),
                                                  _vm._v("险别名称"),
                                                ]
                                              ),
                                              _c(
                                                "th",
                                                { attrs: { scope: "col" } },
                                                [
                                                  _c(
                                                    "span",
                                                    { staticClass: "redDot" },
                                                    [_vm._v("*")]
                                                  ),
                                                  _vm._v(
                                                    "保险金额/赔偿限额(¥)"
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "th",
                                                { attrs: { scope: "col" } },
                                                [
                                                  _c(
                                                    "span",
                                                    { staticClass: "redDot" },
                                                    [_vm._v("*")]
                                                  ),
                                                  _vm._v("保费(¥)"),
                                                ]
                                              ),
                                              _c(
                                                "th",
                                                { attrs: { scope: "col" } },
                                                [_vm._v("操作")]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "tbody",
                                        [
                                          _vm._l(
                                            _vm.form.quoteDutys,
                                            function (item, index) {
                                              return _c(
                                                "tr",
                                                {
                                                  key: index,
                                                  staticClass: "tableTr",
                                                },
                                                [
                                                  _c(
                                                    "td",
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            "label-width":
                                                              "0px",
                                                            prop:
                                                              "quoteDutys." +
                                                              index +
                                                              ".riskkindName",
                                                            rules: [
                                                              {
                                                                required: true,
                                                                message:
                                                                  "请选择险别名称",
                                                                trigger:
                                                                  "change",
                                                              },
                                                            ],
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-select",
                                                            {
                                                              staticStyle: {
                                                                width: "100%",
                                                              },
                                                              attrs: {
                                                                filterable: "",
                                                                placeholder:
                                                                  "请选择险别名称",
                                                                clearable: "",
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.handleRiskItem(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  item.riskkindName,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "riskkindName",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "item.riskkindName",
                                                              },
                                                            },
                                                            _vm._l(
                                                              _vm.dutyAllCode,
                                                              function (
                                                                item,
                                                                index
                                                              ) {
                                                                return _c(
                                                                  "el-option",
                                                                  {
                                                                    key: index,
                                                                    attrs: {
                                                                      label:
                                                                        item.dictCode +
                                                                        "-" +
                                                                        item.dictName,
                                                                      value:
                                                                        item.dictCode,
                                                                    },
                                                                  }
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "td",
                                                    [
                                                      item.riskkindName
                                                        ? _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                "label-width":
                                                                  "0px",
                                                                prop:
                                                                  "quoteDutys." +
                                                                  index +
                                                                  ".unitAmount",
                                                                rules: [
                                                                  {
                                                                    required: true,
                                                                    message:
                                                                      "请输入保险金额",
                                                                    trigger:
                                                                      "blur",
                                                                  },
                                                                ],
                                                              },
                                                            },
                                                            [
                                                              _c("el-input", {
                                                                attrs: {
                                                                  placeholder:
                                                                    "请输入保险金额",
                                                                  clearable: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    item.unitAmount,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        item,
                                                                        "unitAmount",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "item.unitAmount",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "td",
                                                    [
                                                      _c(
                                                        "el-form-item",
                                                        {
                                                          attrs: {
                                                            "label-width":
                                                              "0px",
                                                            prop:
                                                              "quoteDutys." +
                                                              index +
                                                              ".amount",
                                                            rules: [
                                                              {
                                                                required: true,
                                                                message:
                                                                  "请输入保费",
                                                                trigger: "blur",
                                                              },
                                                            ],
                                                          },
                                                        },
                                                        [
                                                          _c("el-input", {
                                                            attrs: {
                                                              placeholder:
                                                                "请输入保费",
                                                              clearable: "",
                                                            },
                                                            model: {
                                                              value:
                                                                item.amount,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "amount",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item.amount",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c("td", [
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-remove-outline deleteIcon",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.deleteInsurance(
                                                            "one",
                                                            index
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ]),
                                                ]
                                              )
                                            }
                                          ),
                                          _c("tr", [
                                            _c(
                                              "td",
                                              { attrs: { colspan: "4" } },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "add_clo",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.addInsurance(
                                                          "one"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-circle-plus-outline addIcon",
                                                    }),
                                                    _c("span", [
                                                      _vm._v("添加险种"),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ],
                                        2
                                      ),
                                    ]
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-collapse",
                        {
                          staticStyle: { "margin-top": "10px" },
                          model: {
                            value: _vm.activeSeven,
                            callback: function ($$v) {
                              _vm.activeSeven = $$v
                            },
                            expression: "activeSeven",
                          },
                        },
                        [
                          _c(
                            "el-collapse-item",
                            {
                              staticClass: "collapse-item-style",
                              attrs: { name: "1" },
                            },
                            [
                              _c("template", { slot: "title" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "title",
                                    staticStyle: { "line-height": "25px" },
                                  },
                                  [
                                    _c("FormLabel", {
                                      attrs: { "form-label": "非车险信息" },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "table",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { border: "1" },
                                },
                                [
                                  _c("colgroup", [
                                    _c("col", {
                                      staticStyle: { width: "15%" },
                                    }),
                                    _c("col", {
                                      staticStyle: { width: "15%" },
                                    }),
                                    _c("col"),
                                    _c("col", {
                                      staticStyle: { width: "15%" },
                                    }),
                                    _c("col", {
                                      staticStyle: { width: "15%" },
                                    }),
                                    _c("col", {
                                      staticStyle: { width: "15%" },
                                    }),
                                    _c("col", { staticStyle: { width: "5%" } }),
                                  ]),
                                  _c(
                                    "thead",
                                    {
                                      staticStyle: {
                                        "background-color": "#eeee",
                                      },
                                    },
                                    [
                                      _c(
                                        "tr",
                                        {
                                          staticStyle: { "font-size": "13px" },
                                        },
                                        [
                                          _c(
                                            "th",
                                            { attrs: { scope: "col" } },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "redDot" },
                                                [_vm._v("*")]
                                              ),
                                              _vm._v("产品名称"),
                                            ]
                                          ),
                                          _c(
                                            "th",
                                            { attrs: { scope: "col" } },
                                            [_vm._v("产品编码")]
                                          ),
                                          _c(
                                            "th",
                                            { attrs: { scope: "col" } },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "redDot" },
                                                [_vm._v("*")]
                                              ),
                                              _vm._v("保单号"),
                                            ]
                                          ),
                                          _c(
                                            "th",
                                            { attrs: { scope: "col" } },
                                            [_vm._v("总保额（元）")]
                                          ),
                                          _c(
                                            "th",
                                            { attrs: { scope: "col" } },
                                            [_vm._v("份数")]
                                          ),
                                          _c(
                                            "th",
                                            { attrs: { scope: "col" } },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "redDot" },
                                                [_vm._v("*")]
                                              ),
                                              _vm._v("保费（元）"),
                                            ]
                                          ),
                                          _c(
                                            "th",
                                            { attrs: { scope: "col" } },
                                            [_vm._v("操作")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "tbody",
                                    [
                                      _vm._l(
                                        _vm.form.noCarInsurance,
                                        function (item, index) {
                                          return _c(
                                            "tr",
                                            {
                                              key: index,
                                              staticClass: "tableTr",
                                            },
                                            [
                                              _c(
                                                "td",
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      placeholder: "",
                                                      clearable: "",
                                                    },
                                                    model: {
                                                      value: item.productName,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "productName",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "item.productName",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "td",
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      placeholder: "",
                                                      clearable: "",
                                                    },
                                                    model: {
                                                      value: item.productCode,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "productCode",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "item.productCode",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "td",
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      placeholder: "",
                                                      clearable: "",
                                                    },
                                                    model: {
                                                      value: item.policyNo,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "policyNo",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "item.policyNo",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "td",
                                                [
                                                  _c("el-input-number", {
                                                    staticStyle: {
                                                      width: "100%",
                                                    },
                                                    attrs: { min: 0 },
                                                    model: {
                                                      value: item.amount,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "amount",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "item.amount",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "td",
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      placeholder: "",
                                                      clearable: "",
                                                    },
                                                    model: {
                                                      value: item.unitAmount,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "unitAmount",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "item.unitAmount",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "td",
                                                [
                                                  _c("el-input-number", {
                                                    staticStyle: {
                                                      width: "100%",
                                                    },
                                                    attrs: { min: 0 },
                                                    model: {
                                                      value: item.premium,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "premium",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "item.premium",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c("td", [
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-remove-outline deleteIcon",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.deleteInsurance(
                                                        "two",
                                                        index
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]),
                                            ]
                                          )
                                        }
                                      ),
                                      _c("tr", [
                                        _c("td", { attrs: { colspan: "7" } }, [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "add_clo",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addInsurance("two")
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-circle-plus-outline addIcon",
                                              }),
                                              _c("span", [_vm._v("添加险种")]),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                    ],
                                    2
                                  ),
                                ]
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-collapse",
                        {
                          staticStyle: { "margin-top": "10px" },
                          model: {
                            value: _vm.activeEight,
                            callback: function ($$v) {
                              _vm.activeEight = $$v
                            },
                            expression: "activeEight",
                          },
                        },
                        [
                          _c(
                            "el-collapse-item",
                            {
                              staticClass: "collapse-item-style",
                              attrs: { name: "1" },
                            },
                            [
                              _c("template", { slot: "title" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "title",
                                    staticStyle: { "line-height": "25px" },
                                  },
                                  [
                                    _c("FormLabel", {
                                      attrs: { "form-label": "附件列表" },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "el-row",
                                { attrs: { gutter: 0 } },
                                [
                                  _c(
                                    "el-upload",
                                    {
                                      staticClass: "upload-demo",
                                      attrs: {
                                        action:
                                          "https://jsonplaceholder.typicode.com/posts/",
                                        multiple: "",
                                        limit: 3,
                                        "on-success": _vm.handleSuccess,
                                        "show-file-list": false,
                                      },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "small",
                                            type: "primary",
                                          },
                                        },
                                        [_vm._v("选择多文件")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "table",
                                    {
                                      staticStyle: {
                                        width: "100%",
                                        "margin-top": "10px",
                                      },
                                      attrs: { border: "1" },
                                    },
                                    [
                                      _c("colgroup", [
                                        _c("col", {
                                          staticStyle: { width: "47.5%" },
                                        }),
                                        _c("col"),
                                        _c("col", {
                                          staticStyle: { width: "5%" },
                                        }),
                                      ]),
                                      _c(
                                        "thead",
                                        {
                                          staticStyle: {
                                            "background-color": "#eeee",
                                          },
                                        },
                                        [
                                          _c(
                                            "tr",
                                            {
                                              staticStyle: {
                                                "font-size": "13px",
                                              },
                                            },
                                            [
                                              _c(
                                                "th",
                                                { attrs: { scope: "col" } },
                                                [_vm._v("文件名")]
                                              ),
                                              _c(
                                                "th",
                                                { attrs: { scope: "col" } },
                                                [_vm._v("状态")]
                                              ),
                                              _c(
                                                "th",
                                                { attrs: { scope: "col" } },
                                                [_vm._v("操作")]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "tbody",
                                        _vm._l(
                                          _vm.form.uploadFiles,
                                          function (item, index) {
                                            return _c("tr", { key: index }, [
                                              _c("td", [
                                                _vm._v(_vm._s(item.fileName)),
                                              ]),
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(item.uplaodStatus)
                                                ),
                                              ]),
                                              _c("td", [
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-remove-outline",
                                                  staticStyle: {
                                                    "font-size": "24px",
                                                    color: "rgb(237, 98, 98)",
                                                    cursor: "pointer",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.deleteInsurance(
                                                        "three",
                                                        index
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]),
                                            ])
                                          }
                                        ),
                                        0
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ]),
        _c(
          "span",
          {
            staticClass: "dialog-footer",
            staticStyle: {
              display: "flex",
              "justify-content": "center",
              "margin-top": "10px",
            },
            attrs: { slot: "footer" },
            slot: "footer",
          },
          [
            _c(
              "el-button",
              {
                attrs: { size: "mini", icon: "el-icon-close" },
                on: { click: _vm.cancel },
              },
              [_vm._v(" 取消 ")]
            ),
            _c(
              "el-button",
              {
                attrs: { size: "mini", type: "primary", icon: "el-icon-check" },
                on: { click: _vm.save },
              },
              [_vm._v(" 确定 ")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }