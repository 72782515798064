import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import Pagination from '@/components/Pagination';
import OemSelect from '@/components/OemSelect';
import request from '@/api/finance';
import exportApi from '@/api/export';
import dict from '@/mixins/dict';
import PayResultDialog from './dialog/payResultDialog';
import PayDialog from './dialog/payDialog';
import PayDetailsDialog from './dialog/payDetailsDialog';
export default {
  components: {
    Pagination: Pagination,
    OemSelect: OemSelect,
    PayResultDialog: PayResultDialog,
    PayDialog: PayDialog,
    PayDetailsDialog: PayDetailsDialog
  },
  mixins: [initHeight, dict],
  data: function data() {
    return {
      drawer: false,
      searchForm: {},
      total: 0,
      orgData: {
        salesOrgName: "",
        salesOrgCode: ""
      },
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      loading: false,
      payStatusList: [{
        value: 'PENDING_PAY',
        label: '待付款'
      }, {
        value: 'PAYING',
        label: '付款中'
      }, {
        value: 'PAY_SUCCESS',
        label: '付款成功'
      }, {
        value: 'PAY_FAILED',
        label: '付款失败'
      }],
      payTypeList: [{
        value: 'ONLINE_AUTO',
        label: '线上自动付款'
      }, {
        value: 'OFFLINE_MANUAL',
        label: '线下人工付款'
      }],
      showPayResultDialog: false,
      showPayDialog: false,
      showDetailsDialog: false,
      currentPayCode: '',
      currentItem: {}
    };
  },
  mounted: function mounted() {
    this.loadData();
  },
  methods: {
    // 导出
    exportFn: function exportFn() {
      var _this = this;
      var postData = {
        "fields": {
          "payCode": "付款编号",
          "payableAmount": "应付金额",
          "payableAmountTax": "应激税额",
          "paidAmount": "实付金额",
          // "accountName": "账户名称",
          "bankName": "开户行名称",
          "bankCardNo": "银行卡号",
          "salesOrgName": "业务机构",
          "salemanName": "业务员",
          "creatorByName": "申请人",
          "createdTime": "申请时间",
          "payTypeDisplay": "付款方式",
          "payStatusDisplay": "付款状态"
        },
        "name": "付款单管理",
        "params": this.searchForm,
        "url": process.env.VUE_APP_APIDOMAIN + "/vi-core-service/finance/pay"
      };
      exportApi.generateExcel(postData).then(function (res) {
        _this.$confirm("".concat(res.msg), '提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: "前往查看",
          showCancelButton: false
        }).then(function () {
          _this.$router.push({
            path: '/meExportLog'
          });
        }).catch(function () {});
      });
    },
    nodeClick: function nodeClick(val) {
      this.searchForm.salesOrgCode = val.salesOrgCode;
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {};
      this.orgData = {};
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    // 刷新
    breakData: function breakData() {
      this.searchData();
    },
    loadData: function loadData() {
      var _this2 = this;
      //获取列表数据
      this.loading = true;
      this.drawer = false;
      this.showPayResultDialog = false;
      this.showPayDialog = false;
      this.showDetailsDialog = false;
      var postData = _objectSpread(_objectSpread({}, this.listQuery), this.searchForm);
      request.searchPay(postData).then(function (res) {
        _this2.loading = false;
        _this2.tableData = res.data.list;
        _this2.total = res.data.page.recordsTotal;
      }).catch(function (err) {
        _this2.loading = false;
      });
    },
    // 查看保单详情
    viewDetail: function viewDetail(item) {
      this.showDetailsDialog = true;
      this.currentPayCode = item.payCode;
    },
    // 付款
    payment: function payment(item) {
      this.showPayDialog = true;
      this.currentItem = item;
    },
    // 录入付款结果
    recordPayResult: function recordPayResult(item) {
      this.currentItem = item;
      this.showPayResultDialog = true;
    }
  }
};