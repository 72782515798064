var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.formLoading,
          expression: "formLoading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: {},
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "540px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "detail",
                  staticStyle: { "margin-bottom": "-20px" },
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-width": "110px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "标题：", prop: "messageTitle" },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.currentType === "viewDetail",
                                  placeholder: "请输入标题",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.messageTitle,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "messageTitle", $$v)
                                  },
                                  expression: "form.messageTitle",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "发送范围：" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  attrs: {
                                    disabled: _vm.currentType === "viewDetail",
                                  },
                                  on: { change: _vm.messageRidioChange },
                                  model: {
                                    value: _vm.form.messageRange,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "messageRange", $$v)
                                    },
                                    expression: "form.messageRange",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: "ALL" } }, [
                                    _vm._v("所有人"),
                                  ]),
                                  _c("el-radio", { attrs: { label: "ORG" } }, [
                                    _vm._v("指定机构"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.form.messageRange === "ORG"
                    ? _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "指定机构：",
                                    prop: "orgCode ",
                                  },
                                },
                                [
                                  _c("OemSelect", {
                                    attrs: {
                                      isMultiple: true,
                                      disabled:
                                        _vm.currentType === "viewDetail",
                                      isContainOem: false,
                                      searchForm: _vm.orgForm,
                                      salesOemName: _vm.salesOemName,
                                    },
                                    on: { nodeClick: _vm.nodeClick },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "正文：",
                                prop: "messageContent",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.currentType === "viewDetail",
                                  type: "textarea",
                                  placeholder: "请输入内容",
                                  autosize: { minRows: 3, maxRows: 3 },
                                  resize: "none",
                                },
                                model: {
                                  value: _vm.form.messageContent,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "messageContent", $$v)
                                  },
                                  expression: "form.messageContent",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _vm.currentType === "add"
            ? _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-check",
                    type: "primary",
                    plain: "",
                    size: "mini",
                  },
                  on: { click: _vm.save },
                },
                [_vm._v("立即发送")]
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: { click: _vm.closePage },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }