import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.regexp.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import OrgSelect from '@/components/OrgSelect';
import rolesRequest from '@/api/roles';
import ssoConfig from '@/api/ssoConfig';
import { mapGetters } from 'vuex';
import { constantRoutes } from '@/router/modules';
import dict from '@/mixins/dict';
export default {
  props: {
    itemId: {
      type: Number,
      default: null
    },
    opType: {
      type: String,
      default: ""
    },
    refresh: {
      type: Boolean,
      default: true
    },
    SsoStrategyList: {
      type: Array,
      default: []
    }
  },
  computed: _objectSpread({}, mapGetters(['permission_routes'])),
  components: {
    OrgSelect: OrgSelect
  },
  mixins: [dict],
  data: function data() {
    return {
      roles: [],
      AllList: [],
      form: {
        // roleName: '',
        // roleMenu: '',
        // bizType: null
      },
      loading: false,
      formRules: {
        roleName: [{
          required: true,
          message: '请输入角色名称',
          trigger: 'blur'
        }, {
          min: 2,
          max: 50,
          message: "角色名称格式有误",
          trigger: ["blur", "change"]
        }],
        bizType: [{
          required: true,
          message: '请选择业务类型',
          trigger: 'change'
        }]
      },
      treeData: [{
        code: 'All',
        label: '系统菜单',
        children: []
      }],
      props: {
        label: 'label',
        children: 'children'
      },
      chekcedMenus: []
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        if (this.opType != 'add') {
          this.loadData();
        } else {
          // this.$refs.tree.setCheckedNodes([]);
          this.form = {
            // roleName: '',
            // roleMenu: ''
          };
          this.$refs['detail'].resetFields();
        }
      }
    }
  },
  created: function created() {
    if (this.opType == 'edit') {
      this.loadData();
    }
  },
  mounted: function mounted() {
    var _this = this;
    constantRoutes.forEach(function (element) {
      if (element.meta && !element.hidden) {
        var obj = {
          code: element.name,
          label: element.meta.title,
          children: []
        };
        if (!_.isEmpty(element.children)) {
          element.children.forEach(function (eitem) {
            if (!eitem.hidden) {
              var cobj = {
                code: eitem.name,
                label: eitem.meta.title,
                children: []
              };
              if (!_.isEmpty(eitem.children)) {
                eitem.children.forEach(function (_item) {
                  var cobjs = {
                    code: _item.name,
                    label: _item.meta.title,
                    children: []
                  };
                  cobj.children.push(cobjs);
                });
              }
              obj.children.push(cobj);
            }
          });
        }
        _this.treeData[0].children.push(obj);
      }
    });
  },
  methods: {
    getOrgCode: function getOrgCode(val) {
      this.getAllList();
      this.getRoles(val);
    },
    getAllList: function getAllList() {
      var _this2 = this;
      ssoConfig.getAllList().then(function (res) {
        if (res.code == 200) {
          _this2.AllList = res.data ? res.data : [];
        }
      });
    },
    nodeClick: function nodeClick(val) {
      this.form.bizOrgCode = val.salesOrgCode;
    },
    getRoles: function getRoles(val) {
      var _this3 = this;
      rolesRequest.getRoleDropdownListByBizType(val).then(function (res) {
        _this3.roles = res.data ? res.data : [];
      });
    },
    loadData: function loadData() {
      var _this4 = this;
      if (!this.itemId) return;
      this.loading = true;
      ssoConfig.getDetail({
        id: this.itemId
      }).then(function (res) {
        _this4.loading = false;
        _this4.form = res.data;
        _this4.getAllList();
        _this4.getRoles(res.data.bizType);
        _this4.form.managerRoleIds = res.data.managerRoleIds.split(',').map(function (item) {
          return parseInt(item);
        });
        _this4.form.normalRoleIds = res.data.normalRoleIds.split(',').map(function (item) {
          return parseInt(item);
        });
        _this4.$forceUpdate();
        if (!_.isEmpty(res.data.roleMenu)) {
          var checkedItems = JSON.parse(res.data.roleMenu);
          _this4.$nextTick(function () {
            this.$refs.tree.setCheckedNodes(checkedItems);
          });
        }
      }).catch(function (err) {
        _this4.loading = false;
        _this4.form = {
          // roleName: '',
          // roleMenu: ''
        };
      });
    },
    save: function save() {
      var _this5 = this;
      this.$refs["detail"].validate(function (valid, object) {
        if (valid) {
          console.log(_this5.form);
          _this5.form.managerRoleIds = _this5.form.managerRoleIds.toString();
          _this5.form.normalRoleIds = _this5.form.normalRoleIds.toString();
          // return
          var localRes = _this5.opType == 'add' ? ssoConfig.add(_this5.form) : ssoConfig.edit(_this5.form);
          localRes.then(function (res) {
            if (res.code == 200) {
              _this5.$message({
                type: "success",
                message: res.msg
              });
              _this5.closePage();
            }
          });
        }
      });
    },
    closePage: function closePage() {
      this.form = {
        roleName: '',
        roleMenu: ''
      };
      this.$refs['detail'].resetFields();
      this.$emit('closePage');
    }
  }
};