//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import CustomerTypeDialog from '../dialog/customerTypeDialog';
import customersRequest from '@/api/customers';
export default {
  components: {
    CustomerTypeDialog: CustomerTypeDialog
  },
  mixins: [initHeight],
  props: {
    currentObj: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    curRenewalConfigParams: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      tableData: [],
      showDialog: false,
      opType: '',
      currentItem: {},
      configId: '',
      loading: false
    };
  },
  watch: {
    currentObj: {
      handler: function handler(val) {
        this.tableData = val.distinguishConversionList;
        this.configId = val.coreCustomerRenewalTaskConfigId;
      },
      deep: true
    }
  },
  created: function created() {
    this.configId = this.currentObj.coreCustomerRenewalTaskConfigId;
    this.tableData = this.currentObj.distinguishConversionList;
  },
  methods: {
    add: function add(type) {
      this.opType = type;
      this.showDialog = true;
    },
    edit: function edit(type, item) {
      this.opType = type;
      this.currentItem = item;
      this.showDialog = true;
    },
    refreshData: function refreshData() {
      var isDialogNoRefresh = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.showDialog = false;
      if (isDialogNoRefresh) return;
      this.getList();
    },
    getList: function getList() {
      var _this = this;
      this.loading = true;
      customersRequest.getAllDistinguish(this.configId).then(function (res) {
        _this.loading = false;
        if (res.code === 200) {
          _this.tableData = res.data;
        }
        ;
      });
    }
  }
};