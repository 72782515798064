var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "app-m-cls",
          staticStyle: { padding: "13px 15px", "max-height": "91vh" },
        },
        [
          _c(
            "el-container",
            { staticStyle: { "background-color": "#fff" } },
            [
              _vm.bizType === "OEM"
                ? _c(
                    "el-aside",
                    {
                      style: {
                        height: _vm.containerHeight + "px",
                        "background-color": "white",
                        "border-right": "1px solid #eee",
                        overflow: "hidden",
                      },
                      attrs: { width: "345px" },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "搜索", maxlength: "40" },
                        model: {
                          value: _vm.filterText,
                          callback: function ($$v) {
                            _vm.filterText = $$v
                          },
                          expression: "filterText",
                        },
                      }),
                      _c(
                        "div",
                        {
                          style: {
                            height: _vm.containerHeight + 10 + "px",
                            "overflow-y": "auto",
                            "margin-top": "5px",
                          },
                        },
                        [
                          _c("el-tree", {
                            ref: "tree",
                            staticClass: "filter-tree",
                            staticStyle: { "padding-top": "5px" },
                            attrs: {
                              "expand-on-click-node": false,
                              indent: 10,
                              "node-key": "id",
                              "default-expanded-keys":
                                _vm.oemDefaultCheckedKeys,
                              "highlight-current": _vm.highlightCurrent,
                              "filter-node-method": _vm.oemFilterNode,
                              data: _vm.oemData,
                              props: _vm.oemDefaultProps,
                            },
                            on: { "node-click": _vm.oemHandleNodeClick },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-container",
                [
                  _c(
                    "el-main",
                    {
                      style: {
                        height: _vm.containerHeight,
                        "background-color": "white",
                      },
                    },
                    [
                      _c(
                        "el-tabs",
                        {
                          attrs: { type: "card" },
                          on: { "tab-click": _vm.handleTabClick },
                          model: {
                            value: _vm.activeName,
                            callback: function ($$v) {
                              _vm.activeName = $$v
                            },
                            expression: "activeName",
                          },
                        },
                        [
                          _c(
                            "el-tab-pane",
                            { attrs: { label: "基本信息", name: "third" } },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "justify-content": "flex-end",
                                    "margin-bottom": "10px",
                                  },
                                },
                                [
                                  _vm.dealerId
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { size: "mini" },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.editOrgInfo("edit")
                                            },
                                          },
                                        },
                                        [_vm._v("编辑")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm.bizType === "BROKER"
                                ? _c(
                                    "table",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: { border: "1" },
                                    },
                                    [
                                      _c("colgroup", [
                                        _c("col", {
                                          staticStyle: { width: "12%" },
                                        }),
                                        _c("col", {
                                          staticStyle: { width: "38%" },
                                        }),
                                        _c("col", {
                                          staticStyle: { width: "12%" },
                                        }),
                                        _c("col", {
                                          staticStyle: { width: "38%" },
                                        }),
                                      ]),
                                      _c("tbody", [
                                        _c("tr", [
                                          _c("th", [_vm._v("机构名称：")]),
                                          _c("td", [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.organizationInfo.fullName
                                                )
                                              ),
                                            ]),
                                          ]),
                                          _c("th", [_vm._v("公司名称：")]),
                                          _c("td", [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.organizationInfo.shortName
                                                )
                                              ),
                                            ]),
                                          ]),
                                        ]),
                                        _c("tr", [
                                          _c("th", [_vm._v("机构编码：")]),
                                          _c("td", [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.organizationInfo
                                                    .customCode || "-"
                                                )
                                              ),
                                            ]),
                                          ]),
                                          _c("th", [_vm._v("门店类型：")]),
                                          _c("td", [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.organizationInfo
                                                    .orgTypeName || "-"
                                                )
                                              ),
                                            ]),
                                          ]),
                                        ]),
                                        _c("tr", [
                                          _c("th", [_vm._v("所在省市：")]),
                                          _c("td", [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.organizationInfo
                                                    .provinceName
                                                ) +
                                                  " " +
                                                  _vm._s(
                                                    _vm.organizationInfo
                                                      .cityName
                                                  ) +
                                                  " " +
                                                  _vm._s(
                                                    _vm.organizationInfo
                                                      .districtName
                                                  )
                                              ),
                                            ]),
                                          ]),
                                          _c("th", [_vm._v("联系电话：")]),
                                          _c("td", [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.organizationInfo.telephone
                                                )
                                              ),
                                            ]),
                                          ]),
                                        ]),
                                        _c("tr", [
                                          _c("th", [_vm._v("联系地址：")]),
                                          _c(
                                            "td",
                                            { attrs: { colspan: "3" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    padding: "5px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.organizationInfo
                                                        .address || "-"
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.bizType === "DEALER"
                                ? _c(
                                    "table",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: { border: "1" },
                                    },
                                    [
                                      _c("colgroup", [
                                        _c("col", {
                                          staticStyle: { width: "12%" },
                                        }),
                                        _c("col", {
                                          staticStyle: { width: "38%" },
                                        }),
                                        _c("col", {
                                          staticStyle: { width: "12%" },
                                        }),
                                        _c("col", {
                                          staticStyle: { width: "38%" },
                                        }),
                                      ]),
                                      _c("tbody", [
                                        _c("tr", [
                                          _c("th", [_vm._v("机构名称：")]),
                                          _c("td", [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.organizationInfo
                                                    .dealerName
                                                )
                                              ),
                                            ]),
                                          ]),
                                          _c("th", [_vm._v("公司名称：")]),
                                          _c("td", [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.organizationInfo
                                                    .companyName
                                                )
                                              ),
                                            ]),
                                          ]),
                                        ]),
                                        _c("tr", [
                                          _c("th", [_vm._v("机构编码：")]),
                                          _c("td", [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.organizationInfo
                                                    .dealerCode || "-"
                                                )
                                              ),
                                            ]),
                                          ]),
                                          _c("th", [_vm._v("所在省市：")]),
                                          _c("td", [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.organizationInfo
                                                    .provinceName
                                                ) +
                                                  " " +
                                                  _vm._s(
                                                    _vm.organizationInfo
                                                      .cityName
                                                  ) +
                                                  " " +
                                                  _vm._s(
                                                    _vm.organizationInfo
                                                      .districtName
                                                  )
                                              ),
                                            ]),
                                          ]),
                                        ]),
                                        _c("tr", [
                                          _c("th", [_vm._v("联系人：")]),
                                          _c("td", [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.organizationInfo
                                                    .contactName
                                                )
                                              ),
                                            ]),
                                          ]),
                                          _c("th", [_vm._v("联系电话：")]),
                                          _c("td", [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.organizationInfo
                                                    .contactPhone
                                                )
                                              ),
                                            ]),
                                          ]),
                                        ]),
                                        _c("tr", [
                                          _c("th", [
                                            _vm._v("统一社会信用代码："),
                                          ]),
                                          _c("td", [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.organizationInfo
                                                    .certificateNo
                                                )
                                              ),
                                            ]),
                                          ]),
                                          _c("th", [_vm._v("邮政编码：")]),
                                          _c("td", [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.organizationInfo
                                                    .postCode || "-"
                                                )
                                              ),
                                            ]),
                                          ]),
                                        ]),
                                        _c("tr", [
                                          _c("th", [_vm._v("联系地址：")]),
                                          _c(
                                            "td",
                                            { attrs: { colspan: "3" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    padding: "5px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.organizationInfo
                                                        .detailAddr || "-"
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.bizType === "OEM"
                                ? _c(
                                    "table",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: { border: "1" },
                                    },
                                    [
                                      _c("colgroup", [
                                        _c("col", {
                                          staticStyle: { width: "12%" },
                                        }),
                                        _c("col", {
                                          staticStyle: { width: "38%" },
                                        }),
                                        _c("col", {
                                          staticStyle: { width: "12%" },
                                        }),
                                        _c("col", {
                                          staticStyle: { width: "38%" },
                                        }),
                                      ]),
                                      _c("tbody", [
                                        _c("tr", [
                                          _c("th", [_vm._v("机构名称：")]),
                                          _c("td", [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.organizationInfo
                                                    .dealerName
                                                )
                                              ),
                                            ]),
                                          ]),
                                          _c("th", [_vm._v("公司名称：")]),
                                          _c("td", [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.organizationInfo
                                                    .companyName
                                                )
                                              ),
                                            ]),
                                          ]),
                                        ]),
                                        _c("tr", [
                                          _c("th", [_vm._v("机构编码：")]),
                                          _c("td", [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.organizationInfo
                                                    .dealerCode || "-"
                                                )
                                              ),
                                            ]),
                                          ]),
                                          _c("th", [_vm._v("所在省市：")]),
                                          _c("td", [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.organizationInfo
                                                    .provinceName
                                                ) +
                                                  " " +
                                                  _vm._s(
                                                    _vm.organizationInfo
                                                      .cityName
                                                  ) +
                                                  " " +
                                                  _vm._s(
                                                    _vm.organizationInfo
                                                      .districtName
                                                  )
                                              ),
                                            ]),
                                          ]),
                                        ]),
                                        _c("tr", [
                                          _c("th", [_vm._v("联系人：")]),
                                          _c("td", [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.organizationInfo
                                                    .contactName
                                                )
                                              ),
                                            ]),
                                          ]),
                                          _c("th", [_vm._v("联系电话：")]),
                                          _c("td", [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.organizationInfo
                                                    .contactPhone
                                                )
                                              ),
                                            ]),
                                          ]),
                                        ]),
                                        _c("tr", [
                                          _c("th", [
                                            _vm._v("统一社会信用代码："),
                                          ]),
                                          _c("td", [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.organizationInfo
                                                    .certificateNo
                                                )
                                              ),
                                            ]),
                                          ]),
                                          _c("th", [_vm._v("邮政编码：")]),
                                          _c("td", [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.organizationInfo
                                                    .postCode || "-"
                                                )
                                              ),
                                            ]),
                                          ]),
                                        ]),
                                        _c("tr", [
                                          _c("th", [_vm._v("联系地址：")]),
                                          _c(
                                            "td",
                                            { attrs: { colspan: "3" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    padding: "5px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.organizationInfo
                                                        .detailAddr || "-"
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "el-tab-pane",
                            { attrs: { label: "出单渠道", name: "first" } },
                            [
                              _vm.bizType !== "DEALER"
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "justify-content": "flex-end",
                                        "margin-bottom": "10px",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            icon: "el-icon-plus",
                                            size: "mini",
                                          },
                                          on: { click: _vm.addChannelItem },
                                        },
                                        [_vm._v("新增")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "el-table",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value: _vm.loading,
                                      expression: "loading",
                                    },
                                  ],
                                  ref: "multipleTable",
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    size: "mini",
                                    data: _vm.tableData,
                                    border: "",
                                    height: _vm.containerHeight - 115 + "px",
                                    "header-cell-style": {
                                      background: "#F7F7F7",
                                    },
                                    "highlight-current-row": "",
                                  },
                                  on: {
                                    "row-click": _vm.handleRowChange,
                                    "selection-change":
                                      _vm.handleSelectionChange,
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "insCorpCode",
                                      label: "保险公司",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getSupplyerName(
                                                    scope.row.insCorpCode
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "insCityCode",
                                      label: "行驶区域",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getCityName(
                                                    scope.row.insCityCode
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "insCorpChannel",
                                      label: "渠道名称",
                                      "show-overflow-tooltip": "",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "insCorpChannelNickname",
                                      label: "渠道别名",
                                      "show-overflow-tooltip": "",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "featureTypes",
                                      label: "渠道功能",
                                      "show-overflow-tooltip": "",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getFeatureTypeName(
                                                    scope.row.featureTypes
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                  _vm.bizType !== "DEALER"
                                    ? _c("el-table-column", {
                                        attrs: {
                                          label: "操作",
                                          align: "center",
                                          width: "150",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: { size: "mini" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.updateItem(
                                                            scope.row
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("编辑")]
                                                  ),
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        type: "danger",
                                                        plain: "",
                                                        size: "mini",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.removeItems(
                                                            scope.row
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("删除")]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          603284618
                                        ),
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-tab-pane",
                            { attrs: { label: "业务配置", name: "second" } },
                            [
                              _c(
                                "el-table",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value: _vm.loading,
                                      expression: "loading",
                                    },
                                  ],
                                  ref: "multipleTable",
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    size: "mini",
                                    data: _vm.bizData,
                                    height: _vm.containerHeight - 115 + "px",
                                    border: "",
                                    "header-cell-style": {
                                      background: "#F7F7F7",
                                    },
                                    "highlight-current-row": "",
                                  },
                                  on: {
                                    "row-click": _vm.handleRowChange,
                                    "selection-change":
                                      _vm.handleSelectionChange,
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "categoryName",
                                      label: "配置名称",
                                      width: "150",
                                      "show-overflow-tooltip": "",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "categoryOptions",
                                      label: "配置项目",
                                      "show-overflow-tooltip": "",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "操作",
                                      align: "center",
                                      width: "80",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: { size: "mini" },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.updateBusinessItem(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("编辑")]
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.bizType === "BROKER"
                            ? _c(
                                "el-tab-pane",
                                {
                                  attrs: {
                                    label: "下属机构",
                                    name: "fourthly",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "justify-content": "flex-end",
                                        "margin-bottom": "10px",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            icon: "el-icon-plus",
                                            size: "mini",
                                          },
                                          on: { click: _vm.addOrg },
                                        },
                                        [_vm._v("新增")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-table",
                                    {
                                      directives: [
                                        {
                                          name: "loading",
                                          rawName: "v-loading",
                                          value: _vm.loading,
                                          expression: "loading",
                                        },
                                      ],
                                      ref: "multipleTable",
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        size: "mini",
                                        data: _vm.orgTableData,
                                        height:
                                          _vm.containerHeight - 115 + "px",
                                        border: "",
                                        "header-cell-style": {
                                          background: "#F7F7F7",
                                        },
                                        "highlight-current-row": "",
                                      },
                                      on: {
                                        "row-click": _vm.handleRowChange,
                                        "selection-change":
                                          _vm.handleSelectionChange,
                                      },
                                    },
                                    [
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "fullName",
                                          label: "机构名称",
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "type",
                                          label: "机构类型",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.getTypeName(
                                                          scope.row.type
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          58646486
                                        ),
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "操作",
                                          align: "center",
                                          width: "80",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        type: "danger",
                                                        plain: "",
                                                        size: "mini",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.deleteOrgItem(
                                                            scope.row
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("删除")]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          1993866267
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "Dialog",
        {
          attrs: {
            title:
              "出单渠道" +
              (_vm.opType == "view"
                ? "查看"
                : _vm.opType == "update"
                ? "修改"
                : "新增"),
            width: "540px",
            "form-loading": _vm.formLoading,
          },
          on: { handleClose: _vm.cancel },
          model: {
            value: _vm.showDialog,
            callback: function ($$v) {
              _vm.showDialog = $$v
            },
            expression: "showDialog",
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                slot: "content",
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "100px",
              },
              slot: "content",
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 22 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "保险公司", prop: "insCorpCode" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                filterable: "",
                                placeholder: "请选择保险公司",
                                clearable: "",
                              },
                              on: { change: _vm.selectInsCropCode },
                              model: {
                                value: _vm.form.insCorpCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "insCorpCode", $$v)
                                },
                                expression: "form.insCorpCode",
                              },
                            },
                            _vm._l(_vm.supplyers, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: {
                                  label: item.shortName,
                                  value: item.code,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 22 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "行驶区域", prop: "insCityCode" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                filterable: "",
                                placeholder: "请选择行驶区域",
                                clearable: "",
                              },
                              on: { change: _vm.selectInsCityCode },
                              model: {
                                value: _vm.form.insCityCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "insCityCode", $$v)
                                },
                                expression: "form.insCityCode",
                              },
                            },
                            _vm._l(_vm.cityList, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: {
                                  label: item.insCityName,
                                  value: item.insCityCode,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 22 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "渠道名称", prop: "channelId" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                filterable: "",
                                placeholder: "请选择渠道",
                                clearable: "",
                              },
                              on: { change: _vm.selectInsChannelName },
                              model: {
                                value: _vm.form.channelId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "channelId", $$v)
                                },
                                expression: "form.channelId",
                              },
                            },
                            _vm._l(_vm.channelNameList, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: {
                                  label: item.insChannelName,
                                  value: item.channelConfigId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 22 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "渠道别名",
                            prop: "insCorpChannelNickname",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: {
                              "max-width": "370px",
                              width: "360px",
                            },
                            attrs: {
                              maxlength: "40",
                              clearable: "",
                              placeholder: "请输入渠道别名",
                            },
                            model: {
                              value: _vm.form.insCorpChannelNickname,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "insCorpChannelNickname",
                                  $$v
                                )
                              },
                              expression: "form.insCorpChannelNickname",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "渠道功能", prop: "featureTypes" } },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              model: {
                                value: _vm.form.featureTypes,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "featureTypes", $$v)
                                },
                                expression: "form.featureTypes",
                              },
                            },
                            _vm._l(_vm.ChannelFunction, function (item, index) {
                              return _c(
                                "el-checkbox",
                                { key: index, attrs: { label: item.itemCode } },
                                [_vm._v(_vm._s(item.itemName))]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _vm.opType != "view"
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        size: "mini",
                        type: "primary",
                        icon: "el-icon-check",
                      },
                      on: { click: _vm.submit },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.opType == "update" ? "保存" : "保存") +
                          " "
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", icon: "el-icon-close" },
                  on: { click: _vm.cancel },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.opType == "view" ? "关闭" : "取消") + " "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "Dialog",
        {
          attrs: {
            title:
              "业务配置" +
              (_vm.opType == "view"
                ? "查看"
                : _vm.opType == "update"
                ? "编辑"
                : "新增"),
            width: "450px",
            "form-loading": _vm.formLoading,
          },
          on: { handleClose: _vm.cancel },
          model: {
            value: _vm.showBzDialog,
            callback: function ($$v) {
              _vm.showBzDialog = $$v
            },
            expression: "showBzDialog",
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "formBz",
              attrs: {
                slot: "content",
                model: _vm.formBz,
                rules: _vm.rules,
                "label-width": "160px",
              },
              slot: "content",
            },
            [
              _c(
                "el-row",
                _vm._l(_vm.formBz.settingItems, function (item, index) {
                  return _c(
                    "el-col",
                    { key: index },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { "line-height": "40px", width: "100%" },
                          attrs: {
                            label: item.display,
                            prop: "settingItems." + index + ".storeValue",
                            rules: [
                              {
                                required: item.required,
                                message: "请完善" + item.display,
                              },
                            ],
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticStyle: { "max-width": "320px" } },
                                [
                                  (_vm._.toLower(
                                    _vm._.toLower(item.inputType)
                                  ) == "text" ||
                                    _vm._.toLower(
                                      _vm._.toLower(item.inputType)
                                    ) == "email") &&
                                  _vm._.toLower(
                                    _vm._.toLower(item.inputType)
                                  ) != "number" &&
                                  _vm._.toLower(
                                    _vm._.toLower(item.inputType)
                                  ) != "select"
                                    ? _c("el-input", {
                                        attrs: {
                                          clearable: "",
                                          size: "mini",
                                          maxlength: "40",
                                        },
                                        on: {
                                          blur: function ($event) {
                                            return _vm.inputValue(item)
                                          },
                                        },
                                        model: {
                                          value: item.storeValue,
                                          callback: function ($$v) {
                                            _vm.$set(item, "storeValue", $$v)
                                          },
                                          expression: "item.storeValue",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._.toLower(
                                    _vm._.toLower(item.inputType)
                                  ) == "number"
                                    ? _c("el-input", {
                                        attrs: {
                                          clearable: "",
                                          size: "mini",
                                          maxlength: "10",
                                        },
                                        on: {
                                          input: [
                                            function ($event) {
                                              item.storeValue =
                                                item.storeValue.replace(
                                                  /[^\d]/g,
                                                  ""
                                                )
                                            },
                                            function ($event) {
                                              return _vm.inputValue(item)
                                            },
                                          ],
                                        },
                                        model: {
                                          value: item.storeValue,
                                          callback: function ($$v) {
                                            _vm.$set(item, "storeValue", $$v)
                                          },
                                          expression: "item.storeValue",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._.toLower(
                                    _vm._.toLower(item.inputType)
                                  ) == "select"
                                    ? _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            placeholder: "请选择",
                                            size: "mini",
                                            clearable: "",
                                          },
                                          on: { change: _vm.updateValue },
                                          model: {
                                            value: item.storeValue,
                                            callback: function ($$v) {
                                              _vm.$set(item, "storeValue", $$v)
                                            },
                                            expression: "item.storeValue",
                                          },
                                        },
                                        _vm._l(
                                          item.options,
                                          function (_item, _index) {
                                            return _c("el-option", {
                                              key: _index,
                                              attrs: {
                                                label: _item.name,
                                                value: _item.value,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _vm.opType != "view"
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        size: "mini",
                        type: "primary",
                        icon: "el-icon-check",
                        disabled: !_vm.canConfirm,
                      },
                      on: { click: _vm.submitBz },
                    },
                    [_vm._v(" 保存 ")]
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", icon: "el-icon-close" },
                  on: { click: _vm.cancelBz },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.opType == "view" ? "关闭" : "取消") + " "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "Dialog",
        {
          attrs: {
            title: (_vm.opType == "edit" ? "编辑" : "新增") + "机构信息",
            width: "540px",
            "form-loading": _vm.formLoading,
          },
          on: { handleClose: _vm.cancel },
          model: {
            value: _vm.showOrgDialog,
            callback: function ($$v) {
              _vm.showOrgDialog = $$v
            },
            expression: "showOrgDialog",
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "formOrg",
              attrs: {
                slot: "content",
                model: _vm.formOrg,
                rules: _vm.rules,
                "label-width": "140px",
              },
              slot: "content",
            },
            [
              _c(
                "el-row",
                [
                  false
                    ? _c(
                        "el-col",
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "父级机构：", prop: "parentOrg" },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  maxlength: "40",
                                  disabled: "",
                                  clearable: "",
                                  placeholder: "请输入父级机构",
                                },
                                model: {
                                  value: _vm.formOrg.parentOrg,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formOrg, "parentOrg", $$v)
                                  },
                                  expression: "formOrg.parentOrg",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  false
                    ? _c(
                        "el-col",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "机构类型：", prop: "type" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: {
                                    width: "100%",
                                    "max-width": "320px",
                                  },
                                  attrs: {
                                    placeholder: "请选择机构类型",
                                    size: "mini",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.formOrg.type,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formOrg, "type", $$v)
                                    },
                                    expression: "formOrg.type",
                                  },
                                },
                                _vm._l(
                                  _vm.dictSource["ORGTYPELIST"],
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.dictName,
                                        value: item.dictCode,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "dealerName", label: "机构名称：" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              maxlength: "40",
                              clearable: "",
                              placeholder: "请输入机构名称",
                            },
                            model: {
                              value: _vm.formOrg.dealerName,
                              callback: function ($$v) {
                                _vm.$set(_vm.formOrg, "dealerName", $$v)
                              },
                              expression: "formOrg.dealerName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "机构编码：" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              disabled: _vm.opType === "edit",
                              maxlength: "40",
                              clearable: "",
                              placeholder: "请输入机构编码",
                            },
                            on: { input: _vm.customCodeInput },
                            model: {
                              value: _vm.formOrg.dealerCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.formOrg, "dealerCode", $$v)
                              },
                              expression: "formOrg.dealerCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "公司名称：", prop: "companyName" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              maxlength: "40",
                              clearable: "",
                              placeholder: "请输入公司名称",
                            },
                            model: {
                              value: _vm.formOrg.companyName,
                              callback: function ($$v) {
                                _vm.$set(_vm.formOrg, "companyName", $$v)
                              },
                              expression: "formOrg.companyName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "联系人：", prop: "contactName" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              maxlength: "40",
                              clearable: "",
                              placeholder: "请输入联系人",
                            },
                            model: {
                              value: _vm.formOrg.contactName,
                              callback: function ($$v) {
                                _vm.$set(_vm.formOrg, "contactName", $$v)
                              },
                              expression: "formOrg.contactName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "联系电话：", prop: "contactPhone" },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              maxlength: "15",
                              clearable: "",
                              placeholder: "请输入联系电话",
                              oninput:
                                "value=value.replace(/[^\\d\\(\\)\\-]/g,'')",
                            },
                            model: {
                              value: _vm.formOrg.contactPhone,
                              callback: function ($$v) {
                                _vm.$set(_vm.formOrg, "contactPhone", $$v)
                              },
                              expression: "formOrg.contactPhone",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "统一社会信用代码：",
                            prop: "certificateNo",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              maxlength: "20",
                              clearable: "",
                              placeholder: "请输入统一社会信用代码",
                              oninput: "value=value.replace(/[\\W]/g,'')",
                            },
                            model: {
                              value: _vm.formOrg.certificateNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.formOrg, "certificateNo", $$v)
                              },
                              expression: "formOrg.certificateNo",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "开户银行：", prop: "bankCode" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: {
                                width: "100%",
                                "max-width": "320px",
                              },
                              attrs: {
                                placeholder: "请选择开户银行",
                                size: "mini",
                                clearable: "",
                              },
                              on: { change: _vm.getBankName },
                              model: {
                                value: _vm.formOrg.bankCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formOrg, "bankCode", $$v)
                                },
                                expression: "formOrg.bankCode",
                              },
                            },
                            _vm._l(_vm.banksList, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: {
                                  label: item.shortName,
                                  value: item.code,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "开户行名称：",
                            prop: "bankCustomerName",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              maxlength: "40",
                              clearable: "",
                              placeholder: "请输入开户行名称",
                            },
                            model: {
                              value: _vm.formOrg.bankCustomerName,
                              callback: function ($$v) {
                                _vm.$set(_vm.formOrg, "bankCustomerName", $$v)
                              },
                              expression: "formOrg.bankCustomerName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "开户账号：", prop: "bankAccount" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              maxlength: "30",
                              clearable: "",
                              placeholder: "请输入开户账号",
                              oninput:
                                "value=value.replace(/[^\\d\\(\\)\\-]/g,'')",
                            },
                            model: {
                              value: _vm.formOrg.bankAccount,
                              callback: function ($$v) {
                                _vm.$set(_vm.formOrg, "bankAccount", $$v)
                              },
                              expression: "formOrg.bankAccount",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "邮政编码：", prop: "postCode" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              maxlength: "10",
                              clearable: "",
                              placeholder: "请输入邮政编码",
                              oninput:
                                "value=value.replace(/[^\\d\\(\\)\\-]/g,'')",
                            },
                            model: {
                              value: _vm.formOrg.postCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.formOrg, "postCode", $$v)
                              },
                              expression: "formOrg.postCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "所在地区：", prop: "districtCode" },
                        },
                        [
                          _c("el-cascader", {
                            ref: "cascaderAddr",
                            staticStyle: {
                              width: "100%",
                              "max-width": "320px",
                            },
                            attrs: {
                              filterable: "",
                              placeholder: "请选择所在地区",
                              options: _vm.options,
                            },
                            on: { change: _vm.handleChange },
                            model: {
                              value: _vm.formOrg.districtCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.formOrg, "districtCode", $$v)
                              },
                              expression: "formOrg.districtCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "详细地址：" } },
                        [
                          _c("el-input", {
                            staticStyle: {
                              width: "100%",
                              "max-width": "320px",
                            },
                            attrs: {
                              type: "textarea",
                              rows: 2,
                              maxlength: "250",
                              clearable: "",
                              placeholder: "请输入详细地址",
                            },
                            model: {
                              value: _vm.formOrg.detailAddr,
                              callback: function ($$v) {
                                _vm.$set(_vm.formOrg, "detailAddr", $$v)
                              },
                              expression: "formOrg.detailAddr",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    loading: _vm.loadingEdit,
                    size: "mini",
                    type: "primary",
                    icon: "el-icon-check",
                    disabled: !_vm.dealerId,
                  },
                  on: { click: _vm.submitOrg },
                },
                [_vm._v(" 保存 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", icon: "el-icon-close" },
                  on: { click: _vm.cancelOrg },
                },
                [_vm._v(" 取消 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }