var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-m-cls", staticStyle: {} }, [
    _c(
      "div",
      {
        ref: "header",
        staticClass: "header-cls",
        staticStyle: { "max-height": "500px", overflow: "auto" },
      },
      [
        _c(
          "el-card",
          { attrs: { shadow: "never" } },
          [
            _c(
              "el-form",
              {
                ref: "detail",
                staticStyle: { "margin-bottom": "-20px" },
                attrs: {
                  model: _vm.form,
                  rules: _vm.rules,
                  "label-width": "110px",
                  size: "mini",
                },
              },
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "车主名：", prop: "ownerName" } },
                          [
                            _c("el-input", {
                              attrs: {
                                maxlength: "40",
                                placeholder: "请输入车主名",
                              },
                              model: {
                                value: _vm.form.ownerName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "ownerName", $$v)
                                },
                                expression: "form.ownerName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "联系电话：", prop: "ownerMobile" },
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                type: "text",
                                onkeyup:
                                  "value=value.replace(/[^0-9a-zA-Z\\-.]+/,'')",
                                maxlength: "13",
                                placeholder: "请输入联系电话",
                                clearable: false,
                              },
                              on: {
                                blur: function ($event) {
                                  return _vm.mobileChange(_vm.form)
                                },
                              },
                              model: {
                                value: _vm.form.ownerMobile,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "ownerMobile", $$v)
                                },
                                expression: "form.ownerMobile",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "车架号：", prop: "vin" } },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "margin-right": "10px",
                                  width: "100%",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "17",
                                    placeholder: "请输入车架号",
                                    clearable: "",
                                  },
                                  on: { input: _vm.inputvin },
                                  model: {
                                    value: _vm.form.vin,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "vin", $$v)
                                    },
                                    expression: "form.vin",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm.bizType === "BROKER"
                      ? _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "车销门店：",
                                  prop: "ownerOrgCode",
                                },
                              },
                              [
                                _c("OrgSelect", {
                                  attrs: { searchForm: _vm.orgForm },
                                  on: { nodeClick: _vm.nodeClick },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm.bizType === "OEM"
                      ? _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "车销门店：",
                                  prop: "ownerOrgCode",
                                },
                              },
                              [
                                _c("OemSelect", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    searchForm: _vm.orgForm,
                                    isContainOem: false,
                                  },
                                  on: { nodeClick: _vm.nodeClick },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "车销门店：",
                                  prop: "ownerOrgCode",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { disabled: "" },
                                  model: {
                                    value: _vm.form.ownerOrgName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "ownerOrgName", $$v)
                                    },
                                    expression: "form.ownerOrgName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "销售员：" } },
                          [
                            _c("el-input", {
                              attrs: {
                                maxlength: "40",
                                placeholder: "请输入销售员",
                                clearable: "",
                              },
                              model: {
                                value: _vm.form.salemanName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "salemanName", $$v)
                                },
                                expression: "form.salemanName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "车销日期：", prop: "salesDate" } },
                          [
                            _c("el-date-picker", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: "请选择车销日期",
                                type: "date",
                                format: "yyyy-MM-dd",
                                "value-format": "yyyy-MM-dd HH:mm:ss",
                              },
                              model: {
                                value: _vm.form.salesDate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "salesDate", $$v)
                                },
                                expression: "form.salesDate",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "发动机号：", prop: "engineNo" } },
                          [
                            _c("el-input", {
                              attrs: {
                                maxlength: "40",
                                placeholder: "请输入发动机号",
                                clearable: "",
                              },
                              on: { input: _vm.inputEngineNo },
                              model: {
                                value: _vm.form.engineNo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "engineNo", $$v)
                                },
                                expression: "form.engineNo",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "品牌型号：", prop: "modelName" } },
                          [
                            _c("el-input", {
                              attrs: {
                                maxlength: "40",
                                placeholder: "请输入品牌型号",
                                clearable: "",
                              },
                              model: {
                                value: _vm.form.modelName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "modelName", $$v)
                                },
                                expression: "form.modelName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "车辆类型：", prop: "vehicleType" },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  placeholder: "请选择车辆类型",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.vehicleType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "vehicleType", $$v)
                                  },
                                  expression: "form.vehicleType",
                                },
                              },
                              _vm._l(_vm.VEHICLE_KINDS, function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    label: item.dictName,
                                    value: item.dictCode,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "能源类型：", prop: "fuelType" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  placeholder: "请选择能源类型",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.fuelType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "fuelType", $$v)
                                  },
                                  expression: "form.fuelType",
                                },
                              },
                              _vm._l(_vm.ENGRGY_TYPES, function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    label: item.dictName,
                                    value: item.dictCode,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "整备质量：", prop: "wholeWeight" },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                maxlength: "40",
                                placeholder: "请输入整备质量",
                                clearable: "",
                                oninput: "value=value.replace(/[^\\d]/g,'')",
                              },
                              model: {
                                value: _vm.form.wholeWeight,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "wholeWeight", $$v)
                                },
                                expression: "form.wholeWeight",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticStyle: {
          display: "flex",
          "justify-content": "center",
          "margin-top": "8px",
        },
      },
      [
        _c(
          "el-button",
          {
            attrs: { size: "mini", icon: "el-icon-close" },
            on: { click: _vm.closePage },
          },
          [_vm._v("关闭")]
        ),
        _c(
          "el-button",
          {
            attrs: { size: "mini", icon: "el-icon-check", type: "primary" },
            on: { click: _vm.save },
          },
          [_vm._v("保存")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }