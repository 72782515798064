var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    [
      _c(
        "el-col",
        { attrs: { span: 8 } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "车主名称：", prop: "ownerName" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入车主名称", clearable: "" },
                model: {
                  value: _vm.form.ownerName,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form,
                      "ownerName",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.ownerName",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-col",
        { attrs: { span: 8 } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "证件类型：", prop: "ownerCertificateType" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    filterable: "",
                    placeholder: "请选择证件类型",
                    clearable: "",
                  },
                  on: { change: _vm.selectCertificate },
                  model: {
                    value: _vm.form.ownerCertificateType,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "ownerCertificateType", $$v)
                    },
                    expression: "form.ownerCertificateType",
                  },
                },
                _vm._l(_vm.VI_CERTIFICATE_TYPE, function (group) {
                  return _c(
                    "el-option-group",
                    { key: group.label, attrs: { label: group.label } },
                    _vm._l(group.options, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.dictName, value: item.dictCode },
                      })
                    }),
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-col",
        { attrs: { span: 8 } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "证件号码：", prop: "ownerCertificateNo" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入证件号码", clearable: "" },
                on: {
                  input: function ($event) {
                    return _vm.limitInput3($event, "ownerCertificateNo")
                  },
                  blur: _vm.certificateNoChange,
                },
                model: {
                  value: _vm.form.ownerCertificateNo,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form,
                      "ownerCertificateNo",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.ownerCertificateNo",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-col",
        { attrs: { span: 8 } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "所在省市：", prop: "address" } },
            [
              _c("el-cascader", {
                ref: "address",
                staticStyle: { width: "100%" },
                attrs: {
                  filterable: "",
                  clearable: "",
                  placeholder: "请选择所在省市",
                  options: _vm.regionOptions,
                },
                on: { change: _vm.handleChange },
                model: {
                  value: _vm.form.address,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "address", $$v)
                  },
                  expression: "form.address",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-col",
        { attrs: { span: 8 } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "详细地址：", prop: "ownerAddress" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入详细地址", clearable: "" },
                model: {
                  value: _vm.form.ownerAddress,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form,
                      "ownerAddress",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.ownerAddress",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-col",
        { attrs: { span: 8 } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "联系电话：", prop: "ownerMobile" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入联系电话",
                  maxlength: "11",
                  clearable: "",
                  oninput: "value=value.replace(/[^\\d]/g,'')",
                },
                on: {
                  blur: function ($event) {
                    return _vm.mobileChange(_vm.form)
                  },
                },
                model: {
                  value: _vm.form.ownerMobile,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form,
                      "ownerMobile",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.ownerMobile",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-col",
        { attrs: { span: 8 } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "电子邮箱：" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入电子邮箱", clearable: "" },
                on: { blur: _vm.emailRule },
                model: {
                  value: _vm.form.ownerEmail,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form,
                      "ownerEmail",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.ownerEmail",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }