var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "app-m-cls",
          staticStyle: { padding: "13px 15px", "max-height": "91vh" },
        },
        [
          _c(
            "el-container",
            { staticStyle: { "background-color": "#fff" } },
            [
              _c(
                "el-aside",
                {
                  style: {
                    height: _vm.containerHeight + "px",
                    "background-color": "white",
                    "border-right": "1px solid #eee",
                  },
                  attrs: { width: "360px" },
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm._.isEmpty(_vm.insCropDataList),
                              expression: "!_.isEmpty(insCropDataList)",
                            },
                          ],
                          staticStyle: { display: "flex" },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "搜索", maxlength: "40" },
                            model: {
                              value: _vm.filterText,
                              callback: function ($$v) {
                                _vm.filterText = $$v
                              },
                              expression: "filterText",
                            },
                          }),
                          _c(
                            "el-button",
                            {
                              staticStyle: { "margin-left": "5px" },
                              attrs: { size: "mini", icon: "el-icon-plus" },
                              on: { click: _vm.showRegion },
                            },
                            [_vm._v("添加地区")]
                          ),
                        ],
                        1
                      ),
                      _c("el-tree", {
                        ref: "tree",
                        staticClass: "filter-tree",
                        staticStyle: { "padding-top": "5px" },
                        attrs: {
                          "expand-on-click-node": false,
                          indent: 10,
                          "node-key": "shortName",
                          "default-checked-keys": _vm.defaultCheckedKeys,
                          "highlight-current": _vm.highlightCurrent,
                          data: _vm.insCropDataList,
                          props: _vm.defaultProps,
                          "default-expand-all": "",
                          "filter-node-method": _vm.filterNode,
                        },
                        on: { "node-click": _vm.handleNodeClick },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-container",
                [
                  _c(
                    "el-main",
                    {
                      style: {
                        height: _vm.containerHeight,
                        "background-color": "white",
                      },
                    },
                    [
                      _c(
                        "el-tabs",
                        {
                          attrs: { type: "card" },
                          model: {
                            value: _vm.activeName,
                            callback: function ($$v) {
                              _vm.activeName = $$v
                            },
                            expression: "activeName",
                          },
                        },
                        [
                          _c(
                            "el-tab-pane",
                            { attrs: { label: "增值服务配置", name: "first" } },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "justify-content": "flex-end",
                                    "margin-bottom": "10px",
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        icon: "el-icon-plus",
                                        size: "mini",
                                      },
                                      on: { click: _vm.addItem },
                                    },
                                    [_vm._v("添加配置")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.currentNodeData.insCityCode,
                                          expression:
                                            "currentNodeData.insCityCode",
                                        },
                                      ],
                                      attrs: {
                                        icon: "el-icon-copy-document",
                                        size: "mini",
                                      },
                                      on: { click: _vm.copyDefaultConfig },
                                    },
                                    [_vm._v("复制默认配置")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-table",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value: _vm.loading,
                                      expression: "loading",
                                    },
                                  ],
                                  ref: "multipleTable",
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    size: "mini",
                                    data: _vm.currentInsCropData.list,
                                    height: _vm.containerHeight - 115 + "px",
                                    border: "",
                                    "header-cell-style": {
                                      background: "#F7F7F7",
                                    },
                                    "highlight-current-row": "",
                                  },
                                  on: {
                                    "row-click": _vm.handleRowChange,
                                    "selection-change":
                                      _vm.handleSelectionChange,
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "序号",
                                      type: "index",
                                      width: "50",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "riskName",
                                      width: "150",
                                      label: "险别名称",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "riskRemark",
                                      label: "描述",
                                      "show-overflow-tooltip": "",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "操作",
                                      align: "center",
                                      width: "150",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            !scope.row.hasValue
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: { size: "mini" },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.viewDetail(
                                                          scope.row
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("编辑")]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "danger",
                                                  plain: "",
                                                  size: "mini",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.removeItems(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("删除")]
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "Dialog",
        {
          attrs: {
            title: (_vm.opType == "update" ? "编辑" : "添加") + "增值服务配置",
            width: "900px",
            "form-loading": _vm.formLoading,
          },
          on: { handleClose: _vm.cancel },
          model: {
            value: _vm.showDialog,
            callback: function ($$v) {
              _vm.showDialog = $$v
            },
            expression: "showDialog",
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                slot: "content",
                rules: _vm.formRules,
                model: _vm.form,
                "label-width": "120",
              },
              slot: "content",
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    border: "1px solid #eee",
                    padding: "10px",
                    height: "170px",
                  },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "增值服务名称",
                                "label-width": "100px",
                                prop: "riskCode",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "200px" },
                                      attrs: {
                                        filterable: "",
                                        placeholder: "请选择增值服务",
                                      },
                                      model: {
                                        value: _vm.form.riskCode,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "riskCode", $$v)
                                        },
                                        expression: "form.riskCode",
                                      },
                                    },
                                    _vm._l(_vm.duyList, function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.name,
                                          value: item.code,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "可选择多个服务项目" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.form.multiple,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "multiple", $$v)
                                    },
                                    expression: "form.multiple",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: true } }, [
                                    _vm._v("是"),
                                  ]),
                                  _c("el-radio", { attrs: { label: false } }, [
                                    _vm._v("否"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    {
                      staticStyle: { "margin-top": "5px" },
                      attrs: { gutter: 20 },
                    },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "描述", "label-width": "100px" },
                            },
                            [
                              _c("el-input", {
                                staticClass: "resizeNone",
                                attrs: {
                                  size: "mini",
                                  placeholder: "填写描述",
                                  maxlength: "900",
                                  type: "textarea",
                                  rows: "5",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.riskRemark,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "riskRemark", $$v)
                                  },
                                  expression: "form.riskRemark",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticStyle: { "margin-top": "15px" } }, [
                _c(
                  "table",
                  { staticStyle: { width: "100%" }, attrs: { border: "1" } },
                  [
                    _c("colgroup", [
                      _c("col", { staticStyle: { width: "15%" } }),
                      _c("col", { staticStyle: { width: "12%" } }),
                      _c("col"),
                      _c("col", { staticStyle: { width: "10%" } }),
                      _c("col", { staticStyle: { width: "10%" } }),
                      _c("col", { staticStyle: { width: "10%" } }),
                      _c("col", { staticStyle: { width: "10%" } }),
                      _c("col"),
                    ]),
                    _c(
                      "thead",
                      { staticStyle: { "background-color": "#eeee" } },
                      [
                        _c("tr", { staticStyle: { "font-size": "13px" } }, [
                          _c("th", { attrs: { scope: "col" } }, [
                            _vm._v("服务项目名称"),
                          ]),
                          _c("th", { attrs: { scope: "col" } }, [
                            _vm._v("编码"),
                          ]),
                          _c("th", { attrs: { scope: "col" } }, [
                            _vm._v("预设值"),
                          ]),
                          _c("th", { attrs: { scope: "col" } }, [
                            _vm._v("默认值"),
                          ]),
                          _c("th", { attrs: { scope: "col" } }, [
                            _vm._v("默认投保"),
                          ]),
                          _c("th", { attrs: { scope: "col" } }, [
                            _vm._v("是否只读"),
                          ]),
                          _c("th", { attrs: { scope: "col" } }),
                        ]),
                      ]
                    ),
                    _c(
                      "tbody",
                      _vm._l(_vm.form.serviceitems, function (item, index) {
                        return _c("tr", { key: index }, [
                          _c(
                            "td",
                            {
                              class: {
                                errHeight:
                                  _vm.errHeightData[
                                    "serviceitems." + index + ".serviceName"
                                  ],
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop:
                                      "serviceitems." + index + ".serviceName",
                                    rules: {
                                      required: true,
                                      message: "请输入名称",
                                      trigger: "blur",
                                    },
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      size: "mini",
                                      maxlength: "50",
                                      placeholder: "输入名称",
                                    },
                                    on: {
                                      blur: function ($event) {
                                        return _vm.inputBlur(
                                          "serviceitems." +
                                            index +
                                            ".serviceName"
                                        )
                                      },
                                    },
                                    model: {
                                      value: item.serviceName,
                                      callback: function ($$v) {
                                        _vm.$set(item, "serviceName", $$v)
                                      },
                                      expression: "item.serviceName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            {
                              class: {
                                errHeight:
                                  _vm.errHeightData[
                                    "serviceitems." + index + ".serviceCode"
                                  ],
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop:
                                      "serviceitems." + index + ".serviceCode",
                                    rules: {
                                      required: true,
                                      message: "请输入编码",
                                      trigger: "blur",
                                    },
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      size: "mini",
                                      maxlength: "20",
                                      placeholder: "输入编码",
                                      oninput: "if(value<0)value=0",
                                    },
                                    on: {
                                      blur: function ($event) {
                                        return _vm.inputBlur(
                                          "serviceitems." +
                                            index +
                                            ".serviceCode"
                                        )
                                      },
                                      input: function ($event) {
                                        return _vm.viewServiceCode(
                                          item.serviceCode
                                        )
                                      },
                                    },
                                    model: {
                                      value: item.serviceCode,
                                      callback: function ($$v) {
                                        _vm.$set(item, "serviceCode", $$v)
                                      },
                                      expression: "item.serviceCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("td", [
                            _c("div", { staticClass: "custom-div" }, [
                              _c(
                                "div",
                                { staticClass: "tags-list inner-content" },
                                [
                                  _vm._l(item.options, function (_tag, _index) {
                                    return _c(
                                      "el-tag",
                                      {
                                        key: _index,
                                        attrs: {
                                          closable: _vm.opType != "view",
                                          "disable-transitions": false,
                                          size: "small",
                                        },
                                        on: {
                                          close: function ($event) {
                                            return _vm.handleTagClose(
                                              _tag,
                                              index
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" " + _vm._s(_tag) + " ")]
                                    )
                                  }),
                                  item.inputVisible
                                    ? _c("el-input", {
                                        ref: "saveTagInput",
                                        refInFor: true,
                                        staticClass: "input-new-tag",
                                        attrs: {
                                          type: "text",
                                          disabled: _vm.opType == "view",
                                          placeholder: "输入数字",
                                          size: "small",
                                        },
                                        on: {
                                          blur: function ($event) {
                                            return _vm.handleInputConfirm(
                                              item,
                                              index
                                            )
                                          },
                                          input: function ($event) {
                                            _vm.inputValue = _vm.onInput(
                                              _vm.inputValue,
                                              0,
                                              1000,
                                              0
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.inputValue,
                                          callback: function ($$v) {
                                            _vm.inputValue = $$v
                                          },
                                          expression: "inputValue",
                                        },
                                      })
                                    : _c(
                                        "el-button",
                                        {
                                          staticClass: "button-new-tag",
                                          attrs: {
                                            disabled: _vm.opType == "view",
                                            size: "small",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.showInput(item)
                                            },
                                          },
                                        },
                                        [_vm._v("+ 添加")]
                                      ),
                                ],
                                2
                              ),
                            ]),
                          ]),
                          _c(
                            "td",
                            [
                              _c(
                                "el-form-item",
                                [
                                  _c("el-input", {
                                    attrs: {
                                      size: "mini",
                                      type: "Number",
                                      placeholder: "默认值",
                                    },
                                    on: {
                                      input: function ($event) {
                                        item.serviceTimes = _vm.onInput(
                                          item.serviceTimes,
                                          0,
                                          1000,
                                          0
                                        )
                                      },
                                    },
                                    model: {
                                      value: item.serviceTimes,
                                      callback: function ($$v) {
                                        _vm.$set(item, "serviceTimes", $$v)
                                      },
                                      expression: "item.serviceTimes",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            [
                              _c(
                                "el-form-item",
                                [
                                  _c("el-switch", {
                                    attrs: { disabled: _vm.opType == "view" },
                                    model: {
                                      value: item.checked,
                                      callback: function ($$v) {
                                        _vm.$set(item, "checked", $$v)
                                      },
                                      expression: "item.checked",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            [
                              _c(
                                "el-form-item",
                                [
                                  _c("el-switch", {
                                    attrs: { disabled: _vm.opType == "view" },
                                    model: {
                                      value: item.readonly,
                                      callback: function ($$v) {
                                        _vm.$set(item, "readonly", $$v)
                                      },
                                      expression: "item.readonly",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("td", [
                            _c("i", {
                              staticClass: "el-icon-circle-plus-outline",
                              staticStyle: {
                                "font-size": "24px",
                                color: "#356ef6",
                              },
                              on: { click: _vm.addServiceitem },
                            }),
                            _c("i", {
                              staticClass: "el-icon-remove-outline",
                              staticStyle: {
                                "font-size": "24px",
                                color: "#ed6262",
                                "margin-left": "8px",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteServiceitem(index)
                                },
                              },
                            }),
                          ]),
                        ])
                      }),
                      0
                    ),
                  ]
                ),
              ]),
            ]
          ),
          _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
            _c("div", { staticClass: "footer-btn" }, [
              _c(
                "div",
                { staticClass: "footer-btn-list" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "mini",
                        type: "primary",
                        icon: "el-icon-check",
                        disabled: _vm.isClick,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.submit(_vm.opType)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.opType == "add" ? "保存" : "保存"))]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", icon: "el-icon-close" },
                      on: { click: _vm.cancel },
                    },
                    [_vm._v(" 取消 ")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "Dialog",
        {
          attrs: { title: "添加地区", width: "540px" },
          on: { handleClose: _vm.cancelRegion },
          model: {
            value: _vm.showRegionDialog,
            callback: function ($$v) {
              _vm.showRegionDialog = $$v
            },
            expression: "showRegionDialog",
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "formRegion",
              attrs: {
                slot: "content",
                rules: _vm.formRegionRules,
                model: _vm.formRegion,
                "label-width": "100px",
              },
              slot: "content",
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 22 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "保险公司：", prop: "insCorpCode" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    filterable: "",
                                    placeholder: "请选择保险公司",
                                  },
                                  model: {
                                    value: _vm.formRegion.insCorpCode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formRegion,
                                        "insCorpCode",
                                        $$v
                                      )
                                    },
                                    expression: "formRegion.insCorpCode",
                                  },
                                },
                                _vm._l(
                                  _vm.insCropDataList,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.shortName,
                                        value: item.code,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 22 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "省份：", prop: "PROVINCE" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                filterable: "",
                                placeholder: "请选择省份",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.getCity($event)
                                },
                              },
                              model: {
                                value: _vm.formRegion.PROVINCE,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formRegion, "PROVINCE", $$v)
                                },
                                expression: "formRegion.PROVINCE",
                              },
                            },
                            _vm._l(_vm.proList, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: {
                                  label: item.name,
                                  value: item.districtId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 22 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "城市：", prop: "insCityCode" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                filterable: "",
                                placeholder: "请选择城市",
                              },
                              model: {
                                value: _vm.formRegion.insCityCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formRegion, "insCityCode", $$v)
                                },
                                expression: "formRegion.insCityCode",
                              },
                            },
                            _vm._l(_vm.citylist, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.name, value: item.code },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
            _c("div", { staticClass: "footer-btn" }, [
              _c(
                "div",
                { staticClass: "footer-btn-list" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "mini",
                        type: "primary",
                        icon: "el-icon-check",
                      },
                      on: { click: _vm.addCity },
                    },
                    [_vm._v("保存")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", icon: "el-icon-close" },
                      on: { click: _vm.cancelRegion },
                    },
                    [_vm._v(" 取消 ")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }