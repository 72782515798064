import _defineProperty from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.find.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ChinaMapChart from './components/ChinaMapChartCopy.vue';
import initHeight from '../../mixins/initHeight';
import lineSmoothChart from './components/lineSmoothChart.vue';
import barChart from './components/barChart.vue';
import pieCharts from './components/pieChartsCopy.vue';
import mapStatisticsRequest from '../../api/mapStatistics.js';
import * as chinaCities from '@/assets/china-cities.json';
import dayjs from 'dayjs';
var dayOfYear = require('dayjs/plugin/dayOfYear');
var WEEK = new Array("日", "一", "二", "三", "四", "五", "六");
import { getCurrentDate, getBeforeDate, getLastMonth, getLastWeekData, getLastYear } from '@/utils/common';
export default {
  name: "mapStatisticsBoard",
  mixins: [initHeight],
  components: {
    ChinaMapChart: ChinaMapChart,
    lineSmoothChart: lineSmoothChart,
    barChart: barChart,
    pieCharts: pieCharts
  },
  data: function data() {
    var _ref;
    return _ref = {
      totalPremiumsNumleft: 0,
      totalQuantitysleft: 0,
      oldQuantity: 0,
      oldPremium: 0,
      animateTime: false,
      animateTime2: false,
      oldTotalQuantity: 0,
      title: process.env.VUE_APP_TITLE,
      xlineData: [],
      seriesLineData: [{
        name: '本月',
        data: []
      }, {
        name: '本月',
        data: []
      }],
      seriesLineData1: [{
        name: '本月',
        data: []
      }, {
        name: '本月',
        data: []
      }],
      xAxisData: [],
      seriesData: {
        name: '出单量',
        data: []
      },
      seriesData1: {
        name: '总保费',
        data: []
      },
      premiumData: [],
      quantityData: [],
      defaultCity: '全国',
      currentCity: '',
      containerH: '100%',
      tabPosition: 'day',
      mapId: 'chinaMap',
      mapW: '100%',
      mapH: '700px',
      mapData: [],
      quantityRateData: {
        "chartType": "Pie",
        "name": "保险公司出单量占比",
        "values": []
      },
      premiumRateData: {
        "chartType": "Pie",
        "name": "保险公司保费规模占比",
        "values": []
      },
      isFullscreen: false,
      areaCode: "",
      dataTime: {
        startDate: getCurrentDate(),
        endDate: getCurrentDate()
      },
      preDataTime: {
        startDate: getCurrentDate(),
        endDate: getCurrentDate()
      },
      totalPremium: "",
      totalQuantity: "",
      currentTab: "",
      riskType: ""
    }, _defineProperty(_ref, "title", process.env.VUE_APP_TITLE), _defineProperty(_ref, "timer", null), _defineProperty(_ref, "timeNum", 0), _defineProperty(_ref, "route", this.$router), _defineProperty(_ref, "dateList", [{
      id: 1,
      name: 'day'
    }, {
      id: 2,
      name: 'week'
    }, {
      id: 3,
      name: 'month'
    }, {
      id: 4,
      name: 'year'
    }]), _defineProperty(_ref, "getTabDataTimer", null), _defineProperty(_ref, "timeout1", null), _ref;
  },
  computed: {},
  mounted: function mounted() {
    // this.dateTabChangestart()
    var self = this;
    self.containerH = document.body.clientHeight - 134 + 'px';
    window.onresize = function () {
      if (!self.checkFull()) {
        // 退出全屏后要执行的动作
        self.isFullscreen = false;
        self.containerH = document.body.clientHeight - 134 + 'px';
        self.containerH = this.isFullscreen ? '95vh' : self.containerH;
        self.$forceUpdate();
      }
    };
    this.handleRadio('day');
  },
  beforeDestroy: function beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
    clearInterval(this.getTabDataTimer);
    this.getTabDataTimer = null;
  },
  methods: {
    clearTimer: function clearTimer() {
      clearInterval(this.timer);
      this.timer = null;
    },
    clearGetDataTimer: function clearGetDataTimer() {
      // console.log('清除定时获取数据定时器')
      clearInterval(this.getTabDataTimer);
      // this.getTabDataTimer = null;
    },
    dateTabChangestart: function dateTabChangestart() {
      var _this = this;
      // 循环时间列表
      var dateList = this.dateList;
      // let a = parseInt(this.timeNum)
      this.timer = setInterval(function () {
        _this.timeNum = parseInt(_this.timeNum) + 1;
        if (_this.timeNum == 5) {
          _this.timeNum = 0;
        } else {
          _this.handleRadio(dateList[_this.timeNum - 1].name);
          _this.tabPosition = dateList[_this.timeNum - 1].name;
        }
      }, 16000);
    },
    riskTypeC: function riskTypeC() {
      this.loadBoardData(this.currentTab);
    },
    handleRadio: function handleRadio(val) {
      var _this2 = this;
      clearInterval(this.timer);
      this.timer = null;
      clearInterval(this.getTabDataTimer);
      this.getTabDataTimer = null;
      if (this.timeout1) {
        //防抖
        clearTimeout(this.timeout1);
      }
      this.timeout1 = setTimeout(function () {
        // console.log('切换',val)
        _this2.handleRadio1(val);
      }, 800);
    },
    getDataTimer: function getDataTimer() {
      var _this3 = this;
      this.getTabDataTimer = setInterval(function () {
        _this3.loadBoardData(_this3.currentTab);
      }, 5000);
    },
    handleRadio1: function handleRadio1(val) {
      switch (val) {
        case "day":
          //今天
          this.dataTime.startDate = getCurrentDate() + ' 00:00:00';
          this.dataTime.endDate = getCurrentDate() + ' 23:59:59';
          this.preDataTime.startDate = getBeforeDate(1) + ' 00:00:00';
          this.preDataTime.endDate = getBeforeDate(1) + ' 23:59:59';
          this.currentTab = 'DAY';
          this.timeNum = 1;
          break;
        case "week":
          //本周
          var day = dayjs().day();
          // 本周：周一 ~ 周日
          if (day == 0) {
            //0表示今天周日
            this.preDataTime.startDate = dayjs().day(-13).format('YYYY-MM-DD') + ' 00:00:00';
            this.preDataTime.endDate = dayjs().day(-7).format('YYYY-MM-DD') + ' 23:59:59';
            this.dataTime.startDate = dayjs().day(-6).format('YYYY-MM-DD') + ' 00:00:00';
            this.dataTime.endDate = dayjs().day(0).format('YYYY-MM-DD') + ' 23:59:59';
          } else {
            this.dataTime.startDate = dayjs().day(1).format('YYYY-MM-DD') + ' 00:00:00';
            this.dataTime.endDate = dayjs().day(7).format('YYYY-MM-DD') + ' 23:59:59';
            this.preDataTime.startDate = dayjs().day(-6).format('YYYY-MM-DD') + ' 00:00:00';
            this.preDataTime.endDate = dayjs().day(0).format('YYYY-MM-DD') + ' 23:59:59';
          }
          this.currentTab = 'week';
          this.timeNum = 2;
          break;
        case "month":
          //本月
          this.dataTime.startDate = dayjs().date(1).format('YYYY-MM-DD') + ' 00:00:00';
          var days = dayjs(getCurrentDate()).daysInMonth();
          this.dataTime.endDate = dayjs().date(days).format('YYYY-MM-DD') + ' 23:59:59';
          this.preDataTime.startDate = getLastMonth()[0] + ' 00:00:00';
          this.preDataTime.endDate = getLastMonth()[1] + ' 23:59:59';
          this.currentTab = 'month';
          this.timeNum = 3;
          break;
        case "year":
          //本年
          dayjs.extend(dayOfYear);
          this.dataTime.startDate = dayjs().dayOfYear(1).format('YYYY-MM-DD') + ' 00:00:00';
          this.dataTime.endDate = dayjs().dayOfYear(365).format('YYYY-MM-DD') + ' 23:59:59';
          this.preDataTime.startDate = getLastYear()[0] + ' 00:00:00';
          this.preDataTime.endDate = getLastYear()[1] + ' 23:59:59';
          this.currentTab = 'YEAR';
          this.timeNum = 4;
          break;
      }
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      } else {
        // console.log('1开启切换Tab定时器')
        this.dateTabChangestart();
      }
      this.loadBoardData(this.currentTab);
      if (this.getTabDataTimer) {
        clearInterval(this.getTabDataTimer);
        this.getTabDataTimer = null;
      } else {
        // console.log('2重新开启获取Map数据定时器')
        this.getDataTimer();
      }
    },
    //切换时间段
    loadBoardData: function loadBoardData(type) {
      var obj = {
        riskType: this.riskType
      };
      var data = Object.assign(obj, this.dataTime);
      this.getLeaderboards(data, this.areaCode);
      this.getInsCorpInsure(data);
      this.getMapStatistics(data, type);
      this.getAreaStatistics(data, type, 1);
      this.preDataTime.riskType = this.riskType;
      this.getAreaStatistics(this.preDataTime, type, 0);
    },
    //保险公司出单业务
    getInsCorpInsure: function getInsCorpInsure(dateTime) {
      var _this4 = this;
      var areaCode = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
      mapStatisticsRequest.getInsCorpInsure(this.areaCode, dateTime).then(function (res) {
        if (res.data && res.data.length != 0) {
          _this4.xAxisData = [];
          _this4.seriesData.data = [];
          _this4.seriesData1.data = [];
          var data1 = [];
          var data2 = [];
          var values1 = [];
          var values2 = [];
          _this4.premiumRateData.values = [];
          if (res.data[0]) {
            var sortList1 = _.orderBy(res.data[0], ['quantity'], ['desc']);
            sortList1.forEach(function (item) {
              _this4.xAxisData.push(item.insCorpName);
              data1.push(item.quantity);
              values1.push({
                name: item.insCorpName,
                value: item.quantity
              });
              _this4.$forceUpdate();
            });
            _this4.quantityRateData.values = values1;
            _this4.seriesData.data = data1;
          }
          if (res.data[1]) {
            var sortList2 = _.orderBy(res.data[1], ['premium'], ['desc']);
            sortList2.forEach(function (item) {
              data2.push(item.premium);
              values2.push({
                name: item.insCorpName,
                value: item.premium
              });
              _this4.$forceUpdate();
            });
            _this4.premiumRateData.values = values2;
            _this4.seriesData1.data = data2;
          }
        } else {
          _this4.clearGetDataTimer();
        }
      });
    },
    //获取总保费/总出单
    getAreaStatistics: function getAreaStatistics(dateTime) {
      var _this5 = this;
      var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
      var num = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;
      var areaCode = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : "";
      var obj1 = {
        name: '本月',
        data: []
      };
      var obj2 = {
        name: '本月',
        data: []
      };
      switch (type) {
        case "DAY":
          //今天
          obj1.name = num === 1 ? '今天' : "昨天";
          obj2.name = num === 1 ? '今天' : "昨天";
          break;
        case "week":
          //本周
          obj1.name = num === 1 ? '本周' : "上周";
          obj2.name = num === 1 ? '本周' : "上周";
          break;
        case "month":
          //本月
          obj1.name = num === 1 ? '本月' : "上月";
          obj2.name = num === 1 ? '本月' : "上月";
          break;
        case "YEAR":
          //今年
          obj1.name = num === 1 ? '今年' : "去年";
          obj2.name = num === 1 ? '今年' : "去年";
          break;
      }
      mapStatisticsRequest.getAreaStatistics(this.areaCode, type, dateTime).then(function (res) {
        if (res.data && res.data.length != 0) {
          if (num === 1) {
            var arr = [];
            _this5.xlineData = [];
            res.data[1].xaxis.forEach(function (item) {
              if (_this5.tabPosition === 'day') {
                arr.push(parseInt(item) + 'h');
              } else if (_this5.tabPosition === 'year') {
                arr.push(parseInt(item.substr(5)) + '月');
              } else if (_this5.tabPosition === 'week') {
                var y = new Date().getFullYear();
                var w = new Date(y + '-' + item.substr(5)).getDay();
                var title = "周" + WEEK[w] + " " + item.substr(5);
                arr.push(title);
              } else {
                arr.push(item.substr(5));
              }
            });
            _this5.xlineData = arr;
          }
          obj1.data = res.data[1].series;
          _this5.$set(_this5.seriesLineData, num, obj1);
          obj2.data = res.data[0].series;
          _this5.$set(_this5.seriesLineData1, num, obj2);
        } else {
          _this5.clearGetDataTimer();
        }
      });
    },
    checkNums: function checkNums(itemName, resDataNum) {
      var _this6 = this;
      if (sessionStorage.getItem(itemName)) {
        //是否有本地缓存
        if (parseInt(sessionStorage.getItem(itemName)) < parseInt(resDataNum + 30)) {
          this.oldQuantity = parseInt(resDataNum + 30) - parseInt(sessionStorage.getItem(itemName));
          this.animateTime = true;
          this.$nextTick(function () {
            // let width = this.$refs.totalQuantitysNum.offsetWidth;
            var length = parseInt(resDataNum + 30) ? parseInt(resDataNum + 30).toString().split('').length : 1;
            _this6.totalQuantitysleft = parseInt(14 * length) + 'px';
          });
          sessionStorage.setItem(itemName, resDataNum ? resDataNum : 0);
        } else {
          this.animateTime = false;
          sessionStorage.setItem(itemName, resDataNum ? resDataNum : 0);
        }
      } else {
        sessionStorage.setItem(itemName, resDataNum ? resDataNum : 0);
        this.animateTime = false;
      }
    },
    checkPremium: function checkPremium(itemName, resDataPremium) {
      var _this7 = this;
      if (sessionStorage.getItem(itemName)) {
        //是否有本地缓存
        if (parseInt(sessionStorage.getItem(itemName)) < parseInt(resDataPremium + 3000)) {
          this.oldPremium = parseInt(resDataPremium + 3000) - parseInt(sessionStorage.getItem(itemName));
          this.animateTime2 = true;
          this.$nextTick(function () {
            // let width = this.$refs.totalPremiumsNum.offsetWidth;
            var length = parseInt(resDataPremium + 3000) ? parseInt(resDataPremium + 3000).toString().split('').length + 1 : 1 + 1;
            _this7.totalPremiumsNumleft = parseInt(25 * length) + 'px';
          });
          sessionStorage.setItem(itemName, resDataPremium ? resDataPremium : 0);
        } else {
          this.animateTime2 = false;
          sessionStorage.setItem(itemName, resDataPremium ? resDataPremium : 0);
        }
      } else {
        sessionStorage.setItem(itemName, resDataPremium ? resDataPremium : 0);
        this.animateTime2 = false;
      }
    },
    //地图
    getMapStatistics: function getMapStatistics(dateTime, type) {
      var _this8 = this;
      var areaCode = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "";
      mapStatisticsRequest.getMapStatistics(this.areaCode, dateTime).then(function (res) {
        if (res.data) {
          _this8.totalPremium = res.data.premium;
          _this8.totalQuantity = res.data.quantity;
          setTimeout(function () {
            _this8.totalPremium = res.data.premium ? res.data.premium + 3000 : 0 + 3000;
          }, 500);
          setTimeout(function () {
            _this8.totalQuantity = res.data.quantity ? parseInt(res.data.quantity + 30) : 0 + 30;
          }, 500);
          if (type && type == 'DAY') {
            _this8.checkNums('yfOldDayTotalQuantity', res.data.quantity);
            _this8.checkPremium('yfOldDayTotalPremium', res.data.premium);
          }
          if (type && type == 'week') {
            _this8.checkNums('yfOldWeekTotalQuantity', res.data.quantity);
            _this8.checkPremium('yfOldWeekTotalPremium', res.data.premium);
          }
          if (type && type == 'month') {
            _this8.checkNums('yfOldMonthTotalQuantity', res.data.quantity);
            _this8.checkPremium('yfOldMonthTotalPremium', res.data.premium);
          }
          if (type && type == 'YEAR') {
            _this8.checkNums('yfOldYearTotalQuantity', res.data.quantity);
            _this8.checkPremium('yfOldYearTotalPremium', res.data.premium);
          }
          var _data = res.data.policyInsCorplnsureResponses ? res.data.policyInsCorplnsureResponses : [];
          _this8.mapData = [];
          if (!_.isEmpty(_data)) {
            _data.forEach(function (element) {
              var obj = {
                name: '',
                emphasis: {
                  label: {
                    show: true
                  }
                },
                value: []
              };
              //获取城市对应的经纬度；
              var cityCode = element.insCityCode;
              //直辖市
              if (element.insCityName.indexOf('北京') != -1 || cityCode == '110100') {
                cityCode = '110000';
              } else if (element.insCityName.indexOf('天津') != -1 || cityCode == '120100') {
                cityCode = '120000';
              } else if (element.insCityName.indexOf('上海') != -1 || cityCode == '310100') {
                cityCode = '310000';
              } else if (element.insCityName.indexOf('重庆') != -1 || cityCode == '500100') {
                cityCode = '500000';
              }
              var _temp = _.find(chinaCities.features, function (item) {
                return item.id == cityCode;
              });
              if (_temp) {
                var _cp = [];
                _cp.push(_temp.properties.cp[0] || '');
                _cp.push(_temp.properties.cp[1] || '');
                _cp.push(element.premium);
                _cp.push(element.quantity);
                obj.name = _temp.properties.name;
                obj.value = _cp;
              }
              _this8.mapData.push(obj);
            });
          }
          setTimeout(function () {
            _this8.animateTime = false;
            _this8.animateTime2 = false;
          }, 2000);
        } else {
          _this8.clearGetDataTimer();
        }
      });
    },
    //获取保费/保单排行榜  5:出单top5
    getLeaderboards: function getLeaderboards(dateTime) {
      var _this9 = this;
      var areaCode = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
      mapStatisticsRequest.getLeaderboards(this.areaCode, 5, dateTime).then(function (res) {
        if (res.data && res.data.length != 0) {
          _this9.premiumData = res.data[0]; //保费
          _this9.quantityData = res.data[1]; //出单量
        } else {
          _this9.clearGetDataTimer();
        }
      });
    },
    switchMap: function switchMap(val) {
      if (this.$refs.chinaMap) {
        this.$refs.chinaMap.switchMap(val);
      }
    },
    selectedCity: function selectedCity(val) {
      if (!val) return;
      //刷新地图数据
      this.currentCity = val.name;
      var _areaCode = val.areaCode || '';
      this.areaCode = _areaCode ? _areaCode.substring(0, 2) : '';
      this.loadBoardData(this.currentTab);
    },
    checkFull: function checkFull() {
      //全屏判断状态 取消
      //判断浏览器是否处于全屏状态 （需要考虑兼容问题）
      //火狐浏览器
      var isFull = document.mozFullScreen || document.fullScreen ||
      //谷歌浏览器及Webkit内核浏览器
      document.webkitIsFullScreen || document.webkitRequestFullScreen || document.mozRequestFullScreen || document.msFullscreenEnabled;
      if (isFull === undefined) {
        isFull = false;
      }
      return isFull;
    },
    switchScreen: function switchScreen() {
      var element = document.getElementById('map-container'); //设置后就是   id==con_lf_top_div 的容器全屏
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen();
        }
      }
      this.isFullscreen = !this.isFullscreen;
      if (this.isFullscreen) {
        this.containerH = '95vh';
      }
      this.$forceUpdate();
    }
  }
};