var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.hasPaid
        ? _c("el-card", { attrs: { shadow: "never" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.showLoading,
                    expression: "showLoading",
                  },
                ],
                staticStyle: {
                  "background-color": "#fff",
                  "max-height": "600px",
                  "overflow-y": "auto",
                },
              },
              [
                !_vm.active && !_vm.hasPaid
                  ? _c(
                      "div",
                      { staticStyle: { padding: "20px" } },
                      [
                        _c(
                          "div",
                          [
                            _c("el-row", { attrs: { gutter: 0 } }, [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "min-height": "35px",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        flex: "1",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "form-title" },
                                        [_vm._v("投保险企：")]
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "form-content" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.paymentData.insCorpName || ""
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        flex: "1",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "form-title" },
                                        [_vm._v("车主名：")]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "form-content",
                                          staticStyle: {
                                            flex: "1",
                                            "margin-left": "4px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.paymentData.ownerName || ""
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _c(
                              "el-row",
                              {
                                staticStyle: { "margin-top": "5px" },
                                attrs: { gutter: 0 },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "min-height": "35px",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          flex: "1",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "form-title" },
                                          [_vm._v("车牌号：")]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "form-content" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.paymentData.plateNo ||
                                                  "新车"
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          flex: "1",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "form-title" },
                                          [_vm._v("投保人：")]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "form-content",
                                            staticStyle: {
                                              flex: "1",
                                              "margin-left": "4px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.paymentData.applicantName ||
                                                  ""
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "el-row",
                              {
                                staticStyle: { "margin-top": "5px" },
                                attrs: { gutter: 0 },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "min-height": "35px",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          flex: "1",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "form-title" },
                                          [_vm._v("投保险种：")]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "form-content" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.paymentData.hasBz &&
                                                  _vm.paymentData.hasBs
                                                  ? "交强险，商业险"
                                                  : _vm.paymentData.hasBz
                                                  ? "交强险"
                                                  : "商业险"
                                              ) +
                                                _vm._s(
                                                  _vm.paymentData.hasNon
                                                    ? "，非车险"
                                                    : ""
                                                )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          flex: "1",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "form-title" },
                                          [_vm._v("被保人：")]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "form-content",
                                            staticStyle: {
                                              flex: "1",
                                              "margin-left": "4px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.paymentData.insuredName ||
                                                  ""
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c("el-divider"),
                        !_vm.hasPaid
                          ? _c("div", [
                              _c(
                                "div",
                                { staticStyle: { "margin-top": "15px" } },
                                [
                                  _c("span", { staticClass: "form-title" }, [
                                    _vm._v("支付金额："),
                                  ]),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        color: "red",
                                        "font-size": "15px",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.paymentData.amount || "0.00")
                                      ),
                                    ]
                                  ),
                                  _c("span", { staticClass: "form-content" }, [
                                    _vm._v("元"),
                                  ]),
                                ]
                              ),
                              _c("div", { staticStyle: { padding: "10px" } }, [
                                _c("div", [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "form-content",
                                      staticStyle: { "line-height": "35px" },
                                    },
                                    [_vm._v("请选择支付方式")]
                                  ),
                                ]),
                                _c(
                                  "div",
                                  { staticStyle: { display: "flex" } },
                                  _vm._l(
                                    _vm.paymentTypes,
                                    function (item, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticStyle: {
                                            cursor: "pointer",
                                            border: "1px solid #f6a13d",
                                            "border-radius": "5px",
                                            padding: "8px",
                                            "text-align": "center",
                                            margin: "10px",
                                          },
                                          style: {
                                            "border-color":
                                              _vm.currentCode.code == item.code
                                                ? "#f6a13d"
                                                : "#EEE",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.toPayment(item)
                                            },
                                          },
                                        },
                                        [
                                          _c("svg-icon", {
                                            attrs: {
                                              "icon-class":
                                                item.client == "QR_CODE"
                                                  ? "qrcode"
                                                  : "pos",
                                            },
                                          }),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "font-size": "12px",
                                                "margin-left": "3px",
                                              },
                                            },
                                            [_vm._v(_vm._s(item.name || ""))]
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.active && !_vm.hasPaid
                  ? _c("div", [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            border: "1px solid #dcd9d9",
                            "border-radius": "5px",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "380px",
                                "border-right": "1px solid #dcd9d9",
                                padding: "10px",
                                "border-radius": "5px 0 0 5px",
                              },
                            },
                            [
                              _c(
                                "el-row",
                                {
                                  staticStyle: { "line-height": "35px" },
                                  attrs: { gutter: 0 },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "min-height": "35px",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            flex: "1",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "form-title-s" },
                                            [_vm._v("投保险企：")]
                                          ),
                                          _c("svg-icon", {
                                            staticStyle: {
                                              "font-size": "18px",
                                              "margin-left": "5px",
                                              "margin-top": "4px",
                                            },
                                            attrs: {
                                              "icon-class": _vm.paymentData
                                                .insCorpCode
                                                ? _vm.paymentData.insCorpCode.toLowerCase()
                                                : "",
                                            },
                                          }),
                                          _c(
                                            "span",
                                            { staticClass: "form-content-s" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.paymentData.insCorpName ||
                                                    ""
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "min-height": "35px",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            flex: "1",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "form-title-s" },
                                            [_vm._v("车牌号：")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "form-content-s",
                                              staticStyle: {
                                                "padding-left": "5px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.paymentData.plateNo ||
                                                    "新车"
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "min-height": "35px",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            flex: "1",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "form-title-s" },
                                            [_vm._v("车主名：")]
                                          ),
                                          _c(
                                            "el-tooltip",
                                            {
                                              staticClass: "item",
                                              attrs: {
                                                effect: "dark",
                                                content:
                                                  _vm.paymentData.ownerName,
                                                placement: "top-start",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "form-content-s",
                                                  staticStyle: {
                                                    width: "175px",
                                                    "margin-left": "4px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.paymentData
                                                        .ownerName || ""
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "min-height": "35px",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            flex: "1",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "form-title-s" },
                                            [_vm._v("投保人：")]
                                          ),
                                          _c(
                                            "el-tooltip",
                                            {
                                              staticClass: "item",
                                              attrs: {
                                                effect: "dark",
                                                content:
                                                  _vm.paymentData.applicantName,
                                                placement: "top-start",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "form-content-s",
                                                  staticStyle: {
                                                    width: "175px",
                                                    "margin-left": "4px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.paymentData
                                                        .applicantName || ""
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "min-height": "35px",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            flex: "1",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "form-title-s" },
                                            [_vm._v("被保人：")]
                                          ),
                                          _c(
                                            "el-tooltip",
                                            {
                                              staticClass: "item",
                                              attrs: {
                                                effect: "dark",
                                                content:
                                                  _vm.paymentData.insuredName,
                                                placement: "top-start",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "form-content-s",
                                                  staticStyle: {
                                                    width: "175px",
                                                    "margin-left": "4px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.paymentData
                                                        .insuredName || ""
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "min-height": "35px",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            flex: "1",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "form-title-s" },
                                            [_vm._v("投保险种：")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "form-content-s" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.paymentData.hasBz &&
                                                    _vm.paymentData.hasBs
                                                    ? "交强险，商业险"
                                                    : _vm.paymentData.hasBz
                                                    ? "交强险"
                                                    : "商业险"
                                                ) +
                                                  _vm._s(
                                                    _vm.paymentData.hasNon
                                                      ? "，非车险"
                                                      : ""
                                                  )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "min-height": "35px",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            flex: "1",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "form-title-s" },
                                            [_vm._v("支付金额：")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "form-content-s",
                                              staticStyle: { color: "red" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.paymentData.amount ||
                                                    "0.00"
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "form-content-s" },
                                            [_vm._v(" 元")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "min-height": "35px",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            flex: "1",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "form-title-s" },
                                            [_vm._v("支付方式：")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "form-content-s" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.currentCode.name
                                                    ? _vm.currentCode.name
                                                    : ""
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticStyle: { flex: "1", padding: "10px" } },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.showConfirm,
                                      expression: "showConfirm",
                                    },
                                  ],
                                  staticStyle: {
                                    "text-align": "center",
                                    display: "flex",
                                    "align-items": "center",
                                    "justify-content": "center",
                                    "margin-top": "100px",
                                  },
                                },
                                [
                                  _vm.showLoadingPay
                                    ? _c("i", {
                                        staticClass: "el-icon-loading",
                                      })
                                    : _vm._e(),
                                  _vm.showLoadingPay
                                    ? _c(
                                        "span",
                                        {
                                          staticStyle: { "font-size": "13px" },
                                        },
                                        [_vm._v("正在获取缴费信息，请稍等...")]
                                      )
                                    : _vm._e(),
                                  !_vm.showLoadingPay
                                    ? _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "font-size": "13px",
                                            color: "red",
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.qrcodeData.errMsg))]
                                      )
                                    : _vm._e(),
                                  _vm._.toLower(_vm.paymentData.insCorpCode) ==
                                    "tmnf" &&
                                  !_vm.showLoadingPay &&
                                  _vm.currentCode &&
                                  _vm.currentCode.code == "10"
                                    ? _c(
                                        "el-button",
                                        {
                                          staticStyle: { "margin-left": "5px" },
                                          attrs: {
                                            type: "text",
                                            size: "small",
                                            icon: "el-icon-refresh",
                                            plain: "",
                                          },
                                          on: { click: _vm.refreshPaymentUrl },
                                        },
                                        [_vm._v("重新获取")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.showConfirm,
                                      expression: "!showConfirm",
                                    },
                                  ],
                                  staticStyle: { "text-align": "center" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.paymentClient == "QRCode",
                                          expression:
                                            "paymentClient == 'QRCode'",
                                        },
                                      ],
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "mini-font-size",
                                          staticStyle: { color: "#dfaf89" },
                                        },
                                        [
                                          _vm._v(
                                            "可将二维码截图发给客户进行付款"
                                          ),
                                        ]
                                      ),
                                      _c("br"),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "mini-font-size",
                                          staticStyle: { color: "red" },
                                        },
                                        [_vm._v("(点击二维码可预览大图)")]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticStyle: { "margin-top": "10px" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "justify-content": "center",
                                          },
                                        },
                                        [
                                          _c("div", {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: !_vm.qrcodeImgSrc,
                                                expression: "!qrcodeImgSrc",
                                              },
                                            ],
                                            ref: "qrCodeUrl",
                                            staticClass: "qrcode",
                                            attrs: { id: "qrcode" },
                                          }),
                                          _vm.qrcodeImgSrc
                                            ? _c("el-image", {
                                                attrs: {
                                                  src: _vm.qrcodeImgSrc,
                                                  "preview-src-list": [
                                                    _vm.qrcodeImgSrc,
                                                  ],
                                                },
                                                on: { click: _vm.clickImage },
                                              })
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.paymentClient == "POS",
                                                  expression:
                                                    "paymentClient == 'POS'",
                                                },
                                              ],
                                              staticStyle: {
                                                "min-height": "160px",
                                                "text-align": "left",
                                              },
                                            },
                                            [_vm._v(_vm._s(_vm.posMsg))]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticStyle: { "line-height": "35px" } },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "mini-font-size",
                                          staticStyle: { color: "#dfaf89" },
                                        },
                                        [
                                          _vm._v(
                                            "由" +
                                              _vm._s(
                                                _vm.paymentData.insCorpName ||
                                                  ""
                                              ) +
                                              "承保并收取保费"
                                          ),
                                        ]
                                      ),
                                      _vm._.toLower(
                                        _vm.paymentData.insCorpCode
                                      ) == "tmnf" &&
                                      _vm.currentCode &&
                                      _vm.currentCode.code == "10"
                                        ? _c(
                                            "el-button",
                                            {
                                              staticStyle: {
                                                "margin-left": "5px",
                                              },
                                              attrs: {
                                                type: "text",
                                                size: "small",
                                                icon: "el-icon-refresh",
                                                plain: "",
                                              },
                                              on: {
                                                click: _vm.refreshPaymentUrl,
                                              },
                                            },
                                            [_vm._v("重新获取")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _vm.paymentStatus
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "padding-bottom": "10px",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                color: "#2150A3",
                                                "font-size": "12px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "状态：" +
                                                  _vm._s(_vm.paymentStatus)
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.effectiveTime,
                                          expression: "effectiveTime",
                                        },
                                      ],
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "mini-font-size",
                                          staticStyle: { color: "red" },
                                        },
                                        [
                                          _vm._v(
                                            "请在 " +
                                              _vm._s(_vm.effectiveTime) +
                                              "前完成缴费，超时订单将失效！"
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]
            ),
          ])
        : _vm._e(),
      !_vm.active && !_vm.hasPaid
        ? _c("div", [
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "justify-content": "center",
                  "line-height": "46px",
                  height: "46px",
                  padding: "8px 0px",
                },
              },
              [
                _c(
                  "el-button",
                  { attrs: { size: "mini" }, on: { click: _vm.closePage } },
                  [_vm._v("关闭")]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.hasPaid
        ? _c(
            "div",
            {
              staticStyle: {
                padding: "20px",
                background: "#fff",
                "border-radius": "5px",
              },
            },
            [
              _c("div", { staticClass: "row-payment-c" }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "line-height": "32px",
                      "justify-content": "center",
                    },
                  },
                  [
                    _c("svg-icon", {
                      staticStyle: { "font-size": "32px" },
                      attrs: { "icon-class": "succeed" },
                    }),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-size": "24px",
                          "margin-left": "10px",
                          "font-weight": "700",
                        },
                      },
                      [_vm._v("支付成功")]
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                {
                  staticClass: "row-payment-c",
                  staticStyle: { "margin-top": "15px" },
                },
                [
                  _c("span", { staticClass: "form-title-s" }, [
                    _vm._v("支付金额："),
                  ]),
                  _c("span", { staticClass: "form-content" }, [
                    _vm._v(_vm._s(_vm.paymentData.amount || "0.00")),
                  ]),
                ]
              ),
              _vm._l(_vm.proposalList, function (item, index) {
                return _c("div", { key: index, staticClass: "row-payment-c" }, [
                  _c("span", { staticClass: "form-title-s-s" }, [
                    _vm._v(
                      _vm._s(
                        item.riskType == "0"
                          ? "交强险"
                          : item.riskType == "1"
                          ? "商业险"
                          : "驾意险"
                      ) + "保单号："
                    ),
                  ]),
                  _c(
                    "span",
                    { staticClass: "form-content" },
                    [
                      _vm._v(_vm._s(item.policyNo || "") + " "),
                      _vm._.toLower(_vm.paymentData.insCorpCode) != "picc" &&
                      _vm._.toLower(_vm.paymentData.insCorpCode) != "cpic"
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                icon: "el-icon-download",
                                plain: "",
                                size: "mini",
                                type: "text",
                                loading: item.loading,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.download(item)
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    item.loading ? "下载中..." : "下载电子保单"
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ])
              }),
            ],
            2
          )
        : _vm._e(),
      _vm.hasPaid
        ? _c("div", [
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "justify-content": "center",
                  "line-height": "46px",
                  height: "46px",
                  padding: "8px 0px",
                },
              },
              [
                _c(
                  "el-button",
                  { attrs: { size: "mini" }, on: { click: _vm.closePage } },
                  [_vm._v("关闭")]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.active && !_vm.hasPaid
        ? _c("div", [
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "justify-content": "center",
                  "line-height": "46px",
                  height: "46px",
                  padding: "8px 0px",
                },
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: { size: "mini", loading: _vm.loadingCancel },
                    on: { click: _vm.updateMode },
                  },
                  [_vm._v("更改支付方式")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      icon: "el-icon-refresh",
                      size: "mini",
                      loading: _vm.loadingStatus,
                    },
                    on: { click: _vm.refreshStatus },
                  },
                  [_vm._v("支付确认")]
                ),
                _c(
                  "el-button",
                  { attrs: { size: "mini" }, on: { click: _vm.closePage } },
                  [_vm._v("关闭")]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c(
        "el-dialog",
        {
          staticClass: "pay_dialog",
          attrs: {
            visible: _vm.showIdentityDialog,
            "close-on-click-modal": false,
            width: "600px",
            "before-close": _vm.handleClose,
            "modal-append-to-body": false,
            center: "",
            modal: false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showIdentityDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "title", attrs: { slot: "title" }, slot: "title" },
            [
              _c("FormLabel", {
                attrs: { "form-label": "请进行身份信息采集" },
              }),
              _c("el-divider"),
            ],
            1
          ),
          _c("Identity", {
            attrs: {
              "identity-data": _vm.identityItems,
              proposalId: "" + _vm.proposalId,
              showDialog: _vm.showIdentityDialog,
            },
            on: {
              confirm: _vm.confirmIdentityVerifyInfo,
              close: _vm.closeIdentityDialog,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pay_dialog",
          attrs: {
            visible: _vm.showPayVerifyDialog,
            width: "400px",
            "close-on-click-modal": false,
            "before-close": _vm.dialogExtendClose,
            "modal-append-to-body": false,
            modal: false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showPayVerifyDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "title", attrs: { slot: "title" }, slot: "title" },
            [_c("FormLabel", { attrs: { "form-label": "验证码" } })],
            1
          ),
          _c("div", { staticStyle: { background: "#fff" } }, [
            _vm.currentflowNode && _vm.currentflowNode.message
              ? _c(
                  "div",
                  {
                    staticStyle: {
                      border: "1px solid #eee",
                      "border-radius": "5px",
                      padding: "10px",
                      "font-size": "13px",
                      "padding-top": "10px",
                    },
                  },
                  [
                    _vm._.toLower(_vm.paymentData.insCorpCode) != "tmnf"
                      ? _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v(_vm._s(_vm.currentflowNode.message)),
                        ])
                      : _c("span", {
                          staticStyle: { color: "red" },
                          domProps: {
                            innerHTML: _vm._s(_vm.currentflowNode.message),
                          },
                        }),
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              { staticStyle: { padding: "15px 15px 30px" } },
              [
                _c(
                  "el-form",
                  { ref: "verify", attrs: { model: _vm.verifyForm } },
                  [
                    _vm._.toLower(_vm.paymentData.insCorpCode) != "tmnf"
                      ? _c(
                          "el-row",
                          { attrs: { gutter: 0 } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 16 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "手机号",
                                      "label-width": "70px",
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.paymentData.mobile
                                            ? _vm.paymentData.mobile
                                            : ""
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("el-row", { attrs: { gutter: 0 } }, [
                      _c(
                        "div",
                        { staticClass: "sms_div" },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 16 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "验证码",
                                    "label-width": "70px",
                                    prop: "verifyNum",
                                    rules: [
                                      {
                                        required: true,
                                        message: "请输入验证码",
                                        trigger: "blur",
                                      },
                                      {
                                        min: 1,
                                        max: 6,
                                        message: "请输入正确的验证码格式",
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      maxlength: "40",
                                      placeholder: "请输入验证码",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.verifyForm.verifyNum,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.verifyForm,
                                          "verifyNum",
                                          $$v
                                        )
                                      },
                                      expression: "verifyForm.verifyNum",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.currentflowNode.sendVerifyCode
                            ? _c(
                                "div",
                                [
                                  !_vm.sendMsgDisabled
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            size: "mini",
                                          },
                                          on: { click: _vm.pushMsg },
                                        },
                                        [_vm._v("获取验证码")]
                                      )
                                    : _vm._e(),
                                  _vm.sendMsgDisabled
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "warning",
                                            size: "mini",
                                            plain: "",
                                            disabled: "",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.codeTime + "秒后获取")
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "span",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "center",
                "line-height": "40px",
                padding: "8px 0px",
              },
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    type: "primary",
                    icon: "el-icon-check",
                  },
                  on: { click: _vm.confirmVerifyMsg },
                },
                [_vm._v(" 确定 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", icon: "el-icon-close" },
                  on: {
                    click: function ($event) {
                      _vm.showPayVerifyDialog = false
                    },
                  },
                },
                [_vm._v(" 取消 ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }