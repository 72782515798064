var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "app-m-cls" }, [
        _c(
          "div",
          { staticClass: "table-cls normal-btn-cls" },
          [
            _c(
              "el-card",
              { staticClass: "box-card", attrs: { shadow: "never" } },
              [
                _c(
                  "div",
                  {
                    staticClass: "search-box",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v("产品名称：")]),
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入产品名称",
                            clearable: "",
                          },
                          model: {
                            value: _vm.searchForm.couponProductName,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "couponProductName", $$v)
                            },
                            expression: "searchForm.couponProductName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v("是否启用：")]),
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择是否启用" },
                            model: {
                              value: _vm.searchForm.enable,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "enable", $$v)
                              },
                              expression: "searchForm.enable",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "全部", value: "" },
                            }),
                            _c("el-option", {
                              attrs: { label: "是", value: true },
                            }),
                            _c("el-option", {
                              attrs: { label: "否", value: false },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              icon: "el-icon-refresh-left",
                            },
                            on: { click: _vm.resetSearch },
                          },
                          [_vm._v("重置")]
                        ),
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "8px" },
                            attrs: {
                              size: "mini",
                              type: "primary",
                              icon: "el-icon-search",
                            },
                            on: { click: _vm.searchData },
                          },
                          [_vm._v("查询")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "search-btn" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: { click: _vm.breakData },
                          },
                          [_vm._v("刷新")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.createProduct("add")
                              },
                            },
                          },
                          [_vm._v("新增")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: { click: _vm.exportFn },
                          },
                          [_vm._v("导出")]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    ref: "multipleTable",
                    staticStyle: { width: "100%" },
                    attrs: {
                      size: "mini",
                      border: "",
                      data: _vm.tableData,
                      height: _vm.tableHeightJD,
                      "header-cell-style": { background: "#F7F7F7" },
                      "highlight-current-row": "",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "couponProductName",
                        label: "产品名称",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "couponProductCode",
                        label: "产品编码",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "couponProductTypeDisplay",
                        label: "类别",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "couponProductPrice",
                        align: "right",
                        label: "价值(元)",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "couponProductCost",
                        align: "right",
                        label: "成本(元)",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "couponProductMaxNum",
                        align: "right",
                        label: "最大发放数量",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "remark",
                        label: "权益说明",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "enable",
                        label: "启用",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("el-switch", {
                                on: {
                                  change: function ($event) {
                                    return _vm.handleSwitch($event, scope.row)
                                  },
                                },
                                model: {
                                  value: scope.row.enable,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "enable", $$v)
                                  },
                                  expression: "scope.row.enable",
                                },
                              }),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { label: "操作", align: "center", width: "140px" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    disabled: scope.row.enable,
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.editProduct("edit", scope.row)
                                    },
                                  },
                                },
                                [_vm._v("编辑")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "danger",
                                    disabled: scope.row.enable,
                                    plain: "",
                                    size: "mini",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.deleteProduct(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c("pagination", {
                  attrs: {
                    total: _vm.total,
                    page: _vm.listQuery.pageNum,
                    limit: _vm.listQuery.pageSize,
                  },
                  on: {
                    "update:page": function ($event) {
                      return _vm.$set(_vm.listQuery, "pageNum", $event)
                    },
                    "update:limit": function ($event) {
                      return _vm.$set(_vm.listQuery, "pageSize", $event)
                    },
                    pagination: function ($event) {
                      return _vm.loadData()
                    },
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showDialog,
            "close-on-click-modal": false,
            width: "500px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [
                  _vm._v(
                    "产品" + _vm._s(_vm.opType === "edit" ? "编辑" : "新增")
                  ),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.formLoading,
                  expression: "formLoading",
                },
              ],
              staticClass: "app-m-cls",
              staticStyle: {},
            },
            [
              _c(
                "div",
                {
                  ref: "header",
                  staticClass: "header-cls",
                  staticStyle: { "max-height": "500px", overflow: "auto" },
                },
                [
                  _c("el-card", { attrs: { shadow: "never" } }, [
                    _c(
                      "div",
                      [
                        _c(
                          "el-form",
                          {
                            ref: "detail",
                            attrs: {
                              rules: _vm.formRules,
                              model: _vm.form,
                              "label-width": "140px",
                              size: "mini",
                            },
                          },
                          [
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 24 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "产品名称",
                                          prop: "couponProductName",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            maxlength: "40",
                                            placeholder: "请输入产品名称",
                                          },
                                          model: {
                                            value: _vm.form.couponProductName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "couponProductName",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.couponProductName",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 24 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "产品编码",
                                          prop: "couponProductCode",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            maxlength: "40",
                                            placeholder: "请输入产品编码",
                                          },
                                          model: {
                                            value: _vm.form.couponProductCode,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "couponProductCode",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.couponProductCode",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 24 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "产品类别",
                                          prop: "couponProductType",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              filterable: "",
                                              placeholder: "请选择产品类别",
                                            },
                                            model: {
                                              value: _vm.form.couponProductType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "couponProductType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.couponProductType",
                                            },
                                          },
                                          _vm._l(
                                            _vm.couponProductTypeList,
                                            function (item, index) {
                                              return _c("el-option", {
                                                key: index,
                                                attrs: {
                                                  label: item.dictName,
                                                  value: item.dictCode,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 24 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "价值(元)",
                                          prop: "couponProductPrice",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            maxlength: "40",
                                            placeholder: "请输入价值",
                                          },
                                          on: {
                                            input: function ($event) {
                                              _vm.form.couponProductPrice =
                                                _vm.onInput(
                                                  _vm.form.couponProductPrice,
                                                  0
                                                )
                                            },
                                          },
                                          model: {
                                            value: _vm.form.couponProductPrice,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "couponProductPrice",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.couponProductPrice",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 24 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "成本(元)",
                                          prop: "couponProductCost",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            maxlength: "40",
                                            placeholder: "请输入成本",
                                          },
                                          on: {
                                            input: function ($event) {
                                              _vm.form.couponProductCost =
                                                _vm.onInput(
                                                  _vm.form.couponProductCost,
                                                  0
                                                )
                                            },
                                          },
                                          model: {
                                            value: _vm.form.couponProductCost,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "couponProductCost",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.couponProductCost",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 24 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "最大发放数量",
                                          prop: "couponProductMaxNum",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            maxlength: "40",
                                            placeholder: "请输入最大发放数量",
                                          },
                                          on: {
                                            input: function ($event) {
                                              _vm.form.couponProductMaxNum =
                                                _vm.onInput(
                                                  _vm.form.couponProductMaxNum,
                                                  0
                                                )
                                            },
                                          },
                                          model: {
                                            value: _vm.form.couponProductMaxNum,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "couponProductMaxNum",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.couponProductMaxNum",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 24 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "权益说明",
                                          prop: "remark",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            type: "textarea",
                                            rows: 3,
                                            placeholder: "请输入权益说明",
                                          },
                                          model: {
                                            value: _vm.form.remark,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.form, "remark", $$v)
                                            },
                                            expression: "form.remark",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "center",
                    "margin-top": "8px",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "primary" },
                      on: { click: _vm.saveProduct },
                    },
                    [_vm._v("保存")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-close", size: "mini" },
                      on: { click: _vm.close },
                    },
                    [_vm._v("关闭")]
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }