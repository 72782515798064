//
//
//
//
//
//
//
//
//
//

export default {
  data: function data() {
    return {};
  },
  computed: {
    elHeight: function elHeight() {
      console.log(document.body.clientHeight);
      return document.body.clientHeight - 85 + 'px';
    }
  },
  mounted: function mounted() {},
  methods: {}
};