import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.find.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import html2canvas from 'html2canvas';
import dict from '../../mixins/dict';
import { getBeforeOfDate, getOsInfo } from "@/utils/common";
export default {
  name: 'PrintInsureProposal',
  props: {
    data: {
      type: Object,
      default: null
    },
    refresh: {
      type: Boolean,
      default: false
    },
    custAttribute: {
      type: String,
      default: null
    },
    useAttribute: {
      type: String,
      default: null
    },
    underwritingConfirm: {
      type: Boolean,
      default: false
    }
  },
  mixins: [dict],
  data: function data() {
    return {
      timeout2: null,
      proposalData: {},
      imgData: '',
      loading: false,
      hasImg: false,
      VEHICLE_KIND: [],
      imgUrl: '',
      osInfo: {},
      cutDown: 5,
      timer: null
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      this.cutDown = 5;
      this.timer = null;
      clearInterval(this.timer);
      if (newValue) {
        this.hasImg = false;
        this.$nextTick(function () {
          this.startCutDown();
          this.convertData(this.data);
        });
      }
    }
  },
  created: function created() {
    this.VEHICLE_KIND = JSON.parse(localStorage.getItem('VI_VEHICLE_KIND')) || [];
  },
  mounted: function mounted() {
    this.$nextTick(function () {
      this.startCutDown();
      this.convertData(this.data);
    });
    this.osInfo = getOsInfo();
    console.log('osInfo=>:', this.osInfo);
  },
  destroyed: function destroyed() {
    this.cutDown = 5;
    this.timer = null;
    clearInterval(this.timer);
  },
  methods: {
    startCutDown: function startCutDown() {
      var _this = this;
      //倒计时
      if (this.underwritingConfirm) {
        this.cutDown = 5;
        this.timer = null;
        clearInterval(this.timer);
        this.timer = setInterval(function () {
          _this.cutDown--;
          if (_this.cutDown === 0) {
            clearInterval(_this.timer);
          }
        }, 1000);
      }
    },
    getQuantity: function getQuantity(item) {
      //拼接增值服务次数
      var text = '';
      if (!_.isEmpty(item.proposalProductDutyServicetermsUpdateVOList)) {
        item.proposalProductDutyServicetermsUpdateVOList.forEach(function (item) {
          text = text + item.serviceText + ':' + item.serviceTimes + ',';
        });
      } else if (!_.isEmpty(item.policyDutyServicetermses)) {
        item.policyDutyServicetermses.forEach(function (item) {
          text = text + item.serviceText + ':' + item.serviceTimes + ',';
        });
      }
      if (text && text.lastIndexOf(',') != -1) {
        text = text.substr(0, text.length - 1);
      }
      return text;
    },
    getEffectiveStartTime: function getEffectiveStartTime(time) {
      //起保日期即时起保
      if (!time) return "-";
      if (time.indexOf("00:00") != -1 || time.indexOf("00:00:00") != -1) {
        return time.slice(0, 13) + "时";
      }
      return time.slice(0, 13) + "时" + time.slice(14, 16) + '分';
    },
    getEffectiveEndTime: function getEffectiveEndTime(time) {
      //终保日期默认为前一天的24时
      if (!time) return "";
      if (time.indexOf("00:00") != -1 || time.indexOf("00:00:00") != -1) {
        var timeStr = time.substring(0, 10);
        var hour = time.substring(11, 13);
        var min = time.substring(14, 16);
        var date = getBeforeOfDate(timeStr, 1);
        if (hour != '00' || min != '00') {
          date = getBeforeOfDate(timeStr, 0);
        }
        var endTime = hour != '00' ? date + ' ' + hour + '时' : date + " 24时";
        return endTime;
      }
      return time.slice(0, 13) + "时" + time.slice(14, 16) + '分';
    },
    calcDeviceAmount: function calcDeviceAmount(devices) {
      //计算新增设备险保额
      if (_.isEmpty(devices)) return '';
      var totalAmount = 0;
      devices.forEach(function (element) {
        totalAmount = totalAmount + element.unitPrice * element.quantity;
      });
      return totalAmount;
    },
    formatOption: function formatOption(amount) {
      //格式化保额
      if (amount) {
        var divideValue = _.divide(amount, 10000);
        var text = amount;
        if (divideValue >= 1) {
          text = divideValue + '万';
        }
        return text;
      }
    },
    convertData: function convertData(sourceData) {
      //获取报价单需要显示的数据
      if (sourceData) {
        var bzProduct = _.find(sourceData.coreProposalProductVOList || [], function (item) {
          return item.riskType == "0";
        });
        var bsProduct = _.find(sourceData.coreProposalProductVOList || [], function (item) {
          return item.riskType == "1";
        });
        var nonProduct = _.find(sourceData.coreProposalProductVOList || [], function (item) {
          return item.riskType == "2";
        });
        var applicant = _.find(sourceData.coreProposalInsuredVOList || [], function (cust) {
          return _.toLower(cust.relationshipFlag) == "applicant";
        });
        var insured = _.find(sourceData.coreProposalInsuredVOList || [], function (cust) {
          return _.toLower(cust.relationshipFlag) == "insurant";
        });
        var quoteVehicle = sourceData.coreProposalVehicleVO || {};
        quoteVehicle.useAttribute = this.useAttribute == '2' ? '非营运' : '营运';
        quoteVehicle.ownAttribute = this.custAttribute == '0' ? '个人' : this.custAttribute == '1' ? '机关' : '企业';
        var vehicleKind = _.find(this.VEHICLE_KIND, function (item) {
          return quoteVehicle.vehicleType == item.dictCode;
        });
        quoteVehicle.vehicleType = vehicleKind ? vehicleKind.dictName : quoteVehicle.vehicleType;
        this.proposalData = {
          'quoteVehicle': quoteVehicle,
          'applicant': applicant,
          "insured": insured,
          "amount": sourceData.totalActualPremium,
          "bzProduct": bzProduct,
          "bsProduct": bsProduct,
          "nonProduct": nonProduct,
          "insCorpCode": sourceData.insCorpCode,
          "insCorpName": sourceData.insCorpName,
          "insCityCode": sourceData.insCityCode,
          "insCityName": sourceData.insCityName,
          'hasBz': !_.isEmpty(bzProduct),
          'hasBs': !_.isEmpty(bsProduct),
          'hasNon': !_.isEmpty(nonProduct),
          'quoteTime': sourceData.quoteTime,
          'quoteNo': sourceData.quoteNo
        };
        var _temp = this.proposalData.bsProduct ? this.proposalData.bsProduct.coreProposalProductDutyVO : null;
        if (_temp) {
          this.proposalData.bsProduct.coreProposalProductDutyVO = _.sortBy(_temp, function (o) {
            return o.riskkindCode;
          });
        }
        this.imgUrl = require("@/assets/images/company_logo/".concat(_.toLower(sourceData.insCorpCode), ".png"));
        // this.$nextTick(function () {
        //     this.toImg();
        // })
      }
    },
    closePage: function closePage() {
      //页面关闭回调函数
      this.hasImg = false;
      this.imgData = null;
      this.timer = null;
      clearInterval(this.timer);
      this.$emit('closeProposal', false);
      this.$forceUpdate();
    },
    // 去核保防抖
    closeUnderwriting: function closeUnderwriting(boolean) {
      var _this2 = this;
      if (this.timeout2) {
        clearTimeout(this.timeout2);
      }
      this.timeout2 = setTimeout(function () {
        _this2.closeUnderwriting1(boolean);
      }, 500);
    },
    closeUnderwriting1: function closeUnderwriting1() {
      //去核保
      this.timer = null;
      clearInterval(this.timer);
      this.$emit('closeUnderwriting', false);
      this.$forceUpdate();
    },
    toImg: function toImg() {
      //生成图片
      window.scroll(0, 0); // 首先先顶部
      var targetDom = document.querySelector("#proposal"); // 获取要截图的元素
      var copyDom = targetDom.cloneNode(true); // 克隆一个
      copyDom.style.width = targetDom.scrollWidth + 'px';
      copyDom.style.height = targetDom.scrollHeight + 'px';
      document.body.appendChild(copyDom); // 添加
      var self = this;
      self.loading = true;
      if (!self.$refs.proposal) {
        self.loading = false;
        return;
      }
      setTimeout(function () {
        html2canvas(copyDom, {
          width: targetDom.clientWidth,
          height: targetDom.clientHeight,
          useCORS: true,
          // 开启跨域
          scale: 3
        }).then(function (canvas) {
          document.body.removeChild(copyDom); // 用完要删除
          var dataURL = canvas.toDataURL('image/png');
          self.imgData = dataURL;
          self.hasImg = true;
          self.loading = false;
        });
      }, 200);
    }
  }
};