import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.find.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SYX_RISK from '@/assets/json/SYX_RISK';
import dict from "@/mixins/dict";
export default {
  name: "tableForm",
  mixins: [dict],
  props: {
    dutyData: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    showModifyButton: {
      type: Boolean,
      default: false
    },
    policy: {
      type: Boolean,
      default: false
    },
    source: {
      type: String,
      default: ''
    },
    formRiskType: {
      type: String,
      default: ''
    },
    currentInsCrop: {
      type: Object,
      default: null
    }
  },
  data: function data() {
    return {
      checkedList: []
    };
  },
  computed: {},
  watch: {
    dutyData: {
      handler: function handler(newValue) {
        if (!_.isEmpty(newValue)) {
          this.checkedList = _.sortBy(newValue, function (o) {
            return o.riskkindCode || o.riskkindName;
          });
          // 获取商业险险别
          var _dutyAllCode = SYX_RISK;
          this.checkedList.forEach(function (duty) {
            var _duty = _.find(_dutyAllCode, function (item) {
              return item.dictCode == duty.riskkindCode || item.dictCode == duty.riskkindName;
            });
            if (_duty) {
              duty.riskkindCode = _duty.dictCode;
              duty.riskkindName = _duty.simpleName;
            }
          });
          this.$forceUpdate();
        }
      },
      deep: true
    }
  },
  created: function created() {},
  mounted: function mounted() {
    if (!_.isEmpty(this.dutyData)) {
      this.checkedList = _.sortBy(this.dutyData, function (o) {
        return o.riskkindCode;
      });
      // 获取商业险险别
      var _dutyAllCode = SYX_RISK;
      this.checkedList.forEach(function (duty) {
        var _duty = _.find(_dutyAllCode, function (item) {
          return item.dictCode == duty.riskkindCode || item.dictCode == duty.riskkindName;
        });
        if (_duty) {
          duty.riskkindCode = _duty.dictCode;
          duty.riskkindName = _duty.simpleName;
        }
      });
    }
  },
  methods: {
    // 保单详情----车辆安全检测特约条款
    getPolicyDutyServicetermses: function getPolicyDutyServicetermses(item) {
      var text = '';
      if (!_.isEmpty(item.policyDutyServicetermses)) {
        item.policyDutyServicetermses.forEach(function (item) {
          text = text + item.serviceText + ':' + item.serviceTimes + '次';
        });
      } else {
        text = (item.quantity ? item.quantity : 0) + '次';
      }
      ;
      return text;
    },
    // 报价页面----车辆安全检测特约条款
    getInsureDutyServicetermses: function getInsureDutyServicetermses(item) {
      var text = '';
      if (!_.isEmpty(item.proposalProductDutyServicetermsUpdateVOList)) {
        item.proposalProductDutyServicetermsUpdateVOList.forEach(function (item) {
          text = text + item.serviceText + ':' + item.serviceTimes + '次';
        });
      } else {
        text = (item.quantity ? item.quantity : 0) + '次';
      }
      ;
      return text;
    },
    // 拼接增值服务次数
    getQuantity: function getQuantity(item) {
      var text = '';
      if (!_.isEmpty(item.proposalProductDutyServicetermsUpdateVOList)) {
        item.proposalProductDutyServicetermsUpdateVOList.forEach(function (item) {
          text = text + item.serviceText + ':' + item.serviceTimes + ',';
        });
      } else if (!_.isEmpty(item.policyDutyServicetermses)) {
        item.policyDutyServicetermses.forEach(function (item) {
          text = text + item.serviceText + ':' + item.serviceTimes + ',';
        });
      }
      if (text && text.lastIndexOf(',') != -1) {
        text = text.substr(0, text.length - 1);
      }
      return text;
    },
    // 计算新增设备险保额
    calcDeviceAmount: function calcDeviceAmount(devices) {
      if (_.isEmpty(devices)) return '';
      var totalAmount = 0;
      devices.forEach(function (element) {
        totalAmount = totalAmount + element.unitPrice * element.quantity;
      });
      return totalAmount;
    },
    modifyAmount: function modifyAmount() {
      this.$emit("modifyAmount");
    },
    formatOption: function formatOption(amount) {
      if (amount) {
        var divideValue = _.divide(amount, 10000);
        var text = amount;
        if (divideValue >= 1) {
          text = divideValue + '万';
        }
        return text;
      }
    }
  }
};