import _toConsumableArray from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import commonRequest from "@/api/common";
import draggable from "vuedraggable";
import slipRequest from "@/api/insuranceslip";
import { limitInput } from "@/utils/common";
export default {
  components: {
    draggable: draggable
  },
  props: {
    refresh: {
      type: Boolean,
      default: false
    },
    quoteId: {
      type: String,
      default: ''
    },
    proposalId: {
      type: Number,
      default: 0
    },
    showPayBtn: {
      type: Boolean,
      default: true
    },
    businessTypeList: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      mediaTypeList: [],
      form: {
        businessType: '',
        ID: '',
        urgency: '',
        // 通融紧急程度 1-普通，2-紧急
        selfPriceRate: '',
        //自主定价系数 0.65-1.35
        reason: "",
        // 文件列表
        imageList: []
      },
      rules: {
        urgency: {
          required: true,
          message: '请选择通融紧急程度',
          trigger: "change"
        },
        selfPriceRate: {
          required: true,
          message: '请输入自主定价系数',
          trigger: "blur"
        },
        reason: {
          required: true,
          message: '请输入通融原因',
          trigger: "blur"
        }
      },
      imageInsList: [],
      subTypeList: [],
      upStyleloading: false,
      imageUrl: "",
      currentBusinessTypeItem: {}
    };
  },
  watch: {
    refresh: function refresh(newVal, oldVal) {
      if (newVal) {
        this.mediaTypeList = this.businessTypeList || [];
        if (this.proposalId) {
          this.getMediaTypes(this.proposalId);
        }
      }
    }
  },
  created: function created() {
    this.mediaTypeList = this.businessTypeList || [];
    if (this.proposalId) {
      this.getMediaTypes(this.proposalId);
    }
  },
  methods: {
    //获取影像上传类型
    getMediaTypes: function getMediaTypes(proposalId) {
      var _this = this;
      this.upStyleloading = true;
      slipRequest.getMediaTypes(proposalId).then(function (res) {
        _this.upStyleloading = false;
        if (res.code == RESPONSE_SUCCESS && res.data) {
          _this.mediaTypeList = res.data.mediaTypeList || [];
          _this.$forceUpdate();
        }
      }).catch(function (err) {
        _this.upStyleloading = false;
      });
    },
    // 选择上传影像证件类型，添加一条内容
    imgIdType: function imgIdType(val) {
      if (val) {
        var item = _.find(this.subTypeList, function (item) {
          return item.code == val;
        });
        var newItem = {
          mediaTypeName: this.currentBusinessTypeItem.name + "-" + item.name,
          imageList: [],
          mediaTypeCode: this.currentBusinessTypeItem.code + "|" + item.code
        };
        var isNewItem = _.find(this.imageInsList, function (item) {
          return item.mediaTypeCode == newItem.mediaTypeCode;
        });
        if (!_.isEmpty(isNewItem)) {
          this.$message({
            type: "warning",
            message: "已存在此类型",
            offset: this.msgOffset,
            customClass: 'zZindex'
          });
        } else {
          this.imageInsList.push(newItem);
        }
      }
    },
    // 判断图片是否已经上传过
    handleImgReturn: function handleImgReturn(item) {
      var isExist = _.find(item.imageList, function (eitem) {
        return eitem.documentId;
      });
      return _.isEmpty(isExist);
    },
    // 删除单个影像
    imgInsClose: function imgInsClose(index) {
      this.imageInsList.splice(index, 1);
    },
    // 影像上传
    uploadImg: function uploadImg(file, item) {
      var _this2 = this;
      var imageUrl = URL.createObjectURL(file.raw);
      var formData = new FormData();
      formData.append("fileList", file.raw);
      formData.append("quoteId", this.quoteId);
      var fileName = file.name;
      var fileNameLen = fileName.length;
      var suffix = fileName.substring(fileName.lastIndexOf('.'));
      if (fileNameLen > 40) {
        fileName = fileName.substring(0, 40 - suffix.length) + suffix;
      }
      formData.append("fileName", fileName);
      if (item && item.code) formData.append("fileMimeType", item.code.split('|')[1]);
      this.upStyleloading = true;
      commonRequest.storageMedia(formData).then(function (res) {
        _this2.upStyleloading = false;
        if (res.code == 200) {
          var imgItem = {
            imageName: fileName,
            imageId: res.data[0].fileStorageId,
            mediaTypeCode: item.mediaTypeCode,
            mediaTypeName: item.mediaTypeName,
            imgUrl: imageUrl,
            documentId: null
          };
          item.imageList.push(imgItem);
        }
      }).catch(function (error) {
        _this2.upStyleloading = false;
      });
    },
    // 影像上传
    uploadImgChange: function uploadImgChange(file, item) {
      var suffix = file.name.substring(file.name.lastIndexOf('.') + 1);
      var canUpload = ['image/jpeg', 'image/png', 'application/pdf'].includes(file.raw.type) && ['jpeg', 'jpg', 'png', 'pdf'].includes(suffix);
      var isLt2M = file.size / 1024 / 1024 < 5;
      if (!canUpload) {
        return this.$message.warning("上传文件只能是jpg, png和pdf格式!");
      }
      if (!isLt2M) {
        return this.$message.warning("上传文件的大小不能超过 5MB!");
      }
      this.uploadImg(file, item);
    },
    // 右侧排序
    rightSort: function rightSort(e) {
      return false;
    },
    // 右边拖动时
    rightOnMove: function rightOnMove(e, originalEvent) {
      return false;
    },
    // 鼠标离开事件
    mouseLeave: function mouseLeave(str) {
      var dom1 = document.querySelector('#' + str);
      if (dom1) dom1.style.display = 'none';
    },
    // 鼠标悬浮事件
    mouseOver: function mouseOver(str) {
      var dom1 = document.querySelector('#' + str);
      if (dom1) dom1.style.display = 'inline-block';
    },
    // 查看PDF文件
    openPdfFile: function openPdfFile(item) {
      if (item && item.imgUrl) window.open(item.imgUrl, '_blank');
    },
    // 影像上传身份类型
    imgPeopleType: function imgPeopleType(val) {
      if (val) {
        var item = _.find(this.mediaTypeList, function (item) {
          return item.code == val;
        });
        this.form.ID = "";
        this.subTypeList = item.subItemList;
        this.currentBusinessTypeItem = item;
        this.imgUploadPeopleType = item;
      }
    },
    // 对输入内容做精度校验
    onInput: function onInput(num, min, max, limit) {
      this.$forceUpdate();
      if (limit > 0) {
        if (min && parseFloat(num) < parseFloat(min)) {
          num = min;
        }
        if (max && parseFloat(num) > parseFloat(max)) {
          num = max;
        }
      } else {
        if (min && num < min) {
          num = min;
        }
        if (max && num > max) {
          num = max;
        }
      }
      return limitInput(num, limit);
    },
    delRightImgItem: function delRightImgItem(eitem, eindex, item) {
      //删除图片
      item.imageList.splice(eindex, 1);
    },
    // 自定义校验
    validate: function validate() {
      // 自主定价系数校验
      var flag = false;
      if (this.form.selfPriceRate < 0.65 || this.form.selfPriceRate > 1.35) {
        this.$message.warning('自主定价系数可调整范围在 0.65 到 1.35 之间，请重新输入');
        flag = true;
        return flag;
      }
      ;
      // 文件列表校验
      if (!this.imageInsList.length || this.imageInsList.some(function (val) {
        return !val.imageList.length;
      })) {
        this.$message.warning('请上传文件');
        flag = true;
        return flag;
      }
      ;
      return flag;
    },
    // 保存
    save: function save() {
      var _this3 = this;
      this.$refs.detail.validate(function (valid) {
        if (valid) {
          if (_this3.validate()) return;
          _this3.form.imageList = [];
          _this3.imageInsList.forEach(function (ele) {
            var _this3$form$imageList;
            (_this3$form$imageList = _this3.form.imageList).push.apply(_this3$form$imageList, _toConsumableArray(ele.imageList));
          });
          _this3.$emit('callBack', _this3.form);
        }
      });
    }
  }
};