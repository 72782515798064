import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import dict from '@/mixins/dict';
import Pagination from '@/components/Pagination';
import DatePicker from '@/components/DatePicker';
import commonReq from '@/api/common';
import OemSelect from '@/components/OemSelect';
import { commonExport, getCurrentDate } from '@/utils/common';
export default {
  name: 'TactPushLog',
  components: {
    Pagination: Pagination,
    OemSelect: OemSelect,
    DatePicker: DatePicker
  },
  mixins: [initHeight, dict],
  computed: {},
  data: function data() {
    return {
      searchForm: {},
      startEndTime: [getCurrentDate(), getCurrentDate()],
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      loading: false,
      highlightCurrent: true,
      drawer: false,
      multipleSelection: [],
      loadingSales: false
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.loadData();
  },
  methods: {
    // 列表行选择事件
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    selectItems: function selectItems(row, index) {
      //根据状态判断是否可以选中
      if (['TO_PULL', 'PULL_FAIL'].includes(row.dataStatus)) {
        return 1; //可勾选
      } else {
        return 0; //不可勾选
      }
    },
    batchReTry: function batchReTry(item) {
      var _this = this;
      var ids = [];
      if (!_.isEmpty(item)) {
        item.loadingSales = true;
        ids.push(item.messageLogId);
      } else {
        if (!_.isEmpty(this.multipleSelection)) {
          ids = _.map(this.multipleSelection, 'messageLogId');
        }
      }
      if (_.isEmpty(ids)) return;
      if (!item && ids) this.loadingSales = true;
      commonReq.batchGetVehicleSales(ids.toString()).then(function (res) {
        if (item) item.loadingSales = false;
        _this.loadingSales = false;
        if (res.code == RESPONSE_SUCCESS) {
          _this.loadData();
        }
      }).catch(function (err) {
        if (item) item.loadingSales = false;
        _this.loadingSales = false;
      });
    },
    // 输入的车架号中的字母自动转大写，并去除i，o，q
    checkValue: function checkValue(val) {
      if (val) {
        val = val.replace(/i/g, '').replace(/I/g, '').replace(/o/g, '').replace(/O/g, '').replace(/q/g, '').replace(/Q/g, '');
      }
      this.searchForm.vin = character(_.toUpper(val));
    },
    breakData: function breakData() {
      this.loadData();
    },
    // 导出
    exportFn: function exportFn() {
      var searchForm = _objectSpread(_objectSpread({}, this.searchForm), this.listQuery);
      var exporParams = {
        total: this.total,
        limitNum: 200000,
        fileName: 'TACT数据推送记录',
        exportUrl: '/ps-ftms-service/ftms/push/vehicleSales/log/search',
        searchForm: searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    // 日期选择事件
    getPickersDate: function getPickersDate(name, val) {
      if (val && val.length > 1) {
        this[name] = val;
      } else {
        this[name] = [];
      }
    },
    // 重置搜索表单
    resetSearch: function resetSearch() {
      this.searchForm = {};
      this.startEndTime = [getCurrentDate(), getCurrentDate()];
      this.listQuery.pageNum = 1;
    },
    // 搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    // 获取列表数据
    loadData: function loadData(flag) {
      var _this2 = this;
      this.loading = true;
      this.drawer = false;
      this.searchForm.pageNum = flag ? 1 : this.listQuery.pageNum;
      this.searchForm.pageSize = this.listQuery.pageSize;
      if (this.startEndTime) {
        this.searchForm.startDate = this.startEndTime[0] && this.startEndTime[0].split(' ')[0] + ' 00:00:00' || null;
        this.searchForm.endDate = this.startEndTime[1] && this.startEndTime[1].split(' ')[0] + ' 23:59:59' || null;
      }
      commonReq.getTactPushLog(this.searchForm).then(function (res) {
        _this2.loading = false;
        if (res.code === 200 && res.data && res.data.list) {
          _this2.tableData = res.data.list;
          _this2.tableData = res.data.list.map(function (res) {
            _this2.$set(res, 'loadingSales', false);
            return res;
          });
          _this2.total = res.data.page.recordsTotal;
        }
      }).catch(function (err) {
        _this2.loading = false;
      });
    }
  }
};