import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import dict from '../../mixins/dict';
import initHeight from '@/mixins/initHeight';
import Dialog from '@/components/Dialog';
import policyTemplateRequest from "@/api/policyTemplate";
import Pagination from '@/components/Pagination';
import Search from "@/components/Search";
import ConfigDetail from "./configDetail";
export default {
  mixins: [dict, initHeight],
  components: {
    Dialog: Dialog,
    Pagination: Pagination,
    Search: Search,
    ConfigDetail: ConfigDetail
  },
  data: function data() {
    return {
      form: {},
      searchForm: {},
      tableData: [],
      supplyers: [],
      riskEnum: [],
      fileList: [],
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      loading: false,
      total: 0,
      showConfigDialog: false,
      configOpType: "add",
      configItemForm: {},
      templateId: null,
      opType: ""
    };
  },
  watch: {
    $route: {
      handler: function handler(newValue, oldValue) {
        this.loadData();
      },
      deep: true
    }
  },
  created: function created() {},
  mounted: function mounted() {
    this.loadData();
  },
  methods: {
    // 删除
    deleteTemplateConfig: function deleteTemplateConfig(item) {
      var _this = this;
      this.$confirm('是否删除当前数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        policyTemplateRequest.deleteTemplateConfig(item.configId).then(function (res) {
          if (res.code == 200) {
            _this.$message.success(res.msg);
            _this.loadData();
          }
        });
      });
    },
    // 打开弹窗
    openDialog: function openDialog(opType, item) {
      this.showConfigDialog = true;
      this.configOpType = opType;
      this.configItemForm = item;
    },
    // 刷新
    breakData: function breakData() {
      // this.resetSearch();
      this.searchData();
    },
    // 重置
    resetSearch: function resetSearch() {
      this.searchForm = {};
    },
    // 查询
    searchData: function searchData() {
      this.loadData();
    },
    closePage: function closePage(isRefresh) {
      //关闭弹窗
      // this.$emit('closePage', isRefresh);
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.go(-1);
    },
    // 关闭配置页面
    closeConfigPage: function closeConfigPage(isRefresh) {
      this.showConfigDialog = false;
      if (isRefresh) {
        this.loadData();
      }
    },
    loadData: function loadData() {
      var _this2 = this;
      this.templateId = this.$route.query.templateId;
      this.loading = true;
      policyTemplateRequest.getConfigList(_objectSpread(_objectSpread(_objectSpread({}, this.listQuery), this.searchForm), {}, {
        templateId: this.templateId
      })).then(function (res) {
        _this2.loading = false;
        if (res.code == 200) {
          _this2.tableData = res.data.list;
          _this2.total = res.data.page.recordsTotal;
        }
      }).catch(function (err) {
        return _this2.loading = false;
      });
    }
  }
};