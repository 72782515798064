/**
 * 任务分配
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 获取列表
   * @param {*} data 内容
   */
  getList: function getList(data) {
    return request.get(getUrl("/renewal/task/distribution/monitor/search", 1), {
      params: data
    });
  },
  /**
   * 获取分配类型
   * @param {*} data 内容
   */
  getDistributionType: function getDistributionType(data) {
    return request.get(getUrl("/renewal/task/distribution/monitor/getRenewalSituation", 1), {
      params: data
    });
  },
  /**
   * 新增分配
   * @param {*} data 内容
   */
  createDistribution: function createDistribution(data) {
    return request.post(getUrl("/renewal/task/distribution/monitor/distributionRenewal", 1), data);
  },
  /**
   * 删除分配
   * @param {*} id 内容
   */
  deleteDistribution: function deleteDistribution(id) {
    return request.post(getUrl("/renewal/task/distribution/monitor/delete?id=".concat(id), 1));
  },
  /**
   * 查看操作明细
   * @param {*} data 内容
   */
  getDistributionDetailList: function getDistributionDetailList(data) {
    return request.get(getUrl("/renewal/task/distribution/monitor/listItem", 1), {
      params: data
    });
  }
};
export default objApi;