var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-m-cls area-dialog", staticStyle: {} }, [
    _c(
      "div",
      {
        ref: "header",
        staticClass: "header-cls",
        staticStyle: { "max-height": "540px", overflow: "auto" },
      },
      [
        _c(
          "el-card",
          { attrs: { shadow: "never" } },
          [
            _c(
              "el-form",
              {
                ref: "detail",
                staticStyle: { "margin-bottom": "-20px" },
                attrs: {
                  model: _vm.form,
                  rules: _vm.rules,
                  "label-width": "114px",
                  size: "mini",
                },
                nativeOn: {
                  submit: function ($event) {
                    $event.preventDefault()
                  },
                },
              },
              [
                _vm.currentType === "CITY"
                  ? _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "省：", prop: "parentId" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      placeholder: "请选择省",
                                      filterable: "",
                                    },
                                    model: {
                                      value: _vm.form.parentId,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "parentId", $$v)
                                      },
                                      expression: "form.parentId",
                                    },
                                  },
                                  _vm._l(_vm.provinceOption, function (item) {
                                    return _c("el-option", {
                                      key: item.districtId,
                                      attrs: {
                                        label: item.name,
                                        value: item.districtId,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.currentType === "DISTRICT"
                  ? _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "省：",
                                  prop: "NoNeedProvinceId",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      placeholder: "请选择省",
                                      filterable: "",
                                    },
                                    on: { change: _vm.provinceChange },
                                    model: {
                                      value: _vm.form.NoNeedProvinceId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "NoNeedProvinceId",
                                          $$v
                                        )
                                      },
                                      expression: "form.NoNeedProvinceId",
                                    },
                                  },
                                  _vm._l(_vm.provinceOption, function (item) {
                                    return _c("el-option", {
                                      key: item.districtId,
                                      attrs: {
                                        label: item.name,
                                        value: item.districtId,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.currentType === "DISTRICT"
                  ? _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "市：", prop: "parentId" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      placeholder: "请选择市",
                                      filterable: "",
                                    },
                                    model: {
                                      value: _vm.form.parentId,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "parentId", $$v)
                                      },
                                      expression: "form.parentId",
                                    },
                                  },
                                  _vm._l(_vm.cityOption, function (item) {
                                    return _c("el-option", {
                                      key: item.districtId,
                                      attrs: {
                                        label: item.name,
                                        value: item.districtId,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "编码：", prop: "code" } },
                          [
                            _c("el-input", {
                              attrs: {
                                maxlength: "40",
                                placeholder: "请输入编码",
                                clearable: "",
                              },
                              model: {
                                value: _vm.form.code,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "code",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "form.code",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "名称：", prop: "name" } },
                          [
                            _c("el-input", {
                              attrs: {
                                maxlength: "40",
                                placeholder: "请输入名称",
                                clearable: "",
                              },
                              model: {
                                value: _vm.form.name,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "name",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "form.name",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "tact类型：", prop: "ftmsType" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  placeholder: "请选择市",
                                  filterable: "",
                                },
                                model: {
                                  value: _vm.form.ftmsType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "ftmsType", $$v)
                                  },
                                  expression: "form.ftmsType",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "省", value: "PROVINCE" },
                                }),
                                _c("el-option", {
                                  attrs: { label: "市", value: "CITY" },
                                }),
                                _c("el-option", {
                                  attrs: { label: "区", value: "DISTRICT" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "tact编码：", prop: "ftmsCode" } },
                          [
                            _c("el-input", {
                              attrs: {
                                maxlength: "40",
                                placeholder: "请输入tact编码",
                                clearable: "",
                              },
                              model: {
                                value: _vm.form.ftmsCode,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "ftmsCode",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "form.ftmsCode",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "tact名称：", prop: "ftmsName" } },
                          [
                            _c("el-input", {
                              attrs: {
                                maxlength: "40",
                                placeholder: "请输入tact名称",
                                clearable: "",
                              },
                              model: {
                                value: _vm.form.ftmsName,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "ftmsName",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "form.ftmsName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticStyle: {
          display: "flex",
          "justify-content": "center",
          "margin-top": "8px",
        },
      },
      [
        _c(
          "el-button",
          {
            attrs: {
              icon: "el-icon-check",
              type: "primary",
              plain: "",
              size: "mini",
            },
            on: { click: _vm.save },
          },
          [_vm._v("保存")]
        ),
        _c(
          "el-button",
          {
            attrs: { icon: "el-icon-close", size: "mini" },
            on: { click: _vm.closePage },
          },
          [_vm._v("关闭")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }