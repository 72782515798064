var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: {},
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "500px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "detail",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.formRules,
                    "label-width": "120px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "接入配置代码" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "接入配置代码",
                                  maxlength: "100",
                                  clearable: "",
                                  type: "text",
                                },
                                model: {
                                  value: _vm.form.ssoSettingCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "ssoSettingCode", $$v)
                                  },
                                  expression: "form.ssoSettingCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "接入配置名称" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "接入配置名称",
                                  maxlength: "40",
                                  clearable: "",
                                  type: "text",
                                },
                                model: {
                                  value: _vm.form.ssoSettingName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "ssoSettingName", $$v)
                                  },
                                  expression: "form.ssoSettingName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "接入服务策略" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    placeholder: "请选择接入服务策略",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.form.ssoStrategy,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "ssoStrategy", $$v)
                                    },
                                    expression: "form.ssoStrategy",
                                  },
                                },
                                _vm._l(
                                  _vm.SsoStrategyList,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.name,
                                        value: item.code,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "接入状态", prop: "enabled" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.form.enabled,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "enabled", $$v)
                                    },
                                    expression: "form.enabled",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: true } }, [
                                    _vm._v("是"),
                                  ]),
                                  _c("el-radio", { attrs: { label: false } }, [
                                    _vm._v("否"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "业务类型：", prop: "bizType" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    disabled: _vm.opType != "add",
                                    placeholder: "请选择业务类型",
                                    clearable: "",
                                  },
                                  on: { change: _vm.getOrgCode },
                                  model: {
                                    value: _vm.form.bizType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "bizType", $$v)
                                    },
                                    expression: "form.bizType",
                                  },
                                },
                                _vm._l(
                                  _vm.dictSource["BIZ_TYPE"],
                                  function (item, index) {
                                    return _c("el-option", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: item.dictName == "主机厂端",
                                          expression:
                                            "item.dictName == '主机厂端'",
                                        },
                                      ],
                                      key: index,
                                      attrs: {
                                        label: item.dictName,
                                        value: item.dictCode,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "业务机构", prop: "bizOrgCode" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    disabled: _vm.opType != "add",
                                    placeholder: "请选择业务机构",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.form.bizOrgCode,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "bizOrgCode", $$v)
                                    },
                                    expression: "form.bizOrgCode",
                                  },
                                },
                                _vm._l(_vm.AllList, function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.oemName,
                                      value: item.oemCode,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "是否创建业务员",
                                prop: "createEmployeeTypeFlag",
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.form.createEmployeeTypeFlag,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "createEmployeeTypeFlag",
                                        $$v
                                      )
                                    },
                                    expression: "form.createEmployeeTypeFlag",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: true } }, [
                                    _vm._v("是"),
                                  ]),
                                  _c("el-radio", { attrs: { label: false } }, [
                                    _vm._v("否"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "是否创建机构",
                                prop: "createOrgFlag",
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.form.createOrgFlag,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "createOrgFlag", $$v)
                                    },
                                    expression: "form.createOrgFlag",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: true } }, [
                                    _vm._v("是"),
                                  ]),
                                  _c("el-radio", { attrs: { label: false } }, [
                                    _vm._v("否"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "管理岗用户角色" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: {
                                    width: "100%",
                                    "max-height": "40px",
                                  },
                                  attrs: {
                                    size: "mini",
                                    multiple: "",
                                    "collapse-tags": "",
                                    placeholder: "请选择管理岗用户角色",
                                    disabled: _vm.opType == "view",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$forceUpdate()
                                    },
                                  },
                                  model: {
                                    value: _vm.form.managerRoleIds,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "managerRoleIds", $$v)
                                    },
                                    expression: "form.managerRoleIds",
                                  },
                                },
                                _vm._l(_vm.roles, function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      disabled: !item.enabled,
                                      label:
                                        "" +
                                        (!item.enabled
                                          ? item.roleName + "(已禁用)"
                                          : item.roleName),
                                      value: item.roleId,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "普通岗用户角色" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: {
                                    width: "100%",
                                    "max-height": "40px",
                                  },
                                  attrs: {
                                    size: "mini",
                                    multiple: "",
                                    "collapse-tags": "",
                                    placeholder: "请选择普通岗用户角色",
                                    disabled: _vm.opType == "view",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$forceUpdate()
                                    },
                                  },
                                  model: {
                                    value: _vm.form.normalRoleIds,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "normalRoleIds", $$v)
                                    },
                                    expression: "form.normalRoleIds",
                                  },
                                },
                                _vm._l(_vm.roles, function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      disabled: !item.enabled,
                                      label:
                                        "" +
                                        (!item.enabled
                                          ? item.roleName + "(已禁用)"
                                          : item.roleName),
                                      value: item.roleId,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "机构接口地址" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "机构接口地址",
                                  maxlength: "100",
                                  clearable: "",
                                  type: "text",
                                },
                                model: {
                                  value: _vm.form.thirdPartOrgUrl,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "thirdPartOrgUrl", $$v)
                                  },
                                  expression: "form.thirdPartOrgUrl",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "用户接口地址" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "用户接口地址",
                                  maxlength: "100",
                                  clearable: "",
                                  type: "text",
                                },
                                model: {
                                  value: _vm.form.thirdPartUserUrl,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "thirdPartUserUrl", $$v)
                                  },
                                  expression: "form.thirdPartUserUrl",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "line-height": "46px",
            height: "46px",
            padding: "8px 0px",
            "margin-top": "5px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-check",
                type: "primary",
                plain: "",
                size: "mini",
              },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: { click: _vm.closePage },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }