import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import _defineProperty from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '../../mixins/initHeight';
import dict from '../../mixins/dict';
import Pagination from '@/components/Pagination';
import OemSelect from '@/components/OemSelect';
import request from '../../api/finance';
import { getSupplyers, getCityItem, limitInput } from '@/utils/common';
export default {
  components: {
    Pagination: Pagination,
    OemSelect: OemSelect
  },
  mixins: [initHeight, dict],
  data: function data() {
    return {
      showFeePolicyDialog: false,
      regionOptions: [],
      formLoading: true,
      FeePolicyConfigRules: {
        conditionName: [{
          required: true,
          message: '请输入子政策名称',
          trigger: 'blur'
        }],
        bzRate: [{
          required: true,
          message: '请输入交强险费率',
          trigger: 'blur'
        }],
        bsRate: [{
          required: true,
          message: '请输入商业险费率',
          trigger: 'blur'
        }],
        accidentRate: [{
          required: true,
          message: '请输入驾意险费率',
          trigger: 'blur'
        }],
        priority: [{
          required: true,
          message: '请输入优先级',
          trigger: 'blur'
        }]
      },
      FeePolicyConfigForm: {},
      FeePolicyForm: {},
      //表单
      FeePolicyRules: {
        insCorpCode: [{
          required: true,
          message: '请选择保险公司',
          trigger: 'change'
        }],
        address: [{
          required: true,
          message: '请选择业务区域',
          trigger: 'change'
        }],
        policyName: [{
          required: true,
          message: '请输入政策名称',
          trigger: 'blur'
        }],
        policyBeginTime: [{
          required: true,
          message: '请选择生效时间',
          trigger: 'change'
        }],
        priority: [{
          required: true,
          message: '请输入优先级',
          trigger: 'change'
        }],
        policyOrgList: [{
          required: true,
          message: '请输入业务机构',
          trigger: 'change'
        }]
      },
      opType: '',
      policyFactorList: [],
      multipleDefaultCheckedKeys: [],
      drawer: false,
      searchForm: {},
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      orgData: {
        salesOrgCode: '',
        salesOrgName: '',
        organizationId: ''
      },
      tableData: [],
      multipleSelection: [],
      loading: false,
      showConfigDialog: false,
      supplyers: [],
      startTime: [],
      orgForm: {
        salesOrgCode: '',
        salesOrgName: '',
        salesOrgType: ''
      },
      showFeePolicyConfDialog: false,
      currentIndex: 0,
      policyFactorsLoading: false,
      costPolicyId: null,
      radio: ''
    };
  },
  watch: {
    showFeePolicyConfDialog: function showFeePolicyConfDialog(val) {
      if (val) {
        this.getAllPolicyFactors();
      }
    },
    showFeePolicyDialog: function showFeePolicyDialog(val) {
      var _this = this;
      //费率政策详情
      if (val) {
        this.$nextTick(function () {
          return _this.$refs['FeePolicyDetail'].clearValidate();
        });
      }
    }
  },
  mounted: function mounted() {
    //保司
    this.supplyers = getSupplyers();
    this.loadData();
    var city = JSON.parse(localStorage.getItem('VI_CityAreaTree')) || [];
    var partialAddress = [];
    city.forEach(function (item, index) {
      partialAddress.push({
        label: item.label,
        value: item.value,
        children: item.children ? [] : null
      });
      if (item.children) {
        item.children.forEach(function (eitem) {
          partialAddress[index].children.push({
            label: eitem.label == '市辖区' ? item.label : eitem.label,
            value: eitem.value
          });
        });
      }
    });
    this.regionOptions = partialAddress;
  },
  methods: {
    onInput: function onInput(val) {
      var num = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
      return limitInput(val, num);
    },
    deleteFeePolicyConfig: function deleteFeePolicyConfig(item, index) {
      this.FeePolicyForm.conditionList.splice(index, 1);
    },
    getInsCorpByCode: function getInsCorpByCode(code) {
      var temp = _.find(this.supplyers, function (item) {
        return _.toLower(item.code) == _.toLower(code);
      });
      return temp && temp.shortName;
    },
    getcityNameByCode: function getcityNameByCode(code) {
      var city = getCityItem(code);
      return city && city.label;
    },
    handlerSwitch: function handlerSwitch(val, item) {
      var _this2 = this;
      item.enabled = !val;
      this.$confirm("\u6B64\u6B21\u64CD\u4F5C\u4F1A\u5F71\u54CD\u8BE5\u6761\u653F\u7B56\u6240\u6D89\u53CA\u7684\u6240\u6709\u4FDD\u5355\u624B\u7EED\u8D39\u3002\u60A8\u786E\u5B9A\u8981".concat(val ? '启用' : '禁用', "\u8BE5\u653F\u7B56\u5417\uFF1F"), {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        _this2.loading = true;
        request.enabledCostPolicy(item.costPolicyId, val).then(function (res) {
          if (res.code == 200) {
            _this2.$message.success(res.msg);
            _this2.loadData();
          }
          _this2.loading = false;
        });
      }).catch(function () {
        _this2.loadData();
      });
    },
    deleteFeePolicy: function deleteFeePolicy(item) {
      var _this3 = this;
      //删除
      var ids = [item.costPolicyId];
      // if (_.isEmpty(this.multipleSelection)) {
      // } else {
      //     ids = _.map(this.multipleSelection, "costPolicyId")
      // }
      this.$confirm('您确定要将费用政策删除？', {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        request.deleteFeePolicy(ids.join(",")).then(function (res) {
          if (res.code === 200) {
            _this3.$message({
              message: res.msg,
              type: 'success'
            });
            _this3.loadData();
          }
        });
      });
    },
    dateChange: function dateChange(start, end, val) {
      //日期更改监听事件
      if (val && val.length > 1) {
        this.FeePolicyForm[start] = val[0];
        this.FeePolicyForm[end] = val[1].split(" ")[0] + " 23:59:59";
      } else {
        this.FeePolicyForm[start] = null;
        this.FeePolicyForm[end] = null;
      }
      ;
    },
    handeRangeInput: function handeRangeInput(val, item, type) {
      //数字范围
      var isExist = false;
      _.find(this.FeePolicyForm.conditionList[this.currentIndex].categoryList, function (eitem) {
        if (item.factorName == eitem.label) {
          isExist = true;
          eitem[type] = val;
          costPolicyFactorId: item.costPolicyFactorId;
        }
      });
      if (!isExist) {
        var _this$FeePolicyForm$c;
        this.FeePolicyForm.conditionList[this.currentIndex].categoryList.push((_this$FeePolicyForm$c = {
          tagType: "NUMBER_RANGE"
        }, _defineProperty(_this$FeePolicyForm$c, type, val), _defineProperty(_this$FeePolicyForm$c, "label", item.factorName), _defineProperty(_this$FeePolicyForm$c, "costPolicyFactorId", item.costPolicyFactorId), _this$FeePolicyForm$c));
      }
      isExist = false;
    },
    handeTextlInput: function handeTextlInput(val, item) {
      //文本输入框
      var isExist = false;
      _.find(this.FeePolicyForm.conditionList[this.currentIndex].categoryList, function (eitem) {
        if (item.factorName == eitem.label) {
          isExist = true;
          eitem.logicOptionContent = val;
          costPolicyFactorId: item.costPolicyFactorId;
        }
      });
      if (!isExist) {
        this.FeePolicyForm.conditionList[this.currentIndex].categoryList.push({
          tagType: "TEXT_INPUT",
          logicOptionContent: val,
          label: item.factorName,
          costPolicyFactorId: item.costPolicyFactorId
        });
      }
      isExist = false;
    },
    handeNumlInput: function handeNumlInput(val, item) {
      //数字输入框
      var isExist = false;
      _.find(this.FeePolicyForm.conditionList[this.currentIndex].categoryList, function (eitem) {
        if (item.factorName == eitem.label) {
          isExist = true;
          eitem.logicNumerical = val;
          costPolicyFactorId: item.costPolicyFactorId;
        }
      });
      if (!isExist) {
        this.FeePolicyForm.conditionList[this.currentIndex].categoryList.push({
          tagType: "NUMBER_INPUT",
          logicNumerical: val,
          label: item.factorName,
          costPolicyFactorId: item.costPolicyFactorId
        });
      }
      isExist = false;
    },
    handelSelect: function handelSelect(val, item) {
      //下拉
      var isExist = false;
      _.find(this.FeePolicyForm.conditionList[this.currentIndex].categoryList, function (eitem) {
        if (item.factorName == eitem.label) {
          isExist = true;
          eitem.logicOptionContent = val;
          costPolicyFactorId: item.costPolicyFactorId;
        }
      });
      if (!isExist) {
        this.FeePolicyForm.conditionList[this.currentIndex].categoryList.push({
          tagType: "DROP_DOWN",
          logicOptionContent: val,
          label: item.factorName,
          costPolicyFactorId: item.costPolicyFactorId
        });
      }
      isExist = false;
    },
    handelRadio: function handelRadio(val, item) {
      //单选
      var isExist = false;
      _.find(this.FeePolicyForm.conditionList[this.currentIndex].categoryList, function (eitem) {
        if (item.factorName == eitem.label) {
          isExist = true;
          eitem.logicOptionContent = val;
          costPolicyFactorId: item.costPolicyFactorId;
        }
      });
      if (!isExist) {
        this.FeePolicyForm.conditionList[this.currentIndex].categoryList.push({
          tagType: "SINGLE_SELECT",
          logicOptionContent: val,
          label: item.factorName,
          costPolicyFactorId: item.costPolicyFactorId
        });
      }
      isExist = false;
    },
    handelCheckbox: function handelCheckbox(vals, item) {
      //如果已存在直接替换，否则新增
      var isExist = false;
      _.find(this.FeePolicyForm.conditionList[this.currentIndex].categoryList, function (eitem) {
        if (item.factorName == eitem.label) {
          isExist = true;
          eitem.logicOptionContent = vals.join(',');
          costPolicyFactorId: item.costPolicyFactorId;
        }
      });
      if (!isExist) {
        if (_.isEmpty(this.FeePolicyForm.conditionList[this.currentIndex])) this.FeePolicyForm.conditionList[this.currentIndex]['categoryList'] = [];
        this.FeePolicyForm.conditionList[this.currentIndex].categoryList.push({
          tagType: "MULTI_SELECT",
          logicOptionContent: vals.join(','),
          label: item.factorName,
          costPolicyFactorId: item.costPolicyFactorId
        });
      }
      isExist = false;
    },
    editFeePolicyConfig: function editFeePolicyConfig(item, index) {
      //配置政策因子条件
      this.currentIndex = index;
      this.showFeePolicyConfDialog = true;
    },
    handleChange: function handleChange(val) {
      //选择省/市/区
      if (!val) return;
      this.FeePolicyForm.cityCode = val[1]; //城市编码
      this.FeePolicyForm.provinceCode = val[0]; //省编码
      this.FeePolicyForm.address = val;
    },
    getAllPolicyFactors: function getAllPolicyFactors() {
      var _this4 = this;
      //获取所有的政策因子
      this.policyFactorsLoading = true;
      request.getAllPolicyFactors().then(function (res) {
        _this4.policyFactorsLoading = false;
        if (res.code === 200) {
          _this4.policyFactorList = res.data.map(function (item, index) {
            return _objectSpread(_objectSpread({}, item), {}, {
              tagData: JSON.parse(item.tagData),
              checkList: [],
              //多选
              radio: '',
              //单选
              select: '',
              //下拉
              num: 0,
              //数字输入框
              text: "",
              //文本输入框
              numBegin: 0,
              //数字范围开始
              numEnd: 0,
              //数字范围结束,
              timeBegin: "",
              //时间开始
              timeEnd: "" //时间结束,
            });
          });

          if (_this4.opType == 'edit' || _this4.opType == 'view') {
            _this4.policyFactorList.forEach(function (item) {
              var temp = _.find(_this4.FeePolicyForm.conditionList[_this4.currentIndex].categoryList, function (eitem) {
                return eitem.tagType == item.tagType && item.factorName == eitem.label;
              });
              switch (item.tagType) {
                case 'MULTI_SELECT':
                  //多选
                  item.checkList = temp && temp.logicOptionContent.split(',');
                  break;
                case 'SINGLE_SELECT':
                  //单选
                  item.radio = temp && temp.logicOptionContent;
                  break;
                case 'DROP_DOWN':
                  //下拉
                  item.select = temp && temp.logicOptionContent;
                  break;
                case 'NUMBER_INPUT':
                  //数字
                  item.num = temp && temp.logicNumerical;
                  break;
                case 'TEXT_INPUT':
                  //文本
                  item.text = temp && temp.logicOptionContent;
                  break;
                case 'NUMBER_RANGE':
                  //数字范围
                  item.numBegin = temp && temp.logicNumericalBegin;
                  item.numEnd = temp && temp.logicNumericalBegin;
                  break;
                // case 'TIME_RANGE': //数字范围
                //     item.numBegin = temp && temp.logicNumericalBegin;
                //     item.numEnd = temp && temp.logicNumericalBegin;
                //     break;
              }
            });
          }
        }
      });
    },
    createConfig: function createConfig() {
      var _this5 = this;
      //设置子政策条件
      this.showConfigDialog = true;
      this.FeePolicyConfigForm = {
        enabled: true
      };
      this.$nextTick(function () {
        return _this5.$refs['FeePolicyConfigDetail'].clearValidate();
      });
    },
    submit: function submit() {
      var _this6 = this;
      //保存新增费率政策
      this.$refs['FeePolicyDetail'].validate(function (valid) {
        if (valid) {
          var req = _this6.opType == 'edit' ? request.updataFeePolicyConfig(_this6.costPolicyId, _this6.FeePolicyForm) : request.createFeePolicyConfig(_this6.FeePolicyForm);
          req.then(function (res) {
            if (res.code == 200) {
              _this6.$message.success(res.msg);
              _this6.loadData();
            }
          });
        }
      });
    },
    createFeePolicy: function createFeePolicy() {
      //新增费用政策
      this.opType = 'add';
      this.showFeePolicyDialog = true;
      this.formLoading = false;
      this.costPolicyId = null;
      this.FeePolicyForm = {
        enabled: true,
        conditionList: [],
        policyOrgList: [],
        policyBeginTime: ''
      };
      this.startTime = [];
      this.multipleDefaultCheckedKeys = [];
    },
    closeShowDialog: function closeShowDialog() {
      this.showFeePolicyDialog = false;
    },
    selectSubOrg: function selectSubOrg(val) {
      //获取子机构网点
      this.FeePolicyForm.policyOrgList = val;
    },
    edit: function edit(item) {
      //编辑费用政策
      this.opType = "edit";
      this.costPolicyId = item.costPolicyId;
      this.showFeePolicyDialog = true;
      this.getDetailByCostPolicyId(item.costPolicyId);
    },
    getDetailByCostPolicyId: function getDetailByCostPolicyId(costPolicyId) {
      var _this7 = this;
      this.formLoading = true;
      request.getDetailByCostPolicyId(costPolicyId).then(function (res) {
        _this7.formLoading = false;
        if (res.code == 200) {
          _this7.FeePolicyForm = res.data;
          _this7.FeePolicyForm.address = [res.data.provinceCode, res.data.cityCode];
          _this7.startTime = [res.data.policyBeginTime, res.data.policyEndTime];
          if (!_.isEmpty(res.data.policyOrgList)) {
            _this7.multipleDefaultCheckedKeys = res.data.policyOrgList.map(function (item) {
              return item.orgCode;
            });
          }
        }
      });
    },
    viewDetail: function viewDetail(item) {
      //查看
      this.opType = "view";
      this.costPolicyId = item.costPolicyId;
      this.showFeePolicyDialog = true;
      this.getDetailByCostPolicyId(item.costPolicyId);
    },
    saveFeePolicyConfig: function saveFeePolicyConfig() {
      var _this8 = this;
      //保存费率政策配置
      this.$refs['FeePolicyConfigDetail'].validate(function (valid) {
        if (valid) {
          if (_.isEmpty(_this8.FeePolicyForm.conditionList)) _this8.FeePolicyForm['conditionList'] = [];
          _this8.FeePolicyForm.conditionList.push(_objectSpread(_objectSpread({}, _this8.FeePolicyConfigForm), {}, {
            categoryList: []
          }));
          _this8.showConfigDialog = false;
        }
      });
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {};
      this.orgData = {};
    },
    // 刷新
    breakData: function breakData() {
      this.searchData();
      this.orgForm = {
        salesOrgCode: '',
        salesOrgName: '全部',
        salesOrgType: ''
      };
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    importFn: function importFn() {},
    loadData: function loadData() {
      var _this9 = this;
      //获取列表数据
      this.loading = true;
      this.drawer = false;
      this.showRematchDialog = false;
      this.showConfigDialog = false;
      this.showFeePolicyDialog = false;
      var postData = _objectSpread(_objectSpread({}, this.searchForm), this.listQuery);
      request.getFeePolicyConfigList(postData).then(function (res) {
        _this9.loading = false;
        _this9.tableData = res.data.list;
        //总条数
        _this9.total = res.data.page.recordsTotal;
      }).catch(function (err) {
        _this9.loading = false;
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      //列表行选择事件
      this.multipleSelection = val;
    }
  }
};