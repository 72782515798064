//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import carService from '@/api/carService';
import { limitInput } from '@/utils/common';
export default {
  // mixins: [dict],
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    DialogType: {
      type: String,
      default: {}
    },
    insCorpItem: {
      type: Object,
      default: {}
    }
  },
  data: function data() {
    return {
      options: [],
      carsOptions: [],
      form: {
        modelNo: "",
        modelName: "",
        newPrice: "",
        guidePrice: "",
        gradecName: "",
        exhaust: "",
        seatCount: "",
        year: null,
        vehicleBrandId: '',
        vehicleBrandSeriesId: '',
        sortBy: 0
      },
      listLoading: false,
      rules: {
        modelNo: [{
          required: true,
          message: '请输入品牌编号',
          trigger: 'blur'
        }],
        modelName: [{
          required: true,
          message: '请输入品牌名称',
          trigger: 'blur'
        }],
        newPrice: [{
          required: true,
          message: '请输入新车价格',
          trigger: 'blur'
        }],
        guidePrice: [{
          required: true,
          message: '请输入新车指导价',
          trigger: 'blur'
        }],
        gradecName: [{
          required: true,
          message: '请输入排量',
          trigger: 'blur'
        }],
        exhaust: [{
          required: true,
          message: '请输入排量',
          trigger: 'blur'
        }],
        seatCount: [{
          required: true,
          message: '请输入座位数',
          trigger: 'blur'
        }],
        year: [{
          required: true,
          message: '请输入车辆年份',
          trigger: 'blur'
        }],
        vehicleBrandId: [{
          required: true,
          message: '请选择品牌',
          trigger: 'blur'
        }],
        vehicleBrandSeriesId: [{
          required: true,
          message: '请选择车系',
          trigger: 'blur'
        }]
      }
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    onInput: function onInput(num, limit, min, max) {
      // this.$forceUpdate();
      //对输入内容做精度校验
      if (min && num < min) {
        num = min;
      }
      if (max && num > max) {
        num = max;
      }
      var res = limitInput(num, limit);
      return res;
    },
    checkChange: function checkChange(val) {
      var _this = this;
      this.form.vehicleBrandSeriesId = null;
      this.carsOptions = [];
      carService.Options(val).then(function (res) {
        if (res.code === 200) {
          _this.carsOptions = res.data;
        }
      });
    },
    initData: function initData() {
      var _this2 = this;
      // 获取所有品牌列表
      carService.brandOptions().then(function (res) {
        if (res.code === 200) {
          _this2.options = res.data;
        }
      });
      if (this.DialogType === "add") {
        this.form = {
          modelNo: "",
          modelName: "",
          newPrice: "",
          seatCount: "",
          year: null,
          vehicleBrandId: '',
          vehicleBrandSeriesId: '',
          sortBy: 0
        };
        this.options = [];
        this.carsOptions = [];
      } else {
        this.form = this.insCorpItem;
        carService.Options(this.form.vehicleBrandId).then(function (res) {
          if (res.code === 200) {
            _this2.carsOptions = res.data;
          }
        });
      }
    },
    save: function save() {
      var _this3 = this;
      //保存数据
      this.$refs.detail.validate(function (valid) {
        if (valid) {
          var req = _this3.DialogType === "add" ? carService.add(_this3.form) : carService.modelsBrand(_this3.insCorpItem.vehicleBrandModelId, _this3.form);
          req.then(function (res) {
            if (res.code === 200) {
              _this3.$message({
                message: res.msg,
                type: 'success'
              });
              _this3.closePage();
            }
          });
        }
      });
    },
    closePage: function closePage() {
      //关闭弹窗
      this.$refs['detail'].resetFields();
      this.$emit('closePage');
    }
  }
};