var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: { "padding-top": "10px" },
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "600px", overflow: "auto" },
        },
        [
          !_vm._.isEmpty(_vm.ORGOCRFunctionLists)
            ? _c(
                "el-card",
                { attrs: { shadow: "never" } },
                [
                  _c(
                    "el-tabs",
                    {
                      staticClass: "TopTabList",
                      staticStyle: { background: "#fff" },
                      model: {
                        value: _vm.OCRFunctionName,
                        callback: function ($$v) {
                          _vm.OCRFunctionName = $$v
                        },
                        expression: "OCRFunctionName",
                      },
                    },
                    _vm._l(_vm.ORGOCRFunctionLists, function (item, index) {
                      return _c("el-tab-pane", {
                        key: item.itemCode + index,
                        attrs: { label: item.itemName, name: item.itemCode },
                      })
                    }),
                    1
                  ),
                  _c("div", { staticClass: "tips" }, [
                    _c("div", [
                      _vm._v(
                        " *提示：建议上传清晰的图片避免识别有误，支持直接上传及图片拖拽的方式，鼠标移至图片可以直接预览和删除，图片格式：PNG、JPG、JPEG、BMP、TIFF（大小不能大于5M） "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "uploadBox" }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          width: "100%",
                          display: "flex",
                          "flex-direction": "row",
                          "justify-content": "flex-start",
                          "align-items": "flex-start",
                        },
                      },
                      [
                        _c("div", { staticClass: "upload" }, [
                          _vm.OCRFunctionName == "VEHICLE_LICENSE"
                            ? _c("div", { staticClass: "flexColumnStrat" }, [
                                !_vm.vehicleLicenseFrontFileList ||
                                _vm.vehicleLicenseFrontFileList.length == 0
                                  ? _c(
                                      "div",
                                      [
                                        _c("div", { staticClass: "lh20" }, [
                                          _vm._v("行驶证"),
                                        ]),
                                        _c(
                                          "el-upload",
                                          {
                                            staticClass: "upload-demo",
                                            attrs: {
                                              drag: "",
                                              limit: 1,
                                              "before-remove": function () {
                                                return (_vm.formData = "")
                                              },
                                              action: "",
                                              multiple: "",
                                              "show-file-list": false,
                                              "http-request": function (val) {
                                                return _vm.HTuploadFile(
                                                  val,
                                                  "VEHICLE_LICENSE_FRONT",
                                                  "VEHICLE_LICENSE"
                                                )
                                              },
                                              "before-upload": function (val) {
                                                return _vm.HTbeforeUpload(val)
                                              },
                                              "file-list":
                                                _vm.vehicleLicenseFrontFileList,
                                              accept: _vm.uploadType,
                                              "list-type": "text",
                                            },
                                          },
                                          [
                                            _c("div", {
                                              staticClass:
                                                "imgMask vehicleLicenseImgFront",
                                            }),
                                            _c(
                                              "div",
                                              { staticClass: "uploadMask" },
                                              [
                                                _c("i", {
                                                  staticClass: "el-icon-upload",
                                                }),
                                                _c("span", [
                                                  _vm._v(
                                                    "将图片拖到此处或点击上传"
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "div",
                                      { staticClass: "identificationBox" },
                                      [
                                        _c("div", { staticClass: "lh20" }, [
                                          _vm._v("行驶证"),
                                        ]),
                                        _c(
                                          "div",
                                          { staticClass: "identificationImg" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "identificationPreviewImgDel",
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "el-icon-view",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.setFileListFileUrl(
                                                        "vehicleLicenseFrontPreviewImg"
                                                      )
                                                    },
                                                  },
                                                }),
                                                _c("i", {
                                                  staticClass: "el-icon-delete",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.delFileList(
                                                        "vehicleLicenseFrontFileList",
                                                        "vehiclelicenseFormFront"
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "elImageModel" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      "margin-bottom": "20px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "此图片仅用于AAA保险平台使用"
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm.getFileSuffix(
                                              _vm.vehicleLicenseFrontFileList[0]
                                                .fileName
                                            )
                                              ? _c("el-image", {
                                                  ref: "vehicleLicenseFrontPreviewImg",
                                                  staticStyle: {
                                                    width: "100%",
                                                    cursor: "pointer",
                                                  },
                                                  attrs: {
                                                    src: _vm
                                                      .vehicleLicenseFrontFileList[0]
                                                      .fileUrl,
                                                    "preview-src-list": [
                                                      _vm
                                                        .vehicleLicenseFrontFileList[0]
                                                        .fileUrl,
                                                    ],
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                !_vm.vehicleLicenseBackFileList ||
                                _vm.vehicleLicenseBackFileList.length == 0
                                  ? _c(
                                      "div",
                                      { staticStyle: { "margin-top": "10px" } },
                                      [
                                        _c("div", { staticClass: "lh20" }, [
                                          _vm._v("行驶证副页"),
                                        ]),
                                        _c(
                                          "el-upload",
                                          {
                                            staticClass: "upload-demo",
                                            attrs: {
                                              drag: "",
                                              limit: 1,
                                              "before-remove": function () {
                                                return (_vm.formData = "")
                                              },
                                              action: "",
                                              multiple: "",
                                              "show-file-list": false,
                                              "http-request": function (val) {
                                                return _vm.HTuploadFile(
                                                  val,
                                                  "VEHICLE_LICENSE_BACK",
                                                  "VEHICLE_LICENSE"
                                                )
                                              },
                                              "before-upload": function (val) {
                                                return _vm.HTbeforeUpload(val)
                                              },
                                              "file-list":
                                                _vm.vehicleLicenseBackFileList,
                                              accept: _vm.uploadType,
                                              "list-type": "text",
                                            },
                                          },
                                          [
                                            _c("div", {
                                              staticClass:
                                                "imgMask vehicleLicenseImgBack",
                                            }),
                                            _c(
                                              "div",
                                              { staticClass: "uploadMask" },
                                              [
                                                _c("i", {
                                                  staticClass: "el-icon-upload",
                                                }),
                                                _c("span", [
                                                  _vm._v(
                                                    "将图片拖到此处或点击上传"
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "div",
                                      {
                                        staticClass: "identificationBox",
                                        staticStyle: { "margin-top": "10px" },
                                      },
                                      [
                                        _c("div", { staticClass: "lh20" }, [
                                          _vm._v("行驶证副页"),
                                        ]),
                                        _c(
                                          "div",
                                          { staticClass: "identificationImg" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "identificationPreviewImgDel",
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "el-icon-view",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.setFileListFileUrl(
                                                        "vehicleLicenseBackPreviewImg"
                                                      )
                                                    },
                                                  },
                                                }),
                                                _c("i", {
                                                  staticClass: "el-icon-delete",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.delFileList(
                                                        "vehicleLicenseBackFileList",
                                                        "vehiclelicenseFormBack"
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "elImageModel" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      "margin-bottom": "20px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "此图片仅用于AAA保险平台使用"
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm.getFileSuffix(
                                              _vm.vehicleLicenseBackFileList[0]
                                                .fileName
                                            )
                                              ? _c("el-image", {
                                                  ref: "vehicleLicenseBackPreviewImg",
                                                  staticStyle: {
                                                    width: "100%",
                                                    cursor: "pointer",
                                                  },
                                                  attrs: {
                                                    src: _vm
                                                      .vehicleLicenseBackFileList[0]
                                                      .fileUrl,
                                                    "preview-src-list": [
                                                      _vm
                                                        .vehicleLicenseBackFileList[0]
                                                        .fileUrl,
                                                    ],
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                              ])
                            : _vm.OCRFunctionName == "ID_CARD"
                            ? _c("div", { staticClass: "flexColumnStrat" }, [
                                !_vm.IDCARDFRONTFileList ||
                                _vm.IDCARDFRONTFileList.length == 0
                                  ? _c(
                                      "div",
                                      [
                                        _c("div", { staticClass: "lh20" }, [
                                          _vm._v("身份证人像面"),
                                        ]),
                                        _c(
                                          "el-upload",
                                          {
                                            staticClass: "upload-demo",
                                            attrs: {
                                              drag: "",
                                              limit: 1,
                                              "before-remove": function () {
                                                return (_vm.formData = "")
                                              },
                                              action: "",
                                              multiple: "",
                                              "show-file-list": false,
                                              "http-request": function (val) {
                                                return _vm.HTuploadFile(
                                                  val,
                                                  "ID_CARD_FRONT",
                                                  "ID_CARD"
                                                )
                                              },
                                              "before-upload": function (val) {
                                                return _vm.HTbeforeUpload(val)
                                              },
                                              "file-list":
                                                _vm.IDCARDFRONTFileList,
                                              accept: _vm.uploadType,
                                              "list-type": "text",
                                            },
                                          },
                                          [
                                            _c("div", {
                                              staticClass:
                                                "imgMask IDCARDSFRONT",
                                            }),
                                            _c(
                                              "div",
                                              { staticClass: "uploadMask" },
                                              [
                                                _c("i", {
                                                  staticClass: "el-icon-upload",
                                                }),
                                                _c("span", [
                                                  _vm._v(
                                                    "将图片拖到此处或点击上传"
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "div",
                                      { staticClass: "identificationBox" },
                                      [
                                        _c("div", { staticClass: "lh20" }, [
                                          _vm._v("身份证人像面"),
                                        ]),
                                        _c(
                                          "div",
                                          { staticClass: "identificationImg" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "identificationPreviewImgDel",
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "el-icon-view",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.setFileListFileUrl(
                                                        "IDCARDFrontPreviewImg"
                                                      )
                                                    },
                                                  },
                                                }),
                                                _c("i", {
                                                  staticClass: "el-icon-delete",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.delFileList(
                                                        "IDCARDFRONTFileList",
                                                        "IDCARDFormFront"
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "elImageModel" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      "margin-bottom": "20px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "此图片仅用于AAA保险平台使用"
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm.getFileSuffix(
                                              _vm.IDCARDFRONTFileList[0]
                                                .fileName
                                            )
                                              ? _c("el-image", {
                                                  ref: "IDCARDFrontPreviewImg",
                                                  staticStyle: {
                                                    width: "100%",
                                                    cursor: "pointer",
                                                  },
                                                  attrs: {
                                                    src: _vm
                                                      .IDCARDFRONTFileList[0]
                                                      .fileUrl,
                                                    "preview-src-list": [
                                                      _vm.IDCARDFRONTFileList[0]
                                                        .fileUrl,
                                                    ],
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                _vm.IdentificationType &&
                                _vm.IdentificationType !== "operator"
                                  ? _c("div", [
                                      !_vm.IDCARDFBACKFileList ||
                                      _vm.IDCARDFBACKFileList.length == 0
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "margin-top": "10px",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "lh20" },
                                                [_vm._v("身份证国徽面")]
                                              ),
                                              _c(
                                                "el-upload",
                                                {
                                                  staticClass: "upload-demo",
                                                  attrs: {
                                                    drag: "",
                                                    limit: 1,
                                                    "before-remove":
                                                      function () {
                                                        return (_vm.formData =
                                                          "")
                                                      },
                                                    action: "",
                                                    multiple: "",
                                                    "show-file-list": false,
                                                    "http-request": function (
                                                      val
                                                    ) {
                                                      return _vm.HTuploadFile(
                                                        val,
                                                        "ID_CARD_BACK",
                                                        "ID_CARD"
                                                      )
                                                    },
                                                    "before-upload": function (
                                                      val
                                                    ) {
                                                      return _vm.HTbeforeUpload(
                                                        val
                                                      )
                                                    },
                                                    "file-list":
                                                      _vm.IDCARDFBACKFileList,
                                                    accept: _vm.uploadType,
                                                    "list-type": "text",
                                                  },
                                                },
                                                [
                                                  _c("div", {
                                                    staticClass:
                                                      "imgMask IDCARDSBACK",
                                                  }),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "uploadMask",
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-upload",
                                                      }),
                                                      _c("span", [
                                                        _vm._v(
                                                          "将图片拖到此处或点击上传"
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "div",
                                            {
                                              staticClass: "identificationBox",
                                              staticStyle: {
                                                "margin-top": "10px",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "lh20" },
                                                [_vm._v("身份证国徽面")]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "identificationImg",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "identificationPreviewImgDel",
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-view",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.setFileListFileUrl(
                                                              "IDCARDBackPreviewImg"
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-delete",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.delFileList(
                                                              "IDCARDFBACKFileList",
                                                              "IDCARDFormBack"
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "elImageModel",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            "margin-bottom":
                                                              "20px",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "此图片仅用于AAA保险平台使用"
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm.getFileSuffix(
                                                    _vm.IDCARDFBACKFileList[0]
                                                      .fileName
                                                  )
                                                    ? _c("el-image", {
                                                        ref: "IDCARDBackPreviewImg",
                                                        staticStyle: {
                                                          width: "100%",
                                                          cursor: "pointer",
                                                        },
                                                        attrs: {
                                                          src: _vm
                                                            .IDCARDFBACKFileList[0]
                                                            .fileUrl,
                                                          "preview-src-list": [
                                                            _vm
                                                              .IDCARDFBACKFileList[0]
                                                              .fileUrl,
                                                          ],
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                    ])
                                  : _vm._e(),
                              ])
                            : _vm.OCRFunctionName == "VEHICLE_SALES_INVOICE"
                            ? _c("div", { staticClass: "flexColumnStrat" }, [
                                !_vm.salesInvoiceFileList ||
                                _vm.salesInvoiceFileList.length == 0
                                  ? _c(
                                      "div",
                                      [
                                        _c("div", { staticClass: "lh20" }, [
                                          _vm._v("购车发票"),
                                        ]),
                                        _c(
                                          "el-upload",
                                          {
                                            staticClass: "upload-demo",
                                            attrs: {
                                              drag: "",
                                              limit: 1,
                                              "before-remove": function () {
                                                return (_vm.formData = "")
                                              },
                                              action: "",
                                              multiple: "",
                                              "show-file-list": false,
                                              "http-request": function (val) {
                                                return _vm.HTuploadFile(
                                                  val,
                                                  "VEHICLE_SALES_INVOICE",
                                                  "VEHICLE_SALES_INVOICE"
                                                )
                                              },
                                              "before-upload": function (val) {
                                                return _vm.HTbeforeUpload(val)
                                              },
                                              "file-list":
                                                _vm.salesInvoiceFileList,
                                              accept: _vm.uploadType,
                                              "list-type": "text",
                                            },
                                          },
                                          [
                                            _c("div", {
                                              staticClass:
                                                "imgMask vehiclesLicenseBK",
                                            }),
                                            _c(
                                              "div",
                                              { staticClass: "uploadMask" },
                                              [
                                                _c("i", {
                                                  staticClass: "el-icon-upload",
                                                }),
                                                _c("span", [
                                                  _vm._v(
                                                    "将图片拖到此处或点击上传"
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "div",
                                      { staticClass: "identificationBox" },
                                      [
                                        _c("div", { staticClass: "lh20" }, [
                                          _vm._v("购车发票"),
                                        ]),
                                        _c(
                                          "div",
                                          { staticClass: "identificationImg" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "identificationPreviewImgDel",
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "el-icon-view",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.setFileListFileUrl(
                                                        "salesInvoiceFilesListPreviewImg"
                                                      )
                                                    },
                                                  },
                                                }),
                                                _c("i", {
                                                  staticClass: "el-icon-delete",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.delFileList(
                                                        "salesInvoiceFileList",
                                                        "vehicleSalesInvoiceForm"
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "elImageModel" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      "margin-bottom": "20px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "此图片仅用于AAA保险平台使用"
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm.getFileSuffix(
                                              _vm.salesInvoiceFileList[0]
                                                .fileName
                                            )
                                              ? _c("el-image", {
                                                  ref: "salesInvoiceFilesListPreviewImg",
                                                  staticStyle: {
                                                    width: "100%",
                                                    cursor: "pointer",
                                                  },
                                                  attrs: {
                                                    src: _vm
                                                      .salesInvoiceFileList[0]
                                                      .fileUrl,
                                                    "preview-src-list": [
                                                      _vm
                                                        .salesInvoiceFileList[0]
                                                        .fileUrl,
                                                    ],
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                              ])
                            : _vm.OCRFunctionName == "BUS_LICENSE"
                            ? _c("div", { staticClass: "flexColumnStrat" }, [
                                !_vm.busLicenseFileList ||
                                _vm.busLicenseFileList.length == 0
                                  ? _c(
                                      "div",
                                      [
                                        _c("div", { staticClass: "lh20" }, [
                                          _vm._v("营业执照"),
                                        ]),
                                        _c(
                                          "el-upload",
                                          {
                                            staticClass: "upload-demo",
                                            attrs: {
                                              drag: "",
                                              limit: 1,
                                              "before-remove": function () {
                                                return (_vm.formData = "")
                                              },
                                              action: "",
                                              multiple: "",
                                              "show-file-list": false,
                                              "http-request": function (val) {
                                                return _vm.HTuploadFile(
                                                  val,
                                                  "BUS_LICENSE",
                                                  "BUS_LICENSE"
                                                )
                                              },
                                              "before-upload": function (val) {
                                                return _vm.HTbeforeUpload(val)
                                              },
                                              "file-list":
                                                _vm.busLicenseFileList,
                                              accept: _vm.uploadType,
                                              "list-type": "text",
                                            },
                                          },
                                          [
                                            _c("div", {
                                              staticClass:
                                                "imgMask BusLicenseBK",
                                            }),
                                            _c(
                                              "div",
                                              { staticClass: "uploadMask" },
                                              [
                                                _c("i", {
                                                  staticClass: "el-icon-upload",
                                                }),
                                                _c("span", [
                                                  _vm._v(
                                                    "将图片拖到此处或点击上传"
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "div",
                                      { staticClass: "identificationBox" },
                                      [
                                        _c("div", { staticClass: "lh20" }, [
                                          _vm._v("营业执照"),
                                        ]),
                                        _c(
                                          "div",
                                          { staticClass: "identificationImg" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "identificationPreviewImgDel",
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "el-icon-view",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.setFileListFileUrl(
                                                        "busLicenseFilesListPreviewImg"
                                                      )
                                                    },
                                                  },
                                                }),
                                                _c("i", {
                                                  staticClass: "el-icon-delete",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.delFileList(
                                                        "busLicenseFileList",
                                                        "busLicenseForm"
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "elImageModel" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      "margin-bottom": "20px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "此图片仅用于AAA保险平台使用"
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm.getFileSuffix(
                                              _vm.busLicenseFileList[0].fileName
                                            )
                                              ? _c("el-image", {
                                                  ref: "busLicenseFilesListPreviewImg",
                                                  staticStyle: {
                                                    width: "100%",
                                                    cursor: "pointer",
                                                  },
                                                  attrs: {
                                                    src: _vm
                                                      .busLicenseFileList[0]
                                                      .fileUrl,
                                                    "preview-src-list": [
                                                      _vm.busLicenseFileList[0]
                                                        .fileUrl,
                                                    ],
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                              ])
                            : _vm.OCRFunctionName ==
                              "VEHICLE_INSPECTION_CERTIFICATE"
                            ? _c("div", { staticClass: "flexColumnStrat" }, [
                                !_vm.vehicleInspectionCertificateFileList ||
                                _vm.vehicleInspectionCertificateFileList
                                  .length == 0
                                  ? _c(
                                      "div",
                                      [
                                        _c("div", { staticClass: "lh20" }, [
                                          _vm._v("车辆合格证"),
                                        ]),
                                        _c(
                                          "el-upload",
                                          {
                                            staticClass: "upload-demo",
                                            attrs: {
                                              drag: "",
                                              limit: 1,
                                              "before-remove": function () {
                                                return (_vm.formData = "")
                                              },
                                              action: "",
                                              multiple: "",
                                              "show-file-list": false,
                                              "http-request": function (val) {
                                                return _vm.HTuploadFile(
                                                  val,
                                                  "VEHICLE_INSPECTION_CERTIFICATE",
                                                  "VEHICLE_INSPECTION_CERTIFICATE"
                                                )
                                              },
                                              "before-upload": function (val) {
                                                return _vm.HTbeforeUpload(val)
                                              },
                                              "file-list":
                                                _vm.vehicleInspectionCertificateFileList,
                                              accept: _vm.uploadType,
                                              "list-type": "text",
                                            },
                                          },
                                          [
                                            _c("div", {
                                              staticClass:
                                                "imgMask vehicleInspectionCertificateBK",
                                            }),
                                            _c(
                                              "div",
                                              { staticClass: "uploadMask" },
                                              [
                                                _c("i", {
                                                  staticClass: "el-icon-upload",
                                                }),
                                                _c("span", [
                                                  _vm._v(
                                                    "将图片拖到此处或点击上传"
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "div",
                                      { staticClass: "identificationBox" },
                                      [
                                        _c("div", { staticClass: "lh20" }, [
                                          _vm._v("车辆合格证"),
                                        ]),
                                        _c(
                                          "div",
                                          { staticClass: "identificationImg" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "identificationPreviewImgDel",
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "el-icon-view",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.setFileListFileUrl(
                                                        "vehicleInspectionCertificateFileListPreviewImg"
                                                      )
                                                    },
                                                  },
                                                }),
                                                _c("i", {
                                                  staticClass: "el-icon-delete",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.delFileList(
                                                        "vehicleInspectionCertificateFileList",
                                                        "vehicleInspectionCertificateForm"
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "elImageModel" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      "margin-bottom": "20px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "此图片仅用于AAA保险平台使用"
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm.getFileSuffix(
                                              _vm
                                                .vehicleInspectionCertificateFileList[0]
                                                .fileName
                                            )
                                              ? _c("el-image", {
                                                  ref: "vehicleInspectionCertificateFileListPreviewImg",
                                                  staticStyle: {
                                                    width: "100%",
                                                    cursor: "pointer",
                                                  },
                                                  attrs: {
                                                    src: _vm
                                                      .vehicleInspectionCertificateFileList[0]
                                                      .fileUrl,
                                                    "preview-src-list": [
                                                      _vm
                                                        .vehicleInspectionCertificateFileList[0]
                                                        .fileUrl,
                                                    ],
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                              ])
                            : _c("div", { staticClass: "flexColumnStrat" }, [
                                !_vm.fileList || _vm.fileList.length == 0
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "el-upload",
                                          {
                                            staticClass: "upload-demo",
                                            attrs: {
                                              drag: "",
                                              limit: 1,
                                              "before-remove": function () {
                                                return (_vm.formData = "")
                                              },
                                              action: "",
                                              multiple: "",
                                              "show-file-list": false,
                                              "http-request": function (val) {
                                                return _vm.HTuploadFile(
                                                  val,
                                                  "other",
                                                  "OTHER"
                                                )
                                              },
                                              "before-upload": function (val) {
                                                return _vm.HTbeforeUpload(val)
                                              },
                                              "file-list": _vm.fileList,
                                              accept: _vm.uploadType,
                                              "list-type": "text",
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-upload",
                                            }),
                                            _c("span", [
                                              _vm._v(
                                                "将图片拖到此处或点击上传"
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "div",
                                      { staticClass: "identificationImg" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "identificationPreviewImgDel",
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-view",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.setFileListFileUrl(
                                                    "filesListPreviewImg"
                                                  )
                                                },
                                              },
                                            }),
                                            _c("i", {
                                              staticClass: "el-icon-delete",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.delFileList(
                                                    "fileList",
                                                    "otherForm"
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "elImageModel" },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "margin-bottom": "20px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "此图片仅用于AAA保险平台使用"
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm.getFileSuffix(_vm.fileListFileName)
                                          ? _c("el-image", {
                                              ref: "filesListPreviewImg",
                                              staticStyle: {
                                                width: "100%",
                                                cursor: "pointer",
                                              },
                                              attrs: {
                                                src: _vm.fileListFileUrl,
                                                "preview-src-list": [
                                                  _vm.fileListFileUrl,
                                                ],
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                              ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "uploadForm" },
                          [
                            _vm.OCRFunctionName == "ID_CARD"
                              ? _c(
                                  "el-form",
                                  {
                                    ref: "IDCARDForm",
                                    staticClass: "demo-ruleForm",
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      model: _vm.IDCARDForm,
                                      "label-width": "100px",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticStyle: { width: "100%" } },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "姓名" } },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                size: "mini",
                                                placeholder: "请输入姓名",
                                                clearable: "",
                                              },
                                              model: {
                                                value: _vm.IDCARDForm.name,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.IDCARDForm,
                                                    "name",
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                },
                                                expression: "IDCARDForm.name",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm.IdentificationType &&
                                        _vm.IdentificationType !== "operator"
                                          ? _c(
                                              "el-form-item",
                                              { attrs: { label: "民族" } },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    size: "mini",
                                                    placeholder: "请输入民族",
                                                    clearable: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.IDCARDForm.ethnicity,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.IDCARDForm,
                                                        "ethnicity",
                                                        typeof $$v === "string"
                                                          ? $$v.trim()
                                                          : $$v
                                                      )
                                                    },
                                                    expression:
                                                      "IDCARDForm.ethnicity",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.IdentificationType &&
                                        _vm.IdentificationType !== "operator"
                                          ? _c(
                                              "el-form-item",
                                              { attrs: { label: "性别" } },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    staticStyle: {
                                                      width: "100%",
                                                    },
                                                    attrs: {
                                                      filterable: "",
                                                      placeholder: "请选择性别",
                                                      clearable: "",
                                                    },
                                                    model: {
                                                      value: _vm.IDCARDForm.sex,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.IDCARDForm,
                                                          "sex",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "IDCARDForm.sex",
                                                    },
                                                  },
                                                  [
                                                    _c("el-option", {
                                                      attrs: {
                                                        label: "男",
                                                        value: "M",
                                                      },
                                                    }),
                                                    _c("el-option", {
                                                      attrs: {
                                                        label: "女",
                                                        value: "F",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.IdentificationType &&
                                        _vm.IdentificationType !== "operator"
                                          ? _c(
                                              "el-form-item",
                                              { attrs: { label: "出生日期" } },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    size: "mini",
                                                    placeholder:
                                                      "请输入出生日期",
                                                    clearable: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.IDCARDForm.birthDate,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.IDCARDForm,
                                                        "birthDate",
                                                        typeof $$v === "string"
                                                          ? $$v.trim()
                                                          : $$v
                                                      )
                                                    },
                                                    expression:
                                                      "IDCARDForm.birthDate",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "证件号" } },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                size: "mini",
                                                placeholder: "请输入证件号",
                                                clearable: "",
                                              },
                                              model: {
                                                value: _vm.IDCARDForm.idNumber,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.IDCARDForm,
                                                    "idNumber",
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                },
                                                expression:
                                                  "IDCARDForm.idNumber",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm.IdentificationType &&
                                        _vm.IdentificationType !== "operator"
                                          ? _c(
                                              "el-form-item",
                                              { attrs: { label: "省市区" } },
                                              [
                                                _c("el-cascader", {
                                                  ref: "addrssCascader",
                                                  staticClass:
                                                    "double-input-city",
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    options: _vm.cityList,
                                                    clearable: false,
                                                    filterable: "",
                                                  },
                                                  on: {
                                                    change: _vm.handleChange,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.IDCARDForm.regionCode,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.IDCARDForm,
                                                        "regionCode",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "IDCARDForm.regionCode",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.IdentificationType &&
                                        _vm.IdentificationType !== "operator"
                                          ? _c(
                                              "el-form-item",
                                              { attrs: { label: "地址" } },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    size: "mini",
                                                    placeholder: "请输入地址",
                                                    clearable: "",
                                                    type: "textarea",
                                                    rows: "3",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.IDCARDForm.address,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.IDCARDForm,
                                                        "address",
                                                        typeof $$v === "string"
                                                          ? $$v.trim()
                                                          : $$v
                                                      )
                                                    },
                                                    expression:
                                                      "IDCARDForm.address",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.IdentificationType &&
                                        _vm.IdentificationType !== "operator"
                                          ? _c(
                                              "el-form-item",
                                              { attrs: { label: "有效起期" } },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    size: "mini",
                                                    placeholder:
                                                      "请输入有效起期",
                                                    clearable: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.IDCARDForm
                                                        .validPeriodStart,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.IDCARDForm,
                                                        "validPeriodStart",
                                                        typeof $$v === "string"
                                                          ? $$v.trim()
                                                          : $$v
                                                      )
                                                    },
                                                    expression:
                                                      "IDCARDForm.validPeriodStart",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.IdentificationType &&
                                        _vm.IdentificationType !== "operator"
                                          ? _c(
                                              "el-form-item",
                                              { attrs: { label: "有效止期" } },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    size: "mini",
                                                    placeholder:
                                                      "请输入有效止期",
                                                    clearable: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.IDCARDForm
                                                        .validPeriodEnd,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.IDCARDForm,
                                                        "validPeriodEnd",
                                                        typeof $$v === "string"
                                                          ? $$v.trim()
                                                          : $$v
                                                      )
                                                    },
                                                    expression:
                                                      "IDCARDForm.validPeriodEnd",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              : _vm.OCRFunctionName == "VEHICLE_LICENSE"
                              ? _c(
                                  "el-form",
                                  {
                                    ref: "vehiclelicenseForm",
                                    staticClass: "demo-ruleForm",
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      model: _vm.vehiclelicenseForm,
                                      "label-width": "100px",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticStyle: { width: "100%" } },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "车牌号" } },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                size: "mini",
                                                placeholder: "请输入车牌号",
                                                clearable: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.vehiclelicenseForm
                                                    .licensePlateNumber,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.vehiclelicenseForm,
                                                    "licensePlateNumber",
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                },
                                                expression:
                                                  "vehiclelicenseForm.licensePlateNumber",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "车辆类型" } },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                size: "mini",
                                                placeholder: "请输入车辆类型",
                                                clearable: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.vehiclelicenseForm
                                                    .vehicleType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.vehiclelicenseForm,
                                                    "vehicleType",
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                },
                                                expression:
                                                  "vehiclelicenseForm.vehicleType",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "使用性质" } },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  filterable: "",
                                                  placeholder: "请选择使用性质",
                                                  clearable: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.vehiclelicenseForm
                                                      .useNature,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.vehiclelicenseForm,
                                                      "useNature",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "vehiclelicenseForm.useNature",
                                                },
                                              },
                                              _vm._l(
                                                _vm.USE_ATTRIBUTE,
                                                function (item, index) {
                                                  return _c("el-option", {
                                                    key: index,
                                                    attrs: {
                                                      label: item.dictName,
                                                      value: item.dictCode,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "品牌型号" } },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                size: "mini",
                                                placeholder: "请输入品牌型号",
                                                clearable: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.vehiclelicenseForm.model,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.vehiclelicenseForm,
                                                    "model",
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                },
                                                expression:
                                                  "vehiclelicenseForm.model",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "车架号" } },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                size: "mini",
                                                placeholder: "请输入车架号",
                                                clearable: "",
                                                maxlength: "17",
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.checkValue(
                                                    "vehiclelicenseForm",
                                                    $event
                                                  )
                                                },
                                                blur: function ($event) {
                                                  return _vm.vincheck(
                                                    "vehiclelicenseForm"
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.vehiclelicenseForm
                                                    .vinCode,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.vehiclelicenseForm,
                                                    "vinCode",
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                },
                                                expression:
                                                  "vehiclelicenseForm.vinCode",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "发动机号" } },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                size: "mini",
                                                placeholder: "请输入发动机号",
                                                clearable: "",
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.inputEngineNo(
                                                    "vehiclelicenseForm",
                                                    $event
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.vehiclelicenseForm
                                                    .engineNumber,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.vehiclelicenseForm,
                                                    "engineNumber",
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                },
                                                expression:
                                                  "vehiclelicenseForm.engineNumber",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "注册日期" } },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                size: "mini",
                                                placeholder: "请输入注册日期",
                                                clearable: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.vehiclelicenseForm
                                                    .registrationDate,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.vehiclelicenseForm,
                                                    "registrationDate",
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                },
                                                expression:
                                                  "vehiclelicenseForm.registrationDate",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "发证日期" } },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                size: "mini",
                                                placeholder: "请输入发证日期",
                                                clearable: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.vehiclelicenseForm
                                                    .issueDate,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.vehiclelicenseForm,
                                                    "issueDate",
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                },
                                                expression:
                                                  "vehiclelicenseForm.issueDate",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "座位数" } },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                size: "mini",
                                                placeholder: "请输入座位数",
                                                clearable: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.vehiclelicenseForm
                                                    .approvedPassengers,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.vehiclelicenseForm,
                                                    "approvedPassengers",
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                },
                                                expression:
                                                  "vehiclelicenseForm.approvedPassengers",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "整备质量" } },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                size: "mini",
                                                placeholder: "请输入整备质量",
                                                clearable: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.vehiclelicenseForm
                                                    .unladenMass,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.vehiclelicenseForm,
                                                    "unladenMass",
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                },
                                                expression:
                                                  "vehiclelicenseForm.unladenMass",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              : _vm.OCRFunctionName ==
                                "VEHICLE_INSPECTION_CERTIFICATE"
                              ? _c(
                                  "el-form",
                                  {
                                    ref: "vehicleInspectionCertificateForm",
                                    staticClass: "demo-ruleForm",
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      model:
                                        _vm.vehicleInspectionCertificateForm,
                                      "label-width": "120px",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticStyle: { width: "100%" } },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "车架号" } },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                size: "mini",
                                                placeholder: "请输入车架号",
                                                clearable: "",
                                                maxlength: "17",
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.checkValue(
                                                    "vehicleInspectionCertificateForm",
                                                    $event
                                                  )
                                                },
                                                blur: function ($event) {
                                                  return _vm.vincheck(
                                                    "vehicleInspectionCertificateForm"
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm
                                                    .vehicleInspectionCertificateForm
                                                    .vinCode,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.vehicleInspectionCertificateForm,
                                                    "vinCode",
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                },
                                                expression:
                                                  "vehicleInspectionCertificateForm.vinCode",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "发动机号" } },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                size: "mini",
                                                placeholder: "请输入发动机号",
                                                clearable: "",
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.inputEngineNo(
                                                    "vehicleInspectionCertificateForm",
                                                    $event
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm
                                                    .vehicleInspectionCertificateForm
                                                    .engineNumber,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.vehicleInspectionCertificateForm,
                                                    "engineNumber",
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                },
                                                expression:
                                                  "vehicleInspectionCertificateForm.engineNumber",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "品牌型号" } },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                size: "mini",
                                                placeholder: "请输入品牌型号",
                                                clearable: "",
                                              },
                                              model: {
                                                value:
                                                  _vm
                                                    .vehicleInspectionCertificateForm
                                                    .vehicleModel,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.vehicleInspectionCertificateForm,
                                                    "vehicleModel",
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                },
                                                expression:
                                                  "vehicleInspectionCertificateForm.vehicleModel",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "能源类型" } },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                size: "mini",
                                                placeholder: "请输入能源类型",
                                                clearable: "",
                                              },
                                              model: {
                                                value:
                                                  _vm
                                                    .vehicleInspectionCertificateForm
                                                    .fuelType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.vehicleInspectionCertificateForm,
                                                    "fuelType",
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                },
                                                expression:
                                                  "vehicleInspectionCertificateForm.fuelType",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "排量(ml)" } },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                size: "mini",
                                                placeholder: "请输入排量",
                                                clearable: "",
                                              },
                                              model: {
                                                value:
                                                  _vm
                                                    .vehicleInspectionCertificateForm
                                                    .displacement,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.vehicleInspectionCertificateForm,
                                                    "displacement",
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                },
                                                expression:
                                                  "vehicleInspectionCertificateForm.displacement",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "功率(KW)" } },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                size: "mini",
                                                placeholder: "请输入功率",
                                                clearable: "",
                                              },
                                              model: {
                                                value:
                                                  _vm
                                                    .vehicleInspectionCertificateForm
                                                    .power,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.vehicleInspectionCertificateForm,
                                                    "power",
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                },
                                                expression:
                                                  "vehicleInspectionCertificateForm.power",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "整备质量(kg)" } },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                size: "mini",
                                                placeholder: "请输入整备质量",
                                                clearable: "",
                                              },
                                              model: {
                                                value:
                                                  _vm
                                                    .vehicleInspectionCertificateForm
                                                    .equipmentWeight,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.vehicleInspectionCertificateForm,
                                                    "equipmentWeight",
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                },
                                                expression:
                                                  "vehicleInspectionCertificateForm.equipmentWeight",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "座位数" } },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                size: "mini",
                                                placeholder: "请输入座位数",
                                                clearable: "",
                                              },
                                              model: {
                                                value:
                                                  _vm
                                                    .vehicleInspectionCertificateForm
                                                    .passengerCapacity,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.vehicleInspectionCertificateForm,
                                                    "passengerCapacity",
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                },
                                                expression:
                                                  "vehicleInspectionCertificateForm.passengerCapacity",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              : _vm.OCRFunctionName == "VEHICLE_SALES_INVOICE"
                              ? _c(
                                  "el-form",
                                  {
                                    ref: "ruleForm",
                                    staticClass: "demo-ruleForm",
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      model: _vm.vehicleSalesInvoiceForm,
                                      "label-width": "100px",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticStyle: { width: "100%" } },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "品牌型号" } },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                size: "mini",
                                                placeholder: "请输入品牌型号",
                                                clearable: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.vehicleSalesInvoiceForm
                                                    .brandMode,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.vehicleSalesInvoiceForm,
                                                    "brandMode",
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                },
                                                expression:
                                                  "vehicleSalesInvoiceForm.brandMode",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "车架号" } },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                size: "mini",
                                                placeholder: "请输入车架号",
                                                clearable: "",
                                                maxlength: "17",
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.checkValue(
                                                    "vehicleSalesInvoiceForm",
                                                    $event
                                                  )
                                                },
                                                blur: function ($event) {
                                                  return _vm.vincheck(
                                                    "vehicleSalesInvoiceForm"
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.vehicleSalesInvoiceForm
                                                    .vinCode,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.vehicleSalesInvoiceForm,
                                                    "vinCode",
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                },
                                                expression:
                                                  "vehicleSalesInvoiceForm.vinCode",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "发动机号" } },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                size: "mini",
                                                placeholder: "请输入发动机号",
                                                clearable: "",
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.inputEngineNo(
                                                    "vehicleSalesInvoiceForm",
                                                    $event
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.vehicleSalesInvoiceForm
                                                    .engineNumber,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.vehicleSalesInvoiceForm,
                                                    "engineNumber",
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                },
                                                expression:
                                                  "vehicleSalesInvoiceForm.engineNumber",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "购买方" } },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                size: "mini",
                                                placeholder: "请输入购买方",
                                                clearable: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.vehicleSalesInvoiceForm
                                                    .purchaserName,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.vehicleSalesInvoiceForm,
                                                    "purchaserName",
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                },
                                                expression:
                                                  "vehicleSalesInvoiceForm.purchaserName",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "纳税人证件号" } },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                size: "mini",
                                                placeholder:
                                                  "请输入纳税人证件号",
                                                clearable: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.vehicleSalesInvoiceForm
                                                    .purchaserTaxNumber,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.vehicleSalesInvoiceForm,
                                                    "purchaserTaxNumber",
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                },
                                                expression:
                                                  "vehicleSalesInvoiceForm.purchaserTaxNumber",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "车辆类型" } },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                size: "mini",
                                                placeholder: "请输入车辆类型",
                                                clearable: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.vehicleSalesInvoiceForm
                                                    .vehicleType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.vehicleSalesInvoiceForm,
                                                    "vehicleType",
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                },
                                                expression:
                                                  "vehicleSalesInvoiceForm.vehicleType",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              : _vm.OCRFunctionName == "BUS_LICENSE"
                              ? _c(
                                  "el-form",
                                  {
                                    ref: "busLicenseForm",
                                    staticClass: "demo-ruleForm",
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      model: _vm.busLicenseForm,
                                      "label-width": "120px",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticStyle: { width: "100%" } },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "名称" } },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                size: "mini",
                                                placeholder: "请输入名称",
                                                clearable: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.busLicenseForm
                                                    .companyName,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.busLicenseForm,
                                                    "companyName",
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                },
                                                expression:
                                                  "busLicenseForm.companyName",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "统一社会信用代码",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                size: "mini",
                                                placeholder:
                                                  "请输入统一社会信用代码",
                                                clearable: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.busLicenseForm.creditCode,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.busLicenseForm,
                                                    "creditCode",
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                },
                                                expression:
                                                  "busLicenseForm.creditCode",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "地址" } },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                size: "mini",
                                                placeholder: "请输入地址",
                                                clearable: "",
                                                type: "textarea",
                                                rows: "3",
                                              },
                                              model: {
                                                value:
                                                  _vm.busLicenseForm
                                                    .businessAddress,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.busLicenseForm,
                                                    "businessAddress",
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                },
                                                expression:
                                                  "busLicenseForm.businessAddress",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              : _c(
                                  "el-form",
                                  {
                                    ref: "otherForm",
                                    staticClass: "demo-ruleForm",
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      model: _vm.otherForm,
                                      "label-width": "120px",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticStyle: { width: "100%" } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "车牌号",
                                              prop: "name",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: { size: "mini" },
                                              model: {
                                                value: _vm.otherForm.name,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.otherForm,
                                                    "name",
                                                    $$v
                                                  )
                                                },
                                                expression: "otherForm.name",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "车架号",
                                              prop: "name",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: { size: "mini" },
                                              model: {
                                                value: _vm.otherForm.name,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.otherForm,
                                                    "name",
                                                    $$v
                                                  )
                                                },
                                                expression: "otherForm.name",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              )
            : _c("el-card", { attrs: { shadow: "never" } }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      width: "100%",
                      "text-align": "center",
                      padding: "30px",
                      "box-sizing": "border-box",
                    },
                  },
                  [
                    _vm._v(
                      " 温馨提示：当前门店未开通证件识别功能，请联系一店一群小助手解决"
                    ),
                  ]
                ),
              ]),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "flex-direction": "column",
            "justify-content": "center",
            "align-items": "center",
            "margin-top": "5px",
          },
        },
        [
          !_vm._.isEmpty(_vm.ORGOCRFunctionLists)
            ? _c("div", { staticClass: "comfirmMsg" }, [
                _vm._v(
                  " 请确认信息是否正确，如果发现有误请及时修改再点击自动填充！"
                ),
              ])
            : _vm._e(),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "flex-direction": "row",
                "justify-content": "center",
                "align-items": "center",
                "line-height": "46px",
                height: "46px",
              },
            },
            [
              !_vm._.isEmpty(_vm.ORGOCRFunctionLists)
                ? _c(
                    "el-button",
                    {
                      staticStyle: { width: "80px" },
                      attrs: { size: "mini", type: "primary", plain: "" },
                      on: {
                        click: function ($event) {
                          return _vm.AutoFill()
                        },
                      },
                    },
                    [_vm._v("自动填充")]
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  staticStyle: { width: "80px" },
                  attrs: { sizi: "mini" },
                  on: { click: _vm.closeIdentificationDialog },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }