//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import OemSelect from '@/components/OemSelect';
import callApi from '@/api/callCenter';
import { getUserOrgInfoDealerOem, commonExport } from '@/utils/common';
export default {
  name: 'CallReport',
  components: {
    OemSelect: OemSelect
  },
  mixins: [initHeight],
  data: function data() {
    return {
      searchForm: {
        category: "SEATS"
      },
      tableData: [],
      loading: false,
      bizType: localStorage.getItem("bizType") || '',
      orgForm: {
        salesOrgCode: '',
        salesOrgName: '',
        salesOrgType: ''
      },
      startEndTime: []
    };
  },
  created: function created() {
    this.loadData();
    if (this.bizType === "DEALER") {
      this.initData();
    }
    ;
  },
  methods: {
    // 初始化数据
    initData: function initData() {
      var orgObj = getUserOrgInfoDealerOem(this.bizType);
      this.searchForm.orgCode = orgObj.salesOrgCode;
      this.searchForm.orgName = orgObj.salesOrgName;
    },
    handleNodeClick: function handleNodeClick(data, node, target) {
      // 机构树点击事件
      this.searchForm.orgCode = data.salesOrgCode;
      this.searchForm.orgName = data.salesOrgName;
      this.searchForm.areaType = data.salesOrgType;
    },
    handleTabClick: function handleTabClick(tab, event) {
      //tab切换事件
      this.searchData();
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {
        orgName: this.bizType === 'OEM' ? '' : this.searchForm.orgName,
        orgCode: this.bizType === 'OEM' ? '' : this.searchForm.orgCode,
        category: this.searchForm.category
      };
      this.orgForm = {
        salesOrgName: this.bizType === 'OEM' ? '全部' : '',
        salesOrgType: '',
        salesOrgCode: ''
      };
      this.startEndTime = [];
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    loadData: function loadData() {
      var _this = this;
      //获取列表数据
      if (this.searchForm && this.searchForm.endDate) {
        this.searchForm.endDate = this.searchForm.endDate.split(' ')[0] + ' 23:59:59';
      }
      this.loading = true;
      callApi.getStatisticReport(this.searchForm).then(function (res) {
        _this.loading = false;
        if (res.code === 200) {
          _this.tableData = res.data;
        }
      }).catch(function (err) {
        _this.loading = false;
      });
    },
    // 刷新
    breakData: function breakData() {
      // this.resetSearch();
      this.loadData();
    },
    dateChange: function dateChange(start, end, val) {
      //日期更改监听事件
      if (val && val.length > 1) {
        this.searchForm[start] = val[0];
        this.searchForm[end] = val[1];
      } else {
        this.searchForm[start] = null;
        this.searchForm[end] = null;
      }
      ;
    },
    exportExcels: function exportExcels() {
      //导出
      var exporParams = {
        total: this.tableData.length,
        fileName: '话务报表',
        exportUrl: '/oc-core-service/callCenter/record/statisticReport',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    }
  }
};