//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import dictRequest from '@/api/dictionary';
import repairApi from '@/api/repair';
export default {
  // mixins: [dict],
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    trackOrderId: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      form: {
        status: '',
        content: '',
        reminded: false,
        remindTime: ''
      },
      rules: {
        status: [{
          required: true,
          message: '请选择跟进状态',
          trigger: 'change'
        }],
        noReturnStatus: [{
          required: true,
          message: '请选择不回厂原因',
          trigger: 'change'
        }]
      },
      isWarn: false,
      warnTime: "",
      followList: [],
      NoReturnFactoryStatuslist: []
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        console.log(this.trackOrderId);
        this.$refs['detail'].resetFields();
        this.initData();
        this.getTrackStatus();
        this.getNoReturnFactoryStatus();
      }
    }
  },
  mounted: function mounted() {
    this.$refs['detail'].resetFields();
    this.initData();
    this.getTrackStatus();
    this.getNoReturnFactoryStatus();
  },
  methods: {
    getTrackStatus: function getTrackStatus() {
      var _this = this;
      //获取结算状态
      dictRequest.getDictItemsByCode("TRACK_STATUS").then(function (res) {
        if (res.data) {
          _this.followList = res.data;
        }
      });
    },
    getNoReturnFactoryStatus: function getNoReturnFactoryStatus() {
      var _this2 = this;
      //获取结算状态
      dictRequest.getDictItemsByCode("NORETURNFACTORY_STATUS").then(function (res) {
        if (res.data) {
          _this2.NoReturnFactoryStatuslist = res.data;
        }
      });
    },
    initData: function initData() {
      //初始化数据
      this.form = {
        status: '',
        content: '',
        reminded: false,
        remindTime: ''
      };
      this.$refs['detail'].resetFields();
    },
    save: function save() {
      var _this3 = this;
      //保存数据
      // this.closePage();
      this.$refs['detail'].validate(function (valid) {
        if (valid) {
          console.log(_this3.form);
          // return
          repairApi.trackOrder(_this3.trackOrderId, _this3.form).then(function (res) {
            console.log(res);
            if (res.code == 200) {
              _this3.$message({
                message: res.msg,
                type: 'success'
              });
              _this3.closePage();
            }
          });
        } else {
          return false;
        }
      });
    },
    closePage: function closePage() {
      //关闭弹窗
      this.$refs['detail'].resetFields();
      this.$emit('closePage');
    }
  }
};