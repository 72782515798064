import "core-js/modules/es.array.concat.js";
/**
 * 保单管理接口
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 列表
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   * @param {*} data 内容
   */
  getList: function getList(pageNum, pageSize, data) {
    return request.get(getUrl("/policy/search?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 1), {
      params: data
    });
  },
  /**
   * 详情
   * @param {*} id 查询参数
   */
  getDetail: function getDetail(id) {
    return request.get(getUrl("/policy/get?policyId=".concat(id), 1));
  },
  /**
   * 通过保单号获取详情
   * @param {*} code 
   * @returns 
   */
  getDetailByCode: function getDetailByCode(code) {
    return request.get(getUrl("/policy/get?policyCode=".concat(code), 1));
  },
  /**
   * 获取PDF电子保单详情
   * @param {*} id 查询参数
   */
  getPDFInfo: function getPDFInfo(id) {
    return request.get(getUrl("/policy/getPDFInfo?policyId=".concat(id), 1));
  },
  /**
   * 通过policyNo电子保单详情
   * @param {*} id 查询参数
   */
  policyCodeGetPDFInfo: function policyCodeGetPDFInfo(id) {
    return request.get(getUrl("/policy/getPDFInfo?policyNo=".concat(id), 1));
  },
  /**
   * 通过影像资料
   * @param {*} policyId 查询参数
   */
  queryPolicyDocument: function queryPolicyDocument(policyId) {
    return request.get(getUrl("/policy/queryPolicyDocument?policyId=".concat(policyId), 1));
  },
  /**
   * 删除保单
   * @param {*} data 查询参数
   */
  deletePolicy: function deletePolicy(ids) {
    return request.post(getUrl("/policy/delete", 1), ids);
  },
  /**
   * 导出保单
   * @param {*} data 查询参数
   */
  exportExcel: function exportExcel(data) {
    return request({
      method: 'post',
      url: getUrl("/policy/export/excels", 1),
      data: data,
      responseType: 'blob'
    });
  },
  /**
   * 下载excel模板
   */
  download: function download() {
    return request({
      method: 'get',
      url: getUrl("/policy/formwork", 1),
      responseType: 'blob'
    });
  },
  /**
   * 导入模板
   *  @param {*} data 内容
   */
  importM: function importM(data) {
    return request({
      method: 'post',
      url: getUrl("/policy/policy/importPolicy", 1),
      data: data
    });
  },
  /**
   * 历史保单管理列表
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   * @param {*} data 内容
   */
  getHistoryPolicyList: function getHistoryPolicyList(pageNum, pageSize, data) {
    return request.get(getUrl("/fir_insurance/search?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 11), {
      params: data
    });
  },
  /**
   * 历史保单管理详情
   * @param {*} id
   */
  getHistoryPolicyDetail: function getHistoryPolicyDetail(id) {
    return request.get(getUrl("/fir_insurance/".concat(id), 11));
  },
  /**
   * 修改销售日期
   * @param {*} data
   */
  updateSalesDate: function updateSalesDate(data) {
    return request.post(getUrl("/policy/update/salesDate", 1), data);
  },
  // *************************************保单管理—保单导入接口*********************************
  /**
   * 分页查询保单导入任务清单
   * @param {*} data 内容
   */
  getPolicyImportList: function getPolicyImportList(data) {
    return request.get(getUrl("/policyImport/search", 1), {
      params: data
    });
  },
  /**
   * 分页查询保单导入明细
   * @param {*} data 内容
   */
  getPolicyImportItemList: function getPolicyImportItemList(data) {
    return request.get(getUrl("/policyImport/searchItem", 1), {
      params: data
    });
  },
  /**
   * 客户导入--任务导入操作
   * @param {*} importId
   * @param {*} operate
   */
  policyImportOperate: function policyImportOperate(importId, operate) {
    return request.post(getUrl("/policyImport/operate?importId=".concat(importId, "&operate=").concat(operate), 1));
  },
  /**
   * 保单导入-上传保单导入清单1
   * @param {*} data
   */
  policyImportUpload: function policyImportUpload(data) {
    return request.post(getUrl("/policyImport/upload", 1), data);
  },
  /**
   * 保单导入-执行导入2
   * @param {*} importId
   */
  policyImportExec: function policyImportExec(importId) {
    return request.post(getUrl("/policyImport/exec?importId=".concat(importId), 1));
  },
  /**
   * 批量导入列表
   * @param {*} importId
   */
  policyAnalysis: function policyAnalysis(data) {
    return request.get(getUrl("/policyAnalysis/search", 1), {
      params: data
    });
  },
  /**
   * 批量导入----批量识别保单pdf
   * @param {*} importId
   */
  policyAnalysisBatch: function policyAnalysisBatch(data) {
    return request.post(getUrl("/policyAnalysis/batch", 1), data);
  },
  /**
   * 我的保单列表
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   * @param {*} data 内容
   */
  getMyPolicyList: function getMyPolicyList(pageNum, pageSize, data) {
    return request.get(getUrl("/policy/search/myPolicy?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 1), {
      params: data
    });
  }
};
export default objApi;