import "core-js/modules/es.json.stringify.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '../../mixins/initHeight';
import dict from '../../mixins/dict';
import Pagination from '@/components/Pagination';
import BankDialog from './dialog/bankDialog';
import insCorpRequest from '../../api/insCorpMaintain';
export default {
  name: 'banksManage',
  components: {
    Pagination: Pagination,
    BankDialog: BankDialog
  },
  mixins: [initHeight, dict],
  computed: {},
  data: function data() {
    return {
      drawer: false,
      searchForm: {},
      form: {},
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      defaultProps: {
        children: 'children',
        label: 'fullName'
      },
      tableData: [],
      loading: false,
      highlightCurrent: true,
      showDialog: false,
      opType: "",
      insCorpItem: {}
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.loadData();
  },
  activated: function activated() {
    var _this = this;
    this.$nextTick(function () {
      _this.$refs.multipleTable.doLayout();
    });
  },
  methods: {
    breakData: function breakData() {
      // this.resetSearch()
      this.loadData();
    },
    deleteInsCorp: function deleteInsCorp(item) {
      var _this2 = this;
      //删除保司
      this.$confirm('您确定要将该银行删除？', {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        insCorpRequest.delete(item.supplierId).then(function (res) {
          if (res.code === 200) {
            _this2.$message({
              message: res.msg,
              type: 'success'
            });
            _this2.loadData();
          }
        });
      });
    },
    editConfig: function editConfig(type, item) {
      //编辑
      this.opType = type;
      this.showDialog = true;
      this.insCorpItem = item;
    },
    addInscorp: function addInscorp(type) {
      //新增
      this.opType = type;
      this.showDialog = true;
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {};
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    loadData: function loadData() {
      var _this3 = this;
      //获取列表数据
      this.loading = true;
      this.drawer = false;
      this.showDialog = false;
      insCorpRequest.getList(this.listQuery.pageNum, this.listQuery.pageSize, this.searchForm).then(function (res) {
        if (res.code === 200) {
          _this3.tableData = res.data.list;
          _this3.loading = false;
          _this3.total = res.data.page.recordsTotal;
        }
      }).catch(function (err) {
        _this3.loading = false;
      });
      insCorpRequest.getAllSuppliers('INSURANCE').then(function (res) {
        if (res && !_.isEmpty(res.data)) {
          localStorage.removeItem('VI_PROP_SUPPLYER');
          localStorage.setItem('VI_PROP_SUPPLYER', JSON.stringify(res.data));
        }
      });
    }
  }
};