/**
 * 组织机构管理
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 
   * 获取token
   */
  getToken: function getToken(data) {
    return request.post(getUrl("/token", 6), data);
  },
  /**
   * 
   * 刷新Token
   */
  refreshToken: function refreshToken() {
    return request.post(getUrl("/token/refresh", 6));
  },
  /**
   * 
   * 获取组织机构类型
   */
  getOrganizationType: function getOrganizationType(hostname) {
    return request.get(getUrl("/org/manage/get/type?domainName=".concat(hostname), 1));
  },
  /**
   * 
   * 账号申请
   * @param {*} data 内容
   */
  accountApplication: function accountApplication(data) {
    return request.post(getUrl("/apply/account", 11), data);
  },
  /**
   * 
   * 获取收信信息
   * @param {*} data 内容
   */
  getReceivingNoticeData: function getReceivingNoticeData() {
    return request.post(getUrl("/message/v2/recivingMessageAndReturn", 8));
  },
  /**
   * 
   * 获取验证码图片
   */
  getValidateImg: function getValidateImg() {
    return request.post(getUrl("/captcha/getImage", 6));
  },
  /**
   * 
   * 匿名访问
   */
  loginVest: function loginVest(account) {
    return request.post(getUrl("/token/loginVest?account=".concat(account), 6));
  }
};
export default objApi;