//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from "../../mixins/initHeight";
import sysApi from '@/api/sysSetting';
import { limitInput } from '@/utils/common';
export default {
  name: "SysSetting",
  components: {},
  mixins: [initHeight],
  data: function data() {
    return {
      form: {},
      formRules: {
        miniMumLength: {
          required: true,
          message: '请输入密码最小长度',
          trigger: 'blur'
        },
        validityPeriod: {
          required: true,
          message: '请输入密码有效期（天）',
          trigger: 'blur'
        },
        loginFailedTimes: {
          required: true,
          message: '请输入登录失败次数',
          trigger: 'blur'
        },
        lockMinutes: {
          required: true,
          message: '请输入锁定分钟数',
          trigger: 'blur'
        },
        tokenValidity: {
          required: true,
          message: '请输入令牌有效期（秒）',
          trigger: 'blur'
        }
      },
      flagOption: [{
        value: true,
        label: '是'
      }, {
        value: false,
        label: '否'
      }],
      loading: false
    };
  },
  created: function created() {
    this.loadData();
  },
  mounted: function mounted() {},
  methods: {
    // 获取列表数据
    loadData: function loadData() {
      var _this = this;
      this.loading = true;
      sysApi.getPwdConfig().then(function (res) {
        _this.loading = false;
        _this.form = res.data;
      }).catch(function (err) {
        _this.loading = false;
      });
    },
    onInput: function onInput(num, min, max, limit) {
      //对输入内容做精度校验
      if (min && num < min) {
        num = min;
      }
      ;
      if (max && num > max) {
        num = max;
      }
      ;
      return limitInput(num, limit);
    },
    // 保存
    save: function save() {
      var _this2 = this;
      this.$refs['form'].validate(function (valid, obj) {
        if (valid) {
          _this2.loading = true;
          sysApi.savePwdConfig(_this2.form).then(function (res) {
            _this2.loading = false;
            if (res.code === 200) {
              _this2.$message.success(res.msg);
            }
            ;
          }).catch(function (err) {
            _this2.loading = false;
          });
        }
        ;
      });
    }
  }
};