import "core-js/modules/es.object.keys.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import messageApi from '@/api/messagePlatform';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    currentId: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      form: {},
      formLoading: false
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
      ;
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    // 初始化数据
    initData: function initData() {
      var _this = this;
      this.form = {};
      this.formLoading = true;
      messageApi.getMessageInfo(this.currentId).then(function (res) {
        _this.formLoading = false;
        if (res.code === 200) {
          _this.form = res.data;
        }
        ;
      }).catch(function (err) {
        _this.loading = false;
      });
    },
    // 关闭弹窗
    closePage: function closePage() {
      var boolean = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      this.$emit('closePage', boolean);
    },
    // 格式化文本
    parseData: function parseData(data) {
      var jsonData = null;
      try {
        jsonData = JSON.parse(data);
      } catch (error) {
        jsonData = data;
      }
      return jsonData;
    }
  }
};