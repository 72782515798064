import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import slipRequest from "../../api/insuranceslip";
import dict from "../../mixins/dict";
export default {
  name: "Identity",
  props: {
    identityData: {
      type: Array,
      required: true
    },
    proposalId: {
      type: String,
      defaults: ''
    },
    showDialog: {
      type: Boolean,
      defaults: false
    }
  },
  mixins: [dict],
  data: function data() {
    return {
      formRules: {
        cust_holder: {
          nation: [{
            required: true,
            message: "请选择民族",
            trigger: "change"
          }],
          issuingAgency: [{
            required: true,
            message: "请输入签发机构",
            trigger: "blur"
          }],
          certStartDate: [{
            required: true,
            message: "请选择证件有效起期",
            trigger: "change"
          }],
          certEndDate: [{
            required: true,
            message: "请选择证件有效止期",
            trigger: "change"
          }]
        },
        cust_insured: {
          nation: [{
            required: true,
            message: "请选择民族",
            trigger: "change"
          }],
          issuingAgency: [{
            required: true,
            message: "请输入签发机构",
            trigger: "blur"
          }],
          certStartDate: [{
            required: true,
            message: "请选择证件有效起期",
            trigger: "change"
          }],
          certEndDate: [{
            required: true,
            message: "请选择证件有效止期",
            trigger: "change"
          }]
        },
        issueCode: [{
          required: true,
          message: "请输入验证码",
          trigger: "blur"
        }]
      },
      identityStatus: 'initIdentity',
      VI_NATIONAL_SOURCE: [],
      formData: {
        cust_holder: {
          certStartDate: '',
          certEndDate: ''
        },
        cust_insured: {
          certStartDate: '',
          certEndDate: ''
        },
        issueCode: ''
      },
      isClick: false,
      loading: false,
      disabledInsureLessThan: this.processInsureMinDate(),
      disabledHolderLessThan: this.processHolderMinDate(),
      disabledInsureMoreThan: this.processInsureMaxDate(),
      disabledHolderMoreThan: this.processHolderMaxDate(),
      holderChecked: false,
      insureChecked: false,
      insCorpCode: '',
      insCityCode: ''
    };
  },
  computed: {},
  watch: {
    showDialog: function showDialog(newValue) {
      if (newValue) {
        this.getCustHolder();
        this.returnKey();
      }
    },
    formData: {
      handler: function handler(newValue, oldValue) {
        sessionStorage.setItem('bj_identity_' + this.proposalId, JSON.stringify(newValue));
      },
      deep: true
    }
  },
  created: function created() {
    this.VI_NATIONAL_SOURCE = this.dictSource["NATION_ITEM"] || [];
    this.getCustHolder();
  },
  mounted: function mounted() {},
  methods: {
    insureChange: function insureChange(val) {
      //被保人证件有效期长期按钮勾选事件
      if (val) {
        this.formData.cust_insured.certEndDate = '9999-12-31 23:59:59';
      } else {
        this.formData.cust_insured.certEndDate = '';
      }
    },
    holderChange: function holderChange(val) {
      //投保人证件有效期长期按钮勾选事件
      if (val) {
        this.formData.cust_holder.certEndDate = '9999-12-31 23:59:59';
      } else {
        this.formData.cust_holder.certEndDate = '';
      }
    },
    processInsureMinDate: function processInsureMinDate() {
      //被保人证件有效期起期
      var self = this;
      return {
        disabledDate: function disabledDate(time) {
          if (self.formData.cust_insured && self.formData.cust_insured.certStartDate) {
            return time.getTime() < new Date(self.formData.cust_insured.certStartDate).getTime();
          }
          return false;
        }
      };
    },
    processHolderMinDate: function processHolderMinDate() {
      //投保人证件有效期起期
      var self = this;
      return {
        disabledDate: function disabledDate(time) {
          if (self.formData.cust_holder && self.formData.cust_holder.certStartDate) {
            return time.getTime() < new Date(self.formData.cust_holder.certStartDate).getTime();
          }
          return false;
        }
      };
    },
    processInsureMaxDate: function processInsureMaxDate() {
      //被保人证件有效期起期
      var self = this;
      return {
        disabledDate: function disabledDate(time) {
          if (self.formData.cust_insured && self.formData.cust_insured.certEndDate) {
            return time.getTime() > new Date(self.formData.cust_insured.certEndDate).getTime();
          }
          return false;
        }
      };
    },
    processHolderMaxDate: function processHolderMaxDate() {
      //投保人证件有效期起期
      var self = this;
      return {
        disabledDate: function disabledDate(time) {
          if (self.formData.cust_holder && self.formData.cust_holder.certEndDate) {
            return time.getTime() > new Date(self.formData.cust_holder.certEndDate).getTime();
          }
          return false;
        }
      };
    },
    getCustHolder: function getCustHolder() {
      //初始化表单数据
      if (this.$refs["identity"]) {
        this.$refs["identity"].resetFields();
        this.formData = {
          cust_holder: {
            certStartDate: '',
            certEndDate: ''
          },
          cust_insured: {
            certStartDate: '',
            certEndDate: ''
          },
          issueCode: ''
        };
      }
      this.holderChecked = false;
      this.insureChecked = false;
      if (sessionStorage.getItem('bj_identity_' + this.proposalId)) {
        this.formData = JSON.parse(sessionStorage.getItem('bj_identity_' + this.proposalId));
      } else {
        this.formData.cust_holder = _.find(this.identityData, function (item) {
          return _.toLower(item.relationshipFlag) == 'applicant';
        });
        this.formData.cust_insured = _.find(this.identityData, function (item) {
          return _.toLower(item.relationshipFlag) == 'insurant';
        });
        //签发机构和民族默认不赋值；
        this.formData.cust_holder.nation = null;
        this.formData.cust_holder.issuingAgency = null;
        this.formData.cust_insured.nation = null;
        this.formData.cust_insured.issuingAgency = null;
      }
      //止期为：9999-12-31 23:59:59时默认勾选上长期
      if (this.formData.cust_holder.certEndDate && this.formData.cust_holder.certEndDate.indexOf('9999-12-31 23:59:59') != -1) {
        this.holderChecked = true;
      }
      //止期为：9999-12-31 23:59:59时默认勾选上长期
      if (this.formData.cust_insured.certEndDate && this.formData.cust_insured.certEndDate.indexOf('9999-12-31 23:59:59') != -1) {
        this.insureChecked = true;
      }
      this.insCorpCode = this.identityData[0].insCorpCode;
      this.insCityCode = this.identityData[0].insCityCode;
    },
    returnKey: function returnKey() {
      // 返回
      this.identityStatus = 'initIdentity';
    },
    confirmIdentityVerifyInfo: function confirmIdentityVerifyInfo() {
      var _this = this;
      // 表单提交
      this.isClick = true;
      this.$refs["identity"].validate(function (valid) {
        if (valid) {
          var postData = {
            issueCode: _this.formData.issueCode,
            proposalId: _this.proposalId
          };
          _this.loading = true;
          slipRequest.customerCecycle(postData).then(function (res) {
            _this.isClick = false;
            _this.loading = false;
            if (res.code == RESPONSE_SUCCESS) {
              if (res.data.code == '200') {
                var _data = {
                  dialog: false,
                  refresh: true
                };
                _this.close(_data);
              } else {
                _this.$message({
                  type: 'error',
                  message: res.data.msg
                });
              }
            }
          }).catch(function () {
            _this.isClick = false;
            _this.loading = false;
          });
        } else {
          _this.isClick = false;
          _this.loading = false;
        }
      });
    },
    close: function close(data) {
      // 关闭
      if (!_.isEmpty(data)) {
        this.$emit('close', data);
      } else {
        var postData = {
          dialog: false,
          refresh: false
        };
        this.$emit('close', postData);
      }
    },
    confirmIdentityInfo: function confirmIdentityInfo() {
      var _this2 = this;
      // 表单提交
      this.isClick = true;
      this.$refs["identity"].validate(function (valid) {
        if (valid) {
          var postData = {
            insuredList: []
          };
          if (_this2.formData.cust_holder.certificateNo == _this2.formData.cust_insured.certificateNo) {
            _this2.formData.cust_insured.nation = _this2.formData.cust_holder.nation;
            _this2.formData.cust_insured.issuingAgency = _this2.formData.cust_holder.issuingAgency;
            _this2.formData.cust_insured.certStartDate = _this2.formData.cust_holder.certStartDate;
            _this2.formData.cust_insured.certEndDate = _this2.formData.cust_holder.certEndDate;
          }
          if (!_.isEmpty(_this2.formData.cust_holder)) {
            postData.insuredList.push(_this2.formData.cust_holder);
          }
          if (!_.isEmpty(_this2.formData.cust_insured)) {
            postData.insuredList.push(_this2.formData.cust_insured);
          }
          postData.proposalId = _this2.proposalId;
          _this2.loading = true;
          slipRequest.customerCollect(postData).then(function (res) {
            _this2.isClick = false;
            _this2.loading = false;
            if (res.code == RESPONSE_SUCCESS) {
              if (res.data.code == RESPONSE_SUCCESS) {
                _this2.formData.issueCode = "";
                //北京地区人保，身份信息采集完成后，直接发起支付；
                if (_.toLower(_this2.insCorpCode) == 'picc' && _this2.insCityCode == '110100') {
                  _this2.identityStatus = 'verification';
                  var _data = {
                    dialog: false,
                    refresh: true
                  };
                  _this2.close(_data);
                } else {
                  _this2.identityStatus = 'verification';
                }
              } else {
                _this2.$message({
                  type: 'error',
                  message: res.data.msg
                });
              }
            }
          }).catch(function () {
            _this2.isClick = false;
            _this2.loading = false;
          });
        } else {
          _this2.isClick = false;
          _this2.loading = false;
        }
      });
    }
  }
};