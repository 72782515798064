var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "app-m-cls",
      },
      [
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "font-size": "14px",
              padding: "5px 15px",
              background: "rgb(255, 255, 255)",
              height: "50px",
              "align-items": "center",
            },
          },
          [
            _vm.bizType === "BROKER"
              ? _c(
                  "div",
                  { staticStyle: { flex: "1" } },
                  [_c("OrgSelect", { attrs: { searchForm: _vm.searchForm } })],
                  1
                )
              : _vm._e(),
            _vm.bizType === "OEM"
              ? _c(
                  "div",
                  [
                    _c("OemSelect", {
                      attrs: { searchForm: _vm.orgForm, canClickArea: true },
                      on: { nodeClick: _vm.nodeClick },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "el-select",
              {
                staticStyle: { margin: "0 5px", width: "320px" },
                attrs: { placeholder: "请选择时间", clearable: false },
                on: { change: _vm.handleTime },
                model: {
                  value: _vm.searchForm.timeSelect,
                  callback: function ($$v) {
                    _vm.$set(_vm.searchForm, "timeSelect", $$v)
                  },
                  expression: "searchForm.timeSelect",
                },
              },
              _vm._l(_vm.dictSource["TIME_SELECT"], function (item, index) {
                return _c("el-option", {
                  key: index,
                  attrs: { label: item.dictName, value: item.dictCode },
                })
              }),
              1
            ),
            _vm.isDataTime
              ? _c("el-date-picker", {
                  ref: "input",
                  staticStyle: { width: "240px" },
                  attrs: {
                    disabled: _vm.isDataTime,
                    "start-placeholder": "起期",
                    "end-placeholder": "止期",
                    type: "daterange",
                    format: "yyyy-MM-dd",
                    "value-format": "yyyy-MM-dd",
                    "unlink-panels": "",
                    "range-separator": "——",
                    "picker-options": _vm.pickerOptions,
                  },
                  on: {
                    change: function ($event) {
                      return _vm.dateChange("startDate", "endDate", $event)
                    },
                  },
                  model: {
                    value: _vm.startEndTime,
                    callback: function ($$v) {
                      _vm.startEndTime = $$v
                    },
                    expression: "startEndTime",
                  },
                })
              : _c("DatePicker", {
                  attrs: {
                    pickerDateTime: _vm.startEndTime,
                    clearable: false,
                    hasPickerOptions: false,
                    startDate: "startDate",
                    endDate: "endDate",
                    width: "240px",
                  },
                  on: {
                    pickerDateChange: function ($event) {
                      return _vm.getPickersDate("startEndTime", $event)
                    },
                  },
                }),
            _c(
              "el-button",
              {
                staticStyle: { "margin-left": "10px" },
                attrs: { size: "mini", type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.loadData()
                  },
                },
              },
              [_vm._v("统计")]
            ),
            _vm.searchForm.category === "Organization"
              ? _c(
                  "el-button",
                  {
                    staticStyle: { "margin-left": "10px" },
                    attrs: { size: "mini" },
                    on: { click: _vm.exportExcels },
                  },
                  [_vm._v("导出")]
                )
              : _vm._e(),
          ],
          1
        ),
        _c(
          "el-card",
          {
            staticClass: "box-card",
            staticStyle: { margin: "10px 10px 0 10px" },
            attrs: { shadow: "never" },
          },
          [
            _vm.bizType === "BROKER"
              ? _c(
                  "div",
                  { staticStyle: { position: "relative" } },
                  [
                    _c(
                      "el-tabs",
                      {
                        on: { "tab-click": _vm.handleTabClick },
                        model: {
                          value: _vm.activeName,
                          callback: function ($$v) {
                            _vm.activeName = $$v
                          },
                          expression: "activeName",
                        },
                      },
                      [
                        _vm.isEmployee
                          ? _c("el-tab-pane", {
                              attrs: {
                                label: "按业务机构汇总",
                                name: "Employee",
                              },
                            })
                          : _vm._e(),
                        !_vm.isEmployee
                          ? _c("el-tab-pane", {
                              attrs: {
                                label: "当前所选机构",
                                name: "Organization",
                              },
                            })
                          : _vm._e(),
                        !_vm.isEmployee
                          ? _c("el-tab-pane", {
                              attrs: {
                                label: "下级业务机构",
                                name: "Department",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "el-table",
              {
                ref: "multipleTable",
                staticStyle: { width: "100%" },
                attrs: {
                  size: "mini",
                  "empty-text": _vm.emptyText,
                  border: "",
                  "show-summary": "",
                  data: _vm.tableData,
                  height: _vm.tableHeightD,
                  "header-cell-style": { background: "#F7F7F7" },
                  "highlight-current-row": "",
                },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    type: "index",
                    label: "序号",
                    width: "50",
                    align: "center",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "name",
                    label: "" + (_vm.isEmployee ? "人员名称" : "机构"),
                    "min-width": "200",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    sortable: "",
                    prop: "qty",
                    label: "保单出单量",
                    "min-width": "110",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    sortable: "",
                    prop: "premiums",
                    label: "总保费",
                    "min-width": "90",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    sortable: "",
                    prop: "insuredQty",
                    label: "在线出单量",
                    "min-width": "110",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    sortable: "",
                    prop: "insuredPremiums",
                    label: "在线出单总保费",
                    "min-width": "140",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    sortable: "",
                    prop: "vehicleProposalNum",
                    label: "报价车辆数",
                    "min-width": "110",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    sortable: "",
                    prop: "proposalNum",
                    label: "报价次数",
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }