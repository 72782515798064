var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "app-m-cls" },
      [
        _c(
          "div",
          { staticClass: "table-cls normal-btn-cls" },
          [
            _c(
              "el-card",
              { staticClass: "box-card", attrs: { shadow: "never" } },
              [
                _c(
                  "div",
                  { attrs: { slot: "header" }, slot: "header" },
                  [
                    _c(
                      "Search",
                      {
                        on: {
                          searchData: _vm.searchData,
                          resetSearch: _vm.resetSearch,
                          breakData: _vm.breakData,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "btnRigth",
                            fn: function () {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openDialog("add", {})
                                      },
                                    },
                                  },
                                  [_vm._v("新增")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      [
                        _c("span", [_vm._v("保险公司：")]),
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              size: "mini",
                              filterable: "",
                              placeholder: "请选择保险公司",
                              clearable: "",
                            },
                            model: {
                              value: _vm.searchForm.insCorpCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "insCorpCode", $$v)
                              },
                              expression: "searchForm.insCorpCode",
                            },
                          },
                          _vm._l(_vm.supplyers, function (item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: {
                                label: item.shortName,
                                value: item.code,
                              },
                            })
                          }),
                          1
                        ),
                        _c("span", [_vm._v("险种：")]),
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              size: "mini",
                              filterable: "",
                              placeholder: "请选择险种",
                              clearable: "",
                            },
                            model: {
                              value: _vm.searchForm.riskType,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "riskType", $$v)
                              },
                              expression: "searchForm.riskType",
                            },
                          },
                          _vm._l(_vm.riskEnum, function (item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: { label: item.text, value: item.code },
                            })
                          }),
                          1
                        ),
                        _c("span", [_vm._v("模板名称：")]),
                        _c("el-input", {
                          attrs: {
                            size: "mini",
                            placeholder: "请输入模板名称",
                            clearable: "",
                          },
                          model: {
                            value: _vm.searchForm.templateName,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "templateName", $$v)
                            },
                            expression: "searchForm.templateName",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    ref: "multipleTable",
                    staticStyle: { width: "100%" },
                    attrs: {
                      size: "mini",
                      border: "",
                      data: _vm.tableData,
                      height: _vm.tableHeightN,
                      "header-cell-style": { background: "#F7F7F7" },
                      "highlight-current-row": "",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "insCorpCode",
                        label: "保司编码",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "insCorpName",
                        label: "保司名称",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "areaCode",
                        label: "区域编码",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "areaName",
                        label: "区域名称",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "riskTypeName",
                        label: "险种名称",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "templateName",
                        label: "模板名称",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "priority",
                        label: "优先级",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "pdfFileName",
                        label: "PDF文件",
                        width: "220px",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-link",
                                {
                                  attrs: { type: "primary", underline: false },
                                  on: {
                                    click: function ($event) {
                                      return _vm.downloadFile(scope.row)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(scope.row.pdfFileName))]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "pageNumber",
                        align: "right",
                        label: "页数",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "width",
                        align: "right",
                        label: "页面宽度",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "height",
                        align: "right",
                        label: "页面高度",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "",
                        label: "启用",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("el-switch", {
                                on: {
                                  change: function ($event) {
                                    return _vm.handleSwitch($event, scope.row)
                                  },
                                },
                                model: {
                                  value: scope.row.enable,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "enable", $$v)
                                  },
                                  expression: "scope.row.enable",
                                },
                              }),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "createdTime",
                        label: "创建时间",
                        width: "150px",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "操作",
                        align: "center",
                        width: "200",
                        fixed: "right",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.openDialog("edit", scope.row)
                                    },
                                  },
                                },
                                [_vm._v("编辑")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.viewConfigDetail(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("详情")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.deleteTemplate(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c("pagination", {
                  attrs: {
                    total: _vm.total,
                    page: _vm.listQuery.pageNum,
                    limit: _vm.listQuery.pageSize,
                  },
                  on: {
                    "update:page": function ($event) {
                      return _vm.$set(_vm.listQuery, "pageNum", $event)
                    },
                    "update:limit": function ($event) {
                      return _vm.$set(_vm.listQuery, "pageSize", $event)
                    },
                    pagination: function ($event) {
                      return _vm.loadData()
                    },
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c("Detail", {
          attrs: { "item-form": _vm.itemForm, "op-type": _vm.opType },
          on: { closePage: _vm.closePage },
          model: {
            value: _vm.showDialog,
            callback: function ($$v) {
              _vm.showDialog = $$v
            },
            expression: "showDialog",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }