import _toConsumableArray from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/toConsumableArray.js";
import _slicedToArray from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/slicedToArray.js";
import _createForOfIteratorHelper from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.unshift.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.slice.js";
var state = {
  visitedViews: [],
  cachedViews: []
};
var mutations = {
  ADD_VISITED_VIEW: function ADD_VISITED_VIEW(state, view) {
    if (state.visitedViews.some(function (v) {
      return v.fullPath === view.fullPath;
    })) return;
    if (view.fullPath.indexOf('/policy/add') != -1) {
      sessionStorage.removeItem("policyForm");
    }
    var _title = view.meta.title;
    //如果有车牌号或者车架号，则作为tab名称后缀
    if (view.query && view.query) {
      var plateNo = view.query.plateNo || '';
      plateNo = plateNo == '未上牌' ? '' : plateNo;
      var vin = view.query.vin || '';
      if (plateNo || vin) {
        _title = view.meta.title + ' - ' + (plateNo || vin);
      }
      var ownerName = view.query.ownerName || '';
      var policyId = view.query.policyId || '';
      var riskType = view.query.riskType || '';
      var showName = view.query.isShowName || '';
      if (policyId && riskType) {
        _title = view.meta.title + ' - ' + (plateNo || vin) + ' - ' + (riskType == '0' ? '交强险' : riskType == '2' ? "非车险" : "商业险");
      }
      if (showName) {
        _title = view.meta.title + ' - ' + ownerName;
      }
      var tabName = view.query.tabName || '';
      if (tabName) {
        _title = tabName;
      }
    }
    state.visitedViews.unshift(Object.assign({}, view, {
      title: _title || 'no-name'
    }));
    // _.reverse(state.visitedViews);
  },

  ADD_CACHED_VIEW: function ADD_CACHED_VIEW(state, view) {
    //新增判断是否为3级菜单，是的话将二级菜单的code加入vuex缓存中
    if (view.matched.length === 3) {
      if (state.cachedViews.includes(view.name)) return;
      if (!view.meta.noCache) {
        state.cachedViews.push(view.name);
        if (state.cachedViews.includes(view.matched[1].name)) return;
        state.cachedViews.push(view.matched[1].name);
      }
      ;
    } else {
      if (state.cachedViews.includes(view.name)) return;
      if (!view.meta.noCache) {
        state.cachedViews.push(view.name);
      }
      ;
    }
    ;
  },
  DEL_VISITED_VIEW: function DEL_VISITED_VIEW(state, view) {
    var _iterator = _createForOfIteratorHelper(state.visitedViews.entries()),
      _step;
    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var _step$value = _slicedToArray(_step.value, 2),
          i = _step$value[0],
          v = _step$value[1];
        if (v.fullPath === view.fullPath) {
          state.visitedViews.splice(i, 1);
          break;
        }
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
  },
  DEL_CACHED_VIEW: function DEL_CACHED_VIEW(state, view) {
    var _iterator2 = _createForOfIteratorHelper(state.cachedViews),
      _step2;
    try {
      for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
        var i = _step2.value;
        if (i === view.name) {
          var index = state.cachedViews.indexOf(i);
          state.cachedViews.splice(index, 1);
          break;
        }
      }
    } catch (err) {
      _iterator2.e(err);
    } finally {
      _iterator2.f();
    }
  },
  DEL_OTHERS_VISITED_VIEWS: function DEL_OTHERS_VISITED_VIEWS(state, view) {
    state.visitedViews = state.visitedViews.filter(function (v) {
      return v.meta.affix || v.fullPath === view.fullPath;
    });
  },
  DEL_OTHERS_CACHED_VIEWS: function DEL_OTHERS_CACHED_VIEWS(state, view) {
    var _iterator3 = _createForOfIteratorHelper(state.cachedViews),
      _step3;
    try {
      for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
        var i = _step3.value;
        if (i === view.name) {
          var index = state.cachedViews.indexOf(i);
          state.cachedViews = state.cachedViews.slice(index, index + 1);
          break;
        }
      }
    } catch (err) {
      _iterator3.e(err);
    } finally {
      _iterator3.f();
    }
  },
  DEL_ALL_VISITED_VIEWS: function DEL_ALL_VISITED_VIEWS(state) {
    // keep affix tags
    var affixTags = state.visitedViews.filter(function (tag) {
      return tag.meta.affix;
    });
    state.visitedViews = affixTags;
  },
  DEL_ALL_CACHED_VIEWS: function DEL_ALL_CACHED_VIEWS(state) {
    state.cachedViews = [];
  },
  UPDATE_VISITED_VIEW: function UPDATE_VISITED_VIEW(state, view) {
    var _iterator4 = _createForOfIteratorHelper(state.visitedViews),
      _step4;
    try {
      for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
        var v = _step4.value;
        if (v.fullPath === view.fullPath) {
          v = Object.assign(v, view);
          break;
        }
      }
    } catch (err) {
      _iterator4.e(err);
    } finally {
      _iterator4.f();
    }
  }
};

/**
 * 清除缓存
 * @param {} view 
 */
function clearCache(view) {
  if (!view) return;
  if (view.name == "extendedWarranty") {
    //延保
    sessionStorage.removeItem('YBForm');
  }
  if (view.name == "repurchase") {
    //保值回购
    sessionStorage.removeItem('BZHGForm');
  }
  if (view.name == "threeTypesInsurance") {
    //三保
    sessionStorage.removeItem('SBForm');
  }
  if (view.name == "scooterInsurance") {
    //代步车
    sessionStorage.removeItem('DBCForm');
  }
  // 数据治理统计缓存
  if (view.path == "/governance/owner") {
    setTimeout(function () {
      sessionStorage.removeItem('G_All_TableData');
    }, 500);
  }
  ;
  if (view.path == "/governance/org") {
    setTimeout(function () {
      sessionStorage.removeItem('G_Department_TableData');
      sessionStorage.removeItem('G_Organization_TableData');
    }, 500);
  }
  ;
  if (view.name == "minorRepairs") {
    //小修安心
    sessionStorage.removeItem('XXAXForm');
  }
  if (view.name == "overhaul") {
    //大修无忧
    sessionStorage.removeItem('DXWYForm');
  }
}
/**
 * 清除other缓存
 * @param {} view 
 */
function clearOtherCache(view) {
  if (!view) return;
  if (view.name == "extendedWarranty") {
    //延保
    sessionStorage.removeItem('BZHGForm');
    sessionStorage.removeItem('SBForm');
    sessionStorage.removeItem('DBCForm');
  }
  if (view.name == "repurchase") {
    //保值回购
    sessionStorage.removeItem('YBForm');
    sessionStorage.removeItem('SBForm');
    sessionStorage.removeItem('DBCForm');
  }
  if (view.name == "threeTypesInsurance") {
    //三保
    sessionStorage.removeItem('YBForm');
    sessionStorage.removeItem('BZHGForm');
    sessionStorage.removeItem('DBCForm');
  }
  if (view.name == "scooterInsurance") {
    //代步车
    sessionStorage.removeItem('YBForm');
    sessionStorage.removeItem('SBForm');
    sessionStorage.removeItem('BZHGForm');
  }
}
/**
 * 清除other缓存
 * @param {} view 
 */
function clearOtherCache2(view) {
  if (!view) return;
  if (view.name != "extendedWarranty" && view.name != "repurchase" && view.name != "threeTypesInsurance" && view.name != "scooterInsurance") {
    // console.log('不是出单页面关闭的')
    sessionStorage.removeItem('YBForm');
    sessionStorage.removeItem('BZHGForm');
    sessionStorage.removeItem('SBForm');
    sessionStorage.removeItem('DBCForm');
  }
}
/**
 * 清除所有缓存
 * @param {} view 
 */
function clearAllCache() {
  sessionStorage.removeItem('YBForm'); //延保
  sessionStorage.removeItem('BZHGForm'); //保值回购
  sessionStorage.removeItem('SBForm'); //三保
  sessionStorage.removeItem('DBCForm'); //代步车
}

var actions = {
  addView: function addView(_ref, view) {
    var dispatch = _ref.dispatch;
    dispatch('addVisitedView', view);
    dispatch('addCachedView', view);
  },
  addVisitedView: function addVisitedView(_ref2, view) {
    var commit = _ref2.commit;
    commit('ADD_VISITED_VIEW', view);
  },
  addCachedView: function addCachedView(_ref3, view) {
    var commit = _ref3.commit;
    commit('ADD_CACHED_VIEW', view);
  },
  delView: function delView(_ref4, view) {
    var dispatch = _ref4.dispatch,
      state = _ref4.state;
    return new Promise(function (resolve) {
      dispatch('delVisitedView', view);
      dispatch('delCachedView', view);
      clearCache(view);
      resolve({
        visitedViews: _toConsumableArray(state.visitedViews),
        cachedViews: _toConsumableArray(state.cachedViews)
      });
    });
  },
  delVisitedView: function delVisitedView(_ref5, view) {
    var commit = _ref5.commit,
      state = _ref5.state;
    return new Promise(function (resolve) {
      commit('DEL_VISITED_VIEW', view);
      resolve(_toConsumableArray(state.visitedViews));
    });
  },
  delCachedView: function delCachedView(_ref6, view) {
    var commit = _ref6.commit,
      state = _ref6.state;
    return new Promise(function (resolve) {
      commit('DEL_CACHED_VIEW', view);
      resolve(_toConsumableArray(state.cachedViews));
    });
  },
  delOthersViews: function delOthersViews(_ref7, view) {
    var dispatch = _ref7.dispatch,
      state = _ref7.state;
    return new Promise(function (resolve) {
      dispatch('delOthersVisitedViews', view);
      dispatch('delOthersCachedViews', view);
      clearOtherCache(view);
      resolve({
        visitedViews: _toConsumableArray(state.visitedViews),
        cachedViews: _toConsumableArray(state.cachedViews)
      });
    });
  },
  delOthersVisitedViews: function delOthersVisitedViews(_ref8, view) {
    var commit = _ref8.commit,
      state = _ref8.state;
    return new Promise(function (resolve) {
      // console.log('delOthersVisitedViews',view)
      clearOtherCache2(view);
      commit('DEL_OTHERS_VISITED_VIEWS', view);
      resolve(_toConsumableArray(state.visitedViews));
    });
  },
  delOthersCachedViews: function delOthersCachedViews(_ref9, view) {
    var commit = _ref9.commit,
      state = _ref9.state;
    return new Promise(function (resolve) {
      // console.log('delOthersCachedViews',view)
      clearOtherCache2(view);
      commit('DEL_OTHERS_CACHED_VIEWS', view);
      resolve(_toConsumableArray(state.cachedViews));
    });
  },
  delAllViews: function delAllViews(_ref10, view) {
    var dispatch = _ref10.dispatch,
      state = _ref10.state;
    return new Promise(function (resolve) {
      dispatch('delAllVisitedViews', view);
      dispatch('delAllCachedViews', view);
      clearAllCache();
      resolve({
        visitedViews: _toConsumableArray(state.visitedViews),
        cachedViews: _toConsumableArray(state.cachedViews)
      });
    });
  },
  delAllVisitedViews: function delAllVisitedViews(_ref11) {
    var commit = _ref11.commit,
      state = _ref11.state;
    return new Promise(function (resolve) {
      commit('DEL_ALL_VISITED_VIEWS');
      resolve(_toConsumableArray(state.visitedViews));
    });
  },
  delAllCachedViews: function delAllCachedViews(_ref12) {
    var commit = _ref12.commit,
      state = _ref12.state;
    return new Promise(function (resolve) {
      commit('DEL_ALL_CACHED_VIEWS');
      resolve(_toConsumableArray(state.cachedViews));
    });
  },
  updateVisitedView: function updateVisitedView(_ref13, view) {
    var commit = _ref13.commit;
    commit('UPDATE_VISITED_VIEW', view);
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};