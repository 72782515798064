//
//
//
//
//
//
//
//
//
//
//
//

import InsurancePolicy from '@/views/customers/detail/insurancePolicy.vue';
export default {
  data: function data() {
    return {
      transmitObj: {
        pageNum: 1,
        pageSize: 20,
        riskType: this.$route.query.riskType,
        vin: this.$route.query.vin
      }
    };
  },
  components: {
    InsurancePolicy: InsurancePolicy
  },
  created: function created() {},
  methods: {}
};