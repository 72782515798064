import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import servicePackageApi from "@/api/servicePackage";
import { getSupplyers } from '@/utils/common';
var delay = function () {
  var _this = this;
  var timeout;
  return function (callback, ms) {
    if (timeout) clearTimeout(timeout);
    var callNow = !timeout;
    timeout = setTimeout(function () {
      timeout = undefined;
    }, ms);
    if (callNow) callback.apply(_this, [callback, ms]);
  };
}();
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    DialogType: {
      type: String,
      default: ''
    },
    accidentSchemeId: {
      type: String,
      default: ''
    }
    // riskTypeList: {
    //     type: Array,
    //     default: []
    // },
  },
  data: function data() {
    return {
      accidentList: [{
        accidentId: 7,
        accidentType: "驾意险",
        accidentTypeCode: "JYX"
      }],
      addForm: {
        accidentSchemeCode: '',
        // "驾意险方案编码",
        // accidentSchemeId: '', // '驾意险方案表id',
        accidentSchemeName: '',
        // "驾意险方案名称",
        accidentSchemeOutlining: '',
        // "驾意险方案说明",
        // accidentSchemePrice: '', // '驾意险方案价格',
        enabled: true,
        // true,
        // extendedParameter: '', // "扩展参数（json）",
        // insCityCode: '', // "城市编码",
        // insCityName: '', // "城市名称",
        insCorpCode: '',
        // "保险公司编码",
        insCorpName: '',
        // "保险公司名称",
        // modelName: '', // "车辆型号",
        packageAmount: '',
        // '套餐保额',
        packageCode: '',
        // "套餐编码",
        packageName: '',
        // "套餐名称",
        packagePremium: '',
        // '套餐保费',
        packageTips: '' // "套餐说明"
      },

      riskAccidentPackageSaveParams: [{
        insuranceCode: "",
        //套餐编码
        insuranceName: "",
        //套餐名称
        minSeat: '',
        //最小座位数
        maxSeat: '',
        //最小座位数
        maxNumber: '',
        //最大购买份数
        minInsureAge: '',
        //最小投保年龄
        maxInsureAge: '',
        //最大投保年龄
        whetherCalculate: false,
        //是否加入乘客计算
        insuredAmount: '',
        //保额
        premium: '',
        //保费
        riskList: [{
          riskCode: "",
          //代码
          riskName: "",
          //险种
          riskType: "",
          //类别
          riskAmount: "" //保额
        }]
      }],

      formLoading: false,
      formRules: {
        insCorpCode: [{
          required: true,
          message: '请选择保险公司',
          trigger: 'change'
        }],
        accidentId: [{
          required: true,
          message: '请选择险种类别',
          trigger: 'change'
        }],
        accidentSchemeCode: [{
          required: true,
          message: '请输入产品编码',
          trigger: 'blur'
        }],
        accidentSchemeName: [{
          required: true,
          message: '请输入产品名称',
          trigger: 'blur'
        }]
      },
      routesData: [],
      routesList: [],
      supplyers: []
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.supplyers = getSupplyers();
        this.initData(); //初始化数据
      }
      ;
    }
  },
  mounted: function mounted() {
    this.supplyers = getSupplyers();
    this.initData(); //初始化数据
  },

  methods: {
    tableRowClassName: function tableRowClassName(_ref) {
      var row = _ref.row,
        rowIndex = _ref.rowIndex;
      row.index = rowIndex;
    },
    onRowClick: function onRowClick(row, event, column) {
      var index = row.index;
    },
    copyList: function copyList(index) {
      var obj = JSON.parse(JSON.stringify(this.riskAccidentPackageSaveParams[index]));
      this.riskAccidentPackageSaveParams.push(obj);
    },
    addList: function addList() {
      this.riskAccidentPackageSaveParams.push({
        insuranceCode: "",
        //套餐编码
        insuranceName: "",
        //套餐名称
        minSeat: '',
        //最小座位数
        maxSeat: '',
        //最小座位数
        maxNumber: '',
        //最大购买份数
        minInsureAge: '',
        //最小投保年龄
        maxInsureAge: '',
        //最大投保年龄
        whetherCalculate: false,
        //是否加入乘客计算
        insuredAmount: '',
        //保额
        premium: '',
        //保费
        riskList: [{
          riskCode: "",
          //代码
          riskName: "",
          //险种
          riskType: "",
          //类别
          riskAmount: "" //保额
        }]
      });
    },
    addriskList: function addriskList(index) {
      if (this.DialogType != 'view') {
        this.riskAccidentPackageSaveParams[index].riskList.push({
          riskCode: "",
          //代码
          riskName: "",
          //险种
          riskType: "",
          //类别
          riskAmount: "" //保额
        });
      }
    },
    delList: function delList(index) {
      if (index != 0) {
        this.riskAccidentPackageSaveParams.splice(index, 1);
      }
    },
    delriskList: function delriskList(val, index) {
      if (this.DialogType != 'view') {
        if (this.riskAccidentPackageSaveParams[index].riskList.length != 1) {
          this.riskAccidentPackageSaveParams[index].riskList.splice(val.index, 1);
        }
      }
    },
    getAccidentType: function getAccidentType(val) {
      var item = _.find(this.supplyers, function (i) {
        return i.code == val;
      });
      this.addForm.insCorpName = item.shortName;
    },
    initData: function initData() {
      var _this2 = this;
      //初始化数据
      this.formLoading = true;
      if (this.DialogType == 'edit' || this.DialogType == 'view') {
        servicePackageApi.getAccidentDetail(this.accidentSchemeId).then(function (res) {
          _this2.formLoading = false;
          if (res.code == 200) {
            _this2.addForm = res.data ? res.data : {};
          }
        });
      } else {
        this.addForm = {
          accidentSchemeCode: '',
          // "驾意险方案编码",
          // accidentSchemeId: '', // '驾意险方案表id',
          accidentSchemeName: '',
          // "驾意险方案名称",
          accidentSchemeOutlining: '',
          // "驾意险方案说明",
          // accidentSchemePrice: '', // '驾意险方案价格',
          enabled: true,
          // true,
          // extendedParameter: '', // "扩展参数（json）",
          // insCityCode: '', // "城市编码",
          // insCityName: '', // "城市名称",
          insCorpCode: '',
          // "保险公司编码",
          insCorpName: '',
          // "保险公司名称",
          // modelName: '', // "车辆型号",
          packageAmount: '',
          // '套餐保额',
          packageCode: '',
          // "套餐编码",
          packageName: '',
          // "套餐名称",
          packagePremium: '',
          // '套餐保费',
          packageTips: '' // "套餐说明"
        };

        this.formLoading = false;
      }
    },
    saveClicked: function saveClicked() {
      var _this3 = this;
      //保存数据
      delay(function () {
        _this3.$refs['addForm'].validate(function (valid) {
          if (valid) {
            if (_this3.DialogType == 'add') {
              servicePackageApi.accidentAdd(_this3.addForm).then(function (res) {
                if (res.code == 200 && res.success == true) {
                  _this3.formLoading = false;
                  _this3.$message.success(res.msg);
                  _this3.closePage(false);
                }
              });
            } else {
              var data = _.cloneDeep(_this3.addForm);
              data.packagePremium = Number(data.packagePremium);
              data.packageAmount = Number(data.packageAmount);
              servicePackageApi.AccidentUpdate(_this3.accidentSchemeId, data).then(function (res) {
                if (res.code == 200 && res.success == true) {
                  _this3.formLoading = false;
                  _this3.$message.success(res.msg);
                  _this3.closePage(false);
                }
              });
            }
          }
        });
      }, 1000);
    },
    closePage: function closePage() {
      var boolean = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      //关闭弹窗
      this.$refs.addForm.resetFields();
      this.$emit('closePage', boolean);
    }
  }
};