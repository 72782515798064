import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from "@/mixins/initHeight";
import dict from "@/mixins/dict";
import Pagination from "@/components/Pagination";
import roleDialog from "./dialog";
import updateDialog from "./updateDialog";
import storageServiceRequest from "@/api/storageService";
import { commonExport } from '@/utils/common';
export default {
  name: 'storageService',
  components: {
    Pagination: Pagination,
    roleDialog: roleDialog,
    updateDialog: updateDialog
  },
  mixins: [initHeight, dict],
  computed: {},
  filters: {
    bizTypeFilter: function bizTypeFilter(val, list) {
      var dictName = null;
      if (val) {
        list.map(function (item) {
          if (item.dictCode == val) {
            dictName = item.dictName;
          }
        });
      }
      return dictName;
    }
  },
  data: function data() {
    return {
      drawer: false,
      searchForm: {},
      form: {},
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      opType: "",
      loading: false,
      showDialog: false,
      showUpdateDialog: false,
      itemId: "",
      bizType: null,
      categoryList: [{
        categoryCode: 'ALI',
        categoryName: '阿里云OSS'
      }, {
        categoryCode: 'TENCENT',
        categoryName: '腾讯云OSS'
      }, {
        categoryCode: 'MIN_IO',
        categoryName: 'MinIO OSS'
      }, {
        categoryCode: 'QINIU',
        categoryName: '七牛云OSS'
      }, {
        categoryCode: 'HUAWEI',
        categoryName: '华为云OBS'
      }]
    };
  },
  created: function created() {
    this.loadData();
  },
  methods: {
    // 导出
    exportFn: function exportFn() {
      var exporParams = {
        total: this.total,
        fileName: '存储服务配置',
        exportUrl: '/tp-storage-service/objStorage/search',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    // 是否默认
    changeDefault: function changeDefault(row, e) {
      var _this = this;
      if (!row.isDefault) {
        return;
      } else {
        this.$confirm('是否将该条数据设为默认?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          storageServiceRequest.default(row.objStorageId).then(function (res) {
            if (res.code == 200) {
              _this.$message({
                type: "success",
                message: res.msg
              });
            }
            _this.loadData();
          });
        }).catch(function () {
          _this.loadData();
        });
      }
    },
    // 是否启用
    changeEnable: function changeEnable(row) {
      var _this2 = this;
      if (row.enabled) {
        this.$confirm('是否启用?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          storageServiceRequest.enable(row.objStorageId).then(function (res) {
            if (res.code == 200) {
              _this2.$message({
                type: "success",
                message: res.msg
              });
            }
            _this2.loadData();
          });
        }).catch(function () {
          _this2.loadData();
        });
      } else {
        this.$confirm('是否禁用?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          storageServiceRequest.disable(row.objStorageId).then(function (res) {
            if (res.code == 200) {
              _this2.$message({
                type: "success",
                message: res.msg
              });
            }
            _this2.loadData();
          });
        }).catch(function () {
          _this2.loadData();
        });
      }
    },
    breakData: function breakData() {
      this.loadData();
    },
    add: function add(type) {
      //新增
      this.opType = type;
      this.showDialog = true;
    },
    updateKey: function updateKey(item) {
      this.showUpdateDialog = true;
      this.itemId = parseInt(item.objStorageId);
      this.$forceUpdate();
    },
    editItem: function editItem(type, item) {
      //编辑
      this.opType = type;
      this.showDialog = true;
      this.itemId = parseInt(item.objStorageId);
      this.$forceUpdate();
    },
    deleteItem: function deleteItem(item) {
      var _this3 = this;
      //删除
      this.$confirm("您确定要删除该数据吗？", "温馨提示", {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: "warning"
      }).then(function () {
        storageServiceRequest.delData(parseInt(item.objStorageId)).then(function (res) {
          if (res.code == 200) {
            _this3.$message({
              type: "success",
              message: res.msg
            });
            _this3.loadData();
          }
        }).catch(function (err) {});
      });
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {};
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    loadData: function loadData() {
      var _this4 = this;
      //获取列表数据
      this.loading = true;
      this.drawer = false;
      this.showDialog = false;
      this.showUpdateDialog = false;
      storageServiceRequest.getStorageList(this.listQuery.pageNum, this.listQuery.pageSize, this.searchForm).then(function (res) {
        _this4.loading = false;
        if (res.data) {
          _this4.tableData = res.data.list || [];
          _this4.total = res.data.page ? res.data.page.recordsTotal : 0;
        }
      });
    }
  }
};