var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageContainer", [
        _c("div", { attrs: { slot: "main" }, slot: "main" }, [
          _c(
            "div",
            {
              staticClass: "app-m-cls",
              staticStyle: { "min-height": "calc(100vh - 130px)" },
            },
            [
              _c(
                "div",
                { ref: "header", staticClass: "header-cls" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.insuranceForm,
                        rules: _vm.formRules,
                        "label-width": "130px",
                        size: "mini",
                      },
                    },
                    [
                      _c(
                        "el-collapse",
                        {
                          staticStyle: { "margin-top": "10px" },
                          model: {
                            value: _vm.activeOne,
                            callback: function ($$v) {
                              _vm.activeOne = $$v
                            },
                            expression: "activeOne",
                          },
                        },
                        [
                          _c(
                            "el-collapse-item",
                            {
                              staticClass: "collapse-item-style",
                              attrs: { name: "1" },
                            },
                            [
                              _c("template", { slot: "title" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "title",
                                    staticStyle: { "line-height": "25px" },
                                  },
                                  [
                                    _c("FormLabel", {
                                      attrs: { "form-label": "基础信息" },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "el-row",
                                { attrs: { gutter: 0 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "出单网点：" } },
                                        [
                                          _c("el-input", {
                                            attrs: { disabled: "" },
                                            model: {
                                              value:
                                                _vm.insuranceForm.salesOrgName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.insuranceForm,
                                                  "salesOrgName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "insuranceForm.salesOrgName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "业务员：" } },
                                        [
                                          _c("el-input", {
                                            attrs: { disabled: "" },
                                            model: {
                                              value:
                                                _vm.insuranceForm.salemanName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.insuranceForm,
                                                  "salemanName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "insuranceForm.salemanName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "投保城市：" } },
                                        [
                                          _c("el-input", {
                                            attrs: { disabled: "" },
                                            model: {
                                              value:
                                                _vm.insuranceForm.insCityName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.insuranceForm,
                                                  "insCityName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "insuranceForm.insCityName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-collapse",
                        {
                          staticStyle: { "margin-top": "10px" },
                          model: {
                            value: _vm.activeTwo,
                            callback: function ($$v) {
                              _vm.activeTwo = $$v
                            },
                            expression: "activeTwo",
                          },
                        },
                        [
                          _c(
                            "el-collapse-item",
                            {
                              staticClass: "collapse-item-style",
                              attrs: { name: "1" },
                            },
                            [
                              _c("template", { slot: "title" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "title",
                                    staticStyle: { "line-height": "25px" },
                                  },
                                  [
                                    _c("FormLabel", {
                                      attrs: { "form-label": "车辆信息" },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "el-row",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "flex-direction": "row",
                                    "flex-wrap": "wrap",
                                  },
                                  attrs: { gutter: 0 },
                                },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "车牌号：" } },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: { display: "flex" },
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: { disabled: "" },
                                                model: {
                                                  value:
                                                    _vm.coreProposalVehicleVO
                                                      .plateNo,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.coreProposalVehicleVO,
                                                      "plateNo",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "coreProposalVehicleVO.plateNo",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "车架号：" } },
                                        [
                                          _c("el-input", {
                                            attrs: { disabled: "" },
                                            model: {
                                              value:
                                                _vm.coreProposalVehicleVO.vin,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.coreProposalVehicleVO,
                                                  "vin",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "coreProposalVehicleVO.vin",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "车辆品牌：" } },
                                        [
                                          _c("el-input", {
                                            attrs: { disabled: "" },
                                            model: {
                                              value:
                                                _vm.coreProposalVehicleVO
                                                  .brandName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.coreProposalVehicleVO,
                                                  "brandName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "coreProposalVehicleVO.brandName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "车辆型号：" } },
                                        [
                                          _c("el-input", {
                                            attrs: { disabled: "" },
                                            model: {
                                              value:
                                                _vm.coreProposalVehicleVO
                                                  .modelName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.coreProposalVehicleVO,
                                                  "modelName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "coreProposalVehicleVO.modelName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "车型价格：" } },
                                        [
                                          _c("el-input", {
                                            attrs: { disabled: "" },
                                            model: {
                                              value:
                                                _vm.coreProposalVehicleVO
                                                  .purchasePrice,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.coreProposalVehicleVO,
                                                  "purchasePrice",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "coreProposalVehicleVO.purchasePrice",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "使用性质：" } },
                                        [
                                          _c("el-input", {
                                            attrs: { disabled: "" },
                                            model: {
                                              value:
                                                _vm.coreProposalVehicleVO
                                                  .useAttributeName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.coreProposalVehicleVO,
                                                  "useAttributeName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "coreProposalVehicleVO.useAttributeName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "注册日期：" } },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              disabled: "",
                                              value: _vm.coreProposalVehicleVO
                                                .registerDate
                                                ? _vm.coreProposalVehicleVO.registerDate.slice(
                                                    0,
                                                    10
                                                  )
                                                : "",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: { label: "行驶证发证日期：" },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              disabled: "",
                                              value: _vm.coreProposalVehicleVO
                                                .issueDate
                                                ? _vm.coreProposalVehicleVO.issueDate.slice(
                                                    0,
                                                    10
                                                  )
                                                : "",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-collapse",
                        {
                          staticStyle: { "margin-top": "10px" },
                          model: {
                            value: _vm.activeThree,
                            callback: function ($$v) {
                              _vm.activeThree = $$v
                            },
                            expression: "activeThree",
                          },
                        },
                        [
                          _c(
                            "el-collapse-item",
                            {
                              staticClass: "collapse-item-style",
                              attrs: { name: "1" },
                            },
                            [
                              _c("template", { slot: "title" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "title",
                                    staticStyle: { "line-height": "25px" },
                                  },
                                  [
                                    _c("FormLabel", {
                                      attrs: { "form-label": "车主信息" },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "el-row",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "flex-direction": "row",
                                    "flex-wrap": "wrap",
                                  },
                                  attrs: { gutter: 0 },
                                },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "车主名称：" } },
                                        [
                                          _c("el-input", {
                                            attrs: { disabled: "" },
                                            model: {
                                              value:
                                                _vm.coreProposalVehicleVO
                                                  .ownerName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.coreProposalVehicleVO,
                                                  "ownerName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "coreProposalVehicleVO.ownerName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "车主性质：" } },
                                        [
                                          _c("el-input", {
                                            attrs: { disabled: "" },
                                            model: {
                                              value:
                                                _vm.coreProposalVehicleVO
                                                  .ownAttributeName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.coreProposalVehicleVO,
                                                  "ownAttributeName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "coreProposalVehicleVO.ownAttributeName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.coreProposalVehicleVO &&
                              _vm.coreProposalVehicleVO.ownAttributeName ==
                                "个人"
                                ? _c(
                                    "el-row",
                                    { attrs: { gutter: 0 } },
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 14 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "证件类型：" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                  },
                                                },
                                                [
                                                  _c("el-input", {
                                                    staticStyle: {
                                                      width: "30%",
                                                    },
                                                    attrs: { disabled: "" },
                                                    model: {
                                                      value:
                                                        _vm
                                                          .coreProposalVehicleVO
                                                          .ownerCertificateTypeName,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.coreProposalVehicleVO,
                                                          "ownerCertificateTypeName",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "coreProposalVehicleVO.ownerCertificateTypeName",
                                                    },
                                                  }),
                                                  _c("el-input", {
                                                    staticStyle: {
                                                      width: "65%",
                                                      "margin-left": "5px",
                                                    },
                                                    attrs: { disabled: "" },
                                                    model: {
                                                      value:
                                                        _vm
                                                          .coreProposalVehicleVO
                                                          .ownerCertificateNo,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.coreProposalVehicleVO,
                                                          "ownerCertificateNo",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "coreProposalVehicleVO.ownerCertificateNo",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 24 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "证件起止日期：",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        display: "flex",
                                                        width: "30%",
                                                      },
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        attrs: {
                                                          disabled: "",
                                                          value: _vm
                                                            .coreProposalVehicleVO
                                                            .ownerCertStartDate
                                                            ? _vm.coreProposalVehicleVO.ownerCertStartDate.slice(
                                                                0,
                                                                10
                                                              )
                                                            : "",
                                                        },
                                                      }),
                                                      _vm._v(" ~ "),
                                                      _c("el-input", {
                                                        attrs: {
                                                          disabled: "",
                                                          value: _vm
                                                            .coreProposalVehicleVO
                                                            .ownerCertEndDate
                                                            ? _vm.coreProposalVehicleVO.ownerCertEndDate.slice(
                                                                0,
                                                                10
                                                              )
                                                            : "",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 8 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "联系电话：" } },
                                            [
                                              _c("el-input", {
                                                attrs: { disabled: "" },
                                                model: {
                                                  value:
                                                    _vm.coreProposalVehicleVO
                                                      .ownerMobile,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.coreProposalVehicleVO,
                                                      "ownerMobile",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "coreProposalVehicleVO.ownerMobile",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 16 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "电子邮箱：" } },
                                            [
                                              _c("el-input", {
                                                attrs: { disabled: "" },
                                                model: {
                                                  value:
                                                    _vm.coreProposalVehicleVO
                                                      .ownerEmail,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.coreProposalVehicleVO,
                                                      "ownerEmail",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "coreProposalVehicleVO.ownerEmail",
                                                },
                                              }),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "font-size": "12px",
                                                    color: "#999",
                                                    "margin-left": "10px",
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass: "el-icon-info",
                                                  }),
                                                  _vm._v(
                                                    " 用于接收保险公司发送的电子保单"
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 18 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              ref: "regionCode",
                                              staticClass:
                                                "special_row_content",
                                              attrs: {
                                                label: "联系地址：",
                                                prop: "regionCode",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "special_col_one",
                                                  staticStyle: {
                                                    display: "flex",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm
                                                          .coreProposalVehicleVO
                                                          .ownerProvinceName
                                                      ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm
                                                          .coreProposalVehicleVO
                                                          .ownerCityName
                                                      ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm
                                                          .coreProposalVehicleVO
                                                          .ownerDistrictName
                                                      ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm
                                                          .coreProposalVehicleVO
                                                          .ownerAddress
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 8 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              ref: "birthday",
                                              attrs: {
                                                label: "出生日期：",
                                                prop: "birthday",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  disabled: "",
                                                  value: _vm
                                                    .coreProposalVehicleVO
                                                    .ownerBirthday
                                                    ? _vm.coreProposalVehicleVO.ownerBirthday.slice(
                                                        0,
                                                        10
                                                      )
                                                    : "",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 8 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "性别：" } },
                                            [
                                              _c(
                                                "el-radio-group",
                                                {
                                                  attrs: { disabled: "" },
                                                  model: {
                                                    value:
                                                      _vm.coreProposalVehicleVO
                                                        .ownerSex,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.coreProposalVehicleVO,
                                                        "ownerSex",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "coreProposalVehicleVO.ownerSex",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-radio",
                                                    { attrs: { label: "M" } },
                                                    [_vm._v("男")]
                                                  ),
                                                  _c(
                                                    "el-radio",
                                                    { attrs: { label: "F" } },
                                                    [_vm._v("女")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.coreProposalVehicleVO &&
                              _vm.coreProposalVehicleVO.ownAttributeName !=
                                "个人"
                                ? _c(
                                    "el-row",
                                    { attrs: { gutter: 0 } },
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 24 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "证件类型：" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                  },
                                                },
                                                [
                                                  _c("el-input", {
                                                    staticStyle: {
                                                      width: "30%",
                                                    },
                                                    attrs: { disabled: "" },
                                                    model: {
                                                      value:
                                                        _vm
                                                          .coreProposalVehicleVO
                                                          .ownerCertificateTypeName,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.coreProposalVehicleVO,
                                                          "ownerCertificateTypeName",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "coreProposalVehicleVO.ownerCertificateTypeName",
                                                    },
                                                  }),
                                                  _c("el-input", {
                                                    attrs: { disabled: "" },
                                                    model: {
                                                      value:
                                                        _vm
                                                          .coreProposalVehicleVO
                                                          .ownerCertificateNo,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.coreProposalVehicleVO,
                                                          "ownerCertificateNo",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "coreProposalVehicleVO.ownerCertificateNo",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 8 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "联系电话：" } },
                                            [
                                              _c("el-input", {
                                                attrs: { disabled: "" },
                                                model: {
                                                  value:
                                                    _vm.coreProposalVehicleVO
                                                      .ownerMobile,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.coreProposalVehicleVO,
                                                      "ownerMobile",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "coreProposalVehicleVO.ownerMobile",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 16 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "电子邮箱：" } },
                                            [
                                              _c("el-input", {
                                                attrs: { disabled: "" },
                                                model: {
                                                  value:
                                                    _vm.coreProposalVehicleVO
                                                      .ownerEmail,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.coreProposalVehicleVO,
                                                      "ownerEmail",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "coreProposalVehicleVO.ownerEmail",
                                                },
                                              }),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "font-size": "12px",
                                                    color: "#999",
                                                    "margin-left": "10px",
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass: "el-icon-info",
                                                  }),
                                                  _vm._v(
                                                    " 用于接收保险公司发送的电子保单"
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 18 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              ref: "regionCode",
                                              staticClass:
                                                "special_row_content",
                                              attrs: {
                                                label: "联系地址：",
                                                prop: "regionCode",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "special_col_one",
                                                  staticStyle: {
                                                    display: "flex",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm
                                                          .coreProposalVehicleVO
                                                          .ownerProvinceName
                                                      ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm
                                                          .coreProposalVehicleVO
                                                          .ownerCityName
                                                      ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm
                                                          .coreProposalVehicleVO
                                                          .ownerDistrictName
                                                      ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm
                                                          .coreProposalVehicleVO
                                                          .ownerAddress
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-collapse",
                        {
                          staticStyle: { "margin-top": "10px" },
                          model: {
                            value: _vm.activeEight,
                            callback: function ($$v) {
                              _vm.activeEight = $$v
                            },
                            expression: "activeEight",
                          },
                        },
                        [
                          _c(
                            "el-collapse-item",
                            {
                              staticClass: "collapse-item-style",
                              attrs: { name: "1" },
                            },
                            [
                              _c("template", { slot: "title" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "title",
                                    staticStyle: { "line-height": "25px" },
                                  },
                                  [
                                    _c("FormLabel", {
                                      attrs: { "form-label": "关系人信息" },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "el-row",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "flex-direction": "row",
                                    "flex-wrap": "wrap",
                                  },
                                  attrs: { gutter: 0 },
                                },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c("el-form-item", {
                                        staticClass: "beibaoren",
                                        attrs: {
                                          label: "投保人",
                                          "label-width": "80px",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "被保人名称：",
                                            "label-width": "120px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.ApplicantObj.name || "-"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "证件类型：",
                                            "label-width": "120px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.ApplicantObj
                                                  .certificateTypeName || "-"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "证件号：",
                                            "label-width": "120px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.ApplicantObj
                                                  .certificateNo || "-"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "联系电话：",
                                            "label-width": "120px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.ApplicantObj.mobile || "-"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "联系地址：",
                                            "label-width": "120px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.ApplicantObj.address || "-"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "flex-direction": "row",
                                    "flex-wrap": "wrap",
                                  },
                                  attrs: { gutter: 0 },
                                },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c("el-form-item", {
                                        staticClass: "beibaoren",
                                        attrs: {
                                          label: "被保人",
                                          "label-width": "80px",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "被保人名称：",
                                            "label-width": "120px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.InsurantObj.name || "-"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "证件类型：",
                                            "label-width": "120px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.InsurantObj
                                                  .certificateTypeName || "-"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "证件号：",
                                            "label-width": "120px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.InsurantObj.certificateNo ||
                                                  "-"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "联系电话：",
                                            "label-width": "120px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.InsurantObj.mobile || "-"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "联系地址：",
                                            "label-width": "120px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.InsurantObj.address || "-"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-collapse",
                        {
                          staticStyle: { "margin-top": "10px" },
                          model: {
                            value: _vm.activeFour,
                            callback: function ($$v) {
                              _vm.activeFour = $$v
                            },
                            expression: "activeFour",
                          },
                        },
                        [
                          _c(
                            "el-collapse-item",
                            {
                              staticClass: "collapse-item-style",
                              attrs: { name: "1" },
                            },
                            [
                              _c("template", { slot: "title" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "title",
                                    staticStyle: { "line-height": "25px" },
                                  },
                                  [
                                    _c("FormLabel", {
                                      attrs: { "form-label": "权益信息" },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "el-row",
                                {
                                  staticClass: "mh30",
                                  staticStyle: {
                                    display: "flex",
                                    "flex-direction": "row",
                                    "flex-wrap": "wrap",
                                  },
                                  attrs: { gutter: 0 },
                                },
                                [
                                  _c("el-col", { attrs: { span: 8 } }, [
                                    _vm._v(" 保险公司： "),
                                    _c(
                                      "span",
                                      { staticStyle: { color: "#C1272D" } },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.insuranceForm.insCorpName)
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _vm._v(" 投保单号： "),
                                      _c(
                                        "el-link",
                                        {
                                          staticStyle: {
                                            "text-decoration": "underline",
                                          },
                                          attrs: {
                                            type: "primary",
                                            underline: false,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.viewDetail(
                                                _vm.insuranceForm
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.insuranceForm.quoteNo)
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                {
                                  staticClass: "mh30",
                                  staticStyle: {
                                    display: "flex",
                                    "flex-direction": "row",
                                    "flex-wrap": "wrap",
                                  },
                                  attrs: { gutter: 0 },
                                },
                                [
                                  !_vm._.isEmpty(_vm.bsRiskKind)
                                    ? _c("el-col", { attrs: { span: 12 } }, [
                                        _vm._v(" 商业险期间："),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getEffectiveStartTime(
                                                _vm.bsRiskKind
                                                  .effectiveStartTime
                                              )
                                            ) +
                                              " 至 " +
                                              _vm._s(
                                                _vm.getEffectiveEndTime(
                                                  _vm.bsRiskKind
                                                    .effectiveEndTime
                                                )
                                              )
                                          ),
                                        ]),
                                      ])
                                    : _vm._e(),
                                  !_vm._.isEmpty(_vm.bzRiskKind)
                                    ? _c("el-col", { attrs: { span: 12 } }, [
                                        _vm._v(" 交强险期间："),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getEffectiveStartTime(
                                                _vm.bzRiskKind
                                                  .effectiveStartTime
                                              )
                                            ) +
                                              " 至 " +
                                              _vm._s(
                                                _vm.getEffectiveEndTime(
                                                  _vm.bzRiskKind
                                                    .effectiveEndTime
                                                )
                                              )
                                          ),
                                        ]),
                                      ])
                                    : _vm._e(),
                                  !_vm._.isEmpty(_vm.bsRiskKind)
                                    ? _c(
                                        "el-col",
                                        { attrs: { span: 12 } },
                                        [
                                          _vm._v(" 商业险保单号："),
                                          _c(
                                            "el-link",
                                            {
                                              staticStyle: {
                                                "text-decoration": "underline",
                                              },
                                              attrs: {
                                                type: "primary",
                                                underline: false,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.viewPolicy(
                                                    _vm.bsRiskKind
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.bsRiskKind.policyNo || "-"
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  !_vm._.isEmpty(_vm.bzRiskKind)
                                    ? _c(
                                        "el-col",
                                        { attrs: { span: 12 } },
                                        [
                                          _vm._v(" 交强险保单号："),
                                          _c(
                                            "el-link",
                                            {
                                              staticStyle: {
                                                "text-decoration": "underline",
                                              },
                                              attrs: {
                                                type: "primary",
                                                underline: false,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.viewPolicy(
                                                    _vm.bzRiskKind
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.bzRiskKind.policyNo || "-"
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  !_vm._.isEmpty(_vm.detailData)
                                    ? _c("el-col", { attrs: { span: 12 } }, [
                                        _vm._v(
                                          " 服务包名称：" +
                                            _vm._s(
                                              (_vm.detailData &&
                                                _vm.detailData.servPackName) ||
                                                "-"
                                            ) +
                                            " "
                                        ),
                                      ])
                                    : _vm._e(),
                                  !_vm._.isEmpty(_vm.detailData)
                                    ? _c("el-col", { attrs: { span: 12 } }, [
                                        _vm._v(
                                          " 服务包编号：" +
                                            _vm._s(
                                              (_vm.detailData &&
                                                _vm.detailData.servPackCode) ||
                                                "-"
                                            ) +
                                            " "
                                        ),
                                      ])
                                    : _vm._e(),
                                  !_vm._.isEmpty(_vm.detailData)
                                    ? _c("el-col", { attrs: { span: 12 } }, [
                                        _vm._v(
                                          " 服务包订单号：" +
                                            _vm._s(
                                              (_vm.detailData &&
                                                _vm.detailData
                                                  .servPackOrderNo) ||
                                                "-"
                                            ) +
                                            " "
                                        ),
                                      ])
                                    : _vm._e(),
                                  !_vm._.isEmpty(_vm.detailData)
                                    ? _c(
                                        "el-col",
                                        { attrs: { span: 12 } },
                                        [
                                          _vm._v(" 合同编号："),
                                          _c(
                                            "el-link",
                                            {
                                              staticStyle: {
                                                "text-decoration": "underline",
                                              },
                                              attrs: {
                                                type: "primary",
                                                underline: false,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.viewContract(
                                                    _vm.detailData
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  (_vm.detailData &&
                                                    _vm.detailData
                                                      .servPackContractNo) ||
                                                    "-"
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              !_vm._.isEmpty(_vm.bsRiskKind)
                                ? _c(
                                    "el-row",
                                    [
                                      _c("el-col", { attrs: { span: 24 } }, [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "font-size": "14px",
                                              "line-height": "40px",
                                              color: "#808080",
                                            },
                                          },
                                          [_vm._v(" 商业险费用明细 ")]
                                        ),
                                        _c(
                                          "table",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: { border: "1" },
                                          },
                                          [
                                            _c("col", {
                                              staticStyle: { width: "50%" },
                                            }),
                                            _c("col", {
                                              staticStyle: { width: "30%" },
                                            }),
                                            _c("col", {
                                              staticStyle: { width: "20%" },
                                            }),
                                            _c("thead", [
                                              _c(
                                                "tr",
                                                {
                                                  staticStyle: {
                                                    "background-color": "#eeee",
                                                  },
                                                },
                                                [
                                                  _c("th", [
                                                    _vm._v("险种/险别"),
                                                  ]),
                                                  _c(
                                                    "th",
                                                    {
                                                      staticStyle: {
                                                        "text-align": "right",
                                                      },
                                                    },
                                                    [_vm._v("保额/限额")]
                                                  ),
                                                  _c(
                                                    "th",
                                                    {
                                                      staticStyle: {
                                                        "text-align": "right",
                                                      },
                                                    },
                                                    [_vm._v("保费(元)")]
                                                  ),
                                                ]
                                              ),
                                            ]),
                                            !_vm._.isEmpty(
                                              _vm.bsRiskKind
                                                .coreProposalProductDutyVO
                                            )
                                              ? _c(
                                                  "tbody",
                                                  _vm._l(
                                                    _vm.bsRiskKind
                                                      .coreProposalProductDutyVO,
                                                    function (item, index) {
                                                      return _c(
                                                        "tr",
                                                        { key: index },
                                                        [
                                                          _c("td", [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.riskkindName
                                                              )
                                                            ),
                                                          ]),
                                                          _c(
                                                            "td",
                                                            {
                                                              staticStyle: {
                                                                "text-align":
                                                                  "right",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticStyle: {
                                                                    display:
                                                                      "flex",
                                                                    "justify-content":
                                                                      "flex-end",
                                                                  },
                                                                },
                                                                [
                                                                  item.riskkindCode ==
                                                                  "A"
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              display:
                                                                                "flex",
                                                                              "justify-content":
                                                                                "space-between",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  "text-align":
                                                                                    "left",
                                                                                  display:
                                                                                    "block",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  item.amount ||
                                                                                    0
                                                                                ) +
                                                                                  " 元"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  "text-align":
                                                                                    "right",
                                                                                  display:
                                                                                    "block",
                                                                                  "margin-left":
                                                                                    "10px",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "绝对免赔额：" +
                                                                                  _vm._s(
                                                                                    item.absoluteDeductibleAmount ||
                                                                                      0
                                                                                  ) +
                                                                                  " 元"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  item.riskkindCode ==
                                                                    "D" ||
                                                                  item.riskkindCode ==
                                                                    "D02" ||
                                                                  (item.riskkindCode ==
                                                                    "D03" &&
                                                                    !item.shareAmount)
                                                                    ? _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              (item.unitAmount >
                                                                              0
                                                                                ? _vm.formatOption(
                                                                                    item.unitAmount
                                                                                  )
                                                                                : "0") +
                                                                                "元" +
                                                                                " × " +
                                                                                item.quantity +
                                                                                "座 "
                                                                            )
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm._.indexOf(
                                                                    [
                                                                      "FZ01",
                                                                      "FZ02",
                                                                      "FZ03",
                                                                      "FZ04",
                                                                    ],
                                                                    item.riskkindCode
                                                                  ) != -1
                                                                    ? _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.getQuantity(
                                                                                item
                                                                              ) +
                                                                                "次"
                                                                            )
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm._.indexOf(
                                                                    [
                                                                      "A04",
                                                                      "A05",
                                                                      "D",
                                                                      "A",
                                                                      "FZ01",
                                                                      "FZ02",
                                                                      "FZ03",
                                                                      "FZ04",
                                                                      "B05",
                                                                      "C02",
                                                                      "A02",
                                                                      "A08",
                                                                      "D02",
                                                                      "D03",
                                                                      "A11",
                                                                      "C03",
                                                                      "B04",
                                                                    ],
                                                                    item.riskkindCode
                                                                  ) == -1 ||
                                                                  ([
                                                                    "C03",
                                                                    "B04",
                                                                  ].includes(
                                                                    item.riskkindCode
                                                                  ) &&
                                                                    !item.shareAmount)
                                                                    ? _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              item.absoluteDeductible
                                                                                ? item.absoluteDeductible +
                                                                                    "%"
                                                                                : item.amount
                                                                                ? _vm.formatOption(
                                                                                    item.amount
                                                                                  ) +
                                                                                  "元"
                                                                                : item.amount +
                                                                                  "元"
                                                                            )
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  item.riskkindCode ==
                                                                  "A04"
                                                                    ? _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              (item.unitAmount >
                                                                              0
                                                                                ? _vm.formatOption(
                                                                                    item.unitAmount
                                                                                  )
                                                                                : "0") +
                                                                                "元" +
                                                                                " × " +
                                                                                item.quantity +
                                                                                "天"
                                                                            )
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  item.riskkindCode ==
                                                                  "A05"
                                                                    ? _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              (item.absoluteDeductible ||
                                                                                "0") +
                                                                                "%"
                                                                            )
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  item.riskkindCode ==
                                                                    "B05" ||
                                                                  item.riskkindCode ==
                                                                    "C02" ||
                                                                  item.riskkindCode ==
                                                                    "A11"
                                                                    ? _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              (item.unitAmount >
                                                                              0
                                                                                ? _vm.formatOption(
                                                                                    item.unitAmount
                                                                                  )
                                                                                : "0") +
                                                                                "元"
                                                                            )
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  item.riskkindCode ==
                                                                  "A02"
                                                                    ? _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              item.unitAmount.toFixed(
                                                                                2
                                                                              ) +
                                                                                "元"
                                                                            )
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  [
                                                                    "C03",
                                                                    "D03",
                                                                    "B04",
                                                                  ].includes(
                                                                    item.riskkindCode
                                                                  ) &&
                                                                  item.shareAmount
                                                                    ? _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "共享保额"
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  item.riskkindCode ==
                                                                  "A08"
                                                                    ? _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "同车损保额"
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "td",
                                                            {
                                                              staticStyle: {
                                                                "text-align":
                                                                  "right",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.actualPremium
                                                                    ? item.actualPremium.toFixed(
                                                                        2
                                                                      )
                                                                    : "0.00"
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              !_vm._.isEmpty(_vm.nonRiskKind)
                                ? _c(
                                    "el-row",
                                    [
                                      _c("el-col", { attrs: { span: 24 } }, [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "font-size": "14px",
                                              "line-height": "40px",
                                              color: "#808080",
                                            },
                                          },
                                          [_vm._v(" 驾意险费用明细 ")]
                                        ),
                                        !_vm._.isEmpty(_vm.nonRiskKind) &&
                                        !_vm._.isEmpty(
                                          _vm.nonRiskKind.proposalRiskAccidentVO
                                        )
                                          ? _c(
                                              "table",
                                              {
                                                staticStyle: { width: "100%" },
                                                attrs: { border: "1" },
                                              },
                                              _vm._l(
                                                _vm.nonRiskKind
                                                  .proposalRiskAccidentVO,
                                                function (item, index) {
                                                  return _c(
                                                    "tbody",
                                                    { key: index },
                                                    [
                                                      _c("tr", [
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              "text-align":
                                                                "center",
                                                              background:
                                                                "#eee",
                                                              "font-weight":
                                                                "bold",
                                                            },
                                                            attrs: {
                                                              colspan: "7",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.categoryName
                                                                ) +
                                                                " - " +
                                                                _vm._s(
                                                                  item.categoryTypeName
                                                                ) +
                                                                " - " +
                                                                _vm._s(
                                                                  item.productName
                                                                ) +
                                                                ", 共:" +
                                                                _vm._s(
                                                                  item.quantity
                                                                ) +
                                                                "份,保费共:" +
                                                                _vm._s(
                                                                  parseInt(
                                                                    item.quantity
                                                                  ) *
                                                                    parseInt(
                                                                      item.unitPremium
                                                                    )
                                                                ) +
                                                                " 元 "
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                      !_vm._.isEmpty(
                                                        item.detailList
                                                      )
                                                        ? _c("tr", [
                                                            _c(
                                                              "td",
                                                              {
                                                                staticStyle: {
                                                                  padding: "0",
                                                                },
                                                                attrs: {
                                                                  colspan: "7",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "table",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        width:
                                                                          "100%",
                                                                      },
                                                                    attrs: {
                                                                      border:
                                                                        "0",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "thead",
                                                                      [
                                                                        _c(
                                                                          "tr",
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                "background-color":
                                                                                  "#eeee",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "th",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    scope:
                                                                                      "col",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "险种名称"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "th",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    "text-align":
                                                                                      "right",
                                                                                  },
                                                                                attrs:
                                                                                  {
                                                                                    scope:
                                                                                      "col",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "保险金额(元)"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "tbody",
                                                                      _vm._l(
                                                                        item.detailList,
                                                                        function (
                                                                          ditem,
                                                                          dindex
                                                                        ) {
                                                                          return _c(
                                                                            "tr",
                                                                            {
                                                                              key: dindex,
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "td",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      ditem.name
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "td",
                                                                                {
                                                                                  staticStyle:
                                                                                    {
                                                                                      "text-align":
                                                                                        "right",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      ditem.amount
                                                                                        ? ditem.amount.toFixed(
                                                                                            2
                                                                                          )
                                                                                        : "0.00"
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          )
                                                                        }
                                                                      ),
                                                                      0
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ])
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          : _vm._e(),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              !(
                                _vm._.isEmpty(_vm.bsRiskKind) &&
                                _vm._.isEmpty(_vm.bzRiskKind) &&
                                _vm._.isEmpty(_vm.nonRiskKind)
                              )
                                ? _c(
                                    "el-row",
                                    [
                                      _c("el-col", { attrs: { span: 24 } }, [
                                        _c(
                                          "table",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: { border: "1" },
                                          },
                                          [
                                            _c("col", {
                                              staticStyle: { width: "50%" },
                                            }),
                                            _c("col", {
                                              staticStyle: { width: "30%" },
                                            }),
                                            _c("col", {
                                              staticStyle: { width: "20%" },
                                            }),
                                            _c("tbody", [
                                              _c(
                                                "tr",
                                                {
                                                  staticStyle: {
                                                    "background-color": "#eeee",
                                                  },
                                                },
                                                [
                                                  _c("td", {
                                                    staticStyle: {
                                                      height: "20px",
                                                    },
                                                    attrs: { colspan: "3" },
                                                  }),
                                                ]
                                              ),
                                              !_vm._.isEmpty(_vm.bsRiskKind)
                                                ? _c("tr", [
                                                    _c(
                                                      "td",
                                                      {
                                                        attrs: { colspan: "3" },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              display: "flex",
                                                              "justify-content":
                                                                "space-between",
                                                            },
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                "商业险保费合计"
                                                              ),
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.bsRiskKind
                                                                    .actualPremium
                                                                    ? _vm.bsRiskKind.actualPremium.toFixed(
                                                                        2
                                                                      )
                                                                    : "0.00"
                                                                ) + "元"
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              !_vm._.isEmpty(_vm.bzRiskKind)
                                                ? _c("tr", [
                                                    _c(
                                                      "td",
                                                      {
                                                        attrs: { colspan: "3" },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              display: "flex",
                                                              "justify-content":
                                                                "space-between",
                                                            },
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                "交强险保费合计"
                                                              ),
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.bzRiskKind
                                                                    .actualPremium
                                                                    ? _vm.bzRiskKind.actualPremium.toFixed(
                                                                        2
                                                                      )
                                                                    : "0.00"
                                                                ) + "元"
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              !_vm._.isEmpty(_vm.bzRiskKind)
                                                ? _c("tr", [
                                                    _c(
                                                      "td",
                                                      {
                                                        attrs: { colspan: "3" },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              display: "flex",
                                                              "justify-content":
                                                                "space-between",
                                                            },
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                "车船税税款合计"
                                                              ),
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.bzRiskKind
                                                                    .vehicleTaxPremium
                                                                    ? _vm.bzRiskKind.vehicleTaxPremium.toFixed(
                                                                        2
                                                                      )
                                                                    : "0.00"
                                                                ) + "元"
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              !_vm._.isEmpty(_vm.nonRiskKind)
                                                ? _c("tr", [
                                                    _c(
                                                      "td",
                                                      {
                                                        attrs: { colspan: "3" },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              display: "flex",
                                                              "justify-content":
                                                                "space-between",
                                                            },
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                "驾意险保费合计"
                                                              ),
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm
                                                                    .nonRiskKind
                                                                    .actualPremium
                                                                    ? _vm.nonRiskKind.actualPremium.toFixed(
                                                                        2
                                                                      )
                                                                    : "0.00"
                                                                ) + "元"
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              _c("tr", [
                                                _c(
                                                  "td",
                                                  { attrs: { colspan: "3" } },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          display: "flex",
                                                          "justify-content":
                                                            "space-between",
                                                        },
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            "总保费及税款合计"
                                                          ),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.insuranceForm
                                                                .totalActualPremium
                                                                ? _vm.insuranceForm.totalActualPremium.toFixed(
                                                                    2
                                                                  )
                                                                : "0.00"
                                                            ) + "元"
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                            ]),
                                          ]
                                        ),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "el-row",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "flex-direction": "row",
                                    "flex-wrap": "wrap",
                                    "margin-top": "20px",
                                  },
                                  attrs: { gutter: 0 },
                                },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-table",
                                        {
                                          ref: "multipleTable",
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            size: "mini",
                                            border: "",
                                            data: _vm.couponList,
                                            "header-cell-style": {
                                              background: "#F7F7F7",
                                            },
                                            "highlight-current-row": "",
                                          },
                                        },
                                        [
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "itemCode",
                                              label: "产品编码",
                                              "min-width": "120",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "itemName",
                                              label: "产品名称",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "itemRemark",
                                              label: "权益说明",
                                              "min-width": "200",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              prop: "itemAmount",
                                              label: "有限期(天)",
                                              "show-overflow-tooltip": "",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ]),
        _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
          _c("div", { staticClass: "footer-btn" }, [
            _c(
              "div",
              { staticClass: "footer-btn-list" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { size: "mini", icon: "el-icon-back" },
                    on: { click: _vm.goBack },
                  },
                  [_vm._v(" 返回 ")]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }