import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import Pagination from '@/components/Pagination';
import giftApi from '@/api/giftManagement';
import GiftMaintainDialog from './dialog/giftMaintainDialog';
import { commonExport } from '@/utils/common';
export default {
  name: 'GiftMaintain',
  components: {
    Pagination: Pagination,
    GiftMaintainDialog: GiftMaintainDialog
  },
  mixins: [initHeight],
  data: function data() {
    return {
      searchForm: {
        enable: null
      },
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      loading: false,
      drawer: false,
      showAddDialog: false,
      multipleSelection: [],
      opType: '',
      currentId: '',
      statusList: [{
        value: null,
        label: '全部'
      }, {
        value: true,
        label: '是'
      }, {
        value: false,
        label: '否'
      }]
    };
  },
  created: function created() {
    this.loadData();
  },
  methods: {
    add: function add(type) {
      //安装
      this.opType = type;
      this.showAddDialog = true;
    },
    edit: function edit(type, item) {
      //编辑
      this.opType = type;
      this.currentId = item.cardVoucherId.toString();
      this.showAddDialog = true;
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {
        enable: null
      };
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    // 刷新
    breakData: function breakData() {
      // this.resetSearch();
      this.loadData();
    },
    loadData: function loadData() {
      var _this = this;
      var isDialogNoRefresh = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      //获取列表数据
      this.showAddDialog = false;
      if (isDialogNoRefresh) return;
      this.loading = true;
      this.drawer = false;
      var data = _objectSpread(_objectSpread({}, this.listQuery), this.searchForm);
      giftApi.getGiftMaintainList(data).then(function (res) {
        _this.loading = false;
        if (res.code === 200) {
          _this.tableData = res.data.list;
          _this.total = res.data.page.recordsTotal;
        }
      }).catch(function (err) {
        _this.loading = false;
      });
    },
    exportExcels: function exportExcels() {
      //导出
      var exporParams = {
        total: this.total,
        fileName: '赠品维护',
        exportUrl: '/oc-core-service/cardVoucher',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    handleSelectionChange: function handleSelectionChange(val) {
      //列表行选择事件
      this.multipleSelection = val;
    },
    batchDelete: function batchDelete(item) {
      var _this2 = this;
      //删除
      var ids = [];
      if (!_.isEmpty(item)) {
        ids.push(item.cardVoucherId);
      } else {
        if (!_.isEmpty(this.multipleSelection)) {
          ids = _.map(this.multipleSelection, 'cardVoucherId');
        }
        ;
      }
      ;
      this.$confirm('您确定要删除么？', {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        giftApi.deleteGiftMaintain(ids).then(function (res) {
          if (res.code === 200) {
            _this2.$message.success(res.msg);
            _this2.loadData();
          }
          ;
        });
      });
    },
    // 是否启用
    enableChange: function enableChange(val) {
      var _this3 = this;
      this.$confirm("\u60A8\u786E\u5B9A\u8981".concat(val.enable ? '禁用' : '启用', "\u8BE5\u8D60\u54C1\u5417\uFF1F"), {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        type: 'warning'
      }).then(function () {
        val.enable = !val.enable;
        giftApi.editGiftMaintain(val.cardVoucherId, val).then(function (res) {
          if (res.code === 200) {
            _this3.$message.success(res.msg);
            _this3.loadData();
          }
          ;
        });
      }).catch(function () {});
    }
  }
};