import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.find.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import requestOrg from '@/api/orgChildren';
export default {
  props: {
    // 是否获取机构人员列表
    isGetUserList: {
      type: Boolean,
      default: false
    },
    // 树节点是选中项
    searchForm: {
      type: Object,
      default: {
        salesOrgCode: '',
        salesOrgName: '',
        salesOrgType: ''
      },
      required: true
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false
    },
    // 是否清空
    clearable: {
      type: Boolean,
      default: false
    },
    // 是否可选择区域
    canClickArea: {
      type: Boolean,
      default: false
    },
    // 是否包含主机厂
    isContainOem: {
      type: Boolean,
      default: true
    },
    // 是否默认带出全部经理
    isGetDealerList: {
      type: Boolean,
      default: false
    },
    // 树节点是否支持多选
    isMultiple: {
      type: Boolean,
      default: false
    },
    // 多选回显
    salesOemName: {
      type: String,
      default: ''
    },
    // 多选勾选
    defaultCode: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    // 是否过滤二网
    isSecondNet: {
      type: Boolean,
      default: false
    },
    // 是否报价页面
    isQuotePage: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      filterText: '',
      orgData: [],
      defaultProps: {
        children: 'child',
        label: 'name',
        id: 'id',
        disabled: 'type'
      },
      treeExpandData: [],
      provincialCenterId: '',
      highlightCurrent: true,
      areaTypes: '',
      userInfo: JSON.parse(localStorage.getItem("userInfo")) || {},
      showOrgName: '',
      showPop: false
    };
  },
  watch: {
    filterText: function filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  mounted: function mounted() {
    this.initOrgData();
  },
  methods: {
    getRoleTreeRootNode: function getRoleTreeRootNode(provincialCenterId) {
      this.treeExpandData.push(provincialCenterId);
    },
    // 获取机构人员列表
    getUsers: function getUsers(orgCode, type) {
      var _this = this;
      var getPersonRequestData = {};
      // console.log('this.isQuotePage', this.isQuotePage);
      // 车险报价、保单录入页面需要判断是否已离职
      if (this.isQuotePage) {
        getPersonRequestData = {
          dealerCode: orgCode,
          employeeType: 'SALESMAN',
          employeeStatus: 'REGULAR'
        };
      } else {
        getPersonRequestData = {
          dealerCode: orgCode
        };
      }
      ;
      var request = this.canClickArea ? requestOrg.getOemAndAreaUserList({
        areaId: orgCode,
        areaType: type
      }) : requestOrg.getOrgPersonForQuote(getPersonRequestData);
      request.then(function (result) {
        var data = result.data || [];
        _this.$emit('getCurrentOrgUsers', data);
      });
    },
    joinNameId: function joinNameId(data) {
      var _this2 = this;
      data.forEach(function (val) {
        if (val.child) {
          val.name = val.type ? "".concat(val.name) : "".concat(val.name, "-").concat(val.id);
          _this2.joinNameId(val.child);
        } else {
          val.name = val.type ? "".concat(val.name) : "".concat(val.name, "-").concat(val.id);
        }
      });
    },
    // 初始化主机厂机构树
    initOrgData: function initOrgData() {
      var _this3 = this;
      this.showOrgName = this.salesOemName;
      var params = {};
      if (this.isSecondNet) {
        params = {
          dealerType: 'LEVEL_1',
          unInvalid: true
        };
      }
      ;
      var req = this.isSecondNet ? requestOrg.getSecondNetTree(this.userInfo.orgCode, params) : requestOrg.getOemTree(this.userInfo.orgCode);
      req.then(function (res) {
        if (res.data) {
          _this3.joinNameId(res.data);
          if (_this3.isContainOem) {
            _this3.orgData = [{
              id: '',
              name: '全部',
              child: res.data,
              type: null,
              isOem: true
            }];
            //带出默认值 isGetDealerList
            if (!_this3.searchForm.salesOrgName) {
              _this3.searchForm.salesOrgName = _this3.orgData[0].name;
            }
            if (!_this3.searchForm.salesOrgCode) {
              _this3.searchForm.salesOrgCode = _this3.orgData[0].id;
            }
            _this3.provincialCenterId = _this3.orgData[0].id;
            _this3.getRoleTreeRootNode(_this3.provincialCenterId);
            _this3.$forceUpdate();
            if (_this3.isGetDealerList) {
              _this3.areaTypes = _this3.orgData[0].type;
              _this3.getUsers(_this3.provincialCenterId, _this3.areaTypes);
            }
          } else {
            _this3.orgData = res.data;
            if (!_this3.searchForm.salesOrgCode) {
              _this3.searchForm.salesOrgCode = _this3.orgData[0].id;
            }
          }
        }
      });
    },
    // 组织机构树选择事件
    handleClear: function handleClear(val) {
      this.searchForm.salesOrgName = null;
      this.searchForm.salesOrgCode = null;
      this.searchForm.salesOrgType = null;
      this.$emit('nodeClick', this.searchForm);
    },
    // 机构树点击事件
    handleNodeClick: function handleNodeClick(data, node, target) {
      var VI_CityAreaTree = JSON.parse(localStorage.getItem('VI_CityAreaTree'));
      var cityNameByCityCode = '';
      if (VI_CityAreaTree && data) {
        var provinceObj = _.find(VI_CityAreaTree, function (item) {
          return item.value == data.provinceCode;
        });
        if (provinceObj && provinceObj.children) {
          var cityObj = _.find(provinceObj.children, function (item) {
            return item.value == data.cityCode;
          });
          cityNameByCityCode = cityObj && cityObj.label;
        }
      }
      if (this.canClickArea) {
        // this.handleClear();
        this.searchForm.salesOrgCode = data.id;
        this.searchForm.salesOrgName = data.name;
        this.searchForm.salesOrgType = data.type;
        if (this.isGetUserList) {
          this.getUsers(this.searchForm.salesOrgCode, this.searchForm.salesOrgType);
        }
        this.$refs.orgSelect.blur();
        this.$emit('nodeClick', _objectSpread(_objectSpread({}, this.searchForm), {}, {
          bizType: data.bizType,
          cityCode: data.cityCode,
          cityName: data.cityName !== '市辖区' ? data.cityName : cityNameByCityCode
        }));
        this.$forceUpdate();
      } else {
        if (!data.type) {
          // this.handleClear();
          this.searchForm.salesOrgCode = data.id;
          this.searchForm.salesOrgName = data.name;
          if (this.isGetUserList) {
            this.getUsers(this.searchForm.salesOrgCode);
          }
          this.$refs.orgSelect.blur();
          this.$emit('nodeClick', _objectSpread(_objectSpread({}, this.searchForm), {}, {
            bizType: data.bizType,
            cityCode: data.cityCode,
            cityName: data.cityName !== '市辖区' ? data.cityName : cityNameByCityCode
          }));
          this.$forceUpdate();
        }
      }
    },
    // 多选确定
    handleCheckChange: function handleCheckChange() {
      this.showPop = false;
      //获取选中的树节点
      var data = this.$refs.tree.getCheckedNodes();
      if (!_.isEmpty(data)) {
        if (data.length > 1) {
          this.showOrgName = data[0].name + ' 等' + data.length + '家';
        } else {
          this.showOrgName = data[0].name;
        }
        var _names = [];
        var _codes = [];
        data.forEach(function (element) {
          _names.push(element.name);
          _codes.push(element.id);
        });
        this.searchForm.salesOrgName = _names;
        this.searchForm.salesOrgCode = _codes;
      } else {
        this.showOrgName = '';
        this.searchForm.salesOrgName = '';
        this.searchForm.salesOrgCode = '';
      }
      this.$emit('nodeClick', this.searchForm);
    },
    // 机构树查询
    filterNode: function filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    // 机构树查询
    dataFilter: function dataFilter(val) {
      this.$refs.tree.filter(val);
    },
    // 组织机构树焦点事件
    orgFocus: function orgFocus(val) {
      if (this.$refs.orgSelect && (this.$refs.orgSelect.query || this.$refs.orgSelect.query == '')) {
        this.$refs.tree.filter('');
        this.$forceUpdate();
      }
    }
  }
};