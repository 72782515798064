import _createForOfIteratorHelper from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FormLabel from "@/components/FormSubLabel";
import DynamicColumn from "@/components/DynamicColumn";
import connectReq from "@/api/connectConfig";
import PageContainer from "@/components/PageContainer";
import dict from "@/mixins/dict";
import Dialog from "@/components/Dialog";
import Pagination from "@/components/Pagination";
import NonAutoRequest from '@/api/nonAutoRequest';
import { getSupplyers, getCityItem, getProvinceItem } from "@/utils/common";
export default {
  name: "ChannelConfigDetail",
  components: {
    FormLabel: FormLabel,
    DynamicColumn: DynamicColumn,
    PageContainer: PageContainer,
    Dialog: Dialog,
    Pagination: Pagination
  },
  mixins: [dict],
  computed: {
    sidebar: function sidebar() {
      return this.$store.state.app.sidebar;
    }
  },
  watch: {
    sidebar: {
      handler: function handler(newValue, oldValue) {
        this.sidebarOpened = newValue.opened;
        this.calcWidth();
      },
      deep: true
    }
  },
  data: function data() {
    return {
      supplierProductConfigId: '',
      SupplierConfigList: [],
      SupplierList: [],
      ProductClassifyList: [],
      SupplierConfigChangeId: '',
      supplierChangeCode: '',
      productClassifyChangeCode: '',
      cityOptions: [],
      searchForm: {},
      form: {
        configInfo: "",
        enabled: true,
        productClassifyCode: "",
        productClassifyName: "",
        supplierCode: "",
        supplierName: "",
        supplierServiceConfigId: ''
      },
      opType: "",
      channelId: "",
      formWidth: 0,
      resData: {},
      apiConfigs: [],
      rules: {
        insChannelConfig: {},
        insCorpCode: [{
          required: true,
          message: "请选择保险公司",
          trigger: "change"
        }],
        insChannelName: [{
          required: true,
          message: "请输入渠道工号名称",
          trigger: "blur"
        }],
        insCityCode: [{
          required: true,
          message: "请选择投保城市",
          trigger: "change"
        }],
        apiConfigId: [{
          required: true,
          message: "请选择接口配置",
          trigger: "change"
        }],
        tenantId: [{
          required: true,
          message: "请选择租户",
          trigger: "change"
        }]
      },
      API_CONFIG_TYPE: [],
      supplyers: [],
      showDialog: false,
      tableData: [],
      multipleSelection: [],
      listQuery: {
        pageNum: 1,
        pageSize: 5
      },
      total: 0,
      headerCollapse: false,
      tenantData: [{
        tenantId: '100001',
        tenantName: '默认租户'
      }],
      cityList: [],
      DetailConfigInfo: {},
      formLoading: false
    };
  },
  created: function created() {
    this.getSupplierServiceConfigList();
    // this.cityList = JSON.parse(localStorage.getItem("VI_CityPcTree")) || [];
    this.opType = this.$route.query.type;
    // this.cityOptions = JSON.parse(localStorage.getItem("VI_CityPcTree")) || [];
    // this.API_CONFIG_TYPE = this.dictSource["API_CONFIG_TYPE"];
    // this.supplyers = getSupplyers();
    if (this.opType == "update" || this.opType == "view") {
      this.supplierProductConfigId = this.$route.query.supplierProductConfigId;
      this.getDetail(this.supplierProductConfigId);
    }
  },
  mounted: function mounted() {
    this.sidebarOpened = this.sidebar.opened;
    this.calcWidth();
  },
  methods: {
    DetailgetSupplierProductSchemaList: function DetailgetSupplierProductSchemaList(data) {
      var _this = this;
      NonAutoRequest.getSupplierProductSchemaList(data).then(function (res) {
        if (res.code == 200) {
          var resData = res.data && _.cloneDeep(res.data);
          if (resData.items) {
            var _iterator = _createForOfIteratorHelper(resData.items),
              _step;
            try {
              for (_iterator.s(); !(_step = _iterator.n()).done;) {
                var i = _step.value;
                for (var j in _this.DetailConfigInfo) {
                  if (i.storeKey == j) {
                    i.storeValue = _this.DetailConfigInfo[j];
                  }
                }
              }
            } catch (err) {
              _iterator.e(err);
            } finally {
              _iterator.f();
            }
          }
          _this.form.configInfo = resData;
        }
      });
    },
    getDetail: function getDetail(id) {
      var _this2 = this;
      //获取详情
      NonAutoRequest.getSupplierProductDetail(id).then(function (res) {
        // console.log('获取详情', res)
        if (res.code == 200) {
          var resData = res.data && _.cloneDeep(res.data);
          // resData.configInfo = resData.configInfo ? JSON.parse(resData.configInfo) : null;
          // let configInfo = JSON.parse(resData.configInfo)
          _this2.DetailConfigInfo = JSON.parse(resData.configInfo);
          _this2.form.configInfo = '';
          if (resData.supplierServiceConfigId) {
            _this2.getSupplierProductList(resData.supplierServiceConfigId);
            _this2.SupplierConfigChangeId = resData.supplierServiceConfigId ? resData.supplierServiceConfigId : '';
            _this2.supplierChangeCode = resData.supplierCode ? resData.supplierCode : '';
            _this2.productClassifyChangeCode = resData.productClassifyCode ? resData.productClassifyCode : '';
            var data = {
              id: resData.supplierServiceConfigId ? resData.supplierServiceConfigId : '',
              supplierCode: resData.supplierCode ? resData.supplierCode : '',
              productClassifyCode: resData.productClassifyCode ? resData.productClassifyCode : ''
            };
            _this2.DetailgetSupplierProductSchemaList(data);
            _this2.form = resData ? resData : {};
          } else {
            _this2.form = resData ? resData : {};
            _this2.form.configInfo = '';
          }
        } else {
          _this2.formLoading = false;
        }
      }).catch(function (err) {
        _this2.formLoading = false;
      });
    },
    getSupplierServiceConfigList: function getSupplierServiceConfigList() {
      var _this3 = this;
      NonAutoRequest.getSupplierServiceConfigList().then(function (res) {
        if (res.code == 200) {
          _this3.SupplierConfigList = res.data ? res.data : [];
        }
      });
    },
    getCityName: function getCityName(code) {
      //获取保司中文名称
      var cityItem = getCityItem(code);
      var name = cityItem ? cityItem.label : '';
      if (!name) {
        cityItem = getProvinceItem(code);
        name = cityItem ? cityItem.label : '';
      }
      if (!name) {
        var provinces = JSON.parse(localStorage.getItem('VI_CityPcTree')) || [];
        provinces.forEach(function (province) {
          if (province.value == code) {
            name = province.label;
          }
          if (province && !_.isEmpty(province.children)) {
            province.children.forEach(function (pitem) {
              if (pitem.value == code) {
                name = pitem.label;
              }
              if (pitem && !_.isEmpty(pitem.children)) {
                pitem.children.forEach(function (araItem) {
                  if (araItem.value == code) {
                    name = pitem.label + araItem.label;
                  }
                });
              }
            });
          }
        });
      }
      return name ? name + '(' + code + ')' : code;
    },
    collapse: function collapse() {
      //收缩或展开
      this.headerCollapse = !this.headerCollapse;
    },
    handleSelectionChange: function handleSelectionChange(val) {
      //收缩或展开
      if (!val) return;
      val.checked = true;
      this.tableData.forEach(function (item) {
        if (item.platformConfigId !== val.platformConfigId) {
          item.checked = false;
        }
      });
      // 如果使用单选框,这里可以把当前选中的这一项先保存起来
      this.multipleSelection = val;
    },
    SupplierConfigChange: function SupplierConfigChange(val) {
      //供应商服务配置
      if (!val) {
        this.SupplierConfigChangeId = '';
        this.supplierChangeCode = '';
        this.productClassifyChangeCode = '';
        this.form.configInfo = '';
      } else {
        this.SupplierConfigChangeId = val;
        this.getSupplierProductList(val);
      }
    },
    getSupplierProductList: function getSupplierProductList(id) {
      var _this4 = this;
      NonAutoRequest.getSupplierProductList(id).then(function (res) {
        if (res.code == 200) {
          if (res.data && res.data.supplierList) {
            _this4.SupplierList = res.data.supplierList ? res.data.supplierList : [];
          }
          if (res.data && res.data.productClassifyList) {
            _this4.ProductClassifyList = res.data.productClassifyList ? res.data.productClassifyList : [];
          }
        } else {
          _this4.SupplierList = [];
          _this4.ProductClassifyList = [];
          _this4.supplierChangeCode = '';
          _this4.productClassifyChangeCode = '';
          _this4.form.configInfo = '';
        }
      });
    },
    SupplierChange: function SupplierChange(val) {
      //供应商
      if (!val) {
        this.supplierChangeCode = '';
        this.productClassifyChangeCode = '';
        this.form.configInfo = '';
      } else {
        this.supplierChangeCode = val;
        var supplierItem = _.find(this.SupplierList, function (i) {
          return i.supplierCode == val;
        });
        this.form.supplierName = supplierItem.supplierName;
        if (this.productClassifyChangeCode) {
          this.getSupplierProductSchemaList(val);
        }
      }
    },
    ProductClassifyChange: function ProductClassifyChange(val) {
      //供应商
      if (!val) {
        this.productClassifyChangeCode = '';
        this.form.configInfo = '';
      } else {
        this.productClassifyChangeCode = val;
        var ProductClassifyItem = _.find(this.ProductClassifyList, function (i) {
          return i.productClassifyCode == val;
        });
        this.form.productClassifyName = ProductClassifyItem.productClassifyName;
        if (this.supplierChangeCode) {
          this.getSupplierProductSchemaList(val);
        }
      }
    },
    getSupplierProductSchemaList: function getSupplierProductSchemaList() {
      var _this5 = this;
      var data = {
        id: this.SupplierConfigChangeId ? this.SupplierConfigChangeId : '',
        supplierCode: this.supplierChangeCode ? this.supplierChangeCode : '',
        productClassifyCode: this.productClassifyChangeCode ? this.productClassifyChangeCode : ''
      };
      NonAutoRequest.getSupplierProductSchemaList(data).then(function (res) {
        if (res.code == 200) {
          _this5.form.configInfo = res.data ? res.data : null;
        }
      });
    },
    changeValue: function changeValue() {
      //刷新
      this.$forceUpdate();
    },
    inputValue: function inputValue() {
      //刷新
      this.$forceUpdate();
    },
    calcWidth: function calcWidth() {
      //动态设置字段宽度
      var width = document.body.clientWidth;
      width = width - (this.sidebarOpened ? 210 : 54) - 30 - 20;
      var w = parseInt(width / 3);
      this.formWidth = width + 50;
    },
    submit: function submit(item) {
      var _this6 = this;
      //表单提交
      // 验证表单
      this.$refs["form"].validate(function (valid) {
        if (valid) {
          _this6.formLoading = true;
          var postData = _.cloneDeep(_this6.form);
          if (_this6.opType == "update") {
            postData.supplierProductConfigId = _this6.supplierProductConfigId ? _this6.supplierProductConfigId : '';
          }
          var obj = {};
          if (postData.configInfo.items) {
            var _iterator2 = _createForOfIteratorHelper(postData.configInfo.items),
              _step2;
            try {
              for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
                var i = _step2.value;
                obj[i.storeKey] = i.storeValue;
              }
            } catch (err) {
              _iterator2.e(err);
            } finally {
              _iterator2.f();
            }
          }
          postData.configInfo = JSON.stringify(obj);
          console.log(postData);
          // return
          var localRequest = _this6.opType == "update" ? NonAutoRequest.supplierProductConfigUpdate(postData) : NonAutoRequest.supplierProductConfigAdd(postData);
          localRequest.then(function (res) {
            if (res.code == RESPONSE_SUCCESS) {
              _this6.$message({
                type: 'success',
                message: res.msg
              });
              _this6.goBack();
            }
            _this6.formLoading = false;
          }).catch(function (err) {
            _this6.formLoading = false;
          });
        }
      });
    },
    goBack: function goBack() {
      //返回上一页
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.replace({
        name: 'NonVehicleSupplierProductConfig'
      });
    }
  }
};