import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import request from '@/api/finance';
import Pagination from '@/components/Pagination';
import initHeight from '@/mixins/initHeight';
export default {
  components: {
    Pagination: Pagination
  },
  mixins: [initHeight],
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    currentItem: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      tabsName: '1',
      costTableData: [],
      pushRedTableData: [],
      form: {
        settleAmount: null,
        reviewRemark: null,
        reviewStatus: null,
        settlementId: null
      },
      formLoading: false,
      searchForm: {
        settlementId: null,
        insurantName: null,
        plateNo: null,
        policyNo: null
      },
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      loading: false
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
      ;
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    handleTabClick: function handleTabClick(tab) {
      switch (tab.name) {
        case '1':
          // // 费用明细
          // this.total = 0;
          // this.listQuery = {
          //     pageNum: 1,
          //     pageSize: 20
          // };
          // this.costTableData = [];
          break;
        case '2':
        // // 冲红明细
        // this.pushRedTableData = [];
        // break;
      }
    },
    initData: function initData() {
      //初始化数据
      this.form = {
        settleAmount: this.currentItem.settleAmount,
        reviewRemark: this.currentItem.reviewRemark,
        reviewStatus: null,
        settlementId: this.currentItem.settlementId
      };
      this.searchForm.settlementId = this.currentItem.settlementId;
      this.getCostTableData();
    },
    getCostTableData: function getCostTableData() {
      var _this = this;
      this.loading = true;
      var data = _objectSpread(_objectSpread({}, this.listQuery), this.searchForm);
      request.searchFinanceDetail(data).then(function (res) {
        _this.loading = false;
        if (res.code === 200) {
          _this.costTableData = res.data.list;
          _this.total = res.data.page.recordsTotal;
        }
        ;
      }).catch(function (err) {
        _this.loading = false;
      });
      ;
    },
    save: function save() {
      var _this2 = this;
      //保存数据
      this.formLoading = true;
      request.reviewSettlement(this.form).then(function (res) {
        _this2.formLoading = false;
        if (res.code === 200) {
          _this2.$message.success(res.msg);
          _this2.closePage();
        }
        ;
      }).catch(function (err) {
        _this2.formLoading = false;
      });
      ;
    },
    repulse: function repulse() {
      var _this3 = this;
      // 打回
      this.formLoading = true;
      request.rejectSettlement(this.searchForm.settlementId, this.form.reviewRemark || '').then(function (res) {
        _this3.formLoading = false;
        if (res.code === 200) {
          _this3.$message.success(res.msg);
          _this3.closePage();
        }
        ;
      }).catch(function (err) {
        _this3.formLoading = false;
      });
      ;
    },
    closePage: function closePage() {
      //关闭弹窗
      this.$emit('closePage');
    }
  }
};