import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import requestOrg from '../../api/orgChildren';
import employeeRequest from '@/api/employee';
import { getCurrentOrganization } from '@/utils/common';
export default {
  props: {
    isGetUserList: {
      //是否获取机构人员列表
      type: Boolean,
      default: false
    },
    searchForm: {
      //树节点是选中项
      type: Object,
      default: {
        salesOrgCode: '',
        salesOrgName: ''
      },
      required: true
    },
    isMultiple: {
      //树节点是否支持多选
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      showPop: false,
      orgData: [],
      defaultProps: {
        children: 'children',
        label: 'fullName'
      },
      highlightCurrent: true,
      salesOrgName: ''
    };
  },
  mounted: function mounted() {
    this.initOrgData();
  },
  methods: {
    getUsers: function getUsers(orgCode) {
      var _this = this;
      //获取机构人员列表
      if (!orgCode) return;
      employeeRequest.salesmanByOrgCode(orgCode).then(function (result) {
        var data = result.data || [];
        _this.$emit('getCurrentOrgUsers', data);
      });
    },
    initOrgData: function initOrgData() {
      var _this2 = this;
      //初始化组织机构树
      requestOrg.getTree(0).then(function (res) {
        if (res.data) {
          // this.orgData = res.data;
          // this.defaultSelectOrg = res.data[0];
          var _orgData = getCurrentOrganization([res.data]);
          _this2.orgData = [_orgData];
          _this2.defaultSelectOrg = _orgData;
        }
      });
    },
    orgFocus: function orgFocus(val) {
      //组织机构树焦点事件
      if (this.$refs.orgSelect && (this.$refs.orgSelect.query || this.$refs.orgSelect.query == '')) {
        this.$refs.tree.filter('');
        this.$forceUpdate();
      }
    },
    orgChange: function orgChange(val) {
      //组织机构树选择事件
      this.searchForm.salesOrgName = null;
      this.searchForm.salesOrgCode = null;
      this.$emit('nodeClick', this.searchForm);
    },
    handleNodeClick: function handleNodeClick(data, node, target) {
      // 机构树点击事件
      if (!this.isMultiple) {
        this.orgChange();
        this.searchForm.salesOrgCode = data.orgCode;
        this.searchForm.salesOrgName = data.fullName;
        this.searchForm.organizationId = data.organizationId;
        if (this.isGetUserList) {
          this.getUsers(data.orgCode);
        }
        this.$refs.orgSelect.blur();
        this.$emit('nodeClick', _objectSpread(_objectSpread({}, this.searchForm), {}, {
          bizType: data.bizType
        }));
        this.$forceUpdate();
      }
    },
    handleCheckChange: function handleCheckChange() {
      this.showPop = false;
      //获取选中的树节点
      var data = this.$refs.tree.getCheckedNodes();
      if (!_.isEmpty(data)) {
        this.salesOrgName = data[0].fullName + ' 等' + data.length + '家';
        var _names = [];
        var _codes = [];
        data.forEach(function (element) {
          _names.push(element.fullName);
          _codes.push(element.orgCode);
        });
        this.searchForm.salesOrgName = _names;
        this.searchForm.salesOrgCode = _codes;
      } else {
        this.salesOrgName = '';
        this.searchForm.salesOrgName = '';
        this.searchForm.salesOrgCode = '';
      }
      this.$emit('nodeClick', this.searchForm);
    },
    filterNode: function filterNode(value, data) {
      //机构树查询
      if (!value) return true;
      return data.fullName.indexOf(value) !== -1;
    },
    dataFilter: function dataFilter(val) {
      //机构树查询
      this.$refs.tree.filter(val);
    }
  }
};