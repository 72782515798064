var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: { "max-height": "600px" },
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "550px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.formRules,
                    "label-width": "110px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "供应商：",
                                        prop: "ocrFunctionSupplierOrgId",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            size: "mini",
                                            disabled: _vm.dialogType !== "add",
                                            clearable: "",
                                            placeholder: "请选择供应商",
                                          },
                                          on: {
                                            change: _vm.getSupplierOCRFunction,
                                          },
                                          model: {
                                            value:
                                              _vm.form.ocrFunctionSupplierOrgId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "ocrFunctionSupplierOrgId",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.ocrFunctionSupplierOrgId",
                                          },
                                        },
                                        _vm._l(
                                          _vm.suppliers,
                                          function (item, index) {
                                            return _c("el-option", {
                                              key: index,
                                              attrs: {
                                                label: item.ocrSupplierName,
                                                value:
                                                  item.ocrFunctionSupplierOrgId,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _vm.form.ocrFunctionSupplierOrgId
                                    ? _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "配置支持功能：",
                                            prop: "ocrFunction",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-checkbox-group",
                                            {
                                              attrs: {
                                                disabled:
                                                  _vm.dialogType == "view",
                                              },
                                              model: {
                                                value: _vm.form.ocrFunction,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "ocrFunction",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.ocrFunction",
                                              },
                                            },
                                            _vm._l(
                                              _vm.supplierOCRFunctions,
                                              function (item, index) {
                                                return _c(
                                                  "el-checkbox",
                                                  {
                                                    key: index,
                                                    attrs: {
                                                      label: item.itemCode,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(item.itemName)
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "配置支持功能：",
                                            prop: "ocrFunction",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticStyle: { color: "#ccc" } },
                                            [_vm._v("请先选择供应商")]
                                          ),
                                        ]
                                      ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _vm.dialogType == "add"
                                ? _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c("orgTree", {
                                        ref: "orgTree",
                                        attrs: {
                                          treeData: _vm.cascaderDatas,
                                          selectData: _vm.form.orgCode,
                                          checkboxDisabled:
                                            _vm.dialogType !== "add",
                                        },
                                        on: { setOrgCode: _vm.getOrgCode },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.dialogType !== "add"
                                ? _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "关联机构：",
                                            prop: "ocrFunction",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.form.orgName) +
                                              " " +
                                              _vm._s(
                                                _vm.form.orgName &&
                                                  _vm.form.orgCode
                                                  ? " - " + _vm.form.orgCode[0]
                                                  : _vm.form.orgCode
                                                  ? _vm.form.orgCode[0]
                                                  : null
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _vm.dialogType !== "view"
            ? _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    icon: "el-icon-check",
                    type: "primary",
                    plain: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.confirmCorpId("ruleForm")
                    },
                  },
                },
                [_vm._v("确认")]
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              attrs: { size: "mini", icon: "el-icon-close" },
              on: {
                click: function ($event) {
                  return _vm.closeRuleDialog(true)
                },
              },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }