//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import requestApi from '@/api/interactPlatform';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    opType: {
      type: String,
      default: ''
    },
    currentId: {
      type: String,
      default: ''
    },
    pushDataList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    dataConverterList: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      form: {
        inputConfigName: '',
        inputConfigCode: '',
        dataConfigCode: '',
        // dataConverter: '',
        inputConfigState: false,
        realTimeFlag: false,
        serviceName: '',
        serviceUri: ''
        // encryptionFlag: false,
        // encryptionKey: '',
      },

      rules: {
        inputConfigName: {
          required: true,
          message: '请输入数据名称',
          trigger: "blur"
        },
        inputConfigCode: {
          required: true,
          message: '请输入接入标识代码',
          trigger: "blur"
        },
        dataConfigCode: {
          required: true,
          message: '请选择推送数据',
          trigger: "change"
        },
        dataConverter: {
          required: true,
          message: '请选择模型转换器',
          trigger: "change"
        },
        inputConfigState: {
          required: true,
          message: '请选择是否启用',
          trigger: "change"
        },
        realTimeFlag: {
          required: true,
          message: '请选择是否实时',
          trigger: "change"
        },
        serviceName: {
          required: true,
          message: '请输入服务名',
          trigger: "blur"
        },
        serviceUri: {
          required: true,
          message: '请输入方法url',
          trigger: "blur"
        }
      },
      formLoading: false
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
      ;
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    initData: function initData() {
      var _this = this;
      this.form = {
        inputConfigName: '',
        inputConfigCode: '',
        dataConfigCode: '',
        // dataConverter: '',
        inputConfigState: false,
        realTimeFlag: false,
        serviceName: '',
        serviceUri: ''
        // encryptionFlag: false,
        // encryptionKey: '',
      };

      if (this.opType === "edit") {
        requestApi.getInputconfigInfo(this.currentId).then(function (res) {
          if (res.code === 200) {
            _this.form = res.data;
          }
          ;
        });
      }
      ;
      this.$refs.detail.resetFields();
    },
    // 保存数据
    save: function save() {
      var _this2 = this;
      this.$refs.detail.validate(function (valid) {
        if (valid) {
          _this2.formLoading = true;
          var req = _this2.opType === 'add' ? requestApi.addInputconfig(_this2.form) : requestApi.updateInputconfig(_this2.form);
          req.then(function (res) {
            if (res.code === 200) {
              _this2.formLoading = false;
              _this2.$message.success(res.msg);
              _this2.closePage();
            }
            ;
          }).catch(function (err) {
            _this2.formLoading = false;
          });
        }
        ;
      });
    },
    //关闭弹窗
    closePage: function closePage() {
      this.$emit('closePage');
    }
  }
};