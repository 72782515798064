import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.fill.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.unshift.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.join.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PageContainer from "@/components/PageContainer";
import FormLabel from "@/components/FormSubLabel";
import dict from '@/mixins/dict';
import { getSupplyers } from "@/utils/common";
import activityRequest from '../../api/insuranceActivities';
import CarBrands from '@/components/CarBrands/index';
import carService from '@/api/carService';
//限制输入最低金额
var numberLengthSix = function numberLengthSix(rule, value, callback) {
  if (String(value).length > 10 || String(value) <= 0 || String(value) == "" || value == undefined) {
    callback("请输入1-10位的金额");
  } else {
    callback();
  }
};
export default {
  name: "ActivityEdit",
  mixins: [dict],
  data: function data() {
    return {
      isCheck: !!this.$route.query.check,
      form: {
        activeRequireList: new Array(1).fill().map(function (val) {
          return {
            vehicleNameCode: '',
            indicators: '',
            conditions: '',
            rate: '',
            relevanceStatus: [],
            selectedArray: []
          };
        })
      },
      formRules: {
        activityName: {
          required: true,
          message: '请输入活动名称',
          trigger: 'blur'
        },
        stimulateAmount: {
          required: true,
          message: '请输入激励金额',
          trigger: 'blur'
        },
        minBsAmount: [{
          required: true,
          validator: numberLengthSix,
          trigger: "blur"
        }],
        // insCorpCode: {
        //   required: true,
        //   message: '请选择保险公司',
        //   trigger: 'change'
        // },
        activityStartTime: {
          required: true,
          message: '请选择活动开始时间',
          trigger: 'change'
        },
        activityEndTime: {
          required: true,
          message: '请选择活动结束时间',
          trigger: 'change'
        },
        description: {
          required: true,
          message: '请输入活动描述',
          trigger: 'blur'
        }
      },
      vehicleList: [],
      insuranceList: [],
      indicatorsList: [{
        name: '新车投保占比',
        value: 'RATE1'
      }, {
        name: '综合续保率',
        value: 'RATE2'
      }, {
        name: '次新车续保率',
        value: 'RATE3'
      }
      // {name: '延保产品渗透率', value: 'RATE4'},
      // {name: '延保销售贡献度', value: 'RATE5'},
      // {name: '代步服务产品渗透率', value: 'RATE6'},
      // {name: '代步服务产品销售贡献度', value: 'RATE7'}
      ],

      conditionsList: [{
        name: '大于',
        value: 'GT'
      }, {
        name: '小于',
        value: 'LT'
      }, {
        name: '等于',
        value: 'EQ'
      }],
      relevanceStatusTypeList: [{
        name: '与',
        value: '与'
      }, {
        name: '或',
        value: '或'
      }],
      activeOne: '1',
      activeTwo: '1',
      formLoading: false,
      selectedArray: [],
      carTypeVal: []
    };
  },
  watch: {
    '$route': function $route(to, from) {
      //监听路由是否变化
      this.isCheck = !!to.query.check;
      this.initData();
    }
  },
  components: {
    PageContainer: PageContainer,
    FormLabel: FormLabel,
    CarBrands: CarBrands
  },
  created: function created() {},
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    // 获取车系
    getCarSeriesList: function getCarSeriesList() {
      var _this = this;
      carService.carSeriesList(1, 10000).then(function (res) {
        if (res.code == 200 && res.data.list) {
          _this.vehicleList = [];
          res.data.list.forEach(function (val) {
            if (val.brandNo === "FTMS") {
              _this.vehicleList.push(val);
            }
          });
        }
      });
    },
    // 监听数据变化
    CarHandleChange: function CarHandleChange(val) {
      this.carTypeVal = val;
    },
    //车型全选
    selectAll: function selectAll(item) {
      if (item.selectedArray.length < this.vehicleList.length) {
        item.selectedArray = [];
        this.vehicleList.map(function (items) {
          item.selectedArray.push(items.vehicleNameCode);
        });
        item.selectedArray.unshift('allVehicle');
      } else {
        item.selectedArray = [];
      }
    },
    //取消全选
    removeTag: function removeTag(val, item) {
      if (val === 'allVehicle') {
        item.selectedArray = [];
      }
    },
    //select单选
    changeSelect: function changeSelect(val, item) {
      if (!val.includes('allVehicle') && val.length === this.vehicleList.length) {
        item.selectedArray.unshift('allVehicle');
      } else if (val.includes('allVehicle') && val.length - 1 < this.vehicleList.length) {
        item.selectedArray = item.selectedArray.filter(function (items) {
          return items !== 'allVehicle';
        });
      }
    },
    initData: function initData() {
      var _this2 = this;
      this.insuranceList = getSupplyers(); // 获取保险公司列表
      // activityRequest.getVehicleModelsList ().then ((res) => { // 获取车型列表
      //   if (res.code == RESPONSE_SUCCESS) {
      //     this.vehicleList = res.data
      //   }
      // }).catch ((err) => {
      //   this.loading = false;
      // });
      this.getCarSeriesList();
      if (this.$route.query.activityId) {
        // 获取活动详情
        this.formLoading = true;
        activityRequest.getActivityDetail(this.$route.query.activityId).then(function (res) {
          _this2.formLoading = false;
          if (res.code == RESPONSE_SUCCESS) {
            _this2.form = res.data;
            if (res.data.activeRequireList == null) {
              _this2.form.activeRequireList = [];
              _this2.form.activeRequireList.push({
                vehicleNameCode: '',
                indicators: '',
                conditions: '',
                rate: '',
                relevanceStatus: [],
                selectedArray: []
              });
              _this2.$forceUpdate();
            } else {
              _this2.form.activeRequireList.forEach(function (item) {
                item.vehicleNameCode = item.vehicleNameCode.split(',');
                _this2.$set(item, 'selectedArray', item.vehicleNameCode);
                item.relevanceStatus = item.relevanceStatus.split(',');
              });
            }
          }
        }).catch(function (err) {
          _this2.loading = false;
        });
      }
    },
    insCorpChange: function insCorpChange(val) {
      var obj = this.insuranceList.find(function (item) {
        return item.code === val;
      });
      this.form.insCorpName = obj.shortName;
    },
    rateInput: function rateInput(value, index) {
      // 清除"数字"和"."以外的字符
      value = value.replace(/[^\d.]/g, '');
      // 验证第一个字符是数字
      value = value.replace(/^\./g, '');
      // 只保留第一个, 清除多余的
      value = value.replace(/\.{2,}/g, '.');
      value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
      // 只能输入两个小数
      value = value.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');
      value > 100 ? value = 100 : '';
      value < 0 ? value = 0 : '';
      this.form.activeRequireList[index].rate = value;
    },
    relevanceStatusChange: function relevanceStatusChange(val) {
      if (val.length > 1) {
        val.splice(0, 1);
      }
    },
    addTarget: function addTarget() {
      this.form.activeRequireList.push({
        vehicleNameCode: '',
        indicators: '',
        conditions: '',
        rate: '',
        relevanceStatus: [],
        selectedArray: []
      });
    },
    deleteTarget: function deleteTarget(index) {
      if (this.form.activeRequireList.length > 1) {
        this.form.activeRequireList.splice(index, 1);
      }
    },
    cancel: function cancel() {
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.go(-1);
    },
    save: function save(flag) {
      var _this3 = this;
      this.$refs['form'].validate(function (valid, obj) {
        if (valid) {
          var params = JSON.parse(JSON.stringify(_this3.form));
          params.releaseStatus = flag;
          params.activeRequireList.forEach(function (item) {
            item.vehicleNameCode = item.selectedArray.join(','); //新增提交
            if (flag == 'SUBMIT') {
              item.vehicleNameCode = item.selectedArray.join(',');
            }
            if (item.selectedArray.length - 1 === _this3.vehicleList.length) {
              item.vehicleNameCode = 'allVehicle';
            }
            if (item.relevanceStatus != null) {
              item.relevanceStatus = item.relevanceStatus && item.relevanceStatus.join(',');
            } else {
              item.relevanceStatus = item.relevanceStatus && item.relevanceStatus.join(',');
            }
            if (item.indicators == '' || item.vehicleNameCode == '') {
              params.activeRequireList = [];
            }
            delete item.selectedArray;
          });
          if (flag !== 'SUBMIT') {
            activityRequest.addActivitys(params).then(function (res) {
              _this3.loading = false;
              if (res.code == RESPONSE_SUCCESS) {
                _this3.$message({
                  type: 'success',
                  message: '新增活动成功'
                });
                _this3.cancel();
              }
            }).catch(function (err) {
              _this3.loading = false;
              _this3.$message({
                type: 'error',
                message: res.msg
              });
            });
          } else {
            delete params.releaseStatus;
            activityRequest.updateActivity(_this3.$route.query.activityId, params).then(function (res) {
              _this3.loading = false;
              if (res.code == RESPONSE_SUCCESS) {
                _this3.$message({
                  type: 'success',
                  message: '编辑活动成功'
                });
                _this3.cancel();
              }
            }).catch(function (err) {
              _this3.loading = false;
              _this3.$message({
                type: 'error',
                message: res.msg
              });
            });
          }
        }
      });
    }
  },
  beforeRouteEnter: function beforeRouteEnter(to, from, next) {
    if (to.query.activityId) {
      to.meta.title = '编辑保险活动';
    }
    if (to.query.check) {
      to.meta.title = '查看保险活动详情';
    }
    next();
  }
};